/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useRef } from 'react';
import { Container, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeSlash,
  faExchangeAlt,
  faEye,
  faFileAlt,
  faFilter,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import DonutChart from '../Chart/DonutChart';
import WaffleChart from '../Chart/WaffleChart';
import './styles.css';

const Demographics = forwardRef(
  (
    {
      componentConfig,
      visualizationConfig,
      handleHideChart,
      handleUpdateDataSet,
      onAddToReport,
      readOnly,
      visualization,
      onExportChart,
      handleRef,
    },
    chartRef
  ) => {
    const schoolRef = useRef(null);
    const studentRef = useRef(null);
    const refsArr = [schoolRef, studentRef];
    const numFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    });
    const demographicBreakdownData = componentConfig?.queryResult?.demographicBreakdownData?.slice(
      0,
      2
    );
    const onChangeChart = () => {
      const newVisualizationComponent = { ...visualizationConfig };
      newVisualizationComponent.viewMode =
        visualizationConfig.viewMode === 'DONUT' ? 'WAFFLE' : 'DONUT';
      handleUpdateDataSet(newVisualizationComponent);
    };

    useEffect(() => {
      if (refsArr.length === 2) {
        handleRef(refsArr);
      }
    }, [refsArr, handleRef]);

    return (
      <Container fluid className="p-0" ref={chartRef}>
        {!readOnly && (
          <Container fluid className="p-4 bg-gray-200">
            <Row noGutters>
              <Col md={9} className="d-flex">
                {componentConfig?.queryResult?.filtered && (
                  <OverlayTrigger overlay={<Tooltip id="map-hide">Filtered</Tooltip>} delay={200}>
                    <FontAwesomeIcon
                      color="#AC193C"
                      icon={faFilter}
                      className="mt-0 mr-5"
                      size="lg"
                    />
                  </OverlayTrigger>
                )}
                <h3>{visualizationConfig.datasetFilter.description}</h3>
              </Col>
              <Col md={3} className="d-flex justify-content-end">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">{visualizationConfig.hidden ? 'View' : 'Hide'}</Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    icon={visualizationConfig.hidden ? faEye : faEyeSlash}
                    className="cursor-pointer mt-0"
                    size="lg"
                    onClick={handleHideChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      Change to {visualizationConfig?.viewMode === 'DONUT' ? 'waffle' : 'donut'}{' '}
                      chart
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    onClick={onChangeChart}
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faExchangeAlt}
                    size="lg"
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Export</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5 mt-0 cursor-pointer"
                    icon={faExternalLinkAlt}
                    size="lg"
                    onClick={onExportChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add to report</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faFileAlt}
                    size="lg"
                    onClick={onAddToReport}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid className="p-4 bg-white">
          {!visualizationConfig.hidden && (
            <>
              <Row>
                <Col className="demogra-col-1 d-flex text-center align-items-center justify-content-center">
                  <div className="d-flex flex-column mr-3">
                    Students
                    <h4 className="mt-3">
                      {componentConfig?.queryResult?.studentCount?.toLocaleString() || 'NA'}
                    </h4>
                  </div>
                  <div className="d-flex flex-column">
                    Teachers
                    <h4 className="mt-3">
                      {componentConfig?.queryResult?.teacherCount?.toLocaleString() || 'NA'}
                    </h4>
                  </div>
                </Col>
                <Col
                  className="demogra-col-3 d-flex flex-column align-items-center"
                  id={`students-chart-${componentConfig?.componentId}`}
                >
                  Student Ethnicity
                  {visualizationConfig?.viewMode === 'WAFFLE' && (
                    <WaffleChart
                      parsedData={componentConfig?.queryResult?.ethnicityData}
                      displayOnDashboard
                      ref={studentRef}
                      scheme={visualization?.details?.scheme}
                    />
                  )}
                  {visualizationConfig?.viewMode === 'DONUT' && (
                    <DonutChart
                      parsedData={componentConfig?.queryResult?.ethnicityData}
                      displayOnDashboard
                      ref={studentRef}
                      scheme={visualization?.details?.scheme}
                    />
                  )}
                </Col>
                <Col
                  className="demogra-col-4 d-flex flex-column align-items-center"
                  id={`school-chart-${componentConfig?.componentId}`}
                >
                  School Distribution
                  {visualizationConfig?.viewMode === 'WAFFLE' && (
                    <WaffleChart
                      parsedData={componentConfig?.queryResult?.schoolDistributionData}
                      displayOnDashboard
                      ref={schoolRef}
                      scheme={visualization?.details?.scheme}
                    />
                  )}
                  {visualizationConfig?.viewMode === 'DONUT' && (
                    <DonutChart
                      parsedData={componentConfig?.queryResult?.schoolDistributionData}
                      displayOnDashboard
                      ref={schoolRef}
                      scheme={visualization?.details?.scheme}
                    />
                  )}
                </Col>
                <Col className="demogra-col-5">
                  <Container fluid className="border border-gray mt-5">
                    <Row>
                      <Col md={5}>&nbsp;</Col>
                      {demographicBreakdownData?.map((db) => (
                        <Col key={db.seriesLabel}>
                          <h3>{db.seriesLabel}</h3>
                        </Col>
                      ))}
                    </Row>
                    <Row className="bg-gray-200 p-1">
                      <Col md={5}>% ELL</Col>
                      {demographicBreakdownData?.map((db) => (
                        <Col key={db.seriesLabel}>{db.ell?.toLocaleString()}%</Col>
                      ))}
                    </Row>
                    <Row className="bg-gray-200 mt-1 p-1">
                      <Col md={5}>% w/Disabilities</Col>
                      {demographicBreakdownData?.map((db) => (
                        <Col key={db.seriesLabel}>{db.disabilities?.toLocaleString()}%</Col>
                      ))}
                    </Row>
                    <Row className="bg-gray-200 mt-1 p-1">
                      <Col md={5}>% Low Income</Col>
                      {demographicBreakdownData?.map((db) => (
                        <Col key={db.seriesLabel}>{db.lowIncome?.toLocaleString()}%</Col>
                      ))}
                    </Row>
                    <Row className="bg-gray-200 mt-1 p-1">
                      <Col md={5}>$ / Student</Col>
                      {demographicBreakdownData?.map((db) => (
                        <Col key={db.seriesLabel}>$ {db.dollarsPerStudent?.toLocaleString()}</Col>
                      ))}
                    </Row>
                    <Row className="bg-gray-200 mt-1 p-1">
                      <Col md={5}>Districts (LEAs)</Col>
                      {demographicBreakdownData?.map((db) => (
                        <Col key={db.seriesLabel}>{numFormatter.format(db.size)}</Col>
                      ))}
                    </Row>
                  </Container>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Container>
    );
  }
);

Demographics.displayName = 'Demographics';

export default React.memo(Demographics);

import React from 'react';
import { Container } from 'react-bootstrap';
import SurveyTab from './components/survey-tab';
import './style.css';

const SurveyExplorerLanding = () => (
  <Container className="summary-container">
    <SurveyTab />
  </Container>
);

export default React.memo(SurveyExplorerLanding);

export const NV = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2252, 39.9994],
            [-118.501, 39.9987],
            [-117.5417, 40.001],
            [-117.4613, 39.8678],
            [-117.4953, 39.7484],
            [-117.4646, 39.6795],
            [-117.4853, 39.5453],
            [-117.5871, 39.5185],
            [-117.646, 39.4266],
            [-117.763, 39.3528],
            [-117.7199, 39.2368],
            [-117.793, 39.1611],
            [-117.7755, 39.0934],
            [-117.8651, 39.0737],
            [-118.7538, 39.0746],
            [-118.754, 39.112],
            [-119.0623, 39.3593],
            [-119.1182, 39.3885],
            [-119.0901, 39.5187],
            [-119.078, 39.7224],
            [-119.1893, 39.6509],
            [-119.1709, 39.7883],
            [-119.2085, 39.81],
            [-119.2252, 39.9994],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200030',
        NAME: 'Churchill County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12770148576,
        AWATER: 242405502,
        INTPTLAT: '+39.5377100',
        INTPTLON: '-118.2641653',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.8969, 36.8421],
            [-115.7408, 36.8531],
            [-114.7625, 36.8535],
            [-114.7623, 36.8439],
            [-114.0504, 36.8431],
            [-114.0439, 36.1933],
            [-114.1534, 36.0232],
            [-114.2439, 36.0153],
            [-114.3702, 36.1426],
            [-114.5047, 36.1272],
            [-114.6271, 36.1408],
            [-114.7545, 36.0862],
            [-114.7081, 35.9099],
            [-114.683, 35.6764],
            [-114.6539, 35.6117],
            [-114.6789, 35.5013],
            [-114.5952, 35.3219],
            [-114.5695, 35.1623],
            [-114.6307, 35.0403],
            [-114.6335, 35.0019],
            [-114.7132, 35.0665],
            [-115.6484, 35.8092],
            [-115.846, 35.9637],
            [-115.8458, 36.1708],
            [-115.8958, 36.1722],
            [-115.8969, 36.8421],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200060',
        NAME: 'Clark County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20438834319,
        AWATER: 438841388,
        INTPTLAT: '+36.2142575',
        INTPTLON: '-115.0138120',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0034, 39.1127],
            [-119.7612, 39.114],
            [-119.7521, 39.0849],
            [-119.551, 39.0858],
            [-119.3117, 39.0836],
            [-119.3107, 38.9907],
            [-119.4208, 38.9547],
            [-119.4063, 38.7334],
            [-119.3499, 38.7292],
            [-119.3284, 38.5348],
            [-119.5854, 38.7132],
            [-119.9043, 38.9333],
            [-120.001, 38.9996],
            [-120.0025, 39.0675],
            [-120.0034, 39.1127],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200090',
        NAME: 'Douglas County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1838162296,
        AWATER: 72317570,
        INTPTLAT: '+38.9051292',
        INTPTLON: '-119.6090191',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0183, 41.9994],
            [-116.8623, 41.9987],
            [-116.3685, 41.9963],
            [-115.57, 41.9968],
            [-114.7212, 41.9982],
            [-114.2819, 41.9942],
            [-114.0415, 41.9939],
            [-114.042, 40.9999],
            [-114.0466, 40.1169],
            [-114.9018, 40.1221],
            [-115.835, 40.1278],
            [-116.0009, 40.1274],
            [-116.1578, 40.6664],
            [-116.1582, 40.9999],
            [-116.5868, 41.0004],
            [-117.0184, 41.0003],
            [-117.0183, 41.9994],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200120',
        NAME: 'Elko County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44471193293,
        AWATER: 85747701,
        INTPTLAT: '+41.1411327',
        INTPTLON: '-115.3514239',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.691, 38.4738],
            [-117.1656, 38.0017],
            [-117.166, 36.9711],
            [-117.479, 37.2045],
            [-117.8327, 37.4649],
            [-118.4281, 37.8962],
            [-118.3515, 37.8937],
            [-117.691, 38.4738],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200150',
        NAME: 'Esmeralda County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9277052939,
        AWATER: 18552011,
        INTPTLAT: '+37.7789658',
        INTPTLON: '-117.6323824',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.5868, 41.0004],
            [-116.1582, 40.9999],
            [-116.1578, 40.6664],
            [-116.0009, 40.1274],
            [-115.835, 40.1278],
            [-115.8357, 39.9947],
            [-115.7949, 39.8531],
            [-115.8331, 39.7294],
            [-115.8135, 39.5935],
            [-115.8422, 39.5112],
            [-115.9077, 39.4644],
            [-115.9071, 39.1618],
            [-116.6009, 39.1615],
            [-116.5929, 39.3749],
            [-116.5904, 40.0723],
            [-116.5968, 40.9465],
            [-116.5868, 41.0004],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200180',
        NAME: 'Eureka County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10814963933,
        AWATER: 11082875,
        INTPTLAT: '+39.9777878',
        INTPTLON: '-116.2722082',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3242, 41.9939],
            [-118.8296, 41.9926],
            [-118.1972, 41.997],
            [-117.0262, 41.9999],
            [-117.0183, 41.9994],
            [-117.0184, 41.0003],
            [-117.0187, 40.643],
            [-117.2468, 40.6424],
            [-117.3006, 40.5261],
            [-117.3094, 40.6835],
            [-117.6444, 40.6843],
            [-117.6442, 40.8578],
            [-118.7867, 40.8559],
            [-118.7867, 40.9609],
            [-119.3096, 40.9604],
            [-119.3298, 40.9604],
            [-119.324, 41.4145],
            [-119.3242, 41.9939],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200210',
        NAME: 'Humboldt County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24969524844,
        AWATER: 44196979,
        INTPTLAT: '+41.4079136',
        INTPTLON: '-118.1275915',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5417, 40.001],
            [-117.3006, 40.5261],
            [-117.2468, 40.6424],
            [-117.0187, 40.643],
            [-117.0184, 41.0003],
            [-116.5868, 41.0004],
            [-116.5968, 40.9465],
            [-116.5904, 40.0723],
            [-116.5929, 39.3749],
            [-116.6009, 39.1615],
            [-117.3319, 39.1634],
            [-117.7755, 39.0934],
            [-117.793, 39.1611],
            [-117.7199, 39.2368],
            [-117.763, 39.3528],
            [-117.646, 39.4266],
            [-117.5871, 39.5185],
            [-117.4853, 39.5453],
            [-117.4646, 39.6795],
            [-117.4953, 39.7484],
            [-117.4613, 39.8678],
            [-117.5417, 40.001],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200240',
        NAME: 'Lander County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14219255502,
        AWATER: 76103226,
        INTPTLAT: '+39.9002105',
        INTPTLON: '-117.0472398',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0008, 38.6773],
            [-114.0498, 38.6774],
            [-114.05, 38.573],
            [-114.05, 38.1488],
            [-114.0524, 37.6048],
            [-114.0501, 37.0002],
            [-114.0504, 36.8431],
            [-114.7623, 36.8439],
            [-114.7625, 36.8535],
            [-115.7408, 36.8531],
            [-115.8969, 36.8421],
            [-115.8951, 37.5481],
            [-115.8939, 38.0505],
            [-115.0008, 38.0508],
            [-115.0008, 38.6773],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200270',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27540364862,
        AWATER: 9802713,
        INTPTLAT: '+37.6346050',
        INTPTLON: '-114.8630367',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7126, 39.2509],
            [-119.4788, 39.3374],
            [-119.2836, 39.6216],
            [-119.1893, 39.6509],
            [-119.078, 39.7224],
            [-119.0901, 39.5187],
            [-119.1182, 39.3885],
            [-119.0623, 39.3593],
            [-118.754, 39.112],
            [-118.7538, 39.0746],
            [-118.9297, 39.0737],
            [-119.0148, 38.9433],
            [-119.0145, 38.8513],
            [-118.9021, 38.8522],
            [-118.9069, 38.4147],
            [-119.157, 38.4147],
            [-119.3284, 38.5348],
            [-119.3499, 38.7292],
            [-119.4063, 38.7334],
            [-119.4208, 38.9547],
            [-119.3107, 38.9907],
            [-119.3117, 39.0836],
            [-119.551, 39.0858],
            [-119.5637, 39.1954],
            [-119.649, 39.1953],
            [-119.7126, 39.2509],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200300',
        NAME: 'Lyon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5183310353,
        AWATER: 59602530,
        INTPTLAT: '+39.0222125',
        INTPTLON: '-119.1974246',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7538, 39.0746],
            [-117.8651, 39.0737],
            [-118.1963, 38.9999],
            [-118.1962, 38.9191],
            [-117.691, 38.4738],
            [-118.3515, 37.8937],
            [-118.4281, 37.8962],
            [-119.157, 38.4147],
            [-118.9069, 38.4147],
            [-118.9021, 38.8522],
            [-119.0145, 38.8513],
            [-119.0148, 38.9433],
            [-118.9297, 39.0737],
            [-118.7538, 39.0746],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200330',
        NAME: 'Mineral County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9719802099,
        AWATER: 155746685,
        INTPTLAT: '+38.5166470',
        INTPTLON: '-118.4162794',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8651, 39.0737],
            [-117.7755, 39.0934],
            [-117.3319, 39.1634],
            [-116.6009, 39.1615],
            [-115.9071, 39.1618],
            [-115.0008, 38.6773],
            [-115.0008, 38.0508],
            [-115.8939, 38.0505],
            [-115.8951, 37.5481],
            [-115.8969, 36.8421],
            [-115.8958, 36.1722],
            [-115.8458, 36.1708],
            [-115.846, 35.9637],
            [-116.4882, 36.4591],
            [-116.8894, 36.7635],
            [-117.0645, 36.8947],
            [-117.166, 36.9711],
            [-117.1656, 38.0017],
            [-117.691, 38.4738],
            [-118.1962, 38.9191],
            [-118.1963, 38.9999],
            [-117.8651, 39.0737],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200360',
        NAME: 'Nye County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47091002405,
        AWATER: 43207981,
        INTPTLAT: '+37.9658636',
        INTPTLON: '-116.4590107',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0043, 39.1656],
            [-119.8808, 39.1655],
            [-119.7126, 39.2509],
            [-119.649, 39.1953],
            [-119.5637, 39.1954],
            [-119.551, 39.0858],
            [-119.7521, 39.0849],
            [-119.7612, 39.114],
            [-120.0034, 39.1127],
            [-120.0043, 39.1656],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200390',
        NAME: 'Carson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374669606,
        AWATER: 32587484,
        INTPTLAT: '+39.1530600',
        INTPTLON: '-119.7473792',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3096, 40.9604],
            [-118.7867, 40.9609],
            [-118.7867, 40.8559],
            [-117.6442, 40.8578],
            [-117.6444, 40.6843],
            [-117.3094, 40.6835],
            [-117.3006, 40.5261],
            [-117.5417, 40.001],
            [-118.501, 39.9987],
            [-119.2252, 39.9994],
            [-119.3393, 39.9986],
            [-119.3385, 40.5178],
            [-119.313, 40.5183],
            [-119.3096, 40.9604],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200420',
        NAME: 'Pershing County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15634723159,
        AWATER: 79934451,
        INTPTLAT: '+40.4396390',
        INTPTLON: '-118.4094765',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2836, 39.6216],
            [-119.4788, 39.3374],
            [-119.7126, 39.2509],
            [-119.6775, 39.2809],
            [-119.6956, 39.52],
            [-119.3706, 39.5869],
            [-119.2836, 39.6216],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200450',
        NAME: 'Storey County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 680962190,
        AWATER: 1871089,
        INTPTLAT: '+39.4383853',
        INTPTLON: '-119.5246462',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0038, 39.4451],
            [-120.0011, 39.7224],
            [-119.997, 40.1588],
            [-119.9987, 40.7795],
            [-119.9998, 41.0119],
            [-119.9992, 41.9945],
            [-119.3602, 41.9942],
            [-119.3242, 41.9939],
            [-119.324, 41.4145],
            [-119.3298, 40.9604],
            [-119.3096, 40.9604],
            [-119.313, 40.5183],
            [-119.3385, 40.5178],
            [-119.3393, 39.9986],
            [-119.2252, 39.9994],
            [-119.2085, 39.81],
            [-119.1709, 39.7883],
            [-119.1893, 39.6509],
            [-119.2836, 39.6216],
            [-119.3706, 39.5869],
            [-119.6956, 39.52],
            [-119.6775, 39.2809],
            [-119.7126, 39.2509],
            [-119.8808, 39.1655],
            [-120.0043, 39.1656],
            [-120.0038, 39.4451],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200480',
        NAME: 'Washoe County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16323089034,
        AWATER: 621162127,
        INTPTLAT: '+40.7340150',
        INTPTLON: '-119.6622724',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.835, 40.1278],
            [-114.9018, 40.1221],
            [-114.0466, 40.1169],
            [-114.047, 39.9061],
            [-114.0476, 39.5427],
            [-114.0498, 38.6774],
            [-115.0008, 38.6773],
            [-115.9071, 39.1618],
            [-115.9077, 39.4644],
            [-115.8422, 39.5112],
            [-115.8135, 39.5935],
            [-115.8331, 39.7294],
            [-115.7949, 39.8531],
            [-115.8357, 39.9947],
            [-115.835, 40.1278],
          ],
        ],
      },
      properties: {
        STATEFP: '32',
        SCSDLEA: '',
        GEOID: '3200510',
        NAME: 'White Pine County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22985054689,
        AWATER: 54678877,
        INTPTLAT: '+39.4182344',
        INTPTLON: '-114.9006050',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
  ],
};

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { GA_MEASUREMENT_ID } from '../config/appConfig';

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    if (GA_MEASUREMENT_ID) {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
    }
  }, [location]);
}

function PageViews({ children }) {
  usePageViews();
  return <>{children}</>;
}

export default PageViews;

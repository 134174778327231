import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { setWizard } from '../../actions/wizard.action';
import Divider from '../Divider';
import providers from '../../providers';
import './style.css';

const VendorSelect = () => {
  const selectedValue = useSelector((state) => state.wizard.providerLabel);
  const jwt = useSelector((state) => state.session.jwt);
  const currentUser = jwtDecode(jwt);
  const dispatch = useDispatch();
  const onSelectHandler = (e) => {
    dispatch(setWizard(e, currentUser));
  };
  return (
    <>
      <div className="VendorSelect">
        <span className="VendorSelect-custom-label">Select a source</span>
        <DropdownButton
          id="dropdown-item-button"
          className="custom-dropdown"
          onSelect={(e) => onSelectHandler(e)}
          title={selectedValue || 'Select'}
        >
          {providers.map((provider) => {
            if (provider.providerLabel) {
              return (
                <Dropdown.Item as="button" eventKey={provider.wizard}>
                  {provider.providerLabel}
                </Dropdown.Item>
              );
            }
            return null;
          })}
        </DropdownButton>
      </div>
      <Divider spacing="md" />
    </>
  );
};

export default VendorSelect;

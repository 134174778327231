import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row, FormCheck } from 'react-bootstrap';

const OrgForm = ({ show, handleClose, handleSave }) => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [suppressData, setSuppressData] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      handleSave({
        name,
        description,
        suppressData,
      });
    }
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleSupressDataChange = (e) => setSuppressData(e.target.checked);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Create Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} className="text-left" onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationName">
                <Form.Label>Organization Name *</Form.Label>
                <Form.Control
                  placeholder="Organization Name"
                  required
                  type="text"
                  onChange={handleNameChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please complete organization name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="Description"
                  as="textarea"
                  rows={3}
                  onChange={handleDescriptionChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FormCheck onChange={handleSupressDataChange} label="Suppress Data" />
              </Form.Group>
            </Form.Row>
            <Row>
              <Col>
                <hr />
                <Button
                  variant="outline-secondary"
                  className="btn btn-custom white mr-3"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit" className="btn btn-custom dark">
                  Save changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

OrgForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default OrgForm;

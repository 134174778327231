import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import SectionTitle from '../SectionTitle';
import './style.css';

const FetchScreen = ({ title, image, alt, caption, type }) => {
  if (type === 'error') {
    return (
      <>
        <SectionTitle title="Error" />
        <div className="FetchScreen">
          <h2 className="FetchScreen-title">{title}</h2>
          <div className="FetchScreen-caption">{caption}</div>
          <div className="FetchScreen-image">
            <img src={image} alt={alt} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="spinner-container">
        <Spinner size="lg" />
      </div>
    </>
  );
};

FetchScreen.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  type: PropTypes.string,
};

FetchScreen.defaultProps = {
  type: 'spinner',
  title: 'There was an error while loading Data',
  image: '/art-error-message.svg',
  caption: null,
  alt: 'Error loading data',
};

export default FetchScreen;

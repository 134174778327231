export default class Filter {
  constructor(groups) {
    this.constraintGroups = groups || [];
  }

  getConstraintGroups() {
    return this.constraintGroups;
  }

  toString() {
    return `Filter [groups=${JSON.stringify(this.constraintGroups)}]`;
  }

  equals(obj) {
    if (this === obj) {
      return true;
    }
    if (!super.equals(obj)) {
      return false;
    }
    if (!(obj instanceof Filter)) {
      return false;
    }
    const other = obj;
    if (this.constraintGroups == null) {
      if (other.constraintGroups != null) {
        return false;
      }
    }
    return true;
  }
}

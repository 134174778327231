export const MO = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5842, 37.714],
            [-89.5166, 37.6927],
            [-89.4784, 37.5989],
            [-89.5219, 37.5662],
            [-89.6509, 37.5979],
            [-89.5842, 37.714],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903040',
        NAME: 'Altenburg 48 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147970898,
        AWATER: 10291090,
        INTPTLAT: '+37.6420272',
        INTPTLON: '-089.5725143',
        ELSDLEA: '03040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7105, 39.8207],
            [-94.8055, 39.821],
            [-94.7286, 39.9077],
            [-94.7105, 39.8207],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904080',
        NAME: 'Avenue City R-IX School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49819836,
        AWATER: 954874,
        INTPTLAT: '+39.8548391',
        INTPTLON: '-094.7532819',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2529, 37.2487],
            [-94.1084, 37.2422],
            [-94.0533, 37.2518],
            [-94.0562, 37.1547],
            [-94.1826, 37.1553],
            [-94.2529, 37.2487],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904110',
        NAME: 'Avilla R-XIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170815004,
        AWATER: 582616,
        INTPTLAT: '+37.2069088',
        INTPTLON: '-094.1362815',
        ELSDLEA: '04110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9653, 37.7385],
            [-90.7006, 37.7362],
            [-90.646, 37.7047],
            [-90.6465, 37.6901],
            [-90.6675, 37.6438],
            [-90.7851, 37.6024],
            [-90.9502, 37.6037],
            [-90.9653, 37.7385],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904590',
        NAME: 'Belleview R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324830493,
        AWATER: 239376,
        INTPTLAT: '+37.6753582',
        INTPTLON: '-090.8184180',
        ELSDLEA: '04590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0459, 39.0385],
            [-92.9152, 39.0228],
            [-92.8476, 38.9809],
            [-92.8965, 38.9405],
            [-93.052, 38.8842],
            [-93.0505, 38.9282],
            [-93.1053, 38.9746],
            [-93.0459, 39.0385],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905190',
        NAME: 'Blackwater R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194319593,
        AWATER: 2881008,
        INTPTLAT: '+38.9759651',
        INTPTLON: '-092.9894442',
        ELSDLEA: '05190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0244, 39.4392],
            [-90.9496, 39.4078],
            [-90.9502, 39.3577],
            [-91.0631, 39.3138],
            [-91.0629, 39.3576],
            [-91.0244, 39.4392],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905400',
        NAME: 'Boncl R-X School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98229634,
        AWATER: 3667983,
        INTPTLAT: '+39.3812008',
        INTPTLON: '-091.0247734',
        ELSDLEA: '05400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6834, 39.873],
            [-92.609, 39.8676],
            [-92.594, 39.7582],
            [-92.6159, 39.6525],
            [-92.616, 39.6525],
            [-92.6895, 39.6981],
            [-92.6834, 39.873],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906540',
        NAME: 'Callao C-8 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152583551,
        AWATER: 951388,
        INTPTLAT: '+39.7764919',
        INTPTLON: '-092.6508222',
        ELSDLEA: '06540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4821, 37.4804],
            [-89.4209, 37.394],
            [-89.4891, 37.3332],
            [-89.5467, 37.3527],
            [-89.5416, 37.4887],
            [-89.4821, 37.4804],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907320',
        NAME: 'Nell Holcomb R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147158932,
        AWATER: 8070175,
        INTPTLAT: '+37.4076721',
        INTPTLON: '-089.5088829',
        ELSDLEA: '07320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0609, 37.5054],
            [-90.918, 37.4969],
            [-90.92, 37.4375],
            [-90.847, 37.3728],
            [-90.8127, 37.2854],
            [-91.0299, 37.358],
            [-91.0095, 37.4491],
            [-91.0609, 37.5054],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908340',
        NAME: 'Centerville R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295296362,
        AWATER: 607912,
        INTPTLAT: '+37.4167064',
        INTPTLON: '-090.9286447',
        ELSDLEA: '08340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6149, 39.9628],
            [-93.611, 39.963],
            [-93.484, 39.9654],
            [-93.362, 39.9676],
            [-93.3613, 39.9239],
            [-93.3893, 39.8797],
            [-93.5412, 39.8772],
            [-93.6149, 39.9628],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908790',
        NAME: 'Livingston County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168594395,
        AWATER: 1141504,
        INTPTLAT: '+39.9253264',
        INTPTLON: '-093.4792329',
        ELSDLEA: '08790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7258, 38.7136],
            [-92.6716, 38.7362],
            [-92.6161, 38.7308],
            [-92.6353, 38.6303],
            [-92.7295, 38.6096],
            [-92.7258, 38.7136],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909090',
        NAME: 'Clarksburg C-2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126850305,
        AWATER: 264307,
        INTPTLAT: '+38.6759485',
        INTPTLON: '-092.6900204',
        ELSDLEA: '09090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9832, 39.5975],
            [-93.9831, 39.6195],
            [-93.8707, 39.6127],
            [-93.9271, 39.5248],
            [-93.9832, 39.5975],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910230',
        NAME: 'Cowgill R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88376265,
        AWATER: 544112,
        INTPTLAT: '+39.5754605',
        INTPTLON: '-093.9341406',
        ELSDLEA: '10230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0674, 38.4032],
            [-93.9008, 38.4137],
            [-93.8129, 38.3533],
            [-93.8144, 38.3022],
            [-93.8506, 38.2963],
            [-94.0336, 38.3025],
            [-94.0705, 38.3305],
            [-94.0674, 38.4032],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910470',
        NAME: 'Davis R-XII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206414918,
        AWATER: 9366673,
        INTPTLAT: '+38.3424751',
        INTPTLON: '-093.9369188',
        ELSDLEA: '10470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.566, 37.7894],
            [-91.5288, 37.789],
            [-91.4188, 37.7885],
            [-91.3083, 37.7872],
            [-91.274, 37.6994],
            [-91.3658, 37.7008],
            [-91.554, 37.6595],
            [-91.6049, 37.7077],
            [-91.566, 37.7894],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910650',
        NAME: 'North Wood R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307315359,
        AWATER: 1982582,
        INTPTLAT: '+37.7175617',
        INTPTLON: '-091.4214582',
        ELSDLEA: '10650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8055, 37.7913],
            [-91.6932, 37.7908],
            [-91.6369, 37.8333],
            [-91.566, 37.7894],
            [-91.6049, 37.7077],
            [-91.554, 37.6595],
            [-91.5542, 37.6253],
            [-91.5729, 37.6259],
            [-91.7548, 37.5988],
            [-91.8091, 37.5989],
            [-91.8055, 37.7913],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910710',
        NAME: 'Dent-Phelps R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445997085,
        AWATER: 1073211,
        INTPTLAT: '+37.6964551',
        INTPTLON: '-091.6929941',
        ELSDLEA: '10710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5787, 37.007],
            [-92.4881, 37.0198],
            [-92.3791, 37.0224],
            [-92.3467, 36.8868],
            [-92.493, 36.8339],
            [-92.5796, 36.9639],
            [-92.5787, 37.007],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911010',
        NAME: 'Skyline R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312689372,
        AWATER: 323625,
        INTPTLAT: '+36.9370676',
        INTPTLON: '-092.4454212',
        ELSDLEA: '11010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9082, 36.8572],
            [-92.908, 36.8676],
            [-92.7632, 36.8927],
            [-92.783, 36.8065],
            [-92.9037, 36.809],
            [-92.9082, 36.8572],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911040',
        NAME: 'Plainview R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134401174,
        AWATER: 153815,
        INTPTLAT: '+36.8720217',
        INTPTLON: '-092.8240341',
        ELSDLEA: '11040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2949, 38.6929],
            [-94.1916, 38.7089],
            [-94.1484, 38.7005],
            [-94.1464, 38.6044],
            [-94.2098, 38.6199],
            [-94.2949, 38.6929],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911160',
        NAME: 'East Lynne 40 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99074862,
        AWATER: 841995,
        INTPTLAT: '+38.6689022',
        INTPTLON: '-094.1995782',
        ELSDLEA: '11160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.113, 36.7772],
            [-91.887, 36.7429],
            [-91.8509, 36.7119],
            [-91.851, 36.6985],
            [-91.8987, 36.6847],
            [-91.9712, 36.6427],
            [-92.1164, 36.6514],
            [-92.1147, 36.7189],
            [-92.113, 36.7772],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911850',
        NAME: 'Fairview R-XI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245281397,
        AWATER: 252118,
        INTPTLAT: '+36.7053231',
        INTPTLON: '-092.0186329',
        ELSDLEA: '11850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2928, 38.3306],
            [-91.2188, 38.3747],
            [-91.1172, 38.3325],
            [-91.1445, 38.2409],
            [-91.2555, 38.2413],
            [-91.2928, 38.3306],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912450',
        NAME: 'Spring Bluff R-XV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162285226,
        AWATER: 158631,
        INTPTLAT: '+38.2954080',
        INTPTLON: '-091.2234184',
        ELSDLEA: '12450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2928, 38.3306],
            [-91.2555, 38.2413],
            [-91.2555, 38.2041],
            [-91.3581, 38.2041],
            [-91.3759, 38.2243],
            [-91.3654, 38.3315],
            [-91.2928, 38.3306],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912480',
        NAME: 'Strain-Japan R-XVI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112185726,
        AWATER: 348273,
        INTPTLAT: '+38.2473250',
        INTPTLON: '-091.3242477',
        ELSDLEA: '12480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3121, 38.5982],
            [-91.1998, 38.5958],
            [-91.2003, 38.5105],
            [-91.2557, 38.5049],
            [-91.3685, 38.5094],
            [-91.3121, 38.5982],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912510',
        NAME: 'Franklin County R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146263179,
        AWATER: 265731,
        INTPTLAT: '+38.5496855',
        INTPTLON: '-091.2833494',
        ELSDLEA: '12510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4654, 37.715],
            [-92.4627, 37.7149],
            [-92.3942, 37.7135],
            [-92.3, 37.669],
            [-92.3896, 37.5258],
            [-92.468, 37.5665],
            [-92.5213, 37.578],
            [-92.4654, 37.715],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912720',
        NAME: 'Gasconade C-4 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235601432,
        AWATER: 1641742,
        INTPTLAT: '+37.6242720',
        INTPTLON: '-092.3975291',
        ELSDLEA: '12720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9685, 39.3152],
            [-92.9606, 39.3129],
            [-92.9498, 39.205],
            [-93.0386, 39.2082],
            [-92.9685, 39.3152],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912840',
        NAME: 'Gilliam C-4 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80708918,
        AWATER: 1929349,
        INTPTLAT: '+39.2453924',
        INTPTLON: '-092.9843153',
        ELSDLEA: '12840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9456, 40.4318],
            [-91.9497, 40.3008],
            [-92.0341, 40.3131],
            [-92.0828, 40.4262],
            [-91.9456, 40.4318],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913020',
        NAME: 'Gorin R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137047363,
        AWATER: 778562,
        INTPTLAT: '+40.3718562',
        INTPTLON: '-092.0089538',
        ELSDLEA: '13020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7548, 37.5988],
            [-91.5729, 37.6259],
            [-91.5365, 37.5812],
            [-91.5554, 37.421],
            [-91.6466, 37.4227],
            [-91.755, 37.4241],
            [-91.7548, 37.5988],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913260',
        NAME: 'Green Forest R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 386118556,
        AWATER: 560376,
        INTPTLAT: '+37.5175834',
        INTPTLON: '-091.6449499',
        ELSDLEA: '13260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4994, 40.1195],
            [-93.4948, 40.0761],
            [-93.484, 39.9654],
            [-93.611, 39.963],
            [-93.612, 40.0318],
            [-93.4994, 40.1195],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913440',
        NAME: 'Pleasant View R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162189970,
        AWATER: 150004,
        INTPTLAT: '+40.0387342',
        INTPTLON: '-093.5459110',
        ELSDLEA: '13440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1841, 39.604],
            [-92.1211, 39.6034],
            [-92.075, 39.5547],
            [-92.0789, 39.424],
            [-92.126, 39.4147],
            [-92.1897, 39.4454],
            [-92.1841, 39.604],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914520',
        NAME: 'Holliday C-2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161211233,
        AWATER: 1042577,
        INTPTLAT: '+39.5083570',
        INTPTLON: '-092.1322433',
        ELSDLEA: '14520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7845, 36.9016],
            [-91.7717, 36.901],
            [-91.7575, 36.9009],
            [-91.6582, 36.8736],
            [-91.6766, 36.6939],
            [-91.713, 36.6951],
            [-91.7868, 36.733],
            [-91.8324, 36.8184],
            [-91.8455, 36.8475],
            [-91.7845, 36.9016],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915060',
        NAME: 'Junction Hill C-12 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261668354,
        AWATER: 112077,
        INTPTLAT: '+36.8086324',
        INTPTLON: '-091.7408827',
        ELSDLEA: '15060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8987, 36.6847],
            [-91.851, 36.6985],
            [-91.844, 36.6255],
            [-91.7634, 36.5768],
            [-91.7085, 36.4994],
            [-91.9258, 36.4986],
            [-91.931, 36.62],
            [-91.8987, 36.6847],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915180',
        NAME: 'Glenwood R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265422260,
        AWATER: 862900,
        INTPTLAT: '+36.5650108',
        INTPTLON: '-091.8315591',
        ELSDLEA: '15180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.851, 36.6985],
            [-91.8509, 36.7119],
            [-91.7868, 36.733],
            [-91.713, 36.6951],
            [-91.7634, 36.5768],
            [-91.844, 36.6255],
            [-91.851, 36.6985],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915210',
        NAME: 'Howell Valley R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160423724,
        AWATER: 379692,
        INTPTLAT: '+36.6710410',
        INTPTLON: '-091.7719293',
        ELSDLEA: '15210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.184, 38.2593],
            [-94.0737, 38.2581],
            [-94.0499, 38.214],
            [-94.1015, 38.1052],
            [-94.2013, 38.1516],
            [-94.184, 38.2593],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915240',
        NAME: 'Hudson R-IX School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197036503,
        AWATER: 1616580,
        INTPTLAT: '+38.1845181',
        INTPTLON: '-094.1320395',
        ELSDLEA: '15240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4082, 38.1881],
            [-90.396, 38.2013],
            [-90.3904, 38.2027],
            [-90.384, 38.2088],
            [-90.3558, 38.2169],
            [-90.2527, 38.1278],
            [-90.3415, 38.0872],
            [-90.4152, 38.0864],
            [-90.4082, 38.1881],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916230',
        NAME: 'Jefferson County R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138096867,
        AWATER: 4286282,
        INTPTLAT: '+38.1375739',
        INTPTLON: '-090.3498617',
        ELSDLEA: '16230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6205, 37.2183],
            [-89.6203, 37.233],
            [-89.5835, 37.2331],
            [-89.4945, 37.1488],
            [-89.6127, 37.0824],
            [-89.6492, 37.1262],
            [-89.6205, 37.2183],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916470',
        NAME: 'Kelso C-7 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124119533,
        AWATER: 812477,
        INTPTLAT: '+37.1631731',
        INTPTLON: '-089.5771469',
        ELSDLEA: '16470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1173, 39.6563],
            [-94.0933, 39.6726],
            [-93.9926, 39.6727],
            [-93.9831, 39.6195],
            [-93.9832, 39.5975],
            [-94.0672, 39.6199],
            [-94.1173, 39.6563],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916620',
        NAME: 'Kingston 42 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59408759,
        AWATER: 423715,
        INTPTLAT: '+39.6406822',
        INTPTLON: '-094.0419171',
        ELSDLEA: '16620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1347, 36.6513],
            [-93.0144, 36.4981],
            [-93.1512, 36.498],
            [-93.1366, 36.5429],
            [-93.2115, 36.6403],
            [-93.1347, 36.6513],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916710',
        NAME: 'Kirbyville R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131476281,
        AWATER: 6428998,
        INTPTLAT: '+36.5681198',
        INTPTLON: '-093.1179202',
        ELSDLEA: '16710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8037, 37.7642],
            [-92.6924, 37.7619],
            [-92.7185, 37.6126],
            [-92.8268, 37.6603],
            [-92.8037, 37.7642],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2917000',
        NAME: 'Laclede County C-5 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203855328,
        AWATER: 545676,
        INTPTLAT: '+37.7061186',
        INTPTLON: '-092.7568376',
        ELSDLEA: '17000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4948, 40.0761],
            [-93.362, 40.0331],
            [-93.362, 39.9676],
            [-93.484, 39.9654],
            [-93.4948, 40.0761],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2917910',
        NAME: 'Laredo R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126503702,
        AWATER: 903968,
        INTPTLAT: '+40.0042073',
        INTPTLON: '-093.4245966',
        ELSDLEA: '17910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6261, 38.3773],
            [-93.5892, 38.3762],
            [-93.5157, 38.374],
            [-93.5183, 38.3218],
            [-93.5956, 38.2423],
            [-93.6309, 38.2694],
            [-93.6261, 38.3773],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918330',
        NAME: 'Leesville R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108845831,
        AWATER: 11868845,
        INTPTLAT: '+38.3256962',
        INTPTLON: '-093.5945111',
        ELSDLEA: '18330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8719, 38.3667],
            [-90.7556, 38.3301],
            [-90.7735, 38.2407],
            [-90.934, 38.2052],
            [-90.8719, 38.3667],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919200',
        NAME: 'Lonedell R-XIV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170537529,
        AWATER: 302834,
        INTPTLAT: '+38.2727822',
        INTPTLON: '-090.8336605',
        ELSDLEA: '19200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9431, 40.6058],
            [-91.9073, 40.607],
            [-91.8329, 40.5613],
            [-91.8343, 40.4298],
            [-91.9102, 40.4601],
            [-91.9431, 40.6058],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919320',
        NAME: 'Luray 33 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143322415,
        AWATER: 814246,
        INTPTLAT: '+40.5171265',
        INTPTLON: '-091.8877153',
        ELSDLEA: '19320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4144, 37.4312],
            [-92.3068, 37.4296],
            [-92.2523, 37.3572],
            [-92.2974, 37.3143],
            [-92.417, 37.3084],
            [-92.4144, 37.4312],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919950',
        NAME: 'Manes R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161511632,
        AWATER: 1117440,
        INTPTLAT: '+37.3624056',
        INTPTLON: '-092.3312067',
        ELSDLEA: '19950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9236, 36.668],
            [-92.7677, 36.6835],
            [-92.7692, 36.6247],
            [-92.7723, 36.4977],
            [-92.7849, 36.4977],
            [-92.854, 36.4978],
            [-92.9294, 36.4979],
            [-92.9681, 36.6057],
            [-92.9236, 36.668],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920340',
        NAME: 'Mark Twain R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249645221,
        AWATER: 3437729,
        INTPTLAT: '+36.5843625',
        INTPTLON: '-092.8503299',
        ELSDLEA: '20340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.302, 39.3098],
            [-93.2527, 39.3388],
            [-93.1643, 39.405],
            [-93.0744, 39.338],
            [-93.1238, 39.2408],
            [-93.1508, 39.1735],
            [-93.2207, 39.1722],
            [-93.302, 39.3098],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920840',
        NAME: 'Miami R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 295113137,
        AWATER: 6808365,
        INTPTLAT: '+39.2889966',
        INTPTLON: '-093.1881466',
        ELSDLEA: '20840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3093, 39.3632],
            [-92.3059, 39.4586],
            [-92.1922, 39.3815],
            [-92.2456, 39.3457],
            [-92.3105, 39.3341],
            [-92.3093, 39.3632],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920880',
        NAME: 'Middle Grove C-1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88976199,
        AWATER: 125599,
        INTPTLAT: '+39.3931645',
        INTPTLON: '-092.2671292',
        ELSDLEA: '20880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2072, 39.5923],
            [-94.1173, 39.6563],
            [-94.0672, 39.6199],
            [-94.1566, 39.5698],
            [-94.2072, 39.5923],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921030',
        NAME: 'Mirabile C-1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82031991,
        AWATER: 695208,
        INTPTLAT: '+39.6119523',
        INTPTLON: '-094.1542544',
        ELSDLEA: '21030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2912, 39.274],
            [-94.2783, 39.2362],
            [-94.3318, 39.1871],
            [-94.2912, 39.274],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921060',
        NAME: 'Missouri City 56 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23970439,
        AWATER: 1213291,
        INTPTLAT: '+39.2370332',
        INTPTLON: '-094.3123453',
        ELSDLEA: '21060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6647, 38.4758],
            [-92.6214, 38.5483],
            [-92.515, 38.5454],
            [-92.5301, 38.4577],
            [-92.6649, 38.4613],
            [-92.6647, 38.4758],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921150',
        NAME: 'High Point R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120882887,
        AWATER: 465589,
        INTPTLAT: '+38.4916919',
        INTPTLON: '-092.5841915',
        ELSDLEA: '21150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7295, 38.6096],
            [-92.6353, 38.6303],
            [-92.6214, 38.5483],
            [-92.6647, 38.4758],
            [-92.7336, 38.5142],
            [-92.7295, 38.6096],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921180',
        NAME: 'Moniteau County R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120647122,
        AWATER: 418063,
        INTPTLAT: '+38.5466940',
        INTPTLON: '-092.6770735',
        ELSDLEA: '21180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9926, 39.6727],
            [-93.8702, 39.6995],
            [-93.8609, 39.6705],
            [-93.8707, 39.6127],
            [-93.9831, 39.6195],
            [-93.9926, 39.6727],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922110',
        NAME: 'New York R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101847033,
        AWATER: 530116,
        INTPTLAT: '+39.6683859',
        INTPTLON: '-093.9237582',
        ELSDLEA: '22110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5554, 37.421],
            [-91.5365, 37.5812],
            [-91.5729, 37.6259],
            [-91.5542, 37.6253],
            [-91.5177, 37.5951],
            [-91.3129, 37.5781],
            [-91.3142, 37.5051],
            [-91.3708, 37.4182],
            [-91.4823, 37.4049],
            [-91.5375, 37.3589],
            [-91.5554, 37.421],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923040',
        NAME: 'Oak Hill R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366771605,
        AWATER: 220528,
        INTPTLAT: '+37.5020735',
        INTPTLON: '-091.4429734',
        ELSDLEA: '23040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7677, 36.6835],
            [-92.7468, 36.8057],
            [-92.6029, 36.7614],
            [-92.621, 36.6226],
            [-92.7692, 36.6247],
            [-92.7677, 36.6835],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923370',
        NAME: 'Thornfield R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275846981,
        AWATER: 1773087,
        INTPTLAT: '+36.7047764',
        INTPTLON: '-092.6765618',
        ELSDLEA: '23370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7631, 36.1903],
            [-89.7, 36.1888],
            [-89.6139, 36.1767],
            [-89.5931, 36.1297],
            [-89.6854, 36.0459],
            [-89.7257, 36.0885],
            [-89.771, 36.1468],
            [-89.7631, 36.1903],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923790',
        NAME: 'Pemiscot County R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125127800,
        AWATER: 13730310,
        INTPTLAT: '+36.1353499',
        INTPTLON: '-089.6901391',
        ELSDLEA: '23790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3047, 38.7954],
            [-93.279, 38.7651],
            [-93.3294, 38.6947],
            [-93.3552, 38.7318],
            [-93.3047, 38.7954],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2924690',
        NAME: 'Pettis County R-XII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61632937,
        AWATER: 609325,
        INTPTLAT: '+38.7394247',
        INTPTLON: '-093.3096550',
        ELSDLEA: '24690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0251, 37.7693],
            [-91.8609, 37.8062],
            [-91.8055, 37.7913],
            [-91.8091, 37.5989],
            [-92.0293, 37.6025],
            [-92.0262, 37.7283],
            [-92.0251, 37.7693],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925080',
        NAME: 'Phelps County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366871343,
        AWATER: 373875,
        INTPTLAT: '+37.7050608',
        INTPTLON: '-091.9094207',
        ELSDLEA: '25080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8913, 37.3827],
            [-91.755, 37.4241],
            [-91.6466, 37.4227],
            [-91.7555, 37.3496],
            [-91.8194, 37.251],
            [-91.8675, 37.2554],
            [-91.8913, 37.3827],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926040',
        NAME: 'Raymondville R-7 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225051105,
        AWATER: 4300,
        INTPTLAT: '+37.3642297',
        INTPTLON: '-091.7873728',
        ELSDLEA: '26040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4826, 39.3583],
            [-92.4253, 39.3875],
            [-92.3093, 39.3632],
            [-92.3105, 39.3341],
            [-92.3725, 39.2477],
            [-92.4302, 39.2488],
            [-92.4486, 39.2536],
            [-92.4826, 39.3583],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926190',
        NAME: 'Renick R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121376770,
        AWATER: 27132,
        INTPTLAT: '+39.3220507',
        INTPTLON: '-092.4021508',
        ELSDLEA: '26190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0947, 36.8375],
            [-91.8455, 36.8475],
            [-91.8324, 36.8184],
            [-91.887, 36.7429],
            [-92.113, 36.7772],
            [-92.0947, 36.8375],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926370',
        NAME: 'Richards R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208436744,
        AWATER: 237504,
        INTPTLAT: '+36.8027494',
        INTPTLON: '-091.9639265',
        ELSDLEA: '26370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1232, 36.6289],
            [-91.1229, 36.6426],
            [-91.0327, 36.6128],
            [-90.9038, 36.6165],
            [-90.9059, 36.5462],
            [-90.9807, 36.4984],
            [-91.1265, 36.4977],
            [-91.1232, 36.6289],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926550',
        NAME: 'Ripley County R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236937393,
        AWATER: 160984,
        INTPTLAT: '+36.5685512',
        INTPTLON: '-091.0239005',
        ELSDLEA: '26550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1159, 36.8237],
            [-90.9467, 36.8176],
            [-90.8476, 36.6511],
            [-90.9038, 36.6165],
            [-91.0327, 36.6128],
            [-91.1229, 36.6426],
            [-91.1159, 36.8237],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926580',
        NAME: 'Ripley County R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430770554,
        AWATER: 1304979,
        INTPTLAT: '+36.7112163',
        INTPTLON: '-091.0016342',
        ELSDLEA: '26580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8373, 38.0679],
            [-93.8011, 38.0556],
            [-93.7501, 37.959],
            [-93.8086, 37.8928],
            [-93.9002, 37.8953],
            [-93.8946, 38.003],
            [-93.8373, 38.0679],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926940',
        NAME: 'Roscoe C-1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179892301,
        AWATER: 5073342,
        INTPTLAT: '+37.9764851',
        INTPTLON: '-093.8259412',
        ELSDLEA: '26940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0994, 39.1219],
            [-92.9054, 39.162],
            [-92.9214, 39.0376],
            [-92.9152, 39.0228],
            [-93.0459, 39.0385],
            [-93.0994, 39.1219],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927330',
        NAME: 'Hardeman R-X School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153181153,
        AWATER: 4446492,
        INTPTLAT: '+39.0950310',
        INTPTLON: '-092.9912864',
        ELSDLEA: '27330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1238, 39.2408],
            [-93.0386, 39.2082],
            [-92.9498, 39.205],
            [-92.9054, 39.162],
            [-93.0994, 39.1219],
            [-93.1508, 39.1735],
            [-93.1238, 39.2408],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927450',
        NAME: 'Orearville R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154663935,
        AWATER: 918008,
        INTPTLAT: '+39.1800945',
        INTPTLON: '-093.0308914',
        ELSDLEA: '27450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8501, 38.5485],
            [-93.7885, 38.562],
            [-93.677, 38.5602],
            [-93.7006, 38.4889],
            [-93.7348, 38.4593],
            [-93.8649, 38.4615],
            [-93.8501, 38.5485],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928080',
        NAME: 'Shawnee R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153161297,
        AWATER: 1025954,
        INTPTLAT: '+38.4988492',
        INTPTLON: '-093.7779504',
        ELSDLEA: '28080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6395, 36.6903],
            [-93.4689, 36.6122],
            [-93.5826, 36.5629],
            [-93.6893, 36.5797],
            [-93.6395, 36.6903],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928200',
        NAME: 'Shell Knob 78 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128270261,
        AWATER: 23222890,
        INTPTLAT: '+36.6263447',
        INTPTLON: '-093.6008805',
        ELSDLEA: '28200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6941, 40.2638],
            [-93.4565, 40.2654],
            [-93.4992, 40.2068],
            [-93.6236, 40.206],
            [-93.6941, 40.2638],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928770',
        NAME: 'Spickard R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116231386,
        AWATER: 52972,
        INTPTLAT: '+40.2427883',
        INTPTLON: '-093.5655276',
        ELSDLEA: '28770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1661, 38.8195],
            [-94.1307, 38.8038],
            [-94.1457, 38.7004],
            [-94.1484, 38.7005],
            [-94.1916, 38.7089],
            [-94.2493, 38.791],
            [-94.1661, 38.8195],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929670',
        NAME: 'Strasburg C-3 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81804382,
        AWATER: 1276728,
        INTPTLAT: '+38.7663473',
        INTPTLON: '-094.1843524',
        ELSDLEA: '29670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0224, 37.5298],
            [-91.9443, 37.433],
            [-92.0398, 37.3846],
            [-92.1978, 37.3859],
            [-92.0224, 37.5298],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929730',
        NAME: 'Success R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244769035,
        AWATER: 550610,
        INTPTLAT: '+37.4407899',
        INTPTLON: '-092.0576908',
        ELSDLEA: '29730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.472, 38.0424],
            [-90.6171, 38.0068],
            [-90.612, 38.0696],
            [-90.472, 38.0424],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929820',
        NAME: 'Sunrise R-IX School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103751859,
        AWATER: 804268,
        INTPTLAT: '+38.0569632',
        INTPTLON: '-090.5497397',
        ELSDLEA: '29820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4069, 37.977],
            [-92.2853, 37.9012],
            [-92.3074, 37.859],
            [-92.3267, 37.8647],
            [-92.4069, 37.977],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929850',
        NAME: 'Swedeborg R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81614996,
        AWATER: 355255,
        INTPTLAT: '+37.9208720',
        INTPTLON: '-092.3581306',
        ELSDLEA: '29850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1411, 36.8138],
            [-93.0444, 36.8121],
            [-92.966, 36.7147],
            [-93.0856, 36.7126],
            [-93.1413, 36.7976],
            [-93.1411, 36.8138],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929910',
        NAME: 'Taneyville R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128826322,
        AWATER: 116124,
        INTPTLAT: '+36.7460069',
        INTPTLON: '-093.0459757',
        ELSDLEA: '29910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9726, 38.1501],
            [-90.9522, 38.2054],
            [-90.934, 38.2052],
            [-90.7735, 38.2407],
            [-90.7164, 38.139],
            [-90.7155, 38.138],
            [-90.7133, 38.1358],
            [-90.7731, 38.0854],
            [-90.9726, 38.1501],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931230',
        NAME: 'Richwoods R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200595497,
        AWATER: 976925,
        INTPTLAT: '+38.1585757',
        INTPTLON: '-090.8461526',
        ELSDLEA: '31230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4996, 36.8811],
            [-94.504, 36.7921],
            [-94.5482, 36.872],
            [-94.4996, 36.8811],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931890',
        NAME: 'Westview C-6 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48510824,
        AWATER: 0,
        INTPTLAT: '+36.8404521',
        INTPTLON: '-094.5164584',
        ELSDLEA: '31890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6141, 37.9875],
            [-94.4989, 38.0575],
            [-94.2828, 38.0087],
            [-94.3335, 37.9627],
            [-94.2821, 37.8198],
            [-94.1739, 37.8458],
            [-94.1798, 37.7078],
            [-94.345, 37.7062],
            [-94.4573, 37.8105],
            [-94.6177, 37.7555],
            [-94.6141, 37.9875],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921840',
        NAME: 'Nevada R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 920544721,
        AWATER: 10071745,
        INTPTLAT: '+37.8795413',
        INTPTLON: '-094.4306160',
        ELSDLEA: '',
        UNSDLEA: '21840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6141, 38.0371],
            [-94.6138, 38.1244],
            [-94.6126, 38.2122],
            [-94.4927, 38.202],
            [-94.4805, 38.1944],
            [-94.5308, 38.174],
            [-94.4989, 38.0575],
            [-94.6141, 37.9875],
            [-94.6141, 38.0371],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915330',
        NAME: 'Hume R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207185432,
        AWATER: 5065335,
        INTPTLAT: '+38.1059540',
        INTPTLON: '-094.5645480',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2828, 38.0087],
            [-94.104, 38.0476],
            [-94.0608, 37.9878],
            [-94.0639, 37.9297],
            [-94.1739, 37.8458],
            [-94.2821, 37.8198],
            [-94.3335, 37.9627],
            [-94.2828, 38.0087],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927600',
        NAME: 'Northeast Vernon County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422323828,
        AWATER: 9810134,
        INTPTLAT: '+37.9523502',
        INTPTLON: '-094.2002372',
        ELSDLEA: '',
        UNSDLEA: '27600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5063, 38.7434],
            [-90.4921, 38.7518],
            [-90.4804, 38.7697],
            [-90.3949, 38.7689],
            [-90.3827, 38.7506],
            [-90.3947, 38.6861],
            [-90.4245, 38.6791],
            [-90.5063, 38.7434],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923700',
        NAME: 'Pattonville R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64354723,
        AWATER: 862271,
        INTPTLAT: '+38.7370338',
        INTPTLON: '-090.4378601',
        ELSDLEA: '',
        UNSDLEA: '23700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3827, 38.7506],
            [-90.3352, 38.7213],
            [-90.3392, 38.6847],
            [-90.36, 38.6774],
            [-90.3947, 38.6861],
            [-90.3827, 38.7506],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926640',
        NAME: 'Ritenour School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27281758,
        AWATER: 44971,
        INTPTLAT: '+38.7093362',
        INTPTLON: '-090.3683712',
        ELSDLEA: '',
        UNSDLEA: '26640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.28, 38.7649],
            [-90.1856, 38.7633],
            [-90.2397, 38.7236],
            [-90.2793, 38.735],
            [-90.28, 38.7649],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926670',
        NAME: 'Riverview Gardens School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24434163,
        AWATER: 0,
        INTPTLAT: '+38.7495228',
        INTPTLON: '-090.2379142',
        ELSDLEA: '',
        UNSDLEA: '26670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.36, 38.6774],
            [-90.3392, 38.6847],
            [-90.2952, 38.664],
            [-90.3028, 38.6496],
            [-90.3538, 38.6567],
            [-90.36, 38.6774],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930660',
        NAME: 'University City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16082549,
        AWATER: 0,
        INTPTLAT: '+38.6665191',
        INTPTLON: '-090.3320897',
        ELSDLEA: '',
        UNSDLEA: '30660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4253, 40.3872],
            [-95.2358, 40.3642],
            [-95.1776, 40.3922],
            [-95.1787, 40.3342],
            [-95.209, 40.2623],
            [-95.3137, 40.2627],
            [-95.4652, 40.2639],
            [-95.4827, 40.3582],
            [-95.4253, 40.3872],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911760',
        NAME: 'Fairfax R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329509601,
        AWATER: 109795,
        INTPTLAT: '+40.3285033',
        INTPTLON: '-095.3221044',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3352, 38.7213],
            [-90.2792, 38.7216],
            [-90.265, 38.7022],
            [-90.2952, 38.664],
            [-90.3392, 38.6847],
            [-90.3352, 38.7213],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922650',
        NAME: 'Normandy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27307525,
        AWATER: 0,
        INTPTLAT: '+38.6970592',
        INTPTLON: '-090.3056945',
        ELSDLEA: '',
        UNSDLEA: '22650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3965, 38.6112],
            [-90.3624, 38.6193],
            [-90.3353, 38.6097],
            [-90.3182, 38.6],
            [-90.3174, 38.5874],
            [-90.3593, 38.5648],
            [-90.3772, 38.5735],
            [-90.3965, 38.6112],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931530',
        NAME: 'Webster Groves School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21337150,
        AWATER: 0,
        INTPTLAT: '+38.5914052',
        INTPTLON: '-090.3565333',
        ELSDLEA: '',
        UNSDLEA: '31530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4289, 39.7914],
            [-94.4323, 39.7037],
            [-94.5081, 39.6677],
            [-94.5536, 39.7472],
            [-94.5374, 39.8131],
            [-94.4289, 39.7914],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929490',
        NAME: 'Stewartsville C-2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135469599,
        AWATER: 695599,
        INTPTLAT: '+39.7541723',
        INTPTLON: '-094.4699260',
        ELSDLEA: '',
        UNSDLEA: '29490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9038, 36.6165],
            [-90.8476, 36.6511],
            [-90.9467, 36.8176],
            [-90.9338, 36.8147],
            [-90.6275, 36.811],
            [-90.6276, 36.7814],
            [-90.6287, 36.6649],
            [-90.5742, 36.65],
            [-90.6651, 36.651],
            [-90.7262, 36.4986],
            [-90.7844, 36.4985],
            [-90.9807, 36.4984],
            [-90.9059, 36.5462],
            [-90.9038, 36.6165],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910920',
        NAME: 'Doniphan R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 767207249,
        AWATER: 3650149,
        INTPTLAT: '+36.6677512',
        INTPTLON: '-090.7768224',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6276, 36.7814],
            [-90.5736, 36.7848],
            [-90.4018, 36.6335],
            [-90.3551, 36.5216],
            [-90.4315, 36.4984],
            [-90.5762, 36.4985],
            [-90.5742, 36.65],
            [-90.6287, 36.6649],
            [-90.6276, 36.7814],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921750',
        NAME: 'Neelyville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430184603,
        AWATER: 1411005,
        INTPTLAT: '+36.6114378',
        INTPTLON: '-090.4966452',
        ELSDLEA: '',
        UNSDLEA: '21750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5548, 40.2645],
            [-95.4652, 40.2639],
            [-95.3137, 40.2627],
            [-95.3145, 40.1484],
            [-95.3533, 40.0945],
            [-95.2397, 40.0592],
            [-95.3082, 39.999],
            [-95.3083, 40],
            [-95.42, 40.0504],
            [-95.3965, 40.1247],
            [-95.5548, 40.2645],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910260',
        NAME: 'Craig R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301746370,
        AWATER: 12322068,
        INTPTLAT: '+40.1539503',
        INTPTLON: '-095.3811199',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3082, 39.999],
            [-95.2397, 40.0592],
            [-95.1074, 40.1015],
            [-95.0499, 40.0752],
            [-95.0046, 39.9821],
            [-94.9934, 39.8979],
            [-95.0219, 39.894],
            [-95.134, 39.8765],
            [-95.3082, 39.999],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923190',
        NAME: 'South Holt County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434760763,
        AWATER: 5042674,
        INTPTLAT: '+39.9837508',
        INTPTLON: '-095.1166144',
        ELSDLEA: '',
        UNSDLEA: '23190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.881, 37.2917],
            [-89.8717, 37.2798],
            [-89.8736, 37.2756],
            [-89.8667, 37.1844],
            [-89.9417, 37.1768],
            [-89.9759, 37.2344],
            [-89.881, 37.2917],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918420',
        NAME: 'Leopold R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101430063,
        AWATER: 171338,
        INTPTLAT: '+37.2175251',
        INTPTLON: '-089.9132832',
        ELSDLEA: '',
        UNSDLEA: '18420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7622, 40.2716],
            [-94.6615, 40.1769],
            [-94.6527, 40.1263],
            [-94.728, 40.1268],
            [-94.8588, 40.128],
            [-94.905, 40.2263],
            [-94.7622, 40.2716],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928500',
        NAME: 'South Nodaway County R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230395693,
        AWATER: 140468,
        INTPTLAT: '+40.1921654',
        INTPTLON: '-094.7859597',
        ELSDLEA: '',
        UNSDLEA: '28500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3074, 37.859],
            [-92.2853, 37.9012],
            [-92.1576, 37.8664],
            [-92.0414, 37.8811],
            [-92.0246, 37.7937],
            [-92.0251, 37.7728],
            [-92.0251, 37.7693],
            [-92.0262, 37.7283],
            [-92.177, 37.6052],
            [-92.2489, 37.6485],
            [-92.242, 37.7987],
            [-92.3074, 37.859],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931440',
        NAME: 'Waynesville R-VI Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 485535731,
        AWATER: 4906326,
        INTPTLAT: '+37.7655497',
        INTPTLON: '-092.1553666',
        ELSDLEA: '',
        UNSDLEA: '31440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.713, 36.6951],
            [-91.6766, 36.6939],
            [-91.5117, 36.6472],
            [-91.6178, 36.5643],
            [-91.6177, 36.4993],
            [-91.7085, 36.4994],
            [-91.7634, 36.5768],
            [-91.713, 36.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916860',
        NAME: 'Oregon-Howell R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285809709,
        AWATER: 75431,
        INTPTLAT: '+36.6089175',
        INTPTLON: '-091.6632896',
        ELSDLEA: '',
        UNSDLEA: '16860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7436, 37.0198],
            [-89.6179, 37.0679],
            [-89.5583, 37.0607],
            [-89.575, 36.9356],
            [-89.6917, 36.9389],
            [-89.7436, 37.0198],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921420',
        NAME: 'Scott County Central Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162109064,
        AWATER: 93161,
        INTPTLAT: '+37.0024283',
        INTPTLON: '-089.6418078',
        ELSDLEA: '',
        UNSDLEA: '21420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9378, 38.2252],
            [-92.843, 38.1926],
            [-92.7645, 38.1896],
            [-92.6409, 38.1857],
            [-92.516, 38.0223],
            [-92.5175, 37.979],
            [-92.6324, 37.8925],
            [-92.6919, 37.8855],
            [-92.856, 37.8805],
            [-92.8892, 37.9057],
            [-92.8474, 38.0666],
            [-92.8951, 38.0723],
            [-92.8815, 38.1645],
            [-92.9378, 38.2252],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906990',
        NAME: 'Camdenton R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 772336069,
        AWATER: 93611128,
        INTPTLAT: '+38.0423961',
        INTPTLON: '-092.7317529',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2383, 37.3749],
            [-93.1816, 37.4536],
            [-93.0716, 37.4874],
            [-93.075, 37.3569],
            [-93.186, 37.3094],
            [-93.2383, 37.3749],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911700',
        NAME: 'Fair Grove R-X School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212549921,
        AWATER: 1619917,
        INTPTLAT: '+37.3899518',
        INTPTLON: '-093.1536099',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0716, 37.4874],
            [-92.9708, 37.4857],
            [-92.9083, 37.4704],
            [-92.8919, 37.3972],
            [-92.6913, 37.3072],
            [-92.6923, 37.2635],
            [-92.8653, 37.2081],
            [-92.9905, 37.209],
            [-93.032, 37.2346],
            [-93.075, 37.3569],
            [-93.0716, 37.4874],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920430',
        NAME: 'Marshfield R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638546093,
        AWATER: 1479449,
        INTPTLAT: '+37.3407321',
        INTPTLON: '-092.9222587',
        ELSDLEA: '',
        UNSDLEA: '20430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3491, 38.6305],
            [-90.3349, 38.631],
            [-90.3353, 38.6097],
            [-90.3624, 38.6193],
            [-90.3491, 38.6305],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905880',
        NAME: 'Brentwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5390195,
        AWATER: 0,
        INTPTLAT: '+38.6200526',
        INTPTLON: '-090.3476941',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8583, 37.8123],
            [-92.856, 37.8805],
            [-92.6919, 37.8855],
            [-92.6333, 37.8491],
            [-92.6354, 37.7614],
            [-92.4654, 37.715],
            [-92.5213, 37.578],
            [-92.468, 37.5665],
            [-92.4682, 37.4757],
            [-92.5872, 37.4791],
            [-92.7185, 37.6126],
            [-92.6924, 37.7619],
            [-92.8037, 37.7642],
            [-92.8583, 37.8123],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918270',
        NAME: 'Lebanon R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 730984493,
        AWATER: 3156019,
        INTPTLAT: '+37.6823948',
        INTPTLON: '-092.6324446',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.4112, 37.7968],
              [-92.3942, 37.7135],
              [-92.4627, 37.7149],
              [-92.4112, 37.7968],
            ],
          ],
          [
            [
              [-92.516, 38.0223],
              [-92.4613, 38.0219],
              [-92.4063, 38.0212],
              [-92.4069, 37.977],
              [-92.3267, 37.8647],
              [-92.4112, 37.797],
              [-92.4647, 37.8462],
              [-92.4622, 37.9783],
              [-92.5175, 37.979],
              [-92.516, 38.0223],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926430',
        NAME: 'Richland R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241172750,
        AWATER: 1755686,
        INTPTLAT: '+37.8894536',
        INTPTLON: '-092.4241800',
        ELSDLEA: '',
        UNSDLEA: '26430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.1751, 36.8039],
              [-89.1235, 36.7852],
              [-89.1749, 36.7578],
              [-89.1751, 36.8039],
            ],
          ],
          [
            [
              [-89.4836, 36.9064],
              [-89.3938, 36.9355],
              [-89.3127, 37.009],
              [-89.2599, 37.0641],
              [-89.1329, 36.9821],
              [-89.1009, 36.944],
              [-89.1763, 36.8056],
              [-89.3469, 36.8048],
              [-89.3559, 36.8506],
              [-89.5021, 36.8476],
              [-89.5198, 36.8696],
              [-89.4836, 36.9064],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908670',
        NAME: 'Charleston R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585284274,
        AWATER: 27448725,
        INTPTLAT: '+36.9041621',
        INTPTLON: '-089.2747660',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7617, 37.0429],
            [-93.7001, 37.0927],
            [-93.6576, 36.924],
            [-93.6458, 36.8214],
            [-93.6386, 36.7811],
            [-93.7708, 36.8166],
            [-93.771, 36.8452],
            [-93.7617, 37.0429],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904020',
        NAME: 'Aurora R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248266961,
        AWATER: 683101,
        INTPTLAT: '+36.9329262',
        INTPTLON: '-093.7096196',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6576, 36.924],
            [-93.5658, 36.9513],
            [-93.5041, 36.8664],
            [-93.6458, 36.8214],
            [-93.6576, 36.924],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910290',
        NAME: 'Crane R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174382356,
        AWATER: 96791,
        INTPTLAT: '+36.8756359',
        INTPTLON: '-093.6006685',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0656, 36.8593],
            [-94.0023, 36.8285],
            [-93.9739, 36.7448],
            [-94.07, 36.69],
            [-94.1296, 36.7501],
            [-94.0656, 36.8593],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931950',
        NAME: 'Wheaton R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155084759,
        AWATER: 487203,
        INTPTLAT: '+36.7699127',
        INTPTLON: '-094.0356358',
        ELSDLEA: '',
        UNSDLEA: '31950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.959, 36.5126],
            [-89.8088, 36.4957],
            [-89.8087, 36.3865],
            [-89.9613, 36.3888],
            [-89.959, 36.5126],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912780',
        NAME: 'Gideon 37 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194685286,
        AWATER: 1712117,
        INTPTLAT: '+36.4364359',
        INTPTLON: '-089.8878153',
        ELSDLEA: '',
        UNSDLEA: '12780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9588, 36.6293],
            [-89.8466, 36.6277],
            [-89.8271, 36.7149],
            [-89.7566, 36.7149],
            [-89.7023, 36.7865],
            [-89.638, 36.8608],
            [-89.5198, 36.8696],
            [-89.5021, 36.8476],
            [-89.4928, 36.8037],
            [-89.3737, 36.7029],
            [-89.3276, 36.6323],
            [-89.4173, 36.499],
            [-89.4854, 36.4975],
            [-89.4799, 36.5691],
            [-89.5707, 36.5577],
            [-89.5392, 36.4979],
            [-89.5196, 36.4687],
            [-89.7016, 36.5047],
            [-89.7458, 36.5839],
            [-89.9627, 36.6003],
            [-89.9588, 36.6293],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2900004',
        NAME: 'New Madrid County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1176370032,
        AWATER: 38501706,
        INTPTLAT: '+36.6640651',
        INTPTLON: '-089.5925077',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4543, 38.5653],
            [-90.4545, 38.5551],
            [-90.5108, 38.5461],
            [-90.4543, 38.5653],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930690',
        NAME: 'Valley Park School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10072919,
        AWATER: 476436,
        INTPTLAT: '+38.5582927',
        INTPTLON: '-090.4834619',
        ELSDLEA: '',
        UNSDLEA: '30690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2885, 37.9872],
            [-93.2453, 37.9526],
            [-93.2694, 37.819],
            [-93.3538, 37.8066],
            [-93.2885, 37.9872],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914310',
        NAME: 'Hermitage R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142600730,
        AWATER: 18032900,
        INTPTLAT: '+37.9006559',
        INTPTLON: '-093.3052519',
        ELSDLEA: '',
        UNSDLEA: '14310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8932, 37.0522],
            [-90.8901, 37.0521],
            [-90.7796, 37.0503],
            [-90.7134, 36.9711],
            [-90.6299, 36.993],
            [-90.5695, 36.9271],
            [-90.5736, 36.7848],
            [-90.6276, 36.7814],
            [-90.6275, 36.811],
            [-90.9338, 36.8147],
            [-90.9011, 36.9152],
            [-90.9733, 36.9467],
            [-90.8932, 37.0522],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911100',
        NAME: 'East Carter County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726978507,
        AWATER: 2763096,
        INTPTLAT: '+36.9122402',
        INTPTLON: '-090.7740378',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5742, 36.65],
            [-90.5762, 36.4985],
            [-90.7262, 36.4986],
            [-90.6651, 36.651],
            [-90.5742, 36.65],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921720',
        NAME: 'Naylor R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199860085,
        AWATER: 479154,
        INTPTLAT: '+36.5688890',
        INTPTLON: '-090.6421453',
        ELSDLEA: '',
        UNSDLEA: '21720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4018, 36.6335],
            [-90.2985, 36.7933],
            [-90.3533, 36.8906],
            [-90.2886, 36.9239],
            [-90.1979, 36.8041],
            [-90.135, 36.8124],
            [-90.1484, 36.7106],
            [-90.1484, 36.6307],
            [-90.2207, 36.4979],
            [-90.3708, 36.4983],
            [-90.4315, 36.4984],
            [-90.3551, 36.5216],
            [-90.4018, 36.6335],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930520',
        NAME: 'Twin Rivers R-X School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 697278252,
        AWATER: 5745462,
        INTPTLAT: '+36.6884725',
        INTPTLON: '-090.2667211',
        ELSDLEA: '',
        UNSDLEA: '30520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.186, 37.3094],
            [-93.075, 37.3569],
            [-93.032, 37.2346],
            [-93.1633, 37.1836],
            [-93.186, 37.3094],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929640',
        NAME: 'Strafford R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194532101,
        AWATER: 353842,
        INTPTLAT: '+37.2678702',
        INTPTLON: '-093.1158191',
        ELSDLEA: '',
        UNSDLEA: '29640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5002, 37.3478],
            [-93.4557, 37.4242],
            [-93.3327, 37.3648],
            [-93.2934, 37.3615],
            [-93.3493, 37.1976],
            [-93.4505, 37.1989],
            [-93.5002, 37.3478],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932010',
        NAME: 'Willard R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290834987,
        AWATER: 798470,
        INTPTLAT: '+37.3007081',
        INTPTLON: '-093.3910728',
        ELSDLEA: '',
        UNSDLEA: '32010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7007, 36.2473],
            [-89.7004, 36.2318],
            [-89.7, 36.1888],
            [-89.7631, 36.1903],
            [-89.8078, 36.2339],
            [-89.7007, 36.2473],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913800',
        NAME: 'Hayti R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91469222,
        AWATER: 1051250,
        INTPTLAT: '+36.2456238',
        INTPTLON: '-089.7597083',
        ELSDLEA: '',
        UNSDLEA: '13800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.616, 39.6525],
            [-92.6159, 39.6525],
            [-92.5533, 39.6955],
            [-92.5179, 39.7891],
            [-92.431, 39.8607],
            [-92.2915, 39.822],
            [-92.2993, 39.6053],
            [-92.3009, 39.6053],
            [-92.5154, 39.6079],
            [-92.6175, 39.6092],
            [-92.616, 39.6525],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919410',
        NAME: 'Macon County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531708087,
        AWATER: 8225819,
        INTPTLAT: '+39.7178133',
        INTPTLON: '-092.4196247',
        ELSDLEA: '',
        UNSDLEA: '19410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5154, 39.6079],
            [-92.3009, 39.6053],
            [-92.3048, 39.4984],
            [-92.3755, 39.4671],
            [-92.4756, 39.4834],
            [-92.5339, 39.4905],
            [-92.5154, 39.6079],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922860',
        NAME: 'Northeast Randolph County R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266074490,
        AWATER: 1423387,
        INTPTLAT: '+39.5443672',
        INTPTLON: '-092.4154837',
        ELSDLEA: '',
        UNSDLEA: '22860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6175, 39.6092],
            [-92.5154, 39.6079],
            [-92.5339, 39.4905],
            [-92.4756, 39.4834],
            [-92.5004, 39.3805],
            [-92.594, 39.3535],
            [-92.7053, 39.3643],
            [-92.6986, 39.5688],
            [-92.6175, 39.6092],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931860',
        NAME: 'Westran R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415696098,
        AWATER: 8184873,
        INTPTLAT: '+39.4754280',
        INTPTLON: '-092.6005410',
        ELSDLEA: '',
        UNSDLEA: '31860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0246, 37.1395],
            [-93.9291, 37.1759],
            [-93.6715, 37.1724],
            [-93.608, 37.142],
            [-93.6086, 37.1127],
            [-93.7001, 37.0927],
            [-93.7617, 37.0429],
            [-93.8704, 37.0226],
            [-93.8974, 37.0263],
            [-94.004, 37.0851],
            [-94.0246, 37.1395],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921600',
        NAME: 'Mount Vernon R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356547961,
        AWATER: 600958,
        INTPTLAT: '+37.1113544',
        INTPTLON: '-093.8255311',
        ELSDLEA: '',
        UNSDLEA: '21600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3508, 40.5973],
            [-92.1798, 40.6005],
            [-91.9431, 40.6058],
            [-91.9102, 40.4601],
            [-91.9456, 40.4318],
            [-92.0828, 40.4262],
            [-92.0341, 40.3131],
            [-92.3478, 40.3028],
            [-92.35, 40.3465],
            [-92.3508, 40.5973],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920700',
        NAME: 'Scotland County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 983498066,
        AWATER: 5981211,
        INTPTLAT: '+40.4641976',
        INTPTLON: '-092.1658400',
        ELSDLEA: '',
        UNSDLEA: '20700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.982, 36.237],
              [-89.9609, 36.3231],
              [-89.88, 36.2484],
              [-89.982, 36.237],
            ],
          ],
          [
            [
              [-90.0511, 36.2658],
              [-89.9823, 36.2366],
              [-89.9911, 36.2224],
              [-90.0511, 36.2658],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916500',
        NAME: 'Kennett 39 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106378444,
        AWATER: 1760401,
        INTPTLAT: '+36.2700028',
        INTPTLON: '-089.9485333',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9613, 36.3888],
            [-89.8087, 36.3865],
            [-89.6113, 36.4113],
            [-89.545, 36.3368],
            [-89.5364, 36.2728],
            [-89.7007, 36.2473],
            [-89.8078, 36.2339],
            [-89.88, 36.2484],
            [-89.9609, 36.3231],
            [-89.9611, 36.3494],
            [-89.9613, 36.3888],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923760',
        NAME: 'North Pemiscot County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456043470,
        AWATER: 25438019,
        INTPTLAT: '+36.3395335',
        INTPTLON: '-089.7427429',
        ELSDLEA: '',
        UNSDLEA: '23760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7004, 36.2318],
            [-89.6295, 36.1854],
            [-89.6139, 36.1767],
            [-89.7, 36.1888],
            [-89.7004, 36.2318],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907470',
        NAME: 'Caruthersville 18 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23431243,
        AWATER: 4926140,
        INTPTLAT: '+36.1885111',
        INTPTLON: '-089.6676785',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7257, 36.0885],
            [-89.6854, 36.0459],
            [-89.7331, 36.0006],
            [-89.7871, 36.0006],
            [-89.8597, 35.9999],
            [-89.8422, 36.0593],
            [-89.7257, 36.0885],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910140',
        NAME: 'Cooter R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98893496,
        AWATER: 6528055,
        INTPTLAT: '+36.0310438',
        INTPTLON: '-089.7796825',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.771, 36.1468],
            [-89.7257, 36.0885],
            [-89.8422, 36.0593],
            [-89.8597, 35.9999],
            [-89.9402, 35.9993],
            [-89.9594, 35.9991],
            [-89.9596, 36.1325],
            [-89.771, 36.1468],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928530',
        NAME: 'South Pemiscot County R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234795712,
        AWATER: 915494,
        INTPTLAT: '+36.0846548',
        INTPTLON: '-089.8787584',
        ELSDLEA: '',
        UNSDLEA: '28530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4788, 40.3866],
            [-94.2143, 40.3843],
            [-94.2165, 40.1657],
            [-94.2743, 40.104],
            [-94.4457, 40.1257],
            [-94.4427, 40.343],
            [-94.4788, 40.3866],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2902970',
        NAME: 'Albany R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 589878052,
        AWATER: 226905,
        INTPTLAT: '+40.2536168',
        INTPTLON: '-094.3373215',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1484, 36.7106],
            [-89.9246, 36.7153],
            [-89.8271, 36.7149],
            [-89.8466, 36.6277],
            [-89.9588, 36.6293],
            [-90.0835, 36.6307],
            [-90.1484, 36.6307],
            [-90.1484, 36.7106],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904950',
        NAME: 'Bernie R-XIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258762919,
        AWATER: 890494,
        INTPTLAT: '+36.6734026',
        INTPTLON: '-089.9879841',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0056, 37.0149],
            [-89.9511, 37.0132],
            [-89.943, 36.9694],
            [-89.8045, 36.936],
            [-89.8068, 36.8774],
            [-89.9207, 36.846],
            [-90.0784, 36.8625],
            [-90.0056, 37.0149],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905250',
        NAME: 'Bloomfield R-XIV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298040959,
        AWATER: 2160220,
        INTPTLAT: '+36.9112877',
        INTPTLON: '-089.9575956',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3845, 39.5292],
            [-93.2829, 39.5328],
            [-93.2295, 39.4417],
            [-93.2751, 39.351],
            [-93.4304, 39.4902],
            [-93.3845, 39.5292],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905610',
        NAME: 'Bosworth R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216686310,
        AWATER: 1221673,
        INTPTLAT: '+39.4499960',
        INTPTLON: '-093.3189703',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3944, 39.6447],
            [-93.3305, 39.7036],
            [-93.3076, 39.6886],
            [-93.2616, 39.6292],
            [-93.2829, 39.5328],
            [-93.3845, 39.5292],
            [-93.3944, 39.6447],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913500',
        NAME: 'Hale R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183063949,
        AWATER: 2693866,
        INTPTLAT: '+39.5999766',
        INTPTLON: '-093.3436123',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7185, 39.3245],
            [-91.6244, 39.3298],
            [-91.6103, 39.2362],
            [-91.5269, 39.191],
            [-91.6319, 39.1026],
            [-91.651, 39.0135],
            [-91.6896, 39.0189],
            [-91.8041, 39.0609],
            [-91.7993, 39.1935],
            [-91.7416, 39.2015],
            [-91.7185, 39.3245],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910020',
        NAME: 'Community R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463886723,
        AWATER: 2290752,
        INTPTLAT: '+39.1710164',
        INTPTLON: '-091.6802038',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1487, 38.8466],
            [-92.0406, 38.8446],
            [-92.0078, 38.7555],
            [-91.9922, 38.6707],
            [-92.2191, 38.6719],
            [-92.1354, 38.8156],
            [-92.1487, 38.8466],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921875',
        NAME: 'New Bloomfield R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268410025,
        AWATER: 2406401,
        INTPTLAT: '+38.7371978',
        INTPTLON: '-092.0949445',
        ELSDLEA: '',
        UNSDLEA: '21875',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1481, 39.0365],
            [-92.0323, 39.1069],
            [-91.9765, 39.062],
            [-91.8041, 39.0609],
            [-91.6896, 39.0189],
            [-91.6391, 38.9393],
            [-91.6423, 38.8437],
            [-91.8286, 38.8102],
            [-91.9051, 38.9078],
            [-92.0967, 38.9208],
            [-92.1453, 38.9705],
            [-92.1481, 39.0365],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922740',
        NAME: 'North Callaway County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 822616215,
        AWATER: 7651035,
        INTPTLAT: '+38.9601571',
        INTPTLON: '-091.8869679',
        ELSDLEA: '',
        UNSDLEA: '22740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5269, 39.191],
            [-91.4082, 39.1864],
            [-91.2602, 39.1398],
            [-91.2667, 39.0826],
            [-91.3636, 39.0529],
            [-91.5207, 39.0567],
            [-91.651, 39.0135],
            [-91.6319, 39.1026],
            [-91.5269, 39.191],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931620',
        NAME: 'Wellsville-Middletown R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388479771,
        AWATER: 3232197,
        INTPTLAT: '+39.1080788',
        INTPTLON: '-091.4543992',
        ELSDLEA: '',
        UNSDLEA: '31620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.856, 40.1248],
            [-92.7044, 40.1249],
            [-92.647, 40.154],
            [-92.6869, 40.3434],
            [-92.6842, 40.3435],
            [-92.4606, 40.3452],
            [-92.4594, 40.1247],
            [-92.4972, 40.1247],
            [-92.6302, 40.0383],
            [-92.7429, 40.038],
            [-92.8475, 40.0373],
            [-92.8562, 40.0373],
            [-92.856, 40.1248],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916740',
        NAME: 'Kirksville R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 658972600,
        AWATER: 4334084,
        INTPTLAT: '+40.1823975',
        INTPTLON: '-092.6170570',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8556, 40.3427],
            [-92.6869, 40.3434],
            [-92.647, 40.154],
            [-92.7044, 40.1249],
            [-92.856, 40.1248],
            [-92.8556, 40.3427],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922980',
        NAME: 'Adair County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391629715,
        AWATER: 1028012,
        INTPTLAT: '+40.2427226',
        INTPTLON: '-092.7668497',
        ELSDLEA: '',
        UNSDLEA: '22980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8459, 39.9195],
            [-91.7583, 39.9477],
            [-91.6176, 39.9224],
            [-91.6764, 39.7731],
            [-91.8441, 39.7964],
            [-91.8459, 39.9195],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920280',
        NAME: 'Marion County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268703095,
        AWATER: 1221884,
        INTPTLAT: '+39.8754036',
        INTPTLON: '-091.7382066',
        ELSDLEA: '',
        UNSDLEA: '20280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7347, 40.0049],
            [-92.6235, 39.9974],
            [-92.5601, 39.9387],
            [-92.4336, 39.9398],
            [-92.2889, 39.9089],
            [-92.2747, 39.8519],
            [-92.2915, 39.822],
            [-92.431, 39.8607],
            [-92.5179, 39.7891],
            [-92.609, 39.8676],
            [-92.6834, 39.873],
            [-92.7347, 40.0049],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903480',
        NAME: 'Atlanta C-3 Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418724222,
        AWATER: 8045495,
        INTPTLAT: '+39.8997878',
        INTPTLON: '-092.5182533',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2889, 39.9089],
            [-92.2879, 39.9525],
            [-91.9527, 39.9494],
            [-91.8459, 39.9195],
            [-91.8441, 39.7964],
            [-91.845, 39.7634],
            [-92.182, 39.7787],
            [-92.1893, 39.8671],
            [-92.2747, 39.8519],
            [-92.2889, 39.9089],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928140',
        NAME: 'North Shelby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 662967239,
        AWATER: 888322,
        INTPTLAT: '+39.8697575',
        INTPTLON: '-092.0525070',
        ELSDLEA: '',
        UNSDLEA: '28140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6424, 38.5052],
            [-91.3685, 38.5094],
            [-91.2557, 38.5049],
            [-91.2188, 38.3747],
            [-91.2928, 38.3306],
            [-91.3654, 38.3315],
            [-91.3759, 38.2243],
            [-91.5351, 38.1964],
            [-91.5622, 38.1541],
            [-91.5895, 38.2258],
            [-91.5924, 38.377],
            [-91.6445, 38.378],
            [-91.6424, 38.5052],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923340',
        NAME: 'Gasconade County R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 918800928,
        AWATER: 5753816,
        INTPTLAT: '+38.3724278',
        INTPTLON: '-091.4496153',
        ELSDLEA: '',
        UNSDLEA: '23340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8627, 38.2929],
            [-91.8174, 38.3948],
            [-91.692, 38.4419],
            [-91.6445, 38.378],
            [-91.5924, 38.377],
            [-91.5895, 38.2258],
            [-91.5622, 38.1541],
            [-91.5664, 38.1527],
            [-91.718, 38.1473],
            [-91.7654, 38.0963],
            [-91.8019, 38.0955],
            [-91.7995, 38.2432],
            [-91.8627, 38.2929],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2900001',
        NAME: 'Maries County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 600057590,
        AWATER: 2499318,
        INTPTLAT: '+38.2721258',
        INTPTLON: '-091.7147540',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8269, 38.5928],
            [-91.7519, 38.5457],
            [-91.6422, 38.5569],
            [-91.6424, 38.5052],
            [-91.6445, 38.378],
            [-91.692, 38.4419],
            [-91.8174, 38.3948],
            [-91.9248, 38.4189],
            [-91.9021, 38.559],
            [-91.8269, 38.5928],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919080',
        NAME: 'Osage County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405593213,
        AWATER: 1998034,
        INTPTLAT: '+38.4820722',
        INTPTLON: '-091.7815466',
        ELSDLEA: '',
        UNSDLEA: '19080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5664, 38.1527],
            [-91.5622, 38.1541],
            [-91.5351, 38.1964],
            [-91.3759, 38.2243],
            [-91.3581, 38.2041],
            [-91.301, 38.0457],
            [-91.3275, 38.0078],
            [-91.5263, 37.9688],
            [-91.525, 38.1524],
            [-91.5664, 38.1527],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910410',
        NAME: 'Crawford County R-2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422857261,
        AWATER: 1891359,
        INTPTLAT: '+38.0945909',
        INTPTLON: '-091.4269904',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7654, 38.0963],
            [-91.718, 38.1473],
            [-91.5664, 38.1527],
            [-91.525, 38.1524],
            [-91.5263, 37.9688],
            [-91.5288, 37.789],
            [-91.566, 37.7894],
            [-91.6369, 37.8333],
            [-91.6611, 37.9211],
            [-91.7654, 38.0963],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929250',
        NAME: 'St. James R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 560197383,
        AWATER: 2800289,
        INTPTLAT: '+38.0084380',
        INTPTLON: '-091.6161648',
        ELSDLEA: '',
        UNSDLEA: '29250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4946, 37.701],
            [-93.3787, 37.8074],
            [-93.3538, 37.8066],
            [-93.2694, 37.819],
            [-93.235, 37.7746],
            [-93.218, 37.7596],
            [-93.318, 37.7112],
            [-93.3497, 37.6363],
            [-93.3143, 37.5747],
            [-93.3427, 37.5375],
            [-93.5069, 37.5439],
            [-93.4946, 37.701],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905370',
        NAME: 'Bolivar R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407151732,
        AWATER: 3820598,
        INTPTLAT: '+37.6727670',
        INTPTLON: '-093.3801499',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3111, 38.0666],
            [-93.1202, 38.0627],
            [-93.0675, 38.0177],
            [-93.0724, 37.9026],
            [-92.8892, 37.9057],
            [-92.856, 37.8805],
            [-92.8583, 37.8123],
            [-93.037, 37.7729],
            [-93.235, 37.7746],
            [-93.2694, 37.819],
            [-93.2453, 37.9526],
            [-93.2885, 37.9872],
            [-93.3111, 38.0666],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914320',
        NAME: 'Hickory County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 729913280,
        AWATER: 3617705,
        INTPTLAT: '+37.8971999',
        INTPTLON: '-093.1139849',
        ELSDLEA: '',
        UNSDLEA: '14320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4496, 38.0808],
            [-93.3111, 38.0666],
            [-93.2885, 37.9872],
            [-93.3538, 37.8066],
            [-93.3787, 37.8074],
            [-93.4583, 37.8102],
            [-93.4496, 38.0808],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931920',
        NAME: 'Wheatland R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310968587,
        AWATER: 13503408,
        INTPTLAT: '+37.9609247',
        INTPTLON: '-093.3804407',
        ELSDLEA: '',
        UNSDLEA: '31920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6453, 39.5249],
            [-93.4895, 39.5287],
            [-93.4304, 39.4902],
            [-93.2751, 39.351],
            [-93.2527, 39.3388],
            [-93.302, 39.3098],
            [-93.4064, 39.2284],
            [-93.6064, 39.2413],
            [-93.5873, 39.3247],
            [-93.6452, 39.4012],
            [-93.6453, 39.5249],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907380',
        NAME: 'Carrollton R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 703928710,
        AWATER: 9626698,
        INTPTLAT: '+39.3729473',
        INTPTLON: '-093.4909903',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6466, 39.6119],
            [-93.5907, 39.6281],
            [-93.5013, 39.6699],
            [-93.3944, 39.6447],
            [-93.3845, 39.5292],
            [-93.4304, 39.4902],
            [-93.4895, 39.5287],
            [-93.6453, 39.5249],
            [-93.6454, 39.5322],
            [-93.6466, 39.6119],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930300',
        NAME: 'Tina-Avalon R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273055697,
        AWATER: 482135,
        INTPTLAT: '+39.5794858',
        INTPTLON: '-093.5095880',
        ELSDLEA: '',
        UNSDLEA: '30300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.367, 40.4266],
            [-93.3098, 40.3835],
            [-93.1957, 40.3855],
            [-93.196, 40.2979],
            [-93.3103, 40.2963],
            [-93.3396, 40.2232],
            [-93.3766, 40.2662],
            [-93.367, 40.4266],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922470',
        NAME: 'Newtown-Harris R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230956033,
        AWATER: 924421,
        INTPTLAT: '+40.3278150',
        INTPTLON: '-093.3215861',
        ELSDLEA: '',
        UNSDLEA: '22470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7743, 40.5775],
            [-93.6999, 40.5778],
            [-93.6037, 40.4735],
            [-93.3668, 40.4559],
            [-93.367, 40.4266],
            [-93.3766, 40.2662],
            [-93.4565, 40.2654],
            [-93.6941, 40.2638],
            [-93.7632, 40.2496],
            [-93.7276, 40.3803],
            [-93.7645, 40.4239],
            [-93.7743, 40.5775],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925590',
        NAME: 'Princeton R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 799541299,
        AWATER: 1744607,
        INTPTLAT: '+40.3825641',
        INTPTLON: '-093.5946371',
        ELSDLEA: '',
        UNSDLEA: '25590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6205, 37.2183],
            [-89.6492, 37.1262],
            [-89.7218, 37.1406],
            [-89.6205, 37.2183],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908460',
        NAME: 'Chaffee R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56303734,
        AWATER: 474187,
        INTPTLAT: '+37.1726916',
        INTPTLON: '-089.6580993',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7652, 37.1261],
            [-89.7218, 37.1406],
            [-89.6492, 37.1262],
            [-89.6127, 37.0824],
            [-89.6179, 37.0679],
            [-89.7436, 37.0198],
            [-89.7652, 37.1261],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923130',
        NAME: 'Oran R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150822314,
        AWATER: 389944,
        INTPTLAT: '+37.0859176',
        INTPTLON: '-089.7052648',
        ELSDLEA: '',
        UNSDLEA: '23130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9918, 39.6768],
            [-94.8765, 39.8206],
            [-94.8055, 39.821],
            [-94.7105, 39.8207],
            [-94.6028, 39.8196],
            [-94.6024, 39.7763],
            [-94.7034, 39.6871],
            [-94.7666, 39.7191],
            [-94.8815, 39.6614],
            [-94.9918, 39.6768],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927060',
        NAME: 'St. Joseph School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342425073,
        AWATER: 8027581,
        INTPTLAT: '+39.7498135',
        INTPTLON: '-094.7981857',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9083, 37.4704],
            [-92.8439, 37.4401],
            [-92.7282, 37.4532],
            [-92.689, 37.3913],
            [-92.6913, 37.3072],
            [-92.8919, 37.3972],
            [-92.9083, 37.4704],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922500',
        NAME: 'Niangua R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185648682,
        AWATER: 554872,
        INTPTLAT: '+37.3935449',
        INTPTLON: '-092.7871489',
        ELSDLEA: '',
        UNSDLEA: '22500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4805, 38.1944],
            [-94.3684, 38.1634],
            [-94.2013, 38.1516],
            [-94.1015, 38.1052],
            [-94.104, 38.0476],
            [-94.2828, 38.0087],
            [-94.4989, 38.0575],
            [-94.5308, 38.174],
            [-94.4805, 38.1944],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926310',
        NAME: 'Rich Hill R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450611457,
        AWATER: 21808642,
        INTPTLAT: '+38.1027052',
        INTPTLON: '-094.3305481',
        ELSDLEA: '',
        UNSDLEA: '26310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6244, 39.3298],
            [-91.4604, 39.4204],
            [-91.4011, 39.4052],
            [-91.4082, 39.1864],
            [-91.5269, 39.191],
            [-91.6103, 39.2362],
            [-91.6244, 39.3298],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930780',
        NAME: 'Van-Far R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417229294,
        AWATER: 2577700,
        INTPTLAT: '+39.2905813',
        INTPTLON: '-091.4983363',
        ELSDLEA: '',
        UNSDLEA: '30780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.852, 38.8689],
            [-92.7226, 38.8612],
            [-92.6694, 38.8292],
            [-92.6716, 38.7362],
            [-92.7258, 38.7136],
            [-92.8804, 38.7584],
            [-92.852, 38.8689],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906150',
        NAME: 'Cooper County R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210999926,
        AWATER: 149267,
        INTPTLAT: '+38.7965821',
        INTPTLON: '-092.7665290',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1751, 38.6191],
            [-93.1687, 38.7838],
            [-93.0536, 38.8417],
            [-93.0333, 38.7954],
            [-93.062, 38.6491],
            [-93.0036, 38.6164],
            [-93.0666, 38.5459],
            [-93.1778, 38.5631],
            [-93.1751, 38.6191],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928380',
        NAME: 'Smithton R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323881305,
        AWATER: 1619142,
        INTPTLAT: '+38.6857576',
        INTPTLON: '-093.1024268',
        ELSDLEA: '',
        UNSDLEA: '28380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7105, 37.2838],
            [-93.623, 37.3692],
            [-93.5002, 37.3478],
            [-93.4505, 37.1989],
            [-93.6068, 37.2003],
            [-93.7105, 37.2838],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903270',
        NAME: 'Ash Grove R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308260827,
        AWATER: 361990,
        INTPTLAT: '+37.2738453',
        INTPTLON: '-093.5785115',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.759, 39.4519],
            [-93.6454, 39.5322],
            [-93.6453, 39.5249],
            [-93.6452, 39.4012],
            [-93.5873, 39.3247],
            [-93.6064, 39.2413],
            [-93.658, 39.2385],
            [-93.7585, 39.207],
            [-93.759, 39.4519],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922620',
        NAME: 'Norborne R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349694027,
        AWATER: 1665378,
        INTPTLAT: '+39.3452428',
        INTPTLON: '-093.6912712',
        ELSDLEA: '',
        UNSDLEA: '22620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.759, 39.4519],
            [-93.7585, 39.207],
            [-93.8902, 39.192],
            [-93.8671, 39.2939],
            [-93.9103, 39.4379],
            [-93.759, 39.4519],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913680',
        NAME: 'Hardin-Central C-2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277024094,
        AWATER: 3178116,
        INTPTLAT: '+39.3376791',
        INTPTLON: '-093.8084169',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9271, 39.5248],
            [-93.8707, 39.6127],
            [-93.8609, 39.6705],
            [-93.7772, 39.6987],
            [-93.7584, 39.6114],
            [-93.6466, 39.6119],
            [-93.6454, 39.5322],
            [-93.759, 39.4519],
            [-93.9103, 39.4379],
            [-93.9379, 39.4376],
            [-93.9271, 39.5248],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905820',
        NAME: 'Braymer C-4 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412471152,
        AWATER: 1278588,
        INTPTLAT: '+39.5623640',
        INTPTLON: '-093.7844713',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8583, 37.8123],
            [-92.8037, 37.7642],
            [-92.8268, 37.6603],
            [-92.8515, 37.553],
            [-92.9602, 37.5544],
            [-92.9708, 37.4857],
            [-93.0716, 37.4874],
            [-93.1816, 37.4536],
            [-93.1799, 37.5187],
            [-93.1732, 37.7297],
            [-93.218, 37.7596],
            [-93.235, 37.7746],
            [-93.037, 37.7729],
            [-92.8583, 37.8123],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906120',
        NAME: 'Dallas County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 977524588,
        AWATER: 4132244,
        INTPTLAT: '+37.6614858',
        INTPTLON: '-093.0204273',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.468, 37.5665],
            [-92.3896, 37.5258],
            [-92.3, 37.669],
            [-92.2489, 37.6485],
            [-92.177, 37.6052],
            [-92.0262, 37.7283],
            [-92.0293, 37.6025],
            [-92.0224, 37.5298],
            [-92.1978, 37.3859],
            [-92.2519, 37.3428],
            [-92.2523, 37.3572],
            [-92.3068, 37.4296],
            [-92.4144, 37.4312],
            [-92.4682, 37.4757],
            [-92.468, 37.5665],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925210',
        NAME: 'Plato R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 788974047,
        AWATER: 2928434,
        INTPTLAT: '+37.5306959',
        INTPTLON: '-092.2306180',
        ELSDLEA: '',
        UNSDLEA: '25210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5175, 37.979],
            [-92.4622, 37.9783],
            [-92.4647, 37.8462],
            [-92.4112, 37.797],
            [-92.4112, 37.7968],
            [-92.4627, 37.7149],
            [-92.4654, 37.715],
            [-92.6354, 37.7614],
            [-92.6333, 37.8491],
            [-92.6919, 37.8855],
            [-92.6324, 37.8925],
            [-92.5175, 37.979],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929580',
        NAME: 'Stoutland R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 425441089,
        AWATER: 1156555,
        INTPTLAT: '+37.8405835',
        INTPTLON: '-092.5514613',
        ELSDLEA: '',
        UNSDLEA: '29580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8268, 37.6603],
            [-92.7185, 37.6126],
            [-92.5872, 37.4791],
            [-92.6499, 37.4807],
            [-92.689, 37.3913],
            [-92.7282, 37.4532],
            [-92.8439, 37.4401],
            [-92.9083, 37.4704],
            [-92.9708, 37.4857],
            [-92.9602, 37.5544],
            [-92.8515, 37.553],
            [-92.8268, 37.6603],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910110',
        NAME: 'Laclede County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453144549,
        AWATER: 831578,
        INTPTLAT: '+37.5188897',
        INTPTLON: '-092.7637695',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3195, 39.8303],
            [-94.2055, 39.8759],
            [-94.1622, 39.7878],
            [-94.0933, 39.6726],
            [-94.1173, 39.6563],
            [-94.2072, 39.5923],
            [-94.2615, 39.6586],
            [-94.3557, 39.6597],
            [-94.3182, 39.7037],
            [-94.3195, 39.8303],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907020',
        NAME: 'Cameron R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381100585,
        AWATER: 2806015,
        INTPTLAT: '+39.7367442',
        INTPTLON: '-094.2258072',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2165, 40.1657],
            [-94.1038, 40.1204],
            [-94.0337, 40.0687],
            [-94.0724, 39.9598],
            [-94.2047, 39.9481],
            [-94.2564, 40.0366],
            [-94.2743, 40.104],
            [-94.2165, 40.1657],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923670',
        NAME: 'Pattonsburg R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308755077,
        AWATER: 3001339,
        INTPTLAT: '+40.0519987',
        INTPTLON: '-094.1628415',
        ELSDLEA: '',
        UNSDLEA: '23670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2047, 39.9481],
            [-94.0724, 39.9598],
            [-94.0532, 39.9558],
            [-94.1191, 39.8986],
            [-94.0861, 39.8438],
            [-94.1622, 39.7878],
            [-94.2055, 39.8759],
            [-94.2047, 39.9481],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932250',
        NAME: 'Winston R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149287453,
        AWATER: 2678062,
        INTPTLAT: '+39.8934836',
        INTPTLON: '-094.1381021',
        ELSDLEA: '',
        UNSDLEA: '32250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1038, 40.1204],
            [-93.9902, 40.1429],
            [-93.8776, 40.0651],
            [-93.883, 39.9976],
            [-94.0532, 39.9558],
            [-94.0724, 39.9598],
            [-94.0337, 40.0687],
            [-94.1038, 40.1204],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915630',
        NAME: 'North Daviess R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259784376,
        AWATER: 1780381,
        INTPTLAT: '+40.0502123',
        INTPTLON: '-093.9849219',
        ELSDLEA: '',
        UNSDLEA: '15630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7633, 40.2344],
            [-93.7632, 40.2496],
            [-93.6941, 40.2638],
            [-93.6236, 40.206],
            [-93.4992, 40.2068],
            [-93.4994, 40.1195],
            [-93.612, 40.0318],
            [-93.611, 39.963],
            [-93.6149, 39.9628],
            [-93.6316, 39.9622],
            [-93.6374, 39.9621],
            [-93.7024, 39.9607],
            [-93.7646, 40.0747],
            [-93.7633, 40.2344],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930360',
        NAME: 'Trenton R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481121544,
        AWATER: 4842100,
        INTPTLAT: '+40.1257838',
        INTPTLON: '-093.6723884',
        ELSDLEA: '',
        UNSDLEA: '30360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6423, 38.8418],
            [-91.4166, 38.8309],
            [-91.3309, 38.7863],
            [-91.2436, 38.6584],
            [-91.3121, 38.5982],
            [-91.3685, 38.5094],
            [-91.6424, 38.5052],
            [-91.6422, 38.5569],
            [-91.6028, 38.6439],
            [-91.6472, 38.7034],
            [-91.6423, 38.8418],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914280',
        NAME: 'Gasconade County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 949177887,
        AWATER: 22388569,
        INTPTLAT: '+38.6620634',
        INTPTLON: '-091.5087242',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9108, 38.6215],
            [-91.8819, 38.6527],
            [-91.7323, 38.7059],
            [-91.6472, 38.7034],
            [-91.6028, 38.6439],
            [-91.6422, 38.5569],
            [-91.7519, 38.5457],
            [-91.8269, 38.5928],
            [-91.9108, 38.6215],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908490',
        NAME: 'Osage County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272075724,
        AWATER: 6826849,
        INTPTLAT: '+38.6203838',
        INTPTLON: '-091.7338304',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.362, 40.0331],
            [-93.31, 40.0339],
            [-93.2291, 39.9402],
            [-93.2304, 39.8368],
            [-93.2304, 39.8254],
            [-93.2309, 39.8221],
            [-93.1877, 39.7611],
            [-93.267, 39.7036],
            [-93.3076, 39.6886],
            [-93.3305, 39.7036],
            [-93.3648, 39.7038],
            [-93.3613, 39.9239],
            [-93.362, 39.9676],
            [-93.362, 40.0331],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920640',
        NAME: 'Meadville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406566923,
        AWATER: 6838560,
        INTPTLAT: '+39.8578374',
        INTPTLON: '-093.2947155',
        ELSDLEA: '',
        UNSDLEA: '20640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2348, 40.0911],
            [-93.083, 40.1027],
            [-93.0031, 40.0365],
            [-92.9595, 40.0364],
            [-92.9445, 39.9643],
            [-93.0747, 39.9219],
            [-93.2309, 39.8221],
            [-93.2304, 39.8254],
            [-93.2304, 39.8368],
            [-93.2291, 39.9402],
            [-93.31, 40.0339],
            [-93.2348, 40.0911],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906000',
        NAME: 'Linn County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548225101,
        AWATER: 3570387,
        INTPTLAT: '+39.9821779',
        INTPTLON: '-093.1396718',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7765, 40.4165],
            [-94.6977, 40.4731],
            [-94.58, 40.4722],
            [-94.5985, 40.3867],
            [-94.6027, 40.3002],
            [-94.7618, 40.3006],
            [-94.7765, 40.4165],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921660',
        NAME: 'Northeast Nodaway County R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292199323,
        AWATER: 69164,
        INTPTLAT: '+40.3938803',
        INTPTLON: '-094.6728912',
        ELSDLEA: '',
        UNSDLEA: '21660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6701, 40.5717],
            [-94.4432, 40.571],
            [-94.2322, 40.5719],
            [-94.2146, 40.4705],
            [-94.2143, 40.3843],
            [-94.4788, 40.3866],
            [-94.5985, 40.3867],
            [-94.58, 40.4722],
            [-94.6977, 40.4731],
            [-94.6701, 40.5717],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932300',
        NAME: 'Worth County R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 706273038,
        AWATER: 535323,
        INTPTLAT: '+40.4778670',
        INTPTLON: '-094.4274160',
        ELSDLEA: '',
        UNSDLEA: '32300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.267, 39.7036],
            [-93.1877, 39.7611],
            [-93.2309, 39.8221],
            [-93.0747, 39.9219],
            [-92.9445, 39.9643],
            [-92.9636, 39.7897],
            [-93.0468, 39.7055],
            [-93.267, 39.7036],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905940',
        NAME: 'Brookfield R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428730890,
        AWATER: 3933771,
        INTPTLAT: '+39.7992934',
        INTPTLON: '-093.0827667',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6999, 40.5778],
            [-93.6331, 40.5787],
            [-93.3744, 40.5803],
            [-93.3668, 40.4559],
            [-93.6037, 40.4735],
            [-93.6999, 40.5778],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920750',
        NAME: 'North Mercer County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296291953,
        AWATER: 1500317,
        INTPTLAT: '+40.5234172',
        INTPTLON: '-093.4999526',
        ELSDLEA: '',
        UNSDLEA: '20750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9636, 39.7897],
            [-92.8216, 39.6999],
            [-92.7582, 39.6993],
            [-92.8751, 39.5853],
            [-93.013, 39.5841],
            [-92.9718, 39.6512],
            [-93.0468, 39.7055],
            [-92.9636, 39.7897],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920050',
        NAME: 'Marceline R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312024596,
        AWATER: 2910229,
        INTPTLAT: '+39.6798599',
        INTPTLON: '-092.9260444',
        ELSDLEA: '',
        UNSDLEA: '20050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7146, 40.5894],
            [-92.6184, 40.5913],
            [-92.3508, 40.5973],
            [-92.35, 40.3465],
            [-92.4606, 40.3452],
            [-92.6842, 40.3435],
            [-92.7058, 40.3865],
            [-92.7146, 40.5894],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927660',
        NAME: 'Schuyler County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 817871758,
        AWATER: 2246585,
        INTPTLAT: '+40.4693611',
        INTPTLON: '-092.5190156',
        ELSDLEA: '',
        UNSDLEA: '27660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1854, 38.0163],
            [-92.1822, 38.1315],
            [-91.9663, 38.0097],
            [-92.0414, 37.8811],
            [-92.1576, 37.8664],
            [-92.1854, 38.0163],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910830',
        NAME: 'Dixon R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344031957,
        AWATER: 2599551,
        INTPTLAT: '+37.9825755',
        INTPTLON: '-092.1034714',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8749, 38.0512],
            [-91.8019, 38.0955],
            [-91.7654, 38.0963],
            [-91.6611, 37.9211],
            [-91.6369, 37.8333],
            [-91.6932, 37.7908],
            [-91.8055, 37.7913],
            [-91.8609, 37.8062],
            [-91.8749, 38.0512],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926890',
        NAME: 'Rolla 31 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531865458,
        AWATER: 1432107,
        INTPTLAT: '+37.9199742',
        INTPTLON: '-091.7564195',
        ELSDLEA: '',
        UNSDLEA: '26890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5021, 36.8476],
            [-89.3559, 36.8506],
            [-89.3469, 36.8048],
            [-89.1763, 36.8056],
            [-89.1751, 36.8039],
            [-89.1749, 36.7578],
            [-89.1747, 36.6505],
            [-89.227, 36.5695],
            [-89.3276, 36.6323],
            [-89.3737, 36.7029],
            [-89.4928, 36.8037],
            [-89.5021, 36.8476],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911220',
        NAME: 'East Prairie R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475612827,
        AWATER: 17046107,
        INTPTLAT: '+36.7301546',
        INTPTLON: '-089.3007382',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6127, 37.0824],
            [-89.4945, 37.1488],
            [-89.4544, 37.1854],
            [-89.3902, 37.1091],
            [-89.3127, 37.009],
            [-89.3938, 36.9355],
            [-89.4836, 36.9064],
            [-89.575, 36.9356],
            [-89.5583, 37.0607],
            [-89.6179, 37.0679],
            [-89.6127, 37.0824],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904890',
        NAME: 'Scott County R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413177704,
        AWATER: 8887593,
        INTPTLAT: '+37.0324454',
        INTPTLON: '-089.4793898',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2574, 36.9836],
            [-93.2496, 36.9175],
            [-93.157, 36.8721],
            [-93.1827, 36.8145],
            [-93.2764, 36.8165],
            [-93.3066, 36.7589],
            [-93.3054, 36.7883],
            [-93.342, 36.8173],
            [-93.3566, 36.9491],
            [-93.2574, 36.9836],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928800',
        NAME: 'Spokane R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209932778,
        AWATER: 82915,
        INTPTLAT: '+36.8817322',
        INTPTLON: '-093.2773499',
        ELSDLEA: '',
        UNSDLEA: '28800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9231, 36.9845],
            [-92.9035, 37.0376],
            [-92.8519, 37.0688],
            [-92.6849, 37.0904],
            [-92.5787, 37.007],
            [-92.5796, 36.9639],
            [-92.493, 36.8339],
            [-92.3467, 36.8868],
            [-92.3473, 36.8756],
            [-92.3672, 36.7993],
            [-92.5074, 36.8016],
            [-92.6029, 36.7614],
            [-92.7468, 36.8057],
            [-92.783, 36.8065],
            [-92.7632, 36.8927],
            [-92.908, 36.8676],
            [-92.9231, 36.9845],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904050',
        NAME: 'Ava R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 927543630,
        AWATER: 437100,
        INTPTLAT: '+36.9157824',
        INTPTLON: '-092.6806534',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6798, 37.2397],
            [-92.5528, 37.2249],
            [-92.5207, 37.1474],
            [-92.4881, 37.0198],
            [-92.5787, 37.007],
            [-92.6849, 37.0904],
            [-92.6798, 37.2397],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919980',
        NAME: 'Mansfield R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351891594,
        AWATER: 206981,
        INTPTLAT: '+37.1276197',
        INTPTLON: '-092.5861068',
        ELSDLEA: '',
        UNSDLEA: '19980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5207, 37.1474],
            [-92.4083, 37.2085],
            [-92.3497, 37.1637],
            [-92.3791, 37.0224],
            [-92.4881, 37.0198],
            [-92.5207, 37.1474],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922950',
        NAME: 'Norwood R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222174879,
        AWATER: 36256,
        INTPTLAT: '+37.1118561',
        INTPTLON: '-092.4248676',
        ELSDLEA: '',
        UNSDLEA: '22950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6913, 37.3072],
            [-92.689, 37.3913],
            [-92.6499, 37.4807],
            [-92.5872, 37.4791],
            [-92.4682, 37.4757],
            [-92.4144, 37.4312],
            [-92.417, 37.3084],
            [-92.4083, 37.2085],
            [-92.5207, 37.1474],
            [-92.5528, 37.2249],
            [-92.6798, 37.2397],
            [-92.6923, 37.2635],
            [-92.6913, 37.3072],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913770',
        NAME: 'Hartville R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663385023,
        AWATER: 1436918,
        INTPTLAT: '+37.3374009',
        INTPTLON: '-092.5351428',
        ELSDLEA: '',
        UNSDLEA: '13770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6353, 38.6303],
            [-92.6161, 38.7308],
            [-92.5411, 38.7359],
            [-92.4156, 38.674],
            [-92.446, 38.5797],
            [-92.515, 38.5454],
            [-92.6214, 38.5483],
            [-92.6353, 38.6303],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906510',
        NAME: 'Moniteau County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309696039,
        AWATER: 2228684,
        INTPTLAT: '+38.6438914',
        INTPTLON: '-092.5355230',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6649, 38.4613],
            [-92.5301, 38.4577],
            [-92.4035, 38.4253],
            [-92.4673, 38.2981],
            [-92.5254, 38.2711],
            [-92.6317, 38.296],
            [-92.7367, 38.2207],
            [-92.7816, 38.336],
            [-92.6649, 38.4613],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911340',
        NAME: 'Eldon R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468122623,
        AWATER: 10888812,
        INTPTLAT: '+38.3535651',
        INTPTLON: '-092.6109520',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4805, 38.9089],
            [-92.4624, 38.8645],
            [-92.5349, 38.8234],
            [-92.5411, 38.7359],
            [-92.6161, 38.7308],
            [-92.6716, 38.7362],
            [-92.6694, 38.8292],
            [-92.7226, 38.8612],
            [-92.5958, 38.9031],
            [-92.4805, 38.9089],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925530',
        NAME: 'Prairie Home R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265542875,
        AWATER: 1703247,
        INTPTLAT: '+38.8286247',
        INTPTLON: '-092.5980542',
        ELSDLEA: '',
        UNSDLEA: '25530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8937, 38.5752],
            [-92.8243, 38.5817],
            [-92.7336, 38.5142],
            [-92.6647, 38.4758],
            [-92.6649, 38.4613],
            [-92.7816, 38.336],
            [-92.7367, 38.2207],
            [-92.7645, 38.1896],
            [-92.843, 38.1926],
            [-92.8534, 38.3523],
            [-92.9239, 38.3988],
            [-92.8937, 38.5752],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930840',
        NAME: 'Morgan County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 521029921,
        AWATER: 15898729,
        INTPTLAT: '+38.4038973',
        INTPTLON: '-092.8143219',
        ELSDLEA: '',
        UNSDLEA: '30840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6089, 37.0982],
            [-93.4905, 37.0712],
            [-93.519, 36.9902],
            [-93.5557, 36.9951],
            [-93.6089, 37.0982],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905070',
        NAME: 'Billings R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102586297,
        AWATER: 272375,
        INTPTLAT: '+37.0381227',
        INTPTLON: '-093.5637757',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5658, 36.9513],
            [-93.5557, 36.9951],
            [-93.519, 36.9902],
            [-93.3935, 36.9534],
            [-93.3852, 36.9374],
            [-93.5041, 36.8664],
            [-93.5658, 36.9513],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915390',
        NAME: 'Hurley R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133210427,
        AWATER: 457992,
        INTPTLAT: '+36.9236804',
        INTPTLON: '-093.4765033',
        ELSDLEA: '',
        UNSDLEA: '15390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3133, 36.5747],
            [-93.3128, 36.5959],
            [-93.2115, 36.6403],
            [-93.1366, 36.5429],
            [-93.1512, 36.498],
            [-93.2935, 36.4983],
            [-93.3298, 36.4985],
            [-93.3133, 36.5747],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914550',
        NAME: 'Hollister R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171706482,
        AWATER: 13870191,
        INTPTLAT: '+36.5552607',
        INTPTLON: '-093.2328615',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5826, 36.5629],
            [-93.4689, 36.6122],
            [-93.3133, 36.5747],
            [-93.3298, 36.4985],
            [-93.4748, 36.4988],
            [-93.5843, 36.4989],
            [-93.5826, 36.5629],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905280',
        NAME: 'Blue Eye R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220798119,
        AWATER: 44044051,
        INTPTLAT: '+36.5489756',
        INTPTLON: '-093.4482505',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0675, 38.0177],
            [-92.8951, 38.0723],
            [-92.8474, 38.0666],
            [-92.8892, 37.9057],
            [-93.0724, 37.9026],
            [-93.0675, 38.0177],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919380',
        NAME: 'Macks Creek R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301096037,
        AWATER: 4243319,
        INTPTLAT: '+37.9824719',
        INTPTLON: '-092.9520724',
        ELSDLEA: '',
        UNSDLEA: '19380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2719, 37.0938],
            [-93.2197, 37.0922],
            [-93.1303, 37.0483],
            [-93.1397, 36.9101],
            [-93.157, 36.8721],
            [-93.2496, 36.9175],
            [-93.2574, 36.9836],
            [-93.2719, 37.0938],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923430',
        NAME: 'Ozark R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233461122,
        AWATER: 689853,
        INTPTLAT: '+36.9880744',
        INTPTLON: '-093.1909303',
        ELSDLEA: '',
        UNSDLEA: '23430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1303, 37.0483],
            [-92.9576, 37.0607],
            [-92.9035, 37.0376],
            [-92.9231, 36.9845],
            [-93.0647, 36.9823],
            [-93.1397, 36.9101],
            [-93.1303, 37.0483],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928740',
        NAME: 'Sparta R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171385049,
        AWATER: 595112,
        INTPTLAT: '+37.0041863',
        INTPTLON: '-093.0432984',
        ELSDLEA: '',
        UNSDLEA: '28740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5697, 38.918],
            [-94.5305, 38.9706],
            [-94.4386, 38.9566],
            [-94.4518, 38.8983],
            [-94.5697, 38.918],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914340',
        NAME: 'Hickman Mills C-1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75395639,
        AWATER: 3226683,
        INTPTLAT: '+38.9339822',
        INTPTLON: '-094.5068139',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3884, 39.0194],
            [-94.2914, 39.0969],
            [-94.2378, 39.0661],
            [-94.2318, 38.9644],
            [-94.3764, 38.9685],
            [-94.3887, 39.0127],
            [-94.3884, 39.0194],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905310',
        NAME: 'Blue Springs R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139413557,
        AWATER: 7305658,
        INTPTLAT: '+39.0224592',
        INTPTLON: '-094.3044383',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6074, 39.0441],
            [-94.6074, 39.1134],
            [-94.5235, 39.1488],
            [-94.4554, 39.1231],
            [-94.4518, 39.0515],
            [-94.5298, 38.986],
            [-94.6083, 38.9747],
            [-94.6074, 39.0441],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916400',
        NAME: 'Kansas City 33 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171414281,
        AWATER: 2743285,
        INTPTLAT: '+39.0650210',
        INTPTLON: '-094.5422019',
        ELSDLEA: '',
        UNSDLEA: '16400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4959, 38.8548],
            [-93.3851, 38.8502],
            [-93.3047, 38.7954],
            [-93.3552, 38.7318],
            [-93.5064, 38.6797],
            [-93.4959, 38.8548],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916920',
        NAME: 'La Monte R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212044561,
        AWATER: 1084341,
        INTPTLAT: '+38.7770000',
        INTPTLON: '-093.4249575',
        ELSDLEA: '',
        UNSDLEA: '16920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0367, 37.0344],
            [-89.9957, 37.0401],
            [-89.9689, 37.1741],
            [-89.9417, 37.1768],
            [-89.8667, 37.1844],
            [-89.8181, 37.1261],
            [-89.8176, 37.0825],
            [-89.9511, 37.0132],
            [-90.0056, 37.0149],
            [-90.0367, 37.0344],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2902880',
        NAME: 'Advance R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226814926,
        AWATER: 1949571,
        INTPTLAT: '+37.0929989',
        INTPTLON: '-089.9219196',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0918, 37.5983],
            [-89.8611, 37.5993],
            [-89.8595, 37.4964],
            [-89.8796, 37.4476],
            [-89.9939, 37.3936],
            [-90.1452, 37.4058],
            [-90.1437, 37.4651],
            [-90.1468, 37.5974],
            [-90.0918, 37.5983],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923640',
        NAME: 'Meadow Heights R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504393669,
        AWATER: 716470,
        INTPTLAT: '+37.5108438',
        INTPTLON: '-090.0044482',
        ELSDLEA: '',
        UNSDLEA: '23640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5263, 37.9688],
            [-91.3275, 38.0078],
            [-91.301, 38.0457],
            [-91.1223, 38.0389],
            [-91.0948, 37.9579],
            [-91.0964, 37.8708],
            [-91.3416, 37.8748],
            [-91.4188, 37.7885],
            [-91.5288, 37.789],
            [-91.5263, 37.9688],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929430',
        NAME: 'Steelville R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713509673,
        AWATER: 544422,
        INTPTLAT: '+37.9318021',
        INTPTLON: '-091.3184789',
        ELSDLEA: '',
        UNSDLEA: '29430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.869, 38.8884],
            [-90.827, 38.879],
            [-90.7782, 38.9225],
            [-90.6632, 38.9342],
            [-90.5861, 38.8706],
            [-90.5679, 38.8234],
            [-90.5769, 38.7791],
            [-90.7368, 38.7354],
            [-90.7355, 38.8056],
            [-90.855, 38.8328],
            [-90.869, 38.8884],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908370',
        NAME: 'Fort Zumwalt R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317183509,
        AWATER: 10986044,
        INTPTLAT: '+38.8470374',
        INTPTLON: '-090.7088225',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1998, 38.5958],
            [-91.1995, 38.6135],
            [-91.0775, 38.6957],
            [-90.9632, 38.6779],
            [-90.7323, 38.6393],
            [-90.7339, 38.5489],
            [-90.8021, 38.5074],
            [-90.9101, 38.5124],
            [-90.9055, 38.4383],
            [-91.087, 38.4693],
            [-91.2003, 38.5105],
            [-91.1998, 38.5958],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931110',
        NAME: 'Washington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 679646055,
        AWATER: 22873597,
        INTPTLAT: '+38.5679723',
        INTPTLON: '-090.9808464',
        ELSDLEA: '',
        UNSDLEA: '31110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2557, 38.5049],
            [-91.2003, 38.5105],
            [-91.087, 38.4693],
            [-90.9055, 38.4383],
            [-90.9079, 38.3994],
            [-91.1033, 38.3614],
            [-91.1109, 38.3326],
            [-91.1172, 38.3325],
            [-91.2188, 38.3747],
            [-91.2557, 38.5049],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930570',
        NAME: 'Union R-XI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396941871,
        AWATER: 527369,
        INTPTLAT: '+38.4232296',
        INTPTLON: '-091.1126107',
        ELSDLEA: '',
        UNSDLEA: '30570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2555, 38.2413],
            [-91.1445, 38.2409],
            [-91.1172, 38.3325],
            [-91.1109, 38.3326],
            [-91.087, 38.2717],
            [-90.9522, 38.2054],
            [-90.9726, 38.1501],
            [-91.0502, 38.133],
            [-91.1898, 38.1316],
            [-91.2555, 38.2041],
            [-91.2555, 38.2413],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929760',
        NAME: 'Sullivan C-2 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325321044,
        AWATER: 0,
        INTPTLAT: '+38.2079369',
        INTPTLON: '-091.0995637',
        ELSDLEA: '',
        UNSDLEA: '29760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2555, 38.2041],
            [-91.1898, 38.1316],
            [-91.0502, 38.133],
            [-91.0484, 38.0535],
            [-90.9389, 38.0443],
            [-90.9408, 37.9675],
            [-91.0948, 37.9579],
            [-91.1223, 38.0389],
            [-91.301, 38.0457],
            [-91.3581, 38.2041],
            [-91.2555, 38.2041],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905640',
        NAME: 'Crawford County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465485784,
        AWATER: 742758,
        INTPTLAT: '+38.0769337',
        INTPTLON: '-091.1607426',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6083, 38.9747],
            [-94.5298, 38.986],
            [-94.5305, 38.9706],
            [-94.5697, 38.918],
            [-94.608, 38.9199],
            [-94.608, 38.9368],
            [-94.6083, 38.9747],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908250',
        NAME: 'Center 58 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30056378,
        AWATER: 6621,
        INTPTLAT: '+38.9541306',
        INTPTLON: '-094.5771590',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2378, 39.0661],
            [-94.1628, 39.0935],
            [-94.171, 38.923],
            [-94.2031, 38.9206],
            [-94.2318, 38.9644],
            [-94.2378, 39.0661],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913080',
        NAME: 'Grain Valley R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100379986,
        AWATER: 347441,
        INTPTLAT: '+39.0142675',
        INTPTLON: '-094.1912074',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4554, 39.1231],
            [-94.4393, 39.1318],
            [-94.4195, 39.1493],
            [-94.2914, 39.0969],
            [-94.3884, 39.0194],
            [-94.4518, 39.0515],
            [-94.4554, 39.1231],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915480',
        NAME: 'Independence 30 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109746027,
        AWATER: 839107,
        INTPTLAT: '+39.0832789',
        INTPTLON: '-094.4026720',
        ELSDLEA: '',
        UNSDLEA: '15480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5298, 38.986],
            [-94.4518, 39.0515],
            [-94.3884, 39.0194],
            [-94.3887, 39.0127],
            [-94.4386, 38.9566],
            [-94.5305, 38.9706],
            [-94.5298, 38.986],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926070',
        NAME: 'Raytown C-2 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82963696,
        AWATER: 325672,
        INTPTLAT: '+39.0036875',
        INTPTLON: '-094.4610383',
        ELSDLEA: '',
        UNSDLEA: '26070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5869, 39.2901],
            [-92.594, 39.3535],
            [-92.5004, 39.3805],
            [-92.4826, 39.3583],
            [-92.4486, 39.2536],
            [-92.6007, 39.1873],
            [-92.5869, 39.2901],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914370',
        NAME: 'Higbee R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176791865,
        AWATER: 569671,
        INTPTLAT: '+39.2828238',
        INTPTLON: '-092.5286672',
        ELSDLEA: '',
        UNSDLEA: '14370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5004, 39.3805],
            [-92.4756, 39.4834],
            [-92.3755, 39.4671],
            [-92.3048, 39.4984],
            [-92.3059, 39.4586],
            [-92.3093, 39.3632],
            [-92.4253, 39.3875],
            [-92.4826, 39.3583],
            [-92.5004, 39.3805],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921100',
        NAME: 'Moberly School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192981298,
        AWATER: 838781,
        INTPTLAT: '+39.4245966',
        INTPTLON: '-092.3966754',
        ELSDLEA: '',
        UNSDLEA: '21100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.658, 39.2385],
            [-93.6064, 39.2413],
            [-93.4064, 39.2284],
            [-93.3766, 39.0773],
            [-93.4919, 39.0229],
            [-93.5909, 39.049],
            [-93.6379, 39.1665],
            [-93.658, 39.2385],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903000',
        NAME: 'Santa Fe R-X School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 423997194,
        AWATER: 7646047,
        INTPTLAT: '+39.1461264',
        INTPTLON: '-093.5157987',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9179, 39.1833],
            [-93.8902, 39.192],
            [-93.7585, 39.207],
            [-93.658, 39.2385],
            [-93.6379, 39.1665],
            [-93.7384, 39.168],
            [-93.8206, 39.0853],
            [-93.9029, 39.0856],
            [-93.9179, 39.1833],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918480',
        NAME: 'Lexington R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209171342,
        AWATER: 6997981,
        INTPTLAT: '+39.1551544',
        INTPTLON: '-093.8018101',
        ELSDLEA: '',
        UNSDLEA: '18480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0987, 39.2862],
            [-94.0986, 39.3484],
            [-94.0604, 39.4639],
            [-93.9379, 39.4376],
            [-93.9103, 39.4379],
            [-93.8671, 39.2939],
            [-93.8902, 39.192],
            [-93.9179, 39.1833],
            [-94.0242, 39.1545],
            [-94.0283, 39.227],
            [-94.0987, 39.2862],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926480',
        NAME: 'Richmond R-XVI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503600185,
        AWATER: 6433656,
        INTPTLAT: '+39.3154012',
        INTPTLON: '-093.9698473',
        ELSDLEA: '',
        UNSDLEA: '26480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1068, 39.0917],
            [-94.1048, 39.1435],
            [-94.0242, 39.1545],
            [-93.9179, 39.1833],
            [-93.9029, 39.0856],
            [-93.9048, 39.042],
            [-94.1088, 39.0453],
            [-94.1068, 39.0917],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931560',
        NAME: 'Wellington-Napoleon R-IX School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177544346,
        AWATER: 5389418,
        INTPTLAT: '+39.1122669',
        INTPTLON: '-094.0014860',
        ELSDLEA: '',
        UNSDLEA: '31560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.149, 39.227],
            [-91.0798, 39.2252],
            [-91.0631, 39.3138],
            [-90.9502, 39.3577],
            [-90.9496, 39.4078],
            [-90.7918, 39.3071],
            [-90.7218, 39.2241],
            [-90.8954, 39.2251],
            [-90.9644, 39.1788],
            [-91.0096, 39.1653],
            [-91.149, 39.227],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925140',
        NAME: 'Pike County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457412134,
        AWATER: 13030201,
        INTPTLAT: '+39.2786527',
        INTPTLON: '-090.9290848',
        ELSDLEA: '',
        UNSDLEA: '25140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4166, 38.8309],
            [-91.2462, 38.8297],
            [-91.2645, 38.9848],
            [-91.0911, 38.8942],
            [-91.0775, 38.6957],
            [-91.1995, 38.6135],
            [-91.2436, 38.6584],
            [-91.3309, 38.7863],
            [-91.4166, 38.8309],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931050',
        NAME: 'Warren County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625634237,
        AWATER: 9271777,
        INTPTLAT: '+38.7996891',
        INTPTLON: '-091.2129582',
        ELSDLEA: '',
        UNSDLEA: '31050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0911, 38.8942],
            [-90.9576, 38.8933],
            [-90.9624, 38.7603],
            [-90.9632, 38.6779],
            [-91.0775, 38.6957],
            [-91.0911, 38.8942],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932310',
        NAME: 'Wright City R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235730144,
        AWATER: 4845154,
        INTPTLAT: '+38.7941649',
        INTPTLON: '-091.0221604',
        ELSDLEA: '',
        UNSDLEA: '32310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1088, 39.0453],
            [-93.9048, 39.042],
            [-93.9029, 39.0856],
            [-93.8206, 39.0853],
            [-93.7744, 39.0747],
            [-93.798, 38.9365],
            [-93.9463, 38.8754],
            [-94.0767, 38.9053],
            [-94.1088, 39.0453],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923100',
        NAME: 'Odessa R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452753028,
        AWATER: 3499227,
        INTPTLAT: '+38.9861990',
        INTPTLON: '-093.9320692',
        ELSDLEA: '',
        UNSDLEA: '23100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5249, 38.8966],
            [-93.4919, 39.0229],
            [-93.3766, 39.0773],
            [-93.3223, 39.0676],
            [-93.2228, 39.0152],
            [-93.2448, 38.9495],
            [-93.4081, 38.9397],
            [-93.3851, 38.8502],
            [-93.4959, 38.8548],
            [-93.5249, 38.8966],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929880',
        NAME: 'Sweet Springs R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365130961,
        AWATER: 2859114,
        INTPTLAT: '+38.9789643',
        INTPTLON: '-093.3810707',
        ELSDLEA: '',
        UNSDLEA: '29880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2847, 38.5871],
            [-94.2098, 38.6199],
            [-94.1464, 38.6044],
            [-94.1197, 38.5687],
            [-93.9704, 38.5945],
            [-93.9173, 38.5642],
            [-93.9008, 38.4137],
            [-94.0674, 38.4032],
            [-94.1477, 38.4507],
            [-94.2791, 38.5182],
            [-94.2847, 38.5871],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910320',
        NAME: 'Sherwood Cass R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490818579,
        AWATER: 4295100,
        INTPTLAT: '+38.5077575',
        INTPTLON: '-094.0748765',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1484, 38.7005],
            [-94.1457, 38.7004],
            [-94.0124, 38.7112],
            [-94.0091, 38.781],
            [-94.1122, 38.8328],
            [-94.1141, 38.9062],
            [-94.0767, 38.9053],
            [-93.9463, 38.8754],
            [-93.9515, 38.7832],
            [-93.8432, 38.6908],
            [-93.8922, 38.6113],
            [-93.9704, 38.5945],
            [-94.1197, 38.5687],
            [-94.1464, 38.6044],
            [-94.1484, 38.7005],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914490',
        NAME: 'Holden R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 536092739,
        AWATER: 3126563,
        INTPTLAT: '+38.7230643',
        INTPTLON: '-094.0087533',
        ELSDLEA: '',
        UNSDLEA: '14490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6946, 38.9048],
            [-93.6242, 38.8592],
            [-93.6693, 38.7579],
            [-93.6084, 38.6833],
            [-93.7827, 38.6595],
            [-93.8252, 38.6902],
            [-93.7845, 38.7334],
            [-93.7629, 38.871],
            [-93.6946, 38.9048],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931020',
        NAME: 'Warrensburg R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279792134,
        AWATER: 1055280,
        INTPTLAT: '+38.7659631',
        INTPTLON: '-093.7140139',
        ELSDLEA: '',
        UNSDLEA: '31020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1307, 38.8038],
            [-94.1122, 38.8328],
            [-94.0091, 38.781],
            [-94.0124, 38.7112],
            [-94.1457, 38.7004],
            [-94.1307, 38.8038],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916660',
        NAME: 'Kingsville R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127917221,
        AWATER: 1051459,
        INTPTLAT: '+38.7643483',
        INTPTLON: '-094.0755179',
        ELSDLEA: '',
        UNSDLEA: '16660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7885, 38.562],
            [-93.7827, 38.6595],
            [-93.6084, 38.6833],
            [-93.5689, 38.6383],
            [-93.677, 38.5602],
            [-93.7885, 38.562],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918360',
        NAME: 'Leeton R-X School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190665084,
        AWATER: 577761,
        INTPTLAT: '+38.6253698',
        INTPTLON: '-093.6779587',
        ELSDLEA: '',
        UNSDLEA: '18360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4605, 36.5939],
            [-91.3824, 36.6278],
            [-91.2434, 36.6118],
            [-91.1232, 36.6289],
            [-91.1265, 36.4977],
            [-91.1724, 36.4975],
            [-91.4073, 36.4971],
            [-91.4605, 36.5939],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910200',
        NAME: 'Couch R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346238639,
        AWATER: 1219485,
        INTPTLAT: '+36.5597043',
        INTPTLON: '-091.2815916',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4279, 37.0453],
            [-91.2907, 37.0453],
            [-91.289, 37.1321],
            [-91.1445, 37.1421],
            [-91.0904, 37.0867],
            [-91.1108, 37.0266],
            [-91.2187, 37.0276],
            [-91.2245, 36.8404],
            [-91.3339, 36.8418],
            [-91.3693, 36.8857],
            [-91.4235, 36.929],
            [-91.4279, 37.0453],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932220',
        NAME: 'Winona R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540065172,
        AWATER: 75774,
        INTPTLAT: '+37.0084397',
        INTPTLON: '-091.2886035',
        ELSDLEA: '',
        UNSDLEA: '32220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4195, 39.1493],
            [-94.4031, 39.182],
            [-94.3318, 39.1871],
            [-94.2783, 39.2362],
            [-94.2125, 39.2071],
            [-94.1048, 39.1435],
            [-94.1068, 39.0917],
            [-94.1628, 39.0935],
            [-94.2378, 39.0661],
            [-94.2914, 39.0969],
            [-94.4195, 39.1493],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912290',
        NAME: 'Fort Osage R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299367408,
        AWATER: 7391556,
        INTPTLAT: '+39.1502526',
        INTPTLON: '-094.2407045',
        ELSDLEA: '',
        UNSDLEA: '12290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.608, 38.9199],
            [-94.5697, 38.918],
            [-94.4518, 38.8983],
            [-94.4368, 38.8397],
            [-94.49, 38.8417],
            [-94.608, 38.8472],
            [-94.608, 38.9199],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913140',
        NAME: 'Grandview C-4 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97380018,
        AWATER: 1482334,
        INTPTLAT: '+38.8741345',
        INTPTLON: '-094.5239520',
        ELSDLEA: '',
        UNSDLEA: '13140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6183, 36.8936],
            [-94.618, 36.9959],
            [-94.4813, 37.0102],
            [-94.5341, 36.9832],
            [-94.4996, 36.8811],
            [-94.5482, 36.872],
            [-94.504, 36.7921],
            [-94.5498, 36.7503],
            [-94.6182, 36.7167],
            [-94.6183, 36.7331],
            [-94.6183, 36.8936],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927900',
        NAME: 'Seneca R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242484754,
        AWATER: 336414,
        INTPTLAT: '+36.8751605',
        INTPTLON: '-094.5785885',
        ELSDLEA: '',
        UNSDLEA: '27900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2165, 40.1657],
            [-94.2143, 40.3843],
            [-94.2146, 40.4705],
            [-94.1101, 40.4705],
            [-94.0636, 40.4113],
            [-94.0634, 40.3823],
            [-93.8791, 40.2932],
            [-93.8218, 40.3802],
            [-93.7276, 40.3803],
            [-93.7632, 40.2496],
            [-93.7633, 40.2344],
            [-93.9486, 40.2064],
            [-93.9902, 40.1429],
            [-94.1038, 40.1204],
            [-94.2165, 40.1657],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2900002',
        NAME: 'South Harrison County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 901731860,
        AWATER: 5517613,
        INTPTLAT: '+40.2843982',
        INTPTLON: '-094.0345716',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7633, 40.2344],
            [-93.7646, 40.0747],
            [-93.8776, 40.0651],
            [-93.9902, 40.1429],
            [-93.9486, 40.2064],
            [-93.7633, 40.2344],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912870',
        NAME: 'Gilman City R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272475659,
        AWATER: 436847,
        INTPTLAT: '+40.1548559',
        INTPTLON: '-093.8312357',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1622, 39.7878],
            [-94.0861, 39.8438],
            [-93.9906, 39.873],
            [-93.9457, 39.7876],
            [-93.8705, 39.7944],
            [-93.8702, 39.6995],
            [-93.9926, 39.6727],
            [-94.0933, 39.6726],
            [-94.1622, 39.7878],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913590',
        NAME: 'Hamilton R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362823370,
        AWATER: 3454132,
        INTPTLAT: '+39.7601852',
        INTPTLON: '-094.0108108',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5483, 37.4069],
            [-90.5467, 37.5112],
            [-90.4536, 37.6428],
            [-90.1468, 37.6416],
            [-90.0918, 37.5983],
            [-90.1468, 37.5974],
            [-90.1437, 37.4651],
            [-90.3602, 37.4358],
            [-90.4385, 37.3161],
            [-90.4526, 37.3162],
            [-90.5497, 37.3176],
            [-90.5483, 37.4069],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912540',
        NAME: 'Fredericktown R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 886942758,
        AWATER: 6840250,
        INTPTLAT: '+37.5177215',
        INTPTLON: '-090.3535325',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4386, 38.9566],
            [-94.3887, 39.0127],
            [-94.3764, 38.9685],
            [-94.2318, 38.9644],
            [-94.2031, 38.9206],
            [-94.2121, 38.8342],
            [-94.353, 38.8159],
            [-94.4368, 38.8397],
            [-94.4518, 38.8983],
            [-94.4386, 38.9566],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918300',
        NAME: "Lee's Summit R-VII School District",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293844036,
        AWATER: 7766003,
        INTPTLAT: '+38.9061171',
        INTPTLON: '-094.3308303',
        ELSDLEA: '',
        UNSDLEA: '18300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2031, 38.9206],
            [-94.171, 38.923],
            [-94.1141, 38.9062],
            [-94.1122, 38.8328],
            [-94.1307, 38.8038],
            [-94.1661, 38.8195],
            [-94.2121, 38.8342],
            [-94.2031, 38.9206],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919230',
        NAME: 'Lone Jack C-6 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99030706,
        AWATER: 184528,
        INTPTLAT: '+38.8711390',
        INTPTLON: '-094.1644135',
        ELSDLEA: '',
        UNSDLEA: '19230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6091, 38.7381],
            [-94.608, 38.8472],
            [-94.49, 38.8417],
            [-94.5631, 38.7195],
            [-94.6095, 38.7217],
            [-94.6091, 38.7381],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904620',
        NAME: 'Belton 124 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110174399,
        AWATER: 972112,
        INTPTLAT: '+38.7937320',
        INTPTLON: '-094.5606225',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.353, 38.8159],
            [-94.2121, 38.8342],
            [-94.1661, 38.8195],
            [-94.2493, 38.791],
            [-94.1916, 38.7089],
            [-94.2949, 38.6929],
            [-94.3566, 38.721],
            [-94.353, 38.8159],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925330',
        NAME: 'Pleasant Hill R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192689178,
        AWATER: 3069888,
        INTPTLAT: '+38.7748057',
        INTPTLON: '-094.2864239',
        ELSDLEA: '',
        UNSDLEA: '25330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7702, 37.5494],
            [-90.6561, 37.558],
            [-90.6589, 37.4103],
            [-90.5483, 37.4069],
            [-90.5497, 37.3176],
            [-90.5874, 37.2733],
            [-90.7796, 37.2542],
            [-90.8127, 37.2854],
            [-90.847, 37.3728],
            [-90.7717, 37.43],
            [-90.7702, 37.5494],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928470',
        NAME: 'South Iron County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557844015,
        AWATER: 2720882,
        INTPTLAT: '+37.3766024',
        INTPTLON: '-090.6841695',
        ELSDLEA: '',
        UNSDLEA: '28470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7796, 37.2542],
            [-90.5874, 37.2733],
            [-90.5497, 37.3176],
            [-90.4526, 37.3162],
            [-90.4638, 37.1257],
            [-90.5001, 37.0757],
            [-90.6026, 37.0984],
            [-90.6299, 36.993],
            [-90.7134, 36.9711],
            [-90.7796, 37.0503],
            [-90.779, 37.1376],
            [-90.7796, 37.2542],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909750',
        NAME: 'Clearwater R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 685964815,
        AWATER: 5045697,
        INTPTLAT: '+37.1629962',
        INTPTLON: '-090.6266223',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6179, 37.313],
            [-94.6176, 37.3642],
            [-94.5169, 37.3612],
            [-94.4705, 37.269],
            [-94.5115, 37.1246],
            [-94.618, 37.0327],
            [-94.6178, 37.1338],
            [-94.6179, 37.313],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907350',
        NAME: 'Carl Junction R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333545092,
        AWATER: 2659064,
        INTPTLAT: '+37.2235750',
        INTPTLON: '-094.5534972',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5631, 38.7195],
            [-94.49, 38.8417],
            [-94.4368, 38.8397],
            [-94.353, 38.8159],
            [-94.3566, 38.721],
            [-94.4492, 38.643],
            [-94.5631, 38.7195],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923730',
        NAME: 'Raymore-Peculiar R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244622805,
        AWATER: 2029312,
        INTPTLAT: '+38.7446747',
        INTPTLON: '-094.4556921',
        ELSDLEA: '',
        UNSDLEA: '23730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4714, 38.5474],
            [-94.4651, 38.5692],
            [-94.2847, 38.5871],
            [-94.2791, 38.5182],
            [-94.1477, 38.4507],
            [-94.1766, 38.4459],
            [-94.5022, 38.4754],
            [-94.4714, 38.5474],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903200',
        NAME: 'Archie R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270581592,
        AWATER: 2322437,
        INTPTLAT: '+38.4938215',
        INTPTLON: '-094.3431288',
        ELSDLEA: '',
        UNSDLEA: '03200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5091, 38.432],
            [-94.5022, 38.4754],
            [-94.1766, 38.4459],
            [-94.2367, 38.334],
            [-94.4575, 38.3387],
            [-94.5091, 38.432],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2902850',
        NAME: 'Adrian R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334872440,
        AWATER: 2073898,
        INTPTLAT: '+38.4083158',
        INTPTLON: '-094.3475202',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6133, 38.4342],
            [-94.5091, 38.432],
            [-94.4575, 38.3387],
            [-94.4927, 38.202],
            [-94.6126, 38.2122],
            [-94.6127, 38.2868],
            [-94.6132, 38.3887],
            [-94.6133, 38.4342],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920820',
        NAME: 'Miami R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 283972960,
        AWATER: 2872250,
        INTPTLAT: '+38.3190094',
        INTPTLON: '-094.5400488',
        ELSDLEA: '',
        UNSDLEA: '20820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6122, 38.5655],
            [-94.4714, 38.5474],
            [-94.5022, 38.4754],
            [-94.5091, 38.432],
            [-94.6133, 38.4342],
            [-94.6122, 38.5655],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911070',
        NAME: 'Drexel R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143674255,
        AWATER: 932422,
        INTPTLAT: '+38.5080946',
        INTPTLON: '-094.5411948',
        ELSDLEA: '',
        UNSDLEA: '11070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7024, 39.9607],
            [-93.6374, 39.9621],
            [-93.6316, 39.9622],
            [-93.6149, 39.9628],
            [-93.5412, 39.8772],
            [-93.3893, 39.8797],
            [-93.3613, 39.9239],
            [-93.3648, 39.7038],
            [-93.3305, 39.7036],
            [-93.3944, 39.6447],
            [-93.5013, 39.6699],
            [-93.5907, 39.6281],
            [-93.5757, 39.7514],
            [-93.6146, 39.7881],
            [-93.7591, 39.8075],
            [-93.7616, 39.9308],
            [-93.7024, 39.9607],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908760',
        NAME: 'Chillicothe R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 757302140,
        AWATER: 9595414,
        INTPTLAT: '+39.8018528',
        INTPTLON: '-093.5428779',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8173, 37.0787],
            [-91.6712, 37.0489],
            [-91.5461, 37.0768],
            [-91.4279, 37.0453],
            [-91.4235, 36.929],
            [-91.3693, 36.8857],
            [-91.6582, 36.8736],
            [-91.7575, 36.9009],
            [-91.7717, 36.901],
            [-91.7845, 36.9016],
            [-91.8553, 36.9781],
            [-91.8173, 37.0787],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921540',
        NAME: 'Mountain View-Birch Tree R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 724135717,
        AWATER: 532653,
        INTPTLAT: '+36.9802611',
        INTPTLON: '-091.6059245',
        ELSDLEA: '',
        UNSDLEA: '21540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3475, 38.3797],
            [-90.3714, 38.323],
            [-90.4224, 38.3241],
            [-90.3475, 38.3797],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932100',
        NAME: 'Windsor C-1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38780294,
        AWATER: 2220008,
        INTPTLAT: '+38.3629816',
        INTPTLON: '-090.3936023',
        ELSDLEA: '',
        UNSDLEA: '32100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7339, 38.5489],
            [-90.7323, 38.6393],
            [-90.6605, 38.6886],
            [-90.5718, 38.6811],
            [-90.5108, 38.5461],
            [-90.4545, 38.5551],
            [-90.4364, 38.5493],
            [-90.4631, 38.5019],
            [-90.5841, 38.5017],
            [-90.6395, 38.4144],
            [-90.7116, 38.4156],
            [-90.682, 38.4839],
            [-90.7339, 38.5489],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926850',
        NAME: 'Rockwood R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398176801,
        AWATER: 8841579,
        INTPTLAT: '+38.5599129',
        INTPTLON: '-090.6267890',
        ELSDLEA: '',
        UNSDLEA: '26850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4526, 37.3162],
            [-90.4385, 37.3161],
            [-90.2177, 37.315],
            [-90.2197, 37.2216],
            [-90.2204, 37.1747],
            [-90.2232, 37.0865],
            [-90.1112, 37.0412],
            [-90.2886, 36.9239],
            [-90.3533, 36.8906],
            [-90.425, 36.9259],
            [-90.5695, 36.9271],
            [-90.6299, 36.993],
            [-90.6026, 37.0984],
            [-90.5001, 37.0757],
            [-90.4638, 37.1257],
            [-90.4526, 37.3162],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913380',
        NAME: 'Greenville R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1216367434,
        AWATER: 31353893,
        INTPTLAT: '+37.1144480',
        INTPTLON: '-090.3798208',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5695, 36.9271],
            [-90.425, 36.9259],
            [-90.3533, 36.8906],
            [-90.2985, 36.7933],
            [-90.4018, 36.6335],
            [-90.5736, 36.7848],
            [-90.5695, 36.9271],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925450',
        NAME: 'Poplar Bluff R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 543871227,
        AWATER: 3584699,
        INTPTLAT: '+36.7952299',
        INTPTLON: '-090.4465174',
        ELSDLEA: '',
        UNSDLEA: '25450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2436, 38.6584],
            [-91.1995, 38.6135],
            [-91.1998, 38.5958],
            [-91.3121, 38.5982],
            [-91.2436, 38.6584],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921960',
        NAME: 'New Haven School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49100577,
        AWATER: 2611879,
        INTPTLAT: '+38.6255648',
        INTPTLON: '-091.2635493',
        ELSDLEA: '',
        UNSDLEA: '21960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6725, 37.7781],
            [-90.5706, 37.7934],
            [-90.5639, 37.7722],
            [-90.5347, 37.6436],
            [-90.6465, 37.6901],
            [-90.646, 37.7047],
            [-90.6725, 37.7781],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905130',
        NAME: 'Bismarck R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166811013,
        AWATER: 1822389,
        INTPTLAT: '+37.7297336',
        INTPTLON: '-090.6026323',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0737, 38.2581],
            [-94.0705, 38.3305],
            [-94.0336, 38.3025],
            [-93.8506, 38.2963],
            [-93.8544, 38.2089],
            [-94.0499, 38.214],
            [-94.0737, 38.2581],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921360',
        NAME: 'Montrose R-XIV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197647522,
        AWATER: 3844903,
        INTPTLAT: '+38.2591226',
        INTPTLON: '-093.9695613',
        ELSDLEA: '',
        UNSDLEA: '21360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7348, 38.4593],
            [-93.7006, 38.4889],
            [-93.5578, 38.4994],
            [-93.5892, 38.3762],
            [-93.6261, 38.3773],
            [-93.7348, 38.4593],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906480',
        NAME: 'Calhoun R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158177555,
        AWATER: 3318842,
        INTPTLAT: '+38.4396100',
        INTPTLON: '-093.6329942',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7218, 39.2241],
            [-90.7013, 39.0699],
            [-90.8874, 39.0906],
            [-90.9534, 39.098],
            [-90.9644, 39.1788],
            [-90.8954, 39.2251],
            [-90.7218, 39.2241],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911400',
        NAME: 'Elsberry R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314322623,
        AWATER: 15609764,
        INTPTLAT: '+39.1359411',
        INTPTLON: '-090.8167346',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8874, 39.0906],
            [-90.7013, 39.0699],
            [-90.6898, 39.0115],
            [-90.6632, 38.9342],
            [-90.7782, 38.9225],
            [-90.827, 38.879],
            [-90.7831, 38.9645],
            [-90.856, 38.9877],
            [-90.8874, 39.0906],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932190',
        NAME: 'Winfield R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223710842,
        AWATER: 10255194,
        INTPTLAT: '+39.0005111',
        INTPTLON: '-090.7728452',
        ELSDLEA: '',
        UNSDLEA: '32190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2629, 39.0529],
            [-91.07, 39.0435],
            [-90.9534, 39.098],
            [-90.8874, 39.0906],
            [-90.856, 38.9877],
            [-90.7831, 38.9645],
            [-90.827, 38.879],
            [-90.869, 38.8884],
            [-90.9576, 38.8933],
            [-91.0911, 38.8942],
            [-91.2645, 38.9848],
            [-91.2629, 39.0529],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930450',
        NAME: 'Troy R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610100081,
        AWATER: 6451899,
        INTPTLAT: '+38.9861456',
        INTPTLON: '-091.0161926',
        ELSDLEA: '',
        UNSDLEA: '30450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0536, 39.6153],
            [-95.04, 39.6488],
            [-94.9918, 39.6768],
            [-94.8815, 39.6614],
            [-94.8662, 39.532],
            [-94.8724, 39.532],
            [-95.1029, 39.5333],
            [-95.0536, 39.6153],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910590',
        NAME: 'Buchanan County R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249195571,
        AWATER: 5163948,
        INTPTLAT: '+39.5924585',
        INTPTLON: '-094.9573140',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8815, 39.6614],
            [-94.7666, 39.7191],
            [-94.7034, 39.6871],
            [-94.7178, 39.5869],
            [-94.7774, 39.5319],
            [-94.8662, 39.532],
            [-94.8815, 39.6614],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911940',
        NAME: 'Mid-Buchanan County R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209174846,
        AWATER: 1762036,
        INTPTLAT: '+39.6239413',
        INTPTLON: '-094.8002200',
        ELSDLEA: '',
        UNSDLEA: '11940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7556, 38.3301],
            [-90.7116, 38.4156],
            [-90.6395, 38.4144],
            [-90.5841, 38.5017],
            [-90.4631, 38.5019],
            [-90.4546, 38.5018],
            [-90.513, 38.4428],
            [-90.5141, 38.3544],
            [-90.6307, 38.3177],
            [-90.7735, 38.2407],
            [-90.7556, 38.3301],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922890',
        NAME: 'Northwest R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320625519,
        AWATER: 1940141,
        INTPTLAT: '+38.3893570',
        INTPTLON: '-090.6176018',
        ELSDLEA: '',
        UNSDLEA: '22890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5484, 39.0266],
            [-92.5313, 39.0566],
            [-92.3213, 39.0695],
            [-92.1673, 39.0484],
            [-92.1481, 39.0365],
            [-92.1453, 38.9705],
            [-92.1479, 38.8473],
            [-92.3279, 38.8398],
            [-92.3825, 38.7909],
            [-92.4624, 38.8645],
            [-92.4805, 38.9089],
            [-92.5581, 38.9707],
            [-92.5484, 39.0266],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2901000',
        NAME: 'Columbia 93 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 773220067,
        AWATER: 6591361,
        INTPTLAT: '+38.9503806',
        INTPTLON: '-092.3430263',
        ELSDLEA: '',
        UNSDLEA: '01000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3825, 38.7909],
            [-92.3279, 38.8398],
            [-92.1479, 38.8473],
            [-92.1487, 38.8466],
            [-92.1354, 38.8156],
            [-92.2191, 38.6719],
            [-92.3355, 38.6732],
            [-92.3825, 38.7909],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928560',
        NAME: 'Southern Boone County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317465121,
        AWATER: 5747008,
        INTPTLAT: '+38.7491494',
        INTPTLON: '-092.2751140',
        ELSDLEA: '',
        UNSDLEA: '28560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.394, 38.2481],
            [-90.3677, 38.2581],
            [-90.3558, 38.2169],
            [-90.384, 38.2088],
            [-90.394, 38.2481],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910380',
        NAME: 'Crystal City 47 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9766157,
        AWATER: 1361220,
        INTPTLAT: '+38.2351280',
        INTPTLON: '-090.3812809',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4546, 38.5018],
            [-90.4053, 38.5012],
            [-90.3414, 38.3883],
            [-90.3475, 38.3797],
            [-90.4224, 38.3241],
            [-90.4618, 38.3081],
            [-90.5141, 38.3544],
            [-90.513, 38.4428],
            [-90.4546, 38.5018],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912300',
        NAME: 'Fox C-6 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190245439,
        AWATER: 2149432,
        INTPTLAT: '+38.4033797',
        INTPTLON: '-090.4400655',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7735, 38.2407],
            [-90.6307, 38.3177],
            [-90.6317, 38.1491],
            [-90.6883, 38.1336],
            [-90.7133, 38.1358],
            [-90.7155, 38.138],
            [-90.7164, 38.139],
            [-90.7735, 38.2407],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913170',
        NAME: 'Grandview R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193648236,
        AWATER: 378000,
        INTPTLAT: '+38.2303133',
        INTPTLON: '-090.7123957',
        ELSDLEA: '',
        UNSDLEA: '13170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4618, 38.3081],
            [-90.4224, 38.3241],
            [-90.3714, 38.323],
            [-90.3677, 38.2581],
            [-90.394, 38.2481],
            [-90.4437, 38.2567],
            [-90.4618, 38.3081],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914250',
        NAME: 'Dunklin R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52286325,
        AWATER: 2603405,
        INTPTLAT: '+38.2890627',
        INTPTLON: '-090.4107975',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6029, 36.7614],
            [-92.5074, 36.8016],
            [-92.3672, 36.7993],
            [-92.3161, 36.6668],
            [-92.2254, 36.633],
            [-92.2595, 36.498],
            [-92.6157, 36.4982],
            [-92.5523, 36.6108],
            [-92.621, 36.6226],
            [-92.6029, 36.7614],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912600',
        NAME: 'Gainesville R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 877048921,
        AWATER: 5627305,
        INTPTLAT: '+36.6440894',
        INTPTLON: '-092.4265901',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7339, 38.5489],
            [-90.682, 38.4839],
            [-90.7116, 38.4156],
            [-90.7556, 38.3301],
            [-90.8719, 38.3667],
            [-90.9079, 38.3994],
            [-90.9055, 38.4383],
            [-90.9101, 38.5124],
            [-90.8021, 38.5074],
            [-90.7339, 38.5489],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923460',
        NAME: 'Meramec Valley R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290519921,
        AWATER: 3116628,
        INTPTLAT: '+38.4357174',
        INTPTLON: '-090.7946730',
        ELSDLEA: '',
        UNSDLEA: '23460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9079, 38.3994],
            [-90.8719, 38.3667],
            [-90.934, 38.2052],
            [-90.9522, 38.2054],
            [-91.087, 38.2717],
            [-91.1109, 38.3326],
            [-91.1033, 38.3614],
            [-90.9079, 38.3994],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929100',
        NAME: 'St. Clair R-XIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287646955,
        AWATER: 656178,
        INTPTLAT: '+38.3165986',
        INTPTLON: '-090.9663767',
        ELSDLEA: '',
        UNSDLEA: '29100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4992, 40.5819],
            [-95.4608, 40.5815],
            [-95.3739, 40.5805],
            [-95.259, 40.5791],
            [-95.2023, 40.5785],
            [-95.1776, 40.3922],
            [-95.2358, 40.3642],
            [-95.4253, 40.3872],
            [-95.4629, 40.4266],
            [-95.4992, 40.5819],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929940',
        NAME: 'Tarkio R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 523332966,
        AWATER: 156863,
        INTPTLAT: '+40.4877337',
        INTPTLON: '-095.3306829',
        ELSDLEA: '',
        UNSDLEA: '29940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3857, 38.2764],
            [-92.3067, 38.2556],
            [-92.233, 38.3277],
            [-92.1957, 38.2922],
            [-92.1776, 38.2919],
            [-92.1717, 38.2917],
            [-92.1971, 38.2069],
            [-92.2422, 38.1372],
            [-92.327, 38.1629],
            [-92.3857, 38.2764],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929130',
        NAME: 'St. Elizabeth R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217344433,
        AWATER: 1582079,
        INTPTLAT: '+38.2261101',
        INTPTLON: '-092.2858039',
        ELSDLEA: '',
        UNSDLEA: '29130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.233, 38.3277],
            [-92.2247, 38.3357],
            [-92.1493, 38.4312],
            [-92.0085, 38.4944],
            [-92.0259, 38.552],
            [-91.9108, 38.6215],
            [-91.8269, 38.5928],
            [-91.9021, 38.559],
            [-91.9248, 38.4189],
            [-91.8174, 38.3948],
            [-91.8627, 38.2929],
            [-92.1717, 38.2917],
            [-92.1776, 38.2919],
            [-92.1957, 38.2922],
            [-92.233, 38.3277],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931830',
        NAME: 'Osage County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 709166797,
        AWATER: 8860342,
        INTPTLAT: '+38.4125871',
        INTPTLON: '-091.9980425',
        ELSDLEA: '',
        UNSDLEA: '31830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3478, 40.3028],
            [-92.0341, 40.3131],
            [-91.9497, 40.3008],
            [-91.9507, 40.262],
            [-91.9527, 39.9494],
            [-92.2879, 39.9525],
            [-92.3451, 40.0372],
            [-92.3478, 40.3028],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911280',
        NAME: 'Knox County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1286025205,
        AWATER: 7266640,
        INTPTLAT: '+40.1284564',
        INTPTLON: '-092.1560353',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9507, 40.262],
            [-91.8936, 40.2561],
            [-91.7982, 40.1716],
            [-91.6681, 40.2085],
            [-91.6144, 40.099],
            [-91.4973, 40.0771],
            [-91.4881, 40.0246],
            [-91.4355, 39.9452],
            [-91.6176, 39.9224],
            [-91.7583, 39.9477],
            [-91.8459, 39.9195],
            [-91.9527, 39.9494],
            [-91.9507, 40.262],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918460',
        NAME: 'Lewis County C-1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1059710954,
        AWATER: 8690560,
        INTPTLAT: '+40.0678744',
        INTPTLON: '-091.7538065',
        ELSDLEA: '',
        UNSDLEA: '18460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8796, 37.4476],
            [-89.8595, 37.4964],
            [-89.6704, 37.4755],
            [-89.6644, 37.5907],
            [-89.6509, 37.5979],
            [-89.5219, 37.5662],
            [-89.4821, 37.4804],
            [-89.5416, 37.4887],
            [-89.5467, 37.3527],
            [-89.6567, 37.2755],
            [-89.8623, 37.2923],
            [-89.8796, 37.4476],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915600',
        NAME: 'Jackson R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728521736,
        AWATER: 4316127,
        INTPTLAT: '+37.4201508',
        INTPTLON: '-089.6994232',
        ELSDLEA: '',
        UNSDLEA: '15600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6567, 37.2755],
            [-89.5467, 37.3527],
            [-89.4891, 37.3332],
            [-89.4887, 37.2515],
            [-89.5835, 37.2331],
            [-89.6203, 37.233],
            [-89.6567, 37.2755],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907120',
        NAME: 'Cape Girardeau 63 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134267043,
        AWATER: 6362275,
        INTPTLAT: '+37.2873673',
        INTPTLON: '-089.5743343',
        ELSDLEA: '',
        UNSDLEA: '07120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8611, 37.5993],
            [-89.6644, 37.5907],
            [-89.6704, 37.4755],
            [-89.8595, 37.4964],
            [-89.8611, 37.5993],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923070',
        NAME: 'Oak Ridge R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223987310,
        AWATER: 211068,
        INTPTLAT: '+37.5436013',
        INTPTLON: '-089.7633340',
        ELSDLEA: '',
        UNSDLEA: '23070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8736, 37.2756],
            [-89.8717, 37.2798],
            [-89.881, 37.2917],
            [-89.8623, 37.2923],
            [-89.6567, 37.2755],
            [-89.6203, 37.233],
            [-89.6205, 37.2183],
            [-89.7218, 37.1406],
            [-89.7652, 37.1261],
            [-89.8181, 37.1261],
            [-89.8667, 37.1844],
            [-89.8736, 37.2756],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910620',
        NAME: 'Delta R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273449303,
        AWATER: 1335546,
        INTPTLAT: '+37.2266034',
        INTPTLON: '-089.7779494',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4972, 40.1247],
            [-92.4594, 40.1247],
            [-92.4606, 40.3452],
            [-92.35, 40.3465],
            [-92.3478, 40.3028],
            [-92.3451, 40.0372],
            [-92.4678, 40.037],
            [-92.4972, 40.1247],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905790',
        NAME: 'Adair County R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344065595,
        AWATER: 60267,
        INTPTLAT: '+40.1887706',
        INTPTLON: '-092.4023700',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7367, 38.2207],
            [-92.6317, 38.296],
            [-92.5254, 38.2711],
            [-92.4948, 38.1684],
            [-92.4107, 38.1559],
            [-92.4613, 38.0219],
            [-92.516, 38.0223],
            [-92.6409, 38.1857],
            [-92.7645, 38.1896],
            [-92.7367, 38.2207],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927630',
        NAME: 'School of The Osage School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350035767,
        AWATER: 33649072,
        INTPTLAT: '+38.1689803',
        INTPTLON: '-092.5759506',
        ELSDLEA: '',
        UNSDLEA: '27630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0987, 39.2862],
            [-94.0283, 39.227],
            [-94.0242, 39.1545],
            [-94.1048, 39.1435],
            [-94.2125, 39.2071],
            [-94.1839, 39.2992],
            [-94.0987, 39.2862],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923220',
        NAME: 'Orrick R-XI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185647501,
        AWATER: 3834718,
        INTPTLAT: '+39.2178231',
        INTPTLON: '-094.1226758',
        ELSDLEA: '',
        UNSDLEA: '23220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1566, 39.5698],
            [-94.0672, 39.6199],
            [-93.9832, 39.5975],
            [-93.9271, 39.5248],
            [-93.9379, 39.4376],
            [-94.0604, 39.4639],
            [-94.1533, 39.5262],
            [-94.1566, 39.5698],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925410',
        NAME: 'Polo R-VII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254312459,
        AWATER: 1037830,
        INTPTLAT: '+39.5285088',
        INTPTLON: '-094.0348690',
        ELSDLEA: '',
        UNSDLEA: '25410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5107, 39.644],
            [-91.4284, 39.6486],
            [-91.3235, 39.599],
            [-91.178, 39.5982],
            [-91.1667, 39.5625],
            [-91.2344, 39.5557],
            [-91.4604, 39.4507],
            [-91.4604, 39.4204],
            [-91.6244, 39.3298],
            [-91.7185, 39.3245],
            [-91.7377, 39.3393],
            [-91.7623, 39.4624],
            [-91.7069, 39.5181],
            [-91.5691, 39.5842],
            [-91.5107, 39.644],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925710',
        NAME: 'Ralls County R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794102029,
        AWATER: 23930129,
        INTPTLAT: '+39.4982148',
        INTPTLON: '-091.5337092',
        ELSDLEA: '',
        UNSDLEA: '25710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3048, 39.4984],
            [-92.3009, 39.6053],
            [-92.2993, 39.6053],
            [-92.1841, 39.604],
            [-92.1897, 39.4454],
            [-92.126, 39.4147],
            [-92.1378, 39.3569],
            [-92.2272, 39.345],
            [-92.2456, 39.3457],
            [-92.1922, 39.3815],
            [-92.3059, 39.4586],
            [-92.3048, 39.4984],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919840',
        NAME: 'Madison C-3 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257943509,
        AWATER: 1009356,
        INTPTLAT: '+39.4937020',
        INTPTLON: '-092.2264718',
        ELSDLEA: '',
        UNSDLEA: '19840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9122, 39.601],
            [-91.9026, 39.6954],
            [-91.845, 39.7634],
            [-91.8441, 39.7964],
            [-91.6764, 39.7731],
            [-91.6652, 39.7224],
            [-91.5105, 39.6854],
            [-91.5107, 39.644],
            [-91.5691, 39.5842],
            [-91.7069, 39.5181],
            [-91.7623, 39.4624],
            [-91.9122, 39.601],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921210',
        NAME: 'Monroe City R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 749313099,
        AWATER: 36970946,
        INTPTLAT: '+39.6337195',
        INTPTLON: '-091.7431544',
        ELSDLEA: '',
        UNSDLEA: '21210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2667, 39.0826],
            [-91.2602, 39.1398],
            [-91.149, 39.227],
            [-91.0096, 39.1653],
            [-90.9644, 39.1788],
            [-90.9534, 39.098],
            [-91.07, 39.0435],
            [-91.2629, 39.0529],
            [-91.2667, 39.0826],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928290',
        NAME: 'Silex R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361065187,
        AWATER: 3109505,
        INTPTLAT: '+39.1151349',
        INTPTLON: '-091.1236726',
        ELSDLEA: '',
        UNSDLEA: '28290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2344, 39.5557],
            [-91.1667, 39.5625],
            [-91.0981, 39.5361],
            [-91.0784, 39.5062],
            [-91.0244, 39.4392],
            [-91.0629, 39.3576],
            [-91.1796, 39.3963],
            [-91.2344, 39.5557],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919260',
        NAME: 'Louisiana R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210453270,
        AWATER: 12059265,
        INTPTLAT: '+39.4751436',
        INTPTLON: '-091.1340263',
        ELSDLEA: '',
        UNSDLEA: '19260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2344, 39.5557],
            [-91.1796, 39.3963],
            [-91.0629, 39.3576],
            [-91.0631, 39.3138],
            [-91.0798, 39.2252],
            [-91.149, 39.227],
            [-91.2602, 39.1398],
            [-91.4082, 39.1864],
            [-91.4011, 39.4052],
            [-91.4604, 39.4204],
            [-91.4604, 39.4507],
            [-91.2344, 39.5557],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905660',
        NAME: 'Bowling Green R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 965448539,
        AWATER: 5448478,
        INTPTLAT: '+39.3362424',
        INTPTLON: '-091.2614693',
        ELSDLEA: '',
        UNSDLEA: '05660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8262, 39.2448],
            [-94.6586, 39.2967],
            [-94.601, 39.2246],
            [-94.6017, 39.1596],
            [-94.7891, 39.2078],
            [-94.8262, 39.2448],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923550',
        NAME: 'Park Hill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184348141,
        AWATER: 5181537,
        INTPTLAT: '+39.2230589',
        INTPTLON: '-094.6997706',
        ELSDLEA: '',
        UNSDLEA: '23550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7034, 39.6871],
            [-94.6024, 39.7763],
            [-94.5536, 39.7472],
            [-94.5081, 39.6677],
            [-94.544, 39.631],
            [-94.5286, 39.5207],
            [-94.6012, 39.4921],
            [-94.6064, 39.4923],
            [-94.7178, 39.5869],
            [-94.7034, 39.6871],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911250',
        NAME: 'East Buchanan County C-1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381870097,
        AWATER: 2008312,
        INTPTLAT: '+39.6326314',
        INTPTLON: '-094.6168900',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0636, 40.4113],
            [-93.8805, 40.4384],
            [-93.8218, 40.3802],
            [-93.8791, 40.2932],
            [-94.0634, 40.3823],
            [-94.0636, 40.4113],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926490',
        NAME: 'Ridgeway R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173076217,
        AWATER: 1144418,
        INTPTLAT: '+40.3707765',
        INTPTLON: '-093.9248710',
        ELSDLEA: '',
        UNSDLEA: '26490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8085, 39.8289],
            [-93.7591, 39.8075],
            [-93.7772, 39.6987],
            [-93.8609, 39.6705],
            [-93.8702, 39.6995],
            [-93.8705, 39.7944],
            [-93.8085, 39.8289],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905850',
        NAME: 'Breckenridge R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123801633,
        AWATER: 794884,
        INTPTLAT: '+39.7951924',
        INTPTLON: '-093.8403701',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0532, 39.9558],
            [-93.883, 39.9976],
            [-93.8361, 39.961],
            [-93.8085, 39.8289],
            [-93.8705, 39.7944],
            [-93.9457, 39.7876],
            [-93.9906, 39.873],
            [-94.0861, 39.8438],
            [-94.1191, 39.8986],
            [-94.0532, 39.9558],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912660',
        NAME: 'Gallatin R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350881174,
        AWATER: 4670859,
        INTPTLAT: '+39.8984010',
        INTPTLON: '-093.9361830',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.883, 39.9976],
            [-93.8776, 40.0651],
            [-93.7646, 40.0747],
            [-93.7024, 39.9607],
            [-93.7616, 39.9308],
            [-93.7591, 39.8075],
            [-93.8085, 39.8289],
            [-93.8361, 39.961],
            [-93.883, 39.9976],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930390',
        NAME: 'Tri-County R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239607281,
        AWATER: 1516903,
        INTPTLAT: '+39.9698371',
        INTPTLON: '-093.7949415',
        ELSDLEA: '',
        UNSDLEA: '30390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3672, 36.7993],
            [-92.3473, 36.8756],
            [-92.2367, 36.9282],
            [-92.1789, 36.9267],
            [-92.1689, 36.9266],
            [-92.1642, 36.9266],
            [-92.2028, 36.8397],
            [-92.0947, 36.8375],
            [-92.113, 36.7772],
            [-92.1147, 36.7189],
            [-92.1688, 36.7345],
            [-92.2254, 36.633],
            [-92.3161, 36.6668],
            [-92.3672, 36.7993],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910950',
        NAME: 'Dora R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450688190,
        AWATER: 1968653,
        INTPTLAT: '+36.7854583',
        INTPTLON: '-092.2452126',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7692, 36.6247],
            [-92.621, 36.6226],
            [-92.5523, 36.6108],
            [-92.6157, 36.4982],
            [-92.6306, 36.4982],
            [-92.7622, 36.4979],
            [-92.7723, 36.4977],
            [-92.7692, 36.6247],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923400',
        NAME: 'Lutie R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208494232,
        AWATER: 15881716,
        INTPTLAT: '+36.5574777',
        INTPTLON: '-092.6767927',
        ELSDLEA: '',
        UNSDLEA: '23400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5254, 38.2711],
            [-92.4673, 38.2981],
            [-92.3857, 38.2764],
            [-92.327, 38.1629],
            [-92.4107, 38.1559],
            [-92.4948, 38.1684],
            [-92.5254, 38.2711],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930510',
        NAME: 'Miller County R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195387928,
        AWATER: 2720774,
        INTPTLAT: '+38.2309462',
        INTPTLON: '-092.4321095',
        ELSDLEA: '',
        UNSDLEA: '30510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4107, 38.1559],
            [-92.327, 38.1629],
            [-92.2422, 38.1372],
            [-92.1971, 38.2069],
            [-92.1822, 38.1315],
            [-92.1854, 38.0163],
            [-92.4063, 38.0212],
            [-92.4613, 38.0219],
            [-92.4107, 38.1559],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915420',
        NAME: 'Iberia R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374627734,
        AWATER: 77078,
        INTPTLAT: '+38.0914045',
        INTPTLON: '-092.3217776',
        ELSDLEA: '',
        UNSDLEA: '15420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4338, 40.0042],
            [-94.5054, 39.9223],
            [-94.6043, 39.915],
            [-94.7019, 40.0046],
            [-94.5761, 40.0393],
            [-94.5464, 39.9898],
            [-94.4338, 40.0042],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930600',
        NAME: 'Union Star R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173439482,
        AWATER: 1075324,
        INTPTLAT: '+39.9722987',
        INTPTLON: '-094.5748301',
        ELSDLEA: '',
        UNSDLEA: '30600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.209, 40.2623],
            [-95.1787, 40.3342],
            [-94.974, 40.3325],
            [-94.905, 40.2263],
            [-94.8588, 40.128],
            [-95.0499, 40.0752],
            [-95.1074, 40.1015],
            [-95.1075, 40.1519],
            [-95.209, 40.2623],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922560',
        NAME: 'Nodaway-Holt R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527069291,
        AWATER: 710014,
        INTPTLAT: '+40.2218975',
        INTPTLON: '-095.0478224',
        ELSDLEA: '',
        UNSDLEA: '22560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.728, 40.1268],
            [-94.6527, 40.1263],
            [-94.4457, 40.1257],
            [-94.2743, 40.104],
            [-94.2564, 40.0366],
            [-94.3936, 40.0392],
            [-94.4338, 40.0042],
            [-94.5464, 39.9898],
            [-94.5761, 40.0393],
            [-94.7019, 40.0046],
            [-94.7263, 40.0375],
            [-94.728, 40.1268],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916590',
        NAME: 'King City R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444253727,
        AWATER: 2899014,
        INTPTLAT: '+40.0754011',
        INTPTLON: '-094.5021899',
        ELSDLEA: '',
        UNSDLEA: '16590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0499, 40.0752],
            [-94.8588, 40.128],
            [-94.728, 40.1268],
            [-94.7263, 40.0375],
            [-94.7736, 40.0029],
            [-95.0046, 39.9821],
            [-95.0499, 40.0752],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922710',
        NAME: 'North Andrew County R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341476787,
        AWATER: 1609771,
        INTPTLAT: '+40.0625968',
        INTPTLON: '-094.8801693',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7622, 40.2716],
            [-94.7618, 40.3006],
            [-94.6027, 40.3002],
            [-94.6046, 40.1989],
            [-94.6615, 40.1769],
            [-94.7622, 40.2716],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916200',
        NAME: 'Jefferson C-123 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150528992,
        AWATER: 52097,
        INTPTLAT: '+40.2471449',
        INTPTLON: '-094.6830170',
        ELSDLEA: '',
        UNSDLEA: '16200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9289, 40.4331],
            [-94.7765, 40.4165],
            [-94.7618, 40.3006],
            [-94.7622, 40.2716],
            [-94.905, 40.2263],
            [-94.974, 40.3325],
            [-94.9289, 40.4331],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920490',
        NAME: 'Maryville R-II Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324365835,
        AWATER: 612152,
        INTPTLAT: '+40.3426688',
        INTPTLON: '-094.8788717',
        ELSDLEA: '',
        UNSDLEA: '20490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0948, 37.9579],
            [-90.9408, 37.9675],
            [-90.9389, 38.0443],
            [-91.0484, 38.0535],
            [-91.0502, 38.133],
            [-90.9726, 38.1501],
            [-90.7731, 38.0854],
            [-90.8223, 38.0572],
            [-90.7714, 37.9642],
            [-90.6413, 37.9802],
            [-90.6229, 37.9779],
            [-90.6421, 37.9176],
            [-90.7214, 37.8427],
            [-90.9242, 37.8768],
            [-91.0964, 37.8708],
            [-91.0948, 37.9579],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925500',
        NAME: 'Potosi R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 749120713,
        AWATER: 3142550,
        INTPTLAT: '+37.9708845',
        INTPTLON: '-090.8803286',
        ELSDLEA: '',
        UNSDLEA: '25500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0964, 37.8708],
            [-90.9242, 37.8768],
            [-90.7214, 37.8427],
            [-90.6725, 37.7781],
            [-90.646, 37.7047],
            [-90.7006, 37.7362],
            [-90.9653, 37.7385],
            [-91.1, 37.74],
            [-91.0964, 37.8708],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930720',
        NAME: 'Valley R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 508061822,
        AWATER: 1691292,
        INTPTLAT: '+37.7909922',
        INTPTLON: '-090.9074306',
        ELSDLEA: '',
        UNSDLEA: '30720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7214, 37.8427],
            [-90.6421, 37.9176],
            [-90.5514, 37.8476],
            [-90.5706, 37.7934],
            [-90.6725, 37.7781],
            [-90.7214, 37.8427],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918240',
        NAME: 'West St. Francis County R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127369673,
        AWATER: 1200965,
        INTPTLAT: '+37.8440331',
        INTPTLON: '-090.6525433',
        ELSDLEA: '',
        UNSDLEA: '18240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9511, 37.0132],
            [-89.8176, 37.0825],
            [-89.8181, 37.1261],
            [-89.7652, 37.1261],
            [-89.7436, 37.0198],
            [-89.6917, 36.9389],
            [-89.6912, 36.9033],
            [-89.8045, 36.936],
            [-89.943, 36.9694],
            [-89.9511, 37.0132],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904530',
        NAME: 'Bell City R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278095978,
        AWATER: 1330315,
        INTPTLAT: '+37.0024700',
        INTPTLON: '-089.8179403',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.135, 36.8124],
            [-90.0784, 36.8625],
            [-89.9207, 36.846],
            [-89.8853, 36.7662],
            [-89.9246, 36.7153],
            [-90.1484, 36.7106],
            [-90.135, 36.8124],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910770',
        NAME: 'Dexter R-XI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324579443,
        AWATER: 2373741,
        INTPTLAT: '+36.7859022',
        INTPTLON: '-090.0220249',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2204, 37.1747],
            [-90.2197, 37.2216],
            [-89.9689, 37.1741],
            [-89.9957, 37.0401],
            [-90.0367, 37.0344],
            [-90.1112, 37.0412],
            [-90.2232, 37.0865],
            [-90.2204, 37.1747],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932490',
        NAME: 'Zalma R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322845654,
        AWATER: 5634378,
        INTPTLAT: '+37.1317569',
        INTPTLON: '-090.0922582',
        ELSDLEA: '',
        UNSDLEA: '32490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1112, 37.0412],
            [-90.0367, 37.0344],
            [-90.0056, 37.0149],
            [-90.0784, 36.8625],
            [-90.135, 36.8124],
            [-90.1979, 36.8041],
            [-90.2886, 36.9239],
            [-90.1112, 37.0412],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925650',
        NAME: 'Puxico R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385001646,
        AWATER: 6934093,
        INTPTLAT: '+36.9314837',
        INTPTLON: '-090.1598557',
        ELSDLEA: '',
        UNSDLEA: '25650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9207, 36.846],
            [-89.8068, 36.8774],
            [-89.8045, 36.936],
            [-89.6912, 36.9033],
            [-89.7023, 36.7865],
            [-89.7566, 36.7149],
            [-89.8271, 36.7149],
            [-89.9246, 36.7153],
            [-89.8853, 36.7662],
            [-89.9207, 36.846],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926400',
        NAME: 'Richland R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 345965606,
        AWATER: 1395429,
        INTPTLAT: '+36.7873397',
        INTPTLON: '-089.7942957',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7429, 40.038],
            [-92.6302, 40.0383],
            [-92.4972, 40.1247],
            [-92.4678, 40.037],
            [-92.3451, 40.0372],
            [-92.2879, 39.9525],
            [-92.2889, 39.9089],
            [-92.4336, 39.9398],
            [-92.5601, 39.9387],
            [-92.6235, 39.9974],
            [-92.7347, 40.0049],
            [-92.7429, 40.038],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916950',
        NAME: 'La Plata R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385687544,
        AWATER: 1229979,
        INTPTLAT: '+40.0032506',
        INTPTLON: '-092.5016651',
        ELSDLEA: '',
        UNSDLEA: '16950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9533, 40.5756],
            [-94.9149, 40.5751],
            [-94.6701, 40.5717],
            [-94.6977, 40.4731],
            [-94.7765, 40.4165],
            [-94.9289, 40.4331],
            [-94.9533, 40.5756],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921690',
        NAME: 'North Nodaway County R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340177013,
        AWATER: 266161,
        INTPTLAT: '+40.5129205',
        INTPTLON: '-094.8123170',
        ELSDLEA: '',
        UNSDLEA: '21690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5861, 38.8706],
            [-90.4508, 38.9678],
            [-90.2758, 38.9235],
            [-90.121, 38.8529],
            [-90.1177, 38.8057],
            [-90.2987, 38.8878],
            [-90.4619, 38.8048],
            [-90.5679, 38.8234],
            [-90.5861, 38.8706],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923160',
        NAME: 'Orchard Farm R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315102539,
        AWATER: 47932290,
        INTPTLAT: '+38.8844475',
        INTPTLON: '-090.3797328',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9576, 38.8933],
            [-90.869, 38.8884],
            [-90.855, 38.8328],
            [-90.7355, 38.8056],
            [-90.7368, 38.7354],
            [-90.8821, 38.731],
            [-90.9624, 38.7603],
            [-90.9576, 38.8933],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931650',
        NAME: 'Wentzville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229506164,
        AWATER: 2478616,
        INTPTLAT: '+38.8017411',
        INTPTLON: '-090.8668807',
        ELSDLEA: '',
        UNSDLEA: '31650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2272, 39.345],
            [-92.1378, 39.3569],
            [-91.9958, 39.3403],
            [-91.9869, 39.1721],
            [-92.0323, 39.1069],
            [-92.1481, 39.0365],
            [-92.1673, 39.0484],
            [-92.157, 39.1538],
            [-92.2587, 39.1896],
            [-92.2272, 39.345],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908400',
        NAME: 'Centralia R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 524131968,
        AWATER: 2059645,
        INTPTLAT: '+39.2210675',
        INTPTLON: '-092.1002869',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1378, 39.3569],
            [-92.126, 39.4147],
            [-92.0789, 39.424],
            [-92.075, 39.5547],
            [-92.1211, 39.6034],
            [-91.9122, 39.601],
            [-91.7623, 39.4624],
            [-91.7377, 39.3393],
            [-91.9958, 39.3403],
            [-92.1378, 39.3569],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923530',
        NAME: 'Paris R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 687783021,
        AWATER: 27338170,
        INTPTLAT: '+39.4501842',
        INTPTLON: '-091.9501688',
        ELSDLEA: '',
        UNSDLEA: '23530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0444, 36.8121],
            [-92.9082, 36.8572],
            [-92.9037, 36.809],
            [-92.783, 36.8065],
            [-92.7468, 36.8057],
            [-92.7677, 36.6835],
            [-92.9236, 36.668],
            [-92.966, 36.7147],
            [-93.0444, 36.8121],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905700',
        NAME: 'Bradleyville R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364721987,
        AWATER: 18378,
        INTPTLAT: '+36.7547860',
        INTPTLON: '-092.8773396',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5639, 37.7722],
            [-90.5, 37.7697],
            [-90.4234, 37.8645],
            [-90.3633, 37.8844],
            [-90.3628, 37.9086],
            [-90.3074, 37.9069],
            [-90.2552, 37.8176],
            [-90.0924, 37.8158],
            [-90.0921, 37.6958],
            [-90.1468, 37.6416],
            [-90.4536, 37.6428],
            [-90.5347, 37.6436],
            [-90.5639, 37.7722],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911910',
        NAME: 'Farmington R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 823568910,
        AWATER: 2785303,
        INTPTLAT: '+37.7382708',
        INTPTLON: '-090.3037944',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5514, 37.8476],
            [-90.3633, 37.8844],
            [-90.4234, 37.8645],
            [-90.5, 37.7697],
            [-90.5639, 37.7722],
            [-90.5706, 37.7934],
            [-90.5514, 37.8476],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929170',
        NAME: 'Central R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129095560,
        AWATER: 312334,
        INTPTLAT: '+37.8465133',
        INTPTLON: '-090.4846467',
        ELSDLEA: '',
        UNSDLEA: '29170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6229, 37.9779],
            [-90.6171, 38.0068],
            [-90.472, 38.0424],
            [-90.3944, 38.0437],
            [-90.3229, 37.9817],
            [-90.3628, 37.9086],
            [-90.3633, 37.8844],
            [-90.5514, 37.8476],
            [-90.6421, 37.9176],
            [-90.6229, 37.9779],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905430',
        NAME: 'North St. Francis County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352577130,
        AWATER: 2442680,
        INTPTLAT: '+37.9504091',
        INTPTLON: '-090.4798606',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3415, 38.0872],
            [-90.2527, 38.1278],
            [-90.2067, 38.088],
            [-90.0404, 38.0002],
            [-89.9407, 37.9709],
            [-89.9386, 37.8743],
            [-90.0921, 37.6958],
            [-90.0924, 37.8158],
            [-90.2552, 37.8176],
            [-90.3074, 37.9069],
            [-90.3628, 37.9086],
            [-90.3229, 37.9817],
            [-90.3944, 38.0437],
            [-90.3415, 38.0872],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929370',
        NAME: 'Ste. Genevieve County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 882174744,
        AWATER: 18314149,
        INTPTLAT: '+37.9423566',
        INTPTLON: '-090.1875490',
        ELSDLEA: '',
        UNSDLEA: '29370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4624, 38.8645],
            [-92.3825, 38.7909],
            [-92.3355, 38.6732],
            [-92.4156, 38.674],
            [-92.5411, 38.7359],
            [-92.5349, 38.8234],
            [-92.4624, 38.8645],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915660',
        NAME: 'Jamestown C-1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215265698,
        AWATER: 5893475,
        INTPTLAT: '+38.7444486',
        INTPTLON: '-092.4482678',
        ELSDLEA: '',
        UNSDLEA: '15660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.515, 38.5454],
            [-92.446, 38.5797],
            [-92.3401, 38.5786],
            [-92.2869, 38.511],
            [-92.4035, 38.4253],
            [-92.5301, 38.4577],
            [-92.515, 38.5454],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926970',
        NAME: 'Cole County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287326945,
        AWATER: 382494,
        INTPTLAT: '+38.5015158',
        INTPTLON: '-092.4219222',
        ELSDLEA: '',
        UNSDLEA: '26970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.616, 37.5727],
            [-93.6098, 37.7332],
            [-93.4946, 37.701],
            [-93.5069, 37.5439],
            [-93.5907, 37.5289],
            [-93.616, 37.5727],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911730',
        NAME: 'Fair Play R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213975395,
        AWATER: 7532559,
        INTPTLAT: '+37.6340466',
        INTPTLON: '-093.5531770',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.218, 37.7596],
            [-93.1732, 37.7297],
            [-93.1799, 37.5187],
            [-93.2799, 37.5179],
            [-93.3143, 37.5747],
            [-93.3497, 37.6363],
            [-93.318, 37.7112],
            [-93.218, 37.7596],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913530',
        NAME: 'Halfway R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299083164,
        AWATER: 486766,
        INTPTLAT: '+37.6301562',
        INTPTLON: '-093.2460090',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2177, 37.315],
            [-90.1457, 37.3121],
            [-90.1452, 37.4058],
            [-89.9939, 37.3936],
            [-89.8796, 37.4476],
            [-89.8623, 37.2923],
            [-89.881, 37.2917],
            [-89.9759, 37.2344],
            [-89.9417, 37.1768],
            [-89.9689, 37.1741],
            [-90.2197, 37.2216],
            [-90.2177, 37.315],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919350',
        NAME: 'Woodland R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 607609113,
        AWATER: 1307024,
        INTPTLAT: '+37.2899110',
        INTPTLON: '-090.0293538',
        ELSDLEA: '',
        UNSDLEA: '19350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1468, 37.6416],
            [-90.0921, 37.6958],
            [-89.9386, 37.8743],
            [-89.8448, 37.9056],
            [-89.6743, 37.8031],
            [-89.5842, 37.714],
            [-89.6509, 37.5979],
            [-89.6644, 37.5907],
            [-89.8611, 37.5993],
            [-90.0918, 37.5983],
            [-90.1468, 37.6416],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2924530',
        NAME: 'Perry County 32 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1055449255,
        AWATER: 15071355,
        INTPTLAT: '+37.7199513',
        INTPTLON: '-089.8572938',
        ELSDLEA: '',
        UNSDLEA: '24530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0964, 37.8708],
            [-91.1, 37.74],
            [-90.9653, 37.7385],
            [-90.9502, 37.6037],
            [-91.1738, 37.5885],
            [-91.2457, 37.5903],
            [-91.274, 37.6994],
            [-91.3083, 37.7872],
            [-91.4188, 37.7885],
            [-91.3416, 37.8748],
            [-91.0964, 37.8708],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915510',
        NAME: 'Iron County C-4 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 753614282,
        AWATER: 1627991,
        INTPTLAT: '+37.7292442',
        INTPTLON: '-091.1559563',
        ELSDLEA: '',
        UNSDLEA: '15510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.28, 38.7649],
            [-90.2793, 38.735],
            [-90.2792, 38.7216],
            [-90.3352, 38.7213],
            [-90.3827, 38.7506],
            [-90.3949, 38.7689],
            [-90.28, 38.7649],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912010',
        NAME: 'Ferguson-Florissant R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65529648,
        AWATER: 39063,
        INTPTLAT: '+38.7639614',
        INTPTLON: '-090.3191459',
        ELSDLEA: '',
        UNSDLEA: '12010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4619, 38.8048],
            [-90.2987, 38.8878],
            [-90.1177, 38.8057],
            [-90.1666, 38.7724],
            [-90.1856, 38.7633],
            [-90.28, 38.7649],
            [-90.3949, 38.7689],
            [-90.4804, 38.7697],
            [-90.4619, 38.8048],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913830',
        NAME: 'Hazelwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200059609,
        AWATER: 16242374,
        INTPTLAT: '+38.8287207',
        INTPTLON: '-090.2989704',
        ELSDLEA: '',
        UNSDLEA: '13830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4364, 38.5493],
            [-90.3772, 38.5735],
            [-90.3593, 38.5648],
            [-90.3369, 38.5372],
            [-90.4053, 38.5012],
            [-90.4546, 38.5018],
            [-90.4631, 38.5019],
            [-90.4364, 38.5493],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918690',
        NAME: 'Lindbergh School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61998559,
        AWATER: 1084241,
        INTPTLAT: '+38.5331396',
        INTPTLON: '-090.3939046',
        ELSDLEA: '',
        UNSDLEA: '18690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4053, 38.5012],
            [-90.3369, 38.5372],
            [-90.3087, 38.5357],
            [-90.2871, 38.5335],
            [-90.2641, 38.521],
            [-90.33, 38.3974],
            [-90.3414, 38.3883],
            [-90.4053, 38.5012],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920670',
        NAME: 'Mehlville R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101066513,
        AWATER: 7319363,
        INTPTLAT: '+38.4757734',
        INTPTLON: '-090.3385650',
        ELSDLEA: '',
        UNSDLEA: '20670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5718, 38.6811],
            [-90.5063, 38.7434],
            [-90.4245, 38.6791],
            [-90.4345, 38.6187],
            [-90.4543, 38.5653],
            [-90.5108, 38.5461],
            [-90.5718, 38.6811],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923580',
        NAME: 'Parkway C-2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177435408,
        AWATER: 4154646,
        INTPTLAT: '+38.6450002',
        INTPTLON: '-090.4914016',
        ELSDLEA: '',
        UNSDLEA: '23580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5909, 39.049],
            [-93.4919, 39.0229],
            [-93.5249, 38.8966],
            [-93.6242, 38.8592],
            [-93.6946, 38.9048],
            [-93.7141, 38.9343],
            [-93.6872, 39.0251],
            [-93.5909, 39.049],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910080',
        NAME: 'Concordia R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284207448,
        AWATER: 2812197,
        INTPTLAT: '+38.9547396',
        INTPTLON: '-093.6072534',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6379, 39.1665],
            [-93.5909, 39.049],
            [-93.6872, 39.0251],
            [-93.7141, 38.9343],
            [-93.798, 38.9365],
            [-93.7744, 39.0747],
            [-93.8206, 39.0853],
            [-93.7384, 39.168],
            [-93.6379, 39.1665],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914400',
        NAME: 'Lafayette County C-1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312484267,
        AWATER: 2777071,
        INTPTLAT: '+39.0742711',
        INTPTLON: '-093.7006486',
        ELSDLEA: '',
        UNSDLEA: '14400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1628, 39.0935],
            [-94.1068, 39.0917],
            [-94.1088, 39.0453],
            [-94.0767, 38.9053],
            [-94.1141, 38.9062],
            [-94.171, 38.923],
            [-94.1628, 39.0935],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923010',
        NAME: 'Oak Grove R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116195909,
        AWATER: 276342,
        INTPTLAT: '+38.9873351',
        INTPTLON: '-094.1215742',
        ELSDLEA: '',
        UNSDLEA: '23010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9599, 39.3194],
            [-92.8205, 39.2867],
            [-92.7182, 39.2994],
            [-92.7685, 39.1265],
            [-92.8306, 39.0851],
            [-92.9214, 39.0376],
            [-92.9054, 39.162],
            [-92.9498, 39.205],
            [-92.9606, 39.3129],
            [-92.9599, 39.3194],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912900',
        NAME: 'Glasgow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374763125,
        AWATER: 13876833,
        INTPTLAT: '+39.1954214',
        INTPTLON: '-092.8500813',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2448, 38.9495],
            [-93.0505, 38.9282],
            [-93.052, 38.8842],
            [-93.0536, 38.8417],
            [-93.1687, 38.7838],
            [-93.279, 38.7651],
            [-93.3047, 38.7954],
            [-93.3851, 38.8502],
            [-93.4081, 38.9397],
            [-93.2448, 38.9495],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915270',
        NAME: 'Pettis County R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428486429,
        AWATER: 1856523,
        INTPTLAT: '+38.8708640',
        INTPTLON: '-093.2303123',
        ELSDLEA: '',
        UNSDLEA: '15270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0744, 39.338],
            [-92.9685, 39.3152],
            [-93.0386, 39.2082],
            [-93.1238, 39.2408],
            [-93.0744, 39.338],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928360',
        NAME: 'Slater School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99403708,
        AWATER: 2316277,
        INTPTLAT: '+39.2719249',
        INTPTLON: '-093.0613692',
        ELSDLEA: '',
        UNSDLEA: '28360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6465, 37.6901],
            [-90.5347, 37.6436],
            [-90.4536, 37.6428],
            [-90.5467, 37.5112],
            [-90.5483, 37.4069],
            [-90.6589, 37.4103],
            [-90.6561, 37.558],
            [-90.7702, 37.5494],
            [-90.7851, 37.6024],
            [-90.6675, 37.6438],
            [-90.6465, 37.6901],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903150',
        NAME: 'Arcadia Valley R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410127211,
        AWATER: 2013380,
        INTPTLAT: '+37.5563245',
        INTPTLON: '-090.6199404',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1437, 37.4651],
            [-90.1452, 37.4058],
            [-90.1457, 37.3121],
            [-90.2177, 37.315],
            [-90.4385, 37.3161],
            [-90.3602, 37.4358],
            [-90.1437, 37.4651],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920370',
        NAME: 'Marquand-Zion R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364717264,
        AWATER: 915937,
        INTPTLAT: '+37.3771263',
        INTPTLON: '-090.2561509',
        ELSDLEA: '',
        UNSDLEA: '20370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6586, 39.4062],
            [-94.6064, 39.4923],
            [-94.6012, 39.4921],
            [-94.5507, 39.4414],
            [-94.4634, 39.4521],
            [-94.492, 39.31],
            [-94.492, 39.3098],
            [-94.6007, 39.3109],
            [-94.6586, 39.4062],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928410',
        NAME: 'Smithville R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203393760,
        AWATER: 15204245,
        INTPTLAT: '+39.3874249',
        INTPTLON: '-094.5639712',
        ELSDLEA: '',
        UNSDLEA: '28410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2668, 39.397],
            [-94.1496, 39.3958],
            [-94.0986, 39.3484],
            [-94.0987, 39.2862],
            [-94.1839, 39.2992],
            [-94.2125, 39.2071],
            [-94.2783, 39.2362],
            [-94.2912, 39.274],
            [-94.3283, 39.3103],
            [-94.2668, 39.397],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911650',
        NAME: 'Excelsior Springs 40 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232184449,
        AWATER: 2892256,
        INTPTLAT: '+39.3278379',
        INTPTLON: '-094.2189325',
        ELSDLEA: '',
        UNSDLEA: '11650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4634, 39.4521],
            [-94.3728, 39.4846],
            [-94.2856, 39.4553],
            [-94.2668, 39.397],
            [-94.3283, 39.3103],
            [-94.492, 39.31],
            [-94.4634, 39.4521],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916450',
        NAME: 'Kearney R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251509949,
        AWATER: 2471806,
        INTPTLAT: '+39.3909379',
        INTPTLON: '-094.3793955',
        ELSDLEA: '',
        UNSDLEA: '16450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2254, 36.633],
            [-92.1688, 36.7345],
            [-92.1147, 36.7189],
            [-92.1164, 36.6514],
            [-92.1125, 36.4989],
            [-92.1503, 36.4986],
            [-92.2595, 36.498],
            [-92.2254, 36.633],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904140',
        NAME: 'Bakersfield R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261378126,
        AWATER: 1716025,
        INTPTLAT: '+36.5876070',
        INTPTLON: '-092.1811296',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.779, 37.1376],
              [-90.7796, 37.0503],
              [-90.8901, 37.0521],
              [-90.779, 37.1376],
            ],
          ],
          [
            [
              [-91.2245, 36.8404],
              [-91.2187, 37.0276],
              [-91.1108, 37.0266],
              [-91.0904, 37.0867],
              [-90.9636, 37.0974],
              [-90.8932, 37.0522],
              [-90.9733, 36.9467],
              [-90.9011, 36.9152],
              [-90.9338, 36.8147],
              [-90.9467, 36.8176],
              [-91.1159, 36.8237],
              [-91.2245, 36.8404],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930750',
        NAME: 'Van Buren R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795339847,
        AWATER: 2038744,
        INTPTLAT: '+36.9653124',
        INTPTLON: '-091.0392737',
        ELSDLEA: '',
        UNSDLEA: '30750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5679, 38.8234],
            [-90.4619, 38.8048],
            [-90.4804, 38.7697],
            [-90.4921, 38.7518],
            [-90.5769, 38.7791],
            [-90.5679, 38.8234],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928920',
        NAME: 'St. Charles R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46111729,
        AWATER: 1295647,
        INTPTLAT: '+38.7897800',
        INTPTLON: '-090.5221376',
        ELSDLEA: '',
        UNSDLEA: '28920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9632, 38.6779],
            [-90.9624, 38.7603],
            [-90.8821, 38.731],
            [-90.7368, 38.7354],
            [-90.5769, 38.7791],
            [-90.4921, 38.7518],
            [-90.5063, 38.7434],
            [-90.5718, 38.6811],
            [-90.6605, 38.6886],
            [-90.7323, 38.6393],
            [-90.9632, 38.6779],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928950',
        NAME: 'Francis Howell R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406767137,
        AWATER: 15582682,
        INTPTLAT: '+38.7006126',
        INTPTLON: '-090.7518262',
        ELSDLEA: '',
        UNSDLEA: '28950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3593, 38.5648],
            [-90.3174, 38.5874],
            [-90.307, 38.5722],
            [-90.3087, 38.5357],
            [-90.3369, 38.5372],
            [-90.3593, 38.5648],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2902910',
        NAME: 'Affton 101 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15347559,
        AWATER: 0,
        INTPTLAT: '+38.5618709',
        INTPTLON: '-090.3274484',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.307, 38.5722],
            [-90.2803, 38.552],
            [-90.2871, 38.5335],
            [-90.3087, 38.5357],
            [-90.307, 38.5722],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904500',
        NAME: 'Bayless School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6858011,
        AWATER: 0,
        INTPTLAT: '+38.5497392',
        INTPTLON: '-090.2991338',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1739, 37.8458],
            [-94.0639, 37.9297],
            [-94.0608, 37.9878],
            [-93.8946, 38.003],
            [-93.9002, 37.8953],
            [-93.8086, 37.8928],
            [-93.8117, 37.8348],
            [-94.054, 37.6755],
            [-94.1798, 37.7078],
            [-94.1739, 37.8458],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911310',
        NAME: 'El Dorado Springs R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 719793719,
        AWATER: 6300645,
        INTPTLAT: '+37.8306494',
        INTPTLON: '-094.0017618',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7382, 37.8319],
            [-93.4583, 37.8102],
            [-93.3787, 37.8074],
            [-93.4946, 37.701],
            [-93.6098, 37.7332],
            [-93.7382, 37.8319],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915300',
        NAME: 'Humansville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281666269,
        AWATER: 3021212,
        INTPTLAT: '+37.7783929',
        INTPTLON: '-093.5480674',
        ELSDLEA: '',
        UNSDLEA: '15300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5835, 37.2331],
            [-89.4887, 37.2515],
            [-89.4587, 37.2483],
            [-89.4544, 37.1854],
            [-89.4945, 37.1488],
            [-89.5835, 37.2331],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2915450',
        NAME: 'Scott City R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63924287,
        AWATER: 4045296,
        INTPTLAT: '+37.2118784',
        INTPTLON: '-089.5059771',
        ELSDLEA: '',
        UNSDLEA: '15450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2277, 37.3818],
            [-94.1424, 37.4674],
            [-94.1389, 37.6008],
            [-94.092, 37.629],
            [-94.0735, 37.6397],
            [-94.0754, 37.5816],
            [-94.0838, 37.2909],
            [-94.0523, 37.2901],
            [-94.0533, 37.2518],
            [-94.1084, 37.2422],
            [-94.131, 37.3093],
            [-94.2277, 37.3818],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912930',
        NAME: 'Golden City R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306728685,
        AWATER: 1214475,
        INTPTLAT: '+37.4161995',
        INTPTLON: '-094.1251771',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3911, 37.4152],
            [-94.37, 37.6338],
            [-94.3472, 37.6482],
            [-94.092, 37.629],
            [-94.1389, 37.6008],
            [-94.1424, 37.4674],
            [-94.2277, 37.3818],
            [-94.3911, 37.4152],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2917850',
        NAME: 'Lamar R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504286959,
        AWATER: 4662743,
        INTPTLAT: '+37.5324243',
        INTPTLON: '-094.2514161',
        ELSDLEA: '',
        UNSDLEA: '17850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.345, 37.7062],
            [-94.1798, 37.7078],
            [-94.054, 37.6755],
            [-94.0735, 37.6397],
            [-94.092, 37.629],
            [-94.3472, 37.6482],
            [-94.345, 37.7062],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928170',
        NAME: 'Sheldon R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185726110,
        AWATER: 697828,
        INTPTLAT: '+37.6791214',
        INTPTLON: '-094.2133429',
        ELSDLEA: '',
        UNSDLEA: '28170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3538, 38.6567],
            [-90.3028, 38.6496],
            [-90.3088, 38.63],
            [-90.3349, 38.631],
            [-90.3491, 38.6305],
            [-90.3538, 38.6567],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909720',
        NAME: 'Clayton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8300713,
        AWATER: 0,
        INTPTLAT: '+38.6426526',
        INTPTLON: '-090.3295680',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2803, 38.552],
            [-90.2574, 38.5319],
            [-90.2641, 38.521],
            [-90.2871, 38.5335],
            [-90.2803, 38.552],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913620',
        NAME: 'Hancock Place School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5175808,
        AWATER: 474735,
        INTPTLAT: '+38.5325866',
        INTPTLON: '-090.2752711',
        ELSDLEA: '',
        UNSDLEA: '13620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2792, 38.7216],
            [-90.2793, 38.735],
            [-90.2397, 38.7236],
            [-90.265, 38.7022],
            [-90.2792, 38.7216],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916290',
        NAME: 'Jennings School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9387375,
        AWATER: 0,
        INTPTLAT: '+38.7223688',
        INTPTLON: '-090.2632626',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7207, 37.415],
            [-93.6217, 37.4128],
            [-93.623, 37.3692],
            [-93.7105, 37.2838],
            [-93.762, 37.2849],
            [-93.7671, 37.285],
            [-93.7854, 37.2854],
            [-93.7645, 37.4016],
            [-93.7207, 37.415],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911580',
        NAME: 'Everton R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150996094,
        AWATER: 736372,
        INTPTLAT: '+37.3491992',
        INTPTLON: '-093.7163354',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0735, 37.6397],
            [-94.054, 37.6755],
            [-93.8117, 37.8348],
            [-93.7382, 37.8319],
            [-93.6098, 37.7332],
            [-93.616, 37.5727],
            [-93.7266, 37.5461],
            [-93.7624, 37.5763],
            [-93.947, 37.5794],
            [-94.0754, 37.5816],
            [-94.0735, 37.6397],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929520',
        NAME: 'Stockton R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 800621455,
        AWATER: 60828011,
        INTPTLAT: '+37.6715836',
        INTPTLON: '-093.8235849',
        ELSDLEA: '',
        UNSDLEA: '29520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6883, 38.1336],
            [-90.6317, 38.1491],
            [-90.5097, 38.1545],
            [-90.4082, 38.1881],
            [-90.4152, 38.0864],
            [-90.3415, 38.0872],
            [-90.3944, 38.0437],
            [-90.472, 38.0424],
            [-90.612, 38.0696],
            [-90.6171, 38.0068],
            [-90.6229, 37.9779],
            [-90.6413, 37.9802],
            [-90.64, 38.0765],
            [-90.6883, 38.1336],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910500',
        NAME: 'De Soto 73 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266376155,
        AWATER: 433236,
        INTPTLAT: '+38.1037701',
        INTPTLON: '-090.5207924',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4437, 38.2567],
            [-90.394, 38.2481],
            [-90.384, 38.2088],
            [-90.3904, 38.2027],
            [-90.396, 38.2013],
            [-90.4082, 38.1881],
            [-90.5097, 38.1545],
            [-90.4437, 38.2567],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912030',
        NAME: 'Festus R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78069346,
        AWATER: 71934,
        INTPTLAT: '+38.2002077',
        INTPTLON: '-090.4591843',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6307, 38.3177],
            [-90.5141, 38.3544],
            [-90.4618, 38.3081],
            [-90.4437, 38.2567],
            [-90.5097, 38.1545],
            [-90.6317, 38.1491],
            [-90.6307, 38.3177],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914430',
        NAME: 'Hillsboro R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274975944,
        AWATER: 2083554,
        INTPTLAT: '+38.2546203',
        INTPTLON: '-090.5556013',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7731, 38.0854],
            [-90.7133, 38.1358],
            [-90.6883, 38.1336],
            [-90.64, 38.0765],
            [-90.6413, 37.9802],
            [-90.7714, 37.9642],
            [-90.8223, 38.0572],
            [-90.7731, 38.0854],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931140',
        NAME: 'Kingston K-14 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227185680,
        AWATER: 1217347,
        INTPTLAT: '+38.0296271',
        INTPTLON: '-090.7174983',
        ELSDLEA: '',
        UNSDLEA: '31140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9463, 38.8754],
            [-93.798, 38.9365],
            [-93.7141, 38.9343],
            [-93.6946, 38.9048],
            [-93.7629, 38.871],
            [-93.7845, 38.7334],
            [-93.8252, 38.6902],
            [-93.8432, 38.6908],
            [-93.9515, 38.7832],
            [-93.9463, 38.8754],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908320',
        NAME: 'Johnson County R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358111187,
        AWATER: 960039,
        INTPTLAT: '+38.8296955',
        INTPTLON: '-093.8435036',
        ELSDLEA: '',
        UNSDLEA: '08320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4651, 38.5692],
            [-94.4492, 38.643],
            [-94.3566, 38.721],
            [-94.2949, 38.6929],
            [-94.2098, 38.6199],
            [-94.2847, 38.5871],
            [-94.4651, 38.5692],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913760',
        NAME: 'Harrisonville R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254127850,
        AWATER: 1420238,
        INTPTLAT: '+38.6348571',
        INTPTLON: '-094.3481508',
        ELSDLEA: '',
        UNSDLEA: '13760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6095, 38.7217],
            [-94.5631, 38.7195],
            [-94.4492, 38.643],
            [-94.4651, 38.5692],
            [-94.4714, 38.5474],
            [-94.6122, 38.5655],
            [-94.6095, 38.7217],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931800',
        NAME: 'Midway School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183561960,
        AWATER: 1136036,
        INTPTLAT: '+38.6222615',
        INTPTLON: '-094.5274093',
        ELSDLEA: '',
        UNSDLEA: '31800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0299, 37.358],
            [-90.8127, 37.2854],
            [-90.7796, 37.2542],
            [-90.779, 37.1376],
            [-90.8901, 37.0521],
            [-90.8932, 37.0522],
            [-90.9636, 37.0974],
            [-91.0904, 37.0867],
            [-91.1445, 37.1421],
            [-91.1593, 37.255],
            [-91.1571, 37.3996],
            [-91.0299, 37.358],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928590',
        NAME: 'Southern Reynolds County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 859752953,
        AWATER: 7970269,
        INTPTLAT: '+37.2197570',
        INTPTLON: '-090.9931053',
        ELSDLEA: '',
        UNSDLEA: '28590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1738, 37.5885],
            [-90.9502, 37.6037],
            [-90.7851, 37.6024],
            [-90.7702, 37.5494],
            [-90.7717, 37.43],
            [-90.847, 37.3728],
            [-90.92, 37.4375],
            [-90.918, 37.4969],
            [-91.0609, 37.5054],
            [-91.1738, 37.5885],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918450',
        NAME: 'Lesterville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 462605462,
        AWATER: 3977175,
        INTPTLAT: '+37.5579219',
        INTPTLON: '-090.9034205',
        ELSDLEA: '',
        UNSDLEA: '18450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5169, 37.3612],
            [-94.3911, 37.4152],
            [-94.2277, 37.3818],
            [-94.131, 37.3093],
            [-94.1084, 37.2422],
            [-94.2529, 37.2487],
            [-94.3992, 37.252],
            [-94.4705, 37.269],
            [-94.5169, 37.3612],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916140',
        NAME: 'Jasper County R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468008468,
        AWATER: 2402065,
        INTPTLAT: '+37.3261574',
        INTPTLON: '-094.3177743',
        ELSDLEA: '',
        UNSDLEA: '16140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4543, 38.5653],
            [-90.4345, 38.6187],
            [-90.3965, 38.6112],
            [-90.3772, 38.5735],
            [-90.4364, 38.5493],
            [-90.4545, 38.5551],
            [-90.4543, 38.5653],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916770',
        NAME: 'Kirkwood R-VII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39707931,
        AWATER: 108840,
        INTPTLAT: '+38.5861781',
        INTPTLON: '-090.4265795',
        ELSDLEA: '',
        UNSDLEA: '16770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4245, 38.6791],
            [-90.3947, 38.6861],
            [-90.36, 38.6774],
            [-90.3538, 38.6567],
            [-90.3491, 38.6305],
            [-90.3624, 38.6193],
            [-90.3965, 38.6112],
            [-90.4345, 38.6187],
            [-90.4245, 38.6791],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2917820',
        NAME: 'Ladue School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47339865,
        AWATER: 0,
        INTPTLAT: '+38.6471701',
        INTPTLON: '-090.3927814',
        ELSDLEA: '',
        UNSDLEA: '17820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3088, 38.63],
            [-90.3182, 38.6],
            [-90.3353, 38.6097],
            [-90.3349, 38.631],
            [-90.3088, 38.63],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920010',
        NAME: 'Maplewood-Richmond Heights School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7103169,
        AWATER: 0,
        INTPTLAT: '+38.6190070',
        INTPTLON: '-090.3237302',
        ELSDLEA: '',
        UNSDLEA: '20010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3186, 39.1377],
            [-92.2587, 39.1896],
            [-92.157, 39.1538],
            [-92.1673, 39.0484],
            [-92.3213, 39.0695],
            [-92.3186, 39.1377],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913560',
        NAME: 'Hallsville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204862523,
        AWATER: 764883,
        INTPTLAT: '+39.1025610',
        INTPTLON: '-092.2183927',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6027, 40.3002],
            [-94.5985, 40.3867],
            [-94.4788, 40.3866],
            [-94.4427, 40.343],
            [-94.4457, 40.1257],
            [-94.6527, 40.1263],
            [-94.6615, 40.1769],
            [-94.6046, 40.1989],
            [-94.6027, 40.3002],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929340',
        NAME: 'Stanberry R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400876853,
        AWATER: 18254,
        INTPTLAT: '+40.2510498',
        INTPTLON: '-094.5287185',
        ELSDLEA: '',
        UNSDLEA: '29340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8014, 40.5773],
            [-93.7743, 40.5775],
            [-93.7645, 40.4239],
            [-93.7276, 40.3803],
            [-93.8218, 40.3802],
            [-93.8805, 40.4384],
            [-93.8014, 40.5773],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906450',
        NAME: 'Cainsville R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176138919,
        AWATER: 505448,
        INTPTLAT: '+40.4607141',
        INTPTLON: '-093.8026607',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.157, 36.8721],
            [-93.1397, 36.9101],
            [-93.0647, 36.9823],
            [-92.9231, 36.9845],
            [-92.908, 36.8676],
            [-92.9082, 36.8572],
            [-93.0444, 36.8121],
            [-93.1411, 36.8138],
            [-93.1827, 36.8145],
            [-93.157, 36.8721],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908430',
        NAME: 'Chadwick R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357779471,
        AWATER: 45802,
        INTPTLAT: '+36.8779264',
        INTPTLON: '-093.0284094',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0533, 37.2518],
            [-94.0523, 37.2901],
            [-93.864, 37.2869],
            [-93.7854, 37.2854],
            [-93.7671, 37.285],
            [-93.762, 37.2849],
            [-93.7105, 37.2838],
            [-93.6068, 37.2003],
            [-93.608, 37.142],
            [-93.6715, 37.1724],
            [-93.9291, 37.1759],
            [-94.0246, 37.1395],
            [-94.0562, 37.1547],
            [-94.0533, 37.2518],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921000',
        NAME: 'Miller R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 508209084,
        AWATER: 1845080,
        INTPTLAT: '+37.2118278',
        INTPTLON: '-093.8646769',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3137, 40.2627],
            [-95.209, 40.2623],
            [-95.1075, 40.1519],
            [-95.1074, 40.1015],
            [-95.2397, 40.0592],
            [-95.3533, 40.0945],
            [-95.3145, 40.1484],
            [-95.3137, 40.2627],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921480',
        NAME: 'Mound City R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312550156,
        AWATER: 2060915,
        INTPTLAT: '+40.1491653',
        INTPTLON: '-095.2333009',
        ELSDLEA: '',
        UNSDLEA: '21480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6458, 36.8214],
            [-93.5041, 36.8664],
            [-93.3852, 36.9374],
            [-93.3566, 36.9491],
            [-93.342, 36.8173],
            [-93.3054, 36.7883],
            [-93.4146, 36.7937],
            [-93.6391, 36.7378],
            [-93.6386, 36.7811],
            [-93.6458, 36.8214],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912630',
        NAME: 'Galena R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310467386,
        AWATER: 3318805,
        INTPTLAT: '+36.8236664',
        INTPTLON: '-093.4757679',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7708, 36.8166],
            [-93.6386, 36.7811],
            [-93.6391, 36.7378],
            [-93.6395, 36.6903],
            [-93.6893, 36.5797],
            [-93.5826, 36.5629],
            [-93.5843, 36.4989],
            [-93.6799, 36.4991],
            [-93.8343, 36.4989],
            [-93.8323, 36.5692],
            [-93.933, 36.6367],
            [-93.9564, 36.7445],
            [-93.7708, 36.8166],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908170',
        NAME: 'Cassville R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 712144528,
        AWATER: 27783463,
        INTPTLAT: '+36.6389190',
        INTPTLON: '-093.7541955',
        ELSDLEA: '',
        UNSDLEA: '08170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6391, 36.7378],
            [-93.4146, 36.7937],
            [-93.3054, 36.7883],
            [-93.3066, 36.7589],
            [-93.3128, 36.5959],
            [-93.3133, 36.5747],
            [-93.4689, 36.6122],
            [-93.6395, 36.6903],
            [-93.6391, 36.7378],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926160',
        NAME: 'Reeds Spring R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395093163,
        AWATER: 52875495,
        INTPTLAT: '+36.6982450',
        INTPTLON: '-093.4340479',
        ELSDLEA: '',
        UNSDLEA: '26160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8704, 37.0226],
            [-93.7617, 37.0429],
            [-93.771, 36.8452],
            [-93.8288, 36.883],
            [-93.8704, 37.0226],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930810',
        NAME: 'Verona R-VII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149651400,
        AWATER: 259161,
        INTPTLAT: '+36.9636450',
        INTPTLON: '-093.8130821',
        ELSDLEA: '',
        UNSDLEA: '30810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6043, 39.915],
            [-94.5054, 39.9223],
            [-94.4338, 40.0042],
            [-94.3936, 40.0392],
            [-94.2564, 40.0366],
            [-94.2047, 39.9481],
            [-94.2055, 39.8759],
            [-94.3195, 39.8303],
            [-94.4289, 39.7914],
            [-94.5374, 39.8131],
            [-94.5536, 39.7472],
            [-94.6024, 39.7763],
            [-94.6028, 39.8196],
            [-94.6043, 39.915],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920550',
        NAME: 'Maysville R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 626290055,
        AWATER: 5342193,
        INTPTLAT: '+39.9028405',
        INTPTLON: '-094.4040681',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0046, 39.9821],
            [-94.7736, 40.0029],
            [-94.7263, 40.0375],
            [-94.7019, 40.0046],
            [-94.6043, 39.915],
            [-94.6028, 39.8196],
            [-94.7105, 39.8207],
            [-94.7286, 39.9077],
            [-94.8055, 39.821],
            [-94.8765, 39.8206],
            [-94.9934, 39.8979],
            [-95.0046, 39.9821],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927570',
        NAME: 'Savannah R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 523762715,
        AWATER: 5701191,
        INTPTLAT: '+39.9265546',
        INTPTLON: '-094.7996339',
        ELSDLEA: '',
        UNSDLEA: '27570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5375, 37.3589],
            [-91.4823, 37.4049],
            [-91.3708, 37.4182],
            [-91.3186, 37.3305],
            [-91.3761, 37.2728],
            [-91.1593, 37.255],
            [-91.1445, 37.1421],
            [-91.289, 37.1321],
            [-91.2907, 37.0453],
            [-91.4279, 37.0453],
            [-91.5461, 37.0768],
            [-91.472, 37.1189],
            [-91.4717, 37.2085],
            [-91.539, 37.2463],
            [-91.5375, 37.3589],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911450',
        NAME: 'Eminence R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876710351,
        AWATER: 208117,
        INTPTLAT: '+37.2251638',
        INTPTLON: '-091.3608236',
        ELSDLEA: '',
        UNSDLEA: '11450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8086, 37.8928],
            [-93.7501, 37.959],
            [-93.5101, 37.9565],
            [-93.5033, 38.0875],
            [-93.4496, 38.0808],
            [-93.4583, 37.8102],
            [-93.7382, 37.8319],
            [-93.8117, 37.8348],
            [-93.8086, 37.8928],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931460',
        NAME: 'Weaubleau R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493708061,
        AWATER: 3551661,
        INTPTLAT: '+37.9083641',
        INTPTLON: '-093.5867080',
        ELSDLEA: '',
        UNSDLEA: '31460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.677, 38.5602],
            [-93.5689, 38.6383],
            [-93.5064, 38.6796],
            [-93.4462, 38.5544],
            [-93.3462, 38.5292],
            [-93.3568, 38.4937],
            [-93.5119, 38.4467],
            [-93.5157, 38.374],
            [-93.5892, 38.3762],
            [-93.5578, 38.4994],
            [-93.7006, 38.4889],
            [-93.677, 38.5602],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932110',
        NAME: 'Henry County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404459800,
        AWATER: 4462242,
        INTPTLAT: '+38.5362034',
        INTPTLON: '-093.5343975',
        ELSDLEA: '',
        UNSDLEA: '32110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1202, 38.0627],
            [-93.0627, 38.1135],
            [-93.0777, 38.2631],
            [-92.9378, 38.2252],
            [-92.8815, 38.1645],
            [-92.8951, 38.0723],
            [-93.0675, 38.0177],
            [-93.1202, 38.0627],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909810',
        NAME: 'Climax Springs R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316409129,
        AWATER: 20137183,
        INTPTLAT: '+38.1268125',
        INTPTLON: '-093.0299143',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3568, 38.4937],
            [-93.3462, 38.5292],
            [-93.2778, 38.5935],
            [-93.1751, 38.6191],
            [-93.1778, 38.5631],
            [-93.0666, 38.5459],
            [-93.0777, 38.2631],
            [-93.2066, 38.2577],
            [-93.2849, 38.3677],
            [-93.2925, 38.4918],
            [-93.3568, 38.4937],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909900',
        NAME: 'Cole Camp R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638612736,
        AWATER: 8930230,
        INTPTLAT: '+38.4395461',
        INTPTLON: '-093.1887875',
        ELSDLEA: '',
        UNSDLEA: '09900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5157, 38.374],
            [-93.5119, 38.4467],
            [-93.3568, 38.4937],
            [-93.2925, 38.4918],
            [-93.2849, 38.3677],
            [-93.2066, 38.2577],
            [-93.2958, 38.3243],
            [-93.5183, 38.3218],
            [-93.5157, 38.374],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918670',
        NAME: 'Lincoln R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375136937,
        AWATER: 10261237,
        INTPTLAT: '+38.3834148',
        INTPTLON: '-093.3838028',
        ELSDLEA: '',
        UNSDLEA: '18670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8011, 38.0556],
            [-93.7809, 38.0952],
            [-93.5003, 38.1454],
            [-93.5033, 38.0875],
            [-93.5101, 37.9565],
            [-93.7501, 37.959],
            [-93.8011, 38.0556],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923270',
        NAME: 'Osceola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404942742,
        AWATER: 20022797,
        INTPTLAT: '+38.0402383',
        INTPTLON: '-093.6262339',
        ELSDLEA: '',
        UNSDLEA: '23270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0666, 38.5459],
            [-93.0036, 38.6164],
            [-92.9531, 38.6126],
            [-92.8937, 38.5752],
            [-92.9239, 38.3988],
            [-92.8534, 38.3523],
            [-92.843, 38.1926],
            [-92.9378, 38.2252],
            [-93.0777, 38.2631],
            [-93.0666, 38.5459],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929610',
        NAME: 'Morgan County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 667345941,
        AWATER: 14636123,
        INTPTLAT: '+38.3936672',
        INTPTLON: '-092.9808973',
        ELSDLEA: '',
        UNSDLEA: '29610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6182, 36.7167],
            [-94.5498, 36.7503],
            [-94.5445, 36.7054],
            [-94.3121, 36.7053],
            [-94.1296, 36.7501],
            [-94.07, 36.69],
            [-94.0709, 36.6529],
            [-94.155, 36.5913],
            [-94.116, 36.4986],
            [-94.1841, 36.4989],
            [-94.2801, 36.4993],
            [-94.6179, 36.4994],
            [-94.6177, 36.6247],
            [-94.6182, 36.7167],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920610',
        NAME: 'McDonald County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1109983954,
        AWATER: 410311,
        INTPTLAT: '+36.6195504',
        INTPTLON: '-094.3609553',
        ELSDLEA: '',
        UNSDLEA: '20610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3166, 36.9441],
            [-94.2242, 37.0085],
            [-94.2057, 37.0079],
            [-94.0741, 36.939],
            [-94.0656, 36.8593],
            [-94.1296, 36.7501],
            [-94.3121, 36.7053],
            [-94.2442, 36.8117],
            [-94.3188, 36.8579],
            [-94.3166, 36.9441],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930420',
        NAME: 'East Newton County R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 533610796,
        AWATER: 1512913,
        INTPTLAT: '+36.8554320',
        INTPTLON: '-094.2011864',
        ELSDLEA: '',
        UNSDLEA: '30420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.504, 36.7921],
            [-94.4996, 36.8811],
            [-94.5341, 36.9832],
            [-94.4813, 37.0102],
            [-94.4616, 37.0099],
            [-94.4079, 36.9564],
            [-94.3166, 36.9441],
            [-94.3188, 36.8579],
            [-94.2442, 36.8117],
            [-94.3121, 36.7053],
            [-94.5445, 36.7054],
            [-94.5498, 36.7503],
            [-94.504, 36.7921],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921810',
        NAME: 'Neosho R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574686289,
        AWATER: 918206,
        INTPTLAT: '+36.8342420',
        INTPTLON: '-094.4095148',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2023, 40.5785],
            [-94.9533, 40.5756],
            [-94.9289, 40.4331],
            [-94.974, 40.3325],
            [-95.1787, 40.3342],
            [-95.1776, 40.3922],
            [-95.2023, 40.5785],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930900',
        NAME: 'West Nodaway County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 532609239,
        AWATER: 949947,
        INTPTLAT: '+40.4592205',
        INTPTLON: '-095.0694590',
        ELSDLEA: '',
        UNSDLEA: '30900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3066, 36.7589],
            [-93.2764, 36.8165],
            [-93.1827, 36.8145],
            [-93.1411, 36.8138],
            [-93.1413, 36.7976],
            [-93.1347, 36.6513],
            [-93.2115, 36.6403],
            [-93.3128, 36.5959],
            [-93.3066, 36.7589],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905760',
        NAME: 'Branson R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283503576,
        AWATER: 5772398,
        INTPTLAT: '+36.7155321',
        INTPTLON: '-093.2250229',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5064, 38.6796],
            [-93.5064, 38.6797],
            [-93.3552, 38.7318],
            [-93.3294, 38.6947],
            [-93.2778, 38.5935],
            [-93.3462, 38.5292],
            [-93.4462, 38.5544],
            [-93.5064, 38.6796],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913290',
        NAME: 'Green Ridge R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274798622,
        AWATER: 1246352,
        INTPTLAT: '+38.6180846',
        INTPTLON: '-093.3836687',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6242, 38.8592],
            [-93.5249, 38.8966],
            [-93.4959, 38.8548],
            [-93.5064, 38.6797],
            [-93.5064, 38.6796],
            [-93.5689, 38.6383],
            [-93.6084, 38.6833],
            [-93.6693, 38.7579],
            [-93.6242, 38.8592],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916830',
        NAME: 'Knob Noster R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302204546,
        AWATER: 1419337,
        INTPTLAT: '+38.7927078',
        INTPTLON: '-093.5753533',
        ELSDLEA: '',
        UNSDLEA: '16830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.279, 38.7651],
            [-93.1687, 38.7838],
            [-93.1751, 38.6191],
            [-93.2778, 38.5935],
            [-93.3294, 38.6947],
            [-93.279, 38.7651],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927830',
        NAME: 'Sedalia 200 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218807796,
        AWATER: 2138485,
        INTPTLAT: '+38.6868032',
        INTPTLON: '-093.2342602',
        ELSDLEA: '',
        UNSDLEA: '27830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8475, 40.0373],
            [-92.7429, 40.038],
            [-92.7347, 40.0049],
            [-92.6834, 39.873],
            [-92.6895, 39.6981],
            [-92.7582, 39.6993],
            [-92.8216, 39.6999],
            [-92.8475, 40.0373],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921880',
        NAME: 'Macon County R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410289852,
        AWATER: 1788093,
        INTPTLAT: '+39.8633269',
        INTPTLON: '-092.7703344',
        ELSDLEA: '',
        UNSDLEA: '21880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9958, 39.3403],
            [-91.7377, 39.3393],
            [-91.7185, 39.3245],
            [-91.7416, 39.2015],
            [-91.7993, 39.1935],
            [-91.8041, 39.0609],
            [-91.9765, 39.062],
            [-92.0323, 39.1069],
            [-91.9869, 39.1721],
            [-91.9958, 39.3403],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920810',
        NAME: 'Mexico 59 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 581561941,
        AWATER: 6142369,
        INTPTLAT: '+39.2113329',
        INTPTLON: '-091.8772718',
        ELSDLEA: '',
        UNSDLEA: '20810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3105, 39.3341],
            [-92.2456, 39.3457],
            [-92.2272, 39.345],
            [-92.2587, 39.1896],
            [-92.3186, 39.1377],
            [-92.3755, 39.1604],
            [-92.3725, 39.2477],
            [-92.3105, 39.3341],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929700',
        NAME: 'Sturgeon R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255188417,
        AWATER: 738496,
        INTPTLAT: '+39.2487705',
        INTPTLON: '-092.3087097',
        ELSDLEA: '',
        UNSDLEA: '29700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3182, 38.6],
            [-90.3088, 38.63],
            [-90.3028, 38.6496],
            [-90.2952, 38.664],
            [-90.265, 38.7022],
            [-90.2397, 38.7236],
            [-90.1856, 38.7633],
            [-90.1666, 38.7724],
            [-90.1968, 38.6881],
            [-90.1868, 38.6753],
            [-90.1816, 38.6604],
            [-90.1786, 38.6461],
            [-90.1869, 38.6079],
            [-90.2241, 38.5739],
            [-90.2574, 38.5319],
            [-90.2803, 38.552],
            [-90.307, 38.5722],
            [-90.3174, 38.5874],
            [-90.3182, 38.6],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929280',
        NAME: 'St. Louis City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160458041,
        AWATER: 10670040,
        INTPTLAT: '+38.6356988',
        INTPTLON: '-090.2445816',
        ELSDLEA: '',
        UNSDLEA: '29280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3076, 39.6886],
            [-93.267, 39.7036],
            [-93.0468, 39.7055],
            [-92.9718, 39.6512],
            [-93.013, 39.5841],
            [-93.0337, 39.5476],
            [-93.0908, 39.497],
            [-93.2295, 39.4417],
            [-93.2829, 39.5328],
            [-93.2616, 39.6292],
            [-93.3076, 39.6886],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922920',
        NAME: 'Northwestern R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497679460,
        AWATER: 18389791,
        INTPTLAT: '+39.5894294',
        INTPTLON: '-093.1619480',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2751, 39.351],
            [-93.2295, 39.4417],
            [-93.0908, 39.497],
            [-93.0337, 39.5476],
            [-93.0392, 39.4088],
            [-92.9555, 39.3474],
            [-92.9599, 39.3194],
            [-92.9606, 39.3129],
            [-92.9685, 39.3152],
            [-93.0744, 39.338],
            [-93.1643, 39.405],
            [-93.2527, 39.3388],
            [-93.2751, 39.351],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906030',
        NAME: 'Brunswick R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304091808,
        AWATER: 11658443,
        INTPTLAT: '+39.4171277',
        INTPTLON: '-093.1075442',
        ELSDLEA: '',
        UNSDLEA: '06030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0337, 39.5476],
            [-93.013, 39.5841],
            [-92.8751, 39.5853],
            [-92.8737, 39.3454],
            [-92.9555, 39.3474],
            [-93.0392, 39.4088],
            [-93.0337, 39.5476],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916530',
        NAME: 'Keytesville R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340546670,
        AWATER: 3825247,
        INTPTLAT: '+39.4752111',
        INTPTLON: '-092.9334326',
        ELSDLEA: '',
        UNSDLEA: '16530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.609, 39.8676],
            [-92.5179, 39.7891],
            [-92.5533, 39.6955],
            [-92.6159, 39.6525],
            [-92.594, 39.7582],
            [-92.609, 39.8676],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904980',
        NAME: 'Bevier C-4 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148214586,
        AWATER: 3020900,
        INTPTLAT: '+39.7523608',
        INTPTLON: '-092.5689102',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9445, 39.9643],
            [-92.9595, 40.0364],
            [-92.8562, 40.0373],
            [-92.8475, 40.0373],
            [-92.8216, 39.6999],
            [-92.9636, 39.7897],
            [-92.9445, 39.9643],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906090',
        NAME: 'Bucklin R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333237578,
        AWATER: 1577894,
        INTPTLAT: '+39.8841197',
        INTPTLON: '-092.8887897',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2915, 39.822],
            [-92.2747, 39.8519],
            [-92.1893, 39.8671],
            [-92.182, 39.7787],
            [-91.845, 39.7634],
            [-91.9026, 39.6954],
            [-91.9122, 39.601],
            [-92.1211, 39.6034],
            [-92.1841, 39.604],
            [-92.2993, 39.6053],
            [-92.2915, 39.822],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928110',
        NAME: 'Shelby County R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760827978,
        AWATER: 2502070,
        INTPTLAT: '+39.6978712',
        INTPTLON: '-092.1055411',
        ELSDLEA: '',
        UNSDLEA: '28110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8653, 37.2081],
            [-92.6923, 37.2635],
            [-92.6798, 37.2397],
            [-92.6849, 37.0904],
            [-92.8519, 37.0688],
            [-92.8653, 37.2081],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927930',
        NAME: 'Seymour R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311192365,
        AWATER: 357105,
        INTPTLAT: '+37.1582384',
        INTPTLON: '-092.7655113',
        ELSDLEA: '',
        UNSDLEA: '27930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2367, 38.334],
            [-94.1766, 38.4459],
            [-94.1477, 38.4507],
            [-94.0674, 38.4032],
            [-94.0705, 38.3305],
            [-94.0737, 38.2581],
            [-94.184, 38.2593],
            [-94.2367, 38.334],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2904170',
        NAME: 'Ballard R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250793098,
        AWATER: 819019,
        INTPTLAT: '+38.3533904',
        INTPTLON: '-094.1374177',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4575, 38.3387],
            [-94.2367, 38.334],
            [-94.184, 38.2593],
            [-94.2013, 38.1516],
            [-94.3684, 38.1634],
            [-94.4805, 38.1944],
            [-94.4927, 38.202],
            [-94.4575, 38.3387],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906360',
        NAME: 'Butler R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460533971,
        AWATER: 3900383,
        INTPTLAT: '+38.2483026',
        INTPTLON: '-094.3370772',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5105, 39.6854],
            [-91.4522, 39.7726],
            [-91.369, 39.8015],
            [-91.3651, 39.7587],
            [-91.3678, 39.729],
            [-91.178, 39.5982],
            [-91.3235, 39.599],
            [-91.4284, 39.6486],
            [-91.5107, 39.644],
            [-91.5105, 39.6854],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913650',
        NAME: 'Hannibal 60 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250437062,
        AWATER: 12539911,
        INTPTLAT: '+39.6739729',
        INTPTLON: '-091.3652220',
        ELSDLEA: '',
        UNSDLEA: '13650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2057, 37.0079],
            [-94.0782, 37.0196],
            [-94.004, 37.0851],
            [-93.8974, 37.0263],
            [-93.9823, 36.9081],
            [-93.9617, 36.871],
            [-94.0023, 36.8285],
            [-94.0656, 36.8593],
            [-94.0741, 36.939],
            [-94.2057, 37.0079],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925110',
        NAME: 'Pierce City R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318310313,
        AWATER: 607498,
        INTPTLAT: '+36.9726544',
        INTPTLON: '-094.0288276',
        ELSDLEA: '',
        UNSDLEA: '25110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.07, 36.69],
            [-93.9739, 36.7448],
            [-93.9564, 36.7445],
            [-93.933, 36.6367],
            [-94.0709, 36.6529],
            [-94.07, 36.69],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911670',
        NAME: 'Exeter R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99133356,
        AWATER: 196409,
        INTPTLAT: '+36.6771723',
        INTPTLON: '-093.9706050',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0709, 36.6529],
            [-93.933, 36.6367],
            [-93.8323, 36.5692],
            [-93.8343, 36.4989],
            [-93.8668, 36.4987],
            [-93.9266, 36.4987],
            [-93.9895, 36.4987],
            [-94.0318, 36.4988],
            [-94.116, 36.4986],
            [-94.155, 36.5913],
            [-94.0709, 36.6529],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928710',
        NAME: 'Southwest R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366658199,
        AWATER: 293161,
        INTPTLAT: '+36.5656406',
        INTPTLON: '-094.0010156',
        ELSDLEA: '',
        UNSDLEA: '28710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8974, 37.0263],
            [-93.8704, 37.0226],
            [-93.8288, 36.883],
            [-93.9617, 36.871],
            [-93.9823, 36.9081],
            [-93.8974, 37.0263],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921120',
        NAME: 'Monett R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180256165,
        AWATER: 387769,
        INTPTLAT: '+36.9305215',
        INTPTLON: '-093.8952248',
        ELSDLEA: '',
        UNSDLEA: '21120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0023, 36.8285],
            [-93.9617, 36.871],
            [-93.8288, 36.883],
            [-93.771, 36.8452],
            [-93.7708, 36.8166],
            [-93.9564, 36.7445],
            [-93.9739, 36.7448],
            [-94.0023, 36.8285],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925620',
        NAME: 'Purdy R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193724830,
        AWATER: 169334,
        INTPTLAT: '+36.8202093',
        INTPTLON: '-093.8897950',
        ELSDLEA: '',
        UNSDLEA: '25620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1971, 38.2069],
            [-92.1717, 38.2917],
            [-91.8627, 38.2929],
            [-91.7995, 38.2432],
            [-91.8019, 38.0955],
            [-91.8749, 38.0512],
            [-91.8762, 38.0512],
            [-91.8945, 38.0512],
            [-91.9663, 38.0097],
            [-92.1822, 38.1315],
            [-92.1971, 38.2069],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930870',
        NAME: 'Maries County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 841282488,
        AWATER: 5625392,
        INTPTLAT: '+38.1700124',
        INTPTLON: '-091.9852415',
        ELSDLEA: '',
        UNSDLEA: '30870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4063, 38.0212],
            [-92.1854, 38.0163],
            [-92.1576, 37.8664],
            [-92.2853, 37.9012],
            [-92.4069, 37.977],
            [-92.4063, 38.0212],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910350',
        NAME: 'Crocker R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225395534,
        AWATER: 798015,
        INTPTLAT: '+37.9574517',
        INTPTLON: '-092.2670904',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4112, 37.7968],
            [-92.4112, 37.797],
            [-92.3267, 37.8647],
            [-92.3074, 37.859],
            [-92.242, 37.7987],
            [-92.2489, 37.6485],
            [-92.3, 37.669],
            [-92.3942, 37.7135],
            [-92.4112, 37.7968],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2917880',
        NAME: 'Laquey R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224551698,
        AWATER: 993421,
        INTPTLAT: '+37.7557188',
        INTPTLON: '-092.3070446',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4973, 40.2488],
            [-91.5053, 40.2005],
            [-91.4973, 40.0771],
            [-91.6144, 40.099],
            [-91.6681, 40.2085],
            [-91.7982, 40.1716],
            [-91.8936, 40.2561],
            [-91.7854, 40.3011],
            [-91.7229, 40.2534],
            [-91.4973, 40.2488],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907080',
        NAME: 'Canton R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351957170,
        AWATER: 6792491,
        INTPTLAT: '+40.1997283',
        INTPTLON: '-091.6520394',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3129, 37.5781],
            [-91.2457, 37.5903],
            [-91.1738, 37.5885],
            [-91.0609, 37.5054],
            [-91.0095, 37.4491],
            [-91.0299, 37.358],
            [-91.1571, 37.3996],
            [-91.1593, 37.255],
            [-91.3761, 37.2728],
            [-91.3186, 37.3305],
            [-91.3708, 37.4182],
            [-91.3142, 37.5051],
            [-91.3129, 37.5781],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906170',
        NAME: 'Bunker R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770812608,
        AWATER: 1566052,
        INTPTLAT: '+37.4346786',
        INTPTLON: '-091.2168978',
        ELSDLEA: '',
        UNSDLEA: '06170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.554, 37.6595],
            [-91.3658, 37.7008],
            [-91.274, 37.6994],
            [-91.2457, 37.5903],
            [-91.3129, 37.5781],
            [-91.5177, 37.5951],
            [-91.5542, 37.6253],
            [-91.554, 37.6595],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927090',
        NAME: 'Salem R-80 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275107007,
        AWATER: 604468,
        INTPTLAT: '+37.6396289',
        INTPTLON: '-091.3742536',
        ELSDLEA: '',
        UNSDLEA: '27090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8583, 37.2106],
            [-91.8194, 37.251],
            [-91.7555, 37.3496],
            [-91.6466, 37.4227],
            [-91.5554, 37.421],
            [-91.5375, 37.3589],
            [-91.539, 37.2463],
            [-91.4717, 37.2085],
            [-91.472, 37.1189],
            [-91.5461, 37.0768],
            [-91.6712, 37.0489],
            [-91.8173, 37.0787],
            [-91.8711, 37.1383],
            [-91.8583, 37.2106],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2929810',
        NAME: 'Summersville R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 921850150,
        AWATER: 306243,
        INTPTLAT: '+37.2133105',
        INTPTLON: '-091.6620205',
        ELSDLEA: '',
        UNSDLEA: '29810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9497, 40.3008],
            [-91.9456, 40.4318],
            [-91.9102, 40.4601],
            [-91.8343, 40.4298],
            [-91.8329, 40.5613],
            [-91.9073, 40.607],
            [-91.8696, 40.608],
            [-91.7291, 40.6136],
            [-91.6912, 40.5657],
            [-91.5237, 40.4556],
            [-91.4194, 40.3783],
            [-91.422, 40.3759],
            [-91.4973, 40.2488],
            [-91.7229, 40.2534],
            [-91.7854, 40.3011],
            [-91.8936, 40.2561],
            [-91.9507, 40.262],
            [-91.9497, 40.3008],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916380',
        NAME: 'Clark County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1113176878,
        AWATER: 17530652,
        INTPTLAT: '+40.3977548',
        INTPTLON: '-091.7143269',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6176, 39.9224],
            [-91.4355, 39.9452],
            [-91.4371, 39.8488],
            [-91.369, 39.8015],
            [-91.4522, 39.7726],
            [-91.5105, 39.6854],
            [-91.6652, 39.7224],
            [-91.6764, 39.7731],
            [-91.6176, 39.9224],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923490',
        NAME: 'Palmyra R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497089739,
        AWATER: 11187943,
        INTPTLAT: '+39.8155096',
        INTPTLON: '-091.5403548',
        ELSDLEA: '',
        UNSDLEA: '23490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4992, 40.2068],
            [-93.4565, 40.2654],
            [-93.3766, 40.2662],
            [-93.3396, 40.2232],
            [-93.2348, 40.0911],
            [-93.31, 40.0339],
            [-93.362, 40.0331],
            [-93.4948, 40.0761],
            [-93.4994, 40.1195],
            [-93.4992, 40.2068],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912690',
        NAME: 'Grundy County R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376074544,
        AWATER: 2312300,
        INTPTLAT: '+40.1412634',
        INTPTLON: '-093.3858432',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3744, 40.5803],
            [-93.2889, 40.5808],
            [-92.9854, 40.5869],
            [-92.7537, 40.589],
            [-92.7146, 40.5894],
            [-92.7058, 40.3865],
            [-92.6842, 40.3435],
            [-92.6869, 40.3434],
            [-92.8556, 40.3427],
            [-92.8553, 40.3859],
            [-93.0262, 40.3857],
            [-93.1957, 40.3855],
            [-93.3098, 40.3835],
            [-93.367, 40.4266],
            [-93.3668, 40.4559],
            [-93.3744, 40.5803],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925640',
        NAME: 'Putnam County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1308387516,
        AWATER: 5900143,
        INTPTLAT: '+40.4813202',
        INTPTLON: '-093.0145633',
        ELSDLEA: '',
        UNSDLEA: '25640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1957, 40.3855],
            [-93.0262, 40.3857],
            [-93.0294, 40.2548],
            [-92.9969, 40.1742],
            [-93.0031, 40.0365],
            [-93.083, 40.1027],
            [-93.2348, 40.0911],
            [-93.3396, 40.2232],
            [-93.3103, 40.2963],
            [-93.196, 40.2979],
            [-93.1957, 40.3855],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920940',
        NAME: 'Milan C-2 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 684139542,
        AWATER: 4555917,
        INTPTLAT: '+40.2171194',
        INTPTLON: '-093.1437203',
        ELSDLEA: '',
        UNSDLEA: '20940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2322, 40.5719],
            [-94.0721, 40.573],
            [-93.8248, 40.577],
            [-93.8014, 40.5773],
            [-93.8805, 40.4384],
            [-94.0636, 40.4113],
            [-94.1101, 40.4705],
            [-94.2146, 40.4705],
            [-94.2322, 40.5719],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922770',
        NAME: 'North Harrison R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494438981,
        AWATER: 2777434,
        INTPTLAT: '+40.5054950',
        INTPTLON: '-094.0261951',
        ELSDLEA: '',
        UNSDLEA: '22770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3747, 39.6479],
            [-94.3557, 39.6597],
            [-94.2615, 39.6586],
            [-94.2072, 39.5923],
            [-94.1566, 39.5698],
            [-94.1533, 39.5262],
            [-94.2856, 39.4553],
            [-94.3728, 39.4846],
            [-94.3747, 39.6479],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2917970',
        NAME: 'Lathrop R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290887336,
        AWATER: 695783,
        INTPTLAT: '+39.5643936',
        INTPTLON: '-094.2866532',
        ELSDLEA: '',
        UNSDLEA: '17970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4289, 39.7914],
            [-94.3195, 39.8303],
            [-94.3182, 39.7037],
            [-94.3557, 39.6597],
            [-94.3747, 39.6479],
            [-94.4323, 39.7037],
            [-94.4289, 39.7914],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923250',
        NAME: 'Osborn R-O School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140362544,
        AWATER: 1704134,
        INTPTLAT: '+39.7569727',
        INTPTLON: '-094.3668237',
        ELSDLEA: '',
        UNSDLEA: '23250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6177, 37.7555],
            [-94.4573, 37.8105],
            [-94.345, 37.7062],
            [-94.3472, 37.6482],
            [-94.37, 37.6338],
            [-94.6177, 37.6536],
            [-94.6178, 37.6731],
            [-94.6177, 37.7555],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905910',
        NAME: 'Bronaugh R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323223152,
        AWATER: 2212585,
        INTPTLAT: '+37.7148262',
        INTPTLON: '-094.4817506',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6177, 37.6536],
            [-94.37, 37.6338],
            [-94.3911, 37.4152],
            [-94.5169, 37.3612],
            [-94.6176, 37.3642],
            [-94.6173, 37.4256],
            [-94.6169, 37.4916],
            [-94.6177, 37.6536],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918510',
        NAME: 'Liberal R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645792546,
        AWATER: 5308789,
        INTPTLAT: '+37.5252891',
        INTPTLON: '-094.5057903',
        ELSDLEA: '',
        UNSDLEA: '18510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2485, 36.1308],
            [-90.2356, 36.1417],
            [-90.1902, 36.2014],
            [-90.0879, 36.2715],
            [-90.0511, 36.2658],
            [-89.9911, 36.2224],
            [-89.9596, 36.1325],
            [-89.9594, 35.9991],
            [-90.1194, 35.9979],
            [-90.195, 35.9972],
            [-90.1692, 36.0611],
            [-90.2485, 36.1308],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927870',
        NAME: 'Senath-Hornersville C-8 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540395727,
        AWATER: 8005078,
        INTPTLAT: '+36.1054164',
        INTPTLON: '-090.0977884',
        ELSDLEA: '',
        UNSDLEA: '27870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2485, 36.1308],
            [-90.1692, 36.0611],
            [-90.195, 35.9972],
            [-90.3779, 35.9957],
            [-90.2485, 36.1308],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928620',
        NAME: 'Southland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144092789,
        AWATER: 1085862,
        INTPTLAT: '+36.0372917',
        INTPTLON: '-090.2750649',
        ELSDLEA: '',
        UNSDLEA: '28620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9173, 38.5642],
            [-93.8501, 38.5485],
            [-93.8649, 38.4615],
            [-93.7348, 38.4593],
            [-93.6261, 38.3773],
            [-93.6309, 38.2694],
            [-93.8144, 38.3022],
            [-93.8129, 38.3533],
            [-93.9008, 38.4137],
            [-93.9173, 38.5642],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909860',
        NAME: 'Clinton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401611911,
        AWATER: 31724507,
        INTPTLAT: '+38.3926601',
        INTPTLON: '-093.7840214',
        ELSDLEA: '',
        UNSDLEA: '09860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1413, 36.7976],
            [-93.0856, 36.7126],
            [-92.966, 36.7147],
            [-92.9236, 36.668],
            [-92.9681, 36.6057],
            [-92.9294, 36.4979],
            [-93.0137, 36.4981],
            [-93.0144, 36.4981],
            [-93.1347, 36.6513],
            [-93.1413, 36.7976],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912240',
        NAME: 'Forsyth R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338889411,
        AWATER: 20221782,
        INTPTLAT: '+36.6217480',
        INTPTLON: '-093.0552706',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.8324, 36.8184],
              [-91.7868, 36.733],
              [-91.8509, 36.7119],
              [-91.887, 36.7429],
              [-91.8324, 36.8184],
            ],
          ],
          [
            [
              [-92.1164, 36.6514],
              [-91.9712, 36.6427],
              [-91.8987, 36.6847],
              [-91.931, 36.62],
              [-91.9258, 36.4986],
              [-91.9364, 36.4986],
              [-92.1125, 36.4989],
              [-92.1164, 36.6514],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931680',
        NAME: 'West Plains R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336513273,
        AWATER: 320915,
        INTPTLAT: '+36.6074093',
        INTPTLON: '-091.9944366',
        ELSDLEA: '',
        UNSDLEA: '31680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4616, 37.0099],
            [-94.3681, 37.09],
            [-94.3673, 37.0973],
            [-94.2591, 37.0529],
            [-94.2242, 37.0085],
            [-94.3166, 36.9441],
            [-94.4079, 36.9564],
            [-94.4616, 37.0099],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910800',
        NAME: 'Diamond R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210863359,
        AWATER: 613572,
        INTPTLAT: '+37.0209641',
        INTPTLON: '-094.3522765',
        ELSDLEA: '',
        UNSDLEA: '10800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4705, 37.269],
            [-94.3992, 37.252],
            [-94.4365, 37.2134],
            [-94.3673, 37.0973],
            [-94.3681, 37.09],
            [-94.5115, 37.1246],
            [-94.4705, 37.269],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931500',
        NAME: 'Webb City R-VII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151045793,
        AWATER: 246968,
        INTPTLAT: '+37.1741017',
        INTPTLON: '-094.4426142',
        ELSDLEA: '',
        UNSDLEA: '31500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2591, 37.0529],
            [-94.1826, 37.1553],
            [-94.0562, 37.1547],
            [-94.0246, 37.1395],
            [-94.004, 37.0851],
            [-94.0782, 37.0196],
            [-94.2057, 37.0079],
            [-94.2242, 37.0085],
            [-94.2591, 37.0529],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927540',
        NAME: 'Sarcoxie R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248784766,
        AWATER: 1851499,
        INTPTLAT: '+37.0769094',
        INTPTLON: '-094.1237424',
        ELSDLEA: '',
        UNSDLEA: '27540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3992, 37.252],
            [-94.2529, 37.2487],
            [-94.1826, 37.1553],
            [-94.2591, 37.0529],
            [-94.3673, 37.0973],
            [-94.4365, 37.2134],
            [-94.3992, 37.252],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907460',
        NAME: 'Carthage R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324238567,
        AWATER: 1188431,
        INTPTLAT: '+37.1632099',
        INTPTLON: '-094.3086709',
        ELSDLEA: '',
        UNSDLEA: '07460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.618, 36.9989],
            [-94.618, 37.0309],
            [-94.618, 37.0327],
            [-94.5115, 37.1246],
            [-94.3681, 37.09],
            [-94.4616, 37.0099],
            [-94.4813, 37.0102],
            [-94.618, 36.9959],
            [-94.618, 36.9989],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916350',
        NAME: 'Joplin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180325247,
        AWATER: 931570,
        INTPTLAT: '+37.0631676',
        INTPTLON: '-094.5042859',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8724, 39.532],
            [-94.8662, 39.532],
            [-94.7774, 39.5319],
            [-94.7178, 39.5869],
            [-94.6064, 39.4923],
            [-94.6586, 39.4062],
            [-94.7657, 39.3819],
            [-94.8144, 39.4333],
            [-94.8724, 39.532],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922830',
        NAME: 'North Platte County R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290128130,
        AWATER: 1691510,
        INTPTLAT: '+39.4739520',
        INTPTLON: '-094.7322134',
        ELSDLEA: '',
        UNSDLEA: '22830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1029, 39.5333],
            [-94.8724, 39.532],
            [-94.8144, 39.4333],
            [-94.8379, 39.2624],
            [-94.8577, 39.2741],
            [-94.9085, 39.3298],
            [-94.9382, 39.3871],
            [-94.969, 39.4189],
            [-95.1029, 39.5333],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931710',
        NAME: 'West Platte County R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341495985,
        AWATER: 8741467,
        INTPTLAT: '+39.4399236',
        INTPTLON: '-094.9173764',
        ELSDLEA: '',
        UNSDLEA: '31710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8544, 38.2089],
            [-93.8506, 38.2963],
            [-93.8144, 38.3022],
            [-93.6309, 38.2694],
            [-93.5956, 38.2423],
            [-93.4973, 38.1991],
            [-93.5003, 38.1454],
            [-93.7809, 38.0952],
            [-93.8011, 38.0556],
            [-93.8373, 38.0679],
            [-93.8544, 38.2089],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910520',
        NAME: 'Lakeland R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445483706,
        AWATER: 46130762,
        INTPTLAT: '+38.1777354',
        INTPTLON: '-093.7100905',
        ELSDLEA: '',
        UNSDLEA: '10520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5183, 38.3218],
            [-93.2958, 38.3243],
            [-93.2066, 38.2577],
            [-93.0777, 38.2631],
            [-93.0627, 38.1135],
            [-93.1202, 38.0627],
            [-93.3111, 38.0666],
            [-93.4496, 38.0808],
            [-93.5033, 38.0875],
            [-93.5003, 38.1454],
            [-93.4973, 38.1991],
            [-93.5956, 38.2423],
            [-93.5183, 38.3218],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2931070',
        NAME: 'Warsaw R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 826550569,
        AWATER: 111770910,
        INTPTLAT: '+38.1945677',
        INTPTLON: '-093.3212983',
        ELSDLEA: '',
        UNSDLEA: '31070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8432, 38.6908],
            [-93.8252, 38.6902],
            [-93.7827, 38.6595],
            [-93.7885, 38.562],
            [-93.8501, 38.5485],
            [-93.9173, 38.5642],
            [-93.9704, 38.5945],
            [-93.8922, 38.6113],
            [-93.8432, 38.6908],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2908730',
        NAME: 'Chilhowee R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153126485,
        AWATER: 318697,
        INTPTLAT: '+38.6126895',
        INTPTLON: '-093.8457845',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2133, 38.4811],
            [-92.0678, 38.5291],
            [-92.0085, 38.4944],
            [-92.1493, 38.4312],
            [-92.2247, 38.3357],
            [-92.2595, 38.377],
            [-92.2133, 38.4811],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909930',
        NAME: 'Blair Oaks R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181876397,
        AWATER: 5426494,
        INTPTLAT: '+38.3794864',
        INTPTLON: '-092.1940557',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4035, 38.4253],
            [-92.2869, 38.511],
            [-92.2133, 38.4811],
            [-92.2595, 38.377],
            [-92.2247, 38.3357],
            [-92.233, 38.3277],
            [-92.3067, 38.2556],
            [-92.3857, 38.2764],
            [-92.4673, 38.2981],
            [-92.4035, 38.4253],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911550',
        NAME: 'Cole County R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368257036,
        AWATER: 3221034,
        INTPTLAT: '+38.3810875',
        INTPTLON: '-092.3316569',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4156, 38.674],
            [-92.3355, 38.6732],
            [-92.2191, 38.6719],
            [-91.9922, 38.6707],
            [-91.8819, 38.6527],
            [-91.9108, 38.6215],
            [-92.0259, 38.552],
            [-92.0085, 38.4944],
            [-92.0678, 38.5291],
            [-92.2133, 38.4811],
            [-92.2869, 38.511],
            [-92.3401, 38.5786],
            [-92.446, 38.5797],
            [-92.4156, 38.674],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2916190',
        NAME: 'Jefferson City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557476747,
        AWATER: 18425268,
        INTPTLAT: '+38.5944158',
        INTPTLON: '-092.1801912',
        ELSDLEA: '',
        UNSDLEA: '16190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8286, 38.8102],
            [-91.6423, 38.8437],
            [-91.6423, 38.8418],
            [-91.6472, 38.7034],
            [-91.7323, 38.7059],
            [-91.8819, 38.6527],
            [-91.9922, 38.6707],
            [-92.0078, 38.7555],
            [-92.0059, 38.7997],
            [-91.8286, 38.8102],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928430',
        NAME: 'South Callaway County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498481026,
        AWATER: 9610141,
        INTPTLAT: '+38.7552188',
        INTPTLON: '-091.8221010',
        ELSDLEA: '',
        UNSDLEA: '28430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6896, 39.0189],
            [-91.651, 39.0135],
            [-91.5207, 39.0567],
            [-91.3636, 39.0529],
            [-91.2667, 39.0826],
            [-91.2629, 39.0529],
            [-91.2645, 38.9848],
            [-91.2462, 38.8297],
            [-91.4166, 38.8309],
            [-91.6423, 38.8418],
            [-91.6423, 38.8437],
            [-91.6391, 38.9393],
            [-91.6896, 39.0189],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921330',
        NAME: 'Montgomery County R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 822038617,
        AWATER: 6094013,
        INTPTLAT: '+38.9442685',
        INTPTLON: '-091.4524860',
        ELSDLEA: '',
        UNSDLEA: '21330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1453, 38.9705],
            [-92.0967, 38.9208],
            [-91.9051, 38.9078],
            [-91.8286, 38.8102],
            [-92.0059, 38.7997],
            [-92.0078, 38.7555],
            [-92.0406, 38.8446],
            [-92.1487, 38.8466],
            [-92.1479, 38.8473],
            [-92.1453, 38.9705],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912550',
        NAME: 'Fulton 58 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306886208,
        AWATER: 4538905,
        INTPTLAT: '+38.8601538',
        INTPTLON: '-092.0067023',
        ELSDLEA: '',
        UNSDLEA: '12550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1633, 37.1836],
            [-93.032, 37.2346],
            [-92.9905, 37.209],
            [-92.9936, 37.0877],
            [-92.9576, 37.0607],
            [-93.1303, 37.0483],
            [-93.2197, 37.0922],
            [-93.1633, 37.1836],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919170',
        NAME: 'Logan-Rogersville R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317572178,
        AWATER: 772959,
        INTPTLAT: '+37.1292648',
        INTPTLON: '-093.0789321',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6086, 37.1127],
            [-93.608, 37.142],
            [-93.6068, 37.2003],
            [-93.4505, 37.1989],
            [-93.3493, 37.1976],
            [-93.3527, 37.095],
            [-93.3905, 37.0714],
            [-93.4905, 37.0712],
            [-93.6089, 37.0982],
            [-93.6086, 37.1127],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926220',
        NAME: 'Republic R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259789954,
        AWATER: 205479,
        INTPTLAT: '+37.1438738',
        INTPTLON: '-093.4846925',
        ELSDLEA: '',
        UNSDLEA: '26220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6217, 37.4128],
            [-93.5654, 37.4608],
            [-93.4557, 37.4242],
            [-93.5002, 37.3478],
            [-93.623, 37.3692],
            [-93.6217, 37.4128],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930990',
        NAME: 'Walnut Grove R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137669549,
        AWATER: 76980,
        INTPTLAT: '+37.4061692',
        INTPTLON: '-093.5463783',
        ELSDLEA: '',
        UNSDLEA: '30990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5907, 37.5289],
            [-93.5069, 37.5439],
            [-93.3427, 37.5375],
            [-93.3722, 37.443],
            [-93.3327, 37.3648],
            [-93.4557, 37.4242],
            [-93.5654, 37.4608],
            [-93.5907, 37.5289],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920160',
        NAME: 'Marion C. Early R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285873621,
        AWATER: 1988998,
        INTPTLAT: '+37.4793132',
        INTPTLON: '-093.4474672',
        ELSDLEA: '',
        UNSDLEA: '20160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3493, 37.1976],
            [-93.2934, 37.3615],
            [-93.2383, 37.3749],
            [-93.186, 37.3094],
            [-93.1633, 37.1836],
            [-93.2197, 37.0922],
            [-93.2719, 37.0938],
            [-93.3527, 37.095],
            [-93.3493, 37.1976],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928860',
        NAME: 'Springfield R-XII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 353705192,
        AWATER: 3721166,
        INTPTLAT: '+37.2086413',
        INTPTLON: '-093.2725847',
        ELSDLEA: '',
        UNSDLEA: '28860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3427, 37.5375],
            [-93.3143, 37.5747],
            [-93.2799, 37.5179],
            [-93.1799, 37.5187],
            [-93.1816, 37.4536],
            [-93.2383, 37.3749],
            [-93.2934, 37.3615],
            [-93.3327, 37.3648],
            [-93.3722, 37.443],
            [-93.3427, 37.5375],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925350',
        NAME: 'Pleasant Hope R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230514621,
        AWATER: 961308,
        INTPTLAT: '+37.4615894',
        INTPTLON: '-093.2783088',
        ELSDLEA: '',
        UNSDLEA: '25350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8965, 38.9405],
            [-92.8476, 38.9809],
            [-92.5581, 38.9707],
            [-92.4805, 38.9089],
            [-92.5958, 38.9031],
            [-92.7226, 38.8612],
            [-92.852, 38.8689],
            [-92.8965, 38.9405],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905580',
        NAME: 'Boonville R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322944823,
        AWATER: 7269946,
        INTPTLAT: '+38.9228934',
        INTPTLON: '-092.7138917',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0333, 38.7954],
            [-93.0221, 38.7572],
            [-92.8811, 38.7285],
            [-92.9505, 38.686],
            [-92.9531, 38.6126],
            [-93.0036, 38.6164],
            [-93.062, 38.6491],
            [-93.0333, 38.7954],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2923310',
        NAME: 'Otterville R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213740897,
        AWATER: 107496,
        INTPTLAT: '+38.6973462',
        INTPTLON: '-092.9549504',
        ELSDLEA: '',
        UNSDLEA: '23310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8811, 38.7285],
            [-92.8804, 38.7584],
            [-92.7258, 38.7136],
            [-92.7295, 38.6096],
            [-92.7336, 38.5142],
            [-92.8243, 38.5817],
            [-92.8937, 38.5752],
            [-92.9531, 38.6126],
            [-92.9505, 38.686],
            [-92.8811, 38.7285],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930330',
        NAME: 'Tipton R-VI School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320189740,
        AWATER: 534016,
        INTPTLAT: '+38.6444455',
        INTPTLON: '-092.8156777',
        ELSDLEA: '',
        UNSDLEA: '30330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.052, 38.8842],
            [-92.8965, 38.9405],
            [-92.852, 38.8689],
            [-92.8804, 38.7584],
            [-92.8811, 38.7285],
            [-93.0221, 38.7572],
            [-93.0333, 38.7954],
            [-93.0536, 38.8417],
            [-93.052, 38.8842],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925170',
        NAME: 'Pilot Grove C-4 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 289780315,
        AWATER: 844861,
        INTPTLAT: '+38.8527866',
        INTPTLON: '-092.9579974',
        ELSDLEA: '',
        UNSDLEA: '25170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2519, 37.3428],
            [-92.1978, 37.3859],
            [-92.0398, 37.3846],
            [-91.9443, 37.433],
            [-91.8913, 37.3827],
            [-91.8675, 37.2554],
            [-91.8194, 37.251],
            [-91.8583, 37.2106],
            [-91.9968, 37.2084],
            [-92.0994, 37.2541],
            [-92.1878, 37.2401],
            [-92.2519, 37.3428],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914840',
        NAME: 'Houston R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514468433,
        AWATER: 1370602,
        INTPTLAT: '+37.3116066',
        INTPTLON: '-092.0168452',
        ELSDLEA: '',
        UNSDLEA: '14840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.417, 37.3084],
            [-92.2974, 37.3143],
            [-92.2523, 37.3572],
            [-92.2519, 37.3428],
            [-92.1878, 37.2401],
            [-92.1778, 37.1599],
            [-92.2372, 37.0594],
            [-92.2367, 36.9282],
            [-92.3473, 36.8756],
            [-92.3467, 36.8868],
            [-92.3791, 37.0224],
            [-92.3497, 37.1637],
            [-92.4083, 37.2085],
            [-92.417, 37.3084],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921510',
        NAME: 'Mountain Grove R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 694940283,
        AWATER: 667395,
        INTPTLAT: '+37.1420783',
        INTPTLON: '-092.2986022',
        ELSDLEA: '',
        UNSDLEA: '21510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7182, 39.2994],
            [-92.5869, 39.2901],
            [-92.6007, 39.1873],
            [-92.4486, 39.2536],
            [-92.4302, 39.2488],
            [-92.5473, 39.0956],
            [-92.5313, 39.0566],
            [-92.5484, 39.0266],
            [-92.6616, 39.0799],
            [-92.8306, 39.0851],
            [-92.7685, 39.1265],
            [-92.7182, 39.2994],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2911990',
        NAME: 'Fayette R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507994374,
        AWATER: 4393435,
        INTPTLAT: '+39.1600521',
        INTPTLON: '-092.6381266',
        ELSDLEA: '',
        UNSDLEA: '11990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9555, 39.3474],
            [-92.8737, 39.3454],
            [-92.8751, 39.5853],
            [-92.7582, 39.6993],
            [-92.6895, 39.6981],
            [-92.616, 39.6525],
            [-92.6175, 39.6092],
            [-92.6986, 39.5688],
            [-92.7053, 39.3643],
            [-92.594, 39.3535],
            [-92.5869, 39.2901],
            [-92.7182, 39.2994],
            [-92.8205, 39.2867],
            [-92.9599, 39.3194],
            [-92.9555, 39.3474],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2927520',
        NAME: 'Salisbury R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 738084195,
        AWATER: 13213088,
        INTPTLAT: '+39.4823044',
        INTPTLON: '-092.7663277',
        ELSDLEA: '',
        UNSDLEA: '27520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9214, 39.0376],
            [-92.8306, 39.0851],
            [-92.6616, 39.0799],
            [-92.5484, 39.0266],
            [-92.5581, 38.9707],
            [-92.8476, 38.9809],
            [-92.9152, 39.0228],
            [-92.9214, 39.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2921940',
        NAME: 'New Franklin R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280623521,
        AWATER: 8274521,
        INTPTLAT: '+39.0239116',
        INTPTLON: '-092.7345961',
        ELSDLEA: '',
        UNSDLEA: '21940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2856, 39.4553],
            [-94.1533, 39.5262],
            [-94.0604, 39.4639],
            [-94.0986, 39.3484],
            [-94.1496, 39.3958],
            [-94.2668, 39.397],
            [-94.2856, 39.4553],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918220',
        NAME: 'Lawson R-XIV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250372242,
        AWATER: 648948,
        INTPTLAT: '+39.4435970',
        INTPTLON: '-094.1706120',
        ELSDLEA: '',
        UNSDLEA: '18220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8144, 39.4333],
            [-94.7657, 39.3819],
            [-94.6586, 39.4062],
            [-94.6007, 39.3109],
            [-94.601, 39.2246],
            [-94.6586, 39.2967],
            [-94.8262, 39.2448],
            [-94.8379, 39.2624],
            [-94.8144, 39.4333],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925230',
        NAME: 'Platte County R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255729312,
        AWATER: 1865520,
        INTPTLAT: '+39.3111220',
        INTPTLON: '-094.7251695',
        ELSDLEA: '',
        UNSDLEA: '25230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.492, 39.3098],
            [-94.492, 39.31],
            [-94.3283, 39.3103],
            [-94.2912, 39.274],
            [-94.3318, 39.1871],
            [-94.4031, 39.182],
            [-94.4459, 39.1819],
            [-94.492, 39.3098],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918540',
        NAME: 'Liberty 53 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212683192,
        AWATER: 1378787,
        INTPTLAT: '+39.2551406',
        INTPTLON: '-094.3973310',
        ELSDLEA: '',
        UNSDLEA: '18540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6012, 39.4921],
            [-94.5286, 39.5207],
            [-94.544, 39.631],
            [-94.5081, 39.6677],
            [-94.4323, 39.7037],
            [-94.3747, 39.6479],
            [-94.3728, 39.4846],
            [-94.4634, 39.4521],
            [-94.5507, 39.4414],
            [-94.6012, 39.4921],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2925290',
        NAME: 'Clinton County R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372293792,
        AWATER: 11789886,
        INTPTLAT: '+39.5616857',
        INTPTLON: '-094.4650632',
        ELSDLEA: '',
        UNSDLEA: '25290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6017, 39.1596],
            [-94.601, 39.2246],
            [-94.6007, 39.3109],
            [-94.492, 39.3098],
            [-94.4459, 39.1819],
            [-94.4031, 39.182],
            [-94.4195, 39.1493],
            [-94.4393, 39.1318],
            [-94.4554, 39.1231],
            [-94.5235, 39.1488],
            [-94.6074, 39.1134],
            [-94.6017, 39.1596],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922800',
        NAME: 'North Kansas City 74 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221791001,
        AWATER: 4617737,
        INTPTLAT: '+39.2102953',
        INTPTLON: '-094.5247343',
        ELSDLEA: '',
        UNSDLEA: '22800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6582, 36.8736],
            [-91.3693, 36.8857],
            [-91.3339, 36.8418],
            [-91.2245, 36.8404],
            [-91.1159, 36.8237],
            [-91.1229, 36.6426],
            [-91.1232, 36.6289],
            [-91.2434, 36.6118],
            [-91.3824, 36.6278],
            [-91.4605, 36.5939],
            [-91.5117, 36.6472],
            [-91.6766, 36.6939],
            [-91.6582, 36.8736],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903060',
        NAME: 'Alton R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1274430874,
        AWATER: 3173483,
        INTPTLAT: '+36.7420566',
        INTPTLON: '-091.3960160',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5117, 36.6472],
            [-91.4605, 36.5939],
            [-91.4073, 36.4971],
            [-91.6177, 36.4993],
            [-91.6178, 36.5643],
            [-91.5117, 36.6472],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2930270',
        NAME: 'Thayer R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199485974,
        AWATER: 58853,
        INTPTLAT: '+36.5574052',
        INTPTLON: '-091.4864024',
        ELSDLEA: '',
        UNSDLEA: '30270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0835, 36.6307],
            [-89.9588, 36.6293],
            [-89.9627, 36.6003],
            [-89.957, 36.5413],
            [-90.0307, 36.506],
            [-90.0835, 36.6307],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919890',
        NAME: 'Malden R-I School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107715842,
        AWATER: 233799,
        INTPTLAT: '+36.5765369',
        INTPTLON: '-090.0204497',
        ELSDLEA: '',
        UNSDLEA: '19890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9911, 36.2224],
            [-89.9823, 36.2366],
            [-89.982, 36.237],
            [-89.88, 36.2484],
            [-89.8078, 36.2339],
            [-89.7631, 36.1903],
            [-89.771, 36.1468],
            [-89.9596, 36.1325],
            [-89.9911, 36.2224],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2905730',
        NAME: 'Delta C-7 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227689061,
        AWATER: 2748355,
        INTPTLAT: '+36.1847578',
        INTPTLON: '-089.9011998',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1484, 36.6307],
            [-90.0835, 36.6307],
            [-90.0307, 36.506],
            [-90.0046, 36.4334],
            [-90.1188, 36.4125],
            [-90.1523, 36.4979],
            [-90.2207, 36.4979],
            [-90.1484, 36.6307],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2907050',
        NAME: 'Campbell R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254675467,
        AWATER: 2688302,
        INTPTLAT: '+36.5191812',
        INTPTLON: '-090.1119279',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1188, 36.4125],
            [-90.0046, 36.4334],
            [-89.9611, 36.3494],
            [-89.9609, 36.3231],
            [-89.982, 36.237],
            [-89.9823, 36.2366],
            [-90.0511, 36.2658],
            [-90.0879, 36.2715],
            [-90.078, 36.2883],
            [-90.0636, 36.3842],
            [-90.1188, 36.4125],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2914460',
        NAME: 'Holcomb R-III School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177131226,
        AWATER: 870801,
        INTPTLAT: '+36.3443130',
        INTPTLON: '-090.0155040',
        ELSDLEA: '',
        UNSDLEA: '14460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0307, 36.506],
            [-89.957, 36.5413],
            [-89.959, 36.5126],
            [-89.9613, 36.3888],
            [-89.9611, 36.3494],
            [-90.0046, 36.4334],
            [-90.0307, 36.506],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909120',
        NAME: 'Clarkton C-4 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82154469,
        AWATER: 13887,
        INTPTLAT: '+36.4535366',
        INTPTLON: '-089.9878764',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7266, 37.5461],
            [-93.616, 37.5727],
            [-93.5907, 37.5289],
            [-93.5654, 37.4608],
            [-93.6217, 37.4128],
            [-93.7207, 37.415],
            [-93.7266, 37.5461],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2910440',
        NAME: 'Dadeville R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179437607,
        AWATER: 8703068,
        INTPTLAT: '+37.4897917',
        INTPTLON: '-093.6580201',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0754, 37.5816],
            [-93.947, 37.5794],
            [-93.8953, 37.5206],
            [-93.8988, 37.3523],
            [-93.864, 37.2869],
            [-94.0523, 37.2901],
            [-94.0838, 37.2909],
            [-94.0754, 37.5816],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919140',
        NAME: 'Lockwood R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505916042,
        AWATER: 1508852,
        INTPTLAT: '+37.4214217',
        INTPTLON: '-093.9915749',
        ELSDLEA: '',
        UNSDLEA: '19140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.947, 37.5794],
            [-93.7624, 37.5763],
            [-93.7266, 37.5461],
            [-93.7207, 37.415],
            [-93.7645, 37.4016],
            [-93.7854, 37.2854],
            [-93.864, 37.2869],
            [-93.8988, 37.3523],
            [-93.8953, 37.5206],
            [-93.947, 37.5794],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913320',
        NAME: 'Greenfield R-IV School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404187379,
        AWATER: 29741085,
        INTPTLAT: '+37.4562701',
        INTPTLON: '-093.8290810',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7656, 40.5852],
            [-95.4992, 40.5819],
            [-95.4629, 40.4266],
            [-95.4253, 40.3872],
            [-95.4827, 40.3582],
            [-95.4652, 40.2639],
            [-95.5548, 40.2645],
            [-95.5935, 40.3059],
            [-95.6249, 40.3493],
            [-95.71, 40.5238],
            [-95.7656, 40.5852],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926790',
        NAME: 'Rock Port R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 564620091,
        AWATER: 7092526,
        INTPTLAT: '+40.4424621',
        INTPTLON: '-095.5734229',
        ELSDLEA: '',
        UNSDLEA: '26790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1015, 38.1052],
            [-94.0499, 38.214],
            [-93.8544, 38.2089],
            [-93.8373, 38.0679],
            [-93.8946, 38.003],
            [-94.0608, 37.9878],
            [-94.104, 38.0476],
            [-94.1015, 38.1052],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2903120',
        NAME: 'Appleton City R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473602198,
        AWATER: 13100072,
        INTPTLAT: '+38.0951221',
        INTPTLON: '-093.9587889',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8088, 36.4957],
            [-89.7016, 36.5047],
            [-89.5196, 36.4687],
            [-89.545, 36.3368],
            [-89.6113, 36.4113],
            [-89.8087, 36.3865],
            [-89.8088, 36.4957],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2900003',
        NAME: 'Portageville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251504874,
        AWATER: 15789808,
        INTPTLAT: '+36.4319856',
        INTPTLON: '-089.6683217',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6912, 36.9033],
            [-89.6917, 36.9389],
            [-89.575, 36.9356],
            [-89.4836, 36.9064],
            [-89.5198, 36.8696],
            [-89.638, 36.8608],
            [-89.7023, 36.7865],
            [-89.6912, 36.9033],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928260',
        NAME: 'Sikeston R-VI School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173903019,
        AWATER: 505097,
        INTPTLAT: '+36.8810932',
        INTPTLON: '-089.6209639',
        ELSDLEA: '',
        UNSDLEA: '28260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9627, 36.6003],
            [-89.7458, 36.5839],
            [-89.7016, 36.5047],
            [-89.8088, 36.4957],
            [-89.959, 36.5126],
            [-89.957, 36.5413],
            [-89.9627, 36.6003],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2926610',
        NAME: 'Risco R-II School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197417856,
        AWATER: 851333,
        INTPTLAT: '+36.5462114',
        INTPTLON: '-089.8376219',
        ELSDLEA: '',
        UNSDLEA: '26610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4905, 37.0712],
            [-93.3905, 37.0714],
            [-93.3935, 36.9534],
            [-93.519, 36.9902],
            [-93.4905, 37.0712],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2909780',
        NAME: 'Clever R-V School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165726076,
        AWATER: 469098,
        INTPTLAT: '+37.0134722',
        INTPTLON: '-093.4389213',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9905, 37.209],
            [-92.8653, 37.2081],
            [-92.8519, 37.0688],
            [-92.9035, 37.0376],
            [-92.9576, 37.0607],
            [-92.9936, 37.0877],
            [-92.9905, 37.209],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2912180',
        NAME: 'Fordland R-III School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214083036,
        AWATER: 285334,
        INTPTLAT: '+37.1394526',
        INTPTLON: '-092.9295014',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6086, 37.1127],
            [-93.6089, 37.0982],
            [-93.5557, 36.9951],
            [-93.5658, 36.9513],
            [-93.6576, 36.924],
            [-93.7001, 37.0927],
            [-93.6086, 37.1127],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920310',
        NAME: 'Marionville R-IX School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160502790,
        AWATER: 236823,
        INTPTLAT: '+37.0171252',
        INTPTLON: '-093.6417789',
        ELSDLEA: '',
        UNSDLEA: '20310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3905, 37.0714],
            [-93.3527, 37.095],
            [-93.2719, 37.0938],
            [-93.2574, 36.9836],
            [-93.3566, 36.9491],
            [-93.3852, 36.9374],
            [-93.3935, 36.9534],
            [-93.3905, 37.0714],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922530',
        NAME: 'Nixa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142066717,
        AWATER: 858513,
        INTPTLAT: '+37.0308513',
        INTPTLON: '-093.3201969',
        ELSDLEA: '',
        UNSDLEA: '22530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7591, 39.8075],
            [-93.6146, 39.7881],
            [-93.5757, 39.7514],
            [-93.5907, 39.6281],
            [-93.6466, 39.6119],
            [-93.7584, 39.6114],
            [-93.7772, 39.6987],
            [-93.7591, 39.8075],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2928680',
        NAME: 'Southwest Livingston County R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287660405,
        AWATER: 1471796,
        INTPTLAT: '+39.6906348',
        INTPTLON: '-093.6712110',
        ELSDLEA: '',
        UNSDLEA: '28680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4064, 39.2284],
            [-93.302, 39.3098],
            [-93.2207, 39.1722],
            [-93.3051, 39.1365],
            [-93.3223, 39.0676],
            [-93.3766, 39.0773],
            [-93.4064, 39.2284],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2919920',
        NAME: 'Malta Bend R-V School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232094640,
        AWATER: 3509583,
        INTPTLAT: '+39.1812362',
        INTPTLON: '-093.3257984',
        ELSDLEA: '',
        UNSDLEA: '19920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2207, 39.1722],
            [-93.1508, 39.1735],
            [-93.0994, 39.1219],
            [-93.0459, 39.0385],
            [-93.1053, 38.9746],
            [-93.0505, 38.9282],
            [-93.2448, 38.9495],
            [-93.2228, 39.0152],
            [-93.3223, 39.0676],
            [-93.3051, 39.1365],
            [-93.2207, 39.1722],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2920410',
        NAME: 'Marshall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418764246,
        AWATER: 2035318,
        INTPTLAT: '+39.0509999',
        INTPTLON: '-093.1684019',
        ELSDLEA: '',
        UNSDLEA: '20410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4302, 39.2488],
            [-92.3725, 39.2477],
            [-92.3755, 39.1604],
            [-92.3186, 39.1377],
            [-92.3213, 39.0695],
            [-92.5313, 39.0566],
            [-92.5473, 39.0956],
            [-92.4302, 39.2488],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913710',
        NAME: 'Harrisburg R-VIII School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248899294,
        AWATER: 436467,
        INTPTLAT: '+39.1344452',
        INTPTLON: '-092.4333696',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1878, 37.2401],
            [-92.0994, 37.2541],
            [-91.9968, 37.2084],
            [-91.8583, 37.2106],
            [-91.8711, 37.1383],
            [-91.9799, 37.1183],
            [-91.9808, 37.0556],
            [-92.0887, 37.0562],
            [-92.1642, 36.9266],
            [-92.1689, 36.9266],
            [-92.1789, 36.9267],
            [-92.2367, 36.9282],
            [-92.2372, 37.0594],
            [-92.1778, 37.1599],
            [-92.1878, 37.2401],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2906430',
        NAME: 'Cabool R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 630172163,
        AWATER: 799803,
        INTPTLAT: '+37.1153450',
        INTPTLON: '-092.0914653',
        ELSDLEA: '',
        UNSDLEA: '06430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1642, 36.9266],
            [-92.0887, 37.0562],
            [-91.9808, 37.0556],
            [-91.9799, 37.1183],
            [-91.8711, 37.1383],
            [-91.8173, 37.0787],
            [-91.8553, 36.9781],
            [-91.7845, 36.9016],
            [-91.8455, 36.8475],
            [-92.0947, 36.8375],
            [-92.2028, 36.8397],
            [-92.1642, 36.9266],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2932070',
        NAME: 'Willow Springs R-IV School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 802062383,
        AWATER: 1309742,
        INTPTLAT: '+36.9545934',
        INTPTLON: '-091.9743328',
        ELSDLEA: '',
        UNSDLEA: '32070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0293, 37.6025],
            [-91.8091, 37.5989],
            [-91.7548, 37.5988],
            [-91.755, 37.4241],
            [-91.8913, 37.3827],
            [-91.9443, 37.433],
            [-92.0224, 37.5298],
            [-92.0293, 37.6025],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2918600',
        NAME: 'Licking R-VIII School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499618569,
        AWATER: 1232515,
        INTPTLAT: '+37.5167249',
        INTPTLON: '-091.8821910',
        ELSDLEA: '',
        UNSDLEA: '18600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9663, 38.0097],
            [-91.8945, 38.0512],
            [-91.8762, 38.0512],
            [-91.8749, 38.0512],
            [-91.8609, 37.8062],
            [-92.0251, 37.7693],
            [-92.0251, 37.7728],
            [-92.0246, 37.7937],
            [-92.0414, 37.8811],
            [-91.9663, 38.0097],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2922140',
        NAME: 'Newburg R-II School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354190187,
        AWATER: 2295529,
        INTPTLAT: '+37.8985094',
        INTPTLON: '-091.9525694',
        ELSDLEA: '',
        UNSDLEA: '22140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0262, 40.3857],
            [-92.8553, 40.3859],
            [-92.8556, 40.3427],
            [-92.856, 40.1248],
            [-92.8562, 40.0373],
            [-92.9595, 40.0364],
            [-93.0031, 40.0365],
            [-92.9969, 40.1742],
            [-93.0294, 40.2548],
            [-93.0262, 40.3857],
          ],
        ],
      },
      properties: {
        STATEFP: '29',
        SCSDLEA: '',
        GEOID: '2913230',
        NAME: 'Green City R-I School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537389669,
        AWATER: 1980120,
        INTPTLAT: '+40.2204228',
        INTPTLON: '-092.9394966',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
  ],
};

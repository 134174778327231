import React from 'react';
import PropTypes from 'prop-types';

/**
 * States:
 * Initial: !progress, !uploading, !uploaded
 * 1. FE progress: Shows progress
 * 2. Is Uploading: Shows indeterminate
 * 3. Is Uploaded: Dissapears.
 */

const InputUploadProgressBar = ({ progress, isUploading, uploaded }) => {
  const progressGraterThanCero = () => {
    if (progress > 0 && progress < 100) {
      return true;
    }
    return false;
  };

  if (uploaded) return null;

  return (
    <div className="InputUploadProgressBar">
      <div className="FileUpload-progress">
        {
          progressGraterThanCero()
          && (
          <span className="progress-number">
            {`${progress.toFixed()}%`}
          </span>
          )
        }
        <div className="progress-bar">
          {
            progressGraterThanCero()
            && (
            <div
              className="progress-bar-completed"
              style={{ width: `${progress}%` }}
            />
            )
          }

          {isUploading
            && (
            <div
              className="progress-bar-indeterminate"
            />
            )}
        </div>
      </div>
    </div>
  );
};

InputUploadProgressBar.propTypes = {
  progress: PropTypes.number,
  isUploading: PropTypes.bool,
  uploaded: PropTypes.bool,
};

InputUploadProgressBar.defaultProps = {
  progress: 0,
  isUploading: false,
  uploaded: false,
};

export default InputUploadProgressBar;

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Spinner = ({ size }) => (
  <div className={`Spinner ${size}`} />
);

Spinner.propTypes = {
  size: PropTypes.string,
};

Spinner.defaultProps = {
  size: 'md',
};

export default Spinner;

export const TX = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2182, 30.3467],
            [-97.1588, 30.3107],
            [-97.1475, 30.2288],
            [-97.2149, 30.184],
            [-97.3187, 30.2693],
            [-97.2182, 30.3467],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '48021',
        GEOID: '4848021',
        NAME: 'Elgin/Giddings Independent School Districts (9-12) in McDade',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 168400411,
        AWATER: 476528,
        INTPTLAT: '+30.2722089',
        INTPTLON: '-097.2192808',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0565, 29.4714],
            [-97.0366, 29.4026],
            [-97.0761, 29.4131],
            [-97.0565, 29.4714],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '48285',
        GEOID: '4848285',
        NAME: 'Hallettsville Independent School District (9-12) in Vysehrad',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 81526213,
        AWATER: 0,
        INTPTLAT: '+29.4381936',
        INTPTLON: '-097.0250317',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1246, 33.2753],
            [-95.0989, 33.2671],
            [-95.0659, 33.174],
            [-95.1256, 33.1191],
            [-95.1246, 33.2753],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '48449',
        GEOID: '4848449',
        NAME: 'Mount Pleasant Independent School District (9-12) in Winfield',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 87817222,
        AWATER: 3986870,
        INTPTLAT: '+33.1966402',
        INTPTLON: '-095.0890337',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1152, 32.4394],
            [-98.0284, 32.4201],
            [-97.953, 32.2501],
            [-97.9591, 32.2457],
            [-98.0586, 32.2874],
            [-98.1061, 32.3493],
            [-98.1152, 32.4394],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '48143',
        GEOID: '4848143',
        NAME: 'Stephenville Independent School District (9-12) in Bluff Dale',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 181774447,
        AWATER: 609348,
        INTPTLAT: '+32.3277402',
        INTPTLON: '-098.0472800',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1152, 32.4394],
            [-98.0284, 32.4201],
            [-97.953, 32.2501],
            [-97.9591, 32.2457],
            [-98.0586, 32.2874],
            [-98.1061, 32.3493],
            [-98.1152, 32.4394],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810620',
        NAME: 'Bluff Dale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181774447,
        AWATER: 609348,
        INTPTLAT: '+32.3277402',
        INTPTLON: '-098.0472800',
        ELSDLEA: '10620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2182, 30.3467],
            [-97.1588, 30.3107],
            [-97.1475, 30.2288],
            [-97.2149, 30.184],
            [-97.3187, 30.2693],
            [-97.2182, 30.3467],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829760',
        NAME: 'McDade Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168400411,
        AWATER: 476528,
        INTPTLAT: '+30.2722089',
        INTPTLON: '-097.2192808',
        ELSDLEA: '29760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0565, 29.4714],
            [-97.0366, 29.4026],
            [-97.0761, 29.4131],
            [-97.0565, 29.4714],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844250',
        NAME: 'Vysehrad Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81526213,
        AWATER: 0,
        INTPTLAT: '+29.4381936',
        INTPTLON: '-097.0250317',
        ELSDLEA: '44250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1246, 33.2753],
            [-95.0989, 33.2671],
            [-95.0659, 33.174],
            [-95.1256, 33.1191],
            [-95.1246, 33.2753],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846110',
        NAME: 'Winfield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87817222,
        AWATER: 3986870,
        INTPTLAT: '+33.1966402',
        INTPTLON: '-095.0890337',
        ELSDLEA: '46110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9991, 30.0257],
            [-93.9693, 30.0125],
            [-93.9696, 29.9515],
            [-94.0491, 29.9505],
            [-93.9991, 30.0257],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832280',
        NAME: 'Nederland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45411569,
        AWATER: 3622561,
        INTPTLAT: '+29.9772516',
        INTPTLON: '-094.0054218',
        ELSDLEA: '',
        UNSDLEA: '32280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3555, 29.7286],
            [-94.2283, 29.7285],
            [-94.1563, 29.6929],
            [-94.0777, 29.747],
            [-94.0337, 29.8821],
            [-93.9269, 29.791],
            [-93.8374, 29.6899],
            [-93.8144, 29.5966],
            [-93.875, 29.6214],
            [-94.0336, 29.625],
            [-94.3549, 29.5063],
            [-94.3555, 29.7286],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838490',
        NAME: 'Sabine Pass Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 535710553,
        AWATER: 454192404,
        INTPTLAT: '+29.6788615',
        INTPTLON: '-094.0920854',
        ELSDLEA: '',
        UNSDLEA: '38490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5788, 33.8303],
            [-101.4834, 33.8311],
            [-101.4311, 33.8316],
            [-101.4681, 33.7381],
            [-101.4683, 33.593],
            [-101.4365, 33.3956],
            [-101.557, 33.4033],
            [-101.558, 33.4907],
            [-101.5607, 33.6385],
            [-101.6135, 33.6385],
            [-101.6136, 33.7691],
            [-101.5788, 33.8303],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828230',
        NAME: 'Lorenzo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 508271503,
        AWATER: 660700,
        INTPTLAT: '+33.6370044',
        INTPTLON: '-101.5151089',
        ELSDLEA: '',
        UNSDLEA: '28230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4311, 33.8316],
            [-101.3008, 33.8325],
            [-101.3076, 33.5161],
            [-101.24, 33.5158],
            [-101.2745, 33.3967],
            [-101.3336, 33.3965],
            [-101.4365, 33.3956],
            [-101.4683, 33.593],
            [-101.4681, 33.7381],
            [-101.4311, 33.8316],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836390',
        NAME: 'Ralls Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 786148974,
        AWATER: 8142,
        INTPTLAT: '+33.5883040',
        INTPTLON: '-101.3724426',
        ELSDLEA: '',
        UNSDLEA: '36390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6538, 28.757],
            [-97.6067, 28.7905],
            [-97.5152, 28.8561],
            [-97.4173, 28.9252],
            [-97.3554, 28.8976],
            [-97.2362, 28.8649],
            [-97.1589, 28.7762],
            [-97.1824, 28.6106],
            [-97.1608, 28.5535],
            [-97.3751, 28.3892],
            [-97.4379, 28.4097],
            [-97.5539, 28.4553],
            [-97.5771, 28.5225],
            [-97.7037, 28.5609],
            [-97.7757, 28.6701],
            [-97.6538, 28.757],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821030',
        NAME: 'Goliad Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2206695364,
        AWATER: 19049508,
        INTPTLAT: '+28.6607000',
        INTPTLON: '-097.4304145',
        ELSDLEA: '',
        UNSDLEA: '21030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.26, 35.5292],
            [-102.2572, 35.5387],
            [-102.2469, 35.5381],
            [-102.2471, 35.5304],
            [-102.26, 35.5292],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811040',
        NAME: 'Boys Ranch Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1243351,
        AWATER: 26936,
        INTPTLAT: '+35.5350890',
        INTPTLON: '-102.2520692',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2111, 32.3594],
            [-101.9579, 32.3906],
            [-101.8736, 32.3502],
            [-101.7571, 32.3764],
            [-101.6919, 32.2992],
            [-101.6923, 32.2652],
            [-101.9021, 32.2179],
            [-102.0502, 32.2289],
            [-102.211, 32.177],
            [-102.2111, 32.3594],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821300',
        NAME: 'Grady Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 773208945,
        AWATER: 42447,
        INTPTLAT: '+32.2965715',
        INTPTLON: '-102.0060249',
        ELSDLEA: '',
        UNSDLEA: '21300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5388, 35.3883],
            [-100.432, 35.4004],
            [-100.3254, 35.5026],
            [-100.1832, 35.5465],
            [-100.1477, 35.4867],
            [-100.1831, 35.3428],
            [-100.3237, 35.3427],
            [-100.4503, 35.3856],
            [-100.4673, 35.2559],
            [-100.5565, 35.3285],
            [-100.5388, 35.3883],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845420',
        NAME: 'Wheeler Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475083525,
        AWATER: 104928,
        INTPTLAT: '+35.4170940',
        INTPTLON: '-100.3372167',
        ELSDLEA: '',
        UNSDLEA: '45420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1839, 32.9876],
            [-97.1102, 32.9863],
            [-97.1767, 32.9225],
            [-97.1839, 32.9876],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813020',
        NAME: 'Carroll Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52359673,
        AWATER: 829930,
        INTPTLAT: '+32.9520655',
        INTPTLON: '-097.1477862',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3278, 32.6588],
            [-97.2438, 32.6528],
            [-97.2343, 32.6169],
            [-97.2633, 32.5912],
            [-97.3172, 32.6067],
            [-97.3278, 32.6588],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818810',
        NAME: 'Everman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46548867,
        AWATER: 137094,
        INTPTLAT: '+32.6255026',
        INTPTLON: '-097.2834176',
        ELSDLEA: '',
        UNSDLEA: '18810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3318, 32.8873],
            [-97.2765, 32.9706],
            [-97.1839, 32.9876],
            [-97.1767, 32.9225],
            [-97.1771, 32.8853],
            [-97.2899, 32.8535],
            [-97.3318, 32.8873],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825260',
        NAME: 'Keller Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130837316,
        AWATER: 598525,
        INTPTLAT: '+32.9213709',
        INTPTLON: '-097.2531569',
        ELSDLEA: '',
        UNSDLEA: '25260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.548, 32.7984],
            [-97.5012, 32.8162],
            [-97.5486, 32.7445],
            [-97.548, 32.7984],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845540',
        NAME: 'White Settlement Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61072939,
        AWATER: 156263,
        INTPTLAT: '+32.7706870',
        INTPTLON: '-097.4890005',
        ELSDLEA: '',
        UNSDLEA: '45540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4829, 33.1212],
            [-97.3935, 33.1557],
            [-97.2589, 33.1416],
            [-97.2538, 33.1315],
            [-97.2189, 33.0449],
            [-97.1584, 33.0219],
            [-97.0872, 32.9898],
            [-97.1102, 32.9863],
            [-97.1839, 32.9876],
            [-97.2765, 32.9706],
            [-97.3318, 32.8873],
            [-97.4487, 32.924],
            [-97.5126, 32.9939],
            [-97.519, 33.0088],
            [-97.4829, 33.1212],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833180',
        NAME: 'Northwest Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 594047158,
        AWATER: 14086488,
        INTPTLAT: '+33.0330059',
        INTPTLON: '-097.3548799',
        ELSDLEA: '',
        UNSDLEA: '33180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4472, 29.4682],
            [-98.4539, 29.4682],
            [-98.4917, 29.4881],
            [-98.4472, 29.4682],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807590',
        NAME: 'Alamo Heights Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24106346,
        AWATER: 53340,
        INTPTLAT: '+29.4942745',
        INTPTLON: '-098.4677469',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0861, 35.3572],
            [-100.9913, 35.3867],
            [-100.9919, 35.4455],
            [-100.883, 35.446],
            [-100.8846, 35.3333],
            [-100.8861, 35.2564],
            [-101.0864, 35.2412],
            [-101.0861, 35.3572],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821560',
        NAME: 'Grandview-Hopkins Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334234902,
        AWATER: 866062,
        INTPTLAT: '+35.3357966',
        INTPTLON: '-100.9823528',
        ELSDLEA: '',
        UNSDLEA: '21560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9797, 33.8922],
            [-97.8536, 33.8527],
            [-97.8298, 33.8316],
            [-97.8508, 33.7274],
            [-97.7482, 33.704],
            [-97.7722, 33.675],
            [-97.8615, 33.6323],
            [-97.979, 33.6176],
            [-98.0036, 33.7934],
            [-97.9797, 33.8922],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820970',
        NAME: 'Gold-Burg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397911250,
        AWATER: 1101949,
        INTPTLAT: '+33.7376302',
        INTPTLON: '-097.8960182',
        ELSDLEA: '',
        UNSDLEA: '20970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1061, 33.6708],
            [-98.0976, 33.761],
            [-98.0036, 33.7934],
            [-97.979, 33.6176],
            [-97.9789, 33.5282],
            [-98.0433, 33.474],
            [-98.1183, 33.4814],
            [-98.1061, 33.6708],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809750',
        NAME: 'Bellevue Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405593443,
        AWATER: 2880716,
        INTPTLAT: '+33.6417472',
        INTPTLON: '-098.0431406',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4079, 32.7965],
            [-97.3935, 32.8015],
            [-97.3893, 32.799],
            [-97.3865, 32.7896],
            [-97.3817, 32.7872],
            [-97.3742, 32.782],
            [-97.4079, 32.7965],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813170',
        NAME: 'Castleberry Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14069567,
        AWATER: 0,
        INTPTLAT: '+32.7794527',
        INTPTLON: '-097.3968572',
        ELSDLEA: '',
        UNSDLEA: '13170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.173, 32.8638],
            [-97.0338, 32.8342],
            [-97.0347, 32.7885],
            [-97.1494, 32.7761],
            [-97.2038, 32.7903],
            [-97.173, 32.8638],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824060',
        NAME: 'Hurst-Euless-Bedford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119545244,
        AWATER: 2635948,
        INTPTLAT: '+32.8273670',
        INTPTLON: '-097.1236998',
        ELSDLEA: '',
        UNSDLEA: '24060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.443, 32.8219],
            [-97.3601, 32.8322],
            [-97.3935, 32.8015],
            [-97.4079, 32.7965],
            [-97.443, 32.8219],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826490',
        NAME: 'Lake Worth Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21416193,
        AWATER: 876749,
        INTPTLAT: '+32.8142940',
        INTPTLON: '-097.4042096',
        ELSDLEA: '',
        UNSDLEA: '26490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6291, 32.7923],
            [-97.548, 32.7984],
            [-97.5486, 32.7445],
            [-97.5413, 32.5984],
            [-97.5505, 32.5624],
            [-97.6696, 32.5561],
            [-97.7046, 32.6652],
            [-97.6291, 32.7923],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807780',
        NAME: 'Aledo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340995112,
        AWATER: 1099879,
        INTPTLAT: '+32.6822340',
        INTPTLON: '-097.6270033',
        ELSDLEA: '',
        UNSDLEA: '07780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2438, 32.6528],
            [-97.2316, 32.6693],
            [-97.1711, 32.6343],
            [-97.2343, 32.6169],
            [-97.2438, 32.6528],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825500',
        NAME: 'Kennedale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27322559,
        AWATER: 98390,
        INTPTLAT: '+32.6422859',
        INTPTLON: '-097.2108382',
        ELSDLEA: '',
        UNSDLEA: '25500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1143, 28.6481],
            [-99.409, 28.6406],
            [-99.3961, 28.5926],
            [-99.3942, 28.2046],
            [-100.1137, 28.1978],
            [-100.1143, 28.6481],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812990',
        NAME: 'Carrizo Springs Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3441795366,
        AWATER: 14576538,
        INTPTLAT: '+28.4235871',
        INTPTLON: '-099.7658713',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8465, 33.8399],
            [-95.7615, 33.8731],
            [-95.7288, 33.8945],
            [-95.5528, 33.9243],
            [-95.5338, 33.8812],
            [-95.3656, 33.8671],
            [-95.3105, 33.8772],
            [-95.3109, 33.7891],
            [-95.4968, 33.6365],
            [-95.6174, 33.6838],
            [-95.7219, 33.6547],
            [-95.855, 33.7463],
            [-95.8465, 33.8399],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833010',
        NAME: 'North Lamar Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1015216535,
        AWATER: 52067097,
        INTPTLAT: '+33.7874069',
        INTPTLON: '-095.5889783',
        ELSDLEA: '',
        UNSDLEA: '33010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1573, 33.3702],
            [-96.1388, 33.3894],
            [-96.0082, 33.39],
            [-95.957, 33.3637],
            [-96.04, 33.2583],
            [-96.1225, 33.2299],
            [-96.1573, 33.3702],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846350',
        NAME: 'Wolfe City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222337672,
        AWATER: 617353,
        INTPTLAT: '+33.3347444',
        INTPTLON: '-096.0706140',
        ELSDLEA: '',
        UNSDLEA: '46350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1892, 33.7557],
            [-96.125, 33.6481],
            [-96.1402, 33.5733],
            [-96.042, 33.5002],
            [-96.0093, 33.4889],
            [-96.0082, 33.39],
            [-96.1388, 33.3894],
            [-96.2707, 33.4382],
            [-96.2937, 33.472],
            [-96.2907, 33.5227],
            [-96.2322, 33.6281],
            [-96.2763, 33.6576],
            [-96.307, 33.7206],
            [-96.1892, 33.7557],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810800',
        NAME: 'Bonham Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 584612581,
        AWATER: 5418754,
        INTPTLAT: '+33.5631058',
        INTPTLON: '-096.1690892',
        ELSDLEA: '',
        UNSDLEA: '10800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1892, 33.7557],
            [-96.1002, 33.8479],
            [-96.0592, 33.8398],
            [-95.9837, 33.8528],
            [-95.9514, 33.769],
            [-95.9837, 33.6808],
            [-96.05, 33.6539],
            [-96.125, 33.6481],
            [-96.1892, 33.7557],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838640',
        NAME: 'Sam Rayburn Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366473753,
        AWATER: 6956378,
        INTPTLAT: '+33.7456449',
        INTPTLON: '-096.0790401',
        ELSDLEA: '',
        UNSDLEA: '38640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.05, 33.6539],
            [-95.9837, 33.6808],
            [-95.9514, 33.769],
            [-95.9837, 33.8528],
            [-95.9248, 33.8848],
            [-95.8465, 33.8399],
            [-95.855, 33.7463],
            [-95.8568, 33.5365],
            [-95.822, 33.4689],
            [-96.0093, 33.4889],
            [-96.042, 33.5002],
            [-96.05, 33.6539],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823460',
        NAME: 'Honey Grove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571049951,
        AWATER: 2650358,
        INTPTLAT: '+33.6485571',
        INTPTLON: '-095.9338389',
        ELSDLEA: '',
        UNSDLEA: '23460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5713, 29.6487],
            [-95.5655, 29.646],
            [-95.537, 29.6328],
            [-95.558, 29.6107],
            [-95.5826, 29.6179],
            [-95.5713, 29.6487],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841350',
        NAME: 'Stafford Municipal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18027732,
        AWATER: 102706,
        INTPTLAT: '+29.6257201',
        INTPTLON: '-095.5657396',
        ELSDLEA: '',
        UNSDLEA: '41350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3881, 29.5857],
            [-95.2693, 29.5943],
            [-95.2329, 29.5753],
            [-95.2164, 29.5561],
            [-95.2255, 29.5018],
            [-95.3834, 29.5271],
            [-95.3881, 29.5857],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834440',
        NAME: 'Pearland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113143976,
        AWATER: 251769,
        INTPTLAT: '+29.5554150',
        INTPTLON: '-095.3080146',
        ELSDLEA: '',
        UNSDLEA: '34440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6441, 29.7356],
            [-95.5389, 29.7371],
            [-95.5655, 29.646],
            [-95.5713, 29.6487],
            [-95.7019, 29.7184],
            [-95.6441, 29.7356],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807830',
        NAME: 'Alief Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100898245,
        AWATER: 549249,
        INTPTLAT: '+29.7045064',
        INTPTLON: '-095.6080614',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9918, 30.1704],
            [-94.808, 30.2326],
            [-94.8205, 30.0953],
            [-94.8546, 29.993],
            [-94.7389, 29.8872],
            [-94.7788, 29.8867],
            [-94.9815, 29.8843],
            [-95.0352, 29.9926],
            [-95.0726, 30.1031],
            [-94.9918, 30.1704],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816410',
        NAME: 'Dayton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670223263,
        AWATER: 8295243,
        INTPTLAT: '+30.0344937',
        INTPTLON: '-094.9164976',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.62, 29.7706],
            [-95.5294, 29.7458],
            [-95.4911, 29.8236],
            [-95.491, 29.8506],
            [-95.2956, 29.9186],
            [-95.2447, 29.9212],
            [-95.2304, 29.8258],
            [-95.2364, 29.7215],
            [-95.2693, 29.5943],
            [-95.3881, 29.5857],
            [-95.4241, 29.5802],
            [-95.537, 29.6328],
            [-95.5655, 29.646],
            [-95.5389, 29.7371],
            [-95.6441, 29.7356],
            [-95.62, 29.7706],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823640',
        NAME: 'Houston Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 854859219,
        AWATER: 8563551,
        INTPTLAT: '+29.7366216',
        INTPTLON: '-095.3803675',
        ELSDLEA: '',
        UNSDLEA: '23640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5097, 30.1485],
            [-95.4625, 30.1053],
            [-95.4274, 30.0357],
            [-95.4809, 29.95],
            [-95.4959, 29.8911],
            [-95.5701, 29.9858],
            [-95.6194, 30.0605],
            [-95.5097, 30.1485],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825740',
        NAME: 'Klein Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211909239,
        AWATER: 2365940,
        INTPTLAT: '+30.0404466',
        INTPTLON: '-095.5290237',
        ELSDLEA: '',
        UNSDLEA: '25740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1414, 29.9235],
            [-95.104, 29.8403],
            [-95.0788, 29.8249],
            [-95.1568, 29.8223],
            [-95.2304, 29.8258],
            [-95.2447, 29.9212],
            [-95.1414, 29.9235],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839990',
        NAME: 'Sheldon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128343132,
        AWATER: 10511435,
        INTPTLAT: '+29.8625472',
        INTPTLON: '-095.1751895',
        ELSDLEA: '',
        UNSDLEA: '39990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.733, 30.0944],
            [-95.667, 30.1718],
            [-95.5583, 30.1655],
            [-95.5097, 30.1485],
            [-95.6194, 30.0605],
            [-95.5701, 29.9858],
            [-95.7292, 30.0292],
            [-95.733, 30.0944],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842960',
        NAME: 'Tomball Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213095638,
        AWATER: 2599825,
        INTPTLAT: '+30.0927686',
        INTPTLON: '-095.6425886',
        ELSDLEA: '',
        UNSDLEA: '42960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.6072, 29.4029],
              [-98.6014, 29.3848],
              [-98.6269, 29.3675],
              [-98.63, 29.3736],
              [-98.6072, 29.4029],
            ],
          ],
          [
            [
              [-98.6698, 29.3893],
              [-98.6436, 29.3681],
              [-98.6737, 29.3466],
              [-98.6901, 29.3836],
              [-98.6698, 29.3893],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826370',
        NAME: 'Lackland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26836090,
        AWATER: 4426,
        INTPTLAT: '+29.3582529',
        INTPTLON: '-098.6637576',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5183, 29.321],
            [-98.4573, 29.3351],
            [-98.4448, 29.3162],
            [-98.4222, 29.2533],
            [-98.3272, 29.2099],
            [-98.4073, 29.1144],
            [-98.5242, 29.1545],
            [-98.5269, 29.1554],
            [-98.5263, 29.2511],
            [-98.5183, 29.321],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840920',
        NAME: 'Southside Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269638644,
        AWATER: 5518543,
        INTPTLAT: '+29.1993173',
        INTPTLON: '-098.4620192',
        ELSDLEA: '',
        UNSDLEA: '40920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6072, 29.4029],
            [-98.5831, 29.4507],
            [-98.5438, 29.3883],
            [-98.6014, 29.3848],
            [-98.6072, 29.4029],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818150',
        NAME: 'Edgewood Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 43276832,
        AWATER: 150007,
        INTPTLAT: '+29.4110086',
        INTPTLON: '-098.5731858',
        ELSDLEA: '',
        UNSDLEA: '18150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2681, 30.7405],
            [-97.0781, 30.6948],
            [-97.1244, 30.6531],
            [-97.0841, 30.4945],
            [-97.1649, 30.481],
            [-97.2532, 30.6932],
            [-97.2746, 30.7369],
            [-97.2681, 30.7405],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842630',
        NAME: 'Thorndale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347384579,
        AWATER: 751634,
        INTPTLAT: '+30.6168263',
        INTPTLON: '-097.1665199',
        ELSDLEA: '',
        UNSDLEA: '42630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5191, 33.8357],
            [-98.4236, 33.836],
            [-98.4231, 33.6712],
            [-98.5195, 33.6561],
            [-98.5136, 33.5192],
            [-98.4579, 33.4967],
            [-98.4207, 33.3962],
            [-98.5817, 33.3956],
            [-98.5796, 33.4453],
            [-98.7146, 33.4541],
            [-98.823, 33.4964],
            [-98.8803, 33.6318],
            [-98.7577, 33.6727],
            [-98.6427, 33.6757],
            [-98.6436, 33.7388],
            [-98.5191, 33.8357],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808610',
        NAME: 'Archer City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1091677063,
        AWATER: 28873926,
        INTPTLAT: '+33.5986100',
        INTPTLON: '-098.6064501',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4231, 33.6712],
            [-98.3687, 33.6268],
            [-98.3697, 33.5202],
            [-98.4217, 33.4964],
            [-98.4579, 33.4967],
            [-98.5136, 33.5192],
            [-98.5195, 33.6561],
            [-98.4231, 33.6712],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846080',
        NAME: 'Windthorst Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213646974,
        AWATER: 5118867,
        INTPTLAT: '+33.5845066',
        INTPTLON: '-098.4562848',
        ELSDLEA: '',
        UNSDLEA: '46080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0433, 33.474],
            [-97.9789, 33.5282],
            [-97.979, 33.6176],
            [-97.8615, 33.6323],
            [-97.7722, 33.675],
            [-97.7152, 33.6217],
            [-97.61, 33.434],
            [-97.88, 33.434],
            [-97.9185, 33.3772],
            [-98.052, 33.4297],
            [-98.0433, 33.474],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810990',
        NAME: 'Bowie Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 723425669,
        AWATER: 8984068,
        INTPTLAT: '+33.5219106',
        INTPTLON: '-097.8432891',
        ELSDLEA: '',
        UNSDLEA: '10990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6641, 32.1584],
            [-100.6621, 32.388],
            [-100.4777, 32.4165],
            [-100.4535, 32.3312],
            [-100.4084, 32.1999],
            [-100.5124, 32.1583],
            [-100.5125, 32.0833],
            [-100.6654, 32.0854],
            [-100.6641, 32.1584],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823190',
        NAME: 'Highland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 667815644,
        AWATER: 43414,
        INTPTLAT: '+32.2526377',
        INTPTLON: '-100.5592182',
        ELSDLEA: '',
        UNSDLEA: '23190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3687, 33.6268],
            [-98.3256, 33.7048],
            [-98.2161, 33.7091],
            [-98.1061, 33.6708],
            [-98.1183, 33.4814],
            [-98.0433, 33.474],
            [-98.052, 33.4297],
            [-98.1685, 33.4278],
            [-98.4217, 33.4964],
            [-98.3697, 33.5202],
            [-98.3687, 33.6268],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830660',
        NAME: 'Midway Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 663570160,
        AWATER: 6100398,
        INTPTLAT: '+33.5650069',
        INTPTLON: '-098.2281160',
        ELSDLEA: '',
        UNSDLEA: '30660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7508, 34.1242],
            [-98.6186, 34.1566],
            [-98.4863, 34.0626],
            [-98.4171, 34.0834],
            [-98.3491, 34.0788],
            [-98.4231, 33.9444],
            [-98.5282, 33.9739],
            [-98.59, 33.9744],
            [-98.6559, 34.067],
            [-98.7506, 34.0687],
            [-98.7508, 34.1242],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812120',
        NAME: 'Burkburnett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456306963,
        AWATER: 1502877,
        INTPTLAT: '+34.0469473',
        INTPTLON: '-098.5492415',
        ELSDLEA: '',
        UNSDLEA: '12120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5538, 32.2524],
            [-94.404, 32.3698],
            [-94.3476, 32.3307],
            [-94.3129, 32.2581],
            [-94.4478, 32.2058],
            [-94.563, 32.1882],
            [-94.5964, 32.1049],
            [-94.5538, 32.2524],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809690',
        NAME: 'Beckville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300786608,
        AWATER: 3212442,
        INTPTLAT: '+32.2493183',
        INTPTLON: '-094.4532201',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7003, 32.3417],
            [-94.6721, 32.3705],
            [-94.5792, 32.3949],
            [-94.4985, 32.3967],
            [-94.404, 32.3698],
            [-94.5538, 32.2524],
            [-94.677, 32.252],
            [-94.7003, 32.3417],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842240',
        NAME: 'Tatum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279460410,
        AWATER: 16386215,
        INTPTLAT: '+32.3243667',
        INTPTLON: '-094.5637300',
        ELSDLEA: '',
        UNSDLEA: '42240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2329, 29.5753],
            [-95.143, 29.6276],
            [-95.1089, 29.6282],
            [-94.9971, 29.6153],
            [-94.9897, 29.5171],
            [-95.1288, 29.4638],
            [-95.2081, 29.4544],
            [-95.2164, 29.5561],
            [-95.2329, 29.5753],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814280',
        NAME: 'Clear Creek Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257014561,
        AWATER: 15333802,
        INTPTLAT: '+29.5466529',
        INTPTLON: '-095.1039350',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4959, 29.8911],
            [-95.4809, 29.95],
            [-95.4274, 30.0357],
            [-95.311, 30.0329],
            [-95.2772, 30.0313],
            [-95.2956, 29.9186],
            [-95.491, 29.8506],
            [-95.4959, 29.8911],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807710',
        NAME: 'Aldine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281626944,
        AWATER: 1417351,
        INTPTLAT: '+29.9439287',
        INTPTLON: '-095.3767681',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1568, 29.8223],
            [-95.0788, 29.8249],
            [-95.0947, 29.8066],
            [-95.093, 29.7531],
            [-95.1568, 29.8223],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813590',
        NAME: 'Channelview Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42520118,
        AWATER: 9421243,
        INTPTLAT: '+29.7930230',
        INTPTLON: '-095.1144823',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2772, 30.0313],
            [-95.2637, 30.0325],
            [-95.1245, 30.1445],
            [-95.1551, 30.0832],
            [-95.1347, 29.9798],
            [-95.1414, 29.9235],
            [-95.2447, 29.9212],
            [-95.2956, 29.9186],
            [-95.2772, 30.0313],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823910',
        NAME: 'Humble Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209071414,
        AWATER: 28182105,
        INTPTLAT: '+30.0057655',
        INTPTLON: '-095.2006821',
        ELSDLEA: '',
        UNSDLEA: '23910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2693, 29.5943],
            [-95.2364, 29.7215],
            [-95.1932, 29.7422],
            [-95.143, 29.6276],
            [-95.2329, 29.5753],
            [-95.2693, 29.5943],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834320',
        NAME: 'Pasadena Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153903689,
        AWATER: 3084056,
        INTPTLAT: '+29.6475714',
        INTPTLON: '-095.2069099',
        ELSDLEA: '',
        UNSDLEA: '34320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8767, 32.571],
            [-94.9622, 32.5748],
            [-94.9163, 32.6271],
            [-94.8767, 32.571],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843530',
        NAME: 'Union Grove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49749871,
        AWATER: 1781508,
        INTPTLAT: '+32.5907995',
        INTPTLON: '-094.9287205',
        ELSDLEA: '',
        UNSDLEA: '43530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.169, 32.8225],
            [-95.0438, 32.8446],
            [-94.9897, 32.7772],
            [-95.0362, 32.6852],
            [-95.0584, 32.6675],
            [-95.1737, 32.6863],
            [-95.2381, 32.7345],
            [-95.169, 32.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822560',
        NAME: 'Harmony Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287874181,
        AWATER: 4910844,
        INTPTLAT: '+32.7760114',
        INTPTLON: '-095.1222059',
        ELSDLEA: '',
        UNSDLEA: '22560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6228, 35.6202],
            [-101.6228, 35.6241],
            [-101.5242, 35.6242],
            [-101.3552, 35.6247],
            [-101.2929, 35.5323],
            [-101.2749, 35.3558],
            [-101.2216, 35.3562],
            [-101.222, 35.2836],
            [-101.3109, 35.2829],
            [-101.3115, 35.1821],
            [-101.6229, 35.1831],
            [-101.6228, 35.6202],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834230',
        NAME: 'Panhandle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1447114248,
        AWATER: 5793983,
        INTPTLAT: '+35.3887575',
        INTPTLON: '-101.4441183',
        ELSDLEA: '',
        UNSDLEA: '34230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3675, 34.1732],
            [-102.2779, 34.103],
            [-102.2796, 33.9872],
            [-102.4629, 33.9872],
            [-102.4602, 34.1036],
            [-102.3675, 34.1732],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808160',
        NAME: 'Amherst Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267185280,
        AWATER: 64975,
        INTPTLAT: '+34.0612013',
        INTPTLON: '-102.3655340',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9043, 33.2042],
            [-96.8913, 33.2194],
            [-96.7329, 33.2036],
            [-96.6981, 33.1593],
            [-96.7334, 33.1008],
            [-96.8419, 33.0831],
            [-96.8968, 33.1098],
            [-96.9043, 33.2042],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820010',
        NAME: 'Frisco Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193437237,
        AWATER: 1012905,
        INTPTLAT: '+33.1504776',
        INTPTLON: '-096.8104403',
        ELSDLEA: '',
        UNSDLEA: '20010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3047, 33.4278],
            [-97.2391, 33.4257],
            [-97.1118, 33.4221],
            [-97.0331, 33.3508],
            [-97.1832, 33.3027],
            [-97.3342, 33.3418],
            [-97.3047, 33.4278],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839180',
        NAME: 'Sanger Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238926795,
        AWATER: 26979167,
        INTPTLAT: '+33.3718058',
        INTPTLON: '-097.1909439',
        ELSDLEA: '',
        UNSDLEA: '39180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0297, 33.3349],
            [-96.8922, 33.2884],
            [-96.9351, 33.2324],
            [-97.044, 33.2383],
            [-97.0297, 33.3349],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808910',
        NAME: 'Aubrey Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112228501,
        AWATER: 8058326,
        INTPTLAT: '+33.2794917',
        INTPTLON: '-096.9818877',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8341, 33.4055],
            [-96.6565, 33.4013],
            [-96.6452, 33.3774],
            [-96.6347, 33.3432],
            [-96.7058, 33.2764],
            [-96.8878, 33.2906],
            [-96.8341, 33.4055],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813290',
        NAME: 'Celina Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244800970,
        AWATER: 3971563,
        INTPTLAT: '+33.3388988',
        INTPTLON: '-096.7529774',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5122, 33.825],
            [-102.4373, 33.8251],
            [-102.439, 33.7177],
            [-102.3188, 33.7158],
            [-102.32, 33.639],
            [-102.2276, 33.638],
            [-102.2425, 33.5536],
            [-102.3496, 33.4864],
            [-102.351, 33.3887],
            [-102.4481, 33.3885],
            [-102.4366, 33.5263],
            [-102.5228, 33.5276],
            [-102.5072, 33.6408],
            [-102.6067, 33.6418],
            [-102.6122, 33.757],
            [-102.5147, 33.7563],
            [-102.5122, 33.825],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827240',
        NAME: 'Levelland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 821043844,
        AWATER: 14789,
        INTPTLAT: '+33.6132928',
        INTPTLON: '-102.4224036',
        ELSDLEA: '',
        UNSDLEA: '27240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2122, 28.1968],
            [-100.1137, 28.1978],
            [-99.3942, 28.2046],
            [-99.3889, 28.0304],
            [-98.8033, 28.0575],
            [-98.8031, 28.0126],
            [-98.8022, 27.7752],
            [-99.1483, 27.8022],
            [-99.1208, 27.6872],
            [-99.1292, 27.5057],
            [-99.1936, 27.4581],
            [-99.1924, 27.3724],
            [-99.3598, 27.3721],
            [-99.3602, 27.3063],
            [-99.4538, 27.2651],
            [-99.5044, 27.3399],
            [-99.4853, 27.4645],
            [-99.463, 27.5305],
            [-99.522, 27.5408],
            [-99.5419, 27.606],
            [-99.7215, 27.6662],
            [-99.7707, 27.7321],
            [-99.8723, 27.7953],
            [-99.8935, 27.8992],
            [-99.9898, 27.9929],
            [-100.0869, 28.1468],
            [-100.2122, 28.1968],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843650',
        NAME: 'United Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6455210327,
        AWATER: 32193917,
        INTPTLAT: '+27.8560208',
        INTPTLON: '-099.4305607',
        ELSDLEA: '',
        UNSDLEA: '43650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8022, 27.7752],
            [-98.7983, 27.354],
            [-98.7981, 27.268],
            [-98.9547, 27.2694],
            [-99.3336, 27.2732],
            [-99.3602, 27.3063],
            [-99.3598, 27.3721],
            [-99.1924, 27.3724],
            [-99.1936, 27.4581],
            [-99.1292, 27.5057],
            [-99.1208, 27.6872],
            [-99.1483, 27.8022],
            [-98.8022, 27.7752],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844810',
        NAME: 'Webb Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2216769765,
        AWATER: 3781873,
        INTPTLAT: '+27.5015800',
        INTPTLON: '-098.9994410',
        ELSDLEA: '',
        UNSDLEA: '44810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0647, 32.2709],
            [-103.0647, 32.5219],
            [-103.0647, 32.5222],
            [-102.211, 32.5232],
            [-102.2111, 32.3594],
            [-102.211, 32.177],
            [-102.2112, 32.0868],
            [-102.287, 32.087],
            [-102.7991, 32.0858],
            [-103.0645, 32.0871],
            [-103.0647, 32.2709],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808280',
        NAME: 'Andrews Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3886770077,
        AWATER: 957039,
        INTPTLAT: '+32.3122581',
        INTPTLON: '-102.6402058',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0224, 29.5019],
            [-95.8805, 29.5678],
            [-95.8911, 29.6277],
            [-96.0552, 29.6829],
            [-96.0327, 29.7279],
            [-95.9023, 29.7596],
            [-95.871, 29.6923],
            [-95.7245, 29.7041],
            [-95.7434, 29.6176],
            [-95.5543, 29.5038],
            [-95.5506, 29.4383],
            [-95.5771, 29.3681],
            [-95.7195, 29.4493],
            [-95.8833, 29.4842],
            [-95.9424, 29.3319],
            [-96.0175, 29.4374],
            [-96.0224, 29.5019],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826580',
        NAME: 'Lamar Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 968036486,
        AWATER: 32116983,
        INTPTLAT: '+29.5500067',
        INTPTLON: '-095.8082717',
        ELSDLEA: '',
        UNSDLEA: '26580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9424, 29.3319],
            [-95.8833, 29.4842],
            [-95.7195, 29.4493],
            [-95.5771, 29.3681],
            [-95.5908, 29.3275],
            [-95.6533, 29.2935],
            [-95.7529, 29.3245],
            [-95.8477, 29.2626],
            [-95.9424, 29.3319],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832310',
        NAME: 'Needville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 512103867,
        AWATER: 7637361,
        INTPTLAT: '+29.3741119',
        INTPTLON: '-095.7815274',
        ELSDLEA: '',
        UNSDLEA: '32310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7245, 29.7041],
            [-95.7019, 29.7184],
            [-95.5713, 29.6487],
            [-95.5826, 29.6179],
            [-95.558, 29.6107],
            [-95.537, 29.6328],
            [-95.4241, 29.5802],
            [-95.4625, 29.4392],
            [-95.5506, 29.4383],
            [-95.5543, 29.5038],
            [-95.7434, 29.6176],
            [-95.7245, 29.7041],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819650',
        NAME: 'Fort Bend Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424654665,
        AWATER: 15317397,
        INTPTLAT: '+29.5805333',
        INTPTLON: '-095.5785567',
        ELSDLEA: '',
        UNSDLEA: '19650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7834, 32.5254],
            [-100.6979, 32.5253],
            [-100.6621, 32.388],
            [-100.6641, 32.1584],
            [-100.757, 32.161],
            [-100.7843, 32.3137],
            [-100.7834, 32.5254],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828170',
        NAME: 'Loraine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424866904,
        AWATER: 79790,
        INTPTLAT: '+32.3541675',
        INTPTLON: '-100.7204183',
        ELSDLEA: '',
        UNSDLEA: '28170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1322, 31.2028],
            [-95.1028, 31.2375],
            [-95.0182, 31.2349],
            [-94.989, 31.182],
            [-94.8531, 31.1858],
            [-94.8429, 31.1466],
            [-94.9392, 31.042],
            [-95.0277, 31.1571],
            [-95.1322, 31.2028],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813380',
        NAME: 'Centerville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 274232645,
        AWATER: 21890,
        INTPTLAT: '+31.1355346',
        INTPTLON: '-094.9755540',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3433, 36.1543],
            [-101.3449, 36.2693],
            [-101.2311, 36.3015],
            [-101.2048, 36.4995],
            [-101.0852, 36.4992],
            [-101.0856, 36.2431],
            [-100.9999, 36.2438],
            [-100.9462, 36.163],
            [-100.9466, 36.0577],
            [-101.0857, 36.0576],
            [-101.0859, 36.0105],
            [-101.3911, 36.0088],
            [-101.3416, 36.0553],
            [-101.3433, 36.1543],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841010',
        NAME: 'Spearman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1230015288,
        AWATER: 768388,
        INTPTLAT: '+36.2062060',
        INTPTLON: '-101.1736925',
        ELSDLEA: '',
        UNSDLEA: '41010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9316, 36.5005],
            [-101.6906, 36.4995],
            [-101.6239, 36.4995],
            [-101.6237, 36.2406],
            [-101.7334, 36.2115],
            [-101.8799, 36.3865],
            [-101.8798, 36.4861],
            [-101.9316, 36.5005],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842540',
        NAME: 'Texhoma Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612157215,
        AWATER: 167861,
        INTPTLAT: '+36.3627168',
        INTPTLON: '-101.7296949',
        ELSDLEA: '',
        UNSDLEA: '42540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6291, 31.7086],
            [-98.6288, 31.7089],
            [-98.4106, 31.7286],
            [-98.399, 31.7175],
            [-98.4218, 31.6269],
            [-98.5081, 31.559],
            [-98.5675, 31.5697],
            [-98.6291, 31.7086],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835820',
        NAME: 'Priddy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258459103,
        AWATER: 169064,
        INTPTLAT: '+31.6553715',
        INTPTLON: '-098.5274654',
        ELSDLEA: '',
        UNSDLEA: '35820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.399, 31.7175],
            [-98.2987, 31.7688],
            [-98.3347, 31.8464],
            [-98.2739, 31.8887],
            [-98.2531, 31.8901],
            [-98.16, 31.895],
            [-97.9951, 31.8291],
            [-97.9203, 31.8949],
            [-97.8927, 31.8548],
            [-97.9478, 31.7926],
            [-97.886, 31.7259],
            [-97.9738, 31.7087],
            [-98.0274, 31.5736],
            [-98.1874, 31.5873],
            [-98.294, 31.5552],
            [-98.3917, 31.5342],
            [-98.4218, 31.6269],
            [-98.399, 31.7175],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822230',
        NAME: 'Hamilton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1335202674,
        AWATER: 746365,
        INTPTLAT: '+31.7102721',
        INTPTLON: '-098.1683509',
        ELSDLEA: '',
        UNSDLEA: '22230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1676, 35.102],
            [-102.1151, 35.1023],
            [-102.0283, 35.1018],
            [-101.9563, 35.1832],
            [-101.9382, 35.1468],
            [-101.7777, 35.1495],
            [-101.7601, 35.1834],
            [-101.6229, 35.1831],
            [-101.626, 34.9718],
            [-101.6686, 34.8836],
            [-101.8298, 34.8681],
            [-101.8113, 34.7929],
            [-102.009, 34.7787],
            [-102.1205, 34.7477],
            [-102.1688, 34.7474],
            [-102.1676, 35.102],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812810',
        NAME: 'Canyon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1826351799,
        AWATER: 25104450,
        INTPTLAT: '+34.9761533',
        INTPTLON: '-101.9113416',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2606, 31.6097],
            [-106.1971, 31.6512],
            [-106.2007, 32.0018],
            [-105.998, 32.0023],
            [-105.9934, 31.5638],
            [-106.0616, 31.5641],
            [-106.193, 31.541],
            [-106.2606, 31.6097],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814430',
        NAME: 'Clint Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 978350323,
        AWATER: 326643,
        INTPTLAT: '+31.7756674',
        INTPTLON: '-106.1031743',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.193, 31.541],
            [-106.0616, 31.5641],
            [-106.0611, 31.5058],
            [-106.131, 31.4247],
            [-106.2196, 31.4816],
            [-106.2462, 31.5412],
            [-106.193, 31.541],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818900',
        NAME: 'Fabens Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153100344,
        AWATER: 446314,
        INTPTLAT: '+31.5101458',
        INTPTLON: '-106.1457091',
        ELSDLEA: '',
        UNSDLEA: '18900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-106.3025, 31.8021],
              [-106.3201, 31.6446],
              [-106.4041, 31.7446],
              [-106.3025, 31.8021],
            ],
          ],
          [
            [
              [-106.4093, 32.0013],
              [-106.3263, 32.0015],
              [-106.3235, 32.0015],
              [-106.4093, 32.0013],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846680',
        NAME: 'Ysleta Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161700851,
        AWATER: 464636,
        INTPTLAT: '+31.7331027',
        INTPTLON: '-106.3068189',
        ELSDLEA: '',
        UNSDLEA: '46680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8145, 33.137],
            [-94.8522, 33.0693],
            [-94.832, 32.9873],
            [-94.9406, 33.0745],
            [-94.9392, 33.1584],
            [-94.8145, 33.137],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813680',
        NAME: 'Chapel Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 134046913,
        AWATER: 3769547,
        INTPTLAT: '+33.0911834',
        INTPTLON: '-094.8823362',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7893, 29.07],
            [-95.7102, 29.1337],
            [-95.6664, 29.0469],
            [-95.5991, 29.0342],
            [-95.5487, 28.938],
            [-95.453, 28.8783],
            [-95.4014, 28.8059],
            [-95.4956, 28.7648],
            [-95.6554, 28.9517],
            [-95.7649, 28.9674],
            [-95.7893, 29.07],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841970',
        NAME: 'Sweeny Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382014814,
        AWATER: 84133635,
        INTPTLAT: '+28.9657730',
        INTPTLON: '-095.6062331',
        ELSDLEA: '',
        UNSDLEA: '41970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5908, 29.3275],
            [-95.5771, 29.3681],
            [-95.5506, 29.4383],
            [-95.4625, 29.4392],
            [-95.4611, 29.3597],
            [-95.3485, 29.3534],
            [-95.3522, 29.2986],
            [-95.4551, 29.2807],
            [-95.3247, 29.1783],
            [-95.3475, 29.2932],
            [-95.26, 29.2236],
            [-95.1785, 29.2992],
            [-95.0931, 29.256],
            [-95.0896, 29.1477],
            [-95.124, 29.0803],
            [-95.1707, 29.1103],
            [-95.3377, 29.0517],
            [-95.489, 29.1111],
            [-95.5104, 29.0706],
            [-95.5403, 29.224],
            [-95.5908, 29.3275],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808310',
        NAME: 'Angleton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 976827951,
        AWATER: 146883923,
        INTPTLAT: '+29.1178681',
        INTPTLON: '-095.4732571',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8419, 29.2587],
            [-95.7717, 29.207],
            [-95.6533, 29.2935],
            [-95.5908, 29.3275],
            [-95.5403, 29.224],
            [-95.5104, 29.0706],
            [-95.4771, 29.0492],
            [-95.5487, 28.938],
            [-95.5991, 29.0342],
            [-95.6664, 29.0469],
            [-95.7102, 29.1337],
            [-95.7893, 29.07],
            [-95.8417, 29.1735],
            [-95.8419, 29.2587],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814670',
        NAME: 'Columbia-Brazoria Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 607787011,
        AWATER: 37143402,
        INTPTLAT: '+29.1437969',
        INTPTLON: '-095.6611010',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5189, 32.8335],
            [-96.5169, 32.9823],
            [-96.4439, 32.9824],
            [-96.406, 32.9819],
            [-96.3082, 32.8142],
            [-96.3808, 32.8137],
            [-96.519, 32.8067],
            [-96.5189, 32.8335],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837650',
        NAME: 'Rockwall Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222372734,
        AWATER: 57170680,
        INTPTLAT: '+32.8887864',
        INTPTLON: '-096.4440595',
        ELSDLEA: '',
        UNSDLEA: '37650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.742, 33.9922],
            [-101.5645, 34.0213],
            [-101.5638, 33.9101],
            [-101.4808, 33.9107],
            [-101.4834, 33.8311],
            [-101.5788, 33.8303],
            [-101.6931, 33.8292],
            [-101.7433, 33.949],
            [-101.742, 33.9922],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834680',
        NAME: 'Petersburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394409820,
        AWATER: 0,
        INTPTLAT: '+33.9093812',
        INTPTLON: '-101.6201975',
        ELSDLEA: '',
        UNSDLEA: '34680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0582, 34.1303],
            [-102.0279, 34.0505],
            [-101.9142, 34.0504],
            [-101.8709, 33.9633],
            [-101.9937, 33.8828],
            [-102.0864, 33.8824],
            [-102.0888, 34.1375],
            [-102.0582, 34.1303],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815360',
        NAME: 'Cotton Center Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341436329,
        AWATER: 100393,
        INTPTLAT: '+33.9871092',
        INTPTLON: '-102.0019020',
        ELSDLEA: '',
        UNSDLEA: '15360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0582, 34.1303],
            [-101.9875, 34.1447],
            [-101.8183, 34.1519],
            [-101.7487, 34.0938],
            [-101.742, 33.9922],
            [-101.7433, 33.949],
            [-101.8709, 33.9633],
            [-101.9142, 34.0504],
            [-102.0279, 34.0505],
            [-102.0582, 34.1303],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822050',
        NAME: 'Hale Center Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405968278,
        AWATER: 0,
        INTPTLAT: '+34.0655009',
        INTPTLON: '-101.8703780',
        ELSDLEA: '',
        UNSDLEA: '22050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5574, 32.961],
            [-101.1734, 32.9636],
            [-101.1746, 32.5241],
            [-101.3284, 32.5243],
            [-101.5174, 32.5249],
            [-101.5857, 32.4586],
            [-101.6241, 32.5758],
            [-101.6893, 32.6312],
            [-101.6908, 32.8282],
            [-101.6913, 32.9618],
            [-101.5574, 32.961],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810860',
        NAME: 'Borden County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2330315701,
        AWATER: 22335281,
        INTPTLAT: '+32.7385899',
        INTPTLON: '-101.4391990',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6666, 26.5989],
            [-97.2254, 26.6002],
            [-97.1833, 26.4714],
            [-97.5981, 26.4688],
            [-97.6841, 26.4824],
            [-97.6666, 26.5989],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839000',
        NAME: 'San Perlita Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400522635,
        AWATER: 306390187,
        INTPTLAT: '+26.5600555',
        INTPTLON: '-097.4768137',
        ELSDLEA: '',
        UNSDLEA: '39000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9923, 33.4965],
            [-99.8991, 33.4564],
            [-99.7399, 33.5102],
            [-99.7198, 33.3984],
            [-99.7871, 33.347],
            [-99.9909, 33.3408],
            [-99.991, 33.3974],
            [-99.9923, 33.4965],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825870',
        NAME: "Knox City-O'Brien Consolidated Independent School District",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372020585,
        AWATER: 1318126,
        INTPTLAT: '+33.4164496',
        INTPTLON: '-099.8616620',
        ELSDLEA: '',
        UNSDLEA: '25870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.086, 35.8239],
            [-101.086, 35.8234],
            [-101.0861, 35.6253],
            [-101.3283, 35.6248],
            [-101.3286, 35.6852],
            [-101.3524, 35.7285],
            [-101.2181, 35.8147],
            [-101.086, 35.8239],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841130',
        NAME: 'Spring Creek Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435785793,
        AWATER: 205699,
        INTPTLAT: '+35.7015439',
        INTPTLON: '-101.2127055',
        ELSDLEA: '',
        UNSDLEA: '41130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3456, 30.6005],
            [-102.567, 30.0528],
            [-102.3208, 29.8789],
            [-102.4045, 29.7652],
            [-102.5718, 29.769],
            [-103.3541, 29.7688],
            [-103.3691, 30.3767],
            [-103.3456, 30.6005],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828980',
        NAME: 'Marathon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5251446296,
        AWATER: 8324637,
        INTPTLAT: '+30.0597953',
        INTPTLON: '-102.9669270',
        ELSDLEA: '',
        UNSDLEA: '28980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4345, 33.1888],
            [-95.3329, 33.2515],
            [-95.3081, 33.0782],
            [-95.3504, 33.1524],
            [-95.4675, 33.1067],
            [-95.4345, 33.1888],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838610',
        NAME: 'Saltillo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151253364,
        AWATER: 3366002,
        INTPTLAT: '+33.1641995',
        INTPTLON: '-095.3715572',
        ELSDLEA: '',
        UNSDLEA: '38610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4271, 32.9619],
            [-95.3424, 32.9819],
            [-95.3083, 33.0503],
            [-95.1522, 33.0134],
            [-95.1601, 32.8982],
            [-95.169, 32.8225],
            [-95.2381, 32.7345],
            [-95.2697, 32.7607],
            [-95.3667, 32.9075],
            [-95.4271, 32.9619],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846200',
        NAME: 'Winnsboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426632552,
        AWATER: 8398551,
        INTPTLAT: '+32.9068048',
        INTPTLON: '-095.2834170',
        ELSDLEA: '',
        UNSDLEA: '46200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4208, 33.363],
            [-98.2707, 33.1963],
            [-98.291, 33.0609],
            [-98.3128, 33.0062],
            [-98.4266, 33.0079],
            [-98.4587, 33.1364],
            [-98.4234, 33.1931],
            [-98.4208, 33.363],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811820',
        NAME: 'Bryson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470455544,
        AWATER: 3761074,
        INTPTLAT: '+33.1713056',
        INTPTLON: '-098.3655847',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4289, 32.0136],
            [-100.2354, 32.0363],
            [-100.1607, 31.9195],
            [-100.1849, 31.7927],
            [-100.1186, 31.6989],
            [-100.2986, 31.6927],
            [-100.4734, 31.6933],
            [-100.4255, 31.7396],
            [-100.4289, 32.0136],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811490',
        NAME: 'Bronte Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 846056100,
        AWATER: 4970823,
        INTPTLAT: '+31.8640081',
        INTPTLON: '-100.2975367',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0717, 30.526],
            [-94.0873, 30.5543],
            [-93.883, 30.5397],
            [-93.8856, 30.495],
            [-93.9015, 30.2427],
            [-93.9894, 30.2422],
            [-94.0278, 30.2876],
            [-94.0996, 30.364],
            [-94.1165, 30.4327],
            [-94.0717, 30.526],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812090',
        NAME: 'Buna Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567525202,
        AWATER: 2032402,
        INTPTLAT: '+30.4133013',
        INTPTLON: '-093.9837679',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0996, 30.364],
            [-94.0278, 30.2876],
            [-94.1016, 30.2623],
            [-94.0996, 30.364],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818750',
        NAME: 'Evadale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70199141,
        AWATER: 2537555,
        INTPTLAT: '+30.3168083',
        INTPTLON: '-094.0738146',
        ELSDLEA: '',
        UNSDLEA: '18750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4864, 29.2861],
            [-97.3337, 29.3162],
            [-97.2101, 29.2132],
            [-97.2243, 29.113],
            [-97.14, 29.1227],
            [-97.049, 29.0528],
            [-97.0799, 29.0301],
            [-97.1606, 28.9709],
            [-97.184, 29.0336],
            [-97.2715, 29.0493],
            [-97.3597, 28.9655],
            [-97.4075, 29.0538],
            [-97.3296, 29.0895],
            [-97.4506, 29.2308],
            [-97.4864, 29.2861],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815960',
        NAME: 'Cuero Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 693462901,
        AWATER: 1602866,
        INTPTLAT: '+29.1377628',
        INTPTLON: '-097.2820466',
        ELSDLEA: '',
        UNSDLEA: '15960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2255, 29.5018],
            [-95.2164, 29.5561],
            [-95.2081, 29.4544],
            [-95.2255, 29.4541],
            [-95.2255, 29.5018],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819950',
        NAME: 'Friendswood Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42601507,
        AWATER: 213750,
        INTPTLAT: '+29.5027403',
        INTPTLON: '-095.2071109',
        ELSDLEA: '',
        UNSDLEA: '19950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0403, 29.3922],
            [-95.023, 29.3925],
            [-94.904, 29.285],
            [-95.0896, 29.1477],
            [-95.0931, 29.256],
            [-95.1034, 29.2714],
            [-95.0403, 29.3922],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823310',
        NAME: 'Hitchcock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146080536,
        AWATER: 79763830,
        INTPTLAT: '+29.2826196',
        INTPTLON: '-095.0076307',
        ELSDLEA: '',
        UNSDLEA: '23310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9406, 29.4186],
            [-94.9305, 29.3372],
            [-94.8438, 29.3357],
            [-94.904, 29.285],
            [-95.023, 29.3925],
            [-94.9406, 29.4186],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826160',
        NAME: 'La Marque Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60420690,
        AWATER: 28120945,
        INTPTLAT: '+29.3955476',
        INTPTLON: '-094.9807072',
        ELSDLEA: '',
        UNSDLEA: '26160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.931, 32.4706],
            [-97.7878, 32.3139],
            [-97.9456, 32.2335],
            [-97.953, 32.2501],
            [-98.0284, 32.4201],
            [-97.931, 32.4706],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842900',
        NAME: 'Tolar Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291509124,
        AWATER: 4422943,
        INTPTLAT: '+32.3626492',
        INTPTLON: '-097.9243536',
        ELSDLEA: '',
        UNSDLEA: '42900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2031, 31.6215],
            [-99.0701, 31.6311],
            [-98.9915, 31.6333],
            [-98.9429, 31.5121],
            [-98.9427, 31.4586],
            [-99.0376, 31.4895],
            [-99.0906, 31.4609],
            [-99.2028, 31.4672],
            [-99.2031, 31.6215],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811550',
        NAME: 'Brookesmith Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407930173,
        AWATER: 1660532,
        INTPTLAT: '+31.5460634',
        INTPTLON: '-099.0756206',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5675, 31.5697],
            [-98.5081, 31.559],
            [-98.4218, 31.6269],
            [-98.3917, 31.5342],
            [-98.4517, 31.4777],
            [-98.4073, 31.35],
            [-98.5326, 31.328],
            [-98.5798, 31.2654],
            [-98.6966, 31.4073],
            [-98.8061, 31.4065],
            [-98.5675, 31.5697],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821000',
        NAME: 'Goldthwaite Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 719820505,
        AWATER: 1412218,
        INTPTLAT: '+31.4360815',
        INTPTLON: '-098.5559036',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1694, 26.5717],
            [-99.0111, 26.675],
            [-98.9542, 26.7857],
            [-98.6429, 26.7841],
            [-98.6242, 26.6181],
            [-98.7714, 26.6292],
            [-98.8144, 26.5431],
            [-98.8755, 26.5089],
            [-98.9012, 26.3705],
            [-99.082, 26.3967],
            [-99.092, 26.4724],
            [-99.1694, 26.5717],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837740',
        NAME: 'Roma Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1321486369,
        AWATER: 9670577,
        INTPTLAT: '+26.6111370',
        INTPTLON: '-098.9041734',
        ELSDLEA: '',
        UNSDLEA: '37740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.235, 34.3132],
            [-102.0091, 34.3131],
            [-101.9875, 34.1447],
            [-102.0582, 34.1303],
            [-102.0888, 34.1375],
            [-102.0864, 33.8824],
            [-102.0861, 33.8656],
            [-102.2255, 33.8669],
            [-102.1971, 34.0878],
            [-102.2779, 34.103],
            [-102.3675, 34.1732],
            [-102.3736, 34.1778],
            [-102.2412, 34.2016],
            [-102.235, 34.3132],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833660',
        NAME: 'Olton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 900303395,
        AWATER: 128845,
        INTPTLAT: '+34.1216305',
        INTPTLON: '-102.1619864',
        ELSDLEA: '',
        UNSDLEA: '33660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9547, 27.2694],
            [-98.7981, 27.268],
            [-98.7983, 27.354],
            [-98.5526, 27.3591],
            [-98.5223, 27.3433],
            [-98.5233, 27.2651],
            [-98.4929, 27.235],
            [-98.4665, 27.0547],
            [-98.4179, 27.0553],
            [-98.4226, 26.7835],
            [-98.6429, 26.7841],
            [-98.9542, 26.7857],
            [-98.9547, 27.2694],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824750',
        NAME: 'Jim Hogg County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2942597110,
        AWATER: 92565,
        INTPTLAT: '+27.0412118',
        INTPTLON: '-098.7001270',
        ELSDLEA: '',
        UNSDLEA: '24750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3142, 31.4084],
            [-94.2488, 31.4812],
            [-94.1481, 31.4853],
            [-94.1326, 31.3087],
            [-94.046, 31.3001],
            [-94.0339, 31.2331],
            [-94.1151, 31.2407],
            [-94.1337, 31.1573],
            [-94.0655, 31.112],
            [-94.1296, 31.0993],
            [-94.3266, 31.2248],
            [-94.3142, 31.4084],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811430',
        NAME: 'Broaddus Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601866457,
        AWATER: 125901071,
        INTPTLAT: '+31.3098255',
        INTPTLON: '-094.2138140',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9794, 30.1377],
            [-98.772, 30.138],
            [-98.711, 30.1001],
            [-98.6512, 30.0324],
            [-98.7478, 29.9249],
            [-98.7499, 29.8549],
            [-98.8854, 29.8418],
            [-98.9177, 29.7814],
            [-98.9897, 29.8135],
            [-98.9901, 29.9115],
            [-98.9532, 29.9736],
            [-99.0601, 30.0156],
            [-99.0752, 30.1049],
            [-98.9794, 30.1377],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814790',
        NAME: 'Comfort Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 984611037,
        AWATER: 973195,
        INTPTLAT: '+29.9868809',
        INTPTLON: '-098.8673480',
        ELSDLEA: '',
        UNSDLEA: '14790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6033, 30.2893],
            [-99.4767, 30.2882],
            [-99.3936, 30.2116],
            [-99.3014, 30.1879],
            [-99.301, 30.1343],
            [-99.1878, 30.1354],
            [-99.1801, 30.085],
            [-99.2752, 30.0167],
            [-99.3023, 30.101],
            [-99.4381, 30.0892],
            [-99.4501, 30.1512],
            [-99.5342, 30.1538],
            [-99.6193, 30.2002],
            [-99.6033, 30.2893],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824210',
        NAME: 'Ingram Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 539526010,
        AWATER: 2140383,
        INTPTLAT: '+30.1667391',
        INTPTLON: '-099.4010421',
        ELSDLEA: '',
        UNSDLEA: '24210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3008, 33.8325],
            [-101.0412, 33.8336],
            [-101.0389, 33.3651],
            [-101.0389, 33.3057],
            [-101.1628, 33.306],
            [-101.162, 33.397],
            [-101.2745, 33.3967],
            [-101.24, 33.5158],
            [-101.3076, 33.5161],
            [-101.3008, 33.8325],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800001',
        NAME: 'Crosbyton Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1219549658,
        AWATER: 4083476,
        INTPTLAT: '+33.5661903',
        INTPTLON: '-101.1450909',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5396, 34.9962],
            [-100.5391, 35.1252],
            [-100.4503, 35.1545],
            [-100.3994, 35.1829],
            [-100.186, 35.1828],
            [-100.0004, 35.1393],
            [-100.0004, 35.0304],
            [-100.0004, 34.6163],
            [-100.0833, 34.6451],
            [-100.2751, 34.6314],
            [-100.2751, 34.7111],
            [-100.2749, 34.7471],
            [-100.4698, 34.7476],
            [-100.5404, 34.8061],
            [-100.5401, 34.9217],
            [-100.434, 34.9217],
            [-100.4336, 35.0085],
            [-100.5396, 34.9962],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844890',
        NAME: 'Wellington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2327587979,
        AWATER: 3001991,
        INTPTLAT: '+34.9144411',
        INTPTLON: '-100.2308269',
        ELSDLEA: '',
        UNSDLEA: '44890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.112, 31.4892],
            [-100.1121, 31.5032],
            [-100.0034, 31.4577],
            [-99.99, 31.3223],
            [-99.8671, 31.33],
            [-99.8667, 31.3863],
            [-99.7589, 31.4089],
            [-99.6344, 31.3929],
            [-99.6864, 31.3784],
            [-99.7212, 31.1615],
            [-99.6689, 31.087],
            [-100.1152, 31.088],
            [-100.112, 31.4892],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818070',
        NAME: 'Eden Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1421987043,
        AWATER: 2934720,
        INTPTLAT: '+31.2461609',
        INTPTLON: '-099.8959677',
        ELSDLEA: '',
        UNSDLEA: '18070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.112, 31.5274],
            [-100.0419, 31.5794],
            [-100.0074, 31.5789],
            [-99.9357, 31.5781],
            [-99.7217, 31.5768],
            [-99.6462, 31.4913],
            [-99.6344, 31.3929],
            [-99.7589, 31.4089],
            [-99.8667, 31.3863],
            [-99.8671, 31.33],
            [-99.99, 31.3223],
            [-100.0034, 31.4577],
            [-100.1121, 31.5032],
            [-100.112, 31.5274],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833980',
        NAME: 'Paint Rock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 802397281,
        AWATER: 22594036,
        INTPTLAT: '+31.4847648',
        INTPTLON: '-099.8805509',
        ELSDLEA: '',
        UNSDLEA: '33980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7625, 33.5344],
            [-101.558, 33.4907],
            [-101.557, 33.4033],
            [-101.592, 33.3745],
            [-101.7114, 33.3939],
            [-101.7625, 33.5344],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840440',
        NAME: 'Slaton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226098239,
        AWATER: 1431607,
        INTPTLAT: '+33.4532290',
        INTPTLON: '-101.6521492',
        ELSDLEA: '',
        UNSDLEA: '40440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7286, 30.7348],
            [-94.6785, 30.7959],
            [-94.5964, 30.7442],
            [-94.5752, 30.6543],
            [-94.5457, 30.527],
            [-94.581, 30.4908],
            [-94.7327, 30.4901],
            [-94.7753, 30.489],
            [-94.7389, 30.5538],
            [-94.7286, 30.7348],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810170',
        NAME: 'Big Sandy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 514928865,
        AWATER: 378999,
        INTPTLAT: '+30.5971535',
        INTPTLON: '-094.6524154',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0063, 30.9872],
            [-94.9392, 31.042],
            [-94.8429, 31.1466],
            [-94.7527, 31.1064],
            [-94.6152, 31.0852],
            [-94.7001, 31.0332],
            [-94.7585, 30.8651],
            [-94.8911, 30.8492],
            [-94.9743, 30.8762],
            [-95.0063, 30.9872],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815300',
        NAME: 'Corrigan-Camden Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 710729717,
        AWATER: 854273,
        INTPTLAT: '+30.9896643',
        INTPTLON: '-094.8329933',
        ELSDLEA: '',
        UNSDLEA: '15300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8911, 30.8492],
            [-94.7585, 30.8651],
            [-94.6567, 30.8409],
            [-94.6785, 30.7959],
            [-94.7286, 30.7348],
            [-94.8838, 30.7456],
            [-94.8911, 30.8492],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827120',
        NAME: 'Leggett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233982047,
        AWATER: 86551,
        INTPTLAT: '+30.8104965',
        INTPTLON: '-094.8002910',
        ELSDLEA: '',
        UNSDLEA: '27120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3873, 32.6516],
            [-96.3722, 32.6783],
            [-96.3299, 32.6448],
            [-96.1523, 32.6642],
            [-96.0758, 32.5657],
            [-96.0757, 32.5486],
            [-96.1408, 32.4779],
            [-96.2474, 32.5145],
            [-96.2885, 32.4556],
            [-96.3623, 32.5901],
            [-96.3873, 32.6516],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825200',
        NAME: 'Kaufman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424894669,
        AWATER: 5162859,
        INTPTLAT: '+32.5590787',
        INTPTLON: '-096.2277260',
        ELSDLEA: '',
        UNSDLEA: '25200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1408, 32.4779],
            [-96.0757, 32.5486],
            [-96.0046, 32.4619],
            [-96.0031, 32.3572],
            [-96.0759, 32.3575],
            [-96.1151, 32.2486],
            [-96.2314, 32.2986],
            [-96.1678, 32.3398],
            [-96.1759, 32.4456],
            [-96.1408, 32.4779],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828680',
        NAME: 'Mabank Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291631664,
        AWATER: 44231834,
        INTPTLAT: '+32.4011967',
        INTPTLON: '-096.1101625',
        ELSDLEA: '',
        UNSDLEA: '28680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4343, 32.4109],
            [-96.2885, 32.4556],
            [-96.2474, 32.5145],
            [-96.1408, 32.4779],
            [-96.1759, 32.4456],
            [-96.1678, 32.3398],
            [-96.2314, 32.2986],
            [-96.2856, 32.2669],
            [-96.2959, 32.2934],
            [-96.4498, 32.3449],
            [-96.4343, 32.4109],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825320',
        NAME: 'Kemp Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399012965,
        AWATER: 42180172,
        INTPTLAT: '+32.4015294',
        INTPTLON: '-096.2721335',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2973, 32.8671],
            [-96.2077, 32.964],
            [-96.1322, 33.014],
            [-96.0987, 33.0094],
            [-95.9663, 32.92],
            [-95.9344, 32.8372],
            [-96.099, 32.8238],
            [-96.1729, 32.8136],
            [-96.2361, 32.8873],
            [-96.2973, 32.8671],
          ],
          [
            [-96.1437, 32.98],
            [-96.1192, 32.9394],
            [-96.082, 32.977],
            [-96.1437, 32.98],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836240',
        NAME: 'Quinlan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305536814,
        AWATER: 80958233,
        INTPTLAT: '+32.9108889',
        INTPTLON: '-096.1194444',
        ELSDLEA: '',
        UNSDLEA: '36240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7541, 30.2907],
            [-99.6033, 30.2893],
            [-99.6193, 30.2002],
            [-99.5342, 30.1538],
            [-99.5331, 30.0679],
            [-99.4711, 29.9997],
            [-99.4711, 29.9064],
            [-99.499, 29.9067],
            [-99.6028, 29.9077],
            [-99.6912, 29.9086],
            [-99.6899, 30.0739],
            [-99.7576, 30.0741],
            [-99.7541, 30.2907],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817190',
        NAME: 'Divide Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726854640,
        AWATER: 640278,
        INTPTLAT: '+30.0951146',
        INTPTLON: '-099.6282112',
        ELSDLEA: '',
        UNSDLEA: '17190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5342, 30.1538],
            [-99.4501, 30.1512],
            [-99.4381, 30.0892],
            [-99.3023, 30.101],
            [-99.2752, 30.0167],
            [-99.3604, 29.9441],
            [-99.4711, 29.9064],
            [-99.4711, 29.9997],
            [-99.5331, 30.0679],
            [-99.5342, 30.1538],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823970',
        NAME: 'Hunt Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 451141070,
        AWATER: 1676052,
        INTPTLAT: '+30.0191421',
        INTPTLON: '-099.4166072',
        ELSDLEA: '',
        UNSDLEA: '23970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0896, 29.1477],
            [-94.904, 29.285],
            [-94.8438, 29.3357],
            [-94.8299, 29.422],
            [-94.8769, 29.5141],
            [-94.6456, 29.5492],
            [-94.564, 29.4711],
            [-94.5663, 29.4231],
            [-94.6662, 29.281],
            [-95.0916, 29.0627],
            [-95.124, 29.0803],
            [-95.0896, 29.1477],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820280',
        NAME: 'Galveston Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172570553,
        AWATER: 837446441,
        INTPTLAT: '+29.3351300',
        INTPTLON: '-094.8114982',
        ELSDLEA: '',
        UNSDLEA: '20280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4714, 34.6752],
            [-101.4716, 34.7475],
            [-101.1999, 34.7477],
            [-101.2287, 34.6788],
            [-101.1192, 34.6246],
            [-101.1018, 34.4791],
            [-101.1545, 34.4068],
            [-101.1714, 34.3123],
            [-101.2233, 34.3123],
            [-101.4716, 34.3123],
            [-101.4721, 34.4341],
            [-101.4714, 34.6752],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840290',
        NAME: 'Silverton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1432489714,
        AWATER: 2832667,
        INTPTLAT: '+34.5078094',
        INTPTLON: '-101.3085448',
        ELSDLEA: '',
        UNSDLEA: '40290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6305, 33.2263],
            [-101.4364, 33.3297],
            [-101.3344, 33.3286],
            [-101.3336, 33.3965],
            [-101.2745, 33.3967],
            [-101.162, 33.397],
            [-101.1628, 33.306],
            [-101.0389, 33.3057],
            [-101.0386, 33.0559],
            [-101.0387, 32.9702],
            [-101.1734, 32.9636],
            [-101.5574, 32.961],
            [-101.5576, 33.0339],
            [-101.5779, 33.1512],
            [-101.6306, 33.2114],
            [-101.6305, 33.2263],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835490',
        NAME: 'Post Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2035581762,
        AWATER: 6622111,
        INTPTLAT: '+33.1489680',
        INTPTLON: '-101.2913114',
        ELSDLEA: '',
        UNSDLEA: '35490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1107, 33.0224],
            [-101.9979, 32.9603],
            [-102.0143, 32.8761],
            [-102.097, 32.8278],
            [-102.2059, 32.8188],
            [-102.2084, 32.9441],
            [-102.1988, 33.0205],
            [-102.1107, 33.0224],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816350',
        NAME: 'Dawson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 357372526,
        AWATER: 313262,
        INTPTLAT: '+32.9254586',
        INTPTLON: '-102.1139125',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0759, 33.3896],
            [-101.9814, 33.3906],
            [-101.8074, 33.3927],
            [-101.8065, 33.3273],
            [-101.8772, 33.3271],
            [-101.8776, 33.2397],
            [-102.0759, 33.2531],
            [-102.0759, 33.3452],
            [-102.0759, 33.3896],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832490',
        NAME: 'New Home Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350540621,
        AWATER: 851,
        INTPTLAT: '+33.3159846',
        INTPTLON: '-101.9549391',
        ELSDLEA: '',
        UNSDLEA: '32490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.8074, 33.3927],
            [-101.7114, 33.3939],
            [-101.592, 33.3745],
            [-101.6305, 33.2263],
            [-101.6306, 33.2114],
            [-101.8064, 33.2394],
            [-101.8065, 33.3273],
            [-101.8074, 33.3927],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845990',
        NAME: 'Wilson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356533092,
        AWATER: 2323764,
        INTPTLAT: '+33.3041328',
        INTPTLON: '-101.7003472',
        ELSDLEA: '',
        UNSDLEA: '45990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0756, 33.1086],
            [-102.0155, 33.0655],
            [-101.8944, 33.0653],
            [-101.8243, 33.0347],
            [-101.5576, 33.0339],
            [-101.5574, 32.961],
            [-101.6913, 32.9618],
            [-101.6908, 32.8282],
            [-101.7877, 32.8066],
            [-101.81, 32.876],
            [-101.9263, 32.8504],
            [-101.9979, 32.9603],
            [-102.1107, 33.0224],
            [-102.0756, 33.1086],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833390',
        NAME: "O'Donnell Independent School District",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 841728540,
        AWATER: 1441342,
        INTPTLAT: '+32.9745224',
        INTPTLON: '-101.8380135',
        ELSDLEA: '',
        UNSDLEA: '33390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9644, 26.5688],
            [-97.8622, 26.5533],
            [-97.8625, 26.4454],
            [-97.9083, 26.4332],
            [-98.0001, 26.4482],
            [-97.9644, 26.5688],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826850',
        NAME: 'Lasara Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155481473,
        AWATER: 15424626,
        INTPTLAT: '+26.4942137',
        INTPTLON: '-097.9303493',
        ELSDLEA: '',
        UNSDLEA: '26850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7758, 31.6513],
            [-101.2671, 31.6509],
            [-101.2676, 31.5565],
            [-101.2679, 31.5287],
            [-101.2748, 31.0794],
            [-101.7762, 31.0798],
            [-101.7758, 31.6513],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836570',
        NAME: 'Reagan County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3044012007,
        AWATER: 1792716,
        INTPTLAT: '+31.3751911',
        INTPTLON: '-101.5143954',
        ELSDLEA: '',
        UNSDLEA: '36570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6434, 28.7121],
            [-96.5746, 28.7055],
            [-96.4284, 28.7035],
            [-96.3227, 28.6429],
            [-96.3952, 28.2797],
            [-96.6188, 28.1695],
            [-96.8159, 28.0228],
            [-96.8309, 28.108],
            [-96.7905, 28.3192],
            [-96.7786, 28.4043],
            [-96.891, 28.5072],
            [-96.9304, 28.5867],
            [-96.8538, 28.6352],
            [-96.6434, 28.7121],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812480',
        NAME: 'Calhoun County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1312707083,
        AWATER: 1361903486,
        INTPTLAT: '+28.4417191',
        INTPTLON: '-096.5795739',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7059, 32.8792],
            [-94.654, 32.8795],
            [-94.4126, 32.8801],
            [-94.1214, 32.8809],
            [-94.0431, 32.8811],
            [-94.0431, 32.693],
            [-94.1316, 32.7385],
            [-94.2428, 32.7507],
            [-94.332, 32.7148],
            [-94.3926, 32.6944],
            [-94.5073, 32.6937],
            [-94.6732, 32.7784],
            [-94.7059, 32.8792],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824730',
        NAME: 'Jefferson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 946367381,
        AWATER: 98144141,
        INTPTLAT: '+32.7983200',
        INTPTLON: '-094.3420905',
        ELSDLEA: '',
        UNSDLEA: '24730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.799, 32.8579],
            [-94.709, 32.8959],
            [-94.7059, 32.8792],
            [-94.6732, 32.7784],
            [-94.7021, 32.7767],
            [-94.8193, 32.782],
            [-94.799, 32.8579],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833840',
        NAME: 'Ore City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174881553,
        AWATER: 10750976,
        INTPTLAT: '+32.8176400',
        INTPTLON: '-094.7515175',
        ELSDLEA: '',
        UNSDLEA: '33840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2425, 33.5536],
            [-102.079, 33.5194],
            [-102.0759, 33.3896],
            [-102.0759, 33.3452],
            [-102.1539, 33.3891],
            [-102.351, 33.3887],
            [-102.3496, 33.4864],
            [-102.2425, 33.5536],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837830',
        NAME: 'Ropes Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409091325,
        AWATER: 0,
        INTPTLAT: '+33.4514663',
        INTPTLON: '-102.2021409',
        ELSDLEA: '',
        UNSDLEA: '37830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7605, 32.9588],
            [-102.6641, 32.9641],
            [-102.5948, 33.0221],
            [-102.5947, 33.1091],
            [-102.5176, 33.1234],
            [-102.5262, 32.9637],
            [-102.468, 32.9638],
            [-102.4854, 32.8569],
            [-102.4508, 32.7989],
            [-102.5891, 32.7989],
            [-102.5885, 32.8861],
            [-102.7606, 32.886],
            [-102.7605, 32.9588],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839600',
        NAME: 'Seagraves Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499842095,
        AWATER: 30145,
        INTPTLAT: '+32.9377951',
        INTPTLON: '-102.5828813',
        ELSDLEA: '',
        UNSDLEA: '39600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1021, 33.2539],
            [-102.0759, 33.2531],
            [-101.8776, 33.2397],
            [-101.8772, 33.3271],
            [-101.8065, 33.3273],
            [-101.8064, 33.2394],
            [-101.6306, 33.2114],
            [-101.5779, 33.1512],
            [-101.5576, 33.0339],
            [-101.8243, 33.0347],
            [-101.8944, 33.0653],
            [-102.0155, 33.0655],
            [-102.0756, 33.1086],
            [-102.0868, 33.1526],
            [-102.1021, 33.2539],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842090',
        NAME: 'Tahoka Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 940311906,
        AWATER: 1544034,
        INTPTLAT: '+33.1587063',
        INTPTLON: '-101.8393388',
        ELSDLEA: '',
        UNSDLEA: '42090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.873, 29.9198],
            [-95.7797, 29.9288],
            [-95.7388, 29.8313],
            [-95.5968, 29.8315],
            [-95.62, 29.7706],
            [-95.6441, 29.7356],
            [-95.7019, 29.7184],
            [-95.7245, 29.7041],
            [-95.871, 29.6923],
            [-95.9023, 29.7596],
            [-95.873, 29.9198],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825170',
        NAME: 'Katy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442505533,
        AWATER: 4516187,
        INTPTLAT: '+29.8000170',
        INTPTLON: '-095.7677935',
        ELSDLEA: '',
        UNSDLEA: '25170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0048, 30.2337],
            [-95.8043, 30.2456],
            [-95.8033, 30.0901],
            [-95.733, 30.0944],
            [-95.7292, 30.0292],
            [-95.7797, 29.9288],
            [-95.873, 29.9198],
            [-95.958, 29.9318],
            [-95.9924, 29.9601],
            [-96.0339, 30.2041],
            [-96.0048, 30.2337],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844430',
        NAME: 'Waller Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793602287,
        AWATER: 7262724,
        INTPTLAT: '+30.0876851',
        INTPTLON: '-095.9007394',
        ELSDLEA: '',
        UNSDLEA: '44430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2671, 31.6509],
            [-101.2642, 32.0871],
            [-101.139, 32.087],
            [-100.8216, 32.0866],
            [-100.8237, 31.8501],
            [-100.8254, 31.6962],
            [-100.8653, 31.5623],
            [-101.2676, 31.5565],
            [-101.2671, 31.6509],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841550',
        NAME: 'Sterling City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2391739055,
        AWATER: 167451,
        INTPTLAT: '+31.8357739',
        INTPTLON: '-101.0549111',
        ELSDLEA: '',
        UNSDLEA: '41550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3554, 33.5433],
            [-94.3085, 33.5526],
            [-94.2157, 33.5604],
            [-94.2159, 33.4973],
            [-94.2207, 33.453],
            [-94.3253, 33.4463],
            [-94.3554, 33.5433],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823490',
        NAME: 'Hooks Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162924958,
        AWATER: 3958569,
        INTPTLAT: '+33.5016327',
        INTPTLON: '-094.2807549',
        ELSDLEA: '',
        UNSDLEA: '23490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4941, 33.5505],
            [-94.5053, 33.457],
            [-94.5491, 33.4633],
            [-94.4941, 33.5505],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828830',
        NAME: 'Malta Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43098614,
        AWATER: 730451,
        INTPTLAT: '+33.4987129',
        INTPTLON: '-094.5169491',
        ELSDLEA: '',
        UNSDLEA: '28830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3255, 33.425],
            [-94.332, 33.2561],
            [-94.4027, 33.2698],
            [-94.3898, 33.4119],
            [-94.3255, 33.425],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829460',
        NAME: 'Maud Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93155643,
        AWATER: 10961988,
        INTPTLAT: '+33.3346839',
        INTPTLON: '-094.3523609',
        ELSDLEA: '',
        UNSDLEA: '29460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3255, 33.425],
            [-94.3253, 33.4463],
            [-94.2207, 33.453],
            [-94.2034, 33.4593],
            [-94.1769, 33.4513],
            [-94.1636, 33.4125],
            [-94.2101, 33.297],
            [-94.2593, 33.237],
            [-94.332, 33.2561],
            [-94.3255, 33.425],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836750',
        NAME: 'Redwater Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195186241,
        AWATER: 39038574,
        INTPTLAT: '+33.3526917',
        INTPTLON: '-094.2598720',
        ELSDLEA: '',
        UNSDLEA: '36750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2983, 31.3698],
            [-97.1611, 31.329],
            [-97.1608, 31.2199],
            [-97.1867, 31.2046],
            [-97.3296, 31.2518],
            [-97.2983, 31.3698],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811730',
        NAME: 'Bruceville-Eddy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181605937,
        AWATER: 1302574,
        INTPTLAT: '+31.2926954',
        INTPTLON: '-097.2372396',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8331, 31.6351],
            [-96.7507, 31.6145],
            [-96.6407, 31.5214],
            [-96.7347, 31.4255],
            [-96.843, 31.4623],
            [-96.9246, 31.5361],
            [-96.9913, 31.5816],
            [-96.8331, 31.6351],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829190',
        NAME: 'Mart Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406556830,
        AWATER: 2766685,
        INTPTLAT: '+31.5355119',
        INTPTLON: '-096.7893894',
        ELSDLEA: '',
        UNSDLEA: '29190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0451, 31.519],
            [-97.031, 31.5267],
            [-96.9246, 31.5361],
            [-96.843, 31.4623],
            [-97.0176, 31.4109],
            [-97.0529, 31.4948],
            [-97.0451, 31.519],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837110',
        NAME: 'Riesel Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148033164,
        AWATER: 4207755,
        INTPTLAT: '+31.4748189',
        INTPTLON: '-096.9461327',
        ELSDLEA: '',
        UNSDLEA: '37110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1571, 31.4837],
            [-97.0529, 31.4948],
            [-97.0176, 31.4109],
            [-97.0069, 31.4068],
            [-97.1084, 31.3655],
            [-97.1817, 31.4259],
            [-97.1571, 31.4837],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837410',
        NAME: 'Robinson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177470624,
        AWATER: 2408129,
        INTPTLAT: '+31.4383488',
        INTPTLON: '-097.0808634',
        ELSDLEA: '',
        UNSDLEA: '37410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6229, 35.67],
            [-101.4998, 35.7226],
            [-101.5242, 35.6242],
            [-101.6228, 35.6241],
            [-101.6229, 35.67],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839150',
        NAME: 'Sanford-Fritch Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80603279,
        AWATER: 8000339,
        INTPTLAT: '+35.6636772',
        INTPTLON: '-101.5641372',
        ELSDLEA: '',
        UNSDLEA: '39150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1939, 33.4943],
            [-94.1407, 33.4705],
            [-94.1769, 33.4513],
            [-94.2034, 33.4593],
            [-94.1939, 33.4943],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836630',
        NAME: 'Red Lick Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24656544,
        AWATER: 496280,
        INTPTLAT: '+33.4770174',
        INTPTLON: '-094.1701734',
        ELSDLEA: '',
        UNSDLEA: '36630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9693, 32.2176],
            [-96.8489, 32.2044],
            [-96.7552, 32.1438],
            [-96.7589, 32.142],
            [-96.8956, 32.101],
            [-96.9693, 32.2176],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824450',
        NAME: 'Italy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154226907,
        AWATER: 1526758,
        INTPTLAT: '+32.1703988',
        INTPTLON: '-096.8800777',
        ELSDLEA: '',
        UNSDLEA: '24450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0488, 32.2106],
            [-96.9761, 32.2249],
            [-96.9693, 32.2176],
            [-96.8956, 32.101],
            [-96.931, 32.0568],
            [-96.9951, 32.06],
            [-97.0281, 32.0978],
            [-97.0488, 32.2106],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830780',
        NAME: 'Milford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155987324,
        AWATER: 1209851,
        INTPTLAT: '+32.1504645',
        INTPTLON: '-096.9773384',
        ELSDLEA: '',
        UNSDLEA: '30780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2763, 33.6576],
            [-96.2322, 33.6281],
            [-96.2907, 33.5227],
            [-96.3123, 33.55],
            [-96.2763, 33.6576],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818030',
        NAME: 'Ector Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73462388,
        AWATER: 307428,
        INTPTLAT: '+33.5941066',
        INTPTLON: '-096.2760799',
        ELSDLEA: '',
        UNSDLEA: '18030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3826, 33.5595],
            [-96.3796, 33.721],
            [-96.3795, 33.7258],
            [-96.307, 33.7206],
            [-96.2763, 33.6576],
            [-96.3123, 33.55],
            [-96.3826, 33.5595],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839450',
        NAME: 'Savoy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124153171,
        AWATER: 3875255,
        INTPTLAT: '+33.6301790',
        INTPTLON: '-096.3302851',
        ELSDLEA: '',
        UNSDLEA: '39450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9399, 33.6286],
            [-101.9399, 33.6503],
            [-101.8541, 33.6503],
            [-101.7842, 33.6507],
            [-101.7809, 33.5345],
            [-101.9397, 33.5052],
            [-101.9399, 33.6286],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828500',
        NAME: 'Lubbock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223493477,
        AWATER: 2323399,
        INTPTLAT: '+33.5802954',
        INTPTLON: '-101.8630170',
        ELSDLEA: '',
        UNSDLEA: '28500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0864, 33.8824],
            [-101.9937, 33.8828],
            [-101.8709, 33.9633],
            [-101.7433, 33.949],
            [-101.6931, 33.8292],
            [-101.7006, 33.8291],
            [-101.8186, 33.7811],
            [-101.9747, 33.7808],
            [-102.0848, 33.7807],
            [-102.0861, 33.8656],
            [-102.0864, 33.8824],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807410',
        NAME: 'Abernathy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468893201,
        AWATER: 511525,
        INTPTLAT: '+33.8652960',
        INTPTLON: '-101.8929231',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4392, 33.2823],
            [-94.4304, 33.2838],
            [-94.4027, 33.2698],
            [-94.332, 33.2561],
            [-94.2593, 33.237],
            [-94.1991, 33.141],
            [-94.1154, 33.1275],
            [-94.0429, 33.086],
            [-94.043, 33.0192],
            [-94.0431, 32.9654],
            [-94.1464, 32.9232],
            [-94.3368, 33.0865],
            [-94.3476, 33.1598],
            [-94.4875, 33.1431],
            [-94.4392, 33.2823],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808880',
        NAME: 'Atlanta Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629121833,
        AWATER: 23425931,
        INTPTLAT: '+33.1109893',
        INTPTLON: '-094.2438679',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6538, 32.917],
            [-94.4825, 32.9747],
            [-94.4126, 32.8801],
            [-94.654, 32.8795],
            [-94.6538, 32.917],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809090',
        NAME: 'Avinger Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179263101,
        AWATER: 2213739,
        INTPTLAT: '+32.9115435',
        INTPTLON: '-094.5392722',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1154, 33.1275],
            [-94.043, 33.2552],
            [-94.0429, 33.086],
            [-94.1154, 33.1275],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810440',
        NAME: 'Bloomburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84320533,
        AWATER: 190950,
        INTPTLAT: '+33.1636547',
        INTPTLON: '-094.0703240',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.541, 33.1395],
            [-94.4875, 33.1431],
            [-94.3476, 33.1598],
            [-94.3368, 33.0865],
            [-94.1464, 32.9232],
            [-94.1214, 32.8809],
            [-94.4126, 32.8801],
            [-94.4825, 32.9747],
            [-94.5429, 33.0537],
            [-94.541, 33.1395],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827540',
        NAME: 'Linden-Kildare Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 708210800,
        AWATER: 2417036,
        INTPTLAT: '+33.0011889',
        INTPTLON: '-094.3622293',
        ELSDLEA: '',
        UNSDLEA: '27540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8088, 33.3636],
            [-94.7469, 33.3289],
            [-94.6156, 33.2483],
            [-94.4392, 33.2823],
            [-94.4875, 33.1431],
            [-94.541, 33.1395],
            [-94.6535, 33.1067],
            [-94.7667, 33.1108],
            [-94.8141, 33.1528],
            [-94.8088, 33.3636],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834800',
        NAME: 'Pewitt Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579178660,
        AWATER: 5970347,
        INTPTLAT: '+33.2124312',
        INTPTLON: '-094.6584172',
        ELSDLEA: '',
        UNSDLEA: '34800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2593, 33.237],
            [-94.2101, 33.297],
            [-94.0704, 33.3007],
            [-94.043, 33.2712],
            [-94.043, 33.2552],
            [-94.1154, 33.1275],
            [-94.1991, 33.141],
            [-94.2593, 33.237],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836210',
        NAME: 'Queen City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263573785,
        AWATER: 29589009,
        INTPTLAT: '+33.2302852',
        INTPTLON: '-094.1600578',
        ELSDLEA: '',
        UNSDLEA: '36210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8568, 33.5365],
            [-95.7304, 33.6002],
            [-95.5849, 33.6064],
            [-95.5753, 33.5508],
            [-95.6442, 33.4861],
            [-95.7931, 33.4822],
            [-95.822, 33.4689],
            [-95.8568, 33.5365],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838160',
        NAME: 'Roxton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230901734,
        AWATER: 2870742,
        INTPTLAT: '+33.5429754',
        INTPTLON: '-095.7161993',
        ELSDLEA: '',
        UNSDLEA: '38160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6174, 33.6838],
            [-95.4968, 33.6365],
            [-95.5243, 33.6283],
            [-95.6174, 33.6838],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834290',
        NAME: 'Paris Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57952265,
        AWATER: 985281,
        INTPTLAT: '+33.6593769',
        INTPTLON: '-095.5637434',
        ELSDLEA: '',
        UNSDLEA: '34290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2276, 33.638],
            [-102.2262, 33.7195],
            [-102.0833, 33.7184],
            [-102.0818, 33.6503],
            [-102.079, 33.5194],
            [-102.2425, 33.5536],
            [-102.2276, 33.638],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840590',
        NAME: 'Smyer Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273062633,
        AWATER: 35321,
        INTPTLAT: '+33.6180048',
        INTPTLON: '-102.1558405',
        ELSDLEA: '',
        UNSDLEA: '40590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5228, 33.5276],
            [-102.4366, 33.5263],
            [-102.4481, 33.3885],
            [-102.4601, 33.3884],
            [-102.5948, 33.3885],
            [-102.6012, 33.5284],
            [-102.5228, 33.5276],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841850',
        NAME: 'Sundown Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228894369,
        AWATER: 18823,
        INTPTLAT: '+33.4588311',
        INTPTLON: '-102.5173257',
        ELSDLEA: '',
        UNSDLEA: '41850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4373, 33.8251],
            [-102.226, 33.8247],
            [-102.2262, 33.7195],
            [-102.2276, 33.638],
            [-102.32, 33.639],
            [-102.3188, 33.7158],
            [-102.439, 33.7177],
            [-102.4373, 33.8251],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845690',
        NAME: 'Whitharral Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310063027,
        AWATER: 0,
        INTPTLAT: '+33.7484686',
        INTPTLON: '-102.3179530',
        ELSDLEA: '',
        UNSDLEA: '45690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9747, 33.7808],
            [-101.8186, 33.7811],
            [-101.7006, 33.8291],
            [-101.7145, 33.7379],
            [-101.8191, 33.7231],
            [-101.8541, 33.6503],
            [-101.9399, 33.6503],
            [-101.9747, 33.7808],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832430',
        NAME: 'New Deal Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311149482,
        AWATER: 1110129,
        INTPTLAT: '+33.7498682',
        INTPTLON: '-101.8584364',
        ELSDLEA: '',
        UNSDLEA: '32430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4458, 30.9214],
            [-98.4397, 31.0295],
            [-98.2941, 31.0308],
            [-98.2718, 30.9335],
            [-98.1433, 30.9884],
            [-98.1054, 30.9459],
            [-97.9895, 30.9325],
            [-97.8619, 30.9633],
            [-97.8285, 30.9062],
            [-97.9401, 30.7668],
            [-97.9744, 30.7646],
            [-98.0499, 30.6242],
            [-98.0666, 30.5931],
            [-98.243, 30.694],
            [-98.3756, 30.6844],
            [-98.4174, 30.6432],
            [-98.4391, 30.7439],
            [-98.5246, 30.7592],
            [-98.526, 30.8629],
            [-98.4458, 30.9214],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812220',
        NAME: 'Burnet Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1739465803,
        AWATER: 91795268,
        INTPTLAT: '+30.8347524',
        INTPTLON: '-098.2040117',
        ELSDLEA: '',
        UNSDLEA: '12220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.04, 33.2583],
            [-95.957, 33.3637],
            [-95.8891, 33.3633],
            [-95.8495, 33.2261],
            [-95.8909, 33.1674],
            [-96.0206, 33.155],
            [-96.04, 33.2583],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814820',
        NAME: 'Commerce Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296866132,
        AWATER: 1180437,
        INTPTLAT: '+33.2614716',
        INTPTLON: '-095.9406295',
        ELSDLEA: '',
        UNSDLEA: '14820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0051, 33.1603],
            [-96.9963, 33.125],
            [-97.0812, 33.1185],
            [-97.0051, 33.1603],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826430',
        NAME: 'Lake Dallas Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31762684,
        AWATER: 12980681,
        INTPTLAT: '+33.1255557',
        INTPTLON: '-097.0340338',
        ELSDLEA: '',
        UNSDLEA: '26430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3151, 34.4087],
            [-99.2069, 34.3383],
            [-99.2119, 34.3054],
            [-99.1984, 34.2606],
            [-99.3371, 34.2217],
            [-99.392, 34.2554],
            [-99.3151, 34.4087],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833090',
        NAME: 'Northside Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 240258602,
        AWATER: 770724,
        INTPTLAT: '+34.3027023',
        INTPTLON: '-099.2941571',
        ELSDLEA: '',
        UNSDLEA: '33090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3667, 36.1292],
            [-100.2943, 36.2016],
            [-100.2593, 36.3468],
            [-100.2582, 36.4998],
            [-100.0257, 36.4999],
            [-100.0004, 36.2735],
            [-100.276, 36.2453],
            [-100.2765, 36.0996],
            [-100.3668, 36.0998],
            [-100.3667, 36.1292],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819500',
        NAME: 'Follett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 672246771,
        AWATER: 57517,
        INTPTLAT: '+36.3597713',
        INTPTLON: '-100.1572162',
        ELSDLEA: '',
        UNSDLEA: '19500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3668, 36.0998],
            [-100.2765, 36.0996],
            [-100.276, 36.2453],
            [-100.0004, 36.2735],
            [-100.0004, 36.0557],
            [-100.3669, 36.0557],
            [-100.3668, 36.0998],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823140',
        NAME: 'Higgins Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602078766,
        AWATER: 133308,
        INTPTLAT: '+36.1522547',
        INTPTLON: '-100.1460222',
        ELSDLEA: '',
        UNSDLEA: '23140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2773, 31.7455],
            [-97.2318, 31.7679],
            [-97.1843, 31.6853],
            [-97.1684, 31.6646],
            [-97.1883, 31.677],
            [-97.2773, 31.7455],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820610',
        NAME: 'Gholson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51289821,
        AWATER: 921037,
        INTPTLAT: '+31.7187858',
        INTPTLON: '-097.2522198',
        ELSDLEA: '',
        UNSDLEA: '20610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5199, 27.6953],
            [-97.4681, 27.7086],
            [-97.3982, 27.6376],
            [-97.405, 27.56],
            [-97.5734, 27.5603],
            [-97.5861, 27.6361],
            [-97.5199, 27.6953],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827990',
        NAME: 'London Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224856574,
        AWATER: 607906,
        INTPTLAT: '+27.6191979',
        INTPTLON: '-097.4785792',
        ELSDLEA: '',
        UNSDLEA: '27990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3109, 33.7891],
            [-95.3105, 33.8772],
            [-95.2258, 33.9621],
            [-95.1559, 33.9366],
            [-95.0956, 33.8881],
            [-95.1545, 33.8509],
            [-95.183, 33.704],
            [-95.1479, 33.6601],
            [-95.186, 33.5788],
            [-95.2448, 33.5486],
            [-95.3104, 33.5511],
            [-95.3109, 33.7891],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816890',
        NAME: 'Detroit Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573840734,
        AWATER: 3480220,
        INTPTLAT: '+33.7810121',
        INTPTLON: '-095.2427279',
        ELSDLEA: '',
        UNSDLEA: '16890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7292, 30.0292],
            [-95.5701, 29.9858],
            [-95.4959, 29.8911],
            [-95.491, 29.8506],
            [-95.4911, 29.8236],
            [-95.5968, 29.8315],
            [-95.7388, 29.8313],
            [-95.7797, 29.9288],
            [-95.7292, 30.0292],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816110',
        NAME: 'Cypress-Fairbanks Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 483718551,
        AWATER: 4335810,
        INTPTLAT: '+29.9232777',
        INTPTLON: '-095.6403904',
        ELSDLEA: '',
        UNSDLEA: '16110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4992, 28.0575],
            [-98.3343, 28.0578],
            [-98.2355, 28.058],
            [-98.2351, 27.9289],
            [-98.1605, 27.9176],
            [-98.2337, 27.6451],
            [-98.2332, 27.5009],
            [-98.3575, 27.4928],
            [-98.3309, 27.6223],
            [-98.445, 27.6615],
            [-98.4681, 27.7478],
            [-98.4532, 27.9217],
            [-98.493, 27.9467],
            [-98.4992, 28.0575],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838820',
        NAME: 'San Diego Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1341007254,
        AWATER: 2885418,
        INTPTLAT: '+27.8213893',
        INTPTLON: '-098.3413604',
        ELSDLEA: '',
        UNSDLEA: '38820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8031, 28.0126],
            [-98.7847, 27.6746],
            [-98.6651, 27.7899],
            [-98.524, 27.79],
            [-98.4681, 27.7478],
            [-98.445, 27.6615],
            [-98.3309, 27.6223],
            [-98.3575, 27.4928],
            [-98.3012, 27.4227],
            [-98.3103, 27.3402],
            [-98.3775, 27.4072],
            [-98.4773, 27.4084],
            [-98.5223, 27.3433],
            [-98.5526, 27.3591],
            [-98.7983, 27.354],
            [-98.8022, 27.7752],
            [-98.8031, 28.0126],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4899130',
        NAME: 'Benavides Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1906350390,
        AWATER: 474379,
        INTPTLAT: '+27.5643686',
        INTPTLON: '-098.5899837',
        ELSDLEA: '',
        UNSDLEA: '99130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5968, 29.8315],
            [-95.4911, 29.8236],
            [-95.5294, 29.7458],
            [-95.62, 29.7706],
            [-95.5968, 29.8315],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841100',
        NAME: 'Spring Branch Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106476834,
        AWATER: 269983,
        INTPTLAT: '+29.7920517',
        INTPTLON: '-095.5358874',
        ELSDLEA: '',
        UNSDLEA: '41100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4625, 30.1053],
            [-95.4242, 30.1155],
            [-95.311, 30.0329],
            [-95.4274, 30.0357],
            [-95.4625, 30.1053],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841220',
        NAME: 'Spring Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143118500,
        AWATER: 1434246,
        INTPTLAT: '+30.0293756',
        INTPTLON: '-095.4218639',
        ELSDLEA: '',
        UNSDLEA: '41220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1245, 30.1445],
            [-95.0967, 30.1672],
            [-95.0726, 30.1031],
            [-95.0352, 29.9926],
            [-95.1347, 29.9798],
            [-95.1551, 30.0832],
            [-95.1245, 30.1445],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823820',
        NAME: 'Huffman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125423642,
        AWATER: 11274627,
        INTPTLAT: '+30.0591301',
        INTPTLON: '-095.0994056',
        ELSDLEA: '',
        UNSDLEA: '23820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2304, 29.8258],
            [-95.1568, 29.8223],
            [-95.093, 29.7531],
            [-95.1932, 29.7422],
            [-95.2364, 29.7215],
            [-95.2304, 29.8258],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820250',
        NAME: 'Galena Park Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81210115,
        AWATER: 6354200,
        INTPTLAT: '+29.7688475',
        INTPTLON: '-095.1860508',
        ELSDLEA: '',
        UNSDLEA: '20250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.998, 32.0023],
            [-105.4733, 32.0007],
            [-105.4732, 31.7495],
            [-105.4916, 31.5469],
            [-105.4985, 31.14],
            [-105.4911, 30.9956],
            [-105.5215, 30.9753],
            [-105.6488, 31.1159],
            [-105.774, 31.1685],
            [-105.8693, 31.289],
            [-105.9972, 31.3872],
            [-105.9934, 31.5638],
            [-105.998, 32.0023],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820130',
        NAME: 'Fort Hancock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4427856770,
        AWATER: 1616986,
        INTPTLAT: '+31.6207477',
        INTPTLON: '-105.7000063',
        ELSDLEA: '',
        UNSDLEA: '20130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4733, 32.0007],
            [-104.9183, 32.0005],
            [-104.9145, 31.6618],
            [-104.9678, 31.7052],
            [-105.1548, 31.7053],
            [-105.1546, 31.7483],
            [-105.4732, 31.7495],
            [-105.4733, 32.0007],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816650',
        NAME: 'Dell City Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1599646034,
        AWATER: 57072,
        INTPTLAT: '+31.8693961',
        INTPTLON: '-105.1789960',
        ELSDLEA: '',
        UNSDLEA: '16650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9529, 33.7852],
            [-98.839, 33.8341],
            [-98.697, 33.8347],
            [-98.6375, 33.8973],
            [-98.6376, 33.835],
            [-98.5191, 33.8357],
            [-98.6436, 33.7388],
            [-98.6427, 33.6757],
            [-98.7577, 33.6727],
            [-98.8803, 33.6318],
            [-98.9534, 33.6271],
            [-98.9529, 33.7852],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823400',
        NAME: 'Holliday Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612827475,
        AWATER: 14628575,
        INTPTLAT: '+33.7550526',
        INTPTLON: '-098.7639716',
        ELSDLEA: '',
        UNSDLEA: '23400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7815, 32.1499],
            [-98.7566, 32.2624],
            [-98.6591, 32.3571],
            [-98.5938, 32.3448],
            [-98.5965, 32.1572],
            [-98.7816, 32.1459],
            [-98.7815, 32.1499],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821270',
        NAME: 'Gorman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309553571,
        AWATER: 2186896,
        INTPTLAT: '+32.2339782',
        INTPTLON: '-098.6658027',
        ELSDLEA: '',
        UNSDLEA: '21270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.937, 32.515],
            [-98.7955, 32.5152],
            [-98.7359, 32.4068],
            [-98.6591, 32.3571],
            [-98.7566, 32.2624],
            [-98.7815, 32.1499],
            [-98.9051, 32.1997],
            [-98.9378, 32.4117],
            [-98.937, 32.515],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817960',
        NAME: 'Eastland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660902385,
        AWATER: 7665456,
        INTPTLAT: '+32.3572158',
        INTPTLON: '-098.8202123',
        ELSDLEA: '',
        UNSDLEA: '17960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4957, 32.3481],
            [-98.3786, 32.3534],
            [-98.2759, 32.275],
            [-98.3327, 32.2101],
            [-98.4777, 32.1814],
            [-98.5269, 32.3203],
            [-98.4957, 32.3481],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827600',
        NAME: 'Lingleville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314432245,
        AWATER: 1458551,
        INTPTLAT: '+32.2855998',
        INTPTLON: '-098.4102543',
        ELSDLEA: '',
        UNSDLEA: '27600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0631, 32.2108],
            [-98.9518, 32.2342],
            [-98.9051, 32.1997],
            [-98.7815, 32.1499],
            [-98.7816, 32.1459],
            [-98.847, 32.0665],
            [-98.8501, 32.0665],
            [-99.0546, 32.0791],
            [-99.0631, 32.2108],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837230',
        NAME: 'Rising Star Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327960356,
        AWATER: 472321,
        INTPTLAT: '+32.1484012',
        INTPTLON: '-098.9405681',
        ELSDLEA: '',
        UNSDLEA: '37230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0433, 34.6505],
            [-102.9711, 34.7064],
            [-102.8633, 34.7072],
            [-102.8647, 34.6084],
            [-102.7628, 34.5687],
            [-102.7633, 34.4821],
            [-102.816, 34.4482],
            [-102.9554, 34.4503],
            [-103.0434, 34.5251],
            [-103.0433, 34.6505],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810950',
        NAME: 'Bovina Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552630693,
        AWATER: 2766055,
        INTPTLAT: '+34.5619101',
        INTPTLON: '-102.9032876',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.718, 34.7481],
            [-100.5407, 34.7477],
            [-100.5404, 34.8061],
            [-100.4698, 34.7476],
            [-100.2749, 34.7471],
            [-100.2751, 34.7111],
            [-100.35, 34.6208],
            [-100.4159, 34.621],
            [-100.4168, 34.4246],
            [-100.5008, 34.3776],
            [-100.5034, 34.3141],
            [-100.5173, 34.3141],
            [-100.6827, 34.3134],
            [-100.6361, 34.3989],
            [-100.6534, 34.5319],
            [-100.8067, 34.5637],
            [-100.9452, 34.6285],
            [-100.9449, 34.7483],
            [-100.718, 34.7481],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830180',
        NAME: 'Memphis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1684740886,
        AWATER: 46344210,
        INTPTLAT: '+34.5941297',
        INTPTLON: '-100.6121713',
        ELSDLEA: '',
        UNSDLEA: '30180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0423, 35.3949],
            [-102.6763, 35.5191],
            [-102.7555, 35.4539],
            [-102.7573, 35.3405],
            [-102.6598, 35.3401],
            [-102.5816, 35.2928],
            [-102.5436, 35.1015],
            [-102.544, 35.0577],
            [-102.6577, 35.0472],
            [-103.0428, 35.0676],
            [-103.0423, 35.3949],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807500',
        NAME: 'Adrian Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1718701447,
        AWATER: 544351,
        INTPTLAT: '+35.2428753',
        INTPTLON: '-102.8066786',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0412, 33.8336],
            [-100.957, 33.834],
            [-100.935, 33.6976],
            [-100.8621, 33.6226],
            [-100.762, 33.5721],
            [-100.5179, 33.5706],
            [-100.5174, 33.3979],
            [-100.9699, 33.3974],
            [-101.0389, 33.3651],
            [-101.0412, 33.8336],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800002',
        NAME: 'Spur Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1365553468,
        AWATER: 7813131,
        INTPTLAT: '+33.5380165',
        INTPTLON: '-100.8382818',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.957, 33.834],
            [-100.5187, 33.8357],
            [-100.5179, 33.5706],
            [-100.762, 33.5721],
            [-100.8621, 33.6226],
            [-100.935, 33.6976],
            [-100.957, 33.834],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834380',
        NAME: 'Patton Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1008796024,
        AWATER: 1245756,
        INTPTLAT: '+33.6933510',
        INTPTLON: '-100.7093249',
        ELSDLEA: '',
        UNSDLEA: '34380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4448, 29.3162],
            [-98.3607, 29.4461],
            [-98.2695, 29.4971],
            [-98.1613, 29.4777],
            [-98.1211, 29.449],
            [-98.2493, 29.3039],
            [-98.3272, 29.2099],
            [-98.4222, 29.2533],
            [-98.4448, 29.3162],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817850',
        NAME: 'East Central Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493309486,
        AWATER: 23775840,
        INTPTLAT: '+29.3636613',
        INTPTLON: '-098.3043514',
        ELSDLEA: '',
        UNSDLEA: '17850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4355, 29.4497],
            [-98.4106, 29.4783],
            [-98.3477, 29.5064],
            [-98.3471, 29.5763],
            [-98.4201, 29.6771],
            [-98.3109, 29.5945],
            [-98.2989, 29.5307],
            [-98.2669, 29.5178],
            [-98.2695, 29.4971],
            [-98.3607, 29.4461],
            [-98.4355, 29.4497],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824990',
        NAME: 'Judson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143350718,
        AWATER: 1260373,
        INTPTLAT: '+29.4932092',
        INTPTLON: '-098.3074043',
        ELSDLEA: '',
        UNSDLEA: '24990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2989, 29.5307],
            [-98.2765, 29.5492],
            [-98.2669, 29.5178],
            [-98.2989, 29.5307],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836450',
        NAME: 'Randolph Field Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10866669,
        AWATER: 104796,
        INTPTLAT: '+29.5305351',
        INTPTLON: '-098.2773912',
        ELSDLEA: '',
        UNSDLEA: '36450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7102, 29.4071],
            [-98.6698, 29.3893],
            [-98.6901, 29.3836],
            [-98.6737, 29.3466],
            [-98.6436, 29.3681],
            [-98.63, 29.3736],
            [-98.6269, 29.3675],
            [-98.5267, 29.321],
            [-98.5183, 29.321],
            [-98.5263, 29.2511],
            [-98.6503, 29.28],
            [-98.735, 29.2267],
            [-98.8048, 29.2507],
            [-98.8046, 29.3233],
            [-98.7178, 29.3433],
            [-98.7102, 29.4071],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840950',
        NAME: 'Southwest Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278351928,
        AWATER: 3856187,
        INTPTLAT: '+29.3033884',
        INTPTLON: '-098.6779578',
        ELSDLEA: '',
        UNSDLEA: '40950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7257, 29.0283],
            [-97.5502, 28.977],
            [-97.5152, 28.8561],
            [-97.6067, 28.7905],
            [-97.6126, 28.8543],
            [-97.7405, 28.9914],
            [-97.7257, 29.0283],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832880',
        NAME: 'Nordheim Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273199524,
        AWATER: 619822,
        INTPTLAT: '+28.9248777',
        INTPTLON: '-097.6132994',
        ELSDLEA: '',
        UNSDLEA: '32880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7257, 29.0283],
            [-97.6131, 29.1098],
            [-97.5565, 29.1523],
            [-97.4075, 29.0538],
            [-97.3597, 28.9655],
            [-97.3554, 28.8976],
            [-97.4173, 28.9252],
            [-97.5152, 28.8561],
            [-97.5502, 28.977],
            [-97.7257, 29.0283],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846650',
        NAME: 'Yorktown Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540858445,
        AWATER: 262950,
        INTPTLAT: '+29.0241088',
        INTPTLON: '-097.5279237',
        ELSDLEA: '',
        UNSDLEA: '46650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5565, 29.1523],
            [-97.4506, 29.2308],
            [-97.3296, 29.0895],
            [-97.4075, 29.0538],
            [-97.5565, 29.1523],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845240',
        NAME: 'Westhoff Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199565080,
        AWATER: 309994,
        INTPTLAT: '+29.1429435',
        INTPTLON: '-097.4393108',
        ELSDLEA: '',
        UNSDLEA: '45240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3337, 29.3162],
            [-97.2887, 29.4073],
            [-97.2027, 29.3456],
            [-97.0949, 29.3754],
            [-97.1083, 29.3012],
            [-96.9986, 29.2423],
            [-96.9764, 29.104],
            [-97.049, 29.0528],
            [-97.14, 29.1227],
            [-97.2243, 29.113],
            [-97.2101, 29.2132],
            [-97.3337, 29.3162],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846620',
        NAME: 'Yoakum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 674380616,
        AWATER: 1565439,
        INTPTLAT: '+29.2214752',
        INTPTLON: '-097.1599977',
        ELSDLEA: '',
        UNSDLEA: '46620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.026, 29.2655],
            [-98.9254, 29.246],
            [-98.8854, 29.1513],
            [-98.8048, 29.1475],
            [-98.8047, 29.0343],
            [-98.8043, 28.9845],
            [-98.9405, 28.9802],
            [-98.9132, 29.0906],
            [-99.0777, 29.0909],
            [-99.026, 29.2655],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816950',
        NAME: 'Devine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 511003604,
        AWATER: 1235150,
        INTPTLAT: '+29.1287850',
        INTPTLON: '-098.9336593',
        ELSDLEA: '',
        UNSDLEA: '16950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0434, 34.5251],
            [-102.9554, 34.4503],
            [-102.816, 34.4482],
            [-102.8171, 34.3128],
            [-102.9058, 34.3127],
            [-103.0438, 34.2144],
            [-103.0434, 34.5251],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819110',
        NAME: 'Farwell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427029013,
        AWATER: 1504804,
        INTPTLAT: '+34.3668811',
        INTPTLON: '-102.9540351',
        ELSDLEA: '',
        UNSDLEA: '19110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4752, 32.513],
            [-98.4473, 32.513],
            [-98.3831, 32.4603],
            [-98.2868, 32.4711],
            [-98.2462, 32.3657],
            [-98.1969, 32.3468],
            [-98.2759, 32.275],
            [-98.3786, 32.3534],
            [-98.4957, 32.3481],
            [-98.5086, 32.3788],
            [-98.4752, 32.513],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823760',
        NAME: 'Huckabay Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405500585,
        AWATER: 2434086,
        INTPTLAT: '+32.3943122',
        INTPTLON: '-098.3636543',
        ELSDLEA: '',
        UNSDLEA: '23760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4777, 32.1814],
            [-98.3327, 32.2101],
            [-98.1516, 32.0476],
            [-98.1463, 31.9685],
            [-98.2531, 31.8901],
            [-98.2739, 31.8887],
            [-98.3758, 31.9403],
            [-98.3927, 32.0363],
            [-98.4739, 32.0145],
            [-98.436, 32.0663],
            [-98.4777, 32.1814],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817580',
        NAME: 'Dublin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651742948,
        AWATER: 4082175,
        INTPTLAT: '+32.0350275',
        INTPTLON: '-098.3144756',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2868, 32.4711],
            [-98.2327, 32.5126],
            [-98.1339, 32.4559],
            [-98.1152, 32.4394],
            [-98.1061, 32.3493],
            [-98.1969, 32.3468],
            [-98.2462, 32.3657],
            [-98.2868, 32.4711],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831410',
        NAME: 'Morgan Mill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191735255,
        AWATER: 765241,
        INTPTLAT: '+32.4194819',
        INTPTLON: '-098.1993588',
        ELSDLEA: '',
        UNSDLEA: '31410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.999, 31.8411],
            [-98.8827, 31.8359],
            [-98.8227, 31.7461],
            [-98.8773, 31.6423],
            [-98.9751, 31.7337],
            [-98.999, 31.8411],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817790',
        NAME: 'Early Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217488092,
        AWATER: 433250,
        INTPTLAT: '+31.7583970',
        INTPTLON: '-098.9069068',
        ELSDLEA: '',
        UNSDLEA: '17790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8015, 31.8976],
            [-98.7142, 31.8981],
            [-98.7412, 31.8081],
            [-98.7017, 31.75],
            [-98.8227, 31.7461],
            [-98.8827, 31.8359],
            [-98.8015, 31.8976],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810380',
        NAME: 'Blanket Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250426455,
        AWATER: 653784,
        INTPTLAT: '+31.8295448',
        INTPTLON: '-098.7939468',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0701, 31.6311],
            [-99.0625, 31.8223],
            [-99.0028, 31.8391],
            [-98.999, 31.8411],
            [-98.9751, 31.7337],
            [-98.8773, 31.6423],
            [-98.8227, 31.5849],
            [-98.9429, 31.5121],
            [-98.9915, 31.6333],
            [-99.0701, 31.6311],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811700',
        NAME: 'Brownwood Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361303028,
        AWATER: 1106545,
        INTPTLAT: '+31.6699645',
        INTPTLON: '-098.9713970',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0744, 28.7618],
            [-97.959, 28.8045],
            [-97.7914, 28.9045],
            [-97.7367, 28.8484],
            [-97.761, 28.7584],
            [-97.8647, 28.6877],
            [-97.937, 28.7662],
            [-98.0527, 28.7391],
            [-98.0744, 28.7618],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825440',
        NAME: 'Kenedy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344629852,
        AWATER: 2168319,
        INTPTLAT: '+28.7779417',
        INTPTLON: '-097.8717545',
        ELSDLEA: '',
        UNSDLEA: '25440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2463, 30.168],
            [-94.1159, 30.1603],
            [-94.0281, 30.0663],
            [-93.9991, 30.0257],
            [-94.0491, 29.9505],
            [-94.0464, 29.8804],
            [-94.2338, 30.0011],
            [-94.2463, 30.168],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809670',
        NAME: 'Beaumont Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442806093,
        AWATER: 23226493,
        INTPTLAT: '+30.0506384',
        INTPTLON: '-094.1452740',
        ELSDLEA: '',
        UNSDLEA: '09670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0491, 29.9505],
            [-93.9696, 29.9515],
            [-93.8399, 29.9197],
            [-93.8544, 29.8651],
            [-93.9269, 29.791],
            [-94.0337, 29.8821],
            [-94.0464, 29.8804],
            [-94.0491, 29.9505],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835400',
        NAME: 'Port Arthur Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136634375,
        AWATER: 75176533,
        INTPTLAT: '+29.8894718',
        INTPTLON: '-093.9461506',
        ELSDLEA: '',
        UNSDLEA: '35400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1253, 28.1163],
            [-97.0236, 28.1877],
            [-97.0834, 28.2782],
            [-96.7905, 28.3192],
            [-96.8309, 28.108],
            [-96.8159, 28.0228],
            [-96.9595, 27.8753],
            [-96.9843, 27.8038],
            [-97.1036, 27.9466],
            [-97.1872, 27.9745],
            [-97.2596, 28.0736],
            [-97.2608, 28.0758],
            [-97.1253, 28.1163],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808550',
        NAME: 'Aransas County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605686646,
        AWATER: 692962481,
        INTPTLAT: '+28.1042254',
        INTPTLON: '-096.9779830',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9254, 29.246],
            [-98.8237, 29.2504],
            [-98.8048, 29.1492],
            [-98.8048, 29.1475],
            [-98.8854, 29.1513],
            [-98.9254, 29.246],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832130',
        NAME: 'Natalia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105673346,
        AWATER: 860480,
        INTPTLAT: '+29.2011232',
        INTPTLON: '-098.8681031',
        ELSDLEA: '',
        UNSDLEA: '32130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8028, 29.6948],
            [-98.6571, 29.7],
            [-98.5405, 29.6723],
            [-98.5605, 29.503],
            [-98.5831, 29.4507],
            [-98.6072, 29.4029],
            [-98.63, 29.3736],
            [-98.6436, 29.3681],
            [-98.6698, 29.3893],
            [-98.7102, 29.4071],
            [-98.8049, 29.4039],
            [-98.8058, 29.5809],
            [-98.9129, 29.5774],
            [-98.8028, 29.6948],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833120',
        NAME: 'Northside Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 820750089,
        AWATER: 2241391,
        INTPTLAT: '+29.5609369',
        INTPTLON: '-098.6919755',
        ELSDLEA: '',
        UNSDLEA: '33120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0099, 29.9645],
            [-97.9076, 29.9607],
            [-97.8492, 29.916],
            [-97.7832, 29.8087],
            [-97.8002, 29.7659],
            [-97.8913, 29.7461],
            [-97.9993, 29.7524],
            [-98.0305, 29.8485],
            [-98.1516, 29.9343],
            [-98.0099, 29.9645],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838970',
        NAME: 'San Marcos Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475619361,
        AWATER: 2975205,
        INTPTLAT: '+29.8571568',
        INTPTLON: '-097.9362656',
        ELSDLEA: '',
        UNSDLEA: '38970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4627, 29.7481],
            [-97.4433, 29.8054],
            [-97.3577, 29.8218],
            [-97.3158, 29.7865],
            [-97.1305, 29.6433],
            [-97.2293, 29.6414],
            [-97.26, 29.5645],
            [-97.3589, 29.56],
            [-97.4001, 29.6873],
            [-97.4627, 29.7481],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844310',
        NAME: 'Waelder Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466678991,
        AWATER: 478982,
        INTPTLAT: '+29.6884565',
        INTPTLON: '-097.3419916',
        ELSDLEA: '',
        UNSDLEA: '44310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9975, 34.5606],
            [-99.8736, 34.5359],
            [-99.6945, 34.3782],
            [-99.5866, 34.3869],
            [-99.5517, 34.1307],
            [-99.6089, 34.0831],
            [-99.772, 34.0933],
            [-100.0253, 34.2389],
            [-99.9977, 34.3118],
            [-99.9975, 34.5606],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836180',
        NAME: 'Quanah Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1446624809,
        AWATER: 4128806,
        INTPTLAT: '+34.2934810',
        INTPTLON: '-099.7989104',
        ELSDLEA: '',
        UNSDLEA: '36180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.24, 28.9997],
            [-96.3092, 28.9633],
            [-96.5412, 29.1632],
            [-96.3165, 29.2246],
            [-96.2592, 29.1146],
            [-96.24, 28.9997],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828350',
        NAME: 'Louise Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445113607,
        AWATER: 1283189,
        INTPTLAT: '+29.1254385',
        INTPTLON: '-096.3642665',
        ELSDLEA: '',
        UNSDLEA: '28350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5252, 34.6401],
            [-102.4081, 34.6458],
            [-102.3709, 34.746],
            [-102.1688, 34.7474],
            [-102.1205, 34.7477],
            [-102.0857, 34.657],
            [-102.1396, 34.6577],
            [-102.2135, 34.5255],
            [-102.1784, 34.4958],
            [-102.2511, 34.4234],
            [-102.2606, 34.3403],
            [-102.4805, 34.3983],
            [-102.5258, 34.4914],
            [-102.5252, 34.6401],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817160',
        NAME: 'Dimmitt Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1151375988,
        AWATER: 7173883,
        INTPTLAT: '+34.5786596',
        INTPTLON: '-102.3364794',
        ELSDLEA: '',
        UNSDLEA: '17160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0713, 33.8361],
            [-100.0485, 33.836],
            [-100.047, 34.2298],
            [-100.0253, 34.2389],
            [-99.772, 34.0933],
            [-99.6089, 34.0831],
            [-99.5044, 34.0763],
            [-99.4754, 34.0029],
            [-99.4757, 33.834],
            [-99.4745, 33.7338],
            [-99.542, 33.7932],
            [-99.6948, 33.8272],
            [-99.7532, 33.8071],
            [-99.8319, 33.6733],
            [-99.9943, 33.6635],
            [-100.0718, 33.7139],
            [-100.0713, 33.8361],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815870',
        NAME: 'Crowell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2283041448,
        AWATER: 18133816,
        INTPTLAT: '+33.9249877',
        INTPTLON: '-099.8053878',
        ELSDLEA: '',
        UNSDLEA: '15870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.318, 31.6513],
            [-102.2873, 31.6513],
            [-101.7758, 31.6513],
            [-101.7762, 31.0798],
            [-102.0482, 31.0832],
            [-102.0473, 31.3414],
            [-102.0977, 31.4964],
            [-102.265, 31.4568],
            [-102.3158, 31.4909],
            [-102.318, 31.6513],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836510',
        NAME: 'Rankin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2156170280,
        AWATER: 464244,
        INTPTLAT: '+31.4061880',
        INTPTLON: '-101.9714567',
        ELSDLEA: '',
        UNSDLEA: '36510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.028, 34.2053],
            [-98.9524, 34.2125],
            [-98.9531, 33.834],
            [-99.059, 33.8341],
            [-99.1451, 34.0372],
            [-99.0352, 34.1037],
            [-99.028, 34.2053],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822620',
        NAME: 'Harrold Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 522684285,
        AWATER: 4079961,
        INTPTLAT: '+34.0012921',
        INTPTLON: '-099.0321793',
        ELSDLEA: '',
        UNSDLEA: '22620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0389, 33.3651],
            [-100.9699, 33.3974],
            [-100.5174, 33.3979],
            [-100.5178, 33.0742],
            [-100.6033, 33.0588],
            [-100.6559, 32.9635],
            [-100.8849, 32.9646],
            [-100.9233, 33.0388],
            [-101.0386, 33.0559],
            [-101.0389, 33.3057],
            [-101.0389, 33.3651],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824660',
        NAME: 'Jayton-Girard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2063436693,
        AWATER: 778502,
        INTPTLAT: '+33.1958583',
        INTPTLON: '-100.7744163',
        ELSDLEA: '',
        UNSDLEA: '24660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1282, 30.7465],
            [-94.0833, 30.5936],
            [-94.2289, 30.6045],
            [-94.2938, 30.6708],
            [-94.2589, 30.7725],
            [-94.1282, 30.7465],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841340',
        NAME: 'Spurger Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282627225,
        AWATER: 1628870,
        INTPTLAT: '+30.6546749',
        INTPTLON: '-094.1843688',
        ELSDLEA: '',
        UNSDLEA: '41340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0428, 34.7474],
            [-102.8486, 34.7475],
            [-102.7614, 34.8102],
            [-102.7607, 34.7475],
            [-102.6121, 34.7472],
            [-102.5252, 34.6401],
            [-102.5258, 34.4914],
            [-102.7633, 34.4821],
            [-102.7628, 34.5687],
            [-102.8647, 34.6084],
            [-102.8633, 34.7072],
            [-102.9711, 34.7064],
            [-103.0433, 34.6505],
            [-103.0428, 34.7474],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819980',
        NAME: 'Friona Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 895513277,
        AWATER: 4515476,
        INTPTLAT: '+34.6390071',
        INTPTLON: '-102.7216611',
        ELSDLEA: '',
        UNSDLEA: '19980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9178, 36.0552],
            [-102.771, 36.0552],
            [-102.77, 36.1728],
            [-102.8413, 36.1729],
            [-102.8403, 36.3346],
            [-102.7713, 36.3693],
            [-102.3678, 36.3856],
            [-102.3686, 36.2258],
            [-102.251, 36.0552],
            [-102.3709, 35.9493],
            [-102.6203, 35.9352],
            [-102.6197, 35.8768],
            [-102.727, 35.8765],
            [-102.7274, 35.9868],
            [-102.8745, 35.9866],
            [-102.9178, 36.0552],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816200',
        NAME: 'Dalhart Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2179282993,
        AWATER: 2895137,
        INTPTLAT: '+36.1191110',
        INTPTLON: '-102.5481854',
        ELSDLEA: '',
        UNSDLEA: '16200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9636, 30.5572],
            [-96.8729, 30.6034],
            [-96.7359, 30.6724],
            [-96.6213, 30.7308],
            [-96.567, 30.6957],
            [-96.4474, 30.5872],
            [-96.5215, 30.5657],
            [-96.5892, 30.4777],
            [-96.5714, 30.428],
            [-96.6595, 30.4394],
            [-96.7515, 30.3297],
            [-96.8456, 30.4275],
            [-96.9636, 30.5572],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812460',
        NAME: 'Caldwell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 993398937,
        AWATER: 12974729,
        INTPTLAT: '+30.5415089',
        INTPTLON: '-096.7111824',
        ELSDLEA: '',
        UNSDLEA: '12460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0151, 31.0149],
            [-98.9806, 31.0438],
            [-98.8481, 31.0149],
            [-98.7598, 31.0806],
            [-98.6576, 31.0799],
            [-98.5177, 30.922],
            [-98.9646, 30.9214],
            [-99.0151, 31.0149],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813740',
        NAME: 'Cherokee Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623762579,
        AWATER: 173424,
        INTPTLAT: '+30.9822613',
        INTPTLON: '-098.7793286',
        ELSDLEA: '',
        UNSDLEA: '13740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0918, 31.1436],
            [-99.0906, 31.4609],
            [-99.0376, 31.4895],
            [-98.9427, 31.4586],
            [-98.8345, 31.4104],
            [-98.8624, 31.3922],
            [-98.8572, 31.2062],
            [-98.9817, 31.1323],
            [-98.9806, 31.0438],
            [-99.0151, 31.0149],
            [-99.0921, 31.0259],
            [-99.0918, 31.1436],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837080',
        NAME: 'Richland Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 895071708,
        AWATER: 3041633,
        INTPTLAT: '+31.2702609',
        INTPTLON: '-098.9857804',
        ELSDLEA: '',
        UNSDLEA: '37080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8345, 31.4104],
            [-98.8061, 31.4065],
            [-98.6966, 31.4073],
            [-98.5798, 31.2654],
            [-98.5291, 31.1537],
            [-98.5235, 31.0889],
            [-98.4397, 31.0295],
            [-98.4458, 30.9214],
            [-98.5177, 30.922],
            [-98.6576, 31.0799],
            [-98.7598, 31.0806],
            [-98.8481, 31.0149],
            [-98.9806, 31.0438],
            [-98.9817, 31.1323],
            [-98.8572, 31.2062],
            [-98.8624, 31.3922],
            [-98.8345, 31.4104],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839030',
        NAME: 'San Saba Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1344216395,
        AWATER: 4856748,
        INTPTLAT: '+31.1377161',
        INTPTLON: '-098.7102465',
        ELSDLEA: '',
        UNSDLEA: '39030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.136, 27.8304],
            [-97.1036, 27.9466],
            [-96.9843, 27.8038],
            [-97.0502, 27.7503],
            [-97.1323, 27.6278],
            [-97.2024, 27.6622],
            [-97.136, 27.8304],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835370',
        NAME: 'Port Aransas Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63501577,
        AWATER: 220460419,
        INTPTLAT: '+27.7350198',
        INTPTLON: '-097.1314381',
        ELSDLEA: '',
        UNSDLEA: '35370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9402, 27.86],
            [-97.921, 27.8961],
            [-97.8471, 27.8035],
            [-97.8609, 27.7325],
            [-97.9383, 27.6958],
            [-98.0174, 27.7601],
            [-97.9402, 27.86],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807530',
        NAME: 'Agua Dulce Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253025163,
        AWATER: 79623,
        INTPTLAT: '+27.7783394',
        INTPTLON: '-097.9255475',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9421, 27.6359],
            [-97.9401, 27.6708],
            [-97.7471, 27.6954],
            [-97.5949, 27.702],
            [-97.5199, 27.6953],
            [-97.5861, 27.6361],
            [-97.5734, 27.5603],
            [-97.8407, 27.5584],
            [-97.9421, 27.6359],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810260',
        NAME: 'Bishop Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 438075872,
        AWATER: 1038122,
        INTPTLAT: '+27.6647933',
        INTPTLON: '-097.6233448',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6195, 27.8079],
            [-97.5759, 27.8608],
            [-97.4776, 27.8606],
            [-97.4764, 27.861],
            [-97.487, 27.7854],
            [-97.6193, 27.755],
            [-97.6195, 27.8079],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843350',
        NAME: 'Tuloso-Midway Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113468026,
        AWATER: 15663612,
        INTPTLAT: '+27.7972108',
        INTPTLON: '-097.5417255',
        ELSDLEA: '',
        UNSDLEA: '43350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8008, 28.6473],
            [-98.6108, 28.6483],
            [-98.3922, 28.6482],
            [-98.335, 28.6127],
            [-98.3343, 28.1615],
            [-98.3343, 28.0578],
            [-98.4992, 28.0575],
            [-98.8033, 28.0575],
            [-98.8008, 28.6473],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829940',
        NAME: 'McMullen County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2952084694,
        AWATER: 43985815,
        INTPTLAT: '+28.3849220',
        INTPTLON: '-098.5788529',
        ELSDLEA: '',
        UNSDLEA: '29940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7801, 32.2892],
            [-95.72, 32.305],
            [-95.7406, 32.2511],
            [-95.7801, 32.2892],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832010',
        NAME: 'Murchison Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31904615,
        AWATER: 229162,
        INTPTLAT: '+32.2826541',
        INTPTLON: '-095.7494860',
        ELSDLEA: '',
        UNSDLEA: '32010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7773, 32.3563],
            [-95.7022, 32.3771],
            [-95.4492, 32.3552],
            [-95.488, 32.2355],
            [-95.4701, 32.174],
            [-95.4621, 32.1287],
            [-95.5264, 32.1255],
            [-95.594, 32.2183],
            [-95.7045, 32.2127],
            [-95.7406, 32.2511],
            [-95.72, 32.305],
            [-95.7801, 32.2892],
            [-95.7773, 32.3563],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811640',
        NAME: 'Brownsboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442984292,
        AWATER: 56138900,
        INTPTLAT: '+32.2693300',
        INTPTLON: '-095.5937721',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.727, 35.8765],
            [-102.6197, 35.8768],
            [-102.6203, 35.9352],
            [-102.3709, 35.9493],
            [-102.251, 36.0552],
            [-102.163, 36.0552],
            [-102.1629, 35.8085],
            [-102.4595, 35.8088],
            [-102.727, 35.7894],
            [-102.727, 35.8765],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822680',
        NAME: 'Hartley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 958461880,
        AWATER: 672137,
        INTPTLAT: '+35.8974978',
        INTPTLON: '-102.4134928',
        ELSDLEA: '',
        UNSDLEA: '22680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9383, 27.6958],
            [-97.8609, 27.7325],
            [-97.75, 27.7252],
            [-97.7471, 27.6954],
            [-97.9401, 27.6708],
            [-97.9383, 27.6958],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817550',
        NAME: 'Driscoll Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120522925,
        AWATER: 2096,
        INTPTLAT: '+27.6495846',
        INTPTLON: '-097.7416587',
        ELSDLEA: '',
        UNSDLEA: '17550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7286, 27.8496],
            [-97.6195, 27.8079],
            [-97.6193, 27.755],
            [-97.5949, 27.702],
            [-97.7471, 27.6954],
            [-97.75, 27.7252],
            [-97.7286, 27.8496],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837440',
        NAME: 'Robstown Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210387727,
        AWATER: 0,
        INTPTLAT: '+27.7672164',
        INTPTLON: '-097.6832677',
        ELSDLEA: '',
        UNSDLEA: '37440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6193, 27.755],
            [-97.487, 27.7854],
            [-97.4681, 27.7086],
            [-97.5199, 27.6953],
            [-97.5949, 27.702],
            [-97.6193, 27.755],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845120',
        NAME: 'West Oso Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114622021,
        AWATER: 0,
        INTPTLAT: '+27.7362741',
        INTPTLON: '-097.5228917',
        ELSDLEA: '',
        UNSDLEA: '45120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3982, 27.6376],
            [-97.3465, 27.6331],
            [-97.2592, 27.8142],
            [-97.1867, 27.8214],
            [-97.136, 27.8304],
            [-97.2024, 27.6622],
            [-97.1323, 27.6278],
            [-97.1651, 27.5683],
            [-97.2421, 27.5829],
            [-97.405, 27.56],
            [-97.3982, 27.6376],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819380',
        NAME: 'Flour Bluff Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144784673,
        AWATER: 309528436,
        INTPTLAT: '+27.6592628',
        INTPTLON: '-097.2508205',
        ELSDLEA: '',
        UNSDLEA: '19380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8956, 32.101],
            [-96.7589, 32.142],
            [-96.7771, 32.1029],
            [-96.7262, 31.9548],
            [-96.8254, 31.9792],
            [-96.931, 32.0568],
            [-96.8956, 32.101],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820040',
        NAME: 'Frost Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214313845,
        AWATER: 9035359,
        INTPTLAT: '+32.0386874',
        INTPTLON: '-096.8079316',
        ELSDLEA: '',
        UNSDLEA: '20040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0867, 32.5079],
            [-97.0384, 32.5487],
            [-96.9186, 32.5478],
            [-96.8738, 32.4986],
            [-96.9742, 32.3943],
            [-97.0874, 32.392],
            [-97.0867, 32.5079],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830600',
        NAME: 'Midlothian Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287106264,
        AWATER: 3649686,
        INTPTLAT: '+32.4721669',
        INTPTLON: '-097.0007259',
        ELSDLEA: '',
        UNSDLEA: '30600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9186, 32.5478],
            [-96.8996, 32.5477],
            [-96.8221, 32.5472],
            [-96.7425, 32.5467],
            [-96.7831, 32.4517],
            [-96.7887, 32.4456],
            [-96.8738, 32.4986],
            [-96.9186, 32.5478],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836660',
        NAME: 'Red Oak Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95265353,
        AWATER: 61234,
        INTPTLAT: '+32.5158967',
        INTPTLON: '-096.8108700',
        ELSDLEA: '',
        UNSDLEA: '36660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1784, 34.4958],
            [-102.0537, 34.4649],
            [-101.9983, 34.4935],
            [-101.9982, 34.4273],
            [-101.998, 34.313],
            [-102.0091, 34.3131],
            [-102.235, 34.3132],
            [-102.2606, 34.3403],
            [-102.2511, 34.4234],
            [-102.1784, 34.4958],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822650',
        NAME: 'Hart Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399772067,
        AWATER: 2604192,
        INTPTLAT: '+34.3965656',
        INTPTLON: '-102.1196403',
        ELSDLEA: '',
        UNSDLEA: '22650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6654, 32.9604],
            [-95.5206, 32.9614],
            [-95.4689, 32.9423],
            [-95.4745, 32.8746],
            [-95.5494, 32.809],
            [-95.6575, 32.8983],
            [-95.6654, 32.9604],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846590',
        NAME: 'Yantis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143109228,
        AWATER: 48973073,
        INTPTLAT: '+32.9095098',
        INTPTLON: '-095.5719409',
        ELSDLEA: '',
        UNSDLEA: '46590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8936, 33.0834],
            [-95.8909, 33.1674],
            [-95.8495, 33.2261],
            [-95.779, 33.2602],
            [-95.773, 33.237],
            [-95.782, 33.0957],
            [-95.8633, 33.0499],
            [-95.8936, 33.0834],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816020',
        NAME: 'Cumby Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175813030,
        AWATER: 1406583,
        INTPTLAT: '+33.1494621',
        INTPTLON: '-095.8140856',
        ELSDLEA: '',
        UNSDLEA: '16020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.773, 33.237],
            [-95.5761, 33.1763],
            [-95.5161, 33.2501],
            [-95.4345, 33.1888],
            [-95.4675, 33.1067],
            [-95.5259, 33.0743],
            [-95.5206, 32.9614],
            [-95.6654, 32.9604],
            [-95.748, 32.9801],
            [-95.7056, 33.0752],
            [-95.782, 33.0957],
            [-95.773, 33.237],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841820',
        NAME: 'Sulphur Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649568988,
        AWATER: 18303408,
        INTPTLAT: '+33.1178883',
        INTPTLON: '-095.6230228',
        ELSDLEA: '',
        UNSDLEA: '41820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7399, 33.5102],
            [-99.5403, 33.5957],
            [-99.4737, 33.5796],
            [-99.4724, 33.399],
            [-99.4343, 33.3989],
            [-99.4723, 33.3321],
            [-99.5397, 33.3988],
            [-99.7198, 33.3984],
            [-99.7399, 33.5102],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831970',
        NAME: 'Munday Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505210206,
        AWATER: 156478,
        INTPTLAT: '+33.4670266',
        INTPTLON: '-099.5791428',
        ELSDLEA: '',
        UNSDLEA: '31970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1952, 27.9135],
            [-97.1882, 27.8242],
            [-97.2486, 27.8749],
            [-97.1952, 27.9135],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824180',
        NAME: 'Ingleside Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59169075,
        AWATER: 1571196,
        INTPTLAT: '+27.8706078',
        INTPTLON: '-097.2079061',
        ELSDLEA: '',
        UNSDLEA: '24180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5243, 33.6283],
            [-95.4968, 33.6365],
            [-95.3109, 33.7891],
            [-95.3104, 33.5511],
            [-95.2448, 33.5486],
            [-95.2903, 33.4935],
            [-95.2637, 33.3717],
            [-95.2805, 33.377],
            [-95.3066, 33.378],
            [-95.4907, 33.4543],
            [-95.5243, 33.6283],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835740',
        NAME: 'Prairiland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 617499334,
        AWATER: 7282236,
        INTPTLAT: '+33.5542297',
        INTPTLON: '-095.3829090',
        ELSDLEA: '',
        UNSDLEA: '35740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8489, 32.2044],
            [-96.7712, 32.2909],
            [-96.7149, 32.1633],
            [-96.7552, 32.1438],
            [-96.8489, 32.2044],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809030',
        NAME: 'Avalon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119038493,
        AWATER: 942408,
        INTPTLAT: '+32.2246537',
        INTPTLON: '-096.7884810',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0874, 32.392],
            [-96.9742, 32.3943],
            [-96.9506, 32.2836],
            [-96.9761, 32.2249],
            [-97.0488, 32.2106],
            [-97.0862, 32.2655],
            [-97.0866, 32.3611],
            [-97.0874, 32.392],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829580',
        NAME: 'Maypearl Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202623687,
        AWATER: 2777899,
        INTPTLAT: '+32.3111157',
        INTPTLON: '-097.0298444',
        ELSDLEA: '',
        UNSDLEA: '29580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9742, 32.3943],
            [-96.8738, 32.4986],
            [-96.7887, 32.4456],
            [-96.7373, 32.41],
            [-96.7712, 32.2909],
            [-96.8489, 32.2044],
            [-96.9693, 32.2176],
            [-96.9761, 32.2249],
            [-96.9506, 32.2836],
            [-96.9742, 32.3943],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844730',
        NAME: 'Waxahachie Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491578802,
        AWATER: 5324718,
        INTPTLAT: '+32.3563392',
        INTPTLON: '-096.8634930',
        ELSDLEA: '',
        UNSDLEA: '44730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7712, 32.2909],
            [-96.7373, 32.41],
            [-96.6061, 32.407],
            [-96.5707, 32.4612],
            [-96.4958, 32.4683],
            [-96.4343, 32.4109],
            [-96.4498, 32.3449],
            [-96.2959, 32.2934],
            [-96.3489, 32.2671],
            [-96.4337, 32.2788],
            [-96.5932, 32.2229],
            [-96.7149, 32.1633],
            [-96.7712, 32.2909],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818540',
        NAME: 'Ennis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651884722,
        AWATER: 23910174,
        INTPTLAT: '+32.3224436',
        INTPTLON: '-096.5714037',
        ELSDLEA: '',
        UNSDLEA: '18540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7887, 32.4456],
            [-96.7831, 32.4517],
            [-96.6645, 32.4837],
            [-96.5707, 32.4612],
            [-96.6061, 32.407],
            [-96.7373, 32.41],
            [-96.7887, 32.4456],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834110',
        NAME: 'Palmer Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142543703,
        AWATER: 1217593,
        INTPTLAT: '+32.4398710',
        INTPTLON: '-096.6780508',
        ELSDLEA: '',
        UNSDLEA: '34110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7152, 33.6217],
            [-97.6256, 33.6487],
            [-97.4863, 33.5568],
            [-97.4864, 33.5418],
            [-97.4869, 33.4588],
            [-97.5822, 33.434],
            [-97.61, 33.434],
            [-97.7152, 33.6217],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819530',
        NAME: 'Forestburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328968846,
        AWATER: 1522346,
        INTPTLAT: '+33.5468681',
        INTPTLON: '-097.5842363',
        ELSDLEA: '',
        UNSDLEA: '19530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.4036, 30.9442],
            [-104.4028, 30.841],
            [-104.2082, 30.8279],
            [-104.2089, 30.7263],
            [-104.0908, 30.7261],
            [-104.0905, 30.4504],
            [-104.6583, 30.565],
            [-104.9798, 30.6293],
            [-104.4036, 30.9442],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843740',
        NAME: 'Valentine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2327811876,
        AWATER: 74130,
        INTPTLAT: '+30.6741964',
        INTPTLON: '-104.4384680',
        ELSDLEA: '',
        UNSDLEA: '43740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7196, 35.5798],
            [-101.7243, 35.3239],
            [-101.7599, 35.2653],
            [-101.8842, 35.2648],
            [-101.922, 35.2942],
            [-101.9147, 35.4498],
            [-101.764, 35.4796],
            [-101.7196, 35.5798],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837260',
        NAME: 'River Road Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403610109,
        AWATER: 4721942,
        INTPTLAT: '+35.3914421',
        INTPTLON: '-101.8157436',
        ELSDLEA: '',
        UNSDLEA: '37260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7599, 35.2653],
            [-101.7243, 35.3239],
            [-101.7196, 35.5798],
            [-101.6723, 35.6201],
            [-101.6228, 35.6202],
            [-101.6229, 35.1831],
            [-101.7601, 35.1834],
            [-101.7599, 35.2653],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835560',
        NAME: 'Highland Park Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 468682028,
        AWATER: 16580046,
        INTPTLAT: '+35.3720168',
        INTPTLON: '-101.6604013',
        ELSDLEA: '',
        UNSDLEA: '35560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0951, 29.8605],
            [-98.9897, 29.8135],
            [-98.9177, 29.7814],
            [-98.8028, 29.6948],
            [-98.9129, 29.5774],
            [-98.9742, 29.5519],
            [-98.9838, 29.6234],
            [-99.3085, 29.6267],
            [-99.2115, 29.7005],
            [-99.0951, 29.8605],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809360',
        NAME: 'Bandera Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 804997603,
        AWATER: 14188544,
        INTPTLAT: '+29.6970902',
        INTPTLON: '-099.0410901',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.499, 29.9067],
            [-99.4711, 29.9064],
            [-99.3604, 29.9441],
            [-99.1791, 29.8953],
            [-99.0951, 29.8605],
            [-99.2115, 29.7005],
            [-99.3085, 29.6267],
            [-99.3092, 29.6267],
            [-99.3535, 29.6272],
            [-99.4101, 29.7362],
            [-99.451, 29.7347],
            [-99.499, 29.9067],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830030',
        NAME: 'Medina Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 791453923,
        AWATER: 2456670,
        INTPTLAT: '+29.8042174',
        INTPTLON: '-099.3126812',
        ELSDLEA: '',
        UNSDLEA: '30030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6122, 36.4995],
            [-100.5221, 36.4993],
            [-100.4505, 36.4995],
            [-100.4245, 36.4994],
            [-100.4019, 36.3615],
            [-100.4197, 36.2308],
            [-100.3667, 36.1292],
            [-100.3668, 36.0998],
            [-100.3669, 36.0557],
            [-100.4705, 36.0089],
            [-100.5402, 36.0565],
            [-100.5467, 36.0565],
            [-100.546, 36.3756],
            [-100.6192, 36.391],
            [-100.6122, 36.4995],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810830',
        NAME: 'Booker Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 780209057,
        AWATER: 40045,
        INTPTLAT: '+36.2605366',
        INTPTLON: '-100.4943240',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8298, 33.8316],
            [-97.7738, 33.8072],
            [-97.6538, 33.8307],
            [-97.5859, 33.9019],
            [-97.5174, 33.914],
            [-97.5811, 33.8164],
            [-97.556, 33.752],
            [-97.6504, 33.6815],
            [-97.7482, 33.704],
            [-97.8508, 33.7274],
            [-97.8298, 33.8316],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832790',
        NAME: 'Nocona Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412471441,
        AWATER: 2690186,
        INTPTLAT: '+33.7923704',
        INTPTLON: '-097.6797106',
        ELSDLEA: '',
        UNSDLEA: '32790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.4709, 32.0011],
            [-106.4093, 32.0013],
            [-106.3235, 32.0015],
            [-106.2701, 32.0015],
            [-106.2702, 31.8064],
            [-106.3025, 31.8021],
            [-106.4041, 31.7446],
            [-106.5282, 31.7831],
            [-106.6324, 31.864],
            [-106.4701, 31.8833],
            [-106.4709, 32.0011],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818300',
        NAME: 'El Paso Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538843027,
        AWATER: 2162473,
        INTPTLAT: '+31.8672858',
        INTPTLON: '-106.4117984',
        ELSDLEA: '',
        UNSDLEA: '18300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3201, 31.6446],
            [-106.3025, 31.8021],
            [-106.2702, 31.8064],
            [-106.2701, 32.0015],
            [-106.2007, 32.0018],
            [-106.1971, 31.6512],
            [-106.2606, 31.6097],
            [-106.2957, 31.601],
            [-106.3201, 31.6446],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840710',
        NAME: 'Socorro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344388236,
        AWATER: 672885,
        INTPTLAT: '+31.7899199',
        INTPTLON: '-106.2462294',
        ELSDLEA: '',
        UNSDLEA: '40710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0876, 31.2762],
            [-103.61, 31.2642],
            [-103.6196, 31.1778],
            [-103.6822, 31.1392],
            [-103.7122, 31.0172],
            [-103.6799, 30.8291],
            [-104.1024, 31.1052],
            [-104.0876, 31.2762],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809330',
        NAME: 'Balmorhea Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1387754135,
        AWATER: 2374450,
        INTPTLAT: '+31.1138386',
        INTPTLON: '-103.8626249',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9627, 33.3113],
            [-94.9198, 33.3262],
            [-94.9315, 33.1867],
            [-94.9899, 33.1867],
            [-94.9579, 33.2518],
            [-94.9627, 33.3113],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822710',
        NAME: 'Harts Bluff Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74091726,
        AWATER: 1701795,
        INTPTLAT: '+33.2508408',
        INTPTLON: '-094.9421016',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2448, 33.5486],
            [-95.186, 33.5788],
            [-95.1563, 33.5168],
            [-94.9522, 33.456],
            [-94.9534, 33.3961],
            [-94.9627, 33.3113],
            [-95.0756, 33.3114],
            [-95.0989, 33.2671],
            [-95.1246, 33.2753],
            [-95.177, 33.3778],
            [-95.2637, 33.3717],
            [-95.2903, 33.4935],
            [-95.2448, 33.5486],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842120',
        NAME: 'Rivercrest Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602663209,
        AWATER: 9954524,
        INTPTLAT: '+33.4197576',
        INTPTLON: '-095.1220029',
        ELSDLEA: '',
        UNSDLEA: '42120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8145, 33.137],
            [-94.8141, 33.1528],
            [-94.7667, 33.1108],
            [-94.6535, 33.1067],
            [-94.6538, 32.917],
            [-94.654, 32.8795],
            [-94.7059, 32.8792],
            [-94.709, 32.8959],
            [-94.832, 32.9873],
            [-94.8522, 33.0693],
            [-94.8145, 33.137],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816180',
        NAME: 'Daingerfield-Lone Star Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327012190,
        AWATER: 12356794,
        INTPTLAT: '+33.0222394',
        INTPTLON: '-094.7426565',
        ELSDLEA: '',
        UNSDLEA: '16180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1256, 33.1191],
            [-95.0659, 33.174],
            [-95.0989, 33.2671],
            [-95.0756, 33.3114],
            [-94.9627, 33.3113],
            [-94.9534, 33.3961],
            [-94.8263, 33.3788],
            [-94.8088, 33.3636],
            [-94.8141, 33.1528],
            [-94.8145, 33.137],
            [-94.9392, 33.1584],
            [-94.9406, 33.0745],
            [-95.1261, 33.0346],
            [-95.1256, 33.1191],
          ],
          [
            [-94.9627, 33.3113],
            [-94.9579, 33.2518],
            [-94.9899, 33.1867],
            [-94.9315, 33.1867],
            [-94.9198, 33.3262],
            [-94.9627, 33.3113],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831760',
        NAME: 'Mount Pleasant Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567754912,
        AWATER: 36271993,
        INTPTLAT: '+33.2145990',
        INTPTLON: '-094.9667825',
        ELSDLEA: '',
        UNSDLEA: '31760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9535, 33.2859],
            [-98.7899, 33.2789],
            [-98.663, 33.309],
            [-98.6348, 33.1465],
            [-98.7493, 33.092],
            [-98.9522, 33.1141],
            [-98.9526, 33.1671],
            [-98.9535, 33.2859],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832700',
        NAME: 'Newcastle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 553538260,
        AWATER: 5567687,
        INTPTLAT: '+33.1917332',
        INTPTLON: '-098.7909323',
        ELSDLEA: '',
        UNSDLEA: '32700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2327, 27.3203],
            [-98.0584, 27.3278],
            [-98.0581, 27.261],
            [-98.2325, 27.2625],
            [-98.2327, 27.3203],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826070',
        NAME: 'La Gloria Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118848294,
        AWATER: 0,
        INTPTLAT: '+27.2929009',
        INTPTLON: '-098.1430942',
        ELSDLEA: '',
        UNSDLEA: '26070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.874, 31.2474],
            [-94.7865, 31.2671],
            [-94.6862, 31.2209],
            [-94.6253, 31.1415],
            [-94.6109, 31.122],
            [-94.6021, 31.0741],
            [-94.6152, 31.0852],
            [-94.7527, 31.1064],
            [-94.8429, 31.1466],
            [-94.8531, 31.1858],
            [-94.874, 31.2474],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817040',
        NAME: 'Diboll Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292875129,
        AWATER: 4236571,
        INTPTLAT: '+31.1748608',
        INTPTLON: '-094.7388522',
        ELSDLEA: '',
        UNSDLEA: '17040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7865, 31.2671],
            [-94.7565, 31.3698],
            [-94.7257, 31.457],
            [-94.6647, 31.4595],
            [-94.5692, 31.433],
            [-94.6126, 31.3561],
            [-94.6253, 31.1415],
            [-94.6862, 31.2209],
            [-94.7865, 31.2671],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828550',
        NAME: 'Lufkin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411763324,
        AWATER: 9547247,
        INTPTLAT: '+31.3358444',
        INTPTLON: '-094.6760227',
        ELSDLEA: '',
        UNSDLEA: '28550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7565, 31.3698],
            [-94.7865, 31.2671],
            [-94.874, 31.2474],
            [-94.9581, 31.3869],
            [-94.9627, 31.39],
            [-94.7565, 31.3698],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823790',
        NAME: 'Hudson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196465794,
        AWATER: 2590304,
        INTPTLAT: '+31.3284869',
        INTPTLON: '-094.8598358',
        ELSDLEA: '',
        UNSDLEA: '23790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8536, 33.8527],
            [-97.7261, 33.9406],
            [-97.6307, 33.9793],
            [-97.5859, 33.9019],
            [-97.6538, 33.8307],
            [-97.7738, 33.8072],
            [-97.8298, 33.8316],
            [-97.8536, 33.8527],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835730',
        NAME: 'Prairie Valley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263170290,
        AWATER: 3794948,
        INTPTLAT: '+33.8823175',
        INTPTLON: '-097.7049988',
        ELSDLEA: '',
        UNSDLEA: '35730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7984, 29.9962],
            [-96.6373, 29.9739],
            [-96.5957, 30.0028],
            [-96.5698, 29.9615],
            [-96.6652, 29.8523],
            [-96.773, 29.9385],
            [-96.7984, 29.9962],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819140',
        NAME: 'Fayetteville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210228650,
        AWATER: 12050572,
        INTPTLAT: '+29.9358525',
        INTPTLON: '-096.6850318',
        ELSDLEA: '',
        UNSDLEA: '19140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9908, 29.8071],
            [-96.8701, 29.8008],
            [-96.8263, 29.7626],
            [-96.8064, 29.7021],
            [-96.8742, 29.6327],
            [-97.0211, 29.6302],
            [-97.0259, 29.748],
            [-96.9908, 29.8071],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839540',
        NAME: 'Schulenburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369055330,
        AWATER: 164650,
        INTPTLAT: '+29.7224139',
        INTPTLON: '-096.9397541',
        ELSDLEA: '',
        UNSDLEA: '39540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3158, 29.7865],
            [-97.1653, 29.923],
            [-97.1033, 29.9236],
            [-97.0027, 29.8959],
            [-96.9908, 29.8071],
            [-97.0259, 29.748],
            [-97.0211, 29.6302],
            [-97.0452, 29.6297],
            [-97.1305, 29.6433],
            [-97.3158, 29.7865],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819280',
        NAME: 'Flatonia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 635769789,
        AWATER: 823249,
        INTPTLAT: '+29.7823610',
        INTPTLON: '-097.1385599',
        ELSDLEA: '',
        UNSDLEA: '19280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8818, 30.099],
            [-96.8063, 30.1504],
            [-96.736, 30.1472],
            [-96.6337, 30.1061],
            [-96.622, 30.0443],
            [-96.5957, 30.0028],
            [-96.6373, 29.9739],
            [-96.7984, 29.9962],
            [-96.8818, 30.099],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838100',
        NAME: 'Round Top-Carmine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350528159,
        AWATER: 2223991,
        INTPTLAT: '+30.0794878',
        INTPTLON: '-096.7479367',
        ELSDLEA: '',
        UNSDLEA: '38100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1033, 29.9236],
            [-97.0245, 30.0514],
            [-96.8818, 30.099],
            [-96.7984, 29.9962],
            [-96.773, 29.9385],
            [-96.6652, 29.8523],
            [-96.7243, 29.7853],
            [-96.8263, 29.7626],
            [-96.8701, 29.8008],
            [-96.9908, 29.8071],
            [-97.0027, 29.8959],
            [-97.1033, 29.9236],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826100',
        NAME: 'La Grange Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 809663086,
        AWATER: 9634559,
        INTPTLAT: '+29.9203957',
        INTPTLON: '-096.8906953',
        ELSDLEA: '',
        UNSDLEA: '26100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2758, 31.6572],
            [-95.1407, 31.6912],
            [-95.0907, 31.7462],
            [-94.9683, 31.7483],
            [-94.9508, 31.6792],
            [-94.9239, 31.5792],
            [-95.0704, 31.574],
            [-95.1432, 31.544],
            [-95.2316, 31.5739],
            [-95.2732, 31.5929],
            [-95.2758, 31.6572],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807980',
        NAME: 'Alto Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 506784577,
        AWATER: 1622107,
        INTPTLAT: '+31.6495633',
        INTPTLON: '-095.0833493',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.164, 31.999],
            [-95.0732, 32.066],
            [-94.9854, 31.9957],
            [-94.9854, 31.964],
            [-95.1453, 31.9371],
            [-95.164, 31.999],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832610',
        NAME: 'New Summerfield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148931323,
        AWATER: 3767194,
        INTPTLAT: '+31.9927817',
        INTPTLON: '-095.0769775',
        ELSDLEA: '',
        UNSDLEA: '32610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1817, 32.1953],
            [-101.1751, 32.4805],
            [-101.1406, 32.4795],
            [-100.9513, 32.4178],
            [-100.9026, 32.2622],
            [-100.8526, 32.1864],
            [-100.8896, 32.1385],
            [-101.0652, 32.1788],
            [-101.1817, 32.1953],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845210',
        NAME: 'Westbrook Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 725371940,
        AWATER: 2409651,
        INTPTLAT: '+32.3011042',
        INTPTLON: '-101.0514627',
        ELSDLEA: '',
        UNSDLEA: '45210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1896, 31.6341],
            [-96.0865, 31.6459],
            [-96.0173, 31.6164],
            [-96.0159, 31.555],
            [-96.138, 31.5414],
            [-96.1896, 31.6341],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816980',
        NAME: 'Dew Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156145886,
        AWATER: 495996,
        INTPTLAT: '+31.5911638',
        INTPTLON: '-096.0935697',
        ELSDLEA: '',
        UNSDLEA: '16980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3597, 28.9655],
            [-97.2715, 29.0493],
            [-97.184, 29.0336],
            [-97.1606, 28.9709],
            [-97.2506, 28.9051],
            [-97.2362, 28.8649],
            [-97.3554, 28.8976],
            [-97.3597, 28.9655],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830450',
        NAME: 'Meyersville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248603273,
        AWATER: 37584,
        INTPTLAT: '+28.9585703',
        INTPTLON: '-097.2660669',
        ELSDLEA: '',
        UNSDLEA: '30450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1606, 28.9709],
            [-97.0799, 29.0301],
            [-97.028, 28.9775],
            [-97.1232, 28.8845],
            [-97.1606, 28.9709],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833270',
        NAME: 'Nursery Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137283486,
        AWATER: 174728,
        INTPTLAT: '+28.9409154',
        INTPTLON: '-097.0958270',
        ELSDLEA: '',
        UNSDLEA: '33270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9357, 31.5781],
            [-100.0074, 31.5789],
            [-100.0068, 31.6667],
            [-99.9418, 31.6667],
            [-99.9357, 31.5781],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833570',
        NAME: 'Olfen Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56551657,
        AWATER: 38626,
        INTPTLAT: '+31.6303251',
        INTPTLON: '-099.9748110',
        ELSDLEA: '',
        UNSDLEA: '33570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0527, 28.7391],
            [-97.937, 28.7662],
            [-97.8647, 28.6877],
            [-97.9058, 28.6732],
            [-97.8686, 28.4967],
            [-97.9611, 28.444],
            [-98.0115, 28.5309],
            [-98.0023, 28.5955],
            [-98.0898, 28.663],
            [-98.0527, 28.7391],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834410',
        NAME: 'Pawnee Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390777753,
        AWATER: 251483,
        INTPTLAT: '+28.6058592',
        INTPTLON: '-097.9698448',
        ELSDLEA: '',
        UNSDLEA: '34410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6858, 36.1386],
            [-101.6235, 36.1546],
            [-101.3433, 36.1543],
            [-101.3416, 36.0553],
            [-101.3911, 36.0088],
            [-101.0859, 36.0105],
            [-101.0859, 35.9639],
            [-101.2116, 35.9654],
            [-101.4115, 35.8827],
            [-101.6231, 35.8706],
            [-101.6231, 35.8729],
            [-101.6235, 36.0554],
            [-101.6858, 36.1386],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835890',
        NAME: 'Pringle-Morse Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 948926891,
        AWATER: 444894,
        INTPTLAT: '+36.0084208',
        INTPTLON: '-101.4252975',
        ELSDLEA: '',
        UNSDLEA: '35890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5223, 27.3433],
            [-98.4773, 27.4084],
            [-98.3775, 27.4072],
            [-98.3103, 27.3402],
            [-98.2327, 27.3203],
            [-98.2325, 27.2625],
            [-98.5233, 27.2651],
            [-98.5223, 27.3433],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836420',
        NAME: 'Ramirez Common School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324712715,
        AWATER: 273724,
        INTPTLAT: '+27.3252075',
        INTPTLON: '-098.3934755',
        ELSDLEA: '',
        UNSDLEA: '36420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3541, 29.7688],
            [-102.5718, 29.769],
            [-102.6458, 29.7339],
            [-102.7686, 29.5947],
            [-102.9064, 29.2621],
            [-103.0339, 29.101],
            [-103.1001, 29.0607],
            [-103.1539, 28.9716],
            [-103.3388, 29.0203],
            [-103.3436, 29.0464],
            [-103.3427, 29.4919],
            [-103.3541, 29.7688],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839060',
        NAME: 'San Vicente Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4019024684,
        AWATER: 6866017,
        INTPTLAT: '+29.4425442',
        INTPTLON: '-103.0822507',
        ELSDLEA: '',
        UNSDLEA: '39060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0429, 34.8211],
            [-103.0427, 34.9541],
            [-103.0428, 35.0676],
            [-102.6577, 35.0472],
            [-102.544, 35.0577],
            [-102.5459, 34.8966],
            [-102.5816, 34.8678],
            [-102.7621, 34.8728],
            [-102.7614, 34.8102],
            [-102.8486, 34.7475],
            [-103.0428, 34.7474],
            [-103.0429, 34.8211],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844370',
        NAME: 'Walcott Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1220426789,
        AWATER: 586931,
        INTPTLAT: '+34.9448303',
        INTPTLON: '-102.8420812',
        ELSDLEA: '',
        UNSDLEA: '44370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.319, 35.3685],
            [-102.1652, 35.3725],
            [-102.1675, 35.1832],
            [-102.1151, 35.1023],
            [-102.1676, 35.102],
            [-102.2424, 35.1027],
            [-102.3288, 35.132],
            [-102.319, 35.3685],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845840',
        NAME: 'Wildorado Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450896392,
        AWATER: 609331,
        INTPTLAT: '+35.2255407',
        INTPTLON: '-102.2216617',
        ELSDLEA: '',
        UNSDLEA: '45840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9423, 32.3704],
            [-94.8968, 32.3042],
            [-94.9428, 32.308],
            [-94.9423, 32.3704],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827270',
        NAME: 'Leveretts Chapel Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30371746,
        AWATER: 0,
        INTPTLAT: '+32.3339395',
        INTPTLON: '-094.9128863',
        ELSDLEA: '',
        UNSDLEA: '27270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7929, 29.5034],
            [-103.3427, 29.4919],
            [-103.3436, 29.0464],
            [-103.3868, 29.0217],
            [-103.5569, 29.1555],
            [-103.7186, 29.1808],
            [-103.7927, 29.2625],
            [-103.7929, 29.5034],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842390',
        NAME: 'Terlingua Common School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1787347509,
        AWATER: 1790285,
        INTPTLAT: '+29.3097383',
        INTPTLON: '-103.5404756',
        ELSDLEA: '',
        UNSDLEA: '42390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7674, 31.5157],
            [-102.7674, 31.2938],
            [-102.8251, 31.2669],
            [-102.9336, 31.3501],
            [-103.011, 31.3713],
            [-103.0462, 31.3837],
            [-102.9232, 31.4933],
            [-102.7674, 31.5157],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821480',
        NAME: 'Grandfalls-Royalty Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473623976,
        AWATER: 235262,
        INTPTLAT: '+31.4147713',
        INTPTLON: '-102.8792533',
        ELSDLEA: '',
        UNSDLEA: '21480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3379, 31.6514],
            [-102.7989, 31.6518],
            [-102.7672, 31.6517],
            [-102.7674, 31.5157],
            [-102.9232, 31.4933],
            [-103.0462, 31.3837],
            [-103.1812, 31.3702],
            [-103.2617, 31.4276],
            [-103.3376, 31.4178],
            [-103.3379, 31.6514],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831170',
        NAME: 'Monahans-Wickett-Pyote Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1294610550,
        AWATER: 336773,
        INTPTLAT: '+31.5364276',
        INTPTLON: '-103.0929640',
        ELSDLEA: '',
        UNSDLEA: '31170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.5704, 32.0007],
            [-106.6255, 31.957],
            [-106.6185, 32.0005],
            [-106.5704, 32.0007],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808430',
        NAME: 'Anthony Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21873055,
        AWATER: 310129,
        INTPTLAT: '+31.9800534',
        INTPTLON: '-106.5987773',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2957, 31.601],
            [-106.2606, 31.6097],
            [-106.193, 31.541],
            [-106.2462, 31.5412],
            [-106.2957, 31.601],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838850',
        NAME: 'San Elizario Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34364111,
        AWATER: 109671,
        INTPTLAT: '+31.5686530',
        INTPTLON: '-106.2509347',
        ELSDLEA: '',
        UNSDLEA: '38850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.6255, 31.957],
            [-106.5704, 32.0007],
            [-106.4709, 32.0011],
            [-106.4701, 31.8833],
            [-106.6324, 31.864],
            [-106.6255, 31.957],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812780',
        NAME: 'Canutillo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182370385,
        AWATER: 632550,
        INTPTLAT: '+31.9488858',
        INTPTLON: '-106.5280689',
        ELSDLEA: '',
        UNSDLEA: '12780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8007, 30.4125],
            [-103.44, 30.6659],
            [-103.3456, 30.6005],
            [-103.3691, 30.3767],
            [-103.3541, 29.7688],
            [-103.3427, 29.4919],
            [-103.7929, 29.5034],
            [-103.8007, 30.4125],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807950',
        NAME: 'Alpine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4958028254,
        AWATER: 5082825,
        INTPTLAT: '+30.0291557',
        INTPTLON: '-103.5694441',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0275, 30.4982],
            [-99.01, 30.4134],
            [-99.1302, 30.3602],
            [-99.2805, 30.4282],
            [-99.3081, 30.4998],
            [-99.1349, 30.526],
            [-99.0275, 30.4982],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817400',
        NAME: 'Doss Consolidated Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342997388,
        AWATER: 293160,
        INTPTLAT: '+30.4651627',
        INTPTLON: '-099.1562250',
        ELSDLEA: '',
        UNSDLEA: '17400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4701, 32.174],
            [-95.3279, 32.1815],
            [-95.2227, 32.1365],
            [-95.2396, 32.0926],
            [-95.4325, 32.0596],
            [-95.4621, 32.1287],
            [-95.4701, 32.174],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812060',
        NAME: 'Bullard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204349645,
        AWATER: 18331327,
        INTPTLAT: '+32.1184780',
        INTPTLON: '-095.3692935',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1829, 32.2616],
            [-95.0988, 32.3276],
            [-94.9859, 32.3504],
            [-94.9854, 32.2584],
            [-94.9854, 32.1984],
            [-95.0754, 32.2093],
            [-95.1726, 32.1534],
            [-95.1829, 32.2616],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808730',
        NAME: 'Arp Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205995485,
        AWATER: 12619441,
        INTPTLAT: '+32.2675400',
        INTPTLON: '-095.0777145',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2582, 32.2952],
            [-95.1829, 32.2616],
            [-95.1726, 32.1534],
            [-95.2227, 32.1365],
            [-95.3279, 32.1815],
            [-95.3318, 32.2434],
            [-95.2582, 32.2952],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845600',
        NAME: 'Whitehouse Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192112565,
        AWATER: 8301549,
        INTPTLAT: '+32.2145615',
        INTPTLON: '-095.2395953',
        ELSDLEA: '',
        UNSDLEA: '45600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5019, 32.6203],
            [-95.346, 32.6093],
            [-95.3017, 32.5904],
            [-95.3223, 32.4677],
            [-95.4973, 32.4314],
            [-95.5003, 32.3997],
            [-95.524, 32.5002],
            [-95.5019, 32.6203],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827510',
        NAME: 'Lindale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411999550,
        AWATER: 6767066,
        INTPTLAT: '+32.5197402',
        INTPTLON: '-095.4470928',
        ELSDLEA: '',
        UNSDLEA: '27510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2267, 32.4028],
            [-95.102, 32.4112],
            [-95.0364, 32.4462],
            [-94.9868, 32.4234],
            [-94.9854, 32.3708],
            [-94.9859, 32.3504],
            [-95.0988, 32.3276],
            [-95.1829, 32.2616],
            [-95.2582, 32.2952],
            [-95.2525, 32.3228],
            [-95.2267, 32.4028],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813650',
        NAME: 'Chapel Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 338938081,
        AWATER: 6234474,
        INTPTLAT: '+32.3570308',
        INTPTLON: '-095.1338659',
        ELSDLEA: '',
        UNSDLEA: '13650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3017, 32.5904],
            [-95.1754, 32.5555],
            [-95.1313, 32.549],
            [-95.1208, 32.4418],
            [-95.0364, 32.4462],
            [-95.102, 32.4112],
            [-95.2267, 32.4028],
            [-95.3223, 32.4677],
            [-95.3017, 32.5904],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846230',
        NAME: 'Winona Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299483744,
        AWATER: 4821416,
        INTPTLAT: '+32.4866646',
        INTPTLON: '-095.2110038',
        ELSDLEA: '',
        UNSDLEA: '46230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.315, 33.2541],
            [-96.2953, 33.2934],
            [-96.1744, 33.1925],
            [-96.2088, 33.1222],
            [-96.2962, 33.1005],
            [-96.315, 33.2541],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810350',
        NAME: 'Bland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156425869,
        AWATER: 469172,
        INTPTLAT: '+33.1951630',
        INTPTLON: '-096.2634065',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4399, 33.2342],
            [-96.315, 33.2541],
            [-96.2962, 33.1005],
            [-96.4686, 33.0823],
            [-96.4217, 33.1672],
            [-96.4399, 33.2342],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819080',
        NAME: 'Farmersville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198532656,
        AWATER: 23630520,
        INTPTLAT: '+33.1738263',
        INTPTLON: '-096.3823557',
        ELSDLEA: '',
        UNSDLEA: '19080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7329, 33.2036],
            [-96.7058, 33.2764],
            [-96.6347, 33.3432],
            [-96.6196, 33.315],
            [-96.6123, 33.262],
            [-96.479, 33.2741],
            [-96.4661, 33.2492],
            [-96.5414, 33.1965],
            [-96.5516, 33.0964],
            [-96.6286, 33.1295],
            [-96.6981, 33.1593],
            [-96.7329, 33.2036],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829850',
        NAME: 'McKinney Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275998943,
        AWATER: 5859368,
        INTPTLAT: '+33.2171135',
        INTPTLON: '-096.6150564',
        ELSDLEA: '',
        UNSDLEA: '29850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4661, 33.2492],
            [-96.4399, 33.2342],
            [-96.4217, 33.1672],
            [-96.4686, 33.0823],
            [-96.4717, 33.0301],
            [-96.5403, 33.0781],
            [-96.5516, 33.0964],
            [-96.5414, 33.1965],
            [-96.4661, 33.2492],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835850',
        NAME: 'Princeton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122779924,
        AWATER: 42223441,
        INTPTLAT: '+33.1414906',
        INTPTLON: '-096.4960024',
        ELSDLEA: '',
        UNSDLEA: '35850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4731, 33.311],
            [-96.4238, 33.3814],
            [-96.4025, 33.3885],
            [-96.3229, 33.3486],
            [-96.2953, 33.2945],
            [-96.2953, 33.2934],
            [-96.315, 33.2541],
            [-96.4399, 33.2342],
            [-96.4661, 33.2492],
            [-96.479, 33.2741],
            [-96.4731, 33.311],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810590',
        NAME: 'Blue Ridge Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166847988,
        AWATER: 1260050,
        INTPTLAT: '+33.3017359',
        INTPTLON: '-096.3969850',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5516, 33.0964],
            [-96.5403, 33.0781],
            [-96.577, 33.07],
            [-96.6029, 33.0754],
            [-96.6286, 33.1295],
            [-96.5516, 33.0964],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828380',
        NAME: 'Lovejoy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43019378,
        AWATER: 1045388,
        INTPTLAT: '+33.1145654',
        INTPTLON: '-096.5914873',
        ELSDLEA: '',
        UNSDLEA: '28380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8922, 33.2884],
            [-96.8878, 33.2906],
            [-96.7058, 33.2764],
            [-96.7329, 33.2036],
            [-96.8913, 33.2194],
            [-96.8922, 33.2884],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836000',
        NAME: 'Prosper Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150981011,
        AWATER: 1823832,
        INTPTLAT: '+33.2428776',
        INTPTLON: '-096.7881329',
        ELSDLEA: '',
        UNSDLEA: '36000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.406, 32.9819],
            [-96.2735, 33.0324],
            [-96.2077, 32.964],
            [-96.2973, 32.8671],
            [-96.3082, 32.8142],
            [-96.406, 32.9819],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838220',
        NAME: 'Royse City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193438629,
        AWATER: 1674523,
        INTPTLAT: '+32.9420515',
        INTPTLON: '-096.3116602',
        ELSDLEA: '',
        UNSDLEA: '38220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4717, 33.0301],
            [-96.4686, 33.0823],
            [-96.2962, 33.1005],
            [-96.2735, 33.0324],
            [-96.406, 32.9819],
            [-96.4439, 32.9824],
            [-96.4717, 33.0301],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814850',
        NAME: 'Community Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172724960,
        AWATER: 16050859,
        INTPTLAT: '+33.0458186',
        INTPTLON: '-096.3785971',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8437, 33.0121],
            [-96.8419, 33.0831],
            [-96.7334, 33.1008],
            [-96.6029, 33.0754],
            [-96.577, 33.07],
            [-96.6134, 32.984],
            [-96.6652, 32.9852],
            [-96.8034, 32.987],
            [-96.844, 32.9876],
            [-96.8437, 33.0121],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835100',
        NAME: 'Plano Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247746729,
        AWATER: 1227835,
        INTPTLAT: '+33.0361785',
        INTPTLON: '-096.7224334',
        ELSDLEA: '',
        UNSDLEA: '35100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5487, 28.938],
            [-95.4771, 29.0492],
            [-95.5104, 29.0706],
            [-95.489, 29.1111],
            [-95.3377, 29.0517],
            [-95.1707, 29.1103],
            [-95.124, 29.0803],
            [-95.0916, 29.0627],
            [-95.0943, 29.0322],
            [-95.3221, 28.8344],
            [-95.4014, 28.8059],
            [-95.453, 28.8783],
            [-95.5487, 28.938],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811190',
        NAME: 'Brazosport Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467600263,
        AWATER: 368615755,
        INTPTLAT: '+28.9751191',
        INTPTLON: '-095.3254191',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3522, 29.2986],
            [-95.3475, 29.2932],
            [-95.3247, 29.1783],
            [-95.4551, 29.2807],
            [-95.3522, 29.2986],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816290',
        NAME: 'Danbury Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164893021,
        AWATER: 7340932,
        INTPTLAT: '+29.2150165',
        INTPTLON: '-095.3349791',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4241, 29.5802],
            [-95.3881, 29.5857],
            [-95.3834, 29.5271],
            [-95.2255, 29.5018],
            [-95.2255, 29.4541],
            [-95.1034, 29.2714],
            [-95.0931, 29.256],
            [-95.1785, 29.2992],
            [-95.26, 29.2236],
            [-95.3475, 29.2932],
            [-95.3522, 29.2986],
            [-95.3485, 29.3534],
            [-95.4611, 29.3597],
            [-95.4625, 29.4392],
            [-95.4241, 29.5802],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808090',
        NAME: 'Alvin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 648495027,
        AWATER: 2994463,
        INTPTLAT: '+29.4002747',
        INTPTLON: '-095.3132021',
        ELSDLEA: '',
        UNSDLEA: '08090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8477, 29.2626],
            [-95.7529, 29.3245],
            [-95.6533, 29.2935],
            [-95.7717, 29.207],
            [-95.8419, 29.2587],
            [-95.8477, 29.2626],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816260',
        NAME: 'Damon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157887298,
        AWATER: 2209906,
        INTPTLAT: '+29.2573279',
        INTPTLON: '-095.7267579',
        ELSDLEA: '',
        UNSDLEA: '16260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6874, 31.8707],
            [-97.4094, 31.8796],
            [-97.3753, 31.8984],
            [-97.2899, 31.8454],
            [-97.3046, 31.8045],
            [-97.5077, 31.7762],
            [-97.5883, 31.7163],
            [-97.6354, 31.6317],
            [-97.6947, 31.6655],
            [-97.7333, 31.6891],
            [-97.6874, 31.8707],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814400',
        NAME: 'Clifton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559433459,
        AWATER: 9288082,
        INTPTLAT: '+31.7883717',
        INTPTLON: '-097.5547163',
        ELSDLEA: '',
        UNSDLEA: '14400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7728, 31.9902],
            [-97.7085, 32.008],
            [-97.605, 31.9424],
            [-97.4388, 31.9335],
            [-97.3753, 31.8984],
            [-97.4094, 31.8796],
            [-97.6874, 31.8707],
            [-97.7979, 31.8669],
            [-97.7728, 31.9902],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830300',
        NAME: 'Meridian Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 367256595,
        AWATER: 7153914,
        INTPTLAT: '+31.9148383',
        INTPTLON: '-097.6290577',
        ELSDLEA: '',
        UNSDLEA: '30300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6354, 31.6317],
            [-97.5883, 31.7163],
            [-97.5077, 31.7762],
            [-97.3046, 31.8045],
            [-97.3017, 31.7571],
            [-97.3955, 31.6605],
            [-97.4624, 31.5582],
            [-97.6052, 31.5878],
            [-97.6354, 31.6317],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843760',
        NAME: 'Valley Mills Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455211202,
        AWATER: 2527037,
        INTPTLAT: '+31.6897344',
        INTPTLON: '-097.4866571',
        ELSDLEA: '',
        UNSDLEA: '43760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3017, 31.7571],
            [-97.2773, 31.7455],
            [-97.1883, 31.677],
            [-97.2448, 31.6017],
            [-97.2794, 31.6014],
            [-97.3414, 31.6097],
            [-97.3955, 31.6605],
            [-97.3017, 31.7571],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813960',
        NAME: 'China Spring Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183438165,
        AWATER: 2644433,
        INTPTLAT: '+31.6674476',
        INTPTLON: '-097.2908241',
        ELSDLEA: '',
        UNSDLEA: '13960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6486, 32.0569],
            [-97.5748, 32.0453],
            [-97.5182, 31.9678],
            [-97.4257, 31.9433],
            [-97.4388, 31.9335],
            [-97.605, 31.9424],
            [-97.7085, 32.008],
            [-97.6486, 32.0569],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831380',
        NAME: 'Morgan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158893923,
        AWATER: 2601374,
        INTPTLAT: '+31.9907989',
        INTPTLON: '-097.5733496',
        ELSDLEA: '',
        UNSDLEA: '31380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7832, 29.8087],
            [-97.7111, 29.801],
            [-97.6844, 29.7245],
            [-97.77, 29.7034],
            [-97.8002, 29.7659],
            [-97.7832, 29.8087],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835700',
        NAME: 'Prairie Lea Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94533441,
        AWATER: 748627,
        INTPTLAT: '+29.7478807',
        INTPTLON: '-097.7357086',
        ELSDLEA: '',
        UNSDLEA: '35700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9853, 32.1579],
            [-94.8927, 32.0988],
            [-94.8923, 32.0341],
            [-94.9854, 31.9957],
            [-95.0732, 32.066],
            [-95.0812, 32.1374],
            [-94.9853, 32.1579],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812870',
        NAME: 'Carlisle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198778059,
        AWATER: 656774,
        INTPTLAT: '+32.0963854',
        INTPTLON: '-094.9741887',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4495, 31.989],
            [-95.4325, 32.0596],
            [-95.2396, 32.0926],
            [-95.168, 32.0639],
            [-95.164, 31.999],
            [-95.1453, 31.9371],
            [-95.1804, 31.8594],
            [-95.3271, 31.853],
            [-95.404, 31.8029],
            [-95.4033, 31.821],
            [-95.4377, 31.8713],
            [-95.4495, 31.989],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824590',
        NAME: 'Jacksonville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669112351,
        AWATER: 7037531,
        INTPTLAT: '+31.9540733',
        INTPTLON: '-095.2964629',
        ELSDLEA: '',
        UNSDLEA: '24590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9524, 34.2125],
            [-98.9234, 34.1863],
            [-98.7508, 34.1242],
            [-98.7506, 34.0687],
            [-98.7839, 34.0386],
            [-98.7962, 33.8983],
            [-98.9531, 33.834],
            [-98.9524, 34.2125],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818330',
        NAME: 'Electra Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556480507,
        AWATER: 809230,
        INTPTLAT: '+34.0121584',
        INTPTLON: '-098.8640415',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6375, 33.8973],
            [-98.5753, 33.9077],
            [-98.5282, 33.9739],
            [-98.4231, 33.9444],
            [-98.423, 33.9114],
            [-98.4236, 33.836],
            [-98.5191, 33.8357],
            [-98.6376, 33.835],
            [-98.6375, 33.8973],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845780',
        NAME: 'Wichita Falls Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208003757,
        AWATER: 4485246,
        INTPTLAT: '+33.8884790',
        INTPTLON: '-098.5147576',
        ELSDLEA: '',
        UNSDLEA: '45780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4732, 31.7495],
            [-105.1546, 31.7483],
            [-105.1548, 31.7053],
            [-105.1679, 31.5351],
            [-105.1677, 30.8305],
            [-105.214, 30.8022],
            [-105.3867, 30.8533],
            [-105.5215, 30.9753],
            [-105.4911, 30.9956],
            [-105.4985, 31.14],
            [-105.4916, 31.5469],
            [-105.4732, 31.7495],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840200',
        NAME: 'Sierra Blanca Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3096212023,
        AWATER: 251039,
        INTPTLAT: '+31.2953252',
        INTPTLON: '-105.2766590',
        ELSDLEA: '',
        UNSDLEA: '40200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7672, 31.6517],
            [-102.318, 31.6513],
            [-102.3158, 31.4909],
            [-102.3012, 31.0862],
            [-102.3888, 31.0872],
            [-102.404, 31.0876],
            [-102.4301, 31.1939],
            [-102.5112, 31.2595],
            [-102.6027, 31.2761],
            [-102.6732, 31.3281],
            [-102.7674, 31.2938],
            [-102.7674, 31.5157],
            [-102.7672, 31.6517],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815540',
        NAME: 'Crane Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2033319717,
        AWATER: 1695980,
        INTPTLAT: '+31.4227965',
        INTPTLON: '-102.4877739',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0479, 33.7962],
            [-102.8643, 33.8248],
            [-102.6416, 33.825],
            [-102.6161, 33.6802],
            [-102.8209, 33.6652],
            [-102.8227, 33.5267],
            [-102.8698, 33.5271],
            [-102.8661, 33.6802],
            [-102.9113, 33.719],
            [-103.05, 33.7147],
            [-103.0479, 33.7962],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831470',
        NAME: 'Morton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 598854831,
        AWATER: 0,
        INTPTLAT: '+33.7313399',
        INTPTLON: '-102.8148111',
        ELSDLEA: '',
        UNSDLEA: '31470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0526, 33.5706],
            [-103.05, 33.7147],
            [-102.9113, 33.719],
            [-102.8661, 33.6802],
            [-102.8698, 33.5271],
            [-102.8227, 33.5267],
            [-102.8209, 33.6652],
            [-102.6161, 33.6802],
            [-102.6416, 33.825],
            [-102.6155, 33.8733],
            [-102.5122, 33.825],
            [-102.5147, 33.7563],
            [-102.6122, 33.757],
            [-102.6067, 33.6418],
            [-102.5072, 33.6408],
            [-102.5228, 33.5276],
            [-102.6012, 33.5284],
            [-102.5948, 33.3885],
            [-103.0565, 33.3884],
            [-103.0526, 33.5706],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845570',
        NAME: 'Whiteface Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1578500470,
        AWATER: 606254,
        INTPTLAT: '+33.5767410',
        INTPTLON: '-102.8053614',
        ELSDLEA: '',
        UNSDLEA: '45570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1071, 32.483],
            [-94.0429, 32.3923],
            [-94.0428, 32.2813],
            [-94.2047, 32.2696],
            [-94.2772, 32.3449],
            [-94.2861, 32.4478],
            [-94.1071, 32.483],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818480',
        NAME: 'Elysian Fields Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371720610,
        AWATER: 400404,
        INTPTLAT: '+32.3721406',
        INTPTLON: '-094.1599697',
        ELSDLEA: '',
        UNSDLEA: '18480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7018, 32.6522],
            [-94.7005, 32.6535],
            [-94.5076, 32.631],
            [-94.4831, 32.4835],
            [-94.4985, 32.3967],
            [-94.5792, 32.3949],
            [-94.702, 32.4218],
            [-94.7018, 32.6522],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822170',
        NAME: 'Hallsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468836263,
        AWATER: 6157216,
        INTPTLAT: '+32.5146502',
        INTPTLON: '-094.5995051',
        ELSDLEA: '',
        UNSDLEA: '22170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5076, 32.631],
            [-94.3926, 32.6944],
            [-94.332, 32.7148],
            [-94.2432, 32.5795],
            [-94.1768, 32.574],
            [-94.1071, 32.483],
            [-94.2861, 32.4478],
            [-94.2772, 32.3449],
            [-94.3476, 32.3307],
            [-94.404, 32.3698],
            [-94.4985, 32.3967],
            [-94.4831, 32.4835],
            [-94.5076, 32.631],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829160',
        NAME: 'Marshall Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 898046501,
        AWATER: 9026067,
        INTPTLAT: '+32.5201457',
        INTPTLON: '-094.3568922',
        ELSDLEA: '',
        UNSDLEA: '29160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8193, 32.782],
            [-94.7021, 32.7767],
            [-94.7005, 32.6535],
            [-94.7018, 32.6522],
            [-94.7431, 32.6652],
            [-94.8468, 32.7233],
            [-94.8193, 32.782],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832460',
        NAME: 'New Diana Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135601213,
        AWATER: 1105371,
        INTPTLAT: '+32.7197646',
        INTPTLON: '-094.7590115',
        ELSDLEA: '',
        UNSDLEA: '32460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.332, 32.7148],
            [-94.2428, 32.7507],
            [-94.1316, 32.7385],
            [-94.0431, 32.693],
            [-94.0428, 32.5754],
            [-94.1768, 32.574],
            [-94.2432, 32.5795],
            [-94.332, 32.7148],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825110',
        NAME: 'Karnack Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337836089,
        AWATER: 24488359,
        INTPTLAT: '+32.6511499',
        INTPTLON: '-094.1683122',
        ELSDLEA: '',
        UNSDLEA: '25110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.138, 31.5414],
            [-96.0159, 31.555],
            [-96.0173, 31.6164],
            [-95.9408, 31.6114],
            [-95.881, 31.5614],
            [-95.8762, 31.4875],
            [-95.819, 31.4375],
            [-95.805, 31.3394],
            [-95.9505, 31.2913],
            [-96.0659, 31.3463],
            [-96.1707, 31.4437],
            [-96.0919, 31.4798],
            [-96.138, 31.5414],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811990',
        NAME: 'Buffalo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 689138934,
        AWATER: 3655105,
        INTPTLAT: '+31.4575792',
        INTPTLON: '-095.9825883',
        ELSDLEA: '',
        UNSDLEA: '11990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4874, 32.1691],
            [-96.4058, 32.1949],
            [-96.3489, 32.2671],
            [-96.2959, 32.2934],
            [-96.2856, 32.2669],
            [-96.4064, 32.07],
            [-96.4058, 31.9542],
            [-96.3337, 31.9435],
            [-96.3436, 31.8341],
            [-96.4105, 31.8871],
            [-96.5199, 31.8751],
            [-96.4793, 31.9646],
            [-96.6173, 32.0125],
            [-96.5371, 32.0716],
            [-96.5695, 32.1432],
            [-96.4874, 32.1691],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815330',
        NAME: 'Corsicana Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 562156335,
        AWATER: 25076679,
        INTPTLAT: '+32.0403733',
        INTPTLON: '-096.4508205',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.676, 31.6673],
            [-96.5911, 31.7264],
            [-96.5761, 31.803],
            [-96.5541, 31.8012],
            [-96.4522, 31.7314],
            [-96.3377, 31.5632],
            [-96.4541, 31.5356],
            [-96.676, 31.6673],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830420',
        NAME: 'Mexia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430497224,
        AWATER: 10686660,
        INTPTLAT: '+31.6586238',
        INTPTLON: '-096.4953249',
        ELSDLEA: '',
        UNSDLEA: '30420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4522, 31.7314],
            [-96.2522, 31.746],
            [-96.1957, 31.7171],
            [-96.1896, 31.6341],
            [-96.138, 31.5414],
            [-96.0919, 31.4798],
            [-96.1707, 31.4437],
            [-96.2366, 31.4134],
            [-96.3377, 31.5632],
            [-96.4522, 31.7314],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842300',
        NAME: 'Teague Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579299976,
        AWATER: 1450736,
        INTPTLAT: '+31.5983225',
        INTPTLON: '-096.2610121',
        ELSDLEA: '',
        UNSDLEA: '42300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3308, 29.0335],
            [-98.1298, 29.0707],
            [-98.0963, 29.1247],
            [-97.991, 29.1558],
            [-97.8182, 29.156],
            [-97.8385, 29.1411],
            [-98.191, 28.8823],
            [-98.3308, 29.0335],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835550',
        NAME: 'Poth Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 546622759,
        AWATER: 4062985,
        INTPTLAT: '+29.0332424',
        INTPTLON: '-098.0993546',
        ELSDLEA: '',
        UNSDLEA: '35550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1211, 29.449],
            [-98.0735, 29.4616],
            [-98.002, 29.3789],
            [-98.0041, 29.2993],
            [-98.1782, 29.2641],
            [-98.2493, 29.3039],
            [-98.1211, 29.449],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826310',
        NAME: 'La Vernia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292828936,
        AWATER: 897850,
        INTPTLAT: '+29.3549312',
        INTPTLON: '-098.1182598',
        ELSDLEA: '',
        UNSDLEA: '26310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.358, 29.8875],
            [-94.3026, 29.9831],
            [-94.2338, 30.0011],
            [-94.0464, 29.8804],
            [-94.0337, 29.8821],
            [-94.0777, 29.747],
            [-94.1563, 29.6929],
            [-94.2283, 29.7285],
            [-94.3555, 29.7286],
            [-94.358, 29.8875],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822320',
        NAME: 'Hamshire-Fannett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 652376122,
        AWATER: 36686001,
        INTPTLAT: '+29.8378899',
        INTPTLON: '-094.2075295',
        ELSDLEA: '',
        UNSDLEA: '22320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3946, 30.2761],
            [-94.2627, 30.276],
            [-94.2463, 30.168],
            [-94.2338, 30.0011],
            [-94.3026, 29.9831],
            [-94.358, 29.8875],
            [-94.4422, 29.8895],
            [-94.4449, 30.113],
            [-94.5399, 30.1119],
            [-94.5469, 30.1119],
            [-94.4913, 30.1438],
            [-94.5009, 30.2325],
            [-94.3946, 30.2761],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822410',
        NAME: 'Hardin-Jefferson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 771109330,
        AWATER: 5648800,
        INTPTLAT: '+30.0953093',
        INTPTLON: '-094.3775771',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9693, 30.0125],
            [-93.9243, 29.9933],
            [-93.8399, 29.9197],
            [-93.9696, 29.9515],
            [-93.9693, 30.0125],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835430',
        NAME: 'Port Neches-Groves Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54285363,
        AWATER: 15974763,
        INTPTLAT: '+29.9640993',
        INTPTLON: '-093.9093145',
        ELSDLEA: '',
        UNSDLEA: '35430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5506, 28.9974],
            [-98.5109, 28.895],
            [-98.4461, 28.8768],
            [-98.3928, 28.7651],
            [-98.4576, 28.7186],
            [-98.3922, 28.6482],
            [-98.6108, 28.6483],
            [-98.6109, 28.713],
            [-98.6761, 28.7662],
            [-98.6256, 28.9253],
            [-98.6988, 28.9581],
            [-98.6537, 29.026],
            [-98.5506, 28.9974],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824960',
        NAME: 'Jourdanton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 721969494,
        AWATER: 1853988,
        INTPTLAT: '+28.8178961',
        INTPTLON: '-098.5496689',
        ELSDLEA: '',
        UNSDLEA: '24960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5506, 28.9974],
            [-98.5242, 29.1545],
            [-98.4073, 29.1144],
            [-98.3308, 29.0335],
            [-98.191, 28.8823],
            [-98.1573, 28.8491],
            [-98.2533, 28.7532],
            [-98.2018, 28.7107],
            [-98.335, 28.6127],
            [-98.3922, 28.6482],
            [-98.4576, 28.7186],
            [-98.3928, 28.7651],
            [-98.4461, 28.8768],
            [-98.5109, 28.895],
            [-98.5506, 28.9974],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835190',
        NAME: 'Pleasanton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1152974849,
        AWATER: 2026125,
        INTPTLAT: '+28.8832873',
        INTPTLON: '-098.3616910',
        ELSDLEA: '',
        UNSDLEA: '35190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8462, 28.2471],
            [-97.772, 28.22],
            [-97.7883, 28.1366],
            [-97.7029, 28.1318],
            [-97.69, 27.9919],
            [-97.7042, 27.9218],
            [-97.7171, 27.9116],
            [-97.7985, 27.9957],
            [-97.8831, 28.0569],
            [-97.9231, 28.2095],
            [-97.8462, 28.2471],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829400',
        NAME: 'Mathis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403041297,
        AWATER: 40265688,
        INTPTLAT: '+28.0890326',
        INTPTLON: '-097.7995535',
        ELSDLEA: '',
        UNSDLEA: '29400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8647, 28.6877],
            [-97.761, 28.7584],
            [-97.7367, 28.8484],
            [-97.6538, 28.757],
            [-97.7757, 28.6701],
            [-97.7037, 28.5609],
            [-97.7598, 28.469],
            [-97.8686, 28.4967],
            [-97.9058, 28.6732],
            [-97.8647, 28.6877],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834770',
        NAME: 'Pettus Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466575460,
        AWATER: 605280,
        INTPTLAT: '+28.6166565',
        INTPTLON: '-097.7524790',
        ELSDLEA: '',
        UNSDLEA: '34770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9144, 28.3639],
            [-97.8449, 28.3883],
            [-97.7511, 28.3272],
            [-97.689, 28.3559],
            [-97.5117, 28.3244],
            [-97.496, 28.2251],
            [-97.5411, 28.1647],
            [-97.5676, 28.1297],
            [-97.7029, 28.1318],
            [-97.7883, 28.1366],
            [-97.772, 28.22],
            [-97.8462, 28.2471],
            [-97.9144, 28.3639],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840410',
        NAME: 'Skidmore-Tynan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 650962129,
        AWATER: 63565,
        INTPTLAT: '+28.2492713',
        INTPTLON: '-097.6759780',
        ELSDLEA: '',
        UNSDLEA: '40410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6981, 33.1593],
            [-96.6286, 33.1295],
            [-96.6029, 33.0754],
            [-96.7334, 33.1008],
            [-96.6981, 33.1593],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807890',
        NAME: 'Allen Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75773782,
        AWATER: 249735,
        INTPTLAT: '+33.1087535',
        INTPTLON: '-096.6707303',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6452, 33.3774],
            [-96.4529, 33.3988],
            [-96.4238, 33.3814],
            [-96.4731, 33.311],
            [-96.6196, 33.315],
            [-96.6347, 33.3432],
            [-96.6452, 33.3774],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808340',
        NAME: 'Anna Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157639400,
        AWATER: 1692998,
        INTPTLAT: '+33.3555000',
        INTPTLON: '-096.5163128',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8364, 33.5009],
            [-96.8255, 33.5162],
            [-96.7724, 33.5199],
            [-96.6616, 33.4384],
            [-96.6565, 33.4013],
            [-96.8341, 33.4055],
            [-96.8348, 33.4055],
            [-96.8364, 33.5009],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821960',
        NAME: 'Gunter Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170751108,
        AWATER: 1411521,
        INTPTLAT: '+33.4562425',
        INTPTLON: '-096.7561663',
        ELSDLEA: '',
        UNSDLEA: '21960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3229, 33.3486],
            [-96.2707, 33.4382],
            [-96.1388, 33.3894],
            [-96.1573, 33.3702],
            [-96.2953, 33.2945],
            [-96.3229, 33.3486],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827210',
        NAME: 'Leonard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168044231,
        AWATER: 1347065,
        INTPTLAT: '+33.3822516',
        INTPTLON: '-096.2493230',
        ELSDLEA: '',
        UNSDLEA: '27210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6196, 33.315],
            [-96.4731, 33.311],
            [-96.479, 33.2741],
            [-96.6123, 33.262],
            [-96.6196, 33.315],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830120',
        NAME: 'Melissa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80031689,
        AWATER: 1294065,
        INTPTLAT: '+33.2798334',
        INTPTLON: '-096.5490239',
        ELSDLEA: '',
        UNSDLEA: '30120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6616, 33.4384],
            [-96.5561, 33.4948],
            [-96.448, 33.4497],
            [-96.4529, 33.3988],
            [-96.6452, 33.3774],
            [-96.6565, 33.4013],
            [-96.6616, 33.4384],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843890',
        NAME: 'Van Alstyne Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164388174,
        AWATER: 986658,
        INTPTLAT: '+33.4347904',
        INTPTLON: '-096.5628138',
        ELSDLEA: '',
        UNSDLEA: '43890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4496, 33.5742],
            [-96.3826, 33.5595],
            [-96.3123, 33.55],
            [-96.2907, 33.5227],
            [-96.2937, 33.472],
            [-96.4023, 33.4311],
            [-96.4025, 33.3885],
            [-96.4238, 33.3814],
            [-96.4529, 33.3988],
            [-96.448, 33.4497],
            [-96.4496, 33.5742],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845670',
        NAME: 'Whitewright Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191687739,
        AWATER: 421533,
        INTPTLAT: '+33.4863236',
        INTPTLON: '-096.3959301',
        ELSDLEA: '',
        UNSDLEA: '45670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.577, 33.07],
            [-96.5403, 33.0781],
            [-96.4717, 33.0301],
            [-96.4439, 32.9824],
            [-96.5169, 32.9823],
            [-96.6134, 32.984],
            [-96.577, 33.07],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846530',
        NAME: 'Wylie Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 90262843,
        AWATER: 15127783,
        INTPTLAT: '+33.0201832',
        INTPTLON: '-096.5332922',
        ELSDLEA: '',
        UNSDLEA: '46530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5645, 34.0213],
            [-101.3925, 34.0224],
            [-101.3093, 34.084],
            [-101.3449, 34.186],
            [-101.3468, 34.3043],
            [-101.2233, 34.3123],
            [-101.1714, 34.3123],
            [-101.1716, 34.2466],
            [-101.19, 34.1914],
            [-101.0413, 34.1778],
            [-101.0412, 33.8336],
            [-101.3008, 33.8325],
            [-101.4311, 33.8316],
            [-101.4834, 33.8311],
            [-101.4808, 33.9107],
            [-101.5638, 33.9101],
            [-101.5645, 34.0213],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819440',
        NAME: 'Floydada Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1650406739,
        AWATER: 817655,
        INTPTLAT: '+34.0147239',
        INTPTLON: '-101.2523446',
        ELSDLEA: '',
        UNSDLEA: '19440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5649, 34.3125],
            [-101.4716, 34.3123],
            [-101.2233, 34.3123],
            [-101.3468, 34.3043],
            [-101.3449, 34.186],
            [-101.3093, 34.084],
            [-101.3925, 34.0224],
            [-101.5645, 34.0213],
            [-101.5649, 34.3125],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827890',
        NAME: 'Lockney Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 652240202,
        AWATER: 123506,
        INTPTLAT: '+34.1781133',
        INTPTLON: '-101.4415213',
        ELSDLEA: '',
        UNSDLEA: '27890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1714, 34.3123],
            [-101.1545, 34.4068],
            [-101.1018, 34.4791],
            [-100.9456, 34.4918],
            [-100.9452, 34.6285],
            [-100.8067, 34.5637],
            [-100.6534, 34.5319],
            [-100.6361, 34.3989],
            [-100.6827, 34.3134],
            [-101.0415, 34.3124],
            [-101.0415, 34.2563],
            [-101.1716, 34.2466],
            [-101.1714, 34.3123],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843400',
        NAME: 'Turkey-Quitaque Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1153797307,
        AWATER: 8110655,
        INTPTLAT: '+34.4166577',
        INTPTLON: '-100.8981253',
        ELSDLEA: '',
        UNSDLEA: '43400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0281, 30.0663],
            [-93.9472, 30.0874],
            [-93.9227, 30.1526],
            [-93.8394, 30.1656],
            [-93.7852, 30.0891],
            [-93.9131, 30.0497],
            [-93.9243, 29.9933],
            [-93.9693, 30.0125],
            [-93.9991, 30.0257],
            [-94.0281, 30.0663],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833780',
        NAME: 'Orangefield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156324475,
        AWATER: 29194786,
        INTPTLAT: '+30.0389560',
        INTPTLON: '-093.9693474',
        ELSDLEA: '',
        UNSDLEA: '33780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7852, 30.0891],
            [-93.7445, 30.0193],
            [-93.8544, 29.8651],
            [-93.8399, 29.9197],
            [-93.9243, 29.9933],
            [-93.9131, 30.0497],
            [-93.7852, 30.0891],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811310',
        NAME: 'Bridge City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111980211,
        AWATER: 60017055,
        INTPTLAT: '+30.0144397',
        INTPTLON: '-093.8282407',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7131, 30.1072],
            [-93.7225, 30.0509],
            [-93.7445, 30.0193],
            [-93.7852, 30.0891],
            [-93.8394, 30.1656],
            [-93.7131, 30.1072],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845090',
        NAME: 'West Orange-Cove Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79602999,
        AWATER: 8235915,
        INTPTLAT: '+30.0981203',
        INTPTLON: '-093.7705905',
        ELSDLEA: '',
        UNSDLEA: '45090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8291, 31.1059],
            [-96.7062, 31.2453],
            [-96.6366, 31.2798],
            [-96.5969, 31.2209],
            [-96.4628, 31.2294],
            [-96.5666, 31.1376],
            [-96.5813, 31.0745],
            [-96.7653, 31.0451],
            [-96.8291, 31.1059],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811250',
        NAME: 'Bremond Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470213258,
        AWATER: 6870842,
        INTPTLAT: '+31.1452221',
        INTPTLON: '-096.6360151',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0176, 31.4109],
            [-96.843, 31.4623],
            [-96.7347, 31.4255],
            [-96.6366, 31.2798],
            [-96.7062, 31.2453],
            [-96.8291, 31.1059],
            [-96.9226, 31.2522],
            [-96.9623, 31.2755],
            [-97.0069, 31.4068],
            [-97.0176, 31.4109],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829130',
        NAME: 'Marlin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 775868790,
        AWATER: 10189167,
        INTPTLAT: '+31.3082212',
        INTPTLON: '-096.8245963',
        ELSDLEA: '',
        UNSDLEA: '29130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1626, 31.1756],
            [-97.0657, 31.1274],
            [-97.1356, 31.0791],
            [-97.1668, 31.123],
            [-97.1626, 31.1756],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845330',
        NAME: 'Westphalia Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57109025,
        AWATER: 606896,
        INTPTLAT: '+31.1267049',
        INTPTLON: '-097.1208172',
        ELSDLEA: '',
        UNSDLEA: '45330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1817, 31.4259],
            [-97.1084, 31.3655],
            [-97.1611, 31.329],
            [-97.2983, 31.3698],
            [-97.3232, 31.4051],
            [-97.2423, 31.4782],
            [-97.1817, 31.4259],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828200',
        NAME: 'Lorena Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157159172,
        AWATER: 1275700,
        INTPTLAT: '+31.3970433',
        INTPTLON: '-097.2198057',
        ELSDLEA: '',
        UNSDLEA: '28200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1611, 31.329],
            [-97.1084, 31.3655],
            [-97.0069, 31.4068],
            [-96.9623, 31.2755],
            [-97.0953, 31.2603],
            [-97.1608, 31.2199],
            [-97.1611, 31.329],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813920',
        NAME: 'Chilton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227050415,
        AWATER: 3337976,
        INTPTLAT: '+31.3108063',
        INTPTLON: '-097.0729062',
        ELSDLEA: '',
        UNSDLEA: '13920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2416, 30.0513],
            [-98.2211, 30.1196],
            [-98.128, 30.0797],
            [-98.0507, 30.0918],
            [-98.0099, 29.9645],
            [-98.1516, 29.9343],
            [-98.2416, 30.0513],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800009',
        NAME: 'Wimberley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347058297,
        AWATER: 139454,
        INTPTLAT: '+30.0256976',
        INTPTLON: '-098.1175426',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8856, 30.495],
            [-93.7147, 30.4916],
            [-93.7417, 30.403],
            [-93.7643, 30.3302],
            [-93.707, 30.2437],
            [-93.9015, 30.2427],
            [-93.8856, 30.495],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817010',
        NAME: 'Deweyville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440332374,
        AWATER: 1290115,
        INTPTLAT: '+30.3721326',
        INTPTLON: '-093.8058216',
        ELSDLEA: '',
        UNSDLEA: '17010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3575, 27.4928],
            [-98.2332, 27.5009],
            [-98.0591, 27.4697],
            [-98.059, 27.4508],
            [-98.0584, 27.3278],
            [-98.2327, 27.3203],
            [-98.3103, 27.3402],
            [-98.3012, 27.4227],
            [-98.3575, 27.4928],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835760',
        NAME: 'Premont Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 437589036,
        AWATER: 222992,
        INTPTLAT: '+27.4062417',
        INTPTLON: '-098.1884379',
        ELSDLEA: '',
        UNSDLEA: '35760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2337, 27.6451],
            [-98.0575, 27.6885],
            [-98.0119, 27.6357],
            [-98.0598, 27.6359],
            [-98.0591, 27.4697],
            [-98.2332, 27.5009],
            [-98.2337, 27.6451],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809870',
        NAME: 'Ben Bolt-Palito Blanco Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357231702,
        AWATER: 84375,
        INTPTLAT: '+27.5784695',
        INTPTLON: '-098.1215247',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7722, 33.675],
            [-97.7482, 33.704],
            [-97.6504, 33.6815],
            [-97.6256, 33.6487],
            [-97.7152, 33.6217],
            [-97.7722, 33.675],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831200',
        NAME: 'Montague Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65495522,
        AWATER: 176951,
        INTPTLAT: '+33.6589773',
        INTPTLON: '-097.6854552',
        ELSDLEA: '',
        UNSDLEA: '31200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3085, 29.6267],
            [-98.9838, 29.6234],
            [-98.9742, 29.5519],
            [-98.9938, 29.3784],
            [-99.026, 29.2655],
            [-99.0777, 29.0909],
            [-99.1745, 28.9605],
            [-99.2741, 29.0006],
            [-99.2532, 29.0915],
            [-99.1798, 29.2047],
            [-99.2188, 29.3114],
            [-99.2368, 29.488],
            [-99.3092, 29.6267],
            [-99.3085, 29.6267],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823430',
        NAME: 'Hondo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1382705150,
        AWATER: 7780028,
        INTPTLAT: '+29.3545179',
        INTPTLON: '-099.1329878',
        ELSDLEA: '',
        UNSDLEA: '23430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9742, 29.5519],
            [-98.9129, 29.5774],
            [-98.8058, 29.5809],
            [-98.8049, 29.4039],
            [-98.7102, 29.4071],
            [-98.7178, 29.3433],
            [-98.8046, 29.3233],
            [-98.8048, 29.2507],
            [-98.8237, 29.2504],
            [-98.9254, 29.246],
            [-99.026, 29.2655],
            [-98.9938, 29.3784],
            [-98.9742, 29.5519],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830060',
        NAME: 'Medina Valley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 744508595,
        AWATER: 9332652,
        INTPTLAT: '+29.4041112',
        INTPTLON: '-098.8888474',
        ELSDLEA: '',
        UNSDLEA: '30060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6291, 35.5173],
            [-100.5401, 35.5614],
            [-100.5406, 35.6783],
            [-100.4514, 35.6489],
            [-100.3085, 35.6694],
            [-100.309, 35.7451],
            [-100.2013, 35.7162],
            [-100.0588, 35.7173],
            [-100.0004, 35.7462],
            [-100.0004, 35.5525],
            [-99.9997, 35.4284],
            [-100.0766, 35.43],
            [-100.0759, 35.5464],
            [-100.1832, 35.5465],
            [-100.3254, 35.5026],
            [-100.432, 35.4004],
            [-100.5388, 35.3883],
            [-100.5391, 35.4314],
            [-100.629, 35.4883],
            [-100.6291, 35.5173],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811400',
        NAME: 'Fort Elliott Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1275115246,
        AWATER: 6267368,
        INTPTLAT: '+35.5719733',
        INTPTLON: '-100.2690814',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.883, 35.446],
            [-100.8072, 35.4903],
            [-100.629, 35.4883],
            [-100.5391, 35.4314],
            [-100.744, 35.3331],
            [-100.8846, 35.3333],
            [-100.883, 35.446],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827090',
        NAME: 'Lefors Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370970964,
        AWATER: 989296,
        INTPTLAT: '+35.4195354',
        INTPTLON: '-100.7365925',
        ELSDLEA: '',
        UNSDLEA: '27090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0861, 35.6253],
            [-101.086, 35.8234],
            [-101.042, 35.9281],
            [-100.9296, 35.9522],
            [-100.9299, 35.6792],
            [-100.8488, 35.6199],
            [-100.6697, 35.6199],
            [-100.6291, 35.5173],
            [-100.629, 35.4883],
            [-100.8072, 35.4903],
            [-100.883, 35.446],
            [-100.9919, 35.4455],
            [-101.086, 35.4445],
            [-101.0861, 35.6253],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834170',
        NAME: 'Pampa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1171093153,
        AWATER: 2705125,
        INTPTLAT: '+35.6283448',
        INTPTLON: '-100.9278498',
        ELSDLEA: '',
        UNSDLEA: '34170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3552, 35.6247],
            [-101.3283, 35.6248],
            [-101.0861, 35.6253],
            [-101.086, 35.4445],
            [-100.9919, 35.4455],
            [-100.9913, 35.3867],
            [-101.0861, 35.3572],
            [-101.2216, 35.3562],
            [-101.2749, 35.3558],
            [-101.2929, 35.5323],
            [-101.3552, 35.6247],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845450',
        NAME: 'White Deer Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670667522,
        AWATER: 3531085,
        INTPTLAT: '+35.4949953',
        INTPTLON: '-101.1766572',
        ELSDLEA: '',
        UNSDLEA: '45450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6512, 30.0324],
            [-98.5697, 30.0414],
            [-98.6027, 29.9316],
            [-98.4859, 29.876],
            [-98.5584, 29.752],
            [-98.5364, 29.6723],
            [-98.5405, 29.6723],
            [-98.6571, 29.7],
            [-98.8028, 29.6948],
            [-98.9177, 29.7814],
            [-98.8854, 29.8418],
            [-98.7499, 29.8549],
            [-98.7478, 29.9249],
            [-98.6512, 30.0324],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810710',
        NAME: 'Boerne Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795152802,
        AWATER: 1649235,
        INTPTLAT: '+29.8265102',
        INTPTLON: '-098.6639030',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4859, 29.876],
            [-98.2976, 30.038],
            [-98.2416, 30.0513],
            [-98.1516, 29.9343],
            [-98.0305, 29.8485],
            [-97.9993, 29.7524],
            [-98.0585, 29.7077],
            [-98.046, 29.629],
            [-98.0676, 29.6558],
            [-98.1734, 29.8087],
            [-98.2703, 29.7636],
            [-98.2518, 29.6863],
            [-98.1774, 29.6976],
            [-98.1255, 29.6379],
            [-98.1772, 29.606],
            [-98.3109, 29.5945],
            [-98.4201, 29.6771],
            [-98.5364, 29.6723],
            [-98.5584, 29.752],
            [-98.4859, 29.876],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814730',
        NAME: 'Comal Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1482958966,
        AWATER: 39917991,
        INTPTLAT: '+29.8052027',
        INTPTLON: '-098.2872895',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0676, 29.6558],
            [-98.108, 29.616],
            [-98.1255, 29.6379],
            [-98.1774, 29.6976],
            [-98.2518, 29.6863],
            [-98.2703, 29.7636],
            [-98.1734, 29.8087],
            [-98.0676, 29.6558],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832370',
        NAME: 'New Braunfels Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195273568,
        AWATER: 1480331,
        INTPTLAT: '+29.7051090',
        INTPTLON: '-098.1777099',
        ELSDLEA: '',
        UNSDLEA: '32370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8785, 32.7878],
            [-95.7639, 32.7579],
            [-95.8294, 32.6979],
            [-95.8281, 32.6263],
            [-95.8313, 32.6032],
            [-95.8974, 32.6081],
            [-95.943, 32.682],
            [-95.8785, 32.7878],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818120',
        NAME: 'Edgewood Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 193152452,
        AWATER: 1894530,
        INTPTLAT: '+32.6933877',
        INTPTLON: '-095.8649374',
        ELSDLEA: '',
        UNSDLEA: '18120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7601, 32.4761],
            [-95.7022, 32.3771],
            [-95.7773, 32.3563],
            [-95.8331, 32.4161],
            [-95.9542, 32.3859],
            [-95.7851, 32.4933],
            [-95.7601, 32.4761],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829220',
        NAME: 'Martins Mill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164939228,
        AWATER: 2716483,
        INTPTLAT: '+32.4230458',
        INTPTLON: '-095.8065613',
        ELSDLEA: '',
        UNSDLEA: '29220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0758, 32.5657],
            [-95.8974, 32.6081],
            [-95.8313, 32.6032],
            [-95.7895, 32.546],
            [-95.7601, 32.4761],
            [-95.7851, 32.4933],
            [-95.9542, 32.3859],
            [-95.9448, 32.3726],
            [-96.0031, 32.3572],
            [-96.0046, 32.4619],
            [-96.0757, 32.5486],
            [-96.0758, 32.5657],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812750',
        NAME: 'Canton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415533946,
        AWATER: 6046582,
        INTPTLAT: '+32.5025127',
        INTPTLON: '-095.9215086',
        ELSDLEA: '',
        UNSDLEA: '12750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8281, 32.6263],
            [-95.7669, 32.6547],
            [-95.7487, 32.7553],
            [-95.7135, 32.7439],
            [-95.5945, 32.687],
            [-95.6107, 32.6266],
            [-95.7895, 32.546],
            [-95.8313, 32.6032],
            [-95.8281, 32.6263],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821450',
        NAME: 'Grand Saline Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267832375,
        AWATER: 5854748,
        INTPTLAT: '+32.6539699',
        INTPTLON: '-095.7013503',
        ELSDLEA: '',
        UNSDLEA: '21450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4629, 33.9872],
            [-102.2796, 33.9872],
            [-102.2779, 34.103],
            [-102.1971, 34.0878],
            [-102.2255, 33.8669],
            [-102.226, 33.8247],
            [-102.4373, 33.8251],
            [-102.5122, 33.825],
            [-102.6155, 33.8733],
            [-102.4637, 33.9104],
            [-102.4629, 33.9872],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827750',
        NAME: 'Littlefield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 572042124,
        AWATER: 1612514,
        INTPTLAT: '+33.9218169',
        INTPTLON: '-102.3507650',
        ELSDLEA: '',
        UNSDLEA: '27750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7946, 29.2416],
            [-100.7453, 29.2649],
            [-100.6991, 29.4197],
            [-100.6999, 29.6239],
            [-100.5756, 29.6235],
            [-100.1121, 29.6233],
            [-100.1122, 29.4522],
            [-100.1114, 29.0863],
            [-100.6675, 29.0843],
            [-100.7378, 29.1391],
            [-100.7946, 29.2416],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811070',
        NAME: 'Brackett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3522534792,
        AWATER: 13124946,
        INTPTLAT: '+29.3470865',
        INTPTLON: '-100.4177003',
        ELSDLEA: '',
        UNSDLEA: '11070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3343, 28.1615],
            [-98.254, 28.2223],
            [-98.19, 28.3921],
            [-98.1218, 28.4837],
            [-98.0115, 28.5309],
            [-97.9611, 28.444],
            [-97.9144, 28.3639],
            [-97.8462, 28.2471],
            [-97.9231, 28.2095],
            [-97.8831, 28.0569],
            [-98.1136, 28.0576],
            [-98.2355, 28.058],
            [-98.3343, 28.0578],
            [-98.3343, 28.1615],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820550',
        NAME: 'George West Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1543447699,
        AWATER: 33344012,
        INTPTLAT: '+28.2471443',
        INTPTLON: '-098.0792701',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.335, 28.6127],
            [-98.2018, 28.7107],
            [-98.0744, 28.7618],
            [-98.0527, 28.7391],
            [-98.0898, 28.663],
            [-98.0023, 28.5955],
            [-98.0115, 28.5309],
            [-98.1218, 28.4837],
            [-98.19, 28.3921],
            [-98.254, 28.2223],
            [-98.3343, 28.1615],
            [-98.335, 28.6127],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842690',
        NAME: 'Three Rivers Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1106250600,
        AWATER: 48759404,
        INTPTLAT: '+28.5028557',
        INTPTLON: '-098.1928194',
        ELSDLEA: '',
        UNSDLEA: '42690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8237, 29.2504],
            [-98.8048, 29.2507],
            [-98.735, 29.2267],
            [-98.7122, 29.1445],
            [-98.8048, 29.1492],
            [-98.8237, 29.2504],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828650',
        NAME: 'Lytle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112911975,
        AWATER: 260497,
        INTPTLAT: '+29.1838518',
        INTPTLON: '-098.7538716',
        ELSDLEA: '',
        UNSDLEA: '28650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6028, 29.9077],
            [-99.499, 29.9067],
            [-99.451, 29.7347],
            [-99.4101, 29.7362],
            [-99.3535, 29.6272],
            [-99.4122, 29.5607],
            [-99.4126, 29.4565],
            [-99.5216, 29.4613],
            [-99.5883, 29.5732],
            [-99.6945, 29.5801],
            [-99.6698, 29.6921],
            [-99.603, 29.756],
            [-99.6028, 29.9077],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843680',
        NAME: 'Utopia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 916258407,
        AWATER: 1636231,
        INTPTLAT: '+29.6651510',
        INTPTLON: '-099.5231105',
        ELSDLEA: '',
        UNSDLEA: '43680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4126, 29.4565],
            [-99.4122, 29.5607],
            [-99.3535, 29.6272],
            [-99.3092, 29.6267],
            [-99.2368, 29.488],
            [-99.2188, 29.3114],
            [-99.1798, 29.2047],
            [-99.2532, 29.0915],
            [-99.4139, 29.0913],
            [-99.4126, 29.4565],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816140',
        NAME: "D'Hanis Independent School District",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 983048719,
        AWATER: 4722346,
        INTPTLAT: '+29.3342187',
        INTPTLON: '-099.3441397',
        ELSDLEA: '',
        UNSDLEA: '16140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9591, 32.2457],
            [-97.953, 32.2501],
            [-97.9456, 32.2335],
            [-97.8877, 32.1276],
            [-97.9538, 32.0831],
            [-98.0485, 32.1071],
            [-98.083, 32.2042],
            [-97.9591, 32.2457],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842720',
        NAME: 'Three Way Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211653131,
        AWATER: 1899642,
        INTPTLAT: '+32.1718133',
        INTPTLON: '-097.9709555',
        ELSDLEA: '',
        UNSDLEA: '42720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6567, 30.8409],
            [-94.5271, 30.8821],
            [-94.4575, 30.8511],
            [-94.1978, 30.8567],
            [-94.1354, 30.7676],
            [-94.1282, 30.7465],
            [-94.2589, 30.7725],
            [-94.2938, 30.6708],
            [-94.4311, 30.6887],
            [-94.5752, 30.6543],
            [-94.5964, 30.7442],
            [-94.6785, 30.7959],
            [-94.6567, 30.8409],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846440',
        NAME: 'Woodville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795441258,
        AWATER: 12249928,
        INTPTLAT: '+30.7735515',
        INTPTLON: '-094.4203665',
        ELSDLEA: '',
        UNSDLEA: '46440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.404, 31.0876],
            [-102.3888, 31.0872],
            [-102.2927, 31.0367],
            [-102.0817, 30.9867],
            [-101.9845, 30.9874],
            [-101.8743, 30.9122],
            [-101.8345, 30.7579],
            [-101.7684, 30.6531],
            [-102.1378, 30.656],
            [-102.1384, 30.5975],
            [-102.3217, 30.5986],
            [-102.3228, 30.7409],
            [-102.436, 30.7452],
            [-102.4309, 30.9447],
            [-102.404, 31.0876],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824330',
        NAME: 'Iraan-Sheffield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2237161860,
        AWATER: 0,
        INTPTLAT: '+30.8251048',
        INTPTLON: '-102.1469556',
        ELSDLEA: '',
        UNSDLEA: '24330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9448, 32.3726],
            [-95.9542, 32.3859],
            [-95.8331, 32.4161],
            [-95.7773, 32.3563],
            [-95.7801, 32.2892],
            [-95.7406, 32.2511],
            [-95.7045, 32.2127],
            [-95.7329, 32.0444],
            [-95.8613, 32.0288],
            [-95.8602, 32.1733],
            [-95.9567, 32.1677],
            [-95.9868, 32.2277],
            [-95.8942, 32.2921],
            [-95.9448, 32.3726],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808870',
        NAME: 'Athens Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602548062,
        AWATER: 12583099,
        INTPTLAT: '+32.2198653',
        INTPTLON: '-095.8294768',
        ELSDLEA: '',
        UNSDLEA: '08870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7272, 31.6438],
            [-95.6638, 31.7189],
            [-95.5238, 31.7061],
            [-95.538, 31.6277],
            [-95.4525, 31.5701],
            [-95.7393, 31.5041],
            [-95.7272, 31.6438],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818390',
        NAME: 'Elkhart Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417820846,
        AWATER: 4153813,
        INTPTLAT: '+31.6047038',
        INTPTLON: '-095.6220292',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6662, 31.9595],
            [-95.5462, 32.0255],
            [-95.5264, 32.1255],
            [-95.4621, 32.1287],
            [-95.4325, 32.0596],
            [-95.4495, 31.989],
            [-95.5472, 31.9886],
            [-95.6012, 31.9278],
            [-95.6662, 31.9595],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819770',
        NAME: 'Frankston Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218952953,
        AWATER: 15266894,
        INTPTLAT: '+32.0266844',
        INTPTLON: '-095.5256941',
        ELSDLEA: '',
        UNSDLEA: '19770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7045, 32.2127],
            [-95.594, 32.2183],
            [-95.5264, 32.1255],
            [-95.5462, 32.0255],
            [-95.6662, 31.9595],
            [-95.7167, 31.9954],
            [-95.7329, 32.0444],
            [-95.7045, 32.2127],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826220',
        NAME: 'Lapoynor Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384653911,
        AWATER: 5037395,
        INTPTLAT: '+32.1073770',
        INTPTLON: '-095.6449642',
        ELSDLEA: '',
        UNSDLEA: '26220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4495, 31.989],
            [-95.4377, 31.8713],
            [-95.5725, 31.8428],
            [-95.6012, 31.9278],
            [-95.5472, 31.9886],
            [-95.4495, 31.989],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832250',
        NAME: 'Neches Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211542107,
        AWATER: 2488021,
        INTPTLAT: '+31.9058856',
        INTPTLON: '-095.5150489',
        ELSDLEA: '',
        UNSDLEA: '32250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8669, 31.7281],
            [-95.8142, 31.7752],
            [-95.7271, 31.8008],
            [-95.6638, 31.7189],
            [-95.7272, 31.6438],
            [-95.8296, 31.6919],
            [-95.8669, 31.7281],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843290',
        NAME: 'Westwood Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249729538,
        AWATER: 4581605,
        INTPTLAT: '+31.7099824',
        INTPTLON: '-095.7653793',
        ELSDLEA: '',
        UNSDLEA: '43290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.609, 29.6427],
            [-96.5451, 29.5621],
            [-96.4488, 29.5847],
            [-96.3752, 29.6896],
            [-96.2697, 29.6891],
            [-96.1932, 29.6496],
            [-96.1754, 29.6338],
            [-96.3077, 29.5145],
            [-96.3625, 29.3997],
            [-96.581, 29.2788],
            [-96.5606, 29.3354],
            [-96.7578, 29.5406],
            [-96.6374, 29.5677],
            [-96.609, 29.6427],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836970',
        NAME: 'Rice Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1058029024,
        AWATER: 21184953,
        INTPTLAT: '+29.5100883',
        INTPTLON: '-096.4663898',
        ELSDLEA: '',
        UNSDLEA: '36970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0452, 29.6297],
            [-97.0211, 29.6302],
            [-96.8742, 29.6327],
            [-96.8498, 29.5706],
            [-96.7578, 29.5406],
            [-96.5606, 29.3354],
            [-96.581, 29.2788],
            [-96.6338, 29.242],
            [-96.6784, 29.2494],
            [-96.8042, 29.1604],
            [-96.8444, 29.2521],
            [-96.8384, 29.3517],
            [-96.9603, 29.3474],
            [-97.0366, 29.4026],
            [-97.0565, 29.4714],
            [-97.0731, 29.4904],
            [-97.0452, 29.6297],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822120',
        NAME: 'Hallettsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1288734925,
        AWATER: 1535404,
        INTPTLAT: '+29.4101930',
        INTPTLON: '-096.8226321',
        ELSDLEA: '',
        UNSDLEA: '22120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9663, 32.92],
            [-95.8625, 32.9796],
            [-95.748, 32.9801],
            [-95.6654, 32.9604],
            [-95.6575, 32.8983],
            [-95.6625, 32.7743],
            [-95.7135, 32.7439],
            [-95.7487, 32.7553],
            [-95.7639, 32.7579],
            [-95.8785, 32.7878],
            [-95.9344, 32.8372],
            [-95.9663, 32.92],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836360',
        NAME: 'Rains Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 532501412,
        AWATER: 75061761,
        INTPTLAT: '+32.8705795',
        INTPTLON: '-095.7954400',
        ELSDLEA: '',
        UNSDLEA: '36360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6152, 31.0852],
            [-94.6021, 31.0741],
            [-94.4799, 31.0295],
            [-94.5271, 30.8821],
            [-94.6567, 30.8409],
            [-94.7585, 30.8651],
            [-94.7001, 31.0332],
            [-94.6152, 31.0852],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813770',
        NAME: 'Chester Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470769638,
        AWATER: 427018,
        INTPTLAT: '+30.9528613',
        INTPTLON: '-094.6220007',
        ELSDLEA: '',
        UNSDLEA: '13770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4799, 31.0295],
            [-94.2766, 31.0702],
            [-94.1978, 30.8567],
            [-94.4575, 30.8511],
            [-94.5271, 30.8821],
            [-94.4799, 31.0295],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814600',
        NAME: 'Colmesneil Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550832267,
        AWATER: 12841643,
        INTPTLAT: '+30.9405169',
        INTPTLON: '-094.3536398',
        ELSDLEA: '',
        UNSDLEA: '14600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.918, 30.0736],
            [-99.8691, 30.0048],
            [-99.8738, 29.7209],
            [-99.8912, 29.7062],
            [-99.8735, 29.4952],
            [-100.0438, 29.4474],
            [-100.1122, 29.4522],
            [-100.1121, 29.6233],
            [-100.5756, 29.6235],
            [-100.3059, 29.7343],
            [-100.357, 29.8429],
            [-100.1368, 29.8866],
            [-100.0795, 29.9473],
            [-100.0468, 30.1291],
            [-99.9439, 30.1423],
            [-99.918, 30.0736],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833240',
        NAME: 'Nueces Canyon Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2328933647,
        AWATER: 4699956,
        INTPTLAT: '+29.7613645',
        INTPTLON: '-100.0955090',
        ELSDLEA: '',
        UNSDLEA: '33240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1122, 29.4522],
            [-100.0438, 29.4474],
            [-99.8735, 29.4952],
            [-99.8912, 29.7062],
            [-99.8017, 29.6633],
            [-99.781, 29.5818],
            [-99.7567, 29.4387],
            [-99.6534, 29.3701],
            [-99.7092, 29.3299],
            [-99.6727, 29.1949],
            [-99.5788, 29.1563],
            [-99.5454, 29.0904],
            [-99.4139, 29.0913],
            [-99.4123, 28.9206],
            [-99.4488, 28.879],
            [-99.7703, 28.872],
            [-99.7991, 29.0029],
            [-99.8692, 29.0886],
            [-100.1114, 29.0863],
            [-100.1122, 29.4522],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843720',
        NAME: 'Uvalde Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2977373781,
        AWATER: 10783818,
        INTPTLAT: '+29.2066236',
        INTPTLON: '-099.7962567',
        ELSDLEA: '',
        UNSDLEA: '43720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.125, 33.6481],
            [-96.05, 33.6539],
            [-96.042, 33.5002],
            [-96.1402, 33.5733],
            [-96.125, 33.6481],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817280',
        NAME: 'Dodd City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116970485,
        AWATER: 442720,
        INTPTLAT: '+33.5919600',
        INTPTLON: '-096.0839579',
        ELSDLEA: '',
        UNSDLEA: '17280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4677, 32.5995],
            [-99.0563, 32.6421],
            [-99.0188, 32.5147],
            [-99.1151, 32.4591],
            [-99.1698, 32.515],
            [-99.4416, 32.5149],
            [-99.4677, 32.5995],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831320',
        NAME: 'Moran Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 536249039,
        AWATER: 466153,
        INTPTLAT: '+32.5597211',
        INTPTLON: '-099.2134776',
        ELSDLEA: '',
        UNSDLEA: '31320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5505, 32.5624],
            [-97.5413, 32.5984],
            [-97.4801, 32.5925],
            [-97.4713, 32.555],
            [-97.4917, 32.4745],
            [-97.4253, 32.4443],
            [-97.5219, 32.3568],
            [-97.6048, 32.3559],
            [-97.6525, 32.4526],
            [-97.5505, 32.5624],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820960',
        NAME: 'Godley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320224313,
        AWATER: 1924417,
        INTPTLAT: '+32.4684200',
        INTPTLON: '-097.5421986',
        ELSDLEA: '',
        UNSDLEA: '20960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9456, 32.2335],
            [-97.7878, 32.3139],
            [-97.6154, 32.3477],
            [-97.6151, 32.2036],
            [-97.6309, 32.2074],
            [-97.8544, 32.0926],
            [-97.8877, 32.1276],
            [-97.9456, 32.2335],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820850',
        NAME: 'Glen Rose Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496735422,
        AWATER: 15595059,
        INTPTLAT: '+32.2298402',
        INTPTLON: '-097.7686371',
        ELSDLEA: '',
        UNSDLEA: '20850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0649, 32.6169],
            [-98.0045, 32.6236],
            [-97.9965, 32.5578],
            [-97.893, 32.5572],
            [-97.931, 32.4706],
            [-98.0284, 32.4201],
            [-98.1152, 32.4394],
            [-98.1339, 32.4559],
            [-98.1303, 32.5584],
            [-98.0649, 32.6169],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827630',
        NAME: 'Lipan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311133086,
        AWATER: 1620302,
        INTPTLAT: '+32.5207182',
        INTPTLON: '-098.0296652',
        ELSDLEA: '',
        UNSDLEA: '27630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9986, 29.2423],
            [-96.9603, 29.3474],
            [-96.8384, 29.3517],
            [-96.8444, 29.2521],
            [-96.8042, 29.1604],
            [-96.9386, 29.0632],
            [-96.9764, 29.104],
            [-96.9986, 29.2423],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818890',
        NAME: 'Ezzell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 431997390,
        AWATER: 94020,
        INTPTLAT: '+29.1873682',
        INTPTLON: '-096.9039930',
        ELSDLEA: '',
        UNSDLEA: '18890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4456, 32.957],
            [-99.1754, 32.9567],
            [-99.096, 32.9046],
            [-99.0959, 32.7362],
            [-99.0563, 32.6421],
            [-99.4677, 32.5995],
            [-99.474, 32.6589],
            [-99.612, 32.662],
            [-99.6121, 32.8254],
            [-99.4455, 32.8112],
            [-99.4456, 32.957],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807680',
        NAME: 'Albany Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1534359683,
        AWATER: 2811743,
        INTPTLAT: '+32.7821535',
        INTPTLON: '-099.3239136',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.096, 32.9046],
            [-98.9367, 32.957],
            [-98.8583, 32.9572],
            [-98.5756, 32.9543],
            [-98.5757, 32.8573],
            [-98.576, 32.6799],
            [-98.5761, 32.5725],
            [-98.7225, 32.584],
            [-98.7955, 32.5152],
            [-98.937, 32.515],
            [-99.0188, 32.5147],
            [-99.0563, 32.6421],
            [-99.0959, 32.7362],
            [-99.096, 32.9046],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811220',
        NAME: 'Breckenridge Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2028158426,
        AWATER: 63985007,
        INTPTLAT: '+32.7360198',
        INTPTLON: '-098.8257079',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.392, 34.2554],
            [-99.3371, 34.2217],
            [-99.1984, 34.2606],
            [-99.1898, 34.2144],
            [-99.028, 34.2053],
            [-99.0352, 34.1037],
            [-99.1451, 34.0372],
            [-99.059, 33.8341],
            [-99.4757, 33.834],
            [-99.4754, 34.0029],
            [-99.5044, 34.0763],
            [-99.3738, 34.1241],
            [-99.392, 34.2554],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844110',
        NAME: 'Vernon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1451637259,
        AWATER: 13225452,
        INTPTLAT: '+34.0296652',
        INTPTLON: '-099.2769077',
        ELSDLEA: '',
        UNSDLEA: '44110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3826, 36.5005],
            [-102.0323, 36.5006],
            [-101.9316, 36.5005],
            [-101.8798, 36.4861],
            [-101.8799, 36.3865],
            [-101.7334, 36.2115],
            [-101.7338, 36.139],
            [-101.8828, 36.1836],
            [-101.9022, 36.0554],
            [-102.163, 36.0552],
            [-102.251, 36.0552],
            [-102.3686, 36.2258],
            [-102.3678, 36.3856],
            [-102.3826, 36.5005],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841670',
        NAME: 'Stratford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2241244655,
        AWATER: 337041,
        INTPTLAT: '+36.2898101',
        INTPTLON: '-102.0834211',
        ELSDLEA: '',
        UNSDLEA: '41670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0024, 36.5004],
            [-102.5707, 36.5004],
            [-102.3826, 36.5005],
            [-102.3678, 36.3856],
            [-102.7713, 36.3693],
            [-102.8403, 36.3346],
            [-102.8413, 36.1729],
            [-102.77, 36.1728],
            [-102.771, 36.0552],
            [-102.9178, 36.0552],
            [-103.0408, 36.0552],
            [-103.0419, 36.5003],
            [-103.0024, 36.5004],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842570',
        NAME: 'Texline Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1583312888,
        AWATER: 3955634,
        INTPTLAT: '+36.3584619',
        INTPTLON: '-102.8232937',
        ELSDLEA: '',
        UNSDLEA: '42570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3328, 32.7269],
            [-98.171, 32.742],
            [-98.0686, 32.7026],
            [-98.0649, 32.6169],
            [-98.1303, 32.5584],
            [-98.1339, 32.4559],
            [-98.2327, 32.5126],
            [-98.2394, 32.5711],
            [-98.3353, 32.5978],
            [-98.3328, 32.7269],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839390',
        NAME: 'Santo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 521669017,
        AWATER: 15847004,
        INTPTLAT: '+32.6230546',
        INTPTLON: '-098.1950495',
        ELSDLEA: '',
        UNSDLEA: '39390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0686, 32.7026],
            [-98.0605, 32.806],
            [-97.8857, 32.8195],
            [-97.8233, 32.7789],
            [-97.8923, 32.7466],
            [-97.988, 32.6841],
            [-98.0045, 32.6236],
            [-98.0649, 32.6169],
            [-98.0686, 32.7026],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830870',
        NAME: 'Millsap Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241210983,
        AWATER: 2908079,
        INTPTLAT: '+32.7205229',
        INTPTLON: '-097.9897139',
        ELSDLEA: '',
        UNSDLEA: '30870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1205, 34.7477],
            [-102.009, 34.7787],
            [-101.8113, 34.7929],
            [-101.8298, 34.8681],
            [-101.6686, 34.8836],
            [-101.626, 34.9718],
            [-101.6293, 34.7476],
            [-101.4716, 34.7475],
            [-101.4714, 34.6752],
            [-101.7761, 34.661],
            [-101.7762, 34.6318],
            [-101.9984, 34.6177],
            [-102.0857, 34.657],
            [-102.1205, 34.7477],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822350',
        NAME: 'Happy Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1039257309,
        AWATER: 9030687,
        INTPTLAT: '+34.7393025',
        INTPTLON: '-101.8038137',
        ELSDLEA: '',
        UNSDLEA: '22350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9473, 28.6081],
              [-96.9304, 28.5867],
              [-96.891, 28.5072],
              [-97.0232, 28.5368],
              [-96.9473, 28.6081],
            ],
          ],
          [
            [
              [-97.4379, 28.4097],
              [-97.3751, 28.3892],
              [-97.1608, 28.5535],
              [-97.0777, 28.5336],
              [-97.1267, 28.371],
              [-97.0834, 28.2782],
              [-97.0236, 28.1877],
              [-97.1253, 28.1163],
              [-97.1961, 28.1897],
              [-97.2779, 28.219],
              [-97.3137, 28.3085],
              [-97.3957, 28.3615],
              [-97.4379, 28.4097],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836780',
        NAME: 'Refugio Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1001868897,
        AWATER: 47315807,
        INTPTLAT: '+28.3675746',
        INTPTLON: '-097.1741231',
        ELSDLEA: '',
        UNSDLEA: '36780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.496, 28.2251],
            [-97.3957, 28.3615],
            [-97.3137, 28.3085],
            [-97.2779, 28.219],
            [-97.1961, 28.1897],
            [-97.1253, 28.1163],
            [-97.2608, 28.0758],
            [-97.3192, 28.126],
            [-97.5411, 28.1647],
            [-97.496, 28.2251],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846380',
        NAME: 'Woodsboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520864196,
        AWATER: 39335155,
        INTPTLAT: '+28.1978620',
        INTPTLON: '-097.3448529',
        ELSDLEA: '',
        UNSDLEA: '46380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4207, 32.7932],
            [-98.3328, 32.7269],
            [-98.3353, 32.5978],
            [-98.2394, 32.5711],
            [-98.2327, 32.5126],
            [-98.2868, 32.4711],
            [-98.3831, 32.4603],
            [-98.4473, 32.513],
            [-98.4207, 32.7932],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821180',
        NAME: 'Gordon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418648643,
        AWATER: 2686374,
        INTPTLAT: '+32.5938561',
        INTPTLON: '-098.3633678',
        ELSDLEA: '',
        UNSDLEA: '21180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.576, 32.6799],
            [-98.4997, 32.6801],
            [-98.4207, 32.7932],
            [-98.4473, 32.513],
            [-98.4752, 32.513],
            [-98.5763, 32.5152],
            [-98.5761, 32.5725],
            [-98.576, 32.6799],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841700',
        NAME: 'Strawn Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278254980,
        AWATER: 1266582,
        INTPTLAT: '+32.6334249',
        INTPTLON: '-098.5343089',
        ELSDLEA: '',
        UNSDLEA: '41700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5948, 33.3885],
            [-102.4601, 33.3884],
            [-102.4632, 33.2835],
            [-102.2403, 33.2972],
            [-102.1021, 33.2539],
            [-102.0868, 33.1526],
            [-102.2051, 33.1089],
            [-102.5176, 33.1234],
            [-102.5947, 33.1091],
            [-102.6286, 33.1092],
            [-102.6286, 33.2403],
            [-102.5948, 33.3885],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811610',
        NAME: 'Brownfield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 993662428,
        AWATER: 3565005,
        INTPTLAT: '+33.2220018',
        INTPTLON: '-102.4005488',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5782, 33.0014],
            [-97.519, 33.0088],
            [-97.5126, 32.9939],
            [-97.4672, 32.8624],
            [-97.5012, 32.8162],
            [-97.548, 32.7984],
            [-97.6291, 32.7923],
            [-97.6648, 32.8692],
            [-97.5585, 32.9331],
            [-97.5782, 33.0014],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809200',
        NAME: 'Azle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222872202,
        AWATER: 17733378,
        INTPTLAT: '+32.8795916',
        INTPTLON: '-097.5657716',
        ELSDLEA: '',
        UNSDLEA: '09200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6426, 30.2625],
            [-97.5681, 30.3068],
            [-97.4728, 30.2439],
            [-97.4911, 30.2124],
            [-97.6494, 30.0679],
            [-97.7102, 30.0245],
            [-97.7701, 30.107],
            [-97.7135, 30.2504],
            [-97.6426, 30.2625],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816620',
        NAME: 'Del Valle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435985162,
        AWATER: 5099357,
        INTPTLAT: '+30.1673599',
        INTPTLON: '-097.6404360',
        ELSDLEA: '',
        UNSDLEA: '16620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.119, 30.3777],
            [-98.1119, 30.3801],
            [-98.0102, 30.4803],
            [-97.9209, 30.435],
            [-97.8992, 30.3401],
            [-97.9071, 30.3047],
            [-98.0106, 30.2437],
            [-98.1241, 30.3216],
            [-98.119, 30.3777],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826470',
        NAME: 'Lake Travis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278850477,
        AWATER: 24547125,
        INTPTLAT: '+30.3495731',
        INTPTLON: '-098.0110220',
        ELSDLEA: '',
        UNSDLEA: '26470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.695, 30.4323],
            [-97.5899, 30.4974],
            [-97.489, 30.4741],
            [-97.4673, 30.4643],
            [-97.4629, 30.4414],
            [-97.4999, 30.4054],
            [-97.6605, 30.3753],
            [-97.695, 30.4323],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834830',
        NAME: 'Pflugerville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198737320,
        AWATER: 94432,
        INTPTLAT: '+30.4380362',
        INTPTLON: '-097.5838182',
        ELSDLEA: '',
        UNSDLEA: '34830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0033, 31.4257],
            [-94.9179, 31.46],
            [-94.8659, 31.5269],
            [-94.7257, 31.457],
            [-94.7565, 31.3698],
            [-94.9627, 31.39],
            [-95.0033, 31.4257],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813500',
        NAME: 'Central Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242262024,
        AWATER: 2715999,
        INTPTLAT: '+31.4337664',
        INTPTLON: '-094.8475532',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6253, 31.1415],
            [-94.6126, 31.3561],
            [-94.5692, 31.433],
            [-94.4864, 31.3943],
            [-94.4494, 31.3213],
            [-94.3493, 31.2394],
            [-94.4751, 31.204],
            [-94.5166, 31.1178],
            [-94.6109, 31.122],
            [-94.6253, 31.1415],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824000',
        NAME: 'Huntington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446427557,
        AWATER: 56130810,
        INTPTLAT: '+31.2557832',
        INTPTLON: '-094.5096911',
        ELSDLEA: '',
        UNSDLEA: '24000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6109, 31.122],
            [-94.5166, 31.1178],
            [-94.4751, 31.204],
            [-94.3493, 31.2394],
            [-94.3266, 31.2248],
            [-94.1296, 31.0993],
            [-94.2766, 31.0702],
            [-94.4799, 31.0295],
            [-94.6021, 31.0741],
            [-94.6109, 31.122],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846740',
        NAME: 'Zavalla Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445492267,
        AWATER: 98078601,
        INTPTLAT: '+31.1077545',
        INTPTLON: '-094.3849324',
        ELSDLEA: '',
        UNSDLEA: '46740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9531, 33.834],
            [-98.7962, 33.8983],
            [-98.7839, 34.0386],
            [-98.7506, 34.0687],
            [-98.6559, 34.067],
            [-98.59, 33.9744],
            [-98.5753, 33.9077],
            [-98.6375, 33.8973],
            [-98.697, 33.8347],
            [-98.839, 33.8341],
            [-98.9529, 33.7852],
            [-98.9531, 33.834],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824260',
        NAME: 'Iowa Park Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445979047,
        AWATER: 12563245,
        INTPTLAT: '+33.9435456',
        INTPTLON: '-098.7522225',
        ELSDLEA: '',
        UNSDLEA: '24260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4579, 33.4967],
            [-98.4217, 33.4964],
            [-98.1685, 33.4278],
            [-98.052, 33.4297],
            [-97.9185, 33.3772],
            [-97.9189, 33.237],
            [-97.92, 33.1439],
            [-97.9866, 33.0598],
            [-98.0875, 33.1221],
            [-98.1894, 33.1339],
            [-98.2047, 33.0266],
            [-98.291, 33.0609],
            [-98.2707, 33.1963],
            [-98.4208, 33.363],
            [-98.4207, 33.3962],
            [-98.4579, 33.4967],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824530',
        NAME: 'Jacksboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1485491334,
        AWATER: 21877652,
        INTPTLAT: '+33.2678401',
        INTPTLON: '-098.1329836',
        ELSDLEA: '',
        UNSDLEA: '24530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9534, 33.6271],
            [-98.8803, 33.6318],
            [-98.823, 33.4964],
            [-98.7146, 33.4541],
            [-98.5796, 33.4453],
            [-98.5817, 33.3956],
            [-98.663, 33.309],
            [-98.7899, 33.2789],
            [-98.9535, 33.2859],
            [-99.0137, 33.3978],
            [-99.0183, 33.4666],
            [-98.9538, 33.4964],
            [-98.9534, 33.6271],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833630',
        NAME: 'Olney Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 931894717,
        AWATER: 8946417,
        INTPTLAT: '+33.4210894',
        INTPTLON: '-098.8348504',
        ELSDLEA: '',
        UNSDLEA: '33630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7508, 30.5606],
            [-97.6138, 30.5823],
            [-97.5899, 30.4974],
            [-97.695, 30.4323],
            [-97.8008, 30.3997],
            [-97.8184, 30.4824],
            [-97.7508, 30.5606],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838080',
        NAME: 'Round Rock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252826398,
        AWATER: 1623279,
        INTPTLAT: '+30.4955379',
        INTPTLON: '-097.7081311',
        ELSDLEA: '',
        UNSDLEA: '38080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.592, 33.3745],
            [-101.557, 33.4033],
            [-101.4365, 33.3956],
            [-101.3336, 33.3965],
            [-101.3344, 33.3286],
            [-101.4364, 33.3297],
            [-101.6305, 33.2263],
            [-101.592, 33.3745],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840890',
        NAME: 'Southland Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305968598,
        AWATER: 403251,
        INTPTLAT: '+33.3263116',
        INTPTLON: '-101.4886470',
        ELSDLEA: '',
        UNSDLEA: '40890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3917, 31.5342],
            [-98.294, 31.5552],
            [-98.3153, 31.5231],
            [-98.1967, 31.4017],
            [-98.2192, 31.3771],
            [-98.4073, 31.35],
            [-98.4517, 31.4777],
            [-98.3917, 31.5342],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841460',
        NAME: 'Star Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370480482,
        AWATER: 219193,
        INTPTLAT: '+31.4459950',
        INTPTLON: '-098.3331893',
        ELSDLEA: '',
        UNSDLEA: '41460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5866, 34.3869],
            [-99.4952, 34.4055],
            [-99.3987, 34.3758],
            [-99.3554, 34.4489],
            [-99.3151, 34.4087],
            [-99.392, 34.2554],
            [-99.3738, 34.1241],
            [-99.5044, 34.0763],
            [-99.6089, 34.0831],
            [-99.5517, 34.1307],
            [-99.5866, 34.3869],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813890',
        NAME: 'Chillicothe Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 680578546,
        AWATER: 601832,
        INTPTLAT: '+34.2662312',
        INTPTLON: '-099.4797389',
        ELSDLEA: '',
        UNSDLEA: '13890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1451, 31.9994],
            [-94.0427, 31.9993],
            [-93.8791, 31.8443],
            [-93.815, 31.7287],
            [-93.9276, 31.7213],
            [-93.8891, 31.8236],
            [-94.0521, 31.8568],
            [-94.1466, 31.8928],
            [-94.1451, 31.9994],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824810',
        NAME: 'Joaquin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285967471,
        AWATER: 64400712,
        INTPTLAT: '+31.8860430',
        INTPTLON: '-094.0097402',
        ELSDLEA: '',
        UNSDLEA: '24810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3316, 31.976],
            [-94.2942, 31.9765],
            [-94.1451, 31.9994],
            [-94.1466, 31.8928],
            [-94.2536, 31.8936],
            [-94.2898, 31.8556],
            [-94.3316, 31.976],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842360',
        NAME: 'Tenaha Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158763795,
        AWATER: 304847,
        INTPTLAT: '+31.9224354',
        INTPTLON: '-094.2228422',
        ELSDLEA: '',
        UNSDLEA: '42360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2942, 31.9765],
            [-94.3316, 31.976],
            [-94.5114, 31.974],
            [-94.531, 31.9738],
            [-94.4616, 32.0448],
            [-94.3448, 32.0758],
            [-94.2942, 31.9765],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820460',
        NAME: 'Gary Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168244257,
        AWATER: 6359120,
        INTPTLAT: '+32.0158042',
        INTPTLON: '-094.3875649',
        ELSDLEA: '',
        UNSDLEA: '20460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5964, 32.1049],
            [-94.563, 32.1882],
            [-94.4478, 32.2058],
            [-94.3129, 32.2581],
            [-94.3476, 32.3307],
            [-94.2772, 32.3449],
            [-94.2047, 32.2696],
            [-94.0428, 32.2813],
            [-94.0426, 32.196],
            [-94.0427, 31.9993],
            [-94.1451, 31.9994],
            [-94.2942, 31.9765],
            [-94.3448, 32.0758],
            [-94.4616, 32.0448],
            [-94.531, 31.9738],
            [-94.6, 31.9732],
            [-94.5964, 32.1049],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813110',
        NAME: 'Carthage Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1364163192,
        AWATER: 14184508,
        INTPTLAT: '+32.1384956',
        INTPTLON: '-094.2741560',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.211, 32.177],
            [-102.0502, 32.2289],
            [-101.9021, 32.2179],
            [-101.6923, 32.2652],
            [-101.6934, 32.1961],
            [-101.5911, 32.1863],
            [-101.5606, 32.0871],
            [-101.7761, 32.0869],
            [-101.97, 32.0868],
            [-102.2112, 32.0868],
            [-102.211, 32.177],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841430',
        NAME: 'Stanton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876188328,
        AWATER: 3138094,
        INTPTLAT: '+32.1730881',
        INTPTLON: '-101.9029632',
        ELSDLEA: '',
        UNSDLEA: '41430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8302, 30.6303],
            [-95.6871, 30.663],
            [-95.6153, 30.5541],
            [-95.6112, 30.5155],
            [-95.6992, 30.5617],
            [-95.82, 30.5255],
            [-95.8124, 30.4367],
            [-95.8944, 30.4646],
            [-95.9016, 30.5582],
            [-95.8302, 30.6303],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836990',
        NAME: 'Richards Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313500158,
        AWATER: 6403668,
        INTPTLAT: '+30.5617231',
        INTPTLON: '-095.7814737',
        ELSDLEA: '',
        UNSDLEA: '36990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.4036, 30.9442],
            [-104.1024, 31.1052],
            [-103.6799, 30.8291],
            [-103.5851, 30.7665],
            [-103.44, 30.6659],
            [-103.8007, 30.4125],
            [-103.901, 30.4122],
            [-104.0905, 30.4504],
            [-104.0908, 30.7261],
            [-104.2089, 30.7263],
            [-104.2082, 30.8279],
            [-104.4028, 30.841],
            [-104.4036, 30.9442],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820100',
        NAME: 'Fort Davis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3537277708,
        AWATER: 132076,
        INTPTLAT: '+30.7653206',
        INTPTLON: '-103.8841539',
        ELSDLEA: '',
        UNSDLEA: '20100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8927, 31.8548],
            [-97.7979, 31.8669],
            [-97.6874, 31.8707],
            [-97.7333, 31.6891],
            [-97.8659, 31.6466],
            [-97.886, 31.7259],
            [-97.9478, 31.7926],
            [-97.8927, 31.8548],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815570',
        NAME: 'Cranfills Gap Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377573839,
        AWATER: 1817568,
        INTPTLAT: '+31.7690028',
        INTPTLON: '-097.8044264',
        ELSDLEA: '',
        UNSDLEA: '15570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9538, 32.0831],
            [-97.8877, 32.1276],
            [-97.8544, 32.0926],
            [-97.7728, 31.9902],
            [-97.7979, 31.8669],
            [-97.8927, 31.8548],
            [-97.9203, 31.8949],
            [-98.0032, 32.0509],
            [-97.9538, 32.0831],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824360',
        NAME: 'Iredell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380660265,
        AWATER: 3780100,
        INTPTLAT: '+31.9849959',
        INTPTLON: '-097.8738905',
        ELSDLEA: '',
        UNSDLEA: '24360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6309, 32.2074],
            [-97.6151, 32.2036],
            [-97.512, 32.1612],
            [-97.4761, 32.1735],
            [-97.4862, 32.0325],
            [-97.4257, 31.9433],
            [-97.5182, 31.9678],
            [-97.5748, 32.0453],
            [-97.6486, 32.0569],
            [-97.6048, 32.148],
            [-97.6309, 32.2074],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825890',
        NAME: 'Kopperl Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277222908,
        AWATER: 21081696,
        INTPTLAT: '+32.0713867',
        INTPTLON: '-097.5311393',
        ELSDLEA: '',
        UNSDLEA: '25890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6309, 32.2074],
            [-97.6048, 32.148],
            [-97.6486, 32.0569],
            [-97.7085, 32.008],
            [-97.7728, 31.9902],
            [-97.8544, 32.0926],
            [-97.6309, 32.2074],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844520',
        NAME: 'Walnut Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263829052,
        AWATER: 2858255,
        INTPTLAT: '+32.1065338',
        INTPTLON: '-097.7089614',
        ELSDLEA: '',
        UNSDLEA: '44520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5464, 29.1677],
            [-96.5412, 29.1632],
            [-96.3092, 28.9633],
            [-96.3107, 28.9226],
            [-96.4494, 28.8874],
            [-96.534, 28.9392],
            [-96.5702, 29.0365],
            [-96.5464, 29.1677],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820310',
        NAME: 'Ganado Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389073596,
        AWATER: 13709824,
        INTPTLAT: '+29.0103445',
        INTPTLON: '-096.4435678',
        ELSDLEA: '',
        UNSDLEA: '20310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3153, 28.8291],
            [-96.2184, 28.7719],
            [-95.9972, 28.8098],
            [-95.9907, 28.7819],
            [-96.0285, 28.5893],
            [-96.0003, 28.5334],
            [-96.1822, 28.452],
            [-96.3208, 28.3629],
            [-96.3952, 28.2797],
            [-96.3227, 28.6429],
            [-96.4284, 28.7035],
            [-96.5746, 28.7055],
            [-96.5073, 28.7559],
            [-96.3818, 28.7725],
            [-96.3153, 28.8291],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834020',
        NAME: 'Palacios Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 717178247,
        AWATER: 805751132,
        INTPTLAT: '+28.6025374',
        INTPTLON: '-096.2209029',
        ELSDLEA: '',
        UNSDLEA: '34020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6126, 31.4022],
            [-95.4587, 31.4456],
            [-95.3533, 31.4429],
            [-95.3499, 31.437],
            [-95.4223, 31.3451],
            [-95.4898, 31.3459],
            [-95.5845, 31.2923],
            [-95.6126, 31.4022],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826910',
        NAME: 'Latexo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245581332,
        AWATER: 4129239,
        INTPTLAT: '+31.3892601',
        INTPTLON: '-095.4886130',
        ELSDLEA: '',
        UNSDLEA: '26910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2355, 28.058],
            [-98.1136, 28.0576],
            [-98.1138, 27.9756],
            [-98.0488, 27.8962],
            [-97.9402, 27.86],
            [-98.0174, 27.7601],
            [-97.9383, 27.6958],
            [-97.9401, 27.6708],
            [-97.9421, 27.6359],
            [-98.0119, 27.6357],
            [-98.0575, 27.6885],
            [-98.2337, 27.6451],
            [-98.1605, 27.9176],
            [-98.2351, 27.9289],
            [-98.2355, 28.058],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807800',
        NAME: 'Alice Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 778559420,
        AWATER: 2077762,
        INTPTLAT: '+27.8305340',
        INTPTLON: '-098.1292528',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1136, 28.0576],
            [-97.8831, 28.0569],
            [-97.7985, 27.9957],
            [-97.921, 27.8961],
            [-97.9402, 27.86],
            [-98.0488, 27.8962],
            [-98.1138, 27.9756],
            [-98.1136, 28.0576],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833720',
        NAME: 'Orange Grove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474139954,
        AWATER: 5862369,
        INTPTLAT: '+27.9837707',
        INTPTLON: '-097.9681786',
        ELSDLEA: '',
        UNSDLEA: '33720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3327, 32.7113],
            [-95.2697, 32.7607],
            [-95.2381, 32.7345],
            [-95.1737, 32.6863],
            [-95.1754, 32.5555],
            [-95.3017, 32.5904],
            [-95.346, 32.6093],
            [-95.3327, 32.7113],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822770',
        NAME: 'Hawkins Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263302570,
        AWATER: 7799085,
        INTPTLAT: '+32.6544144',
        INTPTLON: '-095.2459127',
        ELSDLEA: '',
        UNSDLEA: '22770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4891, 32.7688],
            [-95.3327, 32.7113],
            [-95.346, 32.6093],
            [-95.5019, 32.6203],
            [-95.5408, 32.6519],
            [-95.4891, 32.7688],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830930',
        NAME: 'Mineola Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229191356,
        AWATER: 5270009,
        INTPTLAT: '+32.6692478',
        INTPTLON: '-095.4359819',
        ELSDLEA: '',
        UNSDLEA: '30930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8932, 27.3756],
            [-97.7767, 27.3548],
            [-97.7906, 27.4917],
            [-97.8407, 27.5584],
            [-97.5734, 27.5603],
            [-97.405, 27.56],
            [-97.2421, 27.5829],
            [-97.1651, 27.5683],
            [-97.2883, 27.2776],
            [-97.5089, 27.2666],
            [-97.6505, 27.2835],
            [-97.8575, 27.2434],
            [-97.8932, 27.3756],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837320',
        NAME: 'Riviera Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1315484293,
        AWATER: 539238941,
        INTPTLAT: '+27.4432545',
        INTPTLON: '-097.5034993',
        ELSDLEA: '',
        UNSDLEA: '37320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2794, 31.6014],
            [-97.2448, 31.6017],
            [-97.1413, 31.5963],
            [-97.1221, 31.6028],
            [-97.0451, 31.519],
            [-97.0529, 31.4948],
            [-97.1571, 31.4837],
            [-97.2794, 31.6014],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844280',
        NAME: 'Waco Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131297689,
        AWATER: 26982345,
        INTPTLAT: '+31.5433142',
        INTPTLON: '-097.1564334',
        ELSDLEA: '',
        UNSDLEA: '44280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9348, 33.9545],
            [-96.8926, 33.8897],
            [-96.7868, 33.8652],
            [-96.801, 33.7823],
            [-96.8466, 33.7769],
            [-96.8767, 33.6615],
            [-96.8151, 33.5961],
            [-96.97, 33.6007],
            [-96.9447, 33.798],
            [-97.0094, 33.8185],
            [-97.0289, 33.8425],
            [-96.9348, 33.9545],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845630',
        NAME: 'Whitesboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410387141,
        AWATER: 35965928,
        INTPTLAT: '+33.7697125',
        INTPTLON: '-096.9188450',
        ELSDLEA: '',
        UNSDLEA: '45630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.222, 33.6063],
            [-97.1941, 33.6808],
            [-97.1628, 33.7291],
            [-97.1004, 33.7254],
            [-97.039, 33.6415],
            [-97.0622, 33.5762],
            [-97.1147, 33.5179],
            [-97.1866, 33.5569],
            [-97.222, 33.6063],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820220',
        NAME: 'Gainesville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204276951,
        AWATER: 317010,
        INTPTLAT: '+33.6204046',
        INTPTLON: '-097.1259145',
        ELSDLEA: '',
        UNSDLEA: '20220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0868, 33.8538],
            [-97.0289, 33.8425],
            [-97.0094, 33.8185],
            [-97.0861, 33.7454],
            [-97.0868, 33.8538],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844490',
        NAME: 'Walnut Bend Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53757007,
        AWATER: 0,
        INTPTLAT: '+33.8025145',
        INTPTLON: '-097.0557027',
        ELSDLEA: '',
        UNSDLEA: '44490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2061, 31.9031],
            [-97.1756, 31.9396],
            [-97.0607, 31.9294],
            [-96.9906, 31.8915],
            [-97.0024, 31.8111],
            [-97.0373, 31.8631],
            [-97.1463, 31.8287],
            [-97.2061, 31.9031],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807380',
        NAME: 'Abbott Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191270942,
        AWATER: 3422263,
        INTPTLAT: '+31.8913746',
        INTPTLON: '-097.0917067',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9951, 32.06],
            [-96.931, 32.0568],
            [-96.8254, 31.9792],
            [-96.8472, 31.964],
            [-96.9645, 31.9833],
            [-96.9565, 31.9143],
            [-96.9906, 31.8915],
            [-97.0607, 31.9294],
            [-97.0783, 31.9791],
            [-96.9951, 32.06],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812360',
        NAME: 'Bynum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209852047,
        AWATER: 959893,
        INTPTLAT: '+31.9915369',
        INTPTLON: '-096.9786992',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7964, 31.9272],
            [-96.7123, 31.8143],
            [-96.8317, 31.7587],
            [-96.842, 31.8129],
            [-96.8689, 31.8765],
            [-96.7964, 31.9272],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823730',
        NAME: 'Hubbard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 162233206,
        AWATER: 2301104,
        INTPTLAT: '+31.8352545',
        INTPTLON: '-096.7962783',
        ELSDLEA: '',
        UNSDLEA: '23730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8472, 31.964],
            [-96.7964, 31.9272],
            [-96.8689, 31.8765],
            [-96.9565, 31.9143],
            [-96.9645, 31.9833],
            [-96.8472, 31.964],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828800',
        NAME: 'Malone Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118411660,
        AWATER: 874770,
        INTPTLAT: '+31.9356104',
        INTPTLON: '-096.8926758',
        ELSDLEA: '',
        UNSDLEA: '28800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9803, 31.7785],
            [-96.842, 31.8129],
            [-96.8317, 31.7587],
            [-96.856, 31.6952],
            [-96.965, 31.7559],
            [-96.9803, 31.7785],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831620',
        NAME: 'Mount Calm Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125898433,
        AWATER: 1337508,
        INTPTLAT: '+31.7598629',
        INTPTLON: '-096.8877808',
        ELSDLEA: '',
        UNSDLEA: '31620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0458, 31.335],
            [-103.011, 31.3713],
            [-102.9336, 31.3501],
            [-102.8251, 31.2669],
            [-102.7674, 31.2938],
            [-102.6732, 31.3281],
            [-102.6027, 31.2761],
            [-102.5112, 31.2595],
            [-102.4301, 31.1939],
            [-102.404, 31.0876],
            [-102.4309, 30.9447],
            [-102.436, 30.7452],
            [-102.7106, 30.7454],
            [-102.7085, 31.008],
            [-102.7637, 31.0954],
            [-102.8918, 31.0851],
            [-102.8916, 31.1489],
            [-103.0422, 31.2172],
            [-103.0458, 31.335],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811910',
        NAME: 'Buena Vista Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2257402200,
        AWATER: 2310658,
        INTPTLAT: '+31.0764586',
        INTPTLON: '-102.6497323',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0458, 31.335],
            [-103.0422, 31.2172],
            [-102.8916, 31.1489],
            [-102.8918, 31.0851],
            [-102.7637, 31.0954],
            [-102.7085, 31.008],
            [-102.7106, 30.7454],
            [-102.436, 30.7452],
            [-102.3228, 30.7409],
            [-102.3217, 30.5986],
            [-102.343, 30.5988],
            [-102.3431, 30.2841],
            [-102.5669, 30.2833],
            [-102.567, 30.0528],
            [-103.3456, 30.6005],
            [-103.44, 30.6659],
            [-103.5851, 30.7665],
            [-103.0458, 31.335],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820190',
        NAME: 'Fort Stockton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7843759454,
        AWATER: 221617,
        INTPTLAT: '+30.6767205',
        INTPTLON: '-102.9240439',
        ELSDLEA: '',
        UNSDLEA: '20190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0638, 33.0376],
            [-102.9224, 33.0386],
            [-102.7845, 33.0086],
            [-102.7499, 33.0654],
            [-102.6286, 33.1092],
            [-102.5947, 33.1091],
            [-102.5948, 33.0221],
            [-102.6641, 32.9641],
            [-102.7605, 32.9588],
            [-103.0647, 32.9591],
            [-103.0638, 33.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816770',
        NAME: 'Denver City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404482703,
        AWATER: 34329,
        INTPTLAT: '+33.0014280',
        INTPTLON: '-102.9504320',
        ELSDLEA: '',
        UNSDLEA: '16770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8734, 29.3771],
            [-97.8039, 29.424],
            [-97.7276, 29.525],
            [-97.6212, 29.427],
            [-97.6188, 29.3634],
            [-97.4864, 29.2861],
            [-97.4506, 29.2308],
            [-97.5565, 29.1523],
            [-97.6131, 29.1098],
            [-97.7323, 29.21],
            [-97.7884, 29.1778],
            [-97.7907, 29.2528],
            [-97.8734, 29.3771],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832740',
        NAME: 'Nixon-Smiley Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 961663980,
        AWATER: 1756873,
        INTPTLAT: '+29.3071779',
        INTPTLON: '-097.6862004',
        ELSDLEA: '',
        UNSDLEA: '32740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0616, 31.5641],
            [-105.9934, 31.5638],
            [-105.9972, 31.3872],
            [-106.131, 31.4247],
            [-106.0611, 31.5058],
            [-106.0616, 31.5641],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842990',
        NAME: 'Tornillo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208301985,
        AWATER: 318230,
        INTPTLAT: '+31.4874608',
        INTPTLON: '-106.0327034',
        ELSDLEA: '',
        UNSDLEA: '42990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6231, 35.8706],
            [-101.4115, 35.8827],
            [-101.2116, 35.9654],
            [-101.0859, 35.9639],
            [-101.086, 35.8239],
            [-101.2181, 35.8147],
            [-101.3524, 35.7285],
            [-101.3286, 35.6852],
            [-101.4998, 35.7226],
            [-101.6229, 35.67],
            [-101.6231, 35.8706],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841580',
        NAME: 'Plemons-Stinnett-Phillips Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 891725514,
        AWATER: 10864252,
        INTPTLAT: '+35.8370470',
        INTPTLON: '-101.3627461',
        ELSDLEA: '',
        UNSDLEA: '41580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.676, 31.6673],
            [-96.4541, 31.5356],
            [-96.3377, 31.5632],
            [-96.2366, 31.4134],
            [-96.4133, 31.3108],
            [-96.4628, 31.2294],
            [-96.5969, 31.2209],
            [-96.6366, 31.2798],
            [-96.7347, 31.4255],
            [-96.6407, 31.5214],
            [-96.7507, 31.6145],
            [-96.676, 31.6673],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821810',
        NAME: 'Groesbeck Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1239460530,
        AWATER: 54897046,
        INTPTLAT: '+31.4362506',
        INTPTLON: '-096.5187484',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5199, 31.8751],
            [-96.4105, 31.8871],
            [-96.3436, 31.8341],
            [-96.2522, 31.746],
            [-96.4522, 31.7314],
            [-96.5541, 31.8012],
            [-96.5199, 31.8751],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846470',
        NAME: 'Wortham Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286989900,
        AWATER: 1122476,
        INTPTLAT: '+31.8017768',
        INTPTLON: '-096.4105252',
        ELSDLEA: '',
        UNSDLEA: '46470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0241, 31.6806],
            [-96.965, 31.7559],
            [-96.856, 31.6952],
            [-96.8331, 31.6351],
            [-96.9913, 31.5816],
            [-97.0397, 31.5608],
            [-97.0241, 31.6806],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809150',
        NAME: 'Axtell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218115943,
        AWATER: 2606449,
        INTPTLAT: '+31.6610473',
        INTPTLON: '-096.9551289',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9897, 32.7772],
            [-94.9914, 32.9026],
            [-94.9004, 32.9026],
            [-94.799, 32.8579],
            [-94.8193, 32.782],
            [-94.8468, 32.7233],
            [-94.7431, 32.6652],
            [-94.8409, 32.5839],
            [-94.8729, 32.5721],
            [-94.8767, 32.571],
            [-94.9163, 32.6271],
            [-94.9622, 32.5748],
            [-95.0362, 32.6852],
            [-94.9897, 32.7772],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820700',
        NAME: 'Gilmer Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547282698,
        AWATER: 6667174,
        INTPTLAT: '+32.7344874',
        INTPTLON: '-094.9073926',
        ELSDLEA: '',
        UNSDLEA: '20700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1522, 33.0134],
            [-95.1261, 33.0346],
            [-94.9406, 33.0745],
            [-94.832, 32.9873],
            [-94.709, 32.8959],
            [-94.799, 32.8579],
            [-94.9004, 32.9026],
            [-94.9914, 32.9026],
            [-95.1601, 32.8982],
            [-95.1522, 33.0134],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835010',
        NAME: 'Pittsburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555976621,
        AWATER: 20542461,
        INTPTLAT: '+32.9572571',
        INTPTLON: '-094.9766648',
        ELSDLEA: '',
        UNSDLEA: '35010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5687, 30.3639],
            [-95.4917, 30.3482],
            [-95.4003, 30.4145],
            [-95.2351, 30.3776],
            [-95.2255, 30.3219],
            [-95.2435, 30.1929],
            [-95.3325, 30.1584],
            [-95.2637, 30.0325],
            [-95.2772, 30.0313],
            [-95.311, 30.0329],
            [-95.4242, 30.1155],
            [-95.4625, 30.1053],
            [-95.5097, 30.1485],
            [-95.5583, 30.1655],
            [-95.5448, 30.2464],
            [-95.5687, 30.3639],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815000',
        NAME: 'Conroe Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 889653328,
        AWATER: 8612436,
        INTPTLAT: '+30.2506045',
        INTPTLON: '-095.4021484',
        ELSDLEA: '',
        UNSDLEA: '15000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2255, 30.3219],
            [-95.1539, 30.3141],
            [-95.1056, 30.1906],
            [-95.2435, 30.1929],
            [-95.2255, 30.3219],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841070',
        NAME: 'Splendora Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191799619,
        AWATER: 525227,
        INTPTLAT: '+30.2562453',
        INTPTLON: '-095.1927095',
        ELSDLEA: '',
        UNSDLEA: '41070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4998, 35.7226],
            [-101.3286, 35.6852],
            [-101.3283, 35.6248],
            [-101.3552, 35.6247],
            [-101.5242, 35.6242],
            [-101.4998, 35.7226],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810890',
        NAME: 'Borger Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127850943,
        AWATER: 81948,
        INTPTLAT: '+35.6588410',
        INTPTLON: '-101.4287254',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0717, 30.526],
            [-94.1165, 30.4327],
            [-94.0996, 30.364],
            [-94.1016, 30.2623],
            [-94.1177, 30.2418],
            [-94.1952, 30.298],
            [-94.2649, 30.3695],
            [-94.3014, 30.467],
            [-94.3135, 30.5268],
            [-94.0717, 30.526],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840230',
        NAME: 'Silsbee Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428855318,
        AWATER: 6237552,
        INTPTLAT: '+30.4165742',
        INTPTLON: '-094.1819716',
        ELSDLEA: '',
        UNSDLEA: '40230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4911, 30.2124],
            [-97.4298, 30.1908],
            [-97.3187, 30.2693],
            [-97.2149, 30.184],
            [-97.1475, 30.2288],
            [-97.1588, 30.3107],
            [-97.0914, 30.2657],
            [-97.0592, 30.1781],
            [-97.1007, 30.1268],
            [-97.2302, 30.1137],
            [-97.3366, 30.0055],
            [-97.3511, 29.9412],
            [-97.4379, 29.8892],
            [-97.6494, 30.0679],
            [-97.4911, 30.2124],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809570',
        NAME: 'Bastrop Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1105632403,
        AWATER: 11299051,
        INTPTLAT: '+30.0991010',
        INTPTLON: '-097.3679326',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0592, 30.1781],
            [-97.0245, 30.0514],
            [-97.1033, 29.9236],
            [-97.1653, 29.923],
            [-97.3158, 29.7865],
            [-97.3577, 29.8218],
            [-97.4379, 29.8892],
            [-97.3511, 29.9412],
            [-97.3366, 30.0055],
            [-97.2302, 30.1137],
            [-97.1007, 30.1268],
            [-97.0592, 30.1781],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840550',
        NAME: 'Smithville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755819065,
        AWATER: 6098094,
        INTPTLAT: '+29.9958348',
        INTPTLON: '-097.2023728',
        ELSDLEA: '',
        UNSDLEA: '40550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3114, 31.7552],
            [-94.2898, 31.8556],
            [-94.2536, 31.8936],
            [-94.1466, 31.8928],
            [-94.0521, 31.8568],
            [-94.1134, 31.8308],
            [-94.1476, 31.7472],
            [-94.1013, 31.6216],
            [-94.3058, 31.6437],
            [-94.3114, 31.7552],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813320',
        NAME: 'Center Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531816556,
        AWATER: 1207448,
        INTPTLAT: '+31.7619645',
        INTPTLON: '-094.1990394',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4047, 31.6833],
            [-94.3114, 31.7552],
            [-94.3058, 31.6437],
            [-94.3991, 31.6536],
            [-94.4047, 31.6833],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818840',
        NAME: 'Excelsior Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68202638,
        AWATER: 2219257,
        INTPTLAT: '+31.6963673',
        INTPTLON: '-094.3450866',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4231, 33.9444],
            [-98.3491, 34.0788],
            [-98.4171, 34.0834],
            [-98.3482, 34.1545],
            [-98.1688, 34.1143],
            [-98.1391, 34.1419],
            [-98.1207, 34.0798],
            [-98.0835, 34.0412],
            [-98.1936, 33.9962],
            [-98.1943, 33.9256],
            [-98.423, 33.9114],
            [-98.4231, 33.9444],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834710',
        NAME: 'Petrolia Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561510817,
        AWATER: 2866842,
        INTPTLAT: '+34.0305856',
        INTPTLON: '-098.2674901',
        ELSDLEA: '',
        UNSDLEA: '34710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8044, 30.3123],
            [-95.7326, 30.3074],
            [-95.6691, 30.2498],
            [-95.5448, 30.2464],
            [-95.5583, 30.1655],
            [-95.667, 30.1718],
            [-95.733, 30.0944],
            [-95.8033, 30.0901],
            [-95.8043, 30.2456],
            [-95.8044, 30.3123],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828740',
        NAME: 'Magnolia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389857723,
        AWATER: 1574698,
        INTPTLAT: '+30.2069159',
        INTPTLON: '-095.7156816',
        ELSDLEA: '',
        UNSDLEA: '28740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2627, 30.276],
            [-94.1952, 30.298],
            [-94.1177, 30.2418],
            [-94.1159, 30.1603],
            [-94.2463, 30.168],
            [-94.2627, 30.276],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813530',
        NAME: 'Lumberton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150803450,
        AWATER: 3553762,
        INTPTLAT: '+30.2267463',
        INTPTLON: '-094.1888703',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9897, 29.5171],
            [-94.9294, 29.4999],
            [-94.8769, 29.5141],
            [-94.8299, 29.422],
            [-94.9406, 29.4186],
            [-95.023, 29.3925],
            [-95.0403, 29.3922],
            [-95.1288, 29.4638],
            [-94.9897, 29.5171],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817070',
        NAME: 'Dickinson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162313744,
        AWATER: 77416891,
        INTPTLAT: '+29.4642960',
        INTPTLON: '-094.9919613',
        ELSDLEA: '',
        UNSDLEA: '17070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3716, 29.5972],
            [-94.3694, 29.5006],
            [-94.5663, 29.4231],
            [-94.564, 29.4711],
            [-94.6456, 29.5492],
            [-94.4982, 29.5448],
            [-94.3716, 29.5972],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823160',
        NAME: 'High Island Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73556417,
        AWATER: 185926000,
        INTPTLAT: '+29.5117283',
        INTPTLON: '-094.4935613',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9406, 29.4186],
            [-94.8299, 29.422],
            [-94.8438, 29.3357],
            [-94.9305, 29.3372],
            [-94.9406, 29.4186],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842510',
        NAME: 'Texas City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47142977,
        AWATER: 66335308,
        INTPTLAT: '+29.3719333',
        INTPTLON: '-094.8820445',
        ELSDLEA: '',
        UNSDLEA: '42510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2255, 29.4541],
            [-95.2081, 29.4544],
            [-95.1288, 29.4638],
            [-95.0403, 29.3922],
            [-95.1034, 29.2714],
            [-95.2255, 29.4541],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839270',
        NAME: 'Santa Fe Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186552843,
        AWATER: 1283762,
        INTPTLAT: '+29.3810154',
        INTPTLON: '-095.1220198',
        ELSDLEA: '',
        UNSDLEA: '39270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1832, 35.5465],
            [-100.0759, 35.5464],
            [-100.0766, 35.43],
            [-99.9997, 35.4284],
            [-100.0004, 35.299],
            [-100.1476, 35.2994],
            [-100.1831, 35.3428],
            [-100.1477, 35.4867],
            [-100.1832, 35.5465],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825290',
        NAME: 'Kelton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 345461618,
        AWATER: 435207,
        INTPTLAT: '+35.4090849',
        INTPTLON: '-100.1077290',
        ELSDLEA: '',
        UNSDLEA: '25290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3153, 28.8291],
            [-96.3107, 28.9226],
            [-96.3092, 28.9633],
            [-96.24, 28.9997],
            [-96.0386, 29.1066],
            [-95.999, 28.9865],
            [-96.04, 28.8708],
            [-95.9972, 28.8098],
            [-96.2184, 28.7719],
            [-96.3153, 28.8291],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842810',
        NAME: 'Tidehaven Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 754147848,
        AWATER: 7489057,
        INTPTLAT: '+28.9160974',
        INTPTLON: '-096.1559204',
        ELSDLEA: '',
        UNSDLEA: '42810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0386, 29.1066],
            [-95.964, 29.1479],
            [-95.9363, 29.0883],
            [-95.958, 29.0463],
            [-95.8959, 28.9688],
            [-95.9093, 28.7832],
            [-95.9907, 28.7819],
            [-95.9972, 28.8098],
            [-96.04, 28.8708],
            [-95.999, 28.9865],
            [-96.0386, 29.1066],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809630',
        NAME: 'Bay City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420718895,
        AWATER: 4586077,
        INTPTLAT: '+28.9390882',
        INTPTLON: '-095.9581458',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9363, 29.0883],
            [-95.8417, 29.1735],
            [-95.7893, 29.07],
            [-95.7649, 28.9674],
            [-95.6554, 28.9517],
            [-95.4956, 28.7648],
            [-95.8199, 28.6069],
            [-95.8748, 28.7583],
            [-95.9093, 28.7832],
            [-95.8959, 28.9688],
            [-95.958, 29.0463],
            [-95.9363, 29.0883],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843950',
        NAME: 'Van Vleck Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 888877761,
        AWATER: 337994886,
        INTPTLAT: '+28.8421355',
        INTPTLON: '-095.7645543',
        ELSDLEA: '',
        UNSDLEA: '43950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0859, 35.9639],
            [-101.0859, 36.0105],
            [-101.0857, 36.0576],
            [-100.9466, 36.0577],
            [-100.5467, 36.0565],
            [-100.5402, 36.0565],
            [-100.5406, 35.6783],
            [-100.5401, 35.5614],
            [-100.6291, 35.5173],
            [-100.6697, 35.6199],
            [-100.8488, 35.6199],
            [-100.9299, 35.6792],
            [-100.9296, 35.9522],
            [-101.042, 35.9281],
            [-101.086, 35.8234],
            [-101.086, 35.8239],
            [-101.0859, 35.9639],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830480',
        NAME: 'Miami Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2014793996,
        AWATER: 315028,
        INTPTLAT: '+35.8441767',
        INTPTLON: '-100.7620988',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5605, 29.503],
            [-98.4917, 29.4881],
            [-98.4539, 29.4682],
            [-98.4355, 29.4497],
            [-98.3607, 29.4461],
            [-98.4448, 29.3162],
            [-98.4573, 29.3351],
            [-98.5287, 29.381],
            [-98.5438, 29.3883],
            [-98.5831, 29.4507],
            [-98.5605, 29.503],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838730',
        NAME: 'San Antonio Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192661656,
        AWATER: 1632300,
        INTPTLAT: '+29.4208835',
        INTPTLON: '-098.4709673',
        ELSDLEA: '',
        UNSDLEA: '38730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5287, 29.381],
            [-98.4573, 29.3351],
            [-98.5183, 29.321],
            [-98.5267, 29.321],
            [-98.5287, 29.381],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822470',
        NAME: 'Harlandale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37295030,
        AWATER: 217189,
        INTPTLAT: '+29.3502634',
        INTPTLON: '-098.4974863',
        ELSDLEA: '',
        UNSDLEA: '22470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5405, 29.6723],
            [-98.5364, 29.6723],
            [-98.4201, 29.6771],
            [-98.3471, 29.5763],
            [-98.3477, 29.5064],
            [-98.4106, 29.4783],
            [-98.4442, 29.4635],
            [-98.4472, 29.4682],
            [-98.4917, 29.4881],
            [-98.5605, 29.503],
            [-98.5405, 29.6723],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832940',
        NAME: 'North East Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342884936,
        AWATER: 1083851,
        INTPTLAT: '+29.5778411',
        INTPTLON: '-098.4506569',
        ELSDLEA: '',
        UNSDLEA: '32940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6269, 29.3675],
            [-98.6014, 29.3848],
            [-98.5438, 29.3883],
            [-98.5287, 29.381],
            [-98.5267, 29.321],
            [-98.6269, 29.3675],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840680',
        NAME: 'South San Antonio Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40681918,
        AWATER: 249856,
        INTPTLAT: '+29.3484371',
        INTPTLON: '-098.5762961',
        ELSDLEA: '',
        UNSDLEA: '40680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4539, 29.4682],
            [-98.4472, 29.4682],
            [-98.4442, 29.4635],
            [-98.4106, 29.4783],
            [-98.4355, 29.4497],
            [-98.4539, 29.4682],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820160',
        NAME: 'Fort Sam Houston Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13757220,
        AWATER: 12024,
        INTPTLAT: '+29.4602104',
        INTPTLON: '-098.4358282',
        ELSDLEA: '',
        UNSDLEA: '20160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1347, 29.9798],
            [-95.0352, 29.9926],
            [-94.9815, 29.8843],
            [-94.9471, 29.8495],
            [-95.104, 29.8403],
            [-95.1414, 29.9235],
            [-95.1347, 29.9798],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815750',
        NAME: 'Crosby Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198125903,
        AWATER: 12786069,
        INTPTLAT: '+29.9160895',
        INTPTLON: '-095.0540273',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2435, 30.1929],
            [-95.1056, 30.1906],
            [-95.0967, 30.1672],
            [-95.1245, 30.1445],
            [-95.2637, 30.0325],
            [-95.3325, 30.1584],
            [-95.2435, 30.1929],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832400',
        NAME: 'New Caney Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222856119,
        AWATER: 2000071,
        INTPTLAT: '+30.1341133',
        INTPTLON: '-095.2371752',
        ELSDLEA: '',
        UNSDLEA: '32400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8426, 30.4875],
            [-94.8494, 30.4936],
            [-94.7753, 30.489],
            [-94.7327, 30.4901],
            [-94.6424, 30.2395],
            [-94.7042, 30.1135],
            [-94.8205, 30.0953],
            [-94.808, 30.2326],
            [-94.7812, 30.3502],
            [-94.8508, 30.4249],
            [-94.8426, 30.4875],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822380',
        NAME: 'Hardin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 535106886,
        AWATER: 15004769,
        INTPTLAT: '+30.2945870',
        INTPTLON: '-094.7561224',
        ELSDLEA: '',
        UNSDLEA: '22380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0481, 30.3969],
            [-94.8426, 30.4875],
            [-94.8508, 30.4249],
            [-94.7812, 30.3502],
            [-94.808, 30.2326],
            [-94.9918, 30.1704],
            [-95.0384, 30.2635],
            [-95.0481, 30.3969],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842210',
        NAME: 'Tarkington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 582778752,
        AWATER: 11172951,
        INTPTLAT: '+30.3181694',
        INTPTLON: '-094.9157156',
        ELSDLEA: '',
        UNSDLEA: '42210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1746, 32.5115],
            [-101.1051, 32.5839],
            [-101.0769, 32.6712],
            [-100.9376, 32.6704],
            [-100.9289, 32.5579],
            [-100.9223, 32.5267],
            [-100.9271, 32.5268],
            [-100.9371, 32.5269],
            [-101.0344, 32.5279],
            [-101.1406, 32.4795],
            [-101.1751, 32.4805],
            [-101.1746, 32.5115],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824300',
        NAME: 'Ira Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316613404,
        AWATER: 1367576,
        INTPTLAT: '+32.5789545',
        INTPTLON: '-101.0457213',
        ELSDLEA: '',
        UNSDLEA: '24300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.117, 32.7291],
            [-100.0073, 32.7951],
            [-99.9786, 32.8732],
            [-99.8817, 32.8783],
            [-99.7997, 32.8389],
            [-99.7825, 32.7661],
            [-99.7068, 32.766],
            [-99.7183, 32.6711],
            [-99.8688, 32.6951],
            [-99.9433, 32.592],
            [-100.0215, 32.655],
            [-100.1181, 32.6612],
            [-100.117, 32.7291],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808400',
        NAME: 'Anson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 686027806,
        AWATER: 3036476,
        INTPTLAT: '+32.7365030',
        INTPTLON: '-099.9133187',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.149, 32.3654],
            [-100.0611, 32.4362],
            [-100.0778, 32.5656],
            [-100.1458, 32.65],
            [-100.1181, 32.6612],
            [-100.0215, 32.655],
            [-99.9433, 32.592],
            [-99.8418, 32.5209],
            [-99.8675, 32.412],
            [-99.9885, 32.3528],
            [-99.9772, 32.2757],
            [-100.0563, 32.2372],
            [-100.0646, 32.3146],
            [-100.149, 32.3654],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830340',
        NAME: 'Merkel Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 717604448,
        AWATER: 601594,
        INTPTLAT: '+32.4598520',
        INTPTLON: '-100.0042746',
        ELSDLEA: '',
        UNSDLEA: '30340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7183, 32.6711],
            [-99.6679, 32.6418],
            [-99.6714, 32.6244],
            [-99.7056, 32.5566],
            [-99.8418, 32.5209],
            [-99.9433, 32.592],
            [-99.8688, 32.6951],
            [-99.7183, 32.6711],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822800',
        NAME: 'Hawley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310516982,
        AWATER: 1167151,
        INTPTLAT: '+32.6066961',
        INTPTLON: '-099.8101634',
        ELSDLEA: '',
        UNSDLEA: '22800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0675, 32.1395],
            [-95.9567, 32.1677],
            [-95.8602, 32.1733],
            [-95.8613, 32.0288],
            [-96.0528, 32.0059],
            [-96.0548, 32.0125],
            [-96.0693, 32.0853],
            [-96.0675, 32.1395],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815840',
        NAME: 'Cross Roads Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283609297,
        AWATER: 6858947,
        INTPTLAT: '+32.0772190',
        INTPTLON: '-095.9553309',
        ELSDLEA: '',
        UNSDLEA: '15840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0031, 32.3572],
            [-95.9448, 32.3726],
            [-95.8942, 32.2921],
            [-95.9868, 32.2277],
            [-96.1151, 32.2486],
            [-96.0759, 32.3575],
            [-96.0031, 32.3572],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818720',
        NAME: 'Eustace Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188697912,
        AWATER: 15483671,
        INTPTLAT: '+32.3010287',
        INTPTLON: '-096.0099335',
        ELSDLEA: '',
        UNSDLEA: '18720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2856, 32.2669],
            [-96.2314, 32.2986],
            [-96.1151, 32.2486],
            [-95.9868, 32.2277],
            [-95.9567, 32.1677],
            [-96.0675, 32.1395],
            [-96.124, 32.1558],
            [-96.2856, 32.2669],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828780',
        NAME: 'Malakoff Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246699826,
        AWATER: 42562696,
        INTPTLAT: '+32.2050104',
        INTPTLON: '-096.0997208',
        ELSDLEA: '',
        UNSDLEA: '28780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0675, 32.1395],
            [-96.0693, 32.0853],
            [-96.124, 32.1558],
            [-96.0675, 32.1395],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843170',
        NAME: 'Trinidad Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36340844,
        AWATER: 3181237,
        INTPTLAT: '+32.1237512',
        INTPTLON: '-096.0867551',
        ELSDLEA: '',
        UNSDLEA: '43170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1114, 29.0863],
            [-99.8692, 29.0886],
            [-99.7991, 29.0029],
            [-99.7703, 28.872],
            [-99.7872, 28.8168],
            [-99.8911, 28.7854],
            [-99.8841, 28.8826],
            [-100.1128, 28.8542],
            [-100.1114, 29.0863],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826250',
        NAME: 'La Pryor Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794411190,
        AWATER: 2718270,
        INTPTLAT: '+28.9497135',
        INTPTLON: '-099.9521857',
        ELSDLEA: '',
        UNSDLEA: '26250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5399, 30.1119],
            [-94.4449, 30.113],
            [-94.4422, 29.8895],
            [-94.5257, 29.8896],
            [-94.6384, 29.8884],
            [-94.6548, 30.008],
            [-94.5399, 30.1119],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816920',
        NAME: 'Devers Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397178916,
        AWATER: 2232129,
        INTPTLAT: '+29.9752770',
        INTPTLON: '-094.5232353',
        ELSDLEA: '',
        UNSDLEA: '16920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6424, 30.2395],
            [-94.5963, 30.1121],
            [-94.5469, 30.1119],
            [-94.5399, 30.1119],
            [-94.6548, 30.008],
            [-94.7042, 30.1135],
            [-94.6424, 30.2395],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823880',
        NAME: 'Hull-Daisetta Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214648711,
        AWATER: 2221756,
        INTPTLAT: '+30.1013889',
        INTPTLON: '-094.6424362',
        ELSDLEA: '',
        UNSDLEA: '23880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.046, 31.3001],
            [-94.0161, 31.4344],
            [-93.9331, 31.3883],
            [-93.8952, 31.2409],
            [-94.0339, 31.2331],
            [-94.046, 31.3001],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845180',
        NAME: 'West Sabine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274986106,
        AWATER: 4258418,
        INTPTLAT: '+31.3046774',
        INTPTLON: '-093.9817478',
        ELSDLEA: '',
        UNSDLEA: '45180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3751, 31.4096],
            [-94.3142, 31.4084],
            [-94.3266, 31.2248],
            [-94.3493, 31.2394],
            [-94.4494, 31.3213],
            [-94.4864, 31.3943],
            [-94.3751, 31.4096],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818660',
        NAME: 'Etoile Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141425164,
        AWATER: 54513522,
        INTPTLAT: '+31.3469106',
        INTPTLON: '-094.3965285',
        ELSDLEA: '',
        UNSDLEA: '18660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4833, 31.595],
            [-94.4553, 31.5934],
            [-94.4302, 31.4549],
            [-94.3751, 31.4096],
            [-94.4864, 31.3943],
            [-94.5692, 31.433],
            [-94.6647, 31.4595],
            [-94.4833, 31.595],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846320',
        NAME: 'Woden Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335118122,
        AWATER: 17254117,
        INTPTLAT: '+31.4819934',
        INTPTLON: '-094.5251875',
        ELSDLEA: '',
        UNSDLEA: '46320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9508, 31.6792],
            [-94.7845, 31.6589],
            [-94.8682, 31.5267],
            [-94.9239, 31.5792],
            [-94.9508, 31.6792],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817460',
        NAME: 'Douglass Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224267433,
        AWATER: 8628756,
        INTPTLAT: '+31.6240788',
        INTPTLON: '-094.8653630',
        ELSDLEA: '',
        UNSDLEA: '17460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0521, 31.8568],
            [-93.8891, 31.8236],
            [-93.9276, 31.7213],
            [-93.815, 31.7287],
            [-93.8349, 31.5862],
            [-93.9569, 31.5277],
            [-93.9845, 31.5694],
            [-94.1013, 31.6216],
            [-94.1476, 31.7472],
            [-94.1134, 31.8308],
            [-94.0521, 31.8568],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839960',
        NAME: 'Shelbyville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663908884,
        AWATER: 31451315,
        INTPTLAT: '+31.7043890',
        INTPTLON: '-093.9780195',
        ELSDLEA: '',
        UNSDLEA: '39960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.816, 34.4482],
            [-102.7633, 34.4821],
            [-102.5258, 34.4914],
            [-102.4805, 34.3983],
            [-102.5263, 34.313],
            [-102.8171, 34.3128],
            [-102.816, 34.4482],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826970',
        NAME: 'Lazbuddie Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 512611431,
        AWATER: 2368961,
        INTPTLAT: '+34.3963527',
        INTPTLON: '-102.6579962',
        ELSDLEA: '',
        UNSDLEA: '26970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4745, 33.7338],
            [-99.4757, 33.834],
            [-99.059, 33.8341],
            [-98.9531, 33.834],
            [-98.9529, 33.7852],
            [-98.9534, 33.6271],
            [-98.9538, 33.4964],
            [-99.0183, 33.4666],
            [-99.0137, 33.3978],
            [-99.4343, 33.3989],
            [-99.4724, 33.399],
            [-99.4737, 33.5796],
            [-99.5403, 33.5957],
            [-99.6052, 33.6272],
            [-99.4745, 33.7338],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839780',
        NAME: 'Seymour Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2265256244,
        AWATER: 86409337,
        INTPTLAT: '+33.6211472',
        INTPTLON: '-099.2437682',
        ELSDLEA: '',
        UNSDLEA: '39780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9943, 33.6635],
            [-99.8319, 33.6733],
            [-99.7532, 33.8071],
            [-99.6948, 33.8272],
            [-99.542, 33.7932],
            [-99.4745, 33.7338],
            [-99.6052, 33.6272],
            [-99.5403, 33.5957],
            [-99.7399, 33.5102],
            [-99.8991, 33.4564],
            [-99.9923, 33.4965],
            [-99.9943, 33.6635],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809980',
        NAME: 'Benjamin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1055454453,
        AWATER: 1609567,
        INTPTLAT: '+33.6356815',
        INTPTLON: '-099.7370532',
        ELSDLEA: '',
        UNSDLEA: '09980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7006, 33.8291],
            [-101.6931, 33.8292],
            [-101.5788, 33.8303],
            [-101.6136, 33.7691],
            [-101.6135, 33.6385],
            [-101.7842, 33.6507],
            [-101.8541, 33.6503],
            [-101.8191, 33.7231],
            [-101.7145, 33.7379],
            [-101.7006, 33.8291],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824120',
        NAME: 'Idalou Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315888640,
        AWATER: 1688648,
        INTPTLAT: '+33.7164474',
        INTPTLON: '-101.6916543',
        ELSDLEA: '',
        UNSDLEA: '24120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7842, 33.6507],
            [-101.6135, 33.6385],
            [-101.5607, 33.6385],
            [-101.558, 33.4907],
            [-101.7625, 33.5344],
            [-101.7809, 33.5345],
            [-101.7842, 33.6507],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837800',
        NAME: 'Roosevelt Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282138605,
        AWATER: 1477572,
        INTPTLAT: '+33.5662912',
        INTPTLON: '-101.6656649',
        ELSDLEA: '',
        UNSDLEA: '37800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9397, 33.5052],
            [-101.7809, 33.5345],
            [-101.7625, 33.5344],
            [-101.7114, 33.3939],
            [-101.8074, 33.3927],
            [-101.9814, 33.3906],
            [-101.9397, 33.5052],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815180',
        NAME: 'Lubbock-Cooper Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302492777,
        AWATER: 1500393,
        INTPTLAT: '+33.4544869',
        INTPTLON: '-101.8487991',
        ELSDLEA: '',
        UNSDLEA: '15180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0848, 33.7807],
            [-101.9747, 33.7808],
            [-101.9399, 33.6503],
            [-101.9399, 33.6286],
            [-102.0818, 33.6503],
            [-102.0833, 33.7184],
            [-102.0848, 33.7807],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839870',
        NAME: 'Shallowater Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194565903,
        AWATER: 852343,
        INTPTLAT: '+33.7007409',
        INTPTLON: '-102.0191727',
        ELSDLEA: '',
        UNSDLEA: '39870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0857, 34.657],
            [-101.9984, 34.6177],
            [-101.9983, 34.4935],
            [-102.0537, 34.4649],
            [-102.1784, 34.4958],
            [-102.2135, 34.5255],
            [-102.1396, 34.6577],
            [-102.0857, 34.657],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832220',
        NAME: 'Nazareth Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279945111,
        AWATER: 1179901,
        INTPTLAT: '+34.5606780',
        INTPTLON: '-102.1008990',
        ELSDLEA: '',
        UNSDLEA: '32220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5233, 27.2651],
            [-98.2325, 27.2625],
            [-98.0581, 27.261],
            [-97.9859, 27.2093],
            [-97.9855, 26.7809],
            [-98.3207, 26.7831],
            [-98.4226, 26.7835],
            [-98.4179, 27.0553],
            [-98.4665, 27.0547],
            [-98.4929, 27.235],
            [-98.5233, 27.2651],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811580',
        NAME: 'Brooks County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2443273797,
        AWATER: 741627,
        INTPTLAT: '+27.0349936',
        INTPTLON: '-098.2152756',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8686, 28.4967],
            [-97.7598, 28.469],
            [-97.7037, 28.5609],
            [-97.5771, 28.5225],
            [-97.5539, 28.4553],
            [-97.4379, 28.4097],
            [-97.3957, 28.3615],
            [-97.496, 28.2251],
            [-97.5117, 28.3244],
            [-97.689, 28.3559],
            [-97.7511, 28.3272],
            [-97.8449, 28.3883],
            [-97.9144, 28.3639],
            [-97.9611, 28.444],
            [-97.8686, 28.4967],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809720',
        NAME: 'Beeville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 896600442,
        AWATER: 36595,
        INTPTLAT: '+28.3970483',
        INTPTLON: '-097.6674303',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.478, 27.8898],
            [-97.4482, 28.05],
            [-97.3181, 28.0695],
            [-97.3171, 28.0081],
            [-97.3706, 27.8766],
            [-97.4764, 27.861],
            [-97.4776, 27.8606],
            [-97.478, 27.8898],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842060',
        NAME: 'Taft Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242474728,
        AWATER: 74648,
        INTPTLAT: '+27.9685697',
        INTPTLON: '-097.3922143',
        ELSDLEA: '',
        UNSDLEA: '42060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1952, 27.9135],
            [-97.1872, 27.9745],
            [-97.1036, 27.9466],
            [-97.136, 27.8304],
            [-97.1867, 27.8214],
            [-97.1882, 27.8242],
            [-97.1952, 27.9135],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808580',
        NAME: 'Aransas Pass Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74874811,
        AWATER: 69655683,
        INTPTLAT: '+27.9149271',
        INTPTLON: '-097.1411814',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3181, 28.0695],
            [-97.2596, 28.0736],
            [-97.1872, 27.9745],
            [-97.1952, 27.9135],
            [-97.2486, 27.8749],
            [-97.3706, 27.8766],
            [-97.3171, 28.0081],
            [-97.3181, 28.0695],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821780',
        NAME: 'Gregory-Portland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267375464,
        AWATER: 3771511,
        INTPTLAT: '+27.9707020',
        INTPTLON: '-097.2804139',
        ELSDLEA: '',
        UNSDLEA: '21780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7029, 28.1318],
            [-97.5676, 28.1297],
            [-97.5411, 28.1647],
            [-97.3192, 28.126],
            [-97.2608, 28.0758],
            [-97.2596, 28.0736],
            [-97.3181, 28.0695],
            [-97.4482, 28.05],
            [-97.478, 27.8898],
            [-97.53, 27.9925],
            [-97.69, 27.9919],
            [-97.7029, 28.1318],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840350',
        NAME: 'Sinton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 577199208,
        AWATER: 1069190,
        INTPTLAT: '+28.0680096',
        INTPTLON: '-097.5260151',
        ELSDLEA: '',
        UNSDLEA: '40350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5973, 33.8751],
            [-96.501, 33.7728],
            [-96.4571, 33.7757],
            [-96.3795, 33.7258],
            [-96.3796, 33.721],
            [-96.4764, 33.6707],
            [-96.6042, 33.7202],
            [-96.6649, 33.8121],
            [-96.5973, 33.8751],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816710',
        NAME: 'Denison Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239146204,
        AWATER: 15308716,
        INTPTLAT: '+33.7590603',
        INTPTLON: '-096.5390443',
        ELSDLEA: '',
        UNSDLEA: '16710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7724, 33.5199],
            [-96.6628, 33.5845],
            [-96.562, 33.5793],
            [-96.5561, 33.4948],
            [-96.6616, 33.4384],
            [-96.7724, 33.5199],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823670',
        NAME: 'Howe Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190435832,
        AWATER: 719570,
        INTPTLAT: '+33.5167443',
        INTPTLON: '-096.6492193',
        ELSDLEA: '',
        UNSDLEA: '23670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7425, 32.5467],
            [-96.7069, 32.5595],
            [-96.53, 32.5453],
            [-96.5006, 32.4947],
            [-96.4958, 32.4683],
            [-96.5707, 32.4612],
            [-96.6645, 32.4837],
            [-96.7831, 32.4517],
            [-96.7425, 32.5467],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819170',
        NAME: 'Ferris Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205142241,
        AWATER: 2089916,
        INTPTLAT: '+32.5114845',
        INTPTLON: '-096.6343595',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6661, 33.6946],
            [-96.6042, 33.7202],
            [-96.4764, 33.6707],
            [-96.5131, 33.614],
            [-96.562, 33.5793],
            [-96.6628, 33.5845],
            [-96.6661, 33.6946],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840080',
        NAME: 'Sherman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199231198,
        AWATER: 260029,
        INTPTLAT: '+33.6409340',
        INTPTLON: '-096.5908349',
        ELSDLEA: '',
        UNSDLEA: '40080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1767, 32.9225],
            [-97.1102, 32.9863],
            [-97.0872, 32.9898],
            [-97.0323, 32.9893],
            [-97.0329, 32.9043],
            [-97.0338, 32.8342],
            [-97.173, 32.8638],
            [-97.1771, 32.8853],
            [-97.1767, 32.9225],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821660',
        NAME: 'Grapevine-Colleyville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130671215,
        AWATER: 9949689,
        INTPTLAT: '+32.9110768',
        INTPTLON: '-097.0861863',
        ELSDLEA: '',
        UNSDLEA: '21660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4133, 31.3108],
            [-96.2366, 31.4134],
            [-96.1707, 31.4437],
            [-96.0659, 31.3463],
            [-95.9967, 31.211],
            [-96.0612, 31.1594],
            [-96.0905, 31.2086],
            [-96.1934, 31.17],
            [-96.1934, 31.1024],
            [-96.2558, 31.0679],
            [-96.2994, 31.1842],
            [-96.4133, 31.3108],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827180',
        NAME: 'Leon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 786418149,
        AWATER: 15483077,
        INTPTLAT: '+31.2914290',
        INTPTLON: '-096.2015392',
        ELSDLEA: '',
        UNSDLEA: '27180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9408, 31.6114],
            [-95.8296, 31.6919],
            [-95.7272, 31.6438],
            [-95.7393, 31.5041],
            [-95.6938, 31.3871],
            [-95.819, 31.4375],
            [-95.8762, 31.4875],
            [-95.881, 31.5614],
            [-95.9408, 31.6114],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833340',
        NAME: 'Oakwood Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402136554,
        AWATER: 3391633,
        INTPTLAT: '+31.5544684',
        INTPTLON: '-095.8110079',
        ELSDLEA: '',
        UNSDLEA: '33340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0659, 31.3463],
            [-95.9505, 31.2913],
            [-95.805, 31.3394],
            [-95.819, 31.4375],
            [-95.6938, 31.3871],
            [-95.6764, 31.3688],
            [-95.7479, 31.2023],
            [-95.7644, 31.0942],
            [-95.9917, 31.0843],
            [-96.0612, 31.1594],
            [-95.9967, 31.211],
            [-96.0659, 31.3463],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813410',
        NAME: 'Centerville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 920002478,
        AWATER: 2154003,
        INTPTLAT: '+31.2434940',
        INTPTLON: '-095.8622344',
        ELSDLEA: '',
        UNSDLEA: '13410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.674, 33.1839],
            [-97.5847, 33.1312],
            [-97.6677, 33.052],
            [-97.7279, 33.0395],
            [-97.7592, 33.1775],
            [-97.674, 33.1839],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834260',
        NAME: 'Paradise Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187469372,
        AWATER: 288848,
        INTPTLAT: '+33.1156481',
        INTPTLON: '-097.7161614',
        ELSDLEA: '',
        UNSDLEA: '34260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.92, 33.1439],
            [-97.9189, 33.237],
            [-97.7733, 33.2275],
            [-97.6878, 33.2615],
            [-97.6931, 33.2547],
            [-97.674, 33.1839],
            [-97.7592, 33.1775],
            [-97.7279, 33.0395],
            [-97.7846, 33.0195],
            [-97.9209, 33.0549],
            [-97.92, 33.1439],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811340',
        NAME: 'Bridgeport Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343276859,
        AWATER: 34592036,
        INTPTLAT: '+33.1579781',
        INTPTLON: '-097.8169432',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9907, 28.7819],
            [-95.9093, 28.7832],
            [-95.8748, 28.7583],
            [-95.8199, 28.6069],
            [-96.0003, 28.5334],
            [-96.0285, 28.5893],
            [-95.9907, 28.7819],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829370',
        NAME: 'Matagorda Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162118911,
        AWATER: 208398526,
        INTPTLAT: '+28.6516495',
        INTPTLON: '-095.8769631',
        ELSDLEA: '',
        UNSDLEA: '29370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9894, 30.2422],
            [-93.9015, 30.2427],
            [-93.707, 30.2437],
            [-93.7131, 30.1072],
            [-93.8394, 30.1656],
            [-93.9227, 30.1526],
            [-93.9894, 30.2422],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827690',
        NAME: 'Little Cypress-Mauriceville Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251492230,
        AWATER: 9070470,
        INTPTLAT: '+30.1939846',
        INTPTLON: '-093.8264322',
        ELSDLEA: '',
        UNSDLEA: '27690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5756, 32.9543],
            [-98.4272, 32.9523],
            [-98.4266, 33.0079],
            [-98.3128, 33.0062],
            [-98.291, 33.0609],
            [-98.2047, 33.0266],
            [-98.113, 32.9457],
            [-98.2178, 32.8687],
            [-98.2754, 32.8802],
            [-98.3886, 32.8104],
            [-98.426, 32.8718],
            [-98.5757, 32.8573],
            [-98.5756, 32.9543],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821330',
        NAME: 'Graford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 562899033,
        AWATER: 50407571,
        INTPTLAT: '+32.9432815',
        INTPTLON: '-098.3685760',
        ELSDLEA: '',
        UNSDLEA: '21330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6923, 32.2652],
            [-101.6919, 32.2992],
            [-101.5901, 32.3116],
            [-101.5106, 32.3872],
            [-101.5857, 32.4586],
            [-101.5174, 32.5249],
            [-101.3284, 32.5243],
            [-101.3285, 32.4592],
            [-101.4319, 32.4187],
            [-101.3978, 32.3061],
            [-101.4259, 32.2248],
            [-101.5396, 32.2444],
            [-101.5911, 32.1863],
            [-101.6934, 32.1961],
            [-101.6923, 32.2652],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810200',
        NAME: 'Big Spring Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 622478652,
        AWATER: 2054561,
        INTPTLAT: '+32.3441948',
        INTPTLON: '-101.5049229',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3284, 32.5243],
            [-101.1746, 32.5241],
            [-101.1746, 32.5115],
            [-101.1751, 32.4805],
            [-101.1817, 32.1953],
            [-101.3489, 32.1462],
            [-101.4259, 32.2248],
            [-101.3978, 32.3061],
            [-101.4319, 32.4187],
            [-101.3285, 32.4592],
            [-101.3284, 32.5243],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814490',
        NAME: 'Coahoma Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 784228308,
        AWATER: 2977147,
        INTPTLAT: '+32.3388234',
        INTPTLON: '-101.2847167',
        ELSDLEA: '',
        UNSDLEA: '14490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6714, 32.6244],
            [-99.6679, 32.6418],
            [-99.612, 32.662],
            [-99.474, 32.6589],
            [-99.4677, 32.5995],
            [-99.4416, 32.5149],
            [-99.4721, 32.4008],
            [-99.4573, 32.0801],
            [-99.6314, 32.0813],
            [-99.6307, 32.2281],
            [-99.6739, 32.2701],
            [-99.6309, 32.2994],
            [-99.5264, 32.3112],
            [-99.5429, 32.4932],
            [-99.6314, 32.4745],
            [-99.6907, 32.4965],
            [-99.6714, 32.6244],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814450',
        NAME: 'Clyde Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876522332,
        AWATER: 3602422,
        INTPTLAT: '+32.5224442',
        INTPTLON: '-099.5126391',
        ELSDLEA: '',
        UNSDLEA: '14450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7997, 32.8389],
            [-99.7377, 32.9021],
            [-99.6276, 32.9308],
            [-99.4713, 32.957],
            [-99.4456, 32.957],
            [-99.4455, 32.8112],
            [-99.6121, 32.8254],
            [-99.612, 32.662],
            [-99.6679, 32.6418],
            [-99.7183, 32.6711],
            [-99.7068, 32.766],
            [-99.7825, 32.7661],
            [-99.7997, 32.8389],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828530',
        NAME: 'Lueders-Avoca Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612451926,
        AWATER: 2052320,
        INTPTLAT: '+32.8334174',
        INTPTLON: '-099.6255526',
        ELSDLEA: '',
        UNSDLEA: '28530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8418, 32.5209],
            [-99.7056, 32.5566],
            [-99.6714, 32.6244],
            [-99.6907, 32.4965],
            [-99.6314, 32.4745],
            [-99.6782, 32.416],
            [-99.7424, 32.3868],
            [-99.8675, 32.412],
            [-99.8418, 32.5209],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807440',
        NAME: 'Abilene Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262145407,
        AWATER: 14073403,
        INTPTLAT: '+32.4755737',
        INTPTLON: '-099.7539612',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7761, 32.0869],
            [-101.5606, 32.0871],
            [-101.2642, 32.0871],
            [-101.2671, 31.6509],
            [-101.7758, 31.6513],
            [-101.7759, 31.8289],
            [-101.7761, 32.0869],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820790',
        NAME: 'Glasscock County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2331552809,
        AWATER: 2245298,
        INTPTLAT: '+31.8680062',
        INTPTLON: '-101.5214944',
        ELSDLEA: '',
        UNSDLEA: '20790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.108, 29.616],
            [-98.0676, 29.6558],
            [-98.046, 29.629],
            [-97.9296, 29.6072],
            [-97.8913, 29.7461],
            [-97.8002, 29.7659],
            [-97.77, 29.7034],
            [-97.7739, 29.6444],
            [-97.6839, 29.5828],
            [-97.7276, 29.525],
            [-97.8039, 29.424],
            [-97.8734, 29.3771],
            [-98.002, 29.3789],
            [-98.0735, 29.4616],
            [-98.0371, 29.5084],
            [-98.108, 29.616],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839690',
        NAME: 'Seguin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 955775973,
        AWATER: 4971440,
        INTPTLAT: '+29.5588318',
        INTPTLON: '-097.8985122',
        ELSDLEA: '',
        UNSDLEA: '39690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3109, 29.5945],
            [-98.1772, 29.606],
            [-98.1613, 29.4777],
            [-98.2695, 29.4971],
            [-98.2669, 29.5178],
            [-98.2765, 29.5492],
            [-98.2989, 29.5307],
            [-98.3109, 29.5945],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839480',
        NAME: 'Schertz-Cibolo-Universal City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155109889,
        AWATER: 485579,
        INTPTLAT: '+29.5482898',
        INTPTLON: '-098.2445505',
        ELSDLEA: '',
        UNSDLEA: '39480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1772, 29.606],
            [-98.1255, 29.6379],
            [-98.108, 29.616],
            [-98.0371, 29.5084],
            [-98.0735, 29.4616],
            [-98.1211, 29.449],
            [-98.1613, 29.4777],
            [-98.1772, 29.606],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829100',
        NAME: 'Marion Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216932992,
        AWATER: 28888,
        INTPTLAT: '+29.5413035',
        INTPTLON: '-098.1118440',
        ELSDLEA: '',
        UNSDLEA: '29100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9993, 29.7524],
            [-97.8913, 29.7461],
            [-97.9296, 29.6072],
            [-98.046, 29.629],
            [-98.0585, 29.7077],
            [-97.9993, 29.7524],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832160',
        NAME: 'Navarro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222287007,
        AWATER: 1150031,
        INTPTLAT: '+29.6890011',
        INTPTLON: '-097.9798953',
        ELSDLEA: '',
        UNSDLEA: '32160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9434, 33.537],
            [-96.8364, 33.5009],
            [-96.8348, 33.4055],
            [-96.9437, 33.4452],
            [-96.9434, 33.537],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842870',
        NAME: 'Tioga Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80034746,
        AWATER: 9536558,
        INTPTLAT: '+33.4646373',
        INTPTLON: '-096.8927626',
        ELSDLEA: '',
        UNSDLEA: '42870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5847, 33.1312],
            [-97.4829, 33.1212],
            [-97.519, 33.0088],
            [-97.5782, 33.0014],
            [-97.6677, 33.052],
            [-97.5847, 33.1312],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811010',
        NAME: 'Boyd Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175888218,
        AWATER: 195203,
        INTPTLAT: '+33.0725929',
        INTPTLON: '-097.5796332',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.61, 33.434],
            [-97.5822, 33.434],
            [-97.5522, 33.4009],
            [-97.6931, 33.2547],
            [-97.6878, 33.2615],
            [-97.7503, 33.3419],
            [-97.88, 33.434],
            [-97.61, 33.434],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808100',
        NAME: 'Alvord Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294719411,
        AWATER: 1054759,
        INTPTLAT: '+33.3798458',
        INTPTLON: '-097.7011651',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3888, 31.0872],
            [-102.3012, 31.0862],
            [-102.0482, 31.0832],
            [-101.7762, 31.0798],
            [-101.2748, 31.0794],
            [-100.9622, 31.0825],
            [-100.9606, 30.7061],
            [-100.9606, 30.2878],
            [-101.7584, 30.288],
            [-101.6585, 30.3579],
            [-101.7404, 30.4209],
            [-101.6465, 30.5511],
            [-101.6698, 30.6379],
            [-101.7684, 30.6531],
            [-101.8345, 30.7579],
            [-101.8743, 30.9122],
            [-101.9845, 30.9874],
            [-102.0817, 30.9867],
            [-102.2927, 31.0367],
            [-102.3888, 31.0872],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815690',
        NAME: 'Crockett County Consolidated Common School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7270834113,
        AWATER: 53329,
        INTPTLAT: '+30.7175316',
        INTPTLON: '-101.4042113',
        ELSDLEA: '',
        UNSDLEA: '15690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9646, 30.9214],
            [-98.5177, 30.922],
            [-98.4458, 30.9214],
            [-98.526, 30.8629],
            [-98.5246, 30.7592],
            [-98.4391, 30.7439],
            [-98.4174, 30.6432],
            [-98.352, 30.5584],
            [-98.351, 30.4861],
            [-98.5917, 30.4999],
            [-98.9642, 30.4985],
            [-98.9646, 30.9214],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827810',
        NAME: 'Llano Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2307692770,
        AWATER: 33549018,
        INTPTLAT: '+30.6998083',
        INTPTLON: '-098.7059427',
        ELSDLEA: '',
        UNSDLEA: '27810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6927, 31.476],
            [-100.5916, 31.4765],
            [-100.5237, 31.4771],
            [-100.4363, 31.5488],
            [-100.3753, 31.4509],
            [-100.4641, 31.3787],
            [-100.4856, 31.2481],
            [-100.5523, 31.3188],
            [-100.6909, 31.3379],
            [-100.6927, 31.476],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838700',
        NAME: 'San Angelo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499549861,
        AWATER: 35689904,
        INTPTLAT: '+31.3989736',
        INTPTLON: '-100.5241256',
        ELSDLEA: '',
        UNSDLEA: '38700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3753, 31.4509],
            [-100.2092, 31.4323],
            [-100.112, 31.4892],
            [-100.1152, 31.088],
            [-100.2795, 31.0884],
            [-100.2795, 31.2431],
            [-100.4856, 31.2481],
            [-100.4641, 31.3787],
            [-100.3753, 31.4509],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844410',
        NAME: 'Wall Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1031629587,
        AWATER: 129029,
        INTPTLAT: '+31.2939260',
        INTPTLON: '-100.2632459',
        ELSDLEA: '',
        UNSDLEA: '44410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5187, 33.8357],
            [-100.0713, 33.8361],
            [-100.0718, 33.7139],
            [-99.9943, 33.6635],
            [-99.9923, 33.4965],
            [-99.991, 33.3974],
            [-100.0041, 33.3976],
            [-100.5174, 33.3979],
            [-100.5179, 33.5706],
            [-100.5187, 33.8357],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822020',
        NAME: 'Guthrie Common School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2248423371,
        AWATER: 6260549,
        INTPTLAT: '+33.6057820',
        INTPTLON: '-100.2655891',
        ELSDLEA: '',
        UNSDLEA: '22020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4535, 32.3312],
            [-100.2281, 32.404],
            [-100.149, 32.3654],
            [-100.0646, 32.3146],
            [-100.0563, 32.2372],
            [-100.0565, 32.1637],
            [-100.2354, 32.0363],
            [-100.4289, 32.0136],
            [-100.5125, 32.0833],
            [-100.5124, 32.1583],
            [-100.4084, 32.1999],
            [-100.4535, 32.3312],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800005',
        NAME: 'Blackwell Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1209551407,
        AWATER: 8052695,
        INTPTLAT: '+32.2243897',
        INTPTLON: '-100.2956929',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8216, 32.0866],
            [-100.6654, 32.0854],
            [-100.5125, 32.0833],
            [-100.4289, 32.0136],
            [-100.4255, 31.7396],
            [-100.4734, 31.6933],
            [-100.5363, 31.6935],
            [-100.5486, 31.7868],
            [-100.7672, 31.8778],
            [-100.8237, 31.8501],
            [-100.8216, 32.0866],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837380',
        NAME: 'Robert Lee Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1209240536,
        AWATER: 30817898,
        INTPTLAT: '+31.9277479',
        INTPTLON: '-100.5975969',
        ELSDLEA: '',
        UNSDLEA: '37380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2676, 31.5565],
            [-100.8653, 31.5623],
            [-100.8254, 31.6962],
            [-100.8237, 31.8501],
            [-100.7672, 31.8778],
            [-100.5486, 31.7868],
            [-100.5363, 31.6935],
            [-100.6008, 31.598],
            [-100.5916, 31.4765],
            [-100.6927, 31.476],
            [-100.6931, 31.524],
            [-101.2679, 31.5287],
            [-101.2676, 31.5565],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844710',
        NAME: 'Water Valley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1124845562,
        AWATER: 303637,
        INTPTLAT: '+31.6606485',
        INTPTLON: '-100.7556774',
        ELSDLEA: '',
        UNSDLEA: '44710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0438, 34.1716],
            [-103.0438, 34.2144],
            [-102.9058, 34.3127],
            [-102.8171, 34.3128],
            [-102.5263, 34.313],
            [-102.5365, 34.2117],
            [-102.6154, 34.2123],
            [-102.6578, 34.0628],
            [-102.7373, 34.0637],
            [-102.7387, 33.9507],
            [-102.8835, 33.9513],
            [-102.8826, 34.0765],
            [-103.0436, 34.0781],
            [-103.0438, 34.1716],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831890',
        NAME: 'Muleshoe Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1211790402,
        AWATER: 748609,
        INTPTLAT: '+34.1727848',
        INTPTLON: '-102.8069569',
        ELSDLEA: '',
        UNSDLEA: '31890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7405, 28.9914],
            [-97.6126, 28.8543],
            [-97.6067, 28.7905],
            [-97.6538, 28.757],
            [-97.7367, 28.8484],
            [-97.7914, 28.9045],
            [-97.7405, 28.9914],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838310',
        NAME: 'Runge Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247061492,
        AWATER: 1048091,
        INTPTLAT: '+28.8688749',
        INTPTLON: '-097.6907858',
        ELSDLEA: '',
        UNSDLEA: '38310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5486, 32.7445],
            [-97.5012, 32.8162],
            [-97.4672, 32.8624],
            [-97.443, 32.8219],
            [-97.4079, 32.7965],
            [-97.3742, 32.782],
            [-97.3817, 32.7872],
            [-97.3865, 32.7896],
            [-97.3893, 32.799],
            [-97.3935, 32.8015],
            [-97.3601, 32.8322],
            [-97.2904, 32.8243],
            [-97.2038, 32.7903],
            [-97.1494, 32.7761],
            [-97.2316, 32.6693],
            [-97.2438, 32.6528],
            [-97.3278, 32.6588],
            [-97.4532, 32.6466],
            [-97.4801, 32.5925],
            [-97.5413, 32.5984],
            [-97.5486, 32.7445],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819700',
        NAME: 'Fort Worth Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513100443,
        AWATER: 31156081,
        INTPTLAT: '+32.7166577',
        INTPTLON: '-097.3738906',
        ELSDLEA: '',
        UNSDLEA: '19700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1494, 32.7761],
            [-97.0347, 32.7885],
            [-97.0371, 32.6454],
            [-97.1711, 32.6343],
            [-97.2316, 32.6693],
            [-97.1494, 32.7761],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808700',
        NAME: 'Arlington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226185913,
        AWATER: 3119317,
        INTPTLAT: '+32.7168560',
        INTPTLON: '-097.1055333',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5126, 32.9939],
            [-97.4487, 32.924],
            [-97.3318, 32.8873],
            [-97.2899, 32.8535],
            [-97.2904, 32.8243],
            [-97.3601, 32.8322],
            [-97.443, 32.8219],
            [-97.4672, 32.8624],
            [-97.5126, 32.9939],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817700',
        NAME: 'Eagle Mountain-Saginaw Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174286038,
        AWATER: 17820025,
        INTPTLAT: '+32.8968751',
        INTPTLON: '-097.4211825',
        ELSDLEA: '',
        UNSDLEA: '17700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2904, 32.8243],
            [-97.2899, 32.8535],
            [-97.1771, 32.8853],
            [-97.173, 32.8638],
            [-97.2038, 32.7903],
            [-97.2904, 32.8243],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810230',
        NAME: 'Birdville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103619032,
        AWATER: 667457,
        INTPTLAT: '+32.8366131',
        INTPTLON: '-097.2369074',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7991, 32.0858],
            [-102.287, 32.087],
            [-102.2873, 31.6513],
            [-102.318, 31.6513],
            [-102.7672, 31.6517],
            [-102.7989, 31.6518],
            [-102.7984, 31.8636],
            [-102.7991, 32.0858],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818000',
        NAME: 'Ector County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2325094451,
        AWATER: 10597923,
        INTPTLAT: '+31.8653008',
        INTPTLON: '-102.5425068',
        ELSDLEA: '',
        UNSDLEA: '18000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4174, 30.6432],
            [-98.3756, 30.6844],
            [-98.243, 30.694],
            [-98.0666, 30.5931],
            [-98.0697, 30.5872],
            [-98.0726, 30.5818],
            [-97.9905, 30.5097],
            [-98.0102, 30.4803],
            [-98.1119, 30.3801],
            [-98.1256, 30.4262],
            [-98.351, 30.4861],
            [-98.352, 30.5584],
            [-98.4174, 30.6432],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829010',
        NAME: 'Marble Falls Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 731403761,
        AWATER: 37198464,
        INTPTLAT: '+30.5572934',
        INTPTLON: '-098.2123188',
        ELSDLEA: '',
        UNSDLEA: '29010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3436, 31.8341],
            [-96.3337, 31.9435],
            [-96.1993, 31.974],
            [-96.0548, 32.0125],
            [-96.0528, 32.0059],
            [-96.0062, 31.9243],
            [-95.9834, 31.79],
            [-95.8669, 31.7281],
            [-95.8296, 31.6919],
            [-95.9408, 31.6114],
            [-96.0173, 31.6164],
            [-96.0865, 31.6459],
            [-96.1896, 31.6341],
            [-96.1957, 31.7171],
            [-96.2522, 31.746],
            [-96.3436, 31.8341],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818940',
        NAME: 'Fairfield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1133198546,
        AWATER: 61611772,
        INTPTLAT: '+31.8027771',
        INTPTLON: '-096.1165568',
        ELSDLEA: '',
        UNSDLEA: '18940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7589, 32.142],
            [-96.7552, 32.1438],
            [-96.7149, 32.1633],
            [-96.5932, 32.2229],
            [-96.4874, 32.1691],
            [-96.5695, 32.1432],
            [-96.5371, 32.0716],
            [-96.6173, 32.0125],
            [-96.7262, 31.9548],
            [-96.7771, 32.1029],
            [-96.7589, 32.142],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810470',
        NAME: 'Blooming Grove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422601227,
        AWATER: 7053601,
        INTPTLAT: '+32.0917042',
        INTPTLON: '-096.6542513',
        ELSDLEA: '',
        UNSDLEA: '10470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5932, 32.2229],
            [-96.4337, 32.2788],
            [-96.3489, 32.2671],
            [-96.4058, 32.1949],
            [-96.4874, 32.1691],
            [-96.5932, 32.2229],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836960',
        NAME: 'Rice Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162513247,
        AWATER: 2229179,
        INTPTLAT: '+32.2291128',
        INTPTLON: '-096.4755085',
        ELSDLEA: '',
        UNSDLEA: '36960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1114, 29.0863],
            [-100.1128, 28.8542],
            [-100.1143, 28.6481],
            [-100.1137, 28.1978],
            [-100.2122, 28.1968],
            [-100.2943, 28.2844],
            [-100.3362, 28.4302],
            [-100.5004, 28.662],
            [-100.5466, 28.8249],
            [-100.6316, 28.9028],
            [-100.6675, 29.0843],
            [-100.1114, 29.0863],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817730',
        NAME: 'Eagle Pass Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3313824009,
        AWATER: 31864676,
        INTPTLAT: '+28.7297873',
        INTPTLON: '-100.3166780',
        ELSDLEA: '',
        UNSDLEA: '17730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0589, 32.8708],
            [-97.9834, 32.9416],
            [-97.8857, 32.8195],
            [-98.0605, 32.806],
            [-98.0589, 32.8708],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820370',
        NAME: 'Garner Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131537378,
        AWATER: 1851727,
        INTPTLAT: '+32.8612757',
        INTPTLON: '-097.9733148',
        ELSDLEA: '',
        UNSDLEA: '20370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2178, 32.8687],
            [-98.113, 32.9457],
            [-98.0589, 32.8708],
            [-98.0605, 32.806],
            [-98.0686, 32.7026],
            [-98.171, 32.742],
            [-98.2269, 32.7679],
            [-98.2178, 32.8687],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830950',
        NAME: 'Mineral Wells Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331220064,
        AWATER: 3029384,
        INTPTLAT: '+32.8255798',
        INTPTLON: '-098.1446784',
        ELSDLEA: '',
        UNSDLEA: '30950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9532, 32.9415],
            [-97.9209, 33.0549],
            [-97.7846, 33.0195],
            [-97.7875, 32.9575],
            [-97.832, 32.8976],
            [-97.9532, 32.9415],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835340',
        NAME: 'Poolville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189831850,
        AWATER: 565905,
        INTPTLAT: '+32.9829272',
        INTPTLON: '-097.8679131',
        ELSDLEA: '',
        UNSDLEA: '35340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3808, 32.5351],
            [-97.3172, 32.6067],
            [-97.2633, 32.5912],
            [-97.2024, 32.524],
            [-97.2134, 32.5187],
            [-97.2122, 32.5169],
            [-97.2118, 32.5163],
            [-97.2737, 32.4547],
            [-97.3808, 32.5351],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812180',
        NAME: 'Burleson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130534542,
        AWATER: 220637,
        INTPTLAT: '+32.5278184',
        INTPTLON: '-097.2963853',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6154, 32.3477],
            [-97.6048, 32.3559],
            [-97.5219, 32.3568],
            [-97.4253, 32.4443],
            [-97.3515, 32.3919],
            [-97.3038, 32.3948],
            [-97.2726, 32.3402],
            [-97.2812, 32.2962],
            [-97.3279, 32.2574],
            [-97.4672, 32.3003],
            [-97.5363, 32.2307],
            [-97.512, 32.1612],
            [-97.6151, 32.2036],
            [-97.6154, 32.3477],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814310',
        NAME: 'Cleburne Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494956623,
        AWATER: 12365674,
        INTPTLAT: '+32.3124398',
        INTPTLON: '-097.4660939',
        ELSDLEA: '',
        UNSDLEA: '14310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.931, 32.4706],
            [-97.893, 32.5572],
            [-97.8758, 32.5573],
            [-97.6696, 32.5561],
            [-97.5505, 32.5624],
            [-97.6525, 32.4526],
            [-97.6048, 32.3559],
            [-97.6154, 32.3477],
            [-97.7878, 32.3139],
            [-97.931, 32.4706],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821390',
        NAME: 'Granbury Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 617152856,
        AWATER: 34947524,
        INTPTLAT: '+32.4492740',
        INTPTLON: '-097.7477360',
        ELSDLEA: '',
        UNSDLEA: '21390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.97, 32.0868],
            [-101.7761, 32.0869],
            [-101.7759, 31.8289],
            [-101.8479, 31.9947],
            [-101.9819, 31.9624],
            [-102.0182, 32.0746],
            [-101.97, 32.0868],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821750',
        NAME: 'Greenwood Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344304122,
        AWATER: 812410,
        INTPTLAT: '+31.9983140',
        INTPTLON: '-101.8618636',
        ELSDLEA: '',
        UNSDLEA: '21750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.287, 32.087],
            [-102.2112, 32.0868],
            [-101.97, 32.0868],
            [-102.0182, 32.0746],
            [-101.9819, 31.9624],
            [-101.8479, 31.9947],
            [-101.7759, 31.8289],
            [-101.7758, 31.6513],
            [-102.2873, 31.6513],
            [-102.287, 32.087],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830570',
        NAME: 'Midland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1987344782,
        AWATER: 3708341,
        INTPTLAT: '+31.8569957',
        INTPTLON: '-102.0588435',
        ELSDLEA: '',
        UNSDLEA: '30570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4416, 32.5149],
            [-99.1698, 32.515],
            [-99.1151, 32.4591],
            [-99.1172, 32.2817],
            [-99.3545, 32.2582],
            [-99.3976, 32.2201],
            [-99.4061, 32.0801],
            [-99.4573, 32.0801],
            [-99.4721, 32.4008],
            [-99.4416, 32.5149],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809280',
        NAME: 'Baird Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 969011299,
        AWATER: 2363301,
        INTPTLAT: '+32.3597273',
        INTPTLON: '-099.3064790',
        ELSDLEA: '',
        UNSDLEA: '09280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3223, 32.4677],
            [-95.2267, 32.4028],
            [-95.2525, 32.3228],
            [-95.2582, 32.2952],
            [-95.3318, 32.2434],
            [-95.3279, 32.1815],
            [-95.4701, 32.174],
            [-95.488, 32.2355],
            [-95.4492, 32.3552],
            [-95.5003, 32.3997],
            [-95.4973, 32.4314],
            [-95.3223, 32.4677],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843470',
        NAME: 'Tyler Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514753098,
        AWATER: 18638624,
        INTPTLAT: '+32.3291286',
        INTPTLON: '-095.3714316',
        ELSDLEA: '',
        UNSDLEA: '43470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0001, 26.4482],
            [-97.9083, 26.4332],
            [-97.923, 26.3572],
            [-97.937, 26.3213],
            [-98.0125, 26.3324],
            [-98.0001, 26.4482],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831230',
        NAME: 'Monte Alto Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110134613,
        AWATER: 9698247,
        INTPTLAT: '+26.3908751',
        INTPTLON: '-097.9682037',
        ELSDLEA: '',
        UNSDLEA: '31230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2826, 26.3265],
            [-98.2382, 26.2914],
            [-98.2794, 26.1499],
            [-98.3117, 26.1543],
            [-98.2826, 26.3265],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839930',
        NAME: 'Sharyland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68132763,
        AWATER: 100033,
        INTPTLAT: '+26.2413079',
        INTPTLON: '-098.2769470',
        ELSDLEA: '',
        UNSDLEA: '39930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2495, 26.146],
            [-98.2268, 26.1433],
            [-98.196, 26.1233],
            [-98.2495, 26.146],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843800',
        NAME: 'Valley View Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 26166753,
        AWATER: 190462,
        INTPTLAT: '+26.1119316',
        INTPTLON: '-098.2265825',
        ELSDLEA: '',
        UNSDLEA: '43800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0244, 26.2638],
            [-98.0124, 26.264],
            [-97.9276, 26.229],
            [-97.9252, 26.1269],
            [-98.0217, 26.1055],
            [-98.0244, 26.2638],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844960',
        NAME: 'Weslaco Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140529792,
        AWATER: 691778,
        INTPTLAT: '+26.1864510',
        INTPTLON: '-097.9741132',
        ELSDLEA: '',
        UNSDLEA: '44960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9852, 32.8887],
            [-96.9423, 32.9888],
            [-96.8437, 33.0121],
            [-96.844, 32.9876],
            [-96.9311, 32.8861],
            [-96.9852, 32.8887],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813050',
        NAME: 'Carrollton-Farmers Branch Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122120059,
        AWATER: 2542804,
        INTPTLAT: '+32.9442864',
        INTPTLON: '-096.9115897',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9172, 32.7671],
            [-96.8904, 32.8453],
            [-96.9311, 32.8861],
            [-96.844, 32.9876],
            [-96.8034, 32.987],
            [-96.6917, 32.8788],
            [-96.649, 32.8383],
            [-96.6312, 32.7195],
            [-96.5213, 32.662],
            [-96.53, 32.5453],
            [-96.7069, 32.5595],
            [-96.7187, 32.6183],
            [-96.8229, 32.6188],
            [-96.8828, 32.6189],
            [-96.97, 32.677],
            [-96.9172, 32.7671],
          ],
          [
            [-96.8168, 32.8216],
            [-96.7705, 32.8573],
            [-96.8113, 32.8656],
            [-96.8168, 32.8216],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816230',
        NAME: 'Dallas Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 965377275,
        AWATER: 25670950,
        INTPTLAT: '+32.7359525',
        INTPTLON: '-096.7425077',
        ELSDLEA: '',
        UNSDLEA: '16230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4474, 30.5872],
            [-96.3765, 30.5467],
            [-96.2843, 30.4468],
            [-96.2992, 30.3792],
            [-96.4258, 30.3441],
            [-96.5714, 30.428],
            [-96.5892, 30.4777],
            [-96.5215, 30.5657],
            [-96.4474, 30.5872],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840620',
        NAME: 'Snook Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461698828,
        AWATER: 6774144,
        INTPTLAT: '+30.4544948',
        INTPTLON: '-096.4345220',
        ELSDLEA: '',
        UNSDLEA: '40620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7515, 30.3297],
            [-96.6595, 30.4394],
            [-96.5714, 30.428],
            [-96.4258, 30.3441],
            [-96.4959, 30.3248],
            [-96.6409, 30.2968],
            [-96.7012, 30.3196],
            [-96.7515, 30.3297],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840770',
        NAME: 'Somerville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251787010,
        AWATER: 26301615,
        INTPTLAT: '+30.3652952',
        INTPTLON: '-096.6074968',
        ELSDLEA: '',
        UNSDLEA: '40770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.659, 32.709],
            [-100.6591, 32.7338],
            [-100.4461, 32.7671],
            [-100.4216, 32.8318],
            [-100.3295, 32.8511],
            [-100.117, 32.7291],
            [-100.1181, 32.6612],
            [-100.1458, 32.65],
            [-100.2541, 32.6436],
            [-100.3251, 32.6744],
            [-100.4198, 32.6512],
            [-100.4859, 32.579],
            [-100.6205, 32.5801],
            [-100.659, 32.709],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837470',
        NAME: 'Roby Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 849243706,
        AWATER: 2235888,
        INTPTLAT: '+32.7143303',
        INTPTLON: '-100.4077729',
        ELSDLEA: '',
        UNSDLEA: '37470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8151, 33.5961],
            [-96.8255, 33.5162],
            [-96.8364, 33.5009],
            [-96.9434, 33.537],
            [-96.97, 33.6007],
            [-96.8151, 33.5961],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814580',
        NAME: 'Collinsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132201475,
        AWATER: 1421933,
        INTPTLAT: '+33.5526565',
        INTPTLON: '-096.8665522',
        ELSDLEA: '',
        UNSDLEA: '14580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4764, 33.6707],
            [-96.3796, 33.721],
            [-96.3826, 33.5595],
            [-96.4496, 33.5742],
            [-96.5131, 33.614],
            [-96.4764, 33.6707],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809780',
        NAME: 'Bells Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151097814,
        AWATER: 830185,
        INTPTLAT: '+33.6463375',
        INTPTLON: '-096.4376244',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7868, 33.8652],
            [-96.6907, 33.85],
            [-96.5885, 33.895],
            [-96.5973, 33.8751],
            [-96.6649, 33.8121],
            [-96.6042, 33.7202],
            [-96.6661, 33.6946],
            [-96.801, 33.7823],
            [-96.7868, 33.8652],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835580',
        NAME: 'Pottsboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198238713,
        AWATER: 52062534,
        INTPTLAT: '+33.7792301',
        INTPTLON: '-096.6990195',
        ELSDLEA: '',
        UNSDLEA: '35580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.801, 33.7823],
            [-96.6661, 33.6946],
            [-96.6628, 33.5845],
            [-96.7724, 33.5199],
            [-96.8255, 33.5162],
            [-96.8151, 33.5961],
            [-96.8767, 33.6615],
            [-96.8466, 33.7769],
            [-96.801, 33.7823],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838400',
        NAME: 'S and S Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 330546087,
        AWATER: 5105785,
        INTPTLAT: '+33.6578786',
        INTPTLON: '-096.7687508',
        ELSDLEA: '',
        UNSDLEA: '38400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2937, 33.472],
            [-96.2707, 33.4382],
            [-96.3229, 33.3486],
            [-96.4025, 33.3885],
            [-96.4023, 33.4311],
            [-96.2937, 33.472],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843140',
        NAME: 'Trenton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119160607,
        AWATER: 416965,
        INTPTLAT: '+33.4121676',
        INTPTLON: '-096.3435772',
        ELSDLEA: '',
        UNSDLEA: '43140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0085, 33.4815],
            [-96.9437, 33.4452],
            [-96.8348, 33.4055],
            [-96.8341, 33.4055],
            [-96.8878, 33.2906],
            [-96.8922, 33.2884],
            [-97.0297, 33.3349],
            [-97.0331, 33.3508],
            [-97.1118, 33.4221],
            [-97.0085, 33.4815],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834920',
        NAME: 'Pilot Point Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264210511,
        AWATER: 60026551,
        INTPTLAT: '+33.3823572',
        INTPTLON: '-096.9861843',
        ELSDLEA: '',
        UNSDLEA: '34920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8472, 31.964],
            [-96.8254, 31.9792],
            [-96.7262, 31.9548],
            [-96.6173, 32.0125],
            [-96.4793, 31.9646],
            [-96.5199, 31.8751],
            [-96.5541, 31.8012],
            [-96.5761, 31.803],
            [-96.7123, 31.8143],
            [-96.7964, 31.9272],
            [-96.8472, 31.964],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816380',
        NAME: 'Dawson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 424058123,
        AWATER: 12961296,
        INTPTLAT: '+31.9096648',
        INTPTLON: '-096.6471645',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4064, 32.07],
            [-96.2225, 32.0447],
            [-96.1993, 31.974],
            [-96.3337, 31.9435],
            [-96.4058, 31.9542],
            [-96.4064, 32.07],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830720',
        NAME: 'Mildred Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167354363,
        AWATER: 55924548,
        INTPTLAT: '+32.0129142',
        INTPTLON: '-096.3260268',
        ELSDLEA: '',
        UNSDLEA: '30720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8004, 30.2881],
            [-100.7004, 30.2883],
            [-100.6999, 29.6239],
            [-100.6991, 29.4197],
            [-100.7453, 29.2649],
            [-100.7946, 29.2416],
            [-101.004, 29.3647],
            [-101.064, 29.4599],
            [-100.9861, 29.4939],
            [-100.9474, 29.6133],
            [-101.0236, 29.7196],
            [-100.9763, 29.8261],
            [-100.8753, 29.8554],
            [-100.8824, 29.9794],
            [-100.798, 30.0232],
            [-100.7975, 30.1121],
            [-100.8545, 30.1832],
            [-100.8004, 30.2881],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838900',
        NAME: 'San Felipe-Del Rio Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2295331241,
        AWATER: 72305659,
        INTPTLAT: '+29.6971927',
        INTPTLON: '-100.8249779',
        ELSDLEA: '',
        UNSDLEA: '38900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.69, 27.9919],
            [-97.53, 27.9925],
            [-97.478, 27.8898],
            [-97.4776, 27.8606],
            [-97.5759, 27.8608],
            [-97.7042, 27.9218],
            [-97.69, 27.9919],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833360',
        NAME: 'Odem-Edroy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287369436,
        AWATER: 9702372,
        INTPTLAT: '+27.9354866',
        INTPTLON: '-097.6055561',
        ELSDLEA: '',
        UNSDLEA: '33360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4713, 32.555],
            [-97.3808, 32.5351],
            [-97.2737, 32.4547],
            [-97.2964, 32.4022],
            [-97.3515, 32.3919],
            [-97.4253, 32.4443],
            [-97.4917, 32.4745],
            [-97.4713, 32.555],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824930',
        NAME: 'Joshua Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196281423,
        AWATER: 1052782,
        INTPTLAT: '+32.4740774',
        INTPTLON: '-097.3863747',
        ELSDLEA: '',
        UNSDLEA: '24930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2134, 32.5187],
              [-97.2024, 32.524],
              [-97.2118, 32.5163],
              [-97.2122, 32.5169],
              [-97.2134, 32.5187],
            ],
          ],
          [
            [
              [-97.2633, 32.5912],
              [-97.2343, 32.6169],
              [-97.1711, 32.6343],
              [-97.0371, 32.6454],
              [-97.0374, 32.6228],
              [-97.0384, 32.5487],
              [-97.0867, 32.5079],
              [-97.1342, 32.5036],
              [-97.2024, 32.524],
              [-97.2633, 32.5912],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828920',
        NAME: 'Mansfield Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236237149,
        AWATER: 7391032,
        INTPTLAT: '+32.5800145',
        INTPTLON: '-097.1399932',
        ELSDLEA: '',
        UNSDLEA: '28920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3961, 28.5926],
            [-99.313, 28.5924],
            [-99.1288, 28.4958],
            [-99.1173, 28.6446],
            [-99.0538, 28.6452],
            [-98.8008, 28.6475],
            [-98.8008, 28.6473],
            [-98.8033, 28.0575],
            [-99.3889, 28.0304],
            [-99.3942, 28.2046],
            [-99.3961, 28.5926],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815400',
        NAME: 'Cotulla Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3589865836,
        AWATER: 18859967,
        INTPTLAT: '+28.3229811',
        INTPTLON: '-099.0885249',
        ELSDLEA: '',
        UNSDLEA: '15400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2759, 32.275],
            [-98.1969, 32.3468],
            [-98.1061, 32.3493],
            [-98.0586, 32.2874],
            [-97.9591, 32.2457],
            [-98.083, 32.2042],
            [-98.0485, 32.1071],
            [-98.1516, 32.0476],
            [-98.3327, 32.2101],
            [-98.2759, 32.275],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841520',
        NAME: 'Stephenville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 599567692,
        AWATER: 4346885,
        INTPTLAT: '+32.2110753',
        INTPTLON: '-098.1425534',
        ELSDLEA: '',
        UNSDLEA: '41520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8205, 30.0953],
            [-94.7042, 30.1135],
            [-94.6548, 30.008],
            [-94.6384, 29.8884],
            [-94.7389, 29.8872],
            [-94.8546, 29.993],
            [-94.8205, 30.0953],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827450',
        NAME: 'Liberty Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337255286,
        AWATER: 7473127,
        INTPTLAT: '+29.9891935',
        INTPTLON: '-094.7243262',
        ELSDLEA: '',
        UNSDLEA: '27450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0125, 26.3324],
            [-97.937, 26.3213],
            [-97.9114, 26.2293],
            [-97.9276, 26.229],
            [-98.0124, 26.264],
            [-98.0125, 26.3324],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818060',
        NAME: 'Edcouch-Elsa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72615190,
        AWATER: 0,
        INTPTLAT: '+26.2827010',
        INTPTLON: '-097.9651826',
        ELSDLEA: '',
        UNSDLEA: '18060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.923, 26.3572],
            [-97.8618, 26.3076],
            [-97.8617, 26.2348],
            [-97.8618, 26.2301],
            [-97.9114, 26.2293],
            [-97.937, 26.3213],
            [-97.923, 26.3572],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826340',
        NAME: 'La Villa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88922637,
        AWATER: 2937381,
        INTPTLAT: '+26.2986450',
        INTPTLON: '-097.8885294',
        ELSDLEA: '',
        UNSDLEA: '26340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2382, 26.2914],
            [-98.2003, 26.2511],
            [-98.2268, 26.1433],
            [-98.2495, 26.146],
            [-98.2794, 26.1499],
            [-98.2382, 26.2914],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829670',
        NAME: 'McAllen Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91681480,
        AWATER: 405916,
        INTPTLAT: '+26.2169528',
        INTPTLON: '-098.2377553',
        ELSDLEA: '',
        UNSDLEA: '29670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9276, 26.229],
            [-97.9114, 26.2293],
            [-97.8618, 26.2301],
            [-97.8619, 26.13],
            [-97.8618, 26.0699],
            [-97.9197, 26.058],
            [-97.9252, 26.1269],
            [-97.9276, 26.229],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830250',
        NAME: 'Mercedes Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116355133,
        AWATER: 1998728,
        INTPTLAT: '+26.1468246',
        INTPTLON: '-097.8941130',
        ELSDLEA: '',
        UNSDLEA: '30250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.9815, 29.8843],
              [-94.7788, 29.8867],
              [-94.7892, 29.8189],
              [-94.7171, 29.767],
              [-94.9001, 29.5373],
              [-94.886, 29.819],
              [-94.9471, 29.8495],
              [-94.9815, 29.8843],
            ],
          ],
          [
            [
              [-94.9971, 29.6153],
              [-94.9576, 29.6163],
              [-94.9001, 29.5373],
              [-94.9294, 29.4999],
              [-94.9897, 29.5171],
              [-94.9971, 29.6153],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809450',
        NAME: 'Barbers Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204924375,
        AWATER: 295237550,
        INTPTLAT: '+29.7231354',
        INTPTLON: '-094.8580137',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0414, 35.7393],
            [-103.0408, 36.0552],
            [-102.9178, 36.0552],
            [-102.8745, 35.9866],
            [-102.7274, 35.9868],
            [-102.727, 35.8765],
            [-102.727, 35.7894],
            [-102.4595, 35.8088],
            [-102.1629, 35.8085],
            [-102.1628, 35.62],
            [-102.1652, 35.3725],
            [-102.319, 35.3685],
            [-102.3413, 35.521],
            [-102.5522, 35.5397],
            [-102.6763, 35.5191],
            [-103.0423, 35.3949],
            [-103.0414, 35.7393],
          ],
          [
            [-102.26, 35.5292],
            [-102.2471, 35.5304],
            [-102.2469, 35.5381],
            [-102.2572, 35.5387],
            [-102.26, 35.5292],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813620',
        NAME: 'Channing Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3488348155,
        AWATER: 1358392,
        INTPTLAT: '+35.6729485',
        INTPTLON: '-102.6554935',
        ELSDLEA: '',
        UNSDLEA: '13620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6535, 33.1067],
            [-94.541, 33.1395],
            [-94.5429, 33.0537],
            [-94.4825, 32.9747],
            [-94.6538, 32.917],
            [-94.6535, 33.1067],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823850',
        NAME: 'Hughes Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295875816,
        AWATER: 4895159,
        INTPTLAT: '+33.0388680',
        INTPTLON: '-094.5938912',
        ELSDLEA: '',
        UNSDLEA: '23850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.856, 31.6952],
            [-96.8317, 31.7587],
            [-96.7123, 31.8143],
            [-96.5761, 31.803],
            [-96.5911, 31.7264],
            [-96.676, 31.6673],
            [-96.7507, 31.6145],
            [-96.8331, 31.6351],
            [-96.856, 31.6952],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815120',
        NAME: 'Coolidge Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363665049,
        AWATER: 3668814,
        INTPTLAT: '+31.7181648',
        INTPTLON: '-096.7182551',
        ELSDLEA: '',
        UNSDLEA: '15120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1453, 31.9371],
            [-94.9854, 31.964],
            [-94.9436, 31.8974],
            [-94.9366, 31.8463],
            [-94.9683, 31.7483],
            [-95.0907, 31.7462],
            [-95.1407, 31.6912],
            [-95.2758, 31.6572],
            [-95.3662, 31.7268],
            [-95.404, 31.8029],
            [-95.3271, 31.853],
            [-95.1804, 31.8594],
            [-95.1453, 31.9371],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838360',
        NAME: 'Rusk Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 808975563,
        AWATER: 3253388,
        INTPTLAT: '+31.8081756',
        INTPTLON: '-095.1489639',
        ELSDLEA: '',
        UNSDLEA: '38360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2396, 32.0926],
            [-95.2227, 32.1365],
            [-95.1726, 32.1534],
            [-95.0754, 32.2093],
            [-94.9854, 32.1984],
            [-94.9853, 32.1579],
            [-95.0812, 32.1374],
            [-95.0732, 32.066],
            [-95.164, 31.999],
            [-95.168, 32.0639],
            [-95.2396, 32.0926],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843230',
        NAME: 'Troup Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284178106,
        AWATER: 958668,
        INTPTLAT: '+32.1252104',
        INTPTLON: '-095.1105242',
        ELSDLEA: '',
        UNSDLEA: '43230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1432, 31.544],
            [-95.0704, 31.574],
            [-94.9239, 31.5792],
            [-94.8682, 31.5267],
            [-94.8659, 31.5269],
            [-94.9179, 31.46],
            [-95.0033, 31.4257],
            [-95.1432, 31.544],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844940',
        NAME: 'Wells Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263113081,
        AWATER: 371740,
        INTPTLAT: '+31.4890075',
        INTPTLON: '-095.0141683',
        ELSDLEA: '',
        UNSDLEA: '44940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.39, 33.2605],
            [-97.3342, 33.3418],
            [-97.1832, 33.3027],
            [-97.2312, 33.2216],
            [-97.3555, 33.2409],
            [-97.3919, 33.2077],
            [-97.39, 33.2605],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825980',
        NAME: 'Krum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187227268,
        AWATER: 2323442,
        INTPTLAT: '+33.2788233',
        INTPTLON: '-097.3023098',
        ELSDLEA: '',
        UNSDLEA: '25980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9185, 33.3772],
            [-97.88, 33.434],
            [-97.7503, 33.3419],
            [-97.6878, 33.2615],
            [-97.7733, 33.2275],
            [-97.9189, 33.237],
            [-97.9185, 33.3772],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813800',
        NAME: 'Chico Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291566909,
        AWATER: 1624663,
        INTPTLAT: '+33.3262694',
        INTPTLON: '-097.8291648',
        ELSDLEA: '',
        UNSDLEA: '13800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6931, 33.2547],
            [-97.5522, 33.4009],
            [-97.504, 33.292],
            [-97.39, 33.2605],
            [-97.3919, 33.2077],
            [-97.3935, 33.1557],
            [-97.4829, 33.1212],
            [-97.5847, 33.1312],
            [-97.674, 33.1839],
            [-97.6931, 33.2547],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816500',
        NAME: 'Decatur Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552193550,
        AWATER: 1489092,
        INTPTLAT: '+33.2376303',
        INTPTLON: '-097.5392241',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.581, 30.4908],
            [-94.5457, 30.527],
            [-94.4495, 30.527],
            [-94.4026, 30.4805],
            [-94.3014, 30.467],
            [-94.2649, 30.3695],
            [-94.1952, 30.298],
            [-94.2627, 30.276],
            [-94.3946, 30.2761],
            [-94.4855, 30.3553],
            [-94.5712, 30.3711],
            [-94.581, 30.4908],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825920',
        NAME: 'Kountze Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 655658382,
        AWATER: 2438088,
        INTPTLAT: '+30.3924804',
        INTPTLON: '-094.4043156',
        ELSDLEA: '',
        UNSDLEA: '25920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5752, 30.6543],
            [-94.4311, 30.6887],
            [-94.2938, 30.6708],
            [-94.2289, 30.6045],
            [-94.0833, 30.5936],
            [-94.0873, 30.5543],
            [-94.0717, 30.526],
            [-94.3135, 30.5268],
            [-94.3014, 30.467],
            [-94.4026, 30.4805],
            [-94.4495, 30.527],
            [-94.5457, 30.527],
            [-94.5752, 30.6543],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844580',
        NAME: 'Warren Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669044365,
        AWATER: 2753536,
        INTPTLAT: '+30.5819723',
        INTPTLON: '-094.3502691',
        ELSDLEA: '',
        UNSDLEA: '44580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7327, 30.4901],
            [-94.581, 30.4908],
            [-94.5712, 30.3711],
            [-94.4855, 30.3553],
            [-94.3946, 30.2761],
            [-94.5009, 30.2325],
            [-94.4913, 30.1438],
            [-94.5469, 30.1119],
            [-94.5963, 30.1121],
            [-94.6424, 30.2395],
            [-94.7327, 30.4901],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845000',
        NAME: 'West Hardin County Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 630869868,
        AWATER: 2622759,
        INTPTLAT: '+30.2986068',
        INTPTLON: '-094.5765442',
        ELSDLEA: '',
        UNSDLEA: '45000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8243, 33.0646],
            [-99.7504, 33.062],
            [-99.6224, 33.1769],
            [-99.5832, 33.0922],
            [-99.4716, 33.0918],
            [-99.4713, 32.957],
            [-99.6276, 32.9308],
            [-99.6694, 32.9574],
            [-99.8625, 32.9678],
            [-99.8243, 33.0646],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833960',
        NAME: 'Paint Creek Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 591270685,
        AWATER: 17994092,
        INTPTLAT: '+33.0445506',
        INTPTLON: '-099.6379768',
        ELSDLEA: '',
        UNSDLEA: '33960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9895, 33.2537],
            [-99.9064, 33.275],
            [-99.8152, 33.2214],
            [-99.8479, 33.065],
            [-99.8947, 33.0768],
            [-99.9511, 32.9599],
            [-99.9893, 33.0391],
            [-100.0688, 33.0409],
            [-99.9896, 33.0905],
            [-99.9895, 33.2537],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838280',
        NAME: 'Rule Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392286363,
        AWATER: 100634,
        INTPTLAT: '+33.1283897',
        INTPTLON: '-099.9352549',
        ELSDLEA: '',
        UNSDLEA: '38280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9511, 32.9599],
            [-99.8947, 33.0768],
            [-99.8479, 33.065],
            [-99.8243, 33.0646],
            [-99.8625, 32.9678],
            [-99.6694, 32.9574],
            [-99.6276, 32.9308],
            [-99.7377, 32.9021],
            [-99.7997, 32.8389],
            [-99.8817, 32.8783],
            [-99.9786, 32.8732],
            [-99.9511, 32.9599],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841400',
        NAME: 'Stamford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358801121,
        AWATER: 1040369,
        INTPTLAT: '+32.9297960',
        INTPTLON: '-099.8420098',
        ELSDLEA: '',
        UNSDLEA: '41400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0041, 33.3976],
            [-99.991, 33.3974],
            [-99.9909, 33.3408],
            [-99.7871, 33.347],
            [-99.7198, 33.3984],
            [-99.5397, 33.3988],
            [-99.4723, 33.3321],
            [-99.4716, 33.0918],
            [-99.5832, 33.0922],
            [-99.6224, 33.1769],
            [-99.7504, 33.062],
            [-99.8243, 33.0646],
            [-99.8479, 33.065],
            [-99.8152, 33.2214],
            [-99.9064, 33.275],
            [-99.9895, 33.2537],
            [-100.0041, 33.3976],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822740',
        NAME: 'Haskell Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1147466100,
        AWATER: 621549,
        INTPTLAT: '+33.2450223',
        INTPTLON: '-099.6822214',
        ELSDLEA: '',
        UNSDLEA: '22740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2747, 31.8483],
            [-99.2007, 31.8824],
            [-99.1987, 31.9704],
            [-99.0942, 31.9563],
            [-99.097, 31.8904],
            [-99.0028, 31.8391],
            [-99.0625, 31.8223],
            [-99.0701, 31.6311],
            [-99.2031, 31.6215],
            [-99.2023, 31.8149],
            [-99.2747, 31.8483],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809390',
        NAME: 'Bangs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 512627185,
        AWATER: 20719197,
        INTPTLAT: '+31.7869824',
        INTPTLON: '-099.1311694',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.132, 30.7624],
            [-95.0555, 30.737],
            [-95.0164, 30.8468],
            [-95.0874, 30.9194],
            [-95.0063, 30.9872],
            [-94.9743, 30.8762],
            [-94.8911, 30.8492],
            [-94.8838, 30.7456],
            [-94.7286, 30.7348],
            [-94.7389, 30.5538],
            [-94.7753, 30.489],
            [-94.8494, 30.4936],
            [-94.8809, 30.5512],
            [-94.8666, 30.6312],
            [-95.0088, 30.6139],
            [-95.0244, 30.6605],
            [-95.1454, 30.7138],
            [-95.132, 30.7624],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827780',
        NAME: 'Livingston Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801979096,
        AWATER: 69700067,
        INTPTLAT: '+30.7118268',
        INTPTLON: '-094.9099887',
        ELSDLEA: '',
        UNSDLEA: '27780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0874, 30.9194],
            [-95.0164, 30.8468],
            [-95.0555, 30.737],
            [-95.132, 30.7624],
            [-95.2002, 30.8246],
            [-95.0874, 30.9194],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833690',
        NAME: 'Onalaska Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126685434,
        AWATER: 62381138,
        INTPTLAT: '+30.8297788',
        INTPTLON: '-095.0974428',
        ELSDLEA: '',
        UNSDLEA: '33690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0088, 30.6139],
            [-94.8666, 30.6312],
            [-94.8809, 30.5512],
            [-94.9947, 30.5791],
            [-95.0088, 30.6139],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821090',
        NAME: 'Goodrich Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103512345,
        AWATER: 2534099,
        INTPTLAT: '+30.5683800',
        INTPTLON: '-094.9389880',
        ELSDLEA: '',
        UNSDLEA: '21090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3046, 31.8045],
            [-97.2899, 31.8454],
            [-97.2482, 31.9641],
            [-97.2061, 31.9031],
            [-97.1463, 31.8287],
            [-97.2318, 31.7679],
            [-97.2773, 31.7455],
            [-97.3017, 31.7571],
            [-97.3046, 31.8045],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808520',
        NAME: 'Aquilla Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176954118,
        AWATER: 6531530,
        INTPTLAT: '+31.8380791',
        INTPTLON: '-097.2495405',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2195, 32.1956],
            [-97.1822, 32.2458],
            [-97.0862, 32.2655],
            [-97.0488, 32.2106],
            [-97.0281, 32.0978],
            [-97.089, 32.0698],
            [-97.1789, 32.1043],
            [-97.2594, 32.0656],
            [-97.2697, 32.142],
            [-97.2195, 32.1956],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824480',
        NAME: 'Itasca Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346385058,
        AWATER: 2749044,
        INTPTLAT: '+32.1612416',
        INTPTLON: '-097.1337205',
        ELSDLEA: '',
        UNSDLEA: '24480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6575, 32.8983],
            [-95.5494, 32.809],
            [-95.4891, 32.7688],
            [-95.5408, 32.6519],
            [-95.5945, 32.687],
            [-95.7135, 32.7439],
            [-95.6625, 32.7743],
            [-95.6575, 32.8983],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807650',
        NAME: 'Alba-Golden Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218073326,
        AWATER: 34361780,
        INTPTLAT: '+32.7774702',
        INTPTLON: '-095.6058520',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8633, 33.0499],
            [-95.782, 33.0957],
            [-95.7056, 33.0752],
            [-95.748, 32.9801],
            [-95.8625, 32.9796],
            [-95.8633, 33.0499],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830810',
        NAME: 'Miller Grove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171714343,
        AWATER: 2885802,
        INTPTLAT: '+33.0290071',
        INTPTLON: '-095.7905631',
        ELSDLEA: '',
        UNSDLEA: '30810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1629, 35.8085],
            [-102.163, 36.0552],
            [-101.9022, 36.0554],
            [-101.9222, 35.9956],
            [-101.811, 35.9803],
            [-101.7577, 35.88],
            [-101.6231, 35.8729],
            [-101.6231, 35.8706],
            [-101.6229, 35.67],
            [-101.6228, 35.6241],
            [-101.6228, 35.6202],
            [-101.6723, 35.6201],
            [-102.1628, 35.62],
            [-102.1629, 35.8085],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817610',
        NAME: 'Dumas Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1942694675,
        AWATER: 25392271,
        INTPTLAT: '+35.8091840',
        INTPTLON: '-101.9232658',
        ELSDLEA: '',
        UNSDLEA: '17610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5363, 31.6935],
            [-100.4734, 31.6933],
            [-100.2986, 31.6927],
            [-100.2985, 31.625],
            [-100.4363, 31.5488],
            [-100.5237, 31.4771],
            [-100.5916, 31.4765],
            [-100.6008, 31.598],
            [-100.5363, 31.6935],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821600',
        NAME: 'Grape Creek Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434355517,
        AWATER: 10936274,
        INTPTLAT: '+31.5970140',
        INTPTLON: '-100.4733026',
        ELSDLEA: '',
        UNSDLEA: '21600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6534, 29.3701],
            [-99.5716, 29.37],
            [-99.5424, 29.2417],
            [-99.5788, 29.1563],
            [-99.6727, 29.1949],
            [-99.7092, 29.3299],
            [-99.6534, 29.3701],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825800',
        NAME: 'Knippa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268704992,
        AWATER: 1932312,
        INTPTLAT: '+29.2570702',
        INTPTLON: '-099.6319937',
        ELSDLEA: '',
        UNSDLEA: '25800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.918, 30.0736],
            [-99.7576, 30.0741],
            [-99.6899, 30.0739],
            [-99.6912, 29.9086],
            [-99.6028, 29.9077],
            [-99.603, 29.756],
            [-99.6698, 29.6921],
            [-99.6945, 29.5801],
            [-99.781, 29.5818],
            [-99.8017, 29.6633],
            [-99.8912, 29.7062],
            [-99.8738, 29.7209],
            [-99.8691, 30.0048],
            [-99.918, 30.0736],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827000',
        NAME: 'Leakey Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1100909867,
        AWATER: 1536484,
        INTPTLAT: '+29.8153757',
        INTPTLON: '-099.7273017',
        ELSDLEA: '',
        UNSDLEA: '27000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.781, 29.5818],
            [-99.6945, 29.5801],
            [-99.5883, 29.5732],
            [-99.5216, 29.4613],
            [-99.4126, 29.4565],
            [-99.4139, 29.0913],
            [-99.5454, 29.0904],
            [-99.5788, 29.1563],
            [-99.5424, 29.2417],
            [-99.5716, 29.37],
            [-99.6534, 29.3701],
            [-99.7567, 29.4387],
            [-99.781, 29.5818],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838430',
        NAME: 'Sabinal Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 954147322,
        AWATER: 4275903,
        INTPTLAT: '+29.3699120',
        INTPTLON: '-099.5523280',
        ELSDLEA: '',
        UNSDLEA: '38430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.191, 28.8823],
            [-97.8385, 29.1411],
            [-97.811, 29.1],
            [-97.8743, 29.0249],
            [-97.9836, 28.9666],
            [-98.0137, 28.8749],
            [-98.1573, 28.8491],
            [-98.191, 28.8823],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819020',
        NAME: 'Falls City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341335750,
        AWATER: 4287955,
        INTPTLAT: '+28.9796858',
        INTPTLON: '-098.0098185',
        ELSDLEA: '',
        UNSDLEA: '19020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6839, 29.5828],
            [-97.5267, 29.7043],
            [-97.5179, 29.7606],
            [-97.4627, 29.7481],
            [-97.4001, 29.6873],
            [-97.3589, 29.56],
            [-97.26, 29.5645],
            [-97.2279, 29.5119],
            [-97.2887, 29.4073],
            [-97.3337, 29.3162],
            [-97.4864, 29.2861],
            [-97.6188, 29.3634],
            [-97.6212, 29.427],
            [-97.7276, 29.525],
            [-97.6839, 29.5828],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821060',
        NAME: 'Gonzales Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1347125727,
        AWATER: 6445740,
        INTPTLAT: '+29.5008084',
        INTPTLON: '-097.4831423',
        ELSDLEA: '',
        UNSDLEA: '21060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.158, 33.0435],
            [-97.0812, 33.1185],
            [-96.9963, 33.125],
            [-96.8968, 33.1098],
            [-96.8419, 33.0831],
            [-96.8437, 33.0121],
            [-96.9423, 32.9888],
            [-97.0323, 32.9893],
            [-97.0872, 32.9898],
            [-97.1584, 33.0219],
            [-97.158, 33.0435],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827300',
        NAME: 'Lewisville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283010085,
        AWATER: 39227791,
        INTPTLAT: '+33.0508799',
        INTPTLON: '-096.9907417',
        ELSDLEA: '',
        UNSDLEA: '27300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2589, 33.1416],
            [-97.2312, 33.2216],
            [-97.1832, 33.3027],
            [-97.0331, 33.3508],
            [-97.0297, 33.3349],
            [-97.044, 33.2383],
            [-96.9351, 33.2324],
            [-96.8922, 33.2884],
            [-96.8913, 33.2194],
            [-96.9043, 33.2042],
            [-97.0051, 33.1603],
            [-97.0812, 33.1185],
            [-97.158, 33.0435],
            [-97.1233, 33.1383],
            [-97.2538, 33.1315],
            [-97.2589, 33.1416],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816740',
        NAME: 'Denton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 419094466,
        AWATER: 20737138,
        INTPTLAT: '+33.2141060',
        INTPTLON: '-097.0937275',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3919, 33.2077],
            [-97.3555, 33.2409],
            [-97.2312, 33.2216],
            [-97.2589, 33.1416],
            [-97.3935, 33.1557],
            [-97.3919, 33.2077],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835310',
        NAME: 'Ponder Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136041625,
        AWATER: 2609928,
        INTPTLAT: '+33.1856025',
        INTPTLON: '-097.2959773',
        ELSDLEA: '',
        UNSDLEA: '35310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4269, 31.2278],
            [-99.3413, 31.3039],
            [-99.3072, 31.4173],
            [-99.2028, 31.4672],
            [-99.0906, 31.4609],
            [-99.0918, 31.1436],
            [-99.2271, 31.1418],
            [-99.4269, 31.2278],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837500',
        NAME: 'Rochelle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 826956856,
        AWATER: 4695181,
        INTPTLAT: '+31.2988983',
        INTPTLON: '-099.2113721',
        ELSDLEA: '',
        UNSDLEA: '37500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5115, 31.4244],
            [-99.4541, 31.4396],
            [-99.3072, 31.4173],
            [-99.3413, 31.3039],
            [-99.4269, 31.2278],
            [-99.5444, 31.2734],
            [-99.5115, 31.4244],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827930',
        NAME: 'Lohn Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439061712,
        AWATER: 2122963,
        INTPTLAT: '+31.3447786',
        INTPTLON: '-099.4250961',
        ELSDLEA: '',
        UNSDLEA: '27930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.921, 27.8961],
            [-97.7985, 27.9957],
            [-97.7171, 27.9116],
            [-97.7286, 27.8496],
            [-97.75, 27.7252],
            [-97.8609, 27.7325],
            [-97.8471, 27.8035],
            [-97.921, 27.8961],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809410',
        NAME: 'Banquete Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318945351,
        AWATER: 3632436,
        INTPTLAT: '+27.8527139',
        INTPTLON: '-097.8086451',
        ELSDLEA: '',
        UNSDLEA: '09410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1463, 31.8287],
            [-97.0373, 31.8631],
            [-97.0024, 31.8111],
            [-96.9803, 31.7785],
            [-96.965, 31.7559],
            [-97.0241, 31.6806],
            [-97.0623, 31.6745],
            [-97.1843, 31.6853],
            [-97.2318, 31.7679],
            [-97.1463, 31.8287],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845040',
        NAME: 'West Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326658009,
        AWATER: 1375909,
        INTPTLAT: '+31.7648023',
        INTPTLON: '-097.0898383',
        ELSDLEA: '',
        UNSDLEA: '45040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4245, 36.4994],
            [-100.311, 36.4996],
            [-100.2582, 36.4998],
            [-100.2593, 36.3468],
            [-100.2943, 36.2016],
            [-100.3667, 36.1292],
            [-100.4197, 36.2308],
            [-100.4019, 36.3615],
            [-100.4245, 36.4994],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816320',
        NAME: 'Darrouzett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489360016,
        AWATER: 67871,
        INTPTLAT: '+36.3344227',
        INTPTLON: '-100.3430512',
        ELSDLEA: '',
        UNSDLEA: '16320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1464, 32.9232],
            [-94.0431, 32.9654],
            [-94.0431, 32.8811],
            [-94.1214, 32.8809],
            [-94.1464, 32.9232],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829910',
        NAME: 'McLeod Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85580139,
        AWATER: 183887,
        INTPTLAT: '+32.9309290',
        INTPTLON: '-094.0959566',
        ELSDLEA: '',
        UNSDLEA: '29910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2744, 32.2259],
            [-97.2195, 32.1956],
            [-97.2697, 32.142],
            [-97.2594, 32.0656],
            [-97.289, 32.0633],
            [-97.3428, 32.1536],
            [-97.2744, 32.2259],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815480',
        NAME: 'Covington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111412470,
        AWATER: 1058010,
        INTPTLAT: '+32.1544513',
        INTPTLON: '-097.2800943',
        ELSDLEA: '',
        UNSDLEA: '15480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0024, 31.8111],
            [-96.9906, 31.8915],
            [-96.9565, 31.9143],
            [-96.8689, 31.8765],
            [-96.842, 31.8129],
            [-96.9803, 31.7785],
            [-97.0024, 31.8111],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834560',
        NAME: 'Penelope Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125524018,
        AWATER: 1712901,
        INTPTLAT: '+31.8385954',
        INTPTLON: '-096.9331652',
        ELSDLEA: '',
        UNSDLEA: '34560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3131, 32.0519],
            [-97.289, 32.0633],
            [-97.2594, 32.0656],
            [-97.1789, 32.1043],
            [-97.089, 32.0698],
            [-97.0281, 32.0978],
            [-96.9951, 32.06],
            [-97.0783, 31.9791],
            [-97.0607, 31.9294],
            [-97.1756, 31.9396],
            [-97.2061, 31.9031],
            [-97.2482, 31.9641],
            [-97.3131, 32.0519],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823280',
        NAME: 'Hillsboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325183362,
        AWATER: 7897686,
        INTPTLAT: '+32.0243921',
        INTPTLON: '-097.1488880',
        ELSDLEA: '',
        UNSDLEA: '23280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4862, 32.0325],
            [-97.4221, 32.0698],
            [-97.3131, 32.0519],
            [-97.2482, 31.9641],
            [-97.2899, 31.8454],
            [-97.3753, 31.8984],
            [-97.4388, 31.9335],
            [-97.4257, 31.9433],
            [-97.4862, 32.0325],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845720',
        NAME: 'Whitney Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272805355,
        AWATER: 34798244,
        INTPTLAT: '+31.9691124',
        INTPTLON: '-097.3414248',
        ELSDLEA: '',
        UNSDLEA: '45720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.26, 29.5645],
            [-97.2293, 29.6414],
            [-97.1305, 29.6433],
            [-97.0452, 29.6297],
            [-97.0731, 29.4904],
            [-97.2279, 29.5119],
            [-97.26, 29.5645],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831560',
        NAME: 'Moulton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287181784,
        AWATER: 195248,
        INTPTLAT: '+29.5693609',
        INTPTLON: '-097.1453623',
        ELSDLEA: '',
        UNSDLEA: '31560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0949, 29.3754],
            [-97.0761, 29.4131],
            [-97.0366, 29.4026],
            [-96.9603, 29.3474],
            [-96.9986, 29.2423],
            [-97.1083, 29.3012],
            [-97.0949, 29.3754],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842000',
        NAME: 'Sweet Home Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163977665,
        AWATER: 79786,
        INTPTLAT: '+29.3096419',
        INTPTLON: '-097.0298239',
        ELSDLEA: '',
        UNSDLEA: '42000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2279, 29.5119],
            [-97.0731, 29.4904],
            [-97.0565, 29.4714],
            [-97.0761, 29.4131],
            [-97.0949, 29.3754],
            [-97.2027, 29.3456],
            [-97.2887, 29.4073],
            [-97.2279, 29.5119],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840110',
        NAME: 'Shiner Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293664367,
        AWATER: 45592,
        INTPTLAT: '+29.4349624',
        INTPTLON: '-097.1823466',
        ELSDLEA: '',
        UNSDLEA: '40110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1716, 34.2466],
            [-101.0415, 34.2563],
            [-101.0415, 34.3124],
            [-100.6827, 34.3134],
            [-100.5173, 34.3141],
            [-100.5187, 33.8357],
            [-100.957, 33.834],
            [-101.0412, 33.8336],
            [-101.0413, 34.1778],
            [-101.19, 34.1914],
            [-101.1716, 34.2466],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831500',
        NAME: 'Motley County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2637610049,
        AWATER: 683759,
        INTPTLAT: '+34.0750473',
        INTPTLON: '-100.7942443',
        ELSDLEA: '',
        UNSDLEA: '31500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7584, 30.288],
            [-100.9606, 30.2878],
            [-100.8004, 30.2881],
            [-100.8545, 30.1832],
            [-100.7975, 30.1121],
            [-100.798, 30.0232],
            [-100.8824, 29.9794],
            [-100.8753, 29.8554],
            [-100.9763, 29.8261],
            [-101.0236, 29.7196],
            [-100.9474, 29.6133],
            [-100.9861, 29.4939],
            [-101.064, 29.4599],
            [-101.2612, 29.5368],
            [-101.3, 29.6407],
            [-101.4558, 29.788],
            [-101.6469, 29.7546],
            [-101.7609, 29.7818],
            [-101.7584, 30.288],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814920',
        NAME: 'Comstock Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5822230251,
        AWATER: 155396523,
        INTPTLAT: '+29.9938667',
        INTPTLON: '-101.2775487',
        ELSDLEA: '',
        UNSDLEA: '14920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7004, 30.2883],
            [-100.1165, 30.2903],
            [-99.7541, 30.2907],
            [-99.7576, 30.0741],
            [-99.918, 30.0736],
            [-99.9439, 30.1423],
            [-100.0468, 30.1291],
            [-100.0795, 29.9473],
            [-100.1368, 29.8866],
            [-100.357, 29.8429],
            [-100.3059, 29.7343],
            [-100.5756, 29.6235],
            [-100.6999, 29.6239],
            [-100.7004, 30.2883],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800003',
        NAME: 'Rocksprings Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4207594978,
        AWATER: 3015978,
        INTPTLAT: '+30.0469641',
        INTPTLON: '-100.3681372',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0647, 32.8275],
            [-103.0647, 32.9591],
            [-102.7605, 32.9588],
            [-102.7606, 32.886],
            [-102.5885, 32.8861],
            [-102.5891, 32.7989],
            [-102.4508, 32.7989],
            [-102.4509, 32.7543],
            [-102.2056, 32.7593],
            [-102.211, 32.5232],
            [-103.0647, 32.5222],
            [-103.0647, 32.8275],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839750',
        NAME: 'Seminole Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3015109234,
        AWATER: 1262290,
        INTPTLAT: '+32.7037688',
        INTPTLON: '-102.6919262',
        ELSDLEA: '',
        UNSDLEA: '39750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7453, 32.0719],
            [-98.6931, 32.0751],
            [-98.4948, 31.9731],
            [-98.4739, 32.0145],
            [-98.3927, 32.0363],
            [-98.3758, 31.9403],
            [-98.47, 31.9044],
            [-98.494, 31.8464],
            [-98.4106, 31.7286],
            [-98.6288, 31.7089],
            [-98.6931, 31.7371],
            [-98.7017, 31.75],
            [-98.7412, 31.8081],
            [-98.7142, 31.8981],
            [-98.6909, 32.0108],
            [-98.7453, 32.0719],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814760',
        NAME: 'Comanche Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 847919143,
        AWATER: 10672914,
        INTPTLAT: '+31.8806979',
        INTPTLON: '-098.5665081',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1516, 32.0476],
            [-98.0485, 32.1071],
            [-97.9538, 32.0831],
            [-98.0032, 32.0509],
            [-97.9203, 31.8949],
            [-97.9951, 31.8291],
            [-98.16, 31.895],
            [-98.2531, 31.8901],
            [-98.1463, 31.9685],
            [-98.1516, 32.0476],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823070',
        NAME: 'Hico Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537896833,
        AWATER: 1816021,
        INTPTLAT: '+31.9479997',
        INTPTLON: '-098.0554420',
        ELSDLEA: '',
        UNSDLEA: '23070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0942, 31.9563],
            [-99.0546, 32.0791],
            [-98.8501, 32.0665],
            [-98.8471, 31.9641],
            [-98.8015, 31.8976],
            [-98.8827, 31.8359],
            [-98.999, 31.8411],
            [-99.0028, 31.8391],
            [-99.097, 31.8904],
            [-99.0942, 31.9563],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829520',
        NAME: 'May Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 530234014,
        AWATER: 7337938,
        INTPTLAT: '+31.9685147',
        INTPTLON: '-098.9503176',
        ELSDLEA: '',
        UNSDLEA: '29520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4073, 29.1144],
            [-98.3272, 29.2099],
            [-98.2493, 29.3039],
            [-98.1782, 29.2641],
            [-98.0041, 29.2993],
            [-98.0644, 29.2398],
            [-97.991, 29.1558],
            [-98.0963, 29.1247],
            [-98.1298, 29.0707],
            [-98.3308, 29.0335],
            [-98.4073, 29.1144],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819350',
        NAME: 'Floresville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 723231077,
        AWATER: 3650614,
        INTPTLAT: '+29.1655497',
        INTPTLON: '-098.1981342',
        ELSDLEA: '',
        UNSDLEA: '19350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0041, 29.2993],
            [-98.002, 29.3789],
            [-97.8734, 29.3771],
            [-97.7907, 29.2528],
            [-97.7884, 29.1778],
            [-97.8182, 29.156],
            [-97.991, 29.1558],
            [-98.0644, 29.2398],
            [-98.0041, 29.2993],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841610',
        NAME: 'Stockdale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476382978,
        AWATER: 2625892,
        INTPTLAT: '+29.2761627',
        INTPTLON: '-097.9179380',
        ELSDLEA: '',
        UNSDLEA: '41610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7614, 34.8102],
            [-102.7621, 34.8728],
            [-102.5816, 34.8678],
            [-102.5459, 34.8966],
            [-102.544, 35.0577],
            [-102.5436, 35.1015],
            [-102.2424, 35.1027],
            [-102.1676, 35.102],
            [-102.1688, 34.7474],
            [-102.3709, 34.746],
            [-102.4081, 34.6458],
            [-102.5252, 34.6401],
            [-102.6121, 34.7472],
            [-102.7607, 34.7475],
            [-102.7614, 34.8102],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823010',
        NAME: 'Hereford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1832641780,
        AWATER: 3545740,
        INTPTLAT: '+34.8840801',
        INTPTLON: '-102.4154208',
        ELSDLEA: '',
        UNSDLEA: '23010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.6763, 35.5191],
            [-102.5522, 35.5397],
            [-102.3413, 35.521],
            [-102.319, 35.3685],
            [-102.3288, 35.132],
            [-102.2424, 35.1027],
            [-102.5436, 35.1015],
            [-102.5816, 35.2928],
            [-102.6598, 35.3401],
            [-102.7573, 35.3405],
            [-102.7555, 35.4539],
            [-102.6763, 35.5191],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843980',
        NAME: 'Vega Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1395799607,
        AWATER: 1226583,
        INTPTLAT: '+35.3136593',
        INTPTLON: '-102.5102754',
        ELSDLEA: '',
        UNSDLEA: '43980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9834, 32.9416],
            [-97.9532, 32.9415],
            [-97.832, 32.8976],
            [-97.8233, 32.7789],
            [-97.8857, 32.8195],
            [-97.9834, 32.9416],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834500',
        NAME: 'Peaster Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147074251,
        AWATER: 499643,
        INTPTLAT: '+32.8829901',
        INTPTLON: '-097.8835233',
        ELSDLEA: '',
        UNSDLEA: '34500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8923, 32.7466],
            [-97.8233, 32.7789],
            [-97.832, 32.8976],
            [-97.7875, 32.9575],
            [-97.6648, 32.8692],
            [-97.6291, 32.7923],
            [-97.7046, 32.6652],
            [-97.6696, 32.5561],
            [-97.8758, 32.5573],
            [-97.8495, 32.7091],
            [-97.8923, 32.7466],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844800',
        NAME: 'Weatherford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645771743,
        AWATER: 8191090,
        INTPTLAT: '+32.7232762',
        INTPTLON: '-097.7674777',
        ELSDLEA: '',
        UNSDLEA: '44800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0045, 32.6236],
            [-97.988, 32.6841],
            [-97.8923, 32.7466],
            [-97.8495, 32.7091],
            [-97.8758, 32.5573],
            [-97.893, 32.5572],
            [-97.9965, 32.5578],
            [-98.0045, 32.6236],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811460',
        NAME: 'Brock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223032552,
        AWATER: 2078987,
        INTPTLAT: '+32.6496343',
        INTPTLON: '-097.9346954',
        ELSDLEA: '',
        UNSDLEA: '11460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7846, 33.0195],
            [-97.7279, 33.0395],
            [-97.6677, 33.052],
            [-97.5782, 33.0014],
            [-97.5585, 32.9331],
            [-97.6648, 32.8692],
            [-97.7875, 32.9575],
            [-97.7846, 33.0195],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841280',
        NAME: 'Springtown Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261716616,
        AWATER: 92093,
        INTPTLAT: '+32.9659094',
        INTPTLON: '-097.6747641',
        ELSDLEA: '',
        UNSDLEA: '41280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7653, 31.0451],
            [-96.5813, 31.0745],
            [-96.5378, 31.0246],
            [-96.6187, 30.9166],
            [-96.6814, 30.8817],
            [-96.7563, 30.9272],
            [-96.7737, 31.0163],
            [-96.7653, 31.0451],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812540',
        NAME: 'Calvert Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306660250,
        AWATER: 3328838,
        INTPTLAT: '+30.9911202',
        INTPTLON: '-096.6687821',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7021, 32.7767],
            [-94.6732, 32.7784],
            [-94.5073, 32.6937],
            [-94.3926, 32.6944],
            [-94.5076, 32.631],
            [-94.7005, 32.6535],
            [-94.7021, 32.7767],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822500',
        NAME: 'Harleton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245637792,
        AWATER: 566713,
        INTPTLAT: '+32.6908202',
        INTPTLON: '-094.5886043',
        ELSDLEA: '',
        UNSDLEA: '22500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1768, 32.574],
            [-94.0428, 32.5754],
            [-94.0429, 32.3923],
            [-94.1071, 32.483],
            [-94.1768, 32.574],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844670',
        NAME: 'Waskom Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166024325,
        AWATER: 437550,
        INTPTLAT: '+32.5055105',
        INTPTLON: '-094.0893186',
        ELSDLEA: '',
        UNSDLEA: '44670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2679, 31.5287],
            [-100.6931, 31.524],
            [-100.6927, 31.476],
            [-100.6909, 31.3379],
            [-100.6888, 31.0866],
            [-100.9622, 31.0825],
            [-101.2748, 31.0794],
            [-101.2679, 31.5287],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830360',
        NAME: 'Irion County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2723485993,
        AWATER: 176317,
        INTPTLAT: '+31.3034241',
        INTPTLON: '-100.9813037',
        ELSDLEA: '',
        UNSDLEA: '30360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8501, 32.0665],
            [-98.847, 32.0665],
            [-98.7453, 32.0719],
            [-98.6909, 32.0108],
            [-98.7142, 31.8981],
            [-98.8015, 31.8976],
            [-98.8471, 31.9641],
            [-98.8501, 32.0665],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840170',
        NAME: 'Sidney Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221973761,
        AWATER: 591535,
        INTPTLAT: '+31.9751867',
        INTPTLON: '-098.7601649',
        ELSDLEA: '',
        UNSDLEA: '40170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9118, 31.228],
            [-97.8514, 31.3129],
            [-97.6452, 31.2601],
            [-97.6191, 31.2173],
            [-97.6911, 31.1616],
            [-97.5495, 31.103],
            [-97.5841, 31.0285],
            [-97.69, 30.9922],
            [-97.7758, 30.975],
            [-97.8285, 30.9062],
            [-97.8619, 30.9633],
            [-97.8773, 31.0292],
            [-97.8372, 31.1305],
            [-97.9014, 31.138],
            [-97.9118, 31.228],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825660',
        NAME: 'Killeen Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 917202663,
        AWATER: 9013076,
        INTPTLAT: '+31.1288376',
        INTPTLON: '-097.7598135',
        ELSDLEA: '',
        UNSDLEA: '25660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1608, 31.2199],
              [-97.0953, 31.2603],
              [-96.9623, 31.2755],
              [-96.9226, 31.2522],
              [-96.8291, 31.1059],
              [-96.7653, 31.0451],
              [-96.7737, 31.0163],
              [-96.9167, 31.0158],
              [-97.0751, 30.9837],
              [-97.1356, 31.0791],
              [-97.0657, 31.1274],
              [-97.1626, 31.1756],
              [-97.1867, 31.2046],
              [-97.1608, 31.2199],
            ],
          ],
          [
            [
              [-97.1668, 31.123],
              [-97.1356, 31.0791],
              [-97.1955, 31.0946],
              [-97.1668, 31.123],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837900',
        NAME: 'Rosebud-Lott Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 769550382,
        AWATER: 6188368,
        INTPTLAT: '+31.1244673',
        INTPTLON: '-096.9896434',
        ELSDLEA: '',
        UNSDLEA: '37900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5507, 30.8421],
            [-97.4963, 30.8798],
            [-97.4072, 30.8389],
            [-97.255, 30.8132],
            [-97.2488, 30.8139],
            [-97.2681, 30.7405],
            [-97.2746, 30.7369],
            [-97.3883, 30.7393],
            [-97.5448, 30.7849],
            [-97.5507, 30.8421],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809540',
        NAME: 'Bartlett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260362058,
        AWATER: 1624360,
        INTPTLAT: '+30.8016005',
        INTPTLON: '-097.4104450',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6191, 31.2173],
            [-97.4786, 31.2893],
            [-97.4325, 31.2031],
            [-97.4208, 31.165],
            [-97.3908, 31.0416],
            [-97.401, 30.9798],
            [-97.4433, 30.9686],
            [-97.5841, 31.0285],
            [-97.5495, 31.103],
            [-97.6911, 31.1616],
            [-97.6191, 31.2173],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809860',
        NAME: 'Belton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459184634,
        AWATER: 57390567,
        INTPTLAT: '+31.1206194',
        INTPTLON: '-097.5185359',
        ELSDLEA: '',
        UNSDLEA: '09860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.69, 30.9922],
            [-97.5841, 31.0285],
            [-97.4433, 30.9686],
            [-97.4963, 30.8798],
            [-97.5507, 30.8421],
            [-97.6813, 30.8815],
            [-97.69, 30.9922],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838580',
        NAME: 'Salado Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334187879,
        AWATER: 13799644,
        INTPTLAT: '+30.9345477',
        INTPTLON: '-097.5666264',
        ELSDLEA: '',
        UNSDLEA: '38580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9022, 36.0554],
            [-101.8828, 36.1836],
            [-101.7338, 36.139],
            [-101.6858, 36.1386],
            [-101.6235, 36.0554],
            [-101.6231, 35.8729],
            [-101.7577, 35.88],
            [-101.811, 35.9803],
            [-101.9222, 35.9956],
            [-101.9022, 36.0554],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841910',
        NAME: 'Sunray Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571298866,
        AWATER: 325909,
        INTPTLAT: '+36.0200558',
        INTPTLON: '-101.7507306',
        ELSDLEA: '',
        UNSDLEA: '41910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9606, 30.7061],
            [-100.1162, 30.7104],
            [-100.1165, 30.2903],
            [-100.7004, 30.2883],
            [-100.8004, 30.2881],
            [-100.9606, 30.2878],
            [-100.9606, 30.7061],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840800',
        NAME: 'Sonora Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3765525014,
        AWATER: 1277066,
        INTPTLAT: '+30.5176801',
        INTPTLON: '-100.5052697',
        ELSDLEA: '',
        UNSDLEA: '40800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9984, 34.6177],
            [-101.7762, 34.6318],
            [-101.7761, 34.661],
            [-101.4714, 34.6752],
            [-101.4721, 34.4341],
            [-101.6742, 34.4332],
            [-101.8861, 34.4599],
            [-101.9982, 34.4273],
            [-101.9983, 34.4935],
            [-101.9984, 34.6177],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843320',
        NAME: 'Tulia Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1105464376,
        AWATER: 13764379,
        INTPTLAT: '+34.5535152',
        INTPTLON: '-101.7150603',
        ELSDLEA: '',
        UNSDLEA: '43320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9526, 33.1671],
            [-98.9522, 33.1141],
            [-98.9513, 33.0143],
            [-98.8583, 32.9572],
            [-98.9367, 32.957],
            [-99.096, 32.9046],
            [-99.1754, 32.9567],
            [-99.1672, 33.0661],
            [-99.0935, 33.0654],
            [-99.0408, 33.1505],
            [-98.9526, 33.1671],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846410',
        NAME: 'Woodson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453962968,
        AWATER: 997283,
        INTPTLAT: '+33.0248289',
        INTPTLON: '-099.0310109',
        ELSDLEA: '',
        UNSDLEA: '46410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4343, 33.3989],
            [-99.0137, 33.3978],
            [-98.9535, 33.2859],
            [-98.9526, 33.1671],
            [-99.0408, 33.1505],
            [-99.0935, 33.0654],
            [-99.1672, 33.0661],
            [-99.1754, 32.9567],
            [-99.4456, 32.957],
            [-99.4713, 32.957],
            [-99.4716, 33.0918],
            [-99.4723, 33.3321],
            [-99.4343, 33.3989],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842780',
        NAME: 'Throckmorton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1983603397,
        AWATER: 6917722,
        INTPTLAT: '+33.2121987',
        INTPTLON: '-099.2443700',
        ELSDLEA: '',
        UNSDLEA: '42780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9982, 34.4273],
            [-101.8861, 34.4599],
            [-101.6742, 34.4332],
            [-101.4721, 34.4341],
            [-101.4716, 34.3123],
            [-101.5649, 34.3125],
            [-101.998, 34.313],
            [-101.9982, 34.4273],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825950',
        NAME: 'Kress Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670652426,
        AWATER: 6750701,
        INTPTLAT: '+34.3792109',
        INTPTLON: '-101.7337898',
        ELSDLEA: '',
        UNSDLEA: '25950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1573, 28.8491],
            [-98.0137, 28.8749],
            [-97.9836, 28.9666],
            [-97.8743, 29.0249],
            [-97.811, 29.1],
            [-97.8385, 29.1411],
            [-97.8182, 29.156],
            [-97.7884, 29.1778],
            [-97.7323, 29.21],
            [-97.6131, 29.1098],
            [-97.7257, 29.0283],
            [-97.7405, 28.9914],
            [-97.7914, 28.9045],
            [-97.959, 28.8045],
            [-98.0744, 28.7618],
            [-98.2018, 28.7107],
            [-98.2533, 28.7532],
            [-98.1573, 28.8491],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825140',
        NAME: 'Karnes City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 928961681,
        AWATER: 7593842,
        INTPTLAT: '+28.9512078',
        INTPTLON: '-097.8925780',
        ELSDLEA: '',
        UNSDLEA: '25140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5263, 29.2511],
            [-98.5269, 29.1554],
            [-98.5906, 29.1319],
            [-98.7122, 29.1445],
            [-98.735, 29.2267],
            [-98.6503, 29.28],
            [-98.5263, 29.2511],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840740',
        NAME: 'Somerset Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218951796,
        AWATER: 830237,
        INTPTLAT: '+29.2107109',
        INTPTLON: '-098.6205329',
        ELSDLEA: '',
        UNSDLEA: '40740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8048, 29.1475],
            [-98.8048, 29.1492],
            [-98.7122, 29.1445],
            [-98.5906, 29.1319],
            [-98.5269, 29.1554],
            [-98.5242, 29.1545],
            [-98.5506, 28.9974],
            [-98.6537, 29.026],
            [-98.6988, 28.9581],
            [-98.8047, 29.0343],
            [-98.8048, 29.1475],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835520',
        NAME: 'Poteet Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445730609,
        AWATER: 191811,
        INTPTLAT: '+29.0722617',
        INTPTLON: '-098.6754449',
        ELSDLEA: '',
        UNSDLEA: '35520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9947, 30.5791],
            [-94.8809, 30.5512],
            [-94.8494, 30.4936],
            [-94.8426, 30.4875],
            [-95.0481, 30.3969],
            [-95.1186, 30.3937],
            [-95.1594, 30.4826],
            [-95.1195, 30.5306],
            [-94.9947, 30.5791],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840020',
        NAME: 'Shepherd Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374664259,
        AWATER: 6056372,
        INTPTLAT: '+30.4855188',
        INTPTLON: '-095.0118202',
        ELSDLEA: '',
        UNSDLEA: '40020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2066, 30.9432],
            [-96.0691, 30.9515],
            [-96.0137, 30.8426],
            [-96.1684, 30.823],
            [-96.2066, 30.9432],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833030',
        NAME: 'North Zulch Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212491294,
        AWATER: 2561783,
        INTPTLAT: '+30.8739213',
        INTPTLON: '-096.1040092',
        ELSDLEA: '',
        UNSDLEA: '33030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2538, 33.1315],
            [-97.1233, 33.1383],
            [-97.158, 33.0435],
            [-97.1584, 33.0219],
            [-97.2189, 33.0449],
            [-97.2538, 33.1315],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808670',
        NAME: 'Argyle Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90483013,
        AWATER: 1041949,
        INTPTLAT: '+33.0979318',
        INTPTLON: '-097.1759808',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9043, 33.2042],
            [-96.8968, 33.1098],
            [-96.9963, 33.125],
            [-97.0051, 33.1603],
            [-96.9043, 33.2042],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827720',
        NAME: 'Little Elm Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68954693,
        AWATER: 31806656,
        INTPTLAT: '+33.1518927',
        INTPTLON: '-096.9495996',
        ELSDLEA: '',
        UNSDLEA: '27720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7785, 26.2332],
            [-97.779, 26.2949],
            [-97.6896, 26.3228],
            [-97.6942, 26.2714],
            [-97.6257, 26.1842],
            [-97.7052, 26.1248],
            [-97.7785, 26.1337],
            [-97.7861, 26.138],
            [-97.7785, 26.2332],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822530',
        NAME: 'Harlingen Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222454173,
        AWATER: 2031344,
        INTPTLAT: '+26.2178152',
        INTPTLON: '-097.7180616',
        ELSDLEA: '',
        UNSDLEA: '22530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5946, 26.1614],
            [-97.5341, 26.168],
            [-97.5167, 26.3268],
            [-97.447, 26.3249],
            [-97.3907, 26.4113],
            [-97.2896, 26.4114],
            [-97.1942, 26.2535],
            [-97.1685, 26.0952],
            [-97.3798, 26.0899],
            [-97.3813, 25.9925],
            [-97.4219, 25.9577],
            [-97.5428, 25.9775],
            [-97.5925, 26.0963],
            [-97.5946, 26.1614],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828290',
        NAME: 'Los Fresnos Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 789981239,
        AWATER: 468069894,
        INTPTLAT: '+26.1887232',
        INTPTLON: '-097.3923690',
        ELSDLEA: '',
        UNSDLEA: '28290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7785, 26.1337],
            [-97.7052, 26.1248],
            [-97.6257, 26.1842],
            [-97.5946, 26.1614],
            [-97.5925, 26.0963],
            [-97.6085, 25.9771],
            [-97.6674, 26.0291],
            [-97.7777, 26.0294],
            [-97.7785, 26.1337],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838790',
        NAME: 'San Benito Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256165088,
        AWATER: 10804304,
        INTPTLAT: '+26.0863854',
        INTPTLON: '-097.6772388',
        ELSDLEA: '',
        UNSDLEA: '38790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7639, 32.7579],
            [-95.7487, 32.7553],
            [-95.7669, 32.6547],
            [-95.8281, 32.6263],
            [-95.8294, 32.6979],
            [-95.7639, 32.7579],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820070',
        NAME: 'Fruitvale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74820915,
        AWATER: 1160141,
        INTPTLAT: '+32.6902949',
        INTPTLON: '-095.7899320',
        ELSDLEA: '',
        UNSDLEA: '20070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.779, 33.2602],
            [-95.5812, 33.359],
            [-95.5031, 33.3477],
            [-95.5161, 33.2501],
            [-95.5761, 33.1763],
            [-95.773, 33.237],
            [-95.779, 33.2602],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832970',
        NAME: 'North Hopkins Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235219389,
        AWATER: 31950503,
        INTPTLAT: '+33.2726286',
        INTPTLON: '-095.6200181',
        ELSDLEA: '',
        UNSDLEA: '32970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7286, 27.8496],
            [-97.7171, 27.9116],
            [-97.7042, 27.9218],
            [-97.5759, 27.8608],
            [-97.6195, 27.8079],
            [-97.7286, 27.8496],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812420',
        NAME: 'Calallen Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82917675,
        AWATER: 2312919,
        INTPTLAT: '+27.8600569',
        INTPTLON: '-097.6513451',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4764, 27.861],
            [-97.3706, 27.8766],
            [-97.2486, 27.8749],
            [-97.1882, 27.8242],
            [-97.1867, 27.8214],
            [-97.2592, 27.8142],
            [-97.3465, 27.6331],
            [-97.3982, 27.6376],
            [-97.4681, 27.7086],
            [-97.487, 27.7854],
            [-97.4764, 27.861],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815270',
        NAME: 'Corpus Christi Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187945758,
        AWATER: 246384884,
        INTPTLAT: '+27.7928459',
        INTPTLON: '-097.3853902',
        ELSDLEA: '',
        UNSDLEA: '15270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3295, 32.8511],
            [-100.2642, 32.8656],
            [-100.28, 32.9611],
            [-100.1189, 32.9598],
            [-100.0688, 33.0409],
            [-99.9893, 33.0391],
            [-99.9511, 32.9599],
            [-99.9786, 32.8732],
            [-100.0073, 32.7951],
            [-100.117, 32.7291],
            [-100.3295, 32.8511],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822260',
        NAME: 'Hamlin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 710733545,
        AWATER: 4030583,
        INTPTLAT: '+32.8891995',
        INTPTLON: '-100.1136320',
        ELSDLEA: '',
        UNSDLEA: '22260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8158, 32.6893],
            [-100.8158, 32.7617],
            [-100.7437, 32.7921],
            [-100.659, 32.709],
            [-100.6205, 32.5801],
            [-100.6979, 32.5253],
            [-100.7834, 32.5254],
            [-100.8176, 32.5508],
            [-100.8158, 32.6893],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823040',
        NAME: 'Hermleigh Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396303156,
        AWATER: 300801,
        INTPTLAT: '+32.6399990',
        INTPTLON: '-100.7355343',
        ELSDLEA: '',
        UNSDLEA: '23040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6205, 32.5801],
            [-100.4859, 32.579],
            [-100.4777, 32.4165],
            [-100.6621, 32.388],
            [-100.6979, 32.5253],
            [-100.6205, 32.5801],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837860',
        NAME: 'Roscoe Collegiate Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357008380,
        AWATER: 373197,
        INTPTLAT: '+32.4894288',
        INTPTLON: '-100.5650139',
        ELSDLEA: '',
        UNSDLEA: '37860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6559, 32.9635],
            [-100.6033, 33.0588],
            [-100.5178, 33.0742],
            [-100.401, 33.0485],
            [-100.3551, 32.9618],
            [-100.28, 32.9611],
            [-100.2642, 32.8656],
            [-100.3295, 32.8511],
            [-100.4216, 32.8318],
            [-100.4461, 32.7671],
            [-100.6591, 32.7338],
            [-100.6559, 32.9635],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838040',
        NAME: 'Rotan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 897291538,
        AWATER: 1603729,
        INTPTLAT: '+32.9035834',
        INTPTLON: '-100.4868291',
        ELSDLEA: '',
        UNSDLEA: '38040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2541, 32.6436],
            [-100.1711, 32.5925],
            [-100.1493, 32.5228],
            [-100.2513, 32.4893],
            [-100.2281, 32.404],
            [-100.4535, 32.3312],
            [-100.4777, 32.4165],
            [-100.4859, 32.579],
            [-100.4198, 32.6512],
            [-100.3251, 32.6744],
            [-100.2541, 32.6436],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842030',
        NAME: 'Sweetwater Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740434090,
        AWATER: 5402145,
        INTPTLAT: '+32.5064146',
        INTPTLON: '-100.3469201',
        ELSDLEA: '',
        UNSDLEA: '42030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.1651, 32.0003],
            [-103.0644, 32.0005],
            [-103.0645, 32.0871],
            [-102.7991, 32.0858],
            [-102.7984, 31.8636],
            [-102.9303, 31.8575],
            [-102.9067, 31.7918],
            [-103.0836, 31.7421],
            [-103.1677, 31.8254],
            [-103.2036, 31.9684],
            [-103.1651, 32.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825560',
        NAME: 'Kermit Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1035278984,
        AWATER: 19838,
        INTPTLAT: '+31.9383916',
        INTPTLON: '-102.9959856',
        ELSDLEA: '',
        UNSDLEA: '25560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3065, 30.4112],
            [-97.2815, 30.4187],
            [-97.1649, 30.481],
            [-97.0841, 30.4945],
            [-96.9636, 30.5572],
            [-96.8456, 30.4275],
            [-96.9055, 30.3989],
            [-96.9511, 30.2975],
            [-97.0914, 30.2657],
            [-97.1588, 30.3107],
            [-97.2182, 30.3467],
            [-97.3065, 30.4112],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827330',
        NAME: 'Lexington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 750235413,
        AWATER: 3709016,
        INTPTLAT: '+30.4025108',
        INTPTLON: '-097.0436511',
        ELSDLEA: '',
        UNSDLEA: '27330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3758, 31.9403],
            [-98.2739, 31.8887],
            [-98.3347, 31.8464],
            [-98.2987, 31.7688],
            [-98.399, 31.7175],
            [-98.4106, 31.7286],
            [-98.494, 31.8464],
            [-98.47, 31.9044],
            [-98.3758, 31.9403],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821990',
        NAME: 'Gustine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301182265,
        AWATER: 99617,
        INTPTLAT: '+31.8369824',
        INTPTLON: '-098.3957566',
        ELSDLEA: '',
        UNSDLEA: '21990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5035, 31.326],
            [-97.4498, 31.3651],
            [-97.343, 31.4004],
            [-97.3232, 31.4051],
            [-97.2983, 31.3698],
            [-97.3296, 31.2518],
            [-97.4325, 31.2031],
            [-97.4786, 31.2893],
            [-97.5035, 31.326],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831290',
        NAME: 'Moody Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252084283,
        AWATER: 12271063,
        INTPTLAT: '+31.2944902',
        INTPTLON: '-097.3852590',
        ELSDLEA: '',
        UNSDLEA: '31290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4325, 31.2031],
            [-97.3296, 31.2518],
            [-97.1867, 31.2046],
            [-97.1626, 31.1756],
            [-97.1668, 31.123],
            [-97.1955, 31.0946],
            [-97.2712, 31.1105],
            [-97.4208, 31.165],
            [-97.4325, 31.2031],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843260',
        NAME: 'Troy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270581252,
        AWATER: 1960584,
        INTPTLAT: '+31.1767489',
        INTPTLON: '-097.2835185',
        ELSDLEA: '',
        UNSDLEA: '43260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4963, 30.8798],
            [-97.4433, 30.9686],
            [-97.401, 30.9798],
            [-97.3227, 30.9059],
            [-97.255, 30.8132],
            [-97.4072, 30.8389],
            [-97.4963, 30.8798],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823370',
        NAME: 'Holland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204395309,
        AWATER: 1581091,
        INTPTLAT: '+30.8843283',
        INTPTLON: '-097.3788118',
        ELSDLEA: '',
        UNSDLEA: '23370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0008, 31.2476],
            [-97.9118, 31.228],
            [-97.9014, 31.138],
            [-97.8372, 31.1305],
            [-97.8773, 31.0292],
            [-98.0194, 31.2322],
            [-98.0008, 31.2476],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815240',
        NAME: 'Copperas Cove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 169299679,
        AWATER: 173093,
        INTPTLAT: '+31.1505515',
        INTPTLON: '-097.9224154',
        ELSDLEA: '',
        UNSDLEA: '15240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8409, 32.5839],
            [-94.7431, 32.6652],
            [-94.7018, 32.6522],
            [-94.702, 32.4218],
            [-94.5792, 32.3949],
            [-94.6721, 32.3705],
            [-94.7906, 32.3706],
            [-94.7772, 32.4367],
            [-94.7718, 32.5459],
            [-94.84, 32.5803],
            [-94.8409, 32.5839],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828110',
        NAME: 'Longview Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276115182,
        AWATER: 4761887,
        INTPTLAT: '+32.4968590',
        INTPTLON: '-094.7267978',
        ELSDLEA: '',
        UNSDLEA: '28110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4869, 33.4588],
            [-97.4864, 33.5418],
            [-97.3698, 33.5556],
            [-97.3158, 33.6293],
            [-97.222, 33.6063],
            [-97.1866, 33.5569],
            [-97.2391, 33.4257],
            [-97.3047, 33.4278],
            [-97.4869, 33.4588],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818600',
        NAME: 'Era Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389029774,
        AWATER: 1181670,
        INTPTLAT: '+33.5127860',
        INTPTLON: '-097.3355517',
        ELSDLEA: '',
        UNSDLEA: '18600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3158, 33.6293],
            [-97.2399, 33.7191],
            [-97.1941, 33.6808],
            [-97.222, 33.6063],
            [-97.3158, 33.6293],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827570',
        NAME: 'Lindsay Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105805012,
        AWATER: 167699,
        INTPTLAT: '+33.6554636',
        INTPTLON: '-097.2361625',
        ELSDLEA: '',
        UNSDLEA: '27570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5174, 33.914],
            [-97.5099, 33.9166],
            [-97.4033, 33.8195],
            [-97.485, 33.6982],
            [-97.4863, 33.5568],
            [-97.6256, 33.6487],
            [-97.6504, 33.6815],
            [-97.556, 33.752],
            [-97.5811, 33.8164],
            [-97.5174, 33.914],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838520',
        NAME: 'Saint Jo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426874904,
        AWATER: 1111059,
        INTPTLAT: '+33.7567313',
        INTPTLON: '-097.5381164',
        ELSDLEA: '',
        UNSDLEA: '38520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2448, 31.6017],
            [-97.1883, 31.677],
            [-97.1684, 31.6646],
            [-97.1413, 31.5963],
            [-97.2448, 31.6017],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810910',
        NAME: 'Bosqueville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59160963,
        AWATER: 1543132,
        INTPTLAT: '+31.6230705',
        INTPTLON: '-097.1871877',
        ELSDLEA: '',
        UNSDLEA: '10910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9913, 31.5816],
            [-96.9246, 31.5361],
            [-97.031, 31.5267],
            [-97.0397, 31.5608],
            [-96.9913, 31.5816],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822140',
        NAME: 'Hallsburg Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75143044,
        AWATER: 8324774,
        INTPTLAT: '+31.5703712',
        INTPTLON: '-096.9580680',
        ELSDLEA: '',
        UNSDLEA: '22140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3408, 31.5438],
            [-97.3414, 31.6097],
            [-97.2794, 31.6014],
            [-97.1571, 31.4837],
            [-97.1817, 31.4259],
            [-97.2423, 31.4782],
            [-97.3232, 31.4051],
            [-97.343, 31.4004],
            [-97.3408, 31.5438],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830640',
        NAME: 'Midway Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 222573131,
        AWATER: 3161035,
        INTPTLAT: '+31.4978135',
        INTPTLON: '-097.2661319',
        ELSDLEA: '',
        UNSDLEA: '30640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0374, 32.6228],
            [-96.9847, 32.6422],
            [-96.9084, 32.6043],
            [-96.8996, 32.5477],
            [-96.9186, 32.5478],
            [-97.0384, 32.5487],
            [-97.0374, 32.6228],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813230',
        NAME: 'Cedar Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93779825,
        AWATER: 15384814,
        INTPTLAT: '+32.5850189',
        INTPTLON: '-096.9727138',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7069, 32.5595],
            [-96.7425, 32.5467],
            [-96.8221, 32.5472],
            [-96.8229, 32.6188],
            [-96.7187, 32.6183],
            [-96.7069, 32.5595],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826670',
        NAME: 'Lancaster Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96695618,
        AWATER: 403692,
        INTPTLAT: '+32.5890648',
        INTPTLON: '-096.7711482',
        ELSDLEA: '',
        UNSDLEA: '26670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.649, 32.8383],
            [-96.5957, 32.8332],
            [-96.5198, 32.7545],
            [-96.5213, 32.662],
            [-96.6312, 32.7195],
            [-96.649, 32.8383],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830390',
        NAME: 'Mesquite Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141959388,
        AWATER: 587461,
        INTPTLAT: '+32.7592654',
        INTPTLON: '-096.5939996',
        ELSDLEA: '',
        UNSDLEA: '30390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1437, 32.98],
            [-96.082, 32.977],
            [-96.1192, 32.9394],
            [-96.1437, 32.98],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810740',
        NAME: 'Boles Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20270437,
        AWATER: 1786469,
        INTPTLAT: '+32.9547108',
        INTPTLON: '-096.1008097',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1744, 33.1925],
            [-96.1225, 33.2299],
            [-96.04, 33.2583],
            [-96.0206, 33.155],
            [-95.9943, 33.0821],
            [-96.0987, 33.0094],
            [-96.1322, 33.014],
            [-96.2088, 33.1222],
            [-96.1744, 33.1925],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821720',
        NAME: 'Greenville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343907431,
        AWATER: 3225410,
        INTPTLAT: '+33.1365757',
        INTPTLON: '-096.0941970',
        ELSDLEA: '',
        UNSDLEA: '21720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6314, 32.4745],
            [-99.5429, 32.4932],
            [-99.5264, 32.3112],
            [-99.6309, 32.2994],
            [-99.6782, 32.416],
            [-99.6314, 32.4745],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818690',
        NAME: 'Eula Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300934080,
        AWATER: 379527,
        INTPTLAT: '+32.4022328',
        INTPTLON: '-099.6001769',
        ELSDLEA: '',
        UNSDLEA: '18690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8675, 32.412],
            [-99.7424, 32.3868],
            [-99.6782, 32.416],
            [-99.6309, 32.2994],
            [-99.6739, 32.2701],
            [-99.794, 32.2774],
            [-99.8488, 32.3198],
            [-99.9772, 32.2757],
            [-99.9885, 32.3528],
            [-99.8675, 32.412],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846500',
        NAME: 'Wylie Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 359020986,
        AWATER: 483158,
        INTPTLAT: '+32.3449743',
        INTPTLON: '-099.7992969',
        ELSDLEA: '',
        UNSDLEA: '46500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4553, 31.5934],
            [-94.3284, 31.5936],
            [-94.2488, 31.4812],
            [-94.3142, 31.4084],
            [-94.3751, 31.4096],
            [-94.4302, 31.4549],
            [-94.4553, 31.5934],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813980',
        NAME: 'Chireno Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255984826,
        AWATER: 2725311,
        INTPTLAT: '+31.5164718',
        INTPTLON: '-094.3775590',
        ELSDLEA: '',
        UNSDLEA: '13980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3991, 31.6536],
            [-94.3058, 31.6437],
            [-94.1013, 31.6216],
            [-93.9845, 31.5694],
            [-94.0161, 31.4344],
            [-94.046, 31.3001],
            [-94.1326, 31.3087],
            [-94.1481, 31.4853],
            [-94.2488, 31.4812],
            [-94.3284, 31.5936],
            [-94.3991, 31.6536],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838760',
        NAME: 'San Augustine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 716156623,
        AWATER: 1425469,
        INTPTLAT: '+31.5094255',
        INTPTLON: '-094.1343998',
        ELSDLEA: '',
        UNSDLEA: '38760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1678, 30.5756],
            [-96.0678, 30.6152],
            [-96.0063, 30.7401],
            [-95.8442, 30.7276],
            [-95.8302, 30.6303],
            [-95.9016, 30.5582],
            [-95.8944, 30.4646],
            [-95.8124, 30.4367],
            [-95.8084, 30.3885],
            [-95.9808, 30.3974],
            [-96.0448, 30.5199],
            [-96.149, 30.524],
            [-96.1678, 30.5756],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808230',
        NAME: 'Anderson-Shiro Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 671282850,
        AWATER: 12682595,
        INTPTLAT: '+30.5658506',
        INTPTLON: '-095.9689625',
        ELSDLEA: '',
        UNSDLEA: '08230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1684, 30.823],
            [-96.0137, 30.8426],
            [-96.0063, 30.7401],
            [-96.0678, 30.6152],
            [-96.1678, 30.5756],
            [-96.1757, 30.5819],
            [-96.1684, 30.823],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824240',
        NAME: 'Iola Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336367564,
        AWATER: 10627557,
        INTPTLAT: '+30.7217157',
        INTPTLON: '-096.1092976',
        ELSDLEA: '',
        UNSDLEA: '24240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4761, 32.1735],
            [-97.2792, 32.2249],
            [-97.2744, 32.2259],
            [-97.3428, 32.1536],
            [-97.289, 32.0633],
            [-97.3131, 32.0519],
            [-97.4221, 32.0698],
            [-97.4862, 32.0325],
            [-97.4761, 32.1735],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810650',
        NAME: 'Blum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259034180,
        AWATER: 5108676,
        INTPTLAT: '+32.1228701',
        INTPTLON: '-097.3886992',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5402, 36.0565],
            [-100.4705, 36.0089],
            [-100.3669, 36.0557],
            [-100.0004, 36.0557],
            [-100.0004, 36.0426],
            [-100.0004, 35.8809],
            [-100.0004, 35.7462],
            [-100.0588, 35.7173],
            [-100.2013, 35.7162],
            [-100.309, 35.7451],
            [-100.3085, 35.6694],
            [-100.4514, 35.6489],
            [-100.5406, 35.6783],
            [-100.5402, 36.0565],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812700',
        NAME: 'Canadian Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1865955727,
        AWATER: 10029817,
        INTPTLAT: '+35.8594651',
        INTPTLON: '-100.2810083',
        ELSDLEA: '',
        UNSDLEA: '12700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1932, 29.7422],
            [-95.093, 29.7531],
            [-95.0947, 29.8066],
            [-95.0204, 29.7099],
            [-95.1037, 29.7059],
            [-95.1089, 29.6282],
            [-95.143, 29.6276],
            [-95.1932, 29.7422],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816530',
        NAME: 'Deer Park Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84394722,
        AWATER: 18807031,
        INTPTLAT: '+29.7153880',
        INTPTLON: '-095.1102534',
        ELSDLEA: '',
        UNSDLEA: '16530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0091, 34.3131],
            [-101.998, 34.313],
            [-101.5649, 34.3125],
            [-101.5645, 34.0213],
            [-101.742, 33.9922],
            [-101.7487, 34.0938],
            [-101.8183, 34.1519],
            [-101.9875, 34.1447],
            [-102.0091, 34.3131],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835070',
        NAME: 'Plainview Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1028233292,
        AWATER: 35016,
        INTPTLAT: '+34.1895335',
        INTPTLON: '-101.7469245',
        ELSDLEA: '',
        UNSDLEA: '35070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7895, 32.546],
            [-95.6107, 32.6266],
            [-95.5945, 32.687],
            [-95.5408, 32.6519],
            [-95.5019, 32.6203],
            [-95.524, 32.5002],
            [-95.5003, 32.3997],
            [-95.4492, 32.3552],
            [-95.7022, 32.3771],
            [-95.7601, 32.4761],
            [-95.7895, 32.546],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843920',
        NAME: 'Van Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574039138,
        AWATER: 11126645,
        INTPTLAT: '+32.4860360',
        INTPTLON: '-095.6241799',
        ELSDLEA: '',
        UNSDLEA: '43920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1342, 32.5036],
            [-97.0867, 32.5079],
            [-97.0874, 32.392],
            [-97.0866, 32.3611],
            [-97.119, 32.3559],
            [-97.1782, 32.4829],
            [-97.1342, 32.5036],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844010',
        NAME: 'Venus Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92123144,
        AWATER: 181255,
        INTPTLAT: '+32.4448569',
        INTPTLON: '-097.1277218',
        ELSDLEA: '',
        UNSDLEA: '44010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2602, 31.013],
            [-96.2558, 31.0679],
            [-96.1934, 31.1024],
            [-96.1934, 31.17],
            [-96.0905, 31.2086],
            [-96.0612, 31.1594],
            [-95.9917, 31.0843],
            [-96.0166, 30.9899],
            [-96.0691, 30.9515],
            [-96.2066, 30.9432],
            [-96.2602, 31.013],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832910',
        NAME: 'Normangee Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 458664921,
        AWATER: 3554126,
        INTPTLAT: '+31.0641677',
        INTPTLON: '-096.1268494',
        ELSDLEA: '',
        UNSDLEA: '32910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1843, 31.6853],
            [-97.0623, 31.6745],
            [-97.1221, 31.6028],
            [-97.1413, 31.5963],
            [-97.1684, 31.6646],
            [-97.1843, 31.6853],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814970',
        NAME: 'Connally Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83742936,
        AWATER: 1219936,
        INTPTLAT: '+31.6507598',
        INTPTLON: '-097.1310496',
        ELSDLEA: '',
        UNSDLEA: '14970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3408, 31.5438],
            [-97.343, 31.4004],
            [-97.4498, 31.3651],
            [-97.5174, 31.4623],
            [-97.3408, 31.5438],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829820',
        NAME: 'McGregor Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214257557,
        AWATER: 66103,
        INTPTLAT: '+31.4490776',
        INTPTLON: '-097.4048293',
        ELSDLEA: '',
        UNSDLEA: '29820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1221, 31.6028],
            [-97.0623, 31.6745],
            [-97.0241, 31.6806],
            [-97.0397, 31.5608],
            [-97.031, 31.5267],
            [-97.0451, 31.519],
            [-97.1221, 31.6028],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826280',
        NAME: 'La Vega Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84694736,
        AWATER: 2195439,
        INTPTLAT: '+31.6041618',
        INTPTLON: '-097.0668893',
        ELSDLEA: '',
        UNSDLEA: '26280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4673, 30.4643],
            [-97.3262, 30.5055],
            [-97.2815, 30.4187],
            [-97.3065, 30.4112],
            [-97.4629, 30.4414],
            [-97.4673, 30.4643],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815420',
        NAME: 'Coupland Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109101668,
        AWATER: 1198457,
        INTPTLAT: '+30.4497040',
        INTPTLON: '-097.3634631',
        ELSDLEA: '',
        UNSDLEA: '15420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0102, 30.4803],
            [-97.9905, 30.5097],
            [-97.9209, 30.435],
            [-98.0102, 30.4803],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826400',
        NAME: 'Lago Vista Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73959595,
        AWATER: 16487473,
        INTPTLAT: '+30.4461282',
        INTPTLON: '-097.9750983',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6605, 30.3753],
            [-97.4999, 30.4054],
            [-97.4438, 30.3194],
            [-97.4728, 30.2439],
            [-97.5681, 30.3068],
            [-97.6426, 30.2625],
            [-97.6605, 30.3753],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828890',
        NAME: 'Manor Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227324934,
        AWATER: 5062550,
        INTPTLAT: '+30.3322794',
        INTPTLON: '-097.5549287',
        ELSDLEA: '',
        UNSDLEA: '28890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8992, 30.3401],
            [-97.8444, 30.3363],
            [-97.9071, 30.3047],
            [-97.8992, 30.3401],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817760',
        NAME: 'Eanes Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85898361,
        AWATER: 2318857,
        INTPTLAT: '+30.3064749',
        INTPTLON: '-097.8346934',
        ELSDLEA: '',
        UNSDLEA: '17760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0726, 30.5818],
            [-98.0697, 30.5872],
            [-98.0666, 30.5931],
            [-98.0499, 30.6242],
            [-97.9116, 30.6068],
            [-97.7745, 30.6248],
            [-97.7508, 30.5606],
            [-97.8184, 30.4824],
            [-97.8008, 30.3997],
            [-97.8444, 30.3363],
            [-97.8992, 30.3401],
            [-97.9209, 30.435],
            [-97.9905, 30.5097],
            [-98.0726, 30.5818],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827030',
        NAME: 'Leander Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503947145,
        AWATER: 12981244,
        INTPTLAT: '+30.5189226',
        INTPTLON: '-097.8863759',
        ELSDLEA: '',
        UNSDLEA: '27030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9071, 30.3047],
            [-97.8444, 30.3363],
            [-97.8008, 30.3997],
            [-97.695, 30.4323],
            [-97.6605, 30.3753],
            [-97.6426, 30.2625],
            [-97.7135, 30.2504],
            [-97.7701, 30.107],
            [-97.811, 30.0971],
            [-97.9297, 30.1828],
            [-98.0106, 30.2437],
            [-97.9071, 30.3047],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808940',
        NAME: 'Austin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 590290425,
        AWATER: 3747794,
        INTPTLAT: '+30.3525239',
        INTPTLON: '-097.7207036',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8492, 29.916],
            [-97.7524, 29.9536],
            [-97.7102, 30.0245],
            [-97.6494, 30.0679],
            [-97.4379, 29.8892],
            [-97.3577, 29.8218],
            [-97.4433, 29.8054],
            [-97.4627, 29.7481],
            [-97.5179, 29.7606],
            [-97.627, 29.7603],
            [-97.7111, 29.801],
            [-97.7832, 29.8087],
            [-97.8492, 29.916],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827870',
        NAME: 'Lockhart Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 870371063,
        AWATER: 2914984,
        INTPTLAT: '+29.8864276',
        INTPTLON: '-097.6102174',
        ELSDLEA: '',
        UNSDLEA: '27870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5448, 30.7849],
            [-97.3883, 30.7393],
            [-97.2746, 30.7369],
            [-97.2532, 30.6932],
            [-97.3967, 30.6642],
            [-97.4696, 30.6328],
            [-97.5453, 30.6822],
            [-97.5664, 30.7722],
            [-97.5448, 30.7849],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821590',
        NAME: 'Granger Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243177840,
        AWATER: 13082699,
        INTPTLAT: '+30.7144680',
        INTPTLON: '-097.4320462',
        ELSDLEA: '',
        UNSDLEA: '21590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9401, 30.7668],
            [-97.8206, 30.7311],
            [-97.7745, 30.6248],
            [-97.9116, 30.6068],
            [-98.0499, 30.6242],
            [-97.9744, 30.7646],
            [-97.9401, 30.7668],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827420',
        NAME: 'Liberty Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283881265,
        AWATER: 1554026,
        INTPTLAT: '+30.6796284',
        INTPTLON: '-097.9184846',
        ELSDLEA: '',
        UNSDLEA: '27420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6138, 30.5823],
            [-97.5671, 30.6364],
            [-97.4903, 30.6269],
            [-97.489, 30.4741],
            [-97.5899, 30.4974],
            [-97.6138, 30.5823],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824100',
        NAME: 'Hutto Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175221430,
        AWATER: 1102477,
        INTPTLAT: '+30.5569358',
        INTPTLON: '-097.5411464',
        ELSDLEA: '',
        UNSDLEA: '24100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4903, 30.6269],
            [-97.4696, 30.6328],
            [-97.3967, 30.6642],
            [-97.3806, 30.5501],
            [-97.3262, 30.5055],
            [-97.4673, 30.4643],
            [-97.489, 30.4741],
            [-97.4903, 30.6269],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842280',
        NAME: 'Taylor Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198413189,
        AWATER: 2227463,
        INTPTLAT: '+30.5494015',
        INTPTLON: '-097.4269803',
        ELSDLEA: '',
        UNSDLEA: '42280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8206, 30.7311],
            [-97.7387, 30.7554],
            [-97.6855, 30.7098],
            [-97.5664, 30.7722],
            [-97.5453, 30.6822],
            [-97.4696, 30.6328],
            [-97.4903, 30.6269],
            [-97.5671, 30.6364],
            [-97.6138, 30.5823],
            [-97.7508, 30.5606],
            [-97.7745, 30.6248],
            [-97.8206, 30.7311],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820600',
        NAME: 'Georgetown Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460993083,
        AWATER: 7732026,
        INTPTLAT: '+30.6716170',
        INTPTLON: '-097.6728316',
        ELSDLEA: '',
        UNSDLEA: '20600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2805, 33.377],
            [-95.2637, 33.3717],
            [-95.177, 33.3778],
            [-95.1246, 33.2753],
            [-95.1256, 33.1191],
            [-95.1261, 33.0346],
            [-95.1522, 33.0134],
            [-95.3083, 33.0503],
            [-95.3081, 33.0782],
            [-95.3329, 33.2515],
            [-95.2805, 33.377],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831770',
        NAME: 'Mount Vernon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 626605135,
        AWATER: 25656405,
        INTPTLAT: '+33.1758457',
        INTPTLON: '-095.2190662',
        ELSDLEA: '',
        UNSDLEA: '31770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5031, 33.3477],
            [-95.3066, 33.378],
            [-95.2805, 33.377],
            [-95.3329, 33.2515],
            [-95.4345, 33.1888],
            [-95.5161, 33.2501],
            [-95.5031, 33.3477],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841790',
        NAME: 'Sulphur Bluff Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300978114,
        AWATER: 5550906,
        INTPTLAT: '+33.2982880',
        INTPTLON: '-095.4180633',
        ELSDLEA: '',
        UNSDLEA: '41790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.214, 30.8022],
            [-105.1677, 30.8305],
            [-105.1679, 31.5351],
            [-105.1548, 31.7053],
            [-104.9678, 31.7052],
            [-104.9145, 31.6618],
            [-104.9183, 32.0005],
            [-104.8478, 32.0005],
            [-104.0245, 32],
            [-104.0876, 31.2762],
            [-104.1024, 31.1052],
            [-104.4036, 30.9442],
            [-104.9798, 30.6293],
            [-105.214, 30.8022],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815990',
        NAME: 'Culberson County-Allamoore Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12589707951,
        AWATER: 822094,
        INTPTLAT: '+31.3717009',
        INTPTLON: '-104.6318749',
        ELSDLEA: '',
        UNSDLEA: '15990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9511, 30.2975],
            [-96.8939, 30.2392],
            [-96.7012, 30.3196],
            [-96.6409, 30.2968],
            [-96.7316, 30.2439],
            [-96.736, 30.1472],
            [-96.8063, 30.1504],
            [-96.8818, 30.099],
            [-97.0245, 30.0514],
            [-97.0592, 30.1781],
            [-97.0914, 30.2657],
            [-96.9511, 30.2975],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820640',
        NAME: 'Giddings Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670809136,
        AWATER: 9386425,
        INTPTLAT: '+30.1953442',
        INTPTLON: '-096.8961823',
        ELSDLEA: '',
        UNSDLEA: '20640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2354, 32.0363],
            [-100.0565, 32.1637],
            [-100.0558, 32.1057],
            [-99.8627, 32.0815],
            [-99.8512, 32.0234],
            [-99.7518, 31.9893],
            [-99.7522, 31.9447],
            [-99.8239, 31.8887],
            [-100.0682, 31.8717],
            [-100.1607, 31.9195],
            [-100.2354, 32.0363],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846260',
        NAME: 'Winters Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 903739872,
        AWATER: 4380082,
        INTPTLAT: '+32.0127273',
        INTPTLON: '-100.0005221',
        ELSDLEA: '',
        UNSDLEA: '46260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.84, 32.5803],
            [-94.7718, 32.5459],
            [-94.8416, 32.5316],
            [-94.84, 32.5803],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841190',
        NAME: 'Spring Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23558307,
        AWATER: 0,
        INTPTLAT: '+32.5598310',
        INTPTLON: '-094.8098975',
        ELSDLEA: '',
        UNSDLEA: '41190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1313, 32.549],
            [-95.0414, 32.6024],
            [-95.0584, 32.6675],
            [-95.0362, 32.6852],
            [-94.9622, 32.5748],
            [-94.8767, 32.571],
            [-94.8729, 32.5721],
            [-94.9089, 32.4662],
            [-94.9868, 32.4234],
            [-95.0364, 32.4462],
            [-95.1208, 32.4418],
            [-95.1313, 32.549],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820760',
        NAME: 'Gladewater Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280188881,
        AWATER: 3307961,
        INTPTLAT: '+32.4923486',
        INTPTLON: '-095.0687347',
        ELSDLEA: '',
        UNSDLEA: '20760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4208, 31.165],
            [-97.2712, 31.1105],
            [-97.2886, 31.0525],
            [-97.3908, 31.0416],
            [-97.4208, 31.165],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842330',
        NAME: 'Temple Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154883587,
        AWATER: 699870,
        INTPTLAT: '+31.0933849',
        INTPTLON: '-097.3472469',
        ELSDLEA: '',
        UNSDLEA: '42330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8416, 32.5316],
            [-94.7718, 32.5459],
            [-94.7772, 32.4367],
            [-94.8289, 32.4576],
            [-94.8698, 32.4707],
            [-94.8416, 32.5316],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834980',
        NAME: 'Pine Tree Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72953134,
        AWATER: 330276,
        INTPTLAT: '+32.4991004',
        INTPTLON: '-094.8126327',
        ELSDLEA: '',
        UNSDLEA: '34980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1302, 30.3602],
            [-99.01, 30.4134],
            [-99.0275, 30.4982],
            [-98.9642, 30.4985],
            [-98.5917, 30.4999],
            [-98.5879, 30.2079],
            [-98.546, 30.1596],
            [-98.711, 30.1001],
            [-98.772, 30.138],
            [-98.9794, 30.1377],
            [-99.1254, 30.1361],
            [-99.1302, 30.3602],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819840',
        NAME: 'Fredericksburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1963650468,
        AWATER: 8047460,
        INTPTLAT: '+30.3075152',
        INTPTLON: '-098.8363078',
        ELSDLEA: '',
        UNSDLEA: '19840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.13, 26.0743],
            [-98.123, 26.1131],
            [-98.1018, 26.2375],
            [-98.0244, 26.2638],
            [-98.0217, 26.1055],
            [-98.0083, 26.0611],
            [-98.13, 26.0743],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817390',
        NAME: 'Donna Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224877442,
        AWATER: 2826466,
        INTPTLAT: '+26.1442455',
        INTPTLON: '-098.0741143',
        ELSDLEA: '',
        UNSDLEA: '17390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3117, 26.1543],
            [-98.2794, 26.1499],
            [-98.2495, 26.146],
            [-98.196, 26.1233],
            [-98.123, 26.1131],
            [-98.13, 26.0743],
            [-98.1985, 26.0554],
            [-98.3181, 26.1167],
            [-98.3117, 26.1543],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823100',
        NAME: 'Hidalgo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85597517,
        AWATER: 1986228,
        INTPTLAT: '+26.0736662',
        INTPTLON: '-098.1874561',
        ELSDLEA: '',
        UNSDLEA: '23100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9083, 26.4332],
            [-97.8625, 26.4454],
            [-97.6837, 26.4401],
            [-97.6841, 26.4824],
            [-97.5981, 26.4688],
            [-97.1833, 26.4714],
            [-97.1674, 26.4131],
            [-97.2896, 26.4114],
            [-97.3907, 26.4113],
            [-97.447, 26.3249],
            [-97.5167, 26.3268],
            [-97.6896, 26.3228],
            [-97.779, 26.2949],
            [-97.8618, 26.3076],
            [-97.923, 26.3572],
            [-97.9083, 26.4332],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828620',
        NAME: 'Lyford Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 742852127,
        AWATER: 178135136,
        INTPTLAT: '+26.3971625',
        INTPTLON: '-097.5926019',
        ELSDLEA: '',
        UNSDLEA: '28620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2268, 26.1433],
            [-98.2003, 26.2511],
            [-98.1018, 26.2375],
            [-98.123, 26.1131],
            [-98.196, 26.1233],
            [-98.2268, 26.1433],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834860',
        NAME: 'Pharr-San Juan-Alamo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142855404,
        AWATER: 58823,
        INTPTLAT: '+26.1803300',
        INTPTLON: '-098.1635131',
        ELSDLEA: '',
        UNSDLEA: '34860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6814, 30.8817],
            [-96.6187, 30.9166],
            [-96.5214, 30.9319],
            [-96.418, 30.8081],
            [-96.5035, 30.7239],
            [-96.5517, 30.8232],
            [-96.6443, 30.7788],
            [-96.6792, 30.844],
            [-96.6814, 30.8817],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822830',
        NAME: 'Hearne Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287784917,
        AWATER: 2329851,
        INTPTLAT: '+30.8406108',
        INTPTLON: '-096.5493804',
        ELSDLEA: '',
        UNSDLEA: '22830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5813, 31.0745],
            [-96.5666, 31.1376],
            [-96.4628, 31.2294],
            [-96.4133, 31.3108],
            [-96.2994, 31.1842],
            [-96.2558, 31.0679],
            [-96.2602, 31.013],
            [-96.311, 30.9812],
            [-96.418, 30.8081],
            [-96.5214, 30.9319],
            [-96.6187, 30.9166],
            [-96.5378, 31.0246],
            [-96.5813, 31.0745],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819740',
        NAME: 'Franklin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1030860672,
        AWATER: 7023290,
        INTPTLAT: '+31.0562086',
        INTPTLON: '-096.4187682',
        ELSDLEA: '',
        UNSDLEA: '19740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5035, 30.7239],
            [-96.567, 30.6957],
            [-96.6213, 30.7308],
            [-96.6443, 30.7788],
            [-96.5517, 30.8232],
            [-96.5035, 30.7239],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831950',
        NAME: 'Mumford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103803509,
        AWATER: 1027414,
        INTPTLAT: '+30.7555731',
        INTPTLON: '-096.5501936',
        ELSDLEA: '',
        UNSDLEA: '31950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6209, 31.5133],
            [-97.5174, 31.4623],
            [-97.4498, 31.3651],
            [-97.5035, 31.326],
            [-97.5561, 31.3706],
            [-97.6209, 31.5133],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833420',
        NAME: 'Oglesby Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179664574,
        AWATER: 2299107,
        INTPTLAT: '+31.4095183',
        INTPTLON: '-097.5311336',
        ELSDLEA: '',
        UNSDLEA: '33420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8778, 33.7589],
            [-94.7547, 33.7331],
            [-94.7461, 33.703],
            [-94.7469, 33.3289],
            [-94.8088, 33.3636],
            [-94.8263, 33.3788],
            [-94.8299, 33.5088],
            [-94.9219, 33.6897],
            [-94.8778, 33.7589],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809060',
        NAME: 'Avery Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 429548994,
        AWATER: 5028520,
        INTPTLAT: '+33.5748865',
        INTPTLON: '-094.7956895',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0956, 33.8881],
            [-95.0643, 33.9101],
            [-94.8778, 33.7589],
            [-94.9219, 33.6897],
            [-94.8299, 33.5088],
            [-94.8263, 33.3788],
            [-94.9534, 33.3961],
            [-94.9522, 33.456],
            [-95.1563, 33.5168],
            [-95.186, 33.5788],
            [-95.1479, 33.6601],
            [-95.183, 33.704],
            [-95.1545, 33.8509],
            [-95.0956, 33.8881],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814190',
        NAME: 'Clarksville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1214508067,
        AWATER: 14208574,
        INTPTLAT: '+33.6292843',
        INTPTLON: '-095.0015742',
        ELSDLEA: '',
        UNSDLEA: '14190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5198, 32.7545],
            [-96.519, 32.8067],
            [-96.3808, 32.8137],
            [-96.3722, 32.6783],
            [-96.3873, 32.6516],
            [-96.4538, 32.6978],
            [-96.5213, 32.662],
            [-96.5198, 32.7545],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819560',
        NAME: 'Forney Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207851362,
        AWATER: 3318896,
        INTPTLAT: '+32.7362590',
        INTPTLON: '-096.4467585',
        ELSDLEA: '',
        UNSDLEA: '19560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5006, 32.4947],
            [-96.3623, 32.5901],
            [-96.2885, 32.4556],
            [-96.4343, 32.4109],
            [-96.4958, 32.4683],
            [-96.5006, 32.4947],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839570',
        NAME: 'Scurry-Rosser Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210985049,
        AWATER: 2689937,
        INTPTLAT: '+32.4914385',
        INTPTLON: '-096.3918524',
        ELSDLEA: '',
        UNSDLEA: '39570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.53, 32.5453],
            [-96.5213, 32.662],
            [-96.4538, 32.6978],
            [-96.3873, 32.6516],
            [-96.3623, 32.5901],
            [-96.5006, 32.4947],
            [-96.53, 32.5453],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815510',
        NAME: 'Crandall Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213873192,
        AWATER: 4989466,
        INTPTLAT: '+32.5985105',
        INTPTLON: '-096.4510460',
        ELSDLEA: '',
        UNSDLEA: '15510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3808, 32.8137],
            [-96.3082, 32.8142],
            [-96.2973, 32.8671],
            [-96.2361, 32.8873],
            [-96.1729, 32.8136],
            [-96.099, 32.8238],
            [-96.1997, 32.7268],
            [-96.1523, 32.6642],
            [-96.3299, 32.6448],
            [-96.3722, 32.6783],
            [-96.3808, 32.8137],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842450',
        NAME: 'Terrell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446894766,
        AWATER: 5088511,
        INTPTLAT: '+32.7534925',
        INTPTLON: '-096.2462566',
        ELSDLEA: '',
        UNSDLEA: '42450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.099, 32.8238],
            [-95.9344, 32.8372],
            [-95.8785, 32.7878],
            [-95.943, 32.682],
            [-95.8974, 32.6081],
            [-96.0758, 32.5657],
            [-96.1523, 32.6642],
            [-96.1997, 32.7268],
            [-96.099, 32.8238],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845950',
        NAME: 'Wills Point Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 562505479,
        AWATER: 20211369,
        INTPTLAT: '+32.7151762',
        INTPTLON: '-096.0397286',
        ELSDLEA: '',
        UNSDLEA: '45950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1151, 29.9116],
            [-95.958, 29.9318],
            [-95.873, 29.9198],
            [-95.9023, 29.7596],
            [-96.0327, 29.7279],
            [-96.1025, 29.8097],
            [-96.1124, 29.8894],
            [-96.1151, 29.9116],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838190',
        NAME: 'Royal Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409960716,
        AWATER: 4408710,
        INTPTLAT: '+29.8567777',
        INTPTLON: '-095.9773111',
        ELSDLEA: '',
        UNSDLEA: '38190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0932, 30.2252],
            [-96.0048, 30.2337],
            [-96.0339, 30.2041],
            [-95.9924, 29.9601],
            [-95.958, 29.9318],
            [-96.1151, 29.9116],
            [-96.0845, 30.0051],
            [-96.1461, 30.0702],
            [-96.1914, 30.1347],
            [-96.159, 30.2066],
            [-96.0932, 30.2252],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822920',
        NAME: 'Hempstead Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374262584,
        AWATER: 6523520,
        INTPTLAT: '+30.0794826',
        INTPTLON: '-096.0793423',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4107, 31.7316],
            [-99.2747, 31.8483],
            [-99.2023, 31.8149],
            [-99.2031, 31.6215],
            [-99.2028, 31.4672],
            [-99.3072, 31.4173],
            [-99.4541, 31.4396],
            [-99.4562, 31.5136],
            [-99.4107, 31.7316],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839210',
        NAME: 'Santa Anna Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 824386328,
        AWATER: 3993792,
        INTPTLAT: '+31.6236699',
        INTPTLON: '-099.3216018',
        ELSDLEA: '',
        UNSDLEA: '39210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7518, 31.9893],
            [-99.714, 32.0821],
            [-99.6314, 32.0813],
            [-99.4573, 32.0801],
            [-99.4061, 32.0801],
            [-99.1987, 31.9704],
            [-99.2007, 31.8824],
            [-99.2747, 31.8483],
            [-99.4107, 31.7316],
            [-99.5014, 31.9039],
            [-99.6879, 31.9173],
            [-99.7522, 31.9447],
            [-99.7518, 31.9893],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814550',
        NAME: 'Coleman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1185184431,
        AWATER: 12076475,
        INTPTLAT: '+31.9558857',
        INTPTLON: '-099.4506778',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0323, 32.9893],
            [-96.9423, 32.9888],
            [-96.9852, 32.8887],
            [-97.0329, 32.9043],
            [-97.0323, 32.9893],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815210',
        NAME: 'Coppell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59983547,
        AWATER: 4467022,
        INTPTLAT: '+32.9504300',
        INTPTLON: '-096.9881796',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6652, 32.9852],
            [-96.6134, 32.984],
            [-96.5169, 32.9823],
            [-96.5189, 32.8335],
            [-96.5957, 32.8332],
            [-96.649, 32.8383],
            [-96.6917, 32.8788],
            [-96.6652, 32.9852],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820340',
        NAME: 'Garland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221767710,
        AWATER: 19803855,
        INTPTLAT: '+32.9146981',
        INTPTLON: '-096.5951361',
        ELSDLEA: '',
        UNSDLEA: '20340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0329, 32.9043],
            [-96.9852, 32.8887],
            [-96.9311, 32.8861],
            [-96.8904, 32.8453],
            [-96.9172, 32.7671],
            [-97.0338, 32.8342],
            [-97.0329, 32.9043],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824420',
        NAME: 'Irving Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130698647,
        AWATER: 1828894,
        INTPTLAT: '+32.8315697',
        INTPTLON: '-096.9626184',
        ELSDLEA: '',
        UNSDLEA: '24420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4845, 30.5169],
            [-99.3081, 30.4998],
            [-99.2805, 30.4282],
            [-99.1302, 30.3602],
            [-99.1254, 30.1361],
            [-99.1878, 30.1354],
            [-99.301, 30.1343],
            [-99.3014, 30.1879],
            [-99.3936, 30.2116],
            [-99.4767, 30.2882],
            [-99.4415, 30.3969],
            [-99.4845, 30.5169],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822590',
        NAME: 'Harper Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1001509326,
        AWATER: 888483,
        INTPTLAT: '+30.3118795',
        INTPTLON: '-099.2859632',
        ELSDLEA: '',
        UNSDLEA: '22590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2752, 30.0167],
            [-99.1801, 30.085],
            [-99.1878, 30.1354],
            [-99.1254, 30.1361],
            [-98.9794, 30.1377],
            [-99.0752, 30.1049],
            [-99.0601, 30.0156],
            [-99.1791, 29.8953],
            [-99.3604, 29.9441],
            [-99.2752, 30.0167],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825590',
        NAME: 'Kerrville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424265461,
        AWATER: 3393562,
        INTPTLAT: '+30.0103258',
        INTPTLON: '-099.1916391',
        ELSDLEA: '',
        UNSDLEA: '25590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0601, 30.0156],
            [-98.9532, 29.9736],
            [-98.9901, 29.9115],
            [-98.9897, 29.8135],
            [-99.0951, 29.8605],
            [-99.1791, 29.8953],
            [-99.0601, 30.0156],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813350',
        NAME: 'Center Point Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247593021,
        AWATER: 1357258,
        INTPTLAT: '+29.9027722',
        INTPTLON: '-099.0378818',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0245, 32],
            [-103.9802, 32.0001],
            [-103.9122, 31.9024],
            [-103.815, 31.8521],
            [-103.7966, 31.77],
            [-103.7006, 31.7484],
            [-103.6109, 31.6518],
            [-103.3379, 31.6514],
            [-103.3376, 31.4178],
            [-103.2617, 31.4276],
            [-103.1812, 31.3702],
            [-103.0462, 31.3837],
            [-103.011, 31.3713],
            [-103.0458, 31.335],
            [-103.5851, 30.7665],
            [-103.6799, 30.8291],
            [-103.7122, 31.0172],
            [-103.6822, 31.1392],
            [-103.6196, 31.1778],
            [-103.61, 31.2642],
            [-104.0876, 31.2762],
            [-104.0245, 32],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834550',
        NAME: 'Pecos-Barstow-Toyah Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5833798329,
        AWATER: 15074402,
        INTPTLAT: '+31.3852618',
        INTPTLON: '-103.6367353',
        ELSDLEA: '',
        UNSDLEA: '34550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4259, 32.2248],
            [-101.3489, 32.1462],
            [-101.1817, 32.1953],
            [-101.0652, 32.1788],
            [-101.139, 32.087],
            [-101.2642, 32.0871],
            [-101.5606, 32.0871],
            [-101.5911, 32.1863],
            [-101.5396, 32.2444],
            [-101.4259, 32.2248],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819620',
        NAME: 'Forsan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 539995968,
        AWATER: 2139893,
        INTPTLAT: '+32.1622422',
        INTPTLON: '-101.3662118',
        ELSDLEA: '',
        UNSDLEA: '19620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8034, 32.987],
            [-96.6652, 32.9852],
            [-96.6917, 32.8788],
            [-96.8034, 32.987],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837020',
        NAME: 'Richardson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119440666,
        AWATER: 216947,
        INTPTLAT: '+32.9371427',
        INTPTLON: '-096.7357278',
        ELSDLEA: '',
        UNSDLEA: '37020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9084, 32.6043],
            [-96.8828, 32.6189],
            [-96.8229, 32.6188],
            [-96.8221, 32.5472],
            [-96.8996, 32.5477],
            [-96.9084, 32.6043],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816860',
        NAME: 'DeSoto Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57909384,
        AWATER: 72070,
        INTPTLAT: '+32.5823688',
        INTPTLON: '-096.8622558',
        ELSDLEA: '',
        UNSDLEA: '16860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0371, 32.6454],
            [-97.0347, 32.7885],
            [-97.0338, 32.8342],
            [-96.9172, 32.7671],
            [-96.97, 32.677],
            [-96.9847, 32.6422],
            [-97.0374, 32.6228],
            [-97.0371, 32.6454],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821420',
        NAME: 'Grand Prairie Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123701610,
        AWATER: 14838974,
        INTPTLAT: '+32.7243029',
        INTPTLON: '-096.9974987',
        ELSDLEA: '',
        UNSDLEA: '21420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.412, 28.8833],
            [-99.2112, 28.8405],
            [-99.2014, 28.7666],
            [-99.0781, 28.6958],
            [-99.0538, 28.6452],
            [-99.1173, 28.6446],
            [-99.1288, 28.4958],
            [-99.313, 28.5924],
            [-99.3961, 28.5926],
            [-99.409, 28.6406],
            [-99.412, 28.8833],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817100',
        NAME: 'Dilley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 885233171,
        AWATER: 873406,
        INTPTLAT: '+28.6963001',
        INTPTLON: '-099.2530066',
        ELSDLEA: '',
        UNSDLEA: '17100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8043, 28.9845],
            [-98.8047, 29.0343],
            [-98.6988, 28.9581],
            [-98.6256, 28.9253],
            [-98.6761, 28.7662],
            [-98.6109, 28.713],
            [-98.6108, 28.6483],
            [-98.8008, 28.6473],
            [-98.8008, 28.6475],
            [-98.8028, 28.8465],
            [-98.8615, 28.9304],
            [-98.8043, 28.9845],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813710',
        NAME: 'Charlotte Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 633830590,
        AWATER: 649607,
        INTPTLAT: '+28.8130966',
        INTPTLON: '-098.7261146',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4139, 29.0913],
            [-99.2532, 29.0915],
            [-99.2741, 29.0006],
            [-99.1745, 28.9605],
            [-99.0777, 29.0909],
            [-98.9132, 29.0906],
            [-98.9405, 28.9802],
            [-98.8043, 28.9845],
            [-98.8615, 28.9304],
            [-98.8028, 28.8465],
            [-98.8008, 28.6475],
            [-99.0538, 28.6452],
            [-99.0781, 28.6958],
            [-99.2014, 28.7666],
            [-99.2112, 28.8405],
            [-99.412, 28.8833],
            [-99.4123, 28.9206],
            [-99.4139, 29.0913],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834470',
        NAME: 'Pearsall Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1929926793,
        AWATER: 1671357,
        INTPTLAT: '+28.8722098',
        INTPTLON: '-099.0758061',
        ELSDLEA: '',
        UNSDLEA: '34470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8618, 26.2301],
            [-97.8617, 26.2348],
            [-97.7785, 26.2332],
            [-97.7861, 26.138],
            [-97.8619, 26.13],
            [-97.8618, 26.2301],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826040',
        NAME: 'La Feria Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89277653,
        AWATER: 2424057,
        INTPTLAT: '+26.1822732',
        INTPTLON: '-097.8230093',
        ELSDLEA: '',
        UNSDLEA: '26040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6896, 26.3228],
            [-97.5167, 26.3268],
            [-97.5341, 26.168],
            [-97.5946, 26.1614],
            [-97.6257, 26.1842],
            [-97.6942, 26.2714],
            [-97.6896, 26.3228],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837170',
        NAME: 'Rio Hondo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213369977,
        AWATER: 3742436,
        INTPTLAT: '+26.2549612',
        INTPTLON: '-097.5937624',
        ELSDLEA: '',
        UNSDLEA: '37170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8618, 26.3076],
            [-97.779, 26.2949],
            [-97.7785, 26.2332],
            [-97.8617, 26.2348],
            [-97.8618, 26.3076],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839360',
        NAME: 'Santa Rosa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67919141,
        AWATER: 176135,
        INTPTLAT: '+26.2760053',
        INTPTLON: '-097.8195349',
        ELSDLEA: '',
        UNSDLEA: '39360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4219, 25.9577],
            [-97.3813, 25.9925],
            [-97.3798, 26.0899],
            [-97.1685, 26.0952],
            [-97.1942, 26.2535],
            [-97.2896, 26.4114],
            [-97.1674, 26.4131],
            [-97.1261, 26.2504],
            [-97.0911, 25.9738],
            [-97.2767, 25.9521],
            [-97.3668, 25.9157],
            [-97.4219, 25.9577],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835250',
        NAME: 'Point Isabel Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299494173,
        AWATER: 495458028,
        INTPTLAT: '+26.0263880',
        INTPTLON: '-097.1631040',
        ELSDLEA: '',
        UNSDLEA: '35250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8168, 32.8216],
            [-96.8113, 32.8656],
            [-96.7705, 32.8573],
            [-96.8168, 32.8216],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823250',
        NAME: 'Highland Park Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 16132210,
        AWATER: 19024,
        INTPTLAT: '+32.8445433',
        INTPTLON: '-096.7963891',
        ELSDLEA: '',
        UNSDLEA: '23250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.97, 32.677],
            [-96.8828, 32.6189],
            [-96.9084, 32.6043],
            [-96.9847, 32.6422],
            [-96.97, 32.677],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817640',
        NAME: 'Duncanville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76212453,
        AWATER: 262744,
        INTPTLAT: '+32.6564922',
        INTPTLON: '-096.9284213',
        ELSDLEA: '',
        UNSDLEA: '17640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2766, 31.0702],
            [-94.1296, 31.0993],
            [-94.0655, 31.112],
            [-93.8871, 31.0466],
            [-93.8672, 30.8974],
            [-93.87, 30.8082],
            [-93.9578, 30.7669],
            [-94.1354, 30.7676],
            [-94.1978, 30.8567],
            [-94.2766, 31.0702],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824630',
        NAME: 'Jasper Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1033660243,
        AWATER: 55293618,
        INTPTLAT: '+30.9344247',
        INTPTLON: '-094.0595784',
        ELSDLEA: '',
        UNSDLEA: '24630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1016, 30.2623],
            [-94.0278, 30.2876],
            [-93.9894, 30.2422],
            [-93.9227, 30.1526],
            [-93.9472, 30.0874],
            [-94.0281, 30.0663],
            [-94.1159, 30.1603],
            [-94.1177, 30.2418],
            [-94.1016, 30.2623],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844160',
        NAME: 'Vidor Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302752874,
        AWATER: 12140242,
        INTPTLAT: '+30.1676200',
        INTPTLON: '-094.0225637',
        ELSDLEA: '',
        UNSDLEA: '44160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2986, 31.6927],
            [-100.1186, 31.6989],
            [-100.0419, 31.5794],
            [-100.112, 31.5274],
            [-100.2557, 31.5345],
            [-100.2985, 31.625],
            [-100.2986, 31.6927],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830750',
        NAME: 'Miles Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366340684,
        AWATER: 157532,
        INTPTLAT: '+31.6210721',
        INTPTLON: '-100.1814127',
        ELSDLEA: '',
        UNSDLEA: '30750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6909, 31.3379],
            [-100.5523, 31.3188],
            [-100.4856, 31.2481],
            [-100.2795, 31.2431],
            [-100.2795, 31.0884],
            [-100.6888, 31.0866],
            [-100.6909, 31.3379],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814010',
        NAME: 'Christoval Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 807685804,
        AWATER: 1049576,
        INTPTLAT: '+31.2006715',
        INTPTLON: '-100.5395632',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.394, 31.0861],
            [-95.3368, 30.9874],
            [-95.232, 30.9956],
            [-95.2549, 30.9065],
            [-95.3275, 30.8595],
            [-95.415, 30.9336],
            [-95.6179, 30.9304],
            [-95.394, 31.0861],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843200',
        NAME: 'Trinity Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374149481,
        AWATER: 41719159,
        INTPTLAT: '+30.9736524',
        INTPTLON: '-095.4178162',
        ELSDLEA: '',
        UNSDLEA: '43200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6153, 30.5541],
            [-95.4806, 30.6465],
            [-95.3506, 30.5859],
            [-95.3592, 30.5044],
            [-95.5989, 30.509],
            [-95.6112, 30.5155],
            [-95.6153, 30.5541],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832640',
        NAME: 'New Waverly Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262640604,
        AWATER: 5950317,
        INTPTLAT: '+30.5711831',
        INTPTLON: '-095.4919562',
        ELSDLEA: '',
        UNSDLEA: '32640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5989, 30.509],
            [-95.3592, 30.5044],
            [-95.2615, 30.4463],
            [-95.2351, 30.3776],
            [-95.4003, 30.4145],
            [-95.4917, 30.3482],
            [-95.5687, 30.3639],
            [-95.5989, 30.509],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845900',
        NAME: 'Willis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391831270,
        AWATER: 33510837,
        INTPTLAT: '+30.4344364',
        INTPTLON: '-095.4434078',
        ELSDLEA: '',
        UNSDLEA: '45900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3592, 30.5044],
            [-95.3506, 30.5859],
            [-95.3275, 30.8595],
            [-95.2549, 30.9065],
            [-95.2002, 30.8246],
            [-95.132, 30.7624],
            [-95.1454, 30.7138],
            [-95.0244, 30.6605],
            [-95.0088, 30.6139],
            [-94.9947, 30.5791],
            [-95.1195, 30.5306],
            [-95.1594, 30.4826],
            [-95.1186, 30.3937],
            [-95.2615, 30.4463],
            [-95.3592, 30.5044],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814520',
        NAME: 'Coldspring-Oakhurst Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 917632060,
        AWATER: 145293600,
        INTPTLAT: '+30.6441231',
        INTPTLON: '-095.2159316',
        ELSDLEA: '',
        UNSDLEA: '14520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6409, 30.2968],
            [-96.4959, 30.3248],
            [-96.5711, 30.2263],
            [-96.5188, 30.1763],
            [-96.5789, 30.0587],
            [-96.622, 30.0443],
            [-96.6337, 30.1061],
            [-96.736, 30.1472],
            [-96.7316, 30.2439],
            [-96.6409, 30.2968],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812240',
        NAME: 'Burton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391570025,
        AWATER: 26789331,
        INTPTLAT: '+30.2129310',
        INTPTLON: '-096.6069772',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9522, 33.1141],
            [-98.7493, 33.092],
            [-98.6348, 33.1465],
            [-98.663, 33.309],
            [-98.5817, 33.3956],
            [-98.4207, 33.3962],
            [-98.4208, 33.363],
            [-98.4234, 33.1931],
            [-98.4587, 33.1364],
            [-98.4266, 33.0079],
            [-98.4272, 32.9523],
            [-98.5756, 32.9543],
            [-98.8583, 32.9572],
            [-98.9513, 33.0143],
            [-98.9522, 33.1141],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821360',
        NAME: 'Graham Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1374934471,
        AWATER: 33324791,
        INTPTLAT: '+33.1315313',
        INTPTLON: '-098.6148438',
        ELSDLEA: '',
        UNSDLEA: '21360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4363, 31.5488],
            [-100.2985, 31.625],
            [-100.2557, 31.5345],
            [-100.112, 31.5274],
            [-100.1121, 31.5032],
            [-100.112, 31.4892],
            [-100.2092, 31.4323],
            [-100.3753, 31.4509],
            [-100.4363, 31.5488],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844040',
        NAME: 'Veribest Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321088568,
        AWATER: 0,
        INTPTLAT: '+31.5149808',
        INTPTLON: '-100.2843912',
        ELSDLEA: '',
        UNSDLEA: '44040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8033, 28.0575],
            [-98.4992, 28.0575],
            [-98.493, 27.9467],
            [-98.4532, 27.9217],
            [-98.4681, 27.7478],
            [-98.524, 27.79],
            [-98.6651, 27.7899],
            [-98.7847, 27.6746],
            [-98.8031, 28.0126],
            [-98.8033, 28.0575],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819820',
        NAME: 'Freer Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1047024184,
        AWATER: 2336770,
        INTPTLAT: '+27.8954710',
        INTPTLON: '-098.6381344',
        ELSDLEA: '',
        UNSDLEA: '19820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1999, 34.7477],
            [-101.0907, 34.7482],
            [-101.0885, 34.9579],
            [-101.2035, 34.958],
            [-101.2206, 35.092],
            [-101.1282, 35.136],
            [-101.0869, 35.0801],
            [-100.9743, 35.0801],
            [-100.974, 35.1832],
            [-100.8881, 35.1831],
            [-100.7423, 35.1832],
            [-100.7326, 35.0351],
            [-100.6716, 34.9501],
            [-100.7243, 34.9209],
            [-100.718, 34.7481],
            [-100.9449, 34.7483],
            [-100.9452, 34.6285],
            [-100.9456, 34.4918],
            [-101.1018, 34.4791],
            [-101.1192, 34.6246],
            [-101.2287, 34.6788],
            [-101.1999, 34.7477],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814160',
        NAME: 'Clarendon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2249632099,
        AWATER: 14423313,
        INTPTLAT: '+34.8777228',
        INTPTLON: '-100.9541713',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7423, 35.1832],
            [-100.6627, 35.1831],
            [-100.6631, 35.0966],
            [-100.5923, 35.0824],
            [-100.5396, 34.9962],
            [-100.4336, 35.0085],
            [-100.434, 34.9217],
            [-100.5401, 34.9217],
            [-100.5404, 34.8061],
            [-100.5407, 34.7477],
            [-100.718, 34.7481],
            [-100.7243, 34.9209],
            [-100.6716, 34.9501],
            [-100.7326, 35.0351],
            [-100.7423, 35.1832],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822850',
        NAME: 'Hedley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 791073840,
        AWATER: 1608709,
        INTPTLAT: '+34.9341029',
        INTPTLON: '-100.6121019',
        ELSDLEA: '',
        UNSDLEA: '22850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8861, 35.2564],
            [-100.8846, 35.3333],
            [-100.744, 35.3331],
            [-100.5391, 35.4314],
            [-100.5388, 35.3883],
            [-100.5565, 35.3285],
            [-100.4673, 35.2559],
            [-100.4503, 35.1545],
            [-100.5391, 35.1252],
            [-100.5396, 34.9962],
            [-100.5923, 35.0824],
            [-100.6631, 35.0966],
            [-100.6627, 35.1831],
            [-100.7423, 35.1832],
            [-100.8881, 35.1831],
            [-100.8861, 35.2564],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829880',
        NAME: 'McLean Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 931526873,
        AWATER: 4114893,
        INTPTLAT: '+35.2318203',
        INTPTLON: '-100.6592504',
        ELSDLEA: '',
        UNSDLEA: '29880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3038, 32.3948],
            [-97.2964, 32.4022],
            [-97.2737, 32.4547],
            [-97.2118, 32.5163],
            [-97.2024, 32.524],
            [-97.1342, 32.5036],
            [-97.1782, 32.4829],
            [-97.119, 32.3559],
            [-97.2275, 32.313],
            [-97.2726, 32.3402],
            [-97.3038, 32.3948],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800011',
        NAME: 'Alvarado Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249568425,
        AWATER: 4729997,
        INTPTLAT: '+32.4188786',
        INTPTLON: '-097.2100820',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1124, 29.8894],
            [-96.1025, 29.8097],
            [-96.0327, 29.7279],
            [-96.0552, 29.6829],
            [-96.1175, 29.7053],
            [-96.1932, 29.6496],
            [-96.2697, 29.6891],
            [-96.3309, 29.8144],
            [-96.3787, 29.8236],
            [-96.343, 29.8993],
            [-96.2463, 29.8166],
            [-96.2154, 29.8863],
            [-96.1124, 29.8894],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839630',
        NAME: 'Sealy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525317426,
        AWATER: 10139924,
        INTPTLAT: '+29.7736345',
        INTPTLON: '-096.1898506',
        ELSDLEA: '',
        UNSDLEA: '39630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8929, 27.4753],
            [-97.7906, 27.4917],
            [-97.7767, 27.3548],
            [-97.8932, 27.3756],
            [-97.8929, 27.4753],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836930',
        NAME: 'Ricardo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207532176,
        AWATER: 471928,
        INTPTLAT: '+27.4178410',
        INTPTLON: '-097.8311725',
        ELSDLEA: '',
        UNSDLEA: '36930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.059, 27.4508],
            [-98.0298, 27.5493],
            [-97.893, 27.5501],
            [-97.8929, 27.4753],
            [-97.8932, 27.3756],
            [-97.8575, 27.2434],
            [-97.9859, 27.2093],
            [-98.0581, 27.261],
            [-98.0584, 27.3278],
            [-98.059, 27.4508],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839300',
        NAME: 'Santa Gertrudis Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 509561996,
        AWATER: 1952997,
        INTPTLAT: '+27.3891416',
        INTPTLON: '-097.9755709',
        ELSDLEA: '',
        UNSDLEA: '39300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0119, 27.6357],
            [-97.9421, 27.6359],
            [-97.8407, 27.5584],
            [-97.7906, 27.4917],
            [-97.8929, 27.4753],
            [-97.893, 27.5501],
            [-98.0298, 27.5493],
            [-98.059, 27.4508],
            [-98.0591, 27.4697],
            [-98.0598, 27.6359],
            [-98.0119, 27.6357],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825680',
        NAME: 'Kingsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265754979,
        AWATER: 1608342,
        INTPTLAT: '+27.5571141',
        INTPTLON: '-097.9488510',
        ELSDLEA: '',
        UNSDLEA: '25680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4848, 30.9406],
            [-99.1568, 30.9411],
            [-99.0921, 31.0259],
            [-99.0151, 31.0149],
            [-98.9646, 30.9214],
            [-98.9642, 30.4985],
            [-99.0275, 30.4982],
            [-99.1349, 30.526],
            [-99.3081, 30.4998],
            [-99.4845, 30.5169],
            [-99.5208, 30.6268],
            [-99.5141, 30.7108],
            [-99.5473, 30.8233],
            [-99.4844, 30.8234],
            [-99.4848, 30.9406],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829280',
        NAME: 'Mason Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2566026604,
        AWATER: 8867485,
        INTPTLAT: '+30.7337151',
        INTPTLON: '-099.2291678',
        ELSDLEA: '',
        UNSDLEA: '29280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7277, 31.8438],
            [-94.6102, 31.8441],
            [-94.6345, 31.7431],
            [-94.7493, 31.6985],
            [-94.7277, 31.8438],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813440',
        NAME: 'Central Heights Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180665974,
        AWATER: 818776,
        INTPTLAT: '+31.7652088',
        INTPTLON: '-094.6810752',
        ELSDLEA: '',
        UNSDLEA: '13440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4718, 31.7098],
            [-94.4148, 31.7297],
            [-94.4047, 31.6833],
            [-94.3991, 31.6536],
            [-94.3284, 31.5936],
            [-94.4553, 31.5934],
            [-94.4833, 31.595],
            [-94.5431, 31.6824],
            [-94.4718, 31.7098],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829250',
        NAME: 'Martinsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170675390,
        AWATER: 147965,
        INTPTLAT: '+31.6423340',
        INTPTLON: '-094.4480427',
        ELSDLEA: '',
        UNSDLEA: '29250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7845, 31.6589],
            [-94.7493, 31.6985],
            [-94.6345, 31.7431],
            [-94.4718, 31.7098],
            [-94.5431, 31.6824],
            [-94.4833, 31.595],
            [-94.6647, 31.4595],
            [-94.7257, 31.457],
            [-94.8659, 31.5269],
            [-94.8682, 31.5267],
            [-94.7845, 31.6589],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832090',
        NAME: 'Nacogdoches Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 576432563,
        AWATER: 1579580,
        INTPTLAT: '+31.5971946',
        INTPTLON: '-094.6731668',
        ELSDLEA: '',
        UNSDLEA: '32090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4801, 32.5925],
            [-97.4532, 32.6466],
            [-97.3278, 32.6588],
            [-97.3172, 32.6067],
            [-97.3808, 32.5351],
            [-97.4713, 32.555],
            [-97.4801, 32.5925],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815910',
        NAME: 'Crowley Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144815896,
        AWATER: 5372845,
        INTPTLAT: '+32.5967615',
        INTPTLON: '-097.4023790',
        ELSDLEA: '',
        UNSDLEA: '15910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6993, 28.7778],
            [-96.6434, 28.7121],
            [-96.8538, 28.6352],
            [-96.9304, 28.5867],
            [-96.9473, 28.6081],
            [-96.9627, 28.6531],
            [-96.8461, 28.7523],
            [-96.8024, 28.7068],
            [-96.6993, 28.7778],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810500',
        NAME: 'Bloomington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270971669,
        AWATER: 3609553,
        INTPTLAT: '+28.6889934',
        INTPTLON: '-096.8059744',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1606, 28.9709],
            [-97.1232, 28.8845],
            [-97.028, 28.9775],
            [-97.0799, 29.0301],
            [-97.049, 29.0528],
            [-96.9764, 29.104],
            [-96.9386, 29.0632],
            [-96.8388, 29.0253],
            [-96.8996, 28.9597],
            [-96.8521, 28.8725],
            [-96.7515, 28.8483],
            [-96.6993, 28.7778],
            [-96.8024, 28.7068],
            [-96.8461, 28.7523],
            [-96.9627, 28.6531],
            [-96.9473, 28.6081],
            [-97.0232, 28.5368],
            [-97.0777, 28.5336],
            [-97.1608, 28.5535],
            [-97.1824, 28.6106],
            [-97.1589, 28.7762],
            [-97.2362, 28.8649],
            [-97.2506, 28.9051],
            [-97.1606, 28.9709],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844150',
        NAME: 'Victoria Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1556842748,
        AWATER: 12095023,
        INTPTLAT: '+28.8118075',
        INTPTLON: '-096.9982032',
        ELSDLEA: '',
        UNSDLEA: '44150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2812, 32.2962],
            [-97.2726, 32.3402],
            [-97.2275, 32.313],
            [-97.119, 32.3559],
            [-97.0866, 32.3611],
            [-97.0862, 32.2655],
            [-97.1822, 32.2458],
            [-97.2195, 32.1956],
            [-97.2744, 32.2259],
            [-97.2792, 32.2249],
            [-97.2812, 32.2962],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821540',
        NAME: 'Grandview Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200119268,
        AWATER: 3205544,
        INTPTLAT: '+32.2844803',
        INTPTLON: '-097.1851252',
        ELSDLEA: '',
        UNSDLEA: '21540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2964, 32.4022],
            [-97.3038, 32.3948],
            [-97.3515, 32.3919],
            [-97.2964, 32.4022],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825230',
        NAME: 'Keene Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12764547,
        AWATER: 206340,
        INTPTLAT: '+32.3993069',
        INTPTLON: '-097.3222885',
        ELSDLEA: '',
        UNSDLEA: '25230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2812, 32.2962],
            [-97.2792, 32.2249],
            [-97.4761, 32.1735],
            [-97.512, 32.1612],
            [-97.5363, 32.2307],
            [-97.4672, 32.3003],
            [-97.3279, 32.2574],
            [-97.2812, 32.2962],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837200',
        NAME: 'Rio Vista Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200377933,
        AWATER: 1520133,
        INTPTLAT: '+32.2433030',
        INTPTLON: '-097.4258167',
        ELSDLEA: '',
        UNSDLEA: '37200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1737, 32.6863],
            [-95.0584, 32.6675],
            [-95.0414, 32.6024],
            [-95.1313, 32.549],
            [-95.1754, 32.5555],
            [-95.1737, 32.6863],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810140',
        NAME: 'Big Sandy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 218850222,
        AWATER: 2885694,
        INTPTLAT: '+32.6074044',
        INTPTLON: '-095.1247251',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1601, 32.8982],
            [-94.9914, 32.9026],
            [-94.9897, 32.7772],
            [-95.0438, 32.8446],
            [-95.169, 32.8225],
            [-95.1601, 32.8982],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843560',
        NAME: 'Union Hill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170799878,
        AWATER: 1406655,
        INTPTLAT: '+32.8470082',
        INTPTLON: '-095.0555794',
        ELSDLEA: '',
        UNSDLEA: '43560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0436, 34.0112],
            [-103.0436, 34.0781],
            [-102.8826, 34.0765],
            [-102.8835, 33.9513],
            [-102.7387, 33.9507],
            [-102.7373, 34.0637],
            [-102.6578, 34.0628],
            [-102.6154, 34.2123],
            [-102.5365, 34.2117],
            [-102.3736, 34.1778],
            [-102.3675, 34.1732],
            [-102.4602, 34.1036],
            [-102.4629, 33.9872],
            [-102.4637, 33.9104],
            [-102.6155, 33.8733],
            [-102.6416, 33.825],
            [-102.8643, 33.8248],
            [-103.0479, 33.7962],
            [-103.0436, 34.0112],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841760',
        NAME: 'Sudan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1511737359,
        AWATER: 2929294,
        INTPTLAT: '+33.9786296',
        INTPTLON: '-102.7290353',
        ELSDLEA: '',
        UNSDLEA: '41760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1354, 30.7676],
            [-93.9578, 30.7669],
            [-93.87, 30.8082],
            [-93.8741, 30.697],
            [-93.8205, 30.5725],
            [-93.883, 30.5397],
            [-94.0873, 30.5543],
            [-94.0833, 30.5936],
            [-94.1282, 30.7465],
            [-94.1354, 30.7676],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825710',
        NAME: 'Kirbyville Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 634737294,
        AWATER: 3086652,
        INTPTLAT: '+30.6763666',
        INTPTLON: '-093.9831202',
        ELSDLEA: '',
        UNSDLEA: '25710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.883, 30.5397],
            [-93.8205, 30.5725],
            [-93.8741, 30.697],
            [-93.87, 30.8082],
            [-93.8672, 30.8974],
            [-93.7627, 30.906],
            [-93.7593, 30.9625],
            [-93.5547, 30.9092],
            [-93.5586, 30.8688],
            [-93.6313, 30.6779],
            [-93.7388, 30.541],
            [-93.7147, 30.4916],
            [-93.8856, 30.495],
            [-93.883, 30.5397],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832730',
        NAME: 'Newton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1016636744,
        AWATER: 4301992,
        INTPTLAT: '+30.7511006',
        INTPTLON: '-093.7365264',
        ELSDLEA: '',
        UNSDLEA: '32730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0339, 31.2331],
            [-93.8952, 31.2409],
            [-93.7966, 31.1647],
            [-93.8871, 31.0466],
            [-94.0655, 31.112],
            [-94.1337, 31.1573],
            [-94.1151, 31.2407],
            [-94.0339, 31.2331],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811520',
        NAME: 'Brookeland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325042332,
        AWATER: 67419853,
        INTPTLAT: '+31.1404539',
        INTPTLON: '-093.9584608',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7966, 31.1647],
            [-93.6003, 31.1762],
            [-93.5525, 31.1854],
            [-93.5247, 31.039],
            [-93.5654, 30.9906],
            [-93.5547, 30.9092],
            [-93.7593, 30.9625],
            [-93.7627, 30.906],
            [-93.8672, 30.8974],
            [-93.8871, 31.0466],
            [-93.7966, 31.1647],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812150',
        NAME: 'Burkeville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 841300063,
        AWATER: 10042623,
        INTPTLAT: '+31.0476580',
        INTPTLON: '-093.7064004',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9622, 31.0825],
            [-100.6888, 31.0866],
            [-100.2795, 31.0884],
            [-100.1152, 31.088],
            [-100.1162, 30.7104],
            [-100.9606, 30.7061],
            [-100.9622, 31.0825],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839510',
        NAME: 'Schleicher Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3394564110,
        AWATER: 87401,
        INTPTLAT: '+30.8962331',
        INTPTLON: '-100.5272161',
        ELSDLEA: '',
        UNSDLEA: '39510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.468, 32.9638],
            [-102.2611, 32.9638],
            [-102.2084, 32.9441],
            [-102.2059, 32.8188],
            [-102.2056, 32.7593],
            [-102.4509, 32.7543],
            [-102.4508, 32.7989],
            [-102.4854, 32.8569],
            [-102.468, 32.9638],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828140',
        NAME: 'Loop Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556603126,
        AWATER: 656,
        INTPTLAT: '+32.8819847',
        INTPTLON: '-102.3449559',
        ELSDLEA: '',
        UNSDLEA: '28140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5176, 33.1234],
            [-102.2051, 33.1089],
            [-102.0868, 33.1526],
            [-102.0756, 33.1086],
            [-102.1107, 33.0224],
            [-102.1988, 33.0205],
            [-102.2084, 32.9441],
            [-102.2611, 32.9638],
            [-102.468, 32.9638],
            [-102.5262, 32.9637],
            [-102.5176, 33.1234],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844910',
        NAME: 'Wellman-Union Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 681409063,
        AWATER: 914748,
        INTPTLAT: '+33.0507072',
        INTPTLON: '-102.3151292',
        ELSDLEA: '',
        UNSDLEA: '44910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2047, 33.0266],
            [-98.1894, 33.1339],
            [-98.0875, 33.1221],
            [-97.9866, 33.0598],
            [-97.92, 33.1439],
            [-97.9209, 33.0549],
            [-97.9532, 32.9415],
            [-97.9834, 32.9416],
            [-98.0589, 32.8708],
            [-98.113, 32.9457],
            [-98.2047, 33.0266],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834630',
        NAME: 'Perrin-Whitt Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 447134343,
        AWATER: 3660546,
        INTPTLAT: '+33.0306666',
        INTPTLON: '-098.0540155',
        ELSDLEA: '',
        UNSDLEA: '34630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.522, 27.5408],
            [-99.463, 27.5305],
            [-99.4853, 27.4645],
            [-99.522, 27.5408],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826790',
        NAME: 'Laredo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34220165,
        AWATER: 560369,
        INTPTLAT: '+27.5097708',
        INTPTLON: '-099.4902396',
        ELSDLEA: '',
        UNSDLEA: '26790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4601, 33.3884],
            [-102.4481, 33.3885],
            [-102.351, 33.3887],
            [-102.1539, 33.3891],
            [-102.0759, 33.3452],
            [-102.0759, 33.2531],
            [-102.1021, 33.2539],
            [-102.2403, 33.2972],
            [-102.4632, 33.2835],
            [-102.4601, 33.3884],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830000',
        NAME: 'Meadow Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403818673,
        AWATER: 660460,
        INTPTLAT: '+33.3358437',
        INTPTLON: '-102.2584612',
        ELSDLEA: '',
        UNSDLEA: '30000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3625, 29.3997],
            [-96.3077, 29.5145],
            [-96.1754, 29.6338],
            [-96.0625, 29.5916],
            [-96.0224, 29.5019],
            [-96.0175, 29.4374],
            [-96.0707, 29.4049],
            [-96.1537, 29.4173],
            [-96.2542, 29.4575],
            [-96.2897, 29.3694],
            [-96.3625, 29.3997],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817830',
        NAME: 'East Bernard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464677449,
        AWATER: 3350649,
        INTPTLAT: '+29.5116454',
        INTPTLON: '-096.1741682',
        ELSDLEA: '',
        UNSDLEA: '17830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6242, 26.6181],
            [-98.5907, 26.5601],
            [-98.4289, 26.5652],
            [-98.4919, 26.4454],
            [-98.5867, 26.2575],
            [-98.6646, 26.2354],
            [-98.8205, 26.371],
            [-98.9012, 26.3705],
            [-98.8755, 26.5089],
            [-98.8144, 26.5431],
            [-98.7714, 26.6292],
            [-98.6242, 26.6181],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837140',
        NAME: 'Rio Grande City Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1243468036,
        AWATER: 5649201,
        INTPTLAT: '+26.4474230',
        INTPTLON: '-098.6835994',
        ELSDLEA: '',
        UNSDLEA: '37140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6429, 26.7841],
            [-98.4226, 26.7835],
            [-98.3207, 26.7831],
            [-98.4289, 26.5652],
            [-98.5907, 26.5601],
            [-98.6242, 26.6181],
            [-98.6429, 26.7841],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838910',
        NAME: 'San Isidro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 603061704,
        AWATER: 26592,
        INTPTLAT: '+26.6837172',
        INTPTLON: '-098.5048944',
        ELSDLEA: '',
        UNSDLEA: '38910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1406, 32.4795],
            [-101.0344, 32.5279],
            [-100.9371, 32.5269],
            [-100.9271, 32.5268],
            [-100.9223, 32.5267],
            [-100.8176, 32.5508],
            [-100.7834, 32.5254],
            [-100.7843, 32.3137],
            [-100.757, 32.161],
            [-100.6641, 32.1584],
            [-100.6654, 32.0854],
            [-100.8216, 32.0866],
            [-101.139, 32.087],
            [-101.0652, 32.1788],
            [-100.8896, 32.1385],
            [-100.8526, 32.1864],
            [-100.9026, 32.2622],
            [-100.9513, 32.4178],
            [-101.1406, 32.4795],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814640',
        NAME: 'Colorado Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1115091916,
        AWATER: 10069470,
        INTPTLAT: '+32.3018298',
        INTPTLON: '-100.8797778',
        ELSDLEA: '',
        UNSDLEA: '14640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1734, 32.9636],
            [-101.0387, 32.9702],
            [-101.0386, 33.0559],
            [-100.9233, 33.0388],
            [-100.8849, 32.9646],
            [-100.6559, 32.9635],
            [-100.6591, 32.7338],
            [-100.659, 32.709],
            [-100.7437, 32.7921],
            [-100.8158, 32.7617],
            [-100.8158, 32.6893],
            [-100.8176, 32.5508],
            [-100.9223, 32.5267],
            [-100.9289, 32.5579],
            [-100.9376, 32.6704],
            [-101.0769, 32.6712],
            [-101.1051, 32.5839],
            [-101.1746, 32.5115],
            [-101.1746, 32.5241],
            [-101.1734, 32.9636],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840650',
        NAME: 'Snyder Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1809541937,
        AWATER: 4022520,
        INTPTLAT: '+32.8172469',
        INTPTLON: '-100.9414048',
        ELSDLEA: '',
        UNSDLEA: '40650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7522, 31.9447],
            [-99.6879, 31.9173],
            [-99.5014, 31.9039],
            [-99.4107, 31.7316],
            [-99.4562, 31.5136],
            [-99.4541, 31.4396],
            [-99.5115, 31.4244],
            [-99.5358, 31.4824],
            [-99.6462, 31.4913],
            [-99.7217, 31.5768],
            [-99.7195, 31.6972],
            [-99.8096, 31.6943],
            [-99.779, 31.839],
            [-99.8239, 31.8887],
            [-99.7522, 31.9447],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800007',
        NAME: 'Panther Creek Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1398936327,
        AWATER: 34208456,
        INTPTLAT: '+31.7113034',
        INTPTLON: '-099.5910686',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1607, 31.9195],
            [-100.0682, 31.8717],
            [-99.8239, 31.8887],
            [-99.779, 31.839],
            [-99.8096, 31.6943],
            [-99.7195, 31.6972],
            [-99.7217, 31.5768],
            [-99.9357, 31.5781],
            [-99.9418, 31.6667],
            [-100.0068, 31.6667],
            [-100.0074, 31.5789],
            [-100.0419, 31.5794],
            [-100.1186, 31.6989],
            [-100.1849, 31.7927],
            [-100.1607, 31.9195],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809300',
        NAME: 'Ballinger Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1066959047,
        AWATER: 11609058,
        INTPTLAT: '+31.7427418',
        INTPTLON: '-099.9518988',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0565, 32.1637],
            [-100.0563, 32.2372],
            [-99.9772, 32.2757],
            [-99.8488, 32.3198],
            [-99.794, 32.2774],
            [-99.6739, 32.2701],
            [-99.6307, 32.2281],
            [-99.6314, 32.0813],
            [-99.714, 32.0821],
            [-99.7518, 31.9893],
            [-99.8512, 32.0234],
            [-99.8627, 32.0815],
            [-100.0558, 32.1057],
            [-100.0565, 32.1637],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824780',
        NAME: 'Jim Ned Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 937478246,
        AWATER: 4148490,
        INTPTLAT: '+32.1715232',
        INTPTLON: '-099.8333203',
        ELSDLEA: '',
        UNSDLEA: '24780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1128, 28.8542],
            [-99.8841, 28.8826],
            [-99.8911, 28.7854],
            [-99.7872, 28.8168],
            [-99.7703, 28.872],
            [-99.4488, 28.879],
            [-99.4123, 28.9206],
            [-99.412, 28.8833],
            [-99.409, 28.6406],
            [-100.1143, 28.6481],
            [-100.1128, 28.8542],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815930',
        NAME: 'Crystal City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1663694395,
        AWATER: 6241771,
        INTPTLAT: '+28.7413122',
        INTPTLON: '-099.7458087',
        ELSDLEA: '',
        UNSDLEA: '15930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0777, 28.5336],
            [-97.0232, 28.5368],
            [-96.891, 28.5072],
            [-96.7786, 28.4043],
            [-96.7905, 28.3192],
            [-97.0834, 28.2782],
            [-97.1267, 28.371],
            [-97.0777, 28.5336],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809000',
        NAME: 'Austwell-Tivoli Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644792963,
        AWATER: 38362964,
        INTPTLAT: '+28.4028412',
        INTPTLON: '-096.9701647',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9845, 31.5694],
            [-93.9569, 31.5277],
            [-93.8349, 31.5862],
            [-93.7495, 31.4687],
            [-93.6926, 31.4372],
            [-93.6875, 31.3108],
            [-93.6139, 31.2594],
            [-93.6003, 31.1762],
            [-93.7966, 31.1647],
            [-93.8952, 31.2409],
            [-93.9331, 31.3883],
            [-94.0161, 31.4344],
            [-93.9845, 31.5694],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822890',
        NAME: 'Hemphill Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 855601124,
        AWATER: 198015167,
        INTPTLAT: '+31.3765562',
        INTPTLON: '-093.8024324',
        ELSDLEA: '',
        UNSDLEA: '22890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.77, 29.7034],
            [-97.6844, 29.7245],
            [-97.7111, 29.801],
            [-97.627, 29.7603],
            [-97.5179, 29.7606],
            [-97.5267, 29.7043],
            [-97.6839, 29.5828],
            [-97.7739, 29.6444],
            [-97.77, 29.7034],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828590',
        NAME: 'Luling Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297663136,
        AWATER: 1353532,
        INTPTLAT: '+29.7009051',
        INTPTLON: '-097.6486496',
        ELSDLEA: '',
        UNSDLEA: '28590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1537, 29.4173],
            [-96.0707, 29.4049],
            [-96.0435, 29.2907],
            [-95.9562, 29.2158],
            [-95.964, 29.1479],
            [-96.0386, 29.1066],
            [-96.1551, 29.1609],
            [-96.1711, 29.3424],
            [-96.1537, 29.4173],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845380',
        NAME: 'Wharton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 471132632,
        AWATER: 4106953,
        INTPTLAT: '+29.2574426',
        INTPTLON: '-096.0789073',
        ELSDLEA: '',
        UNSDLEA: '45380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0707, 29.4049],
            [-96.0175, 29.4374],
            [-95.9424, 29.3319],
            [-95.8477, 29.2626],
            [-95.8419, 29.2587],
            [-95.8417, 29.1735],
            [-95.9363, 29.0883],
            [-95.964, 29.1479],
            [-95.9562, 29.2158],
            [-96.0435, 29.2907],
            [-96.0707, 29.4049],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810780',
        NAME: 'Boling Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371954466,
        AWATER: 5474562,
        INTPTLAT: '+29.2742587',
        INTPTLON: '-095.9548694',
        ELSDLEA: '',
        UNSDLEA: '10780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.581, 29.2788],
            [-96.3625, 29.3997],
            [-96.2897, 29.3694],
            [-96.2542, 29.4575],
            [-96.1537, 29.4173],
            [-96.1711, 29.3424],
            [-96.1551, 29.1609],
            [-96.0386, 29.1066],
            [-96.24, 28.9997],
            [-96.2592, 29.1146],
            [-96.3165, 29.2246],
            [-96.5412, 29.1632],
            [-96.5464, 29.1677],
            [-96.6338, 29.242],
            [-96.581, 29.2788],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818280',
        NAME: 'El Campo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1139065204,
        AWATER: 7623243,
        INTPTLAT: '+29.2480396',
        INTPTLON: '-096.2965394',
        ELSDLEA: '',
        UNSDLEA: '18280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2541, 32.6436],
            [-100.1458, 32.65],
            [-100.0778, 32.5656],
            [-100.0611, 32.4362],
            [-100.149, 32.3654],
            [-100.2281, 32.404],
            [-100.2513, 32.4893],
            [-100.1493, 32.5228],
            [-100.1711, 32.5925],
            [-100.2541, 32.6436],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843110',
        NAME: 'Trent Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 367887993,
        AWATER: 753610,
        INTPTLAT: '+32.5172810',
        INTPTLON: '-100.1544523',
        ELSDLEA: '',
        UNSDLEA: '43110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4073, 31.35],
            [-98.2192, 31.3771],
            [-98.2501, 31.2634],
            [-98.2241, 31.2002],
            [-98.3716, 31.146],
            [-98.5291, 31.1537],
            [-98.5798, 31.2654],
            [-98.5326, 31.328],
            [-98.4073, 31.35],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827960',
        NAME: 'Lometa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 639569159,
        AWATER: 904217,
        INTPTLAT: '+31.2721225',
        INTPTLON: '-098.3653382',
        ELSDLEA: '',
        UNSDLEA: '27960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9429, 31.5121],
            [-98.8227, 31.5849],
            [-98.6931, 31.7371],
            [-98.6288, 31.7089],
            [-98.6291, 31.7086],
            [-98.5675, 31.5697],
            [-98.8061, 31.4065],
            [-98.8345, 31.4104],
            [-98.9427, 31.4586],
            [-98.9429, 31.5121],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831920',
        NAME: 'Mullin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 633179067,
        AWATER: 1833546,
        INTPTLAT: '+31.5584146',
        INTPTLON: '-098.7488559',
        ELSDLEA: '',
        UNSDLEA: '31920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8227, 31.7461],
            [-98.7017, 31.75],
            [-98.6931, 31.7371],
            [-98.8227, 31.5849],
            [-98.8773, 31.6423],
            [-98.8227, 31.7461],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846770',
        NAME: 'Zephyr Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192766048,
        AWATER: 393472,
        INTPTLAT: '+31.6866772',
        INTPTLON: '-098.7762773',
        ELSDLEA: '',
        UNSDLEA: '46770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4673, 35.2559],
            [-100.4503, 35.3856],
            [-100.3237, 35.3427],
            [-100.1831, 35.3428],
            [-100.1476, 35.2994],
            [-100.0004, 35.299],
            [-100.0004, 35.2987],
            [-100.0004, 35.1393],
            [-100.186, 35.1828],
            [-100.3994, 35.1829],
            [-100.4503, 35.1545],
            [-100.4673, 35.2559],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839900',
        NAME: 'Shamrock Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 813608394,
        AWATER: 795104,
        INTPTLAT: '+35.2554427',
        INTPTLON: '-100.2597261',
        ELSDLEA: '',
        UNSDLEA: '39900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4941, 33.5505],
            [-94.4577, 33.5997],
            [-94.3554, 33.5433],
            [-94.3253, 33.4463],
            [-94.3255, 33.425],
            [-94.3898, 33.4119],
            [-94.4027, 33.2698],
            [-94.4304, 33.2838],
            [-94.4142, 33.3736],
            [-94.5053, 33.457],
            [-94.4941, 33.5505],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832340',
        NAME: 'New Boston Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271377671,
        AWATER: 5768859,
        INTPTLAT: '+33.4633256',
        INTPTLON: '-094.4168107',
        ELSDLEA: '',
        UNSDLEA: '32340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7461, 33.703],
            [-94.5641, 33.6263],
            [-94.4859, 33.6379],
            [-94.4577, 33.5997],
            [-94.4941, 33.5505],
            [-94.5491, 33.4633],
            [-94.6099, 33.4679],
            [-94.6825, 33.487],
            [-94.6578, 33.4071],
            [-94.6156, 33.2483],
            [-94.7469, 33.3289],
            [-94.7461, 33.703],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816570',
        NAME: 'De Kalb Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666653156,
        AWATER: 9757043,
        INTPTLAT: '+33.5272069',
        INTPTLON: '-094.6422434',
        ELSDLEA: '',
        UNSDLEA: '16570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1636, 33.4125],
            [-94.0431, 33.4054],
            [-94.043, 33.2712],
            [-94.0704, 33.3007],
            [-94.2101, 33.297],
            [-94.1636, 33.4125],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827390',
        NAME: 'Liberty-Eylau Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167155497,
        AWATER: 18953467,
        INTPTLAT: '+33.3518954',
        INTPTLON: '-094.1171850',
        ELSDLEA: '',
        UNSDLEA: '27390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2159, 33.4973],
            [-94.1939, 33.4943],
            [-94.2034, 33.4593],
            [-94.2207, 33.453],
            [-94.2159, 33.4973],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4827060',
        NAME: 'Leary Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25219938,
        AWATER: 201089,
        INTPTLAT: '+33.4771479',
        INTPTLON: '-094.2279055',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5263, 34.313],
            [-102.4805, 34.3983],
            [-102.2606, 34.3403],
            [-102.235, 34.3132],
            [-102.2412, 34.2016],
            [-102.3736, 34.1778],
            [-102.5365, 34.2117],
            [-102.5263, 34.313],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841250',
        NAME: 'Springlake-Earth Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 509284692,
        AWATER: 685552,
        INTPTLAT: '+34.2872154',
        INTPTLON: '-102.3950002',
        ELSDLEA: '',
        UNSDLEA: '41250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.226, 33.8247],
            [-102.2255, 33.8669],
            [-102.0861, 33.8656],
            [-102.0848, 33.7807],
            [-102.0833, 33.7184],
            [-102.2262, 33.7195],
            [-102.226, 33.8247],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808460',
        NAME: 'Anton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213666870,
        AWATER: 0,
        INTPTLAT: '+33.7922835',
        INTPTLON: '-102.1555137',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1186, 30.3937],
            [-95.0481, 30.3969],
            [-95.0384, 30.2635],
            [-94.9918, 30.1704],
            [-95.0726, 30.1031],
            [-95.0967, 30.1672],
            [-95.1056, 30.1906],
            [-95.1539, 30.3141],
            [-95.2255, 30.3219],
            [-95.2351, 30.3776],
            [-95.2615, 30.4463],
            [-95.1186, 30.3937],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814370',
        NAME: 'Cleveland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372007314,
        AWATER: 872944,
        INTPTLAT: '+30.2834317',
        INTPTLON: '-095.0981084',
        ELSDLEA: '',
        UNSDLEA: '14370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6112, 30.5155],
            [-95.5989, 30.509],
            [-95.5687, 30.3639],
            [-95.5448, 30.2464],
            [-95.6691, 30.2498],
            [-95.7326, 30.3074],
            [-95.8044, 30.3123],
            [-95.8084, 30.3885],
            [-95.8124, 30.4367],
            [-95.82, 30.5255],
            [-95.6992, 30.5617],
            [-95.6112, 30.5155],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831260',
        NAME: 'Montgomery Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 558151362,
        AWATER: 43818939,
        INTPTLAT: '+30.4049178',
        INTPTLON: '-095.6943270',
        ELSDLEA: '',
        UNSDLEA: '31260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4289, 26.5652],
            [-98.3207, 26.7831],
            [-97.9855, 26.7809],
            [-97.9574, 26.6118],
            [-97.9644, 26.5688],
            [-98.0001, 26.4482],
            [-98.0125, 26.3324],
            [-98.0124, 26.264],
            [-98.0244, 26.2638],
            [-98.1018, 26.2375],
            [-98.2003, 26.2511],
            [-98.2382, 26.2914],
            [-98.2826, 26.3265],
            [-98.3253, 26.362],
            [-98.323, 26.4176],
            [-98.4919, 26.4454],
            [-98.4289, 26.5652],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818180',
        NAME: 'Edinburg Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2152281928,
        AWATER: 2437571,
        INTPTLAT: '+26.5591580',
        INTPTLON: '-098.1941613',
        ELSDLEA: '',
        UNSDLEA: '18180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4919, 26.4454],
            [-98.323, 26.4176],
            [-98.3253, 26.362],
            [-98.358, 26.1697],
            [-98.4627, 26.2258],
            [-98.5867, 26.2575],
            [-98.4919, 26.4454],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826130',
        NAME: 'La Joya Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 536426254,
        AWATER: 5434672,
        INTPTLAT: '+26.3084258',
        INTPTLON: '-098.4374632',
        ELSDLEA: '',
        UNSDLEA: '26130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.358, 26.1697],
            [-98.3253, 26.362],
            [-98.2826, 26.3265],
            [-98.3117, 26.1543],
            [-98.3181, 26.1167],
            [-98.358, 26.1697],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831040',
        NAME: 'Mission Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107711444,
        AWATER: 765374,
        INTPTLAT: '+26.2456905',
        INTPTLON: '-098.3206770',
        ELSDLEA: '',
        UNSDLEA: '31040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0217, 26.1055],
            [-97.9252, 26.1269],
            [-97.9197, 26.058],
            [-98.0083, 26.0611],
            [-98.0217, 26.1055],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835910',
        NAME: 'Progreso Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56026764,
        AWATER: 1566307,
        INTPTLAT: '+26.0895619',
        INTPTLON: '-097.9646454',
        ELSDLEA: '',
        UNSDLEA: '35910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1769, 33.4513],
            [-94.1407, 33.4705],
            [-94.0433, 33.5283],
            [-94.0431, 33.4054],
            [-94.1636, 33.4125],
            [-94.1769, 33.4513],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842480',
        NAME: 'Texarkana Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76831371,
        AWATER: 502393,
        INTPTLAT: '+33.4515769',
        INTPTLON: '-094.0892358',
        ELSDLEA: '',
        UNSDLEA: '42480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.12, 30.961],
            [-97.0864, 30.8916],
            [-97.1314, 30.8091],
            [-97.1892, 30.8458],
            [-97.1802, 30.9305],
            [-97.12, 30.961],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811850',
        NAME: 'Buckholts Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119646797,
        AWATER: 420748,
        INTPTLAT: '+30.8803068',
        INTPTLON: '-097.1319323',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1314, 30.8091],
            [-97.0864, 30.8916],
            [-97.12, 30.961],
            [-97.0751, 30.9837],
            [-96.9167, 31.0158],
            [-96.7737, 31.0163],
            [-96.7563, 30.9272],
            [-96.6814, 30.8817],
            [-96.6792, 30.844],
            [-96.7577, 30.8251],
            [-96.866, 30.8335],
            [-96.9471, 30.7539],
            [-97.1314, 30.8091],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812640',
        NAME: 'Cameron Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 816535237,
        AWATER: 2857473,
        INTPTLAT: '+30.8911291',
        INTPTLON: '-096.9076015',
        ELSDLEA: '',
        UNSDLEA: '12640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7577, 30.8251],
            [-96.6792, 30.844],
            [-96.6443, 30.7788],
            [-96.6213, 30.7308],
            [-96.7359, 30.6724],
            [-96.7839, 30.7951],
            [-96.7577, 30.8251],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820520',
        NAME: 'Gause Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197797623,
        AWATER: 1488552,
        INTPTLAT: '+30.7464209',
        INTPTLON: '-096.7062758',
        ELSDLEA: '',
        UNSDLEA: '20520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9471, 30.7539],
            [-96.866, 30.8335],
            [-96.7577, 30.8251],
            [-96.7839, 30.7951],
            [-96.7359, 30.6724],
            [-96.8729, 30.6034],
            [-96.9497, 30.6427],
            [-96.9471, 30.7539],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830690',
        NAME: 'Milano Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350416161,
        AWATER: 790434,
        INTPTLAT: '+30.7523826',
        INTPTLON: '-096.8260270',
        ELSDLEA: '',
        UNSDLEA: '30690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2488, 30.8139],
            [-97.1892, 30.8458],
            [-97.1314, 30.8091],
            [-96.9471, 30.7539],
            [-96.9497, 30.6427],
            [-96.8729, 30.6034],
            [-96.9636, 30.5572],
            [-97.0841, 30.4945],
            [-97.1244, 30.6531],
            [-97.0781, 30.6948],
            [-97.2681, 30.7405],
            [-97.2488, 30.8139],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837590',
        NAME: 'Rockdale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 583243026,
        AWATER: 5614386,
        INTPTLAT: '+30.6788025',
        INTPTLON: '-097.0580855',
        ELSDLEA: '',
        UNSDLEA: '37590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4689, 32.9423],
            [-95.4271, 32.9619],
            [-95.3667, 32.9075],
            [-95.2697, 32.7607],
            [-95.3327, 32.7113],
            [-95.4891, 32.7688],
            [-95.5494, 32.809],
            [-95.4745, 32.8746],
            [-95.4689, 32.9423],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836300',
        NAME: 'Quitman Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370504550,
        AWATER: 23785743,
        INTPTLAT: '+32.8172866',
        INTPTLON: '-095.4114451',
        ELSDLEA: '',
        UNSDLEA: '36300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.626, 34.9718],
            [-101.6229, 35.1831],
            [-101.3115, 35.1821],
            [-101.1823, 35.1817],
            [-101.1282, 35.136],
            [-101.2206, 35.092],
            [-101.2035, 34.958],
            [-101.0885, 34.9579],
            [-101.0907, 34.7482],
            [-101.1999, 34.7477],
            [-101.4716, 34.7475],
            [-101.6293, 34.7476],
            [-101.626, 34.9718],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814250',
        NAME: 'Claude Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2121746166,
        AWATER: 10711463,
        INTPTLAT: '+34.9511749',
        INTPTLON: '-101.3773314',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0818, 33.6503],
            [-101.9399, 33.6286],
            [-101.9397, 33.5052],
            [-101.9814, 33.3906],
            [-102.0759, 33.3896],
            [-102.079, 33.5194],
            [-102.0818, 33.6503],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819830',
        NAME: 'Frenship Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314356169,
        AWATER: 1781880,
        INTPTLAT: '+33.5262146',
        INTPTLON: '-102.0184431',
        ELSDLEA: '',
        UNSDLEA: '19830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9366, 31.8463],
            [-94.8003, 31.844],
            [-94.7277, 31.8438],
            [-94.7493, 31.6985],
            [-94.7845, 31.6589],
            [-94.9508, 31.6792],
            [-94.9683, 31.7483],
            [-94.9366, 31.8463],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816080',
        NAME: 'Cushing Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390724385,
        AWATER: 463626,
        INTPTLAT: '+31.7792489',
        INTPTLON: '-094.8478912',
        ELSDLEA: '',
        UNSDLEA: '16080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7192, 31.9541],
            [-94.7755, 31.9261],
            [-94.8003, 31.844],
            [-94.9366, 31.8463],
            [-94.9436, 31.8974],
            [-94.9312, 32],
            [-94.8025, 32.0728],
            [-94.7192, 31.9541],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826700',
        NAME: 'Laneville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325844047,
        AWATER: 604913,
        INTPTLAT: '+31.9501979',
        INTPTLON: '-094.8452215',
        ELSDLEA: '',
        UNSDLEA: '26700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9854, 32.2584],
            [-94.9428, 32.308],
            [-94.8968, 32.3042],
            [-94.8185, 32.2824],
            [-94.8474, 32.2349],
            [-94.8364, 32.1231],
            [-94.8927, 32.0988],
            [-94.9853, 32.1579],
            [-94.9854, 32.1984],
            [-94.9854, 32.2584],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845150',
        NAME: 'West Rusk County Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254722368,
        AWATER: 454612,
        INTPTLAT: '+32.1972335',
        INTPTLON: '-094.9052891',
        ELSDLEA: '',
        UNSDLEA: '45150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3908, 31.0416],
            [-97.2886, 31.0525],
            [-97.2471, 30.9948],
            [-97.3227, 30.9059],
            [-97.401, 30.9798],
            [-97.3908, 31.0416],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807470',
        NAME: 'Academy Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148238176,
        AWATER: 1345277,
        INTPTLAT: '+30.9689896',
        INTPTLON: '-097.3293621',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8285, 30.9062],
            [-97.7758, 30.975],
            [-97.69, 30.9922],
            [-97.6813, 30.8815],
            [-97.7387, 30.7554],
            [-97.8206, 30.7311],
            [-97.9401, 30.7668],
            [-97.8285, 30.9062],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819320',
        NAME: 'Florence Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369373489,
        AWATER: 1802915,
        INTPTLAT: '+30.8504800',
        INTPTLON: '-097.7768905',
        ELSDLEA: '',
        UNSDLEA: '19320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2886, 31.0525],
            [-97.2712, 31.1105],
            [-97.1955, 31.0946],
            [-97.1356, 31.0791],
            [-97.0751, 30.9837],
            [-97.12, 30.961],
            [-97.1802, 30.9305],
            [-97.1892, 30.8458],
            [-97.2488, 30.8139],
            [-97.255, 30.8132],
            [-97.3227, 30.9059],
            [-97.2471, 30.9948],
            [-97.2886, 31.0525],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4837710',
        NAME: 'Rogers Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361718988,
        AWATER: 3482698,
        INTPTLAT: '+30.9669786',
        INTPTLON: '-097.2112748',
        ELSDLEA: '',
        UNSDLEA: '37710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6813, 30.8815],
            [-97.5507, 30.8421],
            [-97.5448, 30.7849],
            [-97.5664, 30.7722],
            [-97.6855, 30.7098],
            [-97.7387, 30.7554],
            [-97.6813, 30.8815],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824600',
        NAME: 'Jarrell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211834720,
        AWATER: 662151,
        INTPTLAT: '+30.7923920',
        INTPTLON: '-097.6454346',
        ELSDLEA: '',
        UNSDLEA: '24600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3967, 30.6642],
            [-97.2532, 30.6932],
            [-97.1649, 30.481],
            [-97.2815, 30.4187],
            [-97.3262, 30.5055],
            [-97.3806, 30.5501],
            [-97.3967, 30.6642],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842660',
        NAME: 'Thrall Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377566841,
        AWATER: 8946686,
        INTPTLAT: '+30.5682202',
        INTPTLON: '-097.2994272',
        ELSDLEA: '',
        UNSDLEA: '42660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8388, 29.0253],
            [-96.7921, 28.9188],
            [-96.7189, 28.8641],
            [-96.5752, 28.8963],
            [-96.534, 28.9392],
            [-96.4494, 28.8874],
            [-96.3107, 28.9226],
            [-96.3153, 28.8291],
            [-96.3818, 28.7725],
            [-96.5073, 28.7559],
            [-96.5746, 28.7055],
            [-96.6434, 28.7121],
            [-96.6993, 28.7778],
            [-96.7515, 28.8483],
            [-96.8521, 28.8725],
            [-96.8996, 28.9597],
            [-96.8388, 29.0253],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824150',
        NAME: 'Industrial Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726044702,
        AWATER: 17692723,
        INTPTLAT: '+28.8486317',
        INTPTLON: '-096.5755203',
        ELSDLEA: '',
        UNSDLEA: '24150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8042, 29.1604],
            [-96.6784, 29.2494],
            [-96.6338, 29.242],
            [-96.5464, 29.1677],
            [-96.5702, 29.0365],
            [-96.534, 28.9392],
            [-96.5752, 28.8963],
            [-96.7189, 28.8641],
            [-96.7921, 28.9188],
            [-96.8388, 29.0253],
            [-96.9386, 29.0632],
            [-96.8042, 29.1604],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818210',
        NAME: 'Edna Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 969923347,
        AWATER: 20714726,
        INTPTLAT: '+29.0640671',
        INTPTLON: '-096.7159535',
        ELSDLEA: '',
        UNSDLEA: '18210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1162, 30.7104],
            [-99.5141, 30.7108],
            [-99.5208, 30.6268],
            [-99.4845, 30.5169],
            [-99.4415, 30.3969],
            [-99.4767, 30.2882],
            [-99.6033, 30.2893],
            [-99.7541, 30.2907],
            [-100.1165, 30.2903],
            [-100.1162, 30.7104],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825020',
        NAME: 'Junction Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2854912025,
        AWATER: 430673,
        INTPTLAT: '+30.4723520',
        INTPTLON: '-099.8215057',
        ELSDLEA: '',
        UNSDLEA: '25020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9089, 32.4662],
            [-94.8698, 32.4707],
            [-94.8289, 32.4576],
            [-94.9428, 32.3704],
            [-94.9854, 32.3708],
            [-94.9868, 32.4234],
            [-94.9089, 32.4662],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4838460',
        NAME: 'Sabine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108084369,
        AWATER: 176870,
        INTPTLAT: '+32.4367847',
        INTPTLON: '-094.9238126',
        ELSDLEA: '',
        UNSDLEA: '38460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9089, 32.4662],
            [-94.8729, 32.5721],
            [-94.8409, 32.5839],
            [-94.84, 32.5803],
            [-94.8416, 32.5316],
            [-94.8698, 32.4707],
            [-94.9089, 32.4662],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4845480',
        NAME: 'White Oak Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41322234,
        AWATER: 214641,
        INTPTLAT: '+32.5213836',
        INTPTLON: '-094.8661178',
        ELSDLEA: '',
        UNSDLEA: '45480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1152, 31.088],
            [-99.6689, 31.087],
            [-99.6032, 31.0873],
            [-99.6036, 30.9407],
            [-99.4848, 30.9406],
            [-99.4844, 30.8234],
            [-99.5473, 30.8233],
            [-99.5141, 30.7108],
            [-100.1162, 30.7104],
            [-100.1152, 31.088],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4830210',
        NAME: 'Menard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2246573046,
        AWATER: 613559,
        INTPTLAT: '+30.8843655',
        INTPTLON: '-099.8539896',
        ELSDLEA: '',
        UNSDLEA: '30210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9644, 26.5688],
            [-97.9574, 26.6118],
            [-97.6666, 26.5989],
            [-97.6841, 26.4824],
            [-97.6837, 26.4401],
            [-97.8625, 26.4454],
            [-97.8622, 26.5533],
            [-97.9644, 26.5688],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836540',
        NAME: 'Raymondville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327535101,
        AWATER: 1951299,
        INTPTLAT: '+26.5312533',
        INTPTLON: '-097.8047111',
        ELSDLEA: '',
        UNSDLEA: '36540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1652, 35.3725],
            [-102.1628, 35.62],
            [-101.6723, 35.6201],
            [-101.7196, 35.5798],
            [-101.764, 35.4796],
            [-101.9147, 35.4498],
            [-101.922, 35.2942],
            [-101.8842, 35.2648],
            [-101.9563, 35.1832],
            [-102.0283, 35.1018],
            [-102.1151, 35.1023],
            [-102.1675, 35.1832],
            [-102.1652, 35.3725],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835570',
        NAME: 'Bushland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1431205002,
        AWATER: 13019685,
        INTPTLAT: '+35.4285729',
        INTPTLON: '-101.9978354',
        ELSDLEA: '',
        UNSDLEA: '35570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.59, 33.9744],
            [-98.5282, 33.9739],
            [-98.5753, 33.9077],
            [-98.59, 33.9744],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814130',
        NAME: 'City View Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36802343,
        AWATER: 61486,
        INTPTLAT: '+33.9455863',
        INTPTLON: '-098.5653587',
        ELSDLEA: '',
        UNSDLEA: '14130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4236, 33.836],
            [-98.423, 33.9114],
            [-98.1943, 33.9256],
            [-98.1936, 33.9962],
            [-98.0835, 34.0412],
            [-97.9717, 34.0054],
            [-97.9734, 33.9438],
            [-97.9797, 33.8922],
            [-98.0036, 33.7934],
            [-98.0976, 33.761],
            [-98.1061, 33.6708],
            [-98.2161, 33.7091],
            [-98.3256, 33.7048],
            [-98.3687, 33.6268],
            [-98.4231, 33.6712],
            [-98.4236, 33.836],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822980',
        NAME: 'Henrietta Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1060759686,
        AWATER: 58916910,
        INTPTLAT: '+33.8369668',
        INTPTLON: '-098.2003330',
        ELSDLEA: '',
        UNSDLEA: '22980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6179, 30.9304],
            [-95.415, 30.9336],
            [-95.3275, 30.8595],
            [-95.3506, 30.5859],
            [-95.4806, 30.6465],
            [-95.6153, 30.5541],
            [-95.6871, 30.663],
            [-95.8302, 30.6303],
            [-95.8442, 30.7276],
            [-95.8631, 30.8643],
            [-95.6179, 30.9304],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824030',
        NAME: 'Huntsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1517532355,
        AWATER: 29645865,
        INTPTLAT: '+30.7625597',
        INTPTLON: '-095.5832220',
        ELSDLEA: '',
        UNSDLEA: '24030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.9802, 32.0001],
            [-103.7229, 32.0002],
            [-103.1651, 32.0003],
            [-103.2036, 31.9684],
            [-103.1677, 31.8254],
            [-103.0836, 31.7421],
            [-102.9067, 31.7918],
            [-102.9303, 31.8575],
            [-102.7984, 31.8636],
            [-102.7989, 31.6518],
            [-103.3379, 31.6514],
            [-103.6109, 31.6518],
            [-103.7006, 31.7484],
            [-103.7966, 31.77],
            [-103.815, 31.8521],
            [-103.9122, 31.9024],
            [-103.9802, 32.0001],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846170',
        NAME: 'Wink-Loving Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2875722624,
        AWATER: 20546383,
        INTPTLAT: '+31.8166289',
        INTPTLON: '-103.3877780',
        ELSDLEA: '',
        UNSDLEA: '46170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6099, 33.4679],
            [-94.6578, 33.4071],
            [-94.6825, 33.487],
            [-94.6099, 33.4679],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4823700',
        NAME: 'Hubbard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 46520400,
        AWATER: 104206,
        INTPTLAT: '+33.4546516',
        INTPTLON: '-094.6493125',
        ELSDLEA: '',
        UNSDLEA: '23700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0434, 33.5523],
            [-94.0433, 33.5283],
            [-94.1407, 33.4705],
            [-94.1939, 33.4943],
            [-94.2159, 33.4973],
            [-94.2157, 33.5604],
            [-94.0434, 33.5523],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835130',
        NAME: 'Pleasant Grove Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142555057,
        AWATER: 5259134,
        INTPTLAT: '+33.5258603',
        INTPTLON: '-094.1332528',
        ELSDLEA: '',
        UNSDLEA: '35130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6578, 33.4071],
            [-94.6099, 33.4679],
            [-94.5491, 33.4633],
            [-94.5053, 33.457],
            [-94.4142, 33.3736],
            [-94.4304, 33.2838],
            [-94.4392, 33.2823],
            [-94.6156, 33.2483],
            [-94.6578, 33.4071],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840320',
        NAME: 'Simms Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376818647,
        AWATER: 2587908,
        INTPTLAT: '+33.3574262',
        INTPTLON: '-094.5467150',
        ELSDLEA: '',
        UNSDLEA: '40320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1172, 32.2817],
            [-99.0631, 32.2108],
            [-99.0546, 32.0791],
            [-99.0942, 31.9563],
            [-99.1987, 31.9704],
            [-99.4061, 32.0801],
            [-99.3976, 32.2201],
            [-99.3545, 32.2582],
            [-99.1172, 32.2817],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815810',
        NAME: 'Cross Plains Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 902193534,
        AWATER: 1682499,
        INTPTLAT: '+32.1463828',
        INTPTLON: '-099.2012506',
        ELSDLEA: '',
        UNSDLEA: '15810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5957, 32.8332],
            [-96.5189, 32.8335],
            [-96.519, 32.8067],
            [-96.5198, 32.7545],
            [-96.5957, 32.8332],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4841880',
        NAME: 'Sunnyvale Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44635146,
        AWATER: 5915376,
        INTPTLAT: '+32.8005250',
        INTPTLON: '-096.5475766',
        ELSDLEA: '',
        UNSDLEA: '41880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.294, 31.5552],
            [-98.1874, 31.5873],
            [-98.0274, 31.5736],
            [-97.9238, 31.5043],
            [-97.947, 31.4287],
            [-98.0094, 31.3958],
            [-98.0545, 31.3005],
            [-98.1967, 31.4017],
            [-98.3153, 31.5231],
            [-98.294, 31.5552],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818780',
        NAME: 'Evant Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645176093,
        AWATER: 188028,
        INTPTLAT: '+31.4435084',
        INTPTLON: '-098.1007120',
        ELSDLEA: '',
        UNSDLEA: '18780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3158, 31.4909],
            [-102.265, 31.4568],
            [-102.0977, 31.4964],
            [-102.0473, 31.3414],
            [-102.0482, 31.0832],
            [-102.3012, 31.0862],
            [-102.3158, 31.4909],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829700',
        NAME: 'McCamey Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1058839619,
        AWATER: 65,
        INTPTLAT: '+31.2744850',
        INTPTLON: '-102.1854116',
        ELSDLEA: '',
        UNSDLEA: '29700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1757, 30.5819],
            [-96.1678, 30.5756],
            [-96.149, 30.524],
            [-96.2611, 30.4973],
            [-96.2843, 30.4468],
            [-96.3765, 30.5467],
            [-96.3768, 30.6263],
            [-96.3129, 30.6463],
            [-96.1757, 30.5819],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4807350',
        NAME: 'College Station Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263826594,
        AWATER: 2546427,
        INTPTLAT: '+30.5444226',
        INTPTLON: '-096.2879570',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.567, 30.6957],
            [-96.5035, 30.7239],
            [-96.418, 30.8081],
            [-96.311, 30.9812],
            [-96.2602, 31.013],
            [-96.2066, 30.9432],
            [-96.1684, 30.823],
            [-96.1757, 30.5819],
            [-96.3129, 30.6463],
            [-96.3768, 30.6263],
            [-96.3765, 30.5467],
            [-96.4474, 30.5872],
            [-96.567, 30.6957],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811790',
        NAME: 'Bryan Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1048213258,
        AWATER: 9759954,
        INTPTLAT: '+30.7424668',
        INTPTLON: '-096.3306153',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2843, 30.4468],
            [-96.2611, 30.4973],
            [-96.149, 30.524],
            [-96.0448, 30.5199],
            [-95.9808, 30.3974],
            [-95.8084, 30.3885],
            [-95.8044, 30.3123],
            [-95.8043, 30.2456],
            [-96.0048, 30.2337],
            [-96.0932, 30.2252],
            [-96.1, 30.2967],
            [-96.1584, 30.3784],
            [-96.2992, 30.3792],
            [-96.2843, 30.4468],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4832190',
        NAME: 'Navasota Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 940336266,
        AWATER: 12805617,
        INTPTLAT: '+30.3828295',
        INTPTLON: '-096.0322658',
        ELSDLEA: '',
        UNSDLEA: '32190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9859, 27.2093],
            [-97.8575, 27.2434],
            [-97.6505, 27.2835],
            [-97.5089, 27.2666],
            [-97.2883, 27.2776],
            [-97.3232, 27.0103],
            [-97.3004, 26.8346],
            [-97.2254, 26.6002],
            [-97.6666, 26.5989],
            [-97.9574, 26.6118],
            [-97.9855, 26.7809],
            [-97.9859, 27.2093],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825410',
        NAME: 'Kenedy County-Wide Common School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3761669872,
        AWATER: 1259897573,
        INTPTLAT: '+26.8902129',
        INTPTLON: '-097.5911434',
        ELSDLEA: '',
        UNSDLEA: '25410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5178, 33.0742],
            [-100.5174, 33.3979],
            [-100.0041, 33.3976],
            [-99.9895, 33.2537],
            [-99.9896, 33.0905],
            [-100.0688, 33.0409],
            [-100.1189, 32.9598],
            [-100.28, 32.9611],
            [-100.3551, 32.9618],
            [-100.401, 33.0485],
            [-100.5178, 33.0742],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800006',
        NAME: 'Aspermont Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2089444181,
        AWATER: 9455280,
        INTPTLAT: '+33.2001456',
        INTPTLON: '-100.2640732',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.211, 32.5232],
            [-102.2056, 32.7593],
            [-102.2059, 32.8188],
            [-102.097, 32.8278],
            [-102.029, 32.7229],
            [-101.9074, 32.5995],
            [-101.8243, 32.5873],
            [-101.762, 32.5558],
            [-101.7345, 32.4696],
            [-101.7571, 32.3764],
            [-101.8736, 32.3502],
            [-101.9579, 32.3906],
            [-102.2111, 32.3594],
            [-102.211, 32.5232],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825790',
        NAME: 'Klondike Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1480790286,
        AWATER: 1489558,
        INTPTLAT: '+32.5472829',
        INTPTLON: '-102.0148306',
        ELSDLEA: '',
        UNSDLEA: '25790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.097, 32.8278],
            [-102.0143, 32.8761],
            [-101.9979, 32.9603],
            [-101.9263, 32.8504],
            [-101.81, 32.876],
            [-101.7877, 32.8066],
            [-101.6908, 32.8282],
            [-101.6893, 32.6312],
            [-101.8243, 32.5873],
            [-101.9074, 32.5995],
            [-102.029, 32.7229],
            [-102.097, 32.8278],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826610',
        NAME: 'Lamesa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 888850942,
        AWATER: 1514288,
        INTPTLAT: '+32.7511520',
        INTPTLON: '-101.8822996',
        ELSDLEA: '',
        UNSDLEA: '26610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.8243, 32.5873],
            [-101.6893, 32.6312],
            [-101.6241, 32.5758],
            [-101.5857, 32.4586],
            [-101.5106, 32.3872],
            [-101.5901, 32.3116],
            [-101.6919, 32.2992],
            [-101.7571, 32.3764],
            [-101.7345, 32.4696],
            [-101.762, 32.5558],
            [-101.8243, 32.5873],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839120',
        NAME: 'Sands Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567414684,
        AWATER: 841129,
        INTPTLAT: '+32.4606598',
        INTPTLON: '-101.6751641',
        ELSDLEA: '',
        UNSDLEA: '39120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2216, 35.3562],
            [-101.0861, 35.3572],
            [-101.0864, 35.2412],
            [-100.8861, 35.2564],
            [-100.8881, 35.1831],
            [-100.974, 35.1832],
            [-100.9743, 35.0801],
            [-101.0869, 35.0801],
            [-101.1282, 35.136],
            [-101.1823, 35.1817],
            [-101.3115, 35.1821],
            [-101.3109, 35.2829],
            [-101.222, 35.2836],
            [-101.2216, 35.3562],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821840',
        NAME: 'Groom Independent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619613403,
        AWATER: 2255104,
        INTPTLAT: '+35.2178926',
        INTPTLON: '-101.1074108',
        ELSDLEA: '',
        UNSDLEA: '21840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8263, 29.7626],
            [-96.7243, 29.7853],
            [-96.6102, 29.6801],
            [-96.609, 29.6427],
            [-96.6374, 29.5677],
            [-96.7578, 29.5406],
            [-96.8498, 29.5706],
            [-96.8742, 29.6327],
            [-96.8064, 29.7021],
            [-96.8263, 29.7626],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844820',
        NAME: 'Weimar Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 447957263,
        AWATER: 681949,
        INTPTLAT: '+29.6616745',
        INTPTLON: '-096.7452928',
        ELSDLEA: '',
        UNSDLEA: '44820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0528, 32.0059],
            [-95.8613, 32.0288],
            [-95.7329, 32.0444],
            [-95.7167, 31.9954],
            [-95.8172, 31.8855],
            [-95.8142, 31.7752],
            [-95.8669, 31.7281],
            [-95.9834, 31.79],
            [-96.0062, 31.9243],
            [-96.0528, 32.0059],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813200',
        NAME: 'Cayuga Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592796644,
        AWATER: 12027937,
        INTPTLAT: '+31.9080784',
        INTPTLON: '-095.8885962',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4033, 31.821],
            [-95.404, 31.8029],
            [-95.3662, 31.7268],
            [-95.2758, 31.6572],
            [-95.2732, 31.5929],
            [-95.4525, 31.5701],
            [-95.538, 31.6277],
            [-95.5238, 31.7061],
            [-95.5132, 31.8193],
            [-95.4033, 31.821],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840500',
        NAME: 'Slocum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446099900,
        AWATER: 3149563,
        INTPTLAT: '+31.6850984',
        INTPTLON: '-095.4115181',
        ELSDLEA: '',
        UNSDLEA: '40500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7167, 31.9954],
            [-95.6662, 31.9595],
            [-95.6012, 31.9278],
            [-95.5725, 31.8428],
            [-95.4377, 31.8713],
            [-95.4033, 31.821],
            [-95.5132, 31.8193],
            [-95.5238, 31.7061],
            [-95.6638, 31.7189],
            [-95.7271, 31.8008],
            [-95.8142, 31.7752],
            [-95.8172, 31.8855],
            [-95.7167, 31.9954],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834050',
        NAME: 'Palestine Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 566029831,
        AWATER: 7364522,
        INTPTLAT: '+31.8412615',
        INTPTLON: '-095.6617264',
        ELSDLEA: '',
        UNSDLEA: '34050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6209, 31.5133],
            [-97.6052, 31.5878],
            [-97.4624, 31.5582],
            [-97.3955, 31.6605],
            [-97.3414, 31.6097],
            [-97.3408, 31.5438],
            [-97.5174, 31.4623],
            [-97.6209, 31.5133],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815600',
        NAME: 'Crawford Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293696276,
        AWATER: 223219,
        INTPTLAT: '+31.5298624',
        INTPTLON: '-097.4986417',
        ELSDLEA: '',
        UNSDLEA: '15600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9238, 31.5043],
            [-97.7935, 31.5557],
            [-97.6947, 31.6655],
            [-97.6354, 31.6317],
            [-97.6052, 31.5878],
            [-97.6209, 31.5133],
            [-97.5561, 31.3706],
            [-97.5035, 31.326],
            [-97.4786, 31.2893],
            [-97.6191, 31.2173],
            [-97.6452, 31.2601],
            [-97.8514, 31.3129],
            [-97.9118, 31.228],
            [-98.0008, 31.2476],
            [-98.0545, 31.3005],
            [-98.0094, 31.3958],
            [-97.947, 31.4287],
            [-97.9238, 31.5043],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820500',
        NAME: 'Gatesville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1262827768,
        AWATER: 1927566,
        INTPTLAT: '+31.3954018',
        INTPTLON: '-097.7535350',
        ELSDLEA: '',
        UNSDLEA: '20500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.886, 31.7259],
            [-97.8659, 31.6466],
            [-97.7333, 31.6891],
            [-97.6947, 31.6655],
            [-97.7935, 31.5557],
            [-97.9238, 31.5043],
            [-98.0274, 31.5736],
            [-97.9738, 31.7087],
            [-97.886, 31.7259],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824900',
        NAME: 'Jonesboro Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453405198,
        AWATER: 151240,
        INTPTLAT: '+31.6054865',
        INTPTLON: '-097.8853832',
        ELSDLEA: '',
        UNSDLEA: '24900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5291, 31.1537],
            [-98.3716, 31.146],
            [-98.2241, 31.2002],
            [-98.2501, 31.2634],
            [-98.2192, 31.3771],
            [-98.1967, 31.4017],
            [-98.0545, 31.3005],
            [-98.0008, 31.2476],
            [-98.0194, 31.2322],
            [-97.8773, 31.0292],
            [-97.8619, 30.9633],
            [-97.9895, 30.9325],
            [-98.1054, 30.9459],
            [-98.1433, 30.9884],
            [-98.2718, 30.9335],
            [-98.2941, 31.0308],
            [-98.4397, 31.0295],
            [-98.5235, 31.0889],
            [-98.5291, 31.1537],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826640',
        NAME: 'Lampasas Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1567474235,
        AWATER: 3785911,
        INTPTLAT: '+31.1227959',
        INTPTLON: '-098.1436580',
        ELSDLEA: '',
        UNSDLEA: '26640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6764, 31.3688],
            [-95.6126, 31.4022],
            [-95.5845, 31.2923],
            [-95.4898, 31.3459],
            [-95.4223, 31.3451],
            [-95.3499, 31.437],
            [-95.3286, 31.3777],
            [-95.2407, 31.3492],
            [-95.2467, 31.2472],
            [-95.3231, 31.2106],
            [-95.4816, 31.2229],
            [-95.5736, 31.1869],
            [-95.6453, 31.2432],
            [-95.7479, 31.2023],
            [-95.6764, 31.3688],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815720',
        NAME: 'Crockett Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 591377885,
        AWATER: 854855,
        INTPTLAT: '+31.2848906',
        INTPTLON: '-095.4906250',
        ELSDLEA: '',
        UNSDLEA: '15720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7393, 31.5041],
            [-95.4525, 31.5701],
            [-95.2732, 31.5929],
            [-95.2316, 31.5739],
            [-95.3533, 31.4429],
            [-95.4587, 31.4456],
            [-95.6126, 31.4022],
            [-95.6764, 31.3688],
            [-95.6938, 31.3871],
            [-95.7393, 31.5041],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821630',
        NAME: 'Grapeland Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585848904,
        AWATER: 3502800,
        INTPTLAT: '+31.4947531',
        INTPTLON: '-095.5235226',
        ELSDLEA: '',
        UNSDLEA: '21630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.394, 31.0861],
            [-95.3266, 31.143],
            [-95.3231, 31.2106],
            [-95.2467, 31.2472],
            [-95.1322, 31.2028],
            [-95.0277, 31.1571],
            [-94.9392, 31.042],
            [-95.0063, 30.9872],
            [-95.0874, 30.9194],
            [-95.2002, 30.8246],
            [-95.2549, 30.9065],
            [-95.232, 30.9956],
            [-95.3368, 30.9874],
            [-95.394, 31.0861],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821900',
        NAME: 'Groveton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1015354470,
        AWATER: 14305430,
        INTPTLAT: '+31.0555198',
        INTPTLON: '-095.1696552',
        ELSDLEA: '',
        UNSDLEA: '21900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3499, 31.437],
            [-95.3533, 31.4429],
            [-95.2316, 31.5739],
            [-95.1432, 31.544],
            [-95.0033, 31.4257],
            [-94.9627, 31.39],
            [-94.9581, 31.3869],
            [-95.1156, 31.2775],
            [-95.1028, 31.2375],
            [-95.1322, 31.2028],
            [-95.2467, 31.2472],
            [-95.2407, 31.3492],
            [-95.3286, 31.3777],
            [-95.3499, 31.437],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825470',
        NAME: 'Kennard Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 854242181,
        AWATER: 747386,
        INTPTLAT: '+31.3873238',
        INTPTLON: '-095.1732438',
        ELSDLEA: '',
        UNSDLEA: '25470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7479, 31.2023],
            [-95.6453, 31.2432],
            [-95.5736, 31.1869],
            [-95.4816, 31.2229],
            [-95.3231, 31.2106],
            [-95.3266, 31.143],
            [-95.394, 31.0861],
            [-95.6179, 30.9304],
            [-95.6726, 30.9763],
            [-95.6565, 31.0793],
            [-95.7644, 31.0942],
            [-95.7479, 31.2023],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828410',
        NAME: 'Lovelady Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 840047252,
        AWATER: 5165677,
        INTPTLAT: '+31.1181367',
        INTPTLON: '-095.5534113',
        ELSDLEA: '',
        UNSDLEA: '28410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6085, 25.9771],
            [-97.5925, 26.0963],
            [-97.5428, 25.9775],
            [-97.4219, 25.9577],
            [-97.3668, 25.9157],
            [-97.3732, 25.84],
            [-97.5826, 25.9379],
            [-97.6085, 25.9771],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811680',
        NAME: 'Brownsville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250887235,
        AWATER: 12058475,
        INTPTLAT: '+25.9394545',
        INTPTLON: '-097.4813700',
        ELSDLEA: '',
        UNSDLEA: '11680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8618, 26.0699],
            [-97.8619, 26.13],
            [-97.7861, 26.138],
            [-97.7785, 26.1337],
            [-97.7777, 26.0294],
            [-97.8618, 26.0699],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4839330',
        NAME: 'Santa Maria Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69724663,
        AWATER: 3223326,
        INTPTLAT: '+26.0866781',
        INTPTLON: '-097.8170609',
        ELSDLEA: '',
        UNSDLEA: '39330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9294, 29.4999],
            [-94.9001, 29.5373],
            [-94.7171, 29.767],
            [-94.7892, 29.8189],
            [-94.7788, 29.8867],
            [-94.7389, 29.8872],
            [-94.6384, 29.8884],
            [-94.5257, 29.8896],
            [-94.5171, 29.7907],
            [-94.4655, 29.7329],
            [-94.4658, 29.645],
            [-94.3716, 29.5972],
            [-94.4982, 29.5448],
            [-94.6456, 29.5492],
            [-94.8769, 29.5141],
            [-94.9294, 29.4999],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808190',
        NAME: 'Anahuac Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 883700049,
        AWATER: 312817705,
        INTPTLAT: '+29.6896580',
        INTPTLON: '-094.6341140',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.104, 29.8403],
            [-94.9471, 29.8495],
            [-94.886, 29.819],
            [-94.9001, 29.5373],
            [-94.9576, 29.6163],
            [-94.9448, 29.6776],
            [-95.0204, 29.7099],
            [-95.0947, 29.8066],
            [-95.0788, 29.8249],
            [-95.104, 29.8403],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821150',
        NAME: 'Goose Creek Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252826496,
        AWATER: 95846555,
        INTPTLAT: '+29.7394443',
        INTPTLON: '-094.9610537',
        ELSDLEA: '',
        UNSDLEA: '21150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0204, 29.7099],
            [-94.9448, 29.6776],
            [-94.9576, 29.6163],
            [-94.9971, 29.6153],
            [-95.1089, 29.6282],
            [-95.1037, 29.7059],
            [-95.0204, 29.7099],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4826190',
        NAME: 'La Porte Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108737331,
        AWATER: 39392893,
        INTPTLAT: '+29.6584361',
        INTPTLON: '-095.0376140',
        ELSDLEA: '',
        UNSDLEA: '26190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5257, 29.8896],
            [-94.4422, 29.8895],
            [-94.358, 29.8875],
            [-94.3555, 29.7286],
            [-94.3549, 29.5063],
            [-94.3694, 29.5006],
            [-94.3716, 29.5972],
            [-94.4658, 29.645],
            [-94.4655, 29.7329],
            [-94.5171, 29.7907],
            [-94.5257, 29.8896],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817880',
        NAME: 'East Chambers Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381161445,
        AWATER: 12765018,
        INTPTLAT: '+29.7516267',
        INTPTLON: '-094.4207155',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9917, 31.0843],
            [-95.7644, 31.0942],
            [-95.6565, 31.0793],
            [-95.6726, 30.9763],
            [-95.6179, 30.9304],
            [-95.8631, 30.8643],
            [-95.8442, 30.7276],
            [-96.0063, 30.7401],
            [-96.0137, 30.8426],
            [-96.0691, 30.9515],
            [-96.0166, 30.9899],
            [-95.9917, 31.0843],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828710',
        NAME: 'Madisonville Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1062325888,
        AWATER: 14317613,
        INTPTLAT: '+30.9438797',
        INTPTLON: '-095.8659369',
        ELSDLEA: '',
        UNSDLEA: '28710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1151, 32.4591],
            [-99.0188, 32.5147],
            [-98.937, 32.515],
            [-98.9378, 32.4117],
            [-98.9051, 32.1997],
            [-98.9518, 32.2342],
            [-99.0631, 32.2108],
            [-99.1172, 32.2817],
            [-99.1151, 32.4591],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814070',
        NAME: 'Cisco Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 566380887,
        AWATER: 2591377,
        INTPTLAT: '+32.3639577',
        INTPTLON: '-099.0175092',
        ELSDLEA: '',
        UNSDLEA: '14070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7955, 32.5152],
            [-98.7225, 32.584],
            [-98.5761, 32.5725],
            [-98.5763, 32.5152],
            [-98.4752, 32.513],
            [-98.5086, 32.3788],
            [-98.5938, 32.3448],
            [-98.6591, 32.3571],
            [-98.7359, 32.4068],
            [-98.7955, 32.5152],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4836480',
        NAME: 'Ranger Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481122672,
        AWATER: 1430669,
        INTPTLAT: '+32.4526008',
        INTPTLON: '-098.6195823',
        ELSDLEA: '',
        UNSDLEA: '36480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7816, 32.1459],
            [-98.5965, 32.1572],
            [-98.5938, 32.3448],
            [-98.5086, 32.3788],
            [-98.4957, 32.3481],
            [-98.5269, 32.3203],
            [-98.4777, 32.1814],
            [-98.436, 32.0663],
            [-98.4739, 32.0145],
            [-98.4948, 31.9731],
            [-98.6931, 32.0751],
            [-98.7453, 32.0719],
            [-98.847, 32.0665],
            [-98.7816, 32.1459],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4816440',
        NAME: 'De Leon Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 621236591,
        AWATER: 13359548,
        INTPTLAT: '+32.1234803',
        INTPTLON: '-098.5772728',
        ELSDLEA: '',
        UNSDLEA: '16440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2751, 34.7111],
            [-100.2751, 34.6314],
            [-100.0833, 34.6451],
            [-100.0004, 34.6163],
            [-99.9975, 34.5606],
            [-99.9977, 34.3118],
            [-100.0253, 34.2389],
            [-100.047, 34.2298],
            [-100.2416, 34.237],
            [-100.2415, 34.3122],
            [-100.4545, 34.2825],
            [-100.5034, 34.3141],
            [-100.5008, 34.3776],
            [-100.4168, 34.4246],
            [-100.4159, 34.621],
            [-100.35, 34.6208],
            [-100.2751, 34.7111],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813860',
        NAME: 'Childress Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1642596988,
        AWATER: 43883741,
        INTPTLAT: '+34.4393717',
        INTPTLON: '-100.2151503',
        ELSDLEA: '',
        UNSDLEA: '13860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5173, 34.3141],
            [-100.5034, 34.3141],
            [-100.4545, 34.2825],
            [-100.2415, 34.3122],
            [-100.2416, 34.237],
            [-100.047, 34.2298],
            [-100.0485, 33.836],
            [-100.0713, 33.8361],
            [-100.5187, 33.8357],
            [-100.5173, 34.3141],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833900',
        NAME: 'Paducah Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2075504092,
        AWATER: 2628154,
        INTPTLAT: '+34.0610130',
        INTPTLON: '-100.2933838',
        ELSDLEA: '',
        UNSDLEA: '33900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.562, 33.5793],
            [-96.5131, 33.614],
            [-96.4496, 33.5742],
            [-96.448, 33.4497],
            [-96.5561, 33.4948],
            [-96.562, 33.5793],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842930',
        NAME: 'Tom Bean Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138509894,
        AWATER: 482052,
        INTPTLAT: '+33.5387459',
        INTPTLON: '-096.5026084',
        ELSDLEA: '',
        UNSDLEA: '42930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6239, 36.4995],
            [-101.583, 36.4995],
            [-101.3668, 36.4996],
            [-101.2048, 36.4995],
            [-101.2311, 36.3015],
            [-101.3449, 36.2693],
            [-101.3433, 36.1543],
            [-101.6235, 36.1546],
            [-101.6858, 36.1386],
            [-101.7338, 36.139],
            [-101.7334, 36.2115],
            [-101.6237, 36.2406],
            [-101.6239, 36.4995],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4821930',
        NAME: 'Gruver Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1358876258,
        AWATER: 786514,
        INTPTLAT: '+36.3281309',
        INTPTLON: '-101.4337355',
        ELSDLEA: '',
        UNSDLEA: '21930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7243, 29.7853],
            [-96.6652, 29.8523],
            [-96.5698, 29.9615],
            [-96.5357, 29.9073],
            [-96.3787, 29.8236],
            [-96.3309, 29.8144],
            [-96.2697, 29.6891],
            [-96.3752, 29.6896],
            [-96.4488, 29.5847],
            [-96.5451, 29.5621],
            [-96.609, 29.6427],
            [-96.6102, 29.6801],
            [-96.7243, 29.7853],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814700',
        NAME: 'Columbus Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1024786664,
        AWATER: 13115468,
        INTPTLAT: '+29.7538388',
        INTPTLON: '-096.5149491',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1004, 33.7254],
            [-97.0861, 33.7454],
            [-97.0094, 33.8185],
            [-96.9447, 33.798],
            [-96.97, 33.6007],
            [-96.9434, 33.537],
            [-96.9437, 33.4452],
            [-97.0085, 33.4815],
            [-97.0622, 33.5762],
            [-97.039, 33.6415],
            [-97.1004, 33.7254],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812510',
        NAME: 'Callisburg Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340776796,
        AWATER: 14592739,
        INTPTLAT: '+33.6458606',
        INTPTLON: '-097.0053091',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4863, 33.5568],
            [-97.485, 33.6982],
            [-97.4033, 33.8195],
            [-97.33, 33.8552],
            [-97.2399, 33.7191],
            [-97.3158, 33.6293],
            [-97.3698, 33.5556],
            [-97.4864, 33.5418],
            [-97.4863, 33.5568],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831860',
        NAME: 'Muenster Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492581016,
        AWATER: 1852096,
        INTPTLAT: '+33.6875486',
        INTPTLON: '-097.3734119',
        ELSDLEA: '',
        UNSDLEA: '31860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4869, 33.4588],
            [-97.3047, 33.4278],
            [-97.3342, 33.3418],
            [-97.39, 33.2605],
            [-97.504, 33.292],
            [-97.5522, 33.4009],
            [-97.5822, 33.434],
            [-97.4869, 33.4588],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840470',
        NAME: 'Slidell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301698108,
        AWATER: 1496875,
        INTPTLAT: '+33.3649643',
        INTPTLON: '-097.4345658',
        ELSDLEA: '',
        UNSDLEA: '40470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1866, 33.5569],
            [-97.1147, 33.5179],
            [-97.0622, 33.5762],
            [-97.0085, 33.4815],
            [-97.1118, 33.4221],
            [-97.2391, 33.4257],
            [-97.1866, 33.5569],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4843860',
        NAME: 'Valley View Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 202398048,
        AWATER: 9437091,
        INTPTLAT: '+33.4959382',
        INTPTLON: '-097.1362552',
        ELSDLEA: '',
        UNSDLEA: '43860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1905, 33.9036],
            [-97.1733, 33.8678],
            [-97.2057, 33.8029],
            [-97.1628, 33.7291],
            [-97.1941, 33.6808],
            [-97.2399, 33.7191],
            [-97.33, 33.8552],
            [-97.1905, 33.9036],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4840380',
        NAME: 'Sivells Bend Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195810949,
        AWATER: 4105717,
        INTPTLAT: '+33.8038268',
        INTPTLON: '-097.2242606',
        ELSDLEA: '',
        UNSDLEA: '40380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5114, 31.974],
            [-94.3316, 31.976],
            [-94.2898, 31.8556],
            [-94.3114, 31.7552],
            [-94.4047, 31.6833],
            [-94.4148, 31.7297],
            [-94.4638, 31.8742],
            [-94.5114, 31.974],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842840',
        NAME: 'Timpson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406104652,
        AWATER: 2463638,
        INTPTLAT: '+31.8733427',
        INTPTLON: '-094.3938628',
        ELSDLEA: '',
        UNSDLEA: '42840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3602, 27.3063],
            [-99.3336, 27.2732],
            [-98.9547, 27.2694],
            [-98.9542, 26.7857],
            [-99.0111, 26.675],
            [-99.1694, 26.5717],
            [-99.2089, 26.7248],
            [-99.2686, 26.8432],
            [-99.3289, 26.8802],
            [-99.3773, 26.9738],
            [-99.4461, 27.023],
            [-99.4264, 27.1783],
            [-99.4538, 27.2651],
            [-99.3602, 27.3063],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4846710',
        NAME: 'Zapata County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2585876159,
        AWATER: 154370982,
        INTPTLAT: '+26.9969806',
        INTPTLON: '-099.1826027',
        ELSDLEA: '',
        UNSDLEA: '46710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9581, 31.3869],
            [-94.874, 31.2474],
            [-94.8531, 31.1858],
            [-94.989, 31.182],
            [-95.0182, 31.2349],
            [-95.1028, 31.2375],
            [-95.1156, 31.2775],
            [-94.9581, 31.3869],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808490',
        NAME: 'Apple Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309855406,
        AWATER: 698274,
        INTPTLAT: '+31.2705295',
        INTPTLON: '-094.9791189',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0611, 33.1699],
            [-103.0565, 33.3884],
            [-102.5948, 33.3885],
            [-102.6286, 33.2403],
            [-102.6286, 33.1092],
            [-102.7499, 33.0654],
            [-102.7845, 33.0086],
            [-102.9224, 33.0386],
            [-103.0638, 33.0376],
            [-103.0611, 33.1699],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835040',
        NAME: 'Plains Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1553158386,
        AWATER: 0,
        INTPTLAT: '+33.2183468',
        INTPTLON: '-102.8471323',
        ELSDLEA: '',
        UNSDLEA: '35040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3217, 30.5986],
            [-102.1384, 30.5975],
            [-102.1378, 30.656],
            [-101.7684, 30.6531],
            [-101.6698, 30.6379],
            [-101.6465, 30.5511],
            [-101.7404, 30.4209],
            [-101.6585, 30.3579],
            [-101.7584, 30.288],
            [-101.7609, 29.7818],
            [-102.1167, 29.793],
            [-102.1889, 29.8488],
            [-102.3208, 29.8789],
            [-102.567, 30.0528],
            [-102.5669, 30.2833],
            [-102.3431, 30.2841],
            [-102.343, 30.5988],
            [-102.3217, 30.5986],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4842420',
        NAME: 'Terrell County Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6107208523,
        AWATER: 105866,
        INTPTLAT: '+30.2323427',
        INTPTLON: '-102.0725126',
        ELSDLEA: '',
        UNSDLEA: '42420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6102, 31.8441],
            [-94.5702, 31.8815],
            [-94.4638, 31.8742],
            [-94.4148, 31.7297],
            [-94.4718, 31.7098],
            [-94.6345, 31.7431],
            [-94.6102, 31.8441],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4820400',
        NAME: 'Garrison Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274588171,
        AWATER: 3991074,
        INTPTLAT: '+31.7924431',
        INTPTLON: '-094.5179968',
        ELSDLEA: '',
        UNSDLEA: '20400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0852, 36.4992],
            [-100.9542, 36.4999],
            [-100.6122, 36.4995],
            [-100.6192, 36.391],
            [-100.546, 36.3756],
            [-100.5467, 36.0565],
            [-100.9466, 36.0577],
            [-100.9462, 36.163],
            [-100.9999, 36.2438],
            [-101.0856, 36.2431],
            [-101.0852, 36.4992],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834650',
        NAME: 'Perryton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2045612573,
        AWATER: 1239667,
        INTPTLAT: '+36.2698759',
        INTPTLON: '-100.7940381',
        ELSDLEA: '',
        UNSDLEA: '34650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0093, 33.4889],
            [-95.822, 33.4689],
            [-95.7931, 33.4822],
            [-95.7845, 33.378],
            [-95.8891, 33.3633],
            [-95.957, 33.3637],
            [-96.0082, 33.39],
            [-96.0093, 33.4889],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4819050',
        NAME: 'Fannindel Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263395486,
        AWATER: 1375255,
        INTPTLAT: '+33.4305782',
        INTPTLON: '-095.9030171',
        ELSDLEA: '',
        UNSDLEA: '19050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7192, 31.9541],
            [-94.5702, 31.8815],
            [-94.6102, 31.8441],
            [-94.7277, 31.8438],
            [-94.8003, 31.844],
            [-94.7755, 31.9261],
            [-94.7192, 31.9541],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4831650',
        NAME: 'Mount Enterprise Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197695287,
        AWATER: 346056,
        INTPTLAT: '+31.8889633',
        INTPTLON: '-094.6865477',
        ELSDLEA: '',
        UNSDLEA: '31650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9854, 32.3708],
            [-94.9428, 32.3704],
            [-94.9423, 32.3704],
            [-94.9428, 32.308],
            [-94.9854, 32.2584],
            [-94.9859, 32.3504],
            [-94.9854, 32.3708],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4833870',
        NAME: 'Overton Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52135434,
        AWATER: 77776,
        INTPTLAT: '+32.3152110',
        INTPTLON: '-094.9647491',
        ELSDLEA: '',
        UNSDLEA: '33870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9854, 31.964],
            [-94.9854, 31.9957],
            [-94.8923, 32.0341],
            [-94.8927, 32.0988],
            [-94.8364, 32.1231],
            [-94.8474, 32.2349],
            [-94.8185, 32.2824],
            [-94.7003, 32.3417],
            [-94.677, 32.252],
            [-94.5538, 32.2524],
            [-94.5964, 32.1049],
            [-94.6, 31.9732],
            [-94.531, 31.9738],
            [-94.5114, 31.974],
            [-94.4638, 31.8742],
            [-94.5702, 31.8815],
            [-94.7192, 31.9541],
            [-94.8025, 32.0728],
            [-94.9312, 32],
            [-94.9436, 31.8974],
            [-94.9854, 31.964],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4822970',
        NAME: 'Henderson Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 933299964,
        AWATER: 14496273,
        INTPTLAT: '+32.0839373',
        INTPTLON: '-094.7240573',
        ELSDLEA: '',
        UNSDLEA: '22970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8456, 30.4275],
            [-96.7515, 30.3297],
            [-96.7012, 30.3196],
            [-96.8939, 30.2392],
            [-96.9511, 30.2975],
            [-96.9055, 30.3989],
            [-96.8456, 30.4275],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4817130',
        NAME: 'Dime Box Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242494999,
        AWATER: 464777,
        INTPTLAT: '+30.3219940',
        INTPTLON: '-096.8340741',
        ELSDLEA: '',
        UNSDLEA: '17130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5757, 32.8573],
            [-98.426, 32.8718],
            [-98.3886, 32.8104],
            [-98.2754, 32.8802],
            [-98.2178, 32.8687],
            [-98.2269, 32.7679],
            [-98.171, 32.742],
            [-98.3328, 32.7269],
            [-98.4207, 32.7932],
            [-98.4997, 32.6801],
            [-98.576, 32.6799],
            [-98.5757, 32.8573],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4834140',
        NAME: 'Palo Pinto Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439960753,
        AWATER: 13787760,
        INTPTLAT: '+32.8233041',
        INTPTLON: '-098.4779660',
        ELSDLEA: '',
        UNSDLEA: '34140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6344, 31.3929],
            [-99.6462, 31.4913],
            [-99.5358, 31.4824],
            [-99.5115, 31.4244],
            [-99.5444, 31.2734],
            [-99.4269, 31.2278],
            [-99.2271, 31.1418],
            [-99.0918, 31.1436],
            [-99.0921, 31.0259],
            [-99.1568, 30.9411],
            [-99.4848, 30.9406],
            [-99.6036, 30.9407],
            [-99.6032, 31.0873],
            [-99.6689, 31.087],
            [-99.7212, 31.1615],
            [-99.6864, 31.3784],
            [-99.6344, 31.3929],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811110',
        NAME: 'Brady Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1799092292,
        AWATER: 13600303,
        INTPTLAT: '+31.1368814',
        INTPTLON: '-099.4535983',
        ELSDLEA: '',
        UNSDLEA: '11110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4959, 30.3248],
            [-96.4258, 30.3441],
            [-96.2992, 30.3792],
            [-96.1584, 30.3784],
            [-96.1, 30.2967],
            [-96.0932, 30.2252],
            [-96.159, 30.2066],
            [-96.1914, 30.1347],
            [-96.1461, 30.0702],
            [-96.2928, 30.0961],
            [-96.5789, 30.0587],
            [-96.5188, 30.1763],
            [-96.5711, 30.2263],
            [-96.4959, 30.3248],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4811280',
        NAME: 'Brenham Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1176380064,
        AWATER: 19156222,
        INTPTLAT: '+30.2147688',
        INTPTLON: '-096.3347713',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5789, 30.0587],
            [-96.2928, 30.0961],
            [-96.1461, 30.0702],
            [-96.0845, 30.0051],
            [-96.1151, 29.9116],
            [-96.1124, 29.8894],
            [-96.2154, 29.8863],
            [-96.2463, 29.8166],
            [-96.343, 29.8993],
            [-96.3787, 29.8236],
            [-96.5357, 29.9073],
            [-96.5698, 29.9615],
            [-96.5957, 30.0028],
            [-96.622, 30.0443],
            [-96.5789, 30.0587],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4809810',
        NAME: 'Bellville Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 988529000,
        AWATER: 13665533,
        INTPTLAT: '+29.9764199',
        INTPTLON: '-096.3409315',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2856, 32.2669],
            [-96.124, 32.1558],
            [-96.0693, 32.0853],
            [-96.0548, 32.0125],
            [-96.1993, 31.974],
            [-96.2225, 32.0447],
            [-96.4064, 32.07],
            [-96.2856, 32.2669],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825530',
        NAME: 'Kerens Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 511675344,
        AWATER: 55686939,
        INTPTLAT: '+32.1089273',
        INTPTLON: '-096.2232485',
        ELSDLEA: '',
        UNSDLEA: '25530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0987, 33.0094],
            [-95.9943, 33.0821],
            [-95.8936, 33.0834],
            [-95.8633, 33.0499],
            [-95.8625, 32.9796],
            [-95.9663, 32.92],
            [-96.0987, 33.0094],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4828020',
        NAME: 'Lone Oak Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218349011,
        AWATER: 17087741,
        INTPTLAT: '+33.0111844',
        INTPTLON: '-095.9662047',
        ELSDLEA: '',
        UNSDLEA: '28020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8909, 33.1674],
            [-95.8936, 33.0834],
            [-95.9943, 33.0821],
            [-96.0206, 33.155],
            [-95.8909, 33.1674],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812660',
        NAME: 'Campbell Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120852999,
        AWATER: 799030,
        INTPTLAT: '+33.1385957',
        INTPTLON: '-095.9405825',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1573, 33.3702],
            [-96.1225, 33.2299],
            [-96.1744, 33.1925],
            [-96.2953, 33.2934],
            [-96.2953, 33.2945],
            [-96.1573, 33.3702],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4813260',
        NAME: 'Celeste Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182606604,
        AWATER: 1012988,
        INTPTLAT: '+33.2843899',
        INTPTLON: '-096.2003883',
        ELSDLEA: '',
        UNSDLEA: '13260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2962, 33.1005],
            [-96.2088, 33.1222],
            [-96.1322, 33.014],
            [-96.2077, 32.964],
            [-96.2735, 33.0324],
            [-96.2962, 33.1005],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4812390',
        NAME: 'Caddo Mills Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178245960,
        AWATER: 348285,
        INTPTLAT: '+33.0523425',
        INTPTLON: '-096.2001905',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0552, 29.6829],
            [-95.8911, 29.6277],
            [-95.8805, 29.5678],
            [-96.0224, 29.5019],
            [-96.0625, 29.5916],
            [-96.1754, 29.6338],
            [-96.1932, 29.6496],
            [-96.1175, 29.7053],
            [-96.0552, 29.6829],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4844470',
        NAME: 'Brazos Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325481757,
        AWATER: 5459913,
        INTPTLAT: '+29.6005279',
        INTPTLON: '-096.0175664',
        ELSDLEA: '',
        UNSDLEA: '44470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4999, 30.4054],
            [-97.4629, 30.4414],
            [-97.3065, 30.4112],
            [-97.2182, 30.3467],
            [-97.3187, 30.2693],
            [-97.4298, 30.1908],
            [-97.4911, 30.2124],
            [-97.4728, 30.2439],
            [-97.4438, 30.3194],
            [-97.4999, 30.4054],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4818360',
        NAME: 'Elgin Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426404896,
        AWATER: 1561176,
        INTPTLAT: '+30.3318931',
        INTPTLON: '-097.3846130',
        ELSDLEA: '',
        UNSDLEA: '18360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.855, 33.7463],
            [-95.7219, 33.6547],
            [-95.6174, 33.6838],
            [-95.5243, 33.6283],
            [-95.4907, 33.4543],
            [-95.6172, 33.4372],
            [-95.6442, 33.4861],
            [-95.5753, 33.5508],
            [-95.5849, 33.6064],
            [-95.7304, 33.6002],
            [-95.8568, 33.5365],
            [-95.855, 33.7463],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800012',
        NAME: 'Chisum Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 521755239,
        AWATER: 6646731,
        INTPTLAT: '+33.5169708',
        INTPTLON: '-095.5450002',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8891, 33.3633],
            [-95.7845, 33.378],
            [-95.7931, 33.4822],
            [-95.6442, 33.4861],
            [-95.6172, 33.4372],
            [-95.4907, 33.4543],
            [-95.3066, 33.378],
            [-95.5031, 33.3477],
            [-95.5812, 33.359],
            [-95.779, 33.2602],
            [-95.8495, 33.2261],
            [-95.8891, 33.3633],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4815150',
        NAME: 'Cooper Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550101238,
        AWATER: 53536587,
        INTPTLAT: '+33.3792631',
        INTPTLON: '-095.6534198',
        ELSDLEA: '',
        UNSDLEA: '15150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.546, 30.1596],
            [-98.4298, 30.1535],
            [-98.337, 30.1873],
            [-98.2252, 30.1319],
            [-98.2211, 30.1196],
            [-98.2416, 30.0513],
            [-98.2976, 30.038],
            [-98.4859, 29.876],
            [-98.6027, 29.9316],
            [-98.5697, 30.0414],
            [-98.6512, 30.0324],
            [-98.711, 30.1001],
            [-98.546, 30.1596],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4810320',
        NAME: 'Blanco Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 903271894,
        AWATER: 1732536,
        INTPTLAT: '+30.0500090',
        INTPTLON: '-098.4668891',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5917, 30.4999],
            [-98.351, 30.4861],
            [-98.1256, 30.4262],
            [-98.1119, 30.3801],
            [-98.119, 30.3777],
            [-98.173, 30.3562],
            [-98.2278, 30.2165],
            [-98.2252, 30.1319],
            [-98.337, 30.1873],
            [-98.4298, 30.1535],
            [-98.546, 30.1596],
            [-98.5879, 30.2079],
            [-98.5917, 30.4999],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4824840',
        NAME: 'Johnson City Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1382792619,
        AWATER: 9298446,
        INTPTLAT: '+30.3304698',
        INTPTLON: '-098.3755715',
        ELSDLEA: '',
        UNSDLEA: '24840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9297, 30.1828],
            [-97.811, 30.0971],
            [-97.7701, 30.107],
            [-97.7102, 30.0245],
            [-97.7524, 29.9536],
            [-97.8492, 29.916],
            [-97.9076, 29.9607],
            [-98.0099, 29.9645],
            [-98.0507, 30.0918],
            [-97.9554, 30.1288],
            [-97.9297, 30.1828],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800010',
        NAME: 'Hays Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559326254,
        AWATER: 2600596,
        INTPTLAT: '+30.0361716',
        INTPTLON: '-097.8794290',
        ELSDLEA: '',
        UNSDLEA: '00010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6583, 30.565],
            [-104.0905, 30.4504],
            [-103.901, 30.4122],
            [-103.8007, 30.4125],
            [-103.7929, 29.5034],
            [-103.7927, 29.2625],
            [-103.9721, 29.2961],
            [-104.1684, 29.3961],
            [-104.259, 29.5062],
            [-104.2556, 29.7291],
            [-104.4845, 29.7313],
            [-104.4843, 30.0212],
            [-104.5559, 30.0207],
            [-104.5759, 30.349],
            [-104.6459, 30.4033],
            [-104.6583, 30.565],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4829040',
        NAME: 'Marfa Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7890378536,
        AWATER: 1763753,
        INTPTLAT: '+29.9750947',
        INTPTLON: '-104.1439490',
        ELSDLEA: '',
        UNSDLEA: '29040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9798, 30.6293],
            [-104.6583, 30.565],
            [-104.6459, 30.4033],
            [-104.5759, 30.349],
            [-104.5559, 30.0207],
            [-104.4843, 30.0212],
            [-104.4845, 29.7313],
            [-104.2556, 29.7291],
            [-104.259, 29.5062],
            [-104.3382, 29.5202],
            [-104.514, 29.6386],
            [-104.5498, 29.7406],
            [-104.6759, 29.9155],
            [-104.7072, 30.0501],
            [-104.6871, 30.1793],
            [-104.7614, 30.3008],
            [-104.8594, 30.3911],
            [-104.8991, 30.5708],
            [-104.9798, 30.6293],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4835800',
        NAME: 'Presidio Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2094645034,
        AWATER: 9435,
        INTPTLAT: '+30.0788489',
        INTPTLON: '-104.5890130',
        ELSDLEA: '',
        UNSDLEA: '35800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.119, 30.3777],
            [-98.1241, 30.3216],
            [-98.0106, 30.2437],
            [-97.9297, 30.1828],
            [-97.9554, 30.1288],
            [-98.0507, 30.0918],
            [-98.128, 30.0797],
            [-98.2211, 30.1196],
            [-98.2252, 30.1319],
            [-98.2278, 30.2165],
            [-98.173, 30.3562],
            [-98.119, 30.3777],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4800008',
        NAME: 'Dripping Springs Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503757401,
        AWATER: 983088,
        INTPTLAT: '+30.2093811',
        INTPTLON: '-098.0996033',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4675, 33.1067],
            [-95.3504, 33.1524],
            [-95.3081, 33.0782],
            [-95.3083, 33.0503],
            [-95.3424, 32.9819],
            [-95.4271, 32.9619],
            [-95.4689, 32.9423],
            [-95.5206, 32.9614],
            [-95.5259, 33.0743],
            [-95.4675, 33.1067],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4814880',
        NAME: 'Como-Pickton Consolidated Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313594173,
        AWATER: 3344712,
        INTPTLAT: '+33.0508922',
        INTPTLON: '-095.4197900',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.8842, 35.2648],
            [-101.7599, 35.2653],
            [-101.7601, 35.1834],
            [-101.7777, 35.1495],
            [-101.9382, 35.1468],
            [-101.9563, 35.1832],
            [-101.8842, 35.2648],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4808130',
        NAME: 'Amarillo Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191723179,
        AWATER: 1721337,
        INTPTLAT: '+35.2065513',
        INTPTLON: '-101.8492306',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8289, 32.4576],
            [-94.7772, 32.4367],
            [-94.7906, 32.3706],
            [-94.6721, 32.3705],
            [-94.7003, 32.3417],
            [-94.8185, 32.2824],
            [-94.8968, 32.3042],
            [-94.9423, 32.3704],
            [-94.9428, 32.3704],
            [-94.8289, 32.4576],
          ],
        ],
      },
      properties: {
        STATEFP: '48',
        SCSDLEA: '',
        GEOID: '4825620',
        NAME: 'Kilgore Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250797775,
        AWATER: 5033017,
        INTPTLAT: '+32.3646010',
        INTPTLON: '-094.8198970',
        ELSDLEA: '',
        UNSDLEA: '25620',
      },
    },
  ],
};

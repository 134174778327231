import React, { createRef, useEffect, useState } from 'react';
import { Button, Col, Container, Spinner, Toast } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import shortid from 'shortid';

import ReportDetailsHeader from './components/ReportDetailsHeader';
import ReportChart from './components/ReportChart';
import {
  getReportDetails,
  updateReportDetails,
  deleteReportItem,
  deleteReport,
  shareReport,
  hideShareToast,
  getSharedReportDetails,
  saveCopy,
} from '../../actions/report.action';
import { addTextToClipboard } from '../reports';
import DeleteReportModal from '../reports/components/DeleteReportModal';
import DeleteReportItemModal from './components/DeleteReportItemModal';
import RenameReportModal from './components/RenameReportModal';
import ExportModal from '../../Components/ExportModal';
import types from '../../types';

import './style.css';
import navigationTracker from '../../helpers/analytics/navigationTracker';
import { COMPONENT_ID_MAP } from '../../constants/charts';
import { describeState } from '../../helpers/location';

const ReportDetailsLanding = () => {
  const dispatch = useDispatch();
  const { showShareToast, shareToken: tokenCopy } = useSelector((state) => state.reports);
  const { data: report, loading, deleted, copiedId } = useSelector((state) => state.reportDetails);
  const storeJwt = useSelector((state) => state.session.jwt);
  const { search } = useLocation();
  const { id } = useParams();
  const { push } = useHistory();

  const [isShared, setIsShared] = useState(false);
  const [shareToken, setSharedToken] = useState(null);
  const [shareUrl, setShareUrl] = useState('');

  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [showDeleteReportItemModal, setShowDeleteReportItemModal] = useState(false);
  const [showRenameReportModal, setShowRenameReportModal] = useState(false);
  const [selectedReportItem, setSelectedReportItem] = useState(null);
  const chartRefMap = report?.items?.reduce(
    (total, curr) => ({
      ...total,
      [curr?.id]: createRef(),
    }),
    {}
  );

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      const shareTokenId = query.get('shareToken');
      setIsShared(true);
      setSharedToken(shareTokenId);
      dispatch(getSharedReportDetails(shareTokenId, storeJwt));
    } else {
      dispatch(getReportDetails(id, storeJwt));
    }
  }, [search, id, storeJwt]);

  useEffect(() => {
    if (tokenCopy) {
      setShareUrl(
        `${window.location.protocol}//${window.location.host}/reports/shared?shareToken=${tokenCopy}`
      );
    }
  }, [tokenCopy]);

  useEffect(() => {
    if (deleted) {
      push('/reports');
    }
  }, [deleted, push]);

  useEffect(() => {
    if (copiedId) {
      push(`/reports/${copiedId}`);
    }
  }, [copiedId, push]);

  const handleDelete = () => setShowDeleteReportModal(true);

  const handleDeleteReportItem = async () => {
    dispatch(deleteReportItem(id, selectedReportItem, storeJwt));
  };

  const handleDragEnd = ({ source, destination }) => {
    if (!destination || destination.index === source.index) return;

    const newItems = [...report.items];
    newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, report.items[source.index]);
    const newVisItems = [...report.visualizations];
    newVisItems.splice(source.index, 1);
    newVisItems.splice(destination.index, 0, report.visualizations[source.index]);
    const newReport = { ...report, items: newItems, visualizations: newVisItems };
    dispatch(updateReportDetails(newReport, storeJwt));
  };

  const moveReportItemAbove = (item) => {
    const newItems = [...report.items];
    const index = newItems.indexOf(item);
    if (index === 0) return null;
    newItems.splice(index, 1);
    newItems.splice(index - 1, 0, item);
    return newItems;
  };

  const moveReportItemBelow = (item) => {
    const newItems = [...report.items];
    const index = newItems.indexOf(item);
    if (index === report.items.length - 1) return null;
    newItems.splice(index, 1);
    newItems.splice(index + 1, 0, item);
    return newItems;
  };

  const sendReportItemToTheTop = (item) => {
    const newItems = [...report.items];
    const index = newItems.indexOf(item);
    if (index === 0) return null;
    newItems.splice(index, 1);
    newItems.splice(0, 0, item);
    return newItems;
  };

  const sendReportItemToTheBottom = (item) => {
    const newItems = [...report.items];
    const index = newItems.indexOf(item);
    if (index === report.items.length - 1) return null;
    newItems.splice(index, 1);
    newItems.splice(report.items.length, 0, item);
    return newItems;
  };

  const moveReportVisualizationItemAbove = (item) => {
    const newItems = [...report.visualizations];
    const index = newItems.findIndex((i) => i.id === item.id);
    if (index === 0) return null;
    newItems.splice(index, 1);
    newItems.splice(index - 1, 0, item);
    return newItems;
  };

  const moveReportVisualizationItemBelow = (item) => {
    const newItems = [...report.visualizations];
    const index = newItems.findIndex((i) => i.id === item.id);
    if (index === report.visualizations.length - 1) return null;
    newItems.splice(index, 1);
    newItems.splice(index + 1, 0, item);
    return newItems;
  };

  const sendReportVisualizationItemToTheTop = (item) => {
    const newItems = [...report.visualizations];
    const index = newItems.findIndex((i) => i.id === item.id);
    if (index === 0) return null;
    newItems.splice(index, 1);
    newItems.splice(0, 0, item);
    return newItems;
  };

  const sendReportVisualizationItemToTheBottom = (item) => {
    const newItems = [...report.visualizations];
    const index = newItems.findIndex((i) => i.id === item.id);
    if (index === report.visualizations.length - 1) return null;
    newItems.splice(index, 1);
    newItems.splice(report.visualizations.length, 0, item);
    return newItems;
  };

  const move = (item, to) => {
    let newItems;
    let newVisItems;
    switch (to) {
      case 'above':
        newItems = moveReportItemAbove(item);
        newVisItems = moveReportVisualizationItemAbove(item);
        break;
      case 'below':
        newItems = moveReportItemBelow(item);
        newVisItems = moveReportVisualizationItemBelow(item);
        break;
      case 'top':
        newItems = sendReportItemToTheTop(item);
        newVisItems = sendReportVisualizationItemToTheTop(item);
        break;
      case 'bottom':
        newItems = sendReportItemToTheBottom(item);
        newVisItems = sendReportVisualizationItemToTheBottom(item);
        break;
      default:
        throw new Error('Unknown move target');
    }
    if (newItems) {
      const newReport = { ...report, items: newItems, visualizations: newVisItems };
      dispatch(updateReportDetails(newReport, storeJwt));
    }
  };

  const handleHeaderFooterUpdate = (item, notes) => {
    const newItems = [...report.items];
    const index = newItems.indexOf(item);

    newItems[index].notes = notes;
    const newReport = { ...report, items: newItems };
    dispatch(updateReportDetails(newReport, storeJwt));
  };

  const handleShare = () => {
    dispatch(shareReport(id, storeJwt));
  };

  const handleCopyToClipboardClick = () => {
    addTextToClipboard(shareUrl);
    dispatch(hideShareToast());
  };

  const handleOnExport = (exportData) => {
    navigationTracker.onExport(exportData);
  };

  const handleRenameReport = (newName) => {
    dispatch(updateReportDetails({ ...report, name: newName }, storeJwt));
    setShowRenameReportModal(false);
  };

  const getSvg = (contextId) => chartRefMap[contextId].current.chart.getSVG();

  const getRelQuestionSvg = (questionId, relatedQuestionId) => {
    return document.querySelector(`#related-question-${questionId}-${relatedQuestionId} svg`)
      .outerHTML;
  };

  const getMapRankSvg = (item, type) => {
    return document.querySelector(`#${type}-chart-${item.id}-${item.context.componentId} svg`)
      .outerHTML;
  };

  const getDemoSvg = (item, type) => {
    return chartRefMap[`${item.id}-${type}`].current.chart.getSVG();
  };

  const getHeaderAndFooter = (item) => {
    let footer = null;
    let header = null;
    try {
      if (item?.notes) {
        const notes = JSON.parse(item.notes);
        footer = notes.footer;
        header = notes.header;
      }
    } catch (e) {
      console.log(e);
    }
    return {
      footer,
      header,
    };
  };

  const mapDashboardItemToExportItem = (item, type) => {
    const { header, footer } = getHeaderAndFooter(item);
    const config = report.visualizations.find((v) => v.id === item.id)?.details;
    const contextId = item.context.componentId;
    const dashboardData = item.context.queryResult;

    if (item.context.componentId === COMPONENT_ID_MAP.ID_MAP) {
      return {
        svg: getMapRankSvg(item, type),
        config,
        contextId,
        dashboardData,
        header,
        footer,
      };
    }
    if (item.context.componentId === COMPONENT_ID_MAP.ID_DEMOGRAPHICS) {
      return {
        svg: getDemoSvg(item, type),
        config,
        contextId,
        dashboardData,
        header,
        footer,
      };
    }
    if (item.context.componentId === COMPONENT_ID_MAP.ID_POINT_IN_TIME) {
      return {
        svg: type,
        config,
        contextId,
        dashboardData,
        header,
        footer,
      };
    }
    return {
      svg: getSvg(item.id),
      config: report.visualizations.find((v) => v.id === item.id)?.details,
      contextId: item.context.componentId,
      dashboardData: item.context.queryResult,
      header,
      footer,
    };
  };

  const mapQuestionToExportItem = (question) => {
    const { header, footer } = getHeaderAndFooter(question);
    return {
      svg: getSvg(question.id),
      config: report.visualizations.find((v) => v.id === question.id)?.details,
      contextId: question.context.id,
      mliDataSegments: question.context.queryResult.mliDataSegments,
      questionCode: question.context.code,
      questionPrompt: question.context.prompt,
      dashboardData: null,
      footer,
      header,
    };
  };

  const mapRelatedQuestionToExportItem = (question) => {
    const { header, footer } = getHeaderAndFooter(question);
    const relatedQuestionInfo = question?.context?.queryResult?.relatedQuestions[0];
    return {
      svg: getRelQuestionSvg(question.context.id, question.context.relatedQuestionId),
      config: report.visualizations.find((v) => v.id === question.id)?.details,
      contextId: relatedQuestionInfo.id,
      mliDataSegments: relatedQuestionInfo.queryResult.mliDataSegments,
      questionCode: relatedQuestionInfo.code,
      questionPrompt: relatedQuestionInfo.prompt,
      dashboardData: null,
      footer,
      header,
    };
  };

  const handleExport = () => {
    const reportItems = [];
    report.items.forEach((item) => {
      if (item?.config?.type === 'QUESTION' && item?.context?.relatedQuestionId) {
        reportItems.push(mapQuestionToExportItem(item));
        const isRelatedQuestionVisible = document.querySelector(
          `#related-question-${item?.context?.id}-${item?.context?.relatedQuestionId} svg`
        );
        if (isRelatedQuestionVisible) {
          reportItems.push(mapRelatedQuestionToExportItem(item));
        }
      } else if (item?.config?.type === 'QUESTION') {
        reportItems.push(mapQuestionToExportItem(item));
      } else if (item.context.componentId === COMPONENT_ID_MAP.ID_MAP) {
        reportItems.push(mapDashboardItemToExportItem(item, 'map'));
        reportItems.push(mapDashboardItemToExportItem(item, 'rank'));
      } else if (item.context.componentId === COMPONENT_ID_MAP.ID_DEMOGRAPHICS) {
        reportItems.push(mapDashboardItemToExportItem(item, 'school'));
        reportItems.push(mapDashboardItemToExportItem(item, 'students'));
      } else if (item.context.componentId === COMPONENT_ID_MAP.ID_POINT_IN_TIME) {
        const graduationCharts = document.querySelectorAll('#graduation-chart svg');
        graduationCharts.forEach((el) => {
          reportItems.push(mapDashboardItemToExportItem(item, el.outerHTML));
        });
      } else {
        reportItems.push(mapDashboardItemToExportItem(item));
      }
    });

    dispatch({ type: types.reducerTypes.EXPORT_MODAL_SHOW, payload: reportItems });
  };

  const handleSaveCopy = () => {
    dispatch(saveCopy(shareToken, storeJwt));
  };

  const getLocationDescription = (item) => {
    if (item.config.type === 'DASHBOARD_ELEMENT') {
      return describeState(item.config.state);
    }
    if (item.locationFilters.length) {
      return item.locationFilters[0].valueDescription;
    }
    return item.locationDescription;
  };

  if (loading) {
    return (
      <Container fluid className="report-details-landing-container">
        <Col xs="12" className="d-flex justify-content-center align-items-center spinner-container">
          <Spinner animation="border" />
        </Col>
      </Container>
    );
  }

  return (
    <Container fluid className="report-details-landing-container">
      <ReportDetailsHeader
        name={report.name}
        onRename={() => setShowRenameReportModal(true)}
        onExport={handleExport}
        onShare={handleShare}
        onDelete={handleDelete}
        shared={isShared}
        onSaveCopy={handleSaveCopy}
      />
      <div className="items-container">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="report-items">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {report.items.map((item, index) => {
                  const reportVisualization = report.visualizations.find((v) => v.id === item.id);
                  const locFilters = reportVisualization?.details?.filter?.constraintGroups.filter(
                    (fl) => fl.type === 'LOCATION'
                  );
                  const queFilters = reportVisualization?.details?.filter?.constraintGroups.filter(
                    (fl) => fl.type === 'QUESTION'
                  );
                  const genFilters = reportVisualization?.details?.filter?.constraintGroups.filter(
                    (fl) => fl.type === 'GENERAL'
                  );
                  return (
                    <Draggable
                      key={shortid.generate()}
                      draggableId={`draggable-report-item-${index}`}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          className="item-container"
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          <ReportChart
                            itemNotes={JSON.parse(item.notes)}
                            itemId={item.id}
                            config={item.config}
                            context={item.context}
                            generalFilters={item.generalFilters
                              .filter((gf) => genFilters.map((qf) => qf.field).includes(gf.code))
                              .map((gf) => {
                                const vis = genFilters.find((gen) => gen.field === gf.code);
                                if (vis) {
                                  return {
                                    ...gf,
                                    selectedValues: vis.constraints
                                      .map((c) => c.valueDescription)
                                      .join(', '),
                                  };
                                }
                                return {
                                  ...gf,
                                };
                              })}
                            locationFilters={item.locationFilters
                              .filter((lf) => locFilters.map((qf) => qf.field).includes(lf.code))
                              .map((lf) => {
                                const vis = locFilters.find((gen) => gen.field === lf.code);
                                if (vis) {
                                  return {
                                    ...lf,
                                    selectedValues: vis.constraints
                                      .map((c) => c.valueDescription)
                                      .join(','),
                                  };
                                }
                                return {
                                  ...lf,
                                };
                              })}
                            questionFilters={item.questionFilters
                              .filter((qf) => queFilters.map((f) => f.field).includes(qf.code))
                              .map((qf) => {
                                const vis = queFilters.find((gen) => gen.field === qf.code);
                                if (vis) {
                                  return {
                                    ...qf,
                                    selectedValues: vis.constraints
                                      .map((c) => c.valueDescription)
                                      .join(','),
                                  };
                                }
                                return {
                                  ...qf,
                                };
                              })}
                            questionCategory={item.questionCategory}
                            visualization={reportVisualization}
                            locationDescription={getLocationDescription(item)}
                            dragHandleProps={draggableProvided.dragHandleProps}
                            ref={chartRefMap[item?.id]}
                            handleRef={(chartRefs) => {
                              const [schoolRef, studentRef] = chartRefs;
                              chartRefMap[`${item?.id}-school`] = schoolRef;
                              chartRefMap[`${item?.id}-students`] = studentRef;
                            }}
                            onMoveAbove={() => move(item, 'above')}
                            onMoveBelow={() => move(item, 'below')}
                            onSendToTheTop={() => move(item, 'top')}
                            onSendToTheBottom={() => move(item, 'bottom')}
                            onDelete={() => {
                              setSelectedReportItem(item.id);
                              setShowDeleteReportItemModal(true);
                            }}
                            updateNotes={(notes) => handleHeaderFooterUpdate(item, notes)}
                            shared={isShared}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <DeleteReportModal
        open={showDeleteReportModal}
        onRequestToClose={() => setShowDeleteReportModal(false)}
        onConfirm={() => dispatch(deleteReport(id, storeJwt))}
      />
      <DeleteReportItemModal
        open={showDeleteReportItemModal}
        onRequestToClose={() => {
          setShowDeleteReportItemModal(false);
          setSelectedReportItem(null);
        }}
        onConfirm={handleDeleteReportItem}
      />
      <ExportModal onExport={handleOnExport} />
      <RenameReportModal
        open={showRenameReportModal}
        currentName={report.name}
        onRequestToClose={() => setShowRenameReportModal(false)}
        onConfirm={handleRenameReport}
      />
      <Toast
        className="report-share-toast-container"
        onClose={() => dispatch(hideShareToast())}
        show={showShareToast}
      >
        <Toast.Header>
          <Button
            className="mr-auto"
            variant="secondary"
            aria-label="Copy to Clipboard"
            onClick={handleCopyToClipboardClick}
          >
            Copy to Clipboard
          </Button>
        </Toast.Header>
        <Toast.Body>{shareUrl}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default ReportDetailsLanding;

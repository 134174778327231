export const LA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.632, 30.4811],
            [-92.4933, 30.4805],
            [-92.2448, 30.4802],
            [-92.1764, 30.4369],
            [-92.1422, 30.2988],
            [-92.2847, 30.1474],
            [-92.426, 30.0494],
            [-92.5346, 30.1079],
            [-92.6255, 30.0923],
            [-92.5905, 30.1988],
            [-92.6266, 30.2303],
            [-92.632, 30.4811],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200030',
        NAME: 'Acadia Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1696777753,
        AWATER: 6021519,
        INTPTLAT: '+30.2914970',
        INTPTLON: '-092.4110366',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2977, 30.6495],
            [-91.2544, 30.7053],
            [-91.1076, 30.7103],
            [-91.0943, 30.6426],
            [-91.0971, 30.6195],
            [-91.1619, 30.6121],
            [-91.1871, 30.5899],
            [-91.3148, 30.5798],
            [-91.2977, 30.6495],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200039',
        NAME: 'Zachary Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218160550,
        AWATER: 8034993,
        INTPTLAT: '+30.6386134',
        INTPTLON: '-091.2069298',
        ELSDLEA: '',
        UNSDLEA: '00039',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1871, 30.5899],
            [-91.1619, 30.6121],
            [-91.1332, 30.5586],
            [-91.1847, 30.5618],
            [-91.1871, 30.5899],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200040',
        NAME: 'Baker City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21173249,
        AWATER: 0,
        INTPTLAT: '+30.5832688',
        INTPTLON: '-091.1577376',
        ELSDLEA: '',
        UNSDLEA: '00040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9785, 30.8782],
            [-92.8239, 30.89],
            [-92.5977, 30.8961],
            [-92.5974, 30.5816],
            [-92.6303, 30.4879],
            [-92.774, 30.4883],
            [-92.7756, 30.4377],
            [-93.0655, 30.4386],
            [-93.1312, 30.4236],
            [-93.1304, 30.5978],
            [-92.9792, 30.5983],
            [-92.9785, 30.8782],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200060',
        NAME: 'Allen Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1973084287,
        AWATER: 10634064,
        INTPTLAT: '+30.6527435',
        INTPTLON: '-092.8196052',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.021, 30.3215],
            [-90.8917, 30.3452],
            [-90.7195, 30.19],
            [-90.6328, 30.2214],
            [-90.642, 30.1664],
            [-90.9156, 30.1133],
            [-90.9637, 30.0664],
            [-91.106, 30.0625],
            [-91.021, 30.3215],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200090',
        NAME: 'Ascension Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 751029431,
        AWATER: 33194722,
        INTPTLAT: '+30.2064437',
        INTPTLON: '-090.9125023',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2244, 30.0253],
            [-91.106, 30.0625],
            [-90.9637, 30.0664],
            [-90.9599, 29.9779],
            [-90.8856, 29.9054],
            [-91.0067, 29.7148],
            [-91.0826, 29.6264],
            [-91.1, 29.6994],
            [-91.0927, 29.8016],
            [-91.1828, 29.8462],
            [-91.2552, 29.9715],
            [-91.2244, 30.0253],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200120',
        NAME: 'Assumption Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 877123530,
        AWATER: 67006149,
        INTPTLAT: '+29.8988529',
        INTPTLON: '-091.0524649',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2807, 30.9651],
            [-92.2088, 30.9647],
            [-92.2343, 31.1315],
            [-92.2332, 31.3215],
            [-92.0867, 31.3354],
            [-92.0081, 31.3258],
            [-91.8343, 31.2663],
            [-91.6773, 31.188],
            [-91.7238, 31.0443],
            [-91.7501, 31.0188],
            [-91.806, 30.9726],
            [-91.8153, 30.8481],
            [-92.2127, 30.8486],
            [-92.2807, 30.9651],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200150',
        NAME: 'Avoyelles Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2156226385,
        AWATER: 85990266,
        INTPTLAT: '+31.0850937',
        INTPTLON: '-091.9937975',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7417, 30.403],
            [-93.7147, 30.4916],
            [-93.7388, 30.541],
            [-93.6313, 30.6779],
            [-93.5586, 30.8688],
            [-93.4211, 30.8831],
            [-92.9785, 30.8782],
            [-92.9792, 30.5983],
            [-93.1304, 30.5978],
            [-93.1312, 30.4236],
            [-93.1311, 30.4031],
            [-93.3851, 30.4026],
            [-93.3847, 30.4901],
            [-93.4864, 30.4905],
            [-93.4861, 30.4025],
            [-93.7417, 30.403],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200180',
        NAME: 'Beauregard Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2997502171,
        AWATER: 21999001,
        INTPTLAT: '+30.6450184',
        INTPTLON: '-093.3402526',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3728, 32.4102],
            [-93.2367, 32.4101],
            [-93.1472, 32.4552],
            [-93.1788, 32.5849],
            [-92.8808, 32.5853],
            [-92.8793, 32.4541],
            [-92.7772, 32.4535],
            [-92.7749, 32.2371],
            [-92.8147, 32.1469],
            [-92.9391, 32.1479],
            [-93.1872, 32.1484],
            [-93.214, 32.2361],
            [-93.4282, 32.2359],
            [-93.4262, 32.3098],
            [-93.3728, 32.4102],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200210',
        NAME: 'Bienville Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2101202068,
        AWATER: 27951294,
        INTPTLAT: '+32.3409517',
        INTPTLON: '-093.0411511',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8329, 30.7657],
            [-89.8328, 30.7653],
            [-89.8798, 30.7524],
            [-89.8889, 30.8043],
            [-89.8364, 30.8043],
            [-89.8329, 30.7657],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200240',
        NAME: 'Bogalusa City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24614321,
        AWATER: 129231,
        INTPTLAT: '+30.7753782',
        INTPTLON: '-089.8616597',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8146, 33.0194],
            [-93.8049, 33.0194],
            [-93.521, 33.0186],
            [-93.5308, 32.9678],
            [-93.4531, 32.8599],
            [-93.4412, 32.4104],
            [-93.3728, 32.4102],
            [-93.4262, 32.3098],
            [-93.4282, 32.2359],
            [-93.4712, 32.2372],
            [-93.5282, 32.3132],
            [-93.6151, 32.3483],
            [-93.7563, 32.5373],
            [-93.7366, 32.5798],
            [-93.8192, 32.736],
            [-93.7832, 32.7844],
            [-93.8146, 33.0194],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200270',
        NAME: 'Bossier Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2176181456,
        AWATER: 70514387,
        INTPTLAT: '+32.6958514',
        INTPTLON: '-093.6161317',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0428, 32.5754],
            [-94.0431, 32.693],
            [-94.0431, 32.8811],
            [-94.0431, 32.9654],
            [-94.043, 33.0192],
            [-93.8146, 33.0194],
            [-93.7832, 32.7844],
            [-93.8192, 32.736],
            [-93.7366, 32.5798],
            [-93.7563, 32.5373],
            [-93.6151, 32.3483],
            [-93.5282, 32.3132],
            [-93.4712, 32.2372],
            [-93.6147, 32.2375],
            [-93.6665, 32.3174],
            [-93.7648, 32.3456],
            [-93.9511, 32.1955],
            [-94.0426, 32.196],
            [-94.0428, 32.2813],
            [-94.0429, 32.3923],
            [-94.0428, 32.5754],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200300',
        NAME: 'Caddo Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2275354742,
        AWATER: 150399603,
        INTPTLAT: '+32.5771951',
        INTPTLON: '-093.8824228',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7417, 30.403],
            [-93.4861, 30.4025],
            [-93.4864, 30.4905],
            [-93.3847, 30.4901],
            [-93.3851, 30.4026],
            [-93.1311, 30.4031],
            [-93.031, 30.3794],
            [-93.0307, 30.2606],
            [-92.9963, 30.157],
            [-92.8933, 30.1563],
            [-92.8876, 30.0825],
            [-92.9964, 30.0385],
            [-93.1285, 30.0526],
            [-93.7225, 30.0509],
            [-93.7131, 30.1072],
            [-93.707, 30.2437],
            [-93.7643, 30.3302],
            [-93.7417, 30.403],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200330',
        NAME: 'Calcasieu Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2754925000,
        AWATER: 79222012,
        INTPTLAT: '+30.2295588',
        INTPTLON: '-093.3580154',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3122, 32.1461],
            [-92.3119, 32.2774],
            [-92.0344, 32.2771],
            [-91.9729, 32.1667],
            [-91.8947, 32.1516],
            [-91.8897, 31.9715],
            [-92.0054, 31.9273],
            [-92.3126, 31.9274],
            [-92.3122, 32.1461],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200360',
        NAME: 'Caldwell Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1371203816,
        AWATER: 29277303,
        INTPTLAT: '+32.1012195',
        INTPTLON: '-092.1142311',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8544, 29.8651],
            [-93.7445, 30.0193],
            [-93.7225, 30.0509],
            [-93.1285, 30.0526],
            [-92.9964, 30.0385],
            [-92.7386, 30.0373],
            [-92.6156, 30.0382],
            [-92.6173, 29.5271],
            [-92.8068, 29.5864],
            [-93.1251, 29.7062],
            [-93.2874, 29.7243],
            [-93.3494, 29.6954],
            [-93.4878, 29.7178],
            [-93.7701, 29.6708],
            [-93.8144, 29.5966],
            [-93.8374, 29.6899],
            [-93.9269, 29.791],
            [-93.8544, 29.8651],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200390',
        NAME: 'Cameron Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3327839224,
        AWATER: 1688122259,
        INTPTLAT: '+29.8718120',
        INTPTLON: '-093.1649478',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0081, 31.3258],
            [-92.0054, 31.9273],
            [-91.8897, 31.9715],
            [-91.7799, 31.8815],
            [-91.646, 31.9611],
            [-91.5757, 31.8826],
            [-91.5448, 31.7517],
            [-91.6945, 31.7353],
            [-91.7059, 31.6429],
            [-91.7758, 31.6486],
            [-91.8269, 31.5917],
            [-91.7797, 31.531],
            [-91.8559, 31.496],
            [-91.8343, 31.2663],
            [-92.0081, 31.3258],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200420',
        NAME: 'Catahoula Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1833782911,
        AWATER: 81308667,
        INTPTLAT: '+31.6665170',
        INTPTLON: '-091.8467030',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2386, 33.018],
            [-92.9887, 33.0174],
            [-92.7247, 33.0144],
            [-92.7254, 32.7595],
            [-92.8285, 32.7588],
            [-92.8808, 32.5853],
            [-93.1788, 32.5849],
            [-93.1866, 32.7168],
            [-93.2374, 32.7162],
            [-93.2386, 33.018],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200450',
        NAME: 'Claiborne Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1955125390,
        AWATER: 32473222,
        INTPTLAT: '+32.8270432',
        INTPTLON: '-092.9897672',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5448, 31.7517],
            [-91.3803, 31.7333],
            [-91.4439, 31.5425],
            [-91.5159, 31.5307],
            [-91.4721, 31.3971],
            [-91.5482, 31.3461],
            [-91.5186, 31.2753],
            [-91.6413, 31.2669],
            [-91.5895, 31.1911],
            [-91.6252, 31.116],
            [-91.5644, 31.039],
            [-91.6369, 30.9994],
            [-91.6625, 30.9704],
            [-91.6583, 30.9944],
            [-91.7238, 31.0443],
            [-91.6773, 31.188],
            [-91.8343, 31.2663],
            [-91.8559, 31.496],
            [-91.7797, 31.531],
            [-91.8269, 31.5917],
            [-91.7758, 31.6486],
            [-91.7059, 31.6429],
            [-91.6945, 31.7353],
            [-91.5448, 31.7517],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200480',
        NAME: 'Concordia Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1805172280,
        AWATER: 130329991,
        INTPTLAT: '+31.4698055',
        INTPTLON: '-091.6263140',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0426, 32.196],
            [-93.9511, 32.1955],
            [-93.7648, 32.3456],
            [-93.6665, 32.3174],
            [-93.6147, 32.2375],
            [-93.5234, 32.177],
            [-93.5414, 32.1059],
            [-93.4415, 31.9892],
            [-93.3555, 31.9322],
            [-93.4405, 31.9126],
            [-93.4412, 31.8452],
            [-93.8791, 31.8443],
            [-94.0427, 31.9993],
            [-94.0426, 32.196],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200510',
        NAME: 'De Soto Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2269878444,
        AWATER: 47049426,
        INTPTLAT: '+32.0592476',
        INTPTLON: '-093.7407968',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.1076, 30.7103],
              [-90.849, 30.7193],
              [-90.9107, 30.6494],
              [-90.9805, 30.5891],
              [-91.0943, 30.6426],
              [-91.1076, 30.7103],
            ],
          ],
          [
            [
              [-91.3148, 30.5798],
              [-91.1871, 30.5899],
              [-91.1847, 30.5618],
              [-91.1332, 30.5586],
              [-91.1619, 30.6121],
              [-91.0971, 30.6195],
              [-91.1011, 30.5226],
              [-90.9892, 30.4658],
              [-90.8917, 30.3452],
              [-91.021, 30.3215],
              [-91.1421, 30.3233],
              [-91.2415, 30.3576],
              [-91.1972, 30.5071],
              [-91.3148, 30.5798],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200540',
        NAME: 'East Baton Rouge Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774073018,
        AWATER: 29813964,
        INTPTLAT: '+30.5011669',
        INTPTLON: '-091.0748187',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4453, 32.582],
            [-91.3448, 32.7567],
            [-91.3355, 32.8661],
            [-91.265, 33.0051],
            [-91.1661, 33.0041],
            [-91.2128, 32.9221],
            [-91.064, 32.9241],
            [-91.1436, 32.8447],
            [-91.1658, 32.7578],
            [-91.0608, 32.7275],
            [-91.1527, 32.6408],
            [-91.0436, 32.5763],
            [-91.0698, 32.5628],
            [-91.1337, 32.5379],
            [-91.4576, 32.5378],
            [-91.4453, 32.582],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200570',
        NAME: 'East Carroll Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1089619259,
        AWATER: 56273972,
        INTPTLAT: '+32.7301699',
        INTPTLON: '-091.2341409',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1761, 30.9991],
            [-91.0602, 30.9989],
            [-90.8258, 30.9994],
            [-90.861, 30.9354],
            [-90.849, 30.7193],
            [-91.1076, 30.7103],
            [-91.2544, 30.7053],
            [-91.2977, 30.6495],
            [-91.3101, 30.654],
            [-91.2405, 30.7872],
            [-91.1761, 30.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200600',
        NAME: 'East Feliciana Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1174307243,
        AWATER: 6151756,
        INTPTLAT: '+30.8397837',
        INTPTLON: '-091.0434338',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6303, 30.4879],
            [-92.5974, 30.5816],
            [-92.5977, 30.8961],
            [-92.5246, 30.8933],
            [-92.3808, 31.0009],
            [-92.2807, 30.9651],
            [-92.2127, 30.8486],
            [-92.1724, 30.7679],
            [-92.2112, 30.5681],
            [-92.2627, 30.5387],
            [-92.4208, 30.5388],
            [-92.4933, 30.4805],
            [-92.632, 30.4811],
            [-92.6303, 30.4879],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200630',
        NAME: 'Evangeline Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1715552083,
        AWATER: 44665790,
        INTPTLAT: '+30.7206932',
        INTPTLON: '-092.4040858',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8947, 32.1516],
            [-91.7766, 32.2391],
            [-91.7591, 32.3177],
            [-91.6251, 32.4053],
            [-91.4767, 32.4058],
            [-91.4517, 32.3686],
            [-91.543, 32.259],
            [-91.4927, 32.2033],
            [-91.5114, 31.93],
            [-91.5757, 31.8826],
            [-91.646, 31.9611],
            [-91.7799, 31.8815],
            [-91.8897, 31.9715],
            [-91.8947, 32.1516],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200660',
        NAME: 'Franklin Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1617667694,
        AWATER: 28107882,
        INTPTLAT: '+32.1390764',
        INTPTLON: '-091.6723711',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3626, 31.7966],
            [-92.3725, 31.7015],
            [-92.343, 31.5958],
            [-92.2821, 31.5035],
            [-92.1961, 31.4779],
            [-92.6319, 31.3905],
            [-92.6947, 31.4072],
            [-92.719, 31.5176],
            [-92.8062, 31.5985],
            [-92.9083, 31.6252],
            [-92.9735, 31.7089],
            [-92.62, 31.7096],
            [-92.6192, 31.7971],
            [-92.3626, 31.7966],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200690',
        NAME: 'Grant Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1665429795,
        AWATER: 56013947,
        INTPTLAT: '+31.5977873',
        INTPTLON: '-092.5617156',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0456, 29.5058],
            [-92.0429, 29.5781],
            [-91.9799, 29.6501],
            [-91.9696, 29.8169],
            [-91.9904, 29.9703],
            [-91.9645, 30.0369],
            [-91.951, 30.0732],
            [-91.8192, 30.044],
            [-91.7652, 30.1085],
            [-91.6589, 30.1082],
            [-91.6004, 30.0332],
            [-91.3688, 30.0588],
            [-91.2244, 30.0253],
            [-91.2552, 29.9715],
            [-91.472, 29.956],
            [-91.6247, 29.9453],
            [-91.7111, 29.8461],
            [-91.9352, 29.6726],
            [-91.7233, 29.6613],
            [-91.5913, 29.5077],
            [-91.5919, 29.3594],
            [-91.8409, 29.3602],
            [-92.0456, 29.5058],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200720',
        NAME: 'Iberia Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1486941068,
        AWATER: 1182115462,
        INTPTLAT: '+29.5507737',
        INTPTLON: '-091.8376719',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7009, 30.4974],
            [-91.4851, 30.4971],
            [-91.3117, 30.3225],
            [-91.1421, 30.3233],
            [-91.021, 30.3215],
            [-91.106, 30.0625],
            [-91.2244, 30.0253],
            [-91.3688, 30.0588],
            [-91.4638, 30.1025],
            [-91.5083, 30.2403],
            [-91.6211, 30.3099],
            [-91.6401, 30.4427],
            [-91.7009, 30.4974],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200750',
        NAME: 'Iberville Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1602209065,
        AWATER: 88655982,
        INTPTLAT: '+30.2706327',
        INTPTLON: '-091.3658402',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7772, 32.4535],
            [-92.6217, 32.453],
            [-92.6223, 32.4964],
            [-92.4153, 32.4955],
            [-92.4154, 32.4078],
            [-92.3118, 32.3208],
            [-92.3119, 32.2774],
            [-92.3122, 32.1461],
            [-92.8147, 32.1469],
            [-92.7749, 32.2371],
            [-92.7772, 32.4535],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200780',
        NAME: 'Jackson Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1474195901,
        AWATER: 28714912,
        INTPTLAT: '+32.3042901',
        INTPTLON: '-092.5616997',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1312, 30.4236],
            [-93.0655, 30.4386],
            [-92.7756, 30.4377],
            [-92.774, 30.4883],
            [-92.6303, 30.4879],
            [-92.632, 30.4811],
            [-92.6266, 30.2303],
            [-92.5905, 30.1988],
            [-92.6255, 30.0923],
            [-92.7386, 30.0373],
            [-92.9964, 30.0385],
            [-92.8876, 30.0825],
            [-92.8933, 30.1563],
            [-92.9963, 30.157],
            [-93.0307, 30.2606],
            [-93.031, 30.3794],
            [-93.1311, 30.4031],
            [-93.1312, 30.4236],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200810',
        NAME: 'Jefferson Davis Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1687025362,
        AWATER: 18718206,
        INTPTLAT: '+30.2695293',
        INTPTLON: '-092.8162205',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2599, 30.1634],
            [-90.1563, 30.1896],
            [-90.1081, 30.1649],
            [-90.1386, 29.9188],
            [-90.0081, 29.8955],
            [-90.0684, 29.8292],
            [-90.0672, 29.7387],
            [-89.9808, 29.5802],
            [-89.9775, 29.4494],
            [-89.9023, 29.2923],
            [-89.8997, 29.155],
            [-90.0323, 29.135],
            [-90.0891, 29.0946],
            [-90.0903, 29.1806],
            [-90.0001, 29.2949],
            [-90.0206, 29.4285],
            [-90.1375, 29.4772],
            [-90.1704, 29.6835],
            [-90.2282, 29.692],
            [-90.1673, 29.8174],
            [-90.2402, 29.8621],
            [-90.2803, 29.9726],
            [-90.2599, 30.1634],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200840',
        NAME: 'Jefferson Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765682141,
        AWATER: 897184732,
        INTPTLAT: '+29.6633433',
        INTPTLON: '-090.1006107',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2847, 30.1474],
            [-92.1422, 30.2988],
            [-92.0448, 30.3782],
            [-91.9887, 30.3686],
            [-91.949, 30.2768],
            [-91.9799, 30.1826],
            [-91.9094, 30.1502],
            [-91.951, 30.0732],
            [-91.9645, 30.0369],
            [-92.2027, 30.1429],
            [-92.2847, 30.1474],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200870',
        NAME: 'Lafayette Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696045251,
        AWATER: 1204114,
        INTPTLAT: '+30.2065065',
        INTPTLON: '-092.0641700',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0067, 29.7148],
            [-90.8856, 29.9054],
            [-90.6563, 29.8892],
            [-90.5296, 29.885],
            [-90.4754, 29.8122],
            [-90.372, 29.7592],
            [-90.3462, 29.6933],
            [-90.2282, 29.692],
            [-90.1704, 29.6835],
            [-90.1375, 29.4772],
            [-90.0206, 29.4285],
            [-90.0001, 29.2949],
            [-90.0903, 29.1806],
            [-90.0891, 29.0946],
            [-90.2007, 29.0362],
            [-90.4094, 28.9953],
            [-90.4094, 29.2397],
            [-90.3855, 29.3677],
            [-90.6193, 29.5981],
            [-90.7668, 29.6955],
            [-90.8057, 29.7745],
            [-90.8802, 29.7168],
            [-91.0067, 29.7148],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200900',
        NAME: 'Lafourche Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2766822935,
        AWATER: 1037697180,
        INTPTLAT: '+29.4919925',
        INTPTLON: '-090.3948490',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3626, 31.7966],
            [-92.3126, 31.9274],
            [-92.0054, 31.9273],
            [-92.0081, 31.3258],
            [-92.0867, 31.3354],
            [-92.1961, 31.4779],
            [-92.2821, 31.5035],
            [-92.343, 31.5958],
            [-92.3725, 31.7015],
            [-92.3626, 31.7966],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200960',
        NAME: 'La Salle Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1617924843,
        AWATER: 97664776,
        INTPTLAT: '+31.6800826',
        INTPTLON: '-092.1615956',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7254, 32.7595],
            [-92.6283, 32.7597],
            [-92.415, 32.6701],
            [-92.4151, 32.5828],
            [-92.4153, 32.4955],
            [-92.6223, 32.4964],
            [-92.6217, 32.453],
            [-92.7772, 32.4535],
            [-92.8793, 32.4541],
            [-92.8808, 32.5853],
            [-92.8285, 32.7588],
            [-92.7254, 32.7595],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2200990',
        NAME: 'Lincoln Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1221806132,
        AWATER: 1897709,
        INTPTLAT: '+32.6018231',
        INTPTLON: '-092.6623026',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9892, 30.4658],
            [-90.9805, 30.5891],
            [-90.9107, 30.6494],
            [-90.5672, 30.65],
            [-90.5669, 30.4871],
            [-90.4778, 30.3564],
            [-90.4858, 30.277],
            [-90.5541, 30.1956],
            [-90.6328, 30.2214],
            [-90.7195, 30.19],
            [-90.8917, 30.3452],
            [-90.9892, 30.4658],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201020',
        NAME: 'Livingston Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1678747394,
        AWATER: 112119238,
        INTPTLAT: '+30.4404194',
        INTPTLON: '-090.7274738',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4767, 32.4058],
            [-91.4576, 32.5378],
            [-91.1337, 32.5379],
            [-91.0698, 32.5628],
            [-91.1167, 32.5001],
            [-91.0274, 32.4339],
            [-90.9867, 32.3518],
            [-90.9124, 32.3395],
            [-90.9834, 32.2118],
            [-91.124, 32.2111],
            [-91.4927, 32.2033],
            [-91.543, 32.259],
            [-91.4517, 32.3686],
            [-91.4767, 32.4058],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201050',
        NAME: 'Madison Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1617282459,
        AWATER: 68539547,
        INTPTLAT: '+32.3413753',
        INTPTLON: '-091.2420231',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0552, 32.5262],
            [-92.1163, 32.4764],
            [-92.1355, 32.5253],
            [-92.0552, 32.5262],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201080',
        NAME: 'Monroe City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75871373,
        AWATER: 8423374,
        INTPTLAT: '+32.5189851',
        INTPTLON: '-092.0771794',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0691, 33.0082],
            [-91.9243, 33.0076],
            [-91.4604, 33.0059],
            [-91.4358, 33.0061],
            [-91.5115, 32.879],
            [-91.5664, 32.8556],
            [-91.6376, 32.6673],
            [-91.7397, 32.5613],
            [-91.8043, 32.5713],
            [-91.9131, 32.5059],
            [-91.9244, 32.6186],
            [-91.975, 32.7114],
            [-92.0652, 32.723],
            [-92.0843, 32.8822],
            [-92.0691, 33.0082],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201110',
        NAME: 'Morehouse Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2058870191,
        AWATER: 28878805,
        INTPTLAT: '+32.8200082',
        INTPTLON: '-091.8003988',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4412, 31.8452],
            [-93.4405, 31.9126],
            [-93.3555, 31.9322],
            [-93.2817, 31.8871],
            [-93.2385, 31.9728],
            [-93.1361, 32.0562],
            [-93.1872, 32.1484],
            [-92.9391, 32.1479],
            [-92.8851, 32.0116],
            [-92.883, 31.94],
            [-92.9422, 31.8543],
            [-92.9735, 31.7089],
            [-92.9083, 31.6252],
            [-92.8062, 31.5985],
            [-92.719, 31.5176],
            [-92.913, 31.3682],
            [-92.9815, 31.3466],
            [-93.0323, 31.3643],
            [-93.2361, 31.3645],
            [-93.2367, 31.5383],
            [-93.3375, 31.5381],
            [-93.339, 31.7143],
            [-93.4417, 31.7145],
            [-93.4412, 31.8452],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201140',
        NAME: 'Natchitoches Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3243341073,
        AWATER: 121033892,
        INTPTLAT: '+31.7252229',
        INTPTLON: '-093.0969902',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1081, 30.1649],
            [-90.0403, 30.1551],
            [-89.9032, 30.1987],
            [-89.7974, 30.1608],
            [-89.6251, 30.1535],
            [-89.6832, 30.0764],
            [-89.8123, 30.0444],
            [-89.8816, 29.9862],
            [-89.9899, 29.9895],
            [-89.9107, 29.8679],
            [-90.0081, 29.8955],
            [-90.1386, 29.9188],
            [-90.1081, 30.1649],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201170',
        NAME: 'Orleans Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 438792720,
        AWATER: 467306395,
        INTPTLAT: '+30.0534195',
        INTPTLON: '-089.9345018',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4153, 32.4955],
            [-92.4151, 32.5828],
            [-92.2723, 32.581],
            [-92.0652, 32.723],
            [-91.975, 32.7114],
            [-91.9244, 32.6186],
            [-91.9131, 32.5059],
            [-92.0043, 32.3805],
            [-92.0344, 32.2771],
            [-92.3119, 32.2774],
            [-92.3118, 32.3208],
            [-92.4154, 32.4078],
            [-92.4153, 32.4955],
          ],
          [
            [-92.0552, 32.5262],
            [-92.1355, 32.5253],
            [-92.1163, 32.4764],
            [-92.0552, 32.5262],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201200',
        NAME: 'Ouachita Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1505060289,
        AWATER: 46871178,
        INTPTLAT: '+32.4755603',
        INTPTLON: '-092.1605707',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0081, 29.8955],
            [-89.9107, 29.8679],
            [-89.8697, 29.7955],
            [-89.721, 29.7577],
            [-89.6282, 29.6807],
            [-89.4846, 29.6176],
            [-89.0478, 29.6478],
            [-88.8792, 29.6474],
            [-89.0389, 29.4922],
            [-89.1195, 29.4581],
            [-89.1376, 29.3814],
            [-89.0694, 29.2943],
            [-88.9421, 29.2537],
            [-88.9092, 29.2002],
            [-88.9278, 29.1173],
            [-89.1333, 28.9197],
            [-89.2796, 28.9778],
            [-89.3847, 28.8715],
            [-89.4876, 28.8874],
            [-89.4634, 29.0828],
            [-89.5023, 29.1584],
            [-89.6104, 29.1986],
            [-89.8997, 29.155],
            [-89.9023, 29.2923],
            [-89.9775, 29.4494],
            [-89.9808, 29.5802],
            [-90.0672, 29.7387],
            [-90.0684, 29.8292],
            [-90.0081, 29.8955],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201230',
        NAME: 'Plaquemines Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2020765755,
        AWATER: 4628160494,
        INTPTLAT: '+29.3847616',
        INTPTLON: '-089.4548319',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7501, 31.0188],
            [-91.6583, 30.9944],
            [-91.6625, 30.9704],
            [-91.6614, 30.8571],
            [-91.5156, 30.8614],
            [-91.553, 30.7308],
            [-91.3806, 30.7588],
            [-91.3309, 30.6587],
            [-91.4148, 30.5115],
            [-91.4851, 30.4971],
            [-91.7009, 30.4974],
            [-91.7562, 30.4977],
            [-91.7337, 30.7017],
            [-91.8153, 30.8481],
            [-91.806, 30.9726],
            [-91.7501, 31.0188],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201260',
        NAME: 'Pointe Coupee Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1443519857,
        AWATER: 86418272,
        INTPTLAT: '+30.7083190',
        INTPTLON: '-091.6046207',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9815, 31.3466],
            [-92.913, 31.3682],
            [-92.719, 31.5176],
            [-92.6947, 31.4072],
            [-92.6319, 31.3905],
            [-92.1961, 31.4779],
            [-92.0867, 31.3354],
            [-92.2332, 31.3215],
            [-92.2343, 31.1315],
            [-92.2088, 30.9647],
            [-92.2807, 30.9651],
            [-92.3808, 31.0009],
            [-92.5246, 30.8933],
            [-92.5977, 30.8961],
            [-92.8239, 30.89],
            [-92.8297, 31.2523],
            [-92.8798, 31.3174],
            [-92.9815, 31.3466],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201290',
        NAME: 'Rapides Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3413632520,
        AWATER: 113043213,
        INTPTLAT: '+31.1932037',
        INTPTLON: '-092.5359531',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6147, 32.2375],
            [-93.4712, 32.2372],
            [-93.4282, 32.2359],
            [-93.214, 32.2361],
            [-93.1872, 32.1484],
            [-93.1361, 32.0562],
            [-93.2385, 31.9728],
            [-93.2817, 31.8871],
            [-93.3555, 31.9322],
            [-93.4415, 31.9892],
            [-93.5414, 32.1059],
            [-93.5234, 32.177],
            [-93.6147, 32.2375],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201320',
        NAME: 'Red River Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1007739207,
        AWATER: 34327512,
        INTPTLAT: '+32.1012125',
        INTPTLON: '-093.3490504',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9131, 32.5059],
            [-91.8043, 32.5713],
            [-91.7397, 32.5613],
            [-91.6376, 32.6673],
            [-91.596, 32.5822],
            [-91.4453, 32.582],
            [-91.4576, 32.5378],
            [-91.4767, 32.4058],
            [-91.6251, 32.4053],
            [-91.7591, 32.3177],
            [-91.7766, 32.2391],
            [-91.8947, 32.1516],
            [-91.9729, 32.1667],
            [-92.0344, 32.2771],
            [-92.0043, 32.3805],
            [-91.9131, 32.5059],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201350',
        NAME: 'Richland Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1448537069,
        AWATER: 13551678,
        INTPTLAT: '+32.4131580',
        INTPTLON: '-091.7483534',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8791, 31.8443],
            [-93.4412, 31.8452],
            [-93.4417, 31.7145],
            [-93.339, 31.7143],
            [-93.3375, 31.5381],
            [-93.2367, 31.5383],
            [-93.2361, 31.3645],
            [-93.3905, 31.3641],
            [-93.4426, 31.2767],
            [-93.5349, 31.2766],
            [-93.5525, 31.1854],
            [-93.6003, 31.1762],
            [-93.6139, 31.2594],
            [-93.6875, 31.3108],
            [-93.6926, 31.4372],
            [-93.7495, 31.4687],
            [-93.8349, 31.5862],
            [-93.815, 31.7287],
            [-93.8791, 31.8443],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201380',
        NAME: 'Sabine Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2244628537,
        AWATER: 375417244,
        INTPTLAT: '+31.5604024',
        INTPTLON: '-093.5595793',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6251, 30.1535],
            [-89.495, 30.1608],
            [-89.3061, 30.1779],
            [-89.1837, 30.2122],
            [-89.0965, 30.1658],
            [-88.889, 30.1398],
            [-88.7837, 30.0267],
            [-88.7584, 29.9183],
            [-88.8018, 29.7502],
            [-88.8792, 29.6474],
            [-89.0478, 29.6478],
            [-89.4846, 29.6176],
            [-89.6282, 29.6807],
            [-89.721, 29.7577],
            [-89.8697, 29.7955],
            [-89.9107, 29.8679],
            [-89.9899, 29.9895],
            [-89.8816, 29.9862],
            [-89.8123, 30.0444],
            [-89.6832, 30.0764],
            [-89.6251, 30.1535],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201410',
        NAME: 'St. Bernard Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 977743046,
        AWATER: 4604215126,
        INTPTLAT: '+29.8914984',
        INTPTLON: '-089.3279425',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2599, 30.1634],
            [-90.2803, 29.9726],
            [-90.2402, 29.8621],
            [-90.1673, 29.8174],
            [-90.2282, 29.692],
            [-90.3462, 29.6933],
            [-90.372, 29.7592],
            [-90.4754, 29.8122],
            [-90.5296, 29.885],
            [-90.5381, 30.0169],
            [-90.4502, 30.0331],
            [-90.3266, 30.1502],
            [-90.2599, 30.1634],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201440',
        NAME: 'St. Charles Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 719646243,
        AWATER: 308361669,
        INTPTLAT: '+29.9057222',
        INTPTLON: '-090.3578553',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.849, 30.7193],
            [-90.861, 30.9354],
            [-90.8258, 30.9994],
            [-90.5672, 30.9997],
            [-90.5672, 30.65],
            [-90.9107, 30.6494],
            [-90.849, 30.7193],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201470',
        NAME: 'St. Helena Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057772592,
        AWATER: 2796218,
        INTPTLAT: '+30.8225386',
        INTPTLON: '-090.7083074',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9637, 30.0664],
            [-90.9156, 30.1133],
            [-90.642, 30.1664],
            [-90.6668, 29.9809],
            [-90.6563, 29.8892],
            [-90.8856, 29.9054],
            [-90.9599, 29.9779],
            [-90.9637, 30.0664],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201500',
        NAME: 'St. James Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625582188,
        AWATER: 42168610,
        INTPTLAT: '+30.0247772',
        INTPTLON: '-090.7939562',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.642, 30.1664],
            [-90.6328, 30.2214],
            [-90.5541, 30.1956],
            [-90.4858, 30.277],
            [-90.3009, 30.2947],
            [-90.1606, 30.2343],
            [-90.1563, 30.1896],
            [-90.2599, 30.1634],
            [-90.3266, 30.1502],
            [-90.4502, 30.0331],
            [-90.5381, 30.0169],
            [-90.5296, 29.885],
            [-90.6563, 29.8892],
            [-90.6668, 29.9809],
            [-90.642, 30.1664],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201530',
        NAME: 'St. John the Baptist Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555050486,
        AWATER: 506472491,
        INTPTLAT: '+30.1473067',
        INTPTLON: '-090.4856737',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2127, 30.8486],
            [-91.8153, 30.8481],
            [-91.7337, 30.7017],
            [-91.7562, 30.4977],
            [-91.6943, 30.4017],
            [-91.8693, 30.4172],
            [-91.9887, 30.3686],
            [-92.0448, 30.3782],
            [-92.1422, 30.2988],
            [-92.1764, 30.4369],
            [-92.2448, 30.4802],
            [-92.4933, 30.4805],
            [-92.4208, 30.5388],
            [-92.2627, 30.5387],
            [-92.2112, 30.5681],
            [-92.1724, 30.7679],
            [-92.2127, 30.8486],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201560',
        NAME: 'St. Landry Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2392904510,
        AWATER: 39047412,
        INTPTLAT: '+30.5834409',
        INTPTLON: '-091.9892745',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.472, 29.956],
              [-91.2552, 29.9715],
              [-91.1828, 29.8462],
              [-91.0927, 29.8016],
              [-91.1, 29.6994],
              [-91.3865, 29.7837],
              [-91.3736, 29.8344],
              [-91.472, 29.956],
            ],
          ],
          [
            [
              [-91.7562, 30.4977],
              [-91.7009, 30.4974],
              [-91.6401, 30.4427],
              [-91.6211, 30.3099],
              [-91.5083, 30.2403],
              [-91.4638, 30.1025],
              [-91.3688, 30.0588],
              [-91.6004, 30.0332],
              [-91.6589, 30.1082],
              [-91.7652, 30.1085],
              [-91.8192, 30.044],
              [-91.951, 30.0732],
              [-91.9094, 30.1502],
              [-91.9799, 30.1826],
              [-91.949, 30.2768],
              [-91.9887, 30.3686],
              [-91.8693, 30.4172],
              [-91.6943, 30.4017],
              [-91.7562, 30.4977],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201590',
        NAME: 'St. Martin Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1910535374,
        AWATER: 204161448,
        INTPTLAT: '+30.1214329',
        INTPTLON: '-091.6114814',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.472, 29.956],
            [-91.3736, 29.8344],
            [-91.3865, 29.7837],
            [-91.1, 29.6994],
            [-91.0826, 29.6264],
            [-91.178, 29.6097],
            [-91.2607, 29.5445],
            [-91.2737, 29.4794],
            [-91.2122, 29.4234],
            [-91.3543, 29.2396],
            [-91.4211, 29.3028],
            [-91.5919, 29.3594],
            [-91.5913, 29.5077],
            [-91.7233, 29.6613],
            [-91.9352, 29.6726],
            [-91.7111, 29.8461],
            [-91.6247, 29.9453],
            [-91.472, 29.956],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201620',
        NAME: 'St. Mary Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1438844850,
        AWATER: 1460448190,
        INTPTLAT: '+29.6293488',
        INTPTLON: '-091.4638037',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2556, 30.7119],
            [-89.9942, 30.6654],
            [-89.8423, 30.666],
            [-89.7528, 30.503],
            [-89.6836, 30.4518],
            [-89.6712, 30.3849],
            [-89.6316, 30.257],
            [-89.5719, 30.1807],
            [-89.495, 30.1608],
            [-89.6251, 30.1535],
            [-89.7974, 30.1608],
            [-89.9032, 30.1987],
            [-90.0403, 30.1551],
            [-90.1081, 30.1649],
            [-90.1563, 30.1896],
            [-90.1606, 30.2343],
            [-90.2436, 30.3616],
            [-90.2556, 30.7119],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201650',
        NAME: 'St. Tammany Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2190289201,
        AWATER: 647245544,
        INTPTLAT: '+30.4100224',
        INTPTLON: '-089.9519622',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5672, 30.9997],
            [-90.5476, 30.9997],
            [-90.3473, 31.0005],
            [-90.3467, 30.9054],
            [-90.3012, 30.8508],
            [-90.2556, 30.7119],
            [-90.2436, 30.3616],
            [-90.1606, 30.2343],
            [-90.3009, 30.2947],
            [-90.4858, 30.277],
            [-90.4778, 30.3564],
            [-90.5669, 30.4871],
            [-90.5672, 30.65],
            [-90.5672, 30.9997],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201680',
        NAME: 'Tangipahoa Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2049504791,
        AWATER: 136674364,
        INTPTLAT: '+30.6215802',
        INTPTLON: '-090.4066329',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5757, 31.8826],
            [-91.5114, 31.93],
            [-91.4927, 32.2033],
            [-91.124, 32.2111],
            [-91.1628, 32.1327],
            [-91.0536, 32.1244],
            [-91.0308, 32.1206],
            [-91.0302, 32.1144],
            [-91.1644, 31.9826],
            [-91.2481, 31.8698],
            [-91.3452, 31.8439],
            [-91.3179, 31.7498],
            [-91.3803, 31.7333],
            [-91.5448, 31.7517],
            [-91.5757, 31.8826],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201710',
        NAME: 'Tensas Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1561204545,
        AWATER: 99471079,
        INTPTLAT: '+32.0014894',
        INTPTLON: '-091.3425760',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0826, 29.6264],
            [-91.0067, 29.7148],
            [-90.8802, 29.7168],
            [-90.8057, 29.7745],
            [-90.7668, 29.6955],
            [-90.6193, 29.5981],
            [-90.3855, 29.3677],
            [-90.4094, 29.2397],
            [-90.4094, 28.9953],
            [-90.5525, 29.0368],
            [-90.7208, 28.9926],
            [-90.9071, 28.9911],
            [-91.0088, 29.0269],
            [-91.0061, 29.12],
            [-91.2927, 29.1962],
            [-91.3543, 29.2396],
            [-91.2122, 29.4234],
            [-91.2737, 29.4794],
            [-91.2607, 29.5445],
            [-91.178, 29.6097],
            [-91.0826, 29.6264],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201740',
        NAME: 'Terrebonne Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3190255110,
        AWATER: 2202158274,
        INTPTLAT: '+29.3341299',
        INTPTLON: '-090.8436739',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7247, 33.0144],
            [-92.538, 33.0127],
            [-92.4525, 33.0118],
            [-92.0691, 33.0082],
            [-92.0843, 32.8822],
            [-92.0652, 32.723],
            [-92.2723, 32.581],
            [-92.4151, 32.5828],
            [-92.415, 32.6701],
            [-92.6283, 32.7597],
            [-92.7254, 32.7595],
            [-92.7247, 33.0144],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201770',
        NAME: 'Union Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2271389438,
        AWATER: 73594418,
        INTPTLAT: '+32.8293486',
        INTPTLON: '-092.3756501',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6255, 30.0923],
            [-92.5346, 30.1079],
            [-92.426, 30.0494],
            [-92.2847, 30.1474],
            [-92.2027, 30.1429],
            [-91.9645, 30.0369],
            [-91.9904, 29.9703],
            [-91.9696, 29.8169],
            [-91.9799, 29.6501],
            [-92.0429, 29.5781],
            [-92.0456, 29.5058],
            [-92.1467, 29.532],
            [-92.2937, 29.4815],
            [-92.6173, 29.5271],
            [-92.6156, 30.0382],
            [-92.7386, 30.0373],
            [-92.6255, 30.0923],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201800',
        NAME: 'Vermilion Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3038610957,
        AWATER: 955342069,
        INTPTLAT: '+29.7897222',
        INTPTLON: '-092.2915678',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5525, 31.1854],
            [-93.5349, 31.2766],
            [-93.4426, 31.2767],
            [-93.3905, 31.3641],
            [-93.2361, 31.3645],
            [-93.0323, 31.3643],
            [-92.9815, 31.3466],
            [-92.8798, 31.3174],
            [-92.8297, 31.2523],
            [-92.8239, 30.89],
            [-92.9785, 30.8782],
            [-93.4211, 30.8831],
            [-93.5586, 30.8688],
            [-93.5547, 30.9092],
            [-93.5654, 30.9906],
            [-93.5247, 31.039],
            [-93.5525, 31.1854],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201830',
        NAME: 'Vernon Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3439275943,
        AWATER: 35153783,
        INTPTLAT: '+31.1105625',
        INTPTLON: '-093.1815202',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3473, 31.0005],
            [-90.2596, 31.0007],
            [-89.8359, 31.002],
            [-89.7281, 31.0023],
            [-89.8329, 30.7657],
            [-89.8364, 30.8043],
            [-89.8889, 30.8043],
            [-89.8798, 30.7524],
            [-89.8328, 30.7653],
            [-89.836, 30.7358],
            [-89.8423, 30.666],
            [-89.9942, 30.6654],
            [-90.2556, 30.7119],
            [-90.3012, 30.8508],
            [-90.3467, 30.9054],
            [-90.3473, 31.0005],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201860',
        NAME: 'Washington Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1709457398,
        AWATER: 16331686,
        INTPTLAT: '+30.8521435',
        INTPTLON: '-090.0462534',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.521, 33.0186],
            [-93.2386, 33.018],
            [-93.2374, 32.7162],
            [-93.1866, 32.7168],
            [-93.1788, 32.5849],
            [-93.1472, 32.4552],
            [-93.2367, 32.4101],
            [-93.3728, 32.4102],
            [-93.4412, 32.4104],
            [-93.4531, 32.8599],
            [-93.5308, 32.9678],
            [-93.521, 33.0186],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201890',
        NAME: 'Webster Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1535941083,
        AWATER: 56486633,
        INTPTLAT: '+32.7321522',
        INTPTLON: '-093.3398245',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3309, 30.6587],
            [-91.3101, 30.654],
            [-91.2977, 30.6495],
            [-91.3148, 30.5798],
            [-91.1972, 30.5071],
            [-91.2415, 30.3576],
            [-91.1421, 30.3233],
            [-91.3117, 30.3225],
            [-91.4851, 30.4971],
            [-91.4148, 30.5115],
            [-91.3309, 30.6587],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201920',
        NAME: 'West Baton Rouge Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498293891,
        AWATER: 29602238,
        INTPTLAT: '+30.4640516',
        INTPTLON: '-091.3098080',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4358, 33.0061],
            [-91.265, 33.0051],
            [-91.3355, 32.8661],
            [-91.3448, 32.7567],
            [-91.4453, 32.582],
            [-91.596, 32.5822],
            [-91.6376, 32.6673],
            [-91.5664, 32.8556],
            [-91.5115, 32.879],
            [-91.4358, 33.0061],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201950',
        NAME: 'West Carroll Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 931478638,
        AWATER: 2386193,
        INTPTLAT: '+32.7924797',
        INTPTLON: '-091.4519982',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.6625, 30.9704],
              [-91.6369, 30.9994],
              [-91.1761, 30.9991],
              [-91.2405, 30.7872],
              [-91.3101, 30.654],
              [-91.3309, 30.6587],
              [-91.3806, 30.7588],
              [-91.553, 30.7308],
              [-91.5156, 30.8614],
              [-91.6614, 30.8571],
              [-91.6625, 30.9704],
            ],
          ],
          [
            [
              [-91.7238, 31.0443],
              [-91.6583, 30.9944],
              [-91.7501, 31.0188],
              [-91.7238, 31.0443],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2201980',
        NAME: 'West Feliciana Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1044342212,
        AWATER: 58999603,
        INTPTLAT: '+30.8727010',
        INTPTLON: '-091.4210081',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9391, 32.1479],
            [-92.8147, 32.1469],
            [-92.3122, 32.1461],
            [-92.3126, 31.9274],
            [-92.3626, 31.7966],
            [-92.6192, 31.7971],
            [-92.62, 31.7096],
            [-92.9735, 31.7089],
            [-92.9422, 31.8543],
            [-92.883, 31.94],
            [-92.8851, 32.0116],
            [-92.9391, 32.1479],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2202010',
        NAME: 'Winn Parish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2460711982,
        AWATER: 17448075,
        INTPTLAT: '+31.9411871',
        INTPTLON: '-092.6412694',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0971, 30.6195],
            [-91.0943, 30.6426],
            [-90.9805, 30.5891],
            [-90.9892, 30.4658],
            [-91.1011, 30.5226],
            [-91.0971, 30.6195],
          ],
        ],
      },
      properties: {
        STATEFP: '22',
        SCSDLEA: '',
        GEOID: '2206840',
        NAME: 'Central Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165939231,
        AWATER: 707794,
        INTPTLAT: '+30.5711423',
        INTPTLON: '-091.0398276',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
  ],
};

import {
  getOrgListApi,
  createOrgApi,
  updateOrgApi,
  deleteOrgApi,
  getOrgListByUserApi,
} from '../api/organizations';
import types from '../types';

export const getOrgListByUser = (userId, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.ORG_LIST_PENDING });
    const data = await getOrgListByUserApi(userId, jwt);
    dispatch({
      type: types.reducerTypes.ORG_LIST_SUCCESS,
      payload: { results: data.map((d) => d.organization) },
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.ORG_LIST_ERROR, payload: 'Unexpected Error!' });
  }
};

export const getOrgList = (filters, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.ORG_LIST_PENDING });
    const data = await getOrgListApi(filters, jwt);
    dispatch({ type: types.reducerTypes.ORG_LIST_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.ORG_LIST_ERROR, payload: 'Unexpected Error!' });
  }
};
export const createOrg = (newOrg, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.ORG_SAVE_PENDING });
    await createOrgApi(newOrg, jwt);
    dispatch({ type: types.reducerTypes.ORG_SAVE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.ORG_SAVE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const updateOrg = (editOrg, jwt, userId) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.ORG_SAVE_PENDING });
    await updateOrgApi(editOrg, jwt, userId);
    dispatch({ type: types.reducerTypes.ORG_SAVE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.ORG_SAVE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const deleteOrg = (ids, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.ORG_DELETE_PENDING });
    await deleteOrgApi(ids, jwt);
    dispatch({ type: types.reducerTypes.ORG_DELETE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.ORG_DELETE_ERROR, payload: 'Unexpected Error!' });
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const FileWizardHeaderStep = ({
  stepNumber,
  stepTitle,
  isActive,
}) => (
  <div className={`FileWizardHeaderStep ${isActive ? 'isActive' : ''}`}>
    <div className="FileWizardHeaderStep-number">
      <span>
        { stepNumber }
      </span>
    </div>
    <div className="FileWizardHeaderStep-title">
      {stepTitle}
    </div>
  </div>
);

FileWizardHeaderStep.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTitle: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default FileWizardHeaderStep;

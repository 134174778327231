import {
  getSurveySampleByIdApi,
  getSurveySampleDimensionsByIdApi,
  updateSurveySampleByIdApi,
} from '../api/survey.wizard';
import types from '../types';

export const getSurveySampleById = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_PENDING });
    const data = await getSurveySampleByIdApi(id, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_ERROR, payload: e });
  }
};

export const getSurveySampleDimensionsById = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_PENDING });
    const data = await getSurveySampleDimensionsByIdApi(id, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_ERROR, payload: e });
  }
};

export const updateSurveySampleById = (id, data, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_PENDING });
    await updateSurveySampleByIdApi(id, data, jwt);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_ERROR, payload: e });
  }
};

/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  loading: false,
  dashboard: {},
  error: '',
  filters: [],
  visualizations: [],
  menuOptions: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.DASHBOARD_CLEAR_ALL:
      return initialState;
    case types.reducerTypes.DASHBOARD_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      };
    case types.reducerTypes.DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.DASHBOARD_FILTERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.DASHBOARD_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: action.payload,
      };
    case types.reducerTypes.DASHBOARD_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.DASHBOARD_VISUALIZATIONS_PENDING:
      return {
        ...state,
        loading: true,
        dashboard: {},
      };
    case types.reducerTypes.DASHBOARD_VISUALIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        visualizations: action.payload,
      };
    case types.reducerTypes.DASHBOARD_VISUALIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.DASHBOARD_MENU_OPTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.DASHBOARD_MENU_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        menuOptions: action.payload,
      };
    case types.reducerTypes.DASHBOARD_MENU_OPTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

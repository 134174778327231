import { take } from 'lodash';
import {
  getUserReportsApi,
  createReportApi,
  addToReportApi,
  shareReportApi,
  updateUserReportApi,
  deleteReportApi,
  deleteReportItemApi,
  getReportDetailsApi,
  getSharedReportDetailsApi,
  saveCopyApi,
} from '../api/report';
import types from '../types';

export const listUserReports = (
  jwt,
  orderBy,
  orderDirection,
  filterText,
  filterFav,
  originalCreator
) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_LIST_PENDING });
    const data = await getUserReportsApi(
      jwt,
      orderBy,
      orderDirection,
      filterText,
      filterFav,
      originalCreator
    );
    dispatch({ type: types.reducerTypes.REPORT_LIST_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.REPORT_LIST_ERROR, payload: 'Unexpected Error!' });
  }
};

export const getMostRecentReports = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_RECENT_LIST_PENDING });
    const data = await getUserReportsApi(jwt);
    dispatch({
      type: types.reducerTypes.REPORT_RECENT_LIST_SUCCESS,
      payload: take(
        data.sort((a, b) => b?.dateUpdated?.valueOf() - a?.dateUpdated?.valueOf()),
        4
      ),
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.REPORT_RECENT_LIST_ERROR, payload: 'Unexpected Error!' });
  }
};

export const addItemsToReport = (id, reportItems, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_ADD_PENDING });
    await addToReportApi(id, reportItems, jwt);
    dispatch({ type: types.reducerTypes.REPORT_ADD_SUCCESS, payload: id });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.REPORT_ADD_ERROR, payload: 'Unexpected Error!' });
  }
};

export const createReport = (report, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_CREATE_PENDING });
    await createReportApi(report, jwt);
    dispatch(listUserReports(jwt));
    dispatch({ type: types.reducerTypes.REPORT_CREATE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.REPORT_CREATE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const createAndAddToReport = (report, reportItems, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_CREATE_PENDING });
    const createData = await createReportApi(report, jwt);
    dispatch(addItemsToReport(createData.data.id, reportItems, jwt));
    dispatch(listUserReports(jwt));
    dispatch({ type: types.reducerTypes.REPORT_CREATE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.REPORT_CREATE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const shareReport = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_SHARE_PENDING });
    const data = await shareReportApi(id, jwt);
    dispatch({ type: types.reducerTypes.REPORT_SHARE_SUCCESS, payload: data.shareToken });
  } catch (e) {
    console.error(e);
    dispatch({ type: types.reducerTypes.REPORT_SHARE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const deleteReport = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_DELETE_PENDING });
    await deleteReportApi(id, jwt);
    dispatch({ type: types.reducerTypes.REPORT_DELETE_SUCCESS, payload: id });
  } catch (e) {
    console.error(e);
    dispatch({ type: types.reducerTypes.REPORT_DELETE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const hideShareToast = () => ({ type: types.reducerTypes.REPORT_SHARE_TOAST_HIDE });

export const getReportDetails = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_GET_PENDING });
    const data = await getReportDetailsApi(id, jwt);
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_GET_SUCCESS, payload: data });
  } catch (e) {
    console.error(e);
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_GET_ERROR, payload: 'Unexpected Error!' });
  }
};

export const getSharedReportDetails = (shareToken, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_GET_PENDING });
    const data = await getSharedReportDetailsApi(shareToken, jwt);
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_GET_SUCCESS, payload: data });
  } catch (e) {
    console.error(e);
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_GET_ERROR, payload: 'Unexpected Error!' });
  }
};

export const updateReportDetails = (report, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_UPDATE_PENDING });
    const data = await updateUserReportApi(report, jwt);
    dispatch({ type: types.reducerTypes.REPORT_DETAILS_UPDATE_SUCCESS, payload: data });
  } catch (e) {
    console.error(e);
    dispatch({
      type: types.reducerTypes.REPORT_DETAILS_UPDATE_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const deleteReportItem = (id, itemId, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_ITEM_DELETE_PENDING });
    await deleteReportItemApi(id, itemId, jwt);
    dispatch({ type: types.reducerTypes.REPORT_ITEM_DELETE_SUCCESS, payload: itemId });
  } catch (e) {
    console.error(e);
    dispatch({ type: types.reducerTypes.REPORT_ITEM_DELETE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const saveCopy = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.REPORT_COPY_PENDING });
    const data = await saveCopyApi(id, jwt);
    console.log(data);
    dispatch({ type: types.reducerTypes.REPORT_COPY_SUCCESS, payload: data });
  } catch (e) {
    console.error(e);
    dispatch({ type: types.reducerTypes.REPORT_COPY_ERROR, payload: 'Unexpected Error!' });
  }
};

import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CalculateMoeConfirmationModal = ({ show, onConfirm, onClose }) => {
  return (
    <Modal centered show={show} onHide={onClose} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              Calculating the margin of error may take up to <strong>30 seconds</strong>. Page will
              reload when the calculation is complete.
            </p>
            <p>Would you like to proceed with the calculation?</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="xl" onClick={onClose}>
          CANCEL
        </Button>
        <Button variant="dark" size="xl" onClick={onConfirm}>
          YES
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CalculateMoeConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CalculateMoeConfirmationModal;

import React from 'react';
import { useSelector } from 'react-redux';
import FileWizardHeaderStep from './FileWizardHeaderStep';
import './style.css';

const FileWizardHeader = () => {
  const fileWizard = useSelector((state) => state.fileWizard);
  const { steps, actualStep } = fileWizard;
  const entries = Object.entries(steps) || [];

  return (
    <div className="FileWizardHeader">
      { (entries.length > 0)
      && entries.map((el, i) => (
        <FileWizardHeaderStep
          stepTitle={el[1].title}
          stepNumber={i + 1}
          isActive={i === actualStep}
          key={el[1].id}
        />
      ))}
    </div>
  );
};


export default FileWizardHeader;

import { AK } from './AK';
import { AL } from './AL';
import { AR } from './AR';
import { AZ } from './AZ';
import { CA } from './CA';
import { CO } from './CO';
import { CT } from './CT';
import { DC } from './DC';
import { DE } from './DE';
import { FL } from './FL';
import { GA } from './GA';
import { HI } from './HI';
import { IA } from './IA';
import { ID } from './ID';
import { IL } from './IL';
import { IN } from './IN';
import { KS } from './KS';
import { KY } from './KY';
import { LA } from './LA';
import { MA } from './MA';
import { MD } from './MD';
import { ME } from './ME';
import { MI } from './MI';
import { MN } from './MN';
import { MO } from './MO';
import { MS } from './MS';
import { MT } from './MT';
import { NC } from './NC';
import { ND } from './ND';
import { NE } from './NE';
import { NH } from './NH';
import { NJ } from './NJ';
import { NM } from './NM';
import { NV } from './NV';
import { NY } from './NY';
import { OH } from './OH';
import { OK } from './OK';
import { OR } from './OR';
import { PA } from './PA';
import { RI } from './RI';
import { SC } from './SC';
import { SD } from './SD';
import { TN } from './TN';
import { TX } from './TX';
import { US } from './US';
import { UT } from './UT';
import { VA } from './VA';
import { VT } from './VT';
import { WA } from './WA';
import { WI } from './WI';
import { WV } from './WV';
import { WY } from './WY';

export const GEO_OBJ = {
  AK,
  AL,
  AR,
  AZ,
  CA,
  CO,
  CT,
  DC,
  DE,
  FL,
  GA,
  HI,
  IA,
  ID,
  IL,
  IN,
  KS,
  KY,
  LA,
  MA,
  MD,
  ME,
  MI,
  MN,
  MO,
  MS,
  MT,
  NC,
  ND,
  NE,
  NH,
  NJ,
  NM,
  NV,
  NY,
  OH,
  OK,
  OR,
  PA,
  RI,
  SC,
  SD,
  TN,
  TX,
  US,
  UT,
  VA,
  VT,
  WA,
  WI,
  WV,
  WY,
};

import React from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import { checkValidToken } from '../../actions/session.action';
import Layout from '../Layout';

const qs = require('query-string');

const PrivateRoute = ({ component: Component, adminRoute, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const returnUrl =
    location.pathname === '/'
      ? '/signup'
      : `/login?returnUrl=${location.pathname}${location.search}`;

  // 1. Look for a jwt on the store
  const storeJwt = useSelector((state) => state.session.jwt);
  if (storeJwt) {
    if (storeJwt !== 'error') {
      const jwtObj = jwtDecode(storeJwt);
      if (!adminRoute) {
        return (
          <Route
            {...rest}
            render={(props) => (
              <Layout {...props}>
                <Component {...props} />
              </Layout>
            )}
          />
        );
      }
      if (adminRoute && jwtObj.role === 'SUPER_ADMIN') {
        return (
          <Route
            {...rest}
            render={(props) => (
              <Layout {...props}>
                <Component {...props} />
              </Layout>
            )}
          />
        );
      }
    }
    history.replace(returnUrl);
  }

  // 2. If there is no jwt on the store, look it on the session
  const sessionJwt = sessionStorage.getItem('jwt');
  if (sessionJwt) {
    dispatch(checkValidToken(sessionJwt));
    return null;
  }

  // 3. If there is no jwt on the session, look it on url params.
  const params = qs.parse(location.search);
  const { jwt } = params;
  if (!jwt) {
    history.replace(returnUrl);
  } else {
    dispatch(checkValidToken(jwt));
    return null;
  }

  return <>Loading...</>;
};
PrivateRoute.defaultProps = {
  adminRoute: false,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  adminRoute: PropTypes.bool,
};

export default PrivateRoute;

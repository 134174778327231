import types from '../types';

const initialState = {
  activeElementId: undefined,
};

const shareALinkResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SHARE_A_LINK_BY_TOKEN_SUCCESS:
      return {
        ...initialState,
        activeElementId: action.payload.activeElementId,
      };
    default:
  }
  return state;
};

export default shareALinkResultReducer;

/* eslint-disable no-param-reassign */
import types from '../types';

import { cloneArrayOfObjects } from '../helpers/helpers';

const initialState = {
  visualizations: [],
  visualizationsFetchErr: false,
  isFetchingVisualizations: true,
  dashboardData: [],
  dashboardDataFetchErr: false,
  isFetchingDashboardData: false,
  selectedVisualization: {},
  existDashboardData: false,
};

const visualizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.GET_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: action.dashboardData || [],
        dashboardDataFetchErr: false,
        isFetchingDashboardData: false,
      };
    }

    case types.reducerTypes.GET_ALL_VISUALIZATIONS: {
      return {
        ...state,
        visualizations: action.visualizations || [],
      };
    }

    case types.reducerTypes.IS_FETCHING_VISUALIZATIONS: {
      const { isFetchingVisualizations } = action;
      return {
        ...state,
        isFetchingVisualizations,
      };
    }
    case types.reducerTypes.VISUALIZATIONS_COPY_CREATED: {
      const { Id: parentId } = action.updatedParent;
      const clonedVisualizationsArr = cloneArrayOfObjects(state.visualizations);
      const index = clonedVisualizationsArr.findIndex((element) => element.Id === parentId);
      clonedVisualizationsArr.splice(index, 1, action.updatedParent);
      return {
        ...state,
        visualizations: clonedVisualizationsArr,
      };
    }
    case types.reducerTypes.VISUALIZATION_COPY_CONVERTED: {
      const { Parent } = action;
      const clonedVisualizationsArr = cloneArrayOfObjects(state.visualizations);
      const index = clonedVisualizationsArr.findIndex((element) => element.Id === Parent);
      clonedVisualizationsArr.splice(index, 1, action.updatedVisualization);
      return {
        ...state,
        visualizations: clonedVisualizationsArr,
      };
    }
    case types.reducerTypes.VISUALIZATION_DELETED: {
      const { Id: parentId } = action.updatedParent;
      const clonedVisualizationsArr = cloneArrayOfObjects(state.visualizations);
      const index = clonedVisualizationsArr.findIndex((element) => element.Id === parentId);
      clonedVisualizationsArr.splice(index, 1, action.updatedParent);
      return {
        ...state,
        visualizations: clonedVisualizationsArr,
      };
    }

    case types.reducerTypes.SHOW_VISUALIZATION_SNACKBAR: {
      const { id, snackBarType } = action;
      const clonedVisualizationsArr = cloneArrayOfObjects(state.visualizations);

      const addSnackbar = (arr = [], elementId) => {
        arr.forEach((element) => {
          if (element.Id === elementId) {
            element.Data.snackBar = snackBarType;
          }
          if (element.Drafts) addSnackbar(element.Drafts, elementId);
        });
      };

      addSnackbar(clonedVisualizationsArr, id);
      return {
        ...state,
        visualizations: clonedVisualizationsArr,
      };
    }

    case types.reducerTypes.HIDE_VISUALIZATION_SNACKBAR: {
      const { id } = action;
      const clonedVisualizationsArr = cloneArrayOfObjects(state.visualizations);

      const hideSnackbar = (arr = [], elementId) => {
        arr.forEach((element) => {
          if (element.Id === elementId) {
            element.Data.snackBar = null;
          }
          if (element.Drafts) hideSnackbar(element.Drafts, elementId);
        });
      };

      hideSnackbar(clonedVisualizationsArr, id);

      return {
        ...state,
        visualizations: clonedVisualizationsArr,
      };
    }

    case types.reducerTypes.VISUALIZATION_ERROR: {
      return {
        ...state,
      };
    }

    case types.reducerTypes.SELECTED_VISUALIZATION: {
      const { Id } = action.payload;
      const selectedVisualization = {
        visualizationId: Id,
      };
      return {
        ...state,
        selectedVisualization,
      };
    }

    case types.reducerTypes.EXIST_DASHBOARD_DATA: {
      return {
        ...state,
        existDashboardData: action.res,
      };
    }

    case types.reducerTypes.VISUALIZATION_SPINNER: {
      const clonedSurveysArr = cloneArrayOfObjects(state.visualizations);
      const { showSpinner, spinnerActionType, spinnerId } = action.payload;

      const addSpinner = (arr = [], elementId) => {
        arr.forEach((element) => {
          if (element.Id === elementId) {
            if (showSpinner) {
              element.Data.spinner = spinnerActionType;
            } else {
              element.Data.spinner = undefined;
            }
          }
          if (element.Drafts) addSpinner(element.Drafts, elementId);
        });
      };

      addSpinner(clonedSurveysArr, spinnerId);
      return {
        ...state,
        visualizations: clonedSurveysArr,
      };
    }

    case types.reducerTypes.IS_FETCHING_DASHBOARDDATA: {
      const { isFetchingDashboardData } = action;
      return {
        ...state,
        isFetchingDashboardData,
      };
    }
    default:
  }
  return state;
};
export default visualizationReducer;

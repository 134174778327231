import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { convertDraftIntoSurvey, deleteDraftSurvey } from '../../actions/survey.action';
import types from '../../types';
import Button from '../Button';

import { convertCopyIntoVisualization } from '../../actions/visualization.action';

import { isImportingToBento } from '../../actions/bento.action';

import { removeFiles } from '../../actions/wizard.action';

const MessageModal = ({ modalProps }) => {
  const {
    title,
    spinner,
    body,
    imageSrc,
    imageAlt,
    caption,
    button1Text,
    button1Action,
    button2Text,
    button2Action,
  } = modalProps;
  const selectedSurvey = useSelector((state) => state.wizard.selectedSurvey);
  const selectedVisualization = useSelector((state) => state.visualizations.selectedVisualization);
  const jwt = useSelector((state) => state.session.jwt);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleButtonClick = (action) => {
    switch (action) {
      case 'closeModal': {
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'resetAndCloseModal': {
        dispatch(isImportingToBento(false));
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'removeFile': {
        dispatch(removeFiles());
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'goToConfigureSurvey': {
        const { surveyId } = selectedSurvey;
        history.push(`/admin/survey/edit/${surveyId}`);
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'goToAllSurveys': {
        const { surveyId } = selectedSurvey;
        history.push(`/surveys/${surveyId}`);
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'goToDashboard': {
        history.push('/admin/dashboard');
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'goToDataSourceManager': {
        history.push('/admin/dashboard/manager');
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'convertDraftToMain': {
        const { surveyId } = selectedSurvey;
        dispatch(convertDraftIntoSurvey(surveyId, jwt));
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'deleteDraftSurvey': {
        const { surveyId, isDraft, parentId } = selectedSurvey;
        dispatch(deleteDraftSurvey(surveyId, isDraft, parentId, jwt));
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      case 'convertVisualizationIntoMain': {
        const { visualizationId } = selectedVisualization;
        dispatch(convertCopyIntoVisualization(visualizationId, jwt));
        dispatch({ type: types.reducerTypes.HIDE_MODAL });
        break;
      }
      default:
    }
  };

  return (
    <>
      <h4 className="CustomModal-title">
        {spinner && (
          <svg
            className="spinner"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 1000 1000"
          >
            <path d="M500 964.1c-130.9 0-253.9-51-346.5-143.5S10 605 10 474.1c0-92.7 26-182.9 75.2-261C133.1 137.3 200.7 76 280.8 35.9L322 118c-65.1 32.6-120.1 82.5-159 144.2-39.9 63.4-61.1 136.7-61.1 212 0 219.5 178.6 398.1 398.1 398.1 219.5 0 398.1-178.6 398.1-398.1 0-75.3-21.1-148.6-61.1-212-38.9-61.7-93.9-111.6-159-144.2l41.2-82.1C799.3 76 866.9 137.3 914.8 213.2c49.2 78 75.2 168.3 75.2 261 0 130.9-51 253.9-143.5 346.5-92.6 92.5-215.6 143.4-346.5 143.4z" />
          </svg>
        )}
        {title}
      </h4>

      {body && <p>{body}</p>}

      {imageSrc && (
        <div className="CustomModal-img-container">
          <img src={imageSrc} alt={imageAlt} />
        </div>
      )}

      {caption && <p className="CustomModal-caption">{caption}</p>}

      {button1Text && !button2Text && (
        <div className="CustomModal-buttons-container single-button">
          <Button
            onClick={() => handleButtonClick(button1Action)}
            theme="dark"
            size="md"
            buttonText={button1Text}
          />
        </div>
      )}

      {button2Text && (
        <div className="CustomModal-buttons-container double-button">
          <Button
            onClick={() => handleButtonClick(button1Action)}
            theme="dark"
            size="md"
            buttonText={button1Text}
          />
          <Button
            onClick={() => handleButtonClick(button2Action)}
            theme="dark"
            size="md"
            buttonText={button2Text}
          />
        </div>
      )}
    </>
  );
};

MessageModal.propTypes = {
  modalProps: PropTypes.shape({
    title: PropTypes.string,
    spinner: PropTypes.bool,
    body: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    caption: PropTypes.string,
    button1Text: PropTypes.string,
    button1Action: PropTypes.string,
    button2Text: PropTypes.string,
    button2Action: PropTypes.string,
  }).isRequired,
};

export default MessageModal;

import React from 'react';
import {
  Modal,
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Button,
  ButtonGroup,
  Image,
  ButtonToolbar,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import types from '../../types';

import { exportFile } from '../../actions/export.action';

import './style.css';

// eslint-disable-next-line react/prop-types
const ExportModal = ({ onExport = () => {} }) => {
  const storeJwt = useSelector((state) => state.session.jwt);
  const show = useSelector((state) => state.exportFile.modalProps.open);
  const exportItems = useSelector((state) => state.exportFile.exportItems);
  // The following line is needed for Google Analytics, for Reports
  const { id: reportId } = useParams();
  const dispatch = useDispatch();

  function handleExport(format) {
    dispatch(exportFile(format, exportItems, storeJwt));
    onExport({
      reportId,
      items: exportItems,
      surveySectionCode: exportItems[0].config?.activeSectionId,
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={() => dispatch({ type: types.reducerTypes.EXPORT_MODAL_HIDE })}
      className="export-modal"
      backdrop="static"
    >
      <Modal.Header className="export-modal-title" closeButton>
        {' '}
        Select a format to export
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md="4">
              <div className="export-modal-legend"> Visualization Data</div>
              <ButtonGroup>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">CSV format</Tooltip>}>
                  <Button
                    variant="light"
                    className="unstyled-button"
                    onClick={() => handleExport('CSV')}
                  >
                    <Image src="/CSV.svg" rounded />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Col>
            <Col md="8">
              <div className="export-modal-legend"> Visualization</div>
              <ButtonToolbar>
                <ButtonGroup className="mr-2">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">DOC format</Tooltip>}>
                    <Button
                      variant="light"
                      className="unstyled-button"
                      onClick={() => handleExport('DOCX')}
                    >
                      <Image src="/DOC.svg" rounded />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
                <ButtonGroup className="mr-2">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">PDF format</Tooltip>}>
                    <Button
                      variant="light"
                      className="unstyled-button"
                      onClick={() => handleExport('PDF')}
                    >
                      <Image src="/PDF.svg" rounded />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default ExportModal;

import types from '../types';

const initialState = {
  sample: undefined,
  dimensions: undefined,
  loading: false,
  error: '',
};

const surveyWizard = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SURVEY_SAMPLE_GET_PENDING: {
      return {
        ...state,
        sample: undefined,
        dimensions: undefined,
        loading: true,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_GET_SUCCESS: {
      return {
        ...state,
        sample: action.payload,
        loading: false,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_GET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_PENDING: {
      return {
        ...state,
        dimensions: undefined,
        loading: true,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_SUCCESS: {
      return {
        ...state,
        dimensions: [...action.payload],
        loading: false,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default surveyWizard;

import React from 'react';

import OrgList from './components/OrgList';
import SectionTitle from '../../Components/SectionTitle';

const ManageOrganizations = () => (
  <>
    <SectionTitle title="Manage Organizations" />
    <OrgList />
  </>
);

export default ManageOrganizations;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Toast } from 'react-bootstrap';

import types from '../../types';
import './style.css';

const ReportToast = () => {
  const show = useSelector((state) => state.reports.showToast);
  const redirectToReport = useSelector((state) => state.reports.redirectToReport);
  const dispatch = useDispatch();

  return (
    <Toast
      className="ReportToastPosition"
      onClose={() => dispatch({ type: types.reducerTypes.REPORT_TOAST_HIDE })}
      show={show}
      delay={10000}
      autohide
    >
      <Toast.Header>
        <strong className="mr-auto">Added to Report</strong>
      </Toast.Header>
      <Toast.Body>
        <Button
          href={`/reports/${redirectToReport}`}
          variant="primary"
          type="button"
          className="btn btn-custom dark px-5"
        >
          GO TO REPORT
        </Button>
      </Toast.Body>
    </Toast>
  );
};

export default ReportToast;

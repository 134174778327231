import axios from 'axios';
import types from '../types';
import { API_ROOT } from '../config/appConfig';
import { openModal } from './modal.action';
import modalMessages from '../messages/modal.messages';
import { getHeaders } from './helper.action';

export const isImportingToBento = (isImporting) => (dispatch) => {
  dispatch({ type: types.reducerTypes.IMPORT_BENTO_PROGRESS, isImporting });
};

export const importToBento = (data, jwt, type) => (dispatch) => {
  let route = '';
  switch (type) {
    case types.modelTypes.sampleMetadata: {
      route = `${API_ROOT}/admin-backend/lambda/import`;
      break;
    }
    case types.modelTypes.dashboardMetadata: {
      route = `${API_ROOT}/admin-backend/lambda/dashboardImport`;
      break;
    }
    default:
  }
  try {
    dispatch(isImportingToBento(true));
    axios.post(route, data, getHeaders('application/json', jwt));
    dispatch(openModal(modalMessages('importBentoProgress')));
  } catch (err) {
    // Error handled on socket response
    console.error(err);
  }
};

export const replaceMetadata = (data, jwt, replaceSurveyId) => (dispatch) => {
  try {
    dispatch(isImportingToBento(true));
    axios.post(
      `${API_ROOT}/admin-backend/lambda/replace/${replaceSurveyId}`,
      data,
      getHeaders('application/json', jwt)
    );
    dispatch(openModal(modalMessages('importBentoProgress')));
  } catch (err) {
    // Error handled on socket response
    console.error(err);
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const RadioRow = ({
  title,
  description,
  years,
  labels,
  date,
  id,
  onChangeHandler,
  checked,
  name,
}) => (
  <div className="RadioRow">
    <div className="RadioRow-data">
      <div className="RadioRow-radio">
        <label className="RadioRow-label" htmlFor={`id-${id}`}>
          <input
            type="checkbox"
            name={name}
            id={`id-${id}`}
            onChange={onChangeHandler}
            data-id={id}
            value={id}
            checked={checked}
          />
        </label>
      </div>
      <div className="RadioRow-name cell-padding">
        { title }
      </div>
      <div className="RadioRow-description cell-padding">
        { description }
      </div>
      <div className="RadioRow-years cell-padding">
        {years}
      </div>
      <div className="RadioRow-labels cell-padding">
        {labels}
      </div>
      <div className="RadioRow-date cell-padding">
        {date}
      </div>
    </div>
  </div>
);

RadioRow.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  years: PropTypes.string,
  date: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.arrayOf(
      PropTypes.string,
    ),
  ]),
  checked: PropTypes.bool,
};

RadioRow.defaultProps = {
  checked: false,
  labels: 'No labels were provided',
  years: 'No years were provided',
  description: 'No description was provided',
};

export default RadioRow;

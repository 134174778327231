import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MetadataContainer from '../Inputs/MetadataContainer';
import SamplesContainer from '../Inputs/SamplesContainer';
import ChartsConfig from '../Inputs/ChartsConfig';
import { updateChart } from '../../../actions/fileWizard.action';
import Button from '../../Button';
import './style.css';

const FileWizardForm = ({ nextStep, prevStep }) => {
  const fileWizard = useSelector((state) => ({
    actualStep: state.fileWizard.actualStep,
    steps: state.fileWizard.steps,
  }));
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt);
  let actualStep;

  if (fileWizard && fileWizard.steps) {
    actualStep = fileWizard.steps[`step${fileWizard.actualStep}`];
  }

  const renderComponent = (step = {}) => {
    const { component } = step;
    switch (component) {
      case 'radioContainer':
        return <MetadataContainer action={step.action} />;
      case 'checkboxContainer':
        return <SamplesContainer action={step.action} />;
      case 'chartsConfig':
        return <ChartsConfig chart={step.chart} action={step.action} />;
      default:
        return null;
    }
  };

  const renderButtons = (step = {}) => {
    const buttonActions = (buttonAction) => {
      switch (buttonAction) {
        case 'next':
          return nextStep;
        case 'prevStep':
          return prevStep;
        case 'confirm':
          return () => {
            dispatch(updateChart(jwt));
          };
        default:
          return () => {};
      }
    };
    const { buttons } = step;
    if (buttons && buttons.length > 0) {
      return buttons.map((el) => (
        <Button
          buttonText={el.buttonText}
          onClick={buttonActions(el.buttonAction)}
          theme="dark"
          size="xl p-xl"
          weight="regular"
          key={el.id}
        />
      ));
    }
    return null;
  };

  return (
    <>
      <form action="">
        {renderComponent(actualStep)}
        <div className="SelectFileWizard-buttonContainer">{renderButtons(actualStep)}</div>
      </form>
    </>
  );
};

FileWizardForm.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
};

FileWizardForm.defaultProps = {
  prevStep: () => {},
  nextStep: () => {},
};

export default FileWizardForm;

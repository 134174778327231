import io from 'socket.io-client';
import { SOCKET_ROOT } from './config/appConfig';

class SocketClient {
  constructor() {
    this.url = SOCKET_ROOT;
    this.socket = {};
    this.connect.bind(this);
    this.close.bind(this);
    this.emit.bind(this);
    this.on.bind(this);
    this.off.bind(this);
    this.eventLogger.bind(this);
    this.getSocket.bind(this);
  }

  connect(token) {
    this.socket = io(this.url, {
      path: '/admin-backend/socket.io',
      query: {
        token,
      },
      forceNew: true,
      transports: ['polling', 'websocket'],
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: Infinity,
    });
  }

  close() {
    this.socket.close();
  }

  getSocket() {
    return this.socket;
  }

  emit(emitUrl, data) {
    this.socket.emit(emitUrl, data);
  }

  on(event, cb) {
    this.socket.on(event, this.eventLogger(event, cb));
  }

  off(event) {
    this.socket.off(event);
  }

  removeListener(event) {
    this.socket.removeListener(event);
  }

  eventLogger(event, cb) {
    return (...args) => {
      if (typeof cb === 'function') cb.apply(this, args);
    };
  }
}

export default new SocketClient();

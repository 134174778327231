/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFileWizard, resetFileWizard } from '../../actions/fileWizard.action';
import { resetErrors } from '../../actions/errors.action';
import FileWizardStepper from './FileWizardStepper';
import FileWizardHeader from './FileWizardHeader';
import { openModal } from '../../actions/modal.action';
import modalMessages from '../../messages/modal.messages';

import './style.css';

const FileWizard = ({ visualizationId }) => {
  const dispatch = useDispatch();
  const getAllMetadatasErr = useSelector((state) => state.errors.getAllMetadatasErr);
  const updateChartErr = useSelector((state) => state.errors.updateChartErr);
  const updateChartSuccess = useSelector((state) => state.fileWizard.updateChartSuccess);
  const history = useHistory();

  useEffect(() => {
    dispatch(setFileWizard(visualizationId));
    return () => {
      dispatch(resetFileWizard());
      dispatch(resetErrors());
    };
  }, []);

  if (getAllMetadatasErr) {
    dispatch(openModal(modalMessages('getAllMetadatasErr')));
    return null;
  }

  if (updateChartSuccess) {
    history.push('/admin/dashboard');
  }

  if (updateChartErr) {
    dispatch(openModal(modalMessages('updateChartErr')));
  }

  return (
    <div className="FileWizard">
      <FileWizardHeader />
      <FileWizardStepper />
    </div>
  );
};

FileWizard.propTypes = {
  visualizationId: PropTypes.number.isRequired,
};

export default FileWizard;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import { Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {
  calculateChartHeight,
  makeMLITooltip,
  regroupForHeatmap,
  rgb2hex,
} from '../../../helpers/charts';
import { HIGHCHARTS_TYPES, HIGHCHARTS_TOOLTIP_DELAY } from '../../../constants/charts';

const HeatMapChart = forwardRef(
  ({ parsedData, benchmarks = {}, qtyOptions, chartLoaded, currentJob = {} }, chartRef) => {
    const [chartOptions, setChartOptions] = useState([]);
    const [loadingChart, setLoadingChart] = useState(true);

    const getTextHeight = (offset_width, font_size, seg_text, font_constant = 2.7) => {
      const line_height = 18;
      const num_chars_per_line = offset_width / (font_size * font_constant);
      const num_lines = Math.ceil(seg_text.length / num_chars_per_line);
      const seg_text_height = num_lines * line_height;

      return seg_text_height;
    };

    const getSegmentTextStart = (seg_text, font_size, offset_height, offset_width) => {
      const seg_text_height = getTextHeight(offset_width, font_size, seg_text);
      const text_start_height = Math.floor((offset_height - seg_text_height) / 2);

      return text_start_height;
    };

    function dataLabelsFormatter() {
      const suffix = currentJob.questionType === 'OPEN_NUMERIC' ? '' : '%';
      return `<div>${this.point.value}${suffix}</div>`;
    }

    function tooltipFormatter() {
      let text_color;
      let text;
      let percent_diff;
      const benchmark = this.point.benchmark_value;
      let benchmark_label = this.point.benchmark_label;
      const this_category = this.series.yAxis.categories[this.point.y];
      let nat_category = false;
      const { filtered, segmented, benchmarked } = this.point;
      const moe = this.point.MOE;

      if (segmented) {
        if (benchmarked) {
          if (this_category.includes('National')) {
            nat_category = true;
          } else if (!this_category.includes('State') && !filtered) {
            nat_category = true;
          }
        } else if (
          (this_category.includes('All respondents') && !filtered) ||
          (this_category.includes('deafult') && !filtered)
        ) {
          nat_category = true;
        }
      } else if (!filtered) {
        nat_category = true;
      }

      if (!nat_category) {
        percent_diff = Math.round(this.point.value - benchmark);

        if (percent_diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          percent_diff = Math.abs(percent_diff);
        }
      } else {
        percent_diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
        benchmark_label = '';
      }
      return makeMLITooltip(
        this.point.cat_name,
        this.point.value,
        percent_diff,
        moe,
        text,
        benchmark_label,
        rgb2hex(this.point.color),
        text_color,
        false,
        true,
        '',
        currentJob.questionType === 'OPEN_NUMERIC' ? '' : '%'
      );
    }

    const buildChart = (segment_id) => {
      // Build the chart
      const color_min = ['#FFE8E8', '#FFF2C6', '#D3D2DF', '#C6DCD1'];
      const color_max = ['#E43333', '#9A601F', '#302954', '#224E48'];

      let series_data;
      let xaxis_categories;
      let yaxis_categories = [];
      let margin_left;
      let spacing_left = 10;

      let benchmarks_active = false;
      let xaxis_enabled = true;

      if (parsedData.segmentsActive && (benchmarks.state || benchmarks.national)) {
        benchmarks_active = true;
        yaxis_categories = parsedData.segmentedSeries[1][segment_id].reverse();
      } else if (parsedData.segmentsActive) {
        yaxis_categories = parsedData.segmentNames.reverse();
      } else {
        yaxis_categories = parsedData.categories.reverse();
      }

      if (!benchmarks_active && parsedData.segmentsActive) {
        margin_left = 300;
        spacing_left = 190;
      } else {
        margin_left = 240;
      }

      // If it's segmented chart get segmented series
      if (parsedData.segmentsActive) {
        series_data = regroupForHeatmap(
          parsedData.segmentedSeries[0][segment_id],
          parsedData.filtered,
          true,
          benchmarks_active
        );

        xaxis_categories = series_data[1];
        xaxis_enabled = segment_id === 0;

        // Otherwise get just the parsed data for non-benchmarked data
      } else {
        series_data = regroupForHeatmap(
          parsedData.dataSeries[0],
          parsedData.filtered,
          false,
          benchmarks_active
        );
        xaxis_categories = series_data[1];
      }

      // This is a default theme for the MLI bar chart
      // These options can be overwritten when creating the chart and passing other options
      const chartHeight = calculateChartHeight(qtyOptions);
      const default_theme = {
        segment_id,
        chart: {
          events: {
            load() {
              const isBenchmarkActive = benchmarks.state || benchmarks.national;
              if (!isBenchmarkActive && typeof segment_id !== 'undefined' && segment_id !== null) {
                const seg_text = parsedData.categories[segment_id];
                const font_size = 14;
                const text_start_height = getSegmentTextStart(
                  seg_text,
                  font_size,
                  this.container.offsetHeight,
                  this.container.offsetWidth
                );
                const path_length = this.container.offsetHeight;
                this.renderer
                  .label(seg_text, 0, text_start_height, undefined, undefined, undefined, true)
                  .css({
                    width: '170px',
                    textAlign: 'end',
                    color: '#121111',
                    fontSize: `${font_size}px`,
                  })
                  .add();

                const linePosition = 180;
                // Draw a line at the edge of margin
                this.renderer
                  .path(['M', linePosition, 5, 'L', linePosition, path_length])
                  .attr({
                    'stroke-width': 0.6,
                    stroke: '#121111',
                  })
                  .add();
              }
              chartLoaded();
            },
          },
          height: chartHeight < 400 ? 400 : chartHeight,
          type: HIGHCHARTS_TYPES.HEATMAP,
          // backgroundColor option is static
          backgroundColor: null,
          marginLeft: margin_left,
          spacingLeft: spacing_left,

          // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
        },

        tooltip: {
          hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
          backgroundColor: '#ffffff',
          style: {
            color: '#343434',
            fontWeight: 'normal',
          },
          useHTML: true,
          padding: 0,
          formatter: tooltipFormatter,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              rotation: 0,
              shadow: false,
              style: {
                textOutline: false,
                fontSize: '14px',
              },
              shape: 'square',
              formatter: dataLabelsFormatter,
              useHTML: false,
            },
          },
        },

        title: {
          text: null,
        },

        // xAxis formatting will be static
        xAxis: {
          categories: xaxis_categories,
          opposite: true,
          lineWidth: 0,
          lineColor: '#f7f7f7',
          gridLineColor: '#f7f7f7',
          gridLineWidth: 0,
          minorGridLineColor: '#f7f7f7',
          minorGridLineWidth: 0,
          minorTickColor: '#f7f7f7',
          minorTickLength: 0,
          minorTickWidth: 0,
          tickColor: '#f7f7f7',
          tickLength: 0,
          tickWidth: 0,

          labels: {
            enabled: xaxis_enabled,
            format: '{value}',
            style: {
              color: '#121111',
              fontSize: '14px',
            },
          },

          title: {
            enabled: xaxis_enabled,
            text: null,
            align: 'low',
            offset: 10,
            x: -60,
            style: {
              color: '#121111',
              fontSize: '14px',
            },
          },
        },
        credits: { enabled: false },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },

        // yAxis formatting will be static
        yAxis: {
          categories: yaxis_categories,
          title: {
            text: '',
          },
          gridLineColor: '#f7f7f7',
          labels: {
            style: {
              color: '#121111',
              fontSize: '14px',
            },
            align: 'right',
            x: -20,
            y: null,
          },
          lineColor: '#f7f7f7',
          lineWidth: 0,
          tickColor: '#f7f7f7',
        },
        colorAxis: {
          min: 0,
          max: 100,
          minColor: color_min[0],
          maxColor: color_max[0],
        },
        legend: {
          enabled: false,
        },
      };

      const chartSeries = [
        {
          name: '',
          data: series_data[0],
          colsize: 1,
          rowsize: 0.5,
          padding: [0, 0, 0, 0],
        },
      ];
      return {
        ...default_theme,
        series: chartSeries,
      };
    };

    useEffect(() => {
      const chartList = [];
      if (parsedData.segmentsActive) {
        for (let i = 0; i < parsedData.categories.length; i += 1) {
          if (
            !parsedData.suppressed ||
            parsedData.suppressed === null ||
            !parsedData.suppressed[i]
          ) {
            const c = buildChart(i);
            chartList.push(c);
          } else {
            chartList.push({ suppressed: true });
          }
        }
      } else if (
        !parsedData.suppressed ||
        parsedData.suppressed === null ||
        !parsedData.suppressed[0]
      ) {
        const c = buildChart();
        chartList.push(c);
      } else {
        chartList.push({ suppressed: true });
        chartLoaded();
      }
      setChartOptions(chartList);
      setLoadingChart(false);
    }, [parsedData]);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    return chartOptions.map((co) => {
      if (co.suppressed) {
        return (
          <Alert className="m-3" variant="warning" key={shortid.generate()}>
            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
            visualization suppressed
          </Alert>
        );
      }
      return (
        <HighchartsReact
          key={shortid.generate()}
          highcharts={Highcharts}
          options={co}
          ref={chartRef}
        />
      );
    });
  }
);

HeatMapChart.displayName = 'HeatMapChart';

export default React.memo(HeatMapChart);

export const KS = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4794, 37.4747],
            [-100.3701, 37.4606],
            [-100.1819, 37.2409],
            [-100.1985, 37.0581],
            [-100.2161, 37.002],
            [-100.5522, 37.0007],
            [-100.4981, 37.1333],
            [-100.4794, 37.4747],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009420',
        NAME: 'Meade Unified School District 226',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1168542921,
        AWATER: 1817744,
        INTPTLAT: '+37.1834163',
        INTPTLON: '-100.3631533',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3701, 39.5661],
            [-96.2391, 39.5807],
            [-96.0141, 39.5661],
            [-95.9795, 39.3907],
            [-96.1279, 39.3907],
            [-96.1839, 39.3475],
            [-96.2771, 39.3625],
            [-96.2767, 39.4352],
            [-96.3795, 39.4788],
            [-96.3701, 39.5661],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010170',
        NAME: 'Onaga-Havensville-Wheaton Unified School District 322',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663145107,
        AWATER: 1967591,
        INTPTLAT: '+39.4759010',
        INTPTLON: '-096.1736954',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9233, 39.3904],
            [-95.9047, 39.3904],
            [-95.6731, 39.3972],
            [-95.6266, 39.3176],
            [-95.589, 39.2739],
            [-95.6361, 39.2165],
            [-95.7664, 39.2165],
            [-95.8492, 39.2165],
            [-95.8497, 39.2894],
            [-95.9233, 39.3904],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009320',
        NAME: 'Royal Valley Unified School District 337',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411907864,
        AWATER: 186146,
        INTPTLAT: '+39.2963844',
        INTPTLON: '-095.7512737',
        ELSDLEA: '',
        UNSDLEA: '09320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6361, 39.2165],
            [-95.589, 39.2739],
            [-95.4501, 39.274],
            [-95.4501, 39.1503],
            [-95.5867, 39.1172],
            [-95.6361, 39.2165],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009510',
        NAME: 'Jefferson West Unified School District 340',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188437650,
        AWATER: 13575019,
        INTPTLAT: '+39.1991700',
        INTPTLON: '-095.5451070',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9733, 39.2883],
            [-94.8577, 39.2741],
            [-94.8379, 39.2624],
            [-94.8262, 39.2448],
            [-94.7891, 39.2078],
            [-94.7891, 39.2012],
            [-94.9005, 39.1869],
            [-94.9932, 39.2013],
            [-95.0404, 39.2668],
            [-94.9733, 39.2883],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008340',
        NAME: 'Lansing Unified School District 469',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129442210,
        AWATER: 1824152,
        INTPTLAT: '+39.2396954',
        INTPTLON: '-094.9243061',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9634, 39.5969],
            [-98.9354, 39.568],
            [-98.7294, 39.5676],
            [-98.617, 39.6254],
            [-98.6563, 39.4809],
            [-98.582, 39.4666],
            [-98.5924, 39.2776],
            [-98.5274, 39.2271],
            [-98.6202, 39.1768],
            [-98.6759, 39.2345],
            [-98.7509, 39.2204],
            [-98.8801, 39.3433],
            [-99.0461, 39.3648],
            [-99.0217, 39.4807],
            [-99.0662, 39.5681],
            [-98.9634, 39.5969],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010290',
        NAME: 'Osborne County Unified School District 392',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1314501975,
        AWATER: 2863646,
        INTPTLAT: '+39.4081912',
        INTPTLON: '-098.7878417',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6016, 39.481],
            [-99.5281, 39.481],
            [-99.4623, 39.5675],
            [-99.4027, 39.5673],
            [-99.2344, 39.5674],
            [-99.0662, 39.5681],
            [-99.0217, 39.4807],
            [-99.0461, 39.3648],
            [-99.1039, 39.2928],
            [-99.1397, 39.3297],
            [-99.3999, 39.3216],
            [-99.4922, 39.3865],
            [-99.6025, 39.3943],
            [-99.6016, 39.481],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012060',
        NAME: 'Stockton Unified School District 271',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1135003108,
        AWATER: 9594274,
        INTPTLAT: '+39.4454509',
        INTPTLON: '-099.2802959',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0498, 39.5682],
            [-101.6386, 39.5685],
            [-101.5106, 39.5684],
            [-101.4654, 39.4102],
            [-101.4848, 39.1348],
            [-102.0472, 39.1331],
            [-102.0498, 39.5682],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006580',
        NAME: 'Goodland Unified School District 352',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2353188093,
        AWATER: 548248,
        INTPTLAT: '+39.3462930',
        INTPTLON: '-101.7738545',
        ELSDLEA: '',
        UNSDLEA: '06580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5106, 39.5684],
            [-101.3289, 39.5683],
            [-101.2635, 39.6045],
            [-101.2267, 39.5683],
            [-101.2222, 39.4824],
            [-101.2229, 39.366],
            [-101.1669, 39.3595],
            [-101.2228, 39.3599],
            [-101.2236, 39.2218],
            [-101.2981, 39.2298],
            [-101.3917, 39.1351],
            [-101.4782, 39.1348],
            [-101.4848, 39.1348],
            [-101.4654, 39.4102],
            [-101.5106, 39.5684],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004080',
        NAME: 'Brewster Unified School District 314',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 974817452,
        AWATER: 24166,
        INTPTLAT: '+39.3744003',
        INTPTLON: '-101.3515660',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2472, 38.6982],
            [-100.1538, 38.6973],
            [-100.0616, 38.6965],
            [-99.7829, 38.7403],
            [-99.708, 38.7838],
            [-99.672, 38.7257],
            [-99.6602, 38.5385],
            [-99.5863, 38.5384],
            [-99.5848, 38.3494],
            [-99.5842, 38.2622],
            [-99.8048, 38.2619],
            [-99.8076, 38.5455],
            [-100.0654, 38.5373],
            [-100.1023, 38.639],
            [-100.2486, 38.6394],
            [-100.2472, 38.6982],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000020',
        NAME: 'Western Plains Unified School District 106',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1535674169,
        AWATER: 2388576,
        INTPTLAT: '+38.5483917',
        INTPTLON: '-099.8219371',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4277, 38.8261],
            [-95.2712, 38.8333],
            [-95.1957, 38.8918],
            [-95.1587, 38.8843],
            [-95.0561, 38.8624],
            [-95.0562, 38.8114],
            [-95.1306, 38.7386],
            [-95.2325, 38.7311],
            [-95.2696, 38.7313],
            [-95.3993, 38.7389],
            [-95.4277, 38.8261],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003720',
        NAME: 'Baldwin City Unified School District 348',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360102821,
        AWATER: 3359779,
        INTPTLAT: '+38.8042661',
        INTPTLON: '-095.2326696',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.608, 38.6953],
            [-95.5193, 38.7389],
            [-95.3993, 38.7389],
            [-95.2696, 38.7313],
            [-95.2674, 38.6879],
            [-95.39, 38.5649],
            [-95.3184, 38.5136],
            [-95.3602, 38.4193],
            [-95.5083, 38.3903],
            [-95.5268, 38.434],
            [-95.4903, 38.5834],
            [-95.5458, 38.6374],
            [-95.608, 38.6953],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010800',
        NAME: 'West Franklin Unified School District 287',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644665563,
        AWATER: 5084090,
        INTPTLAT: '+38.5840498',
        INTPTLON: '-095.4272255',
        ELSDLEA: '',
        UNSDLEA: '10800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5671, 38.6997],
            [-101.4832, 38.7585],
            [-101.206, 38.7734],
            [-101.1601, 38.7008],
            [-101.1284, 38.7006],
            [-101.1254, 38.2645],
            [-101.213, 38.2643],
            [-101.5423, 38.2632],
            [-101.5675, 38.2631],
            [-101.5671, 38.6997],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008610',
        NAME: 'Leoti Unified School District 467',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2003600723,
        AWATER: 61987,
        INTPTLAT: '+38.5069605',
        INTPTLON: '-101.3574327',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.914, 38.0202],
            [-96.7675, 38.0715],
            [-96.7753, 37.8394],
            [-96.7524, 37.7885],
            [-96.8435, 37.7886],
            [-96.8772, 37.8827],
            [-96.9422, 37.9052],
            [-96.914, 38.0202],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005730',
        NAME: 'El Dorado Unified School District 490',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307541010,
        AWATER: 25163462,
        INTPTLAT: '+37.9269910',
        INTPTLON: '-096.8245012',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0401, 38.871],
            [-97.9286, 38.8711],
            [-97.8174, 38.871],
            [-97.8173, 38.929],
            [-97.6686, 38.9581],
            [-97.6503, 38.7727],
            [-97.6501, 38.7543],
            [-97.743, 38.7692],
            [-97.8144, 38.6385],
            [-97.8882, 38.6389],
            [-97.9622, 38.6969],
            [-98.0401, 38.871],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005800',
        NAME: 'Ell-Saline Unified School District 307',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612302932,
        AWATER: 1181600,
        INTPTLAT: '+38.8216389',
        INTPTLON: '-097.8363996',
        ELSDLEA: '',
        UNSDLEA: '05800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4648, 37.7327],
            [-98.3193, 37.7326],
            [-98.2821, 37.5802],
            [-98.2182, 37.5229],
            [-98.2222, 37.3843],
            [-98.222, 37.3767],
            [-98.3495, 37.3264],
            [-98.3497, 37.3841],
            [-98.465, 37.3841],
            [-98.4647, 37.529],
            [-98.5199, 37.5943],
            [-98.5287, 37.696],
            [-98.4648, 37.7327],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005370',
        NAME: 'Cunningham Unified School District 332',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 836526057,
        AWATER: 491499,
        INTPTLAT: '+37.5357580',
        INTPTLON: '-098.3650434',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7213, 37.8248],
            [-98.7194, 37.8248],
            [-98.5289, 37.8056],
            [-98.4648, 37.7327],
            [-98.5287, 37.696],
            [-98.5199, 37.5943],
            [-98.6018, 37.5583],
            [-98.8126, 37.5721],
            [-98.7594, 37.6607],
            [-98.8393, 37.6754],
            [-98.7213, 37.8248],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010890',
        NAME: 'Pratt Unified School District 382',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696097412,
        AWATER: 1375774,
        INTPTLAT: '+37.6918572',
        INTPTLON: '-098.6517154',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9582, 37.2862],
            [-100.9402, 37.2863],
            [-100.7801, 37.3151],
            [-100.7789, 37.3805],
            [-100.6526, 37.4167],
            [-100.5433, 37.4165],
            [-100.543, 37.4748],
            [-100.4794, 37.4747],
            [-100.4981, 37.1333],
            [-100.5522, 37.0007],
            [-100.7294, 36.9991],
            [-100.8682, 36.9988],
            [-100.831, 37.17],
            [-100.9304, 37.2503],
            [-100.9582, 37.2862],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008190',
        NAME: 'Kismet-Plains Unified School District 483',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1381934266,
        AWATER: 3316082,
        INTPTLAT: '+37.2110325',
        INTPTLON: '-100.6621086',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8329, 38.0374],
            [-94.6141, 38.0371],
            [-94.6141, 37.9875],
            [-94.6177, 37.7555],
            [-94.6178, 37.6731],
            [-94.7765, 37.6741],
            [-94.8331, 37.674],
            [-94.8329, 38.0374],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006180',
        NAME: 'Fort Scott Unified School District 234',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 763979330,
        AWATER: 5306184,
        INTPTLAT: '+37.8500723',
        INTPTLON: '-094.7236454',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6686, 38.9581],
            [-97.5382, 38.936],
            [-97.5205, 38.8557],
            [-97.539, 38.7982],
            [-97.6503, 38.7727],
            [-97.6686, 38.9581],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011370',
        NAME: 'Salina Unified School District 305',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241061409,
        AWATER: 758666,
        INTPTLAT: '+38.8607761',
        INTPTLON: '-097.6072599',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.134, 39.8765],
            [-95.0219, 39.894],
            [-95.04, 39.6488],
            [-95.0536, 39.6153],
            [-95.1086, 39.6382],
            [-95.1152, 39.6525],
            [-95.1152, 39.7398],
            [-95.1808, 39.8126],
            [-95.134, 39.8765],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012330',
        NAME: 'Troy Public Schools Unified School District 429',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244148859,
        AWATER: 2232538,
        INTPTLAT: '+39.7599439',
        INTPTLON: '-095.0887534',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9044, 38.2976],
            [-95.7935, 38.2885],
            [-95.7201, 38.34],
            [-95.6105, 38.2509],
            [-95.5822, 38.2056],
            [-95.684, 38.1191],
            [-95.7943, 38.1123],
            [-95.83, 38.1669],
            [-95.9544, 38.2073],
            [-95.9044, 38.2976],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004290',
        NAME: 'Burlington Unified School District 244',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402330050,
        AWATER: 57173679,
        INTPTLAT: '+38.2234063',
        INTPTLON: '-095.7615840',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.261, 38.6093],
            [-97.004, 38.6236],
            [-96.8565, 38.6239],
            [-96.7084, 38.6092],
            [-96.7454, 38.5367],
            [-96.8197, 38.5225],
            [-96.8196, 38.4066],
            [-96.9667, 38.4064],
            [-97.1324, 38.4351],
            [-97.2249, 38.5222],
            [-97.261, 38.6093],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008940',
        NAME: 'Centre Unified School District 397',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 796922786,
        AWATER: 1859097,
        INTPTLAT: '+38.5344376',
        INTPTLON: '-096.9776718',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2771, 39.3625],
            [-96.1839, 39.3475],
            [-96.166, 39.2093],
            [-96.2036, 39.1589],
            [-96.2611, 39.1016],
            [-96.3911, 39.1016],
            [-96.4283, 39.065],
            [-96.4631, 39.1673],
            [-96.3612, 39.1959],
            [-96.371, 39.3334],
            [-96.2771, 39.3625],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000003',
        NAME: 'Wamego Unified School District 320',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490880952,
        AWATER: 6318296,
        INTPTLAT: '+39.2204382',
        INTPTLON: '-096.2941724',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9622, 38.6969],
            [-97.8882, 38.6389],
            [-97.8144, 38.6385],
            [-97.743, 38.7692],
            [-97.6501, 38.7543],
            [-97.6392, 38.609],
            [-97.3719, 38.6094],
            [-97.3719, 38.5076],
            [-97.5742, 38.5012],
            [-97.7399, 38.4782],
            [-97.814, 38.4354],
            [-97.8875, 38.5153],
            [-98.0537, 38.5367],
            [-97.9801, 38.5733],
            [-98.033, 38.6823],
            [-97.9622, 38.6969],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000002',
        NAME: 'Smoky Valley Unified School District 400',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1016841247,
        AWATER: 9716288,
        INTPTLAT: '+38.5878521',
        INTPTLON: '-097.7601472',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1057, 37.3031],
            [-97.0253, 37.376],
            [-96.8888, 37.3753],
            [-96.8795, 37.3203],
            [-96.7524, 37.2367],
            [-96.8164, 37.2086],
            [-96.8467, 37.1277],
            [-96.9237, 37.1493],
            [-97.1403, 37.1434],
            [-97.1495, 37.2232],
            [-97.1057, 37.3031],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2013050',
        NAME: 'Winfield Unified School District 465',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 648211166,
        AWATER: 2139855,
        INTPTLAT: '+37.2407027',
        INTPTLON: '-096.9588472',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.0606, 37.6747],
              [-95.0334, 37.6749],
              [-95.0606, 37.66],
              [-95.0606, 37.6747],
            ],
          ],
          [
            [
              [-95.5252, 37.5514],
              [-95.3793, 37.5583],
              [-95.2883, 37.7326],
              [-95.288, 37.7326],
              [-95.0881, 37.7325],
              [-95.0698, 37.6744],
              [-95.0699, 37.6599],
              [-95.1336, 37.602],
              [-95.1979, 37.5144],
              [-95.1789, 37.3843],
              [-95.2852, 37.3841],
              [-95.4984, 37.384],
              [-95.4341, 37.4568],
              [-95.525, 37.5147],
              [-95.5252, 37.5514],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006000',
        NAME: 'Erie Unified School District 101',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 849522091,
        AWATER: 7051921,
        INTPTLAT: '+37.5499490',
        INTPTLON: '-095.2866424',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.1583, 37.1934],
              [-95.0742, 37.146],
              [-95.0736, 37.0345],
              [-95.035, 36.9995],
              [-95.159, 36.9996],
              [-95.1583, 37.1934],
            ],
          ],
          [
            [
              [-95.1336, 37.602],
              [-95.0513, 37.4711],
              [-95.1425, 37.4426],
              [-95.1241, 37.3841],
              [-95.1789, 37.3843],
              [-95.1979, 37.5144],
              [-95.1336, 37.602],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004770',
        NAME: 'Chetopa-St. Paul Unified School District 505',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329914094,
        AWATER: 7491282,
        INTPTLAT: '+37.5206432',
        INTPTLON: '-095.1580535',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3307, 37.3881],
            [-101.2891, 37.453],
            [-101.163, 37.417],
            [-101.0863, 37.3877],
            [-101.0679, 37.3149],
            [-100.9582, 37.2862],
            [-100.9304, 37.2503],
            [-101.0677, 37.2136],
            [-101.2305, 37.2139],
            [-101.3391, 37.2936],
            [-101.3307, 37.3881],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009720',
        NAME: 'Moscow Public Schools Unified School District 209',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573921291,
        AWATER: 407729,
        INTPTLAT: '+37.2992555',
        INTPTLON: '-101.1681597',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8467, 37.1277],
            [-96.8164, 37.2086],
            [-96.7524, 37.2367],
            [-96.6079, 37.2294],
            [-96.6074, 36.9989],
            [-96.7498, 36.999],
            [-96.8675, 36.9993],
            [-96.8229, 37.0555],
            [-96.8467, 37.1277],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005520',
        NAME: 'Dexter Unified School District 471',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 549788398,
        AWATER: 694897,
        INTPTLAT: '+37.1201655',
        INTPTLON: '-096.6802730',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0779, 38.0377],
            [-95.0767, 38.1683],
            [-95.0762, 38.2117],
            [-94.9333, 38.2042],
            [-94.8628, 38.2728],
            [-94.789, 38.2725],
            [-94.7321, 38.1244],
            [-94.6138, 38.1244],
            [-94.6141, 38.0371],
            [-94.8329, 38.0374],
            [-94.8971, 37.9935],
            [-95.0784, 38.0088],
            [-95.0779, 38.0377],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007750',
        NAME: 'Jayhawk Unified School District 346',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793980129,
        AWATER: 6463479,
        INTPTLAT: '+38.1135230',
        INTPTLON: '-094.8849275',
        ELSDLEA: '',
        UNSDLEA: '07750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4782, 39.1348],
            [-101.3917, 39.1351],
            [-101.2981, 39.2298],
            [-101.2236, 39.2218],
            [-101.1121, 39.2061],
            [-101.0934, 39.1914],
            [-101.1106, 38.9611],
            [-101.0549, 38.96],
            [-101.0397, 38.7862],
            [-101.0399, 38.7287],
            [-101.1601, 38.7008],
            [-101.206, 38.7734],
            [-101.4832, 38.7585],
            [-101.4816, 38.8747],
            [-101.3881, 38.8749],
            [-101.3877, 38.9474],
            [-101.4804, 38.9618],
            [-101.4782, 39.1348],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000013',
        NAME: 'Triplains Unified School District 275',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1710283094,
        AWATER: 237469,
        INTPTLAT: '+38.9470261',
        INTPTLON: '-101.2494690',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0472, 39.1331],
            [-101.4848, 39.1348],
            [-101.4782, 39.1348],
            [-101.4804, 38.9618],
            [-101.3877, 38.9474],
            [-101.3881, 38.8749],
            [-101.4816, 38.8747],
            [-101.4832, 38.7585],
            [-101.5671, 38.6997],
            [-101.8548, 38.6979],
            [-101.7986, 38.7852],
            [-101.8145, 38.9315],
            [-101.9255, 38.9457],
            [-101.923, 39.0607],
            [-102.0467, 39.0756],
            [-102.0472, 39.1331],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011610',
        NAME: 'Wallace County Schools Unified School District 241',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1760715777,
        AWATER: 91645,
        INTPTLAT: '+38.9297376',
        INTPTLON: '-101.7022121',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4314, 38.0576],
            [-96.3759, 38.0572],
            [-96.3428, 37.9411],
            [-96.2877, 37.8979],
            [-95.9586, 37.88],
            [-95.9494, 37.88],
            [-95.8889, 37.7447],
            [-96.07, 37.7359],
            [-96.1022, 37.668],
            [-96.1516, 37.7111],
            [-96.3078, 37.6647],
            [-96.3529, 37.5776],
            [-96.5075, 37.5926],
            [-96.4711, 37.6869],
            [-96.4701, 37.8759],
            [-96.5239, 37.9706],
            [-96.4348, 37.985],
            [-96.4314, 38.0576],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006120',
        NAME: 'Eureka Unified School District 389',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1422943422,
        AWATER: 19681091,
        INTPTLAT: '+37.7841109',
        INTPTLON: '-096.2689780',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.468, 38.0864],
            [-96.3581, 38.0858],
            [-96.0677, 38.0413],
            [-96.0677, 37.9184],
            [-95.9586, 37.88],
            [-96.2877, 37.8979],
            [-96.3428, 37.9411],
            [-96.3759, 38.0572],
            [-96.4314, 38.0576],
            [-96.468, 38.0864],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006870',
        NAME: 'Hamilton Unified School District 390',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548203075,
        AWATER: 1653224,
        INTPTLAT: '+37.9730455',
        INTPTLON: '-096.2070746',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7675, 38.0715],
            [-96.7306, 38.0861],
            [-96.468, 38.0864],
            [-96.4314, 38.0576],
            [-96.4348, 37.985],
            [-96.5239, 37.9706],
            [-96.4701, 37.8759],
            [-96.4711, 37.6869],
            [-96.5258, 37.7154],
            [-96.7074, 37.7374],
            [-96.7524, 37.7885],
            [-96.7753, 37.8394],
            [-96.7675, 38.0715],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011220',
        NAME: 'Flinthills Unified School District 492',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 977025066,
        AWATER: 11681593,
        INTPTLAT: '+37.9041858',
        INTPTLON: '-096.5999454',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1022, 37.668],
            [-96.07, 37.7359],
            [-95.8889, 37.7447],
            [-95.7976, 37.7339],
            [-95.7053, 37.6462],
            [-95.7199, 37.4863],
            [-95.8156, 37.4584],
            [-95.8156, 37.3857],
            [-95.9614, 37.3868],
            [-95.9987, 37.5097],
            [-96.1257, 37.5184],
            [-96.1022, 37.668],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006270',
        NAME: 'Fredonia Unified School District 484',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1023681789,
        AWATER: 14996253,
        INTPTLAT: '+37.5782693',
        INTPTLON: '-095.8987131',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.519, 38.0382],
            [-95.2809, 38.038],
            [-95.2808, 37.8709],
            [-95.519, 37.8713],
            [-95.519, 38.0382],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007740',
        NAME: 'Iola Unified School District 257',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358136405,
        AWATER: 5425070,
        INTPTLAT: '+37.9477301',
        INTPTLON: '-095.3998448',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5187, 38.1037],
            [-95.3893, 38.1477],
            [-95.2427, 38.1107],
            [-95.186, 38.1686],
            [-95.0767, 38.1683],
            [-95.0779, 38.0377],
            [-95.2809, 38.038],
            [-95.519, 38.0382],
            [-95.5187, 38.1037],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008040',
        NAME: 'Crest Unified School District 479',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454810760,
        AWATER: 2513159,
        INTPTLAT: '+38.0887672',
        INTPTLON: '-095.2729373',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2808, 37.8709],
            [-95.2809, 38.038],
            [-95.0779, 38.0377],
            [-95.0784, 38.0088],
            [-95.0881, 37.7325],
            [-95.288, 37.7326],
            [-95.2808, 37.8709],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009660',
        NAME: 'Marmaton Valley Unified School District 256',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 581777133,
        AWATER: 3403735,
        INTPTLAT: '+37.8858214',
        INTPTLON: '-095.1763501',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0784, 38.0088],
            [-94.8971, 37.9935],
            [-94.8329, 38.0374],
            [-94.8331, 37.674],
            [-95.0334, 37.6749],
            [-95.0606, 37.6747],
            [-95.0698, 37.6744],
            [-95.0881, 37.7325],
            [-95.0784, 38.0088],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012450',
        NAME: 'Uniontown Unified School District 235',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801301689,
        AWATER: 3399135,
        INTPTLAT: '+37.8398292',
        INTPTLON: '-094.9545438',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1033, 38.2646],
            [-100.6297, 38.2639],
            [-100.3366, 38.2626],
            [-100.3353, 38.1469],
            [-100.3909, 38.1465],
            [-100.4094, 38.0008],
            [-100.6642, 38.0025],
            [-100.6618, 37.7363],
            [-100.7252, 37.7365],
            [-100.9985, 37.7367],
            [-101.0897, 37.7363],
            [-101.0898, 37.809],
            [-100.9437, 37.8094],
            [-100.9388, 38.0034],
            [-100.8835, 38.0476],
            [-100.8836, 38.1491],
            [-101.1028, 38.1484],
            [-101.1033, 38.2646],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006390',
        NAME: 'Garden City Unified School District 457',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2411671198,
        AWATER: 1155507,
        INTPTLAT: '+38.0498075',
        INTPTLON: '-100.7399674',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5423, 38.2632],
            [-101.213, 38.2643],
            [-101.2134, 37.8284],
            [-101.1985, 37.7363],
            [-101.5271, 37.7363],
            [-101.5428, 37.8276],
            [-101.5423, 38.2632],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008280',
        NAME: 'Lakin Unified School District 215',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1691225635,
        AWATER: 305846,
        INTPTLAT: '+37.9952276',
        INTPTLON: '-101.3678278',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.213, 38.2643],
            [-101.1254, 38.2645],
            [-101.1033, 38.2646],
            [-101.1028, 38.1484],
            [-101.1038, 37.829],
            [-101.0898, 37.809],
            [-101.0897, 37.7363],
            [-101.1985, 37.7363],
            [-101.2134, 37.8284],
            [-101.213, 38.2643],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005400',
        NAME: 'Deerfield Unified School District 216',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 563470951,
        AWATER: 827755,
        INTPTLAT: '+37.9992183',
        INTPTLON: '-101.1534576',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0416, 37.3892],
            [-101.8462, 37.3889],
            [-101.8273, 37.1561],
            [-101.7185, 37.1567],
            [-101.7183, 36.9945],
            [-102.0421, 36.993],
            [-102.0416, 37.3892],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005790',
        NAME: 'Elkhart Unified School District 218',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 978246931,
        AWATER: 336056,
        INTPTLAT: '+37.1695327',
        INTPTLON: '-101.9198313',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9497, 38.6954],
            [-95.9457, 38.6954],
            [-95.7604, 38.7099],
            [-95.7233, 38.7099],
            [-95.7658, 38.5216],
            [-95.8021, 38.506],
            [-95.8393, 38.5058],
            [-95.9498, 38.5641],
            [-95.9497, 38.6954],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010230',
        NAME: 'Osage City Unified School District 420',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328598224,
        AWATER: 1991224,
        INTPTLAT: '+38.6152302',
        INTPTLON: '-095.8479604',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8623, 39.0368],
            [-95.8623, 39.0842],
            [-95.7614, 39.0728],
            [-95.7314, 39.0725],
            [-95.6877, 38.9917],
            [-95.6872, 38.8701],
            [-95.8268, 38.8619],
            [-95.8834, 38.862],
            [-95.8623, 39.0368],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003200',
        NAME: 'Auburn-Washburn Unified School District 437',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318415682,
        AWATER: 9971139,
        INTPTLAT: '+38.9526020',
        INTPTLON: '-095.7866995',
        ELSDLEA: '',
        UNSDLEA: '03200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8834, 38.862],
            [-95.8268, 38.8619],
            [-95.7604, 38.7099],
            [-95.9457, 38.6954],
            [-95.8834, 38.862],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004260',
        NAME: 'Burlingame Public School Unified School District 454',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193184800,
        AWATER: 354460,
        INTPTLAT: '+38.7611856',
        INTPTLON: '-095.8473216',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9985, 37.7367],
            [-100.7252, 37.7365],
            [-100.7253, 37.4167],
            [-100.6526, 37.4167],
            [-100.7789, 37.3805],
            [-100.7801, 37.3151],
            [-100.9402, 37.2863],
            [-100.9054, 37.3515],
            [-100.9439, 37.6495],
            [-100.9985, 37.7367],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012090',
        NAME: 'Sublette Unified School District 374',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 911967019,
        AWATER: 644317,
        INTPTLAT: '+37.5403601',
        INTPTLON: '-100.8438939',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7399, 39.7409],
            [-99.6278, 39.756],
            [-99.6272, 39.8721],
            [-99.4966, 39.8574],
            [-99.4779, 39.6254],
            [-99.4027, 39.5673],
            [-99.4623, 39.5675],
            [-99.5281, 39.481],
            [-99.6016, 39.481],
            [-99.6021, 39.539],
            [-99.7322, 39.539],
            [-99.7782, 39.6101],
            [-99.7399, 39.7409],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008910',
        NAME: 'Logan Unified School District 326',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 831567823,
        AWATER: 576098,
        INTPTLAT: '+39.6624397',
        INTPTLON: '-099.5987554',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2486, 38.6394],
            [-100.1023, 38.639],
            [-100.0654, 38.5373],
            [-99.8076, 38.5455],
            [-99.8048, 38.2619],
            [-100.2271, 38.2622],
            [-100.2444, 38.2623],
            [-100.2486, 38.6394],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009930',
        NAME: 'Ness City Unified School District 303',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1332056917,
        AWATER: 233803,
        INTPTLAT: '+38.4248676',
        INTPTLON: '-100.0437142',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4966, 39.8574],
            [-99.3846, 39.9006],
            [-99.3851, 40.0021],
            [-99.2353, 40.002],
            [-99.2639, 39.8715],
            [-99.1975, 39.7272],
            [-99.2353, 39.6984],
            [-99.2344, 39.5674],
            [-99.4027, 39.5673],
            [-99.4779, 39.6254],
            [-99.4966, 39.8574],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010650',
        NAME: 'Phillipsburg Unified School District 325',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 906107535,
        AWATER: 781407,
        INTPTLAT: '+39.7733585',
        INTPTLON: '-099.3426029',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4094, 38.0008],
            [-100.4335, 37.708],
            [-100.5614, 37.6788],
            [-100.6618, 37.7363],
            [-100.6642, 38.0025],
            [-100.4094, 38.0008],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007680',
        NAME: 'Ingalls Unified School District 477',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 699565923,
        AWATER: 186985,
        INTPTLAT: '+37.8565790',
        INTPTLON: '-100.5484724',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3845, 37.7669],
            [-97.2359, 37.825],
            [-97.1896, 37.7307],
            [-97.1531, 37.6282],
            [-97.1852, 37.6139],
            [-97.3028, 37.5864],
            [-97.4525, 37.6292],
            [-97.4807, 37.7007],
            [-97.3845, 37.7669],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012990',
        NAME: 'Wichita Unified School District 259',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385164251,
        AWATER: 9312245,
        INTPTLAT: '+37.6983664',
        INTPTLON: '-097.3092547',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8078, 37.7339],
            [-97.7995, 37.734],
            [-97.7252, 37.6844],
            [-97.689, 37.5403],
            [-97.7251, 37.4744],
            [-97.8764, 37.5616],
            [-97.8813, 37.6329],
            [-97.8078, 37.7339],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004670',
        NAME: 'Cheney Unified School District 268',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323742377,
        AWATER: 3388246,
        INTPTLAT: '+37.5965126',
        INTPTLON: '-097.7813852',
        ELSDLEA: '',
        UNSDLEA: '04670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2359, 37.825],
            [-97.2167, 37.8251],
            [-96.9422, 37.9052],
            [-96.8772, 37.8827],
            [-96.8435, 37.7886],
            [-96.9623, 37.7229],
            [-97.0528, 37.7161],
            [-97.1896, 37.7307],
            [-97.2359, 37.825],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012300',
        NAME: 'Circle Unified School District 375',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 462207173,
        AWATER: 210722,
        INTPTLAT: '+37.8002922',
        INTPTLON: '-097.0289202',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8072, 38.8994],
            [-96.7053, 38.8993],
            [-96.6501, 38.8122],
            [-96.6495, 38.7393],
            [-96.7914, 38.6856],
            [-96.8532, 38.7542],
            [-96.983, 38.7434],
            [-97.004, 38.6236],
            [-97.261, 38.6093],
            [-97.2236, 38.74],
            [-97.0383, 38.7545],
            [-97.02, 38.8273],
            [-96.9176, 38.8342],
            [-96.8072, 38.8994],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007440',
        NAME: 'Rural Vista Unified School District 481',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 788815227,
        AWATER: 2727767,
        INTPTLAT: '+38.7507007',
        INTPTLON: '-096.9518280',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9614, 39.2201],
            [-96.7102, 39.2187],
            [-96.6819, 39.1748],
            [-96.6801, 39.1023],
            [-96.5318, 39.0728],
            [-96.562, 38.9717],
            [-96.6952, 38.9435],
            [-96.7053, 38.8993],
            [-96.8072, 38.8994],
            [-96.8425, 38.9877],
            [-96.9168, 38.9721],
            [-96.9073, 39.0895],
            [-96.961, 39.1971],
            [-96.9614, 39.2201],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007890',
        NAME: 'Geary County Schools Unified School District 475',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 751452572,
        AWATER: 25610747,
        INTPTLAT: '+39.0635171',
        INTPTLON: '-096.7555168',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6105, 38.2509],
            [-95.5078, 38.314],
            [-95.5083, 38.3903],
            [-95.3602, 38.4193],
            [-95.2759, 38.3974],
            [-95.0659, 38.3972],
            [-95.0566, 38.3899],
            [-95.0762, 38.2117],
            [-95.0767, 38.1683],
            [-95.186, 38.1686],
            [-95.2427, 38.1107],
            [-95.3893, 38.1477],
            [-95.5187, 38.1037],
            [-95.5175, 38.1984],
            [-95.5822, 38.2056],
            [-95.6105, 38.2509],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006450',
        NAME: 'Garnett Unified School District 365',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1174341405,
        AWATER: 8838653,
        INTPTLAT: '+38.2692924',
        INTPTLON: '-095.3127552',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.964, 38.702],
            [-94.9177, 38.7093],
            [-94.7603, 38.7018],
            [-94.8082, 38.6572],
            [-94.7134, 38.5635],
            [-94.714, 38.4768],
            [-94.7934, 38.4403],
            [-94.8636, 38.4979],
            [-94.9183, 38.5489],
            [-95.0651, 38.5571],
            [-95.0929, 38.5772],
            [-94.964, 38.702],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010500',
        NAME: 'Paola Unified School District 368',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507160694,
        AWATER: 21874954,
        INTPTLAT: '+38.5901258',
        INTPTLON: '-094.8777511',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1727, 38.0982],
            [-96.9872, 38.0861],
            [-96.914, 38.0202],
            [-96.9422, 37.9052],
            [-97.2167, 37.8251],
            [-97.2998, 37.9158],
            [-97.1727, 38.0982],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006240',
        NAME: 'Remington-Whitewater Unified School District 206',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653347187,
        AWATER: 1856932,
        INTPTLAT: '+37.9637035',
        INTPTLON: '-097.1067159',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6578, 38.268],
            [-97.5649, 38.3479],
            [-97.4821, 38.3481],
            [-97.4267, 38.2608],
            [-97.3717, 38.1882],
            [-97.4545, 38.1738],
            [-97.4819, 38.1012],
            [-97.5921, 38.0869],
            [-97.62, 38.1302],
            [-97.6474, 38.174],
            [-97.6578, 38.268],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009780',
        NAME: 'Moundridge Unified School District 423',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403650295,
        AWATER: 159535,
        INTPTLAT: '+38.2255325',
        INTPTLON: '-097.5333583',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4455, 37.8687],
            [-97.3317, 37.9122],
            [-97.2998, 37.9158],
            [-97.2167, 37.8251],
            [-97.2359, 37.825],
            [-97.3845, 37.7669],
            [-97.4913, 37.825],
            [-97.4455, 37.8687],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012510',
        NAME: 'Valley Center Public Schools Unified School District 262',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216359765,
        AWATER: 2333746,
        INTPTLAT: '+37.8479229',
        INTPTLON: '-097.3447761',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8796, 40.0023],
            [-98.7264, 40.0024],
            [-98.5045, 40.0023],
            [-98.505, 39.6545],
            [-98.617, 39.6254],
            [-98.7294, 39.5676],
            [-98.9354, 39.568],
            [-98.9634, 39.5969],
            [-98.9824, 39.6909],
            [-98.8795, 39.7053],
            [-98.8982, 39.8286],
            [-98.8796, 40.0023],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000007',
        NAME: 'Smith Center Unified School District 237',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1547056088,
        AWATER: 2269949,
        INTPTLAT: '+39.7929697',
        INTPTLON: '-098.7238388',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.617, 39.6254],
            [-98.505, 39.6545],
            [-98.5053, 39.5676],
            [-98.393, 39.5674],
            [-98.2282, 39.5674],
            [-98.2284, 39.3935],
            [-98.3028, 39.3065],
            [-98.4325, 39.3068],
            [-98.5274, 39.2271],
            [-98.5924, 39.2776],
            [-98.582, 39.4666],
            [-98.6563, 39.4809],
            [-98.617, 39.6254],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004470',
        NAME: 'Waconda Unified School District 272',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1021606461,
        AWATER: 45104762,
        INTPTLAT: '+39.4437074',
        INTPTLON: '-098.4435229',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8496, 39.5678],
            [-100.8252, 39.6329],
            [-100.7411, 39.6548],
            [-100.7222, 39.568],
            [-100.5171, 39.5677],
            [-100.627, 39.4952],
            [-100.627, 39.3939],
            [-100.7125, 39.2926],
            [-100.7769, 39.2926],
            [-100.8422, 39.3216],
            [-100.8496, 39.5678],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011040',
        NAME: 'Golden Plains Unified School District 316',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 627032374,
        AWATER: 185700,
        INTPTLAT: '+39.4710126',
        INTPTLON: '-100.7249377',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9288, 39.1035],
            [-97.7611, 39.0882],
            [-97.4456, 39.1106],
            [-97.427, 39.0235],
            [-97.538, 38.9941],
            [-97.5382, 38.936],
            [-97.6686, 38.9581],
            [-97.8173, 38.929],
            [-97.8174, 38.871],
            [-97.9286, 38.8711],
            [-97.9288, 39.1035],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003960',
        NAME: 'Twin Valley Unified School District 240',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 697902307,
        AWATER: 994320,
        INTPTLAT: '+39.0090733',
        INTPTLON: '-097.7147285',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8225, 37.0828],
            [-94.7408, 37.0609],
            [-94.7113, 36.9988],
            [-94.8315, 36.9988],
            [-94.8225, 37.0828],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003810',
        NAME: 'Baxter Springs Unified School District 508',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71199849,
        AWATER: 298400,
        INTPTLAT: '+37.0333345',
        INTPTLON: '-094.7809611',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2635, 39.6045],
            [-101.2067, 39.6408],
            [-101.2227, 39.8718],
            [-101.1869, 40.0025],
            [-101.098, 40.0024],
            [-100.7588, 40.0023],
            [-100.7496, 40.0021],
            [-100.7388, 40.0021],
            [-100.7411, 39.6548],
            [-100.8252, 39.6329],
            [-100.8496, 39.5678],
            [-101.2267, 39.5683],
            [-101.2635, 39.6045],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000023',
        NAME: 'Rawlins County Unified School District 105',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1898095726,
        AWATER: 332762,
        INTPTLAT: '+39.7860167',
        INTPTLON: '-100.9837026',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8855, 37.9815],
            [-95.8119, 38.0397],
            [-95.519, 38.0382],
            [-95.519, 37.8713],
            [-95.5252, 37.7473],
            [-95.5255, 37.7328],
            [-95.7976, 37.7339],
            [-95.8889, 37.7447],
            [-95.9494, 37.88],
            [-95.8855, 37.9815],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2013110',
        NAME: 'Woodson Unified School District 366',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1102447466,
        AWATER: 9024866,
        INTPTLAT: '+37.8941951',
        INTPTLON: '-095.7107372',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0354, 37.5778],
            [-96.9353, 37.5958],
            [-96.8991, 37.556],
            [-96.7286, 37.5225],
            [-96.9338, 37.4545],
            [-97.108, 37.469],
            [-97.1084, 37.4755],
            [-97.0354, 37.5778],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005610',
        NAME: 'Douglass Public Schools Unified School District 396',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305475316,
        AWATER: 533787,
        INTPTLAT: '+37.5267241',
        INTPTLON: '-096.9422899',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2271, 38.2622],
            [-99.8048, 38.2619],
            [-99.5842, 38.2622],
            [-99.5695, 38.0729],
            [-99.5697, 37.9854],
            [-99.6613, 37.9277],
            [-99.8539, 37.9133],
            [-100.1541, 37.9138],
            [-100.2268, 37.972],
            [-100.2271, 38.2622],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007800',
        NAME: 'Jetmore Unified School District 227',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2079209745,
        AWATER: 826344,
        INTPTLAT: '+38.1028518',
        INTPTLON: '-099.9076438',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0013, 37.1512],
            [-98.6955, 37.1381],
            [-98.6773, 37.1672],
            [-98.3484, 37.1815],
            [-98.2935, 37.1524],
            [-98.2918, 36.9977],
            [-98.5447, 36.9988],
            [-98.986, 36.9995],
            [-99.0003, 36.9995],
            [-99.0013, 37.1512],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008130',
        NAME: 'South Barber Unified School District 255',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1119877751,
        AWATER: 2365291,
        INTPTLAT: '+37.0821696',
        INTPTLON: '-098.6365641',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3495, 37.3264],
            [-98.222, 37.3767],
            [-98.2118, 37.2684],
            [-98.1575, 37.2541],
            [-98.1932, 37.1521],
            [-98.2935, 37.1524],
            [-98.3484, 37.1815],
            [-98.3495, 37.3264],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003570',
        NAME: 'Attica Unified School District 511',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332063085,
        AWATER: 557825,
        INTPTLAT: '+37.2458550',
        INTPTLON: '-098.2635819',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.372, 37.9703],
            [-97.3317, 37.9122],
            [-97.4455, 37.8687],
            [-97.4638, 37.9415],
            [-97.372, 37.9703],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011550',
        NAME: 'Sedgwick Public Schools Unified School District 439',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105047782,
        AWATER: 0,
        INTPTLAT: '+37.9281206',
        INTPTLON: '-097.4165018',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1896, 37.7307],
            [-97.0528, 37.7161],
            [-97.0986, 37.6286],
            [-97.1531, 37.6282],
            [-97.1896, 37.7307],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003360',
        NAME: 'Andover Unified School District 385',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122410428,
        AWATER: 710937,
        INTPTLAT: '+37.7082961',
        INTPTLON: '-097.1304523',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1852, 37.6139],
            [-97.1531, 37.6282],
            [-97.0986, 37.6286],
            [-97.0354, 37.5778],
            [-97.1084, 37.4755],
            [-97.1895, 37.5336],
            [-97.1852, 37.6139],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011250',
        NAME: 'Rose Hill Public Schools Unified School District 394',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144613353,
        AWATER: 129663,
        INTPTLAT: '+37.5726656',
        INTPTLON: '-097.1247413',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4525, 37.6292],
            [-97.3028, 37.5864],
            [-97.3243, 37.5284],
            [-97.3699, 37.5283],
            [-97.453, 37.5926],
            [-97.4525, 37.6292],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007050',
        NAME: 'Haysville Unified School District 261',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92000204,
        AWATER: 886140,
        INTPTLAT: '+37.5850013',
        INTPTLON: '-097.3823441',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.517, 37.7008],
            [-97.4807, 37.7007],
            [-97.4525, 37.6292],
            [-97.453, 37.5926],
            [-97.5975, 37.5696],
            [-97.6253, 37.6671],
            [-97.517, 37.7008],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006540',
        NAME: 'Goddard Unified School District 265',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166661187,
        AWATER: 1249441,
        INTPTLAT: '+37.6428849',
        INTPTLON: '-097.5388361',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3193, 37.7326],
            [-98.2374, 37.747],
            [-97.9456, 37.7194],
            [-97.8078, 37.7339],
            [-97.8813, 37.6329],
            [-97.8764, 37.5616],
            [-97.7251, 37.4744],
            [-97.751, 37.43],
            [-97.7696, 37.43],
            [-97.8583, 37.342],
            [-97.8952, 37.3856],
            [-98.2222, 37.3843],
            [-98.2182, 37.5229],
            [-98.2821, 37.5802],
            [-98.3193, 37.7326],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008070',
        NAME: 'Kingman-Norwich Unified School District 331',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1468346045,
        AWATER: 7818533,
        INTPTLAT: '+37.5538698',
        INTPTLON: '-098.0401316',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3717, 38.1882],
            [-97.279, 38.1747],
            [-97.2896, 38.1154],
            [-97.409, 38.0431],
            [-97.4819, 38.1012],
            [-97.4545, 38.1738],
            [-97.3717, 38.1882],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007170',
        NAME: 'Hesston Unified School District 460',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156312270,
        AWATER: 58469,
        INTPTLAT: '+38.1309315',
        INTPTLON: '-097.3960444',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2267, 38.182],
            [-97.2164, 38.2147],
            [-97.1798, 38.2185],
            [-97.1781, 38.2547],
            [-97.1056, 38.2611],
            [-96.9779, 38.1878],
            [-96.8408, 38.169],
            [-96.7306, 38.0861],
            [-96.7675, 38.0715],
            [-96.914, 38.0202],
            [-96.9872, 38.0861],
            [-97.1727, 38.0982],
            [-97.2267, 38.182],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010590',
        NAME: 'Peabody-Burns Unified School District 398',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592223744,
        AWATER: 967577,
        INTPTLAT: '+38.1371064',
        INTPTLON: '-096.9961749',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5234, 38.7257],
            [-99.4474, 38.9291],
            [-99.4266, 39.0594],
            [-99.3891, 39.0741],
            [-99.1489, 39.0594],
            [-99.1678, 39.0162],
            [-99.2432, 38.9146],
            [-99.2095, 38.697],
            [-99.2538, 38.6899],
            [-99.5234, 38.6966],
            [-99.5234, 38.7257],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007020',
        NAME: 'Hays Unified School District 489',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 982751317,
        AWATER: 366341,
        INTPTLAT: '+38.8666025',
        INTPTLON: '-099.3380608',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6025, 39.3943],
            [-99.4922, 39.3865],
            [-99.3999, 39.3216],
            [-99.3891, 39.0741],
            [-99.4266, 39.0594],
            [-99.5921, 39.1132],
            [-99.717, 39.1323],
            [-99.661, 39.2124],
            [-99.65, 39.3655],
            [-99.6025, 39.3943],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010470',
        NAME: 'Palco Unified School District 269',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 640373291,
        AWATER: 308954,
        INTPTLAT: '+39.2433031',
        INTPTLON: '-099.5384707',
        ELSDLEA: '',
        UNSDLEA: '10470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1678, 39.0162],
            [-99.057, 38.9873],
            [-99.0392, 38.9581],
            [-99.0423, 38.7118],
            [-99.2095, 38.697],
            [-99.2432, 38.9146],
            [-99.1678, 39.0162],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012600',
        NAME: 'Victoria Unified School District 432',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501021569,
        AWATER: 203320,
        INTPTLAT: '+38.8487115',
        INTPTLON: '-099.1226664',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1489, 39.0594],
            [-99.1039, 39.1767],
            [-99.1039, 39.2928],
            [-99.0461, 39.3648],
            [-98.8801, 39.3433],
            [-98.7509, 39.2204],
            [-98.7427, 39.0601],
            [-98.7054, 39.002],
            [-99.057, 38.9873],
            [-99.1678, 39.0162],
            [-99.1489, 39.0594],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009850',
        NAME: 'Paradise Unified School District 399',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1130030134,
        AWATER: 1773235,
        INTPTLAT: '+39.1583159',
        INTPTLON: '-098.9456244',
        ELSDLEA: '',
        UNSDLEA: '09850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6643, 38.4346],
            [-98.6271, 38.4346],
            [-98.4806, 38.4201],
            [-98.5085, 38.2609],
            [-98.5276, 38.2164],
            [-98.6927, 38.2462],
            [-98.701, 38.3464],
            [-98.6643, 38.4346],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005820',
        NAME: 'Ellinwood Public Schools Unified School District 355',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396621430,
        AWATER: 631582,
        INTPTLAT: '+38.3282556',
        INTPTLON: '-098.5823359',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.033, 38.6968],
            [-98.6925, 38.6967],
            [-98.6271, 38.4346],
            [-98.6643, 38.4346],
            [-98.9225, 38.4488],
            [-98.9229, 38.5797],
            [-99.0331, 38.6094],
            [-99.033, 38.6968],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007320',
        NAME: 'Hoisington Unified School District 431',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 771981851,
        AWATER: 9746578,
        INTPTLAT: '+38.5720145',
        INTPTLON: '-098.8216685',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3175, 38.8119],
            [-96.1507, 38.9213],
            [-96.095, 39.0297],
            [-95.9467, 39.0368],
            [-95.8623, 39.0368],
            [-95.8834, 38.862],
            [-95.9457, 38.6954],
            [-95.9497, 38.6954],
            [-96.0386, 38.739],
            [-96.2703, 38.7536],
            [-96.3175, 38.8119],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006060',
        NAME: 'Wabaunsee East Unified School District 330',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 910394385,
        AWATER: 5059938,
        INTPTLAT: '+38.8625286',
        INTPTLON: '-096.0474248',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4283, 39.065],
            [-96.3911, 39.1016],
            [-96.2611, 39.1016],
            [-96.2036, 39.1589],
            [-95.9973, 39.1097],
            [-95.9467, 39.0368],
            [-96.095, 39.0297],
            [-96.1507, 38.9213],
            [-96.3175, 38.8119],
            [-96.4362, 38.8257],
            [-96.5203, 38.9133],
            [-96.4824, 39.0433],
            [-96.4283, 39.065],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003240',
        NAME: 'Mill Creek Valley Unified School District 329',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1018541186,
        AWATER: 5072509,
        INTPTLAT: '+38.9963966',
        INTPTLON: '-096.2514445',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3491, 39.733],
            [-95.3397, 39.653],
            [-95.5641, 39.6529],
            [-95.5641, 39.6094],
            [-95.611, 39.6528],
            [-95.7141, 39.6531],
            [-95.7138, 39.69],
            [-95.6765, 39.7832],
            [-95.3961, 39.7618],
            [-95.3491, 39.733],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007470',
        NAME: 'South Brown County Unified School District 430',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406671635,
        AWATER: 1466115,
        INTPTLAT: '+39.7039181',
        INTPTLON: '-095.5307509',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6026, 39.5132],
            [-95.5482, 39.4121],
            [-95.6266, 39.3176],
            [-95.6731, 39.3972],
            [-95.9047, 39.3904],
            [-95.9047, 39.478],
            [-95.7928, 39.5139],
            [-95.6026, 39.5132],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007380',
        NAME: 'Holton Unified School District 336',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 419652479,
        AWATER: 3563023,
        INTPTLAT: '+39.4412046',
        INTPTLON: '-095.7152192',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1839, 39.3475],
            [-96.1279, 39.3907],
            [-95.9795, 39.3907],
            [-95.9233, 39.3904],
            [-95.8497, 39.2894],
            [-95.8492, 39.2165],
            [-95.8623, 39.0842],
            [-95.8623, 39.0368],
            [-95.9467, 39.0368],
            [-95.9973, 39.1097],
            [-96.2036, 39.1589],
            [-96.166, 39.2093],
            [-96.1839, 39.3475],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007970',
        NAME: 'Kaw Valley Unified School District 321',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 767280048,
        AWATER: 12158705,
        INTPTLAT: '+39.2382867',
        INTPTLON: '-096.0260997',
        ELSDLEA: '',
        UNSDLEA: '07970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3538, 38.5217],
            [-96.3348, 38.7392],
            [-96.2703, 38.7536],
            [-96.0386, 38.739],
            [-95.9497, 38.6954],
            [-95.9498, 38.5641],
            [-95.8393, 38.5058],
            [-95.9502, 38.4414],
            [-96.0793, 38.4414],
            [-96.3542, 38.4638],
            [-96.3538, 38.5217],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003210',
        NAME: 'North Lyon County Unified School District 251',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1103019321,
        AWATER: 10236226,
        INTPTLAT: '+38.5927986',
        INTPTLON: '-096.1392150',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.519, 37.8713],
            [-95.2808, 37.8709],
            [-95.288, 37.7326],
            [-95.2883, 37.7326],
            [-95.5252, 37.7473],
            [-95.519, 37.8713],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007590',
        NAME: 'Humboldt Unified School District 258',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343093245,
        AWATER: 4016356,
        INTPTLAT: '+37.8086075',
        INTPTLON: '-095.4083925',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8789, 37.5805],
            [-99.7954, 37.5857],
            [-99.7969, 37.72],
            [-99.5636, 37.7319],
            [-99.5597, 37.7319],
            [-99.5432, 37.3811],
            [-99.5436, 37.3522],
            [-99.7062, 37.3662],
            [-99.8167, 37.3382],
            [-99.8323, 37.5575],
            [-99.8789, 37.5805],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004140',
        NAME: 'Bucklin Unified School District 459',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 950446738,
        AWATER: 1730881,
        INTPTLAT: '+37.5313658',
        INTPTLON: '-099.6891501',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1985, 37.0581],
            [-100.0902, 37.095],
            [-100.0915, 37.2548],
            [-100.0368, 37.2978],
            [-99.8169, 37.2944],
            [-99.8167, 37.3382],
            [-99.7062, 37.3662],
            [-99.5436, 37.3522],
            [-99.5447, 37.2605],
            [-99.6078, 37.2064],
            [-99.6237, 37.1192],
            [-99.5422, 37.1184],
            [-99.5411, 36.9996],
            [-99.8286, 37.0012],
            [-100.1245, 37.0023],
            [-100.2161, 37.002],
            [-100.1985, 37.0581],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003510',
        NAME: 'Ashland Unified School District 220',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1724399780,
        AWATER: 4791991,
        INTPTLAT: '+37.1571129',
        INTPTLON: '-099.8185465',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1072, 37.5331],
            [-100.1071, 37.5621],
            [-99.8789, 37.5805],
            [-99.8323, 37.5575],
            [-99.8167, 37.3382],
            [-99.8169, 37.2944],
            [-100.0368, 37.2978],
            [-100.0915, 37.2548],
            [-100.1072, 37.5331],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009600',
        NAME: 'Minneola Unified School District 219',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 769959333,
        AWATER: 233648,
        INTPTLAT: '+37.4355107',
        INTPTLON: '-099.9683700',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5597, 37.7319],
            [-99.3773, 37.7339],
            [-99.1957, 37.7335],
            [-99.1936, 37.4408],
            [-99.2836, 37.3827],
            [-99.5432, 37.3811],
            [-99.5597, 37.7319],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006720',
        NAME: 'Greensburg Unified School District 422',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1189670431,
        AWATER: 316585,
        INTPTLAT: '+37.5661184',
        INTPTLON: '-099.3861629',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1957, 37.7335],
            [-99.1233, 37.7335],
            [-99.0135, 37.733],
            [-98.9965, 37.6078],
            [-98.9774, 37.5649],
            [-99.0126, 37.4704],
            [-99.0115, 37.3843],
            [-99.1214, 37.3837],
            [-99.1936, 37.4408],
            [-99.1957, 37.7335],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006990',
        NAME: 'Haviland Unified School District 474',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604065059,
        AWATER: 143724,
        INTPTLAT: '+37.5634246',
        INTPTLON: '-099.0956972',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9623, 37.7229],
            [-96.8435, 37.7886],
            [-96.7524, 37.7885],
            [-96.7074, 37.7374],
            [-96.5258, 37.7154],
            [-96.4711, 37.6869],
            [-96.5075, 37.5926],
            [-96.5257, 37.4764],
            [-96.7252, 37.4756],
            [-96.7286, 37.5225],
            [-96.8991, 37.556],
            [-96.9353, 37.5958],
            [-96.9623, 37.7229],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008550',
        NAME: 'Bluestem Unified School District 205',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 964114883,
        AWATER: 3036486,
        INTPTLAT: '+37.6297143',
        INTPTLON: '-096.7061924',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0528, 37.7161],
            [-96.9623, 37.7229],
            [-96.9353, 37.5958],
            [-97.0354, 37.5778],
            [-97.0986, 37.6286],
            [-97.0528, 37.7161],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003630',
        NAME: 'Augusta Unified School District 402',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203855704,
        AWATER: 2445530,
        INTPTLAT: '+37.6341764',
        INTPTLON: '-097.0076071',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5276, 38.2164],
            [-98.5085, 38.2609],
            [-98.4727, 38.122],
            [-98.472, 37.8681],
            [-98.5289, 37.8056],
            [-98.7194, 37.8248],
            [-98.6736, 37.8681],
            [-98.6919, 37.9992],
            [-98.6375, 38.0859],
            [-98.5093, 38.0853],
            [-98.5276, 38.2164],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011970',
        NAME: 'Stafford Unified School District 349',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 603600122,
        AWATER: 5511387,
        INTPTLAT: '+37.9686312',
        INTPTLON: '-098.5702159',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7482, 38.2028],
            [-98.6927, 38.2462],
            [-98.5276, 38.2164],
            [-98.5093, 38.0853],
            [-98.6375, 38.0859],
            [-98.6919, 37.9992],
            [-98.6736, 37.8681],
            [-98.7194, 37.8248],
            [-98.7213, 37.8248],
            [-98.8392, 37.8253],
            [-98.9038, 37.8615],
            [-98.8394, 37.9271],
            [-98.8486, 38.0001],
            [-98.9131, 38.0583],
            [-98.7482, 38.2028],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011910',
        NAME: 'St. John-Hudson Unified School District 350',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 802242271,
        AWATER: 1866337,
        INTPTLAT: '+38.0502021',
        INTPTLON: '-098.7199166',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0742, 37.146],
            [-95.0682, 37.2227],
            [-94.8865, 37.2227],
            [-94.8323, 37.3067],
            [-94.7321, 37.3387],
            [-94.6179, 37.313],
            [-94.6178, 37.1338],
            [-94.6558, 37.1116],
            [-94.759, 37.1486],
            [-94.8225, 37.0828],
            [-94.8315, 36.9988],
            [-94.8669, 36.9989],
            [-95.0076, 36.9995],
            [-95.035, 36.9995],
            [-95.0736, 37.0345],
            [-95.0742, 37.146],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005070',
        NAME: 'Columbus Unified School District 493',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 955177600,
        AWATER: 4101616,
        INTPTLAT: '+37.1600457',
        INTPTLON: '-094.8562421',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2935, 37.1524],
            [-98.1932, 37.1521],
            [-98.1575, 37.2541],
            [-98.2118, 37.2684],
            [-98.222, 37.3767],
            [-98.2222, 37.3843],
            [-97.8952, 37.3856],
            [-97.8583, 37.342],
            [-97.8023, 37.2202],
            [-97.8021, 37.0889],
            [-97.8023, 36.9987],
            [-98.1463, 36.9982],
            [-98.2918, 36.9977],
            [-98.2935, 37.1524],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003390',
        NAME: 'Anthony-Harper Unified School District 361',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1542419582,
        AWATER: 3023756,
        INTPTLAT: '+37.1720310',
        INTPTLON: '-098.0304139',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6501, 38.7543],
            [-97.6503, 38.7727],
            [-97.539, 38.7982],
            [-97.5205, 38.8557],
            [-97.4092, 38.856],
            [-97.3539, 38.7835],
            [-97.3531, 38.6092],
            [-97.3719, 38.6094],
            [-97.6392, 38.609],
            [-97.6501, 38.7543],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000001',
        NAME: 'Southeast of Saline Unified School District 306',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601472235,
        AWATER: 835370,
        INTPTLAT: '+38.7181723',
        INTPTLON: '-097.4927796',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8196, 38.4066],
            [-96.8197, 38.5225],
            [-96.7454, 38.5367],
            [-96.3538, 38.5217],
            [-96.3542, 38.4638],
            [-96.3183, 38.3327],
            [-96.3553, 38.3039],
            [-96.3573, 38.1727],
            [-96.3581, 38.0858],
            [-96.468, 38.0864],
            [-96.7306, 38.0861],
            [-96.8408, 38.169],
            [-96.8196, 38.4066],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005250',
        NAME: 'Chase County Unified School District 284',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2012268951,
        AWATER: 12378803,
        INTPTLAT: '+38.3124048',
        INTPTLON: '-096.5840196',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7603, 38.7018],
            [-94.7232, 38.7384],
            [-94.6091, 38.7381],
            [-94.6095, 38.7217],
            [-94.6122, 38.5655],
            [-94.6133, 38.4342],
            [-94.6132, 38.3887],
            [-94.714, 38.4768],
            [-94.7134, 38.5635],
            [-94.8082, 38.6572],
            [-94.7603, 38.7018],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008970',
        NAME: 'Louisburg Unified School District 416',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397616454,
        AWATER: 4730493,
        INTPTLAT: '+38.5939962',
        INTPTLON: '-094.6797803',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0651, 38.5571],
            [-94.9183, 38.5489],
            [-94.8636, 38.4979],
            [-94.9183, 38.4182],
            [-95.0566, 38.3899],
            [-95.0659, 38.3972],
            [-95.0651, 38.5571],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010260',
        NAME: 'Osawatomie Unified School District 367',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225503906,
        AWATER: 2900025,
        INTPTLAT: '+38.4807137',
        INTPTLON: '-094.9889359',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6925, 38.6967],
            [-98.597, 38.6969],
            [-98.5961, 38.9586],
            [-98.4853, 38.958],
            [-98.4849, 38.8708],
            [-98.3187, 38.8708],
            [-98.3556, 38.7908],
            [-98.4396, 38.7836],
            [-98.4398, 38.697],
            [-98.3155, 38.6967],
            [-98.1647, 38.6094],
            [-98.1644, 38.5221],
            [-98.257, 38.4931],
            [-98.2571, 38.4495],
            [-98.4806, 38.4201],
            [-98.6271, 38.4346],
            [-98.6925, 38.6967],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000349',
        NAME: 'Central Plains Unified School District 112',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1498220976,
        AWATER: 25321706,
        INTPTLAT: '+38.6438831',
        INTPTLON: '-098.4558758',
        ELSDLEA: '',
        UNSDLEA: '00349',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.1734, 39.9936],
              [-96.1544, 40.0005],
              [-95.9729, 40.0006],
              [-95.8221, 40.0005],
              [-95.673, 40.0003],
              [-95.6394, 39.9283],
              [-95.7518, 39.8776],
              [-95.789, 39.7696],
              [-95.7138, 39.69],
              [-95.7141, 39.6531],
              [-95.794, 39.5804],
              [-95.9016, 39.566],
              [-95.9579, 39.6097],
              [-95.9767, 39.6095],
              [-95.9768, 39.6128],
              [-95.977, 39.682],
              [-95.9018, 39.864],
              [-96.0144, 39.8914],
              [-96.1734, 39.9936],
            ],
          ],
          [
            [
              [-96.5014, 40.001],
              [-96.4637, 40.001],
              [-96.3507, 40.0009],
              [-96.1726, 40.0008],
              [-96.1734, 39.9936],
              [-96.192, 39.9868],
              [-96.2393, 39.9936],
              [-96.2488, 39.7838],
              [-96.3701, 39.8057],
              [-96.3608, 39.9067],
              [-96.5014, 40.001],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000350',
        NAME: 'Prairie Hills Unified School District 113',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1365586774,
        AWATER: 3273445,
        INTPTLAT: '+39.8911747',
        INTPTLON: '-095.8534266',
        ELSDLEA: '',
        UNSDLEA: '00350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0219, 39.894],
            [-94.9934, 39.8979],
            [-94.8765, 39.8206],
            [-94.9918, 39.6768],
            [-95.04, 39.6488],
            [-95.0219, 39.894],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000351',
        NAME: 'Riverside Unified School District 114',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217521414,
        AWATER: 7361466,
        INTPTLAT: '+39.7879488',
        INTPTLON: '-094.9733667',
        ELSDLEA: '',
        UNSDLEA: '00351',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.1726, 40.0008],
              [-96.1544, 40.0005],
              [-96.1734, 39.9936],
              [-96.1726, 40.0008],
            ],
          ],
          [
            [
              [-96.192, 39.9868],
              [-96.1734, 39.9936],
              [-96.0144, 39.8914],
              [-95.9018, 39.864],
              [-95.977, 39.682],
              [-96.0708, 39.6967],
              [-96.0707, 39.7691],
              [-96.2488, 39.7838],
              [-96.2393, 39.9936],
              [-96.192, 39.9868],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000353',
        NAME: 'Nemaha Unified School District 115',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 581656687,
        AWATER: 352134,
        INTPTLAT: '+39.8427118',
        INTPTLON: '-096.0838419',
        ELSDLEA: '',
        UNSDLEA: '00353',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2857, 37.3121],
            [-97.1805, 37.3616],
            [-97.1057, 37.3031],
            [-97.1495, 37.2232],
            [-97.1403, 37.1434],
            [-97.2023, 37.0859],
            [-97.3025, 37.1451],
            [-97.2857, 37.3121],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010440',
        NAME: 'Oxford Unified School District 358',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346994873,
        AWATER: 3346066,
        INTPTLAT: '+37.2246542',
        INTPTLON: '-097.2086749',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3926, 37.4635],
            [-97.1987, 37.4129],
            [-97.1805, 37.3616],
            [-97.2857, 37.3121],
            [-97.4311, 37.4043],
            [-97.3926, 37.4635],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003900',
        NAME: 'Belle Plaine Unified School District 357',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221570476,
        AWATER: 2147212,
        INTPTLAT: '+37.3870319',
        INTPTLON: '-097.3093698',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5853, 37.3003],
            [-97.5121, 37.3226],
            [-97.4665, 37.3959],
            [-97.4311, 37.4043],
            [-97.2857, 37.3121],
            [-97.3025, 37.1451],
            [-97.4924, 37.1499],
            [-97.5835, 37.1775],
            [-97.5853, 37.3003],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012840',
        NAME: 'Wellington Unified School District 353',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588852619,
        AWATER: 2501979,
        INTPTLAT: '+37.2605606',
        INTPTLON: '-097.4276518',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7696, 37.43],
            [-97.751, 37.43],
            [-97.7251, 37.4744],
            [-97.689, 37.5403],
            [-97.616, 37.54],
            [-97.5876, 37.4309],
            [-97.4665, 37.3959],
            [-97.5121, 37.3226],
            [-97.5853, 37.3003],
            [-97.7287, 37.3221],
            [-97.7696, 37.43],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005130',
        NAME: 'Conway Springs Unified School District 356',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410349335,
        AWATER: 217507,
        INTPTLAT: '+37.3966035',
        INTPTLON: '-097.6305896',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2538, 38.6899],
            [-99.2095, 38.697],
            [-99.0423, 38.7118],
            [-99.033, 38.6968],
            [-99.0331, 38.6094],
            [-98.9229, 38.5797],
            [-98.9225, 38.4488],
            [-99.0334, 38.4209],
            [-99.0324, 38.3483],
            [-99.2161, 38.35],
            [-99.2529, 38.3507],
            [-99.2538, 38.6899],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004020',
        NAME: 'Otis-Bison Unified School District 403',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876763368,
        AWATER: 214813,
        INTPTLAT: '+38.5150930',
        INTPTLON: '-099.1155122',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2161, 38.35],
            [-99.0324, 38.3483],
            [-99.0334, 38.4209],
            [-98.9959, 38.3623],
            [-98.8758, 38.3326],
            [-98.9126, 38.2611],
            [-98.9773, 38.1893],
            [-99.0044, 38.0874],
            [-98.9864, 38.0002],
            [-99.105, 38],
            [-99.3179, 38.0005],
            [-99.3504, 38.0876],
            [-99.2962, 38.2038],
            [-99.2884, 38.3127],
            [-99.2161, 38.35],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008370',
        NAME: 'Fort Larned Unified School District 495',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1251414147,
        AWATER: 554911,
        INTPTLAT: '+38.1797062',
        INTPTLON: '-099.1268650',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2852, 37.3841],
            [-95.1789, 37.3843],
            [-95.1241, 37.3841],
            [-95.0752, 37.296],
            [-95.1615, 37.3107],
            [-95.2811, 37.3256],
            [-95.2852, 37.3841],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010560',
        NAME: 'Parsons Unified School District 503',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134077857,
        AWATER: 3077391,
        INTPTLAT: '+37.3488973',
        INTPTLON: '-095.1808270',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5742, 38.5012],
            [-97.3719, 38.5076],
            [-97.3717, 38.3625],
            [-97.4267, 38.2608],
            [-97.4821, 38.3481],
            [-97.5649, 38.3479],
            [-97.5742, 38.5012],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004440',
        NAME: 'Canton-Galva Unified School District 419',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385060508,
        AWATER: 712161,
        INTPTLAT: '+38.4130700',
        INTPTLON: '-097.4731266',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.814, 38.4354],
            [-97.7399, 38.4782],
            [-97.5742, 38.5012],
            [-97.5649, 38.3479],
            [-97.6578, 38.268],
            [-97.8141, 38.348],
            [-97.814, 38.4354],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009390',
        NAME: 'McPherson Unified School District 418',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404602723,
        AWATER: 1435492,
        INTPTLAT: '+38.3822489',
        INTPTLON: '-097.6793371',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2222, 39.4824],
            [-101.2267, 39.5683],
            [-100.8496, 39.5678],
            [-100.8422, 39.3216],
            [-100.9437, 39.2347],
            [-101.1121, 39.2061],
            [-101.2236, 39.2218],
            [-101.2228, 39.3599],
            [-101.1669, 39.3595],
            [-101.2229, 39.366],
            [-101.2222, 39.4824],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005010',
        NAME: 'Colby Public Schools Unified School District 315',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1210640889,
        AWATER: 80781,
        INTPTLAT: '+39.4049905',
        INTPTLON: '-101.0499242',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4078, 39.2626],
            [-97.37, 39.2915],
            [-97.3689, 39.4307],
            [-97.2751, 39.4668],
            [-97.2385, 39.5278],
            [-97.0893, 39.5666],
            [-96.9589, 39.5664],
            [-96.9609, 39.4161],
            [-96.9614, 39.2201],
            [-96.961, 39.1971],
            [-96.9614, 39.1323],
            [-97.3712, 39.132],
            [-97.3711, 39.103],
            [-97.4085, 39.1176],
            [-97.4078, 39.2626],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004890',
        NAME: 'Clay Center Unified School District 379',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1610484287,
        AWATER: 24893109,
        INTPTLAT: '+39.3292243',
        INTPTLON: '-097.1715934',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6885, 38.7582],
            [-100.5309, 38.7865],
            [-100.4483, 38.7432],
            [-100.5038, 38.6999],
            [-100.5048, 38.5545],
            [-100.6866, 38.5262],
            [-100.688, 38.7],
            [-100.8187, 38.6999],
            [-100.6885, 38.7582],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007080',
        NAME: 'Healy Public Schools Unified School District 468',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520660330,
        AWATER: 17263,
        INTPTLAT: '+38.6718018',
        INTPTLON: '-100.5985325',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2939, 39.176],
            [-100.3037, 39.1905],
            [-100.2751, 39.248],
            [-100.1636, 39.2478],
            [-100.1479, 39.1322],
            [-100.1538, 38.6973],
            [-100.2472, 38.6982],
            [-100.4483, 38.7432],
            [-100.5309, 38.7865],
            [-100.3363, 38.8437],
            [-100.334, 39.0823],
            [-100.2939, 39.176],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010950',
        NAME: 'Quinter Public Schools Unified School District 293',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1029751880,
        AWATER: 111153,
        INTPTLAT: '+38.9340369',
        INTPTLON: '-100.2605180',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0566, 38.3899],
            [-94.9183, 38.4182],
            [-94.8636, 38.4979],
            [-94.7934, 38.4403],
            [-94.714, 38.4768],
            [-94.6132, 38.3887],
            [-94.6127, 38.2868],
            [-94.789, 38.2725],
            [-94.8628, 38.2728],
            [-94.9333, 38.2042],
            [-95.0762, 38.2117],
            [-95.0566, 38.3899],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008250',
        NAME: 'Prairie View Unified School District 362',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 800001809,
        AWATER: 21719472,
        INTPTLAT: '+38.3512510',
        INTPTLON: '-094.8588507',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.789, 38.2725],
            [-94.6127, 38.2868],
            [-94.6126, 38.2122],
            [-94.6138, 38.1244],
            [-94.7321, 38.1244],
            [-94.789, 38.2725],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010770',
        NAME: 'Pleasanton Unified School District 344',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231910428,
        AWATER: 7770762,
        INTPTLAT: '+38.2009596',
        INTPTLON: '-094.6840558',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6558, 37.1116],
            [-94.6178, 37.1338],
            [-94.618, 37.0327],
            [-94.618, 37.0309],
            [-94.6558, 37.1116],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006360',
        NAME: 'Galena Unified School District 499',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38766333,
        AWATER: 373287,
        INTPTLAT: '+37.0879845',
        INTPTLON: '-094.6418285',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6558, 37.1116],
            [-94.618, 37.0309],
            [-94.618, 36.9989],
            [-94.7113, 36.9988],
            [-94.7408, 37.0609],
            [-94.8225, 37.0828],
            [-94.759, 37.1486],
            [-94.6558, 37.1116],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011130',
        NAME: 'Riverton Unified School District 404',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160697759,
        AWATER: 2731813,
        INTPTLAT: '+37.0707076',
        INTPTLON: '-094.7167322',
        ELSDLEA: '',
        UNSDLEA: '11130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6866, 38.5262],
            [-100.5048, 38.5545],
            [-100.5038, 38.6999],
            [-100.4483, 38.7432],
            [-100.2472, 38.6982],
            [-100.2486, 38.6394],
            [-100.2444, 38.2623],
            [-100.3366, 38.2626],
            [-100.6297, 38.2639],
            [-100.6846, 38.2711],
            [-100.6866, 38.5262],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005550',
        NAME: 'Dighton Unified School District 482',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1600814966,
        AWATER: 551220,
        INTPTLAT: '+38.4705008',
        INTPTLON: '-100.4416243',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5566, 37.3885],
            [-101.5553, 36.9953],
            [-101.5952, 36.9951],
            [-101.7037, 36.9946],
            [-101.7183, 36.9945],
            [-101.7185, 37.1567],
            [-101.8273, 37.1561],
            [-101.8462, 37.3889],
            [-101.5566, 37.3885],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011190',
        NAME: 'Rolla Unified School District 217',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 911741431,
        AWATER: 171740,
        INTPTLAT: '+37.2170172',
        INTPTLON: '-101.6755553',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9581, 39.3318],
            [-94.9382, 39.3871],
            [-94.9085, 39.3298],
            [-94.9581, 39.3318],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006330',
        NAME: 'Fort Leavenworth Unified School District 207',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26874263,
        AWATER: 2002031,
        INTPTLAT: '+39.3595883',
        INTPTLON: '-094.9197276',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9581, 39.3318],
            [-94.9085, 39.3298],
            [-94.8577, 39.2741],
            [-94.9733, 39.2883],
            [-94.9581, 39.3318],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008430',
        NAME: 'Leavenworth Unified School District 453',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41480255,
        AWATER: 1124282,
        INTPTLAT: '+39.3005501',
        INTPTLON: '-094.9339943',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0897, 37.7363],
            [-100.9985, 37.7367],
            [-100.9439, 37.6495],
            [-100.9054, 37.3515],
            [-100.9402, 37.2863],
            [-100.9582, 37.2862],
            [-101.0679, 37.3149],
            [-101.0863, 37.3877],
            [-101.163, 37.417],
            [-101.0898, 37.504],
            [-101.0897, 37.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011400',
        NAME: 'Satanta Unified School District 507',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 675965412,
        AWATER: 162322,
        INTPTLAT: '+37.4968150',
        INTPTLON: '-101.0258989',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0451, 38.6152],
            [-102.0452, 38.6976],
            [-101.8548, 38.6979],
            [-101.5671, 38.6997],
            [-101.5675, 38.2631],
            [-102.0445, 38.2624],
            [-102.0445, 38.2687],
            [-102.0451, 38.6152],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006690',
        NAME: 'Greeley County Schools Unified School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2016057984,
        AWATER: 0,
        INTPTLAT: '+38.4804041',
        INTPTLON: '-101.8059697',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7125, 39.2926],
            [-100.627, 39.3939],
            [-100.627, 39.4952],
            [-100.5171, 39.5677],
            [-100.3209, 39.6742],
            [-100.1802, 39.7305],
            [-100.1622, 39.509],
            [-100.1636, 39.2478],
            [-100.2751, 39.248],
            [-100.3037, 39.1905],
            [-100.3596, 39.2343],
            [-100.4428, 39.191],
            [-100.5359, 39.2055],
            [-100.7125, 39.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007530',
        NAME: 'Hoxie Community Schools Unified School District 412',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1856533955,
        AWATER: 407904,
        INTPTLAT: '+39.4155627',
        INTPTLON: '-100.3951528',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7388, 40.0021],
            [-100.7027, 40.0021],
            [-100.6916, 40.0021],
            [-100.6651, 40.002],
            [-100.6459, 40.0019],
            [-100.6271, 40.0021],
            [-100.6089, 40.0019],
            [-100.5993, 40.0019],
            [-100.5337, 40.0019],
            [-100.4635, 40.0017],
            [-100.4575, 40.0018],
            [-100.1459, 40.0016],
            [-100.1372, 40.0016],
            [-100.1315, 40.0017],
            [-100.1326, 39.9145],
            [-100.1325, 39.871],
            [-100.1802, 39.7305],
            [-100.3209, 39.6742],
            [-100.5171, 39.5677],
            [-100.7222, 39.568],
            [-100.7411, 39.6548],
            [-100.7388, 40.0021],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010080',
        NAME: 'Oberlin Unified School District 294',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2153503400,
        AWATER: 1685387,
        INTPTLAT: '+39.8061242',
        INTPTLON: '-100.4677854',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9225, 38.4488],
            [-98.6643, 38.4346],
            [-98.701, 38.3464],
            [-98.6927, 38.2462],
            [-98.7482, 38.2028],
            [-98.7847, 38.2609],
            [-98.9126, 38.2611],
            [-98.8758, 38.3326],
            [-98.9959, 38.3623],
            [-99.0334, 38.4209],
            [-98.9225, 38.4488],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006660',
        NAME: 'Great Bend Unified School District 428',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 509626021,
        AWATER: 1360743,
        INTPTLAT: '+38.3540112',
        INTPTLON: '-098.8207971',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.105, 38],
            [-98.9864, 38.0002],
            [-99.0044, 38.0874],
            [-98.9773, 38.1893],
            [-98.9126, 38.2611],
            [-98.7847, 38.2609],
            [-98.7482, 38.2028],
            [-98.9131, 38.0583],
            [-98.8486, 38.0001],
            [-98.8394, 37.9271],
            [-98.9038, 37.8615],
            [-99.0135, 37.733],
            [-99.1233, 37.7335],
            [-99.1326, 37.9126],
            [-99.105, 38],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009060',
        NAME: 'Macksville Unified School District 351',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 949653980,
        AWATER: 155603,
        INTPTLAT: '+37.9785081',
        INTPTLON: '-098.9586875',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1028, 38.1484],
            [-100.8836, 38.1491],
            [-100.8835, 38.0476],
            [-100.9388, 38.0034],
            [-100.9437, 37.8094],
            [-101.0898, 37.809],
            [-101.1038, 37.829],
            [-101.1028, 38.1484],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007350',
        NAME: 'Holcomb Unified School District 363',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 603467542,
        AWATER: 336384,
        INTPTLAT: '+37.9931557',
        INTPTLON: '-101.0020028',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4094, 38.0008],
            [-100.3909, 38.1465],
            [-100.3353, 38.1469],
            [-100.3366, 38.2626],
            [-100.2444, 38.2623],
            [-100.2271, 38.2622],
            [-100.2268, 37.972],
            [-100.1541, 37.9138],
            [-100.2271, 37.914],
            [-100.2148, 37.7659],
            [-100.1062, 37.6932],
            [-100.1071, 37.5621],
            [-100.1072, 37.5331],
            [-100.307, 37.5261],
            [-100.3425, 37.6936],
            [-100.4335, 37.708],
            [-100.4094, 38.0008],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004800',
        NAME: 'Cimarron-Ensign Unified School District 102',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1394668680,
        AWATER: 629336,
        INTPTLAT: '+37.8490790',
        INTPTLON: '-100.2731336',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5085, 38.2609],
            [-98.4806, 38.4201],
            [-98.2571, 38.4495],
            [-98.257, 38.297],
            [-98.3805, 38.2607],
            [-98.363, 38.137],
            [-98.4727, 38.122],
            [-98.5085, 38.2609],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004650',
        NAME: 'Chase-Raymond Unified School District 401',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 522203637,
        AWATER: 1198762,
        INTPTLAT: '+38.2967915',
        INTPTLON: '-098.3785881',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2571, 38.4495],
            [-98.257, 38.4931],
            [-98.211, 38.4788],
            [-98.0902, 38.4493],
            [-98.0353, 38.3475],
            [-98.0542, 38.282],
            [-98.257, 38.297],
            [-98.2571, 38.4495],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009030',
        NAME: 'Lyons Unified School District 405',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300617809,
        AWATER: 128042,
        INTPTLAT: '+38.3714537',
        INTPTLON: '-098.1567903',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.257, 38.297],
            [-98.0542, 38.282],
            [-98.0543, 38.2605],
            [-98.1059, 38.1739],
            [-98.3259, 38.1223],
            [-98.363, 38.137],
            [-98.3805, 38.2607],
            [-98.257, 38.297],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012030',
        NAME: 'Sterling Unified School District 376',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402836022,
        AWATER: 2534590,
        INTPTLAT: '+38.2079523',
        INTPTLON: '-098.2261590',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9315, 39.6538],
            [-97.9021, 39.4811],
            [-97.9106, 39.2919],
            [-97.9297, 39.2193],
            [-98.2469, 39.234],
            [-98.3028, 39.3065],
            [-98.2284, 39.3935],
            [-98.2282, 39.5674],
            [-98.393, 39.5674],
            [-98.3628, 39.6543],
            [-97.9315, 39.6538],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003870',
        NAME: 'Beloit Unified School District 273',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1456533397,
        AWATER: 1203228,
        INTPTLAT: '+39.4469427',
        INTPTLON: '-098.1001976',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7509, 39.2204],
            [-98.6759, 39.2345],
            [-98.6202, 39.1768],
            [-98.5274, 39.2271],
            [-98.4325, 39.3068],
            [-98.3028, 39.3065],
            [-98.2469, 39.234],
            [-98.312, 39.1829],
            [-98.2526, 39.0673],
            [-98.2628, 38.8711],
            [-98.3187, 38.8708],
            [-98.4849, 38.8708],
            [-98.4853, 38.958],
            [-98.5961, 38.9586],
            [-98.7054, 39.002],
            [-98.7427, 39.0601],
            [-98.7509, 39.2204],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012120',
        NAME: 'Sylvan Grove Unified School District 299',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1460536737,
        AWATER: 3933316,
        INTPTLAT: '+39.0817013',
        INTPTLON: '-098.4753762',
        ELSDLEA: '',
        UNSDLEA: '12120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2469, 39.234],
            [-97.9297, 39.2193],
            [-97.9288, 39.1035],
            [-97.9286, 38.8711],
            [-98.0401, 38.871],
            [-98.2628, 38.8711],
            [-98.2526, 39.0673],
            [-98.312, 39.1829],
            [-98.2469, 39.234],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008790',
        NAME: 'Lincoln Unified School District 298',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1148429806,
        AWATER: 1710736,
        INTPTLAT: '+39.0519128',
        INTPTLON: '-098.1124612',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6203, 37.3479],
            [-95.5665, 37.3694],
            [-95.5794, 37.4787],
            [-95.525, 37.5147],
            [-95.4341, 37.4568],
            [-95.4984, 37.384],
            [-95.5218, 37.1285],
            [-95.6296, 37.1816],
            [-95.6203, 37.3479],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004740',
        NAME: 'Cherryvale Unified School District 447',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 353150295,
        AWATER: 816654,
        INTPTLAT: '+37.3287146',
        INTPTLON: '-095.5501887',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5218, 37.1285],
            [-95.4984, 37.384],
            [-95.2852, 37.3841],
            [-95.2811, 37.3256],
            [-95.1615, 37.3107],
            [-95.1583, 37.1934],
            [-95.159, 36.9996],
            [-95.4076, 36.9993],
            [-95.5224, 36.9993],
            [-95.5218, 37.1285],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003300',
        NAME: 'Labette County Unified School District 506',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1287315047,
        AWATER: 12559485,
        INTPTLAT: '+37.1805532',
        INTPTLON: '-095.3468692',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9338, 37.4545],
            [-96.7286, 37.5225],
            [-96.7252, 37.4756],
            [-96.5257, 37.4764],
            [-96.5253, 37.4034],
            [-96.3891, 37.4034],
            [-96.3896, 37.3529],
            [-96.4892, 37.3023],
            [-96.6079, 37.2294],
            [-96.7524, 37.2367],
            [-96.8795, 37.3203],
            [-96.8888, 37.3753],
            [-96.9338, 37.4545],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004230',
        NAME: 'Central Unified School District 462',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 904710351,
        AWATER: 5823143,
        INTPTLAT: '+37.3738223',
        INTPTLON: '-096.6915277',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1615, 37.3107],
            [-95.0752, 37.296],
            [-95.0682, 37.2227],
            [-95.0742, 37.146],
            [-95.1583, 37.1934],
            [-95.1615, 37.3107],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010350',
        NAME: 'Oswego Unified School District 504',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111173124,
        AWATER: 2454920,
        INTPTLAT: '+37.2264502',
        INTPTLON: '-095.1056060',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5382, 38.936],
            [-97.538, 38.9941],
            [-97.427, 39.0235],
            [-97.4456, 39.1106],
            [-97.4085, 39.1176],
            [-97.3711, 39.103],
            [-97.3345, 39.0306],
            [-97.2977, 38.9584],
            [-97.3167, 38.7836],
            [-97.3539, 38.7835],
            [-97.4092, 38.856],
            [-97.5205, 38.8557],
            [-97.5382, 38.936],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011760',
        NAME: 'Solomon Unified School District 393',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463338729,
        AWATER: 1196588,
        INTPTLAT: '+38.9360554',
        INTPTLON: '-097.3998195',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9774, 38.2166],
            [-97.9405, 38.2172],
            [-97.851, 38.3478],
            [-97.8141, 38.348],
            [-97.6578, 38.268],
            [-97.6474, 38.174],
            [-97.9405, 38.1737],
            [-97.9774, 38.2166],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007710',
        NAME: 'Inman Unified School District 448',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369994879,
        AWATER: 709842,
        INTPTLAT: '+38.2371344',
        INTPTLON: '-097.8039547',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.835, 39.1195],
            [-94.7891, 39.2012],
            [-94.7891, 39.2078],
            [-94.6017, 39.1596],
            [-94.6074, 39.1134],
            [-94.6074, 39.0441],
            [-94.6491, 39.044],
            [-94.7625, 39.0654],
            [-94.835, 39.1195],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007950',
        NAME: 'Kansas City Unified School District 500',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176556620,
        AWATER: 7320054,
        INTPTLAT: '+39.1258773',
        INTPTLON: '-094.6952089',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3345, 39.0306],
            [-97.2039, 39.0314],
            [-97.1483, 38.9814],
            [-97.1486, 38.864],
            [-97.2794, 38.842],
            [-97.3167, 38.7836],
            [-97.2977, 38.9584],
            [-97.3345, 39.0306],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003180',
        NAME: 'Abilene Unified School District 435',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261868591,
        AWATER: 1926898,
        INTPTLAT: '+38.9339307',
        INTPTLON: '-097.2396988',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3711, 39.103],
            [-97.3712, 39.132],
            [-96.9614, 39.1323],
            [-96.961, 39.1971],
            [-96.9073, 39.0895],
            [-96.9168, 38.9721],
            [-96.8425, 38.9877],
            [-96.8072, 38.8994],
            [-96.9176, 38.8342],
            [-97.02, 38.8273],
            [-97.0383, 38.7545],
            [-97.2236, 38.74],
            [-97.261, 38.6093],
            [-97.3531, 38.6092],
            [-97.3539, 38.7835],
            [-97.3167, 38.7836],
            [-97.2794, 38.842],
            [-97.1486, 38.864],
            [-97.1483, 38.9814],
            [-97.2039, 39.0314],
            [-97.3345, 39.0306],
            [-97.3711, 39.103],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004620',
        NAME: 'Chapman Unified School District 473',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1423230283,
        AWATER: 30642561,
        INTPTLAT: '+38.9292773',
        INTPTLON: '-097.1086267',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5575, 39.7359],
            [-96.4636, 39.798],
            [-96.3701, 39.8057],
            [-96.2488, 39.7838],
            [-96.0707, 39.7691],
            [-96.0708, 39.6967],
            [-95.977, 39.682],
            [-95.9768, 39.6128],
            [-95.9767, 39.6095],
            [-96.0141, 39.5661],
            [-96.2391, 39.5807],
            [-96.3701, 39.5661],
            [-96.4823, 39.566],
            [-96.5382, 39.5808],
            [-96.5201, 39.6745],
            [-96.5575, 39.7359],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004560',
        NAME: 'Vermillion Unified School District 380',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1038108759,
        AWATER: 6311004,
        INTPTLAT: '+39.6748688',
        INTPTLON: '-096.2865223',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9609, 39.4161],
            [-96.8112, 39.4221],
            [-96.7377, 39.3492],
            [-96.6247, 39.3125],
            [-96.5572, 39.3345],
            [-96.5572, 39.2761],
            [-96.6442, 39.2622],
            [-96.6819, 39.1748],
            [-96.7102, 39.2187],
            [-96.9614, 39.2201],
            [-96.9609, 39.4161],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011100',
        NAME: 'Riley County Unified School District 378',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579921648,
        AWATER: 18961267,
        INTPTLAT: '+39.2882969',
        INTPTLON: '-096.8025269',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8065, 39.7984],
            [-96.5575, 39.7359],
            [-96.5201, 39.6745],
            [-96.5382, 39.5808],
            [-96.8253, 39.5664],
            [-96.853, 39.6606],
            [-96.8065, 39.7984],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012780',
        NAME: 'Valley Heights Unified School District 498',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559155000,
        AWATER: 4434520,
        INTPTLAT: '+39.6700538',
        INTPTLON: '-096.6864976',
        ELSDLEA: '',
        UNSDLEA: '12780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6819, 39.1748],
            [-96.6442, 39.2622],
            [-96.5572, 39.2761],
            [-96.4731, 39.2474],
            [-96.4631, 39.1673],
            [-96.4283, 39.065],
            [-96.4824, 39.0433],
            [-96.5203, 38.9133],
            [-96.562, 38.9717],
            [-96.5318, 39.0728],
            [-96.6801, 39.1023],
            [-96.6819, 39.1748],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009180',
        NAME: 'Manhattan Unified School District 383',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440932060,
        AWATER: 9734317,
        INTPTLAT: '+39.1484048',
        INTPTLON: '-096.5488457',
        ELSDLEA: '',
        UNSDLEA: '09180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5572, 39.2761],
            [-96.5572, 39.3345],
            [-96.5573, 39.3996],
            [-96.4823, 39.566],
            [-96.3701, 39.5661],
            [-96.3795, 39.4788],
            [-96.2767, 39.4352],
            [-96.2771, 39.3625],
            [-96.371, 39.3334],
            [-96.3612, 39.1959],
            [-96.4631, 39.1673],
            [-96.4731, 39.2474],
            [-96.5572, 39.2761],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000004',
        NAME: 'Rock Creek Unified School District 323',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 635005975,
        AWATER: 2819912,
        INTPTLAT: '+39.3646292',
        INTPTLON: '-096.4319809',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0935, 38.9931],
            [-95.0014, 39.0729],
            [-94.9932, 39.2013],
            [-94.9005, 39.1869],
            [-94.9084, 39.1223],
            [-94.9276, 39.0028],
            [-95.0563, 38.9821],
            [-95.0935, 38.9931],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003780',
        NAME: 'Basehor-Linwood Unified School District 458',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226638488,
        AWATER: 2946659,
        INTPTLAT: '+39.0823301',
        INTPTLON: '-094.9742914',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1331, 39.2594],
            [-95.0404, 39.2668],
            [-94.9932, 39.2013],
            [-95.0014, 39.0729],
            [-95.0935, 38.9931],
            [-95.1488, 38.9743],
            [-95.1871, 39.0441],
            [-95.188, 39.1289],
            [-95.1341, 39.1651],
            [-95.1331, 39.2594],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012210',
        NAME: 'Tonganoxie Unified School District 464',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357755552,
        AWATER: 2729984,
        INTPTLAT: '+39.1376644',
        INTPTLON: '-095.0802894',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4467, 38.9407],
            [-95.3076, 39.038],
            [-95.2561, 39.0441],
            [-95.1871, 39.0441],
            [-95.1488, 38.9743],
            [-95.1587, 38.8843],
            [-95.1957, 38.8918],
            [-95.2712, 38.8333],
            [-95.4277, 38.8261],
            [-95.4821, 38.8841],
            [-95.4467, 38.9407],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008400',
        NAME: 'Lawrence Unified School District 497',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421675143,
        AWATER: 32434656,
        INTPTLAT: '+38.9329683',
        INTPTLON: '-095.2908310',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2278, 39.734],
            [-97.1519, 39.7483],
            [-97.0311, 39.7116],
            [-96.919, 39.7113],
            [-96.9188, 39.7983],
            [-96.9279, 39.8057],
            [-96.9935, 39.9293],
            [-97.0131, 40.0014],
            [-97.0102, 40.0014],
            [-96.9832, 40.0016],
            [-96.9307, 40.0015],
            [-96.9192, 40.0015],
            [-96.9164, 40.0015],
            [-96.8058, 40.0015],
            [-96.7878, 39.8276],
            [-96.8065, 39.7984],
            [-96.853, 39.6606],
            [-96.8253, 39.5664],
            [-96.9589, 39.5664],
            [-97.0893, 39.5666],
            [-97.1812, 39.5886],
            [-97.2378, 39.6683],
            [-97.2654, 39.7267],
            [-97.2278, 39.734],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000012',
        NAME: 'Barnes Unified School District 223',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 994867488,
        AWATER: 5124526,
        INTPTLAT: '+39.8956217',
        INTPTLON: '-096.9046567',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9589, 39.5664],
            [-96.8253, 39.5664],
            [-96.5382, 39.5808],
            [-96.4823, 39.566],
            [-96.5573, 39.3996],
            [-96.5572, 39.3345],
            [-96.6247, 39.3125],
            [-96.7377, 39.3492],
            [-96.8112, 39.4221],
            [-96.9609, 39.4161],
            [-96.9589, 39.5664],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010980',
        NAME: 'Blue Valley Unified School District 384',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770791952,
        AWATER: 43398265,
        INTPTLAT: '+39.4747311',
        INTPTLON: '-096.7123798',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0513, 37.4711],
            [-94.9423, 37.4354],
            [-94.7588, 37.4112],
            [-94.7321, 37.3387],
            [-94.8323, 37.3067],
            [-94.8865, 37.2227],
            [-95.0682, 37.2227],
            [-95.0752, 37.296],
            [-95.1241, 37.3841],
            [-95.1425, 37.4426],
            [-95.0513, 37.4711],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004710',
        NAME: 'Cherokee Unified School District 247',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 689058048,
        AWATER: 5231950,
        INTPTLAT: '+37.3528540',
        INTPTLON: '-094.9541673',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.057, 38.9873],
            [-98.7054, 39.002],
            [-98.5961, 38.9586],
            [-98.597, 38.6969],
            [-98.6925, 38.6967],
            [-99.033, 38.6968],
            [-99.0423, 38.7118],
            [-99.0392, 38.9581],
            [-99.057, 38.9873],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011310',
        NAME: 'Russell County Unified School District 407',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1260986768,
        AWATER: 8528774,
        INTPTLAT: '+38.8500734',
        INTPTLON: '-098.8238871',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4727, 38.122],
            [-98.363, 38.137],
            [-98.3259, 38.1223],
            [-98.142, 38.0285],
            [-98.1421, 37.9341],
            [-98.0869, 37.9119],
            [-98.1141, 37.8317],
            [-98.2287, 37.815],
            [-98.2374, 37.747],
            [-98.3193, 37.7326],
            [-98.4648, 37.7327],
            [-98.5289, 37.8056],
            [-98.472, 37.8681],
            [-98.4727, 38.122],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008310',
        NAME: 'Fairfield Unified School District 310',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1150467277,
        AWATER: 1074425,
        INTPTLAT: '+37.9190925',
        INTPTLON: '-098.3164397',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.0698, 37.6744],
              [-95.0606, 37.6747],
              [-95.0606, 37.66],
              [-95.0699, 37.6599],
              [-95.0698, 37.6744],
            ],
          ],
          [
            [
              [-95.1336, 37.602],
              [-95.0699, 37.6599],
              [-95.0606, 37.66],
              [-95.0334, 37.6749],
              [-94.8331, 37.674],
              [-94.7765, 37.6741],
              [-94.751, 37.499],
              [-94.7599, 37.4257],
              [-94.7588, 37.4112],
              [-94.9423, 37.4354],
              [-95.0513, 37.4711],
              [-95.1336, 37.602],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006480',
        NAME: 'Girard Unified School District 248',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 708898799,
        AWATER: 4302640,
        INTPTLAT: '+37.5628954',
        INTPTLON: '-094.9147619',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5255, 37.7328],
            [-95.5252, 37.7473],
            [-95.2883, 37.7326],
            [-95.3793, 37.5583],
            [-95.5252, 37.5514],
            [-95.5255, 37.7328],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004590',
        NAME: 'Chanute Public Schools Unified School District 413',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332599135,
        AWATER: 3416639,
        INTPTLAT: '+37.6547101',
        INTPTLON: '-095.4288604',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7233, 38.7099],
            [-95.608, 38.6953],
            [-95.5458, 38.6374],
            [-95.6201, 38.5652],
            [-95.7658, 38.5216],
            [-95.7233, 38.7099],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009000',
        NAME: 'Lyndon Unified School District 421',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250793093,
        AWATER: 4366968,
        INTPTLAT: '+38.6274713',
        INTPTLON: '-095.6805924',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.672, 38.7257],
            [-99.5234, 38.7257],
            [-99.5234, 38.6966],
            [-99.2538, 38.6899],
            [-99.2529, 38.3507],
            [-99.5848, 38.3494],
            [-99.5863, 38.5384],
            [-99.6602, 38.5385],
            [-99.672, 38.7257],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008220',
        NAME: 'LaCrosse Unified School District 395',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1257461100,
        AWATER: 408801,
        INTPTLAT: '+38.5302079',
        INTPTLON: '-099.4446480',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3999, 39.3216],
            [-99.1397, 39.3297],
            [-99.1039, 39.2928],
            [-99.1039, 39.1767],
            [-99.1489, 39.0594],
            [-99.3891, 39.0741],
            [-99.3999, 39.3216],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010740',
        NAME: 'Plainville Unified School District 270',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 711996926,
        AWATER: 1802687,
        INTPTLAT: '+39.1963406',
        INTPTLON: '-099.2610362',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5921, 39.1132],
            [-99.4266, 39.0594],
            [-99.4474, 38.9291],
            [-99.5234, 38.7257],
            [-99.672, 38.7257],
            [-99.708, 38.7838],
            [-99.6191, 38.8559],
            [-99.6784, 38.9723],
            [-99.6681, 39.0674],
            [-99.5921, 39.1132],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005850',
        NAME: 'Ellis Unified School District 388',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 732387949,
        AWATER: 332198,
        INTPTLAT: '+38.9252780',
        INTPTLON: '-099.5707942',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1636, 39.2478],
            [-100.1622, 39.509],
            [-100.0782, 39.4655],
            [-99.8916, 39.5386],
            [-99.7322, 39.539],
            [-99.6021, 39.539],
            [-99.6016, 39.481],
            [-99.6025, 39.3943],
            [-99.65, 39.3655],
            [-99.661, 39.2124],
            [-99.717, 39.1323],
            [-100.1479, 39.1322],
            [-100.1636, 39.2478],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007260',
        NAME: 'Hill City Unified School District 281',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1890659110,
        AWATER: 518771,
        INTPTLAT: '+39.3282150',
        INTPTLON: '-099.9003138',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1479, 39.1322],
            [-99.717, 39.1323],
            [-99.5921, 39.1132],
            [-99.6681, 39.0674],
            [-99.6784, 38.9723],
            [-99.6191, 38.8559],
            [-99.708, 38.7838],
            [-99.7829, 38.7403],
            [-100.0616, 38.6965],
            [-100.1538, 38.6973],
            [-100.1479, 39.1322],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012630',
        NAME: 'WaKeeney Unified School District 208',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1865383175,
        AWATER: 24115946,
        INTPTLAT: '+38.9356239',
        INTPTLON: '-099.9007816',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0397, 38.7862],
            [-100.9658, 38.6993],
            [-100.8187, 38.6999],
            [-100.688, 38.7],
            [-100.6866, 38.5262],
            [-100.6846, 38.2711],
            [-100.6297, 38.2639],
            [-101.1033, 38.2646],
            [-101.1254, 38.2645],
            [-101.1284, 38.7006],
            [-101.1601, 38.7008],
            [-101.0399, 38.7287],
            [-101.0397, 38.7862],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000017',
        NAME: 'Scott County Unified School District 466',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1951714994,
        AWATER: 306079,
        INTPTLAT: '+38.4885395',
        INTPTLON: '-100.9092835',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7138, 38.9351],
            [-94.608, 38.9368],
            [-94.608, 38.9199],
            [-94.608, 38.8472],
            [-94.6091, 38.7381],
            [-94.7232, 38.7384],
            [-94.7517, 38.8327],
            [-94.7138, 38.9351],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012000',
        NAME: 'Blue Valley Unified School District 229',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232021604,
        AWATER: 2395330,
        INTPTLAT: '+38.8316162',
        INTPTLON: '-094.6728149',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0562, 38.8114],
            [-95.0561, 38.8624],
            [-95.0375, 38.877],
            [-94.8902, 38.8691],
            [-94.8435, 38.8326],
            [-94.9177, 38.7093],
            [-94.964, 38.702],
            [-95.0564, 38.7462],
            [-95.0562, 38.8114],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006420',
        NAME: 'Gardner Edgerton Unified School District 231',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258806817,
        AWATER: 3166102,
        INTPTLAT: '+38.8039130',
        INTPTLON: '-094.9550340',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7973, 38.9741],
            [-94.7138, 38.9351],
            [-94.7517, 38.8327],
            [-94.8435, 38.8326],
            [-94.8902, 38.8691],
            [-94.8997, 38.9634],
            [-94.7973, 38.9741],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010140',
        NAME: 'Olathe Unified School District 233',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190218847,
        AWATER: 2277535,
        INTPTLAT: '+38.9013940',
        INTPTLON: '-094.8130015',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7799, 39.0437],
            [-94.6491, 39.044],
            [-94.6074, 39.0441],
            [-94.6083, 38.9747],
            [-94.608, 38.9368],
            [-94.7138, 38.9351],
            [-94.7973, 38.9741],
            [-94.7799, 39.0437],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011640',
        NAME: 'Shawnee Mission Public Schools Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193636895,
        AWATER: 1008751,
        INTPTLAT: '+38.9879704',
        INTPTLON: '-094.6936559',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8435, 38.8326],
            [-94.7517, 38.8327],
            [-94.7232, 38.7384],
            [-94.7603, 38.7018],
            [-94.9177, 38.7093],
            [-94.8435, 38.8326],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011850',
        NAME: 'Spring Hill Unified School District 230',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181560819,
        AWATER: 3624406,
        INTPTLAT: '+38.7601601',
        INTPTLON: '-094.8100218',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5436, 37.3522],
            [-99.5432, 37.3811],
            [-99.2836, 37.3827],
            [-99.1936, 37.4408],
            [-99.1214, 37.3837],
            [-99.0115, 37.3843],
            [-99.0013, 37.1512],
            [-99.0003, 36.9995],
            [-99.4562, 36.9995],
            [-99.5411, 36.9996],
            [-99.5422, 37.1184],
            [-99.6237, 37.1192],
            [-99.6078, 37.2064],
            [-99.5447, 37.2605],
            [-99.5436, 37.3522],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005040',
        NAME: 'Comanche County Unified School District 300',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2223200500,
        AWATER: 3810666,
        INTPTLAT: '+37.1997544',
        INTPTLON: '-099.2839223',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7914, 38.6856],
            [-96.8565, 38.6239],
            [-97.004, 38.6236],
            [-96.983, 38.7434],
            [-96.8532, 38.7542],
            [-96.7914, 38.6856],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007110',
        NAME: 'Herington Unified School District 487',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239066591,
        AWATER: 2919977,
        INTPTLAT: '+38.6854570',
        INTPTLON: '-096.9146936',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7914, 38.6856],
            [-96.6495, 38.7393],
            [-96.6501, 38.8122],
            [-96.7053, 38.8993],
            [-96.6952, 38.9435],
            [-96.562, 38.9717],
            [-96.5203, 38.9133],
            [-96.4362, 38.8257],
            [-96.3175, 38.8119],
            [-96.2703, 38.7536],
            [-96.3348, 38.7392],
            [-96.3538, 38.5217],
            [-96.7454, 38.5367],
            [-96.7084, 38.6092],
            [-96.8565, 38.6239],
            [-96.7914, 38.6856],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005280',
        NAME: 'Morris County Unified School District 417',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1365045012,
        AWATER: 18359827,
        INTPTLAT: '+38.6989226',
        INTPTLON: '-096.5380166',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1541, 37.9138],
            [-99.8539, 37.9133],
            [-99.8519, 37.7271],
            [-99.7969, 37.72],
            [-99.7954, 37.5857],
            [-99.8789, 37.5805],
            [-100.1071, 37.5621],
            [-100.1062, 37.6932],
            [-100.2148, 37.7659],
            [-100.2271, 37.914],
            [-100.1541, 37.9138],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005580',
        NAME: 'Dodge City Unified School District 443',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1112144114,
        AWATER: 1625767,
        INTPTLAT: '+37.7509679',
        INTPTLON: '-099.9968851',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.673, 40.0003],
            [-95.3686, 40],
            [-95.3398, 39.9584],
            [-95.3491, 39.733],
            [-95.3961, 39.7618],
            [-95.6765, 39.7832],
            [-95.7138, 39.69],
            [-95.789, 39.7696],
            [-95.7518, 39.8776],
            [-95.6394, 39.9283],
            [-95.673, 40.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000006',
        NAME: 'Hiawatha Unified School District 415',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 845706351,
        AWATER: 1444096,
        INTPTLAT: '+39.8664478',
        INTPTLON: '-095.5416720',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4501, 39.274],
            [-95.3666, 39.274],
            [-95.2547, 39.2743],
            [-95.271, 39.1289],
            [-95.4501, 39.1503],
            [-95.4501, 39.274],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010320',
        NAME: 'Oskaloosa Public Schools Unified School District 341',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243647729,
        AWATER: 22922230,
        INTPTLAT: '+39.1996052',
        INTPTLON: '-095.3588915',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7314, 39.0725],
            [-95.6311, 39.0854],
            [-95.6877, 38.9917],
            [-95.7314, 39.0725],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012260',
        NAME: 'Topeka Public Schools Unified School District 501',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92135810,
        AWATER: 1808267,
        INTPTLAT: '+39.0378304',
        INTPTLON: '-095.6881790',
        ELSDLEA: '',
        UNSDLEA: '12260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9642, 37.1833],
            [-95.7926, 37.1822],
            [-95.7468, 37.0877],
            [-95.7548, 36.9992],
            [-95.7868, 36.9993],
            [-96.0008, 36.9989],
            [-96.0358, 36.999],
            [-95.9642, 37.1833],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004410',
        NAME: 'Caney Valley Unified School District 436',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410507447,
        AWATER: 2831144,
        INTPTLAT: '+37.0748971',
        INTPTLON: '-095.8841752',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9987, 37.5097],
            [-95.9614, 37.3868],
            [-95.8156, 37.3857],
            [-95.7836, 37.3274],
            [-95.9013, 37.2992],
            [-95.9825, 37.2399],
            [-96.0007, 37.2788],
            [-96.145, 37.3006],
            [-96.126, 37.4313],
            [-96.0345, 37.4305],
            [-95.9987, 37.5097],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005760',
        NAME: 'Elk Valley Unified School District 283',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411118004,
        AWATER: 2768360,
        INTPTLAT: '+37.3513541',
        INTPTLON: '-095.9774631',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9825, 37.2399],
            [-95.9013, 37.2992],
            [-95.7836, 37.3274],
            [-95.6203, 37.3479],
            [-95.6296, 37.1816],
            [-95.7469, 37.128],
            [-95.7468, 37.0877],
            [-95.7926, 37.1822],
            [-95.9642, 37.1833],
            [-95.9825, 37.2399],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007650',
        NAME: 'Independence Unified School District 446',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 541400543,
        AWATER: 16556877,
        INTPTLAT: '+37.2336371',
        INTPTLON: '-095.7739927',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7468, 37.0877],
            [-95.7469, 37.128],
            [-95.6296, 37.1816],
            [-95.5218, 37.1285],
            [-95.5224, 36.9993],
            [-95.6604, 36.9993],
            [-95.7548, 36.9992],
            [-95.7468, 37.0877],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004980',
        NAME: 'Coffeyville Unified School District 445',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343470628,
        AWATER: 531608,
        INTPTLAT: '+37.0846966',
        INTPTLON: '-095.6402201',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7199, 37.4863],
            [-95.5794, 37.4787],
            [-95.5665, 37.3694],
            [-95.6203, 37.3479],
            [-95.7836, 37.3274],
            [-95.8156, 37.3857],
            [-95.8156, 37.4584],
            [-95.7199, 37.4863],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009900',
        NAME: 'Neodesha Unified School District 461',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296772732,
        AWATER: 2335562,
        INTPTLAT: '+37.4067708',
        INTPTLON: '-095.6832714',
        ELSDLEA: '',
        UNSDLEA: '09900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8268, 38.8619],
            [-95.6872, 38.8701],
            [-95.4821, 38.8841],
            [-95.4277, 38.8261],
            [-95.3993, 38.7389],
            [-95.5193, 38.7389],
            [-95.608, 38.6953],
            [-95.7233, 38.7099],
            [-95.7604, 38.7099],
            [-95.8268, 38.8619],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010410',
        NAME: 'Santa Fe Trail Unified School District 434',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517980292,
        AWATER: 3737902,
        INTPTLAT: '+38.7899912',
        INTPTLON: '-095.6216525',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2325, 38.7311],
            [-95.1306, 38.7386],
            [-95.0562, 38.8114],
            [-95.0564, 38.7462],
            [-94.964, 38.702],
            [-95.0929, 38.5772],
            [-95.1746, 38.5647],
            [-95.2325, 38.7311],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012870',
        NAME: 'Wellsville Unified School District 289',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319522294,
        AWATER: 4052310,
        INTPTLAT: '+38.6836560',
        INTPTLON: '-095.1042990',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3184, 38.5136],
            [-95.1942, 38.4705],
            [-95.1746, 38.5647],
            [-95.0929, 38.5772],
            [-95.0651, 38.5571],
            [-95.0659, 38.3972],
            [-95.2759, 38.3974],
            [-95.3602, 38.4193],
            [-95.3184, 38.5136],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000014',
        NAME: 'Central Heights Unified School District 288',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344638346,
        AWATER: 4156951,
        INTPTLAT: '+38.4689727',
        INTPTLON: '-095.2048588',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8021, 38.506],
            [-95.7658, 38.5216],
            [-95.6201, 38.5652],
            [-95.5458, 38.6374],
            [-95.4903, 38.5834],
            [-95.5268, 38.434],
            [-95.7843, 38.4417],
            [-95.8021, 38.506],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009480',
        NAME: 'Marais des Cygnes Valley Unified School District 456',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336142150,
        AWATER: 12154968,
        INTPTLAT: '+38.5106170',
        INTPTLON: '-095.6104946',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2696, 38.7313],
            [-95.2325, 38.7311],
            [-95.1746, 38.5647],
            [-95.1942, 38.4705],
            [-95.3184, 38.5136],
            [-95.39, 38.5649],
            [-95.2674, 38.6879],
            [-95.2696, 38.7313],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000015',
        NAME: 'Ottawa Unified School District 290',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301056058,
        AWATER: 2807928,
        INTPTLAT: '+38.5927673',
        INTPTLON: '-095.2559336',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9502, 38.4414],
            [-95.8393, 38.5058],
            [-95.8021, 38.506],
            [-95.7843, 38.4417],
            [-95.5268, 38.434],
            [-95.5083, 38.3903],
            [-95.5078, 38.314],
            [-95.6105, 38.2509],
            [-95.7201, 38.34],
            [-95.7935, 38.2885],
            [-95.9044, 38.2976],
            [-95.9502, 38.4414],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012810',
        NAME: 'Lebo-Waverly Unified School District 243',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 648279529,
        AWATER: 19922723,
        INTPTLAT: '+38.3729327',
        INTPTLON: '-095.7227218',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5075, 37.5926],
            [-96.3529, 37.5776],
            [-96.3078, 37.6647],
            [-96.1516, 37.7111],
            [-96.1022, 37.668],
            [-96.1257, 37.5184],
            [-95.9987, 37.5097],
            [-96.0345, 37.4305],
            [-96.126, 37.4313],
            [-96.145, 37.3006],
            [-96.2358, 37.251],
            [-96.3902, 37.273],
            [-96.4892, 37.3023],
            [-96.3896, 37.3529],
            [-96.3891, 37.4034],
            [-96.5253, 37.4034],
            [-96.5257, 37.4764],
            [-96.5075, 37.5926],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007500',
        NAME: 'West Elk Unified School District 282',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1389175361,
        AWATER: 13875847,
        INTPTLAT: '+37.4654830',
        INTPTLON: '-096.2832009',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6079, 37.2294],
            [-96.4892, 37.3023],
            [-96.3902, 37.273],
            [-96.3358, 37.1652],
            [-96.3453, 36.9993],
            [-96.3803, 36.9993],
            [-96.4152, 36.9992],
            [-96.6074, 36.9989],
            [-96.6079, 37.2294],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004500',
        NAME: 'Cedar Vale Unified School District 285',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 650127372,
        AWATER: 5464716,
        INTPTLAT: '+37.1406115',
        INTPTLON: '-096.4804606',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3902, 37.273],
            [-96.2358, 37.251],
            [-96.145, 37.3006],
            [-96.0007, 37.2788],
            [-95.9825, 37.2399],
            [-95.9642, 37.1833],
            [-96.0358, 36.999],
            [-96.3453, 36.9993],
            [-96.3358, 37.1652],
            [-96.3902, 37.273],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011520',
        NAME: 'Chautauqua County Community Unified School District 286',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 974693124,
        AWATER: 9519909,
        INTPTLAT: '+37.1486594',
        INTPTLON: '-096.1684797',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1488, 38.9743],
            [-95.0935, 38.9931],
            [-95.0563, 38.9821],
            [-95.0375, 38.877],
            [-95.0561, 38.8624],
            [-95.1587, 38.8843],
            [-95.1488, 38.9743],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006090',
        NAME: 'Eudora Unified School District 491',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134527638,
        AWATER: 3528902,
        INTPTLAT: '+38.9184315',
        INTPTLON: '-095.0977581',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6877, 38.9917],
            [-95.6311, 39.0854],
            [-95.5948, 39.0864],
            [-95.4638, 39.0293],
            [-95.4467, 38.9407],
            [-95.4821, 38.8841],
            [-95.6872, 38.8701],
            [-95.6877, 38.9917],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012180',
        NAME: 'Shawnee Heights Unified School District 450',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361912552,
        AWATER: 7731876,
        INTPTLAT: '+38.9634629',
        INTPTLON: '-095.5693359',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3719, 38.5076],
            [-97.3719, 38.6094],
            [-97.3531, 38.6092],
            [-97.261, 38.6093],
            [-97.2249, 38.5222],
            [-97.1324, 38.4351],
            [-97.1056, 38.2611],
            [-97.1781, 38.2547],
            [-97.1798, 38.2185],
            [-97.2164, 38.2147],
            [-97.2796, 38.3333],
            [-97.3717, 38.3625],
            [-97.3719, 38.5076],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007290',
        NAME: 'Durham-Hillsboro-Lehigh Unified School District 410',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 631775799,
        AWATER: 12237801,
        INTPTLAT: '+38.4237050',
        INTPTLON: '-097.2464869',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1324, 38.4351],
            [-96.9667, 38.4064],
            [-96.8196, 38.4066],
            [-96.8408, 38.169],
            [-96.9779, 38.1878],
            [-97.1056, 38.2611],
            [-97.1324, 38.4351],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009240',
        NAME: 'Marion-Florence Unified School District 408',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610408454,
        AWATER: 10298551,
        INTPTLAT: '+38.3006572',
        INTPTLON: '-096.9769265',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8492, 39.2165],
            [-95.7664, 39.2165],
            [-95.7614, 39.0728],
            [-95.8623, 39.0842],
            [-95.8492, 39.2165],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011700',
        NAME: 'Silver Lake Unified School District 372',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180009238,
        AWATER: 1957591,
        INTPTLAT: '+39.1419668',
        INTPTLON: '-095.8403898',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3553, 38.3039],
            [-96.2539, 38.3327],
            [-96.116, 38.3323],
            [-96.0793, 38.4414],
            [-95.9502, 38.4414],
            [-95.9044, 38.2976],
            [-95.9544, 38.2073],
            [-95.9589, 38.1854],
            [-96.239, 38.2019],
            [-96.2749, 38.1798],
            [-96.3573, 38.1727],
            [-96.3553, 38.3039],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006930',
        NAME: 'Southern Lyon County Unified School District 252',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 759186839,
        AWATER: 7254797,
        INTPTLAT: '+38.2850606',
        INTPTLON: '-096.1105589',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3542, 38.4638],
            [-96.0793, 38.4414],
            [-96.116, 38.3323],
            [-96.2539, 38.3327],
            [-96.3553, 38.3039],
            [-96.3183, 38.3327],
            [-96.3542, 38.4638],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005940',
        NAME: 'Emporia Unified School District 253',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334808288,
        AWATER: 4723406,
        INTPTLAT: '+38.4008851',
        INTPTLON: '-096.2221843',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5614, 37.6788],
            [-100.4335, 37.708],
            [-100.3425, 37.6936],
            [-100.307, 37.5261],
            [-100.3701, 37.4606],
            [-100.4794, 37.4747],
            [-100.543, 37.4748],
            [-100.5614, 37.6788],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009630',
        NAME: 'Montezuma Unified School District 371',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 521390597,
        AWATER: 584028,
        INTPTLAT: '+37.5833157',
        INTPTLON: '-100.4401399',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7769, 39.2926],
            [-100.7125, 39.2926],
            [-100.5359, 39.2055],
            [-100.5559, 39.0033],
            [-100.6305, 38.9606],
            [-100.6313, 38.8592],
            [-100.7615, 38.8591],
            [-100.7567, 39.1333],
            [-100.7769, 39.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006780',
        NAME: 'Grinnell Public Schools Unified School District 291',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 684775706,
        AWATER: 202494,
        INTPTLAT: '+39.0843211',
        INTPTLON: '-100.6753585',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8422, 39.3216],
            [-100.7769, 39.2926],
            [-100.7567, 39.1333],
            [-100.7615, 38.8591],
            [-100.8178, 38.7878],
            [-100.6885, 38.7582],
            [-100.8187, 38.6999],
            [-100.9658, 38.6993],
            [-101.0397, 38.7862],
            [-101.0549, 38.96],
            [-101.1106, 38.9611],
            [-101.0934, 39.1914],
            [-101.1121, 39.2061],
            [-100.9437, 39.2347],
            [-100.8422, 39.3216],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010050',
        NAME: 'Oakley Unified School District 274',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1636001856,
        AWATER: 214256,
        INTPTLAT: '+39.0035972',
        INTPTLON: '-100.9152559',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3701, 37.4606],
            [-100.307, 37.5261],
            [-100.1072, 37.5331],
            [-100.0915, 37.2548],
            [-100.0902, 37.095],
            [-100.1985, 37.0581],
            [-100.1819, 37.2409],
            [-100.3701, 37.4606],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006210',
        NAME: 'Fowler Unified School District 225',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701258897,
        AWATER: 120827,
        INTPTLAT: '+37.3496285',
        INTPTLON: '-100.1916425',
        ELSDLEA: '',
        UNSDLEA: '06210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8539, 37.9133],
            [-99.6613, 37.9277],
            [-99.607, 37.8622],
            [-99.5636, 37.7319],
            [-99.7969, 37.72],
            [-99.8519, 37.7271],
            [-99.8539, 37.9133],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011820',
        NAME: 'Spearville Unified School District 381',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490924055,
        AWATER: 100796,
        INTPTLAT: '+37.8333340',
        INTPTLON: '-099.7314892',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5641, 39.6094],
            [-95.5641, 39.6529],
            [-95.3397, 39.653],
            [-95.1152, 39.6525],
            [-95.1086, 39.6382],
            [-95.1992, 39.5426],
            [-95.0662, 39.4626],
            [-95.0665, 39.4263],
            [-95.1804, 39.4117],
            [-95.3289, 39.4418],
            [-95.3947, 39.4277],
            [-95.5482, 39.4121],
            [-95.6026, 39.5132],
            [-95.5641, 39.6094],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005700',
        NAME: 'Atchison County Community Schools Unified School District 377',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 932439734,
        AWATER: 2216114,
        INTPTLAT: '+39.5351637',
        INTPTLON: '-095.3417585',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6266, 39.3176],
            [-95.5482, 39.4121],
            [-95.3947, 39.4277],
            [-95.3666, 39.274],
            [-95.4501, 39.274],
            [-95.589, 39.2739],
            [-95.6266, 39.3176],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012540',
        NAME: 'Valley Falls Unified School District 338',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291960078,
        AWATER: 4406594,
        INTPTLAT: '+39.3434966',
        INTPTLON: '-095.4921147',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1086, 39.6382],
            [-95.0536, 39.6153],
            [-95.1029, 39.5333],
            [-94.969, 39.4189],
            [-95.0477, 39.419],
            [-95.0572, 39.4224],
            [-95.0665, 39.4263],
            [-95.0662, 39.4626],
            [-95.1992, 39.5426],
            [-95.1086, 39.6382],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003540',
        NAME: 'Atchison Public Schools Unified School District 409',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143344982,
        AWATER: 4544002,
        INTPTLAT: '+39.5869038',
        INTPTLON: '-095.1573639',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0141, 39.5661],
            [-95.9767, 39.6095],
            [-95.9579, 39.6097],
            [-95.9016, 39.566],
            [-95.794, 39.5804],
            [-95.7141, 39.6531],
            [-95.611, 39.6528],
            [-95.5641, 39.6094],
            [-95.6026, 39.5132],
            [-95.7928, 39.5139],
            [-95.9047, 39.478],
            [-95.9047, 39.3904],
            [-95.9233, 39.3904],
            [-95.9795, 39.3907],
            [-96.0141, 39.5661],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004830',
        NAME: 'North Jackson Unified School District 335',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550262606,
        AWATER: 785859,
        INTPTLAT: '+39.5449589',
        INTPTLON: '-095.8020772',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1804, 39.4117],
            [-95.0665, 39.4263],
            [-95.0572, 39.4224],
            [-95.0477, 39.419],
            [-94.969, 39.4189],
            [-94.9382, 39.3871],
            [-94.9581, 39.3318],
            [-94.9733, 39.2883],
            [-95.0404, 39.2668],
            [-95.1331, 39.2594],
            [-95.1802, 39.282],
            [-95.1804, 39.4117],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005640',
        NAME: 'Easton Unified School District 449',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308339836,
        AWATER: 1550520,
        INTPTLAT: '+39.3532959',
        INTPTLON: '-095.0719107',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0537, 38.5367],
            [-97.8875, 38.5153],
            [-97.814, 38.4354],
            [-97.8141, 38.348],
            [-97.851, 38.3478],
            [-97.9405, 38.2172],
            [-97.9774, 38.2166],
            [-98.0543, 38.2605],
            [-98.0542, 38.282],
            [-98.0353, 38.3475],
            [-98.0902, 38.4493],
            [-98.211, 38.4788],
            [-98.1274, 38.4785],
            [-98.0537, 38.5367],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008880',
        NAME: 'Little River Unified School District 444',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 635955521,
        AWATER: 773899,
        INTPTLAT: '+38.3852698',
        INTPTLON: '-097.9668262',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3259, 38.1223],
            [-98.1059, 38.1739],
            [-98.0543, 38.2605],
            [-97.9774, 38.2166],
            [-97.9405, 38.1737],
            [-97.9502, 38.0936],
            [-97.9271, 38.0291],
            [-98.142, 38.0285],
            [-98.3259, 38.1223],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009990',
        NAME: 'Nickerson Unified School District 309',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475769218,
        AWATER: 3467966,
        INTPTLAT: '+38.1183376',
        INTPTLON: '-098.0726819',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7615, 38.8591],
            [-100.6313, 38.8592],
            [-100.6305, 38.9606],
            [-100.5559, 39.0033],
            [-100.5359, 39.2055],
            [-100.4428, 39.191],
            [-100.3596, 39.2343],
            [-100.3037, 39.1905],
            [-100.2939, 39.176],
            [-100.334, 39.0823],
            [-100.3363, 38.8437],
            [-100.5309, 38.7865],
            [-100.6885, 38.7582],
            [-100.8178, 38.7878],
            [-100.7615, 38.8591],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006630',
        NAME: 'Wheatland Unified School District 292',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1127302341,
        AWATER: 39745,
        INTPTLAT: '+38.9797629',
        INTPTLON: '-100.4978149',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3187, 38.8708],
            [-98.2628, 38.8711],
            [-98.0401, 38.871],
            [-97.9622, 38.6969],
            [-98.033, 38.6823],
            [-97.9801, 38.5733],
            [-98.0537, 38.5367],
            [-98.1274, 38.4785],
            [-98.211, 38.4788],
            [-98.257, 38.4931],
            [-98.1644, 38.5221],
            [-98.1647, 38.6094],
            [-98.3155, 38.6967],
            [-98.4398, 38.697],
            [-98.4396, 38.7836],
            [-98.3556, 38.7908],
            [-98.3187, 38.8708],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005870',
        NAME: 'Ellsworth Unified School District 327',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1096848934,
        AWATER: 11247639,
        INTPTLAT: '+38.7237427',
        INTPTLON: '-098.1723220',
        ELSDLEA: '',
        UNSDLEA: '05870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0467, 39.0756],
            [-101.923, 39.0607],
            [-101.9255, 38.9457],
            [-101.8145, 38.9315],
            [-101.7986, 38.7852],
            [-101.8548, 38.6979],
            [-102.0452, 38.6976],
            [-102.0466, 39.047],
            [-102.0467, 39.0756],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012900',
        NAME: 'Weskan Unified School District 242',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657765596,
        AWATER: 49287,
        INTPTLAT: '+38.8623662',
        INTPTLON: '-101.9553595',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8322, 40.0016],
            [-99.6094, 40.0019],
            [-99.5807, 40.0019],
            [-99.4141, 40.0019],
            [-99.3851, 40.0021],
            [-99.3846, 39.9006],
            [-99.4966, 39.8574],
            [-99.6272, 39.8721],
            [-99.6278, 39.756],
            [-99.7399, 39.7409],
            [-99.8322, 40.0016],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003270',
        NAME: 'Northern Valley Unified School District 212',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 675207151,
        AWATER: 413091,
        INTPTLAT: '+39.9090853',
        INTPTLON: '-099.6328281',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1802, 39.7305],
            [-100.1325, 39.871],
            [-100.1326, 39.9145],
            [-100.1315, 40.0017],
            [-100.1272, 40.0017],
            [-100.0992, 40.0016],
            [-100.043, 40.0017],
            [-100.0322, 40.0016],
            [-99.9577, 40.0017],
            [-99.8322, 40.0016],
            [-99.7399, 39.7409],
            [-99.7782, 39.6101],
            [-99.7322, 39.539],
            [-99.8916, 39.5386],
            [-100.0782, 39.4655],
            [-100.1622, 39.509],
            [-100.1802, 39.7305],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010020',
        NAME: 'Norton Community Schools Unified School District 211',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1755076041,
        AWATER: 8260927,
        INTPTLAT: '+39.7335283',
        INTPTLON: '-099.9739471',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5271, 37.7363],
            [-101.1985, 37.7363],
            [-101.0897, 37.7363],
            [-101.0898, 37.504],
            [-101.163, 37.417],
            [-101.2891, 37.453],
            [-101.3307, 37.3881],
            [-101.5258, 37.3884],
            [-101.5271, 37.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012420',
        NAME: 'Ulysses Unified School District 214',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1336649571,
        AWATER: 876764,
        INTPTLAT: '+37.5727331',
        INTPTLON: '-101.3211888',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9405, 38.1737],
            [-97.6474, 38.174],
            [-97.62, 38.1302],
            [-97.7482, 38.0214],
            [-97.8669, 38.043],
            [-97.9502, 38.0936],
            [-97.9405, 38.1737],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004200',
        NAME: 'Buhler Unified School District 313',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354198447,
        AWATER: 860532,
        INTPTLAT: '+38.1084302',
        INTPTLON: '-097.7847965',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7482, 38.0214],
            [-97.62, 38.1302],
            [-97.5921, 38.0869],
            [-97.6104, 37.8777],
            [-97.7068, 37.9116],
            [-97.7482, 38.0214],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004350',
        NAME: 'Burrton Unified School District 369',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245633667,
        AWATER: 1799202,
        INTPTLAT: '+37.9923687',
        INTPTLON: '-097.6553336',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.142, 38.0285],
            [-97.9271, 38.0291],
            [-97.8669, 38.043],
            [-97.7482, 38.0214],
            [-97.7068, 37.9116],
            [-97.6104, 37.8777],
            [-97.7996, 37.7708],
            [-97.913, 37.8249],
            [-97.9403, 37.9121],
            [-98.0869, 37.9119],
            [-98.1421, 37.9341],
            [-98.142, 38.0285],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006960',
        NAME: 'Haven Public Schools Unified School District 312',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 756264794,
        AWATER: 3957519,
        INTPTLAT: '+37.9300922',
        INTPTLON: '-097.8757373',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0869, 37.9119],
            [-97.9403, 37.9121],
            [-97.913, 37.8249],
            [-97.7996, 37.7708],
            [-97.7995, 37.734],
            [-97.8078, 37.7339],
            [-97.9456, 37.7194],
            [-98.2374, 37.747],
            [-98.2287, 37.815],
            [-98.1141, 37.8317],
            [-98.0869, 37.9119],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010920',
        NAME: 'Pretty Prairie Unified School District 311',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514677588,
        AWATER: 30754015,
        INTPTLAT: '+37.7992492',
        INTPTLON: '-098.0187889',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7996, 37.7708],
            [-97.6104, 37.8777],
            [-97.5188, 37.8426],
            [-97.517, 37.7008],
            [-97.6253, 37.6671],
            [-97.5975, 37.5696],
            [-97.616, 37.54],
            [-97.689, 37.5403],
            [-97.7252, 37.6844],
            [-97.7995, 37.734],
            [-97.7996, 37.7708],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011080',
        NAME: 'Renwick Unified School District 267',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515932177,
        AWATER: 6694009,
        INTPTLAT: '+37.7242743',
        INTPTLON: '-097.6368063',
        ELSDLEA: '',
        UNSDLEA: '11080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5978, 40.0027],
            [-101.1869, 40.0025],
            [-101.2227, 39.8718],
            [-101.2067, 39.6408],
            [-101.2635, 39.6045],
            [-101.3289, 39.5683],
            [-101.5106, 39.5684],
            [-101.6386, 39.5685],
            [-101.6568, 39.6119],
            [-101.6535, 39.8423],
            [-101.6738, 39.9162],
            [-101.5978, 40.0027],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004790',
        NAME: 'Cheylin Unified School District 103',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1771950153,
        AWATER: 481661,
        INTPTLAT: '+39.7930385',
        INTPTLON: '-101.4384219',
        ELSDLEA: '',
        UNSDLEA: '04790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0517, 40.0031],
            [-101.5978, 40.0027],
            [-101.6738, 39.9162],
            [-101.6535, 39.8423],
            [-101.6568, 39.6119],
            [-101.6386, 39.5685],
            [-102.0498, 39.5682],
            [-102.0498, 39.5741],
            [-102.0515, 39.8595],
            [-102.0517, 40.0031],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011880',
        NAME: 'St. Francis Community Schools Unified School District 297',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1658486198,
        AWATER: 2325787,
        INTPTLAT: '+39.8034933',
        INTPTLON: '-101.8592818',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0422, 37.7385],
            [-101.5271, 37.7363],
            [-101.5258, 37.3884],
            [-101.5566, 37.3885],
            [-101.8462, 37.3889],
            [-102.0416, 37.3892],
            [-102.0416, 37.6443],
            [-102.0422, 37.7385],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007860',
        NAME: 'Stanton County Unified School District 452',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1781276118,
        AWATER: 180097,
        INTPTLAT: '+37.5633867',
        INTPTLON: '-101.7809568',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9005, 39.1869],
            [-94.7891, 39.2012],
            [-94.835, 39.1195],
            [-94.9084, 39.1223],
            [-94.9005, 39.1869],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010680',
        NAME: 'Piper Unified School District 203',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81530691,
        AWATER: 580754,
        INTPTLAT: '+39.1617300',
        INTPTLON: '-094.8524535',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9084, 39.1223],
            [-94.835, 39.1195],
            [-94.7625, 39.0654],
            [-94.8009, 39.0435],
            [-94.9276, 39.0028],
            [-94.9084, 39.1223],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004050',
        NAME: 'Bonner Springs Unified School District 204',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100125354,
        AWATER: 4100436,
        INTPTLAT: '+39.0707720',
        INTPTLON: '-094.8569548',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8009, 39.0435],
            [-94.7625, 39.0654],
            [-94.6491, 39.044],
            [-94.7799, 39.0437],
            [-94.8009, 39.0435],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012360',
        NAME: 'Turner Unified School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46883566,
        AWATER: 1488178,
        INTPTLAT: '+39.0631098',
        INTPTLON: '-094.7211709',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7664, 39.2165],
            [-95.6361, 39.2165],
            [-95.5867, 39.1172],
            [-95.5948, 39.0864],
            [-95.6311, 39.0854],
            [-95.7314, 39.0725],
            [-95.7614, 39.0728],
            [-95.7664, 39.2165],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011490',
        NAME: 'Seaman Unified School District 345',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213376561,
        AWATER: 3594681,
        INTPTLAT: '+39.1477982',
        INTPTLON: '-095.6820904',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9589, 38.1854],
            [-95.9544, 38.2073],
            [-95.83, 38.1669],
            [-95.7943, 38.1123],
            [-95.684, 38.1191],
            [-95.5822, 38.2056],
            [-95.5175, 38.1984],
            [-95.5187, 38.1037],
            [-95.519, 38.0382],
            [-95.8119, 38.0397],
            [-95.8855, 37.9815],
            [-95.9777, 38.0768],
            [-95.9589, 38.1854],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008670',
        NAME: 'Leroy-Gridley Unified School District 245',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629834745,
        AWATER: 5449369,
        INTPTLAT: '+38.0921312',
        INTPTLON: '-095.7534701',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3573, 38.1727],
            [-96.2749, 38.1798],
            [-96.239, 38.2019],
            [-95.9589, 38.1854],
            [-95.9777, 38.0768],
            [-95.8855, 37.9815],
            [-95.9494, 37.88],
            [-95.9586, 37.88],
            [-96.0677, 37.9184],
            [-96.0677, 38.0413],
            [-96.3581, 38.0858],
            [-96.3573, 38.1727],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009090',
        NAME: 'Madison-Virgil Unified School District 386',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645365161,
        AWATER: 3697333,
        INTPTLAT: '+38.0794612',
        INTPTLON: '-096.1233141',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7976, 37.7339],
            [-95.5255, 37.7328],
            [-95.5252, 37.5514],
            [-95.525, 37.5147],
            [-95.5794, 37.4787],
            [-95.7199, 37.4863],
            [-95.7053, 37.6462],
            [-95.7976, 37.7339],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004170',
        NAME: 'Altoona-Midway Unified School District 387',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 487407873,
        AWATER: 3163874,
        INTPTLAT: '+37.6137801',
        INTPTLON: '-095.6250080',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5566, 37.3885],
            [-101.5258, 37.3884],
            [-101.3307, 37.3881],
            [-101.3391, 37.2936],
            [-101.2305, 37.2139],
            [-101.0677, 37.2136],
            [-101.0665, 36.9979],
            [-101.2149, 36.997],
            [-101.397, 36.9961],
            [-101.5553, 36.9953],
            [-101.5566, 37.3885],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007560',
        NAME: 'Hugoton Public Schools Unified School District 210',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1468380927,
        AWATER: 57207,
        INTPTLAT: '+37.1586473',
        INTPTLON: '-101.3434725',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3028, 37.5864],
            [-97.1852, 37.6139],
            [-97.1895, 37.5336],
            [-97.3243, 37.5284],
            [-97.3028, 37.5864],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005460',
        NAME: 'Derby Unified School District 260',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129414084,
        AWATER: 1527580,
        INTPTLAT: '+37.5791407',
        INTPTLON: '-097.2532163',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5188, 37.8426],
            [-97.4913, 37.825],
            [-97.3845, 37.7669],
            [-97.4807, 37.7007],
            [-97.517, 37.7008],
            [-97.5188, 37.8426],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009140',
        NAME: 'Maize Unified School District 266',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112473983,
        AWATER: 4218797,
        INTPTLAT: '+37.7590044',
        INTPTLON: '-097.4545157',
        ELSDLEA: '',
        UNSDLEA: '09140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.616, 37.54],
            [-97.5975, 37.5696],
            [-97.453, 37.5926],
            [-97.3699, 37.5283],
            [-97.3926, 37.4635],
            [-97.4311, 37.4043],
            [-97.4665, 37.3959],
            [-97.5876, 37.4309],
            [-97.616, 37.54],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004920',
        NAME: 'Clearwater Unified School District 264',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355585325,
        AWATER: 1678381,
        INTPTLAT: '+37.5110608',
        INTPTLON: '-097.4919874',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3699, 37.5283],
            [-97.3243, 37.5284],
            [-97.1895, 37.5336],
            [-97.1084, 37.4755],
            [-97.108, 37.469],
            [-97.1987, 37.4129],
            [-97.3926, 37.4635],
            [-97.3699, 37.5283],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009840',
        NAME: 'Mulvane Unified School District 263',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211825163,
        AWATER: 1292188,
        INTPTLAT: '+37.4773290',
        INTPTLON: '-097.2433359',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2353, 40.002],
            [-99.2166, 40.002],
            [-99.1978, 40.002],
            [-99.1791, 40.002],
            [-98.8796, 40.0023],
            [-98.8982, 39.8286],
            [-98.8795, 39.7053],
            [-98.9824, 39.6909],
            [-98.9634, 39.5969],
            [-99.0662, 39.5681],
            [-99.2344, 39.5674],
            [-99.2353, 39.6984],
            [-99.1975, 39.7272],
            [-99.2639, 39.8715],
            [-99.2353, 40.002],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000346',
        NAME: 'Thunder Ridge Unified School District 110',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1252914382,
        AWATER: 22146456,
        INTPTLAT: '+39.8052422',
        INTPTLON: '-099.0787332',
        ELSDLEA: '',
        UNSDLEA: '00346',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4068, 40.002],
            [-97.0131, 40.0014],
            [-96.9935, 39.9293],
            [-96.9279, 39.8057],
            [-96.9188, 39.7983],
            [-96.919, 39.7113],
            [-97.0311, 39.7116],
            [-97.1519, 39.7483],
            [-97.2278, 39.734],
            [-97.2654, 39.7267],
            [-97.3128, 39.748],
            [-97.3318, 39.7553],
            [-97.3687, 39.7774],
            [-97.3692, 39.9729],
            [-97.4068, 40.002],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000028',
        NAME: 'North Central-Washington Schools Unified School District 108',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1023007112,
        AWATER: 4243780,
        INTPTLAT: '+39.8635389',
        INTPTLON: '-097.1555451',
        ELSDLEA: '',
        UNSDLEA: '00028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.505, 39.6545],
            [-98.5045, 40.0023],
            [-98.4152, 40.0025],
            [-97.9884, 40.0022],
            [-97.9317, 39.9142],
            [-97.9315, 39.6538],
            [-98.3628, 39.6543],
            [-98.393, 39.5674],
            [-98.5053, 39.5676],
            [-98.505, 39.6545],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000029',
        NAME: 'Rock Hills Unified School District 107',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1955025679,
        AWATER: 11207815,
        INTPTLAT: '+39.8101723',
        INTPTLON: '-098.2362894',
        ELSDLEA: '',
        UNSDLEA: '00029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2023, 37.0859],
            [-97.1403, 37.1434],
            [-96.9237, 37.1493],
            [-96.8467, 37.1277],
            [-96.8229, 37.0555],
            [-96.8675, 36.9993],
            [-97.1114, 36.999],
            [-97.2015, 36.9989],
            [-97.2199, 36.9989],
            [-97.2023, 37.0859],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003450',
        NAME: 'Arkansas City Unified School District 470',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482953748,
        AWATER: 6238599,
        INTPTLAT: '+37.0710762',
        INTPTLON: '-097.0220170',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8021, 37.0889],
            [-97.6929, 37.1767],
            [-97.5835, 37.1775],
            [-97.4924, 37.1499],
            [-97.4729, 36.9987],
            [-97.6148, 36.9988],
            [-97.8023, 36.9987],
            [-97.8021, 37.0889],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004380',
        NAME: 'Caldwell Unified School District 360',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491574932,
        AWATER: 22468,
        INTPTLAT: '+37.0769318',
        INTPTLON: '-097.6268520',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1987, 37.4129],
            [-97.108, 37.469],
            [-96.9338, 37.4545],
            [-96.8888, 37.3753],
            [-97.0253, 37.376],
            [-97.1057, 37.3031],
            [-97.1805, 37.3616],
            [-97.1987, 37.4129],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012390',
        NAME: 'Udall Unified School District 463',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286910774,
        AWATER: 1388042,
        INTPTLAT: '+37.3976695',
        INTPTLON: '-097.0500403',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8583, 37.342],
            [-97.7696, 37.43],
            [-97.7287, 37.3221],
            [-97.5853, 37.3003],
            [-97.5835, 37.1775],
            [-97.6929, 37.1767],
            [-97.8021, 37.0889],
            [-97.8023, 37.2202],
            [-97.8583, 37.342],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003430',
        NAME: 'Argonia Public Schools Unified School District 359',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457131406,
        AWATER: 119016,
        INTPTLAT: '+37.2501787',
        INTPTLON: '-097.7338260',
        ELSDLEA: '',
        UNSDLEA: '03430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4924, 37.1499],
            [-97.3025, 37.1451],
            [-97.2023, 37.0859],
            [-97.2199, 36.9989],
            [-97.4623, 36.9987],
            [-97.4729, 36.9987],
            [-97.4924, 37.1499],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011790',
        NAME: 'South Haven Unified School District 509',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377530036,
        AWATER: 0,
        INTPTLAT: '+37.0728766',
        INTPTLON: '-097.3484596',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5921, 38.0869],
            [-97.4819, 38.1012],
            [-97.409, 38.0431],
            [-97.372, 37.9703],
            [-97.4638, 37.9415],
            [-97.4455, 37.8687],
            [-97.4913, 37.825],
            [-97.5188, 37.8426],
            [-97.6104, 37.8777],
            [-97.5921, 38.0869],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006840',
        NAME: 'Halstead Unified School District 440',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361899647,
        AWATER: 895173,
        INTPTLAT: '+37.9774668',
        INTPTLON: '-097.5099513',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6613, 37.9277],
            [-99.5697, 37.9854],
            [-99.5695, 38.0729],
            [-99.3504, 38.0876],
            [-99.3179, 38.0005],
            [-99.3323, 37.8612],
            [-99.3773, 37.7339],
            [-99.5597, 37.7319],
            [-99.5636, 37.7319],
            [-99.607, 37.8622],
            [-99.6613, 37.9277],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008100',
        NAME: 'Kinsley-Offerle Unified School District 347',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 888307214,
        AWATER: 193382,
        INTPTLAT: '+37.9091927',
        INTPTLON: '-099.4804939',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3179, 38.0005],
            [-99.105, 38],
            [-99.1326, 37.9126],
            [-99.1233, 37.7335],
            [-99.1957, 37.7335],
            [-99.3773, 37.7339],
            [-99.3323, 37.8612],
            [-99.3179, 38.0005],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008700',
        NAME: 'Lewis Unified School District 502',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 594415280,
        AWATER: 0,
        INTPTLAT: '+37.8648793',
        INTPTLON: '-099.2353061',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9884, 40.0022],
            [-97.8704, 40.0021],
            [-97.8403, 40.0021],
            [-97.8328, 40.002],
            [-97.4351, 40.002],
            [-97.4068, 40.002],
            [-97.3692, 39.9729],
            [-97.3687, 39.7774],
            [-97.3318, 39.7553],
            [-97.3686, 39.6758],
            [-97.5184, 39.6536],
            [-97.6836, 39.6612],
            [-97.6798, 39.7404],
            [-97.7258, 39.8278],
            [-97.8316, 39.901],
            [-97.9317, 39.9142],
            [-97.9884, 40.0022],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000030',
        NAME: 'Republic County-Hillcrest Rural Schools Unified School District 109',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1398632162,
        AWATER: 4075818,
        INTPTLAT: '+39.8534413',
        INTPTLON: '-097.5995305',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3686, 40],
            [-95.3083, 40],
            [-95.3082, 39.999],
            [-95.134, 39.8765],
            [-95.1808, 39.8126],
            [-95.1152, 39.7398],
            [-95.1152, 39.6525],
            [-95.3397, 39.653],
            [-95.3491, 39.733],
            [-95.3398, 39.9584],
            [-95.3686, 40],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000348',
        NAME: 'Doniphan West Unified School District 111',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 581307899,
        AWATER: 3045612,
        INTPTLAT: '+39.7987529',
        INTPTLON: '-095.2455442',
        ELSDLEA: '',
        UNSDLEA: '00348',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3717, 38.3625],
            [-97.2796, 38.3333],
            [-97.2164, 38.2147],
            [-97.2267, 38.182],
            [-97.279, 38.1747],
            [-97.3717, 38.1882],
            [-97.4267, 38.2608],
            [-97.3717, 38.3625],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006570',
        NAME: 'Goessel Unified School District 411',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283759775,
        AWATER: 259890,
        INTPTLAT: '+38.2538574',
        INTPTLON: '-097.3257533',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.409, 38.0431],
            [-97.2896, 38.1154],
            [-97.279, 38.1747],
            [-97.2267, 38.182],
            [-97.1727, 38.0982],
            [-97.2998, 37.9158],
            [-97.3317, 37.9122],
            [-97.372, 37.9703],
            [-97.409, 38.0431],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009960',
        NAME: 'Newton Unified School District 373',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338218780,
        AWATER: 218019,
        INTPTLAT: '+38.0500687',
        INTPTLON: '-097.2907679',
        ELSDLEA: '',
        UNSDLEA: '09960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.751, 37.499],
            [-94.6169, 37.4916],
            [-94.6173, 37.4256],
            [-94.7599, 37.4257],
            [-94.751, 37.499],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006300',
        NAME: 'Frontenac Public Schools Unified School District 249',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95075138,
        AWATER: 2036975,
        INTPTLAT: '+37.4631503',
        INTPTLON: '-094.6978102',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3947, 39.4277],
            [-95.3289, 39.4418],
            [-95.1804, 39.4117],
            [-95.1802, 39.282],
            [-95.2547, 39.2743],
            [-95.3666, 39.274],
            [-95.3947, 39.4277],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2013020',
        NAME: 'Jefferson County North Unified School District 339',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304484684,
        AWATER: 2144917,
        INTPTLAT: '+39.3553182',
        INTPTLON: '-095.2901939',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.271, 39.1289],
            [-95.2547, 39.2743],
            [-95.1802, 39.282],
            [-95.1331, 39.2594],
            [-95.1341, 39.1651],
            [-95.188, 39.1289],
            [-95.1871, 39.0441],
            [-95.2561, 39.0441],
            [-95.271, 39.1289],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009360',
        NAME: 'McLouth Unified School District 342',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233512246,
        AWATER: 2537349,
        INTPTLAT: '+39.1696120',
        INTPTLON: '-095.2020525',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5948, 39.0864],
            [-95.5867, 39.1172],
            [-95.4501, 39.1503],
            [-95.271, 39.1289],
            [-95.2561, 39.0441],
            [-95.3076, 39.038],
            [-95.4467, 38.9407],
            [-95.4638, 39.0293],
            [-95.5948, 39.0864],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010620',
        NAME: 'Perry Public Schools Unified School District 343',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375522184,
        AWATER: 26914637,
        INTPTLAT: '+39.0609044',
        INTPTLON: '-095.4136849',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9297, 39.2193],
            [-97.9106, 39.2919],
            [-97.7601, 39.3358],
            [-97.6296, 39.3358],
            [-97.5184, 39.263],
            [-97.4078, 39.2626],
            [-97.4085, 39.1176],
            [-97.4456, 39.1106],
            [-97.7611, 39.0882],
            [-97.9288, 39.1035],
            [-97.9297, 39.2193],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009570',
        NAME: 'North Ottawa County Unified School District 239',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1077917629,
        AWATER: 641136,
        INTPTLAT: '+39.1980584',
        INTPTLON: '-097.6787603',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9021, 39.4811],
            [-97.7226, 39.4162],
            [-97.4798, 39.4306],
            [-97.3689, 39.4307],
            [-97.37, 39.2915],
            [-97.4078, 39.2626],
            [-97.5184, 39.263],
            [-97.6296, 39.3358],
            [-97.7601, 39.3358],
            [-97.9106, 39.2919],
            [-97.9021, 39.4811],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2006510',
        NAME: 'Southern Cloud Unified School District 334',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 706055255,
        AWATER: 300064,
        INTPTLAT: '+39.3720117',
        INTPTLON: '-097.6386359',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9315, 39.6538],
            [-97.6836, 39.6612],
            [-97.5184, 39.6536],
            [-97.4803, 39.5811],
            [-97.4798, 39.4306],
            [-97.7226, 39.4162],
            [-97.9021, 39.4811],
            [-97.9315, 39.6538],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005100',
        NAME: 'Concordia Unified School District 333',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 858021546,
        AWATER: 4805553,
        INTPTLAT: '+39.5459609',
        INTPTLON: '-097.6946474',
        ELSDLEA: '',
        UNSDLEA: '05100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5184, 39.6536],
            [-97.3686, 39.6758],
            [-97.3318, 39.7553],
            [-97.3128, 39.748],
            [-97.2654, 39.7267],
            [-97.2378, 39.6683],
            [-97.1812, 39.5886],
            [-97.0893, 39.5666],
            [-97.2385, 39.5278],
            [-97.2751, 39.4668],
            [-97.3689, 39.4307],
            [-97.4798, 39.4306],
            [-97.4803, 39.5811],
            [-97.5184, 39.6536],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2004950',
        NAME: 'Clifton-Clyde Unified School District 224',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 655306775,
        AWATER: 3958974,
        INTPTLAT: '+39.5789503',
        INTPTLON: '-097.3421914',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9317, 39.9142],
            [-97.8316, 39.901],
            [-97.7258, 39.8278],
            [-97.6798, 39.7404],
            [-97.6836, 39.6612],
            [-97.9315, 39.6538],
            [-97.9317, 39.9142],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005310',
        NAME: 'Pike Valley Unified School District 426',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498000758,
        AWATER: 4654706,
        INTPTLAT: '+39.7651466',
        INTPTLON: '-097.8243416',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7765, 37.6741],
            [-94.6178, 37.6731],
            [-94.6177, 37.6536],
            [-94.6169, 37.4916],
            [-94.751, 37.499],
            [-94.7765, 37.6741],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2003480',
        NAME: 'Northeast Unified School District 246',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256431971,
        AWATER: 2289850,
        INTPTLAT: '+37.5929734',
        INTPTLON: '-094.6814221',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5842, 38.2622],
            [-99.5848, 38.3494],
            [-99.2529, 38.3507],
            [-99.2161, 38.35],
            [-99.2884, 38.3127],
            [-99.2962, 38.2038],
            [-99.3504, 38.0876],
            [-99.5695, 38.0729],
            [-99.5842, 38.2622],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011280',
        NAME: 'Pawnee Heights Unified School District 496',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 736635682,
        AWATER: 470433,
        INTPTLAT: '+38.2263142',
        INTPTLON: '-099.4346775',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8058, 40.0015],
            [-96.6957, 40.0014],
            [-96.6899, 40.0013],
            [-96.6711, 40.0013],
            [-96.6637, 40.0013],
            [-96.6615, 40.0013],
            [-96.6336, 40.0013],
            [-96.6145, 40.0009],
            [-96.6013, 40.0009],
            [-96.5954, 40.0009],
            [-96.5858, 40.001],
            [-96.5638, 40.001],
            [-96.5579, 40.0011],
            [-96.554, 40.0011],
            [-96.5297, 40.001],
            [-96.5203, 40.001],
            [-96.5014, 40.001],
            [-96.3608, 39.9067],
            [-96.3701, 39.8057],
            [-96.4636, 39.798],
            [-96.5575, 39.7359],
            [-96.8065, 39.7984],
            [-96.7878, 39.8276],
            [-96.8058, 40.0015],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2000016',
        NAME: 'Marysville Unified School District 364',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878461988,
        AWATER: 3767274,
        INTPTLAT: '+39.8813315',
        INTPTLON: '-096.5926037',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0126, 37.4704],
            [-98.9028, 37.4702],
            [-98.7205, 37.3841],
            [-98.6291, 37.4705],
            [-98.6018, 37.5583],
            [-98.5199, 37.5943],
            [-98.4647, 37.529],
            [-98.465, 37.3841],
            [-98.3497, 37.3841],
            [-98.3495, 37.3264],
            [-98.3484, 37.1815],
            [-98.6773, 37.1672],
            [-98.6955, 37.1381],
            [-99.0013, 37.1512],
            [-99.0115, 37.3843],
            [-99.0126, 37.4704],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2009450',
        NAME: 'Barber County North Unified School District 254',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1873114373,
        AWATER: 2848918,
        INTPTLAT: '+37.3153703',
        INTPTLON: '-098.6787860',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0677, 37.2136],
            [-100.9304, 37.2503],
            [-100.831, 37.17],
            [-100.8682, 36.9988],
            [-101.0177, 36.9982],
            [-101.0665, 36.9979],
            [-101.0677, 37.2136],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2008730',
        NAME: 'Liberal Unified School District 480',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525671099,
        AWATER: 734532,
        INTPTLAT: '+37.1194229',
        INTPTLON: '-100.9495820',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0135, 37.733],
            [-98.9038, 37.8615],
            [-98.8392, 37.8253],
            [-98.7213, 37.8248],
            [-98.8393, 37.6754],
            [-98.7594, 37.6607],
            [-98.8126, 37.5721],
            [-98.6018, 37.5583],
            [-98.6291, 37.4705],
            [-98.7205, 37.3841],
            [-98.9028, 37.4702],
            [-99.0126, 37.4704],
            [-98.9774, 37.5649],
            [-98.9965, 37.6078],
            [-99.0135, 37.733],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2011430',
        NAME: 'Skyline Schools Unified School District 438',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1066947835,
        AWATER: 602646,
        INTPTLAT: '+37.6012952',
        INTPTLON: '-098.8399781',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9502, 38.0936],
            [-97.8669, 38.043],
            [-97.9271, 38.0291],
            [-97.9502, 38.0936],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2007620',
        NAME: 'Hutchinson Public Schools Unified School District 308',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39373468,
        AWATER: 78703,
        INTPTLAT: '+38.0610015',
        INTPTLON: '-097.9209117',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0563, 38.9821],
            [-94.9276, 39.0028],
            [-94.8009, 39.0435],
            [-94.7799, 39.0437],
            [-94.7973, 38.9741],
            [-94.8997, 38.9634],
            [-94.8902, 38.8691],
            [-95.0375, 38.877],
            [-95.0563, 38.9821],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005490',
        NAME: 'De Soto Unified School District 232',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234480183,
        AWATER: 7293844,
        INTPTLAT: '+38.9623442',
        INTPTLON: '-094.9236300',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7252, 37.7365],
            [-100.6618, 37.7363],
            [-100.5614, 37.6788],
            [-100.543, 37.4748],
            [-100.5433, 37.4165],
            [-100.6526, 37.4167],
            [-100.7253, 37.4167],
            [-100.7252, 37.7365],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2005190',
        NAME: 'Copeland Unified School District 476',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517543507,
        AWATER: 328883,
        INTPTLAT: '+37.5757204',
        INTPTLON: '-100.6428503',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0445, 38.2624],
            [-101.5675, 38.2631],
            [-101.5423, 38.2632],
            [-101.5428, 37.8276],
            [-101.5271, 37.7363],
            [-102.0422, 37.7385],
            [-102.0445, 38.2624],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2012150',
        NAME: 'Syracuse Unified School District 494',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2580947447,
        AWATER: 2904549,
        INTPTLAT: '+37.9952438',
        INTPTLON: '-101.7936887',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7588, 37.4112],
            [-94.7599, 37.4257],
            [-94.6173, 37.4256],
            [-94.6176, 37.3642],
            [-94.6179, 37.313],
            [-94.7321, 37.3387],
            [-94.7588, 37.4112],
          ],
        ],
      },
      properties: {
        STATEFP: '20',
        SCSDLEA: '',
        GEOID: '2010710',
        NAME: 'Pittsburg Unified School District 250',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115306967,
        AWATER: 1536461,
        INTPTLAT: '+37.3852730',
        INTPTLON: '-094.6770285',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
  ],
};

import { exportApi } from '../api/export';
import types from '../types';

export const exportFile = (format, exportItems, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.EXPORT_PENDING });
    const data = await exportApi(format, exportItems, jwt);

    if (data.data) {
      // trigger download of the file
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = data.data.shareToken;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      dispatch({ type: types.reducerTypes.EXPORT_SUCCESS });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.EXPORT_ERROR, payload: 'Unexpected Error!' });
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../Spinner/index';
import Tabs from './Tabs';
import {
  getAllSampleMetadata,
  setSampleValue,
  initializeFileWizardSelectedSamples,
} from '../../../../actions/fileWizard.action';
import './style.css';

const CheckboxContainer = ({ action }) => {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt);
  const allSampleMetadata = useSelector((state) => state.fileWizard.allSampleMetadata);
  const allMetadatas = useSelector((state) => state.fileWizard.allMetadatas);
  const isFetchingData = useSelector((state) => state.fileWizard.isFetchingData);
  const selectedMetadatasIds = useSelector((state) => state.fileWizard.selectedMetadatasIds);
  const dataSources = useSelector((state) => state.fileWizard.dataSources);

  const onChangeHandler = (e) => {
    dispatch(setSampleValue(e.target));
  };

  const actionHandler = (actionParam) => {
    switch (actionParam) {
      case 'getDashboardSampleMetadata':
        return getAllSampleMetadata;
      default:
        console.error('Error with fileWizardStructure action');
        return null;
    }
  };

  useEffect(() => {
    dispatch(initializeFileWizardSelectedSamples());
    if (typeof actionHandler(action) === 'function') {
      dispatch(actionHandler(action)(jwt, selectedMetadatasIds));
    }
  }, []);

  const isChcked = (value) => {
    let isSelected = false;
    dataSources.forEach((sample) => {
      if (sample.samplesIds.includes(value.toString())) {
        isSelected = true;
      }
    });
    return isSelected;
  };

  if (isFetchingData)
    return (
      <div className="spinner-container">
        <Spinner size="lg" />
      </div>
    );
  if (selectedMetadatasIds.length >= 0) {
    return (
      <Tabs
        allSampleMetadata={allSampleMetadata}
        onChangeHandler={onChangeHandler}
        isChecked={isChcked}
        allMetadatas={allMetadatas}
      />
    );
  }
  return null;
};

CheckboxContainer.propTypes = {
  action: PropTypes.string.isRequired,
};

export default CheckboxContainer;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import types from '../../types';

const MoeCalculationInProgressModal = () => {
    const { calculatingMoe } = useSelector((state) => state.surveyDetails);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch({ type: types.reducerTypes.MOE_CALCULATION_IN_PROGRESS_MODAL_HIDE });
    };

    return (
        <Modal
            centered
            show={calculatingMoe}
            onHide={closeModal}
            backdrop="static"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>
                            Calculating margin of error.
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="light"
                    size="xl"
                    onClick={closeModal}
                >CANCEL</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoeCalculationInProgressModal;

/* eslint-disable no-await-in-loop */
import jwtDecode from 'jwt-decode';
import {
  getUsersListApi,
  createUserApi,
  updateUserApi,
  deleteUserApi,
  exportUsersApi,
  checkTokenApi,
  assignOrgToUserApi,
  getUsersByEmailListApi,
} from '../api/users';
import types from '../types';

export const getUsersByEmailList = (emails, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_BY_EMAIL_LIST_PENDING });
    const data = await getUsersByEmailListApi(emails, jwt);
    dispatch({ type: types.reducerTypes.USERS_BY_EMAIL_LIST_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.USERS_BY_EMAIL_LIST_ERROR, payload: 'Unexpected Error!' });
  }
};

export const getUsersList = (filters, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_LIST_PENDING });
    const data = await getUsersListApi(filters, jwt);
    dispatch({ type: types.reducerTypes.USERS_LIST_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.USERS_LIST_ERROR, payload: 'Unexpected Error!' });
  }
};

export const createUser = (newUser, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_SAVE_PENDING });
    await createUserApi(newUser, jwt);
    dispatch({ type: types.reducerTypes.USERS_SAVE_SUCCESS });
  } catch (e) {
    dispatch({ type: types.reducerTypes.USERS_SAVE_ERROR, payload: e.response.data.message });
  }
};

export const updateUser = (editUser, jwt, userId) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_SAVE_PENDING });
    await updateUserApi(editUser, jwt, userId);
    dispatch({ type: types.reducerTypes.USERS_SAVE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.USERS_SAVE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const deleteUser = (ids, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_DELETE_PENDING });
    await deleteUserApi(ids, jwt);
    dispatch({ type: types.reducerTypes.USERS_DELETE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.USERS_DELETE_ERROR, payload: 'Unexpected Error!' });
  }
};

export const createUserBatch = (users, jwt) => async (dispatch) => {
  dispatch({ type: types.reducerTypes.USERS_BATCH_PENDING });
  for (let i = 0; i < users.length; i += 1) {
    const progress = (i * 100) / users.length;
    const currUser = users[i];
    dispatch({ type: types.reducerTypes.USERS_BATCH_PROGRESS, batchProgress: progress });
    try {
      const loggedUser = jwtDecode(jwt);
      if (
        (loggedUser.role === 'ORGANIZATION_ADMIN' && !currUser?.organizations?.length) ||
        (currUser.role === 'ORGANIZATION_ADMIN' && !currUser?.organizations?.length)
      ) {
        throw new Error('must be associated to an organization');
      }
      const userResult = await createUserApi(currUser, jwt);
      if (userResult?.status !== 201 && userResult?.message) {
        dispatch({
          type: types.reducerTypes.USERS_BATCH_ERROR,
          payload: { username: currUser?.username, message: userResult?.message },
        });
      }
      await Promise.all(
        currUser.organizations.map((org) =>
          assignOrgToUserApi(userResult.data.id, org, currUser.role === 'ORGANIZATION_ADMIN', jwt)
        )
      );
    } catch (e) {
      dispatch({
        type: types.reducerTypes.USERS_BATCH_ERROR,
        payload: {
          username: currUser?.username,
          message: e?.response?.data?.message || e?.message || 'Unexpected error!',
        },
      });
    }
  }
  dispatch({ type: types.reducerTypes.USERS_BATCH_SUCCESS });
};

export const exportUsers = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_EXPORT_PENDING });
    const result = await exportUsersApi(jwt);
    dispatch({ type: types.reducerTypes.USERS_EXPORT_SUCCESS, payload: result?.data?.shareToken });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.USERS_EXPORT_ERROR, payload: 'Unexpected Error!' });
  }
};

export const resetUserBatch = () => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.USERS_BATCH_RESET });
  } catch (e) {
    console.log(e);
  }
};

export const checkToken = (token) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.CHECK_TOKEN_PENDING });
    const payload = await checkTokenApi(token);
    dispatch({ type: types.reducerTypes.CHECK_TOKEN_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.CHECK_TOKEN_ERROR, payload: 'Unexpected Error!' });
  }
};

export const assignOrgToUser = (userId, orgId, isAdmin, token) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.CHECK_TOKEN_PENDING });
    const payload = await assignOrgToUserApi(userId, orgId, isAdmin, token);
    dispatch({ type: types.reducerTypes.CHECK_TOKEN_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.CHECK_TOKEN_ERROR, payload: 'Unexpected Error!' });
  }
};

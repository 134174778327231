/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import themes from '../../constants/themes';

import './styles.css';

const ThemeSelectorModal = ({ show, onClose, currentSelected, onConfirm }) => {
  const [selectedTheme, setSelectedTheme] = useState(currentSelected);
  return (
    <Modal className="theme-selector-modal" centered show={show} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>Select a theme</Modal.Header>
      <Modal.Body className="body">
        {themes.map((theme) => (
          <div
            key={`theme-${theme.index}`}
            className={`colors-rounded ${theme.index === selectedTheme?.index ? 'selected' : ''}`}
            onClick={() => setSelectedTheme(theme)}
            role="button"
          >
            {theme.positive_negative.map((color) => (
              <div
                role="button"
                key={`color-${color}`}
                style={{ backgroundColor: color, width: 100, height: 20 }}
              />
            ))}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onConfirm(selectedTheme)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ThemeSelectorModal;

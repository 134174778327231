import React from 'react';
import PropTypes from 'prop-types';
import Nav from '../Nav';

const Layout = (props) => {
  const { children } = props;
  return (
    <>
      <Nav {...props} />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

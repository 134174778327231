/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {
  calculateChartHeight,
  makeMLITooltip,
  regroupForDonut,
  regroupForDonutDashboard,
} from '../../../helpers/charts';
import { HIGHCHARTS_TYPES, HIGHCHARTS_TOOLTIP_DELAY } from '../../../constants/charts';

const DonutChart = forwardRef(
  (
    {
      parsedData,
      segmented = false,
      benchmarked = false,
      displayOnDashboard = false,
      qtyOptions,
      chartLoaded,
      scheme = undefined,
    },
    chartRef
  ) => {
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);

    function dashboardLegendLabelFormatter() {
      const percentage = this.y !== null ? this.y : 'NA';
      const updated_name = this.name.includes('% ') ? this.name.replace('% ', '') : this.name;
      return `${updated_name} (${percentage}%)`;
    }

    function legendLabelFormatter() {
      let display_value = this.y !== null ? this.y : 'NA';
      let legend_item_label = `<div><span style="font-weight:bold;opacity=0.7;">${this.name} (${display_value}%)</span>`;
      let series_name;

      if (parsedData.length > 1) {
        for (let i = 1; i < parsedData.length; i += 1) {
          display_value =
            parsedData[i].data[this.x].y !== null ? parsedData[i].data[this.x].y : 'NA';
          series_name = parsedData[i].name;
          legend_item_label = `${legend_item_label}<br><span style="font-weight:normal;opacity=0.7;">${series_name} (${display_value}%)</span>'`;
        }
      }
      legend_item_label = `${legend_item_label}</div>`;

      return legend_item_label;
    }

    function tooltipFormatter() {
      let series_name;
      let text_color;
      let text;
      let percent_diff;

      if (this.series.name === 'default') {
        series_name = 'All respondents';
      } else {
        series_name = this.series.name;
      }
      const { benchmark, benchmark_label, filtered } = this.point;
      let nat_category = false;
      const moe = Array.isArray(this.point.MOE) ? this.point.MOE[0] : this.point.MOE;

      if (segmented) {
        if (benchmarked) {
          if (series_name.includes('National')) {
            nat_category = true;
          } else if (!series_name.includes('State') && !filtered) {
            nat_category = true;
          }
        } else if (series_name.includes('National')) {
          nat_category = true;
        } else if (
          (series_name.includes('All respondents') && !filtered) ||
          (series_name.includes('deafult') && !filtered)
        ) {
          nat_category = true;
        }
      } else if (!filtered) {
        nat_category = true;
      }

      if (benchmark !== undefined && benchmark !== null && !nat_category) {
        percent_diff = Math.round(this.y - benchmark);

        if (percent_diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          percent_diff = Math.abs(percent_diff);
        }
      } else {
        percent_diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
      }

      return makeMLITooltip(
        `${this.point.name} (${series_name})`,
        this.y,
        percent_diff,
        moe,
        text,
        benchmark_label,
        this.color,
        text_color,
        false
      );
    }

    useEffect(() => {
      const chartHeight = calculateChartHeight(qtyOptions);
      if (displayOnDashboard) {
        const dashboardDonutSeries = regroupForDonutDashboard(
          parsedData,
          undefined,
          undefined,
          undefined,
          undefined,
          scheme?.discrete
        );
        const dashboard_theme = {
          chart: {
            height: 150,
            type: HIGHCHARTS_TYPES.PIE,
            // backgroundColor option is static
            backgroundColor: null,
            marginLeft: 20,
            width: 250,

            // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
            style: {
              fontFamily: 'AvenirNext-Regular',
            },

            // plotBorderColor option is static
            plotBorderColor: '#f7f7f7',
          },

          // title formatting options will be static
          title: {
            text: null,
            align: 'left',
            style: {
              color: '#404040',
              fontSize: '14px',
            },
            spacing: [0, 0, 0, 0],
            marginLeft: 0,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            layout: 'vertical',
            margin: 0,
            x: 0,
            align: 'right',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            verticalAlign: 'top',
            symbolRadius: 0,
            symbolWidth: 8,
            symbolHeight: 8,
            useHTML: true,
            itemStyle: {
              color: '#404040',
              fontSize: '14px',
              fontWeight: 'heavy',
            },
            itemHoverStyle: {
              color: '#404040',
            },
            itemHiddenStyle: {
              color: '#404040',
            },
            labelFormatter: dashboardLegendLabelFormatter,
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  legendItemClick: () => {
                    return false;
                  },
                },
              },
            },
            pie: {
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
          credits: {
            enabled: false,
          },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          series: dashboardDonutSeries,
        };

        if (
          !dashboardDonutSeries?.length ||
          dashboardDonutSeries[0].data.every((d) => d.y === null)
        ) {
          setChartOptions(null);
        } else {
          setChartOptions({ ...dashboard_theme });
        }
      } else {
        const default_theme = {
          chart: {
            events: {
              load() {
                chartLoaded();
              },
            },
            height: chartHeight < 400 ? 400 : chartHeight,
            type: HIGHCHARTS_TYPES.PIE,
            // backgroundColor option is static
            backgroundColor: null,
            marginLeft: 80,

            // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
            style: {
              fontFamily: 'AvenirNext-Regular',
            },

            // plotBorderColor option is static
            plotBorderColor: '#f7f7f7',
          },

          // title formatting options will be static
          title: {
            text: null,
            align: 'left',
            style: {
              color: '#404040',
              fontSize: '14px',
            },
          },
          credits: { enabled: false },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },

          // xAxis formatting will be static
          xAxis: {
            gridLineColor: '#b8b4b4',
            labels: {
              rotation: 0,
              step: 1,
              padding: 0,
              style: {
                color: '#121111',
                fontSize: '14px',
              },
            },
            lineColor: '#b8b4b4',
            minorGridLineColor: '#b8b4b4',
            tickColor: '#f7f7f7',
            tickInterval: 1,
            title: {
              style: {
                color: '#121111',
              },
            },
          },
          // yAxis formatting will be static
          yAxis: {
            gridLineColor: 'transparent',
            labels: {
              format: '{value}%',
              style: {
                color: '#bdbdbd',
                fontSize: '14px',
              },
            },
            lineColor: '#b8b4b4',
            lineWidth: 1,
            minorGridLineColor: 'transparent',
            tickColor: '#f7f7f7',
            tickWidth: 1,
            title: {
              style: {
                color: '#121111',
                fontSize: '14px',
              },
            },
          },
          tooltip: {
            hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
            enabled: true,
            backgroundColor: 'rgba(255,255,255, 0.98)',
            useHTML: true,
            formatter: tooltipFormatter,
          },
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            y: 50,
            symbolRadius: 0,
            itemMarginTop: 10,
            itemWidth: 250,
            useHTML: true,
            labelFormatter: legendLabelFormatter,
            itemStyle: {
              color: '#121111',
              fontWeight: 'normal',
              textOverflow: 'wrap',
              fontSize: '14px',
            },
            itemHoverStyle: {
              color: '#121111',
            },
            itemHiddenStyle: {
              color: '#121111',
            },
            navigation: {
              activeColor: '#ac193c',
              buttonOptions: {
                enabled: false,
              },
            },
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  legendItemClick: () => {
                    return false;
                  },
                },
              },
            },
            pie: {
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
          series: regroupForDonut(parsedData),
        };

        setChartOptions({ ...default_theme });
      }
      setLoadingChart(false);
    }, [parsedData]);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }
    if (chartOptions) {
      return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
    }
    return (
      <Alert className="m-3" variant="warning">
        <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data not
        available
      </Alert>
    );
  }
);

DonutChart.displayName = 'DonutChart';

export default React.memo(DonutChart);

export const MD = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9312, 39.7228],
            [-78.8084, 39.7229],
            [-78.6583, 39.7229],
            [-78.3806, 39.7226],
            [-78.3428, 39.7223],
            [-78.3338, 39.6368],
            [-78.4686, 39.5166],
            [-78.6571, 39.5351],
            [-78.7953, 39.6107],
            [-78.9573, 39.4401],
            [-79.0679, 39.4792],
            [-78.9312, 39.7228],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400030',
        NAME: 'Allegany County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1098572104,
        AWATER: 14940164,
        INTPTLAT: '+39.6123134',
        INTPTLON: '-078.7031037',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8404, 39.1031],
            [-76.6971, 39.2141],
            [-76.6186, 39.2374],
            [-76.5295, 39.2184],
            [-76.3495, 39.1566],
            [-76.3517, 39.0562],
            [-76.4365, 38.8074],
            [-76.4636, 38.7111],
            [-76.6864, 38.7485],
            [-76.7144, 38.7808],
            [-76.6696, 38.9037],
            [-76.6983, 38.9836],
            [-76.8404, 39.1031],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400060',
        NAME: 'Anne Arundel County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1074549020,
        AWATER: 447837723,
        INTPTLAT: '+38.9935793',
        INTPTLON: '-076.5604840',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5295, 39.2184],
            [-76.6186, 39.2374],
            [-76.7111, 39.2779],
            [-76.7113, 39.3719],
            [-76.5298, 39.3721],
            [-76.5295, 39.2184],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400090',
        NAME: 'Baltimore City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209643555,
        AWATER: 28767622,
        INTPTLAT: '+39.3000324',
        INTPTLON: '-076.6104761',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7871, 39.7208],
            [-76.6094, 39.7213],
            [-76.5695, 39.7212],
            [-76.5322, 39.544],
            [-76.3917, 39.4645],
            [-76.2877, 39.2541],
            [-76.3495, 39.1566],
            [-76.5295, 39.2184],
            [-76.5298, 39.3721],
            [-76.7113, 39.3719],
            [-76.7111, 39.2779],
            [-76.6186, 39.2374],
            [-76.6971, 39.2141],
            [-76.8823, 39.3502],
            [-76.8833, 39.4748],
            [-76.7871, 39.7208],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400120',
        NAME: 'Baltimore County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1549750692,
        AWATER: 215989606,
        INTPTLAT: '+39.4431666',
        INTPTLON: '-076.6165693',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6864, 38.7485],
            [-76.4636, 38.7111],
            [-76.4388, 38.5753],
            [-76.4207, 38.4966],
            [-76.3219, 38.3273],
            [-76.4453, 38.3052],
            [-76.5341, 38.3978],
            [-76.6741, 38.4996],
            [-76.6755, 38.5359],
            [-76.6864, 38.7485],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400150',
        NAME: 'Calvert County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552134151,
        AWATER: 341605063,
        INTPTLAT: '+38.5226102',
        INTPTLON: '-076.5294945',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9493, 38.9183],
            [-75.9297, 38.9907],
            [-75.8349, 39.1084],
            [-75.7478, 39.1433],
            [-75.7443, 39.0997],
            [-75.7387, 39.0297],
            [-75.7241, 38.8467],
            [-75.7124, 38.698],
            [-75.7074, 38.6354],
            [-75.838, 38.704],
            [-75.9452, 38.6741],
            [-76.015, 38.7287],
            [-75.8968, 38.8151],
            [-75.9493, 38.9183],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400180',
        NAME: 'Caroline County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 827350233,
        AWATER: 16777062,
        INTPTLAT: '+38.8715306',
        INTPTLON: '-075.8316620',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.217, 39.7199],
            [-77.2002, 39.7198],
            [-76.9993, 39.7201],
            [-76.8005, 39.7206],
            [-76.7871, 39.7208],
            [-76.8833, 39.4748],
            [-76.8823, 39.3502],
            [-77.1681, 39.354],
            [-77.1068, 39.4917],
            [-77.2001, 39.5786],
            [-77.2998, 39.6123],
            [-77.217, 39.7199],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400210',
        NAME: 'Carroll County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1159279157,
        AWATER: 13186232,
        INTPTLAT: '+39.5633179',
        INTPTLON: '-077.0153530',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2333, 39.7214],
            [-76.1355, 39.7211],
            [-75.8419, 39.722],
            [-75.7886, 39.7222],
            [-75.7838, 39.5905],
            [-75.7666, 39.3776],
            [-75.908, 39.3645],
            [-76.0799, 39.4055],
            [-76.042, 39.4942],
            [-76.2333, 39.7214],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400240',
        NAME: 'Cecil County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 896861126,
        AWATER: 185332636,
        INTPTLAT: '+39.5623537',
        INTPTLON: '-075.9415852',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3153, 38.4788],
            [-77.2827, 38.5277],
            [-77.2255, 38.6056],
            [-77.1297, 38.6347],
            [-77.0864, 38.7062],
            [-77.0502, 38.6207],
            [-76.9657, 38.6613],
            [-76.863, 38.6586],
            [-76.7476, 38.6173],
            [-76.6755, 38.5359],
            [-76.6741, 38.4996],
            [-76.775, 38.5089],
            [-76.8715, 38.3899],
            [-76.8213, 38.2688],
            [-76.8689, 38.1714],
            [-76.9623, 38.2299],
            [-76.9621, 38.257],
            [-76.9909, 38.2736],
            [-76.9977, 38.278],
            [-77.0148, 38.3324],
            [-77.0146, 38.3592],
            [-77.2862, 38.347],
            [-77.3153, 38.4788],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400270',
        NAME: 'Charles County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1185653700,
        AWATER: 479519793,
        INTPTLAT: '+38.4728532',
        INTPTLON: '-077.0154272',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9452, 38.6741],
            [-75.838, 38.704],
            [-75.7074, 38.6354],
            [-75.7016, 38.5607],
            [-75.8237, 38.481],
            [-75.8642, 38.3508],
            [-75.9286, 38.2973],
            [-75.9485, 38.216],
            [-76.0316, 38.1475],
            [-76.0786, 38.0609],
            [-76.2047, 38.0584],
            [-76.3219, 38.3273],
            [-76.4207, 38.4966],
            [-76.4388, 38.5753],
            [-76.3846, 38.5762],
            [-76.2401, 38.6632],
            [-76.0234, 38.5739],
            [-75.9452, 38.6741],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400300',
        NAME: 'Dorchester County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1400542045,
        AWATER: 1145386121,
        INTPTLAT: '+38.4199446',
        INTPTLON: '-076.0773856',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4691, 39.72],
            [-77.4594, 39.72],
            [-77.3203, 39.7199],
            [-77.217, 39.7199],
            [-77.2998, 39.6123],
            [-77.2001, 39.5786],
            [-77.1068, 39.4917],
            [-77.1681, 39.354],
            [-77.1688, 39.3535],
            [-77.4588, 39.2203],
            [-77.5688, 39.3064],
            [-77.6772, 39.3245],
            [-77.5702, 39.62],
            [-77.4691, 39.72],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400330',
        NAME: 'Frederick County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1710554388,
        AWATER: 18041931,
        INTPTLAT: '+39.4704287',
        INTPTLON: '-077.3976237',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4767, 39.7211],
            [-79.3925, 39.7216],
            [-79.2084, 39.722],
            [-79.0566, 39.7228],
            [-78.9312, 39.7228],
            [-79.0679, 39.4792],
            [-79.2717, 39.328],
            [-79.4869, 39.2059],
            [-79.4767, 39.7211],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400360',
        NAME: 'Garrett County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1675988217,
        AWATER: 22363868,
        INTPTLAT: '+39.5472985',
        INTPTLON: '-079.2746192',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5695, 39.7212],
            [-76.2398, 39.7213],
            [-76.2333, 39.7214],
            [-76.042, 39.4942],
            [-76.0799, 39.4055],
            [-76.1412, 39.3804],
            [-76.2877, 39.2541],
            [-76.3917, 39.4645],
            [-76.5322, 39.544],
            [-76.5695, 39.7212],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400390',
        NAME: 'Harford County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1132091443,
        AWATER: 231907633,
        INTPTLAT: '+39.5374292',
        INTPTLON: '-076.2997894',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1688, 39.3535],
            [-77.1681, 39.354],
            [-76.8823, 39.3502],
            [-76.6971, 39.2141],
            [-76.8404, 39.1031],
            [-76.8885, 39.131],
            [-76.9486, 39.1296],
            [-77.0656, 39.251],
            [-77.1306, 39.2684],
            [-77.1688, 39.3535],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400420',
        NAME: 'Howard County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649942749,
        AWATER: 6345542,
        INTPTLAT: '+39.2522639',
        INTPTLON: '-076.9244057',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3495, 39.1566],
            [-76.2877, 39.2541],
            [-76.1412, 39.3804],
            [-76.0799, 39.4055],
            [-75.908, 39.3645],
            [-75.7666, 39.3776],
            [-75.7643, 39.3493],
            [-75.756, 39.2461],
            [-75.8652, 39.2614],
            [-76.0103, 39.2395],
            [-76.1171, 39.1071],
            [-76.258, 39.0118],
            [-76.3517, 39.0562],
            [-76.3495, 39.1566],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400450',
        NAME: 'Kent County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 717510795,
        AWATER: 353308111,
        INTPTLAT: '+39.2412793',
        INTPTLON: '-076.1259867',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4588, 39.2203],
            [-77.1688, 39.3535],
            [-77.1306, 39.2684],
            [-77.0656, 39.251],
            [-76.9486, 39.1296],
            [-76.8885, 39.131],
            [-77.0024, 38.9657],
            [-77.1198, 38.9343],
            [-77.2449, 38.9826],
            [-77.3283, 39.0577],
            [-77.4606, 39.0748],
            [-77.5273, 39.147],
            [-77.4588, 39.2203],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400480',
        NAME: 'Montgomery County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1276634446,
        AWATER: 36245371,
        INTPTLAT: '+39.1373815',
        INTPTLON: '-077.2030633',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0864, 38.7062],
            [-77.0414, 38.7854],
            [-77.039, 38.7916],
            [-76.9094, 38.8929],
            [-77.0024, 38.9657],
            [-76.8885, 39.131],
            [-76.8404, 39.1031],
            [-76.6983, 38.9836],
            [-76.6696, 38.9037],
            [-76.7144, 38.7808],
            [-76.6864, 38.7485],
            [-76.6755, 38.5359],
            [-76.7476, 38.6173],
            [-76.863, 38.6586],
            [-76.9657, 38.6613],
            [-77.0502, 38.6207],
            [-77.0864, 38.7062],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400510',
        NAME: "Prince George's County Public Schools",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1250211154,
        AWATER: 41768855,
        INTPTLAT: '+38.8258796',
        INTPTLON: '-076.8472724',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3517, 39.0562],
            [-76.258, 39.0118],
            [-76.1171, 39.1071],
            [-76.0103, 39.2395],
            [-75.8652, 39.2614],
            [-75.756, 39.2461],
            [-75.751, 39.1836],
            [-75.7478, 39.1433],
            [-75.8349, 39.1084],
            [-75.9297, 38.9907],
            [-75.9493, 38.9183],
            [-76.097, 38.9443],
            [-76.1035, 38.8823],
            [-76.4365, 38.8074],
            [-76.3517, 39.0562],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400540',
        NAME: 'Queen Annes County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 962645491,
        AWATER: 360037121,
        INTPTLAT: '+39.0406929',
        INTPTLON: '-076.0824053',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2361, 37.8885],
            [-76.2047, 38.0584],
            [-76.0786, 38.0609],
            [-76.0316, 38.1475],
            [-75.9485, 38.216],
            [-75.881, 38.2176],
            [-75.7697, 38.2846],
            [-75.6128, 38.2786],
            [-75.5459, 38.1787],
            [-75.5422, 38.1093],
            [-75.6618, 38.0419],
            [-75.6243, 37.9942],
            [-75.8012, 37.9122],
            [-75.9527, 37.9068],
            [-76.052, 37.9536],
            [-76.2365, 37.8866],
            [-76.2361, 37.8885],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400570',
        NAME: 'Somerset County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 828090980,
        AWATER: 752713771,
        INTPTLAT: '+38.0744501',
        INTPTLON: '-075.8533228',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6741, 38.4996],
            [-76.5341, 38.3978],
            [-76.4453, 38.3052],
            [-76.3219, 38.3273],
            [-76.2047, 38.0584],
            [-76.2361, 37.8885],
            [-76.4129, 37.9663],
            [-76.5164, 38.0347],
            [-76.6104, 38.1485],
            [-76.8689, 38.1714],
            [-76.8213, 38.2688],
            [-76.8715, 38.3899],
            [-76.775, 38.5089],
            [-76.6741, 38.4996],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400600',
        NAME: 'St. Marys County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 925648069,
        AWATER: 1053753865,
        INTPTLAT: '+38.2202894',
        INTPTLON: '-076.5303837',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4636, 38.7111],
            [-76.4365, 38.8074],
            [-76.1035, 38.8823],
            [-76.097, 38.9443],
            [-75.9493, 38.9183],
            [-75.8968, 38.8151],
            [-76.015, 38.7287],
            [-75.9452, 38.6741],
            [-76.0234, 38.5739],
            [-76.2401, 38.6632],
            [-76.3846, 38.5762],
            [-76.4388, 38.5753],
            [-76.4636, 38.7111],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400630',
        NAME: 'Talbot County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 695511462,
        AWATER: 539408789,
        INTPTLAT: '+38.7483519',
        INTPTLON: '-076.1784798',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3428, 39.7223],
            [-78.0994, 39.7221],
            [-77.79, 39.7216],
            [-77.6696, 39.7207],
            [-77.4691, 39.72],
            [-77.5702, 39.62],
            [-77.6772, 39.3245],
            [-77.7195, 39.3213],
            [-77.7537, 39.4239],
            [-77.8256, 39.4933],
            [-77.8891, 39.5975],
            [-78.0221, 39.6197],
            [-78.1715, 39.6956],
            [-78.2666, 39.619],
            [-78.3338, 39.6368],
            [-78.3428, 39.7223],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400660',
        NAME: 'Washington County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1185686686,
        AWATER: 24726569,
        INTPTLAT: '+39.6036207',
        INTPTLON: '-077.8146709',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7016, 38.5607],
            [-75.6985, 38.522],
            [-75.6937, 38.4601],
            [-75.4286, 38.4528],
            [-75.3413, 38.452],
            [-75.307, 38.3548],
            [-75.3646, 38.2904],
            [-75.6128, 38.2786],
            [-75.7697, 38.2846],
            [-75.881, 38.2176],
            [-75.9485, 38.216],
            [-75.9286, 38.2973],
            [-75.8642, 38.3508],
            [-75.8237, 38.481],
            [-75.7016, 38.5607],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400690',
        NAME: 'Wicomico County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 969732871,
        AWATER: 66801500,
        INTPTLAT: '+38.3671156',
        INTPTLON: '-075.6319639',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6128, 38.2786],
            [-75.3646, 38.2904],
            [-75.307, 38.3548],
            [-75.3413, 38.452],
            [-74.9863, 38.4516],
            [-74.9996, 38.3717],
            [-75.1664, 38.0278],
            [-75.6243, 37.9942],
            [-75.6618, 38.0419],
            [-75.5422, 38.1093],
            [-75.5459, 38.1787],
            [-75.6128, 38.2786],
          ],
        ],
      },
      properties: {
        STATEFP: '24',
        SCSDLEA: '',
        GEOID: '2400720',
        NAME: 'Worcester County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1212833034,
        AWATER: 586833648,
        INTPTLAT: '+38.2221332',
        INTPTLON: '-075.3099315',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
  ],
};

import EventInfo from './eventInfo';

export default class HitInfo {
  static get PAGE_VIEW() {
    return 0;
  }

  static get EVENT() {
    return 1;
  }

  constructor(hitType) {
    this.hitType = hitType === HitInfo.PAGE_VIEW ? 'pageview' : 'event';
    this.customDimensions = [];
  }

  setPath(path) {
    this.path = path;
    return this;
  }

  setEventInfo(category, action) {
    this.eventInfo = new EventInfo(category, action);
    return this;
  }

  addCustomDimension(dimension, value) {
    this.customDimensions.push({ dimension, value });
    return this;
  }

  getHitType() {
    return this.hitType;
  }

  getPath() {
    return this.path;
  }

  getCustomDimensions() {
    return this.customDimensions;
  }

  getEventInfo() {
    return this.eventInfo;
  }
}

export const CO = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5447, 39.041],
            [-102.4659, 39.0427],
            [-102.354, 39.0457],
            [-102.0466, 39.047],
            [-102.0452, 38.6976],
            [-102.0451, 38.6152],
            [-102.4546, 38.6148],
            [-102.627, 38.6133],
            [-102.6192, 38.982],
            [-102.5447, 39.041],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '800001',
        NAME: 'Cheyenne County School District RE-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2336588242,
        AWATER: 286959,
        INTPTLAT: '+38.8363887',
        INTPTLON: '-102.3269763',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7934, 40.2197],
            [-102.78, 40.4384],
            [-102.5691, 40.4241],
            [-102.5016, 40.3734],
            [-102.4923, 40.3734],
            [-102.4522, 40.3519],
            [-102.4523, 40.1271],
            [-102.4303, 39.9834],
            [-102.457, 39.8607],
            [-102.4751, 39.8316],
            [-102.8004, 39.8308],
            [-102.803, 39.9602],
            [-102.7934, 40.2197],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '800016',
        NAME: 'Yuma School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1897738954,
        AWATER: 928298,
        INTPTLAT: '+40.1246352',
        INTPTLON: '-102.6333456',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4522, 40.3519],
            [-102.401, 40.3958],
            [-102.0515, 40.3966],
            [-102.0516, 40.2659],
            [-102.0517, 40.0031],
            [-102.0515, 39.8595],
            [-102.0717, 39.8332],
            [-102.3143, 39.8314],
            [-102.457, 39.8607],
            [-102.4303, 39.9834],
            [-102.4523, 40.1271],
            [-102.4522, 40.3519],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '800017',
        NAME: 'Wray School District RD-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2067327082,
        AWATER: 1754391,
        INTPTLAT: '+40.1197393',
        INTPTLON: '-102.2363560',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.457, 39.8607],
            [-102.3143, 39.8314],
            [-102.0717, 39.8332],
            [-102.0515, 39.8595],
            [-102.0498, 39.5741],
            [-102.3447, 39.5709],
            [-102.4, 39.5997],
            [-102.4599, 39.614],
            [-102.4751, 39.8316],
            [-102.457, 39.8607],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '800018',
        NAME: 'Idalia School District RJ-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1017006012,
        AWATER: 7898169,
        INTPTLAT: '+39.7098238',
        INTPTLON: '-102.2731641',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8004, 39.8308],
            [-102.4751, 39.8316],
            [-102.4599, 39.614],
            [-102.4, 39.5997],
            [-102.4304, 39.5708],
            [-102.4814, 39.5659],
            [-102.6674, 39.5039],
            [-102.8029, 39.5678],
            [-102.8004, 39.8308],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '800019',
        NAME: 'Liberty School District J-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 979496219,
        AWATER: 525937,
        INTPTLAT: '+39.6773444',
        INTPTLON: '-102.6224630',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9357, 38.9835],
            [-104.8494, 39.0566],
            [-104.6074, 39.057],
            [-104.7197, 38.9402],
            [-104.9053, 38.9111],
            [-104.9357, 38.9835],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '801920',
        NAME: 'Academy School District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332084029,
        AWATER: 165582,
        INTPTLAT: '+38.9914897',
        INTPTLON: '-104.7892297',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9666, 39.7938],
            [-104.9402, 39.885],
            [-104.9125, 39.9072],
            [-104.9082, 39.8815],
            [-104.7909, 39.8418],
            [-104.7909, 39.7983],
            [-104.9666, 39.7938],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '801950',
        NAME: 'Adams County School District 14',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119300876,
        AWATER: 3722345,
        INTPTLAT: '+39.8361750',
        INTPTLON: '-104.8749334',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.2961, 39.5647],
            [-104.285, 39.5647],
            [-104.1743, 39.5653],
            [-103.7144, 39.5663],
            [-103.715, 39.4337],
            [-103.8257, 39.4342],
            [-103.8258, 39.3033],
            [-103.8997, 39.2463],
            [-104.0296, 39.2449],
            [-104.0878, 39.3454],
            [-104.1833, 39.3739],
            [-104.2014, 39.4336],
            [-104.2971, 39.4331],
            [-104.2961, 39.5647],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '801980',
        NAME: 'Agate School District 300',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1188816927,
        AWATER: 123516,
        INTPTLAT: '+39.3650295',
        INTPTLON: '-104.0191700',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.029, 37.3395],
            [-104.925, 37.3962],
            [-104.7484, 37.4074],
            [-104.5487, 37.5747],
            [-104.4549, 37.7408],
            [-104.3511, 37.8175],
            [-104.1432, 37.7583],
            [-104.2461, 37.6494],
            [-104.2478, 37.5839],
            [-104.3366, 37.4677],
            [-104.3373, 37.3815],
            [-104.4489, 37.3818],
            [-104.5182, 37.3243],
            [-104.6101, 37.2639],
            [-104.8298, 37.2509],
            [-105.0294, 37.2508],
            [-105.029, 37.3395],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802010',
        NAME: 'Aguilar Reorganized School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1879179353,
        AWATER: 158386,
        INTPTLAT: '+37.5488542',
        INTPTLON: '-104.4910197',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3569, 40.3496],
            [-103.2352, 40.3783],
            [-103.1604, 40.4367],
            [-103.0456, 40.4371],
            [-103.0617, 40.2948],
            [-103.0798, 40.2218],
            [-103.086, 39.9606],
            [-103.1435, 39.8871],
            [-103.2934, 39.8492],
            [-103.3661, 39.8705],
            [-103.3651, 39.957],
            [-103.472, 40.0015],
            [-103.4714, 40.2918],
            [-103.3592, 40.2923],
            [-103.3569, 40.3496],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802040',
        NAME: 'Akron School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1704180107,
        AWATER: 440534,
        INTPTLAT: '+40.1413211',
        INTPTLON: '-103.2548311',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1124, 37.4011],
            [-106.0393, 37.4009],
            [-106.0393, 37.5702],
            [-105.9744, 37.565],
            [-105.5958, 37.5615],
            [-105.4855, 37.5779],
            [-105.7433, 37.3568],
            [-105.8751, 37.384],
            [-105.9838, 37.3418],
            [-106.1123, 37.3354],
            [-106.1124, 37.4011],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802070',
        NAME: 'Alamosa School District RE-11J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 852136289,
        AWATER: 1545318,
        INTPTLAT: '+37.4692166',
        INTPTLON: '-105.8289777',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8178, 37.1367],
            [-105.7481, 37.1366],
            [-105.7707, 37.0418],
            [-105.7185, 36.9958],
            [-106.4762, 36.9938],
            [-106.6707, 37.2254],
            [-106.365, 37.2279],
            [-106.3641, 37.1413],
            [-105.8178, 37.1367],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802130',
        NAME: 'South Conejos School District RE-10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1315360312,
        AWATER: 1700777,
        INTPTLAT: '+37.0878409',
        INTPTLON: '-106.1995407',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4822, 37.6395],
            [-107.4822, 37.643],
            [-107.3067, 37.6775],
            [-107.2315, 37.5953],
            [-107.1252, 37.6041],
            [-107.1287, 37.3924],
            [-106.7108, 37.4042],
            [-106.6784, 37.4036],
            [-106.6707, 37.2254],
            [-106.4762, 36.9938],
            [-106.8958, 37.0001],
            [-107.3338, 37],
            [-107.3345, 37.1411],
            [-107.443, 37.1413],
            [-107.4821, 37.1838],
            [-107.4526, 37.2528],
            [-107.4823, 37.3287],
            [-107.4822, 37.6395],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802190',
        NAME: 'Archuleta County School District 50-JT',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4071193694,
        AWATER: 6825659,
        INTPTLAT: '+37.2648486',
        INTPTLON: '-107.0789593',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.2934, 39.8492],
            [-103.1435, 39.8871],
            [-103.086, 39.9606],
            [-102.803, 39.9602],
            [-102.8004, 39.8308],
            [-102.8029, 39.5678],
            [-102.9677, 39.5682],
            [-103.2746, 39.5665],
            [-103.2556, 39.7844],
            [-103.2934, 39.8492],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802220',
        NAME: 'Arickaree School District R-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1655592500,
        AWATER: 3665471,
        INTPTLAT: '+39.7469167',
        INTPTLON: '-103.0322247',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3781, 39.5662],
            [-103.2746, 39.5665],
            [-102.9677, 39.5682],
            [-102.9506, 39.301],
            [-102.9776, 39.1999],
            [-102.9773, 39.0393],
            [-103.163, 39.0376],
            [-103.1551, 39.1419],
            [-103.3795, 39.1422],
            [-103.3781, 39.5662],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802260',
        NAME: 'Arriba-Flagler School District C-20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1863208770,
        AWATER: 4925369,
        INTPTLAT: '+39.3281352',
        INTPTLON: '-103.1635083',
        ELSDLEA: '',
        UNSDLEA: '02260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.9885, 39.0696],
            [-106.9699, 39.1991],
            [-106.8977, 39.3329],
            [-106.8226, 39.3046],
            [-106.6701, 39.3039],
            [-106.6671, 39.2456],
            [-106.4891, 39.2485],
            [-106.578, 39.0579],
            [-106.5992, 38.998],
            [-106.7407, 39.0459],
            [-106.8019, 38.9783],
            [-106.9082, 38.9926],
            [-106.9885, 39.0696],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802280',
        NAME: 'Aspen School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1117805310,
        AWATER: 1061480,
        INTPTLAT: '+39.1581716',
        INTPTLON: '-106.6685596',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9434, 40.9982],
            [-104.6001, 41.0015],
            [-104.6009, 40.8407],
            [-104.524, 40.7985],
            [-104.5621, 40.7686],
            [-104.5829, 40.638],
            [-104.6966, 40.5604],
            [-104.8222, 40.5594],
            [-104.8318, 40.6174],
            [-104.944, 40.6168],
            [-104.9434, 40.9982],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802310',
        NAME: 'Ault-Highland School District RE-9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1388889484,
        AWATER: 1039998,
        INTPTLAT: '+40.7877930',
        INTPTLON: '-104.7749292',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7627, 39.8056],
            [-104.7161, 39.8232],
            [-104.6591, 39.814],
            [-104.6592, 39.8128],
            [-104.6603, 39.7106],
            [-104.6132, 39.6383],
            [-104.8279, 39.6531],
            [-104.866, 39.7111],
            [-104.8799, 39.7547],
            [-104.7627, 39.8056],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802340',
        NAME: 'Adams-Arapahoe School District 28J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303006796,
        AWATER: 291817,
        INTPTLAT: '+39.7141863',
        INTPTLON: '-104.7285303',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8297, 39.5649],
            [-105.3989, 39.5661],
            [-105.3981, 39.3158],
            [-105.5313, 39.3491],
            [-105.8989, 39.4723],
            [-105.8297, 39.5649],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802370',
        NAME: 'Platte Canyon School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 814998077,
        AWATER: 1132374,
        INTPTLAT: '+39.4976281',
        INTPTLON: '-105.6499586',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.8039, 37.6402],
            [-107.4822, 37.6395],
            [-107.4823, 37.3287],
            [-107.4526, 37.2528],
            [-107.4821, 37.1838],
            [-107.6506, 37.1839],
            [-107.6969, 37.2159],
            [-107.7436, 37.3118],
            [-107.6475, 37.3426],
            [-107.6472, 37.45],
            [-107.7231, 37.4499],
            [-107.7212, 37.5528],
            [-107.8219, 37.5545],
            [-107.8039, 37.6402],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802400',
        NAME: 'Bayfield School District R-10-JT',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1061394340,
        AWATER: 14563747,
        INTPTLAT: '+37.4989744',
        INTPTLON: '-107.5769734',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6592, 39.8128],
            [-104.5457, 39.8259],
            [-104.4894, 39.9266],
            [-104.3764, 39.9267],
            [-104.3778, 39.6924],
            [-104.3987, 39.5639],
            [-104.4574, 39.564],
            [-104.5692, 39.5653],
            [-104.6008, 39.5658],
            [-104.6132, 39.6383],
            [-104.6603, 39.7106],
            [-104.6592, 39.8128],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802430',
        NAME: 'Bennett School District 29-J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 746081160,
        AWATER: 3698886,
        INTPTLAT: '+39.7454414',
        INTPTLON: '-104.4963778',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4814, 39.5659],
            [-102.4304, 39.5708],
            [-102.3615, 39.5039],
            [-102.354, 39.0457],
            [-102.4659, 39.0427],
            [-102.4837, 39.1708],
            [-102.4814, 39.5659],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802460',
        NAME: 'Bethune School District R-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 624533203,
        AWATER: 51803,
        INTPTLAT: '+39.2973708',
        INTPTLON: '-102.3967506',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.6266, 40.1279],
            [-105.357, 40.1214],
            [-105.2821, 40.087],
            [-105.0934, 40.0657],
            [-105.0528, 40.0003],
            [-105.0529, 39.9142],
            [-105.3978, 39.9129],
            [-105.3975, 39.8543],
            [-105.5377, 39.882],
            [-105.7008, 39.8789],
            [-105.6832, 39.9929],
            [-105.6266, 40.1279],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802490',
        NAME: 'Boulder Valley School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1227945005,
        AWATER: 20882381,
        INTPTLAT: '+39.9974059',
        INTPTLON: '-105.4009150',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7326, 37.5092],
            [-103.6252, 37.5077],
            [-103.659, 37.3911],
            [-103.7336, 37.2889],
            [-103.7332, 37.1177],
            [-103.4732, 37.1044],
            [-103.4729, 37.0323],
            [-103.4081, 36.9994],
            [-103.4723, 36.9992],
            [-104.0079, 36.9962],
            [-104.2323, 36.9948],
            [-104.2317, 37.1174],
            [-104.1764, 37.1172],
            [-104.0645, 37.2199],
            [-103.9205, 37.2912],
            [-103.9032, 37.349],
            [-103.7326, 37.5092],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802520',
        NAME: 'Branson Reorganized School District 82',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1869521091,
        AWATER: 0,
        INTPTLAT: '+37.1687855',
        INTPTLON: '-103.8488087',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.4669, 40.7844],
            [-104.2685, 40.7847],
            [-104.1901, 40.6983],
            [-104.0289, 40.6993],
            [-104.0514, 40.5244],
            [-104.1477, 40.5171],
            [-104.1467, 40.4378],
            [-104.3, 40.4368],
            [-104.3372, 40.5157],
            [-104.4868, 40.6099],
            [-104.4669, 40.7844],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802550',
        NAME: 'Briggsdale School District RE-10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1019182638,
        AWATER: 512315,
        INTPTLAT: '+40.6249825',
        INTPTLON: '-104.2870969',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9593, 40.0003],
            [-104.7537, 40.0222],
            [-104.4898, 40.0006],
            [-104.4894, 39.9266],
            [-104.5457, 39.8259],
            [-104.6592, 39.8128],
            [-104.6591, 39.814],
            [-104.6004, 39.8995],
            [-104.7315, 39.8986],
            [-104.7909, 39.8418],
            [-104.9082, 39.8815],
            [-104.9125, 39.9072],
            [-104.9593, 40.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802580',
        NAME: 'Brighton School District 27J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 534356212,
        AWATER: 15101090,
        INTPTLAT: '+39.9379374',
        INTPTLON: '-104.7132425',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.6391, 40.5227],
            [-103.5818, 40.5234],
            [-103.5224, 40.4369],
            [-103.3569, 40.3496],
            [-103.3592, 40.2923],
            [-103.4714, 40.2918],
            [-103.472, 40.0015],
            [-103.698, 40.0013],
            [-103.6945, 40.4348],
            [-103.6391, 40.5227],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802610',
        NAME: 'Brush School District RE-2J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1102796530,
        AWATER: 3169639,
        INTPTLAT: '+40.2409499',
        INTPTLON: '-103.5268137',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.578, 39.0579],
            [-106.191, 39.0561],
            [-106.1058, 38.9404],
            [-106.0281, 38.9428],
            [-105.9066, 38.807],
            [-105.9699, 38.6944],
            [-106.1667, 38.6267],
            [-106.3859, 38.6267],
            [-106.4302, 38.6535],
            [-106.4163, 38.8239],
            [-106.465, 38.9104],
            [-106.5992, 38.998],
            [-106.578, 39.0579],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802640',
        NAME: 'Buena Vista School District R-31',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1739099572,
        AWATER: 3380647,
        INTPTLAT: '+38.8368689',
        INTPTLON: '-106.2952552',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4, 39.5997],
            [-102.3447, 39.5709],
            [-102.0498, 39.5741],
            [-102.0498, 39.5682],
            [-102.0472, 39.1331],
            [-102.0467, 39.0756],
            [-102.0466, 39.047],
            [-102.354, 39.0457],
            [-102.3615, 39.5039],
            [-102.4304, 39.5708],
            [-102.4, 39.5997],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802670',
        NAME: 'Burlington School District RE-6J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1568329948,
        AWATER: 837856,
        INTPTLAT: '+39.3216667',
        INTPTLON: '-102.2232314',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.188, 39.9424],
            [-104.0751, 39.9427],
            [-103.9809, 40.0011],
            [-103.7057, 40.0014],
            [-103.7065, 39.769],
            [-103.7779, 39.7982],
            [-103.9246, 39.7977],
            [-104.0569, 39.8266],
            [-104.0965, 39.6818],
            [-104.1736, 39.6529],
            [-104.1743, 39.5653],
            [-104.285, 39.5647],
            [-104.2838, 39.7387],
            [-104.2279, 39.739],
            [-104.2259, 39.8551],
            [-104.188, 39.9424],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802700',
        NAME: 'Byers School District 32J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1179673606,
        AWATER: 16443089,
        INTPTLAT: '+39.8628815',
        INTPTLON: '-104.0144084',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.3863, 39.1582],
            [-104.3477, 39.2232],
            [-104.2645, 39.2159],
            [-104.2191, 39.0989],
            [-104.1444, 39.1002],
            [-104.1459, 38.9844],
            [-104.1846, 38.9116],
            [-104.3144, 38.9112],
            [-104.3865, 38.9394],
            [-104.417, 39.1112],
            [-104.3863, 39.1582],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802730',
        NAME: 'Calhan School District RJ-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579318107,
        AWATER: 429322,
        INTPTLAT: '+39.0662376',
        INTPTLON: '-104.2791368',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8142, 37.1625],
            [-102.561, 37.2068],
            [-102.4794, 37.178],
            [-102.3617, 37.1773],
            [-102.3252, 36.9943],
            [-102.3533, 36.9945],
            [-102.8867, 36.9995],
            [-102.8864, 37.1488],
            [-102.8142, 37.1625],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802760',
        NAME: 'Campo School District RE-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 990273994,
        AWATER: 16133,
        INTPTLAT: '+37.0642030',
        INTPTLON: '-102.5893574',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7021, 38.6947],
            [-105.3291, 38.6972],
            [-105.2404, 38.6476],
            [-104.9424, 38.6499],
            [-105.0002, 38.6098],
            [-104.999, 38.5205],
            [-105.0716, 38.5055],
            [-105.2174, 38.3589],
            [-105.2163, 38.2586],
            [-105.3689, 38.2594],
            [-105.3803, 38.4312],
            [-105.4981, 38.4374],
            [-105.4963, 38.5019],
            [-105.6077, 38.5023],
            [-105.7021, 38.6947],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802790',
        NAME: 'Cañon City School District RE-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1704618399,
        AWATER: 209381,
        INTPTLAT: '+38.5275385',
        INTPTLON: '-105.2990143',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0533, 37.9223],
            [-105.9282, 37.9207],
            [-105.9285, 37.8649],
            [-106.0107, 37.8066],
            [-106.0386, 37.712],
            [-106.2319, 37.7261],
            [-106.439, 37.7483],
            [-106.4365, 37.9233],
            [-106.0533, 37.9223],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802850',
        NAME: 'Center School District 26-JT',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 866897825,
        AWATER: 1266462,
        INTPTLAT: '+37.8283043',
        INTPTLON: '-106.1979474',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.6647, 38.113],
            [-103.6193, 38.1715],
            [-103.5094, 38.1725],
            [-103.5018, 38.265],
            [-103.3999, 38.2654],
            [-103.3987, 38.0847],
            [-103.5648, 38.048],
            [-103.6654, 38.0616],
            [-103.6647, 38.113],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802880',
        NAME: 'Cheraw School District 31',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333481529,
        AWATER: 9959134,
        INTPTLAT: '+38.1438813',
        INTPTLON: '-103.4926705',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9786, 39.6241],
            [-104.9676, 39.6604],
            [-104.866, 39.7111],
            [-104.8279, 39.6531],
            [-104.6132, 39.6383],
            [-104.6008, 39.5658],
            [-104.9119, 39.566],
            [-104.9786, 39.6241],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802910',
        NAME: 'Cherry Creek School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280042211,
        AWATER: 4293873,
        INTPTLAT: '+39.6156390',
        INTPTLON: '-104.7846505',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0718, 38.8179],
            [-104.8866, 38.8243],
            [-104.8221, 38.8027],
            [-104.8125, 38.7669],
            [-104.9397, 38.6961],
            [-104.939, 38.7969],
            [-105.0718, 38.8179],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '802940',
        NAME: 'Cheyenne Mountain School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135610472,
        AWATER: 788317,
        INTPTLAT: '+38.7761990',
        INTPTLON: '-104.9257621',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.9246, 39.699],
            [-105.8876, 39.7966],
            [-105.7364, 39.8048],
            [-105.6903, 39.852],
            [-105.5386, 39.7716],
            [-105.3979, 39.7471],
            [-105.3989, 39.5661],
            [-105.8297, 39.5649],
            [-105.7827, 39.629],
            [-105.9246, 39.699],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803000',
        NAME: 'Clear Creek School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1023554877,
        AWATER: 3279667,
        INTPTLAT: '+39.6894025',
        INTPTLON: '-105.6707833',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9704, 39.3662],
            [-107.9155, 39.3662],
            [-107.4316, 39.3662],
            [-107.4565, 39.3069],
            [-107.3945, 39.2563],
            [-107.5098, 39.2166],
            [-107.7651, 39.0447],
            [-107.8626, 39.0789],
            [-107.9822, 39.0599],
            [-108.1856, 38.8924],
            [-108.1802, 39.0398],
            [-108.2506, 39.0819],
            [-108.2897, 39.1923],
            [-108.2895, 39.2481],
            [-108.1595, 39.2972],
            [-107.9706, 39.3159],
            [-107.9704, 39.3662],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803030',
        NAME: 'Plateau Valley School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2076607791,
        AWATER: 8573518,
        INTPTLAT: '+39.2189855',
        INTPTLON: '-107.9100166',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7197, 38.9402],
            [-104.6825, 38.8533],
            [-104.6919, 38.8174],
            [-104.7203, 38.8174],
            [-104.8221, 38.8027],
            [-104.8866, 38.8243],
            [-104.9053, 38.9111],
            [-104.7197, 38.9402],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803060',
        NAME: 'Colorado Springs School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185979581,
        AWATER: 470959,
        INTPTLAT: '+38.8716965',
        INTPTLON: '-104.7965789',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0434, 37.4848],
            [-108.9171, 37.6319],
            [-108.6661, 37.6316],
            [-108.5981, 37.5365],
            [-108.6191, 37.4503],
            [-108.5275, 37.4173],
            [-108.444, 37.3937],
            [-108.4447, 37.2975],
            [-108.5546, 37.283],
            [-108.5445, 37.1565],
            [-108.4456, 37.2159],
            [-108.2952, 37.215],
            [-108.2905, 37.146],
            [-108.3792, 36.9995],
            [-108.5364, 36.9993],
            [-109.0452, 36.999],
            [-109.0434, 37.4848],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803090',
        NAME: 'Montezuma-Cortez School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3384391311,
        AWATER: 3607280,
        INTPTLAT: '+37.2547520',
        INTPTLON: '-108.7522496',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8143, 38.6945],
            [-105.7021, 38.6947],
            [-105.6077, 38.5023],
            [-105.4963, 38.5019],
            [-105.4981, 38.4374],
            [-105.3803, 38.4312],
            [-105.3689, 38.2594],
            [-105.7969, 38.265],
            [-105.8835, 38.3282],
            [-105.881, 38.4084],
            [-105.8091, 38.5171],
            [-105.8143, 38.6945],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803120',
        NAME: 'Cotopaxi School District RE-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1324446914,
        AWATER: 15955,
        INTPTLAT: '+38.4059859',
        INTPTLON: '-105.6680308',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0006, 37.956],
            [-106.8714, 37.9419],
            [-106.6938, 37.8352],
            [-106.7116, 37.6643],
            [-106.7108, 37.4042],
            [-107.1287, 37.3924],
            [-107.1252, 37.6041],
            [-107.1451, 37.6755],
            [-107.1382, 37.9388],
            [-107.0006, 37.956],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803150',
        NAME: 'Creede Consolidated School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2268152612,
        AWATER: 5222161,
        INTPTLAT: '+37.6514834',
        INTPTLON: '-106.9323033',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3284, 38.8928],
            [-105.2185, 38.851],
            [-105.0684, 38.8511],
            [-105.0718, 38.8179],
            [-104.939, 38.7969],
            [-104.9397, 38.6961],
            [-104.9424, 38.6499],
            [-105.2404, 38.6476],
            [-105.3291, 38.6972],
            [-105.3284, 38.8928],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803180',
        NAME: 'Cripple Creek-Victor School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 691870240,
        AWATER: 3006571,
        INTPTLAT: '+38.7648886',
        INTPTLON: '-105.1446164',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.999, 38.5227],
            [-103.7993, 38.5218],
            [-103.7999, 38.6089],
            [-103.6135, 38.6092],
            [-103.613, 38.5179],
            [-103.5047, 38.5164],
            [-103.5018, 38.265],
            [-103.5094, 38.1725],
            [-103.6193, 38.1715],
            [-103.6647, 38.113],
            [-103.8014, 38.1125],
            [-103.8571, 38.1701],
            [-103.9401, 38.1258],
            [-104.0028, 38.1687],
            [-103.999, 38.5227],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803210',
        NAME: 'Crowley County School District RE-1-J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1954868625,
        AWATER: 31770405,
        INTPTLAT: '+38.3466345',
        INTPTLON: '-103.7496089',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0512, 39.6605],
            [-108.5546, 39.6502],
            [-108.4506, 39.6941],
            [-108.2179, 39.6939],
            [-108.2189, 39.565],
            [-108.1606, 39.5652],
            [-108.1607, 39.3662],
            [-107.9704, 39.3662],
            [-107.9706, 39.3159],
            [-108.1595, 39.2972],
            [-108.2895, 39.2481],
            [-108.2897, 39.1923],
            [-108.3813, 39.2205],
            [-108.3801, 39.3072],
            [-108.5266, 39.309],
            [-108.5257, 39.3662],
            [-109.0508, 39.3667],
            [-109.0512, 39.4977],
            [-109.0512, 39.6605],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803240',
        NAME: 'De Beque School District 49-JT',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2999986640,
        AWATER: 2524693,
        INTPTLAT: '+39.4805592',
        INTPTLON: '-108.5961659',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7065, 39.769],
            [-103.7071, 39.5663],
            [-103.7144, 39.5663],
            [-104.1743, 39.5653],
            [-104.1736, 39.6529],
            [-104.0965, 39.6818],
            [-104.0569, 39.8266],
            [-103.9246, 39.7977],
            [-103.7779, 39.7982],
            [-103.7065, 39.769],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803270',
        NAME: 'Deer Trail School District 26J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 929501921,
        AWATER: 6080125,
        INTPTLAT: '+39.6868575',
        INTPTLON: '-103.8942514',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.6938, 37.8352],
            [-106.5871, 37.8344],
            [-106.5864, 37.7478],
            [-106.439, 37.7483],
            [-106.2319, 37.7261],
            [-106.2228, 37.6314],
            [-106.2957, 37.5012],
            [-106.3646, 37.5014],
            [-106.3654, 37.4009],
            [-106.6784, 37.4036],
            [-106.7108, 37.4042],
            [-106.7116, 37.6643],
            [-106.6938, 37.8352],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803300',
        NAME: 'Del Norte School District C-7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1521951506,
        AWATER: 437179,
        INTPTLAT: '+37.6185218',
        INTPTLON: '-106.4905693',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.1856, 38.8924],
            [-107.9822, 39.0599],
            [-107.8626, 39.0789],
            [-107.7651, 39.0447],
            [-107.5098, 39.2166],
            [-107.3945, 39.2563],
            [-107.3537, 39.1733],
            [-107.2744, 39.1187],
            [-107.2786, 38.8691],
            [-107.4821, 38.8676],
            [-107.5006, 38.8396],
            [-107.5006, 38.4468],
            [-107.6783, 38.5558],
            [-107.7958, 38.5985],
            [-107.8486, 38.6681],
            [-108.3787, 38.6681],
            [-108.3791, 38.5],
            [-108.601, 38.5],
            [-108.5975, 38.6977],
            [-108.3788, 38.6962],
            [-108.379, 38.8291],
            [-108.1856, 38.8924],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803330',
        NAME: 'Delta County School District 50J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4543875225,
        AWATER: 20944292,
        INTPTLAT: '+38.8396543',
        INTPTLON: '-107.8212269',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0532, 39.7911],
            [-104.9967, 39.7911],
            [-104.9666, 39.7938],
            [-104.7909, 39.7983],
            [-104.7909, 39.8418],
            [-104.7315, 39.8986],
            [-104.6004, 39.8995],
            [-104.6591, 39.814],
            [-104.7161, 39.8232],
            [-104.7627, 39.8056],
            [-104.8799, 39.7547],
            [-104.866, 39.7111],
            [-104.9676, 39.6604],
            [-105.0049, 39.6605],
            [-105.0107, 39.6605],
            [-105.0158, 39.6637],
            [-105.0276, 39.6282],
            [-105.0534, 39.6316],
            [-105.0535, 39.6295],
            [-105.0533, 39.628],
            [-105.0534, 39.6244],
            [-105.0534, 39.6214],
            [-105.0969, 39.6144],
            [-105.0533, 39.6678],
            [-105.0532, 39.7911],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803360',
        NAME: 'Denver County School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397136421,
        AWATER: 4225831,
        INTPTLAT: '+39.7618502',
        INTPTLON: '-104.8811054',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6661, 37.6316],
            [-107.9701, 37.6396],
            [-108.0241, 37.5435],
            [-108.177, 37.5471],
            [-108.177, 37.5042],
            [-108.3349, 37.5039],
            [-108.3354, 37.4293],
            [-108.444, 37.3937],
            [-108.5275, 37.4173],
            [-108.6191, 37.4503],
            [-108.5981, 37.5365],
            [-108.6661, 37.6316],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803390',
        NAME: 'Dolores School District RE-4A',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 951670374,
        AWATER: 21825229,
        INTPTLAT: '+37.5591767',
        INTPTLON: '-108.2803950',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0426, 38.153],
            [-108.9067, 38.1533],
            [-108.8158, 38.0381],
            [-108.7586, 38.0379],
            [-108.7422, 37.8954],
            [-108.2565, 37.8946],
            [-108.2091, 37.8209],
            [-108.1171, 37.8535],
            [-107.9892, 37.8566],
            [-107.8608, 37.7755],
            [-107.873, 37.7224],
            [-107.9689, 37.6965],
            [-107.9701, 37.6396],
            [-108.6661, 37.6316],
            [-108.9171, 37.6319],
            [-109.0434, 37.4848],
            [-109.0426, 38.153],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803420',
        NAME: 'Dolores County School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3394306380,
        AWATER: 2716141,
        INTPTLAT: '+37.7822083',
        INTPTLON: '-108.5816994',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3292, 39.1297],
            [-105.2339, 39.2469],
            [-105.0487, 39.5661],
            [-104.9119, 39.566],
            [-104.6008, 39.5658],
            [-104.5692, 39.5653],
            [-104.5701, 39.4919],
            [-104.6621, 39.4804],
            [-104.6629, 39.2458],
            [-104.6629, 39.1295],
            [-105.0335, 39.1298],
            [-105.3292, 39.1297],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803450',
        NAME: 'Douglas County School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2242757918,
        AWATER: 6758463,
        INTPTLAT: '+39.3383105',
        INTPTLON: '-104.9069045',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.2952, 37.215],
            [-108.2048, 37.3441],
            [-108.1059, 37.3821],
            [-108.0331, 37.4649],
            [-108.0241, 37.5435],
            [-107.9701, 37.6396],
            [-107.8039, 37.6402],
            [-107.8219, 37.5545],
            [-107.7212, 37.5528],
            [-107.7231, 37.4499],
            [-107.6472, 37.45],
            [-107.6475, 37.3426],
            [-107.7436, 37.3118],
            [-107.6969, 37.2159],
            [-107.7531, 37.184],
            [-107.7669, 37],
            [-108.032, 36.9998],
            [-108.3792, 36.9995],
            [-108.2905, 37.146],
            [-108.2952, 37.215],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803480',
        NAME: 'Durango School District 9-R',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2794642490,
        AWATER: 10676739,
        INTPTLAT: '+37.2673798',
        INTPTLON: '-107.9784340',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5047, 38.5164],
            [-103.1729, 38.5253],
            [-103.1729, 38.6124],
            [-102.627, 38.6133],
            [-102.4546, 38.6148],
            [-102.471, 38.2678],
            [-102.4797, 38.2679],
            [-102.6682, 38.2673],
            [-102.8345, 38.2665],
            [-103.071, 38.2664],
            [-103.3999, 38.2654],
            [-103.5018, 38.265],
            [-103.5047, 38.5164],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803510',
        NAME: 'Eads School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3206075002,
        AWATER: 44324358,
        INTPTLAT: '+38.4252084',
        INTPTLON: '-102.9451714',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0374, 40.0915],
            [-107.034, 39.9189],
            [-106.8241, 39.9195],
            [-106.7897, 40.0471],
            [-106.637, 40.0454],
            [-106.6291, 39.8288],
            [-106.4125, 39.8293],
            [-106.3895, 39.7824],
            [-106.1761, 39.6142],
            [-106.2483, 39.5443],
            [-106.2067, 39.3796],
            [-106.2832, 39.3505],
            [-106.4265, 39.3619],
            [-106.5779, 39.3621],
            [-106.5785, 39.4428],
            [-106.8539, 39.4441],
            [-106.8531, 39.4857],
            [-107.1136, 39.4955],
            [-107.1267, 39.6191],
            [-107.2, 39.6482],
            [-107.3934, 39.6521],
            [-107.4318, 39.8284],
            [-107.4317, 39.9177],
            [-107.3188, 39.915],
            [-107.317, 40.0908],
            [-107.0374, 40.0915],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803540',
        NAME: 'Eagle County School District RE 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5135395154,
        AWATER: 23397782,
        INTPTLAT: '+39.7172809',
        INTPTLON: '-106.8127214',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8222, 40.5594],
            [-104.6966, 40.5604],
            [-104.5829, 40.638],
            [-104.5621, 40.7686],
            [-104.524, 40.7985],
            [-104.4669, 40.7844],
            [-104.4868, 40.6099],
            [-104.3372, 40.5157],
            [-104.527, 40.5153],
            [-104.5732, 40.4642],
            [-104.7923, 40.4483],
            [-104.8222, 40.5594],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803600',
        NAME: 'Eaton School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592207982,
        AWATER: 4057713,
        INTPTLAT: '+40.5631659',
        INTPTLON: '-104.5936980',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.2934, 38.7083],
            [-104.2735, 38.7082],
            [-103.908, 38.6945],
            [-103.8003, 38.654],
            [-103.7999, 38.6089],
            [-103.7993, 38.5218],
            [-103.999, 38.5227],
            [-104.1341, 38.5218],
            [-104.1666, 38.45],
            [-104.2752, 38.4493],
            [-104.2755, 38.5211],
            [-104.2934, 38.7083],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803630',
        NAME: 'Edison School District 54-JT',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 875892907,
        AWATER: 744248,
        INTPTLAT: '+38.6010817',
        INTPTLON: '-104.0877657',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6629, 39.2458],
            [-104.5533, 39.2743],
            [-104.4037, 39.2663],
            [-104.3477, 39.2232],
            [-104.3863, 39.1582],
            [-104.5893, 39.1294],
            [-104.6451, 39.1295],
            [-104.6629, 39.1295],
            [-104.6629, 39.2458],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803690',
        NAME: 'Elbert School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358218384,
        AWATER: 0,
        INTPTLAT: '+39.2005793',
        INTPTLON: '-104.5134453',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5692, 39.5653],
            [-104.4574, 39.564],
            [-104.458, 39.4624],
            [-104.5145, 39.4193],
            [-104.5533, 39.2743],
            [-104.6629, 39.2458],
            [-104.6621, 39.4804],
            [-104.5701, 39.4919],
            [-104.5692, 39.5653],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803720',
        NAME: 'Elizabeth School District C-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417060171,
        AWATER: 0,
        INTPTLAT: '+39.4099523',
        INTPTLON: '-104.5729230',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6919, 38.8174],
            [-104.6825, 38.8533],
            [-104.5081, 38.8536],
            [-104.4789, 38.9396],
            [-104.3865, 38.9394],
            [-104.3144, 38.9112],
            [-104.3137, 38.7813],
            [-104.2735, 38.7082],
            [-104.2934, 38.7083],
            [-104.5519, 38.7085],
            [-104.6089, 38.7115],
            [-104.6087, 38.8246],
            [-104.6919, 38.8174],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803750',
        NAME: 'Ellicott School District 22',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610840390,
        AWATER: 61967,
        INTPTLAT: '+38.7927680',
        INTPTLON: '-104.4305434',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0049, 39.6605],
            [-104.9676, 39.6604],
            [-104.9786, 39.6241],
            [-105.0101, 39.6241],
            [-105.0049, 39.6605],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803780',
        NAME: 'Englewood School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13581902,
        AWATER: 96638,
        INTPTLAT: '+39.6484452',
        INTPTLON: '-104.9918014',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.9051, 40.5354],
            [-105.4029, 40.5342],
            [-105.4005, 40.4222],
            [-105.3355, 40.349],
            [-105.3373, 40.2473],
            [-105.4289, 40.2431],
            [-105.4463, 40.1743],
            [-105.6664, 40.1573],
            [-105.6542, 40.2613],
            [-105.7677, 40.3598],
            [-105.8549, 40.4863],
            [-105.9051, 40.5354],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803810',
        NAME: 'Park School District R-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1265006559,
        AWATER: 6510560,
        INTPTLAT: '+40.3847437',
        INTPTLON: '-105.5863764',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1355, 39.3795],
            [-106.0561, 39.3584],
            [-105.8989, 39.4723],
            [-105.5313, 39.3491],
            [-105.3981, 39.3158],
            [-105.3979, 39.1296],
            [-105.3304, 39.1297],
            [-105.3284, 38.8928],
            [-105.3291, 38.6972],
            [-105.7021, 38.6947],
            [-105.8143, 38.6945],
            [-105.9699, 38.6944],
            [-105.9066, 38.807],
            [-106.0281, 38.9428],
            [-106.1058, 38.9404],
            [-106.191, 39.0561],
            [-106.1697, 39.0864],
            [-106.184, 39.3094],
            [-106.1355, 39.3795],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803840',
        NAME: 'Park County School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4867128577,
        AWATER: 42398075,
        INTPTLAT: '+39.0512377',
        INTPTLON: '-105.7337002',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7197, 38.9402],
            [-104.6074, 39.057],
            [-104.6451, 39.1295],
            [-104.5893, 39.1294],
            [-104.5527, 38.9475],
            [-104.4789, 38.9396],
            [-104.5081, 38.8536],
            [-104.6825, 38.8533],
            [-104.7197, 38.9402],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803870',
        NAME: 'Falcon School District 49',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343723272,
        AWATER: 96918,
        INTPTLAT: '+38.9505645',
        INTPTLON: '-104.6064250',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7292, 40.7927],
            [-102.6656, 40.6132],
            [-102.7789, 40.6126],
            [-102.78, 40.4384],
            [-102.9422, 40.4379],
            [-102.9809, 40.6128],
            [-103.106, 40.6123],
            [-103.106, 40.6852],
            [-102.9597, 40.7025],
            [-102.8833, 40.8058],
            [-102.7292, 40.7927],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803930',
        NAME: 'Frenchman School District RE-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 852004986,
        AWATER: 0,
        INTPTLAT: '+40.6324740',
        INTPTLON: '-102.8640390',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9424, 38.6499],
            [-104.8298, 38.6498],
            [-104.7734, 38.606],
            [-104.7758, 38.5199],
            [-104.9415, 38.5196],
            [-104.9404, 38.2583],
            [-105.0492, 38.258],
            [-105.0504, 38.0958],
            [-105.1554, 38.1221],
            [-105.1509, 38.2578],
            [-105.2163, 38.2586],
            [-105.2174, 38.3589],
            [-105.0716, 38.5055],
            [-104.999, 38.5205],
            [-105.0002, 38.6098],
            [-104.9424, 38.6499],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803960',
        NAME: 'Florence School District RE-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 958098798,
        AWATER: 1999264,
        INTPTLAT: '+38.3833453',
        INTPTLON: '-105.0240817',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1906, 40.9976],
            [-105.2769, 40.9982],
            [-104.9434, 40.9982],
            [-104.944, 40.6168],
            [-104.9442, 40.457],
            [-105.1353, 40.4946],
            [-105.2291, 40.5953],
            [-105.4029, 40.5342],
            [-105.9051, 40.5354],
            [-106.0538, 40.8127],
            [-106.1853, 40.934],
            [-106.1906, 40.9976],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '803990',
        NAME: 'Poudre School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4758560582,
        AWATER: 61154182,
        INTPTLAT: '+40.7757960',
        INTPTLON: '-105.4880641',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9047, 40.1456],
            [-104.7539, 40.1745],
            [-104.716, 40.0586],
            [-104.7537, 40.0222],
            [-104.9593, 40.0003],
            [-104.9708, 40.0003],
            [-104.9051, 40.0583],
            [-104.9047, 40.1456],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804020',
        NAME: 'Weld County School District RE-8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266077720,
        AWATER: 1786386,
        INTPTLAT: '+40.0881719',
        INTPTLON: '-104.8344860',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.9798, 40.2765],
            [-103.924, 40.2759],
            [-103.9179, 40.5239],
            [-103.6391, 40.5227],
            [-103.6945, 40.4348],
            [-103.698, 40.0013],
            [-103.7057, 40.0014],
            [-103.9809, 40.0011],
            [-103.9798, 40.2765],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804050',
        NAME: 'Fort Morgan School District RE-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1303049549,
        AWATER: 5092908,
        INTPTLAT: '+40.2503452',
        INTPTLON: '-103.8250332',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9397, 38.6961],
            [-104.8125, 38.7669],
            [-104.7755, 38.7372],
            [-104.6089, 38.7115],
            [-104.5519, 38.7085],
            [-104.5524, 38.607],
            [-104.7734, 38.606],
            [-104.8298, 38.6498],
            [-104.9424, 38.6499],
            [-104.9397, 38.6961],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804080',
        NAME: 'Fountain School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381001114,
        AWATER: 463104,
        INTPTLAT: '+38.6874718',
        INTPTLON: '-104.7487761',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.1666, 38.45],
            [-104.1341, 38.5218],
            [-103.999, 38.5227],
            [-104.0028, 38.1687],
            [-103.9401, 38.1258],
            [-103.9496, 37.9101],
            [-103.9503, 37.8228],
            [-104.0619, 37.8227],
            [-104.0611, 37.7347],
            [-104.1432, 37.7583],
            [-104.3511, 37.8175],
            [-104.3842, 37.8268],
            [-104.3844, 37.9362],
            [-104.2267, 37.9381],
            [-104.2233, 38.1259],
            [-104.1324, 38.2143],
            [-104.1295, 38.4425],
            [-104.1666, 38.45],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804110',
        NAME: 'Fowler School District R-4J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1671460176,
        AWATER: 4030247,
        INTPTLAT: '+38.0666462',
        INTPTLON: '-104.0951701',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7433, 37.3568],
            [-105.4855, 37.5779],
            [-105.2956, 37.6544],
            [-105.2067, 37.5898],
            [-105.1713, 37.5132],
            [-105.1652, 37.2931],
            [-105.7223, 37.2943],
            [-105.7433, 37.3568],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804140',
        NAME: 'Sierra Grande School District R-30',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1450865638,
        AWATER: 3766243,
        INTPTLAT: '+37.4352806',
        INTPTLON: '-105.4073605',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9233, 40.24],
            [-104.763, 40.3489],
            [-104.6104, 40.3562],
            [-104.6027, 40.2034],
            [-104.6407, 40.161],
            [-104.7539, 40.1745],
            [-104.9047, 40.1456],
            [-104.9233, 40.24],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804200',
        NAME: 'Weld County School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455034273,
        AWATER: 12150145,
        INTPTLAT: '+40.2538894',
        INTPTLON: '-104.7443330',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7008, 39.8789],
            [-105.5377, 39.882],
            [-105.3975, 39.8543],
            [-105.3979, 39.7471],
            [-105.5386, 39.7716],
            [-105.6903, 39.852],
            [-105.7008, 39.8789],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804230',
        NAME: 'Gilpin County School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209543507,
        AWATER: 241299,
        INTPTLAT: '+39.8243718',
        INTPTLON: '-105.5158684',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4924, 39.8283],
            [-107.4318, 39.8284],
            [-107.3934, 39.6521],
            [-107.2, 39.6482],
            [-107.1267, 39.6191],
            [-107.1136, 39.4955],
            [-106.8531, 39.4857],
            [-106.8539, 39.4441],
            [-106.5785, 39.4428],
            [-106.5779, 39.3621],
            [-106.4265, 39.3619],
            [-106.5027, 39.2981],
            [-106.4891, 39.2485],
            [-106.6671, 39.2456],
            [-106.6701, 39.3039],
            [-106.8226, 39.3046],
            [-106.8977, 39.3329],
            [-106.9699, 39.1991],
            [-106.9885, 39.0696],
            [-107.0663, 39.1188],
            [-107.2744, 39.1187],
            [-107.3537, 39.1733],
            [-107.3945, 39.2563],
            [-107.4565, 39.3069],
            [-107.4316, 39.3662],
            [-107.3804, 39.4657],
            [-107.4872, 39.5656],
            [-107.4924, 39.8283],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804260',
        NAME: 'Roaring Fork School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2902598896,
        AWATER: 11207733,
        INTPTLAT: '+39.3955458',
        INTPTLON: '-107.1093383',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4797, 38.2679],
            [-102.471, 38.2678],
            [-102.2567, 38.2681],
            [-102.2565, 38.1042],
            [-102.192, 38.0433],
            [-102.2377, 37.9845],
            [-102.2194, 37.6442],
            [-102.3831, 37.6441],
            [-102.4194, 37.6439],
            [-102.4122, 38.1145],
            [-102.4687, 38.1441],
            [-102.4797, 38.2679],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804290',
        NAME: 'Granada School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1185910692,
        AWATER: 3717430,
        INTPTLAT: '+37.9691092',
        INTPTLON: '-102.3287180',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1403, 40.3301],
            [-105.906, 40.3997],
            [-105.8549, 40.4863],
            [-105.7677, 40.3598],
            [-105.6542, 40.2613],
            [-105.6664, 40.1573],
            [-105.6266, 40.1279],
            [-105.6832, 39.9929],
            [-105.7008, 39.8789],
            [-105.6903, 39.852],
            [-105.7364, 39.8048],
            [-105.8876, 39.7966],
            [-105.9998, 39.9996],
            [-106.1431, 40.043],
            [-106.1403, 40.3301],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804320',
        NAME: 'East Grand School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2179695296,
        AWATER: 39801741,
        INTPTLAT: '+40.1161126',
        INTPTLON: '-105.8713586',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0508, 39.3667],
            [-108.5257, 39.3662],
            [-108.5266, 39.309],
            [-108.3801, 39.3072],
            [-108.3813, 39.2205],
            [-108.2897, 39.1923],
            [-108.2506, 39.0819],
            [-108.1802, 39.0398],
            [-108.1856, 38.8924],
            [-108.379, 38.8291],
            [-108.3788, 38.6962],
            [-108.5975, 38.6977],
            [-108.601, 38.5],
            [-109.0601, 38.5],
            [-109.0508, 39.3667],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804350',
        NAME: 'Mesa County Valley School District 51',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5648037623,
        AWATER: 20394694,
        INTPTLAT: '+38.9605378',
        INTPTLON: '-108.6804431',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.2179, 39.6939],
            [-107.9385, 39.6945],
            [-107.9371, 39.4957],
            [-107.9155, 39.3662],
            [-107.9704, 39.3662],
            [-108.1607, 39.3662],
            [-108.1606, 39.5652],
            [-108.2189, 39.565],
            [-108.2179, 39.6939],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804380',
        NAME: 'Garfield County School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793381480,
        AWATER: 3314986,
        INTPTLAT: '+39.5331216',
        INTPTLON: '-108.0541622',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8131, 40.3842],
            [-104.7923, 40.4483],
            [-104.5732, 40.4642],
            [-104.6104, 40.3562],
            [-104.763, 40.3489],
            [-104.8131, 40.3842],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804410',
        NAME: 'Greeley School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191798827,
        AWATER: 1521951,
        INTPTLAT: '+40.4072405',
        INTPTLON: '-104.7009274',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6001, 41.0015],
            [-104.5995, 41.0015],
            [-104.0532, 41.0014],
            [-103.9148, 41.0017],
            [-103.9164, 40.6997],
            [-104.0289, 40.6993],
            [-104.1901, 40.6983],
            [-104.2685, 40.7847],
            [-104.4669, 40.7844],
            [-104.524, 40.7985],
            [-104.6009, 40.8407],
            [-104.6001, 41.0015],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804440',
        NAME: 'Pawnee School District RE-12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1615577671,
        AWATER: 643075,
        INTPTLAT: '+40.8698064',
        INTPTLON: '-104.2221409',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.5006, 38.4468],
            [-107.5006, 38.8396],
            [-107.4821, 38.8676],
            [-107.2786, 38.8691],
            [-107.2744, 39.1187],
            [-107.0663, 39.1188],
            [-106.9885, 39.0696],
            [-106.9082, 38.9926],
            [-106.8019, 38.9783],
            [-106.7407, 39.0459],
            [-106.5992, 38.998],
            [-106.465, 38.9104],
            [-106.4163, 38.8239],
            [-106.4302, 38.6535],
            [-106.3859, 38.6267],
            [-106.3481, 38.5125],
            [-106.2469, 38.4228],
            [-106.3334, 38.2927],
            [-106.505, 38.3149],
            [-106.5969, 38.1566],
            [-106.6714, 38.0876],
            [-106.7998, 38.0412],
            [-106.8714, 37.9419],
            [-107.0006, 37.956],
            [-107.0011, 38.1471],
            [-107.5686, 38.1473],
            [-107.6171, 38.2727],
            [-107.4666, 38.3101],
            [-107.3746, 38.3623],
            [-107.3725, 38.4506],
            [-107.5006, 38.4468],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804470',
        NAME: 'Gunnison Watershed School District RE-1J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9538973406,
        AWATER: 50648636,
        INTPTLAT: '+38.5533623',
        INTPTLON: '-106.9382931',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7734, 38.606],
            [-104.5524, 38.607],
            [-104.5519, 38.7085],
            [-104.2934, 38.7083],
            [-104.2755, 38.5211],
            [-104.7758, 38.5199],
            [-104.7734, 38.606],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804500',
        NAME: 'Hanover School District 28',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 687733323,
        AWATER: 173806,
        INTPTLAT: '+38.5997503',
        INTPTLON: '-104.4896252',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7203, 38.8174],
            [-104.7755, 38.7372],
            [-104.8125, 38.7669],
            [-104.8221, 38.8027],
            [-104.7203, 38.8174],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804530',
        NAME: 'Harrison School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48789737,
        AWATER: 122940,
        INTPTLAT: '+38.7908028',
        INTPTLON: '-104.7701773',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7292, 40.7927],
            [-102.6514, 40.793],
            [-102.5357, 40.7495],
            [-102.43, 40.7783],
            [-102.41, 40.7492],
            [-102.4006, 40.7493],
            [-102.4005, 40.7419],
            [-102.4004, 40.7348],
            [-102.4176, 40.6294],
            [-102.4844, 40.5416],
            [-102.4923, 40.3734],
            [-102.5016, 40.3734],
            [-102.5691, 40.4241],
            [-102.78, 40.4384],
            [-102.7789, 40.6126],
            [-102.6656, 40.6132],
            [-102.7292, 40.7927],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804560',
        NAME: 'Haxtun School District RE-2J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 967326302,
        AWATER: 20904,
        INTPTLAT: '+40.5876617',
        INTPTLON: '-102.5979300',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.3163, 40.8595],
            [-107.0829, 40.8605],
            [-107.0834, 40.5449],
            [-106.9967, 40.5167],
            [-106.9977, 40.4559],
            [-107.085, 40.4274],
            [-107.116, 40.2252],
            [-107.4394, 40.2234],
            [-107.4288, 40.5422],
            [-107.3142, 40.6003],
            [-107.3163, 40.8595],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804590',
        NAME: 'Hayden School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1794390457,
        AWATER: 1900319,
        INTPTLAT: '+40.5069191',
        INTPTLON: '-107.2392298',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.519, 38.0582],
            [-107.5686, 38.1473],
            [-107.0011, 38.1471],
            [-107.0006, 37.956],
            [-107.1382, 37.9388],
            [-107.1451, 37.6755],
            [-107.1252, 37.6041],
            [-107.2315, 37.5953],
            [-107.3067, 37.6775],
            [-107.4822, 37.643],
            [-107.4822, 37.7684],
            [-107.5689, 37.965],
            [-107.519, 38.0582],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804620',
        NAME: 'Hinsdale County School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2125048301,
        AWATER: 11982026,
        INTPTLAT: '+37.9118541',
        INTPTLON: '-107.2564655',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5182, 37.3243],
            [-104.4489, 37.3818],
            [-104.3373, 37.3815],
            [-104.3366, 37.4677],
            [-104.2478, 37.5839],
            [-104.2461, 37.6494],
            [-104.1432, 37.7583],
            [-104.0611, 37.7347],
            [-104.0582, 37.644],
            [-103.7321, 37.6436],
            [-103.7326, 37.5092],
            [-103.9032, 37.349],
            [-103.9205, 37.2912],
            [-104.0645, 37.2199],
            [-104.1764, 37.1172],
            [-104.2317, 37.1174],
            [-104.2323, 36.9948],
            [-104.4685, 36.9938],
            [-104.4851, 37.28],
            [-104.5182, 37.3243],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804650',
        NAME: 'Hoehne Reorganized School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3041211858,
        AWATER: 437022,
        INTPTLAT: '+37.3757802',
        INTPTLON: '-104.1619008',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2567, 38.2681],
            [-102.0445, 38.2687],
            [-102.0445, 38.2624],
            [-102.0422, 37.7385],
            [-102.0416, 37.6443],
            [-102.2194, 37.6442],
            [-102.2377, 37.9845],
            [-102.192, 38.0433],
            [-102.2565, 38.1042],
            [-102.2567, 38.2681],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804680',
        NAME: 'Holly School District RE-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1135130694,
        AWATER: 3759815,
        INTPTLAT: '+37.9612677',
        INTPTLON: '-102.1449177',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.4006, 40.7493],
              [-102.3912, 40.7419],
              [-102.4005, 40.7419],
              [-102.4006, 40.7493],
            ],
          ],
          [
            [
              [-102.4004, 40.7348],
              [-102.3912, 40.7419],
              [-102.3822, 40.7639],
              [-102.3631, 40.7493],
              [-102.0514, 40.7496],
              [-102.0515, 40.6464],
              [-102.0515, 40.3966],
              [-102.401, 40.3958],
              [-102.4522, 40.3519],
              [-102.4923, 40.3734],
              [-102.4844, 40.5416],
              [-102.4176, 40.6294],
              [-102.4004, 40.7348],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804710',
        NAME: 'Holyoke School District RE-1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1364165757,
        AWATER: 309293,
        INTPTLAT: '+40.5637547',
        INTPTLON: '-102.2539564',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.721, 39.009],
            [-103.6665, 39.0093],
            [-103.6407, 39.2147],
            [-103.5473, 39.3302],
            [-103.5485, 39.5661],
            [-103.3781, 39.5662],
            [-103.3795, 39.1422],
            [-103.1551, 39.1419],
            [-103.163, 39.0376],
            [-103.1638, 38.7627],
            [-103.3863, 38.7924],
            [-103.386, 38.8944],
            [-103.4965, 38.8944],
            [-103.5357, 38.9516],
            [-103.7209, 38.9511],
            [-103.721, 39.009],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804740',
        NAME: 'Genoa-Hugo School District C-113',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2197780893,
        AWATER: 8851202,
        INTPTLAT: '+39.1207811',
        INTPTLON: '-103.4286835',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6969, 37.2159],
            [-107.6506, 37.1839],
            [-107.4821, 37.1838],
            [-107.443, 37.1413],
            [-107.3345, 37.1411],
            [-107.3338, 37],
            [-107.4209, 37],
            [-107.7669, 37],
            [-107.7531, 37.184],
            [-107.6969, 37.2159],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804770',
        NAME: 'Ignacio School District 11-JT',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 715018252,
        AWATER: 10500465,
        INTPTLAT: '+37.0924549',
        INTPTLON: '-107.5678312',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3979, 39.7471],
            [-105.3975, 39.8543],
            [-105.3978, 39.9129],
            [-105.0529, 39.9142],
            [-105.0531, 39.8854],
            [-105.0532, 39.7911],
            [-105.0533, 39.6678],
            [-105.0969, 39.6144],
            [-105.0534, 39.6214],
            [-105.0487, 39.5661],
            [-105.2339, 39.2469],
            [-105.3292, 39.1297],
            [-105.3304, 39.1297],
            [-105.3979, 39.1296],
            [-105.3981, 39.3158],
            [-105.3989, 39.5661],
            [-105.3979, 39.7471],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804800',
        NAME: 'Jefferson County School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1989125359,
        AWATER: 26094913,
        INTPTLAT: '+39.5795106',
        INTPTLON: '-105.2454623',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9446, 40.407],
            [-104.8131, 40.3842],
            [-104.763, 40.3489],
            [-104.9233, 40.24],
            [-104.9987, 40.2695],
            [-104.9446, 40.407],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804830',
        NAME: 'Johnstown-Milliken School District RE-5J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210109904,
        AWATER: 2063248,
        INTPTLAT: '+40.3297397',
        INTPTLON: '-104.9031168',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.4005, 40.7419],
              [-102.3912, 40.7419],
              [-102.4004, 40.7348],
              [-102.4005, 40.7419],
            ],
          ],
          [
            [
              [-102.43, 40.7783],
              [-102.2686, 40.7929],
              [-102.3449, 41.0022],
              [-102.2402, 41.0023],
              [-102.0517, 41.0024],
              [-102.0516, 40.9596],
              [-102.0514, 40.7496],
              [-102.3631, 40.7493],
              [-102.3822, 40.7639],
              [-102.3912, 40.7419],
              [-102.4006, 40.7493],
              [-102.41, 40.7492],
              [-102.43, 40.7783],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804860',
        NAME: 'Julesburg School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 636911327,
        AWATER: 36263,
        INTPTLAT: '+40.8732359',
        INTPTLON: '-102.1957082',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8329, 38.8669],
            [-103.7197, 38.8668],
            [-103.7209, 38.9511],
            [-103.5357, 38.9516],
            [-103.4965, 38.8944],
            [-103.386, 38.8944],
            [-103.3863, 38.7924],
            [-103.1638, 38.7627],
            [-103.1729, 38.6124],
            [-103.1729, 38.5253],
            [-103.5047, 38.5164],
            [-103.613, 38.5179],
            [-103.6135, 38.6092],
            [-103.7999, 38.6089],
            [-103.8003, 38.654],
            [-103.8311, 38.6928],
            [-103.8329, 38.8669],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804890',
        NAME: 'Karval School District RE-23',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2002031433,
        AWATER: 5928202,
        INTPTLAT: '+38.7165156',
        INTPTLON: '-103.5075664',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7539, 40.1745],
            [-104.6407, 40.161],
            [-104.6027, 40.2034],
            [-104.4924, 40.2619],
            [-104.3006, 40.2619],
            [-104.2638, 40.1747],
            [-104.1502, 40.1089],
            [-104.208, 40.0008],
            [-104.3768, 40.0007],
            [-104.3764, 39.9267],
            [-104.4894, 39.9266],
            [-104.4898, 40.0006],
            [-104.7537, 40.0222],
            [-104.716, 40.0586],
            [-104.7539, 40.1745],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804920',
        NAME: 'Keenesburg School District RE-3J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1238746907,
        AWATER: 5900555,
        INTPTLAT: '+40.0968494',
        INTPTLON: '-104.4477554',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6104, 40.3562],
            [-104.5732, 40.4642],
            [-104.527, 40.5153],
            [-104.3372, 40.5157],
            [-104.3, 40.4368],
            [-104.3006, 40.2619],
            [-104.4924, 40.2619],
            [-104.6027, 40.2034],
            [-104.6104, 40.3562],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804950',
        NAME: 'Platte Valley School District RE-7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 736244692,
        AWATER: 5234225,
        INTPTLAT: '+40.3692176',
        INTPTLON: '-104.4609799',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7326, 37.5092],
            [-103.7321, 37.6436],
            [-103.4043, 37.6436],
            [-103.0759, 37.6434],
            [-103.0861, 37.0003],
            [-103.4081, 36.9994],
            [-103.4729, 37.0323],
            [-103.4732, 37.1044],
            [-103.7332, 37.1177],
            [-103.7336, 37.2889],
            [-103.659, 37.3911],
            [-103.6252, 37.5077],
            [-103.7326, 37.5092],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '804980',
        NAME: 'Kim Reorganized School District 88',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3640066724,
        AWATER: 29631,
        INTPTLAT: '+37.3787624',
        INTPTLON: '-103.3875742',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.4574, 39.564],
            [-104.3987, 39.5639],
            [-104.2961, 39.5647],
            [-104.2971, 39.4331],
            [-104.2014, 39.4336],
            [-104.1833, 39.3739],
            [-104.0878, 39.3454],
            [-104.1795, 39.2157],
            [-104.2645, 39.2159],
            [-104.3477, 39.2232],
            [-104.4037, 39.2663],
            [-104.5533, 39.2743],
            [-104.5145, 39.4193],
            [-104.458, 39.4624],
            [-104.4574, 39.564],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805010',
        NAME: 'Kiowa School District C-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 868418710,
        AWATER: 81700,
        INTPTLAT: '+39.3696118',
        INTPTLON: '-104.3429014',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.1638, 38.7627],
            [-103.163, 39.0376],
            [-102.9773, 39.0393],
            [-102.6692, 39.0394],
            [-102.5447, 39.041],
            [-102.6192, 38.982],
            [-102.627, 38.6133],
            [-103.1729, 38.6124],
            [-103.1638, 38.7627],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805040',
        NAME: 'Kit Carson School District R-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2269125887,
        AWATER: 7879175,
        INTPTLAT: '+38.8380923',
        INTPTLON: '-102.8880029',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.6521, 40.4452],
            [-106.6123, 40.3794],
            [-106.4434, 40.3465],
            [-106.1403, 40.3301],
            [-106.1431, 40.043],
            [-105.9998, 39.9996],
            [-105.8876, 39.7966],
            [-105.9246, 39.699],
            [-106.0238, 39.6884],
            [-106.1315, 39.8442],
            [-106.2524, 39.7829],
            [-106.3895, 39.7824],
            [-106.4125, 39.8293],
            [-106.6291, 39.8288],
            [-106.637, 40.0454],
            [-106.6324, 40.3129],
            [-106.6521, 40.4452],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805070',
        NAME: 'West Grand School District 1-JT',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3025537365,
        AWATER: 30116361,
        INTPTLAT: '+40.0455381',
        INTPTLON: '-106.3470168',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.6784, 37.4036],
            [-106.3654, 37.4009],
            [-106.1124, 37.4011],
            [-106.1123, 37.3354],
            [-105.9838, 37.3418],
            [-105.8751, 37.384],
            [-105.9342, 37.2395],
            [-105.8177, 37.2022],
            [-105.8178, 37.1367],
            [-106.3641, 37.1413],
            [-106.365, 37.2279],
            [-106.6707, 37.2254],
            [-106.6784, 37.4036],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805100',
        NAME: 'North Conejos School District RE-1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1663670394,
        AWATER: 6888550,
        INTPTLAT: '+37.2696102',
        INTPTLON: '-106.2623176',
        ELSDLEA: '',
        UNSDLEA: '05100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.9496, 37.9101],
            [-103.8581, 37.9098],
            [-103.7116, 37.9096],
            [-103.5834, 37.8957],
            [-103.5648, 38.048],
            [-103.3987, 38.0847],
            [-103.4043, 37.6436],
            [-103.7321, 37.6436],
            [-104.0582, 37.644],
            [-104.0611, 37.7347],
            [-104.0619, 37.8227],
            [-103.9503, 37.8228],
            [-103.9496, 37.9101],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805130',
        NAME: 'East Otero School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1881034603,
        AWATER: 3375724,
        INTPTLAT: '+37.7150414',
        INTPTLON: '-103.7224451',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.2067, 37.5898],
            [-104.9784, 37.5761],
            [-104.925, 37.3962],
            [-105.029, 37.3395],
            [-105.1542, 37.2931],
            [-105.1652, 37.2931],
            [-105.1713, 37.5132],
            [-105.2067, 37.5898],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805160',
        NAME: 'La Veta School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579457297,
        AWATER: 596465,
        INTPTLAT: '+37.4723858',
        INTPTLON: '-105.0459253',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.4891, 39.2485],
            [-106.5027, 39.2981],
            [-106.4265, 39.3619],
            [-106.2832, 39.3505],
            [-106.2067, 39.3796],
            [-106.1355, 39.3795],
            [-106.184, 39.3094],
            [-106.1697, 39.0864],
            [-106.191, 39.0561],
            [-106.578, 39.0579],
            [-106.4891, 39.2485],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805190',
        NAME: 'Lake County School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 976206802,
        AWATER: 18111565,
        INTPTLAT: '+39.2053405',
        INTPTLON: '-106.3500790',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7417, 38.0966],
            [-102.6232, 38.1068],
            [-102.6682, 38.2673],
            [-102.4797, 38.2679],
            [-102.4687, 38.1441],
            [-102.4122, 38.1145],
            [-102.4194, 37.6439],
            [-102.7476, 37.6436],
            [-102.7417, 38.0966],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805220',
        NAME: 'Lamar School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1775343486,
        AWATER: 7471194,
        INTPTLAT: '+37.9306432',
        INTPTLON: '-102.5678570',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3987, 38.0847],
            [-103.3999, 38.2654],
            [-103.071, 38.2664],
            [-103.071, 38.088],
            [-103.0136, 38.0702],
            [-103.0213, 37.804],
            [-102.9662, 37.7312],
            [-102.9658, 37.6438],
            [-103.0759, 37.6434],
            [-103.4043, 37.6436],
            [-103.3987, 38.0847],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805250',
        NAME: 'Las Animas School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2171698985,
        AWATER: 51424936,
        INTPTLAT: '+37.8517653',
        INTPTLON: '-103.2817414',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8258, 39.3033],
            [-103.8257, 39.4342],
            [-103.715, 39.4337],
            [-103.7144, 39.5663],
            [-103.7071, 39.5663],
            [-103.5485, 39.5661],
            [-103.5473, 39.3302],
            [-103.6407, 39.2147],
            [-103.6665, 39.0093],
            [-103.721, 39.009],
            [-103.8302, 39.0401],
            [-103.8258, 39.3033],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805280',
        NAME: 'Limon School District RE-4J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1050789052,
        AWATER: 2237400,
        INTPTLAT: '+39.3077142',
        INTPTLON: '-103.6952985',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0534, 39.6214],
            [-105.0534, 39.6244],
            [-105.0533, 39.628],
            [-105.0535, 39.6295],
            [-105.0534, 39.6316],
            [-105.0276, 39.6282],
            [-105.0101, 39.6241],
            [-104.9786, 39.6241],
            [-104.9119, 39.566],
            [-105.0487, 39.5661],
            [-105.0534, 39.6214],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805310',
        NAME: 'Littleton School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70212246,
        AWATER: 3112552,
        INTPTLAT: '+39.5955539',
        INTPTLON: '-104.9895201',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0456, 40.4371],
            [-102.9422, 40.4379],
            [-102.78, 40.4384],
            [-102.7934, 40.2197],
            [-102.9952, 40.2951],
            [-103.0617, 40.2948],
            [-103.0456, 40.4371],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805340',
        NAME: 'Lone Star School District 101',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495732035,
        AWATER: 0,
        INTPTLAT: '+40.3501534',
        INTPTLON: '-102.9281146',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.6664, 40.1573],
            [-105.4463, 40.1743],
            [-105.4289, 40.2431],
            [-105.3373, 40.2473],
            [-105.3355, 40.349],
            [-105.2317, 40.3098],
            [-105.2148, 40.2464],
            [-104.9987, 40.2695],
            [-104.9233, 40.24],
            [-104.9047, 40.1456],
            [-104.9051, 40.0583],
            [-104.9708, 40.0003],
            [-105.0528, 40.0003],
            [-105.0934, 40.0657],
            [-105.2821, 40.087],
            [-105.357, 40.1214],
            [-105.6266, 40.1279],
            [-105.6664, 40.1573],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805370',
        NAME: 'St. Vrain Valley School District RE 1J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1017961067,
        AWATER: 21707441,
        INTPTLAT: '+40.1711571',
        INTPTLON: '-105.1758811',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9442, 40.457],
            [-104.9446, 40.407],
            [-104.9987, 40.2695],
            [-105.2148, 40.2464],
            [-105.2317, 40.3098],
            [-105.3355, 40.349],
            [-105.4005, 40.4222],
            [-105.4029, 40.5342],
            [-105.2291, 40.5953],
            [-105.1353, 40.4946],
            [-104.9442, 40.457],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805400',
        NAME: 'Thompson School District R-2J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 896568719,
        AWATER: 33600380,
        INTPTLAT: '+40.4028118',
        INTPTLON: '-105.1823528',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.444, 37.3937],
            [-108.3354, 37.4293],
            [-108.3349, 37.5039],
            [-108.177, 37.5042],
            [-108.177, 37.5471],
            [-108.0241, 37.5435],
            [-108.0331, 37.4649],
            [-108.1059, 37.3821],
            [-108.2048, 37.3441],
            [-108.2952, 37.215],
            [-108.4456, 37.2159],
            [-108.5445, 37.1565],
            [-108.5546, 37.283],
            [-108.4447, 37.2975],
            [-108.444, 37.3937],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805460',
        NAME: 'Mancos School District RE-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 919847910,
        AWATER: 1970733,
        INTPTLAT: '+37.3574741',
        INTPTLON: '-108.2925447',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0684, 38.8511],
            [-105.0289, 38.8689],
            [-105.0278, 39.0144],
            [-104.9357, 38.9835],
            [-104.9053, 38.9111],
            [-104.8866, 38.8243],
            [-105.0718, 38.8179],
            [-105.0684, 38.8511],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805490',
        NAME: 'Manitou Springs School District 14',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234029504,
        AWATER: 2240646,
        INTPTLAT: '+38.9008026',
        INTPTLON: '-104.9787095',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.9401, 38.1258],
            [-103.8571, 38.1701],
            [-103.8014, 38.1125],
            [-103.8574, 38.0762],
            [-103.8581, 37.9098],
            [-103.9496, 37.9101],
            [-103.9401, 38.1258],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805520',
        NAME: 'Manzanola School District 3J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243940419,
        AWATER: 2160981,
        INTPTLAT: '+38.0398888',
        INTPTLON: '-103.8874534',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9969, 39.8425],
            [-104.9402, 39.885],
            [-104.9666, 39.7938],
            [-104.9967, 39.7911],
            [-104.9969, 39.8425],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805550',
        NAME: 'Mapleton School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31393976,
        AWATER: 930453,
        INTPTLAT: '+39.8331301',
        INTPTLON: '-104.9669841',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.071, 38.2664],
            [-102.8345, 38.2665],
            [-102.8348, 38.1715],
            [-102.7417, 38.0966],
            [-102.7476, 37.6436],
            [-102.7842, 37.6436],
            [-102.9658, 37.6438],
            [-102.9662, 37.7312],
            [-103.0213, 37.804],
            [-103.0136, 38.0702],
            [-103.071, 38.088],
            [-103.071, 38.2664],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805580',
        NAME: 'McClave School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1648739428,
        AWATER: 21344858,
        INTPTLAT: '+37.9833553',
        INTPTLON: '-102.9035942',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.3268, 40.2219],
            [-107.4394, 40.2234],
            [-107.116, 40.2252],
            [-107.0377, 40.1643],
            [-107.0374, 40.0915],
            [-107.317, 40.0908],
            [-107.3188, 39.915],
            [-107.4317, 39.9177],
            [-107.4318, 39.8284],
            [-107.4924, 39.8283],
            [-107.9385, 39.825],
            [-107.9385, 39.6945],
            [-108.2179, 39.6939],
            [-108.4506, 39.6941],
            [-108.4479, 40.13],
            [-108.3462, 40.1294],
            [-108.3268, 40.2219],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805610',
        NAME: 'Meeker School District RE1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4999859368,
        AWATER: 4511753,
        INTPTLAT: '+39.9487163',
        INTPTLON: '-107.8686826',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5743, 40.7021],
            [-103.4663, 40.6923],
            [-103.4488, 40.5813],
            [-103.3332, 40.5949],
            [-103.2654, 40.5091],
            [-103.1609, 40.509],
            [-103.1604, 40.4367],
            [-103.2352, 40.3783],
            [-103.3569, 40.3496],
            [-103.5224, 40.4369],
            [-103.5818, 40.5234],
            [-103.5743, 40.7021],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805640',
        NAME: 'Buffalo School District RE-4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 693869563,
        AWATER: 10107006,
        INTPTLAT: '+40.5239285',
        INTPTLON: '-103.3883571',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.3144, 38.9112],
            [-104.1846, 38.9116],
            [-104.1459, 38.9844],
            [-103.9404, 38.9554],
            [-103.9405, 38.8682],
            [-103.8329, 38.8669],
            [-103.8311, 38.6928],
            [-103.8003, 38.654],
            [-103.908, 38.6945],
            [-104.2735, 38.7082],
            [-104.3137, 38.7813],
            [-104.3144, 38.9112],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805670',
        NAME: 'Miami-Yoder School District 60-JT',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1078492193,
        AWATER: 1250223,
        INTPTLAT: '+38.8264312',
        INTPTLON: '-104.0720267',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7161, 38.1853],
            [-105.5603, 37.9519],
            [-105.4732, 37.896],
            [-105.4289, 37.8517],
            [-105.4573, 37.7515],
            [-105.7095, 37.7514],
            [-105.8264, 37.8666],
            [-105.9285, 37.8649],
            [-105.9282, 37.9207],
            [-106.0533, 37.9223],
            [-106.0256, 37.9659],
            [-106.0507, 38.1014],
            [-105.8094, 38.0995],
            [-105.8086, 38.1863],
            [-105.7161, 38.1853],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805700',
        NAME: 'Moffat Consolidated School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1494706344,
        AWATER: 742371,
        INTPTLAT: '+37.9600195',
        INTPTLON: '-105.7544821',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0489, 40.6626],
            [-109.05, 41.0007],
            [-108.4887, 41.0001],
            [-107.9184, 41.002],
            [-107.3178, 41.0029],
            [-107.3163, 40.8595],
            [-107.3142, 40.6003],
            [-107.4288, 40.5422],
            [-107.4394, 40.2234],
            [-108.3268, 40.2219],
            [-109.0509, 40.2226],
            [-109.0489, 40.6626],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805730',
        NAME: 'Moffat County School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12284800386,
        AWATER: 19803413,
        INTPTLAT: '+40.6163576',
        INTPTLON: '-108.2110988',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2228, 37.6314],
            [-106.0393, 37.5702],
            [-106.0393, 37.4009],
            [-106.1124, 37.4011],
            [-106.3654, 37.4009],
            [-106.3646, 37.5014],
            [-106.2957, 37.5012],
            [-106.2228, 37.6314],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805760',
        NAME: 'Monte Vista School District C-8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 553452473,
        AWATER: 543954,
        INTPTLAT: '+37.4936342',
        INTPTLON: '-106.1893961',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.5006, 38.4468],
            [-107.3725, 38.4506],
            [-107.3746, 38.3623],
            [-107.4666, 38.3101],
            [-107.6171, 38.2727],
            [-107.8564, 38.2764],
            [-107.8561, 38.3322],
            [-108.1324, 38.3316],
            [-108.0866, 38.2552],
            [-107.9376, 38.219],
            [-107.9658, 38.1523],
            [-108.1161, 38.1523],
            [-108.2418, 38.1525],
            [-108.2382, 38.2241],
            [-108.2339, 38.3111],
            [-108.3439, 38.3133],
            [-108.3382, 38.4597],
            [-108.3791, 38.5],
            [-108.3787, 38.6681],
            [-107.8486, 38.6681],
            [-107.7958, 38.5985],
            [-107.6783, 38.5558],
            [-107.5006, 38.4468],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805790',
        NAME: 'Montrose County School District RE-1J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3050809332,
        AWATER: 3760634,
        INTPTLAT: '+38.4514457',
        INTPTLON: '-107.9799119',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0335, 39.1298],
            [-104.6629, 39.1295],
            [-104.6451, 39.1295],
            [-104.6074, 39.057],
            [-104.8494, 39.0566],
            [-104.9357, 38.9835],
            [-105.0278, 39.0144],
            [-105.0335, 39.1298],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805820',
        NAME: 'Lewis-Palmer School District 38',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332597397,
        AWATER: 597391,
        INTPTLAT: '+39.0861956',
        INTPTLON: '-104.8585366',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0601, 38.5],
            [-108.601, 38.5],
            [-108.3791, 38.5],
            [-108.3382, 38.4597],
            [-108.3439, 38.3133],
            [-108.2339, 38.3111],
            [-108.2382, 38.2241],
            [-108.4918, 38.2295],
            [-108.4917, 38.1532],
            [-108.9067, 38.1533],
            [-109.0426, 38.153],
            [-109.0602, 38.2755],
            [-109.0601, 38.5],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805850',
        NAME: 'West End School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2378084726,
        AWATER: 504738,
        INTPTLAT: '+38.3260080',
        INTPTLON: '-108.6757307',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.9067, 38.1533],
            [-108.4917, 38.1532],
            [-108.4918, 38.2295],
            [-108.2382, 38.2241],
            [-108.2418, 38.1525],
            [-108.1161, 38.1523],
            [-108.1171, 37.8535],
            [-108.2091, 37.8209],
            [-108.2565, 37.8946],
            [-108.7422, 37.8954],
            [-108.7586, 38.0379],
            [-108.8158, 38.0381],
            [-108.9067, 38.1533],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805880',
        NAME: 'Norwood School District R-2J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1991941044,
        AWATER: 3857456,
        INTPTLAT: '+38.0093735',
        INTPTLON: '-108.4273260',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.085, 40.4274],
            [-106.9905, 40.3541],
            [-106.8433, 40.3135],
            [-106.6324, 40.3129],
            [-106.637, 40.0454],
            [-106.7897, 40.0471],
            [-106.8241, 39.9195],
            [-107.034, 39.9189],
            [-107.0374, 40.0915],
            [-107.0377, 40.1643],
            [-107.116, 40.2252],
            [-107.085, 40.4274],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805910',
        NAME: 'South Routt School District RE-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1512828921,
        AWATER: 4617266,
        INTPTLAT: '+40.1580319',
        INTPTLON: '-106.8457800',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0617, 40.2948],
            [-102.9952, 40.2951],
            [-102.7934, 40.2197],
            [-102.803, 39.9602],
            [-103.086, 39.9606],
            [-103.0798, 40.2218],
            [-103.0617, 40.2948],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805940',
        NAME: 'Otis School District R-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 815305207,
        AWATER: 27995,
        INTPTLAT: '+40.0538218',
        INTPTLON: '-102.8921584',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.8019, 37.9869],
            [-107.7994, 38.1327],
            [-107.6661, 38.1418],
            [-107.6519, 38.0597],
            [-107.519, 38.0582],
            [-107.5689, 37.965],
            [-107.7383, 37.9054],
            [-107.8019, 37.9869],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '805970',
        NAME: 'Ouray School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391256723,
        AWATER: 304613,
        INTPTLAT: '+38.0192623',
        INTPTLON: '-107.7436964',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.6535, 41.0023],
            [-102.3449, 41.0022],
            [-102.2686, 40.7929],
            [-102.43, 40.7783],
            [-102.5357, 40.7495],
            [-102.6514, 40.793],
            [-102.6535, 41.0023],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806000',
        NAME: 'Platte Valley School District RE-3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740002410,
        AWATER: 3494483,
        INTPTLAT: '+40.8847454',
        INTPTLON: '-102.4903770',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5738, 41.0017],
            [-103.3062, 41.0019],
            [-102.8856, 41.0021],
            [-102.8853, 40.9226],
            [-103.0766, 40.9055],
            [-103.1148, 40.819],
            [-103.5261, 40.8195],
            [-103.5734, 40.834],
            [-103.5738, 41.0017],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806030',
        NAME: 'Plateau School District RE-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 985629763,
        AWATER: 166575,
        INTPTLAT: '+40.8754515',
        INTPTLON: '-103.2920182',
        ELSDLEA: '',
        UNSDLEA: '06030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5893, 39.1294],
            [-104.3863, 39.1582],
            [-104.417, 39.1112],
            [-104.3865, 38.9394],
            [-104.4789, 38.9396],
            [-104.5527, 38.9475],
            [-104.5893, 39.1294],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806060',
        NAME: 'Peyton School District 23-JT',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322902075,
        AWATER: 193370,
        INTPTLAT: '+39.0590669',
        INTPTLON: '-104.4869427',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0759, 37.6434],
            [-102.9658, 37.6438],
            [-102.7842, 37.6436],
            [-102.7782, 37.2793],
            [-102.8142, 37.1625],
            [-102.8864, 37.1488],
            [-102.8867, 36.9995],
            [-103.0022, 37.0001],
            [-103.0861, 37.0003],
            [-103.0759, 37.6434],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806090',
        NAME: 'Pritchett School District RE-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1700309086,
        AWATER: 216455,
        INTPTLAT: '+37.3564633',
        INTPTLON: '-102.9631276',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7362, 38.2853],
            [-104.6628, 38.3314],
            [-104.572, 38.3322],
            [-104.5638, 38.1984],
            [-104.7081, 38.1708],
            [-104.7362, 38.2853],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806120',
        NAME: 'Pueblo City School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230896558,
        AWATER: 6486911,
        INTPTLAT: '+38.2514996',
        INTPTLON: '-104.6435747',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7758, 38.5199],
            [-104.2755, 38.5211],
            [-104.2752, 38.4493],
            [-104.1666, 38.45],
            [-104.1295, 38.4425],
            [-104.1324, 38.2143],
            [-104.2233, 38.1259],
            [-104.2267, 37.9381],
            [-104.3844, 37.9362],
            [-104.3842, 37.8268],
            [-104.6464, 37.9005],
            [-105.0137, 37.8813],
            [-105.0499, 37.9155],
            [-105.0504, 38.0958],
            [-105.0492, 38.258],
            [-104.9404, 38.2583],
            [-104.9415, 38.5196],
            [-104.7758, 38.5199],
          ],
          [
            [-104.7362, 38.2853],
            [-104.7081, 38.1708],
            [-104.5638, 38.1984],
            [-104.572, 38.3322],
            [-104.6628, 38.3314],
            [-104.7362, 38.2853],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806150',
        NAME: 'Pueblo County School District 70',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4726049049,
        AWATER: 21016960,
        INTPTLAT: '+38.1625211',
        INTPTLON: '-104.5738459',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0509, 40.2226],
            [-108.3268, 40.2219],
            [-108.3462, 40.1294],
            [-108.4479, 40.13],
            [-108.4506, 39.6941],
            [-108.5546, 39.6502],
            [-109.0512, 39.6605],
            [-109.0509, 40.2226],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806180',
        NAME: 'Rangely School District RE-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3309111158,
        AWATER: 34258,
        INTPTLAT: '+39.9402205',
        INTPTLON: '-108.6653685',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6171, 38.2727],
            [-107.5686, 38.1473],
            [-107.519, 38.0582],
            [-107.6519, 38.0597],
            [-107.6661, 38.1418],
            [-107.7994, 38.1327],
            [-107.8019, 37.9869],
            [-107.9002, 38.0262],
            [-107.8915, 38.116],
            [-107.9658, 38.1523],
            [-107.9376, 38.219],
            [-108.0866, 38.2552],
            [-108.1324, 38.3316],
            [-107.8561, 38.3322],
            [-107.8564, 38.2764],
            [-107.6171, 38.2727],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806210',
        NAME: 'Ridgway School District R-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 886321081,
        AWATER: 1294930,
        INTPTLAT: '+38.2012215',
        INTPTLON: '-107.8202950',
        ELSDLEA: '',
        UNSDLEA: '06210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4924, 39.8283],
            [-107.4872, 39.5656],
            [-107.3804, 39.4657],
            [-107.4316, 39.3662],
            [-107.9155, 39.3662],
            [-107.9371, 39.4957],
            [-107.9385, 39.6945],
            [-107.9385, 39.825],
            [-107.4924, 39.8283],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806240',
        NAME: 'Garfield School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2083023133,
        AWATER: 6982289,
        INTPTLAT: '+39.6035624',
        INTPTLON: '-107.6914306',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8014, 38.1125],
            [-103.6647, 38.113],
            [-103.6654, 38.0616],
            [-103.7116, 37.9096],
            [-103.8581, 37.9098],
            [-103.8574, 38.0762],
            [-103.8014, 38.1125],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806270',
        NAME: 'Rocky Ford School District R-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344287930,
        AWATER: 4305765,
        INTPTLAT: '+38.0036020',
        INTPTLON: '-103.7588389',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.8714, 37.9419],
            [-106.7998, 38.0412],
            [-106.6714, 38.0876],
            [-106.5969, 38.1566],
            [-106.505, 38.3149],
            [-106.3334, 38.2927],
            [-106.2469, 38.4228],
            [-106.0765, 38.4237],
            [-106.0177, 38.4487],
            [-105.8835, 38.3282],
            [-105.7969, 38.265],
            [-105.7161, 38.1853],
            [-105.8086, 38.1863],
            [-105.8094, 38.0995],
            [-106.0507, 38.1014],
            [-106.0256, 37.9659],
            [-106.0533, 37.9223],
            [-106.4365, 37.9233],
            [-106.439, 37.7483],
            [-106.5864, 37.7478],
            [-106.5871, 37.8344],
            [-106.6938, 37.8352],
            [-106.8714, 37.9419],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806300',
        NAME: 'Mountain Valley School District RE-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3647874033,
        AWATER: 1133274,
        INTPTLAT: '+38.1324118',
        INTPTLON: '-106.2972506',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3859, 38.6267],
            [-106.1667, 38.6267],
            [-105.9699, 38.6944],
            [-105.8143, 38.6945],
            [-105.8091, 38.5171],
            [-105.881, 38.4084],
            [-105.8835, 38.3282],
            [-106.0177, 38.4487],
            [-106.0765, 38.4237],
            [-106.2469, 38.4228],
            [-106.3481, 38.5125],
            [-106.3859, 38.6267],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806330',
        NAME: 'Salida School District R-32',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1214619640,
        AWATER: 745004,
        INTPTLAT: '+38.5478390',
        INTPTLON: '-106.0518097',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7481, 37.1366],
            [-105.7223, 37.2943],
            [-105.1652, 37.2931],
            [-105.1542, 37.2931],
            [-105.155, 36.9953],
            [-105.2206, 36.9952],
            [-105.7185, 36.9958],
            [-105.7707, 37.0418],
            [-105.7481, 37.1366],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806360',
        NAME: 'Centennial School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1726940499,
        AWATER: 5062663,
        INTPTLAT: '+37.1368555',
        INTPTLON: '-105.4471718',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8751, 37.384],
            [-105.7433, 37.3568],
            [-105.7223, 37.2943],
            [-105.7481, 37.1366],
            [-105.8178, 37.1367],
            [-105.8177, 37.2022],
            [-105.9342, 37.2395],
            [-105.8751, 37.384],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806390',
        NAME: 'Sanford School District 6J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358571286,
        AWATER: 571049,
        INTPTLAT: '+37.2786043',
        INTPTLON: '-105.8081166',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0386, 37.712],
            [-106.0107, 37.8066],
            [-105.9285, 37.8649],
            [-105.8264, 37.8666],
            [-105.7095, 37.7514],
            [-105.4573, 37.7515],
            [-105.4855, 37.5779],
            [-105.5958, 37.5615],
            [-105.9744, 37.565],
            [-105.9746, 37.6687],
            [-106.0386, 37.712],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806420',
        NAME: 'Sangre de Cristo School District RE-22J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1196506522,
        AWATER: 626000,
        INTPTLAT: '+37.6754256',
        INTPTLON: '-105.7627124',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2319, 37.7261],
            [-106.0386, 37.712],
            [-105.9746, 37.6687],
            [-105.9744, 37.565],
            [-106.0393, 37.5702],
            [-106.2228, 37.6314],
            [-106.2319, 37.7261],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806450',
        NAME: 'Sargent School District RE-33J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303817725,
        AWATER: 103196,
        INTPTLAT: '+37.6510130',
        INTPTLON: '-106.0985565',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7203, 38.8174],
            [-104.6919, 38.8174],
            [-104.6087, 38.8246],
            [-104.6089, 38.7115],
            [-104.7755, 38.7372],
            [-104.7203, 38.8174],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806480',
        NAME: 'Widefield School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152468766,
        AWATER: 1286852,
        INTPTLAT: '+38.7561648',
        INTPTLON: '-104.6974328',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9677, 39.5682],
            [-102.8029, 39.5678],
            [-102.6674, 39.5039],
            [-102.6692, 39.0394],
            [-102.9773, 39.0393],
            [-102.9776, 39.1999],
            [-102.9506, 39.301],
            [-102.9677, 39.5682],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806510',
        NAME: 'Hi-Plains School District R-23',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1453078583,
        AWATER: 62555,
        INTPTLAT: '+39.2943894',
        INTPTLON: '-102.8203921',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0158, 39.6637],
            [-105.0107, 39.6605],
            [-105.0049, 39.6605],
            [-105.0101, 39.6241],
            [-105.0276, 39.6282],
            [-105.0158, 39.6637],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806540',
        NAME: 'Sheridan School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8431486,
        AWATER: 313177,
        INTPTLAT: '+39.6477575',
        INTPTLON: '-105.0174566',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.8608, 37.7755],
            [-107.7383, 37.9054],
            [-107.5689, 37.965],
            [-107.4822, 37.7684],
            [-107.4822, 37.643],
            [-107.4822, 37.6395],
            [-107.8039, 37.6402],
            [-107.9701, 37.6396],
            [-107.9689, 37.6965],
            [-107.873, 37.7224],
            [-107.8608, 37.7755],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806570',
        NAME: 'Silverton School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1003638261,
        AWATER: 2035929,
        INTPTLAT: '+37.7810745',
        INTPTLON: '-107.6702566',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.2645, 39.2159],
            [-104.1795, 39.2157],
            [-104.0878, 39.3454],
            [-104.0296, 39.2449],
            [-103.8997, 39.2463],
            [-103.8258, 39.3033],
            [-103.8302, 39.0401],
            [-103.721, 39.009],
            [-103.7209, 38.9511],
            [-103.7197, 38.8668],
            [-103.8329, 38.8669],
            [-103.9405, 38.8682],
            [-103.9404, 38.9554],
            [-104.1459, 38.9844],
            [-104.1444, 39.1002],
            [-104.2191, 39.0989],
            [-104.2645, 39.2159],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806600',
        NAME: 'Big Sandy School District 100J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1385889701,
        AWATER: 84865,
        INTPTLAT: '+39.0796323',
        INTPTLON: '-103.9454038',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7842, 37.6436],
            [-102.7476, 37.6436],
            [-102.4194, 37.6439],
            [-102.3831, 37.6441],
            [-102.3828, 37.4705],
            [-102.529, 37.4699],
            [-102.5438, 37.3231],
            [-102.4794, 37.178],
            [-102.561, 37.2068],
            [-102.8142, 37.1625],
            [-102.7782, 37.2793],
            [-102.7842, 37.6436],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806630',
        NAME: 'Springfield School District RE-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1473296526,
        AWATER: 5040504,
        INTPTLAT: '+37.4295502',
        INTPTLON: '-102.6539976',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.3178, 41.0029],
            [-107.1161, 41.0031],
            [-106.8578, 41.0027],
            [-106.8699, 40.9567],
            [-106.6369, 40.7895],
            [-106.7061, 40.6166],
            [-106.6521, 40.4452],
            [-106.6324, 40.3129],
            [-106.8433, 40.3135],
            [-106.9905, 40.3541],
            [-107.085, 40.4274],
            [-106.9977, 40.4559],
            [-106.9967, 40.5167],
            [-107.0834, 40.5449],
            [-107.0829, 40.8605],
            [-107.3163, 40.8595],
            [-107.3178, 41.0029],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806660',
        NAME: 'Steamboat Springs School District RE-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2620288899,
        AWATER: 9540068,
        INTPTLAT: '+40.6878542',
        INTPTLON: '-106.8629945',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5734, 40.834],
            [-103.5261, 40.8195],
            [-103.1148, 40.819],
            [-103.0766, 40.9055],
            [-102.8853, 40.9226],
            [-102.8856, 41.0021],
            [-102.7928, 41.0023],
            [-102.6535, 41.0023],
            [-102.6514, 40.793],
            [-102.7292, 40.7927],
            [-102.8833, 40.8058],
            [-102.9597, 40.7025],
            [-103.106, 40.6852],
            [-103.106, 40.6123],
            [-102.9809, 40.6128],
            [-102.9422, 40.4379],
            [-103.0456, 40.4371],
            [-103.1604, 40.4367],
            [-103.1609, 40.509],
            [-103.2654, 40.5091],
            [-103.3332, 40.5949],
            [-103.4488, 40.5813],
            [-103.4663, 40.6923],
            [-103.5743, 40.7021],
            [-103.5734, 40.834],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806690',
        NAME: 'Valley School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2171465957,
        AWATER: 13950761,
        INTPTLAT: '+40.7307860',
        INTPTLON: '-103.1122880',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0289, 40.6993],
            [-103.9164, 40.6997],
            [-103.9148, 41.0017],
            [-103.5927, 41.0017],
            [-103.5738, 41.0017],
            [-103.5734, 40.834],
            [-103.5743, 40.7021],
            [-103.5818, 40.5234],
            [-103.6391, 40.5227],
            [-103.9179, 40.5239],
            [-104.0514, 40.5244],
            [-104.0289, 40.6993],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806720',
        NAME: 'Prairie School District RE-11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1721349435,
        AWATER: 132425,
        INTPTLAT: '+40.7954530',
        INTPTLON: '-103.7745900',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.3764, 39.9267],
            [-104.3768, 40.0007],
            [-104.208, 40.0008],
            [-104.188, 39.9424],
            [-104.2259, 39.8551],
            [-104.2279, 39.739],
            [-104.2838, 39.7387],
            [-104.285, 39.5647],
            [-104.2961, 39.5647],
            [-104.3987, 39.5639],
            [-104.3778, 39.6924],
            [-104.3764, 39.9267],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806750',
        NAME: 'Strasburg School District 31J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 587815706,
        AWATER: 2945458,
        INTPTLAT: '+39.8043495',
        INTPTLON: '-104.3051552',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.6674, 39.5039],
            [-102.4814, 39.5659],
            [-102.4837, 39.1708],
            [-102.4659, 39.0427],
            [-102.5447, 39.041],
            [-102.6692, 39.0394],
            [-102.6674, 39.5039],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806780',
        NAME: 'Stratton School District R-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 877947079,
        AWATER: 226321,
        INTPTLAT: '+39.2836430',
        INTPTLON: '-102.5678827',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3895, 39.7824],
            [-106.2524, 39.7829],
            [-106.1315, 39.8442],
            [-106.0238, 39.6884],
            [-105.9246, 39.699],
            [-105.7827, 39.629],
            [-105.8297, 39.5649],
            [-105.8989, 39.4723],
            [-106.0561, 39.3584],
            [-106.1355, 39.3795],
            [-106.2067, 39.3796],
            [-106.2483, 39.5443],
            [-106.1761, 39.6142],
            [-106.3895, 39.7824],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806810',
        NAME: 'Summit School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1332646560,
        AWATER: 19021697,
        INTPTLAT: '+39.5913789',
        INTPTLON: '-106.0810912',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7116, 37.9096],
            [-103.6654, 38.0616],
            [-103.5648, 38.048],
            [-103.5834, 37.8957],
            [-103.7116, 37.9096],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806840',
        NAME: 'Swink School District 33',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164138194,
        AWATER: 741613,
        INTPTLAT: '+37.9638977',
        INTPTLON: '-103.6339068',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.1161, 38.1523],
            [-107.9658, 38.1523],
            [-107.8915, 38.116],
            [-107.9002, 38.0262],
            [-107.8019, 37.9869],
            [-107.7383, 37.9054],
            [-107.8608, 37.7755],
            [-107.9892, 37.8566],
            [-108.1171, 37.8535],
            [-108.1161, 38.1523],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806870',
        NAME: 'Telluride School District R-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 895177207,
        AWATER: 1386277,
        INTPTLAT: '+37.9727650',
        INTPTLON: '-107.9481907',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0528, 40.0003],
            [-104.9708, 40.0003],
            [-104.9593, 40.0003],
            [-104.9125, 39.9072],
            [-104.9402, 39.885],
            [-104.9969, 39.8425],
            [-105.0531, 39.8854],
            [-105.0529, 39.9142],
            [-105.0528, 40.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806900',
        NAME: 'Northglenn-Thornton School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156506120,
        AWATER: 2409169,
        INTPTLAT: '+39.9247561',
        INTPTLON: '-104.9911464',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4546, 38.6148],
            [-102.0451, 38.6152],
            [-102.0445, 38.2687],
            [-102.2567, 38.2681],
            [-102.471, 38.2678],
            [-102.4546, 38.6148],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806930',
        NAME: 'Plainview School District RE-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1372522110,
        AWATER: 2708561,
        INTPTLAT: '+38.4361952',
        INTPTLON: '-102.2460083',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5182, 37.3243],
            [-104.4851, 37.28],
            [-104.4685, 36.9938],
            [-104.6314, 36.9936],
            [-104.6309, 37.1197],
            [-104.668, 37.1903],
            [-104.8298, 37.2509],
            [-104.6101, 37.2639],
            [-104.5182, 37.3243],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806960',
        NAME: 'Trinidad School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 633169959,
        AWATER: 5308076,
        INTPTLAT: '+37.1637911',
        INTPTLON: '-104.6035462',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3828, 37.4705],
            [-102.3291, 37.3245],
            [-102.3798, 37.3074],
            [-102.3617, 37.1773],
            [-102.4794, 37.178],
            [-102.5438, 37.3231],
            [-102.529, 37.4699],
            [-102.3828, 37.4705],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '806990',
        NAME: 'Vilas School District RE-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466580637,
        AWATER: 0,
        INTPTLAT: '+37.3367461',
        INTPTLON: '-102.4333782',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.8578, 41.0027],
            [-106.3212, 40.9991],
            [-106.1906, 40.9976],
            [-106.1853, 40.934],
            [-106.0538, 40.8127],
            [-105.9051, 40.5354],
            [-105.8549, 40.4863],
            [-105.906, 40.3997],
            [-106.1403, 40.3301],
            [-106.4434, 40.3465],
            [-106.6123, 40.3794],
            [-106.6521, 40.4452],
            [-106.7061, 40.6166],
            [-106.6369, 40.7895],
            [-106.8699, 40.9567],
            [-106.8578, 41.0027],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807050',
        NAME: 'North Park School District R-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4179536193,
        AWATER: 18750017,
        INTPTLAT: '+40.6634319',
        INTPTLON: '-106.3292476',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4573, 37.7515],
            [-105.4289, 37.8517],
            [-105.4732, 37.896],
            [-105.4136, 37.8905],
            [-105.2386, 37.9628],
            [-105.1687, 38.0189],
            [-105.0499, 37.9155],
            [-105.0137, 37.8813],
            [-104.6464, 37.9005],
            [-104.3842, 37.8268],
            [-104.3511, 37.8175],
            [-104.4549, 37.7408],
            [-104.5487, 37.5747],
            [-104.7484, 37.4074],
            [-104.925, 37.3962],
            [-104.9784, 37.5761],
            [-105.2067, 37.5898],
            [-105.2956, 37.6544],
            [-105.4855, 37.5779],
            [-105.4573, 37.7515],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807080',
        NAME: 'Huerfano School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3541350701,
        AWATER: 5195636,
        INTPTLAT: '+37.7168395',
        INTPTLON: '-104.9480641',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3831, 37.6441],
            [-102.2194, 37.6442],
            [-102.0416, 37.6443],
            [-102.0416, 37.3892],
            [-102.0421, 36.993],
            [-102.1365, 36.9934],
            [-102.3252, 36.9943],
            [-102.3617, 37.1773],
            [-102.3798, 37.3074],
            [-102.3291, 37.3245],
            [-102.3828, 37.4705],
            [-102.3831, 37.6441],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807110',
        NAME: 'Walsh School District RE-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1986855945,
        AWATER: 869101,
        INTPTLAT: '+37.3318054',
        INTPTLON: '-102.1743828',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.1467, 40.4378],
            [-104.1477, 40.5171],
            [-104.0514, 40.5244],
            [-103.9179, 40.5239],
            [-103.924, 40.2759],
            [-103.9798, 40.2765],
            [-104.0839, 40.3344],
            [-104.1467, 40.4378],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807140',
        NAME: 'Weldon Valley School District RE-20J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477925477,
        AWATER: 14186573,
        INTPTLAT: '+40.4322461',
        INTPTLON: '-104.0330005',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7969, 38.265],
            [-105.3689, 38.2594],
            [-105.2163, 38.2586],
            [-105.1509, 38.2578],
            [-105.1554, 38.1221],
            [-105.0504, 38.0958],
            [-105.0499, 37.9155],
            [-105.1687, 38.0189],
            [-105.2386, 37.9628],
            [-105.4136, 37.8905],
            [-105.4732, 37.896],
            [-105.5603, 37.9519],
            [-105.7161, 38.1853],
            [-105.7969, 38.265],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807200',
        NAME: 'Custer County School District C-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1753494167,
        AWATER: 3258533,
        INTPTLAT: '+38.1006637',
        INTPTLON: '-105.4101323',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0531, 39.8854],
            [-104.9969, 39.8425],
            [-104.9967, 39.7911],
            [-105.0532, 39.7911],
            [-105.0531, 39.8854],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807230',
        NAME: 'Westminster School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37187500,
        AWATER: 1388761,
        INTPTLAT: '+39.8327009',
        INTPTLON: '-105.0273739',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.1542, 37.2931],
            [-105.029, 37.3395],
            [-105.0294, 37.2508],
            [-104.8298, 37.2509],
            [-104.668, 37.1903],
            [-104.6309, 37.1197],
            [-104.6314, 36.9936],
            [-104.7559, 36.9935],
            [-105.155, 36.9953],
            [-105.1542, 37.2931],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807260',
        NAME: 'Primero Reorganized School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1298060128,
        AWATER: 996855,
        INTPTLAT: '+37.1079253',
        INTPTLON: '-104.9553433',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.3, 40.4368],
            [-104.1467, 40.4378],
            [-104.0839, 40.3344],
            [-103.9798, 40.2765],
            [-103.9809, 40.0011],
            [-104.0751, 39.9427],
            [-104.188, 39.9424],
            [-104.208, 40.0008],
            [-104.1502, 40.1089],
            [-104.2638, 40.1747],
            [-104.3006, 40.2619],
            [-104.3, 40.4368],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807290',
        NAME: 'Wiggins School District RE-50J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 894720807,
        AWATER: 38765764,
        INTPTLAT: '+40.1949130',
        INTPTLON: '-104.1297177',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8345, 38.2665],
            [-102.6682, 38.2673],
            [-102.6232, 38.1068],
            [-102.7417, 38.0966],
            [-102.8348, 38.1715],
            [-102.8345, 38.2665],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807320',
        NAME: 'Wiley School District RE-13-JT',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244902614,
        AWATER: 727685,
        INTPTLAT: '+38.1944985',
        INTPTLON: '-102.7328577',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9442, 40.457],
            [-104.944, 40.6168],
            [-104.8318, 40.6174],
            [-104.8222, 40.5594],
            [-104.7923, 40.4483],
            [-104.8131, 40.3842],
            [-104.9446, 40.407],
            [-104.9442, 40.457],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807350',
        NAME: 'Windsor School District RE-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257016055,
        AWATER: 7767357,
        INTPTLAT: '+40.5003199',
        INTPTLON: '-104.8780189',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3304, 39.1297],
            [-105.3292, 39.1297],
            [-105.0335, 39.1298],
            [-105.0278, 39.0144],
            [-105.0289, 38.8689],
            [-105.0684, 38.8511],
            [-105.2185, 38.851],
            [-105.3284, 38.8928],
            [-105.3304, 39.1297],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807380',
        NAME: 'Woodland Park School District RE-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 750878419,
        AWATER: 1938007,
        INTPTLAT: '+38.9934585',
        INTPTLON: '-105.1912155',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7065, 39.769],
            [-103.7057, 40.0014],
            [-103.698, 40.0013],
            [-103.472, 40.0015],
            [-103.3651, 39.957],
            [-103.3661, 39.8705],
            [-103.2934, 39.8492],
            [-103.2556, 39.7844],
            [-103.2746, 39.5665],
            [-103.3781, 39.5662],
            [-103.5485, 39.5661],
            [-103.7071, 39.5663],
            [-103.7065, 39.769],
          ],
        ],
      },
      properties: {
        STATEFP: '08',
        SCSDLEA: '',
        GEOID: '807410',
        NAME: 'Woodlin School District R-104',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1626120915,
        AWATER: 3212093,
        INTPTLAT: '+39.7640174',
        INTPTLON: '-103.5019588',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
  ],
};

/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showVisualizationSnackbar } from '../../actions/visualization.action';
import types from '../../types';
import CardAuthor from './CardAuthor';
import CardEditableTitle from './CardEditableTitle';
import CardImage from './CardImage';
import CardSnackbar from './CardSnackbar';
import Cardtitle from './Cardtitle';
import SurveyActions from './SurveyActions';
import VisualizationActions from './VisualizationActions';

import './style.css';

const cn = require('classnames');

const Card = ({
  data,
  id,
  isSurvey,
  isChild,
  isVisualization,
  organization,
  hasChildren,
  parentId,
}) => {
  const {
    author,
    createdAt,
    isPublished,
    snackBar = null,
    title,
    imageSrc,
    importedAt,
    spinner,
    updatedAt,
    exactNSize,
    minRespondents,
  } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    if (importedAt) {
      const now = moment.utc();
      const imported = moment(importedAt);
      const diff = now.diff(imported, 'minutes');
      if (diff < 2) {
        dispatch({ type: types.reducerTypes.SHOW_SURVEY_SNACKBAR, id, snackBarType: 'newSurvey' });
      }
    }

    if (updatedAt) {
      const now = moment.utc();
      const imported = moment(updatedAt);
      const diff = now.diff(imported, 'seconds');
      if (diff < 30) {
        dispatch(showVisualizationSnackbar(id, 'newVisualization'));
      }
    }
  }, []);

  return (
    <div
      className={cn('Card', {
        isChild,
        isPublished,
      })}
      id={id}
    >
      <div
        className={cn('Card-container', {
          isVisualization,
          isSurvey,
          isChild,
        })}
      >
        <div className="card-labels-container">
          {isSurvey && (
            <div
              className={cn('Card-label label-publish', {
                isPublished,
                isNotPublished: !isPublished,
              })}
            >
              {isPublished && <span>Published</span>}
              {!isPublished && <span>Not published</span>}
            </div>
          )}

          {isSurvey && isChild && <div className="Card-label label-draft"> Draft </div>}
          {isVisualization && isChild && <div className="Card-label label-copy"> Copy </div>}
          {organization && !isChild && (
            <div className="Card-label label-org"> {organization.name} </div>
          )}
        </div>
        {snackBar && (
          <CardSnackbar
            snackBar={snackBar}
            cardId={id}
            importedAt={importedAt}
            isVisualization={isVisualization}
          />
        )}

        <CardImage isSurvey={isSurvey} isVisualization={isVisualization} imageSrc={imageSrc} />

        {isSurvey && <CardEditableTitle data={data} id={id} />}
        {isVisualization && <Cardtitle title={title} />}

        <CardAuthor author={author} createdAt={createdAt} />
      </div>

      {isSurvey && (
        <SurveyActions
          id={id}
          isPublished={isPublished}
          isDraft={isChild}
          spinner={spinner}
          isCustomNSize={exactNSize}
          minRespondents={minRespondents}
          hasChildren={hasChildren}
          parentId={parentId}
        />
      )}

      {isVisualization && <VisualizationActions isCopy={isChild} id={id} spinner={spinner} />}
    </div>
  );
};

Card.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.string,
    createdAt: PropTypes.string,
    isPublished: PropTypes.bool,
    title: PropTypes.string,
    snackBar: PropTypes.string,
    imageSrc: PropTypes.string,
    importedAt: PropTypes.string,
    spinner: PropTypes.shape({
      spinner: PropTypes.string,
    }),
    updatedAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    exactNSize: PropTypes.bool,
    minRespondents: PropTypes.string,
  }).isRequired,
  organization: PropTypes.any,
  id: PropTypes.number.isRequired,
  isSurvey: PropTypes.bool.isRequired,
  isVisualization: PropTypes.bool.isRequired,
  isChild: PropTypes.bool,
  hasChildren: PropTypes.bool.isRequired,
  parentId: PropTypes.number,
};

Card.defaultProps = {
  isChild: false,
  organization: undefined,
  parentId: 0,
};

export default Card;

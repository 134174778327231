import React from 'react';
import { useDispatch } from 'react-redux';
import types from '../../../types';
import FileWizardForm from '../FileWizardForm';

const FileWizardStepper = () => {
  const dispatch = useDispatch();
  const nextStep = () => {
    dispatch({ type: types.reducerTypes.SUM_STEP_FILEWIZARD });
  };

  const prevStep = () => {
    dispatch({ type: types.reducerTypes.DEC_STEP_FILEWIZARD });
  };
  return (
    <div className="FileWizardStepper">
      <FileWizardForm
        nextStep={nextStep}
        prevStep={prevStep}
      />
    </div>
  );
};

export default FileWizardStepper;

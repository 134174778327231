import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { Container, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { feedbackApi } from '../../api/users';

import './styles.css';

const FeedbackPage = () => {
  const jwt = useSelector((state) => state.session.jwt);
  const [validated, setValidated] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const history = useHistory();

  const itemArray = [
    { value: '1', text: 'Not at all likely' },
    { value: '2', text: '' },
    { value: '3', text: '' },
    { value: '4', text: '' },
    { value: '5', text: 'Neutral' },
    { value: '6', text: '' },
    { value: '7', text: '' },
    { value: '8', text: '' },
    { value: '9', text: '' },
    { value: '10', text: 'Extremely likely' },
  ];

  const validateAndSend = async (payload) => {
    await feedbackApi(jwt, payload);
    history.push('/');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      const { elements } = form;
      const payload = {};
      Object.keys(elements).forEach((elKey) => {
        const el = elements[elKey];
        if (el.name && el.value) {
          const key = el.name.replace(/[^a-zA-Z0-9]/g, '');
          if (el.type === 'radio' && el.checked) {
            payload[key] = el.value;
          } else if (el.type !== 'radio') {
            payload[key] = el.value;
          }
        }
      });

      validateAndSend(payload);
    }

    setValidated(true);
  };

  return (
    <Container fluid className="p-0" key={shortid.generate()}>
      <Container>
        <div className="my-12 text-left feedback-title mb-5 mt-3">
          <h2>Feedback</h2>
        </div>
        <div className="my-12 text-left feedback-title mb-4">
          We would love to hear your feedback. Send us a note below.
        </div>
        <Form
          noValidate
          validated={validated}
          className="col-12 trans-form"
          onSubmit={handleSubmit}
        >
          <div className="my-12">
            <Form.Control
              as="textarea"
              name="feedbackText"
              rows="4"
              cols="30"
              placeholder="Enter your feedback here..."
            />
          </div>
          <div className="my-12 text-left mt-4">
            On a scale of 1 to 10, how likely are you to recommend Bento to a colleague that uses
            this data?
          </div>
          <Row md="12" className="radio-row mt-3">
            <Col md="11">
              <Row>
                {itemArray.map((item) => (
                  <Col key={item.value} className="d-flex flex-column radio-col">
                    <Form.Label className="radio-details pb-0">{item.value}</Form.Label>
                    <Form.Check
                      type="radio"
                      required
                      id={`radio-${item.value}`}
                      name="feedbackValue"
                      value={item.value}
                      className="radio-details"
                    />
                    <InputGroup.Text className="radio-details pt-0">{item.text}</InputGroup.Text>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md="1" className="d-flex justify-content-center align-items-center radio-col">
              <Button
                className="btn btn-custom dark font-weight-bold px-5"
                variant="primary"
                type="submit"
              >
                Send
              </Button>
            </Col>
          </Row>
          {!isFormValid && <p className="text-danger d-block">Please select a feedback value</p>}
        </Form>
      </Container>
    </Container>
  );
};
export default React.memo(FeedbackPage);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import {
  Alert,
  Container,
  Col,
  Row,
  Spinner,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import {
  faExclamationTriangle,
  faEyeSlash,
  faChartLine,
  faExternalLinkAlt,
  faEye,
  faFileAlt,
  faFilter,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { regroupForColumnDashboard, setLocationData } from '../../../helpers/charts';
import { HIGHCHARTS_TYPES } from '../../../constants/charts';

Highcharts.SVGRenderer.prototype.symbols.avg_line = (x, y, w, h) => {
  // Make the line longer on each side
  //   since the default viewbox isn't as wide as the column
  const pad = 16;

  // M: [M]ove the point to the far left side, half of the height
  // L: Draw a [L]ine from the point to the far right side
  // z: Stop the path
  return ['M', x - pad, y + h / 2, 'L', x + pad + w, y + h / 2, 'z'];
};

const GraduationChart = forwardRef(
  (
    {
      componentConfig,
      visualizationDetails,
      handleHideChart,
      menuOptions,
      handleUpdateDataSet,
      onAddToReport,
      onExportChart,
      readOnly,
    },
    chartRef
  ) => {
    const numFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    });
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);
    const visualizationComponent = useMemo(
      () => visualizationDetails.components.find((c) => c.id === componentConfig.componentId),
      [visualizationDetails.components, componentConfig.componentId]
    );

    const onDatasetChange = (dataSetLvl1, dataSetLvl2, dataSetLvl3, dataSetLvl4) => {
      const newFilters = [];
      if (dataSetLvl1) {
        newFilters.push({ ...dataSetLvl1, children: [] });
      }
      if (dataSetLvl2) {
        newFilters.push({ ...dataSetLvl2, children: [] });
      }
      if (dataSetLvl3) {
        newFilters.push({ ...dataSetLvl3, children: [] });
      }
      if (dataSetLvl4) {
        newFilters.push({ ...dataSetLvl4, children: [] });
      }
      const newVisualizationComponent = {
        ...visualizationComponent,
      };
      newVisualizationComponent.datasetFilter.filter.constraints[0].value = newFilters;
      setLoadingChart(true);
      handleUpdateDataSet(newVisualizationComponent);
    };

    const getDisplayValue = (value) => {
      // , isPercentage) => {
      if (value === null || value === undefined) {
        return null;
      }
      // strip decimals for percentage values, leave them otherwise
      return Number.isNaN(Number(value)) ? value : numFormatter.format(value);
    };

    function legendFormatter() {
      return this.name;
    }

    function seriesAuxFormatter() {
      if (this.point.not_available) {
        return 'NA';
      }
      return '';
    }

    function yAxisFormatter() {
      const locationData = setLocationData(
        visualizationDetails,
        visualizationComponent.viewMode || ''
      );
      const parseData = regroupForColumnDashboard(
        componentConfig?.queryResult,
        locationData,
        visualizationDetails?.scheme?.index
      );
      const valuePrefix = parseData[10];
      const valueSuffix = parseData[11];
      return `${valuePrefix}${getDisplayValue(this.value)}${valueSuffix}`;
    }

    function seriesFormatter(format) {
      let data_label = '';
      if (this.point.not_available) {
        data_label = 'NA';
        format.color = '#404040';
        format.y = null;
      } else if (this.point.uncertainty > 0) {
        data_label = '*';
      }

      return data_label;
    }

    function tooltipAuxFormatter() {
      let text_color = null;
      let data_label = '';

      if (this.point.y < 0) {
        text_color = '#ac193c';
      } else if (this.point.y > 0) {
        text_color = '#6f9c2c';
        data_label = '+';
      } else {
        text_color = '#cbd0d5';
      }

      return `<div style="opacity: 0.99;">
      <p style="font-size: 14px; font-weight: heavy; text-align: center; padding: 0px 0px 0px 0px;">
      ${this.point.name}
      </p><hr style="margin: 0px 0 0px 0;" width="100%" color="
      ${this.point.color}
      ">
      <table border="0" cellspacing="0" cellpadding="0" align="center">
      <tr>
      <td style="font-size:14px; color:
      ${text_color}
      ;">
      ${data_label}
      ${this.point.label}
      </td>
      </tr>
      </div>`;
    }
    function tooltipFormatter() {
      const locationData = setLocationData(
        visualizationDetails,
        visualizationDetails.components[0].viewMode || ''
      );
      let benchmark_label = 'the US';
      let benchmark_value = this.point.nat_av;
      const parseData = regroupForColumnDashboard(
        componentConfig?.queryResult,
        locationData,
        visualizationDetails?.scheme?.index
      );
      const valuePrefix = parseData[10];
      const valueSuffix = parseData[11];

      if (locationData.abbr.length > 2 && locationData.map_level === 'district') {
        benchmark_label = 'State';
        benchmark_value = this.point.state_av;
      }

      if (this.series.name === 'National Average' || this.series.name === 'State Average') {
        return `<div style="opacity: 0.99; padding: 0px 0px 0px 0px;">
        <p style=text-align: center; padding: 0px 0px 0px 0px; font-size: 14px; font-weight: heavy; line-height: 0.8; >
        ${this.point.name} ${this.series.name}</p><hr style="margin: 0px 0 0px 0;" width="100%" color="${this.point.color}"><p style="text-align: center; font-size: 14px; padding: 0px 0px 0px 0px;">${this.point.label}</p></div>`;
      }

      let diff = this.point.y - benchmark_value;
      let abs_diff = Math.abs(diff);
      let display_text = null;
      let text_color = null;

      if (this.point.uncertainty > 0 || benchmark_value == null) {
        display_text = 'than';
        diff = 'NA';
        text_color = '#cbd0d5';
        abs_diff = 'NA';
      } else {
        if (diff < 0) {
          display_text = 'less than';
          text_color = '#ac193c';
        } else {
          display_text = 'greater than';
          text_color = '#6f9c2c';
        }

        // add prefix/suffix to diff
        abs_diff = valuePrefix + abs_diff + valueSuffix;
      }

      return `<div style="opacity: 0.99; padding: 0px 0px 0px 0px;"><p style="text-align: center; padding: 0px 0px 0px 0px; font-size: 14px; font-weight: heavy; line-height: 0.8;">
      ${this.point.name}
      </p><hr style="margin: 0px 0 2px 0;" width="100%" color="
      ${this.point.color}
      ">
      <table border="0" cellspacing="0" cellpadding="3" align="center">
      <tr>
      <td style="padding: 0px 8px; font-size: 14px; text-align: center;  line-height: 0.8; "></td>
      <td style="padding: 0px 8px; border: solid 0 #c5c5c5; border-left-width: 1px; font-size: 14px; text-align: center;  line-height: 0.8; color:
      ${text_color}
      ;">
      ${
        Number.isNaN(Number(abs_diff)) ? abs_diff : numFormatter.format(abs_diff).replace('.00', '')
      }
      </td>
      </tr>
      <tr>
      <td style="padding: 0px 8px; font-size: 14px; text-align: center;  line-height: 0.8; ">
      ${
        Number.isNaN(Number(this.point.label))
          ? this.point.label
          : numFormatter.format(this.point.label).replace('.00', '')
      }
      </td>
      <td style="padding: 0px 8px; border: solid 0 #c5c5c5; border-left-width: 1px; font-size: 14px; text-align: center; white-space: nowrap; line-height: 0.8; color:
      ${text_color}
      ;">
      ${display_text}
      </td>
      </tr>
      <tr>
      <td style="padding: 0px 8px; font-size: 14px; text-align: center; line-height: 0.8; "></td>
      <td style="padding: 0px 8px; border: solid 0 #c5c5c5; border-left-width: 1px; font-size: 14px; text-align: center; white-space: nowrap; line-height: 0.8; color:
      ${text_color}
      ;">
      ${benchmark_label}
      </td>
      </tr>
      </table>
      </div>`;
    }

    const buildChart = (parseData, noAuxColumn, customHeight = 400) => {
      let plot_options;
      let tooltip;
      let current_series;
      let other_options;
      const allowStateAverages = visualizationDetails.state && visualizationDetails.state.district;

      const valuePrefix = parseData[10];
      const valueSuffix = parseData[11];

      if (noAuxColumn) {
        current_series = parseData[0];

        if (visualizationComponent.benchmarks !== null) {
          if (visualizationComponent.benchmarks.national) {
            current_series = current_series.concat(parseData[1]);
          }

          if (allowStateAverages && visualizationComponent.benchmarks.state) {
            current_series = current_series.concat(parseData[2]);
          }
        }

        tooltip = {
          useHTML: true,
          backgroundColor: 'rgba(255,255,255, 0.98)',
          padding: 5,
          formatter: tooltipFormatter,
        };

        plot_options = {
          column: {
            events: {
              legendItemClick: () => {
                return false;
              },
            },
            borderWidth: 0,
            minPointLength: 3,
          },
          scatter: {
            marker: {
              enabled: true,
            },
            events: {
              legendItemClick: () => {
                return false;
              },
            },
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              align: 'center',
              color: '#ac193c',
              shadow: false,
              y: -15,
              style: {
                fontSize: '14px',
                color: '#404040',
                textShadow: '0px',
                textOutline: 'false',
              },
              formatter: seriesFormatter,
            },
          },
        };

        other_options = {
          chart: {
            type: HIGHCHARTS_TYPES.COLUMN,
            marginTop: 50,
            marginBottom: 60,
            marginLeft: 90,
            paddingBottom: 20,
            height: customHeight,
          },
          title: {
            text: visualizationComponent.datasetFilter.description,
          },
          xAxis: {
            categories: parseData[3],
            labels: {
              style: {
                fontSize: '14px',
                color: '#808080',
                textShadow: '0px',
                textOutline: 'false',
              },
            },
          },
          yAxis: {
            title: {
              text: null,
            },
            max: parseData[7],
            min: parseData[6],
            // minTickInterval: 100, // make sure the 100 tick appears in the y axis
          },
        };

        // If this is the auxiliary column chart
      } else {
        current_series = parseData[5];
        tooltip = {
          backgroundColor: 'rgba(255,255,255, 0.98)',
          useHTML: true,
          padding: 5,
          formatter: tooltipAuxFormatter,
        };
        plot_options = {
          column: {
            events: {
              legendItemClick: () => {
                return false;
              },
            },
            borderWidth: 0,
            minPointLength: 3,
          },
          scatter: {
            marker: {
              enabled: true,
            },
            events: {
              legendItemClick: () => {
                return false;
              },
            },
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              align: 'center',
              color: '#404040',
              shadow: false,
              style: {
                fontSize: '14px',
                color: '#404040',
                textShadow: '0px',
                textOutline: 'false',
              },
              formatter: seriesAuxFormatter,
            },
          },
        };

        other_options = {
          chart: {
            type: HIGHCHARTS_TYPES.COLUMN,
            marginTop: 50,
            marginBottom: 60,
            marginLeft: 90,
            paddingBottom: 20,
            height: customHeight,
          },
          title: {
            text: parseData[5][0].name,
            paddingTop: 10,
          },
          xAxis: {
            categories: parseData[3],
            labels: {
              style: {
                fontSize: '14px',
                color: '#808080',
                textShadow: '0px',
                textOutline: 'false',
              },
              formatter: () => {
                return '';
              },
            },
          },
          yAxis: {
            title: {
              text: null,
            },
            max: parseData[9],
            min: parseData[8],
            tickInterval: 1,
            plotLines: [
              {
                value: 0,
                color: '#d5d5d5',
                dashStyle: 'Solid',
                width: 1,
                zIndex: 1,
              },
            ],
          },
        };
      }

      // This is a default theme for the MLI bar chart
      // These options can be overwritten when creating the chart and passing other options
      const default_theme = {
        series: current_series,

        chart: {
          ...other_options.chart,
          // backgroundColor option is static
          backgroundColor: null,

          // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
        },

        // title formatting options will be static
        title: {
          align: 'left',
          style: {
            fontSize: '14px',
            color: '#808080',
            textShadow: '0px',
            textOutline: 'false',
          },
          ...other_options.title,
        },

        // xAxis formatting will be static
        xAxis: {
          ...other_options.xAxis,
          gridLineColor: '#b8b4b4',
          labels: {
            rotation: 0,
            step: 1,
            padding: 10,
            style: {
              color: '#121111',
              fontSize: '14px',
            },
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          tickInterval: 1,
          title: {
            style: {
              color: '#121111',
            },
          },
        },

        // yAxis formatting will be static
        yAxis: {
          ...other_options.yAxis,
          gridLineColor: 'transparent',
          labels: {
            formatter: yAxisFormatter,
            format: `${valuePrefix}{value}${valueSuffix}`,
            style: {
              color: '#bdbdbd',
              fontSize: '14px',
            },
          },
          lineColor: '#b8b4b4',
          lineWidth: 1,
          minorGridLineColor: 'transparent',
          tickColor: '#f7f7f7',
          tickWidth: 1,
          title: {
            text: null,
            style: {
              color: '#121111',
            },
          },
        },
        plotOptions: plot_options,
        tooltip,
        legend: {
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',
          symbolPadding: 5,
          symbolWidth: 38,
          labelFormatter: legendFormatter,
          itemStyle: {
            fontSize: '14px',
            color: '#606060',
          },
          itemHoverStyle: {
            color: '#606060',
          },
          itemHiddenStyle: {
            color: '#121111',
          },
        },
        credits: {
          enabled: false,
        },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
      };
      return { ...default_theme, other_options };
    };

    useEffect(() => {
      if (!visualizationComponent.hidden) {
        const locationData = setLocationData(
          visualizationDetails,
          visualizationComponent.viewMode || ''
        );
        const parseData = regroupForColumnDashboard(
          componentConfig?.queryResult,
          locationData,
          visualizationDetails?.scheme?.index
        );
        if (parseData) {
          const noAuxColumn = parseData[5] === null || parseData[5] === undefined;
          if (noAuxColumn) {
            const chart = buildChart(parseData, noAuxColumn);
            setChartOptions([chart]);
          } else {
            const chartAux = buildChart(parseData, noAuxColumn, 200);
            const chartNoAux = buildChart(parseData, !noAuxColumn, 200);
            setChartOptions([chartNoAux, chartAux]);
          }
        } else {
          setChartOptions({ suppressed: true });
        }
      }
      setLoadingChart(false);
    }, [visualizationDetails, componentConfig]);

    const onChangeBenchmarkNational = () => {
      const newComponentVisualizationConfig = { ...visualizationComponent };
      newComponentVisualizationConfig.benchmarks.national = !newComponentVisualizationConfig
        .benchmarks.national;
      newComponentVisualizationConfig.benchmarks.state = false;
      handleUpdateDataSet(newComponentVisualizationConfig);
    };

    const onChangeBenchmarkState = () => {
      const newComponentVisualizationConfig = { ...visualizationComponent };
      newComponentVisualizationConfig.benchmarks.state = !newComponentVisualizationConfig.benchmarks
        .state;
      newComponentVisualizationConfig.benchmarks.national = false;
      handleUpdateDataSet(newComponentVisualizationConfig);
    };

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container fluid className="bg-white p-0">
        {!readOnly && (
          <Container fluid className="p-4 bg-gray-200">
            <Row noGutters>
              <Col md={9} className="d-flex">
                {componentConfig?.queryResult?.filtered && (
                  <OverlayTrigger overlay={<Tooltip id="map-hide">Filtered</Tooltip>} delay={200}>
                    <FontAwesomeIcon
                      color="#AC193C"
                      icon={faFilter}
                      className="mt-0 mr-5"
                      size="lg"
                    />
                  </OverlayTrigger>
                )}
                <Dropdown>
                  <Dropdown.Toggle className="d-flex p-0 border-0 bg-gray-200 text-dark not-hover">
                    <h3 className="text-left cut-text">
                      {visualizationComponent.datasetFilter.description}
                    </h3>
                  </Dropdown.Toggle>
                  <Dropdown.Menu size="sm" title="">
                    {menuOptions.map((option) => (
                      <Dropdown key={shortid.generate()}>
                        <Dropdown.Toggle
                          variant="secondary"
                          className="w-100 d-flex bg-white text-dark border-0 px-4"
                        >
                          {option.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu size="sm" className="w-100">
                          {option.children.map((subOption) => {
                            if (subOption.children.length) {
                              return (
                                <Dropdown key={shortid.generate()}>
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    className="w-100 d-flex bg-white text-dark border-0 px-4"
                                  >
                                    {subOption.label}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu size="sm" className="w-100">
                                    {subOption.children.map((thirdOption) => {
                                      if (thirdOption.children.length) {
                                        return (
                                          <Dropdown key={shortid.generate()}>
                                            <Dropdown.Toggle
                                              variant="secondary"
                                              className="w-100 d-flex bg-white text-dark border-0 px-4"
                                            >
                                              {thirdOption.label}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm" className="w-100">
                                              {thirdOption.children.map((lastOption) => (
                                                <Dropdown.Item
                                                  className="w-100 custom-text-wrap"
                                                  key={shortid.generate()}
                                                  onClick={() =>
                                                    onDatasetChange(
                                                      option,
                                                      subOption,
                                                      thirdOption,
                                                      lastOption
                                                    )
                                                  }
                                                >
                                                  {lastOption.label}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        );
                                      }
                                      return (
                                        <Dropdown.Item
                                          className="w-100 custom-text-wrap"
                                          key={shortid.generate()}
                                          onClick={() =>
                                            onDatasetChange(option, subOption, thirdOption)
                                          }
                                        >
                                          {thirdOption.label}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              );
                            }
                            return (
                              <Dropdown.Item
                                className="w-100 custom-text-wrap"
                                key={shortid.generate()}
                                onClick={() => onDatasetChange(option, subOption)}
                              >
                                {subOption.label}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={3} className="d-flex justify-content-end">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      {visualizationComponent.hidden ? 'View' : 'Hide'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    icon={visualizationComponent.hidden ? faEye : faEyeSlash}
                    className="cursor-pointer mt-0"
                    size="lg"
                    onClick={handleHideChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      Benchmark/National {visualizationComponent.benchmarks.national ? 'On' : 'Off'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    onClick={onChangeBenchmarkNational}
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faChartLine}
                    size="lg"
                    color={visualizationComponent.benchmarks.national && '#58bfc4'}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      Benchmark/State {visualizationComponent.benchmarks.state ? 'On' : 'Off'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    onClick={onChangeBenchmarkState}
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faChartBar}
                    size="lg"
                    color={visualizationComponent.benchmarks.state && '#58bfc4'}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Export</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5 mt-0 cursor-pointer"
                    icon={faExternalLinkAlt}
                    size="lg"
                    onClick={onExportChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add to report</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faFileAlt}
                    size="lg"
                    onClick={onAddToReport}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid className="p-4 bg-white" id="graduation-chart">
          {chartOptions.suppressed && (
            <Alert className="m-3" variant="warning">
              <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
              visualization suppressed
            </Alert>
          )}
          {!visualizationComponent.hidden &&
            !chartOptions.suppressed &&
            chartOptions.map((co) => (
              <HighchartsReact
                key={shortid.generate()}
                highcharts={Highcharts}
                options={co}
                ref={chartRef}
              />
            ))}
        </Container>
      </Container>
    );
  }
);

GraduationChart.displayName = 'GraduationChart';

export default React.memo(GraduationChart);

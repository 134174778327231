import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CardAuthor = ({ author, createdAt }) => (
  <div className="Card-info">
    <div>
      { `Created ${moment(createdAt).format('LL')}, ${moment(createdAt).format('h:mm A')}` }
    </div>
    <div>
      { `by ${author || 'John Miler'}` }
    </div>
  </div>
);

CardAuthor.propTypes = {
  author: PropTypes.string,
  createdAt: PropTypes.string,
};

CardAuthor.defaultProps = {
  author: 'John Miller',
  createdAt: null,
};

export default CardAuthor;

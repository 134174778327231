/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  loading: false,
  organizations: [],
  error: '',
  sortField: 'uname',
  sortDirection: 'asc',
  pageNumber: 0,
  totalSize: 10,
  canGetList: false,
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.ORG_LIST_PENDING:
      return {
        ...state,
        loading: true,
        organizations: [],
        error: '',
      };
    case types.reducerTypes.ORG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.payload.results,
        sortField: action.payload.sortField,
        sortDirection: action.payload.sortDirection,
        pageNumber: action.payload.pageNumber,
        totalSize: action.payload.totalSize,
      };
    case types.reducerTypes.ORG_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.reducerTypes.ORG_SAVE_PENDING:
      return {
        ...state,
        loading: true,
        canGetList: false,
      };
    case types.reducerTypes.ORG_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        canGetList: true,
      };
    case types.reducerTypes.ORG_SAVE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.reducerTypes.ORG_DELETE_PENDING:
      return {
        ...state,
        canGetList: false,
        loading: true,
      };
    case types.reducerTypes.ORG_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        canGetList: true,
      };
    case types.reducerTypes.ORG_DELETE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
  }
  return state;
};

export default orgReducer;

import types from '../types';

const initialState = {
  activeTopic: {},
  allTopics: [],
  isLoading: false,
};

const topicReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.TOPIC_LIST_PENDING: {
      return {
        ...state,
        isLoading: true,
        allTopics: [],
      };
    }
    case types.reducerTypes.TOPIC_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allTopics: action.payload,
      };
    }
    case types.reducerTypes.TOPIC_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        allTopics: [],
      };
    }
    case types.reducerTypes.TOPIC_ID_PENDING: {
      return {
        ...state,
        isLoading: true,
        activeTopic: {},
      };
    }
    case types.reducerTypes.TOPIC_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activeTopic: action.payload,
      };
    }
    case types.reducerTypes.TOPIC_ID_ERROR: {
      return {
        ...state,
        isLoading: false,
        activeTopic: {},
      };
    }
    default:
  }
  return state;
};

export default topicReducer;

import { ENABLE_SURVEY_WIZARD } from '../config/appConfig';

const modalMessages = (type) => {
  const message = {};
  switch (type) {
    case 'invalidFile': {
      message.title = 'Uploaded failed';
      message.body = 'This file format is not supported';
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Upload Error';
      return message;
    }
    case 'duplicateFile': {
      message.title = 'Duplicate file';
      message.body = 'This file is already uploaded. Try using a different file';
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Upload Error';
      return message;
    }
    case 'removeFile': {
      message.title = 'Do you want to remove this file?';
      message.imageSrc = '/modal-message-remove.svg';
      message.imageAlt = 'Remove File';
      message.button1Text = 'Yes';
      message.button2Text = 'No';
      message.button1Action = 'removeFile';
      message.button2Action = 'closeModal';
      return message;
    }
    case 'importBentoProgress': {
      message.title = 'Importing files to Bento';
      message.body = 'Importing files might take up to 20 minutes';
      message.button1Text = 'Cancel';
      message.button1Action = 'resetAndCloseModal';
      message.spinner = true;
      message.className = 'single';
      return message;
    }
    case 'importDashboardBentoSuccess': {
      message.title = 'Success! Files were imported into Bento.';
      message.caption = null;
      message.imageSrc = '/modal-message-bento-success.svg';
      message.imageAlt = 'Import to Bento Success';
      message.button1Text = 'Go to Data Source Manager';
      message.button1Action = 'goToDataSourceManager';
      message.className = 'import-success';
      return message;
    }
    case 'importDashboardBentoError': {
      message.title = 'The file you’ve imported couldn’t be processed, try to upload it again';
      message.caption = null;
      message.imageSrc = '/modal-message-bento-success.svg';
      message.imageAlt = 'Import to Bento Error';
      message.button1Text = 'Okay';
      message.button1Action = 'goToDataSourceManager';
      message.className = 'import-error';
      return message;
    }
    case 'importSampleBentoSuccess': {
      message.title = 'Success! Files were imported into Bento.';
      message.caption = 'Your new survey was created and can now be managed in Bento.';
      message.imageSrc = '/modal-message-bento-success.svg';
      message.imageAlt = 'Import to Bento Success';
      if (ENABLE_SURVEY_WIZARD) {
        message.button1Text = 'Configure new survey';
        message.button1Action = 'goToConfigureSurvey';
        message.button2Text = 'Go to new survey';
        message.button2Action = 'goToAllSurveys';
      } else {
        message.button1Text = 'Go to new survey';
        message.button1Action = 'goToAllSurveys';
      }
      message.className = 'import-success';
      return message;
    }
    case 'importSampleBentoError': {
      message.title = 'The file you’ve imported couldn’t be processed, try to upload it again';
      message.caption = null;
      message.imageSrc = '/modal-message-bento-success.svg';
      message.imageAlt = 'Import to Bento Error';
      message.button1Text = 'Okay';
      message.button1Action = 'goToAllSurveys';
      message.className = 'import-error';
      return message;
    }
    case 'draftIntoMainSurvey': {
      message.title = 'Are you sure you want to convert this draft into the main survey?';
      message.caption = 'This will publish your draft survey';
      message.imageSrc = '/convert-art.svg';
      message.imageAlt = 'Convert Draft Survey';
      message.button1Text = 'Cancel';
      message.button1Action = 'goToAllSurveys';
      message.button2Text = 'Yes, convert';
      message.button2Action = 'convertDraftToMain';
      return message;
    }
    case 'deleteDraftSurvey': {
      message.title = 'Are you sure you want to delete this survey?';
      message.imageSrc = '/modal-message-remove.svg';
      message.imageAlt = 'Delete Draft Survey';
      message.button1Text = 'Yes';
      message.button1Action = 'deleteDraftSurvey';
      message.button2Text = 'No';
      message.button2Action = 'closeModal';
      return message;
    }
    case 'visualizationCopyIntoMain': {
      message.title =
        'Are you sure you want to convert this visualization into the main visualization?';
      message.caption = 'This will publish your copy visualization';
      message.imageSrc = '/convert-art.svg';
      message.imageAlt = 'Convert Copy Visualization';
      message.button1Text = 'Yes';
      message.button1Action = 'convertVisualizationIntoMain';
      message.button2Text = 'Cancel';
      message.button2Action = 'closeModal';
      return message;
    }
    case 'errorDeleteDraftSurvey': {
      message.title = 'There was an error deleting draft. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Delete error';
      message.button1Text = 'Close';
      message.button1Action = 'closeModal';
      return message;
    }
    case 'errorConvertDraftIntoSurvey': {
      message.title =
        'There was an error converting draft into main survey. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Convert Into Main Survey error';
      message.button1Text = 'Close';
      message.button1Action = 'closeModal';
      return message;
    }
    case 'errorCreateDraftSurvey': {
      message.title = 'There was an error creating a Draft. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Create draft survey error';
      message.button1Text = 'Close';
      message.button1Action = 'closeModal';
      return message;
    }
    case 'errorPublishSurvey': {
      message.title = 'There was an error publishing a Survey. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Publish survey error';
      message.button1Text = 'Close';
      message.button1Action = 'closeModal';
      return message;
    }
    case 'errorConvertCopyIntoVisualization': {
      message.title = 'There was an error publishing the visualization. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Publish visualization error';
      message.button1Text = 'Close';
      message.button1Action = 'closeModal';
      return message;
    }

    case 'errorCopyIntoVisualization': {
      message.title = 'There was an error copying the visualization. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Copying visualization error';
      message.button1Text = 'Close';
      message.button1Action = 'closeModal';
      return message;
    }

    case 'getAllMetadatasErr': {
      message.title = 'There was an error fetching metadatas. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Copying visualization error';
      message.button1Text = 'Go to dashboard';
      message.button1Action = 'goToDashboard';
      return message;
    }

    case 'getAllSampleMetadataErr': {
      message.title = 'There was an error fetching variables. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Copying visualization error';
      message.button1Text = 'Return to dashboard';
      message.button1Action = 'goToDashboard';
      return message;
    }

    case 'updateChartErr': {
      message.title = 'There was an error updating chart. Please try again later.';
      message.caption = null;
      message.imageSrc = '/modal-message-error-upload.svg';
      message.imageAlt = 'Copying visualization error';
      message.button1Text = 'Return to dashboard';
      message.button1Action = 'goToDashboard';
      return message;
    }

    default:
  }
  return message;
};

export default modalMessages;

import axios from 'axios';
import types from '../types';
import { API_ROOT } from '../config/appConfig';
import { getHeaders } from './helper.action';
import { loginApi } from '../api/users';

export const checkValidToken = (jwtParam = null) => async (dispatch) => {
  const jwt = jwtParam;
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/authorization`,
      getHeaders('application/json', jwt)
    );
    if (res.status !== 200) {
      dispatch({ type: types.reducerTypes.VALID_TOKEN_ERROR });
      return false;
    }
    const { firstName, lastName } = res.data;
    dispatch({
      type: types.reducerTypes.VALID_TOKEN_SUCCESS,
      jwt,
      firstName,
      lastName,
    });
    sessionStorage.setItem('jwt', jwt);
    return true;
  } catch (error) {
    console.error(error);
    dispatch({ type: types.reducerTypes.VALID_TOKEN_ERROR });
    return false;
  }
};

export const login = (payload) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.VALID_TOKEN_CLEAR });
    const { errorMessage, token: jwt } = await loginApi(payload);
    if (errorMessage) {
      dispatch({ type: types.reducerTypes.VALID_TOKEN_ERROR, payload: 'Unexpected Error!' });
    } else {
      const res = await axios.get(
        `${API_ROOT}/admin-backend/authorization`,
        getHeaders('application/json', jwt)
      );
      const { firstName, lastName } = res.data;
      dispatch({
        type: types.reducerTypes.VALID_TOKEN_SUCCESS,
        jwt,
        firstName,
        lastName,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.VALID_TOKEN_ERROR, payload: 'Unexpected Error!' });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: types.reducerTypes.VALID_TOKEN_SUCCESS,
  });
};

import React from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

import { epochToDateHuman } from '../../helpers/helpers';

import './styles.css';

const UserProfilePage = () => {
  const jwt = useSelector((state) => state.session.jwt);
  const jwtObj = jwtDecode(jwt);

  return (
    <Container fluid className="p-0">
      <Container>
        <Form className="col-12 trans-form">
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="12" className="text-center profile-title">
              {jwtObj.firstName} {jwtObj.lastName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="12" className="text-center">
              {jwtObj.role}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="5" className="text-right text-uppercase">
              E-mail:
            </Form.Label>
            <Col sm="7">
              <Form.Control plaintext readOnly defaultValue={jwtObj.email} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="5" className="text-right text-uppercase">
              Password:
            </Form.Label>
            <Col sm="2" className="text-center">
              <Button href="/change-password" className="profile-button">
                Change Password
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="5" className="text-right text-uppercase">
              Last login:
            </Form.Label>
            <Col sm="7">
              <Form.Control
                plaintext
                readOnly
                defaultValue={
                  jwtObj.lastLogin ? epochToDateHuman(jwtObj?.lastLogin?.epochSecond) : '-'
                }
              />
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </Container>
  );
};
export default React.memo(UserProfilePage);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import shortid from 'shortid';
import CheckboxRow from '../CheckboxRow';
import './style.css';

const Tabs = ({ allSampleMetadata, onChangeHandler, isChecked, allMetadatas }) => {
  const [isActive, setIsActive] = useState(true);
  const renderCheckbox = (metadata = []) => {
    if (metadata.length <= 0) return null;
    const filteredData = metadata.filter((el) => el.Data.identifier.includes('VALUE'));
    return filteredData.map((sample) => (
      <CheckboxRow
        variableName={sample.Data.identifier}
        name={`value_dimension-${sample.Parent}`}
        id={sample.Id}
        parent={sample.Parent}
        onChangeHandler={onChangeHandler}
        key={sample.Id}
        checked={isChecked(sample.Id)}
      />
    ));
  };

  const renderTabPane = (multipleMetadatas) =>
    multipleMetadatas.map((metadata, i) => (
      <Tab.Pane
        key={shortid.generate()}
        eventKey={`tab-${i}`}
        className={isActive && i === 0 ? 'active show' : null}
      >
        {renderCheckbox(metadata)}
      </Tab.Pane>
    ));

  const getMetadataName = (metadataId = []) => {
    if (allMetadatas.length <= 0) return null;
    const filteredMetadata = allMetadatas.filter((metadata) => metadata.Id === metadataId)[0];
    if (filteredMetadata.length <= 0 || !filteredMetadata.Data) return null;
    const { Data } = filteredMetadata;
    return Data.title;
  };

  // TODO: Check order to be same as previous screen
  const parseName = (samples = []) => {
    // All samples will retrutn it aprent ID from the BE
    const parentId = samples.every((sample) => sample.Parent === samples[0].Parent);
    if (parentId) {
      return getMetadataName(samples[0].Parent);
    }
    return 'Default Metadata';
  };

  const setDisable = () => {
    setIsActive(false);
  };

  return (
    <div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="no-gutters">
          <Col sm={3} className="custom-border">
            <Nav variant="pills" className="flex-column">
              {allSampleMetadata.map((samples, i) => (
                <Nav.Item key={shortid.generate()}>
                  <Nav.Link
                    eventKey={`tab-${i}`}
                    onClick={setDisable}
                    className={isActive && i === 0 ? 'active' : null}
                  >
                    {parseName(samples)}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>{renderTabPane(allSampleMetadata)}</Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

Tabs.propTypes = {
  allSampleMetadata: PropTypes.node.isRequired,
  allMetadatas: PropTypes.node.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  isChecked: PropTypes.string.isRequired,
};

export default Tabs;

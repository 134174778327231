/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../Button/index';
import types from '../../types';
import { importToBento, replaceMetadata } from '../../actions/bento.action';
import './style.css';

const Validations = ({ formAction, prevStep, nextStep, submitRef }) => {
  const { buttonLabel, buttonAction, validation } = formAction;
  const jwt = useSelector((state) => state.session.jwt) || '';
  const wizard = useSelector((state) => state.wizard) || '';
  const isImportingToBento = useSelector((state) => state.bento.isImporting);
  const dispatch = useDispatch();

  const randS0ImportToBentoStatus = useSelector(
    (state) => state.validations.randS0ImportToBentoStatus
  );

  const replaceDataSetS0ImportToBentoStatus = useSelector(
    (state) => state.validations.replaceDataSetS0ImportToBentoStatus
  );

  const visualizationS1BentoStatus = useSelector(
    (state) => state.validations.visualizationS1BentoStatus
  );

  /** TODO: Should be a helper */
  const getObjectByFileType = (object, fileType) => {
    if (!object) return undefined;
    let objectName;
    Object.keys(object).forEach((key) => {
      if (object[key].FileType === fileType) {
        objectName = object[key].ObjectName;
      }
    });
    return objectName;
  };

  const handleFormAction = (action) => {
    const { files, replaceDataSet, formData, relatedSurveyId, relatedOrgId } = wizard;
    switch (action) {
      case 'importToBento': {
        const type = types.modelTypes.sampleMetadata;
        const bentoSchema = {
          provider: wizard.wizardRule,
          sasFileName: getObjectByFileType(files, 'sasFileName'),
          dataFile: getObjectByFileType(files, 'dataFile'),
          fileName: getObjectByFileType(files, 'fileName'),
          relatedSurveyId,
          organizationId: relatedOrgId,
        };
        dispatch(importToBento(bentoSchema, jwt, type));
        return;
      }
      case 'importSampleDashboardToBento': {
        const type = types.modelTypes.dashboardMetadata;
        const bentoSchema = {
          provider: wizard.wizardRule,
          dataFile: getObjectByFileType(files, 'dataFile'),
          userData: formData,
        };
        dispatch(importToBento(bentoSchema, jwt, type));
        return;
      }
      case 'replaceDataSet': {
        const { surveyId, surveyParentId, orgId } = replaceDataSet;
        const bentoSchema = {
          provider: wizard.wizardRule,
          sasFileName: getObjectByFileType(files, 'sasFileName'),
          dataFile: getObjectByFileType(files, 'dataFile'),
          fileName: getObjectByFileType(files, 'fileName'),
          parent: surveyParentId,
          relatedSurveyId,
          organizationId: orgId,
        };
        dispatch(replaceMetadata(bentoSchema, jwt, surveyId));
        return;
      }
      case 'nextStep': {
        submitRef.current.click();
        break;
      }
      default:
    }
  };

  const randS0ImportToBento = () => !randS0ImportToBentoStatus;
  const visualizationS1Bento = () => !visualizationS1BentoStatus;
  const replaceDataSetS0ImportToBento = () => !replaceDataSetS0ImportToBentoStatus;

  const validationByStepByProvider = (validationRule) => {
    switch (validationRule) {
      case 'rand_s0_importToBento': {
        if (isImportingToBento) return true;
        return randS0ImportToBento();
      }
      case 'replaceDataSet_s0_importToBento': {
        if (isImportingToBento) return true;
        return replaceDataSetS0ImportToBento();
      }
      case 'visualization_s1_bento': {
        return visualizationS1Bento();
      }
      default:
        return null;
    }
  };

  return (
    <div className="Validations">
      <div className="required-files mb-2">* Required Fields to Upload</div>
      <Button
        buttonText={buttonLabel}
        theme="dark"
        size="xl"
        onClick={() => handleFormAction(buttonAction)}
        disabled={validationByStepByProvider(validation)}
      />
    </div>
  );
};

Validations.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
  formAction: PropTypes.shape({
    buttonLabel: PropTypes.string,
    buttonAction: PropTypes.string,
    validation: PropTypes.string,
  }),
  submitRef: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
};

Validations.defaultProps = {
  prevStep: () => {},
  nextStep: () => {},
  formAction: {},
};

export default Validations;

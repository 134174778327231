import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import CardsGroup from '../CardsGroup';

const SurveysContainer = ({ surveys }) => {
  return (
    <Container fluid>
      {surveys &&
        surveys.length > 0 &&
        surveys.map((surveyData) => (
          <CardsGroup
            key={surveyData.Id}
            id={surveyData.Id}
            surveyData={surveyData}
            surveyChild={surveyData.Drafts}
            isSurvey
          />
        ))}
    </Container>
  );
};

SurveysContainer.propTypes = {
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      Data: PropTypes.object,
    })
  ).isRequired,
};

export default SurveysContainer;

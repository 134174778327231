import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';

const BarConfig = ({ onClickHandler, isChecked }) => (
  <div className="BarConfig">
    <Tabs
      type="bar"
      onClickHandler={onClickHandler}
      isChecked={isChecked}
    />
  </div>
);

BarConfig.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
};

export default BarConfig;

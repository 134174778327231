/* eslint-disable consistent-return */
import axios from 'axios';
import { getHeaders } from '../actions/helper.action';
import { API_ROOT } from '../config/appConfig';

export const getSectionsApi = async (jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/section`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSectionByIdApi = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/section/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const createSectionApi = async (sectionInfo, jwt) => {
  await axios.post(
    `${API_ROOT}/admin-backend/section`,
    {
      Data: sectionInfo,
    },
    getHeaders('application/json', jwt)
  );
};

export const updateSectionApi = async (sectionId, sectionInfo, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/section/${sectionId}`,
    {
      Data: sectionInfo,
    },
    getHeaders('application/json', jwt)
  );
};

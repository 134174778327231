const CustomDimension = {
  USER_ROLE: { id: 1, name: 'user_role' },
  USER_STATE: { id: 2, name: 'user_state' },
  USER_USERNAME: { id: 3, name: 'user_username' },
  SURVEY_SECTION: { id: 4, name: 'survey_section' },
  FILTER_IS_APPLIED: { id: 5, name: 'survey_is_filtered' },
  FILTER: { id: 6, name: 'survey_filter' },
  DASH_GLOBAL_FILTER: { id: 7, name: 'dashboard_filter' },
  DASH_FILTER_IS_APPLIED: { id: 8, name: 'dashboard_filter_is_applied' },
  DASH_LOCATION_SELECTION: { id: 9, name: 'dashboard_selected_location' },
  DASH_DATASET_LTOP: { id: 10, name: 'dashboard_dataset_map' },
  DASH_DATASET_LBOT_LINE: { id: 11, name: 'dashboard_dataset_line' },
  DASH_DATASET_LBOT_BAR: { id: 12, name: 'dashboard_dataset_bar' },
  DASH_DATASET_RTOP: { id: 13, name: 'dashboard_dataset_demographics' },
  DASH_DATASET_RMID: { id: 14, name: 'dashboard_dataset_column' },
  DASH_DATASET_RBOT: { id: 15, name: 'dashboard_dataset_question' },
  ITEM_EXPORTED: { id: 16, name: 'item_exported' },
  ITEM_ADDED_TO_REPORT: { id: 17, name: 'item_added_to_report' },
  SEGMENTS_COUNT: { id: 18, name: 'survey_segments_count' },
};

export default CustomDimension;

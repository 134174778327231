export const NJ = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3293, 39.94],
            [-74.2707, 39.9874],
            [-74.1563, 39.9404],
            [-74.1476, 39.9474],
            [-74.1322, 39.9418],
            [-74.139, 39.9337],
            [-74.0867, 39.9437],
            [-74.0686, 39.9482],
            [-74.0709, 39.9368],
            [-74.0767, 39.9129],
            [-74.1015, 39.7568],
            [-74.1053, 39.7649],
            [-74.1031, 39.7965],
            [-74.1667, 39.8673],
            [-74.3293, 39.94],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '02910',
        GEOID: '3402910',
        NAME: 'Central Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117078807,
        AWATER: 35400519,
        INTPTLAT: '+39.9141687',
        INTPTLON: '-074.1903836',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.1398, 39.8883],
              [-75.1274, 39.8808],
              [-75.1206, 39.8726],
              [-75.1154, 39.8612],
              [-75.1354, 39.8617],
              [-75.1456, 39.8845],
              [-75.1398, 39.8883],
            ],
          ],
          [
            [
              [-75.1602, 39.791],
              [-75.1446, 39.8026],
              [-75.1381, 39.7807],
              [-75.1522, 39.783],
              [-75.1602, 39.791],
            ],
          ],
          [
            [
              [-75.1606, 39.8241],
              [-75.1503, 39.8261],
              [-75.1657, 39.811],
              [-75.1606, 39.8241],
            ],
          ],
          [
            [
              [-75.1893, 39.8807],
              [-75.169, 39.8635],
              [-75.1821, 39.8568],
              [-75.1999, 39.8732],
              [-75.1893, 39.8807],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '05820',
        GEOID: '3405820',
        NAME: 'Gateway Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10937744,
        AWATER: 2120934,
        INTPTLAT: '+39.7935306',
        INTPTLON: '-075.1457357',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3782, 39.7458],
            [-75.2863, 39.7928],
            [-75.2369, 39.8199],
            [-75.2277, 39.8261],
            [-75.1877, 39.7967],
            [-75.2301, 39.7587],
            [-75.2666, 39.7618],
            [-75.2722, 39.7345],
            [-75.2072, 39.709],
            [-75.2208, 39.6619],
            [-75.2481, 39.6649],
            [-75.3209, 39.6912],
            [-75.3604, 39.7132],
            [-75.3782, 39.7458],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '07980',
        GEOID: '3407980',
        NAME: 'Kingsway Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134023209,
        AWATER: 2301640,
        INTPTLAT: '+39.7406425',
        INTPTLON: '-075.2761006',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.28, 41.163],
            [-74.2345, 41.1429],
            [-74.2133, 41.134],
            [-74.2489, 41.0638],
            [-74.2506, 41.0607],
            [-74.2705, 41.0221],
            [-74.303, 41.0114],
            [-74.3179, 41.0684],
            [-74.3462, 41.0608],
            [-74.28, 41.163],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '08190',
        GEOID: '3408190',
        NAME: 'Lakeland Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86062246,
        AWATER: 10869020,
        INTPTLAT: '+41.0819577',
        INTPTLON: '-074.2790699',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1486, 39.6986],
            [-74.1527, 39.754],
            [-74.1053, 39.7649],
            [-74.1015, 39.7568],
            [-74.2296, 39.5695],
            [-74.2498, 39.547],
            [-74.2961, 39.5084],
            [-74.261, 39.5522],
            [-74.2666, 39.5853],
            [-74.2656, 39.5889],
            [-74.239, 39.6424],
            [-74.3582, 39.7165],
            [-74.3897, 39.773],
            [-74.1486, 39.6986],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '15480',
        GEOID: '3415480',
        NAME: 'Southern Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142498599,
        AWATER: 72973273,
        INTPTLAT: '+39.6863790',
        INTPTLON: '-074.2452728',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2666, 39.7618],
            [-75.2301, 39.7587],
            [-75.1877, 39.7967],
            [-75.1721, 39.7949],
            [-75.1602, 39.791],
            [-75.1522, 39.783],
            [-75.1288, 39.7685],
            [-75.121, 39.7442],
            [-75.1359, 39.7179],
            [-75.139, 39.7148],
            [-75.1683, 39.6857],
            [-75.2072, 39.709],
            [-75.2722, 39.7345],
            [-75.2666, 39.7618],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '03210',
        GEOID: '3403210',
        NAME: 'Clearview Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90640927,
        AWATER: 403117,
        INTPTLAT: '+39.7442923',
        INTPTLON: '-075.1905244',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4292, 39.769],
            [-74.3899, 39.7734],
            [-74.3897, 39.773],
            [-74.3582, 39.7165],
            [-74.239, 39.6424],
            [-74.2656, 39.5889],
            [-74.2666, 39.5853],
            [-74.261, 39.5522],
            [-74.2961, 39.5084],
            [-74.3264, 39.5063],
            [-74.4174, 39.5573],
            [-74.4423, 39.5434],
            [-74.4796, 39.556],
            [-74.5279, 39.6582],
            [-74.4292, 39.769],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '13000',
        GEOID: '3413000',
        NAME: 'Pinelands Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 367344332,
        AWATER: 83259096,
        INTPTLAT: '+39.6341538',
        INTPTLON: '-074.3770220',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7168, 41.2216],
            [-74.649, 41.3364],
            [-74.5368, 41.2847],
            [-74.5367, 41.2847],
            [-74.5753, 41.1937],
            [-74.6245, 41.1612],
            [-74.6386, 41.1186],
            [-74.6855, 41.0519],
            [-74.7116, 41.0828],
            [-74.7204, 41.1123],
            [-74.8466, 41.1478],
            [-74.7168, 41.2216],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '07140',
        GEOID: '3407140',
        NAME: 'High Point Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310490862,
        AWATER: 5878752,
        INTPTLAT: '+41.2044305',
        INTPTLON: '-074.6602180',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8823, 41.1806],
            [-74.836, 41.279],
            [-74.7168, 41.2216],
            [-74.8466, 41.1478],
            [-74.7204, 41.1123],
            [-74.7116, 41.0828],
            [-74.7361, 41.0604],
            [-74.7629, 41.0688],
            [-74.7664, 41.0389],
            [-74.7874, 41.0054],
            [-74.8592, 40.9949],
            [-74.8798, 41.0139],
            [-74.8866, 41.0201],
            [-74.939, 41.0687],
            [-74.9669, 41.0945],
            [-74.8823, 41.1806],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '08060',
        GEOID: '3408060',
        NAME: 'Kittatinny Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348671043,
        AWATER: 10096942,
        INTPTLAT: '+41.1237725',
        INTPTLON: '-074.8349458',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.049, 40.9653],
            [-74.0164, 40.9683],
            [-74.0032, 40.9551],
            [-74.0026, 40.9521],
            [-74.0261, 40.9401],
            [-74.0302, 40.9133],
            [-74.0302, 40.9132],
            [-74.0418, 40.9114],
            [-74.0388, 40.9437],
            [-74.049, 40.9653],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '12260',
        GEOID: '3412260',
        NAME: 'River Dell Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11080080,
        AWATER: 501272,
        INTPTLAT: '+40.9437789',
        INTPTLON: '-074.0342658',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3228, 40.9088],
            [-74.2721, 40.8974],
            [-74.2545, 40.8831],
            [-74.2467, 40.8808],
            [-74.2519, 40.8498],
            [-74.2598, 40.8349],
            [-74.2643, 40.8254],
            [-74.2763, 40.8139],
            [-74.2806, 40.807],
            [-74.3343, 40.8184],
            [-74.3292, 40.8383],
            [-74.2983, 40.8304],
            [-74.2637, 40.8352],
            [-74.2702, 40.8598],
            [-74.3285, 40.8447],
            [-74.3299, 40.8492],
            [-74.3228, 40.9088],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '17460',
        GEOID: '3417460',
        NAME: 'West Essex Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47280422,
        AWATER: 503257,
        INTPTLAT: '+40.8818053',
        INTPTLON: '-074.3160761',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0495, 39.8585],
            [-75.0409, 39.8623],
            [-75.0222, 39.8577],
            [-75.016, 39.8593],
            [-75.0016, 39.8365],
            [-75.005, 39.8341],
            [-74.9982, 39.8255],
            [-75.0151, 39.8188],
            [-75.028, 39.8214],
            [-75.0283, 39.8316],
            [-75.0184, 39.8408],
            [-75.0286, 39.8341],
            [-75.0396, 39.8493],
            [-75.0493, 39.8506],
            [-75.0495, 39.8585],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '02880',
        GEOID: '3402880',
        NAME: 'Sterling High School School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10108807,
        AWATER: 0,
        INTPTLAT: '+39.8519620',
        INTPTLON: '-075.0358108',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1315, 40.9893],
            [-74.9669, 41.0945],
            [-74.939, 41.0687],
            [-74.8866, 41.0201],
            [-74.8798, 41.0139],
            [-74.8592, 40.9949],
            [-74.8217, 40.9607],
            [-74.897, 40.9272],
            [-74.9279, 40.9068],
            [-74.9395, 40.9459],
            [-75.0081, 40.9197],
            [-75.0222, 40.8758],
            [-75.054, 40.8592],
            [-75.1205, 40.9684],
            [-75.1213, 40.9692],
            [-75.1315, 40.9893],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '09550',
        GEOID: '3409550',
        NAME: 'North Warren Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299150298,
        AWATER: 7819892,
        INTPTLAT: '+40.9827120',
        INTPTLON: '-074.9848417',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.105, 40.7192],
            [-75.0416, 40.7685],
            [-75.0199, 40.7843],
            [-74.9828, 40.7998],
            [-74.9532, 40.8327],
            [-74.8505, 40.8413],
            [-74.8317, 40.8281],
            [-74.8898, 40.7878],
            [-74.9305, 40.7516],
            [-74.9678, 40.7121],
            [-74.9813, 40.7062],
            [-74.9833, 40.7054],
            [-75.0719, 40.6663],
            [-75.0792, 40.6599],
            [-75.105, 40.7192],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '16970',
        GEOID: '3416970',
        NAME: 'Warren Hills Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190266478,
        AWATER: 753408,
        INTPTLAT: '+40.7651335',
        INTPTLON: '-074.9737834',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2545, 40.8831],
            [-74.2306, 40.8831],
            [-74.2033, 40.9245],
            [-74.1989, 40.9247],
            [-74.1958, 40.9076],
            [-74.1746, 40.8994],
            [-74.1908, 40.8724],
            [-74.1965, 40.8598],
            [-74.2048, 40.8609],
            [-74.2467, 40.8808],
            [-74.2545, 40.8831],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '12570',
        GEOID: '3412570',
        NAME: 'Passaic Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25131514,
        AWATER: 747706,
        INTPTLAT: '+40.8919453',
        INTPTLON: '-074.2146115',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5238, 40.6496],
            [-74.5137, 40.6977],
            [-74.4497, 40.7188],
            [-74.4443, 40.688],
            [-74.4612, 40.6734],
            [-74.4485, 40.6554],
            [-74.4034, 40.6665],
            [-74.4083, 40.6442],
            [-74.4137, 40.641],
            [-74.4417, 40.6294],
            [-74.4824, 40.6213],
            [-74.5177, 40.5874],
            [-74.5631, 40.6107],
            [-74.5238, 40.6496],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '17220',
        GEOID: '3417220',
        NAME: 'Watchung Hills Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96988079,
        AWATER: 1009685,
        INTPTLAT: '+40.6500528',
        INTPTLON: '-074.4958145',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0164, 40.9683],
            [-74.0018, 40.9708],
            [-73.9937, 40.9754],
            [-73.9979, 40.9815],
            [-73.9855, 40.9992],
            [-73.9938, 41.0382],
            [-73.9665, 41.026],
            [-73.959, 41.0225],
            [-73.9384, 41.0133],
            [-73.9416, 40.9953],
            [-73.926, 40.9936],
            [-73.9343, 40.977],
            [-73.945, 40.9575],
            [-73.9425, 40.9429],
            [-73.9757, 40.9531],
            [-73.9758, 40.9541],
            [-74.0032, 40.9551],
            [-74.0164, 40.9683],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '11760',
        GEOID: '3411760',
        NAME: 'Northern Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42371024,
        AWATER: 4295812,
        INTPTLAT: '+40.9864451',
        INTPTLON: '-073.9722041',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6245, 41.1612],
            [-74.5753, 41.1937],
            [-74.4826, 41.1038],
            [-74.5029, 41.0859],
            [-74.536, 41.0585],
            [-74.577, 41.079],
            [-74.6038, 41.0647],
            [-74.6022, 41.0931],
            [-74.6073, 41.0979],
            [-74.6386, 41.1186],
            [-74.6245, 41.1612],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '16870',
        GEOID: '3416870',
        NAME: 'Wallkill Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103364485,
        AWATER: 2077322,
        INTPTLAT: '+41.1209637',
        INTPTLON: '-074.5643282',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1206, 39.8726],
            [-75.1081, 39.878],
            [-75.0821, 39.8724],
            [-75.0677, 39.8721],
            [-75.0642, 39.8599],
            [-75.0495, 39.8585],
            [-75.0493, 39.8506],
            [-75.0396, 39.8493],
            [-75.0286, 39.8341],
            [-75.0283, 39.8316],
            [-75.028, 39.8214],
            [-75.0237, 39.8042],
            [-74.9684, 39.7671],
            [-75.0153, 39.7264],
            [-75.0753, 39.8049],
            [-75.0839, 39.8405],
            [-75.1032, 39.8563],
            [-75.1154, 39.8612],
            [-75.1206, 39.8726],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '01770',
        GEOID: '3401770',
        NAME: 'Black Horse Pike Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72569856,
        AWATER: 1208653,
        INTPTLAT: '+39.8072540',
        INTPTLON: '-075.0478275',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1195, 40.8533],
            [-74.1028, 40.8415],
            [-74.0989, 40.8504],
            [-74.0761, 40.8376],
            [-74.0531, 40.8337],
            [-74.035, 40.8253],
            [-74.0321, 40.8129],
            [-74.045, 40.8077],
            [-74.0607, 40.8057],
            [-74.0818, 40.788],
            [-74.1214, 40.8472],
            [-74.1195, 40.8533],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '02800',
        GEOID: '3402800',
        NAME: 'Carlstadt-East Rutherford School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19995571,
        AWATER: 1487165,
        INTPTLAT: '+40.8205016',
        INTPTLON: '-074.0725557',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2208, 39.6619],
            [-75.2072, 39.709],
            [-75.1683, 39.6857],
            [-75.1074, 39.6795],
            [-75.1007, 39.6404],
            [-75.0318, 39.6626],
            [-74.9116, 39.5786],
            [-74.9852, 39.5148],
            [-75.0188, 39.5384],
            [-75.0025, 39.5535],
            [-75.0197, 39.5581],
            [-75.0345, 39.5494],
            [-75.062, 39.5686],
            [-75.0875, 39.5869],
            [-75.1341, 39.6203],
            [-75.2208, 39.6619],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '15450',
        GEOID: '3415450',
        NAME: 'Delsea Regional High School School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195288942,
        AWATER: 1949040,
        INTPTLAT: '+39.6111023',
        INTPTLON: '-075.0487711',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.9661, 39.7997],
              [-74.957, 39.8092],
              [-74.908, 39.7838],
              [-74.9133, 39.7765],
              [-74.9478, 39.7848],
              [-74.9661, 39.7997],
            ],
          ],
          [
            [
              [-75.016, 39.8593],
              [-74.9269, 39.8738],
              [-74.9086, 39.8095],
              [-74.9569, 39.8167],
              [-74.958, 39.8176],
              [-74.9859, 39.8341],
              [-75.0016, 39.8365],
              [-75.016, 39.8593],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '04380',
        GEOID: '3404380',
        NAME: 'Eastern Camden County Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44710431,
        AWATER: 577288,
        INTPTLAT: '+39.8327374',
        INTPTLON: '-074.9524539',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0407, 40.3205],
            [-74.0027, 40.334],
            [-74.0032, 40.3399],
            [-73.992, 40.3494],
            [-73.9775, 40.3835],
            [-73.9781, 40.3889],
            [-73.9784, 40.3992],
            [-73.9749, 40.3995],
            [-73.9719, 40.3458],
            [-73.9743, 40.3224],
            [-73.9972, 40.3298],
            [-74.0125, 40.3049],
            [-74.0029, 40.2764],
            [-74.0276, 40.2727],
            [-74.0325, 40.2987],
            [-74.051, 40.315],
            [-74.0407, 40.3205],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '14940',
        GEOID: '3414940',
        NAME: 'Shore Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19965136,
        AWATER: 6059645,
        INTPTLAT: '+40.3172637',
        INTPTLON: '-074.0079549',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4112, 39.4204],
            [-75.3511, 39.4715],
            [-75.307, 39.5013],
            [-75.295, 39.5108],
            [-75.2707, 39.5316],
            [-75.2395, 39.5592],
            [-75.2205, 39.547],
            [-75.1742, 39.5173],
            [-75.084, 39.4574],
            [-75.0849, 39.444],
            [-75.146, 39.4309],
            [-75.1262, 39.392],
            [-75.2718, 39.343],
            [-75.281, 39.2992],
            [-75.357, 39.3441],
            [-75.4068, 39.3806],
            [-75.4112, 39.4204],
          ],
          [
            [-75.232, 39.4028],
            [-75.2085, 39.4356],
            [-75.2394, 39.4463],
            [-75.232, 39.4028],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '03620',
        GEOID: '3403620',
        NAME: 'Cumberland Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404967873,
        AWATER: 13924921,
        INTPTLAT: '+39.4294280',
        INTPTLON: '-075.2687618',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1911, 40.5859],
            [-75.1896, 40.5918],
            [-75.1099, 40.6389],
            [-75.1008, 40.6384],
            [-75.0459, 40.6311],
            [-74.9578, 40.5834],
            [-74.9904, 40.5603],
            [-74.9533, 40.5034],
            [-75.0438, 40.4124],
            [-75.0607, 40.4213],
            [-75.0654, 40.5176],
            [-75.0668, 40.5365],
            [-75.0872, 40.5563],
            [-75.1013, 40.568],
            [-75.1911, 40.5859],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '03750',
        GEOID: '3403750',
        NAME: 'Delaware Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229236412,
        AWATER: 3925102,
        INTPTLAT: '+40.5510348',
        INTPTLON: '-075.0532234',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.9541, 40.3765],
              [-74.9468, 40.3856],
              [-74.9129, 40.4189],
              [-74.8304, 40.3821],
              [-74.9429, 40.3417],
              [-74.9458, 40.355],
              [-74.9541, 40.3765],
            ],
          ],
          [
            [
              [-74.9666, 40.3982],
              [-74.986, 40.4068],
              [-74.9654, 40.4063],
              [-74.9666, 40.3982],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '15300',
        GEOID: '3415300',
        NAME: 'South Hunterdon Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60237405,
        AWATER: 1071866,
        INTPTLAT: '+40.3700173',
        INTPTLON: '-074.9101701',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0438, 40.4124],
            [-74.9533, 40.5034],
            [-74.9369, 40.5202],
            [-74.8781, 40.5795],
            [-74.8686, 40.5748],
            [-74.7886, 40.6534],
            [-74.7237, 40.6469],
            [-74.7037, 40.6213],
            [-74.7748, 40.5042],
            [-74.7978, 40.5156],
            [-74.7825, 40.4878],
            [-74.752, 40.4314],
            [-74.7481, 40.4242],
            [-74.8304, 40.3821],
            [-74.9129, 40.4189],
            [-74.9468, 40.3856],
            [-74.9541, 40.3765],
            [-74.9666, 40.3982],
            [-74.9654, 40.4063],
            [-74.986, 40.4068],
            [-75.0412, 40.4105],
            [-75.0438, 40.4124],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '07590',
        GEOID: '3407590',
        NAME: 'Hunterdon Central Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392260693,
        AWATER: 2460156,
        INTPTLAT: '+40.5005012',
        INTPTLON: '-074.8523980',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0337, 40.3757],
            [-73.9775, 40.3835],
            [-73.992, 40.3494],
            [-74.0032, 40.3399],
            [-74.0377, 40.3522],
            [-74.0511, 40.348],
            [-74.0592, 40.3633],
            [-74.0337, 40.3757],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '14400',
        GEOID: '3414400',
        NAME: 'Rumson-Fair Haven Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17233876,
        AWATER: 6668791,
        INTPTLAT: '+40.3639754',
        INTPTLON: '-074.0098377',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1099, 40.6389],
            [-75.1084, 40.6403],
            [-75.0671, 40.6563],
            [-75.0719, 40.6663],
            [-74.9833, 40.7054],
            [-74.9813, 40.7062],
            [-74.9678, 40.7121],
            [-74.9305, 40.7516],
            [-74.8898, 40.7878],
            [-74.8241, 40.7424],
            [-74.7258, 40.7195],
            [-74.7237, 40.6469],
            [-74.7886, 40.6534],
            [-74.8686, 40.5748],
            [-74.8781, 40.5795],
            [-74.9369, 40.5202],
            [-74.9533, 40.5034],
            [-74.9904, 40.5603],
            [-74.9578, 40.5834],
            [-75.0459, 40.6311],
            [-75.1008, 40.6384],
            [-75.1099, 40.6389],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '11610',
        GEOID: '3411610',
        NAME: 'North Hunterdon-Voorhees Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424080480,
        AWATER: 17354582,
        INTPTLAT: '+40.6608239',
        INTPTLON: '-074.8948749',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1918, 40.9787],
            [-74.171, 40.977],
            [-74.1695, 40.9466],
            [-74.1666, 40.9343],
            [-74.1768, 40.9335],
            [-74.1989, 40.9247],
            [-74.2033, 40.9245],
            [-74.1963, 40.9462],
            [-74.2117, 40.9802],
            [-74.1918, 40.9787],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '12600',
        GEOID: '3412600',
        NAME: 'Passaic County Manchester Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13190842,
        AWATER: 123666,
        INTPTLAT: '+40.9550739',
        INTPTLON: '-074.1836380',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9837, 39.9269],
            [-74.9667, 39.9377],
            [-74.8822, 40.0007],
            [-74.8724, 39.9977],
            [-74.8595, 39.9964],
            [-74.8624, 39.9431],
            [-74.8623, 39.9311],
            [-74.7871, 39.9327],
            [-74.7501, 39.9783],
            [-74.7361, 39.9821],
            [-74.5965, 39.8988],
            [-74.4708, 39.9226],
            [-74.4347, 39.8548],
            [-74.4172, 39.8232],
            [-74.3899, 39.7734],
            [-74.4292, 39.769],
            [-74.545, 39.7608],
            [-74.6267, 39.7461],
            [-74.7242, 39.7223],
            [-74.7363, 39.7299],
            [-74.8125, 39.7853],
            [-74.8629, 39.7853],
            [-74.9025, 39.7908],
            [-74.9086, 39.8095],
            [-74.9269, 39.8738],
            [-74.9572, 39.9143],
            [-74.9837, 39.9269],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '08490',
        GEOID: '3408490',
        NAME: 'Lenape Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 836037712,
        AWATER: 13782567,
        INTPTLAT: '+39.8502255',
        INTPTLON: '-074.6861828',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3525, 40.3248],
            [-74.3274, 40.3446],
            [-74.2627, 40.3954],
            [-74.2083, 40.3818],
            [-74.1976, 40.3454],
            [-74.1489, 40.3247],
            [-74.113, 40.3203],
            [-74.1256, 40.2493],
            [-74.1391, 40.2509],
            [-74.1297, 40.1174],
            [-74.1544, 40.0997],
            [-74.243, 40.1158],
            [-74.2638, 40.1683],
            [-74.4068, 40.1724],
            [-74.3535, 40.2089],
            [-74.4081, 40.2738],
            [-74.3525, 40.3248],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '05610',
        GEOID: '3405610',
        NAME: 'Freehold Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501950487,
        AWATER: 5942565,
        INTPTLAT: '+40.2503232',
        INTPTLON: '-074.2457755',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5961, 39.3361],
            [-74.58, 39.3682],
            [-74.5505, 39.3865],
            [-74.5321, 39.375],
            [-74.5503, 39.3538],
            [-74.569, 39.3213],
            [-74.5864, 39.3099],
            [-74.5988, 39.3052],
            [-74.626, 39.2928],
            [-74.5961, 39.3361],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '09360',
        GEOID: '3409360',
        NAME: 'Mainland Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29252042,
        AWATER: 4014231,
        INTPTLAT: '+39.3404701',
        INTPTLON: '-074.5826326',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1256, 40.2493],
            [-74.113, 40.3203],
            [-74.0876, 40.3362],
            [-74.0749, 40.3373],
            [-74.0644, 40.3115],
            [-74.051, 40.315],
            [-74.0325, 40.2987],
            [-74.0276, 40.2727],
            [-74.0702, 40.2708],
            [-74.0774, 40.2344],
            [-74.0944, 40.2063],
            [-74.1256, 40.2493],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '10440',
        GEOID: '3410440',
        NAME: 'Monmouth Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55471450,
        AWATER: 482215,
        INTPTLAT: '+40.2754742',
        INTPTLON: '-074.0833400',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0592, 40.3633],
            [-74.0511, 40.348],
            [-74.0377, 40.3522],
            [-74.0032, 40.3399],
            [-74.0027, 40.334],
            [-74.0407, 40.3205],
            [-74.051, 40.315],
            [-74.0644, 40.3115],
            [-74.0749, 40.3373],
            [-74.0876, 40.3362],
            [-74.0592, 40.3633],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '13750',
        GEOID: '3413750',
        NAME: 'Red Bank Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17131308,
        AWATER: 2751527,
        INTPTLAT: '+40.3364045',
        INTPTLON: '-074.0511073',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8898, 40.7878],
            [-74.8317, 40.8281],
            [-74.8217, 40.8485],
            [-74.7255, 40.8055],
            [-74.6817, 40.8279],
            [-74.6454, 40.8369],
            [-74.6309, 40.8047],
            [-74.5361, 40.8221],
            [-74.5422, 40.7894],
            [-74.5501, 40.7474],
            [-74.5708, 40.7549],
            [-74.6185, 40.7441],
            [-74.6573, 40.7352],
            [-74.6825, 40.7294],
            [-74.7258, 40.7195],
            [-74.8241, 40.7424],
            [-74.8898, 40.7878],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '17550',
        GEOID: '3417550',
        NAME: 'West Morris Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257037028,
        AWATER: 1904709,
        INTPTLAT: '+40.7845704',
        INTPTLON: '-074.7151950',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4744, 40.8198],
            [-74.4642, 40.8358],
            [-74.384, 40.825],
            [-74.342, 40.8468],
            [-74.3299, 40.8492],
            [-74.3285, 40.8447],
            [-74.3292, 40.8383],
            [-74.3343, 40.8184],
            [-74.3653, 40.7955],
            [-74.3673, 40.7611],
            [-74.3651, 40.7497],
            [-74.3921, 40.7514],
            [-74.4408, 40.7774],
            [-74.4342, 40.7911],
            [-74.4744, 40.8198],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '06660',
        GEOID: '3406660',
        NAME: 'Hanover Park Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66608078,
        AWATER: 1627844,
        INTPTLAT: '+40.8082650',
        INTPTLON: '-074.4041202',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9982, 40.4226],
            [-73.9784, 40.3992],
            [-73.9781, 40.3889],
            [-74.0045, 40.4062],
            [-74.051, 40.4132],
            [-74.0286, 40.4443],
            [-73.9982, 40.4226],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '07050',
        GEOID: '3407050',
        NAME: 'Henry Hudson Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5256933,
        AWATER: 10102976,
        INTPTLAT: '+40.4132193',
        INTPTLON: '-074.0207698',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5946, 40.9021],
            [-74.5944, 40.9023],
            [-74.441, 41.045],
            [-74.4302, 41.0202],
            [-74.4286, 40.9582],
            [-74.4515, 40.9343],
            [-74.4406, 40.9072],
            [-74.4658, 40.885],
            [-74.534, 40.8331],
            [-74.5249, 40.8806],
            [-74.5401, 40.8839],
            [-74.5681, 40.9003],
            [-74.5782, 40.886],
            [-74.5965, 40.901],
            [-74.5946, 40.9021],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '10740',
        GEOID: '3410740',
        NAME: 'Morris Hills Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149614201,
        AWATER: 13051024,
        INTPTLAT: '+40.9421346',
        INTPTLON: '-074.5120948',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7892, 40.9313],
            [-74.7406, 40.9687],
            [-74.6884, 41.0091],
            [-74.6474, 40.9995],
            [-74.6973, 40.9251],
            [-74.6854, 40.9137],
            [-74.677, 40.9109],
            [-74.6832, 40.9101],
            [-74.6922, 40.9044],
            [-74.7018, 40.8911],
            [-74.7134, 40.9024],
            [-74.7201, 40.9132],
            [-74.7679, 40.9118],
            [-74.7892, 40.9313],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '08500',
        GEOID: '3408500',
        NAME: 'Lenape Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61552984,
        AWATER: 4180593,
        INTPTLAT: '+40.9434031',
        INTPTLON: '-074.7183429',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9353, 39.039],
            [-74.8333, 38.9918],
            [-74.8331, 38.9858],
            [-74.8468, 38.9577],
            [-74.8687, 38.9428],
            [-74.9388, 38.9316],
            [-74.9598, 38.9311],
            [-74.9609, 38.9423],
            [-74.972, 38.9404],
            [-74.9353, 39.039],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '09090',
        GEOID: '3409090',
        NAME: 'Lower Cape May Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81096173,
        AWATER: 9377727,
        INTPTLAT: '+38.9776752',
        INTPTLON: '-074.9107367',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.084, 41.0438],
            [-74.0794, 41.0485],
            [-74.0719, 41.0729],
            [-74.0509, 41.0635],
            [-74.0126, 41.0464],
            [-73.9938, 41.0382],
            [-73.9855, 40.9992],
            [-73.9979, 40.9815],
            [-74.0043, 40.9872],
            [-74.0257, 40.9966],
            [-74.0773, 41.0045],
            [-74.0761, 41.0147],
            [-74.084, 41.0438],
          ],
          [
            [-74.0697, 41.0415],
            [-74.0264, 41.0206],
            [-74.0199, 41.0206],
            [-74.0215, 41.0377],
            [-74.0697, 41.0415],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '12510',
        GEOID: '3412510',
        NAME: 'Pascack Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37205573,
        AWATER: 1245454,
        INTPTLAT: '+41.0069960',
        INTPTLON: '-074.0132534',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8557, 40.032],
            [-74.7975, 40.0442],
            [-74.7713, 40.0298],
            [-74.7333, 40.009],
            [-74.7361, 39.9821],
            [-74.7501, 39.9783],
            [-74.7871, 39.9327],
            [-74.8623, 39.9311],
            [-74.8624, 39.9431],
            [-74.8595, 39.9964],
            [-74.8724, 39.9977],
            [-74.8557, 40.032],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '13620',
        GEOID: '3413620',
        NAME: 'Rancocas Valley Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100914533,
        AWATER: 1810659,
        INTPTLAT: '+39.9860484',
        INTPTLON: '-074.8054285',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8259, 39.5617],
            [-74.8008, 39.5835],
            [-74.7803, 39.6012],
            [-74.6758, 39.6913],
            [-74.5781, 39.6001],
            [-74.5338, 39.5737],
            [-74.4948, 39.5573],
            [-74.4423, 39.5434],
            [-74.4174, 39.5573],
            [-74.3264, 39.5063],
            [-74.3323, 39.4431],
            [-74.4138, 39.3972],
            [-74.461, 39.4155],
            [-74.5227, 39.4349],
            [-74.5863, 39.4664],
            [-74.7178, 39.404],
            [-74.7552, 39.4403],
            [-74.8377, 39.4244],
            [-74.8694, 39.5243],
            [-74.8259, 39.5617],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '06060',
        GEOID: '3406060',
        NAME: 'Greater Egg Harbor Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 692974632,
        AWATER: 75280653,
        INTPTLAT: '+39.5189677',
        INTPTLON: '-074.6193739',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.1164, 41.0728],
              [-74.1038, 41.0869],
              [-74.0912, 41.0813],
              [-74.0719, 41.0729],
              [-74.0794, 41.0485],
              [-74.084, 41.0438],
              [-74.1144, 41.0457],
              [-74.1164, 41.0728],
            ],
          ],
          [
            [
              [-74.1556, 41.0258],
              [-74.1503, 41.0405],
              [-74.1146, 41.0436],
              [-74.1213, 41.0199],
              [-74.1476, 41.0221],
              [-74.1556, 41.0258],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '11730',
        GEOID: '3411730',
        NAME: 'Northern Highlands Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21645212,
        AWATER: 107355,
        INTPTLAT: '+41.0523667',
        INTPTLON: '-074.1112146',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2705, 41.0221],
            [-74.2506, 41.0607],
            [-74.2489, 41.0638],
            [-74.2018, 41.0438],
            [-74.1676, 41.0238],
            [-74.1556, 41.0258],
            [-74.1476, 41.0221],
            [-74.1375, 41.0075],
            [-74.1412, 40.9828],
            [-74.1491, 40.9711],
            [-74.171, 40.977],
            [-74.1918, 40.9787],
            [-74.2117, 40.9802],
            [-74.2485, 40.9923],
            [-74.2667, 41.0141],
            [-74.2705, 41.0221],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '13560',
        GEOID: '3413560',
        NAME: 'Ramapo-Indian Hill Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63135276,
        AWATER: 2071771,
        INTPTLAT: '+41.0139220',
        INTPTLON: '-074.2091351',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8023, 40.073],
            [-74.7722, 40.0668],
            [-74.7612, 40.1214],
            [-74.6989, 40.118],
            [-74.6773, 40.1673],
            [-74.5948, 40.1379],
            [-74.5878, 40.1382],
            [-74.5531, 40.0791],
            [-74.5406, 40.057],
            [-74.5999, 40.0002],
            [-74.6003, 40.0001],
            [-74.6282, 40.0395],
            [-74.6339, 40.0332],
            [-74.6193, 39.9939],
            [-74.6561, 40.0102],
            [-74.6552, 40.0141],
            [-74.6572, 40.0108],
            [-74.7333, 40.009],
            [-74.7713, 40.0298],
            [-74.7975, 40.0442],
            [-74.8023, 40.073],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '11700',
        GEOID: '3411700',
        NAME: 'Northern Burlington Regional School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253541659,
        AWATER: 1471285,
        INTPTLAT: '+40.0727906',
        INTPTLON: '-074.6819699',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4112, 39.4204],
            [-75.3234, 39.4255],
            [-75.33, 39.3869],
            [-75.357, 39.3441],
            [-75.4068, 39.3806],
            [-75.4112, 39.4204],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406150',
        NAME: 'Greenwich Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 46248278,
        AWATER: 2531548,
        INTPTLAT: '+39.3962734',
        INTPTLON: '-075.3675507',
        ELSDLEA: '06150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3234, 39.4255],
            [-75.295, 39.5108],
            [-75.2707, 39.5316],
            [-75.2394, 39.4463],
            [-75.232, 39.4028],
            [-75.33, 39.3869],
            [-75.3234, 39.4255],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407500',
        NAME: 'Hopewell Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80504608,
        AWATER: 2465274,
        INTPTLAT: '+39.4385784',
        INTPTLON: '-075.2817041',
        ELSDLEA: '07500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3511, 39.4715],
            [-75.307, 39.5013],
            [-75.295, 39.5108],
            [-75.3234, 39.4255],
            [-75.4112, 39.4204],
            [-75.3511, 39.4715],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415840',
        NAME: 'Stow Creek Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47396306,
        AWATER: 1414588,
        INTPTLAT: '+39.4561955',
        INTPTLON: '-075.3550160',
        ELSDLEA: '15840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2395, 39.5592],
            [-75.2205, 39.547],
            [-75.1742, 39.5173],
            [-75.1677, 39.4317],
            [-75.2085, 39.4356],
            [-75.2394, 39.4463],
            [-75.2707, 39.5316],
            [-75.2395, 39.5592],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416530',
        NAME: 'Upper Deerfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80543375,
        AWATER: 458303,
        INTPTLAT: '+39.4948296',
        INTPTLON: '-075.2185893',
        ELSDLEA: '16530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.33, 39.3869],
            [-75.232, 39.4028],
            [-75.2085, 39.4356],
            [-75.1677, 39.4317],
            [-75.146, 39.4309],
            [-75.1262, 39.392],
            [-75.2718, 39.343],
            [-75.281, 39.2992],
            [-75.357, 39.3441],
            [-75.33, 39.3869],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405040',
        NAME: 'Fairfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 106864190,
        AWATER: 6965765,
        INTPTLAT: '+39.3723162',
        INTPTLON: '-075.2387206',
        ELSDLEA: '05040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5781, 39.6001],
            [-74.5338, 39.5737],
            [-74.6568, 39.5325],
            [-74.5781, 39.6001],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404530',
        NAME: 'Egg Harbor City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28314191,
        AWATER: 1310874,
        INTPTLAT: '+39.5636873',
        INTPTLON: '-074.5958654',
        ELSDLEA: '04530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6568, 39.5325],
            [-74.5338, 39.5737],
            [-74.4948, 39.5573],
            [-74.4423, 39.5434],
            [-74.4174, 39.5573],
            [-74.3264, 39.5063],
            [-74.3323, 39.4431],
            [-74.4138, 39.3972],
            [-74.461, 39.4155],
            [-74.5227, 39.4349],
            [-74.5863, 39.4664],
            [-74.6729, 39.5266],
            [-74.6568, 39.5325],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405730',
        NAME: 'Galloway Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230701195,
        AWATER: 67700859,
        INTPTLAT: '+39.4914527',
        INTPTLON: '-074.4738654',
        ELSDLEA: '05730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8259, 39.5617],
            [-74.8008, 39.5835],
            [-74.7803, 39.6012],
            [-74.6729, 39.5266],
            [-74.5863, 39.4664],
            [-74.7178, 39.404],
            [-74.7552, 39.4403],
            [-74.8377, 39.4244],
            [-74.8694, 39.5243],
            [-74.8259, 39.5617],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406510',
        NAME: 'Hamilton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 287828907,
        AWATER: 5022530,
        INTPTLAT: '+39.4816025',
        INTPTLON: '-074.7374072',
        ELSDLEA: '06510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.58, 39.3682],
            [-74.5503, 39.3538],
            [-74.569, 39.3213],
            [-74.5961, 39.3361],
            [-74.58, 39.3682],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408670',
        NAME: 'Linwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10001495,
        AWATER: 985892,
        INTPTLAT: '+39.3437182',
        INTPTLON: '-074.5710495',
        ELSDLEA: '08670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6758, 39.6913],
            [-74.5781, 39.6001],
            [-74.6568, 39.5325],
            [-74.6729, 39.5266],
            [-74.7803, 39.6012],
            [-74.6758, 39.6913],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411070',
        NAME: 'Mullica Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146130339,
        AWATER: 1246390,
        INTPTLAT: '+39.5964863',
        INTPTLON: '-074.6764997',
        ELSDLEA: '11070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5961, 39.3361],
            [-74.569, 39.3213],
            [-74.5864, 39.3099],
            [-74.5988, 39.3052],
            [-74.626, 39.2928],
            [-74.5961, 39.3361],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415030',
        NAME: 'Somers Point City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10434881,
        AWATER: 2925999,
        INTPTLAT: '+39.3160799',
        INTPTLON: '-074.6067702',
        ELSDLEA: '15030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5505, 39.3865],
            [-74.5321, 39.375],
            [-74.5503, 39.3538],
            [-74.58, 39.3682],
            [-74.5505, 39.3865],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411790',
        NAME: 'Northfield City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8815666,
        AWATER: 102340,
        INTPTLAT: '+39.3710580',
        INTPTLON: '-074.5548216',
        ELSDLEA: '11790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1742, 39.5173],
            [-75.084, 39.4574],
            [-75.0849, 39.444],
            [-75.146, 39.4309],
            [-75.1677, 39.4317],
            [-75.1742, 39.5173],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403660',
        NAME: 'Deerfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43411116,
        AWATER: 89443,
        INTPTLAT: '+39.4590432',
        INTPTLON: '-075.1319867',
        ELSDLEA: '03660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3179, 41.0684],
            [-74.2506, 41.0607],
            [-74.2705, 41.0221],
            [-74.303, 41.0114],
            [-74.3179, 41.0684],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416950',
        NAME: 'Wanaque Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20743556,
        AWATER: 3221465,
        INTPTLAT: '+41.0424238',
        INTPTLON: '-074.2886433',
        ELSDLEA: '16950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.28, 41.163],
            [-74.2345, 41.1429],
            [-74.2133, 41.134],
            [-74.2489, 41.0638],
            [-74.2506, 41.0607],
            [-74.3179, 41.0684],
            [-74.3462, 41.0608],
            [-74.28, 41.163],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413860',
        NAME: 'Ringwood Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65318690,
        AWATER: 7647555,
        INTPTLAT: '+41.1039627',
        INTPTLON: '-074.2711379',
        ELSDLEA: '13860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1747, 40.946],
            [-74.1695, 40.9466],
            [-74.1666, 40.9343],
            [-74.1768, 40.9335],
            [-74.1747, 40.946],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413470',
        NAME: 'Prospect Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1231691,
        AWATER: 6146,
        INTPTLAT: '+40.9415204',
        INTPTLON: '-074.1744353',
        ELSDLEA: '13470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1918, 40.9787],
            [-74.171, 40.977],
            [-74.1695, 40.9466],
            [-74.1747, 40.946],
            [-74.1963, 40.9462],
            [-74.2117, 40.9802],
            [-74.1918, 40.9787],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411550',
        NAME: 'North Haledon Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8950071,
        AWATER: 114994,
        INTPTLAT: '+40.9636178',
        INTPTLON: '-074.1852806',
        ELSDLEA: '11550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2033, 40.9245],
            [-74.1963, 40.9462],
            [-74.1747, 40.946],
            [-74.1768, 40.9335],
            [-74.1989, 40.9247],
            [-74.2033, 40.9245],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406450',
        NAME: 'Haledon Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3009080,
        AWATER: 2526,
        INTPTLAT: '+40.9369885',
        INTPTLON: '-074.1888673',
        ELSDLEA: '06450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1958, 40.9076],
            [-74.1746, 40.8994],
            [-74.1908, 40.8724],
            [-74.2193, 40.8908],
            [-74.1958, 40.9076],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417640',
        NAME: 'Woodland Park Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7599018,
        AWATER: 387754,
        INTPTLAT: '+40.8899082',
        INTPTLON: '-074.1945807',
        ELSDLEA: '17640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2033, 40.9245],
            [-74.1989, 40.9247],
            [-74.1958, 40.9076],
            [-74.2193, 40.8908],
            [-74.2306, 40.8831],
            [-74.2033, 40.9245],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416260',
        NAME: 'Totowa Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10354901,
        AWATER: 178581,
        INTPTLAT: '+40.9032664',
        INTPTLON: '-074.2213719',
        ELSDLEA: '16260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2545, 40.8831],
            [-74.2306, 40.8831],
            [-74.2193, 40.8908],
            [-74.1908, 40.8724],
            [-74.1965, 40.8598],
            [-74.2048, 40.8609],
            [-74.2467, 40.8808],
            [-74.2545, 40.8831],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408730',
        NAME: 'Little Falls Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7177595,
        AWATER: 181371,
        INTPTLAT: '+40.8762350',
        INTPTLON: '-074.2188858',
        ELSDLEA: '08730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9348, 38.9327],
            [-74.926, 38.9442],
            [-74.8687, 38.9428],
            [-74.9388, 38.9316],
            [-74.9348, 38.9327],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402700',
        NAME: 'Cape May City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6225728,
        AWATER: 876878,
        INTPTLAT: '+38.9415580',
        INTPTLON: '-074.9004831',
        ELSDLEA: '02700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1008, 40.6384],
            [-75.0459, 40.6311],
            [-74.9578, 40.5834],
            [-74.9904, 40.5603],
            [-75.0464, 40.5374],
            [-75.0668, 40.5365],
            [-75.0872, 40.5563],
            [-75.0837, 40.5597],
            [-75.1008, 40.6384],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400690',
        NAME: 'Alexandria Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71021491,
        AWATER: 561697,
        INTPTLAT: '+40.5945319',
        INTPTLON: '-075.0265659',
        ELSDLEA: '00690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1099, 40.6389],
            [-75.1084, 40.6403],
            [-75.0671, 40.6563],
            [-75.0719, 40.6663],
            [-74.9833, 40.7054],
            [-74.9813, 40.7062],
            [-74.9525, 40.7034],
            [-74.9339, 40.687],
            [-74.9229, 40.6805],
            [-75.0459, 40.6311],
            [-75.1008, 40.6384],
            [-75.1099, 40.6389],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401710',
        NAME: 'Bethlehem Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53641604,
        AWATER: 302587,
        INTPTLAT: '+40.6650355',
        INTPTLON: '-075.0119350',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9173, 40.671],
            [-74.9121, 40.6701],
            [-74.8934, 40.6557],
            [-74.8744, 40.6633],
            [-74.8907, 40.6807],
            [-74.8434, 40.6929],
            [-74.7886, 40.6534],
            [-74.8686, 40.5748],
            [-74.8781, 40.5795],
            [-74.9095, 40.6256],
            [-74.917, 40.6448],
            [-74.9173, 40.671],
          ],
          [
            [-74.8477, 40.6495],
            [-74.8367, 40.6352],
            [-74.8221, 40.64],
            [-74.8317, 40.6486],
            [-74.8477, 40.6495],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403360',
        NAME: 'Clinton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77379554,
        AWATER: 10223268,
        INTPTLAT: '+40.6300981',
        INTPTLON: '-074.8672023',
        ELSDLEA: '03360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0438, 40.4124],
            [-74.9533, 40.5034],
            [-74.9369, 40.5202],
            [-74.874, 40.4468],
            [-74.9129, 40.4189],
            [-74.9468, 40.3856],
            [-74.9541, 40.3765],
            [-74.9666, 40.3982],
            [-74.9654, 40.4063],
            [-74.986, 40.4068],
            [-75.0412, 40.4105],
            [-75.0438, 40.4124],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403720',
        NAME: 'Delaware Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94917559,
        AWATER: 1001477,
        INTPTLAT: '+40.4397619',
        INTPTLON: '-074.9563288',
        ELSDLEA: '03720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.874, 40.4468],
            [-74.7825, 40.4878],
            [-74.752, 40.4314],
            [-74.7481, 40.4242],
            [-74.8304, 40.3821],
            [-74.9129, 40.4189],
            [-74.874, 40.4468],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404080',
        NAME: 'East Amwell Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73707654,
        AWATER: 264277,
        INTPTLAT: '+40.4349925',
        INTPTLON: '-074.8230299',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8781, 40.5795],
            [-74.8686, 40.5748],
            [-74.7978, 40.5156],
            [-74.7825, 40.4878],
            [-74.874, 40.4468],
            [-74.9369, 40.5202],
            [-74.8781, 40.5795],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405190',
        NAME: 'Flemington-Raritan Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99999294,
        AWATER: 415510,
        INTPTLAT: '+40.5075374',
        INTPTLON: '-074.8645946',
        ELSDLEA: '05190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9578, 40.5834],
            [-74.9195, 40.6312],
            [-74.9095, 40.6256],
            [-74.8781, 40.5795],
            [-74.9369, 40.5202],
            [-74.9533, 40.5034],
            [-74.9904, 40.5603],
            [-74.9578, 40.5834],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405460',
        NAME: 'Franklin Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 59058190,
        AWATER: 505064,
        INTPTLAT: '+40.5690334',
        INTPTLON: '-074.9312269',
        ELSDLEA: '05460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8333, 40.725],
            [-74.8232, 40.7145],
            [-74.8362, 40.7111],
            [-74.8333, 40.725],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402610',
        NAME: 'Califon Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2458993,
        AWATER: 59402,
        INTPTLAT: '+40.7193807',
        INTPTLON: '-074.8367928',
        ELSDLEA: '02610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.917, 40.6448],
              [-74.9095, 40.6256],
              [-74.9195, 40.6312],
              [-74.917, 40.6448],
            ],
          ],
          [
            [
              [-74.9491, 40.7108],
              [-74.9339, 40.687],
              [-74.9525, 40.7034],
              [-74.9491, 40.7108],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403330',
        NAME: 'Clinton Town-Glen Gardner School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7414475,
        AWATER: 205185,
        INTPTLAT: '+40.6900134',
        INTPTLON: '-074.9398233',
        ELSDLEA: '03330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9353, 39.039],
            [-74.8333, 38.9918],
            [-74.8331, 38.9858],
            [-74.8468, 38.9577],
            [-74.8687, 38.9428],
            [-74.926, 38.9442],
            [-74.9377, 38.9497],
            [-74.9517, 38.9401],
            [-74.9348, 38.9327],
            [-74.9388, 38.9316],
            [-74.9598, 38.9311],
            [-74.9609, 38.9423],
            [-74.972, 38.9404],
            [-74.9353, 39.039],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409150',
        NAME: 'Lower Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71852359,
        AWATER: 8474707,
        INTPTLAT: '+38.9837684',
        INTPTLON: '-074.9113078',
        ELSDLEA: '09150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9813, 40.7062],
            [-74.9678, 40.7121],
            [-74.9491, 40.7108],
            [-74.9525, 40.7034],
            [-74.9813, 40.7062],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406600',
        NAME: 'Hampton Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3965398,
        AWATER: 35569,
        INTPTLAT: '+40.7045503',
        INTPTLON: '-074.9715705',
        ELSDLEA: '06600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0668, 40.5365],
            [-75.0464, 40.5374],
            [-75.0654, 40.5176],
            [-75.0668, 40.5365],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405700',
        NAME: 'Frenchtown Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3274833,
        AWATER: 252740,
        INTPTLAT: '+40.5312177',
        INTPTLON: '-075.0513677',
        ELSDLEA: '05700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.926, 38.9442],
            [-74.9348, 38.9327],
            [-74.9517, 38.9401],
            [-74.9377, 38.9497],
            [-74.926, 38.9442],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417400',
        NAME: 'West Cape May Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3018086,
        AWATER: 26142,
        INTPTLAT: '+38.9422264',
        INTPTLON: '-074.9390332',
        ELSDLEA: '17400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1556, 41.0258],
            [-74.1503, 41.0405],
            [-74.1146, 41.0436],
            [-74.1213, 41.0199],
            [-74.1476, 41.0221],
            [-74.1556, 41.0258],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400750',
        NAME: 'Allendale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8019272,
        AWATER: 55900,
        INTPTLAT: '+41.0326690',
        INTPTLON: '-074.1338262',
        ELSDLEA: '00750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9937, 40.9754],
            [-73.9635, 40.9843],
            [-73.9343, 40.977],
            [-73.945, 40.9575],
            [-73.9747, 40.9676],
            [-74.0018, 40.9708],
            [-73.9937, 40.9754],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403390',
        NAME: 'Closter Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8191006,
        AWATER: 344255,
        INTPTLAT: '+40.9728898',
        INTPTLON: '-073.9603147',
        ELSDLEA: '03390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9747, 40.9676],
            [-73.945, 40.9575],
            [-73.9425, 40.9429],
            [-73.9757, 40.9531],
            [-73.9758, 40.9541],
            [-73.9747, 40.9676],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403810',
        NAME: 'Demarest Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5352666,
        AWATER: 29387,
        INTPTLAT: '+40.9545757',
        INTPTLON: '-073.9565634',
        ELSDLEA: '03810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2705, 41.0221],
            [-74.2506, 41.0607],
            [-74.2489, 41.0638],
            [-74.2018, 41.0438],
            [-74.2485, 40.9923],
            [-74.2667, 41.0141],
            [-74.2705, 41.0221],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411910',
        NAME: 'Oakland Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21898009,
        AWATER: 708199,
        INTPTLAT: '+41.0294971',
        INTPTLON: '-074.2430679',
        ELSDLEA: '11910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0719, 41.0729],
            [-74.0509, 41.0635],
            [-74.0126, 41.0464],
            [-74.0215, 41.0377],
            [-74.0697, 41.0415],
            [-74.0794, 41.0485],
            [-74.0719, 41.0729],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410620',
        NAME: 'Montvale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10311778,
        AWATER: 23765,
        INTPTLAT: '+41.0545938',
        INTPTLON: '-074.0472980',
        ELSDLEA: '10620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1676, 41.0238],
            [-74.1556, 41.0258],
            [-74.1476, 41.0221],
            [-74.1375, 41.0075],
            [-74.1412, 40.9828],
            [-74.1491, 40.9711],
            [-74.171, 40.977],
            [-74.1918, 40.9787],
            [-74.1676, 41.0238],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418360',
        NAME: 'Wyckoff Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16940214,
        AWATER: 155128,
        INTPTLAT: '+40.9990933',
        INTPTLON: '-074.1688486',
        ELSDLEA: '18360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9938, 41.0382],
            [-73.9665, 41.026],
            [-73.959, 41.0225],
            [-73.9653, 41.0117],
            [-73.9687, 41.0068],
            [-73.9855, 40.9992],
            [-73.9938, 41.0382],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412180',
        NAME: 'Old Tappan Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8626094,
        AWATER: 2244517,
        INTPTLAT: '+41.0232821',
        INTPTLON: '-073.9842418',
        ELSDLEA: '12180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0761, 41.0147],
            [-74.0264, 41.0206],
            [-74.0199, 41.0206],
            [-74.0257, 40.9966],
            [-74.0773, 41.0045],
            [-74.0761, 41.0147],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407260',
        NAME: 'Hillsdale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7649245,
        AWATER: 112511,
        INTPTLAT: '+41.0071268',
        INTPTLON: '-074.0451189',
        ELSDLEA: '07260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0164, 40.9683],
            [-74.0018, 40.9708],
            [-73.9747, 40.9676],
            [-73.9758, 40.9541],
            [-74.0032, 40.9551],
            [-74.0164, 40.9683],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406960',
        NAME: 'Haworth Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5036528,
        AWATER: 1065006,
        INTPTLAT: '+40.9617128',
        INTPTLON: '-073.9974371',
        ELSDLEA: '06960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0989, 40.8504],
            [-74.0761, 40.8376],
            [-74.0531, 40.8337],
            [-74.035, 40.8253],
            [-74.0321, 40.8129],
            [-74.045, 40.8077],
            [-74.0607, 40.8057],
            [-74.1028, 40.8415],
            [-74.0989, 40.8504],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402790',
        NAME: 'Carlstadt Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10360144,
        AWATER: 631502,
        INTPTLAT: '+40.8264241',
        INTPTLON: '-074.0623382',
        ELSDLEA: '02790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.049, 40.9653],
            [-74.0164, 40.9683],
            [-74.0032, 40.9551],
            [-74.0026, 40.9521],
            [-74.0261, 40.9401],
            [-74.0388, 40.9437],
            [-74.049, 40.9653],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412240',
        NAME: 'Oradell Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6277709,
        AWATER: 395039,
        INTPTLAT: '+40.9566509',
        INTPTLON: '-074.0328576',
        ELSDLEA: '12240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1164, 41.0728],
            [-74.1038, 41.0869],
            [-74.0912, 41.0813],
            [-74.0719, 41.0729],
            [-74.0794, 41.0485],
            [-74.084, 41.0438],
            [-74.1144, 41.0457],
            [-74.1164, 41.0728],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416620',
        NAME: 'Upper Saddle River Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13625940,
        AWATER: 51455,
        INTPTLAT: '+41.0631570',
        INTPTLON: '-074.0999764',
        ELSDLEA: '16620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1195, 40.8533],
            [-74.1028, 40.8415],
            [-74.0607, 40.8057],
            [-74.0818, 40.788],
            [-74.1214, 40.8472],
            [-74.1195, 40.8533],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404290',
        NAME: 'East Rutherford Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9635427,
        AWATER: 855663,
        INTPTLAT: '+40.8170966',
        INTPTLON: '-074.0850235',
        ELSDLEA: '04290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0388, 40.9437],
            [-74.0261, 40.9401],
            [-74.0302, 40.9133],
            [-74.0302, 40.9132],
            [-74.0418, 40.9114],
            [-74.0388, 40.9437],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413890',
        NAME: 'River Edge Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4802371,
        AWATER: 106233,
        INTPTLAT: '+40.9267663',
        INTPTLON: '-074.0374682',
        ELSDLEA: '13890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0199, 41.0206],
            [-74.0215, 41.0377],
            [-74.0126, 41.0464],
            [-73.9938, 41.0382],
            [-73.9855, 40.9992],
            [-73.9979, 40.9815],
            [-74.0043, 40.9872],
            [-74.0257, 40.9966],
            [-74.0199, 41.0206],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413950',
        NAME: 'River Vale Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10389663,
        AWATER: 682362,
        INTPTLAT: '+41.0142014',
        INTPTLON: '-074.0067739',
        ELSDLEA: '13950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.959, 41.0225],
            [-73.9384, 41.0133],
            [-73.9416, 40.9953],
            [-73.9653, 41.0117],
            [-73.959, 41.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411820',
        NAME: 'Northvale Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3354978,
        AWATER: 6891,
        INTPTLAT: '+41.0130002',
        INTPTLON: '-073.9480850',
        ELSDLEA: '11820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9855, 40.9992],
            [-73.9687, 41.0068],
            [-73.9635, 40.9843],
            [-73.9937, 40.9754],
            [-73.9979, 40.9815],
            [-73.9855, 40.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406840',
        NAME: 'Harrington Park Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4744680,
        AWATER: 588649,
        INTPTLAT: '+40.9916807',
        INTPTLON: '-073.9802022',
        ELSDLEA: '06840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9687, 41.0068],
            [-73.9653, 41.0117],
            [-73.9416, 40.9953],
            [-73.926, 40.9936],
            [-73.9343, 40.977],
            [-73.9635, 40.9843],
            [-73.9687, 41.0068],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411850',
        NAME: 'Norwood Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7065072,
        AWATER: 17107,
        INTPTLAT: '+40.9922519',
        INTPTLON: '-073.9509167',
        ELSDLEA: '11850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2018, 41.0438],
            [-74.1676, 41.0238],
            [-74.1918, 40.9787],
            [-74.2117, 40.9802],
            [-74.2485, 40.9923],
            [-74.2018, 41.0438],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405370',
        NAME: 'Franklin Lakes Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24297053,
        AWATER: 1208444,
        INTPTLAT: '+41.0075259',
        INTPTLON: '-074.2056527',
        ELSDLEA: '05370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.084, 41.0438],
            [-74.0794, 41.0485],
            [-74.0697, 41.0415],
            [-74.0264, 41.0206],
            [-74.0761, 41.0147],
            [-74.084, 41.0438],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418210',
        NAME: 'Woodcliff Lake Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8854887,
        AWATER: 426816,
        INTPTLAT: '+41.0259773',
        INTPTLON: '-074.0610613',
        ELSDLEA: '18210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8866, 41.0201],
            [-74.8798, 41.0139],
            [-74.8592, 40.9949],
            [-74.8217, 40.9607],
            [-74.897, 40.9272],
            [-74.9279, 40.9068],
            [-74.9395, 40.9459],
            [-74.8866, 41.0201],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405670',
        NAME: 'Frelinghuysen Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60406064,
        AWATER: 628016,
        INTPTLAT: '+40.9594858',
        INTPTLON: '-074.8951540',
        ELSDLEA: '05670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1315, 40.9893],
            [-74.9669, 41.0945],
            [-74.939, 41.0687],
            [-74.8866, 41.0201],
            [-74.9395, 40.9459],
            [-75.0081, 40.9197],
            [-75.1213, 40.9692],
            [-75.1315, 40.9893],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401800',
        NAME: 'Blairstown Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174644718,
        AWATER: 5689021,
        INTPTLAT: '+41.0015738',
        INTPTLON: '-075.0026817',
        ELSDLEA: '01800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0416, 40.7685],
            [-75.0199, 40.7843],
            [-74.9828, 40.7998],
            [-74.9305, 40.7516],
            [-74.9678, 40.7121],
            [-74.9813, 40.7062],
            [-74.9833, 40.7054],
            [-75.0416, 40.7685],
          ],
          [
            [-74.9994, 40.7604],
            [-74.9936, 40.7447],
            [-74.9621, 40.7591],
            [-74.9684, 40.7685],
            [-74.9994, 40.7604],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417160',
        NAME: 'Washington Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 45744890,
        AWATER: 230216,
        INTPTLAT: '+40.7299444',
        INTPTLON: '-074.9589720',
        ELSDLEA: '17160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9828, 40.7998],
            [-74.9532, 40.8327],
            [-74.8505, 40.8413],
            [-74.8317, 40.8281],
            [-74.8898, 40.7878],
            [-74.9305, 40.7516],
            [-74.9828, 40.7998],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409540',
        NAME: 'Mansfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 77227771,
        AWATER: 288746,
        INTPTLAT: '+40.8084661',
        INTPTLON: '-074.9102300',
        ELSDLEA: '09540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.105, 40.7192],
            [-75.0416, 40.7685],
            [-74.9833, 40.7054],
            [-75.0719, 40.6663],
            [-75.0792, 40.6599],
            [-75.105, 40.7192],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405520',
        NAME: 'Franklin Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 62263764,
        AWATER: 225449,
        INTPTLAT: '+40.7090304',
        INTPTLON: '-075.0458790',
        ELSDLEA: '05520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9994, 40.7604],
            [-74.9684, 40.7685],
            [-74.9621, 40.7591],
            [-74.9936, 40.7447],
            [-74.9994, 40.7604],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417010',
        NAME: 'Washington Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5030053,
        AWATER: 8997,
        INTPTLAT: '+40.7585245',
        INTPTLON: '-074.9831897',
        ELSDLEA: '17010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0081, 40.9197],
            [-75.0222, 40.8758],
            [-75.054, 40.8592],
            [-75.1205, 40.9684],
            [-75.1213, 40.9692],
            [-75.0081, 40.9197],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408070',
        NAME: 'Knowlton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64099516,
        AWATER: 1502855,
        INTPTLAT: '+40.9292610',
        INTPTLON: '-075.0617961',
        ELSDLEA: '08070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.677, 40.9109],
              [-74.6832, 40.9101],
              [-74.6854, 40.9137],
              [-74.677, 40.9109],
            ],
          ],
          [
            [
              [-74.7892, 40.9313],
              [-74.7406, 40.9687],
              [-74.6884, 41.0091],
              [-74.6474, 40.9995],
              [-74.6973, 40.9251],
              [-74.7201, 40.9132],
              [-74.7679, 40.9118],
              [-74.7892, 40.9313],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402550',
        NAME: 'Byram Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54611886,
        AWATER: 3078491,
        INTPTLAT: '+40.9603346',
        INTPTLON: '-074.7129042',
        ELSDLEA: '02550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7168, 41.2216],
            [-74.6548, 41.1618],
            [-74.7204, 41.1123],
            [-74.8466, 41.1478],
            [-74.7168, 41.2216],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405340',
        NAME: 'Frankford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89649562,
        AWATER: 3688962,
        INTPTLAT: '+41.1642960',
        INTPTLON: '-074.7377981',
        ELSDLEA: '05340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5701, 41.1369],
            [-74.5774, 41.1382],
            [-74.5829, 41.1559],
            [-74.5692, 41.1596],
            [-74.5701, 41.1369],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406480',
        NAME: 'Hamburg Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2958014,
        AWATER: 59008,
        INTPTLAT: '+41.1487416',
        INTPTLON: '-074.5733826',
        ELSDLEA: '06480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5774, 41.1382],
            [-74.5701, 41.1369],
            [-74.5879, 41.0849],
            [-74.6022, 41.0931],
            [-74.6073, 41.0979],
            [-74.5774, 41.1382],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405400',
        NAME: 'Franklin Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11298899,
        AWATER: 185638,
        INTPTLAT: '+41.1089970',
        INTPTLON: '-074.5886407',
        ELSDLEA: '05400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8798, 41.0139],
            [-74.8113, 41.0595],
            [-74.7629, 41.0688],
            [-74.7664, 41.0389],
            [-74.7874, 41.0054],
            [-74.8592, 40.9949],
            [-74.8798, 41.0139],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405550',
        NAME: 'Fredon Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45808035,
        AWATER: 717702,
        INTPTLAT: '+41.0246749',
        INTPTLON: '-074.8182239',
        ELSDLEA: '05550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6245, 41.1612],
            [-74.5753, 41.1937],
            [-74.4826, 41.1038],
            [-74.5029, 41.0859],
            [-74.536, 41.0585],
            [-74.577, 41.079],
            [-74.5879, 41.0849],
            [-74.5701, 41.1369],
            [-74.5692, 41.1596],
            [-74.5829, 41.1559],
            [-74.5774, 41.1382],
            [-74.6073, 41.0979],
            [-74.6386, 41.1186],
            [-74.6245, 41.1612],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406780',
        NAME: 'Hardyston Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83288816,
        AWATER: 1714929,
        INTPTLAT: '+41.1209637',
        INTPTLON: '-074.5643282',
        ELSDLEA: '06780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8823, 41.1806],
            [-74.836, 41.279],
            [-74.7168, 41.2216],
            [-74.8466, 41.1478],
            [-74.85, 41.1459],
            [-74.939, 41.0687],
            [-74.9669, 41.0945],
            [-74.8823, 41.1806],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414610',
        NAME: 'Sandyston-Walpack Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170593548,
        AWATER: 3570181,
        INTPTLAT: '+41.1862500',
        INTPTLON: '-074.8281371',
        ELSDLEA: '14610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.649, 41.3364],
            [-74.5368, 41.2847],
            [-74.5367, 41.2847],
            [-74.5753, 41.1937],
            [-74.6245, 41.1612],
            [-74.6548, 41.1618],
            [-74.7168, 41.2216],
            [-74.649, 41.3364],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415960',
        NAME: 'Sussex-Wantage Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174415854,
        AWATER: 1963842,
        INTPTLAT: '+41.2522038',
        INTPTLON: '-074.6328346',
        ELSDLEA: '15960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6022, 41.0931],
            [-74.5879, 41.0849],
            [-74.577, 41.079],
            [-74.6038, 41.0647],
            [-74.6022, 41.0931],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412150',
        NAME: 'Ogdensburg Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5818756,
        AWATER: 117747,
        INTPTLAT: '+41.0800905',
        INTPTLON: '-074.5976262',
        ELSDLEA: '12150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7204, 41.1123],
            [-74.6548, 41.1618],
            [-74.6245, 41.1612],
            [-74.6386, 41.1186],
            [-74.6855, 41.0519],
            [-74.7116, 41.0828],
            [-74.7204, 41.1123],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408130',
        NAME: 'Lafayette Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46425446,
        AWATER: 225948,
        INTPTLAT: '+41.1117409',
        INTPTLON: '-074.6775620',
        ELSDLEA: '08130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6973, 40.9251],
            [-74.6854, 40.9137],
            [-74.6832, 40.9101],
            [-74.6922, 40.9044],
            [-74.7134, 40.9024],
            [-74.7201, 40.9132],
            [-74.6973, 40.9251],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415720',
        NAME: 'Stanhope Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4754579,
        AWATER: 912134,
        INTPTLAT: '+40.9129757',
        INTPTLON: '-074.7029140',
        ELSDLEA: '15720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8466, 41.1478],
            [-74.7204, 41.1123],
            [-74.7116, 41.0828],
            [-74.7361, 41.0604],
            [-74.7629, 41.0688],
            [-74.8113, 41.0595],
            [-74.85, 41.1459],
            [-74.8466, 41.1478],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406630',
        NAME: 'Hampton Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63245543,
        AWATER: 2382321,
        INTPTLAT: '+41.0917038',
        INTPTLON: '-074.7895981',
        ELSDLEA: '06630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.85, 41.1459],
            [-74.8113, 41.0595],
            [-74.8798, 41.0139],
            [-74.8866, 41.0201],
            [-74.939, 41.0687],
            [-74.85, 41.1459],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415750',
        NAME: 'Stillwater Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69023917,
        AWATER: 3426738,
        INTPTLAT: '+41.0741250',
        INTPTLON: '-074.8688779',
        ELSDLEA: '15750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7255, 40.8055],
            [-74.6817, 40.8279],
            [-74.6454, 40.8369],
            [-74.6309, 40.8047],
            [-74.6573, 40.7352],
            [-74.6825, 40.7294],
            [-74.7258, 40.7195],
            [-74.7255, 40.8055],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403060',
        NAME: 'Chester Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80411788,
        AWATER: 224869,
        INTPTLAT: '+40.7811961',
        INTPTLON: '-074.6839453',
        ELSDLEA: '03060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5249, 40.8806],
            [-74.5228, 40.8835],
            [-74.4974, 40.8952],
            [-74.5034, 40.9078],
            [-74.4515, 40.9343],
            [-74.4406, 40.9072],
            [-74.4658, 40.885],
            [-74.534, 40.8331],
            [-74.5249, 40.8806],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403870',
        NAME: 'Denville Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31017770,
        AWATER: 1999976,
        INTPTLAT: '+40.8884789',
        INTPTLON: '-074.4883768',
        ELSDLEA: '03870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.384, 40.825],
            [-74.342, 40.8468],
            [-74.3299, 40.8492],
            [-74.3285, 40.8447],
            [-74.3292, 40.8383],
            [-74.3343, 40.8184],
            [-74.3653, 40.7955],
            [-74.4108, 40.7926],
            [-74.384, 40.825],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404170',
        NAME: 'East Hanover Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20401299,
        AWATER: 615530,
        INTPTLAT: '+40.8185531',
        INTPTLON: '-074.3637423',
        ELSDLEA: '04170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4342, 40.7911],
            [-74.4108, 40.7926],
            [-74.3653, 40.7955],
            [-74.3673, 40.7611],
            [-74.3651, 40.7497],
            [-74.3921, 40.7514],
            [-74.4408, 40.7774],
            [-74.4342, 40.7911],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405250',
        NAME: 'Florham Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18947263,
        AWATER: 489463,
        INTPTLAT: '+40.7758324',
        INTPTLON: '-074.3940582',
        ELSDLEA: '05250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4744, 40.8198],
            [-74.4642, 40.8358],
            [-74.384, 40.825],
            [-74.4108, 40.7926],
            [-74.4342, 40.7911],
            [-74.4744, 40.8198],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406690',
        NAME: 'Hanover Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27259516,
        AWATER: 522851,
        INTPTLAT: '+40.8199503',
        INTPTLON: '-074.4279856',
        ELSDLEA: '06690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5708, 40.7549],
            [-74.6185, 40.7441],
            [-74.6185, 40.7874],
            [-74.5867, 40.793],
            [-74.5708, 40.7549],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409900',
        NAME: 'Mendham Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15411403,
        AWATER: 129989,
        INTPTLAT: '+40.7674871',
        INTPTLON: '-074.5972190',
        ELSDLEA: '09900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6309, 40.8047],
            [-74.5361, 40.8221],
            [-74.5422, 40.7894],
            [-74.5501, 40.7474],
            [-74.5708, 40.7549],
            [-74.5867, 40.793],
            [-74.6185, 40.7874],
            [-74.6185, 40.7441],
            [-74.6573, 40.7352],
            [-74.6309, 40.8047],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409930',
        NAME: 'Mendham Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46056525,
        AWATER: 594153,
        INTPTLAT: '+40.8012619',
        INTPTLON: '-074.5701219',
        ELSDLEA: '09930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5137, 40.6977],
            [-74.4497, 40.7188],
            [-74.4443, 40.688],
            [-74.4612, 40.6734],
            [-74.5238, 40.6496],
            [-74.5137, 40.6977],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412660',
        NAME: 'Long Hill Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30744335,
        AWATER: 738643,
        INTPTLAT: '+40.6848273',
        INTPTLON: '-074.4920686',
        ELSDLEA: '12660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.441, 41.045],
            [-74.4302, 41.0202],
            [-74.4286, 40.9582],
            [-74.4515, 40.9343],
            [-74.5034, 40.9078],
            [-74.5228, 40.8835],
            [-74.5249, 40.8806],
            [-74.5401, 40.8839],
            [-74.5681, 40.9003],
            [-74.5944, 40.9023],
            [-74.441, 41.045],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414130',
        NAME: 'Rockaway Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107868261,
        AWATER: 10759599,
        INTPTLAT: '+40.9590480',
        INTPTLON: '-074.4988898',
        ELSDLEA: '14130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8898, 40.7878],
            [-74.8317, 40.8281],
            [-74.8217, 40.8485],
            [-74.7255, 40.8055],
            [-74.7258, 40.7195],
            [-74.8241, 40.7424],
            [-74.8898, 40.7878],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417130',
        NAME: 'Washington Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 115157312,
        AWATER: 955698,
        INTPTLAT: '+40.7846338',
        INTPTLON: '-074.7977665',
        ELSDLEA: '17130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5034, 40.9078],
            [-74.4974, 40.8952],
            [-74.5228, 40.8835],
            [-74.5034, 40.9078],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414100',
        NAME: 'Rockaway Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5367170,
        AWATER: 120749,
        INTPTLAT: '+40.8958526',
        INTPTLON: '-074.5173232',
        ELSDLEA: '14100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7134, 40.9024],
            [-74.6922, 40.9044],
            [-74.7018, 40.8911],
            [-74.7134, 40.9024],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411190',
        NAME: 'Netcong Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2186519,
        AWATER: 189968,
        INTPTLAT: '+40.8978524',
        INTPTLON: '-074.7017038',
        ELSDLEA: '11190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5946, 40.9021],
            [-74.5944, 40.9023],
            [-74.5681, 40.9003],
            [-74.5782, 40.886],
            [-74.5965, 40.901],
            [-74.5946, 40.9021],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417880',
        NAME: 'Wharton Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5361000,
        AWATER: 170700,
        INTPTLAT: '+40.8970134',
        INTPTLON: '-074.5744543',
        ELSDLEA: '17880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5238, 40.6496],
            [-74.4612, 40.6734],
            [-74.4485, 40.6554],
            [-74.4824, 40.6213],
            [-74.5177, 40.5874],
            [-74.5631, 40.6107],
            [-74.5238, 40.6496],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416980',
        NAME: 'Warren Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50636384,
        AWATER: 199305,
        INTPTLAT: '+40.6345884',
        INTPTLON: '-074.5190442',
        ELSDLEA: '16980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4824, 40.6213],
            [-74.4485, 40.6554],
            [-74.4034, 40.6665],
            [-74.4083, 40.6442],
            [-74.4137, 40.641],
            [-74.4417, 40.6294],
            [-74.4824, 40.6213],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417190',
        NAME: 'Watchung Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15607360,
        AWATER: 71737,
        INTPTLAT: '+40.6428449',
        INTPTLON: '-074.4362333',
        ELSDLEA: '17190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3228, 40.9088],
            [-74.2721, 40.8974],
            [-74.2545, 40.8831],
            [-74.2702, 40.8598],
            [-74.3285, 40.8447],
            [-74.3299, 40.8492],
            [-74.3228, 40.9088],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405010',
        NAME: 'Fairfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 26668102,
        AWATER: 424984,
        INTPTLAT: '+40.8825075',
        INTPTLON: '-074.3045935',
        ELSDLEA: '05010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2545, 40.8831],
            [-74.2467, 40.8808],
            [-74.2519, 40.8498],
            [-74.2598, 40.8349],
            [-74.2637, 40.8352],
            [-74.2702, 40.8598],
            [-74.2545, 40.8831],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411520',
        NAME: 'North Caldwell Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7796666,
        AWATER: 11723,
        INTPTLAT: '+40.8645004',
        INTPTLON: '-074.2599958',
        ELSDLEA: '11520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3292, 40.8383],
            [-74.2983, 40.8304],
            [-74.2763, 40.8139],
            [-74.2806, 40.807],
            [-74.3343, 40.8184],
            [-74.3292, 40.8383],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414250',
        NAME: 'Roseland Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9158210,
        AWATER: 51261,
        INTPTLAT: '+40.8217373',
        INTPTLON: '-074.3101465',
        ELSDLEA: '14250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1911, 40.5859],
            [-75.1896, 40.5918],
            [-75.1099, 40.6389],
            [-75.1008, 40.6384],
            [-75.0837, 40.5597],
            [-75.1013, 40.568],
            [-75.1911, 40.5859],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407380',
        NAME: 'Holland Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60902817,
        AWATER: 1317936,
        INTPTLAT: '+40.5980387',
        INTPTLON: '-075.1241652',
        ELSDLEA: '07380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0654, 40.5176],
            [-75.0464, 40.5374],
            [-74.9904, 40.5603],
            [-74.9533, 40.5034],
            [-75.0438, 40.4124],
            [-75.0607, 40.4213],
            [-75.0654, 40.5176],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408010',
        NAME: 'Kingwood Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91062065,
        AWATER: 1592467,
        INTPTLAT: '+40.4947043',
        INTPTLON: '-075.0194612',
        ELSDLEA: '08010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9305, 40.7516],
            [-74.8898, 40.7878],
            [-74.8241, 40.7424],
            [-74.8333, 40.725],
            [-74.8362, 40.7111],
            [-74.8434, 40.6929],
            [-74.8907, 40.6807],
            [-74.9121, 40.6701],
            [-74.9173, 40.671],
            [-74.9229, 40.6805],
            [-74.9339, 40.687],
            [-74.9491, 40.7108],
            [-74.9678, 40.7121],
            [-74.9305, 40.7516],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408460',
        NAME: 'Lebanon Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81474537,
        AWATER: 615975,
        INTPTLAT: '+40.7263694',
        INTPTLON: '-074.8944081',
        ELSDLEA: '08460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8686, 40.5748],
            [-74.7886, 40.6534],
            [-74.7237, 40.6469],
            [-74.7037, 40.6213],
            [-74.7748, 40.5042],
            [-74.7978, 40.5156],
            [-74.8686, 40.5748],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413710',
        NAME: 'Readington Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123636186,
        AWATER: 778892,
        INTPTLAT: '+40.5841107',
        INTPTLON: '-074.7699862',
        ELSDLEA: '13710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8362, 40.7111],
            [-74.8232, 40.7145],
            [-74.8333, 40.725],
            [-74.8241, 40.7424],
            [-74.7258, 40.7195],
            [-74.7237, 40.6469],
            [-74.7886, 40.6534],
            [-74.8434, 40.6929],
            [-74.8362, 40.7111],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416170',
        NAME: 'Tewksbury Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81676243,
        AWATER: 447668,
        INTPTLAT: '+40.6901688',
        INTPTLON: '-074.7716846',
        ELSDLEA: '16170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8907, 40.6807],
            [-74.8744, 40.6633],
            [-74.8934, 40.6557],
            [-74.9121, 40.6701],
            [-74.8907, 40.6807],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407110',
        NAME: 'High Bridge Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6187744,
        AWATER: 109376,
        INTPTLAT: '+40.6700225',
        INTPTLON: '-074.8902706',
        ELSDLEA: '07110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8477, 40.6495],
            [-74.8317, 40.6486],
            [-74.8221, 40.64],
            [-74.8367, 40.6352],
            [-74.8477, 40.6495],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408430',
        NAME: 'Lebanon Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2295836,
        AWATER: 821,
        INTPTLAT: '+40.6418618',
        INTPTLON: '-074.8333118',
        ELSDLEA: '08430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0837, 40.5597],
            [-75.0872, 40.5563],
            [-75.1013, 40.568],
            [-75.0837, 40.5597],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410170',
        NAME: 'Milford Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2975206,
        AWATER: 200262,
        INTPTLAT: '+40.5738433',
        INTPTLON: '-075.0894833',
        ELSDLEA: '10170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9541, 40.3765],
            [-74.9468, 40.3856],
            [-74.9458, 40.355],
            [-74.9541, 40.3765],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408250',
        NAME: 'Lambertville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2813531,
        AWATER: 368922,
        INTPTLAT: '+40.3685634',
        INTPTLON: '-074.9430486',
        ELSDLEA: '08250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9666, 40.3982],
            [-74.986, 40.4068],
            [-74.9654, 40.4063],
            [-74.9666, 40.3982],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415780',
        NAME: 'Stockton Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1385666,
        AWATER: 198395,
        INTPTLAT: '+40.4055884',
        INTPTLON: '-074.9705033',
        ELSDLEA: '15780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0839, 39.8405],
            [-75.0493, 39.8506],
            [-75.0396, 39.8493],
            [-75.0286, 39.8341],
            [-75.0283, 39.8316],
            [-75.028, 39.8214],
            [-75.0237, 39.8042],
            [-74.9684, 39.7671],
            [-75.0153, 39.7264],
            [-75.0753, 39.8049],
            [-75.0839, 39.8405],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406030',
        NAME: 'Gloucester Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59526336,
        AWATER: 718283,
        INTPTLAT: '+39.7921855',
        INTPTLON: '-075.0360590',
        ELSDLEA: '06030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.016, 39.8593],
            [-74.9269, 39.8738],
            [-74.9086, 39.8095],
            [-74.9569, 39.8167],
            [-74.958, 39.8176],
            [-74.9647, 39.8457],
            [-74.9859, 39.8341],
            [-75.0016, 39.8365],
            [-75.016, 39.8593],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416830',
        NAME: 'Voorhees Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29765115,
        AWATER: 433153,
        INTPTLAT: '+39.8485387',
        INTPTLON: '-074.9534979',
        ELSDLEA: '16830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1206, 39.8726],
            [-75.1081, 39.878],
            [-75.0821, 39.8724],
            [-75.0677, 39.8721],
            [-75.0642, 39.8599],
            [-75.1032, 39.8563],
            [-75.1154, 39.8612],
            [-75.1206, 39.8726],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401380',
        NAME: 'Bellmawr Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7764856,
        AWATER: 340067,
        INTPTLAT: '+39.8663561',
        INTPTLON: '-075.0946697',
        ELSDLEA: '01380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0495, 39.8585],
            [-75.0409, 39.8623],
            [-75.0222, 39.8577],
            [-75.0396, 39.8493],
            [-75.0493, 39.8506],
            [-75.0495, 39.8585],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409300',
        NAME: 'Magnolia Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2506677,
        AWATER: 0,
        INTPTLAT: '+39.8561838',
        INTPTLON: '-075.0364713',
        ELSDLEA: '09300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9661, 39.7997],
            [-74.957, 39.8092],
            [-74.908, 39.7838],
            [-74.9133, 39.7765],
            [-74.9478, 39.7848],
            [-74.9661, 39.7997],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401590',
        NAME: 'Berlin Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9292608,
        AWATER: 33522,
        INTPTLAT: '+39.7920594',
        INTPTLON: '-074.9369855',
        ELSDLEA: '01590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0459, 40.6311],
            [-74.9229, 40.6805],
            [-74.9173, 40.671],
            [-74.917, 40.6448],
            [-74.9195, 40.6312],
            [-74.9578, 40.5834],
            [-75.0459, 40.6311],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416440',
        NAME: 'Union Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 48527906,
        AWATER: 4849667,
        INTPTLAT: '+40.6322148',
        INTPTLON: '-074.9649650',
        ELSDLEA: '16440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9129, 40.4189],
            [-74.8304, 40.3821],
            [-74.9429, 40.3417],
            [-74.9458, 40.355],
            [-74.9468, 40.3856],
            [-74.9129, 40.4189],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417370',
        NAME: 'West Amwell Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56038208,
        AWATER: 504549,
        INTPTLAT: '+40.3790510',
        INTPTLON: '-074.8877350',
        ELSDLEA: '17370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9859, 39.8341],
            [-74.9647, 39.8457],
            [-74.958, 39.8176],
            [-74.9859, 39.8341],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405850',
        NAME: 'Gibbsboro Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5652708,
        AWATER: 110613,
        INTPTLAT: '+39.8286910',
        INTPTLON: '-074.9683234',
        ELSDLEA: '05850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1032, 39.8563],
            [-75.0642, 39.8599],
            [-75.0495, 39.8585],
            [-75.0493, 39.8506],
            [-75.0839, 39.8405],
            [-75.1032, 39.8563],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414430',
        NAME: 'Runnemede Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5278664,
        AWATER: 150303,
        INTPTLAT: '+39.8534114',
        INTPTLON: '-075.0755019',
        ELSDLEA: '14430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0222, 39.8577],
            [-75.016, 39.8593],
            [-75.0016, 39.8365],
            [-75.005, 39.8341],
            [-75.0184, 39.8408],
            [-75.0286, 39.8341],
            [-75.0396, 39.8493],
            [-75.0222, 39.8577],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415000',
        NAME: 'Somerdale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3592081,
        AWATER: 0,
        INTPTLAT: '+39.8453912',
        INTPTLON: '-075.0217007',
        ELSDLEA: '15000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0283, 39.8316],
            [-75.0184, 39.8408],
            [-75.005, 39.8341],
            [-74.9982, 39.8255],
            [-75.0151, 39.8188],
            [-75.028, 39.8214],
            [-75.0283, 39.8316],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415870',
        NAME: 'Stratford Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4010049,
        AWATER: 0,
        INTPTLAT: '+39.8289569',
        INTPTLON: '-075.0154579',
        ELSDLEA: '15870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2301, 39.7587],
            [-75.1877, 39.7967],
            [-75.1721, 39.7949],
            [-75.1602, 39.791],
            [-75.1522, 39.783],
            [-75.1288, 39.7685],
            [-75.121, 39.7442],
            [-75.1359, 39.7179],
            [-75.139, 39.7148],
            [-75.2301, 39.7587],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409600',
        NAME: 'Mantua Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41048023,
        AWATER: 180083,
        INTPTLAT: '+39.7620120',
        INTPTLON: '-075.1730892',
        ELSDLEA: '09600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2208, 39.6619],
            [-75.2072, 39.709],
            [-75.1683, 39.6857],
            [-75.1074, 39.6795],
            [-75.1007, 39.6404],
            [-75.1341, 39.6203],
            [-75.2208, 39.6619],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404620',
        NAME: 'Elk Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50489990,
        AWATER: 501733,
        INTPTLAT: '+39.6627518',
        INTPTLON: '-075.1569719',
        ELSDLEA: '04620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1341, 39.6203],
            [-75.1007, 39.6404],
            [-75.0318, 39.6626],
            [-74.9116, 39.5786],
            [-74.9852, 39.5148],
            [-75.0188, 39.5384],
            [-75.0025, 39.5535],
            [-75.0197, 39.5581],
            [-75.0345, 39.5494],
            [-75.062, 39.5686],
            [-75.0875, 39.5869],
            [-75.1341, 39.6203],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405430',
        NAME: 'Franklin Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 144798952,
        AWATER: 1447307,
        INTPTLAT: '+39.5988405',
        INTPTLON: '-075.0208744',
        ELSDLEA: '05430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2863, 39.7928],
            [-75.2369, 39.8199],
            [-75.2277, 39.8261],
            [-75.1877, 39.7967],
            [-75.2301, 39.7587],
            [-75.2666, 39.7618],
            [-75.2863, 39.7928],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404140',
        NAME: 'East Greenwich Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37407094,
        AWATER: 1249341,
        INTPTLAT: '+39.7905188',
        INTPTLON: '-075.2405918',
        ELSDLEA: '04140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3782, 39.7458],
            [-75.2863, 39.7928],
            [-75.2666, 39.7618],
            [-75.2722, 39.7345],
            [-75.3209, 39.6912],
            [-75.3604, 39.7132],
            [-75.3782, 39.7458],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415990',
        NAME: 'Swedesboro-Woolwich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56029961,
        AWATER: 912359,
        INTPTLAT: '+39.7400872',
        INTPTLON: '-075.3173969',
        ELSDLEA: '15990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2666, 39.7618],
            [-75.2301, 39.7587],
            [-75.139, 39.7148],
            [-75.1683, 39.6857],
            [-75.2072, 39.709],
            [-75.2722, 39.7345],
            [-75.2666, 39.7618],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406900',
        NAME: 'Harrison Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49592904,
        AWATER: 223034,
        INTPTLAT: '+39.7267218',
        INTPTLON: '-075.2055295',
        ELSDLEA: '06900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1606, 39.8241],
            [-75.1503, 39.8261],
            [-75.1657, 39.811],
            [-75.1606, 39.8241],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418180',
        NAME: 'Woodbury Heights Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3165728,
        AWATER: 16037,
        INTPTLAT: '+39.8133761',
        INTPTLON: '-075.1506172',
        ELSDLEA: '18180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1398, 39.8883],
            [-75.1274, 39.8808],
            [-75.1206, 39.8726],
            [-75.1154, 39.8612],
            [-75.1354, 39.8617],
            [-75.1456, 39.8845],
            [-75.1398, 39.8883],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417790',
        NAME: 'Westville Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2652651,
        AWATER: 915633,
        INTPTLAT: '+39.8689216',
        INTPTLON: '-075.1315842',
        ELSDLEA: '17790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1893, 39.8807],
            [-75.169, 39.8635],
            [-75.1821, 39.8568],
            [-75.1999, 39.8732],
            [-75.1893, 39.8807],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411100',
        NAME: 'National Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2600960,
        AWATER: 1160718,
        INTPTLAT: '+39.8672966',
        INTPTLON: '-075.1856214',
        ELSDLEA: '11100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9982, 40.4226],
            [-74.0045, 40.4062],
            [-74.051, 40.4132],
            [-74.0286, 40.4443],
            [-73.9982, 40.4226],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401020',
        NAME: 'Atlantic Highlands Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3356219,
        AWATER: 8469464,
        INTPTLAT: '+40.4132193',
        INTPTLON: '-074.0207698',
        ELSDLEA: '01020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1976, 40.3454],
            [-74.1489, 40.3247],
            [-74.113, 40.3203],
            [-74.1256, 40.2493],
            [-74.1391, 40.2509],
            [-74.2239, 40.2611],
            [-74.2229, 40.2774],
            [-74.1976, 40.3454],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403450',
        NAME: 'Colts Neck Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79592589,
        AWATER: 2748443,
        INTPTLAT: '+40.2934378',
        INTPTLON: '-074.1682569',
        ELSDLEA: '03450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0644, 40.3115],
            [-74.051, 40.315],
            [-74.0325, 40.2987],
            [-74.0276, 40.2727],
            [-74.0702, 40.2708],
            [-74.0644, 40.3115],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404410',
        NAME: 'Eatontown Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15089107,
        AWATER: 130077,
        INTPTLAT: '+40.2915877',
        INTPTLON: '-074.0542257',
        ELSDLEA: '04410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0337, 40.3757],
            [-74.0377, 40.3522],
            [-74.0511, 40.348],
            [-74.0592, 40.3633],
            [-74.0337, 40.3757],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404950',
        NAME: 'Fair Haven Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4136537,
        AWATER: 1333656,
        INTPTLAT: '+40.3619401',
        INTPTLON: '-074.0387747',
        ELSDLEA: '04950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1789, 40.2031],
            [-74.1668, 40.2053],
            [-74.1673, 40.1897],
            [-74.1789, 40.2031],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405130',
        NAME: 'Farmingdale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1351039,
        AWATER: 2495,
        INTPTLAT: '+40.2013908',
        INTPTLON: '-074.1678386',
        ELSDLEA: '05130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3209, 39.6912],
            [-75.2722, 39.7345],
            [-75.2072, 39.709],
            [-75.2208, 39.6619],
            [-75.2481, 39.6649],
            [-75.3209, 39.6912],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415270',
        NAME: 'South Harrison Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40586154,
        AWATER: 139940,
        INTPTLAT: '+39.6935957',
        INTPTLON: '-075.2537794',
        ELSDLEA: '15270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3535, 40.2089],
            [-74.2902, 40.2937],
            [-74.2229, 40.2774],
            [-74.2239, 40.2611],
            [-74.2638, 40.1683],
            [-74.4068, 40.1724],
            [-74.3535, 40.2089],
          ],
          [
            [-74.292, 40.2647],
            [-74.2873, 40.2512],
            [-74.2616, 40.2563],
            [-74.2648, 40.2725],
            [-74.292, 40.2647],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405640',
        NAME: 'Freehold Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99726048,
        AWATER: 580463,
        INTPTLAT: '+40.2243816',
        INTPTLON: '-074.2990509',
        ELSDLEA: '05640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2239, 40.2611],
            [-74.1391, 40.2509],
            [-74.1297, 40.1174],
            [-74.1544, 40.0997],
            [-74.243, 40.1158],
            [-74.2638, 40.1683],
            [-74.2239, 40.2611],
          ],
          [
            [-74.1789, 40.2031],
            [-74.1673, 40.1897],
            [-74.1668, 40.2053],
            [-74.1789, 40.2031],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407560',
        NAME: 'Howell Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156852936,
        AWATER: 1676187,
        INTPTLAT: '+40.1820512',
        INTPTLON: '-074.1985197',
        ELSDLEA: '07560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.292, 40.2647],
            [-74.2648, 40.2725],
            [-74.2616, 40.2563],
            [-74.2873, 40.2512],
            [-74.292, 40.2647],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405580',
        NAME: 'Freehold Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5046893,
        AWATER: 4739,
        INTPTLAT: '+40.2602193',
        INTPTLON: '-074.2758842',
        ELSDLEA: '05580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0045, 40.4062],
            [-73.9982, 40.4226],
            [-73.9784, 40.3992],
            [-73.9781, 40.3889],
            [-74.0045, 40.4062],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407200',
        NAME: 'Highlands Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1900714,
        AWATER: 1633512,
        INTPTLAT: '+40.4043473',
        INTPTLON: '-073.9885074',
        ELSDLEA: '07200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1602, 39.791],
            [-75.1446, 39.8026],
            [-75.1381, 39.7807],
            [-75.1522, 39.783],
            [-75.1602, 39.791],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417340',
        NAME: 'Wenonah Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2518405,
        AWATER: 28546,
        INTPTLAT: '+39.7920009',
        INTPTLON: '-075.1482160',
        ELSDLEA: '17340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3525, 40.3248],
            [-74.3274, 40.3446],
            [-74.2902, 40.2937],
            [-74.3535, 40.2089],
            [-74.4081, 40.2738],
            [-74.3525, 40.3248],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409390',
        NAME: 'Manalapan-Englishtown Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80745053,
        AWATER: 645061,
        INTPTLAT: '+40.2823530',
        INTPTLON: '-074.3465645',
        ELSDLEA: '09390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2627, 40.3954],
            [-74.2083, 40.3818],
            [-74.1976, 40.3454],
            [-74.2229, 40.2774],
            [-74.2902, 40.2937],
            [-74.3274, 40.3446],
            [-74.2627, 40.3954],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409720',
        NAME: 'Marlboro Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78635929,
        AWATER: 285177,
        INTPTLAT: '+40.3429310',
        INTPTLON: '-074.2571970',
        ELSDLEA: '09720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.992, 40.3494],
              [-73.9775, 40.3835],
              [-73.9781, 40.3889],
              [-73.9784, 40.3992],
              [-73.9749, 40.3995],
              [-73.9719, 40.3458],
              [-73.992, 40.3494],
            ],
          ],
          [
            [
              [-74.0407, 40.3205],
              [-74.0027, 40.334],
              [-73.9972, 40.3298],
              [-74.0125, 40.3049],
              [-74.0325, 40.2987],
              [-74.051, 40.315],
              [-74.0407, 40.3205],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412120',
        NAME: 'Oceanport Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9921402,
        AWATER: 3248736,
        INTPTLAT: '+40.3282431',
        INTPTLON: '-074.0123325',
        ELSDLEA: '12120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0592, 40.3633],
            [-74.0511, 40.348],
            [-74.0637, 40.3383],
            [-74.0749, 40.3373],
            [-74.0876, 40.3362],
            [-74.0592, 40.3633],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413740',
        NAME: 'Red Bank Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4503881,
        AWATER: 1096232,
        INTPTLAT: '+40.3487222',
        INTPTLON: '-074.0665281',
        ELSDLEA: '13740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0032, 40.3399],
            [-73.992, 40.3494],
            [-73.9719, 40.3458],
            [-73.9743, 40.3224],
            [-73.9972, 40.3298],
            [-74.0027, 40.334],
            [-74.0032, 40.3399],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410380',
        NAME: 'Monmouth Beach Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2640928,
        AWATER: 2717990,
        INTPTLAT: '+40.3358950',
        INTPTLON: '-073.9856075',
        ELSDLEA: '10380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0511, 40.348],
            [-74.0377, 40.3522],
            [-74.0032, 40.3399],
            [-74.0027, 40.334],
            [-74.0407, 40.3205],
            [-74.0637, 40.3383],
            [-74.0511, 40.348],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408790',
        NAME: 'Little Silver Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7013353,
        AWATER: 1572691,
        INTPTLAT: '+40.3369522',
        INTPTLON: '-074.0345347',
        ELSDLEA: '08790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0749, 40.3373],
            [-74.0637, 40.3383],
            [-74.0407, 40.3205],
            [-74.051, 40.315],
            [-74.0644, 40.3115],
            [-74.0749, 40.3373],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414970',
        NAME: 'Shrewsbury Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5614074,
        AWATER: 82604,
        INTPTLAT: '+40.3264505',
        INTPTLON: '-074.0573731',
        ELSDLEA: '14970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1256, 40.2493],
            [-74.113, 40.3203],
            [-74.0876, 40.3362],
            [-74.0749, 40.3373],
            [-74.0644, 40.3115],
            [-74.0702, 40.2708],
            [-74.0774, 40.2344],
            [-74.0944, 40.2063],
            [-74.1256, 40.2493],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416200',
        NAME: 'Tinton Falls Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40382343,
        AWATER: 352138,
        INTPTLAT: '+40.2662339',
        INTPTLON: '-074.0961745',
        ELSDLEA: '16200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0325, 40.2987],
            [-74.0125, 40.3049],
            [-74.0029, 40.2764],
            [-74.0276, 40.2727],
            [-74.0325, 40.2987],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417490',
        NAME: 'West Long Branch Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7402806,
        AWATER: 92919,
        INTPTLAT: '+40.2879037',
        INTPTLON: '-074.0200191',
        ELSDLEA: '17490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0377, 40.3522],
            [-74.0337, 40.3757],
            [-73.9775, 40.3835],
            [-73.992, 40.3494],
            [-74.0032, 40.3399],
            [-74.0377, 40.3522],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414370',
        NAME: 'Rumson Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13097339,
        AWATER: 5335135,
        INTPTLAT: '+40.3620666',
        INTPTLON: '-074.0045104',
        ELSDLEA: '14370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0686, 39.9482],
            [-74.0709, 39.9368],
            [-74.0787, 39.9375],
            [-74.0867, 39.9437],
            [-74.0686, 39.9482],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414790',
        NAME: 'Seaside Heights Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1605869,
        AWATER: 329530,
        INTPTLAT: '+39.9449784',
        INTPTLON: '-074.0787832',
        ELSDLEA: '14790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4292, 39.769],
            [-74.3899, 39.7734],
            [-74.3904, 39.596],
            [-74.4174, 39.5573],
            [-74.4423, 39.5434],
            [-74.4796, 39.556],
            [-74.5279, 39.6582],
            [-74.4292, 39.769],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401200',
        NAME: 'Bass River Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194354064,
        AWATER: 8352396,
        INTPTLAT: '+39.6584431',
        INTPTLON: '-074.4481270',
        ELSDLEA: '01200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6773, 40.1673],
            [-74.5948, 40.1379],
            [-74.6192, 40.0562],
            [-74.6441, 40.0632],
            [-74.6989, 40.118],
            [-74.6773, 40.1673],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403090',
        NAME: 'Chesterfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55254195,
        AWATER: 480473,
        INTPTLAT: '+40.1191746',
        INTPTLON: '-074.6628843',
        ELSDLEA: '03090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7713, 40.0298],
            [-74.7333, 40.009],
            [-74.7361, 39.9821],
            [-74.7501, 39.9783],
            [-74.768, 39.9794],
            [-74.7795, 40.0072],
            [-74.7713, 40.0298],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404350',
        NAME: 'Eastampton Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14887452,
        AWATER: 224085,
        INTPTLAT: '+40.0030113',
        INTPTLON: '-074.7573218',
        ELSDLEA: '04350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9572, 39.9143],
            [-74.8625, 39.9198],
            [-74.8629, 39.7853],
            [-74.9025, 39.7908],
            [-74.9086, 39.8095],
            [-74.9269, 39.8738],
            [-74.9572, 39.9143],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404890',
        NAME: 'Evesham Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75844849,
        AWATER: 1097405,
        INTPTLAT: '+39.8566770',
        INTPTLON: '-074.9008104',
        ELSDLEA: '04890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8595, 39.9964],
            [-74.806, 39.9972],
            [-74.8007, 39.9882],
            [-74.8624, 39.9431],
            [-74.8595, 39.9964],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406420',
        NAME: 'Hainesport Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16729782,
        AWATER: 684641,
        INTPTLAT: '+39.9779562',
        INTPTLON: '-074.8363343',
        ELSDLEA: '06420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8624, 39.9431],
            [-74.8007, 39.9882],
            [-74.768, 39.9794],
            [-74.7501, 39.9783],
            [-74.7871, 39.9327],
            [-74.8623, 39.9311],
            [-74.8624, 39.9431],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409180',
        NAME: 'Lumberton Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33473740,
        AWATER: 343003,
        INTPTLAT: '+39.9588554',
        INTPTLON: '-074.8024782',
        ELSDLEA: '09180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7722, 40.0668],
            [-74.7612, 40.1214],
            [-74.6989, 40.118],
            [-74.6441, 40.0632],
            [-74.7722, 40.0668],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409510',
        NAME: 'Mansfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 56307489,
        AWATER: 432168,
        INTPTLAT: '+40.0874160',
        INTPTLON: '-074.7122587',
        ELSDLEA: '09510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8192, 39.8541],
            [-74.8052, 39.8684],
            [-74.7964, 39.8514],
            [-74.8192, 39.8541],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409840',
        NAME: 'Medford Lakes Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3008318,
        AWATER: 339865,
        INTPTLAT: '+39.8536489',
        INTPTLON: '-074.8116647',
        ELSDLEA: '09840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3293, 39.94],
            [-74.2707, 39.9874],
            [-74.1563, 39.9404],
            [-74.139, 39.9337],
            [-74.0867, 39.9437],
            [-74.0787, 39.9375],
            [-74.0767, 39.9129],
            [-74.1015, 39.7568],
            [-74.1053, 39.7649],
            [-74.1031, 39.7965],
            [-74.1667, 39.8673],
            [-74.3293, 39.94],
          ],
          [
            [-74.1472, 39.9231],
            [-74.124, 39.923],
            [-74.1238, 39.9285],
            [-74.1401, 39.9306],
            [-74.1472, 39.9231],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401560',
        NAME: 'Berkeley Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111043242,
        AWATER: 33983924,
        INTPTLAT: '+39.9141687',
        INTPTLON: '-074.1903836',
        ELSDLEA: '01560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3899, 39.7734],
            [-74.3897, 39.773],
            [-74.3582, 39.7165],
            [-74.2656, 39.5889],
            [-74.2666, 39.5853],
            [-74.261, 39.5522],
            [-74.2961, 39.5084],
            [-74.3264, 39.5063],
            [-74.4174, 39.5573],
            [-74.3904, 39.596],
            [-74.3899, 39.7734],
          ],
          [
            [-74.3505, 39.5997],
            [-74.3391, 39.578],
            [-74.3139, 39.587],
            [-74.3214, 39.6169],
            [-74.3505, 39.5997],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408700',
        NAME: 'Little Egg Harbor Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122681465,
        AWATER: 66524190,
        INTPTLAT: '+39.6349653',
        INTPTLON: '-074.3370325',
        ELSDLEA: '08700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.2656, 39.5889],
              [-74.239, 39.6424],
              [-74.1486, 39.6986],
              [-74.1527, 39.754],
              [-74.1053, 39.7649],
              [-74.1015, 39.7568],
              [-74.2296, 39.5695],
              [-74.2666, 39.5853],
              [-74.2656, 39.5889],
            ],
          ],
          [
            [
              [-74.261, 39.5522],
              [-74.2498, 39.547],
              [-74.2961, 39.5084],
              [-74.261, 39.5522],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408910',
        NAME: 'Long Beach Island School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21217520,
        AWATER: 46101364,
        INTPTLAT: '+39.6015505',
        INTPTLON: '-074.2263671',
        ELSDLEA: '08910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1486, 39.6986],
            [-74.239, 39.6424],
            [-74.3582, 39.7165],
            [-74.3897, 39.773],
            [-74.1486, 39.6986],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415690',
        NAME: 'Stafford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118747339,
        AWATER: 23396089,
        INTPTLAT: '+39.7056322',
        INTPTLON: '-074.2641210',
        ELSDLEA: '15690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3505, 39.5997],
            [-74.3214, 39.6169],
            [-74.3139, 39.587],
            [-74.3391, 39.578],
            [-74.3505, 39.5997],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416320',
        NAME: 'Tuckerton Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8711684,
        AWATER: 1139128,
        INTPTLAT: '+39.5920988',
        INTPTLON: '-074.3334481',
        ELSDLEA: '16320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8625, 39.9198],
            [-74.8623, 39.9311],
            [-74.7871, 39.9327],
            [-74.7676, 39.8629],
            [-74.7544, 39.8425],
            [-74.8125, 39.7853],
            [-74.8629, 39.7853],
            [-74.8625, 39.9198],
          ],
          [
            [-74.8192, 39.8541],
            [-74.7964, 39.8514],
            [-74.8052, 39.8684],
            [-74.8192, 39.8541],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409870',
        NAME: 'Medford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100804651,
        AWATER: 2610650,
        INTPTLAT: '+39.8626955',
        INTPTLON: '-074.8223105',
        ELSDLEA: '09870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9837, 39.9269],
            [-74.9667, 39.9377],
            [-74.8822, 40.0007],
            [-74.8724, 39.9977],
            [-74.8595, 39.9964],
            [-74.8624, 39.9431],
            [-74.8623, 39.9311],
            [-74.8625, 39.9198],
            [-74.9572, 39.9143],
            [-74.9837, 39.9269],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410950',
        NAME: 'Mount Laurel Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56181049,
        AWATER: 722371,
        INTPTLAT: '+39.9489918',
        INTPTLON: '-074.9002470',
        ELSDLEA: '10950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.6282, 40.0395],
              [-74.6192, 40.0562],
              [-74.5948, 40.1379],
              [-74.5878, 40.1382],
              [-74.5531, 40.0791],
              [-74.5406, 40.057],
              [-74.5999, 40.0002],
              [-74.6003, 40.0001],
              [-74.6282, 40.0395],
            ],
          ],
          [
            [
              [-74.6339, 40.0332],
              [-74.6193, 39.9939],
              [-74.6561, 40.0102],
              [-74.6552, 40.0141],
              [-74.6339, 40.0332],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411580',
        NAME: 'North Hanover Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65950077,
        AWATER: 405511,
        INTPTLAT: '+40.0619567',
        INTPTLON: '-074.5918776',
        ELSDLEA: '11580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8125, 39.7853],
            [-74.7544, 39.8425],
            [-74.6267, 39.7461],
            [-74.7242, 39.7223],
            [-74.7363, 39.7299],
            [-74.8125, 39.7853],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414880',
        NAME: 'Shamong Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114974335,
        AWATER: 1560362,
        INTPTLAT: '+39.7850030',
        INTPTLON: '-074.7171784',
        ELSDLEA: '14880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7871, 39.9327],
            [-74.7501, 39.9783],
            [-74.7361, 39.9821],
            [-74.5965, 39.8988],
            [-74.6562, 39.8811],
            [-74.7676, 39.8629],
            [-74.7871, 39.9327],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415420',
        NAME: 'Southampton Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113098743,
        AWATER: 1439286,
        INTPTLAT: '+39.9162363',
        INTPTLON: '-074.7177661',
        ELSDLEA: '15420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8023, 40.073],
            [-74.7722, 40.0668],
            [-74.6441, 40.0632],
            [-74.6192, 40.0562],
            [-74.6282, 40.0395],
            [-74.6339, 40.0332],
            [-74.6552, 40.0141],
            [-74.6572, 40.0108],
            [-74.7333, 40.009],
            [-74.7713, 40.0298],
            [-74.7975, 40.0442],
            [-74.8023, 40.073],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415660',
        NAME: 'Springfield Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 76029898,
        AWATER: 153133,
        INTPTLAT: '+40.0412160',
        INTPTLON: '-074.7101246',
        ELSDLEA: '15660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6562, 39.8811],
            [-74.545, 39.7608],
            [-74.6267, 39.7461],
            [-74.7544, 39.8425],
            [-74.7676, 39.8629],
            [-74.6562, 39.8811],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416020',
        NAME: 'Tabernacle Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127221032,
        AWATER: 1279659,
        INTPTLAT: '+39.8183874',
        INTPTLON: '-074.6513037',
        ELSDLEA: '16020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8557, 40.032],
            [-74.7975, 40.0442],
            [-74.7713, 40.0298],
            [-74.7795, 40.0072],
            [-74.806, 39.9972],
            [-74.8595, 39.9964],
            [-74.8724, 39.9977],
            [-74.8557, 40.032],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417730',
        NAME: 'Westampton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28554786,
        AWATER: 439232,
        INTPTLAT: '+40.0183692',
        INTPTLON: '-074.8166696',
        ELSDLEA: '17730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5965, 39.8988],
            [-74.4708, 39.9226],
            [-74.4347, 39.8548],
            [-74.4172, 39.8232],
            [-74.3899, 39.7734],
            [-74.4292, 39.769],
            [-74.545, 39.7608],
            [-74.6562, 39.8811],
            [-74.5965, 39.8988],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418240',
        NAME: 'Woodland Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244904735,
        AWATER: 4732969,
        INTPTLAT: '+39.8412891',
        INTPTLON: '-074.5177398',
        ELSDLEA: '18240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2666, 39.5853],
            [-74.2296, 39.5695],
            [-74.2498, 39.547],
            [-74.261, 39.5522],
            [-74.2666, 39.5853],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401290',
        NAME: 'Beach Haven Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2533740,
        AWATER: 3475820,
        INTPTLAT: '+39.5760308',
        INTPTLON: '-074.2517910',
        ELSDLEA: '01290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.139, 39.9337],
            [-74.1563, 39.9404],
            [-74.1476, 39.9474],
            [-74.1322, 39.9418],
            [-74.139, 39.9337],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407710',
        NAME: 'Island Heights Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1582365,
        AWATER: 767134,
        INTPTLAT: '+39.9417918',
        INTPTLON: '-074.1438141',
        ELSDLEA: '07710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0787, 39.9375],
            [-74.0709, 39.9368],
            [-74.0767, 39.9129],
            [-74.0787, 39.9375],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414820',
        NAME: 'Seaside Park Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1682673,
        AWATER: 305178,
        INTPTLAT: '+39.9256657',
        INTPTLON: '-074.0787536',
        ELSDLEA: '14820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1472, 39.9231],
            [-74.1401, 39.9306],
            [-74.1238, 39.9285],
            [-74.124, 39.923],
            [-74.1472, 39.9231],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412030',
        NAME: 'Ocean Gate Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1164658,
        AWATER: 14753,
        INTPTLAT: '+39.9258465',
        INTPTLON: '-074.1346137',
        ELSDLEA: '12030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2637, 40.8352],
            [-74.2598, 40.8349],
            [-74.2643, 40.8254],
            [-74.2763, 40.8139],
            [-74.2983, 40.8304],
            [-74.2637, 40.8352],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404830',
        NAME: 'Essex Fells Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3657444,
        AWATER: 15289,
        INTPTLAT: '+40.8281268',
        INTPTLON: '-074.2761970',
        ELSDLEA: '04830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3582, 39.7165],
            [-74.239, 39.6424],
            [-74.2656, 39.5889],
            [-74.3582, 39.7165],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404050',
        NAME: 'Eagleswood Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41597119,
        AWATER: 7243382,
        INTPTLAT: '+39.6471459',
        INTPTLON: '-074.2846225',
        ELSDLEA: '04050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7795, 40.0072],
            [-74.768, 39.9794],
            [-74.8007, 39.9882],
            [-74.806, 39.9972],
            [-74.7795, 40.0072],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410920',
        NAME: 'Mount Holly Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7268773,
        AWATER: 119698,
        INTPTLAT: '+39.9953512',
        INTPTLON: '-074.7864522',
        ELSDLEA: '10920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5949, 40.455],
            [-74.5893, 40.5279],
            [-74.5665, 40.5428],
            [-74.5438, 40.5546],
            [-74.5158, 40.545],
            [-74.4672, 40.5084],
            [-74.4895, 40.4744],
            [-74.5315, 40.4414],
            [-74.6196, 40.3744],
            [-74.6227, 40.3844],
            [-74.5949, 40.455],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405490',
        NAME: 'Franklin Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 119522850,
        AWATER: 1810494,
        INTPTLAT: '+40.4768719',
        INTPTLON: '-074.5504471',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7978, 40.5156],
            [-74.7748, 40.5042],
            [-74.6874, 40.5554],
            [-74.6264, 40.5632],
            [-74.6081, 40.5584],
            [-74.5989, 40.5576],
            [-74.5893, 40.5279],
            [-74.5949, 40.455],
            [-74.752, 40.4314],
            [-74.7825, 40.4878],
            [-74.7978, 40.5156],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407230',
        NAME: 'Hillsborough Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143156084,
        AWATER: 1261912,
        INTPTLAT: '+40.4976920',
        INTPTLON: '-074.6705050',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4087, 40.5438],
            [-74.3946, 40.6009],
            [-74.3591, 40.6035],
            [-74.3385, 40.6055],
            [-74.3244, 40.4908],
            [-74.3768, 40.4778],
            [-74.3925, 40.4852],
            [-74.416, 40.4927],
            [-74.4399, 40.5145],
            [-74.4087, 40.5438],
          ],
          [
            [-74.3814, 40.5512],
            [-74.3549, 40.5281],
            [-74.3464, 40.5551],
            [-74.3814, 40.5512],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404500',
        NAME: 'Edison Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77621675,
        AWATER: 1694338,
        INTPTLAT: '+40.5039907',
        INTPTLON: '-074.3494113',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3679, 40.4069],
            [-74.3615, 40.4166],
            [-74.2437, 40.4835],
            [-74.2282, 40.4774],
            [-74.2627, 40.3954],
            [-74.3274, 40.3446],
            [-74.3525, 40.3248],
            [-74.3816, 40.3829],
            [-74.3679, 40.4069],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409270',
        NAME: 'Old Bridge Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98577844,
        AWATER: 7050574,
        INTPTLAT: '+40.4046321',
        INTPTLON: '-074.3085374',
        ELSDLEA: '',
        UNSDLEA: '09270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5238, 40.5695],
            [-74.5122, 40.582],
            [-74.4781, 40.5934],
            [-74.4697, 40.5856],
            [-74.5195, 40.5553],
            [-74.527, 40.5596],
            [-74.5238, 40.5695],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410050',
        NAME: 'Middlesex Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9110575,
        AWATER: 58012,
        INTPTLAT: '+40.5746267',
        INTPTLON: '-074.4982587',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5315, 40.4414],
            [-74.4895, 40.4744],
            [-74.4149, 40.4778],
            [-74.4326, 40.4598],
            [-74.4474, 40.449],
            [-74.4857, 40.4208],
            [-74.5315, 40.4414],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411490',
        NAME: 'North Brunswick Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31066417,
        AWATER: 716754,
        INTPTLAT: '+40.4503922',
        INTPTLON: '-074.4794159',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3385, 40.6055],
            [-74.3034, 40.6089],
            [-74.2581, 40.6022],
            [-74.2478, 40.6001],
            [-74.2291, 40.5582],
            [-74.2502, 40.5396],
            [-74.2922, 40.5082],
            [-74.3244, 40.4908],
            [-74.3385, 40.6055],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418120',
        NAME: 'Woodbridge Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60124902,
        AWATER: 3337423,
        INTPTLAT: '+40.5607301',
        INTPTLON: '-074.2926979',
        ELSDLEA: '',
        UNSDLEA: '18120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.447, 40.506],
            [-74.4399, 40.5145],
            [-74.416, 40.4927],
            [-74.447, 40.506],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407170',
        NAME: 'Highland Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4692225,
        AWATER: 20629,
        INTPTLAT: '+40.5007945',
        INTPTLON: '-074.4279107',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3814, 40.5512],
            [-74.3464, 40.5551],
            [-74.3549, 40.5281],
            [-74.3814, 40.5512],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409990',
        NAME: 'Metuchen Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7192517,
        AWATER: 6323,
        INTPTLAT: '+40.5424449',
        INTPTLON: '-074.3627670',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0733, 40.8922],
            [-74.0689, 40.9085],
            [-74.0506, 40.9106],
            [-74.0641, 40.8868],
            [-74.0733, 40.8922],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409810',
        NAME: 'Maywood Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3330428,
        AWATER: 3739,
        INTPTLAT: '+40.9028851',
        INTPTLON: '-074.0634573',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1399, 40.8053],
            [-74.1109, 40.7768],
            [-74.1475, 40.7865],
            [-74.1399, 40.8053],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411430',
        NAME: 'North Arlington Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6635910,
        AWATER: 156784,
        INTPTLAT: '+40.7862555',
        INTPTLON: '-074.1262199',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1, 40.9553],
            [-74.0778, 40.9784],
            [-74.0552, 40.9698],
            [-74.049, 40.9653],
            [-74.0388, 40.9437],
            [-74.0418, 40.9114],
            [-74.0506, 40.9106],
            [-74.0689, 40.9085],
            [-74.0893, 40.9196],
            [-74.1, 40.9553],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412420',
        NAME: 'Paramus Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27113722,
        AWATER: 128792,
        INTPTLAT: '+40.9472994',
        INTPTLON: '-074.0701694',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0215, 41.0377],
            [-74.0199, 41.0206],
            [-74.0264, 41.0206],
            [-74.0697, 41.0415],
            [-74.0215, 41.0377],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412450',
        NAME: 'Park Ridge Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6689104,
        AWATER: 53556,
        INTPTLAT: '+41.0361833',
        INTPTLON: '-074.0442926',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0354, 40.8288],
            [-74.0299, 40.8467],
            [-74.0107, 40.8462],
            [-73.9913, 40.8359],
            [-73.9888, 40.8345],
            [-73.9962, 40.8245],
            [-74.0136, 40.8229],
            [-74.0321, 40.8129],
            [-74.035, 40.8253],
            [-74.0354, 40.8288],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413770',
        NAME: 'Ridgefield Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6608436,
        AWATER: 789921,
        INTPTLAT: '+40.8294157',
        INTPTLON: '-074.0157149',
        ELSDLEA: '',
        UNSDLEA: '13770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0357, 40.869],
            [-74.0225, 40.8659],
            [-74.0047, 40.8599],
            [-74.0061, 40.857],
            [-74.0107, 40.8462],
            [-74.0299, 40.8467],
            [-74.0326, 40.8587],
            [-74.0357, 40.869],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413800',
        NAME: 'Ridgefield Park Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4450415,
        AWATER: 492552,
        INTPTLAT: '+40.8547046',
        INTPTLON: '-074.0199264',
        ELSDLEA: '',
        UNSDLEA: '13800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9384, 41.0133],
            [-73.9221, 41.0059],
            [-73.926, 40.9936],
            [-73.9416, 40.9953],
            [-73.9384, 41.0133],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414160',
        NAME: 'Rockleigh Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2508966,
        AWATER: 19057,
        INTPTLAT: '+41.0002412',
        INTPTLON: '-073.9340677',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1965, 40.8598],
            [-74.1908, 40.8724],
            [-74.1746, 40.8994],
            [-74.1292, 40.8941],
            [-74.1302, 40.8869],
            [-74.1178, 40.8768],
            [-74.1467, 40.8489],
            [-74.1219, 40.8341],
            [-74.1242, 40.8226],
            [-74.13, 40.82],
            [-74.1626, 40.8379],
            [-74.1827, 40.8488],
            [-74.1965, 40.8598],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403300',
        NAME: 'Clifton City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29183152,
        AWATER: 333158,
        INTPTLAT: '+40.8621369',
        INTPTLON: '-074.1603932',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1989, 40.9247],
            [-74.1768, 40.9335],
            [-74.1666, 40.9343],
            [-74.1481, 40.9416],
            [-74.1319, 40.9222],
            [-74.1292, 40.8941],
            [-74.1746, 40.8994],
            [-74.1958, 40.9076],
            [-74.1989, 40.9247],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412690',
        NAME: 'Paterson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21828314,
        AWATER: 711983,
        INTPTLAT: '+40.9147662',
        INTPTLON: '-074.1628291',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4826, 41.1038],
            [-74.3668, 41.2033],
            [-74.333, 41.1879],
            [-74.2802, 41.1631],
            [-74.28, 41.163],
            [-74.3462, 41.0608],
            [-74.363, 41.0132],
            [-74.3658, 41.0117],
            [-74.4302, 41.0202],
            [-74.441, 41.045],
            [-74.5029, 41.0859],
            [-74.4826, 41.1038],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417520',
        NAME: 'West Milford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197084880,
        AWATER: 13342537,
        INTPTLAT: '+41.0965170',
        INTPTLON: '-074.3994488',
        ELSDLEA: '',
        UNSDLEA: '17520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2502, 40.5396],
            [-74.259, 40.4971],
            [-74.2878, 40.4984],
            [-74.2922, 40.5082],
            [-74.2502, 40.5396],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412930',
        NAME: 'Perth Amboy City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12179770,
        AWATER: 3248257,
        INTPTLAT: '+40.5201604',
        INTPTLON: '-074.2713313',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.385, 40.4627],
            [-74.3768, 40.4778],
            [-74.3244, 40.4908],
            [-74.2922, 40.5082],
            [-74.2878, 40.4984],
            [-74.2526, 40.4871],
            [-74.2437, 40.4835],
            [-74.3615, 40.4166],
            [-74.3563, 40.4296],
            [-74.385, 40.4627],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414640',
        NAME: 'Sayreville Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41089150,
        AWATER: 7379994,
        INTPTLAT: '+40.4669448',
        INTPTLON: '-074.3201919',
        ELSDLEA: '',
        UNSDLEA: '14640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4373, 40.5972],
            [-74.3946, 40.6009],
            [-74.4087, 40.5438],
            [-74.4373, 40.5972],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415360',
        NAME: 'South Plainfield Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21565753,
        AWATER: 86840,
        INTPTLAT: '+40.5744129',
        INTPTLON: '-074.4147996',
        ELSDLEA: '',
        UNSDLEA: '15360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.385, 40.4627],
            [-74.3563, 40.4296],
            [-74.3942, 40.4455],
            [-74.385, 40.4627],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415390',
        NAME: 'South River Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7142889,
        AWATER: 385237,
        INTPTLAT: '+40.4456522',
        INTPTLON: '-074.3784585',
        ELSDLEA: '',
        UNSDLEA: '15390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4474, 40.449],
            [-74.4337, 40.4366],
            [-74.4233, 40.4405],
            [-74.4326, 40.4598],
            [-74.4149, 40.4778],
            [-74.3925, 40.4852],
            [-74.3768, 40.4778],
            [-74.385, 40.4627],
            [-74.3942, 40.4455],
            [-74.3563, 40.4296],
            [-74.3615, 40.4166],
            [-74.3679, 40.4069],
            [-74.4386, 40.3711],
            [-74.4517, 40.3772],
            [-74.4857, 40.4208],
            [-74.4474, 40.449],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404110',
        NAME: 'East Brunswick Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56266892,
        AWATER: 1447706,
        INTPTLAT: '+40.4262486',
        INTPTLON: '-074.4182440',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4841, 40.3384],
            [-74.4517, 40.3772],
            [-74.4386, 40.3711],
            [-74.3816, 40.3829],
            [-74.3525, 40.3248],
            [-74.4081, 40.2738],
            [-74.4843, 40.2533],
            [-74.4888, 40.2766],
            [-74.4841, 40.3384],
          ],
          [
            [-74.4541, 40.3594],
            [-74.4455, 40.3555],
            [-74.45, 40.3422],
            [-74.4333, 40.3492],
            [-74.4359, 40.3566],
            [-74.4541, 40.3594],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410500',
        NAME: 'Monroe Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 108728206,
        AWATER: 656855,
        INTPTLAT: '+40.3194824',
        INTPTLON: '-074.4287850',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0026, 40.9521],
            [-74.0032, 40.9551],
            [-73.9758, 40.9541],
            [-73.9757, 40.9531],
            [-73.9797, 40.9366],
            [-74.0105, 40.9349],
            [-74.0026, 40.9521],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403990',
        NAME: 'Dumont Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5134838,
        AWATER: 7152,
        INTPTLAT: '+40.9452388',
        INTPTLON: '-073.9924284',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1319, 40.9222],
            [-74.1134, 40.9176],
            [-74.1042, 40.8937],
            [-74.1302, 40.8869],
            [-74.1292, 40.8941],
            [-74.1319, 40.9222],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404660',
        NAME: 'Elmwood Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6852548,
        AWATER: 284933,
        INTPTLAT: '+40.9045259',
        INTPTLON: '-074.1195136',
        ELSDLEA: '',
        UNSDLEA: '04660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0043, 40.9872],
            [-73.9979, 40.9815],
            [-73.9937, 40.9754],
            [-74.0018, 40.9708],
            [-74.0164, 40.9683],
            [-74.049, 40.9653],
            [-74.0552, 40.9698],
            [-74.0043, 40.9872],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404710',
        NAME: 'Emerson Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5711056,
        AWATER: 507123,
        INTPTLAT: '+40.9749901',
        INTPTLON: '-074.0232476',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7874, 41.0054],
            [-74.7406, 40.9687],
            [-74.7892, 40.9313],
            [-74.8217, 40.9607],
            [-74.8592, 40.9949],
            [-74.7874, 41.0054],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406090',
        NAME: 'Green Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41396886,
        AWATER: 707388,
        INTPTLAT: '+40.9740727',
        INTPTLON: '-074.8025843',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8843, 39.6021],
            [-74.8772, 39.6083],
            [-74.8591, 39.6241],
            [-74.8008, 39.5835],
            [-74.8259, 39.5617],
            [-74.8843, 39.6021],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405280',
        NAME: 'Folsom Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21243368,
        AWATER: 611736,
        INTPTLAT: '+39.5967446',
        INTPTLON: '-074.8431626',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3266, 40.6577],
            [-74.3123, 40.654],
            [-74.3197, 40.642],
            [-74.3266, 40.6577],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405790',
        NAME: 'Garwood Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1720230,
        AWATER: 0,
        INTPTLAT: '+40.6512488',
        INTPTLON: '-074.3231370',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3414, 39.8461],
            [-75.3165, 39.85],
            [-75.2571, 39.8518],
            [-75.2369, 39.8199],
            [-75.2863, 39.7928],
            [-75.3458, 39.8444],
            [-75.3414, 39.8461],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406180',
        NAME: 'Greenwich Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 23225011,
        AWATER: 7933720,
        INTPTLAT: '+39.8345817',
        INTPTLON: '-075.2889144',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.105, 40.7192],
            [-75.0792, 40.6599],
            [-75.1088, 40.641],
            [-75.1523, 40.6868],
            [-75.105, 40.7192],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406210',
        NAME: 'Greenwich Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 27271788,
        AWATER: 32998,
        INTPTLAT: '+40.6802735',
        INTPTLON: '-075.1187089',
        ELSDLEA: '',
        UNSDLEA: '06210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9893, 40.9115],
            [-73.9844, 40.9121],
            [-73.9492, 40.9008],
            [-73.9668, 40.8686],
            [-73.9757, 40.8689],
            [-73.9939, 40.8765],
            [-73.9893, 40.9115],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404740',
        NAME: 'Englewood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12733019,
        AWATER: 59635,
        INTPTLAT: '+40.8911966',
        INTPTLON: '-073.9725150',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.988, 40.8509],
            [-73.9757, 40.8689],
            [-73.9668, 40.8686],
            [-73.9484, 40.8585],
            [-73.954, 40.848],
            [-73.9764, 40.8276],
            [-73.9888, 40.8345],
            [-73.9913, 40.8359],
            [-73.988, 40.8509],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405310',
        NAME: 'Fort Lee Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6567812,
        AWATER: 915921,
        INTPTLAT: '+40.8506403',
        INTPTLON: '-073.9710074',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4932, 39.3648],
            [-74.4873, 39.3782],
            [-74.4883, 39.3859],
            [-74.461, 39.4155],
            [-74.4138, 39.3972],
            [-74.4063, 39.3705],
            [-74.4586, 39.3355],
            [-74.5016, 39.3573],
            [-74.4932, 39.3648],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400960',
        NAME: 'Atlantic City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27834683,
        AWATER: 16289930,
        INTPTLAT: '+39.3772970',
        INTPTLON: '-074.4510821',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.569, 39.3213],
              [-74.5503, 39.3538],
              [-74.5321, 39.375],
              [-74.5024, 39.3563],
              [-74.5064, 39.3444],
              [-74.5229, 39.3245],
              [-74.543, 39.3016],
              [-74.5569, 39.2988],
              [-74.5864, 39.3099],
              [-74.569, 39.3213],
            ],
          ],
          [
            [
              [-74.7178, 39.404],
              [-74.5863, 39.4664],
              [-74.5227, 39.4349],
              [-74.5211, 39.4184],
              [-74.5505, 39.3865],
              [-74.58, 39.3682],
              [-74.5961, 39.3361],
              [-74.626, 39.2928],
              [-74.6401, 39.296],
              [-74.6566, 39.3074],
              [-74.7178, 39.404],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404560',
        NAME: 'Egg Harbor Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172476681,
        AWATER: 21589706,
        INTPTLAT: '+39.3782911',
        INTPTLON: '-074.5997794',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9256, 40.0329],
            [-74.889, 40.0559],
            [-74.8557, 40.032],
            [-74.8724, 39.9977],
            [-74.8822, 40.0007],
            [-74.9046, 40.0136],
            [-74.9316, 40.0293],
            [-74.9256, 40.0329],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418000',
        NAME: 'Willingboro Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20042237,
        AWATER: 1065888,
        INTPTLAT: '+40.0279495',
        INTPTLON: '-074.8869838',
        ELSDLEA: '',
        UNSDLEA: '18000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5227, 39.4349],
            [-74.461, 39.4155],
            [-74.4883, 39.3859],
            [-74.5211, 39.4184],
            [-74.5227, 39.4349],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400660',
        NAME: 'Absecon City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13975072,
        AWATER: 4911451,
        INTPTLAT: '+39.4235629',
        INTPTLON: '-074.4930249',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8011, 39.6741],
            [-74.7363, 39.7299],
            [-74.7242, 39.7223],
            [-74.6758, 39.6913],
            [-74.7803, 39.6012],
            [-74.8008, 39.5835],
            [-74.8591, 39.6241],
            [-74.8011, 39.6741],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406570',
        NAME: 'Hammonton Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105897156,
        AWATER: 1377194,
        INTPTLAT: '+39.6607800',
        INTPTLON: '-074.7670211',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5229, 39.3245],
            [-74.5158, 39.3171],
            [-74.543, 39.3016],
            [-74.5229, 39.3245],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408671',
        NAME: 'Longport Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 997577,
        AWATER: 3042745,
        INTPTLAT: '+39.3113371',
        INTPTLON: '-074.5269565',
        ELSDLEA: '',
        UNSDLEA: '08671',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7333, 40.009],
            [-74.6572, 40.0108],
            [-74.6561, 40.0102],
            [-74.6193, 39.9939],
            [-74.6003, 40.0001],
            [-74.5999, 40.0002],
            [-74.5148, 40.0077],
            [-74.4953, 39.9696],
            [-74.4708, 39.9226],
            [-74.5965, 39.8988],
            [-74.7361, 39.9821],
            [-74.7333, 40.009],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412810',
        NAME: 'Pemberton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 169823942,
        AWATER: 3338537,
        INTPTLAT: '+39.9585870',
        INTPTLON: '-074.6046017',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0165, 40.9146],
            [-74.0105, 40.9349],
            [-73.9797, 40.9366],
            [-73.9798, 40.9341],
            [-73.9844, 40.9121],
            [-73.9893, 40.9115],
            [-74.0165, 40.9146],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401500',
        NAME: 'Bergenfield Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7449333,
        AWATER: 24382,
        INTPTLAT: '+40.9223342',
        INTPTLON: '-073.9980008',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.998, 40.8085],
            [-73.9962, 40.8245],
            [-73.9888, 40.8345],
            [-73.9764, 40.8276],
            [-73.9944, 40.8065],
            [-73.998, 40.8085],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403270',
        NAME: 'Cliffside Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2495479,
        AWATER: 0,
        INTPTLAT: '+40.8220838',
        INTPTLON: '-073.9878248',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9797, 40.9366],
            [-73.9757, 40.9531],
            [-73.9425, 40.9429],
            [-73.9417, 40.9259],
            [-73.9798, 40.9341],
            [-73.9797, 40.9366],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403600',
        NAME: 'Cresskill Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5345367,
        AWATER: 25725,
        INTPTLAT: '+40.9397855',
        INTPTLON: '-073.9585808',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2478, 40.6001],
            [-74.2037, 40.5927],
            [-74.2291, 40.5582],
            [-74.2478, 40.6001],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402820',
        NAME: 'Carteret Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11441567,
        AWATER: 1508587,
        INTPTLAT: '+40.5837895',
        INTPTLON: '-074.2274575',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4634, 40.5992],
            [-74.4559, 40.5955],
            [-74.4697, 40.5856],
            [-74.4781, 40.5934],
            [-74.4634, 40.5992],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404020',
        NAME: 'Dunellen Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2714765,
        AWATER: 0,
        INTPTLAT: '+40.5902312',
        INTPTLON: '-074.4655947',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8217, 40.9607],
            [-74.7892, 40.9313],
            [-74.7679, 40.9118],
            [-74.8068, 40.8821],
            [-74.8167, 40.875],
            [-74.897, 40.9272],
            [-74.8217, 40.9607],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400720',
        NAME: 'Allamuchy Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52990650,
        AWATER: 726146,
        INTPTLAT: '+40.9200334',
        INTPTLON: '-074.8197589',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3212, 39.611],
            [-75.2868, 39.5892],
            [-75.2395, 39.5592],
            [-75.2707, 39.5316],
            [-75.295, 39.5108],
            [-75.307, 39.5013],
            [-75.4047, 39.5788],
            [-75.3212, 39.611],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400810',
        NAME: 'Alloway Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86510068,
        AWATER: 1119941,
        INTPTLAT: '+39.5620722',
        INTPTLON: '-075.3105423',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1742, 40.6469],
            [-75.169, 40.6653],
            [-75.1515, 40.6724],
            [-75.1385, 40.6621],
            [-75.1742, 40.6469],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400840',
        NAME: 'Alpha Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4329348,
        AWATER: 67543,
        INTPTLAT: '+40.6594468',
        INTPTLON: '-075.1570525',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.945, 40.9575],
            [-73.9343, 40.977],
            [-73.926, 40.9936],
            [-73.9221, 41.0059],
            [-73.894, 40.9972],
            [-73.8988, 40.9748],
            [-73.9179, 40.9176],
            [-73.9199, 40.9136],
            [-73.9417, 40.9259],
            [-73.9425, 40.9429],
            [-73.945, 40.9575],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400870',
        NAME: 'Alpine Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16606290,
        AWATER: 7305646,
        INTPTLAT: '+40.9687824',
        INTPTLON: '-073.9168962',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5505, 39.3865],
            [-74.5211, 39.4184],
            [-74.4883, 39.3859],
            [-74.4873, 39.3782],
            [-74.4932, 39.3648],
            [-74.5016, 39.3573],
            [-74.5024, 39.3563],
            [-74.5321, 39.375],
            [-74.5505, 39.3865],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413200',
        NAME: 'Pleasantville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14746305,
        AWATER: 4154456,
        INTPTLAT: '+39.3888001',
        INTPTLON: '-074.5142625',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2091, 40.405],
            [-74.1332, 40.4266],
            [-74.1489, 40.3247],
            [-74.1976, 40.3454],
            [-74.2083, 40.3818],
            [-74.2091, 40.405],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407410',
        NAME: 'Holmdel Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46351492,
        AWATER: 564439,
        INTPTLAT: '+40.3749643',
        INTPTLON: '-074.1738486',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1242, 40.8226],
            [-74.1219, 40.8341],
            [-74.1214, 40.8472],
            [-74.0818, 40.788],
            [-74.0834, 40.7863],
            [-74.1242, 40.8226],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414460',
        NAME: 'Rutherford Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7285656,
        AWATER: 351607,
        INTPTLAT: '+40.8203143',
        INTPTLON: '-074.1060409',
        ELSDLEA: '',
        UNSDLEA: '14460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0302, 40.9132],
            [-74.0302, 40.9133],
            [-74.0165, 40.9146],
            [-73.9893, 40.9115],
            [-73.9939, 40.8765],
            [-74.0047, 40.8599],
            [-74.0225, 40.8659],
            [-74.0343, 40.8846],
            [-74.0302, 40.9132],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416080',
        NAME: 'Teaneck Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15607296,
        AWATER: 538107,
        INTPTLAT: '+40.8903167',
        INTPTLON: '-074.0114782',
        ELSDLEA: '',
        UNSDLEA: '16080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6474, 40.9995],
            [-74.6148, 40.9903],
            [-74.6362, 40.9426],
            [-74.6518, 40.9233],
            [-74.677, 40.9109],
            [-74.6854, 40.9137],
            [-74.6973, 40.9251],
            [-74.6474, 40.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407440',
        NAME: 'Hopatcong Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28112999,
        AWATER: 3797156,
        INTPTLAT: '+40.9516486',
        INTPTLON: '-074.6623770',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5367, 41.2847],
            [-74.3668, 41.2033],
            [-74.4826, 41.1038],
            [-74.5753, 41.1937],
            [-74.5367, 41.2847],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416710',
        NAME: 'Vernon Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174118547,
        AWATER: 6088172,
        INTPTLAT: '+41.1951935',
        INTPTLON: '-074.4887659',
        ELSDLEA: '',
        UNSDLEA: '16710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0599, 39.9915],
            [-75.0308, 39.9928],
            [-75.0192, 39.9675],
            [-75.0134, 39.9542],
            [-75.0369, 39.9497],
            [-75.0479, 39.9573],
            [-75.0515, 39.9521],
            [-75.0456, 39.9473],
            [-75.0662, 39.9259],
            [-75.0832, 39.9251],
            [-75.1238, 39.9627],
            [-75.0599, 39.9915],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412870',
        NAME: 'Pennsauken Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27026552,
        AWATER: 4264421,
        INTPTLAT: '+39.9683681',
        INTPTLON: '-075.0578512',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9684, 39.7671],
            [-74.9478, 39.7848],
            [-74.9133, 39.7765],
            [-74.8742, 39.7414],
            [-74.8895, 39.738],
            [-74.882, 39.7202],
            [-74.8615, 39.7311],
            [-74.8011, 39.6741],
            [-74.8591, 39.6241],
            [-74.8772, 39.6083],
            [-74.9306, 39.6908],
            [-75.0153, 39.7264],
            [-74.9684, 39.7671],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418060',
        NAME: 'Winslow Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148515157,
        AWATER: 2201358,
        INTPTLAT: '+39.7017223',
        INTPTLON: '-074.9083512',
        ELSDLEA: '',
        UNSDLEA: '18060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0621, 40.1207],
            [-74.0463, 40.1312],
            [-74.0283, 40.1174],
            [-74.0317, 40.101],
            [-74.0386, 40.1045],
            [-74.0621, 40.1207],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409420',
        NAME: 'Manasquan Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3582761,
        AWATER: 2970124,
        INTPTLAT: '+40.1125801',
        INTPTLON: '-074.0379065',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0321, 40.1654],
            [-74.0295, 40.1746],
            [-74.0206, 40.169],
            [-74.0321, 40.1654],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415150',
        NAME: 'Lake Como Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657433,
        AWATER: 30380,
        INTPTLAT: '+40.1700112',
        INTPTLON: '-074.0248613',
        ELSDLEA: '',
        UNSDLEA: '15150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6135, 40.1827],
            [-74.5429, 40.2167],
            [-74.4887, 40.2164],
            [-74.477, 40.212],
            [-74.4584, 40.1362],
            [-74.4695, 40.1286],
            [-74.5531, 40.0791],
            [-74.5878, 40.1382],
            [-74.6135, 40.1827],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416560',
        NAME: 'Upper Freehold Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121789869,
        AWATER: 2179819,
        INTPTLAT: '+40.1535728',
        INTPTLON: '-074.5293150',
        ELSDLEA: '',
        UNSDLEA: '16560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.0095, 40.2332],
              [-73.994, 40.2328],
              [-73.9947, 40.2301],
              [-74.0062, 40.2312],
              [-74.0095, 40.2332],
            ],
          ],
          [
            [
              [-74.0702, 40.2708],
              [-74.0276, 40.2727],
              [-74.0029, 40.2764],
              [-74.0002, 40.2634],
              [-74.008, 40.2399],
              [-74.0087, 40.2354],
              [-74.0138, 40.2302],
              [-74.0272, 40.225],
              [-74.0774, 40.2344],
              [-74.0702, 40.2708],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412060',
        NAME: 'Ocean Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 28402080,
        AWATER: 454926,
        INTPTLAT: '+40.2515342',
        INTPTLON: '-074.0388398',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9353, 39.039],
            [-74.8921, 39.1629],
            [-74.716, 39.1188],
            [-74.7163, 39.1174],
            [-74.7543, 39.0659],
            [-74.7895, 39.0225],
            [-74.8154, 38.9982],
            [-74.8162, 38.9988],
            [-74.8242, 39.0057],
            [-74.8307, 39.0015],
            [-74.8216, 38.9965],
            [-74.8333, 38.9918],
            [-74.9353, 39.039],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410020',
        NAME: 'Middle Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182166373,
        AWATER: 32674110,
        INTPTLAT: '+39.0933826',
        INTPTLON: '-074.8394332',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9844, 40.9121],
            [-73.9798, 40.9341],
            [-73.9417, 40.9259],
            [-73.9199, 40.9136],
            [-73.9271, 40.8955],
            [-73.9492, 40.9008],
            [-73.9844, 40.9121],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416110',
        NAME: 'Tenafly Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11916017,
        AWATER: 1510225,
        INTPTLAT: '+40.9183086',
        INTPTLON: '-073.9505227',
        ELSDLEA: '',
        UNSDLEA: '16110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1476, 41.0221],
            [-74.1213, 41.0199],
            [-74.0939, 41.0053],
            [-74.1225, 41.0061],
            [-74.1309, 41.0062],
            [-74.1375, 41.0075],
            [-74.1476, 41.0221],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416860',
        NAME: 'Waldwick Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5370452,
        AWATER: 51298,
        INTPTLAT: '+41.0136149',
        INTPTLON: '-074.1259191',
        ELSDLEA: '',
        UNSDLEA: '16860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3653, 40.7955],
            [-74.3343, 40.8184],
            [-74.2806, 40.807],
            [-74.305, 40.7579],
            [-74.3673, 40.7611],
            [-74.3653, 40.7955],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408820',
        NAME: 'Livingston Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35673087,
        AWATER: 808989,
        INTPTLAT: '+40.7858277',
        INTPTLON: '-074.3291000',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2348, 40.7507],
            [-74.19, 40.7686],
            [-74.1863, 40.7747],
            [-74.1483, 40.7799],
            [-74.1648, 40.7547],
            [-74.1661, 40.7474],
            [-74.1375, 40.7414],
            [-74.1171, 40.7104],
            [-74.1146, 40.7011],
            [-74.1367, 40.6744],
            [-74.209, 40.6904],
            [-74.2286, 40.7083],
            [-74.2437, 40.7308],
            [-74.2348, 40.7507],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411340',
        NAME: 'Newark City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62660655,
        AWATER: 4962770,
        INTPTLAT: '+40.7242204',
        INTPTLON: '-074.1725735',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0938, 39.8996],
            [-75.0734, 39.8984],
            [-75.0515, 39.8899],
            [-75.0801, 39.8817],
            [-75.0892, 39.8887],
            [-75.0938, 39.8996],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401050',
        NAME: 'Audubon Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4230611,
        AWATER: 67808,
        INTPTLAT: '+39.8909917',
        INTPTLON: '-075.0733288',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0192, 39.9675],
            [-75.0055, 39.973],
            [-74.9667, 39.9377],
            [-74.9837, 39.9269],
            [-75.0134, 39.9542],
            [-75.0192, 39.9675],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409660',
        NAME: 'Maple Shade Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9897789,
        AWATER: 3021,
        INTPTLAT: '+39.9523809',
        INTPTLON: '-074.9948958',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0914, 39.9211],
            [-75.0832, 39.9251],
            [-75.0662, 39.9259],
            [-75.0661, 39.9257],
            [-75.0709, 39.9065],
            [-75.0879, 39.9061],
            [-75.0923, 39.9096],
            [-75.0961, 39.9137],
            [-75.0914, 39.9211],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403420',
        NAME: 'Collingswood Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4721352,
        AWATER: 258527,
        INTPTLAT: '+39.9152748',
        INTPTLON: '-075.0783915',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5045, 39.6982],
            [-75.4762, 39.6727],
            [-75.4754, 39.5785],
            [-75.4945, 39.5761],
            [-75.513, 39.5784],
            [-75.5597, 39.6304],
            [-75.5045, 39.6982],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409120',
        NAME: 'Pennsville Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55110675,
        AWATER: 8572045,
        INTPTLAT: '+39.6263411',
        INTPTLON: '-075.5034509',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4705, 39.7419],
            [-75.4662, 39.7508],
            [-75.3894, 39.6816],
            [-75.4095, 39.6608],
            [-75.4762, 39.6727],
            [-75.5045, 39.6982],
            [-75.4705, 39.7419],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412840',
        NAME: 'Penns Grove-Carneys Point Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46034929,
        AWATER: 2269893,
        INTPTLAT: '+39.6992576',
        INTPTLON: '-075.4451796',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1302, 40.8869],
            [-74.1042, 40.8937],
            [-74.0919, 40.8866],
            [-74.0972, 40.8672],
            [-74.1016, 40.8638],
            [-74.1073, 40.8631],
            [-74.1178, 40.8768],
            [-74.1302, 40.8869],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405760',
        NAME: 'Garfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5443871,
        AWATER: 155964,
        INTPTLAT: '+40.8797971',
        INTPTLON: '-074.1082503',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.141, 40.9663],
            [-74.1, 40.9553],
            [-74.1464, 40.9484],
            [-74.141, 40.9663],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405970',
        NAME: 'Glen Rock Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7034337,
        AWATER: 58402,
        INTPTLAT: '+40.9594705',
        INTPTLON: '-074.1252020',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0876, 40.8614],
            [-74.0684, 40.876],
            [-74.0583, 40.8707],
            [-74.0517, 40.8576],
            [-74.054, 40.8539],
            [-74.0751, 40.8486],
            [-74.0876, 40.8614],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406930',
        NAME: 'Hasbrouck Heights Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6883274,
        AWATER: 12757,
        INTPTLAT: '+40.8610248',
        INTPTLON: '-074.0679589',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0047, 40.8599],
            [-73.9939, 40.8765],
            [-73.9757, 40.8689],
            [-73.988, 40.8509],
            [-74.0061, 40.857],
            [-74.0047, 40.8599],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408520',
        NAME: 'Leonia Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3999542,
        AWATER: 233106,
        INTPTLAT: '+40.8633911',
        INTPTLON: '-073.9884656',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0919, 40.8866],
            [-74.0789, 40.8951],
            [-74.0733, 40.8922],
            [-74.0641, 40.8868],
            [-74.0684, 40.876],
            [-74.0876, 40.8614],
            [-74.0939, 40.8651],
            [-74.0972, 40.8672],
            [-74.0919, 40.8866],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408850',
        NAME: 'Lodi Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5865712,
        AWATER: 52831,
        INTPTLAT: '+40.8779151',
        INTPTLON: '-074.0825000',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1289, 39.9074],
            [-75.101, 39.8987],
            [-75.0965, 39.8899],
            [-75.1081, 39.878],
            [-75.1274, 39.8808],
            [-75.1398, 39.8883],
            [-75.1289, 39.9074],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406000',
        NAME: 'Gloucester City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6028796,
        AWATER: 1197310,
        INTPTLAT: '+39.8916942',
        INTPTLON: '-075.1166923',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0801, 39.8817],
            [-75.0515, 39.8899],
            [-75.046, 39.8831],
            [-75.0677, 39.8721],
            [-75.0821, 39.8724],
            [-75.0801, 39.8817],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406330',
        NAME: 'Haddon Heights Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4062714,
        AWATER: 13573,
        INTPTLAT: '+39.8791269',
        INTPTLON: '-075.0659184',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0286, 39.8341],
            [-75.0184, 39.8408],
            [-75.0283, 39.8316],
            [-75.0286, 39.8341],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407080',
        NAME: 'Hi-Nella Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 597162,
        AWATER: 0,
        INTPTLAT: '+39.8365508',
        INTPTLON: '-075.0218430',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0948, 39.9015],
            [-75.0879, 39.9061],
            [-75.0709, 39.9065],
            [-75.0734, 39.8984],
            [-75.0938, 39.8996],
            [-75.0948, 39.9015],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411940',
        NAME: 'Oaklyn Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1625773,
        AWATER: 170280,
        INTPTLAT: '+39.9012913',
        INTPTLON: '-075.0798269',
        ELSDLEA: '',
        UNSDLEA: '11940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9819, 39.7974],
            [-74.9729, 39.7929],
            [-74.9595, 39.7832],
            [-74.9685, 39.7775],
            [-74.9854, 39.7879],
            [-74.9819, 39.7974],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413020',
        NAME: 'Pine Valley Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2540913,
        AWATER: 39145,
        INTPTLAT: '+39.7882842',
        INTPTLON: '-074.9748820',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0662, 39.9259],
            [-75.0456, 39.9473],
            [-75.0369, 39.9497],
            [-75.0134, 39.9542],
            [-74.9837, 39.9269],
            [-74.9572, 39.9143],
            [-74.9269, 39.8738],
            [-75.016, 39.8593],
            [-75.0165, 39.8719],
            [-75.0393, 39.9165],
            [-75.0661, 39.9257],
            [-75.0662, 39.9259],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403000',
        NAME: 'Cherry Hill Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62410692,
        AWATER: 319277,
        INTPTLAT: '+39.9046107',
        INTPTLON: '-074.9969997',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0393, 39.9165],
            [-75.0165, 39.8719],
            [-75.0371, 39.8789],
            [-75.046, 39.8831],
            [-75.0515, 39.8899],
            [-75.0393, 39.9165],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406390',
        NAME: 'Haddonfield Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7972342,
        AWATER: 127067,
        INTPTLAT: '+39.8937041',
        INTPTLON: '-075.0335578',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1367, 40.6744],
            [-74.1146, 40.7011],
            [-74.0496, 40.6616],
            [-74.1614, 40.6443],
            [-74.1367, 40.6744],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401260',
        NAME: 'Bayonne City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15056054,
        AWATER: 13647517,
        INTPTLAT: '+40.6625304',
        INTPTLON: '-074.1101916',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1661, 40.7474],
            [-74.1575, 40.7522],
            [-74.1375, 40.7414],
            [-74.1661, 40.7474],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406870',
        NAME: 'Harrison Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3116003,
        AWATER: 298890,
        INTPTLAT: '+40.7429780',
        INTPTLON: '-074.1529113',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3651, 40.7497],
            [-74.3673, 40.7611],
            [-74.305, 40.7579],
            [-74.2904, 40.7576],
            [-74.2853, 40.7221],
            [-74.2987, 40.7148],
            [-74.3321, 40.7202],
            [-74.3719, 40.7392],
            [-74.3651, 40.7497],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410200',
        NAME: 'Millburn Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24157643,
        AWATER: 1422039,
        INTPTLAT: '+40.7416078',
        INTPTLON: '-074.3212863',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2643, 40.8254],
            [-74.2598, 40.8349],
            [-74.2519, 40.8498],
            [-74.221, 40.8348],
            [-74.2326, 40.8149],
            [-74.2643, 40.8254],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416740',
        NAME: 'Verona Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7137693,
        AWATER: 54370,
        INTPTLAT: '+40.8340073',
        INTPTLON: '-074.2428767',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0153, 39.7264],
            [-74.9306, 39.6908],
            [-74.8772, 39.6083],
            [-74.8843, 39.6021],
            [-74.9116, 39.5786],
            [-75.0318, 39.6626],
            [-75.0548, 39.6786],
            [-75.0917, 39.7076],
            [-75.0153, 39.7264],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410470',
        NAME: 'Monroe Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 120158299,
        AWATER: 1381032,
        INTPTLAT: '+39.6599459',
        INTPTLON: '-074.9692619',
        ELSDLEA: '',
        UNSDLEA: '10470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2286, 39.8586],
            [-75.2115, 39.867],
            [-75.1999, 39.8732],
            [-75.1821, 39.8568],
            [-75.169, 39.8635],
            [-75.1893, 39.8807],
            [-75.1456, 39.8845],
            [-75.1354, 39.8617],
            [-75.1428, 39.8507],
            [-75.1606, 39.8241],
            [-75.1657, 39.811],
            [-75.1721, 39.7949],
            [-75.1877, 39.7967],
            [-75.2277, 39.8261],
            [-75.2286, 39.8586],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417430',
        NAME: 'West Deptford Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39915566,
        AWATER: 6355195,
        INTPTLAT: '+39.8419691',
        INTPTLON: '-075.1850793',
        ELSDLEA: '',
        UNSDLEA: '17430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1074, 39.6795],
            [-75.0548, 39.6786],
            [-75.0318, 39.6626],
            [-75.1007, 39.6404],
            [-75.1074, 39.6795],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403180',
        NAME: 'Clayton Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18492259,
        AWATER: 501730,
        INTPTLAT: '+39.6637555',
        INTPTLON: '-075.0775045',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0376, 40.7506],
            [-74.0327, 40.7589],
            [-74.0138, 40.7566],
            [-74.0204, 40.7303],
            [-74.0376, 40.7506],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407350',
        NAME: 'Hoboken City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3263378,
        AWATER: 1942517,
        INTPTLAT: '+40.7452508',
        INTPTLON: '-074.0279257',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0231, 40.7829],
            [-74.0148, 40.7797],
            [-74.0327, 40.7589],
            [-74.0376, 40.7506],
            [-74.0458, 40.7574],
            [-74.0231, 40.7829],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416380',
        NAME: 'Union City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3322169,
        AWATER: 0,
        INTPTLAT: '+40.7674712',
        INTPTLON: '-074.0322900',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2205, 39.547],
            [-75.1841, 39.5838],
            [-75.1669, 39.5851],
            [-75.1668, 39.5968],
            [-75.0875, 39.5869],
            [-75.062, 39.5686],
            [-75.084, 39.4574],
            [-75.1742, 39.5173],
            [-75.2205, 39.547],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413110',
        NAME: 'Pittsgrove Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116764975,
        AWATER: 2155807,
        INTPTLAT: '+39.5426497',
        INTPTLON: '-075.1299006',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4436, 40.7717],
            [-74.3921, 40.7514],
            [-74.3651, 40.7497],
            [-74.3719, 40.7392],
            [-74.3928, 40.718],
            [-74.429, 40.6988],
            [-74.4443, 40.688],
            [-74.4497, 40.7188],
            [-74.4436, 40.7717],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400004',
        NAME: 'The Chathams School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29309160,
        AWATER: 1108739,
        INTPTLAT: '+40.7305218',
        INTPTLON: '-074.4190802',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5361, 40.8221],
            [-74.534, 40.8331],
            [-74.4955, 40.8252],
            [-74.4744, 40.8198],
            [-74.4342, 40.7911],
            [-74.4408, 40.7774],
            [-74.4419, 40.7749],
            [-74.5422, 40.7894],
            [-74.5361, 40.8221],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410810',
        NAME: 'Morris Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48197084,
        AWATER: 596569,
        INTPTLAT: '+40.7963215',
        INTPTLON: '-074.4906524',
        ELSDLEA: '',
        UNSDLEA: '10810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4658, 40.885],
            [-74.4171, 40.8963],
            [-74.3833, 40.8995],
            [-74.342, 40.8468],
            [-74.384, 40.825],
            [-74.4642, 40.8358],
            [-74.4955, 40.8252],
            [-74.534, 40.8331],
            [-74.4658, 40.885],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412480',
        NAME: 'Parsippany-Troy Hills Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60966602,
        AWATER: 4737753,
        INTPTLAT: '+40.8596359',
        INTPTLON: '-074.4233483',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6237, 40.8668],
            [-74.5791, 40.8776],
            [-74.5401, 40.8839],
            [-74.5249, 40.8806],
            [-74.534, 40.8331],
            [-74.5361, 40.8221],
            [-74.6309, 40.8047],
            [-74.6454, 40.8369],
            [-74.6237, 40.8668],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413650',
        NAME: 'Randolph Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53992908,
        AWATER: 685914,
        INTPTLAT: '+40.8416908',
        INTPTLON: '-074.5783300',
        ELSDLEA: '',
        UNSDLEA: '13650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6922, 40.9044],
            [-74.6832, 40.9101],
            [-74.677, 40.9109],
            [-74.6518, 40.9233],
            [-74.6223, 40.9395],
            [-74.5946, 40.9021],
            [-74.5965, 40.901],
            [-74.6237, 40.8668],
            [-74.6454, 40.8369],
            [-74.6817, 40.8279],
            [-74.7018, 40.8911],
            [-74.6922, 40.9044],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414340',
        NAME: 'Roxbury Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53977813,
        AWATER: 2763682,
        INTPTLAT: '+40.8883960',
        INTPTLON: '-074.6515010',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0024, 40.8604],
            [-74.9279, 40.9068],
            [-74.897, 40.9272],
            [-74.8167, 40.875],
            [-74.8505, 40.8413],
            [-74.9532, 40.8327],
            [-75.0024, 40.8604],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400008',
        NAME: 'Great Meadows Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81190008,
        AWATER: 1067682,
        INTPTLAT: '+40.8692829',
        INTPTLON: '-074.9106124',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0148, 40.7797],
            [-74.0013, 40.7744],
            [-74.0138, 40.7566],
            [-74.0327, 40.7589],
            [-74.0148, 40.7797],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417310',
        NAME: 'Weehawken Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2032310,
        AWATER: 1797432,
        INTPTLAT: '+40.7689850',
        INTPTLON: '-074.0154055',
        ELSDLEA: '',
        UNSDLEA: '17310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0157, 40.7966],
            [-73.9929, 40.7863],
            [-74.0013, 40.7744],
            [-74.0148, 40.7797],
            [-74.0231, 40.7829],
            [-74.0157, 40.7966],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417580',
        NAME: 'West New York Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2575990,
        AWATER: 864403,
        INTPTLAT: '+40.7860317',
        INTPTLON: '-074.0095067',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4754, 39.5785],
            [-75.4524, 39.567],
            [-75.4603, 39.5557],
            [-75.4945, 39.5761],
            [-75.4754, 39.5785],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414550',
        NAME: 'Salem City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6069563,
        AWATER: 1221411,
        INTPTLAT: '+39.5682361',
        INTPTLON: '-075.4725530',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3894, 39.6816],
            [-75.3604, 39.7132],
            [-75.3209, 39.6912],
            [-75.2481, 39.6649],
            [-75.2868, 39.5892],
            [-75.3212, 39.611],
            [-75.4095, 39.6608],
            [-75.3894, 39.6816],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418330',
        NAME: 'Woodstown-Pilesgrove Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94347507,
        AWATER: 701198,
        INTPTLAT: '+39.6574256',
        INTPTLON: '-075.3188838',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1073, 40.8631],
            [-74.1016, 40.8638],
            [-74.0978, 40.8615],
            [-74.0989, 40.8504],
            [-74.1028, 40.8415],
            [-74.1195, 40.8533],
            [-74.1073, 40.8631],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416920',
        NAME: 'Wallington Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2547380,
        AWATER: 130735,
        INTPTLAT: '+40.8530836',
        INTPTLON: '-074.1063227',
        ELSDLEA: '',
        UNSDLEA: '16920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0978, 40.8615],
            [-74.0939, 40.8651],
            [-74.0876, 40.8614],
            [-74.0751, 40.8486],
            [-74.0761, 40.8376],
            [-74.0989, 40.8504],
            [-74.0978, 40.8615],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418300',
        NAME: 'Wood-Ridge Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2841136,
        AWATER: 303,
        INTPTLAT: '+40.8501831',
        INTPTLON: '-074.0870676',
        ELSDLEA: '',
        UNSDLEA: '18300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0061, 40.857],
            [-73.988, 40.8509],
            [-73.9913, 40.8359],
            [-74.0107, 40.8462],
            [-74.0061, 40.857],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412360',
        NAME: 'Palisades Park Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3238863,
        AWATER: 63688,
        INTPTLAT: '+40.8470173',
        INTPTLON: '-073.9970615',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1695, 40.9466],
            [-74.171, 40.977],
            [-74.1491, 40.9711],
            [-74.141, 40.9663],
            [-74.1464, 40.9484],
            [-74.1481, 40.9416],
            [-74.1666, 40.9343],
            [-74.1695, 40.9466],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406990',
        NAME: 'Hawthorne Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8637257,
        AWATER: 78862,
        INTPTLAT: '+40.9569572',
        INTPTLON: '-074.1585611',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1178, 40.8768],
            [-74.1073, 40.8631],
            [-74.1195, 40.8533],
            [-74.1214, 40.8472],
            [-74.1219, 40.8341],
            [-74.1467, 40.8489],
            [-74.1178, 40.8768],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412540',
        NAME: 'Passaic City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8150093,
        AWATER: 251303,
        INTPTLAT: '+40.8564132',
        INTPTLON: '-074.1269404',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2705, 41.0221],
            [-74.2667, 41.0141],
            [-74.2813, 40.98],
            [-74.2973, 40.9836],
            [-74.3003, 41.0027],
            [-74.303, 41.0114],
            [-74.2705, 41.0221],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413350',
        NAME: 'Pompton Lakes Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7533074,
        AWATER: 724797,
        INTPTLAT: '+41.0030951',
        INTPTLON: '-074.2854545',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7242, 40.1473],
            [-74.6773, 40.1673],
            [-74.6989, 40.118],
            [-74.7612, 40.1214],
            [-74.776, 40.125],
            [-74.7242, 40.1473],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402030',
        NAME: 'Bordentown Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25156530,
        AWATER: 2222045,
        INTPTLAT: '+40.1432811',
        INTPTLON: '-074.7102614',
        ELSDLEA: '',
        UNSDLEA: '02030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8988, 40.0733],
            [-74.8804, 40.079],
            [-74.8289, 40.0711],
            [-74.8382, 40.1011],
            [-74.834, 40.1078],
            [-74.8287, 40.1203],
            [-74.8023, 40.073],
            [-74.7975, 40.0442],
            [-74.8557, 40.032],
            [-74.889, 40.0559],
            [-74.8988, 40.0733],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402460',
        NAME: 'Burlington Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34655228,
        AWATER: 1463274,
        INTPTLAT: '+40.0621640',
        INTPTLON: '-074.8368831',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0308, 39.9928],
            [-75.0102, 40.0033],
            [-75.0138, 40.0202],
            [-74.9902, 40.0363],
            [-74.9583, 39.9918],
            [-75.0055, 39.973],
            [-75.0192, 39.9675],
            [-75.0308, 39.9928],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403120',
        NAME: 'Cinnaminson Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19437446,
        AWATER: 1439050,
        INTPTLAT: '+40.0001617',
        INTPTLON: '-074.9916319',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8156, 40.1277],
            [-74.776, 40.125],
            [-74.7612, 40.1214],
            [-74.7722, 40.0668],
            [-74.8023, 40.073],
            [-74.8287, 40.1203],
            [-74.8156, 40.1277],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405220',
        NAME: 'Florence Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25331250,
        AWATER: 1028775,
        INTPTLAT: '+40.0946971',
        INTPTLON: '-074.7839118',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2444, 40.7112],
            [-74.2286, 40.7083],
            [-74.209, 40.6904],
            [-74.2223, 40.675],
            [-74.2444, 40.7112],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407290',
        NAME: 'Hillside Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7120903,
        AWATER: 27772,
        INTPTLAT: '+40.6958894',
        INTPTLON: '-074.2288001',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3104, 40.6784],
            [-74.3078, 40.6803],
            [-74.2718, 40.6708],
            [-74.2856, 40.666],
            [-74.3104, 40.6784],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407920',
        NAME: 'Kenilworth Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5587767,
        AWATER: 10391,
        INTPTLAT: '+40.6774198',
        INTPTLON: '-074.2893410',
        ELSDLEA: '',
        UNSDLEA: '07920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3928, 40.718],
            [-74.3836, 40.6961],
            [-74.429, 40.6988],
            [-74.3928, 40.718],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411310',
        NAME: 'New Providence Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9428375,
        AWATER: 63643,
        INTPTLAT: '+40.6988898',
        INTPTLON: '-074.4066229',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3034, 40.6089],
            [-74.2769, 40.6275],
            [-74.2581, 40.6022],
            [-74.3034, 40.6089],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413530',
        NAME: 'Rahway City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10107848,
        AWATER: 336348,
        INTPTLAT: '+40.6071525',
        INTPTLON: '-074.2805307',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2835, 40.6582],
            [-74.2467, 40.6632],
            [-74.2333, 40.6545],
            [-74.2815, 40.6501],
            [-74.2835, 40.6582],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414280',
        NAME: 'Roselle Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6866740,
        AWATER: 32524,
        INTPTLAT: '+40.6522106',
        INTPTLON: '-074.2601578',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2856, 40.666],
            [-74.2718, 40.6708],
            [-74.2542, 40.6702],
            [-74.2467, 40.6632],
            [-74.2835, 40.6582],
            [-74.2856, 40.666],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414310',
        NAME: 'Roselle Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3190279,
        AWATER: 0,
        INTPTLAT: '+40.6652658',
        INTPTLON: '-074.2667075',
        ELSDLEA: '',
        UNSDLEA: '14310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3321, 40.7202],
            [-74.2987, 40.7148],
            [-74.3078, 40.6803],
            [-74.3104, 40.6784],
            [-74.3224, 40.6774],
            [-74.3314, 40.6822],
            [-74.3604, 40.6998],
            [-74.3321, 40.7202],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415630',
        NAME: 'Springfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 13401021,
        AWATER: 48258,
        INTPTLAT: '+40.6978929',
        INTPTLON: '-074.3345348',
        ELSDLEA: '',
        UNSDLEA: '15630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8566, 40.0913],
            [-74.8382, 40.1011],
            [-74.8289, 40.0711],
            [-74.8804, 40.079],
            [-74.8566, 40.0913],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402430',
        NAME: 'Burlington City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7931931,
        AWATER: 1861012,
        INTPTLAT: '+40.0783164',
        INTPTLON: '-074.8532279',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9828, 40.0418],
            [-74.9728, 40.0423],
            [-74.9616, 40.0241],
            [-74.9368, 40.0335],
            [-74.9316, 40.0293],
            [-74.9046, 40.0136],
            [-74.9583, 39.9918],
            [-74.9902, 40.0363],
            [-74.9828, 40.0418],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403780',
        NAME: 'Delran Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17071042,
        AWATER: 1593073,
        INTPTLAT: '+40.0130239',
        INTPTLON: '-074.9474228',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0055, 39.973],
            [-74.9583, 39.9918],
            [-74.9046, 40.0136],
            [-74.8822, 40.0007],
            [-74.9667, 39.9377],
            [-75.0055, 39.973],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410710',
        NAME: 'Moorestown Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38055149,
        AWATER: 582785,
        INTPTLAT: '+39.9770739',
        INTPTLON: '-074.9449976',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0306, 40.0162],
            [-75.0102, 40.0033],
            [-75.0308, 39.9928],
            [-75.0599, 39.9915],
            [-75.0306, 40.0162],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412390',
        NAME: 'Palmyra Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4828311,
        AWATER: 1776895,
        INTPTLAT: '+40.0026146',
        INTPTLON: '-075.0352732',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9728, 40.0423],
            [-74.9368, 40.0335],
            [-74.9616, 40.0241],
            [-74.9728, 40.0423],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414010',
        NAME: 'Riverside Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3856465,
        AWATER: 323202,
        INTPTLAT: '+40.0356770',
        INTPTLON: '-074.9558009',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.146, 39.4309],
            [-75.0849, 39.444],
            [-74.9588, 39.3777],
            [-75.0119, 39.3368],
            [-75.0627, 39.3475],
            [-75.0807, 39.3488],
            [-75.1262, 39.392],
            [-75.146, 39.4309],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410320',
        NAME: 'Millville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108814020,
        AWATER: 6413875,
        INTPTLAT: '+39.3900935',
        INTPTLON: '-075.0547965',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2394, 39.4463],
            [-75.2085, 39.4356],
            [-75.232, 39.4028],
            [-75.2394, 39.4463],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402250',
        NAME: 'Bridgeton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16003480,
        AWATER: 652999,
        INTPTLAT: '+39.4275181',
        INTPTLON: '-075.2279544',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5177, 40.5874],
            [-74.4824, 40.6213],
            [-74.4417, 40.6294],
            [-74.4573, 40.6022],
            [-74.4634, 40.5992],
            [-74.4781, 40.5934],
            [-74.5122, 40.582],
            [-74.5177, 40.5874],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406120',
        NAME: 'Green Brook Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11583851,
        AWATER: 21004,
        INTPTLAT: '+40.6038315',
        INTPTLON: '-074.4832080',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4417, 40.6294],
            [-74.4137, 40.641],
            [-74.4573, 40.6022],
            [-74.4417, 40.6294],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411640',
        NAME: 'North Plainfield Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7237471,
        AWATER: 32481,
        INTPTLAT: '+40.6212554',
        INTPTLON: '-074.4394664',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1238, 39.9627],
            [-75.0832, 39.9251],
            [-75.0914, 39.9211],
            [-75.102, 39.9179],
            [-75.0961, 39.9137],
            [-75.0923, 39.9096],
            [-75.0948, 39.9015],
            [-75.101, 39.8987],
            [-75.1289, 39.9074],
            [-75.1238, 39.9627],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402640',
        NAME: 'Camden City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23106017,
        AWATER: 3677478,
        INTPTLAT: '+39.9367868',
        INTPTLON: '-075.1066438',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0237, 39.8042],
            [-75.0003, 39.8054],
            [-74.9819, 39.7974],
            [-74.9854, 39.7879],
            [-74.9685, 39.7775],
            [-74.9595, 39.7832],
            [-74.9729, 39.7929],
            [-74.9661, 39.7997],
            [-74.9478, 39.7848],
            [-74.9684, 39.7671],
            [-75.0237, 39.8042],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412990',
        NAME: 'Pine Hill Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10026102,
        AWATER: 109439,
        INTPTLAT: '+39.7889809',
        INTPTLON: '-074.9868714',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.028, 39.8214],
            [-75.0151, 39.8188],
            [-75.0024, 39.8165],
            [-74.9982, 39.8255],
            [-75.005, 39.8341],
            [-75.0016, 39.8365],
            [-74.9859, 39.8341],
            [-74.958, 39.8176],
            [-74.9569, 39.8167],
            [-74.957, 39.8092],
            [-74.9661, 39.7997],
            [-75.0003, 39.8054],
            [-75.0237, 39.8042],
            [-75.028, 39.8214],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408640',
        NAME: 'Lindenwold Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10086172,
        AWATER: 103755,
        INTPTLAT: '+39.8172275',
        INTPTLON: '-074.9888904',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.0709, 39.9065],
              [-75.0661, 39.9257],
              [-75.0393, 39.9165],
              [-75.0515, 39.8899],
              [-75.0734, 39.8984],
              [-75.0709, 39.9065],
            ],
          ],
          [
            [
              [-75.0923, 39.9096],
              [-75.0879, 39.9061],
              [-75.0948, 39.9015],
              [-75.0923, 39.9096],
            ],
          ],
          [
            [
              [-75.101, 39.8987],
              [-75.0948, 39.9015],
              [-75.0938, 39.8996],
              [-75.0892, 39.8887],
              [-75.0965, 39.8899],
              [-75.101, 39.8987],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406360',
        NAME: 'Haddon Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6964272,
        AWATER: 333820,
        INTPTLAT: '+39.9062159',
        INTPTLON: '-075.0629510',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7319, 40.2421],
            [-74.664, 40.2672],
            [-74.6496, 40.242],
            [-74.6135, 40.1827],
            [-74.5878, 40.1382],
            [-74.5948, 40.1379],
            [-74.6773, 40.1673],
            [-74.7242, 40.1473],
            [-74.7517, 40.1838],
            [-74.7319, 40.2421],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406540',
        NAME: 'Hamilton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 102301225,
        AWATER: 2301382,
        INTPTLAT: '+40.2062665',
        INTPTLON: '-074.6752741',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7619, 40.2842],
            [-74.7143, 40.3605],
            [-74.6846, 40.3049],
            [-74.664, 40.2672],
            [-74.7319, 40.2421],
            [-74.7507, 40.2419],
            [-74.7619, 40.2842],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408400',
        NAME: 'Lawrence Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 56483062,
        AWATER: 659784,
        INTPTLAT: '+40.2958873',
        INTPTLON: '-074.7200934',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6239, 40.3666],
            [-74.5485, 40.3364],
            [-74.5676, 40.2999],
            [-74.5792, 40.2664],
            [-74.6496, 40.242],
            [-74.664, 40.2672],
            [-74.6846, 40.3049],
            [-74.6239, 40.3666],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417700',
        NAME: 'West Windsor-Plainsboro Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96761330,
        AWATER: 2923110,
        INTPTLAT: '+40.3032972',
        INTPTLON: '-074.6125044',
        ELSDLEA: '',
        UNSDLEA: '17700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3256, 40.6251],
            [-74.3132, 40.6382],
            [-74.2898, 40.64],
            [-74.2902, 40.6385],
            [-74.2928, 40.6337],
            [-74.2853, 40.6349],
            [-74.2769, 40.6275],
            [-74.3034, 40.6089],
            [-74.3385, 40.6055],
            [-74.3591, 40.6035],
            [-74.3256, 40.6251],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403150',
        NAME: 'Clark Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11146643,
        AWATER: 491376,
        INTPTLAT: '+40.6184734',
        INTPTLON: '-074.3160046',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3794, 40.6676],
            [-74.3314, 40.6822],
            [-74.3224, 40.6774],
            [-74.3266, 40.6577],
            [-74.3197, 40.642],
            [-74.3132, 40.6382],
            [-74.3256, 40.6251],
            [-74.3794, 40.6676],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417760',
        NAME: 'Westfield Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17401268,
        AWATER: 62226,
        INTPTLAT: '+40.6516437',
        INTPTLON: '-074.3434472',
        ELSDLEA: '',
        UNSDLEA: '17760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4634, 40.5992],
            [-74.4573, 40.6022],
            [-74.4137, 40.641],
            [-74.4083, 40.6442],
            [-74.3946, 40.6009],
            [-74.4373, 40.5972],
            [-74.4559, 40.5955],
            [-74.4634, 40.5992],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413140',
        NAME: 'Plainfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15598582,
        AWATER: 27384,
        INTPTLAT: '+40.6154442',
        INTPTLON: '-074.4157753',
        ELSDLEA: '',
        UNSDLEA: '13140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3928, 40.718],
            [-74.3719, 40.7392],
            [-74.3321, 40.7202],
            [-74.3604, 40.6998],
            [-74.3794, 40.6854],
            [-74.3836, 40.6961],
            [-74.3928, 40.718],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415900',
        NAME: 'Summit City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15529111,
        AWATER: 133284,
        INTPTLAT: '+40.7156222',
        INTPTLON: '-074.3646841',
        ELSDLEA: '',
        UNSDLEA: '15900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6309, 40.6677],
            [-74.549, 40.741],
            [-74.5137, 40.6977],
            [-74.5238, 40.6496],
            [-74.5631, 40.6107],
            [-74.6242, 40.6493],
            [-74.6309, 40.6677],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401650',
        NAME: 'Bernards Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61977949,
        AWATER: 329754,
        INTPTLAT: '+40.6774549',
        INTPTLON: '-074.5672771',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8565, 40.2774],
            [-74.7619, 40.2842],
            [-74.7507, 40.2419],
            [-74.8195, 40.2385],
            [-74.8531, 40.2697],
            [-74.8565, 40.2774],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404920',
        NAME: 'Ewing Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39499715,
        AWATER: 900734,
        INTPTLAT: '+40.2627220',
        INTPTLON: '-074.7983066',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9429, 40.3417],
            [-74.8304, 40.3821],
            [-74.7481, 40.4242],
            [-74.7222, 40.3752],
            [-74.7143, 40.3605],
            [-74.7619, 40.2842],
            [-74.8565, 40.2774],
            [-74.9269, 40.3295],
            [-74.9429, 40.3417],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407530',
        NAME: 'Hopewell Valley Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154597141,
        AWATER: 2283938,
        INTPTLAT: '+40.3563619',
        INTPTLON: '-074.8120022',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7507, 40.2419],
            [-74.7319, 40.2421],
            [-74.7517, 40.1838],
            [-74.7609, 40.1992],
            [-74.7819, 40.2219],
            [-74.8195, 40.2385],
            [-74.7507, 40.2419],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416290',
        NAME: 'Trenton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19809454,
        AWATER: 1312654,
        INTPTLAT: '+40.2237484',
        INTPTLON: '-074.7640005',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5676, 40.2999],
            [-74.4888, 40.2766],
            [-74.4843, 40.2533],
            [-74.5429, 40.2167],
            [-74.5792, 40.2664],
            [-74.5676, 40.2999],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404320',
        NAME: 'East Windsor Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43666935,
        AWATER: 330052,
        INTPTLAT: '+40.2605151',
        INTPTLON: '-074.5280740',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7222, 40.3752],
            [-74.6227, 40.3844],
            [-74.6196, 40.3744],
            [-74.6239, 40.3666],
            [-74.6846, 40.3049],
            [-74.7143, 40.3605],
            [-74.7222, 40.3752],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413410',
        NAME: 'Princeton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46443932,
        AWATER: 1115431,
        INTPTLAT: '+40.3580611',
        INTPTLON: '-074.6664059',
        ELSDLEA: '',
        UNSDLEA: '13410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4612, 40.6734],
            [-74.4443, 40.688],
            [-74.429, 40.6988],
            [-74.3836, 40.6961],
            [-74.3794, 40.6854],
            [-74.3876, 40.6782],
            [-74.4034, 40.6665],
            [-74.4485, 40.6554],
            [-74.4612, 40.6734],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401530',
        NAME: 'Berkeley Heights Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16086941,
        AWATER: 142492,
        INTPTLAT: '+40.6763668',
        INTPTLON: '-074.4249897',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2223, 40.675],
            [-74.209, 40.6904],
            [-74.1367, 40.6744],
            [-74.1614, 40.6443],
            [-74.2005, 40.6326],
            [-74.2333, 40.6545],
            [-74.2467, 40.6632],
            [-74.2542, 40.6702],
            [-74.2223, 40.675],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404590',
        NAME: 'Elizabeth City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31904152,
        AWATER: 2960471,
        INTPTLAT: '+40.6663674',
        INTPTLON: '-074.1935028',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2898, 40.64],
            [-74.2815, 40.6501],
            [-74.2333, 40.6545],
            [-74.2005, 40.6326],
            [-74.2037, 40.5927],
            [-74.2478, 40.6001],
            [-74.2581, 40.6022],
            [-74.2769, 40.6275],
            [-74.2853, 40.6349],
            [-74.2902, 40.6385],
            [-74.2898, 40.64],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408610',
        NAME: 'Linden City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27658452,
        AWATER: 1894923,
        INTPTLAT: '+40.6252302',
        INTPTLON: '-074.2378233',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4034, 40.6665],
            [-74.3876, 40.6782],
            [-74.3794, 40.6676],
            [-74.3256, 40.6251],
            [-74.3591, 40.6035],
            [-74.3946, 40.6009],
            [-74.4083, 40.6442],
            [-74.4034, 40.6665],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414670',
        NAME: 'Scotch Plains-Fanwood Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26827231,
        AWATER: 81430,
        INTPTLAT: '+40.6340945',
        INTPTLON: '-074.3756956',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2987, 40.7148],
            [-74.2853, 40.7221],
            [-74.2563, 40.7133],
            [-74.2444, 40.7112],
            [-74.2223, 40.675],
            [-74.2542, 40.6702],
            [-74.2718, 40.6708],
            [-74.3078, 40.6803],
            [-74.2987, 40.7148],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416500',
        NAME: 'Union Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 23494867,
        AWATER: 54478,
        INTPTLAT: '+40.6952664',
        INTPTLON: '-074.2690777',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3224, 40.6774],
            [-74.3104, 40.6784],
            [-74.2856, 40.666],
            [-74.2835, 40.6582],
            [-74.2815, 40.6501],
            [-74.2898, 40.64],
            [-74.3132, 40.6382],
            [-74.3197, 40.642],
            [-74.3123, 40.654],
            [-74.3266, 40.6577],
            [-74.3224, 40.6774],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403570',
        NAME: 'Cranford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12508673,
        AWATER: 100262,
        INTPTLAT: '+40.6563910',
        INTPTLON: '-074.3048302',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.176, 40.8096],
            [-74.1399, 40.8055],
            [-74.1399, 40.8053],
            [-74.1475, 40.7865],
            [-74.1483, 40.7799],
            [-74.1863, 40.7747],
            [-74.176, 40.8096],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401350',
        NAME: 'Belleville Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8651348,
        AWATER: 153710,
        INTPTLAT: '+40.7955631',
        INTPTLON: '-074.1617893',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2119, 40.7856],
            [-74.1946, 40.8289],
            [-74.1827, 40.8488],
            [-74.1626, 40.8379],
            [-74.176, 40.8096],
            [-74.1863, 40.7747],
            [-74.19, 40.7686],
            [-74.2119, 40.7856],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401830',
        NAME: 'Bloomfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13742683,
        AWATER: 62809,
        INTPTLAT: '+40.8091411',
        INTPTLON: '-074.1871418',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2904, 40.7576],
            [-74.2553, 40.7581],
            [-74.2379, 40.7523],
            [-74.2348, 40.7507],
            [-74.2437, 40.7308],
            [-74.2563, 40.7133],
            [-74.2853, 40.7221],
            [-74.2904, 40.7576],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415330',
        NAME: 'South Orange-Maplewood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17438279,
        AWATER: 11681,
        INTPTLAT: '+40.7419348',
        INTPTLON: '-074.2649121',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.305, 40.7579],
            [-74.2806, 40.807],
            [-74.2763, 40.8139],
            [-74.2643, 40.8254],
            [-74.2326, 40.8149],
            [-74.2209, 40.7898],
            [-74.2553, 40.7581],
            [-74.2904, 40.7576],
            [-74.305, 40.7579],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417610',
        NAME: 'West Orange Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31199410,
        AWATER: 322010,
        INTPTLAT: '+40.7857553',
        INTPTLON: '-074.2650580',
        ELSDLEA: '',
        UNSDLEA: '17610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3285, 40.8447],
            [-74.2702, 40.8598],
            [-74.2637, 40.8352],
            [-74.2983, 40.8304],
            [-74.3292, 40.8383],
            [-74.3285, 40.8447],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402580',
        NAME: 'Caldwell-West Caldwell School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16116018,
        AWATER: 37699,
        INTPTLAT: '+40.8472337',
        INTPTLON: '-074.2944278',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2467, 40.8808],
            [-74.2048, 40.8609],
            [-74.221, 40.8348],
            [-74.2519, 40.8498],
            [-74.2467, 40.8808],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402850',
        NAME: 'Cedar Grove Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11010845,
        AWATER: 325963,
        INTPTLAT: '+40.8580173',
        INTPTLON: '-074.2297838',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2379, 40.7523],
            [-74.2184, 40.7881],
            [-74.2119, 40.7856],
            [-74.19, 40.7686],
            [-74.2348, 40.7507],
            [-74.2379, 40.7523],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404230',
        NAME: 'East Orange City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10095049,
        AWATER: 0,
        INTPTLAT: '+40.7650440',
        INTPTLON: '-074.2117651',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1428, 39.8507],
            [-75.1503, 39.8261],
            [-75.1606, 39.8241],
            [-75.1428, 39.8507],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418150',
        NAME: 'Woodbury City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5203466,
        AWATER: 129744,
        INTPTLAT: '+39.8379065',
        INTPTLON: '-075.1515300',
        ELSDLEA: '',
        UNSDLEA: '18150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1288, 39.7685],
            [-75.0753, 39.8049],
            [-75.0153, 39.7264],
            [-75.0917, 39.7076],
            [-75.1138, 39.7374],
            [-75.121, 39.7442],
            [-75.1288, 39.7685],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417070',
        NAME: 'Washington Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 55379315,
        AWATER: 564854,
        INTPTLAT: '+39.7484244',
        INTPTLON: '-075.0690921',
        ELSDLEA: '',
        UNSDLEA: '17070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2187, 40.7883],
            [-74.1946, 40.8289],
            [-74.2119, 40.7856],
            [-74.2184, 40.7881],
            [-74.2187, 40.7883],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405940',
        NAME: 'Glen Ridge Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3319592,
        AWATER: 11548,
        INTPTLAT: '+40.8047984',
        INTPTLON: '-074.2045690',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2563, 40.7133],
            [-74.2437, 40.7308],
            [-74.2286, 40.7083],
            [-74.2444, 40.7112],
            [-74.2563, 40.7133],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407680',
        NAME: 'Irvington Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7583441,
        AWATER: 5417,
        INTPTLAT: '+40.7238590',
        INTPTLON: '-074.2325223',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9916, 40.7881],
            [-73.9929, 40.7863],
            [-74.0157, 40.7966],
            [-73.9916, 40.7881],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406240',
        NAME: 'Guttenberg Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505560,
        AWATER: 124840,
        INTPTLAT: '+40.7927841',
        INTPTLON: '-074.0045723',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5501, 40.7474],
            [-74.5422, 40.7894],
            [-74.4419, 40.7749],
            [-74.4436, 40.7717],
            [-74.4497, 40.7188],
            [-74.5137, 40.6977],
            [-74.549, 40.741],
            [-74.5501, 40.7474],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406720',
        NAME: 'Harding Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51653142,
        AWATER: 1361915,
        INTPTLAT: '+40.7378245',
        INTPTLON: '-074.4953350',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1657, 39.811],
            [-75.1503, 39.8261],
            [-75.1428, 39.8507],
            [-75.1354, 39.8617],
            [-75.1154, 39.8612],
            [-75.1032, 39.8563],
            [-75.0839, 39.8405],
            [-75.0753, 39.8049],
            [-75.1288, 39.7685],
            [-75.1522, 39.783],
            [-75.1381, 39.7807],
            [-75.1446, 39.8026],
            [-75.1602, 39.791],
            [-75.1721, 39.7949],
            [-75.1657, 39.811],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403900',
        NAME: 'Deptford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44966014,
        AWATER: 644794,
        INTPTLAT: '+39.8137940',
        INTPTLON: '-075.1186925',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1956, 40.7526],
            [-75.1905, 40.7565],
            [-75.1815, 40.7602],
            [-75.175, 40.7738],
            [-75.1413, 40.7739],
            [-75.1107, 40.7902],
            [-75.0416, 40.7685],
            [-75.105, 40.7192],
            [-75.1861, 40.7257],
            [-75.1956, 40.7526],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406810',
        NAME: 'Harmony Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61387431,
        AWATER: 981305,
        INTPTLAT: '+40.7477011',
        INTPTLON: '-075.1227770',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1225, 41.0061],
            [-74.0939, 41.0053],
            [-74.0773, 41.0045],
            [-74.0797, 40.9899],
            [-74.1225, 41.0061],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407320',
        NAME: 'Ho-Ho-Kus Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4493470,
        AWATER: 36351,
        INTPTLAT: '+40.9995000',
        INTPTLON: '-074.0965826',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0081, 40.9197],
            [-74.9395, 40.9459],
            [-74.9279, 40.9068],
            [-75.0024, 40.8604],
            [-75.0222, 40.8758],
            [-75.0081, 40.9197],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407470',
        NAME: 'Hope Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48228547,
        AWATER: 558966,
        INTPTLAT: '+40.9103103',
        INTPTLON: '-074.9857696',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4541, 40.3594],
            [-74.4359, 40.3566],
            [-74.4333, 40.3492],
            [-74.45, 40.3422],
            [-74.4455, 40.3555],
            [-74.4541, 40.3594],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407770',
        NAME: 'Jamesburg Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2272823,
        AWATER: 15728,
        INTPTLAT: '+40.3490381',
        INTPTLON: '-074.4402016',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3318, 40.021],
            [-74.3204, 40.0198],
            [-74.3157, 40.0102],
            [-74.3301, 40.0086],
            [-74.3318, 40.021],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408160',
        NAME: 'Lakehurst Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2369669,
        AWATER: 240040,
        INTPTLAT: '+40.0131186',
        INTPTLON: '-074.3203559',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9982, 39.8255],
            [-75.0024, 39.8165],
            [-75.0151, 39.8188],
            [-74.9982, 39.8255],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408280',
        NAME: 'Laurel Springs Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1186843,
        AWATER: 32873,
        INTPTLAT: '+39.8205431',
        INTPTLON: '-075.0054446',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2326, 40.8149],
            [-74.221, 40.8348],
            [-74.2048, 40.8609],
            [-74.1965, 40.8598],
            [-74.1827, 40.8488],
            [-74.1946, 40.8289],
            [-74.2187, 40.7883],
            [-74.2209, 40.7898],
            [-74.2326, 40.8149],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410560',
        NAME: 'Montclair Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16341312,
        AWATER: 18079,
        INTPTLAT: '+40.8244154',
        INTPTLON: '-074.2123522',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1626, 40.8379],
            [-74.13, 40.82],
            [-74.1399, 40.8055],
            [-74.176, 40.8096],
            [-74.1626, 40.8379],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411880',
        NAME: 'Nutley Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8765183,
        AWATER: 113643,
        INTPTLAT: '+40.8206164',
        INTPTLON: '-074.1562502',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0637, 39.98],
            [-74.0672, 39.9592],
            [-74.0808, 39.961],
            [-74.0733, 39.9825],
            [-74.0637, 39.98],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408310',
        NAME: 'Lavallette Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2093919,
        AWATER: 378890,
        INTPTLAT: '+39.9694741',
        INTPTLON: '-074.0718801',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0371, 39.8789],
            [-75.0165, 39.8719],
            [-75.016, 39.8593],
            [-75.0222, 39.8577],
            [-75.0409, 39.8623],
            [-75.0371, 39.8789],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408340',
        NAME: 'Lawnside Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3658255,
        AWATER: 0,
        INTPTLAT: '+39.8672183',
        INTPTLON: '-075.0288369',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1262, 39.392],
            [-75.0807, 39.3488],
            [-75.2437, 39.2835],
            [-75.281, 39.2992],
            [-75.2718, 39.343],
            [-75.1262, 39.392],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408370',
        NAME: 'Lawrence Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 95631400,
        AWATER: 3647725,
        INTPTLAT: '+39.3348775',
        INTPTLON: '-075.1845789',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3411, 40.9463],
            [-74.3298, 40.9526],
            [-74.2821, 40.9362],
            [-74.2721, 40.8974],
            [-74.3228, 40.9088],
            [-74.3411, 40.9463],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408580',
        NAME: 'Lincoln Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16520212,
        AWATER: 1371003,
        INTPTLAT: '+40.9237508',
        INTPTLON: '-074.3042350',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.054, 40.8539],
            [-74.0517, 40.8576],
            [-74.0383, 40.859],
            [-74.0326, 40.8587],
            [-74.0299, 40.8467],
            [-74.0354, 40.8288],
            [-74.0393, 40.8309],
            [-74.054, 40.8539],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408760',
        NAME: 'Little Ferry Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3822000,
        AWATER: 586726,
        INTPTLAT: '+40.8443318',
        INTPTLON: '-074.0361641',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4151, 39.8019],
            [-75.3898, 39.8159],
            [-75.3458, 39.8444],
            [-75.2863, 39.7928],
            [-75.3782, 39.7458],
            [-75.4502, 39.7748],
            [-75.4151, 39.8019],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408880',
        NAME: 'Logan Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56823101,
        AWATER: 12923888,
        INTPTLAT: '+39.7920790',
        INTPTLON: '-075.3551790',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.198, 40.7065],
            [-75.1861, 40.7257],
            [-75.105, 40.7192],
            [-75.1523, 40.6868],
            [-75.1619, 40.6804],
            [-75.198, 40.7065],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409000',
        NAME: 'Lopatcong Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18384253,
        AWATER: 156846,
        INTPTLAT: '+40.7142265',
        INTPTLON: '-075.1558479',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5591, 39.4958],
            [-75.528, 39.5004],
            [-75.4603, 39.5557],
            [-75.3511, 39.4715],
            [-75.4112, 39.4204],
            [-75.4068, 39.3806],
            [-75.4448, 39.3551],
            [-75.5502, 39.4467],
            [-75.5591, 39.4958],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409030',
        NAME: 'Lower Alloways Creek Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117178650,
        AWATER: 70480332,
        INTPTLAT: '+39.4505060',
        INTPTLON: '-075.4577411',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4762, 39.6727],
            [-75.4095, 39.6608],
            [-75.3212, 39.611],
            [-75.4047, 39.5788],
            [-75.4524, 39.567],
            [-75.4754, 39.5785],
            [-75.4762, 39.6727],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409480',
        NAME: 'Mannington Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87293950,
        AWATER: 10413733,
        INTPTLAT: '+39.6232487',
        INTPTLON: '-075.4146621',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2209, 40.7898],
            [-74.2187, 40.7883],
            [-74.2184, 40.7881],
            [-74.2379, 40.7523],
            [-74.2553, 40.7581],
            [-74.2209, 40.7898],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412270',
        NAME: 'Orange City Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5750512,
        AWATER: 5154,
        INTPTLAT: '+40.7680399',
        INTPTLON: '-074.2356922',
        ELSDLEA: '',
        UNSDLEA: '12270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1683, 39.6857],
            [-75.139, 39.7148],
            [-75.1359, 39.7179],
            [-75.1138, 39.7374],
            [-75.0917, 39.7076],
            [-75.0548, 39.6786],
            [-75.1074, 39.6795],
            [-75.1683, 39.6857],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405880',
        NAME: 'Glassboro Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23787412,
        AWATER: 94737,
        INTPTLAT: '+39.7000956',
        INTPTLON: '-075.1114226',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2571, 39.8518],
            [-75.2286, 39.8586],
            [-75.2277, 39.8261],
            [-75.2369, 39.8199],
            [-75.2571, 39.8518],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412720',
        NAME: 'Paulsboro Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4908264,
        AWATER: 1835517,
        INTPTLAT: '+39.8399753',
        INTPTLON: '-075.2399603',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5064, 39.3444],
            [-74.4906, 39.3305],
            [-74.5158, 39.3171],
            [-74.5229, 39.3245],
            [-74.5064, 39.3444],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409690',
        NAME: 'Margate City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3664966,
        AWATER: 559648,
        INTPTLAT: '+39.3309129',
        INTPTLON: '-074.5068485',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0119, 39.3368],
            [-74.9588, 39.3777],
            [-74.8966, 39.453],
            [-74.8571, 39.4207],
            [-74.8521, 39.3808],
            [-74.8611, 39.3238],
            [-74.8821, 39.3083],
            [-74.9147, 39.1779],
            [-75.0441, 39.2129],
            [-74.9799, 39.2966],
            [-75.0119, 39.3368],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409780',
        NAME: 'Maurice River Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241148079,
        AWATER: 6870260,
        INTPTLAT: '+39.2956260',
        INTPTLON: '-074.9288820',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0369, 39.9497],
            [-75.0456, 39.9473],
            [-75.0515, 39.9521],
            [-75.0479, 39.9573],
            [-75.0369, 39.9497],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409960',
        NAME: 'Merchantville Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1553273,
        AWATER: 0,
        INTPTLAT: '+39.9501199',
        INTPTLON: '-075.0500897',
        ELSDLEA: '',
        UNSDLEA: '09960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5429, 40.2167],
            [-74.4843, 40.2533],
            [-74.4081, 40.2738],
            [-74.3535, 40.2089],
            [-74.4068, 40.1724],
            [-74.4584, 40.1362],
            [-74.477, 40.212],
            [-74.4655, 40.2324],
            [-74.485, 40.2345],
            [-74.4887, 40.2164],
            [-74.5429, 40.2167],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410230',
        NAME: 'Millstone Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94790466,
        AWATER: 1748318,
        INTPTLAT: '+40.2133390',
        INTPTLON: '-074.4316813',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7895, 39.0225],
            [-74.7543, 39.0659],
            [-74.747, 39.0624],
            [-74.7882, 39.0175],
            [-74.7895, 39.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415810',
        NAME: 'Stone Harbor Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3619761,
        AWATER: 1461440,
        INTPTLAT: '+39.0416697',
        INTPTLON: '-074.7687770',
        ELSDLEA: '',
        UNSDLEA: '15810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.187, 40.4471],
            [-74.1472, 40.4477],
            [-74.1882, 40.4393],
            [-74.187, 40.4471],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416350',
        NAME: 'Union Beach Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4672700,
        AWATER: 220979,
        INTPTLAT: '+40.4474900',
        INTPTLON: '-074.1700460',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4172, 39.8232],
            [-74.3433, 39.828],
            [-74.3149, 39.7755],
            [-74.1527, 39.754],
            [-74.1486, 39.6986],
            [-74.3897, 39.773],
            [-74.3899, 39.7734],
            [-74.4172, 39.8232],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416470',
        NAME: 'Barnegat Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89035131,
        AWATER: 16590928,
        INTPTLAT: '+39.7689501',
        INTPTLON: '-074.2764379',
        ELSDLEA: '',
        UNSDLEA: '16470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2481, 39.6649],
            [-75.2208, 39.6619],
            [-75.1341, 39.6203],
            [-75.0875, 39.5869],
            [-75.1668, 39.5968],
            [-75.1841, 39.5838],
            [-75.2205, 39.547],
            [-75.2395, 39.5592],
            [-75.2868, 39.5892],
            [-75.2481, 39.6649],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416590',
        NAME: 'Upper Pittsgrove Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104449464,
        AWATER: 407999,
        INTPTLAT: '+39.6123748',
        INTPTLON: '-075.2095489',
        ELSDLEA: '',
        UNSDLEA: '16590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8611, 39.3238],
            [-74.7847, 39.3072],
            [-74.6401, 39.296],
            [-74.626, 39.2928],
            [-74.5988, 39.3052],
            [-74.6475, 39.2109],
            [-74.6698, 39.1817],
            [-74.6907, 39.1734],
            [-74.7664, 39.2236],
            [-74.8165, 39.2568],
            [-74.8821, 39.3083],
            [-74.8611, 39.3238],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416650',
        NAME: 'Upper Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160965722,
        AWATER: 16933851,
        INTPTLAT: '+39.2581116',
        INTPTLON: '-074.7269122',
        ELSDLEA: '',
        UNSDLEA: '16650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5064, 39.3444],
            [-74.5024, 39.3563],
            [-74.5016, 39.3573],
            [-74.4586, 39.3355],
            [-74.4906, 39.3305],
            [-74.5064, 39.3444],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416680',
        NAME: 'Ventnor City City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5054274,
        AWATER: 4068456,
        INTPTLAT: '+39.3420758',
        INTPTLON: '-074.4826495',
        ELSDLEA: '',
        UNSDLEA: '16680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7242, 39.7223],
            [-74.6267, 39.7461],
            [-74.545, 39.7608],
            [-74.4292, 39.769],
            [-74.5279, 39.6582],
            [-74.4796, 39.556],
            [-74.4948, 39.5573],
            [-74.5338, 39.5737],
            [-74.5781, 39.6001],
            [-74.6758, 39.6913],
            [-74.7242, 39.7223],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417040',
        NAME: 'Washington Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 257760120,
        AWATER: 8245360,
        INTPTLAT: '+39.6841629',
        INTPTLON: '-074.5726394',
        ELSDLEA: '',
        UNSDLEA: '17040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5792, 40.2664],
            [-74.5429, 40.2167],
            [-74.6135, 40.1827],
            [-74.6496, 40.242],
            [-74.5792, 40.2664],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417100',
        NAME: 'Robbinsville Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52618095,
        AWATER: 454098,
        INTPTLAT: '+40.2247225',
        INTPTLON: '-074.5940252',
        ELSDLEA: '',
        UNSDLEA: '17100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.908, 39.7838],
            [-74.9025, 39.7908],
            [-74.8629, 39.7853],
            [-74.8125, 39.7853],
            [-74.7363, 39.7299],
            [-74.8011, 39.6741],
            [-74.8615, 39.7311],
            [-74.8742, 39.7414],
            [-74.9133, 39.7765],
            [-74.908, 39.7838],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417250',
        NAME: 'Waterford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93345389,
        AWATER: 586243,
        INTPTLAT: '+39.7438754',
        INTPTLON: '-074.8235937',
        ELSDLEA: '',
        UNSDLEA: '17250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8571, 39.4207],
            [-74.8377, 39.4244],
            [-74.7552, 39.4403],
            [-74.8521, 39.3808],
            [-74.8571, 39.4207],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417850',
        NAME: 'Weymouth Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31310914,
        AWATER: 934719,
        INTPTLAT: '+39.3963304',
        INTPTLON: '-074.8269553',
        ELSDLEA: '',
        UNSDLEA: '17850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.084, 40.8243],
            [-75.0663, 40.8182],
            [-75.0608, 40.8339],
            [-75.0889, 40.8325],
            [-75.054, 40.8592],
            [-75.0222, 40.8758],
            [-75.0024, 40.8604],
            [-74.9532, 40.8327],
            [-75.0199, 40.7843],
            [-75.0416, 40.7685],
            [-75.1107, 40.7902],
            [-75.084, 40.8243],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417910',
        NAME: 'White Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70322462,
        AWATER: 1242727,
        INTPTLAT: '+40.8211895',
        INTPTLON: '-075.0307664',
        ELSDLEA: '',
        UNSDLEA: '17910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8331, 38.9858],
            [-74.8223, 38.9769],
            [-74.8468, 38.9577],
            [-74.8331, 38.9858],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417970',
        NAME: 'Wildwood Crest Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2936635,
        AWATER: 453565,
        INTPTLAT: '+38.9725907',
        INTPTLON: '-074.8386064',
        ELSDLEA: '',
        UNSDLEA: '17970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2902, 40.6385],
            [-74.2853, 40.6349],
            [-74.2928, 40.6337],
            [-74.2902, 40.6385],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418030',
        NAME: 'Winfield Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466013,
        AWATER: 416,
        INTPTLAT: '+40.6332886',
        INTPTLON: '-074.2912796',
        ELSDLEA: '',
        UNSDLEA: '18030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8165, 39.2568],
            [-74.7664, 39.2236],
            [-74.7903, 39.2156],
            [-74.8411, 39.2365],
            [-74.8165, 39.2568],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418090',
        NAME: 'Woodbine Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20772852,
        AWATER: 0,
        INTPTLAT: '+39.2283990',
        INTPTLON: '-074.8097257',
        ELSDLEA: '',
        UNSDLEA: '18090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0914, 39.9211],
            [-75.0961, 39.9137],
            [-75.102, 39.9179],
            [-75.0914, 39.9211],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3418270',
        NAME: 'Woodlynne Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567099,
        AWATER: 18297,
        INTPTLAT: '+39.9164778',
        INTPTLON: '-075.0955486',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.121, 39.7442],
            [-75.1138, 39.7374],
            [-75.1359, 39.7179],
            [-75.121, 39.7442],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413080',
        NAME: 'Pitman Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5869637,
        AWATER: 108557,
        INTPTLAT: '+39.7329417',
        INTPTLON: '-075.1306946',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9116, 39.5786],
            [-74.8843, 39.6021],
            [-74.8259, 39.5617],
            [-74.8694, 39.5243],
            [-74.8377, 39.4244],
            [-74.8571, 39.4207],
            [-74.8966, 39.453],
            [-74.9852, 39.5148],
            [-74.9116, 39.5786],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402400',
        NAME: 'Buena Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125955086,
        AWATER: 1232823,
        INTPTLAT: '+39.5145409',
        INTPTLON: '-074.8986250',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6566, 39.3074],
            [-74.6401, 39.296],
            [-74.7847, 39.3072],
            [-74.6566, 39.3074],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403510',
        NAME: 'Corbin City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19853231,
        AWATER: 3302617,
        INTPTLAT: '+39.3016745',
        INTPTLON: '-074.7055782',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4326, 40.4598],
            [-74.4233, 40.4405],
            [-74.4337, 40.4366],
            [-74.4474, 40.449],
            [-74.4326, 40.4598],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410290',
        NAME: 'Milltown Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4011417,
        AWATER: 115581,
        INTPTLAT: '+40.4502231',
        INTPTLON: '-074.4347863',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5965, 40.901],
            [-74.5782, 40.886],
            [-74.5791, 40.8776],
            [-74.6237, 40.8668],
            [-74.5965, 40.901],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410350',
        NAME: 'Mine Hill Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7594385,
        AWATER: 194224,
        INTPTLAT: '+40.8780875',
        INTPTLON: '-074.6011764',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8167, 40.875],
            [-74.8068, 40.8821],
            [-74.8217, 40.8485],
            [-74.8317, 40.8281],
            [-74.8505, 40.8413],
            [-74.8167, 40.875],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406300',
        NAME: 'Hackettstown Town School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9347033,
        AWATER: 275651,
        INTPTLAT: '+40.8537041',
        INTPTLON: '-074.8248771',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1544, 40.0997],
            [-74.1297, 40.1174],
            [-74.0859, 40.098],
            [-74.0856, 40.0976],
            [-74.0516, 40.059],
            [-74.0521, 40.0266],
            [-74.0586, 40.0012],
            [-74.1588, 40.0238],
            [-74.1544, 40.0997],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402220',
        NAME: 'Brick Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66602555,
        AWATER: 17094507,
        INTPTLAT: '+40.0617726',
        INTPTLON: '-074.1095574',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4584, 40.1362],
            [-74.4068, 40.1724],
            [-74.2638, 40.1683],
            [-74.243, 40.1158],
            [-74.2531, 40.0587],
            [-74.2661, 40.0556],
            [-74.4234, 39.999],
            [-74.4695, 40.1286],
            [-74.4584, 40.1362],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407740',
        NAME: 'Jackson Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257041547,
        AWATER: 3574833,
        INTPTLAT: '+40.0907963',
        INTPTLON: '-074.3612644',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4347, 39.8548],
            [-74.3293, 39.94],
            [-74.1667, 39.8673],
            [-74.1031, 39.7965],
            [-74.3433, 39.828],
            [-74.4172, 39.8232],
            [-74.4347, 39.8548],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408100',
        NAME: 'Lacey Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215710205,
        AWATER: 39467586,
        INTPTLAT: '+39.8551526',
        INTPTLON: '-074.2671517',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.243, 40.1158],
            [-74.1544, 40.0997],
            [-74.1588, 40.0238],
            [-74.2531, 40.0587],
            [-74.243, 40.1158],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408220',
        NAME: 'Lakewood Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63667674,
        AWATER: 1036616,
        INTPTLAT: '+40.0770635',
        INTPTLON: '-074.2003728',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4953, 39.9696],
            [-74.4234, 39.999],
            [-74.2661, 40.0556],
            [-74.2707, 39.9874],
            [-74.3293, 39.94],
            [-74.4347, 39.8548],
            [-74.4708, 39.9226],
            [-74.4953, 39.9696],
          ],
          [
            [-74.3318, 40.021],
            [-74.3301, 40.0086],
            [-74.3157, 40.0102],
            [-74.3204, 40.0198],
            [-74.3318, 40.021],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409450',
        NAME: 'Manchester Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211410671,
        AWATER: 2782397,
        INTPTLAT: '+39.9555175',
        INTPTLON: '-074.3755625',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2707, 39.9874],
            [-74.2661, 40.0556],
            [-74.2531, 40.0587],
            [-74.1588, 40.0238],
            [-74.0586, 40.0012],
            [-74.0637, 39.98],
            [-74.0733, 39.9825],
            [-74.0808, 39.961],
            [-74.0672, 39.9592],
            [-74.0686, 39.9482],
            [-74.0867, 39.9437],
            [-74.139, 39.9337],
            [-74.1322, 39.9418],
            [-74.1476, 39.9474],
            [-74.1563, 39.9404],
            [-74.2707, 39.9874],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416230',
        NAME: 'Toms River Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116868256,
        AWATER: 32254073,
        INTPTLAT: '+39.9839478',
        INTPTLON: '-074.1691947',
        ELSDLEA: '',
        UNSDLEA: '16230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5988, 39.3052],
            [-74.5864, 39.3099],
            [-74.5569, 39.2988],
            [-74.6475, 39.2109],
            [-74.5988, 39.3052],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411970',
        NAME: 'Ocean City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16413015,
        AWATER: 11550539,
        INTPTLAT: '+39.2636680',
        INTPTLON: '-074.6078562',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8333, 38.9918],
            [-74.8216, 38.9965],
            [-74.8162, 38.9988],
            [-74.8154, 38.9982],
            [-74.8019, 38.9884],
            [-74.8223, 38.9769],
            [-74.8331, 38.9858],
            [-74.8333, 38.9918],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417940',
        NAME: 'Wildwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3381000,
        AWATER: 244016,
        INTPTLAT: '+38.9889136',
        INTPTLON: '-074.8198244',
        ELSDLEA: '',
        UNSDLEA: '17940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.972, 38.9404],
            [-74.9609, 38.9423],
            [-74.9598, 38.9311],
            [-74.972, 38.9404],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402760',
        NAME: 'Cape May Point Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765541,
        AWATER: 50205,
        INTPTLAT: '+38.9369094',
        INTPTLON: '-074.9653744',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.084, 40.8243],
            [-75.0889, 40.8325],
            [-75.0608, 40.8339],
            [-75.0663, 40.8182],
            [-75.084, 40.8243],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401440',
        NAME: 'Belvidere Town School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3759524,
        AWATER: 99557,
        INTPTLAT: '+40.8298024',
        INTPTLON: '-075.0733366',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.198, 40.7065],
            [-75.1619, 40.6804],
            [-75.1806, 40.6686],
            [-75.1902, 40.6794],
            [-75.198, 40.7065],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412960',
        NAME: 'Phillipsburg Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8269840,
        AWATER: 304978,
        INTPTLAT: '+40.6919740',
        INTPTLON: '-075.1790059',
        ELSDLEA: '',
        UNSDLEA: '12960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0787, 40.7506],
            [-74.0569, 40.7689],
            [-74.0458, 40.7574],
            [-74.0376, 40.7506],
            [-74.0204, 40.7303],
            [-74.0496, 40.6616],
            [-74.1146, 40.7011],
            [-74.1171, 40.7104],
            [-74.0787, 40.7506],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407830',
        NAME: 'Jersey City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38312316,
        AWATER: 16419138,
        INTPTLAT: '+40.7114174',
        INTPTLON: '-074.0647599',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1648, 40.7547],
            [-74.1483, 40.7799],
            [-74.1475, 40.7865],
            [-74.1109, 40.7768],
            [-74.0907, 40.7621],
            [-74.0787, 40.7506],
            [-74.1171, 40.7104],
            [-74.1375, 40.7414],
            [-74.1575, 40.7522],
            [-74.1648, 40.7547],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407890',
        NAME: 'Kearny Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22743455,
        AWATER: 3658193,
        INTPTLAT: '+40.7541654',
        INTPTLON: '-074.1195037',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0569, 40.7689],
            [-74.045, 40.8077],
            [-74.0321, 40.8129],
            [-74.0136, 40.8229],
            [-73.998, 40.8085],
            [-73.9944, 40.8065],
            [-73.9849, 40.7975],
            [-73.9916, 40.7881],
            [-74.0157, 40.7966],
            [-74.0231, 40.7829],
            [-74.0458, 40.7574],
            [-74.0569, 40.7689],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411460',
        NAME: 'North Bergen Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13456230,
        AWATER: 1150751,
        INTPTLAT: '+40.7941628',
        INTPTLON: '-074.0249470',
        ELSDLEA: '',
        UNSDLEA: '11460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0907, 40.7621],
            [-74.0834, 40.7863],
            [-74.0818, 40.788],
            [-74.0607, 40.8057],
            [-74.045, 40.8077],
            [-74.0569, 40.7689],
            [-74.0787, 40.7506],
            [-74.0907, 40.7621],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414850',
        NAME: 'Secaucus Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14924663,
        AWATER: 1998434,
        INTPTLAT: '+40.7819580',
        INTPTLON: '-074.0676485',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7874, 41.0054],
            [-74.7664, 41.0389],
            [-74.7361, 41.0604],
            [-74.7116, 41.0828],
            [-74.6855, 41.0519],
            [-74.6884, 41.0091],
            [-74.7406, 40.9687],
            [-74.7874, 41.0054],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400900',
        NAME: 'Andover Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54978607,
        AWATER: 1939144,
        INTPTLAT: '+41.0216411',
        INTPTLON: '-074.7279671',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7163, 39.1174],
            [-74.747, 39.0624],
            [-74.7543, 39.0659],
            [-74.7163, 39.1174],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401110',
        NAME: 'Avalon Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10753460,
        AWATER: 2006495,
        INTPTLAT: '+39.0864040',
        INTPTLON: '-074.7390030',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0237, 40.191],
            [-74.0213, 40.1974],
            [-74.0075, 40.1956],
            [-74.0065, 40.1873],
            [-74.0259, 40.1846],
            [-74.0237, 40.191],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401140',
        NAME: 'Avon-by-the-Sea Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1104168,
        AWATER: 298376,
        INTPTLAT: '+40.1914176',
        INTPTLON: '-074.0151054',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0677, 39.8721],
            [-75.046, 39.8831],
            [-75.0371, 39.8789],
            [-75.0409, 39.8623],
            [-75.0495, 39.8585],
            [-75.0642, 39.8599],
            [-75.0677, 39.8721],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401170',
        NAME: 'Barrington Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4150236,
        AWATER: 0,
        INTPTLAT: '+39.8689182',
        INTPTLON: '-075.0514297',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0478, 40.0779],
            [-74.0407, 40.0755],
            [-74.0444, 40.058],
            [-74.0516, 40.059],
            [-74.0478, 40.0779],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401230',
        NAME: 'Bay Head Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1507735,
        AWATER: 306241,
        INTPTLAT: '+40.0703149',
        INTPTLON: '-074.0481623',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6949, 41.3574],
            [-74.674, 41.3477],
            [-74.649, 41.3364],
            [-74.7168, 41.2216],
            [-74.836, 41.279],
            [-74.6949, 41.3574],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410530',
        NAME: 'Montague Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116834280,
        AWATER: 3617774,
        INTPTLAT: '+41.2809235',
        INTPTLON: '-074.7304832',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0751, 40.8486],
            [-74.054, 40.8539],
            [-74.0393, 40.8309],
            [-74.0531, 40.8337],
            [-74.0761, 40.8376],
            [-74.0751, 40.8486],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410680',
        NAME: 'Moonachie Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4315923,
        AWATER: 29937,
        INTPTLAT: '+40.8413344',
        INTPTLON: '-074.0576708',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7258, 40.7195],
            [-74.6825, 40.7294],
            [-74.6309, 40.6677],
            [-74.6242, 40.6493],
            [-74.6673, 40.616],
            [-74.7037, 40.6213],
            [-74.7237, 40.6469],
            [-74.7258, 40.7195],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401320',
        NAME: 'Bedminster Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67544491,
        AWATER: 573014,
        INTPTLAT: '+40.6730890',
        INTPTLON: '-074.6863250',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0482, 40.1843],
            [-74.0259, 40.1846],
            [-74.0065, 40.1873],
            [-74.0151, 40.1669],
            [-74.0206, 40.169],
            [-74.0295, 40.1746],
            [-74.0482, 40.1843],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401410',
        NAME: 'Belmar Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2706778,
        AWATER: 1558839,
        INTPTLAT: '+40.1796631',
        INTPTLON: '-074.0244398',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9569, 39.8167],
            [-74.9086, 39.8095],
            [-74.9025, 39.7908],
            [-74.908, 39.7838],
            [-74.957, 39.8092],
            [-74.9569, 39.8167],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401620',
        NAME: 'Berlin Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8370648,
        AWATER: 15360,
        INTPTLAT: '+39.8071159',
        INTPTLON: '-074.9241780',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9119, 40.0703],
            [-74.9282, 40.0573],
            [-74.9326, 40.0688],
            [-74.9119, 40.0703],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401740',
        NAME: 'Beverly City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1436786,
        AWATER: 594486,
        INTPTLAT: '+40.0648106',
        INTPTLON: '-074.9219042',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3462, 41.0608],
            [-74.3179, 41.0684],
            [-74.303, 41.0114],
            [-74.3003, 41.0027],
            [-74.3284, 41.0014],
            [-74.363, 41.0132],
            [-74.3462, 41.0608],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401860',
        NAME: 'Bloomingdale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22580921,
        AWATER: 1158475,
        INTPTLAT: '+41.0352572',
        INTPTLON: '-074.3337310',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0792, 40.6599],
            [-75.0719, 40.6663],
            [-75.0671, 40.6563],
            [-75.1084, 40.6403],
            [-75.1088, 40.641],
            [-75.0792, 40.6599],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401890',
        NAME: 'Bloomsbury Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2271755,
        AWATER: 85868,
        INTPTLAT: '+40.6566743',
        INTPTLON: '-075.0764973',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4515, 40.9343],
            [-74.4286, 40.9582],
            [-74.4005, 40.9596],
            [-74.3934, 40.9171],
            [-74.4255, 40.904],
            [-74.4406, 40.9072],
            [-74.4515, 40.9343],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401980',
        NAME: 'Boonton Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21101660,
        AWATER: 1006629,
        INTPTLAT: '+40.9312638',
        INTPTLON: '-074.4249278',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0197, 40.2019],
            [-74.0032, 40.2085],
            [-74.0075, 40.1956],
            [-74.0213, 40.1974],
            [-74.0197, 40.2019],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402130',
        NAME: 'Bradley Beach Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1584906,
        AWATER: 54490,
        INTPTLAT: '+40.2016041',
        INTPTLON: '-074.0120565',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7037, 40.6213],
            [-74.6673, 40.616],
            [-74.6874, 40.5554],
            [-74.7748, 40.5042],
            [-74.7037, 40.6213],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402160',
        NAME: 'Branchburg Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51912478,
        AWATER: 608719,
        INTPTLAT: '+40.5600242',
        INTPTLON: '-074.7161186',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0859, 40.098],
            [-74.0621, 40.1207],
            [-74.0386, 40.1045],
            [-74.0643, 40.0935],
            [-74.0856, 40.0976],
            [-74.0859, 40.098],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402310',
        NAME: 'Brielle Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4550096,
        AWATER: 1600504,
        INTPTLAT: '+40.1053748',
        INTPTLON: '-074.0634210',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4138, 39.3972],
            [-74.3323, 39.4431],
            [-74.4063, 39.3705],
            [-74.4138, 39.3972],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402340',
        NAME: 'Brigantine City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16543199,
        AWATER: 10301299,
        INTPTLAT: '+39.4272906',
        INTPTLON: '-074.3896170',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1274, 39.8808],
            [-75.1081, 39.878],
            [-75.1206, 39.8726],
            [-75.1274, 39.8808],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402370',
        NAME: 'Brooklawn Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1273944,
        AWATER: 84836,
        INTPTLAT: '+39.8793503',
        INTPTLON: '-075.1203958',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8742, 39.7414],
            [-74.8615, 39.7311],
            [-74.882, 39.7202],
            [-74.8895, 39.738],
            [-74.8742, 39.7414],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403030',
        NAME: 'Chesilhurst Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4449226,
        AWATER: 4000,
        INTPTLAT: '+39.7312887',
        INTPTLON: '-074.8799265',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9661, 39.7997],
            [-74.9729, 39.7929],
            [-74.9819, 39.7974],
            [-75.0003, 39.8054],
            [-74.9661, 39.7997],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403240',
        NAME: 'Clementon Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4958151,
        AWATER: 150323,
        INTPTLAT: '+39.8027459',
        INTPTLON: '-074.9838085',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0627, 39.3475],
            [-75.0119, 39.3368],
            [-74.9799, 39.2966],
            [-75.0441, 39.2129],
            [-75.108, 39.2105],
            [-75.0627, 39.3475],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403480',
        NAME: 'Commercial Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83214368,
        AWATER: 5980080,
        INTPTLAT: '+39.2737695',
        INTPTLON: '-075.0453429',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5485, 40.3364],
            [-74.4841, 40.3384],
            [-74.4888, 40.2766],
            [-74.5676, 40.2999],
            [-74.5485, 40.3364],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403540',
        NAME: 'Cranbury Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34294566,
        AWATER: 388450,
        INTPTLAT: '+40.3134477',
        INTPTLON: '-074.5202243',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0002, 40.2634],
            [-73.9867, 40.2602],
            [-73.9929, 40.2373],
            [-74.008, 40.2399],
            [-74.0002, 40.2634],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403630',
        NAME: 'Deal Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3059390,
        AWATER: 355656,
        INTPTLAT: '+40.2497012',
        INTPTLON: '-073.9974576',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.974, 40.0486],
            [-74.9326, 40.0688],
            [-74.9282, 40.0573],
            [-74.9256, 40.0329],
            [-74.9316, 40.0293],
            [-74.9368, 40.0335],
            [-74.9728, 40.0423],
            [-74.9828, 40.0418],
            [-74.974, 40.0486],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403690',
        NAME: 'Delanco Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6102136,
        AWATER: 2574488,
        INTPTLAT: '+40.0506660',
        INTPTLON: '-074.9377462',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8821, 39.3083],
            [-74.8165, 39.2568],
            [-74.8411, 39.2365],
            [-74.7903, 39.2156],
            [-74.7664, 39.2236],
            [-74.6907, 39.1734],
            [-74.7157, 39.1199],
            [-74.716, 39.1188],
            [-74.8921, 39.1629],
            [-74.9147, 39.1779],
            [-74.8821, 39.3083],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403840',
        NAME: 'Dennis Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157469809,
        AWATER: 9141393,
        INTPTLAT: '+39.2065131',
        INTPTLON: '-074.8184174',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2437, 39.2835],
            [-75.0807, 39.3488],
            [-75.0627, 39.3475],
            [-75.108, 39.2105],
            [-75.2437, 39.2835],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403960',
        NAME: 'Downe Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125940683,
        AWATER: 14610813,
        INTPTLAT: '+39.2694966',
        INTPTLON: '-075.1333181',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1648, 40.7547],
            [-74.1575, 40.7522],
            [-74.1661, 40.7474],
            [-74.1648, 40.7547],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404200',
        NAME: 'East Newark Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265055,
        AWATER: 55112,
        INTPTLAT: '+40.7519652',
        INTPTLON: '-074.1622016',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9944, 40.8065],
            [-73.9764, 40.8276],
            [-73.954, 40.848],
            [-73.9849, 40.7975],
            [-73.9944, 40.8065],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404440',
        NAME: 'Edgewater Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2411527,
        AWATER: 3859069,
        INTPTLAT: '+40.8120910',
        INTPTLON: '-073.9835605',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9282, 40.0573],
            [-74.9119, 40.0703],
            [-74.9091, 40.0705],
            [-74.8988, 40.0733],
            [-74.889, 40.0559],
            [-74.9256, 40.0329],
            [-74.9282, 40.0573],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404470',
        NAME: 'Edgewater Park Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7495328,
        AWATER: 377296,
        INTPTLAT: '+40.0551731',
        INTPTLON: '-074.9169249',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4642, 40.8358],
            [-74.4744, 40.8198],
            [-74.4955, 40.8252],
            [-74.4642, 40.8358],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410770',
        NAME: 'Morris Plains Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6623316,
        AWATER: 94621,
        INTPTLAT: '+40.8411080',
        INTPTLON: '-074.4728611',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6518, 40.9233],
            [-74.6362, 40.9426],
            [-74.6223, 40.9395],
            [-74.6518, 40.9233],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410860',
        NAME: 'Mount Arlington Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5625023,
        AWATER: 1747408,
        INTPTLAT: '+40.9208509',
        INTPTLON: '-074.6408278',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1081, 39.878],
            [-75.0965, 39.8899],
            [-75.0892, 39.8887],
            [-75.0801, 39.8817],
            [-75.0821, 39.8724],
            [-75.1081, 39.878],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410890',
        NAME: 'Mount Ephraim Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2260402,
        AWATER: 46619,
        INTPTLAT: '+39.8796656',
        INTPTLON: '-075.0909475',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3794, 40.6854],
            [-74.3604, 40.6998],
            [-74.3314, 40.6822],
            [-74.3794, 40.6676],
            [-74.3876, 40.6782],
            [-74.3794, 40.6854],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411040',
        NAME: 'Mountainside Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10380037,
        AWATER: 106248,
        INTPTLAT: '+40.6807216',
        INTPTLON: '-074.3602919',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0197, 40.2019],
            [-74.0213, 40.1974],
            [-74.0237, 40.191],
            [-74.0197, 40.2019],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411130',
        NAME: 'Neptune City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2470275,
        AWATER: 0,
        INTPTLAT: '+40.2002113',
        INTPTLON: '-074.0336201',
        ELSDLEA: '',
        UNSDLEA: '11130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.5999, 40.0002],
              [-74.5406, 40.057],
              [-74.5148, 40.0077],
              [-74.5999, 40.0002],
            ],
          ],
          [
            [
              [-74.6339, 40.0332],
              [-74.6282, 40.0395],
              [-74.6003, 40.0001],
              [-74.6193, 39.9939],
              [-74.6339, 40.0332],
            ],
          ],
          [
            [
              [-74.6572, 40.0108],
              [-74.6552, 40.0141],
              [-74.6561, 40.0102],
              [-74.6572, 40.0108],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411250',
        NAME: 'New Hanover Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32848387,
        AWATER: 385394,
        INTPTLAT: '+40.0078327',
        INTPTLON: '-074.5749151',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0188, 39.5384],
            [-75.0345, 39.5494],
            [-75.0197, 39.5581],
            [-75.0025, 39.5535],
            [-75.0188, 39.5384],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411370',
        NAME: 'Newfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4410291,
        AWATER: 7774,
        INTPTLAT: '+39.5510906',
        INTPTLON: '-075.0104047',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1668, 39.5968],
            [-75.1669, 39.5851],
            [-75.1841, 39.5838],
            [-75.1668, 39.5968],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404650',
        NAME: 'Elmer Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2240927,
        AWATER: 31769,
        INTPTLAT: '+39.5922584',
        INTPTLON: '-075.1745689',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.513, 39.5784],
            [-75.4945, 39.5761],
            [-75.4603, 39.5557],
            [-75.528, 39.5004],
            [-75.513, 39.5784],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404680',
        NAME: 'Elsinboro Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30864923,
        AWATER: 3646265,
        INTPTLAT: '+39.5333174',
        INTPTLON: '-075.4984782',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9492, 40.9008],
            [-73.9271, 40.8955],
            [-73.9484, 40.8585],
            [-73.9668, 40.8686],
            [-73.9492, 40.9008],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404770',
        NAME: 'Englewood Cliffs Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5410310,
        AWATER: 3212569,
        INTPTLAT: '+40.8896639',
        INTPTLON: '-073.9420086',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8521, 39.3808],
            [-74.7552, 39.4403],
            [-74.7178, 39.404],
            [-74.6566, 39.3074],
            [-74.7847, 39.3072],
            [-74.8611, 39.3238],
            [-74.8521, 39.3808],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404860',
        NAME: 'Estell Manor City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138099797,
        AWATER: 4604439,
        INTPTLAT: '+39.3539329',
        INTPTLON: '-074.7751350',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0136, 40.8229],
            [-73.9962, 40.8245],
            [-73.998, 40.8085],
            [-74.0136, 40.8229],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3405070',
        NAME: 'Fairview Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2181997,
        AWATER: 2824,
        INTPTLAT: '+40.8210539',
        INTPTLON: '-074.0030608',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6148, 40.9903],
            [-74.536, 41.0585],
            [-74.5029, 41.0859],
            [-74.441, 41.045],
            [-74.5944, 40.9023],
            [-74.5946, 40.9021],
            [-74.6223, 40.9395],
            [-74.6362, 40.9426],
            [-74.6148, 40.9903],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407800',
        NAME: 'Jefferson Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100732782,
        AWATER: 10309257,
        INTPTLAT: '+41.0027355',
        INTPTLON: '-074.5594494',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3314, 40.9883],
            [-74.2973, 40.9836],
            [-74.2813, 40.98],
            [-74.2821, 40.9362],
            [-74.3298, 40.9526],
            [-74.3314, 40.9883],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412900',
        NAME: 'Pequannock Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17479202,
        AWATER: 1096222,
        INTPTLAT: '+40.9626704',
        INTPTLON: '-074.3052049',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4005, 40.9596],
            [-74.3411, 40.9463],
            [-74.3228, 40.9088],
            [-74.3299, 40.8492],
            [-74.342, 40.8468],
            [-74.3833, 40.8995],
            [-74.3934, 40.9171],
            [-74.4005, 40.9596],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410650',
        NAME: 'Montville Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47933577,
        AWATER: 1492614,
        INTPTLAT: '+40.9132519',
        INTPTLON: '-074.3547984',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3934, 40.9171],
            [-74.3833, 40.8995],
            [-74.4171, 40.8963],
            [-74.4255, 40.904],
            [-74.3934, 40.9171],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401950',
        NAME: 'Boonton Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6047852,
        AWATER: 441789,
        INTPTLAT: '+40.9038179',
        INTPTLON: '-074.4063688',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3658, 41.0117],
            [-74.363, 41.0132],
            [-74.3284, 41.0014],
            [-74.331, 40.9917],
            [-74.3658, 41.0117],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402520',
        NAME: 'Butler Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5280563,
        AWATER: 129245,
        INTPTLAT: '+40.9980587',
        INTPTLON: '-074.3487582',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7895, 39.0225],
            [-74.7882, 39.0175],
            [-74.8019, 38.9884],
            [-74.8154, 38.9982],
            [-74.7895, 39.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411670',
        NAME: 'North Wildwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4539095,
        AWATER: 988476,
        INTPTLAT: '+39.0043284',
        INTPTLON: '-074.7994579',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3433, 39.828],
            [-74.1031, 39.7965],
            [-74.1053, 39.7649],
            [-74.1527, 39.754],
            [-74.3149, 39.7755],
            [-74.3433, 39.828],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412090',
        NAME: 'Ocean Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 53237888,
        AWATER: 29755685,
        INTPTLAT: '+39.7853021',
        INTPTLON: '-074.2176835',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4662, 39.7508],
            [-75.4502, 39.7748],
            [-75.3782, 39.7458],
            [-75.3604, 39.7132],
            [-75.3894, 39.6816],
            [-75.4662, 39.7508],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412210',
        NAME: 'Oldmans Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50610155,
        AWATER: 2175768,
        INTPTLAT: '+39.7391928',
        INTPTLON: '-075.4114057',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5782, 40.886],
            [-74.5681, 40.9003],
            [-74.5401, 40.8839],
            [-74.5791, 40.8776],
            [-74.5782, 40.886],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3403930',
        NAME: 'Dover Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7315621,
        AWATER: 118946,
        INTPTLAT: '+40.8855999',
        INTPTLON: '-074.5591632',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4436, 40.7717],
            [-74.4419, 40.7749],
            [-74.4408, 40.7774],
            [-74.3921, 40.7514],
            [-74.4436, 40.7717],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409240',
        NAME: 'Madison Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11145385,
        AWATER: 32500,
        INTPTLAT: '+40.7580439',
        INTPTLON: '-074.4178066',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9532, 40.8327],
            [-74.9828, 40.7998],
            [-75.0199, 40.7843],
            [-74.9532, 40.8327],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3412300',
        NAME: 'Oxford Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14987155,
        AWATER: 260967,
        INTPTLAT: '+40.8086499',
        INTPTLON: '-074.9934211',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1806, 40.6686],
            [-75.1619, 40.6804],
            [-75.1523, 40.6868],
            [-75.1088, 40.641],
            [-75.1084, 40.6403],
            [-75.1099, 40.6389],
            [-75.1896, 40.5918],
            [-75.1968, 40.6086],
            [-75.1806, 40.6686],
          ],
          [
            [-75.1742, 40.6469],
            [-75.1385, 40.6621],
            [-75.1515, 40.6724],
            [-75.169, 40.6653],
            [-75.1742, 40.6469],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413260',
        NAME: 'Pohatcong Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34589818,
        AWATER: 924350,
        INTPTLAT: '+40.6364833',
        INTPTLON: '-075.1749057',
        ELSDLEA: '',
        UNSDLEA: '13260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4948, 39.5573],
            [-74.4796, 39.556],
            [-74.4423, 39.5434],
            [-74.4948, 39.5573],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413380',
        NAME: 'Port Republic City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19377844,
        AWATER: 2852354,
        INTPTLAT: '+39.5347979',
        INTPTLON: '-074.4760995',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4603, 39.5557],
            [-75.4524, 39.567],
            [-75.4047, 39.5788],
            [-75.307, 39.5013],
            [-75.3511, 39.4715],
            [-75.4603, 39.5557],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413500',
        NAME: 'Quinton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62396715,
        AWATER: 1259710,
        INTPTLAT: '+39.5293548',
        INTPTLON: '-075.3922428',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.331, 40.9917],
            [-74.3284, 41.0014],
            [-74.3003, 41.0027],
            [-74.2973, 40.9836],
            [-74.3314, 40.9883],
            [-74.331, 40.9917],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413980',
        NAME: 'Riverdale Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5226439,
        AWATER: 176731,
        INTPTLAT: '+40.9959716',
        INTPTLON: '-074.3151238',
        ELSDLEA: '',
        UNSDLEA: '13980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0138, 40.0202],
            [-75.0102, 40.0033],
            [-75.0306, 40.0162],
            [-75.0138, 40.0202],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414040',
        NAME: 'Riverton Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1722175,
        AWATER: 779899,
        INTPTLAT: '+40.0119324',
        INTPTLON: '-075.0147346',
        ELSDLEA: '',
        UNSDLEA: '14040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0893, 40.9196],
            [-74.0689, 40.9085],
            [-74.0733, 40.8922],
            [-74.0789, 40.8951],
            [-74.0893, 40.9196],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414070',
        NAME: 'Rochelle Park Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2701136,
        AWATER: 55770,
        INTPTLAT: '+40.9106940',
        INTPTLON: '-074.0828953',
        ELSDLEA: '',
        UNSDLEA: '14070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.477, 40.212],
            [-74.4887, 40.2164],
            [-74.485, 40.2345],
            [-74.4655, 40.2324],
            [-74.477, 40.212],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414220',
        NAME: 'Roosevelt Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4946650,
        AWATER: 25661,
        INTPTLAT: '+40.2207424',
        INTPTLON: '-074.4701547',
        ELSDLEA: '',
        UNSDLEA: '14220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1146, 41.0436],
            [-74.1144, 41.0457],
            [-74.084, 41.0438],
            [-74.0761, 41.0147],
            [-74.0773, 41.0045],
            [-74.0939, 41.0053],
            [-74.1213, 41.0199],
            [-74.1146, 41.0436],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414520',
        NAME: 'Saddle River Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12752732,
        AWATER: 144267,
        INTPTLAT: '+41.0236960',
        INTPTLON: '-074.0925529',
        ELSDLEA: '',
        UNSDLEA: '14520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0422, 40.1408],
            [-74.0377, 40.1406],
            [-74.0215, 40.1375],
            [-74.0283, 40.1174],
            [-74.0463, 40.1312],
            [-74.0422, 40.1408],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414730',
        NAME: 'Sea Girt Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2737338,
        AWATER: 1018263,
        INTPTLAT: '+40.1304989',
        INTPTLON: '-074.0338426',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6907, 39.1734],
            [-74.6698, 39.1817],
            [-74.7157, 39.1199],
            [-74.6907, 39.1734],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414760',
        NAME: 'Sea Isle City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5616610,
        AWATER: 940179,
        INTPTLAT: '+39.1537975',
        INTPTLON: '-074.6964088',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4406, 40.9072],
            [-74.4255, 40.904],
            [-74.4171, 40.8963],
            [-74.4658, 40.885],
            [-74.4406, 40.9072],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411010',
        NAME: 'Mountain Lakes Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6774832,
        AWATER: 697633,
        INTPTLAT: '+40.8913740',
        INTPTLON: '-074.4411141',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4302, 41.0202],
            [-74.3658, 41.0117],
            [-74.331, 40.9917],
            [-74.3314, 40.9883],
            [-74.3298, 40.9526],
            [-74.3411, 40.9463],
            [-74.4005, 40.9596],
            [-74.4286, 40.9582],
            [-74.4302, 41.0202],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408040',
        NAME: 'Kinnelon Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46591120,
        AWATER: 3259613,
        INTPTLAT: '+40.9820358',
        INTPTLON: '-074.3858956',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8068, 40.8821],
            [-74.7679, 40.9118],
            [-74.7201, 40.9132],
            [-74.7134, 40.9024],
            [-74.7018, 40.8911],
            [-74.6817, 40.8279],
            [-74.7255, 40.8055],
            [-74.8217, 40.8485],
            [-74.8068, 40.8821],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410980',
        NAME: 'Mount Olive Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75895021,
        AWATER: 4379020,
        INTPTLAT: '+40.8657665',
        INTPTLON: '-074.7430287',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1464, 40.9484],
            [-74.1, 40.9553],
            [-74.0893, 40.9196],
            [-74.1134, 40.9176],
            [-74.1319, 40.9222],
            [-74.1481, 40.9416],
            [-74.1464, 40.9484],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3404980',
        NAME: 'Fair Lawn Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13306945,
        AWATER: 161218,
        INTPTLAT: '+40.9358333',
        INTPTLON: '-074.1175036',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1399, 40.8055],
            [-74.13, 40.82],
            [-74.1242, 40.8226],
            [-74.0834, 40.7863],
            [-74.0907, 40.7621],
            [-74.1109, 40.7768],
            [-74.1399, 40.8053],
            [-74.1399, 40.8055],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409210',
        NAME: 'Lyndhurst Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11797220,
        AWATER: 859470,
        INTPTLAT: '+40.7980044',
        INTPTLON: '-074.1132495',
        ELSDLEA: '',
        UNSDLEA: '09210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1375, 41.0075],
            [-74.1309, 41.0062],
            [-74.1412, 40.9828],
            [-74.1375, 41.0075],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410140',
        NAME: 'Midland Park Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4028121,
        AWATER: 21022,
        INTPTLAT: '+40.9958086',
        INTPTLON: '-074.1412624',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5428, 40.5552],
            [-74.527, 40.5596],
            [-74.5195, 40.5553],
            [-74.5158, 40.545],
            [-74.5438, 40.5546],
            [-74.5428, 40.5552],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415180',
        NAME: 'South Bound Brook Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1704421,
        AWATER: 250310,
        INTPTLAT: '+40.5539473',
        INTPTLON: '-074.5274549',
        ELSDLEA: '',
        UNSDLEA: '15180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.0393, 40.8309],
              [-74.0354, 40.8288],
              [-74.035, 40.8253],
              [-74.0531, 40.8337],
              [-74.0393, 40.8309],
            ],
          ],
          [
            [
              [-74.0583, 40.8707],
              [-74.0383, 40.859],
              [-74.0517, 40.8576],
              [-74.0583, 40.8707],
            ],
          ],
          [
            [
              [-74.1016, 40.8638],
              [-74.0972, 40.8672],
              [-74.0939, 40.8651],
              [-74.0978, 40.8615],
              [-74.1016, 40.8638],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415240',
        NAME: 'South Hackensack Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1872699,
        AWATER: 59737,
        INTPTLAT: '+40.8648008',
        INTPTLON: '-074.0481151',
        ELSDLEA: '',
        UNSDLEA: '15240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0321, 40.1654],
            [-74.0206, 40.169],
            [-74.0151, 40.1669],
            [-74.0215, 40.1375],
            [-74.0377, 40.1406],
            [-74.0321, 40.1654],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415570',
        NAME: 'Spring Lake Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3443976,
        AWATER: 1031295,
        INTPTLAT: '+40.1527499',
        INTPTLON: '-074.0271714',
        ELSDLEA: '',
        UNSDLEA: '15570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0321, 40.1654],
            [-74.0377, 40.1406],
            [-74.0422, 40.1408],
            [-74.0321, 40.1654],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415600',
        NAME: 'Spring Lake Heights Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3324834,
        AWATER: 66024,
        INTPTLAT: '+40.1494828',
        INTPTLON: '-074.0461693',
        ELSDLEA: '',
        UNSDLEA: '15600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1491, 40.9711],
            [-74.1412, 40.9828],
            [-74.1309, 41.0062],
            [-74.1225, 41.0061],
            [-74.0797, 40.9899],
            [-74.0778, 40.9784],
            [-74.1, 40.9553],
            [-74.141, 40.9663],
            [-74.1491, 40.9711],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413830',
        NAME: 'Ridgewood Village School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14898659,
        AWATER: 171554,
        INTPTLAT: '+40.9815914',
        INTPTLON: '-074.1135057',
        ELSDLEA: '',
        UNSDLEA: '13830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2133, 41.134],
            [-74.1038, 41.0869],
            [-74.1164, 41.0728],
            [-74.1503, 41.0405],
            [-74.1556, 41.0258],
            [-74.1676, 41.0238],
            [-74.2018, 41.0438],
            [-74.2489, 41.0638],
            [-74.2133, 41.134],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409330',
        NAME: 'Mahwah Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66557962,
        AWATER: 1279284,
        INTPTLAT: '+41.0827464',
        INTPTLON: '-074.1874511',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0343, 40.8846],
            [-74.0225, 40.8659],
            [-74.0357, 40.869],
            [-74.0343, 40.8846],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3401920',
        NAME: 'Bogota Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1981148,
        AWATER: 124892,
        INTPTLAT: '+40.8742872',
        INTPTLON: '-074.0297346',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0261, 40.9401],
            [-74.0026, 40.9521],
            [-74.0105, 40.9349],
            [-74.0165, 40.9146],
            [-74.0302, 40.9133],
            [-74.0261, 40.9401],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411280',
        NAME: 'New Milford Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5889722,
        AWATER: 87736,
        INTPTLAT: '+40.9341614',
        INTPTLON: '-074.0194531',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1164, 41.0728],
            [-74.1144, 41.0457],
            [-74.1146, 41.0436],
            [-74.1503, 41.0405],
            [-74.1164, 41.0728],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413590',
        NAME: 'Ramsey Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14299503,
        AWATER: 183105,
        INTPTLAT: '+41.0591364',
        INTPTLON: '-074.1459311',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1134, 40.9176],
            [-74.0893, 40.9196],
            [-74.0789, 40.8951],
            [-74.0919, 40.8866],
            [-74.1042, 40.8937],
            [-74.1134, 40.9176],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3414490',
        NAME: 'Saddle Brook Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6963835,
        AWATER: 70601,
        INTPTLAT: '+40.9029236',
        INTPTLON: '-074.0940050',
        ELSDLEA: '',
        UNSDLEA: '14490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0641, 40.8868],
            [-74.0506, 40.9106],
            [-74.0418, 40.9114],
            [-74.0302, 40.9132],
            [-74.0343, 40.8846],
            [-74.0357, 40.869],
            [-74.0326, 40.8587],
            [-74.0383, 40.859],
            [-74.0583, 40.8707],
            [-74.0684, 40.876],
            [-74.0641, 40.8868],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3406270',
        NAME: 'Hackensack City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10823912,
        AWATER: 432538,
        INTPTLAT: '+40.8893976',
        INTPTLON: '-074.0456981',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0773, 41.0045],
            [-74.0257, 40.9966],
            [-74.0043, 40.9872],
            [-74.0552, 40.9698],
            [-74.0778, 40.9784],
            [-74.0797, 40.9899],
            [-74.0773, 41.0045],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417820',
        NAME: 'Westwood Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13397994,
        AWATER: 254568,
        INTPTLAT: '+40.9875543',
        INTPTLON: '-074.0493865',
        ELSDLEA: '',
        UNSDLEA: '17820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4448, 39.3551],
            [-75.4068, 39.3806],
            [-75.357, 39.3441],
            [-75.281, 39.2992],
            [-75.2437, 39.2835],
            [-75.108, 39.2105],
            [-75.0441, 39.2129],
            [-74.9147, 39.1779],
            [-74.8921, 39.1629],
            [-74.9353, 39.039],
            [-74.972, 38.9404],
            [-74.9598, 38.9311],
            [-74.9388, 38.9316],
            [-74.8687, 38.9428],
            [-74.8468, 38.9577],
            [-74.8223, 38.9769],
            [-74.8019, 38.9884],
            [-74.7882, 39.0175],
            [-74.747, 39.0624],
            [-74.7163, 39.1174],
            [-74.716, 39.1188],
            [-74.7157, 39.1199],
            [-74.6698, 39.1817],
            [-74.6475, 39.2109],
            [-74.5569, 39.2988],
            [-74.543, 39.3016],
            [-74.5158, 39.3171],
            [-74.4906, 39.3305],
            [-74.4586, 39.3355],
            [-74.4063, 39.3705],
            [-74.3323, 39.4431],
            [-74.3264, 39.5063],
            [-74.2961, 39.5084],
            [-74.2498, 39.547],
            [-74.2296, 39.5695],
            [-74.1015, 39.7568],
            [-74.0767, 39.9129],
            [-74.0709, 39.9368],
            [-74.0686, 39.9482],
            [-74.0672, 39.9592],
            [-74.0637, 39.98],
            [-74.0586, 40.0012],
            [-74.0521, 40.0266],
            [-74.0444, 40.058],
            [-74.0407, 40.0755],
            [-74.0317, 40.101],
            [-74.0283, 40.1174],
            [-74.0215, 40.1375],
            [-74.0151, 40.1669],
            [-74.0065, 40.1873],
            [-74.0075, 40.1956],
            [-74.0032, 40.2085],
            [-73.9985, 40.2168],
            [-73.9947, 40.2301],
            [-73.994, 40.2328],
            [-73.9929, 40.2373],
            [-73.9867, 40.2602],
            [-73.9743, 40.3224],
            [-73.9719, 40.3458],
            [-73.9749, 40.3995],
            [-74.0276, 40.4862],
            [-74.104, 40.4653],
            [-74.0915, 40.5001],
            [-73.9499, 40.5255],
            [-73.8867, 40.4898],
            [-73.8982, 40.2744],
            [-73.9663, 40.1086],
            [-74.0382, 39.7501],
            [-74.2659, 39.4224],
            [-74.3625, 39.3258],
            [-74.4616, 39.301],
            [-74.6443, 39.1148],
            [-74.7284, 38.9742],
            [-74.8151, 38.9018],
            [-74.911, 38.8751],
            [-75.0151, 38.7887],
            [-75.129, 38.9874],
            [-75.1917, 39.079],
            [-75.2482, 39.1578],
            [-75.3162, 39.2453],
            [-75.4448, 39.3551],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3499997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 0,
        AWATER: 2233292369,
        INTPTLAT: '+39.1143223',
        INTPTLON: '-074.9573478',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2065, 40.481],
            [-74.0915, 40.5001],
            [-74.104, 40.4653],
            [-74.1313, 40.4354],
            [-74.1472, 40.4477],
            [-74.187, 40.4471],
            [-74.2052, 40.4391],
            [-74.2065, 40.481],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407860',
        NAME: 'Keansburg Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2768816,
        AWATER: 40723337,
        INTPTLAT: '+40.4482789',
        INTPTLON: '-074.1458229',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0774, 40.2344],
            [-74.0272, 40.225],
            [-73.9985, 40.2168],
            [-74.0032, 40.2085],
            [-74.0197, 40.2019],
            [-74.0237, 40.191],
            [-74.0259, 40.1846],
            [-74.0482, 40.1843],
            [-74.0944, 40.2063],
            [-74.0774, 40.2344],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411160',
        NAME: 'Neptune Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21190550,
        AWATER: 1271008,
        INTPTLAT: '+40.2138156',
        INTPTLON: '-074.0537123',
        ELSDLEA: '',
        UNSDLEA: '11160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1391, 40.2509],
            [-74.1256, 40.2493],
            [-74.0944, 40.2063],
            [-74.0482, 40.1843],
            [-74.0295, 40.1746],
            [-74.0321, 40.1654],
            [-74.0422, 40.1408],
            [-74.0463, 40.1312],
            [-74.0621, 40.1207],
            [-74.0859, 40.098],
            [-74.1297, 40.1174],
            [-74.1391, 40.2509],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416890',
        NAME: 'Wall Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79443311,
        AWATER: 2754646,
        INTPTLAT: '+40.1672995',
        INTPTLON: '-074.0960892',
        ELSDLEA: '',
        UNSDLEA: '16890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.084, 39.4574],
            [-75.062, 39.5686],
            [-75.0345, 39.5494],
            [-75.0188, 39.5384],
            [-74.9852, 39.5148],
            [-74.8966, 39.453],
            [-74.9588, 39.3777],
            [-75.0849, 39.444],
            [-75.084, 39.4574],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3416800',
        NAME: 'Vineland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177217336,
        AWATER: 1566834,
        INTPTLAT: '+39.4650072',
        INTPTLON: '-074.9971151',
        ELSDLEA: '',
        UNSDLEA: '16800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2052, 40.4391],
            [-74.187, 40.4471],
            [-74.1882, 40.4393],
            [-74.2119, 40.4226],
            [-74.2052, 40.4391],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407950',
        NAME: 'Keyport Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3614119,
        AWATER: 192740,
        INTPTLAT: '+40.4321142',
        INTPTLON: '-074.2015294',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0125, 40.3049],
            [-73.9972, 40.3298],
            [-73.9743, 40.3224],
            [-73.9867, 40.2602],
            [-74.0002, 40.2634],
            [-74.0029, 40.2764],
            [-74.0125, 40.3049],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3408940',
        NAME: 'Long Branch City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13258760,
        AWATER: 3014915,
        INTPTLAT: '+40.2953722',
        INTPTLON: '-073.9898990',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6825, 40.7294],
            [-74.6573, 40.7352],
            [-74.6185, 40.7441],
            [-74.5708, 40.7549],
            [-74.5501, 40.7474],
            [-74.549, 40.741],
            [-74.6309, 40.6677],
            [-74.6825, 40.7294],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400009',
        NAME: 'Somerset Hills Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60837151,
        AWATER: 526936,
        INTPTLAT: '+40.7189155',
        INTPTLON: '-074.6159372',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6673, 40.616],
            [-74.6242, 40.6493],
            [-74.5631, 40.6107],
            [-74.5177, 40.5874],
            [-74.5122, 40.582],
            [-74.5238, 40.5695],
            [-74.5428, 40.5552],
            [-74.5438, 40.5546],
            [-74.5665, 40.5428],
            [-74.5989, 40.5576],
            [-74.6081, 40.5584],
            [-74.5987, 40.5797],
            [-74.6245, 40.5804],
            [-74.6264, 40.5632],
            [-74.6874, 40.5554],
            [-74.6673, 40.616],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402280',
        NAME: 'Bridgewater-Raritan Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88136755,
        AWATER: 1340726,
        INTPTLAT: '+40.5915880',
        INTPTLON: '-074.6117640',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.752, 40.4314],
            [-74.5949, 40.455],
            [-74.6227, 40.3844],
            [-74.7222, 40.3752],
            [-74.7481, 40.4242],
            [-74.752, 40.4314],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410590',
        NAME: 'Montgomery Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85275835,
        AWATER: 458983,
        INTPTLAT: '+40.4282699',
        INTPTLON: '-074.6803129',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2282, 40.4774],
            [-74.2065, 40.481],
            [-74.2052, 40.4391],
            [-74.2119, 40.4226],
            [-74.2091, 40.405],
            [-74.2083, 40.3818],
            [-74.2627, 40.3954],
            [-74.2282, 40.4774],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409750',
        NAME: 'Matawan-Aberdeen Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19963787,
        AWATER: 6396596,
        INTPTLAT: '+40.4253472',
        INTPTLON: '-074.2265738',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1332, 40.4266],
            [-74.1313, 40.4354],
            [-74.104, 40.4653],
            [-74.0276, 40.4862],
            [-73.9749, 40.3995],
            [-73.9784, 40.3992],
            [-73.9982, 40.4226],
            [-74.0286, 40.4443],
            [-74.051, 40.4132],
            [-74.0045, 40.4062],
            [-73.9781, 40.3889],
            [-73.9775, 40.3835],
            [-74.0337, 40.3757],
            [-74.0592, 40.3633],
            [-74.0876, 40.3362],
            [-74.113, 40.3203],
            [-74.1489, 40.3247],
            [-74.1332, 40.4266],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3410110',
        NAME: 'Middletown Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106137693,
        AWATER: 45985369,
        INTPTLAT: '+40.4070948',
        INTPTLON: '-074.0698411',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2119, 40.4226],
            [-74.1882, 40.4393],
            [-74.1472, 40.4477],
            [-74.1313, 40.4354],
            [-74.1332, 40.4266],
            [-74.2091, 40.405],
            [-74.2119, 40.4226],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413680',
        NAME: 'Hazlet Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14419497,
        AWATER: 278355,
        INTPTLAT: '+40.4256273',
        INTPTLON: '-074.1686904',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0087, 40.2354],
            [-74.008, 40.2399],
            [-73.9929, 40.2373],
            [-73.994, 40.2328],
            [-74.0095, 40.2332],
            [-74.0087, 40.2354],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400780',
        NAME: 'Allenhurst Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 646320,
        AWATER: 87005,
        INTPTLAT: '+40.2359448',
        INTPTLON: '-074.0024167',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0272, 40.225],
            [-74.0138, 40.2302],
            [-74.0062, 40.2312],
            [-73.9947, 40.2301],
            [-73.9985, 40.2168],
            [-74.0272, 40.225],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3400930',
        NAME: 'Asbury Park City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3688154,
        AWATER: 462355,
        INTPTLAT: '+40.2228837',
        INTPTLON: '-074.0102318',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0087, 40.2354],
            [-74.0095, 40.2332],
            [-74.0062, 40.2312],
            [-74.0138, 40.2302],
            [-74.0087, 40.2354],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3407650',
        NAME: 'Interlaken Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 855587,
        AWATER: 137550,
        INTPTLAT: '+40.2343130',
        INTPTLON: '-074.0159388',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2821, 40.9362],
            [-74.2813, 40.98],
            [-74.2667, 41.0141],
            [-74.2485, 40.9923],
            [-74.2117, 40.9802],
            [-74.1963, 40.9462],
            [-74.2033, 40.9245],
            [-74.2306, 40.8831],
            [-74.2545, 40.8831],
            [-74.2721, 40.8974],
            [-74.2821, 40.9362],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417280',
        NAME: 'Wayne Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61516695,
        AWATER: 3676290,
        INTPTLAT: '+40.9458547',
        INTPTLON: '-074.2450769',
        ELSDLEA: '',
        UNSDLEA: '17280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0856, 40.0976],
            [-74.0643, 40.0935],
            [-74.0478, 40.0779],
            [-74.0516, 40.059],
            [-74.0856, 40.0976],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413290',
        NAME: 'Point Pleasant Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9044992,
        AWATER: 1744901,
        INTPTLAT: '+40.0777477',
        INTPTLON: '-074.0699206',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.0516, 40.059],
              [-74.0444, 40.058],
              [-74.0521, 40.0266],
              [-74.0516, 40.059],
            ],
          ],
          [
            [
              [-74.0386, 40.1045],
              [-74.0317, 40.101],
              [-74.0407, 40.0755],
              [-74.0478, 40.0779],
              [-74.0643, 40.0935],
              [-74.0386, 40.1045],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413320',
        NAME: 'Point Pleasant Beach Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4693140,
        AWATER: 1488926,
        INTPTLAT: '+40.0788261',
        INTPTLON: '-074.0470032',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5531, 40.0791],
            [-74.4695, 40.1286],
            [-74.4234, 39.999],
            [-74.4953, 39.9696],
            [-74.5148, 40.0077],
            [-74.5406, 40.057],
            [-74.5531, 40.0791],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413230',
        NAME: 'Plumsted Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102842152,
        AWATER: 1131302,
        INTPTLAT: '+40.0457155',
        INTPTLON: '-074.4852452',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7629, 41.0688],
            [-74.7361, 41.0604],
            [-74.7664, 41.0389],
            [-74.7629, 41.0688],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411400',
        NAME: 'Newton Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8697271,
        AWATER: 56591,
        INTPTLAT: '+41.0514865',
        INTPTLON: '-074.7536009',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6855, 41.0519],
            [-74.6386, 41.1186],
            [-74.6073, 41.0979],
            [-74.6022, 41.0931],
            [-74.6038, 41.0647],
            [-74.577, 41.079],
            [-74.536, 41.0585],
            [-74.6148, 40.9903],
            [-74.6474, 40.9995],
            [-74.6884, 41.0091],
            [-74.6855, 41.0519],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415510',
        NAME: 'Sparta Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95691171,
        AWATER: 5226319,
        INTPTLAT: '+41.0522832',
        INTPTLON: '-074.6279063',
        ELSDLEA: '',
        UNSDLEA: '15510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4895, 40.4744],
            [-74.4672, 40.5084],
            [-74.447, 40.506],
            [-74.416, 40.4927],
            [-74.3925, 40.4852],
            [-74.4149, 40.4778],
            [-74.4895, 40.4744],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3411220',
        NAME: 'New Brunswick City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13656866,
        AWATER: 1338116,
        INTPTLAT: '+40.4866775',
        INTPTLON: '-074.4444144',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4697, 40.5856],
            [-74.4559, 40.5955],
            [-74.4373, 40.5972],
            [-74.4087, 40.5438],
            [-74.4399, 40.5145],
            [-74.447, 40.506],
            [-74.4672, 40.5084],
            [-74.5158, 40.545],
            [-74.5195, 40.5553],
            [-74.4697, 40.5856],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3413050',
        NAME: 'Piscataway Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48793430,
        AWATER: 492578,
        INTPTLAT: '+40.5456398',
        INTPTLON: '-074.4608169',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2878, 40.4984],
            [-74.259, 40.4971],
            [-74.2526, 40.4871],
            [-74.2878, 40.4984],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415120',
        NAME: 'South Amboy City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3988211,
        AWATER: 2958091,
        INTPTLAT: '+40.4863907',
        INTPTLON: '-074.2787618',
        ELSDLEA: '',
        UNSDLEA: '15120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6239, 40.3666],
            [-74.6196, 40.3744],
            [-74.5315, 40.4414],
            [-74.4857, 40.4208],
            [-74.4517, 40.3772],
            [-74.4841, 40.3384],
            [-74.5485, 40.3364],
            [-74.6239, 40.3666],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415210',
        NAME: 'South Brunswick Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105274116,
        AWATER: 993810,
        INTPTLAT: '+40.3846946',
        INTPTLON: '-074.5227618',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3679, 40.4069],
            [-74.3816, 40.3829],
            [-74.4386, 40.3711],
            [-74.3679, 40.4069],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415540',
        NAME: 'Spotswood Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8072597,
        AWATER: 679034,
        INTPTLAT: '+40.3909960',
        INTPTLON: '-074.3996963',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5238, 40.5695],
            [-74.527, 40.5596],
            [-74.5428, 40.5552],
            [-74.5238, 40.5695],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3402100',
        NAME: 'Bound Brook Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4297065,
        AWATER: 91961,
        INTPTLAT: '+40.5677486',
        INTPTLON: '-074.5372499',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5989, 40.5576],
            [-74.5665, 40.5428],
            [-74.5893, 40.5279],
            [-74.5989, 40.5576],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3409630',
        NAME: 'Manville Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6113833,
        AWATER: 227075,
        INTPTLAT: '+40.5412695',
        INTPTLON: '-074.5892731',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6081, 40.5584],
            [-74.6264, 40.5632],
            [-74.6245, 40.5804],
            [-74.5987, 40.5797],
            [-74.6081, 40.5584],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3415090',
        NAME: 'Somerville Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6040280,
        AWATER: 78034,
        INTPTLAT: '+40.5697495',
        INTPTLON: '-074.6076819',
        ELSDLEA: '',
        UNSDLEA: '15090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8162, 38.9988],
            [-74.8216, 38.9965],
            [-74.8307, 39.0015],
            [-74.8242, 39.0057],
            [-74.8162, 38.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '34',
        SCSDLEA: '',
        GEOID: '3417670',
        NAME: 'West Wildwood Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713642,
        AWATER: 180772,
        INTPTLAT: '+39.0005979',
        INTPTLON: '-074.8235730',
        ELSDLEA: '',
        UNSDLEA: '17670',
      },
    },
  ],
};

export const WV = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1664, 39.2423],
            [-80.0234, 39.2479],
            [-79.8955, 39.2996],
            [-79.8097, 39.2305],
            [-79.8251, 39.1158],
            [-79.8961, 38.9734],
            [-80.0851, 38.9472],
            [-80.0447, 39.0102],
            [-80.2272, 39.1128],
            [-80.1664, 39.2423],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400030',
        NAME: 'Barbour County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 883338731,
        AWATER: 4639232,
        INTPTLAT: '+39.1397248',
        INTPTLON: '-079.9969466',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0221, 39.6197],
            [-77.8891, 39.5975],
            [-77.8256, 39.4933],
            [-78.0332, 39.2646],
            [-78.2288, 39.3912],
            [-78.1389, 39.5938],
            [-78.0221, 39.6197],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400060',
        NAME: 'Berkeley County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 831766636,
        AWATER: 1061824,
        INTPTLAT: '+39.4480541',
        INTPTLON: '-078.0377755',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9325, 38.0254],
            [-81.9324, 38.1501],
            [-81.8335, 38.2096],
            [-81.7646, 38.2275],
            [-81.6485, 38.185],
            [-81.4781, 38.0601],
            [-81.4566, 37.9873],
            [-81.5713, 37.9281],
            [-81.5142, 37.7912],
            [-81.6075, 37.7887],
            [-81.7221, 37.8095],
            [-81.7856, 37.9364],
            [-81.9325, 38.0254],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400090',
        NAME: 'Boone County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1298993469,
        AWATER: 4284345,
        INTPTLAT: '+38.0228142',
        INTPTLON: '-081.7135390',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9849, 38.7179],
            [-80.7015, 38.879],
            [-80.6058, 38.9042],
            [-80.4738, 38.8291],
            [-80.4573, 38.7392],
            [-80.5087, 38.6456],
            [-80.6508, 38.5266],
            [-80.7852, 38.5576],
            [-80.8812, 38.507],
            [-81.0317, 38.6678],
            [-80.9849, 38.7179],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400120',
        NAME: 'Braxton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1322996693,
        AWATER: 14144661,
        INTPTLAT: '+38.6993418',
        INTPTLON: '-080.7316565',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.615, 40.2916],
            [-80.6108, 40.3409],
            [-80.6297, 40.395],
            [-80.518, 40.3996],
            [-80.5191, 40.2817],
            [-80.5189, 40.1959],
            [-80.5191, 40.1597],
            [-80.6818, 40.1856],
            [-80.615, 40.2916],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400150',
        NAME: 'Brooke County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231034166,
        AWATER: 8714144,
        INTPTLAT: '+40.2726454',
        INTPTLON: '-080.5786912',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4951, 38.4152],
            [-82.392, 38.4354],
            [-82.324, 38.4493],
            [-82.2734, 38.5942],
            [-82.219, 38.5917],
            [-82.0551, 38.4745],
            [-82.0471, 38.3744],
            [-82.1958, 38.3235],
            [-82.2648, 38.2292],
            [-82.5083, 38.4112],
            [-82.4951, 38.4152],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400180',
        NAME: 'Cabell County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 727838068,
        AWATER: 18122000,
        INTPTLAT: '+38.4195778',
        INTPTLON: '-082.2433966',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2784, 38.9149],
            [-81.1625, 39.0306],
            [-81.0336, 39.0096],
            [-81.0028, 38.846],
            [-81.0522, 38.7908],
            [-80.9849, 38.7179],
            [-81.0317, 38.6678],
            [-81.0837, 38.612],
            [-81.1494, 38.6416],
            [-81.1718, 38.7398],
            [-81.2784, 38.9149],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400210',
        NAME: 'Calhoun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 723252434,
        AWATER: 3704539,
        INTPTLAT: '+38.8441591',
        INTPTLON: '-081.1154775',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1941, 38.5276],
            [-81.0837, 38.612],
            [-81.0317, 38.6678],
            [-80.8812, 38.507],
            [-80.9114, 38.4148],
            [-81.2314, 38.2637],
            [-81.2839, 38.472],
            [-81.1941, 38.5276],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400240',
        NAME: 'Clay County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 885617251,
        AWATER: 4862445,
        INTPTLAT: '+38.4598259',
        INTPTLON: '-081.0818663',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8887, 39.2943],
            [-80.6186, 39.4504],
            [-80.5445, 39.4291],
            [-80.5448, 39.3518],
            [-80.6043, 39.286],
            [-80.5288, 39.1957],
            [-80.5961, 39.1671],
            [-80.7283, 39.0957],
            [-80.813, 39.1094],
            [-80.9138, 39.2729],
            [-80.8887, 39.2943],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400270',
        NAME: 'Doddridge County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 828070481,
        AWATER: 1958441,
        INTPTLAT: '+39.2634805',
        INTPTLON: '-080.7012316',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3808, 37.9691],
            [-81.3315, 38.1676],
            [-81.2316, 38.2635],
            [-81.075, 38.1961],
            [-80.9835, 38.2225],
            [-80.8822, 38.1035],
            [-80.7699, 37.9853],
            [-80.8063, 37.8689],
            [-80.9451, 37.8191],
            [-81.0732, 37.8759],
            [-81.2951, 37.8893],
            [-81.3808, 37.9691],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400300',
        NAME: 'Fayette County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1713485858,
        AWATER: 17527378,
        INTPTLAT: '+38.0309328',
        INTPTLON: '-081.0860508',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0336, 39.0096],
            [-80.9183, 39.0436],
            [-80.813, 39.1094],
            [-80.7283, 39.0957],
            [-80.7168, 39.0084],
            [-80.6058, 38.9042],
            [-80.7015, 38.879],
            [-80.9849, 38.7179],
            [-81.0522, 38.7908],
            [-81.0028, 38.846],
            [-81.0336, 39.0096],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400330',
        NAME: 'Gilmer County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876717634,
        AWATER: 4082111,
        INTPTLAT: '+38.9158671',
        INTPTLON: '-080.8494132',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4872, 39.1949],
            [-79.4869, 39.2059],
            [-79.2717, 39.328],
            [-79.1345, 39.3125],
            [-78.9799, 39.2376],
            [-79.0897, 39.0382],
            [-79.046, 38.9287],
            [-79.1343, 38.8133],
            [-79.3499, 38.9575],
            [-79.3577, 38.9645],
            [-79.3038, 39.047],
            [-79.3482, 39.0937],
            [-79.2967, 39.1984],
            [-79.4872, 39.1949],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400360',
        NAME: 'Grant County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1236390743,
        AWATER: 7545524,
        INTPTLAT: '+39.1059882',
        INTPTLON: '-079.1950640',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8822, 38.1035],
            [-80.712, 38.0791],
            [-80.4365, 38.2673],
            [-80.36, 38.2258],
            [-80.3633, 38.1143],
            [-80.2647, 38.0466],
            [-80.1692, 38.0361],
            [-79.9598, 38.0637],
            [-80.0561, 37.952],
            [-80.1622, 37.8751],
            [-80.2961, 37.6918],
            [-80.5067, 37.6912],
            [-80.6633, 37.7317],
            [-80.8063, 37.8689],
            [-80.7699, 37.9853],
            [-80.8822, 38.1035],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400390',
        NAME: 'Greenbrier County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2640888946,
        AWATER: 12578876,
        INTPTLAT: '+37.9244182',
        INTPTLON: '-080.4505904',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9799, 39.2376],
            [-78.8507, 39.3311],
            [-78.7674, 39.4472],
            [-78.6571, 39.5351],
            [-78.4686, 39.5166],
            [-78.3471, 39.466],
            [-78.3404, 39.3536],
            [-78.4194, 39.2575],
            [-78.4039, 39.1677],
            [-78.5081, 39.0886],
            [-78.8997, 39.1991],
            [-78.9799, 39.2376],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400420',
        NAME: 'Hampshire County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1658559627,
        AWATER: 10969233,
        INTPTLAT: '+39.3121395',
        INTPTLON: '-078.6119886',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6326, 40.6172],
            [-80.519, 40.6388],
            [-80.519, 40.6374],
            [-80.519, 40.4774],
            [-80.518, 40.3996],
            [-80.6297, 40.395],
            [-80.6143, 40.4087],
            [-80.6114, 40.4371],
            [-80.6136, 40.4957],
            [-80.668, 40.5825],
            [-80.6326, 40.6172],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400450',
        NAME: 'Hancock County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213957141,
        AWATER: 13879330,
        INTPTLAT: '+40.5169584',
        INTPTLON: '-080.5701643',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9799, 39.2376],
            [-78.8997, 39.1991],
            [-78.5081, 39.0886],
            [-78.5441, 39.0567],
            [-78.6805, 38.9253],
            [-78.788, 38.8851],
            [-78.8693, 38.763],
            [-78.9938, 38.85],
            [-79.0573, 38.7614],
            [-79.1343, 38.8133],
            [-79.046, 38.9287],
            [-79.0897, 39.0382],
            [-78.9799, 39.2376],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400480',
        NAME: 'Hardy County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1508195534,
        AWATER: 5617193,
        INTPTLAT: '+39.0113612',
        INTPTLON: '-078.8417292',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5445, 39.4291],
            [-80.4941, 39.4696],
            [-80.2703, 39.4301],
            [-80.1973, 39.3932],
            [-80.2025, 39.2783],
            [-80.1664, 39.2423],
            [-80.2272, 39.1128],
            [-80.3002, 39.1039],
            [-80.5961, 39.1671],
            [-80.5288, 39.1957],
            [-80.6043, 39.286],
            [-80.5448, 39.3518],
            [-80.5445, 39.4291],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400510',
        NAME: 'Harrison County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1077454953,
        AWATER: 1320319,
        INTPTLAT: '+39.2791820',
        INTPTLON: '-080.3864982',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9086, 38.8785],
            [-81.7818, 38.9649],
            [-81.7933, 39.0404],
            [-81.7473, 39.0954],
            [-81.5814, 39.0262],
            [-81.5026, 38.9179],
            [-81.5466, 38.6724],
            [-81.5222, 38.6127],
            [-81.6328, 38.5544],
            [-81.6963, 38.6264],
            [-81.7728, 38.6808],
            [-81.9086, 38.8785],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400540',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1202677931,
        AWATER: 18836096,
        INTPTLAT: '+38.8342339',
        INTPTLON: '-081.6777168',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8256, 39.4933],
            [-77.7537, 39.4239],
            [-77.7195, 39.3213],
            [-77.8283, 39.1324],
            [-78.0332, 39.2646],
            [-77.8256, 39.4933],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400570',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 542732146,
        AWATER: 5491067,
        INTPTLAT: '+39.3073985',
        INTPTLON: '-077.8632200',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6963, 38.6264],
            [-81.6328, 38.5544],
            [-81.5222, 38.6127],
            [-81.4654, 38.5499],
            [-81.1941, 38.5276],
            [-81.2839, 38.472],
            [-81.2314, 38.2637],
            [-81.2316, 38.2635],
            [-81.3315, 38.1676],
            [-81.3808, 37.9691],
            [-81.4566, 37.9873],
            [-81.4781, 38.0601],
            [-81.6485, 38.185],
            [-81.7646, 38.2275],
            [-81.8335, 38.2096],
            [-81.8182, 38.3168],
            [-81.9152, 38.3256],
            [-81.9002, 38.3986],
            [-81.7258, 38.4666],
            [-81.7505, 38.591],
            [-81.6963, 38.6264],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400600',
        NAME: 'Kanawha County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2335953946,
        AWATER: 23318622,
        INTPTLAT: '+38.3280675',
        INTPTLON: '-081.5235156',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7283, 39.0957],
            [-80.5961, 39.1671],
            [-80.3002, 39.1039],
            [-80.3193, 38.9609],
            [-80.387, 38.8748],
            [-80.3931, 38.7276],
            [-80.4573, 38.7392],
            [-80.4738, 38.8291],
            [-80.6058, 38.9042],
            [-80.7168, 39.0084],
            [-80.7283, 39.0957],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400630',
        NAME: 'Lewis County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1002099077,
        AWATER: 7081051,
        INTPTLAT: '+38.9888773',
        INTPTLON: '-080.4954773',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2648, 38.2292],
            [-82.1958, 38.3235],
            [-82.0471, 38.3744],
            [-82.0492, 38.2917],
            [-81.9152, 38.3256],
            [-81.8182, 38.3168],
            [-81.8335, 38.2096],
            [-81.9324, 38.1501],
            [-81.9325, 38.0254],
            [-82.1907, 37.9748],
            [-82.3064, 37.9436],
            [-82.2022, 38.0362],
            [-82.2857, 38.145],
            [-82.2648, 38.2292],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400660',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1131925075,
        AWATER: 4023478,
        INTPTLAT: '+38.1717696',
        INTPTLON: '-082.0776231',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1907, 37.9748],
            [-81.9325, 38.0254],
            [-81.7856, 37.9364],
            [-81.7221, 37.8095],
            [-81.6075, 37.7887],
            [-81.7647, 37.7521],
            [-81.8029, 37.6605],
            [-82.0799, 37.7001],
            [-82.1907, 37.9748],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400690',
        NAME: 'Logan County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1175209714,
        AWATER: 4830097,
        INTPTLAT: '+37.8305905',
        INTPTLON: '-081.9408530',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3958, 39.6373],
            [-80.3475, 39.6117],
            [-80.1535, 39.6104],
            [-79.9389, 39.4525],
            [-80.025, 39.4014],
            [-80.1973, 39.3932],
            [-80.2703, 39.4301],
            [-80.4941, 39.4696],
            [-80.4982, 39.5629],
            [-80.3958, 39.6373],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400720',
        NAME: 'Marion County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 799642465,
        AWATER: 7186184,
        INTPTLAT: '+39.5058387',
        INTPTLON: '-080.2434018',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8079, 39.9145],
            [-80.7395, 39.9784],
            [-80.7338, 40.0334],
            [-80.5191, 40.0164],
            [-80.5191, 39.9622],
            [-80.5193, 39.7214],
            [-80.8335, 39.7208],
            [-80.8699, 39.7636],
            [-80.7931, 39.8638],
            [-80.8079, 39.9145],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400750',
        NAME: 'Marshall County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 791063504,
        AWATER: 17411500,
        INTPTLAT: '+39.8544259',
        INTPTLON: '-080.6717944',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1407, 38.8561],
            [-82.099, 38.9584],
            [-82.0005, 39.0268],
            [-81.9006, 38.9377],
            [-81.9086, 38.8785],
            [-81.7728, 38.6808],
            [-81.9558, 38.6574],
            [-82.0646, 38.608],
            [-82.0551, 38.4745],
            [-82.219, 38.5917],
            [-82.1867, 38.6686],
            [-82.2216, 38.7872],
            [-82.1407, 38.8561],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400780',
        NAME: 'Mason County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1115633604,
        AWATER: 36174216,
        INTPTLAT: '+38.7592882',
        INTPTLON: '-082.0241715',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9277, 37.5121],
            [-81.8559, 37.5489],
            [-81.7294, 37.4946],
            [-81.6544, 37.5233],
            [-81.5829, 37.4873],
            [-81.4502, 37.4762],
            [-81.3112, 37.4245],
            [-81.3622, 37.3377],
            [-81.5606, 37.2067],
            [-81.6782, 37.2015],
            [-81.7401, 37.2378],
            [-81.8536, 37.2877],
            [-81.9966, 37.4767],
            [-81.9277, 37.5121],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400810',
        NAME: 'McDowell County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1381646920,
        AWATER: 3733264,
        INTPTLAT: '+37.3826627',
        INTPTLON: '-081.6582048',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3112, 37.4245],
            [-81.2221, 37.5104],
            [-81.1993, 37.5745],
            [-81.0946, 37.5887],
            [-80.8595, 37.4295],
            [-80.8495, 37.3469],
            [-80.9801, 37.2927],
            [-81.1041, 37.2806],
            [-81.2251, 37.2349],
            [-81.3622, 37.3377],
            [-81.3112, 37.4245],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400840',
        NAME: 'Mercer County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1085127232,
        AWATER: 4432913,
        INTPTLAT: '+37.4034477',
        INTPTLON: '-081.1064560',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0679, 39.4792],
            [-78.9573, 39.4401],
            [-78.7953, 39.6107],
            [-78.6571, 39.5351],
            [-78.7674, 39.4472],
            [-78.8507, 39.3311],
            [-78.9799, 39.2376],
            [-79.1345, 39.3125],
            [-79.2717, 39.328],
            [-79.0679, 39.4792],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400870',
        NAME: 'Mineral County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 849080971,
        AWATER: 3595062,
        INTPTLAT: '+39.4047806',
        INTPTLON: '-078.9566899',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4148, 37.8555],
            [-82.3064, 37.9436],
            [-82.1907, 37.9748],
            [-82.0799, 37.7001],
            [-81.8029, 37.6605],
            [-81.8559, 37.5489],
            [-81.9277, 37.5121],
            [-81.968, 37.538],
            [-82.1173, 37.5592],
            [-82.2822, 37.6758],
            [-82.3331, 37.7408],
            [-82.4148, 37.8555],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400900',
        NAME: 'Mingo County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1095914379,
        AWATER: 2190076,
        INTPTLAT: '+37.7211608',
        INTPTLON: '-082.1589891',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4214, 39.7212],
            [-80.2938, 39.7212],
            [-80.0417, 39.7213],
            [-79.9163, 39.7208],
            [-79.7638, 39.7208],
            [-79.7893, 39.6238],
            [-79.8997, 39.4819],
            [-79.8947, 39.4356],
            [-79.9389, 39.4525],
            [-80.1535, 39.6104],
            [-80.3475, 39.6117],
            [-80.3958, 39.6373],
            [-80.4214, 39.7212],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400930',
        NAME: 'Monongalia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 932572492,
        AWATER: 15051916,
        INTPTLAT: '+39.6336448',
        INTPTLON: '-080.0590736',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8585, 37.4283],
            [-80.6604, 37.7146],
            [-80.6633, 37.7317],
            [-80.5067, 37.6912],
            [-80.2961, 37.6918],
            [-80.221, 37.6278],
            [-80.3285, 37.5643],
            [-80.2998, 37.5083],
            [-80.4756, 37.4229],
            [-80.5114, 37.4817],
            [-80.7701, 37.3724],
            [-80.8585, 37.4283],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400960',
        NAME: 'Monroe County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1224422315,
        AWATER: 2298715,
        INTPTLAT: '+37.5503396',
        INTPTLON: '-080.5478898',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3338, 39.6368],
            [-78.2666, 39.619],
            [-78.1715, 39.6956],
            [-78.0221, 39.6197],
            [-78.1389, 39.5938],
            [-78.2288, 39.3912],
            [-78.3471, 39.466],
            [-78.4686, 39.5166],
            [-78.3338, 39.6368],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5400990',
        NAME: 'Morgan County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 593295476,
        AWATER: 1401570,
        INTPTLAT: '+39.5550021',
        INTPTLON: '-078.2573449',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2316, 38.2635],
            [-81.2314, 38.2637],
            [-80.9114, 38.4148],
            [-80.8812, 38.507],
            [-80.7852, 38.5576],
            [-80.6508, 38.5266],
            [-80.6783, 38.5035],
            [-80.6094, 38.3586],
            [-80.4365, 38.2673],
            [-80.712, 38.0791],
            [-80.8822, 38.1035],
            [-80.9835, 38.2225],
            [-81.075, 38.1961],
            [-81.2316, 38.2635],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401020',
        NAME: 'Nicholas County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1675296013,
        AWATER: 19608975,
        INTPTLAT: '+38.2914157',
        INTPTLON: '-080.7975160',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7354, 40.0821],
            [-80.708, 40.1433],
            [-80.6818, 40.1856],
            [-80.5191, 40.1597],
            [-80.5191, 40.0164],
            [-80.7338, 40.0334],
            [-80.7325, 40.0559],
            [-80.7354, 40.0821],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401050',
        NAME: 'Ohio County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274098955,
        AWATER: 8274300,
        INTPTLAT: '+40.0989316',
        INTPTLON: '-080.6207278',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6268, 38.6642],
            [-79.5101, 38.7807],
            [-79.5391, 38.8414],
            [-79.4555, 38.9299],
            [-79.3499, 38.9575],
            [-79.1343, 38.8133],
            [-79.0573, 38.7614],
            [-79.2243, 38.4778],
            [-79.3123, 38.4119],
            [-79.4766, 38.4572],
            [-79.5369, 38.5509],
            [-79.6491, 38.5915],
            [-79.6268, 38.6642],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401080',
        NAME: 'Pendleton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1802790562,
        AWATER: 5437237,
        INTPTLAT: '+38.6744501',
        INTPTLON: '-079.3406175',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3043, 39.3708],
            [-81.2117, 39.393],
            [-81.1215, 39.4578],
            [-81.0257, 39.4623],
            [-81.0078, 39.3509],
            [-81.2213, 39.3075],
            [-81.2395, 39.2683],
            [-81.3713, 39.3419],
            [-81.3043, 39.3708],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401110',
        NAME: 'Pleasants County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336965609,
        AWATER: 11510007,
        INTPTLAT: '+39.3681331',
        INTPTLON: '-081.1611724',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.36, 38.2258],
            [-80.3303, 38.3358],
            [-80.2455, 38.3885],
            [-80.1169, 38.474],
            [-80.0292, 38.4592],
            [-79.8632, 38.5508],
            [-79.7983, 38.7032],
            [-79.6268, 38.6642],
            [-79.6491, 38.5915],
            [-79.6897, 38.4315],
            [-79.7959, 38.2656],
            [-79.8973, 38.1934],
            [-79.9598, 38.0637],
            [-80.1692, 38.0361],
            [-80.2647, 38.0466],
            [-80.3633, 38.1143],
            [-80.36, 38.2258],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401140',
        NAME: 'Pocahontas County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2435344905,
        AWATER: 3978619,
        INTPTLAT: '+38.3326060',
        INTPTLON: '-080.0101207',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7638, 39.7208],
            [-79.7191, 39.721],
            [-79.4767, 39.7211],
            [-79.4869, 39.2059],
            [-79.4872, 39.1949],
            [-79.6873, 39.2714],
            [-79.8097, 39.2305],
            [-79.8955, 39.2996],
            [-79.8947, 39.4356],
            [-79.8997, 39.4819],
            [-79.7893, 39.6238],
            [-79.7638, 39.7208],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401170',
        NAME: 'Preston County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1680406635,
        AWATER: 6681831,
        INTPTLAT: '+39.4690296',
        INTPTLON: '-079.6688649',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7728, 38.6808],
            [-81.6963, 38.6264],
            [-81.7505, 38.591],
            [-81.7258, 38.4666],
            [-81.9002, 38.3986],
            [-81.9152, 38.3256],
            [-82.0492, 38.2917],
            [-82.0471, 38.3744],
            [-82.0551, 38.4745],
            [-82.0646, 38.608],
            [-81.9558, 38.6574],
            [-81.7728, 38.6808],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401200',
        NAME: 'Putnam County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 895440742,
        AWATER: 12088530,
        INTPTLAT: '+38.5105128',
        INTPTLON: '-081.9061090',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4566, 37.9873],
            [-81.3808, 37.9691],
            [-81.2951, 37.8893],
            [-81.0732, 37.8759],
            [-80.9451, 37.8191],
            [-80.906, 37.6986],
            [-81.0688, 37.6398],
            [-81.0946, 37.5887],
            [-81.1993, 37.5745],
            [-81.2221, 37.5104],
            [-81.3041, 37.5466],
            [-81.4278, 37.7494],
            [-81.5142, 37.7912],
            [-81.5713, 37.9281],
            [-81.4566, 37.9873],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401230',
        NAME: 'Raleigh County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1567864571,
        AWATER: 10336249,
        INTPTLAT: '+37.7624696',
        INTPTLON: '-081.2646711',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0851, 38.9472],
            [-79.8961, 38.9734],
            [-79.8251, 39.1158],
            [-79.7857, 39.0374],
            [-79.5088, 38.9752],
            [-79.3577, 38.9645],
            [-79.3499, 38.9575],
            [-79.4555, 38.9299],
            [-79.5391, 38.8414],
            [-79.5101, 38.7807],
            [-79.6268, 38.6642],
            [-79.7983, 38.7032],
            [-79.8632, 38.5508],
            [-80.0292, 38.4592],
            [-80.1169, 38.474],
            [-80.2455, 38.3885],
            [-80.1839, 38.5254],
            [-80.2801, 38.6949],
            [-80.2368, 38.7438],
            [-80.1261, 38.7458],
            [-80.0851, 38.9472],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401260',
        NAME: 'Randolph County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2692796583,
        AWATER: 667359,
        INTPTLAT: '+38.7810953',
        INTPTLON: '-079.8677881',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.298, 39.1856],
            [-81.2395, 39.2683],
            [-81.2213, 39.3075],
            [-81.0078, 39.3509],
            [-80.9374, 39.3866],
            [-80.8887, 39.2943],
            [-80.9138, 39.2729],
            [-80.813, 39.1094],
            [-80.9183, 39.0436],
            [-81.0336, 39.0096],
            [-81.1625, 39.0306],
            [-81.2503, 39.0351],
            [-81.298, 39.1856],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401290',
        NAME: 'Ritchie County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1170648310,
        AWATER: 4425143,
        INTPTLAT: '+39.1771118',
        INTPTLON: '-081.0663167',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5026, 38.9179],
            [-81.4481, 38.9416],
            [-81.3439, 38.8956],
            [-81.2784, 38.9149],
            [-81.1718, 38.7398],
            [-81.1494, 38.6416],
            [-81.0837, 38.612],
            [-81.1941, 38.5276],
            [-81.4654, 38.5499],
            [-81.5222, 38.6127],
            [-81.5466, 38.6724],
            [-81.5026, 38.9179],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401320',
        NAME: 'Roane County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1252422376,
        AWATER: 404682,
        INTPTLAT: '+38.7430030',
        INTPTLON: '-081.3545223',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9451, 37.8191],
            [-80.8063, 37.8689],
            [-80.6633, 37.7317],
            [-80.6604, 37.7146],
            [-80.8585, 37.4283],
            [-80.8595, 37.4295],
            [-81.0946, 37.5887],
            [-81.0688, 37.6398],
            [-80.906, 37.6986],
            [-80.9451, 37.8191],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401350',
        NAME: 'Summers County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 933832340,
        AWATER: 18575981,
        INTPTLAT: '+37.6560029',
        INTPTLON: '-080.8563149',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1973, 39.3932],
            [-80.025, 39.4014],
            [-79.9389, 39.4525],
            [-79.8947, 39.4356],
            [-79.8955, 39.2996],
            [-80.0234, 39.2479],
            [-80.1664, 39.2423],
            [-80.2025, 39.2783],
            [-80.1973, 39.3932],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401380',
        NAME: 'Taylor County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 447475066,
        AWATER: 7532835,
        INTPTLAT: '+39.3324778',
        INTPTLON: '-080.0465539',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8097, 39.2305],
            [-79.6873, 39.2714],
            [-79.4872, 39.1949],
            [-79.2967, 39.1984],
            [-79.3482, 39.0937],
            [-79.3038, 39.047],
            [-79.3577, 38.9645],
            [-79.5088, 38.9752],
            [-79.7857, 39.0374],
            [-79.8251, 39.1158],
            [-79.8097, 39.2305],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401410',
        NAME: 'Tucker County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1085589879,
        AWATER: 5528932,
        INTPTLAT: '+39.1111751',
        INTPTLON: '-079.5599678',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1215, 39.4578],
            [-81.0387, 39.5399],
            [-80.9438, 39.6069],
            [-80.8437, 39.5839],
            [-80.7161, 39.4755],
            [-80.6186, 39.4504],
            [-80.8887, 39.2943],
            [-80.9374, 39.3866],
            [-81.0078, 39.3509],
            [-81.0257, 39.4623],
            [-81.1215, 39.4578],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401440',
        NAME: 'Tyler County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663797704,
        AWATER: 11403003,
        INTPTLAT: '+39.4656340',
        INTPTLON: '-080.8772193',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3002, 39.1039],
            [-80.2272, 39.1128],
            [-80.0447, 39.0102],
            [-80.0851, 38.9472],
            [-80.1261, 38.7458],
            [-80.2368, 38.7438],
            [-80.2801, 38.6949],
            [-80.3931, 38.7276],
            [-80.387, 38.8748],
            [-80.3193, 38.9609],
            [-80.3002, 39.1039],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401470',
        NAME: 'Upshur County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 918506896,
        AWATER: 302703,
        INTPTLAT: '+38.9025365',
        INTPTLON: '-080.2313352',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6046, 38.2477],
            [-82.5935, 38.4218],
            [-82.5083, 38.4112],
            [-82.2648, 38.2292],
            [-82.2857, 38.145],
            [-82.2022, 38.0362],
            [-82.3064, 37.9436],
            [-82.4148, 37.8555],
            [-82.4978, 37.9455],
            [-82.5492, 38.068],
            [-82.6347, 38.1369],
            [-82.6046, 38.2477],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401500',
        NAME: 'Wayne County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1310504123,
        AWATER: 15860720,
        INTPTLAT: '+38.1455413',
        INTPTLON: '-082.4196812',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6508, 38.5266],
            [-80.5087, 38.6456],
            [-80.4573, 38.7392],
            [-80.3931, 38.7276],
            [-80.2801, 38.6949],
            [-80.1839, 38.5254],
            [-80.2455, 38.3885],
            [-80.3303, 38.3358],
            [-80.36, 38.2258],
            [-80.4365, 38.2673],
            [-80.6094, 38.3586],
            [-80.6783, 38.5035],
            [-80.6508, 38.5266],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401530',
        NAME: 'Webster County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1433475688,
        AWATER: 7148637,
        INTPTLAT: '+38.4834590',
        INTPTLON: '-080.4490515',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9438, 39.6069],
            [-80.8804, 39.6207],
            [-80.8335, 39.7208],
            [-80.5193, 39.7214],
            [-80.4214, 39.7212],
            [-80.3958, 39.6373],
            [-80.4982, 39.5629],
            [-80.4941, 39.4696],
            [-80.5445, 39.4291],
            [-80.6186, 39.4504],
            [-80.7161, 39.4755],
            [-80.8437, 39.5839],
            [-80.9438, 39.6069],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401560',
        NAME: 'Wetzel County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 927380674,
        AWATER: 8458366,
        INTPTLAT: '+39.6006015',
        INTPTLON: '-080.6281566',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.298, 39.1856],
            [-81.2503, 39.0351],
            [-81.1625, 39.0306],
            [-81.2784, 38.9149],
            [-81.3439, 38.8956],
            [-81.4481, 38.9416],
            [-81.5026, 38.9179],
            [-81.5814, 39.0262],
            [-81.4249, 39.1357],
            [-81.298, 39.1856],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401590',
        NAME: 'Wirt County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602201080,
        AWATER: 5839706,
        INTPTLAT: '+39.0200337',
        INTPTLON: '-081.3829749',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7561, 39.1806],
            [-81.7256, 39.2159],
            [-81.6834, 39.2711],
            [-81.5652, 39.2762],
            [-81.5591, 39.333],
            [-81.4834, 39.3892],
            [-81.3713, 39.3419],
            [-81.2395, 39.2683],
            [-81.298, 39.1856],
            [-81.4249, 39.1357],
            [-81.5814, 39.0262],
            [-81.7473, 39.0954],
            [-81.7561, 39.1806],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401620',
        NAME: 'Wood County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 948609303,
        AWATER: 27211266,
        INTPTLAT: '+39.2115749',
        INTPTLON: '-081.5162531',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8029, 37.6605],
            [-81.7647, 37.7521],
            [-81.6075, 37.7887],
            [-81.5142, 37.7912],
            [-81.4278, 37.7494],
            [-81.3041, 37.5466],
            [-81.2221, 37.5104],
            [-81.3112, 37.4245],
            [-81.4502, 37.4762],
            [-81.5829, 37.4873],
            [-81.6544, 37.5233],
            [-81.7294, 37.4946],
            [-81.8559, 37.5489],
            [-81.8029, 37.6605],
          ],
        ],
      },
      properties: {
        STATEFP: '54',
        SCSDLEA: '',
        GEOID: '5401650',
        NAME: 'Wyoming County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1293573973,
        AWATER: 6126986,
        INTPTLAT: '+37.6037173',
        INTPTLON: '-081.5488841',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Col, Container, Row, Spinner, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import shortid from 'shortid';
import { debounce } from 'lodash';
import {
  listUserReports,
  createReport,
  shareReport,
  deleteReport,
  hideShareToast,
  updateReportDetails,
} from '../../actions/report.action';
import ReportSorter from './components/ReportSorter';
import ReportTile from './components/ReportTile';
import CreateReportModal from './components/CreateReportModal';
import DeleteReportModal from './components/DeleteReportModal';
import ReportSearchInput from './components/ReportSearchInput';
import { BENTO_URL, ENABLE_REPORT_IMPROVEMENTS } from '../../config/appConfig';
import { getUsersByEmailList } from '../../actions/user.action';

import './style.css';

export const addTextToClipboard = (text) => {
  const elem = document.createElement('textarea');
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
};

const ReportsLanding = () => {
  const storeJwt = useSelector((state) => state.session.jwt);
  const dispatch = useDispatch();
  const { usersCreators } = useSelector((state) => state.users);
  const { loading, reports, showShareToast, shareToken } = useSelector((state) => state.reports);
  const [showCreateReportModal, setShowCreateReportModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [filterByFav, setFilterByFav] = useState(false);
  const [currentSort, setCurrentSort] = useState();
  const [currentSortDirection, setCurrentSortDirection] = useState();
  const [currentSearchText, setCurrentSearchText] = useState();
  const [currentSearchAuthorText, setCurrentSearchAuthorText] = useState();
  const [currentReportId, setCurrentReportId] = useState(null);

  const shareUrl = useMemo(
    () => shareToken && `${BENTO_URL}/reports/shared?shareToken=${shareToken}`,
    [shareToken]
  );

  const handleAuthorSearchInputChange = (selectedOptions) => {
    const searchInputValue = selectedOptions?.value;
    if (searchInputValue) {
      setCurrentSearchAuthorText(searchInputValue.trim());
    } else {
      setCurrentSearchAuthorText('');
    }
  };

  const searchReportsDebounced = useCallback(
    debounce((searchInputValue) => {
      setCurrentSearchText(searchInputValue.trim());
    }, 500),
    []
  );

  const handleSearchInputChange = (e) => {
    const searchInputValue = e.target.value;
    if (!searchInputValue) {
      setCurrentSearchText(searchInputValue.trim());
      return;
    }
    searchReportsDebounced(searchInputValue);
  };
  const handleCreateReportConfirmation = (reportName) => {
    if (reportName) {
      dispatch(createReport({ name: reportName }, storeJwt));
    }
  };

  const handleShareButtonClick = (id) => {
    dispatch(shareReport(id, storeJwt));
  };

  const handleDeleteButtonClick = (id) => {
    setCurrentReportId(id);
    setShowDeleteReportModal(true);
  };

  const handleToggleFav = (id) => {
    const report = reports.find((r) => r.id === id);
    dispatch(updateReportDetails({ ...report, isStarred: !report.isStarred }, storeJwt));
    setTimeout(() => {
      dispatch(listUserReports(storeJwt, currentSort, currentSortDirection, currentSearchText));
    }, 200);
  };

  const handleCopyToClipboardClick = () => {
    addTextToClipboard(shareUrl);
    dispatch(hideShareToast());
  };

  const handleSortChange = (sortName, direction) => {
    setCurrentSort(sortName);
    setCurrentSortDirection(direction);
  };

  useEffect(() => {
    dispatch(
      listUserReports(
        storeJwt,
        currentSort,
        currentSortDirection,
        currentSearchText,
        filterByFav ? 1 : 0,
        currentSearchAuthorText
      )
    );
  }, [currentSort, currentSortDirection, currentSearchText, filterByFav, currentSearchAuthorText]);

  useEffect(() => {
    if (reports.length) {
      const creatorsEmails = reports.filter((r) => r.originalCreator).map((r) => r.originalCreator);
      dispatch(getUsersByEmailList(creatorsEmails, storeJwt));
    }
  }, [reports]);

  return (
    <Container className="reports-landing-container pb-5">
      <Row className="mt-5">
        <Col xs="4" md="3">
          <ReportSorter handleSortChange={handleSortChange} />
        </Col>
        <Col xs="4" md="2" className="d-flex">
          <Button
            onClick={() => {
              setFilterByFav(!filterByFav);
            }}
            className={filterByFav ? 'rounded-pill mx-2 btn-type-selected' : 'rounded-pill mx-2'}
          >
            <FontAwesomeIcon className="mt-0" icon={faStar} />
          </Button>
          {ENABLE_REPORT_IMPROVEMENTS && (
            <Select
              isClearable
              className="w-75"
              placeholder="Select Original Author"
              isSearchable
              closeMenuOnSelect={false}
              options={usersCreators?.map((l) => ({
                label: `${l.firstName} ${l.lastName}`,
                value: l.email,
              }))}
              onChange={handleAuthorSearchInputChange}
            />
          )}
        </Col>
        <Col xs="12" sm="4" md={{ span: 3, offset: 3 }} lg={{ span: 3, offset: 3 }}>
          <ReportSearchInput onChange={handleSearchInputChange} />
        </Col>
      </Row>
      <Row className="mt-3">
        {loading ? (
          <Col xs="12" className="d-flex justify-content-center">
            <Spinner animation="border" />
          </Col>
        ) : (
          <>
            <Col key={shortid.generate()} xs="12" sm="6" md="4" lg="3" className="my-2">
              <Card
                className="h-100 d-flex flex-column justify-content-center align-items-center text-center create-report-tile"
                size="lg"
                border="dark"
                onClick={() => setShowCreateReportModal(true)}
              >
                <Card.Text className="title-text-size">New Report</Card.Text>
                <FontAwesomeIcon icon={faPlusCircle} size="3x" />
              </Card>
            </Col>
            {reports?.map((report) => (
              <Col key={report.id} xs="12" sm="6" md="4" lg="3" className="my-2">
                <ReportTile
                  report={report}
                  onShareButtonClick={handleShareButtonClick}
                  onDeleteButtonClick={handleDeleteButtonClick}
                  onToggleFav={handleToggleFav}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
      <CreateReportModal
        open={showCreateReportModal}
        onRequestToClose={() => setShowCreateReportModal(false)}
        onConfirm={handleCreateReportConfirmation}
      />
      <DeleteReportModal
        open={showDeleteReportModal}
        onRequestToClose={() => {
          setShowDeleteReportModal(false);
          setCurrentReportId(null);
        }}
        onConfirm={() => dispatch(deleteReport(currentReportId, storeJwt))}
      />
      <Toast
        className="report-share-toast-container"
        onClose={() => dispatch(hideShareToast())}
        show={showShareToast}
      >
        <Toast.Header>
          <Button
            className="mr-auto"
            variant="secondary"
            aria-label="Copy to Clipboard"
            onClick={handleCopyToClipboardClick}
          >
            Copy to Clipboard
          </Button>
        </Toast.Header>
        <Toast.Body>{shareUrl}</Toast.Body>
      </Toast>
    </Container>
  );
};

export default React.memo(ReportsLanding);

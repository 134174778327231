import types from '../types';

const initialState = {
  surveyFetchErr: false,
  visualizationsFetchErr: false,
  getAllMetadatasErr: false,
  getAllSampleMetadataErr: false,
  updateChartErr: false,
};

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.RESET_ALL_ERRORS: {
      return {
        ...state,
        ...initialState,
      };
    }

    case types.reducerTypes.NEW_ERROR: {
      const { errorType } = action;
      switch (errorType) {
        case types.errorTypes.surveyFetchErr: {
          return {
            ...state,
            surveyFetchErr: true,
          };
        }

        case types.errorTypes.visualizationsFetchErr: {
          return {
            ...state,
            visualizationsFetchErr: true,
          };
        }

        case types.errorTypes.getAllMetadatasErr: {
          return {
            ...state,
            getAllMetadatasErr: true,
          };
        }

        case types.errorTypes.getAllSampleMetadataErr: {
          return {
            ...state,
            getAllSampleMetadataErr: true,
          };
        }

        case types.errorTypes.updateChartErr: {
          return {
            ...state,
            updateChartErr: true,
          };
        }
        default:
          return state;
      }
    }
    default:
  }
  return state;
};

export default errorsReducer;

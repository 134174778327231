import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, ListGroup, Modal, ProgressBar, Row } from 'react-bootstrap';

import { createUserBatch, resetUserBatch } from '../../../../actions/user.action';

const UserBatch = () => {
  const dispatch = useDispatch();
  const storeJwt = useSelector((state) => state.session.jwt);
  const { isProcessingBatch, batchProgress, batchErrors } = useSelector((state) => state.users);
  const [showModal, setShowModal] = useState(false);
  const [fileContent, setFileContent] = useState();
  const [userBatch, setUsersBatch] = useState([]);
  const fileRef = useRef();

  const handleClose = () => {
    if (!isProcessingBatch) {
      setShowModal(false);
      dispatch(resetUserBatch());
    }
  };

  const handleCreateBatchUsers = () => setShowModal(true);
  const handleUploadClick = () => {
    if (fileRef?.current) {
      setUsersBatch([]);
      fileRef.current.value = null;
      fileRef.current.click();
    }
  };

  const handleFileChange = () => {
    const fr = new FileReader();
    fr.onload = async (e) => {
      const text = e.target.result;
      setFileContent(text);
    };
    fr.readAsText(fileRef.current.files[0]);
  };

  const handleProcessUsers = async () => {
    if (userBatch.length > 0) {
      const usersToProcess = userBatch.map((ub) => ({
        username: ub[2],
        email: ub[2],
        firstName: ub[0],
        lastName: ub[1],
        status: 'ACTIVE',
        role: ub[4],
        state: '',
        organizations: ub[3] && ub[3] !== '' ? ub[3].split(';').map((i) => i.trim()) : [],
        lastLogin: new Date(),
        institution: ub.length === 5 ? ub[5] : '',
      }));
      dispatch(createUserBatch(usersToProcess, storeJwt));
      setFileContent('');
    }
  };

  useEffect(() => {
    if (fileContent && fileContent !== '') {
      const csvLines = fileContent
        .split(/[\r\n]+/g)
        .map((line) => line.trim())
        .filter((line) => line.length > 0);
      const usersBatch = csvLines.slice(1).map((r) => r.split(',').map((i) => i.trim()));
      setUsersBatch(usersBatch);
    }
  }, [fileContent]);

  return (
    <>
      <button
        title="Create Users Batch"
        className="btn btn-custom dark"
        type="button"
        onClick={handleCreateBatchUsers}
      >
        <FontAwesomeIcon icon={faUsers} />
      </button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Create Users Batch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="text-left">
                <p>Upload batch file. (.csv)</p>
                <input ref={fileRef} className="d-none" type="file" onChange={handleFileChange} />
                <Button
                  variant="primary"
                  className="mb-5 btn btn-custom dark"
                  onClick={handleUploadClick}
                  disabled={isProcessingBatch}
                >
                  Upload File <FontAwesomeIcon className="ml-2" icon={faFileCsv} />
                </Button>
              </Col>
            </Row>
            {isProcessingBatch && (
              <Row>
                <Col>
                  <ProgressBar striped variant="info" now={batchProgress} />
                </Col>
              </Row>
            )}
            {batchErrors?.length > 0 && !isProcessingBatch && (
              <ListGroup>
                {batchErrors.map((error) => (
                  <ListGroup.Item key={error.username} variant="danger">
                    {error.username}: {error.message}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
            {batchErrors?.length === 0 && batchProgress === 100 && (
              <ListGroup>
                <ListGroup.Item variant="success">
                  Batch process complete successfully! New {userBatch.length} users created!
                </ListGroup.Item>
              </ListGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="btn btn-custom white"
              disabled={isProcessingBatch}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn btn-custom dark"
              disabled={isProcessingBatch}
              onClick={handleProcessUsers}
            >
              Create Users
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default UserBatch;

import _ from 'lodash';
import types from '../types';

const initialState = {
  relatedSurveyId: null,
  relatedOrgId: null,
  wizard: undefined,
  wizardRule: undefined,
  providerSteps: undefined,
  step: 0,
  files: {},
  informative: {},
  formData: {},
  selectedFile: undefined,
  uploadError: false,
  replaceDataSet: {},
  selectedSurvey: {
    surveyId: undefined,
  },
  selectedOrg: {
    orgId: undefined,
  },
};

const wizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SET_PROVIDER: {
      const {
        wizard,
        steps: providerSteps,
        informative,
        providerLabel,
        wizardRule,
      } = action.payload;
      return {
        ...state,
        wizard,
        providerSteps,
        files: {},
        step: 0,
        informative,
        providerLabel,
        wizardRule,
      };
    }
    case types.reducerTypes.SET_WIZARD: {
      const { wizardName: providerName, steps: providerSteps, informative } = action.payload;
      return {
        ...state,
        providerName,
        providerSteps,
        files: {},
        step: 0,
        informative,
      };
    }
    case types.reducerTypes.SUM_STEP: {
      const step = state.step + 1;
      return { ...state, step };
    }
    case types.reducerTypes.DEC_STEP: {
      const step = state.step - 1;
      return { ...state, step };
    }
    case types.reducerTypes.SET_FILE_PROGRESS: {
      const { file } = action.payload;
      file.uploaded = false;
      file.isUploading = true;
      return {
        ...state,
        files: { ...state.files, [file.ObjectName]: file },
      };
    }
    case types.reducerTypes.SET_FILE_SUCCESS: {
      const { file } = action.payload;
      file.uploaded = true;
      file.isUploading = false;
      return {
        ...state,
        files: { ...state.files, [file.ObjectName]: file },
      };
    }
    case types.reducerTypes.SET_FILE_ERROR: {
      return {
        ...state,
        uploadError: true,
      };
    }
    case types.reducerTypes.SELECTED_FILE: {
      const { name } = action.payload;
      return {
        ...state,
        selectedFile: name,
      };
    }
    case types.reducerTypes.REMOVE_FILE: {
      const files = { ...state.files };
      delete files[state.selectedFile];
      return {
        ...state,
        files,
      };
    }
    case types.reducerTypes.SET_INPUT_VALUE: {
      const formData = { ...state.formData };
      const { name, value } = action.payload;
      formData[name] = value;
      return {
        ...state,
        formData,
      };
    }
    case types.reducerTypes.SET_CHECKBOX_VALUE: {
      const { name, value, checkboxGroupLabel } = action.payload;
      const formData = { ...state.formData };
      if (!_.has(formData, checkboxGroupLabel)) {
        formData[checkboxGroupLabel] = [];
      }
      if (value === true) formData[checkboxGroupLabel].push(name);
      if (formData[checkboxGroupLabel].includes(name)) {
        if (value === false) {
          const index = formData[checkboxGroupLabel].indexOf(name);
          if (index !== -1) formData[checkboxGroupLabel].splice(index, 1);
        }
      }

      if (formData[checkboxGroupLabel].length <= 0) delete formData[checkboxGroupLabel];
      return {
        ...state,
        formData,
      };
    }
    case types.reducerTypes.SET_REPLACE_DATA: {
      const { surveyId, surveyParentId, orgId } = action.payload;
      const replaceDataSet = { surveyId, surveyParentId, orgId };
      return {
        ...state,
        replaceDataSet,
      };
    }
    case types.reducerTypes.RESET_FILES: {
      return {
        ...state,
        wizard: undefined,
        wizardRule: undefined,
        providerSteps: undefined,
        step: 0,
        files: {},
        informative: {},
        formData: {},
        selectedFile: undefined,
        uploadError: false,
        replaceDataSet: {},
      };
    }
    case types.reducerTypes.SELECTED_SURVEY: {
      const { Id, isDraft, parentId } = action.payload;
      const selectedSurvey = {
        surveyId: Id,
        isDraft,
        parentId,
      };
      return {
        ...state,
        selectedSurvey,
      };
    }
    case types.reducerTypes.RESET_WIZARD: {
      return {
        ...state,
        ...initialState,
      };
    }
    case types.reducerTypes.SET_RELATED_SURVEY_ID: {
      const { relatedSurveyId } = action;
      return {
        ...state,
        relatedSurveyId,
      };
    }
    case types.reducerTypes.SET_RELATED_ORG_ID: {
      const { relatedOrgId } = action;
      return {
        ...state,
        relatedOrgId,
      };
    }
    default:
      return { ...state };
  }
};
export default wizardReducer;

export const IL = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9225, 42.1717],
            [-87.922, 42.172],
            [-87.9074, 42.1747],
            [-87.8949, 42.1826],
            [-87.8942, 42.1857],
            [-87.8942, 42.1892],
            [-87.8869, 42.2038],
            [-87.847, 42.2036],
            [-87.8089, 42.2247],
            [-87.759, 42.1524],
            [-87.7895, 42.1525],
            [-87.8284, 42.1527],
            [-87.8482, 42.1528],
            [-87.8873, 42.153],
            [-87.9099, 42.1531],
            [-87.9152, 42.1677],
            [-87.9156, 42.168],
            [-87.9225, 42.1717],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '19080',
        GEOID: '1719080',
        NAME: 'Township High School District 113',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64004700,
        AWATER: 352724,
        INTPTLAT: '+42.1787714',
        INTPTLON: '-087.8367387',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1446, 42.0574],
            [-88.1217, 42.0671],
            [-88.0767, 42.1539],
            [-88.034, 42.1537],
            [-88.0045, 42.1536],
            [-88.0047, 42.1317],
            [-88.0047, 42.0956],
            [-88.0282, 42.0626],
            [-88.0291, 42.0509],
            [-88.0238, 41.9925],
            [-88.0314, 41.9925],
            [-88.0699, 41.991],
            [-88.1009, 41.9899],
            [-88.1451, 41.9882],
            [-88.1446, 42.0574],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '30450',
        GEOID: '1730450',
        NAME: 'Township High School District 211',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160476863,
        AWATER: 1139747,
        INTPTLAT: '+42.0719749',
        INTPTLON: '-088.0712901',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5117, 41.4948],
            [-89.4795, 41.524],
            [-89.4519, 41.5241],
            [-89.4494, 41.5241],
            [-89.4327, 41.5193],
            [-89.4326, 41.5095],
            [-89.4266, 41.4949],
            [-89.3938, 41.4949],
            [-89.3163, 41.4808],
            [-89.2776, 41.4078],
            [-89.3102, 41.3931],
            [-89.3257, 41.3827],
            [-89.3157, 41.3827],
            [-89.3206, 41.3786],
            [-89.3257, 41.3785],
            [-89.3257, 41.3693],
            [-89.3346, 41.3493],
            [-89.3395, 41.3494],
            [-89.3939, 41.2337],
            [-89.4944, 41.1631],
            [-89.544, 41.234],
            [-89.6257, 41.2555],
            [-89.6257, 41.3068],
            [-89.5101, 41.3311],
            [-89.5117, 41.4948],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '32700',
        GEOID: '1732700',
        NAME: 'Princeton High School District 500',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 589110097,
        AWATER: 849932,
        INTPTLAT: '+41.3564987',
        INTPTLON: '-089.4554198',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8245, 41.9086],
            [-87.8057, 41.9089],
            [-87.7741, 41.8655],
            [-87.779, 41.8654],
            [-87.8042, 41.865],
            [-87.805, 41.887],
            [-87.8273, 41.8792],
            [-87.8245, 41.9086],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '29280',
        GEOID: '1729280',
        NAME: 'Oak Park and River Forest District 200',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18408259,
        AWATER: 4794,
        INTPTLAT: '+41.8899758',
        INTPTLON: '-087.7989388',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7928, 41.5585],
            [-87.7643, 41.5589],
            [-87.7524, 41.557],
            [-87.7136, 41.5571],
            [-87.7133, 41.5279],
            [-87.6745, 41.5243],
            [-87.6745, 41.5206],
            [-87.6743, 41.499],
            [-87.6693, 41.4843],
            [-87.6742, 41.477],
            [-87.6742, 41.4698],
            [-87.7031, 41.4698],
            [-87.7322, 41.4697],
            [-87.7873, 41.4698],
            [-87.7903, 41.4698],
            [-87.7903, 41.499],
            [-87.7928, 41.5585],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '33420',
        GEOID: '1733420',
        NAME: 'Rich Township High School District 227',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85915674,
        AWATER: 387516,
        INTPTLAT: '+41.5100091',
        INTPTLON: '-087.7346941',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.816, 40.9589],
            [-88.7831, 41.0328],
            [-88.6996, 41.0558],
            [-88.6622, 41.0481],
            [-88.6522, 41.0481],
            [-88.6245, 40.9757],
            [-88.6329, 40.9611],
            [-88.7009, 40.9643],
            [-88.7011, 40.9315],
            [-88.7121, 40.9314],
            [-88.7588, 40.9309],
            [-88.7733, 40.939],
            [-88.7733, 40.9307],
            [-88.7971, 40.9305],
            [-88.816, 40.9589],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '17901',
        GEOID: '1717901',
        NAME: 'Flanagan-Cornell District 74 in Cornell',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 161795241,
        AWATER: 338506,
        INTPTLAT: '+40.9935122',
        INTPTLON: '-088.7233485',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.817, 41.9707],
              [-87.8072, 41.9683],
              [-87.8072, 41.9673],
              [-87.817, 41.9707],
            ],
          ],
          [
            [
              [-87.8366, 41.9798],
              [-87.8235, 41.9831],
              [-87.8244, 41.976],
              [-87.8366, 41.9798],
            ],
          ],
          [
            [
              [-87.9214, 42.0662],
              [-87.9077, 42.0661],
              [-87.9007, 42.0661],
              [-87.8883, 42.066],
              [-87.8648, 42.0659],
              [-87.806, 42.0552],
              [-87.8064, 42.0336],
              [-87.8064, 42.0263],
              [-87.8065, 42.019],
              [-87.8559, 41.9891],
              [-87.8594, 41.9958],
              [-87.8801, 41.9952],
              [-87.9205, 42.0051],
              [-87.9212, 42.0488],
              [-87.9214, 42.0662],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '24090',
        GEOID: '1724090',
        NAME: 'Maine Township High School District 207',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72202919,
        AWATER: 640545,
        INTPTLAT: '+42.0294530',
        INTPTLON: '-087.8642087',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5164, 41.6244],
            [-89.3912, 41.5849],
            [-89.3734, 41.5385],
            [-89.3938, 41.4949],
            [-89.4266, 41.4949],
            [-89.4326, 41.5095],
            [-89.4327, 41.5193],
            [-89.4494, 41.5241],
            [-89.4519, 41.5241],
            [-89.4795, 41.524],
            [-89.5117, 41.4948],
            [-89.512, 41.5238],
            [-89.5164, 41.6244],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '29730',
        GEOID: '1729730',
        NAME: 'Ohio Community High School District 505',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130733715,
        AWATER: 88305,
        INTPTLAT: '+41.5594694',
        INTPTLON: '-089.4448495',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9817, 41.4326],
            [-88.9675, 41.4475],
            [-88.9339, 41.4399],
            [-88.8453, 41.4558],
            [-88.7759, 41.4139],
            [-88.7077, 41.4337],
            [-88.666, 41.4182],
            [-88.6862, 41.3458],
            [-88.6742, 41.3285],
            [-88.6949, 41.2998],
            [-88.7046, 41.2887],
            [-88.7032, 41.2141],
            [-88.8939, 41.2209],
            [-88.894, 41.2293],
            [-88.9423, 41.2151],
            [-89.0009, 41.253],
            [-88.9523, 41.3179],
            [-88.9911, 41.3257],
            [-88.9617, 41.3296],
            [-88.9817, 41.4326],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '30330',
        GEOID: '1730330',
        NAME: 'Ottawa Township High School District 140',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605063322,
        AWATER: 13259793,
        INTPTLAT: '+41.3313340',
        INTPTLON: '-088.8325200',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7971, 40.9305],
            [-88.7733, 40.9307],
            [-88.7588, 40.9309],
            [-88.7121, 40.9314],
            [-88.7011, 40.9315],
            [-88.6329, 40.9611],
            [-88.6245, 40.9757],
            [-88.6522, 41.0481],
            [-88.6431, 41.0481],
            [-88.6328, 41.0481],
            [-88.6239, 41.0481],
            [-88.619, 41.0482],
            [-88.6132, 41.0482],
            [-88.6124, 41.0482],
            [-88.6116, 41.0482],
            [-88.6047, 41.0483],
            [-88.5853, 41.0713],
            [-88.5468, 41.0847],
            [-88.5083, 41.0641],
            [-88.5007, 41.0565],
            [-88.4768, 41.0565],
            [-88.4758, 41.0565],
            [-88.456, 41.0209],
            [-88.4362, 41.0068],
            [-88.4352, 40.9778],
            [-88.4349, 40.9633],
            [-88.4313, 40.9633],
            [-88.3488, 40.9498],
            [-88.3548, 40.8232],
            [-88.4891, 40.8319],
            [-88.5246, 40.8348],
            [-88.5258, 40.8311],
            [-88.527, 40.8239],
            [-88.6225, 40.7862],
            [-88.6998, 40.8239],
            [-88.7672, 40.8229],
            [-88.7971, 40.9305],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '32220',
        GEOID: '1732220',
        NAME: 'Pontiac Township High School District 90',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 702832979,
        AWATER: 1079513,
        INTPTLAT: '+40.9124848',
        INTPTLON: '-088.5631348',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.7969, 41.7996],
              [-87.782, 41.8044],
              [-87.7531, 41.8134],
              [-87.782, 41.7997],
              [-87.7969, 41.7996],
            ],
          ],
          [
            [
              [-87.8008, 41.7737],
              [-87.7421, 41.7711],
              [-87.7411, 41.7345],
              [-87.7606, 41.7342],
              [-87.7991, 41.7335],
              [-87.8003, 41.7627],
              [-87.8007, 41.7705],
              [-87.8008, 41.7737],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '33270',
        GEOID: '1733270',
        NAME: 'Reavis Township High School District 220',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24447637,
        AWATER: 0,
        INTPTLAT: '+41.7597695',
        INTPTLON: '-087.7704005',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0322, 38.5328],
            [-89.0321, 38.5515],
            [-89.032, 38.5577],
            [-89.0318, 38.5651],
            [-89.031, 38.674],
            [-89.0311, 38.7068],
            [-88.9744, 38.7075],
            [-88.8268, 38.686],
            [-88.6991, 38.6933],
            [-88.6987, 38.5622],
            [-88.699, 38.4837],
            [-88.699, 38.4749],
            [-88.7186, 38.4751],
            [-88.7231, 38.4752],
            [-88.7325, 38.4754],
            [-88.7739, 38.4756],
            [-88.8002, 38.4757],
            [-88.8015, 38.4757],
            [-88.8106, 38.4741],
            [-88.8106, 38.4758],
            [-88.9039, 38.4767],
            [-88.9072, 38.4768],
            [-88.9224, 38.477],
            [-88.9485, 38.4763],
            [-88.9495, 38.5056],
            [-88.9411, 38.5058],
            [-89.0322, 38.5328],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '35190',
        GEOID: '1735190',
        NAME: 'Salem Community High School District 600',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 664956693,
        AWATER: 2850347,
        INTPTLAT: '+38.5968831',
        INTPTLON: '-088.8692552',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0042, 42.3606],
            [-88.0041, 42.3678],
            [-88.0041, 42.3931],
            [-88.004, 42.3966],
            [-88.004, 42.4079],
            [-88.0035, 42.448],
            [-87.9722, 42.4583],
            [-87.9363, 42.4435],
            [-87.8843, 42.423],
            [-87.8843, 42.4212],
            [-87.8845, 42.4139],
            [-87.8851, 42.3565],
            [-87.8855, 42.3417],
            [-87.8852, 42.3263],
            [-87.9443, 42.3273],
            [-88.0047, 42.3274],
            [-88.0042, 42.3606],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '40800',
        GEOID: '1740800',
        NAME: 'Warren Township High School District 121',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132270797,
        AWATER: 2666241,
        INTPTLAT: '+42.3897157',
        INTPTLON: '-087.9458874',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8124, 38.3222],
            [-88.8118, 38.3542],
            [-88.8118, 38.3735],
            [-88.8118, 38.3844],
            [-88.8116, 38.3881],
            [-88.8106, 38.4741],
            [-88.8015, 38.4757],
            [-88.8002, 38.4757],
            [-88.7739, 38.4756],
            [-88.7325, 38.4754],
            [-88.7231, 38.4752],
            [-88.7186, 38.4751],
            [-88.699, 38.4749],
            [-88.6998, 38.3865],
            [-88.702, 38.2788],
            [-88.7024, 38.2567],
            [-88.7218, 38.2276],
            [-88.7769, 38.2989],
            [-88.7819, 38.2989],
            [-88.7845, 38.2989],
            [-88.8075, 38.2992],
            [-88.8123, 38.3043],
            [-88.8124, 38.3222],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '41370',
        GEOID: '1741370',
        NAME: 'Webber Township High School District 204',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221088851,
        AWATER: 979185,
        INTPTLAT: '+38.3702056',
        INTPTLON: '-088.7531928',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.64, 38.5361],
            [-89.6306, 38.5723],
            [-89.6232, 38.6557],
            [-89.6085, 38.6704],
            [-89.5492, 38.7419],
            [-89.5488, 38.7566],
            [-89.4192, 38.7409],
            [-89.4276, 38.5611],
            [-89.4278, 38.4989],
            [-89.4974, 38.4733],
            [-89.5712, 38.4827],
            [-89.5933, 38.4755],
            [-89.64, 38.5361],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '04770',
        GEOID: '1704770',
        NAME: 'Central Community High School District 71',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528155624,
        AWATER: 1712477,
        INTPTLAT: '+38.6059576',
        INTPTLON: '-089.5269379',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1849, 41.6977],
            [-89.1902, 41.7435],
            [-89.1684, 41.7443],
            [-89.0986, 41.7522],
            [-89.0052, 41.6274],
            [-88.9359, 41.5102],
            [-88.9675, 41.4546],
            [-88.9675, 41.4475],
            [-88.9817, 41.4326],
            [-89.0248, 41.4553],
            [-89.0493, 41.4556],
            [-89.146, 41.4546],
            [-89.1555, 41.4544],
            [-89.166, 41.4524],
            [-89.1823, 41.5248],
            [-89.2565, 41.5852],
            [-89.167, 41.6146],
            [-89.1849, 41.6977],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '25650',
        GEOID: '1725650',
        NAME: 'Mendota Township High School District 280',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 524310604,
        AWATER: 505349,
        INTPTLAT: '+41.5786140',
        INTPTLON: '-089.0930993',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9204, 41.9068],
            [-87.8838, 41.9076],
            [-87.8704, 41.9078],
            [-87.8644, 41.9079],
            [-87.8255, 41.9085],
            [-87.8245, 41.9086],
            [-87.8273, 41.8792],
            [-87.805, 41.887],
            [-87.8042, 41.865],
            [-87.8038, 41.8504],
            [-87.8231, 41.85],
            [-87.8328, 41.8498],
            [-87.857, 41.842],
            [-87.8619, 41.8419],
            [-87.8653, 41.8417],
            [-87.8806, 41.8449],
            [-87.8818, 41.8374],
            [-87.886, 41.834],
            [-87.9006, 41.8336],
            [-87.9003, 41.8264],
            [-87.9186, 41.8264],
            [-87.9192, 41.8477],
            [-87.9205, 41.8769],
            [-87.9204, 41.9068],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '32910',
        GEOID: '1732910',
        NAME: 'Proviso Township High School District 209',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63716689,
        AWATER: 0,
        INTPTLAT: '+41.8724134',
        INTPTLON: '-087.8707980',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2331, 40.3408],
            [-88.2337, 40.3959],
            [-88.079, 40.371],
            [-88.0405, 40.3711],
            [-88.0018, 40.2543],
            [-88.0017, 40.25],
            [-88.0016, 40.2288],
            [-88.0016, 40.225],
            [-88.1358, 40.2245],
            [-88.1357, 40.2152],
            [-88.1445, 40.2151],
            [-88.1557, 40.215],
            [-88.1634, 40.2075],
            [-88.2019, 40.1983],
            [-88.2208, 40.2071],
            [-88.2698, 40.2237],
            [-88.2511, 40.253],
            [-88.2331, 40.3408],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '33240',
        GEOID: '1733240',
        NAME: 'Rantoul Township High School District 193',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389478265,
        AWATER: 362791,
        INTPTLAT: '+40.2981750',
        INTPTLON: '-088.1345723',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9514, 41.1709],
            [-88.9516, 41.1782],
            [-88.9321, 41.1854],
            [-88.9322, 41.1927],
            [-88.9296, 41.2072],
            [-88.9267, 41.2072],
            [-88.9423, 41.2151],
            [-88.894, 41.2293],
            [-88.8939, 41.2209],
            [-88.7032, 41.2141],
            [-88.703, 41.2013],
            [-88.7029, 41.1942],
            [-88.6783, 41.1945],
            [-88.6667, 41.1946],
            [-88.633, 41.1729],
            [-88.6324, 41.1079],
            [-88.6622, 41.0554],
            [-88.6622, 41.0481],
            [-88.6996, 41.0558],
            [-88.8065, 41.1066],
            [-88.9192, 41.1059],
            [-88.9595, 41.1127],
            [-88.9514, 41.1709],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '38100',
        GEOID: '1738100',
        NAME: 'Streator Township High School District 40',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315401320,
        AWATER: 95539,
        INTPTLAT: '+41.1575752',
        INTPTLON: '-088.7877848',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6016, 40.1224],
            [-89.5637, 40.2479],
            [-89.4861, 40.2012],
            [-89.369, 40.2215],
            [-89.3461, 40.2521],
            [-89.1373, 40.195],
            [-89.1456, 40.1064],
            [-89.2538, 40.0545],
            [-89.3671, 40.063],
            [-89.5229, 40.0637],
            [-89.5794, 40.0488],
            [-89.5873, 40.0488],
            [-89.6016, 40.1224],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '23050',
        GEOID: '1723050',
        NAME: 'Lincoln Community High School District 404',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 698643822,
        AWATER: 1970215,
        INTPTLAT: '+40.1403132',
        INTPTLON: '-089.3890882',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1852, 41.9542],
            [-89.1963, 42.0049],
            [-89.0664, 42.0578],
            [-88.9616, 42.0649],
            [-88.8879, 42.0149],
            [-88.9167, 41.9791],
            [-88.9413, 41.8918],
            [-88.9362, 41.8843],
            [-89.0461, 41.7895],
            [-89.1126, 41.7813],
            [-89.1218, 41.8683],
            [-89.2246, 41.9394],
            [-89.1852, 41.9542],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '34290',
        GEOID: '1734290',
        NAME: 'Rochelle Township High School District 212',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 563436814,
        AWATER: 642848,
        INTPTLAT: '+41.9436890',
        INTPTLON: '-089.0439671',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6797, 41.6482],
            [-87.6702, 41.6482],
            [-87.6696, 41.6482],
            [-87.6513, 41.6486],
            [-87.647, 41.657],
            [-87.6367, 41.6574],
            [-87.6172, 41.6446],
            [-87.5881, 41.6447],
            [-87.5593, 41.6445],
            [-87.5593, 41.6373],
            [-87.5591, 41.6154],
            [-87.5591, 41.6081],
            [-87.5587, 41.5945],
            [-87.5589, 41.5779],
            [-87.5782, 41.5765],
            [-87.5782, 41.5714],
            [-87.5781, 41.5575],
            [-87.6263, 41.5574],
            [-87.6266, 41.5605],
            [-87.6363, 41.5719],
            [-87.675, 41.5717],
            [-87.6751, 41.5863],
            [-87.6751, 41.5936],
            [-87.6752, 41.6044],
            [-87.6753, 41.6082],
            [-87.6795, 41.6409],
            [-87.6797, 41.6482],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '38970',
        GEOID: '1738970',
        NAME: 'Thornton Township High School District 205',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88241376,
        AWATER: 1281601,
        INTPTLAT: '+41.6083740',
        INTPTLON: '-087.6225568',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.029, 42.2116],
            [-88.0628, 42.2408],
            [-88.0242, 42.2407],
            [-88.019, 42.2404],
            [-87.9842, 42.2406],
            [-87.9846, 42.2248],
            [-87.9844, 42.2221],
            [-87.9795, 42.2212],
            [-87.9796, 42.2189],
            [-87.9649, 42.2118],
            [-87.9653, 42.2233],
            [-87.964, 42.2266],
            [-87.9551, 42.2263],
            [-87.9507, 42.2264],
            [-87.9106, 42.2401],
            [-87.8995, 42.2401],
            [-87.9009, 42.238],
            [-87.9016, 42.2301],
            [-87.8869, 42.2038],
            [-87.8942, 42.1892],
            [-87.8942, 42.1857],
            [-87.8949, 42.1826],
            [-87.9074, 42.1747],
            [-87.922, 42.172],
            [-87.9225, 42.1717],
            [-87.9156, 42.168],
            [-87.9152, 42.1677],
            [-87.9099, 42.1531],
            [-87.9654, 42.1535],
            [-88.0045, 42.1536],
            [-88.034, 42.1537],
            [-88.029, 42.2116],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '32580',
        GEOID: '1732580',
        NAME: 'Adlai E. Stevenson High School District 125',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105001719,
        AWATER: 2140181,
        INTPTLAT: '+42.1907882',
        INTPTLON: '-087.9810531',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0405, 40.3711],
            [-87.9713, 40.3991],
            [-87.9329, 40.3994],
            [-87.8598, 40.3715],
            [-87.8338, 40.2558],
            [-87.7361, 40.2037],
            [-87.9227, 40.1855],
            [-87.9418, 40.2035],
            [-87.9421, 40.2255],
            [-87.9478, 40.2254],
            [-88.0016, 40.225],
            [-88.0016, 40.2288],
            [-88.0017, 40.25],
            [-88.0018, 40.2543],
            [-88.0405, 40.3711],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '04230',
        GEOID: '1704230',
        NAME: 'Armstrong Township High School District 225',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347154112,
        AWATER: 416186,
        INTPTLAT: '+40.2823717',
        INTPTLON: '-087.9020276',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7588, 40.9309],
            [-88.7733, 40.9307],
            [-88.7733, 40.939],
            [-88.7588, 40.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '17903',
        GEOID: '1717903',
        NAME: 'Flanagan-Cornell District 74 in Rooks Creek',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 762687,
        AWATER: 0,
        INTPTLAT: '+40.9341111',
        INTPTLON: '-088.7675833',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8406, 41.8205],
            [-87.8026, 41.8212],
            [-87.8038, 41.8504],
            [-87.8042, 41.865],
            [-87.779, 41.8654],
            [-87.7741, 41.8655],
            [-87.7449, 41.822],
            [-87.7531, 41.8134],
            [-87.782, 41.8044],
            [-87.7969, 41.7996],
            [-87.8021, 41.7978],
            [-87.802, 41.8064],
            [-87.8176, 41.7985],
            [-87.8406, 41.8205],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '26880',
        GEOID: '1726880',
        NAME: 'J. Sterling Morton High School District 201',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39787852,
        AWATER: 558346,
        INTPTLAT: '+41.8320708',
        INTPTLON: '-087.7814893',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4812, 38.3189],
            [-89.3889, 38.4943],
            [-89.3206, 38.5106],
            [-89.2549, 38.509],
            [-89.2342, 38.3898],
            [-89.2303, 38.3897],
            [-89.23, 38.3897],
            [-89.2305, 38.3755],
            [-89.221, 38.3753],
            [-89.2118, 38.3676],
            [-89.2119, 38.3604],
            [-89.1454, 38.3582],
            [-89.1273, 38.3004],
            [-89.1186, 38.3004],
            [-89.1164, 38.3004],
            [-89.0956, 38.3002],
            [-89.082, 38.2718],
            [-89.096, 38.272],
            [-89.1474, 38.2129],
            [-89.1623, 38.213],
            [-89.1841, 38.2134],
            [-89.1892, 38.2135],
            [-89.1929, 38.2135],
            [-89.259, 38.2139],
            [-89.3223, 38.2137],
            [-89.3422, 38.2138],
            [-89.3892, 38.2146],
            [-89.4262, 38.2152],
            [-89.4346, 38.2152],
            [-89.4394, 38.2153],
            [-89.5016, 38.2172],
            [-89.507, 38.2173],
            [-89.5928, 38.2193],
            [-89.552, 38.2993],
            [-89.4812, 38.3189],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '27740',
        GEOID: '1727740',
        NAME: 'Nashville Community High School District 99',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 837268887,
        AWATER: 2335524,
        INTPTLAT: '+38.3234419',
        INTPTLON: '-089.3236894',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7804, 42.1235],
            [-87.7895, 42.1525],
            [-87.759, 42.1524],
            [-87.7411, 42.1275],
            [-87.7108, 42.0954],
            [-87.7028, 42.0897],
            [-87.6796, 42.0717],
            [-87.7324, 42.0647],
            [-87.766, 42.0639],
            [-87.7715, 42.0651],
            [-87.7714, 42.0725],
            [-87.7707, 42.0941],
            [-87.799, 42.1054],
            [-87.8043, 42.1135],
            [-87.7804, 42.1235],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '28200',
        GEOID: '1728200',
        NAME: 'New Trier Township High School District 203',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49262634,
        AWATER: 1027033,
        INTPTLAT: '+42.1017872',
        INTPTLON: '-087.7501283',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7991, 41.7335],
            [-87.7606, 41.7342],
            [-87.7411, 41.7345],
            [-87.7217, 41.7278],
            [-87.7211, 41.7132],
            [-87.76, 41.7124],
            [-87.76, 41.7125],
            [-87.7984, 41.7119],
            [-87.7991, 41.7335],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '29220',
        GEOID: '1729220',
        NAME: 'Oak Lawn Community High School District 229',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15481020,
        AWATER: 0,
        INTPTLAT: '+41.7233592',
        INTPTLON: '-087.7600471',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1454, 38.3582],
            [-89.1454, 38.3584],
            [-89.1455, 38.3874],
            [-89.1449, 38.445],
            [-89.0337, 38.4453],
            [-89.0337, 38.4472],
            [-88.9799, 38.4472],
            [-88.9992, 38.3873],
            [-88.9798, 38.3],
            [-88.9897, 38.3],
            [-89.0042, 38.2724],
            [-89.082, 38.2718],
            [-89.0956, 38.3002],
            [-89.1164, 38.3004],
            [-89.1186, 38.3004],
            [-89.1273, 38.3004],
            [-89.1454, 38.3582],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '43200',
        GEOID: '1743200',
        NAME: 'Woodlawn Community High School District 205',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260552032,
        AWATER: 434007,
        INTPTLAT: '+38.3599490',
        INTPTLON: '-089.0575056',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4731, 38.4071],
            [-88.4722, 38.4287],
            [-88.3384, 38.4681],
            [-88.2221, 38.4011],
            [-88.1659, 38.4447],
            [-88.2127, 38.314],
            [-88.1691, 38.2706],
            [-88.192, 38.2566],
            [-88.2622, 38.1918],
            [-88.3708, 38.2256],
            [-88.4259, 38.2558],
            [-88.4626, 38.2965],
            [-88.4731, 38.4071],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '26180',
        GEOID: '1726180',
        NAME: 'Fairfield Community High School District 225',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 570171614,
        AWATER: 1990398,
        INTPTLAT: '+38.3505563',
        INTPTLON: '-088.3329368',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7565, 40.7109],
            [-89.7175, 40.7323],
            [-89.6893, 40.7302],
            [-89.6893, 40.7399],
            [-89.642, 40.6866],
            [-89.6422, 40.6556],
            [-89.6428, 40.6234],
            [-89.6364, 40.6232],
            [-89.6588, 40.5892],
            [-89.6824, 40.5526],
            [-89.6888, 40.5585],
            [-89.6921, 40.5974],
            [-89.7566, 40.653],
            [-89.7565, 40.7109],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '22950',
        GEOID: '1722950',
        NAME: 'Limestone Community High School District 310',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128767645,
        AWATER: 2157054,
        INTPTLAT: '+40.6569862',
        INTPTLON: '-089.6840634',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.888, 42.0873],
            [-87.8881, 42.0948],
            [-87.8878, 42.1237],
            [-87.8873, 42.153],
            [-87.8482, 42.1528],
            [-87.8284, 42.1527],
            [-87.7895, 42.1525],
            [-87.7804, 42.1235],
            [-87.8043, 42.1135],
            [-87.799, 42.1054],
            [-87.7707, 42.0941],
            [-87.7714, 42.0725],
            [-87.7715, 42.0651],
            [-87.806, 42.0552],
            [-87.8648, 42.0659],
            [-87.8883, 42.066],
            [-87.888, 42.0873],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '29010',
        GEOID: '1729010',
        NAME: 'Northfield Township High School District 225',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84407359,
        AWATER: 749648,
        INTPTLAT: '+42.1058914',
        INTPTLON: '-087.8345603',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8416, 41.9701],
            [-87.8367, 41.9713],
            [-87.8334, 41.9739],
            [-87.8267, 41.9731],
            [-87.8185, 41.9706],
            [-87.817, 41.9707],
            [-87.8072, 41.9673],
            [-87.8072, 41.9683],
            [-87.8073, 41.9539],
            [-87.8466, 41.9691],
            [-87.8416, 41.9701],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '33720',
        GEOID: '1733720',
        NAME: 'Ridgewood Community High School District 234',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6948668,
        AWATER: 0,
        INTPTLAT: '+41.9627382',
        INTPTLON: '-087.8166774',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7632, 41.7576],
            [-89.6776, 41.7889],
            [-89.6028, 41.8145],
            [-89.5738, 41.7441],
            [-89.6031, 41.7296],
            [-89.5914, 41.7006],
            [-89.6321, 41.6568],
            [-89.7092, 41.657],
            [-89.8144, 41.7181],
            [-89.7632, 41.7576],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '34380',
        GEOID: '1734380',
        NAME: 'Rock Falls Township High School District 301',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208650930,
        AWATER: 6218658,
        INTPTLAT: '+41.7263082',
        INTPTLON: '-089.6803904',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2662, 37.7747],
            [-89.2653, 37.8202],
            [-89.1799, 37.8481],
            [-89.1514, 37.8099],
            [-89.1515, 37.8037],
            [-89.1524, 37.7125],
            [-89.0783, 37.6861],
            [-89.0782, 37.5974],
            [-89.1537, 37.6005],
            [-89.1946, 37.5997],
            [-89.2778, 37.6285],
            [-89.282, 37.6984],
            [-89.2662, 37.7747],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '08370',
        GEOID: '1708370',
        NAME: 'Carbondale Community High School District 165',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336278474,
        AWATER: 14604461,
        INTPTLAT: '+37.7079289',
        INTPTLON: '-089.2020600',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9716, 42.4948],
            [-87.9498, 42.4946],
            [-87.8529, 42.4932],
            [-87.8032, 42.4926],
            [-87.7979, 42.4641],
            [-87.8035, 42.4355],
            [-87.8033, 42.4138],
            [-87.8845, 42.4139],
            [-87.8843, 42.4212],
            [-87.8843, 42.423],
            [-87.9363, 42.4435],
            [-87.9722, 42.4583],
            [-87.9716, 42.4948],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '43890',
        GEOID: '1743890',
        NAME: 'Zion-Benton Township High School District 126',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94473450,
        AWATER: 1187648,
        INTPTLAT: '+42.4611073',
        INTPTLON: '-087.8726236',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9009, 42.238],
            [-87.8995, 42.2401],
            [-87.8961, 42.2401],
            [-87.8923, 42.2401],
            [-87.886, 42.2429],
            [-87.8892, 42.2543],
            [-87.8859, 42.2473],
            [-87.8855, 42.2798],
            [-87.89, 42.2834],
            [-87.8852, 42.2981],
            [-87.8333, 42.3049],
            [-87.8281, 42.2689],
            [-87.8089, 42.2247],
            [-87.847, 42.2036],
            [-87.8869, 42.2038],
            [-87.9016, 42.2301],
            [-87.9009, 42.238],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '21780',
        GEOID: '1721780',
        NAME: 'Lake Forest Community High School District 115',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55462280,
        AWATER: 177376,
        INTPTLAT: '+42.2526073',
        INTPTLON: '-087.8589232',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0047, 42.3274],
            [-87.9443, 42.3273],
            [-87.8852, 42.3263],
            [-87.8852, 42.2981],
            [-87.89, 42.2834],
            [-87.8855, 42.2798],
            [-87.8859, 42.2473],
            [-87.8892, 42.2543],
            [-87.886, 42.2429],
            [-87.8923, 42.2401],
            [-87.8961, 42.2401],
            [-87.8995, 42.2401],
            [-87.9106, 42.2401],
            [-87.9507, 42.2264],
            [-87.9551, 42.2263],
            [-87.964, 42.2266],
            [-87.9653, 42.2233],
            [-87.9649, 42.2118],
            [-87.9796, 42.2189],
            [-87.9795, 42.2212],
            [-87.9838, 42.2695],
            [-87.9837, 42.2733],
            [-87.9839, 42.284],
            [-88.0042, 42.2977],
            [-88.0045, 42.3136],
            [-88.0045, 42.3165],
            [-88.0046, 42.3206],
            [-88.0047, 42.3274],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '22830',
        GEOID: '1722830',
        NAME: 'Libertyville Community High School District 128',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89158382,
        AWATER: 2313022,
        INTPTLAT: '+42.2800078',
        INTPTLON: '-087.9399324',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1208, 42.3295],
            [-88.0824, 42.3136],
            [-88.0045, 42.3136],
            [-88.0042, 42.2977],
            [-87.9839, 42.284],
            [-87.9837, 42.2733],
            [-87.9838, 42.2695],
            [-87.9795, 42.2212],
            [-87.9844, 42.2221],
            [-87.9846, 42.2248],
            [-87.9842, 42.2406],
            [-88.019, 42.2404],
            [-88.0242, 42.2407],
            [-88.0628, 42.2408],
            [-88.1203, 42.2434],
            [-88.1208, 42.3295],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '27570',
        GEOID: '1727570',
        NAME: 'Mundelein Consolidated High School District 120',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88441042,
        AWATER: 4466883,
        INTPTLAT: '+42.2805082',
        INTPTLON: '-088.0538296',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8065, 42.019],
            [-87.8064, 42.0263],
            [-87.8064, 42.0336],
            [-87.806, 42.0552],
            [-87.7715, 42.0651],
            [-87.766, 42.0639],
            [-87.7324, 42.0647],
            [-87.7279, 42.0371],
            [-87.7089, 42.0226],
            [-87.7087, 42.0191],
            [-87.7091, 42.0119],
            [-87.7618, 42.0047],
            [-87.7769, 42.0155],
            [-87.8065, 42.019],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '28530',
        GEOID: '1728530',
        NAME: 'Niles Township Community High School District 219',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49732916,
        AWATER: 0,
        INTPTLAT: '+42.0298874',
        INTPTLON: '-087.7585770',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8653, 41.8417],
            [-87.8619, 41.8419],
            [-87.857, 41.842],
            [-87.8328, 41.8498],
            [-87.8231, 41.85],
            [-87.8038, 41.8504],
            [-87.8026, 41.8212],
            [-87.8406, 41.8205],
            [-87.8415, 41.8205],
            [-87.8503, 41.8203],
            [-87.8614, 41.831],
            [-87.8653, 41.8417],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '34020',
        GEOID: '1734020',
        NAME: 'Riverside Brookfield Township School District 208',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16040750,
        AWATER: 89901,
        INTPTLAT: '+41.8350092',
        INTPTLON: '-087.8334190',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0333, 41.951],
            [-87.9984, 41.9564],
            [-87.9789, 41.9494],
            [-87.9691, 41.9349],
            [-87.9688, 41.9056],
            [-87.9594, 41.8756],
            [-87.9394, 41.8546],
            [-87.9768, 41.8461],
            [-88.0018, 41.8384],
            [-88.008, 41.9048],
            [-88.0326, 41.9042],
            [-88.0333, 41.951],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '13940',
        GEOID: '1713940',
        NAME: 'DuPage High School District 88',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55686784,
        AWATER: 1017002,
        INTPTLAT: '+41.9021667',
        INTPTLON: '-087.9903629',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0446, 37.406],
            [-89.0446, 37.4196],
            [-89.0443, 37.4642],
            [-89.0441, 37.475],
            [-89.044, 37.4786],
            [-89.043, 37.5077],
            [-88.9288, 37.4903],
            [-88.8849, 37.5193],
            [-88.875, 37.5857],
            [-88.7179, 37.6145],
            [-88.7085, 37.5993],
            [-88.7105, 37.4089],
            [-88.7107, 37.3371],
            [-88.8384, 37.3357],
            [-88.8877, 37.3356],
            [-88.9291, 37.3026],
            [-89.0628, 37.3308],
            [-89.0446, 37.406],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '40290',
        GEOID: '1740290',
        NAME: 'Vienna High School District 133',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 752812924,
        AWATER: 7253709,
        INTPTLAT: '+37.4438151',
        INTPTLON: '-088.8658483',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0515, 41.7793],
            [-88.0527, 41.8155],
            [-88.0435, 41.8318],
            [-88.0018, 41.8384],
            [-87.9768, 41.8461],
            [-87.9863, 41.822],
            [-87.9852, 41.8203],
            [-87.9859, 41.8192],
            [-87.9848, 41.788],
            [-87.9751, 41.7882],
            [-87.9743, 41.7664],
            [-87.98, 41.7443],
            [-87.9837, 41.7443],
            [-87.9977, 41.7219],
            [-87.9977, 41.7216],
            [-88.0303, 41.7221],
            [-88.0306, 41.7289],
            [-88.069, 41.7281],
            [-88.09, 41.7827],
            [-88.0515, 41.7793],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '12570',
        GEOID: '1712570',
        NAME: 'Community High School District 99',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79926841,
        AWATER: 1465582,
        INTPTLAT: '+41.7761556',
        INTPTLON: '-088.0217838',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4614, 41.5235],
            [-90.4251, 41.5526],
            [-90.3561, 41.5828],
            [-90.3569, 41.549],
            [-90.3179, 41.5344],
            [-90.3179, 41.5088],
            [-90.367, 41.4995],
            [-90.3523, 41.4641],
            [-90.3767, 41.4787],
            [-90.3913, 41.4734],
            [-90.4319, 41.4568],
            [-90.4614, 41.5235],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '39870',
        GEOID: '1739870',
        NAME: 'United Township High School District 30',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99015643,
        AWATER: 7861351,
        INTPTLAT: '+41.5161010',
        INTPTLON: '-090.3907663',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6324, 41.1079],
            [-88.633, 41.1729],
            [-88.6157, 41.1952],
            [-88.6062, 41.1953],
            [-88.5867, 41.181],
            [-88.5864, 41.1373],
            [-88.3993, 41.1689],
            [-88.3968, 41.1634],
            [-88.3967, 41.1602],
            [-88.3966, 41.1595],
            [-88.3573, 41.1409],
            [-88.3381, 41.1414],
            [-88.3236, 41.1417],
            [-88.319, 41.1418],
            [-88.3182, 41.1273],
            [-88.3192, 40.9937],
            [-88.3588, 40.9787],
            [-88.3488, 40.9498],
            [-88.4313, 40.9633],
            [-88.4349, 40.9633],
            [-88.4352, 40.9778],
            [-88.4362, 41.0068],
            [-88.456, 41.0209],
            [-88.4758, 41.0565],
            [-88.4768, 41.0565],
            [-88.5007, 41.0565],
            [-88.5083, 41.0641],
            [-88.5468, 41.0847],
            [-88.5853, 41.0713],
            [-88.6047, 41.0483],
            [-88.6116, 41.0482],
            [-88.6124, 41.0482],
            [-88.6132, 41.0482],
            [-88.619, 41.0482],
            [-88.6239, 41.0481],
            [-88.6328, 41.0481],
            [-88.6431, 41.0481],
            [-88.6522, 41.0481],
            [-88.6622, 41.0481],
            [-88.6622, 41.0554],
            [-88.6324, 41.1079],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '12870',
        GEOID: '1712870',
        NAME: 'Dwight Township High School District 230',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412243832,
        AWATER: 221068,
        INTPTLAT: '+41.0729988',
        INTPTLON: '-088.4631538',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.624, 41.5531],
            [-88.558, 41.617],
            [-88.5434, 41.6226],
            [-88.4637, 41.531],
            [-88.3755, 41.5173],
            [-88.3848, 41.4747],
            [-88.3847, 41.4675],
            [-88.4136, 41.4596],
            [-88.429, 41.4626],
            [-88.4335, 41.4664],
            [-88.4473, 41.4588],
            [-88.4817, 41.4579],
            [-88.5105, 41.4457],
            [-88.5179, 41.4433],
            [-88.5685, 41.4209],
            [-88.5952, 41.4205],
            [-88.5956, 41.4404],
            [-88.615, 41.4402],
            [-88.5965, 41.4751],
            [-88.5972, 41.4932],
            [-88.624, 41.5531],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '28260',
        GEOID: '1728260',
        NAME: 'Newark Community High School District 18',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257864842,
        AWATER: 370498,
        INTPTLAT: '+41.5122017',
        INTPTLON: '-088.5179116',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7046, 41.2887],
            [-88.6949, 41.2998],
            [-88.6742, 41.3285],
            [-88.6862, 41.3458],
            [-88.666, 41.4182],
            [-88.615, 41.4402],
            [-88.5956, 41.4404],
            [-88.5952, 41.4205],
            [-88.5685, 41.4209],
            [-88.5557, 41.3373],
            [-88.5167, 41.3307],
            [-88.5546, 41.3189],
            [-88.3817, 41.2998],
            [-88.3611, 41.2427],
            [-88.3979, 41.1925],
            [-88.3979, 41.1922],
            [-88.397, 41.1687],
            [-88.3993, 41.1689],
            [-88.5864, 41.1373],
            [-88.5867, 41.181],
            [-88.6062, 41.1953],
            [-88.6157, 41.1952],
            [-88.633, 41.1729],
            [-88.6667, 41.1946],
            [-88.6783, 41.1945],
            [-88.7029, 41.1942],
            [-88.703, 41.2013],
            [-88.7032, 41.2141],
            [-88.7046, 41.2887],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '35850',
        GEOID: '1735850',
        NAME: 'Seneca Township High School District 160',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 563131969,
        AWATER: 12017434,
        INTPTLAT: '+41.2592008',
        INTPTLON: '-088.5525680',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9972, 41.9927],
            [-87.9592, 41.9932],
            [-87.9399, 41.9935],
            [-87.92, 41.9582],
            [-87.9203, 41.9358],
            [-87.9691, 41.9349],
            [-87.9789, 41.9494],
            [-87.9984, 41.9564],
            [-87.9972, 41.9927],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '15030',
        GEOID: '1715030',
        NAME: 'Fenton Community High School District 100',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31039325,
        AWATER: 456302,
        INTPTLAT: '+41.9658652',
        INTPTLON: '-087.9616282',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1449, 41.9665],
            [-88.1091, 41.9609],
            [-88.09, 41.9444],
            [-88.0417, 41.9532],
            [-88.0333, 41.951],
            [-88.0326, 41.9042],
            [-88.008, 41.9048],
            [-88.0018, 41.8384],
            [-88.0435, 41.8318],
            [-88.0527, 41.8155],
            [-88.0875, 41.815],
            [-88.079, 41.8592],
            [-88.093, 41.9023],
            [-88.1018, 41.902],
            [-88.1454, 41.9011],
            [-88.1649, 41.9259],
            [-88.1449, 41.9665],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '16830',
        GEOID: '1716830',
        NAME: 'Glenbard Township High School District 87',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114558787,
        AWATER: 3669859,
        INTPTLAT: '+41.8936718',
        INTPTLON: '-088.0728660',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8649, 40.6788],
            [-87.7712, 40.6993],
            [-87.6492, 40.6873],
            [-87.6531, 40.793],
            [-87.5257, 40.8173],
            [-87.5262, 40.6498],
            [-87.5263, 40.5673],
            [-87.5487, 40.5343],
            [-87.7968, 40.5454],
            [-87.8649, 40.6788],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '25980',
        GEOID: '1725980',
        NAME: 'Milford Township High School District 233',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 580182511,
        AWATER: 54555,
        INTPTLAT: '+40.6606650',
        INTPTLON: '-087.6497853',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5179, 41.4433],
            [-88.5105, 41.4457],
            [-88.4817, 41.4579],
            [-88.4473, 41.4588],
            [-88.4335, 41.4664],
            [-88.429, 41.4626],
            [-88.4136, 41.4596],
            [-88.3472, 41.4385],
            [-88.3469, 41.4168],
            [-88.3416, 41.4015],
            [-88.3166, 41.4002],
            [-88.3089, 41.3925],
            [-88.3181, 41.3886],
            [-88.3258, 41.3847],
            [-88.3817, 41.2998],
            [-88.5546, 41.3189],
            [-88.5167, 41.3307],
            [-88.5557, 41.3373],
            [-88.5685, 41.4209],
            [-88.5179, 41.4433],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '26640',
        GEOID: '1726640',
        NAME: 'Morris Community High School District 101',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271784363,
        AWATER: 13465220,
        INTPTLAT: '+41.3777600',
        INTPTLON: '-088.4360457',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3411, 42.4143],
            [-88.3281, 42.4146],
            [-88.3062, 42.3729],
            [-88.1988, 42.3983],
            [-88.1988, 42.3872],
            [-88.1988, 42.3799],
            [-88.1988, 42.3724],
            [-88.1985, 42.358],
            [-88.1986, 42.337],
            [-88.1986, 42.3289],
            [-88.2268, 42.2797],
            [-88.2771, 42.2988],
            [-88.3552, 42.2986],
            [-88.3555, 42.3715],
            [-88.3411, 42.4143],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '25320',
        GEOID: '1725320',
        NAME: 'McHenry Community High School District 156',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112257166,
        AWATER: 7918327,
        INTPTLAT: '+42.3376521',
        INTPTLON: '-088.2795920',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5615, 40.7956],
            [-89.5605, 40.7975],
            [-89.5033, 40.8713],
            [-89.4975, 40.8787],
            [-89.3779, 40.8355],
            [-89.2741, 40.8367],
            [-89.2735, 40.778],
            [-89.3402, 40.7482],
            [-89.4558, 40.7476],
            [-89.4693, 40.7477],
            [-89.5032, 40.7476],
            [-89.5174, 40.7476],
            [-89.555, 40.7476],
            [-89.5615, 40.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '25770',
        GEOID: '1725770',
        NAME: 'Metamora Township High School District 122',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249902756,
        AWATER: 25152561,
        INTPTLAT: '+40.8140116',
        INTPTLON: '-089.4199248',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8438, 41.0482],
            [-87.7332, 41.0641],
            [-87.7393, 41.12],
            [-87.6569, 41.0949],
            [-87.5266, 41.1215],
            [-87.5263, 41.0027],
            [-87.5612, 40.9807],
            [-87.5993, 40.9798],
            [-87.6186, 40.9864],
            [-87.8117, 40.9398],
            [-87.8438, 41.0482],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '37140',
        GEOID: '1737140',
        NAME: 'St. Anne Community High School District 302',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346671908,
        AWATER: 1059283,
        INTPTLAT: '+41.0314845',
        INTPTLON: '-087.6727770',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2627, 42.4982],
            [-89.1299, 42.4971],
            [-89.1206, 42.497],
            [-89.0795, 42.4963],
            [-89.0395, 42.4627],
            [-89.0196, 42.463],
            [-89.0116, 42.4961],
            [-89.0013, 42.496],
            [-88.9519, 42.4953],
            [-88.9407, 42.4454],
            [-88.9407, 42.4192],
            [-88.9408, 42.4027],
            [-88.9825, 42.403],
            [-89.0394, 42.3912],
            [-89.0547, 42.3817],
            [-89.1743, 42.3676],
            [-89.2329, 42.3861],
            [-89.1976, 42.4358],
            [-89.2627, 42.4982],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '19620',
        GEOID: '1719620',
        NAME: 'Hononegah Community High School District 207',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284487888,
        AWATER: 4249415,
        INTPTLAT: '+42.4362751',
        INTPTLON: '-089.1013015',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1451, 41.9882],
            [-88.1009, 41.9899],
            [-88.0699, 41.991],
            [-88.0314, 41.9925],
            [-88.0238, 41.9925],
            [-87.9972, 41.9927],
            [-87.9984, 41.9564],
            [-88.0333, 41.951],
            [-88.0417, 41.9532],
            [-88.09, 41.9444],
            [-88.1091, 41.9609],
            [-88.1449, 41.9665],
            [-88.1451, 41.9882],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '21840',
        GEOID: '1721840',
        NAME: 'Lake Park Community High School District 108',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49531530,
        AWATER: 1649095,
        INTPTLAT: '+41.9709738',
        INTPTLON: '-088.0673596',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3281, 37.4947],
            [-89.2753, 37.4955],
            [-89.2293, 37.507],
            [-89.2265, 37.5035],
            [-89.1537, 37.6005],
            [-89.0782, 37.5974],
            [-89.051, 37.5968],
            [-89.043, 37.5077],
            [-89.044, 37.4786],
            [-89.0441, 37.475],
            [-89.0443, 37.4642],
            [-89.0446, 37.4196],
            [-89.0446, 37.406],
            [-89.156, 37.4376],
            [-89.2189, 37.3898],
            [-89.2484, 37.3351],
            [-89.3208, 37.3347],
            [-89.3281, 37.4947],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '03780',
        GEOID: '1703780',
        NAME: 'Anna Jonesboro Community High School District 81',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405630194,
        AWATER: 2799932,
        INTPTLAT: '+37.4712354',
        INTPTLON: '-089.1913985',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.593, 38.0731],
            [-89.5924, 38.1394],
            [-89.5928, 38.2193],
            [-89.507, 38.2173],
            [-89.5016, 38.2172],
            [-89.4394, 38.2153],
            [-89.4346, 38.2152],
            [-89.4262, 38.2152],
            [-89.3892, 38.2146],
            [-89.3422, 38.2138],
            [-89.3223, 38.2137],
            [-89.259, 38.2139],
            [-89.1929, 38.2135],
            [-89.1892, 38.2135],
            [-89.1841, 38.2134],
            [-89.1623, 38.213],
            [-89.1474, 38.2129],
            [-89.1296, 38.1247],
            [-89.1316, 38.1169],
            [-89.1862, 38.1256],
            [-89.2526, 38.0832],
            [-89.3447, 38.0391],
            [-89.3546, 37.9519],
            [-89.4277, 37.9532],
            [-89.4826, 38.0415],
            [-89.593, 38.0731],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '31620',
        GEOID: '1731620',
        NAME: 'Pinckneyville Community High School District 101',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 749372714,
        AWATER: 5500521,
        INTPTLAT: '+38.1170831',
        INTPTLON: '-089.3810703',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.355, 42.4946],
            [-88.3047, 42.4946],
            [-88.28, 42.4948],
            [-88.2654, 42.4947],
            [-88.1995, 42.4951],
            [-88.199, 42.4591],
            [-88.1989, 42.4447],
            [-88.1988, 42.4156],
            [-88.3281, 42.4146],
            [-88.3411, 42.4143],
            [-88.3547, 42.4147],
            [-88.355, 42.4946],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '33510',
        GEOID: '1733510',
        NAME: 'Richmond-Burton Community High School District 157',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112936905,
        AWATER: 669707,
        INTPTLAT: '+42.4546502',
        INTPTLON: '-088.2767482',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2549, 38.509],
            [-89.2456, 38.5081],
            [-89.179, 38.5497],
            [-89.1422, 38.5569],
            [-89.0871, 38.5798],
            [-89.0318, 38.5651],
            [-89.032, 38.5577],
            [-89.0321, 38.5515],
            [-89.0322, 38.5328],
            [-88.9411, 38.5058],
            [-88.9495, 38.5056],
            [-88.9485, 38.4763],
            [-88.9653, 38.4759],
            [-88.9793, 38.4755],
            [-88.9799, 38.4472],
            [-89.0337, 38.4472],
            [-89.0337, 38.4453],
            [-89.1449, 38.445],
            [-89.1455, 38.3874],
            [-89.1454, 38.3584],
            [-89.1454, 38.3582],
            [-89.2119, 38.3604],
            [-89.2118, 38.3676],
            [-89.221, 38.3753],
            [-89.2305, 38.3755],
            [-89.23, 38.3897],
            [-89.2303, 38.3897],
            [-89.2342, 38.3898],
            [-89.2549, 38.509],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '09300',
        GEOID: '1709300',
        NAME: 'Centralia High School District 200',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343614834,
        AWATER: 3326064,
        INTPTLAT: '+38.4765831',
        INTPTLON: '-089.1266608',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.146, 38.1047],
            [-90.0807, 38.1231],
            [-90.0404, 38.0002],
            [-90.2067, 38.088],
            [-90.146, 38.1047],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '09780',
        GEOID: '1709780',
        NAME: 'Chester Non-High School District 122',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89456160,
        AWATER: 6847720,
        INTPTLAT: '+38.0723787',
        INTPTLON: '-090.0975553',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7055, 42.241],
            [-88.7056, 42.3279],
            [-88.5794, 42.3276],
            [-88.4718, 42.2414],
            [-88.521, 42.1832],
            [-88.5104, 42.1539],
            [-88.5887, 42.1536],
            [-88.7056, 42.1536],
            [-88.7055, 42.241],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '24570',
        GEOID: '1724570',
        NAME: 'Marengo Community High School District 154',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295984593,
        AWATER: 349429,
        INTPTLAT: '+42.2290672',
        INTPTLON: '-088.6141840',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9897, 38.3],
            [-88.9798, 38.3],
            [-88.9992, 38.3873],
            [-88.9799, 38.4472],
            [-88.9793, 38.4755],
            [-88.9653, 38.4759],
            [-88.9485, 38.4763],
            [-88.9224, 38.477],
            [-88.9072, 38.4768],
            [-88.9039, 38.4767],
            [-88.8106, 38.4758],
            [-88.8106, 38.4741],
            [-88.8116, 38.3881],
            [-88.8118, 38.3844],
            [-88.8118, 38.3735],
            [-88.8118, 38.3542],
            [-88.8124, 38.3222],
            [-88.8123, 38.3043],
            [-88.8075, 38.2992],
            [-88.7845, 38.2989],
            [-88.7819, 38.2989],
            [-88.7769, 38.2989],
            [-88.7218, 38.2276],
            [-88.7046, 38.1252],
            [-88.7902, 38.1261],
            [-88.7995, 38.1262],
            [-88.8157, 38.1264],
            [-88.8527, 38.1266],
            [-88.9307, 38.1255],
            [-88.93, 38.2139],
            [-88.9858, 38.2723],
            [-89.0042, 38.2724],
            [-88.9897, 38.3],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '27360',
        GEOID: '1727360',
        NAME: 'Mount Vernon Township High School District 201',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663520169,
        AWATER: 6435463,
        INTPTLAT: '+38.2900715',
        INTPTLON: '-088.8599649',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1557, 40.215],
            [-88.1445, 40.2151],
            [-88.1357, 40.2152],
            [-88.1358, 40.2245],
            [-88.0016, 40.225],
            [-87.9478, 40.2254],
            [-87.9421, 40.2255],
            [-87.9418, 40.2035],
            [-87.9227, 40.1855],
            [-87.9258, 40.0838],
            [-88.0097, 40.0688],
            [-88.0288, 40.0543],
            [-88.1239, 40.055],
            [-88.1252, 40.1714],
            [-88.1444, 40.2076],
            [-88.1556, 40.2076],
            [-88.1634, 40.2075],
            [-88.1557, 40.215],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '37410',
        GEOID: '1737410',
        NAME: 'St. Joseph-Ogden Community High School District 305',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312389487,
        AWATER: 839091,
        INTPTLAT: '+40.1461844',
        INTPTLON: '-088.0393249',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5782, 41.5765],
            [-87.5589, 41.5779],
            [-87.5587, 41.5945],
            [-87.5591, 41.6081],
            [-87.5591, 41.6154],
            [-87.5593, 41.6373],
            [-87.5593, 41.6445],
            [-87.525, 41.6446],
            [-87.5251, 41.6302],
            [-87.5251, 41.6155],
            [-87.5253, 41.6011],
            [-87.5253, 41.5815],
            [-87.5253, 41.5771],
            [-87.5254, 41.5575],
            [-87.525, 41.5283],
            [-87.5586, 41.5359],
            [-87.5587, 41.5575],
            [-87.5685, 41.5575],
            [-87.5781, 41.5575],
            [-87.5782, 41.5714],
            [-87.5782, 41.5765],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '38940',
        GEOID: '1738940',
        NAME: 'Thornton Fractional Township High School District 215',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39302484,
        AWATER: 534157,
        INTPTLAT: '+41.5856206',
        INTPTLON: '-087.5445710',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3979, 41.1922],
            [-88.3979, 41.1925],
            [-88.3611, 41.2427],
            [-88.3136, 41.2436],
            [-88.2455, 41.2446],
            [-88.2442, 41.2015],
            [-88.2093, 41.1731],
            [-88.319, 41.1418],
            [-88.3236, 41.1417],
            [-88.3381, 41.1414],
            [-88.3573, 41.1409],
            [-88.3966, 41.1595],
            [-88.3967, 41.1602],
            [-88.3968, 41.1634],
            [-88.3993, 41.1689],
            [-88.397, 41.1687],
            [-88.3979, 41.1922],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '16260',
        GEOID: '1716260',
        NAME: 'Gardner-South Wilmington Township High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134671029,
        AWATER: 3726219,
        INTPTLAT: '+41.1897470',
        INTPTLON: '-088.3083297',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.838, 41.2068],
            [-87.7456, 41.2075],
            [-87.7789, 41.1558],
            [-87.8287, 41.1558],
            [-87.8945, 41.1451],
            [-87.8956, 41.1477],
            [-87.9912, 41.2053],
            [-87.838, 41.2068],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '06960',
        GEOID: '1706960',
        NAME: 'Bradley-Bourbonnais Consolidated High School District 307',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112198784,
        AWATER: 947236,
        INTPTLAT: '+41.1829130',
        INTPTLON: '-087.8612047',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3395, 41.3494],
            [-89.3346, 41.3493],
            [-89.3257, 41.3693],
            [-89.3257, 41.3785],
            [-89.3206, 41.3786],
            [-89.3157, 41.3827],
            [-89.3257, 41.3827],
            [-89.3102, 41.3931],
            [-89.2776, 41.4078],
            [-89.2582, 41.4078],
            [-89.1657, 41.4302],
            [-89.1648, 41.3794],
            [-89.1643, 41.35],
            [-89.1643, 41.3485],
            [-89.1637, 41.3103],
            [-89.1637, 41.3102],
            [-89.2774, 41.3167],
            [-89.3346, 41.335],
            [-89.3353, 41.3007],
            [-89.3567, 41.2332],
            [-89.3939, 41.2337],
            [-89.3395, 41.3494],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '18030',
        GEOID: '1718030',
        NAME: 'Hall High School District 502',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182696290,
        AWATER: 6498084,
        INTPTLAT: '+41.3540938',
        INTPTLON: '-089.2525339',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2626, 41.863],
            [-88.2624, 41.8992],
            [-88.2378, 41.936],
            [-88.2374, 41.936],
            [-88.2328, 41.929],
            [-88.1945, 41.9217],
            [-88.1649, 41.9259],
            [-88.1454, 41.9011],
            [-88.1507, 41.8936],
            [-88.148, 41.8789],
            [-88.1463, 41.8572],
            [-88.2193, 41.8374],
            [-88.263, 41.8368],
            [-88.2626, 41.863],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '40440',
        GEOID: '1740440',
        NAME: 'Community High School District 94',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81296435,
        AWATER: 2327043,
        INTPTLAT: '+41.8793727',
        INTPTLON: '-088.2033455',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0047, 42.0956],
            [-88.0047, 42.1317],
            [-88.0045, 42.1536],
            [-87.9654, 42.1535],
            [-87.9099, 42.1531],
            [-87.8873, 42.153],
            [-87.8878, 42.1237],
            [-87.8881, 42.0948],
            [-87.888, 42.0873],
            [-87.8883, 42.066],
            [-87.9007, 42.0661],
            [-87.9077, 42.0661],
            [-87.9214, 42.0662],
            [-87.9212, 42.0488],
            [-87.9205, 42.0051],
            [-87.9399, 41.9935],
            [-87.9592, 41.9932],
            [-87.9972, 41.9927],
            [-88.0238, 41.9925],
            [-88.0291, 42.0509],
            [-88.0282, 42.0626],
            [-88.0047, 42.0956],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '04170',
        GEOID: '1704170',
        NAME: 'Township High School District 214',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168917996,
        AWATER: 1392445,
        INTPTLAT: '+42.0762484',
        INTPTLON: '-087.9617537',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4129, 42.2405],
            [-88.3552, 42.2986],
            [-88.2771, 42.2988],
            [-88.2268, 42.2797],
            [-88.2134, 42.245],
            [-88.1988, 42.2367],
            [-88.2035, 42.2049],
            [-88.2382, 42.1867],
            [-88.2384, 42.1945],
            [-88.2776, 42.205],
            [-88.3543, 42.1849],
            [-88.4129, 42.2405],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '11370',
        GEOID: '1711370',
        NAME: 'Community High School District 155',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171303257,
        AWATER: 5401621,
        INTPTLAT: '+42.2416429',
        INTPTLON: '-088.3000111',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7984, 41.7119],
            [-87.76, 41.7125],
            [-87.76, 41.7124],
            [-87.7211, 41.7132],
            [-87.7206, 41.6986],
            [-87.7301, 41.6911],
            [-87.7201, 41.684],
            [-87.6864, 41.6772],
            [-87.671, 41.6774],
            [-87.647, 41.657],
            [-87.6513, 41.6486],
            [-87.6696, 41.6482],
            [-87.6702, 41.6482],
            [-87.6797, 41.6482],
            [-87.6795, 41.6409],
            [-87.6986, 41.6332],
            [-87.7084, 41.6331],
            [-87.7327, 41.6326],
            [-87.7378, 41.6407],
            [-87.7768, 41.6403],
            [-87.7765, 41.6319],
            [-87.7952, 41.6316],
            [-87.7957, 41.6462],
            [-87.7969, 41.6753],
            [-87.7972, 41.6826],
            [-87.7979, 41.7007],
            [-87.7981, 41.7044],
            [-87.7984, 41.7119],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '06540',
        GEOID: '1706540',
        NAME: 'Community High School District 218',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77909379,
        AWATER: 1207801,
        INTPTLAT: '+41.6702888',
        INTPTLON: '-087.7358926',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2479, 40.6384],
            [-91.1125, 40.6963],
            [-91.1145, 40.7206],
            [-91.0203, 40.7001],
            [-90.8946, 40.6387],
            [-90.9558, 40.4324],
            [-90.908, 40.3774],
            [-91.0253, 40.3703],
            [-91.0259, 40.2828],
            [-91.191, 40.2838],
            [-91.2269, 40.3727],
            [-91.2165, 40.4318],
            [-91.2505, 40.4914],
            [-91.0902, 40.548],
            [-91.0897, 40.5774],
            [-91.2487, 40.5801],
            [-91.2479, 40.6384],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '01384',
        GEOID: '1701384',
        NAME: 'Illini West High School District 307',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 947594120,
        AWATER: 16384081,
        INTPTLAT: '+40.4997501',
        INTPTLON: '-091.0654874',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9143, 41.7167],
            [-87.8865, 41.7316],
            [-87.857, 41.7324],
            [-87.8475, 41.7326],
            [-87.7991, 41.7335],
            [-87.7984, 41.7119],
            [-87.7981, 41.7044],
            [-87.7979, 41.7007],
            [-87.7972, 41.6826],
            [-87.7969, 41.6753],
            [-87.7957, 41.6462],
            [-87.7952, 41.6316],
            [-87.7761, 41.6172],
            [-87.7758, 41.6099],
            [-87.7752, 41.5952],
            [-87.7938, 41.5918],
            [-87.7928, 41.5585],
            [-87.8608, 41.5576],
            [-87.9095, 41.5567],
            [-87.9119, 41.6439],
            [-87.9135, 41.6875],
            [-87.9143, 41.7167],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '08400',
        GEOID: '1708400',
        NAME: 'Consolidated High School District 230',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183567081,
        AWATER: 6143861,
        INTPTLAT: '+41.6433950',
        INTPTLON: '-087.8512225',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8765, 41.7393],
            [-87.8485, 41.768],
            [-87.8261, 41.7916],
            [-87.8176, 41.7985],
            [-87.802, 41.8064],
            [-87.8021, 41.7978],
            [-87.8008, 41.7737],
            [-87.8007, 41.7705],
            [-87.8003, 41.7627],
            [-87.7991, 41.7335],
            [-87.8475, 41.7326],
            [-87.857, 41.7324],
            [-87.8865, 41.7316],
            [-87.8765, 41.7393],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '04020',
        GEOID: '1704020',
        NAME: 'Argo Community High School District 217',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30606417,
        AWATER: 1034899,
        INTPTLAT: '+41.7598003',
        INTPTLON: '-087.8289776',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6745, 41.5206],
            [-87.6598, 41.5206],
            [-87.6512, 41.5279],
            [-87.6458, 41.5279],
            [-87.6457, 41.5352],
            [-87.6457, 41.5388],
            [-87.636, 41.5496],
            [-87.6263, 41.5574],
            [-87.5781, 41.5575],
            [-87.5685, 41.5575],
            [-87.5587, 41.5575],
            [-87.5586, 41.5359],
            [-87.525, 41.5283],
            [-87.5256, 41.5227],
            [-87.5256, 41.4989],
            [-87.5257, 41.4703],
            [-87.5867, 41.47],
            [-87.6742, 41.4698],
            [-87.6742, 41.477],
            [-87.6693, 41.4843],
            [-87.6743, 41.499],
            [-87.6745, 41.5206],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '06420',
        GEOID: '1706420',
        NAME: 'Bloom Township High School District 206',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103278401,
        AWATER: 414395,
        INTPTLAT: '+41.5031250',
        INTPTLON: '-087.6056812',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6329, 40.9611],
            [-88.7011, 40.9315],
            [-88.7009, 40.9643],
            [-88.6329, 40.9611],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '17902',
        GEOID: '1717902',
        NAME: 'Flanagan-Cornell District 74 in Pontiac',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 12723901,
        AWATER: 0,
        INTPTLAT: '+40.9512121',
        INTPTLON: '-088.6748113',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9186, 41.8255],
            [-87.9186, 41.8264],
            [-87.9003, 41.8264],
            [-87.9006, 41.8336],
            [-87.886, 41.834],
            [-87.8818, 41.8374],
            [-87.8806, 41.8449],
            [-87.8653, 41.8417],
            [-87.8614, 41.831],
            [-87.8503, 41.8203],
            [-87.8415, 41.8205],
            [-87.8406, 41.8205],
            [-87.8176, 41.7985],
            [-87.8261, 41.7916],
            [-87.8485, 41.768],
            [-87.8765, 41.7393],
            [-87.8865, 41.7316],
            [-87.9143, 41.7167],
            [-87.9149, 41.7311],
            [-87.9162, 41.7671],
            [-87.9046, 41.7676],
            [-87.9069, 41.7896],
            [-87.9186, 41.8255],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '23880',
        GEOID: '1723880',
        NAME: 'Lyons Township High School District 204',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61022133,
        AWATER: 289108,
        INTPTLAT: '+41.7860138',
        INTPTLON: '-087.8797038',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1989, 42.4447],
            [-88.1712, 42.4459],
            [-88.168, 42.4442],
            [-88.1604, 42.4156],
            [-88.1402, 42.4162],
            [-88.1204, 42.4154],
            [-88.0921, 42.4004],
            [-88.0822, 42.3932],
            [-88.1205, 42.3847],
            [-88.1204, 42.3655],
            [-88.1203, 42.3435],
            [-88.14, 42.3285],
            [-88.1986, 42.3289],
            [-88.1986, 42.337],
            [-88.1985, 42.358],
            [-88.1988, 42.3724],
            [-88.1988, 42.3799],
            [-88.1988, 42.3872],
            [-88.1988, 42.3983],
            [-88.1988, 42.4156],
            [-88.1989, 42.4447],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '17340',
        GEOID: '1717340',
        NAME: 'Grant Community High School District 124',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60310566,
        AWATER: 17029841,
        INTPTLAT: '+42.3819573',
        INTPTLON: '-088.1593888',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2553, 41.5501],
            [-88.1418, 41.5512],
            [-88.1102, 41.5521],
            [-88.077, 41.5532],
            [-88.0249, 41.5542],
            [-88.0058, 41.5546],
            [-88.0052, 41.5364],
            [-87.9934, 41.5262],
            [-88.0001, 41.5261],
            [-88.0235, 41.511],
            [-88.0042, 41.5041],
            [-88.0035, 41.4893],
            [-88.0032, 41.4825],
            [-88.0021, 41.468],
            [-88.0118, 41.4678],
            [-88.0221, 41.4677],
            [-88.0261, 41.4641],
            [-88.0474, 41.3796],
            [-88.1736, 41.3843],
            [-88.2322, 41.3889],
            [-88.1743, 41.434],
            [-88.1684, 41.4426],
            [-88.167, 41.4643],
            [-88.2119, 41.5072],
            [-88.212, 41.5109],
            [-88.2289, 41.5286],
            [-88.2553, 41.5501],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '20610',
        GEOID: '1720610',
        NAME: 'Joliet Township High School District 204',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280122896,
        AWATER: 5619862,
        INTPTLAT: '+41.4726603',
        INTPTLON: '-088.1091531',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0303, 41.7221],
            [-87.9977, 41.7216],
            [-87.9977, 41.7219],
            [-87.9535, 41.7231],
            [-87.9532, 41.7155],
            [-87.9535, 41.6942],
            [-87.9143, 41.7167],
            [-87.9135, 41.6875],
            [-87.9119, 41.6439],
            [-87.9591, 41.643],
            [-88.0288, 41.6698],
            [-88.0303, 41.7221],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '22500',
        GEOID: '1722500',
        NAME: 'Lemont Township High School District 210',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77453879,
        AWATER: 2900640,
        INTPTLAT: '+41.6801475',
        INTPTLON: '-087.9731885',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8635, 40.5177],
            [-89.7331, 40.5527],
            [-89.6888, 40.5585],
            [-89.6824, 40.5526],
            [-89.6588, 40.5892],
            [-89.6364, 40.6232],
            [-89.624, 40.6335],
            [-89.6161, 40.6608],
            [-89.6078, 40.6607],
            [-89.6033, 40.63],
            [-89.5888, 40.6263],
            [-89.5651, 40.6266],
            [-89.5549, 40.6119],
            [-89.542, 40.568],
            [-89.5682, 40.5095],
            [-89.6064, 40.4805],
            [-89.6481, 40.48],
            [-89.6635, 40.4798],
            [-89.7184, 40.4934],
            [-89.8635, 40.5177],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '31110',
        GEOID: '1731110',
        NAME: 'Pekin Community High School District 303',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216484819,
        AWATER: 6520141,
        INTPTLAT: '+40.5439497',
        INTPTLON: '-089.6596456',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7952, 41.6316],
            [-87.7765, 41.6319],
            [-87.7768, 41.6403],
            [-87.7378, 41.6407],
            [-87.7327, 41.6326],
            [-87.7084, 41.6331],
            [-87.6986, 41.6332],
            [-87.6795, 41.6409],
            [-87.6753, 41.6082],
            [-87.6752, 41.6044],
            [-87.6751, 41.5936],
            [-87.6751, 41.5863],
            [-87.675, 41.5717],
            [-87.6847, 41.5718],
            [-87.685, 41.5643],
            [-87.7044, 41.5571],
            [-87.7136, 41.5571],
            [-87.7524, 41.557],
            [-87.7643, 41.5589],
            [-87.7928, 41.5585],
            [-87.7938, 41.5918],
            [-87.7752, 41.5952],
            [-87.7758, 41.6099],
            [-87.7761, 41.6172],
            [-87.7952, 41.6316],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '07050',
        GEOID: '1707050',
        NAME: 'Bremen Community High School District 228',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79985649,
        AWATER: 255013,
        INTPTLAT: '+41.5987613',
        INTPTLON: '-087.7324748',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9837, 41.7443],
            [-87.98, 41.7443],
            [-87.9743, 41.7664],
            [-87.9751, 41.7882],
            [-87.9654, 41.7884],
            [-87.9525, 41.8177],
            [-87.9859, 41.8192],
            [-87.9852, 41.8203],
            [-87.9863, 41.822],
            [-87.9768, 41.8461],
            [-87.9394, 41.8546],
            [-87.9192, 41.8477],
            [-87.9186, 41.8264],
            [-87.9186, 41.8255],
            [-87.9069, 41.7896],
            [-87.9046, 41.7676],
            [-87.9162, 41.7671],
            [-87.9149, 41.7311],
            [-87.9143, 41.7167],
            [-87.9535, 41.6942],
            [-87.9532, 41.7155],
            [-87.9535, 41.7231],
            [-87.9977, 41.7219],
            [-87.9837, 41.7443],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '19320',
        GEOID: '1719320',
        NAME: 'Hinsdale Township High School District 86',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73091464,
        AWATER: 2214484,
        INTPTLAT: '+41.7788563',
        INTPTLON: '-087.9436813',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.8594, 41.9958],
              [-87.8559, 41.9891],
              [-87.8668, 41.9739],
              [-87.8738, 41.9767],
              [-87.8801, 41.9952],
              [-87.8594, 41.9958],
            ],
          ],
          [
            [
              [-87.92, 41.9582],
              [-87.8843, 41.9575],
              [-87.853, 41.9431],
              [-87.8496, 41.9373],
              [-87.8263, 41.9378],
              [-87.8255, 41.911],
              [-87.8255, 41.9085],
              [-87.8644, 41.9079],
              [-87.8704, 41.9078],
              [-87.8838, 41.9076],
              [-87.9204, 41.9068],
              [-87.9204, 41.914],
              [-87.9203, 41.9358],
              [-87.92, 41.9582],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '22740',
        GEOID: '1722740',
        NAME: 'Leyden Community High School District 212',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44225877,
        AWATER: 0,
        INTPTLAT: '+41.9376511',
        INTPTLON: '-087.8759775',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1995, 42.4951],
            [-88.1986, 42.4951],
            [-88.1097, 42.4956],
            [-88.0621, 42.4955],
            [-87.9716, 42.4948],
            [-87.9722, 42.4583],
            [-88.0035, 42.448],
            [-88.004, 42.4079],
            [-88.019, 42.4079],
            [-88.0209, 42.4079],
            [-88.0921, 42.4004],
            [-88.1204, 42.4154],
            [-88.1402, 42.4162],
            [-88.1604, 42.4156],
            [-88.168, 42.4442],
            [-88.1712, 42.4459],
            [-88.1989, 42.4447],
            [-88.199, 42.4591],
            [-88.1995, 42.4951],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '03870',
        GEOID: '1703870',
        NAME: 'Antioch Community High School District 117',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129884275,
        AWATER: 22802777,
        INTPTLAT: '+42.4519009',
        INTPTLON: '-088.0898435',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7324, 42.0647],
            [-87.6796, 42.0717],
            [-87.6651, 42.023],
            [-87.7087, 42.0191],
            [-87.7089, 42.0226],
            [-87.7279, 42.0371],
            [-87.7324, 42.0647],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '14490',
        GEOID: '1714490',
        NAME: 'Evanston Township High School District 202',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23094787,
        AWATER: 57390,
        INTPTLAT: '+42.0471170',
        INTPTLON: '-087.6971114',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0728, 38.3965],
            [-90.0734, 38.3967],
            [-90.0408, 38.4182],
            [-90.0141, 38.4464],
            [-90.0198, 38.4539],
            [-90.014, 38.4538],
            [-89.9946, 38.4538],
            [-89.9625, 38.4835],
            [-89.9452, 38.4836],
            [-89.9428, 38.4836],
            [-89.9307, 38.4836],
            [-89.9286, 38.4836],
            [-89.7811, 38.394],
            [-89.7042, 38.4076],
            [-89.7038, 38.3354],
            [-89.741, 38.3356],
            [-89.772, 38.3498],
            [-89.9643, 38.3589],
            [-89.9989, 38.3626],
            [-90.0361, 38.3233],
            [-90.0728, 38.3965],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '15840',
        GEOID: '1715840',
        NAME: 'Freeburg Community High School District 77',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296455333,
        AWATER: 7683848,
        INTPTLAT: '+38.4019249',
        INTPTLON: '-089.8963036',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1328, 41.6031],
            [-88.1152, 41.5883],
            [-88.0704, 41.5876],
            [-88.0565, 41.5972],
            [-88.0288, 41.6698],
            [-87.9591, 41.643],
            [-87.9119, 41.6439],
            [-87.9095, 41.5567],
            [-87.9321, 41.5564],
            [-87.9431, 41.556],
            [-88.0058, 41.5546],
            [-88.0249, 41.5542],
            [-88.077, 41.5532],
            [-88.1102, 41.5521],
            [-88.1418, 41.5512],
            [-88.1328, 41.6031],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '23350',
        GEOID: '1723350',
        NAME: 'Lockport Township High School District 205',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157916592,
        AWATER: 1170943,
        INTPTLAT: '+41.5930519',
        INTPTLON: '-088.0104827',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3847, 41.4675],
            [-88.3848, 41.4747],
            [-88.3755, 41.5173],
            [-88.3522, 41.5485],
            [-88.3093, 41.5491],
            [-88.2553, 41.5501],
            [-88.2289, 41.5286],
            [-88.212, 41.5109],
            [-88.2119, 41.5072],
            [-88.167, 41.4643],
            [-88.1684, 41.4426],
            [-88.1743, 41.434],
            [-88.2322, 41.3889],
            [-88.25, 41.3885],
            [-88.25, 41.3878],
            [-88.3089, 41.3925],
            [-88.3166, 41.4002],
            [-88.3416, 41.4015],
            [-88.3469, 41.4168],
            [-88.3472, 41.4385],
            [-88.4136, 41.4596],
            [-88.3847, 41.4675],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '26340',
        GEOID: '1726340',
        NAME: 'Minooka Community High School District 111',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229304616,
        AWATER: 5181216,
        INTPTLAT: '+41.4730770',
        INTPTLON: '-088.2768508',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9893, 38.6072],
            [-89.9851, 38.6232],
            [-89.9284, 38.6577],
            [-89.8434, 38.6561],
            [-89.825, 38.5689],
            [-89.8739, 38.5697],
            [-89.9288, 38.5427],
            [-89.9291, 38.5706],
            [-89.9473, 38.5706],
            [-89.9499, 38.5707],
            [-89.9661, 38.5708],
            [-89.9662, 38.5786],
            [-89.9662, 38.5847],
            [-89.9662, 38.5865],
            [-89.9662, 38.5927],
            [-89.9663, 38.6023],
            [-89.9717, 38.6071],
            [-89.9813, 38.6071],
            [-89.9893, 38.6072],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '29790',
        GEOID: '1729790',
        NAME: "O'Fallon Township High School District 203",
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126164180,
        AWATER: 1742492,
        INTPTLAT: '+38.6045704',
        INTPTLON: '-089.9064056',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.144, 38.4846],
            [-90.1343, 38.5322],
            [-90.0975, 38.5536],
            [-90.092, 38.5646],
            [-90.0885, 38.5708],
            [-90.0465, 38.5721],
            [-90.0359, 38.572],
            [-90.0373, 38.6232],
            [-89.9851, 38.6232],
            [-89.9893, 38.6072],
            [-89.9813, 38.6071],
            [-89.9717, 38.6071],
            [-89.9663, 38.6023],
            [-89.9662, 38.5927],
            [-89.9662, 38.5865],
            [-89.9662, 38.5847],
            [-89.9662, 38.5786],
            [-89.9661, 38.5708],
            [-89.9499, 38.5707],
            [-89.9473, 38.5706],
            [-89.9291, 38.5706],
            [-89.9288, 38.5427],
            [-89.9287, 38.5262],
            [-89.9286, 38.4836],
            [-89.9307, 38.4836],
            [-89.9428, 38.4836],
            [-89.9452, 38.4836],
            [-89.9625, 38.4835],
            [-89.9946, 38.4538],
            [-90.014, 38.4538],
            [-90.0198, 38.4539],
            [-90.0141, 38.4464],
            [-90.0408, 38.4182],
            [-90.0734, 38.3967],
            [-90.1289, 38.3976],
            [-90.144, 38.4846],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '05640',
        GEOID: '1705640',
        NAME: 'Belleville Township High School District 201',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302543107,
        AWATER: 5014532,
        INTPTLAT: '+38.5095867',
        INTPTLON: '-090.0391635',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9839, 38.0376],
            [-88.9954, 38.1251],
            [-88.9307, 38.1255],
            [-88.8527, 38.1266],
            [-88.8157, 38.1264],
            [-88.7995, 38.1262],
            [-88.7902, 38.1261],
            [-88.7046, 38.1252],
            [-88.7227, 38.049],
            [-88.7065, 37.951],
            [-88.8221, 37.9507],
            [-88.8478, 37.9507],
            [-88.9841, 37.9501],
            [-89.002, 37.9501],
            [-88.9962, 38.0014],
            [-88.9839, 38.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '05970',
        GEOID: '1705970',
        NAME: 'Benton Consolidated High School District 103',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440530997,
        AWATER: 46824457,
        INTPTLAT: '+38.0431026',
        INTPTLON: '-088.8459047',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1203, 42.3435],
            [-88.0681, 42.386],
            [-88.0822, 42.3932],
            [-88.0921, 42.4004],
            [-88.0209, 42.4079],
            [-88.019, 42.4079],
            [-88.004, 42.4079],
            [-88.004, 42.3966],
            [-88.0041, 42.3931],
            [-88.0041, 42.3678],
            [-88.0042, 42.3606],
            [-88.0047, 42.3274],
            [-88.0046, 42.3206],
            [-88.0045, 42.3165],
            [-88.0045, 42.3136],
            [-88.0824, 42.3136],
            [-88.1208, 42.3295],
            [-88.14, 42.3285],
            [-88.1203, 42.3435],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '17550',
        GEOID: '1717550',
        NAME: 'Grayslake Community High School District 127',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63786667,
        AWATER: 4622271,
        INTPTLAT: '+42.3606206',
        INTPTLON: '-088.0470870',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5032, 40.7476],
            [-89.4693, 40.7477],
            [-89.4558, 40.7476],
            [-89.3402, 40.7482],
            [-89.3296, 40.6608],
            [-89.3864, 40.6605],
            [-89.4457, 40.6601],
            [-89.5023, 40.6746],
            [-89.5027, 40.6819],
            [-89.5028, 40.6966],
            [-89.5028, 40.6985],
            [-89.5032, 40.7476],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '40980',
        GEOID: '1740980',
        NAME: 'Washington Community High School District 308',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139115302,
        AWATER: 34035,
        INTPTLAT: '+40.7042865',
        INTPTLON: '-089.4140836',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5506, 40.7387],
            [-89.555, 40.7476],
            [-89.5174, 40.7476],
            [-89.5032, 40.7476],
            [-89.5028, 40.6985],
            [-89.5028, 40.6966],
            [-89.5027, 40.6819],
            [-89.5023, 40.6746],
            [-89.5127, 40.6746],
            [-89.5127, 40.6686],
            [-89.5549, 40.6119],
            [-89.5651, 40.6266],
            [-89.5888, 40.6263],
            [-89.6033, 40.63],
            [-89.6078, 40.6607],
            [-89.6161, 40.6608],
            [-89.5506, 40.7387],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '13230',
        GEOID: '1713230',
        NAME: 'East Peoria Community High School District 309',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80881435,
        AWATER: 9595867,
        INTPTLAT: '+40.6741311',
        INTPTLON: '-089.5458262',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.166, 41.4524],
            [-89.1555, 41.4544],
            [-89.146, 41.4546],
            [-89.0493, 41.4556],
            [-89.0248, 41.4553],
            [-88.9817, 41.4326],
            [-88.9617, 41.3296],
            [-88.9911, 41.3257],
            [-88.9523, 41.3179],
            [-89.0009, 41.253],
            [-88.9423, 41.2151],
            [-88.9267, 41.2072],
            [-88.9296, 41.2072],
            [-88.9322, 41.1927],
            [-88.9321, 41.1854],
            [-88.9516, 41.1782],
            [-88.9514, 41.1709],
            [-89.1626, 41.1923],
            [-89.1442, 41.265],
            [-89.1637, 41.3102],
            [-89.1637, 41.3103],
            [-89.1643, 41.3485],
            [-89.1643, 41.35],
            [-89.1648, 41.3794],
            [-89.1657, 41.4302],
            [-89.166, 41.4524],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '22110',
        GEOID: '1722110',
        NAME: 'LaSalle-Peru Township High School District 120',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469190344,
        AWATER: 6454211,
        INTPTLAT: '+41.3168007',
        INTPTLON: '-089.0736782',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0261, 41.4641],
            [-88.0221, 41.4677],
            [-88.0118, 41.4678],
            [-88.0021, 41.468],
            [-88.0032, 41.4825],
            [-88.0035, 41.4893],
            [-88.0042, 41.5041],
            [-88.0235, 41.511],
            [-88.0001, 41.5261],
            [-87.9934, 41.5262],
            [-88.0052, 41.5364],
            [-88.0058, 41.5546],
            [-87.9431, 41.556],
            [-87.9321, 41.5564],
            [-87.9095, 41.5567],
            [-87.8608, 41.5576],
            [-87.7928, 41.5585],
            [-87.7903, 41.499],
            [-87.7903, 41.4698],
            [-87.9064, 41.4696],
            [-87.9028, 41.4106],
            [-88.0183, 41.3804],
            [-88.0474, 41.3796],
            [-88.0261, 41.4641],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '23070',
        GEOID: '1723070',
        NAME: 'Lincoln Way Community High School District 210',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254531511,
        AWATER: 248918,
        INTPTLAT: '+41.4875890',
        INTPTLON: '-087.9219563',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7217, 41.7278],
            [-87.6874, 41.7354],
            [-87.6818, 41.7137],
            [-87.7211, 41.7132],
            [-87.7217, 41.7278],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '14580',
        GEOID: '1714580',
        NAME: 'Evergreen Park Community High School District 231',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8191088,
        AWATER: 0,
        INTPTLAT: '+41.7212749',
        INTPTLON: '-087.7013342',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7136, 41.5571],
            [-87.7044, 41.5571],
            [-87.685, 41.5643],
            [-87.6847, 41.5718],
            [-87.675, 41.5717],
            [-87.6363, 41.5719],
            [-87.6266, 41.5605],
            [-87.6263, 41.5574],
            [-87.636, 41.5496],
            [-87.6457, 41.5388],
            [-87.6457, 41.5352],
            [-87.6458, 41.5279],
            [-87.6512, 41.5279],
            [-87.6598, 41.5206],
            [-87.6745, 41.5206],
            [-87.6745, 41.5243],
            [-87.7133, 41.5279],
            [-87.7136, 41.5571],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '19560',
        GEOID: '1719560',
        NAME: 'Homewood-Flossmoor Community High School District 233',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29900115,
        AWATER: 154112,
        INTPTLAT: '+41.5485798',
        INTPTLON: '-087.6699662',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1132, 38.8933],
            [-90.0871, 38.8896],
            [-90.087, 38.8692],
            [-90.076, 38.7971],
            [-90.0759, 38.7912],
            [-90.1177, 38.8057],
            [-90.121, 38.8529],
            [-90.1272, 38.8696],
            [-90.1132, 38.8933],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '12990',
        GEOID: '1712990',
        NAME: 'East Alton-Wood River Community High School District 14',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31616063,
        AWATER: 3510104,
        INTPTLAT: '+38.8394437',
        INTPTLON: '-090.1020127',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.008, 41.9048],
            [-87.9688, 41.9056],
            [-87.9594, 41.8756],
            [-87.9768, 41.8461],
            [-88.0018, 41.8384],
            [-88.008, 41.9048],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740350',
        NAME: 'Villa Park School District 45',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19296648,
        AWATER: 220309,
        INTPTLAT: '+41.8791859',
        INTPTLON: '-087.9862152',
        ELSDLEA: '40350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.079, 41.8592],
            [-88.0416, 41.8667],
            [-88.0435, 41.8318],
            [-88.0527, 41.8155],
            [-88.0875, 41.815],
            [-88.079, 41.8592],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740500',
        NAME: 'Glen Ellyn Community Consolidated School District 89',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20293006,
        AWATER: 731207,
        INTPTLAT: '+41.8394475',
        INTPTLON: '-088.0566208',
        ELSDLEA: '40500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2626, 41.863],
            [-88.2624, 41.8992],
            [-88.2378, 41.936],
            [-88.2374, 41.936],
            [-88.2328, 41.929],
            [-88.1945, 41.9217],
            [-88.1507, 41.8936],
            [-88.148, 41.8789],
            [-88.1757, 41.8787],
            [-88.1657, 41.8569],
            [-88.1463, 41.8572],
            [-88.2193, 41.8374],
            [-88.263, 41.8368],
            [-88.2626, 41.863],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741550',
        NAME: 'West Chicago School District 33',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62752764,
        AWATER: 1912490,
        INTPTLAT: '+41.8741179',
        INTPTLON: '-088.2112307',
        ELSDLEA: '41550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9972, 41.9927],
            [-87.9592, 41.9932],
            [-87.9789, 41.9494],
            [-87.9984, 41.9564],
            [-87.9972, 41.9927],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743020',
        NAME: 'Wood Dale School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12738357,
        AWATER: 265560,
        INTPTLAT: '+41.9765040',
        INTPTLON: '-087.9764973',
        ELSDLEA: '43020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1218, 41.8683],
            [-89.0128, 41.8909],
            [-88.9413, 41.8918],
            [-88.9362, 41.8843],
            [-89.0461, 41.7895],
            [-89.1126, 41.7813],
            [-89.1218, 41.8683],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737800',
        NAME: 'Steward Elementary School District 220',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129503222,
        AWATER: 114099,
        INTPTLAT: '+41.8393486',
        INTPTLON: '-089.0401135',
        ELSDLEA: '37800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1852, 41.9542],
            [-89.0393, 41.9779],
            [-89.0204, 41.9781],
            [-89.0128, 41.8909],
            [-89.1218, 41.8683],
            [-89.2246, 41.9394],
            [-89.1852, 41.9542],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734260',
        NAME: 'Rochelle Community Consolidated District 231',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130216365,
        AWATER: 286110,
        INTPTLAT: '+41.9255365',
        INTPTLON: '-089.1016461',
        ELSDLEA: '34260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7632, 41.7576],
            [-89.6841, 41.758],
            [-89.6031, 41.7296],
            [-89.5914, 41.7006],
            [-89.6321, 41.6568],
            [-89.7092, 41.657],
            [-89.8144, 41.7181],
            [-89.7632, 41.7576],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726590',
        NAME: 'Montmorency Community Consolidated School District 145',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148786095,
        AWATER: 1042659,
        INTPTLAT: '+41.7077676',
        INTPTLON: '-089.7000137',
        ELSDLEA: '26590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1849, 41.6977],
            [-89.1902, 41.7435],
            [-89.1684, 41.7443],
            [-89.0986, 41.7522],
            [-89.0052, 41.6274],
            [-88.9359, 41.5102],
            [-88.9675, 41.4546],
            [-89.0248, 41.4553],
            [-89.0493, 41.4556],
            [-89.146, 41.4546],
            [-89.1555, 41.4544],
            [-89.166, 41.4524],
            [-89.1823, 41.5248],
            [-89.2565, 41.5852],
            [-89.167, 41.6146],
            [-89.1849, 41.6977],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725620',
        NAME: 'Mendota Community Consolidated School District 289',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513580644,
        AWATER: 505349,
        INTPTLAT: '+41.5786140',
        INTPTLON: '-089.0930993',
        ELSDLEA: '25620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5164, 41.6244],
            [-89.3912, 41.5849],
            [-89.3734, 41.5385],
            [-89.3938, 41.4949],
            [-89.4266, 41.4949],
            [-89.4326, 41.5095],
            [-89.4327, 41.5193],
            [-89.4494, 41.5241],
            [-89.4519, 41.5241],
            [-89.4795, 41.524],
            [-89.512, 41.5238],
            [-89.5164, 41.6244],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729700',
        NAME: 'Ohio Community Consolidated School District 17',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124609236,
        AWATER: 88305,
        INTPTLAT: '+41.5594694',
        INTPTLON: '-089.4448495',
        ELSDLEA: '29700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0204, 41.9781],
            [-88.9167, 41.9791],
            [-88.9413, 41.8918],
            [-89.0128, 41.8909],
            [-89.0204, 41.9781],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711220',
        NAME: 'Creston Community Consolidated School District 161',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84899985,
        AWATER: 125383,
        INTPTLAT: '+41.9459693',
        INTPTLON: '-088.9783063',
        ELSDLEA: '11220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0333, 41.951],
            [-87.9984, 41.9564],
            [-87.9789, 41.9494],
            [-87.9691, 41.9349],
            [-87.9688, 41.9056],
            [-88.008, 41.9048],
            [-88.0326, 41.9042],
            [-88.0333, 41.951],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703150',
        NAME: 'Addison School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27978406,
        AWATER: 659536,
        INTPTLAT: '+41.9301607',
        INTPTLON: '-088.0014687',
        ELSDLEA: '03150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9592, 41.9932],
            [-87.9399, 41.9935],
            [-87.92, 41.9582],
            [-87.9203, 41.9358],
            [-87.9691, 41.9349],
            [-87.9789, 41.9494],
            [-87.9592, 41.9932],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705910',
        NAME: 'Bensenville School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18300968,
        AWATER: 190742,
        INTPTLAT: '+41.9570848',
        INTPTLON: '-087.9454736',
        ELSDLEA: '05910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1091, 41.9609],
            [-88.0909, 41.9759],
            [-88.0641, 41.9696],
            [-88.0414, 41.9634],
            [-88.0417, 41.9532],
            [-88.09, 41.9444],
            [-88.1091, 41.9609],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706450',
        NAME: 'Bloomingdale School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12556717,
        AWATER: 514002,
        INTPTLAT: '+41.9562783',
        INTPTLON: '-088.0756898',
        ELSDLEA: '06450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9768, 41.8461],
            [-87.9394, 41.8546],
            [-87.9192, 41.8477],
            [-87.9186, 41.8264],
            [-87.9186, 41.8255],
            [-87.9525, 41.8177],
            [-87.9859, 41.8192],
            [-87.9852, 41.8203],
            [-87.9863, 41.822],
            [-87.9768, 41.8461],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707980',
        NAME: 'Butler School District 53',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14451034,
        AWATER: 594279,
        INTPTLAT: '+41.8338173',
        INTPTLON: '-087.9516040',
        ELSDLEA: '07980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6614, 40.663],
            [-89.6422, 40.6556],
            [-89.6428, 40.6234],
            [-89.6555, 40.6234],
            [-89.6647, 40.6541],
            [-89.6614, 40.663],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705190',
        NAME: 'Bartonville School District 66',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4782331,
        AWATER: 380906,
        INTPTLAT: '+40.6434154',
        INTPTLON: '-089.6516385',
        ELSDLEA: '05190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7565, 40.7109],
            [-89.7175, 40.7323],
            [-89.6893, 40.7302],
            [-89.6807, 40.7108],
            [-89.704, 40.6851],
            [-89.6959, 40.6523],
            [-89.6891, 40.6235],
            [-89.6921, 40.5974],
            [-89.7566, 40.653],
            [-89.7565, 40.7109],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722920',
        NAME: 'Limestone Walters Community Consolidated School District 316',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44552818,
        AWATER: 0,
        INTPTLAT: '+40.6736037',
        INTPTLON: '-089.7228281',
        ELSDLEA: '22920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0704, 41.5876],
            [-88.0565, 41.5972],
            [-88.0258, 41.5833],
            [-88.045, 41.5827],
            [-88.072, 41.582],
            [-88.0704, 41.5876],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709210',
        NAME: 'Lockport School District 91',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5560146,
        AWATER: 330518,
        INTPTLAT: '+41.5892583',
        INTPTLON: '-088.0480213',
        ELSDLEA: '09210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1966, 41.4637],
            [-88.1684, 41.4426],
            [-88.1743, 41.434],
            [-88.1744, 41.4211],
            [-88.1736, 41.3843],
            [-88.2322, 41.3889],
            [-88.25, 41.3885],
            [-88.1966, 41.4637],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709540',
        NAME: 'Channahon School District 17',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47676275,
        AWATER: 3947481,
        INTPTLAT: '+41.4245578',
        INTPTLON: '-088.2087512',
        ELSDLEA: '09540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1744, 41.4211],
            [-88.0261, 41.4641],
            [-88.0474, 41.3796],
            [-88.1736, 41.3843],
            [-88.1744, 41.4211],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714160',
        NAME: 'Elwood Community Consolidated School District 203',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75503647,
        AWATER: 105260,
        INTPTLAT: '+41.4120769',
        INTPTLON: '-088.1005053',
        ELSDLEA: '14160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0815, 41.5671],
            [-88.045, 41.5827],
            [-88.0258, 41.5833],
            [-88.0249, 41.5542],
            [-88.077, 41.5532],
            [-88.0815, 41.5671],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714760',
        NAME: 'Fairmont School District 89',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10432979,
        AWATER: 159028,
        INTPTLAT: '+41.5640204',
        INTPTLON: '-088.0484103',
        ELSDLEA: '14760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9074, 41.4983],
            [-87.8501, 41.5284],
            [-87.7903, 41.499],
            [-87.7903, 41.4698],
            [-87.9064, 41.4696],
            [-87.9161, 41.4694],
            [-87.9074, 41.4983],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715700',
        NAME: 'Frankfort Community Consolidated School District 157C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44489364,
        AWATER: 12574,
        INTPTLAT: '+41.4883484',
        INTPTLON: '-087.8565000',
        ELSDLEA: '15700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0258, 41.5833],
            [-87.9591, 41.643],
            [-87.9119, 41.6439],
            [-87.9095, 41.5567],
            [-87.9321, 41.5564],
            [-87.9431, 41.556],
            [-88.0058, 41.5546],
            [-88.0249, 41.5542],
            [-88.0258, 41.5833],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719500',
        NAME: 'Homer Community Consolidated School District 33C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66057313,
        AWATER: 120382,
        INTPTLAT: '+41.5922221',
        INTPTLON: '-087.9642517',
        ELSDLEA: '19500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1418, 41.5512],
            [-88.1102, 41.5521],
            [-88.077, 41.5532],
            [-88.0249, 41.5542],
            [-88.0052, 41.5364],
            [-87.9934, 41.5262],
            [-88.0001, 41.5261],
            [-88.0235, 41.511],
            [-88.0514, 41.4886],
            [-88.1212, 41.4938],
            [-88.1401, 41.5085],
            [-88.1418, 41.5512],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720580',
        NAME: 'Joliet Public School District 86',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57499851,
        AWATER: 1484126,
        INTPTLAT: '+41.5274214',
        INTPTLON: '-088.0779843',
        ELSDLEA: '20580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1743, 41.434],
            [-88.1684, 41.4426],
            [-88.167, 41.4643],
            [-88.1398, 41.4938],
            [-88.1212, 41.4938],
            [-88.0514, 41.4886],
            [-88.0221, 41.4677],
            [-88.0261, 41.4641],
            [-88.1744, 41.4211],
            [-88.1743, 41.434],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722050',
        NAME: 'Laraway Community Consolidated School District 70C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56812970,
        AWATER: 1646187,
        INTPTLAT: '+41.4584096',
        INTPTLON: '-088.1089694',
        ELSDLEA: '22050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0288, 41.6698],
            [-87.9591, 41.643],
            [-88.0258, 41.5833],
            [-88.0565, 41.5972],
            [-88.0288, 41.6698],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723730',
        NAME: 'Will County School District 92',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47209266,
        AWATER: 58547,
        INTPTLAT: '+41.6209437',
        INTPTLON: '-088.0147014',
        ELSDLEA: '23730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0261, 41.4641],
            [-88.0221, 41.4677],
            [-88.0118, 41.4678],
            [-88.0021, 41.468],
            [-87.9161, 41.4694],
            [-87.9064, 41.4696],
            [-87.9028, 41.4106],
            [-88.0183, 41.3804],
            [-88.0474, 41.3796],
            [-88.0261, 41.4641],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724270',
        NAME: 'Manhattan School District 114',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79397032,
        AWATER: 0,
        INTPTLAT: '+41.4336863',
        INTPTLON: '-087.9757611',
        ELSDLEA: '24270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3847, 41.4675],
            [-88.3848, 41.4747],
            [-88.3755, 41.5173],
            [-88.3522, 41.5485],
            [-88.3093, 41.5491],
            [-88.2553, 41.5501],
            [-88.2289, 41.5286],
            [-88.212, 41.5109],
            [-88.2119, 41.5072],
            [-88.1966, 41.4637],
            [-88.25, 41.3885],
            [-88.25, 41.3878],
            [-88.3089, 41.3925],
            [-88.3181, 41.3886],
            [-88.3166, 41.4002],
            [-88.3416, 41.4015],
            [-88.3469, 41.4168],
            [-88.3472, 41.4385],
            [-88.4136, 41.4596],
            [-88.3847, 41.4675],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726310',
        NAME: 'Minooka Community Consolidated School District 201',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179291069,
        AWATER: 2036643,
        INTPTLAT: '+41.4790218',
        INTPTLON: '-088.3063591',
        ELSDLEA: '26310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9095, 41.5567],
            [-87.8608, 41.5576],
            [-87.8501, 41.5284],
            [-87.9074, 41.4983],
            [-87.9095, 41.5567],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726370',
        NAME: 'Mokena School District 159',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24248876,
        AWATER: 40249,
        INTPTLAT: '+41.5330179',
        INTPTLON: '-087.8881639',
        ELSDLEA: '26370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0249, 41.5542],
            [-88.0058, 41.5546],
            [-87.9431, 41.556],
            [-87.9321, 41.5564],
            [-87.9095, 41.5567],
            [-87.9074, 41.4983],
            [-87.9161, 41.4694],
            [-88.0021, 41.468],
            [-88.0118, 41.4678],
            [-88.0032, 41.4825],
            [-88.0035, 41.4893],
            [-88.0042, 41.5041],
            [-88.0235, 41.511],
            [-88.0001, 41.5261],
            [-87.9934, 41.5262],
            [-88.0052, 41.5364],
            [-88.0249, 41.5542],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728140',
        NAME: 'New Lenox School District 122',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77453584,
        AWATER: 196095,
        INTPTLAT: '+41.5140148',
        INTPTLON: '-087.9633111',
        ELSDLEA: '28140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1328, 41.6031],
            [-88.1152, 41.5883],
            [-88.1102, 41.5521],
            [-88.1418, 41.5512],
            [-88.1328, 41.6031],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733450',
        NAME: 'Richland School District 88A',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13118695,
        AWATER: 101514,
        INTPTLAT: '+41.5746979',
        INTPTLON: '-088.1320164',
        ELSDLEA: '33450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1212, 41.4938],
            [-88.1398, 41.4938],
            [-88.1401, 41.5085],
            [-88.1212, 41.4938],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734470',
        NAME: 'Rockdale School District 84',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5523793,
        AWATER: 114649,
        INTPTLAT: '+41.5035047',
        INTPTLON: '-088.1248009',
        ELSDLEA: '34470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6693, 41.4843],
            [-87.6162, 41.4987],
            [-87.5872, 41.4987],
            [-87.5867, 41.47],
            [-87.6742, 41.4698],
            [-87.6742, 41.477],
            [-87.6693, 41.4843],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737680',
        NAME: 'Steger School District 194',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20735859,
        AWATER: 17991,
        INTPTLAT: '+41.4761665',
        INTPTLON: '-087.6278292',
        ELSDLEA: '37680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8608, 41.5576],
            [-87.7928, 41.5585],
            [-87.7903, 41.499],
            [-87.8501, 41.5284],
            [-87.8608, 41.5576],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738220',
        NAME: 'Summit Hill School District 161',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30744343,
        AWATER: 0,
        INTPTLAT: '+41.5316652',
        INTPTLON: '-087.8202422',
        ELSDLEA: '38220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6776, 41.7889],
            [-89.6841, 41.758],
            [-89.7632, 41.7576],
            [-89.6776, 41.7889],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734350',
        NAME: 'Rock Falls Elementary School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17369980,
        AWATER: 1594382,
        INTPTLAT: '+41.7668549',
        INTPTLON: '-089.7134809',
        ELSDLEA: '34350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1449, 41.9665],
            [-88.1091, 41.9609],
            [-88.09, 41.9444],
            [-88.0937, 41.9322],
            [-88.1018, 41.902],
            [-88.1454, 41.9011],
            [-88.1649, 41.9259],
            [-88.1449, 41.9665],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710470',
        NAME: 'Community Consolidated School District 93',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29649448,
        AWATER: 1482904,
        INTPTLAT: '+41.9333926',
        INTPTLON: '-088.1290431',
        ELSDLEA: '10470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0527, 41.8155],
            [-88.0435, 41.8318],
            [-88.0018, 41.8384],
            [-87.9768, 41.8461],
            [-87.9863, 41.822],
            [-87.9852, 41.8203],
            [-87.9859, 41.8192],
            [-87.9848, 41.788],
            [-87.9891, 41.766],
            [-88.0033, 41.7513],
            [-88.031, 41.7508],
            [-88.0515, 41.7793],
            [-88.0527, 41.8155],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712540',
        NAME: 'Downers Grove Grade School District 58',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43416103,
        AWATER: 486042,
        INTPTLAT: '+41.7971984',
        INTPTLON: '-088.0145460',
        ELSDLEA: '12540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.093, 41.9023],
            [-88.0718, 41.903],
            [-88.0326, 41.9042],
            [-88.0416, 41.8667],
            [-88.079, 41.8592],
            [-88.093, 41.9023],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716800',
        NAME: 'Glen Ellyn School District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19464450,
        AWATER: 423667,
        INTPTLAT: '+41.8849781',
        INTPTLON: '-088.0615883',
        ELSDLEA: '16800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0515, 41.7793],
            [-88.031, 41.7508],
            [-88.0306, 41.7289],
            [-88.069, 41.7281],
            [-88.09, 41.7827],
            [-88.0515, 41.7793],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717040',
        NAME: 'Woodridge School District 68',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19033886,
        AWATER: 429606,
        INTPTLAT: '+41.7529992',
        INTPTLON: '-088.0576772',
        ELSDLEA: '17040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9511, 41.7669],
            [-87.9361, 41.7817],
            [-87.9162, 41.7671],
            [-87.9149, 41.7311],
            [-87.9483, 41.7305],
            [-87.9595, 41.7444],
            [-87.9511, 41.7669],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717160',
        NAME: 'Gower School District 62',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13446476,
        AWATER: 456850,
        INTPTLAT: '+41.7509313',
        INTPTLON: '-087.9336572',
        ELSDLEA: '17160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6621, 40.6898],
            [-89.642, 40.6866],
            [-89.6422, 40.6556],
            [-89.6614, 40.663],
            [-89.6673, 40.6797],
            [-89.6621, 40.6898],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731860',
        NAME: 'Pleasant Hill School District 69',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5177065,
        AWATER: 0,
        INTPTLAT: '+40.6748187',
        INTPTLON: '-089.6531871',
        ELSDLEA: '31860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6893, 40.7399],
            [-89.642, 40.6866],
            [-89.6621, 40.6898],
            [-89.6807, 40.7108],
            [-89.6893, 40.7302],
            [-89.6893, 40.7399],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731950',
        NAME: 'Pleasant Valley School District 62',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18218944,
        AWATER: 34405,
        INTPTLAT: '+40.7140185',
        INTPTLON: '-089.6650197',
        ELSDLEA: '31950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2553, 41.5501],
            [-88.1418, 41.5512],
            [-88.1401, 41.5085],
            [-88.1398, 41.4938],
            [-88.167, 41.4643],
            [-88.1684, 41.4426],
            [-88.1966, 41.4637],
            [-88.2119, 41.5072],
            [-88.212, 41.5109],
            [-88.2289, 41.5286],
            [-88.2553, 41.5501],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739510',
        NAME: 'Troy Community Consolidated School District 30C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66411438,
        AWATER: 1366963,
        INTPTLAT: '+41.5054400',
        INTPTLON: '-088.1868237',
        ELSDLEA: '39510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6776, 41.7889],
            [-89.6028, 41.8145],
            [-89.5738, 41.7441],
            [-89.6031, 41.7296],
            [-89.6841, 41.758],
            [-89.6776, 41.7889],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701411',
        NAME: 'East Coloma-Nelson Consolidated Elementary School District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42494855,
        AWATER: 3581617,
        INTPTLAT: '+41.7696879',
        INTPTLON: '-089.6199700',
        ELSDLEA: '01411',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4626, 38.2965],
            [-88.3433, 38.3691],
            [-88.2127, 38.314],
            [-88.1691, 38.2706],
            [-88.192, 38.2566],
            [-88.2622, 38.1918],
            [-88.3708, 38.2256],
            [-88.4259, 38.2558],
            [-88.4626, 38.2965],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728110',
        NAME: 'New Hope Community Consolidated School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249849556,
        AWATER: 1255153,
        INTPTLAT: '+38.2969439',
        INTPTLON: '-088.3184025',
        ELSDLEA: '28110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2119, 38.3604],
            [-89.2118, 38.3676],
            [-89.221, 38.3753],
            [-89.2305, 38.3755],
            [-89.23, 38.3897],
            [-89.2279, 38.3896],
            [-89.2078, 38.3892],
            [-89.1455, 38.3874],
            [-89.1454, 38.3584],
            [-89.1273, 38.3004],
            [-89.1186, 38.3004],
            [-89.1164, 38.3004],
            [-89.0956, 38.3002],
            [-89.096, 38.272],
            [-89.1474, 38.2129],
            [-89.1623, 38.213],
            [-89.1841, 38.2134],
            [-89.1892, 38.2135],
            [-89.1929, 38.2135],
            [-89.259, 38.2139],
            [-89.257, 38.3611],
            [-89.2119, 38.3604],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704340',
        NAME: 'Ashley Community Consolidated School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214129923,
        AWATER: 252720,
        INTPTLAT: '+38.2968484',
        INTPTLON: '-089.1906783',
        ELSDLEA: '04340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8216, 38.3222],
            [-88.8124, 38.3222],
            [-88.8123, 38.3043],
            [-88.8075, 38.2992],
            [-88.7845, 38.2989],
            [-88.7819, 38.2989],
            [-88.7769, 38.2989],
            [-88.702, 38.2788],
            [-88.7024, 38.2567],
            [-88.7218, 38.2276],
            [-88.7046, 38.1252],
            [-88.7902, 38.1261],
            [-88.7995, 38.1262],
            [-88.8157, 38.1264],
            [-88.8153, 38.1556],
            [-88.8518, 38.2145],
            [-88.8546, 38.2994],
            [-88.8216, 38.3222],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705570',
        NAME: 'Opdyke-Belle Rive Community Consolidated School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193953508,
        AWATER: 1072244,
        INTPTLAT: '+38.2162191',
        INTPTLON: '-088.7761469',
        ELSDLEA: '05570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.929, 38.2999],
            [-88.8857, 38.2997],
            [-88.9133, 38.2634],
            [-88.929, 38.2999],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706180',
        NAME: 'Bethel School District 82',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13296866,
        AWATER: 50163,
        INTPTLAT: '+38.2826894',
        INTPTLON: '-088.9079193',
        ELSDLEA: '06180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8295, 38.3419],
            [-88.8118, 38.3542],
            [-88.8118, 38.3735],
            [-88.6998, 38.3865],
            [-88.702, 38.2788],
            [-88.7769, 38.2989],
            [-88.7819, 38.2989],
            [-88.7845, 38.2989],
            [-88.8075, 38.2992],
            [-88.8123, 38.3043],
            [-88.8124, 38.3222],
            [-88.8216, 38.3222],
            [-88.8295, 38.3419],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706630',
        NAME: 'Bluford Community Consolidated School District 114',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103603994,
        AWATER: 545980,
        INTPTLAT: '+38.3347583',
        INTPTLON: '-088.7576965',
        ELSDLEA: '06630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1427, 38.5393],
            [-89.0503, 38.5258],
            [-89.0577, 38.4742],
            [-89.1444, 38.4739],
            [-89.1442, 38.4812],
            [-89.1435, 38.514],
            [-89.1427, 38.5393],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709270',
        NAME: 'Centralia School District 135',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57314072,
        AWATER: 1555839,
        INTPTLAT: '+38.5099345',
        INTPTLON: '-089.1093420',
        ELSDLEA: '09270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9133, 38.2634],
            [-88.8857, 38.2997],
            [-88.8839, 38.2996],
            [-88.8546, 38.2994],
            [-88.8518, 38.2145],
            [-88.918, 38.2139],
            [-88.9133, 38.2634],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712390',
        NAME: 'Dodds Community Consolidated School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65508307,
        AWATER: 455667,
        INTPTLAT: '+38.2561460',
        INTPTLON: '-088.8637871',
        ELSDLEA: '12390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9954, 38.1251],
            [-88.9307, 38.1255],
            [-88.8527, 38.1266],
            [-88.8153, 38.1556],
            [-88.8157, 38.1264],
            [-88.7995, 38.1262],
            [-88.7902, 38.1261],
            [-88.7046, 38.1252],
            [-88.7227, 38.049],
            [-88.8538, 38.009],
            [-88.9839, 38.0376],
            [-88.9954, 38.1251],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714640',
        NAME: 'Ewing Northern Community Consolidated District 115',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199609844,
        AWATER: 43712553,
        INTPTLAT: '+38.0834593',
        INTPTLON: '-088.8530066',
        ELSDLEA: '14640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8116, 38.3881],
            [-88.8106, 38.4741],
            [-88.8106, 38.4758],
            [-88.8015, 38.4757],
            [-88.8002, 38.4757],
            [-88.7739, 38.4756],
            [-88.7325, 38.4754],
            [-88.7231, 38.4752],
            [-88.7186, 38.4751],
            [-88.7196, 38.4837],
            [-88.699, 38.4837],
            [-88.699, 38.4749],
            [-88.6998, 38.3865],
            [-88.8118, 38.3735],
            [-88.8118, 38.3844],
            [-88.8116, 38.3881],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714940',
        NAME: 'Farrington Community Consolidated School District 99',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102976619,
        AWATER: 219159,
        INTPTLAT: '+38.4281967',
        INTPTLON: '-088.7570471',
        ELSDLEA: '14940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1449, 38.445],
            [-89.1444, 38.4739],
            [-89.0577, 38.4742],
            [-89.0331, 38.474],
            [-89.0337, 38.4472],
            [-89.0337, 38.4453],
            [-89.0341, 38.3891],
            [-89.1455, 38.3874],
            [-89.1449, 38.445],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717190',
        NAME: 'Grand Prairie Community Consolidated School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92669121,
        AWATER: 291142,
        INTPTLAT: '+38.4301299',
        INTPTLON: '-089.0901156',
        ELSDLEA: '17190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.93, 38.2139],
            [-88.918, 38.2139],
            [-88.8518, 38.2145],
            [-88.8153, 38.1556],
            [-88.8527, 38.1266],
            [-88.9307, 38.1255],
            [-88.93, 38.2139],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720010',
        NAME: 'Ina Community Consolidated School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68869875,
        AWATER: 3574778,
        INTPTLAT: '+38.1620991',
        INTPTLON: '-088.8896365',
        ELSDLEA: '20010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9495, 38.5056],
            [-88.9411, 38.5058],
            [-88.8842, 38.5626],
            [-88.8461, 38.5623],
            [-88.81, 38.5051],
            [-88.7196, 38.4837],
            [-88.7186, 38.4751],
            [-88.7231, 38.4752],
            [-88.7325, 38.4754],
            [-88.7739, 38.4756],
            [-88.8002, 38.4757],
            [-88.8015, 38.4757],
            [-88.8106, 38.4758],
            [-88.9039, 38.4767],
            [-88.9072, 38.4768],
            [-88.9224, 38.477],
            [-88.9485, 38.4763],
            [-88.9653, 38.4759],
            [-88.9495, 38.5056],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720910',
        NAME: 'Kell Consolidated School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116916436,
        AWATER: 89853,
        INTPTLAT: '+38.4981510',
        INTPTLON: '-088.8416372',
        ELSDLEA: '20910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9897, 38.3],
            [-88.9798, 38.3],
            [-88.9643, 38.3],
            [-88.929, 38.2999],
            [-88.9133, 38.2634],
            [-88.918, 38.2139],
            [-88.93, 38.2139],
            [-88.9858, 38.2723],
            [-88.9897, 38.3],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725260',
        NAME: 'McClellan Community Consolidated School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47284832,
        AWATER: 98114,
        INTPTLAT: '+38.2649117',
        INTPTLON: '-088.9549224',
        ELSDLEA: '25260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9365, 38.3511],
            [-88.9056, 38.3517],
            [-88.887, 38.3333],
            [-88.8839, 38.2996],
            [-88.8857, 38.2997],
            [-88.929, 38.2999],
            [-88.9643, 38.3],
            [-88.9365, 38.3511],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727340',
        NAME: 'Mount Vernon School District 80',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31596933,
        AWATER: 136616,
        INTPTLAT: '+38.3213850',
        INTPTLON: '-088.9231735',
        ELSDLEA: '27340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0337, 38.4453],
            [-89.0337, 38.4472],
            [-89.0331, 38.474],
            [-88.9793, 38.4755],
            [-88.9653, 38.4759],
            [-88.9485, 38.4763],
            [-88.9224, 38.477],
            [-88.9056, 38.3517],
            [-88.9365, 38.3511],
            [-88.9992, 38.3873],
            [-89.0341, 38.3891],
            [-89.0337, 38.4453],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734590',
        NAME: 'Rome Community Consolidated School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111712746,
        AWATER: 236967,
        INTPTLAT: '+38.4194364',
        INTPTLON: '-088.9722295',
        ELSDLEA: '34590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8295, 38.3419],
            [-88.8216, 38.3222],
            [-88.8546, 38.2994],
            [-88.8839, 38.2996],
            [-88.887, 38.3333],
            [-88.8295, 38.3419],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738190',
        NAME: 'Summersville School District 79',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20357517,
        AWATER: 80069,
        INTPTLAT: '+38.3227809',
        INTPTLON: '-088.8589028',
        ELSDLEA: '38190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1455, 38.3874],
            [-89.0341, 38.3891],
            [-88.9992, 38.3873],
            [-88.9365, 38.3511],
            [-88.9643, 38.3],
            [-88.9798, 38.3],
            [-88.9897, 38.3],
            [-88.9858, 38.2723],
            [-89.0042, 38.2724],
            [-89.082, 38.2718],
            [-89.096, 38.272],
            [-89.0956, 38.3002],
            [-89.1164, 38.3004],
            [-89.1186, 38.3004],
            [-89.1273, 38.3004],
            [-89.1454, 38.3584],
            [-89.1455, 38.3874],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743170',
        NAME: 'Woodlawn Community Consolidated School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182237182,
        AWATER: 179984,
        INTPTLAT: '+38.3316182',
        INTPTLON: '-089.0371569',
        ELSDLEA: '43170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0416, 41.8667],
            [-88.0326, 41.9042],
            [-88.008, 41.9048],
            [-88.0018, 41.8384],
            [-88.0435, 41.8318],
            [-88.0416, 41.8667],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723460',
        NAME: 'Lombard School District 44',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20723144,
        AWATER: 280505,
        INTPTLAT: '+41.8658195',
        INTPTLON: '-088.0215649',
        ELSDLEA: '23460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9977, 41.7149],
            [-87.9532, 41.7155],
            [-87.9535, 41.7231],
            [-87.9483, 41.7305],
            [-87.9149, 41.7311],
            [-87.9143, 41.7167],
            [-87.9535, 41.6942],
            [-87.9977, 41.7149],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730510',
        NAME: 'Community Consolidated School District 180',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19028016,
        AWATER: 1384796,
        INTPTLAT: '+41.7051672',
        INTPTLON: '-087.9606967',
        ELSDLEA: '30510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9224, 38.477],
            [-88.9072, 38.4768],
            [-88.9039, 38.4767],
            [-88.8106, 38.4758],
            [-88.8106, 38.4741],
            [-88.8116, 38.3881],
            [-88.8118, 38.3844],
            [-88.8118, 38.3735],
            [-88.8118, 38.3542],
            [-88.8295, 38.3419],
            [-88.887, 38.3333],
            [-88.9056, 38.3517],
            [-88.9224, 38.477],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715090',
        NAME: 'Field Community Consolidated School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134039899,
        AWATER: 929022,
        INTPTLAT: '+38.4123858',
        INTPTLON: '-088.8576415',
        ELSDLEA: '15090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.148, 41.8789],
            [-88.1463, 41.8572],
            [-88.1657, 41.8569],
            [-88.1757, 41.8787],
            [-88.148, 41.8789],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742720',
        NAME: 'Winfield School District 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5607159,
        AWATER: 83197,
        INTPTLAT: '+41.8706021',
        INTPTLON: '-088.1599364',
        ELSDLEA: '42720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0699, 41.991],
            [-88.0314, 41.9925],
            [-88.0414, 41.9634],
            [-88.0641, 41.9696],
            [-88.0699, 41.991],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725500',
        NAME: 'Medinah School District 11',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9335569,
        AWATER: 259458,
        INTPTLAT: '+41.9772898',
        INTPTLON: '-088.0497565',
        ELSDLEA: '25500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0937, 41.9322],
            [-88.0718, 41.903],
            [-88.093, 41.9023],
            [-88.1018, 41.902],
            [-88.0937, 41.9322],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732970',
        NAME: 'Queen Bee School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7850271,
        AWATER: 173386,
        INTPTLAT: '+41.9146083',
        INTPTLON: '-088.0864719',
        ELSDLEA: '32970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9837, 41.7443],
            [-87.98, 41.7443],
            [-87.9595, 41.7444],
            [-87.9483, 41.7305],
            [-87.9535, 41.7231],
            [-87.9532, 41.7155],
            [-87.9977, 41.7149],
            [-87.9977, 41.7216],
            [-87.9977, 41.7219],
            [-87.9837, 41.7443],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708790',
        NAME: 'Cass School District 63',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10999283,
        AWATER: 352603,
        INTPTLAT: '+41.7293931',
        INTPTLON: '-087.9736961',
        ELSDLEA: '08790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6921, 40.5974],
            [-89.6891, 40.6235],
            [-89.6555, 40.6234],
            [-89.6428, 40.6234],
            [-89.6364, 40.6232],
            [-89.6588, 40.5892],
            [-89.6824, 40.5526],
            [-89.6888, 40.5585],
            [-89.6921, 40.5974],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719420',
        NAME: 'Hollis Consolidated School District 328',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20589991,
        AWATER: 1741743,
        INTPTLAT: '+40.5864704',
        INTPTLON: '-089.6691069',
        ELSDLEA: '19420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9848, 41.788],
            [-87.9751, 41.7882],
            [-87.9654, 41.7884],
            [-87.9361, 41.7817],
            [-87.9511, 41.7669],
            [-87.9743, 41.7664],
            [-87.9891, 41.766],
            [-87.9848, 41.788],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724000',
        NAME: 'Maercker School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8288209,
        AWATER: 333887,
        INTPTLAT: '+41.7784999',
        INTPTLON: '-087.9645608',
        ELSDLEA: '24000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9891, 41.766],
            [-87.9743, 41.7664],
            [-87.9511, 41.7669],
            [-87.9595, 41.7444],
            [-87.98, 41.7443],
            [-87.9837, 41.7443],
            [-88.0033, 41.7513],
            [-87.9891, 41.766],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721450',
        NAME: 'Darien School District 61',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8950813,
        AWATER: 61067,
        INTPTLAT: '+41.7564231',
        INTPTLON: '-087.9720516',
        ELSDLEA: '21450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0815, 41.5671],
            [-88.072, 41.582],
            [-88.045, 41.5827],
            [-88.0815, 41.5671],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738520',
        NAME: 'Taft School District 90',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3607455,
        AWATER: 213177,
        INTPTLAT: '+41.5761264',
        INTPTLON: '-088.0609422',
        ELSDLEA: '38520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6959, 40.6523],
            [-89.6647, 40.6541],
            [-89.6555, 40.6234],
            [-89.6891, 40.6235],
            [-89.6959, 40.6523],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729100',
        NAME: 'Oak Grove School District 68',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 12121636,
        AWATER: 0,
        INTPTLAT: '+40.6382096',
        INTPTLON: '-089.6769519',
        ELSDLEA: '29100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1945, 41.9217],
            [-88.1649, 41.9259],
            [-88.1454, 41.9011],
            [-88.1507, 41.8936],
            [-88.1945, 41.9217],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705880',
        NAME: 'Benjamin School District 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12936512,
        AWATER: 331356,
        INTPTLAT: '+41.9115850',
        INTPTLON: '-088.1724174',
        ELSDLEA: '05880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9594, 41.8756],
            [-87.9394, 41.8546],
            [-87.9768, 41.8461],
            [-87.9594, 41.8756],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735220',
        NAME: 'Salt Creek School District 48',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8411730,
        AWATER: 137157,
        INTPTLAT: '+41.8578274',
        INTPTLON: '-087.9637036',
        ELSDLEA: '35220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1009, 41.9899],
            [-88.0699, 41.991],
            [-88.0641, 41.9696],
            [-88.0909, 41.9759],
            [-88.1009, 41.9899],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734710',
        NAME: 'Roselle School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5773981,
        AWATER: 44389,
        INTPTLAT: '+41.9802803',
        INTPTLON: '-088.0833976',
        ELSDLEA: '34710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1451, 41.9882],
            [-88.1009, 41.9899],
            [-88.0909, 41.9759],
            [-88.1091, 41.9609],
            [-88.1449, 41.9665],
            [-88.1451, 41.9882],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720880',
        NAME: 'Keeneyville School District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9799298,
        AWATER: 377033,
        INTPTLAT: '+41.9772907',
        INTPTLON: '-088.1211674',
        ELSDLEA: '20880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.031, 41.7508],
            [-88.0033, 41.7513],
            [-87.9837, 41.7443],
            [-87.9977, 41.7219],
            [-87.9977, 41.7216],
            [-88.0303, 41.7221],
            [-88.0306, 41.7289],
            [-88.031, 41.7508],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708970',
        NAME: 'Center Cass School District 66',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11443779,
        AWATER: 430027,
        INTPTLAT: '+41.7358862',
        INTPTLON: '-088.0102123',
        ELSDLEA: '08970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.704, 40.6851],
            [-89.6673, 40.6797],
            [-89.6614, 40.663],
            [-89.6647, 40.6541],
            [-89.6959, 40.6523],
            [-89.704, 40.6851],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726490',
        NAME: 'Monroe School District 70',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10639487,
        AWATER: 0,
        INTPTLAT: '+40.6686233',
        INTPTLON: '-089.6832340',
        ELSDLEA: '26490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0235, 41.511],
            [-88.0042, 41.5041],
            [-88.0035, 41.4893],
            [-88.0032, 41.4825],
            [-88.0118, 41.4678],
            [-88.0221, 41.4677],
            [-88.0514, 41.4886],
            [-88.0235, 41.511],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739660',
        NAME: 'Union School District 81',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15339904,
        AWATER: 0,
        INTPTLAT: '+41.4917790',
        INTPTLON: '-088.0191291',
        ELSDLEA: '39660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0414, 41.9634],
            [-88.0314, 41.9925],
            [-88.0238, 41.9925],
            [-87.9972, 41.9927],
            [-87.9984, 41.9564],
            [-88.0333, 41.951],
            [-88.0417, 41.9532],
            [-88.0414, 41.9634],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720220',
        NAME: 'Itasca School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12065965,
        AWATER: 454213,
        INTPTLAT: '+41.9724607',
        INTPTLON: '-088.0160493',
        ELSDLEA: '20220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.09, 41.9444],
            [-88.0417, 41.9532],
            [-88.0333, 41.951],
            [-88.0326, 41.9042],
            [-88.0718, 41.903],
            [-88.0937, 41.9322],
            [-88.09, 41.9444],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724780',
        NAME: 'Marquardt School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16578468,
        AWATER: 578190,
        INTPTLAT: '+41.9270406',
        INTPTLON: '-088.0597118',
        ELSDLEA: '24780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1152, 41.5883],
            [-88.0704, 41.5876],
            [-88.072, 41.582],
            [-88.0815, 41.5671],
            [-88.077, 41.5532],
            [-88.1102, 41.5521],
            [-88.1152, 41.5883],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709510',
        NAME: 'Chaney-Monge School District 88',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11552071,
        AWATER: 187777,
        INTPTLAT: '+41.5743936',
        INTPTLON: '-088.0899929',
        ELSDLEA: '09510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6807, 40.7108],
            [-89.6621, 40.6898],
            [-89.6673, 40.6797],
            [-89.704, 40.6851],
            [-89.6807, 40.7108],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729040',
        NAME: 'Norwood Elementary School District 63',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12685373,
        AWATER: 0,
        INTPTLAT: '+40.6982098',
        INTPTLON: '-089.6946777',
        ELSDLEA: '29040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0245, 42.3858],
            [-88.021, 42.4003],
            [-88.004, 42.3966],
            [-88.0041, 42.3931],
            [-87.9335, 42.4146],
            [-87.8851, 42.3565],
            [-87.8855, 42.3417],
            [-87.8852, 42.3263],
            [-87.9443, 42.3273],
            [-88.0047, 42.3274],
            [-88.0238, 42.3275],
            [-88.0042, 42.3606],
            [-88.0041, 42.3678],
            [-88.0245, 42.3858],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743110',
        NAME: 'Woodland Community Consolidated School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76320038,
        AWATER: 1804376,
        INTPTLAT: '+42.3643639',
        INTPTLON: '-087.9608707',
        ELSDLEA: '43110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9249, 42.2698],
            [-87.9249, 42.2837],
            [-87.89, 42.2834],
            [-87.8855, 42.2798],
            [-87.8859, 42.2473],
            [-87.886, 42.2429],
            [-87.8923, 42.2401],
            [-87.8961, 42.2401],
            [-87.9009, 42.238],
            [-87.8995, 42.2401],
            [-87.9106, 42.2401],
            [-87.9249, 42.2698],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734620',
        NAME: 'Rondout School District 72',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14422297,
        AWATER: 297184,
        INTPTLAT: '+42.2570414',
        INTPTLON: '-087.9016639',
        ELSDLEA: '34620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8529, 42.4932],
            [-87.8032, 42.4926],
            [-87.7979, 42.4641],
            [-87.8523, 42.4682],
            [-87.8529, 42.4932],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742900',
        NAME: 'Winthrop Harbor School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12310078,
        AWATER: 90463,
        INTPTLAT: '+42.4796734',
        INTPTLON: '-087.8228489',
        ELSDLEA: '42900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.355, 42.4946],
            [-88.3047, 42.4946],
            [-88.28, 42.4948],
            [-88.2654, 42.4947],
            [-88.1995, 42.4951],
            [-88.199, 42.4591],
            [-88.1989, 42.4447],
            [-88.1988, 42.4156],
            [-88.3281, 42.4146],
            [-88.3411, 42.4143],
            [-88.3547, 42.4147],
            [-88.355, 42.4946],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700222',
        NAME: 'Nippersink School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112936905,
        AWATER: 669707,
        INTPTLAT: '+42.4546502',
        INTPTLON: '-088.2767482',
        ELSDLEA: '00222',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8523, 42.4682],
            [-87.7979, 42.4641],
            [-87.8035, 42.4355],
            [-87.8523, 42.4682],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743860',
        NAME: 'Zion Elementary School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16278591,
        AWATER: 238443,
        INTPTLAT: '+42.4521347',
        INTPTLON: '-087.8309630',
        ELSDLEA: '43860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.838, 41.2068],
            [-87.8424, 41.1773],
            [-87.8956, 41.1477],
            [-87.9912, 41.2053],
            [-87.838, 41.2068],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706750',
        NAME: 'Bourbonnais School District 53',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56707453,
        AWATER: 844592,
        INTPTLAT: '+41.1809634',
        INTPTLON: '-087.8994468',
        ELSDLEA: '06750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8424, 41.1773],
            [-87.8287, 41.1558],
            [-87.8945, 41.1451],
            [-87.8956, 41.1477],
            [-87.8424, 41.1773],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706930',
        NAME: 'Bradley School District 61',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12786218,
        AWATER: 102644,
        INTPTLAT: '+41.1508661',
        INTPTLON: '-087.8561950',
        ELSDLEA: '06930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9186, 41.8255],
            [-87.9186, 41.8264],
            [-87.9003, 41.8264],
            [-87.8887, 41.8028],
            [-87.9069, 41.7896],
            [-87.9186, 41.8255],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741820',
        NAME: 'Western Springs School District 101',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6963625,
        AWATER: 254,
        INTPTLAT: '+41.8113839',
        INTPTLON: '-087.9019290',
        ELSDLEA: '41820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0045, 42.1536],
            [-87.9654, 42.1535],
            [-87.9099, 42.1531],
            [-87.8873, 42.153],
            [-87.8878, 42.1237],
            [-87.8881, 42.0948],
            [-87.9271, 42.0989],
            [-87.9659, 42.1245],
            [-88.0047, 42.1317],
            [-88.0045, 42.1536],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742210',
        NAME: 'Wheeling Community Consolidated School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40202835,
        AWATER: 164920,
        INTPTLAT: '+42.1317570',
        INTPTLON: '-087.9344309',
        ELSDLEA: '42210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9143, 41.7167],
            [-87.8865, 41.7316],
            [-87.8765, 41.7393],
            [-87.8485, 41.768],
            [-87.8484, 41.7616],
            [-87.8475, 41.7326],
            [-87.857, 41.7324],
            [-87.8558, 41.6959],
            [-87.9135, 41.6875],
            [-87.9143, 41.7167],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742570',
        NAME: 'Willow Springs School District 108',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24570400,
        AWATER: 2685304,
        INTPTLAT: '+41.7103760',
        INTPTLON: '-087.8859560',
        ELSDLEA: '42570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7714, 42.0725],
            [-87.7245, 42.0878],
            [-87.7028, 42.0897],
            [-87.6796, 42.0717],
            [-87.7324, 42.0647],
            [-87.766, 42.0639],
            [-87.7715, 42.0651],
            [-87.7714, 42.0725],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742600',
        NAME: 'Wilmette School District 39',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12366173,
        AWATER: 23599,
        INTPTLAT: '+42.0757565',
        INTPTLON: '-087.7241244',
        ELSDLEA: '42600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7704, 42.1197],
            [-87.7411, 42.1275],
            [-87.7108, 42.0954],
            [-87.7267, 42.0936],
            [-87.7707, 42.1086],
            [-87.7704, 42.1197],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742840',
        NAME: 'Winnetka School District 36',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8956310,
        AWATER: 207366,
        INTPTLAT: '+42.1077006',
        INTPTLON: '-087.7401014',
        ELSDLEA: '42840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8072, 41.697],
            [-87.7979, 41.7007],
            [-87.7981, 41.7044],
            [-87.7899, 41.7049],
            [-87.769, 41.6905],
            [-87.7782, 41.6756],
            [-87.7969, 41.6753],
            [-87.7972, 41.6826],
            [-87.8072, 41.697],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743380',
        NAME: 'Worth School District 127',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6395956,
        AWATER: 79724,
        INTPTLAT: '+41.6892273',
        INTPTLON: '-087.7899980',
        ELSDLEA: '43380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6569, 41.0949],
            [-87.5266, 41.1215],
            [-87.5263, 41.0027],
            [-87.5612, 40.9807],
            [-87.6412, 41.008],
            [-87.6569, 41.0949],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731140',
        NAME: 'Pembroke Consolidated Community School District 259',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116528443,
        AWATER: 0,
        INTPTLAT: '+41.0535321',
        INTPTLON: '-087.5829552',
        ELSDLEA: '31140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8438, 41.0482],
            [-87.7332, 41.0641],
            [-87.7393, 41.12],
            [-87.6569, 41.0949],
            [-87.6412, 41.008],
            [-87.5612, 40.9807],
            [-87.5993, 40.9798],
            [-87.6186, 40.9864],
            [-87.8117, 40.9398],
            [-87.8438, 41.0482],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737120',
        NAME: 'St. Anne Consolidated Community School District 256',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230143465,
        AWATER: 1059283,
        INTPTLAT: '+41.0175062',
        INTPTLON: '-087.7208730',
        ELSDLEA: '37120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4731, 38.4071],
            [-88.3728, 38.3919],
            [-88.2221, 38.4011],
            [-88.1659, 38.4447],
            [-88.2127, 38.314],
            [-88.3433, 38.3691],
            [-88.4626, 38.2965],
            [-88.4731, 38.4071],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714710',
        NAME: 'Fairfield Public School District 112',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168137622,
        AWATER: 523254,
        INTPTLAT: '+38.3618468',
        INTPTLON: '-088.3244945',
        ELSDLEA: '14710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8424, 41.1773],
            [-87.838, 41.2068],
            [-87.7456, 41.2075],
            [-87.7789, 41.1558],
            [-87.8287, 41.1558],
            [-87.8424, 41.1773],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737320',
        NAME: 'St. George Community Consolidated School District 258',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42705113,
        AWATER: 0,
        INTPTLAT: '+41.1819850',
        INTPTLON: '-087.7921046',
        ELSDLEA: '37320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4722, 38.4287],
            [-88.3384, 38.4681],
            [-88.3728, 38.3919],
            [-88.4731, 38.4071],
            [-88.4722, 38.4287],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716320',
        NAME: 'Geff Community Consolidated School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71641735,
        AWATER: 0,
        INTPTLAT: '+38.4273807',
        INTPTLON: '-088.4077320',
        ELSDLEA: '16320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3728, 38.3919],
            [-88.3384, 38.4681],
            [-88.2221, 38.4011],
            [-88.3728, 38.3919],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720370',
        NAME: 'Jasper Community Consolidated School District 17',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80542701,
        AWATER: 211991,
        INTPTLAT: '+38.4178472',
        INTPTLON: '-088.3085277',
        ELSDLEA: '20370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0361, 38.5295],
            [-90.0359, 38.572],
            [-90.002, 38.5712],
            [-90.0019, 38.5431],
            [-89.9678, 38.5381],
            [-89.9476, 38.5273],
            [-89.9625, 38.4835],
            [-89.9946, 38.4538],
            [-90.0141, 38.4464],
            [-90.014, 38.4538],
            [-90.0198, 38.4539],
            [-90.0361, 38.5295],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705610',
        NAME: 'Belleville School District 118',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63796893,
        AWATER: 986743,
        INTPTLAT: '+38.5049118',
        INTPTLON: '-090.0072397',
        ELSDLEA: '05610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0141, 38.4464],
            [-89.9946, 38.4538],
            [-89.9625, 38.4835],
            [-89.9452, 38.4836],
            [-89.9428, 38.4836],
            [-89.9307, 38.4836],
            [-89.9286, 38.4836],
            [-89.7811, 38.394],
            [-89.772, 38.3498],
            [-89.9643, 38.3589],
            [-90.0364, 38.418],
            [-90.0408, 38.4182],
            [-90.0141, 38.4464],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715820',
        NAME: 'Freeburg Community Consolidated School District 70',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183469530,
        AWATER: 6326172,
        INTPTLAT: '+38.4142826',
        INTPTLON: '-089.8969075',
        ELSDLEA: '15820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0019, 38.5431],
            [-89.9698, 38.5397],
            [-89.9678, 38.5381],
            [-90.0019, 38.5431],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718960',
        NAME: 'High Mount School District 116',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4029342,
        AWATER: 59236,
        INTPTLAT: '+38.5378481',
        INTPTLON: '-089.9910121',
        ELSDLEA: '18960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0373, 38.6232],
            [-89.9851, 38.6232],
            [-89.9893, 38.6072],
            [-89.9939, 38.5715],
            [-90.002, 38.5712],
            [-90.0359, 38.572],
            [-90.0373, 38.6232],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717310',
        NAME: 'Grant Community Consolidated School District 110',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23261659,
        AWATER: 126543,
        INTPTLAT: '+38.5982895',
        INTPTLON: '-090.0142023',
        ELSDLEA: '17310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.092, 38.5646],
            [-90.0465, 38.5721],
            [-90.0359, 38.572],
            [-90.0361, 38.5295],
            [-90.0975, 38.5536],
            [-90.092, 38.5646],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714220',
        NAME: 'Harmony Emge School District 175',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17615621,
        AWATER: 38332,
        INTPTLAT: '+38.5500867',
        INTPTLON: '-090.0636955',
        ELSDLEA: '14220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9476, 38.5273],
            [-89.9287, 38.5262],
            [-89.9286, 38.4836],
            [-89.9307, 38.4836],
            [-89.9428, 38.4836],
            [-89.9452, 38.4836],
            [-89.9625, 38.4835],
            [-89.9476, 38.5273],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705580',
        NAME: 'Belle Valley School District 119',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15052992,
        AWATER: 64169,
        INTPTLAT: '+38.5037738',
        INTPTLON: '-089.9457430',
        ELSDLEA: '05580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9663, 38.6023],
            [-89.9291, 38.5706],
            [-89.9473, 38.5706],
            [-89.9499, 38.5707],
            [-89.9661, 38.5708],
            [-89.9662, 38.5786],
            [-89.9662, 38.5847],
            [-89.9662, 38.5865],
            [-89.9662, 38.5927],
            [-89.9663, 38.6023],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709170',
        NAME: 'Central School District 104',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12649398,
        AWATER: 62400,
        INTPTLAT: '+38.5881908',
        INTPTLON: '-089.9466993',
        ELSDLEA: '09170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2753, 37.4955],
            [-89.2293, 37.507],
            [-89.2265, 37.5035],
            [-89.0443, 37.4642],
            [-89.0446, 37.4196],
            [-89.0446, 37.406],
            [-89.156, 37.4376],
            [-89.2189, 37.3898],
            [-89.2753, 37.4955],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703750',
        NAME: 'Anna Community Consolidated School District 37',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133010385,
        AWATER: 1208630,
        INTPTLAT: '+37.4521638',
        INTPTLON: '-089.1722751',
        ELSDLEA: '03750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0446, 37.406],
            [-89.0446, 37.4196],
            [-88.9848, 37.4211],
            [-88.8877, 37.3356],
            [-88.9291, 37.3026],
            [-89.0628, 37.3308],
            [-89.0446, 37.406],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711520',
        NAME: 'Cypress School District 64',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153142642,
        AWATER: 1175237,
        INTPTLAT: '+37.3623478',
        INTPTLON: '-088.9774278',
        ELSDLEA: '11520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3281, 37.4947],
            [-89.2753, 37.4955],
            [-89.2189, 37.3898],
            [-89.2484, 37.3351],
            [-89.3208, 37.3347],
            [-89.3281, 37.4947],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720640',
        NAME: 'Jonesboro Community Consolidated School District 43',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114844784,
        AWATER: 424934,
        INTPTLAT: '+37.3876150',
        INTPTLON: '-089.3026076',
        ELSDLEA: '20640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2265, 37.5035],
            [-89.1537, 37.6005],
            [-89.0782, 37.5974],
            [-89.051, 37.5968],
            [-89.043, 37.5077],
            [-89.044, 37.4786],
            [-89.0441, 37.475],
            [-89.0443, 37.4642],
            [-89.2265, 37.5035],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722860',
        NAME: 'Lick Creek Community Consolidated School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157610162,
        AWATER: 1166368,
        INTPTLAT: '+37.5270747',
        INTPTLON: '-089.1231643',
        ELSDLEA: '22860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.044, 37.4786],
            [-89.043, 37.5077],
            [-88.9288, 37.4903],
            [-88.9848, 37.4211],
            [-89.0446, 37.4196],
            [-89.0443, 37.4642],
            [-89.0441, 37.475],
            [-89.044, 37.4786],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707740',
        NAME: 'Buncombe Consolidated School District 43',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87701856,
        AWATER: 1097367,
        INTPTLAT: '+37.4492833',
        INTPTLON: '-088.9875832',
        ELSDLEA: '07740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.769, 41.6905],
            [-87.7595, 41.6979],
            [-87.7303, 41.6984],
            [-87.7301, 41.6911],
            [-87.7201, 41.684],
            [-87.7199, 41.6694],
            [-87.7581, 41.6614],
            [-87.7782, 41.6756],
            [-87.769, 41.6905],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703480',
        NAME: 'Alsip-Hazelgreen-Oaklawn School District 126',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13008645,
        AWATER: 141067,
        INTPTLAT: '+41.6789736',
        INTPTLON: '-087.7447750',
        ELSDLEA: '03480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7752, 41.5952],
            [-87.7665, 41.6101],
            [-87.7267, 41.6106],
            [-87.7333, 41.5716],
            [-87.7644, 41.566],
            [-87.7752, 41.5952],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703930',
        NAME: 'Arbor Park School District 145',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12788597,
        AWATER: 153356,
        INTPTLAT: '+41.5915700',
        INTPTLON: '-087.7500959',
        ELSDLEA: '03930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8485, 41.768],
            [-87.8261, 41.7916],
            [-87.8176, 41.7985],
            [-87.802, 41.8064],
            [-87.7821, 41.8063],
            [-87.782, 41.8044],
            [-87.782, 41.7997],
            [-87.7969, 41.7996],
            [-87.8021, 41.7978],
            [-87.8008, 41.7737],
            [-87.8007, 41.7705],
            [-87.8003, 41.7627],
            [-87.8484, 41.7616],
            [-87.8485, 41.768],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704050',
        NAME: 'Summit School District 104',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13005786,
        AWATER: 777619,
        INTPTLAT: '+41.7811120',
        INTPTLON: '-087.8151353',
        ELSDLEA: '04050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0047, 42.0956],
            [-88.0047, 42.1317],
            [-87.9659, 42.1245],
            [-87.9368, 42.088],
            [-87.9368, 42.0808],
            [-87.9701, 42.0589],
            [-87.9994, 42.0666],
            [-88.0047, 42.0956],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704140',
        NAME: 'Arlington Heights School District 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26244860,
        AWATER: 41892,
        INTPTLAT: '+42.0923863',
        INTPTLON: '-087.9816727',
        ELSDLEA: '04140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.7201, 41.684],
              [-87.6864, 41.6772],
              [-87.7199, 41.6694],
              [-87.7201, 41.684],
            ],
          ],
          [
            [
              [-87.7303, 41.6984],
              [-87.7206, 41.6986],
              [-87.7301, 41.6911],
              [-87.7303, 41.6984],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704560',
        NAME: 'Atwood Heights School District 125',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4875477,
        AWATER: 0,
        INTPTLAT: '+41.6804834',
        INTPTLON: '-087.6912439',
        ELSDLEA: '04560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7707, 42.0941],
            [-87.7707, 42.1086],
            [-87.7267, 42.0936],
            [-87.7245, 42.0878],
            [-87.7714, 42.0725],
            [-87.7707, 42.0941],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704800',
        NAME: 'Avoca School District 37',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8648953,
        AWATER: 98380,
        INTPTLAT: '+42.0895105',
        INTPTLON: '-087.7583568',
        ELSDLEA: '04800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0664, 42.0578],
            [-88.9616, 42.0649],
            [-88.8879, 42.0149],
            [-88.9167, 41.9791],
            [-89.0204, 41.9781],
            [-89.0393, 41.9779],
            [-89.0664, 42.0578],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714410',
        NAME: 'Eswood Community Consolidated District 269',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112397410,
        AWATER: 52054,
        INTPTLAT: '+42.0265856',
        INTPTLON: '-088.9745592',
        ELSDLEA: '14410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8538, 38.009],
            [-88.7227, 38.049],
            [-88.7065, 37.951],
            [-88.8221, 37.9507],
            [-88.8538, 38.009],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703210',
        NAME: 'Akin Community Consolidated School District 91',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125468716,
        AWATER: 458658,
        INTPTLAT: '+38.0007445',
        INTPTLON: '-088.7689720',
        ELSDLEA: '03210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9839, 38.0376],
            [-88.8538, 38.009],
            [-88.8221, 37.9507],
            [-88.8478, 37.9507],
            [-88.9841, 37.9501],
            [-89.002, 37.9501],
            [-88.9962, 38.0014],
            [-88.9839, 38.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705950',
        NAME: 'Benton Community Consolidated School District 47',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126689024,
        AWATER: 2653246,
        INTPTLAT: '+37.9948681',
        INTPTLON: '-088.9233355',
        ELSDLEA: '05950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.144, 38.4846],
            [-90.1343, 38.5322],
            [-90.0975, 38.5536],
            [-90.0361, 38.5295],
            [-90.0198, 38.4539],
            [-90.014, 38.4538],
            [-90.0141, 38.4464],
            [-90.0408, 38.4182],
            [-90.0364, 38.418],
            [-90.0728, 38.3965],
            [-90.0734, 38.3967],
            [-90.1289, 38.3976],
            [-90.144, 38.4846],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726190',
        NAME: 'Millstadt Consolidated Community School District 160',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144247572,
        AWATER: 3003357,
        INTPTLAT: '+38.4746500',
        INTPTLON: '-090.0863805',
        ELSDLEA: '26190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9893, 38.6072],
            [-89.9851, 38.6232],
            [-89.9284, 38.6577],
            [-89.8434, 38.6561],
            [-89.825, 38.5689],
            [-89.8739, 38.5697],
            [-89.9291, 38.5706],
            [-89.9663, 38.6023],
            [-89.9717, 38.6071],
            [-89.9813, 38.6071],
            [-89.9893, 38.6072],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729760',
        NAME: "O'Fallon Community Consolidated School District 90",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97726050,
        AWATER: 1560091,
        INTPTLAT: '+38.6218715',
        INTPTLON: '-089.9019550',
        ELSDLEA: '29760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2662, 37.7747],
            [-89.1514, 37.8099],
            [-89.1515, 37.8037],
            [-89.1524, 37.7125],
            [-89.19, 37.6974],
            [-89.282, 37.6984],
            [-89.2662, 37.7747],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708340',
        NAME: 'Carbondale Elementary School District 95',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94598544,
        AWATER: 1587752,
        INTPTLAT: '+37.7478765',
        INTPTLON: '-089.2090139',
        ELSDLEA: '08340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.19, 37.6974],
            [-89.1524, 37.7125],
            [-89.0783, 37.6861],
            [-89.0782, 37.5974],
            [-89.1537, 37.6005],
            [-89.1946, 37.5997],
            [-89.19, 37.6974],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716590',
        NAME: 'Giant City Community Consolidated School District 130',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90503531,
        AWATER: 6644713,
        INTPTLAT: '+37.6525058',
        INTPTLON: '-089.1424964',
        ELSDLEA: '16590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2653, 37.8202],
            [-89.1799, 37.8481],
            [-89.1514, 37.8099],
            [-89.2662, 37.7747],
            [-89.2653, 37.8202],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712150',
        NAME: 'De Soto Consolidated School District 86',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60173880,
        AWATER: 1993176,
        INTPTLAT: '+37.8132023',
        INTPTLON: '-089.2200765',
        ELSDLEA: '12150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.282, 37.6984],
            [-89.19, 37.6974],
            [-89.1946, 37.5997],
            [-89.2778, 37.6285],
            [-89.282, 37.6984],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739930',
        NAME: 'Unity Point Community Consolidated School District 140',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91002519,
        AWATER: 4378820,
        INTPTLAT: '+37.6525505',
        INTPTLON: '-089.2359627',
        ELSDLEA: '39930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8838, 41.9076],
            [-87.8704, 41.9078],
            [-87.8644, 41.9079],
            [-87.8579, 41.8639],
            [-87.8662, 41.8637],
            [-87.8828, 41.8709],
            [-87.8929, 41.8776],
            [-87.8838, 41.9076],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705760',
        NAME: 'Bellwood School District 88',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8618097,
        AWATER: 0,
        INTPTLAT: '+41.8865681',
        INTPTLON: '-087.8729920',
        ELSDLEA: '05760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9204, 41.9068],
            [-87.9204, 41.914],
            [-87.8838, 41.9076],
            [-87.8929, 41.8776],
            [-87.9205, 41.8769],
            [-87.9204, 41.9068],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706000',
        NAME: 'Berkeley School District 87',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11931940,
        AWATER: 0,
        INTPTLAT: '+41.8964759',
        INTPTLON: '-087.9028510',
        ELSDLEA: '06000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8038, 41.8504],
            [-87.7786, 41.8508],
            [-87.7778, 41.8215],
            [-87.7827, 41.8215],
            [-87.8026, 41.8212],
            [-87.8038, 41.8504],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706090',
        NAME: 'Berwyn South School District 100',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6735920,
        AWATER: 0,
        INTPTLAT: '+41.8360260',
        INTPTLON: '-087.7906877',
        ELSDLEA: '06090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.777, 41.6465],
            [-87.7581, 41.6614],
            [-87.7199, 41.6694],
            [-87.6864, 41.6772],
            [-87.671, 41.6774],
            [-87.6702, 41.6482],
            [-87.6797, 41.6482],
            [-87.7186, 41.6475],
            [-87.7378, 41.6407],
            [-87.7768, 41.6403],
            [-87.777, 41.6465],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706510',
        NAME: 'Cook County School District 130',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19089912,
        AWATER: 576031,
        INTPTLAT: '+41.6567141',
        INTPTLON: '-087.7158388',
        ELSDLEA: '06510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8484, 41.7616],
            [-87.8003, 41.7627],
            [-87.7991, 41.7335],
            [-87.8475, 41.7326],
            [-87.8484, 41.7616],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707170',
        NAME: 'Indian Springs School District 109',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13042289,
        AWATER: 60646,
        INTPTLAT: '+41.7534009',
        INTPTLON: '-087.8269406',
        ELSDLEA: '07170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0303, 41.7221],
            [-87.9977, 41.7216],
            [-87.9977, 41.7149],
            [-87.9535, 41.6942],
            [-87.9143, 41.7167],
            [-87.9135, 41.6875],
            [-87.9119, 41.6439],
            [-87.9591, 41.643],
            [-88.0288, 41.6698],
            [-88.0303, 41.7221],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707290',
        NAME: 'Lemont-Bromberek Combined School District 113A',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62029789,
        AWATER: 1845503,
        INTPTLAT: '+41.6739756',
        INTPTLON: '-087.9725592',
        ELSDLEA: '07290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.671, 41.6774],
            [-87.647, 41.657],
            [-87.6367, 41.6574],
            [-87.6472, 41.6487],
            [-87.6513, 41.6486],
            [-87.6696, 41.6482],
            [-87.6702, 41.6482],
            [-87.671, 41.6774],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708130',
        NAME: 'Calumet Public School District 132',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5907430,
        AWATER: 239047,
        INTPTLAT: '+41.6603137',
        INTPTLON: '-087.6577657',
        ELSDLEA: '08130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6745, 41.5206],
            [-87.6598, 41.5206],
            [-87.6512, 41.5279],
            [-87.6458, 41.5279],
            [-87.6406, 41.5279],
            [-87.6164, 41.5208],
            [-87.6162, 41.4987],
            [-87.6693, 41.4843],
            [-87.6743, 41.499],
            [-87.6745, 41.5206],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709960',
        NAME: 'Chicago Heights School District 170',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18764415,
        AWATER: 142551,
        INTPTLAT: '+41.5044946',
        INTPTLON: '-087.6438187',
        ELSDLEA: '09960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7899, 41.7049],
            [-87.7598, 41.7053],
            [-87.7595, 41.6979],
            [-87.769, 41.6905],
            [-87.7899, 41.7049],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709990',
        NAME: 'Chicago Ridge School District 127-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3656731,
        AWATER: 59,
        INTPTLAT: '+41.6986615',
        INTPTLON: '-087.7724456',
        ELSDLEA: '09990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.779, 41.8654],
            [-87.7741, 41.8655],
            [-87.7449, 41.822],
            [-87.7778, 41.8215],
            [-87.7786, 41.8508],
            [-87.779, 41.8654],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710200',
        NAME: 'Cicero School District 99',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15857075,
        AWATER: 0,
        INTPTLAT: '+41.8437411',
        INTPTLON: '-087.7588372',
        ELSDLEA: '10200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5593, 41.6445],
            [-87.525, 41.6446],
            [-87.5251, 41.6302],
            [-87.5593, 41.6373],
            [-87.5593, 41.6445],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707860',
        NAME: 'Burnham School District 154-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3210998,
        AWATER: 140553,
        INTPTLAT: '+41.6349743',
        INTPTLON: '-087.5348725',
        ELSDLEA: '07860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5591, 41.6154],
            [-87.5251, 41.6155],
            [-87.5253, 41.6011],
            [-87.5591, 41.6081],
            [-87.5591, 41.6154],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708100',
        NAME: 'Lincoln Elementary School District 156',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3208912,
        AWATER: 108623,
        INTPTLAT: '+41.6098568',
        INTPTLON: '-087.5392406',
        ELSDLEA: '08100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8042, 41.865],
            [-87.779, 41.8654],
            [-87.7786, 41.8508],
            [-87.8038, 41.8504],
            [-87.8042, 41.865],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706060',
        NAME: 'Berwyn North School District 98',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3376565,
        AWATER: 0,
        INTPTLAT: '+41.8579089',
        INTPTLON: '-087.7914008',
        ELSDLEA: '06060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8619, 41.8419],
            [-87.857, 41.842],
            [-87.8423, 41.8425],
            [-87.8415, 41.8205],
            [-87.8503, 41.8203],
            [-87.8614, 41.831],
            [-87.8619, 41.8419],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707320',
        NAME: 'Brookfield School District 95',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3941180,
        AWATER: 0,
        INTPTLAT: '+41.8312151',
        INTPTLON: '-087.8516623',
        ELSDLEA: '07320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.593, 38.0731],
            [-89.5924, 38.1394],
            [-89.5928, 38.2193],
            [-89.507, 38.2173],
            [-89.5016, 38.2172],
            [-89.4394, 38.2153],
            [-89.4346, 38.2152],
            [-89.4262, 38.2152],
            [-89.3892, 38.2146],
            [-89.3422, 38.2138],
            [-89.3223, 38.2137],
            [-89.371, 38.1271],
            [-89.3447, 38.0391],
            [-89.3546, 37.9519],
            [-89.4277, 37.9532],
            [-89.4826, 38.0415],
            [-89.593, 38.0731],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700009',
        NAME: 'Pinckneyville School District 50',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486566480,
        AWATER: 4517879,
        INTPTLAT: '+38.1176877',
        INTPTLON: '-089.4698566',
        ELSDLEA: '00009',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3206, 38.5106],
            [-89.2549, 38.509],
            [-89.2456, 38.5081],
            [-89.2091, 38.5031],
            [-89.2096, 38.4915],
            [-89.2078, 38.3892],
            [-89.2279, 38.3896],
            [-89.2278, 38.3918],
            [-89.2236, 38.3918],
            [-89.2247, 38.3945],
            [-89.2303, 38.3897],
            [-89.2342, 38.3898],
            [-89.3223, 38.3754],
            [-89.3206, 38.5106],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719740',
        NAME: 'Hoyleton Consolidated School District 29',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156072766,
        AWATER: 12444,
        INTPTLAT: '+38.4426262',
        INTPTLON: '-089.2702419',
        ELSDLEA: '19740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2096, 38.4915],
            [-89.1442, 38.4812],
            [-89.1444, 38.4739],
            [-89.1449, 38.445],
            [-89.1455, 38.3874],
            [-89.2078, 38.3892],
            [-89.2096, 38.4915],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720190',
        NAME: 'Irvington County Community Unit School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52723262,
        AWATER: 166286,
        INTPTLAT: '+38.4349622',
        INTPTLON: '-089.1762004',
        ELSDLEA: '20190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3889, 38.4943],
            [-89.3206, 38.5106],
            [-89.3223, 38.3754],
            [-89.2342, 38.3898],
            [-89.2303, 38.3897],
            [-89.2247, 38.3945],
            [-89.2236, 38.3918],
            [-89.2278, 38.3918],
            [-89.2279, 38.3896],
            [-89.23, 38.3897],
            [-89.2305, 38.3755],
            [-89.221, 38.3753],
            [-89.2118, 38.3676],
            [-89.2119, 38.3604],
            [-89.257, 38.3611],
            [-89.259, 38.2139],
            [-89.3223, 38.2137],
            [-89.3422, 38.2138],
            [-89.3892, 38.2146],
            [-89.4262, 38.2152],
            [-89.4812, 38.3189],
            [-89.3889, 38.4943],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727720',
        NAME: 'Nashville Consolidated Community School District 49',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412615358,
        AWATER: 1984136,
        INTPTLAT: '+38.3376602',
        INTPTLON: '-089.3585466',
        ELSDLEA: '27720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7644, 41.566],
            [-87.7333, 41.5716],
            [-87.7138, 41.5643],
            [-87.7136, 41.5571],
            [-87.7133, 41.5279],
            [-87.7325, 41.528],
            [-87.7524, 41.557],
            [-87.7643, 41.5589],
            [-87.7644, 41.566],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711010',
        NAME: 'Country Club Hills School District 160',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15368509,
        AWATER: 102416,
        INTPTLAT: '+41.5508767',
        INTPTLON: '-087.7340158',
        ELSDLEA: '11010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9214, 42.0662],
            [-87.9077, 42.0661],
            [-87.9007, 42.0661],
            [-87.8883, 42.066],
            [-87.8648, 42.0659],
            [-87.86, 42.0393],
            [-87.8594, 41.9958],
            [-87.8801, 41.9952],
            [-87.9205, 42.0051],
            [-87.9212, 42.0488],
            [-87.9214, 42.0662],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712120',
        NAME: 'Des Plaines Community Consolidated School District 62',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33546973,
        AWATER: 469130,
        INTPTLAT: '+42.0329150',
        INTPTLON: '-087.8912651',
        ELSDLEA: '12120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5881, 41.6447],
            [-87.5593, 41.6445],
            [-87.5593, 41.6373],
            [-87.5591, 41.6154],
            [-87.5591, 41.6081],
            [-87.5775, 41.601],
            [-87.6171, 41.619],
            [-87.5881, 41.6447],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712420',
        NAME: 'Dolton School District 149',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13444773,
        AWATER: 435367,
        INTPTLAT: '+41.6241290',
        INTPTLON: '-087.5794488',
        ELSDLEA: '12420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6696, 41.6482],
            [-87.6513, 41.6486],
            [-87.6472, 41.6487],
            [-87.6172, 41.6446],
            [-87.5881, 41.6447],
            [-87.6171, 41.619],
            [-87.6266, 41.6206],
            [-87.6562, 41.6368],
            [-87.6696, 41.6482],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712450',
        NAME: 'Dolton School District 148',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12415550,
        AWATER: 143898,
        INTPTLAT: '+41.6362247',
        INTPTLON: '-087.6241852',
        ELSDLEA: '12450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8648, 42.0659],
            [-87.806, 42.0552],
            [-87.8064, 42.0336],
            [-87.8064, 42.0263],
            [-87.86, 42.0393],
            [-87.8648, 42.0659],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713140',
        NAME: 'East Maine School District 63',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15423174,
        AWATER: 40484,
        INTPTLAT: '+42.0482288',
        INTPTLON: '-087.8348014',
        ELSDLEA: '13140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0291, 42.0509],
            [-87.9994, 42.0666],
            [-87.9701, 42.0589],
            [-87.9212, 42.0488],
            [-87.9205, 42.0051],
            [-87.9399, 41.9935],
            [-87.9592, 41.9932],
            [-87.9972, 41.9927],
            [-88.0238, 41.9925],
            [-88.0291, 42.0509],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713770',
        NAME: 'Community Consolidated School District 59',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58245872,
        AWATER: 1053144,
        INTPTLAT: '+42.0234889',
        INTPTLON: '-087.9773126',
        ELSDLEA: '13770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7324, 42.0647],
            [-87.6796, 42.0717],
            [-87.6651, 42.023],
            [-87.7087, 42.0191],
            [-87.7089, 42.0226],
            [-87.7279, 42.0371],
            [-87.7324, 42.0647],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714460',
        NAME: 'Evanston Community Consolidated School District 65',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23094787,
        AWATER: 57390,
        INTPTLAT: '+42.0471170',
        INTPTLON: '-087.6971114',
        ELSDLEA: '14460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7217, 41.7278],
            [-87.6874, 41.7354],
            [-87.6818, 41.7137],
            [-87.7211, 41.7132],
            [-87.7217, 41.7278],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714550',
        NAME: 'Evergreen Park Elementary School District 124',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8191088,
        AWATER: 0,
        INTPTLAT: '+41.7212749',
        INTPTLON: '-087.7013342',
        ELSDLEA: '14550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7769, 42.0223],
            [-87.7428, 42.0226],
            [-87.743, 42.0154],
            [-87.7618, 42.0047],
            [-87.7769, 42.0155],
            [-87.7769, 42.0223],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714820',
        NAME: 'Skokie-Fairview School District 72',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4335103,
        AWATER: 0,
        INTPTLAT: '+42.0150825',
        INTPTLON: '-087.7606951',
        ELSDLEA: '14820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7138, 41.5643],
            [-87.7044, 41.5571],
            [-87.685, 41.5643],
            [-87.6457, 41.5388],
            [-87.6457, 41.5352],
            [-87.6406, 41.5279],
            [-87.6458, 41.5279],
            [-87.6512, 41.5279],
            [-87.6598, 41.5206],
            [-87.6745, 41.5206],
            [-87.6745, 41.5243],
            [-87.7133, 41.5279],
            [-87.7136, 41.5571],
            [-87.7138, 41.5643],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715420',
        NAME: 'Flossmoor School District 161',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19143443,
        AWATER: 44483,
        INTPTLAT: '+41.5414833',
        INTPTLON: '-087.6808973',
        ELSDLEA: '15420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8273, 41.8792],
            [-87.805, 41.887],
            [-87.8042, 41.865],
            [-87.8038, 41.8504],
            [-87.8231, 41.85],
            [-87.8231, 41.8574],
            [-87.8273, 41.8792],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715450',
        NAME: 'Forest Park School District 91',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6269218,
        AWATER: 0,
        INTPTLAT: '+41.8672338',
        INTPTLON: '-087.8141713',
        ELSDLEA: '15450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7768, 41.6403],
            [-87.7378, 41.6407],
            [-87.7327, 41.6326],
            [-87.7171, 41.6108],
            [-87.7267, 41.6106],
            [-87.7665, 41.6101],
            [-87.7758, 41.6099],
            [-87.7761, 41.6172],
            [-87.7765, 41.6319],
            [-87.7768, 41.6403],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715480',
        NAME: 'Forest Ridge School District 142',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12869485,
        AWATER: 0,
        INTPTLAT: '+41.6241505',
        INTPTLON: '-087.7525901',
        ELSDLEA: '15480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8768, 41.9438],
            [-87.853, 41.9431],
            [-87.8496, 41.9373],
            [-87.852, 41.9229],
            [-87.8693, 41.9088],
            [-87.8768, 41.9438],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715780',
        NAME: 'Franklin Park School District 84',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5835178,
        AWATER: 0,
        INTPTLAT: '+41.9303852',
        INTPTLON: '-087.8638124',
        ELSDLEA: '15780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7895, 42.1525],
            [-87.759, 42.1524],
            [-87.7411, 42.1275],
            [-87.7704, 42.1197],
            [-87.7804, 42.1235],
            [-87.7895, 42.1525],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716860',
        NAME: 'Glencoe School District 35',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9956417,
        AWATER: 497003,
        INTPTLAT: '+42.1359002',
        INTPTLON: '-087.7664846',
        ELSDLEA: '16860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8662, 41.8637],
            [-87.8579, 41.8639],
            [-87.843, 41.857],
            [-87.8659, 41.8564],
            [-87.8662, 41.8637],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713440',
        NAME: 'Lindop School District 92',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2203270,
        AWATER: 0,
        INTPTLAT: '+41.8587397',
        INTPTLON: '-087.8540172',
        ELSDLEA: '13440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7378, 42.0371],
            [-87.7279, 42.0371],
            [-87.7089, 42.0226],
            [-87.7428, 42.0226],
            [-87.7378, 42.0371],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710380',
        NAME: 'Skokie School District 73-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4038107,
        AWATER: 0,
        INTPTLAT: '+42.0295696',
        INTPTLON: '-087.7241633',
        ELSDLEA: '10380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6164, 41.5208],
            [-87.5679, 41.5209],
            [-87.5679, 41.5136],
            [-87.5872, 41.4987],
            [-87.6162, 41.4987],
            [-87.6164, 41.5208],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710950',
        NAME: 'Ford Heights School District 169',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8557791,
        AWATER: 0,
        INTPTLAT: '+41.5103245',
        INTPTLON: '-087.5951144',
        ELSDLEA: '10950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7428, 42.0226],
            [-87.7089, 42.0226],
            [-87.7087, 42.0191],
            [-87.7091, 42.0119],
            [-87.743, 42.0154],
            [-87.7428, 42.0226],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713260',
        NAME: 'East Prairie School District 73',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2718536,
        AWATER: 0,
        INTPTLAT: '+42.0179844',
        INTPTLON: '-087.7239953',
        ELSDLEA: '13260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4812, 38.3189],
            [-89.4262, 38.2152],
            [-89.4346, 38.2152],
            [-89.4394, 38.2153],
            [-89.5016, 38.2172],
            [-89.507, 38.2173],
            [-89.5928, 38.2193],
            [-89.552, 38.2993],
            [-89.4812, 38.3189],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729310',
        NAME: 'Oakdale Community Consolidated School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123127557,
        AWATER: 171717,
        INTPTLAT: '+38.2582051',
        INTPTLON: '-089.5038769',
        ELSDLEA: '29310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.179, 38.5497],
            [-89.1422, 38.5569],
            [-89.1427, 38.5393],
            [-89.1435, 38.514],
            [-89.2091, 38.5031],
            [-89.2456, 38.5081],
            [-89.179, 38.5497],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742510',
        NAME: 'Willow Grove School District 46',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29267458,
        AWATER: 62554,
        INTPTLAT: '+38.5232099',
        INTPTLON: '-089.1790714',
        ELSDLEA: '42510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2091, 38.5031],
            [-89.1435, 38.514],
            [-89.1442, 38.4812],
            [-89.2096, 38.4915],
            [-89.2091, 38.5031],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740680',
        NAME: 'North Wamac School District 186',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15550496,
        AWATER: 53979,
        INTPTLAT: '+38.4949735',
        INTPTLON: '-089.1617989',
        ELSDLEA: '40680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.1557, 40.215],
              [-88.1445, 40.2151],
              [-88.1444, 40.2076],
              [-88.1556, 40.2076],
              [-88.1557, 40.215],
            ],
          ],
          [
            [
              [-88.1172, 40.2867],
              [-88.1137, 40.2867],
              [-88.1088, 40.2833],
              [-88.0018, 40.2543],
              [-88.0017, 40.25],
              [-88.0016, 40.2288],
              [-87.9478, 40.2254],
              [-87.9421, 40.2255],
              [-87.9418, 40.2035],
              [-87.9227, 40.1855],
              [-87.9258, 40.0838],
              [-88.0097, 40.0688],
              [-88.0096, 40.1644],
              [-88.1252, 40.1714],
              [-88.1444, 40.2076],
              [-88.1357, 40.2152],
              [-88.1358, 40.2245],
              [-88.1172, 40.2867],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700330',
        NAME: 'Prairieview-Ogden Community Consolidated School District 197',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258671790,
        AWATER: 397940,
        INTPTLAT: '+40.1870340',
        INTPTLON: '-088.0287280',
        ELSDLEA: '00330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2505, 40.4914],
            [-91.0902, 40.548],
            [-91.0036, 40.4292],
            [-90.9558, 40.4324],
            [-90.908, 40.3774],
            [-91.0253, 40.3703],
            [-91.0259, 40.2828],
            [-91.191, 40.2838],
            [-91.2269, 40.3727],
            [-91.2165, 40.4318],
            [-91.2505, 40.4914],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708680',
        NAME: 'Carthage Elementary School District 317',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460580158,
        AWATER: 573240,
        INTPTLAT: '+40.4025551',
        INTPTLON: '-091.1049798',
        ELSDLEA: '08680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0897, 40.5774],
            [-91.0203, 40.7001],
            [-90.8946, 40.6387],
            [-90.9558, 40.4324],
            [-91.0036, 40.4292],
            [-91.0902, 40.548],
            [-91.0897, 40.5774],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721690',
        NAME: 'La Harpe Community School District 347',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365428747,
        AWATER: 69361,
        INTPTLAT: '+40.5717259',
        INTPTLON: '-090.9842579',
        ELSDLEA: '21690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3767, 41.4787],
            [-90.367, 41.4995],
            [-90.3523, 41.4641],
            [-90.3767, 41.4787],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710690',
        NAME: 'Colona School District 190',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10194828,
        AWATER: 606277,
        INTPTLAT: '+41.4835928',
        INTPTLON: '-090.3558098',
        ELSDLEA: '10690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.888, 42.0873],
            [-87.8492, 42.0949],
            [-87.799, 42.1054],
            [-87.7707, 42.0941],
            [-87.7714, 42.0725],
            [-87.7715, 42.0651],
            [-87.806, 42.0552],
            [-87.8648, 42.0659],
            [-87.8883, 42.066],
            [-87.888, 42.0873],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716920',
        NAME: 'Glenview Community Consolidated School District 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32304681,
        AWATER: 262577,
        INTPTLAT: '+42.0773217',
        INTPTLON: '-087.8258699',
        ELSDLEA: '16920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6457, 41.5388],
            [-87.636, 41.5496],
            [-87.6266, 41.5605],
            [-87.6263, 41.5574],
            [-87.5781, 41.5575],
            [-87.5685, 41.5575],
            [-87.5679, 41.5356],
            [-87.5679, 41.5209],
            [-87.6164, 41.5208],
            [-87.6406, 41.5279],
            [-87.6457, 41.5352],
            [-87.6457, 41.5388],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716950',
        NAME: 'Brookwood School District 167',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23283471,
        AWATER: 10459,
        INTPTLAT: '+41.5368736',
        INTPTLON: '-087.6042541',
        ELSDLEA: '16950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8873, 42.153],
            [-87.8482, 42.1528],
            [-87.8487, 42.1238],
            [-87.8537, 42.1166],
            [-87.8878, 42.1237],
            [-87.8873, 42.153],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717850',
        NAME: 'Northbrook Elementary School District 27',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11326436,
        AWATER: 121335,
        INTPTLAT: '+42.1330785',
        INTPTLON: '-087.8631118',
        ELSDLEA: '17850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6752, 41.6044],
            [-87.6562, 41.6368],
            [-87.6266, 41.6206],
            [-87.6364, 41.5918],
            [-87.6751, 41.5936],
            [-87.6752, 41.6044],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718450',
        NAME: 'Harvey School District 152',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9845442,
        AWATER: 0,
        INTPTLAT: '+41.6104048',
        INTPTLON: '-087.6488159',
        ELSDLEA: '18450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6797, 41.6482],
            [-87.6702, 41.6482],
            [-87.6696, 41.6482],
            [-87.6562, 41.6368],
            [-87.6752, 41.6044],
            [-87.6753, 41.6082],
            [-87.6795, 41.6409],
            [-87.6797, 41.6482],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718480',
        NAME: 'West Harvey-Dixmoor Public School District 147',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7738814,
        AWATER: 0,
        INTPTLAT: '+41.6254450',
        INTPTLON: '-087.6674047',
        ELSDLEA: '18480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6751, 41.5863],
            [-87.6751, 41.5936],
            [-87.6364, 41.5918],
            [-87.6364, 41.5823],
            [-87.6363, 41.5719],
            [-87.675, 41.5717],
            [-87.6847, 41.5718],
            [-87.6751, 41.5863],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718600',
        NAME: 'Hazel Crest School District 152-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9020965,
        AWATER: 0,
        INTPTLAT: '+41.5820046',
        INTPTLON: '-087.6596474',
        ELSDLEA: '18600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9069, 41.7896],
            [-87.8887, 41.8028],
            [-87.879, 41.803],
            [-87.8816, 41.7725],
            [-87.9046, 41.7676],
            [-87.9069, 41.7896],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719110',
        NAME: 'LaGrange Highlands School District 106',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7635035,
        AWATER: 0,
        INTPTLAT: '+41.7841180',
        INTPTLON: '-087.8921425',
        ELSDLEA: '19110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9205, 41.8769],
            [-87.8929, 41.8776],
            [-87.8828, 41.8709],
            [-87.9006, 41.8336],
            [-87.9003, 41.8264],
            [-87.9186, 41.8264],
            [-87.9192, 41.8477],
            [-87.9205, 41.8769],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719230',
        NAME: 'Hillside School District 93',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11950001,
        AWATER: 0,
        INTPTLAT: '+41.8562288',
        INTPTLON: '-087.9049857',
        ELSDLEA: '19230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9525, 41.8177],
            [-87.9186, 41.8255],
            [-87.9069, 41.7896],
            [-87.9046, 41.7676],
            [-87.9162, 41.7671],
            [-87.9361, 41.7817],
            [-87.9654, 41.7884],
            [-87.9525, 41.8177],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719290',
        NAME: 'Hinsdale Community Consolidated School District 181',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19384796,
        AWATER: 206046,
        INTPTLAT: '+41.7978826',
        INTPTLON: '-087.9313727',
        ELSDLEA: '19290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6847, 41.5718],
            [-87.675, 41.5717],
            [-87.6363, 41.5719],
            [-87.6266, 41.5605],
            [-87.636, 41.5496],
            [-87.6457, 41.5388],
            [-87.685, 41.5643],
            [-87.6847, 41.5718],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719530',
        NAME: 'Homewood School District 153',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11544771,
        AWATER: 109629,
        INTPTLAT: '+41.5603759',
        INTPTLON: '-087.6530930',
        ELSDLEA: '19530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5775, 41.601],
            [-87.5591, 41.6081],
            [-87.5253, 41.6011],
            [-87.5253, 41.5815],
            [-87.5587, 41.5945],
            [-87.5773, 41.5973],
            [-87.5775, 41.601],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719680',
        NAME: 'Hoover-Schrum Memorial School District 157',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6332973,
        AWATER: 21088,
        INTPTLAT: '+41.5982622',
        INTPTLON: '-087.5486058',
        ELSDLEA: '19680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8137, 41.602],
            [-87.7938, 41.5918],
            [-87.7928, 41.5585],
            [-87.8608, 41.5576],
            [-87.8137, 41.602],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721270',
        NAME: 'Kirby School District 140',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25027857,
        AWATER: 46323,
        INTPTLAT: '+41.5786853',
        INTPTLON: '-087.8267868',
        ELSDLEA: '21270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8659, 41.8564],
            [-87.843, 41.857],
            [-87.8231, 41.8574],
            [-87.8231, 41.85],
            [-87.8328, 41.8498],
            [-87.8423, 41.8425],
            [-87.857, 41.842],
            [-87.8619, 41.8419],
            [-87.8653, 41.8417],
            [-87.8806, 41.8449],
            [-87.8659, 41.8564],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721420',
        NAME: 'Komarek School District 94',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4990309,
        AWATER: 0,
        INTPTLAT: '+41.8490931',
        INTPTLON: '-087.8486388',
        ELSDLEA: '21420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9006, 41.8336],
            [-87.886, 41.834],
            [-87.8818, 41.8374],
            [-87.8806, 41.8449],
            [-87.8653, 41.8417],
            [-87.8619, 41.8419],
            [-87.8614, 41.831],
            [-87.8503, 41.8203],
            [-87.8497, 41.8055],
            [-87.879, 41.803],
            [-87.8887, 41.8028],
            [-87.9003, 41.8264],
            [-87.9006, 41.8336],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721600',
        NAME: 'LaGrange School District 102',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12078081,
        AWATER: 0,
        INTPTLAT: '+41.8224653',
        INTPTLON: '-087.8737786',
        ELSDLEA: '21600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8816, 41.7725],
            [-87.879, 41.803],
            [-87.8497, 41.8055],
            [-87.8261, 41.7916],
            [-87.8485, 41.768],
            [-87.8765, 41.7393],
            [-87.8816, 41.7725],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721630',
        NAME: 'LaGrange School District 105',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16710547,
        AWATER: 192321,
        INTPTLAT: '+41.7783986',
        INTPTLON: '-087.8624494',
        ELSDLEA: '21630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5784, 41.5807],
            [-87.5589, 41.5779],
            [-87.5587, 41.5945],
            [-87.5253, 41.5815],
            [-87.5253, 41.5771],
            [-87.5254, 41.5575],
            [-87.5587, 41.5575],
            [-87.5685, 41.5575],
            [-87.5781, 41.5575],
            [-87.5782, 41.5714],
            [-87.5782, 41.5765],
            [-87.5784, 41.5807],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722020',
        NAME: 'Lansing School District 158',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13905786,
        AWATER: 64071,
        INTPTLAT: '+41.5728686',
        INTPTLON: '-087.5497192',
        ELSDLEA: '22020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3223, 38.2137],
            [-89.259, 38.2139],
            [-89.1929, 38.2135],
            [-89.1892, 38.2135],
            [-89.1841, 38.2134],
            [-89.1623, 38.213],
            [-89.1474, 38.2129],
            [-89.1296, 38.1247],
            [-89.1316, 38.1169],
            [-89.1862, 38.1256],
            [-89.2597, 38.1521],
            [-89.2526, 38.0832],
            [-89.3447, 38.0391],
            [-89.371, 38.1271],
            [-89.3223, 38.2137],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731560',
        NAME: 'Community Consolidated School District 204',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231513042,
        AWATER: 966562,
        INTPTLAT: '+38.1489441',
        INTPTLON: '-089.2747184',
        ELSDLEA: '31560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1862, 38.1256],
            [-89.2526, 38.0832],
            [-89.2597, 38.1521],
            [-89.1862, 38.1256],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738550',
        NAME: 'Tamaroa School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39204753,
        AWATER: 16080,
        INTPTLAT: '+38.1221727',
        INTPTLON: '-089.2196254',
        ELSDLEA: '38550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7267, 42.0936],
            [-87.7108, 42.0954],
            [-87.7028, 42.0897],
            [-87.7245, 42.0878],
            [-87.7267, 42.0936],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720970',
        NAME: 'Kenilworth School District 38',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1698672,
        AWATER: 0,
        INTPTLAT: '+42.0892630',
        INTPTLON: '-087.7150949',
        ELSDLEA: '20970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2479, 40.6384],
            [-91.1125, 40.6963],
            [-91.1145, 40.7206],
            [-91.0203, 40.7001],
            [-91.0897, 40.5774],
            [-91.2487, 40.5801],
            [-91.2479, 40.6384],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711670',
        NAME: 'Dallas Elementary School District 327',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121585215,
        AWATER: 15741480,
        INTPTLAT: '+40.6339365',
        INTPTLON: '-091.1382433',
        ELSDLEA: '11670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9716, 42.4948],
            [-87.9498, 42.4946],
            [-87.8529, 42.4932],
            [-87.8523, 42.4682],
            [-87.8035, 42.4355],
            [-87.8033, 42.4138],
            [-87.8845, 42.4139],
            [-87.8843, 42.4212],
            [-87.8843, 42.423],
            [-87.9363, 42.4435],
            [-87.9722, 42.4583],
            [-87.9716, 42.4948],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700010',
        NAME: 'Beach Park Community Consolidated School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65913678,
        AWATER: 858742,
        INTPTLAT: '+42.4611573',
        INTPTLON: '-087.8893160',
        ELSDLEA: '00010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.852, 42.1896],
            [-87.847, 42.2036],
            [-87.8089, 42.2247],
            [-87.759, 42.1524],
            [-87.7895, 42.1525],
            [-87.8284, 42.1527],
            [-87.852, 42.1896],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700119',
        NAME: 'North Shore School District 112',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34117757,
        AWATER: 84689,
        INTPTLAT: '+42.1846237',
        INTPTLON: '-087.8109043',
        ELSDLEA: '00119',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1995, 42.4951],
            [-88.1986, 42.4951],
            [-88.1097, 42.4956],
            [-88.0621, 42.4955],
            [-87.9716, 42.4948],
            [-87.9722, 42.4583],
            [-88.0035, 42.448],
            [-88.0234, 42.4186],
            [-88.0924, 42.4445],
            [-88.1356, 42.466],
            [-88.168, 42.4442],
            [-88.1712, 42.4459],
            [-88.199, 42.4591],
            [-88.1995, 42.4951],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703840',
        NAME: 'Antioch Community Consolidated School District 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83057790,
        AWATER: 9524823,
        INTPTLAT: '+42.4682086',
        INTPTLON: '-088.0754990',
        ELSDLEA: '03840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9653, 42.1961],
            [-87.9074, 42.1747],
            [-87.922, 42.172],
            [-87.9225, 42.1717],
            [-87.9156, 42.168],
            [-87.9152, 42.1677],
            [-87.9099, 42.1531],
            [-87.9654, 42.1535],
            [-87.9653, 42.1961],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703900',
        NAME: 'Aptakisic-Tripp Community Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16126950,
        AWATER: 152910,
        INTPTLAT: '+42.1687428',
        INTPTLON: '-087.9394956',
        ELSDLEA: '03900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2664, 42.2415],
            [-88.2134, 42.245],
            [-88.1988, 42.2367],
            [-88.2035, 42.2049],
            [-88.2384, 42.1945],
            [-88.2776, 42.205],
            [-88.2664, 42.2415],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708730',
        NAME: 'Cary Community Consolidated School District 26',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31701653,
        AWATER: 1796893,
        INTPTLAT: '+42.2212231',
        INTPTLON: '-088.2294077',
        ELSDLEA: '08730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9225, 42.1717],
            [-87.922, 42.172],
            [-87.9074, 42.1747],
            [-87.8949, 42.1826],
            [-87.852, 42.1896],
            [-87.8284, 42.1527],
            [-87.8482, 42.1528],
            [-87.8873, 42.153],
            [-87.9099, 42.1531],
            [-87.9152, 42.1677],
            [-87.9156, 42.168],
            [-87.9225, 42.1717],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711980',
        NAME: 'Deerfield School District 109',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22749932,
        AWATER: 185401,
        INTPTLAT: '+42.1682343',
        INTPTLON: '-087.8683085',
        ELSDLEA: '11980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1963, 42.0049],
            [-89.0664, 42.0578],
            [-89.0393, 41.9779],
            [-89.1852, 41.9542],
            [-89.1963, 42.0049],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721130',
        NAME: 'Kings Consolidated School District 144',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106419832,
        AWATER: 65202,
        INTPTLAT: '+41.9978501',
        INTPTLON: '-089.1114452',
        ELSDLEA: '21130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8942, 42.1892],
            [-87.8869, 42.2038],
            [-87.847, 42.2036],
            [-87.852, 42.1896],
            [-87.8949, 42.1826],
            [-87.8942, 42.1857],
            [-87.8942, 42.1892],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704950',
        NAME: 'Bannockburn School District 106',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7187091,
        AWATER: 82634,
        INTPTLAT: '+42.1922265',
        INTPTLON: '-087.8698114',
        ELSDLEA: '04950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0242, 42.255],
            [-87.9989, 42.2552],
            [-87.9842, 42.2406],
            [-87.9846, 42.2248],
            [-88.0138, 42.2267],
            [-88.019, 42.2404],
            [-88.0242, 42.2407],
            [-88.0242, 42.255],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712210',
        NAME: 'Diamond Lake School District 76',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9046339,
        AWATER: 690181,
        INTPTLAT: '+42.2423254',
        INTPTLON: '-088.0023003',
        ELSDLEA: '12210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1988, 42.3724],
            [-88.1988, 42.3799],
            [-88.1988, 42.3872],
            [-88.1595, 42.3864],
            [-88.1204, 42.3655],
            [-88.1203, 42.3435],
            [-88.14, 42.3285],
            [-88.1986, 42.3289],
            [-88.1986, 42.337],
            [-88.1985, 42.358],
            [-88.1988, 42.3724],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706270',
        NAME: 'Big Hollow School District 38',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26882639,
        AWATER: 3610569,
        INTPTLAT: '+42.3562327',
        INTPTLON: '-088.1626392',
        ELSDLEA: '06270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1208, 42.3295],
            [-88.0824, 42.3136],
            [-88.0238, 42.3275],
            [-88.0047, 42.3274],
            [-88.0046, 42.3206],
            [-88.0045, 42.3165],
            [-88.0045, 42.3136],
            [-88.0042, 42.2977],
            [-88.0042, 42.296],
            [-88.0242, 42.255],
            [-88.0242, 42.2407],
            [-88.019, 42.2404],
            [-88.0138, 42.2267],
            [-88.029, 42.2116],
            [-88.0628, 42.2408],
            [-88.1203, 42.2434],
            [-88.1208, 42.3295],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715930',
        NAME: 'Fremont School District 79',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81467887,
        AWATER: 3446609,
        INTPTLAT: '+42.2749173',
        INTPTLON: '-088.0678303',
        ELSDLEA: '15930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1203, 42.3435],
            [-88.0681, 42.386],
            [-88.0245, 42.3858],
            [-88.0041, 42.3678],
            [-88.0042, 42.3606],
            [-88.0238, 42.3275],
            [-88.0824, 42.3136],
            [-88.1208, 42.3295],
            [-88.14, 42.3285],
            [-88.1203, 42.3435],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717520',
        NAME: 'Grayslake Consolidated Community School District 46',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36851568,
        AWATER: 2006515,
        INTPTLAT: '+42.3508896',
        INTPTLON: '-088.0579438',
        ELSDLEA: '17520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9335, 42.4146],
            [-87.9363, 42.4435],
            [-87.8843, 42.423],
            [-87.8843, 42.4212],
            [-87.8845, 42.4139],
            [-87.8851, 42.3565],
            [-87.9335, 42.4146],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717800',
        NAME: 'Gurnee School District 56',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35589584,
        AWATER: 644025,
        INTPTLAT: '+42.3962665',
        INTPTLON: '-087.9088283',
        ELSDLEA: '17800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9989, 42.2552],
            [-87.9885, 42.268],
            [-87.9837, 42.2733],
            [-87.9838, 42.2695],
            [-87.9249, 42.2698],
            [-87.9106, 42.2401],
            [-87.9507, 42.2264],
            [-87.9551, 42.2263],
            [-87.964, 42.2266],
            [-87.9653, 42.2233],
            [-87.9649, 42.2118],
            [-87.9796, 42.2189],
            [-87.9844, 42.2221],
            [-87.9846, 42.2248],
            [-87.9842, 42.2406],
            [-87.9989, 42.2552],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718570',
        NAME: 'Hawthorn Community Consolidated School District 73',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27912132,
        AWATER: 763579,
        INTPTLAT: '+42.2461297',
        INTPTLON: '-087.9557880',
        ELSDLEA: '18570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.029, 42.2116],
            [-88.0138, 42.2267],
            [-87.9846, 42.2248],
            [-87.9844, 42.2221],
            [-87.9796, 42.2189],
            [-87.9649, 42.2118],
            [-87.9653, 42.1961],
            [-87.9654, 42.1535],
            [-88.0045, 42.1536],
            [-88.034, 42.1537],
            [-88.029, 42.2116],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721030',
        NAME: 'Kildeer Countryside Community Consolidated School District 96',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46473067,
        AWATER: 1202404,
        INTPTLAT: '+42.1867407',
        INTPTLON: '-088.0051947',
        ELSDLEA: '21030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9016, 42.2301],
            [-87.8923, 42.2401],
            [-87.886, 42.2429],
            [-87.8859, 42.2473],
            [-87.8855, 42.2798],
            [-87.8281, 42.2689],
            [-87.8089, 42.2247],
            [-87.847, 42.2036],
            [-87.8869, 42.2038],
            [-87.9016, 42.2301],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721750',
        NAME: 'Lake Forest School District 67',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40846579,
        AWATER: 69939,
        INTPTLAT: '+42.2362509',
        INTPTLON: '-087.8548153',
        ELSDLEA: '21750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1207, 42.4408],
            [-88.0924, 42.4445],
            [-88.0234, 42.4186],
            [-88.019, 42.4079],
            [-88.0209, 42.4079],
            [-88.021, 42.4003],
            [-88.0245, 42.3858],
            [-88.0681, 42.386],
            [-88.0822, 42.3932],
            [-88.1205, 42.3847],
            [-88.1204, 42.4154],
            [-88.1402, 42.4162],
            [-88.1207, 42.4408],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721870',
        NAME: 'Lake Villa Community Consolidated School District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36589639,
        AWATER: 6471827,
        INTPTLAT: '+42.4117535',
        INTPTLON: '-088.0813709',
        ELSDLEA: '21870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5665, 38.6451],
            [-89.5492, 38.7419],
            [-89.5488, 38.7566],
            [-89.4192, 38.7409],
            [-89.4276, 38.5611],
            [-89.4981, 38.566],
            [-89.5715, 38.5828],
            [-89.5665, 38.6451],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707010',
        NAME: 'Breese School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195468990,
        AWATER: 65769,
        INTPTLAT: '+38.6494625',
        INTPTLON: '-089.4814709',
        ELSDLEA: '07010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1604, 42.4156],
            [-88.1402, 42.4162],
            [-88.1204, 42.4154],
            [-88.1205, 42.3847],
            [-88.1204, 42.3655],
            [-88.1595, 42.3864],
            [-88.1604, 42.4156],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716290',
        NAME: 'Gavin School District 37',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10790872,
        AWATER: 5382545,
        INTPTLAT: '+42.3927268',
        INTPTLON: '-088.1378994',
        ELSDLEA: '16290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.168, 42.4442],
            [-88.1356, 42.466],
            [-88.1207, 42.4408],
            [-88.1402, 42.4162],
            [-88.1604, 42.4156],
            [-88.168, 42.4442],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717490',
        NAME: 'Grass Lake School District 36',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7811957,
        AWATER: 6513111,
        INTPTLAT: '+42.4366045',
        INTPTLON: '-088.1449080',
        ELSDLEA: '17490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1356, 42.466],
            [-88.0924, 42.4445],
            [-88.1207, 42.4408],
            [-88.1356, 42.466],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714250',
        NAME: 'Emmons School District 33',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9121460,
        AWATER: 1656213,
        INTPTLAT: '+42.4574813',
        INTPTLON: '-088.1088545',
        ELSDLEA: '14250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.199, 42.4591],
            [-88.1712, 42.4459],
            [-88.168, 42.4442],
            [-88.1604, 42.4156],
            [-88.1595, 42.3864],
            [-88.1988, 42.3872],
            [-88.1988, 42.3983],
            [-88.1988, 42.4156],
            [-88.1989, 42.4447],
            [-88.199, 42.4591],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715630',
        NAME: 'Fox Lake Grade School District 114',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14396237,
        AWATER: 7678364,
        INTPTLAT: '+42.4218853',
        INTPTLON: '-088.1823868',
        ELSDLEA: '15630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8852, 42.2981],
            [-87.8333, 42.3049],
            [-87.8281, 42.2689],
            [-87.8855, 42.2798],
            [-87.89, 42.2834],
            [-87.8852, 42.2981],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721720',
        NAME: 'Lake Bluff Elementary School District 65',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13309113,
        AWATER: 21304,
        INTPTLAT: '+42.2846175',
        INTPTLON: '-087.8548787',
        ELSDLEA: '21720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9653, 42.2233],
            [-87.964, 42.2266],
            [-87.9551, 42.2263],
            [-87.9507, 42.2264],
            [-87.9106, 42.2401],
            [-87.8995, 42.2401],
            [-87.9009, 42.238],
            [-87.8961, 42.2401],
            [-87.8923, 42.2401],
            [-87.9016, 42.2301],
            [-87.8869, 42.2038],
            [-87.8942, 42.1892],
            [-87.8942, 42.1857],
            [-87.8949, 42.1826],
            [-87.9074, 42.1747],
            [-87.9653, 42.1961],
            [-87.9649, 42.2118],
            [-87.9653, 42.2233],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723090',
        NAME: 'Lincolnshire-Prairieview School District 103',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30283671,
        AWATER: 635149,
        INTPTLAT: '+42.2157015',
        INTPTLON: '-087.9200437',
        ELSDLEA: '23090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3555, 42.3715],
            [-88.3062, 42.3729],
            [-88.1988, 42.3983],
            [-88.1988, 42.3872],
            [-88.1988, 42.3799],
            [-88.1988, 42.3724],
            [-88.1985, 42.358],
            [-88.1986, 42.337],
            [-88.1986, 42.3289],
            [-88.2268, 42.2797],
            [-88.2771, 42.2988],
            [-88.3552, 42.2986],
            [-88.3555, 42.3715],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725290',
        NAME: 'McHenry Community Consolidated School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103694292,
        AWATER: 7386968,
        INTPTLAT: '+42.3322598',
        INTPTLON: '-088.2679493',
        ELSDLEA: '25290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0035, 42.448],
            [-87.9722, 42.4583],
            [-87.9363, 42.4435],
            [-87.9335, 42.4146],
            [-88.0041, 42.3931],
            [-88.004, 42.3966],
            [-88.021, 42.4003],
            [-88.0209, 42.4079],
            [-88.019, 42.4079],
            [-88.0234, 42.4186],
            [-88.0035, 42.448],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726100',
        NAME: 'Millburn Community Consolidated School District 24',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38522936,
        AWATER: 932144,
        INTPTLAT: '+42.4331332',
        INTPTLON: '-087.9821999',
        ELSDLEA: '26100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.624, 41.5531],
            [-88.558, 41.617],
            [-88.5434, 41.6226],
            [-88.4637, 41.531],
            [-88.5972, 41.4932],
            [-88.624, 41.5531],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728270',
        NAME: 'Newark Community Consolidated School District 66',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122243019,
        AWATER: 369124,
        INTPTLAT: '+41.5482507',
        INTPTLON: '-088.5420259',
        ELSDLEA: '28270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0042, 42.296],
            [-87.9839, 42.284],
            [-87.9837, 42.2733],
            [-87.9885, 42.268],
            [-87.9989, 42.2552],
            [-88.0242, 42.255],
            [-88.0042, 42.296],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727540',
        NAME: 'Mundelein Elementary School District 75',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10373480,
        AWATER: 734618,
        INTPTLAT: '+42.2755273',
        INTPTLON: '-088.0071848',
        ELSDLEA: '27540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9443, 42.3273],
            [-87.8852, 42.3263],
            [-87.8852, 42.2981],
            [-87.89, 42.2834],
            [-87.9249, 42.2837],
            [-87.9443, 42.3273],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729130',
        NAME: 'Oak Grove School District 68',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 22421822,
        AWATER: 355668,
        INTPTLAT: '+42.3073685',
        INTPTLON: '-087.9156029',
        ELSDLEA: '29130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0046, 42.3206],
            [-88.0047, 42.3274],
            [-87.9443, 42.3273],
            [-87.9249, 42.2837],
            [-87.9249, 42.2698],
            [-87.9838, 42.2695],
            [-87.9837, 42.2733],
            [-87.9839, 42.284],
            [-88.0042, 42.296],
            [-88.0042, 42.2977],
            [-88.0045, 42.3136],
            [-88.0045, 42.3165],
            [-88.0046, 42.3206],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722800',
        NAME: 'Libertyville School District 70',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31659840,
        AWATER: 1056101,
        INTPTLAT: '+42.2993770',
        INTPTLON: '-087.9632523',
        ELSDLEA: '22800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7618, 42.0047],
            [-87.743, 42.0154],
            [-87.7091, 42.0119],
            [-87.7618, 42.0047],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723100',
        NAME: 'Lincolnwood School District 74',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6971338,
        AWATER: 0,
        INTPTLAT: '+42.0054299',
        INTPTLON: '-087.7329594',
        ELSDLEA: '23100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8503, 41.8203],
            [-87.8415, 41.8205],
            [-87.8406, 41.8205],
            [-87.8026, 41.8212],
            [-87.7827, 41.8215],
            [-87.7821, 41.8063],
            [-87.802, 41.8064],
            [-87.8176, 41.7985],
            [-87.8261, 41.7916],
            [-87.8497, 41.8055],
            [-87.8503, 41.8203],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723850',
        NAME: 'Lyons School District 103',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13027681,
        AWATER: 155450,
        INTPTLAT: '+41.8089808',
        INTPTLON: '-087.8230236',
        ELSDLEA: '23850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.92, 41.9582],
            [-87.8843, 41.9575],
            [-87.8768, 41.9438],
            [-87.8693, 41.9088],
            [-87.8704, 41.9078],
            [-87.8838, 41.9076],
            [-87.9204, 41.914],
            [-87.9203, 41.9358],
            [-87.92, 41.9582],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724330',
        NAME: 'Mannheim School District 83',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16840797,
        AWATER: 0,
        INTPTLAT: '+41.9319078',
        INTPTLON: '-087.8972438',
        ELSDLEA: '24330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8537, 42.1166],
            [-87.8487, 42.1238],
            [-87.8043, 42.1135],
            [-87.799, 42.1054],
            [-87.8492, 42.0949],
            [-87.8537, 42.1166],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724420',
        NAME: 'Northbrook/Glenview School District 30',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11957335,
        AWATER: 87118,
        INTPTLAT: '+42.1078983',
        INTPTLON: '-087.8310360',
        ELSDLEA: '24420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7267, 41.6106],
            [-87.7171, 41.6108],
            [-87.6976, 41.6114],
            [-87.6753, 41.6082],
            [-87.6752, 41.6044],
            [-87.6751, 41.5936],
            [-87.6751, 41.5863],
            [-87.6847, 41.5718],
            [-87.685, 41.5643],
            [-87.7044, 41.5571],
            [-87.7138, 41.5643],
            [-87.7333, 41.5716],
            [-87.7267, 41.6106],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724720',
        NAME: 'Prairie-Hills Elementary School District 144',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21134579,
        AWATER: 38353,
        INTPTLAT: '+41.5876288',
        INTPTLON: '-087.7053438',
        ELSDLEA: '24720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7325, 41.528],
            [-87.7133, 41.5279],
            [-87.6745, 41.5243],
            [-87.6745, 41.5206],
            [-87.6743, 41.499],
            [-87.7031, 41.4698],
            [-87.7322, 41.4697],
            [-87.7325, 41.528],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725020',
        NAME: 'Matteson Elementary School District 162',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22568156,
        AWATER: 35173,
        INTPTLAT: '+41.5038652',
        INTPTLON: '-087.7065000',
        ELSDLEA: '25020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8644, 41.9079],
            [-87.8255, 41.9085],
            [-87.8245, 41.9086],
            [-87.8273, 41.8792],
            [-87.8231, 41.8574],
            [-87.843, 41.857],
            [-87.8579, 41.8639],
            [-87.8644, 41.9079],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725110',
        NAME: 'Maywood-Melrose Park-Broadview School District 89',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14150885,
        AWATER: 0,
        INTPTLAT: '+41.8841137',
        INTPTLON: '-087.8440699',
        ELSDLEA: '25110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7378, 41.6407],
            [-87.7186, 41.6475],
            [-87.7084, 41.6331],
            [-87.6986, 41.6332],
            [-87.6976, 41.6114],
            [-87.7171, 41.6108],
            [-87.7327, 41.6326],
            [-87.7378, 41.6407],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725920',
        NAME: 'Midlothian School District 143',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8764144,
        AWATER: 0,
        INTPTLAT: '+41.6282722',
        INTPTLON: '-087.7172785',
        ELSDLEA: '25920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.806, 42.0552],
            [-87.7715, 42.0651],
            [-87.766, 42.0639],
            [-87.7663, 42.0515],
            [-87.796, 42.0336],
            [-87.8064, 42.0336],
            [-87.806, 42.0552],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726820',
        NAME: 'Golf Elementary School District 67',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5647369,
        AWATER: 0,
        INTPTLAT: '+42.0501620',
        INTPTLON: '-087.7899308',
        ELSDLEA: '26820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.796, 42.0336],
            [-87.7663, 42.0515],
            [-87.7639, 42.0409],
            [-87.7767, 42.0264],
            [-87.796, 42.0336],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726850',
        NAME: 'Morton Grove School District 70',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5179553,
        AWATER: 0,
        INTPTLAT: '+42.0383358',
        INTPTLON: '-087.7796698',
        ELSDLEA: '26850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9368, 42.0808],
            [-87.9214, 42.0662],
            [-87.9212, 42.0488],
            [-87.9701, 42.0589],
            [-87.9368, 42.0808],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727210',
        NAME: 'Mount Prospect School District 57',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11077239,
        AWATER: 0,
        INTPTLAT: '+42.0631535',
        INTPTLON: '-087.9423900',
        ELSDLEA: '27210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8065, 42.019],
            [-87.8064, 42.0263],
            [-87.8064, 42.0336],
            [-87.796, 42.0336],
            [-87.7767, 42.0264],
            [-87.7769, 42.0223],
            [-87.7769, 42.0155],
            [-87.8065, 42.019],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728500',
        NAME: 'Niles Elementary School District 71',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6677066,
        AWATER: 0,
        INTPTLAT: '+42.0163301',
        INTPTLON: '-087.7941945',
        ELSDLEA: '28500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8466, 41.9691],
            [-87.8416, 41.9701],
            [-87.8367, 41.9713],
            [-87.8334, 41.9739],
            [-87.8267, 41.9731],
            [-87.8185, 41.9706],
            [-87.817, 41.9707],
            [-87.8072, 41.9673],
            [-87.8073, 41.9539],
            [-87.8466, 41.9691],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728650',
        NAME: 'Norridge School District 80',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4846104,
        AWATER: 0,
        INTPTLAT: '+41.9623525',
        INTPTLON: '-087.8235814',
        ELSDLEA: '28650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.857, 41.7324],
            [-87.8475, 41.7326],
            [-87.7991, 41.7335],
            [-87.7984, 41.7119],
            [-87.7981, 41.7044],
            [-87.7979, 41.7007],
            [-87.8072, 41.697],
            [-87.8558, 41.6959],
            [-87.857, 41.7324],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728890',
        NAME: 'North Palos School District 117',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19234252,
        AWATER: 136879,
        INTPTLAT: '+41.7145133',
        INTPTLON: '-087.8254364',
        ELSDLEA: '28890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8482, 42.1528],
            [-87.8284, 42.1527],
            [-87.7895, 42.1525],
            [-87.7804, 42.1235],
            [-87.8043, 42.1135],
            [-87.8487, 42.1238],
            [-87.8482, 42.1528],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728980',
        NAME: 'Northbrook School District 28',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17731721,
        AWATER: 238304,
        INTPTLAT: '+42.1364500',
        INTPTLON: '-087.8135417',
        ELSDLEA: '28980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7606, 41.7342],
            [-87.7411, 41.7345],
            [-87.7217, 41.7278],
            [-87.7211, 41.7132],
            [-87.7206, 41.6986],
            [-87.7303, 41.6984],
            [-87.7595, 41.6979],
            [-87.7598, 41.7053],
            [-87.76, 41.7124],
            [-87.76, 41.7125],
            [-87.7606, 41.7342],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729190',
        NAME: 'Oak Lawn-Hometown School District 123',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13059840,
        AWATER: 0,
        INTPTLAT: '+41.7163994',
        INTPTLON: '-087.7406952',
        ELSDLEA: '29190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8057, 41.9089],
            [-87.7741, 41.8655],
            [-87.779, 41.8654],
            [-87.8042, 41.865],
            [-87.805, 41.887],
            [-87.8057, 41.9089],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729250',
        NAME: 'Oak Park Elementary School District 97',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12172451,
        AWATER: 0,
        INTPTLAT: '+41.8871644',
        INTPTLON: '-087.7899404',
        ELSDLEA: '29250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9119, 41.6439],
            [-87.7957, 41.6462],
            [-87.7952, 41.6316],
            [-87.8137, 41.602],
            [-87.8608, 41.5576],
            [-87.9095, 41.5567],
            [-87.9119, 41.6439],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730220',
        NAME: 'Orland School District 135',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61228853,
        AWATER: 1823676,
        INTPTLAT: '+41.6076791',
        INTPTLON: '-087.8676051',
        ELSDLEA: '30220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0767, 42.1539],
            [-88.034, 42.1537],
            [-88.0045, 42.1536],
            [-88.0047, 42.1317],
            [-88.0047, 42.0956],
            [-87.9994, 42.0666],
            [-88.0291, 42.0509],
            [-88.0282, 42.0626],
            [-88.1217, 42.0671],
            [-88.0767, 42.1539],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730420',
        NAME: 'Palatine Community Consolidated School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90195461,
        AWATER: 609270,
        INTPTLAT: '+42.1046390',
        INTPTLON: '-088.0558411',
        ELSDLEA: '30420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9135, 41.6875],
            [-87.8558, 41.6959],
            [-87.8072, 41.697],
            [-87.7972, 41.6826],
            [-87.7969, 41.6753],
            [-87.7957, 41.6462],
            [-87.9119, 41.6439],
            [-87.9135, 41.6875],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730600',
        NAME: 'Palos Community Consolidated School District 118',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47854030,
        AWATER: 1767263,
        INTPTLAT: '+41.6678628',
        INTPTLON: '-087.8510803',
        ELSDLEA: '30600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6743, 41.499],
            [-87.6693, 41.4843],
            [-87.6742, 41.477],
            [-87.6742, 41.4698],
            [-87.7031, 41.4698],
            [-87.6743, 41.499],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730810',
        NAME: 'Park Forest School District 163',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8971513,
        AWATER: 0,
        INTPTLAT: '+41.4853738',
        INTPTLON: '-087.6835601',
        ELSDLEA: '30810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.86, 42.0393],
            [-87.8064, 42.0263],
            [-87.8065, 42.019],
            [-87.8559, 41.9891],
            [-87.8594, 41.9958],
            [-87.86, 42.0393],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730840',
        NAME: 'Park Ridge Consolidated Community School District 64',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20760955,
        AWATER: 130931,
        INTPTLAT: '+42.0136454',
        INTPTLON: '-087.8428257',
        ELSDLEA: '30840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.817, 41.9707],
              [-87.8072, 41.9683],
              [-87.8072, 41.9673],
              [-87.817, 41.9707],
            ],
          ],
          [
            [
              [-87.8366, 41.9798],
              [-87.8235, 41.9831],
              [-87.8244, 41.976],
              [-87.8366, 41.9798],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731200',
        NAME: 'Pennoyer School District 79',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1702311,
        AWATER: 0,
        INTPTLAT: '+41.9741058',
        INTPTLON: '-087.8230614',
        ELSDLEA: '31200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9162, 41.7671],
            [-87.9046, 41.7676],
            [-87.8816, 41.7725],
            [-87.8765, 41.7393],
            [-87.8865, 41.7316],
            [-87.9143, 41.7167],
            [-87.9149, 41.7311],
            [-87.9162, 41.7671],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732040',
        NAME: 'Pleasantdale School District 107',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14388774,
        AWATER: 51797,
        INTPTLAT: '+41.7479486',
        INTPTLON: '-087.8985562',
        ELSDLEA: '32040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7186, 41.6475],
            [-87.6797, 41.6482],
            [-87.6795, 41.6409],
            [-87.6753, 41.6082],
            [-87.6976, 41.6114],
            [-87.6986, 41.6332],
            [-87.7084, 41.6331],
            [-87.7186, 41.6475],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732370',
        NAME: 'Posen-Robbins Elementary School District 143-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9736255,
        AWATER: 0,
        INTPTLAT: '+41.6306888',
        INTPTLON: '-087.6921548',
        ELSDLEA: '32370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9659, 42.1245],
            [-87.9271, 42.0989],
            [-87.9368, 42.088],
            [-87.9659, 42.1245],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732850',
        NAME: 'Prospect Heights School District 23',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12670795,
        AWATER: 107245,
        INTPTLAT: '+42.1067859',
        INTPTLON: '-087.9440315',
        ELSDLEA: '32850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8693, 41.9088],
            [-87.852, 41.9229],
            [-87.8255, 41.911],
            [-87.8255, 41.9085],
            [-87.8644, 41.9079],
            [-87.8704, 41.9078],
            [-87.8693, 41.9088],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733390',
        NAME: 'Rhodes School District 84-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4072778,
        AWATER: 0,
        INTPTLAT: '+41.9149430',
        INTPTLON: '-087.8473507',
        ELSDLEA: '33390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7991, 41.7335],
            [-87.7606, 41.7342],
            [-87.76, 41.7125],
            [-87.76, 41.7124],
            [-87.7598, 41.7053],
            [-87.7899, 41.7049],
            [-87.7981, 41.7044],
            [-87.7984, 41.7119],
            [-87.7991, 41.7335],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733690',
        NAME: 'Ridgeland School District 122',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9940781,
        AWATER: 0,
        INTPTLAT: '+41.7197958',
        INTPTLON: '-087.7795076',
        ELSDLEA: '33690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8245, 41.9086],
            [-87.8057, 41.9089],
            [-87.805, 41.887],
            [-87.8273, 41.8792],
            [-87.8245, 41.9086],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733810',
        NAME: 'River Forest School District 90',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6235808,
        AWATER: 4794,
        INTPTLAT: '+41.8948750',
        INTPTLON: '-087.8190635',
        ELSDLEA: '33810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8496, 41.9373],
            [-87.8263, 41.9378],
            [-87.8255, 41.911],
            [-87.852, 41.9229],
            [-87.8496, 41.9373],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733840',
        NAME: 'River Grove School District 85-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3732791,
        AWATER: 0,
        INTPTLAT: '+41.9283943',
        INTPTLON: '-087.8371287',
        ELSDLEA: '33840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9368, 42.088],
            [-87.9271, 42.0989],
            [-87.8881, 42.0948],
            [-87.888, 42.0873],
            [-87.8883, 42.066],
            [-87.9007, 42.0661],
            [-87.9077, 42.0661],
            [-87.9214, 42.0662],
            [-87.9368, 42.0808],
            [-87.9368, 42.088],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733870',
        NAME: 'River Trails School District 26',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11068136,
        AWATER: 0,
        INTPTLAT: '+42.0813534',
        INTPTLON: '-087.9093118',
        ELSDLEA: '33870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8423, 41.8425],
            [-87.8328, 41.8498],
            [-87.8231, 41.85],
            [-87.8038, 41.8504],
            [-87.8026, 41.8212],
            [-87.8406, 41.8205],
            [-87.8415, 41.8205],
            [-87.8423, 41.8425],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733990',
        NAME: 'Riverside School District 96',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10114608,
        AWATER: 89901,
        INTPTLAT: '+41.8315593',
        INTPTLON: '-087.8214679',
        ELSDLEA: '33990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1446, 42.0574],
            [-88.1217, 42.0671],
            [-88.0282, 42.0626],
            [-88.0291, 42.0509],
            [-88.0238, 41.9925],
            [-88.0314, 41.9925],
            [-88.0699, 41.991],
            [-88.1009, 41.9899],
            [-88.1451, 41.9882],
            [-88.1446, 42.0574],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734740',
        NAME: 'Schaumburg Community Consolidated School District 54',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80583360,
        AWATER: 555721,
        INTPTLAT: '+42.0291148',
        INTPTLON: '-088.0895105',
        ELSDLEA: '34740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8594, 41.9958],
            [-87.8559, 41.9891],
            [-87.8668, 41.9739],
            [-87.8738, 41.9767],
            [-87.8801, 41.9952],
            [-87.8594, 41.9958],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734770',
        NAME: 'Rosemont Elementary School District 78',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3503551,
        AWATER: 0,
        INTPTLAT: '+41.9861525',
        INTPTLON: '-087.8670779',
        ELSDLEA: '34770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6016, 40.1224],
            [-89.5637, 40.2479],
            [-89.4861, 40.2012],
            [-89.5229, 40.0637],
            [-89.5794, 40.0488],
            [-89.5873, 40.0488],
            [-89.6016, 40.1224],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700103',
        NAME: 'New Holland-Middletown Elementary School District 88',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187520511,
        AWATER: 76792,
        INTPTLAT: '+40.1469100',
        INTPTLON: '-089.5487180',
        ELSDLEA: '00103',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3676, 40.1226],
            [-89.3729, 40.1651],
            [-89.369, 40.2215],
            [-89.3461, 40.2521],
            [-89.1373, 40.195],
            [-89.1456, 40.1064],
            [-89.2538, 40.0545],
            [-89.3671, 40.063],
            [-89.3676, 40.1226],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709850',
        NAME: 'Chester-East Lincoln Community Csd 61',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319670377,
        AWATER: 150094,
        INTPTLAT: '+40.1435063',
        INTPTLON: '-089.2801683',
        ELSDLEA: '09850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4861, 40.2012],
            [-89.369, 40.2215],
            [-89.3729, 40.1651],
            [-89.3907, 40.1357],
            [-89.3676, 40.1226],
            [-89.3671, 40.063],
            [-89.5229, 40.0637],
            [-89.4861, 40.2012],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741600',
        NAME: 'West Lincoln-Broadwell Elementary School District 92',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178693171,
        AWATER: 1743329,
        INTPTLAT: '+40.1251218',
        INTPTLON: '-089.4288442',
        ELSDLEA: '41600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5679, 41.5356],
            [-87.5586, 41.5359],
            [-87.525, 41.5283],
            [-87.5256, 41.5227],
            [-87.5256, 41.4989],
            [-87.5679, 41.5136],
            [-87.5679, 41.5209],
            [-87.5679, 41.5356],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735340',
        NAME: 'Sandridge School District 172',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10752690,
        AWATER: 151971,
        INTPTLAT: '+41.5159840',
        INTPTLON: '-087.5450579',
        ELSDLEA: '35340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6472, 41.6487],
            [-87.6367, 41.6574],
            [-87.6172, 41.6446],
            [-87.6472, 41.6487],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730900',
        NAME: 'General George Patton School District 133',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3010989,
        AWATER: 391018,
        INTPTLAT: '+41.6509620',
        INTPTLON: '-087.6310197',
        ELSDLEA: '30900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3729, 40.1651],
            [-89.3676, 40.1226],
            [-89.3907, 40.1357],
            [-89.3729, 40.1651],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722980',
        NAME: 'Lincoln Elementary School District 27',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12759763,
        AWATER: 0,
        INTPTLAT: '+40.1490495',
        INTPTLON: '-089.3682466',
        ELSDLEA: '22980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7969, 41.6753],
            [-87.7782, 41.6756],
            [-87.7581, 41.6614],
            [-87.777, 41.6465],
            [-87.7957, 41.6462],
            [-87.7969, 41.6753],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730570',
        NAME: 'Palos Heights School District 128',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10088321,
        AWATER: 206653,
        INTPTLAT: '+41.6632299',
        INTPTLON: '-087.7758493',
        ELSDLEA: '30570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.166, 41.4524],
            [-89.1555, 41.4544],
            [-89.146, 41.4546],
            [-89.0493, 41.4556],
            [-89.0486, 41.3466],
            [-89.1061, 41.3609],
            [-89.1643, 41.3485],
            [-89.1643, 41.35],
            [-89.1648, 41.3794],
            [-89.1657, 41.4302],
            [-89.166, 41.4524],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712270',
        NAME: 'Dimmick Community Consolidated School District 175',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113405064,
        AWATER: 79075,
        INTPTLAT: '+41.4052259',
        INTPTLON: '-089.0976686',
        ELSDLEA: '12270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.3206, 41.3786],
              [-89.3157, 41.3827],
              [-89.3102, 41.3931],
              [-89.2776, 41.4078],
              [-89.2582, 41.4078],
              [-89.1805, 41.3791],
              [-89.1993, 41.3566],
              [-89.3157, 41.3692],
              [-89.3206, 41.3786],
            ],
          ],
          [
            [
              [-89.3395, 41.3494],
              [-89.3346, 41.3493],
              [-89.3346, 41.335],
              [-89.3353, 41.3007],
              [-89.3567, 41.2332],
              [-89.3939, 41.2337],
              [-89.3395, 41.3494],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721510',
        NAME: 'Ladd Community Consolidated School District 94',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92249278,
        AWATER: 4820734,
        INTPTLAT: '+41.3745032',
        INTPTLON: '-089.2288426',
        ELSDLEA: '21510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8843, 41.9575],
            [-87.853, 41.9431],
            [-87.8768, 41.9438],
            [-87.8843, 41.9575],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735640',
        NAME: 'Schiller Park School District 81',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7856333,
        AWATER: 0,
        INTPTLAT: '+41.9580542',
        INTPTLON: '-087.8687587',
        ELSDLEA: '35640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7928, 41.5585],
            [-87.7643, 41.5589],
            [-87.7524, 41.557],
            [-87.7325, 41.528],
            [-87.7322, 41.4697],
            [-87.7873, 41.4698],
            [-87.7903, 41.4698],
            [-87.7903, 41.499],
            [-87.7928, 41.5585],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736300',
        NAME: 'Elementary School District 159',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44821887,
        AWATER: 307884,
        INTPTLAT: '+41.5105336',
        INTPTLON: '-087.7619289',
        ELSDLEA: '36300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7663, 42.0515],
            [-87.766, 42.0639],
            [-87.7324, 42.0647],
            [-87.7279, 42.0371],
            [-87.7378, 42.0371],
            [-87.7639, 42.0409],
            [-87.7663, 42.0515],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736450',
        NAME: 'Skokie School District 68',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8642260,
        AWATER: 0,
        INTPTLAT: '+42.0522967',
        INTPTLON: '-087.7462512',
        ELSDLEA: '36450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7767, 42.0264],
            [-87.7639, 42.0409],
            [-87.7378, 42.0371],
            [-87.7428, 42.0226],
            [-87.7769, 42.0223],
            [-87.7767, 42.0264],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736480',
        NAME: 'Skokie School District 69',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5523584,
        AWATER: 0,
        INTPTLAT: '+42.0311170',
        INTPTLON: '-087.7558320',
        ELSDLEA: '36480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5773, 41.5973],
            [-87.5587, 41.5945],
            [-87.5589, 41.5779],
            [-87.5784, 41.5807],
            [-87.6121, 41.5828],
            [-87.5773, 41.5973],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736720',
        NAME: 'South Holland School District 150',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8023527,
        AWATER: 40377,
        INTPTLAT: '+41.5879359',
        INTPTLON: '-087.5850832',
        ELSDLEA: '36720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6266, 41.6206],
            [-87.6171, 41.619],
            [-87.5775, 41.601],
            [-87.5773, 41.5973],
            [-87.6121, 41.5828],
            [-87.6364, 41.5823],
            [-87.6364, 41.5918],
            [-87.6266, 41.6206],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736750',
        NAME: 'South Holland School District 151',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12233737,
        AWATER: 0,
        INTPTLAT: '+41.6016046',
        INTPTLON: '-087.6145438',
        ELSDLEA: '36750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8007, 41.7705],
            [-87.7421, 41.7711],
            [-87.7411, 41.7345],
            [-87.7606, 41.7342],
            [-87.7991, 41.7335],
            [-87.8003, 41.7627],
            [-87.8007, 41.7705],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736810',
        NAME: 'Burbank School District 111',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19759066,
        AWATER: 0,
        INTPTLAT: '+41.7523372',
        INTPTLON: '-087.7707159',
        ELSDLEA: '36810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.7827, 41.8215],
              [-87.7778, 41.8215],
              [-87.7449, 41.822],
              [-87.7531, 41.8134],
              [-87.782, 41.7997],
              [-87.782, 41.8044],
              [-87.7821, 41.8063],
              [-87.7827, 41.8215],
            ],
          ],
          [
            [
              [-87.8008, 41.7737],
              [-87.7421, 41.7711],
              [-87.8007, 41.7705],
              [-87.8008, 41.7737],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737860',
        NAME: 'Central Stickney School District 110',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8048021,
        AWATER: 323782,
        INTPTLAT: '+41.8035539',
        INTPTLON: '-087.7684739',
        ELSDLEA: '37860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7804, 42.1235],
            [-87.7704, 42.1197],
            [-87.7707, 42.1086],
            [-87.7707, 42.0941],
            [-87.799, 42.1054],
            [-87.8043, 42.1135],
            [-87.7804, 42.1235],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738400',
        NAME: 'Sunset Ridge School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7636109,
        AWATER: 200685,
        INTPTLAT: '+42.1086156',
        INTPTLON: '-087.7857575',
        ELSDLEA: '38400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6364, 41.5823],
            [-87.6121, 41.5828],
            [-87.5784, 41.5807],
            [-87.5782, 41.5765],
            [-87.5782, 41.5714],
            [-87.5781, 41.5575],
            [-87.6263, 41.5574],
            [-87.6266, 41.5605],
            [-87.6363, 41.5719],
            [-87.6364, 41.5823],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738910',
        NAME: 'Thornton School District 154',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11220664,
        AWATER: 208984,
        INTPTLAT: '+41.5694831',
        INTPTLON: '-087.6017086',
        ELSDLEA: '38910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7952, 41.6316],
            [-87.7957, 41.6462],
            [-87.777, 41.6465],
            [-87.7768, 41.6403],
            [-87.7765, 41.6319],
            [-87.7761, 41.6172],
            [-87.7758, 41.6099],
            [-87.7665, 41.6101],
            [-87.7752, 41.5952],
            [-87.7644, 41.566],
            [-87.7643, 41.5589],
            [-87.7928, 41.5585],
            [-87.7938, 41.5918],
            [-87.8137, 41.602],
            [-87.7952, 41.6316],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739030',
        NAME: 'Community Consolidated School District 146',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25770889,
        AWATER: 37424,
        INTPTLAT: '+41.6009789',
        INTPTLON: '-087.7880825',
        ELSDLEA: '39030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9514, 41.1709],
            [-88.9516, 41.1782],
            [-88.9321, 41.1854],
            [-88.9322, 41.1927],
            [-88.9296, 41.2072],
            [-88.9267, 41.2072],
            [-88.9118, 41.2067],
            [-88.7828, 41.15],
            [-88.8065, 41.1066],
            [-88.9192, 41.1059],
            [-88.9595, 41.1127],
            [-88.9514, 41.1709],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700112',
        NAME: 'Streator Elementary School District 44',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95161395,
        AWATER: 2543,
        INTPTLAT: '+41.1421203',
        INTPTLON: '-088.8811489',
        ELSDLEA: '00112',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7532, 41.3309],
            [-88.7249, 41.3382],
            [-88.6862, 41.3458],
            [-88.6742, 41.3285],
            [-88.6949, 41.2998],
            [-88.705, 41.3161],
            [-88.7532, 41.3309],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705466',
        NAME: 'Marseilles Elementary School District 150',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12772563,
        AWATER: 1789605,
        INTPTLAT: '+41.3271566',
        INTPTLON: '-088.6987446',
        ELSDLEA: '05466',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0483, 41.32],
            [-88.9911, 41.3257],
            [-88.9523, 41.3179],
            [-88.8699, 41.2962],
            [-88.894, 41.2293],
            [-88.8939, 41.2209],
            [-88.9118, 41.2067],
            [-88.9267, 41.2072],
            [-88.9423, 41.2151],
            [-89.0009, 41.253],
            [-89.0486, 41.2786],
            [-89.0483, 41.32],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711910',
        NAME: 'Deer Park Community Consolidated School District 82',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125651487,
        AWATER: 6428467,
        INTPTLAT: '+41.2763206',
        INTPTLON: '-088.9560492',
        ELSDLEA: '11910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9118, 41.2067],
            [-88.8939, 41.2209],
            [-88.894, 41.2293],
            [-88.8699, 41.2962],
            [-88.8172, 41.3428],
            [-88.7532, 41.3309],
            [-88.705, 41.3161],
            [-88.7046, 41.2887],
            [-88.7032, 41.2141],
            [-88.703, 41.2013],
            [-88.7029, 41.1942],
            [-88.7828, 41.15],
            [-88.9118, 41.2067],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717220',
        NAME: 'Grand Ridge Community Consolidated School District 95',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279432659,
        AWATER: 784030,
        INTPTLAT: '+41.2300614',
        INTPTLON: '-088.7895595',
        ELSDLEA: '17220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1061, 41.3609],
            [-89.0486, 41.3466],
            [-89.0483, 41.32],
            [-89.1062, 41.3137],
            [-89.1061, 41.3609],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722080',
        NAME: 'LaSalle Elementary School District 122',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15196056,
        AWATER: 1152903,
        INTPTLAT: '+41.3349145',
        INTPTLON: '-089.0836756',
        ELSDLEA: '22080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1637, 41.3103],
            [-89.1062, 41.3137],
            [-89.0483, 41.32],
            [-89.0486, 41.2786],
            [-89.0868, 41.265],
            [-89.1442, 41.265],
            [-89.1637, 41.3102],
            [-89.1637, 41.3103],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729670',
        NAME: 'Oglesby Elementary School District 125',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57942876,
        AWATER: 1482740,
        INTPTLAT: '+41.2903957',
        INTPTLON: '-089.1022900',
        ELSDLEA: '29670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9617, 41.3296],
            [-88.9377, 41.3262],
            [-88.8008, 41.3684],
            [-88.8172, 41.3428],
            [-88.8699, 41.2962],
            [-88.9523, 41.3179],
            [-88.9911, 41.3257],
            [-88.9617, 41.3296],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730300',
        NAME: 'Ottawa Elementary School District 141',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53878628,
        AWATER: 5170165,
        INTPTLAT: '+41.3282054',
        INTPTLON: '-088.8577228',
        ELSDLEA: '30300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8008, 41.3684],
            [-88.7759, 41.4139],
            [-88.7077, 41.4337],
            [-88.7249, 41.3382],
            [-88.7532, 41.3309],
            [-88.8172, 41.3428],
            [-88.8008, 41.3684],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735100',
        NAME: 'Rutland Community Consolidated School District 230',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65132177,
        AWATER: 1406682,
        INTPTLAT: '+41.3792755',
        INTPTLON: '-088.7593444',
        ELSDLEA: '35100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1442, 41.265],
            [-89.0868, 41.265],
            [-89.0486, 41.2786],
            [-89.0009, 41.253],
            [-88.9423, 41.2151],
            [-88.9267, 41.2072],
            [-88.9296, 41.2072],
            [-88.9322, 41.1927],
            [-88.9321, 41.1854],
            [-88.9516, 41.1782],
            [-88.9514, 41.1709],
            [-89.1626, 41.1923],
            [-89.1442, 41.265],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739180',
        NAME: 'Tonica Community Consolidated School District 79',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149283477,
        AWATER: 108772,
        INTPTLAT: '+41.2182067',
        INTPTLON: '-089.0597687',
        ELSDLEA: '39180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1805, 41.3791],
            [-89.1648, 41.3794],
            [-89.1643, 41.35],
            [-89.1993, 41.3566],
            [-89.1805, 41.3791],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711700',
        NAME: 'Dalzell School District 98',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9394317,
        AWATER: 26104,
        INTPTLAT: '+41.3645458',
        INTPTLON: '-089.1739373',
        ELSDLEA: '11700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8072, 41.9673],
            [-87.8072, 41.9683],
            [-87.8073, 41.9539],
            [-87.8072, 41.9673],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739780',
        NAME: 'Union Ridge School District 86',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1978371,
        AWATER: 0,
        INTPTLAT: '+41.9631903',
        INTPTLON: '-087.7980454',
        ELSDLEA: '39780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5872, 41.4987],
            [-87.5679, 41.5136],
            [-87.5256, 41.4989],
            [-87.5257, 41.4703],
            [-87.5867, 41.47],
            [-87.5872, 41.4987],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735460',
        NAME: 'Community Consolidated School District 168',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18907125,
        AWATER: 91423,
        INTPTLAT: '+41.4874597',
        INTPTLON: '-087.5559986',
        ELSDLEA: '35460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5685, 41.5575],
            [-87.5587, 41.5575],
            [-87.5254, 41.5575],
            [-87.525, 41.5283],
            [-87.5586, 41.5359],
            [-87.5679, 41.5356],
            [-87.5685, 41.5575],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738370',
        NAME: 'Sunnybrook School District 171',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11434940,
        AWATER: 199822,
        INTPTLAT: '+41.5437756',
        INTPTLON: '-087.5459619',
        ELSDLEA: '38370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1643, 41.3485],
            [-89.1061, 41.3609],
            [-89.1062, 41.3137],
            [-89.1637, 41.3103],
            [-89.1643, 41.3485],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731380',
        NAME: 'Peru Elementary School District 124',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18223759,
        AWATER: 497546,
        INTPTLAT: '+41.3360872',
        INTPTLON: '-089.1338124',
        ELSDLEA: '31380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1657, 41.4302],
            [-89.1648, 41.3794],
            [-89.1805, 41.3791],
            [-89.2582, 41.4078],
            [-89.1657, 41.4302],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709720',
        NAME: 'Cherry School District 92',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30897030,
        AWATER: 0,
        INTPTLAT: '+41.4193944',
        INTPTLON: '-089.2066571',
        ELSDLEA: '09720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3346, 41.3493],
            [-89.3257, 41.3693],
            [-89.3157, 41.3692],
            [-89.1993, 41.3566],
            [-89.1643, 41.35],
            [-89.1643, 41.3485],
            [-89.1637, 41.3103],
            [-89.1637, 41.3102],
            [-89.2774, 41.3167],
            [-89.3346, 41.335],
            [-89.3346, 41.3493],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737050',
        NAME: 'Spring Valley Community Consolidated School District 99',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47703861,
        AWATER: 1651246,
        INTPTLAT: '+41.3391208',
        INTPTLON: '-089.2331365',
        ELSDLEA: '37050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3938, 41.4949],
            [-89.3163, 41.4808],
            [-89.2776, 41.4078],
            [-89.3102, 41.3931],
            [-89.3157, 41.3827],
            [-89.3257, 41.3827],
            [-89.3257, 41.3785],
            [-89.3933, 41.393],
            [-89.3938, 41.4949],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724120',
        NAME: 'Malden Community Consolidated School District 84',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93487509,
        AWATER: 45653,
        INTPTLAT: '+41.4276146',
        INTPTLON: '-089.3436613',
        ELSDLEA: '24120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7759, 41.4139],
            [-88.8008, 41.3684],
            [-88.9377, 41.3262],
            [-88.9339, 41.4399],
            [-88.8453, 41.4558],
            [-88.7759, 41.4139],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740530',
        NAME: 'Wallace Community Consolidated School District 195',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126281656,
        AWATER: 353280,
        INTPTLAT: '+41.4044055',
        INTPTLON: '-088.8644641',
        ELSDLEA: '40530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0493, 41.4556],
            [-89.0248, 41.4553],
            [-88.9675, 41.4546],
            [-88.9675, 41.4475],
            [-88.9339, 41.4399],
            [-88.9377, 41.3262],
            [-88.9617, 41.3296],
            [-88.9911, 41.3257],
            [-89.0483, 41.32],
            [-89.0486, 41.3466],
            [-89.0493, 41.4556],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740620',
        NAME: 'Waltham Community Consolidated School District 185',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134830099,
        AWATER: 475557,
        INTPTLAT: '+41.3914228',
        INTPTLON: '-088.9982846',
        ELSDLEA: '40620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8878, 42.1237],
            [-87.8537, 42.1166],
            [-87.8492, 42.0949],
            [-87.888, 42.0873],
            [-87.8881, 42.0948],
            [-87.8878, 42.1237],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741700',
        NAME: 'West Northfield School District 31',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11087186,
        AWATER: 40314,
        INTPTLAT: '+42.1043028',
        INTPTLON: '-087.8696988',
        ELSDLEA: '41700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8828, 41.8709],
            [-87.8662, 41.8637],
            [-87.8659, 41.8564],
            [-87.8806, 41.8449],
            [-87.8818, 41.8374],
            [-87.886, 41.834],
            [-87.9006, 41.8336],
            [-87.8828, 41.8709],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741790',
        NAME: 'Westchester School District 92-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7489415,
        AWATER: 0,
        INTPTLAT: '+41.8524605',
        INTPTLON: '-087.8843177',
        ELSDLEA: '41790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5593, 41.6373],
            [-87.5251, 41.6302],
            [-87.5251, 41.6155],
            [-87.5591, 41.6154],
            [-87.5593, 41.6373],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741520',
        NAME: 'Calumet City School District 155',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5829584,
        AWATER: 0,
        INTPTLAT: '+41.6250041',
        INTPTLON: '-087.5437240',
        ELSDLEA: '41520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6306, 38.5723],
            [-89.5815, 38.5764],
            [-89.5806, 38.5316],
            [-89.64, 38.5361],
            [-89.6306, 38.5723],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703270',
        NAME: 'Albers School District 63',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21405622,
        AWATER: 619224,
        INTPTLAT: '+38.5522041',
        INTPTLON: '-089.6040279',
        ELSDLEA: '03270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4981, 38.566],
            [-89.4276, 38.5611],
            [-89.4278, 38.4989],
            [-89.4974, 38.4733],
            [-89.4981, 38.566],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705160',
        NAME: 'Bartelso School District 57',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60985865,
        AWATER: 307208,
        INTPTLAT: '+38.5206441',
        INTPTLON: '-089.4599985',
        ELSDLEA: '05160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5815, 38.5764],
            [-89.5715, 38.5828],
            [-89.4981, 38.566],
            [-89.4974, 38.4733],
            [-89.5712, 38.4827],
            [-89.5806, 38.5316],
            [-89.5815, 38.5764],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716530',
        NAME: 'Germantown School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73574962,
        AWATER: 230616,
        INTPTLAT: '+38.5174977',
        INTPTLON: '-089.5406471',
        ELSDLEA: '16530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.64, 38.5361],
            [-89.5806, 38.5316],
            [-89.5712, 38.4827],
            [-89.5933, 38.4755],
            [-89.64, 38.5361],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711730',
        NAME: 'Damiansville School District 62',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41411296,
        AWATER: 408563,
        INTPTLAT: '+38.5063131',
        INTPTLON: '-089.6171242',
        ELSDLEA: '11730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9713, 40.3991],
            [-87.9329, 40.3994],
            [-87.8598, 40.3715],
            [-87.8338, 40.2558],
            [-87.7361, 40.2037],
            [-87.9227, 40.1855],
            [-87.9418, 40.2035],
            [-87.9421, 40.2255],
            [-87.9304, 40.3171],
            [-87.9713, 40.3991],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704200',
        NAME: 'Armstrong-Ellis Consolidated School District 61',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237155854,
        AWATER: 183318,
        INTPTLAT: '+40.2630582',
        INTPTLON: '-087.8733238',
        ELSDLEA: '04200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1175, 40.3122],
            [-88.079, 40.371],
            [-88.0405, 40.3711],
            [-87.9713, 40.3991],
            [-87.9304, 40.3171],
            [-87.9421, 40.2255],
            [-87.9478, 40.2254],
            [-88.0016, 40.2288],
            [-88.0017, 40.25],
            [-88.0018, 40.2543],
            [-88.1088, 40.2833],
            [-88.1137, 40.2867],
            [-88.1172, 40.2867],
            [-88.1173, 40.2973],
            [-88.1175, 40.3122],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716650',
        NAME: 'Gifford Community Consolidated School District 188',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181685238,
        AWATER: 232868,
        INTPTLAT: '+40.3193021',
        INTPTLON: '-088.0112425',
        ELSDLEA: '16650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1252, 40.1714],
            [-88.0096, 40.1644],
            [-88.0097, 40.0688],
            [-88.0288, 40.0543],
            [-88.1239, 40.055],
            [-88.1252, 40.1714],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737380',
        NAME: 'St. Joseph Community Consolidated School District 169',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123375565,
        AWATER: 482052,
        INTPTLAT: '+40.1067600',
        INTPTLON: '-088.0802358',
        ELSDLEA: '37380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2511, 40.253],
            [-88.1173, 40.2973],
            [-88.1172, 40.2867],
            [-88.1358, 40.2245],
            [-88.1357, 40.2152],
            [-88.1444, 40.2076],
            [-88.1445, 40.2151],
            [-88.1557, 40.215],
            [-88.1556, 40.2076],
            [-88.1634, 40.2075],
            [-88.2019, 40.1983],
            [-88.2208, 40.2071],
            [-88.2698, 40.2237],
            [-88.2511, 40.253],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738790',
        NAME: 'Thomasboro Community Consolidated School District 130',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87451402,
        AWATER: 41329,
        INTPTLAT: '+40.2494936',
        INTPTLON: '-088.1881716',
        ELSDLEA: '38790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5032, 40.7476],
            [-89.4693, 40.7477],
            [-89.4558, 40.7476],
            [-89.4453, 40.6863],
            [-89.5028, 40.6985],
            [-89.5032, 40.7476],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709150',
        NAME: 'Central School District 51',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33587832,
        AWATER: 0,
        INTPTLAT: '+40.7184546',
        INTPTLON: '-089.4643710',
        ELSDLEA: '09150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6161, 40.6608],
            [-89.6078, 40.6607],
            [-89.5651, 40.6266],
            [-89.5888, 40.6263],
            [-89.6033, 40.63],
            [-89.624, 40.6335],
            [-89.6161, 40.6608],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711290',
        NAME: 'Creve Coeur School District 76',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13457801,
        AWATER: 711675,
        INTPTLAT: '+40.6412472',
        INTPTLON: '-089.5939355',
        ELSDLEA: '11290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5506, 40.7387],
            [-89.555, 40.7476],
            [-89.5174, 40.7476],
            [-89.5032, 40.7476],
            [-89.5028, 40.6985],
            [-89.5028, 40.6966],
            [-89.5266, 40.6837],
            [-89.5127, 40.6686],
            [-89.5549, 40.6119],
            [-89.5651, 40.6266],
            [-89.6078, 40.6607],
            [-89.6161, 40.6608],
            [-89.5506, 40.7387],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713240',
        NAME: 'East Peoria School District 86',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63299761,
        AWATER: 9376391,
        INTPTLAT: '+40.6792169',
        INTPTLON: '-089.5418678',
        ELSDLEA: '13240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6824, 40.5526],
            [-89.6588, 40.5892],
            [-89.5888, 40.6263],
            [-89.5651, 40.6266],
            [-89.5549, 40.6119],
            [-89.542, 40.568],
            [-89.5682, 40.5095],
            [-89.6824, 40.5526],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731080',
        NAME: 'Pekin Public School District108',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73630854,
        AWATER: 1582366,
        INTPTLAT: '+40.5673455',
        INTPTLON: '-089.6001464',
        ELSDLEA: '31080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7331, 40.5527],
            [-89.6888, 40.5585],
            [-89.6824, 40.5526],
            [-89.5682, 40.5095],
            [-89.6064, 40.4805],
            [-89.6481, 40.48],
            [-89.6447, 40.509],
            [-89.6547, 40.5091],
            [-89.6635, 40.4798],
            [-89.7184, 40.4934],
            [-89.7331, 40.5527],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733120',
        NAME: 'Rankin Community School District 98',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79614257,
        AWATER: 578294,
        INTPTLAT: '+40.5335770',
        INTPTLON: '-089.6884539',
        ELSDLEA: '33120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7055, 42.241],
            [-88.5932, 42.2407],
            [-88.521, 42.1832],
            [-88.5104, 42.1539],
            [-88.5887, 42.1536],
            [-88.7056, 42.1536],
            [-88.7055, 42.241],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733750',
        NAME: 'Riley Community Consolidated School District 18',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123948082,
        AWATER: 271359,
        INTPTLAT: '+42.1927508',
        INTPTLON: '-088.6265987',
        ELSDLEA: '33750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5028, 40.6966],
            [-89.5027, 40.6819],
            [-89.5127, 40.6746],
            [-89.5127, 40.6686],
            [-89.5266, 40.6837],
            [-89.5028, 40.6966],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734170',
        NAME: 'Robein School District 85',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5314056,
        AWATER: 0,
        INTPTLAT: '+40.6821013',
        INTPTLON: '-089.5206518',
        ELSDLEA: '34170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6364, 40.6232],
            [-89.624, 40.6335],
            [-89.6033, 40.63],
            [-89.5888, 40.6263],
            [-89.6588, 40.5892],
            [-89.6364, 40.6232],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728920',
        NAME: 'North Pekin-Marquette Heights School District 102',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18056058,
        AWATER: 2249693,
        INTPTLAT: '+40.6081745',
        INTPTLON: '-089.6153337',
        ELSDLEA: '28920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2331, 40.3408],
            [-88.1175, 40.3122],
            [-88.1173, 40.2973],
            [-88.2511, 40.253],
            [-88.2331, 40.3408],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733210',
        NAME: 'Rantoul City School District 137',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68532536,
        AWATER: 242691,
        INTPTLAT: '+40.3035565',
        INTPTLON: '-088.2034340',
        ELSDLEA: '33210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2337, 40.3959],
            [-88.079, 40.371],
            [-88.1175, 40.3122],
            [-88.2331, 40.3408],
            [-88.2337, 40.3959],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723700',
        NAME: 'Ludlow Community Consolidated School District 142',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92149479,
        AWATER: 37870,
        INTPTLAT: '+40.3552744',
        INTPTLON: '-088.1577034',
        ELSDLEA: '23700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8635, 40.5177],
            [-89.7331, 40.5527],
            [-89.7184, 40.4934],
            [-89.8635, 40.5177],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737020',
        NAME: 'Spring Lake Community Consolidated School District 606',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35535068,
        AWATER: 1617589,
        INTPTLAT: '+40.5391804',
        INTPTLON: '-089.7703995',
        ELSDLEA: '37020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5027, 40.6819],
            [-89.5028, 40.6966],
            [-89.5028, 40.6985],
            [-89.4453, 40.6863],
            [-89.4457, 40.6601],
            [-89.5023, 40.6746],
            [-89.5127, 40.6746],
            [-89.5027, 40.6819],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741010',
        NAME: 'District 50 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19224265,
        AWATER: 34035,
        INTPTLAT: '+40.6727781',
        INTPTLON: '-089.4710068',
        ELSDLEA: '41010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4558, 40.7476],
            [-89.3402, 40.7482],
            [-89.3296, 40.6608],
            [-89.3864, 40.6605],
            [-89.4457, 40.6601],
            [-89.4453, 40.6863],
            [-89.4558, 40.7476],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741040',
        NAME: 'Washington School District 52',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86988443,
        AWATER: 0,
        INTPTLAT: '+40.7045950',
        INTPTLON: '-089.3844857',
        ELSDLEA: '41040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6481, 40.48],
            [-89.6635, 40.4798],
            [-89.6547, 40.5091],
            [-89.6447, 40.509],
            [-89.6481, 40.48],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736780',
        NAME: 'South Pekin School District 137',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7773161,
        AWATER: 0,
        INTPTLAT: '+40.5017778',
        INTPTLON: '-089.6566650',
        ELSDLEA: '36780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8598, 40.3715],
            [-87.7649, 40.3904],
            [-87.7602, 40.3868],
            [-87.7409, 40.3577],
            [-87.7356, 40.2036],
            [-87.7361, 40.2037],
            [-87.8338, 40.2558],
            [-87.8598, 40.3715],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732090',
        NAME: 'Potomac Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144092179,
        AWATER: 39605,
        INTPTLAT: '+40.3136953',
        INTPTLON: '-087.7880184',
        ELSDLEA: '32090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0395, 42.4627],
            [-89.0196, 42.463],
            [-88.9407, 42.4454],
            [-88.9407, 42.4192],
            [-88.9408, 42.4027],
            [-88.9825, 42.403],
            [-89.0394, 42.3912],
            [-89.0395, 42.4627],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721180',
        NAME: 'Kinnikinnick Community Consolidated School District 131',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47201512,
        AWATER: 1148470,
        INTPTLAT: '+42.4285789',
        INTPTLON: '-088.9966276',
        ELSDLEA: '21180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2627, 42.4982],
            [-89.1299, 42.4971],
            [-89.1206, 42.497],
            [-89.1976, 42.4358],
            [-89.2627, 42.4982],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736240',
        NAME: 'Shirland Community Consolidated School District 134',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77010334,
        AWATER: 763965,
        INTPTLAT: '+42.4713828',
        INTPTLON: '-089.1868189',
        ELSDLEA: '36240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0116, 42.4961],
            [-89.0013, 42.496],
            [-88.9519, 42.4953],
            [-88.9407, 42.4454],
            [-89.0196, 42.463],
            [-89.0116, 42.4961],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732550',
        NAME: 'Prairie Hill Community Consolidated School District 133',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30953413,
        AWATER: 63376,
        INTPTLAT: '+42.4641860',
        INTPTLON: '-088.9719360',
        ELSDLEA: '32550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4059, 41.5001],
            [-90.3179, 41.5344],
            [-90.3179, 41.5088],
            [-90.367, 41.4995],
            [-90.3767, 41.4787],
            [-90.3913, 41.4734],
            [-90.4059, 41.5001],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708310',
        NAME: 'Carbon Cliff-Barstow School District 36',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18362942,
        AWATER: 804477,
        INTPTLAT: '+41.5093884',
        INTPTLON: '-090.3649392',
        ELSDLEA: '08310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4614, 41.5235],
            [-90.4251, 41.5526],
            [-90.3962, 41.5368],
            [-90.4059, 41.5001],
            [-90.3913, 41.4734],
            [-90.4319, 41.4568],
            [-90.4614, 41.5235],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713170',
        NAME: 'East Moline School District 37',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34096804,
        AWATER: 3487636,
        INTPTLAT: '+41.5035400',
        INTPTLON: '-090.4293090',
        ELSDLEA: '13170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3561, 41.5828],
            [-90.3569, 41.549],
            [-90.3962, 41.5368],
            [-90.4251, 41.5526],
            [-90.3561, 41.5828],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718090',
        NAME: 'Hampton School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11994499,
        AWATER: 2962961,
        INTPTLAT: '+41.5632373',
        INTPTLON: '-090.3840924',
        ELSDLEA: '18090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3962, 41.5368],
            [-90.3569, 41.549],
            [-90.3179, 41.5344],
            [-90.4059, 41.5001],
            [-90.3962, 41.5368],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736360',
        NAME: 'Silvis School District 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24366570,
        AWATER: 0,
        INTPTLAT: '+41.5299107',
        INTPTLON: '-090.3733050',
        ELSDLEA: '36360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2329, 42.3861],
            [-89.1976, 42.4358],
            [-89.1206, 42.497],
            [-89.0795, 42.4963],
            [-89.0395, 42.4627],
            [-89.0394, 42.3912],
            [-89.0547, 42.3817],
            [-89.1743, 42.3676],
            [-89.2329, 42.3861],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734540',
        NAME: 'Rockton School District 140',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129322629,
        AWATER: 2273604,
        INTPTLAT: '+42.4167790',
        INTPTLON: '-089.1277848',
        ELSDLEA: '34540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8268, 38.686],
            [-88.6991, 38.6933],
            [-88.6987, 38.5622],
            [-88.699, 38.4837],
            [-88.7196, 38.4837],
            [-88.81, 38.5051],
            [-88.8461, 38.5623],
            [-88.8268, 38.686],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720250',
        NAME: 'Iuka Community Consolidated School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248037277,
        AWATER: 447678,
        INTPTLAT: '+38.5920054',
        INTPTLON: '-088.7593112',
        ELSDLEA: '20250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0503, 38.5258],
            [-89.0322, 38.5328],
            [-89.0321, 38.5515],
            [-89.032, 38.5577],
            [-89.0318, 38.5651],
            [-88.9494, 38.5862],
            [-88.8842, 38.5626],
            [-88.9411, 38.5058],
            [-88.9495, 38.5056],
            [-88.9653, 38.4759],
            [-88.9793, 38.4755],
            [-89.0331, 38.474],
            [-89.0577, 38.4742],
            [-89.0503, 38.5258],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733030',
        NAME: 'Raccoon Consolidated School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118431670,
        AWATER: 1344895,
        INTPTLAT: '+38.5247525',
        INTPTLON: '-088.9825953',
        ELSDLEA: '33030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9744, 38.7075],
            [-88.8268, 38.686],
            [-88.8461, 38.5623],
            [-88.8842, 38.5626],
            [-88.9494, 38.5862],
            [-88.9744, 38.7075],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735160',
        NAME: 'Salem School District 111',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147626078,
        AWATER: 1053895,
        INTPTLAT: '+38.6337054',
        INTPTLON: '-088.9089161',
        ELSDLEA: '35160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.031, 38.674],
            [-89.0311, 38.7068],
            [-88.9744, 38.7075],
            [-88.9494, 38.5862],
            [-89.0318, 38.5651],
            [-89.031, 38.674],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735770',
        NAME: 'Selmaville Community Consolidated School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79345365,
        AWATER: 14545,
        INTPTLAT: '+38.6310286',
        INTPTLON: '-088.9970929',
        ELSDLEA: '35770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5958, 41.4497],
            [-88.5179, 41.4433],
            [-88.5105, 41.4457],
            [-88.4815, 41.4504],
            [-88.4608, 41.3655],
            [-88.5167, 41.3307],
            [-88.5557, 41.3373],
            [-88.5913, 41.3298],
            [-88.5952, 41.4205],
            [-88.5956, 41.4404],
            [-88.5958, 41.4497],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727930',
        NAME: 'Nettle Creek Community Consolidated School District 24C',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118484353,
        AWATER: 941106,
        INTPTLAT: '+41.3919888',
        INTPTLON: '-088.5275412',
        ELSDLEA: '27930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5167, 41.3307],
            [-88.4608, 41.3655],
            [-88.3258, 41.3847],
            [-88.3817, 41.2998],
            [-88.5546, 41.3189],
            [-88.5167, 41.3307],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726610',
        NAME: 'Morris School District 54',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92387859,
        AWATER: 10973000,
        INTPTLAT: '+41.3203754',
        INTPTLON: '-088.3990093',
        ELSDLEA: '26610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3979, 41.1922],
            [-88.3979, 41.1925],
            [-88.3611, 41.2427],
            [-88.3136, 41.2436],
            [-88.2823, 41.2008],
            [-88.319, 41.1418],
            [-88.3182, 41.1273],
            [-88.3236, 41.1417],
            [-88.3381, 41.1414],
            [-88.3573, 41.1409],
            [-88.3966, 41.1595],
            [-88.3967, 41.1602],
            [-88.3968, 41.1634],
            [-88.397, 41.1687],
            [-88.3979, 41.1922],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716230',
        NAME: 'Gardner Community Consolidated School District 72C',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82749928,
        AWATER: 462016,
        INTPTLAT: '+41.1893167',
        INTPTLON: '-088.3349366',
        ELSDLEA: '16230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4815, 41.4504],
            [-88.4817, 41.4579],
            [-88.4473, 41.4588],
            [-88.4335, 41.4664],
            [-88.429, 41.4626],
            [-88.4136, 41.4596],
            [-88.3472, 41.4385],
            [-88.3469, 41.4168],
            [-88.3416, 41.4015],
            [-88.3166, 41.4002],
            [-88.3181, 41.3886],
            [-88.3258, 41.3847],
            [-88.4608, 41.3655],
            [-88.4815, 41.4504],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735400',
        NAME: 'Saratoga Community Consolidated School District 60C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113495490,
        AWATER: 1721414,
        INTPTLAT: '+41.4123000',
        INTPTLON: '-088.4140753',
        ELSDLEA: '35400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5546, 41.3189],
            [-88.3817, 41.2998],
            [-88.3611, 41.2427],
            [-88.3979, 41.1925],
            [-88.3979, 41.1922],
            [-88.397, 41.1687],
            [-88.3993, 41.1689],
            [-88.5864, 41.1373],
            [-88.5867, 41.181],
            [-88.5869, 41.1954],
            [-88.5546, 41.3189],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725190',
        NAME: 'Mazon-Verona-Kinsman Elementary School District 2C',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279565889,
        AWATER: 1358510,
        INTPTLAT: '+41.2308752',
        INTPTLON: '-088.4815679',
        ELSDLEA: '25190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.705, 41.3161],
            [-88.6949, 41.2998],
            [-88.6742, 41.3285],
            [-88.5913, 41.3298],
            [-88.5557, 41.3373],
            [-88.5167, 41.3307],
            [-88.5546, 41.3189],
            [-88.5869, 41.1954],
            [-88.6062, 41.1953],
            [-88.6157, 41.1952],
            [-88.6667, 41.1946],
            [-88.6783, 41.1945],
            [-88.703, 41.2013],
            [-88.7032, 41.2141],
            [-88.7046, 41.2887],
            [-88.705, 41.3161],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735820',
        NAME: 'Seneca Community Consolidated School District 170',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159309391,
        AWATER: 10576350,
        INTPTLAT: '+41.2527208',
        INTPTLON: '-088.6317718',
        ELSDLEA: '35820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.4352, 40.9778],
              [-88.4312, 40.9778],
              [-88.4313, 40.9633],
              [-88.4349, 40.9633],
              [-88.4352, 40.9778],
            ],
          ],
          [
            [
              [-88.6324, 41.1079],
              [-88.5864, 41.1373],
              [-88.3993, 41.1689],
              [-88.397, 41.1687],
              [-88.3968, 41.1634],
              [-88.3967, 41.1602],
              [-88.3966, 41.1595],
              [-88.3573, 41.1409],
              [-88.3381, 41.1414],
              [-88.3236, 41.1417],
              [-88.3182, 41.1273],
              [-88.3192, 40.9937],
              [-88.3588, 40.9787],
              [-88.4162, 40.978],
              [-88.4362, 41.0068],
              [-88.456, 41.0209],
              [-88.4758, 41.0565],
              [-88.4768, 41.0565],
              [-88.5007, 41.0565],
              [-88.5083, 41.0641],
              [-88.5468, 41.0847],
              [-88.5853, 41.0713],
              [-88.6047, 41.0483],
              [-88.6048, 41.0428],
              [-88.6124, 41.0427],
              [-88.6124, 41.0482],
              [-88.6116, 41.0482],
              [-88.6132, 41.0482],
              [-88.619, 41.0482],
              [-88.6239, 41.0481],
              [-88.6328, 41.0481],
              [-88.6431, 41.0481],
              [-88.6622, 41.0554],
              [-88.6324, 41.1079],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712840',
        NAME: 'Dwight Common School District 232',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 330432418,
        AWATER: 147596,
        INTPTLAT: '+41.0852289',
        INTPTLON: '-088.4476537',
        ELSDLEA: '12840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7077, 41.4337],
            [-88.666, 41.4182],
            [-88.615, 41.4402],
            [-88.5965, 41.4751],
            [-88.5958, 41.4497],
            [-88.5956, 41.4404],
            [-88.5952, 41.4205],
            [-88.5913, 41.3298],
            [-88.6742, 41.3285],
            [-88.6862, 41.3458],
            [-88.7249, 41.3382],
            [-88.7077, 41.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726250',
        NAME: 'Miller Township Community Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124815247,
        AWATER: 13568,
        INTPTLAT: '+41.3840504',
        INTPTLON: '-088.6445261',
        ELSDLEA: '26250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5972, 41.4932],
            [-88.4637, 41.531],
            [-88.3755, 41.5173],
            [-88.3848, 41.4747],
            [-88.3847, 41.4675],
            [-88.4136, 41.4596],
            [-88.429, 41.4626],
            [-88.4335, 41.4664],
            [-88.4473, 41.4588],
            [-88.4817, 41.4579],
            [-88.4815, 41.4504],
            [-88.5105, 41.4457],
            [-88.5179, 41.4433],
            [-88.5958, 41.4497],
            [-88.5965, 41.4751],
            [-88.5972, 41.4932],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723160',
        NAME: 'Lisbon Community Consolidated School District 90',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112739306,
        AWATER: 0,
        INTPTLAT: '+41.4845582',
        INTPTLON: '-088.4811830',
        ELSDLEA: '23160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3136, 41.2436],
            [-88.2455, 41.2446],
            [-88.2442, 41.2015],
            [-88.2823, 41.2008],
            [-88.3136, 41.2436],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706840',
        NAME: 'Braceville School District 75',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21257896,
        AWATER: 445840,
        INTPTLAT: '+41.2234884',
        INTPTLON: '-088.2721140',
        ELSDLEA: '06840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2823, 41.2008],
            [-88.2442, 41.2015],
            [-88.2093, 41.1731],
            [-88.319, 41.1418],
            [-88.2823, 41.2008],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736840',
        NAME: 'South Wilmington Consolidated School District 74',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32297687,
        AWATER: 2818212,
        INTPTLAT: '+41.1744122',
        INTPTLON: '-088.2586844',
        ELSDLEA: '36840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4349, 40.9633],
            [-88.4313, 40.9633],
            [-88.4312, 40.9778],
            [-88.4162, 40.978],
            [-88.3588, 40.9787],
            [-88.3488, 40.9498],
            [-88.3548, 40.8232],
            [-88.4891, 40.8319],
            [-88.5246, 40.8348],
            [-88.5299, 40.9332],
            [-88.4349, 40.9633],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700065',
        NAME: 'Saunemin Community Consolidated School District 438',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218446305,
        AWATER: 73472,
        INTPTLAT: '+40.8987781',
        INTPTLON: '-088.4235885',
        ELSDLEA: '00065',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7828, 41.15],
            [-88.7029, 41.1942],
            [-88.703, 41.2013],
            [-88.6783, 41.1945],
            [-88.6667, 41.1946],
            [-88.6157, 41.1952],
            [-88.6062, 41.1953],
            [-88.5869, 41.1954],
            [-88.5867, 41.181],
            [-88.5864, 41.1373],
            [-88.6324, 41.1079],
            [-88.6622, 41.0554],
            [-88.6431, 41.0481],
            [-88.6522, 41.0481],
            [-88.6622, 41.0481],
            [-88.6996, 41.0558],
            [-88.8065, 41.1066],
            [-88.7828, 41.15],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703420',
        NAME: 'Allen Township Community Consolidated School District 65',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167371860,
        AWATER: 78178,
        INTPTLAT: '+41.1437621',
        INTPTLON: '-088.6732577',
        ELSDLEA: '03420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.816, 40.9589],
            [-88.7831, 41.0328],
            [-88.6996, 41.0558],
            [-88.6622, 41.0481],
            [-88.6522, 41.0481],
            [-88.6245, 40.9757],
            [-88.6329, 40.9611],
            [-88.7009, 40.9643],
            [-88.7011, 40.9315],
            [-88.7121, 40.9314],
            [-88.7588, 40.9309],
            [-88.7733, 40.939],
            [-88.7733, 40.9307],
            [-88.7971, 40.9305],
            [-88.816, 40.9589],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710860',
        NAME: 'Cornell Community Consolidated School District 426',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161795241,
        AWATER: 338506,
        INTPTLAT: '+40.9935122',
        INTPTLON: '-088.7233485',
        ELSDLEA: '10860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6522, 41.0481],
            [-88.6431, 41.0481],
            [-88.6328, 41.0481],
            [-88.6239, 41.0481],
            [-88.619, 41.0482],
            [-88.6132, 41.0482],
            [-88.6116, 41.0482],
            [-88.6124, 41.0482],
            [-88.6124, 41.0427],
            [-88.6048, 41.0428],
            [-88.6047, 41.0483],
            [-88.5853, 41.0713],
            [-88.5468, 41.0847],
            [-88.5083, 41.0641],
            [-88.5007, 41.0565],
            [-88.4768, 41.0565],
            [-88.4758, 41.0565],
            [-88.456, 41.0209],
            [-88.4362, 41.0068],
            [-88.4162, 40.978],
            [-88.4312, 40.9778],
            [-88.4352, 40.9778],
            [-88.4349, 40.9633],
            [-88.5299, 40.9332],
            [-88.6245, 40.9757],
            [-88.6522, 41.0481],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729520',
        NAME: 'Odell Community Consolidated School District 435',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207687907,
        AWATER: 73226,
        INTPTLAT: '+41.0023946',
        INTPTLON: '-088.5323559',
        ELSDLEA: '29520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7121, 40.9314],
            [-88.7011, 40.9315],
            [-88.7009, 40.9643],
            [-88.6329, 40.9611],
            [-88.6245, 40.9757],
            [-88.5299, 40.9332],
            [-88.5246, 40.8348],
            [-88.5258, 40.8311],
            [-88.527, 40.8239],
            [-88.6225, 40.7862],
            [-88.6998, 40.8239],
            [-88.7121, 40.9314],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732160',
        NAME: 'Pontiac Community Consolidated School District 429',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247944977,
        AWATER: 1006287,
        INTPTLAT: '+40.8816081',
        INTPTLON: '-088.6202831',
        ELSDLEA: '32160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7971, 40.9305],
            [-88.7733, 40.9307],
            [-88.7733, 40.939],
            [-88.7588, 40.9309],
            [-88.7121, 40.9314],
            [-88.6998, 40.8239],
            [-88.7672, 40.8229],
            [-88.7971, 40.9305],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734650',
        NAME: 'Rooks Creek Community Consolidated School District 425',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81924664,
        AWATER: 0,
        INTPTLAT: '+40.8820640',
        INTPTLON: '-088.7434483',
        ELSDLEA: '34650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5117, 41.4948],
            [-89.512, 41.5238],
            [-89.4795, 41.524],
            [-89.4519, 41.5241],
            [-89.4494, 41.5241],
            [-89.4327, 41.5193],
            [-89.4326, 41.5095],
            [-89.4266, 41.4949],
            [-89.3938, 41.4949],
            [-89.3933, 41.393],
            [-89.3257, 41.3785],
            [-89.3257, 41.3827],
            [-89.3157, 41.3827],
            [-89.3206, 41.3786],
            [-89.3157, 41.3692],
            [-89.3257, 41.3693],
            [-89.3346, 41.3493],
            [-89.3395, 41.3494],
            [-89.3939, 41.2337],
            [-89.4944, 41.1631],
            [-89.544, 41.234],
            [-89.6257, 41.2555],
            [-89.6257, 41.3068],
            [-89.5101, 41.3311],
            [-89.5117, 41.4948],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732670',
        NAME: 'Princeton Elementary School District 115',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504198871,
        AWATER: 804279,
        INTPTLAT: '+41.3441259',
        INTPTLON: '-089.4837593',
        ELSDLEA: '32670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.146, 38.1047],
            [-90.0807, 38.1231],
            [-90.0404, 38.0002],
            [-90.2067, 38.088],
            [-90.146, 38.1047],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732490',
        NAME: 'Prairie du Rocher Community Consolidated School District 134',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89456160,
        AWATER: 6847720,
        INTPTLAT: '+38.0723787',
        INTPTLON: '-090.0975553',
        ELSDLEA: '32490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9893, 38.6072],
            [-89.9813, 38.6071],
            [-89.9717, 38.6071],
            [-89.9663, 38.6023],
            [-89.9662, 38.5927],
            [-89.9662, 38.5865],
            [-89.9662, 38.5847],
            [-89.9662, 38.5786],
            [-89.9661, 38.5708],
            [-89.9939, 38.5715],
            [-89.9893, 38.6072],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732190',
        NAME: 'Pontiac-William Holliday School District 105',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9677693,
        AWATER: 96611,
        INTPTLAT: '+38.5906554',
        INTPTLON: '-089.9805953',
        ELSDLEA: '32190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0728, 38.3965],
            [-90.0364, 38.418],
            [-89.9643, 38.3589],
            [-89.9989, 38.3626],
            [-90.0361, 38.3233],
            [-90.0728, 38.3965],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736510',
        NAME: 'Smithton Community Consolidated School District 130',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62714597,
        AWATER: 1085686,
        INTPTLAT: '+38.3812430',
        INTPTLON: '-090.0232025',
        ELSDLEA: '36510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0885, 38.5708],
            [-90.0465, 38.5721],
            [-90.092, 38.5646],
            [-90.0885, 38.5708],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736330',
        NAME: 'Signal Hill School District 181',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7376637,
        AWATER: 219252,
        INTPTLAT: '+38.5746445',
        INTPTLON: '-090.0650502',
        ELSDLEA: '36330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7042, 38.4076],
            [-89.7038, 38.3354],
            [-89.741, 38.3356],
            [-89.772, 38.3498],
            [-89.7811, 38.394],
            [-89.7042, 38.4076],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737440',
        NAME: 'St. Libory Consolidated School District 30',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44238978,
        AWATER: 155560,
        INTPTLAT: '+38.3932880',
        INTPTLON: '-089.7361248',
        ELSDLEA: '37440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9291, 38.5706],
            [-89.8739, 38.5697],
            [-89.9288, 38.5427],
            [-89.9291, 38.5706],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736210',
        NAME: 'Shiloh Village School District 85',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14459477,
        AWATER: 120001,
        INTPTLAT: '+38.5584469',
        INTPTLON: '-089.9113531',
        ELSDLEA: '36210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6232, 38.6557],
            [-89.6085, 38.6704],
            [-89.5665, 38.6451],
            [-89.5715, 38.5828],
            [-89.5815, 38.5764],
            [-89.6306, 38.5723],
            [-89.6232, 38.6557],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704740',
        NAME: 'Aviston School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52563449,
        AWATER: 37708,
        INTPTLAT: '+38.6138467',
        INTPTLON: '-089.6002707',
        ELSDLEA: '04740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.002, 38.5712],
            [-89.9939, 38.5715],
            [-89.9661, 38.5708],
            [-89.9698, 38.5397],
            [-90.0019, 38.5431],
            [-90.002, 38.5712],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742960',
        NAME: 'Wolf Branch School District 113',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9733429,
        AWATER: 262169,
        INTPTLAT: '+38.5600758',
        INTPTLON: '-089.9815288',
        ELSDLEA: '42960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9661, 38.5708],
            [-89.9499, 38.5707],
            [-89.9473, 38.5706],
            [-89.9291, 38.5706],
            [-89.9288, 38.5427],
            [-89.9287, 38.5262],
            [-89.9476, 38.5273],
            [-89.9678, 38.5381],
            [-89.9698, 38.5397],
            [-89.9661, 38.5708],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742300',
        NAME: 'Whiteside School District 115',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15112752,
        AWATER: 274550,
        INTPTLAT: '+38.5496802',
        INTPTLON: '-089.9511689',
        ELSDLEA: '42300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4838, 40.7913],
            [-89.4693, 40.7477],
            [-89.5032, 40.7476],
            [-89.5174, 40.7476],
            [-89.4838, 40.7913],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716560',
        NAME: 'Germantown Hills School District 69',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22855516,
        AWATER: 112528,
        INTPTLAT: '+40.7663495',
        INTPTLON: '-089.4777000',
        ELSDLEA: '16560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5492, 38.7419],
            [-89.5665, 38.6451],
            [-89.6085, 38.6704],
            [-89.5492, 38.7419],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737470',
        NAME: 'St. Rose School District 14-15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82745440,
        AWATER: 43389,
        INTPTLAT: '+38.6912744',
        INTPTLON: '-089.5453092',
        ELSDLEA: '37470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1272, 38.8696],
            [-90.087, 38.8692],
            [-90.076, 38.7971],
            [-90.0759, 38.7912],
            [-90.1177, 38.8057],
            [-90.121, 38.8529],
            [-90.1272, 38.8696],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743050',
        NAME: 'Wood River-Hartford Elementary School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22878703,
        AWATER: 3406543,
        INTPTLAT: '+38.8247837',
        INTPTLON: '-090.0980081',
        ELSDLEA: '43050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1132, 38.8933],
            [-90.0871, 38.8896],
            [-90.087, 38.8692],
            [-90.1272, 38.8696],
            [-90.1132, 38.8933],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712960',
        NAME: 'East Alton School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8737360,
        AWATER: 103561,
        INTPTLAT: '+38.8818515',
        INTPTLON: '-090.1041408',
        ELSDLEA: '12960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5033, 40.8713],
            [-89.4975, 40.8787],
            [-89.3779, 40.8355],
            [-89.2741, 40.8367],
            [-89.2735, 40.778],
            [-89.3402, 40.7482],
            [-89.4558, 40.7476],
            [-89.4693, 40.7477],
            [-89.4838, 40.7913],
            [-89.5033, 40.8713],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725740',
        NAME: 'Metamora Community Consolidated School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195058174,
        AWATER: 5405396,
        INTPTLAT: '+40.8140116',
        INTPTLON: '-089.4199248',
        ELSDLEA: '25740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5615, 40.7956],
            [-89.5605, 40.7975],
            [-89.5033, 40.8713],
            [-89.4838, 40.7913],
            [-89.5174, 40.7476],
            [-89.555, 40.7476],
            [-89.5615, 40.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734110',
        NAME: 'Riverview Consolidated Community School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31989066,
        AWATER: 19634637,
        INTPTLAT: '+40.7986252',
        INTPTLON: '-089.5289573',
        ELSDLEA: '34110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7056, 42.3279],
            [-88.5794, 42.3276],
            [-88.4718, 42.2414],
            [-88.521, 42.1832],
            [-88.5932, 42.2407],
            [-88.7055, 42.241],
            [-88.7056, 42.3279],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700077',
        NAME: 'Marengo-Union Elementary Consolidated District 165',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172036511,
        AWATER: 78070,
        INTPTLAT: '+42.2673773',
        INTPTLON: '-088.5984078',
        ELSDLEA: '00077',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4129, 42.2405],
            [-88.3552, 42.2986],
            [-88.2771, 42.2988],
            [-88.2664, 42.2415],
            [-88.2776, 42.205],
            [-88.3543, 42.1849],
            [-88.4129, 42.2405],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711350',
        NAME: 'Crystal Lake Community Consolidated School District 47',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106975130,
        AWATER: 2496015,
        INTPTLAT: '+42.2420354',
        INTPTLON: '-088.3367973',
        ELSDLEA: '11350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2384, 42.1945],
            [-88.2035, 42.2049],
            [-88.2382, 42.1867],
            [-88.2384, 42.1945],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715660',
        NAME: 'Fox River Grove Consolidated School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4155076,
        AWATER: 271831,
        INTPTLAT: '+42.1923974',
        INTPTLON: '-088.2222968',
        ELSDLEA: '15660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3411, 42.4143],
            [-88.3281, 42.4146],
            [-88.3062, 42.3729],
            [-88.3555, 42.3715],
            [-88.3411, 42.4143],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718360',
        NAME: 'Harrison School District 36',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12521728,
        AWATER: 1099793,
        INTPTLAT: '+42.3830541',
        INTPTLON: '-088.3342347',
        ELSDLEA: '18360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2771, 42.2988],
            [-88.2268, 42.2797],
            [-88.2134, 42.245],
            [-88.2664, 42.2415],
            [-88.2771, 42.2988],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732520',
        NAME: 'Prairie Grove Community School District 46',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28471398,
        AWATER: 836882,
        INTPTLAT: '+42.2664811',
        INTPTLON: '-088.2523780',
        ELSDLEA: '32520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8649, 40.6788],
            [-87.7712, 40.6993],
            [-87.6492, 40.6873],
            [-87.6531, 40.793],
            [-87.5257, 40.8173],
            [-87.5262, 40.6498],
            [-87.5263, 40.5673],
            [-87.5487, 40.5343],
            [-87.7968, 40.5454],
            [-87.8649, 40.6788],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725950',
        NAME: 'Milford Community Consolidated School District 280',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 580182511,
        AWATER: 54555,
        INTPTLAT: '+40.6606650',
        INTPTLON: '-087.6497853',
        ELSDLEA: '25950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.875, 37.5857],
            [-88.7179, 37.6145],
            [-88.7085, 37.5993],
            [-88.7105, 37.4089],
            [-88.8198, 37.424],
            [-88.8849, 37.5193],
            [-88.875, 37.5857],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728160',
        NAME: 'New Simpson Hill Consolidated District 32',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271676340,
        AWATER: 2529472,
        INTPTLAT: '+37.5208665',
        INTPTLON: '-088.7948868',
        ELSDLEA: '28160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9288, 37.4903],
            [-88.8849, 37.5193],
            [-88.8198, 37.424],
            [-88.7105, 37.4089],
            [-88.7107, 37.3371],
            [-88.8384, 37.3357],
            [-88.8877, 37.3356],
            [-88.9848, 37.4211],
            [-88.9288, 37.4903],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740260',
        NAME: 'Vienna School District 55',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240456949,
        AWATER: 2451633,
        INTPTLAT: '+37.4047311',
        INTPTLON: '-088.8467082',
        ELSDLEA: '40260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1422, 38.5569],
            [-89.0871, 38.5798],
            [-89.0318, 38.5651],
            [-89.032, 38.5577],
            [-89.0321, 38.5515],
            [-89.0322, 38.5328],
            [-89.0503, 38.5258],
            [-89.1427, 38.5393],
            [-89.1422, 38.5569],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709180',
        NAME: 'Central City School District 133',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32588201,
        AWATER: 1280144,
        INTPTLAT: '+38.5542595',
        INTPTLON: '-089.0818758',
        ELSDLEA: '09180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8478, 37.9507],
            [-88.8221, 37.9507],
            [-88.7065, 37.951],
            [-88.6718, 37.9215],
            [-88.7303, 37.8634],
            [-88.817, 37.863],
            [-88.8478, 37.9507],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701382',
        NAME: 'Thompsonville Community Unit School District 174',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118287677,
        AWATER: 2038329,
        INTPTLAT: '+37.9099664',
        INTPTLON: '-088.7565527',
        ELSDLEA: '',
        UNSDLEA: '01382',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8855, 42.3417],
            [-87.8269, 42.3413],
            [-87.8333, 42.3049],
            [-87.8852, 42.2981],
            [-87.8852, 42.3263],
            [-87.8855, 42.3417],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700110',
        NAME: 'North Chicago School District 187',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20590132,
        AWATER: 37866,
        INTPTLAT: '+42.3185095',
        INTPTLON: '-087.8591900',
        ELSDLEA: '',
        UNSDLEA: '00110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8847, 39.1761],
            [-87.7829, 39.1786],
            [-87.7259, 39.2466],
            [-87.6889, 39.216],
            [-87.6205, 39.185],
            [-87.6194, 39.1721],
            [-87.6107, 39.0812],
            [-87.6624, 39.0591],
            [-87.7646, 39.1085],
            [-87.8437, 39.0904],
            [-87.8847, 39.1761],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719920',
        NAME: 'Hutsonville Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260827082,
        AWATER: 2185185,
        INTPTLAT: '+39.1466734',
        INTPTLON: '-087.7261732',
        ELSDLEA: '',
        UNSDLEA: '19920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9168, 39.9022],
            [-90.9136, 40.1045],
            [-90.8384, 40.105],
            [-90.6959, 40.1038],
            [-90.6071, 39.9817],
            [-90.5137, 39.9879],
            [-90.5377, 39.9195],
            [-90.5718, 39.8393],
            [-90.702, 39.8401],
            [-90.9166, 39.8451],
            [-90.9168, 39.9022],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727300',
        NAME: 'Brown County Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 790083660,
        AWATER: 4139308,
        INTPTLAT: '+39.9620694',
        INTPTLON: '-090.7503095',
        ELSDLEA: '',
        UNSDLEA: '27300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2583, 38.3977],
            [-90.1289, 38.3976],
            [-90.0734, 38.3967],
            [-90.0728, 38.3965],
            [-90.0361, 38.3233],
            [-89.9624, 38.2798],
            [-90.0364, 38.2665],
            [-90.0807, 38.1231],
            [-90.146, 38.1047],
            [-90.2596, 38.2678],
            [-90.2583, 38.3977],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741070',
        NAME: 'Waterloo Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468431045,
        AWATER: 4382178,
        INTPTLAT: '+38.2806665',
        INTPTLON: '-090.1326315',
        ELSDLEA: '',
        UNSDLEA: '41070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3761, 42.0669],
            [-88.2084, 42.0673],
            [-88.1446, 42.0574],
            [-88.1451, 41.9882],
            [-88.1449, 41.9665],
            [-88.1649, 41.9259],
            [-88.1945, 41.9217],
            [-88.2328, 41.929],
            [-88.2374, 41.936],
            [-88.2378, 41.936],
            [-88.2677, 41.9863],
            [-88.3804, 41.9918],
            [-88.3761, 42.0669],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713710',
        NAME: 'School District U-46',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213106983,
        AWATER: 4341413,
        INTPTLAT: '+42.0049332',
        INTPTLON: '-088.2432665',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9691, 41.9349],
            [-87.9203, 41.9358],
            [-87.9204, 41.914],
            [-87.9204, 41.9068],
            [-87.9205, 41.8769],
            [-87.9192, 41.8477],
            [-87.9394, 41.8546],
            [-87.9594, 41.8756],
            [-87.9688, 41.9056],
            [-87.9691, 41.9349],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713970',
        NAME: 'Elmhurst School District 205',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30971614,
        AWATER: 287907,
        INTPTLAT: '+41.8980804',
        INTPTLON: '-087.9412073',
        ELSDLEA: '',
        UNSDLEA: '13970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3739, 42.1011],
            [-89.3372, 42.2024],
            [-89.173, 42.2042],
            [-89.1725, 42.1799],
            [-89.2772, 42.0734],
            [-89.3739, 42.1011],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708010',
        NAME: 'Byron Community Unit School District 226',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147762065,
        AWATER: 2971863,
        INTPTLAT: '+42.1438380',
        INTPTLON: '-089.2793365',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7687, 39.2776],
            [-88.6863, 39.3967],
            [-88.5414, 39.3529],
            [-88.5393, 39.2335],
            [-88.5818, 39.2084],
            [-88.7685, 39.2408],
            [-88.7687, 39.2776],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737830',
        NAME: 'Stewardson-Strasburg Community Unit District 5A',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295443830,
        AWATER: 0,
        INTPTLAT: '+39.2942994',
        INTPTLON: '-088.6400873',
        ELSDLEA: '',
        UNSDLEA: '37830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6934, 38.922],
            [-88.6536, 38.9444],
            [-88.6517, 39.1249],
            [-88.5955, 39.1719],
            [-88.5232, 39.1626],
            [-88.5058, 39.031],
            [-88.511, 38.9832],
            [-88.5442, 38.9146],
            [-88.6937, 38.9109],
            [-88.6981, 38.9145],
            [-88.6934, 38.922],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713560',
        NAME: 'Effingham Community Unit School District 40',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324606410,
        AWATER: 1392258,
        INTPTLAT: '+39.0389728',
        INTPTLON: '-088.5879194',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1803, 41.7984],
            [-88.0941, 41.815],
            [-88.09, 41.7827],
            [-88.069, 41.7281],
            [-88.1439, 41.7132],
            [-88.1803, 41.7984],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727710',
        NAME: 'Naperville Community Unit District 203',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77018707,
        AWATER: 1591401,
        INTPTLAT: '+41.7663714',
        INTPTLON: '-088.1258484',
        ELSDLEA: '',
        UNSDLEA: '27710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4105, 41.7222],
            [-88.3753, 41.7226],
            [-88.32, 41.7234],
            [-88.262, 41.732],
            [-88.2237, 41.6694],
            [-88.3093, 41.5491],
            [-88.3522, 41.5485],
            [-88.3557, 41.6352],
            [-88.4105, 41.7222],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730270',
        NAME: 'Oswego Community Unit School District 308',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181650844,
        AWATER: 1400297,
        INTPTLAT: '+41.6538730',
        INTPTLON: '-088.3151626',
        ELSDLEA: '',
        UNSDLEA: '30270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2237, 41.6694],
            [-88.1466, 41.6819],
            [-88.1328, 41.6031],
            [-88.1418, 41.5512],
            [-88.2553, 41.5501],
            [-88.3093, 41.5491],
            [-88.2237, 41.6694],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731740',
        NAME: 'Plainfield School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153921174,
        AWATER: 5135351,
        INTPTLAT: '+41.6107201',
        INTPTLON: '-088.2024276',
        ELSDLEA: '',
        UNSDLEA: '31740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2419, 41.2887],
            [-88.1582, 41.2906],
            [-88.0403, 41.2226],
            [-88.0118, 41.2056],
            [-88.2093, 41.1731],
            [-88.2442, 41.2015],
            [-88.2455, 41.2446],
            [-88.2419, 41.2887],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733380',
        NAME: 'Reed Custer Community Unit School District 255U',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136473981,
        AWATER: 3681990,
        INTPTLAT: '+41.2258612',
        INTPTLON: '-088.1677417',
        ELSDLEA: '',
        UNSDLEA: '33380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4237, 41.8965],
            [-88.2624, 41.8992],
            [-88.2626, 41.863],
            [-88.4059, 41.8534],
            [-88.4237, 41.8965],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716380',
        NAME: 'Geneva Community Unit School District 304',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52821212,
        AWATER: 655205,
        INTPTLAT: '+41.8783425',
        INTPTLON: '-088.3420718',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4059, 41.8534],
            [-88.2626, 41.863],
            [-88.263, 41.8368],
            [-88.2626, 41.7998],
            [-88.3013, 41.7975],
            [-88.3761, 41.8293],
            [-88.4059, 41.8534],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705220',
        NAME: 'Batavia Unit School District 101',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52046866,
        AWATER: 838303,
        INTPTLAT: '+41.8392959',
        INTPTLON: '-088.3199354',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1234, 40.049],
            [-89.056, 40.0496],
            [-88.9739, 39.9037],
            [-89.0288, 39.8244],
            [-89.0289, 39.8754],
            [-89.1993, 39.9171],
            [-89.1234, 40.049],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740830',
        NAME: 'Warrensburg-Latham Community Unit District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242480718,
        AWATER: 0,
        INTPTLAT: '+39.9455341',
        INTPTLON: '-089.0820031',
        ELSDLEA: '',
        UNSDLEA: '40830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.939, 39.7989],
            [-88.8409, 39.842],
            [-88.7582, 39.7692],
            [-88.7578, 39.7399],
            [-88.8691, 39.704],
            [-88.939, 39.7989],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727390',
        NAME: 'Mount Zion Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163282297,
        AWATER: 43821,
        INTPTLAT: '+39.7648537',
        INTPTLON: '-088.8543827',
        ELSDLEA: '',
        UNSDLEA: '27390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3817, 41.2998],
            [-88.3258, 41.3847],
            [-88.3181, 41.3886],
            [-88.3089, 41.3925],
            [-88.25, 41.3878],
            [-88.2419, 41.2887],
            [-88.2455, 41.2446],
            [-88.3136, 41.2436],
            [-88.3611, 41.2427],
            [-88.3817, 41.2998],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710530',
        NAME: 'Coal City Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127829574,
        AWATER: 11359139,
        INTPTLAT: '+41.3103089',
        INTPTLON: '-088.2995758',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6428, 42.5085],
            [-90.5306, 42.5077],
            [-90.5273, 42.4063],
            [-90.6428, 42.5085],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713050',
        NAME: 'East Dubuque Community Unit School District 119',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61985200,
        AWATER: 10203146,
        INTPTLAT: '+42.4865123',
        INTPTLON: '-090.5683485',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8434, 38.6561],
            [-89.7185, 38.6551],
            [-89.707, 38.6552],
            [-89.7056, 38.5679],
            [-89.825, 38.5689],
            [-89.8434, 38.6561],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722300',
        NAME: 'Lebanon Community Unit School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117780119,
        AWATER: 588561,
        INTPTLAT: '+38.6091610',
        INTPTLON: '-089.7666958',
        ELSDLEA: '',
        UNSDLEA: '22300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9558, 40.4324],
            [-90.8946, 40.6387],
            [-90.7897, 40.6358],
            [-90.7799, 40.6354],
            [-90.5971, 40.6309],
            [-90.5991, 40.5211],
            [-90.7905, 40.5154],
            [-90.7049, 40.3527],
            [-90.7722, 40.2811],
            [-90.7926, 40.3029],
            [-90.908, 40.3774],
            [-90.9558, 40.4324],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700314',
        NAME: 'West Prairie Community Unit School District 103',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638573367,
        AWATER: 490754,
        INTPTLAT: '+40.4600717',
        INTPTLON: '-090.7643533',
        ELSDLEA: '',
        UNSDLEA: '00314',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1205, 42.3847],
            [-88.0822, 42.3932],
            [-88.0681, 42.386],
            [-88.1203, 42.3435],
            [-88.1204, 42.3655],
            [-88.1205, 42.3847],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734990',
        NAME: 'Round Lake Community Unit School District 116',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20507211,
        AWATER: 1186247,
        INTPTLAT: '+42.3610017',
        INTPTLON: '-088.0939281',
        ELSDLEA: '',
        UNSDLEA: '34990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8851, 42.3565],
            [-87.8845, 42.4139],
            [-87.8033, 42.4138],
            [-87.8269, 42.3413],
            [-87.8855, 42.3417],
            [-87.8851, 42.3565],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741250',
        NAME: 'Waukegan Community Unit School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49717314,
        AWATER: 749076,
        INTPTLAT: '+42.3817580',
        INTPTLON: '-087.8481818',
        ELSDLEA: '',
        UNSDLEA: '41250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2741, 40.8367],
            [-89.17, 40.9267],
            [-89.0462, 40.8202],
            [-89.1575, 40.7503],
            [-89.2735, 40.778],
            [-89.2741, 40.8367],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734140',
        NAME: 'Roanoke-Benson Community Unit School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221604447,
        AWATER: 130433,
        INTPTLAT: '+40.8310470',
        INTPTLON: '-089.1686181',
        ELSDLEA: '',
        UNSDLEA: '34140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1016, 40.625],
            [-88.903, 40.6664],
            [-88.9181, 40.6345],
            [-88.8074, 40.5704],
            [-88.8069, 40.516],
            [-88.8438, 40.4507],
            [-88.9408, 40.4002],
            [-89.0622, 40.4295],
            [-89.0991, 40.5615],
            [-89.1647, 40.5962],
            [-89.1016, 40.625],
          ],
          [
            [-89.0309, 40.439],
            [-88.9805, 40.464],
            [-88.9277, 40.4591],
            [-88.94, 40.5027],
            [-89.0109, 40.4952],
            [-89.0309, 40.439],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728620',
        NAME: 'McLean County Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517441330,
        AWATER: 5513916,
        INTPTLAT: '+40.5436969',
        INTPTLON: '-088.9755962',
        ELSDLEA: '',
        UNSDLEA: '28620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3907, 37.8389],
            [-89.2653, 37.8202],
            [-89.2662, 37.7747],
            [-89.282, 37.6984],
            [-89.2778, 37.6285],
            [-89.4156, 37.5997],
            [-89.426, 37.6624],
            [-89.5051, 37.6926],
            [-89.4894, 37.8082],
            [-89.3907, 37.8389],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727610',
        NAME: 'Murphysboro Community Unit School District 186',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 393204787,
        AWATER: 15137990,
        INTPTLAT: '+37.7342920',
        INTPTLON: '-089.3746805',
        ELSDLEA: '',
        UNSDLEA: '27610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9326, 40.3233],
            [-89.8526, 40.3035],
            [-89.6508, 40.3559],
            [-89.5458, 40.2992],
            [-89.5637, 40.2479],
            [-89.6016, 40.1224],
            [-89.6388, 40.1551],
            [-89.829, 40.1257],
            [-89.9674, 40.1426],
            [-89.98, 40.2461],
            [-89.9326, 40.3233],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700113',
        NAME: 'Illini Central Community Unit School District 189',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 699390860,
        AWATER: 1407146,
        INTPTLAT: '+40.2317848',
        INTPTLON: '-089.7579689',
        ELSDLEA: '',
        UNSDLEA: '00113',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1456, 40.1064],
            [-89.1373, 40.195],
            [-89.0523, 40.2612],
            [-88.8781, 40.2826],
            [-88.8041, 40.2606],
            [-88.7777, 40.1861],
            [-88.8411, 40.1362],
            [-88.8413, 40.0613],
            [-88.9072, 40.0974],
            [-89.056, 40.0496],
            [-89.1234, 40.049],
            [-89.1456, 40.1064],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710440',
        NAME: 'Clinton Community Unit School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 587152905,
        AWATER: 11725404,
        INTPTLAT: '+40.1714644',
        INTPTLON: '-088.9707249',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5458, 40.2992],
            [-89.4542, 40.3295],
            [-89.3461, 40.2521],
            [-89.369, 40.2215],
            [-89.4861, 40.2012],
            [-89.5637, 40.2479],
            [-89.5458, 40.2992],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718390',
        NAME: 'Hartsburg-Emden Community Unit School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188691745,
        AWATER: 0,
        INTPTLAT: '+40.2628613',
        INTPTLON: '-089.4524290',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5794, 40.0488],
            [-89.5229, 40.0637],
            [-89.3671, 40.063],
            [-89.2538, 40.0545],
            [-89.1456, 40.1064],
            [-89.1234, 40.049],
            [-89.1993, 39.9171],
            [-89.3486, 39.9175],
            [-89.4617, 39.9332],
            [-89.5786, 39.9907],
            [-89.5794, 40.0488],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727290',
        NAME: 'Mount Pulaski Community Unit District 23',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515901950,
        AWATER: 135623,
        INTPTLAT: '+39.9974792',
        INTPTLON: '-089.3273520',
        ELSDLEA: '',
        UNSDLEA: '27290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5786, 39.9907],
            [-89.4617, 39.9332],
            [-89.5304, 39.8745],
            [-89.5975, 39.8715],
            [-89.6541, 39.8709],
            [-89.5786, 39.9907],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742480',
        NAME: 'Williamsville Community Unit School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181216086,
        AWATER: 607536,
        INTPTLAT: '+39.9184513',
        INTPTLON: '-089.5607655',
        ELSDLEA: '',
        UNSDLEA: '42480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9178, 39.1649],
            [-88.7685, 39.2408],
            [-88.5818, 39.2084],
            [-88.5955, 39.1719],
            [-88.6517, 39.1249],
            [-88.8056, 39.1299],
            [-88.9178, 39.1649],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705460',
        NAME: 'Beecher City Community Unit School District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246673652,
        AWATER: 1210670,
        INTPTLAT: '+39.1807324',
        INTPTLON: '-088.7377669',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2466, 40.6471],
            [-88.2082, 40.6754],
            [-88.1976, 40.6755],
            [-88.1197, 40.6189],
            [-88.0643, 40.6626],
            [-88.0554, 40.6559],
            [-87.946, 40.6512],
            [-87.9953, 40.5425],
            [-87.9446, 40.4859],
            [-87.9329, 40.3994],
            [-87.9713, 40.3991],
            [-88.0405, 40.3711],
            [-88.079, 40.371],
            [-88.2337, 40.3959],
            [-88.2337, 40.3995],
            [-88.1945, 40.5747],
            [-88.2466, 40.6471],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707650',
        NAME: 'Paxton-Buckley-Loda Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 665914041,
        AWATER: 1516633,
        INTPTLAT: '+40.5201221',
        INTPTLON: '-088.0871939',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4605, 40.2674],
            [-88.3041, 40.2236],
            [-88.3509, 40.1422],
            [-88.3327, 40.0551],
            [-88.3878, 40.0552],
            [-88.4632, 40.1415],
            [-88.4605, 40.2674],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724060',
        NAME: 'Mahomet-Seymour Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224829533,
        AWATER: 1004826,
        INTPTLAT: '+40.1652731',
        INTPTLON: '-088.3936678',
        ELSDLEA: '',
        UNSDLEA: '24060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.574, 40.1415],
            [-88.4632, 40.1415],
            [-88.3878, 40.0552],
            [-88.4342, 39.9673],
            [-88.6685, 39.9672],
            [-88.7453, 39.9461],
            [-88.7644, 40.0552],
            [-88.6075, 40.0543],
            [-88.574, 40.1415],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726550',
        NAME: 'Monticello Community Unit School District 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411577589,
        AWATER: 505871,
        INTPTLAT: '+40.0369183',
        INTPTLON: '-088.5474545',
        ELSDLEA: '',
        UNSDLEA: '26550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3966, 39.8939],
            [-88.2551, 39.8791],
            [-88.1951, 39.7459],
            [-88.3575, 39.7391],
            [-88.3855, 39.7541],
            [-88.4154, 39.8792],
            [-88.3966, 39.8939],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739600',
        NAME: 'Tuscola Community Unit School District 301',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252950792,
        AWATER: 581600,
        INTPTLAT: '+39.8168140',
        INTPTLON: '-088.3013553',
        ELSDLEA: '',
        UNSDLEA: '39600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9166, 39.8451],
            [-90.702, 39.8401],
            [-90.684, 39.7594],
            [-90.6154, 39.7512],
            [-90.6155, 39.6492],
            [-90.7226, 39.6345],
            [-90.8344, 39.6633],
            [-90.8372, 39.7532],
            [-90.9161, 39.7647],
            [-90.9166, 39.8451],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717790',
        NAME: 'Griggsville-Perry Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379151304,
        AWATER: 2990063,
        INTPTLAT: '+39.7452635',
        INTPTLON: '-090.7708156',
        ELSDLEA: '',
        UNSDLEA: '17790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4371, 39.8488],
            [-91.2646, 39.875],
            [-91.2638, 39.9347],
            [-91.185, 39.8453],
            [-91.0298, 39.7717],
            [-91.0682, 39.7576],
            [-91.3651, 39.7587],
            [-91.369, 39.8015],
            [-91.4371, 39.8488],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730990',
        NAME: 'Payson Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314727827,
        AWATER: 6415899,
        INTPTLAT: '+39.8167992',
        INTPTLON: '-091.2402884',
        ELSDLEA: '',
        UNSDLEA: '30990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1458, 40.1662],
            [-90.9694, 40.179],
            [-90.9136, 40.1045],
            [-90.9168, 39.9022],
            [-90.993, 39.8882],
            [-91.0306, 39.9608],
            [-91.2284, 39.9775],
            [-91.223, 40.0493],
            [-91.1473, 40.093],
            [-91.1458, 40.1662],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708220',
        NAME: 'Camp Point Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602495973,
        AWATER: 800768,
        INTPTLAT: '+40.0484524',
        INTPTLON: '-091.0401828',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4881, 40.0246],
            [-91.3194, 40.0227],
            [-91.2634, 39.978],
            [-91.2638, 39.9347],
            [-91.2646, 39.875],
            [-91.4371, 39.8488],
            [-91.4355, 39.9452],
            [-91.4881, 40.0246],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733000',
        NAME: 'Quincy School District 172',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256398874,
        AWATER: 16003785,
        INTPTLAT: '+39.9444062',
        INTPTLON: '-091.3651959',
        ELSDLEA: '',
        UNSDLEA: '33000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8681, 39.6529],
            [-88.8691, 39.704],
            [-88.7578, 39.7399],
            [-88.676, 39.6664],
            [-88.6791, 39.5248],
            [-88.6973, 39.4796],
            [-88.7419, 39.4553],
            [-88.9063, 39.4996],
            [-88.8681, 39.6529],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700223',
        NAME: 'Okaw Valley Community Unit School District 302',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406759952,
        AWATER: 11184838,
        INTPTLAT: '+39.5855427',
        INTPTLON: '-088.7823230',
        ELSDLEA: '',
        UNSDLEA: '00223',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9361, 39.9063],
            [-88.9185, 40.0017],
            [-88.8413, 40.0613],
            [-88.7644, 40.0552],
            [-88.7453, 39.9461],
            [-88.7868, 39.924],
            [-88.9361, 39.9063],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703990',
        NAME: 'Argenta-Oreana Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223336755,
        AWATER: 205919,
        INTPTLAT: '+39.9757399',
        INTPTLON: '-088.8358702',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0469, 39.8468],
            [-87.8819, 39.88],
            [-87.7661, 39.881],
            [-87.7638, 39.7339],
            [-87.8071, 39.7039],
            [-87.7963, 39.5941],
            [-87.9324, 39.6571],
            [-87.9865, 39.7288],
            [-88.1012, 39.7455],
            [-88.0469, 39.8468],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700122',
        NAME: 'Shiloh Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601927516,
        AWATER: 210129,
        INTPTLAT: '+39.7680987',
        INTPTLON: '-087.9026715',
        ELSDLEA: '',
        UNSDLEA: '00122',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4041, 42.2597],
            [-89.3289, 42.2672],
            [-89.2598, 42.3763],
            [-89.2329, 42.3861],
            [-89.1743, 42.3676],
            [-89.173, 42.2042],
            [-89.3372, 42.2024],
            [-89.4041, 42.2597],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742790',
        NAME: 'Winnebago Community Unit School District 323',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244177194,
        AWATER: 178778,
        INTPTLAT: '+42.2681136',
        INTPTLON: '-089.2626887',
        ELSDLEA: '',
        UNSDLEA: '42790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4561, 42.3072],
            [-89.3981, 42.378],
            [-89.2598, 42.3763],
            [-89.3289, 42.2672],
            [-89.4041, 42.2597],
            [-89.4558, 42.2661],
            [-89.4561, 42.3072],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731050',
        NAME: 'Pecatonica Community Unit School District 321',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194221153,
        AWATER: 1317183,
        INTPTLAT: '+42.3157216',
        INTPTLON: '-089.3574994',
        ELSDLEA: '',
        UNSDLEA: '31050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0795, 42.4963],
            [-89.0708, 42.4963],
            [-89.0116, 42.4961],
            [-89.0196, 42.463],
            [-89.0395, 42.4627],
            [-89.0795, 42.4963],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736600',
        NAME: 'South Beloit Community Unit School District 320',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15708914,
        AWATER: 864254,
        INTPTLAT: '+42.4822998',
        INTPTLON: '-089.0463480',
        ELSDLEA: '',
        UNSDLEA: '36600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1868, 38.6753],
            [-90.1473, 38.6602],
            [-90.1816, 38.6604],
            [-90.1868, 38.6753],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740200',
        NAME: 'Venice Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4634787,
        AWATER: 539983,
        INTPTLAT: '+38.6670872',
        INTPTLON: '-090.1665097',
        ELSDLEA: '',
        UNSDLEA: '40200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9284, 38.745],
            [-89.8167, 38.8157],
            [-89.7079, 38.8156],
            [-89.7185, 38.6551],
            [-89.8434, 38.6561],
            [-89.9284, 38.6577],
            [-89.9284, 38.745],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737350',
        NAME: 'Triad Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312768302,
        AWATER: 3176228,
        INTPTLAT: '+38.7362743',
        INTPTLON: '-089.8139029',
        ELSDLEA: '',
        UNSDLEA: '37350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.076, 38.7971],
            [-90.0368, 38.8427],
            [-90.0403, 38.8698],
            [-90.0412, 38.9708],
            [-90.0208, 38.9987],
            [-89.8979, 38.988],
            [-89.7732, 38.9178],
            [-89.8167, 38.8157],
            [-89.9284, 38.745],
            [-90.0386, 38.7459],
            [-90.0759, 38.7912],
            [-90.076, 38.7971],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713530',
        NAME: 'Edwardsville Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450053764,
        AWATER: 7531986,
        INTPTLAT: '+38.8628538',
        INTPTLON: '-089.9465869',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9768, 38.8236],
            [-88.9204, 38.8971],
            [-88.8938, 38.9118],
            [-88.8068, 38.9117],
            [-88.6981, 38.9145],
            [-88.6937, 38.9109],
            [-88.6969, 38.7379],
            [-88.6991, 38.6933],
            [-88.8268, 38.686],
            [-88.9744, 38.7075],
            [-89.0311, 38.7068],
            [-88.9776, 38.7363],
            [-88.9768, 38.8236],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700114',
        NAME: 'South Central Community Unit School District 401',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561609522,
        AWATER: 3480310,
        INTPTLAT: '+38.7919711',
        INTPTLON: '-088.8345132',
        ELSDLEA: '',
        UNSDLEA: '00114',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8068, 38.9117],
            [-88.8056, 39.1299],
            [-88.6517, 39.1249],
            [-88.6536, 38.9444],
            [-88.6934, 38.922],
            [-88.6981, 38.9145],
            [-88.8068, 38.9117],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703510',
        NAME: 'Altamont Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313252582,
        AWATER: 464084,
        INTPTLAT: '+39.0231727',
        INTPTLON: '-088.7390014',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9444, 39.1482],
            [-88.9178, 39.1649],
            [-88.8056, 39.1299],
            [-88.8068, 38.9117],
            [-88.8938, 38.9118],
            [-88.8951, 39.044],
            [-88.9858, 39.1138],
            [-88.9444, 39.1482],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737230',
        NAME: 'St. Elmo Community Unit School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246321005,
        AWATER: 546158,
        INTPTLAT: '+39.0417248',
        INTPTLON: '-088.8630393',
        ELSDLEA: '',
        UNSDLEA: '37230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6484, 41.2882],
            [-90.5887, 41.3277],
            [-90.587, 41.4142],
            [-90.5196, 41.4136],
            [-90.4958, 41.4137],
            [-90.4354, 41.2678],
            [-90.4377, 41.1513],
            [-90.6377, 41.1323],
            [-90.6484, 41.2882],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736180',
        NAME: 'Sherrard Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 449338159,
        AWATER: 811538,
        INTPTLAT: '+41.2628988',
        INTPTLON: '-090.5461800',
        ELSDLEA: '',
        UNSDLEA: '36180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3561, 41.5828],
            [-90.3402, 41.6556],
            [-90.3131, 41.6996],
            [-90.3054, 41.6777],
            [-90.1612, 41.6439],
            [-90.1856, 41.5847],
            [-90.1965, 41.5401],
            [-90.3179, 41.5088],
            [-90.3179, 41.5344],
            [-90.3569, 41.549],
            [-90.3561, 41.5828],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733930',
        NAME: 'Riverdale Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226438884,
        AWATER: 8455014,
        INTPTLAT: '+41.6042430',
        INTPTLON: '-090.2600057',
        ELSDLEA: '',
        UNSDLEA: '33930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6054, 41.8953],
            [-89.4453, 41.9309],
            [-89.3599, 41.9239],
            [-89.3614, 41.7944],
            [-89.5738, 41.7441],
            [-89.6028, 41.8145],
            [-89.6054, 41.8953],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712330',
        NAME: 'Dixon Unit School District 170',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297663774,
        AWATER: 7342496,
        INTPTLAT: '+41.8435980',
        INTPTLON: '-089.4805413',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7085, 37.5993],
            [-88.5989, 37.5993],
            [-88.4121, 37.5999],
            [-88.4151, 37.4241],
            [-88.4741, 37.3919],
            [-88.5071, 37.2597],
            [-88.4246, 37.1518],
            [-88.4904, 37.0686],
            [-88.4903, 37.1594],
            [-88.7107, 37.3371],
            [-88.7105, 37.4089],
            [-88.7085, 37.5993],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732280',
        NAME: 'Pope County Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 955109670,
        AWATER: 14328523,
        INTPTLAT: '+37.4171687',
        INTPTLON: '-088.5423737',
        ELSDLEA: '',
        UNSDLEA: '32280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4723, 40.9212],
            [-89.3286, 40.9509],
            [-89.2288, 40.9987],
            [-89.17, 40.9267],
            [-89.2741, 40.8367],
            [-89.3779, 40.8355],
            [-89.4975, 40.8787],
            [-89.4723, 40.9212],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740920',
        NAME: 'Lowpoint-Washburn Community Unit School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264247799,
        AWATER: 11857124,
        INTPTLAT: '+40.9141728',
        INTPTLON: '-089.3232939',
        ELSDLEA: '',
        UNSDLEA: '40920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.702, 39.8401],
            [-90.5718, 39.8393],
            [-90.5377, 39.9195],
            [-90.4712, 39.9193],
            [-90.4831, 39.7892],
            [-90.6154, 39.7512],
            [-90.684, 39.7594],
            [-90.702, 39.8401],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725680',
        NAME: 'Meredosia-Chambersburg Community Unit School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205123291,
        AWATER: 10499060,
        INTPTLAT: '+39.8357229',
        INTPTLON: '-090.5620902',
        ELSDLEA: '',
        UNSDLEA: '25680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6898, 39.0115],
            [-90.5762, 39.0114],
            [-90.4508, 38.9678],
            [-90.5861, 38.8706],
            [-90.6632, 38.9342],
            [-90.6898, 39.0115],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700102',
        NAME: 'Brussels Community Unit School District 42',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155255219,
        AWATER: 27440365,
        INTPTLAT: '+38.9368206',
        INTPTLON: '-090.5847997',
        ELSDLEA: '',
        UNSDLEA: '00102',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4184, 42.3276],
            [-90.3412, 42.401],
            [-90.1624, 42.3861],
            [-90.0997, 42.3138],
            [-90.1555, 42.1969],
            [-90.3179, 42.1939],
            [-90.3911, 42.2255],
            [-90.4184, 42.3276],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700001',
        NAME: 'River Ridge Community Unit School District 210',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424056391,
        AWATER: 18800200,
        INTPTLAT: '+42.3020736',
        INTPTLON: '-090.2580864',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3179, 42.1939],
            [-90.1555, 42.1969],
            [-89.9391, 42.1968],
            [-89.929, 42.1968],
            [-89.9092, 42.0734],
            [-89.9773, 42.0359],
            [-89.9784, 41.9312],
            [-90.0165, 41.9305],
            [-90.152, 41.9289],
            [-90.1642, 41.9462],
            [-90.1496, 41.9775],
            [-90.1629, 42.1167],
            [-90.3066, 42.1905],
            [-90.3179, 42.1939],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700130',
        NAME: 'West Carroll Community Unit School District 314',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 529953435,
        AWATER: 54179977,
        INTPTLAT: '+42.0806790',
        INTPTLON: '-090.0755083',
        ELSDLEA: '',
        UNSDLEA: '00130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3902, 37.1091],
            [-89.2611, 37.1075],
            [-89.1721, 37.0683],
            [-89.1329, 36.9821],
            [-89.2599, 37.0641],
            [-89.3127, 37.009],
            [-89.3902, 37.1091],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708070',
        NAME: 'Cairo Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150166023,
        AWATER: 24784162,
        INTPTLAT: '+37.0985049',
        INTPTLON: '-089.3350147',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5372, 41.5256],
            [-90.533, 41.5249],
            [-90.4743, 41.5197],
            [-90.4614, 41.5235],
            [-90.4319, 41.4568],
            [-90.4958, 41.4137],
            [-90.5196, 41.4136],
            [-90.5372, 41.5256],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726400',
        NAME: 'Moline Unit School District 40',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79894639,
        AWATER: 5517434,
        INTPTLAT: '+41.4667411',
        INTPTLON: '-090.4875994',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5372, 41.5256],
            [-90.5196, 41.4136],
            [-90.587, 41.4142],
            [-90.6814, 41.4594],
            [-90.5372, 41.5256],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734410',
        NAME: 'Rock Island School District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75761402,
        AWATER: 8051573,
        INTPTLAT: '+41.4642860',
        INTPTLON: '-090.5720334',
        ELSDLEA: '',
        UNSDLEA: '34410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1046, 40.4806],
            [-90.0685, 40.4807],
            [-89.9056, 40.4515],
            [-89.9852, 40.3921],
            [-90.033, 40.3778],
            [-90.119, 40.2353],
            [-90.1996, 40.1839],
            [-90.2199, 40.184],
            [-90.219, 40.305],
            [-90.2592, 40.4066],
            [-90.2351, 40.4514],
            [-90.1046, 40.4806],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700153',
        NAME: 'Lewistown Community Unit School District 97',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427940089,
        AWATER: 12598474,
        INTPTLAT: '+40.3572667',
        INTPTLON: '-090.1213513',
        ELSDLEA: '',
        UNSDLEA: '00153',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2989, 40.5758],
            [-90.1416, 40.5676],
            [-90.1046, 40.4806],
            [-90.2351, 40.4514],
            [-90.2592, 40.4066],
            [-90.3621, 40.4736],
            [-90.4478, 40.4521],
            [-90.4176, 40.5168],
            [-90.2989, 40.5758],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711400',
        NAME: 'Fulton County Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306093627,
        AWATER: 5351086,
        INTPTLAT: '+40.4972364',
        INTPTLON: '-090.2555437',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5188, 40.8092],
            [-90.5187, 40.8168],
            [-90.4421, 40.8673],
            [-90.3279, 40.8489],
            [-90.2724, 40.7795],
            [-90.3378, 40.6694],
            [-90.3002, 40.6668],
            [-90.3292, 40.6273],
            [-90.3486, 40.602],
            [-90.5598, 40.6447],
            [-90.5188, 40.8092],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701413',
        NAME: 'Abingdon-Avon Community Unit School District 276',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475162674,
        AWATER: 1270612,
        INTPTLAT: '+40.7395234',
        INTPTLON: '-090.4232635',
        ELSDLEA: '',
        UNSDLEA: '01413',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3575, 39.7391],
            [-88.1951, 39.7459],
            [-88.1578, 39.7454],
            [-88.1769, 39.6521],
            [-88.2472, 39.62],
            [-88.4148, 39.6153],
            [-88.3575, 39.7391],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703960',
        NAME: 'Arcola Consolidated Unit School District 306',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293410537,
        AWATER: 327652,
        INTPTLAT: '+39.6755388',
        INTPTLON: '-088.2774386',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0019, 39.576],
            [-87.9324, 39.6571],
            [-87.7963, 39.5941],
            [-87.7933, 39.4854],
            [-87.8543, 39.4834],
            [-87.9039, 39.4819],
            [-87.9983, 39.5027],
            [-88.0019, 39.576],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720790',
        NAME: 'Kansas Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247341696,
        AWATER: 148672,
        INTPTLAT: '+39.5484694',
        INTPTLON: '-087.8963132',
        ELSDLEA: '',
        UNSDLEA: '20790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0232, 40.8828],
            [-90.9629, 40.925],
            [-90.9438, 41.0265],
            [-90.8633, 41.0325],
            [-90.8445, 40.9819],
            [-90.7865, 40.953],
            [-90.7883, 40.8135],
            [-90.7363, 40.7992],
            [-90.7884, 40.7629],
            [-90.7799, 40.6354],
            [-90.7897, 40.6358],
            [-90.8946, 40.6387],
            [-91.0203, 40.7001],
            [-91.1145, 40.7206],
            [-91.0969, 40.8114],
            [-91.0232, 40.8828],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700319',
        NAME: 'West Central Community Unit School District 235',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 785845613,
        AWATER: 28414971,
        INTPTLAT: '+40.8125075',
        INTPTLON: '-090.9129227',
        ELSDLEA: '',
        UNSDLEA: '00319',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8472, 40.8728],
            [-87.729, 40.8754],
            [-87.6531, 40.793],
            [-87.6492, 40.6873],
            [-87.7712, 40.6993],
            [-87.8269, 40.8586],
            [-87.8472, 40.8728],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720170',
        NAME: 'Iroquois County Community Unit School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219059842,
        AWATER: 649471,
        INTPTLAT: '+40.7820699',
        INTPTLON: '-087.7263056',
        ELSDLEA: '',
        UNSDLEA: '20170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8117, 40.9398],
            [-87.6186, 40.9864],
            [-87.5993, 40.9798],
            [-87.5612, 40.9807],
            [-87.5263, 41.0027],
            [-87.526, 40.9118],
            [-87.5257, 40.8173],
            [-87.6531, 40.793],
            [-87.729, 40.8754],
            [-87.8472, 40.8728],
            [-87.8117, 40.9398],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712510',
        NAME: 'Donovan Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336218020,
        AWATER: 399880,
        INTPTLAT: '+40.8980663',
        INTPTLON: '-087.6411606',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.873, 40.8433],
            [-87.8269, 40.8586],
            [-87.7712, 40.6993],
            [-87.8649, 40.6788],
            [-87.8976, 40.6817],
            [-87.873, 40.8433],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700323',
        NAME: 'Crescent Iroquois Community Unit School District 249',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156901640,
        AWATER: 517047,
        INTPTLAT: '+40.7650181',
        INTPTLON: '-087.8342462',
        ELSDLEA: '',
        UNSDLEA: '00323',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7303, 37.8634],
            [-88.6718, 37.9215],
            [-88.559, 37.9066],
            [-88.5593, 37.8596],
            [-88.606, 37.761],
            [-88.707, 37.7453],
            [-88.7071, 37.777],
            [-88.7303, 37.8634],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716020',
        NAME: 'Galatia Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218223194,
        AWATER: 2649112,
        INTPTLAT: '+37.8395467',
        INTPTLON: '-088.6348475',
        ELSDLEA: '',
        UNSDLEA: '16020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.707, 37.7453],
            [-88.606, 37.761],
            [-88.5593, 37.8596],
            [-88.5033, 37.7756],
            [-88.4106, 37.7448],
            [-88.4121, 37.5999],
            [-88.5989, 37.5993],
            [-88.597, 37.7312],
            [-88.7072, 37.7314],
            [-88.707, 37.7453],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718270',
        NAME: 'Harrisburg Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361668297,
        AWATER: 6833623,
        INTPTLAT: '+37.7008242',
        INTPTLON: '-088.5203235',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8602, 41.5407],
            [-89.787, 41.5256],
            [-89.6794, 41.5848],
            [-89.6321, 41.6568],
            [-89.5914, 41.7006],
            [-89.5164, 41.6244],
            [-89.512, 41.5238],
            [-89.5117, 41.4948],
            [-89.5101, 41.3311],
            [-89.6257, 41.3068],
            [-89.6257, 41.2555],
            [-89.7414, 41.2339],
            [-89.7119, 41.3074],
            [-89.7895, 41.3503],
            [-89.8568, 41.4522],
            [-89.8602, 41.5407],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700125',
        NAME: 'Bureau Valley Community Unit School District 340',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878532432,
        AWATER: 909063,
        INTPTLAT: '+41.4657450',
        INTPTLON: '-089.6554357',
        ELSDLEA: '',
        UNSDLEA: '00125',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2484, 37.3351],
            [-89.0628, 37.3308],
            [-88.9291, 37.3026],
            [-88.928, 37.2264],
            [-88.9334, 37.225],
            [-89.0437, 37.2007],
            [-89.1, 37.2407],
            [-89.2206, 37.2464],
            [-89.2484, 37.3351],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739630',
        NAME: 'Century Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290455469,
        AWATER: 5610379,
        INTPTLAT: '+37.2746335',
        INTPTLON: '-089.0792474',
        ELSDLEA: '',
        UNSDLEA: '39630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5219, 37.5662],
            [-89.4784, 37.5989],
            [-89.5166, 37.6927],
            [-89.5051, 37.6926],
            [-89.426, 37.6624],
            [-89.4156, 37.5997],
            [-89.3281, 37.4947],
            [-89.3208, 37.3347],
            [-89.3401, 37.2467],
            [-89.4587, 37.2483],
            [-89.4887, 37.2515],
            [-89.4891, 37.3332],
            [-89.4209, 37.394],
            [-89.4821, 37.4804],
            [-89.5219, 37.5662],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742990',
        NAME: 'Shawnee Community Unit School District 84',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517733028,
        AWATER: 26859400,
        INTPTLAT: '+37.4480056',
        INTPTLON: '-089.4152965',
        ELSDLEA: '',
        UNSDLEA: '42990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9741, 41.5395],
            [-89.8602, 41.5407],
            [-89.8568, 41.4522],
            [-89.7895, 41.3503],
            [-89.9876, 41.3151],
            [-90.011, 41.3228],
            [-89.9724, 41.3736],
            [-89.9741, 41.5395],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703810',
        NAME: 'Annawan Community Unit School District 226',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329810495,
        AWATER: 848868,
        INTPTLAT: '+41.4183538',
        INTPTLON: '-089.8964460',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.261, 41.346],
            [-90.0825, 41.353],
            [-90.0495, 41.3234],
            [-90.0596, 41.268],
            [-90.1994, 41.211],
            [-90.2906, 41.2659],
            [-90.261, 41.346],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708160',
        NAME: 'Cambridge Community Unit School District 227',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228550315,
        AWATER: 47264,
        INTPTLAT: '+41.2935942',
        INTPTLON: '-090.1789081',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3166, 41.7289],
            [-90.2424, 41.783],
            [-90.0928, 41.7574],
            [-89.9778, 41.7577],
            [-89.9978, 41.5837],
            [-90.1856, 41.5847],
            [-90.1612, 41.6439],
            [-90.3054, 41.6777],
            [-90.3131, 41.6996],
            [-90.3166, 41.7289],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714350',
        NAME: 'Erie Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402794595,
        AWATER: 9711555,
        INTPTLAT: '+41.6903958',
        INTPTLON: '-090.1097190',
        ELSDLEA: '',
        UNSDLEA: '14350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1994, 41.211],
            [-90.0596, 41.268],
            [-90.0495, 41.3234],
            [-90.011, 41.3228],
            [-89.9876, 41.3151],
            [-89.9927, 41.2347],
            [-89.9452, 41.1493],
            [-90.0332, 41.062],
            [-90.0963, 41.15],
            [-90.1993, 41.1667],
            [-90.1994, 41.211],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716140',
        NAME: 'Galva Community Unit School District 224',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285401267,
        AWATER: 48861,
        INTPTLAT: '+41.2001518',
        INTPTLON: '-090.0698072',
        ELSDLEA: '',
        UNSDLEA: '16140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4958, 41.4137],
            [-90.4319, 41.4568],
            [-90.3913, 41.4734],
            [-90.3767, 41.4787],
            [-90.3523, 41.4641],
            [-90.261, 41.346],
            [-90.2906, 41.2659],
            [-90.4354, 41.2678],
            [-90.4958, 41.4137],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730200',
        NAME: 'Orion Community Unit School District 223',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296829160,
        AWATER: 908068,
        INTPTLAT: '+41.3589277',
        INTPTLON: '-090.3751344',
        ELSDLEA: '',
        UNSDLEA: '30200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9452, 41.1493],
            [-89.8108, 41.2342],
            [-89.7619, 41.234],
            [-89.7832, 41.1345],
            [-89.6685, 41.1124],
            [-89.6386, 41.0613],
            [-89.6387, 40.9869],
            [-89.9668, 40.9746],
            [-90.0138, 41.062],
            [-90.0332, 41.062],
            [-89.9452, 41.1493],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737490',
        NAME: 'Stark County Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 606223197,
        AWATER: 794285,
        INTPTLAT: '+41.0696912',
        INTPTLON: '-089.8257815',
        ELSDLEA: '',
        UNSDLEA: '37490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1743, 42.3676],
            [-89.0547, 42.3817],
            [-88.9825, 42.403],
            [-88.9408, 42.4027],
            [-88.9399, 42.1821],
            [-88.9502, 42.1522],
            [-89.1725, 42.1799],
            [-89.173, 42.2042],
            [-89.1743, 42.3676],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734510',
        NAME: 'Rockford School District 205',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396220396,
        AWATER: 6003895,
        INTPTLAT: '+42.2609016',
        INTPTLON: '-089.0491147',
        ELSDLEA: '',
        UNSDLEA: '34510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9519, 42.4953],
            [-88.7765, 42.4919],
            [-88.7365, 42.4928],
            [-88.7056, 42.3279],
            [-88.8548, 42.3276],
            [-88.8613, 42.4107],
            [-88.9407, 42.4192],
            [-88.9407, 42.4454],
            [-88.9519, 42.4953],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728700',
        NAME: 'North Boone Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282393085,
        AWATER: 620710,
        INTPTLAT: '+42.4161956',
        INTPTLON: '-088.8117514',
        ELSDLEA: '',
        UNSDLEA: '28700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3734, 41.5385],
            [-89.3912, 41.5849],
            [-89.2565, 41.5852],
            [-89.1823, 41.5248],
            [-89.166, 41.4524],
            [-89.1657, 41.4302],
            [-89.2582, 41.4078],
            [-89.2776, 41.4078],
            [-89.3163, 41.4808],
            [-89.3938, 41.4949],
            [-89.3734, 41.5385],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721680',
        NAME: 'La Moille Community Unit School District 303',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230994875,
        AWATER: 69685,
        INTPTLAT: '+41.5140306',
        INTPTLON: '-089.2812600',
        ELSDLEA: '',
        UNSDLEA: '21680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3182, 41.1273],
            [-88.319, 41.1418],
            [-88.2093, 41.1731],
            [-88.0118, 41.2056],
            [-87.9912, 41.2053],
            [-87.8956, 41.1477],
            [-87.8945, 41.1451],
            [-87.8984, 41.0765],
            [-87.96, 41.0747],
            [-88.0167, 40.978],
            [-88.131, 40.9539],
            [-88.2105, 41.0536],
            [-88.3192, 40.9937],
            [-88.3182, 41.1273],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718840',
        NAME: 'Herscher Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 637191532,
        AWATER: 3088816,
        INTPTLAT: '+41.0944507',
        INTPTLON: '-088.1085121',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1387, 38.7019],
            [-89.0311, 38.7068],
            [-89.031, 38.674],
            [-89.0871, 38.5798],
            [-89.1422, 38.5569],
            [-89.179, 38.5497],
            [-89.176, 38.6651],
            [-89.1387, 38.7019],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735310',
        NAME: 'Sandoval Community Unit School District 501',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131293271,
        AWATER: 148346,
        INTPTLAT: '+38.6362537',
        INTPTLON: '-089.1186456',
        ELSDLEA: '',
        UNSDLEA: '35310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8323, 39.5663],
            [-89.6457, 39.5167],
            [-89.7369, 39.3628],
            [-89.9065, 39.4058],
            [-89.9259, 39.4928],
            [-89.8323, 39.5663],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701403',
        NAME: 'North Mac Community Unit School District 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385005281,
        AWATER: 2993673,
        INTPTLAT: '+39.4611598',
        INTPTLON: '-089.8001831',
        ELSDLEA: '',
        UNSDLEA: '01403',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0871, 38.5798],
            [-89.031, 38.674],
            [-89.0318, 38.5651],
            [-89.0871, 38.5798],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701404',
        NAME: 'Odin Public School District 722',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53102000,
        AWATER: 55907,
        INTPTLAT: '+38.6208095',
        INTPTLON: '-089.0562486',
        ELSDLEA: '',
        UNSDLEA: '01404',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.676, 39.6664],
            [-88.5772, 39.6736],
            [-88.4715, 39.5784],
            [-88.4707, 39.4747],
            [-88.6791, 39.5248],
            [-88.676, 39.6664],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738130',
        NAME: 'Sullivan Community Unit School District 300',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328493952,
        AWATER: 15999653,
        INTPTLAT: '+39.5751113',
        INTPTLON: '-088.5775543',
        ELSDLEA: '',
        UNSDLEA: '38130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6791, 39.5248],
            [-88.4707, 39.4747],
            [-88.4708, 39.3962],
            [-88.5414, 39.3529],
            [-88.6863, 39.3967],
            [-88.6973, 39.4796],
            [-88.6791, 39.5248],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742690',
        NAME: 'Windsor Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254082817,
        AWATER: 3823512,
        INTPTLAT: '+39.4389565',
        INTPTLON: '-088.5861905',
        ELSDLEA: '',
        UNSDLEA: '42690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5925, 40.8126],
            [-89.5605, 40.7975],
            [-89.5615, 40.7956],
            [-89.5838, 40.7393],
            [-89.5506, 40.7387],
            [-89.6161, 40.6608],
            [-89.624, 40.6335],
            [-89.6364, 40.6232],
            [-89.6428, 40.6234],
            [-89.6422, 40.6556],
            [-89.642, 40.6866],
            [-89.6893, 40.7399],
            [-89.6895, 40.7544],
            [-89.5925, 40.8126],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731230',
        NAME: 'Peoria School District 150',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95968721,
        AWATER: 3529212,
        INTPTLAT: '+40.7247961',
        INTPTLON: '-089.6140242',
        ELSDLEA: '',
        UNSDLEA: '31230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9668, 40.9746],
            [-89.6387, 40.9869],
            [-89.5992, 40.9733],
            [-89.601, 40.901],
            [-89.6972, 40.9158],
            [-89.756, 40.8279],
            [-89.878, 40.9086],
            [-89.9811, 40.8876],
            [-89.9668, 40.9746],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732770',
        NAME: 'Princeville Community Unit School District 326',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313768023,
        AWATER: 683698,
        INTPTLAT: '+40.9263809',
        INTPTLON: '-089.7835278',
        ELSDLEA: '',
        UNSDLEA: '32770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4715, 39.5784],
            [-88.4148, 39.6153],
            [-88.2472, 39.62],
            [-88.2474, 39.5214],
            [-88.3138, 39.3743],
            [-88.3276, 39.3742],
            [-88.4708, 39.3962],
            [-88.4707, 39.4747],
            [-88.4715, 39.5784],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725050',
        NAME: 'Mattoon Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415566719,
        AWATER: 924329,
        INTPTLAT: '+39.5053706',
        INTPTLON: '-088.3716337',
        ELSDLEA: '',
        UNSDLEA: '25050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2472, 39.62],
            [-88.1769, 39.6521],
            [-88.0019, 39.576],
            [-87.9983, 39.5027],
            [-88.0361, 39.4065],
            [-88.1023, 39.3765],
            [-88.3138, 39.3743],
            [-88.2474, 39.5214],
            [-88.2472, 39.62],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709600',
        NAME: 'Charleston Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 596652161,
        AWATER: 2586390,
        INTPTLAT: '+39.4906458',
        INTPTLON: '-088.1513846',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1023, 39.3765],
            [-88.0361, 39.4065],
            [-87.9983, 39.5027],
            [-87.9039, 39.4819],
            [-87.8952, 39.176],
            [-87.9497, 39.1442],
            [-88.0981, 39.1796],
            [-88.1023, 39.3765],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700002',
        NAME: 'Casey-Westfield Community Unit School District 4C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515188485,
        AWATER: 802782,
        INTPTLAT: '+39.3063433',
        INTPTLON: '-087.9938970',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.192, 38.2566],
            [-88.1691, 38.2706],
            [-88.0585, 38.2557],
            [-87.9556, 38.3263],
            [-87.943, 38.2578],
            [-87.9837, 38.2304],
            [-87.9853, 38.2155],
            [-88.1437, 38.2127],
            [-88.192, 38.2566],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717580',
        NAME: 'Grayville Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135813071,
        AWATER: 2085090,
        INTPTLAT: '+38.2514421',
        INTPTLON: '-088.0485283',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1659, 38.4447],
            [-88.1481, 38.5472],
            [-88.1476, 38.5687],
            [-87.9539, 38.5701],
            [-87.9556, 38.3263],
            [-88.0585, 38.2557],
            [-88.1691, 38.2706],
            [-88.2127, 38.314],
            [-88.1659, 38.4447],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713500',
        NAME: 'Edwards County Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588769827,
        AWATER: 953822,
        INTPTLAT: '+38.4194803',
        INTPTLON: '-088.0685354',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1389, 38.0376],
            [-88.9962, 38.0014],
            [-89.002, 37.9501],
            [-89.1508, 37.9502],
            [-89.1776, 37.9503],
            [-89.1389, 38.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700217',
        NAME: 'Christopher Community Unit School District 99',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96638112,
        AWATER: 1262957,
        INTPTLAT: '+37.9797384',
        INTPTLON: '-089.0780219',
        ELSDLEA: '',
        UNSDLEA: '00217',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2543, 38.7488],
            [-89.1884, 38.8256],
            [-88.9768, 38.8236],
            [-88.9776, 38.7363],
            [-89.0311, 38.7068],
            [-89.1387, 38.7019],
            [-89.2543, 38.7488],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730870',
        NAME: 'Patoka Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249143521,
        AWATER: 12766191,
        INTPTLAT: '+38.7697873',
        INTPTLON: '-089.1017353',
        ELSDLEA: '',
        UNSDLEA: '30870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1416, 40.5676],
            [-90.1021, 40.6259],
            [-89.9111, 40.6253],
            [-89.8781, 40.5095],
            [-89.9056, 40.4515],
            [-90.0685, 40.4807],
            [-90.1046, 40.4806],
            [-90.1416, 40.5676],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708280',
        NAME: 'Canton Union School District 66',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343443898,
        AWATER: 20929459,
        INTPTLAT: '+40.5423774',
        INTPTLON: '-090.0125920',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4899, 41.7211],
            [-88.4892, 41.7211],
            [-88.4105, 41.7222],
            [-88.3557, 41.6352],
            [-88.3522, 41.5485],
            [-88.3755, 41.5173],
            [-88.4637, 41.531],
            [-88.5434, 41.6226],
            [-88.4899, 41.7211],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743960',
        NAME: 'Yorkville Community Unit School District 115',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222476342,
        AWATER: 2162069,
        INTPTLAT: '+41.6168295',
        INTPTLON: '-088.4329896',
        ELSDLEA: '',
        UNSDLEA: '43960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5924, 41.7197],
            [-88.4899, 41.7211],
            [-88.5434, 41.6226],
            [-88.558, 41.617],
            [-88.5924, 41.7197],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731770',
        NAME: 'Plano Community Unit School District 88',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79638655,
        AWATER: 273472,
        INTPTLAT: '+41.6787416',
        INTPTLON: '-088.5372090',
        ELSDLEA: '',
        UNSDLEA: '31770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7918, 39.3071],
            [-90.688, 39.3666],
            [-90.6137, 39.3958],
            [-90.6139, 39.395],
            [-90.5806, 39.1849],
            [-90.6033, 39.1107],
            [-90.5762, 39.0114],
            [-90.6898, 39.0115],
            [-90.7013, 39.0699],
            [-90.7218, 39.2241],
            [-90.7918, 39.3071],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718180',
        NAME: 'Calhoun Community Unit School District 40',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377318488,
        AWATER: 37857180,
        INTPTLAT: '+39.1996355',
        INTPTLON: '-090.6683384',
        ELSDLEA: '',
        UNSDLEA: '18180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6139, 39.395],
            [-90.4394, 39.3577],
            [-90.3521, 39.3871],
            [-90.3141, 39.2307],
            [-90.4467, 39.1891],
            [-90.5806, 39.1849],
            [-90.6139, 39.395],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708610',
        NAME: 'Carrollton Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482072934,
        AWATER: 4476162,
        INTPTLAT: '+39.2852580',
        INTPTLON: '-090.4711694',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3521, 39.3871],
            [-90.228, 39.4707],
            [-90.2281, 39.5199],
            [-90.1725, 39.5202],
            [-90.1537, 39.5165],
            [-90.152, 39.4338],
            [-90.0947, 39.391],
            [-90.1015, 39.3053],
            [-90.1481, 39.2619],
            [-90.2389, 39.2181],
            [-90.3141, 39.2307],
            [-90.3521, 39.3871],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717670',
        NAME: 'Greenfield Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461098743,
        AWATER: 606810,
        INTPTLAT: '+39.3630520',
        INTPTLON: '-090.2215498',
        ELSDLEA: '',
        UNSDLEA: '17670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1079, 39.7999],
            [-89.9986, 39.8072],
            [-89.9855, 39.7812],
            [-90.0077, 39.6815],
            [-90.0207, 39.5796],
            [-90.0745, 39.5211],
            [-90.0987, 39.5207],
            [-90.1039, 39.5207],
            [-90.1537, 39.5165],
            [-90.1725, 39.5202],
            [-90.1913, 39.6069],
            [-90.1073, 39.7384],
            [-90.1079, 39.7999],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715750',
        NAME: 'Franklin Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351012059,
        AWATER: 349036,
        INTPTLAT: '+39.6664220',
        INTPTLON: '-090.0718983',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5806, 39.1849],
            [-90.4467, 39.1891],
            [-90.3141, 39.2307],
            [-90.2389, 39.2181],
            [-90.2207, 39],
            [-90.2758, 38.9235],
            [-90.4508, 38.9678],
            [-90.5762, 39.0114],
            [-90.6033, 39.1107],
            [-90.5806, 39.1849],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720430',
        NAME: 'Jersey Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 851599600,
        AWATER: 20049283,
        INTPTLAT: '+39.0794939',
        INTPTLON: '-090.3825522',
        ELSDLEA: '',
        UNSDLEA: '20430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1799, 37.8481],
            [-89.1512, 37.862],
            [-89.0071, 37.8676],
            [-88.9834, 37.8621],
            [-88.9707, 37.774],
            [-88.9841, 37.774],
            [-89.1515, 37.8037],
            [-89.1514, 37.8099],
            [-89.1799, 37.8481],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718810',
        NAME: 'Herrin Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123659864,
        AWATER: 3073199,
        INTPTLAT: '+37.8265652',
        INTPTLON: '-089.0675394',
        ELSDLEA: '',
        UNSDLEA: '18810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1524, 37.7125],
            [-89.1515, 37.8037],
            [-88.9841, 37.774],
            [-89.0783, 37.6861],
            [-89.1524, 37.7125],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708640',
        NAME: 'Carterville Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103826259,
        AWATER: 26338462,
        INTPTLAT: '+37.7354417',
        INTPTLON: '-089.0892170',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8327, 39.6494],
            [-89.8148, 39.6544],
            [-89.7127, 39.6551],
            [-89.6567, 39.6113],
            [-89.6175, 39.5209],
            [-89.6457, 39.5167],
            [-89.8323, 39.5663],
            [-89.8327, 39.6494],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704620',
        NAME: 'Auburn Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193390828,
        AWATER: 49623,
        INTPTLAT: '+39.5852827',
        INTPTLON: '-089.7259635',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7341, 39.7416],
            [-89.5807, 39.7182],
            [-89.4788, 39.6697],
            [-89.4973, 39.6409],
            [-89.6567, 39.6113],
            [-89.7127, 39.6551],
            [-89.8148, 39.6544],
            [-89.7341, 39.7416],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704920',
        NAME: 'Ball-Chatham Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233629269,
        AWATER: 11236190,
        INTPTLAT: '+39.6763633',
        INTPTLON: '-089.6612973',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0371, 40.1021],
            [-89.9674, 40.1426],
            [-89.829, 40.1257],
            [-89.8311, 40.0407],
            [-89.7691, 40.0041],
            [-89.7693, 39.9143],
            [-89.9757, 39.9098],
            [-89.995, 40.0466],
            [-90.0371, 40.1021],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731410',
        NAME: 'Porta Community Unit School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450534549,
        AWATER: 1771633,
        INTPTLAT: '+40.0193859',
        INTPTLON: '-089.8918147',
        ELSDLEA: '',
        UNSDLEA: '31410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0077, 39.6815],
            [-89.8327, 39.6494],
            [-89.8323, 39.5663],
            [-89.9259, 39.4928],
            [-90.0745, 39.5211],
            [-90.0207, 39.5796],
            [-90.0077, 39.6815],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741280',
        NAME: 'Waverly Community Unit School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246431121,
        AWATER: 227602,
        INTPTLAT: '+39.5835201',
        INTPTLON: '-089.9391375',
        ELSDLEA: '',
        UNSDLEA: '41280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5975, 39.8715],
            [-89.5304, 39.8745],
            [-89.5407, 39.8142],
            [-89.5742, 39.8023],
            [-89.5975, 39.8715],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734100',
        NAME: 'Riverton Community Unit School District 14',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38341003,
        AWATER: 660659,
        INTPTLAT: '+39.8455936',
        INTPTLON: '-089.5642846',
        ELSDLEA: '',
        UNSDLEA: '34100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3552, 40.1242],
            [-90.1996, 40.1839],
            [-90.119, 40.2353],
            [-90.033, 40.3778],
            [-89.9852, 40.3921],
            [-89.9326, 40.3233],
            [-89.98, 40.2461],
            [-89.9674, 40.1426],
            [-90.0371, 40.1021],
            [-90.1966, 40.0525],
            [-90.3011, 40.0762],
            [-90.3552, 40.1242],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718510',
        NAME: 'Havana Community Unit School District 126',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 530920887,
        AWATER: 51854017,
        INTPTLAT: '+40.1989957',
        INTPTLON: '-090.1105685',
        ELSDLEA: '',
        UNSDLEA: '18510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9056, 40.4515],
            [-89.8781, 40.5095],
            [-89.8635, 40.5177],
            [-89.7184, 40.4934],
            [-89.6635, 40.4798],
            [-89.6481, 40.48],
            [-89.6064, 40.4805],
            [-89.5848, 40.4508],
            [-89.6508, 40.3559],
            [-89.8526, 40.3035],
            [-89.9326, 40.3233],
            [-89.9852, 40.3921],
            [-89.9056, 40.4515],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743962',
        NAME: 'Midwest Central Community Unit School District 191',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495162640,
        AWATER: 15087888,
        INTPTLAT: '+40.4141384',
        INTPTLON: '-089.7861666',
        ELSDLEA: '',
        UNSDLEA: '43962',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4944, 41.1631],
            [-89.3939, 41.2337],
            [-89.3567, 41.2332],
            [-89.2788, 41.0893],
            [-89.5811, 41.0684],
            [-89.5632, 41.1487],
            [-89.4944, 41.1631],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700115',
        NAME: 'Henry-Senachwine Consolidated Unit School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264975153,
        AWATER: 32708037,
        INTPTLAT: '+41.1407979',
        INTPTLON: '-089.4189971',
        ELSDLEA: '',
        UNSDLEA: '00115',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3567, 41.2332],
            [-89.3353, 41.3007],
            [-89.2774, 41.3167],
            [-89.1637, 41.3102],
            [-89.1442, 41.265],
            [-89.1626, 41.1923],
            [-89.1241, 41.1115],
            [-89.1667, 41.0896],
            [-89.2788, 41.0893],
            [-89.3567, 41.2332],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732960',
        NAME: 'Putnam County Community Unit School District 535',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358752467,
        AWATER: 9976135,
        INTPTLAT: '+41.2043412',
        INTPTLON: '-089.2464212',
        ELSDLEA: '',
        UNSDLEA: '32960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7619, 41.234],
            [-89.7414, 41.2339],
            [-89.6257, 41.2555],
            [-89.544, 41.234],
            [-89.4944, 41.1631],
            [-89.5632, 41.1487],
            [-89.5811, 41.0684],
            [-89.6386, 41.0613],
            [-89.6685, 41.1124],
            [-89.7832, 41.1345],
            [-89.7619, 41.234],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706880',
        NAME: 'Bradford Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300809365,
        AWATER: 216735,
        INTPTLAT: '+41.1692600',
        INTPTLON: '-089.6475037',
        ELSDLEA: '',
        UNSDLEA: '06880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9205, 42.0051],
            [-87.8801, 41.9952],
            [-87.8738, 41.9767],
            [-87.8668, 41.9739],
            [-87.8559, 41.9891],
            [-87.8065, 42.019],
            [-87.7769, 42.0155],
            [-87.7618, 42.0047],
            [-87.7091, 42.0119],
            [-87.7087, 42.0191],
            [-87.6651, 42.023],
            [-87.5795, 41.7922],
            [-87.5237, 41.7599],
            [-87.5242, 41.717],
            [-87.525, 41.6446],
            [-87.5593, 41.6445],
            [-87.5881, 41.6447],
            [-87.6172, 41.6446],
            [-87.6367, 41.6574],
            [-87.647, 41.657],
            [-87.671, 41.6774],
            [-87.6864, 41.6772],
            [-87.7201, 41.684],
            [-87.7301, 41.6911],
            [-87.7206, 41.6986],
            [-87.7211, 41.7132],
            [-87.6818, 41.7137],
            [-87.6874, 41.7354],
            [-87.7217, 41.7278],
            [-87.7411, 41.7345],
            [-87.7421, 41.7711],
            [-87.8008, 41.7737],
            [-87.8021, 41.7978],
            [-87.7969, 41.7996],
            [-87.782, 41.7997],
            [-87.7531, 41.8134],
            [-87.7449, 41.822],
            [-87.7741, 41.8655],
            [-87.8057, 41.9089],
            [-87.8263, 41.9378],
            [-87.8496, 41.9373],
            [-87.853, 41.9431],
            [-87.8843, 41.9575],
            [-87.92, 41.9582],
            [-87.9399, 41.9935],
            [-87.9205, 42.0051],
          ],
          [
            [-87.8466, 41.9691],
            [-87.8073, 41.9539],
            [-87.8072, 41.9683],
            [-87.817, 41.9707],
            [-87.8185, 41.9706],
            [-87.8267, 41.9731],
            [-87.8334, 41.9739],
            [-87.8367, 41.9713],
            [-87.8416, 41.9701],
            [-87.8466, 41.9691],
          ],
          [
            [-87.8366, 41.9798],
            [-87.8244, 41.976],
            [-87.8235, 41.9831],
            [-87.8366, 41.9798],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709930',
        NAME: 'Chicago Public School District 299',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588969649,
        AWATER: 16493959,
        INTPTLAT: '+41.8368268',
        INTPTLON: '-087.6824319',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8263, 41.9378],
            [-87.8057, 41.9089],
            [-87.8245, 41.9086],
            [-87.8255, 41.9085],
            [-87.8255, 41.911],
            [-87.8263, 41.9378],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714100',
        NAME: 'Elmwood Park Community Unit School District 401',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5376236,
        AWATER: 0,
        INTPTLAT: '+41.9222301',
        INTPTLON: '-087.8173393',
        ELSDLEA: '',
        UNSDLEA: '14100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1869, 38.6079],
            [-90.0885, 38.5708],
            [-90.092, 38.5646],
            [-90.0975, 38.5536],
            [-90.1343, 38.5322],
            [-90.2241, 38.5739],
            [-90.1869, 38.6079],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708040',
        NAME: 'Cahokia Community Unit School District 187',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49523218,
        AWATER: 3076564,
        INTPTLAT: '+38.5692214',
        INTPTLON: '-090.1554167',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2641, 38.521],
            [-90.2574, 38.5319],
            [-90.2241, 38.5739],
            [-90.1343, 38.5322],
            [-90.144, 38.4846],
            [-90.2641, 38.521],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712720',
        NAME: 'Dupo Community Unit School District 196',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64855593,
        AWATER: 2670182,
        INTPTLAT: '+38.5245302',
        INTPTLON: '-090.1987699',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8074, 40.5704],
            [-88.6832, 40.5737],
            [-88.6512, 40.6693],
            [-88.4593, 40.5659],
            [-88.5069, 40.5076],
            [-88.479, 40.3987],
            [-88.603, 40.3983],
            [-88.6881, 40.3987],
            [-88.6882, 40.5155],
            [-88.8069, 40.516],
            [-88.8074, 40.5704],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700109',
        NAME: 'Ridgeview Community Unit School District 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513075747,
        AWATER: 194181,
        INTPTLAT: '+40.5228695',
        INTPTLON: '-088.6079007',
        ELSDLEA: '',
        UNSDLEA: '00109',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9181, 40.6345],
            [-88.903, 40.6664],
            [-88.8139, 40.7113],
            [-88.6512, 40.6693],
            [-88.6832, 40.5737],
            [-88.8074, 40.5704],
            [-88.9181, 40.6345],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722710',
        NAME: 'Lexington Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234411271,
        AWATER: 223776,
        INTPTLAT: '+40.6297261',
        INTPTLON: '-088.7879870',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8069, 40.516],
            [-88.6882, 40.5155],
            [-88.6881, 40.3987],
            [-88.8245, 40.3787],
            [-88.8728, 40.3304],
            [-88.9408, 40.4002],
            [-88.8438, 40.4507],
            [-88.8069, 40.516],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713920',
        NAME: 'Tri-Valley Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257144575,
        AWATER: 756699,
        INTPTLAT: '+40.4360944',
        INTPTLON: '-088.7996795',
        ELSDLEA: '',
        UNSDLEA: '13920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1816, 38.6604],
            [-90.1473, 38.6602],
            [-90.1464, 38.6602],
            [-90.1786, 38.6461],
            [-90.1816, 38.6604],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723640',
        NAME: 'Brooklyn Community Unit School District 188',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4006631,
        AWATER: 539342,
        INTPTLAT: '+38.6521930',
        INTPTLON: '-090.1643013',
        ELSDLEA: '',
        UNSDLEA: '23640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.25, 41.3885],
            [-88.2322, 41.3889],
            [-88.1736, 41.3843],
            [-88.0474, 41.3796],
            [-88.0183, 41.3804],
            [-88.0139, 41.2924],
            [-88.0403, 41.2226],
            [-88.1582, 41.2906],
            [-88.2419, 41.2887],
            [-88.25, 41.3878],
            [-88.25, 41.3885],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742630',
        NAME: 'Wilmington Community Unit School District 209U',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247060769,
        AWATER: 9929752,
        INTPTLAT: '+41.3151578',
        INTPTLON: '-088.1185719',
        ELSDLEA: '',
        UNSDLEA: '42630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9228, 42.3349],
            [-89.7489, 42.3141],
            [-89.6983, 42.2431],
            [-89.7762, 42.1972],
            [-89.929, 42.1968],
            [-89.9391, 42.1968],
            [-89.9228, 42.3349],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731020',
        NAME: 'Pearl City Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234676440,
        AWATER: 39879,
        INTPTLAT: '+42.2636599',
        INTPTLON: '-089.8388747',
        ELSDLEA: '',
        UNSDLEA: '31020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.946, 40.6512],
            [-87.8976, 40.6817],
            [-87.8649, 40.6788],
            [-87.7968, 40.5454],
            [-87.9446, 40.4859],
            [-87.9953, 40.5425],
            [-87.946, 40.6512],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710290',
        NAME: 'Cissna Park Community Unit School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224763062,
        AWATER: 105421,
        INTPTLAT: '+40.5721974',
        INTPTLON: '-087.8910161',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2788, 39.3997],
            [-89.1661, 39.4801],
            [-89.0817, 39.4648],
            [-88.9259, 39.4489],
            [-88.8802, 39.3517],
            [-88.9172, 39.293],
            [-89.0152, 39.2882],
            [-89.082, 39.2313],
            [-89.1397, 39.2328],
            [-89.1867, 39.3489],
            [-89.2788, 39.3997],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730630',
        NAME: 'Pana Community Unit School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569228220,
        AWATER: 1323457,
        INTPTLAT: '+39.3678609',
        INTPTLON: '-089.0794145',
        ELSDLEA: '',
        UNSDLEA: '30630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.082, 39.2313],
            [-89.0152, 39.2882],
            [-88.9172, 39.293],
            [-88.8064, 39.3256],
            [-88.7687, 39.2776],
            [-88.7685, 39.2408],
            [-88.9178, 39.1649],
            [-88.9444, 39.1482],
            [-89.082, 39.2313],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700176',
        NAME: 'Cowden-Herrick Community Unit School District 3A',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280904654,
        AWATER: 13853,
        INTPTLAT: '+39.2407774',
        INTPTLON: '-088.9274870',
        ELSDLEA: '',
        UNSDLEA: '00176',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9259, 39.4489],
            [-88.9063, 39.4996],
            [-88.7419, 39.4553],
            [-88.6973, 39.4796],
            [-88.6863, 39.3967],
            [-88.7687, 39.2776],
            [-88.8064, 39.3256],
            [-88.9172, 39.293],
            [-88.8802, 39.3517],
            [-88.9259, 39.4489],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736090',
        NAME: 'Shelbyville Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350052316,
        AWATER: 13342869,
        INTPTLAT: '+39.3843009',
        INTPTLON: '-088.8168260',
        ELSDLEA: '',
        UNSDLEA: '36090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7777, 40.1861],
            [-88.574, 40.1415],
            [-88.6075, 40.0543],
            [-88.7644, 40.0552],
            [-88.8413, 40.0613],
            [-88.8411, 40.1362],
            [-88.7777, 40.1861],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712030',
        NAME: 'DeLand-Weldon Community Unit School District 57',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269751513,
        AWATER: 4970614,
        INTPTLAT: '+40.1168008',
        INTPTLON: '-088.7036353',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1012, 39.6404],
            [-88.8681, 39.6529],
            [-88.9063, 39.4996],
            [-88.9259, 39.4489],
            [-89.0817, 39.4648],
            [-89.1661, 39.4801],
            [-89.1012, 39.6404],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709100',
        NAME: 'Central A & M Community Unit School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476792642,
        AWATER: 90698,
        INTPTLAT: '+39.5611417',
        INTPTLON: '-089.0021496',
        ELSDLEA: '',
        UNSDLEA: '09100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5414, 39.3529],
            [-88.4708, 39.3962],
            [-88.3276, 39.3742],
            [-88.3271, 39.2583],
            [-88.4198, 39.2318],
            [-88.5393, 39.2335],
            [-88.5414, 39.3529],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727840',
        NAME: 'Neoga Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282947105,
        AWATER: 4463923,
        INTPTLAT: '+39.2959784',
        INTPTLON: '-088.4256106',
        ELSDLEA: '',
        UNSDLEA: '27840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1397, 39.2328],
            [-89.082, 39.2313],
            [-88.9444, 39.1482],
            [-88.9858, 39.1138],
            [-89.0275, 39.0707],
            [-89.2507, 39.0864],
            [-89.2509, 39.1991],
            [-89.1397, 39.2328],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733090',
        NAME: 'Ramsey Community Unit School District 204',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365508926,
        AWATER: 503582,
        INTPTLAT: '+39.1471408',
        INTPTLON: '-089.1312216',
        ELSDLEA: '',
        UNSDLEA: '33090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2593, 40.976],
            [-90.1556, 40.9755],
            [-90.1001, 40.8742],
            [-90.1245, 40.8005],
            [-90.2724, 40.7795],
            [-90.3279, 40.8489],
            [-90.3262, 40.9592],
            [-90.2593, 40.976],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721390',
        NAME: 'Knoxville Community Unit School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348023851,
        AWATER: 261515,
        INTPTLAT: '+40.8754220',
        INTPTLON: '-090.2206246',
        ELSDLEA: '',
        UNSDLEA: '21390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3292, 40.6273],
            [-90.3002, 40.6668],
            [-90.3378, 40.6694],
            [-90.2724, 40.7795],
            [-90.1245, 40.8005],
            [-90.1387, 40.7206],
            [-90.1021, 40.6259],
            [-90.1416, 40.5676],
            [-90.2989, 40.5758],
            [-90.3486, 40.602],
            [-90.3292, 40.6273],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736960',
        NAME: 'Spoon River Valley Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405614332,
        AWATER: 1837971,
        INTPTLAT: '+40.6770405',
        INTPTLON: '-090.2281710',
        ELSDLEA: '',
        UNSDLEA: '36960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3714, 38.323],
            [-90.3475, 38.3797],
            [-90.3414, 38.3883],
            [-90.33, 38.3974],
            [-90.2583, 38.3977],
            [-90.2596, 38.2678],
            [-90.146, 38.1047],
            [-90.2067, 38.088],
            [-90.2527, 38.1278],
            [-90.3558, 38.2169],
            [-90.3677, 38.2581],
            [-90.3714, 38.323],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740080',
        NAME: 'Valmeyer Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331089194,
        AWATER: 20794965,
        INTPTLAT: '+38.2365962',
        INTPTLON: '-090.2722571',
        ELSDLEA: '',
        UNSDLEA: '40080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7968, 40.5454],
            [-87.5487, 40.5343],
            [-87.5263, 40.5673],
            [-87.5273, 40.4327],
            [-87.7434, 40.4258],
            [-87.7602, 40.3868],
            [-87.7649, 40.3904],
            [-87.8598, 40.3715],
            [-87.9329, 40.3994],
            [-87.9446, 40.4859],
            [-87.7968, 40.5454],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719660',
        NAME: 'Hoopeston Area Community Unit School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 458345957,
        AWATER: 88393,
        INTPTLAT: '+40.4698699',
        INTPTLON: '-087.7496638',
        ELSDLEA: '',
        UNSDLEA: '19660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3192, 40.9937],
            [-88.2105, 41.0536],
            [-88.131, 40.9539],
            [-88.0911, 40.8667],
            [-88.1006, 40.8519],
            [-88.1171, 40.7715],
            [-88.1256, 40.7634],
            [-88.1976, 40.6755],
            [-88.2082, 40.6754],
            [-88.2412, 40.8203],
            [-88.3548, 40.8232],
            [-88.3488, 40.9498],
            [-88.3588, 40.9787],
            [-88.3192, 40.9937],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739480',
        NAME: 'Tri-Point Community Unit School District 6-J',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561444644,
        AWATER: 26077,
        INTPTLAT: '+40.8719064',
        INTPTLON: '-088.2160962',
        ELSDLEA: '',
        UNSDLEA: '39480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7087, 41.3835],
            [-87.5262, 41.3853],
            [-87.5265, 41.3484],
            [-87.5267, 41.2981],
            [-87.7079, 41.2965],
            [-87.7087, 41.3835],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705430',
        NAME: 'Beecher Community Unit School District 200U',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147042334,
        AWATER: 12586,
        INTPTLAT: '+41.3439342',
        INTPTLON: '-087.6164059',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7873, 41.4698],
            [-87.7322, 41.4697],
            [-87.7031, 41.4698],
            [-87.6742, 41.4698],
            [-87.5867, 41.47],
            [-87.5257, 41.4703],
            [-87.5258, 41.4356],
            [-87.5262, 41.3853],
            [-87.7087, 41.3835],
            [-87.7862, 41.3831],
            [-87.7873, 41.4698],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711250',
        NAME: 'Crete-Monee Community Unit School District 201-U',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202399097,
        AWATER: 278824,
        INTPTLAT: '+41.4249732',
        INTPTLON: '-087.6530009',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1439, 41.7132],
            [-88.069, 41.7281],
            [-88.0306, 41.7289],
            [-88.0303, 41.7221],
            [-88.0288, 41.6698],
            [-88.0565, 41.5972],
            [-88.0704, 41.5876],
            [-88.1152, 41.5883],
            [-88.1328, 41.6031],
            [-88.1466, 41.6819],
            [-88.1439, 41.7132],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740070',
        NAME: 'Valley View Community Unit School District 365U',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100603969,
        AWATER: 1570683,
        INTPTLAT: '+41.6652246',
        INTPTLON: '-088.0888345',
        ELSDLEA: '',
        UNSDLEA: '40070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6851, 42.0248],
            [-89.6855, 42.0538],
            [-89.52, 42.0697],
            [-89.4453, 41.9309],
            [-89.6054, 41.8953],
            [-89.6854, 41.9303],
            [-89.6851, 42.0248],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732100',
        NAME: 'Polo Community Unit School District 222',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300644254,
        AWATER: 0,
        INTPTLAT: '+41.9807341',
        INTPTLON: '-089.5784899',
        ELSDLEA: '',
        UNSDLEA: '32100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1204, 42.241],
            [-88.1203, 42.2434],
            [-88.0628, 42.2408],
            [-88.029, 42.2116],
            [-88.034, 42.1537],
            [-88.0767, 42.1539],
            [-88.1204, 42.241],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721900',
        NAME: 'Lake Zurich Community Unit School District 95',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51189934,
        AWATER: 2339183,
        INTPTLAT: '+42.1992914',
        INTPTLON: '-088.0774569',
        ELSDLEA: '',
        UNSDLEA: '21900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7661, 39.881],
            [-87.7305, 39.8809],
            [-87.5332, 39.8534],
            [-87.5331, 39.8233],
            [-87.5328, 39.6945],
            [-87.5822, 39.7227],
            [-87.7638, 39.7339],
            [-87.7661, 39.881],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713370',
        NAME: 'Edgar County Community Unit District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325237620,
        AWATER: 23364,
        INTPTLAT: '+39.7965629',
        INTPTLON: '-087.6459928',
        ELSDLEA: '',
        UNSDLEA: '13370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7963, 39.5941],
            [-87.8071, 39.7039],
            [-87.7638, 39.7339],
            [-87.5822, 39.7227],
            [-87.5328, 39.6945],
            [-87.5322, 39.6073],
            [-87.5316, 39.4771],
            [-87.7933, 39.4854],
            [-87.7963, 39.5941],
          ],
          [
            [-87.7226, 39.6171],
            [-87.7114, 39.5812],
            [-87.6727, 39.6062],
            [-87.7226, 39.6171],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730780',
        NAME: 'Paris Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601319963,
        AWATER: 1213637,
        INTPTLAT: '+39.5999662',
        INTPTLON: '-087.6598858',
        ELSDLEA: '',
        UNSDLEA: '30780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8634, 40.0802],
            [-87.8066, 40.0831],
            [-87.7135, 39.9985],
            [-87.7305, 39.8809],
            [-87.7661, 39.881],
            [-87.8819, 39.88],
            [-87.9014, 40.0264],
            [-87.8634, 40.0802],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720350',
        NAME: 'Jamaica Community Unit School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290462022,
        AWATER: 802752,
        INTPTLAT: '+39.9628151',
        INTPTLON: '-087.8163316',
        ELSDLEA: '',
        UNSDLEA: '20350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9811, 40.8876],
            [-89.878, 40.9086],
            [-89.756, 40.8279],
            [-89.6895, 40.7544],
            [-89.6893, 40.7399],
            [-89.6893, 40.7302],
            [-89.7175, 40.7323],
            [-89.7658, 40.7848],
            [-89.9369, 40.7998],
            [-89.9854, 40.8584],
            [-89.9854, 40.8653],
            [-89.9811, 40.8876],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707200',
        NAME: 'Brimfield Community Unit School District 309',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194490068,
        AWATER: 33457,
        INTPTLAT: '+40.8283731',
        INTPTLON: '-089.8448465',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.756, 40.8279],
            [-89.6972, 40.9158],
            [-89.601, 40.901],
            [-89.5925, 40.8126],
            [-89.6895, 40.7544],
            [-89.756, 40.8279],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712700',
        NAME: 'Dunlap Community Unit School District 323',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175400114,
        AWATER: 9467,
        INTPTLAT: '+40.8420361',
        INTPTLON: '-089.6764493',
        ELSDLEA: '',
        UNSDLEA: '12700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7226, 39.6171],
            [-87.6727, 39.6062],
            [-87.7114, 39.5812],
            [-87.7226, 39.6171],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730750',
        NAME: 'Paris-Union School District 95',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17696247,
        AWATER: 238593,
        INTPTLAT: '+39.6065857',
        INTPTLON: '-087.6968033',
        ELSDLEA: '',
        UNSDLEA: '30750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4967, 40.3654],
            [-90.4478, 40.4521],
            [-90.3621, 40.4736],
            [-90.2592, 40.4066],
            [-90.219, 40.305],
            [-90.4028, 40.2743],
            [-90.4508, 40.2403],
            [-90.4967, 40.3654],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738490',
        NAME: 'V.I.T. Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368750455,
        AWATER: 854473,
        INTPTLAT: '+40.3523551',
        INTPTLON: '-090.3715081',
        ELSDLEA: '',
        UNSDLEA: '38490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7135, 39.9985],
            [-87.6663, 40.0206],
            [-87.5323, 40.0118],
            [-87.5332, 39.8534],
            [-87.7305, 39.8809],
            [-87.7135, 39.9985],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700092',
        NAME: 'Georgetown-Ridge Farm Consolidated Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260037465,
        AWATER: 355381,
        INTPTLAT: '+39.9476395',
        INTPTLON: '-087.6148392',
        ELSDLEA: '',
        UNSDLEA: '00092',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3664, 38.911],
            [-88.3606, 39.1232],
            [-88.3029, 39.171],
            [-88.0981, 39.1796],
            [-87.9497, 39.1442],
            [-87.9853, 39.0645],
            [-87.9928, 38.8497],
            [-88.1863, 38.8469],
            [-88.2585, 38.8219],
            [-88.3616, 38.8671],
            [-88.3664, 38.911],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720380',
        NAME: 'Jasper County Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1167651911,
        AWATER: 9319417,
        INTPTLAT: '+39.0089911',
        INTPTLON: '-088.1602447',
        ELSDLEA: '',
        UNSDLEA: '20380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2585, 38.8219],
            [-88.1863, 38.8469],
            [-88.15, 38.7882],
            [-88.1476, 38.5687],
            [-88.1481, 38.5472],
            [-88.2221, 38.5839],
            [-88.2974, 38.6346],
            [-88.2962, 38.7904],
            [-88.2585, 38.8219],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741730',
        NAME: 'West Richland Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325541078,
        AWATER: 274206,
        INTPTLAT: '+38.6959011',
        INTPTLON: '-088.2174355',
        ELSDLEA: '',
        UNSDLEA: '41730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7722, 40.2811],
            [-90.7049, 40.3527],
            [-90.6286, 40.3817],
            [-90.4967, 40.3654],
            [-90.4508, 40.2403],
            [-90.4077, 40.1587],
            [-90.2199, 40.184],
            [-90.1996, 40.1839],
            [-90.3552, 40.1242],
            [-90.4335, 40.024],
            [-90.5137, 39.9879],
            [-90.6071, 39.9817],
            [-90.6959, 40.1038],
            [-90.8384, 40.105],
            [-90.7722, 40.2811],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700332',
        NAME: 'Schuyler-Industry Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1117677732,
        AWATER: 10569099,
        INTPTLAT: '+40.1797093',
        INTPTLON: '-090.5819288',
        ELSDLEA: '',
        UNSDLEA: '00332',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9497, 39.1442],
            [-87.8952, 39.176],
            [-87.8847, 39.1761],
            [-87.8437, 39.0904],
            [-87.8138, 38.9091],
            [-87.7582, 38.8556],
            [-87.7596, 38.8495],
            [-87.9078, 38.887],
            [-87.9459, 38.8501],
            [-87.9928, 38.8497],
            [-87.9853, 39.0645],
            [-87.9497, 39.1442],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729420',
        NAME: 'Oblong Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439917034,
        AWATER: 159897,
        INTPTLAT: '+38.9917824',
        INTPTLON: '-087.8936511',
        ELSDLEA: '',
        UNSDLEA: '29420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5971, 40.6309],
            [-90.5598, 40.6447],
            [-90.3486, 40.602],
            [-90.2989, 40.5758],
            [-90.4176, 40.5168],
            [-90.4478, 40.4521],
            [-90.5991, 40.5139],
            [-90.5991, 40.5211],
            [-90.5971, 40.6309],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707950',
        NAME: 'Bushnell-Prairie City Community Unit School District 170',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344714275,
        AWATER: 519709,
        INTPTLAT: '+40.5623114',
        INTPTLON: '-090.4608548',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2207, 39],
            [-90.0528, 38.9986],
            [-90.0208, 38.9987],
            [-90.0412, 38.9708],
            [-90.1132, 38.8933],
            [-90.1272, 38.8696],
            [-90.121, 38.8529],
            [-90.2758, 38.9235],
            [-90.2207, 39],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703600',
        NAME: 'Alton Community Unit School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210359716,
        AWATER: 10597544,
        INTPTLAT: '+38.9560079',
        INTPTLON: '-090.1693542',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0208, 39.0608],
            [-89.9233, 39.1291],
            [-89.8671, 39.0565],
            [-89.8979, 38.988],
            [-90.0208, 38.9987],
            [-90.0528, 38.9986],
            [-90.0208, 39.0608],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707770',
        NAME: 'Bunker Hill Community Unit School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162560121,
        AWATER: 723781,
        INTPTLAT: '+39.0475525',
        INTPTLON: '-089.9560901',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0871, 38.8896],
            [-90.0403, 38.8698],
            [-90.0368, 38.8427],
            [-90.076, 38.7971],
            [-90.087, 38.8692],
            [-90.0871, 38.8896],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735010',
        NAME: 'Roxana Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39411255,
        AWATER: 695914,
        INTPTLAT: '+38.8451365',
        INTPTLON: '-090.0587963',
        ELSDLEA: '',
        UNSDLEA: '35010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4528, 41.9363],
            [-88.3804, 41.9918],
            [-88.2677, 41.9863],
            [-88.2378, 41.936],
            [-88.2624, 41.8992],
            [-88.4237, 41.8965],
            [-88.4528, 41.9363],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737170',
        NAME: 'St. Charles Community Unit School District 303',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148943781,
        AWATER: 2379346,
        INTPTLAT: '+41.9369978',
        INTPTLON: '-088.3476143',
        ELSDLEA: '',
        UNSDLEA: '37170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9407, 37.9709],
            [-89.9489, 38.0179],
            [-89.8725, 38.0186],
            [-89.7596, 38.001],
            [-89.6715, 37.901],
            [-89.6743, 37.8031],
            [-89.8448, 37.9056],
            [-89.9386, 37.8743],
            [-89.9407, 37.9709],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709810',
        NAME: 'Chester Community Unit School District 139',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339380380,
        AWATER: 23313579,
        INTPTLAT: '+37.9243039',
        INTPTLON: '-089.8180072',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8156, 38.2199],
            [-89.6572, 38.2194],
            [-89.5924, 38.1394],
            [-89.593, 38.0731],
            [-89.6303, 38.0585],
            [-89.7596, 38.001],
            [-89.8725, 38.0186],
            [-89.9489, 38.0179],
            [-89.9437, 38.1202],
            [-89.8574, 38.1142],
            [-89.8156, 38.2199],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736900',
        NAME: 'Sparta Community Unit School District 140',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 484855925,
        AWATER: 7552266,
        INTPTLAT: '+38.1060187',
        INTPTLON: '-089.7716149',
        ELSDLEA: '',
        UNSDLEA: '36900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6303, 38.0585],
            [-89.5987, 37.9553],
            [-89.6715, 37.901],
            [-89.7596, 38.001],
            [-89.6303, 38.0585],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737650',
        NAME: 'Steeleville Community Unit School District 138',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147790731,
        AWATER: 366127,
        INTPTLAT: '+37.9908305',
        INTPTLON: '-089.6794471',
        ELSDLEA: '',
        UNSDLEA: '37650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7596, 38.8495],
            [-87.76, 38.7756],
            [-87.6851, 38.6876],
            [-87.6856, 38.6273],
            [-87.7601, 38.5688],
            [-87.8177, 38.5696],
            [-87.9123, 38.5701],
            [-87.9081, 38.8501],
            [-87.9459, 38.8501],
            [-87.9078, 38.887],
            [-87.7596, 38.8495],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722130',
        NAME: 'Red Hill Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 529168814,
        AWATER: 276836,
        INTPTLAT: '+38.7133245',
        INTPTLON: '-087.8290002',
        ELSDLEA: '',
        UNSDLEA: '22130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7601, 38.5688],
            [-87.6856, 38.6273],
            [-87.6851, 38.6876],
            [-87.76, 38.7756],
            [-87.7596, 38.8495],
            [-87.7582, 38.8556],
            [-87.5942, 38.8518],
            [-87.5311, 38.8525],
            [-87.5062, 38.7335],
            [-87.5644, 38.6749],
            [-87.6515, 38.5682],
            [-87.7601, 38.5688],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722150',
        NAME: 'Lawrence County Community Unit District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476961828,
        AWATER: 4800942,
        INTPTLAT: '+38.7440615',
        INTPTLON: '-087.6396992',
        ELSDLEA: '',
        UNSDLEA: '22150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6624, 39.0591],
            [-87.6107, 39.0812],
            [-87.5229, 38.9024],
            [-87.5311, 38.8525],
            [-87.5942, 38.8518],
            [-87.681, 38.919],
            [-87.6624, 39.0591],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730480',
        NAME: 'Palestine Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224256074,
        AWATER: 2855365,
        INTPTLAT: '+38.9712058',
        INTPTLON: '-087.6111145',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6624, 39.0591],
            [-87.681, 38.919],
            [-87.5942, 38.8518],
            [-87.7582, 38.8556],
            [-87.8138, 38.9091],
            [-87.8437, 39.0904],
            [-87.7646, 39.1085],
            [-87.6624, 39.0591],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734230',
        NAME: 'Robinson Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339356112,
        AWATER: 889886,
        INTPTLAT: '+38.9738294',
        INTPTLON: '-087.7314446',
        ELSDLEA: '',
        UNSDLEA: '34230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2382, 42.1867],
            [-88.2035, 42.2049],
            [-88.1988, 42.2367],
            [-88.1204, 42.241],
            [-88.0767, 42.1539],
            [-88.1217, 42.0671],
            [-88.1446, 42.0574],
            [-88.2084, 42.0673],
            [-88.2382, 42.1867],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705050',
        NAME: 'Barrington Community Unit School District 220',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171329300,
        AWATER: 6458374,
        INTPTLAT: '+42.1464981',
        INTPTLON: '-088.1646544',
        ELSDLEA: '',
        UNSDLEA: '05050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4121, 37.5999],
            [-88.1957, 37.6066],
            [-88.1332, 37.5741],
            [-88.0613, 37.5053],
            [-88.0822, 37.4727],
            [-88.2792, 37.4533],
            [-88.3585, 37.4048],
            [-88.4151, 37.4241],
            [-88.4121, 37.5999],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718200',
        NAME: 'Hardin County Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467887478,
        AWATER: 10539570,
        INTPTLAT: '+37.5178517',
        INTPTLON: '-088.2661478',
        ELSDLEA: '',
        UNSDLEA: '18200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8621, 41.8882],
            [-89.6854, 41.9303],
            [-89.6054, 41.8953],
            [-89.6028, 41.8145],
            [-89.6776, 41.7889],
            [-89.7632, 41.7576],
            [-89.8144, 41.7181],
            [-89.8454, 41.7579],
            [-89.8621, 41.8882],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742310',
        NAME: 'Sterling Community Unit District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305766823,
        AWATER: 5241504,
        INTPTLAT: '+41.8332048',
        INTPTLON: '-089.7379834',
        ELSDLEA: '',
        UNSDLEA: '42310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6031, 41.7296],
            [-89.5738, 41.7441],
            [-89.3614, 41.7944],
            [-89.2858, 41.7075],
            [-89.2092, 41.7042],
            [-89.1849, 41.6977],
            [-89.167, 41.6146],
            [-89.2565, 41.5852],
            [-89.3912, 41.5849],
            [-89.5164, 41.6244],
            [-89.5914, 41.7006],
            [-89.6031, 41.7296],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703690',
        NAME: 'Amboy Community Unit School District 272',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525789226,
        AWATER: 1306904,
        INTPTLAT: '+41.6858092',
        INTPTLON: '-089.3721205',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0461, 41.7895],
            [-88.9362, 41.8843],
            [-88.8671, 41.8777],
            [-88.7149, 41.835],
            [-88.6608, 41.7191],
            [-88.6887, 41.7188],
            [-88.7519, 41.685],
            [-88.8823, 41.6298],
            [-88.9262, 41.658],
            [-88.9265, 41.7171],
            [-89.0461, 41.7895],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735970',
        NAME: 'Indian Creek Community Unit District 425',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 458352583,
        AWATER: 2395078,
        INTPTLAT: '+41.7798720',
        INTPTLON: '-088.8619454',
        ELSDLEA: '',
        UNSDLEA: '35970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1126, 41.7813],
            [-89.0461, 41.7895],
            [-88.9265, 41.7171],
            [-88.9262, 41.658],
            [-89.0052, 41.6274],
            [-89.0986, 41.7522],
            [-89.1684, 41.7443],
            [-89.1126, 41.7813],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722350',
        NAME: 'Lee Center Community Unit School District 271',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159277342,
        AWATER: 136364,
        INTPTLAT: '+41.6998191',
        INTPTLON: '-089.0166100',
        ELSDLEA: '',
        UNSDLEA: '22350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3718, 39.7227],
            [-90.3169, 39.7678],
            [-90.2765, 39.874],
            [-90.1498, 39.8731],
            [-90.1079, 39.7999],
            [-90.1073, 39.7384],
            [-90.1913, 39.6069],
            [-90.1725, 39.5202],
            [-90.2281, 39.5199],
            [-90.3018, 39.5202],
            [-90.3007, 39.6365],
            [-90.3718, 39.7227],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720280',
        NAME: 'Jacksonville School District 117',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 570261437,
        AWATER: 3112742,
        INTPTLAT: '+39.6997882',
        INTPTLON: '-090.2309490',
        ELSDLEA: '',
        UNSDLEA: '20280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7424, 42.5054],
            [-89.5638, 42.5026],
            [-89.4551, 42.5012],
            [-89.4442, 42.4896],
            [-89.5558, 42.4611],
            [-89.6178, 42.3886],
            [-89.7136, 42.3785],
            [-89.7424, 42.5054],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730060',
        NAME: 'Orangeville Community Unit School District 203',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216792554,
        AWATER: 0,
        INTPTLAT: '+42.4524636',
        INTPTLON: '-089.6296486',
        ELSDLEA: '',
        UNSDLEA: '30060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1786, 38.6461],
            [-90.1464, 38.6602],
            [-90.1129, 38.6599],
            [-90.12, 38.6456],
            [-90.1253, 38.6388],
            [-90.0373, 38.6232],
            [-90.0359, 38.572],
            [-90.0465, 38.5721],
            [-90.0885, 38.5708],
            [-90.1869, 38.6079],
            [-90.1786, 38.6461],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713320',
        NAME: 'East St. Louis School District 189',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86314064,
        AWATER: 2626262,
        INTPTLAT: '+38.6162038',
        INTPTLON: '-090.1054892',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9288, 38.5427],
            [-89.8739, 38.5697],
            [-89.825, 38.5689],
            [-89.7056, 38.5679],
            [-89.7044, 38.4157],
            [-89.7042, 38.4076],
            [-89.7811, 38.394],
            [-89.9286, 38.4836],
            [-89.9287, 38.5262],
            [-89.9288, 38.5427],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724940',
        NAME: 'Mascoutah Community Unit School District 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271104476,
        AWATER: 2288723,
        INTPTLAT: '+38.4927883',
        INTPTLON: '-089.7988778',
        ELSDLEA: '',
        UNSDLEA: '24940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9989, 38.3626],
            [-89.9643, 38.3589],
            [-89.772, 38.3498],
            [-89.741, 38.3356],
            [-89.8158, 38.307],
            [-89.8512, 38.2203],
            [-89.9624, 38.2798],
            [-90.0361, 38.3233],
            [-89.9989, 38.3626],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727960',
        NAME: 'New Athens Community Unit School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209840274,
        AWATER: 11726878,
        INTPTLAT: '+38.3137345',
        INTPTLON: '-089.8846130',
        ELSDLEA: '',
        UNSDLEA: '27960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9624, 38.2798],
            [-89.8512, 38.2203],
            [-89.8156, 38.2199],
            [-89.8574, 38.1142],
            [-89.9437, 38.1202],
            [-89.9489, 38.0179],
            [-89.9407, 37.9709],
            [-90.0404, 38.0002],
            [-90.0807, 38.1231],
            [-90.0364, 38.2665],
            [-89.9624, 38.2798],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733300',
        NAME: 'Red Bud Community Unit School District 132',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426495187,
        AWATER: 17795587,
        INTPTLAT: '+38.1464338',
        INTPTLON: '-089.9691187',
        ELSDLEA: '',
        UNSDLEA: '33300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7056, 38.5679],
            [-89.707, 38.6552],
            [-89.6232, 38.6557],
            [-89.6306, 38.5723],
            [-89.64, 38.5361],
            [-89.5933, 38.4755],
            [-89.7044, 38.4157],
            [-89.7056, 38.5679],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739420',
        NAME: 'Wesclin Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144980473,
        AWATER: 435323,
        INTPTLAT: '+38.5474899',
        INTPTLON: '-089.6633646',
        ELSDLEA: '',
        UNSDLEA: '39420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9616, 42.0649],
            [-88.9502, 42.1522],
            [-88.9399, 42.1821],
            [-88.7931, 42.1498],
            [-88.7736, 42.0524],
            [-88.8544, 42.0161],
            [-88.8879, 42.0149],
            [-88.9616, 42.0649],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721300',
        NAME: 'Hiawatha Community Unit School District 426',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200091457,
        AWATER: 1319784,
        INTPTLAT: '+42.0999817',
        INTPTLON: '-088.8740605',
        ELSDLEA: '',
        UNSDLEA: '21300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.051, 37.5968],
            [-88.875, 37.5857],
            [-88.8849, 37.5193],
            [-88.9288, 37.4903],
            [-89.043, 37.5077],
            [-89.051, 37.5968],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700014',
        NAME: 'Goreville Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151689997,
        AWATER: 5527918,
        INTPTLAT: '+37.5527196',
        INTPTLON: '-088.9631335',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2189, 37.3898],
            [-89.156, 37.4376],
            [-89.0446, 37.406],
            [-89.0628, 37.3308],
            [-89.2484, 37.3351],
            [-89.2189, 37.3898],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712480',
        NAME: 'Dongola School Unit District 66',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151949244,
        AWATER: 1292231,
        INTPTLAT: '+37.3809789',
        INTPTLON: '-089.1469603',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1245, 40.8005],
            [-90.1001, 40.8742],
            [-89.9854, 40.8653],
            [-89.9854, 40.8584],
            [-89.9861, 40.7123],
            [-89.7565, 40.7109],
            [-89.7566, 40.653],
            [-89.9111, 40.6253],
            [-90.1021, 40.6259],
            [-90.1387, 40.7206],
            [-90.1245, 40.8005],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700044',
        NAME: 'Farmington Central Community Unit School District 265',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489042858,
        AWATER: 2594258,
        INTPTLAT: '+40.6707356',
        INTPTLON: '-089.8525283',
        ELSDLEA: '',
        UNSDLEA: '00044',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5615, 40.7956],
            [-89.555, 40.7476],
            [-89.5506, 40.7387],
            [-89.5838, 40.7393],
            [-89.5615, 40.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731270',
        NAME: 'Peoria Heights Community Unit School District 325',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11407342,
        AWATER: 2998341,
        INTPTLAT: '+40.7691887',
        INTPTLON: '-089.5771675',
        ELSDLEA: '',
        UNSDLEA: '31270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3599, 41.9239],
            [-89.2246, 41.9394],
            [-89.1218, 41.8683],
            [-89.1126, 41.7813],
            [-89.1684, 41.7443],
            [-89.1902, 41.7435],
            [-89.1849, 41.6977],
            [-89.2092, 41.7042],
            [-89.2858, 41.7075],
            [-89.3614, 41.7944],
            [-89.3599, 41.9239],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704380',
        NAME: 'Ashton Community Unit School District 275',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381710382,
        AWATER: 724162,
        INTPTLAT: '+41.8232958',
        INTPTLON: '-089.2413951',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8543, 39.4834],
            [-87.7933, 39.4854],
            [-87.5316, 39.4771],
            [-87.5316, 39.3479],
            [-87.6041, 39.2595],
            [-87.6205, 39.185],
            [-87.6889, 39.216],
            [-87.7259, 39.2466],
            [-87.7906, 39.4038],
            [-87.8543, 39.4834],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724870',
        NAME: 'Marshall Community Unit School District 2C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552898729,
        AWATER: 4657096,
        INTPTLAT: '+39.3638935',
        INTPTLON: '-087.6659459',
        ELSDLEA: '',
        UNSDLEA: '24870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5883, 42.0665],
            [-88.3761, 42.0669],
            [-88.3804, 41.9918],
            [-88.4528, 41.9363],
            [-88.566, 41.9676],
            [-88.6029, 42.0665],
            [-88.5883, 42.0665],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707830',
        NAME: 'Central Community Unit School District 301',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215940821,
        AWATER: 55754,
        INTPTLAT: '+42.0154285',
        INTPTLON: '-088.4837692',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9167, 41.9791],
            [-88.8879, 42.0149],
            [-88.8544, 42.0161],
            [-88.6405, 41.9376],
            [-88.6596, 41.8788],
            [-88.7149, 41.835],
            [-88.8671, 41.8777],
            [-88.9362, 41.8843],
            [-88.9413, 41.8918],
            [-88.9167, 41.9791],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712000',
        NAME: 'DeKalb Community Unit School District 428',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281921489,
        AWATER: 1121057,
        INTPTLAT: '+41.9141882',
        INTPTLON: '-088.7941960',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7931, 42.1498],
            [-88.7056, 42.1536],
            [-88.5887, 42.1536],
            [-88.5883, 42.0665],
            [-88.6029, 42.0665],
            [-88.7736, 42.0524],
            [-88.7931, 42.1498],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716410',
        NAME: 'Genoa-Kingston Community Unit School District 424',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174895915,
        AWATER: 1188922,
        INTPTLAT: '+42.1072087',
        INTPTLON: '-088.6922750',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6405, 41.9376],
            [-88.566, 41.9676],
            [-88.4528, 41.9363],
            [-88.4237, 41.8965],
            [-88.4059, 41.8534],
            [-88.3761, 41.8293],
            [-88.3753, 41.7226],
            [-88.4105, 41.7222],
            [-88.4892, 41.7211],
            [-88.5032, 41.8082],
            [-88.6023, 41.8068],
            [-88.6596, 41.8788],
            [-88.6405, 41.9376],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724480',
        NAME: 'Kaneland Community Unit School District 302',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365629106,
        AWATER: 756517,
        INTPTLAT: '+41.8669880',
        INTPTLON: '-088.5092225',
        ELSDLEA: '',
        UNSDLEA: '24480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1986, 42.3289],
            [-88.14, 42.3285],
            [-88.1208, 42.3295],
            [-88.1203, 42.2434],
            [-88.1204, 42.241],
            [-88.1988, 42.2367],
            [-88.2134, 42.245],
            [-88.2268, 42.2797],
            [-88.1986, 42.3289],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741190',
        NAME: 'Wauconda Community Unit School District 118',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66604109,
        AWATER: 3992774,
        INTPTLAT: '+42.2825768',
        INTPTLON: '-088.1665871',
        ELSDLEA: '',
        UNSDLEA: '41190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2634, 39.978],
            [-91.2284, 39.9775],
            [-91.0306, 39.9608],
            [-90.993, 39.8882],
            [-90.9168, 39.9022],
            [-90.9166, 39.8451],
            [-90.9161, 39.7647],
            [-90.9537, 39.7571],
            [-91.0298, 39.7717],
            [-91.185, 39.8453],
            [-91.2638, 39.9347],
            [-91.2634, 39.978],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722770',
        NAME: 'Liberty Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 419688623,
        AWATER: 920458,
        INTPTLAT: '+39.8712428',
        INTPTLON: '-091.0656471',
        ELSDLEA: '',
        UNSDLEA: '22770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5306, 42.5077],
            [-90.4264, 42.5072],
            [-90.3819, 42.507],
            [-90.3412, 42.401],
            [-90.4184, 42.3276],
            [-90.4738, 42.3815],
            [-90.5273, 42.4063],
            [-90.5306, 42.5077],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716050',
        NAME: 'Galena Unit School District 120',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222630261,
        AWATER: 14653971,
        INTPTLAT: '+42.4244972',
        INTPTLON: '-090.4324171',
        ELSDLEA: '',
        UNSDLEA: '16050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1624, 42.3861],
            [-90.1628, 42.4222],
            [-89.9244, 42.4102],
            [-89.9228, 42.3349],
            [-89.9391, 42.1968],
            [-90.1555, 42.1969],
            [-90.0997, 42.3138],
            [-90.1624, 42.3861],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737980',
        NAME: 'Stockton Community Unit School District 206',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430683989,
        AWATER: 316529,
        INTPTLAT: '+42.3062384',
        INTPTLON: '-090.0443512',
        ELSDLEA: '',
        UNSDLEA: '37980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3819, 42.507],
            [-90.3086, 42.5074],
            [-90.1871, 42.508],
            [-90.1628, 42.4222],
            [-90.1624, 42.3861],
            [-90.3412, 42.401],
            [-90.3819, 42.507],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735610',
        NAME: 'Scales Mound Community Unit School District 211',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184181317,
        AWATER: 1234577,
        INTPTLAT: '+42.4527650',
        INTPTLON: '-090.2624469',
        ELSDLEA: '',
        UNSDLEA: '35610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9244, 42.4102],
            [-89.9066, 42.5058],
            [-89.7685, 42.5054],
            [-89.7424, 42.5054],
            [-89.7136, 42.3785],
            [-89.7489, 42.3141],
            [-89.9228, 42.3349],
            [-89.9244, 42.4102],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722530',
        NAME: 'Lena-Winslow Community Unit School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307250281,
        AWATER: 214731,
        INTPTLAT: '+42.4078991',
        INTPTLON: '-089.8387467',
        ELSDLEA: '',
        UNSDLEA: '22530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1871, 42.508],
            [-89.9807, 42.5064],
            [-89.9066, 42.5058],
            [-89.9244, 42.4102],
            [-90.1628, 42.4222],
            [-90.1871, 42.508],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740740',
        NAME: 'Warren Community Unit School District 205',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219418678,
        AWATER: 568405,
        INTPTLAT: '+42.4628621',
        INTPTLON: '-090.0413609',
        ELSDLEA: '',
        UNSDLEA: '40740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8945, 41.1451],
            [-87.8287, 41.1558],
            [-87.7789, 41.1558],
            [-87.7393, 41.12],
            [-87.7332, 41.0641],
            [-87.8438, 41.0482],
            [-87.8984, 41.0765],
            [-87.8945, 41.1451],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720760',
        NAME: 'Kankakee School District 111',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118664888,
        AWATER: 3245378,
        INTPTLAT: '+41.0966327',
        INTPTLON: '-087.8151843',
        ELSDLEA: '',
        UNSDLEA: '20760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3346, 41.335],
            [-89.2774, 41.3167],
            [-89.3353, 41.3007],
            [-89.3346, 41.335],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712090',
        NAME: 'Depue Community Unit School District 103',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16097837,
        AWATER: 2932626,
        INTPTLAT: '+41.3300727',
        INTPTLON: '-089.3042955',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6591, 42.1998],
            [-89.6102, 42.2411],
            [-89.4558, 42.2661],
            [-89.4041, 42.2597],
            [-89.3372, 42.2024],
            [-89.3739, 42.1011],
            [-89.52, 42.0697],
            [-89.6855, 42.0538],
            [-89.6591, 42.1998],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715490',
        NAME: 'Forrestville Valley Community Unit School District 221',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461591737,
        AWATER: 86207,
        INTPTLAT: '+42.1645930',
        INTPTLON: '-089.5264966',
        ELSDLEA: '',
        UNSDLEA: '15490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3739, 42.1011],
            [-89.2772, 42.0734],
            [-89.1963, 42.0049],
            [-89.1852, 41.9542],
            [-89.2246, 41.9394],
            [-89.3599, 41.9239],
            [-89.4453, 41.9309],
            [-89.52, 42.0697],
            [-89.3739, 42.1011],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730160',
        NAME: 'Oregon Community Unit School District 220',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385869670,
        AWATER: 4447625,
        INTPTLAT: '+42.0112667',
        INTPTLON: '-089.3613416',
        ELSDLEA: '',
        UNSDLEA: '30160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9092, 42.0734],
            [-89.8595, 42.0221],
            [-89.6851, 42.0248],
            [-89.6854, 41.9303],
            [-89.8621, 41.8882],
            [-89.9784, 41.9312],
            [-89.9773, 42.0359],
            [-89.9092, 42.0734],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709400',
        NAME: 'Chadwick-Milledgeville Community Unit School District 399',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349465913,
        AWATER: 1163,
        INTPTLAT: '+41.9666215',
        INTPTLON: '-089.8291913',
        ELSDLEA: '',
        UNSDLEA: '09400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7365, 42.4928],
            [-88.6708, 42.4945],
            [-88.5598, 42.495],
            [-88.4913, 42.4144],
            [-88.4818, 42.4143],
            [-88.5794, 42.3276],
            [-88.7056, 42.3279],
            [-88.7365, 42.4928],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718420',
        NAME: 'Harvard Community Unit School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274760386,
        AWATER: 240468,
        INTPTLAT: '+42.4156107',
        INTPTLON: '-088.6232761',
        ELSDLEA: '',
        UNSDLEA: '18420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7384, 39.2198],
            [-89.6998, 39.1321],
            [-89.7924, 39.0713],
            [-89.8671, 39.0565],
            [-89.9233, 39.1291],
            [-89.9421, 39.1588],
            [-89.8131, 39.2198],
            [-89.7384, 39.2198],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716680',
        NAME: 'Gillespie Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237296463,
        AWATER: 1951126,
        INTPTLAT: '+39.1460840',
        INTPTLON: '-089.8266465',
        ELSDLEA: '',
        UNSDLEA: '16680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.367, 41.4995],
            [-90.3179, 41.5088],
            [-90.1965, 41.5401],
            [-90.1856, 41.5847],
            [-89.9978, 41.5837],
            [-89.9741, 41.5395],
            [-89.9724, 41.3736],
            [-90.011, 41.3228],
            [-90.0495, 41.3234],
            [-90.0825, 41.353],
            [-90.261, 41.346],
            [-90.3523, 41.4641],
            [-90.367, 41.4995],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716350',
        NAME: 'Geneseo Community Unit School District 228',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663922772,
        AWATER: 4345992,
        INTPTLAT: '+41.4533154',
        INTPTLON: '-090.1260406',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9876, 41.3151],
            [-89.7895, 41.3503],
            [-89.7119, 41.3074],
            [-89.7414, 41.2339],
            [-89.7619, 41.234],
            [-89.8108, 41.2342],
            [-89.9927, 41.2347],
            [-89.9876, 41.3151],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1721000',
        NAME: 'Kewanee Community Unit School District 229',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230457052,
        AWATER: 168823,
        INTPTLAT: '+41.2801396',
        INTPTLON: '-089.8607681',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4114, 41.1513],
            [-90.4044, 41.1513],
            [-90.399, 41.1513],
            [-90.3897, 41.1513],
            [-90.2432, 41.1519],
            [-90.1993, 41.1667],
            [-90.0963, 41.15],
            [-90.0332, 41.062],
            [-90.0138, 41.062],
            [-90.1556, 40.9755],
            [-90.2593, 40.976],
            [-90.3241, 41.0639],
            [-90.4394, 41.064],
            [-90.4114, 41.1513],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729940',
        NAME: 'ROWVA Community Unit School District 208',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 472677632,
        AWATER: 2532739,
        INTPTLAT: '+41.0670833',
        INTPTLON: '-090.2153231',
        ELSDLEA: '',
        UNSDLEA: '29940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0547, 42.3817],
            [-89.0394, 42.3912],
            [-88.9825, 42.403],
            [-89.0547, 42.3817],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718240',
        NAME: 'Harlem Unit School District 122',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54740336,
        AWATER: 1823132,
        INTPTLAT: '+42.3528344',
        INTPTLON: '-089.0256145',
        ELSDLEA: '',
        UNSDLEA: '18240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4551, 42.5012],
            [-89.444, 42.501],
            [-89.4192, 42.5008],
            [-89.41, 42.5007],
            [-89.2627, 42.4982],
            [-89.1976, 42.4358],
            [-89.2329, 42.3861],
            [-89.2598, 42.3763],
            [-89.3981, 42.378],
            [-89.4442, 42.4896],
            [-89.4551, 42.5012],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712810',
        NAME: 'Durand Community Unit School District 322',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192276485,
        AWATER: 791454,
        INTPTLAT: '+42.4388766',
        INTPTLON: '-089.3159786',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2772, 42.0734],
            [-89.1725, 42.1799],
            [-88.9502, 42.1522],
            [-88.9616, 42.0649],
            [-89.0664, 42.0578],
            [-89.1963, 42.0049],
            [-89.2772, 42.0734],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725690',
        NAME: 'Meridian Community Unit School District 223',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322994073,
        AWATER: 1160171,
        INTPTLAT: '+42.1004867',
        INTPTLON: '-089.1205503',
        ELSDLEA: '',
        UNSDLEA: '25690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9675, 41.4475],
            [-88.9675, 41.4546],
            [-88.9359, 41.5102],
            [-88.8555, 41.5355],
            [-88.7195, 41.5544],
            [-88.6735, 41.5712],
            [-88.624, 41.5531],
            [-88.5972, 41.4932],
            [-88.5965, 41.4751],
            [-88.615, 41.4402],
            [-88.666, 41.4182],
            [-88.7077, 41.4337],
            [-88.7759, 41.4139],
            [-88.8453, 41.4558],
            [-88.9339, 41.4399],
            [-88.9675, 41.4475],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715880',
        NAME: 'Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314123562,
        AWATER: 2468410,
        INTPTLAT: '+41.5009690',
        INTPTLON: '-088.7591510',
        ELSDLEA: '',
        UNSDLEA: '15880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1626, 41.1923],
            [-88.9514, 41.1709],
            [-88.9595, 41.1127],
            [-89.1241, 41.1115],
            [-89.1626, 41.1923],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700120',
        NAME: 'Lostant Community Unit School District 425',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135306412,
        AWATER: 0,
        INTPTLAT: '+41.1470070',
        INTPTLON: '-089.0559705',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0165, 41.9305],
            [-89.9784, 41.9312],
            [-89.8621, 41.8882],
            [-89.8454, 41.7579],
            [-89.9778, 41.7577],
            [-90.0928, 41.7574],
            [-90.0651, 41.8743],
            [-90.0165, 41.9305],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726710',
        NAME: 'Morrison Community Unit School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306187262,
        AWATER: 346784,
        INTPTLAT: '+41.8338643',
        INTPTLON: '-089.9633198',
        ELSDLEA: '',
        UNSDLEA: '26710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2424, 41.783],
            [-90.188, 41.8032],
            [-90.152, 41.9289],
            [-90.0165, 41.9305],
            [-90.0651, 41.8743],
            [-90.0928, 41.7574],
            [-90.2424, 41.783],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1733950',
        NAME: 'River Bend Community Unit District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186924378,
        AWATER: 14383764,
        INTPTLAT: '+41.8471808',
        INTPTLON: '-090.1219466',
        ELSDLEA: '',
        UNSDLEA: '33950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6508, 40.3559],
            [-89.5848, 40.4508],
            [-89.4921, 40.4491],
            [-89.4542, 40.3295],
            [-89.5458, 40.2992],
            [-89.6508, 40.3559],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712060',
        NAME: 'Delavan Community Unit School District 703',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167706645,
        AWATER: 52147,
        INTPTLAT: '+40.3741278',
        INTPTLON: '-089.5371174',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4273, 40.5699],
            [-89.3864, 40.6605],
            [-89.3296, 40.6608],
            [-89.2688, 40.5793],
            [-89.2665, 40.4856],
            [-89.4189, 40.4831],
            [-89.4273, 40.5699],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711880',
        NAME: 'Deer Creek-Mackinaw Community Unit School District 701',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193883090,
        AWATER: 728598,
        INTPTLAT: '+40.5629137',
        INTPTLON: '-089.3469995',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5682, 40.5095],
            [-89.542, 40.568],
            [-89.4273, 40.5699],
            [-89.4189, 40.4831],
            [-89.4921, 40.4491],
            [-89.5848, 40.4508],
            [-89.6064, 40.4805],
            [-89.5682, 40.5095],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739390',
        NAME: 'Tremont Community Unit School District 702',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180166259,
        AWATER: 84851,
        INTPTLAT: '+40.5046998',
        INTPTLON: '-089.5015733',
        ELSDLEA: '',
        UNSDLEA: '39390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6715, 37.901],
            [-89.5987, 37.9553],
            [-89.6303, 38.0585],
            [-89.593, 38.0731],
            [-89.4826, 38.0415],
            [-89.4277, 37.9532],
            [-89.3907, 37.8389],
            [-89.4894, 37.8082],
            [-89.5051, 37.6926],
            [-89.5166, 37.6927],
            [-89.5842, 37.714],
            [-89.6743, 37.8031],
            [-89.6715, 37.901],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708250',
        NAME: 'Trico Community Unit School District 176',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629338034,
        AWATER: 12086588,
        INTPTLAT: '+37.8776228',
        INTPTLON: '-089.5530302',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6572, 38.2194],
            [-89.6338, 38.2194],
            [-89.5928, 38.2193],
            [-89.5924, 38.1394],
            [-89.6572, 38.2194],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710980',
        NAME: 'Coulterville Unit School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61537181,
        AWATER: 891733,
        INTPTLAT: '+38.1745840',
        INTPTLON: '-089.6196627',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5488, 40.9622],
            [-90.4394, 41.064],
            [-90.3241, 41.0639],
            [-90.2593, 40.976],
            [-90.3262, 40.9592],
            [-90.3279, 40.8489],
            [-90.4421, 40.8673],
            [-90.5488, 40.9622],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1716080',
        NAME: 'Galesburg Community Unit School District 205',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325794976,
        AWATER: 1070888,
        INTPTLAT: '+40.9695656',
        INTPTLON: '-090.4121861',
        ELSDLEA: '',
        UNSDLEA: '16080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5549, 40.6119],
            [-89.5127, 40.6686],
            [-89.5127, 40.6746],
            [-89.5023, 40.6746],
            [-89.4457, 40.6601],
            [-89.3864, 40.6605],
            [-89.4273, 40.5699],
            [-89.542, 40.568],
            [-89.5549, 40.6119],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726800',
        NAME: 'Morton Community Unit School District 709',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128682138,
        AWATER: 217405,
        INTPTLAT: '+40.6126510',
        INTPTLON: '-089.4607812',
        ELSDLEA: '',
        UNSDLEA: '26800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4593, 40.5659],
            [-88.4427, 40.6174],
            [-88.2466, 40.6471],
            [-88.1945, 40.5747],
            [-88.2337, 40.3995],
            [-88.3661, 40.3987],
            [-88.4604, 40.3402],
            [-88.479, 40.3987],
            [-88.5069, 40.5076],
            [-88.4593, 40.5659],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700041',
        NAME: 'Gibson City-Melvin-Sibley Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 576087776,
        AWATER: 1163976,
        INTPTLAT: '+40.5083731',
        INTPTLON: '-088.3383153',
        ELSDLEA: '',
        UNSDLEA: '00041',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1976, 40.6755],
            [-88.1256, 40.7634],
            [-88.1171, 40.7715],
            [-88.1006, 40.8519],
            [-87.873, 40.8433],
            [-87.8976, 40.6817],
            [-87.946, 40.6512],
            [-88.0554, 40.6559],
            [-88.0643, 40.6626],
            [-88.1197, 40.6189],
            [-88.1976, 40.6755],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720180',
        NAME: 'Iroquois West Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474791421,
        AWATER: 327276,
        INTPTLAT: '+40.7486441',
        INTPTLON: '-088.0153954',
        ELSDLEA: '',
        UNSDLEA: '20180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8148, 40.7988],
            [-88.7672, 40.8229],
            [-88.6998, 40.8239],
            [-88.6225, 40.7862],
            [-88.527, 40.8239],
            [-88.5258, 40.8311],
            [-88.5246, 40.8348],
            [-88.4891, 40.8319],
            [-88.3548, 40.8232],
            [-88.2412, 40.8203],
            [-88.2082, 40.6754],
            [-88.2466, 40.6471],
            [-88.4427, 40.6174],
            [-88.4593, 40.5659],
            [-88.6512, 40.6693],
            [-88.8139, 40.7113],
            [-88.8148, 40.7988],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700005',
        NAME: 'Prairie Central Community Unit School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 954747039,
        AWATER: 1938502,
        INTPTLAT: '+40.7226710',
        INTPTLON: '-088.4967261',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.43, 39.3488],
            [-89.3066, 39.3999],
            [-89.2788, 39.3997],
            [-89.1867, 39.3489],
            [-89.1397, 39.2328],
            [-89.2509, 39.1991],
            [-89.2507, 39.0864],
            [-89.3066, 39.0863],
            [-89.307, 39.2613],
            [-89.431, 39.2641],
            [-89.43, 39.3488],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728560',
        NAME: 'Nokomis Community Unit School District 22',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420545907,
        AWATER: 50733,
        INTPTLAT: '+39.2650696',
        INTPTLON: '-089.2925934',
        ELSDLEA: '',
        UNSDLEA: '28560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4778, 39.5682],
            [-89.3857, 39.5819],
            [-89.2888, 39.6116],
            [-89.2891, 39.6991],
            [-89.4, 39.7351],
            [-89.3971, 39.7422],
            [-89.2984, 39.7866],
            [-89.2462, 39.8259],
            [-89.1604, 39.772],
            [-89.2154, 39.699],
            [-89.1012, 39.6404],
            [-89.1661, 39.4801],
            [-89.2788, 39.3997],
            [-89.3066, 39.3999],
            [-89.3938, 39.5242],
            [-89.4774, 39.5354],
            [-89.4778, 39.5682],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738700',
        NAME: 'Taylorville Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 703691523,
        AWATER: 6109594,
        INTPTLAT: '+39.6010841',
        INTPTLON: '-089.2628555',
        ELSDLEA: '',
        UNSDLEA: '38700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4789, 39.6044],
            [-89.3857, 39.5819],
            [-89.4778, 39.5682],
            [-89.4789, 39.6044],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736640',
        NAME: 'South Fork School District 14',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27168847,
        AWATER: 1515300,
        INTPTLAT: '+39.5842427',
        INTPTLON: '-089.4337127',
        ELSDLEA: '',
        UNSDLEA: '36640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7369, 39.3628],
            [-89.6457, 39.5167],
            [-89.6175, 39.5209],
            [-89.5421, 39.4848],
            [-89.5457, 39.3851],
            [-89.43, 39.3488],
            [-89.431, 39.2641],
            [-89.5848, 39.2589],
            [-89.7028, 39.2953],
            [-89.7369, 39.3628],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730660',
        NAME: 'Panhandle Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 447016265,
        AWATER: 566813,
        INTPTLAT: '+39.3675245',
        INTPTLON: '-089.5882505',
        ELSDLEA: '',
        UNSDLEA: '30660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.191, 40.2838],
            [-91.0259, 40.2828],
            [-91.0253, 40.3703],
            [-90.908, 40.3774],
            [-90.7926, 40.3029],
            [-90.7722, 40.2811],
            [-90.8384, 40.105],
            [-90.9136, 40.1045],
            [-90.9694, 40.179],
            [-91.1458, 40.1662],
            [-91.2586, 40.2118],
            [-91.191, 40.2838],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736610',
        NAME: 'Southeastern Community Unit School District 337',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701817434,
        AWATER: 717748,
        INTPTLAT: '+40.2419179',
        INTPTLON: '-090.9782855',
        ELSDLEA: '',
        UNSDLEA: '36610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3697, 40.5888],
            [-91.2479, 40.6384],
            [-91.2487, 40.5801],
            [-91.0897, 40.5774],
            [-91.0902, 40.548],
            [-91.2505, 40.4914],
            [-91.3637, 40.4931],
            [-91.3697, 40.5888],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727780',
        NAME: 'Nauvoo-Colusa Community Unit School District 325',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238393308,
        AWATER: 20910075,
        INTPTLAT: '+40.5580307',
        INTPTLON: '-091.2589823',
        ELSDLEA: '',
        UNSDLEA: '27780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.829, 40.1257],
            [-89.6388, 40.1551],
            [-89.6016, 40.1224],
            [-89.5873, 40.0488],
            [-89.7691, 40.0041],
            [-89.8311, 40.0407],
            [-89.829, 40.1257],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717700',
        NAME: 'Greenview Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228762488,
        AWATER: 738021,
        INTPTLAT: '+40.0761707',
        INTPTLON: '-089.7092888',
        ELSDLEA: '',
        UNSDLEA: '17700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5421, 39.4848],
            [-89.4774, 39.5354],
            [-89.3938, 39.5242],
            [-89.3066, 39.3999],
            [-89.43, 39.3488],
            [-89.5457, 39.3851],
            [-89.5421, 39.4848],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726760',
        NAME: 'Morrisonville Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301951138,
        AWATER: 39876,
        INTPTLAT: '+39.4314546',
        INTPTLON: '-089.4459230',
        ELSDLEA: '',
        UNSDLEA: '26760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1604, 39.772],
            [-89.0288, 39.8242],
            [-88.9433, 39.8056],
            [-88.9386, 39.8035],
            [-88.939, 39.7989],
            [-88.8691, 39.704],
            [-88.8681, 39.6529],
            [-89.1012, 39.6404],
            [-89.2154, 39.699],
            [-89.1604, 39.772],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700123',
        NAME: 'Meridian Community Unit School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354974631,
        AWATER: 160779,
        INTPTLAT: '+39.7208988',
        INTPTLON: '-089.0367677',
        ELSDLEA: '',
        UNSDLEA: '00123',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4194, 40.3783],
            [-91.3675, 40.4762],
            [-91.3637, 40.4931],
            [-91.2505, 40.4914],
            [-91.2165, 40.4318],
            [-91.2269, 40.3727],
            [-91.2749, 40.3268],
            [-91.374, 40.3286],
            [-91.422, 40.3759],
            [-91.4194, 40.3783],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718060',
        NAME: 'Hamilton Community Consolidated School District 328',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199161996,
        AWATER: 13807465,
        INTPTLAT: '+40.4090929',
        INTPTLON: '-091.2968807',
        ELSDLEA: '',
        UNSDLEA: '18060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4973, 40.2488],
            [-91.422, 40.3759],
            [-91.374, 40.3286],
            [-91.2749, 40.3268],
            [-91.2269, 40.3727],
            [-91.191, 40.2838],
            [-91.2586, 40.2118],
            [-91.5053, 40.2005],
            [-91.4973, 40.2488],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740890',
        NAME: 'Warsaw Community Unit School District 316',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360884772,
        AWATER: 13574360,
        INTPTLAT: '+40.2827544',
        INTPTLON: '-091.3533164',
        ELSDLEA: '',
        UNSDLEA: '40890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5053, 40.2005],
            [-91.2586, 40.2118],
            [-91.1458, 40.1662],
            [-91.1473, 40.093],
            [-91.223, 40.0493],
            [-91.2284, 39.9775],
            [-91.2634, 39.978],
            [-91.3194, 40.0227],
            [-91.4881, 40.0246],
            [-91.4973, 40.0771],
            [-91.5053, 40.2005],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725590',
        NAME: 'Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569381157,
        AWATER: 17591714,
        INTPTLAT: '+40.1138124',
        INTPTLON: '-091.3281543',
        ELSDLEA: '',
        UNSDLEA: '25590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6567, 39.6113],
            [-89.4973, 39.6409],
            [-89.4789, 39.6044],
            [-89.4778, 39.5682],
            [-89.4774, 39.5354],
            [-89.5421, 39.4848],
            [-89.6175, 39.5209],
            [-89.6567, 39.6113],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730930',
        NAME: 'Pawnee Community Unit School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172652852,
        AWATER: 2938875,
        INTPTLAT: '+39.5762904',
        INTPTLON: '-089.5453883',
        ELSDLEA: '',
        UNSDLEA: '30930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5742, 39.8023],
            [-89.5407, 39.8142],
            [-89.3971, 39.7422],
            [-89.4, 39.7351],
            [-89.4788, 39.6697],
            [-89.5807, 39.7182],
            [-89.5742, 39.8023],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734320',
        NAME: 'Rochester Community Unit School District 3A',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168040812,
        AWATER: 1813681,
        INTPTLAT: '+39.7397951',
        INTPTLON: '-089.4969292',
        ELSDLEA: '',
        UNSDLEA: '34320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3486, 39.9175],
            [-89.1993, 39.9171],
            [-89.0289, 39.8754],
            [-89.0288, 39.8244],
            [-89.0288, 39.8242],
            [-89.1604, 39.772],
            [-89.2462, 39.8259],
            [-89.2984, 39.7866],
            [-89.3486, 39.9175],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700324',
        NAME: 'Sangamon Valley Community Unit School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 289987302,
        AWATER: 597923,
        INTPTLAT: '+39.8474430',
        INTPTLON: '-089.1968272',
        ELSDLEA: '',
        UNSDLEA: '00324',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9227, 40.1855],
            [-87.7361, 40.2037],
            [-87.7356, 40.2036],
            [-87.6877, 40.1967],
            [-87.6682, 40.1181],
            [-87.8066, 40.0831],
            [-87.8634, 40.0802],
            [-87.9258, 40.0838],
            [-87.9227, 40.1855],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710800',
        NAME: 'Oakwood Community Unit School District 76',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226954634,
        AWATER: 1785849,
        INTPTLAT: '+40.1364993',
        INTPTLON: '-087.8235051',
        ELSDLEA: '',
        UNSDLEA: '10800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7174, 37.6364],
            [-88.7072, 37.7314],
            [-88.597, 37.7312],
            [-88.5989, 37.5993],
            [-88.7085, 37.5993],
            [-88.7179, 37.6145],
            [-88.7174, 37.6364],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708580',
        NAME: 'Carrier Mills-Stonefort Community Unit School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140330624,
        AWATER: 5636990,
        INTPTLAT: '+37.6544771',
        INTPTLON: '-088.6570363',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1508, 37.9502],
            [-89.002, 37.9501],
            [-88.9841, 37.9501],
            [-89.0071, 37.8676],
            [-89.1512, 37.862],
            [-89.1508, 37.9502],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743800',
        NAME: 'Zeigler-Royalton Community Unit School District 188',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136815419,
        AWATER: 4428403,
        INTPTLAT: '+37.9016956',
        INTPTLON: '-089.0693601',
        ELSDLEA: '',
        UNSDLEA: '43800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9834, 37.8621],
            [-88.817, 37.863],
            [-88.7303, 37.8634],
            [-88.7071, 37.777],
            [-88.8176, 37.7758],
            [-88.9707, 37.774],
            [-88.9834, 37.8621],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720550',
        NAME: 'Johnston City Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211567752,
        AWATER: 2564812,
        INTPTLAT: '+37.8125810',
        INTPTLON: '-088.8413060',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6685, 39.9672],
            [-88.4342, 39.9673],
            [-88.4251, 39.8863],
            [-88.4763, 39.8827],
            [-88.6507, 39.8503],
            [-88.6506, 39.8539],
            [-88.6685, 39.9672],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705820',
        NAME: 'Bement Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238834518,
        AWATER: 12023,
        INTPTLAT: '+39.9142424',
        INTPTLON: '-088.5476519',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7868, 39.924],
            [-88.7453, 39.9461],
            [-88.6685, 39.9672],
            [-88.6506, 39.8539],
            [-88.6507, 39.8503],
            [-88.6437, 39.7922],
            [-88.7582, 39.7692],
            [-88.8409, 39.842],
            [-88.7868, 39.924],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709330',
        NAME: 'Cerro Gordo Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261695784,
        AWATER: 167435,
        INTPTLAT: '+39.8748986',
        INTPTLON: '-088.7369731',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.131, 40.9539],
            [-88.0167, 40.978],
            [-87.96, 41.0747],
            [-87.8984, 41.0765],
            [-87.8438, 41.0482],
            [-87.8117, 40.9398],
            [-87.8472, 40.8728],
            [-87.8269, 40.8586],
            [-87.873, 40.8433],
            [-88.1006, 40.8519],
            [-88.0911, 40.8667],
            [-88.131, 40.9539],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710410',
        NAME: 'Central Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461441772,
        AWATER: 1383220,
        INTPTLAT: '+40.9501862',
        INTPTLON: '-087.9530351',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.511, 38.9832],
            [-88.5058, 39.031],
            [-88.418, 39.0308],
            [-88.3966, 39.2149],
            [-88.3029, 39.171],
            [-88.3606, 39.1232],
            [-88.3664, 38.911],
            [-88.5442, 38.9146],
            [-88.511, 38.9832],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712240',
        NAME: 'Dieterich Community Unit School District 30',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275138381,
        AWATER: 116205,
        INTPTLAT: '+39.0209148',
        INTPTLON: '-088.4281200',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9841, 37.9501],
            [-88.8478, 37.9507],
            [-88.817, 37.863],
            [-88.9834, 37.8621],
            [-89.0071, 37.8676],
            [-88.9841, 37.9501],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741580',
        NAME: 'Frankfort Community Unit School District 168',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147290937,
        AWATER: 1990338,
        INTPTLAT: '+37.9015787',
        INTPTLON: '-088.9114976',
        ELSDLEA: '',
        UNSDLEA: '41580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9039, 39.4819],
            [-87.8543, 39.4834],
            [-87.7906, 39.4038],
            [-87.7259, 39.2466],
            [-87.7829, 39.1786],
            [-87.8847, 39.1761],
            [-87.8952, 39.176],
            [-87.9039, 39.4819],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724900',
        NAME: 'Martinsville Community Unit School District 3C',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368206393,
        AWATER: 3067321,
        INTPTLAT: '+39.3118384',
        INTPTLON: '-087.8332568',
        ELSDLEA: '',
        UNSDLEA: '24900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5991, 40.5211],
            [-90.5991, 40.5139],
            [-90.4478, 40.4521],
            [-90.4967, 40.3654],
            [-90.6286, 40.3817],
            [-90.7049, 40.3527],
            [-90.7905, 40.5154],
            [-90.5991, 40.5211],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723920',
        NAME: 'Macomb Community Unit School District 185',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390597430,
        AWATER: 1305583,
        INTPTLAT: '+40.4406597',
        INTPTLON: '-090.6093803',
        ELSDLEA: '',
        UNSDLEA: '23920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0309, 40.439],
            [-89.0109, 40.4952],
            [-88.94, 40.5027],
            [-88.9277, 40.4591],
            [-88.9805, 40.464],
            [-89.0309, 40.439],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706480',
        NAME: 'Bloomington School District 87',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30258394,
        AWATER: 0,
        INTPTLAT: '+40.4774511',
        INTPTLON: '-088.9732707',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4921, 40.4491],
            [-89.4189, 40.4831],
            [-89.2665, 40.4856],
            [-89.2688, 40.5793],
            [-89.1647, 40.5962],
            [-89.0991, 40.5615],
            [-89.0622, 40.4295],
            [-89.1426, 40.3994],
            [-89.0523, 40.2612],
            [-89.1373, 40.195],
            [-89.3461, 40.2521],
            [-89.4542, 40.3295],
            [-89.4921, 40.4491],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729890',
        NAME: 'Olympia Community Unit School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 978288455,
        AWATER: 438034,
        INTPTLAT: '+40.3772685',
        INTPTLON: '-089.2540107',
        ELSDLEA: '',
        UNSDLEA: '29890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.056, 40.0496],
            [-88.9072, 40.0974],
            [-88.8413, 40.0613],
            [-88.9185, 40.0017],
            [-88.9361, 39.9063],
            [-88.9739, 39.9037],
            [-89.056, 40.0496],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724750',
        NAME: 'Maroa-Forsyth Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214158183,
        AWATER: 0,
        INTPTLAT: '+40.0132311',
        INTPTLON: '-088.9629116',
        ELSDLEA: '',
        UNSDLEA: '24750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8041, 40.2606],
            [-88.5889, 40.3474],
            [-88.603, 40.3983],
            [-88.479, 40.3987],
            [-88.4604, 40.3402],
            [-88.4605, 40.2674],
            [-88.4632, 40.1415],
            [-88.574, 40.1415],
            [-88.7777, 40.1861],
            [-88.8041, 40.2606],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700003',
        NAME: 'Blue Ridge Community Unit School District 18',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550612146,
        AWATER: 3066779,
        INTPTLAT: '+40.2612501',
        INTPTLON: '-088.5920946',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0622, 40.4295],
            [-88.9408, 40.4002],
            [-88.8728, 40.3304],
            [-88.8781, 40.2826],
            [-89.0523, 40.2612],
            [-89.1426, 40.3994],
            [-89.0622, 40.4295],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718870',
        NAME: 'Heyworth Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258102703,
        AWATER: 354769,
        INTPTLAT: '+40.3459718',
        INTPTLON: '-089.0106052',
        ELSDLEA: '',
        UNSDLEA: '18870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0783, 37.6861],
            [-88.9841, 37.774],
            [-88.9707, 37.774],
            [-88.8176, 37.7758],
            [-88.856, 37.6783],
            [-88.8188, 37.6266],
            [-88.7174, 37.6364],
            [-88.7179, 37.6145],
            [-88.875, 37.5857],
            [-89.051, 37.5968],
            [-89.0782, 37.5974],
            [-89.0783, 37.6861],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724600',
        NAME: 'Marion Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400539923,
        AWATER: 19334624,
        INTPTLAT: '+37.6757780',
        INTPTLON: '-088.9323272',
        ELSDLEA: '',
        UNSDLEA: '24600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0288, 39.8242],
            [-89.0288, 39.8244],
            [-88.9739, 39.9037],
            [-88.9361, 39.9063],
            [-88.7868, 39.924],
            [-88.8409, 39.842],
            [-88.939, 39.7989],
            [-88.9386, 39.8035],
            [-88.9433, 39.8056],
            [-89.0288, 39.8242],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711850',
        NAME: 'Decatur School District 61',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109951396,
        AWATER: 12249702,
        INTPTLAT: '+39.8485930',
        INTPTLON: '-088.9307640',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7363, 40.7992],
            [-90.5187, 40.8168],
            [-90.5188, 40.8092],
            [-90.5598, 40.6447],
            [-90.5971, 40.6309],
            [-90.7799, 40.6354],
            [-90.7884, 40.7629],
            [-90.7363, 40.7992],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700320',
        NAME: 'Monmouth-Roseville Community Unit School District 238',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403068601,
        AWATER: 79475,
        INTPTLAT: '+40.7272648',
        INTPTLON: '-090.6570455',
        ELSDLEA: '',
        UNSDLEA: '00320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9778, 41.7577],
            [-89.8454, 41.7579],
            [-89.8144, 41.7181],
            [-89.7092, 41.657],
            [-89.6321, 41.6568],
            [-89.6794, 41.5848],
            [-89.787, 41.5256],
            [-89.8602, 41.5407],
            [-89.9741, 41.5395],
            [-89.9978, 41.5837],
            [-89.9778, 41.7577],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1732830',
        NAME: 'Prophetstown-Lyndon-Tampico Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493071665,
        AWATER: 3471829,
        INTPTLAT: '+41.6385912',
        INTPTLON: '-089.8528849',
        ELSDLEA: '',
        UNSDLEA: '32830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9927, 41.2347],
            [-89.8108, 41.2342],
            [-89.9452, 41.1493],
            [-89.9927, 41.2347],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742060',
        NAME: 'Wethersfield Community Unit School District 230',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102844529,
        AWATER: 0,
        INTPTLAT: '+41.1997118',
        INTPTLON: '-089.9124986',
        ELSDLEA: '',
        UNSDLEA: '42060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3013, 41.7975],
            [-88.2626, 41.7998],
            [-88.262, 41.732],
            [-88.32, 41.7234],
            [-88.3013, 41.7975],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704680',
        NAME: 'Aurora East Unit School District 131',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36743597,
        AWATER: 907166,
        INTPTLAT: '+41.7590320',
        INTPTLON: '-088.2905286',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3761, 41.8293],
            [-88.3013, 41.7975],
            [-88.32, 41.7234],
            [-88.3753, 41.7226],
            [-88.3761, 41.8293],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704710',
        NAME: 'Aurora West Unit School District 129',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76419757,
        AWATER: 1290425,
        INTPTLAT: '+41.7800954',
        INTPTLON: '-088.3572518',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4718, 42.2414],
            [-88.4129, 42.2405],
            [-88.3543, 42.1849],
            [-88.3547, 42.1323],
            [-88.5104, 42.1539],
            [-88.521, 42.1832],
            [-88.4718, 42.2414],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719830',
        NAME: 'Huntley Consolidated School District 158',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117797180,
        AWATER: 151446,
        INTPTLAT: '+42.1817076',
        INTPTLON: '-088.4360080',
        ELSDLEA: '',
        UNSDLEA: '19830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0139, 41.2924],
            [-87.7425, 41.2962],
            [-87.7414, 41.2075],
            [-87.7456, 41.2075],
            [-87.838, 41.2068],
            [-87.9912, 41.2053],
            [-88.0118, 41.2056],
            [-88.0403, 41.2226],
            [-88.0139, 41.2924],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724390',
        NAME: 'Manteno Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233317901,
        AWATER: 903944,
        INTPTLAT: '+41.2415730',
        INTPTLON: '-087.8860186',
        ELSDLEA: '',
        UNSDLEA: '24390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9064, 41.4696],
            [-87.7903, 41.4698],
            [-87.7873, 41.4698],
            [-87.7862, 41.3831],
            [-87.7087, 41.3835],
            [-87.7079, 41.2965],
            [-87.7425, 41.2962],
            [-88.0139, 41.2924],
            [-88.0183, 41.3804],
            [-87.9028, 41.4106],
            [-87.9064, 41.4696],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731290',
        NAME: 'Peotone Community Unit School District 207U',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377376582,
        AWATER: 189734,
        INTPTLAT: '+41.3652811',
        INTPTLON: '-087.8661824',
        ELSDLEA: '',
        UNSDLEA: '31290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7425, 41.2962],
            [-87.7079, 41.2965],
            [-87.5267, 41.2981],
            [-87.5265, 41.2091],
            [-87.7414, 41.2075],
            [-87.7425, 41.2962],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717370',
        NAME: 'Grant Park Community Unit School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178505961,
        AWATER: 65845,
        INTPTLAT: '+41.2562417',
        INTPTLON: '-087.6356301',
        ELSDLEA: '',
        UNSDLEA: '17370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.699, 38.4749],
            [-88.699, 38.4837],
            [-88.6987, 38.5622],
            [-88.6409, 38.5631],
            [-88.6041, 38.4836],
            [-88.4722, 38.4287],
            [-88.4731, 38.4071],
            [-88.4626, 38.2965],
            [-88.4259, 38.2558],
            [-88.5471, 38.2268],
            [-88.7024, 38.2567],
            [-88.702, 38.2788],
            [-88.6998, 38.3865],
            [-88.699, 38.4749],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741360',
        NAME: 'Wayne City Community Unit School District 100',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 582894087,
        AWATER: 440074,
        INTPTLAT: '+38.3696522',
        INTPTLON: '-088.5861066',
        ELSDLEA: '',
        UNSDLEA: '41360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1316, 38.1169],
            [-89.1296, 38.1247],
            [-88.9954, 38.1251],
            [-88.9839, 38.0376],
            [-88.9962, 38.0014],
            [-89.1389, 38.0376],
            [-89.1316, 38.1169],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735940',
        NAME: 'Sesser-Valier Community Unit School District 196',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145337983,
        AWATER: 2412577,
        INTPTLAT: '+38.0667215',
        INTPTLON: '-089.0599640',
        ELSDLEA: '',
        UNSDLEA: '35940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5887, 42.1536],
            [-88.5104, 42.1539],
            [-88.3547, 42.1323],
            [-88.3543, 42.1849],
            [-88.2776, 42.205],
            [-88.2384, 42.1945],
            [-88.2382, 42.1867],
            [-88.2084, 42.0673],
            [-88.3761, 42.0669],
            [-88.5883, 42.0665],
            [-88.5887, 42.1536],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708550',
        NAME: 'Community Unit School District 300',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302548973,
        AWATER: 3956604,
        INTPTLAT: '+42.1169725',
        INTPTLON: '-088.3891977',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0052, 41.6274],
            [-88.9262, 41.658],
            [-88.8823, 41.6298],
            [-88.8555, 41.5355],
            [-88.9359, 41.5102],
            [-89.0052, 41.6274],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712930',
        NAME: 'Earlville Community Unit School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156181157,
        AWATER: 7401,
        INTPTLAT: '+41.5866276',
        INTPTLON: '-088.9206038',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9408, 42.4027],
            [-88.9407, 42.4192],
            [-88.8613, 42.4107],
            [-88.8548, 42.3276],
            [-88.7056, 42.3279],
            [-88.7055, 42.241],
            [-88.7056, 42.1536],
            [-88.7931, 42.1498],
            [-88.9399, 42.1821],
            [-88.9408, 42.4027],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705790',
        NAME: 'Belvidere Consolidated Unit School District 100',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404668059,
        AWATER: 2706783,
        INTPTLAT: '+42.2637458',
        INTPTLON: '-088.8313535',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8633, 41.0325],
            [-90.6377, 41.1323],
            [-90.4377, 41.1513],
            [-90.4114, 41.1513],
            [-90.4394, 41.064],
            [-90.5488, 40.9622],
            [-90.4421, 40.8673],
            [-90.5187, 40.8168],
            [-90.7363, 40.7992],
            [-90.7883, 40.8135],
            [-90.7865, 40.953],
            [-90.8445, 40.9819],
            [-90.8633, 41.0325],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700321',
        NAME: 'United Community School District 304',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 983629694,
        AWATER: 591829,
        INTPTLAT: '+40.9814652',
        INTPTLON: '-090.6310675',
        ELSDLEA: '',
        UNSDLEA: '00321',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7519, 41.685],
            [-88.6887, 41.7188],
            [-88.6735, 41.5712],
            [-88.7195, 41.5544],
            [-88.7519, 41.685],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1736570',
        NAME: 'Somonauk Community Unit School District 432',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109089098,
        AWATER: 660039,
        INTPTLAT: '+41.6342448',
        INTPTLON: '-088.7061746',
        ELSDLEA: '',
        UNSDLEA: '36570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8544, 42.0161],
            [-88.7736, 42.0524],
            [-88.6029, 42.0665],
            [-88.566, 41.9676],
            [-88.6405, 41.9376],
            [-88.8544, 42.0161],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738460',
        NAME: 'Sycamore Community Unit School District 427',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212255185,
        AWATER: 1378151,
        INTPTLAT: '+42.0052530',
        INTPTLON: '-088.6980739',
        ELSDLEA: '',
        UNSDLEA: '38460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5598, 42.495],
            [-88.5502, 42.495],
            [-88.4469, 42.4947],
            [-88.4131, 42.4946],
            [-88.355, 42.4946],
            [-88.3547, 42.4147],
            [-88.4818, 42.4143],
            [-88.4913, 42.4144],
            [-88.5598, 42.495],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703300',
        NAME: 'Alden-Hebron School District 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130769048,
        AWATER: 54823,
        INTPTLAT: '+42.4527526',
        INTPTLON: '-088.4462025',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4442, 42.4896],
            [-89.3981, 42.378],
            [-89.4561, 42.3072],
            [-89.5926, 42.316],
            [-89.6178, 42.3886],
            [-89.5558, 42.4611],
            [-89.4442, 42.4896],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711610',
        NAME: 'Dakota Community Unit School District 201',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260710761,
        AWATER: 466025,
        INTPTLAT: '+42.3921094',
        INTPTLON: '-089.5090043',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7136, 42.3785],
            [-89.6178, 42.3886],
            [-89.5926, 42.316],
            [-89.4561, 42.3072],
            [-89.4558, 42.2661],
            [-89.6102, 42.2411],
            [-89.6591, 42.1998],
            [-89.6983, 42.2431],
            [-89.7489, 42.3141],
            [-89.7136, 42.3785],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715900',
        NAME: 'Freeport School District 145',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241296666,
        AWATER: 519510,
        INTPTLAT: '+42.2971895',
        INTPTLON: '-089.6239261',
        ELSDLEA: '',
        UNSDLEA: '15900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6154, 39.7512],
            [-90.4831, 39.7892],
            [-90.4377, 39.7272],
            [-90.5136, 39.6589],
            [-90.6121, 39.6448],
            [-90.6155, 39.6492],
            [-90.6154, 39.7512],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706600',
        NAME: 'Scott-Morgan Consolidated Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196185988,
        AWATER: 3096001,
        INTPTLAT: '+39.7235710',
        INTPTLON: '-090.5553550',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5811, 39.5217],
            [-90.3018, 39.5202],
            [-90.2281, 39.5199],
            [-90.228, 39.4707],
            [-90.3521, 39.3871],
            [-90.4394, 39.3577],
            [-90.6139, 39.395],
            [-90.6137, 39.3958],
            [-90.5811, 39.5217],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742240',
        NAME: 'North Greene Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459285733,
        AWATER: 2390673,
        INTPTLAT: '+39.4492681',
        INTPTLON: '-090.4390215',
        ELSDLEA: '',
        UNSDLEA: '42240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6121, 39.6448],
            [-90.5136, 39.6589],
            [-90.4377, 39.7272],
            [-90.3718, 39.7227],
            [-90.3007, 39.6365],
            [-90.3018, 39.5202],
            [-90.5811, 39.5217],
            [-90.6121, 39.6448],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742660',
        NAME: 'Winchester Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441018700,
        AWATER: 1716705,
        INTPTLAT: '+39.6041699',
        INTPTLON: '-090.4495793',
        ELSDLEA: '',
        UNSDLEA: '42660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4831, 39.7892],
            [-90.4712, 39.9193],
            [-90.3148, 39.9541],
            [-90.2765, 39.874],
            [-90.3169, 39.7678],
            [-90.3718, 39.7227],
            [-90.4377, 39.7272],
            [-90.4831, 39.7892],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710830',
        NAME: 'Triopia Community Unit School District 27',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338009949,
        AWATER: 84535,
        INTPTLAT: '+39.8458245',
        INTPTLON: '-090.3797881',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7691, 40.0041],
            [-89.5873, 40.0488],
            [-89.5794, 40.0488],
            [-89.5786, 39.9907],
            [-89.6541, 39.8709],
            [-89.7205, 39.8547],
            [-89.7693, 39.9143],
            [-89.7691, 40.0041],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704470',
        NAME: 'Athens Community Unit School District 213',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216793277,
        AWATER: 736493,
        INTPTLAT: '+39.9505739',
        INTPTLON: '-089.6854946',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5304, 39.8745],
            [-89.4617, 39.9332],
            [-89.3486, 39.9175],
            [-89.2984, 39.7866],
            [-89.3971, 39.7422],
            [-89.5407, 39.8142],
            [-89.5304, 39.8745],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739450',
        NAME: 'Tri-City Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257063915,
        AWATER: 729244,
        INTPTLAT: '+39.8352627',
        INTPTLON: '-089.4213003',
        ELSDLEA: '',
        UNSDLEA: '39450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3011, 40.0762],
            [-90.1966, 40.0525],
            [-90.0371, 40.1021],
            [-89.995, 40.0466],
            [-89.9757, 39.9098],
            [-89.9986, 39.8072],
            [-90.1079, 39.7999],
            [-90.1498, 39.8731],
            [-90.1027, 39.8728],
            [-90.0508, 40.0035],
            [-90.2523, 40.0043],
            [-90.3011, 40.0762],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700105',
        NAME: 'A-C Central Community Unit School District 262',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377198148,
        AWATER: 1185357,
        INTPTLAT: '+39.8619330',
        INTPTLON: '-090.0551532',
        ELSDLEA: '',
        UNSDLEA: '00105',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7435, 39.7866],
            [-89.7205, 39.8547],
            [-89.6541, 39.8709],
            [-89.5975, 39.8715],
            [-89.5742, 39.8023],
            [-89.5807, 39.7182],
            [-89.7341, 39.7416],
            [-89.7435, 39.7866],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737080',
        NAME: 'Springfield School District 186',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157489202,
        AWATER: 6015181,
        INTPTLAT: '+39.7978730',
        INTPTLON: '-089.6546368',
        ELSDLEA: '',
        UNSDLEA: '37080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3281, 42.4146],
            [-88.1988, 42.4156],
            [-88.1988, 42.3983],
            [-88.3062, 42.3729],
            [-88.3281, 42.4146],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1720490',
        NAME: 'Johnsburg Community Unit School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47397814,
        AWATER: 1967171,
        INTPTLAT: '+42.3913432',
        INTPTLON: '-088.2609313',
        ELSDLEA: '',
        UNSDLEA: '20490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4973, 39.6409],
            [-89.4788, 39.6697],
            [-89.4, 39.7351],
            [-89.2891, 39.6991],
            [-89.2888, 39.6116],
            [-89.3857, 39.5819],
            [-89.4789, 39.6044],
            [-89.4973, 39.6409],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713410',
        NAME: 'Edinburg Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188476883,
        AWATER: 5486567,
        INTPTLAT: '+39.6566463',
        INTPTLON: '-089.3811956',
        ELSDLEA: '',
        UNSDLEA: '13410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4818, 42.4143],
            [-88.3547, 42.4147],
            [-88.3411, 42.4143],
            [-88.3555, 42.3715],
            [-88.3552, 42.2986],
            [-88.4129, 42.2405],
            [-88.4718, 42.2414],
            [-88.5794, 42.3276],
            [-88.4818, 42.4143],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1743330',
        NAME: 'Woodstock Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277052500,
        AWATER: 2233983,
        INTPTLAT: '+42.3303768',
        INTPTLON: '-088.4533154',
        ELSDLEA: '',
        UNSDLEA: '43330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3066, 39.0863],
            [-89.2507, 39.0864],
            [-89.0275, 39.0707],
            [-89.0299, 38.9101],
            [-88.9204, 38.8971],
            [-88.9768, 38.8236],
            [-89.1884, 38.8256],
            [-89.2553, 38.8634],
            [-89.2128, 38.9756],
            [-89.299, 39.0282],
            [-89.3066, 39.0863],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740140',
        NAME: 'Vandalia Community Unit School District 203',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604997465,
        AWATER: 4002802,
        INTPTLAT: '+38.9461884',
        INTPTLON: '-089.1301478',
        ELSDLEA: '',
        UNSDLEA: '40140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0275, 39.0707],
            [-88.9858, 39.1138],
            [-88.8951, 39.044],
            [-88.8938, 38.9118],
            [-88.9204, 38.8971],
            [-89.0299, 38.9101],
            [-89.0275, 39.0707],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1707440',
        NAME: 'Brownstown Community Unit School District 201',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239608053,
        AWATER: 297787,
        INTPTLAT: '+38.9995506',
        INTPTLON: '-088.9637903',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1863, 38.8469],
            [-87.9928, 38.8497],
            [-87.9459, 38.8501],
            [-87.9081, 38.8501],
            [-87.9123, 38.5701],
            [-87.9539, 38.5701],
            [-88.1476, 38.5687],
            [-88.15, 38.7882],
            [-88.1863, 38.8469],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713290',
        NAME: 'East Richland Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 665030966,
        AWATER: 4654033,
        INTPTLAT: '+38.7140120',
        INTPTLON: '-088.0361709',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9031, 40.962],
            [-88.816, 40.9589],
            [-88.7971, 40.9305],
            [-88.7672, 40.8229],
            [-88.8148, 40.7988],
            [-88.9305, 40.826],
            [-88.9031, 40.962],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701390',
        NAME: 'Flanagan-Cornell District 74',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202102011,
        AWATER: 0,
        INTPTLAT: '+40.8803938',
        INTPTLON: '-088.8549923',
        ELSDLEA: '',
        UNSDLEA: '01390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1575, 40.7503],
            [-89.0462, 40.8202],
            [-88.9305, 40.826],
            [-88.8148, 40.7988],
            [-88.8139, 40.7113],
            [-88.903, 40.6664],
            [-89.1016, 40.625],
            [-89.1575, 40.7503],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700326',
        NAME: 'El Paso-Gridley Community Unit School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452699115,
        AWATER: 981277,
        INTPTLAT: '+40.7343754',
        INTPTLON: '-088.9769889',
        ELSDLEA: '',
        UNSDLEA: '00326',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9192, 41.1059],
            [-88.8065, 41.1066],
            [-88.6996, 41.0558],
            [-88.7831, 41.0328],
            [-88.816, 40.9589],
            [-88.9031, 40.962],
            [-88.9192, 41.1059],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738070',
        NAME: 'Woodland Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219001621,
        AWATER: 927714,
        INTPTLAT: '+41.0543475',
        INTPTLON: '-088.8244627',
        ELSDLEA: '',
        UNSDLEA: '38070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8066, 40.0831],
            [-87.6682, 40.1181],
            [-87.6674, 40.0871],
            [-87.6663, 40.0206],
            [-87.7135, 39.9985],
            [-87.8066, 40.0831],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710760',
        NAME: 'Catlin Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105092317,
        AWATER: 271075,
        INTPTLAT: '+40.0500488',
        INTPTLON: '-087.7161707',
        ELSDLEA: '',
        UNSDLEA: '10760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0645, 41.3699],
            [-91.0483, 41.4097],
            [-90.7863, 41.4529],
            [-90.6814, 41.4594],
            [-90.587, 41.4142],
            [-90.5887, 41.3277],
            [-90.6484, 41.2882],
            [-90.6596, 41.2883],
            [-90.707, 41.3286],
            [-91.0721, 41.3334],
            [-91.0645, 41.3699],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734440',
        NAME: 'Rockridge Community Unit School District 300',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454639853,
        AWATER: 27798979,
        INTPTLAT: '+41.3786899',
        INTPTLON: '-090.7991699',
        ELSDLEA: '',
        UNSDLEA: '34440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1015, 39.3053],
            [-89.9999, 39.319],
            [-89.9065, 39.4058],
            [-89.7369, 39.3628],
            [-89.7028, 39.2953],
            [-89.7384, 39.2198],
            [-89.8131, 39.2198],
            [-89.9421, 39.1588],
            [-90.0921, 39.2032],
            [-90.1481, 39.2619],
            [-90.1015, 39.3053],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708430',
        NAME: 'Carlinville Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 614395588,
        AWATER: 2194977,
        INTPTLAT: '+39.2656766',
        INTPTLON: '-089.9109538',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8979, 38.988],
            [-89.8671, 39.0565],
            [-89.7924, 39.0713],
            [-89.6985, 39.006],
            [-89.7732, 38.9178],
            [-89.8979, 38.988],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1737590',
        NAME: 'Staunton Community Unit School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196673342,
        AWATER: 1696404,
        INTPTLAT: '+38.9890771',
        INTPTLON: '-089.7905123',
        ELSDLEA: '',
        UNSDLEA: '37590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4106, 37.7448],
            [-88.3651, 37.8753],
            [-88.1542, 37.9187],
            [-88.0922, 37.8909],
            [-88.028, 37.7992],
            [-88.1604, 37.6561],
            [-88.1332, 37.5741],
            [-88.1957, 37.6066],
            [-88.4121, 37.5999],
            [-88.4106, 37.7448],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700045',
        NAME: 'Gallatin Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 850945167,
        AWATER: 14533428,
        INTPTLAT: '+37.7570995',
        INTPTLON: '-088.2268693',
        ELSDLEA: '',
        UNSDLEA: '00045',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.741, 38.3356],
            [-89.7038, 38.3354],
            [-89.6338, 38.2194],
            [-89.6572, 38.2194],
            [-89.8156, 38.2199],
            [-89.8512, 38.2203],
            [-89.8158, 38.307],
            [-89.741, 38.3356],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1724650',
        NAME: 'Marissa Community Unit School District 40',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153402005,
        AWATER: 4038208,
        INTPTLAT: '+38.2626623',
        INTPTLON: '-089.7408010',
        ELSDLEA: '',
        UNSDLEA: '24650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7044, 38.4157],
            [-89.5933, 38.4755],
            [-89.5712, 38.4827],
            [-89.4974, 38.4733],
            [-89.4278, 38.4989],
            [-89.3889, 38.4943],
            [-89.4812, 38.3189],
            [-89.552, 38.2993],
            [-89.5928, 38.2193],
            [-89.6338, 38.2194],
            [-89.7038, 38.3354],
            [-89.7042, 38.4076],
            [-89.7044, 38.4157],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741750',
        NAME: 'West Washington County Community Unit District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 472051294,
        AWATER: 724294,
        INTPTLAT: '+38.3713474',
        INTPTLON: '-089.5801935',
        ELSDLEA: '',
        UNSDLEA: '41750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8176, 37.7758],
            [-88.7071, 37.777],
            [-88.707, 37.7453],
            [-88.7072, 37.7314],
            [-88.7174, 37.6364],
            [-88.8188, 37.6266],
            [-88.856, 37.6783],
            [-88.8176, 37.7758],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711100',
        NAME: 'Crab Orchard Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178576014,
        AWATER: 5278773,
        INTPTLAT: '+37.6965409',
        INTPTLON: '-088.7588371',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2389, 39.2181],
            [-90.1481, 39.2619],
            [-90.0921, 39.2032],
            [-89.9421, 39.1588],
            [-89.9233, 39.1291],
            [-90.0208, 39.0608],
            [-90.0528, 38.9986],
            [-90.2207, 39],
            [-90.2389, 39.2181],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731500',
        NAME: 'Southwestern Community Unit School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 448276819,
        AWATER: 1796844,
        INTPTLAT: '+39.1239660',
        INTPTLON: '-090.1072809',
        ELSDLEA: '',
        UNSDLEA: '31500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1039, 39.5207],
            [-90.0987, 39.5207],
            [-90.0745, 39.5211],
            [-89.9259, 39.4928],
            [-89.9065, 39.4058],
            [-89.9999, 39.319],
            [-90.1015, 39.3053],
            [-90.0947, 39.391],
            [-90.152, 39.4338],
            [-90.1537, 39.5165],
            [-90.1039, 39.5207],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1730540',
        NAME: 'Northwestern Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360479116,
        AWATER: 1275929,
        INTPTLAT: '+39.4235846',
        INTPTLON: '-090.0310718',
        ELSDLEA: '',
        UNSDLEA: '30540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7924, 39.0713],
            [-89.6998, 39.1321],
            [-89.6145, 39.0287],
            [-89.6393, 38.9991],
            [-89.6985, 39.006],
            [-89.7924, 39.0713],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727180',
        NAME: 'Mount Olive Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144377496,
        AWATER: 1091141,
        INTPTLAT: '+39.0587312',
        INTPTLON: '-089.6950410',
        ELSDLEA: '',
        UNSDLEA: '27180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7384, 39.2198],
            [-89.7028, 39.2953],
            [-89.5848, 39.2589],
            [-89.5859, 39.0937],
            [-89.5486, 39.0354],
            [-89.6145, 39.0287],
            [-89.6998, 39.1321],
            [-89.7384, 39.2198],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723250',
        NAME: 'Litchfield Community Unit School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275863851,
        AWATER: 5638468,
        INTPTLAT: '+39.1722912',
        INTPTLON: '-089.6406080',
        ELSDLEA: '',
        UNSDLEA: '23250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7156, 39.3797],
            [-90.688, 39.3666],
            [-90.7918, 39.3071],
            [-90.9496, 39.4078],
            [-91.0244, 39.4392],
            [-91.0784, 39.5062],
            [-90.9883, 39.5274],
            [-90.8188, 39.5012],
            [-90.8194, 39.4142],
            [-90.7156, 39.3797],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731890',
        NAME: 'Pleasant Hill Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358230912,
        AWATER: 25150494,
        INTPTLAT: '+39.4271685',
        INTPTLON: '-090.8788130',
        ELSDLEA: '',
        UNSDLEA: '31890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0981, 39.5361],
            [-91.0678, 39.5799],
            [-90.9261, 39.5787],
            [-90.9159, 39.7133],
            [-90.9537, 39.7571],
            [-90.9161, 39.7647],
            [-90.8372, 39.7532],
            [-90.8344, 39.6633],
            [-90.7226, 39.6345],
            [-90.6155, 39.6492],
            [-90.6121, 39.6448],
            [-90.5811, 39.5217],
            [-90.6137, 39.3958],
            [-90.688, 39.3666],
            [-90.7156, 39.3797],
            [-90.8194, 39.4142],
            [-90.8188, 39.5012],
            [-90.9883, 39.5274],
            [-91.0784, 39.5062],
            [-91.0981, 39.5361],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731710',
        NAME: 'Pikeland Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 861073576,
        AWATER: 9251845,
        INTPTLAT: '+39.5544798',
        INTPTLON: '-090.7766208',
        ELSDLEA: '',
        UNSDLEA: '31710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4587, 37.2483],
            [-89.3401, 37.2467],
            [-89.3208, 37.3347],
            [-89.2484, 37.3351],
            [-89.2206, 37.2464],
            [-89.2604, 37.231],
            [-89.2611, 37.1075],
            [-89.3902, 37.1091],
            [-89.4544, 37.1854],
            [-89.4587, 37.2483],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713590',
        NAME: 'Egyptian Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299703263,
        AWATER: 14568017,
        INTPTLAT: '+37.2057658',
        INTPTLON: '-089.3398145',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2206, 37.2464],
            [-89.1, 37.2407],
            [-89.0437, 37.2007],
            [-89.1721, 37.0683],
            [-89.2611, 37.1075],
            [-89.2604, 37.231],
            [-89.2206, 37.2464],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726970',
        NAME: 'Meridian Community Unit School District 101',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250322148,
        AWATER: 5017391,
        INTPTLAT: '+37.1667980',
        INTPTLON: '-089.1841240',
        ELSDLEA: '',
        UNSDLEA: '26970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9854, 40.8584],
            [-89.9369, 40.7998],
            [-89.7658, 40.7848],
            [-89.7175, 40.7323],
            [-89.7565, 40.7109],
            [-89.9861, 40.7123],
            [-89.9854, 40.8584],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714050',
        NAME: 'Elmwood Community Unit School District 322',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189004887,
        AWATER: 1538819,
        INTPTLAT: '+40.7578826',
        INTPTLON: '-089.8836653',
        ELSDLEA: '',
        UNSDLEA: '14050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9111, 40.6253],
            [-89.7566, 40.653],
            [-89.6921, 40.5974],
            [-89.6888, 40.5585],
            [-89.7331, 40.5527],
            [-89.8635, 40.5177],
            [-89.8781, 40.5095],
            [-89.9111, 40.6253],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719960',
        NAME: 'Illini Bluffs Community Unit School District 327',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161798504,
        AWATER: 4452150,
        INTPTLAT: '+40.5875751',
        INTPTLON: '-089.7945407',
        ELSDLEA: '',
        UNSDLEA: '19960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.559, 37.9066],
            [-88.426, 37.9514],
            [-88.3651, 37.8753],
            [-88.4106, 37.7448],
            [-88.5033, 37.7756],
            [-88.5593, 37.8596],
            [-88.559, 37.9066],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713660',
        NAME: 'Eldorado Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265229178,
        AWATER: 1591538,
        INTPTLAT: '+37.8498752',
        INTPTLON: '-088.4514921',
        ELSDLEA: '',
        UNSDLEA: '13660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7732, 38.9178],
            [-89.6985, 39.006],
            [-89.6393, 38.9991],
            [-89.6178, 38.889],
            [-89.5579, 38.8589],
            [-89.5488, 38.7566],
            [-89.5492, 38.7419],
            [-89.6085, 38.6704],
            [-89.6232, 38.6557],
            [-89.707, 38.6552],
            [-89.7185, 38.6551],
            [-89.7079, 38.8156],
            [-89.8167, 38.8157],
            [-89.7732, 38.9178],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1718990',
        NAME: 'Highland Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463562814,
        AWATER: 5107692,
        INTPTLAT: '+38.8234922',
        INTPTLON: '-089.6660780',
        ELSDLEA: '',
        UNSDLEA: '18990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5137, 39.9879],
            [-90.4335, 40.024],
            [-90.3552, 40.1242],
            [-90.3011, 40.0762],
            [-90.2523, 40.0043],
            [-90.3148, 39.9541],
            [-90.4712, 39.9193],
            [-90.5377, 39.9195],
            [-90.5137, 39.9879],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1705310',
        NAME: 'Beardstown Community Unit School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254108962,
        AWATER: 15202184,
        INTPTLAT: '+40.0081909',
        INTPTLON: '-090.3811551',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3148, 39.9541],
            [-90.2523, 40.0043],
            [-90.0508, 40.0035],
            [-90.1027, 39.8728],
            [-90.1498, 39.8731],
            [-90.2765, 39.874],
            [-90.3148, 39.9541],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740410',
        NAME: 'Virginia Community Unit School District 64',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278293860,
        AWATER: 206934,
        INTPTLAT: '+39.9452149',
        INTPTLON: '-090.1823719',
        ELSDLEA: '',
        UNSDLEA: '40410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.12, 38.6456],
            [-90.1129, 38.6599],
            [-90.0844, 38.6773],
            [-90.0386, 38.7459],
            [-89.9284, 38.745],
            [-89.9284, 38.6577],
            [-89.9851, 38.6232],
            [-90.0373, 38.6232],
            [-90.1253, 38.6388],
            [-90.12, 38.6456],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710650',
        NAME: 'Collinsville Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124606066,
        AWATER: 4405641,
        INTPTLAT: '+38.6849892',
        INTPTLON: '-089.9976843',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1666, 38.7724],
            [-90.1177, 38.8057],
            [-90.0759, 38.7912],
            [-90.0386, 38.7459],
            [-90.0844, 38.6773],
            [-90.1968, 38.6881],
            [-90.1666, 38.7724],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717280',
        NAME: 'Granite City Community Unit School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123729762,
        AWATER: 22512352,
        INTPTLAT: '+38.7321841',
        INTPTLON: '-090.1163442',
        ELSDLEA: '',
        UNSDLEA: '17280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7409, 40.3577],
            [-87.6741, 40.2931],
            [-87.5295, 40.2866],
            [-87.531, 40.1772],
            [-87.5314, 40.1483],
            [-87.6877, 40.1967],
            [-87.7356, 40.2036],
            [-87.7409, 40.3577],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706390',
        NAME: 'Bismarck-Henning Consolidated Unit School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251577210,
        AWATER: 248692,
        INTPTLAT: '+40.2445328',
        INTPTLON: '-087.6387268',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6674, 40.0871],
            [-87.532, 40.0889],
            [-87.5323, 40.0118],
            [-87.6663, 40.0206],
            [-87.6674, 40.0871],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710820',
        NAME: 'Westville Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88979495,
        AWATER: 445144,
        INTPTLAT: '+40.0537715',
        INTPTLON: '-087.6099113',
        ELSDLEA: '',
        UNSDLEA: '10820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6877, 40.1967],
            [-87.5314, 40.1483],
            [-87.5314, 40.1481],
            [-87.532, 40.0889],
            [-87.6674, 40.0871],
            [-87.6682, 40.1181],
            [-87.6877, 40.1967],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1711790',
        NAME: 'Danville Community Consolidated School District 118',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120492361,
        AWATER: 3470453,
        INTPTLAT: '+40.1345627',
        INTPTLON: '-087.6171244',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7602, 40.3868],
            [-87.7434, 40.4258],
            [-87.5273, 40.4327],
            [-87.528, 40.389],
            [-87.5295, 40.2866],
            [-87.6741, 40.2931],
            [-87.7409, 40.3577],
            [-87.7602, 40.3868],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1734870',
        NAME: 'Rossville-Alvin Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250782767,
        AWATER: 0,
        INTPTLAT: '+40.3669764',
        INTPTLON: '-087.6226071',
        ELSDLEA: '',
        UNSDLEA: '34870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8269, 42.3413],
            [-87.8033, 42.4138],
            [-87.8035, 42.4355],
            [-87.7979, 42.4641],
            [-87.8032, 42.4926],
            [-87.8005, 42.4919],
            [-87.0199, 42.4935],
            [-87.2078, 41.761],
            [-87.5237, 41.7599],
            [-87.5795, 41.7922],
            [-87.6651, 42.023],
            [-87.6796, 42.0717],
            [-87.7028, 42.0897],
            [-87.7108, 42.0954],
            [-87.7411, 42.1275],
            [-87.759, 42.1524],
            [-87.8089, 42.2247],
            [-87.8281, 42.2689],
            [-87.8333, 42.3049],
            [-87.8269, 42.3413],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1799997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 56959,
        AWATER: 4071300595,
        INTPTLAT: '+41.8863071',
        INTPTLON: '-087.6135293',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2288, 40.9987],
            [-89.1667, 41.0896],
            [-89.1241, 41.1115],
            [-88.9595, 41.1127],
            [-88.9192, 41.1059],
            [-88.9031, 40.962],
            [-88.9305, 40.826],
            [-89.0462, 40.8202],
            [-89.17, 40.9267],
            [-89.2288, 40.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715100',
        NAME: 'Fieldcrest Community Unit School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 620420705,
        AWATER: 226477,
        INTPTLAT: '+40.9732279',
        INTPTLON: '-089.0445197',
        ELSDLEA: '',
        UNSDLEA: '15100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0412, 38.9708],
            [-90.0403, 38.8698],
            [-90.0871, 38.8896],
            [-90.1132, 38.8933],
            [-90.0412, 38.9708],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1706120',
        NAME: 'Bethalto Consolidated Unit School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55086835,
        AWATER: 671207,
        INTPTLAT: '+38.9094518',
        INTPTLON: '-090.0422420',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6409, 38.5631],
            [-88.4202, 38.5738],
            [-88.298, 38.5567],
            [-88.2221, 38.5839],
            [-88.1481, 38.5472],
            [-88.1659, 38.4447],
            [-88.2221, 38.4011],
            [-88.3384, 38.4681],
            [-88.4722, 38.4287],
            [-88.6041, 38.4836],
            [-88.6409, 38.5631],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710240',
        NAME: 'North Wayne Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505905082,
        AWATER: 1582379,
        INTPTLAT: '+38.5054163',
        INTPTLON: '-088.3746988',
        ELSDLEA: '',
        UNSDLEA: '10240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3041, 40.2236],
            [-88.2698, 40.2237],
            [-88.2208, 40.2071],
            [-88.2019, 40.1983],
            [-88.2283, 40.0544],
            [-88.3327, 40.0551],
            [-88.3509, 40.1422],
            [-88.3041, 40.2236],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1709420',
        NAME: 'Champaign Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208726856,
        AWATER: 320067,
        INTPTLAT: '+40.1308395',
        INTPTLON: '-088.2837376',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4342, 39.9673],
            [-88.3878, 40.0552],
            [-88.3327, 40.0551],
            [-88.2283, 40.0544],
            [-88.1239, 40.055],
            [-88.0288, 40.0543],
            [-88.0286, 39.9668],
            [-88.1231, 39.9385],
            [-88.2454, 39.9373],
            [-88.2551, 39.8791],
            [-88.3966, 39.8939],
            [-88.4154, 39.8792],
            [-88.4251, 39.8863],
            [-88.4342, 39.9673],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739120',
        NAME: 'Tolono Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 449816925,
        AWATER: 575994,
        INTPTLAT: '+39.9847872',
        INTPTLON: '-088.2419274',
        ELSDLEA: '',
        UNSDLEA: '39120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4198, 39.2318],
            [-88.3271, 39.2583],
            [-88.3276, 39.3742],
            [-88.3138, 39.3743],
            [-88.1023, 39.3765],
            [-88.0981, 39.1796],
            [-88.3029, 39.171],
            [-88.3966, 39.2149],
            [-88.4198, 39.2318],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739090',
        NAME: 'Cumberland Community Unit School District 77',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480418483,
        AWATER: 331290,
        INTPTLAT: '+39.2683343',
        INTPTLON: '-088.2287735',
        ELSDLEA: '',
        UNSDLEA: '39090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3651, 39.7587],
            [-91.0682, 39.7576],
            [-91.0298, 39.7717],
            [-90.9537, 39.7571],
            [-90.9159, 39.7133],
            [-90.9261, 39.5787],
            [-91.0678, 39.5799],
            [-91.0981, 39.5361],
            [-91.1667, 39.5625],
            [-91.178, 39.5982],
            [-91.3678, 39.729],
            [-91.3651, 39.7587],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701387',
        NAME: 'Western Community Unit School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 622388020,
        AWATER: 19232211,
        INTPTLAT: '+39.6731950',
        INTPTLON: '-091.1182264',
        ELSDLEA: '',
        UNSDLEA: '01387',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.601, 40.901],
            [-89.5992, 40.9733],
            [-89.4723, 40.9212],
            [-89.4975, 40.8787],
            [-89.5033, 40.8713],
            [-89.5605, 40.7975],
            [-89.5925, 40.8126],
            [-89.601, 40.901],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719970',
        NAME: 'Illinois Valley Central Unit School District 321',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147616073,
        AWATER: 14091933,
        INTPTLAT: '+40.9004256',
        INTPTLON: '-089.5488843',
        ELSDLEA: '',
        UNSDLEA: '19970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6386, 41.0613],
            [-89.5811, 41.0684],
            [-89.2788, 41.0893],
            [-89.1667, 41.0896],
            [-89.2288, 40.9987],
            [-89.3286, 40.9509],
            [-89.4723, 40.9212],
            [-89.5992, 40.9733],
            [-89.6387, 40.9869],
            [-89.6386, 41.0613],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700126',
        NAME: 'Midland Community Unit School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426289899,
        AWATER: 18428979,
        INTPTLAT: '+41.0214054',
        INTPTLON: '-089.4092627',
        ELSDLEA: '',
        UNSDLEA: '00126',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1968, 38.6881],
            [-90.0844, 38.6773],
            [-90.1129, 38.6599],
            [-90.1464, 38.6602],
            [-90.1473, 38.6602],
            [-90.1868, 38.6753],
            [-90.1968, 38.6881],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723970',
        NAME: 'Madison Community Unit School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17131904,
        AWATER: 4613296,
        INTPTLAT: '+38.6778106',
        INTPTLON: '-090.1283255',
        ELSDLEA: '',
        UNSDLEA: '23970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3708, 38.2256],
            [-88.2622, 38.1918],
            [-88.2644, 37.9958],
            [-88.1542, 37.9187],
            [-88.3651, 37.8753],
            [-88.426, 37.9514],
            [-88.3708, 38.2256],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700004',
        NAME: 'Norris City-Omaha-Enfield Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531449976,
        AWATER: 1514917,
        INTPTLAT: '+38.0284199',
        INTPTLON: '-088.3286147',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2622, 38.1918],
            [-88.192, 38.2566],
            [-88.1437, 38.2127],
            [-87.9853, 38.2155],
            [-87.9167, 38.1566],
            [-87.9579, 38.0838],
            [-88.0341, 38.032],
            [-88.0134, 37.8949],
            [-88.0922, 37.8909],
            [-88.1542, 37.9187],
            [-88.2644, 37.9958],
            [-88.2622, 38.1918],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708500',
        NAME: 'Carmi-White County Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755628243,
        AWATER: 15977055,
        INTPTLAT: '+38.0901525',
        INTPTLON: '-088.1238789',
        ELSDLEA: '',
        UNSDLEA: '08500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6596, 41.8788],
            [-88.6023, 41.8068],
            [-88.5032, 41.8082],
            [-88.4892, 41.7211],
            [-88.4899, 41.7211],
            [-88.5924, 41.7197],
            [-88.6608, 41.7191],
            [-88.7149, 41.835],
            [-88.6596, 41.8788],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719260',
        NAME: 'Hinckley-Big Rock Community Unit School District 429',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231744927,
        AWATER: 462588,
        INTPTLAT: '+41.7883758',
        INTPTLON: '-088.6121501',
        ELSDLEA: '',
        UNSDLEA: '19260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6887, 41.7188],
            [-88.6608, 41.7191],
            [-88.5924, 41.7197],
            [-88.558, 41.617],
            [-88.624, 41.5531],
            [-88.6735, 41.5712],
            [-88.6887, 41.7188],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1735370',
        NAME: 'Sandwich Community Unit School District 430',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132709016,
        AWATER: 2160464,
        INTPTLAT: '+41.6392433',
        INTPTLON: '-088.6246665',
        ELSDLEA: '',
        UNSDLEA: '35370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7519, 41.685],
            [-88.7195, 41.5544],
            [-88.8555, 41.5355],
            [-88.8823, 41.6298],
            [-88.7519, 41.685],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722870',
        NAME: 'Leland Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140970247,
        AWATER: 159454,
        INTPTLAT: '+41.6112923',
        INTPTLON: '-088.7929930',
        ELSDLEA: '',
        UNSDLEA: '22870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2019, 40.1983],
            [-88.1634, 40.2075],
            [-88.1556, 40.2076],
            [-88.1444, 40.2076],
            [-88.1252, 40.1714],
            [-88.1239, 40.055],
            [-88.2283, 40.0544],
            [-88.2019, 40.1983],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1739960',
        NAME: 'Urbana School District 116',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115558223,
        AWATER: 207681,
        INTPTLAT: '+40.1201229',
        INTPTLON: '-088.1762333',
        ELSDLEA: '',
        UNSDLEA: '39960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4604, 40.3402],
            [-88.3661, 40.3987],
            [-88.2337, 40.3995],
            [-88.2337, 40.3959],
            [-88.2331, 40.3408],
            [-88.2511, 40.253],
            [-88.2698, 40.2237],
            [-88.3041, 40.2236],
            [-88.4605, 40.2674],
            [-88.4604, 40.3402],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715180',
        NAME: 'Fisher Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267178981,
        AWATER: 870708,
        INTPTLAT: '+40.3185257',
        INTPTLON: '-088.3347532',
        ELSDLEA: '',
        UNSDLEA: '15180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6507, 39.8503],
            [-88.4763, 39.8827],
            [-88.4251, 39.8863],
            [-88.4154, 39.8792],
            [-88.3855, 39.7541],
            [-88.492, 39.7771],
            [-88.5828, 39.754],
            [-88.6437, 39.7922],
            [-88.6507, 39.8503],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704590',
        NAME: 'Atwood-Hammond Community Unit School District 39',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250073239,
        AWATER: 13227,
        INTPTLAT: '+39.8136327',
        INTPTLON: '-088.5017059',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7582, 39.7692],
            [-88.6437, 39.7922],
            [-88.5828, 39.754],
            [-88.492, 39.7771],
            [-88.3855, 39.7541],
            [-88.3575, 39.7391],
            [-88.4148, 39.6153],
            [-88.4715, 39.5784],
            [-88.5772, 39.6736],
            [-88.676, 39.6664],
            [-88.7578, 39.7399],
            [-88.7582, 39.7692],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704260',
        NAME: 'Arthur Community Unit School District 305',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415216952,
        AWATER: 85504,
        INTPTLAT: '+39.7174256',
        INTPTLON: '-088.5575796',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1769, 39.6521],
            [-88.1578, 39.7454],
            [-88.1012, 39.7455],
            [-87.9865, 39.7288],
            [-87.9324, 39.6571],
            [-88.0019, 39.576],
            [-88.1769, 39.6521],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1729340',
        NAME: 'Oakland Community Unit School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230658599,
        AWATER: 410426,
        INTPTLAT: '+39.6638178',
        INTPTLON: '-088.0488846',
        ELSDLEA: '',
        UNSDLEA: '29340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7789, 41.1558],
            [-87.7456, 41.2075],
            [-87.7414, 41.2075],
            [-87.5265, 41.2091],
            [-87.5266, 41.1661],
            [-87.5266, 41.1215],
            [-87.6569, 41.0949],
            [-87.7393, 41.12],
            [-87.7789, 41.1558],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1726430',
        NAME: 'Momence Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215179268,
        AWATER: 2226212,
        INTPTLAT: '+41.1474724',
        INTPTLON: '-087.6360985',
        ELSDLEA: '',
        UNSDLEA: '26430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1474, 38.2129],
            [-89.096, 38.272],
            [-89.082, 38.2718],
            [-89.0042, 38.2724],
            [-88.9858, 38.2723],
            [-88.93, 38.2139],
            [-88.9307, 38.1255],
            [-88.9954, 38.1251],
            [-89.1296, 38.1247],
            [-89.1474, 38.2129],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740650',
        NAME: 'Waltonville Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252893750,
        AWATER: 24759811,
        INTPTLAT: '+38.1953533',
        INTPTLON: '-089.0458394',
        ELSDLEA: '',
        UNSDLEA: '40650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7218, 38.2276],
            [-88.7024, 38.2567],
            [-88.5471, 38.2268],
            [-88.4259, 38.2558],
            [-88.3708, 38.2256],
            [-88.426, 37.9514],
            [-88.559, 37.9066],
            [-88.6718, 37.9215],
            [-88.7065, 37.951],
            [-88.7227, 38.049],
            [-88.7046, 38.1252],
            [-88.7218, 38.2276],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710790',
        NAME: 'Hamilton County Community Unit School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 938464433,
        AWATER: 2822570,
        INTPTLAT: '+38.0903955',
        INTPTLON: '-088.5754184',
        ELSDLEA: '',
        UNSDLEA: '10790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4354, 41.2678],
            [-90.2906, 41.2659],
            [-90.1994, 41.211],
            [-90.1993, 41.1667],
            [-90.2432, 41.1519],
            [-90.3897, 41.1513],
            [-90.399, 41.1513],
            [-90.4044, 41.1513],
            [-90.4114, 41.1513],
            [-90.4377, 41.1513],
            [-90.4354, 41.2678],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703660',
        NAME: 'Alwood Community Unit School District 225',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223193736,
        AWATER: 133189,
        INTPTLAT: '+41.2040805',
        INTPTLON: '-090.3267568',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0288, 40.0543],
            [-88.0097, 40.0688],
            [-87.9258, 40.0838],
            [-87.8634, 40.0802],
            [-87.9014, 40.0264],
            [-87.8819, 39.88],
            [-88.0469, 39.8468],
            [-88.1231, 39.9385],
            [-88.0286, 39.9668],
            [-88.0288, 40.0543],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700106',
        NAME: 'Heritage Community Unit School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347004140,
        AWATER: 705258,
        INTPTLAT: '+39.9590065',
        INTPTLON: '-087.9847632',
        ELSDLEA: '',
        UNSDLEA: '00106',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1231, 39.9385],
            [-88.0469, 39.8468],
            [-88.1012, 39.7455],
            [-88.1578, 39.7454],
            [-88.1951, 39.7459],
            [-88.2551, 39.8791],
            [-88.2454, 39.9373],
            [-88.1231, 39.9385],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740320',
        NAME: 'Villa Grove Community Unit School District 302',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231484275,
        AWATER: 289074,
        INTPTLAT: '+39.8485600',
        INTPTLON: '-088.1580190',
        ELSDLEA: '',
        UNSDLEA: '40320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2641, 38.521],
            [-90.144, 38.4846],
            [-90.1289, 38.3976],
            [-90.2583, 38.3977],
            [-90.33, 38.3974],
            [-90.2641, 38.521],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710740',
        NAME: 'Columbia Community Unit School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126840684,
        AWATER: 7070100,
        INTPTLAT: '+38.4474704',
        INTPTLON: '-090.2111999',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0721, 41.3334],
            [-90.707, 41.3286],
            [-90.6596, 41.2883],
            [-90.6484, 41.2882],
            [-90.6377, 41.1323],
            [-90.8633, 41.0325],
            [-90.9438, 41.0265],
            [-90.9494, 41.0727],
            [-90.9897, 41.1557],
            [-91.1104, 41.2373],
            [-91.0721, 41.3334],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1701395',
        NAME: 'Mercer County School District 404',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 958165885,
        AWATER: 20836647,
        INTPTLAT: '+41.2073450',
        INTPTLON: '-090.8643785',
        ELSDLEA: '',
        UNSDLEA: '01395',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8384, 37.3357],
            [-88.7107, 37.3371],
            [-88.4903, 37.1594],
            [-88.4904, 37.0686],
            [-88.5661, 37.0752],
            [-88.6169, 37.116],
            [-88.7836, 37.1747],
            [-88.8384, 37.3357],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1725000',
        NAME: 'Massac Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482814717,
        AWATER: 6799799,
        INTPTLAT: '+37.2090590',
        INTPTLON: '-088.6672205',
        ELSDLEA: '',
        UNSDLEA: '25000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9291, 37.3026],
            [-88.8877, 37.3356],
            [-88.8384, 37.3357],
            [-88.7836, 37.1747],
            [-88.928, 37.2264],
            [-88.9291, 37.3026],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700015',
        NAME: 'Joppa-Maple Grove Unit District 38',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124383199,
        AWATER: 5083110,
        INTPTLAT: '+37.2591385',
        INTPTLON: '-088.8603355',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6969, 38.7379],
            [-88.4187, 38.7211],
            [-88.4202, 38.5738],
            [-88.6409, 38.5631],
            [-88.6987, 38.5622],
            [-88.6991, 38.6933],
            [-88.6969, 38.7379],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1715400',
        NAME: 'Flora Community Unit School District 35',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450447822,
        AWATER: 703478,
        INTPTLAT: '+38.6436485',
        INTPTLON: '-088.5545964',
        ELSDLEA: '',
        UNSDLEA: '15400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4187, 38.7211],
            [-88.3991, 38.8533],
            [-88.3616, 38.8671],
            [-88.2585, 38.8219],
            [-88.2962, 38.7904],
            [-88.2974, 38.6346],
            [-88.2221, 38.5839],
            [-88.298, 38.5567],
            [-88.4202, 38.5738],
            [-88.4187, 38.7211],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710340',
        NAME: 'Clay City Community Unit District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373734317,
        AWATER: 1595064,
        INTPTLAT: '+38.7010867',
        INTPTLON: '-088.3435646',
        ELSDLEA: '',
        UNSDLEA: '10340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1556, 40.9755],
            [-90.0138, 41.062],
            [-89.9668, 40.9746],
            [-89.9811, 40.8876],
            [-89.9854, 40.8653],
            [-90.1001, 40.8742],
            [-90.1556, 40.9755],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742450',
        NAME: 'Williamsfield Community Unit School District 210',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195457205,
        AWATER: 2572263,
        INTPTLAT: '+40.9437147',
        INTPTLON: '-090.0480286',
        ELSDLEA: '',
        UNSDLEA: '42450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3447, 38.0391],
            [-89.2526, 38.0832],
            [-89.1862, 38.1256],
            [-89.1316, 38.1169],
            [-89.1389, 38.0376],
            [-89.1776, 37.9503],
            [-89.3546, 37.9519],
            [-89.3447, 38.0391],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1712760',
        NAME: 'Du Quoin Community Unit School District 300',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270318160,
        AWATER: 6395505,
        INTPTLAT: '+38.0230732',
        INTPTLON: '-089.2296338',
        ELSDLEA: '',
        UNSDLEA: '12760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4156, 37.5997],
            [-89.2778, 37.6285],
            [-89.1946, 37.5997],
            [-89.1537, 37.6005],
            [-89.2265, 37.5035],
            [-89.2293, 37.507],
            [-89.2753, 37.4955],
            [-89.3281, 37.4947],
            [-89.4156, 37.5997],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1710570',
        NAME: 'Cobden School Unit District 17',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223135128,
        AWATER: 2944951,
        INTPTLAT: '+37.5607243',
        INTPTLON: '-089.2798644',
        ELSDLEA: '',
        UNSDLEA: '10570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4277, 37.9532],
            [-89.3546, 37.9519],
            [-89.1776, 37.9503],
            [-89.1508, 37.9502],
            [-89.1512, 37.862],
            [-89.1799, 37.8481],
            [-89.2653, 37.8202],
            [-89.3907, 37.8389],
            [-89.4277, 37.9532],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1713860',
        NAME: 'Elverado Community Unit School District 196',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270955423,
        AWATER: 6905669,
        INTPTLAT: '+37.8892170',
        INTPTLON: '-089.2926305',
        ELSDLEA: '',
        UNSDLEA: '13860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5818, 39.2084],
            [-88.5393, 39.2335],
            [-88.4198, 39.2318],
            [-88.3966, 39.2149],
            [-88.418, 39.0308],
            [-88.5058, 39.031],
            [-88.5232, 39.1626],
            [-88.5955, 39.1719],
            [-88.5818, 39.2084],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1738760',
        NAME: 'Teutopolis Community Unit School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264446656,
        AWATER: 0,
        INTPTLAT: '+39.1494914',
        INTPTLON: '-088.4735433',
        ELSDLEA: '',
        UNSDLEA: '38760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6937, 38.9109],
            [-88.5442, 38.9146],
            [-88.3664, 38.911],
            [-88.3616, 38.8671],
            [-88.3991, 38.8533],
            [-88.4187, 38.7211],
            [-88.6969, 38.7379],
            [-88.6937, 38.9109],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1728810',
        NAME: 'North Clay Community Unit School District 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538654217,
        AWATER: 1138238,
        INTPTLAT: '+38.8132138',
        INTPTLON: '-088.5350087',
        ELSDLEA: '',
        UNSDLEA: '28810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8728, 40.3304],
            [-88.8245, 40.3787],
            [-88.6881, 40.3987],
            [-88.603, 40.3983],
            [-88.5889, 40.3474],
            [-88.8041, 40.2606],
            [-88.8781, 40.2826],
            [-88.8728, 40.3304],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1722620',
        NAME: 'Le Roy Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258214938,
        AWATER: 163512,
        INTPTLAT: '+40.3441509',
        INTPTLON: '-088.7410568',
        ELSDLEA: '',
        UNSDLEA: '22620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0941, 41.815],
            [-88.0875, 41.815],
            [-88.0527, 41.8155],
            [-88.0515, 41.7793],
            [-88.09, 41.7827],
            [-88.0941, 41.815],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1723200',
        NAME: 'Lisle Community Unit School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14663606,
        AWATER: 276521,
        INTPTLAT: '+41.7977324',
        INTPTLON: '-088.0758978',
        ELSDLEA: '',
        UNSDLEA: '23200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2193, 41.8374],
            [-88.1463, 41.8572],
            [-88.148, 41.8789],
            [-88.1507, 41.8936],
            [-88.1454, 41.9011],
            [-88.1018, 41.902],
            [-88.093, 41.9023],
            [-88.079, 41.8592],
            [-88.0875, 41.815],
            [-88.0941, 41.815],
            [-88.1803, 41.7984],
            [-88.2193, 41.8374],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1742180',
        NAME: 'Community Unit School District 200',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66906551,
        AWATER: 1999166,
        INTPTLAT: '+41.8481362',
        INTPTLON: '-088.1351213',
        ELSDLEA: '',
        UNSDLEA: '42180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.263, 41.8368],
            [-88.2193, 41.8374],
            [-88.1803, 41.7984],
            [-88.1439, 41.7132],
            [-88.1466, 41.6819],
            [-88.2237, 41.6694],
            [-88.262, 41.732],
            [-88.2626, 41.7998],
            [-88.263, 41.8368],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741690',
        NAME: 'Indian Prairie Community Unit School District 204',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119832403,
        AWATER: 3014035,
        INTPTLAT: '+41.7504155',
        INTPTLON: '-088.2114314',
        ELSDLEA: '',
        UNSDLEA: '41690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.929, 42.1968],
            [-89.7762, 42.1972],
            [-89.6983, 42.2431],
            [-89.6591, 42.1998],
            [-89.6855, 42.0538],
            [-89.6851, 42.0248],
            [-89.8595, 42.0221],
            [-89.9092, 42.0734],
            [-89.929, 42.1968],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1700007',
        NAME: 'Eastland Community Unit School District 308',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398016097,
        AWATER: 1661474,
        INTPTLAT: '+42.1286147',
        INTPTLON: '-089.7749071',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9859, 41.8192],
            [-87.9525, 41.8177],
            [-87.9654, 41.7884],
            [-87.9751, 41.7882],
            [-87.9848, 41.788],
            [-87.9859, 41.8192],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1741980',
        NAME: 'Westmont Community Unit School District 201',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8013349,
        AWATER: 118391,
        INTPTLAT: '+41.8089005',
        INTPTLON: '-087.9722138',
        ELSDLEA: '',
        UNSDLEA: '41980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2735, 40.778],
            [-89.1575, 40.7503],
            [-89.1016, 40.625],
            [-89.1647, 40.5962],
            [-89.2688, 40.5793],
            [-89.3296, 40.6608],
            [-89.3402, 40.7482],
            [-89.2735, 40.778],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1714430',
        NAME: 'Eureka Community Unit District 140',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292917889,
        AWATER: 368184,
        INTPTLAT: '+40.6839621',
        INTPTLON: '-089.2284152',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8177, 38.5696],
            [-87.7601, 38.5688],
            [-87.6515, 38.5682],
            [-87.7349, 38.48],
            [-87.8177, 38.5696],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1703450',
        NAME: 'Allendale Community Consolidated School District 17',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102509854,
        AWATER: 1963823,
        INTPTLAT: '+38.5387999',
        INTPTLON: '-087.7332132',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9556, 38.3263],
            [-87.9539, 38.5701],
            [-87.9123, 38.5701],
            [-87.8177, 38.5696],
            [-87.7349, 38.48],
            [-87.7437, 38.4144],
            [-87.7594, 38.3927],
            [-87.8511, 38.2751],
            [-87.943, 38.2578],
            [-87.9556, 38.3263],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1740470',
        NAME: 'Wabash Community Unit School District 348',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470350948,
        AWATER: 8150547,
        INTPTLAT: '+38.4307734',
        INTPTLON: '-087.8710958',
        ELSDLEA: '',
        UNSDLEA: '40470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9855, 39.7812],
            [-89.7435, 39.7866],
            [-89.7341, 39.7416],
            [-89.8148, 39.6544],
            [-89.8327, 39.6494],
            [-90.0077, 39.6815],
            [-89.9855, 39.7812],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727990',
        NAME: 'Community Unit School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296920712,
        AWATER: 205470,
        INTPTLAT: '+39.7173587',
        INTPTLON: '-089.8896216',
        ELSDLEA: '',
        UNSDLEA: '27990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9986, 39.8072],
            [-89.9757, 39.9098],
            [-89.7693, 39.9143],
            [-89.7205, 39.8547],
            [-89.7435, 39.7866],
            [-89.9855, 39.7812],
            [-89.9986, 39.8072],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1731920',
        NAME: 'Pleasant Plains Community Unit School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295063154,
        AWATER: 351767,
        INTPTLAT: '+39.8365837',
        INTPTLON: '-089.8787772',
        ELSDLEA: '',
        UNSDLEA: '31920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4508, 40.2403],
            [-90.4028, 40.2743],
            [-90.219, 40.305],
            [-90.2199, 40.184],
            [-90.4077, 40.1587],
            [-90.4508, 40.2403],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1704440',
        NAME: 'Astoria Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234445551,
        AWATER: 1449371,
        INTPTLAT: '+40.2055511',
        INTPTLON: '-090.3114309',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4192, 38.7409],
            [-89.2824, 38.7858],
            [-89.2543, 38.7488],
            [-89.1387, 38.7019],
            [-89.176, 38.6651],
            [-89.179, 38.5497],
            [-89.2456, 38.5081],
            [-89.2549, 38.509],
            [-89.3206, 38.5106],
            [-89.3889, 38.4943],
            [-89.4278, 38.4989],
            [-89.4276, 38.5611],
            [-89.4192, 38.7409],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1708460',
        NAME: 'Carlyle Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516698315,
        AWATER: 75842627,
        INTPTLAT: '+38.6294717',
        INTPTLON: '-089.2985642',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6393, 38.9991],
            [-89.6145, 39.0287],
            [-89.5486, 39.0354],
            [-89.4857, 38.9845],
            [-89.3991, 39.0019],
            [-89.354, 38.9502],
            [-89.3521, 38.829],
            [-89.2824, 38.7858],
            [-89.4192, 38.7409],
            [-89.5488, 38.7566],
            [-89.5579, 38.8589],
            [-89.6178, 38.889],
            [-89.6393, 38.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1717730',
        NAME: 'Bond County Community Unit School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 600112639,
        AWATER: 3191149,
        INTPTLAT: '+38.8838075',
        INTPTLON: '-089.4780778',
        ELSDLEA: '',
        UNSDLEA: '17730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5848, 39.2589],
            [-89.431, 39.2641],
            [-89.307, 39.2613],
            [-89.3066, 39.0863],
            [-89.299, 39.0282],
            [-89.3991, 39.0019],
            [-89.4857, 38.9845],
            [-89.5486, 39.0354],
            [-89.5859, 39.0937],
            [-89.5848, 39.2589],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1719200',
        NAME: 'Hillsboro Community Unit School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 598962617,
        AWATER: 9192920,
        INTPTLAT: '+39.1391107',
        INTPTLON: '-089.4574354',
        ELSDLEA: '',
        UNSDLEA: '19200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.299, 39.0282],
            [-89.2128, 38.9756],
            [-89.2553, 38.8634],
            [-89.1884, 38.8256],
            [-89.2543, 38.7488],
            [-89.2824, 38.7858],
            [-89.3521, 38.829],
            [-89.354, 38.9502],
            [-89.3991, 39.0019],
            [-89.299, 39.0282],
          ],
        ],
      },
      properties: {
        STATEFP: '17',
        SCSDLEA: '',
        GEOID: '1727450',
        NAME: 'Mulberry Grove Community Unit School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282580260,
        AWATER: 5618444,
        INTPTLAT: '+38.9104401',
        INTPTLON: '-089.2942714',
        ELSDLEA: '',
        UNSDLEA: '27450',
      },
    },
  ],
};

import types from '../types';

const initialState = {
  loading: false,
  showModal: false,
  error: '',
  sId: undefined,
  activate: undefined,
  shareLinkId: undefined,
};

const shareALinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SHARE_A_LINK_CLEAR:
      return {
        ...initialState,
      };
    case types.reducerTypes.SHARE_A_LINK_PENDING:
      return {
        ...initialState,
        loading: true,
        showModal: false,
      };
    case types.reducerTypes.SHARE_A_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        showModal: true,
        shareLinkId: action.payload.shareToken,
      };
    case types.reducerTypes.SHARE_A_LINK_ERROR:
      return {
        ...state,
        showModal: false,
        error: action.payload,
      };
    default:
  }
  return state;
};

export default shareALinkReducer;

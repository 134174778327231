export const ME = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6739, 44.3942],
            [-67.6473, 44.5222],
            [-67.64, 44.6175],
            [-67.5424, 44.6143],
            [-67.4209, 44.5943],
            [-67.3888, 44.5202],
            [-67.5157, 44.4196],
            [-67.6739, 44.3942],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '08400',
        GEOID: '2308400',
        NAME: 'Moosabec Community School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88405090,
        AWATER: 296686932,
        INTPTLAT: '+44.5059115',
        INTPTLON: '-067.5342418',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.2689, 44.3647],
            [-69.1876, 44.3046],
            [-69.1709, 44.2926],
            [-69.078, 44.3587],
            [-68.9789, 44.2709],
            [-69.0215, 44.2315],
            [-69.0047, 44.2031],
            [-69.0695, 44.107],
            [-69.1605, 44.1726],
            [-69.1966, 44.1992],
            [-69.2549, 44.2416],
            [-69.3466, 44.3083],
            [-69.2689, 44.3647],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '00053',
        GEOID: '2300053',
        NAME: 'Five Town Community School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341609094,
        AWATER: 75260553,
        INTPTLAT: '+44.2542007',
        INTPTLON: '-069.1379708',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5102, 44.298],
            [-68.4626, 44.3246],
            [-68.4711, 44.362],
            [-68.4397, 44.3847],
            [-68.4198, 44.3856],
            [-68.3431, 44.444],
            [-68.2476, 44.4526],
            [-68.1631, 44.3747],
            [-68.1661, 44.2917],
            [-68.2808, 44.2778],
            [-68.2648, 44.1931],
            [-68.2932, 44.185],
            [-68.4734, 44.2495],
            [-68.5102, 44.298],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '08500',
        GEOID: '2308500',
        NAME: 'Mount Desert Community School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283523740,
        AWATER: 216452334,
        INTPTLAT: '+44.3188034',
        INTPTLON: '-068.3232666',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4757, 44.007],
            [-69.4485, 44.057],
            [-69.364, 44.0129],
            [-69.3803, 43.9445],
            [-69.438, 43.9571],
            [-69.4757, 44.007],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '03480',
        GEOID: '2303480',
        NAME: 'Bremen',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42647102,
        AWATER: 29483360,
        INTPTLAT: '+43.9800698',
        INTPTLON: '-069.4303812',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.579, 44.1156],
            [-69.5178, 44.101],
            [-69.5195, 44.0584],
            [-69.5409, 44.0043],
            [-69.5471, 43.9952],
            [-69.563, 43.9798],
            [-69.6378, 44.0171],
            [-69.579, 44.1156],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '08760',
        GEOID: '2308760',
        NAME: 'Newcastle',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75245160,
        AWATER: 9108003,
        INTPTLAT: '+44.0522769',
        INTPTLON: '-069.5675223',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.5195, 44.0584],
            [-69.45, 44.0583],
            [-69.4485, 44.057],
            [-69.4757, 44.007],
            [-69.5409, 44.0043],
            [-69.5195, 44.0584],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '04830',
        GEOID: '2304830',
        NAME: 'Damariscotta',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32204515,
        AWATER: 5899813,
        INTPTLAT: '+44.0223510',
        INTPTLON: '-069.4907559',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.2689, 44.3647],
            [-69.1876, 44.3046],
            [-69.2549, 44.2416],
            [-69.3466, 44.3083],
            [-69.2689, 44.3647],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302510',
        NAME: 'Appleton',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84715932,
        AWATER: 1864235,
        INTPTLAT: '+44.2978558',
        INTPTLON: '-069.2704210',
        ELSDLEA: '02510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.4198, 44.3856],
            [-68.3431, 44.444],
            [-68.2476, 44.4526],
            [-68.1631, 44.3747],
            [-68.1661, 44.2917],
            [-68.3312, 44.3777],
            [-68.4198, 44.3856],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302850',
        NAME: 'Bar Harbor',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109404415,
        AWATER: 54057371,
        INTPTLAT: '+44.3974700',
        INTPTLON: '-068.2638076',
        ELSDLEA: '02850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6739, 44.3942],
            [-67.6473, 44.5222],
            [-67.5798, 44.5262],
            [-67.5157, 44.4196],
            [-67.6739, 44.3942],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302950',
        NAME: 'Beals',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14547139,
        AWATER: 110626986,
        INTPTLAT: '+44.4461687',
        INTPTLON: '-067.6028545',
        ELSDLEA: '02950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.579, 44.1156],
            [-69.5178, 44.101],
            [-69.5195, 44.0584],
            [-69.45, 44.0583],
            [-69.4485, 44.057],
            [-69.364, 44.0129],
            [-69.3803, 43.9445],
            [-69.438, 43.9571],
            [-69.4757, 44.007],
            [-69.5409, 44.0043],
            [-69.5471, 43.9952],
            [-69.563, 43.9798],
            [-69.6378, 44.0171],
            [-69.579, 44.1156],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306160',
        NAME: 'Great Salt Bay Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150096777,
        AWATER: 44491176,
        INTPTLAT: '+44.0223510',
        INTPTLON: '-069.4907559',
        ELSDLEA: '06160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.2549, 44.2416],
            [-69.1876, 44.3046],
            [-69.1709, 44.2926],
            [-69.1216, 44.2556],
            [-69.1966, 44.1992],
            [-69.2549, 44.2416],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306610',
        NAME: 'Hope',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56877134,
        AWATER: 4968364,
        INTPTLAT: '+44.2411781',
        INTPTLON: '-069.1885129',
        ELSDLEA: '06610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.64, 44.6175],
            [-67.5424, 44.6143],
            [-67.4209, 44.5943],
            [-67.3888, 44.5202],
            [-67.5157, 44.4196],
            [-67.5798, 44.5262],
            [-67.6473, 44.5222],
            [-67.64, 44.6175],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307100',
        NAME: 'Jonesport',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73857951,
        AWATER: 186059946,
        INTPTLAT: '+44.5607592',
        INTPTLON: '-067.4822337',
        ELSDLEA: '07100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.1709, 44.2926],
            [-69.078, 44.3587],
            [-68.9789, 44.2709],
            [-69.0215, 44.2315],
            [-69.1216, 44.2556],
            [-69.1709, 44.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307450',
        NAME: 'Lincolnville',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96729267,
        AWATER: 16329610,
        INTPTLAT: '+44.2954425',
        INTPTLON: '-069.0664245',
        ELSDLEA: '07450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.4711, 44.362],
            [-68.4397, 44.3847],
            [-68.4198, 44.3856],
            [-68.3312, 44.3777],
            [-68.1661, 44.2917],
            [-68.2808, 44.2778],
            [-68.384, 44.3157],
            [-68.4626, 44.3246],
            [-68.4711, 44.362],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308490',
        NAME: 'Mount Desert',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95527906,
        AWATER: 46599482,
        INTPTLAT: '+44.3336389',
        INTPTLON: '-068.3304054',
        ELSDLEA: '08490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.1216, 44.2556],
            [-69.0215, 44.2315],
            [-69.0047, 44.2031],
            [-69.0695, 44.107],
            [-69.1605, 44.1726],
            [-69.1966, 44.1992],
            [-69.1216, 44.2556],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311130',
        NAME: 'School Administrative District 28',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103286761,
        AWATER: 52098344,
        INTPTLAT: '+44.1926862',
        INTPTLON: '-069.0875866',
        ELSDLEA: '11130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.384, 44.3157],
            [-68.2808, 44.2778],
            [-68.2648, 44.1931],
            [-68.2932, 44.185],
            [-68.384, 44.3157],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312390',
        NAME: 'Southwest Harbor',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34995599,
        AWATER: 23767521,
        INTPTLAT: '+44.2574506',
        INTPTLON: '-068.3128193',
        ELSDLEA: '12390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5102, 44.298],
            [-68.4626, 44.3246],
            [-68.384, 44.3157],
            [-68.2932, 44.185],
            [-68.4734, 44.2495],
            [-68.5102, 44.298],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312960',
        NAME: 'Tremont',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43595820,
        AWATER: 92027960,
        INTPTLAT: '+44.2570242',
        INTPTLON: '-068.4582193',
        ELSDLEA: '12960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4823, 44.9942],
            [-69.3557, 45.0735],
            [-69.232, 45.0902],
            [-69.1094, 45.1072],
            [-69.0862, 45.0174],
            [-69.0624, 44.927],
            [-69.1835, 44.9108],
            [-69.2085, 45.0044],
            [-69.4754, 44.9682],
            [-69.4823, 44.9942],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314530',
        NAME: 'School Administrative District 46',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352230009,
        AWATER: 6936569,
        INTPTLAT: '+45.0085852',
        INTPTLON: '-069.2366328',
        ELSDLEA: '',
        UNSDLEA: '14530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.0008, 44.7738],
            [-69.8889, 44.7878],
            [-69.8596, 44.8872],
            [-69.7512, 44.9011],
            [-69.719, 44.7897],
            [-69.8697, 44.7709],
            [-69.9601, 44.6812],
            [-70.0008, 44.7738],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314560',
        NAME: 'School Administrative District 59',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215904797,
        AWATER: 9023420,
        INTPTLAT: '+44.7883815',
        INTPTLON: '-069.8575039',
        ELSDLEA: '',
        UNSDLEA: '14560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7394, 44.6953],
            [-70.6553, 44.5908],
            [-70.7327, 44.5609],
            [-70.661, 44.4572],
            [-70.5508, 44.4535],
            [-70.506, 44.3708],
            [-70.6856, 44.2805],
            [-70.7627, 44.3822],
            [-70.8677, 44.3883],
            [-70.8988, 44.4276],
            [-70.9042, 44.5514],
            [-70.8211, 44.5969],
            [-70.8605, 44.651],
            [-70.7394, 44.6953],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311670',
        NAME: 'School Administrative District 44',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701661883,
        AWATER: 10267112,
        INTPTLAT: '+44.4496339',
        INTPTLON: '-070.7615502',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0582, 44.9905],
            [-70.9475, 45.0016],
            [-70.9286, 44.9102],
            [-71.0494, 44.896],
            [-71.0582, 44.9905],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307440',
        NAME: 'Lincoln Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84600433,
        AWATER: 11511752,
        INTPTLAT: '+44.9511887',
        INTPTLON: '-070.9838118',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.0151, 44.3714],
              [-70.8677, 44.3883],
              [-70.7627, 44.3822],
              [-70.6856, 44.2805],
              [-70.7865, 44.227],
              [-70.8452, 44.3011],
              [-71.0096, 44.2733],
              [-71.01, 44.2848],
              [-71.0129, 44.3409],
              [-71.0151, 44.3714],
            ],
          ],
          [
            [
              [-71.0839, 45.3055],
              [-70.9892, 45.3342],
              [-70.8351, 45.2771],
              [-70.7798, 45.0184],
              [-70.8236, 45.0138],
              [-70.7739, 44.8397],
              [-70.7692, 44.7322],
              [-70.7394, 44.6953],
              [-70.8605, 44.651],
              [-70.8211, 44.5969],
              [-70.9042, 44.5514],
              [-70.8988, 44.4276],
              [-71.0173, 44.4152],
              [-71.0188, 44.4404],
              [-71.031, 44.6557],
              [-70.9318, 44.668],
              [-70.9382, 44.7746],
              [-70.9286, 44.9102],
              [-70.9475, 45.0016],
              [-71.0582, 44.9905],
              [-71.0595, 45.0149],
              [-71.0839, 45.3055],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382002',
        NAME: 'Oxford Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1608432744,
        AWATER: 98355638,
        INTPTLAT: '+44.8209987',
        INTPTLON: '-070.9026099',
        ELSDLEA: '',
        UNSDLEA: '82002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.7292, 45.3792],
            [-69.6019, 45.4006],
            [-69.5369, 45.41],
            [-69.5188, 45.3445],
            [-69.5752, 45.3096],
            [-69.7004, 45.2925],
            [-69.7292, 45.3792],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312180',
        NAME: 'Shirley',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138067207,
        AWATER: 2156888,
        INTPTLAT: '+45.3580396',
        INTPTLON: '-069.6302919',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.0341, 45.7655],
              [-68.0326, 45.8543],
              [-67.914, 45.8649],
              [-67.914, 45.7806],
              [-67.9374, 45.7732],
              [-68.0346, 45.7485],
              [-68.0341, 45.7655],
            ],
          ],
          [
            [
              [-68.0403, 46.1874],
              [-67.9038, 46.1896],
              [-67.9066, 46.0953],
              [-67.7812, 46.0962],
              [-67.796, 45.8654],
              [-67.914, 45.8649],
              [-67.9119, 45.9931],
              [-68.04, 46.0387],
              [-68.0403, 46.1874],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314762',
        NAME: 'School Administrative District 70',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 586174192,
        AWATER: 7868039,
        INTPTLAT: '+45.9654640',
        INTPTLON: '-067.8977687',
        ELSDLEA: '',
        UNSDLEA: '14762',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4545, 45.3536],
            [-69.3312, 45.3708],
            [-69.2993, 45.2606],
            [-69.4245, 45.2432],
            [-69.4545, 45.3536],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313740',
        NAME: 'Willimantic',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111842491,
        AWATER: 12247722,
        INTPTLAT: '+45.3031513',
        INTPTLON: '-069.3795593',
        ELSDLEA: '',
        UNSDLEA: '13740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9312, 47.0484],
            [-67.7902, 47.0501],
            [-67.7897, 46.9592],
            [-67.9293, 46.9579],
            [-67.9312, 47.0484],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304110',
        NAME: 'Caswell',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106973103,
        AWATER: 677458,
        INTPTLAT: '+47.0180629',
        INTPTLON: '-067.8630874',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5619, 46.7436],
            [-68.4401, 46.7449],
            [-68.4379, 46.6568],
            [-68.5613, 46.6552],
            [-68.5619, 46.7436],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308580',
        NAME: 'Nashville Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91203252,
        AWATER: 992295,
        INTPTLAT: '+46.6977998',
        INTPTLON: '-068.4958057',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.3005, 46.7452],
            [-68.0565, 46.7757],
            [-67.9261, 46.7788],
            [-67.9216, 46.6865],
            [-67.9089, 46.5971],
            [-67.9149, 46.5087],
            [-68.0442, 46.5062],
            [-68.0471, 46.5682],
            [-68.1736, 46.5652],
            [-68.1726, 46.6607],
            [-68.3007, 46.6578],
            [-68.3005, 46.7452],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310860',
        NAME: 'School Administrative District 01',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 580288147,
        AWATER: 8168384,
        INTPTLAT: '+46.6387240',
        INTPTLON: '-068.0686376',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7356, 44.1192],
            [-68.5482, 44.1295],
            [-68.4846, 44.0283],
            [-68.5473, 43.9686],
            [-68.7071, 43.9638],
            [-68.7356, 44.1192],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306780',
        NAME: 'Isle au Haut',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32428316,
        AWATER: 260750908,
        INTPTLAT: '+44.0465595',
        INTPTLON: '-068.6268884',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.0695, 44.107],
            [-69.0047, 44.2031],
            [-68.8964, 44.2099],
            [-68.7774, 44.1798],
            [-68.7356, 44.1192],
            [-68.8336, 44.1343],
            [-68.9937, 44.0937],
            [-69.0695, 44.107],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311820',
        NAME: 'School Administrative District 07',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30092369,
        AWATER: 183556007,
        INTPTLAT: '+44.1023330',
        INTPTLON: '-068.9534251',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.0573, 44.7615],
            [-67.9365, 44.7777],
            [-67.9148, 44.6953],
            [-68.0161, 44.6767],
            [-68.0573, 44.7615],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304880',
        NAME: 'Deblois',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92813959,
        AWATER: 646210,
        INTPTLAT: '+44.7267605',
        INTPTLON: '-067.9846151',
        ELSDLEA: '',
        UNSDLEA: '04880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.04, 44.9477],
            [-67.0355, 44.9488],
            [-67.0197, 44.9524],
            [-66.9808, 44.8739],
            [-67.0523, 44.9108],
            [-67.04, 44.9477],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305360',
        NAME: 'Eastport',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9402043,
        AWATER: 22546308,
        INTPTLAT: '+44.9109583',
        INTPTLON: '-067.0089131',
        ELSDLEA: '',
        UNSDLEA: '05360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5712, 45.4126],
            [-67.7146, 45.3908],
            [-67.8319, 45.3718],
            [-67.8688, 45.4933],
            [-67.6052, 45.5339],
            [-67.5712, 45.4126],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305380',
        NAME: 'East Range Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271320880,
        AWATER: 13960634,
        INTPTLAT: '+45.4584383',
        INTPTLON: '-067.7324646',
        ELSDLEA: '',
        UNSDLEA: '05380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6899, 45.3035],
            [-67.6611, 45.1923],
            [-67.7807, 45.171],
            [-67.8118, 45.2854],
            [-67.6899, 45.3035],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306150',
        NAME: 'Great Lake Stream Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113870201,
        AWATER: 11754718,
        INTPTLAT: '+45.2203109',
        INTPTLON: '-067.7544481',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.3942, 43.858],
            [-69.3219, 43.9252],
            [-69.2822, 44.0548],
            [-69.2191, 44.079],
            [-69.1605, 44.1726],
            [-69.0695, 44.107],
            [-68.9937, 44.0937],
            [-69.0601, 44.0503],
            [-69.1196, 43.9458],
            [-69.2366, 43.8256],
            [-69.2335, 43.778],
            [-69.3528, 43.8307],
            [-69.3942, 43.858],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314787',
        NAME: 'Regional School Unit 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228817064,
        AWATER: 308387371,
        INTPTLAT: '+43.9795990',
        INTPTLON: '-069.2160402',
        ELSDLEA: '',
        UNSDLEA: '14787',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6458, 45.1013],
            [-69.5192, 45.1196],
            [-69.5469, 45.2127],
            [-69.5506, 45.2253],
            [-69.4245, 45.2432],
            [-69.2993, 45.2606],
            [-69.232, 45.0902],
            [-69.3557, 45.0735],
            [-69.4823, 44.9942],
            [-69.621, 45.011],
            [-69.6458, 45.1013],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311730',
        NAME: 'School Administrative District 04',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 549673044,
        AWATER: 10998016,
        INTPTLAT: '+45.1290484',
        INTPTLON: '-069.4327614',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.507, 45.5353],
            [-69.5928, 45.5221],
            [-69.6002, 45.5209],
            [-69.6056, 45.5202],
            [-69.507, 45.5353],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302960',
        NAME: 'Beaver Cove',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82494661,
        AWATER: 2237663,
        INTPTLAT: '+45.5510186',
        INTPTLON: '-069.4785268',
        ELSDLEA: '',
        UNSDLEA: '02960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.9135, 45.2291],
              [-68.8809, 45.2249],
              [-68.8566, 45.1428],
              [-68.9735, 45.1272],
              [-69.0078, 45.2132],
              [-68.9135, 45.2291],
            ],
          ],
          [
            [
              [-69.7004, 45.2925],
              [-69.5752, 45.3096],
              [-69.5506, 45.2253],
              [-69.5469, 45.2127],
              [-69.6828, 45.1949],
              [-69.7004, 45.2925],
            ],
          ],
          [
            [
              [-69.7211, 46.5741],
              [-68.8214, 46.5725],
              [-68.8194, 46.3958],
              [-68.827, 45.6848],
              [-68.9589, 45.6622],
              [-68.9647, 45.5124],
              [-68.8573, 45.5273],
              [-68.8312, 45.4424],
              [-68.9659, 45.4233],
              [-69.0824, 45.4058],
              [-69.0521, 45.2954],
              [-69.1559, 45.2809],
              [-69.2137, 45.3877],
              [-69.3312, 45.3708],
              [-69.4545, 45.3536],
              [-69.5188, 45.3445],
              [-69.5369, 45.41],
              [-69.4732, 45.4198],
              [-69.507, 45.5353],
              [-69.6056, 45.5202],
              [-69.6002, 45.5209],
              [-69.5928, 45.5221],
              [-69.6019, 45.4006],
              [-69.7292, 45.3792],
              [-69.7799, 45.5428],
              [-69.7017, 45.628],
              [-69.7853, 45.6928],
              [-69.7043, 45.8461],
              [-69.6466, 45.864],
              [-69.7286, 45.9769],
              [-69.7323, 46.394],
              [-69.7211, 46.5741],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382009',
        NAME: 'Piscataquis Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8187392051,
        AWATER: 971288511,
        INTPTLAT: '+45.9783040',
        INTPTLON: '-069.2920522',
        ELSDLEA: '',
        UNSDLEA: '82009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6717, 44.6816],
            [-67.5325, 44.7191],
            [-67.4998, 44.6629],
            [-67.5424, 44.6143],
            [-67.64, 44.6175],
            [-67.6717, 44.6816],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306900',
        NAME: 'Jonesboro',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94733000,
        AWATER: 4621795,
        INTPTLAT: '+44.6655035',
        INTPTLON: '-067.5949703',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9601, 44.6812],
            [-69.8697, 44.7709],
            [-69.719, 44.7897],
            [-69.7512, 44.9011],
            [-69.7201, 44.905],
            [-69.615, 44.8798],
            [-69.5217, 44.8121],
            [-69.4834, 44.7203],
            [-69.7803, 44.6771],
            [-69.7443, 44.6026],
            [-69.9305, 44.6109],
            [-69.9601, 44.6812],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314590',
        NAME: 'School Administrative District 54',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 614583808,
        AWATER: 24754206,
        INTPTLAT: '+44.7408644',
        INTPTLON: '-069.7327930',
        ELSDLEA: '',
        UNSDLEA: '14590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.0044, 45.0537],
              [-69.9017, 45.0671],
              [-69.8744, 44.9776],
              [-70.1104, 44.9473],
              [-70.1334, 45.0367],
              [-70.0044, 45.0537],
            ],
          ],
          [
            [
              [-70.5529, 45.6679],
              [-70.418, 45.7133],
              [-70.4152, 45.7957],
              [-70.2845, 45.8717],
              [-70.3071, 46.0611],
              [-70.2371, 46.1449],
              [-70.2904, 46.1851],
              [-70.209, 46.3295],
              [-70.0565, 46.4168],
              [-70.0232, 46.5735],
              [-69.7211, 46.5741],
              [-69.7323, 46.394],
              [-69.7286, 45.9769],
              [-69.6466, 45.864],
              [-69.7043, 45.8461],
              [-69.7853, 45.6928],
              [-69.7017, 45.628],
              [-69.7799, 45.5428],
              [-69.7292, 45.3792],
              [-69.7004, 45.2925],
              [-69.6828, 45.1949],
              [-69.6548, 45.1],
              [-69.769, 45.0857],
              [-69.8112, 45.1773],
              [-69.8379, 45.2739],
              [-69.8745, 45.3559],
              [-69.9585, 45.3442],
              [-69.9286, 45.4124],
              [-70.1079, 45.4163],
              [-70.0805, 45.3308],
              [-69.9696, 45.3313],
              [-69.9911, 45.2521],
              [-69.9666, 45.1569],
              [-70.0292, 45.1475],
              [-70.1594, 45.1284],
              [-70.2938, 45.1099],
              [-70.4185, 45.144],
              [-70.443, 45.2366],
              [-70.5529, 45.6679],
            ],
            [
              [-70.418, 45.7133],
              [-70.4013, 45.621],
              [-70.2687, 45.641],
              [-70.2438, 45.5473],
              [-70.1177, 45.5637],
              [-70.1601, 45.7514],
              [-70.2921, 45.7309],
              [-70.418, 45.7133],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382008',
        NAME: 'Somerset Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6781448574,
        AWATER: 317066555,
        INTPTLAT: '+45.7713894',
        INTPTLON: '-070.0248172',
        ELSDLEA: '',
        UNSDLEA: '82008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9585, 45.3442],
            [-69.9696, 45.3313],
            [-70.0805, 45.3308],
            [-70.1079, 45.4163],
            [-69.9286, 45.4124],
            [-69.9585, 45.3442],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300058',
        NAME: 'West Forks Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126095654,
        AWATER: 1147072,
        INTPTLAT: '+45.3833246',
        INTPTLON: '-069.9992412',
        ELSDLEA: '',
        UNSDLEA: '00058',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0173, 44.4152],
            [-70.8988, 44.4276],
            [-70.8677, 44.3883],
            [-71.0151, 44.3714],
            [-71.0173, 44.4152],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305980',
        NAME: 'Gilead',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48893361,
        AWATER: 2046589,
        INTPTLAT: '+44.4025119',
        INTPTLON: '-070.9432192',
        ELSDLEA: '',
        UNSDLEA: '05980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5069, 44.7248],
            [-67.4324, 44.7292],
            [-67.4214, 44.7148],
            [-67.439, 44.6502],
            [-67.4735, 44.6527],
            [-67.5069, 44.7248],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307710',
        NAME: 'Machias',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35931545,
        AWATER: 2400115,
        INTPTLAT: '+44.6963013',
        INTPTLON: '-067.4442690',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5181, 44.8093],
            [-67.4324, 44.7292],
            [-67.5069, 44.7248],
            [-67.55, 44.8027],
            [-67.5181, 44.8093],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307980',
        NAME: 'Marshfield',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44064632,
        AWATER: 1372382,
        INTPTLAT: '+44.7503589',
        INTPTLON: '-067.4750197',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.4107, 45.0486],
            [-67.3745, 45.0597],
            [-67.2991, 45.0473],
            [-67.3507, 45.0067],
            [-67.4107, 45.0486],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308135',
        NAME: 'Meddybemps',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33951968,
        AWATER: 7666836,
        INTPTLAT: '+45.0485294',
        INTPTLON: '-067.3553452',
        ELSDLEA: '',
        UNSDLEA: '08135',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6941, 44.8487],
            [-67.561, 44.8851],
            [-67.5181, 44.8093],
            [-67.55, 44.8027],
            [-67.5796, 44.7952],
            [-67.701, 44.766],
            [-67.6941, 44.8487],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309090',
        NAME: 'Northfield',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112859037,
        AWATER: 5854267,
        INTPTLAT: '+44.8197738',
        INTPTLON: '-067.6151116',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.2559, 44.9784],
            [-67.1903, 45.0077],
            [-67.1858, 45.0097],
            [-67.0823, 44.9084],
            [-67.1098, 44.8916],
            [-67.1958, 44.9103],
            [-67.2559, 44.9784],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309560',
        NAME: 'Pembroke',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70855018,
        AWATER: 20457230,
        INTPTLAT: '+44.9168064',
        INTPTLON: '-067.1349576',
        ELSDLEA: '',
        UNSDLEA: '09560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.0829, 45.0555],
            [-67.0197, 44.9524],
            [-67.0355, 44.9488],
            [-67.0415, 44.9602],
            [-67.0511, 44.9538],
            [-67.04, 44.9477],
            [-67.0523, 44.9108],
            [-67.0823, 44.9084],
            [-67.1858, 45.0097],
            [-67.0829, 45.0555],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309650',
        NAME: 'Perry',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75811128,
        AWATER: 33113581,
        INTPTLAT: '+44.9835423',
        INTPTLON: '-067.1013820',
        ELSDLEA: '',
        UNSDLEA: '09650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9252, 44.3414],
            [-67.8738, 44.4551],
            [-67.9083, 44.5526],
            [-67.8699, 44.6287],
            [-67.8921, 44.6992],
            [-67.6906, 44.7302],
            [-67.6717, 44.6816],
            [-67.64, 44.6175],
            [-67.6473, 44.5222],
            [-67.6739, 44.3942],
            [-67.8056, 44.391],
            [-67.9252, 44.3414],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311430',
        NAME: 'School Administrative District 37',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384975590,
        AWATER: 353215014,
        INTPTLAT: '+44.5301051',
        INTPTLON: '-067.7656170',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9498, 43.7801],
            [-68.9109, 43.8377],
            [-68.7849, 43.8032],
            [-68.8269, 43.7357],
            [-68.9498, 43.7801],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382007',
        NAME: 'Knox Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1472741,
        AWATER: 121230924,
        INTPTLAT: '+43.7852320',
        INTPTLON: '-068.8540796',
        ELSDLEA: '',
        UNSDLEA: '82007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4513, 44.3139],
            [-69.4096, 44.328],
            [-69.3944, 44.3429],
            [-69.3466, 44.3083],
            [-69.2549, 44.2416],
            [-69.1966, 44.1992],
            [-69.1605, 44.1726],
            [-69.2191, 44.079],
            [-69.2822, 44.0548],
            [-69.3219, 43.9252],
            [-69.3942, 43.858],
            [-69.3981, 43.8689],
            [-69.3803, 43.9445],
            [-69.364, 44.0129],
            [-69.4485, 44.057],
            [-69.45, 44.0583],
            [-69.4322, 44.1577],
            [-69.4328, 44.2267],
            [-69.4513, 44.3139],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311550',
        NAME: 'School Administrative District 40',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 523862247,
        AWATER: 78739772,
        INTPTLAT: '+44.1363186',
        INTPTLON: '-069.3306797',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2292, 43.9741],
            [-70.1709, 44.021],
            [-70.1586, 44.0053],
            [-70.0518, 43.991],
            [-70.0342, 43.9757],
            [-70.0879, 43.919],
            [-70.0284, 43.8167],
            [-70.0698, 43.7708],
            [-70.0843, 43.781],
            [-70.1739, 43.8429],
            [-70.2486, 43.8983],
            [-70.2292, 43.9741],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314789',
        NAME: 'Regional School Unit 05',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248236064,
        AWATER: 32483649,
        INTPTLAT: '+43.8960633',
        INTPTLON: '-070.1245042',
        ELSDLEA: '',
        UNSDLEA: '14789',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.4486, 43.817],
            [-70.3781, 43.6974],
            [-70.392, 43.6557],
            [-70.4685, 43.6313],
            [-70.5466, 43.7132],
            [-70.4486, 43.817],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306060',
        NAME: 'Gorham',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131094668,
        AWATER: 1725384,
        INTPTLAT: '+43.7036174',
        INTPTLON: '-070.4637804',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7349, 44.1219],
            [-70.6403, 44.0105],
            [-70.4801, 44.0321],
            [-70.4486, 44.0094],
            [-70.5357, 43.864],
            [-70.6268, 43.8338],
            [-70.7248, 43.856],
            [-70.7298, 43.9283],
            [-70.7154, 43.9618],
            [-70.8218, 44.0771],
            [-70.7349, 44.1219],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314210',
        NAME: 'School Administrative District 61',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395389565,
        AWATER: 92623294,
        INTPTLAT: '+43.9757686',
        INTPTLON: '-070.6392975',
        ELSDLEA: '',
        UNSDLEA: '14210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7918, 44.9009],
            [-68.6719, 44.8664],
            [-68.6962, 44.8453],
            [-68.7209, 44.8637],
            [-68.7952, 44.8735],
            [-68.7918, 44.9009],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314778',
        NAME: 'Regional School Unit 26',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47073703,
        AWATER: 3645065,
        INTPTLAT: '+44.8816800',
        INTPTLON: '-068.7386440',
        ELSDLEA: '',
        UNSDLEA: '14778',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6171, 45.1345],
            [-68.5405, 45.1466],
            [-68.5167, 45.0394],
            [-68.6451, 45.0211],
            [-68.6171, 45.1345],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306180',
        NAME: 'Greenbush',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113436602,
        AWATER: 410449,
        INTPTLAT: '+45.0721779',
        INTPTLON: '-068.5893948',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9134, 44.8574],
            [-68.859, 44.8649],
            [-68.8364, 44.7775],
            [-68.9589, 44.7612],
            [-68.9134, 44.8574],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306510',
        NAME: 'Hermon',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92908871,
        AWATER: 2425604,
        INTPTLAT: '+44.8199595',
        INTPTLON: '-068.9177507',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.2128, 45.3386],
            [-68.0903, 45.3592],
            [-68.027, 45.3437],
            [-68.0026, 45.2598],
            [-68.055, 45.2526],
            [-68.1807, 45.2372],
            [-68.2128, 45.3386],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307200',
        NAME: 'Lakeville',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151133019,
        AWATER: 19257493,
        INTPTLAT: '+45.3028144',
        INTPTLON: '-068.0861434',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7533, 45.6654],
            [-68.6215, 45.6839],
            [-68.6829, 45.6125],
            [-68.7533, 45.6654],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308280',
        NAME: 'Millinocket',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41307640,
        AWATER: 5866971,
        INTPTLAT: '+45.6490930',
        INTPTLON: '-068.6844260',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7875, 44.7672],
            [-68.7351, 44.7401],
            [-68.7208, 44.6997],
            [-68.8158, 44.6838],
            [-68.7875, 44.7672],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309330',
        NAME: 'Orrington',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64726302,
        AWATER: 6067965,
        INTPTLAT: '+44.6887779',
        INTPTLON: '-068.7628054',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.0624, 44.927],
            [-68.9204, 44.8842],
            [-68.9134, 44.8574],
            [-68.9589, 44.7612],
            [-69.0814, 44.7458],
            [-69.1027, 44.8325],
            [-69.0624, 44.927],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310980',
        NAME: 'School Administrative District 23',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172474028,
        AWATER: 975164,
        INTPTLAT: '+44.8346539',
        INTPTLON: '-069.0094224',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.3632, 45.4955],
            [-68.228, 45.5154],
            [-68.1485, 45.5328],
            [-68.1439, 45.5336],
            [-68.1156, 45.4459],
            [-68.0903, 45.3592],
            [-68.2128, 45.3386],
            [-68.3411, 45.318],
            [-68.3686, 45.4061],
            [-68.4425, 45.4383],
            [-68.3632, 45.4955],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311220',
        NAME: 'School Administrative District 30',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407890609,
        AWATER: 3482939,
        INTPTLAT: '+45.4122412',
        INTPTLON: '-068.2533363',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.5506, 44.927],
            [-69.4754, 44.9682],
            [-69.2085, 45.0044],
            [-69.1835, 44.9108],
            [-69.1027, 44.8325],
            [-69.0814, 44.7458],
            [-69.061, 44.6639],
            [-69.1837, 44.644],
            [-69.2668, 44.7219],
            [-69.2863, 44.808],
            [-69.5217, 44.8121],
            [-69.615, 44.8798],
            [-69.5506, 44.927],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314785',
        NAME: 'Regional School Unit 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728012638,
        AWATER: 49961944,
        INTPTLAT: '+44.8520328',
        INTPTLON: '-069.3071834',
        ELSDLEA: '',
        UNSDLEA: '14785',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5345, 45.5594],
            [-68.4335, 45.5759],
            [-68.3709, 45.4943],
            [-68.5064, 45.4722],
            [-68.5345, 45.5594],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314040',
        NAME: 'Woodville',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110677056,
        AWATER: 0,
        INTPTLAT: '+45.5253867',
        INTPTLON: '-068.4498515',
        ELSDLEA: '',
        UNSDLEA: '14040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-69.0862, 45.0174],
              [-68.9582, 45.0338],
              [-68.9836, 45.1258],
              [-68.9735, 45.1272],
              [-68.8566, 45.1428],
              [-68.8423, 45.0951],
              [-68.8078, 44.9612],
              [-68.9204, 44.8842],
              [-69.0624, 44.927],
              [-69.0862, 45.0174],
            ],
          ],
          [
            [
              [-69.1835, 44.9108],
              [-69.0624, 44.927],
              [-69.1027, 44.8325],
              [-69.1835, 44.9108],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314440',
        NAME: 'School Administrative District 64',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442464846,
        AWATER: 10472697,
        INTPTLAT: '+44.9821853',
        INTPTLON: '-068.9787733',
        ELSDLEA: '',
        UNSDLEA: '14440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6987, 44.8215],
            [-68.6962, 44.8453],
            [-68.6719, 44.8664],
            [-68.5599, 44.8176],
            [-68.473, 44.8708],
            [-68.4549, 44.8015],
            [-68.4452, 44.763],
            [-68.5285, 44.7522],
            [-68.6909, 44.7217],
            [-68.7208, 44.6997],
            [-68.7351, 44.7401],
            [-68.6987, 44.8215],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314470',
        NAME: 'School Administrative District 63',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235277062,
        AWATER: 10699336,
        INTPTLAT: '+44.7939792',
        INTPTLON: '-068.5913084',
        ELSDLEA: '',
        UNSDLEA: '14470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5194, 45.2912],
            [-68.4653, 45.2967],
            [-68.421, 45.1729],
            [-68.5428, 45.1555],
            [-68.5194, 45.2912],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300063',
        NAME: 'Lowell',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99188851,
        AWATER: 4994273,
        INTPTLAT: '+45.2140279',
        INTPTLON: '-068.5074293',
        ELSDLEA: '',
        UNSDLEA: '00063',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.4185, 45.144],
              [-70.2938, 45.1099],
              [-70.4059, 45.0949],
              [-70.4172, 45.1391],
              [-70.4185, 45.144],
            ],
          ],
          [
            [
              [-70.7739, 44.8397],
              [-70.4874, 44.87],
              [-70.5215, 45.0465],
              [-70.3987, 45.0656],
              [-70.2716, 45.0206],
              [-70.2459, 44.9299],
              [-70.1521, 44.9418],
              [-70.1299, 44.8508],
              [-70.1556, 44.8469],
              [-70.2546, 44.8355],
              [-70.3489, 44.9044],
              [-70.4853, 44.8487],
              [-70.4423, 44.7644],
              [-70.5638, 44.7132],
              [-70.615, 44.7956],
              [-70.7692, 44.7322],
              [-70.7739, 44.8397],
            ],
          ],
          [
            [
              [-70.5529, 45.6679],
              [-70.443, 45.2366],
              [-70.5664, 45.221],
              [-70.541, 45.1254],
              [-70.5215, 45.0465],
              [-70.7798, 45.0184],
              [-70.8351, 45.2771],
              [-70.7941, 45.4284],
              [-70.6245, 45.4061],
              [-70.7224, 45.5139],
              [-70.5529, 45.6679],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382003',
        NAME: 'Franklin Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2089447673,
        AWATER: 25207575,
        INTPTLAT: '+45.1651767',
        INTPTLON: '-070.5704763',
        ELSDLEA: '',
        UNSDLEA: '82003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.5638, 44.7132],
            [-70.4423, 44.7644],
            [-70.3559, 44.718],
            [-70.2369, 44.7572],
            [-70.1556, 44.8469],
            [-70.1299, 44.8508],
            [-70.0333, 44.8645],
            [-70.0008, 44.7738],
            [-69.9601, 44.6812],
            [-69.9305, 44.6109],
            [-69.9441, 44.5378],
            [-70.0423, 44.4852],
            [-70.1294, 44.4872],
            [-70.1525, 44.5893],
            [-70.2807, 44.5696],
            [-70.3322, 44.6089],
            [-70.398, 44.6739],
            [-70.5106, 44.6272],
            [-70.5638, 44.7132],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311880',
        NAME: 'School Administrative District 09',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 943278042,
        AWATER: 26548290,
        INTPTLAT: '+44.6810326',
        INTPTLON: '-070.1814039',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.4374, 46.2075],
            [-68.0402, 46.2075],
            [-68.0403, 46.1874],
            [-68.04, 46.0387],
            [-68.1672, 46.0376],
            [-68.167, 45.9438],
            [-68.2971, 45.9442],
            [-68.2975, 45.8515],
            [-68.4266, 45.8534],
            [-68.5586, 45.8555],
            [-68.5634, 46.1252],
            [-68.4363, 46.1247],
            [-68.4374, 46.2075],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314806',
        NAME: 'Regional School Unit 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1170793084,
        AWATER: 22263605,
        INTPTLAT: '+46.0536199',
        INTPTLON: '-068.3290581',
        ELSDLEA: '',
        UNSDLEA: '14806',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2807, 44.5696],
            [-70.1525, 44.5893],
            [-70.1294, 44.4872],
            [-70.1005, 44.3839],
            [-70.1326, 44.3714],
            [-70.2652, 44.3629],
            [-70.2807, 44.5696],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314805',
        NAME: 'Regional School Unit 73',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273721728,
        AWATER: 8622141,
        INTPTLAT: '+44.4681943',
        INTPTLON: '-070.1990985',
        ELSDLEA: '',
        UNSDLEA: '14805',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.7798, 45.0184],
              [-70.5215, 45.0465],
              [-70.4874, 44.87],
              [-70.7739, 44.8397],
              [-70.8236, 45.0138],
              [-70.7798, 45.0184],
            ],
          ],
          [
            [
              [-71.0494, 44.896],
              [-70.9286, 44.9102],
              [-70.9382, 44.7746],
              [-71.0393, 44.7779],
              [-71.0433, 44.8286],
              [-71.0468, 44.8635],
              [-71.0494, 44.896],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314803',
        NAME: 'Regional School Unit 78',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 526989331,
        AWATER: 76143654,
        INTPTLAT: '+44.9207410',
        INTPTLON: '-070.7342451',
        ELSDLEA: '',
        UNSDLEA: '14803',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.29, 44.1991],
            [-70.1998, 44.1675],
            [-70.2239, 44.092],
            [-70.1709, 44.021],
            [-70.2292, 43.9741],
            [-70.3148, 44.0374],
            [-70.29, 44.1991],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302610',
        NAME: 'Auburn',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153664246,
        AWATER: 16601169,
        INTPTLAT: '+44.0845366',
        INTPTLON: '-070.2496493',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.769, 45.0857],
            [-69.6548, 45.1],
            [-69.6458, 45.1013],
            [-69.621, 45.011],
            [-69.7454, 44.9945],
            [-69.7504, 44.9938],
            [-69.769, 45.0857],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314811',
        NAME: 'Brighton Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100659635,
        AWATER: 1828118,
        INTPTLAT: '+45.0661839',
        INTPTLON: '-069.7057705',
        ELSDLEA: '',
        UNSDLEA: '14811',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.0161, 44.6767],
            [-67.9148, 44.6953],
            [-67.8921, 44.6992],
            [-67.8699, 44.6287],
            [-67.9083, 44.5526],
            [-67.9936, 44.5922],
            [-68.0161, 44.6767],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314814',
        NAME: 'Cherryfield',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115091619,
        AWATER: 1423540,
        INTPTLAT: '+44.6312690',
        INTPTLON: '-067.9271202',
        ELSDLEA: '',
        UNSDLEA: '14814',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.443, 45.2366],
            [-70.4185, 45.144],
            [-70.4172, 45.1391],
            [-70.541, 45.1254],
            [-70.5664, 45.221],
            [-70.443, 45.2366],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314818',
        NAME: 'Eustis',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101323185,
        AWATER: 6076872,
        INTPTLAT: '+45.1815349',
        INTPTLON: '-070.5079502',
        ELSDLEA: '',
        UNSDLEA: '14818',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9204, 44.8842],
            [-68.8078, 44.9612],
            [-68.7918, 44.9009],
            [-68.7952, 44.8735],
            [-68.859, 44.8649],
            [-68.9134, 44.8574],
            [-68.9204, 44.8842],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306000',
        NAME: 'Glenburn',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70345774,
        AWATER: 5130856,
        INTPTLAT: '+44.8926944',
        INTPTLON: '-068.8453277',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7209, 44.8637],
            [-68.6962, 44.8453],
            [-68.6987, 44.8215],
            [-68.7187, 44.8204],
            [-68.7209, 44.8637],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313140',
        NAME: 'Veazie',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7834727,
        AWATER: 494435,
        INTPTLAT: '+44.8474416',
        INTPTLON: '-068.7141887',
        ELSDLEA: '',
        UNSDLEA: '13140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.8612, 44.2967],
            [-68.7042, 44.302],
            [-68.6322, 44.2704],
            [-68.5169, 44.1927],
            [-68.5482, 44.1295],
            [-68.7356, 44.1192],
            [-68.7774, 44.1798],
            [-68.8964, 44.2099],
            [-68.8612, 44.2967],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304895',
        NAME: 'Deer Isle-Stonington Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102415551,
        AWATER: 315893815,
        INTPTLAT: '+44.2178288',
        INTPTLON: '-068.7015979',
        ELSDLEA: '',
        UNSDLEA: '04895',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5285, 44.7522],
            [-68.4452, 44.763],
            [-68.4187, 44.6661],
            [-68.5029, 44.655],
            [-68.5285, 44.7522],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309390',
        NAME: 'Otis',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64162183,
        AWATER: 9856638,
        INTPTLAT: '+44.7215064',
        INTPTLON: '-068.4900967',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.0355, 44.9488],
            [-67.04, 44.9477],
            [-67.0511, 44.9538],
            [-67.0415, 44.9602],
            [-67.0355, 44.9488],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300003',
        NAME: 'Pleasant Point',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1467673,
        AWATER: 1050202,
        INTPTLAT: '+44.9549298',
        INTPTLON: '-067.0430846',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.0346, 45.7485],
            [-67.9374, 45.7732],
            [-67.9335, 45.6591],
            [-68.0349, 45.6422],
            [-68.0346, 45.7485],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302790',
        NAME: 'Bancroft',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104720501,
        AWATER: 1440324,
        INTPTLAT: '+45.6918268',
        INTPTLON: '-067.9691571',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.179, 47.0205],
            [-68.0632, 47.0228],
            [-68.0629, 46.9556],
            [-68.0623, 46.9314],
            [-68.1787, 46.9289],
            [-68.179, 47.0205],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308700',
        NAME: 'New Sweden',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89538043,
        AWATER: 74880,
        INTPTLAT: '+46.9763913',
        INTPTLON: '-068.1149617',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.7998, 44.0786],
            [-69.7901, 44.0429],
            [-69.8135, 44.0268],
            [-69.7998, 44.0786],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382004',
        NAME: 'Sagadahoc Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5960357,
        AWATER: 3719563,
        INTPTLAT: '+44.0608975',
        INTPTLON: '-069.7997483',
        ELSDLEA: '',
        UNSDLEA: '82004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.7454, 44.9945],
            [-69.621, 45.011],
            [-69.5506, 44.927],
            [-69.615, 44.8798],
            [-69.7201, 44.905],
            [-69.7454, 44.9945],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314817',
        NAME: 'Athens',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112920218,
        AWATER: 17853,
        INTPTLAT: '+44.9652597',
        INTPTLON: '-069.6669351',
        ELSDLEA: '',
        UNSDLEA: '14817',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.1098, 44.8916],
            [-67.0823, 44.9084],
            [-67.0523, 44.9108],
            [-66.9808, 44.8739],
            [-66.8854, 44.7942],
            [-67.0108, 44.7173],
            [-67.1098, 44.8916],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310830',
        NAME: 'School Administrative District 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86096951,
        AWATER: 116898234,
        INTPTLAT: '+44.8054011',
        INTPTLON: '-067.0317838',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5192, 45.5876],
            [-67.4311, 45.5841],
            [-67.4247, 45.504],
            [-67.5192, 45.5876],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313080',
        NAME: 'Vanceboro',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52165402,
        AWATER: 5855455,
        INTPTLAT: '+45.5731592',
        INTPTLON: '-067.4706505',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.7146, 45.3908],
            [-67.5712, 45.4126],
            [-67.5508, 45.3199],
            [-67.6899, 45.3035],
            [-67.7146, 45.3908],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313200',
        NAME: 'Waite',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113916062,
        AWATER: 159345,
        INTPTLAT: '+45.3352168',
        INTPTLON: '-067.6049881',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5796, 44.7952],
            [-67.55, 44.8027],
            [-67.5069, 44.7248],
            [-67.4735, 44.6527],
            [-67.4998, 44.6629],
            [-67.5325, 44.7191],
            [-67.5796, 44.7952],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313710',
        NAME: 'Whitneyville',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38546736,
        AWATER: 813385,
        INTPTLAT: '+44.7571515',
        INTPTLON: '-067.5398967',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5181, 44.8093],
            [-67.4035, 44.8436],
            [-67.3621, 44.768],
            [-67.3382, 44.7244],
            [-67.4214, 44.7148],
            [-67.4324, 44.7292],
            [-67.5181, 44.8093],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300062',
        NAME: 'East Machias',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90149948,
        AWATER: 13490655,
        INTPTLAT: '+44.7761526',
        INTPTLON: '-067.4165195',
        ELSDLEA: '',
        UNSDLEA: '00062',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.2968, 44.9604],
            [-67.2559, 44.9784],
            [-67.1958, 44.9103],
            [-67.2968, 44.9604],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304900',
        NAME: 'Dennysville',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38632454,
        AWATER: 923215,
        INTPTLAT: '+44.9238739',
        INTPTLON: '-067.2459670',
        ELSDLEA: '',
        UNSDLEA: '04900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.478, 44.8891],
            [-68.3493, 44.9054],
            [-68.3759, 44.9974],
            [-68.2464, 45.0105],
            [-68.181, 44.7429],
            [-68.3034, 44.7306],
            [-68.3262, 44.817],
            [-68.4549, 44.8015],
            [-68.473, 44.8708],
            [-68.478, 44.8891],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302500',
        NAME: 'Airline Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388365236,
        AWATER: 17750371,
        INTPTLAT: '+44.8938805',
        INTPTLON: '-068.3038320',
        ELSDLEA: '',
        UNSDLEA: '02500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5995, 44.4931],
            [-68.4806, 44.443],
            [-68.4711, 44.362],
            [-68.4626, 44.3246],
            [-68.5102, 44.298],
            [-68.578, 44.3447],
            [-68.6967, 44.4063],
            [-68.5995, 44.4931],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303210',
        NAME: 'Blue Hill',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161817137,
        AWATER: 62380879,
        INTPTLAT: '+44.4021376',
        INTPTLON: '-068.5277179',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.578, 44.3447],
            [-68.5102, 44.298],
            [-68.4734, 44.2495],
            [-68.493, 44.2158],
            [-68.5169, 44.1927],
            [-68.6322, 44.2704],
            [-68.578, 44.3447],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303610',
        NAME: 'Brooklin',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46423730,
        AWATER: 60238232,
        INTPTLAT: '+44.2583531',
        INTPTLON: '-068.5463234',
        ELSDLEA: '',
        UNSDLEA: '03610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.574, 45.1147],
            [-67.4547, 45.1495],
            [-67.3891, 45.0759],
            [-67.3745, 45.0597],
            [-67.4107, 45.0486],
            [-67.5034, 45.0231],
            [-67.5602, 45.0865],
            [-67.574, 45.1147],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302280',
        NAME: 'Alexander',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103954223,
        AWATER: 14258593,
        INTPTLAT: '+45.0855964',
        INTPTLON: '-067.4755059',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.3891, 45.0759],
            [-67.3409, 45.1251],
            [-67.3056, 45.1448],
            [-67.2427, 45.072],
            [-67.2991, 45.0473],
            [-67.3745, 45.0597],
            [-67.3891, 45.0759],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302880',
        NAME: 'Baring Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54265049,
        AWATER: 8720348,
        INTPTLAT: '+45.0924148',
        INTPTLON: '-067.3051518',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.3507, 45.0067],
            [-67.2991, 45.0473],
            [-67.2427, 45.072],
            [-67.1903, 45.0077],
            [-67.2559, 44.9784],
            [-67.2968, 44.9604],
            [-67.3345, 44.9771],
            [-67.3507, 45.0067],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304160',
        NAME: 'Charlotte',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80254161,
        AWATER: 7154665,
        INTPTLAT: '+45.0108217',
        INTPTLON: '-067.2730042',
        ELSDLEA: '',
        UNSDLEA: '04160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5034, 45.0231],
            [-67.4107, 45.0486],
            [-67.3507, 45.0067],
            [-67.3345, 44.9771],
            [-67.4576, 44.9424],
            [-67.4956, 45.0096],
            [-67.5034, 45.0231],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304440',
        NAME: 'Cooper',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79536384,
        AWATER: 4824486,
        INTPTLAT: '+44.9889651',
        INTPTLON: '-067.4228789',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.8423, 45.0951],
            [-68.7731, 45.1066],
            [-68.6502, 45.003],
            [-68.648, 44.9434],
            [-68.4918, 44.944],
            [-68.478, 44.8891],
            [-68.473, 44.8708],
            [-68.5599, 44.8176],
            [-68.6719, 44.8664],
            [-68.7918, 44.9009],
            [-68.8078, 44.9612],
            [-68.8423, 45.0951],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314782',
        NAME: 'Regional School Unit 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338403624,
        AWATER: 15031414,
        INTPTLAT: '+44.9498274',
        INTPTLON: '-068.6755657',
        ELSDLEA: '',
        UNSDLEA: '14782',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6451, 45.0211],
            [-68.5167, 45.0394],
            [-68.5011, 44.9807],
            [-68.4918, 44.944],
            [-68.648, 44.9434],
            [-68.6451, 45.0211],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308250',
        NAME: 'Milford',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118172317,
        AWATER: 431488,
        INTPTLAT: '+44.9933779',
        INTPTLON: '-068.5759447',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6701, 43.3912],
            [-70.6426, 43.4204],
            [-70.5695, 43.4963],
            [-70.5653, 43.4943],
            [-70.3954, 43.4107],
            [-70.3462, 43.3582],
            [-70.493, 43.2868],
            [-70.5648, 43.3675],
            [-70.6701, 43.3912],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314773',
        NAME: 'Regional School Unit 21',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205815461,
        AWATER: 97491761,
        INTPTLAT: '+43.3847035',
        INTPTLON: '-070.4967112',
        ELSDLEA: '',
        UNSDLEA: '14773',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.492, 43.6068],
            [-70.2221, 43.4669],
            [-70.2593, 43.4324],
            [-70.4526, 43.4995],
            [-70.5653, 43.4943],
            [-70.5695, 43.4963],
            [-70.6343, 43.566],
            [-70.492, 43.6068],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314784',
        NAME: 'Regional School Unit 23',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165212713,
        AWATER: 77450488,
        INTPTLAT: '+43.5240178',
        INTPTLON: '-070.4379134',
        ELSDLEA: '',
        UNSDLEA: '14784',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7242, 43.3349],
            [-70.6701, 43.3912],
            [-70.5648, 43.3675],
            [-70.493, 43.2868],
            [-70.5094, 43.2078],
            [-70.6529, 43.2609],
            [-70.7093, 43.3024],
            [-70.7242, 43.3349],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313490',
        NAME: 'Wells-Ogunquit Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159892138,
        AWATER: 70241246,
        INTPTLAT: '+43.3075878',
        INTPTLON: '-070.6088974',
        ELSDLEA: '',
        UNSDLEA: '13490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9793, 43.6732],
            [-70.8381, 43.6754],
            [-70.7763, 43.7456],
            [-70.6923, 43.6745],
            [-70.6343, 43.566],
            [-70.5695, 43.4963],
            [-70.6426, 43.4204],
            [-70.7767, 43.4762],
            [-70.8628, 43.4702],
            [-70.9085, 43.5976],
            [-70.9752, 43.6144],
            [-70.9793, 43.6732],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314670',
        NAME: 'School Administrative District 57',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569185028,
        AWATER: 22444892,
        INTPTLAT: '+43.5665763',
        INTPTLON: '-070.7591238',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9752, 43.6144],
            [-70.9085, 43.5976],
            [-70.8628, 43.4702],
            [-70.8721, 43.4691],
            [-70.9697, 43.4806],
            [-70.9622, 43.5411],
            [-70.9752, 43.6144],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302220',
        NAME: 'Acton',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97673585,
        AWATER: 8802481,
        INTPTLAT: '+43.5366467',
        INTPTLON: '-070.9402579',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2593, 43.4324],
            [-70.3462, 43.3582],
            [-70.3954, 43.4107],
            [-70.5653, 43.4943],
            [-70.4526, 43.4995],
            [-70.2593, 43.4324],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303150',
        NAME: 'Biddeford',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77918025,
        AWATER: 75101175,
        INTPTLAT: '+43.4387328',
        INTPTLON: '-070.3929427',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8628, 43.4702],
            [-70.7767, 43.4762],
            [-70.6426, 43.4204],
            [-70.6701, 43.3912],
            [-70.7242, 43.3349],
            [-70.8721, 43.4691],
            [-70.8628, 43.4702],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310500',
        NAME: 'Sanford',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123755837,
        AWATER: 2518077,
        INTPTLAT: '+43.4241422',
        INTPTLON: '-070.7578593',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9697, 43.4806],
            [-70.8721, 43.4691],
            [-70.7242, 43.3349],
            [-70.7093, 43.3024],
            [-70.8176, 43.2377],
            [-70.8389, 43.2445],
            [-70.8987, 43.2874],
            [-70.9857, 43.3792],
            [-70.9697, 43.4806],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314700',
        NAME: 'School Administrative District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338076263,
        AWATER: 3380390,
        INTPTLAT: '+43.3569111',
        INTPTLON: '-070.8581991',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7812, 44.7783],
            [-68.7187, 44.8204],
            [-68.6987, 44.8215],
            [-68.7351, 44.7401],
            [-68.7875, 44.7672],
            [-68.7812, 44.7783],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303510',
        NAME: 'Brewer',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39455700,
        AWATER: 1168416,
        INTPTLAT: '+44.7847539',
        INTPTLON: '-068.7350119',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.3312, 45.3708],
            [-69.2137, 45.3877],
            [-69.1559, 45.2809],
            [-69.2993, 45.2606],
            [-69.3312, 45.3708],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303390',
        NAME: 'Bowerbank',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108624472,
        AWATER: 14171781,
        INTPTLAT: '+45.3190981',
        INTPTLON: '-069.2387378',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.8747, 43.9536],
            [-69.8135, 44.026],
            [-69.7463, 44.0138],
            [-69.7435, 43.8935],
            [-69.7886, 43.7994],
            [-69.7856, 43.6579],
            [-69.8819, 43.6365],
            [-69.896, 43.8662],
            [-69.8747, 43.9536],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314772',
        NAME: 'Regional School Unit 01',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239813837,
        AWATER: 153365561,
        INTPTLAT: '+43.8462729',
        INTPTLON: '-069.8249002',
        ELSDLEA: '',
        UNSDLEA: '14772',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.7435, 43.8935],
            [-69.6921, 43.8572],
            [-69.6906, 43.8542],
            [-69.6647, 43.7117],
            [-69.7856, 43.6579],
            [-69.7886, 43.7994],
            [-69.7435, 43.8935],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305970',
        NAME: 'Georgetown',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48128867,
        AWATER: 119062134,
        INTPTLAT: '+43.7084052',
        INTPTLON: '-069.7578991',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.0215, 44.2315],
            [-68.9789, 44.2709],
            [-68.8531, 44.4124],
            [-68.8439, 44.3547],
            [-68.8612, 44.2967],
            [-68.8964, 44.2099],
            [-69.0047, 44.2031],
            [-69.0215, 44.2315],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306810',
        NAME: 'Islesboro',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37009083,
        AWATER: 141391358,
        INTPTLAT: '+44.2988796',
        INTPTLON: '-068.9152501',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4095, 44.5789],
            [-69.3931, 44.6404],
            [-69.2668, 44.7219],
            [-69.1837, 44.644],
            [-69.061, 44.6639],
            [-68.9878, 44.5797],
            [-69.0654, 44.5247],
            [-69.1183, 44.4298],
            [-69.2018, 44.4648],
            [-69.2689, 44.3647],
            [-69.3466, 44.3083],
            [-69.3944, 44.3429],
            [-69.396, 44.4571],
            [-69.4095, 44.5789],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311520',
        NAME: 'School Administrative District 03',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 846598982,
        AWATER: 19799679,
        INTPTLAT: '+44.5440181',
        INTPTLON: '-069.2314038',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.3008, 45.5905],
              [-68.2575, 45.5979],
              [-68.228, 45.5154],
              [-68.3632, 45.4955],
              [-68.4109, 45.5733],
              [-68.3008, 45.5905],
            ],
          ],
          [
            [
              [-68.6003, 45.3277],
              [-68.4425, 45.4383],
              [-68.3686, 45.4061],
              [-68.3411, 45.318],
              [-68.4653, 45.2967],
              [-68.5194, 45.2912],
              [-68.6003, 45.3277],
            ],
          ],
          [
            [
              [-68.5064, 45.4722],
              [-68.3709, 45.4943],
              [-68.5474, 45.3666],
              [-68.5875, 45.46],
              [-68.5064, 45.4722],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314760',
        NAME: 'School Administrative District 67',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392099185,
        AWATER: 18456619,
        INTPTLAT: '+45.4204037',
        INTPTLON: '-068.4241052',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-69.1094, 45.1072],
              [-68.9836, 45.1258],
              [-68.9582, 45.0338],
              [-69.0862, 45.0174],
              [-69.1094, 45.1072],
            ],
          ],
          [
            [
              [-69.1559, 45.2809],
              [-69.0521, 45.2954],
              [-69.0295, 45.2132],
              [-69.1322, 45.1968],
              [-69.1094, 45.1072],
              [-69.232, 45.0902],
              [-69.2993, 45.2606],
              [-69.1559, 45.2809],
            ],
          ],
          [
            [
              [-69.5188, 45.3445],
              [-69.4545, 45.3536],
              [-69.4245, 45.2432],
              [-69.5506, 45.2253],
              [-69.5752, 45.3096],
              [-69.5188, 45.3445],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314761',
        NAME: 'School Administrative District 68',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496844605,
        AWATER: 17645084,
        INTPTLAT: '+45.2050709',
        INTPTLON: '-069.2232147',
        ELSDLEA: '',
        UNSDLEA: '14761',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.8254, 45.4206],
            [-68.7016, 45.4409],
            [-68.6965, 45.343],
            [-68.8009, 45.3291],
            [-68.8254, 45.4206],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300057',
        NAME: 'Seboeis Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103631849,
        AWATER: 4611972,
        INTPTLAT: '+45.3926023',
        INTPTLON: '-068.7575160',
        ELSDLEA: '',
        UNSDLEA: '00057',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9385, 44.5652],
            [-68.8625, 44.5937],
            [-68.8158, 44.6838],
            [-68.7208, 44.6997],
            [-68.6909, 44.7217],
            [-68.6245, 44.6387],
            [-68.5959, 44.5569],
            [-68.6255, 44.5408],
            [-68.7925, 44.4937],
            [-68.8052, 44.549],
            [-68.9385, 44.5652],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314796',
        NAME: 'Regional School Unit 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318335187,
        AWATER: 40027189,
        INTPTLAT: '+44.5849393',
        INTPTLON: '-068.7646924',
        ELSDLEA: '',
        UNSDLEA: '14796',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.8531, 44.4124],
            [-68.801, 44.462],
            [-68.7628, 44.4248],
            [-68.8439, 44.3547],
            [-68.8531, 44.4124],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304080',
        NAME: 'Castine',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20140012,
        AWATER: 31684539,
        INTPTLAT: '+44.4090064',
        INTPTLON: '-068.8155328',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.2808, 44.2778],
            [-68.1661, 44.2917],
            [-68.1278, 44.2644],
            [-68.1707, 44.1899],
            [-68.2648, 44.1931],
            [-68.2808, 44.2778],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304620',
        NAME: 'Cranberry Isles',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8207174,
        AWATER: 109810355,
        INTPTLAT: '+44.2588493',
        INTPTLON: '-068.2265287',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6909, 44.7217],
            [-68.5285, 44.7522],
            [-68.5029, 44.655],
            [-68.6245, 44.6387],
            [-68.6909, 44.7217],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304890',
        NAME: 'Dedham',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101852804,
        AWATER: 13083957,
        INTPTLAT: '+44.7008917',
        INTPTLON: '-068.5579582',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6245, 44.6387],
            [-68.5029, 44.655],
            [-68.4187, 44.6661],
            [-68.4452, 44.763],
            [-68.4549, 44.8015],
            [-68.3262, 44.817],
            [-68.3034, 44.7306],
            [-68.181, 44.7429],
            [-68.1573, 44.5668],
            [-68.1031, 44.4899],
            [-67.9918, 44.5045],
            [-67.9936, 44.5922],
            [-67.9083, 44.5526],
            [-67.8738, 44.4551],
            [-67.9252, 44.3414],
            [-68.0182, 44.2751],
            [-68.1278, 44.2644],
            [-68.1661, 44.2917],
            [-68.1631, 44.3747],
            [-68.2476, 44.4526],
            [-68.3431, 44.444],
            [-68.4346, 44.4759],
            [-68.5959, 44.5569],
            [-68.6245, 44.6387],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314790',
        NAME: 'Regional School Unit 24',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1034991075,
        AWATER: 495166988,
        INTPTLAT: '+44.5323272',
        INTPTLON: '-068.2052827',
        ELSDLEA: '',
        UNSDLEA: '14790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.7426, 43.9431],
              [-68.6847, 43.8546],
              [-68.7489, 43.8325],
              [-68.8043, 43.9031],
              [-68.7426, 43.9431],
            ],
          ],
          [
            [
              [-69.1196, 43.9458],
              [-69.0601, 44.0503],
              [-68.9937, 44.0937],
              [-68.8336, 44.1343],
              [-68.7356, 44.1192],
              [-68.7071, 43.9638],
              [-68.7215, 43.9619],
              [-69.0328, 43.9654],
              [-69.1196, 43.9458],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311850',
        NAME: 'School Administrative District 08',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63205681,
        AWATER: 502316849,
        INTPTLAT: '+44.0334754',
        INTPTLON: '-068.9015757',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.438, 43.9571],
            [-69.3803, 43.9445],
            [-69.3981, 43.8689],
            [-69.438, 43.9571],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382013',
        NAME: 'Louds Island Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4046951,
        AWATER: 34744108,
        INTPTLAT: '+43.9101846',
        INTPTLON: '-069.4004395',
        ELSDLEA: '',
        UNSDLEA: '82013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9149, 46.5087],
            [-67.9089, 46.5971],
            [-67.7882, 46.5999],
            [-67.7849, 46.4666],
            [-67.9127, 46.4647],
            [-67.9149, 46.5087],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311610',
        NAME: 'School Administrative District 42',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139158508,
        AWATER: 332364,
        INTPTLAT: '+46.5268081',
        INTPTLON: '-067.8476680',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.4734, 44.2495],
            [-68.2932, 44.185],
            [-68.2648, 44.1931],
            [-68.1707, 44.1899],
            [-68.1759, 44.1251],
            [-68.3358, 44.0506],
            [-68.4072, 44.201],
            [-68.493, 44.2158],
            [-68.4734, 44.2495],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307590',
        NAME: 'Frenchboro',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12469924,
        AWATER: 216559946,
        INTPTLAT: '+44.1502287',
        INTPTLON: '-068.2479020',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.5616, 45.6693],
              [-68.4325, 45.6678],
              [-68.433, 45.5812],
              [-68.5617, 45.6608],
              [-68.5616, 45.6693],
            ],
          ],
          [
            [
              [-68.5625, 45.6146],
              [-68.4335, 45.5759],
              [-68.5345, 45.5594],
              [-68.5625, 45.6146],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308160',
        NAME: 'Medway',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106196894,
        AWATER: 358494,
        INTPTLAT: '+45.6424028',
        INTPTLON: '-068.4786709',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.4653, 45.2967],
              [-68.3411, 45.318],
              [-68.3037, 45.2215],
              [-68.2954, 45.1904],
              [-68.421, 45.1729],
              [-68.4653, 45.2967],
            ],
          ],
          [
            [
              [-68.6003, 45.3277],
              [-68.5194, 45.2912],
              [-68.5428, 45.1555],
              [-68.5405, 45.1466],
              [-68.6171, 45.1345],
              [-68.6491, 45.2246],
              [-68.6003, 45.3277],
            ],
          ],
          [
            [
              [-68.8009, 45.3291],
              [-68.6965, 45.343],
              [-68.6356, 45.3075],
              [-68.6513, 45.2237],
              [-68.6301, 45.1324],
              [-68.7312, 45.1138],
              [-68.7761, 45.2403],
              [-68.8009, 45.3291],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311250',
        NAME: 'School Administrative District 31',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501216661,
        AWATER: 22327687,
        INTPTLAT: '+45.2395108',
        INTPTLON: '-068.5859753',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.027, 45.3437],
              [-67.9669, 45.3519],
              [-68.0026, 45.2598],
              [-68.027, 45.3437],
            ],
          ],
          [
            [
              [-68.1439, 45.5336],
              [-68.0204, 45.5533],
              [-67.9932, 45.467],
              [-68.1156, 45.4459],
              [-68.1439, 45.5336],
            ],
          ],
          [
            [
              [-68.1804, 45.618],
              [-68.1485, 45.5328],
              [-68.228, 45.5154],
              [-68.2575, 45.5979],
              [-68.1804, 45.618],
            ],
          ],
          [
            [
              [-68.3411, 45.318],
              [-68.2128, 45.3386],
              [-68.1807, 45.2372],
              [-68.3037, 45.2215],
              [-68.3411, 45.318],
            ],
          ],
          [
            [
              [-68.5428, 45.1555],
              [-68.421, 45.1729],
              [-68.2954, 45.1904],
              [-68.2705, 45.1001],
              [-68.3969, 45.0833],
              [-68.3759, 44.9974],
              [-68.5011, 44.9807],
              [-68.5167, 45.0394],
              [-68.5405, 45.1466],
              [-68.5428, 45.1555],
            ],
          ],
          [
            [
              [-68.7731, 45.1066],
              [-68.7312, 45.1138],
              [-68.6301, 45.1324],
              [-68.6502, 45.003],
              [-68.7731, 45.1066],
            ],
          ],
          [
            [
              [-68.8194, 46.3958],
              [-68.5679, 46.3825],
              [-68.4364, 46.3821],
              [-68.4374, 46.2075],
              [-68.4363, 46.1247],
              [-68.5634, 46.1252],
              [-68.5586, 45.8555],
              [-68.4266, 45.8534],
              [-68.4325, 45.6678],
              [-68.5616, 45.6693],
              [-68.6071, 45.6324],
              [-68.5625, 45.6146],
              [-68.5345, 45.5594],
              [-68.5064, 45.4722],
              [-68.5875, 45.46],
              [-68.5474, 45.3666],
              [-68.6356, 45.3075],
              [-68.6965, 45.343],
              [-68.7016, 45.4409],
              [-68.8254, 45.4206],
              [-68.8312, 45.4424],
              [-68.8573, 45.5273],
              [-68.9647, 45.5124],
              [-68.9589, 45.6622],
              [-68.827, 45.6848],
              [-68.8194, 46.3958],
            ],
            [
              [-68.7533, 45.6654],
              [-68.6829, 45.6125],
              [-68.6215, 45.6839],
              [-68.7533, 45.6654],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382010',
        NAME: 'Penobscot Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3395836421,
        AWATER: 196911136,
        INTPTLAT: '+45.7533753',
        INTPTLON: '-068.5932405',
        ELSDLEA: '',
        UNSDLEA: '82010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.3002, 46.9264],
            [-68.1787, 46.9289],
            [-68.1773, 46.837],
            [-68.0594, 46.8393],
            [-68.0565, 46.7757],
            [-68.3005, 46.7452],
            [-68.3002, 46.9264],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311700',
        NAME: 'School Administrative District 45',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276819094,
        AWATER: 2778891,
        INTPTLAT: '+46.8198656',
        INTPTLON: '-068.1902342',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.179, 47.0205],
            [-68.1787, 46.9289],
            [-68.3002, 46.9264],
            [-68.302, 47.0174],
            [-68.179, 47.0205],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313590',
        NAME: 'Westmanland',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92173185,
        AWATER: 2235697,
        INTPTLAT: '+46.9751293',
        INTPTLON: '-068.2472485',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.1787, 46.9289],
            [-68.0623, 46.9314],
            [-68.0594, 46.8393],
            [-68.1773, 46.837],
            [-68.1787, 46.9289],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314010',
        NAME: 'Woodland',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91294541,
        AWATER: 0,
        INTPTLAT: '+46.8840579',
        INTPTLON: '-068.1195081',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.541, 45.1254],
            [-70.4172, 45.1391],
            [-70.4059, 45.0949],
            [-70.3987, 45.0656],
            [-70.5215, 45.0465],
            [-70.541, 45.1254],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304500',
        NAME: 'Coplin Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85581893,
        AWATER: 0,
        INTPTLAT: '+45.0938415',
        INTPTLON: '-070.4702654',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2811, 43.5975],
            [-70.2136, 43.6336],
            [-70.1072, 43.5443],
            [-70.1842, 43.4986],
            [-70.2811, 43.5975],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303930',
        NAME: 'Cape Elizabeth',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38062795,
        AWATER: 80871799,
        INTPTLAT: '+43.5673345',
        INTPTLON: '-070.2035092',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3368, 43.8071],
            [-70.169, 43.724],
            [-70.1992, 43.7001],
            [-70.3113, 43.7199],
            [-70.3612, 43.7538],
            [-70.3368, 43.8071],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305670',
        NAME: 'Falmouth',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76084036,
        AWATER: 18035734,
        INTPTLAT: '+43.7404718',
        INTPTLON: '-070.2771725',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.1222, 43.7013],
              [-69.9759, 43.5979],
              [-70.0167, 43.5811],
              [-70.1222, 43.7013],
            ],
          ],
          [
            [
              [-70.3113, 43.7199],
              [-70.1992, 43.7001],
              [-70.0753, 43.5573],
              [-70.1072, 43.5443],
              [-70.2136, 43.6336],
              [-70.3474, 43.6453],
              [-70.3113, 43.7199],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309930',
        NAME: 'Portland',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55801118,
        AWATER: 124050542,
        INTPTLAT: '+43.6331574',
        INTPTLON: '-070.1853051',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.4486, 44.0094],
            [-70.402, 43.9901],
            [-70.4454, 43.8818],
            [-70.3244, 43.8198],
            [-70.3368, 43.8071],
            [-70.3612, 43.7538],
            [-70.3781, 43.6974],
            [-70.4486, 43.817],
            [-70.5357, 43.864],
            [-70.4486, 44.0094],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314793',
        NAME: 'Regional School Unit 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206525440,
        AWATER: 39280274,
        INTPTLAT: '+43.8544197',
        INTPTLON: '-070.4340517',
        ELSDLEA: '',
        UNSDLEA: '14793',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9374, 44.2677],
            [-69.9107, 44.2688],
            [-69.8762, 44.258],
            [-69.7686, 44.2466],
            [-69.6654, 44.2276],
            [-69.6663, 44.124],
            [-69.855, 44.1679],
            [-69.9472, 44.2383],
            [-69.9374, 44.2677],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310590',
        NAME: 'School Administrative District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193236096,
        AWATER: 12168271,
        INTPTLAT: '+44.1950739',
        INTPTLON: '-069.7939117',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4606, 44.6471],
            [-69.3931, 44.6404],
            [-69.4095, 44.5789],
            [-69.4606, 44.6471],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382005',
        NAME: 'Kennebec Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26967006,
        AWATER: 51216,
        INTPTLAT: '+44.6289184',
        INTPTLON: '-069.4288810',
        ELSDLEA: '',
        UNSDLEA: '82005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.0252, 44.0509],
              [-70.0055, 44.1245],
              [-69.8978, 44.1077],
              [-69.7998, 44.0786],
              [-69.8135, 44.0268],
              [-69.8135, 44.026],
              [-69.8747, 43.9536],
              [-69.9762, 43.9153],
              [-70.0342, 43.9757],
              [-70.0518, 43.991],
              [-70.0252, 44.0509],
            ],
          ],
          [
            [
              [-70.0698, 43.7708],
              [-70.0284, 43.8167],
              [-69.896, 43.8662],
              [-69.8819, 43.6365],
              [-69.9637, 43.6029],
              [-70.0539, 43.6809],
              [-70.0698, 43.7708],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314768',
        NAME: 'School Administrative District 75',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347723601,
        AWATER: 289573283,
        INTPTLAT: '+43.7199229',
        INTPTLON: '-070.0296588',
        ELSDLEA: '',
        UNSDLEA: '14768',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9923, 43.8863],
            [-70.8735, 43.8891],
            [-70.7298, 43.9283],
            [-70.7248, 43.856],
            [-70.6268, 43.8338],
            [-70.7763, 43.7456],
            [-70.8381, 43.6754],
            [-70.9793, 43.6732],
            [-70.9803, 43.6843],
            [-70.9891, 43.792],
            [-70.9917, 43.8776],
            [-70.9923, 43.8863],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314190',
        NAME: 'School Administrative District 55',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480276728,
        AWATER: 12764319,
        INTPTLAT: '+43.7870348',
        INTPTLON: '-070.8386159',
        ELSDLEA: '',
        UNSDLEA: '14190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.492, 43.6068],
            [-70.4685, 43.6313],
            [-70.392, 43.6557],
            [-70.3705, 43.6424],
            [-70.2811, 43.5975],
            [-70.1842, 43.4986],
            [-70.2221, 43.4669],
            [-70.492, 43.6068],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310530',
        NAME: 'Scarborough',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123307965,
        AWATER: 59609340,
        INTPTLAT: '+43.5712034',
        INTPTLON: '-070.3341696',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.402, 43.9901],
            [-70.3148, 44.0374],
            [-70.2292, 43.9741],
            [-70.2486, 43.8983],
            [-70.3244, 43.8198],
            [-70.4454, 43.8818],
            [-70.402, 43.9901],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310710',
        NAME: 'School Administrative District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234122579,
        AWATER: 8814266,
        INTPTLAT: '+43.9244252',
        INTPTLON: '-070.3186614',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3705, 43.6424],
            [-70.3474, 43.6453],
            [-70.2136, 43.6336],
            [-70.2811, 43.5975],
            [-70.3705, 43.6424],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312330',
        NAME: 'South Portland',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31191524,
        AWATER: 5101357,
        INTPTLAT: '+43.6314020',
        INTPTLON: '-070.2859894',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3781, 43.6974],
            [-70.3612, 43.7538],
            [-70.3113, 43.7199],
            [-70.3474, 43.6453],
            [-70.3705, 43.6424],
            [-70.392, 43.6557],
            [-70.3781, 43.6974],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313560',
        NAME: 'Westbrook',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44429364,
        AWATER: 461639,
        INTPTLAT: '+43.7079198',
        INTPTLON: '-070.3526019',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.4059, 45.0949],
            [-70.2938, 45.1099],
            [-70.1594, 45.1284],
            [-70.1334, 45.0367],
            [-70.2716, 45.0206],
            [-70.3987, 45.0656],
            [-70.4059, 45.0949],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303980',
        NAME: 'Carrabassett Valley',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200661910,
        AWATER: 356322,
        INTPTLAT: '+45.0643441',
        INTPTLON: '-070.2727491',
        ELSDLEA: '',
        UNSDLEA: '03980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1739, 43.8429],
            [-70.0843, 43.781],
            [-70.1551, 43.7383],
            [-70.2206, 43.8151],
            [-70.1739, 43.8429],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314100',
        NAME: 'Yarmouth',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34582017,
        AWATER: 24846732,
        INTPTLAT: '+43.7881370',
        INTPTLON: '-070.1564780',
        ELSDLEA: '',
        UNSDLEA: '14100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3368, 43.8071],
            [-70.3244, 43.8198],
            [-70.2486, 43.8983],
            [-70.1739, 43.8429],
            [-70.2206, 43.8151],
            [-70.1551, 43.7383],
            [-70.0843, 43.781],
            [-70.0698, 43.7708],
            [-70.0539, 43.6809],
            [-69.9637, 43.6029],
            [-69.9759, 43.5979],
            [-70.1222, 43.7013],
            [-70.169, 43.724],
            [-70.3368, 43.8071],
          ],
          [
            [-70.1407, 43.7217],
            [-70.1145, 43.7244],
            [-70.0965, 43.7506],
            [-70.1219, 43.7487],
            [-70.1407, 43.7217],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314240',
        NAME: 'School Administrative District 51',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115573567,
        AWATER: 63550546,
        INTPTLAT: '+43.7805194',
        INTPTLON: '-070.1941571',
        ELSDLEA: '',
        UNSDLEA: '14240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.2716, 45.0206],
              [-70.1334, 45.0367],
              [-70.1104, 44.9473],
              [-70.1521, 44.9418],
              [-70.2459, 44.9299],
              [-70.2716, 45.0206],
            ],
          ],
          [
            [
              [-70.1556, 44.8469],
              [-70.2369, 44.7572],
              [-70.3559, 44.718],
              [-70.4423, 44.7644],
              [-70.4853, 44.8487],
              [-70.3489, 44.9044],
              [-70.2546, 44.8355],
              [-70.1556, 44.8469],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314300',
        NAME: 'School Administrative District 58',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 423887607,
        AWATER: 3079777,
        INTPTLAT: '+44.8524251',
        INTPTLON: '-070.2994411',
        ELSDLEA: '',
        UNSDLEA: '14300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7394, 44.6953],
            [-70.7692, 44.7322],
            [-70.615, 44.7956],
            [-70.5638, 44.7132],
            [-70.5106, 44.6272],
            [-70.398, 44.6739],
            [-70.3322, 44.6089],
            [-70.2807, 44.5696],
            [-70.2652, 44.3629],
            [-70.3145, 44.2372],
            [-70.4318, 44.2513],
            [-70.506, 44.3708],
            [-70.5508, 44.4535],
            [-70.661, 44.4572],
            [-70.7327, 44.5609],
            [-70.6553, 44.5908],
            [-70.7394, 44.6953],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314795',
        NAME: 'Regional School Unit 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1216569920,
        AWATER: 23081334,
        INTPTLAT: '+44.5178361',
        INTPTLON: '-070.4807880',
        ELSDLEA: '',
        UNSDLEA: '14795',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.3873, 43.799],
            [-69.3528, 43.8307],
            [-69.2335, 43.778],
            [-69.2979, 43.7077],
            [-69.3844, 43.7318],
            [-69.3873, 43.799],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308340',
        NAME: 'Monhegan Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2202209,
        AWATER: 123581853,
        INTPTLAT: '+43.7588809',
        INTPTLON: '-069.3188780',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7332, 43.1483],
            [-70.5858, 43.081],
            [-70.5289, 43.0058],
            [-70.5751, 42.9171],
            [-70.6056, 42.9722],
            [-70.7012, 43.0452],
            [-70.738, 43.0737],
            [-70.7678, 43.0936],
            [-70.7332, 43.1483],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307140',
        NAME: 'Kittery',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46030535,
        AWATER: 148981183,
        INTPTLAT: '+43.0048522',
        INTPTLON: '-070.6043548',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9659, 45.4233],
            [-68.8312, 45.4424],
            [-68.8254, 45.4206],
            [-68.8009, 45.3291],
            [-68.9381, 45.3123],
            [-68.9659, 45.4233],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300059',
        NAME: 'Lake View Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105337629,
        AWATER: 31438282,
        INTPTLAT: '+45.3717914',
        INTPTLON: '-068.8811743',
        ELSDLEA: '',
        UNSDLEA: '00059',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7865, 44.227],
            [-70.6856, 44.2805],
            [-70.506, 44.3708],
            [-70.4318, 44.2513],
            [-70.3145, 44.2372],
            [-70.3252, 44.2084],
            [-70.4853, 44.0604],
            [-70.4801, 44.0321],
            [-70.6403, 44.0105],
            [-70.7349, 44.1219],
            [-70.7865, 44.227],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310770',
        NAME: 'School Administrative District 17',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 762860381,
        AWATER: 42829889,
        INTPTLAT: '+44.1768328',
        INTPTLON: '-070.5686171',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0096, 44.2733],
            [-70.8452, 44.3011],
            [-70.7865, 44.227],
            [-70.7349, 44.1219],
            [-70.8218, 44.0771],
            [-70.7154, 43.9618],
            [-70.7298, 43.9283],
            [-70.8735, 43.8891],
            [-70.9923, 43.8863],
            [-70.9932, 43.9254],
            [-71.0002, 44.0693],
            [-71.0096, 44.2733],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314765',
        NAME: 'School Administrative District 72',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 722446286,
        AWATER: 49308139,
        INTPTLAT: '+44.0724032',
        INTPTLON: '-070.8915362',
        ELSDLEA: '',
        UNSDLEA: '14765',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.5613, 46.6552],
              [-68.4379, 46.6568],
              [-68.4401, 46.7449],
              [-68.3005, 46.7452],
              [-68.3007, 46.6578],
              [-68.3047, 46.4694],
              [-68.4364, 46.4702],
              [-68.4369, 46.5612],
              [-68.5609, 46.5608],
              [-68.5613, 46.6552],
            ],
          ],
          [
            [
              [-68.4401, 46.7449],
              [-68.5619, 46.7436],
              [-68.5635, 46.8299],
              [-68.4415, 46.8319],
              [-68.4401, 46.7449],
            ],
          ],
          [
            [
              [-68.4364, 46.4702],
              [-68.4364, 46.3821],
              [-68.5679, 46.3825],
              [-68.5679, 46.4709],
              [-68.4364, 46.4702],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311280',
        NAME: 'School Administrative District 32',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585404428,
        AWATER: 17745021,
        INTPTLAT: '+46.6142260',
        INTPTLON: '-068.4328959',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9469, 47.1481],
            [-68.8822, 47.1829],
            [-68.5778, 47.2876],
            [-68.4617, 47.2892],
            [-68.4453, 47.1989],
            [-68.4446, 47.1064],
            [-68.564, 47.1039],
            [-68.5631, 46.9232],
            [-68.6955, 46.9218],
            [-68.6975, 47.1026],
            [-68.9556, 47.1017],
            [-68.9469, 47.1481],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311100',
        NAME: 'School Administrative District 27',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 733532802,
        AWATER: 24616216,
        INTPTLAT: '+47.1319563',
        INTPTLON: '-068.6506192',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.4617, 47.2892],
            [-68.3534, 47.357],
            [-68.2152, 47.2008],
            [-68.4453, 47.1989],
            [-68.4617, 47.2892],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311310',
        NAME: 'School Administrative District 33',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150780303,
        AWATER: 15486609,
        INTPTLAT: '+47.2552694',
        INTPTLON: '-068.3704143',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.0632, 47.0228],
              [-68.063, 47.0464],
              [-67.9312, 47.0484],
              [-67.9293, 46.9579],
              [-68.0629, 46.9556],
              [-68.0632, 47.0228],
            ],
          ],
          [
            [
              [-68.433, 45.5812],
              [-68.4325, 45.6678],
              [-68.4266, 45.8534],
              [-68.2975, 45.8515],
              [-68.2971, 45.9442],
              [-68.167, 45.9438],
              [-68.1672, 46.0376],
              [-68.04, 46.0387],
              [-67.9119, 45.9931],
              [-67.914, 45.8649],
              [-68.0326, 45.8543],
              [-68.165, 45.8559],
              [-68.1666, 45.766],
              [-68.1662, 45.6733],
              [-68.2994, 45.6731],
              [-68.3008, 45.5905],
              [-68.4109, 45.5733],
              [-68.433, 45.5812],
            ],
          ],
          [
            [
              [-68.4364, 46.3821],
              [-68.4364, 46.4702],
              [-68.3047, 46.4694],
              [-68.3007, 46.6578],
              [-68.1726, 46.6607],
              [-68.1736, 46.5652],
              [-68.0471, 46.5682],
              [-68.0442, 46.5062],
              [-67.9149, 46.5087],
              [-67.9127, 46.4647],
              [-67.9089, 46.3717],
              [-67.9071, 46.2765],
              [-68.0402, 46.2768],
              [-68.0402, 46.2075],
              [-68.4374, 46.2075],
              [-68.4364, 46.3821],
            ],
          ],
          [
            [
              [-68.8822, 47.1829],
              [-68.9469, 47.1481],
              [-69.0829, 47.1478],
              [-69.2117, 47.1903],
              [-69.2121, 47.0142],
              [-68.9558, 47.0135],
              [-68.9556, 47.1017],
              [-68.6975, 47.1026],
              [-68.6955, 46.9218],
              [-68.5631, 46.9232],
              [-68.564, 47.1039],
              [-68.4446, 47.1064],
              [-68.4453, 47.1989],
              [-68.2152, 47.2008],
              [-68.1798, 47.2008],
              [-68.0606, 47.2015],
              [-68.0601, 47.1116],
              [-68.1794, 47.1109],
              [-68.179, 47.0205],
              [-68.302, 47.0174],
              [-68.3002, 46.9264],
              [-68.3005, 46.7452],
              [-68.4401, 46.7449],
              [-68.4415, 46.8319],
              [-68.5635, 46.8299],
              [-68.5619, 46.7436],
              [-68.5613, 46.6552],
              [-68.5609, 46.5608],
              [-68.4369, 46.5612],
              [-68.4364, 46.4702],
              [-68.5679, 46.4709],
              [-68.5679, 46.3825],
              [-68.8194, 46.3958],
              [-68.8214, 46.5725],
              [-69.7211, 46.5741],
              [-70.0232, 46.5735],
              [-69.9972, 46.6954],
              [-69.2245, 47.4599],
              [-69.0358, 47.4072],
              [-69.0409, 47.245],
              [-68.8822, 47.1829],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382001',
        NAME: 'Aroostook Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10269912625,
        AWATER: 242163429,
        INTPTLAT: '+46.7114986',
        INTPTLON: '-068.8567072',
        ELSDLEA: '',
        UNSDLEA: '82001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.3534, 47.357],
            [-68.179, 47.3299],
            [-68.1798, 47.2008],
            [-68.2152, 47.2008],
            [-68.3534, 47.357],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307800',
        NAME: 'Madawaska',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143889186,
        AWATER: 1748968,
        INTPTLAT: '+47.2932982',
        INTPTLON: '-068.2518091',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.914, 45.8649],
            [-67.796, 45.8654],
            [-67.8048, 45.7737],
            [-67.914, 45.7806],
            [-67.914, 45.8649],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309240',
        NAME: 'Orient',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91988026,
        AWATER: 5556495,
        INTPTLAT: '+45.8217231',
        INTPTLON: '-067.8546391',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.1666, 45.766],
            [-68.0341, 45.7655],
            [-68.0346, 45.7485],
            [-68.0349, 45.6422],
            [-68.048, 45.6399],
            [-68.1668, 45.6204],
            [-68.1662, 45.6733],
            [-68.1666, 45.766],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310140',
        NAME: 'Reed Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152730275,
        AWATER: 578186,
        INTPTLAT: '+45.6921383',
        INTPTLON: '-068.0891118',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.418, 45.7133],
            [-70.2921, 45.7309],
            [-70.2687, 45.641],
            [-70.4013, 45.621],
            [-70.418, 45.7133],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304897',
        NAME: 'Dennistown Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99848962,
        AWATER: 4677735,
        INTPTLAT: '+45.6584831',
        INTPTLON: '-070.3387661',
        ELSDLEA: '',
        UNSDLEA: '04897',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9381, 45.3123],
            [-68.8009, 45.3291],
            [-68.7761, 45.2403],
            [-68.8809, 45.2249],
            [-68.9135, 45.2291],
            [-68.9381, 45.3123],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308140',
        NAME: 'Medford',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109588323,
        AWATER: 2203793,
        INTPTLAT: '+45.2868319',
        INTPTLON: '-068.8951091',
        ELSDLEA: '',
        UNSDLEA: '08140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.0814, 44.7458],
            [-68.9589, 44.7612],
            [-68.8364, 44.7775],
            [-68.7812, 44.7783],
            [-68.7875, 44.7672],
            [-68.8158, 44.6838],
            [-68.8625, 44.5937],
            [-68.9385, 44.5652],
            [-68.9878, 44.5797],
            [-69.061, 44.6639],
            [-69.0814, 44.7458],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310950',
        NAME: 'Regional School Unit 22',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334328057,
        AWATER: 9725338,
        INTPTLAT: '+44.6739586',
        INTPTLON: '-068.9548393',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9469, 47.1481],
            [-68.9556, 47.1017],
            [-68.9558, 47.0135],
            [-69.2121, 47.0142],
            [-69.2117, 47.1903],
            [-69.0829, 47.1478],
            [-68.9469, 47.1481],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310560',
        NAME: 'School Administration District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333106353,
        AWATER: 7269680,
        INTPTLAT: '+47.0941438',
        INTPTLON: '-069.1029510',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9261, 46.7788],
            [-67.9191, 46.866],
            [-67.7895, 46.8722],
            [-67.7889, 46.6895],
            [-67.9216, 46.6865],
            [-67.9261, 46.7788],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310890',
        NAME: 'School Administrative District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198521935,
        AWATER: 4411926,
        INTPTLAT: '+46.7762407',
        INTPTLON: '-067.8591970',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.0601, 47.1116],
            [-68.0606, 47.2015],
            [-68.0615, 47.256],
            [-67.952, 47.1946],
            [-67.8829, 47.1043],
            [-67.7902, 47.0501],
            [-67.9312, 47.0484],
            [-68.063, 47.0464],
            [-68.0601, 47.1116],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311010',
        NAME: 'School Administrative District 24',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247246996,
        AWATER: 4600716,
        INTPTLAT: '+47.1226746',
        INTPTLON: '-067.9695292',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8199, 43.197],
            [-70.8176, 43.2377],
            [-70.7093, 43.3024],
            [-70.6529, 43.2609],
            [-70.7545, 43.1976],
            [-70.7332, 43.1483],
            [-70.7678, 43.0936],
            [-70.7827, 43.0996],
            [-70.82, 43.1225],
            [-70.8199, 43.197],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311370',
        NAME: 'School Administrative District 35',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134461750,
        AWATER: 5313471,
        INTPTLAT: '+43.2112874',
        INTPTLON: '-070.7623854',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6529, 43.2609],
            [-70.5094, 43.2078],
            [-70.4062, 43.1021],
            [-70.5858, 43.081],
            [-70.7332, 43.1483],
            [-70.7545, 43.1976],
            [-70.6529, 43.2609],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314130',
        NAME: 'York',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141655587,
        AWATER: 199677159,
        INTPTLAT: '+43.1654947',
        INTPTLON: '-070.5910103',
        ELSDLEA: '',
        UNSDLEA: '14130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9089, 46.3717],
            [-67.7833, 46.373],
            [-67.7812, 46.0962],
            [-67.9066, 46.0953],
            [-67.9038, 46.1896],
            [-68.0403, 46.1874],
            [-68.0402, 46.2075],
            [-68.0402, 46.2768],
            [-67.9071, 46.2765],
            [-67.9089, 46.3717],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311160',
        NAME: 'School Administrative District 29',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394624746,
        AWATER: 1264162,
        INTPTLAT: '+46.2275429',
        INTPTLON: '-067.8730690',
        ELSDLEA: '',
        UNSDLEA: '11160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.9878, 44.5797],
            [-68.9385, 44.5652],
            [-68.8052, 44.549],
            [-68.7925, 44.4937],
            [-68.801, 44.462],
            [-68.8531, 44.4124],
            [-68.9789, 44.2709],
            [-69.078, 44.3587],
            [-69.1709, 44.2926],
            [-69.1876, 44.3046],
            [-69.2689, 44.3647],
            [-69.2018, 44.4648],
            [-69.1183, 44.4298],
            [-69.0654, 44.5247],
            [-68.9878, 44.5797],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314791',
        NAME: 'Regional School Unit 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501779156,
        AWATER: 113960621,
        INTPTLAT: '+44.4270773',
        INTPTLON: '-069.0200514',
        ELSDLEA: '',
        UNSDLEA: '14791',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.8319, 45.3718],
            [-67.7146, 45.3908],
            [-67.6899, 45.3035],
            [-67.8118, 45.2854],
            [-67.8319, 45.3718],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312810',
        NAME: 'Talmadge',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97905071,
        AWATER: 3261191,
        INTPTLAT: '+45.3379048',
        INTPTLON: '-067.7715935',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6766, 44.5005],
            [-69.5656, 44.4826],
            [-69.6104, 44.3592],
            [-69.6257, 44.3618],
            [-69.729, 44.3777],
            [-69.6766, 44.5005],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313110',
        NAME: 'Vassalboro',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114637164,
        AWATER: 9181957,
        INTPTLAT: '+44.4293349',
        INTPTLON: '-069.6482941',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6655, 44.5864],
            [-69.6057, 44.5773],
            [-69.6766, 44.5005],
            [-69.6655, 44.5864],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313350',
        NAME: 'Waterville',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35166367,
        AWATER: 1228036,
        INTPTLAT: '+44.5452848',
        INTPTLON: '-069.6613961',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6766, 44.5005],
            [-69.6057, 44.5773],
            [-69.4935, 44.5609],
            [-69.514, 44.4945],
            [-69.5656, 44.4826],
            [-69.6766, 44.5005],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313860',
        NAME: 'Winslow',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95356658,
        AWATER: 4798926,
        INTPTLAT: '+44.5238496',
        INTPTLON: '-069.5771791',
        ELSDLEA: '',
        UNSDLEA: '13860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6899, 45.3035],
            [-67.5508, 45.3199],
            [-67.522, 45.2531],
            [-67.5365, 45.245],
            [-67.6276, 45.2105],
            [-67.6611, 45.1923],
            [-67.6899, 45.3035],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300002',
        NAME: 'Indian Township',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97216885,
        AWATER: 17867567,
        INTPTLAT: '+45.2898668',
        INTPTLON: '-067.6193498',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9107, 44.2688],
            [-69.9374, 44.2677],
            [-70.0413, 44.2841],
            [-70.017, 44.3593],
            [-69.8793, 44.3411],
            [-69.9107, 44.2688],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313970',
        NAME: 'Winthrop',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80793221,
        AWATER: 17359641,
        INTPTLAT: '+44.3099910',
        INTPTLON: '-069.9630934',
        ELSDLEA: '',
        UNSDLEA: '13970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4834, 44.7203],
            [-69.4606, 44.6471],
            [-69.4095, 44.5789],
            [-69.396, 44.4571],
            [-69.4455, 44.4638],
            [-69.514, 44.4945],
            [-69.4935, 44.5609],
            [-69.6057, 44.5773],
            [-69.6655, 44.5864],
            [-69.7443, 44.6026],
            [-69.7803, 44.6771],
            [-69.4834, 44.7203],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314330',
        NAME: 'School Administrative District 49',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427003704,
        AWATER: 7860017,
        INTPTLAT: '+44.6106574',
        INTPTLON: '-069.5596997',
        ELSDLEA: '',
        UNSDLEA: '14330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9696, 45.3313],
            [-69.9585, 45.3442],
            [-69.8745, 45.3559],
            [-69.8379, 45.2739],
            [-69.9911, 45.2521],
            [-69.9696, 45.3313],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300052',
        NAME: 'The Forks Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102477517,
        AWATER: 4938538,
        INTPTLAT: '+45.2996262',
        INTPTLON: '-069.9283179',
        ELSDLEA: '',
        UNSDLEA: '00052',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.621, 45.011],
            [-69.4823, 44.9942],
            [-69.4754, 44.9682],
            [-69.5506, 44.927],
            [-69.621, 45.011],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306330',
        NAME: 'Harmony',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100142376,
        AWATER: 4284702,
        INTPTLAT: '+44.9771472',
        INTPTLON: '-069.5455539',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1594, 45.1284],
            [-70.0292, 45.1475],
            [-70.0044, 45.0537],
            [-70.1334, 45.0367],
            [-70.1594, 45.1284],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306600',
        NAME: 'Highland Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109260061,
        AWATER: 60005,
        INTPTLAT: '+45.0877395',
        INTPTLON: '-070.1004287',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.0292, 45.1475],
            [-69.9666, 45.1569],
            [-69.9017, 45.0671],
            [-70.0044, 45.0537],
            [-70.0292, 45.1475],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309810',
        NAME: 'Pleasant Ridge Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57350422,
        AWATER: 6278116,
        INTPTLAT: '+45.0937757',
        INTPTLON: '-069.9698750',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2687, 45.641],
            [-70.2921, 45.7309],
            [-70.1601, 45.7514],
            [-70.1177, 45.5637],
            [-70.2438, 45.5473],
            [-70.2687, 45.641],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310620',
        NAME: 'School Administrative District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210643227,
        AWATER: 4257552,
        INTPTLAT: '+45.6476859',
        INTPTLON: '-070.2144554',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0393, 44.7779],
            [-70.9382, 44.7746],
            [-70.9318, 44.668],
            [-71.031, 44.6557],
            [-71.0344, 44.71],
            [-71.0393, 44.7779],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313070',
        NAME: 'Upton',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102329037,
        AWATER: 6010322,
        INTPTLAT: '+44.7285984',
        INTPTLON: '-070.9835836',
        ELSDLEA: '',
        UNSDLEA: '13070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9666, 45.1569],
            [-69.8112, 45.1773],
            [-69.769, 45.0857],
            [-69.7504, 44.9938],
            [-69.8744, 44.9776],
            [-69.9017, 45.0671],
            [-69.9666, 45.1569],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310650',
        NAME: 'School Administrative District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209335111,
        AWATER: 6357631,
        INTPTLAT: '+45.0957543',
        INTPTLON: '-069.8384337',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.5217, 44.8121],
            [-69.2863, 44.808],
            [-69.2668, 44.7219],
            [-69.3931, 44.6404],
            [-69.4606, 44.6471],
            [-69.4834, 44.7203],
            [-69.5217, 44.8121],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314610',
        NAME: 'School Administrative District 53',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277950254,
        AWATER: 7859823,
        INTPTLAT: '+44.7280647',
        INTPTLON: '-069.3951352',
        ELSDLEA: '',
        UNSDLEA: '14610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1521, 44.9418],
            [-70.1104, 44.9473],
            [-69.8744, 44.9776],
            [-69.7504, 44.9938],
            [-69.7454, 44.9945],
            [-69.7201, 44.905],
            [-69.7512, 44.9011],
            [-69.8596, 44.8872],
            [-69.8889, 44.7878],
            [-70.0008, 44.7738],
            [-70.0333, 44.8645],
            [-70.1299, 44.8508],
            [-70.1521, 44.9418],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314767',
        NAME: 'School Administrative District 74',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441841921,
        AWATER: 15944524,
        INTPTLAT: '+44.9002664',
        INTPTLON: '-069.9417077',
        ELSDLEA: '',
        UNSDLEA: '14767',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9911, 45.2521],
            [-69.8379, 45.2739],
            [-69.8112, 45.1773],
            [-69.9666, 45.1569],
            [-69.9911, 45.2521],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300061',
        NAME: 'Caratunk',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135450950,
        AWATER: 7683225,
        INTPTLAT: '+45.2123725',
        INTPTLON: '-069.9033967',
        ELSDLEA: '',
        UNSDLEA: '00061',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6767, 43.9223],
            [-69.5849, 43.9331],
            [-69.5727, 43.8253],
            [-69.5156, 43.7599],
            [-69.5666, 43.7028],
            [-69.6647, 43.7117],
            [-69.6127, 43.8071],
            [-69.6906, 43.8542],
            [-69.6921, 43.8572],
            [-69.6767, 43.9223],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303290',
        NAME: 'Boothbay-Boothbay Harbor Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71564381,
        AWATER: 138277045,
        INTPTLAT: '+43.8418478',
        INTPTLON: '-069.6061479',
        ELSDLEA: '',
        UNSDLEA: '03290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.5409, 44.0043],
            [-69.4757, 44.007],
            [-69.438, 43.9571],
            [-69.3981, 43.8689],
            [-69.3942, 43.858],
            [-69.3528, 43.8307],
            [-69.3873, 43.799],
            [-69.5058, 43.7782],
            [-69.5438, 43.876],
            [-69.5471, 43.9952],
            [-69.5409, 44.0043],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303600',
        NAME: 'Bristol',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88051035,
        AWATER: 114568301,
        INTPTLAT: '+43.8920868',
        INTPTLON: '-069.4731910',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-69.8762, 44.258],
              [-69.8271, 44.314],
              [-69.78, 44.2951],
              [-69.7686, 44.2466],
              [-69.8762, 44.258],
            ],
          ],
          [
            [
              [-69.8978, 44.1077],
              [-69.855, 44.1679],
              [-69.6663, 44.124],
              [-69.7463, 44.0138],
              [-69.8135, 44.026],
              [-69.8135, 44.0268],
              [-69.7901, 44.0429],
              [-69.7998, 44.0786],
              [-69.8978, 44.1077],
            ],
          ],
          [
            [
              [-70.0743, 44.2902],
              [-70.0413, 44.2841],
              [-69.9374, 44.2677],
              [-69.9472, 44.2383],
              [-70.0757, 44.2062],
              [-70.0743, 44.2902],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314776',
        NAME: 'Regional School Unit 02',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290157314,
        AWATER: 24408583,
        INTPTLAT: '+44.0907021',
        INTPTLON: '-069.7598434',
        ELSDLEA: '',
        UNSDLEA: '14776',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6378, 44.0171],
            [-69.563, 43.9798],
            [-69.5849, 43.9331],
            [-69.6767, 43.9223],
            [-69.6378, 44.0171],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305400',
        NAME: 'Edgecomb',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46808028,
        AWATER: 7015203,
        INTPTLAT: '+43.9759894',
        INTPTLON: '-069.6109523',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4328, 44.2267],
            [-69.4322, 44.1577],
            [-69.5178, 44.101],
            [-69.579, 44.1156],
            [-69.5403, 44.2598],
            [-69.4328, 44.2267],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306870',
        NAME: 'Jefferson',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136154515,
        AWATER: 15558056,
        INTPTLAT: '+44.1715983',
        INTPTLON: '-069.5145909',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.0823, 44.8499],
            [-67.9597, 44.8662],
            [-67.9365, 44.7777],
            [-68.0573, 44.7615],
            [-68.0823, 44.8499],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302980',
        NAME: 'Beddington',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89590989,
        AWATER: 8470907,
        INTPTLAT: '+44.8137055',
        INTPTLON: '-068.0177163',
        ELSDLEA: '',
        UNSDLEA: '02980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.5178, 44.101],
            [-69.4322, 44.1577],
            [-69.45, 44.0583],
            [-69.5195, 44.0584],
            [-69.5178, 44.101],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2308940',
        NAME: 'Nobleboro',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49027894,
        AWATER: 10826129,
        INTPTLAT: '+44.1012484',
        INTPTLON: '-069.4826583',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.563, 43.9798],
            [-69.5471, 43.9952],
            [-69.5438, 43.876],
            [-69.5058, 43.7782],
            [-69.5156, 43.7599],
            [-69.5727, 43.8253],
            [-69.5849, 43.9331],
            [-69.563, 43.9798],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312300',
        NAME: 'South Bristol',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33944699,
        AWATER: 42932843,
        INTPTLAT: '+43.8821693',
        INTPTLON: '-069.5495398',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6906, 43.8542],
            [-69.6127, 43.8071],
            [-69.6647, 43.7117],
            [-69.6906, 43.8542],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312360',
        NAME: 'Southport',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13930576,
        AWATER: 46052611,
        INTPTLAT: '+43.8133391',
        INTPTLON: '-069.6628684',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.0342, 43.9757],
            [-69.9762, 43.9153],
            [-69.8747, 43.9536],
            [-69.896, 43.8662],
            [-70.0284, 43.8167],
            [-70.0879, 43.919],
            [-70.0342, 43.9757],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303780',
        NAME: 'Brunswick',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121022815,
        AWATER: 19695348,
        INTPTLAT: '+43.8964047',
        INTPTLON: '-069.9734360',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6268, 43.8338],
            [-70.5357, 43.864],
            [-70.4486, 43.817],
            [-70.5466, 43.7132],
            [-70.4685, 43.6313],
            [-70.492, 43.6068],
            [-70.6343, 43.566],
            [-70.6923, 43.6745],
            [-70.7763, 43.7456],
            [-70.6268, 43.8338],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311790',
        NAME: 'School Administrative District 06',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452858240,
        AWATER: 64334337,
        INTPTLAT: '+43.7226672',
        INTPTLON: '-070.5902265',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.6219, 44.9935],
            [-67.6118, 44.9759],
            [-67.561, 44.8851],
            [-67.6941, 44.8487],
            [-67.7266, 44.9628],
            [-67.6219, 44.9935],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2313500',
        NAME: 'Wesley',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129187512,
        AWATER: 1954157,
        INTPTLAT: '+44.9146847',
        INTPTLON: '-067.6371060',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.2366, 43.8256],
            [-69.1196, 43.9458],
            [-69.0328, 43.9654],
            [-68.7215, 43.9619],
            [-68.7426, 43.9431],
            [-68.8043, 43.9031],
            [-68.7489, 43.8325],
            [-68.7849, 43.8032],
            [-68.9109, 43.8377],
            [-68.9498, 43.7801],
            [-69.083, 43.8256],
            [-69.2366, 43.8256],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314410',
        NAME: 'School Administrative District 65',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6114336,
        AWATER: 522269139,
        INTPTLAT: '+43.9040684',
        INTPTLON: '-069.0092904',
        ELSDLEA: '',
        UNSDLEA: '14410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9335, 45.6591],
            [-67.9374, 45.7732],
            [-67.914, 45.7806],
            [-67.8048, 45.7737],
            [-67.7543, 45.6676],
            [-67.6973, 45.6105],
            [-67.9394, 45.5677],
            [-67.9335, 45.6591],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310680',
        NAME: 'School Administrative District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219106877,
        AWATER: 42504049,
        INTPTLAT: '+45.6659894',
        INTPTLON: '-067.8274545',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9127, 46.4647],
            [-67.7849, 46.4666],
            [-67.7833, 46.373],
            [-67.9089, 46.3717],
            [-67.9127, 46.4647],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300004',
        NAME: 'Bridgewater',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100490510,
        AWATER: 441599,
        INTPTLAT: '+46.4135147',
        INTPTLON: '-067.8491551',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.179, 47.3299],
            [-68.0615, 47.256],
            [-68.0606, 47.2015],
            [-68.1798, 47.2008],
            [-68.179, 47.3299],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300005',
        NAME: 'Grand Isle',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89582442,
        AWATER: 2041435,
        INTPTLAT: '+47.2561493',
        INTPTLON: '-068.1374811',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4096, 44.328],
            [-69.4513, 44.3139],
            [-69.435, 44.3319],
            [-69.4096, 44.328],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382006',
        NAME: 'Lincoln Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1930669,
        AWATER: 18014,
        INTPTLAT: '+44.3257398',
        INTPTLON: '-069.4270148',
        ELSDLEA: '',
        UNSDLEA: '82006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6502, 45.003],
            [-68.6301, 45.1324],
            [-68.6513, 45.2237],
            [-68.6356, 45.3075],
            [-68.5474, 45.3666],
            [-68.3709, 45.4943],
            [-68.4335, 45.5759],
            [-68.5625, 45.6146],
            [-68.6071, 45.6324],
            [-68.5624, 45.6162],
            [-68.5617, 45.66],
            [-68.5617, 45.6608],
            [-68.433, 45.5812],
            [-68.4109, 45.5733],
            [-68.3632, 45.4955],
            [-68.4425, 45.4383],
            [-68.6003, 45.3277],
            [-68.6491, 45.2246],
            [-68.6171, 45.1345],
            [-68.6451, 45.0211],
            [-68.648, 44.9434],
            [-68.6502, 45.003],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300001',
        NAME: 'Indian Island',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19572511,
        AWATER: 37513619,
        INTPTLAT: '+45.0444545',
        INTPTLON: '-068.6650099',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.859, 44.8649],
            [-68.7952, 44.8735],
            [-68.7209, 44.8637],
            [-68.7187, 44.8204],
            [-68.7812, 44.7783],
            [-68.8364, 44.7775],
            [-68.859, 44.8649],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302820',
        NAME: 'Bangor',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88731373,
        AWATER: 868257,
        INTPTLAT: '+44.8296254',
        INTPTLON: '-068.7887668',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.1156, 45.4459],
            [-67.9932, 45.467],
            [-67.9669, 45.3519],
            [-68.027, 45.3437],
            [-68.0903, 45.3592],
            [-68.1156, 45.4459],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303990',
        NAME: 'Carroll Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113841714,
        AWATER: 450630,
        INTPTLAT: '+45.4023002',
        INTPTLON: '-068.0254718',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.1668, 45.6204],
            [-68.048, 45.6399],
            [-68.0204, 45.5533],
            [-68.1439, 45.5336],
            [-68.1485, 45.5328],
            [-68.1804, 45.618],
            [-68.1668, 45.6204],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305160',
        NAME: 'Drew Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98503775,
        AWATER: 2781594,
        INTPTLAT: '+45.5798412',
        INTPTLON: '-068.0938698',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5616, 45.6693],
            [-68.5617, 45.6608],
            [-68.5617, 45.66],
            [-68.5624, 45.6162],
            [-68.6071, 45.6324],
            [-68.5616, 45.6693],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305280',
        NAME: 'East Millinocket',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18376965,
        AWATER: 1839924,
        INTPTLAT: '+45.6536396',
        INTPTLON: '-068.5973397',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.0629, 46.9556],
              [-67.9293, 46.9579],
              [-67.7897, 46.9592],
              [-67.7895, 46.8722],
              [-67.9191, 46.866],
              [-67.9261, 46.7788],
              [-68.0565, 46.7757],
              [-68.0594, 46.8393],
              [-68.0623, 46.9314],
              [-68.0629, 46.9556],
            ],
          ],
          [
            [
              [-68.0601, 47.1116],
              [-68.063, 47.0464],
              [-68.0632, 47.0228],
              [-68.179, 47.0205],
              [-68.1794, 47.1109],
              [-68.0601, 47.1116],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314794',
        NAME: 'Regional School Unit 39',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398725667,
        AWATER: 3115388,
        INTPTLAT: '+46.9238125',
        INTPTLON: '-067.9869888',
        ELSDLEA: '',
        UNSDLEA: '14794',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5602, 45.0865],
            [-67.5034, 45.0231],
            [-67.4956, 45.0096],
            [-67.6118, 44.9759],
            [-67.6219, 44.9935],
            [-67.6596, 45.0589],
            [-67.5602, 45.0865],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2304650',
        NAME: 'Crawford',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89415450,
        AWATER: 8430391,
        INTPTLAT: '+45.0481611',
        INTPTLON: '-067.5755520',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1268, 44.0656],
            [-70.0252, 44.0509],
            [-70.0518, 43.991],
            [-70.1586, 44.0053],
            [-70.1268, 44.0656],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307530',
        NAME: 'Lisbon',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59107755,
        AWATER: 2601677,
        INTPTLAT: '+44.0194953',
        INTPTLON: '-070.0789321',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3252, 44.2084],
            [-70.29, 44.1991],
            [-70.3148, 44.0374],
            [-70.402, 43.9901],
            [-70.4486, 44.0094],
            [-70.4801, 44.0321],
            [-70.4853, 44.0604],
            [-70.3252, 44.2084],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314775',
        NAME: 'Regional School Unit 16',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214457278,
        AWATER: 13728768,
        INTPTLAT: '+44.0835662',
        INTPTLON: '-070.3713348',
        ELSDLEA: '',
        UNSDLEA: '14775',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3145, 44.2372],
            [-70.2652, 44.3629],
            [-70.1326, 44.3714],
            [-70.0743, 44.2902],
            [-70.0757, 44.2062],
            [-70.1153, 44.1262],
            [-70.1998, 44.1675],
            [-70.29, 44.1991],
            [-70.3252, 44.2084],
            [-70.3145, 44.2372],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314160',
        NAME: 'School Administrative District 52',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340811243,
        AWATER: 25199072,
        INTPTLAT: '+44.2421404',
        INTPTLON: '-070.1833653',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1153, 44.1262],
            [-70.0757, 44.2062],
            [-69.9472, 44.2383],
            [-69.855, 44.1679],
            [-69.8978, 44.1077],
            [-70.0055, 44.1245],
            [-70.0252, 44.0509],
            [-70.1268, 44.0656],
            [-70.1153, 44.1262],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314783',
        NAME: 'Regional School Unit 04',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204801763,
        AWATER: 11194985,
        INTPTLAT: '+44.1426028',
        INTPTLON: '-070.0021797',
        ELSDLEA: '',
        UNSDLEA: '14783',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5365, 45.245],
            [-67.522, 45.2531],
            [-67.4871, 45.2802],
            [-67.3409, 45.1251],
            [-67.3891, 45.0759],
            [-67.4547, 45.1495],
            [-67.5365, 45.245],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302730',
        NAME: 'Baileyville',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96227196,
        AWATER: 12323955,
        INTPTLAT: '+45.1814566',
        INTPTLON: '-067.4398865',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.1227, 45.1248],
            [-67.2427, 45.072],
            [-67.3056, 45.1448],
            [-67.1589, 45.162],
            [-67.1227, 45.1248],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303870',
        NAME: 'Calais',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88895707,
        AWATER: 14981767,
        INTPTLAT: '+45.1429520',
        INTPTLON: '-067.2175239',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.2427, 45.072],
            [-67.1227, 45.1248],
            [-67.0829, 45.0555],
            [-67.1858, 45.0097],
            [-67.1903, 45.0077],
            [-67.2427, 45.072],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310190',
        NAME: 'Robbinston',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73001904,
        AWATER: 14361212,
        INTPTLAT: '+45.0673414',
        INTPTLON: '-067.1499529',
        ELSDLEA: '',
        UNSDLEA: '10190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.6118, 44.9759],
              [-67.4956, 45.0096],
              [-67.4576, 44.9424],
              [-67.3345, 44.9771],
              [-67.2968, 44.9604],
              [-67.1958, 44.9103],
              [-67.1098, 44.8916],
              [-67.0108, 44.7173],
              [-67.0774, 44.6607],
              [-67.1435, 44.7516],
              [-67.186, 44.8258],
              [-67.3621, 44.768],
              [-67.4035, 44.8436],
              [-67.5181, 44.8093],
              [-67.561, 44.8851],
              [-67.6118, 44.9759],
            ],
          ],
          [
            [
              [-68.055, 45.2526],
              [-68.0026, 45.2598],
              [-67.9669, 45.3519],
              [-67.9932, 45.467],
              [-68.0204, 45.5533],
              [-68.048, 45.6399],
              [-68.0349, 45.6422],
              [-67.9335, 45.6591],
              [-67.9394, 45.5677],
              [-67.6973, 45.6105],
              [-67.7543, 45.6676],
              [-67.5192, 45.5876],
              [-67.4247, 45.504],
              [-67.5001, 45.4908],
              [-67.4273, 45.3907],
              [-67.4871, 45.2802],
              [-67.522, 45.2531],
              [-67.5508, 45.3199],
              [-67.5712, 45.4126],
              [-67.6052, 45.5339],
              [-67.8688, 45.4933],
              [-67.8319, 45.3718],
              [-67.8118, 45.2854],
              [-67.7807, 45.171],
              [-67.6611, 45.1923],
              [-67.6276, 45.2105],
              [-67.574, 45.1147],
              [-67.5602, 45.0865],
              [-67.6596, 45.0589],
              [-67.6219, 44.9935],
              [-67.7266, 44.9628],
              [-67.6941, 44.8487],
              [-67.701, 44.766],
              [-67.5796, 44.7952],
              [-67.5325, 44.7191],
              [-67.6717, 44.6816],
              [-67.6906, 44.7302],
              [-67.8921, 44.6992],
              [-67.9148, 44.6953],
              [-67.9365, 44.7777],
              [-67.9597, 44.8662],
              [-68.0823, 44.8499],
              [-68.105, 44.9391],
              [-67.9819, 44.9559],
              [-68.055, 45.2526],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382012',
        NAME: 'Washington Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2921002757,
        AWATER: 363653879,
        INTPTLAT: '+45.1173671',
        INTPTLON: '-067.6990596',
        ELSDLEA: '',
        UNSDLEA: '82012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.8185, 44.3917],
            [-69.729, 44.3777],
            [-69.6257, 44.3618],
            [-69.6515, 44.275],
            [-69.78, 44.2951],
            [-69.8271, 44.314],
            [-69.8185, 44.3917],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2302640',
        NAME: 'Augusta',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142796178,
        AWATER: 7516734,
        INTPTLAT: '+44.3349137',
        INTPTLON: '-069.7342027',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.78, 44.2951],
            [-69.6515, 44.275],
            [-69.6257, 44.3618],
            [-69.6104, 44.3592],
            [-69.5064, 44.3429],
            [-69.4455, 44.4638],
            [-69.396, 44.4571],
            [-69.3944, 44.3429],
            [-69.4096, 44.328],
            [-69.435, 44.3319],
            [-69.4513, 44.3139],
            [-69.4328, 44.2267],
            [-69.5403, 44.2598],
            [-69.579, 44.1156],
            [-69.6378, 44.0171],
            [-69.6767, 43.9223],
            [-69.6921, 43.8572],
            [-69.7435, 43.8935],
            [-69.7463, 44.0138],
            [-69.6663, 44.124],
            [-69.6654, 44.2276],
            [-69.7686, 44.2466],
            [-69.78, 44.2951],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314780',
        NAME: 'Regional School Unit 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 564222524,
        AWATER: 38262980,
        INTPTLAT: '+44.2055056',
        INTPTLON: '-069.5726064',
        ELSDLEA: '',
        UNSDLEA: '14780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-69.5656, 44.4826],
              [-69.514, 44.4945],
              [-69.4455, 44.4638],
              [-69.5064, 44.3429],
              [-69.6104, 44.3592],
              [-69.5656, 44.4826],
            ],
          ],
          [
            [
              [-69.9441, 44.5378],
              [-69.9305, 44.6109],
              [-69.7443, 44.6026],
              [-69.6655, 44.5864],
              [-69.6766, 44.5005],
              [-69.729, 44.3777],
              [-69.8185, 44.3917],
              [-69.8878, 44.4032],
              [-69.9441, 44.5378],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314779',
        NAME: 'Regional School Unit 18',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482898629,
        AWATER: 87387835,
        INTPTLAT: '+44.4668954',
        INTPTLON: '-069.7439691',
        ELSDLEA: '',
        UNSDLEA: '14779',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.343, 44.5208],
            [-67.3233, 44.6975],
            [-67.1435, 44.7516],
            [-67.0774, 44.6607],
            [-67.1878, 44.5818],
            [-67.343, 44.5208],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300065',
        NAME: 'Cutler',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121640924,
        AWATER: 183806176,
        INTPTLAT: '+44.6488046',
        INTPTLON: '-067.2248768',
        ELSDLEA: '',
        UNSDLEA: '00065',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.439, 44.6502],
            [-67.4214, 44.7148],
            [-67.3382, 44.7244],
            [-67.3233, 44.6975],
            [-67.343, 44.5208],
            [-67.3888, 44.5202],
            [-67.4209, 44.5943],
            [-67.439, 44.6502],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300067',
        NAME: 'Machiasport',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55460883,
        AWATER: 104097325,
        INTPTLAT: '+44.6281530',
        INTPTLON: '-067.3795443',
        ELSDLEA: '',
        UNSDLEA: '00067',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.169, 43.724],
            [-70.1222, 43.7013],
            [-70.0167, 43.5811],
            [-70.0753, 43.5573],
            [-70.1992, 43.7001],
            [-70.169, 43.724],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300009',
        NAME: 'Long Island',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3715082,
        AWATER: 83116804,
        INTPTLAT: '+43.6769364',
        INTPTLON: '-070.1547563',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.3621, 44.768],
            [-67.186, 44.8258],
            [-67.1435, 44.7516],
            [-67.3233, 44.6975],
            [-67.3382, 44.7244],
            [-67.3621, 44.768],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300068',
        NAME: 'Whiting',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121063625,
        AWATER: 13815757,
        INTPTLAT: '+44.7603219',
        INTPTLON: '-067.2550471',
        ELSDLEA: '',
        UNSDLEA: '00068',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.9216, 46.6865],
            [-67.7889, 46.6895],
            [-67.7882, 46.5999],
            [-67.9089, 46.5971],
            [-67.9216, 46.6865],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2305310',
        NAME: 'Easton',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100266657,
        AWATER: 569958,
        INTPTLAT: '+46.6333043',
        INTPTLON: '-067.8476235',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.0326, 45.8543],
            [-68.0341, 45.7655],
            [-68.1666, 45.766],
            [-68.165, 45.8559],
            [-68.0326, 45.8543],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306030',
        NAME: 'Glenwood Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98820117,
        AWATER: 3565068,
        INTPTLAT: '+45.8098274',
        INTPTLON: '-068.1017158',
        ELSDLEA: '',
        UNSDLEA: '06030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.1662, 45.6733],
            [-68.1668, 45.6204],
            [-68.1804, 45.618],
            [-68.2575, 45.5979],
            [-68.3008, 45.5905],
            [-68.2994, 45.6731],
            [-68.1662, 45.6733],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307770',
        NAME: 'Macwahoc Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76246559,
        AWATER: 420184,
        INTPTLAT: '+45.6328276',
        INTPTLON: '-068.2401015',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.8809, 45.2249],
              [-68.7761, 45.2403],
              [-68.7312, 45.1138],
              [-68.7731, 45.1066],
              [-68.8423, 45.0951],
              [-68.8566, 45.1428],
              [-68.8809, 45.2249],
            ],
          ],
          [
            [
              [-69.0521, 45.2954],
              [-69.0824, 45.4058],
              [-68.9659, 45.4233],
              [-68.9381, 45.3123],
              [-68.9135, 45.2291],
              [-69.0078, 45.2132],
              [-68.9735, 45.1272],
              [-68.9836, 45.1258],
              [-69.1094, 45.1072],
              [-69.1322, 45.1968],
              [-69.0295, 45.2132],
              [-69.0521, 45.2954],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311580',
        NAME: 'School Administrative District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428104499,
        AWATER: 4841494,
        INTPTLAT: '+45.2365246',
        INTPTLON: '-068.9547058',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.5928, 45.5221],
            [-69.507, 45.5353],
            [-69.4732, 45.4198],
            [-69.5369, 45.41],
            [-69.6019, 45.4006],
            [-69.5928, 45.5221],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2306250',
        NAME: 'Greenville',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109637538,
        AWATER: 9852257,
        INTPTLAT: '+45.4621803',
        INTPTLON: '-069.5433184',
        ELSDLEA: '',
        UNSDLEA: '06250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.6828, 45.1949],
            [-69.5469, 45.2127],
            [-69.5192, 45.1196],
            [-69.6458, 45.1013],
            [-69.6548, 45.1],
            [-69.6828, 45.1949],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307110',
        NAME: 'Kingsbury Plantation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114265769,
        AWATER: 1773936,
        INTPTLAT: '+45.1538035',
        INTPTLON: '-069.5984499',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1294, 44.4872],
            [-70.0423, 44.4852],
            [-70.0218, 44.3909],
            [-70.1005, 44.3839],
            [-70.1294, 44.4872],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2300008',
        NAME: 'Fayette',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75539529,
        AWATER: 6055190,
        INTPTLAT: '+44.4340068',
        INTPTLON: '-070.0670336',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1407, 43.7217],
            [-70.1219, 43.7487],
            [-70.0965, 43.7506],
            [-70.1145, 43.7244],
            [-70.1407, 43.7217],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314771',
        NAME: 'Chebeague Island',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7904726,
        AWATER: 0,
        INTPTLAT: '+43.7338618',
        INTPTLON: '-070.1183069',
        ELSDLEA: '',
        UNSDLEA: '14771',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1998, 44.1675],
            [-70.1153, 44.1262],
            [-70.1268, 44.0656],
            [-70.1586, 44.0053],
            [-70.1709, 44.021],
            [-70.2239, 44.092],
            [-70.1998, 44.1675],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2307320',
        NAME: 'Lewiston',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88439118,
        AWATER: 3595481,
        INTPTLAT: '+44.0895941',
        INTPTLON: '-070.1721851',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1326, 44.3714],
            [-70.1005, 44.3839],
            [-70.0218, 44.3909],
            [-70.0423, 44.4852],
            [-69.9441, 44.5378],
            [-69.8878, 44.4032],
            [-69.8185, 44.3917],
            [-69.8271, 44.314],
            [-69.8762, 44.258],
            [-69.9107, 44.2688],
            [-69.8793, 44.3411],
            [-70.017, 44.3593],
            [-70.0413, 44.2841],
            [-70.0743, 44.2902],
            [-70.1326, 44.3714],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2314788',
        NAME: 'Regional School Unit 38',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278973795,
        AWATER: 38619355,
        INTPTLAT: '+44.4012773',
        INTPTLON: '-069.9522918',
        ELSDLEA: '',
        UNSDLEA: '14788',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.8439, 44.3547],
            [-68.7628, 44.4248],
            [-68.7041, 44.3954],
            [-68.7042, 44.302],
            [-68.8612, 44.2967],
            [-68.8439, 44.3547],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2303640',
        NAME: 'Brooksville',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80619469,
        AWATER: 51773778,
        INTPTLAT: '+44.3500242',
        INTPTLON: '-068.7664772',
        ELSDLEA: '',
        UNSDLEA: '03640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.2954, 45.1904],
              [-68.3037, 45.2215],
              [-68.1807, 45.2372],
              [-68.055, 45.2526],
              [-67.9819, 44.9559],
              [-68.105, 44.9391],
              [-68.0823, 44.8499],
              [-68.0573, 44.7615],
              [-68.0161, 44.6767],
              [-67.9936, 44.5922],
              [-67.9918, 44.5045],
              [-68.1031, 44.4899],
              [-68.1573, 44.5668],
              [-68.181, 44.7429],
              [-68.2464, 45.0105],
              [-68.3759, 44.9974],
              [-68.3969, 45.0833],
              [-68.2705, 45.1001],
              [-68.2954, 45.1904],
            ],
          ],
          [
            [
              [-68.5011, 44.9807],
              [-68.3759, 44.9974],
              [-68.3493, 44.9054],
              [-68.478, 44.8891],
              [-68.4918, 44.944],
              [-68.5011, 44.9807],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2382011',
        NAME: 'Hancock Unorganized Territory',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1293019241,
        AWATER: 99568729,
        INTPTLAT: '+44.9339475',
        INTPTLON: '-068.1665485',
        ELSDLEA: '',
        UNSDLEA: '82011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7925, 44.4937],
            [-68.6255, 44.5408],
            [-68.5995, 44.4931],
            [-68.6967, 44.4063],
            [-68.7041, 44.3954],
            [-68.7628, 44.4248],
            [-68.801, 44.462],
            [-68.7925, 44.4937],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309630',
        NAME: 'Penobscot',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103266887,
        AWATER: 17299813,
        INTPTLAT: '+44.4827026',
        INTPTLON: '-068.7059970',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.5482, 44.1295],
            [-68.5169, 44.1927],
            [-68.493, 44.2158],
            [-68.4072, 44.201],
            [-68.3358, 44.0506],
            [-68.4846, 44.0283],
            [-68.5482, 44.1295],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2311910',
        NAME: 'School Administrative District 76',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36214634,
        AWATER: 187214134,
        INTPTLAT: '+44.1315785',
        INTPTLON: '-068.4683224',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7042, 44.302],
            [-68.7041, 44.3954],
            [-68.6967, 44.4063],
            [-68.578, 44.3447],
            [-68.6322, 44.2704],
            [-68.7042, 44.302],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312140',
        NAME: 'Sedgwick',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69909398,
        AWATER: 10552461,
        INTPTLAT: '+44.3468802',
        INTPTLON: '-068.6343652',
        ELSDLEA: '',
        UNSDLEA: '12140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.6255, 44.5408],
            [-68.5959, 44.5569],
            [-68.4346, 44.4759],
            [-68.4397, 44.3847],
            [-68.4711, 44.362],
            [-68.4806, 44.443],
            [-68.5995, 44.4931],
            [-68.6255, 44.5408],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312750',
        NAME: 'Surry',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95759828,
        AWATER: 36665063,
        INTPTLAT: '+44.4674660',
        INTPTLON: '-068.5073242',
        ELSDLEA: '',
        UNSDLEA: '12750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.4346, 44.4759],
            [-68.3431, 44.444],
            [-68.4198, 44.3856],
            [-68.4397, 44.3847],
            [-68.4346, 44.4759],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2312980',
        NAME: 'Trenton',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47070516,
        AWATER: 26459794,
        INTPTLAT: '+44.4655406',
        INTPTLON: '-068.3909002',
        ELSDLEA: '',
        UNSDLEA: '12980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.5365, 45.245],
            [-67.4547, 45.1495],
            [-67.574, 45.1147],
            [-67.6276, 45.2105],
            [-67.5365, 45.245],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2309990',
        NAME: 'Princeton',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95439697,
        AWATER: 12804859,
        INTPTLAT: '+45.1431088',
        INTPTLON: '-067.5265890',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.4998, 44.6629],
            [-67.4735, 44.6527],
            [-67.439, 44.6502],
            [-67.4209, 44.5943],
            [-67.5424, 44.6143],
            [-67.4998, 44.6629],
          ],
        ],
      },
      properties: {
        STATEFP: '23',
        SCSDLEA: '',
        GEOID: '2310320',
        NAME: 'Roque Bluffs',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26901462,
        AWATER: 23678838,
        INTPTLAT: '+44.6176685',
        INTPTLON: '-067.4921940',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
  ],
};

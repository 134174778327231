const ComponentConstants = {
  /** ID of the "LTOP" dashboard component (Map) */
  ID_LTOP: 1,

  /** ID of the "LBOT" dashboard component, in Line mode */
  ID_LBOT_LINE: 2,

  /** ID of the "LBOT" dashboard component, in Bar mode */
  ID_LBOT_BAR: 3,

  /** ID of the "RTOP" dashboard component (Demographics) */
  ID_RTOP: 4,

  /** ID of the "RMID" dashboard component ('point-in-time') */
  ID_RMID: 5,

  /** ID of the "RBOT" dashboard component (MLI) */
  ID_RBOT: 6,

  // MAP view modes
  /** View mode constant for specifying that the Map should display in 'States' view */
  VIEW_STATE: 'STATE',

  /** View mode constant for specifying that the Map should display in 'District' view */
  VIEW_DISTRICT: 'DISTRICT',

  // Demographics view modes
  /** View mode constant for specifying that the Demographics should display in 'Waffle' view */
  VIEW_WAFFLE: 'WAFFLE',

  /** View mode constant for specifying that the Demographics should display in 'Donut' view */
  VIEW_DONUT: 'DONUT',

  // 'lbot' view modes
  /** View mode constant for specifying that the LBOT section should display in 'Line' view */
  VIEW_LINE: 'LINE',

  /** View mode constant for specifying that the LBOT section should display in 'Bar' view */
  VIEW_BAR: 'BAR',

  SAMPLE_SIZE_LIMIT_WARN: 50,
  SAMPLE_SIZE_LIMIT_SUPPRESS: 20,

  MAX_NON_RESPONSE_LIMIT: 20,
};

export default ComponentConstants;

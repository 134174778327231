/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from '../Components/SectionTitle';
import Wizard from '../Components/Wizard';
import { setWizard } from '../actions/wizard.action';

const NewSurvey = () => {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt);
  const currentUser = jwtDecode(jwt);

  useEffect(() => {
    dispatch(setWizard('RAND', currentUser));
  }, []);
  return (
    <div className="NewSurvey">
      <SectionTitle title="Create New Survey" />
      <Wizard vendorSelect />
    </div>
  );
};

export default NewSurvey;

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import LoginForm from '../Components/LoginForm';
import './css/login.css';

function Login() {
  const [showModal, setShowModal] = useState(false);
  const [disableLogin, setDisableLogin] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    const handleResize = () => {
      setShowModal(false);
      setDisableLogin(false);
      if (window.innerWidth < 431) {
        setShowModal(true);
        setDisableLogin(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="Login">
      <Container>
        <Row>
          <Col>
            <Container>
              <Row className="flex-column mt-2">
                <Col className="Login-info-logo">
                  <img src="/bento-logo.png" alt="Bento Logo" />
                  <span>Bento</span>
                </Col>
                <Col className="Login-info-welcome">Welcome to</Col>
                <h2 className="Login-info-title">Bento</h2>
                <h3 className="Login-info-subtitle">The Benchmarking Tool</h3>
              </Row>
              <Row className="mt-4">
                <LoginForm disableLogin={disableLogin} />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal centered show={showModal} onHide={toggleModal} backdrop="static">
        <Modal.Header closeButton>
          <img src="/bento-logo.png" alt="Bento Logo" />
          <span>Bento</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>Bento is best viewed on a laptop screen or larger.</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="xl" onClick={toggleModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;

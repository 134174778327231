function orderSections(a, b) {
  if (a.Data.name === b.Data.name) {
    return 0;
  }
  return a.Data.name < b.Data.name ? -1 : 1;
}

export const sortSections = (sections) => {
  return sections.sort(orderSections);
};

export const getSectionCodeByNameAndSurveyData = (name, surveyData) => {
  const sectionsArray = surveyData.surveySummaryDetailsDto.sections.split(',');
  const sectionCodesArray = surveyData.surveySummaryDetailsDto.sectionCodes.split(',');

  const sectionIndex = sectionsArray
    .map((section) => section.trim().toLowerCase())
    .indexOf(name.trim().toLowerCase());

  if (sectionIndex !== -1) {
    return sectionCodesArray[sectionIndex].toLowerCase().trim();
  } 

  return null;
};

export const removeDuplicatedSections = (sections) => {
  return sections.filter(
    (section, index) => index === sections.findIndex((s) => s.Data.name === section.Data.name)
  );
};

export const removeEmptyTopicSections = (topicSections, surveys) => {
  const filteredTopicSections = topicSections.filter((topicSection) => {
    const filteredSurveys = surveys.filter((survey) =>
      survey.surveySummaryDetailsDto?.sectionCodes
        .trim()
        .toLowerCase()
        .split(', ')
        .includes(topicSection.Data.code.trim().toLowerCase())
    );
    return filteredSurveys && filteredSurveys.length > 0;
  });

  return filteredTopicSections.sort(orderSections);
};

export const removeExcludedSurveysForSection = (surveys, section) => {
  // if the section is an override, it will have a property sectionKeyId
  const selectedSection = section.sectionKeyId
    ? section.Data.overrides[section.sectionKeyId]
    : section.Data;

  // Allowed surveys will have precedence over excluded surveys
  if (selectedSection.allowedSurveys && selectedSection.allowedSurveys.length > 0) {
    return surveys.filter((survey) =>
      selectedSection.allowedSurveys.map(String).includes(survey.id.toString())
    );
  }

  // eslint-disable-next-line no-use-before-define
  return surveys.filter((survey) => !isAnExcludedSurveyForSection(survey.id, selectedSection));
};

export const isAnExcludedSectionForTopic = (topicId, section) => {
  // Use of the new excludedTopics field
  if (section.excludedTopics && section.excludedTopics.includes(topicId)) {
    return true;
  }

  return false;
};

export const isAnExcludedSurveyForSection = (surveyId, section) => {
  // Use of the new excludedSurveys field
  if (
    section.excludedSurveys &&
    section.excludedSurveys.some((survey) => survey.toString() === surveyId.toString())
  ) {
    return true;
  }

  return false;
};

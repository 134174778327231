import React from 'react';

import UsersList from './components/UserList';
import SectionTitle from '../../Components/SectionTitle';

const ManageUsers = () => (
  <>
    <SectionTitle title="Manage Users" />
    <UsersList />
  </>
);

export default ManageUsers;

import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import ChangePasswordForm from '../Components/ChangePasswordForm';
import './css/login.css';

export default function Activate() {
  const jwt = useSelector((state) => state.session.jwt);
  const jwtObj = jwtDecode(jwt);

  return (
    <Container fluid className="p-0 mt-3">
      <Container>
        <Row>
          <h3 className="profile-title">
            {jwtObj.firstName} {jwtObj.lastName}
          </h3>
        </Row>
        <Row className="mt-4">
          <ChangePasswordForm />
        </Row>
      </Container>
    </Container>
  );
}

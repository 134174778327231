import {
  createSectionApi,
  getSectionByIdApi,
  getSectionsApi,
  updateSectionApi,
} from '../api/sections';
import types from '../types';
import { prepareSectionMetadata } from './helpers/section.helper';

export const getSections = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SECTION_LIST_PENDING });
    const data = await getSectionsApi(jwt);
    dispatch({
      type: types.reducerTypes.SECTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SECTION_LIST_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const getSectionById = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SECTION_ID_PENDING });
    const data = await getSectionByIdApi(id, jwt);
    dispatch({
      type: types.reducerTypes.SECTION_ID_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SECTION_ID_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const createSection = (metadata, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SECTION_CREATE_PENDING });
    await createSectionApi(metadata, jwt);
    dispatch({
      type: types.reducerTypes.SECTION_CREATE_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SECTION_CREATE_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const updateSection = (sectionId, metadata, oldMetadata, survey, jwt) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.reducerTypes.SECTION_UPDATE_PENDING });
    // Send the new description (name) as an override, or update it if it already exists
    const finalMetadata = prepareSectionMetadata(metadata, oldMetadata, survey);

    await updateSectionApi(sectionId, finalMetadata, jwt);
    dispatch({
      type: types.reducerTypes.SECTION_UPDATE_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SECTION_UPDATE_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};
export const hideErrorToast = () => ({ type: types.reducerTypes.SURVEY_SUMMARY_HIDE_ERROR_TOAST });

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setChartConfig } from '../../../../actions/fileWizard.action';
import LineConfig from './LineConfig';
import ColumnConfig from './ColumnConfig';
import BarConfig from './BarConfig';

const ChartsConfig = ({ chart }) => {
  const { dataSources } = useSelector((state) => state.fileWizard);
  const dispatch = useDispatch();

  const setGraphDimension = (e) => {
    const { target } = e;
    const chartType = chart;
    dispatch(setChartConfig(target, chartType));
  };

  const isChecked = (sampleIdentifier, metadataId, type, inputName) => {
    if (dataSources.length <= 0) return false;
    const metadataToModify = dataSources.filter((el) => el.metadataId === metadataId);
    if (metadataToModify.length <= 0) return false;
    const { configuration } = metadataToModify[0];
    if (!configuration) return false;
    const chartType = configuration[`${type}`];
    if (!chartType) return false;
    const chartValue = chartType[`${inputName}`];
    if (chartValue === sampleIdentifier) return true;
    return false;
  };

  useEffect(() => () => {
    // TODO Reset Everything here
  }, []);

  const renderConfig = (chartType) => {
    switch (chartType) {
      case 'column': {
        return (
          <ColumnConfig
            onClickHandler={setGraphDimension}
            isChecked={isChecked}
          />
        );
      }
      case 'line': {
        return (
          <LineConfig
            onClickHandler={setGraphDimension}
            isChecked={isChecked}
          />
        );
      }
      case 'bar': {
        return (
          <BarConfig
            onClickHandler={setGraphDimension}
            isChecked={isChecked}
          />
        );
      }
      default:
        return null;
    }
  };
  return (
    <>
      {renderConfig(chart)}
    </>
  );
};

ChartsConfig.propTypes = {
  chart: PropTypes.node.isRequired,
};

export default ChartsConfig;

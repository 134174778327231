import types from '../types';

export const newError = (errorType) => (dispatch) => {
  if (!errorType) return;
  dispatch({ type: types.reducerTypes.NEW_ERROR, errorType });
};

export const resetErrors = (reducer = '') => (dispatch) => {
  switch (reducer) {
    case 'surveys': {
      break;
    }
    case 'all': {
      dispatch({ type: types.reducerTypes.RESET_ALL_ERRORS });
      break;
    }
    default:
  }
};

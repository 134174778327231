import Filter from './filter';

class MliVisualizationDetails {
  constructor(details, groups) {
    this.surveyDatasetName = details.surveyDatasetName;
    this.segments = details.segments;
    this.activeSectionId = details.activeSectionId;
    this.scheme = details?.scheme;
    this.chartSettings = details.chartSettings;
    this.navSettings = details.navSettings;
    this.enableDefaultSegment = true;
    this.type = 1;
    this.filter = new Filter(groups);
  }

  getFilter() {
    return this.filter;
  }

  getSegments() {
    return this.segments;
  }

  isFiltered() {
    return (
      this.filter != null &&
      this.filter.getConstraintGroups() != null &&
      this.filter.getConstraintGroups().length > 0
    );
  }

  getType() {
    return this.type;
  }

  getActiveSectionId() {
    return this.activeSectionId;
  }

  getScheme() {
    return this.scheme;
  }

  getNavConfig() {
    return this.navConfig;
  }

  getChartSettings() {
    return this.chartSettings;
  }

  getSurveyDatasetName() {
    return this.surveyDatasetName;
  }

  getEnableDefaultSegment() {
    return this.enableDefaultSegment;
  }

  enableNationalBenchmarks() {
    return !this.filter.getConstraintGroups().isEmpty();
  }

  // Return true if:
  //   There is more than one constraint
  //   There is EXACTLY ONE Location constraint
  enableStateBenchmarks() {
    if (this.enableNationalBenchmarks() && this.filter.getConstraintGroups().length > 1) {
      let locations = 0;
      this.filter.getConstraintGroups().forEach((el) => {
        if (el.getType() === 'Location') {
          locations += 1;
        }
      });
      return locations === 1;
    }
    return false;
  }

  toString() {
    return `MliVisualizationDetails [f=${this.filter}, s=${this.segments}, defaultSegment=${this.enableDefaultSegment}]`;
  }

  hashCode() {
    const prime = 31;
    let result = 1;
    result = prime * result + (this.enableDefaultSegment ? 1231 : 1237);
    result = prime * result + (this.filter == null ? 0 : this.filter.hashCode());
    result = prime * result + (this.segments == null ? 0 : this.segments.hashCode());
    return result;
  }

  equals(obj) {
    if (this === obj) {
      return true;
    }
    if (obj == null) {
      return false;
    }
    if (!(obj instanceof MliVisualizationDetails)) {
      return false;
    }
    const other = obj;
    if (this.enableDefaultSegment !== other.enableDefaultSegment) {
      return false;
    }
    if (this.filter == null) {
      if (other.filter != null) {
        return false;
      }
    } else if (!this.filter.equals(other.filter)) {
      return false;
    }
    if (this.segments == null) {
      if (other.segments != null) {
        return false;
      }
    } else if (!this.segments.equals(other.segments)) {
      return false;
    }
    return true;
  }
}

export default MliVisualizationDetails;

import React from 'react';
import PropTypes from 'prop-types';

const InputUploadLabel = ({ label, fileType, required }) => (
  <>
    <span className="custom-form-label">
      {label}
      <span className="custom-form-file-type">{` (.${fileType}) ${required ? '*' : ''} `}</span>
    </span>
  </>
);

InputUploadLabel.propTypes = {
  label: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default InputUploadLabel;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import Spinner from '../Spinner';
import { changePasswordApi } from '../../api/users';
import './style.css';

const ChangePasswordForm = () => {
  const [validated, setValidated] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);
  const storeJwt = useSelector((state) => state.session.jwt);

  const validateAndSend = async () => {
    setIsSending(true);
    const result = await changePasswordApi({ newPassword, oldPassword }, storeJwt);
    setIsSending(false);
    if (result.errorMessage) {
      setShowPasswordError(true);
    } else {
      setShowPasswordSuccess(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInvalidConfirmPassword(false);
    setInvalidPassword(false);
    const form = event.currentTarget;
    const passwordStrongRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    const passwordsNotMatch = newPassword !== confirmPassword;
    const validPassword = passwordStrongRegExp.test(newPassword);
    if (passwordsNotMatch) {
      setInvalidConfirmPassword(true);
    }
    if (!validPassword) {
      setInvalidPassword(true);
    }
    if (form.checkValidity() === false || passwordsNotMatch || !validPassword) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      validateAndSend();
    }
    setValidated(true);
  };

  const handleChangePassword = (e) => setNewPassword(e.target.value);

  const handleChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);

  const handleChangeOldPassword = (e) => setOldPassword(e.target.value);

  return (
    <Container className="w-50 bg-white p-4 m-0">
      <h3 className="Login-info-subtitle mb-3">Set a new password</h3>
      {isSending && <Spinner size="lg" />}
      {showPasswordSuccess && (
        <Alert variant="success">
          <p className="m-0">Password changed successfully!</p>
        </Alert>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            required
            type="password"
            placeholder="Current Password *"
            name="currentPassword"
            size="md"
            className="mb-2"
            maxLength="100"
            disabled={isSending}
            onChange={handleChangeOldPassword}
          />
          <Form.Control.Feedback type="invalid">
            Please complete your current password.
          </Form.Control.Feedback>
          {showPasswordError && (
            <p className="m-0 invalid-feedback d-block">Current password is not valid</p>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            required
            type="password"
            placeholder="Password *"
            name="password"
            size="md"
            className="mb-2"
            maxLength="100"
            disabled={isSending}
            onChange={handleChangePassword}
          />
          <Form.Control.Feedback type="invalid">
            Please complete your password
          </Form.Control.Feedback>
          {invalidPassword && (
            <p className="m-0 invalid-feedback d-block">Password must fit the requirements.</p>
          )}
          <p className="text-black-50 font-size-10 mb-1">
            Must be at least 8 characters long. Must include uppercase and lowercase letters.
          </p>
          <p className="text-black-50 font-size-10 mb-3">
            Must include at least one number. Must include at least one special character: !@#$%^&*
          </p>
        </Form.Group>
        <Form.Group>
          <Form.Control
            required
            type="password"
            placeholder="Confirm Password *"
            name="confirmPassword"
            size="md"
            className="mb-2"
            maxLength="100"
            disabled={isSending}
            onChange={handleChangeConfirmPassword}
          />
          <Form.Control.Feedback type="invalid">Please confirm the password.</Form.Control.Feedback>
          {invalidConfirmPassword && (
            <p className="m-0 invalid-feedback d-block">Passwords do not match</p>
          )}
        </Form.Group>
        <Form.Group>
          <Container className="p-0">
            <Button type="button" href="/profile" className="mr-3 rounded-0" disabled={isSending}>
              <span className="button-text">Cancel</span>
            </Button>
            <Button type="submit" className="bento-btn" disabled={isSending}>
              <span className="button-text">Change Password</span>
            </Button>
          </Container>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default ChangePasswordForm;

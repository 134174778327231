export const AZ = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2687, 33.0298],
            [-114.2678, 33.4636],
            [-113.958, 33.4647],
            [-113.958, 33.3776],
            [-113.6447, 33.3775],
            [-113.3339, 33.3774],
            [-113.3334, 33.2046],
            [-113.3338, 33.117],
            [-113.3338, 32.8251],
            [-113.3337, 32.7679],
            [-113.3337, 32.5936],
            [-114.0215, 32.5914],
            [-114.2905, 32.591],
            [-114.2911, 32.591],
            [-114.2614, 32.6691],
            [-114.2687, 33.0298],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '00720',
        GEOID: '400720',
        NAME: 'Antelope Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7937908278,
        AWATER: 3400,
        INTPTLAT: '+32.9920489',
        INTPTLON: '-113.8173717',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2037, 32.9417],
            [-112.2028, 32.9417],
            [-112.1, 32.9696],
            [-111.963, 32.9699],
            [-111.9275, 33.0289],
            [-111.9615, 33.1177],
            [-112.202, 33.1167],
            [-112.202, 33.2033],
            [-112.099, 33.231],
            [-112.0835, 33.2046],
            [-111.8931, 33.2048],
            [-111.7892, 33.2047],
            [-111.7892, 33.1179],
            [-111.6688, 33.0898],
            [-111.6691, 32.9961],
            [-111.6705, 32.9234],
            [-111.5667, 32.9232],
            [-111.5675, 32.8503],
            [-111.5676, 32.8067],
            [-111.5677, 32.7922],
            [-111.568, 32.7196],
            [-111.706, 32.7193],
            [-111.7589, 32.6752],
            [-111.7804, 32.6753],
            [-111.7815, 32.6754],
            [-111.7845, 32.6753],
            [-111.7926, 32.6753],
            [-111.7925, 32.5068],
            [-111.7946, 32.5068],
            [-111.8614, 32.5067],
            [-112.2037, 32.5068],
            [-112.2037, 32.9417],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '01740',
        GEOID: '401740',
        NAME: 'Casa Grande Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3365445185,
        AWATER: 1173327,
        INTPTLAT: '+32.8391529',
        INTPTLON: '-111.9208947',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6271, 33.4336],
            [-114.558, 33.5326],
            [-114.5249, 33.6388],
            [-114.4766, 33.6802],
            [-114.3626, 33.6802],
            [-114.3485, 33.7809],
            [-114.2201, 34.1058],
            [-114.1973, 34.1251],
            [-113.9798, 34.1251],
            [-113.9799, 34.0703],
            [-113.6655, 34.0702],
            [-113.6651, 34.213],
            [-113.5395, 34.3064],
            [-113.3705, 34.292],
            [-113.3335, 34.3179],
            [-113.3338, 33.9992],
            [-113.334, 33.6386],
            [-113.3335, 33.552],
            [-113.3339, 33.3774],
            [-113.6447, 33.3775],
            [-113.958, 33.3776],
            [-113.958, 33.4647],
            [-114.2678, 33.4636],
            [-114.2687, 33.0298],
            [-114.5165, 33.0276],
            [-114.6708, 33.038],
            [-114.7079, 33.0974],
            [-114.677, 33.2702],
            [-114.7201, 33.4079],
            [-114.6271, 33.4336],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '01160',
        GEOID: '401160',
        NAME: 'Bicentennial Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9842304876,
        AWATER: 22988821,
        INTPTLAT: '+33.6694969',
        INTPTLON: '-113.9521527',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7926, 32.6753],
            [-111.7845, 32.6753],
            [-111.7815, 32.6754],
            [-111.7804, 32.6753],
            [-111.7589, 32.6752],
            [-111.706, 32.7193],
            [-111.568, 32.7196],
            [-111.5677, 32.7922],
            [-111.5676, 32.8067],
            [-111.5675, 32.8503],
            [-111.4647, 32.8506],
            [-111.3615, 32.8497],
            [-111.3613, 32.7629],
            [-111.2597, 32.7441],
            [-111.0892, 32.5985],
            [-110.9993, 32.5984],
            [-110.9992, 32.511],
            [-111.0297, 32.5112],
            [-111.4657, 32.5013],
            [-111.5908, 32.5068],
            [-111.7925, 32.5068],
            [-111.7926, 32.6753],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '07530',
        GEOID: '407530',
        NAME: 'Santa Cruz Valley Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1857330613,
        AWATER: 3443533,
        INTPTLAT: '+32.6430009',
        INTPTLON: '-111.4370935',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.8003, 32.5888],
            [-114.78, 32.6327],
            [-114.7196, 32.7188],
            [-114.6504, 32.733],
            [-114.5269, 32.7571],
            [-114.4629, 32.9079],
            [-114.5165, 33.0276],
            [-114.2687, 33.0298],
            [-114.2614, 32.6691],
            [-114.2911, 32.591],
            [-114.2905, 32.591],
            [-114.0215, 32.5914],
            [-113.3337, 32.5936],
            [-113.3338, 32.0386],
            [-113.9294, 32.2246],
            [-114.6215, 32.4366],
            [-114.7355, 32.471],
            [-114.8136, 32.4943],
            [-114.8003, 32.5888],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '09630',
        GEOID: '409630',
        NAME: 'Yuma Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6343283514,
        AWATER: 13167916,
        INTPTLAT: '+32.5295065',
        INTPTLON: '-114.0048912',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.683, 35.6764],
            [-114.4656, 35.6765],
            [-114.4614, 35.3281],
            [-114.4084, 35.3283],
            [-114.4008, 35.1544],
            [-114.2443, 35.1563],
            [-114.2448, 34.8939],
            [-114.2443, 34.8058],
            [-114.1925, 34.8053],
            [-114.1933, 34.6211],
            [-114.3505, 34.622],
            [-114.3517, 34.7615],
            [-114.4041, 34.7615],
            [-114.4389, 34.6223],
            [-114.4716, 34.713],
            [-114.5749, 34.8081],
            [-114.6283, 34.8636],
            [-114.6335, 35.0019],
            [-114.6307, 35.0403],
            [-114.5695, 35.1623],
            [-114.5952, 35.3219],
            [-114.6789, 35.5013],
            [-114.6539, 35.6117],
            [-114.683, 35.6764],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '00082',
        GEOID: '400082',
        NAME: 'Colorado River Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2711195786,
        AWATER: 74672820,
        INTPTLAT: '+35.1052137',
        INTPTLON: '-114.4678343',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1005, 33.3196],
            [-112.1005, 33.333],
            [-112.1005, 33.3495],
            [-112.0382, 33.3633],
            [-112.0316, 33.3692],
            [-112.0166, 33.3707],
            [-111.9971, 33.3707],
            [-111.9955, 33.4261],
            [-111.9952, 33.4294],
            [-111.9955, 33.4299],
            [-111.9955, 33.436],
            [-111.9955, 33.4371],
            [-111.9853, 33.4371],
            [-111.9789, 33.4371],
            [-111.9563, 33.451],
            [-111.9534, 33.451],
            [-111.9505, 33.451],
            [-111.9503, 33.451],
            [-111.9501, 33.451],
            [-111.95, 33.451],
            [-111.944, 33.4511],
            [-111.892, 33.4513],
            [-111.8946, 33.3638],
            [-111.8931, 33.3349],
            [-111.8931, 33.2048],
            [-112.0835, 33.2046],
            [-112.099, 33.231],
            [-112.1005, 33.3196],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '08340',
        GEOID: '408340',
        NAME: 'Tempe Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428686713,
        AWATER: 2553151,
        INTPTLAT: '+33.3111998',
        INTPTLON: '-111.9835387',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3335, 33.552],
            [-113.0211, 33.551],
            [-113.0216, 33.3774],
            [-112.7638, 33.3775],
            [-112.7639, 33.4644],
            [-112.6257, 33.4645],
            [-112.5305, 33.4645],
            [-112.5129, 33.4646],
            [-112.4442, 33.4646],
            [-112.3751, 33.3917],
            [-112.3062, 33.3626],
            [-112.3061, 33.2904],
            [-112.3063, 33.2033],
            [-112.3063, 33.1162],
            [-112.5113, 33.1161],
            [-112.6843, 33.1163],
            [-112.8283, 33.1165],
            [-112.8284, 33.2035],
            [-113.0266, 33.2044],
            [-113.3334, 33.2046],
            [-113.3339, 33.3774],
            [-113.3335, 33.552],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '01410',
        GEOID: '401410',
        NAME: 'Buckeye Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3165182876,
        AWATER: 214246,
        INTPTLAT: '+33.3198987',
        INTPTLON: '-112.8117940',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2378, 33.4726],
            [-112.2377, 33.5088],
            [-112.1864, 33.5096],
            [-112.1514, 33.5385],
            [-112.0997, 33.5241],
            [-112.0824, 33.5239],
            [-112.048, 33.555],
            [-112.0127, 33.5537],
            [-111.9961, 33.5537],
            [-111.9955, 33.4804],
            [-111.944, 33.4511],
            [-111.95, 33.451],
            [-111.9501, 33.451],
            [-111.9503, 33.451],
            [-111.9505, 33.451],
            [-111.9534, 33.451],
            [-111.9563, 33.451],
            [-111.9789, 33.4371],
            [-111.9853, 33.4371],
            [-111.9955, 33.4371],
            [-111.9955, 33.436],
            [-111.9955, 33.4299],
            [-111.9952, 33.4294],
            [-111.9955, 33.4261],
            [-111.9971, 33.3707],
            [-112.0166, 33.3707],
            [-112.0316, 33.3692],
            [-112.0382, 33.3633],
            [-112.1005, 33.3495],
            [-112.1005, 33.333],
            [-112.1005, 33.3196],
            [-112.2291, 33.3832],
            [-112.237, 33.3919],
            [-112.2041, 33.401],
            [-112.1866, 33.4515],
            [-112.238, 33.4653],
            [-112.2378, 33.4726],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '06330',
        GEOID: '406330',
        NAME: 'Phoenix Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 449331865,
        AWATER: 1962849,
        INTPTLAT: '+33.4408648',
        INTPTLON: '-112.0990258',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.169, 33.5675],
            [-112.1691, 33.639],
            [-112.0828, 33.6402],
            [-112.048, 33.555],
            [-112.0824, 33.5239],
            [-112.0997, 33.5241],
            [-112.1514, 33.5385],
            [-112.1864, 33.5096],
            [-112.2377, 33.5088],
            [-112.2377, 33.5377],
            [-112.169, 33.5675],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '03450',
        GEOID: '403450',
        NAME: 'Glendale Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153669443,
        AWATER: 412847,
        INTPTLAT: '+33.5743168',
        INTPTLON: '-112.1351943',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3751, 33.3917],
            [-112.3326, 33.4642],
            [-112.3238, 33.4642],
            [-112.3065, 33.5365],
            [-112.2377, 33.5377],
            [-112.2377, 33.5088],
            [-112.2378, 33.4726],
            [-112.238, 33.4653],
            [-112.1866, 33.4515],
            [-112.2041, 33.401],
            [-112.237, 33.3919],
            [-112.2291, 33.3832],
            [-112.2292, 33.2904],
            [-112.3061, 33.2904],
            [-112.3062, 33.3626],
            [-112.3751, 33.3917],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '08520',
        GEOID: '408520',
        NAME: 'Tolleson Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264915187,
        AWATER: 636237,
        INTPTLAT: '+33.4119203',
        INTPTLON: '-112.2743781',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5135, 33.5515],
            [-112.3065, 33.5365],
            [-112.3238, 33.4642],
            [-112.3326, 33.4642],
            [-112.3751, 33.3917],
            [-112.4442, 33.4646],
            [-112.5129, 33.4646],
            [-112.5135, 33.5515],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '00450',
        GEOID: '400450',
        NAME: 'Agua Fria Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244600228,
        AWATER: 781020,
        INTPTLAT: '+33.4814273',
        INTPTLON: '-112.4078710',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3345, 34.973],
            [-111.9121, 34.9763],
            [-111.7778, 34.981],
            [-111.7777, 34.9339],
            [-111.8833, 34.9342],
            [-111.8833, 34.8036],
            [-111.8531, 34.7331],
            [-111.8536, 34.6566],
            [-111.977, 34.6451],
            [-112.0905, 34.6576],
            [-112.2016, 34.7156],
            [-112.2007, 34.8031],
            [-112.3076, 34.8024],
            [-112.3345, 34.973],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '05070',
        GEOID: '405070',
        NAME: 'Mingus Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1320336275,
        AWATER: 401767,
        INTPTLAT: '+34.8350635',
        INTPTLON: '-112.0838977',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9626, 31.9347],
            [-109.9379, 32.0499],
            [-109.8478, 32.0505],
            [-109.7447, 31.9055],
            [-109.6953, 31.9926],
            [-109.5931, 31.9488],
            [-109.4405, 31.9489],
            [-109.3722, 31.9057],
            [-109.3368, 31.8176],
            [-109.5379, 31.7872],
            [-109.5298, 31.6853],
            [-109.5438, 31.6425],
            [-109.7036, 31.6418],
            [-109.7728, 31.6861],
            [-109.7615, 31.7587],
            [-109.8645, 31.7582],
            [-109.9626, 31.9347],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '08910',
        GEOID: '408910',
        NAME: 'Valley Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1469476869,
        AWATER: 694059,
        INTPTLAT: '+31.8655400',
        INTPTLON: '-109.6700375',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9575, 31.7267],
            [-110.8898, 31.7268],
            [-110.8724, 31.7267],
            [-110.8556, 31.7267],
            [-110.8071, 31.7317],
            [-110.4517, 31.7313],
            [-110.4517, 31.7307],
            [-110.4607, 31.5851],
            [-110.4599, 31.4281],
            [-110.4602, 31.3328],
            [-110.761, 31.3336],
            [-110.7606, 31.4703],
            [-110.8736, 31.4845],
            [-110.9594, 31.4875],
            [-110.9575, 31.7267],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '06030',
        GEOID: '406030',
        NAME: 'Patagonia Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1795973227,
        AWATER: 1646873,
        INTPTLAT: '+31.5472872',
        INTPTLON: '-110.6799581',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2016, 34.7156],
            [-112.2007, 34.8031],
            [-111.9747, 34.8036],
            [-112.0907, 34.7301],
            [-112.0905, 34.6576],
            [-112.2016, 34.7156],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400004',
        NAME: 'Clarkdale-Jerome Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211390865,
        AWATER: 380983,
        INTPTLAT: '+34.7488572',
        INTPTLON: '-112.1120281',
        ELSDLEA: '00004',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.9758, 33.782],
            [-113.7667, 33.7827],
            [-113.7658, 33.8119],
            [-113.5839, 33.8122],
            [-113.5406, 33.7393],
            [-113.5403, 33.6527],
            [-113.6448, 33.6524],
            [-113.6447, 33.3775],
            [-113.958, 33.3776],
            [-113.958, 33.4647],
            [-113.9575, 33.7242],
            [-113.9758, 33.782],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400005',
        NAME: 'Salome Consolidated Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1551914513,
        AWATER: 37430,
        INTPTLAT: '+33.5987726',
        INTPTLON: '-113.8244639',
        ELSDLEA: '00005',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8489, 34.2276],
            [-109.8486, 34.3015],
            [-109.5342, 34.3009],
            [-109.5442, 34.1252],
            [-109.8494, 34.1259],
            [-109.8489, 34.2276],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400022',
        NAME: 'Vernon Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 565402511,
        AWATER: 517748,
        INTPTLAT: '+34.2156642',
        INTPTLON: '-109.6915379',
        ELSDLEA: '00022',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3338, 33.9992],
            [-113.142, 33.9997],
            [-113.142, 33.7246],
            [-113.1274, 33.6386],
            [-113.334, 33.6386],
            [-113.3338, 33.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400480',
        NAME: 'Aguila Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 724565824,
        AWATER: 0,
        INTPTLAT: '+33.8164844',
        INTPTLON: '-113.2360137',
        ELSDLEA: '00480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1514, 33.5385],
            [-112.0997, 33.5241],
            [-112.0999, 33.4878],
            [-112.1171, 33.4733],
            [-112.1517, 33.495],
            [-112.1864, 33.5096],
            [-112.1514, 33.5385],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400600',
        NAME: 'Alhambra Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32841861,
        AWATER: 53404,
        INTPTLAT: '+33.5085619',
        INTPTLON: '-112.1364587',
        ELSDLEA: '00600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0466, 33.908],
            [-109.0469, 33.7782],
            [-109.221, 33.7778],
            [-109.3481, 33.7775],
            [-109.4954, 33.6527],
            [-109.4967, 33.6524],
            [-109.4951, 33.7775],
            [-109.4363, 33.8216],
            [-109.2455, 33.8218],
            [-109.2453, 33.909],
            [-109.0466, 33.908],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400630',
        NAME: 'Alpine Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452290498,
        AWATER: 1413906,
        INTPTLAT: '+33.8042419',
        INTPTLON: '-109.2452933',
        ELSDLEA: '00630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3335, 31.642],
            [-109.3251, 31.657],
            [-109.3253, 31.729],
            [-109.2237, 31.7292],
            [-109.1579, 31.8155],
            [-109.0491, 31.8417],
            [-109.0493, 31.5525],
            [-109.3353, 31.553],
            [-109.3335, 31.642],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400750',
        NAME: 'Apache Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657812135,
        AWATER: 1250405,
        INTPTLAT: '+31.6685669',
        INTPTLON: '-109.1614324',
        ELSDLEA: '00750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3335, 33.552],
            [-113.0211, 33.551],
            [-113.0216, 33.3774],
            [-112.7638, 33.3775],
            [-112.6772, 33.3267],
            [-112.6843, 33.1163],
            [-112.8283, 33.1165],
            [-112.8284, 33.2035],
            [-113.0266, 33.2044],
            [-113.3334, 33.2046],
            [-113.3339, 33.3774],
            [-113.3335, 33.552],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400840',
        NAME: 'Arlington Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1833715326,
        AWATER: 53343,
        INTPTLAT: '+33.3354864',
        INTPTLON: '-113.0477539',
        ELSDLEA: '00840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3722, 31.9057],
            [-109.3368, 31.8176],
            [-109.5379, 31.7872],
            [-109.6392, 31.8481],
            [-109.7377, 31.8473],
            [-109.7447, 31.9055],
            [-109.6953, 31.9926],
            [-109.5931, 31.9488],
            [-109.4405, 31.9489],
            [-109.3722, 31.9057],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400870',
        NAME: 'Ash Creek Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 530664709,
        AWATER: 232916,
        INTPTLAT: '+31.8790558',
        INTPTLON: '-109.5397127',
        ELSDLEA: '00870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4442, 33.4646],
            [-112.3326, 33.4642],
            [-112.3751, 33.3917],
            [-112.4442, 33.4646],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400960',
        NAME: 'Avondale Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74314438,
        AWATER: 41222,
        INTPTLAT: '+33.4317450',
        INTPTLON: '-112.3865615',
        ELSDLEA: '00960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0129, 33.4549],
            [-111.9955, 33.4804],
            [-111.944, 33.4511],
            [-111.95, 33.451],
            [-111.9501, 33.451],
            [-111.9503, 33.451],
            [-111.9505, 33.451],
            [-111.9534, 33.451],
            [-111.9563, 33.451],
            [-111.9789, 33.4371],
            [-111.9853, 33.4371],
            [-111.9955, 33.4371],
            [-112.0129, 33.4549],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401050',
        NAME: 'Balsz Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22642404,
        AWATER: 117790,
        INTPTLAT: '+33.4618596',
        INTPTLON: '-111.9735783',
        ELSDLEA: '01050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8531, 34.7331],
            [-111.7117, 34.7323],
            [-111.6765, 34.8032],
            [-111.5521, 34.8025],
            [-111.5534, 34.6107],
            [-111.8532, 34.6109],
            [-111.8536, 34.6566],
            [-111.8531, 34.7331],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401080',
        NAME: 'Beaver Creek Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 472229339,
        AWATER: 36349,
        INTPTLAT: '+34.6947235',
        INTPTLON: '-111.6731517',
        ELSDLEA: '01080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.221, 33.7778],
            [-109.0469, 33.7782],
            [-109.0471, 33.2404],
            [-109.1557, 33.2091],
            [-109.3055, 33.2091],
            [-109.305, 33.2963],
            [-109.2203, 33.2968],
            [-109.221, 33.7778],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401230',
        NAME: 'Blue Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1077099422,
        AWATER: 716963,
        INTPTLAT: '+33.5107508',
        INTPTLON: '-109.1290466',
        ELSDLEA: '01230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4512, 32.5138],
            [-110.4486, 32.7023],
            [-110.1438, 32.7031],
            [-110.1439, 32.5999],
            [-110.0425, 32.6002],
            [-110.0424, 32.7331],
            [-109.9395, 32.7333],
            [-109.8722, 32.6901],
            [-109.8686, 32.5143],
            [-109.8402, 32.4273],
            [-110.2512, 32.4267],
            [-110.4515, 32.4274],
            [-110.4512, 32.5138],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401260',
        NAME: 'Bonita Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1605275369,
        AWATER: 319501,
        INTPTLAT: '+32.5683405',
        INTPTLON: '-110.1371469',
        ELSDLEA: '01260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.6655, 34.0702],
            [-113.6645, 33.9859],
            [-113.766, 33.9858],
            [-113.7658, 33.8119],
            [-113.7667, 33.7827],
            [-113.9758, 33.782],
            [-114.3485, 33.7809],
            [-114.2201, 34.1058],
            [-114.1973, 34.1251],
            [-113.9798, 34.1251],
            [-113.9799, 34.0703],
            [-113.6655, 34.0702],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401290',
        NAME: 'Bouse Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1776098919,
        AWATER: 581304,
        INTPTLAT: '+33.9430233',
        INTPTLON: '-114.0220293',
        ELSDLEA: '01290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6772, 33.3267],
            [-112.6335, 33.334],
            [-112.6257, 33.4645],
            [-112.5305, 33.4645],
            [-112.5113, 33.1161],
            [-112.6843, 33.1163],
            [-112.6772, 33.3267],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401380',
        NAME: 'Buckeye Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 484668969,
        AWATER: 0,
        INTPTLAT: '+33.2657512',
        INTPTLON: '-112.5963492',
        ELSDLEA: '01380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.683, 35.6764],
            [-114.4656, 35.6765],
            [-114.4614, 35.3281],
            [-114.4084, 35.3283],
            [-114.4008, 35.1544],
            [-114.4011, 35.0393],
            [-114.6307, 35.0403],
            [-114.5695, 35.1623],
            [-114.5952, 35.3219],
            [-114.6789, 35.5013],
            [-114.6539, 35.6117],
            [-114.683, 35.6764],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401500',
        NAME: 'Bullhead City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1162637840,
        AWATER: 51309997,
        INTPTLAT: '+35.3625685',
        INTPTLON: '-114.5429171',
        ELSDLEA: '01500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3778, 34.3463],
            [-112.1995, 34.3424],
            [-112.1994, 34.2397],
            [-112.0943, 34.2411],
            [-112.0995, 34.041],
            [-112.2029, 33.9705],
            [-112.2028, 33.9859],
            [-112.2035, 34.1284],
            [-112.2034, 34.1949],
            [-112.2738, 34.2453],
            [-112.3775, 34.2449],
            [-112.3778, 34.3463],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401650',
        NAME: 'Canon Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442869530,
        AWATER: 0,
        INTPTLAT: '+34.2055081',
        INTPTLON: '-112.2145454',
        ELSDLEA: '01650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2378, 33.4726],
            [-112.2377, 33.5088],
            [-112.1864, 33.5096],
            [-112.1517, 33.495],
            [-112.1691, 33.4512],
            [-112.1866, 33.4515],
            [-112.238, 33.4653],
            [-112.2378, 33.4726],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401680',
        NAME: 'Cartwright Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36497418,
        AWATER: 56987,
        INTPTLAT: '+33.4857188',
        INTPTLON: '-112.1973735',
        ELSDLEA: '01680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9275, 33.0289],
            [-111.8929, 32.996],
            [-111.6691, 32.9961],
            [-111.6705, 32.9234],
            [-111.5667, 32.9232],
            [-111.5675, 32.8503],
            [-111.5676, 32.8067],
            [-111.7572, 32.8066],
            [-111.7403, 32.7189],
            [-111.7926, 32.6753],
            [-111.7925, 32.5068],
            [-111.7946, 32.5068],
            [-111.8614, 32.5067],
            [-111.8611, 32.7635],
            [-111.9293, 32.7676],
            [-111.9294, 32.9386],
            [-111.963, 32.9699],
            [-111.9275, 33.0289],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401710',
        NAME: 'Casa Grande Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 926150005,
        AWATER: 338527,
        INTPTLAT: '+32.8364877',
        INTPTLON: '-111.7909508',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 34.7208],
            [-110.7507, 34.6366],
            [-110.7507, 34.2647],
            [-110.7507, 34.2633],
            [-110.7831, 34.2999],
            [-110.8481, 34.2672],
            [-110.9429, 34.3122],
            [-111.0875, 34.395],
            [-111.1393, 34.3864],
            [-111.2467, 34.4508],
            [-111.3419, 34.4248],
            [-111.3419, 34.4682],
            [-111.1253, 34.4673],
            [-111.1254, 34.721],
            [-110.7507, 34.7208],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401920',
        NAME: 'Chevelon Butte School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1617206338,
        AWATER: 2034364,
        INTPTLAT: '+34.5200702',
        INTPTLON: '-110.9498383',
        ELSDLEA: '01920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0455, 32.166],
            [-109.8407, 32.1453],
            [-109.8478, 32.0505],
            [-109.9379, 32.0499],
            [-110.0467, 32.0942],
            [-110.1486, 32.0787],
            [-110.1486, 32.1674],
            [-110.0455, 32.166],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402130',
        NAME: 'Cochise Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240745071,
        AWATER: 39908820,
        INTPTLAT: '+32.1078105',
        INTPTLON: '-110.0047824',
        ELSDLEA: '02130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8507, 34.6462],
            [-109.8507, 34.6465],
            [-109.7464, 34.6481],
            [-109.7517, 34.5622],
            [-109.5258, 34.5675],
            [-109.533, 34.3875],
            [-109.5342, 34.3009],
            [-109.8486, 34.3015],
            [-109.8485, 34.3883],
            [-109.8652, 34.5597],
            [-109.8507, 34.6462],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402190',
        NAME: 'Concho Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 951128439,
        AWATER: 1339459,
        INTPTLAT: '+34.4449495',
        INTPTLON: '-109.7095310',
        ELSDLEA: '02190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3335, 34.3179],
            [-113.3332, 34.4115],
            [-112.971, 34.4121],
            [-112.8236, 34.3738],
            [-112.8237, 34.3303],
            [-112.824, 34.2433],
            [-112.6183, 34.2432],
            [-112.6173, 34.1128],
            [-112.6176, 34.0712],
            [-112.8253, 34.0711],
            [-112.8242, 33.9999],
            [-113.142, 33.9997],
            [-113.3338, 33.9992],
            [-113.3335, 34.3179],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402220',
        NAME: 'Congress Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2424554517,
        AWATER: 134194,
        INTPTLAT: '+34.1958501',
        INTPTLON: '-113.0418148',
        ELSDLEA: '02220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.8069, 31.8183],
            [-110.8071, 31.7317],
            [-110.8556, 31.7267],
            [-110.8724, 31.7267],
            [-110.8898, 31.7268],
            [-110.9575, 31.7267],
            [-110.9939, 31.7267],
            [-111.0622, 31.7685],
            [-111.0627, 31.8844],
            [-110.8578, 31.8992],
            [-110.8069, 31.8183],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402250',
        NAME: 'Continental Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378300940,
        AWATER: 60281,
        INTPTLAT: '+31.8113004',
        INTPTLON: '-110.9310852',
        ELSDLEA: '02250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3345, 34.973],
            [-111.9121, 34.9763],
            [-111.7778, 34.981],
            [-111.7777, 34.9339],
            [-111.8833, 34.9342],
            [-111.8833, 34.8036],
            [-111.8531, 34.7331],
            [-111.8536, 34.6566],
            [-111.977, 34.6451],
            [-112.0905, 34.6576],
            [-112.0907, 34.7301],
            [-111.9747, 34.8036],
            [-112.2007, 34.8031],
            [-112.3076, 34.8024],
            [-112.3345, 34.973],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402370',
        NAME: 'Cottonwood-Oak Creek Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1108945410,
        AWATER: 20784,
        INTPTLAT: '+34.8457669',
        INTPTLON: '-112.0405558',
        ELSDLEA: '02370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.78, 32.6327],
            [-114.7196, 32.7188],
            [-114.6504, 32.733],
            [-114.6326, 32.6187],
            [-114.78, 32.6327],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402400',
        NAME: 'Crane Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117184797,
        AWATER: 1140826,
        INTPTLAT: '+32.6726998',
        INTPTLON: '-114.6893594',
        ELSDLEA: '02400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0476, 33.4802],
            [-112.0476, 33.4935],
            [-112.0127, 33.5537],
            [-111.9961, 33.5537],
            [-111.9955, 33.4804],
            [-112.0129, 33.4549],
            [-112.039, 33.4553],
            [-112.0476, 33.4802],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402430',
        NAME: 'Creighton Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30494795,
        AWATER: 89504,
        INTPTLAT: '+33.4931566',
        INTPTLON: '-112.0140642',
        ELSDLEA: '02430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3775, 34.2449],
            [-112.2738, 34.2453],
            [-112.2034, 34.1949],
            [-112.2035, 34.1284],
            [-112.4122, 34.1283],
            [-112.3775, 34.2449],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402460',
        NAME: 'Crown King Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213597879,
        AWATER: 15578,
        INTPTLAT: '+34.1926943',
        INTPTLON: '-112.3195454',
        ELSDLEA: '02460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7239, 31.5545],
            [-109.6467, 31.5544],
            [-109.6465, 31.4661],
            [-109.6465, 31.4374],
            [-109.7321, 31.4376],
            [-109.7239, 31.5545],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402490',
        NAME: 'Double Adobe Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101220214,
        AWATER: 27065,
        INTPTLAT: '+31.4876788',
        INTPTLON: '-109.6698151',
        ELSDLEA: '02490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4954, 33.6527],
            [-109.3481, 33.7775],
            [-109.221, 33.7778],
            [-109.2203, 33.2968],
            [-109.305, 33.2963],
            [-109.3055, 33.2091],
            [-109.3398, 33.1803],
            [-109.4952, 33.1805],
            [-109.4954, 33.6527],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402710',
        NAME: 'Eagle Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1476102086,
        AWATER: 593842,
        INTPTLAT: '+33.4834297',
        INTPTLON: '-109.2829279',
        ELSDLEA: '02710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7615, 31.7587],
            [-109.7377, 31.8473],
            [-109.6392, 31.8481],
            [-109.5379, 31.7872],
            [-109.5298, 31.6853],
            [-109.5438, 31.6425],
            [-109.7036, 31.6418],
            [-109.7728, 31.6861],
            [-109.7615, 31.7587],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402760',
        NAME: 'Elfrida Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418453415,
        AWATER: 188440,
        INTPTLAT: '+31.7448399',
        INTPTLON: '-109.6453117',
        ELSDLEA: '02760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7926, 32.6753],
            [-111.7845, 32.6753],
            [-111.7815, 32.6754],
            [-111.7804, 32.6753],
            [-111.7589, 32.6752],
            [-111.706, 32.7193],
            [-111.568, 32.7196],
            [-111.5677, 32.7922],
            [-111.5338, 32.6763],
            [-111.355, 32.6761],
            [-111.3549, 32.5885],
            [-111.4656, 32.5892],
            [-111.4657, 32.5013],
            [-111.5908, 32.5068],
            [-111.7925, 32.5068],
            [-111.7926, 32.6753],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402790',
        NAME: 'Eloy Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770361366,
        AWATER: 238569,
        INTPTLAT: '+32.6133217',
        INTPTLON: '-111.5963833',
        ELSDLEA: '02790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.8069, 31.8183],
            [-110.758, 31.818],
            [-110.6551, 31.8179],
            [-110.6553, 31.9041],
            [-110.6042, 31.9481],
            [-110.451, 31.9477],
            [-110.4508, 31.9051],
            [-110.4517, 31.7313],
            [-110.8071, 31.7317],
            [-110.8069, 31.8183],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402820',
        NAME: 'Empire Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 577589333,
        AWATER: 0,
        INTPTLAT: '+31.8208591',
        INTPTLON: '-110.5926054',
        ELSDLEA: '02820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2382, 33.4372],
            [-112.238, 33.4653],
            [-112.1866, 33.4515],
            [-112.2041, 33.401],
            [-112.237, 33.3919],
            [-112.2382, 33.4372],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403060',
        NAME: 'Fowler Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32564451,
        AWATER: 0,
        INTPTLAT: '+33.4337497',
        INTPTLON: '-112.2152020',
        ELSDLEA: '03060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.8003, 32.5888],
            [-114.7356, 32.5891],
            [-114.7355, 32.471],
            [-114.8136, 32.4943],
            [-114.8003, 32.5888],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403240',
        NAME: 'Gadsden Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78284510,
        AWATER: 357118,
        INTPTLAT: '+32.5324826',
        INTPTLON: '-114.7739783',
        ELSDLEA: '03240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.169, 33.5675],
            [-112.1514, 33.5385],
            [-112.1864, 33.5096],
            [-112.2377, 33.5088],
            [-112.2377, 33.5377],
            [-112.169, 33.5675],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403420',
        NAME: 'Glendale Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41051334,
        AWATER: 7542,
        INTPTLAT: '+33.5402228',
        INTPTLON: '-112.1969550',
        ELSDLEA: '03420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8358, 36.0491],
            [-113.6282, 35.8224],
            [-113.5027, 35.7658],
            [-113.5159, 35.5008],
            [-113.7113, 35.5021],
            [-113.7112, 35.3286],
            [-113.5159, 35.3283],
            [-113.493, 35.242],
            [-113.3597, 35.243],
            [-113.3596, 35.1515],
            [-113.8135, 35.1523],
            [-113.8303, 35.497],
            [-113.8358, 36.0491],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403660',
        NAME: 'Hackberry School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2162236274,
        AWATER: 3150253,
        INTPTLAT: '+35.5442829',
        INTPTLON: '-113.7525700',
        ELSDLEA: '03660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3338, 33.117],
            [-113.3338, 32.8251],
            [-113.3337, 32.7679],
            [-113.6439, 32.7673],
            [-113.6614, 32.8255],
            [-113.6658, 33.1173],
            [-113.3338, 33.117],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403900',
        NAME: 'Hyder Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1188825729,
        AWATER: 0,
        INTPTLAT: '+32.9435809',
        INTPTLON: '-113.4978533',
        ELSDLEA: '03900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1517, 33.495],
            [-112.1171, 33.4733],
            [-112.1175, 33.4511],
            [-112.1431, 33.4514],
            [-112.1691, 33.4512],
            [-112.1517, 33.495],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403960',
        NAME: 'Isaac Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15737029,
        AWATER: 7173,
        INTPTLAT: '+33.4667029',
        INTPTLON: '-112.1437256',
        ELSDLEA: '03960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.8236, 34.3738],
            [-112.796, 34.4583],
            [-112.6554, 34.4323],
            [-112.6246, 34.5045],
            [-112.4998, 34.4978],
            [-112.5012, 34.3753],
            [-112.6183, 34.3315],
            [-112.8237, 34.3303],
            [-112.8236, 34.3738],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404170',
        NAME: 'Kirkland Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 436792014,
        AWATER: 0,
        INTPTLAT: '+34.4039660',
        INTPTLON: '-112.6427546',
        ELSDLEA: '04170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4494, 32.9874],
            [-110.3627, 33.0013],
            [-110.2302, 32.9439],
            [-110.1785, 32.8621],
            [-110.1786, 32.776],
            [-110.0433, 32.7764],
            [-110.0424, 32.7331],
            [-110.0425, 32.6002],
            [-110.1439, 32.5999],
            [-110.1438, 32.7031],
            [-110.4486, 32.7023],
            [-110.4485, 32.7712],
            [-110.4494, 32.9874],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404200',
        NAME: 'Klondyke Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 936917129,
        AWATER: 17587,
        INTPTLAT: '+32.8034734',
        INTPTLON: '-110.2610926',
        ELSDLEA: '04200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1005, 33.3196],
            [-112.1005, 33.333],
            [-112.1005, 33.3495],
            [-112.0382, 33.3633],
            [-112.0316, 33.3692],
            [-112.0166, 33.3707],
            [-111.9971, 33.3707],
            [-111.8946, 33.3638],
            [-111.8931, 33.3349],
            [-111.8931, 33.2048],
            [-112.0835, 33.2046],
            [-112.099, 33.231],
            [-112.1005, 33.3196],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404230',
        NAME: 'Kyrene Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335591883,
        AWATER: 1148175,
        INTPTLAT: '+33.2830918',
        INTPTLON: '-112.0144251',
        ELSDLEA: '04230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.237, 33.3919],
            [-112.2041, 33.401],
            [-112.1421, 33.4065],
            [-112.1337, 33.4067],
            [-112.1005, 33.3495],
            [-112.1005, 33.333],
            [-112.1005, 33.3196],
            [-112.2291, 33.3832],
            [-112.237, 33.3919],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404290',
        NAME: 'Laveen Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72467932,
        AWATER: 308078,
        INTPTLAT: '+33.3643346',
        INTPTLON: '-112.1600498',
        ELSDLEA: '04290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5305, 33.4645],
            [-112.5129, 33.4646],
            [-112.4442, 33.4646],
            [-112.3751, 33.3917],
            [-112.3062, 33.3626],
            [-112.3061, 33.2904],
            [-112.3063, 33.2033],
            [-112.3063, 33.1162],
            [-112.5113, 33.1161],
            [-112.5305, 33.4645],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404320',
        NAME: 'Liberty Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 678140093,
        AWATER: 0,
        INTPTLAT: '+33.2724088',
        INTPTLON: '-112.4273599',
        ELSDLEA: '04320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5135, 33.5515],
            [-112.3065, 33.5365],
            [-112.3238, 33.4642],
            [-112.3326, 33.4642],
            [-112.4442, 33.4646],
            [-112.5129, 33.4646],
            [-112.5135, 33.5515],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404380',
        NAME: 'Litchfield Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170285790,
        AWATER: 739798,
        INTPTLAT: '+33.5009219',
        INTPTLON: '-112.4138363',
        ELSDLEA: '04380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3751, 33.3917],
            [-112.3326, 33.4642],
            [-112.3238, 33.4642],
            [-112.2809, 33.4646],
            [-112.2724, 33.4357],
            [-112.3062, 33.3626],
            [-112.3751, 33.3917],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404440',
        NAME: 'Littleton Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75671669,
        AWATER: 0,
        INTPTLAT: '+33.4086224',
        INTPTLON: '-112.3161994',
        ELSDLEA: '04440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.048, 33.555],
            [-112.0127, 33.5537],
            [-112.0476, 33.4935],
            [-112.0824, 33.5239],
            [-112.048, 33.555],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404500',
        NAME: 'Madison Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38446610,
        AWATER: 224977,
        INTPTLAT: '+33.5258127',
        INTPTLON: '-112.0458166',
        ELSDLEA: '04500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9066, 35.4569],
            [-111.8164, 35.4132],
            [-111.816, 35.3249],
            [-111.8939, 35.3241],
            [-111.9118, 35.1049],
            [-112.019, 35.1046],
            [-112.1245, 35.1468],
            [-112.1238, 35.2357],
            [-112.0538, 35.2532],
            [-112.013, 35.3233],
            [-112.0134, 35.4564],
            [-111.9066, 35.4569],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404530',
        NAME: 'Maine Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610306253,
        AWATER: 233422,
        INTPTLAT: '+35.2852492',
        INTPTLON: '-111.9671823',
        ELSDLEA: '04530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.218, 32.1902],
            [-111.1664, 32.1903],
            [-111.1661, 32.1042],
            [-111.217, 32.0752],
            [-111.2152, 31.7839],
            [-111.2663, 31.7696],
            [-111.595, 31.7695],
            [-111.5812, 31.9237],
            [-111.5473, 32.0098],
            [-111.4785, 32.0107],
            [-111.4409, 32.1725],
            [-111.218, 32.1902],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404770',
        NAME: 'Altar Valley Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1341301049,
        AWATER: 20482,
        INTPTLAT: '+31.9588543',
        INTPTLON: '-111.3656669',
        ELSDLEA: '04770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8494, 34.1259],
            [-109.5442, 34.1252],
            [-109.5442, 33.9801],
            [-109.8591, 33.9804],
            [-109.8594, 34.1088],
            [-109.8494, 34.1259],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404860',
        NAME: 'McNary Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465084398,
        AWATER: 4064246,
        INTPTLAT: '+34.0550118',
        INTPTLON: '-109.7083755',
        ELSDLEA: '04860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7036, 31.6418],
            [-109.5438, 31.6425],
            [-109.5446, 31.5535],
            [-109.5029, 31.4654],
            [-109.6465, 31.4661],
            [-109.6467, 31.5544],
            [-109.7239, 31.5545],
            [-109.7036, 31.6418],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404920',
        NAME: 'McNeal Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291557275,
        AWATER: 223294,
        INTPTLAT: '+31.5594248',
        INTPTLON: '-109.5963390',
        ELSDLEA: '04920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5113, 33.1161],
            [-112.3063, 33.1162],
            [-112.3063, 33.2033],
            [-112.202, 33.2033],
            [-112.202, 33.1167],
            [-112.2028, 32.9417],
            [-112.2037, 32.9417],
            [-112.5112, 32.9426],
            [-112.5113, 33.1161],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405100',
        NAME: 'Mobile Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 648986923,
        AWATER: 0,
        INTPTLAT: '+33.0482479',
        INTPTLON: '-112.3418330',
        ELSDLEA: '05100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6307, 35.0403],
            [-114.4011, 35.0393],
            [-114.4008, 35.1544],
            [-114.2443, 35.1563],
            [-114.2448, 34.8939],
            [-114.2443, 34.8058],
            [-114.5749, 34.8081],
            [-114.6283, 34.8636],
            [-114.6335, 35.0019],
            [-114.6307, 35.0403],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405190',
        NAME: 'Mohave Valley Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1069528761,
        AWATER: 5029888,
        INTPTLAT: '+34.9511050',
        INTPTLON: '-114.4266033',
        ELSDLEA: '05190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.958, 33.3776],
            [-113.6447, 33.3775],
            [-113.3339, 33.3774],
            [-113.3334, 33.2046],
            [-113.3338, 33.117],
            [-113.6658, 33.1173],
            [-113.6614, 32.8255],
            [-113.6439, 32.7673],
            [-113.3337, 32.7679],
            [-113.3337, 32.5936],
            [-114.0215, 32.5914],
            [-114.0565, 32.7223],
            [-114.0621, 33.3776],
            [-113.958, 33.3776],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405220',
        NAME: 'Mohawk Valley Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4660499052,
        AWATER: 3400,
        INTPTLAT: '+32.9615147',
        INTPTLON: '-113.7419939',
        ELSDLEA: '05220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4279, 33.9208],
            [-112.4296, 33.8545],
            [-112.5336, 33.8547],
            [-112.5336, 33.7822],
            [-112.6203, 33.7822],
            [-112.7075, 33.7822],
            [-112.685, 33.9852],
            [-112.4279, 33.9208],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405340',
        NAME: 'Morristown Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401968052,
        AWATER: 79668,
        INTPTLAT: '+33.8749535',
        INTPTLON: '-112.5835580',
        ELSDLEA: '05340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1431, 33.4514],
            [-112.1175, 33.4511],
            [-112.0996, 33.4174],
            [-112.1337, 33.4067],
            [-112.1421, 33.4065],
            [-112.1431, 33.4514],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405400',
        NAME: 'Murphy Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17827068,
        AWATER: 100686,
        INTPTLAT: '+33.4314364',
        INTPTLON: '-112.1237340',
        ELSDLEA: '05400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0036, 31.3798],
            [-109.9184, 31.3338],
            [-110.0031, 31.3332],
            [-110.0036, 31.3798],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405430',
        NAME: 'Naco Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41271835,
        AWATER: 13749,
        INTPTLAT: '+31.3632425',
        INTPTLON: '-109.9734020',
        ELSDLEA: '05430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9451, 32.7716],
            [-110.7555, 32.7714],
            [-110.7556, 32.6697],
            [-110.6526, 32.6701],
            [-110.6529, 32.5139],
            [-110.6957, 32.5136],
            [-110.8587, 32.5112],
            [-110.9992, 32.511],
            [-110.9993, 32.5984],
            [-110.9451, 32.6843],
            [-110.9451, 32.7716],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405640',
        NAME: 'Oracle Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 776157317,
        AWATER: 695,
        INTPTLAT: '+32.6260081',
        INTPTLON: '-110.8448610',
        ELSDLEA: '05640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0999, 33.4878],
            [-112.0997, 33.5241],
            [-112.0824, 33.5239],
            [-112.0476, 33.4935],
            [-112.0476, 33.4802],
            [-112.0999, 33.4878],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405670',
        NAME: 'Osborn Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17988209,
        AWATER: 95638,
        INTPTLAT: '+33.4960718',
        INTPTLON: '-112.0789495',
        ELSDLEA: '05670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1939, 34.5067],
            [-113.7782, 34.5071],
            [-113.7779, 34.8051],
            [-113.8126, 34.8057],
            [-113.8132, 34.9786],
            [-113.8135, 35.1523],
            [-113.3596, 35.1515],
            [-113.3329, 35.1514],
            [-113.3334, 34.7209],
            [-113.3332, 34.4115],
            [-113.3335, 34.3179],
            [-113.3705, 34.292],
            [-113.5395, 34.3064],
            [-113.6651, 34.213],
            [-113.7307, 34.2403],
            [-114.0311, 34.2598],
            [-114.1383, 34.3032],
            [-114.1933, 34.3574],
            [-114.1939, 34.5067],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405730',
        NAME: 'Owens-Whitney Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5125352862,
        AWATER: 18821836,
        INTPTLAT: '+34.6656043',
        INTPTLON: '-113.6528990',
        ELSDLEA: '05730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4349, 33.987],
            [-111.2945, 33.9874],
            [-111.0696, 33.9873],
            [-111.0692, 33.8463],
            [-111.1222, 33.7589],
            [-111.0652, 33.6822],
            [-111.3152, 33.6815],
            [-111.3868, 33.75],
            [-111.3723, 33.8239],
            [-111.4509, 33.9199],
            [-111.4349, 33.987],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405760',
        NAME: 'Tonto Basin Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 934352169,
        AWATER: 30459953,
        INTPTLAT: '+33.8570520',
        INTPTLON: '-111.2458217',
        ELSDLEA: '05760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6257, 33.4645],
            [-112.6335, 33.334],
            [-112.6772, 33.3267],
            [-112.7638, 33.3775],
            [-112.7639, 33.4644],
            [-112.6257, 33.4645],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405850',
        NAME: 'Palo Verde Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168658488,
        AWATER: 160903,
        INTPTLAT: '+33.4004362',
        INTPTLON: '-112.6844007',
        ELSDLEA: '05850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.1041, 31.5555],
            [-110.0534, 31.5531],
            [-110.0035, 31.4685],
            [-110.0036, 31.3798],
            [-110.0031, 31.3332],
            [-110.2742, 31.3333],
            [-110.2743, 31.3798],
            [-110.2068, 31.4673],
            [-110.1897, 31.556],
            [-110.1041, 31.5555],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405880',
        NAME: 'Palominas Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498354683,
        AWATER: 154392,
        INTPTLAT: '+31.4312546',
        INTPTLON: '-110.1318309',
        ELSDLEA: '05880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9575, 31.7267],
            [-110.8898, 31.7268],
            [-110.8724, 31.7267],
            [-110.8556, 31.7267],
            [-110.8216, 31.6443],
            [-110.6902, 31.6442],
            [-110.6564, 31.5571],
            [-110.5632, 31.5571],
            [-110.5625, 31.4282],
            [-110.4599, 31.4281],
            [-110.4602, 31.3328],
            [-110.761, 31.3336],
            [-110.7606, 31.4703],
            [-110.8736, 31.4845],
            [-110.9594, 31.4875],
            [-110.9575, 31.7267],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406000',
        NAME: 'Patagonia Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1112357595,
        AWATER: 1364219,
        INTPTLAT: '+31.5188040',
        INTPTLON: '-110.7571645',
        ELSDLEA: '06000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9626, 31.9347],
            [-109.9379, 32.0499],
            [-109.8478, 32.0505],
            [-109.7447, 31.9055],
            [-109.7377, 31.8473],
            [-109.7615, 31.7587],
            [-109.8645, 31.7582],
            [-109.9626, 31.9347],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406150',
        NAME: 'Pearce Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520358745,
        AWATER: 272703,
        INTPTLAT: '+31.9012903',
        INTPTLON: '-109.8516550',
        ELSDLEA: '06150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3065, 33.5365],
            [-112.2377, 33.5377],
            [-112.2377, 33.5088],
            [-112.2378, 33.4726],
            [-112.2809, 33.4646],
            [-112.3238, 33.4642],
            [-112.3065, 33.5365],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406210',
        NAME: 'Pendergast Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50230247,
        AWATER: 331697,
        INTPTLAT: '+33.4986266',
        INTPTLON: '-112.2764579',
        ELSDLEA: '06210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1171, 33.4733],
            [-112.0999, 33.4878],
            [-112.0476, 33.4802],
            [-112.039, 33.4553],
            [-112.0478, 33.422],
            [-112.0996, 33.4174],
            [-112.1175, 33.4511],
            [-112.1171, 33.4733],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406300',
        NAME: 'Phoenix Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40173025,
        AWATER: 12397,
        INTPTLAT: '+33.4530772',
        INTPTLON: '-112.0783438',
        ELSDLEA: '06300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5675, 32.8503],
            [-111.4647, 32.8506],
            [-111.3615, 32.8497],
            [-111.3613, 32.7629],
            [-111.2597, 32.7441],
            [-111.0892, 32.5985],
            [-111.3549, 32.5885],
            [-111.355, 32.6761],
            [-111.5338, 32.6763],
            [-111.5677, 32.7922],
            [-111.5676, 32.8067],
            [-111.5675, 32.8503],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406360',
        NAME: 'Picacho Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653100705,
        AWATER: 3185423,
        INTPTLAT: '+32.7157274',
        INTPTLON: '-111.3588406',
        ELSDLEA: '06360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6853, 34.2817],
            [-111.6596, 34.3863],
            [-111.5547, 34.4717],
            [-111.4919, 34.4227],
            [-111.3789, 34.4309],
            [-111.3786, 34.2852],
            [-111.4912, 34.2209],
            [-111.4843, 34.151],
            [-111.7121, 34.1513],
            [-111.6853, 34.2817],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406510',
        NAME: 'Pine Strawberry Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 733553697,
        AWATER: 686561,
        INTPTLAT: '+34.3038561',
        INTPTLON: '-111.5611326',
        ELSDLEA: '06510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.1471, 31.9785],
            [-110.2459, 31.9491],
            [-110.3101, 32.0217],
            [-110.1478, 32.0211],
            [-110.1471, 31.9785],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406630',
        NAME: 'Pomerene Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98108168,
        AWATER: 49602,
        INTPTLAT: '+31.9897709',
        INTPTLON: '-110.2063793',
        ELSDLEA: '06630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6271, 33.4336],
            [-114.558, 33.5326],
            [-114.5249, 33.6388],
            [-114.4766, 33.6802],
            [-114.3626, 33.6802],
            [-114.3485, 33.7809],
            [-113.9758, 33.782],
            [-113.9575, 33.7242],
            [-113.958, 33.4647],
            [-114.2678, 33.4636],
            [-114.2687, 33.0298],
            [-114.5165, 33.0276],
            [-114.6708, 33.038],
            [-114.7079, 33.0974],
            [-114.677, 33.2702],
            [-114.7201, 33.4079],
            [-114.6271, 33.4336],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406780',
        NAME: 'Quartzsite Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3555644012,
        AWATER: 14549323,
        INTPTLAT: '+33.4219121',
        INTPTLON: '-114.3837598',
        ELSDLEA: '06780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3549, 32.5885],
            [-111.0892, 32.5985],
            [-110.9993, 32.5984],
            [-110.9992, 32.511],
            [-111.0297, 32.5112],
            [-111.4657, 32.5013],
            [-111.4656, 32.5892],
            [-111.3549, 32.5885],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406900',
        NAME: 'Red Rock Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433913464,
        AWATER: 19541,
        INTPTLAT: '+32.5501691',
        INTPTLON: '-111.2360541',
        ELSDLEA: '06900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6957, 32.5136],
            [-110.6529, 32.5139],
            [-110.4512, 32.5138],
            [-110.4515, 32.4274],
            [-110.451, 32.269],
            [-110.5525, 32.312],
            [-110.6388, 32.471],
            [-110.6957, 32.5136],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406930',
        NAME: 'Redington Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363205504,
        AWATER: 0,
        INTPTLAT: '+32.4192707',
        INTPTLON: '-110.5342709',
        ELSDLEA: '06930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1866, 33.4515],
            [-112.1691, 33.4512],
            [-112.1431, 33.4514],
            [-112.1421, 33.4065],
            [-112.2041, 33.401],
            [-112.1866, 33.4515],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407020',
        NAME: 'Riverside Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23711731,
        AWATER: 687753,
        INTPTLAT: '+33.4259527',
        INTPTLON: '-112.1736267',
        ELSDLEA: '07020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1337, 33.4067],
            [-112.0996, 33.4174],
            [-112.0478, 33.422],
            [-112.0129, 33.418],
            [-112.0039, 33.4218],
            [-111.9996, 33.4252],
            [-111.9995, 33.4288],
            [-111.9954, 33.4288],
            [-111.9955, 33.4261],
            [-111.9971, 33.3707],
            [-112.0166, 33.3707],
            [-112.0316, 33.3692],
            [-112.0382, 33.3633],
            [-112.1005, 33.3495],
            [-112.1337, 33.4067],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407080',
        NAME: 'Roosevelt Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85010222,
        AWATER: 206596,
        INTPTLAT: '+33.3868803',
        INTPTLON: '-112.0683870',
        ELSDLEA: '07080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5379, 31.7872],
            [-109.3368, 31.8176],
            [-109.3253, 31.729],
            [-109.3251, 31.657],
            [-109.3335, 31.642],
            [-109.3888, 31.5531],
            [-109.5034, 31.553],
            [-109.5298, 31.6853],
            [-109.5379, 31.7872],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407140',
        NAME: 'Rucker Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433073262,
        AWATER: 101162,
        INTPTLAT: '+31.6930020',
        INTPTLON: '-109.4347432',
        ELSDLEA: '07140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.099, 33.231],
            [-112.0835, 33.2046],
            [-111.8931, 33.2048],
            [-111.7892, 33.2047],
            [-111.7892, 33.1179],
            [-111.6688, 33.0898],
            [-111.6691, 32.9961],
            [-111.8929, 32.996],
            [-111.9275, 33.0289],
            [-111.9615, 33.1177],
            [-112.202, 33.1167],
            [-112.202, 33.2033],
            [-112.099, 33.231],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407200',
        NAME: 'Sacaton Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728254727,
        AWATER: 184214,
        INTPTLAT: '+33.1258069',
        INTPTLON: '-111.9315480',
        ELSDLEA: '07200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.595, 31.7695],
            [-111.2663, 31.7696],
            [-111.2647, 31.7262],
            [-111.4027, 31.726],
            [-111.416, 31.5198],
            [-111.4511, 31.4531],
            [-111.6231, 31.5079],
            [-111.6516, 31.5896],
            [-111.595, 31.7695],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407380',
        NAME: 'San Fernando Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726242192,
        AWATER: 290678,
        INTPTLAT: '+31.6332915',
        INTPTLON: '-111.4914213',
        ELSDLEA: '07380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.8736, 31.4845],
            [-110.7606, 31.4703],
            [-110.761, 31.3336],
            [-110.8907, 31.3332],
            [-110.9404, 31.42],
            [-110.8736, 31.4845],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407500',
        NAME: 'Santa Cruz Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205916544,
        AWATER: 271567,
        INTPTLAT: '+31.3994097',
        INTPTLON: '-110.8054674',
        ELSDLEA: '07500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3334, 33.2046],
            [-113.0266, 33.2044],
            [-113.0259, 33.1162],
            [-113.0235, 32.9411],
            [-113.0232, 32.8836],
            [-113.0231, 32.825],
            [-113.3338, 32.8251],
            [-113.3338, 33.117],
            [-113.3334, 33.2046],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407680',
        NAME: 'Sentinel Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1211777376,
        AWATER: 210596,
        INTPTLAT: '+33.0154956',
        INTPTLON: '-113.2122465',
        ELSDLEA: '07680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.626, 34.7189],
            [-112.6246, 34.5045],
            [-112.6554, 34.4323],
            [-112.796, 34.4583],
            [-112.866, 34.5031],
            [-112.9358, 34.6047],
            [-112.936, 34.7174],
            [-112.729, 34.7008],
            [-112.626, 34.7189],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407770',
        NAME: 'Skull Valley Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 677461679,
        AWATER: 0,
        INTPTLAT: '+34.5972261',
        INTPTLON: '-112.7612280',
        ELSDLEA: '07770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.6262, 33.5896],
            [-109.4967, 33.6524],
            [-109.4954, 33.6527],
            [-109.4952, 33.1805],
            [-109.4657, 33.0352],
            [-109.4254, 32.9768],
            [-109.245, 32.742],
            [-109.2062, 32.6358],
            [-109.2261, 32.5291],
            [-109.114, 32.4262],
            [-109.3301, 32.426],
            [-109.6736, 32.4269],
            [-109.6643, 32.5137],
            [-109.6686, 33.3775],
            [-109.6247, 33.3778],
            [-109.6262, 33.5896],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407860',
        NAME: 'Solomon Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3400242564,
        AWATER: 9479116,
        INTPTLAT: '+32.8674811',
        INTPTLON: '-109.4596501',
        ELSDLEA: '07860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.78, 32.6327],
            [-114.6326, 32.6187],
            [-114.6215, 32.4366],
            [-114.7355, 32.471],
            [-114.7356, 32.5891],
            [-114.8003, 32.5888],
            [-114.78, 32.6327],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407890',
        NAME: 'Somerton Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227789951,
        AWATER: 203622,
        INTPTLAT: '+32.5395774',
        INTPTLON: '-114.7056745',
        ELSDLEA: '07890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.8556, 31.7267],
            [-110.8071, 31.7317],
            [-110.4517, 31.7313],
            [-110.4517, 31.7307],
            [-110.4607, 31.5851],
            [-110.4599, 31.4281],
            [-110.5625, 31.4282],
            [-110.5632, 31.5571],
            [-110.6564, 31.5571],
            [-110.6902, 31.6442],
            [-110.8216, 31.6443],
            [-110.8556, 31.7267],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407920',
        NAME: 'Sonoita Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 683615632,
        AWATER: 282654,
        INTPTLAT: '+31.6197650',
        INTPTLON: '-110.6003765',
        ELSDLEA: '07920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2037, 32.9417],
            [-112.2028, 32.9417],
            [-112.1, 32.9696],
            [-111.963, 32.9699],
            [-111.9294, 32.9386],
            [-111.9293, 32.7676],
            [-111.8611, 32.7635],
            [-111.8614, 32.5067],
            [-112.2037, 32.5068],
            [-112.2037, 32.9417],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408130',
        NAME: 'Stanfield Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1469301354,
        AWATER: 323858,
        INTPTLAT: '+32.7215374',
        INTPTLON: '-112.0493972',
        ELSDLEA: '08130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9971, 33.3707],
            [-111.9955, 33.4261],
            [-111.9954, 33.4288],
            [-111.9955, 33.4299],
            [-111.9955, 33.436],
            [-111.9955, 33.4371],
            [-111.9853, 33.4371],
            [-111.9789, 33.4371],
            [-111.9563, 33.451],
            [-111.9534, 33.451],
            [-111.9505, 33.451],
            [-111.9503, 33.451],
            [-111.9501, 33.451],
            [-111.95, 33.451],
            [-111.944, 33.4511],
            [-111.892, 33.4513],
            [-111.8946, 33.3638],
            [-111.9971, 33.3707],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408310',
        NAME: 'Tempe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92975780,
        AWATER: 1404458,
        INTPTLAT: '+33.4062883',
        INTPTLON: '-111.9378544',
        ELSDLEA: '08310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0235, 32.9411],
            [-112.9779, 32.9982],
            [-112.7876, 32.9709],
            [-112.7869, 32.8844],
            [-113.0232, 32.8836],
            [-113.0235, 32.9411],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408430',
        NAME: 'Paloma School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234516055,
        AWATER: 0,
        INTPTLAT: '+32.9339880',
        INTPTLON: '-112.9014605',
        ELSDLEA: '08430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9358, 34.6047],
            [-112.866, 34.5031],
            [-112.796, 34.4583],
            [-112.8236, 34.3738],
            [-112.971, 34.4121],
            [-112.9358, 34.6047],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408460',
        NAME: 'Hillside Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291297881,
        AWATER: 0,
        INTPTLAT: '+34.4644124',
        INTPTLON: '-112.8874647',
        ELSDLEA: '08460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2809, 33.4646],
            [-112.2378, 33.4726],
            [-112.238, 33.4653],
            [-112.2382, 33.4372],
            [-112.2724, 33.4357],
            [-112.2809, 33.4646],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408490',
        NAME: 'Tolleson Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16036557,
        AWATER: 0,
        INTPTLAT: '+33.4559638',
        INTPTLON: '-112.2586346',
        ELSDLEA: '08490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5676, 32.8067],
            [-111.5677, 32.7922],
            [-111.568, 32.7196],
            [-111.706, 32.7193],
            [-111.7589, 32.6752],
            [-111.7804, 32.6753],
            [-111.7815, 32.6754],
            [-111.7845, 32.6753],
            [-111.7926, 32.6753],
            [-111.7403, 32.7189],
            [-111.7572, 32.8066],
            [-111.5676, 32.8067],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408550',
        NAME: 'Toltec Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241694177,
        AWATER: 326728,
        INTPTLAT: '+32.7569359',
        INTPTLON: '-111.6851151',
        ELSDLEA: '08550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.5749, 34.8081],
            [-114.2443, 34.8058],
            [-114.1925, 34.8053],
            [-114.1933, 34.6211],
            [-114.3505, 34.622],
            [-114.3517, 34.7615],
            [-114.4041, 34.7615],
            [-114.4389, 34.6223],
            [-114.4716, 34.713],
            [-114.5749, 34.8081],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408640',
        NAME: 'Topock Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 479029185,
        AWATER: 18332935,
        INTPTLAT: '+34.6968896',
        INTPTLON: '-114.3442456',
        ELSDLEA: '08640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3062, 33.3626],
            [-112.2724, 33.4357],
            [-112.2382, 33.4372],
            [-112.237, 33.3919],
            [-112.2291, 33.3832],
            [-112.2292, 33.2904],
            [-112.3061, 33.2904],
            [-112.3062, 33.3626],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408820',
        NAME: 'Union Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90412263,
        AWATER: 304540,
        INTPTLAT: '+33.3505757',
        INTPTLON: '-112.2580879',
        ELSDLEA: '08820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.5159, 35.5008],
            [-113.5159, 35.3283],
            [-113.7112, 35.3286],
            [-113.7113, 35.5021],
            [-113.5159, 35.5008],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408880',
        NAME: 'Valentine Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339170562,
        AWATER: 162998,
        INTPTLAT: '+35.3818090',
        INTPTLON: '-113.6185902',
        ELSDLEA: '08880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6183, 34.2432],
            [-112.6183, 34.3315],
            [-112.5012, 34.3753],
            [-112.3777, 34.3752],
            [-112.3778, 34.3463],
            [-112.3775, 34.2449],
            [-112.4122, 34.1283],
            [-112.2035, 34.1284],
            [-112.2028, 33.9859],
            [-112.393, 33.9701],
            [-112.4978, 33.9991],
            [-112.5168, 34.113],
            [-112.6173, 34.1128],
            [-112.6183, 34.2432],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409030',
        NAME: 'Walnut Grove Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 998400469,
        AWATER: 0,
        INTPTLAT: '+34.1562553',
        INTPTLON: '-112.4348384',
        ELSDLEA: '09030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1691, 33.639],
            [-112.0828, 33.6402],
            [-112.048, 33.555],
            [-112.0824, 33.5239],
            [-112.0997, 33.5241],
            [-112.1514, 33.5385],
            [-112.169, 33.5675],
            [-112.1691, 33.639],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409060',
        NAME: 'Washington Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112618109,
        AWATER: 405305,
        INTPTLAT: '+33.5887000',
        INTPTLON: '-112.1130910',
        ELSDLEA: '09060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2687, 33.0298],
            [-114.2678, 33.4636],
            [-113.958, 33.4647],
            [-113.958, 33.3776],
            [-114.0621, 33.3776],
            [-114.0565, 32.7223],
            [-114.0215, 32.5914],
            [-114.2905, 32.591],
            [-114.2911, 32.591],
            [-114.2614, 32.6691],
            [-114.2687, 33.0298],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409090',
        NAME: 'Wellton Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2088583716,
        AWATER: 0,
        INTPTLAT: '+33.0207988',
        INTPTLON: '-114.1593085',
        ELSDLEA: '09090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.6655, 34.0702],
            [-113.6651, 34.213],
            [-113.5395, 34.3064],
            [-113.3705, 34.292],
            [-113.3335, 34.3179],
            [-113.3338, 33.9992],
            [-113.334, 33.6386],
            [-113.3335, 33.552],
            [-113.3339, 33.3774],
            [-113.6447, 33.3775],
            [-113.6448, 33.6524],
            [-113.5403, 33.6527],
            [-113.5406, 33.7393],
            [-113.5839, 33.8122],
            [-113.7658, 33.8119],
            [-113.766, 33.9858],
            [-113.6645, 33.9859],
            [-113.6655, 34.0702],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409120',
        NAME: 'Wenden Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2958647432,
        AWATER: 7820764,
        INTPTLAT: '+33.8437249',
        INTPTLON: '-113.5030244',
        ELSDLEA: '09120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.7335, 34.9826],
            [-112.6283, 34.9822],
            [-112.6285, 34.8954],
            [-112.523, 34.8947],
            [-112.5364, 34.8061],
            [-112.626, 34.7189],
            [-112.729, 34.7008],
            [-112.936, 34.7174],
            [-113.1451, 34.7209],
            [-113.1806, 34.8401],
            [-113.1773, 34.9755],
            [-112.7335, 34.9826],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409360',
        NAME: 'Williamson Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1571117088,
        AWATER: 285207,
        INTPTLAT: '+34.8463045',
        INTPTLON: '-112.8683971',
        ELSDLEA: '09360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.039, 33.4553],
            [-112.0129, 33.4549],
            [-111.9955, 33.4371],
            [-111.9955, 33.436],
            [-111.9955, 33.4299],
            [-111.9954, 33.4288],
            [-111.9995, 33.4288],
            [-111.9996, 33.4252],
            [-112.0039, 33.4218],
            [-112.0129, 33.418],
            [-112.0478, 33.422],
            [-112.039, 33.4553],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409390',
        NAME: 'Wilson Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15612611,
        AWATER: 2384,
        INTPTLAT: '+33.4295804',
        INTPTLON: '-112.0140972',
        ELSDLEA: '09390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.8237, 34.3303],
            [-112.6183, 34.3315],
            [-112.6183, 34.2432],
            [-112.824, 34.2433],
            [-112.8237, 34.3303],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409510',
        NAME: 'Yarnell Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214257001,
        AWATER: 0,
        INTPTLAT: '+34.2768964',
        INTPTLON: '-112.7242252',
        ELSDLEA: '09510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9429, 34.3122],
            [-110.8481, 34.2672],
            [-110.7831, 34.2999],
            [-110.7832, 33.8979],
            [-110.7444, 33.6978],
            [-110.7424, 33.6839],
            [-110.8795, 33.6812],
            [-111.0652, 33.6822],
            [-111.1222, 33.7589],
            [-111.0692, 33.8463],
            [-111.0696, 33.9873],
            [-111.2945, 33.9874],
            [-111.2714, 34.1192],
            [-111.0987, 34.1609],
            [-111.0988, 34.2482],
            [-110.9421, 34.2629],
            [-110.9429, 34.3122],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409540',
        NAME: 'Young Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2221322086,
        AWATER: 419425,
        INTPTLAT: '+33.9766515',
        INTPTLON: '-110.9603436',
        ELSDLEA: '09540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2448, 34.8939],
            [-114.0203, 34.8919],
            [-114.0213, 34.9784],
            [-113.8132, 34.9786],
            [-113.8126, 34.8057],
            [-113.7779, 34.8051],
            [-113.7782, 34.5071],
            [-114.1939, 34.5067],
            [-114.1933, 34.6211],
            [-114.1925, 34.8053],
            [-114.2443, 34.8058],
            [-114.2448, 34.8939],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409570',
        NAME: 'Yucca Elementary District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1818089710,
        AWATER: 25631,
        INTPTLAT: '+34.7323251',
        INTPTLON: '-113.9748830',
        ELSDLEA: '09570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6504, 32.733],
            [-114.5269, 32.7571],
            [-114.4629, 32.9079],
            [-114.5165, 33.0276],
            [-114.2687, 33.0298],
            [-114.2614, 32.6691],
            [-114.2911, 32.591],
            [-114.2905, 32.591],
            [-114.0215, 32.5914],
            [-113.3337, 32.5936],
            [-113.3338, 32.0386],
            [-113.9294, 32.2246],
            [-114.6215, 32.4366],
            [-114.6326, 32.6187],
            [-114.6504, 32.733],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409600',
        NAME: 'Yuma Elementary District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5920024037,
        AWATER: 11466350,
        INTPTLAT: '+32.5295065',
        INTPTLON: '-114.0048912',
        ELSDLEA: '09600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9957, 33.9009],
            [-111.786, 33.9005],
            [-111.7697, 33.7703],
            [-111.6861, 33.7417],
            [-111.6997, 33.6405],
            [-111.7874, 33.6401],
            [-111.7875, 33.6981],
            [-111.8906, 33.698],
            [-111.9967, 33.7264],
            [-111.9957, 33.9009],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400001',
        NAME: 'Cave Creek Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538358255,
        AWATER: 136942,
        INTPTLAT: '+33.7682294',
        INTPTLON: '-111.8588413',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.735, 35.069],
            [-112.5231, 35.0704],
            [-112.523, 34.9833],
            [-112.3346, 34.9882],
            [-112.3345, 34.973],
            [-112.3076, 34.8024],
            [-112.2007, 34.8031],
            [-112.2016, 34.7156],
            [-112.378, 34.717],
            [-112.3771, 34.6434],
            [-112.5174, 34.6578],
            [-112.5364, 34.8061],
            [-112.523, 34.8947],
            [-112.6285, 34.8954],
            [-112.6283, 34.9822],
            [-112.7335, 34.9826],
            [-112.735, 35.069],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400003',
        NAME: 'Chino Valley Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1041558725,
        AWATER: 157636,
        INTPTLAT: '+34.8594247',
        INTPTLON: '-112.4467294',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.321, 37],
            [-112.9376, 37.0003],
            [-112.9177, 36.731],
            [-112.9905, 36.7308],
            [-112.9823, 36.2317],
            [-113.1249, 36.1711],
            [-113.0917, 36.2096],
            [-113.0909, 36.4701],
            [-113.3066, 36.4692],
            [-113.321, 37],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400021',
        NAME: 'Colorado City Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2161284898,
        AWATER: 863717,
        INTPTLAT: '+36.6872576',
        INTPTLON: '-113.1156932',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 36.2984],
            [-110.0007, 36.2984],
            [-110.0007, 35.8805],
            [-110.077, 35.8804],
            [-110.2626, 35.9894],
            [-110.4015, 36],
            [-110.7507, 35.9775],
            [-110.7507, 36.2984],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400023',
        NAME: 'Pinon Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2436156471,
        AWATER: 470679,
        INTPTLAT: '+36.1323302',
        INTPTLON: '-110.3498472',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 34.6366],
            [-110.4865, 34.6403],
            [-110.4865, 34.8208],
            [-110.3785, 34.792],
            [-110.373, 34.3874],
            [-110.4249, 34.3059],
            [-110.7507, 34.2647],
            [-110.7507, 34.6366],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400026',
        NAME: 'Heber-Overgaard Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1494550170,
        AWATER: 6967158,
        INTPTLAT: '+34.4652586',
        INTPTLON: '-110.5612021',
        ELSDLEA: '',
        UNSDLEA: '00026',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4515, 32.4274],
            [-110.2512, 32.4267],
            [-110.2504, 32.2674],
            [-110.0458, 32.2679],
            [-110.0455, 32.166],
            [-110.1486, 32.1674],
            [-110.1486, 32.0787],
            [-110.0467, 32.0942],
            [-109.9379, 32.0499],
            [-109.9626, 31.9347],
            [-110.0438, 31.9346],
            [-110.1471, 31.9785],
            [-110.1478, 32.0211],
            [-110.3101, 32.0217],
            [-110.2459, 31.9491],
            [-110.347, 31.9491],
            [-110.3658, 31.8756],
            [-110.4508, 31.9051],
            [-110.451, 31.9477],
            [-110.4514, 32.0497],
            [-110.4481, 32.1376],
            [-110.3438, 32.1373],
            [-110.3482, 32.2683],
            [-110.451, 32.269],
            [-110.4515, 32.4274],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400212',
        NAME: 'Benson Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1515151454,
        AWATER: 844008,
        INTPTLAT: '+32.1545717',
        INTPTLON: '-110.2323326',
        ELSDLEA: '',
        UNSDLEA: '00212',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0439, 36.1933],
            [-113.8358, 36.0491],
            [-113.8303, 35.497],
            [-113.8135, 35.1523],
            [-113.8132, 34.9786],
            [-114.0213, 34.9784],
            [-114.0203, 34.8919],
            [-114.2448, 34.8939],
            [-114.2443, 35.1563],
            [-114.4008, 35.1544],
            [-114.4084, 35.3283],
            [-114.4614, 35.3281],
            [-114.4656, 35.6765],
            [-114.683, 35.6764],
            [-114.7081, 35.9099],
            [-114.7545, 36.0862],
            [-114.6271, 36.1408],
            [-114.5047, 36.1272],
            [-114.3702, 36.1426],
            [-114.2439, 36.0153],
            [-114.1534, 36.0232],
            [-114.0439, 36.1933],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400295',
        NAME: 'Kingman Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7819677587,
        AWATER: 233358062,
        INTPTLAT: '+35.5697848',
        INTPTLON: '-114.1715227',
        ELSDLEA: '',
        UNSDLEA: '00295',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9158, 32.5054],
            [-112.7254, 32.5055],
            [-112.7278, 32.3313],
            [-112.9164, 32.3314],
            [-112.9158, 32.5054],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400520',
        NAME: 'Ajo Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344979445,
        AWATER: 0,
        INTPTLAT: '+32.4183508',
        INTPTLON: '-112.8214191',
        ELSDLEA: '',
        UNSDLEA: '00520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0297, 32.5112],
            [-110.9992, 32.511],
            [-110.8587, 32.5112],
            [-110.9098, 32.3373],
            [-110.9438, 32.2857],
            [-110.9865, 32.2503],
            [-111.0299, 32.3374],
            [-111.0297, 32.5112],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400680',
        NAME: 'Amphitheater Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282467858,
        AWATER: 535621,
        INTPTLAT: '+32.4129772',
        INTPTLON: '-110.9635991',
        ELSDLEA: '',
        UNSDLEA: '00680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5843, 33.3786],
            [-111.5806, 33.4659],
            [-111.2698, 33.4655],
            [-111.2658, 33.2922],
            [-111.3754, 33.2783],
            [-111.5831, 33.2926],
            [-111.5838, 33.3353],
            [-111.5843, 33.3786],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400790',
        NAME: 'Apache Junction Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561935478,
        AWATER: 76198,
        INTPTLAT: '+33.3846492',
        INTPTLON: '-111.4220685',
        ELSDLEA: '',
        UNSDLEA: '00790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.7633, 35.3134],
            [-112.6948, 35.3116],
            [-112.5483, 35.2376],
            [-112.5491, 35.3254],
            [-112.4413, 35.3256],
            [-112.4423, 35.1494],
            [-112.3357, 35.1483],
            [-112.3346, 34.9882],
            [-112.523, 34.9833],
            [-112.5231, 35.0704],
            [-112.735, 35.069],
            [-112.7634, 35.1533],
            [-112.7633, 35.3134],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '400910',
        NAME: 'Ash Fork Joint Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 971740606,
        AWATER: 49936,
        INTPTLAT: '+35.1600326',
        INTPTLON: '-112.5511790',
        ELSDLEA: '',
        UNSDLEA: '00910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3334, 34.7209],
            [-113.1451, 34.7209],
            [-112.936, 34.7174],
            [-112.9358, 34.6047],
            [-112.971, 34.4121],
            [-113.3332, 34.4115],
            [-113.3334, 34.7209],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401000',
        NAME: 'Bagdad Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1226504487,
        AWATER: 57427,
        INTPTLAT: '+34.5733844',
        INTPTLON: '-113.1438803',
        ELSDLEA: '',
        UNSDLEA: '01000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0534, 31.5531],
            [-109.8519, 31.5532],
            [-109.8525, 31.6412],
            [-109.7728, 31.6861],
            [-109.7036, 31.6418],
            [-109.7239, 31.5545],
            [-109.7321, 31.4376],
            [-109.6465, 31.4374],
            [-109.6472, 31.334],
            [-109.9184, 31.3338],
            [-110.0036, 31.3798],
            [-110.0035, 31.4685],
            [-110.0534, 31.5531],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401180',
        NAME: 'Bisbee Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 879145591,
        AWATER: 3495215,
        INTPTLAT: '+31.4723333',
        INTPTLON: '-109.8301165',
        ELSDLEA: '',
        UNSDLEA: '01180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7225, 32.4271],
            [-109.6736, 32.4269],
            [-109.3301, 32.426],
            [-109.3278, 32.0795],
            [-109.3366, 32.0795],
            [-109.4828, 32.0795],
            [-109.5333, 32.1658],
            [-109.5283, 32.2553],
            [-109.6359, 32.253],
            [-109.7129, 32.2531],
            [-109.7225, 32.4271],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401330',
        NAME: 'Bowie Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1024770989,
        AWATER: 507064,
        INTPTLAT: '+32.2845813',
        INTPTLON: '-109.4934658',
        ELSDLEA: '',
        UNSDLEA: '01330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.3594, 31.435],
            [-110.2721, 31.5116],
            [-110.3083, 31.5689],
            [-110.2057, 31.6432],
            [-110.1036, 31.6431],
            [-110.1041, 31.5555],
            [-110.1897, 31.556],
            [-110.2068, 31.4673],
            [-110.2743, 31.3798],
            [-110.3594, 31.435],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401460',
        NAME: 'Sierra Vista Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279708312,
        AWATER: 560771,
        INTPTLAT: '+31.5329840',
        INTPTLON: '-110.2193565',
        ELSDLEA: '',
        UNSDLEA: '01460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9853, 34.4564],
            [-111.977, 34.6451],
            [-111.8536, 34.6566],
            [-111.8532, 34.6109],
            [-111.5534, 34.6107],
            [-111.5547, 34.4717],
            [-111.6596, 34.3863],
            [-111.6853, 34.2817],
            [-111.7753, 34.2822],
            [-111.7751, 34.3688],
            [-111.9856, 34.3695],
            [-111.9853, 34.4564],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401600',
        NAME: 'Camp Verde Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1143067243,
        AWATER: 128223,
        INTPTLAT: '+34.4869563',
        INTPTLON: '-111.7853472',
        ELSDLEA: '',
        UNSDLEA: '01600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9098, 32.3373],
            [-110.8241, 32.3378],
            [-110.9438, 32.2857],
            [-110.9098, 32.3373],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401760',
        NAME: 'Catalina Foothills Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65463190,
        AWATER: 0,
        INTPTLAT: '+32.3154985',
        INTPTLON: '-110.8843712',
        ELSDLEA: '',
        UNSDLEA: '01760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 35.9775],
            [-110.4015, 36],
            [-110.2626, 35.9894],
            [-110.077, 35.8804],
            [-110.0007, 35.8805],
            [-110.0007, 35.8371],
            [-110.0007, 35.6627],
            [-109.8269, 35.6625],
            [-109.8267, 35.6054],
            [-110.3593, 35.6057],
            [-110.3683, 35.5189],
            [-110.4389, 35.5189],
            [-110.7507, 35.5198],
            [-110.7507, 35.5237],
            [-110.7507, 35.9775],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401810',
        NAME: 'Cedar Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3204217176,
        AWATER: 1393117,
        INTPTLAT: '+35.7645907',
        INTPTLON: '-110.4003356',
        ELSDLEA: '',
        UNSDLEA: '01810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8931, 33.3349],
            [-111.8417, 33.3351],
            [-111.7379, 33.2777],
            [-111.6774, 33.249],
            [-111.669, 33.2047],
            [-111.7892, 33.2047],
            [-111.8931, 33.2048],
            [-111.8931, 33.3349],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401870',
        NAME: 'Chandler Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205602193,
        AWATER: 504382,
        INTPTLAT: '+33.2596460',
        INTPTLON: '-111.8104650',
        ELSDLEA: '',
        UNSDLEA: '01870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0007, 36.2984],
            [-110.0007, 36.4661],
            [-109.0457, 36.4656],
            [-109.046, 36.0023],
            [-109.0461, 35.923],
            [-109.1708, 35.9238],
            [-109.1747, 35.8369],
            [-109.2807, 35.8366],
            [-110.0007, 35.8371],
            [-110.0007, 35.8805],
            [-110.0007, 36.2984],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '401940',
        NAME: 'Chinle Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5869418490,
        AWATER: 11705631,
        INTPTLAT: '+36.1563867',
        INTPTLON: '-109.5287026',
        ELSDLEA: '',
        UNSDLEA: '01940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4657, 33.0352],
            [-109.3574, 33.0351],
            [-109.3398, 33.1803],
            [-109.3055, 33.2091],
            [-109.1557, 33.2091],
            [-109.0471, 33.2404],
            [-109.0472, 33.209],
            [-109.0471, 32.9764],
            [-109.4254, 32.9768],
            [-109.4657, 33.0352],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402110',
        NAME: 'Clifton Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 767594508,
        AWATER: 3381537,
        INTPTLAT: '+33.1028425',
        INTPTLON: '-109.1906758',
        ELSDLEA: '',
        UNSDLEA: '02110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7892, 33.2047],
            [-111.669, 33.2047],
            [-111.5828, 33.2056],
            [-111.5773, 33.1182],
            [-111.4214, 32.9881],
            [-111.4149, 32.9086],
            [-111.4647, 32.8506],
            [-111.5675, 32.8503],
            [-111.5667, 32.9232],
            [-111.6705, 32.9234],
            [-111.6691, 32.9961],
            [-111.6688, 33.0898],
            [-111.7892, 33.1179],
            [-111.7892, 33.2047],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402320',
        NAME: 'Coolidge Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 678516515,
        AWATER: 2112304,
        INTPTLAT: '+33.0389355',
        INTPTLON: '-111.5977167',
        ELSDLEA: '',
        UNSDLEA: '02320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.6465, 31.4374],
            [-109.6465, 31.4661],
            [-109.5029, 31.4654],
            [-109.5446, 31.5535],
            [-109.5034, 31.553],
            [-109.3888, 31.5531],
            [-109.3353, 31.553],
            [-109.0493, 31.5525],
            [-109.05, 31.3325],
            [-109.6472, 31.334],
            [-109.6465, 31.4374],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402530',
        NAME: 'Douglas Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1249342826,
        AWATER: 2642269,
        INTPTLAT: '+31.4276624',
        INTPTLON: '-109.2718234',
        ELSDLEA: '',
        UNSDLEA: '02530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4254, 32.9768],
            [-109.0471, 32.9764],
            [-109.0471, 32.7776],
            [-109.0476, 32.4264],
            [-109.114, 32.4262],
            [-109.2261, 32.5291],
            [-109.2062, 32.6358],
            [-109.245, 32.742],
            [-109.4254, 32.9768],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402600',
        NAME: 'Duncan Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1214653558,
        AWATER: 3426888,
        INTPTLAT: '+32.7509867',
        INTPTLON: '-109.1966461',
        ELSDLEA: '',
        UNSDLEA: '02600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5133, 33.6388],
            [-112.513, 33.6969],
            [-112.4123, 33.7247],
            [-112.3602, 33.7248],
            [-112.3548, 33.6821],
            [-112.325, 33.6767],
            [-112.3043, 33.6457],
            [-112.3074, 33.5942],
            [-112.3065, 33.5365],
            [-112.5135, 33.5515],
            [-112.5133, 33.6388],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402690',
        NAME: 'Dysart Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331084611,
        AWATER: 647774,
        INTPTLAT: '+33.6284081',
        INTPTLON: '-112.4161038',
        ELSDLEA: '',
        UNSDLEA: '02690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9118, 35.1049],
            [-111.8939, 35.3241],
            [-111.816, 35.3249],
            [-111.8164, 35.4132],
            [-111.9066, 35.4569],
            [-111.9043, 35.934],
            [-111.7607, 35.9341],
            [-111.7604, 36.0073],
            [-111.7173, 35.9775],
            [-111.7262, 35.847],
            [-111.6932, 35.7461],
            [-111.7465, 35.6721],
            [-111.5544, 35.6989],
            [-111.5196, 35.7575],
            [-111.3205, 35.7571],
            [-111.33, 35.6524],
            [-111.2443, 35.4954],
            [-111.0006, 35.4955],
            [-111.0006, 35.5244],
            [-110.7507, 35.5237],
            [-110.7507, 35.5198],
            [-110.7507, 34.7208],
            [-111.1254, 34.721],
            [-111.1253, 34.4673],
            [-111.3419, 34.4682],
            [-111.3419, 34.4248],
            [-111.3789, 34.4309],
            [-111.4919, 34.4227],
            [-111.5547, 34.4717],
            [-111.5534, 34.6107],
            [-111.5521, 34.8025],
            [-111.6765, 34.8032],
            [-111.7783, 34.8036],
            [-111.7777, 34.9339],
            [-111.7778, 34.981],
            [-111.9121, 34.9763],
            [-111.9118, 35.1049],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402860',
        NAME: 'Flagstaff Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11429847822,
        AWATER: 36916059,
        INTPTLAT: '+35.1567691',
        INTPTLON: '-111.3476214',
        ELSDLEA: '',
        UNSDLEA: '02860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5828, 33.2056],
            [-111.3924, 33.206],
            [-111.2689, 33.2059],
            [-111.1639, 33.2493],
            [-111.1645, 33.0963],
            [-111.1647, 33.0327],
            [-110.9627, 33.0326],
            [-110.9451, 32.7716],
            [-110.9451, 32.6843],
            [-110.9993, 32.5984],
            [-111.0892, 32.5985],
            [-111.2597, 32.7441],
            [-111.3613, 32.7629],
            [-111.3615, 32.8497],
            [-111.4647, 32.8506],
            [-111.4149, 32.9086],
            [-111.4214, 32.9881],
            [-111.5773, 33.1182],
            [-111.5828, 33.2056],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '402920',
        NAME: 'Florence Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2407884324,
        AWATER: 1542496,
        INTPTLAT: '+32.9437185',
        INTPTLON: '-111.2223323',
        ELSDLEA: '',
        UNSDLEA: '02920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0299, 32.3374],
            [-110.9865, 32.2503],
            [-111.0629, 32.3087],
            [-111.0299, 32.3374],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403010',
        NAME: 'Flowing Wells Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30988859,
        AWATER: 496444,
        INTPTLAT: '+32.2960635',
        INTPTLON: '-111.0258593',
        ELSDLEA: '',
        UNSDLEA: '03010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7874, 33.6401],
            [-111.6997, 33.6405],
            [-111.7701, 33.5679],
            [-111.7874, 33.6401],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403040',
        NAME: 'Fountain Hills Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63754613,
        AWATER: 237133,
        INTPTLAT: '+33.6074523',
        INTPTLON: '-111.7222360',
        ELSDLEA: '',
        UNSDLEA: '03040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9376, 37.0003],
            [-112.8992, 37.0004],
            [-112.0007, 37.001],
            [-111.4128, 37.0015],
            [-111.4117, 37.0015],
            [-111.3905, 36.9634],
            [-111.6162, 36.8489],
            [-111.8581, 36.4994],
            [-111.8529, 36.3848],
            [-111.8591, 36.2967],
            [-111.7996, 36.1938],
            [-111.8194, 36.129],
            [-111.9058, 36.0461],
            [-112.0863, 36.1012],
            [-112.2006, 36.0969],
            [-112.3002, 36.1512],
            [-112.3287, 36.2081],
            [-112.5293, 36.2669],
            [-112.4569, 36.3348],
            [-112.5413, 36.4001],
            [-112.6399, 36.3888],
            [-112.6816, 36.3417],
            [-112.9823, 36.2317],
            [-112.9905, 36.7308],
            [-112.9177, 36.731],
            [-112.9376, 37.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403080',
        NAME: 'Fredonia-Moccasin Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9667910633,
        AWATER: 32023943,
        INTPTLAT: '+36.6176875',
        INTPTLON: '-112.2992800',
        ELSDLEA: '',
        UNSDLEA: '03080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4478, 31.5851],
            [-110.2057, 31.6432],
            [-110.3083, 31.5689],
            [-110.2721, 31.5116],
            [-110.3594, 31.435],
            [-110.4478, 31.5851],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403150',
        NAME: 'Fort Huachuca Accommodation District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328559358,
        AWATER: 152613,
        INTPTLAT: '+31.5672964',
        INTPTLON: '-110.3302287',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0006, 33.5769],
            [-109.9048, 33.5751],
            [-109.9053, 33.3772],
            [-109.9419, 33.3774],
            [-109.9372, 33.0276],
            [-109.9009, 32.9439],
            [-110.2302, 32.9439],
            [-110.3627, 33.0013],
            [-110.4494, 32.9874],
            [-110.449, 33.1945],
            [-110.4517, 33.3616],
            [-110.3409, 33.4271],
            [-110.1695, 33.4657],
            [-110.0006, 33.4659],
            [-110.0006, 33.5769],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403200',
        NAME: 'Fort Thomas Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2637995745,
        AWATER: 28132928,
        INTPTLAT: '+33.2111779',
        INTPTLON: '-110.1086483',
        ELSDLEA: '',
        UNSDLEA: '03200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0007, 35.8371],
            [-109.2807, 35.8366],
            [-109.2945, 35.515],
            [-109.3152, 35.4709],
            [-109.8436, 35.4707],
            [-109.8267, 35.6054],
            [-109.8269, 35.6625],
            [-110.0007, 35.6627],
            [-110.0007, 35.8371],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403290',
        NAME: 'Ganado Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2274987682,
        AWATER: 2963655,
        INTPTLAT: '+35.6676446',
        INTPTLON: '-109.6060032',
        ELSDLEA: '',
        UNSDLEA: '03290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0231, 32.825],
            [-113.0232, 32.8836],
            [-112.7869, 32.8844],
            [-112.7876, 32.9709],
            [-112.9779, 32.9982],
            [-113.0235, 32.9411],
            [-113.0259, 33.1162],
            [-112.8283, 33.1165],
            [-112.6843, 33.1163],
            [-112.5113, 33.1161],
            [-112.5112, 32.9426],
            [-112.5207, 32.5055],
            [-112.5748, 32.5052],
            [-112.7254, 32.5055],
            [-112.9158, 32.5054],
            [-113.0281, 32.5052],
            [-113.0231, 32.825],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403310',
        NAME: 'Gila Bend Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3010431310,
        AWATER: 0,
        INTPTLAT: '+32.8018581',
        INTPTLON: '-112.7584407',
        ELSDLEA: '',
        UNSDLEA: '03310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5843, 33.3786],
            [-111.5838, 33.3353],
            [-111.67, 33.336],
            [-111.7379, 33.2777],
            [-111.8417, 33.3351],
            [-111.8313, 33.386],
            [-111.5843, 33.3786],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403400',
        NAME: 'Gilbert Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155671351,
        AWATER: 374519,
        INTPTLAT: '+33.3480491',
        INTPTLON: '-111.7269826',
        ELSDLEA: '',
        UNSDLEA: '03400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.8795, 33.6812],
            [-110.7424, 33.6839],
            [-110.694, 33.5038],
            [-110.7381, 33.2519],
            [-110.9162, 33.2503],
            [-110.8762, 33.2916],
            [-110.8606, 33.437],
            [-110.8968, 33.5552],
            [-110.8795, 33.6812],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403500',
        NAME: 'Globe Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 676076529,
        AWATER: 2689296,
        INTPTLAT: '+33.4673814',
        INTPTLON: '-110.7848641',
        ELSDLEA: '',
        UNSDLEA: '03500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3287, 36.2081],
            [-112.3002, 36.1512],
            [-112.2006, 36.0969],
            [-112.0863, 36.1012],
            [-111.9058, 36.0461],
            [-111.8194, 36.129],
            [-111.7996, 36.1938],
            [-111.7628, 36.1985],
            [-111.7604, 36.0073],
            [-111.7607, 35.9341],
            [-111.9043, 35.934],
            [-112.1137, 35.9338],
            [-112.1149, 35.7621],
            [-112.3286, 35.7628],
            [-112.329, 36.0204],
            [-112.3287, 36.2081],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403550',
        NAME: 'Grand Canyon Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1336376510,
        AWATER: 3077512,
        INTPTLAT: '+35.9784936',
        INTPTLON: '-112.1007282',
        ELSDLEA: '',
        UNSDLEA: '03550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9162, 33.2503],
            [-110.7381, 33.2519],
            [-110.7528, 33.1706],
            [-110.5319, 33.1695],
            [-110.6344, 33.1424],
            [-110.7828, 32.9847],
            [-110.8656, 33.1195],
            [-110.9344, 33.2305],
            [-110.9162, 33.2503],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403730',
        NAME: 'Hayden-Winkelman Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441421852,
        AWATER: 974460,
        INTPTLAT: '+33.1866560',
        INTPTLON: '-110.7777181',
        ELSDLEA: '',
        UNSDLEA: '03730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.67, 33.336],
            [-111.6774, 33.249],
            [-111.7379, 33.2777],
            [-111.67, 33.336],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403780',
        NAME: 'Higley Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61696332,
        AWATER: 62943,
        INTPTLAT: '+33.2898253',
        INTPTLON: '-111.7055832',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.3683, 35.5189],
            [-110.3593, 35.6057],
            [-109.8267, 35.6054],
            [-109.8436, 35.4707],
            [-109.8448, 34.9095],
            [-109.8507, 34.6465],
            [-109.8507, 34.6462],
            [-110.2742, 34.6417],
            [-110.2744, 34.7922],
            [-110.257, 34.9005],
            [-110.1988, 34.8979],
            [-110.1912, 35.344],
            [-110.3681, 35.3453],
            [-110.3683, 35.5189],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403820',
        NAME: 'Holbrook Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4070637702,
        AWATER: 2353758,
        INTPTLAT: '+35.1309592',
        INTPTLON: '-110.0714366',
        ELSDLEA: '',
        UNSDLEA: '03820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2016, 34.7156],
            [-112.0905, 34.6576],
            [-111.977, 34.6451],
            [-111.9853, 34.4564],
            [-112.3252, 34.4707],
            [-112.3771, 34.6434],
            [-112.378, 34.717],
            [-112.2016, 34.7156],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403870',
        NAME: 'Humboldt Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 826273808,
        AWATER: 0,
        INTPTLAT: '+34.5799146',
        INTPTLON: '-112.1736442',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.7278, 32.3313],
            [-112.6765, 32.418],
            [-112.5752, 32.4187],
            [-112.5748, 32.5052],
            [-112.5207, 32.5055],
            [-112.2037, 32.5068],
            [-111.8614, 32.5067],
            [-111.7946, 32.5068],
            [-111.8066, 32.3746],
            [-111.6815, 32.3742],
            [-111.5908, 32.4218],
            [-111.4737, 32.3742],
            [-111.4743, 32.2159],
            [-111.218, 32.2191],
            [-111.218, 32.1902],
            [-111.4409, 32.1725],
            [-111.4785, 32.0107],
            [-111.5473, 32.0098],
            [-111.5812, 31.9237],
            [-111.595, 31.7695],
            [-111.6516, 31.5896],
            [-111.6231, 31.5079],
            [-112.6078, 31.8159],
            [-112.7278, 32.1573],
            [-112.7278, 32.3313],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403950',
        NAME: 'Indian Oasis-Baboquivari Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9757021672,
        AWATER: 714643,
        INTPTLAT: '+32.0833805',
        INTPTLON: '-112.0669560',
        ELSDLEA: '',
        UNSDLEA: '03950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5831, 33.2926],
            [-111.3754, 33.2783],
            [-111.3924, 33.206],
            [-111.5828, 33.2056],
            [-111.5831, 33.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '403990',
        NAME: 'J. O. Combs Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176446399,
        AWATER: 0,
        INTPTLAT: '+33.2496505',
        INTPTLON: '-111.4848745',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4387, 35.17],
            [-110.3679, 35.1706],
            [-110.3681, 35.3453],
            [-110.1912, 35.344],
            [-110.1988, 34.8979],
            [-110.257, 34.9005],
            [-110.2744, 34.7922],
            [-110.3785, 34.792],
            [-110.4865, 34.8208],
            [-110.4501, 34.9598],
            [-110.4387, 35.17],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404010',
        NAME: 'Joseph City Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1221535355,
        AWATER: 1696824,
        INTPTLAT: '+35.0796100',
        INTPTLON: '-110.2839243',
        ELSDLEA: '',
        UNSDLEA: '04010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 36.5586],
            [-110.7507, 37.003],
            [-110.0007, 36.9983],
            [-110.0007, 36.4661],
            [-110.0007, 36.2984],
            [-110.7507, 36.2984],
            [-110.7507, 36.5586],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404060',
        NAME: 'Kayenta Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5217239676,
        AWATER: 3095250,
        INTPTLAT: '+36.6207929',
        INTPTLON: '-110.4254480',
        ELSDLEA: '',
        UNSDLEA: '04060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.4389, 34.6223],
            [-114.4041, 34.7615],
            [-114.3517, 34.7615],
            [-114.3505, 34.622],
            [-114.1933, 34.6211],
            [-114.1939, 34.5067],
            [-114.1933, 34.3574],
            [-114.3396, 34.4514],
            [-114.4389, 34.6223],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404280',
        NAME: 'Lake Havasu Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470624153,
        AWATER: 30125202,
        INTPTLAT: '+34.5449370',
        INTPTLON: '-114.3020714',
        ELSDLEA: '',
        UNSDLEA: '04280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0501, 37.0002],
            [-113.321, 37],
            [-113.3066, 36.4692],
            [-113.0909, 36.4701],
            [-113.0917, 36.2096],
            [-113.1249, 36.1711],
            [-113.2249, 36.0911],
            [-113.3134, 36.1],
            [-113.3542, 36.0404],
            [-113.3171, 35.9614],
            [-113.334, 35.803],
            [-113.3839, 35.7546],
            [-113.5027, 35.7658],
            [-113.6282, 35.8224],
            [-113.8358, 36.0491],
            [-114.0439, 36.1933],
            [-114.0504, 36.8431],
            [-114.0501, 37.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404410',
        NAME: 'Littlefield Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8505729736,
        AWATER: 24201359,
        INTPTLAT: '+36.4460816',
        INTPTLON: '-113.6336219',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7555, 32.7714],
            [-110.4485, 32.7712],
            [-110.4486, 32.7023],
            [-110.4512, 32.5138],
            [-110.6529, 32.5139],
            [-110.6526, 32.6701],
            [-110.7556, 32.6697],
            [-110.7555, 32.7714],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404570',
        NAME: 'Mammoth-San Manuel Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653673713,
        AWATER: 1586901,
        INTPTLAT: '+32.6553292',
        INTPTLON: '-110.5759899',
        ELSDLEA: '',
        UNSDLEA: '04570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5908, 32.5068],
            [-111.4657, 32.5013],
            [-111.0297, 32.5112],
            [-111.0299, 32.3374],
            [-111.0629, 32.3087],
            [-111.0966, 32.2202],
            [-111.1664, 32.1903],
            [-111.218, 32.1902],
            [-111.218, 32.2191],
            [-111.4743, 32.2159],
            [-111.4737, 32.3742],
            [-111.5908, 32.4218],
            [-111.5908, 32.5068],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404630',
        NAME: 'Marana Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1416064770,
        AWATER: 2022544,
        INTPTLAT: '+32.3804501',
        INTPTLON: '-111.2924291',
        ELSDLEA: '',
        UNSDLEA: '04630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.202, 33.1167],
            [-111.9615, 33.1177],
            [-111.9275, 33.0289],
            [-111.963, 32.9699],
            [-112.1, 32.9696],
            [-112.2028, 32.9417],
            [-112.202, 33.1167],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404720',
        NAME: 'Maricopa Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409458599,
        AWATER: 337693,
        INTPTLAT: '+33.0361723',
        INTPTLON: '-112.0799574',
        ELSDLEA: '',
        UNSDLEA: '04720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3777, 34.3752],
            [-112.3252, 34.4707],
            [-111.9853, 34.4564],
            [-111.9856, 34.3695],
            [-111.7751, 34.3688],
            [-111.7753, 34.2822],
            [-111.6853, 34.2817],
            [-111.7121, 34.1513],
            [-111.4843, 34.151],
            [-111.4948, 33.9998],
            [-111.7253, 33.9998],
            [-111.9962, 34.0298],
            [-112.0995, 34.041],
            [-112.0943, 34.2411],
            [-112.1994, 34.2397],
            [-112.1995, 34.3424],
            [-112.3778, 34.3463],
            [-112.3777, 34.3752],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404820',
        NAME: 'Mayer Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2243957548,
        AWATER: 4987063,
        INTPTLAT: '+34.2197183',
        INTPTLON: '-111.9254128',
        ELSDLEA: '',
        UNSDLEA: '04820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8946, 33.3638],
            [-111.892, 33.4513],
            [-111.8917, 33.5021],
            [-111.7355, 33.4954],
            [-111.6824, 33.5673],
            [-111.5808, 33.5673],
            [-111.5806, 33.4659],
            [-111.5843, 33.3786],
            [-111.8313, 33.386],
            [-111.8417, 33.3351],
            [-111.8931, 33.3349],
            [-111.8946, 33.3638],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '404970',
        NAME: 'Mesa Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480790859,
        AWATER: 9726047,
        INTPTLAT: '+33.4544563',
        INTPTLON: '-111.7236690',
        ELSDLEA: '',
        UNSDLEA: '04970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3152, 33.6815],
            [-111.0652, 33.6822],
            [-110.8795, 33.6812],
            [-110.8968, 33.5552],
            [-110.8606, 33.437],
            [-110.8762, 33.2916],
            [-110.9162, 33.2503],
            [-110.9344, 33.2305],
            [-111.0399, 33.4661],
            [-111.1534, 33.6776],
            [-111.2617, 33.6226],
            [-111.3152, 33.6815],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405030',
        NAME: 'Miami Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 803645035,
        AWATER: 32803689,
        INTPTLAT: '+33.5188619',
        INTPTLON: '-110.9581986',
        ELSDLEA: '',
        UNSDLEA: '05030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4952, 33.1805],
            [-109.3398, 33.1803],
            [-109.3574, 33.0351],
            [-109.4657, 33.0352],
            [-109.4952, 33.1805],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405320',
        NAME: 'Morenci Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238222309,
        AWATER: 5626012,
        INTPTLAT: '+33.1244436',
        INTPTLON: '-109.4260028',
        ELSDLEA: '',
        UNSDLEA: '05320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6203, 33.7822],
            [-112.5336, 33.7822],
            [-112.5336, 33.8547],
            [-112.4296, 33.8545],
            [-112.4279, 33.9208],
            [-112.3764, 33.9079],
            [-112.3772, 33.7675],
            [-112.4123, 33.7247],
            [-112.513, 33.6969],
            [-112.608, 33.6969],
            [-112.6203, 33.7822],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405460',
        NAME: 'Nadaburg Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303641354,
        AWATER: 189147,
        INTPTLAT: '+33.7892258',
        INTPTLON: '-112.4852797',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3666, 31.5213],
            [-111.1637, 31.5218],
            [-111.1631, 31.4209],
            [-110.9404, 31.42],
            [-110.8907, 31.3332],
            [-111.0748, 31.3322],
            [-111.3666, 31.4261],
            [-111.3666, 31.5213],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405530',
        NAME: 'Nogales Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505862983,
        AWATER: 419833,
        INTPTLAT: '+31.4063471',
        INTPTLON: '-111.1182378',
        ELSDLEA: '',
        UNSDLEA: '05530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4117, 37.0015],
            [-110.7507, 37.003],
            [-110.7507, 36.5586],
            [-111.359, 36.5591],
            [-111.357, 36.3845],
            [-111.8529, 36.3848],
            [-111.8581, 36.4994],
            [-111.6162, 36.8489],
            [-111.3905, 36.9634],
            [-111.4117, 37.0015],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405820',
        NAME: 'Page Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4691596303,
        AWATER: 24299398,
        INTPTLAT: '+36.7094664',
        INTPTLON: '-111.2733702',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9967, 33.7264],
            [-111.8906, 33.698],
            [-111.8916, 33.5965],
            [-111.9961, 33.5537],
            [-112.0127, 33.5537],
            [-112.048, 33.555],
            [-112.0828, 33.6402],
            [-111.9967, 33.7264],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405930',
        NAME: 'Paradise Valley Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253218861,
        AWATER: 500050,
        INTPTLAT: '+33.6534512',
        INTPTLON: '-111.9799825',
        ELSDLEA: '',
        UNSDLEA: '05930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.4354, 34.0797],
            [-114.2541, 34.1738],
            [-114.1383, 34.3032],
            [-114.0311, 34.2598],
            [-113.7307, 34.2403],
            [-113.6651, 34.213],
            [-113.6655, 34.0702],
            [-113.9799, 34.0703],
            [-113.9798, 34.1251],
            [-114.1973, 34.1251],
            [-114.2201, 34.1058],
            [-114.3485, 33.7809],
            [-114.3626, 33.6802],
            [-114.4766, 33.6802],
            [-114.5249, 33.6388],
            [-114.4965, 33.6969],
            [-114.5341, 33.9252],
            [-114.4383, 34.0226],
            [-114.4354, 34.0797],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '405980',
        NAME: 'Parker Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1811695806,
        AWATER: 13579200,
        INTPTLAT: '+34.1427771',
        INTPTLON: '-113.8532508',
        ELSDLEA: '',
        UNSDLEA: '05980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4843, 34.151],
            [-111.4912, 34.2209],
            [-111.3786, 34.2852],
            [-111.3789, 34.4309],
            [-111.3419, 34.4248],
            [-111.2467, 34.4508],
            [-111.1393, 34.3864],
            [-111.0875, 34.395],
            [-110.9429, 34.3122],
            [-110.9421, 34.2629],
            [-111.0988, 34.2482],
            [-111.0987, 34.1609],
            [-111.2714, 34.1192],
            [-111.2945, 33.9874],
            [-111.4349, 33.987],
            [-111.4948, 33.9998],
            [-111.4843, 34.151],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406070',
        NAME: 'Payson Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1360772090,
        AWATER: 306265,
        INTPTLAT: '+34.2308287',
        INTPTLON: '-111.2684801',
        ELSDLEA: '',
        UNSDLEA: '06070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.5159, 35.5008],
            [-113.5027, 35.7658],
            [-113.3839, 35.7546],
            [-113.334, 35.803],
            [-113.3342, 35.528],
            [-113.3329, 35.1514],
            [-113.3596, 35.1515],
            [-113.3597, 35.243],
            [-113.493, 35.242],
            [-113.5159, 35.3283],
            [-113.5159, 35.5008],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406120',
        NAME: 'Peach Springs Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 923603951,
        AWATER: 593223,
        INTPTLAT: '+35.4774988',
        INTPTLON: '-113.4470910',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3764, 33.9079],
            [-112.273, 33.8943],
            [-112.273, 33.7249],
            [-112.1691, 33.639],
            [-112.169, 33.5675],
            [-112.2377, 33.5377],
            [-112.3065, 33.5365],
            [-112.3074, 33.5942],
            [-112.3043, 33.6457],
            [-112.325, 33.6767],
            [-112.3548, 33.6821],
            [-112.3602, 33.7248],
            [-112.3772, 33.7675],
            [-112.3764, 33.9079],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406250',
        NAME: 'Peoria Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 353196854,
        AWATER: 4761307,
        INTPTLAT: '+33.7197600',
        INTPTLON: '-112.2898820',
        ELSDLEA: '',
        UNSDLEA: '06250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2302, 32.9439],
            [-109.9009, 32.9439],
            [-109.9372, 33.0276],
            [-109.9419, 33.3774],
            [-109.9053, 33.3772],
            [-109.9048, 33.5751],
            [-109.8918, 33.5678],
            [-109.7673, 33.4754],
            [-109.7396, 33.5105],
            [-109.7379, 33.3777],
            [-109.782, 33.3766],
            [-109.783, 32.9444],
            [-109.8048, 32.884],
            [-109.8045, 32.5354],
            [-109.7895, 32.4272],
            [-109.8402, 32.4273],
            [-109.8686, 32.5143],
            [-109.8722, 32.6901],
            [-109.9395, 32.7333],
            [-110.0424, 32.7331],
            [-110.0433, 32.7764],
            [-110.1786, 32.776],
            [-110.1785, 32.8621],
            [-110.2302, 32.9439],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406440',
        NAME: 'Pima Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1958372869,
        AWATER: 4700654,
        INTPTLAT: '+33.0698916',
        INTPTLON: '-109.8498690',
        ELSDLEA: '',
        UNSDLEA: '06440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0407, 34.2005],
            [-109.8489, 34.2276],
            [-109.8494, 34.1259],
            [-109.8594, 34.1088],
            [-110.0115, 34.1467],
            [-110.0407, 34.2005],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406580',
        NAME: 'Blue Ridge Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198106079,
        AWATER: 1409226,
        INTPTLAT: '+34.1591215',
        INTPTLON: '-109.9273545',
        ELSDLEA: '',
        UNSDLEA: '06580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.626, 34.7189],
            [-112.5364, 34.8061],
            [-112.5174, 34.6578],
            [-112.3771, 34.6434],
            [-112.3252, 34.4707],
            [-112.3777, 34.3752],
            [-112.5012, 34.3753],
            [-112.4998, 34.4978],
            [-112.6246, 34.5045],
            [-112.626, 34.7189],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406730',
        NAME: 'Prescott Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 753808504,
        AWATER: 2093077,
        INTPTLAT: '+34.5627420',
        INTPTLON: '-112.4840155',
        ELSDLEA: '',
        UNSDLEA: '06730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8436, 35.4707],
            [-109.3152, 35.4709],
            [-109.315, 35.427],
            [-109.0467, 35.4287],
            [-109.0462, 35.1747],
            [-109.0459, 34.9597],
            [-109.046, 34.9042],
            [-109.8448, 34.9095],
            [-109.8436, 35.4707],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406740',
        NAME: 'Sanders Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4418777289,
        AWATER: 2442018,
        INTPTLAT: '+35.1849770',
        INTPTLON: '-109.4458574',
        ELSDLEA: '',
        UNSDLEA: '06740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6774, 33.249],
            [-111.67, 33.336],
            [-111.5838, 33.3353],
            [-111.5831, 33.2926],
            [-111.5828, 33.2056],
            [-111.669, 33.2047],
            [-111.6774, 33.249],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406810',
        NAME: 'Queen Creek Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118714302,
        AWATER: 123746,
        INTPTLAT: '+33.2701678',
        INTPTLON: '-111.6302290',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1645, 33.0963],
            [-111.067, 33.1151],
            [-111.0623, 33.221],
            [-110.9624, 33.2208],
            [-110.9622, 33.1339],
            [-110.8656, 33.1195],
            [-110.7828, 32.9847],
            [-110.6344, 33.1424],
            [-110.5319, 33.1695],
            [-110.449, 33.1945],
            [-110.4494, 32.9874],
            [-110.4485, 32.7712],
            [-110.7555, 32.7714],
            [-110.9451, 32.7716],
            [-110.9627, 33.0326],
            [-111.1647, 33.0327],
            [-111.1645, 33.0963],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406850',
        NAME: 'Ray Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2035475775,
        AWATER: 11790532,
        INTPTLAT: '+32.9730950',
        INTPTLON: '-110.7292671',
        ELSDLEA: '',
        UNSDLEA: '06850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0007, 36.9983],
            [-109.0452, 36.999],
            [-109.0457, 36.4656],
            [-110.0007, 36.4661],
            [-110.0007, 36.9983],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406870',
        NAME: 'Red Mesa Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5046727750,
        AWATER: 2600939,
        INTPTLAT: '+36.7266422',
        INTPTLON: '-109.5326651',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7381, 33.2519],
            [-110.694, 33.5038],
            [-110.7424, 33.6839],
            [-110.7444, 33.6978],
            [-110.6887, 33.7693],
            [-110.5848, 33.836],
            [-110.5259, 33.8144],
            [-110.5286, 33.7265],
            [-110.0007, 33.727],
            [-110.0006, 33.5769],
            [-110.0006, 33.4659],
            [-110.1695, 33.4657],
            [-110.3409, 33.4271],
            [-110.4517, 33.3616],
            [-110.449, 33.1945],
            [-110.5319, 33.1695],
            [-110.7528, 33.1706],
            [-110.7381, 33.2519],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '406960',
        NAME: 'San Carlos Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3034940045,
        AWATER: 23917478,
        INTPTLAT: '+33.5796449',
        INTPTLON: '-110.4524166',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8591, 33.9804],
            [-109.5442, 33.9801],
            [-109.5442, 34.1252],
            [-109.5342, 34.3009],
            [-109.533, 34.3875],
            [-109.0465, 34.3873],
            [-109.0466, 34.2595],
            [-109.0466, 33.908],
            [-109.2453, 33.909],
            [-109.2455, 33.8218],
            [-109.4363, 33.8216],
            [-109.4951, 33.7775],
            [-109.4967, 33.6524],
            [-109.6262, 33.5896],
            [-109.7396, 33.5105],
            [-109.7673, 33.4754],
            [-109.8918, 33.5678],
            [-109.8918, 33.7778],
            [-109.8593, 33.7777],
            [-109.8591, 33.9804],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407130',
        NAME: 'Round Valley Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4245299610,
        AWATER: 22746455,
        INTPTLAT: '+33.9928987',
        INTPTLON: '-109.4459828',
        ELSDLEA: '',
        UNSDLEA: '07130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.334, 33.6386],
            [-113.1274, 33.6386],
            [-112.5133, 33.6388],
            [-112.5135, 33.5515],
            [-112.5129, 33.4646],
            [-112.5305, 33.4645],
            [-112.6257, 33.4645],
            [-112.7639, 33.4644],
            [-112.7638, 33.3775],
            [-113.0216, 33.3774],
            [-113.0211, 33.551],
            [-113.3335, 33.552],
            [-113.334, 33.6386],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407170',
        NAME: 'Saddle Mountain Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1423199246,
        AWATER: 117833,
        INTPTLAT: '+33.5467276',
        INTPTLON: '-112.8634157',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7396, 33.5105],
            [-109.6262, 33.5896],
            [-109.6247, 33.3778],
            [-109.6686, 33.3775],
            [-109.6643, 32.5137],
            [-109.6736, 32.4269],
            [-109.7225, 32.4271],
            [-109.742, 32.4274],
            [-109.7331, 32.8866],
            [-109.783, 32.9444],
            [-109.782, 33.3766],
            [-109.7379, 33.3777],
            [-109.7396, 33.5105],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407240',
        NAME: 'Safford Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1092740329,
        AWATER: 2405354,
        INTPTLAT: '+33.0583274',
        INTPTLON: '-109.6843627',
        ELSDLEA: '',
        UNSDLEA: '07240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2663, 31.7696],
            [-111.2152, 31.7839],
            [-111.217, 32.0752],
            [-110.9614, 32.0753],
            [-110.859, 32.0304],
            [-110.858, 31.9046],
            [-110.7573, 31.8902],
            [-110.758, 31.818],
            [-110.8069, 31.8183],
            [-110.8578, 31.8992],
            [-111.0627, 31.8844],
            [-111.0622, 31.7685],
            [-110.9939, 31.7267],
            [-111.1642, 31.726],
            [-111.1637, 31.5218],
            [-111.3666, 31.5213],
            [-111.416, 31.5198],
            [-111.4027, 31.726],
            [-111.2647, 31.7262],
            [-111.2663, 31.7696],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407300',
        NAME: 'Sahuarita Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1574331760,
        AWATER: 377306,
        INTPTLAT: '+31.8160529',
        INTPTLON: '-111.1075526',
        ELSDLEA: '',
        UNSDLEA: '07300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3301, 32.426],
            [-109.114, 32.4262],
            [-109.0476, 32.4264],
            [-109.0483, 32.0818],
            [-109.0491, 31.8417],
            [-109.1579, 31.8155],
            [-109.221, 31.8793],
            [-109.2203, 31.9705],
            [-109.3202, 31.9711],
            [-109.3278, 32.0795],
            [-109.3301, 32.426],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407430',
        NAME: 'San Simon Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1565447182,
        AWATER: 206337,
        INTPTLAT: '+32.1452068',
        INTPTLON: '-109.2008576',
        ELSDLEA: '',
        UNSDLEA: '07430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1637, 31.5218],
            [-111.1642, 31.726],
            [-110.9939, 31.7267],
            [-110.9575, 31.7267],
            [-110.9594, 31.4875],
            [-110.8736, 31.4845],
            [-110.9404, 31.42],
            [-111.1631, 31.4209],
            [-111.1637, 31.5218],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407520',
        NAME: 'Santa Cruz Valley Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 690515033,
        AWATER: 749774,
        INTPTLAT: '+31.5665230',
        INTPTLON: '-111.0587982',
        ELSDLEA: '',
        UNSDLEA: '07520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8906, 33.698],
            [-111.7875, 33.6981],
            [-111.7874, 33.6401],
            [-111.7701, 33.5679],
            [-111.8914, 33.5672],
            [-111.8917, 33.5021],
            [-111.892, 33.4513],
            [-111.944, 33.4511],
            [-111.9955, 33.4804],
            [-111.9961, 33.5537],
            [-111.8916, 33.5965],
            [-111.8906, 33.698],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407570',
        NAME: 'Scottsdale Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286478032,
        AWATER: 1351061,
        INTPTLAT: '+33.5816291',
        INTPTLON: '-111.8867550',
        ELSDLEA: '',
        UNSDLEA: '07570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3342, 35.528],
            [-113.3013, 35.5311],
            [-113.1745, 35.5064],
            [-112.9759, 35.3929],
            [-112.9448, 35.3526],
            [-112.7633, 35.3134],
            [-112.7634, 35.1533],
            [-112.735, 35.069],
            [-112.7335, 34.9826],
            [-113.1773, 34.9755],
            [-113.1806, 34.8401],
            [-113.1451, 34.7209],
            [-113.3334, 34.7209],
            [-113.3329, 35.1514],
            [-113.3342, 35.528],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407630',
        NAME: 'Seligman Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3055474376,
        AWATER: 372351,
        INTPTLAT: '+35.1799764',
        INTPTLON: '-113.0943620',
        ELSDLEA: '',
        UNSDLEA: '07630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.373, 34.3874],
            [-109.8485, 34.3883],
            [-109.8486, 34.3015],
            [-109.8489, 34.2276],
            [-110.0407, 34.2005],
            [-110.3355, 34.2334],
            [-110.4249, 34.3059],
            [-110.373, 34.3874],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407700',
        NAME: 'Show Low Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 913758597,
        AWATER: 1765426,
        INTPTLAT: '+34.3151411',
        INTPTLON: '-110.1373867',
        ELSDLEA: '',
        UNSDLEA: '07700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.273, 33.8943],
            [-112.2029, 33.9705],
            [-112.0995, 34.041],
            [-111.9962, 34.0298],
            [-111.9957, 33.9009],
            [-111.9967, 33.7264],
            [-112.0828, 33.6402],
            [-112.1691, 33.639],
            [-112.273, 33.7249],
            [-112.273, 33.8943],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407750',
        NAME: 'Deer Valley Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 942147098,
        AWATER: 4806858,
        INTPTLAT: '+33.8385377',
        INTPTLON: '-112.1283031',
        ELSDLEA: '',
        UNSDLEA: '07750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.3785, 34.792],
            [-110.2744, 34.7922],
            [-110.2742, 34.6417],
            [-109.8507, 34.6462],
            [-109.8652, 34.5597],
            [-109.8485, 34.3883],
            [-110.373, 34.3874],
            [-110.3785, 34.792],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '407820',
        NAME: 'Snowflake Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1520920595,
        AWATER: 1184963,
        INTPTLAT: '+34.5393407',
        INTPTLON: '-110.1503850',
        ELSDLEA: '',
        UNSDLEA: '07820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4508, 31.9051],
            [-110.3658, 31.8756],
            [-110.347, 31.9491],
            [-110.2459, 31.9491],
            [-110.1471, 31.9785],
            [-110.0438, 31.9346],
            [-110.0436, 31.8179],
            [-110.1465, 31.8184],
            [-110.2629, 31.7308],
            [-110.4517, 31.7307],
            [-110.4517, 31.7313],
            [-110.4508, 31.9051],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408020',
        NAME: 'St. David Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761118587,
        AWATER: 318464,
        INTPTLAT: '+31.8459898',
        INTPTLON: '-110.2535072',
        ELSDLEA: '',
        UNSDLEA: '08020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8448, 34.9095],
            [-109.046, 34.9042],
            [-109.046, 34.6981],
            [-109.0465, 34.3873],
            [-109.533, 34.3875],
            [-109.5258, 34.5675],
            [-109.7517, 34.5622],
            [-109.7464, 34.6481],
            [-109.8507, 34.6465],
            [-109.8448, 34.9095],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408080',
        NAME: 'St. Johns Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3580715111,
        AWATER: 3670129,
        INTPTLAT: '+34.6792323',
        INTPTLON: '-109.4026583',
        ELSDLEA: '',
        UNSDLEA: '08080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1661, 32.1042],
            [-111.0194, 32.1049],
            [-110.9927, 32.1632],
            [-110.8579, 32.163],
            [-110.859, 32.0304],
            [-110.9614, 32.0753],
            [-111.217, 32.0752],
            [-111.1661, 32.1042],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408170',
        NAME: 'Sunnyside Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250483745,
        AWATER: 0,
        INTPTLAT: '+32.0979297',
        INTPTLON: '-110.9752480',
        ELSDLEA: '',
        UNSDLEA: '08170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3754, 33.2783],
            [-111.2658, 33.2922],
            [-111.2698, 33.4655],
            [-111.0399, 33.4661],
            [-110.9344, 33.2305],
            [-110.8656, 33.1195],
            [-110.9622, 33.1339],
            [-110.9624, 33.2208],
            [-111.0623, 33.221],
            [-111.067, 33.1151],
            [-111.1645, 33.0963],
            [-111.1639, 33.2493],
            [-111.2689, 33.2059],
            [-111.3924, 33.206],
            [-111.3754, 33.2783],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408230',
        NAME: 'Superior Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 974677181,
        AWATER: 267940,
        INTPTLAT: '+33.3392788',
        INTPTLON: '-111.1158976',
        ELSDLEA: '',
        UNSDLEA: '08230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7554, 32.3377],
            [-110.6523, 32.3383],
            [-110.6524, 32.1621],
            [-110.7557, 32.1628],
            [-110.7554, 32.3377],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408280',
        NAME: 'Tanque Verde Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201362221,
        AWATER: 0,
        INTPTLAT: '+32.2516572',
        INTPTLON: '-110.7076014',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.783, 32.9444],
            [-109.7331, 32.8866],
            [-109.742, 32.4274],
            [-109.7895, 32.4272],
            [-109.8045, 32.5354],
            [-109.8048, 32.884],
            [-109.783, 32.9444],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408410',
        NAME: 'Thatcher Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340761010,
        AWATER: 2650435,
        INTPTLAT: '+32.6871094',
        INTPTLON: '-109.7861243',
        ELSDLEA: '',
        UNSDLEA: '08410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.4517, 31.7307],
            [-110.2629, 31.7308],
            [-110.1465, 31.8184],
            [-110.0436, 31.8179],
            [-110.0438, 31.9346],
            [-109.9626, 31.9347],
            [-109.8645, 31.7582],
            [-109.7615, 31.7587],
            [-109.7728, 31.6861],
            [-109.8525, 31.6412],
            [-109.8519, 31.5532],
            [-110.0534, 31.5531],
            [-110.1041, 31.5555],
            [-110.1036, 31.6431],
            [-110.2057, 31.6432],
            [-110.4478, 31.5851],
            [-110.4607, 31.5851],
            [-110.4517, 31.7307],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408600',
        NAME: 'Tombstone Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1316071787,
        AWATER: 398896,
        INTPTLAT: '+31.7071046',
        INTPTLON: '-110.0610126',
        ELSDLEA: '',
        UNSDLEA: '08600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8529, 36.3848],
            [-111.357, 36.3845],
            [-111.359, 36.5591],
            [-110.7507, 36.5586],
            [-110.7507, 36.2984],
            [-110.7507, 35.9775],
            [-110.7507, 35.5237],
            [-111.0006, 35.5244],
            [-111.0006, 35.4955],
            [-111.2443, 35.4954],
            [-111.33, 35.6524],
            [-111.3205, 35.7571],
            [-111.5196, 35.7575],
            [-111.5544, 35.6989],
            [-111.7465, 35.6721],
            [-111.6932, 35.7461],
            [-111.7262, 35.847],
            [-111.7173, 35.9775],
            [-111.7604, 36.0073],
            [-111.7628, 36.1985],
            [-111.7996, 36.1938],
            [-111.8591, 36.2967],
            [-111.8529, 36.3848],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408680',
        NAME: 'Tuba City Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8995504606,
        AWATER: 4951007,
        INTPTLAT: '+36.0617975',
        INTPTLON: '-111.2078559',
        ELSDLEA: '',
        UNSDLEA: '08680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1664, 32.1903],
            [-111.0966, 32.2202],
            [-111.0629, 32.3087],
            [-110.9865, 32.2503],
            [-110.9438, 32.2857],
            [-110.8241, 32.3378],
            [-110.7554, 32.3377],
            [-110.7557, 32.1628],
            [-110.8579, 32.163],
            [-110.9927, 32.1632],
            [-111.0194, 32.1049],
            [-111.1661, 32.1042],
            [-111.1664, 32.1903],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408800',
        NAME: 'Tucson Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592099144,
        AWATER: 755055,
        INTPTLAT: '+32.2114132',
        INTPTLON: '-110.9627656',
        ELSDLEA: '',
        UNSDLEA: '08800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.859, 32.0304],
            [-110.8579, 32.163],
            [-110.7557, 32.1628],
            [-110.6524, 32.1621],
            [-110.5526, 32.1618],
            [-110.5539, 32.0559],
            [-110.4514, 32.0497],
            [-110.451, 31.9477],
            [-110.6042, 31.9481],
            [-110.6553, 31.9041],
            [-110.6551, 31.8179],
            [-110.758, 31.818],
            [-110.7573, 31.8902],
            [-110.858, 31.9046],
            [-110.859, 32.0304],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '408850',
        NAME: 'Vail Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1009158779,
        AWATER: 0,
        INTPTLAT: '+32.0155294',
        INTPTLON: '-110.6873435',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 34.2633],
            [-110.7507, 34.2647],
            [-110.4249, 34.3059],
            [-110.3355, 34.2334],
            [-110.0407, 34.2005],
            [-110.0115, 34.1467],
            [-109.8594, 34.1088],
            [-109.8591, 33.9804],
            [-109.8593, 33.7777],
            [-109.8918, 33.7778],
            [-109.8918, 33.5678],
            [-109.9048, 33.5751],
            [-110.0006, 33.5769],
            [-110.0007, 33.727],
            [-110.0007, 34],
            [-110.7507, 34],
            [-110.7507, 34.2633],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409160',
        NAME: 'Whiteriver Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2647812105,
        AWATER: 1881973,
        INTPTLAT: '+34.1287332',
        INTPTLON: '-110.2515875',
        ELSDLEA: '',
        UNSDLEA: '09160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.142, 33.9997],
            [-112.8242, 33.9999],
            [-112.8253, 34.0711],
            [-112.6176, 34.0712],
            [-112.6173, 34.1128],
            [-112.5168, 34.113],
            [-112.4978, 33.9991],
            [-112.393, 33.9701],
            [-112.2028, 33.9859],
            [-112.2029, 33.9705],
            [-112.273, 33.8943],
            [-112.3764, 33.9079],
            [-112.4279, 33.9208],
            [-112.685, 33.9852],
            [-112.7075, 33.7822],
            [-112.6203, 33.7822],
            [-112.608, 33.6969],
            [-112.513, 33.6969],
            [-112.5133, 33.6388],
            [-113.1274, 33.6386],
            [-113.142, 33.7246],
            [-113.142, 33.9997],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409190',
        NAME: 'Wickenburg Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2355689208,
        AWATER: 3392961,
        INTPTLAT: '+33.8546677',
        INTPTLON: '-112.8070321',
        ELSDLEA: '',
        UNSDLEA: '09190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2512, 32.4267],
            [-109.8402, 32.4273],
            [-109.7895, 32.4272],
            [-109.742, 32.4274],
            [-109.7225, 32.4271],
            [-109.7129, 32.2531],
            [-109.6359, 32.253],
            [-109.5283, 32.2553],
            [-109.5333, 32.1658],
            [-109.4828, 32.0795],
            [-109.3366, 32.0795],
            [-109.3722, 31.9057],
            [-109.4405, 31.9489],
            [-109.5931, 31.9488],
            [-109.6953, 31.9926],
            [-109.7447, 31.9055],
            [-109.8478, 32.0505],
            [-109.8407, 32.1453],
            [-110.0455, 32.166],
            [-110.0458, 32.2679],
            [-110.2504, 32.2674],
            [-110.2512, 32.4267],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409250',
        NAME: 'Willcox Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2304715783,
        AWATER: 84615106,
        INTPTLAT: '+32.2003761',
        INTPTLON: '-109.8016872',
        ELSDLEA: '',
        UNSDLEA: '09250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3013, 35.5311],
            [-112.8769, 35.8203],
            [-112.8759, 36.1507],
            [-112.7615, 36.1085],
            [-112.601, 36.1078],
            [-112.5299, 36.0207],
            [-112.329, 36.0204],
            [-112.3286, 35.7628],
            [-112.1149, 35.7621],
            [-112.1137, 35.9338],
            [-111.9043, 35.934],
            [-111.9066, 35.4569],
            [-112.0134, 35.4564],
            [-112.013, 35.3233],
            [-112.0538, 35.2532],
            [-112.1238, 35.2357],
            [-112.1245, 35.1468],
            [-112.019, 35.1046],
            [-111.9118, 35.1049],
            [-111.9121, 34.9763],
            [-112.3345, 34.973],
            [-112.3346, 34.9882],
            [-112.3357, 35.1483],
            [-112.4423, 35.1494],
            [-112.4413, 35.3256],
            [-112.5491, 35.3254],
            [-112.5483, 35.2376],
            [-112.6948, 35.3116],
            [-112.7633, 35.3134],
            [-112.9448, 35.3526],
            [-112.9759, 35.3929],
            [-113.1745, 35.5064],
            [-113.3013, 35.5311],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409310',
        NAME: 'Williams Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8593339399,
        AWATER: 1368059,
        INTPTLAT: '+35.5791605',
        INTPTLON: '-112.4714881',
        ELSDLEA: '',
        UNSDLEA: '09310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3152, 35.4709],
            [-109.2945, 35.515],
            [-109.2807, 35.8366],
            [-109.1747, 35.8369],
            [-109.1708, 35.9238],
            [-109.0461, 35.923],
            [-109.0467, 35.4287],
            [-109.315, 35.427],
            [-109.3152, 35.4709],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409430',
        NAME: 'Window Rock Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1131980516,
        AWATER: 706593,
        INTPTLAT: '+35.6533530',
        INTPTLON: '-109.1651175',
        ELSDLEA: '',
        UNSDLEA: '09430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7507, 35.5198],
            [-110.4389, 35.5189],
            [-110.4387, 35.17],
            [-110.4501, 34.9598],
            [-110.4865, 34.8208],
            [-110.4865, 34.6403],
            [-110.7507, 34.6366],
            [-110.7507, 34.7208],
            [-110.7507, 35.5198],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409460',
        NAME: 'Winslow Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2597149463,
        AWATER: 1737727,
        INTPTLAT: '+35.1017079',
        INTPTLON: '-110.6138141',
        ELSDLEA: '',
        UNSDLEA: '09460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7777, 34.9339],
            [-111.7783, 34.8036],
            [-111.6765, 34.8032],
            [-111.7117, 34.7323],
            [-111.8531, 34.7331],
            [-111.8833, 34.8036],
            [-111.8833, 34.9342],
            [-111.7777, 34.9339],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '409733',
        NAME: 'Sedona-Oak Creek Joint Unified District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255820077,
        AWATER: 0,
        INTPTLAT: '+34.8236887',
        INTPTLON: '-111.8032196',
        ELSDLEA: '',
        UNSDLEA: '09733',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-109.3335, 31.642],
              [-109.3353, 31.553],
              [-109.3888, 31.5531],
              [-109.3335, 31.642],
            ],
          ],
          [
            [
              [-109.3366, 32.0795],
              [-109.3278, 32.0795],
              [-109.3202, 31.9711],
              [-109.2203, 31.9705],
              [-109.221, 31.8793],
              [-109.1579, 31.8155],
              [-109.2237, 31.7292],
              [-109.3253, 31.729],
              [-109.3368, 31.8176],
              [-109.3722, 31.9057],
              [-109.3366, 32.0795],
            ],
          ],
          [
            [
              [-109.5438, 31.6425],
              [-109.5298, 31.6853],
              [-109.5034, 31.553],
              [-109.5446, 31.5535],
              [-109.5438, 31.6425],
            ],
          ],
          [
            [
              [-110.4389, 35.5189],
              [-110.3683, 35.5189],
              [-110.3681, 35.3453],
              [-110.3679, 35.1706],
              [-110.4387, 35.17],
              [-110.4389, 35.5189],
            ],
          ],
          [
            [
              [-110.4607, 31.5851],
              [-110.4478, 31.5851],
              [-110.3594, 31.435],
              [-110.2743, 31.3798],
              [-110.2742, 31.3333],
              [-110.4602, 31.3328],
              [-110.4599, 31.4281],
              [-110.4607, 31.5851],
            ],
          ],
          [
            [
              [-110.7831, 34.2999],
              [-110.7507, 34.2633],
              [-110.7507, 34],
              [-110.0007, 34],
              [-110.0007, 33.727],
              [-110.5286, 33.7265],
              [-110.5259, 33.8144],
              [-110.5848, 33.836],
              [-110.6887, 33.7693],
              [-110.7444, 33.6978],
              [-110.7832, 33.8979],
              [-110.7831, 34.2999],
            ],
          ],
          [
            [
              [-110.8587, 32.5112],
              [-110.6957, 32.5136],
              [-110.6388, 32.471],
              [-110.5525, 32.312],
              [-110.451, 32.269],
              [-110.3482, 32.2683],
              [-110.3438, 32.1373],
              [-110.4481, 32.1376],
              [-110.4514, 32.0497],
              [-110.5539, 32.0559],
              [-110.5526, 32.1618],
              [-110.6524, 32.1621],
              [-110.6523, 32.3383],
              [-110.7554, 32.3377],
              [-110.8241, 32.3378],
              [-110.9098, 32.3373],
              [-110.8587, 32.5112],
            ],
          ],
          [
            [
              [-111.416, 31.5198],
              [-111.3666, 31.5213],
              [-111.3666, 31.4261],
              [-111.4511, 31.4531],
              [-111.416, 31.5198],
            ],
          ],
          [
            [
              [-111.7946, 32.5068],
              [-111.7925, 32.5068],
              [-111.5908, 32.5068],
              [-111.5908, 32.4218],
              [-111.6815, 32.3742],
              [-111.8066, 32.3746],
              [-111.7946, 32.5068],
            ],
          ],
          [
            [
              [-111.9962, 34.0298],
              [-111.7253, 33.9998],
              [-111.4948, 33.9998],
              [-111.4349, 33.987],
              [-111.4509, 33.9199],
              [-111.3723, 33.8239],
              [-111.3868, 33.75],
              [-111.3152, 33.6815],
              [-111.2617, 33.6226],
              [-111.1534, 33.6776],
              [-111.0399, 33.4661],
              [-111.2698, 33.4655],
              [-111.5806, 33.4659],
              [-111.5808, 33.5673],
              [-111.6824, 33.5673],
              [-111.7355, 33.4954],
              [-111.8917, 33.5021],
              [-111.8914, 33.5672],
              [-111.7701, 33.5679],
              [-111.6997, 33.6405],
              [-111.6861, 33.7417],
              [-111.7697, 33.7703],
              [-111.786, 33.9005],
              [-111.9957, 33.9009],
              [-111.9962, 34.0298],
            ],
          ],
          [
            [
              [-112.3061, 33.2904],
              [-112.2292, 33.2904],
              [-112.2291, 33.3832],
              [-112.1005, 33.3196],
              [-112.099, 33.231],
              [-112.202, 33.2033],
              [-112.3063, 33.2033],
              [-112.3061, 33.2904],
            ],
          ],
          [
            [
              [-112.3772, 33.7675],
              [-112.3602, 33.7248],
              [-112.4123, 33.7247],
              [-112.3772, 33.7675],
            ],
          ],
          [
            [
              [-112.5112, 32.9426],
              [-112.2037, 32.9417],
              [-112.2037, 32.5068],
              [-112.5207, 32.5055],
              [-112.5112, 32.9426],
            ],
          ],
          [
            [
              [-112.7254, 32.5055],
              [-112.5748, 32.5052],
              [-112.5752, 32.4187],
              [-112.6765, 32.418],
              [-112.7278, 32.3313],
              [-112.7254, 32.5055],
            ],
          ],
          [
            [
              [-113.0266, 33.2044],
              [-112.8284, 33.2035],
              [-112.8283, 33.1165],
              [-113.0259, 33.1162],
              [-113.0266, 33.2044],
            ],
          ],
          [
            [
              [-113.3337, 32.5936],
              [-113.3337, 32.7679],
              [-113.3338, 32.8251],
              [-113.0231, 32.825],
              [-113.0281, 32.5052],
              [-112.9158, 32.5054],
              [-112.9164, 32.3314],
              [-112.7278, 32.3313],
              [-112.7278, 32.1573],
              [-112.6078, 31.8159],
              [-113.3338, 32.0386],
              [-113.3337, 32.5936],
            ],
          ],
          [
            [
              [-113.1249, 36.1711],
              [-112.9823, 36.2317],
              [-112.6816, 36.3417],
              [-112.6399, 36.3888],
              [-112.5413, 36.4001],
              [-112.4569, 36.3348],
              [-112.5293, 36.2669],
              [-112.3287, 36.2081],
              [-112.329, 36.0204],
              [-112.5299, 36.0207],
              [-112.601, 36.1078],
              [-112.7615, 36.1085],
              [-112.8759, 36.1507],
              [-112.8769, 35.8203],
              [-113.3013, 35.5311],
              [-113.3342, 35.528],
              [-113.334, 35.803],
              [-113.3171, 35.9614],
              [-113.3542, 36.0404],
              [-113.3134, 36.1],
              [-113.2249, 36.0911],
              [-113.1249, 36.1711],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '04',
        SCSDLEA: '',
        GEOID: '499997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 17359298254,
        AWATER: 45747962,
        INTPTLAT: '+32.5911701',
        INTPTLON: '-112.2741373',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

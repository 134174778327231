export const ND = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6857, 46.9828],
            [-100.5807, 46.9824],
            [-100.5823, 46.8957],
            [-100.7038, 46.9104],
            [-100.6857, 46.9828],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800015',
        NAME: 'Naughton Public School District 25',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81387939,
        AWATER: 0,
        INTPTLAT: '+46.9396626',
        INTPTLON: '-100.6380751',
        ELSDLEA: '00015',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9089, 46.5872],
            [-97.8052, 46.5437],
            [-97.9088, 46.4563],
            [-98.0341, 46.4566],
            [-98.043, 46.5291],
            [-97.9089, 46.5872],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800033',
        NAME: 'Fort Ransom Public School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172044837,
        AWATER: 83218,
        INTPTLAT: '+46.5100409',
        INTPTLON: '-097.9229470',
        ELSDLEA: '00033',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8009, 46.5407],
            [-103.7345, 46.6297],
            [-103.6092, 46.6298],
            [-103.3005, 46.6298],
            [-103.3005, 46.5404],
            [-103.1751, 46.5407],
            [-103.1753, 46.4535],
            [-103.1757, 46.3667],
            [-103.5508, 46.3669],
            [-103.5511, 46.4537],
            [-103.8014, 46.4539],
            [-103.8009, 46.5407],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800037',
        NAME: 'Central Elementary Public School District 32',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1115983415,
        AWATER: 4743535,
        INTPTLAT: '+46.5039310',
        INTPTLON: '-103.4857883',
        ELSDLEA: '00037',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1553, 48.5143],
            [-98.1875, 48.5433],
            [-98.0572, 48.5433],
            [-97.9709, 48.4489],
            [-97.9683, 48.3398],
            [-98.0979, 48.2671],
            [-98.1626, 48.245],
            [-98.1626, 48.2601],
            [-98.2821, 48.3402],
            [-98.1553, 48.5143],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3801680',
        NAME: 'Adams Public School District 128',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442287894,
        AWATER: 9328034,
        INTPTLAT: '+48.4056908',
        INTPTLON: '-098.1115892',
        ELSDLEA: '01680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5947, 46.8088],
            [-100.5848, 46.7218],
            [-100.6686, 46.722],
            [-100.5947, 46.8088],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3802014',
        NAME: 'Apple Creek Public School District 39',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62231992,
        AWATER: 51578,
        INTPTLAT: '+46.7714561',
        INTPTLON: '-100.6312874',
        ELSDLEA: '02014',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5035, 45.9437],
            [-100.5034, 46.0448],
            [-100.2954, 46.0443],
            [-100.2125, 46.0725],
            [-100.0668, 46.0292],
            [-100.0676, 45.9421],
            [-100.0879, 45.9422],
            [-100.4994, 45.9437],
            [-100.5035, 45.9437],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3802240',
        NAME: 'Bakker Public School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407155154,
        AWATER: 7633059,
        INTPTLAT: '+45.9977644',
        INTPTLON: '-100.2740696',
        ELSDLEA: '02240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.6667, 47.3294],
            [-103.0992, 47.3287],
            [-103.0326, 47.3287],
            [-103.033, 47.1687],
            [-103.0327, 46.9806],
            [-103.1055, 46.9805],
            [-103.231, 46.9803],
            [-103.2316, 46.6298],
            [-103.3005, 46.6298],
            [-103.6092, 46.6298],
            [-103.6095, 46.9799],
            [-103.667, 46.9798],
            [-103.6667, 47.3294],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3802840',
        NAME: 'Billings County Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2975505802,
        AWATER: 11971658,
        INTPTLAT: '+47.0070440',
        INTPTLON: '-103.3640163',
        ELSDLEA: '02840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0453, 47.3971],
            [-104.0439, 47.5904],
            [-103.7358, 47.5894],
            [-103.7377, 47.3294],
            [-104.0454, 47.3301],
            [-104.0453, 47.3971],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805670',
        NAME: 'Earl Public School District 18',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669362278,
        AWATER: 712266,
        INTPTLAT: '+47.4418606',
        INTPTLON: '-103.8790342',
        ELSDLEA: '05670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4304, 47.8294],
            [-97.4294, 47.9332],
            [-97.3642, 47.973],
            [-97.2389, 47.9764],
            [-97.239, 47.9551],
            [-97.2388, 47.9112],
            [-97.239, 47.8173],
            [-97.3249, 47.8028],
            [-97.4304, 47.8294],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806360',
        NAME: 'Emerado Public School District 127',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249108146,
        AWATER: 324234,
        INTPTLAT: '+47.8819355',
        INTPTLON: '-097.3432178',
        ELSDLEA: '06360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0432, 47.7611],
            [-104.0425, 47.8068],
            [-103.9257, 47.7615],
            [-103.797, 47.7615],
            [-103.6691, 47.7615],
            [-103.6686, 47.6747],
            [-103.7364, 47.6474],
            [-103.7358, 47.5894],
            [-104.0439, 47.5904],
            [-104.0432, 47.7611],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809810',
        NAME: 'Horse Creek Public School District 32',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547535277,
        AWATER: 4227607,
        INTPTLAT: '+47.6929142',
        INTPTLON: '-103.8712604',
        ELSDLEA: '09810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9861, 46.6321],
            [-100.9533, 46.6906],
            [-100.8707, 46.6237],
            [-100.8611, 46.5305],
            [-100.8714, 46.5305],
            [-100.903, 46.5161],
            [-100.9862, 46.5162],
            [-100.9861, 46.6321],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811540',
        NAME: 'Little Heart Public School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222933486,
        AWATER: 0,
        INTPTLAT: '+46.6112738',
        INTPTLON: '-100.9292959',
        ELSDLEA: '11540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0461, 46.8035],
            [-103.7976, 46.8035],
            [-103.8189, 46.7599],
            [-103.7347, 46.7596],
            [-103.7345, 46.6297],
            [-103.8009, 46.5407],
            [-104.0452, 46.5409],
            [-104.0454, 46.6415],
            [-104.0461, 46.8035],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811610',
        NAME: 'Lonetree Public School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619872902,
        AWATER: 1060967,
        INTPTLAT: '+46.6758282',
        INTPTLON: '-103.9091913',
        ELSDLEA: '11610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6686, 46.722],
            [-100.5848, 46.7218],
            [-100.5851, 46.6349],
            [-100.6619, 46.6345],
            [-100.7121, 46.6575],
            [-100.6686, 46.722],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811970',
        NAME: 'Manning Public School District 45',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61211951,
        AWATER: 14829856,
        INTPTLAT: '+46.6877668',
        INTPTLON: '-100.6398130',
        ELSDLEA: '11970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2976, 48.2088],
            [-97.1498, 48.2181],
            [-97.0841, 48.0564],
            [-97.0534, 47.9913],
            [-97.1747, 47.9914],
            [-97.1963, 47.9691],
            [-97.239, 47.9551],
            [-97.2389, 47.9764],
            [-97.3199, 48.078],
            [-97.2976, 48.2088],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812000',
        NAME: 'Manvel Public School District 125',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360201912,
        AWATER: 1736189,
        INTPTLAT: '+48.0970184',
        INTPTLON: '-097.2068089',
        ELSDLEA: '12000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9883, 46.9496],
            [-96.9686, 46.8335],
            [-97.0111, 46.7901],
            [-97.0635, 46.7895],
            [-97.1173, 46.8615],
            [-97.115, 46.9733],
            [-96.9883, 46.9496],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812030',
        NAME: 'Mapleton Public School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184850714,
        AWATER: 369741,
        INTPTLAT: '+46.8857491',
        INTPTLON: '-097.0480000',
        ELSDLEA: '12030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0452, 46.5409],
            [-103.8009, 46.5407],
            [-103.8014, 46.4539],
            [-103.7845, 46.2057],
            [-103.8675, 46.2058],
            [-103.8677, 46.1188],
            [-104.0458, 46.1191],
            [-104.0452, 46.5409],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812120',
        NAME: 'Marmarth Public School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 839045278,
        AWATER: 3651606,
        INTPTLAT: '+46.3391816',
        INTPTLON: '-103.9304914',
        ELSDLEA: '12120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5823, 46.8957],
            [-100.5807, 46.9824],
            [-100.5378, 46.9825],
            [-100.4549, 46.953],
            [-100.3939, 46.8371],
            [-100.4574, 46.7943],
            [-100.4595, 46.6343],
            [-100.5851, 46.6349],
            [-100.5848, 46.7218],
            [-100.5947, 46.8088],
            [-100.5823, 46.8957],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812720',
        NAME: 'Menoken Public School District 33',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391469320,
        AWATER: 319790,
        INTPTLAT: '+46.8017014',
        INTPTLON: '-100.4839116',
        ELSDLEA: '12720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1658, 48.2836],
            [-101.1446, 48.1967],
            [-101.2669, 48.1964],
            [-101.2527, 48.2366],
            [-101.2742, 48.2834],
            [-101.1658, 48.2836],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813660',
        NAME: 'Nedrose Public School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80073911,
        AWATER: 1756963,
        INTPTLAT: '+48.2436365',
        INTPTLON: '-101.1981988',
        ELSDLEA: '13660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0468, 48.3526],
            [-103.82, 48.3725],
            [-103.7981, 48.4599],
            [-103.6677, 48.4607],
            [-103.6677, 48.5464],
            [-103.4288, 48.5464],
            [-103.2766, 48.5464],
            [-103.277, 48.4595],
            [-103.407, 48.4596],
            [-103.4694, 48.3727],
            [-103.4698, 48.198],
            [-103.0872, 48.1687],
            [-103.0227, 48.2116],
            [-102.9145, 48.2118],
            [-102.915, 48.2986],
            [-102.8282, 48.2987],
            [-102.8285, 48.1237],
            [-103.1071, 48.1465],
            [-103.2019, 48.1352],
            [-103.2773, 48.058],
            [-103.499, 48.0056],
            [-103.6082, 48.1141],
            [-103.7139, 48.1106],
            [-103.9052, 48.1112],
            [-104.0443, 48.0248],
            [-104.0468, 48.3526],
          ],
          [
            [-103.7138, 48.1798],
            [-103.598, 48.1401],
            [-103.6041, 48.1932],
            [-103.7138, 48.1798],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813760',
        NAME: 'New Public School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2259470447,
        AWATER: 150427480,
        INTPTLAT: '+48.2553695',
        INTPTLON: '-103.5672665',
        ELSDLEA: '13760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3622, 47.977],
            [-99.1248, 47.9939],
            [-99.1253, 47.949],
            [-98.9097, 47.9641],
            [-98.9096, 47.9201],
            [-99.169, 47.9199],
            [-99.1686, 47.8472],
            [-99.3617, 47.8467],
            [-99.3188, 47.9336],
            [-99.3622, 47.977],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814520',
        NAME: 'Oberon Public School District 16',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336743636,
        AWATER: 2066594,
        INTPTLAT: '+47.9273830',
        INTPTLON: '-099.1877112',
        ELSDLEA: '14520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0329, 47.5318],
            [-99.8317, 47.5882],
            [-99.8839, 47.4798],
            [-99.8412, 47.4142],
            [-99.8633, 47.327],
            [-100.0333, 47.3272],
            [-100.0329, 47.5318],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3815360',
        NAME: 'Pleasant Valley Public School District 35',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339443510,
        AWATER: 11667201,
        INTPTLAT: '+47.4467424',
        INTPTLON: '-099.9346230',
        ELSDLEA: '15360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8632, 47.3142],
            [-99.6505, 47.3271],
            [-99.6081, 47.2991],
            [-99.608, 47.0674],
            [-99.7768, 46.981],
            [-99.8616, 46.9811],
            [-99.8632, 47.3142],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3815960',
        NAME: 'Robinson Public School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 586742542,
        AWATER: 57660360,
        INTPTLAT: '+47.1754929',
        INTPTLON: '-099.7494642',
        ELSDLEA: '15960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.534, 48.0662],
            [-101.4039, 48.1099],
            [-101.4038, 48.1968],
            [-101.2741, 48.1965],
            [-101.2743, 48.1243],
            [-101.2312, 48.0303],
            [-101.2285, 47.9354],
            [-101.4334, 47.9641],
            [-101.5188, 47.9349],
            [-101.534, 48.0662],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817170',
        NAME: 'South Prairie Public School District 70',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459080122,
        AWATER: 15111485,
        INTPTLAT: '+48.0446094',
        INTPTLON: '-101.3933868',
        ELSDLEA: '17170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4549, 46.953],
            [-100.1599, 46.9667],
            [-100.1403, 46.8673],
            [-100.0777, 46.8216],
            [-100.1642, 46.7204],
            [-100.3323, 46.7209],
            [-100.4574, 46.7943],
            [-100.3939, 46.8371],
            [-100.4549, 46.953],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817760',
        NAME: 'Sterling Public School District 35',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 564353925,
        AWATER: 2030548,
        INTPTLAT: '+46.8443496',
        INTPTLON: '-100.2693436',
        ELSDLEA: '17760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2185, 46.848],
            [-101.09, 46.8482],
            [-101.0822, 46.7707],
            [-101.1961, 46.7755],
            [-101.2169, 46.7172],
            [-101.2185, 46.848],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817940',
        NAME: 'Sweet Briar Public School District 17',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97226740,
        AWATER: 44809,
        INTPTLAT: '+46.8032697',
        INTPTLON: '-101.1574824',
        ELSDLEA: '17940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4605, 47.5125],
            [-102.4365, 47.5698],
            [-102.2635, 47.6196],
            [-102.1613, 47.5654],
            [-102.0366, 47.5727],
            [-101.8358, 47.4955],
            [-101.9075, 47.4579],
            [-102.2057, 47.4798],
            [-102.4385, 47.4611],
            [-102.4605, 47.5013],
            [-102.4605, 47.5125],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3818600',
        NAME: 'Twin Buttes Public School District 37',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368266327,
        AWATER: 112004755,
        INTPTLAT: '+47.5237022',
        INTPTLON: '-102.1951043',
        ELSDLEA: '18600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0441, 47.9961],
            [-103.9198, 47.9559],
            [-103.7974, 47.9992],
            [-103.797, 47.7615],
            [-103.9257, 47.7615],
            [-104.0425, 47.8068],
            [-104.0425, 47.807],
            [-104.0441, 47.9961],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3820340',
        NAME: 'Yellowstone Public School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360904121,
        AWATER: 20520068,
        INTPTLAT: '+47.8922274',
        INTPTLON: '-103.8980047',
        ELSDLEA: '20340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.936, 46.9828],
            [-100.8807, 47.041],
            [-100.6857, 46.9828],
            [-100.7038, 46.9104],
            [-100.5823, 46.8957],
            [-100.5947, 46.8088],
            [-100.6686, 46.722],
            [-100.7121, 46.6575],
            [-100.7888, 46.6917],
            [-100.8191, 46.7924],
            [-100.936, 46.9828],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800014',
        NAME: 'Bismarck Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557470079,
        AWATER: 12857881,
        INTPTLAT: '+46.8680138',
        INTPTLON: '-100.7635270',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.629, 46.146],
            [-100.3775, 46.1462],
            [-100.3565, 46.1821],
            [-100.3458, 46.1821],
            [-100.212, 46.1964],
            [-99.9828, 46.2105],
            [-99.9882, 46.2173],
            [-99.9205, 46.1882],
            [-99.8785, 46.2394],
            [-99.8789, 46.1662],
            [-99.8794, 46.0576],
            [-100.0046, 46.0144],
            [-99.984, 45.9419],
            [-100.0676, 45.9421],
            [-100.0668, 46.0292],
            [-100.2125, 46.0725],
            [-100.2954, 46.0443],
            [-100.5034, 46.0448],
            [-100.5035, 45.9437],
            [-100.5119, 45.9437],
            [-100.6145, 46.0718],
            [-100.629, 46.146],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800016',
        NAME: 'Strasburg Public School District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 907243931,
        AWATER: 40292107,
        INTPTLAT: '+46.1081245',
        INTPTLON: '-100.2101294',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5601, 46.2338],
            [-100.5492, 46.3293],
            [-100.5902, 46.4279],
            [-100.5985, 46.4444],
            [-100.5045, 46.459],
            [-100.463, 46.3713],
            [-100.0433, 46.3708],
            [-100.0432, 46.4144],
            [-99.9169, 46.3706],
            [-99.8784, 46.2828],
            [-99.8785, 46.2394],
            [-99.9205, 46.1882],
            [-99.9882, 46.2173],
            [-99.9828, 46.2105],
            [-100.212, 46.1964],
            [-100.3458, 46.1821],
            [-100.3565, 46.1821],
            [-100.3775, 46.1462],
            [-100.629, 46.146],
            [-100.5601, 46.2338],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800017',
        NAME: 'Linton Public School District 36',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1112058514,
        AWATER: 43826011,
        INTPTLAT: '+46.2799249',
        INTPTLON: '-100.2708068',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7746, 48.8629],
            [-97.7529, 48.8916],
            [-97.4573, 48.8476],
            [-97.3589, 48.8473],
            [-97.294, 48.7681],
            [-97.32, 48.6951],
            [-97.5232, 48.7178],
            [-97.5679, 48.6592],
            [-97.7536, 48.6662],
            [-97.884, 48.7177],
            [-97.8835, 48.8041],
            [-97.7746, 48.8629],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800018',
        NAME: 'Cavalier Public School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 787739723,
        AWATER: 1316710,
        INTPTLAT: '+48.7684466',
        INTPTLON: '-097.6117891',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.1319, 46.8482],
              [-99.1208, 46.8481],
              [-99.0683, 46.8485],
              [-99.0379, 46.9794],
              [-98.8372, 46.9867],
              [-98.7423, 46.9864],
              [-98.7316, 46.9864],
              [-98.7319, 46.994],
              [-98.6791, 47.0809],
              [-98.6155, 47.0805],
              [-98.5448, 46.8488],
              [-98.5236, 46.8195],
              [-98.481, 46.8121],
              [-98.5449, 46.7613],
              [-98.6919, 46.7181],
              [-98.903, 46.7179],
              [-99.0691, 46.7901],
              [-99.1319, 46.841],
              [-99.1319, 46.8482],
            ],
          ],
          [
            [
              [-99.1318, 46.8551],
              [-99.1319, 46.8482],
              [-99.143, 46.8481],
              [-99.1318, 46.8551],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800021',
        NAME: 'Jamestown Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1197166481,
        AWATER: 19066771,
        INTPTLAT: '+46.8742417',
        INTPTLON: '-098.8085818',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.1757, 46.3667],
            [-103.1753, 46.4535],
            [-103.052, 46.4538],
            [-103.0507, 46.3668],
            [-102.9125, 46.2816],
            [-102.9543, 46.1624],
            [-102.8297, 46.1044],
            [-102.9333, 46.0467],
            [-102.9538, 45.945],
            [-103.2451, 45.9452],
            [-103.2448, 46.28],
            [-103.1757, 46.3667],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800023',
        NAME: 'Scranton Public School District 33',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1235748899,
        AWATER: 2089305,
        INTPTLAT: '+46.1625185',
        INTPTLON: '-103.0890460',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8857, 48.7205],
            [-102.8086, 48.7495],
            [-102.5466, 48.7204],
            [-102.3212, 48.7203],
            [-102.3432, 48.5464],
            [-102.5704, 48.5031],
            [-102.6255, 48.3725],
            [-102.6908, 48.3725],
            [-102.7407, 48.4184],
            [-102.7553, 48.5463],
            [-102.8858, 48.5898],
            [-102.8857, 48.7205],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800024',
        NAME: 'Powers Lake Public School District 27',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 998835291,
        AWATER: 38259601,
        INTPTLAT: '+48.5971285',
        INTPTLON: '-102.6143368',
        ELSDLEA: '',
        UNSDLEA: '00024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3782, 46.6874],
            [-97.2834, 46.6873],
            [-97.1163, 46.7892],
            [-97.0635, 46.7895],
            [-97.0111, 46.7901],
            [-96.9683, 46.7319],
            [-96.8724, 46.746],
            [-96.7909, 46.703],
            [-96.7981, 46.6303],
            [-96.853, 46.5289],
            [-97.0301, 46.4923],
            [-97.1973, 46.4997],
            [-97.2802, 46.4565],
            [-97.2801, 46.5716],
            [-97.4091, 46.6294],
            [-97.3782, 46.6874],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800025',
        NAME: 'Kindred Public School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1013150742,
        AWATER: 225292,
        INTPTLAT: '+46.6370742',
        INTPTLON: '-097.0836358',
        ELSDLEA: '',
        UNSDLEA: '00025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.3005, 46.6298],
            [-103.2316, 46.6298],
            [-103.1054, 46.6298],
            [-103.1054, 46.6878],
            [-102.9368, 46.6802],
            [-102.8517, 46.7164],
            [-102.6011, 46.7168],
            [-102.4964, 46.688],
            [-102.4536, 46.6881],
            [-102.391, 46.6302],
            [-102.6561, 46.6299],
            [-102.6736, 46.2828],
            [-102.9125, 46.2816],
            [-103.0507, 46.3668],
            [-103.052, 46.4538],
            [-103.1753, 46.4535],
            [-103.1751, 46.5407],
            [-103.3005, 46.5404],
            [-103.3005, 46.6298],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800027',
        NAME: 'New England Public School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1726614209,
        AWATER: 2024017,
        INTPTLAT: '+46.5228498',
        INTPTLON: '-102.8911946',
        ELSDLEA: '',
        UNSDLEA: '00027',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6928, 47.9422],
            [-100.5613, 48.0803],
            [-100.4858, 48.1091],
            [-100.41, 48.1525],
            [-100.2371, 48.1962],
            [-100.2369, 48.0362],
            [-100.3274, 47.9343],
            [-100.349, 47.7317],
            [-100.4563, 47.674],
            [-100.585, 47.7037],
            [-100.585, 47.9201],
            [-100.6928, 47.9422],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800029',
        NAME: 'Drake Public School District 57',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1083739521,
        AWATER: 51970933,
        INTPTLAT: '+47.9563215',
        INTPTLON: '-100.4456436',
        ELSDLEA: '',
        UNSDLEA: '00029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6943, 47.5386],
            [-101.5261, 47.5479],
            [-101.4213, 47.5021],
            [-101.4063, 47.416],
            [-101.5045, 47.4151],
            [-101.4482, 47.2994],
            [-101.4479, 47.2125],
            [-101.5743, 47.1042],
            [-101.6376, 47.0827],
            [-101.7014, 47.2847],
            [-101.7481, 47.3355],
            [-101.6943, 47.5386],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800031',
        NAME: 'Hazen Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 787703945,
        AWATER: 27209559,
        INTPTLAT: '+47.3310688',
        INTPTLON: '-101.5874171',
        ELSDLEA: '',
        UNSDLEA: '00031',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2057, 47.4798],
            [-101.9075, 47.4579],
            [-101.8358, 47.4955],
            [-101.7135, 47.5369],
            [-101.6943, 47.5386],
            [-101.7481, 47.3355],
            [-101.7014, 47.2847],
            [-101.6376, 47.0827],
            [-101.68, 47.0246],
            [-101.8702, 47.0247],
            [-101.9123, 47.0938],
            [-102.0178, 47.1548],
            [-102.1445, 47.1553],
            [-102.2718, 47.1694],
            [-102.2716, 47.3281],
            [-102.2057, 47.3284],
            [-102.2057, 47.4798],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800032',
        NAME: 'Beulah Public School District 27',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1757130509,
        AWATER: 37869222,
        INTPTLAT: '+47.2675290',
        INTPTLON: '-101.9433180',
        ELSDLEA: '',
        UNSDLEA: '00032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0301, 46.4923],
            [-96.853, 46.5289],
            [-96.7981, 46.6303],
            [-96.7844, 46.6305],
            [-96.6566, 46.3695],
            [-96.9043, 46.3403],
            [-97.0298, 46.3691],
            [-97.0301, 46.4923],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800036',
        NAME: 'Richland Public School District 44',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527996307,
        AWATER: 0,
        INTPTLAT: '+46.4611557',
        INTPTLON: '-096.8548984',
        ELSDLEA: '',
        UNSDLEA: '00036',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9274, 47.1105],
            [-102.5254, 47.1114],
            [-102.5386, 46.8908],
            [-102.4753, 46.8835],
            [-102.4536, 46.6881],
            [-102.4964, 46.688],
            [-102.6011, 46.7168],
            [-102.8517, 46.7164],
            [-102.8516, 46.9807],
            [-102.9057, 46.9808],
            [-102.9274, 47.1105],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800038',
        NAME: 'Dickinson Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1286946532,
        AWATER: 5251130,
        INTPTLAT: '+46.9083920',
        INTPTLON: '-102.6914148',
        ELSDLEA: '',
        UNSDLEA: '00038',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.103, 48.6333],
            [-103.0156, 48.6333],
            [-102.9399, 48.7205],
            [-102.8857, 48.7205],
            [-102.8858, 48.5898],
            [-102.7553, 48.5463],
            [-102.7407, 48.4184],
            [-102.6908, 48.3725],
            [-102.6988, 48.2838],
            [-102.7639, 48.2406],
            [-102.6988, 48.1572],
            [-102.8285, 48.1237],
            [-102.8282, 48.2987],
            [-102.915, 48.2986],
            [-102.9145, 48.2118],
            [-103.0227, 48.2116],
            [-103.0224, 48.342],
            [-103.0822, 48.3906],
            [-103.103, 48.6333],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800039',
        NAME: 'Tioga Public School District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1145665961,
        AWATER: 29596853,
        INTPTLAT: '+48.4232461',
        INTPTLON: '-102.8966281',
        ELSDLEA: '',
        UNSDLEA: '00039',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0277, 47.5937],
              [-98.0172, 47.5867],
              [-98.0278, 47.5868],
              [-98.0277, 47.5937],
            ],
          ],
          [
            [
              [-98.0919, 47.6086],
              [-98.0813, 47.6015],
              [-98.0919, 47.6016],
              [-98.0919, 47.6086],
            ],
          ],
          [
            [
              [-98.5475, 47.9187],
              [-98.5366, 47.9226],
              [-98.5427, 47.9624],
              [-98.3227, 47.9187],
              [-98.2258, 47.9248],
              [-98.1629, 48.079],
              [-98.1953, 48.1302],
              [-98.1626, 48.245],
              [-98.0979, 48.2671],
              [-98.0659, 48.1947],
              [-97.8605, 48.1949],
              [-97.8719, 48.1079],
              [-97.8503, 48.0846],
              [-97.8604, 48.0645],
              [-97.8502, 48.0787],
              [-97.8172, 48.079],
              [-97.7739, 48.0432],
              [-97.8737, 47.9291],
              [-97.8718, 47.8463],
              [-97.9038, 47.7953],
              [-97.9254, 47.7447],
              [-97.914, 47.7447],
              [-97.8824, 47.6722],
              [-97.9844, 47.6446],
              [-98.0277, 47.5937],
              [-98.049, 47.5938],
              [-98.0919, 47.6086],
              [-98.1124, 47.6161],
              [-98.1875, 47.6453],
              [-98.2439, 47.6724],
              [-98.5252, 47.6725],
              [-98.5256, 47.7303],
              [-98.6542, 47.7456],
              [-98.5475, 47.9187],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800040',
        NAME: 'Dakota Prairie Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2316753375,
        AWATER: 49154931,
        INTPTLAT: '+47.8895508',
        INTPTLON: '-098.1503568',
        ELSDLEA: '',
        UNSDLEA: '00040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6439, 47.4988],
            [-97.5908, 47.513],
            [-97.6222, 47.5566],
            [-97.4107, 47.5563],
            [-97.2714, 47.6495],
            [-97.167, 47.5335],
            [-97.092, 47.5125],
            [-97.1986, 47.4256],
            [-97.2399, 47.324],
            [-97.3243, 47.2242],
            [-97.4514, 47.1954],
            [-97.5367, 47.268],
            [-97.5913, 47.3391],
            [-97.6015, 47.4407],
            [-97.6439, 47.4988],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800041',
        NAME: 'May-Port CG Public School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1128928813,
        AWATER: 549732,
        INTPTLAT: '+47.4329473',
        INTPTLON: '-097.3784550',
        ELSDLEA: '',
        UNSDLEA: '00041',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6542, 47.7456],
            [-98.5256, 47.7303],
            [-98.5252, 47.6725],
            [-98.2439, 47.6724],
            [-98.1875, 47.6453],
            [-98.244, 47.5873],
            [-98.2439, 47.5004],
            [-98.3718, 47.457],
            [-98.3831, 47.3132],
            [-98.5419, 47.3266],
            [-98.5739, 47.3266],
            [-98.5943, 47.3265],
            [-98.6489, 47.4136],
            [-98.8194, 47.4138],
            [-98.915, 47.5729],
            [-98.7991, 47.6725],
            [-98.6547, 47.6944],
            [-98.6542, 47.7456],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800042',
        NAME: 'Midkota Public School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1518594557,
        AWATER: 32884508,
        INTPTLAT: '+47.5508380',
        INTPTLON: '-098.5325025',
        ELSDLEA: '',
        UNSDLEA: '00042',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.1318, 46.8551],
              [-99.1208, 46.8481],
              [-99.1319, 46.8482],
              [-99.1318, 46.8551],
            ],
          ],
          [
            [
              [-99.1431, 46.8404],
              [-99.143, 46.8481],
              [-99.1319, 46.8482],
              [-99.1319, 46.841],
              [-99.132, 46.819],
              [-99.1431, 46.8404],
            ],
          ],
          [
            [
              [-99.6013, 46.632],
              [-99.5536, 46.7334],
              [-99.4476, 46.7987],
              [-99.3534, 46.762],
              [-99.1549, 46.7766],
              [-99.132, 46.819],
              [-99.0691, 46.7901],
              [-98.903, 46.7179],
              [-98.8719, 46.6307],
              [-98.8717, 46.616],
              [-98.9128, 46.566],
              [-99.0365, 46.5437],
              [-99.0579, 46.4137],
              [-99.2259, 46.3846],
              [-99.2674, 46.4279],
              [-99.2674, 46.4135],
              [-99.4666, 46.4645],
              [-99.4666, 46.4715],
              [-99.4767, 46.4859],
              [-99.372, 46.5218],
              [-99.5183, 46.5745],
              [-99.6013, 46.632],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800043',
        NAME: 'Gackle-Streeter Public School District 56',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1629342680,
        AWATER: 53999255,
        INTPTLAT: '+46.6089623',
        INTPTLON: '-099.2253880',
        ELSDLEA: '',
        UNSDLEA: '00043',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4514, 47.1954],
            [-97.3243, 47.2242],
            [-97.2399, 47.324],
            [-97.1126, 47.2388],
            [-96.9435, 47.2824],
            [-96.8334, 47.238],
            [-96.8401, 47.1505],
            [-96.8301, 47.0206],
            [-96.943, 46.9919],
            [-97.0792, 47.0498],
            [-97.4201, 47.0647],
            [-97.3875, 47.1593],
            [-97.4514, 47.1954],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800044',
        NAME: 'Northern Cass Public School District 97',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1117303495,
        AWATER: 200218,
        INTPTLAT: '+47.1445417',
        INTPTLON: '-097.1016241',
        ELSDLEA: '',
        UNSDLEA: '00044',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0931, 46.631],
            [-102.0129, 46.6452],
            [-101.7373, 46.5887],
            [-101.6964, 46.5886],
            [-101.6752, 46.5887],
            [-101.6874, 46.1322],
            [-101.6242, 46.0365],
            [-101.6776, 46.0129],
            [-101.8618, 46.0648],
            [-101.9986, 46.0535],
            [-101.9979, 46.2055],
            [-102.1026, 46.2057],
            [-102.0397, 46.2232],
            [-102.0505, 46.6025],
            [-102.0931, 46.631],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800045',
        NAME: 'Elgin-New Leipzig Public School District 49',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1798556535,
        AWATER: 12705606,
        INTPTLAT: '+46.3283005',
        INTPTLON: '-101.8554451',
        ELSDLEA: '',
        UNSDLEA: '00045',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.391, 46.6302],
            [-102.1798, 46.631],
            [-102.0931, 46.631],
            [-102.0505, 46.6025],
            [-102.0397, 46.2232],
            [-102.1026, 46.2057],
            [-102.5915, 46.2493],
            [-102.6736, 46.2828],
            [-102.6561, 46.6299],
            [-102.391, 46.6302],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800046',
        NAME: 'Mott-Regent Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2220550250,
        AWATER: 3500613,
        INTPTLAT: '+46.4217625',
        INTPTLON: '-102.3495839',
        ELSDLEA: '',
        UNSDLEA: '00046',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0166, 48.5745],
            [-100.929, 48.6326],
            [-100.7694, 48.6637],
            [-100.5593, 48.6325],
            [-100.2978, 48.6611],
            [-100.2221, 48.545],
            [-100.2767, 48.5448],
            [-100.2373, 48.3712],
            [-100.2371, 48.1962],
            [-100.41, 48.1525],
            [-100.4858, 48.1091],
            [-100.4963, 48.1673],
            [-100.6692, 48.1563],
            [-100.8857, 48.1964],
            [-101.0145, 48.1966],
            [-101.0142, 48.3125],
            [-100.9294, 48.4586],
            [-100.9293, 48.5457],
            [-101.0166, 48.5745],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800047',
        NAME: 'Towner-Granville-Upham Public School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2678841157,
        AWATER: 49793033,
        INTPTLAT: '+48.4074813',
        INTPTLON: '-100.6138709',
        ELSDLEA: '',
        UNSDLEA: '00047',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.5252, 47.17],
            [-102.3026, 47.126],
            [-102.2718, 47.1694],
            [-102.1445, 47.1553],
            [-102.1447, 47.0681],
            [-102.2182, 47.0246],
            [-102.1811, 46.8842],
            [-102.1798, 46.631],
            [-102.391, 46.6302],
            [-102.4536, 46.6881],
            [-102.4753, 46.8835],
            [-102.5386, 46.8908],
            [-102.5254, 47.1114],
            [-102.5252, 47.17],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800048',
        NAME: 'Richardton-Taylor Public School District 34',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1361493411,
        AWATER: 5681939,
        INTPTLAT: '+46.9082805',
        INTPTLON: '-102.3405823',
        ELSDLEA: '',
        UNSDLEA: '00048',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8317, 47.5882],
            [-99.8416, 47.6734],
            [-99.7909, 47.7313],
            [-99.7478, 47.7093],
            [-99.7163, 47.7526],
            [-99.6833, 47.8367],
            [-99.5973, 47.7976],
            [-99.426, 47.7744],
            [-99.3515, 47.652],
            [-99.3941, 47.5875],
            [-99.3941, 47.573],
            [-99.4582, 47.5152],
            [-99.5591, 47.4142],
            [-99.5647, 47.3273],
            [-99.6081, 47.2991],
            [-99.6505, 47.3271],
            [-99.8632, 47.3142],
            [-99.8633, 47.327],
            [-99.8412, 47.4142],
            [-99.8839, 47.4798],
            [-99.8317, 47.5882],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800049',
        NAME: 'Fessenden-Bowdon Public School District 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1420483470,
        AWATER: 24433680,
        INTPTLAT: '+47.5735382',
        INTPTLON: '-099.6395925',
        ELSDLEA: '',
        UNSDLEA: '00049',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-101.2782, 48.9993],
              [-101.2342, 48.9994],
              [-101.2782, 48.9803],
              [-101.2782, 48.9993],
            ],
          ],
          [
            [
              [-101.9996, 48.999],
              [-101.3442, 48.9994],
              [-101.2782, 48.9803],
              [-101.2342, 48.8136],
              [-101.2775, 48.676],
              [-101.1901, 48.6102],
              [-101.2995, 48.5458],
              [-101.5656, 48.5463],
              [-101.6256, 48.5463],
              [-101.7777, 48.5899],
              [-101.8425, 48.6768],
              [-101.8806, 48.8],
              [-101.8898, 48.8936],
              [-101.9996, 48.999],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800050',
        NAME: 'Mohall-Lansford-Sherwood Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2112360280,
        AWATER: 27780262,
        INTPTLAT: '+48.7851663',
        INTPTLON: '-101.5732238',
        ELSDLEA: '',
        UNSDLEA: '00050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4073, 47.9764],
            [-97.3642, 47.973],
            [-97.4294, 47.9332],
            [-97.4073, 47.9764],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800051',
        NAME: 'Grand Forks AFB Public School District 140',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25729108,
        AWATER: 0,
        INTPTLAT: '+47.9556979',
        INTPTLON: '-097.3967387',
        ELSDLEA: '',
        UNSDLEA: '00051',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4063, 47.416],
            [-101.3582, 47.3423],
            [-101.3457, 47.2919],
            [-101.2154, 47.2485],
            [-100.9864, 47.2569],
            [-100.9652, 47.1566],
            [-100.8807, 47.041],
            [-100.936, 46.9828],
            [-101.2192, 46.9814],
            [-101.3836, 46.9811],
            [-101.4261, 47.0682],
            [-101.5743, 47.1042],
            [-101.4479, 47.2125],
            [-101.4482, 47.2994],
            [-101.5045, 47.4151],
            [-101.4063, 47.416],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800052',
        NAME: 'Center-Stanton Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1388978577,
        AWATER: 21779717,
        INTPTLAT: '+47.1445557',
        INTPTLON: '-101.2333522',
        ELSDLEA: '',
        UNSDLEA: '00052',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2064, 49.0003],
            [-97.2287, 49.0006],
            [-97.2341, 48.9479],
            [-97.1576, 48.7875],
            [-97.294, 48.7681],
            [-97.3589, 48.8473],
            [-97.4573, 48.8476],
            [-97.7529, 48.8916],
            [-97.7746, 48.8629],
            [-97.8835, 48.8041],
            [-97.884, 48.7177],
            [-97.9277, 48.7177],
            [-98.0814, 48.8044],
            [-98.2162, 48.9493],
            [-98.2064, 49.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800054',
        NAME: 'North Border School District 100',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1414714257,
        AWATER: 3511002,
        INTPTLAT: '+48.8998154',
        INTPTLON: '-097.6999888',
        ELSDLEA: '',
        UNSDLEA: '00054',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9683, 48.3398],
            [-97.8499, 48.2819],
            [-97.6872, 48.2673],
            [-97.6008, 48.2383],
            [-97.7306, 48.1948],
            [-97.7092, 48.0934],
            [-97.8172, 48.079],
            [-97.8502, 48.0787],
            [-97.8503, 48.0846],
            [-97.8719, 48.1079],
            [-97.8605, 48.1949],
            [-98.0659, 48.1947],
            [-98.0979, 48.2671],
            [-97.9683, 48.3398],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800056',
        NAME: 'Fordville-Lankin Public School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548281250,
        AWATER: 2981522,
        INTPTLAT: '+48.2312964',
        INTPTLON: '-097.8715233',
        ELSDLEA: '',
        UNSDLEA: '00056',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6524, 46.5945],
            [-98.5513, 46.5729],
            [-98.4602, 46.6311],
            [-98.4392, 46.8487],
            [-98.4392, 46.8926],
            [-98.3221, 46.8926],
            [-98.2912, 46.878],
            [-98.2912, 46.8708],
            [-98.2795, 46.8496],
            [-98.2701, 46.8496],
            [-98.2488, 46.8417],
            [-98.2068, 46.8263],
            [-98.1856, 46.8342],
            [-98.1444, 46.7905],
            [-98.0181, 46.6963],
            [-98.0398, 46.6598],
            [-97.8921, 46.6738],
            [-97.9089, 46.5872],
            [-98.043, 46.5291],
            [-98.2853, 46.5217],
            [-98.327, 46.4781],
            [-98.4106, 46.5218],
            [-98.5368, 46.4861],
            [-98.6524, 46.5945],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800057',
        NAME: 'Litchville-Marion Public School District 46',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1322187048,
        AWATER: 5686170,
        INTPTLAT: '+46.6529357',
        INTPTLON: '-098.2706513',
        ELSDLEA: '',
        UNSDLEA: '00057',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9733, 48.4159],
            [-101.8092, 48.4595],
            [-101.7777, 48.5899],
            [-101.6256, 48.5463],
            [-101.7127, 48.415],
            [-101.6205, 48.3564],
            [-101.4894, 48.3567],
            [-101.4793, 48.2978],
            [-101.6422, 48.2548],
            [-101.6633, 48.0667],
            [-101.534, 48.0662],
            [-101.5188, 47.9349],
            [-101.5817, 47.8624],
            [-101.6147, 47.8048],
            [-101.7333, 47.7464],
            [-102.001, 47.7318],
            [-101.8934, 47.7608],
            [-101.8719, 47.8331],
            [-101.9795, 47.8693],
            [-101.9795, 47.9779],
            [-102.1164, 48.0231],
            [-102.1165, 48.1316],
            [-102.1166, 48.1898],
            [-101.9869, 48.2837],
            [-101.9733, 48.4159],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800058',
        NAME: 'Lewis and Clark Public School District 161',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2433280399,
        AWATER: 64640452,
        INTPTLAT: '+48.1320250',
        INTPTLON: '-101.8249795',
        ELSDLEA: '',
        UNSDLEA: '00058',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.426, 47.7744],
            [-99.3617, 47.8467],
            [-99.1686, 47.8472],
            [-99.169, 47.9199],
            [-98.9096, 47.9201],
            [-98.9111, 47.7597],
            [-98.6542, 47.7456],
            [-98.6547, 47.6944],
            [-98.7991, 47.6725],
            [-98.915, 47.5729],
            [-99.3941, 47.5875],
            [-99.3515, 47.652],
            [-99.426, 47.7744],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800059',
        NAME: 'New Rockford-Sheyenne Public School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1394830683,
        AWATER: 14956972,
        INTPTLAT: '+47.7230342',
        INTPTLON: '-099.0820992',
        ELSDLEA: '',
        UNSDLEA: '00059',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.9291, 47.1677],
              [-97.8971, 47.1531],
              [-97.8858, 47.1387],
              [-97.9291, 47.1677],
            ],
          ],
          [
            [
              [-98.2277, 46.8776],
              [-98.1856, 46.8342],
              [-98.2068, 46.8263],
              [-98.2277, 46.8776],
            ],
          ],
          [
            [
              [-98.2701, 46.8559],
              [-98.2585, 46.8852],
              [-98.2488, 46.8923],
              [-98.2277, 46.8776],
              [-98.2488, 46.8417],
              [-98.2701, 46.8496],
              [-98.2701, 46.8559],
            ],
          ],
          [
            [
              [-98.2912, 46.8708],
              [-98.2701, 46.8559],
              [-98.2795, 46.8496],
              [-98.2912, 46.8708],
            ],
          ],
          [
            [
              [-98.2488, 46.8923],
              [-98.2585, 46.8852],
              [-98.2912, 46.878],
              [-98.3221, 46.8926],
              [-98.2488, 46.8923],
            ],
          ],
          [
            [
              [-98.5943, 47.3265],
              [-98.5739, 47.3266],
              [-98.5737, 47.3231],
              [-98.5943, 47.3265],
            ],
          ],
          [
            [
              [-98.7211, 47.1534],
              [-98.7212, 47.2115],
              [-98.6055, 47.2403],
              [-98.5737, 47.3231],
              [-98.5419, 47.3266],
              [-98.3831, 47.3132],
              [-98.3406, 47.2406],
              [-97.9446, 47.2377],
              [-97.9189, 47.2296],
              [-97.8975, 47.2259],
              [-97.94, 47.2029],
              [-97.9291, 47.1688],
              [-97.9291, 47.1677],
              [-98.025, 47.1827],
              [-98.0813, 46.9501],
              [-98.1535, 46.9648],
              [-98.1855, 46.9068],
              [-98.2055, 46.9068],
              [-98.2911, 46.9694],
              [-98.4394, 46.9583],
              [-98.4392, 46.8926],
              [-98.4392, 46.8487],
              [-98.4603, 46.8196],
              [-98.481, 46.8121],
              [-98.5236, 46.8195],
              [-98.5448, 46.8488],
              [-98.6155, 47.0805],
              [-98.6791, 47.0809],
              [-98.7211, 47.1534],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800060',
        NAME: 'Barnes County North Public School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1818006570,
        AWATER: 42595523,
        INTPTLAT: '+47.1029040',
        INTPTLON: '-098.3511287',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8921, 46.6738],
            [-97.8921, 46.6883],
            [-97.7151, 46.688],
            [-97.6817, 46.8039],
            [-97.5354, 46.796],
            [-97.4722, 46.6945],
            [-97.3782, 46.6874],
            [-97.4091, 46.6294],
            [-97.2801, 46.5716],
            [-97.2802, 46.4565],
            [-97.2802, 46.4561],
            [-97.4897, 46.472],
            [-97.6581, 46.5436],
            [-97.8052, 46.5437],
            [-97.9089, 46.5872],
            [-97.8921, 46.6738],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800061',
        NAME: 'Enderlin Area Public School District 24',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1046855631,
        AWATER: 4924588,
        INTPTLAT: '+46.6187362',
        INTPTLON: '-097.5794249',
        ELSDLEA: '',
        UNSDLEA: '00061',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1133, 47.3273],
            [-100.0333, 47.3272],
            [-99.8633, 47.327],
            [-99.8632, 47.3142],
            [-99.8616, 46.9811],
            [-99.7768, 46.981],
            [-99.608, 47.0674],
            [-99.6081, 47.2991],
            [-99.5647, 47.3273],
            [-99.3758, 47.1829],
            [-99.3545, 47.099],
            [-99.4182, 47.0736],
            [-99.4464, 46.9809],
            [-99.4476, 46.7987],
            [-99.5536, 46.7334],
            [-99.6013, 46.632],
            [-99.6693, 46.7192],
            [-99.828, 46.7196],
            [-99.8284, 46.6325],
            [-100.0217, 46.6179],
            [-100.038, 46.6912],
            [-100.1642, 46.7204],
            [-100.0777, 46.8216],
            [-100.1403, 46.8673],
            [-100.1599, 46.9667],
            [-100.1151, 47.0252],
            [-100.1133, 47.3273],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800389',
        NAME: 'Kidder County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2994882354,
        AWATER: 162872781,
        INTPTLAT: '+46.9626771',
        INTPTLON: '-099.8104357',
        ELSDLEA: '',
        UNSDLEA: '00389',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5587, 48.6463],
            [-99.5258, 48.7762],
            [-99.2849, 48.7761],
            [-99.1319, 48.7325],
            [-99.0006, 48.7471],
            [-98.9984, 48.6663],
            [-98.9704, 48.5442],
            [-99.0141, 48.3705],
            [-99.0938, 48.3706],
            [-99.1837, 48.3017],
            [-99.3627, 48.437],
            [-99.4933, 48.4514],
            [-99.4932, 48.5448],
            [-99.5586, 48.6028],
            [-99.5587, 48.6463],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800390',
        NAME: 'North Star School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1654954469,
        AWATER: 33562423,
        INTPTLAT: '+48.5642951',
        INTPTLON: '-099.2420968',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0572, 48.5433],
            [-97.9271, 48.5872],
            [-97.9277, 48.7177],
            [-97.884, 48.7177],
            [-97.7536, 48.6662],
            [-97.5679, 48.6592],
            [-97.5462, 48.5721],
            [-97.4588, 48.543],
            [-97.601, 48.4641],
            [-97.6771, 48.4707],
            [-97.6996, 48.4921],
            [-97.7429, 48.4803],
            [-97.7618, 48.4804],
            [-97.9709, 48.4489],
            [-98.0572, 48.5433],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800397',
        NAME: 'Valley-Edinburg Public School District 118',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765914719,
        AWATER: 485900,
        INTPTLAT: '+48.5648459',
        INTPTLON: '-097.7644838',
        ELSDLEA: '',
        UNSDLEA: '00397',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2342, 48.8136],
            [-100.7542, 48.8065],
            [-100.5771, 48.7628],
            [-100.5593, 48.6325],
            [-100.7694, 48.6637],
            [-100.929, 48.6326],
            [-101.0166, 48.5745],
            [-101.1901, 48.6102],
            [-101.2775, 48.676],
            [-101.2342, 48.8136],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3800825',
        NAME: 'Newburg United Public School District 54',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 975554406,
        AWATER: 12593979,
        INTPTLAT: '+48.7123333',
        INTPTLON: '-100.9393357',
        ELSDLEA: '',
        UNSDLEA: '00825',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7974, 47.9992],
            [-103.7139, 48.1106],
            [-103.6082, 48.1141],
            [-103.499, 48.0056],
            [-103.5405, 47.8923],
            [-103.5398, 47.6746],
            [-103.7364, 47.6474],
            [-103.6686, 47.6747],
            [-103.6691, 47.7615],
            [-103.797, 47.7615],
            [-103.7974, 47.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3801760',
        NAME: 'Alexander Public School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 829351823,
        AWATER: 39970408,
        INTPTLAT: '+47.8990125',
        INTPTLON: '-103.6583964',
        ELSDLEA: '',
        UNSDLEA: '01760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2369, 48.0362],
            [-100.0691, 47.9921],
            [-100.0689, 47.912],
            [-100.198, 47.8476],
            [-100.1984, 47.6737],
            [-100.1606, 47.6463],
            [-100.2883, 47.6465],
            [-100.349, 47.7317],
            [-100.3274, 47.9343],
            [-100.2369, 48.0362],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3801950',
        NAME: 'Anamoose Public School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528857372,
        AWATER: 26939778,
        INTPTLAT: '+47.8547523',
        INTPTLON: '-100.2301444',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6702, 46.0708],
            [-99.566, 46.0711],
            [-99.5239, 46.1577],
            [-99.3774, 46.1866],
            [-99.3785, 46.2451],
            [-99.2766, 46.2834],
            [-99.2534, 46.2006],
            [-99.1568, 46.2006],
            [-99.0037, 46.1137],
            [-98.9846, 46.0264],
            [-99.0058, 45.9397],
            [-99.2223, 45.94],
            [-99.63, 45.9411],
            [-99.6702, 46.0708],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3802170',
        NAME: 'Ashley Public School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1227603690,
        AWATER: 41281849,
        INTPTLAT: '+46.0706167',
        INTPTLON: '-099.3059314',
        ELSDLEA: '',
        UNSDLEA: '02170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0454, 47.3301],
            [-103.7377, 47.3294],
            [-103.6667, 47.3294],
            [-103.667, 46.9798],
            [-103.6095, 46.9799],
            [-103.6092, 46.6298],
            [-103.7345, 46.6297],
            [-103.7347, 46.7596],
            [-103.8189, 46.7599],
            [-103.7976, 46.8035],
            [-104.0461, 46.8035],
            [-104.0454, 47.3301],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3802380',
        NAME: 'Beach Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1974029124,
        AWATER: 3086504,
        INTPTLAT: '+47.0117127',
        INTPTLON: '-103.8159885',
        ELSDLEA: '',
        UNSDLEA: '02380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9199, 48.8924],
            [-99.6593, 48.8923],
            [-99.6575, 48.8054],
            [-99.8543, 48.8056],
            [-99.9201, 48.8057],
            [-99.9199, 48.8924],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3802530',
        NAME: 'Belcourt Public School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175555493,
        AWATER: 11221901,
        INTPTLAT: '+48.8446317',
        INTPTLON: '-099.8027898',
        ELSDLEA: '',
        UNSDLEA: '02530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7087, 48.9995],
            [-100.162, 48.9993],
            [-100.1829, 48.9367],
            [-100.183, 48.7189],
            [-100.1028, 48.6904],
            [-100.0592, 48.5741],
            [-100.2221, 48.545],
            [-100.2978, 48.6611],
            [-100.5593, 48.6325],
            [-100.5771, 48.7628],
            [-100.7542, 48.8065],
            [-100.6872, 48.9084],
            [-100.7087, 48.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3803060',
        NAME: 'Bottineau Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1586678502,
        AWATER: 42912432,
        INTPTLAT: '+48.8098911',
        INTPTLON: '-100.3984433',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3494, 48.9988],
            [-102.0211, 48.999],
            [-102.0214, 48.9373],
            [-102.1211, 48.8072],
            [-102.1525, 48.7203],
            [-102.3212, 48.7203],
            [-102.5466, 48.7204],
            [-102.5463, 48.7783],
            [-102.3933, 48.8073],
            [-102.3495, 48.8653],
            [-102.3494, 48.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3803090',
        NAME: 'Bowbells Public School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 719425486,
        AWATER: 14082399,
        INTPTLAT: '+48.8558751',
        INTPTLON: '-102.2568013',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0458, 46.1191],
            [-103.8677, 46.1188],
            [-103.8675, 46.2058],
            [-103.7845, 46.2057],
            [-103.8014, 46.4539],
            [-103.5511, 46.4537],
            [-103.5508, 46.3669],
            [-103.1757, 46.3667],
            [-103.2448, 46.28],
            [-103.2451, 45.9452],
            [-104.0455, 45.9453],
            [-104.0458, 46.1191],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3803200',
        NAME: 'Bowman County Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2621713169,
        AWATER: 13618755,
        INTPTLAT: '+46.1600834',
        INTPTLON: '-103.5991209',
        ELSDLEA: '',
        UNSDLEA: '03200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9177, 48.9993],
            [-102.3494, 48.9988],
            [-102.3495, 48.8653],
            [-102.3933, 48.8073],
            [-102.5463, 48.7783],
            [-102.5466, 48.7204],
            [-102.8086, 48.7495],
            [-102.8857, 48.7205],
            [-102.9399, 48.7205],
            [-102.8859, 48.7496],
            [-102.8741, 48.8945],
            [-102.9177, 48.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3803590',
        NAME: 'Burke Central Public School District 36',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1026454835,
        AWATER: 12197947,
        INTPTLAT: '+48.8563050',
        INTPTLON: '-102.6402669',
        ELSDLEA: '',
        UNSDLEA: '03590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4582, 47.5152],
            [-99.3941, 47.573],
            [-99.3941, 47.5875],
            [-98.915, 47.5729],
            [-98.8194, 47.4138],
            [-98.8303, 47.3269],
            [-98.933, 47.313],
            [-99.1011, 47.2983],
            [-99.1231, 47.1824],
            [-99.1008, 47.0666],
            [-99.2915, 47.1537],
            [-99.2701, 47.1958],
            [-99.3127, 47.1765],
            [-99.3545, 47.099],
            [-99.3758, 47.1829],
            [-99.5647, 47.3273],
            [-99.5591, 47.4142],
            [-99.4582, 47.5152],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3803790',
        NAME: 'Carrington Public School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1960783605,
        AWATER: 52687721,
        INTPTLAT: '+47.3794129',
        INTPTLON: '-099.2083216',
        ELSDLEA: '',
        UNSDLEA: '03790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5354, 46.796],
            [-97.4299, 46.8245],
            [-97.4095, 47.0359],
            [-97.4201, 47.0647],
            [-97.0792, 47.0498],
            [-96.943, 46.9919],
            [-96.9883, 46.9496],
            [-97.115, 46.9733],
            [-97.1173, 46.8615],
            [-97.0635, 46.7895],
            [-97.1163, 46.7892],
            [-97.2834, 46.6873],
            [-97.3782, 46.6874],
            [-97.4722, 46.6945],
            [-97.5354, 46.796],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3804090',
        NAME: 'Central Cass Public School District 17',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1040108550,
        AWATER: 1078901,
        INTPTLAT: '+46.8836005',
        INTPTLON: '-097.2844462',
        ELSDLEA: '',
        UNSDLEA: '04090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2709, 47.6722],
            [-97.239, 47.7157],
            [-97.239, 47.7223],
            [-97.239, 47.7302],
            [-97.0987, 47.7156],
            [-96.9379, 47.7602],
            [-96.9204, 47.7114],
            [-96.8499, 47.5908],
            [-96.8561, 47.4938],
            [-97.092, 47.5125],
            [-97.167, 47.5335],
            [-97.2714, 47.6495],
            [-97.2709, 47.6722],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3804140',
        NAME: 'Central Valley Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629814122,
        AWATER: 600694,
        INTPTLAT: '+47.6212958',
        INTPTLON: '-097.0374781',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3831, 47.3132],
            [-98.3718, 47.457],
            [-98.2439, 47.5004],
            [-98.244, 47.5873],
            [-98.1875, 47.6453],
            [-98.1124, 47.6161],
            [-98.0919, 47.6016],
            [-98.0813, 47.6015],
            [-98.049, 47.5938],
            [-98.0278, 47.5868],
            [-98.0172, 47.5867],
            [-97.9856, 47.413],
            [-97.9009, 47.3549],
            [-97.9664, 47.3405],
            [-97.9402, 47.2378],
            [-97.9446, 47.2377],
            [-98.3406, 47.2406],
            [-98.3831, 47.3132],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3804560',
        NAME: 'Griggs County Central School District 18',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1088675234,
        AWATER: 7502041,
        INTPTLAT: '+47.4061942',
        INTPTLON: '-098.1456153',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.5845, 48.1513],
              [-98.5734, 48.1659],
              [-98.552, 48.1369],
              [-98.5845, 48.1513],
            ],
          ],
          [
            [
              [-99.0489, 48.303],
              [-98.8697, 48.2819],
              [-98.756, 48.3544],
              [-98.681, 48.3325],
              [-98.552, 48.3108],
              [-98.5518, 48.2384],
              [-98.5734, 48.1659],
              [-98.5845, 48.1513],
              [-98.607, 48.0935],
              [-98.5828, 48.044],
              [-98.5427, 47.9624],
              [-98.5366, 47.9226],
              [-98.5475, 47.9187],
              [-98.6599, 47.9602],
              [-98.7382, 47.9346],
              [-98.9098, 47.978],
              [-99.0373, 48.0471],
              [-99.1571, 48.1659],
              [-99.1572, 48.2238],
              [-99.0489, 48.303],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805040',
        NAME: 'Devils Lake Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1233484725,
        AWATER: 223134858,
        INTPTLAT: '+48.1515317',
        INTPTLON: '-098.8272824',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8592, 48.9996],
            [-102.9177, 48.9993],
            [-102.8741, 48.8945],
            [-102.8859, 48.7496],
            [-102.9399, 48.7205],
            [-103.0156, 48.6333],
            [-103.103, 48.6333],
            [-103.3419, 48.6333],
            [-103.2766, 48.5464],
            [-103.4288, 48.5464],
            [-103.4286, 48.6334],
            [-103.5588, 48.6483],
            [-103.5368, 48.7206],
            [-103.804, 48.8222],
            [-103.9448, 48.8078],
            [-103.9474, 48.9743],
            [-103.8592, 48.9996],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805160',
        NAME: 'Divide County Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2598637181,
        AWATER: 56675298,
        INTPTLAT: '+48.8255065',
        INTPTLON: '-103.3611958',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.32, 48.6951],
            [-97.294, 48.7681],
            [-97.1576, 48.7875],
            [-97.1054, 48.6896],
            [-97.1634, 48.544],
            [-97.1227, 48.4203],
            [-97.1374, 48.4134],
            [-97.3327, 48.4632],
            [-97.355, 48.5429],
            [-97.32, 48.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805340',
        NAME: 'Drayton Public School District 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498085940,
        AWATER: 6162342,
        INTPTLAT: '+48.6039870',
        INTPTLON: '-097.2229029',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.162, 48.9993],
            [-99.9651, 48.9991],
            [-99.9199, 48.8924],
            [-99.9201, 48.8057],
            [-100.0078, 48.7188],
            [-100.1028, 48.6904],
            [-100.183, 48.7189],
            [-100.1829, 48.9367],
            [-100.162, 48.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805460',
        NAME: 'Dunseith Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 485205673,
        AWATER: 23312628,
        INTPTLAT: '+48.8548764',
        INTPTLON: '-100.0630899',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.8189, 46.5079],
              [-98.8086, 46.5079],
              [-98.8086, 46.5004],
              [-98.8189, 46.5079],
            ],
          ],
          [
            [
              [-98.9023, 46.4572],
              [-98.9017, 46.4647],
              [-98.8505, 46.4784],
              [-98.8086, 46.5004],
              [-98.8086, 46.5079],
              [-98.8717, 46.616],
              [-98.8719, 46.6307],
              [-98.6622, 46.6309],
              [-98.6524, 46.5945],
              [-98.5368, 46.4861],
              [-98.5239, 46.2418],
              [-98.661, 46.1994],
              [-98.8178, 46.2003],
              [-98.7758, 46.25],
              [-98.9023, 46.4572],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805850',
        NAME: 'Edgeley Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1063091313,
        AWATER: 1094701,
        INTPTLAT: '+46.4063171',
        INTPTLON: '-098.7012010',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7096, 48.5584],
            [-98.5793, 48.5651],
            [-98.536, 48.609],
            [-98.4055, 48.5581],
            [-98.1875, 48.5433],
            [-98.1553, 48.5143],
            [-98.2821, 48.3402],
            [-98.1626, 48.2601],
            [-98.4219, 48.2817],
            [-98.5518, 48.2384],
            [-98.552, 48.3108],
            [-98.681, 48.3325],
            [-98.6663, 48.5004],
            [-98.7096, 48.5584],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3805950',
        NAME: 'Edmore Public School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1085174516,
        AWATER: 17665002,
        INTPTLAT: '+48.4253836',
        INTPTLON: '-098.4375382',
        ELSDLEA: '',
        UNSDLEA: '05950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0443, 48.0248],
            [-103.9052, 48.1112],
            [-103.7139, 48.1106],
            [-103.7974, 47.9992],
            [-103.9198, 47.9559],
            [-104.0441, 47.9961],
            [-104.0443, 48.0248],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806010',
        NAME: 'Eightmile Public School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227846096,
        AWATER: 9430487,
        INTPTLAT: '+48.0339174',
        INTPTLON: '-103.8770274',
        ELSDLEA: '',
        UNSDLEA: '06010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.4094, 46.1829],
              [-98.3968, 46.1683],
              [-98.3995, 46.1683],
              [-98.4093, 46.1684],
              [-98.4094, 46.1829],
            ],
          ],
          [
            [
              [-98.9846, 46.0264],
              [-98.8811, 46.04],
              [-98.8178, 46.2003],
              [-98.661, 46.1994],
              [-98.5239, 46.2418],
              [-98.51, 46.2418],
              [-98.4094, 46.1829],
              [-98.4204, 46.1685],
              [-98.2973, 46.0587],
              [-98.2783, 45.9361],
              [-98.3522, 45.9363],
              [-98.7244, 45.9387],
              [-99.0058, 45.9397],
              [-98.9846, 46.0264],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806090',
        NAME: 'Ellendale Public School District 40',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1308551356,
        AWATER: 8278178,
        INTPTLAT: '+46.0687026',
        INTPTLON: '-098.6200356',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.1055, 46.9805],
            [-103.1054, 46.6878],
            [-103.1054, 46.6298],
            [-103.2316, 46.6298],
            [-103.231, 46.9803],
            [-103.1055, 46.9805],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806210',
        NAME: 'Belfield Public School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373720661,
        AWATER: 1034332,
        INTPTLAT: '+46.7999387',
        INTPTLON: '-103.1630761',
        ELSDLEA: '',
        UNSDLEA: '06210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.71, 46.1092],
            [-96.5632, 46.1086],
            [-96.5766, 46.0218],
            [-96.5637, 45.9352],
            [-96.7638, 45.9358],
            [-96.7007, 46.0225],
            [-96.71, 46.1092],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806690',
        NAME: 'Fairmount Public School District 18',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231110350,
        AWATER: 0,
        INTPTLAT: '+46.0172598',
        INTPTLON: '-096.6458762',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8724, 46.746],
            [-96.82, 46.9768],
            [-96.7767, 46.8956],
            [-96.7839, 46.7064],
            [-96.7909, 46.703],
            [-96.8724, 46.746],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806780',
        NAME: 'Fargo Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156231141,
        AWATER: 103148,
        INTPTLAT: '+46.8351754',
        INTPTLON: '-096.8180562',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.9844, 47.6446],
              [-97.8824, 47.6722],
              [-97.7188, 47.6359],
              [-97.6222, 47.5566],
              [-97.5908, 47.513],
              [-97.6439, 47.4988],
              [-97.6015, 47.4407],
              [-97.7399, 47.4193],
              [-97.8903, 47.4128],
              [-97.9009, 47.3549],
              [-97.9856, 47.413],
              [-98.0172, 47.5867],
              [-98.0277, 47.5937],
              [-97.9844, 47.6446],
            ],
          ],
          [
            [
              [-98.049, 47.5938],
              [-98.0277, 47.5937],
              [-98.0278, 47.5868],
              [-98.049, 47.5938],
            ],
          ],
          [
            [
              [-98.049, 47.5938],
              [-98.0813, 47.6015],
              [-98.0919, 47.6086],
              [-98.049, 47.5938],
            ],
          ],
          [
            [
              [-98.1124, 47.6161],
              [-98.0919, 47.6086],
              [-98.0919, 47.6016],
              [-98.1124, 47.6161],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806910',
        NAME: 'Finley-Sharon Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 749142395,
        AWATER: 2936873,
        INTPTLAT: '+47.5363962',
        INTPTLON: '-097.8247669',
        ELSDLEA: '',
        UNSDLEA: '06910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3827, 46.5744],
            [-101.2153, 46.66],
            [-101.1941, 46.631],
            [-100.9861, 46.6321],
            [-100.9862, 46.5162],
            [-100.903, 46.5161],
            [-100.8149, 46.3847],
            [-100.9189, 46.395],
            [-101.0257, 46.2848],
            [-101.2503, 46.1363],
            [-101.2986, 46.1172],
            [-101.4381, 46.2191],
            [-101.4242, 46.4221],
            [-101.3407, 46.4745],
            [-101.3827, 46.5744],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3806930',
        NAME: 'Flasher Public School District 39',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1659619693,
        AWATER: 679456,
        INTPTLAT: '+46.3932776',
        INTPTLON: '-101.1945199',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1248, 47.9939],
            [-99.0373, 48.0471],
            [-98.9098, 47.978],
            [-98.9097, 47.9641],
            [-99.1253, 47.949],
            [-99.1248, 47.9939],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3807170',
        NAME: 'Fort Totten Public School District 30',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93518263,
        AWATER: 8398561,
        INTPTLAT: '+47.9860863',
        INTPTLON: '-099.0220756',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0257, 46.2848],
            [-100.8766, 46.2848],
            [-100.8772, 46.2047],
            [-100.7522, 46.2338],
            [-100.5601, 46.2338],
            [-100.629, 46.146],
            [-100.6145, 46.0718],
            [-100.5119, 45.9437],
            [-100.7938, 45.9438],
            [-100.8681, 45.9656],
            [-100.8774, 46.1174],
            [-101.2503, 46.1363],
            [-101.0257, 46.2848],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3807200',
        NAME: 'Fort Yates Public School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1016579042,
        AWATER: 67200930,
        INTPTLAT: '+46.1027543',
        INTPTLON: '-100.8551956',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.001, 47.7318],
            [-101.7333, 47.7464],
            [-101.6147, 47.8048],
            [-101.5817, 47.8624],
            [-101.4651, 47.8482],
            [-101.2953, 47.6883],
            [-101.1003, 47.6744],
            [-101.2286, 47.589],
            [-101.3563, 47.5889],
            [-101.5261, 47.5479],
            [-101.6943, 47.5386],
            [-101.7135, 47.5369],
            [-101.8221, 47.5885],
            [-101.8222, 47.674],
            [-102.0006, 47.674],
            [-102.001, 47.7318],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3807590',
        NAME: 'Garrison Public School District 51',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1059946489,
        AWATER: 148431363,
        INTPTLAT: '+47.6877872',
        INTPTLON: '-101.5486136',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0129, 46.6452],
            [-102.0016, 46.8046],
            [-101.9288, 46.8699],
            [-101.8702, 47.0247],
            [-101.68, 47.0246],
            [-101.6067, 46.9419],
            [-101.6054, 46.8042],
            [-101.6786, 46.8039],
            [-101.6559, 46.6668],
            [-101.6964, 46.5886],
            [-101.7373, 46.5887],
            [-102.0129, 46.6452],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3807830',
        NAME: 'Glen Ullin Public School District 48',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1105786624,
        AWATER: 7778612,
        INTPTLAT: '+46.8068352',
        INTPTLON: '-101.8009325',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5656, 48.5463],
            [-101.2995, 48.5458],
            [-101.1901, 48.6102],
            [-101.0166, 48.5745],
            [-100.9293, 48.5457],
            [-100.9294, 48.4586],
            [-101.0385, 48.4295],
            [-101.1437, 48.3715],
            [-101.3795, 48.3718],
            [-101.3427, 48.401],
            [-101.3863, 48.4225],
            [-101.5656, 48.5463],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3807850',
        NAME: 'Glenburn Public School District 26',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794976097,
        AWATER: 1405383,
        INTPTLAT: '+48.4949391',
        INTPTLON: '-101.2229343',
        ELSDLEA: '',
        UNSDLEA: '07850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2883, 47.6465],
            [-100.1606, 47.6463],
            [-100.0329, 47.646],
            [-100.0329, 47.5318],
            [-100.0333, 47.3272],
            [-100.1133, 47.3273],
            [-100.2894, 47.3273],
            [-100.2883, 47.6465],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3807950',
        NAME: 'Goodrich Public School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 656486771,
        AWATER: 28692869,
        INTPTLAT: '+47.4853459',
        INTPTLON: '-100.1893448',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.601, 48.4641],
            [-97.4588, 48.543],
            [-97.355, 48.5429],
            [-97.3327, 48.4632],
            [-97.1374, 48.4134],
            [-97.1456, 48.358],
            [-97.5689, 48.3467],
            [-97.601, 48.4641],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3808060',
        NAME: 'Grafton Public School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517599469,
        AWATER: 1552487,
        INTPTLAT: '+48.4298704',
        INTPTLON: '-097.3887268',
        ELSDLEA: '',
        UNSDLEA: '08060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.239, 47.9551],
            [-97.1963, 47.9691],
            [-97.1747, 47.9914],
            [-97.0534, 47.9913],
            [-96.9834, 47.8053],
            [-97.1103, 47.8026],
            [-97.1099, 47.9112],
            [-97.2388, 47.9112],
            [-97.239, 47.9551],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3808130',
        NAME: 'Grand Forks Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204417884,
        AWATER: 1052127,
        INTPTLAT: '+47.8968645',
        INTPTLON: '-097.1008327',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0491, 48.9996],
            [-103.8592, 48.9996],
            [-103.9474, 48.9743],
            [-103.9448, 48.8078],
            [-103.804, 48.8222],
            [-103.5368, 48.7206],
            [-103.5588, 48.6483],
            [-103.4286, 48.6334],
            [-103.4288, 48.5464],
            [-103.6677, 48.5464],
            [-103.6677, 48.4607],
            [-103.7981, 48.4599],
            [-103.82, 48.3725],
            [-104.0468, 48.3526],
            [-104.047, 48.3893],
            [-104.0479, 48.621],
            [-104.0491, 48.9996],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3808460',
        NAME: 'Grenora Public School District 99',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1690614700,
        AWATER: 43079433,
        INTPTLAT: '+48.6310593',
        INTPTLON: '-103.8298787',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.4605, 47.5013],
            [-102.4385, 47.4611],
            [-102.2057, 47.4798],
            [-102.2057, 47.3284],
            [-102.2716, 47.3281],
            [-102.2718, 47.1694],
            [-102.3026, 47.126],
            [-102.5252, 47.17],
            [-102.5459, 47.372],
            [-102.5143, 47.5014],
            [-102.4605, 47.5013],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3808610',
        NAME: 'Halliday Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 810455954,
        AWATER: 3549324,
        INTPTLAT: '+47.3096409',
        INTPTLON: '-102.3875657',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0535, 46.0225],
            [-97.0122, 46.0661],
            [-97.0108, 46.1819],
            [-96.9482, 46.2265],
            [-96.71, 46.1092],
            [-96.7007, 46.0225],
            [-96.7638, 45.9358],
            [-96.8523, 45.9359],
            [-97.075, 45.9358],
            [-97.0535, 46.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3808710',
        NAME: 'Hankinson Public School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 617232909,
        AWATER: 10616309,
        INTPTLAT: '+46.0444584',
        INTPTLON: '-096.8926566',
        ELSDLEA: '',
        UNSDLEA: '08710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.7723, 48.0723],
              [-99.7616, 48.0794],
              [-99.7718, 48.0605],
              [-99.7723, 48.0723],
            ],
          ],
          [
            [
              [-100.0691, 47.9921],
              [-99.9407, 47.9926],
              [-99.9002, 48.036],
              [-99.842, 48.1084],
              [-99.8158, 48.0504],
              [-99.7718, 48.0605],
              [-99.7938, 48.0281],
              [-99.7696, 48.0139],
              [-99.7741, 47.9665],
              [-99.7049, 47.934],
              [-99.6833, 47.8367],
              [-99.7163, 47.7526],
              [-99.7478, 47.7093],
              [-99.7909, 47.7313],
              [-99.8416, 47.6734],
              [-99.8317, 47.5882],
              [-100.0329, 47.5318],
              [-100.0329, 47.646],
              [-100.1606, 47.6463],
              [-100.1984, 47.6737],
              [-100.198, 47.8476],
              [-100.0689, 47.912],
              [-100.0691, 47.9921],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3808890',
        NAME: 'Harvey Public School District 38',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1448171157,
        AWATER: 31358325,
        INTPTLAT: '+47.8111230',
        INTPTLON: '-099.9303453',
        ELSDLEA: '',
        UNSDLEA: '08890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7188, 47.6359],
            [-97.5069, 47.6721],
            [-97.4957, 47.7154],
            [-97.239, 47.7157],
            [-97.2709, 47.6722],
            [-97.2714, 47.6495],
            [-97.4107, 47.5563],
            [-97.6222, 47.5566],
            [-97.7188, 47.6359],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809000',
        NAME: 'Hatton Public School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410739272,
        AWATER: 2983406,
        INTPTLAT: '+47.6393243',
        INTPTLON: '-097.4688954',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6619, 46.6345],
            [-100.5851, 46.6349],
            [-100.4595, 46.6343],
            [-100.4574, 46.7943],
            [-100.3323, 46.7209],
            [-100.1642, 46.7204],
            [-100.038, 46.6912],
            [-100.0217, 46.6179],
            [-100.0638, 46.5105],
            [-100.0432, 46.4144],
            [-100.0433, 46.3708],
            [-100.463, 46.3713],
            [-100.5045, 46.459],
            [-100.5985, 46.4444],
            [-100.5446, 46.5338],
            [-100.6619, 46.6345],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809130',
        NAME: 'Hazelton-Moffit-Braddock Public School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1423585088,
        AWATER: 34137461,
        INTPTLAT: '+46.5599780',
        INTPTLON: '-100.3162841',
        ELSDLEA: '',
        UNSDLEA: '09130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1445, 47.1553],
            [-102.0178, 47.1548],
            [-101.9123, 47.0938],
            [-101.8702, 47.0247],
            [-101.9288, 46.8699],
            [-102.0016, 46.8046],
            [-102.0129, 46.6452],
            [-102.0931, 46.631],
            [-102.1798, 46.631],
            [-102.1811, 46.8842],
            [-102.2182, 47.0246],
            [-102.1447, 47.0681],
            [-102.1445, 47.1553],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809180',
        NAME: 'Hebron Public School District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1009191666,
        AWATER: 2638067,
        INTPTLAT: '+46.9037843',
        INTPTLON: '-102.0590239',
        ELSDLEA: '',
        UNSDLEA: '09180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9125, 46.2816],
            [-102.6736, 46.2828],
            [-102.5915, 46.2493],
            [-102.1026, 46.2057],
            [-101.9979, 46.2055],
            [-101.9986, 46.0535],
            [-101.9986, 45.9447],
            [-102.9421, 45.945],
            [-102.9538, 45.945],
            [-102.9333, 46.0467],
            [-102.8297, 46.1044],
            [-102.9543, 46.1624],
            [-102.9125, 46.2816],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809410',
        NAME: 'Hettinger Public School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2225580410,
        AWATER: 2213517,
        INTPTLAT: '+46.0970300',
        INTPTLON: '-102.4871903',
        ELSDLEA: '',
        UNSDLEA: '09410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.092, 47.5125],
            [-96.8561, 47.4938],
            [-96.8581, 47.4477],
            [-96.8334, 47.238],
            [-96.9435, 47.2824],
            [-97.1126, 47.2388],
            [-97.2399, 47.324],
            [-97.1986, 47.4256],
            [-97.092, 47.5125],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809570',
        NAME: 'Hillsboro Public School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 707040917,
        AWATER: 1068276,
        INTPTLAT: '+47.3747464',
        INTPTLON: '-097.0172150',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.94, 47.2029],
              [-97.9187, 47.1968],
              [-97.9291, 47.1688],
              [-97.94, 47.2029],
            ],
          ],
          [
            [
              [-97.9009, 47.3549],
              [-97.8903, 47.4128],
              [-97.7399, 47.4193],
              [-97.6015, 47.4407],
              [-97.5913, 47.3391],
              [-97.5367, 47.268],
              [-97.6852, 47.2688],
              [-97.7272, 47.1818],
              [-97.8332, 47.1531],
              [-97.8751, 47.1386],
              [-97.8858, 47.1387],
              [-97.8971, 47.1531],
              [-97.9187, 47.1968],
              [-97.8975, 47.2112],
              [-97.8661, 47.2039],
              [-97.8118, 47.1964],
              [-97.8975, 47.2259],
              [-97.9189, 47.2296],
              [-97.9402, 47.2378],
              [-97.9664, 47.3405],
              [-97.9009, 47.3549],
            ],
            [
              [-97.8118, 47.1964],
              [-97.8333, 47.1965],
              [-97.8333, 47.1892],
              [-97.8118, 47.1964],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3809710',
        NAME: 'Hope Public School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 647408072,
        AWATER: 2248660,
        INTPTLAT: '+47.3060843',
        INTPTLON: '-097.7490773',
        ELSDLEA: '',
        UNSDLEA: '09710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3212, 48.7203],
            [-102.1525, 48.7203],
            [-102.1211, 48.8072],
            [-102.0214, 48.9373],
            [-102.0211, 48.999],
            [-101.9996, 48.999],
            [-101.8898, 48.8936],
            [-101.8806, 48.8],
            [-101.8425, 48.6768],
            [-101.7777, 48.5899],
            [-101.8092, 48.4595],
            [-101.9733, 48.4159],
            [-102.0604, 48.4594],
            [-102.3432, 48.5464],
            [-102.3212, 48.7203],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810180',
        NAME: 'Kenmare Public School District 28',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1536093303,
        AWATER: 38020946,
        INTPTLAT: '+48.6549431',
        INTPTLON: '-102.0277368',
        ELSDLEA: '',
        UNSDLEA: '10180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.5739, 47.3266],
              [-98.5419, 47.3266],
              [-98.5737, 47.3231],
              [-98.5739, 47.3266],
            ],
          ],
          [
            [
              [-98.8194, 47.4138],
              [-98.6489, 47.4136],
              [-98.5943, 47.3265],
              [-98.5737, 47.3231],
              [-98.6055, 47.2403],
              [-98.7212, 47.2115],
              [-98.7211, 47.1534],
              [-98.8477, 47.1824],
              [-98.8479, 47.2404],
              [-98.933, 47.313],
              [-98.8303, 47.3269],
              [-98.8194, 47.4138],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810260',
        NAME: 'Kensal Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452449964,
        AWATER: 15563022,
        INTPTLAT: '+47.2999841',
        INTPTLON: '-098.7400919',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7843, 47.6743],
            [-102.7853, 47.5944],
            [-102.4605, 47.5125],
            [-102.4605, 47.5013],
            [-102.5143, 47.5014],
            [-102.5459, 47.372],
            [-102.5252, 47.17],
            [-102.5254, 47.1114],
            [-102.9274, 47.1105],
            [-103.033, 47.1687],
            [-103.0326, 47.3287],
            [-103.0992, 47.3287],
            [-103.0999, 47.6744],
            [-102.7843, 47.6743],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810270',
        NAME: 'Killdeer Public School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2237948575,
        AWATER: 25848795,
        INTPTLAT: '+47.3841907',
        INTPTLON: '-102.8167334',
        ELSDLEA: '',
        UNSDLEA: '10270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2674, 46.4135],
            [-99.2674, 46.4279],
            [-99.2259, 46.3846],
            [-99.0579, 46.4137],
            [-99.0365, 46.5437],
            [-98.9128, 46.566],
            [-98.8717, 46.616],
            [-98.8086, 46.5079],
            [-98.8189, 46.5079],
            [-98.8086, 46.5004],
            [-98.8505, 46.4784],
            [-98.9017, 46.4647],
            [-98.9023, 46.4572],
            [-98.7758, 46.25],
            [-98.8178, 46.2003],
            [-98.8811, 46.04],
            [-98.9846, 46.0264],
            [-99.0037, 46.1137],
            [-99.1568, 46.2006],
            [-99.2534, 46.2006],
            [-99.2766, 46.2834],
            [-99.2674, 46.4135],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810500',
        NAME: 'Kulm Public School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1259060320,
        AWATER: 16991962,
        INTPTLAT: '+46.3042515',
        INTPTLON: '-099.0170431',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.607, 48.0935],
            [-98.5845, 48.1513],
            [-98.552, 48.1369],
            [-98.5734, 48.1659],
            [-98.5518, 48.2384],
            [-98.4219, 48.2817],
            [-98.1626, 48.2601],
            [-98.1626, 48.245],
            [-98.1953, 48.1302],
            [-98.1629, 48.079],
            [-98.2258, 47.9248],
            [-98.3227, 47.9187],
            [-98.5427, 47.9624],
            [-98.5828, 48.044],
            [-98.607, 48.0935],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810600',
        NAME: 'Lakota Public School District 66',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1014627294,
        AWATER: 45313658,
        INTPTLAT: '+48.0935250',
        INTPTLON: '-098.3791510',
        ELSDLEA: '',
        UNSDLEA: '10600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.4094, 46.1829],
              [-98.4093, 46.1684],
              [-98.4204, 46.1685],
              [-98.4094, 46.1829],
            ],
          ],
          [
            [
              [-98.5368, 46.4861],
              [-98.4106, 46.5218],
              [-98.327, 46.4781],
              [-98.2853, 46.5217],
              [-98.043, 46.5291],
              [-98.0341, 46.4566],
              [-98.0345, 46.3987],
              [-97.951, 46.3549],
              [-97.951, 46.3405],
              [-98.16, 46.3554],
              [-98.2538, 46.1967],
              [-98.379, 46.1462],
              [-98.3995, 46.1683],
              [-98.3968, 46.1683],
              [-98.4094, 46.1829],
              [-98.51, 46.2418],
              [-98.5239, 46.2418],
              [-98.5368, 46.4861],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810740',
        NAME: 'LaMoure Public School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1091600528,
        AWATER: 6405935,
        INTPTLAT: '+46.3845770',
        INTPTLON: '-098.2955849',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.869, 49.0002],
            [-98.2064, 49.0003],
            [-98.2162, 48.9493],
            [-98.0814, 48.8044],
            [-97.9277, 48.7177],
            [-97.9271, 48.5872],
            [-98.0572, 48.5433],
            [-98.1875, 48.5433],
            [-98.4055, 48.5581],
            [-98.536, 48.609],
            [-98.6442, 48.6885],
            [-98.7371, 48.8048],
            [-98.8252, 48.8047],
            [-98.869, 49.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810810',
        NAME: 'Langdon-Milton-Osnabrock School District 23',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2371818920,
        AWATER: 28412470,
        INTPTLAT: '+48.7832629',
        INTPTLON: '-098.3757497',
        ELSDLEA: '',
        UNSDLEA: '10810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8503, 48.0846],
              [-97.8502, 48.0787],
              [-97.8604, 48.0645],
              [-97.8503, 48.0846],
            ],
          ],
          [
            [
              [-97.8172, 48.079],
              [-97.7092, 48.0934],
              [-97.7089, 48.0789],
              [-97.5793, 48.0644],
              [-97.4073, 47.9764],
              [-97.4294, 47.9332],
              [-97.4304, 47.8294],
              [-97.6879, 47.7892],
              [-97.8077, 47.7882],
              [-97.8718, 47.8463],
              [-97.8737, 47.9291],
              [-97.7739, 48.0432],
              [-97.8172, 48.079],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810860',
        NAME: 'Larimore Public School District 44',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 868854912,
        AWATER: 1279146,
        INTPTLAT: '+47.9252951',
        INTPTLON: '-097.6517053',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6752, 48.3711],
            [-99.4933, 48.4514],
            [-99.3627, 48.437],
            [-99.1837, 48.3017],
            [-99.0489, 48.303],
            [-99.1572, 48.2238],
            [-99.1571, 48.1659],
            [-99.3082, 48.1706],
            [-99.3296, 48.1226],
            [-99.4591, 48.1145],
            [-99.5884, 48.1949],
            [-99.7183, 48.1584],
            [-99.6217, 48.1953],
            [-99.6752, 48.3711],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3810980',
        NAME: 'Leeds Public School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 978903244,
        AWATER: 37516516,
        INTPTLAT: '+48.2746908',
        INTPTLON: '-099.4302768',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3319, 46.1445],
            [-97.2594, 46.1955],
            [-97.0108, 46.1819],
            [-97.0122, 46.0661],
            [-97.0535, 46.0225],
            [-97.075, 45.9358],
            [-97.3119, 45.9357],
            [-97.2911, 45.9795],
            [-97.3165, 46.0505],
            [-97.322, 46.0506],
            [-97.333, 46.0757],
            [-97.3329, 46.0796],
            [-97.3319, 46.1445],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811140',
        NAME: 'Lidgerwood Public School District 28',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 577178797,
        AWATER: 14188551,
        INTPTLAT: '+46.0712287',
        INTPTLON: '-097.1671713',
        ELSDLEA: '',
        UNSDLEA: '11140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.951, 46.3549],
              [-97.9305, 46.3403],
              [-97.951, 46.3405],
              [-97.951, 46.3549],
            ],
          ],
          [
            [
              [-98.0341, 46.4566],
              [-97.9088, 46.4563],
              [-97.8052, 46.5437],
              [-97.6581, 46.5436],
              [-97.4897, 46.472],
              [-97.2802, 46.4561],
              [-97.3123, 46.3259],
              [-97.3435, 46.37],
              [-97.48, 46.3698],
              [-97.5113, 46.326],
              [-97.5901, 46.2824],
              [-97.881, 46.2825],
              [-97.9305, 46.3403],
              [-97.9302, 46.3838],
              [-98.0345, 46.3987],
              [-98.0341, 46.4566],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811430',
        NAME: 'Lisbon Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1112429511,
        AWATER: 1156239,
        INTPTLAT: '+46.4044674',
        INTPTLON: '-097.6688916',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.842, 48.1084],
            [-99.7183, 48.1584],
            [-99.5884, 48.1949],
            [-99.4591, 48.1145],
            [-99.4592, 48.0211],
            [-99.3622, 47.977],
            [-99.3188, 47.9336],
            [-99.3617, 47.8467],
            [-99.426, 47.7744],
            [-99.5973, 47.7976],
            [-99.6833, 47.8367],
            [-99.7049, 47.934],
            [-99.7741, 47.9665],
            [-99.7696, 48.0139],
            [-99.7938, 48.0281],
            [-99.7718, 48.0605],
            [-99.8158, 48.0504],
            [-99.842, 48.1084],
          ],
          [
            [-99.7718, 48.0605],
            [-99.7616, 48.0794],
            [-99.7723, 48.0723],
            [-99.7718, 48.0605],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811700',
        NAME: 'Maddock Public School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1012999147,
        AWATER: 14805291,
        INTPTLAT: '+47.9851423',
        INTPTLON: '-099.5858572',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2192, 46.9814],
            [-100.936, 46.9828],
            [-100.8191, 46.7924],
            [-100.7888, 46.6917],
            [-100.7121, 46.6575],
            [-100.6619, 46.6345],
            [-100.5446, 46.5338],
            [-100.5985, 46.4444],
            [-100.5902, 46.4279],
            [-100.7251, 46.5159],
            [-100.8611, 46.5305],
            [-100.8707, 46.6237],
            [-100.9533, 46.6906],
            [-100.9861, 46.6321],
            [-101.1941, 46.631],
            [-101.2153, 46.66],
            [-101.2169, 46.7172],
            [-101.1961, 46.7755],
            [-101.0822, 46.7707],
            [-101.09, 46.8482],
            [-101.2185, 46.848],
            [-101.2192, 46.9814],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811820',
        NAME: 'Mandan Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1296888015,
        AWATER: 42396606,
        INTPTLAT: '+46.7279941',
        INTPTLON: '-100.9303764',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7843, 47.6743],
            [-102.7847, 47.862],
            [-102.6562, 47.8618],
            [-102.562, 47.7801],
            [-102.3858, 47.758],
            [-102.2616, 47.7243],
            [-102.2635, 47.6196],
            [-102.4365, 47.5698],
            [-102.4605, 47.5125],
            [-102.7853, 47.5944],
            [-102.7843, 47.6743],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3811850',
        NAME: 'Mandaree Public School District 36',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 919239979,
        AWATER: 123715493,
        INTPTLAT: '+47.6862822',
        INTPTLON: '-102.5558171',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8749, 47.1241],
            [-97.823, 47.1453],
            [-97.7907, 47.131],
            [-97.8013, 47.1237],
            [-97.7906, 47.1237],
            [-97.6425, 47.0218],
            [-97.4095, 47.0359],
            [-97.4299, 46.8245],
            [-97.5354, 46.796],
            [-97.6817, 46.8039],
            [-97.7151, 46.688],
            [-97.8921, 46.6883],
            [-97.934, 46.7319],
            [-97.8921, 46.8332],
            [-97.892, 46.942],
            [-97.9599, 47.0007],
            [-97.9601, 47.0806],
            [-97.8749, 47.1241],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812020',
        NAME: 'Maple Valley Public School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1305281083,
        AWATER: 1669364,
        INTPTLAT: '+46.9128547',
        INTPTLON: '-097.7119377',
        ELSDLEA: '',
        UNSDLEA: '12020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5188, 47.9349],
            [-101.4334, 47.9641],
            [-101.2285, 47.9354],
            [-101.0996, 47.9065],
            [-101.0999, 47.8484],
            [-100.9713, 47.8487],
            [-100.9182, 47.7759],
            [-101.1003, 47.6744],
            [-101.2953, 47.6883],
            [-101.4651, 47.8482],
            [-101.5817, 47.8624],
            [-101.5188, 47.9349],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812350',
        NAME: 'Max Public School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 869291043,
        AWATER: 11688914,
        INTPTLAT: '+47.8254348',
        INTPTLON: '-101.2390310',
        ELSDLEA: '',
        UNSDLEA: '12350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.6496, 47.6741],
            [-100.585, 47.7037],
            [-100.4563, 47.674],
            [-100.349, 47.7317],
            [-100.2883, 47.6465],
            [-100.2894, 47.3273],
            [-100.4535, 47.3275],
            [-100.6733, 47.3274],
            [-100.6296, 47.5017],
            [-100.6496, 47.6741],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812430',
        NAME: 'McClusky Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1086755607,
        AWATER: 18339543,
        INTPTLAT: '+47.5138098',
        INTPTLON: '-100.4639154',
        ELSDLEA: '',
        UNSDLEA: '12430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7358, 47.5894],
            [-103.7364, 47.6474],
            [-103.5398, 47.6746],
            [-103.5405, 47.8923],
            [-103.499, 48.0056],
            [-103.2773, 48.058],
            [-103.2019, 48.1352],
            [-103.1071, 48.1465],
            [-102.8285, 48.1237],
            [-102.7848, 47.9988],
            [-102.7847, 47.862],
            [-102.7843, 47.6743],
            [-103.0999, 47.6744],
            [-103.0992, 47.3287],
            [-103.6667, 47.3294],
            [-103.7377, 47.3294],
            [-103.7358, 47.5894],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812540',
        NAME: 'McKenzie County Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4239946226,
        AWATER: 119928024,
        INTPTLAT: '+47.7263684',
        INTPTLON: '-103.2270414',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.1319, 46.841],
              [-99.0691, 46.7901],
              [-99.132, 46.819],
              [-99.1319, 46.841],
            ],
          ],
          [
            [
              [-99.3545, 47.099],
              [-99.3127, 47.1765],
              [-99.2701, 47.1958],
              [-99.2915, 47.1537],
              [-99.1008, 47.0666],
              [-99.0379, 46.9794],
              [-99.0683, 46.8485],
              [-99.1208, 46.8481],
              [-99.1318, 46.8551],
              [-99.143, 46.8481],
              [-99.1431, 46.8404],
              [-99.132, 46.819],
              [-99.1549, 46.7766],
              [-99.3534, 46.762],
              [-99.4476, 46.7987],
              [-99.4464, 46.9809],
              [-99.4182, 47.0736],
              [-99.3545, 47.099],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812660',
        NAME: 'Medina Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 965707067,
        AWATER: 69545577,
        INTPTLAT: '+46.9499949',
        INTPTLON: '-099.2562665',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6008, 48.2383],
            [-97.5682, 48.2597],
            [-97.2976, 48.2088],
            [-97.3199, 48.078],
            [-97.2389, 47.9764],
            [-97.3642, 47.973],
            [-97.4073, 47.9764],
            [-97.5793, 48.0644],
            [-97.7089, 48.0789],
            [-97.7092, 48.0934],
            [-97.7306, 48.1948],
            [-97.6008, 48.2383],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812920',
        NAME: 'Midway Public School District 128',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755271031,
        AWATER: 3396457,
        INTPTLAT: '+48.1256631',
        INTPTLON: '-097.4808170',
        ELSDLEA: '',
        UNSDLEA: '12920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5901, 46.2824],
            [-97.5113, 46.326],
            [-97.48, 46.3698],
            [-97.3435, 46.37],
            [-97.3123, 46.3259],
            [-97.2594, 46.1955],
            [-97.3319, 46.1445],
            [-97.478, 46.1236],
            [-97.5703, 46.1887],
            [-97.5901, 46.2824],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812930',
        NAME: 'Milnor Public School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 479389839,
        AWATER: 6553616,
        INTPTLAT: '+46.2307901',
        INTPTLON: '-097.4142394',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4591, 48.1145],
            [-99.3296, 48.1226],
            [-99.3082, 48.1706],
            [-99.1571, 48.1659],
            [-99.0373, 48.0471],
            [-99.1248, 47.9939],
            [-99.3622, 47.977],
            [-99.4592, 48.0211],
            [-99.4591, 48.1145],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3812990',
        NAME: 'Minnewaukan Public School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461722505,
        AWATER: 31519032,
        INTPTLAT: '+48.0770615',
        INTPTLON: '-099.2603293',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3953, 48.267],
            [-101.3892, 48.2658],
            [-101.3394, 48.2762],
            [-101.3795, 48.3718],
            [-101.1437, 48.3715],
            [-101.1658, 48.2836],
            [-101.2742, 48.2834],
            [-101.2527, 48.2366],
            [-101.2669, 48.1964],
            [-101.1446, 48.1967],
            [-101.1114, 48.1387],
            [-101.2743, 48.1243],
            [-101.2741, 48.1965],
            [-101.4038, 48.1968],
            [-101.3964, 48.2507],
            [-101.3953, 48.267],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813030',
        NAME: 'Minot Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350805188,
        AWATER: 2701560,
        INTPTLAT: '+48.3196891',
        INTPTLON: '-101.2847733',
        ELSDLEA: '',
        UNSDLEA: '13030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5689, 48.3467],
            [-97.1456, 48.358],
            [-97.1498, 48.2181],
            [-97.2976, 48.2088],
            [-97.5682, 48.2597],
            [-97.5689, 48.3467],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813050',
        NAME: 'Minto Public School District 20',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406800565,
        AWATER: 3785102,
        INTPTLAT: '+48.2912299',
        INTPTLON: '-097.3330267',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9652, 47.1566],
            [-100.8971, 47.1716],
            [-100.7917, 47.3272],
            [-100.6733, 47.3274],
            [-100.4535, 47.3275],
            [-100.4532, 47.0839],
            [-100.5377, 47.0696],
            [-100.5378, 46.9825],
            [-100.5807, 46.9824],
            [-100.6857, 46.9828],
            [-100.8807, 47.041],
            [-100.9652, 47.1566],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813200',
        NAME: 'Wilton Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1101519725,
        AWATER: 8392996,
        INTPTLAT: '+47.1600232',
        INTPTLON: '-100.6782762',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.903, 46.7179],
            [-98.6919, 46.7181],
            [-98.5449, 46.7613],
            [-98.481, 46.8121],
            [-98.4603, 46.8196],
            [-98.4392, 46.8487],
            [-98.4602, 46.6311],
            [-98.5513, 46.5729],
            [-98.6524, 46.5945],
            [-98.6622, 46.6309],
            [-98.8719, 46.6307],
            [-98.903, 46.7179],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813230',
        NAME: 'Montpelier Public School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559713267,
        AWATER: 938644,
        INTPTLAT: '+46.6952206',
        INTPTLON: '-098.6051435',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8543, 48.8056],
            [-99.6575, 48.8054],
            [-99.6593, 48.8923],
            [-99.6573, 48.9992],
            [-99.11, 48.9999],
            [-99.1319, 48.7325],
            [-99.2849, 48.7761],
            [-99.5258, 48.7762],
            [-99.5587, 48.6463],
            [-99.7012, 48.7619],
            [-99.8543, 48.8056],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813400',
        NAME: 'Mount Pleasant Public School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1204778893,
        AWATER: 15555737,
        INTPTLAT: '+48.8701338',
        INTPTLON: '-099.4058135',
        ELSDLEA: '',
        UNSDLEA: '13400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.11, 48.9999],
            [-98.869, 49.0002],
            [-98.8252, 48.8047],
            [-98.7371, 48.8048],
            [-98.6442, 48.6885],
            [-98.536, 48.609],
            [-98.5793, 48.5651],
            [-98.7096, 48.5584],
            [-98.7315, 48.5584],
            [-98.7529, 48.5583],
            [-98.9704, 48.5442],
            [-98.9984, 48.6663],
            [-99.0006, 48.7471],
            [-99.1319, 48.7325],
            [-99.11, 48.9999],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813440',
        NAME: 'Munich Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1160277198,
        AWATER: 25701179,
        INTPTLAT: '+48.7558277',
        INTPTLON: '-098.8730256',
        ELSDLEA: '',
        UNSDLEA: '13440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0217, 46.6179],
            [-99.8284, 46.6325],
            [-99.828, 46.7196],
            [-99.6693, 46.7192],
            [-99.6013, 46.632],
            [-99.5183, 46.5745],
            [-99.372, 46.5218],
            [-99.4767, 46.4859],
            [-99.7071, 46.3628],
            [-99.7285, 46.297],
            [-99.8784, 46.2828],
            [-99.9169, 46.3706],
            [-100.0432, 46.4144],
            [-100.0638, 46.5105],
            [-100.0217, 46.6179],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813510',
        NAME: 'Napoleon Public School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1408306708,
        AWATER: 18892289,
        INTPTLAT: '+46.5015495',
        INTPTLON: '-099.7767245',
        ELSDLEA: '',
        UNSDLEA: '13510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.2766, 48.5464],
            [-103.3419, 48.6333],
            [-103.103, 48.6333],
            [-103.0822, 48.3906],
            [-103.0224, 48.342],
            [-103.0227, 48.2116],
            [-103.0872, 48.1687],
            [-103.4698, 48.198],
            [-103.4694, 48.3727],
            [-103.407, 48.4596],
            [-103.277, 48.4595],
            [-103.2766, 48.5464],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813710',
        NAME: 'Nesson Public School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1224159049,
        AWATER: 3738219,
        INTPTLAT: '+48.3647070',
        INTPTLON: '-103.2272472',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8285, 48.1237],
            [-102.6988, 48.1572],
            [-102.548, 48.197],
            [-102.5478, 48.1246],
            [-102.2459, 48.11],
            [-102.2576, 47.9241],
            [-102.3857, 47.8463],
            [-102.3858, 47.758],
            [-102.562, 47.7801],
            [-102.6562, 47.8618],
            [-102.7847, 47.862],
            [-102.7848, 47.9988],
            [-102.8285, 48.1237],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3813920',
        NAME: 'New Town Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1134189755,
        AWATER: 230531733,
        INTPTLAT: '+47.9822326',
        INTPTLON: '-102.5405541',
        ELSDLEA: '',
        UNSDLEA: '13920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.881, 46.2825],
            [-97.5901, 46.2824],
            [-97.5703, 46.1887],
            [-97.8827, 46.1608],
            [-97.881, 46.2825],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814290',
        NAME: 'North Sargent Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302538976,
        AWATER: 1497445,
        INTPTLAT: '+46.2269776',
        INTPTLON: '-097.7274073',
        ELSDLEA: '',
        UNSDLEA: '14290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9038, 47.7953],
            [-97.8718, 47.8463],
            [-97.8077, 47.7882],
            [-97.6879, 47.7892],
            [-97.4304, 47.8294],
            [-97.3249, 47.8028],
            [-97.2818, 47.7803],
            [-97.2818, 47.7737],
            [-97.239, 47.7302],
            [-97.2606, 47.7303],
            [-97.239, 47.7223],
            [-97.239, 47.7157],
            [-97.4957, 47.7154],
            [-97.5069, 47.6721],
            [-97.7188, 47.6359],
            [-97.8824, 47.6722],
            [-97.914, 47.7447],
            [-97.9254, 47.7447],
            [-97.9038, 47.7953],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814340',
        NAME: 'Northwood Public School District 129',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 659849354,
        AWATER: 819638,
        INTPTLAT: '+47.7507389',
        INTPTLON: '-097.6097221',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0345, 46.3987],
              [-97.9302, 46.3838],
              [-97.9305, 46.3403],
              [-97.951, 46.3549],
              [-98.0345, 46.3987],
            ],
          ],
          [
            [
              [-98.4204, 46.1685],
              [-98.4093, 46.1684],
              [-98.3995, 46.1683],
              [-98.379, 46.1462],
              [-98.2538, 46.1967],
              [-98.16, 46.3554],
              [-97.951, 46.3405],
              [-97.9305, 46.3403],
              [-97.881, 46.2825],
              [-97.8827, 46.1608],
              [-97.936, 46.0589],
              [-98.008, 46.0298],
              [-98.0081, 45.9359],
              [-98.2783, 45.9361],
              [-98.2973, 46.0587],
              [-98.4204, 46.1685],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814500',
        NAME: 'Oakes Public School District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1268678563,
        AWATER: 10438435,
        INTPTLAT: '+46.1493889',
        INTPTLON: '-098.1056711',
        ELSDLEA: '',
        UNSDLEA: '14500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7907, 47.131],
              [-97.7906, 47.1237],
              [-97.8013, 47.1237],
              [-97.7907, 47.131],
            ],
          ],
          [
            [
              [-97.5367, 47.268],
              [-97.4514, 47.1954],
              [-97.3875, 47.1593],
              [-97.4201, 47.0647],
              [-97.4095, 47.0359],
              [-97.6425, 47.0218],
              [-97.7906, 47.1237],
              [-97.7907, 47.131],
              [-97.823, 47.1453],
              [-97.8332, 47.1531],
              [-97.7272, 47.1818],
              [-97.6852, 47.2688],
              [-97.5367, 47.268],
            ],
          ],
          [
            [
              [-97.8751, 47.1386],
              [-97.8332, 47.1531],
              [-97.823, 47.1453],
              [-97.8749, 47.1241],
              [-97.8751, 47.1386],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814730',
        NAME: 'Page Public School District 80',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619392780,
        AWATER: 766199,
        INTPTLAT: '+47.1422373',
        INTPTLON: '-097.5904735',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9709, 48.4489],
            [-97.7618, 48.4804],
            [-97.7429, 48.4803],
            [-97.6996, 48.4921],
            [-97.6771, 48.4707],
            [-97.601, 48.4641],
            [-97.5689, 48.3467],
            [-97.5682, 48.2597],
            [-97.6008, 48.2383],
            [-97.6872, 48.2673],
            [-97.8499, 48.2819],
            [-97.9683, 48.3398],
            [-97.9709, 48.4489],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814880',
        NAME: 'Park River Public School District 78',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 578331121,
        AWATER: 737111,
        INTPTLAT: '+48.3776901',
        INTPTLON: '-097.7460637',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2459, 48.11],
            [-102.1165, 48.1316],
            [-102.1164, 48.0231],
            [-101.9795, 47.9779],
            [-101.9795, 47.8693],
            [-101.8719, 47.8331],
            [-101.8934, 47.7608],
            [-102.001, 47.7318],
            [-102.0006, 47.674],
            [-102.1294, 47.6735],
            [-102.2616, 47.7243],
            [-102.3858, 47.758],
            [-102.3857, 47.8463],
            [-102.2576, 47.9241],
            [-102.2459, 48.11],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3814940',
        NAME: 'Parshall Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 937062639,
        AWATER: 97259624,
        INTPTLAT: '+47.8824055',
        INTPTLON: '-102.1379051',
        ELSDLEA: '',
        UNSDLEA: '14940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.7423, 46.9939],
              [-98.7319, 46.994],
              [-98.7316, 46.9864],
              [-98.7423, 46.9864],
              [-98.7423, 46.9939],
            ],
          ],
          [
            [
              [-98.933, 47.313],
              [-98.8479, 47.2404],
              [-98.8477, 47.1824],
              [-98.7211, 47.1534],
              [-98.6791, 47.0809],
              [-98.7319, 46.994],
              [-98.7423, 46.9939],
              [-98.7423, 46.9864],
              [-98.8372, 46.9867],
              [-99.0379, 46.9794],
              [-99.1008, 47.0666],
              [-99.1231, 47.1824],
              [-99.1011, 47.2983],
              [-98.933, 47.313],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3815150',
        NAME: 'Pingree-Buchanan Public School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 835906499,
        AWATER: 26036606,
        INTPTLAT: '+47.1279803',
        INTPTLON: '-098.9247808',
        ELSDLEA: '',
        UNSDLEA: '15150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1028, 48.6904],
            [-100.0078, 48.7188],
            [-99.9201, 48.8057],
            [-99.8543, 48.8056],
            [-99.7012, 48.7619],
            [-99.5587, 48.6463],
            [-99.5586, 48.6028],
            [-99.754, 48.5448],
            [-99.8847, 48.5449],
            [-100.0592, 48.5741],
            [-100.1028, 48.6904],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816050',
        NAME: 'Rolette Public School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 715074357,
        AWATER: 24392819,
        INTPTLAT: '+48.6644610',
        INTPTLON: '-099.8436786',
        ELSDLEA: '',
        UNSDLEA: '16050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6752, 46.5887],
            [-101.4244, 46.6028],
            [-101.3827, 46.5744],
            [-101.3407, 46.4745],
            [-101.4242, 46.4221],
            [-101.4381, 46.2191],
            [-101.2986, 46.1172],
            [-101.354, 46.0746],
            [-101.6242, 46.0365],
            [-101.6874, 46.1322],
            [-101.6752, 46.5887],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816090',
        NAME: 'Roosevelt Public School District 18',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1325181747,
        AWATER: 665224,
        INTPTLAT: '+46.3243764',
        INTPTLON: '-101.5186284',
        ELSDLEA: '',
        UNSDLEA: '16090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2221, 48.545],
            [-100.0592, 48.5741],
            [-99.8847, 48.5449],
            [-99.8853, 48.4581],
            [-99.7687, 48.4441],
            [-99.6752, 48.3711],
            [-99.6217, 48.1953],
            [-99.7183, 48.1584],
            [-99.842, 48.1084],
            [-99.9002, 48.036],
            [-99.9407, 47.9926],
            [-100.0691, 47.9921],
            [-100.2369, 48.0362],
            [-100.2371, 48.1962],
            [-100.2373, 48.3712],
            [-100.2767, 48.5448],
            [-100.2221, 48.545],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816130',
        NAME: 'Rugby Public School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1987156295,
        AWATER: 130152903,
        INTPTLAT: '+48.2964583',
        INTPTLON: '-100.0221657',
        ELSDLEA: '',
        UNSDLEA: '16130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8827, 46.1608],
            [-97.5703, 46.1887],
            [-97.478, 46.1236],
            [-97.3319, 46.1445],
            [-97.3329, 46.0796],
            [-97.333, 46.0757],
            [-97.322, 46.0506],
            [-97.3165, 46.0505],
            [-97.2911, 45.9795],
            [-97.3119, 45.9357],
            [-97.5326, 45.9352],
            [-98.0081, 45.9359],
            [-98.008, 46.0298],
            [-97.936, 46.0589],
            [-97.8827, 46.1608],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816430',
        NAME: 'Sargent Central Public School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1215744279,
        AWATER: 10909087,
        INTPTLAT: '+46.0434523',
        INTPTLON: '-097.6507333',
        ELSDLEA: '',
        UNSDLEA: '16430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2743, 48.1243],
            [-101.1114, 48.1387],
            [-101.0146, 48.175],
            [-101.0139, 48.008],
            [-100.9708, 47.9564],
            [-100.9713, 47.8487],
            [-101.0999, 47.8484],
            [-101.0996, 47.9065],
            [-101.2285, 47.9354],
            [-101.2312, 48.0303],
            [-101.2743, 48.1243],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816470',
        NAME: 'Sawyer Public School District 16',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 509750904,
        AWATER: 3452618,
        INTPTLAT: '+48.0066475',
        INTPTLON: '-101.1123247',
        ELSDLEA: '',
        UNSDLEA: '16470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9986, 46.0535],
            [-101.8618, 46.0648],
            [-101.6776, 46.0129],
            [-101.6242, 46.0365],
            [-101.354, 46.0746],
            [-101.2986, 46.1172],
            [-101.2503, 46.1363],
            [-100.8774, 46.1174],
            [-100.8681, 45.9656],
            [-100.7938, 45.9438],
            [-101.0798, 45.9436],
            [-101.7212, 45.9443],
            [-101.9986, 45.9447],
            [-101.9986, 46.0535],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816510',
        NAME: 'Selfridge Public School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1263773368,
        AWATER: 731283,
        INTPTLAT: '+45.9822930',
        INTPTLON: '-101.3633291',
        ELSDLEA: '',
        UNSDLEA: '16510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.903, 46.5161],
            [-100.8714, 46.5305],
            [-100.8611, 46.5305],
            [-100.7251, 46.5159],
            [-100.5902, 46.4279],
            [-100.5492, 46.3293],
            [-100.5601, 46.2338],
            [-100.7522, 46.2338],
            [-100.8772, 46.2047],
            [-100.8766, 46.2848],
            [-101.0257, 46.2848],
            [-100.9189, 46.395],
            [-100.8149, 46.3847],
            [-100.903, 46.5161],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3816980',
        NAME: 'Solen Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 717003915,
        AWATER: 20610054,
        INTPTLAT: '+46.3193979',
        INTPTLON: '-100.7513126',
        ELSDLEA: '',
        UNSDLEA: '16980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0146, 48.175],
            [-101.0145, 48.1966],
            [-100.8857, 48.1964],
            [-100.6692, 48.1563],
            [-100.4963, 48.1673],
            [-100.4858, 48.1091],
            [-100.5613, 48.0803],
            [-100.6928, 47.9422],
            [-100.585, 47.9201],
            [-100.585, 47.7037],
            [-100.6496, 47.6741],
            [-100.757, 47.7031],
            [-100.7999, 47.7612],
            [-100.9182, 47.7759],
            [-100.9713, 47.8487],
            [-100.9708, 47.9564],
            [-101.0139, 48.008],
            [-101.0146, 48.175],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817040',
        NAME: 'Velva Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1485237113,
        AWATER: 21367274,
        INTPTLAT: '+47.9713813',
        INTPTLON: '-100.7892349',
        ELSDLEA: '',
        UNSDLEA: '17040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.1055, 46.9805],
            [-103.0327, 46.9806],
            [-103.033, 47.1687],
            [-102.9274, 47.1105],
            [-102.9057, 46.9808],
            [-102.8516, 46.9807],
            [-102.8517, 46.7164],
            [-102.9368, 46.6802],
            [-103.1054, 46.6878],
            [-103.1055, 46.9805],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817130',
        NAME: 'South Heart Public School District 9',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 780147927,
        AWATER: 3621393,
        INTPTLAT: '+46.8821639',
        INTPTLON: '-102.9882823',
        ELSDLEA: '',
        UNSDLEA: '17130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9651, 48.9991],
            [-99.6573, 48.9992],
            [-99.6593, 48.8923],
            [-99.9199, 48.8924],
            [-99.9651, 48.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817460',
        NAME: 'St. John Public School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252091991,
        AWATER: 26166827,
        INTPTLAT: '+48.9436386',
        INTPTLON: '-099.7971085',
        ELSDLEA: '',
        UNSDLEA: '17460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.32, 48.6951],
            [-97.355, 48.5429],
            [-97.4588, 48.543],
            [-97.5462, 48.5721],
            [-97.5679, 48.6592],
            [-97.5232, 48.7178],
            [-97.32, 48.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817520',
        NAME: 'St. Thomas Public School District 43',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293582505,
        AWATER: 80944,
        INTPTLAT: '+48.6375267',
        INTPTLON: '-097.4358513',
        ELSDLEA: '',
        UNSDLEA: '17520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.6908, 48.3725],
            [-102.6255, 48.3725],
            [-102.5704, 48.5031],
            [-102.3432, 48.5464],
            [-102.0604, 48.4594],
            [-101.9733, 48.4159],
            [-101.9869, 48.2837],
            [-102.1166, 48.1898],
            [-102.1165, 48.1316],
            [-102.2459, 48.11],
            [-102.5478, 48.1246],
            [-102.548, 48.197],
            [-102.6988, 48.1572],
            [-102.7639, 48.2406],
            [-102.6988, 48.2838],
            [-102.6908, 48.3725],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817570',
        NAME: 'Stanley Public School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1931025634,
        AWATER: 56864441,
        INTPTLAT: '+48.3195286',
        INTPTLON: '-102.3631399',
        ELSDLEA: '',
        UNSDLEA: '17570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9704, 48.5442],
            [-98.7529, 48.5583],
            [-98.7315, 48.5584],
            [-98.7096, 48.5584],
            [-98.6663, 48.5004],
            [-98.681, 48.3325],
            [-98.756, 48.3544],
            [-98.8697, 48.2819],
            [-99.0489, 48.303],
            [-99.1837, 48.3017],
            [-99.0938, 48.3706],
            [-99.0141, 48.3705],
            [-98.9704, 48.5442],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817670',
        NAME: 'Starkweather Public School District 44',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 714453921,
        AWATER: 36729719,
        INTPTLAT: '+48.4247362',
        INTPTLON: '-098.8476165',
        ELSDLEA: '',
        UNSDLEA: '17670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1437, 48.3715],
            [-101.0385, 48.4295],
            [-100.9294, 48.4586],
            [-101.0142, 48.3125],
            [-101.0145, 48.1966],
            [-101.0146, 48.175],
            [-101.1114, 48.1387],
            [-101.1446, 48.1967],
            [-101.1658, 48.2836],
            [-101.1437, 48.3715],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3817910',
        NAME: 'Surrey Public School District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328571781,
        AWATER: 550779,
        INTPTLAT: '+48.3050766',
        INTPTLON: '-101.0465126',
        ELSDLEA: '',
        UNSDLEA: '17910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2606, 47.7303],
              [-97.239, 47.7302],
              [-97.239, 47.7223],
              [-97.2606, 47.7303],
            ],
          ],
          [
            [
              [-97.3249, 47.8028],
              [-97.239, 47.8173],
              [-97.2388, 47.9112],
              [-97.1099, 47.9112],
              [-97.1103, 47.8026],
              [-96.9834, 47.8053],
              [-96.9765, 47.8037],
              [-96.9723, 47.8028],
              [-96.9384, 47.7611],
              [-96.9379, 47.7602],
              [-97.0987, 47.7156],
              [-97.239, 47.7302],
              [-97.2818, 47.7737],
              [-97.2818, 47.7803],
              [-97.3249, 47.8028],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3818280',
        NAME: 'Thompson Public School District 61',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305278966,
        AWATER: 514268,
        INTPTLAT: '+47.8110155',
        INTPTLON: '-097.1437202',
        ELSDLEA: '',
        UNSDLEA: '18280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1003, 47.6744],
            [-100.9182, 47.7759],
            [-100.7999, 47.7612],
            [-100.757, 47.7031],
            [-100.6496, 47.6741],
            [-100.6296, 47.5017],
            [-100.6733, 47.3274],
            [-100.7917, 47.3272],
            [-100.9828, 47.4075],
            [-101.0038, 47.4584],
            [-101.0685, 47.5745],
            [-101.2286, 47.589],
            [-101.1003, 47.6744],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3818500',
        NAME: 'Turtle Lake-Mercer Public School District 72',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1264873321,
        AWATER: 66977446,
        INTPTLAT: '+47.5477890',
        INTPTLON: '-100.8489070',
        ELSDLEA: '',
        UNSDLEA: '18500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5261, 47.5479],
            [-101.3563, 47.5889],
            [-101.2286, 47.589],
            [-101.0685, 47.5745],
            [-101.0038, 47.4584],
            [-101.1637, 47.3569],
            [-101.3582, 47.3423],
            [-101.4063, 47.416],
            [-101.4213, 47.5021],
            [-101.5261, 47.5479],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3818660',
        NAME: 'Underwood Public School District 8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 607719525,
        AWATER: 98620804,
        INTPTLAT: '+47.4702093',
        INTPTLON: '-101.2567620',
        ELSDLEA: '',
        UNSDLEA: '18660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.6256, 48.5463],
            [-101.5656, 48.5463],
            [-101.3863, 48.4225],
            [-101.3427, 48.401],
            [-101.3795, 48.3718],
            [-101.3394, 48.2762],
            [-101.3892, 48.2658],
            [-101.3953, 48.267],
            [-101.3964, 48.2507],
            [-101.4038, 48.1968],
            [-101.4039, 48.1099],
            [-101.534, 48.0662],
            [-101.6633, 48.0667],
            [-101.6422, 48.2548],
            [-101.4793, 48.2978],
            [-101.4894, 48.3567],
            [-101.6205, 48.3564],
            [-101.7127, 48.415],
            [-101.6256, 48.5463],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3818730',
        NAME: 'United Public School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 791206481,
        AWATER: 25665165,
        INTPTLAT: '+48.2923830',
        INTPTLON: '-101.5235941',
        ELSDLEA: '',
        UNSDLEA: '18730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8333, 47.1965],
              [-97.8118, 47.1964],
              [-97.8333, 47.1892],
              [-97.8333, 47.1965],
            ],
          ],
          [
            [
              [-97.9187, 47.1968],
              [-97.8971, 47.1531],
              [-97.9291, 47.1677],
              [-97.9291, 47.1688],
              [-97.9187, 47.1968],
            ],
          ],
          [
            [
              [-97.8975, 47.2259],
              [-97.8118, 47.1964],
              [-97.8661, 47.2039],
              [-97.8975, 47.2112],
              [-97.9187, 47.1968],
              [-97.94, 47.2029],
              [-97.8975, 47.2259],
            ],
          ],
          [
            [
              [-97.9446, 47.2377],
              [-97.9402, 47.2378],
              [-97.9189, 47.2296],
              [-97.9446, 47.2377],
            ],
          ],
          [
            [
              [-98.2277, 46.8776],
              [-98.2068, 46.8263],
              [-98.2488, 46.8417],
              [-98.2277, 46.8776],
            ],
          ],
          [
            [
              [-98.2701, 46.8559],
              [-98.2701, 46.8496],
              [-98.2795, 46.8496],
              [-98.2701, 46.8559],
            ],
          ],
          [
            [
              [-98.2912, 46.878],
              [-98.2585, 46.8852],
              [-98.2701, 46.8559],
              [-98.2912, 46.8708],
              [-98.2912, 46.878],
            ],
          ],
          [
            [
              [-98.2055, 46.9068],
              [-98.1855, 46.9068],
              [-98.1535, 46.9648],
              [-98.0813, 46.9501],
              [-98.025, 47.1827],
              [-97.9291, 47.1677],
              [-97.8858, 47.1387],
              [-97.8751, 47.1386],
              [-97.8749, 47.1241],
              [-97.9601, 47.0806],
              [-97.9599, 47.0007],
              [-97.892, 46.942],
              [-97.8921, 46.8332],
              [-97.934, 46.7319],
              [-97.8921, 46.6883],
              [-97.8921, 46.6738],
              [-98.0398, 46.6598],
              [-98.0181, 46.6963],
              [-98.1444, 46.7905],
              [-98.1856, 46.8342],
              [-98.2277, 46.8776],
              [-98.2488, 46.8923],
              [-98.3221, 46.8926],
              [-98.4392, 46.8926],
              [-98.4394, 46.9583],
              [-98.2911, 46.9694],
              [-98.2055, 46.9068],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3818850',
        NAME: 'Valley City Public School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 916105696,
        AWATER: 20559394,
        INTPTLAT: '+46.9170000',
        INTPTLON: '-098.0542975',
        ELSDLEA: '',
        UNSDLEA: '18850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9043, 46.3403],
            [-96.6566, 46.3695],
            [-96.5997, 46.3305],
            [-96.5793, 46.1524],
            [-96.5632, 46.1086],
            [-96.71, 46.1092],
            [-96.9482, 46.2265],
            [-96.9043, 46.3403],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819020',
        NAME: 'Wahpeton Public School District 37',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 648041164,
        AWATER: 519884,
        INTPTLAT: '+46.2322388',
        INTPTLON: '-096.7513848',
        ELSDLEA: '',
        UNSDLEA: '19020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9096, 47.9201],
            [-98.9097, 47.9641],
            [-98.9098, 47.978],
            [-98.7382, 47.9346],
            [-98.6599, 47.9602],
            [-98.5475, 47.9187],
            [-98.6542, 47.7456],
            [-98.9111, 47.7597],
            [-98.9096, 47.9201],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819260',
        NAME: 'Warwick Public School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 549167327,
        AWATER: 27134967,
        INTPTLAT: '+47.8675965',
        INTPTLON: '-098.7570495',
        ELSDLEA: '',
        UNSDLEA: '19260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3582, 47.3423],
            [-101.1637, 47.3569],
            [-101.0038, 47.4584],
            [-100.9828, 47.4075],
            [-100.7917, 47.3272],
            [-100.8971, 47.1716],
            [-100.9652, 47.1566],
            [-100.9864, 47.2569],
            [-101.2154, 47.2485],
            [-101.3457, 47.2919],
            [-101.3582, 47.3423],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819290',
        NAME: 'Washburn Public School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623788631,
        AWATER: 17230161,
        INTPTLAT: '+47.3177390',
        INTPTLON: '-101.0522929',
        ELSDLEA: '',
        UNSDLEA: '19290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0111, 46.7901],
            [-96.9686, 46.8335],
            [-96.9883, 46.9496],
            [-96.943, 46.9919],
            [-96.8301, 47.0206],
            [-96.82, 46.9768],
            [-96.8724, 46.746],
            [-96.9683, 46.7319],
            [-97.0111, 46.7901],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819410',
        NAME: 'West Fargo Public School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317409481,
        AWATER: 1427355,
        INTPTLAT: '+46.8719305',
        INTPTLON: '-096.9155545',
        ELSDLEA: '',
        UNSDLEA: '19410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-101.3442, 48.9994],
              [-101.2782, 48.9993],
              [-101.2782, 48.9803],
              [-101.3442, 48.9994],
            ],
          ],
          [
            [
              [-101.2782, 48.9803],
              [-101.2342, 48.9994],
              [-100.7087, 48.9995],
              [-100.6872, 48.9084],
              [-100.7542, 48.8065],
              [-101.2342, 48.8136],
              [-101.2782, 48.9803],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819470',
        NAME: 'West Hope Public School District 17',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 893531401,
        AWATER: 14664325,
        INTPTLAT: '+48.9009588',
        INTPTLON: '-101.0051624',
        ELSDLEA: '',
        UNSDLEA: '19470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2616, 47.7243],
            [-102.1294, 47.6735],
            [-102.0006, 47.674],
            [-101.8222, 47.674],
            [-101.8221, 47.5885],
            [-101.7135, 47.5369],
            [-101.8358, 47.4955],
            [-102.0366, 47.5727],
            [-102.1613, 47.5654],
            [-102.2635, 47.6196],
            [-102.2616, 47.7243],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819680',
        NAME: 'White Shield Public School District 85',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392297005,
        AWATER: 191995480,
        INTPTLAT: '+47.6205134',
        INTPTLON: '-102.0002226',
        ELSDLEA: '',
        UNSDLEA: '19680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7138, 48.1798],
            [-103.6041, 48.1932],
            [-103.598, 48.1401],
            [-103.7138, 48.1798],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3819880',
        NAME: 'Williston Public School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50039862,
        AWATER: 1035935,
        INTPTLAT: '+48.1698162',
        INTPTLON: '-103.6324336',
        ELSDLEA: '',
        UNSDLEA: '19880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5378, 46.9825],
            [-100.5377, 47.0696],
            [-100.4532, 47.0839],
            [-100.4535, 47.3275],
            [-100.2894, 47.3273],
            [-100.1133, 47.3273],
            [-100.1151, 47.0252],
            [-100.1599, 46.9667],
            [-100.4549, 46.953],
            [-100.5378, 46.9825],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3820130',
        NAME: 'Wing Public School District 28',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1054362731,
        AWATER: 26983824,
        INTPTLAT: '+47.1450932',
        INTPTLON: '-100.3168989',
        ELSDLEA: '',
        UNSDLEA: '20130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8785, 46.2394],
            [-99.8784, 46.2828],
            [-99.7285, 46.297],
            [-99.7071, 46.3628],
            [-99.4767, 46.4859],
            [-99.4666, 46.4715],
            [-99.4666, 46.4645],
            [-99.2674, 46.4135],
            [-99.2766, 46.2834],
            [-99.3785, 46.2451],
            [-99.3774, 46.1866],
            [-99.5239, 46.1577],
            [-99.566, 46.0711],
            [-99.6702, 46.0708],
            [-99.7744, 46.0859],
            [-99.8789, 46.1662],
            [-99.8785, 46.2394],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3820190',
        NAME: 'Wishek Public School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1251038313,
        AWATER: 18786113,
        INTPTLAT: '+46.2657790',
        INTPTLON: '-099.5716822',
        ELSDLEA: '',
        UNSDLEA: '20190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8847, 48.5449],
            [-99.754, 48.5448],
            [-99.5586, 48.6028],
            [-99.4932, 48.5448],
            [-99.4933, 48.4514],
            [-99.6752, 48.3711],
            [-99.7687, 48.4441],
            [-99.8853, 48.4581],
            [-99.8847, 48.5449],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3820230',
        NAME: 'Wolford Public School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478280571,
        AWATER: 31302571,
        INTPTLAT: '+48.4906076',
        INTPTLON: '-099.6728324',
        ELSDLEA: '',
        UNSDLEA: '20230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2802, 46.4561],
            [-97.2802, 46.4565],
            [-97.1973, 46.4997],
            [-97.0301, 46.4923],
            [-97.0298, 46.3691],
            [-96.9043, 46.3403],
            [-96.9482, 46.2265],
            [-97.0108, 46.1819],
            [-97.2594, 46.1955],
            [-97.3123, 46.3259],
            [-97.2802, 46.4561],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3820310',
        NAME: 'Wyndmere Public School District 42',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 800042209,
        AWATER: 0,
        INTPTLAT: '+46.3364960',
        INTPTLON: '-097.1200974',
        ELSDLEA: '',
        UNSDLEA: '20310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8789, 46.1662],
            [-99.7744, 46.0859],
            [-99.6702, 46.0708],
            [-99.63, 45.9411],
            [-99.7389, 45.9408],
            [-99.984, 45.9419],
            [-100.0046, 46.0144],
            [-99.8794, 46.0576],
            [-99.8789, 46.1662],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3820400',
        NAME: 'Zeeland Public School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441142494,
        AWATER: 406433,
        INTPTLAT: '+46.0280750',
        INTPTLON: '-099.8161915',
        ELSDLEA: '',
        UNSDLEA: '20400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.68, 47.0246],
            [-101.6376, 47.0827],
            [-101.5743, 47.1042],
            [-101.4261, 47.0682],
            [-101.3836, 46.9811],
            [-101.2192, 46.9814],
            [-101.2185, 46.848],
            [-101.2169, 46.7172],
            [-101.2153, 46.66],
            [-101.3827, 46.5744],
            [-101.4244, 46.6028],
            [-101.6752, 46.5887],
            [-101.6964, 46.5886],
            [-101.6559, 46.6668],
            [-101.6786, 46.8039],
            [-101.6054, 46.8042],
            [-101.6067, 46.9419],
            [-101.68, 47.0246],
          ],
        ],
      },
      properties: {
        STATEFP: '38',
        SCSDLEA: '',
        GEOID: '3830049',
        NAME: 'New Salem-Almont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1577738657,
        AWATER: 3274985,
        INTPTLAT: '+46.8144960',
        INTPTLON: '-101.4457209',
        ELSDLEA: '',
        UNSDLEA: '30049',
      },
    },
  ],
};

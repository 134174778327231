export const MN = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4234, 47.0056],
            [-95.3815, 47.0058],
            [-95.3601, 46.9774],
            [-95.4136, 46.9773],
            [-95.4234, 47.0056],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '27001',
        GEOID: '2727001',
        NAME: 'Park Rapids Public School District in Pine Point (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 10707429,
        AWATER: 2071546,
        INTPTLAT: '+46.9975863',
        INTPTLON: '-095.3953881',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4234, 47.0056],
            [-95.3815, 47.0058],
            [-95.3601, 46.9774],
            [-95.4136, 46.9773],
            [-95.4234, 47.0056],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728960',
        NAME: 'Pine Point Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10707429,
        AWATER: 2071546,
        INTPTLAT: '+46.9975863',
        INTPTLON: '-095.3953881',
        ELSDLEA: '28960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6139, 45.4742],
            [-94.442, 45.5739],
            [-94.3025, 45.4854],
            [-94.3335, 45.3936],
            [-94.4415, 45.3555],
            [-94.5576, 45.4056],
            [-94.5769, 45.4056],
            [-94.6139, 45.4742],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709440',
        NAME: 'Rocori Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308033846,
        AWATER: 21248696,
        INTPTLAT: '+45.4665852',
        INTPTLON: '-094.4613695',
        ELSDLEA: '',
        UNSDLEA: '09440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9323, 47.63],
            [-92.7992, 47.6292],
            [-92.8069, 47.4677],
            [-92.9404, 47.4836],
            [-92.9323, 47.63],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708910',
        NAME: 'Chisholm Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172698606,
        AWATER: 3352660,
        INTPTLAT: '+47.5410390',
        INTPTLON: '-092.8621902',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8002, 48.0659],
            [-91.7993, 47.7575],
            [-92.0523, 47.7583],
            [-92.0543, 48.0597],
            [-91.8002, 48.0659],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711520',
        NAME: 'Ely Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561064130,
        AWATER: 85314912,
        INTPTLAT: '+47.9073158',
        INTPTLON: '-091.9323094',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1902, 47.0252],
            [-93.0559, 47.0264],
            [-93.0562, 47.1093],
            [-92.9385, 47.109],
            [-92.9314, 47.0264],
            [-92.8051, 47.0256],
            [-92.8063, 46.9396],
            [-92.6799, 46.9421],
            [-92.6804, 46.8764],
            [-92.8063, 46.8523],
            [-92.8086, 46.7663],
            [-93.0615, 46.7665],
            [-93.0578, 46.9397],
            [-93.1903, 46.9391],
            [-93.1902, 47.0252],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712210',
        NAME: 'Floodwood Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 812912790,
        AWATER: 8570110,
        INTPTLAT: '+46.9180728',
        INTPTLON: '-092.9431903',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0407, 44.1514],
            [-94.9305, 44.1511],
            [-94.8427, 44.2102],
            [-94.7383, 44.1089],
            [-94.7793, 44.0655],
            [-94.8592, 44.0213],
            [-95.0199, 44.0502],
            [-95.0407, 44.1514],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709540',
        NAME: 'Comfrey Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319607361,
        AWATER: 1988199,
        INTPTLAT: '+44.1000001',
        INTPTLON: '-094.9100681',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0998, 44.0069],
            [-95.0199, 44.0502],
            [-94.8592, 44.0213],
            [-94.8545, 43.8194],
            [-94.8833, 43.7906],
            [-94.9546, 43.8045],
            [-94.9791, 43.8766],
            [-95.0701, 43.9279],
            [-95.0998, 44.0069],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723010',
        NAME: 'Mountain Lake Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357403241,
        AWATER: 2900943,
        INTPTLAT: '+43.9392731',
        INTPTLON: '-094.9530768',
        ELSDLEA: '',
        UNSDLEA: '23010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7623, 44.1533],
            [-92.6783, 44.1083],
            [-92.6479, 43.928],
            [-92.8093, 43.9541],
            [-92.7989, 44.1126],
            [-92.7623, 44.1533],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2716980',
        NAME: 'Kasson-Mantorville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248307412,
        AWATER: 218175,
        INTPTLAT: '+44.0248378',
        INTPTLON: '-092.7369578',
        ELSDLEA: '',
        UNSDLEA: '16980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.3608, 44.252],
              [-92.2199, 44.2947],
              [-92.1594, 44.2875],
              [-91.9496, 44.2074],
              [-91.9495, 44.2038],
              [-91.9496, 44.1967],
              [-91.9607, 44.1785],
              [-91.9861, 44.1428],
              [-92.007, 44.1136],
              [-92.1385, 44.0935],
              [-92.1392, 44.0711],
              [-92.3189, 44.0644],
              [-92.3901, 44.1514],
              [-92.3608, 44.252],
            ],
          ],
          [
            [
              [-92.4211, 44.2232],
              [-92.4008, 44.2082],
              [-92.4207, 44.2124],
              [-92.4211, 44.2232],
            ],
          ],
          [
            [
              [-92.451, 44.2127],
              [-92.451, 44.2164],
              [-92.4454, 44.2164],
              [-92.4409, 44.2164],
              [-92.4208, 44.2082],
              [-92.4008, 44.2082],
              [-92.4799, 44.1946],
              [-92.451, 44.2127],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700287',
        NAME: 'Plainview-Elgin-Millville',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 630638328,
        AWATER: 2674359,
        INTPTLAT: '+44.1847281',
        INTPTLON: '-092.1941707',
        ELSDLEA: '',
        UNSDLEA: '00287',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0055, 43.9502],
            [-92.8093, 43.9541],
            [-92.6479, 43.928],
            [-92.6282, 43.9171],
            [-92.6034, 43.8918],
            [-92.5983, 43.8919],
            [-92.6783, 43.863],
            [-92.6696, 43.7973],
            [-92.6892, 43.7758],
            [-92.8094, 43.7692],
            [-92.8692, 43.6713],
            [-92.92, 43.7699],
            [-92.9753, 43.9211],
            [-93.0055, 43.9502],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713590',
        NAME: 'Hayfield Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513986272,
        AWATER: 102916,
        INTPTLAT: '+43.8447085',
        INTPTLON: '-092.8184578',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2672, 43.848],
            [-93.9698, 43.8482],
            [-93.923, 43.7896],
            [-93.867, 43.6736],
            [-93.8884, 43.4998],
            [-94.0886, 43.5001],
            [-94.228, 43.5001],
            [-94.2479, 43.558],
            [-94.1653, 43.7026],
            [-94.2773, 43.8185],
            [-94.2672, 43.848],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700130',
        NAME: 'Blue Earth Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1003394548,
        AWATER: 9871768,
        INTPTLAT: '+43.6634707',
        INTPTLON: '-094.0614148',
        ELSDLEA: '',
        UNSDLEA: '00130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.302, 47.0241],
            [-92.1754, 47.0249],
            [-92.1853, 46.7642],
            [-92.3017, 46.7641],
            [-92.2381, 46.8513],
            [-92.2372, 46.9379],
            [-92.302, 47.0241],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713920',
        NAME: 'Hermantown Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203250879,
        AWATER: 34581897,
        INTPTLAT: '+46.8799795',
        INTPTLON: '-092.2276835',
        ELSDLEA: '',
        UNSDLEA: '13920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.3017, 46.7641],
              [-92.1853, 46.7642],
              [-92.301, 46.6772],
              [-92.3017, 46.7641],
            ],
          ],
          [
            [
              [-92.302, 47.0241],
              [-92.2372, 46.9379],
              [-92.2381, 46.8513],
              [-92.3017, 46.7641],
              [-92.4285, 46.7648],
              [-92.4281, 46.8443],
              [-92.429, 47.0247],
              [-92.302, 47.0241],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730090',
        NAME: 'Proctor Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368970359,
        AWATER: 19623227,
        INTPTLAT: '+46.8600970',
        INTPTLON: '-092.3344233',
        ELSDLEA: '',
        UNSDLEA: '30090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.691, 47.6768],
            [-92.562, 47.7147],
            [-92.3031, 47.7143],
            [-92.3047, 47.6323],
            [-92.3691, 47.6343],
            [-92.4352, 47.5454],
            [-92.5597, 47.4891],
            [-92.5591, 47.573],
            [-92.6889, 47.5729],
            [-92.691, 47.6768],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741040',
        NAME: 'Virginia Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392378929,
        AWATER: 13979594,
        INTPTLAT: '+47.6240008',
        INTPTLON: '-092.5231972',
        ELSDLEA: '',
        UNSDLEA: '41040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2796, 46.2932],
            [-95.1506, 46.3546],
            [-95.1351, 46.3476],
            [-95.098, 46.3688],
            [-95.0982, 46.3542],
            [-94.9671, 46.3036],
            [-94.9156, 46.2304],
            [-95.1463, 46.1941],
            [-95.3017, 46.2383],
            [-95.2796, 46.2932],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2705430',
        NAME: 'Bertha-Hewitt Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359619544,
        AWATER: 814682,
        INTPTLAT: '+46.2726783',
        INTPTLON: '-095.1112680',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.414, 46.4055],
            [-95.2857, 46.456],
            [-95.1973, 46.5467],
            [-94.9679, 46.543],
            [-95.0301, 46.4993],
            [-95.098, 46.3688],
            [-95.1351, 46.3476],
            [-95.1506, 46.3546],
            [-95.2796, 46.2932],
            [-95.3008, 46.3402],
            [-95.414, 46.4055],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700022',
        NAME: 'Wadena-Deer Creek School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493033125,
        AWATER: 1543271,
        INTPTLAT: '+46.4342740',
        INTPTLON: '-095.1865091',
        ELSDLEA: '',
        UNSDLEA: '00022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.8645, 46.1945],
              [-94.859, 46.1832],
              [-94.8751, 46.1793],
              [-94.8645, 46.1945],
            ],
          ],
          [
            [
              [-94.8853, 46.1868],
              [-94.8751, 46.1793],
              [-94.8751, 46.1718],
              [-94.8853, 46.1868],
            ],
          ],
          [
            [
              [-94.9799, 46.0415],
              [-94.9568, 46.0922],
              [-94.8905, 46.1214],
              [-94.8751, 46.1718],
              [-94.8541, 46.1832],
              [-94.8026, 46.1502],
              [-94.6476, 46.1826],
              [-94.6479, 46.0569],
              [-94.6896, 46.0415],
              [-94.71, 46.0339],
              [-94.8137, 46.0491],
              [-94.9799, 46.0415],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2706300',
        NAME: 'Browerville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295120547,
        AWATER: 9704274,
        INTPTLAT: '+46.1004248',
        INTPTLON: '-094.8026211',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4149, 46.861],
            [-95.2684, 46.8475],
            [-95.0486, 46.8406],
            [-94.9116, 46.8051],
            [-94.7871, 46.8039],
            [-94.7866, 46.7163],
            [-95.0385, 46.7181],
            [-95.1853, 46.6882],
            [-95.1859, 46.7063],
            [-95.2887, 46.7166],
            [-95.3574, 46.751],
            [-95.348, 46.7594],
            [-95.4149, 46.861],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2720580',
        NAME: 'Menahga Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538367484,
        AWATER: 19280624,
        INTPTLAT: '+46.7761727',
        INTPTLON: '-095.1015823',
        ELSDLEA: '',
        UNSDLEA: '20580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.8451, 46.6841],
              [-95.7339, 46.7737],
              [-95.7316, 46.8853],
              [-95.6252, 46.9194],
              [-95.5302, 47.0056],
              [-95.4234, 47.0056],
              [-95.4136, 46.9773],
              [-95.4149, 46.861],
              [-95.348, 46.7594],
              [-95.3574, 46.751],
              [-95.4165, 46.7355],
              [-95.4144, 46.7162],
              [-95.6217, 46.7017],
              [-95.622, 46.6908],
              [-95.8451, 46.6841],
            ],
          ],
          [
            [
              [-95.8789, 46.7022],
              [-95.8783, 46.6736],
              [-95.9195, 46.659],
              [-95.9242, 46.6757],
              [-95.8789, 46.7022],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712360',
        NAME: 'Frazee-Vergas Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 746752304,
        AWATER: 87324246,
        INTPTLAT: '+46.8118024',
        INTPTLON: '-095.5747638',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9195, 46.659],
            [-95.8783, 46.6736],
            [-95.8451, 46.6841],
            [-95.622, 46.6908],
            [-95.6217, 46.7017],
            [-95.4144, 46.7162],
            [-95.3713, 46.6586],
            [-95.5021, 46.5279],
            [-95.5114, 46.4488],
            [-95.657, 46.3975],
            [-95.6821, 46.4471],
            [-95.8537, 46.4414],
            [-95.8539, 46.456],
            [-95.8543, 46.5427],
            [-95.9195, 46.659],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728230',
        NAME: 'Perham-Dent Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 712699476,
        AWATER: 191631410,
        INTPTLAT: '+46.5746195',
        INTPTLON: '-095.6476793',
        ELSDLEA: '',
        UNSDLEA: '28230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9679, 46.4273],
            [-95.8539, 46.456],
            [-95.8537, 46.4414],
            [-95.7797, 46.3695],
            [-95.8012, 46.1983],
            [-95.9362, 46.1819],
            [-95.9461, 46.3046],
            [-95.8996, 46.3419],
            [-95.9679, 46.4273],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740830',
        NAME: 'Underwood Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270853511,
        AWATER: 49523058,
        INTPTLAT: '+46.3122690',
        INTPTLON: '-095.8754669',
        ELSDLEA: '',
        UNSDLEA: '40830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0552, 46.5069],
            [-92.8462, 46.5051],
            [-92.7203, 46.4614],
            [-92.5295, 46.4605],
            [-92.5294, 46.4181],
            [-92.636, 46.3314],
            [-92.8873, 46.4191],
            [-93.0548, 46.4193],
            [-93.0552, 46.5069],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721450',
        NAME: 'Moose Lake Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399153951,
        AWATER: 11815182,
        INTPTLAT: '+46.4338449',
        INTPTLON: '-092.7953294',
        ELSDLEA: '',
        UNSDLEA: '21450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7332, 46.7678],
            [-92.5243, 46.7659],
            [-92.5238, 46.8372],
            [-92.4281, 46.8443],
            [-92.4285, 46.7648],
            [-92.4187, 46.6927],
            [-92.7343, 46.7074],
            [-92.7332, 46.7678],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709420',
        NAME: 'Cloquet Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245122751,
        AWATER: 6165888,
        INTPTLAT: '+46.7510150',
        INTPTLON: '-092.5333795',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9435, 45.4368],
            [-93.9452, 45.5379],
            [-93.8526, 45.56],
            [-93.7583, 45.4727],
            [-93.7948, 45.3444],
            [-93.8676, 45.3393],
            [-93.9939, 45.4008],
            [-93.9435, 45.4368],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703870',
        NAME: 'Becker Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280409847,
        AWATER: 6511439,
        INTPTLAT: '+45.4446179',
        INTPTLON: '-093.8684422',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0548, 46.4193],
            [-92.8873, 46.4191],
            [-92.636, 46.3314],
            [-92.887, 46.2241],
            [-93.0542, 46.2528],
            [-93.1162, 46.2888],
            [-93.1165, 46.4193],
            [-93.0548, 46.4193],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742750',
        NAME: 'Willow River Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 570115769,
        AWATER: 9693668,
        INTPTLAT: '+46.3282087',
        INTPTLON: '-092.9013540',
        ELSDLEA: '',
        UNSDLEA: '42750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3648, 46.8769],
            [-93.2797, 46.9606],
            [-93.1903, 46.9391],
            [-93.0578, 46.9397],
            [-93.0615, 46.7665],
            [-93.0556, 46.5477],
            [-93.0552, 46.5069],
            [-93.0548, 46.4193],
            [-93.1165, 46.4193],
            [-93.1162, 46.2888],
            [-93.0542, 46.2528],
            [-93.1366, 46.1801],
            [-93.179, 46.1585],
            [-93.3049, 46.1589],
            [-93.4311, 46.3333],
            [-93.3703, 46.4187],
            [-93.4322, 46.5051],
            [-93.4647, 46.6379],
            [-93.4426, 46.7274],
            [-93.3327, 46.7929],
            [-93.3648, 46.8769],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2719170',
        NAME: 'McGregor Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1997598311,
        AWATER: 86253557,
        INTPTLAT: '+46.5771440',
        INTPTLON: '-093.2387416',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7753, 47.0304],
            [-93.1902, 47.0252],
            [-93.1903, 46.9391],
            [-93.2797, 46.9606],
            [-93.3648, 46.8769],
            [-93.5485, 46.8566],
            [-93.7755, 46.8564],
            [-93.7753, 47.0304],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714010',
        NAME: 'Hill City Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660787006,
        AWATER: 11268775,
        INTPTLAT: '+46.9527982',
        INTPTLON: '-093.5237792',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0142, 45.3619],
            [-93.9942, 45.375],
            [-93.9939, 45.4008],
            [-93.8676, 45.3393],
            [-93.7948, 45.3444],
            [-93.6594, 45.2959],
            [-93.684, 45.2809],
            [-93.7658, 45.2379],
            [-93.9003, 45.2377],
            [-93.954, 45.2962],
            [-94.0041, 45.34],
            [-94.0142, 45.3619],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721390',
        NAME: 'Monticello Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214801432,
        AWATER: 15221711,
        INTPTLAT: '+45.3003336',
        INTPTLON: '-093.8474812',
        ELSDLEA: '',
        UNSDLEA: '21390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1691, 43.6302],
            [-93.1601, 43.6374],
            [-93.1195, 43.7356],
            [-92.92, 43.7699],
            [-92.8692, 43.6713],
            [-92.9385, 43.5871],
            [-93.0594, 43.5795],
            [-93.1691, 43.6302],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703450',
        NAME: 'Austin Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370353127,
        AWATER: 746333,
        INTPTLAT: '+43.6693745',
        INTPTLON: '-093.0110587',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.788, 45.7886],
            [-94.6542, 45.7845],
            [-94.649, 45.7812],
            [-94.6439, 45.7812],
            [-94.6109, 45.76],
            [-94.6346, 45.6429],
            [-94.7021, 45.5712],
            [-94.7014, 45.499],
            [-94.886, 45.4849],
            [-94.8959, 45.564],
            [-94.9471, 45.5855],
            [-94.8463, 45.7745],
            [-94.788, 45.7886],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2720550',
        NAME: 'Melrose Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 562588494,
        AWATER: 14982480,
        INTPTLAT: '+45.6484138',
        INTPTLON: '-094.7833726',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.71, 46.0339],
            [-94.6896, 46.0415],
            [-94.6479, 46.0569],
            [-94.5765, 46.0128],
            [-94.5448, 45.8906],
            [-94.6171, 45.8468],
            [-94.7519, 45.8939],
            [-94.71, 46.0339],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2738280',
        NAME: 'Swanville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232249408,
        AWATER: 9426092,
        INTPTLAT: '+45.9464225',
        INTPTLON: '-094.6396887',
        ELSDLEA: '',
        UNSDLEA: '38280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5191, 46.5132],
            [-94.4146, 46.514],
            [-94.3724, 46.5001],
            [-94.3702, 46.3691],
            [-94.3273, 46.2897],
            [-94.3804, 46.1945],
            [-94.5783, 46.1946],
            [-94.5823, 46.4564],
            [-94.5191, 46.5132],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728350',
        NAME: 'Pillager Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 519100094,
        AWATER: 45391911,
        INTPTLAT: '+46.3477855',
        INTPTLON: '-094.4646279',
        ELSDLEA: '',
        UNSDLEA: '28350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0615, 46.7665],
            [-92.8086, 46.7663],
            [-92.7332, 46.7678],
            [-92.7343, 46.7074],
            [-92.7344, 46.6501],
            [-92.8792, 46.5471],
            [-93.0556, 46.5477],
            [-93.0615, 46.7665],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709690',
        NAME: 'Cromwell-Wright Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540561974,
        AWATER: 6794812,
        INTPTLAT: '+46.6705876',
        INTPTLON: '-092.9108987',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4285, 46.7648],
            [-92.3017, 46.7641],
            [-92.301, 46.6772],
            [-92.3011, 46.668],
            [-92.4187, 46.6927],
            [-92.4285, 46.7648],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2738880',
        NAME: 'Esko Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104207362,
        AWATER: 345245,
        INTPTLAT: '+46.7138310',
        INTPTLON: '-092.3607923',
        ELSDLEA: '',
        UNSDLEA: '38880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5086, 46.4892],
            [-92.4247, 46.518],
            [-92.4279, 46.6342],
            [-92.2916, 46.6341],
            [-92.2929, 46.4172],
            [-92.5294, 46.4181],
            [-92.5295, 46.4605],
            [-92.5086, 46.4892],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2744190',
        NAME: 'Wrenshall Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321620734,
        AWATER: 732851,
        INTPTLAT: '+46.5190098',
        INTPTLON: '-092.3437032',
        ELSDLEA: '',
        UNSDLEA: '44190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1861, 43.8849],
            [-93.226, 43.921],
            [-93.217, 43.9248],
            [-93.0601, 43.9865],
            [-93.0582, 43.9865],
            [-93.0558, 43.9821],
            [-93.0055, 43.9502],
            [-92.9753, 43.9211],
            [-92.92, 43.7699],
            [-93.1195, 43.7356],
            [-93.214, 43.7897],
            [-93.1998, 43.7899],
            [-93.1861, 43.8849],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2705760',
        NAME: 'Blooming Prairie Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457830657,
        AWATER: 1727297,
        INTPTLAT: '+43.8506216',
        INTPTLON: '-093.0575229',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0658, 44.0082],
            [-93.0457, 44.168],
            [-93.0002, 44.1967],
            [-92.7989, 44.1965],
            [-92.7623, 44.1533],
            [-92.7989, 44.1126],
            [-92.8093, 43.9541],
            [-93.0055, 43.9502],
            [-93.0558, 43.9821],
            [-93.0582, 43.9865],
            [-93.0601, 43.9865],
            [-93.0658, 44.0082],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740665',
        NAME: 'Triton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555964151,
        AWATER: 221077,
        INTPTLAT: '+44.0816379',
        INTPTLON: '-092.9166166',
        ELSDLEA: '',
        UNSDLEA: '40665',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9501, 48.3667],
            [-94.4287, 48.3673],
            [-94.4185, 47.8458],
            [-94.8039, 47.846],
            [-94.7216, 47.9635],
            [-94.7225, 48.2086],
            [-94.9458, 48.1944],
            [-94.9501, 48.3667],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717010',
        NAME: 'Kelliher Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1429514526,
        AWATER: 194694222,
        INTPTLAT: '+48.2086227',
        INTPTLON: '-094.6516094',
        ELSDLEA: '',
        UNSDLEA: '17010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4303, 48.7009],
            [-94.2645, 48.6989],
            [-94.2444, 48.6534],
            [-93.844, 48.6294],
            [-93.7945, 48.516],
            [-93.5888, 48.5289],
            [-93.5861, 48.5214],
            [-93.7259, 48.5046],
            [-93.7753, 48.458],
            [-93.9075, 48.4581],
            [-93.9114, 48.3661],
            [-94.0367, 48.3654],
            [-94.0399, 48.0168],
            [-93.7785, 48.0174],
            [-93.7768, 48.0716],
            [-93.4092, 48.0697],
            [-93.4097, 47.8944],
            [-93.7758, 47.8989],
            [-93.7762, 47.8464],
            [-94.4185, 47.8458],
            [-94.4287, 48.3673],
            [-94.4303, 48.7009],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732700',
        NAME: 'South Koochiching School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3958031534,
        AWATER: 13793870,
        INTPTLAT: '+48.2561332',
        INTPTLON: '-094.0707213',
        ELSDLEA: '',
        UNSDLEA: '32700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.649, 45.7847],
              [-94.6439, 45.7812],
              [-94.649, 45.7812],
              [-94.649, 45.7847],
            ],
          ],
          [
            [
              [-95.1052, 46.0201],
              [-94.9799, 46.0415],
              [-94.8137, 46.0491],
              [-94.71, 46.0339],
              [-94.7519, 45.8939],
              [-94.6171, 45.8468],
              [-94.649, 45.7847],
              [-94.6542, 45.7845],
              [-94.788, 45.7886],
              [-94.85, 45.8464],
              [-94.9536, 45.8608],
              [-95.0844, 45.9462],
              [-95.1052, 46.0201],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700109',
        NAME: 'Long Prairie-Grey Eagle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611909697,
        AWATER: 25033841,
        INTPTLAT: '+45.9300223',
        INTPTLON: '-094.8429194',
        ELSDLEA: '',
        UNSDLEA: '00109',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.6542, 45.7845],
              [-94.649, 45.7847],
              [-94.649, 45.7812],
              [-94.6542, 45.7845],
            ],
          ],
          [
            [
              [-94.6171, 45.8468],
              [-94.5448, 45.8906],
              [-94.431, 45.8549],
              [-94.4238, 45.8192],
              [-94.4195, 45.8045],
              [-94.5762, 45.7598],
              [-94.6109, 45.76],
              [-94.6439, 45.7812],
              [-94.649, 45.7847],
              [-94.6171, 45.8468],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740860',
        NAME: 'Upsala Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175643769,
        AWATER: 2422935,
        INTPTLAT: '+45.8246408',
        INTPTLON: '-094.5295267',
        ELSDLEA: '',
        UNSDLEA: '40860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4311, 46.3333],
            [-93.3049, 46.1589],
            [-93.3059, 46.072],
            [-93.4328, 46.0393],
            [-93.4325, 45.9853],
            [-93.5589, 45.9817],
            [-93.5533, 46.2468],
            [-93.5594, 46.3196],
            [-93.4311, 46.3333],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2715510',
        NAME: 'Isle Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 511353377,
        AWATER: 77015308,
        INTPTLAT: '+46.1541655',
        INTPTLON: '-093.4547996',
        ELSDLEA: '',
        UNSDLEA: '15510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8509, 45.9538],
            [-93.8306, 45.9535],
            [-93.8306, 45.9681],
            [-93.579, 45.9083],
            [-93.5183, 45.8784],
            [-93.5141, 45.7595],
            [-93.4733, 45.719],
            [-93.5959, 45.6619],
            [-93.7704, 45.6488],
            [-93.8028, 45.7206],
            [-93.8175, 45.7643],
            [-93.8677, 45.837],
            [-93.8824, 45.8448],
            [-93.8918, 45.9105],
            [-93.8509, 45.9538],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2720670',
        NAME: 'Milaca Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 738214259,
        AWATER: 3234917,
        INTPTLAT: '+45.8154999',
        INTPTLON: '-093.6915235',
        ELSDLEA: '',
        UNSDLEA: '20670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1426, 45.9482],
            [-92.6608, 45.9221],
            [-92.7125, 45.8917],
            [-92.804, 45.7498],
            [-93.0148, 45.7501],
            [-93.0759, 45.7598],
            [-93.1422, 45.8319],
            [-93.1426, 45.9482],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728380',
        NAME: 'Pine City Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 551992787,
        AWATER: 17777004,
        INTPTLAT: '+45.8537903',
        INTPTLON: '-092.9273121',
        ELSDLEA: '',
        UNSDLEA: '28380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3561, 48.156],
            [-93.0971, 48.1792],
            [-93.089, 48.0679],
            [-93.3579, 48.0693],
            [-93.3561, 48.156],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733720',
        NAME: 'Nett Lake Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250510453,
        AWATER: 29624388,
        INTPTLAT: '+48.1299347',
        INTPTLON: '-093.1769195',
        ELSDLEA: '',
        UNSDLEA: '33720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5861, 48.5214],
            [-93.4832, 48.5057],
            [-93.4824, 48.4184],
            [-93.3531, 48.4184],
            [-93.3561, 48.156],
            [-93.3579, 48.0693],
            [-93.4092, 48.0697],
            [-93.7768, 48.0716],
            [-93.7785, 48.0174],
            [-94.0399, 48.0168],
            [-94.0367, 48.3654],
            [-93.9114, 48.3661],
            [-93.9075, 48.4581],
            [-93.7753, 48.458],
            [-93.7259, 48.5046],
            [-93.5861, 48.5214],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718270',
        NAME: 'Littlefork-Big Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2266405715,
        AWATER: 4764826,
        INTPTLAT: '+48.2648879',
        INTPTLON: '-093.6596490',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0851, 44.8619],
            [-93.0621, 44.8836],
            [-93.0101, 44.8638],
            [-93.016, 44.7756],
            [-93.0851, 44.8619],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2715030',
        NAME: 'Inver Grove Heights Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51423565,
        AWATER: 5157114,
        INTPTLAT: '+44.8256309',
        INTPTLON: '-093.0449554',
        ELSDLEA: '',
        UNSDLEA: '15030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2764, 44.4003],
            [-94.1443, 44.4172],
            [-94.1003, 44.3121],
            [-94.0788, 44.2688],
            [-94.1449, 44.194],
            [-94.3118, 44.2428],
            [-94.2764, 44.4003],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723580',
        NAME: 'Nicollet Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319261956,
        AWATER: 40488499,
        INTPTLAT: '+44.3081092',
        INTPTLON: '-094.2074851',
        ELSDLEA: '',
        UNSDLEA: '23580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7947, 45.2962],
            [-92.7061, 45.3253],
            [-92.7457, 45.296],
            [-92.7947, 45.2962],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708880',
        NAME: 'Franconia Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12148095,
        AWATER: 771819,
        INTPTLAT: '+45.3194964',
        INTPTLON: '-092.7451913',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4741, 48.9991],
            [-95.1537, 48.9989],
            [-95.1533, 49.3844],
            [-94.9574, 49.3701],
            [-94.8256, 49.2944],
            [-94.6843, 48.884],
            [-94.6903, 48.8382],
            [-94.8742, 48.8822],
            [-94.9394, 48.9656],
            [-95.0257, 48.9159],
            [-95.024, 48.7584],
            [-95.0899, 48.7131],
            [-95.3427, 48.713],
            [-95.3426, 48.6269],
            [-95.473, 48.627],
            [-95.4833, 48.7776],
            [-95.4509, 48.7995],
            [-95.4741, 48.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741850',
        NAME: 'Warroad Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1198779275,
        AWATER: 1221753537,
        INTPTLAT: '+48.9739301',
        INTPTLON: '-095.0293359',
        ELSDLEA: '',
        UNSDLEA: '41850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.474, 43.6801],
            [-93.4986, 43.7119],
            [-93.4088, 43.7748],
            [-93.2435, 43.7926],
            [-93.214, 43.7897],
            [-93.1195, 43.7356],
            [-93.1601, 43.6374],
            [-93.1691, 43.6302],
            [-93.2887, 43.607],
            [-93.3283, 43.5499],
            [-93.5049, 43.5862],
            [-93.474, 43.6801],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702970',
        NAME: 'Albert Lea Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571948000,
        AWATER: 19702722,
        INTPTLAT: '+43.6770329',
        INTPTLON: '-093.3138055',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5987, 43.5433],
            [-93.5049, 43.5862],
            [-93.3283, 43.5499],
            [-93.2887, 43.607],
            [-93.1691, 43.6302],
            [-93.0594, 43.5795],
            [-93.1005, 43.4996],
            [-93.3799, 43.4995],
            [-93.6046, 43.4996],
            [-93.5987, 43.5433],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700148',
        NAME: 'Glenville-Emmons School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382268584,
        AWATER: 5080543,
        INTPTLAT: '+43.5464193',
        INTPTLON: '-093.2988039',
        ELSDLEA: '',
        UNSDLEA: '00148',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0646, 44.9195],
            [-93.02, 44.8907],
            [-93.0101, 44.8638],
            [-93.0621, 44.8836],
            [-93.0646, 44.9195],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733270',
        NAME: 'South St. Paul Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14186551,
        AWATER: 1271325,
        INTPTLAT: '+44.8880271',
        INTPTLON: '-093.0404974',
        ELSDLEA: '',
        UNSDLEA: '33270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6101, 45.8517],
            [-95.5203, 45.9335],
            [-95.4794, 46.0719],
            [-95.3217, 46.0557],
            [-95.2122, 46.0986],
            [-95.1461, 46.0629],
            [-95.1467, 46.0198],
            [-95.2631, 45.7806],
            [-95.3149, 45.7589],
            [-95.6034, 45.7735],
            [-95.6101, 45.8517],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703060',
        NAME: 'Alexandria Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760234770,
        AWATER: 132651390,
        INTPTLAT: '+45.9152644',
        INTPTLON: '-095.3864984',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9325, 45.4124],
            [-95.8504, 45.4558],
            [-95.8714, 45.5424],
            [-95.7065, 45.5426],
            [-95.6242, 45.4853],
            [-95.6968, 45.4559],
            [-95.8598, 45.3691],
            [-95.9325, 45.4124],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713380',
        NAME: 'Hancock Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228688758,
        AWATER: 2001852,
        INTPTLAT: '+45.4815835',
        INTPTLON: '-095.7983050',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1396, 45.7522],
            [-95.0167, 45.8538],
            [-94.9536, 45.8608],
            [-94.85, 45.8464],
            [-94.788, 45.7886],
            [-94.8463, 45.7745],
            [-94.9471, 45.5855],
            [-95.1394, 45.6368],
            [-95.1396, 45.7522],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732850',
        NAME: 'Sauk Centre Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491567784,
        AWATER: 19053094,
        INTPTLAT: '+45.7351279',
        INTPTLON: '-095.0002673',
        ELSDLEA: '',
        UNSDLEA: '32850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6449, 47.4993],
            [-96.516, 47.4992],
            [-96.4302, 47.4484],
            [-96.3871, 47.4125],
            [-96.3645, 47.1966],
            [-96.3014, 47.1513],
            [-96.3714, 47.1512],
            [-96.69, 47.1115],
            [-96.69, 47.1289],
            [-96.69, 47.1368],
            [-96.6449, 47.4993],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700126',
        NAME: 'Ada-Borup Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 890543367,
        AWATER: 2443875,
        INTPTLAT: '+47.2910163',
        INTPTLON: '-096.5238723',
        ELSDLEA: '',
        UNSDLEA: '00126',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7004, 47.1079],
            [-96.69, 47.1115],
            [-96.3714, 47.1512],
            [-96.4414, 46.9771],
            [-96.4206, 46.8044],
            [-96.4833, 46.7612],
            [-96.5894, 46.7687],
            [-96.6723, 46.8332],
            [-96.6687, 47.0058],
            [-96.7004, 47.1079],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2710260',
        NAME: 'Dilworth-Glyndon-Felton',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 710874767,
        AWATER: 549055,
        INTPTLAT: '+46.9549953',
        INTPTLON: '-096.5507470',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2325, 46.7257],
            [-96.1751, 46.7371],
            [-95.9428, 46.7244],
            [-95.9242, 46.6757],
            [-95.9195, 46.659],
            [-95.8543, 46.5427],
            [-95.8539, 46.456],
            [-95.9679, 46.4273],
            [-96.0727, 46.4532],
            [-96.0981, 46.4146],
            [-96.1556, 46.4131],
            [-96.2812, 46.5732],
            [-96.2333, 46.6307],
            [-96.2325, 46.7257],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728170',
        NAME: 'Pelican Rapids Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 736190223,
        AWATER: 117948078,
        INTPTLAT: '+46.5793346',
        INTPTLON: '-096.0610197',
        ELSDLEA: '',
        UNSDLEA: '28170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7112, 47.1224],
              [-96.69, 47.1289],
              [-96.69, 47.1115],
              [-96.7004, 47.1079],
              [-96.7112, 47.1224],
            ],
          ],
          [
            [
              [-96.8581, 47.4477],
              [-96.7726, 47.4553],
              [-96.6811, 47.4987],
              [-96.6449, 47.4993],
              [-96.69, 47.1368],
              [-96.7112, 47.1224],
              [-96.8401, 47.1505],
              [-96.8334, 47.238],
              [-96.8581, 47.4477],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791448',
        NAME: 'Norman County West School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 511761110,
        AWATER: 3111198,
        INTPTLAT: '+47.3209770',
        INTPTLON: '-096.7645873',
        ELSDLEA: '',
        UNSDLEA: '91448',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8598, 45.3691],
            [-95.6968, 45.4559],
            [-95.6242, 45.4853],
            [-95.5311, 45.4478],
            [-95.3821, 45.4184],
            [-95.3577, 45.4125],
            [-95.4502, 45.2966],
            [-95.4818, 45.2098],
            [-95.5016, 45.1149],
            [-95.5127, 45.1081],
            [-95.5428, 45.0937],
            [-95.6749, 45.1514],
            [-95.8196, 45.2463],
            [-95.82, 45.2755],
            [-95.8598, 45.3691],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2704470',
        NAME: 'Benson Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1032334201,
        AWATER: 9181428,
        INTPTLAT: '+45.3054287',
        INTPTLON: '-095.6143439',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1394, 45.6368],
            [-94.9471, 45.5855],
            [-94.8959, 45.564],
            [-94.886, 45.4849],
            [-94.8454, 45.3698],
            [-95.0598, 45.369],
            [-95.1832, 45.3261],
            [-95.1832, 45.3338],
            [-95.2552, 45.4125],
            [-95.3577, 45.4125],
            [-95.3821, 45.4184],
            [-95.3773, 45.499],
            [-95.2656, 45.5131],
            [-95.1394, 45.6368],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700105',
        NAME: 'Belgrade-Brooten-Elrosa Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 866486644,
        AWATER: 27279691,
        INTPTLAT: '+45.4636000',
        INTPTLON: '-095.0949723',
        ELSDLEA: '',
        UNSDLEA: '00105',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0348, 44.6746],
            [-92.9742, 44.7724],
            [-92.8863, 44.7746],
            [-92.8215, 44.8656],
            [-92.7695, 44.862],
            [-92.8084, 44.7516],
            [-92.732, 44.7143],
            [-92.7868, 44.5652],
            [-92.9139, 44.5867],
            [-93.0352, 44.6163],
            [-93.0348, 44.6746],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713530',
        NAME: 'Hastings Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442358749,
        AWATER: 23044123,
        INTPTLAT: '+44.6945396',
        INTPTLON: '-092.8625847',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.69, 47.1368],
              [-96.69, 47.1289],
              [-96.7112, 47.1224],
              [-96.69, 47.1368],
            ],
          ],
          [
            [
              [-96.8401, 47.1505],
              [-96.7112, 47.1224],
              [-96.7004, 47.1079],
              [-96.6687, 47.0058],
              [-96.6723, 46.8332],
              [-96.5894, 46.7687],
              [-96.622, 46.6947],
              [-96.7839, 46.7064],
              [-96.7767, 46.8956],
              [-96.82, 46.9768],
              [-96.8301, 47.0206],
              [-96.8401, 47.1505],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721420',
        NAME: 'Moorhead Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 526274156,
        AWATER: 10452,
        INTPTLAT: '+46.8979991',
        INTPTLON: '-096.7204655',
        ELSDLEA: '',
        UNSDLEA: '21420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3714, 47.1512],
            [-96.3014, 47.1513],
            [-96.1097, 47.1517],
            [-96.0666, 47.0571],
            [-96.1954, 46.9208],
            [-96.2377, 46.9209],
            [-96.3215, 46.9772],
            [-96.4414, 46.9771],
            [-96.3714, 47.1512],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740810',
        NAME: 'Ulen-Hitterdal Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 543595307,
        AWATER: 5115138,
        INTPTLAT: '+47.0571218',
        INTPTLON: '-096.2473959',
        ELSDLEA: '',
        UNSDLEA: '40810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1954, 46.9208],
            [-96.0666, 47.0571],
            [-95.9975, 47.0211],
            [-95.9222, 46.8476],
            [-95.9428, 46.7244],
            [-96.1751, 46.7371],
            [-96.1954, 46.9208],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700162',
        NAME: 'Lake Park Audubon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499571809,
        AWATER: 63754377,
        INTPTLAT: '+46.8693853',
        INTPTLON: '-096.0612805',
        ELSDLEA: '',
        UNSDLEA: '00162',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8163, 44.3049],
            [-95.7052, 44.3482],
            [-95.6143, 44.3849],
            [-95.6143, 44.3777],
            [-95.6143, 44.3704],
            [-95.5234, 44.3266],
            [-95.5228, 44.1088],
            [-95.6027, 44.0221],
            [-95.7347, 44.0392],
            [-95.7078, 44.1236],
            [-95.9484, 44.1169],
            [-96.0179, 44.2398],
            [-95.8163, 44.3049],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700326',
        NAME: 'Tracy Area Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 871485865,
        AWATER: 30263787,
        INTPTLAT: '+44.2033246',
        INTPTLON: '-095.7343300',
        ELSDLEA: '',
        UNSDLEA: '00326',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.3855, 44.537],
              [-95.3909, 44.5296],
              [-95.4013, 44.5299],
              [-95.3855, 44.537],
            ],
          ],
          [
            [
              [-95.4727, 44.3779],
              [-95.4615, 44.378],
              [-95.4726, 44.3703],
              [-95.4726, 44.3735],
              [-95.4727, 44.3779],
            ],
          ],
          [
            [
              [-95.4013, 44.5299],
              [-95.4013, 44.5219],
              [-95.391, 44.522],
              [-95.3605, 44.5297],
              [-95.2498, 44.4855],
              [-95.148, 44.4383],
              [-95.0769, 44.3913],
              [-95.0769, 44.3836],
              [-95.0973, 44.3507],
              [-95.1678, 44.2964],
              [-95.3702, 44.3266],
              [-95.3795, 44.3343],
              [-95.3795, 44.3268],
              [-95.4615, 44.378],
              [-95.4727, 44.3849],
              [-95.5029, 44.5014],
              [-95.4013, 44.5299],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741430',
        NAME: 'Wabasso Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 549650016,
        AWATER: 1702955,
        INTPTLAT: '+44.4211852',
        INTPTLON: '-095.3004837',
        ELSDLEA: '',
        UNSDLEA: '41430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2076, 44.9881],
            [-93.0661, 44.992],
            [-93.0043, 44.8908],
            [-93.02, 44.8907],
            [-93.0646, 44.9195],
            [-93.1785, 44.8883],
            [-93.1938, 44.9053],
            [-93.2076, 44.9881],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733840',
        NAME: 'St. Paul Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134624449,
        AWATER: 10873794,
        INTPTLAT: '+44.9489010',
        INTPTLON: '-093.1040631',
        ELSDLEA: '',
        UNSDLEA: '33840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7583, 45.4727],
            [-93.6541, 45.4722],
            [-93.6594, 45.2959],
            [-93.7948, 45.3444],
            [-93.7583, 45.4727],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2705460',
        NAME: 'Big Lake Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158892109,
        AWATER: 8915417,
        INTPTLAT: '+45.3915322',
        INTPTLON: '-093.7064576',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6903, 48.8382],
            [-94.6943, 48.7822],
            [-94.5921, 48.7191],
            [-94.4303, 48.7009],
            [-94.4287, 48.3673],
            [-94.9501, 48.3667],
            [-95.211, 48.366],
            [-95.212, 48.54],
            [-95.3425, 48.5402],
            [-95.3426, 48.6269],
            [-95.3427, 48.713],
            [-95.0899, 48.7131],
            [-95.024, 48.7584],
            [-95.0257, 48.9159],
            [-94.9394, 48.9656],
            [-94.8742, 48.8822],
            [-94.6903, 48.8382],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717570',
        NAME: 'Lake of the Woods School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2947296260,
        AWATER: 15164373,
        INTPTLAT: '+48.5881525',
        INTPTLON: '-094.8866975',
        ELSDLEA: '',
        UNSDLEA: '17570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8341, 44.3951],
            [-93.7276, 44.4922],
            [-93.702, 44.4858],
            [-93.5447, 44.5006],
            [-93.4144, 44.4494],
            [-93.4026, 44.4282],
            [-93.4642, 44.3033],
            [-93.5149, 44.3082],
            [-93.7068, 44.2901],
            [-93.7067, 44.2828],
            [-93.7226, 44.2828],
            [-93.8341, 44.3951],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700379',
        NAME: 'Tri-City United School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548403721,
        AWATER: 25432978,
        INTPTLAT: '+44.4063973',
        INTPTLON: '-093.6155150',
        ELSDLEA: '',
        UNSDLEA: '00379',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1599, 47.9269],
            [-96.095, 47.9784],
            [-95.8934, 47.9792],
            [-95.7842, 48.0309],
            [-95.5824, 47.9912],
            [-95.5822, 47.934],
            [-95.7094, 47.9358],
            [-95.6458, 47.8479],
            [-95.7728, 47.7609],
            [-96.0584, 47.7602],
            [-96.0953, 47.814],
            [-96.1599, 47.9269],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700385',
        NAME: 'Red Lake County Central Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 862770791,
        AWATER: 64282,
        INTPTLAT: '+47.8991442',
        INTPTLON: '-095.8699031',
        ELSDLEA: '',
        UNSDLEA: '00385',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8111, 46.0064],
            [-95.8113, 46.0208],
            [-95.708, 46.1219],
            [-95.5724, 46.1183],
            [-95.4794, 46.0719],
            [-95.5203, 45.9335],
            [-95.6101, 45.8517],
            [-95.6342, 45.8575],
            [-95.8315, 45.9484],
            [-95.8111, 46.0064],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700382',
        NAME: 'Brandon-Evansville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497820465,
        AWATER: 68247689,
        INTPTLAT: '+45.9963965',
        INTPTLON: '-095.6617685',
        ELSDLEA: '',
        UNSDLEA: '00382',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1634, 48.544],
            [-96.7805, 48.5435],
            [-96.5964, 48.4712],
            [-96.5624, 48.3843],
            [-96.5658, 48.3118],
            [-96.6219, 48.2825],
            [-96.9686, 48.2822],
            [-97.0867, 48.3257],
            [-97.1227, 48.4203],
            [-97.1634, 48.544],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700127',
        NAME: 'Stephen-Argyle Central Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1044808406,
        AWATER: 1432294,
        INTPTLAT: '+48.4181348',
        INTPTLON: '-096.8642414',
        ELSDLEA: '',
        UNSDLEA: '00127',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5624, 48.3843],
            [-96.387, 48.4136],
            [-96.2992, 48.457],
            [-96.2106, 48.369],
            [-96.0638, 48.369],
            [-96.0776, 48.2858],
            [-96.1348, 48.226],
            [-96.2585, 48.2263],
            [-96.3027, 48.1747],
            [-96.5008, 48.1741],
            [-96.5003, 48.2827],
            [-96.5658, 48.3118],
            [-96.5624, 48.3843],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723550',
        NAME: 'Marshall County Central Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794754988,
        AWATER: 3663,
        INTPTLAT: '+48.3059436',
        INTPTLON: '-096.3363092',
        ELSDLEA: '',
        UNSDLEA: '23550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7973, 48.5255],
            [-95.6661, 48.5109],
            [-95.582, 48.5386],
            [-95.3425, 48.5402],
            [-95.212, 48.54],
            [-95.211, 48.366],
            [-94.9501, 48.3667],
            [-94.9458, 48.1944],
            [-95.5938, 48.1945],
            [-95.5938, 48.1729],
            [-95.6607, 48.1729],
            [-95.7869, 48.2811],
            [-95.7867, 48.3391],
            [-95.981, 48.3383],
            [-95.9053, 48.4116],
            [-95.8286, 48.4107],
            [-95.7973, 48.5255],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718920',
        NAME: 'Grygla Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2088035507,
        AWATER: 4652648,
        INTPTLAT: '+48.3598285',
        INTPTLON: '-095.4220433',
        ELSDLEA: '',
        UNSDLEA: '18920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7103, 48.7458],
            [-96.6241, 48.8034],
            [-96.4722, 48.8045],
            [-96.3243, 48.5812],
            [-96.2355, 48.5159],
            [-96.2992, 48.457],
            [-96.387, 48.4136],
            [-96.5624, 48.3843],
            [-96.5964, 48.4712],
            [-96.7805, 48.5435],
            [-96.7151, 48.5874],
            [-96.7103, 48.7458],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791446',
        NAME: 'Tri-County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1155474412,
        AWATER: 11843510,
        INTPTLAT: '+48.6133652',
        INTPTLON: '-096.5350604',
        ELSDLEA: '',
        UNSDLEA: '91446',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1374, 48.4134],
            [-97.1227, 48.4203],
            [-97.0867, 48.3257],
            [-96.9686, 48.2822],
            [-96.6219, 48.2825],
            [-96.5658, 48.3118],
            [-96.5003, 48.2827],
            [-96.5008, 48.1741],
            [-96.5009, 48.0356],
            [-96.7468, 48.0071],
            [-96.7601, 48.0645],
            [-97.0841, 48.0564],
            [-97.1498, 48.2181],
            [-97.1456, 48.358],
            [-97.1374, 48.4134],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700024',
        NAME: 'Warren-Alvarado-Oslo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1299434813,
        AWATER: 2965037,
        INTPTLAT: '+48.1769101',
        INTPTLON: '-096.8124444',
        ELSDLEA: '',
        UNSDLEA: '00024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.732, 44.7143],
            [-92.6202, 44.6342],
            [-92.5399, 44.5675],
            [-92.4111, 44.5601],
            [-92.36, 44.4831],
            [-92.4468, 44.4479],
            [-92.6489, 44.4927],
            [-92.7017, 44.4703],
            [-92.7116, 44.4703],
            [-92.7868, 44.5652],
            [-92.732, 44.7143],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730480',
        NAME: 'Red Wing Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 425218295,
        AWATER: 31489979,
        INTPTLAT: '+44.5518381',
        INTPTLON: '-092.6089443',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5751, 45.4281],
            [-96.5027, 45.3987],
            [-96.1998, 45.4123],
            [-96.2085, 45.399],
            [-96.1475, 45.4054],
            [-96.1687, 45.3197],
            [-96.2305, 45.2612],
            [-96.1613, 45.1882],
            [-96.2069, 45.109],
            [-96.4522, 45.0883],
            [-96.4521, 45.1499],
            [-96.453, 45.2761],
            [-96.5555, 45.3842],
            [-96.5748, 45.391],
            [-96.5751, 45.4281],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700328',
        NAME: 'Ortonville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 734567142,
        AWATER: 35670047,
        INTPTLAT: '+45.2578189',
        INTPTLON: '-096.3329867',
        ELSDLEA: '',
        UNSDLEA: '00328',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5528, 43.7615],
            [-95.4524, 43.8117],
            [-95.4441, 43.7754],
            [-95.3955, 43.7536],
            [-95.359, 43.5945],
            [-95.3946, 43.5002],
            [-95.5, 43.5],
            [-95.5651, 43.4999],
            [-95.4926, 43.6452],
            [-95.5528, 43.7615],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700388',
        NAME: 'Round Lake-Brewster Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355350341,
        AWATER: 6452265,
        INTPTLAT: '+43.6340199',
        INTPTLON: '-095.4732115',
        ELSDLEA: '',
        UNSDLEA: '00388',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2251, 46.1086],
            [-96.1419, 46.1155],
            [-95.95, 46.1233],
            [-95.8939, 46.0212],
            [-95.8113, 46.0208],
            [-95.8111, 46.0064],
            [-95.8315, 45.9484],
            [-95.6342, 45.8575],
            [-95.6101, 45.8517],
            [-95.6034, 45.7735],
            [-95.638, 45.6869],
            [-95.717, 45.6944],
            [-95.8191, 45.7525],
            [-95.9437, 45.7596],
            [-95.9656, 45.8465],
            [-96.2251, 45.9576],
            [-96.2664, 45.9779],
            [-96.2251, 46.1086],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700104',
        NAME: 'West Central Area',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1146926948,
        AWATER: 53803025,
        INTPTLAT: '+45.8922198',
        INTPTLON: '-095.9400423',
        ELSDLEA: '',
        UNSDLEA: '00104',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9362, 46.1819],
            [-95.8012, 46.1983],
            [-95.708, 46.1219],
            [-95.8113, 46.0208],
            [-95.8939, 46.0212],
            [-95.95, 46.1233],
            [-95.9362, 46.1819],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703300',
        NAME: 'Ashby Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215641986,
        AWATER: 35903326,
        INTPTLAT: '+46.1181061',
        INTPTLON: '-095.8362743',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2541, 45.9122],
            [-96.2565, 45.9346],
            [-96.2251, 45.9576],
            [-95.9656, 45.8465],
            [-95.9437, 45.7596],
            [-96.1302, 45.7306],
            [-96.1582, 45.6944],
            [-96.1608, 45.6944],
            [-96.1716, 45.6944],
            [-96.2541, 45.6802],
            [-96.3163, 45.774],
            [-96.2541, 45.9122],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713890',
        NAME: 'Herman-Norcross School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517023585,
        AWATER: 11850614,
        INTPTLAT: '+45.8112296',
        INTPTLON: '-096.1491705',
        ELSDLEA: '',
        UNSDLEA: '13890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5822, 47.934],
            [-95.3856, 47.8047],
            [-95.1939, 47.8033],
            [-95.1849, 47.6299],
            [-95.5535, 47.6161],
            [-95.6436, 47.7612],
            [-95.7728, 47.7609],
            [-95.6458, 47.8479],
            [-95.7094, 47.9358],
            [-95.5822, 47.934],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700103',
        NAME: 'Clearbrook-Gonvick School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 843459360,
        AWATER: 18438949,
        INTPTLAT: '+47.7535781',
        INTPTLON: '-095.4621868',
        ELSDLEA: '',
        UNSDLEA: '00103',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9458, 48.1944],
            [-94.7225, 48.2086],
            [-94.7216, 47.9635],
            [-94.8039, 47.846],
            [-94.9246, 47.8027],
            [-95.1939, 47.8033],
            [-95.3856, 47.8047],
            [-95.5822, 47.934],
            [-95.5824, 47.9912],
            [-95.5938, 48.1729],
            [-95.5938, 48.1945],
            [-94.9458, 48.1944],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730510',
        NAME: 'Red Lake Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1656402850,
        AWATER: 975297803,
        INTPTLAT: '+47.8715933',
        INTPTLON: '-095.1475384',
        ELSDLEA: '',
        UNSDLEA: '30510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6476, 46.1826],
            [-94.5783, 46.1946],
            [-94.3804, 46.1945],
            [-94.3746, 46.1571],
            [-94.125, 46.156],
            [-94.2194, 45.9119],
            [-94.3745, 45.9107],
            [-94.4106, 45.8621],
            [-94.4105, 45.8582],
            [-94.4201, 45.8549],
            [-94.431, 45.8549],
            [-94.5448, 45.8906],
            [-94.5765, 46.0128],
            [-94.6479, 46.0569],
            [-94.6476, 46.1826],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718240',
        NAME: 'Little Falls Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057192169,
        AWATER: 20747990,
        INTPTLAT: '+46.0420885',
        INTPTLON: '-094.4054992',
        ELSDLEA: '',
        UNSDLEA: '18240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.8751, 46.1793],
              [-94.859, 46.1832],
              [-94.8541, 46.1832],
              [-94.8751, 46.1718],
              [-94.8751, 46.1793],
            ],
          ],
          [
            [
              [-95.1461, 46.0629],
              [-95.1463, 46.1941],
              [-94.9156, 46.2304],
              [-94.8853, 46.1868],
              [-94.8751, 46.1718],
              [-94.8905, 46.1214],
              [-94.9568, 46.0922],
              [-94.9799, 46.0415],
              [-95.1052, 46.0201],
              [-95.1467, 46.0198],
              [-95.1461, 46.0629],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700112',
        NAME: 'Eagle Valley Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347414554,
        AWATER: 464240,
        INTPTLAT: '+46.1386165',
        INTPTLON: '-095.0298095',
        ELSDLEA: '',
        UNSDLEA: '00112',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0982, 46.3542],
            [-95.098, 46.3688],
            [-95.0301, 46.4993],
            [-94.9679, 46.543],
            [-94.8633, 46.5431],
            [-94.8318, 46.4991],
            [-94.9671, 46.3542],
            [-94.9671, 46.3036],
            [-95.0982, 46.3542],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740920',
        NAME: 'Verndale Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271702236,
        AWATER: 0,
        INTPTLAT: '+46.4361563',
        INTPTLON: '-094.9638265',
        ELSDLEA: '',
        UNSDLEA: '40920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4945, 43.7395],
            [-92.3692, 43.8193],
            [-92.3436, 43.7724],
            [-92.1591, 43.7607],
            [-92.1591, 43.7536],
            [-92.2291, 43.5873],
            [-92.2991, 43.5659],
            [-92.4245, 43.5876],
            [-92.4595, 43.6896],
            [-92.4793, 43.6961],
            [-92.4945, 43.7395],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700007',
        NAME: 'Kingsland Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476433913,
        AWATER: 157942,
        INTPTLAT: '+43.6950381',
        INTPTLON: '-092.3179725',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2991, 43.5659],
            [-92.2291, 43.5873],
            [-92.1591, 43.7536],
            [-92.1591, 43.7607],
            [-92.0539, 43.753],
            [-91.9904, 43.645],
            [-91.9703, 43.6413],
            [-91.9697, 43.5007],
            [-91.9707, 43.5006],
            [-92.2894, 43.5006],
            [-92.2991, 43.5659],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700100',
        NAME: 'Fillmore Central',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548228265,
        AWATER: 0,
        INTPTLAT: '+43.6030910',
        INTPTLON: '-092.1088110',
        ELSDLEA: '',
        UNSDLEA: '00100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9896, 43.8434],
            [-91.9796, 43.8436],
            [-91.9097, 43.7814],
            [-91.9098, 43.7173],
            [-91.8296, 43.6739],
            [-91.8097, 43.6451],
            [-91.9703, 43.6413],
            [-91.9904, 43.645],
            [-92.0539, 43.753],
            [-91.9896, 43.8434],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717910',
        NAME: 'Lanesboro Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270298462,
        AWATER: 770320,
        INTPTLAT: '+43.7275800',
        INTPTLON: '-091.9447447',
        ELSDLEA: '',
        UNSDLEA: '17910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5138, 43.9641],
            [-95.4012, 43.8913],
            [-95.4524, 43.8117],
            [-95.5528, 43.7615],
            [-95.733, 43.7615],
            [-95.8224, 43.7901],
            [-95.8524, 43.8485],
            [-95.6827, 43.9348],
            [-95.5138, 43.9641],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712480',
        NAME: 'Fulda Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547705467,
        AWATER: 10848465,
        INTPTLAT: '+43.8579099',
        INTPTLON: '-095.6291080',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0643, 44.0662],
            [-95.9484, 44.1169],
            [-95.7078, 44.1236],
            [-95.7347, 44.0392],
            [-95.6027, 44.0221],
            [-95.5138, 43.9641],
            [-95.6827, 43.9348],
            [-95.8524, 43.8485],
            [-95.9429, 43.8266],
            [-96.0043, 43.849],
            [-96.024, 43.9789],
            [-96.0643, 44.0662],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700090',
        NAME: 'Murray County Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801300843,
        AWATER: 9809184,
        INTPTLAT: '+43.9869789',
        INTPTLON: '-095.8416774',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.0315, 44.7537],
              [-96.0314, 44.761],
              [-95.9609, 44.7503],
              [-95.9407, 44.7541],
              [-95.9304, 44.7468],
              [-95.9516, 44.7214],
              [-95.9712, 44.7177],
              [-95.9814, 44.7176],
              [-96.0118, 44.7173],
              [-96.0315, 44.7537],
            ],
          ],
          [
            [
              [-96.1749, 44.878],
              [-96.1658, 44.9792],
              [-96.0913, 44.9793],
              [-96.0546, 45.0634],
              [-95.9403, 45.0519],
              [-95.8993, 44.9932],
              [-95.8294, 44.8991],
              [-95.8287, 44.8409],
              [-95.849, 44.8053],
              [-96.0111, 44.8049],
              [-96.0314, 44.761],
              [-96.0411, 44.7574],
              [-96.1954, 44.8324],
              [-96.1749, 44.878],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2710090',
        NAME: 'Dawson-Boyd Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638401945,
        AWATER: 1679500,
        INTPTLAT: '+44.8993198',
        INTPTLON: '-096.0123720',
        ELSDLEA: '',
        UNSDLEA: '10090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0431, 43.7682],
            [-96.0333, 43.7756],
            [-95.9429, 43.8266],
            [-95.8524, 43.8485],
            [-95.8224, 43.7901],
            [-95.8422, 43.7832],
            [-95.8125, 43.761],
            [-95.8141, 43.5507],
            [-95.9336, 43.5436],
            [-95.9937, 43.587],
            [-96.0331, 43.6884],
            [-96.0431, 43.7682],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702730',
        NAME: 'Adrian Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 462384497,
        AWATER: 688731,
        INTPTLAT: '+43.6921400',
        INTPTLON: '-095.9207079',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8422, 43.7832],
            [-95.8224, 43.7901],
            [-95.733, 43.7615],
            [-95.5528, 43.7615],
            [-95.4926, 43.6452],
            [-95.5651, 43.4999],
            [-95.774, 43.4999],
            [-95.8141, 43.5507],
            [-95.8125, 43.761],
            [-95.8422, 43.7832],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2744160',
        NAME: 'Worthington Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 672095578,
        AWATER: 11674021,
        INTPTLAT: '+43.6415789',
        INTPTLON: '-095.6597357',
        ELSDLEA: '',
        UNSDLEA: '44160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4524, 43.8117],
            [-95.4012, 43.8913],
            [-95.3502, 43.9347],
            [-95.341, 43.9203],
            [-95.2157, 43.7905],
            [-95.3051, 43.6092],
            [-95.359, 43.5945],
            [-95.3955, 43.7536],
            [-95.4441, 43.7754],
            [-95.4524, 43.8117],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713930',
        NAME: 'Heron Lake-Okabena School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389961188,
        AWATER: 9667876,
        INTPTLAT: '+43.7674628',
        INTPTLON: '-095.3490939',
        ELSDLEA: '',
        UNSDLEA: '13930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6141, 44.4285],
            [-95.5946, 44.5006],
            [-95.5332, 44.5418],
            [-95.5029, 44.5014],
            [-95.4727, 44.3849],
            [-95.4726, 44.3703],
            [-95.5234, 44.3266],
            [-95.6143, 44.3704],
            [-95.6143, 44.3777],
            [-95.6143, 44.3849],
            [-95.6141, 44.4285],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721210',
        NAME: 'Milroy Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225626173,
        AWATER: 1032101,
        INTPTLAT: '+44.4344121',
        INTPTLON: '-095.5307440',
        ELSDLEA: '',
        UNSDLEA: '21210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3992, 45.0659],
            [-94.3793, 45.1522],
            [-94.256, 45.2111],
            [-94.256, 45.2113],
            [-94.1042, 45.1522],
            [-94.1147, 45.116],
            [-94.1343, 44.9788],
            [-94.2561, 44.9795],
            [-94.3532, 44.9862],
            [-94.4044, 45.0149],
            [-94.4122, 45.023],
            [-94.3992, 45.0659],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2710060',
        NAME: 'Dassel-Cokato Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421299978,
        AWATER: 35339801,
        INTPTLAT: '+45.0827058',
        INTPTLON: '-094.2583745',
        ELSDLEA: '',
        UNSDLEA: '10060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1176, 45.3981],
            [-94.0814, 45.3731],
            [-94.0142, 45.3619],
            [-94.0041, 45.34],
            [-93.954, 45.2962],
            [-94.0562, 45.2932],
            [-94.0579, 45.1613],
            [-94.1042, 45.1522],
            [-94.256, 45.2113],
            [-94.2571, 45.3057],
            [-94.1581, 45.3258],
            [-94.1176, 45.3981],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703150',
        NAME: 'Annandale Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301480049,
        AWATER: 48980394,
        INTPTLAT: '+45.2695378',
        INTPTLON: '-094.1215491',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4415, 45.3555],
            [-94.3335, 45.3936],
            [-94.2333, 45.4211],
            [-94.1176, 45.3981],
            [-94.1581, 45.3258],
            [-94.2571, 45.3057],
            [-94.256, 45.2113],
            [-94.256, 45.2111],
            [-94.4553, 45.2572],
            [-94.4415, 45.3555],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717220',
        NAME: 'Kimball Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316718434,
        AWATER: 12559862,
        INTPTLAT: '+45.3301778',
        INTPTLON: '-094.2868138',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0572, 45.8164],
            [-94.0209, 45.8239],
            [-93.9902, 45.8613],
            [-93.8918, 45.9105],
            [-93.8824, 45.8448],
            [-93.8677, 45.837],
            [-93.8175, 45.7643],
            [-93.8028, 45.7206],
            [-93.7704, 45.6488],
            [-93.8526, 45.56],
            [-93.9452, 45.5379],
            [-94.0183, 45.5592],
            [-94.0572, 45.8164],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712240',
        NAME: 'Foley Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 684313559,
        AWATER: 452339,
        INTPTLAT: '+45.7090658',
        INTPTLON: '-093.9148163',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1754, 45.7938],
            [-94.1248, 45.8232],
            [-94.1241, 45.8232],
            [-94.0733, 45.8235],
            [-94.0686, 45.8235],
            [-94.0572, 45.8164],
            [-94.0183, 45.5592],
            [-94.1686, 45.5859],
            [-94.2535, 45.7389],
            [-94.2564, 45.7403],
            [-94.2638, 45.7503],
            [-94.2717, 45.775],
            [-94.1754, 45.7938],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732880',
        NAME: 'Sauk Rapids-Rice Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350624952,
        AWATER: 10729878,
        INTPTLAT: '+45.6963282',
        INTPTLON: '-094.1242278',
        ELSDLEA: '',
        UNSDLEA: '32880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8997, 45.1902],
            [-94.8132, 45.2828],
            [-94.7418, 45.2464],
            [-94.6415, 45.2722],
            [-94.6243, 45.2461],
            [-94.6351, 44.9935],
            [-94.5632, 44.9505],
            [-94.5432, 44.8993],
            [-94.5435, 44.8921],
            [-94.8034, 44.8922],
            [-94.9366, 44.9651],
            [-94.8799, 45.0664],
            [-94.8997, 45.1902],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700106',
        NAME: 'A.C.G.C. Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 866074790,
        AWATER: 42072683,
        INTPTLAT: '+45.0735832',
        INTPTLON: '-094.7472047',
        ELSDLEA: '',
        UNSDLEA: '00106',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1832, 45.3261],
            [-95.0598, 45.369],
            [-94.8454, 45.3698],
            [-94.8132, 45.2828],
            [-94.8997, 45.1902],
            [-95.0628, 45.2389],
            [-95.1852, 45.2387],
            [-95.1832, 45.3261],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723400',
        NAME: 'New London-Spicer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352577897,
        AWATER: 79607408,
        INTPTLAT: '+45.2902759',
        INTPTLON: '-094.9950583',
        ELSDLEA: '',
        UNSDLEA: '23400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2268, 45.1452],
            [-95.1855, 45.1972],
            [-95.16, 45.2258],
            [-95.1852, 45.2387],
            [-95.0628, 45.2389],
            [-94.8997, 45.1902],
            [-94.8799, 45.0664],
            [-94.9366, 44.9651],
            [-95.0439, 44.91],
            [-95.0846, 44.979],
            [-95.0876, 45.0813],
            [-95.1491, 45.1099],
            [-95.2268, 45.1452],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742720',
        NAME: 'Willmar Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525307955,
        AWATER: 43997965,
        INTPTLAT: '+45.0876664',
        INTPTLON: '-095.0236757',
        ELSDLEA: '',
        UNSDLEA: '42720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3778, 43.6011],
            [-94.4184, 43.7529],
            [-94.2773, 43.8185],
            [-94.1653, 43.7026],
            [-94.2479, 43.558],
            [-94.228, 43.5001],
            [-94.3947, 43.5003],
            [-94.4375, 43.5004],
            [-94.3778, 43.6011],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713040',
        NAME: 'Granada-Huntley-East Chain',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514013695,
        AWATER: 4281296,
        INTPTLAT: '+43.6369519',
        INTPTLON: '-094.3165151',
        ELSDLEA: '',
        UNSDLEA: '13040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.4013, 44.5299],
              [-95.3909, 44.5296],
              [-95.3803, 44.5371],
              [-95.3605, 44.5297],
              [-95.391, 44.522],
              [-95.3956, 44.522],
              [-95.4013, 44.5219],
              [-95.4013, 44.5299],
            ],
          ],
          [
            [
              [-95.8287, 44.8409],
              [-95.8294, 44.8991],
              [-95.7187, 44.8561],
              [-95.5952, 44.9056],
              [-95.4299, 44.8472],
              [-95.4531, 44.7924],
              [-95.3602, 44.6985],
              [-95.3803, 44.5371],
              [-95.3855, 44.537],
              [-95.4013, 44.5299],
              [-95.5029, 44.5014],
              [-95.5332, 44.5418],
              [-95.4622, 44.6361],
              [-95.5079, 44.727],
              [-95.6041, 44.6664],
              [-95.7882, 44.6744],
              [-95.8696, 44.6308],
              [-95.931, 44.6598],
              [-95.9311, 44.6672],
              [-95.9814, 44.7176],
              [-95.9712, 44.7177],
              [-95.9516, 44.7214],
              [-95.9304, 44.7468],
              [-95.9407, 44.7541],
              [-95.9609, 44.7503],
              [-96.0314, 44.761],
              [-96.0111, 44.8049],
              [-95.849, 44.8053],
              [-95.8287, 44.8409],
            ],
          ],
          [
            [
              [-96.0314, 44.761],
              [-96.0315, 44.7537],
              [-96.0411, 44.7574],
              [-96.0314, 44.761],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700099',
        NAME: 'Yellow Medicine East',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1084015131,
        AWATER: 6332457,
        INTPTLAT: '+44.7392517',
        INTPTLON: '-095.6553503',
        ELSDLEA: '',
        UNSDLEA: '00099',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4522, 45.0883],
            [-96.2069, 45.109],
            [-96.1613, 45.1882],
            [-96.2305, 45.2612],
            [-96.1687, 45.3197],
            [-96.1475, 45.4054],
            [-95.9943, 45.4123],
            [-95.9325, 45.4124],
            [-95.8598, 45.3691],
            [-95.82, 45.2755],
            [-95.8196, 45.2463],
            [-95.6749, 45.1514],
            [-95.6616, 45.0861],
            [-95.6516, 45.0718],
            [-95.6847, 45.0573],
            [-95.7062, 45.0573],
            [-95.8993, 44.9932],
            [-95.9403, 45.0519],
            [-96.0546, 45.0634],
            [-96.0913, 44.9793],
            [-96.1658, 44.9792],
            [-96.1749, 44.878],
            [-96.1954, 44.8324],
            [-96.3994, 44.8922],
            [-96.4516, 44.8487],
            [-96.4518, 44.8911],
            [-96.4522, 45.0883],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700125',
        NAME: 'Lac Qui Parle Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1903475683,
        AWATER: 65455793,
        INTPTLAT: '+45.1318276',
        INTPTLON: '-096.0732220',
        ELSDLEA: '',
        UNSDLEA: '00125',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6109, 45.76],
            [-94.5762, 45.7598],
            [-94.5282, 45.7021],
            [-94.4097, 45.6021],
            [-94.442, 45.5739],
            [-94.6139, 45.4742],
            [-94.7014, 45.499],
            [-94.7021, 45.5712],
            [-94.6346, 45.6429],
            [-94.6109, 45.76],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702930',
        NAME: 'Albany Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377950398,
        AWATER: 15001782,
        INTPTLAT: '+45.6081637',
        INTPTLON: '-094.5642547',
        ELSDLEA: '',
        UNSDLEA: '02930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.2638, 45.7503],
              [-94.2564, 45.7403],
              [-94.2649, 45.7403],
              [-94.2638, 45.7503],
            ],
          ],
          [
            [
              [-94.2535, 45.7389],
              [-94.1686, 45.5859],
              [-94.3198, 45.5998],
              [-94.3402, 45.6788],
              [-94.2535, 45.7389],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732820',
        NAME: 'Sartell-St. Stephen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120447761,
        AWATER: 2699236,
        INTPTLAT: '+45.6461437',
        INTPTLON: '-094.2477597',
        ELSDLEA: '',
        UNSDLEA: '32820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7103, 43.6664],
            [-91.7005, 43.6698],
            [-91.6109, 43.6439],
            [-91.5457, 43.5947],
            [-91.5211, 43.5006],
            [-91.6108, 43.5007],
            [-91.7302, 43.5008],
            [-91.7103, 43.6664],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733300',
        NAME: 'Spring Grove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233889905,
        AWATER: 424154,
        INTPTLAT: '+43.5755838',
        INTPTLON: '-091.6318898',
        ELSDLEA: '',
        UNSDLEA: '33300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3189, 44.0644],
            [-92.1392, 44.0711],
            [-92.0887, 43.9796],
            [-92.1158, 43.9193],
            [-92.3187, 43.9558],
            [-92.3189, 44.0644],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711010',
        NAME: 'Dover-Eyota Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265384367,
        AWATER: 0,
        INTPTLAT: '+43.9949007',
        INTPTLON: '-092.2154739',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.609, 43.5869],
            [-92.4893, 43.6162],
            [-92.4793, 43.6961],
            [-92.4595, 43.6896],
            [-92.4245, 43.5876],
            [-92.2991, 43.5659],
            [-92.2894, 43.5006],
            [-92.4455, 43.5005],
            [-92.6288, 43.5002],
            [-92.609, 43.5869],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718060',
        NAME: 'LeRoy-Ostrander Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311749291,
        AWATER: 124890,
        INTPTLAT: '+43.5655039',
        INTPTLON: '-092.4712195',
        ELSDLEA: '',
        UNSDLEA: '18060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1392, 44.0711],
            [-92.1385, 44.0935],
            [-92.007, 44.1136],
            [-91.9861, 44.1428],
            [-92.0099, 44.0418],
            [-91.9594, 44.0204],
            [-91.9437, 43.9125],
            [-91.9594, 43.8764],
            [-91.9796, 43.8436],
            [-91.9896, 43.8434],
            [-92.0793, 43.8471],
            [-92.1158, 43.9193],
            [-92.0887, 43.9796],
            [-92.1392, 44.0711],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733450',
        NAME: 'St. Charles Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342487914,
        AWATER: 29239,
        INTPTLAT: '+43.9844145',
        INTPTLON: '-092.0394562',
        ELSDLEA: '',
        UNSDLEA: '33450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.3795, 44.3343],
              [-95.3702, 44.3266],
              [-95.3795, 44.3268],
              [-95.3795, 44.3343],
            ],
          ],
          [
            [
              [-95.4727, 44.3849],
              [-95.4615, 44.378],
              [-95.4727, 44.3779],
              [-95.4726, 44.3735],
              [-95.4726, 44.3703],
              [-95.4727, 44.3849],
            ],
          ],
          [
            [
              [-95.5234, 44.3266],
              [-95.4726, 44.3703],
              [-95.4615, 44.378],
              [-95.3795, 44.3268],
              [-95.3497, 44.268],
              [-95.3722, 44.0506],
              [-95.3502, 43.9347],
              [-95.4012, 43.8913],
              [-95.5138, 43.9641],
              [-95.6027, 44.0221],
              [-95.5228, 44.1088],
              [-95.5234, 44.3266],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700183',
        NAME: 'Westbrook-Walnut Grove Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 668555624,
        AWATER: 6697365,
        INTPTLAT: '+44.1304554',
        INTPTLON: '-095.4523926',
        ELSDLEA: '',
        UNSDLEA: '00183',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3795, 44.3268],
            [-95.3702, 44.3266],
            [-95.1678, 44.2964],
            [-95.0973, 44.3507],
            [-95.098, 44.2386],
            [-95.0407, 44.1514],
            [-95.0199, 44.0502],
            [-95.0998, 44.0069],
            [-95.2609, 43.9784],
            [-95.341, 43.9203],
            [-95.3502, 43.9347],
            [-95.3722, 44.0506],
            [-95.3497, 44.268],
            [-95.3795, 44.3268],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700132',
        NAME: 'Red Rock Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 888395923,
        AWATER: 4215255,
        INTPTLAT: '+44.1444711',
        INTPTLON: '-095.2306374',
        ELSDLEA: '',
        UNSDLEA: '00132',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.067, 47.2388],
            [-95.89, 47.2671],
            [-95.6792, 47.2661],
            [-95.6789, 47.2085],
            [-95.5513, 47.2093],
            [-95.5512, 47.1515],
            [-95.2973, 47.1518],
            [-95.2973, 47.0797],
            [-95.5301, 47.0784],
            [-95.8063, 47.064],
            [-95.8591, 46.9995],
            [-95.9975, 47.0211],
            [-96.0666, 47.0571],
            [-96.1097, 47.1517],
            [-96.067, 47.2388],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742120',
        NAME: 'Waubun-Ogema-White Earth Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 961672593,
        AWATER: 73653352,
        INTPTLAT: '+47.1267802',
        INTPTLON: '-095.7871130',
        ELSDLEA: '',
        UNSDLEA: '42120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1582, 45.6944],
            [-96.1302, 45.7306],
            [-95.9437, 45.7596],
            [-95.8191, 45.7525],
            [-95.717, 45.6944],
            [-95.638, 45.6869],
            [-95.7065, 45.5426],
            [-95.8714, 45.5424],
            [-95.8504, 45.4558],
            [-95.9325, 45.4124],
            [-95.9943, 45.4123],
            [-95.9737, 45.4704],
            [-96.0639, 45.7015],
            [-96.1582, 45.6944],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700383',
        NAME: 'Morris Area Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765866058,
        AWATER: 21994909,
        INTPTLAT: '+45.6241226',
        INTPTLON: '-095.8929815',
        ELSDLEA: '',
        UNSDLEA: '00383',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3884, 43.8663],
            [-92.3399, 43.9056],
            [-92.3187, 43.9558],
            [-92.1158, 43.9193],
            [-92.0793, 43.8471],
            [-91.9896, 43.8434],
            [-92.0539, 43.753],
            [-92.1591, 43.7607],
            [-92.3436, 43.7724],
            [-92.3692, 43.8193],
            [-92.3683, 43.8554],
            [-92.3884, 43.8663],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708220',
        NAME: 'Chatfield Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422721059,
        AWATER: 0,
        INTPTLAT: '+43.8430231',
        INTPTLON: '-092.2043769',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9703, 43.6413],
            [-91.8097, 43.6451],
            [-91.8048, 43.6527],
            [-91.7154, 43.6664],
            [-91.7103, 43.6664],
            [-91.7302, 43.5008],
            [-91.811, 43.5007],
            [-91.9697, 43.5007],
            [-91.9703, 43.6413],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718540',
        NAME: 'Mabel-Canton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295264729,
        AWATER: 28009,
        INTPTLAT: '+43.5763611',
        INTPTLON: '-091.8301799',
        ELSDLEA: '',
        UNSDLEA: '18540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5983, 43.8919],
            [-92.6034, 43.8918],
            [-92.6282, 43.9171],
            [-92.6182, 43.9171],
            [-92.558, 43.935],
            [-92.3384, 43.9202],
            [-92.3373, 43.9131],
            [-92.3399, 43.9056],
            [-92.3884, 43.8663],
            [-92.3683, 43.8554],
            [-92.3692, 43.8193],
            [-92.4945, 43.7395],
            [-92.5593, 43.7904],
            [-92.6696, 43.7973],
            [-92.6783, 43.863],
            [-92.5983, 43.8919],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2738160',
        NAME: 'Stewartville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355647641,
        AWATER: 0,
        INTPTLAT: '+43.8471760',
        INTPTLON: '-092.5094270',
        ELSDLEA: '',
        UNSDLEA: '38160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9594, 43.8764],
            [-91.78, 43.8896],
            [-91.74, 43.9332],
            [-91.7091, 43.877],
            [-91.6608, 43.8321],
            [-91.7154, 43.6664],
            [-91.8048, 43.6527],
            [-91.8097, 43.6451],
            [-91.8296, 43.6739],
            [-91.9098, 43.7173],
            [-91.9097, 43.7814],
            [-91.9796, 43.8436],
            [-91.9594, 43.8764],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728290',
        NAME: 'Rushford-Peterson Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465527933,
        AWATER: 1583206,
        INTPTLAT: '+43.7863588',
        INTPTLON: '-091.7953632',
        ELSDLEA: '',
        UNSDLEA: '28290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1556, 46.4131],
            [-96.0981, 46.4146],
            [-96.0727, 46.4532],
            [-95.9679, 46.4273],
            [-95.8996, 46.3419],
            [-95.9461, 46.3046],
            [-95.9362, 46.1819],
            [-95.95, 46.1233],
            [-96.1419, 46.1155],
            [-96.2251, 46.1086],
            [-96.1943, 46.1953],
            [-96.2654, 46.2386],
            [-96.2805, 46.3477],
            [-96.1556, 46.4131],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711880',
        NAME: 'Fergus Falls Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 772268802,
        AWATER: 67331293,
        INTPTLAT: '+46.2724241',
        INTPTLON: '-096.0903114',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5296, 46.5001],
            [-96.4458, 46.5435],
            [-96.3437, 46.5296],
            [-96.2812, 46.5732],
            [-96.1556, 46.4131],
            [-96.2805, 46.3477],
            [-96.4872, 46.3839],
            [-96.5136, 46.4639],
            [-96.5296, 46.5001],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732460',
        NAME: 'Rothsay Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 448860441,
        AWATER: 3201513,
        INTPTLAT: '+46.4416465',
        INTPTLON: '-096.3541823',
        ELSDLEA: '',
        UNSDLEA: '32460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2045, 43.9358],
            [-96.1554, 43.9937],
            [-96.024, 43.9789],
            [-96.0043, 43.849],
            [-95.9429, 43.8266],
            [-96.0333, 43.7756],
            [-96.0431, 43.7682],
            [-96.1726, 43.7977],
            [-96.2414, 43.8486],
            [-96.2045, 43.9358],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711220',
        NAME: 'Edgerton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364629103,
        AWATER: 483223,
        INTPTLAT: '+43.8820974',
        INTPTLON: '-096.1016365',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6783, 44.1083],
            [-92.5993, 44.1144],
            [-92.5391, 44.0288],
            [-92.5682, 43.9857],
            [-92.558, 43.935],
            [-92.6182, 43.9171],
            [-92.6282, 43.9171],
            [-92.6479, 43.928],
            [-92.6783, 44.1083],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707350',
        NAME: 'Byron Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183255087,
        AWATER: 11010,
        INTPTLAT: '+44.0203987',
        INTPTLON: '-092.6182543',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.102, 44.204],
            [-93.0505, 44.3813],
            [-93.0104, 44.4286],
            [-92.9078, 44.3705],
            [-92.7727, 44.3921],
            [-92.7317, 44.384],
            [-92.7459, 44.2395],
            [-92.7989, 44.1965],
            [-93.0002, 44.1967],
            [-93.0457, 44.168],
            [-93.0747, 44.1751],
            [-93.102, 44.204],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717100',
        NAME: 'Kenyon-Wanamingo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574981734,
        AWATER: 179907,
        INTPTLAT: '+44.2935274',
        INTPTLON: '-092.9168760',
        ELSDLEA: '',
        UNSDLEA: '17100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.449, 45.3231],
            [-93.3678, 45.2842],
            [-93.1638, 45.2923],
            [-93.1425, 45.2035],
            [-93.168, 45.1893],
            [-93.2688, 45.1115],
            [-93.2808, 45.0717],
            [-93.32, 45.0801],
            [-93.3255, 45.1448],
            [-93.5213, 45.167],
            [-93.5017, 45.2706],
            [-93.449, 45.3231],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703180',
        NAME: 'Anoka-Hennepin Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428566589,
        AWATER: 16929040,
        INTPTLAT: '+45.2173219',
        INTPTLON: '-093.3334627',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8021, 45.101],
            [-93.7486, 45.0692],
            [-93.5838, 45.0584],
            [-93.5954, 45.0511],
            [-93.7373, 44.9924],
            [-93.7369, 44.9776],
            [-93.9178, 45.0146],
            [-93.8021, 45.101],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2710170',
        NAME: 'Delano Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160875392,
        AWATER: 7384803,
        INTPTLAT: '+45.0403830',
        INTPTLON: '-093.7693773',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5923, 44.9289],
            [-93.5427, 44.9348],
            [-93.4817, 44.9484],
            [-93.4823, 44.8917],
            [-93.5208, 44.8661],
            [-93.6624, 44.8913],
            [-93.5923, 44.9289],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711670',
        NAME: 'Minnetonka Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59582265,
        AWATER: 18498826,
        INTPTLAT: '+44.9052748',
        INTPTLON: '-093.5503185',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5208, 44.8661],
            [-93.4823, 44.8917],
            [-93.3991, 44.8843],
            [-93.3736, 44.8604],
            [-93.3988, 44.7988],
            [-93.4503, 44.8035],
            [-93.5204, 44.8043],
            [-93.5208, 44.8661],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714220',
        NAME: 'Eden Prairie Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80947030,
        AWATER: 7082376,
        INTPTLAT: '+44.8468508',
        INTPTLON: '-093.4573412',
        ELSDLEA: '',
        UNSDLEA: '14220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7373, 44.9924],
            [-93.6483, 44.9894],
            [-93.5923, 44.9289],
            [-93.6624, 44.8913],
            [-93.6831, 44.8909],
            [-93.7065, 44.9134],
            [-93.7064, 44.9205],
            [-93.7056, 44.9278],
            [-93.7369, 44.9776],
            [-93.7373, 44.9924],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2722920',
        NAME: 'Westonka Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50182089,
        AWATER: 26415440,
        INTPTLAT: '+44.9396488',
        INTPTLON: '-093.6651032',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5269, 45.1636],
            [-93.5213, 45.167],
            [-93.3255, 45.1448],
            [-93.32, 45.0801],
            [-93.3141, 45.0656],
            [-93.4616, 45.0465],
            [-93.522, 45.0804],
            [-93.543, 45.1093],
            [-93.5269, 45.1636],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2725200',
        NAME: 'Osseo Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161919995,
        AWATER: 7553400,
        INTPTLAT: '+45.1079725',
        INTPTLON: '-093.4304112',
        ELSDLEA: '',
        UNSDLEA: '25200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5954, 45.0511],
            [-93.5838, 45.0584],
            [-93.522, 45.0804],
            [-93.4616, 45.0465],
            [-93.4108, 44.9948],
            [-93.4817, 44.9484],
            [-93.5427, 44.9348],
            [-93.5954, 45.0511],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742160',
        NAME: 'Wayzata Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103819478,
        AWATER: 7382344,
        INTPTLAT: '+45.0102050',
        INTPTLON: '-093.4991063',
        ELSDLEA: '',
        UNSDLEA: '42160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4369, 44.1817],
            [-93.3532, 44.1962],
            [-93.2274, 44.2291],
            [-93.102, 44.204],
            [-93.0747, 44.1751],
            [-93.3065, 44.1313],
            [-93.4065, 44.1455],
            [-93.4369, 44.1817],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2719320',
        NAME: 'Medford Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194504879,
        AWATER: 259541,
        INTPTLAT: '+44.1740130',
        INTPTLON: '-093.2634920',
        ELSDLEA: '',
        UNSDLEA: '19320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6381, 44.1742],
            [-93.4448, 44.2038],
            [-93.4369, 44.1817],
            [-93.4065, 44.1455],
            [-93.4065, 43.9858],
            [-93.4366, 43.9931],
            [-93.5867, 43.9723],
            [-93.6472, 44.0004],
            [-93.6176, 44.0949],
            [-93.6381, 44.1742],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741880',
        NAME: 'Waseca Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394186984,
        AWATER: 7278905,
        INTPTLAT: '+44.0788088',
        INTPTLON: '-093.5189102',
        ELSDLEA: '',
        UNSDLEA: '41880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1938, 44.9053],
            [-93.1785, 44.8883],
            [-93.1979, 44.862],
            [-93.2473, 44.8623],
            [-93.2529, 44.8907],
            [-93.1938, 44.9053],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2799997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 18503138,
        AWATER: 940803,
        INTPTLAT: '+44.8793200',
        INTPTLON: '-093.2252724',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0318, 48.1885],
            [-90.8855, 48.2458],
            [-90.7616, 48.0983],
            [-90.3758, 48.0912],
            [-90.1326, 48.1118],
            [-90.0236, 48.0847],
            [-89.8991, 47.9881],
            [-89.8198, 48.0151],
            [-89.4834, 48.0137],
            [-89.6807, 47.7146],
            [-89.9571, 47.2911],
            [-90.6547, 47.3098],
            [-90.7952, 47.2443],
            [-91.0231, 47.465],
            [-91.0207, 47.888],
            [-91.0318, 48.1885],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713110',
        NAME: 'Cook County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3761415800,
        AWATER: 4887981182,
        INTPTLAT: '+47.7246717',
        INTPTLON: '-090.4437558',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9255, 44.6441],
            [-94.8129, 44.6299],
            [-94.8017, 44.6299],
            [-94.7942, 44.471],
            [-94.7552, 44.4476],
            [-94.8055, 44.3912],
            [-94.8663, 44.3189],
            [-94.9589, 44.3261],
            [-95.0769, 44.3836],
            [-95.0769, 44.3913],
            [-94.9464, 44.5005],
            [-94.9255, 44.6441],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700110',
        NAME: 'Cedar Mountain School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496603019,
        AWATER: 709378,
        INTPTLAT: '+44.4705864',
        INTPTLON: '-094.8958927',
        ELSDLEA: '',
        UNSDLEA: '00110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.3909, 44.5296],
              [-95.3855, 44.537],
              [-95.3803, 44.5371],
              [-95.3909, 44.5296],
            ],
          ],
          [
            [
              [-95.3602, 44.6985],
              [-95.1677, 44.6467],
              [-95.0647, 44.6505],
              [-94.9255, 44.6441],
              [-94.9464, 44.5005],
              [-95.0769, 44.3913],
              [-95.148, 44.4383],
              [-95.2498, 44.4855],
              [-95.3605, 44.5297],
              [-95.3803, 44.5371],
              [-95.3602, 44.6985],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700182',
        NAME: 'Redwood Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696313617,
        AWATER: 4012709,
        INTPTLAT: '+44.5631644',
        INTPTLON: '-095.1370458',
        ELSDLEA: '',
        UNSDLEA: '00182',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.638, 45.6869],
            [-95.6034, 45.7735],
            [-95.3149, 45.7589],
            [-95.2631, 45.7806],
            [-95.1396, 45.7522],
            [-95.1394, 45.6368],
            [-95.2656, 45.5131],
            [-95.3773, 45.499],
            [-95.3821, 45.4184],
            [-95.5311, 45.4478],
            [-95.6242, 45.4853],
            [-95.7065, 45.5426],
            [-95.638, 45.6869],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700019',
        NAME: 'Minnewaska School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1095483583,
        AWATER: 97736942,
        INTPTLAT: '+45.6286744',
        INTPTLON: '-095.4453550',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1467, 46.0198],
            [-95.1052, 46.0201],
            [-95.0844, 45.9462],
            [-94.9536, 45.8608],
            [-95.0167, 45.8538],
            [-95.1396, 45.7522],
            [-95.2631, 45.7806],
            [-95.1467, 46.0198],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2725140',
        NAME: 'Osakis Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369464453,
        AWATER: 32589716,
        INTPTLAT: '+45.8851530',
        INTPTLON: '-095.1055025',
        ELSDLEA: '',
        UNSDLEA: '25140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4299, 44.8472],
            [-95.3939, 44.891],
            [-95.2485, 44.8999],
            [-95.1965, 44.9059],
            [-95.1869, 44.9059],
            [-95.125, 44.964],
            [-95.0846, 44.979],
            [-95.0439, 44.91],
            [-95.0647, 44.6505],
            [-95.1677, 44.6467],
            [-95.3602, 44.6985],
            [-95.4531, 44.7924],
            [-95.4299, 44.8472],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700163',
        NAME: 'Renville County West School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 807480344,
        AWATER: 398085,
        INTPTLAT: '+44.7907181',
        INTPTLON: '-095.2186117',
        ELSDLEA: '',
        UNSDLEA: '00163',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4302, 47.4484],
            [-96.0674, 47.4559],
            [-96.0882, 47.3252],
            [-96.067, 47.2388],
            [-96.1097, 47.1517],
            [-96.3014, 47.1513],
            [-96.3645, 47.1966],
            [-96.3871, 47.4125],
            [-96.4302, 47.4484],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700101',
        NAME: 'Norman County East School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 731592995,
        AWATER: 3627212,
        INTPTLAT: '+47.3165421',
        INTPTLON: '-096.2256869',
        ELSDLEA: '',
        UNSDLEA: '00101',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9204, 47.7114],
            [-96.8781, 47.7172],
            [-96.8678, 47.7171],
            [-96.8032, 47.688],
            [-96.7077, 47.6732],
            [-96.7075, 47.586],
            [-96.6811, 47.4987],
            [-96.7726, 47.4553],
            [-96.8581, 47.4477],
            [-96.8561, 47.4938],
            [-96.8499, 47.5908],
            [-96.9204, 47.7114],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709360',
        NAME: 'Climax-Shelly Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313805646,
        AWATER: 416686,
        INTPTLAT: '+47.5940442',
        INTPTLON: '-096.7922684',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0674, 47.4559],
            [-96.0676, 47.4991],
            [-95.8692, 47.4993],
            [-95.8053, 47.4114],
            [-95.551, 47.4112],
            [-95.5513, 47.2093],
            [-95.6789, 47.2085],
            [-95.6792, 47.2661],
            [-95.89, 47.2671],
            [-96.067, 47.2388],
            [-96.0882, 47.3252],
            [-96.0674, 47.4559],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718660',
        NAME: 'Mahnomen Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 861719254,
        AWATER: 31564263,
        INTPTLAT: '+47.3456638',
        INTPTLON: '-095.8395570',
        ELSDLEA: '',
        UNSDLEA: '18660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5793, 46.1524],
            [-96.3277, 46.152],
            [-96.2654, 46.2386],
            [-96.1943, 46.1953],
            [-96.2251, 46.1086],
            [-96.2664, 45.9779],
            [-96.2565, 45.9346],
            [-96.2541, 45.9122],
            [-96.3964, 45.9039],
            [-96.4926, 45.935],
            [-96.5766, 46.0218],
            [-96.5632, 46.1086],
            [-96.5793, 46.1524],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707450',
        NAME: 'Campbell-Tintah Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 689111683,
        AWATER: 136024,
        INTPTLAT: '+46.0602873',
        INTPTLON: '-096.3761962',
        ELSDLEA: '',
        UNSDLEA: '07450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6991, 45.722],
            [-96.6574, 45.7426],
            [-96.6466, 45.6507],
            [-96.8355, 45.5861],
            [-96.8386, 45.6475],
            [-96.6991, 45.722],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707110',
        NAME: 'Browns Valley Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120334758,
        AWATER: 18125295,
        INTPTLAT: '+45.6513049',
        INTPTLON: '-096.7505553',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.2664, 45.9779],
              [-96.2251, 45.9576],
              [-96.2565, 45.9346],
              [-96.2664, 45.9779],
            ],
          ],
          [
            [
              [-96.6574, 45.7426],
              [-96.5831, 45.82],
              [-96.5637, 45.9352],
              [-96.5766, 46.0218],
              [-96.4926, 45.935],
              [-96.3964, 45.9039],
              [-96.2541, 45.9122],
              [-96.3163, 45.774],
              [-96.2541, 45.6802],
              [-96.254, 45.6727],
              [-96.4895, 45.6724],
              [-96.6466, 45.6507],
              [-96.6574, 45.7426],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742330',
        NAME: 'Wheaton Area Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 739232766,
        AWATER: 11110093,
        INTPTLAT: '+45.8038629',
        INTPTLON: '-096.4578202',
        ELSDLEA: '',
        UNSDLEA: '42330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4818, 45.2098],
            [-95.4502, 45.2966],
            [-95.3577, 45.4125],
            [-95.2552, 45.4125],
            [-95.1832, 45.3338],
            [-95.1832, 45.3261],
            [-95.1852, 45.2387],
            [-95.16, 45.2258],
            [-95.1855, 45.1972],
            [-95.2268, 45.1452],
            [-95.2469, 45.1018],
            [-95.5016, 45.1149],
            [-95.4818, 45.2098],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717120',
        NAME: 'Kerkhoven-Murdock-Sunburg',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 632123481,
        AWATER: 13025695,
        INTPTLAT: '+45.2458922',
        INTPTLON: '-095.3379434',
        ELSDLEA: '',
        UNSDLEA: '17120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.4208, 44.2082],
              [-92.4207, 44.2124],
              [-92.4008, 44.2082],
              [-92.4208, 44.2082],
            ],
          ],
          [
            [
              [-92.4408, 44.2236],
              [-92.4409, 44.2164],
              [-92.4454, 44.2164],
              [-92.451, 44.2202],
              [-92.4408, 44.2236],
            ],
          ],
          [
            [
              [-92.451, 44.2202],
              [-92.451, 44.2164],
              [-92.451, 44.2127],
              [-92.451, 44.2202],
            ],
          ],
          [
            [
              [-92.5433, 44.1948],
              [-92.5334, 44.1912],
              [-92.5336, 44.1803],
              [-92.5433, 44.1948],
            ],
          ],
          [
            [
              [-92.5993, 44.1144],
              [-92.5287, 44.1299],
              [-92.5777, 44.1588],
              [-92.5336, 44.1803],
              [-92.5184, 44.173],
              [-92.5171, 44.1875],
              [-92.4799, 44.1946],
              [-92.4008, 44.2082],
              [-92.4211, 44.2232],
              [-92.4408, 44.2236],
              [-92.451, 44.238],
              [-92.4389, 44.238],
              [-92.3608, 44.252],
              [-92.3901, 44.1514],
              [-92.3189, 44.0644],
              [-92.3187, 43.9558],
              [-92.3399, 43.9056],
              [-92.3373, 43.9131],
              [-92.3384, 43.9202],
              [-92.558, 43.935],
              [-92.5682, 43.9857],
              [-92.5391, 44.0288],
              [-92.5993, 44.1144],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2731800',
        NAME: 'Rochester Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557399901,
        AWATER: 3635047,
        INTPTLAT: '+44.0546582',
        INTPTLON: '-092.4425662',
        ELSDLEA: '',
        UNSDLEA: '31800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8696, 44.6308],
            [-95.7882, 44.6744],
            [-95.6041, 44.6664],
            [-95.5079, 44.727],
            [-95.4622, 44.6361],
            [-95.5332, 44.5418],
            [-95.5946, 44.5006],
            [-95.696, 44.5011],
            [-95.8495, 44.5761],
            [-95.8696, 44.6308],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700088',
        NAME: 'Lakeview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455129273,
        AWATER: 8965168,
        INTPTLAT: '+44.6086870',
        INTPTLON: '-095.6348351',
        ELSDLEA: '',
        UNSDLEA: '00088',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0478, 47.4152],
            [-91.7902, 47.4167],
            [-91.7993, 47.7575],
            [-91.8002, 48.0659],
            [-91.7983, 48.2029],
            [-91.7152, 48.1993],
            [-91.7118, 48.1146],
            [-91.4296, 48.0486],
            [-91.2501, 48.0841],
            [-91.0318, 48.1885],
            [-91.0207, 47.888],
            [-91.0231, 47.465],
            [-90.7952, 47.2443],
            [-91.5494, 46.8932],
            [-91.8864, 46.7591],
            [-91.9202, 46.8715],
            [-91.9199, 47.1987],
            [-92.0476, 47.1982],
            [-92.0478, 47.4152],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740740',
        NAME: 'Lake Superior Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6220860312,
        AWATER: 2431614686,
        INTPTLAT: '+47.4744895',
        INTPTLON: '-091.4873255',
        ELSDLEA: '',
        UNSDLEA: '40740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7992, 47.6292],
            [-92.691, 47.6768],
            [-92.6889, 47.5729],
            [-92.5591, 47.573],
            [-92.5597, 47.4891],
            [-92.6231, 47.4593],
            [-92.8068, 47.4528],
            [-92.8069, 47.4677],
            [-92.7992, 47.6292],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700001',
        NAME: 'Mountain Iron-Buhl School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346732504,
        AWATER: 13450438,
        INTPTLAT: '+47.5654822',
        INTPTLON: '-092.7232734',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8692, 43.6713],
            [-92.8094, 43.7692],
            [-92.6892, 43.7758],
            [-92.6297, 43.6957],
            [-92.609, 43.5869],
            [-92.6288, 43.5002],
            [-92.6726, 43.5001],
            [-92.8358, 43.4999],
            [-92.9385, 43.5871],
            [-92.8692, 43.6713],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702720',
        NAME: 'Southland Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 518924453,
        AWATER: 0,
        INTPTLAT: '+43.6241708',
        INTPTLON: '-092.7431067',
        ELSDLEA: '',
        UNSDLEA: '02720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4071, 46.5438],
            [-94.1875, 46.5299],
            [-94.0683, 46.4609],
            [-93.8106, 46.3573],
            [-93.8107, 46.2863],
            [-93.9373, 46.1566],
            [-94.125, 46.156],
            [-94.3746, 46.1571],
            [-94.3804, 46.1945],
            [-94.3273, 46.2897],
            [-94.3702, 46.3691],
            [-94.3724, 46.5001],
            [-94.4146, 46.514],
            [-94.4071, 46.5438],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2706090',
        NAME: 'Brainerd Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1175360485,
        AWATER: 164792847,
        INTPTLAT: '+46.3229193',
        INTPTLON: '-094.1252103',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4195, 45.8045],
            [-94.2717, 45.775],
            [-94.2638, 45.7503],
            [-94.2649, 45.7403],
            [-94.2564, 45.7403],
            [-94.2535, 45.7389],
            [-94.3402, 45.6788],
            [-94.3198, 45.5998],
            [-94.4097, 45.6021],
            [-94.5282, 45.7021],
            [-94.5762, 45.7598],
            [-94.4195, 45.8045],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714190',
        NAME: 'Holdingford Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313983258,
        AWATER: 2987596,
        INTPTLAT: '+45.7126891',
        INTPTLON: '-094.4059426',
        ELSDLEA: '',
        UNSDLEA: '14190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.168, 45.1893],
            [-93.1425, 45.2035],
            [-93.0409, 45.182],
            [-93.056, 45.1458],
            [-93.0872, 45.124],
            [-93.2047, 45.1244],
            [-93.168, 45.1893],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708100',
        NAME: 'Centennial Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60607563,
        AWATER: 11046698,
        INTPTLAT: '+45.1573025',
        INTPTLON: '-093.1079788',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5106, 45.4719],
            [-93.4615, 45.4718],
            [-93.3067, 45.4713],
            [-93.2527, 45.4138],
            [-93.1421, 45.4136],
            [-93.1638, 45.2923],
            [-93.3678, 45.2842],
            [-93.449, 45.3231],
            [-93.5098, 45.3855],
            [-93.5106, 45.4719],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733540',
        NAME: 'St. Francis Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407629421,
        AWATER: 17024961,
        INTPTLAT: '+45.3812252',
        INTPTLON: '-093.3280528',
        ELSDLEA: '',
        UNSDLEA: '33540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9579, 45.3676],
            [-92.9583, 45.4261],
            [-92.7908, 45.4747],
            [-92.7274, 45.5274],
            [-92.6466, 45.4416],
            [-92.6745, 45.3829],
            [-92.7061, 45.3253],
            [-92.7947, 45.2962],
            [-92.8732, 45.2563],
            [-92.9579, 45.3676],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700017',
        NAME: 'Chisago Lakes School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385650252,
        AWATER: 40876345,
        INTPTLAT: '+45.3905590',
        INTPTLON: '-092.8049523',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4422, 45.6809],
            [-93.3889, 45.6793],
            [-93.3686, 45.6447],
            [-93.1095, 45.6444],
            [-93.0893, 45.6018],
            [-93.1078, 45.4136],
            [-93.1421, 45.4136],
            [-93.2527, 45.4138],
            [-93.3067, 45.4713],
            [-93.4615, 45.4718],
            [-93.4097, 45.5127],
            [-93.4422, 45.6809],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707410',
        NAME: 'Cambridge-Isanti Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 615264475,
        AWATER: 21541401,
        INTPTLAT: '+45.5423445',
        INTPTLON: '-093.2605831',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1095, 45.6444],
            [-93.0759, 45.7598],
            [-93.0148, 45.7501],
            [-92.804, 45.7498],
            [-92.8871, 45.6441],
            [-92.886, 45.6004],
            [-93.0893, 45.6018],
            [-93.1095, 45.6444],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732550',
        NAME: 'Rush City Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263034345,
        AWATER: 12172259,
        INTPTLAT: '+45.6742597',
        INTPTLON: '-092.9703207',
        ELSDLEA: '',
        UNSDLEA: '32550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9319, 46.9501],
            [-94.9182, 47.1523],
            [-94.8334, 47.1077],
            [-94.774, 46.9773],
            [-94.7871, 46.8039],
            [-94.9116, 46.8051],
            [-94.9319, 46.9501],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723370',
        NAME: 'Nevis Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285662295,
        AWATER: 42169925,
        INTPTLAT: '+46.9476317',
        INTPTLON: '-094.8874799',
        ELSDLEA: '',
        UNSDLEA: '23370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1939, 47.8033],
            [-94.9246, 47.8027],
            [-94.8066, 47.7284],
            [-94.8005, 47.6422],
            [-94.6719, 47.6369],
            [-94.5455, 47.5845],
            [-94.546, 47.4109],
            [-94.7558, 47.4105],
            [-94.6942, 47.2727],
            [-95.0241, 47.312],
            [-95.0462, 47.2689],
            [-95.1696, 47.2826],
            [-95.1849, 47.6299],
            [-95.1939, 47.8033],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2704440',
        NAME: 'Bemidji Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1934042000,
        AWATER: 198764739,
        INTPTLAT: '+47.5133428',
        INTPTLON: '-094.9334579',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6942, 47.2727],
            [-94.6636, 47.2366],
            [-94.6633, 47.1069],
            [-94.8334, 47.1077],
            [-94.9182, 47.1523],
            [-95.0462, 47.2689],
            [-95.0241, 47.312],
            [-94.6942, 47.2727],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717940',
        NAME: 'Laporte Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 387546594,
        AWATER: 24415135,
        INTPTLAT: '+47.2173235',
        INTPTLON: '-094.8752198',
        ELSDLEA: '',
        UNSDLEA: '17940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7075, 47.586],
            [-96.4936, 47.5861],
            [-96.3518, 47.6735],
            [-96.3517, 47.7603],
            [-96.2629, 47.7599],
            [-96.1808, 47.7189],
            [-96.1395, 47.6291],
            [-96.0676, 47.5929],
            [-96.0676, 47.4991],
            [-96.0674, 47.4559],
            [-96.4302, 47.4484],
            [-96.516, 47.4992],
            [-96.6449, 47.4993],
            [-96.6811, 47.4987],
            [-96.7075, 47.586],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711910',
        NAME: 'Fertile-Beltrami School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 908734355,
        AWATER: 17264175,
        INTPTLAT: '+47.5643519',
        INTPTLON: '-096.3387644',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0635, 47.6331],
            [-93.0613, 47.7202],
            [-92.9294, 47.7191],
            [-92.9323, 47.63],
            [-92.9404, 47.4836],
            [-92.8069, 47.4677],
            [-92.8068, 47.4528],
            [-92.7897, 47.2825],
            [-92.9388, 47.2831],
            [-92.9385, 47.109],
            [-93.0562, 47.1093],
            [-93.0662, 47.1093],
            [-93.0635, 47.6331],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713980',
        NAME: 'Hibbing Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 853857375,
        AWATER: 31891300,
        INTPTLAT: '+47.4071146',
        INTPTLON: '-092.9673899',
        ELSDLEA: '',
        UNSDLEA: '13980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3691, 47.6343],
            [-92.3047, 47.6323],
            [-92.0522, 47.6289],
            [-92.0478, 47.4152],
            [-92.0476, 47.1982],
            [-92.1752, 47.1982],
            [-92.3024, 47.1977],
            [-92.3037, 47.3715],
            [-92.3693, 47.4293],
            [-92.3691, 47.6343],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791450',
        NAME: 'Mesabi East School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 987760014,
        AWATER: 44637766,
        INTPTLAT: '+47.4226819',
        INTPTLON: '-092.1921374',
        ELSDLEA: '',
        UNSDLEA: '91450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8034, 44.8922],
            [-94.5435, 44.8921],
            [-94.5184, 44.8265],
            [-94.4064, 44.7758],
            [-94.4169, 44.6738],
            [-94.5586, 44.6296],
            [-94.8017, 44.6299],
            [-94.8129, 44.6299],
            [-94.8034, 44.8922],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700023',
        NAME: 'Buffalo Lake-Hector-Stewart Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 769885464,
        AWATER: 11126698,
        INTPTLAT: '+44.7532528',
        INTPTLON: '-094.6501775',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8663, 44.3189],
            [-94.8055, 44.3912],
            [-94.7552, 44.4476],
            [-94.6237, 44.3824],
            [-94.614, 44.2177],
            [-94.6464, 44.1517],
            [-94.7144, 44.1235],
            [-94.7383, 44.1089],
            [-94.8427, 44.2102],
            [-94.8528, 44.2354],
            [-94.8663, 44.3189],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733210',
        NAME: 'Sleepy Eye Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528993203,
        AWATER: 3231528,
        INTPTLAT: '+44.2683274',
        INTPTLON: '-094.7357253',
        ELSDLEA: '',
        UNSDLEA: '33210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5301, 47.0784],
            [-95.2973, 47.0797],
            [-95.2973, 47.1518],
            [-95.1692, 47.1525],
            [-95.1696, 47.2826],
            [-95.0462, 47.2689],
            [-94.9182, 47.1523],
            [-94.9319, 46.9501],
            [-94.9116, 46.8051],
            [-95.0486, 46.8406],
            [-95.2684, 46.8475],
            [-95.4149, 46.861],
            [-95.4136, 46.9773],
            [-95.3601, 46.9774],
            [-95.3815, 47.0058],
            [-95.4234, 47.0056],
            [-95.5302, 47.0056],
            [-95.5301, 47.0784],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728080',
        NAME: 'Park Rapids Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1420164414,
        AWATER: 101572517,
        INTPTLAT: '+47.0230651',
        INTPTLON: '-095.1362515',
        ELSDLEA: '',
        UNSDLEA: '28080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-93.1794, 46.1146],
              [-93.0128, 46.1154],
              [-92.9721, 46.0714],
              [-92.6277, 46.075],
              [-92.6164, 46.0749],
              [-92.6164, 46.0797],
              [-92.4932, 46.07],
              [-92.4216, 46.0265],
              [-92.4728, 45.973],
              [-92.6107, 45.9364],
              [-92.6608, 45.9221],
              [-93.1426, 45.9482],
              [-93.1794, 46.1146],
            ],
          ],
          [
            [
              [-93.0542, 46.2528],
              [-92.887, 46.2241],
              [-92.8968, 46.1696],
              [-93.0539, 46.1581],
              [-93.1366, 46.1801],
              [-93.0542, 46.2528],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714070',
        NAME: 'Hinckley-Finlayson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1066696611,
        AWATER: 13994949,
        INTPTLAT: '+46.0433365',
        INTPTLON: '-092.8958254',
        ELSDLEA: '',
        UNSDLEA: '14070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5589, 45.9817],
            [-93.4325, 45.9853],
            [-93.4108, 45.9865],
            [-93.3483, 45.8606],
            [-93.321, 45.7309],
            [-93.3889, 45.6793],
            [-93.4422, 45.6809],
            [-93.4733, 45.719],
            [-93.5141, 45.7595],
            [-93.5183, 45.8784],
            [-93.579, 45.9083],
            [-93.5589, 45.9817],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723970',
        NAME: 'Ogilvie Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395712085,
        AWATER: 5372147,
        INTPTLAT: '+45.8379796',
        INTPTLON: '-093.4461391',
        ELSDLEA: '',
        UNSDLEA: '23970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.179, 46.1585],
            [-93.1366, 46.1801],
            [-93.0539, 46.1581],
            [-92.8968, 46.1696],
            [-92.887, 46.2241],
            [-92.636, 46.3314],
            [-92.5294, 46.4181],
            [-92.2929, 46.4172],
            [-92.293, 46.3322],
            [-92.294, 46.0744],
            [-92.4216, 46.0265],
            [-92.4932, 46.07],
            [-92.6164, 46.0797],
            [-92.6164, 46.0749],
            [-92.6277, 46.075],
            [-92.9721, 46.0714],
            [-93.0128, 46.1154],
            [-93.1794, 46.1146],
            [-93.179, 46.1585],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711085',
        NAME: 'East Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1612887260,
        AWATER: 15995883,
        INTPTLAT: '+46.2146579',
        INTPTLON: '-092.5934769',
        ELSDLEA: '',
        UNSDLEA: '11085',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3049, 46.1589],
            [-93.179, 46.1585],
            [-93.1794, 46.1146],
            [-93.1426, 45.9482],
            [-93.1422, 45.8319],
            [-93.2557, 45.7993],
            [-93.321, 45.7309],
            [-93.3483, 45.8606],
            [-93.4108, 45.9865],
            [-93.4325, 45.9853],
            [-93.4328, 46.0393],
            [-93.3059, 46.072],
            [-93.3049, 46.1589],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721480',
        NAME: 'Mora Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657220624,
        AWATER: 20446142,
        INTPTLAT: '+45.9520506',
        INTPTLON: '-093.2714573',
        ELSDLEA: '',
        UNSDLEA: '21480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.931, 49],
            [-96.4054, 49],
            [-96.4713, 48.9358],
            [-96.4722, 48.8045],
            [-96.6241, 48.8034],
            [-96.7103, 48.7458],
            [-96.832, 48.783],
            [-96.9859, 48.8763],
            [-96.931, 49],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717880',
        NAME: 'Lancaster Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 834919782,
        AWATER: 1823042,
        INTPTLAT: '+48.9194320',
        INTPTLON: '-096.6907618',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2287, 49.0006],
            [-96.931, 49],
            [-96.9859, 48.8763],
            [-96.832, 48.783],
            [-96.7103, 48.7458],
            [-96.7151, 48.5874],
            [-96.7805, 48.5435],
            [-97.1634, 48.544],
            [-97.1054, 48.6896],
            [-97.1576, 48.7875],
            [-97.2341, 48.9479],
            [-97.2287, 49.0006],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791447',
        NAME: 'Kittson Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1211243657,
        AWATER: 5690064,
        INTPTLAT: '+48.7510461',
        INTPTLON: '-096.9832754',
        ELSDLEA: '',
        UNSDLEA: '91447',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0102, 44.4573],
            [-92.9188, 44.5176],
            [-92.9139, 44.5867],
            [-92.7868, 44.5652],
            [-92.7116, 44.4703],
            [-92.7727, 44.3921],
            [-92.9078, 44.3705],
            [-93.0104, 44.4286],
            [-93.0102, 44.4573],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707500',
        NAME: 'Cannon Falls Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344470592,
        AWATER: 1360250,
        INTPTLAT: '+44.4789680',
        INTPTLON: '-092.8530418',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1164, 44.5581],
            [-93.0352, 44.6163],
            [-92.9139, 44.5867],
            [-92.9188, 44.5176],
            [-93.0102, 44.4573],
            [-93.1164, 44.5581],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730150',
        NAME: 'Randolph Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172341775,
        AWATER: 6293488,
        INTPTLAT: '+44.5396343',
        INTPTLON: '-093.0106215',
        ELSDLEA: '',
        UNSDLEA: '30150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7468, 48.0071],
            [-96.8054, 47.9783],
            [-96.9384, 47.7611],
            [-96.9723, 47.8028],
            [-96.9765, 47.8037],
            [-96.9834, 47.8053],
            [-97.0534, 47.9913],
            [-97.0841, 48.0564],
            [-96.7601, 48.0645],
            [-96.7468, 48.0071],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711130',
        NAME: 'East Grand Forks Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455264323,
        AWATER: 1219553,
        INTPTLAT: '+47.9564692',
        INTPTLON: '-096.9232401',
        ELSDLEA: '',
        UNSDLEA: '11130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9379, 47.7602],
            [-96.9384, 47.7611],
            [-96.8054, 47.9783],
            [-96.6768, 47.9199],
            [-96.6759, 47.8257],
            [-96.8032, 47.688],
            [-96.8678, 47.7171],
            [-96.8781, 47.7172],
            [-96.9204, 47.7114],
            [-96.9379, 47.7602],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712180',
        NAME: 'Fisher Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 383307549,
        AWATER: 0,
        INTPTLAT: '+47.8304531',
        INTPTLON: '-096.7959339',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5535, 47.6161],
            [-95.1849, 47.6299],
            [-95.1696, 47.2826],
            [-95.1692, 47.1525],
            [-95.2973, 47.1518],
            [-95.5512, 47.1515],
            [-95.5513, 47.2093],
            [-95.551, 47.4112],
            [-95.5535, 47.6161],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703570',
        NAME: 'Bagley Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1431700019,
        AWATER: 53271331,
        INTPTLAT: '+47.3964340',
        INTPTLON: '-095.3651415',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5008, 48.1741],
            [-96.3027, 48.1747],
            [-96.2585, 48.2263],
            [-96.1348, 48.226],
            [-96.0776, 48.2858],
            [-95.9813, 48.2655],
            [-95.9173, 48.2295],
            [-95.9083, 48.0478],
            [-95.7842, 48.0309],
            [-95.8934, 47.9792],
            [-96.095, 47.9784],
            [-96.1599, 47.9269],
            [-96.2884, 47.9629],
            [-96.4827, 47.9812],
            [-96.5009, 48.0356],
            [-96.5008, 48.1741],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2738850',
        NAME: 'Thief River Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1218855448,
        AWATER: 4322421,
        INTPTLAT: '+48.0909487',
        INTPTLON: '-096.1827921',
        ELSDLEA: '',
        UNSDLEA: '38850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1335, 48.9357],
            [-95.9535, 48.9059],
            [-95.9311, 48.8045],
            [-95.8658, 48.7756],
            [-95.8861, 48.6062],
            [-96.0486, 48.6232],
            [-96.0491, 48.7023],
            [-96.1175, 48.7678],
            [-96.1335, 48.9357],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703540',
        NAME: 'Badger Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478149320,
        AWATER: 13028,
        INTPTLAT: '+48.7669679',
        INTPTLON: '-095.9940300',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2175, 44.6949],
            [-93.0348, 44.6746],
            [-93.0352, 44.6163],
            [-93.1164, 44.5581],
            [-93.2178, 44.5799],
            [-93.2175, 44.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711820',
        NAME: 'Farmington Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199689498,
        AWATER: 1442400,
        INTPTLAT: '+44.6299256',
        INTPTLON: '-093.1272603',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0973, 44.3507],
            [-95.0769, 44.3836],
            [-94.9589, 44.3261],
            [-94.8663, 44.3189],
            [-94.8528, 44.2354],
            [-94.8427, 44.2102],
            [-94.9305, 44.1511],
            [-95.0407, 44.1514],
            [-95.098, 44.2386],
            [-95.0973, 44.3507],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733390',
        NAME: 'Springfield Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380235391,
        AWATER: 1013690,
        INTPTLAT: '+44.2607998',
        INTPTLON: '-094.9772089',
        ELSDLEA: '',
        UNSDLEA: '33390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4827, 47.9812],
            [-96.2884, 47.9629],
            [-96.1599, 47.9269],
            [-96.0953, 47.814],
            [-96.2629, 47.7599],
            [-96.3517, 47.7603],
            [-96.3957, 47.8475],
            [-96.482, 47.8329],
            [-96.4827, 47.9812],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730450',
        NAME: 'Red Lake Falls Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491903366,
        AWATER: 169851,
        INTPTLAT: '+47.8693921',
        INTPTLON: '-096.2982327',
        ELSDLEA: '',
        UNSDLEA: '30450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2629, 47.7599],
            [-96.0953, 47.814],
            [-96.0584, 47.7602],
            [-95.7728, 47.7609],
            [-95.7721, 47.6738],
            [-95.8962, 47.536],
            [-95.8692, 47.4993],
            [-96.0676, 47.4991],
            [-96.0676, 47.5929],
            [-96.1395, 47.6291],
            [-96.1808, 47.7189],
            [-96.2629, 47.7599],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791449',
        NAME: 'Win-E-Mac School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 742109826,
        AWATER: 25947004,
        INTPTLAT: '+47.6679681',
        INTPTLON: '-095.9930738',
        ELSDLEA: '',
        UNSDLEA: '91449',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7468, 48.0071],
            [-96.5009, 48.0356],
            [-96.4827, 47.9812],
            [-96.482, 47.8329],
            [-96.3957, 47.8475],
            [-96.3517, 47.7603],
            [-96.3518, 47.6735],
            [-96.4936, 47.5861],
            [-96.7075, 47.586],
            [-96.7077, 47.6732],
            [-96.8032, 47.688],
            [-96.6759, 47.8257],
            [-96.6768, 47.9199],
            [-96.8054, 47.9783],
            [-96.7468, 48.0071],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709720',
        NAME: 'Crookston Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1066619379,
        AWATER: 161267,
        INTPTLAT: '+47.7977780',
        INTPTLON: '-096.5770935',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9145, 44.6371],
            [-93.8392, 44.5431],
            [-93.748, 44.5109],
            [-93.7276, 44.4922],
            [-93.8341, 44.3951],
            [-93.9192, 44.37],
            [-94.0095, 44.4281],
            [-94.1047, 44.4421],
            [-94.0219, 44.5505],
            [-93.9145, 44.6371],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718070',
        NAME: 'LeSueur-Henderson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434136992,
        AWATER: 5226853,
        INTPTLAT: '+44.4791485',
        INTPTLON: '-093.9253079',
        ELSDLEA: '',
        UNSDLEA: '18070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1443, 44.4172],
            [-94.1047, 44.4421],
            [-94.0095, 44.4281],
            [-93.9192, 44.37],
            [-93.8884, 44.2563],
            [-94.0191, 44.2395],
            [-94.0788, 44.2688],
            [-94.1003, 44.3121],
            [-94.1443, 44.4172],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733870',
        NAME: 'St. Peter Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296962415,
        AWATER: 5207160,
        INTPTLAT: '+44.3392023',
        INTPTLON: '-094.0012302',
        ELSDLEA: '',
        UNSDLEA: '33870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8334, 47.1077],
            [-94.6633, 47.1069],
            [-94.6636, 47.2366],
            [-94.5118, 47.2109],
            [-94.4138, 47.2732],
            [-94.247, 47.2852],
            [-94.4046, 47.1529],
            [-94.4065, 47.0669],
            [-94.278, 46.9795],
            [-94.3213, 46.9063],
            [-94.4265, 46.8631],
            [-94.6594, 46.8907],
            [-94.6599, 46.8037],
            [-94.7871, 46.8039],
            [-94.774, 46.9773],
            [-94.8334, 47.1077],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702910',
        NAME: 'Walker-Hackensack-Akeley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 901766270,
        AWATER: 430314914,
        INTPTLAT: '+47.0446058',
        INTPTLON: '-094.5583883',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.546, 47.4109],
            [-94.4163, 47.4448],
            [-94.3275, 47.4158],
            [-94.118, 47.4807],
            [-94.033, 47.413],
            [-94.0299, 47.3584],
            [-94.1156, 47.2863],
            [-94.247, 47.2852],
            [-94.4138, 47.2732],
            [-94.5118, 47.2109],
            [-94.6636, 47.2366],
            [-94.6942, 47.2727],
            [-94.7558, 47.4105],
            [-94.546, 47.4109],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708070',
        NAME: 'Cass Lake-Bena Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770368413,
        AWATER: 222389192,
        INTPTLAT: '+47.3331398',
        INTPTLON: '-094.3905378',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2859, 44.4204],
            [-94.2751, 44.6304],
            [-94.0105, 44.7175],
            [-93.9024, 44.673],
            [-93.9145, 44.6371],
            [-94.0219, 44.5505],
            [-94.1047, 44.4421],
            [-94.1443, 44.4172],
            [-94.2764, 44.4003],
            [-94.2859, 44.4204],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700102',
        NAME: 'Sibley East School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 634109517,
        AWATER: 11735672,
        INTPTLAT: '+44.5680938',
        INTPTLON: '-094.1212062',
        ELSDLEA: '',
        UNSDLEA: '00102',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.321, 45.7309],
            [-93.2557, 45.7993],
            [-93.1422, 45.8319],
            [-93.0759, 45.7598],
            [-93.1095, 45.6444],
            [-93.3686, 45.6447],
            [-93.3889, 45.6793],
            [-93.321, 45.7309],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2706060',
        NAME: 'Braham Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346116621,
        AWATER: 12682215,
        INTPTLAT: '+45.7231148',
        INTPTLON: '-093.1987532',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1638, 45.2923],
            [-93.1421, 45.4136],
            [-93.1078, 45.4136],
            [-92.9579, 45.3676],
            [-92.8732, 45.2563],
            [-92.7947, 45.2962],
            [-92.7457, 45.296],
            [-92.7523, 45.2169],
            [-92.8159, 45.2099],
            [-92.9381, 45.1444],
            [-92.9482, 45.1458],
            [-93.0409, 45.182],
            [-93.1425, 45.2035],
            [-93.1638, 45.2923],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712270',
        NAME: 'Forest Lake Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507946535,
        AWATER: 48475682,
        INTPTLAT: '+45.2738655',
        INTPTLON: '-092.9945130',
        ELSDLEA: '',
        UNSDLEA: '12270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2688, 45.1115],
            [-93.168, 45.1893],
            [-93.2047, 45.1244],
            [-93.2277, 45.0933],
            [-93.2688, 45.1115],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733330',
        NAME: 'Spring Lake Park Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47820841,
        AWATER: 525451,
        INTPTLAT: '+45.1460459',
        INTPTLON: '-093.2098108',
        ELSDLEA: '',
        UNSDLEA: '33330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4516, 44.8487],
            [-96.3994, 44.8922],
            [-96.1954, 44.8324],
            [-96.0411, 44.7574],
            [-96.0315, 44.7537],
            [-96.0118, 44.7173],
            [-96.1285, 44.6016],
            [-96.1539, 44.5503],
            [-96.1744, 44.5579],
            [-96.2855, 44.5791],
            [-96.3225, 44.5435],
            [-96.348, 44.6089],
            [-96.4091, 44.6238],
            [-96.4256, 44.6312],
            [-96.4395, 44.6312],
            [-96.4516, 44.6313],
            [-96.4515, 44.6668],
            [-96.4516, 44.8487],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707470',
        NAME: 'Canby Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 884309557,
        AWATER: 1573371,
        INTPTLAT: '+44.7154673',
        INTPTLON: '-096.2609803',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9937, 43.587],
            [-95.9336, 43.5436],
            [-95.8141, 43.5507],
            [-95.774, 43.4999],
            [-95.8317, 43.5],
            [-95.9783, 43.5001],
            [-96.1544, 43.5002],
            [-96.0434, 43.5934],
            [-95.9937, 43.587],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711460',
        NAME: 'Ellsworth Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215991421,
        AWATER: 186410,
        INTPTLAT: '+43.5313480',
        INTPTLON: '-095.9835394',
        ELSDLEA: '',
        UNSDLEA: '11460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3419, 43.7112],
            [-96.2414, 43.8486],
            [-96.1726, 43.7977],
            [-96.0431, 43.7682],
            [-96.0331, 43.6884],
            [-95.9937, 43.587],
            [-96.0434, 43.5934],
            [-96.1544, 43.5002],
            [-96.1927, 43.5003],
            [-96.2731, 43.5439],
            [-96.3324, 43.6526],
            [-96.3419, 43.7112],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718330',
        NAME: 'Luverne Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669898053,
        AWATER: 703355,
        INTPTLAT: '+43.6779145',
        INTPTLON: '-096.1824337',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9373, 46.1566],
            [-93.8107, 46.2863],
            [-93.7963, 46.2432],
            [-93.5533, 46.2468],
            [-93.5589, 45.9817],
            [-93.579, 45.9083],
            [-93.8306, 45.9681],
            [-93.9363, 45.9833],
            [-93.9373, 46.1566],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2725050',
        NAME: 'Onamia Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 729806839,
        AWATER: 238688639,
        INTPTLAT: '+46.0922673',
        INTPTLON: '-093.7583998',
        ELSDLEA: '',
        UNSDLEA: '25050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7871, 46.8039],
            [-94.6599, 46.8037],
            [-94.6594, 46.8907],
            [-94.4265, 46.8631],
            [-94.3213, 46.9063],
            [-94.154, 46.8046],
            [-94.0296, 46.8033],
            [-94.0297, 46.7169],
            [-94.1749, 46.6892],
            [-94.346, 46.6901],
            [-94.409, 46.6314],
            [-94.4071, 46.5438],
            [-94.4146, 46.514],
            [-94.5191, 46.5132],
            [-94.6037, 46.6305],
            [-94.781, 46.6301],
            [-94.7866, 46.7163],
            [-94.7871, 46.8039],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728970',
        NAME: 'Pine River-Backus Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1314414738,
        AWATER: 95771765,
        INTPTLAT: '+46.7412975',
        INTPTLON: '-094.4551824',
        ELSDLEA: '',
        UNSDLEA: '28970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0297, 46.7169],
            [-94.0296, 46.8033],
            [-93.776, 46.8029],
            [-93.7774, 46.7164],
            [-93.8835, 46.6718],
            [-93.8951, 46.5341],
            [-93.8122, 46.5044],
            [-93.8106, 46.3573],
            [-94.0683, 46.4609],
            [-94.1875, 46.5299],
            [-94.0303, 46.6601],
            [-94.0297, 46.7169],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709750',
        NAME: 'Crosby-Ironton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 722753802,
        AWATER: 123562758,
        INTPTLAT: '+46.5777636',
        INTPTLON: '-093.9814824',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0297, 46.7169],
            [-94.0303, 46.6601],
            [-94.1875, 46.5299],
            [-94.4071, 46.5438],
            [-94.409, 46.6314],
            [-94.346, 46.6901],
            [-94.1749, 46.6892],
            [-94.0297, 46.7169],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728200',
        NAME: 'Pequot Lakes Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311770017,
        AWATER: 89359585,
        INTPTLAT: '+46.6263185',
        INTPTLON: '-094.2239286',
        ELSDLEA: '',
        UNSDLEA: '28200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.776, 46.8029],
            [-93.7755, 46.8564],
            [-93.5485, 46.8566],
            [-93.3648, 46.8769],
            [-93.3327, 46.7929],
            [-93.4426, 46.7274],
            [-93.4647, 46.6379],
            [-93.4322, 46.5051],
            [-93.3703, 46.4187],
            [-93.4311, 46.3333],
            [-93.5594, 46.3196],
            [-93.5533, 46.2468],
            [-93.7963, 46.2432],
            [-93.8107, 46.2863],
            [-93.8106, 46.3573],
            [-93.8122, 46.5044],
            [-93.8951, 46.5341],
            [-93.8835, 46.6718],
            [-93.7774, 46.7164],
            [-93.776, 46.8029],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702760',
        NAME: 'Aitkin Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1788509864,
        AWATER: 342701298,
        INTPTLAT: '+46.5641443',
        INTPTLON: '-093.6489304',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4414, 46.9771],
            [-96.3215, 46.9772],
            [-96.2377, 46.9209],
            [-96.1954, 46.9208],
            [-96.1751, 46.7371],
            [-96.2325, 46.7257],
            [-96.4206, 46.8044],
            [-96.4414, 46.9771],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713560',
        NAME: 'Hawley Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371018508,
        AWATER: 10756261,
        INTPTLAT: '+46.8474473',
        INTPTLON: '-096.3123236',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9024, 44.673],
            [-93.7788, 44.7062],
            [-93.7352, 44.7174],
            [-93.6789, 44.6869],
            [-93.6629, 44.6073],
            [-93.748, 44.5109],
            [-93.8392, 44.5431],
            [-93.9145, 44.6371],
            [-93.9024, 44.673],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2704050',
        NAME: 'Belle Plaine Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273597696,
        AWATER: 4820612,
        INTPTLAT: '+44.6197594',
        INTPTLON: '-093.7821096',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.548, 43.7173],
            [-94.542, 43.7539],
            [-94.4184, 43.7529],
            [-94.3778, 43.6011],
            [-94.4375, 43.5004],
            [-94.5673, 43.5004],
            [-94.7445, 43.501],
            [-94.7344, 43.5806],
            [-94.5611, 43.5947],
            [-94.548, 43.7173],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700124',
        NAME: 'Fairmont Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426537959,
        AWATER: 25447252,
        INTPTLAT: '+43.5982023',
        INTPTLON: '-094.5435189',
        ELSDLEA: '',
        UNSDLEA: '00124',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3736, 44.8604],
            [-93.3289, 44.8619],
            [-93.2473, 44.8623],
            [-93.1979, 44.862],
            [-93.2078, 44.8436],
            [-93.335, 44.787],
            [-93.3988, 44.7988],
            [-93.3736, 44.8604],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2705790',
        NAME: 'Bloomington Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89305547,
        AWATER: 9555132,
        INTPTLAT: '+44.8296656',
        INTPTLON: '-093.3152170',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7811, 45.1263],
            [-93.543, 45.1093],
            [-93.522, 45.0804],
            [-93.5838, 45.0584],
            [-93.7486, 45.0692],
            [-93.8021, 45.101],
            [-93.7811, 45.1263],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732070',
        NAME: 'Rockford Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110012604,
        AWATER: 3305321,
        INTPTLAT: '+45.1037223',
        INTPTLON: '-093.6742182',
        ELSDLEA: '',
        UNSDLEA: '32070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5769, 45.4056],
            [-94.5576, 45.4056],
            [-94.4415, 45.3555],
            [-94.4553, 45.2572],
            [-94.4864, 45.2608],
            [-94.6243, 45.2461],
            [-94.6415, 45.2722],
            [-94.6397, 45.3689],
            [-94.5769, 45.4056],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711190',
        NAME: 'Eden Valley-Watkins School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252018009,
        AWATER: 11885538,
        INTPTLAT: '+45.3251307',
        INTPTLON: '-094.5217966',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.8068, 47.4528],
              [-92.6231, 47.4593],
              [-92.558, 47.372],
              [-92.3037, 47.3715],
              [-92.3024, 47.1977],
              [-92.1752, 47.1982],
              [-92.1754, 47.0249],
              [-92.302, 47.0241],
              [-92.429, 47.0247],
              [-92.4281, 46.8443],
              [-92.5238, 46.8372],
              [-92.5243, 46.7659],
              [-92.7332, 46.7678],
              [-92.8086, 46.7663],
              [-92.8063, 46.8523],
              [-92.6804, 46.8764],
              [-92.6799, 46.9421],
              [-92.8063, 46.9396],
              [-92.8051, 47.0256],
              [-92.9314, 47.0264],
              [-92.9385, 47.109],
              [-92.9388, 47.2831],
              [-92.7897, 47.2825],
              [-92.8068, 47.4528],
            ],
          ],
          [
            [
              [-93.4092, 48.0697],
              [-93.3579, 48.0693],
              [-93.089, 48.0679],
              [-93.0971, 48.1792],
              [-93.0884, 48.6276],
              [-92.985, 48.6237],
              [-92.728, 48.5393],
              [-92.6349, 48.5429],
              [-92.656, 48.4367],
              [-92.5073, 48.4479],
              [-92.4699, 48.3518],
              [-92.3701, 48.2203],
              [-92.2807, 48.2443],
              [-92.289, 48.343],
              [-92.162, 48.3633],
              [-92.0001, 48.3214],
              [-92.0066, 48.2654],
              [-91.7983, 48.2029],
              [-91.8002, 48.0659],
              [-92.0543, 48.0597],
              [-92.0523, 47.7583],
              [-91.7993, 47.7575],
              [-91.7902, 47.4167],
              [-92.0478, 47.4152],
              [-92.0522, 47.6289],
              [-92.3047, 47.6323],
              [-92.3031, 47.7143],
              [-92.562, 47.7147],
              [-92.691, 47.6768],
              [-92.7992, 47.6292],
              [-92.9323, 47.63],
              [-92.9294, 47.7191],
              [-93.0613, 47.7202],
              [-93.0812, 47.8919],
              [-93.4097, 47.8944],
              [-93.4092, 48.0697],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700008',
        NAME: 'St. Louis County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9963317029,
        AWATER: 929673623,
        INTPTLAT: '+47.7887979',
        INTPTLON: '-092.5257300',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6231, 47.4593],
            [-92.5597, 47.4891],
            [-92.4352, 47.5454],
            [-92.3691, 47.6343],
            [-92.3693, 47.4293],
            [-92.3037, 47.3715],
            [-92.558, 47.372],
            [-92.6231, 47.4593],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700021',
        NAME: 'Eveleth-Gilbert School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346200915,
        AWATER: 15295807,
        INTPTLAT: '+47.4673383',
        INTPTLON: '-092.4484387',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.301, 46.6772],
            [-92.1853, 46.7642],
            [-92.1754, 47.0249],
            [-92.1752, 47.1982],
            [-92.0476, 47.1982],
            [-91.9199, 47.1987],
            [-91.9202, 46.8715],
            [-91.8864, 46.7591],
            [-91.9312, 46.7421],
            [-92.0203, 46.704],
            [-92.0895, 46.7492],
            [-92.2124, 46.6499],
            [-92.2922, 46.6633],
            [-92.3011, 46.668],
            [-92.301, 46.6772],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711040',
        NAME: 'Duluth Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 841932066,
        AWATER: 225483065,
        INTPTLAT: '+46.9446286',
        INTPTLON: '-092.0577521',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1449, 44.194],
            [-94.0788, 44.2688],
            [-94.0191, 44.2395],
            [-93.8884, 44.2563],
            [-93.7786, 44.2321],
            [-93.768, 44.2009],
            [-93.8197, 44.152],
            [-93.8285, 44.1486],
            [-94.0039, 44.0722],
            [-94.1449, 44.194],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718780',
        NAME: 'Mankato Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337952461,
        AWATER: 16596927,
        INTPTLAT: '+44.1836384',
        INTPTLON: '-093.9750829',
        ELSDLEA: '',
        UNSDLEA: '18780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.614, 44.2177],
            [-94.6237, 44.3824],
            [-94.6187, 44.427],
            [-94.5023, 44.4526],
            [-94.2859, 44.4204],
            [-94.2764, 44.4003],
            [-94.3118, 44.2428],
            [-94.3689, 44.1814],
            [-94.4342, 44.1235],
            [-94.5701, 44.1222],
            [-94.6464, 44.1517],
            [-94.614, 44.2177],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723490',
        NAME: 'New Ulm Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 806973263,
        AWATER: 15752690,
        INTPTLAT: '+44.2905495',
        INTPTLON: '-094.4658550',
        ELSDLEA: '',
        UNSDLEA: '23490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9482, 45.1458],
            [-92.9381, 45.1444],
            [-92.8941, 45.1083],
            [-92.9481, 45.0281],
            [-92.9848, 45.0358],
            [-92.9482, 45.1458],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718750',
        NAME: 'Mahtomedi Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64696120,
        AWATER: 9813176,
        INTPTLAT: '+45.0855899',
        INTPTLON: '-092.9479147',
        ELSDLEA: '',
        UNSDLEA: '18750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2078, 44.8436],
            [-93.1979, 44.862],
            [-93.1785, 44.8883],
            [-93.0646, 44.9195],
            [-93.0621, 44.8836],
            [-93.0851, 44.8619],
            [-93.1874, 44.8209],
            [-93.2078, 44.8436],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742270',
        NAME: 'West St. Paul-Mendota Heights-Eagan',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63588612,
        AWATER: 5926627,
        INTPTLAT: '+44.8736090',
        INTPTLON: '-093.1412907',
        ELSDLEA: '',
        UNSDLEA: '42270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1874, 44.8209],
            [-93.0851, 44.8619],
            [-93.016, 44.7756],
            [-92.9742, 44.7724],
            [-93.0348, 44.6746],
            [-93.2175, 44.6949],
            [-93.2881, 44.7328],
            [-93.1874, 44.8209],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732390',
        NAME: 'Rosemount-Apple Valley-Eagan',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265728867,
        AWATER: 13712464,
        INTPTLAT: '+44.7517975',
        INTPTLON: '-093.1233268',
        ELSDLEA: '',
        UNSDLEA: '32390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.341, 43.9203],
            [-95.2609, 43.9784],
            [-95.0998, 44.0069],
            [-95.0701, 43.9279],
            [-94.9791, 43.8766],
            [-94.9546, 43.8045],
            [-95.0348, 43.761],
            [-95.125, 43.7691],
            [-95.2157, 43.7905],
            [-95.341, 43.9203],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742780',
        NAME: 'Windom Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515727751,
        AWATER: 10587378,
        INTPTLAT: '+43.8791360',
        INTPTLON: '-095.1443165',
        ELSDLEA: '',
        UNSDLEA: '42780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.684, 45.2809],
            [-93.5709, 45.2597],
            [-93.6387, 45.1744],
            [-93.7469, 45.188],
            [-93.7658, 45.2379],
            [-93.684, 45.2809],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733790',
        NAME: 'St. Michael-Albertville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97517036,
        AWATER: 7431602,
        INTPTLAT: '+45.2219217',
        INTPTLON: '-093.6787153',
        ELSDLEA: '',
        UNSDLEA: '33790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.954, 45.2962],
            [-93.9003, 45.2377],
            [-94.0022, 45.1593],
            [-94.0023, 45.1449],
            [-94.0579, 45.1613],
            [-94.0562, 45.2932],
            [-93.954, 45.2962],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718810',
        NAME: 'Maple Lake Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137208010,
        AWATER: 11980147,
        INTPTLAT: '+45.2268294',
        INTPTLON: '-093.9950566',
        ELSDLEA: '',
        UNSDLEA: '18810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4054, 49],
            [-96.2088, 49],
            [-96.1335, 48.9357],
            [-96.1175, 48.7678],
            [-96.0491, 48.7023],
            [-96.0486, 48.6232],
            [-95.8861, 48.6062],
            [-95.7973, 48.5255],
            [-95.8286, 48.4107],
            [-95.9053, 48.4116],
            [-95.981, 48.3383],
            [-95.9813, 48.2655],
            [-96.0776, 48.2858],
            [-96.0638, 48.369],
            [-96.2106, 48.369],
            [-96.2992, 48.457],
            [-96.2355, 48.5159],
            [-96.3243, 48.5812],
            [-96.4722, 48.8045],
            [-96.4713, 48.9358],
            [-96.4054, 49],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700107',
        NAME: 'Greenbush-Middle River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1804557178,
        AWATER: 82589849,
        INTPTLAT: '+48.6405555',
        INTPTLON: '-096.1599528',
        ELSDLEA: '',
        UNSDLEA: '00107',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4522, 44.3717],
            [-96.3841, 44.3641],
            [-96.3536, 44.3863],
            [-96.3236, 44.3863],
            [-96.3024, 44.4009],
            [-96.2267, 44.3502],
            [-96.2452, 44.183],
            [-96.2854, 44.1908],
            [-96.4528, 44.1968],
            [-96.4522, 44.3717],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717460',
        NAME: 'Lake Benton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364194640,
        AWATER: 11960888,
        INTPTLAT: '+44.2915588',
        INTPTLON: '-096.3335852',
        ELSDLEA: '',
        UNSDLEA: '17460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3898, 44.7599],
            [-93.3261, 44.7176],
            [-93.4089, 44.6343],
            [-93.4302, 44.6162],
            [-93.4402, 44.6202],
            [-93.521, 44.6889],
            [-93.3898, 44.7599],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730060',
        NAME: 'Prior Lake-Savage Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116111769,
        AWATER: 11514872,
        INTPTLAT: '+44.7026583',
        INTPTLON: '-093.4149192',
        ELSDLEA: '',
        UNSDLEA: '30060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9813, 48.2655],
            [-95.981, 48.3383],
            [-95.7867, 48.3391],
            [-95.7869, 48.2811],
            [-95.6607, 48.1729],
            [-95.5938, 48.1729],
            [-95.5824, 47.9912],
            [-95.7842, 48.0309],
            [-95.9083, 48.0478],
            [-95.9173, 48.2295],
            [-95.9813, 48.2655],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713020',
        NAME: 'Goodridge Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 725707032,
        AWATER: 8812379,
        INTPTLAT: '+48.1577112',
        INTPTLON: '-095.7872601',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2088, 49],
            [-95.4741, 48.9991],
            [-95.4509, 48.7995],
            [-95.4833, 48.7776],
            [-95.473, 48.627],
            [-95.3426, 48.6269],
            [-95.3425, 48.5402],
            [-95.582, 48.5386],
            [-95.6661, 48.5109],
            [-95.7973, 48.5255],
            [-95.8861, 48.6062],
            [-95.8658, 48.7756],
            [-95.9311, 48.8045],
            [-95.9535, 48.9059],
            [-96.1335, 48.9357],
            [-96.2088, 49],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732250',
        NAME: 'Roseau Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1869734212,
        AWATER: 6919980,
        INTPTLAT: '+48.7834631',
        INTPTLON: '-095.7126967',
        ELSDLEA: '',
        UNSDLEA: '32250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6629, 44.6073],
            [-93.4402, 44.6202],
            [-93.4302, 44.6162],
            [-93.3995, 44.6099],
            [-93.3498, 44.5441],
            [-93.3626, 44.4858],
            [-93.3469, 44.479],
            [-93.3926, 44.4572],
            [-93.4144, 44.4494],
            [-93.5447, 44.5006],
            [-93.702, 44.4858],
            [-93.7276, 44.4922],
            [-93.748, 44.5109],
            [-93.6629, 44.6073],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723430',
        NAME: 'New Prague Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398753167,
        AWATER: 7759482,
        INTPTLAT: '+44.5562905',
        INTPTLON: '-093.5121778',
        ELSDLEA: '',
        UNSDLEA: '23430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7786, 44.2321],
            [-93.7226, 44.2828],
            [-93.7067, 44.2828],
            [-93.7068, 44.2901],
            [-93.5149, 44.3082],
            [-93.4642, 44.3033],
            [-93.4032, 44.2978],
            [-93.3532, 44.1962],
            [-93.4369, 44.1817],
            [-93.4448, 44.2038],
            [-93.6381, 44.1742],
            [-93.7128, 44.1923],
            [-93.768, 44.2009],
            [-93.7786, 44.2321],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700013',
        NAME: 'Waterville-Elysian-Morristown Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354236449,
        AWATER: 32541848,
        INTPTLAT: '+44.2453160',
        INTPTLON: '-093.5755938',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8537, 46.4414],
            [-95.6821, 46.4471],
            [-95.657, 46.3975],
            [-95.5847, 46.369],
            [-95.5926, 46.2893],
            [-95.5213, 46.195],
            [-95.5724, 46.1183],
            [-95.708, 46.1219],
            [-95.8012, 46.1983],
            [-95.7797, 46.3695],
            [-95.8537, 46.4414],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703750',
        NAME: 'Battle Lake Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418786016,
        AWATER: 119556758,
        INTPTLAT: '+46.2807238',
        INTPTLON: '-095.6969970',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9975, 47.0211],
            [-95.8591, 46.9995],
            [-95.8063, 47.064],
            [-95.5301, 47.0784],
            [-95.5302, 47.0056],
            [-95.6252, 46.9194],
            [-95.7316, 46.8853],
            [-95.7339, 46.7737],
            [-95.8451, 46.6841],
            [-95.8783, 46.6736],
            [-95.8789, 46.7022],
            [-95.9242, 46.6757],
            [-95.9428, 46.7244],
            [-95.9222, 46.8476],
            [-95.9975, 47.0211],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2710230',
        NAME: 'Detroit Lakes Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 724330624,
        AWATER: 116434956,
        INTPTLAT: '+46.9235960',
        INTPTLON: '-095.7837481',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.657, 46.3975],
            [-95.5114, 46.4488],
            [-95.414, 46.4055],
            [-95.3008, 46.3402],
            [-95.2796, 46.2932],
            [-95.3017, 46.2383],
            [-95.4606, 46.2282],
            [-95.5213, 46.195],
            [-95.5926, 46.2893],
            [-95.5847, 46.369],
            [-95.657, 46.3975],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713860',
        NAME: 'Henning Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410481776,
        AWATER: 33053325,
        INTPTLAT: '+46.3190300',
        INTPTLON: '-095.4644919',
        ELSDLEA: '',
        UNSDLEA: '13860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5021, 46.5279],
            [-95.3713, 46.6586],
            [-95.4144, 46.7162],
            [-95.4165, 46.7355],
            [-95.3574, 46.751],
            [-95.2887, 46.7166],
            [-95.2704, 46.5646],
            [-95.1973, 46.5467],
            [-95.2857, 46.456],
            [-95.414, 46.4055],
            [-95.5114, 46.4488],
            [-95.5021, 46.5279],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723520',
        NAME: 'New York Mills Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463562138,
        AWATER: 11459418,
        INTPTLAT: '+46.5498881',
        INTPTLON: '-095.3431901',
        ELSDLEA: '',
        UNSDLEA: '23520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.247, 47.2852],
            [-94.1156, 47.2863],
            [-94.0299, 47.3584],
            [-93.774, 47.1923],
            [-93.7753, 47.0304],
            [-93.7755, 46.8564],
            [-93.776, 46.8029],
            [-94.0296, 46.8033],
            [-94.154, 46.8046],
            [-94.3213, 46.9063],
            [-94.278, 46.9795],
            [-94.4065, 47.0669],
            [-94.4046, 47.1529],
            [-94.247, 47.2852],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730870',
        NAME: 'Northland Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1859790251,
        AWATER: 267138273,
        INTPTLAT: '+47.0544103',
        INTPTLON: '-094.0510611',
        ELSDLEA: '',
        UNSDLEA: '30870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4503, 44.8035],
            [-93.3988, 44.7988],
            [-93.335, 44.787],
            [-93.2078, 44.8436],
            [-93.1874, 44.8209],
            [-93.2881, 44.7328],
            [-93.3261, 44.7176],
            [-93.3898, 44.7599],
            [-93.4503, 44.8035],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707290',
        NAME: 'Burnsville Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89619468,
        AWATER: 6463855,
        INTPTLAT: '+44.7873554',
        INTPTLON: '-093.3080975',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-93.4089, 44.6343],
              [-93.3995, 44.6099],
              [-93.4302, 44.6162],
              [-93.4089, 44.6343],
            ],
          ],
          [
            [
              [-93.6789, 44.6869],
              [-93.6224, 44.7472],
              [-93.521, 44.6889],
              [-93.4402, 44.6202],
              [-93.6629, 44.6073],
              [-93.6789, 44.6869],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2715750',
        NAME: 'Jordan Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186086868,
        AWATER: 3179184,
        INTPTLAT: '+44.6509943',
        INTPTLON: '-093.5727788',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3261, 44.7176],
            [-93.2881, 44.7328],
            [-93.2175, 44.6949],
            [-93.2178, 44.5799],
            [-93.3498, 44.5441],
            [-93.3995, 44.6099],
            [-93.4089, 44.6343],
            [-93.3261, 44.7176],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717780',
        NAME: 'Lakeville Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214168266,
        AWATER: 6197066,
        INTPTLAT: '+44.6343539',
        INTPTLON: '-093.2908232',
        ELSDLEA: '',
        UNSDLEA: '17780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6224, 44.7472],
            [-93.5204, 44.8043],
            [-93.4503, 44.8035],
            [-93.3898, 44.7599],
            [-93.521, 44.6889],
            [-93.6224, 44.7472],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733000',
        NAME: 'Shakopee Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127777573,
        AWATER: 4501517,
        INTPTLAT: '+44.7539755',
        INTPTLON: '-093.5146083',
        ELSDLEA: '',
        UNSDLEA: '33000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7844, 46.6305],
            [-96.7094, 46.5873],
            [-96.5935, 46.6091],
            [-96.5296, 46.5001],
            [-96.5136, 46.4639],
            [-96.4872, 46.3839],
            [-96.2805, 46.3477],
            [-96.2654, 46.2386],
            [-96.3277, 46.152],
            [-96.5793, 46.1524],
            [-96.5997, 46.3305],
            [-96.6566, 46.3695],
            [-96.7844, 46.6305],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2706150',
        NAME: 'Breckenridge Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 885056862,
        AWATER: 314902,
        INTPTLAT: '+46.3623768',
        INTPTLON: '-096.5184185',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8633, 46.5431],
            [-94.781, 46.6301],
            [-94.6037, 46.6305],
            [-94.5191, 46.5132],
            [-94.5823, 46.4564],
            [-94.5783, 46.1946],
            [-94.6476, 46.1826],
            [-94.8026, 46.1502],
            [-94.8541, 46.1832],
            [-94.859, 46.1832],
            [-94.8645, 46.1945],
            [-94.8751, 46.1793],
            [-94.8853, 46.1868],
            [-94.9156, 46.2304],
            [-94.9671, 46.3036],
            [-94.9671, 46.3542],
            [-94.8318, 46.4991],
            [-94.8633, 46.5431],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733900',
        NAME: 'Staples-Motley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1201670844,
        AWATER: 33857968,
        INTPTLAT: '+46.3778650',
        INTPTLON: '-094.7615283',
        ELSDLEA: '',
        UNSDLEA: '33900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2887, 46.7166],
            [-95.1859, 46.7063],
            [-95.1853, 46.6882],
            [-95.0385, 46.7181],
            [-94.7866, 46.7163],
            [-94.781, 46.6301],
            [-94.8633, 46.5431],
            [-94.9679, 46.543],
            [-95.1973, 46.5467],
            [-95.2704, 46.5646],
            [-95.2887, 46.7166],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732970',
        NAME: 'Sebeka Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 668716915,
        AWATER: 3515226,
        INTPTLAT: '+46.6154599',
        INTPTLON: '-095.0206271',
        ELSDLEA: '',
        UNSDLEA: '32970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.0098, 45.8615],
              [-94.0058, 45.8615],
              [-94.0096, 45.8535],
              [-94.0098, 45.8615],
            ],
          ],
          [
            [
              [-94.0686, 45.8235],
              [-94.0522, 45.8397],
              [-94.0209, 45.8239],
              [-94.0572, 45.8164],
              [-94.0686, 45.8235],
            ],
          ],
          [
            [
              [-94.0719, 45.8326],
              [-94.0686, 45.8235],
              [-94.0733, 45.8235],
              [-94.0719, 45.8326],
            ],
          ],
          [
            [
              [-94.125, 46.156],
              [-93.9373, 46.1566],
              [-93.9363, 45.9833],
              [-93.8306, 45.9681],
              [-93.8306, 45.9535],
              [-93.8509, 45.9538],
              [-93.8918, 45.9105],
              [-93.9902, 45.8613],
              [-94.0058, 45.8615],
              [-94.0099, 45.8678],
              [-94.0522, 45.8397],
              [-94.0719, 45.8326],
              [-94.1241, 45.8232],
              [-94.1248, 45.8232],
              [-94.2194, 45.9119],
              [-94.125, 46.156],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728320',
        NAME: 'Pierz Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610444750,
        AWATER: 4789278,
        INTPTLAT: '+45.9942788',
        INTPTLON: '-094.0548626',
        ELSDLEA: '',
        UNSDLEA: '28320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.0099, 45.8678],
              [-94.0058, 45.8615],
              [-94.0098, 45.8615],
              [-94.0099, 45.8678],
            ],
          ],
          [
            [
              [-94.0522, 45.8397],
              [-94.0686, 45.8235],
              [-94.0719, 45.8326],
              [-94.0522, 45.8397],
            ],
          ],
          [
            [
              [-94.0099, 45.8678],
              [-94.0098, 45.8615],
              [-94.0096, 45.8535],
              [-94.0058, 45.8615],
              [-93.9902, 45.8613],
              [-94.0209, 45.8239],
              [-94.0522, 45.8397],
              [-94.0099, 45.8678],
            ],
          ],
          [
            [
              [-94.0719, 45.8326],
              [-94.0733, 45.8235],
              [-94.1241, 45.8232],
              [-94.0719, 45.8326],
            ],
          ],
          [
            [
              [-94.431, 45.8549],
              [-94.4201, 45.8549],
              [-94.4105, 45.8582],
              [-94.4106, 45.8621],
              [-94.3745, 45.9107],
              [-94.2194, 45.9119],
              [-94.1248, 45.8232],
              [-94.1754, 45.7938],
              [-94.2717, 45.775],
              [-94.4195, 45.8045],
              [-94.4238, 45.8192],
              [-94.431, 45.8549],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732520',
        NAME: 'Royalton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288179242,
        AWATER: 4062436,
        INTPTLAT: '+45.8412940',
        INTPTLON: '-094.2385041',
        ELSDLEA: '',
        UNSDLEA: '32520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8017, 44.6299],
            [-94.5586, 44.6296],
            [-94.4169, 44.6738],
            [-94.2751, 44.6304],
            [-94.2859, 44.4204],
            [-94.5023, 44.4526],
            [-94.6187, 44.427],
            [-94.6237, 44.3824],
            [-94.7552, 44.4476],
            [-94.7942, 44.471],
            [-94.8017, 44.6299],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712580',
        NAME: 'G.F.W.',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 979505667,
        AWATER: 10315602,
        INTPTLAT: '+44.5410367',
        INTPTLON: '-094.5217167',
        ELSDLEA: '',
        UNSDLEA: '12580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8526, 45.56],
            [-93.7704, 45.6488],
            [-93.5959, 45.6619],
            [-93.4733, 45.719],
            [-93.4422, 45.6809],
            [-93.4097, 45.5127],
            [-93.4615, 45.4718],
            [-93.5106, 45.4719],
            [-93.6541, 45.4722],
            [-93.7583, 45.4727],
            [-93.8526, 45.56],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2730030',
        NAME: 'Princeton Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 593150580,
        AWATER: 20678754,
        INTPTLAT: '+45.5759710',
        INTPTLON: '-093.6000896',
        ELSDLEA: '',
        UNSDLEA: '30030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4097, 45.6021],
            [-94.3198, 45.5998],
            [-94.1686, 45.5859],
            [-94.0183, 45.5592],
            [-93.9452, 45.5379],
            [-93.9435, 45.4368],
            [-93.9939, 45.4008],
            [-93.9942, 45.375],
            [-94.0142, 45.3619],
            [-94.0814, 45.3731],
            [-94.1176, 45.3981],
            [-94.2333, 45.4211],
            [-94.3335, 45.3936],
            [-94.3025, 45.4854],
            [-94.442, 45.5739],
            [-94.4097, 45.6021],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733510',
        NAME: 'St. Cloud Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 606127115,
        AWATER: 30002730,
        INTPTLAT: '+45.5025578',
        INTPTLON: '-094.1648116',
        ELSDLEA: '',
        UNSDLEA: '33510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9246, 47.8027],
            [-94.8039, 47.846],
            [-94.4185, 47.8458],
            [-94.4163, 47.4448],
            [-94.546, 47.4109],
            [-94.5455, 47.5845],
            [-94.6719, 47.6369],
            [-94.8005, 47.6422],
            [-94.8066, 47.7284],
            [-94.9246, 47.8027],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2705730',
        NAME: 'Blackduck Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1027780870,
        AWATER: 83532948,
        INTPTLAT: '+47.6928832',
        INTPTLON: '-094.5782797',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2795, 47.4573],
            [-93.1961, 47.4572],
            [-93.1939, 47.2838],
            [-93.2373, 47.2677],
            [-93.3628, 47.268],
            [-93.3754, 47.1963],
            [-93.5032, 47.1956],
            [-93.4483, 47.2825],
            [-93.45, 47.4572],
            [-93.2795, 47.4573],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709480',
        NAME: 'Greenway Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452565637,
        AWATER: 33091606,
        INTPTLAT: '+47.3445627',
        INTPTLON: '-093.3507880',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0635, 47.6331],
            [-93.0662, 47.1093],
            [-93.1935, 47.1091],
            [-93.2373, 47.2677],
            [-93.1939, 47.2838],
            [-93.1961, 47.4572],
            [-93.2795, 47.4573],
            [-93.2748, 47.6308],
            [-93.0635, 47.6331],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723310',
        NAME: 'Nashwauk-Keewatin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 683258088,
        AWATER: 33877000,
        INTPTLAT: '+47.3907940',
        INTPTLON: '-093.1489511',
        ELSDLEA: '',
        UNSDLEA: '23310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2078, 45.0206],
            [-93.1063, 45.0426],
            [-93.0562, 45.0359],
            [-93.0661, 44.992],
            [-93.2076, 44.9881],
            [-93.2076, 45.0062],
            [-93.2078, 45.0206],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2732430',
        NAME: 'Roseville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51262074,
        AWATER: 4721635,
        INTPTLAT: '+45.0121283',
        INTPTLON: '-093.1338462',
        ELSDLEA: '',
        UNSDLEA: '32430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0872, 45.124],
            [-93.056, 45.1458],
            [-93.0409, 45.182],
            [-92.9482, 45.1458],
            [-92.9848, 45.0358],
            [-93.0562, 45.0359],
            [-93.1063, 45.0426],
            [-93.0872, 45.124],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2742360',
        NAME: 'White Bear Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100127681,
        AWATER: 18879480,
        INTPTLAT: '+45.1013449',
        INTPTLON: '-093.0247794',
        ELSDLEA: '',
        UNSDLEA: '42360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2047, 45.1244],
            [-93.0872, 45.124],
            [-93.1063, 45.0426],
            [-93.2078, 45.0206],
            [-93.2271, 45.0499],
            [-93.2274, 45.0721],
            [-93.2277, 45.0933],
            [-93.2047, 45.1244],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2722950',
        NAME: 'Mounds View Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97510339,
        AWATER: 13241186,
        INTPTLAT: '+45.0835011',
        INTPTLON: '-093.1549252',
        ELSDLEA: '',
        UNSDLEA: '22950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0562, 45.0359],
            [-92.9848, 45.0358],
            [-92.9481, 45.0281],
            [-92.9238, 44.9343],
            [-93.0043, 44.8908],
            [-93.0661, 44.992],
            [-93.0562, 45.0359],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723850',
        NAME: 'North St. Paul-Maplewood School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90594123,
        AWATER: 4101115,
        INTPTLAT: '+44.9829666',
        INTPTLON: '-092.9808588',
        ELSDLEA: '',
        UNSDLEA: '23850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5428, 45.0937],
            [-95.5127, 45.1081],
            [-95.5016, 45.1149],
            [-95.2469, 45.1018],
            [-95.2268, 45.1452],
            [-95.1491, 45.1099],
            [-95.0876, 45.0813],
            [-95.0846, 44.979],
            [-95.125, 44.964],
            [-95.2484, 44.9783],
            [-95.2485, 44.8999],
            [-95.3939, 44.891],
            [-95.4299, 44.8472],
            [-95.5952, 44.9056],
            [-95.5547, 44.9269],
            [-95.5944, 45.0647],
            [-95.5428, 45.0937],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700091',
        NAME: 'M.A.C.C.R.A.Y. School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 822021278,
        AWATER: 1117333,
        INTPTLAT: '+45.0003459',
        INTPTLON: '-095.3616922',
        ELSDLEA: '',
        UNSDLEA: '00091',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.886, 45.4849],
            [-94.7014, 45.499],
            [-94.6139, 45.4742],
            [-94.5769, 45.4056],
            [-94.6397, 45.3689],
            [-94.6415, 45.2722],
            [-94.7418, 45.2464],
            [-94.8132, 45.2828],
            [-94.8454, 45.3698],
            [-94.886, 45.4849],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728140',
        NAME: 'Paynesville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420811776,
        AWATER: 19501365,
        INTPTLAT: '+45.4018454',
        INTPTLON: '-094.7324897',
        ELSDLEA: '',
        UNSDLEA: '28140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1147, 45.116],
            [-94.1042, 45.1522],
            [-94.0579, 45.1613],
            [-94.0023, 45.1449],
            [-93.9411, 45.0331],
            [-94.0125, 44.9353],
            [-94.104, 44.9456],
            [-94.1141, 44.9498],
            [-94.1291, 44.9498],
            [-94.1343, 44.9788],
            [-94.1147, 45.116],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700123',
        NAME: 'Howard Lake-Waverly-Winsted',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274676549,
        AWATER: 17697965,
        INTPTLAT: '+45.0423581',
        INTPTLON: '-094.0396813',
        ELSDLEA: '',
        UNSDLEA: '00123',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5632, 44.9505],
            [-94.5526, 44.9651],
            [-94.4122, 45.023],
            [-94.4044, 45.0149],
            [-94.3532, 44.9862],
            [-94.2561, 44.9795],
            [-94.2596, 44.9505],
            [-94.2739, 44.8848],
            [-94.2953, 44.8413],
            [-94.4064, 44.7758],
            [-94.5184, 44.8265],
            [-94.5435, 44.8921],
            [-94.5432, 44.8993],
            [-94.5632, 44.9505],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714970',
        NAME: 'Hutchinson Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412694899,
        AWATER: 23877372,
        INTPTLAT: '+44.8984750',
        INTPTLON: '-094.4018630',
        ELSDLEA: '',
        UNSDLEA: '14970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1141, 44.9498],
            [-94.104, 44.9456],
            [-94.0125, 44.9353],
            [-94.0123, 44.9061],
            [-94.01, 44.8623],
            [-94.01, 44.8551],
            [-94.01, 44.8333],
            [-94.1314, 44.8444],
            [-94.1141, 44.9498],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2702640',
        NAME: 'Lester Prairie Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106761329,
        AWATER: 579242,
        INTPTLAT: '+44.8804377',
        INTPTLON: '-094.0614136',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0556, 46.5477],
            [-92.8792, 46.5471],
            [-92.7344, 46.6501],
            [-92.5442, 46.635],
            [-92.5086, 46.4892],
            [-92.5295, 46.4605],
            [-92.7203, 46.4614],
            [-92.8462, 46.5051],
            [-93.0552, 46.5069],
            [-93.0556, 46.5477],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703690',
        NAME: 'Barnum Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499099471,
        AWATER: 7715453,
        INTPTLAT: '+46.5603249',
        INTPTLON: '-092.7112679',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7343, 46.7074],
            [-92.4187, 46.6927],
            [-92.3011, 46.668],
            [-92.2922, 46.6633],
            [-92.2916, 46.6341],
            [-92.4279, 46.6342],
            [-92.4247, 46.518],
            [-92.5086, 46.4892],
            [-92.5442, 46.635],
            [-92.7344, 46.6501],
            [-92.7343, 46.7074],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707590',
        NAME: 'Carlton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277955227,
        AWATER: 12811623,
        INTPTLAT: '+46.6111180',
        INTPTLON: '-092.5231059',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9861, 44.1428],
            [-91.9607, 44.1785],
            [-91.9392, 44.1329],
            [-91.7762, 44.041],
            [-91.74, 43.9332],
            [-91.78, 43.8896],
            [-91.9594, 43.8764],
            [-91.9437, 43.9125],
            [-91.9594, 44.0204],
            [-92.0099, 44.0418],
            [-91.9861, 44.1428],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718090',
        NAME: 'Lewiston-Altura Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411495743,
        AWATER: 0,
        INTPTLAT: '+44.0024748',
        INTPTLON: '-091.8789787',
        ELSDLEA: '',
        UNSDLEA: '18090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9607, 44.1785],
            [-91.9496, 44.1967],
            [-91.9495, 44.2038],
            [-91.9496, 44.2074],
            [-91.9199, 44.2073],
            [-91.8925, 44.2363],
            [-91.8752, 44.2006],
            [-91.559, 44.0253],
            [-91.4252, 43.9843],
            [-91.3477, 43.912],
            [-91.3434, 43.9046],
            [-91.4754, 43.8786],
            [-91.4755, 43.8787],
            [-91.4802, 43.8787],
            [-91.7091, 43.877],
            [-91.74, 43.9332],
            [-91.7762, 44.041],
            [-91.9392, 44.1329],
            [-91.9607, 44.1785],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2744070',
        NAME: 'Winona Area Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653348029,
        AWATER: 44410380,
        INTPTLAT: '+43.9893140',
        INTPTLON: '-091.6630395',
        ELSDLEA: '',
        UNSDLEA: '44070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7909, 46.703],
            [-96.7839, 46.7064],
            [-96.622, 46.6947],
            [-96.5894, 46.7687],
            [-96.4833, 46.7612],
            [-96.4206, 46.8044],
            [-96.2325, 46.7257],
            [-96.2333, 46.6307],
            [-96.2812, 46.5732],
            [-96.3437, 46.5296],
            [-96.4458, 46.5435],
            [-96.5296, 46.5001],
            [-96.5935, 46.6091],
            [-96.7094, 46.5873],
            [-96.7844, 46.6305],
            [-96.7981, 46.6303],
            [-96.7909, 46.703],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703660',
        NAME: 'Barnesville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 887315145,
        AWATER: 1674110,
        INTPTLAT: '+46.6523910',
        INTPTLON: '-096.4724032',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.452, 44.4128],
            [-96.4516, 44.6313],
            [-96.4395, 44.6312],
            [-96.4256, 44.6312],
            [-96.4091, 44.6238],
            [-96.348, 44.6089],
            [-96.3225, 44.5435],
            [-96.3536, 44.3863],
            [-96.3841, 44.3641],
            [-96.4522, 44.3717],
            [-96.452, 44.4128],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713680',
        NAME: 'Hendricks Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226325191,
        AWATER: 4505330,
        INTPTLAT: '+44.5088558',
        INTPTLON: '-096.3990986',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1285, 44.6016],
            [-96.0118, 44.7173],
            [-95.9814, 44.7176],
            [-95.9311, 44.6672],
            [-95.931, 44.6598],
            [-95.8696, 44.6308],
            [-95.8495, 44.5761],
            [-95.8494, 44.5724],
            [-95.9072, 44.487],
            [-96.0393, 44.4151],
            [-96.0794, 44.4153],
            [-96.1001, 44.5029],
            [-96.1205, 44.5247],
            [-96.1539, 44.5503],
            [-96.1285, 44.6016],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721270',
        NAME: 'Minneota Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453221761,
        AWATER: 540925,
        INTPTLAT: '+44.5695891',
        INTPTLON: '-096.0003253',
        ELSDLEA: '',
        UNSDLEA: '21270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3225, 44.5435],
            [-96.2855, 44.5791],
            [-96.1744, 44.5579],
            [-96.1539, 44.5503],
            [-96.1205, 44.5247],
            [-96.1001, 44.5029],
            [-96.0794, 44.4153],
            [-96.2057, 44.379],
            [-96.2267, 44.3502],
            [-96.3024, 44.4009],
            [-96.3236, 44.3863],
            [-96.3536, 44.3863],
            [-96.3225, 44.5435],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2715540',
        NAME: 'Ivanhoe Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374406680,
        AWATER: 9725657,
        INTPTLAT: '+44.4676928',
        INTPTLON: '-096.2309742',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.033, 47.413],
            [-94.036, 47.7599],
            [-93.7972, 47.7458],
            [-93.7941, 47.6733],
            [-93.5771, 47.6308],
            [-93.6904, 47.4563],
            [-93.7012, 47.326],
            [-93.774, 47.3108],
            [-93.774, 47.1923],
            [-94.0299, 47.3584],
            [-94.033, 47.413],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2710140',
        NAME: 'Deer River Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1251063739,
        AWATER: 156816268,
        INTPTLAT: '+47.5138496',
        INTPTLON: '-093.8474107',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4185, 47.8458],
            [-93.7762, 47.8464],
            [-93.7758, 47.8989],
            [-93.4097, 47.8944],
            [-93.0812, 47.8919],
            [-93.0613, 47.7202],
            [-93.0635, 47.6331],
            [-93.2748, 47.6308],
            [-93.2795, 47.4573],
            [-93.45, 47.4572],
            [-93.4483, 47.2825],
            [-93.5032, 47.1956],
            [-93.3754, 47.1963],
            [-93.3628, 47.268],
            [-93.2373, 47.2677],
            [-93.1935, 47.1091],
            [-93.0662, 47.1093],
            [-93.0562, 47.1093],
            [-93.0559, 47.0264],
            [-93.1902, 47.0252],
            [-93.7753, 47.0304],
            [-93.774, 47.1923],
            [-93.774, 47.3108],
            [-93.7012, 47.326],
            [-93.6904, 47.4563],
            [-93.5771, 47.6308],
            [-93.7941, 47.6733],
            [-93.7972, 47.7458],
            [-94.036, 47.7599],
            [-94.033, 47.413],
            [-94.118, 47.4807],
            [-94.3275, 47.4158],
            [-94.4163, 47.4448],
            [-94.4185, 47.8458],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713170',
        NAME: 'Grand Rapids Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4595508300,
        AWATER: 451319451,
        INTPTLAT: '+47.6833946',
        INTPTLON: '-093.3914386',
        ELSDLEA: '',
        UNSDLEA: '13170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6109, 43.6439],
            [-91.4423, 43.7317],
            [-91.3727, 43.7245],
            [-91.2679, 43.7116],
            [-91.2681, 43.7015],
            [-91.2687, 43.6153],
            [-91.2177, 43.5005],
            [-91.4045, 43.5008],
            [-91.5211, 43.5006],
            [-91.5457, 43.5947],
            [-91.6109, 43.6439],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707380',
        NAME: 'Caledonia Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 589435145,
        AWATER: 24140289,
        INTPTLAT: '+43.6137125',
        INTPTLON: '-091.4305114',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0101, 44.8638],
            [-93.02, 44.8907],
            [-93.0043, 44.8908],
            [-92.9238, 44.9343],
            [-92.8215, 44.8656],
            [-92.8863, 44.7746],
            [-92.9742, 44.7724],
            [-93.016, 44.7756],
            [-93.0101, 44.8638],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733810',
        NAME: 'South Washington County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189718455,
        AWATER: 14784146,
        INTPTLAT: '+44.8546994',
        INTPTLON: '-092.9284352',
        ELSDLEA: '',
        UNSDLEA: '33810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9481, 45.0281],
            [-92.8941, 45.1083],
            [-92.9381, 45.1444],
            [-92.8159, 45.2099],
            [-92.7523, 45.2169],
            [-92.7557, 45.1671],
            [-92.7924, 45.0789],
            [-92.7648, 44.9054],
            [-92.7695, 44.862],
            [-92.8215, 44.8656],
            [-92.9238, 44.9343],
            [-92.9481, 45.0281],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2738190',
        NAME: 'Stillwater Area Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363257813,
        AWATER: 34294253,
        INTPTLAT: '+45.0376019',
        INTPTLON: '-092.8353427',
        ELSDLEA: '',
        UNSDLEA: '38190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0893, 45.6018],
            [-92.886, 45.6004],
            [-92.7274, 45.5274],
            [-92.7908, 45.4747],
            [-92.9583, 45.4261],
            [-92.9579, 45.3676],
            [-93.1078, 45.4136],
            [-93.0893, 45.6018],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723820',
        NAME: 'North Branch Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439189583,
        AWATER: 14750187,
        INTPTLAT: '+45.5052345',
        INTPTLON: '-092.9526805',
        ELSDLEA: '',
        UNSDLEA: '23820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4986, 43.7119],
            [-93.474, 43.6801],
            [-93.5049, 43.5862],
            [-93.5987, 43.5433],
            [-93.6484, 43.5943],
            [-93.6484, 43.7059],
            [-93.4986, 43.7119],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2703030',
        NAME: 'Alden-Conger Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221423448,
        AWATER: 1690920,
        INTPTLAT: '+43.6364923',
        INTPTLON: '-093.5615029',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1896, 44.3793],
            [-92.1381, 44.4244],
            [-92.0836, 44.4073],
            [-91.96, 44.3598],
            [-91.9238, 44.2903],
            [-91.8925, 44.2363],
            [-91.9199, 44.2073],
            [-91.9496, 44.2074],
            [-92.1594, 44.2875],
            [-92.2199, 44.2947],
            [-92.1896, 44.3793],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741060',
        NAME: 'Wabasha-Kellogg Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321815328,
        AWATER: 35252099,
        INTPTLAT: '+44.3128279',
        INTPTLON: '-092.0309015',
        ELSDLEA: '',
        UNSDLEA: '41060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4754, 43.8786],
            [-91.3434, 43.9046],
            [-91.2441, 43.7747],
            [-91.2679, 43.7116],
            [-91.3727, 43.7245],
            [-91.4423, 43.7317],
            [-91.4164, 43.7951],
            [-91.4754, 43.8786],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717250',
        NAME: 'La Crescent-Hokah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231349142,
        AWATER: 19320505,
        INTPTLAT: '+43.8010928',
        INTPTLON: '-091.3357997',
        ELSDLEA: '',
        UNSDLEA: '17250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6608, 43.8321],
            [-91.7091, 43.877],
            [-91.4802, 43.8787],
            [-91.4755, 43.8787],
            [-91.4754, 43.8786],
            [-91.4164, 43.7951],
            [-91.4423, 43.7317],
            [-91.6109, 43.6439],
            [-91.7005, 43.6698],
            [-91.7103, 43.6664],
            [-91.7154, 43.6664],
            [-91.6608, 43.8321],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714280',
        NAME: 'Houston Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397633319,
        AWATER: 2531007,
        INTPTLAT: '+43.7767700',
        INTPTLON: '-091.5745845',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.32, 45.0801],
            [-93.2808, 45.0717],
            [-93.2841, 45.0649],
            [-93.2822, 45.0512],
            [-93.3142, 45.0512],
            [-93.3141, 45.0656],
            [-93.32, 45.0801],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2706240',
        NAME: 'Brooklyn Center School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7100599,
        AWATER: 204707,
        INTPTLAT: '+45.0676760',
        INTPTLON: '-093.3020893',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0023, 45.1449],
            [-94.0022, 45.1593],
            [-93.9003, 45.2377],
            [-93.7658, 45.2379],
            [-93.7469, 45.188],
            [-93.6387, 45.1744],
            [-93.5269, 45.1636],
            [-93.543, 45.1093],
            [-93.7811, 45.1263],
            [-93.8021, 45.101],
            [-93.9178, 45.0146],
            [-93.9411, 45.0331],
            [-94.0023, 45.1449],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707200',
        NAME: 'Buffalo-Hanover-Montrose Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357416123,
        AWATER: 29200511,
        INTPTLAT: '+45.1478809',
        INTPTLON: '-093.8166389',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4817, 44.9484],
            [-93.4108, 44.9948],
            [-93.3184, 44.9916],
            [-93.3285, 44.9666],
            [-93.3708, 44.9205],
            [-93.3991, 44.8843],
            [-93.4823, 44.8917],
            [-93.4817, 44.9484],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714260',
        NAME: 'Hopkins Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73814741,
        AWATER: 2099028,
        INTPTLAT: '+44.9380003',
        INTPTLON: '-093.4127217',
        ELSDLEA: '',
        UNSDLEA: '14260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3188, 44.8908],
            [-93.2529, 44.8907],
            [-93.2473, 44.8623],
            [-93.3289, 44.8619],
            [-93.3188, 44.8908],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2731750',
        NAME: 'Richfield Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19974917,
        AWATER: 410201,
        INTPTLAT: '+44.8771330',
        INTPTLON: '-093.2873664',
        ELSDLEA: '',
        UNSDLEA: '31750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2841, 45.0649],
            [-93.2274, 45.0721],
            [-93.2271, 45.0499],
            [-93.2269, 45.0357],
            [-93.2822, 45.0512],
            [-93.2841, 45.0649],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709510',
        NAME: 'Columbia Heights Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15322463,
        AWATER: 793706,
        INTPTLAT: '+45.0522467',
        INTPTLON: '-093.2538317',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5867, 43.9723],
            [-93.4366, 43.9931],
            [-93.4065, 43.9858],
            [-93.226, 43.921],
            [-93.1861, 43.8849],
            [-93.1998, 43.7899],
            [-93.214, 43.7897],
            [-93.2435, 43.7926],
            [-93.4088, 43.7748],
            [-93.4656, 43.7748],
            [-93.4655, 43.7862],
            [-93.5122, 43.7821],
            [-93.5187, 43.84],
            [-93.6473, 43.8552],
            [-93.5867, 43.9723],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700089',
        NAME: 'NRHEG School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 603312124,
        AWATER: 6145773,
        INTPTLAT: '+43.8850975',
        INTPTLON: '-093.4117599',
        ELSDLEA: '',
        UNSDLEA: '00089',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4065, 44.1455],
            [-93.3065, 44.1313],
            [-93.0747, 44.1751],
            [-93.0457, 44.168],
            [-93.0658, 44.0082],
            [-93.0601, 43.9865],
            [-93.217, 43.9248],
            [-93.226, 43.921],
            [-93.4065, 43.9858],
            [-93.4065, 44.1455],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728050',
        NAME: 'Owatonna Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 599096700,
        AWATER: 4506628,
        INTPTLAT: '+44.0443277',
        INTPTLON: '-093.2162179',
        ELSDLEA: '',
        UNSDLEA: '28050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.923, 43.7896],
            [-93.7678, 43.79],
            [-93.708, 43.8479],
            [-93.6473, 43.8552],
            [-93.5187, 43.84],
            [-93.5122, 43.7821],
            [-93.4655, 43.7862],
            [-93.4656, 43.7748],
            [-93.4088, 43.7748],
            [-93.4986, 43.7119],
            [-93.6484, 43.7059],
            [-93.6484, 43.5943],
            [-93.5987, 43.5433],
            [-93.6046, 43.4996],
            [-93.6972, 43.4997],
            [-93.8884, 43.4998],
            [-93.867, 43.6736],
            [-93.923, 43.7896],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700005',
        NAME: 'United South Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 874854238,
        AWATER: 14477850,
        INTPTLAT: '+43.6790272',
        INTPTLON: '-093.7140255',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7728, 47.7609],
            [-95.6436, 47.7612],
            [-95.5535, 47.6161],
            [-95.551, 47.4112],
            [-95.8053, 47.4114],
            [-95.8692, 47.4993],
            [-95.8962, 47.536],
            [-95.7721, 47.6738],
            [-95.7728, 47.7609],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712300',
        NAME: 'Fosston Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 748863758,
        AWATER: 38475423,
        INTPTLAT: '+47.5680727',
        INTPTLON: '-095.6958251',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5888, 48.5289],
            [-93.3475, 48.6266],
            [-93.0884, 48.6276],
            [-93.0971, 48.1792],
            [-93.3561, 48.156],
            [-93.3531, 48.4184],
            [-93.4824, 48.4184],
            [-93.4832, 48.5057],
            [-93.5861, 48.5214],
            [-93.5888, 48.5289],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2715000',
        NAME: 'International Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1133120565,
        AWATER: 89056549,
        INTPTLAT: '+48.4142810',
        INTPTLON: '-093.2781962',
        ELSDLEA: '',
        UNSDLEA: '15000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2271, 45.0499],
            [-93.2078, 45.0206],
            [-93.2076, 45.0062],
            [-93.2269, 45.0357],
            [-93.2271, 45.0499],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733420',
        NAME: 'St. Anthony-New Brighton Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6438132,
        AWATER: 317033,
        INTPTLAT: '+45.0303318',
        INTPTLON: '-093.2154174',
        ELSDLEA: '',
        UNSDLEA: '33420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3285, 44.9666],
            [-93.3291, 44.9306],
            [-93.3708, 44.9205],
            [-93.3285, 44.9666],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733780',
        NAME: 'St. Louis Park Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26676922,
        AWATER: 588496,
        INTPTLAT: '+44.9486930',
        INTPTLON: '-093.3637621',
        ELSDLEA: '',
        UNSDLEA: '33780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6466, 45.6507],
            [-96.4895, 45.6724],
            [-96.254, 45.6727],
            [-96.2412, 45.448],
            [-96.1998, 45.4123],
            [-96.5027, 45.3987],
            [-96.5751, 45.4281],
            [-96.5748, 45.391],
            [-96.6781, 45.4104],
            [-96.7632, 45.5195],
            [-96.8355, 45.5861],
            [-96.6466, 45.6507],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700150',
        NAME: 'Clinton-Graceville-Beardsley',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1113017761,
        AWATER: 31924560,
        INTPTLAT: '+45.5331969',
        INTPTLON: '-096.4835852',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.254, 45.6727],
            [-96.2541, 45.6802],
            [-96.1716, 45.6944],
            [-96.1608, 45.6944],
            [-96.1582, 45.6944],
            [-96.0639, 45.7015],
            [-95.9737, 45.4704],
            [-95.9943, 45.4123],
            [-96.1475, 45.4054],
            [-96.2085, 45.399],
            [-96.1998, 45.4123],
            [-96.2412, 45.448],
            [-96.254, 45.6727],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708940',
        NAME: 'Chokio-Alberta Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579816697,
        AWATER: 7424506,
        INTPTLAT: '+45.5350514',
        INTPTLON: '-096.1276125',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6243, 45.2461],
            [-94.4864, 45.2608],
            [-94.4553, 45.2572],
            [-94.256, 45.2111],
            [-94.3793, 45.1522],
            [-94.3992, 45.0659],
            [-94.4122, 45.023],
            [-94.5526, 44.9651],
            [-94.5632, 44.9505],
            [-94.6351, 44.9935],
            [-94.6243, 45.2461],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718210',
        NAME: 'Litchfield Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 584879418,
        AWATER: 39842017,
        INTPTLAT: '+45.1257351',
        INTPTLON: '-094.4979181',
        ELSDLEA: '',
        UNSDLEA: '18210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8993, 44.9932],
            [-95.7062, 45.0573],
            [-95.6847, 45.0573],
            [-95.6516, 45.0718],
            [-95.6616, 45.0861],
            [-95.6749, 45.1514],
            [-95.5428, 45.0937],
            [-95.5944, 45.0647],
            [-95.5547, 44.9269],
            [-95.5952, 44.9056],
            [-95.7187, 44.8561],
            [-95.8294, 44.8991],
            [-95.8993, 44.9932],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721320',
        NAME: 'Montevideo Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480185747,
        AWATER: 2868101,
        INTPTLAT: '+44.9856003',
        INTPTLON: '-095.7060085',
        ELSDLEA: '',
        UNSDLEA: '21320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3384, 43.9348],
            [-94.3289, 43.9348],
            [-94.3092, 43.9347],
            [-94.3092, 43.9456],
            [-94.1186, 43.9349],
            [-94.074, 44.0328],
            [-93.9792, 44.06],
            [-93.8692, 44.0079],
            [-93.8692, 43.9644],
            [-93.7277, 43.9059],
            [-93.708, 43.8479],
            [-93.7678, 43.79],
            [-93.923, 43.7896],
            [-93.9698, 43.8482],
            [-94.2672, 43.848],
            [-94.2994, 43.8696],
            [-94.3492, 43.9205],
            [-94.3384, 43.9348],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700006',
        NAME: 'Maple River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765341639,
        AWATER: 17941767,
        INTPTLAT: '+43.9061261',
        INTPTLON: '-093.9885016',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3689, 44.1814],
            [-94.3118, 44.2428],
            [-94.1449, 44.194],
            [-94.0039, 44.0722],
            [-93.9792, 44.06],
            [-94.074, 44.0328],
            [-94.1186, 43.9349],
            [-94.3092, 43.9456],
            [-94.3093, 44.0726],
            [-94.3689, 44.1814],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791445',
        NAME: 'Lake Crystal-Wellcome Memorial',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 578895430,
        AWATER: 12977491,
        INTPTLAT: '+44.0846977',
        INTPTLON: '-094.1950556',
        ELSDLEA: '',
        UNSDLEA: '91445',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8692, 44.0079],
            [-93.7683, 44.0602],
            [-93.8197, 44.152],
            [-93.768, 44.2009],
            [-93.7128, 44.1923],
            [-93.6381, 44.1742],
            [-93.6176, 44.0949],
            [-93.6472, 44.0004],
            [-93.5867, 43.9723],
            [-93.6473, 43.8552],
            [-93.708, 43.8479],
            [-93.7277, 43.9059],
            [-93.8692, 43.9644],
            [-93.8692, 44.0079],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2791451',
        NAME: 'Janesville-Waldorf-Pemberton',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503196921,
        AWATER: 18245460,
        INTPTLAT: '+44.0281360',
        INTPTLON: '-093.7224228',
        ELSDLEA: '',
        UNSDLEA: '91451',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5701, 44.1222],
            [-94.4342, 44.1235],
            [-94.3689, 44.1814],
            [-94.3093, 44.0726],
            [-94.3092, 43.9456],
            [-94.3092, 43.9347],
            [-94.3289, 43.9348],
            [-94.3384, 43.9348],
            [-94.4892, 43.935],
            [-94.5701, 44.1222],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718570',
        NAME: 'Madelia Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372171769,
        AWATER: 4566010,
        INTPTLAT: '+44.0438696',
        INTPTLON: '-094.4144554',
        ELSDLEA: '',
        UNSDLEA: '18570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0039, 44.0722],
            [-93.8285, 44.1486],
            [-93.8197, 44.152],
            [-93.7683, 44.0602],
            [-93.8692, 44.0079],
            [-93.9792, 44.06],
            [-94.0039, 44.0722],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733480',
        NAME: 'St. Clair Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192769288,
        AWATER: 1169305,
        INTPTLAT: '+44.0826562',
        INTPTLON: '-093.8713999',
        ELSDLEA: '',
        UNSDLEA: '33480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9192, 44.37],
            [-93.8341, 44.3951],
            [-93.7226, 44.2828],
            [-93.7786, 44.2321],
            [-93.8884, 44.2563],
            [-93.9192, 44.37],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2709330',
        NAME: 'Cleveland Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149010002,
        AWATER: 23069826,
        INTPTLAT: '+44.3053501',
        INTPTLON: '-093.8195353',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0594, 43.5795],
            [-92.9385, 43.5871],
            [-92.8358, 43.4999],
            [-93.0654, 43.4996],
            [-93.1005, 43.4996],
            [-93.0594, 43.5795],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718360',
        NAME: 'Lyle Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167664570,
        AWATER: 0,
        INTPTLAT: '+43.5366771',
        INTPTLON: '-092.9650599',
        ELSDLEA: '',
        UNSDLEA: '18360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6892, 43.7758],
            [-92.6696, 43.7973],
            [-92.5593, 43.7904],
            [-92.4945, 43.7395],
            [-92.4793, 43.6961],
            [-92.4893, 43.6162],
            [-92.609, 43.5869],
            [-92.6297, 43.6957],
            [-92.6892, 43.7758],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2713140',
        NAME: 'Grand Meadow Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249281371,
        AWATER: 11114,
        INTPTLAT: '+43.7005876',
        INTPTLON: '-092.5729687',
        ELSDLEA: '',
        UNSDLEA: '13140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6237, 43.8273],
            [-94.4892, 43.935],
            [-94.3384, 43.9348],
            [-94.3492, 43.9205],
            [-94.2994, 43.8696],
            [-94.2672, 43.848],
            [-94.2773, 43.8185],
            [-94.4184, 43.7529],
            [-94.542, 43.7539],
            [-94.6237, 43.8273],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2740680',
        NAME: 'Truman Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388711923,
        AWATER: 1937948,
        INTPTLAT: '+43.8412684',
        INTPTLON: '-094.4373760',
        ELSDLEA: '',
        UNSDLEA: '40680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7383, 44.1089],
            [-94.7144, 44.1235],
            [-94.6464, 44.1517],
            [-94.5701, 44.1222],
            [-94.4892, 43.935],
            [-94.6237, 43.8273],
            [-94.7322, 43.8482],
            [-94.739, 44.0365],
            [-94.7793, 44.0655],
            [-94.7383, 44.1089],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2733600',
        NAME: 'St. James Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569552258,
        AWATER: 5211832,
        INTPTLAT: '+43.9836244',
        INTPTLON: '-094.6347259',
        ELSDLEA: '',
        UNSDLEA: '33600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8592, 44.0213],
            [-94.7793, 44.0655],
            [-94.739, 44.0365],
            [-94.7322, 43.8482],
            [-94.7945, 43.8198],
            [-94.8545, 43.8194],
            [-94.8592, 44.0213],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2707320',
        NAME: 'Butterfield Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240515695,
        AWATER: 3046496,
        INTPTLAT: '+43.9378261',
        INTPTLON: '-094.8072871',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.359, 43.5945],
            [-95.3051, 43.6092],
            [-95.2157, 43.7905],
            [-95.125, 43.7691],
            [-95.0348, 43.761],
            [-94.9546, 43.8045],
            [-94.8833, 43.7906],
            [-94.8545, 43.7905],
            [-94.8546, 43.5009],
            [-94.9248, 43.5009],
            [-95.2013, 43.5008],
            [-95.3946, 43.5002],
            [-95.359, 43.5945],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700231',
        NAME: 'Jackson County Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1117504036,
        AWATER: 24568711,
        INTPTLAT: '+43.6221225',
        INTPTLON: '-095.0818993',
        ELSDLEA: '',
        UNSDLEA: '00231',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8545, 43.8194],
            [-94.7945, 43.8198],
            [-94.7322, 43.8482],
            [-94.6237, 43.8273],
            [-94.542, 43.7539],
            [-94.548, 43.7173],
            [-94.5611, 43.5947],
            [-94.7344, 43.5806],
            [-94.7445, 43.501],
            [-94.8546, 43.5009],
            [-94.8545, 43.7905],
            [-94.8833, 43.7906],
            [-94.8545, 43.8194],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718960',
        NAME: 'Martin County West School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713707662,
        AWATER: 12588595,
        INTPTLAT: '+43.6844103',
        INTPTLON: '-094.7132710',
        ELSDLEA: '',
        UNSDLEA: '18960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4534, 43.6459],
            [-96.4534, 43.6824],
            [-96.3419, 43.7112],
            [-96.3324, 43.6526],
            [-96.2731, 43.5439],
            [-96.1927, 43.5003],
            [-96.2737, 43.5003],
            [-96.4533, 43.5004],
            [-96.4534, 43.6459],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2714040',
        NAME: 'Hills-Beaver Creek School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301856205,
        AWATER: 59869,
        INTPTLAT: '+43.5800159',
        INTPTLON: '-096.3621938',
        ELSDLEA: '',
        UNSDLEA: '14040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0123, 44.9061],
            [-93.849, 44.8772],
            [-93.7056, 44.9278],
            [-93.7064, 44.9205],
            [-93.7065, 44.9134],
            [-93.6831, 44.8909],
            [-93.7394, 44.8044],
            [-93.7352, 44.7174],
            [-93.7788, 44.7062],
            [-93.7678, 44.7894],
            [-93.9973, 44.8543],
            [-94.01, 44.8623],
            [-94.0123, 44.9061],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741460',
        NAME: 'Waconia Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232341878,
        AWATER: 28309572,
        INTPTLAT: '+44.8486350',
        INTPTLON: '-093.7997752',
        ELSDLEA: '',
        UNSDLEA: '41460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9411, 45.0331],
            [-93.9178, 45.0146],
            [-93.7369, 44.9776],
            [-93.7056, 44.9278],
            [-93.849, 44.8772],
            [-94.0123, 44.9061],
            [-94.0125, 44.9353],
            [-93.9411, 45.0331],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2741910',
        NAME: 'Watertown-Mayer Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248293431,
        AWATER: 8299232,
        INTPTLAT: '+44.9438211',
        INTPTLON: '-093.8829604',
        ELSDLEA: '',
        UNSDLEA: '41910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.01, 44.8623],
              [-93.9973, 44.8543],
              [-94.01, 44.8551],
              [-94.01, 44.8623],
            ],
          ],
          [
            [
              [-94.4064, 44.7758],
              [-94.2953, 44.8413],
              [-94.2739, 44.8848],
              [-94.2596, 44.9505],
              [-94.2561, 44.9795],
              [-94.1343, 44.9788],
              [-94.1291, 44.9498],
              [-94.1141, 44.9498],
              [-94.1314, 44.8444],
              [-94.01, 44.8333],
              [-94.0105, 44.7175],
              [-94.2751, 44.6304],
              [-94.4169, 44.6738],
              [-94.4064, 44.7758],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700128',
        NAME: 'Glencoe-Silver Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696732926,
        AWATER: 19339866,
        INTPTLAT: '+44.7772246',
        INTPTLON: '-094.1985627',
        ELSDLEA: '',
        UNSDLEA: '00128',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.01, 44.8333],
            [-94.01, 44.8551],
            [-93.9973, 44.8543],
            [-93.7678, 44.7894],
            [-93.7788, 44.7062],
            [-93.9024, 44.673],
            [-94.0105, 44.7175],
            [-94.01, 44.8333],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723910',
        NAME: 'Central Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280279388,
        AWATER: 7523164,
        INTPTLAT: '+44.7627448',
        INTPTLON: '-093.9034995',
        ELSDLEA: '',
        UNSDLEA: '23910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6831, 44.8909],
            [-93.6624, 44.8913],
            [-93.5208, 44.8661],
            [-93.5204, 44.8043],
            [-93.6224, 44.7472],
            [-93.6789, 44.6869],
            [-93.7352, 44.7174],
            [-93.7394, 44.8044],
            [-93.6831, 44.8909],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2708190',
        NAME: 'Eastern Carver County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203976519,
        AWATER: 9912077,
        INTPTLAT: '+44.8002389',
        INTPTLON: '-093.6377622',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0439, 44.91],
            [-94.9366, 44.9651],
            [-94.8034, 44.8922],
            [-94.8129, 44.6299],
            [-94.9255, 44.6441],
            [-95.0647, 44.6505],
            [-95.0439, 44.91],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2705660',
        NAME: 'Bird Island-Olivia-Lake Lillian Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 620114181,
        AWATER: 3754850,
        INTPTLAT: '+44.7806015',
        INTPTLON: '-094.9254407',
        ELSDLEA: '',
        UNSDLEA: '05660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.125, 44.964],
            [-95.1869, 44.9059],
            [-95.1965, 44.9059],
            [-95.2485, 44.8999],
            [-95.2484, 44.9783],
            [-95.125, 44.964],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2716830',
        NAME: 'Prinsburg Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71863010,
        AWATER: 215343,
        INTPTLAT: '+44.9440375',
        INTPTLON: '-095.1978620',
        ELSDLEA: '',
        UNSDLEA: '16830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8494, 44.5724],
            [-95.8495, 44.5761],
            [-95.696, 44.5011],
            [-95.5946, 44.5006],
            [-95.6141, 44.4285],
            [-95.6143, 44.3849],
            [-95.7052, 44.3482],
            [-95.8163, 44.3049],
            [-95.8268, 44.3327],
            [-95.9072, 44.487],
            [-95.8494, 44.5724],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718940',
        NAME: 'Marshall Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421430158,
        AWATER: 1083543,
        INTPTLAT: '+44.4366217',
        INTPTLON: '-095.7463607',
        ELSDLEA: '',
        UNSDLEA: '18940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9072, 44.487],
            [-95.8268, 44.3327],
            [-96.0128, 44.3779],
            [-96.0393, 44.4151],
            [-95.9072, 44.487],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2718390',
        NAME: 'Lynd Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186657825,
        AWATER: 816942,
        INTPTLAT: '+44.4033052',
        INTPTLON: '-095.9276237',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2452, 44.183],
            [-96.2267, 44.3502],
            [-96.2057, 44.379],
            [-96.0794, 44.4153],
            [-96.0393, 44.4151],
            [-96.0128, 44.3779],
            [-95.8268, 44.3327],
            [-95.8163, 44.3049],
            [-96.0179, 44.2398],
            [-95.9484, 44.1169],
            [-96.0643, 44.0662],
            [-96.2047, 44.0992],
            [-96.2452, 44.183],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700294',
        NAME: 'RTR Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 762327381,
        AWATER: 10303189,
        INTPTLAT: '+44.2515350',
        INTPTLON: '-096.0693417',
        ELSDLEA: '',
        UNSDLEA: '00294',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4534, 43.9135],
            [-96.4534, 43.9203],
            [-96.4532, 43.9636],
            [-96.4532, 43.9927],
            [-96.4533, 44.007],
            [-96.4532, 44.0144],
            [-96.4528, 44.1693],
            [-96.4528, 44.1968],
            [-96.2854, 44.1908],
            [-96.2452, 44.183],
            [-96.2047, 44.0992],
            [-96.0643, 44.0662],
            [-96.024, 43.9789],
            [-96.1554, 43.9937],
            [-96.2045, 43.9358],
            [-96.2414, 43.8486],
            [-96.3419, 43.7112],
            [-96.4534, 43.6824],
            [-96.4531, 43.8051],
            [-96.4533, 43.8912],
            [-96.4534, 43.9135],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2700108',
        NAME: 'Pipestone Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1079715668,
        AWATER: 1486893,
        INTPTLAT: '+43.9778346',
        INTPTLON: '-096.3110668',
        ELSDLEA: '',
        UNSDLEA: '00108',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3708, 44.9205],
            [-93.3291, 44.9306],
            [-93.3188, 44.8908],
            [-93.3289, 44.8619],
            [-93.3736, 44.8604],
            [-93.3991, 44.8843],
            [-93.3708, 44.9205],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711250',
        NAME: 'Edina Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32922219,
        AWATER: 1139303,
        INTPTLAT: '+44.8910772',
        INTPTLON: '-093.3578231',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.4409, 44.2164],
              [-92.4408, 44.2236],
              [-92.4211, 44.2232],
              [-92.4207, 44.2124],
              [-92.4208, 44.2082],
              [-92.4409, 44.2164],
            ],
          ],
          [
            [
              [-92.451, 44.2202],
              [-92.4454, 44.2164],
              [-92.451, 44.2164],
              [-92.451, 44.2202],
            ],
          ],
          [
            [
              [-92.7317, 44.384],
              [-92.4708, 44.3392],
              [-92.451, 44.238],
              [-92.4408, 44.2236],
              [-92.451, 44.2202],
              [-92.451, 44.2127],
              [-92.4799, 44.1946],
              [-92.5171, 44.1875],
              [-92.5334, 44.1912],
              [-92.5433, 44.1948],
              [-92.5906, 44.2527],
              [-92.7459, 44.2395],
              [-92.7317, 44.384],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2745735',
        NAME: 'Zumbrota-Mazeppa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356299209,
        AWATER: 1758535,
        INTPTLAT: '+44.2896310',
        INTPTLON: '-092.5862494',
        ELSDLEA: '',
        UNSDLEA: '45735',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.5334, 44.1912],
              [-92.5171, 44.1875],
              [-92.5184, 44.173],
              [-92.5336, 44.1803],
              [-92.5334, 44.1912],
            ],
          ],
          [
            [
              [-92.7989, 44.1965],
              [-92.7459, 44.2395],
              [-92.5906, 44.2527],
              [-92.5433, 44.1948],
              [-92.5336, 44.1803],
              [-92.5777, 44.1588],
              [-92.5287, 44.1299],
              [-92.5993, 44.1144],
              [-92.6783, 44.1083],
              [-92.7623, 44.1533],
              [-92.7989, 44.1965],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728950',
        NAME: 'Pine Island Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229904532,
        AWATER: 338124,
        INTPTLAT: '+44.1855636',
        INTPTLON: '-092.6360995',
        ELSDLEA: '',
        UNSDLEA: '28950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2808, 45.0717],
            [-93.2688, 45.1115],
            [-93.2277, 45.0933],
            [-93.2274, 45.0721],
            [-93.2841, 45.0649],
            [-93.2808, 45.0717],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712420',
        NAME: 'Fridley Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12135961,
        AWATER: 1125869,
        INTPTLAT: '+45.0846155',
        INTPTLON: '-093.2592376',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3285, 44.9666],
            [-93.3184, 44.9916],
            [-93.3142, 45.0512],
            [-93.2822, 45.0512],
            [-93.2269, 45.0357],
            [-93.2076, 45.0062],
            [-93.2076, 44.9881],
            [-93.1938, 44.9053],
            [-93.2529, 44.8907],
            [-93.3188, 44.8908],
            [-93.3291, 44.9306],
            [-93.3285, 44.9666],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2721240',
        NAME: 'Minneapolis Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139948160,
        AWATER: 9007010,
        INTPTLAT: '+44.9633242',
        INTPTLON: '-093.2683198',
        ELSDLEA: '',
        UNSDLEA: '21240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5954, 45.0511],
            [-93.5427, 44.9348],
            [-93.5923, 44.9289],
            [-93.6483, 44.9894],
            [-93.7373, 44.9924],
            [-93.5954, 45.0511],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2725080',
        NAME: 'Orono Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104070275,
        AWATER: 23888115,
        INTPTLAT: '+44.9973086',
        INTPTLON: '-093.6101040',
        ELSDLEA: '',
        UNSDLEA: '25080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4616, 45.0465],
            [-93.3141, 45.0656],
            [-93.3142, 45.0512],
            [-93.3184, 44.9916],
            [-93.4108, 44.9948],
            [-93.4616, 45.0465],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2731780',
        NAME: 'Robbinsdale Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72612250,
        AWATER: 6159688,
        INTPTLAT: '+45.0304737',
        INTPTLON: '-093.3784050',
        ELSDLEA: '',
        UNSDLEA: '31780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6541, 45.4722],
            [-93.5106, 45.4719],
            [-93.5098, 45.3855],
            [-93.449, 45.3231],
            [-93.5017, 45.2706],
            [-93.5213, 45.167],
            [-93.5269, 45.1636],
            [-93.6387, 45.1744],
            [-93.5709, 45.2597],
            [-93.684, 45.2809],
            [-93.6594, 45.2959],
            [-93.6541, 45.4722],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711370',
        NAME: 'Elk River Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422827734,
        AWATER: 16472317,
        INTPTLAT: '+45.3272507',
        INTPTLON: '-093.5722122',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5213, 46.195],
            [-95.4606, 46.2282],
            [-95.3017, 46.2383],
            [-95.1463, 46.1941],
            [-95.1461, 46.0629],
            [-95.2122, 46.0986],
            [-95.3217, 46.0557],
            [-95.4794, 46.0719],
            [-95.5724, 46.1183],
            [-95.5213, 46.195],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2728110',
        NAME: 'Parkers Prairie Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492918528,
        AWATER: 22140010,
        INTPTLAT: '+46.1399273',
        INTPTLON: '-095.3563313',
        ELSDLEA: '',
        UNSDLEA: '28110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7116, 44.4703],
            [-92.7017, 44.4703],
            [-92.6489, 44.4927],
            [-92.4468, 44.4479],
            [-92.4708, 44.3392],
            [-92.7317, 44.384],
            [-92.7727, 44.3921],
            [-92.7116, 44.4703],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2712900',
        NAME: 'Goodhue Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313375014,
        AWATER: 168864,
        INTPTLAT: '+44.4187062',
        INTPTLON: '-092.5910394',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4708, 44.3392],
            [-92.4468, 44.4479],
            [-92.36, 44.4831],
            [-92.4111, 44.5601],
            [-92.3162, 44.541],
            [-92.2211, 44.4404],
            [-92.1381, 44.4244],
            [-92.1896, 44.3793],
            [-92.2199, 44.2947],
            [-92.3608, 44.252],
            [-92.4389, 44.238],
            [-92.451, 44.238],
            [-92.4708, 44.3392],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2717520',
        NAME: 'Lake City Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459167086,
        AWATER: 46167272,
        INTPTLAT: '+44.3928129',
        INTPTLON: '-092.3338849',
        ELSDLEA: '',
        UNSDLEA: '17520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4026, 44.4282],
            [-93.1809, 44.3704],
            [-93.0505, 44.3813],
            [-93.102, 44.204],
            [-93.2274, 44.2291],
            [-93.3532, 44.1962],
            [-93.4032, 44.2978],
            [-93.4642, 44.3033],
            [-93.4026, 44.4282],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2711760',
        NAME: 'Faribault Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 545364747,
        AWATER: 23262373,
        INTPTLAT: '+44.3075745',
        INTPTLON: '-093.2538880',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4144, 44.4494],
            [-93.3926, 44.4572],
            [-93.3469, 44.479],
            [-93.3626, 44.4858],
            [-93.3498, 44.5441],
            [-93.2178, 44.5799],
            [-93.1164, 44.5581],
            [-93.0102, 44.4573],
            [-93.0104, 44.4286],
            [-93.0505, 44.3813],
            [-93.1809, 44.3704],
            [-93.4026, 44.4282],
            [-93.4144, 44.4494],
          ],
        ],
      },
      properties: {
        STATEFP: '27',
        SCSDLEA: '',
        GEOID: '2723880',
        NAME: 'Northfield Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 451551468,
        AWATER: 12370620,
        INTPTLAT: '+44.4658288',
        INTPTLON: '-093.2065442',
        ELSDLEA: '',
        UNSDLEA: '23880',
      },
    },
  ],
};

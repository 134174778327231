import React from 'react';
import { Container } from 'react-bootstrap';
import TopicsTab from './components/topics-tab';
import './style.css';

const SurveyTopicsLanding = () => (
  <Container className="summary-container">
    <TopicsTab />
  </Container>
);

export default SurveyTopicsLanding;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import types from '../../types';
import './style.css';

const SurveyInfoModal = ({ handleRedirectSurvey }) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.surveySummary.showInfoModal);
  const surveyDetails = useSelector((state) => state.surveySummary.selectedSurveyInfo);
  const surveyTitle = useSelector((state) => state.surveySummary.selectedSurveyTitle);
  const surveyId = useSelector((state) => state.surveySummary.selectedSurveyId);

  const handleCloseModal = () => {
    dispatch({ type: types.reducerTypes.SURVEY_SUMMARY_INFO_MODAL_HIDE });
  };

  return (
    <Modal className="survey-info-modal" show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Button className="text-dark bg-white border-0" onClick={handleCloseModal}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
        <Modal.Title>{surveyTitle}</Modal.Title>
        <Button variant="dark" className="rounded-0" onClick={() => handleRedirectSurvey(surveyId)}>
          View Survey
        </Button>
      </Modal.Header>
      <Modal.Body className="info-sections-position">
        <h4>Section Names</h4>
        <div className="my-2">
          {surveyDetails.sections
            ?.split(', ')
            .sort((a, b) => {
              if (a === b) return 0;
              return a < b ? -1 : 1;
            })
            ?.join(', ')}
        </div>
        <h4>State Oversamples</h4>
        <div className="my-2">{surveyDetails.oversampledStates}</div>
        <h4>Content Description</h4>
        <div className="my-2">{surveyDetails.description || '-'}</div>
        <h4>Number Of Respondents</h4>
        <div className="my-2">{surveyDetails.numberOfRespondents}</div>
        <h4>Administered</h4>
        <div className="my-2">{surveyDetails.dateAdministrated || '-'}</div>
        <h4>Linked Surveys</h4>
        <div className="my-2">{surveyDetails.linkedSurveys}</div>
        {surveyDetails.organization?.name && (
          <>
            <h4>Organization</h4>
            <div className="my-2">{surveyDetails.organization?.name}</div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

SurveyInfoModal.propTypes = {
  handleRedirectSurvey: PropTypes.func.isRequired,
};

export default SurveyInfoModal;

import types from '../types';

const initialState = {
  jwt: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.VALID_TOKEN_CLEAR: {
      return {
        ...state,
        jwt: null,
        firstName: null,
        lastName: null,
      };
    }
    case types.reducerTypes.VALID_TOKEN_SUCCESS: {
      return {
        ...state,
        jwt: action.jwt,
        firstName: action.firstName,
        lastName: action.lastName,
      };
    }
    case types.reducerTypes.VALID_TOKEN_ERROR: {
      return {
        ...state,
        jwt: 'error',
      };
    }
    default:
  }
  return state;
};

export default sessionReducer;

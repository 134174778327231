/* eslint-disable no-use-before-define */
import React from 'react';
import Input from '../../Inputs/Input/Input';
import InputCheckboxContainer from '../../Inputs/InputCheckboxContainer';
import FileUpload from '../../FileUpload';
import FormGroup from '../../FormGroup';
import CustomDropdown from '../../CustomDropdown';

export const renderComponents = (component) => {
  switch (component.component) {
    case 'input':
      if (component.type === 'area') {
        return (
          <Input
            label={component.label}
            required={component.required}
            maxChar={component.maxChar}
            name={component.name}
            key={component.name}
            type={component.type}
            isTextArea
          />
        );
      }
      return (
        <Input
          label={component.label}
          required={component.required}
          maxChar={component.maxChar}
          name={component.name}
          key={component.name}
          type={component.type}
        />
      );
    case 'checkbox': {
      const { checkboxes, label } = component;
      return <InputCheckboxContainer label={label} checkboxes={checkboxes} />;
    }
    case 'upload':
      return (
        <FileUpload
          type={component.type}
          label={component.label}
          vendorDataType={component.vendorDataType}
          required={component.required}
        />
      );
    case 'formGroup':
      return <FormGroup>{renderGroup(component.group)}</FormGroup>;
    case 'customDropdown': {
      return (
        <CustomDropdown
          type={component.type}
          label={component.label}
          emptyLabel={component.emptyLabel}
          options={[]}
          asyncAction={component.asyncAction}
          required={component.required}
        />
      );
    }
    default:
      return null;
  }
};

export const renderGroup = (group = []) => {
  if (group.length <= 0) return null;
  const reactElements = [];
  group.forEach((component) => {
    reactElements.push(renderComponents(component));
  });
  return reactElements;
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { hideSnackbar } from '../../actions/survey.action';
import { hideVisualizationSnackbar } from '../../actions/visualization.action';
import { snackBarParser } from './snackBar.parser';

const CardSnackbar = ({ cardId, snackBar, isVisualization }) => {
  const dispatch = useDispatch();
  const closeSnackbarHandler = () => {
    if (isVisualization) {
      dispatch(hideVisualizationSnackbar(cardId, snackBar));
    } else {
      dispatch(hideSnackbar(cardId, snackBar));
    }
  };
  return (
    <div className="Card-is-new-label">
      { snackBarParser(snackBar) }
      <button
        className="Card-close-icon"
        onClick={closeSnackbarHandler}
        type="button"
      >
        <img
          src="/x-icon.svg"
          alt="Close Icon"
        />
      </button>
    </div>
  );
};

CardSnackbar.propTypes = {
  snackBar: PropTypes.string,
  cardId: PropTypes.number.isRequired,
  isVisualization: PropTypes.bool,
  diff: PropTypes.number,
};

CardSnackbar.defaultProps = {
  snackBar: null,
  diff: null,
  isVisualization: false,
};


export default CardSnackbar;

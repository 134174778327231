export const CA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3995, 36.9607],
            [-121.2154, 36.9612],
            [-121.2197, 36.9115],
            [-121.1415, 36.8367],
            [-120.9311, 36.75],
            [-120.9187, 36.7404],
            [-120.7173, 36.5794],
            [-120.5971, 36.4856],
            [-120.597, 36.3575],
            [-120.7448, 36.3842],
            [-120.82, 36.4856],
            [-120.9109, 36.4867],
            [-120.9936, 36.5317],
            [-120.9748, 36.4503],
            [-121.117, 36.3852],
            [-121.1435, 36.4062],
            [-121.2393, 36.5048],
            [-121.2932, 36.5061],
            [-121.3252, 36.577],
            [-121.3314, 36.6204],
            [-121.3633, 36.6547],
            [-121.4681, 36.6855],
            [-121.4762, 36.7543],
            [-121.483, 36.765],
            [-121.4562, 36.7911],
            [-121.4292, 36.8817],
            [-121.5117, 36.9492],
            [-121.3995, 36.9607],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '34140',
        GEOID: '634140',
        NAME: 'San Benito High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2659327023,
        AWATER: 1725481,
        INTPTLAT: '+36.6535732',
        INTPTLON: '-121.0991880',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8443, 38.4923],
            [-122.8208, 38.5033],
            [-122.7243, 38.5833],
            [-122.6872, 38.5834],
            [-122.687, 38.5762],
            [-122.5737, 38.5387],
            [-122.5296, 38.4696],
            [-122.4944, 38.4227],
            [-122.592, 38.3986],
            [-122.6018, 38.3575],
            [-122.6377, 38.351],
            [-122.667, 38.3505],
            [-122.7597, 38.3604],
            [-122.7693, 38.3861],
            [-122.7736, 38.3982],
            [-122.788, 38.4233],
            [-122.779, 38.4455],
            [-122.8404, 38.4673],
            [-122.8443, 38.4923],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '35830',
        GEOID: '635830',
        NAME: 'Santa Rosa High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476308297,
        AWATER: 913262,
        INTPTLAT: '+38.4570453',
        INTPTLON: '-122.6692775',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8682, 35.528],
            [-119.8686, 35.5718],
            [-119.6544, 35.5726],
            [-119.626, 35.5292],
            [-119.6603, 35.383],
            [-119.5538, 35.3249],
            [-119.5538, 35.2676],
            [-119.4836, 35.2675],
            [-119.4659, 35.2676],
            [-119.4474, 35.362],
            [-119.3412, 35.3621],
            [-119.2966, 35.2964],
            [-119.3058, 35.2672],
            [-119.3413, 35.2536],
            [-119.2893, 35.1804],
            [-119.2894, 35.0939],
            [-119.4727, 35.0769],
            [-119.4988, 35.0913],
            [-119.5536, 35.18],
            [-119.6671, 35.1748],
            [-119.6667, 35.2616],
            [-119.8094, 35.2636],
            [-119.8093, 35.3509],
            [-119.8802, 35.3512],
            [-119.9442, 35.4393],
            [-119.9408, 35.5277],
            [-119.8682, 35.528],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '38730',
        GEOID: '638730',
        NAME: 'Taft Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1441819227,
        AWATER: 2410560,
        INTPTLAT: '+35.3198328',
        INTPTLON: '-119.6178338',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1941, 35.7892],
            [-119.7548, 35.79],
            [-119.6523, 35.7904],
            [-119.6334, 35.7027],
            [-119.4906, 35.7028],
            [-119.4374, 35.6743],
            [-119.3116, 35.6742],
            [-119.241, 35.6309],
            [-119.2421, 35.5584],
            [-119.332, 35.5362],
            [-119.3326, 35.4562],
            [-119.4036, 35.4567],
            [-119.4393, 35.5006],
            [-119.5464, 35.5141],
            [-119.5458, 35.5718],
            [-119.6355, 35.5725],
            [-119.626, 35.5292],
            [-119.6544, 35.5726],
            [-119.8686, 35.5718],
            [-119.8682, 35.528],
            [-119.9408, 35.5277],
            [-119.9442, 35.4393],
            [-119.9974, 35.4395],
            [-120.0867, 35.5266],
            [-120.0859, 35.6145],
            [-120.1939, 35.6144],
            [-120.1941, 35.7892],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '41430',
        GEOID: '641430',
        NAME: 'Wasco Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1991513939,
        AWATER: 4717058,
        INTPTLAT: '+35.6380655',
        INTPTLON: '-119.7608514',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7716, 33.2558],
            [-116.667, 33.1516],
            [-116.6461, 33.1375],
            [-116.1845, 33.1327],
            [-116.1033, 33.0747],
            [-116.103, 32.9471],
            [-116.1031, 32.9041],
            [-116.6685, 32.9054],
            [-116.7191, 32.9059],
            [-116.7208, 33.1237],
            [-116.823, 33.1226],
            [-116.8766, 33.1828],
            [-116.7716, 33.2558],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '19110',
        GEOID: '619110',
        NAME: 'Julian Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1624941351,
        AWATER: 3926945,
        INTPTLAT: '+33.0470228',
        INTPTLON: '-116.4590731',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0187, 40.7647],
            [-120.9803, 40.8052],
            [-120.8435, 40.8061],
            [-120.813, 40.971],
            [-120.642, 40.9704],
            [-120.6419, 40.9398],
            [-120.3431, 40.9395],
            [-120.3421, 41.0124],
            [-119.9998, 41.0119],
            [-119.9987, 40.7795],
            [-119.997, 40.1588],
            [-120.1708, 40.1594],
            [-120.3017, 40.1148],
            [-120.4364, 40.1696],
            [-120.5634, 40.2636],
            [-120.5852, 40.2855],
            [-120.6118, 40.2856],
            [-120.7963, 40.3014],
            [-120.7964, 40.3013],
            [-120.8527, 40.4092],
            [-121.0246, 40.4101],
            [-121.0187, 40.7647],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '21060',
        GEOID: '621060',
        NAME: 'Lassen Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6353927053,
        AWATER: 381951460,
        INTPTLAT: '+40.6096275',
        INTPTLON: '-120.4598515',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.814, 37.3828],
            [-120.7314, 37.4057],
            [-120.7488, 37.4557],
            [-120.7483, 37.4926],
            [-120.5991, 37.5511],
            [-120.5215, 37.5815],
            [-120.5146, 37.5842],
            [-120.3877, 37.6334],
            [-120.2454, 37.3764],
            [-120.3242, 37.3765],
            [-120.3242, 37.3611],
            [-120.3561, 37.2738],
            [-120.4195, 37.1951],
            [-120.4008, 37.1178],
            [-120.5417, 37.0445],
            [-120.5775, 37.098],
            [-120.6008, 37.1715],
            [-120.7648, 37.1861],
            [-120.8149, 37.236],
            [-120.8185, 37.2489],
            [-120.7019, 37.2489],
            [-120.7017, 37.3026],
            [-120.706, 37.3028],
            [-120.814, 37.3828],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '24660',
        GEOID: '624660',
        NAME: 'Merced Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1803880394,
        AWATER: 9887384,
        INTPTLAT: '+37.3573198',
        INTPTLON: '-120.5256084',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.24, 37.6649],
            [-121.222, 37.6743],
            [-121.2061, 37.6949],
            [-121.0599, 37.7493],
            [-121.014, 37.7565],
            [-120.9818, 37.7563],
            [-120.9588, 37.7415],
            [-120.9043, 37.711],
            [-120.9033, 37.667],
            [-120.8302, 37.6822],
            [-120.8117, 37.6821],
            [-120.8017, 37.6188],
            [-120.9039, 37.625],
            [-120.948, 37.6201],
            [-120.9937, 37.5804],
            [-121.0859, 37.5805],
            [-121.0857, 37.5924],
            [-121.1759, 37.606],
            [-121.2305, 37.6411],
            [-121.2439, 37.6619],
            [-121.24, 37.6649],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '25150',
        GEOID: '625150',
        NAME: 'Modesto City High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422408868,
        AWATER: 8246078,
        INTPTLAT: '+37.6623610',
        INTPTLON: '-121.0342213',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3626, 33.8],
            [-117.1912, 33.8084],
            [-117.1389, 33.859],
            [-117.1211, 33.9031],
            [-117.0511, 33.8456],
            [-117.0509, 33.7875],
            [-117.0849, 33.7573],
            [-117.1365, 33.6996],
            [-117.1012, 33.6163],
            [-117.106, 33.6126],
            [-117.206, 33.6119],
            [-117.2668, 33.7282],
            [-117.4147, 33.7712],
            [-117.3626, 33.8],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '30210',
        GEOID: '630210',
        NAME: 'Perris Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470362912,
        AWATER: 1168231,
        INTPTLAT: '+33.7439510',
        INTPTLON: '-117.1888911',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0504, 33.85],
            [-118.0461, 33.8536],
            [-117.9807, 33.854],
            [-117.9762, 33.8542],
            [-117.8961, 33.8564],
            [-117.8637, 33.8355],
            [-117.9063, 33.7925],
            [-117.9589, 33.8031],
            [-117.9911, 33.8029],
            [-118.0194, 33.8075],
            [-118.041, 33.7956],
            [-118.0632, 33.8196],
            [-118.0504, 33.85],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '02630',
        GEOID: '602630',
        NAME: 'Anaheim Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104952769,
        AWATER: 301696,
        INTPTLAT: '+33.8273173',
        INTPTLON: '-117.9621537',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9258, 39.9682],
            [-122.5546, 39.9744],
            [-122.5162, 39.9596],
            [-122.46, 40.0228],
            [-122.4416, 40.0204],
            [-122.3706, 40.0198],
            [-122.2074, 40.0002],
            [-122.1637, 40.0074],
            [-122.0826, 39.9467],
            [-122.0449, 39.8838],
            [-122.0557, 39.864],
            [-122.0645, 39.8336],
            [-122.0465, 39.7976],
            [-122.1248, 39.7975],
            [-122.141, 39.7976],
            [-122.1597, 39.7975],
            [-122.1884, 39.8269],
            [-122.2511, 39.7993],
            [-122.4786, 39.7995],
            [-122.9377, 39.7982],
            [-122.9601, 39.8452],
            [-122.9258, 39.9682],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '09810',
        GEOID: '609810',
        NAME: 'Corning Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1570512173,
        AWATER: 13598799,
        INTPTLAT: '+39.8936816',
        INTPTLON: '-122.4874293',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2345, 40.7568],
            [-124.1958, 40.6509],
            [-124.0941, 40.6066],
            [-124.0842, 40.6066],
            [-124.0422, 40.6064],
            [-124.0067, 40.6063],
            [-123.8917, 40.5923],
            [-123.7783, 40.6436],
            [-123.6702, 40.6023],
            [-123.6632, 40.6935],
            [-123.5445, 40.6938],
            [-123.5444, 40.5654],
            [-123.6633, 40.5492],
            [-123.6635, 40.4822],
            [-123.5443, 40.4639],
            [-123.5445, 40.3412],
            [-123.6629, 40.3786],
            [-123.7968, 40.3786],
            [-123.9689, 40.3572],
            [-124.0627, 40.4196],
            [-124.1212, 40.4196],
            [-124.1961, 40.4196],
            [-124.1518, 40.5111],
            [-124.1959, 40.6134],
            [-124.3129, 40.6424],
            [-124.2345, 40.7568],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '14190',
        GEOID: '614190',
        NAME: 'Fortuna Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1457886410,
        AWATER: 18488776,
        INTPTLAT: '+40.4941727',
        INTPTLON: '-123.8376331',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1623, 37.3044],
            [-122.0611, 37.352],
            [-122.0617, 37.3608],
            [-122.0381, 37.4393],
            [-122.0227, 37.3598],
            [-121.9682, 37.3299],
            [-121.975, 37.3171],
            [-122.0059, 37.2855],
            [-122.1385, 37.2675],
            [-122.1523, 37.2861],
            [-122.1623, 37.3044],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '14430',
        GEOID: '614430',
        NAME: 'Fremont Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144982916,
        AWATER: 349209,
        INTPTLAT: '+37.3293586',
        INTPTLON: '-122.0614987',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8322, 37.9171],
            [-121.7324, 37.9832],
            [-121.7326, 38.029],
            [-121.6619, 38.0956],
            [-121.58, 38.0944],
            [-121.5729, 38.0742],
            [-121.5798, 38.0064],
            [-121.5656, 37.9182],
            [-121.5551, 37.8174],
            [-121.5569, 37.8172],
            [-121.6203, 37.8003],
            [-121.7496, 37.7695],
            [-121.765, 37.8366],
            [-121.8321, 37.8544],
            [-121.8322, 37.9171],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '21600',
        GEOID: '621600',
        NAME: 'Liberty Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514829897,
        AWATER: 49307614,
        INTPTLAT: '+37.9222276',
        INTPTLON: '-121.6683590',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2204, 37.948],
            [-122.1612, 37.9239],
            [-122.0985, 37.9477],
            [-122.0919, 37.917],
            [-122.0072, 37.8824],
            [-122.0616, 37.843],
            [-122.0631, 37.8371],
            [-122.0643, 37.7994],
            [-122.1328, 37.804],
            [-122.1779, 37.8163],
            [-122.1889, 37.8376],
            [-122.2469, 37.8854],
            [-122.2563, 37.897],
            [-122.2204, 37.948],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '01650',
        GEOID: '601650',
        NAME: 'Acalanes Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205137702,
        AWATER: 4413448,
        INTPTLAT: '+37.8772150',
        INTPTLON: '-122.1288576',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.975, 37.3171],
            [-121.9682, 37.3299],
            [-121.9564, 37.3299],
            [-121.9502, 37.3255],
            [-121.947, 37.3255],
            [-121.932, 37.3269],
            [-121.9256, 37.3198],
            [-121.92, 37.3167],
            [-121.9193, 37.3166],
            [-121.9203, 37.2846],
            [-121.9058, 37.2608],
            [-121.8942, 37.2217],
            [-121.9545, 37.2509],
            [-121.9569, 37.2605],
            [-121.9962, 37.2457],
            [-122.0026, 37.2789],
            [-122.0059, 37.2855],
            [-121.975, 37.3171],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '07230',
        GEOID: '607230',
        NAME: 'Campbell Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78382018,
        AWATER: 219026,
        INTPTLAT: '+37.2754238',
        INTPTLON: '-121.9462937',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3561, 37.2738],
            [-120.3242, 37.3611],
            [-120.3242, 37.3765],
            [-120.2454, 37.3764],
            [-120.1843, 37.2727],
            [-120.0521, 37.1831],
            [-120.0546, 37.1821],
            [-120.2563, 37.1562],
            [-120.4008, 37.1178],
            [-120.4195, 37.1951],
            [-120.3561, 37.2738],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '21270',
        GEOID: '621270',
        NAME: 'Le Grand Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465322765,
        AWATER: 0,
        INTPTLAT: '+37.2358769',
        INTPTLON: '-120.2614016',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6887, 39.5378],
            [-121.6345, 39.5752],
            [-121.595, 39.7141],
            [-121.524, 39.9292],
            [-121.4206, 39.9296],
            [-121.3605, 39.8407],
            [-121.2106, 39.7119],
            [-121.1367, 39.6282],
            [-121.1854, 39.5558],
            [-121.3151, 39.5446],
            [-121.3334, 39.4687],
            [-121.3729, 39.3646],
            [-121.4828, 39.3266],
            [-121.5764, 39.3108],
            [-121.6174, 39.4054],
            [-121.63, 39.4562],
            [-121.6887, 39.5378],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '29130',
        GEOID: '629130',
        NAME: 'Oroville Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1661911053,
        AWATER: 69733992,
        INTPTLAT: '+39.6041742',
        INTPTLON: '-121.4309121',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-121.9277, 37.386],
              [-121.9249, 37.3674],
              [-121.9297, 37.3718],
              [-121.933, 37.3749],
              [-121.9277, 37.386],
            ],
          ],
          [
            [
              [-121.9152, 37.3961],
              [-121.8924, 37.4034],
              [-121.7691, 37.4079],
              [-121.7018, 37.3755],
              [-121.5878, 37.3388],
              [-121.7206, 37.3082],
              [-121.7153, 37.2414],
              [-121.781, 37.2093],
              [-121.8498, 37.2746],
              [-121.8576, 37.3451],
              [-121.8691, 37.3664],
              [-121.8767, 37.3656],
              [-121.9275, 37.3861],
              [-121.9152, 37.3961],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '11820',
        GEOID: '611820',
        NAME: 'East Side Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354466683,
        AWATER: 516255,
        INTPTLAT: '+37.3270983',
        INTPTLON: '-121.7770803',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1186, 38.7171],
            [-121.0075, 38.7637],
            [-120.9042, 38.8272],
            [-120.8319, 38.7696],
            [-120.7861, 38.839],
            [-120.6928, 38.867],
            [-120.6886, 38.9117],
            [-120.459, 38.8867],
            [-120.2687, 38.8825],
            [-120.15, 38.8784],
            [-120.1154, 38.7667],
            [-120.1466, 38.6864],
            [-120.1221, 38.6686],
            [-120.153, 38.6372],
            [-120.2326, 38.5935],
            [-120.2745, 38.5595],
            [-120.5652, 38.5081],
            [-120.6284, 38.5032],
            [-120.8124, 38.5621],
            [-120.8912, 38.5283],
            [-121.0275, 38.5083],
            [-121.0542, 38.57],
            [-121.0793, 38.6329],
            [-121.097, 38.6718],
            [-121.1186, 38.7171],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '12070',
        GEOID: '612070',
        NAME: 'El Dorado Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2881738036,
        AWATER: 28360959,
        INTPTLAT: '+38.7079090',
        INTPTLON: '-120.5909221',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1567, 33.0945],
            [-117.1147, 33.1268],
            [-117.1593, 33.2268],
            [-117.1423, 33.2245],
            [-116.9986, 33.1534],
            [-116.9183, 33.0805],
            [-116.9966, 33.0521],
            [-117.0316, 33.0668],
            [-117.1483, 33.0363],
            [-117.1527, 33.04],
            [-117.1567, 33.0945],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '12910',
        GEOID: '612910',
        NAME: 'Escondido Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272453966,
        AWATER: 3141331,
        INTPTLAT: '+33.1232648',
        INTPTLON: '-117.0594888',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5097, 33.505],
            [-117.3709, 33.4905],
            [-117.1932, 33.4322],
            [-117.1453, 33.4326],
            [-117.0946, 33.4305],
            [-117.0943, 33.3882],
            [-117.0523, 33.3151],
            [-117.1407, 33.2938],
            [-117.1423, 33.2245],
            [-117.1593, 33.2268],
            [-117.1909, 33.2275],
            [-117.2751, 33.2575],
            [-117.2969, 33.2792],
            [-117.3492, 33.3439],
            [-117.4164, 33.2596],
            [-117.5121, 33.2562],
            [-117.6111, 33.334],
            [-117.5785, 33.4539],
            [-117.5097, 33.505],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '13530',
        GEOID: '613530',
        NAME: 'Fallbrook Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 898157281,
        AWATER: 101198620,
        INTPTLAT: '+33.3628878',
        INTPTLON: '-117.3414128',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0496, 32.7554],
            [-117.0064, 32.8137],
            [-117.0318, 32.8909],
            [-116.9637, 32.9031],
            [-116.9621, 32.9628],
            [-116.7887, 32.9637],
            [-116.7902, 32.9066],
            [-116.7191, 32.9059],
            [-116.6685, 32.9054],
            [-116.667, 32.8157],
            [-116.6229, 32.7317],
            [-116.6235, 32.6545],
            [-116.6894, 32.5712],
            [-116.8222, 32.5603],
            [-116.8228, 32.6595],
            [-116.9147, 32.6846],
            [-116.9631, 32.7049],
            [-117.017, 32.6913],
            [-117.023, 32.719],
            [-117.0496, 32.7554],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '16230',
        GEOID: '616230',
        NAME: 'Grossmont Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1206775554,
        AWATER: 22322469,
        INTPTLAT: '+32.7738000',
        INTPTLON: '-116.8175945',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5705, 37.6653],
            [-122.5752, 37.7067],
            [-122.4408, 37.7083],
            [-122.43, 37.7083],
            [-122.2818, 37.7082],
            [-122.2809, 37.7072],
            [-122.2656, 37.6889],
            [-122.252, 37.6724],
            [-122.279, 37.672],
            [-122.4312, 37.679],
            [-122.4739, 37.6574],
            [-122.4641, 37.6388],
            [-122.4543, 37.6321],
            [-122.4463, 37.6222],
            [-122.4442, 37.6192],
            [-122.4654, 37.5701],
            [-122.5876, 37.5736],
            [-122.5705, 37.6653],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '18930',
        GEOID: '618930',
        NAME: 'Jefferson Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83585084,
        AWATER: 142459568,
        INTPTLAT: '+37.6901929',
        INTPTLON: '-122.4962701',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6298, 38.0717],
            [-122.5193, 38.0399],
            [-122.3828, 38.0398],
            [-122.4013, 38.0179],
            [-122.435, 37.9368],
            [-122.5135, 37.9431],
            [-122.5332, 37.9602],
            [-122.5509, 37.9723],
            [-122.5576, 37.9933],
            [-122.6169, 38.0205],
            [-122.6339, 38.0256],
            [-122.6298, 38.0717],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '35110',
        GEOID: '635110',
        NAME: 'San Rafael City High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93228854,
        AWATER: 60996689,
        INTPTLAT: '+38.0055686',
        INTPTLON: '-122.5028113',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.084, 37.9838],
            [-122.7751, 37.9838],
            [-122.7253, 38.0015],
            [-122.6977, 38.0335],
            [-122.7751, 38.1184],
            [-122.7662, 38.1262],
            [-122.646, 38.1055],
            [-122.6298, 38.0717],
            [-122.6339, 38.0256],
            [-122.6169, 38.0205],
            [-122.5576, 37.9933],
            [-122.5509, 37.9723],
            [-122.5332, 37.9602],
            [-122.5135, 37.9431],
            [-122.435, 37.9368],
            [-122.4323, 37.9298],
            [-122.4302, 37.9184],
            [-122.4336, 37.8469],
            [-122.6123, 37.8152],
            [-122.6324, 37.8277],
            [-122.7525, 37.8577],
            [-122.8893, 37.9741],
            [-123.0225, 37.9421],
            [-123.084, 37.9838],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '38790',
        GEOID: '638790',
        NAME: 'Tamalpais Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442349553,
        AWATER: 319574549,
        INTPTLAT: '+37.9455929',
        INTPTLON: '-122.6862035',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5841, 39.0096],
            [-121.5078, 39.1141],
            [-121.3956, 39.1488],
            [-121.3845, 39.2204],
            [-121.2795, 39.2305],
            [-121.2797, 39.1624],
            [-121.2797, 39.0896],
            [-121.2796, 39.0748],
            [-121.2795, 39.0346],
            [-121.4148, 38.9965],
            [-121.5078, 38.9829],
            [-121.5738, 38.9302],
            [-121.5755, 38.9184],
            [-121.5841, 39.0096],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '42350',
        GEOID: '642350',
        NAME: 'Wheatland Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400896712,
        AWATER: 3658509,
        INTPTLAT: '+39.0852587',
        INTPTLON: '-121.4073702',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5417, 37.0445],
            [-120.4008, 37.1178],
            [-120.2563, 37.1562],
            [-120.0546, 37.1821],
            [-120.0546, 37.0836],
            [-120.2568, 37.0401],
            [-120.2576, 36.9709],
            [-120.4586, 36.9699],
            [-120.5006, 36.9952],
            [-120.5417, 37.0445],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '08550',
        GEOID: '608550',
        NAME: 'Chowchilla Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 584227872,
        AWATER: 2275784,
        INTPTLAT: '+37.0721241',
        INTPTLON: '-120.2969305',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4843, 38.2415],
            [-121.4289, 38.2542],
            [-121.3923, 38.2979],
            [-121.2606, 38.3798],
            [-121.0272, 38.413],
            [-121.0271, 38.3003],
            [-121.0064, 38.2505],
            [-121.1523, 38.2054],
            [-121.2637, 38.2074],
            [-121.3931, 38.2186],
            [-121.4961, 38.1264],
            [-121.5719, 38.1315],
            [-121.4843, 38.2415],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '14820',
        GEOID: '614820',
        NAME: 'Galt Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669657055,
        AWATER: 7125442,
        INTPTLAT: '+38.2857504',
        INTPTLON: '-121.2399046',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0654, 32.6624],
            [-117.017, 32.6913],
            [-116.9631, 32.7049],
            [-116.9147, 32.6846],
            [-116.8228, 32.6595],
            [-116.8222, 32.5603],
            [-116.8928, 32.5539],
            [-117.0667, 32.5395],
            [-117.1247, 32.5342],
            [-117.1343, 32.6054],
            [-117.1204, 32.6088],
            [-117.1085, 32.6039],
            [-117.0654, 32.6624],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '38640',
        GEOID: '638640',
        NAME: 'Sweetwater Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388171653,
        AWATER: 23090298,
        INTPTLAT: '+32.6023081',
        INTPTLON: '-116.9846947',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0454, 39.1806],
            [-123.0197, 39.3218],
            [-123.0751, 39.4077],
            [-123.0444, 39.5112],
            [-122.8903, 39.529],
            [-122.8854, 39.5801],
            [-122.7356, 39.5807],
            [-122.7518, 39.3478],
            [-122.7571, 39.2843],
            [-122.5734, 39.2043],
            [-122.5153, 39.2092],
            [-122.4882, 39.0946],
            [-122.4902, 39.0737],
            [-122.5901, 39.1417],
            [-122.7662, 39.15],
            [-122.7213, 39.0992],
            [-122.7443, 39.0267],
            [-122.854, 39.0529],
            [-122.9115, 39.1422],
            [-123.078, 39.1518],
            [-123.0454, 39.1806],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '40500',
        GEOID: '640500',
        NAME: 'Upper Lake Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1463481853,
        AWATER: 74175570,
        INTPTLAT: '+39.3010039',
        INTPTLON: '-122.8366093',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6523, 35.7904],
            [-119.5381, 35.7896],
            [-119.4278, 35.7901],
            [-119.3922, 35.8771],
            [-119.3923, 35.9057],
            [-119.3576, 35.9357],
            [-119.2148, 35.9355],
            [-119.1617, 35.9355],
            [-119.1616, 35.9209],
            [-119.1614, 35.8774],
            [-119.0712, 35.7908],
            [-118.9382, 35.7905],
            [-118.9402, 35.6168],
            [-118.8884, 35.6164],
            [-118.9044, 35.4994],
            [-118.9592, 35.5296],
            [-119.0102, 35.6027],
            [-119.0094, 35.6896],
            [-119.1517, 35.6746],
            [-119.2763, 35.7252],
            [-119.3116, 35.6742],
            [-119.4374, 35.6743],
            [-119.4906, 35.7028],
            [-119.6334, 35.7027],
            [-119.6523, 35.7904],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '10860',
        GEOID: '610860',
        NAME: 'Delano Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1197023972,
        AWATER: 5348009,
        INTPTLAT: '+35.7630952',
        INTPTLON: '-119.2469519',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5763, 36.6074],
            [-121.4673, 36.6455],
            [-121.3636, 36.6456],
            [-121.4164, 36.5739],
            [-121.5312, 36.533],
            [-121.5763, 36.6074],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06053',
        GEOID: '606053',
        NAME: 'Gonzales Unified (9-12) School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 171474797,
        AWATER: 264130,
        INTPTLAT: '+36.5897368',
        INTPTLON: '-121.4746436',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2797, 39.1624],
            [-121.2795, 39.2305],
            [-121.2096, 39.2963],
            [-121.1368, 39.3681],
            [-121.0221, 39.3916],
            [-120.7557, 39.4527],
            [-120.659, 39.5238],
            [-120.5772, 39.5231],
            [-120.455, 39.4461],
            [-120.4564, 39.3497],
            [-120.6076, 39.3448],
            [-120.6075, 39.3155],
            [-120.7279, 39.2881],
            [-120.9238, 39.1588],
            [-120.9475, 39.1434],
            [-120.974, 39.1175],
            [-120.9981, 39.0707],
            [-121.0795, 39.0069],
            [-121.1878, 39.0125],
            [-121.2795, 39.0346],
            [-121.2796, 39.0748],
            [-121.2797, 39.0896],
            [-121.2797, 39.1624],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '26880',
        GEOID: '626880',
        NAME: 'Nevada Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1886943445,
        AWATER: 28171109,
        INTPTLAT: '+39.2776911',
        INTPTLON: '-120.9336868',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3759, 33.0752],
            [-117.2613, 33.0921],
            [-117.1959, 33.0877],
            [-117.1567, 33.0945],
            [-117.1527, 33.04],
            [-117.1483, 33.0363],
            [-117.173, 32.9702],
            [-117.1709, 32.9326],
            [-117.3344, 32.9396],
            [-117.3457, 32.9766],
            [-117.3525, 32.9987],
            [-117.3637, 33.0355],
            [-117.3759, 33.0752],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '34380',
        GEOID: '634380',
        NAME: 'San Dieguito Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209031317,
        AWATER: 108314193,
        INTPTLAT: '+33.0161063',
        INTPTLON: '-117.2545162',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5294, 36.2482],
            [-119.5017, 36.2478],
            [-119.4561, 36.2688],
            [-119.3539, 36.2763],
            [-119.2786, 36.2771],
            [-119.234, 36.2759],
            [-119.188, 36.2184],
            [-119.1879, 36.1962],
            [-119.215, 36.1607],
            [-119.206, 36.1165],
            [-119.2682, 36.0947],
            [-119.2146, 36.0658],
            [-119.2233, 36.0222],
            [-119.2146, 36.015],
            [-119.2148, 35.9355],
            [-119.3576, 35.9357],
            [-119.3923, 35.9057],
            [-119.4639, 35.9491],
            [-119.4109, 36.0075],
            [-119.4112, 36.0511],
            [-119.4467, 36.0654],
            [-119.5363, 36.0655],
            [-119.547, 36.2111],
            [-119.5294, 36.2482],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '39930',
        GEOID: '639930',
        NAME: 'Tulare Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 941878881,
        AWATER: 1718072,
        INTPTLAT: '+36.1055200',
        INTPTLON: '-119.3706428',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.7111, 34.0795],
            [-117.7046, 34.0944],
            [-117.6286, 34.0926],
            [-117.6285, 34.0994],
            [-117.6193, 34.1216],
            [-117.6301, 34.2199],
            [-117.6638, 34.2203],
            [-117.6469, 34.2959],
            [-117.5592, 34.296],
            [-117.5587, 34.2191],
            [-117.4645, 34.2201],
            [-117.4668, 34.1214],
            [-117.5154, 34.0772],
            [-117.5243, 34.0335],
            [-117.5583, 34.0335],
            [-117.5584, 33.9884],
            [-117.6069, 33.9728],
            [-117.606, 34.0305],
            [-117.7245, 34.0447],
            [-117.7111, 34.0795],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '08160',
        GEOID: '608160',
        NAME: 'Chaffey Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422788497,
        AWATER: 183824,
        INTPTLAT: '+34.1385568',
        INTPTLON: '-117.5776791',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6673, 34.8225],
            [-117.4545, 34.821],
            [-117.2402, 34.8203],
            [-117.2416, 34.7616],
            [-117.2072, 34.6409],
            [-117.2633, 34.5862],
            [-117.2547, 34.4709],
            [-117.3731, 34.4704],
            [-117.3995, 34.4703],
            [-117.3995, 34.5067],
            [-117.6601, 34.4986],
            [-117.667, 34.558],
            [-117.6671, 34.6902],
            [-117.6673, 34.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '36972',
        GEOID: '636972',
        NAME: 'Victor Valley Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1423249939,
        AWATER: 6360388,
        INTPTLAT: '+34.6620026',
        INTPTLON: '-117.4471342',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4984, 41.1827],
            [-122.5091, 41.2477],
            [-122.1946, 41.2492],
            [-122.1943, 41.1844],
            [-122.0728, 41.1831],
            [-122.0935, 41.1126],
            [-122.1791, 41.0462],
            [-122.5275, 41.0435],
            [-122.5228, 41.0566],
            [-122.4571, 41.0966],
            [-122.4984, 41.1827],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '11700',
        GEOID: '611700',
        NAME: 'Dunsmuir Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666720348,
        AWATER: 2649733,
        INTPTLAT: '+41.1423017',
        INTPTLON: '-122.3099775',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7871, 38.239],
            [-122.787, 38.308],
            [-122.7963, 38.3284],
            [-122.7892, 38.3284],
            [-122.7539, 38.3364],
            [-122.7195, 38.3138],
            [-122.7046, 38.3041],
            [-122.667, 38.3505],
            [-122.6377, 38.351],
            [-122.6018, 38.3575],
            [-122.5837, 38.3577],
            [-122.5371, 38.2664],
            [-122.4068, 38.1587],
            [-122.4068, 38.1556],
            [-122.3475, 38.0733],
            [-122.5028, 38.113],
            [-122.5454, 38.1587],
            [-122.6451, 38.1811],
            [-122.6491, 38.1475],
            [-122.646, 38.1055],
            [-122.7662, 38.1262],
            [-122.7751, 38.1184],
            [-122.8416, 38.1642],
            [-122.7871, 38.239],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '30250',
        GEOID: '630250',
        NAME: 'Petaluma Joint Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 636425199,
        AWATER: 43431377,
        INTPTLAT: '+38.1985401',
        INTPTLON: '-122.6342942',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-119.8013, 36.6154],
              [-119.8041, 36.6633],
              [-119.7727, 36.6923],
              [-119.7637, 36.6924],
              [-119.7547, 36.6634],
              [-119.7094, 36.5909],
              [-119.7729, 36.5763],
              [-119.8089, 36.5762],
              [-119.8013, 36.6154],
            ],
          ],
          [
            [
              [-119.8445, 36.7139],
              [-119.8268, 36.714],
              [-119.9073, 36.6632],
              [-119.8445, 36.7139],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06018',
        GEOID: '606018',
        NAME: 'Washington Unified School District (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 116724995,
        AWATER: 44038,
        INTPTLAT: '+36.6451008',
        INTPTLON: '-119.7975716',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4576, 36.5246],
            [-119.4487, 36.5203],
            [-119.4219, 36.4728],
            [-119.476, 36.4886],
            [-119.4576, 36.5246],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06016',
        GEOID: '606016',
        NAME: 'Dinuba Unified (9-12) in Kings River Union',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 16236442,
        AWATER: 0,
        INTPTLAT: '+36.4914661',
        INTPTLON: '-119.4534660',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0114, 33.8809],
            [-117.9766, 33.9028],
            [-117.9766, 33.9088],
            [-117.9943, 33.9172],
            [-117.9767, 33.9748],
            [-117.9344, 33.9729],
            [-117.9258, 33.946],
            [-117.9156, 33.947],
            [-117.9156, 33.9174],
            [-117.8817, 33.907],
            [-117.8961, 33.8564],
            [-117.9762, 33.8542],
            [-117.9807, 33.854],
            [-118.0461, 33.8536],
            [-118.0114, 33.8809],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '14760',
        GEOID: '614760',
        NAME: 'Fullerton Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112065276,
        AWATER: 173318,
        INTPTLAT: '+33.9036909',
        INTPTLON: '-117.9491871',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6758, 36.5037],
            [-119.5931, 36.5035],
            [-119.5297, 36.5613],
            [-119.5297, 36.5685],
            [-119.4937, 36.5755],
            [-119.4962, 36.5357],
            [-119.4576, 36.5246],
            [-119.476, 36.4886],
            [-119.4219, 36.4728],
            [-119.3856, 36.4585],
            [-119.475, 36.3905],
            [-119.5429, 36.4452],
            [-119.6427, 36.4299],
            [-119.6758, 36.5037],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '19830',
        GEOID: '619830',
        NAME: 'Kingsburg Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253780310,
        AWATER: 932528,
        INTPTLAT: '+36.4693360',
        INTPTLON: '-119.5329896',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2682, 36.0947],
            [-119.206, 36.1165],
            [-119.215, 36.1607],
            [-119.1879, 36.1962],
            [-119.0859, 36.1672],
            [-118.9926, 36.2255],
            [-118.9013, 36.1953],
            [-118.8548, 36.2938],
            [-118.7835, 36.3341],
            [-118.1007, 36.3354],
            [-118.1276, 36.2803],
            [-118.0402, 36.0324],
            [-118.6269, 36.0388],
            [-118.6805, 36.1091],
            [-118.7884, 36.0579],
            [-118.9268, 36.066],
            [-118.9816, 36.0513],
            [-118.9779, 36.082],
            [-118.9467, 36.0802],
            [-118.947, 36.1093],
            [-119.0715, 36.1164],
            [-119.0803, 36.1164],
            [-119.0796, 36.0638],
            [-119.1075, 36.0223],
            [-119.0178, 35.9948],
            [-118.841, 35.9521],
            [-118.6339, 35.9813],
            [-118.0101, 35.9776],
            [-117.9825, 35.8958],
            [-118.008, 35.7892],
            [-118.0198, 35.7898],
            [-118.361, 35.7906],
            [-118.5739, 35.7897],
            [-118.5892, 35.7897],
            [-118.6811, 35.7899],
            [-118.7442, 35.7902],
            [-118.9382, 35.7905],
            [-119.0712, 35.7908],
            [-119.1614, 35.8774],
            [-119.1616, 35.9209],
            [-119.1617, 35.9355],
            [-119.2148, 35.9355],
            [-119.2146, 36.015],
            [-119.2233, 36.0222],
            [-119.2146, 36.0658],
            [-119.2682, 36.0947],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06107',
        GEOID: '606107',
        NAME: 'Porterville Unified (9-12) School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 4943317282,
        AWATER: 9774083,
        INTPTLAT: '+36.0714962',
        INTPTLON: '-118.5589696',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0654, 40.287],
            [-122.7486, 40.3657],
            [-122.6339, 40.3341],
            [-122.52, 40.3937],
            [-122.3104, 40.3711],
            [-122.085, 40.4127],
            [-122.0475, 40.4312],
            [-121.9201, 40.4717],
            [-121.4631, 40.4859],
            [-121.3229, 40.5118],
            [-121.3278, 40.4454],
            [-121.4976, 40.4456],
            [-121.4704, 40.3502],
            [-121.3818, 40.3405],
            [-121.349, 40.2645],
            [-121.4369, 40.1519],
            [-121.5864, 40.1005],
            [-121.6347, 40.1008],
            [-121.8862, 40.1006],
            [-121.9917, 40.0548],
            [-122.1148, 40.0429],
            [-122.1637, 40.0074],
            [-122.2074, 40.0002],
            [-122.3706, 40.0198],
            [-122.4416, 40.0204],
            [-122.46, 40.0228],
            [-122.5162, 39.9596],
            [-122.5546, 39.9744],
            [-122.9258, 39.9682],
            [-122.934, 39.9781],
            [-122.9686, 40.1389],
            [-122.989, 40.2501],
            [-123.0654, 40.287],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '32010',
        GEOID: '632010',
        NAME: 'Red Bluff Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5521382546,
        AWATER: 16847255,
        INTPTLAT: '+40.2517209',
        INTPTLON: '-122.1687429',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7968, 34.6166],
            [-118.6247, 34.6155],
            [-118.5165, 34.6017],
            [-118.3932, 34.6019],
            [-118.3759, 34.5586],
            [-118.3773, 34.5442],
            [-118.3257, 34.4375],
            [-118.3244, 34.3522],
            [-118.4487, 34.3528],
            [-118.6509, 34.3205],
            [-118.6776, 34.375],
            [-118.6914, 34.403],
            [-118.7968, 34.6166],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '42510',
        GEOID: '642510',
        NAME: 'William S. Hart Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1013458496,
        AWATER: 14132329,
        INTPTLAT: '+34.4865289',
        INTPTLON: '-118.5520651',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3961, 33.9309],
            [-118.37, 33.9309],
            [-118.37, 33.95],
            [-118.3439, 33.9309],
            [-118.3265, 33.9237],
            [-118.3265, 33.9019],
            [-118.3265, 33.8874],
            [-118.3523, 33.8742],
            [-118.3698, 33.8946],
            [-118.3787, 33.8946],
            [-118.3962, 33.9019],
            [-118.3961, 33.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '07920',
        GEOID: '607920',
        NAME: 'Centinela Valley Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33866003,
        AWATER: 91687,
        INTPTLAT: '+33.9112654',
        INTPTLON: '-118.3590183',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1259, 33.6972],
            [-118.1238, 33.7104],
            [-118.0744, 33.7261],
            [-118.0419, 33.7395],
            [-118.0425, 33.774],
            [-117.9721, 33.7375],
            [-117.9695, 33.7233],
            [-117.9307, 33.7019],
            [-117.9451, 33.6724],
            [-117.9891, 33.5858],
            [-118.1259, 33.6972],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '18060',
        GEOID: '618060',
        NAME: 'Huntington Beach Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115530323,
        AWATER: 103510791,
        INTPTLAT: '+33.6846338',
        INTPTLON: '-118.0211933',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1811, 40.8369],
            [-124.1626, 40.8687],
            [-124.1349, 40.9337],
            [-124.1161, 40.9997],
            [-124.1557, 41.0902],
            [-124.0991, 41.2671],
            [-124.0652, 41.4647],
            [-123.9976, 41.4643],
            [-123.998, 41.2673],
            [-123.998, 41.1149],
            [-123.9256, 41.1193],
            [-123.7988, 41.1208],
            [-123.7708, 40.9408],
            [-123.7163, 40.8972],
            [-123.6993, 40.7636],
            [-123.5501, 40.764],
            [-123.544, 40.7329],
            [-123.5445, 40.6938],
            [-123.6632, 40.6935],
            [-123.6702, 40.6023],
            [-123.7783, 40.6436],
            [-123.8564, 40.6852],
            [-123.9106, 40.7805],
            [-123.964, 40.8189],
            [-123.9866, 40.7957],
            [-124.1009, 40.8165],
            [-124.1005, 40.837],
            [-124.1698, 40.8369],
            [-124.2194, 40.766],
            [-124.2341, 40.7653],
            [-124.1811, 40.8369],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '03030',
        GEOID: '603030',
        NAME: 'Northern Humboldt Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1820418495,
        AWATER: 34335692,
        INTPTLAT: '+40.9721687',
        INTPTLON: '-123.9071651',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-119.6703, 34.4658],
              [-119.6428, 34.4658],
              [-119.5917, 34.4658],
              [-119.6123, 34.3674],
              [-119.66, 34.3595],
              [-119.6532, 34.4222],
              [-119.6703, 34.4658],
            ],
          ],
          [
            [
              [-120.0038, 34.5249],
              [-119.7557, 34.5235],
              [-119.7556, 34.4946],
              [-119.7286, 34.4802],
              [-119.7557, 34.3493],
              [-119.7875, 34.3609],
              [-119.8985, 34.3604],
              [-120.0223, 34.4083],
              [-120.0038, 34.5249],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06019',
        GEOID: '606019',
        NAME: 'Santa Barbara Unified School District (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 291568990,
        AWATER: 178333731,
        INTPTLAT: '+34.4401000',
        INTPTLON: '-119.8456870',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7283, 34.9282],
            [-120.7028, 35],
            [-120.6341, 34.9596],
            [-120.5427, 34.9804],
            [-120.4355, 34.9868],
            [-120.3553, 34.9413],
            [-120.3099, 35.0092],
            [-120.1881, 35.0312],
            [-120.1015, 35.1135],
            [-120.093, 35.1142],
            [-120.0835, 34.9685],
            [-120.0834, 34.8612],
            [-120.1434, 34.8017],
            [-120.1892, 34.6824],
            [-120.2629, 34.6869],
            [-120.4298, 34.7339],
            [-120.4809, 34.7744],
            [-120.6956, 34.7786],
            [-120.6733, 34.8386],
            [-120.7283, 34.9282],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '35670',
        GEOID: '635670',
        NAME: 'Santa Maria Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1462807725,
        AWATER: 159627442,
        INTPTLAT: '+34.8746921',
        INTPTLON: '-120.3547359',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2981, 34.5722],
            [-120.2436, 34.6218],
            [-120.2629, 34.6869],
            [-120.1892, 34.6824],
            [-120.1434, 34.8017],
            [-120.0834, 34.8612],
            [-120.0835, 34.9685],
            [-119.9836, 34.9698],
            [-119.9829, 34.8959],
            [-119.8011, 34.8959],
            [-119.8014, 34.8212],
            [-119.4761, 34.8195],
            [-119.4414, 34.7333],
            [-119.4424, 34.5611],
            [-119.4423, 34.4655],
            [-119.5917, 34.4658],
            [-119.6428, 34.4658],
            [-119.6703, 34.4658],
            [-119.7286, 34.4802],
            [-119.7556, 34.4946],
            [-119.7557, 34.5235],
            [-120.0038, 34.5249],
            [-120.0223, 34.4083],
            [-120.1351, 34.4221],
            [-120.4177, 34.3976],
            [-120.3745, 34.5929],
            [-120.2981, 34.5722],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '35850',
        GEOID: '635850',
        NAME: 'Santa Ynez Valley Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3040008842,
        AWATER: 223865210,
        INTPTLAT: '+34.6358707',
        INTPTLON: '-119.8949947',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4934, 38.6708],
            [-121.4013, 38.6541],
            [-121.4056, 38.6383],
            [-121.4932, 38.642],
            [-121.4934, 38.6708],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06004',
        GEOID: '606004',
        NAME: 'Twin Rivers Unified School District in Robla',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 25662730,
        AWATER: 0,
        INTPTLAT: '+38.6551086',
        INTPTLON: '-121.4473217',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1899, 37.3203],
            [-122.1841, 37.3348],
            [-122.1156, 37.4127],
            [-122.0968, 37.4619],
            [-122.0815, 37.4778],
            [-122.0453, 37.4603],
            [-122.0381, 37.4393],
            [-122.0617, 37.3608],
            [-122.0611, 37.352],
            [-122.1623, 37.3044],
            [-122.1899, 37.3203],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '26310',
        GEOID: '626310',
        NAME: 'Mountain View-Los Altos Union School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96960374,
        AWATER: 9223664,
        INTPTLAT: '+37.3868098',
        INTPTLON: '-122.0998178',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4174, 38.5748],
            [-123.3567, 38.6245],
            [-123.1629, 38.6147],
            [-123.1271, 38.6172],
            [-123.0695, 38.5761],
            [-122.9551, 38.5449],
            [-122.861, 38.5032],
            [-122.8443, 38.4923],
            [-122.8404, 38.4673],
            [-122.779, 38.4455],
            [-122.788, 38.4233],
            [-122.7736, 38.3982],
            [-122.7693, 38.3861],
            [-122.7597, 38.3604],
            [-122.7539, 38.3364],
            [-122.7892, 38.3284],
            [-122.7963, 38.3284],
            [-122.8882, 38.3571],
            [-123.0169, 38.4042],
            [-123.1655, 38.3888],
            [-123.2042, 38.4241],
            [-123.3609, 38.5102],
            [-123.4174, 38.5748],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '02670',
        GEOID: '602670',
        NAME: 'West Sonoma County Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 835756695,
        AWATER: 195336635,
        INTPTLAT: '+38.4845855',
        INTPTLON: '-123.0858851',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1523, 37.2861],
            [-122.1385, 37.2675],
            [-122.0059, 37.2855],
            [-122.0026, 37.2789],
            [-121.9962, 37.2457],
            [-121.9569, 37.2605],
            [-121.9545, 37.2509],
            [-121.8942, 37.2217],
            [-121.844, 37.1577],
            [-121.8404, 37.1556],
            [-121.8425, 37.0945],
            [-121.8588, 37.0758],
            [-121.9464, 37.0685],
            [-121.9535, 37.0762],
            [-121.984, 37.1356],
            [-122.0215, 37.1278],
            [-122.1515, 37.23],
            [-122.1523, 37.2861],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '22800',
        GEOID: '622800',
        NAME: 'Los Gatos-Saratoga Joint Union School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365690861,
        AWATER: 2432712,
        INTPTLAT: '+37.1752816',
        INTPTLON: '-121.9659947',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5583, 38.7363],
            [-121.4696, 38.7947],
            [-121.4142, 38.7952],
            [-121.4434, 38.7049],
            [-121.558, 38.7001],
            [-121.5583, 38.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06003',
        GEOID: '606003',
        NAME: 'Twin Rivers Unified School District in Elverta',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 64094764,
        AWATER: 36883,
        INTPTLAT: '+38.7392057',
        INTPTLON: '-121.4806566',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3601, 41.1615],
            [-123.2452, 41.078],
            [-123.0865, 41.0579],
            [-123.1052, 40.9252],
            [-123.107, 40.7036],
            [-123.3475, 40.7053],
            [-123.36, 40.846],
            [-123.3601, 41.1615],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06008',
        GEOID: '606008',
        NAME: 'Trinity Alps Unified School District in Cox Bar',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 953390033,
        AWATER: 8361,
        INTPTLAT: '+40.9502798',
        INTPTLON: '-123.2351549',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1248, 39.7975],
            [-122.0465, 39.7976],
            [-122.0228, 39.7664],
            [-122.1009, 39.7517],
            [-122.1248, 39.7975],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06025',
        GEOID: '606025',
        NAME: 'Hamilton Unified School District in Capay (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 30867909,
        AWATER: 929530,
        INTPTLAT: '+39.7787191',
        INTPTLON: '-122.0754139',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.903, 40.6935],
            [-122.8262, 40.8399],
            [-122.6572, 40.803],
            [-122.752, 40.6899],
            [-122.711, 40.6248],
            [-122.8629, 40.6377],
            [-122.903, 40.6935],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06011',
        GEOID: '606011',
        NAME: 'Trinity Alps Unified School District in Lewiston',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 266070955,
        AWATER: 7814052,
        INTPTLAT: '+40.7461333',
        INTPTLON: '-122.8022178',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1725, 38.9196],
            [-121.1878, 39.0125],
            [-121.0795, 39.0069],
            [-120.9981, 39.0707],
            [-120.974, 39.1175],
            [-120.9475, 39.1434],
            [-120.9238, 39.1588],
            [-120.7279, 39.2881],
            [-120.6075, 39.3155],
            [-120.567, 39.2153],
            [-120.3242, 39.2492],
            [-120.2649, 39.1858],
            [-120.2591, 39.0247],
            [-120.4352, 39.0284],
            [-120.471, 38.9655],
            [-120.5724, 38.9144],
            [-120.7302, 39.004],
            [-120.9347, 38.9638],
            [-121.0375, 38.9157],
            [-121.1014, 38.8153],
            [-121.121, 38.7525],
            [-121.2243, 38.7596],
            [-121.2178, 38.7704],
            [-121.2314, 38.8392],
            [-121.1725, 38.9196],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '30750',
        GEOID: '630750',
        NAME: 'Placer Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2200816271,
        AWATER: 25227821,
        INTPTLAT: '+39.0715279',
        INTPTLON: '-120.7122179',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9283, 39.1987],
            [-121.9334, 39.2126],
            [-121.7181, 39.2165],
            [-121.6236, 39.1974],
            [-121.6181, 39.1822],
            [-121.6886, 39.1707],
            [-121.6888, 39.1561],
            [-121.7649, 39.0987],
            [-121.735, 39.0024],
            [-121.6359, 38.8817],
            [-121.6594, 38.8357],
            [-121.8172, 38.882],
            [-121.8191, 38.9886],
            [-121.8942, 39.0904],
            [-121.9003, 39.1003],
            [-121.9283, 39.1987],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '38610',
        GEOID: '638610',
        NAME: 'Sutter Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619790017,
        AWATER: 4761309,
        INTPTLAT: '+39.0624282',
        INTPTLON: '-121.7735887',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.7072, 32.7231],
            [-115.7049, 32.8108],
            [-115.6182, 32.8255],
            [-115.595, 32.8183],
            [-115.5357, 32.8253],
            [-115.4727, 32.8249],
            [-115.4785, 32.7594],
            [-115.4827, 32.7304],
            [-115.5426, 32.7085],
            [-115.64, 32.709],
            [-115.692, 32.65],
            [-115.8433, 32.6387],
            [-115.8433, 32.7139],
            [-115.7072, 32.7231],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '08010',
        GEOID: '608010',
        NAME: 'Central Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405419951,
        AWATER: 177538,
        INTPTLAT: '+32.7410776',
        INTPTLON: '-115.6421516',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7674, 36.3898],
            [-119.7745, 36.3794],
            [-119.736, 36.3641],
            [-119.736, 36.3281],
            [-119.7359, 36.284],
            [-119.7339, 36.1379],
            [-119.7538, 36.051],
            [-119.8956, 36.0518],
            [-119.8969, 36.1379],
            [-119.9589, 36.1814],
            [-119.9589, 36.2555],
            [-119.9589, 36.2991],
            [-119.898, 36.385],
            [-119.7674, 36.3898],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '21400',
        GEOID: '621400',
        NAME: 'Lemoore Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 661390883,
        AWATER: 321825,
        INTPTLAT: '+36.2331345',
        INTPTLON: '-119.8435592',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7119, 36.6304],
            [-121.7483, 36.6986],
            [-121.7349, 36.6984],
            [-121.729, 36.7385],
            [-121.6462, 36.7616],
            [-121.6231, 36.8154],
            [-121.5971, 36.8368],
            [-121.5351, 36.7836],
            [-121.483, 36.765],
            [-121.4762, 36.7543],
            [-121.4681, 36.6855],
            [-121.3633, 36.6547],
            [-121.3636, 36.6456],
            [-121.4673, 36.6455],
            [-121.5763, 36.6074],
            [-121.5312, 36.533],
            [-121.5679, 36.4749],
            [-121.6459, 36.5065],
            [-121.7774, 36.5517],
            [-121.7119, 36.6304],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '33980',
        GEOID: '633980',
        NAME: 'Salinas Union High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 668524342,
        AWATER: 4599101,
        INTPTLAT: '+36.6532922',
        INTPTLON: '-121.6154238',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.096, 34.4208],
            [-119.079, 34.5617],
            [-119.0235, 34.4758],
            [-119.0259, 34.3936],
            [-119.096, 34.4208],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06023',
        GEOID: '606023',
        NAME: 'Santa Paula Unified (9-12) in Mupu',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 103472260,
        AWATER: 10762,
        INTPTLAT: '+34.5090691',
        INTPTLON: '-119.0476898',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.345, 37.463],
            [-122.3187, 37.4824],
            [-122.1905, 37.5929],
            [-122.1868, 37.5929],
            [-122.18, 37.5845],
            [-122.1504, 37.5475],
            [-122.1492, 37.5458],
            [-122.1219, 37.5123],
            [-122.0815, 37.4778],
            [-122.0968, 37.4619],
            [-122.1449, 37.4582],
            [-122.1899, 37.4288],
            [-122.1919, 37.3975],
            [-122.1841, 37.3348],
            [-122.1899, 37.3203],
            [-122.2873, 37.3596],
            [-122.2864, 37.3946],
            [-122.3185, 37.4344],
            [-122.345, 37.463],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '36390',
        GEOID: '636390',
        NAME: 'Sequoia Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256554159,
        AWATER: 63055379,
        INTPTLAT: '+37.4640935',
        INTPTLON: '-122.2255367',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0736, 34.0905],
            [-118.0549, 34.0897],
            [-118.0499, 34.0965],
            [-118.0184, 34.1091],
            [-118.0045, 34.1111],
            [-117.9809, 34.1091],
            [-117.9984, 34.0743],
            [-118.0026, 34.0606],
            [-118.0098, 34.0407],
            [-118.0299, 34.0366],
            [-118.0542, 34.0207],
            [-118.083, 34.0206],
            [-118.075, 34.0407],
            [-118.0731, 34.0449],
            [-118.0717, 34.0627],
            [-118.0908, 34.0802],
            [-118.0736, 34.0905],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '12120',
        GEOID: '612120',
        NAME: 'El Monte Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54665317,
        AWATER: 3700728,
        INTPTLAT: '+34.0694766',
        INTPTLON: '-118.0373239',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1088, 33.9277],
            [-118.0938, 33.9505],
            [-118.0892, 33.9571],
            [-118.0819, 33.9774],
            [-118.0542, 34.0207],
            [-118.0299, 34.0366],
            [-118.0098, 34.0407],
            [-117.9953, 34.0343],
            [-118.003, 33.9893],
            [-117.9767, 33.9748],
            [-117.9943, 33.9172],
            [-118.0232, 33.9241],
            [-118.0604, 33.9171],
            [-118.1088, 33.9277],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '42480',
        GEOID: '642480',
        NAME: 'Whittier Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104240619,
        AWATER: 672705,
        INTPTLAT: '+33.9670928',
        INTPTLON: '-118.0376700',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4654, 37.5701],
            [-122.4442, 37.6192],
            [-122.4463, 37.6222],
            [-122.4543, 37.6321],
            [-122.279, 37.6339],
            [-122.279, 37.672],
            [-122.252, 37.6724],
            [-122.2187, 37.6317],
            [-122.2087, 37.6195],
            [-122.1868, 37.5929],
            [-122.1905, 37.5929],
            [-122.3187, 37.4824],
            [-122.345, 37.463],
            [-122.4021, 37.534],
            [-122.4654, 37.5701],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '34980',
        GEOID: '634980',
        NAME: 'San Mateo Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162006310,
        AWATER: 122330680,
        INTPTLAT: '+37.5784337',
        INTPTLON: '-122.3292816',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1649, 34.4076],
            [-119.1361, 34.4126],
            [-119.0802, 34.3129],
            [-119.1294, 34.2884],
            [-119.1649, 34.4076],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06022',
        GEOID: '606022',
        NAME: 'Santa Paula Unified (9-12) in Briggs',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 56357270,
        AWATER: 1186892,
        INTPTLAT: '+34.3587415',
        INTPTLON: '-119.1331432',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7932, 37.9586],
            [-120.7907, 38.0886],
            [-120.5692, 38.1067],
            [-120.4974, 38.1466],
            [-120.3982, 38.1903],
            [-120.3959, 38.2934],
            [-120.2636, 38.3177],
            [-120.2644, 38.4827],
            [-120.0725, 38.5099],
            [-120.02, 38.4335],
            [-120.1766, 38.3735],
            [-120.324, 38.2001],
            [-120.4308, 38.0668],
            [-120.5336, 37.9451],
            [-120.6527, 37.8314],
            [-120.7473, 37.9161],
            [-120.7932, 37.9586],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '05940',
        GEOID: '605940',
        NAME: 'Bret Harte Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1101575694,
        AWATER: 13459559,
        INTPTLAT: '+38.1478436',
        INTPTLON: '-120.4651598',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1083, 34.0771],
            [-118.0908, 34.0802],
            [-118.0717, 34.0627],
            [-118.0731, 34.0449],
            [-118.075, 34.0407],
            [-118.1167, 34.043],
            [-118.1083, 34.0771],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06037',
        GEOID: '606037',
        NAME: 'Alhambra Unified (9-12) School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 11666276,
        AWATER: 120610,
        INTPTLAT: '+34.0587656',
        INTPTLON: '-118.0964295',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7674, 36.3898],
            [-119.7531, 36.4019],
            [-119.7098, 36.4238],
            [-119.6669, 36.4187],
            [-119.6427, 36.4299],
            [-119.5429, 36.4452],
            [-119.475, 36.3905],
            [-119.4561, 36.2688],
            [-119.5017, 36.2478],
            [-119.5294, 36.2482],
            [-119.547, 36.2111],
            [-119.6727, 36.1598],
            [-119.7339, 36.1379],
            [-119.7359, 36.284],
            [-119.736, 36.3281],
            [-119.736, 36.3641],
            [-119.7745, 36.3794],
            [-119.7674, 36.3898],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '16500',
        GEOID: '616500',
        NAME: 'Hanford Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 646753831,
        AWATER: 2468994,
        INTPTLAT: '+36.3047466',
        INTPTLON: '-119.6141167',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0235, 34.4758],
            [-119.0066, 34.3599],
            [-119.0259, 34.3936],
            [-119.0235, 34.4758],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06024',
        GEOID: '606024',
        NAME: 'Santa Paula Unified (9-12) in Santa Clara',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 44264830,
        AWATER: 1096975,
        INTPTLAT: '+34.4127008',
        INTPTLON: '-119.0010042',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5011, 36.3137],
            [-121.3266, 36.3129],
            [-121.2486, 36.3796],
            [-121.1435, 36.4062],
            [-121.117, 36.3852],
            [-120.9748, 36.4503],
            [-120.9936, 36.5317],
            [-120.9109, 36.4867],
            [-120.82, 36.4856],
            [-120.7448, 36.3842],
            [-120.597, 36.3575],
            [-120.7468, 36.1998],
            [-120.6951, 36.1555],
            [-120.4778, 36.0033],
            [-120.5826, 36.0026],
            [-120.6225, 36.0034],
            [-120.6395, 35.9465],
            [-120.6923, 35.8726],
            [-120.6916, 35.7909],
            [-120.9593, 35.7926],
            [-121.1757, 35.7941],
            [-121.2438, 35.7945],
            [-121.4372, 36.0153],
            [-121.576, 36.1415],
            [-121.5758, 36.3142],
            [-121.5011, 36.3137],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '19650',
        GEOID: '619650',
        NAME: 'King City Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4841546149,
        AWATER: 63075068,
        INTPTLAT: '+36.1143669',
        INTPTLON: '-121.0487966',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.433, 38.8243],
            [-121.3002, 38.8246],
            [-121.2178, 38.7704],
            [-121.2243, 38.7596],
            [-121.121, 38.7525],
            [-121.141, 38.712],
            [-121.1938, 38.7147],
            [-121.2361, 38.7177],
            [-121.3088, 38.7228],
            [-121.3347, 38.7035],
            [-121.3645, 38.7039],
            [-121.3585, 38.7662],
            [-121.4142, 38.7952],
            [-121.4696, 38.7947],
            [-121.433, 38.8243],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '33630',
        GEOID: '633630',
        NAME: 'Roseville Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183540969,
        AWATER: 12570316,
        INTPTLAT: '+38.7632586',
        INTPTLON: '-121.2955334',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6594, 38.8357],
            [-121.6359, 38.8817],
            [-121.5755, 38.9184],
            [-121.5738, 38.9302],
            [-121.5078, 38.9829],
            [-121.4148, 38.9965],
            [-121.4694, 38.926],
            [-121.4695, 38.8969],
            [-121.433, 38.8243],
            [-121.4696, 38.7947],
            [-121.5583, 38.7363],
            [-121.5586, 38.7363],
            [-121.6029, 38.7358],
            [-121.6594, 38.8357],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '11780',
        GEOID: '611780',
        NAME: 'East Nicolaus Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379108089,
        AWATER: 3107688,
        INTPTLAT: '+38.8548216',
        INTPTLON: '-121.5333568',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9487, 37.5364],
            [-120.9214, 37.5361],
            [-120.9214, 37.5336],
            [-120.9036, 37.5217],
            [-120.8579, 37.4508],
            [-120.9844, 37.3994],
            [-121.0284, 37.4495],
            [-121.0882, 37.5086],
            [-120.9487, 37.5364],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06013',
        GEOID: '606013',
        NAME: 'Turlock Unified School District in Chatom Union',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 164875312,
        AWATER: 442746,
        INTPTLAT: '+37.4710052',
        INTPTLON: '-120.9627120',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8366, 34.8175],
            [-118.6909, 34.8181],
            [-118.6257, 34.8182],
            [-118.1668, 34.8206],
            [-118.0423, 34.822],
            [-118.0234, 34.8222],
            [-117.6673, 34.8225],
            [-117.6671, 34.6902],
            [-117.667, 34.558],
            [-117.6601, 34.4986],
            [-117.6552, 34.3972],
            [-117.8748, 34.3818],
            [-118.0285, 34.3812],
            [-118.0284, 34.4701],
            [-118.0797, 34.5244],
            [-118.1853, 34.5582],
            [-118.3759, 34.5586],
            [-118.3932, 34.6019],
            [-118.5165, 34.6017],
            [-118.6247, 34.6155],
            [-118.7968, 34.6166],
            [-118.8814, 34.7906],
            [-118.8366, 34.8175],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '02820',
        GEOID: '602820',
        NAME: 'Antelope Valley Union Joint High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3597856937,
        AWATER: 18975632,
        INTPTLAT: '+34.6548837',
        INTPTLON: '-118.1499193',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6203, 37.8003],
            [-121.5569, 37.8172],
            [-121.557, 37.7077],
            [-121.557, 37.6706],
            [-121.6025, 37.6705],
            [-121.6203, 37.8003],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06020',
        GEOID: '606020',
        NAME: 'Lammersville Joint Unified School District (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 70915952,
        AWATER: 1137220,
        INTPTLAT: '+37.7473502',
        INTPTLON: '-121.5819582',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.359, 36.5745],
            [-119.305, 36.5882],
            [-119.2952, 36.4695],
            [-119.3679, 36.4582],
            [-119.359, 36.5745],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06017',
        GEOID: '606017',
        NAME: 'Dinuba Unified (9-12) in Monson-Sultana Joint Union',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 57001317,
        AWATER: 0,
        INTPTLAT: '+36.5189083',
        INTPTLON: '-119.3310929',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1029, 42.0068],
            [-122.9877, 42.0032],
            [-122.8755, 42.0033],
            [-122.7536, 42.0041],
            [-122.4035, 42.0087],
            [-122.2895, 42.0078],
            [-122.2849, 42.0077],
            [-122.1863, 42.0075],
            [-122.2474, 41.9522],
            [-122.2474, 41.8581],
            [-122.2477, 41.8066],
            [-122.1516, 41.8063],
            [-122.1336, 41.6902],
            [-122.2501, 41.6912],
            [-122.2456, 41.6103],
            [-122.2426, 41.5234],
            [-122.481, 41.5385],
            [-122.503, 41.4399],
            [-122.5976, 41.4394],
            [-122.6518, 41.4387],
            [-122.6522, 41.5768],
            [-122.6521, 41.6103],
            [-122.7929, 41.782],
            [-122.9169, 41.7835],
            [-122.9093, 41.6963],
            [-123.1007, 41.6717],
            [-123.1603, 41.6961],
            [-123.1519, 41.8684],
            [-123.0934, 41.869],
            [-123.1029, 42.0068],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '43410',
        GEOID: '643410',
        NAME: 'Yreka Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3355267245,
        AWATER: 20958376,
        INTPTLAT: '+41.7984998',
        INTPTLON: '-122.6322692',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.5976, 41.4394],
              [-122.503, 41.4399],
              [-122.481, 41.5385],
              [-122.2426, 41.5234],
              [-122.2456, 41.6103],
              [-122.2501, 41.6912],
              [-122.1336, 41.6902],
              [-122.1332, 41.6107],
              [-122.0264, 41.6093],
              [-122.0271, 41.5229],
              [-121.9093, 41.5227],
              [-121.9102, 41.4367],
              [-121.4482, 41.4293],
              [-121.4464, 41.2922],
              [-121.4465, 41.1835],
              [-121.7923, 41.1833],
              [-122.018, 41.1832],
              [-122.0728, 41.1831],
              [-122.1943, 41.1844],
              [-122.1946, 41.2492],
              [-122.5091, 41.2477],
              [-122.5425, 41.3499],
              [-122.5868, 41.3492],
              [-122.5976, 41.4394],
            ],
          ],
          [
            [
              [-123.6724, 41.7337],
              [-123.7037, 41.8295],
              [-123.5654, 41.9031],
              [-123.5179, 42.0009],
              [-123.3425, 41.9993],
              [-123.2308, 42.0039],
              [-123.1029, 42.0068],
              [-123.0934, 41.869],
              [-123.1519, 41.8684],
              [-123.1603, 41.6961],
              [-123.2749, 41.696],
              [-123.2767, 41.6361],
              [-123.2631, 41.4904],
              [-123.4324, 41.4687],
              [-123.474, 41.3264],
              [-123.5012, 41.3826],
              [-123.6614, 41.3821],
              [-123.6124, 41.449],
              [-123.7192, 41.5956],
              [-123.6724, 41.7337],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '36940',
        GEOID: '636940',
        NAME: 'Siskiyou Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5528286889,
        AWATER: 25910163,
        INTPTLAT: '+41.3926588',
        INTPTLON: '-122.0181498',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4083, 41.1799],
            [-123.3601, 41.1615],
            [-123.36, 40.846],
            [-123.3475, 40.7053],
            [-123.544, 40.7329],
            [-123.5501, 40.764],
            [-123.5346, 40.9308],
            [-123.448, 40.9438],
            [-123.4083, 41.1799],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06006',
        GEOID: '606006',
        NAME: 'Trinity Alps Unified School District in Burnt Ranch',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 544150888,
        AWATER: 3143,
        INTPTLAT: '+40.8894393',
        INTPTLON: '-123.4325603',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9454, 41.1814],
            [-122.8113, 41.2025],
            [-122.5868, 41.3492],
            [-122.5425, 41.3499],
            [-122.5091, 41.2477],
            [-122.4984, 41.1827],
            [-122.4571, 41.0966],
            [-122.5228, 41.0566],
            [-122.8361, 41.0591],
            [-122.9093, 41.0429],
            [-122.9734, 41.1121],
            [-122.9454, 41.1814],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06007',
        GEOID: '606007',
        NAME: 'Trinity Alps Unified School District in Coffee Creek',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 927372476,
        AWATER: 2326281,
        INTPTLAT: '+41.2034758',
        INTPTLON: '-122.7097356',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9979, 40.6935],
            [-122.903, 40.6935],
            [-122.8629, 40.6377],
            [-122.711, 40.6248],
            [-122.7055, 40.6051],
            [-122.8606, 40.4827],
            [-122.9747, 40.5299],
            [-123.0375, 40.622],
            [-122.9979, 40.6935],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06009',
        GEOID: '606009',
        NAME: 'Trinity Alps Unified School District in Douglas City',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 343800436,
        AWATER: 32384,
        INTPTLAT: '+40.5911229',
        INTPTLON: '-122.8702226',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.775, 37.4631],
            [-118.6632, 37.463],
            [-118.6382, 37.525],
            [-118.6356, 37.525],
            [-118.5156, 37.4627],
            [-118.4599, 37.463],
            [-118.4594, 37.3612],
            [-118.5725, 37.361],
            [-118.5723, 37.2913],
            [-118.6865, 37.2911],
            [-118.7867, 37.3434],
            [-118.775, 37.4631],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06021',
        GEOID: '606021',
        NAME: 'Bishop Unified School District in Round Valley (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 476789691,
        AWATER: 4512133,
        INTPTLAT: '+37.4054387',
        INTPTLON: '-118.6301034',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.107, 40.7036],
            [-123.1052, 40.9252],
            [-123.0865, 41.0579],
            [-123.0438, 41.0141],
            [-122.991, 40.9273],
            [-122.9979, 40.6935],
            [-123.0375, 40.622],
            [-123.107, 40.7036],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06010',
        GEOID: '606010',
        NAME: 'Trinity Alps Unified School District in Junction City',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 365528089,
        AWATER: 631179,
        INTPTLAT: '+40.8290137',
        INTPTLON: '-123.0228589',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.234, 36.2759],
            [-119.2251, 36.2832],
            [-119.1765, 36.2825],
            [-119.188, 36.2184],
            [-119.234, 36.2759],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06029',
        GEOID: '606029',
        NAME: 'Exeter Unified School District (9-12) in Outside Creek',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 39229412,
        AWATER: 0,
        INTPTLAT: '+36.2563219',
        INTPTLON: '-119.1940883',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1345, 36.3583],
            [-118.9564, 36.4013],
            [-118.9917, 36.3384],
            [-119.0633, 36.3129],
            [-119.1345, 36.3583],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06028',
        GEOID: '606028',
        NAME: 'Exeter Unified School District (9-12) in Sequoia Union',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 91819336,
        AWATER: 3118304,
        INTPTLAT: '+36.3580610',
        INTPTLON: '-119.0522089',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9093, 41.0429],
            [-122.8361, 41.0591],
            [-122.5228, 41.0566],
            [-122.5275, 41.0435],
            [-122.6093, 40.926],
            [-122.6572, 40.803],
            [-122.8262, 40.8399],
            [-122.8266, 40.9398],
            [-122.9142, 40.9981],
            [-122.9093, 41.0429],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06012',
        GEOID: '606012',
        NAME: 'Trinity Alps Unified School District in Trinity Center',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 521919426,
        AWATER: 54482114,
        INTPTLAT: '+40.9469937',
        INTPTLON: '-122.7266042',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.474, 41.3264],
            [-123.4324, 41.4687],
            [-123.2631, 41.4904],
            [-123.0387, 41.4912],
            [-123.0432, 41.3594],
            [-122.9469, 41.3567],
            [-122.9454, 41.1814],
            [-122.9734, 41.1121],
            [-122.9093, 41.0429],
            [-122.9142, 40.9981],
            [-123.0438, 41.0141],
            [-123.0865, 41.0579],
            [-123.2452, 41.078],
            [-123.3601, 41.1615],
            [-123.4083, 41.1799],
            [-123.474, 41.3264],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06005',
        GEOID: '606005',
        NAME: 'Scott Valley Unified School District in Forks of Salmon',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 1665841983,
        AWATER: 4125722,
        INTPTLAT: '+41.2663944',
        INTPTLON: '-123.1705167',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1952, 36.5137],
            [-119.2062, 36.4284],
            [-119.2323, 36.4576],
            [-119.1952, 36.5137],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06026',
        GEOID: '606026',
        NAME: 'Woodlake Unified School District (9-12) in Stone Corral',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 50050938,
        AWATER: 0,
        INTPTLAT: '+36.4785925',
        INTPTLON: '-119.1969249',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9917, 36.3384],
            [-118.9564, 36.4013],
            [-118.9811, 36.5666],
            [-118.2918, 36.5705],
            [-118.2489, 36.4865],
            [-118.1007, 36.3354],
            [-118.7835, 36.3341],
            [-118.8548, 36.2938],
            [-118.9917, 36.3384],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06027',
        GEOID: '606027',
        NAME: 'Woodlake Unified School District (9-12) in Three Rivers Union',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 1823021229,
        AWATER: 6070456,
        INTPTLAT: '+36.4369892',
        INTPTLON: '-118.6715322',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.626, 35.5292],
            [-119.6355, 35.5725],
            [-119.5458, 35.5718],
            [-119.5464, 35.5141],
            [-119.4393, 35.5006],
            [-119.4036, 35.4567],
            [-119.3326, 35.4562],
            [-119.332, 35.5362],
            [-119.2421, 35.5584],
            [-119.1159, 35.5444],
            [-119.0293, 35.5301],
            [-118.9592, 35.5296],
            [-118.9044, 35.4994],
            [-118.8884, 35.6164],
            [-118.9402, 35.6168],
            [-118.9382, 35.7905],
            [-118.7442, 35.7902],
            [-118.6811, 35.7899],
            [-118.5892, 35.7897],
            [-118.5739, 35.7897],
            [-118.361, 35.7906],
            [-118.0198, 35.7898],
            [-118.0232, 35.6185],
            [-118.1655, 35.5577],
            [-118.157, 35.449],
            [-118.2716, 35.4502],
            [-118.2703, 35.3628],
            [-118.2686, 35.2753],
            [-118.6273, 35.2676],
            [-118.6996, 35.2385],
            [-118.7222, 35.2095],
            [-118.7526, 35.102],
            [-118.6367, 35.0396],
            [-118.6359, 34.9528],
            [-118.6257, 34.8182],
            [-118.6909, 34.8181],
            [-118.8366, 34.8175],
            [-118.8538, 34.9247],
            [-119.0659, 34.9261],
            [-119.0648, 35.0949],
            [-119.0742, 35.0948],
            [-119.2894, 35.0939],
            [-119.2893, 35.1804],
            [-119.3413, 35.2536],
            [-119.3058, 35.2672],
            [-119.2966, 35.2964],
            [-119.3412, 35.3621],
            [-119.4474, 35.362],
            [-119.4659, 35.2676],
            [-119.4836, 35.2675],
            [-119.5538, 35.2676],
            [-119.5538, 35.3249],
            [-119.6603, 35.383],
            [-119.626, 35.5292],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '19540',
        GEOID: '619540',
        NAME: 'Kern Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7735978139,
        AWATER: 50810279,
        INTPTLAT: '+35.3930930',
        INTPTLON: '-118.7772289',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3292, 34.2278],
            [-119.1949, 34.239],
            [-119.1613, 34.264],
            [-119.1294, 34.2884],
            [-119.0802, 34.3129],
            [-119.0293, 34.3273],
            [-119.0073, 34.3288],
            [-118.9552, 34.3431],
            [-118.9367, 34.2485],
            [-118.8656, 34.259],
            [-118.9966, 34.1564],
            [-118.9797, 34.1043],
            [-118.8769, 34.1142],
            [-118.941, 34.0748],
            [-118.9517, 33.9929],
            [-119.159, 34.0403],
            [-119.2269, 34.0743],
            [-119.2891, 34.1464],
            [-119.3292, 34.2278],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '29270',
        GEOID: '629270',
        NAME: 'Oxnard Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 599768613,
        AWATER: 284870886,
        INTPTLAT: '+34.1666166',
        INTPTLON: '-119.0841513',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0657, 40.3439],
            [-122.8606, 40.4827],
            [-122.7055, 40.6051],
            [-122.711, 40.6248],
            [-122.752, 40.6899],
            [-122.6572, 40.803],
            [-122.6093, 40.926],
            [-122.4912, 40.8389],
            [-122.4894, 40.75],
            [-122.4341, 40.5942],
            [-122.3635, 40.6123],
            [-122.3468, 40.6154],
            [-122.2854, 40.6505],
            [-122.2573, 40.7662],
            [-122.1238, 40.7645],
            [-122.0351, 40.8242],
            [-122.029, 40.9601],
            [-122.018, 41.1832],
            [-121.7923, 41.1833],
            [-121.7986, 40.9574],
            [-121.8346, 40.9587],
            [-121.8067, 40.8379],
            [-121.7491, 40.7541],
            [-121.5786, 40.7533],
            [-121.5423, 40.5996],
            [-121.4545, 40.6648],
            [-121.3578, 40.6639],
            [-121.3211, 40.7585],
            [-121.3228, 40.5409],
            [-121.3229, 40.5118],
            [-121.4631, 40.4859],
            [-121.9201, 40.4717],
            [-122.0475, 40.4312],
            [-122.0473, 40.5326],
            [-122.1992, 40.4893],
            [-122.2203, 40.506],
            [-122.3148, 40.5493],
            [-122.3538, 40.5347],
            [-122.3672, 40.5055],
            [-122.4009, 40.5048],
            [-122.4267, 40.4971],
            [-122.5066, 40.5034],
            [-122.5036, 40.4461],
            [-122.5997, 40.4452],
            [-122.6339, 40.3341],
            [-122.7486, 40.3657],
            [-123.0654, 40.287],
            [-123.0657, 40.3439],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '36600',
        GEOID: '636600',
        NAME: 'Shasta Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4704649636,
        AWATER: 44670054,
        INTPTLAT: '+40.6652243',
        INTPTLON: '-122.1342460',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5336, 37.9451],
            [-120.4308, 38.0668],
            [-120.324, 38.2001],
            [-120.3233, 38.0642],
            [-120.2582, 38.0347],
            [-120.2495, 38.0203],
            [-120.2819, 37.9549],
            [-120.2685, 37.8912],
            [-120.3295, 37.891],
            [-120.4098, 37.8977],
            [-120.353, 37.7755],
            [-120.4617, 37.6895],
            [-120.4619, 37.6896],
            [-120.6527, 37.8314],
            [-120.5336, 37.9451],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '37260',
        GEOID: '637260',
        NAME: 'Sonora Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 806086930,
        AWATER: 47072177,
        INTPTLAT: '+37.9227248',
        INTPTLON: '-120.4225030',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1033, 33.0747],
            [-116.082, 33.235],
            [-115.6684, 33.2345],
            [-115.6679, 33.0891],
            [-115.5626, 33.0895],
            [-115.4798, 33.0891],
            [-115.3542, 33.1039],
            [-115.3428, 33.4281],
            [-115.251, 33.4282],
            [-115.252, 33.152],
            [-115.2641, 32.9503],
            [-115.4601, 32.953],
            [-115.465, 32.9419],
            [-115.6077, 32.9265],
            [-115.7345, 32.9605],
            [-115.7791, 32.929],
            [-116.103, 32.9471],
            [-116.1033, 33.0747],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '05820',
        GEOID: '605820',
        NAME: 'Brawley Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2029304402,
        AWATER: 198655836,
        INTPTLAT: '+33.0859033',
        INTPTLON: '-115.6791989',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5036, 40.4461],
            [-122.5066, 40.5034],
            [-122.4267, 40.4971],
            [-122.4009, 40.5048],
            [-122.3672, 40.5055],
            [-122.3538, 40.5347],
            [-122.3148, 40.5493],
            [-122.2203, 40.506],
            [-122.1992, 40.4893],
            [-122.0473, 40.5326],
            [-122.0475, 40.4312],
            [-122.085, 40.4127],
            [-122.3104, 40.3711],
            [-122.52, 40.3937],
            [-122.6339, 40.3341],
            [-122.5997, 40.4452],
            [-122.5036, 40.4461],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '02700',
        GEOID: '602700',
        NAME: 'Anderson Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 615538430,
        AWATER: 7173748,
        INTPTLAT: '+40.4431202',
        INTPTLON: '-122.3156362',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2427, 37.19],
            [-122.1933, 37.1057],
            [-122.0986, 37.0263],
            [-122.0471, 37.0464],
            [-122.0055, 37.0421],
            [-121.9535, 37.0762],
            [-121.9464, 37.0685],
            [-121.8588, 37.0758],
            [-121.9192, 37.0453],
            [-121.9652, 37.043],
            [-121.995, 37.0081],
            [-121.9891, 36.9875],
            [-121.9375, 36.9572],
            [-121.9317, 36.8893],
            [-121.9938, 36.8408],
            [-122.0519, 36.8196],
            [-122.0764, 36.9102],
            [-122.2374, 36.9779],
            [-122.3139, 37.0548],
            [-122.2427, 37.19],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '35600',
        GEOID: '635600',
        NAME: 'Santa Cruz City High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362096057,
        AWATER: 279584881,
        INTPTLAT: '+37.0020733',
        INTPTLON: '-122.1134729',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.324, 38.2001],
            [-120.1766, 38.3735],
            [-120.02, 38.4335],
            [-119.8847, 38.3562],
            [-119.7535, 38.4168],
            [-119.6392, 38.3269],
            [-119.5474, 38.144],
            [-119.3498, 38.0856],
            [-119.3205, 37.9732],
            [-119.9059, 37.9787],
            [-119.9387, 37.891],
            [-120.2685, 37.8912],
            [-120.2819, 37.9549],
            [-120.2495, 38.0203],
            [-120.2582, 38.0347],
            [-120.3233, 38.0642],
            [-120.324, 38.2001],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '38280',
        GEOID: '638280',
        NAME: 'Summerville Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3282340327,
        AWATER: 46660141,
        INTPTLAT: '+38.1414340',
        INTPTLON: '-119.9276170',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7952, 38.9916],
            [-123.7861, 39.0921],
            [-123.6269, 39.0895],
            [-123.5065, 39.0232],
            [-123.4336, 38.9339],
            [-123.3852, 38.8841],
            [-123.3853, 38.777],
            [-123.2093, 38.8089],
            [-123.2452, 38.7047],
            [-123.1278, 38.6465],
            [-123.1271, 38.6172],
            [-123.1629, 38.6147],
            [-123.3567, 38.6245],
            [-123.4174, 38.5748],
            [-123.5106, 38.6809],
            [-123.6325, 38.7581],
            [-123.7847, 38.8937],
            [-123.7952, 38.9916],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '31230',
        GEOID: '631230',
        NAME: 'Point Arena Joint Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1044769014,
        AWATER: 422977638,
        INTPTLAT: '+38.8349084',
        INTPTLON: '-123.5185681',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3282, 37.8079],
            [-121.2807, 37.7108],
            [-121.3615, 37.7107],
            [-121.3981, 37.7106],
            [-121.3988, 37.8037],
            [-121.3282, 37.8079],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06031',
        GEOID: '606031',
        NAME: 'Tracy Unified School District (9-12) in Banta',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 95276207,
        AWATER: 3054995,
        INTPTLAT: '+37.7590922',
        INTPTLON: '-121.3564239',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.78, 37.4026],
            [-119.6512, 37.4178],
            [-119.652, 37.4979],
            [-119.5037, 37.6239],
            [-119.4645, 37.626],
            [-119.4624, 37.4002],
            [-119.5091, 37.2852],
            [-119.6549, 37.2397],
            [-119.6733, 37.3022],
            [-119.7639, 37.302],
            [-119.78, 37.4026],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06001',
        GEOID: '606001',
        NAME: 'Yosemite Unified School District in Bass Lake',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 721625325,
        AWATER: 4871530,
        INTPTLAT: '+37.4169095',
        INTPTLON: '-119.5990979',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9018, 37.5659],
            [-120.8652, 37.5367],
            [-120.9036, 37.5217],
            [-120.9214, 37.5336],
            [-120.9214, 37.5361],
            [-120.9487, 37.5364],
            [-120.9018, 37.5659],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06014',
        GEOID: '606014',
        NAME: 'Turlock Unified School District in Keyes Union',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 25291868,
        AWATER: 0,
        INTPTLAT: '+37.5477005',
        INTPTLON: '-120.9068907',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.557, 37.6706],
            [-121.557, 37.7077],
            [-121.4791, 37.7071],
            [-121.3981, 37.7106],
            [-121.3615, 37.7107],
            [-121.3609, 37.5698],
            [-121.4719, 37.4818],
            [-121.5567, 37.5427],
            [-121.557, 37.6706],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06032',
        GEOID: '606032',
        NAME: 'Tracy Unified School District (9-12) in Jefferson',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 356601145,
        AWATER: 61969,
        INTPTLAT: '+37.6257134',
        INTPTLON: '-121.4377868',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0546, 37.1821],
            [-120.0521, 37.1831],
            [-119.78, 37.4026],
            [-119.7639, 37.302],
            [-119.8368, 37.2389],
            [-119.8192, 37.1695],
            [-119.8378, 37.0104],
            [-119.8936, 37.0103],
            [-119.9103, 37.0542],
            [-120.0546, 37.0836],
            [-120.0546, 37.1821],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06002',
        GEOID: '606002',
        NAME: 'Yosemite Unified School District in Raymond-Knowles',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 502931732,
        AWATER: 11866102,
        INTPTLAT: '+37.1737072',
        INTPTLON: '-119.9030808',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.995, 37.0081],
            [-121.9652, 37.043],
            [-121.9192, 37.0453],
            [-121.9375, 36.9572],
            [-121.9891, 36.9875],
            [-121.995, 37.0081],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06015',
        GEOID: '606015',
        NAME: 'Santa Cruz City High School District (9-12) in Soquel',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 37770506,
        AWATER: 4034269,
        INTPTLAT: '+37.0013895',
        INTPTLON: '-121.9520594',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3615, 37.7107],
            [-121.2807, 37.7108],
            [-121.24, 37.6649],
            [-121.2439, 37.6619],
            [-121.3609, 37.5698],
            [-121.3615, 37.7107],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '06033',
        GEOID: '606033',
        NAME: 'Tracy Unified School District (9-12) in New Jerusalem',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 98778309,
        AWATER: 1170658,
        INTPTLAT: '+37.6469333',
        INTPTLON: '-121.3149687',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9917, 36.3384],
            [-118.9564, 36.4013],
            [-118.9811, 36.5666],
            [-118.2918, 36.5705],
            [-118.2489, 36.4865],
            [-118.1007, 36.3354],
            [-118.7835, 36.3341],
            [-118.8548, 36.2938],
            [-118.9917, 36.3384],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639210',
        NAME: 'Three Rivers Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1823021229,
        AWATER: 6070456,
        INTPTLAT: '+36.4369892',
        INTPTLON: '-118.6715322',
        ELSDLEA: '39210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1792, 35.8703],
            [-119.1614, 35.8774],
            [-119.0712, 35.7908],
            [-119.1967, 35.7905],
            [-119.1792, 35.8703],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632430',
        NAME: 'Richgrove Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68558614,
        AWATER: 0,
        INTPTLAT: '+35.8291108',
        INTPTLON: '-119.1403939',
        ELSDLEA: '32430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4112, 36.0511],
            [-119.286, 36.1016],
            [-119.2682, 36.0947],
            [-119.2146, 36.0658],
            [-119.2233, 36.0222],
            [-119.4109, 36.0075],
            [-119.4112, 36.0511],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639300',
        NAME: 'Tipton Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127153962,
        AWATER: 0,
        INTPTLAT: '+36.0477094',
        INTPTLON: '-119.3136421',
        ELSDLEA: '39300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1345, 36.3583],
            [-118.9564, 36.4013],
            [-118.9917, 36.3384],
            [-119.0633, 36.3129],
            [-119.1345, 36.3583],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636360',
        NAME: 'Sequoia Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91819336,
        AWATER: 3118304,
        INTPTLAT: '+36.3580610',
        INTPTLON: '-119.0522089',
        ELSDLEA: '36360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5029, 36.5307],
            [-119.4962, 36.5357],
            [-119.4576, 36.5246],
            [-119.4487, 36.5203],
            [-119.4219, 36.4728],
            [-119.476, 36.4886],
            [-119.5272, 36.4889],
            [-119.5029, 36.5307],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619740',
        NAME: 'Kings River Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35348028,
        AWATER: 48200,
        INTPTLAT: '+36.5011241',
        INTPTLON: '-119.4713050',
        ELSDLEA: '19740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1879, 36.1962],
            [-119.0859, 36.1672],
            [-119.0715, 36.1164],
            [-119.0803, 36.1164],
            [-119.1029, 36.0876],
            [-119.121, 36.0876],
            [-119.206, 36.1165],
            [-119.215, 36.1607],
            [-119.1879, 36.1962],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638430',
        NAME: 'Sunnyside Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95407916,
        AWATER: 3184,
        INTPTLAT: '+36.1458464',
        INTPTLON: '-119.1446304',
        ELSDLEA: '38430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4021, 36.1964],
            [-119.349, 36.2402],
            [-119.2961, 36.2404],
            [-119.2954, 36.1601],
            [-119.215, 36.1607],
            [-119.206, 36.1165],
            [-119.2682, 36.0947],
            [-119.286, 36.1016],
            [-119.384, 36.1748],
            [-119.4021, 36.1964],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639870',
        NAME: 'Tulare City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116668469,
        AWATER: 367457,
        INTPTLAT: '+36.1727062',
        INTPTLON: '-119.3115364',
        ELSDLEA: '39870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0859, 36.1672],
            [-118.9926, 36.2255],
            [-118.9013, 36.1953],
            [-118.9019, 36.1264],
            [-118.947, 36.1093],
            [-119.0715, 36.1164],
            [-119.0859, 36.1672],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638130',
        NAME: 'Strathmore Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167501158,
        AWATER: 57697,
        INTPTLAT: '+36.1670700',
        INTPTLON: '-118.9870628',
        ELSDLEA: '38130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.947, 36.1093],
            [-118.9019, 36.1264],
            [-118.7884, 36.0579],
            [-118.9268, 36.066],
            [-118.9467, 36.0802],
            [-118.947, 36.1093],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608730',
        NAME: 'Citrus South Tule Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63982396,
        AWATER: 8718342,
        INTPTLAT: '+36.0586542',
        INTPTLON: '-118.8695856',
        ELSDLEA: '08730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8367, 38.7341],
            [-120.7957, 38.7029],
            [-120.7787, 38.6219],
            [-120.8124, 38.5621],
            [-120.8912, 38.5283],
            [-120.8903, 38.584],
            [-120.9092, 38.7003],
            [-120.91, 38.7381],
            [-120.8367, 38.7341],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625980',
        NAME: 'Mother Lode Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160524407,
        AWATER: 333421,
        INTPTLAT: '+38.6325316',
        INTPTLON: '-120.8440154',
        ELSDLEA: '25980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1186, 38.7171],
            [-121.0075, 38.7637],
            [-120.91, 38.7381],
            [-120.9092, 38.7003],
            [-121.097, 38.6718],
            [-121.1186, 38.7171],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632310',
        NAME: 'Rescue Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125743264,
        AWATER: 6576959,
        INTPTLAT: '+38.7220637',
        INTPTLON: '-121.0109212',
        ELSDLEA: '32310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0075, 38.7637],
            [-120.9042, 38.8272],
            [-120.8319, 38.7696],
            [-120.8367, 38.7341],
            [-120.91, 38.7381],
            [-121.0075, 38.7637],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615450',
        NAME: 'Gold Trail Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121530615,
        AWATER: 0,
        INTPTLAT: '+38.7843845',
        INTPTLON: '-120.9171354',
        ELSDLEA: '15450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4597, 38.8357],
            [-120.2698, 38.8364],
            [-120.2687, 38.8825],
            [-120.15, 38.8784],
            [-120.1154, 38.7667],
            [-120.1466, 38.6864],
            [-120.2643, 38.7505],
            [-120.4625, 38.7515],
            [-120.4597, 38.8357],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636810',
        NAME: 'Silver Fork Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391847286,
        AWATER: 5276707,
        INTPTLAT: '+38.7970942',
        INTPTLON: '-120.2567848',
        ELSDLEA: '36810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9092, 38.7003],
            [-120.8903, 38.584],
            [-121.0793, 38.6329],
            [-121.097, 38.6718],
            [-120.9092, 38.7003],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606270',
        NAME: 'Buckeye Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146832497,
        AWATER: 133165,
        INTPTLAT: '+38.6469973',
        INTPTLON: '-120.9678147',
        ELSDLEA: '06270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8367, 38.7341],
            [-120.8319, 38.7696],
            [-120.7861, 38.839],
            [-120.6928, 38.867],
            [-120.7324, 38.7377],
            [-120.7957, 38.7029],
            [-120.8367, 38.7341],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630780',
        NAME: 'Placerville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124920012,
        AWATER: 528129,
        INTPTLAT: '+38.7916753',
        INTPTLON: '-120.7457610',
        ELSDLEA: '30780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-120.459, 38.8867],
              [-120.2687, 38.8825],
              [-120.2698, 38.8364],
              [-120.4597, 38.8357],
              [-120.459, 38.8867],
            ],
          ],
          [
            [
              [-120.4597, 38.8357],
              [-120.4625, 38.7515],
              [-120.2643, 38.7505],
              [-120.1466, 38.6864],
              [-120.1221, 38.6686],
              [-120.153, 38.6372],
              [-120.4283, 38.6791],
              [-120.5065, 38.7219],
              [-120.6117, 38.6812],
              [-120.6394, 38.7439],
              [-120.636, 38.7917],
              [-120.4597, 38.8357],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631290',
        NAME: 'Pollock Pines Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 502820108,
        AWATER: 12946730,
        INTPTLAT: '+38.6983239',
        INTPTLON: '-120.2719956',
        ELSDLEA: '31290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7957, 38.7029],
            [-120.7324, 38.7377],
            [-120.6394, 38.7439],
            [-120.6117, 38.6812],
            [-120.7787, 38.6219],
            [-120.7957, 38.7029],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615390',
        NAME: 'Gold Oak Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134682542,
        AWATER: 64065,
        INTPTLAT: '+38.6847730',
        INTPTLON: '-120.7210866',
        ELSDLEA: '15390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8903, 38.584],
            [-120.8912, 38.5283],
            [-121.0275, 38.5083],
            [-121.0542, 38.57],
            [-121.0793, 38.6329],
            [-120.8903, 38.584],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621180',
        NAME: 'Latrobe Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143176504,
        AWATER: 0,
        INTPTLAT: '+38.5691594',
        INTPTLON: '-120.9829562',
        ELSDLEA: '21180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2326, 38.5935],
            [-120.2745, 38.5595],
            [-120.5652, 38.5081],
            [-120.6127, 38.6088],
            [-120.4096, 38.5614],
            [-120.2326, 38.5935],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618240',
        NAME: 'Indian Diggings Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162047691,
        AWATER: 7661,
        INTPTLAT: '+38.5575379',
        INTPTLON: '-120.4843801',
        ELSDLEA: '18240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6928, 38.867],
            [-120.6886, 38.9117],
            [-120.459, 38.8867],
            [-120.4597, 38.8357],
            [-120.636, 38.7917],
            [-120.6394, 38.7439],
            [-120.7324, 38.7377],
            [-120.6928, 38.867],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607170',
        NAME: 'Camino Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268232986,
        AWATER: 2345126,
        INTPTLAT: '+38.8265969',
        INTPTLON: '-120.6269859',
        ELSDLEA: '07170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6169, 38.0205],
            [-122.5576, 37.9933],
            [-122.5509, 37.9723],
            [-122.6017, 37.9628],
            [-122.6274, 37.9954],
            [-122.6169, 38.0205],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600006',
        NAME: 'Ross Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32811473,
        AWATER: 0,
        INTPTLAT: '+37.9937178',
        INTPTLON: '-122.5927749',
        ELSDLEA: '00006',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.084, 37.9838],
            [-122.7751, 37.9838],
            [-122.7253, 38.0015],
            [-122.6844, 37.9619],
            [-122.6159, 37.9091],
            [-122.6324, 37.8277],
            [-122.7525, 37.8577],
            [-122.8893, 37.9741],
            [-123.0225, 37.9421],
            [-123.084, 37.9838],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605520',
        NAME: 'Bolinas-Stinson Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97473131,
        AWATER: 233158318,
        INTPTLAT: '+37.9786610',
        INTPTLON: '-122.7928941',
        ELSDLEA: '05520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6298, 38.0717],
            [-122.5193, 38.0399],
            [-122.3828, 38.0398],
            [-122.4013, 38.0179],
            [-122.5576, 37.9933],
            [-122.6169, 38.0205],
            [-122.6339, 38.0256],
            [-122.6298, 38.0717],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611220',
        NAME: 'Dixie Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48562526,
        AWATER: 23140760,
        INTPTLAT: '+38.0285166',
        INTPTLON: '-122.5292892',
        ELSDLEA: '11220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6274, 37.9954],
            [-122.6017, 37.9628],
            [-122.5332, 37.9602],
            [-122.5135, 37.9431],
            [-122.5603, 37.9294],
            [-122.6159, 37.9091],
            [-122.6844, 37.9619],
            [-122.6274, 37.9954],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619380',
        NAME: 'Kentfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57160901,
        AWATER: 1787420,
        INTPTLAT: '+37.9571528',
        INTPTLON: '-122.6012118',
        ELSDLEA: '19380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.6512, 38.2124],
              [-122.647, 38.1812],
              [-122.6831, 38.1917],
              [-122.6512, 38.2124],
            ],
          ],
          [
            [
              [-122.7871, 38.239],
              [-122.7189, 38.2186],
              [-122.6941, 38.1951],
              [-122.7045, 38.1745],
              [-122.7662, 38.1262],
              [-122.7751, 38.1184],
              [-122.8416, 38.1642],
              [-122.7871, 38.239],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620430',
        NAME: 'Laguna Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94964183,
        AWATER: 774767,
        INTPTLAT: '+38.1924205',
        INTPTLON: '-122.7745900',
        ELSDLEA: '20430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7253, 38.0015],
            [-122.6977, 38.0335],
            [-122.6339, 38.0256],
            [-122.6169, 38.0205],
            [-122.6274, 37.9954],
            [-122.6844, 37.9619],
            [-122.7253, 38.0015],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620520',
        NAME: 'Lagunitas Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41420308,
        AWATER: 1138280,
        INTPTLAT: '+38.0076912',
        INTPTLON: '-122.6721839',
        ELSDLEA: '20520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5135, 37.9431],
            [-122.435, 37.9368],
            [-122.4323, 37.9298],
            [-122.4302, 37.9184],
            [-122.503, 37.9093],
            [-122.5603, 37.9294],
            [-122.5135, 37.9431],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620910',
        NAME: 'Larkspur Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13074443,
        AWATER: 11862892,
        INTPTLAT: '+37.9241392',
        INTPTLON: '-122.4993659',
        ELSDLEA: '20910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7045, 38.1745],
            [-122.6491, 38.1475],
            [-122.646, 38.1055],
            [-122.7662, 38.1262],
            [-122.7045, 38.1745],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621660',
        NAME: 'Lincoln Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74376415,
        AWATER: 558767,
        INTPTLAT: '+38.1431735',
        INTPTLON: '-122.6905381',
        ELSDLEA: '21660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6324, 37.8277],
            [-122.6159, 37.9091],
            [-122.5603, 37.9294],
            [-122.503, 37.9093],
            [-122.4914, 37.8725],
            [-122.6123, 37.8152],
            [-122.6324, 37.8277],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624870',
        NAME: 'Mill Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53927689,
        AWATER: 17955056,
        INTPTLAT: '+37.8792333',
        INTPTLON: '-122.5684622',
        ELSDLEA: '24870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7751, 38.1184],
            [-122.7662, 38.1262],
            [-122.646, 38.1055],
            [-122.6298, 38.0717],
            [-122.6339, 38.0256],
            [-122.6977, 38.0335],
            [-122.7751, 38.1184],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627300',
        NAME: 'Nicasio Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89601154,
        AWATER: 3591545,
        INTPTLAT: '+38.0928857',
        INTPTLON: '-122.7084152',
        ELSDLEA: '27300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3923, 35.9057],
            [-119.3576, 35.9357],
            [-119.2148, 35.9355],
            [-119.1617, 35.9355],
            [-119.1616, 35.9209],
            [-119.1614, 35.8774],
            [-119.1792, 35.8703],
            [-119.2414, 35.7905],
            [-119.3295, 35.7902],
            [-119.3587, 35.7902],
            [-119.3922, 35.8771],
            [-119.3923, 35.9057],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611760',
        NAME: 'Earlimart Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233247305,
        AWATER: 266703,
        INTPTLAT: '+35.8736662',
        INTPTLON: '-119.2815507',
        ELSDLEA: '11760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4109, 36.0075],
            [-119.2233, 36.0222],
            [-119.2146, 36.015],
            [-119.2148, 35.9355],
            [-119.3576, 35.9357],
            [-119.3923, 35.9057],
            [-119.4639, 35.9491],
            [-119.4109, 36.0075],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630630',
        NAME: 'Pixley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195221736,
        AWATER: 84940,
        INTPTLAT: '+35.9715060',
        INTPTLON: '-119.3309140',
        ELSDLEA: '30630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1616, 35.9209],
            [-118.8411, 35.9355],
            [-118.8512, 35.8773],
            [-118.6803, 35.8334],
            [-118.6811, 35.7899],
            [-118.7442, 35.7902],
            [-118.9382, 35.7905],
            [-119.0712, 35.7908],
            [-119.1614, 35.8774],
            [-119.1616, 35.9209],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611550',
        NAME: 'Ducor Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514904168,
        AWATER: 0,
        INTPTLAT: '+35.8551429',
        INTPTLON: '-118.9260341',
        ELSDLEA: '11550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2682, 36.0947],
            [-119.206, 36.1165],
            [-119.121, 36.0876],
            [-119.1432, 36.0659],
            [-119.2146, 36.0658],
            [-119.2682, 36.0947],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643170',
        NAME: 'Woodville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49342459,
        AWATER: 33015,
        INTPTLAT: '+36.0907710',
        INTPTLON: '-119.1855297',
        ELSDLEA: '43170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1617, 35.9355],
            [-119.1614, 36.0079],
            [-119.1254, 36.0079],
            [-119.1074, 36.0078],
            [-119.0178, 35.9948],
            [-118.841, 35.9521],
            [-118.8411, 35.9355],
            [-119.1616, 35.9209],
            [-119.1617, 35.9355],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639060',
        NAME: 'Terra Bella Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193446838,
        AWATER: 0,
        INTPTLAT: '+35.9585365',
        INTPTLON: '-119.0273311',
        ELSDLEA: '39060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9013, 36.1953],
            [-118.8548, 36.2938],
            [-118.7835, 36.3341],
            [-118.1007, 36.3354],
            [-118.1276, 36.2803],
            [-118.0402, 36.0324],
            [-118.6269, 36.0388],
            [-118.6805, 36.1091],
            [-118.7884, 36.0579],
            [-118.9019, 36.1264],
            [-118.9013, 36.1953],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637770',
        NAME: 'Springville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2237028638,
        AWATER: 559124,
        INTPTLAT: '+36.1727771',
        INTPTLON: '-118.4920872',
        ELSDLEA: '37770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1432, 36.0659],
            [-119.121, 36.0876],
            [-119.1029, 36.0876],
            [-119.0796, 36.0638],
            [-119.1075, 36.0223],
            [-119.1074, 36.0078],
            [-119.1254, 36.0079],
            [-119.1432, 36.0659],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633270',
        NAME: 'Rockford Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27455981,
        AWATER: 162639,
        INTPTLAT: '+36.0549642',
        INTPTLON: '-119.1163598',
        ELSDLEA: '33270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8411, 35.9355],
            [-118.841, 35.9521],
            [-118.6339, 35.9813],
            [-118.0101, 35.9776],
            [-117.9825, 35.8958],
            [-118.008, 35.7892],
            [-118.0198, 35.7898],
            [-118.361, 35.7906],
            [-118.5739, 35.7897],
            [-118.6803, 35.8334],
            [-118.8512, 35.8773],
            [-118.8411, 35.9355],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617640',
        NAME: 'Hot Springs Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1378944103,
        AWATER: 0,
        INTPTLAT: '+35.8907871',
        INTPTLON: '-118.3755646',
        ELSDLEA: '17640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6203, 37.8003],
            [-121.5569, 37.8172],
            [-121.557, 37.7077],
            [-121.557, 37.6706],
            [-121.6025, 37.6705],
            [-121.6203, 37.8003],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626130',
        NAME: 'Mountain House Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70915952,
        AWATER: 1137220,
        INTPTLAT: '+37.7473502',
        INTPTLON: '-121.5819582',
        ELSDLEA: '26130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7932, 37.9586],
            [-120.7907, 38.0886],
            [-120.5692, 38.1067],
            [-120.4974, 38.1466],
            [-120.4308, 38.0668],
            [-120.5336, 37.9451],
            [-120.6527, 37.8314],
            [-120.7473, 37.9161],
            [-120.7932, 37.9586],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623970',
        NAME: 'Mark Twain Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585427641,
        AWATER: 11256049,
        INTPTLAT: '+38.0233784',
        INTPTLON: '-120.6300276',
        ELSDLEA: '23970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4974, 38.1466],
            [-120.3982, 38.1903],
            [-120.3959, 38.2934],
            [-120.2636, 38.3177],
            [-120.2644, 38.4827],
            [-120.0725, 38.5099],
            [-120.02, 38.4335],
            [-120.1766, 38.3735],
            [-120.324, 38.2001],
            [-120.4308, 38.0668],
            [-120.4974, 38.1466],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640680',
        NAME: 'Vallecito Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516148053,
        AWATER: 2203510,
        INTPTLAT: '+38.3121386',
        INTPTLON: '-120.2782845',
        ELSDLEA: '40680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5509, 37.9723],
            [-122.5332, 37.9602],
            [-122.6017, 37.9628],
            [-122.5509, 37.9723],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633660',
        NAME: 'Ross Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6178598,
        AWATER: 17048,
        INTPTLAT: '+37.9635727',
        INTPTLON: '-122.5641443',
        ELSDLEA: '33660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5576, 37.9933],
            [-122.4013, 38.0179],
            [-122.435, 37.9368],
            [-122.5135, 37.9431],
            [-122.5332, 37.9602],
            [-122.5509, 37.9723],
            [-122.5576, 37.9933],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635090',
        NAME: 'San Rafael City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44666328,
        AWATER: 37855929,
        INTPTLAT: '+37.9809122',
        INTPTLON: '-122.4816980',
        ELSDLEA: '35090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4914, 37.8725],
            [-122.4336, 37.8469],
            [-122.6123, 37.8152],
            [-122.4914, 37.8725],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636000',
        NAME: 'Sausalito Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32918245,
        AWATER: 23372291,
        INTPTLAT: '+37.8486405',
        INTPTLON: '-122.5156922',
        ELSDLEA: '36000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7045, 38.1745],
            [-122.6941, 38.1951],
            [-122.6831, 38.1917],
            [-122.647, 38.1812],
            [-122.6451, 38.1811],
            [-122.6491, 38.1475],
            [-122.7045, 38.1745],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640410',
        NAME: 'Union Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20270315,
        AWATER: 47000,
        INTPTLAT: '+38.1639231',
        INTPTLON: '-122.6593677',
        ELSDLEA: '40410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8124, 38.5621],
            [-120.7787, 38.6219],
            [-120.6117, 38.6812],
            [-120.5065, 38.7219],
            [-120.4283, 38.6791],
            [-120.153, 38.6372],
            [-120.2326, 38.5935],
            [-120.4096, 38.5614],
            [-120.6127, 38.6088],
            [-120.5652, 38.5081],
            [-120.6284, 38.5032],
            [-120.8124, 38.5621],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630480',
        NAME: 'Pioneer Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 599380124,
        AWATER: 148996,
        INTPTLAT: '+38.6163499',
        INTPTLON: '-120.5238525',
        ELSDLEA: '30480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0962, 39.7438],
            [-122.0809, 39.6524],
            [-122.1408, 39.6526],
            [-122.0962, 39.7438],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630870',
        NAME: 'Plaza Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64817033,
        AWATER: 17206,
        INTPTLAT: '+39.7024117',
        INTPTLON: '-122.0932716',
        ELSDLEA: '30870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1597, 39.7975],
            [-122.141, 39.7976],
            [-122.1248, 39.7975],
            [-122.1009, 39.7517],
            [-122.0999, 39.7498],
            [-122.1597, 39.7975],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620610',
        NAME: 'Lake Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28042838,
        AWATER: 225166,
        INTPTLAT: '+39.7716524',
        INTPTLON: '-122.1457107',
        ELSDLEA: '20610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1958, 40.6509],
            [-124.0941, 40.6066],
            [-124.094, 40.512],
            [-124.094, 40.5058],
            [-124.1518, 40.5111],
            [-124.1959, 40.6134],
            [-124.1958, 40.6509],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601420',
        NAME: 'Fortuna Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75335372,
        AWATER: 1217522,
        INTPTLAT: '+40.5870861',
        INTPTLON: '-124.1431065',
        ELSDLEA: '01420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.141, 39.7976],
            [-122.0645, 39.8336],
            [-122.0465, 39.7976],
            [-122.0228, 39.7664],
            [-122.1009, 39.7517],
            [-122.1248, 39.7975],
            [-122.141, 39.7976],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607410',
        NAME: 'Capay Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59843696,
        AWATER: 1334643,
        INTPTLAT: '+39.7933185',
        INTPTLON: '-122.0946666',
        ELSDLEA: '07410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1792, 35.8703],
            [-119.1967, 35.7905],
            [-119.2414, 35.7905],
            [-119.1792, 35.8703],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609510',
        NAME: 'Columbine Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45384165,
        AWATER: 0,
        INTPTLAT: '+35.8308086',
        INTPTLON: '-119.2104356',
        ELSDLEA: '09510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.234, 36.2759],
            [-119.2251, 36.2832],
            [-119.1765, 36.2825],
            [-119.188, 36.2184],
            [-119.234, 36.2759],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629160',
        NAME: 'Outside Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39229412,
        AWATER: 0,
        INTPTLAT: '+36.2563219',
        INTPTLON: '-119.1940883',
        ELSDLEA: '29160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4302, 37.9184],
            [-122.4336, 37.8469],
            [-122.4914, 37.8725],
            [-122.503, 37.9093],
            [-122.4302, 37.9184],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632160',
        NAME: 'Reed Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17783611,
        AWATER: 26691699,
        INTPTLAT: '+37.8871660',
        INTPTLON: '-122.4582910',
        ELSDLEA: '32160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1952, 36.5137],
            [-119.2062, 36.4284],
            [-119.2323, 36.4576],
            [-119.1952, 36.5137],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638040',
        NAME: 'Stone Corral Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50050938,
        AWATER: 0,
        INTPTLAT: '+36.4785925',
        INTPTLON: '-119.1969249',
        ELSDLEA: '38040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4556, 33.8614],
            [-118.3914, 33.8728],
            [-118.4453, 33.8431],
            [-118.4556, 33.8614],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617040',
        NAME: 'Hermosa Beach City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3694271,
        AWATER: 11667552,
        INTPTLAT: '+33.8696728',
        INTPTLON: '-118.4052294',
        ELSDLEA: '17040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0736, 34.0905],
            [-118.0549, 34.0897],
            [-118.0717, 34.0627],
            [-118.0908, 34.0802],
            [-118.0736, 34.0905],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633570',
        NAME: 'Rosemead Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8032729,
        AWATER: 0,
        INTPTLAT: '+34.0792437',
        INTPTLON: '-118.0732948',
        ELSDLEA: '33570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1083, 34.0771],
            [-118.0908, 34.0802],
            [-118.0717, 34.0627],
            [-118.0731, 34.0449],
            [-118.075, 34.0407],
            [-118.1167, 34.043],
            [-118.1083, 34.0771],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614940',
        NAME: 'Garvey Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11666276,
        AWATER: 120610,
        INTPTLAT: '+34.0587656',
        INTPTLON: '-118.0964295',
        ELSDLEA: '14940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3613, 33.9063],
            [-118.3265, 33.9019],
            [-118.3265, 33.8874],
            [-118.3523, 33.8742],
            [-118.3698, 33.8946],
            [-118.3613, 33.9063],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621210',
        NAME: 'Lawndale Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9454269,
        AWATER: 87869,
        INTPTLAT: '+33.8892514',
        INTPTLON: '-118.3460941',
        ELSDLEA: '21210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.79, 32.8738],
            [-116.7902, 32.9066],
            [-116.7191, 32.9059],
            [-116.6685, 32.9054],
            [-116.667, 32.8157],
            [-116.6229, 32.7317],
            [-116.7077, 32.7277],
            [-116.7669, 32.7884],
            [-116.8494, 32.8124],
            [-116.79, 32.8738],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602100',
        NAME: 'Alpine Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246383969,
        AWATER: 2313162,
        INTPTLAT: '+32.8259683',
        INTPTLON: '-116.7019634',
        ELSDLEA: '02100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2969, 33.2792],
            [-117.2127, 33.2998],
            [-117.1389, 33.3734],
            [-117.0943, 33.3882],
            [-117.0523, 33.3151],
            [-117.1407, 33.2938],
            [-117.1423, 33.2245],
            [-117.1593, 33.2268],
            [-117.1909, 33.2275],
            [-117.2751, 33.2575],
            [-117.2969, 33.2792],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605670',
        NAME: 'Bonsall Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180308379,
        AWATER: 1661528,
        INTPTLAT: '+33.3088899',
        INTPTLON: '-117.1575616',
        ELSDLEA: '05670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9885, 32.8265],
            [-116.9438, 32.8242],
            [-116.79, 32.8738],
            [-116.8494, 32.8124],
            [-116.8767, 32.7437],
            [-116.9147, 32.6846],
            [-116.9631, 32.7049],
            [-116.9885, 32.8265],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606810',
        NAME: 'Cajon Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168052862,
        AWATER: 3019623,
        INTPTLAT: '+32.7891191',
        INTPTLON: '-116.9045932',
        ELSDLEA: '06810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3637, 33.0355],
            [-117.2553, 33.0114],
            [-117.3525, 32.9987],
            [-117.3637, 33.0355],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607470',
        NAME: 'Cardiff Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7562770,
        AWATER: 24238472,
        INTPTLAT: '+33.0342639',
        INTPTLON: '-117.2923008',
        ELSDLEA: '07470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0654, 32.6624],
            [-117.017, 32.6913],
            [-116.9631, 32.7049],
            [-116.9147, 32.6846],
            [-116.8228, 32.6595],
            [-116.8222, 32.5603],
            [-116.8928, 32.5539],
            [-116.893, 32.6109],
            [-117.0567, 32.5681],
            [-117.1016, 32.6121],
            [-117.0654, 32.6624],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608610',
        NAME: 'Chula Vista Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256854342,
        AWATER: 12140337,
        INTPTLAT: '+32.6268673',
        INTPTLON: '-116.9635078',
        ELSDLEA: '08610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8494, 32.8124],
            [-116.7669, 32.7884],
            [-116.8767, 32.7437],
            [-116.8494, 32.8124],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610710',
        NAME: 'Dehesa Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52135347,
        AWATER: 1122033,
        INTPTLAT: '+32.7794645',
        INTPTLON: '-116.8326040',
        ELSDLEA: '10710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3457, 32.9766],
            [-117.173, 32.9702],
            [-117.1709, 32.9326],
            [-117.3344, 32.9396],
            [-117.3457, 32.9766],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610740',
        NAME: 'Del Mar Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38756195,
        AWATER: 29368288,
        INTPTLAT: '+32.9482673',
        INTPTLON: '-117.2549186',
        ELSDLEA: '10740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3759, 33.0752],
            [-117.2613, 33.0921],
            [-117.1959, 33.0877],
            [-117.2419, 33.0212],
            [-117.2553, 33.0114],
            [-117.3637, 33.0355],
            [-117.3759, 33.0752],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612750',
        NAME: 'Encinitas Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60060499,
        AWATER: 32884931,
        INTPTLAT: '+33.0606687',
        INTPTLON: '-117.2821525',
        ELSDLEA: '12750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1567, 33.0945],
            [-117.1147, 33.1268],
            [-117.1593, 33.2268],
            [-117.1423, 33.2245],
            [-116.9986, 33.1534],
            [-117.0316, 33.0668],
            [-117.1483, 33.0363],
            [-117.1527, 33.04],
            [-117.1567, 33.0945],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612880',
        NAME: 'Escondido Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197223789,
        AWATER: 3109514,
        INTPTLAT: '+33.1338315',
        INTPTLON: '-117.0856960',
        ELSDLEA: '12880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5097, 33.505],
            [-117.3709, 33.4905],
            [-117.1932, 33.4322],
            [-117.1389, 33.3734],
            [-117.2127, 33.2998],
            [-117.2969, 33.2792],
            [-117.3492, 33.3439],
            [-117.4164, 33.2596],
            [-117.5121, 33.2562],
            [-117.6111, 33.334],
            [-117.5785, 33.4539],
            [-117.5097, 33.505],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613500',
        NAME: 'Fallbrook Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 665324836,
        AWATER: 99537092,
        INTPTLAT: '+33.3802376',
        INTPTLON: '-117.3901004',
        ELSDLEA: '13500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9147, 32.6846],
            [-116.8767, 32.7437],
            [-116.7669, 32.7884],
            [-116.7077, 32.7277],
            [-116.6229, 32.7317],
            [-116.6235, 32.6545],
            [-116.6894, 32.5712],
            [-116.8222, 32.5603],
            [-116.8228, 32.6595],
            [-116.9147, 32.6846],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618730',
        NAME: 'Jamul-Dulzura Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 419023850,
        AWATER: 3784050,
        INTPTLAT: '+32.6938677',
        INTPTLON: '-116.7753372',
        ELSDLEA: '18730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7716, 33.2558],
            [-116.667, 33.1516],
            [-116.6681, 33.0801],
            [-116.6157, 33.1051],
            [-116.6461, 33.1375],
            [-116.1845, 33.1327],
            [-116.1033, 33.0747],
            [-116.103, 32.9471],
            [-116.1031, 32.9041],
            [-116.6685, 32.9054],
            [-116.7191, 32.9059],
            [-116.7208, 33.1237],
            [-116.823, 33.1226],
            [-116.8766, 33.1828],
            [-116.7716, 33.2558],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619140',
        NAME: 'Julian Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1599591429,
        AWATER: 3887780,
        INTPTLAT: '+33.0470228',
        INTPTLON: '-116.4590731',
        ELSDLEA: '19140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0064, 32.8137],
            [-116.9885, 32.8265],
            [-116.9631, 32.7049],
            [-117.017, 32.6913],
            [-117.023, 32.719],
            [-117.0206, 32.7497],
            [-117.0496, 32.7554],
            [-117.0064, 32.8137],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620250',
        NAME: 'La Mesa-Spring Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64297346,
        AWATER: 4126716,
        INTPTLAT: '+32.7487582',
        INTPTLON: '-116.9958334',
        ELSDLEA: '20250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9621, 32.9628],
            [-116.7887, 32.9637],
            [-116.7902, 32.9066],
            [-116.79, 32.8738],
            [-116.9438, 32.8242],
            [-116.9637, 32.9031],
            [-116.9621, 32.9628],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620790',
        NAME: 'Lakeside Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 175436343,
        AWATER: 7085107,
        INTPTLAT: '+32.9006100',
        INTPTLON: '-116.8808603',
        ELSDLEA: '20790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0496, 32.7554],
            [-117.0206, 32.7497],
            [-117.023, 32.719],
            [-117.0496, 32.7554],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621330',
        NAME: 'Lemon Grove Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13934697,
        AWATER: 0,
        INTPTLAT: '+32.7350869',
        INTPTLON: '-117.0366715',
        ELSDLEA: '21330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0654, 32.6624],
            [-117.1016, 32.6121],
            [-117.1085, 32.6039],
            [-117.0654, 32.6624],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626670',
        NAME: 'National Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19628157,
        AWATER: 4296057,
        INTPTLAT: '+32.6658617',
        INTPTLON: '-117.0973610',
        ELSDLEA: '26670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1959, 33.0877],
            [-117.1567, 33.0945],
            [-117.1527, 33.04],
            [-117.2419, 33.0212],
            [-117.1959, 33.0877],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631740',
        NAME: 'Rancho Santa Fe Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48062845,
        AWATER: 188500,
        INTPTLAT: '+33.0409579',
        INTPTLON: '-117.1997907',
        ELSDLEA: '31740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9986, 33.1534],
            [-116.9183, 33.0805],
            [-116.9966, 33.0521],
            [-117.0316, 33.0668],
            [-116.9986, 33.1534],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635040',
        NAME: 'San Pasqual Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75230177,
        AWATER: 31817,
        INTPTLAT: '+33.0893848',
        INTPTLON: '-116.9853528',
        ELSDLEA: '35040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0567, 32.5681],
            [-116.893, 32.6109],
            [-116.8928, 32.5539],
            [-117.0667, 32.5395],
            [-117.0567, 32.5681],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635220',
        NAME: 'San Ysidro Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74441269,
        AWATER: 718535,
        INTPTLAT: '+32.5754926',
        INTPTLON: '-116.9784789',
        ELSDLEA: '35220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0318, 32.8909],
            [-116.9637, 32.9031],
            [-116.9438, 32.8242],
            [-116.9885, 32.8265],
            [-117.0064, 32.8137],
            [-117.0318, 32.8909],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635880',
        NAME: 'Santee Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67511140,
        AWATER: 871778,
        INTPTLAT: '+32.8573745',
        INTPTLON: '-116.9981939',
        ELSDLEA: '35880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1873, 33.7867],
            [-117.0849, 33.7573],
            [-117.1365, 33.6996],
            [-117.2408, 33.7285],
            [-117.1873, 33.7867],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633390',
        NAME: 'Romoland Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80712402,
        AWATER: 0,
        INTPTLAT: '+33.7442890',
        INTPTLON: '-117.1582982',
        ELSDLEA: '33390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1912, 33.8084],
            [-117.1389, 33.859],
            [-117.1211, 33.9031],
            [-117.0511, 33.8456],
            [-117.0509, 33.7875],
            [-117.0849, 33.7573],
            [-117.1873, 33.7867],
            [-117.1912, 33.8084],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627780',
        NAME: 'Nuview Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111296584,
        AWATER: 677928,
        INTPTLAT: '+33.8160205',
        INTPTLON: '-117.1115181',
        ELSDLEA: '27780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2668, 33.7282],
            [-117.2408, 33.7285],
            [-117.1365, 33.6996],
            [-117.1012, 33.6163],
            [-117.106, 33.6126],
            [-117.206, 33.6119],
            [-117.2668, 33.7282],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624540',
        NAME: 'Menifee Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151558746,
        AWATER: 449104,
        INTPTLAT: '+33.6659701',
        INTPTLON: '-117.1730163',
        ELSDLEA: '24540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3626, 33.8],
            [-117.1912, 33.8084],
            [-117.1873, 33.7867],
            [-117.2408, 33.7285],
            [-117.2668, 33.7282],
            [-117.4147, 33.7712],
            [-117.3626, 33.8],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630180',
        NAME: 'Perris Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126795180,
        AWATER: 41199,
        INTPTLAT: '+33.7737016',
        INTPTLON: '-117.2937067',
        ELSDLEA: '30180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0424, 33.9686],
            [-118.003, 33.9893],
            [-117.9767, 33.9748],
            [-117.9943, 33.9172],
            [-118.0232, 33.9241],
            [-118.0507, 33.9588],
            [-118.0424, 33.9686],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611850',
        NAME: 'East Whittier City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32658867,
        AWATER: 26528,
        INTPTLAT: '+33.9544310',
        INTPTLON: '-118.0091534',
        ELSDLEA: '11850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9767, 33.9748],
            [-117.9344, 33.9729],
            [-117.9258, 33.946],
            [-117.9708, 33.9091],
            [-117.9766, 33.9088],
            [-117.9943, 33.9172],
            [-117.9767, 33.9748],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623010',
        NAME: 'Lowell Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24937229,
        AWATER: 4740,
        INTPTLAT: '+33.9483811',
        INTPTLON: '-117.9605005',
        ELSDLEA: '23010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.06, 33.9465],
            [-118.0507, 33.9588],
            [-118.0232, 33.9241],
            [-118.0604, 33.9171],
            [-118.06, 33.9465],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637560',
        NAME: 'South Whittier Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10547691,
        AWATER: 22187,
        INTPTLAT: '+33.9336455',
        INTPTLON: '-118.0452649',
        ELSDLEA: '37560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0819, 33.9774],
            [-118.0542, 34.0207],
            [-118.0299, 34.0366],
            [-118.0098, 34.0407],
            [-117.9953, 34.0343],
            [-118.003, 33.9893],
            [-118.0424, 33.9686],
            [-118.0819, 33.9774],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642450',
        NAME: 'Whittier City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38232886,
        AWATER: 381187,
        INTPTLAT: '+34.0007242',
        INTPTLON: '-118.0370981',
        ELSDLEA: '42450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3657, 33.9309],
            [-118.3439, 33.9309],
            [-118.3265, 33.9237],
            [-118.3265, 33.9019],
            [-118.3613, 33.9063],
            [-118.3657, 33.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616680',
        NAME: 'Hawthorne Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10162871,
        AWATER: 3818,
        INTPTLAT: '+33.9158857',
        INTPTLON: '-118.3452711',
        ELSDLEA: '16680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9984, 34.0743],
            [-118.0026, 34.0606],
            [-118.0098, 34.0407],
            [-118.0299, 34.0366],
            [-118.0556, 34.0488],
            [-117.9984, 34.0743],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626190',
        NAME: 'Mountain View Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 11747665,
        AWATER: 703205,
        INTPTLAT: '+34.0567727',
        INTPTLON: '-118.0215082',
        ELSDLEA: '26190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0717, 34.0627],
            [-118.0549, 34.0897],
            [-118.0499, 34.0965],
            [-118.0184, 34.1091],
            [-118.0045, 34.1111],
            [-117.9809, 34.1091],
            [-117.9984, 34.0743],
            [-118.0556, 34.0488],
            [-118.0731, 34.0449],
            [-118.0717, 34.0627],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612090',
        NAME: 'El Monte City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27209414,
        AWATER: 2127780,
        INTPTLAT: '+34.0833101',
        INTPTLON: '-118.0290541',
        ELSDLEA: '12090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0423, 34.822],
            [-118.0234, 34.8222],
            [-117.6673, 34.8225],
            [-117.6671, 34.6902],
            [-117.8807, 34.6901],
            [-117.8801, 34.6322],
            [-117.9873, 34.6313],
            [-118.0948, 34.6311],
            [-118.095, 34.7188],
            [-118.0423, 34.822],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611910',
        NAME: 'Eastside Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657928345,
        AWATER: 478909,
        INTPTLAT: '+34.7406414',
        INTPTLON: '-117.8995278',
        ELSDLEA: '11910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6071, 34.4353],
            [-118.4844, 34.396],
            [-118.4487, 34.3528],
            [-118.6509, 34.3205],
            [-118.6776, 34.375],
            [-118.6914, 34.403],
            [-118.6071, 34.4353],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627180',
        NAME: 'Newhall Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168025285,
        AWATER: 53100,
        INTPTLAT: '+34.3691254',
        INTPTLON: '-118.5800118',
        ELSDLEA: '27180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9873, 34.6313],
            [-117.8801, 34.6322],
            [-117.8265, 34.558],
            [-117.667, 34.558],
            [-117.6601, 34.4986],
            [-117.6552, 34.3972],
            [-117.8748, 34.3818],
            [-118.0285, 34.3812],
            [-118.0284, 34.4701],
            [-118.0797, 34.5244],
            [-117.9869, 34.5579],
            [-117.9873, 34.6313],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619440',
        NAME: 'Keppel Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 783253911,
        AWATER: 1944431,
        INTPTLAT: '+34.4944888',
        INTPTLON: '-117.8619782',
        ELSDLEA: '19440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6071, 34.4353],
            [-118.5717, 34.44],
            [-118.5165, 34.6017],
            [-118.3932, 34.6019],
            [-118.3759, 34.5586],
            [-118.3773, 34.5442],
            [-118.4312, 34.5442],
            [-118.4844, 34.396],
            [-118.6071, 34.4353],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635970',
        NAME: 'Saugus Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251464244,
        AWATER: 486432,
        INTPTLAT: '+34.5132818',
        INTPTLON: '-118.4782645',
        ELSDLEA: '35970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4844, 34.396],
            [-118.4312, 34.5442],
            [-118.3773, 34.5442],
            [-118.3257, 34.4375],
            [-118.3244, 34.3522],
            [-118.4487, 34.3528],
            [-118.4844, 34.396],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638220',
        NAME: 'Sulphur Springs Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201829247,
        AWATER: 266135,
        INTPTLAT: '+34.4251840',
        INTPTLON: '-118.3907717',
        ELSDLEA: '38220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1853, 34.5582],
            [-118.1477, 34.6458],
            [-118.0948, 34.6311],
            [-117.9873, 34.6313],
            [-117.9869, 34.5579],
            [-118.0797, 34.5244],
            [-118.1853, 34.5582],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629580',
        NAME: 'Palmdale Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185247582,
        AWATER: 2127631,
        INTPTLAT: '+34.5789322',
        INTPTLON: '-118.0831611',
        ELSDLEA: '29580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6909, 34.8181],
            [-118.6257, 34.8182],
            [-118.1668, 34.8206],
            [-118.1477, 34.6458],
            [-118.1853, 34.5582],
            [-118.3759, 34.5586],
            [-118.3932, 34.6019],
            [-118.4121, 34.7024],
            [-118.6243, 34.7294],
            [-118.6909, 34.8181],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642120',
        NAME: 'Westside Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 815068059,
        AWATER: 4454388,
        INTPTLAT: '+34.7188130',
        INTPTLON: '-118.3683660',
        ELSDLEA: '42120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.083, 34.0206],
            [-118.075, 34.0407],
            [-118.0731, 34.0449],
            [-118.0556, 34.0488],
            [-118.0299, 34.0366],
            [-118.0542, 34.0207],
            [-118.083, 34.0206],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640650',
        NAME: 'Valle Lindo Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7675509,
        AWATER: 869743,
        INTPTLAT: '+34.0344665',
        INTPTLON: '-118.0601170',
        ELSDLEA: '40650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1088, 33.9277],
            [-118.0938, 33.9505],
            [-118.0892, 33.9571],
            [-118.06, 33.9465],
            [-118.0604, 33.9171],
            [-118.1088, 33.9277],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621930',
        NAME: 'Little Lake City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13832971,
        AWATER: 147592,
        INTPTLAT: '+33.9344404',
        INTPTLON: '-118.0825307',
        ELSDLEA: '21930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3961, 33.9309],
            [-118.37, 33.9309],
            [-118.3657, 33.9309],
            [-118.3613, 33.9063],
            [-118.3698, 33.8946],
            [-118.3787, 33.8946],
            [-118.3962, 33.9019],
            [-118.3961, 33.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642990',
        NAME: 'Wiseburn Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9989332,
        AWATER: 0,
        INTPTLAT: '+33.9152134',
        INTPTLON: '-118.3798822',
        ELSDLEA: '42990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0819, 33.9774],
            [-118.0424, 33.9686],
            [-118.0507, 33.9588],
            [-118.06, 33.9465],
            [-118.0892, 33.9571],
            [-118.0819, 33.9774],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622890',
        NAME: 'Los Nietos Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8968204,
        AWATER: 95211,
        INTPTLAT: '+33.9625507',
        INTPTLON: '-118.0680113',
        ELSDLEA: '22890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8366, 34.8175],
            [-118.6909, 34.8181],
            [-118.6243, 34.7294],
            [-118.6247, 34.6155],
            [-118.7968, 34.6166],
            [-118.8814, 34.7906],
            [-118.8366, 34.8175],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615600',
        NAME: 'Gorman Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390222970,
        AWATER: 6436695,
        INTPTLAT: '+34.7106484',
        INTPTLON: '-118.7563859',
        ELSDLEA: '15600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6671, 34.6902],
            [-117.667, 34.558],
            [-117.8265, 34.558],
            [-117.8801, 34.6322],
            [-117.8807, 34.6901],
            [-117.6671, 34.6902],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642810',
        NAME: 'Wilsona Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259987177,
        AWATER: 158724,
        INTPTLAT: '+34.6271431',
        INTPTLON: '-117.7641827',
        ELSDLEA: '42810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6469, 34.2959],
            [-117.5592, 34.296],
            [-117.5587, 34.2191],
            [-117.6301, 34.2199],
            [-117.6638, 34.2203],
            [-117.6469, 34.2959],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626340',
        NAME: 'Mount Baldy Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74358670,
        AWATER: 0,
        INTPTLAT: '+34.2565105',
        INTPTLON: '-117.6067058',
        ELSDLEA: '26340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6243, 34.7294],
            [-118.4121, 34.7024],
            [-118.3932, 34.6019],
            [-118.5165, 34.6017],
            [-118.6247, 34.6155],
            [-118.6243, 34.7294],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617880',
        NAME: 'Hughes-Elizabeth Lakes Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290416570,
        AWATER: 1024889,
        INTPTLAT: '+34.6640856',
        INTPTLON: '-118.4971509',
        ELSDLEA: '17880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.775, 37.4631],
            [-118.6632, 37.463],
            [-118.6382, 37.525],
            [-118.6356, 37.525],
            [-118.5156, 37.4627],
            [-118.4599, 37.463],
            [-118.4594, 37.3612],
            [-118.5725, 37.361],
            [-118.5723, 37.2913],
            [-118.6865, 37.2911],
            [-118.7867, 37.3434],
            [-118.775, 37.4631],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633690',
        NAME: 'Round Valley Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476789691,
        AWATER: 4512133,
        INTPTLAT: '+37.4054387',
        INTPTLON: '-118.6301034',
        ELSDLEA: '33690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5608, 40.3435],
            [-120.4342, 40.3365],
            [-120.4205, 40.2181],
            [-120.3189, 40.1837],
            [-120.2876, 40.245],
            [-120.1708, 40.1594],
            [-120.3017, 40.1148],
            [-120.4364, 40.1696],
            [-120.5634, 40.2636],
            [-120.5852, 40.2855],
            [-120.5608, 40.3435],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618780',
        NAME: 'Janesville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320867024,
        AWATER: 23001887,
        INTPTLAT: '+40.2866430',
        INTPTLON: '-120.4704931',
        ELSDLEA: '18780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6166, 40.3845],
            [-120.5627, 40.4023],
            [-120.5608, 40.3435],
            [-120.5852, 40.2855],
            [-120.6118, 40.2856],
            [-120.6166, 40.3845],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619050',
        NAME: 'Johnstonville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48749003,
        AWATER: 143939,
        INTPTLAT: '+40.3524779',
        INTPTLON: '-120.5814120',
        ELSDLEA: '19050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.43, 37.7083],
            [-122.2818, 37.7082],
            [-122.2809, 37.7072],
            [-122.2656, 37.6889],
            [-122.43, 37.7083],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604200',
        NAME: 'Bayshore Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4190759,
        AWATER: 23046403,
        INTPTLAT: '+37.6912293',
        INTPTLON: '-122.3914363',
        ELSDLEA: '04200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1905, 37.5929],
            [-122.1868, 37.5929],
            [-122.18, 37.5845],
            [-122.1504, 37.5475],
            [-122.2389, 37.5225],
            [-122.2939, 37.484],
            [-122.3187, 37.4824],
            [-122.1905, 37.5929],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604530',
        NAME: 'Belmont-Redwood Shores Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28165718,
        AWATER: 25072645,
        INTPTLAT: '+37.5375787',
        INTPTLON: '-122.2372911',
        ELSDLEA: '04530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4408, 37.7083],
            [-122.43, 37.7083],
            [-122.2656, 37.6889],
            [-122.252, 37.6724],
            [-122.279, 37.672],
            [-122.4312, 37.679],
            [-122.4408, 37.7083],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606060',
        NAME: 'Brisbane Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12439655,
        AWATER: 20935062,
        INTPTLAT: '+37.6757495',
        INTPTLON: '-122.3833020',
        ELSDLEA: '06060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3337, 37.6016],
            [-122.3519, 37.5699],
            [-122.3903, 37.5664],
            [-122.3979, 37.5633],
            [-122.3337, 37.6016],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606480',
        NAME: 'Burlingame Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13411065,
        AWATER: 4275105,
        INTPTLAT: '+37.5866037',
        INTPTLON: '-122.3660887',
        ELSDLEA: '06480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3519, 37.5699],
            [-122.33, 37.541],
            [-122.3589, 37.53],
            [-122.3903, 37.5664],
            [-122.3519, 37.5699],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617190',
        NAME: 'Hillsborough City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16135054,
        AWATER: 0,
        INTPTLAT: '+37.5570772',
        INTPTLON: '-122.3585086',
        ELSDLEA: '17190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5752, 37.7067],
            [-122.4408, 37.7083],
            [-122.4312, 37.679],
            [-122.4739, 37.6574],
            [-122.5705, 37.6653],
            [-122.5752, 37.7067],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618870',
        NAME: 'Jefferson Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 24725681,
        AWATER: 30852871,
        INTPTLAT: '+37.6901929',
        INTPTLON: '-122.4962701',
        ELSDLEA: '18870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5705, 37.6653],
            [-122.4739, 37.6574],
            [-122.4641, 37.6388],
            [-122.4463, 37.6222],
            [-122.4442, 37.6192],
            [-122.4654, 37.5701],
            [-122.5876, 37.5736],
            [-122.5705, 37.6653],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620460',
        NAME: 'Pacifica School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40506489,
        AWATER: 67625232,
        INTPTLAT: '+37.5978253',
        INTPTLON: '-122.5128618',
        ELSDLEA: '20460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2412, 37.4366],
            [-122.2094, 37.4497],
            [-122.1899, 37.4288],
            [-122.1919, 37.3975],
            [-122.2422, 37.4161],
            [-122.2412, 37.4366],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622380',
        NAME: 'Las Lomitas Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20773631,
        AWATER: 85453,
        INTPTLAT: '+37.4221460',
        INTPTLON: '-122.2159023',
        ELSDLEA: '22380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2094, 37.4497],
            [-122.1774, 37.4786],
            [-122.1449, 37.4582],
            [-122.1899, 37.4288],
            [-122.2094, 37.4497],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624570',
        NAME: 'Menlo Park City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16575499,
        AWATER: 0,
        INTPTLAT: '+37.4558838',
        INTPTLON: '-122.1833905',
        ELSDLEA: '24570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4654, 37.5701],
            [-122.4442, 37.6192],
            [-122.2087, 37.6195],
            [-122.1868, 37.5929],
            [-122.1905, 37.5929],
            [-122.3337, 37.6016],
            [-122.3979, 37.5633],
            [-122.4021, 37.534],
            [-122.4654, 37.5701],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624900',
        NAME: 'Millbrae Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44964344,
        AWATER: 42608731,
        INTPTLAT: '+37.6192966',
        INTPTLON: '-122.3645694',
        ELSDLEA: '24900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2873, 37.3596],
            [-122.2864, 37.3946],
            [-122.2422, 37.4161],
            [-122.1919, 37.3975],
            [-122.1841, 37.3348],
            [-122.1899, 37.3203],
            [-122.2873, 37.3596],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631470',
        NAME: 'Portola Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51016589,
        AWATER: 158535,
        INTPTLAT: '+37.3650457',
        INTPTLON: '-122.2326785',
        ELSDLEA: '31470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1219, 37.5123],
            [-122.0815, 37.4778],
            [-122.0968, 37.4619],
            [-122.1449, 37.4582],
            [-122.1774, 37.4786],
            [-122.1219, 37.5123],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631860',
        NAME: 'Ravenswood City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20178417,
        AWATER: 16815137,
        INTPTLAT: '+37.4894915',
        INTPTLON: '-122.1334597',
        ELSDLEA: '31860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.345, 37.463],
            [-122.3187, 37.4824],
            [-122.2939, 37.484],
            [-122.2568, 37.4838],
            [-122.2389, 37.5225],
            [-122.1504, 37.5475],
            [-122.1492, 37.5458],
            [-122.1219, 37.5123],
            [-122.1774, 37.4786],
            [-122.2094, 37.4497],
            [-122.2412, 37.4366],
            [-122.3185, 37.4344],
            [-122.345, 37.463],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632130',
        NAME: 'Redwood City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75031886,
        AWATER: 20556300,
        INTPTLAT: '+37.4867735',
        INTPTLON: '-122.2315684',
        ELSDLEA: '32130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4641, 37.6388],
            [-122.4543, 37.6321],
            [-122.279, 37.6339],
            [-122.279, 37.672],
            [-122.252, 37.6724],
            [-122.2187, 37.6317],
            [-122.2087, 37.6195],
            [-122.4442, 37.6192],
            [-122.4463, 37.6222],
            [-122.4641, 37.6388],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634230',
        NAME: 'San Bruno Park Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16673271,
        AWATER: 37760003,
        INTPTLAT: '+37.6264115',
        INTPTLON: '-122.3857566',
        ELSDLEA: '34230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2389, 37.5225],
            [-122.2568, 37.4838],
            [-122.2939, 37.484],
            [-122.2389, 37.5225],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634290',
        NAME: 'San Carlos Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14578746,
        AWATER: 367309,
        INTPTLAT: '+37.5010545',
        INTPTLON: '-122.2664313',
        ELSDLEA: '34290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9501, 35.1365],
            [-118.8423, 35.2236],
            [-118.7222, 35.2095],
            [-118.7526, 35.102],
            [-118.6367, 35.0396],
            [-118.6359, 34.9528],
            [-118.6257, 34.8182],
            [-118.6909, 34.8181],
            [-118.8366, 34.8175],
            [-118.8538, 34.9247],
            [-118.8555, 35.0454],
            [-118.9354, 35.0453],
            [-118.9501, 35.1365],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603270',
        NAME: 'Arvin Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 816776965,
        AWATER: 465959,
        INTPTLAT: '+34.9995723',
        INTPTLON: '-118.7682384',
        ELSDLEA: '03270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0566, 35.3688],
            [-119.0388, 35.3832],
            [-119.0032, 35.4087],
            [-118.9592, 35.5296],
            [-118.9044, 35.4994],
            [-118.7998, 35.4996],
            [-118.7021, 35.4419],
            [-118.701, 35.3696],
            [-118.9142, 35.3583],
            [-118.9499, 35.3104],
            [-118.9675, 35.296],
            [-119.0301, 35.3322],
            [-119.0566, 35.3688],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603630',
        NAME: 'Bakersfield City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385671971,
        AWATER: 1860662,
        INTPTLAT: '+35.4219717',
        INTPTLON: '-118.8844707',
        ELSDLEA: '03630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1159, 35.5444],
            [-119.0293, 35.5301],
            [-119.0032, 35.4087],
            [-119.0388, 35.3832],
            [-119.0744, 35.4051],
            [-119.1006, 35.4514],
            [-119.1159, 35.5444],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604260',
        NAME: 'Beardsley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84892260,
        AWATER: 315910,
        INTPTLAT: '+35.4757194',
        INTPTLON: '-119.0693328',
        ELSDLEA: '04260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8682, 35.528],
            [-119.8686, 35.5718],
            [-119.6544, 35.5726],
            [-119.626, 35.5292],
            [-119.6603, 35.383],
            [-119.7664, 35.3685],
            [-119.7668, 35.441],
            [-119.8682, 35.528],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604560',
        NAME: 'Belridge Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341361347,
        AWATER: 588609,
        INTPTLAT: '+35.4918637',
        INTPTLON: '-119.7404959',
        ELSDLEA: '04560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2966, 35.2964],
            [-119.1721, 35.3465],
            [-119.1152, 35.3544],
            [-119.0566, 35.3688],
            [-119.0301, 35.3322],
            [-119.0208, 35.2378],
            [-119.0211, 35.0952],
            [-119.0648, 35.0949],
            [-119.0742, 35.0948],
            [-119.1283, 35.2672],
            [-119.3058, 35.2672],
            [-119.2966, 35.2964],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606390',
        NAME: 'Panama-Buena Vista Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238925802,
        AWATER: 1098811,
        INTPTLAT: '+35.3365549',
        INTPTLON: '-119.1251607',
        ELSDLEA: '06390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.626, 35.5292],
            [-119.6355, 35.5725],
            [-119.5458, 35.5718],
            [-119.5464, 35.5141],
            [-119.4393, 35.5006],
            [-119.4036, 35.4567],
            [-119.3412, 35.3621],
            [-119.4474, 35.362],
            [-119.4659, 35.2676],
            [-119.4836, 35.2675],
            [-119.5538, 35.2676],
            [-119.5538, 35.3249],
            [-119.6603, 35.383],
            [-119.626, 35.5292],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606720',
        NAME: 'Buttonwillow Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 551704704,
        AWATER: 1795818,
        INTPTLAT: '+35.4165342',
        INTPTLON: '-119.5232976',
        ELSDLEA: '06720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.701, 35.3696],
            [-118.7021, 35.4419],
            [-118.5851, 35.4426],
            [-118.3791, 35.4431],
            [-118.378, 35.3626],
            [-118.2703, 35.3628],
            [-118.2686, 35.2753],
            [-118.6273, 35.2676],
            [-118.6996, 35.2385],
            [-118.7175, 35.3112],
            [-118.701, 35.3696],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606960',
        NAME: 'Caliente Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 699215201,
        AWATER: 159036,
        INTPTLAT: '+35.3292652',
        INTPTLON: '-118.5291130',
        ELSDLEA: '06960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3295, 35.7902],
            [-119.2414, 35.7905],
            [-119.1967, 35.7905],
            [-119.0712, 35.7908],
            [-118.9382, 35.7905],
            [-118.9402, 35.6168],
            [-118.8884, 35.6164],
            [-118.9044, 35.4994],
            [-118.9592, 35.5296],
            [-119.0102, 35.6027],
            [-119.0094, 35.6896],
            [-119.1517, 35.6746],
            [-119.2763, 35.7252],
            [-119.3295, 35.7902],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610890',
        NAME: 'Delano Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453727662,
        AWATER: 1780105,
        INTPTLAT: '+35.7005620',
        INTPTLON: '-119.0671672',
        ELSDLEA: '10890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9803, 40.8052],
            [-120.8435, 40.8061],
            [-120.813, 40.971],
            [-120.642, 40.9704],
            [-120.6419, 40.9398],
            [-120.3431, 40.9395],
            [-120.3421, 41.0124],
            [-119.9998, 41.0119],
            [-119.9987, 40.7795],
            [-120.2065, 40.784],
            [-120.205, 40.6946],
            [-120.3636, 40.6939],
            [-120.4354, 40.764],
            [-121.0187, 40.7647],
            [-120.9803, 40.8052],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631830',
        NAME: 'Ravendale-Termo Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1825637141,
        AWATER: 19279046,
        INTPTLAT: '+40.8479807',
        INTPTLON: '-120.3987936',
        ELSDLEA: '31830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3636, 40.6939],
            [-120.205, 40.6946],
            [-120.2065, 40.784],
            [-119.9987, 40.7795],
            [-119.997, 40.1588],
            [-120.1708, 40.1594],
            [-120.2876, 40.245],
            [-120.3189, 40.1837],
            [-120.4205, 40.2181],
            [-120.4342, 40.3365],
            [-120.5608, 40.3435],
            [-120.5627, 40.4023],
            [-120.5608, 40.4804],
            [-120.3636, 40.4949],
            [-120.3636, 40.6939],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636420',
        NAME: 'Shaffer Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2039342453,
        AWATER: 219802696,
        INTPTLAT: '+40.4530328',
        INTPTLON: '-120.2159544',
        ELSDLEA: '36420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0187, 40.7647],
            [-120.4354, 40.764],
            [-120.3636, 40.6939],
            [-120.3636, 40.4949],
            [-120.5608, 40.4804],
            [-120.5627, 40.4023],
            [-120.6166, 40.3845],
            [-120.7974, 40.3896],
            [-120.7963, 40.3014],
            [-120.7964, 40.3013],
            [-120.8527, 40.4092],
            [-121.0246, 40.4101],
            [-121.0187, 40.7647],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638550',
        NAME: 'Susanville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1977642165,
        AWATER: 119110318,
        INTPTLAT: '+40.5827057',
        INTPTLON: '-120.7169249',
        ELSDLEA: '38550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8607, 35.2964],
            [-118.7175, 35.3112],
            [-118.6996, 35.2385],
            [-118.7222, 35.2095],
            [-118.8423, 35.2236],
            [-118.8606, 35.2672],
            [-118.8607, 35.2964],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611130',
        NAME: 'Di Giorgio Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136580663,
        AWATER: 84638,
        INTPTLAT: '+35.2603233',
        INTPTLON: '-118.7797831',
        ELSDLEA: '11130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9142, 35.3583],
            [-118.701, 35.3696],
            [-118.7175, 35.3112],
            [-118.8607, 35.2964],
            [-118.9053, 35.318],
            [-118.9142, 35.3583],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611940',
        NAME: 'Edison Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124249501,
        AWATER: 1805,
        INTPTLAT: '+35.3376627',
        INTPTLON: '-118.8152006',
        ELSDLEA: '11940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6166, 40.3845],
            [-120.6118, 40.2856],
            [-120.7963, 40.3014],
            [-120.7974, 40.3896],
            [-120.6166, 40.3845],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632520',
        NAME: 'Richmond Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141689267,
        AWATER: 613574,
        INTPTLAT: '+40.3460044',
        INTPTLON: '-120.7012496',
        ELSDLEA: '32520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9382, 35.7905],
            [-118.7442, 35.7902],
            [-118.7664, 35.6591],
            [-118.8884, 35.6164],
            [-118.9402, 35.6168],
            [-118.9382, 35.7905],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605250',
        NAME: 'Blake Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300395664,
        AWATER: 12151,
        INTPTLAT: '+35.7069910',
        INTPTLON: '-118.8624280',
        ELSDLEA: '05250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3412, 35.3621],
            [-119.2966, 35.2964],
            [-119.3058, 35.2672],
            [-119.3413, 35.2536],
            [-119.4659, 35.2676],
            [-119.4474, 35.362],
            [-119.3412, 35.3621],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612360',
        NAME: 'Elk Hills Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128344997,
        AWATER: 925371,
        INTPTLAT: '+35.3062125',
        INTPTLON: '-119.3901732',
        ELSDLEA: '12360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.557, 37.6706],
            [-121.557, 37.7077],
            [-121.4791, 37.7071],
            [-121.3981, 37.7106],
            [-121.3615, 37.7107],
            [-121.3609, 37.5698],
            [-121.4719, 37.4818],
            [-121.5567, 37.5427],
            [-121.557, 37.6706],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618840',
        NAME: 'Jefferson Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 356601145,
        AWATER: 61969,
        INTPTLAT: '+37.6257134',
        INTPTLON: '-121.4377868',
        ELSDLEA: '18840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4843, 38.2415],
            [-121.4289, 38.2542],
            [-121.3931, 38.2186],
            [-121.4961, 38.1264],
            [-121.5719, 38.1315],
            [-121.4843, 38.2415],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626970',
        NAME: 'New Hope Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86780154,
        AWATER: 3711578,
        INTPTLAT: '+38.1577939',
        INTPTLON: '-121.5202225',
        ELSDLEA: '26970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3615, 37.7107],
            [-121.2807, 37.7108],
            [-121.24, 37.6649],
            [-121.2439, 37.6619],
            [-121.3609, 37.5698],
            [-121.3615, 37.7107],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627030',
        NAME: 'New Jerusalem Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98778309,
        AWATER: 1170658,
        INTPTLAT: '+37.6469333',
        INTPTLON: '-121.3149687',
        ELSDLEA: '27030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.153, 38.2625],
            [-121.0271, 38.3003],
            [-121.0064, 38.2505],
            [-121.1523, 38.2054],
            [-121.2637, 38.2074],
            [-121.153, 38.2625],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627930',
        NAME: 'Oak View Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121098908,
        AWATER: 127201,
        INTPTLAT: '+38.2505382',
        INTPTLON: '-121.1435703',
        ELSDLEA: '27930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9265, 38.0172],
            [-120.9264, 38.0774],
            [-120.7932, 37.9586],
            [-120.7473, 37.9161],
            [-120.8008, 37.8394],
            [-120.9234, 37.8013],
            [-120.9265, 38.0172],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640800',
        NAME: 'Valley Home Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247426847,
        AWATER: 17124074,
        INTPTLAT: '+37.9090649',
        INTPTLON: '-120.8765275',
        ELSDLEA: '40800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3282, 37.8079],
            [-121.2807, 37.7108],
            [-121.3615, 37.7107],
            [-121.3981, 37.7106],
            [-121.3988, 37.8037],
            [-121.3282, 37.8079],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603870',
        NAME: 'Banta Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95276207,
        AWATER: 3054995,
        INTPTLAT: '+37.7590922',
        INTPTLON: '-121.3564239',
        ELSDLEA: '03870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4289, 38.2542],
            [-121.3923, 38.2979],
            [-121.2643, 38.2985],
            [-121.153, 38.2625],
            [-121.2637, 38.2074],
            [-121.3931, 38.2186],
            [-121.4289, 38.2542],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614790',
        NAME: 'Galt Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155687004,
        AWATER: 1263338,
        INTPTLAT: '+38.2551183',
        INTPTLON: '-121.3127602',
        ELSDLEA: '14790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3979, 37.5633],
            [-122.3903, 37.5664],
            [-122.3589, 37.53],
            [-122.33, 37.541],
            [-122.3519, 37.5699],
            [-122.3337, 37.6016],
            [-122.1905, 37.5929],
            [-122.3187, 37.4824],
            [-122.345, 37.463],
            [-122.4021, 37.534],
            [-122.3979, 37.5633],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634920',
        NAME: 'San Mateo-Foster City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72545076,
        AWATER: 37686841,
        INTPTLAT: '+37.5481725',
        INTPTLON: '-122.3056044',
        ELSDLEA: '34920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3185, 37.4344],
            [-122.2412, 37.4366],
            [-122.2422, 37.4161],
            [-122.2864, 37.3946],
            [-122.3185, 37.4344],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643140',
        NAME: 'Woodside Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30233673,
        AWATER: 0,
        INTPTLAT: '+37.4234304',
        INTPTLON: '-122.2794782',
        ELSDLEA: '43140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.11, 35.4053],
            [-119.0744, 35.4051],
            [-119.0388, 35.3832],
            [-119.0566, 35.3688],
            [-119.1152, 35.3544],
            [-119.11, 35.4053],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614700',
        NAME: 'Fruitvale Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25867799,
        AWATER: 860335,
        INTPTLAT: '+35.3815964',
        INTPTLON: '-119.0867064',
        ELSDLEA: '14700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0648, 35.0949],
            [-119.0211, 35.0952],
            [-119.0208, 35.2378],
            [-118.95, 35.2231],
            [-118.9501, 35.1365],
            [-118.9354, 35.0453],
            [-118.8555, 35.0454],
            [-118.8538, 34.9247],
            [-119.0659, 34.9261],
            [-119.0648, 35.0949],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615000',
        NAME: 'General Shafter Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422980247,
        AWATER: 656450,
        INTPTLAT: '+35.0410905',
        INTPTLON: '-118.9719663',
        ELSDLEA: '15000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.5892, 35.7897],
            [-118.5739, 35.7897],
            [-118.361, 35.7906],
            [-118.3788, 35.6887],
            [-118.3791, 35.4431],
            [-118.5851, 35.4426],
            [-118.5892, 35.7897],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619590',
        NAME: 'Kernville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713986989,
        AWATER: 33290928,
        INTPTLAT: '+35.6228752',
        INTPTLON: '-118.4788252',
        ELSDLEA: '19590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3413, 35.2536],
            [-119.3058, 35.2672],
            [-119.1283, 35.2672],
            [-119.0742, 35.0948],
            [-119.2894, 35.0939],
            [-119.2893, 35.1804],
            [-119.3413, 35.2536],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620730',
        NAME: 'Lakeside Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 425820881,
        AWATER: 4752762,
        INTPTLAT: '+35.1900417',
        INTPTLON: '-119.1916871',
        ELSDLEA: '20730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1941, 35.7892],
            [-119.7548, 35.79],
            [-119.6523, 35.7904],
            [-119.6334, 35.7027],
            [-119.6355, 35.5725],
            [-119.626, 35.5292],
            [-119.6544, 35.5726],
            [-119.8686, 35.5718],
            [-119.8682, 35.528],
            [-119.9408, 35.5277],
            [-119.9442, 35.4393],
            [-119.9974, 35.4395],
            [-120.0867, 35.5266],
            [-120.0859, 35.6145],
            [-120.1939, 35.6144],
            [-120.1941, 35.7892],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622950',
        NAME: 'Lost Hills Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1320012410,
        AWATER: 4711987,
        INTPTLAT: '+35.6619190',
        INTPTLON: '-119.9121082',
        ELSDLEA: '22950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8682, 35.528],
            [-119.7668, 35.441],
            [-119.7664, 35.3685],
            [-119.6603, 35.383],
            [-119.5538, 35.3249],
            [-119.5538, 35.2676],
            [-119.6667, 35.2616],
            [-119.8094, 35.2636],
            [-119.8093, 35.3509],
            [-119.8802, 35.3512],
            [-119.9442, 35.4393],
            [-119.9408, 35.5277],
            [-119.8682, 35.528],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624330',
        NAME: 'McKittrick Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446217991,
        AWATER: 0,
        INTPTLAT: '+35.3681034',
        INTPTLON: '-119.7621683',
        ELSDLEA: '24330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3701, 37.0938],
            [-119.3694, 37.146],
            [-119.3392, 37.1947],
            [-119.3122, 37.3527],
            [-119.0224, 37.5857],
            [-118.9171, 37.5503],
            [-118.8505, 37.4758],
            [-118.775, 37.4631],
            [-118.7867, 37.3434],
            [-118.6865, 37.2911],
            [-118.6674, 37.16],
            [-119.1963, 37.1649],
            [-119.1967, 37.093],
            [-119.3701, 37.0938],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604860',
        NAME: 'Big Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2018664296,
        AWATER: 47002669,
        INTPTLAT: '+37.3214380',
        INTPTLON: '-119.0219483',
        ELSDLEA: '04860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1032, 36.4733],
            [-120.0614, 36.5462],
            [-119.9536, 36.5469],
            [-119.9539, 36.5187],
            [-120.0489, 36.357],
            [-120.1032, 36.4733],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606540',
        NAME: 'Burrel Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186954747,
        AWATER: 0,
        INTPTLAT: '+36.4585211',
        INTPTLON: '-120.0260280',
        ELSDLEA: '06540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6758, 36.5037],
            [-119.5931, 36.5035],
            [-119.5297, 36.5613],
            [-119.5029, 36.5307],
            [-119.5272, 36.4889],
            [-119.5429, 36.4452],
            [-119.6427, 36.4299],
            [-119.6758, 36.5037],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619800',
        NAME: 'Kingsburg Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121936005,
        AWATER: 552899,
        INTPTLAT: '+36.4950017',
        INTPTLON: '-119.5875078',
        ELSDLEA: '19800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9142, 35.3583],
            [-118.9053, 35.318],
            [-118.9499, 35.3104],
            [-118.9142, 35.3583],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613290',
        NAME: 'Fairfax Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27139738,
        AWATER: 0,
        INTPTLAT: '+35.3370426',
        INTPTLON: '-118.9339948',
        ELSDLEA: '13290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9675, 35.296],
            [-118.9499, 35.3104],
            [-118.9053, 35.318],
            [-118.8607, 35.2964],
            [-118.8606, 35.2672],
            [-118.9501, 35.2524],
            [-118.9675, 35.296],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620850',
        NAME: 'Lamont Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60037923,
        AWATER: 152441,
        INTPTLAT: '+35.2824702',
        INTPTLON: '-118.9126582',
        ELSDLEA: '20850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8089, 36.5762],
            [-119.7729, 36.5763],
            [-119.7641, 36.5329],
            [-119.8089, 36.5762],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602370',
        NAME: 'Alvina Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18104056,
        AWATER: 0,
        INTPTLAT: '+36.5535138',
        INTPTLON: '-119.7877455',
        ELSDLEA: '02370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5464, 35.5141],
            [-119.332, 35.5362],
            [-119.3326, 35.4562],
            [-119.4036, 35.4567],
            [-119.4393, 35.5006],
            [-119.5464, 35.5141],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623760',
        NAME: 'Maple Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87783286,
        AWATER: 0,
        INTPTLAT: '+35.4986547',
        INTPTLON: '-119.3905478',
        ELSDLEA: '23760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0301, 35.3322],
            [-118.9675, 35.296],
            [-118.9501, 35.2524],
            [-118.95, 35.2231],
            [-119.0208, 35.2378],
            [-119.0301, 35.3322],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616050',
        NAME: 'Greenfield Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 58796388,
        AWATER: 0,
        INTPTLAT: '+35.2713551',
        INTPTLON: '-118.9967022',
        ELSDLEA: '16050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.332, 35.5362],
            [-119.2421, 35.5584],
            [-119.1159, 35.5444],
            [-119.1006, 35.4514],
            [-119.1632, 35.4487],
            [-119.172, 35.4487],
            [-119.3326, 35.4562],
            [-119.332, 35.5362],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621450',
        NAME: 'Richland-Lerdo Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220452349,
        AWATER: 0,
        INTPTLAT: '+35.5039644',
        INTPTLON: '-119.2155476',
        ELSDLEA: '21450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5297, 36.5685],
            [-119.4937, 36.5755],
            [-119.4962, 36.5357],
            [-119.5029, 36.5307],
            [-119.5297, 36.5613],
            [-119.5297, 36.5685],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608850',
        NAME: 'Clay Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13373767,
        AWATER: 276518,
        INTPTLAT: '+36.5576711',
        INTPTLON: '-119.5123281',
        ELSDLEA: '08850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2854, 40.6505],
            [-122.2573, 40.7662],
            [-122.1238, 40.7645],
            [-122.0455, 40.7211],
            [-122.1034, 40.6629],
            [-122.1423, 40.6334],
            [-122.2585, 40.6148],
            [-122.2854, 40.6505],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604350',
        NAME: 'Bella Vista Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222385538,
        AWATER: 11275862,
        INTPTLAT: '+40.6983424',
        INTPTLON: '-122.1668477',
        ELSDLEA: '04350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0473, 40.5326],
            [-121.9898, 40.5326],
            [-121.9201, 40.5744],
            [-121.6921, 40.5455],
            [-121.3228, 40.5409],
            [-121.3229, 40.5118],
            [-121.4631, 40.4859],
            [-121.9201, 40.4717],
            [-122.0475, 40.4312],
            [-122.0473, 40.5326],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605220',
        NAME: 'Black Butte Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489023000,
        AWATER: 1520805,
        INTPTLAT: '+40.5124168',
        INTPTLON: '-121.7841813',
        ELSDLEA: '05220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4009, 40.5048],
            [-122.3672, 40.5055],
            [-122.2181, 40.4472],
            [-122.332, 40.4179],
            [-122.4009, 40.5048],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607680',
        NAME: 'Cascade Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67950856,
        AWATER: 2347889,
        INTPTLAT: '+40.4611119',
        INTPTLON: '-122.3050340',
        ELSDLEA: '07680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4984, 41.1827],
            [-122.1943, 41.1844],
            [-122.0728, 41.1831],
            [-122.0935, 41.1126],
            [-122.1791, 41.0462],
            [-122.5275, 41.0435],
            [-122.5228, 41.0566],
            [-122.4571, 41.0966],
            [-122.4984, 41.1827],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607770',
        NAME: 'Castle Rock Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477741403,
        AWATER: 1936546,
        INTPTLAT: '+41.1106421',
        INTPTLON: '-122.2952253',
        ELSDLEA: '07770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2854, 40.6505],
            [-122.2585, 40.6148],
            [-122.2686, 40.5782],
            [-122.2871, 40.5585],
            [-122.3468, 40.6154],
            [-122.2854, 40.6505],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609450',
        NAME: 'Columbia Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50265548,
        AWATER: 562853,
        INTPTLAT: '+40.6102870',
        INTPTLON: '-122.3036438',
        ELSDLEA: '09450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5036, 40.4461],
            [-122.332, 40.4179],
            [-122.2181, 40.4472],
            [-122.1992, 40.4893],
            [-122.0473, 40.5326],
            [-122.0475, 40.4312],
            [-122.085, 40.4127],
            [-122.3104, 40.3711],
            [-122.52, 40.3937],
            [-122.6339, 40.3341],
            [-122.5997, 40.4452],
            [-122.5036, 40.4461],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609990',
        NAME: 'Cottonwood Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379930711,
        AWATER: 2050367,
        INTPTLAT: '+40.3850396',
        INTPTLON: '-122.4846997',
        ELSDLEA: '09990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3635, 40.6123],
            [-122.3468, 40.6154],
            [-122.2871, 40.5585],
            [-122.3148, 40.5493],
            [-122.3538, 40.5347],
            [-122.3635, 40.6123],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612810',
        NAME: 'Enterprise Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30416914,
        AWATER: 617697,
        INTPTLAT: '+40.5684144',
        INTPTLON: '-122.3429512',
        ELSDLEA: '12810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6572, 40.803],
            [-122.6093, 40.926],
            [-122.4912, 40.8389],
            [-122.4894, 40.75],
            [-122.562, 40.7318],
            [-122.5175, 40.5773],
            [-122.5362, 40.5772],
            [-122.7055, 40.6051],
            [-122.711, 40.6248],
            [-122.752, 40.6899],
            [-122.6572, 40.803],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614490',
        NAME: 'French Gulch-Whiskeytown Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 485468444,
        AWATER: 12990795,
        INTPTLAT: '+40.7244789',
        INTPTLON: '-122.6022895',
        ELSDLEA: '14490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5362, 40.5772],
            [-122.5175, 40.5773],
            [-122.444, 40.5633],
            [-122.4267, 40.4971],
            [-122.5066, 40.5034],
            [-122.5362, 40.5772],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615690',
        NAME: 'Grant Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63206055,
        AWATER: 326621,
        INTPTLAT: '+40.5326573',
        INTPTLON: '-122.4824261',
        ELSDLEA: '15690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.359, 36.5745],
            [-119.305, 36.5882],
            [-119.2952, 36.4695],
            [-119.3679, 36.4582],
            [-119.359, 36.5745],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625350',
        NAME: 'Monson-Sultana Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57001317,
        AWATER: 0,
        INTPTLAT: '+36.5189083',
        INTPTLON: '-119.3310929',
        ELSDLEA: '25350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7727, 36.6923],
            [-119.7637, 36.6924],
            [-119.7547, 36.6634],
            [-119.7637, 36.6561],
            [-119.8041, 36.6633],
            [-119.7727, 36.6923],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628530',
        NAME: 'Orange Center Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13870186,
        AWATER: 0,
        INTPTLAT: '+36.6753286',
        INTPTLON: '-119.7805224',
        ELSDLEA: '28530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7729, 36.5763],
            [-119.7094, 36.5909],
            [-119.7095, 36.5764],
            [-119.721, 36.5328],
            [-119.7371, 36.5329],
            [-119.7641, 36.5329],
            [-119.7729, 36.5763],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625290',
        NAME: 'Monroe Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28431723,
        AWATER: 103327,
        INTPTLAT: '+36.5623777',
        INTPTLON: '-119.7392542',
        ELSDLEA: '25290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8013, 36.6154],
            [-119.7637, 36.6561],
            [-119.7547, 36.6634],
            [-119.7094, 36.5909],
            [-119.7729, 36.5763],
            [-119.8089, 36.5762],
            [-119.8013, 36.6154],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629400',
        NAME: 'Pacific Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 50559182,
        AWATER: 44038,
        INTPTLAT: '+36.6124585',
        INTPTLON: '-119.7594680',
        ELSDLEA: '29400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3701, 37.0938],
            [-119.1967, 37.093],
            [-119.1963, 37.1649],
            [-118.6674, 37.16],
            [-118.6671, 37.1579],
            [-118.538, 37.1136],
            [-118.4326, 37.0345],
            [-119.196, 37.0348],
            [-119.3672, 37.0197],
            [-119.3701, 37.0938],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630390',
        NAME: 'Pine Ridge Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 962809387,
        AWATER: 16880705,
        INTPTLAT: '+37.1098623',
        INTPTLON: '-118.9369649',
        ELSDLEA: '30390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8041, 36.6633],
            [-119.7637, 36.6561],
            [-119.8013, 36.6154],
            [-119.8041, 36.6633],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641460',
        NAME: 'Washington Colony Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18738689,
        AWATER: 0,
        INTPTLAT: '+36.6390470',
        INTPTLON: '-119.7889126',
        ELSDLEA: '41460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0257, 36.6181],
            [-119.9525, 36.619],
            [-119.863, 36.5759],
            [-119.9536, 36.5469],
            [-120.0614, 36.5462],
            [-120.0257, 36.6181],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631680',
        NAME: 'Raisin City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110195036,
        AWATER: 0,
        INTPTLAT: '+36.5833386',
        INTPTLON: '-119.9601400',
        ELSDLEA: '31680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4797, 41.5774],
            [-122.4772, 41.6536],
            [-122.3638, 41.6104],
            [-122.2456, 41.6103],
            [-122.2426, 41.5234],
            [-122.481, 41.5385],
            [-122.4797, 41.5774],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604980',
        NAME: 'Big Springs Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224357961,
        AWATER: 3213634,
        INTPTLAT: '+41.5814425',
        INTPTLON: '-122.3807046',
        ELSDLEA: '04980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5066, 40.5034],
            [-122.4267, 40.4971],
            [-122.4009, 40.5048],
            [-122.332, 40.4179],
            [-122.5036, 40.4461],
            [-122.5066, 40.5034],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616570',
        NAME: 'Happy Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99389522,
        AWATER: 550360,
        INTPTLAT: '+40.4572236',
        INTPTLON: '-122.4218326',
        ELSDLEA: '16570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0657, 40.3439],
            [-122.8606, 40.4827],
            [-122.7055, 40.6051],
            [-122.5362, 40.5772],
            [-122.5066, 40.5034],
            [-122.5036, 40.4461],
            [-122.5997, 40.4452],
            [-122.6339, 40.3341],
            [-122.7486, 40.3657],
            [-123.0654, 40.287],
            [-123.0657, 40.3439],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618160',
        NAME: 'Igo, Ono, Platina Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 805312065,
        AWATER: 780397,
        INTPTLAT: '+40.4444875',
        INTPTLON: '-122.7510870',
        ELSDLEA: '18160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.018, 41.1832],
            [-121.7923, 41.1833],
            [-121.7986, 40.9574],
            [-121.8346, 40.9587],
            [-122.029, 40.9601],
            [-122.018, 41.1832],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618270',
        NAME: 'Indian Springs Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 448897053,
        AWATER: 3908529,
        INTPTLAT: '+41.0969101',
        INTPTLON: '-121.8780475',
        ELSDLEA: '18270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.22, 40.5672],
            [-122.1423, 40.6334],
            [-122.1034, 40.6629],
            [-122.0083, 40.6627],
            [-121.9898, 40.5326],
            [-122.0473, 40.5326],
            [-122.1992, 40.4893],
            [-122.2203, 40.506],
            [-122.22, 40.5672],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624930',
        NAME: 'Millville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248862099,
        AWATER: 1045257,
        INTPTLAT: '+40.5765235',
        INTPTLON: '-122.1008029',
        ELSDLEA: '24930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.029, 40.9601],
            [-121.8346, 40.9587],
            [-121.8067, 40.8379],
            [-121.7491, 40.7541],
            [-121.798, 40.7504],
            [-121.9486, 40.7513],
            [-122.0455, 40.7211],
            [-122.1238, 40.7645],
            [-122.0351, 40.8242],
            [-122.029, 40.9601],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627040',
        NAME: 'Mountain Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 519834235,
        AWATER: 5621597,
        INTPTLAT: '+40.8363464',
        INTPTLON: '-121.9226928',
        ELSDLEA: '27040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3538, 40.5347],
            [-122.3148, 40.5493],
            [-122.2203, 40.506],
            [-122.1992, 40.4893],
            [-122.2181, 40.4472],
            [-122.3672, 40.5055],
            [-122.3538, 40.5347],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629280',
        NAME: 'Pacheco Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68267341,
        AWATER: 2225132,
        INTPTLAT: '+40.5089868',
        INTPTLON: '-122.2911701',
        ELSDLEA: '29280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.444, 40.5633],
            [-122.4341, 40.5942],
            [-122.3635, 40.6123],
            [-122.3538, 40.5347],
            [-122.3672, 40.5055],
            [-122.4009, 40.5048],
            [-122.4267, 40.4971],
            [-122.444, 40.5633],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632040',
        NAME: 'Redding Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65330900,
        AWATER: 2755902,
        INTPTLAT: '+40.5520839',
        INTPTLON: '-122.3945823',
        ELSDLEA: '32040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4894, 40.75],
            [-122.4341, 40.5942],
            [-122.444, 40.5633],
            [-122.5175, 40.5773],
            [-122.562, 40.7318],
            [-122.4894, 40.75],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636570',
        NAME: 'Shasta Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134502197,
        AWATER: 983541,
        INTPTLAT: '+40.6462362',
        INTPTLON: '-122.4787757',
        ELSDLEA: '36570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0083, 40.6627],
            [-121.798, 40.7504],
            [-121.7491, 40.7541],
            [-121.5786, 40.7533],
            [-121.5423, 40.5996],
            [-121.4545, 40.6648],
            [-121.3578, 40.6639],
            [-121.3211, 40.7585],
            [-121.3228, 40.5409],
            [-121.6921, 40.5455],
            [-121.9201, 40.5744],
            [-121.9898, 40.5326],
            [-122.0083, 40.6627],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642420',
        NAME: 'Whitmore Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 920768041,
        AWATER: 1349865,
        INTPTLAT: '+40.6433146',
        INTPTLON: '-121.6495781',
        ELSDLEA: '42420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2585, 40.6148],
            [-122.1423, 40.6334],
            [-122.22, 40.5672],
            [-122.2686, 40.5782],
            [-122.2585, 40.6148],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627510',
        NAME: 'North Cow Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42858545,
        AWATER: 165192,
        INTPTLAT: '+40.6101573',
        INTPTLON: '-122.1975408',
        ELSDLEA: '27510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0455, 40.7211],
            [-121.9486, 40.7513],
            [-121.798, 40.7504],
            [-122.0083, 40.6627],
            [-122.1034, 40.6629],
            [-122.0455, 40.7211],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627870',
        NAME: 'Oak Run Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133899795,
        AWATER: 416304,
        INTPTLAT: '+40.7080235',
        INTPTLON: '-121.9526225',
        ELSDLEA: '27870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3148, 40.5493],
            [-122.2871, 40.5585],
            [-122.2686, 40.5782],
            [-122.22, 40.5672],
            [-122.2203, 40.506],
            [-122.3148, 40.5493],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619200',
        NAME: 'Junction Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 43619207,
        AWATER: 348837,
        INTPTLAT: '+40.5230532',
        INTPTLON: '-122.2575351',
        ELSDLEA: '19200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.706, 37.3028],
            [-120.6679, 37.3894],
            [-120.5947, 37.4185],
            [-120.5236, 37.4039],
            [-120.559, 37.3272],
            [-120.7017, 37.3026],
            [-120.706, 37.3028],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603420',
        NAME: 'Atwater Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116712483,
        AWATER: 11735,
        INTPTLAT: '+37.3621851',
        INTPTLON: '-120.6111146',
        ELSDLEA: '03420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7488, 37.4557],
            [-120.7483, 37.4926],
            [-120.5991, 37.5511],
            [-120.5942, 37.5204],
            [-120.6127, 37.4326],
            [-120.6678, 37.4039],
            [-120.7314, 37.4057],
            [-120.7488, 37.4557],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603750',
        NAME: 'Ballico-Cressey Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149351268,
        AWATER: 300626,
        INTPTLAT: '+37.4702877',
        INTPTLON: '-120.6710831',
        ELSDLEA: '03750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6008, 37.1715],
            [-120.5462, 37.1858],
            [-120.4195, 37.1951],
            [-120.4008, 37.1178],
            [-120.5417, 37.0445],
            [-120.5775, 37.098],
            [-120.6008, 37.1715],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612150',
        NAME: 'El Nido Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194728517,
        AWATER: 960842,
        INTPTLAT: '+37.1385639',
        INTPTLON: '-120.5056412',
        ELSDLEA: '12150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3182, 37.2734],
            [-120.1843, 37.2727],
            [-120.0521, 37.1831],
            [-120.0546, 37.1821],
            [-120.2563, 37.1562],
            [-120.3182, 37.2734],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621240',
        NAME: 'Le Grand Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202480728,
        AWATER: 0,
        INTPTLAT: '+37.2158550',
        INTPTLON: '-120.2008211',
        ELSDLEA: '21240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.814, 37.3828],
            [-120.7314, 37.4057],
            [-120.6678, 37.4039],
            [-120.6679, 37.3894],
            [-120.706, 37.3028],
            [-120.814, 37.3828],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622170',
        NAME: 'Livingston Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111462239,
        AWATER: 664629,
        INTPTLAT: '+37.3567968',
        INTPTLON: '-120.7465373',
        ELSDLEA: '22170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8185, 37.2489],
            [-120.7019, 37.2489],
            [-120.7017, 37.3026],
            [-120.559, 37.3272],
            [-120.4971, 37.2736],
            [-120.5462, 37.1858],
            [-120.6008, 37.1715],
            [-120.7648, 37.1861],
            [-120.8149, 37.236],
            [-120.8185, 37.2489],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624360',
        NAME: 'McSwain Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293237979,
        AWATER: 2888542,
        INTPTLAT: '+37.2510903',
        INTPTLON: '-120.6306363',
        ELSDLEA: '24360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5236, 37.4039],
            [-120.4875, 37.4474],
            [-120.3357, 37.4335],
            [-120.3242, 37.3765],
            [-120.3242, 37.3611],
            [-120.4971, 37.2736],
            [-120.559, 37.3272],
            [-120.5236, 37.4039],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624600',
        NAME: 'Merced City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236208043,
        AWATER: 2148850,
        INTPTLAT: '+37.3664192',
        INTPTLON: '-120.4403261',
        ELSDLEA: '24600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5942, 37.5204],
            [-120.4844, 37.5192],
            [-120.4843, 37.4616],
            [-120.3357, 37.4335],
            [-120.4875, 37.4474],
            [-120.5236, 37.4039],
            [-120.5947, 37.4185],
            [-120.6127, 37.4326],
            [-120.5942, 37.5204],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624630',
        NAME: 'Merced River Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156254553,
        AWATER: 582264,
        INTPTLAT: '+37.4647594',
        INTPTLON: '-120.5182474',
        ELSDLEA: '24630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3561, 37.2738],
            [-120.3182, 37.2734],
            [-120.2563, 37.1562],
            [-120.4008, 37.1178],
            [-120.4195, 37.1951],
            [-120.3561, 37.2738],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630810',
        NAME: 'Plainsburg Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134001315,
        AWATER: 0,
        INTPTLAT: '+37.1869106',
        INTPTLON: '-120.3409506',
        ELSDLEA: '30810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3242, 37.3611],
            [-120.3242, 37.3765],
            [-120.2454, 37.3764],
            [-120.1843, 37.2727],
            [-120.3182, 37.2734],
            [-120.3561, 37.2738],
            [-120.3242, 37.3611],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630840',
        NAME: 'Planada Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128840722,
        AWATER: 0,
        INTPTLAT: '+37.3182166',
        INTPTLON: '-120.2738573',
        ELSDLEA: '30840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5215, 37.5815],
            [-120.5146, 37.5842],
            [-120.3877, 37.6334],
            [-120.2454, 37.3764],
            [-120.3242, 37.3765],
            [-120.3357, 37.4335],
            [-120.4843, 37.4616],
            [-120.4844, 37.5192],
            [-120.5942, 37.5204],
            [-120.5991, 37.5511],
            [-120.5215, 37.5815],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636960',
        NAME: 'Snelling-Merced Falls Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335963876,
        AWATER: 2111728,
        INTPTLAT: '+37.5196214',
        INTPTLON: '-120.4030581',
        ELSDLEA: '36960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4971, 37.2736],
            [-120.3242, 37.3611],
            [-120.3561, 37.2738],
            [-120.4195, 37.1951],
            [-120.5462, 37.1858],
            [-120.4971, 37.2736],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641880',
        NAME: 'Weaver Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188451192,
        AWATER: 218168,
        INTPTLAT: '+37.2695263',
        INTPTLON: '-120.4201125',
        ELSDLEA: '41880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6678, 37.4039],
            [-120.6127, 37.4326],
            [-120.5947, 37.4185],
            [-120.6679, 37.3894],
            [-120.6678, 37.4039],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642960',
        NAME: 'Winton Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21510244,
        AWATER: 0,
        INTPTLAT: '+37.4028975',
        INTPTLON: '-120.6241846',
        ELSDLEA: '42960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5976, 41.4394],
            [-122.503, 41.4399],
            [-122.481, 41.5385],
            [-122.2426, 41.5234],
            [-122.463, 41.4368],
            [-122.4024, 41.3779],
            [-122.5425, 41.3499],
            [-122.5868, 41.3492],
            [-122.5976, 41.4394],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606690',
        NAME: 'Butteville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316567295,
        AWATER: 5801986,
        INTPTLAT: '+41.4513072',
        INTPTLON: '-122.4064452',
        ELSDLEA: '06690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5091, 41.2477],
            [-122.1946, 41.2492],
            [-122.1943, 41.1844],
            [-122.4984, 41.1827],
            [-122.5091, 41.2477],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611670',
        NAME: 'Dunsmuir Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188978945,
        AWATER: 713187,
        INTPTLAT: '+41.2135218',
        INTPTLON: '-122.3924294',
        ELSDLEA: '11670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.474, 41.3264],
            [-123.4324, 41.4687],
            [-123.2631, 41.4904],
            [-123.0387, 41.4912],
            [-123.0432, 41.3594],
            [-122.9469, 41.3567],
            [-122.9454, 41.1814],
            [-122.9734, 41.1121],
            [-122.9093, 41.0429],
            [-122.9142, 40.9981],
            [-123.0438, 41.0141],
            [-123.0865, 41.0579],
            [-123.2452, 41.078],
            [-123.3601, 41.1615],
            [-123.4083, 41.1799],
            [-123.474, 41.3264],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614040',
        NAME: 'Forks of Salmon Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1665841983,
        AWATER: 4125722,
        INTPTLAT: '+41.2663944',
        INTPTLON: '-123.1705167',
        ELSDLEA: '14040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5179, 42.0009],
            [-123.3425, 41.9993],
            [-123.343, 41.8405],
            [-123.2972, 41.8405],
            [-123.2749, 41.696],
            [-123.2767, 41.6361],
            [-123.4988, 41.6395],
            [-123.4968, 41.7342],
            [-123.6724, 41.7337],
            [-123.7037, 41.8295],
            [-123.5654, 41.9031],
            [-123.5179, 42.0009],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616530',
        NAME: 'Happy Camp Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 935410924,
        AWATER: 2946495,
        INTPTLAT: '+41.8189035',
        INTPTLON: '-123.5524829',
        ELSDLEA: '16530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9877, 42.0032],
            [-122.8755, 42.0033],
            [-122.7536, 42.0041],
            [-122.4035, 42.0087],
            [-122.2895, 42.0078],
            [-122.2849, 42.0077],
            [-122.4024, 41.9611],
            [-122.4414, 41.9025],
            [-122.5381, 41.872],
            [-122.5391, 41.8146],
            [-122.578, 41.8143],
            [-122.6768, 41.8561],
            [-122.6757, 41.9305],
            [-122.7711, 41.9303],
            [-122.9856, 41.93],
            [-122.9877, 42.0032],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617620',
        NAME: 'Hornbrook Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 614209159,
        AWATER: 5595527,
        INTPTLAT: '+41.9466483',
        INTPTLON: '-122.6348078',
        ELSDLEA: '17620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6724, 41.7337],
            [-123.4968, 41.7342],
            [-123.4988, 41.6395],
            [-123.2767, 41.6361],
            [-123.2631, 41.4904],
            [-123.4324, 41.4687],
            [-123.474, 41.3264],
            [-123.5012, 41.3826],
            [-123.6614, 41.3821],
            [-123.6124, 41.449],
            [-123.7192, 41.5956],
            [-123.6724, 41.7337],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619230',
        NAME: 'Junction Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 998816177,
        AWATER: 5562358,
        INTPTLAT: '+41.5386075',
        INTPTLON: '-123.4930105',
        ELSDLEA: '19230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1029, 42.0068],
            [-122.9877, 42.0032],
            [-122.9856, 41.93],
            [-122.7711, 41.9303],
            [-122.7929, 41.782],
            [-122.9169, 41.7835],
            [-122.9093, 41.6963],
            [-123.1007, 41.6717],
            [-123.1603, 41.6961],
            [-123.1519, 41.8684],
            [-123.0934, 41.869],
            [-123.1029, 42.0068],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619920',
        NAME: 'Klamath River Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765338501,
        AWATER: 2381164,
        INTPTLAT: '+41.8323271',
        INTPTLON: '-122.9879634',
        ELSDLEA: '19920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3979, 41.7719],
            [-122.2477, 41.8066],
            [-122.1516, 41.8063],
            [-122.1336, 41.6902],
            [-122.2501, 41.6912],
            [-122.2456, 41.6103],
            [-122.3638, 41.6104],
            [-122.4772, 41.6536],
            [-122.4963, 41.6927],
            [-122.3979, 41.7719],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621960',
        NAME: 'Little Shasta Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410839777,
        AWATER: 2017313,
        INTPTLAT: '+41.6982775',
        INTPTLON: '-122.3058184',
        ELSDLEA: '21960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1949, 41.3782],
            [-122.1947, 41.4376],
            [-121.9102, 41.4367],
            [-121.4482, 41.4293],
            [-121.4464, 41.2922],
            [-121.4465, 41.1835],
            [-121.7923, 41.1833],
            [-122.018, 41.1832],
            [-122.0728, 41.1831],
            [-122.1943, 41.1844],
            [-122.1946, 41.2492],
            [-122.1949, 41.3782],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624210',
        NAME: 'McCloud Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1732703284,
        AWATER: 4288433,
        INTPTLAT: '+41.3353643',
        INTPTLON: '-121.8601272',
        ELSDLEA: '24210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.578, 41.8143],
            [-122.5391, 41.8146],
            [-122.3979, 41.7719],
            [-122.4963, 41.6927],
            [-122.5448, 41.7056],
            [-122.5557, 41.7113],
            [-122.578, 41.8143],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625380',
        NAME: 'Montague Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98981797,
        AWATER: 832886,
        INTPTLAT: '+41.7584010',
        INTPTLON: '-122.4997733',
        ELSDLEA: '25380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5425, 41.3499],
            [-122.4024, 41.3779],
            [-122.1949, 41.3782],
            [-122.1946, 41.2492],
            [-122.5091, 41.2477],
            [-122.5425, 41.3499],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626040',
        NAME: 'Mount Shasta Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349886838,
        AWATER: 2286891,
        INTPTLAT: '+41.3045132',
        INTPTLON: '-122.3518278',
        ELSDLEA: '26040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5557, 41.7113],
            [-122.5448, 41.7056],
            [-122.5926, 41.6767],
            [-122.5557, 41.7113],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610980',
        NAME: 'Delphic Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18657421,
        AWATER: 5795,
        INTPTLAT: '+41.6986453',
        INTPTLON: '-122.5806656',
        ELSDLEA: '10980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6522, 41.5768],
            [-122.4797, 41.5774],
            [-122.481, 41.5385],
            [-122.503, 41.4399],
            [-122.5976, 41.4394],
            [-122.6518, 41.4387],
            [-122.6522, 41.5768],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614970',
        NAME: 'Gazelle Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212281048,
        AWATER: 1024331,
        INTPTLAT: '+41.5195091',
        INTPTLON: '-122.5812560',
        ELSDLEA: '14970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4414, 41.9025],
            [-122.4024, 41.9611],
            [-122.2849, 42.0077],
            [-122.1863, 42.0075],
            [-122.2474, 41.9522],
            [-122.2474, 41.8581],
            [-122.4408, 41.8575],
            [-122.4414, 41.9025],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605490',
        NAME: 'Bogus Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221014035,
        AWATER: 3778422,
        INTPTLAT: '+41.9237545',
        INTPTLON: '-122.3120169',
        ELSDLEA: '05490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6521, 41.6103],
            [-122.5926, 41.6767],
            [-122.5448, 41.7056],
            [-122.4963, 41.6927],
            [-122.4772, 41.6536],
            [-122.4797, 41.5774],
            [-122.6522, 41.5768],
            [-122.6521, 41.6103],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616110',
        NAME: 'Grenada Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139536306,
        AWATER: 724519,
        INTPTLAT: '+41.6351005',
        INTPTLON: '-122.5466582',
        ELSDLEA: '16110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1933, 37.1057],
            [-122.0986, 37.0263],
            [-122.1533, 37.0187],
            [-122.1933, 37.1057],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605640',
        NAME: 'Bonny Doon Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74975309,
        AWATER: 0,
        INTPTLAT: '+37.0664956',
        INTPTLON: '-122.1508199',
        ELSDLEA: '05640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0055, 37.0421],
            [-121.9535, 37.0762],
            [-121.9464, 37.0685],
            [-121.9652, 37.043],
            [-121.995, 37.0081],
            [-122.0055, 37.0421],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616560',
        NAME: 'Happy Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17069145,
        AWATER: 0,
        INTPTLAT: '+37.0461541',
        INTPTLON: '-121.9813775',
        ELSDLEA: '16560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9891, 36.9875],
            [-121.9375, 36.9572],
            [-121.9317, 36.8893],
            [-121.9938, 36.8408],
            [-121.9891, 36.9875],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621990',
        NAME: 'Live Oak Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7901999,
        AWATER: 64730231,
        INTPTLAT: '+36.9552013',
        INTPTLON: '-121.9748944',
        ELSDLEA: '21990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9464, 37.0685],
            [-121.8588, 37.0758],
            [-121.9192, 37.0453],
            [-121.9652, 37.043],
            [-121.9464, 37.0685],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626070',
        NAME: 'Mountain Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35868277,
        AWATER: 0,
        INTPTLAT: '+37.0604654',
        INTPTLON: '-121.9179736',
        ELSDLEA: '26070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2427, 37.19],
            [-122.1933, 37.1057],
            [-122.1533, 37.0187],
            [-122.2374, 36.9779],
            [-122.3139, 37.0548],
            [-122.2427, 37.19],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629340',
        NAME: 'Pacific Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127190890,
        AWATER: 62594487,
        INTPTLAT: '+37.1246005',
        INTPTLON: '-122.2452673',
        ELSDLEA: '29340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2374, 36.9779],
            [-122.1533, 37.0187],
            [-122.0986, 37.0263],
            [-122.0471, 37.0464],
            [-122.0055, 37.0421],
            [-121.995, 37.0081],
            [-121.9891, 36.9875],
            [-121.9938, 36.8408],
            [-122.0519, 36.8196],
            [-122.0764, 36.9102],
            [-122.2374, 36.9779],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635590',
        NAME: 'Santa Cruz City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99090437,
        AWATER: 152260163,
        INTPTLAT: '+36.9496534',
        INTPTLON: '-122.0618098',
        ELSDLEA: '35590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.995, 37.0081],
            [-121.9652, 37.043],
            [-121.9192, 37.0453],
            [-121.9375, 36.9572],
            [-121.9891, 36.9875],
            [-121.995, 37.0081],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637290',
        NAME: 'Soquel Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37770506,
        AWATER: 4034269,
        INTPTLAT: '+37.0013895',
        INTPTLON: '-121.9520594',
        ELSDLEA: '37290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3933, 36.4001],
            [-120.2936, 36.4428],
            [-120.1032, 36.4733],
            [-120.0489, 36.357],
            [-120.0489, 36.3279],
            [-120.1576, 36.3131],
            [-120.3922, 36.3269],
            [-120.3933, 36.4001],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642210',
        NAME: 'Westside Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369444416,
        AWATER: 695948,
        INTPTLAT: '+36.3780687',
        INTPTLON: '-120.2206098',
        ELSDLEA: '42210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8445, 36.7139],
            [-119.8268, 36.714],
            [-119.9073, 36.6632],
            [-119.8445, 36.7139],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642060',
        NAME: 'West Park Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33556938,
        AWATER: 0,
        INTPTLAT: '+36.6874223',
        INTPTLON: '-119.8692742',
        ELSDLEA: '42060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2426, 41.5234],
            [-122.2456, 41.6103],
            [-122.2501, 41.6912],
            [-122.1336, 41.6902],
            [-122.1332, 41.6107],
            [-122.0264, 41.6093],
            [-122.0271, 41.5229],
            [-121.9093, 41.5227],
            [-121.9102, 41.4367],
            [-122.1947, 41.4376],
            [-122.1949, 41.3782],
            [-122.4024, 41.3779],
            [-122.463, 41.4368],
            [-122.2426, 41.5234],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641980',
        NAME: 'Weed Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670536446,
        AWATER: 3924110,
        INTPTLAT: '+41.5464321',
        INTPTLON: '-122.1799079',
        ELSDLEA: '41980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5391, 41.8146],
            [-122.5381, 41.872],
            [-122.4414, 41.9025],
            [-122.4408, 41.8575],
            [-122.2474, 41.8581],
            [-122.2477, 41.8066],
            [-122.3979, 41.7719],
            [-122.5391, 41.8146],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642600',
        NAME: 'Willow Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232784019,
        AWATER: 284774,
        INTPTLAT: '+41.8249310',
        INTPTLON: '-122.4086805',
        ELSDLEA: '42600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7711, 41.9303],
            [-122.6757, 41.9305],
            [-122.6768, 41.8561],
            [-122.578, 41.8143],
            [-122.5557, 41.7113],
            [-122.5926, 41.6767],
            [-122.6521, 41.6103],
            [-122.7929, 41.782],
            [-122.7711, 41.9303],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643380',
        NAME: 'Yreka Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417267221,
        AWATER: 1100011,
        INTPTLAT: '+41.7728486',
        INTPTLON: '-122.6949023',
        ELSDLEA: '43380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3425, 41.9993],
            [-123.2308, 42.0039],
            [-123.1029, 42.0068],
            [-123.0934, 41.869],
            [-123.1519, 41.8684],
            [-123.1603, 41.6961],
            [-123.2749, 41.696],
            [-123.2972, 41.8405],
            [-123.343, 41.8405],
            [-123.3425, 41.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636240',
        NAME: 'Seiad Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 524365925,
        AWATER: 1099890,
        INTPTLAT: '+41.9241598',
        INTPTLON: '-123.2607820',
        ELSDLEA: '36240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3832, 34.8944],
            [-120.3553, 34.9413],
            [-120.3099, 35.0092],
            [-120.1881, 35.0312],
            [-120.1015, 35.1135],
            [-120.093, 35.1142],
            [-120.0835, 34.9685],
            [-120.0834, 34.8612],
            [-120.1434, 34.8017],
            [-120.1683, 34.7737],
            [-120.3651, 34.8215],
            [-120.3832, 34.8944],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605280',
        NAME: 'Blochman Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669712881,
        AWATER: 3096949,
        INTPTLAT: '+34.9129854',
        INTPTLON: '-120.1688954',
        ELSDLEA: '05280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5427, 34.9804],
            [-120.4355, 34.9868],
            [-120.3553, 34.9413],
            [-120.3832, 34.8944],
            [-120.521, 34.9311],
            [-120.5427, 34.9804],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605580',
        NAME: 'Santa Maria-Bonita Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124836244,
        AWATER: 2426814,
        INTPTLAT: '+34.9438775',
        INTPTLON: '-120.4477330',
        ELSDLEA: '05580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2629, 34.6869],
            [-120.1892, 34.6824],
            [-120.1497, 34.6501],
            [-120.1362, 34.5386],
            [-120.191, 34.5222],
            [-120.2981, 34.5722],
            [-120.2436, 34.6218],
            [-120.2629, 34.6869],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606330',
        NAME: 'Buellton Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175506234,
        AWATER: 97129,
        INTPTLAT: '+34.5887514',
        INTPTLON: '-120.1987860',
        ELSDLEA: '06330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6703, 34.4658],
            [-119.6428, 34.4658],
            [-119.6532, 34.4222],
            [-119.6703, 34.4658],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609270',
        NAME: 'Cold Spring Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9846180,
        AWATER: 1410,
        INTPTLAT: '+34.4474432',
        INTPTLON: '-119.6555602',
        ELSDLEA: '09270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1038, 34.6503],
            [-120.0656, 34.7712],
            [-119.9987, 34.7191],
            [-119.8482, 34.7339],
            [-119.4414, 34.7333],
            [-119.4424, 34.5611],
            [-119.4423, 34.4655],
            [-119.5917, 34.4658],
            [-119.6428, 34.4658],
            [-119.6703, 34.4658],
            [-119.7286, 34.4802],
            [-119.7556, 34.4946],
            [-119.7557, 34.5235],
            [-120.0038, 34.5249],
            [-120.0073, 34.5476],
            [-120.1044, 34.6067],
            [-120.1038, 34.6503],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609330',
        NAME: 'College Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1484238937,
        AWATER: 14737702,
        INTPTLAT: '+34.6733168',
        INTPTLON: '-119.7436158',
        ELSDLEA: '09330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0038, 34.5249],
            [-119.7557, 34.5235],
            [-119.7556, 34.4946],
            [-119.7875, 34.3609],
            [-119.8985, 34.3604],
            [-120.0223, 34.4083],
            [-120.0038, 34.5249],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615510',
        NAME: 'Goleta Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231909493,
        AWATER: 135287259,
        INTPTLAT: '+34.4534159',
        INTPTLON: '-119.8860449',
        ELSDLEA: '15510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7028, 35],
            [-120.6341, 34.9596],
            [-120.5427, 34.9804],
            [-120.521, 34.9311],
            [-120.5541, 34.886],
            [-120.7283, 34.9282],
            [-120.7028, 35],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616260',
        NAME: 'Guadalupe Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93701098,
        AWATER: 39388360,
        INTPTLAT: '+34.9402685',
        INTPTLON: '-120.6200700',
        ELSDLEA: '16260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7556, 34.4946],
            [-119.7286, 34.4802],
            [-119.7557, 34.3493],
            [-119.7875, 34.3609],
            [-119.7556, 34.4946],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617490',
        NAME: 'Hope Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 25833113,
        AWATER: 17993693,
        INTPTLAT: '+34.4198302',
        INTPTLON: '-119.7664344',
        ELSDLEA: '17490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1434, 34.8017],
            [-120.0834, 34.8612],
            [-120.0835, 34.9685],
            [-119.9836, 34.9698],
            [-119.9829, 34.8959],
            [-119.8011, 34.8959],
            [-119.8014, 34.8212],
            [-119.4761, 34.8195],
            [-119.4414, 34.7333],
            [-119.8482, 34.7339],
            [-119.9987, 34.7191],
            [-120.0656, 34.7712],
            [-120.1038, 34.6503],
            [-120.1424, 34.6501],
            [-120.1497, 34.6501],
            [-120.1892, 34.6824],
            [-120.1434, 34.8017],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622920',
        NAME: 'Los Olivos Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 956088774,
        AWATER: 684617,
        INTPTLAT: '+34.7898396',
        INTPTLON: '-119.8873256',
        ELSDLEA: '22920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6532, 34.4222],
            [-119.6428, 34.4658],
            [-119.5917, 34.4658],
            [-119.6123, 34.3674],
            [-119.66, 34.3595],
            [-119.6532, 34.4222],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625500',
        NAME: 'Montecito Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23980204,
        AWATER: 25051369,
        INTPTLAT: '+34.4168334',
        INTPTLON: '-119.6403251',
        ELSDLEA: '25500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7283, 34.9282],
            [-120.5541, 34.886],
            [-120.521, 34.9311],
            [-120.3832, 34.8944],
            [-120.3651, 34.8215],
            [-120.1683, 34.7737],
            [-120.1434, 34.8017],
            [-120.1892, 34.6824],
            [-120.2629, 34.6869],
            [-120.4298, 34.7339],
            [-120.4809, 34.7744],
            [-120.6956, 34.7786],
            [-120.6733, 34.8386],
            [-120.7283, 34.9282],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628710',
        NAME: 'Orcutt Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574557502,
        AWATER: 114715319,
        INTPTLAT: '+34.8217848',
        INTPTLON: '-120.4589738',
        ELSDLEA: '28710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1497, 34.6501],
            [-120.1424, 34.6501],
            [-120.1044, 34.6067],
            [-120.0073, 34.5476],
            [-120.1362, 34.5386],
            [-120.1497, 34.6501],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637110',
        NAME: 'Solvang Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92273273,
        AWATER: 737915,
        INTPTLAT: '+34.5727281',
        INTPTLON: '-120.0632007',
        ELSDLEA: '37110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2981, 34.5722],
            [-120.191, 34.5222],
            [-120.1362, 34.5386],
            [-120.0073, 34.5476],
            [-120.0038, 34.5249],
            [-120.0223, 34.4083],
            [-120.1351, 34.4221],
            [-120.4177, 34.3976],
            [-120.3745, 34.5929],
            [-120.2981, 34.5722],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641220',
        NAME: 'Vista del Mar Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315072009,
        AWATER: 207580363,
        INTPTLAT: '+34.4796189',
        INTPTLON: '-120.2258295',
        ELSDLEA: '41220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1424, 34.6501],
            [-120.1038, 34.6503],
            [-120.1044, 34.6067],
            [-120.1424, 34.6501],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603720',
        NAME: 'Ballard Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16829615,
        AWATER: 27484,
        INTPTLAT: '+34.6298305',
        INTPTLON: '-120.1232814',
        ELSDLEA: '03720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3525, 32.9987],
            [-117.2553, 33.0114],
            [-117.2419, 33.0212],
            [-117.1527, 33.04],
            [-117.1483, 33.0363],
            [-117.173, 32.9702],
            [-117.3457, 32.9766],
            [-117.3525, 32.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636990',
        NAME: 'Solana Beach Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54589008,
        AWATER: 21634002,
        INTPTLAT: '+32.9907992',
        INTPTLON: '-117.2352710',
        ELSDLEA: '36990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1343, 32.6054],
            [-117.1204, 32.6088],
            [-117.1085, 32.6039],
            [-117.1016, 32.6121],
            [-117.0567, 32.5681],
            [-117.0667, 32.5395],
            [-117.1247, 32.5342],
            [-117.1343, 32.6054],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637380',
        NAME: 'South Bay Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 37247885,
        AWATER: 5935369,
        INTPTLAT: '+32.5685612',
        INTPTLON: '-117.1004576',
        ELSDLEA: '37380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1932, 33.4322],
            [-117.1453, 33.4326],
            [-117.0946, 33.4305],
            [-117.0943, 33.3882],
            [-117.1389, 33.3734],
            [-117.1932, 33.4322],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640710',
        NAME: 'Vallecitos Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52524066,
        AWATER: 0,
        INTPTLAT: '+33.4045790',
        INTPTLON: '-117.1407106',
        ELSDLEA: '40710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.667, 33.1516],
            [-116.6461, 33.1375],
            [-116.6157, 33.1051],
            [-116.6681, 33.0801],
            [-116.667, 33.1516],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637680',
        NAME: 'Spencer Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25349922,
        AWATER: 39165,
        INTPTLAT: '+33.1087882',
        INTPTLON: '-116.6483207',
        ELSDLEA: '37680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7968, 34.6166],
            [-118.6247, 34.6155],
            [-118.5165, 34.6017],
            [-118.5717, 34.44],
            [-118.6071, 34.4353],
            [-118.6914, 34.403],
            [-118.7968, 34.6166],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607740',
        NAME: 'Castaic Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392139720,
        AWATER: 13326662,
        INTPTLAT: '+34.5312777',
        INTPTLON: '-118.6473073',
        ELSDLEA: '07740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1668, 34.8206],
            [-118.0423, 34.822],
            [-118.095, 34.7188],
            [-118.0948, 34.6311],
            [-118.1477, 34.6458],
            [-118.1668, 34.8206],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620880',
        NAME: 'Lancaster Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215732323,
        AWATER: 2349965,
        INTPTLAT: '+34.7407704',
        INTPTLON: '-118.1219466',
        ELSDLEA: '20880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.37, 33.95],
            [-118.3439, 33.9309],
            [-118.3657, 33.9309],
            [-118.37, 33.9309],
            [-118.37, 33.95],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621420',
        NAME: 'Lennox Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4259531,
        AWATER: 0,
        INTPTLAT: '+33.9389861',
        INTPTLON: '-118.3575826',
        ELSDLEA: '21420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.5626, 33.0895],
            [-115.4798, 33.0891],
            [-115.466, 33.0088],
            [-115.4601, 32.953],
            [-115.465, 32.9419],
            [-115.6077, 32.9265],
            [-115.7345, 32.9605],
            [-115.5786, 33.0155],
            [-115.5626, 33.0895],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605790',
        NAME: 'Brawley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233408159,
        AWATER: 1292281,
        INTPTLAT: '+32.9948267',
        INTPTLON: '-115.5548221',
        ELSDLEA: '05790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.595, 32.8183],
            [-115.5357, 32.8253],
            [-115.5395, 32.7669],
            [-115.595, 32.8183],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612030',
        NAME: 'El Centro Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27110166,
        AWATER: 21575,
        INTPTLAT: '+32.7962113',
        INTPTLON: '-115.5625393',
        ELSDLEA: '12030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.3542, 33.1039],
            [-115.3428, 33.4281],
            [-115.251, 33.4282],
            [-115.252, 33.152],
            [-115.2641, 32.9503],
            [-115.4601, 32.953],
            [-115.466, 33.0088],
            [-115.3367, 33.0082],
            [-115.3542, 33.1039],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623460',
        NAME: 'Magnolia Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503735463,
        AWATER: 0,
        INTPTLAT: '+33.1698349',
        INTPTLON: '-115.3166870',
        ELSDLEA: '23460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.7072, 32.7231],
            [-115.6182, 32.8255],
            [-115.595, 32.8183],
            [-115.5395, 32.7669],
            [-115.5176, 32.7588],
            [-115.5426, 32.7085],
            [-115.64, 32.709],
            [-115.692, 32.65],
            [-115.8433, 32.6387],
            [-115.8433, 32.7139],
            [-115.7072, 32.7231],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624180',
        NAME: 'McCabe Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240382189,
        AWATER: 23943,
        INTPTLAT: '+32.7121921',
        INTPTLON: '-115.6824550',
        ELSDLEA: '24180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.5357, 32.8253],
            [-115.4727, 32.8249],
            [-115.4785, 32.7594],
            [-115.5176, 32.7588],
            [-115.5395, 32.7669],
            [-115.5357, 32.8253],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624390',
        NAME: 'Meadows Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48464334,
        AWATER: 0,
        INTPTLAT: '+32.7896667',
        INTPTLON: '-115.4994226',
        ELSDLEA: '24390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.4798, 33.0891],
            [-115.3542, 33.1039],
            [-115.3367, 33.0082],
            [-115.466, 33.0088],
            [-115.4798, 33.0891],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626430',
        NAME: 'Mulberry Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138219413,
        AWATER: 0,
        INTPTLAT: '+33.0557324',
        INTPTLON: '-115.4124063',
        ELSDLEA: '26430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.6182, 32.8255],
            [-115.7072, 32.7231],
            [-115.7049, 32.8108],
            [-115.6182, 32.8255],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636210',
        NAME: 'Seeley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59294088,
        AWATER: 132020,
        INTPTLAT: '+32.7781455',
        INTPTLON: '-115.6668338',
        ELSDLEA: '36210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1033, 33.0747],
            [-116.082, 33.235],
            [-115.6684, 33.2345],
            [-115.6679, 33.0891],
            [-115.5626, 33.0895],
            [-115.5786, 33.0155],
            [-115.7345, 32.9605],
            [-115.7791, 32.929],
            [-116.103, 32.9471],
            [-116.1033, 33.0747],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642180',
        NAME: 'Westmorland Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1153941367,
        AWATER: 197363555,
        INTPTLAT: '+33.0171389',
        INTPTLON: '-115.8499737',
        ELSDLEA: '42180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.5176, 32.7588],
            [-115.4785, 32.7594],
            [-115.4827, 32.7304],
            [-115.5426, 32.7085],
            [-115.5176, 32.7588],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616830',
        NAME: 'Heber Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30169174,
        AWATER: 0,
        INTPTLAT: '+32.7325009',
        INTPTLON: '-115.5114122',
        ELSDLEA: '16830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.736, 36.3281],
            [-119.691, 36.3281],
            [-119.664, 36.2983],
            [-119.6369, 36.255],
            [-119.6727, 36.1598],
            [-119.7339, 36.1379],
            [-119.7359, 36.284],
            [-119.736, 36.3281],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603180',
        NAME: 'Armona Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127681681,
        AWATER: 2435692,
        INTPTLAT: '+36.2355205',
        INTPTLON: '-119.7049424',
        ELSDLEA: '03180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9589, 36.1814],
            [-119.9589, 36.2555],
            [-119.9589, 36.2991],
            [-119.8514, 36.2988],
            [-119.7359, 36.284],
            [-119.7339, 36.1379],
            [-119.7538, 36.051],
            [-119.8956, 36.0518],
            [-119.8969, 36.1379],
            [-119.9589, 36.1814],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607980',
        NAME: 'Central Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455482412,
        AWATER: 306460,
        INTPTLAT: '+36.1791004',
        INTPTLON: '-119.8441233',
        ELSDLEA: '07980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6371, 36.3574],
            [-119.6187, 36.3572],
            [-119.6301, 36.3058],
            [-119.664, 36.2983],
            [-119.691, 36.3281],
            [-119.6371, 36.3574],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616470',
        NAME: 'Hanford Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33566156,
        AWATER: 0,
        INTPTLAT: '+36.3308184',
        INTPTLON: '-119.6505226',
        ELSDLEA: '16470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7674, 36.3898],
            [-119.7745, 36.3794],
            [-119.8514, 36.2988],
            [-119.9589, 36.2991],
            [-119.898, 36.385],
            [-119.7674, 36.3898],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618510',
        NAME: 'Island Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110117659,
        AWATER: 15365,
        INTPTLAT: '+36.3477747',
        INTPTLON: '-119.8823039',
        ELSDLEA: '18510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7674, 36.3898],
            [-119.7531, 36.4019],
            [-119.7098, 36.4238],
            [-119.6669, 36.4187],
            [-119.6427, 36.4299],
            [-119.5429, 36.4452],
            [-119.475, 36.3905],
            [-119.6187, 36.3572],
            [-119.6371, 36.3574],
            [-119.736, 36.3641],
            [-119.7745, 36.3794],
            [-119.7674, 36.3898],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619770',
        NAME: 'Kings River-Hardwick Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116587058,
        AWATER: 0,
        INTPTLAT: '+36.4024904',
        INTPTLON: '-119.6194028',
        ELSDLEA: '19770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6187, 36.3572],
            [-119.475, 36.3905],
            [-119.4561, 36.2688],
            [-119.5017, 36.2478],
            [-119.5294, 36.2482],
            [-119.6301, 36.3058],
            [-119.6187, 36.3572],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619890',
        NAME: 'Kit Carson Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180049131,
        AWATER: 0,
        INTPTLAT: '+36.3217928',
        INTPTLON: '-119.5328572',
        ELSDLEA: '19890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.664, 36.2983],
            [-119.6301, 36.3058],
            [-119.5294, 36.2482],
            [-119.547, 36.2111],
            [-119.6727, 36.1598],
            [-119.6369, 36.255],
            [-119.664, 36.2983],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620760',
        NAME: 'Lakeside Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 143100581,
        AWATER: 33302,
        INTPTLAT: '+36.2328593',
        INTPTLON: '-119.6092332',
        ELSDLEA: '20760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7745, 36.3794],
            [-119.736, 36.3641],
            [-119.736, 36.3281],
            [-119.7359, 36.284],
            [-119.8514, 36.2988],
            [-119.7745, 36.3794],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621360',
        NAME: 'Lemoore Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95790812,
        AWATER: 0,
        INTPTLAT: '+36.3196738',
        INTPTLON: '-119.7919975',
        ELSDLEA: '21360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3481, 34.5787],
            [-117.2633, 34.5862],
            [-117.2547, 34.4709],
            [-117.3731, 34.4704],
            [-117.3481, 34.5787],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641040',
        NAME: 'Victor Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100699264,
        AWATER: 2351811,
        INTPTLAT: '+34.5161332',
        INTPTLON: '-117.3164248',
        ELSDLEA: '41040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6673, 34.8225],
            [-117.4545, 34.821],
            [-117.4543, 34.7029],
            [-117.3834, 34.7013],
            [-117.3481, 34.5787],
            [-117.3731, 34.4704],
            [-117.3995, 34.4703],
            [-117.3995, 34.5067],
            [-117.6601, 34.4986],
            [-117.667, 34.558],
            [-117.6671, 34.6902],
            [-117.6673, 34.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601710',
        NAME: 'Adelanto Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 868470886,
        AWATER: 832474,
        INTPTLAT: '+34.6306380',
        INTPTLON: '-117.5205628',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3834, 34.7013],
            [-117.2421, 34.7029],
            [-117.2416, 34.7616],
            [-117.2072, 34.6409],
            [-117.2633, 34.5862],
            [-117.3481, 34.5787],
            [-117.3834, 34.7013],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628950',
        NAME: 'Oro Grande Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199015683,
        AWATER: 1258849,
        INTPTLAT: '+34.6599126',
        INTPTLON: '-117.2900047',
        ELSDLEA: '28950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6286, 34.0926],
            [-117.6285, 34.0994],
            [-117.558, 34.1023],
            [-117.5154, 34.0772],
            [-117.5243, 34.0335],
            [-117.5583, 34.0335],
            [-117.5977, 34.0561],
            [-117.6286, 34.0926],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616300',
        NAME: 'Cucamonga Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53817047,
        AWATER: 137085,
        INTPTLAT: '+34.0729245',
        INTPTLON: '-117.5604440',
        ELSDLEA: '16300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5977, 34.0561],
            [-117.5583, 34.0335],
            [-117.5584, 33.9884],
            [-117.6069, 33.9728],
            [-117.606, 34.0305],
            [-117.5977, 34.0561],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626220',
        NAME: 'Mountain View Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 35311651,
        AWATER: 22016,
        INTPTLAT: '+34.0177400',
        INTPTLON: '-117.5840258',
        ELSDLEA: '26220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6193, 34.1216],
            [-117.5585, 34.1215],
            [-117.558, 34.1023],
            [-117.6285, 34.0994],
            [-117.6193, 34.1216],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607950',
        NAME: 'Central Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14618057,
        AWATER: 3447,
        INTPTLAT: '+34.1120553',
        INTPTLON: '-117.5959570',
        ELSDLEA: '07950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5587, 34.2191],
            [-117.4645, 34.2201],
            [-117.4668, 34.1214],
            [-117.5154, 34.0772],
            [-117.558, 34.1023],
            [-117.5585, 34.1215],
            [-117.5587, 34.2191],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612960',
        NAME: 'Etiwanda Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110544609,
        AWATER: 21276,
        INTPTLAT: '+34.1590742',
        INTPTLON: '-117.5072989',
        ELSDLEA: '12960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.7111, 34.0795],
            [-117.7046, 34.0944],
            [-117.6286, 34.0926],
            [-117.5977, 34.0561],
            [-117.606, 34.0305],
            [-117.7245, 34.0447],
            [-117.7111, 34.0795],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628470',
        NAME: 'Ontario-Montclair Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62822068,
        AWATER: 0,
        INTPTLAT: '+34.0619129',
        INTPTLON: '-117.6577523',
        ELSDLEA: '28470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6301, 34.2199],
            [-117.5587, 34.2191],
            [-117.5585, 34.1215],
            [-117.6193, 34.1216],
            [-117.6301, 34.2199],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602160',
        NAME: 'Alta Loma Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71316395,
        AWATER: 0,
        INTPTLAT: '+34.1707962',
        INTPTLON: '-117.5952274',
        ELSDLEA: '02160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4545, 34.821],
            [-117.2402, 34.8203],
            [-117.2416, 34.7616],
            [-117.2421, 34.7029],
            [-117.3834, 34.7013],
            [-117.4543, 34.7029],
            [-117.4545, 34.821],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616860',
        NAME: 'Helendale Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255064106,
        AWATER: 1917254,
        INTPTLAT: '+34.7606773',
        INTPTLON: '-117.3501035',
        ELSDLEA: '16860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5272, 36.4889],
            [-119.476, 36.4886],
            [-119.4219, 36.4728],
            [-119.3856, 36.4585],
            [-119.475, 36.3905],
            [-119.5429, 36.4452],
            [-119.5272, 36.4889],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639600',
        NAME: 'Traver Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99358952,
        AWATER: 54911,
        INTPTLAT: '+36.4416344',
        INTPTLON: '-119.4769770',
        ELSDLEA: '39600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6075, 39.3155],
            [-120.567, 39.2153],
            [-120.6821, 39.2023],
            [-120.8429, 39.1478],
            [-120.9238, 39.1588],
            [-120.7279, 39.2881],
            [-120.6075, 39.3155],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602250',
        NAME: 'Alta-Dutch Flat Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273768468,
        AWATER: 2014405,
        INTPTLAT: '+39.2246574',
        INTPTLON: '-120.7182119',
        ELSDLEA: '02250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.974, 39.1175],
            [-120.9475, 39.1434],
            [-120.9238, 39.1588],
            [-120.8429, 39.1478],
            [-120.6821, 39.2023],
            [-120.6814, 39.1372],
            [-120.7856, 39.1329],
            [-120.902, 39.0435],
            [-120.9981, 39.0707],
            [-120.974, 39.1175],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609300',
        NAME: 'Colfax Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199729349,
        AWATER: 1591914,
        INTPTLAT: '+39.1118328',
        INTPTLON: '-120.8779689',
        ELSDLEA: '09300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1878, 39.0125],
            [-121.0795, 39.0069],
            [-121.0609, 38.9535],
            [-121.04, 38.9283],
            [-121.0375, 38.9157],
            [-121.1014, 38.8153],
            [-121.1175, 38.8547],
            [-121.0988, 38.8837],
            [-121.1725, 38.9196],
            [-121.1878, 39.0125],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603480',
        NAME: 'Auburn Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153926916,
        AWATER: 2151549,
        INTPTLAT: '+38.9388974',
        INTPTLON: '-121.1223412',
        ELSDLEA: '03480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1725, 38.9196],
            [-121.0988, 38.8837],
            [-121.182, 38.8833],
            [-121.1543, 38.8543],
            [-121.1175, 38.8547],
            [-121.1014, 38.8153],
            [-121.121, 38.7525],
            [-121.2243, 38.7596],
            [-121.2178, 38.7704],
            [-121.2314, 38.8392],
            [-121.1725, 38.9196],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622560',
        NAME: 'Loomis Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130498327,
        AWATER: 7971930,
        INTPTLAT: '+38.8307247',
        INTPTLON: '-121.1621072',
        ELSDLEA: '22560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0795, 39.0069],
            [-120.9981, 39.0707],
            [-120.902, 39.0435],
            [-121.0262, 38.9358],
            [-121.0609, 38.9535],
            [-121.0795, 39.0069],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630720',
        NAME: 'Placer Hills Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132866089,
        AWATER: 1268068,
        INTPTLAT: '+39.0091548',
        INTPTLON: '-120.9948566',
        ELSDLEA: '30720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.736, 36.3641],
            [-119.6371, 36.3574],
            [-119.691, 36.3281],
            [-119.736, 36.3281],
            [-119.736, 36.3641],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630510',
        NAME: 'Pioneer Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 45769224,
        AWATER: 0,
        INTPTLAT: '+36.3608860',
        INTPTLON: '-119.6993605',
        ELSDLEA: '30510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5417, 37.0445],
            [-120.4008, 37.1178],
            [-120.2568, 37.0839],
            [-120.2568, 37.0401],
            [-120.2576, 36.9709],
            [-120.4586, 36.9699],
            [-120.5006, 36.9952],
            [-120.5417, 37.0445],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602360',
        NAME: 'Alview-Dairyland Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299675181,
        AWATER: 1778270,
        INTPTLAT: '+37.0286980',
        INTPTLON: '-120.3875169',
        ELSDLEA: '02360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.78, 37.4026],
            [-119.6512, 37.4178],
            [-119.652, 37.4979],
            [-119.5037, 37.6239],
            [-119.4645, 37.626],
            [-119.4624, 37.4002],
            [-119.5091, 37.2852],
            [-119.6549, 37.2397],
            [-119.6733, 37.3022],
            [-119.7639, 37.302],
            [-119.78, 37.4026],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604080',
        NAME: 'Bass Lake Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 721625325,
        AWATER: 4871530,
        INTPTLAT: '+37.4169095',
        INTPTLON: '-119.5990979',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4008, 37.1178],
            [-120.2563, 37.1562],
            [-120.0546, 37.1821],
            [-120.0546, 37.0836],
            [-120.2568, 37.0401],
            [-120.2568, 37.0839],
            [-120.4008, 37.1178],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608520',
        NAME: 'Chowchilla Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284552691,
        AWATER: 497514,
        INTPTLAT: '+37.1054698',
        INTPTLON: '-120.1974536',
        ELSDLEA: '08520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9073, 35.5462],
            [-120.8019, 35.5027],
            [-120.8018, 35.4644],
            [-120.8376, 35.4644],
            [-120.9276, 35.3334],
            [-120.9841, 35.4076],
            [-120.9712, 35.5109],
            [-120.9073, 35.5462],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607840',
        NAME: 'Cayucos Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126992463,
        AWATER: 73156961,
        INTPTLAT: '+35.4786049',
        INTPTLON: '-120.8947470',
        ELSDLEA: '07840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3585, 38.7662],
            [-121.3088, 38.7228],
            [-121.3347, 38.7035],
            [-121.3645, 38.7039],
            [-121.3585, 38.7662],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611490',
        NAME: 'Dry Creek Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36213664,
        AWATER: 0,
        INTPTLAT: '+38.7432107',
        INTPTLON: '-121.3369069',
        ELSDLEA: '11490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.433, 38.8243],
            [-121.3002, 38.8246],
            [-121.2178, 38.7704],
            [-121.2243, 38.7596],
            [-121.2361, 38.7177],
            [-121.3088, 38.7228],
            [-121.3585, 38.7662],
            [-121.4142, 38.7952],
            [-121.4696, 38.7947],
            [-121.433, 38.8243],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633600',
        NAME: 'Roseville City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110095095,
        AWATER: 3366,
        INTPTLAT: '+38.7839992',
        INTPTLON: '-121.3361317',
        ELSDLEA: '33600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2243, 38.7596],
            [-121.121, 38.7525],
            [-121.141, 38.712],
            [-121.1938, 38.7147],
            [-121.2361, 38.7177],
            [-121.2243, 38.7596],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613080',
        NAME: 'Eureka Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37232210,
        AWATER: 12566950,
        INTPTLAT: '+38.7378177',
        INTPTLON: '-121.1875273',
        ELSDLEA: '13080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0988, 38.8837],
            [-121.1175, 38.8547],
            [-121.1543, 38.8543],
            [-121.182, 38.8833],
            [-121.0988, 38.8837],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627120',
        NAME: 'Newcastle Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20827184,
        AWATER: 50642,
        INTPTLAT: '+38.8755847',
        INTPTLON: '-121.1447472',
        ELSDLEA: '27120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5583, 38.7363],
            [-121.4696, 38.7947],
            [-121.4142, 38.7952],
            [-121.4434, 38.7049],
            [-121.558, 38.7001],
            [-121.5583, 38.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612600',
        NAME: 'Elverta Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64094764,
        AWATER: 36883,
        INTPTLAT: '+38.7392057',
        INTPTLON: '-121.4806566',
        ELSDLEA: '12600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4036, 35.4567],
            [-119.3326, 35.4562],
            [-119.172, 35.4487],
            [-119.1721, 35.3465],
            [-119.2966, 35.2964],
            [-119.3412, 35.3621],
            [-119.4036, 35.4567],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632710',
        NAME: 'Rio Bravo-Greeley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244578488,
        AWATER: 699775,
        INTPTLAT: '+35.3813948',
        INTPTLON: '-119.2719540',
        ELSDLEA: '32710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0262, 38.9358],
            [-120.902, 39.0435],
            [-120.7856, 39.1329],
            [-120.6814, 39.1372],
            [-120.6821, 39.2023],
            [-120.567, 39.2153],
            [-120.3242, 39.2492],
            [-120.2649, 39.1858],
            [-120.2591, 39.0247],
            [-120.4352, 39.0284],
            [-120.471, 38.9655],
            [-120.5724, 38.9144],
            [-120.7302, 39.004],
            [-120.9347, 38.9638],
            [-121.0375, 38.9157],
            [-121.04, 38.9283],
            [-121.0262, 38.9358],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613980',
        NAME: 'Foresthill Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1277891056,
        AWATER: 10171685,
        INTPTLAT: '+39.0828051',
        INTPTLON: '-120.5640417',
        ELSDLEA: '13980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0609, 38.9535],
            [-121.0262, 38.9358],
            [-121.04, 38.9283],
            [-121.0609, 38.9535],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601680',
        NAME: 'Ackerman Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11308882,
        AWATER: 7628,
        INTPTLAT: '+38.9366318',
        INTPTLON: '-121.0507823',
        ELSDLEA: '01680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.172, 35.4487],
            [-119.1632, 35.4487],
            [-119.11, 35.4053],
            [-119.1152, 35.3544],
            [-119.1721, 35.3465],
            [-119.172, 35.4487],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633480',
        NAME: 'Rosedale Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64210185,
        AWATER: 106480,
        INTPTLAT: '+35.3943675',
        INTPTLON: '-119.1588324',
        ELSDLEA: '33480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0039, 40.9426],
            [-124.0242, 40.9427],
            [-124.0433, 40.9424],
            [-124.0592, 40.9846],
            [-124.0034, 40.9843],
            [-124.0039, 40.9426],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613740',
        NAME: 'Fieldbrook Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21670212,
        AWATER: 5749,
        INTPTLAT: '+40.9630227',
        INTPTLON: '-124.0302533',
        ELSDLEA: '13740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.169, 40.7518],
            [-124.0835, 40.7591],
            [-124.0836, 40.7224],
            [-124.169, 40.7518],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610380',
        NAME: 'Cutten Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30325438,
        AWATER: 0,
        INTPTLAT: '+40.7432832',
        INTPTLON: '-124.1253894',
        ELSDLEA: '10380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0422, 40.6064],
            [-124.0067, 40.6063],
            [-123.8917, 40.5923],
            [-123.8924, 40.4735],
            [-124.0242, 40.5047],
            [-124.0422, 40.6064],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610230',
        NAME: 'Cuddeback Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164741266,
        AWATER: 322415,
        INTPTLAT: '+40.5460849',
        INTPTLON: '-123.9658274',
        ELSDLEA: '10230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.361, 35.7906],
            [-118.0198, 35.7898],
            [-118.0232, 35.6185],
            [-118.1655, 35.5577],
            [-118.157, 35.449],
            [-118.2716, 35.4502],
            [-118.2703, 35.3628],
            [-118.378, 35.3626],
            [-118.3791, 35.4431],
            [-118.3788, 35.6887],
            [-118.361, 35.7906],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637470',
        NAME: 'South Fork Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1116269871,
        AWATER: 2941450,
        INTPTLAT: '+35.6176875',
        INTPTLON: '-118.1932133',
        ELSDLEA: '37470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4836, 35.2675],
            [-119.4659, 35.2676],
            [-119.3413, 35.2536],
            [-119.2893, 35.1804],
            [-119.2894, 35.0939],
            [-119.4727, 35.0769],
            [-119.4988, 35.0913],
            [-119.4836, 35.2675],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638700',
        NAME: 'Taft City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302119005,
        AWATER: 896580,
        INTPTLAT: '+35.1611201',
        INTPTLON: '-119.4053499',
        ELSDLEA: '38700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1005, 40.837],
            [-123.99, 40.8538],
            [-123.964, 40.8189],
            [-123.9866, 40.7957],
            [-124.1009, 40.8165],
            [-124.1005, 40.837],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618660',
        NAME: 'Jacoby Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54877172,
        AWATER: 5079096,
        INTPTLAT: '+40.8314593',
        INTPTLON: '-124.0303996',
        ELSDLEA: '18660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0061, 40.7654],
            [-123.9866, 40.7957],
            [-123.964, 40.8189],
            [-123.9106, 40.7805],
            [-123.8564, 40.6852],
            [-123.7783, 40.6436],
            [-123.8917, 40.5923],
            [-124.0067, 40.6063],
            [-124.0061, 40.7654],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619980',
        NAME: 'Kneeland Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312380254,
        AWATER: 411149,
        INTPTLAT: '+40.6871304',
        INTPTLON: '-123.9238418',
        ELSDLEA: '19980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2345, 40.7568],
            [-124.1958, 40.6509],
            [-124.1959, 40.6134],
            [-124.3129, 40.6424],
            [-124.2345, 40.7568],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622320',
        NAME: 'Loleta Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63199322,
        AWATER: 12993333,
        INTPTLAT: '+40.6643228',
        INTPTLON: '-124.2504563',
        ELSDLEA: '22320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.9106, 40.7805],
            [-123.6993, 40.7636],
            [-123.5501, 40.764],
            [-123.544, 40.7329],
            [-123.5445, 40.6938],
            [-123.6632, 40.6935],
            [-123.6702, 40.6023],
            [-123.7783, 40.6436],
            [-123.8564, 40.6852],
            [-123.9106, 40.7805],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623730',
        NAME: 'Maple Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373225314,
        AWATER: 516896,
        INTPTLAT: '+40.7286612',
        INTPTLON: '-123.7373295',
        ELSDLEA: '23730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1161, 40.9997],
            [-123.8866, 40.9994],
            [-123.8865, 40.9424],
            [-124.0039, 40.9426],
            [-124.0034, 40.9843],
            [-124.0592, 40.9846],
            [-124.0433, 40.9424],
            [-124.0643, 40.9197],
            [-124.1349, 40.9337],
            [-124.1161, 40.9997],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624300',
        NAME: 'McKinleyville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121986520,
        AWATER: 725900,
        INTPTLAT: '+40.9771144',
        INTPTLON: '-123.9541503',
        ELSDLEA: '24300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0652, 41.4647],
            [-123.9976, 41.4643],
            [-123.998, 41.2673],
            [-124.0991, 41.2671],
            [-124.0652, 41.4647],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628830',
        NAME: 'Orick Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144458117,
        AWATER: 1061928,
        INTPTLAT: '+41.3572961',
        INTPTLON: '-124.0486535',
        ELSDLEA: '28830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1349, 40.9337],
            [-124.0643, 40.9197],
            [-124.0244, 40.8977],
            [-124.1626, 40.8687],
            [-124.1349, 40.9337],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629430',
        NAME: 'Pacific Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 39492396,
        AWATER: 2185888,
        INTPTLAT: '+40.9026900',
        INTPTLON: '-124.1008124',
        ELSDLEA: '29430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0836, 40.7224],
            [-124.0835, 40.7591],
            [-124.0836, 40.7655],
            [-124.0061, 40.7654],
            [-124.0067, 40.6063],
            [-124.0422, 40.6064],
            [-124.0842, 40.6066],
            [-124.0836, 40.7224],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614910',
        NAME: 'Garfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115726451,
        AWATER: 12470,
        INTPTLAT: '+40.7127515',
        INTPTLON: '-124.0474331',
        ELSDLEA: '14910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9501, 35.2524],
            [-118.8606, 35.2672],
            [-118.8423, 35.2236],
            [-118.9501, 35.1365],
            [-118.95, 35.2231],
            [-118.9501, 35.2524],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641130',
        NAME: 'Vineland Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87933130,
        AWATER: 85681,
        INTPTLAT: '+35.2014549',
        INTPTLON: '-118.9053287',
        ELSDLEA: '41130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1009, 40.8165],
            [-123.9866, 40.7957],
            [-124.0061, 40.7654],
            [-124.0836, 40.7655],
            [-124.1009, 40.8165],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614520',
        NAME: 'Freshwater Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56145894,
        AWATER: 403016,
        INTPTLAT: '+40.7797089',
        INTPTLON: '-124.0300034',
        ELSDLEA: '14520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6334, 35.7027],
            [-119.4906, 35.7028],
            [-119.4374, 35.6743],
            [-119.4385, 35.573],
            [-119.5458, 35.5718],
            [-119.6355, 35.5725],
            [-119.6334, 35.7027],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636330',
        NAME: 'Semitropic Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269443504,
        AWATER: 0,
        INTPTLAT: '+35.6352837',
        INTPTLON: '-119.5454297',
        ELSDLEA: '36330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.9256, 41.0895],
            [-123.9256, 41.1193],
            [-123.7988, 41.1208],
            [-123.7708, 40.9408],
            [-123.7163, 40.8972],
            [-123.8867, 40.8979],
            [-123.8865, 40.9424],
            [-123.8866, 40.9994],
            [-123.9256, 41.0895],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615990',
        NAME: 'Green Point Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273614836,
        AWATER: 312253,
        INTPTLAT: '+41.0149072',
        INTPTLON: '-123.8446443',
        ELSDLEA: '15990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0941, 40.6066],
            [-124.0842, 40.6066],
            [-124.0422, 40.6064],
            [-124.0242, 40.5047],
            [-124.094, 40.512],
            [-124.0941, 40.6066],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618120',
        NAME: 'Hydesville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56091791,
        AWATER: 130583,
        INTPTLAT: '+40.5508497',
        INTPTLON: '-124.0815039',
        ELSDLEA: '18120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5458, 35.5718],
            [-119.4385, 35.573],
            [-119.4374, 35.6743],
            [-119.3116, 35.6742],
            [-119.241, 35.6309],
            [-119.2421, 35.5584],
            [-119.332, 35.5362],
            [-119.5464, 35.5141],
            [-119.5458, 35.5718],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641400',
        NAME: 'Wasco Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314274739,
        AWATER: 5071,
        INTPTLAT: '+35.5902801',
        INTPTLON: '-119.3804652',
        ELSDLEA: '41400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0293, 35.5301],
            [-118.9592, 35.5296],
            [-119.0032, 35.4087],
            [-119.0293, 35.5301],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637890',
        NAME: 'Standard Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96952975,
        AWATER: 219307,
        INTPTLAT: '+35.4719539',
        INTPTLON: '-118.9992506',
        ELSDLEA: '37890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8322, 37.9171],
            [-121.7324, 37.9832],
            [-121.696, 37.9688],
            [-121.6588, 37.9256],
            [-121.765, 37.8366],
            [-121.8321, 37.8544],
            [-121.8322, 37.9171],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605910',
        NAME: 'Brentwood Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146178985,
        AWATER: 240923,
        INTPTLAT: '+37.9105080',
        INTPTLON: '-121.7483390',
        ELSDLEA: '05910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6588, 37.9256],
            [-121.5656, 37.9182],
            [-121.5551, 37.8174],
            [-121.5569, 37.8172],
            [-121.6203, 37.8003],
            [-121.7496, 37.7695],
            [-121.765, 37.8366],
            [-121.6588, 37.9256],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606750',
        NAME: 'Byron Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191449708,
        AWATER: 11821323,
        INTPTLAT: '+37.8516642',
        INTPTLON: '-121.6611394',
        ELSDLEA: '06750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1811, 40.8369],
            [-124.1698, 40.8369],
            [-124.2194, 40.766],
            [-124.2341, 40.7653],
            [-124.1811, 40.8369],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630090',
        NAME: 'Peninsula Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10326342,
        AWATER: 5741,
        INTPTLAT: '+40.7960567',
        INTPTLON: '-124.2033459',
        ELSDLEA: '30090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1557, 41.0902],
            [-123.9256, 41.0895],
            [-123.8866, 40.9994],
            [-124.1161, 40.9997],
            [-124.1557, 41.0902],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639720',
        NAME: 'Trinidad Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195849269,
        AWATER: 122393,
        INTPTLAT: '+41.0454689',
        INTPTLON: '-124.0208165',
        ELSDLEA: '39720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1518, 40.5111],
            [-124.094, 40.5058],
            [-124.0627, 40.4196],
            [-124.1212, 40.4196],
            [-124.1961, 40.4196],
            [-124.1518, 40.5111],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632730',
        NAME: 'Rio Dell Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82040403,
        AWATER: 727218,
        INTPTLAT: '+40.4411363',
        INTPTLON: '-124.0999128',
        ELSDLEA: '32730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2345, 40.7568],
            [-124.2341, 40.7653],
            [-124.2194, 40.766],
            [-124.169, 40.7518],
            [-124.0836, 40.7224],
            [-124.0842, 40.6066],
            [-124.0941, 40.6066],
            [-124.1958, 40.6509],
            [-124.2345, 40.7568],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637350',
        NAME: 'South Bay Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 121631381,
        AWATER: 14873601,
        INTPTLAT: '+40.6865049',
        INTPTLON: '-124.1567492',
        ELSDLEA: '37350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.094, 40.5058],
            [-124.094, 40.512],
            [-124.0242, 40.5047],
            [-123.8924, 40.4735],
            [-123.7968, 40.3786],
            [-123.9689, 40.3572],
            [-124.0627, 40.4196],
            [-124.094, 40.5058],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636100',
        NAME: 'Scotia Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229378921,
        AWATER: 2428219,
        INTPTLAT: '+40.4289757',
        INTPTLON: '-123.9541209',
        ELSDLEA: '36100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.696, 37.9688],
            [-121.5798, 38.0064],
            [-121.5656, 37.9182],
            [-121.6588, 37.9256],
            [-121.696, 37.9688],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620040',
        NAME: 'Knightsen Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75709570,
        AWATER: 4773321,
        INTPTLAT: '+37.9651673',
        INTPTLON: '-121.6123003',
        ELSDLEA: '20040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1612, 37.9239],
            [-122.0985, 37.9477],
            [-122.0919, 37.917],
            [-122.0616, 37.843],
            [-122.0631, 37.8371],
            [-122.143, 37.8685],
            [-122.1612, 37.9239],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620310',
        NAME: 'Lafayette Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48625464,
        AWATER: 283790,
        INTPTLAT: '+37.8962040',
        INTPTLON: '-122.1160684',
        ELSDLEA: '20310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.143, 37.8685],
            [-122.0631, 37.8371],
            [-122.0643, 37.7994],
            [-122.1328, 37.804],
            [-122.1613, 37.8402],
            [-122.143, 37.8685],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625740',
        NAME: 'Moraga Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44945326,
        AWATER: 135733,
        INTPTLAT: '+37.8319712',
        INTPTLON: '-122.1059224',
        ELSDLEA: '25740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2204, 37.948],
            [-122.1612, 37.9239],
            [-122.143, 37.8685],
            [-122.1613, 37.8402],
            [-122.1889, 37.8376],
            [-122.2469, 37.8854],
            [-122.2563, 37.897],
            [-122.2204, 37.948],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628860',
        NAME: 'Orinda Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65720527,
        AWATER: 3838302,
        INTPTLAT: '+37.8929056',
        INTPTLON: '-122.1955431',
        ELSDLEA: '28860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0919, 37.917],
            [-122.0072, 37.8824],
            [-122.0616, 37.843],
            [-122.0919, 37.917],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641250',
        NAME: 'Walnut Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35882123,
        AWATER: 15373,
        INTPTLAT: '+37.8867300',
        INTPTLON: '-122.0544561',
        ELSDLEA: '41250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7326, 38.029],
            [-121.6619, 38.0956],
            [-121.58, 38.0944],
            [-121.5729, 38.0742],
            [-121.5798, 38.0064],
            [-121.696, 37.9688],
            [-121.7324, 37.9832],
            [-121.7326, 38.029],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628080',
        NAME: 'Oakley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101491634,
        AWATER: 32472047,
        INTPTLAT: '+38.0341677',
        INTPTLON: '-121.6636988',
        ELSDLEA: '28080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1649, 34.4076],
            [-119.1361, 34.4126],
            [-119.0802, 34.3129],
            [-119.1294, 34.2884],
            [-119.1649, 34.4076],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606030',
        NAME: 'Briggs Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56357270,
        AWATER: 1186892,
        INTPTLAT: '+34.3587415',
        INTPTLON: '-119.1331432',
        ELSDLEA: '06030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4845, 38.7005],
            [-122.4273, 38.753],
            [-122.3271, 38.6386],
            [-122.3052, 38.5337],
            [-122.4105, 38.5608],
            [-122.4851, 38.6074],
            [-122.4845, 38.7005],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631380',
        NAME: 'Pope Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247543482,
        AWATER: 2905785,
        INTPTLAT: '+38.6435723',
        INTPTLON: '-122.4056131',
        ELSDLEA: '31380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4851, 38.6074],
            [-122.4105, 38.5608],
            [-122.4853, 38.5661],
            [-122.4851, 38.6074],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617760',
        NAME: 'Howell Mountain Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42289977,
        AWATER: 407720,
        INTPTLAT: '+38.5801367',
        INTPTLON: '-122.4478205',
        ELSDLEA: '17760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2891, 34.1464],
            [-119.1601, 34.1733],
            [-119.2269, 34.0743],
            [-119.2891, 34.1464],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617850',
        NAME: 'Hueneme Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19042763,
        AWATER: 52572686,
        INTPTLAT: '+34.1453181',
        INTPTLON: '-119.2165725',
        ELSDLEA: '17850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1613, 37.8402],
            [-122.1328, 37.804],
            [-122.1779, 37.8163],
            [-122.1889, 37.8376],
            [-122.1613, 37.8402],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607350',
        NAME: 'Canyon Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9964262,
        AWATER: 140250,
        INTPTLAT: '+37.8225428',
        INTPTLON: '-122.1603499',
        ELSDLEA: '07350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7952, 38.9916],
            [-123.6773, 38.934],
            [-123.4336, 38.9339],
            [-123.3852, 38.8841],
            [-123.3853, 38.777],
            [-123.6325, 38.7581],
            [-123.7847, 38.8937],
            [-123.7952, 38.9916],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603090',
        NAME: 'Arena Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390646397,
        AWATER: 184481574,
        INTPTLAT: '+38.8555008',
        INTPTLON: '-123.5714239',
        ELSDLEA: '03090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7861, 39.0921],
            [-123.6269, 39.0895],
            [-123.5065, 39.0232],
            [-123.4336, 38.9339],
            [-123.6773, 38.934],
            [-123.7952, 38.9916],
            [-123.7861, 39.0921],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623550',
        NAME: 'Manchester Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259379181,
        AWATER: 90285400,
        INTPTLAT: '+38.9766404',
        INTPTLON: '-123.6048016',
        ELSDLEA: '23550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.085, 40.4127],
              [-122.0475, 40.4312],
              [-121.9201, 40.4717],
              [-121.4631, 40.4859],
              [-121.3229, 40.5118],
              [-121.3278, 40.4454],
              [-121.4976, 40.4456],
              [-121.4704, 40.3502],
              [-121.3818, 40.3405],
              [-121.349, 40.2645],
              [-121.4369, 40.1519],
              [-121.5864, 40.1005],
              [-121.6347, 40.1008],
              [-121.6311, 40.3362],
              [-121.7597, 40.2858],
              [-122.0844, 40.2862],
              [-122.085, 40.4127],
            ],
          ],
          [
            [
              [-122.1778, 40.2433],
              [-122.0847, 40.2584],
              [-122.1546, 40.1459],
              [-122.1778, 40.2433],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602760',
        NAME: 'Antelope Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1652403991,
        AWATER: 5187449,
        INTPTLAT: '+40.3309845',
        INTPTLON: '-121.6988846',
        ELSDLEA: '02760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.46, 40.0228],
            [-122.4416, 40.0204],
            [-122.3706, 40.0198],
            [-122.2074, 40.0002],
            [-122.0826, 39.9467],
            [-122.0449, 39.8838],
            [-122.0557, 39.864],
            [-122.1884, 39.8269],
            [-122.2511, 39.7993],
            [-122.2503, 39.8711],
            [-122.4019, 39.9148],
            [-122.3473, 39.9866],
            [-122.46, 40.0228],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609780',
        NAME: 'Corning Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421794665,
        AWATER: 2965147,
        INTPTLAT: '+39.9267538',
        INTPTLON: '-122.2432946',
        ELSDLEA: '09780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9258, 39.9682],
            [-122.5546, 39.9744],
            [-122.5162, 39.9596],
            [-122.4786, 39.7995],
            [-122.9377, 39.7982],
            [-122.9601, 39.8452],
            [-122.9258, 39.9682],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612420',
        NAME: 'Elkins Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 714486632,
        AWATER: 654921,
        INTPTLAT: '+39.8707210',
        INTPTLON: '-122.7081288',
        ELSDLEA: '12420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0654, 40.287],
            [-122.7486, 40.3657],
            [-122.6339, 40.3341],
            [-122.52, 40.3937],
            [-122.3104, 40.3711],
            [-122.085, 40.4127],
            [-122.0844, 40.2862],
            [-122.0847, 40.2584],
            [-122.1778, 40.2433],
            [-122.4053, 40.2587],
            [-122.4055, 40.2033],
            [-122.5396, 40.2002],
            [-122.6254, 40.1401],
            [-122.9686, 40.1389],
            [-122.989, 40.2501],
            [-123.0654, 40.287],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613170',
        NAME: 'Evergreen Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1479379624,
        AWATER: 5800725,
        INTPTLAT: '+40.2732554',
        INTPTLON: '-122.5912374',
        ELSDLEA: '13170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5162, 39.9596],
            [-122.46, 40.0228],
            [-122.3473, 39.9866],
            [-122.4019, 39.9148],
            [-122.2503, 39.8711],
            [-122.2511, 39.7993],
            [-122.4786, 39.7995],
            [-122.5162, 39.9596],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613860',
        NAME: 'Flournoy Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297057771,
        AWATER: 7957265,
        INTPTLAT: '+39.8959487',
        INTPTLON: '-122.4108932',
        ELSDLEA: '13860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3706, 40.0198],
            [-122.2888, 40.1039],
            [-122.1378, 40.1265],
            [-122.1148, 40.0429],
            [-122.1637, 40.0074],
            [-122.2074, 40.0002],
            [-122.3706, 40.0198],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615090',
        NAME: 'Gerber Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153708639,
        AWATER: 1012176,
        INTPTLAT: '+40.0580692',
        INTPTLON: '-122.2192963',
        ELSDLEA: '15090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0847, 40.2584],
            [-122.0844, 40.2862],
            [-121.7597, 40.2858],
            [-121.6311, 40.3362],
            [-121.6347, 40.1008],
            [-121.8862, 40.1006],
            [-121.9917, 40.0548],
            [-122.1148, 40.0429],
            [-122.1378, 40.1265],
            [-122.1546, 40.1459],
            [-122.0847, 40.2584],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621090',
        NAME: 'Lassen View Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 948694320,
        AWATER: 1949318,
        INTPTLAT: '+40.1943009',
        INTPTLON: '-121.8564922',
        ELSDLEA: '21090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0557, 39.864],
            [-122.0645, 39.8336],
            [-122.141, 39.7976],
            [-122.1597, 39.7975],
            [-122.1884, 39.8269],
            [-122.0557, 39.864],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619860',
        NAME: 'Kirkwood Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47351294,
        AWATER: 932535,
        INTPTLAT: '+39.8471433',
        INTPTLON: '-122.1409251',
        ELSDLEA: '19860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.096, 34.4208],
            [-119.079, 34.5617],
            [-119.0235, 34.4758],
            [-119.0259, 34.3936],
            [-119.096, 34.4208],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626460',
        NAME: 'Mupu Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103472260,
        AWATER: 10762,
        INTPTLAT: '+34.5090691',
        INTPTLON: '-119.0476898',
        ELSDLEA: '26460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1613, 34.264],
            [-119.1294, 34.2884],
            [-119.0802, 34.3129],
            [-119.0293, 34.3273],
            [-119.0296, 34.2513],
            [-119.0967, 34.2246],
            [-119.1613, 34.264],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624720',
        NAME: 'Mesa Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73674297,
        AWATER: 509845,
        INTPTLAT: '+34.2765906',
        INTPTLON: '-119.0833501',
        ELSDLEA: '24720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2269, 34.0743],
            [-119.1601, 34.1733],
            [-119.1247, 34.1824],
            [-119.0963, 34.184],
            [-118.9966, 34.1564],
            [-118.9797, 34.1043],
            [-118.8769, 34.1142],
            [-118.941, 34.0748],
            [-118.9517, 33.9929],
            [-119.159, 34.0403],
            [-119.2269, 34.0743],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628170',
        NAME: 'Ocean View Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 193435504,
        AWATER: 180558407,
        INTPTLAT: '+34.0916685',
        INTPTLON: '-119.0412064',
        ELSDLEA: '28170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3292, 34.2278],
            [-119.1949, 34.239],
            [-119.1247, 34.1824],
            [-119.1601, 34.1733],
            [-119.2891, 34.1464],
            [-119.3292, 34.2278],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629220',
        NAME: 'Oxnard Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62739757,
        AWATER: 49945443,
        INTPTLAT: '+34.2098935',
        INTPTLON: '-119.2319857',
        ELSDLEA: '29220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0967, 34.2246],
            [-119.0296, 34.2513],
            [-118.9367, 34.2485],
            [-118.8656, 34.259],
            [-118.9966, 34.1564],
            [-119.0963, 34.184],
            [-119.0967, 34.2246],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630990',
        NAME: 'Pleasant Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 127503207,
        AWATER: 24048,
        INTPTLAT: '+34.2135604',
        INTPTLON: '-119.0060109',
        ELSDLEA: '30990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1949, 34.239],
            [-119.1613, 34.264],
            [-119.0967, 34.2246],
            [-119.0963, 34.184],
            [-119.1247, 34.1824],
            [-119.1949, 34.239],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632760',
        NAME: 'Rio Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40270346,
        AWATER: 528862,
        INTPTLAT: '+34.2233506',
        INTPTLON: '-119.1404763',
        ELSDLEA: '32760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0537, 39.5222],
            [-121.0221, 39.3916],
            [-121.1368, 39.3681],
            [-121.13, 39.5192],
            [-121.0537, 39.5222],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607260',
        NAME: 'Camptonville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135672005,
        AWATER: 10983925,
        INTPTLAT: '+39.4308753',
        INTPTLON: '-121.1005893',
        ELSDLEA: '07260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5841, 39.0096],
            [-121.5078, 39.1141],
            [-121.4685, 39.0427],
            [-121.5078, 38.9829],
            [-121.5738, 38.9302],
            [-121.5755, 38.9184],
            [-121.5841, 39.0096],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631180',
        NAME: 'Plumas Lake Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94716410,
        AWATER: 926165,
        INTPTLAT: '+39.0201102',
        INTPTLON: '-121.5317817',
        ELSDLEA: '31180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5078, 38.9829],
            [-121.4685, 39.0427],
            [-121.5078, 39.1141],
            [-121.3956, 39.1488],
            [-121.3845, 39.2204],
            [-121.2795, 39.2305],
            [-121.2797, 39.1624],
            [-121.2797, 39.0896],
            [-121.2796, 39.0748],
            [-121.2795, 39.0346],
            [-121.4148, 38.9965],
            [-121.5078, 38.9829],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642330',
        NAME: 'Wheatland Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306180302,
        AWATER: 2732344,
        INTPTLAT: '+39.1060986',
        INTPTLON: '-121.3681796',
        ELSDLEA: '42330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0235, 34.4758],
            [-119.0066, 34.3599],
            [-119.0259, 34.3936],
            [-119.0235, 34.4758],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635490',
        NAME: 'Santa Clara Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44264830,
        AWATER: 1096975,
        INTPTLAT: '+34.4127008',
        INTPTLON: '-119.0010042',
        ELSDLEA: '35490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0293, 34.3273],
            [-119.0073, 34.3288],
            [-118.9552, 34.3431],
            [-118.9367, 34.2485],
            [-119.0296, 34.2513],
            [-119.0293, 34.3273],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637140',
        NAME: 'Somis Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83102739,
        AWATER: 731595,
        INTPTLAT: '+34.2956748',
        INTPTLON: '-118.9725283',
        ELSDLEA: '37140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4416, 40.0204],
            [-122.4055, 40.2033],
            [-122.4053, 40.2587],
            [-122.1778, 40.2433],
            [-122.1546, 40.1459],
            [-122.1378, 40.1265],
            [-122.2888, 40.1039],
            [-122.3706, 40.0198],
            [-122.4416, 40.0204],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631980',
        NAME: 'Red Bluff Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303928966,
        AWATER: 2710441,
        INTPTLAT: '+40.1484352',
        INTPTLON: '-122.3106017',
        ELSDLEA: '31980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9686, 40.1389],
            [-122.6254, 40.1401],
            [-122.5396, 40.2002],
            [-122.4055, 40.2033],
            [-122.4416, 40.0204],
            [-122.46, 40.0228],
            [-122.5162, 39.9596],
            [-122.5546, 39.9744],
            [-122.9258, 39.9682],
            [-122.934, 39.9781],
            [-122.9686, 40.1389],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632250',
        NAME: 'Reeds Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 983267006,
        AWATER: 187146,
        INTPTLAT: '+40.0768065',
        INTPTLON: '-122.7096180',
        ELSDLEA: '32250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2074, 40.0002],
            [-122.1637, 40.0074],
            [-122.0826, 39.9467],
            [-122.2074, 40.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632400',
        NAME: 'Richfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60846024,
        AWATER: 683818,
        INTPTLAT: '+39.9734713',
        INTPTLON: '-122.1576723',
        ELSDLEA: '32400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9979, 39.134],
            [-120.9477, 39.1678],
            [-120.9475, 39.1434],
            [-120.974, 39.1175],
            [-120.9979, 39.134],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608340',
        NAME: 'Chicago Park Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17086210,
        AWATER: 720370,
        INTPTLAT: '+39.1414342',
        INTPTLON: '-120.9745679',
        ELSDLEA: '08340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2797, 39.0896],
            [-121.122, 39.1971],
            [-121.0882, 39.1738],
            [-121.188, 39.0734],
            [-121.2796, 39.0748],
            [-121.2797, 39.0896],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608880',
        NAME: 'Clear Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78975156,
        AWATER: 118964,
        INTPTLAT: '+39.1200981',
        INTPTLON: '-121.1681268',
        ELSDLEA: '08880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.173, 39.2889],
            [-121.0955, 39.2951],
            [-121.0842, 39.2491],
            [-120.9447, 39.2346],
            [-120.9477, 39.1678],
            [-120.9979, 39.134],
            [-121.0882, 39.1738],
            [-121.122, 39.1971],
            [-121.1726, 39.2414],
            [-121.173, 39.2889],
          ],
          [
            [-121.0508, 39.2123],
            [-121.0121, 39.1861],
            [-121.005, 39.2203],
            [-121.0508, 39.2123],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615780',
        NAME: 'Grass Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150357623,
        AWATER: 151132,
        INTPTLAT: '+39.2154391',
        INTPTLON: '-121.0549249',
        ELSDLEA: '15780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6076, 39.3448],
            [-120.6075, 39.3155],
            [-120.7279, 39.2881],
            [-120.9238, 39.1588],
            [-120.9475, 39.1434],
            [-120.9477, 39.1678],
            [-120.9447, 39.2346],
            [-121.0842, 39.2491],
            [-121.0955, 39.2951],
            [-121.0481, 39.3274],
            [-120.8764, 39.3464],
            [-120.6748, 39.3284],
            [-120.6076, 39.3448],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626820',
        NAME: 'Nevada City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420581490,
        AWATER: 7033206,
        INTPTLAT: '+39.2838370',
        INTPTLON: '-120.8886994',
        ELSDLEA: '26820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2796, 39.0748],
            [-121.188, 39.0734],
            [-121.0882, 39.1738],
            [-120.9979, 39.134],
            [-120.974, 39.1175],
            [-120.9981, 39.0707],
            [-121.0795, 39.0069],
            [-121.1878, 39.0125],
            [-121.2795, 39.0346],
            [-121.2796, 39.0748],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630930',
        NAME: 'Pleasant Ridge Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246976616,
        AWATER: 1825924,
        INTPTLAT: '+39.0625434',
        INTPTLON: '-121.1077211',
        ELSDLEA: '30930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2795, 39.2305],
            [-121.2096, 39.2963],
            [-121.173, 39.2889],
            [-121.1726, 39.2414],
            [-121.2797, 39.1624],
            [-121.2795, 39.2305],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630960',
        NAME: 'Pleasant Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 87140787,
        AWATER: 2554980,
        INTPTLAT: '+39.2247454',
        INTPTLON: '-121.2207813',
        ELSDLEA: '30960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2797, 39.1624],
            [-121.1726, 39.2414],
            [-121.122, 39.1971],
            [-121.2797, 39.0896],
            [-121.2797, 39.1624],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631950',
        NAME: 'Ready Springs Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127513109,
        AWATER: 22539,
        INTPTLAT: '+39.1629057',
        INTPTLON: '-121.1898214',
        ELSDLEA: '31950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0508, 39.2123],
            [-121.005, 39.2203],
            [-121.0121, 39.1861],
            [-121.0508, 39.2123],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640380',
        NAME: 'Union Hill Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15420210,
        AWATER: 0,
        INTPTLAT: '+39.2049210',
        INTPTLON: '-121.0211161',
        ELSDLEA: '40380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2096, 39.2963],
            [-121.1368, 39.3681],
            [-121.0221, 39.3916],
            [-120.7557, 39.4527],
            [-120.659, 39.5238],
            [-120.5772, 39.5231],
            [-120.455, 39.4461],
            [-120.4564, 39.3497],
            [-120.6076, 39.3448],
            [-120.6748, 39.3284],
            [-120.8764, 39.3464],
            [-121.0481, 39.3274],
            [-121.0955, 39.2951],
            [-121.173, 39.2889],
            [-121.2096, 39.2963],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600012',
        NAME: 'Twin Ridges Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 742892244,
        AWATER: 15743994,
        INTPTLAT: '+39.4051970',
        INTPTLON: '-120.7604788',
        ELSDLEA: '00012',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0454, 39.1806],
            [-123.0197, 39.3218],
            [-123.0751, 39.4077],
            [-123.0444, 39.5112],
            [-122.8903, 39.529],
            [-122.8854, 39.5801],
            [-122.7356, 39.5807],
            [-122.7518, 39.3478],
            [-122.7571, 39.2843],
            [-122.5734, 39.2043],
            [-122.5153, 39.2092],
            [-122.4882, 39.0946],
            [-122.4902, 39.0737],
            [-122.5901, 39.1417],
            [-122.7662, 39.15],
            [-122.854, 39.0529],
            [-122.9115, 39.1422],
            [-123.078, 39.1518],
            [-123.0454, 39.1806],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640470',
        NAME: 'Upper Lake Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1385395455,
        AWATER: 37429195,
        INTPTLAT: '+39.3124068',
        INTPTLON: '-122.8166819',
        ELSDLEA: '40470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7662, 39.15],
            [-122.7213, 39.0992],
            [-122.7443, 39.0267],
            [-122.854, 39.0529],
            [-122.7662, 39.15],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623040',
        NAME: 'Lucerne Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78086398,
        AWATER: 36746375,
        INTPTLAT: '+39.0907791',
        INTPTLON: '-122.7850454',
        ELSDLEA: '23040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3233, 38.0642],
            [-120.2582, 38.0347],
            [-120.2667, 38.0273],
            [-120.3321, 38.0352],
            [-120.3592, 38.0204],
            [-120.3233, 38.0642],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604500',
        NAME: 'Belleview Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39691842,
        AWATER: 22467,
        INTPTLAT: '+38.0558607',
        INTPTLON: '-120.2961578',
        ELSDLEA: '04500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4308, 38.0668],
            [-120.324, 38.2001],
            [-120.3233, 38.0642],
            [-120.3592, 38.0204],
            [-120.4044, 37.9775],
            [-120.5336, 37.9451],
            [-120.4308, 38.0668],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609480',
        NAME: 'Columbia Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174304841,
        AWATER: 25586865,
        INTPTLAT: '+38.0437309',
        INTPTLON: '-120.4282745',
        ELSDLEA: '09480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3321, 38.0352],
            [-120.2667, 38.0273],
            [-120.2819, 37.9549],
            [-120.2685, 37.8912],
            [-120.3295, 37.891],
            [-120.3321, 38.0352],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610320',
        NAME: 'Curtis Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92635979,
        AWATER: 574685,
        INTPTLAT: '+37.9538832',
        INTPTLON: '-120.3063417',
        ELSDLEA: '10320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5336, 37.9451],
            [-120.4044, 37.9775],
            [-120.4098, 37.8977],
            [-120.353, 37.7755],
            [-120.4617, 37.6895],
            [-120.4619, 37.6896],
            [-120.6527, 37.8314],
            [-120.5336, 37.9451],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618690',
        NAME: 'Jamestown Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411857371,
        AWATER: 20746915,
        INTPTLAT: '+37.8475300',
        INTPTLON: '-120.4865572',
        ELSDLEA: '18690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4044, 37.9775],
            [-120.3592, 38.0204],
            [-120.3321, 38.0352],
            [-120.3295, 37.891],
            [-120.4098, 37.8977],
            [-120.4044, 37.9775],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637230',
        NAME: 'Sonora Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68892007,
        AWATER: 112604,
        INTPTLAT: '+37.9552962',
        INTPTLON: '-120.3632823',
        ELSDLEA: '37230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2819, 37.9549],
            [-120.2495, 38.0203],
            [-120.0493, 38.0622],
            [-120.0493, 38.1502],
            [-119.5474, 38.144],
            [-119.3498, 38.0856],
            [-119.3205, 37.9732],
            [-119.9059, 37.9787],
            [-119.9387, 37.891],
            [-120.2685, 37.8912],
            [-120.2819, 37.9549],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638250',
        NAME: 'Summerville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1543229832,
        AWATER: 27988423,
        INTPTLAT: '+38.0406457',
        INTPTLON: '-119.8372726',
        ELSDLEA: '38250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.324, 38.2001],
            [-120.1766, 38.3735],
            [-120.02, 38.4335],
            [-119.8847, 38.3562],
            [-119.7535, 38.4168],
            [-119.6392, 38.3269],
            [-119.5474, 38.144],
            [-120.0493, 38.1502],
            [-120.0493, 38.0622],
            [-120.2495, 38.0203],
            [-120.2582, 38.0347],
            [-120.3233, 38.0642],
            [-120.324, 38.2001],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640200',
        NAME: 'Twain Harte-Long Barn Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1739110495,
        AWATER: 18671718,
        INTPTLAT: '+38.2396910',
        INTPTLON: '-119.9825108',
        ELSDLEA: '40200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2667, 38.0273],
            [-120.2582, 38.0347],
            [-120.2495, 38.0203],
            [-120.2819, 37.9549],
            [-120.2667, 38.0273],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637320',
        NAME: 'Soulsbyville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18704890,
        AWATER: 28641,
        INTPTLAT: '+37.9883485',
        INTPTLON: '-120.2702115',
        ELSDLEA: '37320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0272, 38.413],
            [-121.0271, 38.3003],
            [-121.153, 38.2625],
            [-121.2643, 38.2985],
            [-121.3923, 38.2979],
            [-121.2606, 38.3798],
            [-121.0272, 38.413],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603060',
        NAME: 'Arcohe Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306090989,
        AWATER: 2023325,
        INTPTLAT: '+38.3422679',
        INTPTLON: '-121.1741948',
        ELSDLEA: '03060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4934, 38.6708],
            [-121.4013, 38.6541],
            [-121.4056, 38.6383],
            [-121.4932, 38.642],
            [-121.4934, 38.6708],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633240',
        NAME: 'Robla Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25662730,
        AWATER: 0,
        INTPTLAT: '+38.6551086',
        INTPTLON: '-121.4473217',
        ELSDLEA: '33240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6967, 38.721],
            [-122.6274, 38.6675],
            [-122.6872, 38.5834],
            [-122.7243, 38.5833],
            [-122.7799, 38.591],
            [-122.8634, 38.6859],
            [-122.6967, 38.721],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601890',
        NAME: 'Alexander Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171901135,
        AWATER: 174852,
        INTPTLAT: '+38.6860805',
        INTPTLON: '-122.7921333',
        ELSDLEA: '01890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7736, 38.3982],
            [-122.7394, 38.4122],
            [-122.7212, 38.4186],
            [-122.6857, 38.4237],
            [-122.6377, 38.351],
            [-122.667, 38.3505],
            [-122.7597, 38.3604],
            [-122.7693, 38.3861],
            [-122.7736, 38.3982],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604380',
        NAME: 'Bellevue Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57656686,
        AWATER: 26109,
        INTPTLAT: '+38.3933396',
        INTPTLON: '-122.7012564',
        ELSDLEA: '04380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6817, 38.436],
            [-122.6106, 38.407],
            [-122.592, 38.3986],
            [-122.6018, 38.3575],
            [-122.6377, 38.351],
            [-122.6857, 38.4237],
            [-122.6817, 38.436],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604650',
        NAME: 'Bennett Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44080309,
        AWATER: 44762,
        INTPTLAT: '+38.3871023',
        INTPTLON: '-122.6349188',
        ELSDLEA: '04650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.6441, 38.2595],
              [-122.6422, 38.2584],
              [-122.6446, 38.257],
              [-122.6515, 38.2637],
              [-122.6441, 38.2595],
            ],
          ],
          [
            [
              [-122.6833, 38.274],
              [-122.6641, 38.2412],
              [-122.6827, 38.2596],
              [-122.6833, 38.274],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608700',
        NAME: 'Cinnabar Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6456715,
        AWATER: 0,
        INTPTLAT: '+38.2562646',
        INTPTLON: '-122.6654179',
        ELSDLEA: '08700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9551, 38.5449],
            [-122.861, 38.5032],
            [-122.8443, 38.4923],
            [-122.8404, 38.4673],
            [-122.9359, 38.4517],
            [-122.9729, 38.4512],
            [-122.9731, 38.4741],
            [-122.9551, 38.5449],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614010',
        NAME: 'Forestville Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68193928,
        AWATER: 342257,
        INTPTLAT: '+38.4834623',
        INTPTLON: '-122.9115054',
        ELSDLEA: '14010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4174, 38.5748],
            [-123.3567, 38.6245],
            [-123.1629, 38.6147],
            [-123.1428, 38.5011],
            [-123.2042, 38.4241],
            [-123.3609, 38.5102],
            [-123.4174, 38.5748],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614130',
        NAME: 'Fort Ross Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187038247,
        AWATER: 150607078,
        INTPTLAT: '+38.5432919',
        INTPTLON: '-123.2643740',
        ELSDLEA: '14130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8156, 38.3868],
            [-122.7693, 38.3861],
            [-122.7597, 38.3604],
            [-122.7539, 38.3364],
            [-122.7892, 38.3284],
            [-122.8156, 38.3868],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615840',
        NAME: 'Gravenstein Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25030838,
        AWATER: 0,
        INTPTLAT: '+38.3603975',
        INTPTLON: '-122.7842133',
        ELSDLEA: '15840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7963, 38.3284],
            [-122.7892, 38.3284],
            [-122.7539, 38.3364],
            [-122.7195, 38.3138],
            [-122.7358, 38.2955],
            [-122.787, 38.308],
            [-122.7963, 38.3284],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611610',
        NAME: 'Dunham Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18890338,
        AWATER: 0,
        INTPTLAT: '+38.3156929',
        INTPTLON: '-122.7574564',
        ELSDLEA: '11610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4652, 39.3948],
            [-121.3334, 39.4687],
            [-121.3729, 39.3646],
            [-121.4828, 39.3266],
            [-121.4652, 39.3948],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603780',
        NAME: 'Bangor Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103433814,
        AWATER: 30997,
        INTPTLAT: '+39.3988963',
        INTPTLON: '-121.4081210',
        ELSDLEA: '03780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2106, 39.7119],
            [-121.1367, 39.6282],
            [-121.1854, 39.5558],
            [-121.3151, 39.5446],
            [-121.408, 39.5495],
            [-121.2971, 39.5973],
            [-121.2999, 39.67],
            [-121.2106, 39.7119],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613710',
        NAME: 'Feather Falls Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225146748,
        AWATER: 13160251,
        INTPTLAT: '+39.6210376',
        INTPTLON: '-121.2481602',
        ELSDLEA: '13710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0695, 38.5761],
            [-122.9551, 38.5449],
            [-122.9731, 38.4741],
            [-123.049, 38.4875],
            [-123.0695, 38.5761],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616320',
        NAME: 'Guerneville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81317026,
        AWATER: 671381,
        INTPTLAT: '+38.5501490',
        INTPTLON: '-122.9903977',
        ELSDLEA: '16320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0169, 38.4042],
            [-122.9729, 38.4512],
            [-122.9359, 38.4517],
            [-122.9083, 38.4262],
            [-122.893, 38.3998],
            [-122.8882, 38.3571],
            [-123.0169, 38.4042],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616620',
        NAME: 'Harmony Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126364801,
        AWATER: 295030,
        INTPTLAT: '+38.3754303',
        INTPTLON: '-122.9857950',
        ELSDLEA: '16620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6325, 38.7581],
            [-123.3853, 38.777],
            [-123.2093, 38.8089],
            [-123.2452, 38.7047],
            [-123.1278, 38.6465],
            [-123.1271, 38.6172],
            [-123.1629, 38.6147],
            [-123.3567, 38.6245],
            [-123.4174, 38.5748],
            [-123.5106, 38.6809],
            [-123.6325, 38.7581],
          ],
          [
            [-123.3415, 38.6548],
            [-123.3363, 38.6547],
            [-123.3367, 38.6584],
            [-123.3414, 38.6585],
            [-123.3415, 38.6548],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617580',
        NAME: 'Horicon Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394569220,
        AWATER: 148210664,
        INTPTLAT: '+38.7022206',
        INTPTLON: '-123.3573786',
        ELSDLEA: '17580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5296, 38.4696],
            [-122.4944, 38.4227],
            [-122.592, 38.3986],
            [-122.6106, 38.407],
            [-122.5296, 38.4696],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619410',
        NAME: 'Kenwood Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61469429,
        AWATER: 107202,
        INTPTLAT: '+38.4186041',
        INTPTLON: '-122.5330461',
        ELSDLEA: '19410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7243, 38.5833],
            [-122.6872, 38.5834],
            [-122.687, 38.5762],
            [-122.7134, 38.4966],
            [-122.7421, 38.4738],
            [-122.8208, 38.5033],
            [-122.7243, 38.5833],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624000',
        NAME: 'Mark West Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63230504,
        AWATER: 19899,
        INTPTLAT: '+38.5170480',
        INTPTLON: '-122.7463771',
        ELSDLEA: '24000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2042, 38.4241],
            [-123.1428, 38.5011],
            [-123.049, 38.4875],
            [-122.9731, 38.4741],
            [-122.9729, 38.4512],
            [-123.0169, 38.4042],
            [-123.1655, 38.3888],
            [-123.2042, 38.4241],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625410',
        NAME: 'Monte Rio Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97230377,
        AWATER: 43332178,
        INTPTLAT: '+38.4427648',
        INTPTLON: '-123.0890207',
        ELSDLEA: '25410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8404, 38.4673],
            [-122.779, 38.4455],
            [-122.788, 38.4233],
            [-122.9083, 38.4262],
            [-122.9359, 38.4517],
            [-122.8404, 38.4673],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627840',
        NAME: 'Oak Grove Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39847571,
        AWATER: 73946,
        INTPTLAT: '+38.4420299',
        INTPTLON: '-122.8527901',
        ELSDLEA: '27840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6441, 38.2595],
            [-122.6337, 38.2716],
            [-122.6278, 38.2684],
            [-122.6079, 38.2899],
            [-122.6186, 38.3191],
            [-122.5837, 38.3577],
            [-122.5371, 38.2664],
            [-122.4068, 38.1587],
            [-122.4068, 38.1556],
            [-122.3475, 38.0733],
            [-122.5028, 38.113],
            [-122.5454, 38.1587],
            [-122.6422, 38.2584],
            [-122.6441, 38.2595],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628320',
        NAME: 'Old Adobe Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196635672,
        AWATER: 40059904,
        INTPTLAT: '+38.1998827',
        INTPTLON: '-122.5124079',
        ELSDLEA: '28320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7046, 38.3041],
            [-122.667, 38.3505],
            [-122.6377, 38.351],
            [-122.6018, 38.3575],
            [-122.5837, 38.3577],
            [-122.6186, 38.3191],
            [-122.6569, 38.2667],
            [-122.6553, 38.2659],
            [-122.6515, 38.2637],
            [-122.6446, 38.257],
            [-122.6422, 38.2584],
            [-122.5454, 38.1587],
            [-122.6451, 38.1811],
            [-122.647, 38.1812],
            [-122.6512, 38.2124],
            [-122.6641, 38.2412],
            [-122.6833, 38.274],
            [-122.7046, 38.3041],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630230',
        NAME: 'Petaluma City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86476874,
        AWATER: 1520593,
        INTPTLAT: '+38.2567666',
        INTPTLON: '-122.6172738',
        ELSDLEA: '30230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8443, 38.4923],
            [-122.8208, 38.5033],
            [-122.7421, 38.4738],
            [-122.7696, 38.4418],
            [-122.779, 38.4455],
            [-122.8404, 38.4673],
            [-122.8443, 38.4923],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630450',
        NAME: 'Piner-Olivet Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35206736,
        AWATER: 21574,
        INTPTLAT: '+38.4725689',
        INTPTLON: '-122.8006952',
        ELSDLEA: '30450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.687, 38.5762],
            [-122.5737, 38.5387],
            [-122.5296, 38.4696],
            [-122.6106, 38.407],
            [-122.6817, 38.436],
            [-122.7134, 38.4966],
            [-122.687, 38.5762],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632640',
        NAME: 'Rincon Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159383165,
        AWATER: 567420,
        INTPTLAT: '+38.4906209',
        INTPTLON: '-122.6323149',
        ELSDLEA: '32640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.743, 38.4384],
            [-122.7212, 38.4186],
            [-122.7394, 38.4122],
            [-122.743, 38.4384],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633510',
        NAME: 'Roseland Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4216559,
        AWATER: 0,
        INTPTLAT: '+38.4261468',
        INTPTLON: '-122.7319189',
        ELSDLEA: '33510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7421, 38.4738],
            [-122.7134, 38.4966],
            [-122.6817, 38.436],
            [-122.6857, 38.4237],
            [-122.7212, 38.4186],
            [-122.743, 38.4384],
            [-122.7696, 38.4418],
            [-122.7421, 38.4738],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635810',
        NAME: 'Santa Rosa Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36849048,
        AWATER: 126296,
        INTPTLAT: '+38.4547738',
        INTPTLON: '-122.7206709',
        ELSDLEA: '35810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9083, 38.4262],
            [-122.788, 38.4233],
            [-122.7736, 38.3982],
            [-122.7693, 38.3861],
            [-122.8156, 38.3868],
            [-122.893, 38.3998],
            [-122.9083, 38.4262],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636180',
        NAME: 'Sebastopol Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37925063,
        AWATER: 0,
        INTPTLAT: '+38.4089364',
        INTPTLON: '-122.8348198',
        ELSDLEA: '36180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.893, 38.3998],
            [-122.8156, 38.3868],
            [-122.7892, 38.3284],
            [-122.7963, 38.3284],
            [-122.8882, 38.3571],
            [-122.893, 38.3998],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640230',
        NAME: 'Twin Hills Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49843510,
        AWATER: 0,
        INTPTLAT: '+38.3647793',
        INTPTLON: '-122.8483844',
        ELSDLEA: '40230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.787, 38.308],
            [-122.7358, 38.2955],
            [-122.7193, 38.2632],
            [-122.7189, 38.2186],
            [-122.7871, 38.239],
            [-122.787, 38.308],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640260',
        NAME: 'Two Rock Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67581258,
        AWATER: 470346,
        INTPTLAT: '+38.2654364',
        INTPTLON: '-122.7776574',
        ELSDLEA: '40260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3415, 38.6548],
            [-123.3414, 38.6585],
            [-123.3367, 38.6584],
            [-123.3363, 38.6547],
            [-123.3415, 38.6548],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632340',
        NAME: 'Kashia Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174216,
        AWATER: 0,
        INTPTLAT: '+38.6565775',
        INTPTLON: '-123.3389676',
        ELSDLEA: '32340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7358, 38.2955],
            [-122.7195, 38.3138],
            [-122.7046, 38.3041],
            [-122.6833, 38.274],
            [-122.6827, 38.2596],
            [-122.7193, 38.2632],
            [-122.7358, 38.2955],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621540',
        NAME: 'Liberty Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 21101980,
        AWATER: 0,
        INTPTLAT: '+38.2827446',
        INTPTLON: '-122.7097236',
        ELSDLEA: '21540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1629, 38.6147],
            [-123.1271, 38.6172],
            [-123.0695, 38.5761],
            [-123.049, 38.4875],
            [-123.1428, 38.5011],
            [-123.1629, 38.6147],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625650',
        NAME: 'Montgomery Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122965334,
        AWATER: 14765,
        INTPTLAT: '+38.5471748',
        INTPTLON: '-123.1037254',
        ELSDLEA: '25650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6209, 36.7154],
            [-121.4762, 36.7543],
            [-121.4681, 36.6855],
            [-121.3633, 36.6547],
            [-121.3636, 36.6456],
            [-121.4673, 36.6455],
            [-121.5763, 36.6074],
            [-121.6055, 36.6542],
            [-121.6209, 36.7154],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601950',
        NAME: 'Alisal Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168136725,
        AWATER: 299703,
        INTPTLAT: '+36.6747263',
        INTPTLON: '-121.5237812',
        ELSDLEA: '01950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9936, 36.5317],
            [-120.9109, 36.4867],
            [-120.82, 36.4856],
            [-120.7448, 36.3842],
            [-120.597, 36.3575],
            [-120.7468, 36.1998],
            [-120.864, 36.2919],
            [-120.9796, 36.2905],
            [-121.117, 36.3852],
            [-120.9748, 36.4503],
            [-120.9936, 36.5317],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605190',
        NAME: 'Bitterwater-Tully Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713433101,
        AWATER: 2630983,
        INTPTLAT: '+36.3834561',
        INTPTLON: '-120.8636505',
        ELSDLEA: '05190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9826, 35.9218],
            [-120.8355, 35.9214],
            [-120.7936, 36.0444],
            [-120.6225, 36.0034],
            [-120.6395, 35.9465],
            [-120.6923, 35.8726],
            [-120.6916, 35.7909],
            [-120.9593, 35.7926],
            [-120.9826, 35.9218],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605730',
        NAME: 'Bradley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537585712,
        AWATER: 16169821,
        INTPTLAT: '+35.8949794',
        INTPTLON: '-120.7887705',
        ELSDLEA: '05730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5763, 36.6074],
            [-121.4673, 36.6455],
            [-121.3636, 36.6456],
            [-121.4164, 36.5739],
            [-121.5312, 36.533],
            [-121.5763, 36.6074],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608580',
        NAME: 'Chualar Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171474797,
        AWATER: 264130,
        INTPTLAT: '+36.5897368',
        INTPTLON: '-121.4746436',
        ELSDLEA: '08580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9487, 37.5364],
            [-120.9214, 37.5361],
            [-120.9214, 37.5336],
            [-120.9036, 37.5217],
            [-120.8579, 37.4508],
            [-120.9844, 37.3994],
            [-121.0284, 37.4495],
            [-121.0882, 37.5086],
            [-120.9487, 37.5364],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608250',
        NAME: 'Chatom Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164865559,
        AWATER: 442746,
        INTPTLAT: '+37.4710052',
        INTPTLON: '-120.9627120',
        ELSDLEA: '08250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.944, 37.6668],
            [-120.9033, 37.667],
            [-120.8302, 37.6822],
            [-120.8117, 37.6821],
            [-120.8017, 37.6188],
            [-120.9039, 37.625],
            [-120.948, 37.6201],
            [-120.944, 37.6668],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612690',
        NAME: 'Empire Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59599942,
        AWATER: 258706,
        INTPTLAT: '+37.6462692',
        INTPTLON: '-120.8700324',
        ELSDLEA: '12690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.783, 37.5875],
            [-120.7646, 37.5656],
            [-120.8104, 37.5585],
            [-120.783, 37.5875],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615810',
        NAME: 'Gratton Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13086315,
        AWATER: 0,
        INTPTLAT: '+37.5729682',
        INTPTLON: '-120.7875570',
        ELSDLEA: '15810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.24, 37.6649],
            [-121.222, 37.6743],
            [-121.0619, 37.6894],
            [-121.0312, 37.6639],
            [-121.0446, 37.6459],
            [-121.0768, 37.6455],
            [-121.2305, 37.6411],
            [-121.2439, 37.6619],
            [-121.24, 37.6649],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616650',
        NAME: 'Hart-Ransom Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68441747,
        AWATER: 603382,
        INTPTLAT: '+37.6580439',
        INTPTLON: '-121.1330423',
        ELSDLEA: '16650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.595, 39.7141],
            [-121.524, 39.9292],
            [-121.4206, 39.9296],
            [-121.3605, 39.8407],
            [-121.356, 39.7427],
            [-121.4139, 39.6665],
            [-121.506, 39.6669],
            [-121.4664, 39.5533],
            [-121.6345, 39.5752],
            [-121.595, 39.7141],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615480',
        NAME: 'Golden Feather Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517062520,
        AWATER: 21292534,
        INTPTLAT: '+39.7340995',
        INTPTLON: '-121.4888872',
        ELSDLEA: '15480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4664, 39.5495],
            [-121.408, 39.5495],
            [-121.3151, 39.5446],
            [-121.3334, 39.4687],
            [-121.4652, 39.3948],
            [-121.5025, 39.4822],
            [-121.6039, 39.4623],
            [-121.4664, 39.5495],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629100',
        NAME: 'Oroville City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210313262,
        AWATER: 7962860,
        INTPTLAT: '+39.4903971',
        INTPTLON: '-121.4373826',
        ELSDLEA: '29100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.63, 39.4562],
            [-121.6039, 39.4623],
            [-121.5025, 39.4822],
            [-121.4652, 39.3948],
            [-121.4828, 39.3266],
            [-121.5764, 39.3108],
            [-121.6174, 39.4054],
            [-121.63, 39.4562],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629540',
        NAME: 'Palermo Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175438900,
        AWATER: 487937,
        INTPTLAT: '+39.3990524',
        INTPTLON: '-121.5447411',
        ELSDLEA: '29540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3605, 39.8407],
            [-121.2106, 39.7119],
            [-121.2999, 39.67],
            [-121.2971, 39.5973],
            [-121.408, 39.5495],
            [-121.4664, 39.5495],
            [-121.4664, 39.5533],
            [-121.506, 39.6669],
            [-121.4139, 39.6665],
            [-121.356, 39.7427],
            [-121.3605, 39.8407],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630520',
        NAME: 'Pioneer Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 313831458,
        AWATER: 20712358,
        INTPTLAT: '+39.6733136',
        INTPTLON: '-121.3673265',
        ELSDLEA: '30520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6887, 39.5378],
            [-121.6345, 39.5752],
            [-121.4664, 39.5533],
            [-121.4664, 39.5495],
            [-121.6039, 39.4623],
            [-121.63, 39.4562],
            [-121.6887, 39.5378],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639180',
        NAME: 'Thermalito Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116684351,
        AWATER: 6087055,
        INTPTLAT: '+39.5341130',
        INTPTLON: '-121.6153454',
        ELSDLEA: '39180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6302, 39.3057],
            [-121.7055, 39.3046],
            [-121.6834, 39.3485],
            [-121.6302, 39.3057],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623700',
        NAME: 'Manzanita Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29400976,
        AWATER: 227326,
        INTPTLAT: '+39.3263017',
        INTPTLON: '-121.6638966',
        ELSDLEA: '23700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7181, 39.2165],
            [-121.6886, 39.1707],
            [-121.6888, 39.1561],
            [-121.7649, 39.1135],
            [-121.7844, 39.1221],
            [-121.9283, 39.1987],
            [-121.9334, 39.2126],
            [-121.7181, 39.2165],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606090',
        NAME: 'Brittan Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162241730,
        AWATER: 314696,
        INTPTLAT: '+39.1727264',
        INTPTLON: '-121.8058194',
        ELSDLEA: '06090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5078, 38.9829],
            [-121.4148, 38.9965],
            [-121.4694, 38.926],
            [-121.5738, 38.9302],
            [-121.5078, 38.9829],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606100',
        NAME: 'Browns Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84192951,
        AWATER: 0,
        INTPTLAT: '+38.9560908',
        INTPTLON: '-121.4812046',
        ELSDLEA: '06100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6888, 39.1561],
            [-121.7649, 39.0987],
            [-121.7649, 39.1135],
            [-121.6888, 39.1561],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614340',
        NAME: 'Franklin Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40269084,
        AWATER: 54882,
        INTPTLAT: '+39.1195330',
        INTPTLON: '-121.7081452',
        ELSDLEA: '14340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6594, 38.8357],
            [-121.6359, 38.8817],
            [-121.5755, 38.9184],
            [-121.5738, 38.9302],
            [-121.4694, 38.926],
            [-121.4695, 38.8969],
            [-121.5521, 38.8228],
            [-121.5586, 38.7363],
            [-121.6029, 38.7358],
            [-121.6594, 38.8357],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623800',
        NAME: 'Marcum-Illinois Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160114204,
        AWATER: 2745058,
        INTPTLAT: '+38.8584742',
        INTPTLON: '-121.5671248',
        ELSDLEA: '23800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9283, 39.1987],
            [-121.7844, 39.1221],
            [-121.9003, 39.1003],
            [-121.9283, 39.1987],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624690',
        NAME: 'Meridian Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64322187,
        AWATER: 1030506,
        INTPTLAT: '+39.1396063',
        INTPTLON: '-121.8799586',
        ELSDLEA: '24690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7181, 39.2165],
            [-121.6236, 39.1974],
            [-121.6181, 39.1822],
            [-121.6886, 39.1707],
            [-121.7181, 39.2165],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627750',
        NAME: 'Nuestro Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24695236,
        AWATER: 55797,
        INTPTLAT: '+39.1939278',
        INTPTLON: '-121.6923783',
        ELSDLEA: '27750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4695, 38.8969],
            [-121.433, 38.8243],
            [-121.4696, 38.7947],
            [-121.5583, 38.7363],
            [-121.5586, 38.7363],
            [-121.5521, 38.8228],
            [-121.4695, 38.8969],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630900',
        NAME: 'Pleasant Grove Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134800934,
        AWATER: 362630,
        INTPTLAT: '+38.8169221',
        INTPTLON: '-121.4944398',
        ELSDLEA: '30900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7844, 39.1221],
            [-121.7649, 39.1135],
            [-121.7649, 39.0987],
            [-121.735, 39.0024],
            [-121.6359, 38.8817],
            [-121.6594, 38.8357],
            [-121.8172, 38.882],
            [-121.8191, 38.9886],
            [-121.8942, 39.0904],
            [-121.9003, 39.1003],
            [-121.7844, 39.1221],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642900',
        NAME: 'Winship Robbins Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328261780,
        AWATER: 3305428,
        INTPTLAT: '+38.9756160',
        INTPTLON: '-121.7595873',
        ELSDLEA: '42900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1278, 38.6465],
            [-123.0195, 38.6647],
            [-122.8564, 38.5766],
            [-122.861, 38.5032],
            [-122.9551, 38.5449],
            [-123.0695, 38.5761],
            [-123.1271, 38.6172],
            [-123.1278, 38.6465],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642270',
        NAME: 'West Side Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196925612,
        AWATER: 954229,
        INTPTLAT: '+38.5871134',
        INTPTLON: '-122.9989798',
        ELSDLEA: '42270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5011, 36.3137],
            [-121.3266, 36.3129],
            [-121.2486, 36.3796],
            [-121.1995, 36.3093],
            [-121.2626, 36.2578],
            [-121.2101, 36.1963],
            [-121.2111, 36.0763],
            [-121.3622, 36.1408],
            [-121.576, 36.1415],
            [-121.5758, 36.3142],
            [-121.5011, 36.3137],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616080',
        NAME: 'Greenfield Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 685879814,
        AWATER: 6058370,
        INTPTLAT: '+36.1843378',
        INTPTLON: '-121.3437772',
        ELSDLEA: '16080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1435, 36.4062],
            [-121.117, 36.3852],
            [-120.9796, 36.2905],
            [-120.9464, 36.1645],
            [-121.0679, 36.1606],
            [-121.1105, 36.0516],
            [-121.2111, 36.0763],
            [-121.2101, 36.1963],
            [-121.2626, 36.2578],
            [-121.1995, 36.3093],
            [-121.2486, 36.3796],
            [-121.1435, 36.4062],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619680',
        NAME: 'King City Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 599544479,
        AWATER: 8174391,
        INTPTLAT: '+36.2420280',
        INTPTLON: '-121.1226855',
        ELSDLEA: '19680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5825, 35.8771],
            [-120.4558, 35.8778],
            [-120.4256, 35.79],
            [-120.5151, 35.7903],
            [-120.5336, 35.7032],
            [-120.6494, 35.707],
            [-120.6583, 35.7909],
            [-120.5825, 35.8771],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631020',
        NAME: 'Pleasant Valley Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283024775,
        AWATER: 0,
        INTPTLAT: '+35.7945313',
        INTPTLON: '-120.5441299',
        ELSDLEA: '31020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.685, 36.7034],
            [-121.6209, 36.7154],
            [-121.6055, 36.6542],
            [-121.6831, 36.6793],
            [-121.685, 36.7034],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633930',
        NAME: 'Salinas City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35360121,
        AWATER: 45591,
        INTPTLAT: '+36.6788595',
        INTPTLON: '-121.6488646',
        ELSDLEA: '33930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.576, 36.1415],
            [-121.3622, 36.1408],
            [-121.2111, 36.0763],
            [-121.1105, 36.0516],
            [-121.0421, 36.0314],
            [-120.9826, 35.9218],
            [-120.9593, 35.7926],
            [-121.1757, 35.7941],
            [-121.2438, 35.7945],
            [-121.4372, 36.0153],
            [-121.576, 36.1415],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634050',
        NAME: 'San Antonio Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1202050471,
        AWATER: 16506339,
        INTPTLAT: '+35.9535675',
        INTPTLON: '-121.2253898',
        ELSDLEA: '34050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0421, 36.0314],
            [-120.9136, 36.1421],
            [-120.6951, 36.1555],
            [-120.4778, 36.0033],
            [-120.5826, 36.0026],
            [-120.6225, 36.0034],
            [-120.7936, 36.0444],
            [-120.8355, 35.9214],
            [-120.9826, 35.9218],
            [-121.0421, 36.0314],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634080',
        NAME: 'San Ardo Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 633285748,
        AWATER: 6524333,
        INTPTLAT: '+36.0464534',
        INTPTLON: '-120.7877231',
        ELSDLEA: '34080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9796, 36.2905],
            [-120.864, 36.2919],
            [-120.7468, 36.1998],
            [-120.6951, 36.1555],
            [-120.9136, 36.1421],
            [-121.0421, 36.0314],
            [-121.1105, 36.0516],
            [-121.0679, 36.1606],
            [-120.9464, 36.1645],
            [-120.9796, 36.2905],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634770',
        NAME: 'San Lucas Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469766824,
        AWATER: 7010831,
        INTPTLAT: '+36.1729797',
        INTPTLON: '-120.9027227',
        ELSDLEA: '34770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1757, 35.7941],
            [-120.9593, 35.7926],
            [-120.6916, 35.7909],
            [-120.6923, 35.8726],
            [-120.6395, 35.9465],
            [-120.5826, 35.9458],
            [-120.5825, 35.8771],
            [-120.6583, 35.7909],
            [-120.6494, 35.707],
            [-120.873, 35.7214],
            [-121.1125, 35.7042],
            [-121.1757, 35.7941],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635010',
        NAME: 'San Miguel Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 485830518,
        AWATER: 19089826,
        INTPTLAT: '+35.7839762',
        INTPTLON: '-120.8460781',
        ELSDLEA: '35010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6462, 36.7616],
            [-121.5351, 36.7836],
            [-121.483, 36.765],
            [-121.4762, 36.7543],
            [-121.6209, 36.7154],
            [-121.685, 36.7034],
            [-121.729, 36.7385],
            [-121.6462, 36.7616],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635790',
        NAME: 'Santa Rita Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99387295,
        AWATER: 331677,
        INTPTLAT: '+36.7508168',
        INTPTLON: '-121.5929400',
        ELSDLEA: '35790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7483, 36.6986],
            [-121.7349, 36.6984],
            [-121.6831, 36.6793],
            [-121.6055, 36.6542],
            [-121.5763, 36.6074],
            [-121.5312, 36.533],
            [-121.5679, 36.4749],
            [-121.6459, 36.5065],
            [-121.7119, 36.6304],
            [-121.7483, 36.6986],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637710',
        NAME: 'Spreckels Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196382339,
        AWATER: 3729341,
        INTPTLAT: '+36.5797598',
        INTPTLON: '-121.6366315',
        ELSDLEA: '37710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0857, 37.5924],
            [-121.0757, 37.5852],
            [-121.0441, 37.6126],
            [-121.0446, 37.6459],
            [-121.0312, 37.6639],
            [-121.017, 37.6822],
            [-120.944, 37.6668],
            [-120.948, 37.6201],
            [-120.9937, 37.5804],
            [-121.0859, 37.5805],
            [-121.0857, 37.5924],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625130',
        NAME: 'Modesto City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74412786,
        AWATER: 778183,
        INTPTLAT: '+37.6302917',
        INTPTLON: '-121.0043936',
        ELSDLEA: '25130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2061, 37.6949],
            [-121.0599, 37.7493],
            [-121.0619, 37.6894],
            [-121.222, 37.6743],
            [-121.2061, 37.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633900',
        NAME: 'Salida Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61424411,
        AWATER: 3416395,
        INTPTLAT: '+37.6996112',
        INTPTLON: '-121.1230171',
        ELSDLEA: '33900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0599, 37.7493],
            [-121.014, 37.7565],
            [-120.9818, 37.7563],
            [-121.017, 37.6822],
            [-121.0312, 37.6639],
            [-121.0619, 37.6894],
            [-121.0599, 37.7493],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637950',
        NAME: 'Stanislaus Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41883542,
        AWATER: 1897954,
        INTPTLAT: '+37.7180230',
        INTPTLON: '-121.0327140',
        ELSDLEA: '37950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9818, 37.7563],
            [-120.9588, 37.7415],
            [-120.9043, 37.711],
            [-120.9033, 37.667],
            [-120.944, 37.6668],
            [-121.017, 37.6822],
            [-120.9818, 37.7563],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638670',
        NAME: 'Sylvan Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53867378,
        AWATER: 529291,
        INTPTLAT: '+37.6989525',
        INTPTLON: '-120.9609270',
        ELSDLEA: '38670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7827, 37.6251],
            [-120.682, 37.645],
            [-120.6817, 37.5955],
            [-120.7646, 37.5656],
            [-120.783, 37.5875],
            [-120.7827, 37.6251],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617160',
        NAME: 'Hickman Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51088353,
        AWATER: 383716,
        INTPTLAT: '+37.6077089',
        INTPTLON: '-120.7312772',
        ELSDLEA: '17160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.682, 37.645],
            [-120.6473, 37.6855],
            [-120.611, 37.7012],
            [-120.5212, 37.6896],
            [-120.5146, 37.5842],
            [-120.5215, 37.5815],
            [-120.6817, 37.5955],
            [-120.682, 37.645],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633210',
        NAME: 'Roberts Ferry Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157045785,
        AWATER: 11590725,
        INTPTLAT: '+37.6281290',
        INTPTLON: '-120.5678645',
        ELSDLEA: '33210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9018, 37.5659],
            [-120.8652, 37.5367],
            [-120.9036, 37.5217],
            [-120.9214, 37.5336],
            [-120.9214, 37.5361],
            [-120.9487, 37.5364],
            [-120.9018, 37.5659],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619620',
        NAME: 'Keyes Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25301621,
        AWATER: 0,
        INTPTLAT: '+37.5476957',
        INTPTLON: '-120.9068962',
        ELSDLEA: '19620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0768, 37.6455],
            [-121.0757, 37.5852],
            [-121.0857, 37.5924],
            [-121.1759, 37.606],
            [-121.2305, 37.6411],
            [-121.0768, 37.6455],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636660',
        NAME: 'Shiloh Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45976315,
        AWATER: 667667,
        INTPTLAT: '+37.6188919',
        INTPTLON: '-121.1349305',
        ELSDLEA: '36660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5212, 37.6896],
            [-120.4619, 37.6896],
            [-120.4617, 37.6895],
            [-120.3876, 37.6337],
            [-120.3877, 37.6334],
            [-120.5146, 37.5842],
            [-120.5212, 37.6896],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620160',
        NAME: 'La Grange Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81005261,
        AWATER: 2652673,
        INTPTLAT: '+37.6349121',
        INTPTLON: '-120.4363012',
        ELSDLEA: '20160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0768, 37.6455],
            [-121.0446, 37.6459],
            [-121.0441, 37.6126],
            [-121.0757, 37.5852],
            [-121.0768, 37.6455],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629790',
        NAME: 'Paradise Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16802747,
        AWATER: 94500,
        INTPTLAT: '+37.6212392',
        INTPTLON: '-121.0625028',
        ELSDLEA: '29790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.729, 36.7385],
            [-121.685, 36.7034],
            [-121.6831, 36.6793],
            [-121.7349, 36.6984],
            [-121.729, 36.7385],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615870',
        NAME: 'Graves Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18348247,
        AWATER: 0,
        INTPTLAT: '+36.7052990',
        INTPTLON: '-121.7104764',
        ELSDLEA: '15870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6462, 36.7616],
            [-121.6231, 36.8154],
            [-121.5971, 36.8368],
            [-121.5351, 36.7836],
            [-121.6462, 36.7616],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620490',
        NAME: 'Lagunita Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44115534,
        AWATER: 119368,
        INTPTLAT: '+36.7872955',
        INTPTLON: '-121.5890096',
        ELSDLEA: '20490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7119, 36.6304],
            [-121.6459, 36.5065],
            [-121.7774, 36.5517],
            [-121.7119, 36.6304],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641610',
        NAME: 'Washington Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106794081,
        AWATER: 73421,
        INTPTLAT: '+36.5465026',
        INTPTLON: '-121.7013299',
        ELSDLEA: '41610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7473, 37.9161],
            [-120.6527, 37.8314],
            [-120.4619, 37.6896],
            [-120.5212, 37.6896],
            [-120.611, 37.7012],
            [-120.7019, 37.7557],
            [-120.7049, 37.8405],
            [-120.8008, 37.8394],
            [-120.7473, 37.9161],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620010',
        NAME: 'Knights Ferry Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238728293,
        AWATER: 269954,
        INTPTLAT: '+37.7722050',
        INTPTLON: '-120.6494618',
        ELSDLEA: '20010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6186, 38.3191],
            [-122.6079, 38.2899],
            [-122.6278, 38.2684],
            [-122.6337, 38.2716],
            [-122.6441, 38.2595],
            [-122.6515, 38.2637],
            [-122.6553, 38.2659],
            [-122.6569, 38.2667],
            [-122.6186, 38.3191],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641790',
        NAME: 'Waugh Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18789872,
        AWATER: 0,
        INTPTLAT: '+38.2884699',
        INTPTLON: '-122.6365767',
        ELSDLEA: '41790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5001, 36.4158],
            [-121.4298, 36.4538],
            [-121.3265, 36.4125],
            [-121.3877, 36.3425],
            [-121.4979, 36.4136],
            [-121.5001, 36.4158],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625110',
        NAME: 'Mission Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90708898,
        AWATER: 1922171,
        INTPTLAT: '+36.3828860',
        INTPTLON: '-121.4123678',
        ELSDLEA: '25110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7691, 37.4079],
            [-121.7018, 37.3755],
            [-121.5878, 37.3388],
            [-121.7206, 37.3082],
            [-121.7381, 37.341],
            [-121.7862, 37.3621],
            [-121.8006, 37.3355],
            [-121.8363, 37.3231],
            [-121.8576, 37.3451],
            [-121.8691, 37.3664],
            [-121.7691, 37.4079],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602310',
        NAME: 'Alum Rock Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127485548,
        AWATER: 471968,
        INTPTLAT: '+37.3639357',
        INTPTLON: '-121.7167413',
        ELSDLEA: '02310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1899, 37.3203],
            [-122.1841, 37.3348],
            [-122.1156, 37.4127],
            [-122.0617, 37.3608],
            [-122.0611, 37.352],
            [-122.1623, 37.3044],
            [-122.1899, 37.3203],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622650',
        NAME: 'Los Altos Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53472464,
        AWATER: 0,
        INTPTLAT: '+37.3556344',
        INTPTLON: '-122.1301053',
        ELSDLEA: '22650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1623, 37.3044],
            [-122.0611, 37.352],
            [-122.0227, 37.3598],
            [-121.9682, 37.3299],
            [-121.975, 37.3171],
            [-122.0059, 37.2855],
            [-122.1385, 37.2675],
            [-122.1523, 37.2861],
            [-122.1623, 37.3044],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610290',
        NAME: 'Cupertino Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109433176,
        AWATER: 339868,
        INTPTLAT: '+37.3064914',
        INTPTLON: '-122.0806403',
        ELSDLEA: '10290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1385, 37.2675],
            [-122.0059, 37.2855],
            [-122.0026, 37.2789],
            [-121.9962, 37.2457],
            [-122.0297, 37.2173],
            [-122.1385, 37.2675],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635910',
        NAME: 'Saratoga Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61030699,
        AWATER: 63458,
        INTPTLAT: '+37.2450178',
        INTPTLON: '-122.0484662',
        ELSDLEA: '35910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4083, 41.1799],
            [-123.3601, 41.1615],
            [-123.36, 40.846],
            [-123.3475, 40.7053],
            [-123.544, 40.7329],
            [-123.5501, 40.764],
            [-123.5346, 40.9308],
            [-123.448, 40.9438],
            [-123.4083, 41.1799],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606510',
        NAME: 'Burnt Ranch Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544150888,
        AWATER: 3143,
        INTPTLAT: '+40.8894393',
        INTPTLON: '-123.4325603',
        ELSDLEA: '06510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9454, 41.1814],
            [-122.8113, 41.2025],
            [-122.5868, 41.3492],
            [-122.5425, 41.3499],
            [-122.5091, 41.2477],
            [-122.4984, 41.1827],
            [-122.4571, 41.0966],
            [-122.5228, 41.0566],
            [-122.8361, 41.0591],
            [-122.9093, 41.0429],
            [-122.9734, 41.1121],
            [-122.9454, 41.1814],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609240',
        NAME: 'Coffee Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 927372476,
        AWATER: 2326281,
        INTPTLAT: '+41.2034758',
        INTPTLON: '-122.7097356',
        ELSDLEA: '09240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3601, 41.1615],
            [-123.2452, 41.078],
            [-123.0865, 41.0579],
            [-123.1052, 40.9252],
            [-123.107, 40.7036],
            [-123.3475, 40.7053],
            [-123.36, 40.846],
            [-123.3601, 41.1615],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610080',
        NAME: 'Cox Bar Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 953390033,
        AWATER: 8361,
        INTPTLAT: '+40.9502798',
        INTPTLON: '-123.2351549',
        ELSDLEA: '10080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9979, 40.6935],
            [-122.903, 40.6935],
            [-122.8629, 40.6377],
            [-122.711, 40.6248],
            [-122.7055, 40.6051],
            [-122.8606, 40.4827],
            [-122.9747, 40.5299],
            [-123.0375, 40.622],
            [-122.9979, 40.6935],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611430',
        NAME: 'Douglas City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343800436,
        AWATER: 32384,
        INTPTLAT: '+40.5911229',
        INTPTLON: '-122.8702226',
        ELSDLEA: '11430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.107, 40.7036],
            [-123.1052, 40.9252],
            [-123.0865, 41.0579],
            [-123.0438, 41.0141],
            [-122.991, 40.9273],
            [-122.9979, 40.6935],
            [-123.0375, 40.622],
            [-123.107, 40.7036],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619170',
        NAME: 'Junction City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365528089,
        AWATER: 631179,
        INTPTLAT: '+40.8290137',
        INTPTLON: '-123.0228589',
        ELSDLEA: '19170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.903, 40.6935],
            [-122.8262, 40.8399],
            [-122.6572, 40.803],
            [-122.752, 40.6899],
            [-122.711, 40.6248],
            [-122.8629, 40.6377],
            [-122.903, 40.6935],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621480',
        NAME: 'Lewiston Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266070955,
        AWATER: 7814052,
        INTPTLAT: '+40.7461333',
        INTPTLON: '-122.8022178',
        ELSDLEA: '21480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9093, 41.0429],
            [-122.8361, 41.0591],
            [-122.5228, 41.0566],
            [-122.5275, 41.0435],
            [-122.6093, 40.926],
            [-122.6572, 40.803],
            [-122.8262, 40.8399],
            [-122.8266, 40.9398],
            [-122.9142, 40.9981],
            [-122.9093, 41.0429],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639750',
        NAME: 'Trinity Center Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 521919426,
        AWATER: 54482114,
        INTPTLAT: '+40.9469937',
        INTPTLON: '-122.7266042',
        ELSDLEA: '39750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0803, 36.1164],
            [-119.0796, 36.0638],
            [-119.1029, 36.0876],
            [-119.0803, 36.1164],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606570',
        NAME: 'Burton Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16971893,
        AWATER: 239850,
        INTPTLAT: '+36.0816537',
        INTPTLON: '-119.0747994',
        ELSDLEA: '06570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4471, 36.1379],
            [-119.384, 36.1748],
            [-119.286, 36.1016],
            [-119.4112, 36.0511],
            [-119.4467, 36.0654],
            [-119.4471, 36.1379],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629670',
        NAME: 'Palo Verde Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127310995,
        AWATER: 269947,
        INTPTLAT: '+36.1123637',
        INTPTLON: '-119.3851313',
        ELSDLEA: '29670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5017, 36.2478],
            [-119.4561, 36.2688],
            [-119.3539, 36.2763],
            [-119.349, 36.2402],
            [-119.4021, 36.1964],
            [-119.4755, 36.1956],
            [-119.5017, 36.2478],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627900',
        NAME: 'Oak Valley Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78418151,
        AWATER: 75948,
        INTPTLAT: '+36.2326465',
        INTPTLON: '-119.4118752',
        ELSDLEA: '27900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2146, 36.0658],
            [-119.1432, 36.0659],
            [-119.1254, 36.0079],
            [-119.1614, 36.0079],
            [-119.2146, 36.015],
            [-119.2233, 36.0222],
            [-119.2146, 36.0658],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631050',
        NAME: 'Pleasant View Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46608575,
        AWATER: 0,
        INTPTLAT: '+36.0403659',
        INTPTLON: '-119.1747074',
        ELSDLEA: '31050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4755, 36.1956],
            [-119.4021, 36.1964],
            [-119.384, 36.1748],
            [-119.4471, 36.1379],
            [-119.4755, 36.1956],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606420',
        NAME: 'Buena Vista Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55569894,
        AWATER: 54753,
        INTPTLAT: '+36.1709476',
        INTPTLON: '-119.4345322',
        ELSDLEA: '06420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3539, 36.2763],
            [-119.2786, 36.2771],
            [-119.2961, 36.2404],
            [-119.349, 36.2402],
            [-119.3539, 36.2763],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621570',
        NAME: 'Liberty Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 32455274,
        AWATER: 44121,
        INTPTLAT: '+36.2661803',
        INTPTLON: '-119.3046943',
        ELSDLEA: '21570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8884, 35.6164],
            [-118.7664, 35.6591],
            [-118.7442, 35.7902],
            [-118.6811, 35.7899],
            [-118.6803, 35.8334],
            [-118.5739, 35.7897],
            [-118.5892, 35.7897],
            [-118.5851, 35.4426],
            [-118.7021, 35.4419],
            [-118.7998, 35.4996],
            [-118.9044, 35.4994],
            [-118.8884, 35.6164],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621900',
        NAME: 'Linns Valley-Poso Flat Union School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 846007229,
        AWATER: 1214035,
        INTPTLAT: '+35.6017003',
        INTPTLON: '-118.7095301',
        ELSDLEA: '21900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2961, 36.2404],
            [-119.2786, 36.2771],
            [-119.234, 36.2759],
            [-119.188, 36.2184],
            [-119.1879, 36.1962],
            [-119.215, 36.1607],
            [-119.2954, 36.1601],
            [-119.2961, 36.2404],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638340',
        NAME: 'Sundale Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87354087,
        AWATER: 818966,
        INTPTLAT: '+36.2180060',
        INTPTLON: '-119.2497859',
        ELSDLEA: '38340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5294, 36.2482],
            [-119.5017, 36.2478],
            [-119.4755, 36.1956],
            [-119.4471, 36.1379],
            [-119.4467, 36.0654],
            [-119.5363, 36.0655],
            [-119.547, 36.2111],
            [-119.5294, 36.2482],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641820',
        NAME: 'Waukena Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121726313,
        AWATER: 1940,
        INTPTLAT: '+36.1419296',
        INTPTLON: '-119.4964000',
        ELSDLEA: '41820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9467, 36.0802],
            [-118.9268, 36.066],
            [-118.9816, 36.0513],
            [-118.9779, 36.082],
            [-118.9467, 36.0802],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602220',
        NAME: 'Alta Vista Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15438799,
        AWATER: 232,
        INTPTLAT: '+36.0663916',
        INTPTLON: '-118.9412578',
        ELSDLEA: '02220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2146, 36.015],
            [-119.1614, 36.0079],
            [-119.1617, 35.9355],
            [-119.2148, 35.9355],
            [-119.2146, 36.015],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635940',
        NAME: 'Saucelito Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45815655,
        AWATER: 0,
        INTPTLAT: '+35.9783950',
        INTPTLON: '-119.1877290',
        ELSDLEA: '35940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1075, 36.0223],
            [-119.0178, 35.9948],
            [-119.1074, 36.0078],
            [-119.1075, 36.0223],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617520',
        NAME: 'Hope Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 37449317,
        AWATER: 0,
        INTPTLAT: '+36.0100208',
        INTPTLON: '-119.0606887',
        ELSDLEA: '17520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0026, 37.2789],
            [-121.975, 37.3171],
            [-121.9682, 37.3299],
            [-121.9564, 37.3299],
            [-121.9502, 37.3255],
            [-121.947, 37.3255],
            [-121.932, 37.3269],
            [-121.9319, 37.3199],
            [-121.9256, 37.3198],
            [-121.92, 37.3167],
            [-121.9193, 37.3166],
            [-121.9203, 37.2846],
            [-121.9569, 37.2605],
            [-121.9962, 37.2457],
            [-122.0026, 37.2789],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607200',
        NAME: 'Campbell Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33532652,
        AWATER: 145840,
        INTPTLAT: '+37.2896896',
        INTPTLON: '-121.9557532',
        ELSDLEA: '07200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9917, 37.1453],
            [-121.844, 37.1577],
            [-121.8404, 37.1556],
            [-121.8425, 37.0945],
            [-121.8588, 37.0758],
            [-121.9464, 37.0685],
            [-121.9535, 37.0762],
            [-121.984, 37.1356],
            [-121.9917, 37.1453],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622350',
        NAME: 'Loma Prieta Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103638378,
        AWATER: 279482,
        INTPTLAT: '+37.1217971',
        INTPTLON: '-121.8762077',
        ELSDLEA: '22350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9962, 37.2457],
            [-121.9569, 37.2605],
            [-121.9545, 37.2509],
            [-121.8942, 37.2217],
            [-121.844, 37.1577],
            [-121.9917, 37.1453],
            [-122.0297, 37.2173],
            [-121.9962, 37.2457],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622830',
        NAME: 'Los Gatos Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119099982,
        AWATER: 2019398,
        INTPTLAT: '+37.1888286',
        INTPTLON: '-121.9379885',
        ELSDLEA: '22830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1523, 37.2861],
            [-122.1385, 37.2675],
            [-122.0297, 37.2173],
            [-121.9917, 37.1453],
            [-121.984, 37.1356],
            [-122.0215, 37.1278],
            [-122.1515, 37.23],
            [-122.1523, 37.2861],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620700',
        NAME: 'Lakeside Joint Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81921802,
        AWATER: 70374,
        INTPTLAT: '+37.1883085',
        INTPTLON: '-122.0598842',
        ELSDLEA: '20700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0968, 37.4619],
            [-122.0815, 37.4778],
            [-122.0453, 37.4603],
            [-122.0381, 37.4393],
            [-122.0617, 37.3608],
            [-122.1156, 37.4127],
            [-122.0968, 37.4619],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626280',
        NAME: 'Mountain View Whisman Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43487910,
        AWATER: 9223664,
        INTPTLAT: '+37.4140278',
        INTPTLON: '-122.0706785',
        ELSDLEA: '26280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0381, 37.4393],
            [-122.0227, 37.3598],
            [-122.0611, 37.352],
            [-122.0617, 37.3608],
            [-122.0381, 37.4393],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638460',
        NAME: 'Sunnyvale Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35549740,
        AWATER: 9341,
        INTPTLAT: '+37.3897355',
        INTPTLON: '-122.0247588',
        ELSDLEA: '38460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8576, 37.3451],
            [-121.8363, 37.3231],
            [-121.8068, 37.2789],
            [-121.8498, 37.2746],
            [-121.8576, 37.3451],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614370',
        NAME: 'Franklin-McKinley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27661036,
        AWATER: 18008,
        INTPTLAT: '+37.3027677',
        INTPTLON: '-121.8454089',
        ELSDLEA: '14370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.975, 37.3171],
            [-122.0026, 37.2789],
            [-122.0059, 37.2855],
            [-121.975, 37.3171],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625770',
        NAME: 'Moreland Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14400318,
        AWATER: 0,
        INTPTLAT: '+37.2963307',
        INTPTLON: '-121.9805435',
        ELSDLEA: '25770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9545, 37.2509],
            [-121.9058, 37.2608],
            [-121.8942, 37.2217],
            [-121.9545, 37.2509],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640320',
        NAME: 'Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19099952,
        AWATER: 0,
        INTPTLAT: '+37.2420087',
        INTPTLON: '-121.9174106',
        ELSDLEA: '40320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8498, 37.2746],
            [-121.8068, 37.2789],
            [-121.7153, 37.2414],
            [-121.781, 37.2093],
            [-121.8498, 37.2746],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627810',
        NAME: 'Oak Grove Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55520738,
        AWATER: 12748,
        INTPTLAT: '+37.2480299',
        INTPTLON: '-121.7940232',
        ELSDLEA: '27810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8006, 37.3355],
            [-121.7381, 37.341],
            [-121.7206, 37.3082],
            [-121.7153, 37.2414],
            [-121.8068, 37.2789],
            [-121.8363, 37.3231],
            [-121.8006, 37.3355],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613140',
        NAME: 'Evergreen Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77055998,
        AWATER: 13531,
        INTPTLAT: '+37.2942719',
        INTPTLON: '-121.7568524',
        ELSDLEA: '13140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-121.9277, 37.386],
              [-121.9249, 37.3674],
              [-121.9297, 37.3718],
              [-121.933, 37.3749],
              [-121.9277, 37.386],
            ],
          ],
          [
            [
              [-121.9152, 37.3961],
              [-121.8924, 37.4034],
              [-121.8767, 37.3656],
              [-121.9275, 37.3861],
              [-121.9152, 37.3961],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628680',
        NAME: 'Orchard Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15104624,
        AWATER: 0,
        INTPTLAT: '+37.3839025',
        INTPTLON: '-121.9088091',
        ELSDLEA: '28680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7691, 37.4079],
            [-121.8691, 37.3664],
            [-121.8767, 37.3656],
            [-121.8924, 37.4034],
            [-121.7691, 37.4079],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604800',
        NAME: 'Berryessa Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35756594,
        AWATER: 0,
        INTPTLAT: '+37.3993725',
        INTPTLON: '-121.8478925',
        ELSDLEA: '04800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7381, 37.341],
            [-121.8006, 37.3355],
            [-121.7862, 37.3621],
            [-121.7381, 37.341],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626400',
        NAME: 'Mount Pleasant Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15882145,
        AWATER: 0,
        INTPTLAT: '+37.3501513',
        INTPTLON: '-121.7837140',
        ELSDLEA: '26400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9569, 37.2605],
            [-121.9203, 37.2846],
            [-121.9058, 37.2608],
            [-121.9545, 37.2509],
            [-121.9569, 37.2605],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607140',
        NAME: 'Cambrian Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10682480,
        AWATER: 73186,
        INTPTLAT: '+37.2702291',
        INTPTLON: '-121.9324706',
        ELSDLEA: '07140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.932, 37.3269],
            [-121.9256, 37.3198],
            [-121.9319, 37.3199],
            [-121.932, 37.3269],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623130',
        NAME: 'Luther Burbank Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666616,
        AWATER: 0,
        INTPTLAT: '+37.3227603',
        INTPTLON: '-121.9275031',
        ELSDLEA: '23130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0546, 37.1821],
            [-120.0521, 37.1831],
            [-119.78, 37.4026],
            [-119.7639, 37.302],
            [-119.8368, 37.2389],
            [-119.8192, 37.1695],
            [-119.8378, 37.0104],
            [-119.8936, 37.0103],
            [-119.9103, 37.0542],
            [-120.0546, 37.0836],
            [-120.0546, 37.1821],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631920',
        NAME: 'Raymond-Knowles Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 502931732,
        AWATER: 11866102,
        INTPTLAT: '+37.1737072',
        INTPTLON: '-119.9030808',
        ELSDLEA: '31920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4762, 36.7543],
            [-121.483, 36.765],
            [-121.4562, 36.7911],
            [-121.373, 36.7842],
            [-121.307, 36.7416],
            [-121.2808, 36.665],
            [-121.3314, 36.6204],
            [-121.3633, 36.6547],
            [-121.4681, 36.6855],
            [-121.4762, 36.7543],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608640',
        NAME: 'Cienega Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183946291,
        AWATER: 22880,
        INTPTLAT: '+36.7036621',
        INTPTLON: '-121.3980349',
        ELSDLEA: '08640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4292, 36.8817],
            [-121.3548, 36.8682],
            [-121.3365, 36.8103],
            [-121.373, 36.7842],
            [-121.4562, 36.7911],
            [-121.4292, 36.8817],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617340',
        NAME: 'Hollister School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97579027,
        AWATER: 42757,
        INTPTLAT: '+36.8320539',
        INTPTLON: '-121.3987381',
        ELSDLEA: '17340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3252, 36.577],
            [-121.1189, 36.6072],
            [-121.0383, 36.5748],
            [-120.9936, 36.5317],
            [-120.9748, 36.4503],
            [-121.117, 36.3852],
            [-121.1435, 36.4062],
            [-121.2393, 36.5048],
            [-121.2932, 36.5061],
            [-121.3252, 36.577],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618810',
        NAME: 'Jefferson Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 456704159,
        AWATER: 167620,
        INTPTLAT: '+36.5121094',
        INTPTLON: '-121.1260655',
        ELSDLEA: '18810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3995, 36.9607],
            [-121.2154, 36.9612],
            [-121.2197, 36.9115],
            [-121.3548, 36.8682],
            [-121.4292, 36.8817],
            [-121.5117, 36.9492],
            [-121.3995, 36.9607],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627480',
        NAME: 'North County Joint Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230521508,
        AWATER: 958123,
        INTPTLAT: '+36.9251727',
        INTPTLON: '-121.3709258',
        ELSDLEA: '27480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0396, 36.6722],
            [-120.9311, 36.75],
            [-120.9187, 36.7404],
            [-120.7173, 36.5794],
            [-120.5971, 36.4856],
            [-120.597, 36.3575],
            [-120.7448, 36.3842],
            [-120.82, 36.4856],
            [-120.9109, 36.4867],
            [-120.9936, 36.5317],
            [-121.0383, 36.5748],
            [-121.0396, 36.6722],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629770',
        NAME: 'Panoche Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 871939895,
        AWATER: 2456,
        INTPTLAT: '+36.5386960',
        INTPTLON: '-120.7734702',
        ELSDLEA: '29770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3548, 36.8682],
            [-121.2197, 36.9115],
            [-121.1415, 36.8367],
            [-120.9311, 36.75],
            [-121.0396, 36.6722],
            [-121.2012, 36.751],
            [-121.3038, 36.7589],
            [-121.3365, 36.8103],
            [-121.3548, 36.8682],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639690',
        NAME: 'Tres Pinos Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432644358,
        AWATER: 193070,
        INTPTLAT: '+36.7906127',
        INTPTLON: '-121.1650039',
        ELSDLEA: '39690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.307, 36.7416],
            [-121.3038, 36.7589],
            [-121.2012, 36.751],
            [-121.0396, 36.6722],
            [-121.0383, 36.5748],
            [-121.1189, 36.6072],
            [-121.3252, 36.577],
            [-121.3314, 36.6204],
            [-121.2808, 36.665],
            [-121.307, 36.7416],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642630',
        NAME: 'Willow Grove Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351797985,
        AWATER: 338575,
        INTPTLAT: '+36.6417492',
        INTPTLON: '-121.1960046',
        ELSDLEA: '42630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3922, 35.8771],
            [-119.3587, 35.7902],
            [-119.4278, 35.7901],
            [-119.3922, 35.8771],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601980',
        NAME: 'Allensworth Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52502495,
        AWATER: 0,
        INTPTLAT: '+35.8303037',
        INTPTLON: '-119.3890390',
        ELSDLEA: '01980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3365, 36.8103],
            [-121.3038, 36.7589],
            [-121.307, 36.7416],
            [-121.373, 36.7842],
            [-121.3365, 36.8103],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637650',
        NAME: 'Southside Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34193800,
        AWATER: 0,
        INTPTLAT: '+36.7726151',
        INTPTLON: '-121.3431230',
        ELSDLEA: '37650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1632, 35.4487],
            [-119.1006, 35.4514],
            [-119.0744, 35.4051],
            [-119.11, 35.4053],
            [-119.1632, 35.4487],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627450',
        NAME: 'Norris Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39550602,
        AWATER: 35845,
        INTPTLAT: '+35.4293535',
        INTPTLON: '-119.1145027',
        ELSDLEA: '27450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6523, 35.7904],
            [-119.5381, 35.7896],
            [-119.4278, 35.7901],
            [-119.3587, 35.7902],
            [-119.3295, 35.7902],
            [-119.2763, 35.7252],
            [-119.3116, 35.6742],
            [-119.4374, 35.6743],
            [-119.4906, 35.7028],
            [-119.6334, 35.7027],
            [-119.6523, 35.7904],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631350',
        NAME: 'Pond Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343603731,
        AWATER: 3301201,
        INTPTLAT: '+35.7406883',
        INTPTLON: '-119.4650602',
        ELSDLEA: '31350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6667, 35.2616],
            [-119.5538, 35.2676],
            [-119.4836, 35.2675],
            [-119.4988, 35.0913],
            [-119.5536, 35.18],
            [-119.6671, 35.1748],
            [-119.6667, 35.2616],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624780',
        NAME: 'Midway Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223775887,
        AWATER: 0,
        INTPTLAT: '+35.2014456',
        INTPTLON: '-119.5600536',
        ELSDLEA: '24780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7193, 38.2632],
            [-122.6827, 38.2596],
            [-122.6641, 38.2412],
            [-122.6512, 38.2124],
            [-122.6831, 38.1917],
            [-122.6941, 38.1951],
            [-122.7189, 38.2186],
            [-122.7193, 38.2632],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642750',
        NAME: 'Wilmar Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30881577,
        AWATER: 0,
        INTPTLAT: '+38.2290879',
        INTPTLON: '-122.6903443',
        ELSDLEA: '42750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.788, 38.4233],
            [-122.779, 38.4455],
            [-122.7696, 38.4418],
            [-122.743, 38.4384],
            [-122.7394, 38.4122],
            [-122.7736, 38.3982],
            [-122.788, 38.4233],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643200',
        NAME: 'Wright Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14215861,
        AWATER: 0,
        INTPTLAT: '+38.4222292',
        INTPTLON: '-122.7630196',
        ELSDLEA: '43200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-124.0643, 40.9197],
              [-124.0433, 40.9424],
              [-124.0242, 40.9427],
              [-124.0244, 40.8977],
              [-124.0643, 40.9197],
            ],
          ],
          [
            [
              [-124.1626, 40.8687],
              [-124.0244, 40.8977],
              [-123.99, 40.8538],
              [-124.1005, 40.837],
              [-124.1698, 40.8369],
              [-124.1811, 40.8369],
              [-124.1626, 40.8687],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603000',
        NAME: 'Arcata Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47058969,
        AWATER: 13926057,
        INTPTLAT: '+40.8725872',
        INTPTLON: '-124.0776185',
        ELSDLEA: '03000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0242, 40.9427],
            [-124.0039, 40.9426],
            [-123.8865, 40.9424],
            [-123.8867, 40.8979],
            [-123.7163, 40.8972],
            [-123.6993, 40.7636],
            [-123.9106, 40.7805],
            [-123.964, 40.8189],
            [-123.99, 40.8538],
            [-124.0244, 40.8977],
            [-124.0242, 40.9427],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605400',
        NAME: 'Blue Lake Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309644973,
        AWATER: 662604,
        INTPTLAT: '+40.8612709',
        INTPTLON: '-123.8840472',
        ELSDLEA: '05400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.8917, 40.5923],
            [-123.7783, 40.6436],
            [-123.6702, 40.6023],
            [-123.6632, 40.6935],
            [-123.5445, 40.6938],
            [-123.5444, 40.5654],
            [-123.6633, 40.5492],
            [-123.6635, 40.4822],
            [-123.5443, 40.4639],
            [-123.5445, 40.3412],
            [-123.6629, 40.3786],
            [-123.7968, 40.3786],
            [-123.8924, 40.4735],
            [-123.8917, 40.5923],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606000',
        NAME: 'Bridgeville Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 787099335,
        AWATER: 669486,
        INTPTLAT: '+40.5173863',
        INTPTLON: '-123.7061009',
        ELSDLEA: '06000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0991, 41.2671],
            [-123.998, 41.2673],
            [-123.998, 41.1149],
            [-123.9256, 41.1193],
            [-123.9256, 41.0895],
            [-124.1557, 41.0902],
            [-124.0991, 41.2671],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604890',
        NAME: 'Big Lagoon Union Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228214375,
        AWATER: 9731187,
        INTPTLAT: '+41.1575011',
        INTPTLON: '-124.0403106',
        ELSDLEA: '04890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9807, 33.854],
            [-117.9762, 33.8542],
            [-117.8961, 33.8564],
            [-117.8637, 33.8355],
            [-117.9063, 33.7925],
            [-117.9589, 33.8031],
            [-117.9805, 33.8468],
            [-117.9807, 33.854],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602610',
        NAME: 'Anaheim Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54574415,
        AWATER: 278075,
        INTPTLAT: '+33.8265579',
        INTPTLON: '-117.9167756',
        ELSDLEA: '02610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0114, 33.8809],
            [-117.9766, 33.9028],
            [-117.9762, 33.8542],
            [-117.9807, 33.854],
            [-118.0461, 33.8536],
            [-118.0114, 33.8809],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606360',
        NAME: 'Buena Park Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18483512,
        AWATER: 124646,
        INTPTLAT: '+33.8700902',
        INTPTLON: '-118.0018223',
        ELSDLEA: '06360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0461, 33.8536],
            [-117.9807, 33.854],
            [-117.9805, 33.8468],
            [-117.9934, 33.8248],
            [-118.0239, 33.8245],
            [-118.0504, 33.85],
            [-118.0461, 33.8536],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608070',
        NAME: 'Centralia Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15210453,
        AWATER: 610,
        INTPTLAT: '+33.8411497',
        INTPTLON: '-118.0135956',
        ELSDLEA: '08070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0504, 33.85],
            [-118.0239, 33.8245],
            [-118.0194, 33.8075],
            [-118.041, 33.7956],
            [-118.0632, 33.8196],
            [-118.0504, 33.85],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610440',
        NAME: 'Cypress Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15697122,
        AWATER: 23011,
        INTPTLAT: '+33.8203562',
        INTPTLON: '-118.0393935',
        ELSDLEA: '10440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9695, 33.7233],
            [-117.9307, 33.7019],
            [-117.9451, 33.6724],
            [-117.9802, 33.694],
            [-117.9695, 33.7233],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614220',
        NAME: 'Fountain Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19762453,
        AWATER: 200235,
        INTPTLAT: '+33.6957957',
        INTPTLON: '-117.9600966',
        ELSDLEA: '14220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9766, 33.9028],
            [-117.9766, 33.9088],
            [-117.9708, 33.9091],
            [-117.9156, 33.9174],
            [-117.8817, 33.907],
            [-117.8961, 33.8564],
            [-117.9762, 33.8542],
            [-117.9766, 33.9028],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614730',
        NAME: 'Fullerton Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51966934,
        AWATER: 28063,
        INTPTLAT: '+33.8826868',
        INTPTLON: '-117.9307245',
        ELSDLEA: '14730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1259, 33.6972],
            [-118.1238, 33.7104],
            [-118.0744, 33.7261],
            [-117.9802, 33.694],
            [-117.9451, 33.6724],
            [-117.9891, 33.5858],
            [-118.1259, 33.6972],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618030',
        NAME: 'Huntington Beach City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35207710,
        AWATER: 102435253,
        INTPTLAT: '+33.6735714',
        INTPTLON: '-118.0250328',
        ELSDLEA: '18030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9708, 33.9091],
            [-117.9258, 33.946],
            [-117.9156, 33.947],
            [-117.9156, 33.9174],
            [-117.9708, 33.9091],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620190',
        NAME: 'La Habra City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16677601,
        AWATER: 15869,
        INTPTLAT: '+33.9272138',
        INTPTLON: '-117.9402771',
        ELSDLEA: '20190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9805, 33.8468],
            [-117.9589, 33.8031],
            [-117.9911, 33.8029],
            [-117.9934, 33.8248],
            [-117.9805, 33.8468],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623430',
        NAME: 'Magnolia Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13429976,
        AWATER: 0,
        INTPTLAT: '+33.8225698',
        INTPTLON: '-117.9752466',
        ELSDLEA: '23430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0419, 33.7395],
            [-117.9721, 33.7375],
            [-117.9695, 33.7233],
            [-117.9802, 33.694],
            [-118.0744, 33.7261],
            [-118.0419, 33.7395],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628140',
        NAME: 'Ocean View Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 34587455,
        AWATER: 875303,
        INTPTLAT: '+33.7183855',
        INTPTLON: '-118.0178033',
        ELSDLEA: '28140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0239, 33.8245],
            [-117.9934, 33.8248],
            [-117.9911, 33.8029],
            [-118.0194, 33.8075],
            [-118.0239, 33.8245],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636030',
        NAME: 'Savanna Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6040803,
        AWATER: 0,
        INTPTLAT: '+33.8143430',
        INTPTLON: '-118.0066358',
        ELSDLEA: '36030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0425, 33.774],
            [-117.9721, 33.7375],
            [-118.0419, 33.7395],
            [-118.0425, 33.774],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642150',
        NAME: 'Westminster Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25972705,
        AWATER: 0,
        INTPTLAT: '+33.7555589',
        INTPTLON: '-118.0078145',
        ELSDLEA: '42150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.15, 38.621],
            [-121.9347, 38.6347],
            [-121.8772, 38.5831],
            [-121.8613, 38.5385],
            [-121.9689, 38.4749],
            [-122.1121, 38.4761],
            [-122.1034, 38.5122],
            [-122.15, 38.621],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642930',
        NAME: 'Winters Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331401970,
        AWATER: 980301,
        INTPTLAT: '+38.5688660',
        INTPTLON: '-122.0797813',
        ELSDLEA: '',
        UNSDLEA: '42930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3085, 39.6402],
            [-122.1408, 39.6526],
            [-122.0809, 39.6524],
            [-121.9835, 39.6233],
            [-122.0031, 39.5577],
            [-122.136, 39.3855],
            [-122.4178, 39.384],
            [-122.3983, 39.5237],
            [-122.3085, 39.6402],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642710',
        NAME: 'Willows Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 792451940,
        AWATER: 3765626,
        INTPTLAT: '+39.5166110',
        INTPTLON: '-122.2209672',
        ELSDLEA: '',
        UNSDLEA: '42710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.078, 39.1518],
            [-122.9115, 39.1422],
            [-122.854, 39.0529],
            [-122.8959, 38.9388],
            [-122.9684, 38.9402],
            [-122.9866, 38.9973],
            [-123.093, 39.072],
            [-123.078, 39.1518],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620670',
        NAME: 'Lakeport Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297197806,
        AWATER: 34953187,
        INTPTLAT: '+39.0573241',
        INTPTLON: '-122.9685093',
        ELSDLEA: '',
        UNSDLEA: '20670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8047, 38.8367],
            [-122.7047, 38.8524],
            [-122.6118, 38.8523],
            [-122.4158, 38.768],
            [-122.4273, 38.753],
            [-122.4845, 38.7005],
            [-122.6274, 38.6675],
            [-122.6967, 38.721],
            [-122.7476, 38.7923],
            [-122.8047, 38.8367],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624750',
        NAME: 'Middletown Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 387451818,
        AWATER: 2213121,
        INTPTLAT: '+38.7737905',
        INTPTLON: '-122.6071650',
        ELSDLEA: '',
        UNSDLEA: '24750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3116, 35.6742],
            [-119.2763, 35.7252],
            [-119.1517, 35.6746],
            [-119.0094, 35.6896],
            [-119.0102, 35.6027],
            [-118.9592, 35.5296],
            [-119.0293, 35.5301],
            [-119.1159, 35.5444],
            [-119.2421, 35.5584],
            [-119.241, 35.6309],
            [-119.3116, 35.6742],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624230',
        NAME: 'McFarland Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 423499077,
        AWATER: 37601,
        INTPTLAT: '+35.6228995',
        INTPTLON: '-119.1161985',
        ELSDLEA: '',
        UNSDLEA: '24230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0198, 35.7898],
            [-118.008, 35.7892],
            [-117.9245, 35.7981],
            [-117.4215, 35.7956],
            [-117.4569, 35.6577],
            [-117.5949, 35.6238],
            [-117.6302, 35.451],
            [-117.5223, 35.4461],
            [-117.523, 35.275],
            [-117.6313, 35.2742],
            [-117.8444, 35.2722],
            [-117.8432, 35.3899],
            [-117.9505, 35.3894],
            [-117.9507, 35.4479],
            [-118.157, 35.449],
            [-118.1655, 35.5577],
            [-118.0232, 35.6185],
            [-118.0198, 35.7898],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636800',
        NAME: 'Sierra Sands Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2506296944,
        AWATER: 4658939,
        INTPTLAT: '+35.5552905',
        INTPTLON: '-117.7793146',
        ELSDLEA: '',
        UNSDLEA: '36800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2686, 35.2753],
            [-118.2703, 35.3628],
            [-118.2716, 35.4502],
            [-118.157, 35.449],
            [-117.9507, 35.4479],
            [-117.9505, 35.3894],
            [-117.8432, 35.3899],
            [-117.8444, 35.2722],
            [-117.6313, 35.2742],
            [-117.6331, 35.0869],
            [-117.8996, 35.0874],
            [-117.8991, 34.9994],
            [-118.0247, 34.9545],
            [-118.0957, 34.9094],
            [-118.2032, 34.9074],
            [-118.2035, 34.9653],
            [-118.3098, 34.9656],
            [-118.2722, 35.0945],
            [-118.2686, 35.2753],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625230',
        NAME: 'Mojave Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2408526927,
        AWATER: 2362338,
        INTPTLAT: '+35.1925527',
        INTPTLON: '-118.0317109',
        ELSDLEA: '',
        UNSDLEA: '25230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9377, 39.7982],
            [-122.4786, 39.7995],
            [-122.2511, 39.7993],
            [-122.1884, 39.8269],
            [-122.1597, 39.7975],
            [-122.0999, 39.7498],
            [-122.0962, 39.7438],
            [-122.1408, 39.6526],
            [-122.3085, 39.6402],
            [-122.3466, 39.6976],
            [-122.4584, 39.7575],
            [-122.6256, 39.7573],
            [-122.6257, 39.7092],
            [-122.8927, 39.7089],
            [-122.9377, 39.7982],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600045',
        NAME: 'Orland Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 731090601,
        AWATER: 12145314,
        INTPTLAT: '+39.7749276',
        INTPTLON: '-122.7301903',
        ELSDLEA: '',
        UNSDLEA: '00045',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1148, 40.0429],
            [-121.9917, 40.0548],
            [-121.8862, 40.1006],
            [-121.6347, 40.1008],
            [-121.5864, 40.1005],
            [-121.6178, 40.0394],
            [-121.804, 39.8843],
            [-122.0449, 39.8838],
            [-122.0826, 39.9467],
            [-122.1637, 40.0074],
            [-122.1148, 40.0429],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622860',
        NAME: 'Los Molinos Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 817001484,
        AWATER: 3655174,
        INTPTLAT: '+39.9886253',
        INTPTLON: '-121.9149709',
        ELSDLEA: '',
        UNSDLEA: '22860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4853, 38.5661],
            [-122.4105, 38.5608],
            [-122.3052, 38.5337],
            [-122.3271, 38.6386],
            [-122.4273, 38.753],
            [-122.4158, 38.768],
            [-122.3951, 38.8642],
            [-122.288, 38.8399],
            [-122.1986, 38.6696],
            [-122.15, 38.621],
            [-122.1034, 38.5122],
            [-122.3416, 38.4654],
            [-122.4595, 38.3875],
            [-122.4944, 38.4227],
            [-122.5296, 38.4696],
            [-122.5737, 38.5387],
            [-122.4853, 38.5661],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637830',
        NAME: 'St. Helena Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 730935161,
        AWATER: 81128373,
        INTPTLAT: '+38.5954891',
        INTPTLON: '-122.3199583',
        ELSDLEA: '',
        UNSDLEA: '37830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6274, 38.6675],
            [-122.4845, 38.7005],
            [-122.4851, 38.6074],
            [-122.4853, 38.5661],
            [-122.5737, 38.5387],
            [-122.687, 38.5762],
            [-122.6872, 38.5834],
            [-122.6274, 38.6675],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607020',
        NAME: 'Calistoga Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242823823,
        AWATER: 396255,
        INTPTLAT: '+38.6348225',
        INTPTLON: '-122.6150507',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3402, 38.9242],
            [-122.335, 38.9239],
            [-122.093, 38.9257],
            [-121.9715, 38.8379],
            [-121.8869, 38.7657],
            [-121.8753, 38.6872],
            [-121.9347, 38.6347],
            [-122.15, 38.621],
            [-122.1986, 38.6696],
            [-122.288, 38.8399],
            [-122.3951, 38.8642],
            [-122.3402, 38.9242],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612930',
        NAME: 'Esparto Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 940350210,
        AWATER: 2152367,
        INTPTLAT: '+38.8304650',
        INTPTLON: '-122.1269068',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6195, 38.5906],
            [-121.5536, 38.6033],
            [-121.5087, 38.5968],
            [-121.5072, 38.5912],
            [-121.5581, 38.5019],
            [-121.5837, 38.5082],
            [-121.6195, 38.5906],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641580',
        NAME: 'Washington Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 71138603,
        AWATER: 2896521,
        INTPTLAT: '+38.5364604',
        INTPTLON: '-121.5821883',
        ELSDLEA: '',
        UNSDLEA: '41580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9715, 38.8379],
            [-121.8172, 38.882],
            [-121.6594, 38.8357],
            [-121.6029, 38.7358],
            [-121.6304, 38.6976],
            [-121.5536, 38.6033],
            [-121.6195, 38.5906],
            [-121.8772, 38.5831],
            [-121.9347, 38.6347],
            [-121.8753, 38.6872],
            [-121.8869, 38.7657],
            [-121.9715, 38.8379],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643080',
        NAME: 'Woodland Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 799091748,
        AWATER: 9084174,
        INTPTLAT: '+38.7201907',
        INTPTLON: '-121.7710472',
        ELSDLEA: '',
        UNSDLEA: '43080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3151, 35.9072],
            [-119.9589, 36.1814],
            [-119.8969, 36.1379],
            [-119.8956, 36.0518],
            [-119.7538, 36.051],
            [-119.7548, 35.79],
            [-120.1941, 35.7892],
            [-120.3151, 35.9072],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632270',
        NAME: 'Reef-Sunset Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1414914332,
        AWATER: 1475111,
        INTPTLAT: '+35.9404598',
        INTPTLON: '-119.9911455',
        ELSDLEA: '',
        UNSDLEA: '32270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7538, 36.051],
            [-119.7339, 36.1379],
            [-119.6727, 36.1598],
            [-119.547, 36.2111],
            [-119.5363, 36.0655],
            [-119.4467, 36.0654],
            [-119.4112, 36.0511],
            [-119.4109, 36.0075],
            [-119.4639, 35.9491],
            [-119.5362, 35.949],
            [-119.5381, 35.7896],
            [-119.6523, 35.7904],
            [-119.7548, 35.79],
            [-119.7538, 36.051],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609690',
        NAME: 'Corcoran Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 948642677,
        AWATER: 922030,
        INTPTLAT: '+35.9815933',
        INTPTLON: '-119.6167932',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.5426, 32.7085],
            [-115.4827, 32.7304],
            [-115.3822, 32.7309],
            [-115.363, 32.6741],
            [-115.692, 32.65],
            [-115.64, 32.709],
            [-115.5426, 32.7085],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606900',
        NAME: 'Calexico Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158653820,
        AWATER: 0,
        INTPTLAT: '+32.6882938',
        INTPTLON: '-115.5180099',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.5712, 33.4269],
            [-115.3428, 33.4281],
            [-115.3542, 33.1039],
            [-115.4798, 33.0891],
            [-115.5626, 33.0895],
            [-115.6679, 33.0891],
            [-115.6684, 33.2345],
            [-115.6687, 33.3191],
            [-115.9012, 33.3392],
            [-115.8984, 33.4265],
            [-115.5712, 33.4269],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606990',
        NAME: 'Calipatria Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1087897664,
        AWATER: 236422283,
        INTPTLAT: '+33.2819302',
        INTPTLON: '-115.5605643',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7649, 39.0987],
            [-121.6888, 39.1561],
            [-121.6886, 39.1707],
            [-121.6181, 39.1822],
            [-121.5841, 39.0096],
            [-121.5755, 38.9184],
            [-121.6359, 38.8817],
            [-121.735, 39.0024],
            [-121.7649, 39.0987],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643470',
        NAME: 'Yuba City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294574293,
        AWATER: 4783349,
        INTPTLAT: '+39.0342246',
        INTPTLON: '-121.6633133',
        ELSDLEA: '',
        UNSDLEA: '43470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0351, 34.1584],
            [-117.0136, 34.2926],
            [-116.7154, 34.2936],
            [-116.7158, 34.2066],
            [-116.6073, 34.2063],
            [-116.6065, 34.1214],
            [-116.8279, 34.1217],
            [-117.0351, 34.1584],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604230',
        NAME: 'Bear Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605433487,
        AWATER: 11907318,
        INTPTLAT: '+34.1910759',
        INTPTLON: '-116.8423297',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5592, 34.296],
            [-117.5101, 34.2968],
            [-117.4216, 34.2209],
            [-117.3257, 34.0963],
            [-117.3921, 34.085],
            [-117.4645, 34.2201],
            [-117.5587, 34.2191],
            [-117.5592, 34.296],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632370',
        NAME: 'Rialto Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131481597,
        AWATER: 266401,
        INTPTLAT: '+34.2396896',
        INTPTLON: '-117.5006806',
        ELSDLEA: '',
        UNSDLEA: '32370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2887, 37.85],
            [-122.278, 37.827],
            [-122.3337, 37.8404],
            [-122.2887, 37.85],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612630',
        NAME: 'Emery Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3317306,
        AWATER: 2517858,
        INTPTLAT: '+37.8399065',
        INTPTLON: '-122.2989635',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0781, 37.5174],
            [-122.0385, 37.5634],
            [-121.988, 37.5225],
            [-122.0309, 37.4816],
            [-122.0781, 37.5174],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627060',
        NAME: 'Newark Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36116580,
        AWATER: 60526,
        INTPTLAT: '+37.5045144',
        INTPTLON: '-122.0329158',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1222, 37.7298],
            [-122.1227, 37.716],
            [-122.1768, 37.6694],
            [-122.1222, 37.7298],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634680',
        NAME: 'San Leandro Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30078999,
        AWATER: 5679338,
        INTPTLAT: '+37.7069948',
        INTPTLON: '-122.1663168',
        ELSDLEA: '',
        UNSDLEA: '34680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.744, 33.6136],
            [-117.5779, 33.6431],
            [-117.4663, 33.5911],
            [-117.5097, 33.505],
            [-117.5785, 33.4539],
            [-117.6111, 33.334],
            [-117.7509, 33.419],
            [-117.744, 33.6136],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607440',
        NAME: 'Capistrano Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406415811,
        AWATER: 114471185,
        INTPTLAT: '+33.5050021',
        INTPTLON: '-117.6258158',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.041, 33.7956],
            [-118.0194, 33.8075],
            [-117.9911, 33.8029],
            [-117.9589, 33.8031],
            [-117.9063, 33.7925],
            [-117.8892, 33.7739],
            [-117.9307, 33.7019],
            [-117.9695, 33.7233],
            [-117.9721, 33.7375],
            [-118.0425, 33.774],
            [-118.041, 33.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614880',
        NAME: 'Garden Grove Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74420476,
        AWATER: 360856,
        INTPTLAT: '+33.7664418',
        INTPTLON: '-117.9564828',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6642, 33.6981],
            [-117.4579, 33.6902],
            [-117.4609, 33.598],
            [-117.4663, 33.5911],
            [-117.5779, 33.6431],
            [-117.744, 33.6136],
            [-117.7482, 33.6196],
            [-117.6642, 33.6981],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633860',
        NAME: 'Saddleback Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248804598,
        AWATER: 806296,
        INTPTLAT: '+33.6454869',
        INTPTLON: '-117.5829385',
        ELSDLEA: '',
        UNSDLEA: '33860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8892, 33.7739],
            [-117.8472, 33.7672],
            [-117.8415, 33.6903],
            [-117.8645, 33.6553],
            [-117.9307, 33.7019],
            [-117.8892, 33.7739],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635310',
        NAME: 'Santa Ana Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68342710,
        AWATER: 623061,
        INTPTLAT: '+33.7211480',
        INTPTLON: '-117.8754516',
        ELSDLEA: '',
        UNSDLEA: '35310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8645, 33.6553],
            [-117.8415, 33.6903],
            [-117.7364, 33.7562],
            [-117.6642, 33.6981],
            [-117.7482, 33.6196],
            [-117.7945, 33.609],
            [-117.8645, 33.6553],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '684500',
        NAME: 'Irvine Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151067915,
        AWATER: 986880,
        INTPTLAT: '+33.6783986',
        INTPTLON: '-117.7712541',
        ELSDLEA: '',
        UNSDLEA: '84500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1779, 37.8163],
            [-122.1328, 37.804],
            [-122.0643, 37.7994],
            [-121.978, 37.7296],
            [-121.9777, 37.6999],
            [-121.9276, 37.6219],
            [-121.9328, 37.5917],
            [-121.9517, 37.5975],
            [-122.014, 37.6317],
            [-122.0962, 37.6881],
            [-122.1227, 37.716],
            [-122.1222, 37.7298],
            [-122.1779, 37.8163],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607800',
        NAME: 'Castro Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177385526,
        AWATER: 4276387,
        INTPTLAT: '+37.7196716',
        INTPTLON: '-122.0463454',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9684, 38.9402],
            [-122.8959, 38.9388],
            [-122.854, 39.0529],
            [-122.7443, 39.0267],
            [-122.6879, 38.9558],
            [-122.7047, 38.8524],
            [-122.8047, 38.8367],
            [-122.8216, 38.8501],
            [-122.9684, 38.9402],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619320',
        NAME: 'Kelseyville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290572109,
        AWATER: 36659611,
        INTPTLAT: '+38.9221314',
        INTPTLON: '-122.7895589',
        ELSDLEA: '',
        UNSDLEA: '19320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7662, 39.15],
            [-122.5901, 39.1417],
            [-122.4902, 39.0737],
            [-122.4091, 38.9628],
            [-122.3402, 38.9242],
            [-122.3951, 38.8642],
            [-122.4158, 38.768],
            [-122.6118, 38.8523],
            [-122.7047, 38.8524],
            [-122.6879, 38.9558],
            [-122.7443, 39.0267],
            [-122.7213, 39.0992],
            [-122.7662, 39.15],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620070',
        NAME: 'Konocti Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 815584718,
        AWATER: 40911021,
        INTPTLAT: '+38.9777763',
        INTPTLON: '-122.5579721',
        ELSDLEA: '',
        UNSDLEA: '20070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2578, 38.0071],
            [-121.124, 38.081],
            [-121.1146, 38.1477],
            [-120.9692, 38.163],
            [-120.9264, 38.0774],
            [-120.9265, 38.0172],
            [-120.9721, 37.9736],
            [-121.1664, 37.9422],
            [-121.2578, 38.0071],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621810',
        NAME: 'Linden Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444725504,
        AWATER: 2323061,
        INTPTLAT: '+38.0549190',
        INTPTLON: '-121.0518273',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5719, 38.1315],
            [-121.4961, 38.1264],
            [-121.3931, 38.2186],
            [-121.2637, 38.2074],
            [-121.1523, 38.2054],
            [-121.0064, 38.2505],
            [-120.9955, 38.2254],
            [-120.9692, 38.163],
            [-121.1146, 38.1477],
            [-121.124, 38.081],
            [-121.2578, 38.0071],
            [-121.3228, 38.0283],
            [-121.4215, 37.9954],
            [-121.5086, 37.9995],
            [-121.5729, 38.0742],
            [-121.58, 38.0944],
            [-121.5719, 38.1315],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622230',
        NAME: 'Lodi Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 772761918,
        AWATER: 37522312,
        INTPTLAT: '+38.1205485',
        INTPTLON: '-121.2995155',
        ELSDLEA: '',
        UNSDLEA: '22230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3187, 37.9204],
            [-121.1839, 37.9054],
            [-121.1555, 37.8702],
            [-121.1427, 37.7686],
            [-121.2061, 37.6949],
            [-121.222, 37.6743],
            [-121.24, 37.6649],
            [-121.2807, 37.7108],
            [-121.3282, 37.8079],
            [-121.3187, 37.9204],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623610',
        NAME: 'Manteca Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287845558,
        AWATER: 4267750,
        INTPTLAT: '+37.8082866',
        INTPTLON: '-121.2416672',
        ELSDLEA: '',
        UNSDLEA: '23610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1555, 37.8702],
            [-121.014, 37.7565],
            [-121.0599, 37.7493],
            [-121.2061, 37.6949],
            [-121.1427, 37.7686],
            [-121.1555, 37.8702],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632880',
        NAME: 'Ripon Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139625434,
        AWATER: 1182231,
        INTPTLAT: '+37.7788996',
        INTPTLON: '-121.1341904',
        ELSDLEA: '',
        UNSDLEA: '32880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3707, 37.9681],
            [-121.3228, 38.0283],
            [-121.2578, 38.0071],
            [-121.1664, 37.9422],
            [-121.1839, 37.9054],
            [-121.3187, 37.9204],
            [-121.3707, 37.9681],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638010',
        NAME: 'Stockton Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146392981,
        AWATER: 3253362,
        INTPTLAT: '+37.9553945',
        INTPTLON: '-121.2702579',
        ELSDLEA: '',
        UNSDLEA: '38010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3228, 38.0283],
            [-121.3707, 37.9681],
            [-121.4215, 37.9954],
            [-121.3228, 38.0283],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621690',
        NAME: 'Lincoln Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29824854,
        AWATER: 2402345,
        INTPTLAT: '+37.9995651',
        INTPTLON: '-121.3622008',
        ELSDLEA: '',
        UNSDLEA: '21690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8346, 40.9587],
            [-121.7986, 40.9574],
            [-121.7923, 41.1833],
            [-121.4465, 41.1835],
            [-121.4464, 41.2922],
            [-121.3307, 41.2909],
            [-121.3318, 41.1079],
            [-121.2557, 41.1071],
            [-121.2514, 40.9852],
            [-121.2129, 40.9412],
            [-121.0012, 40.9409],
            [-120.9803, 40.8052],
            [-121.0187, 40.7647],
            [-121.3211, 40.7585],
            [-121.3578, 40.6639],
            [-121.4545, 40.6648],
            [-121.5423, 40.5996],
            [-121.5786, 40.7533],
            [-121.7491, 40.7541],
            [-121.8067, 40.8379],
            [-121.8346, 40.9587],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613470',
        NAME: 'Fall River Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2871851837,
        AWATER: 32216928,
        INTPTLAT: '+40.9633330',
        INTPTLON: '-121.4666060',
        ELSDLEA: '',
        UNSDLEA: '13470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5444, 40.5654],
            [-123.4578, 40.446],
            [-123.3459, 40.3428],
            [-123.1823, 40.4],
            [-123.0657, 40.3439],
            [-123.0654, 40.287],
            [-122.989, 40.2501],
            [-122.9686, 40.1389],
            [-122.934, 39.9781],
            [-123.4473, 39.9772],
            [-123.5445, 40.0019],
            [-123.5445, 40.3412],
            [-123.5443, 40.4639],
            [-123.6635, 40.4822],
            [-123.6633, 40.5492],
            [-123.5444, 40.5654],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637630',
        NAME: 'Southern Trinity Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2269868183,
        AWATER: 4680090,
        INTPTLAT: '+40.0875652',
        INTPTLON: '-123.1534981',
        ELSDLEA: '',
        UNSDLEA: '37630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.4197, 33.4273],
            [-116.4143, 33.4273],
            [-116.2688, 33.4281],
            [-116.0852, 33.4259],
            [-116.082, 33.235],
            [-116.1033, 33.0747],
            [-116.1845, 33.1327],
            [-116.2067, 33.1698],
            [-116.3831, 33.1714],
            [-116.4183, 33.2272],
            [-116.4197, 33.4273],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605700',
        NAME: 'Borrego Springs Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 996077264,
        AWATER: 1535421,
        INTPTLAT: '+33.2560523',
        INTPTLON: '-116.2172487',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4488, 41.5167],
            [-121.106, 41.5175],
            [-121.064, 41.4141],
            [-120.9826, 41.3698],
            [-120.8473, 41.3703],
            [-120.8066, 41.2253],
            [-120.6411, 41.2264],
            [-120.642, 40.9704],
            [-120.813, 40.971],
            [-120.8435, 40.8061],
            [-120.9803, 40.8052],
            [-121.0012, 40.9409],
            [-121.2129, 40.9412],
            [-121.2514, 40.9852],
            [-121.2557, 41.1071],
            [-121.3318, 41.1079],
            [-121.3307, 41.2909],
            [-121.4464, 41.2922],
            [-121.4482, 41.4293],
            [-121.4488, 41.5167],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605010',
        NAME: 'Big Valley Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3132388324,
        AWATER: 39894624,
        INTPTLAT: '+41.1925692',
        INTPTLON: '-121.0442590',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1607, 41.9946],
            [-120.0471, 41.9951],
            [-119.9992, 41.9945],
            [-119.9998, 41.0119],
            [-120.3421, 41.0124],
            [-120.31, 41.1841],
            [-120.1332, 41.1841],
            [-120.2834, 41.5334],
            [-120.2569, 41.7268],
            [-120.2136, 41.8309],
            [-120.2303, 41.911],
            [-120.1607, 41.9946],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638520',
        NAME: 'Surprise Valley Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1997580170,
        AWATER: 217465776,
        INTPTLAT: '+41.4900037',
        INTPTLON: '-120.1374935',
        ELSDLEA: '',
        UNSDLEA: '38520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1708, 40.1594],
            [-119.997, 40.1588],
            [-120.0011, 39.7224],
            [-120.1106, 39.7658],
            [-120.1088, 39.9395],
            [-120.2013, 40.0135],
            [-120.2096, 40.086],
            [-120.3017, 40.1148],
            [-120.1708, 40.1594],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600011',
        NAME: 'Fort Sage Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 682689199,
        AWATER: 5404559,
        INTPTLAT: '+39.9744995',
        INTPTLON: '-120.0901010',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3229, 40.5118],
            [-121.3228, 40.5409],
            [-121.3211, 40.7585],
            [-121.0187, 40.7647],
            [-121.0246, 40.4101],
            [-120.8527, 40.4092],
            [-120.7964, 40.3013],
            [-120.8718, 40.2416],
            [-121.0037, 40.221],
            [-121.0615, 40.2564],
            [-121.0614, 40.4465],
            [-121.3278, 40.4454],
            [-121.3229, 40.5118],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642300',
        NAME: 'Westwood Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1282758004,
        AWATER: 33717293,
        INTPTLAT: '+40.5187694',
        INTPTLON: '-121.1037818',
        ELSDLEA: '',
        UNSDLEA: '42300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1112, 41.9955],
            [-120.8795, 41.9938],
            [-120.414, 41.9935],
            [-120.3285, 41.9932],
            [-120.1607, 41.9946],
            [-120.2303, 41.911],
            [-120.2136, 41.8309],
            [-120.2569, 41.7268],
            [-120.2834, 41.5334],
            [-120.1332, 41.1841],
            [-120.31, 41.1841],
            [-120.3421, 41.0124],
            [-120.3431, 40.9395],
            [-120.6419, 40.9398],
            [-120.642, 40.9704],
            [-120.6411, 41.2264],
            [-120.8066, 41.2253],
            [-120.8473, 41.3703],
            [-120.9826, 41.3698],
            [-121.064, 41.4141],
            [-121.106, 41.5175],
            [-121.1112, 41.9955],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625190',
        NAME: 'Modoc Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6238586850,
        AWATER: 434289331,
        INTPTLAT: '+41.5512606',
        INTPTLON: '-120.6033192',
        ELSDLEA: '',
        UNSDLEA: '25190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4663, 33.5911],
            [-117.4609, 33.598],
            [-117.206, 33.6119],
            [-117.106, 33.6126],
            [-117.1929, 33.5094],
            [-117.1453, 33.4326],
            [-117.1932, 33.4322],
            [-117.3709, 33.4905],
            [-117.5097, 33.505],
            [-117.4663, 33.5911],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600029',
        NAME: 'Murrieta Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434722356,
        AWATER: 169447,
        INTPTLAT: '+33.5376982',
        INTPTLON: '-117.2882915',
        ELSDLEA: '',
        UNSDLEA: '00029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1204, 32.6088],
            [-117.1343, 32.6054],
            [-117.1632, 32.6658],
            [-117.1333, 32.676],
            [-117.1204, 32.6088],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609870',
        NAME: 'Coronado Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19883741,
        AWATER: 15633104,
        INTPTLAT: '+32.6733920',
        INTPTLON: '-117.1675622',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9966, 33.0521],
            [-116.9183, 33.0805],
            [-116.8766, 33.1828],
            [-116.823, 33.1226],
            [-116.7208, 33.1237],
            [-116.7191, 32.9059],
            [-116.7902, 32.9066],
            [-116.7887, 32.9637],
            [-116.9621, 32.9628],
            [-116.9966, 33.0521],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631710',
        NAME: 'Ramona City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444973925,
        AWATER: 5036714,
        INTPTLAT: '+33.0314157',
        INTPTLON: '-116.8358394',
        ELSDLEA: '',
        UNSDLEA: '31710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2352, 33.1316],
            [-117.1909, 33.2275],
            [-117.1593, 33.2268],
            [-117.1147, 33.1268],
            [-117.1567, 33.0945],
            [-117.1959, 33.0877],
            [-117.2613, 33.0921],
            [-117.2352, 33.1316],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634880',
        NAME: 'San Marcos Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109498708,
        AWATER: 275324,
        INTPTLAT: '+33.1436677',
        INTPTLON: '-117.1796935',
        ELSDLEA: '',
        UNSDLEA: '34880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6029, 36.7784],
            [-120.3742, 36.7913],
            [-120.2775, 36.7687],
            [-120.2736, 36.7742],
            [-120.278, 36.7488],
            [-120.2787, 36.6874],
            [-120.4953, 36.5747],
            [-120.4943, 36.4869],
            [-120.5971, 36.4856],
            [-120.7173, 36.5794],
            [-120.6392, 36.6338],
            [-120.6029, 36.7784],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600022',
        NAME: 'Mendota Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 824298864,
        AWATER: 7315266,
        INTPTLAT: '+36.6581781',
        INTPTLON: '-120.5006818',
        ELSDLEA: '',
        UNSDLEA: '00022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.305, 36.5882],
            [-119.2329, 36.5948],
            [-119.1932, 36.688],
            [-118.9848, 36.6571],
            [-118.9289, 36.7412],
            [-118.3606, 36.7448],
            [-118.3294, 36.6666],
            [-118.2918, 36.5705],
            [-118.9811, 36.5666],
            [-119.1773, 36.5651],
            [-119.1952, 36.5137],
            [-119.2323, 36.4576],
            [-119.2952, 36.4695],
            [-119.305, 36.5882],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610350',
        NAME: 'Cutler-Orosi Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1472200601,
        AWATER: 7971389,
        INTPTLAT: '+36.6156243',
        INTPTLON: '-118.6975741',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8711, 36.8516],
            [-119.8368, 36.8537],
            [-119.7361, 36.8376],
            [-119.6821, 36.7369],
            [-119.7184, 36.7077],
            [-119.7637, 36.6924],
            [-119.7727, 36.6923],
            [-119.8268, 36.714],
            [-119.8445, 36.7139],
            [-119.8711, 36.8516],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614550',
        NAME: 'Fresno Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198631888,
        AWATER: 253355,
        INTPTLAT: '+36.7745379',
        INTPTLON: '-119.7826650',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5284, 36.6666],
            [-119.413, 36.706],
            [-119.314, 36.8381],
            [-118.3907, 36.8339],
            [-118.3606, 36.7448],
            [-118.9289, 36.7412],
            [-118.9848, 36.6571],
            [-119.1932, 36.688],
            [-119.2329, 36.5948],
            [-119.305, 36.5882],
            [-119.359, 36.5745],
            [-119.4487, 36.5203],
            [-119.4576, 36.5246],
            [-119.4962, 36.5357],
            [-119.4937, 36.5755],
            [-119.5284, 36.6666],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619700',
        NAME: 'Kings Canyon Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1548678808,
        AWATER: 4729423,
        INTPTLAT: '+36.7383433',
        INTPTLON: '-119.0398425',
        ELSDLEA: '',
        UNSDLEA: '19700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6755, 37.0092],
            [-119.563, 37.0641],
            [-119.5594, 37.1436],
            [-119.4068, 37.16],
            [-119.3694, 37.146],
            [-119.3701, 37.0938],
            [-119.3672, 37.0197],
            [-119.196, 37.0348],
            [-118.4326, 37.0345],
            [-118.3688, 36.8977],
            [-118.3907, 36.8339],
            [-119.314, 36.8381],
            [-119.3993, 36.8702],
            [-119.4736, 36.9494],
            [-119.6739, 36.9451],
            [-119.6755, 37.0092],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636805',
        NAME: 'Sierra Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2374178196,
        AWATER: 41509541,
        INTPTLAT: '+36.9573725',
        INTPTLON: '-119.0074137',
        ELSDLEA: '',
        UNSDLEA: '36805',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0557, 39.864],
            [-122.0449, 39.8838],
            [-121.804, 39.8843],
            [-121.6178, 40.0394],
            [-121.6351, 39.7852],
            [-121.6911, 39.6848],
            [-121.9692, 39.657],
            [-121.9416, 39.6981],
            [-122.0228, 39.7664],
            [-122.0465, 39.7976],
            [-122.0645, 39.8336],
            [-122.0557, 39.864],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608370',
        NAME: 'Chico Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 851890919,
        AWATER: 6949316,
        INTPTLAT: '+39.8138490',
        INTPTLON: '-121.7787870',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8927, 39.3619],
            [-121.8365, 39.391],
            [-121.6174, 39.4054],
            [-121.5764, 39.3108],
            [-121.6238, 39.2956],
            [-121.6302, 39.3057],
            [-121.6834, 39.3485],
            [-121.7055, 39.3046],
            [-121.9083, 39.3039],
            [-121.8927, 39.3619],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600051',
        NAME: 'Gridley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223121295,
        AWATER: 1579915,
        INTPTLAT: '+39.3517543',
        INTPTLON: '-121.7427081',
        ELSDLEA: '',
        UNSDLEA: '00051',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6178, 40.0394],
            [-121.5864, 40.1005],
            [-121.4369, 40.1519],
            [-121.3765, 40.1086],
            [-121.4206, 39.9296],
            [-121.524, 39.9292],
            [-121.595, 39.7141],
            [-121.6911, 39.6848],
            [-121.6351, 39.7852],
            [-121.6178, 40.0394],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629820',
        NAME: 'Paradise Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579632349,
        AWATER: 3890290,
        INTPTLAT: '+39.9655369',
        INTPTLON: '-121.5336930',
        ELSDLEA: '',
        UNSDLEA: '29820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-120.9043, 37.711],
              [-120.8302, 37.6822],
              [-120.9033, 37.667],
              [-120.9043, 37.711],
            ],
          ],
          [
            [
              [-120.9588, 37.7415],
              [-120.9229, 37.7588],
              [-120.9043, 37.711],
              [-120.9588, 37.7415],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600061',
        NAME: 'Riverbank Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52873374,
        AWATER: 564851,
        INTPTLAT: '+37.6928701',
        INTPTLON: '-120.8826599',
        ELSDLEA: '',
        UNSDLEA: '00061',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6359, 34.9528],
            [-118.3098, 34.9656],
            [-118.2035, 34.9653],
            [-118.2032, 34.9074],
            [-118.0957, 34.9094],
            [-118.0247, 34.9545],
            [-118.0234, 34.8222],
            [-118.0423, 34.822],
            [-118.1668, 34.8206],
            [-118.6257, 34.8182],
            [-118.6359, 34.9528],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637620',
        NAME: 'Southern Kern Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 791426711,
        AWATER: 665891,
        INTPTLAT: '+34.8891545',
        INTPTLON: '-118.3374689',
        ELSDLEA: '',
        UNSDLEA: '37620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6671, 37.1579],
            [-118.3247, 37.1454],
            [-118.3246, 37.2026],
            [-117.479, 37.2045],
            [-117.166, 36.9711],
            [-117.0645, 36.8947],
            [-118.3688, 36.8977],
            [-118.4326, 37.0345],
            [-118.538, 37.1136],
            [-118.6671, 37.1579],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604950',
        NAME: 'Big Pine Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3502682378,
        AWATER: 17337435,
        INTPTLAT: '+37.0504480',
        INTPTLON: '-117.6620765',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3907, 36.8339],
            [-118.3688, 36.8977],
            [-117.0645, 36.8947],
            [-116.8894, 36.7635],
            [-116.8892, 36.75],
            [-117.5555, 36.7517],
            [-118.0073, 36.7534],
            [-118.061, 36.6657],
            [-118.3294, 36.6666],
            [-118.3606, 36.7448],
            [-118.3907, 36.8339],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629190',
        NAME: 'Owens Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2265098429,
        AWATER: 4216471,
        INTPTLAT: '+36.8168050',
        INTPTLON: '-117.6237784',
        ELSDLEA: '',
        UNSDLEA: '29190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3294, 36.6666],
            [-118.061, 36.6657],
            [-118.0073, 36.7534],
            [-117.5555, 36.7517],
            [-117.5323, 36.5834],
            [-117.2899, 36.3802],
            [-117.5119, 36.3471],
            [-117.5115, 36.2315],
            [-117.4343, 36.1455],
            [-117.4234, 35.9708],
            [-117.4215, 35.7956],
            [-117.9245, 35.7981],
            [-118.008, 35.7892],
            [-117.9825, 35.8958],
            [-118.0101, 35.9776],
            [-118.0402, 36.0324],
            [-118.1276, 36.2803],
            [-118.1007, 36.3354],
            [-118.2489, 36.4865],
            [-118.2918, 36.5705],
            [-118.3294, 36.6666],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622440',
        NAME: 'Lone Pine Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6216158927,
        AWATER: 38991533,
        INTPTLAT: '+36.2927259',
        INTPTLON: '-117.8653519',
        ELSDLEA: '',
        UNSDLEA: '22440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2062, 36.4284],
            [-119.1952, 36.5137],
            [-119.1773, 36.5651],
            [-118.9811, 36.5666],
            [-118.9564, 36.4013],
            [-119.1345, 36.3583],
            [-119.1531, 36.3773],
            [-119.2062, 36.4284],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601418',
        NAME: 'Woodlake Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391270115,
        AWATER: 5322526,
        INTPTLAT: '+36.4829385',
        INTPTLON: '-119.0447462',
        ELSDLEA: '',
        UNSDLEA: '01418',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1531, 36.3773],
            [-119.1345, 36.3583],
            [-119.0633, 36.3129],
            [-118.9917, 36.3384],
            [-118.8548, 36.2938],
            [-118.9013, 36.1953],
            [-118.9926, 36.2255],
            [-119.1364, 36.2462],
            [-119.188, 36.2184],
            [-119.1765, 36.2825],
            [-119.1789, 36.305],
            [-119.1531, 36.3773],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601423',
        NAME: 'Exeter Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295593914,
        AWATER: 0,
        INTPTLAT: '+36.2777988',
        INTPTLON: '-119.0246818',
        ELSDLEA: '',
        UNSDLEA: '01423',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5006, 36.9952],
            [-120.4586, 36.9699],
            [-120.2576, 36.9709],
            [-120.2568, 37.0401],
            [-120.0546, 37.0836],
            [-119.9103, 37.0542],
            [-119.8936, 37.0103],
            [-119.906, 36.8501],
            [-120.0339, 36.8154],
            [-120.2736, 36.7742],
            [-120.2775, 36.7687],
            [-120.273, 36.9241],
            [-120.4572, 36.9239],
            [-120.5006, 36.9952],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623340',
        NAME: 'Madera Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 998268507,
        AWATER: 1447001,
        INTPTLAT: '+36.9419167',
        INTPTLON: '-120.1238633',
        ELSDLEA: '',
        UNSDLEA: '23340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1863, 42.0075],
            [-121.6831, 42.0004],
            [-121.6848, 41.8627],
            [-121.5688, 41.8625],
            [-121.5688, 41.7764],
            [-121.449, 41.7764],
            [-121.4488, 41.5167],
            [-121.4482, 41.4293],
            [-121.9102, 41.4367],
            [-121.9093, 41.5227],
            [-122.0271, 41.5229],
            [-122.0264, 41.6093],
            [-122.1332, 41.6107],
            [-122.1336, 41.6902],
            [-122.1516, 41.8063],
            [-122.2477, 41.8066],
            [-122.2474, 41.8581],
            [-122.2474, 41.9522],
            [-122.1863, 42.0075],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606580',
        NAME: 'Butte Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3056550249,
        AWATER: 58188403,
        INTPTLAT: '+41.7336072',
        INTPTLON: '-121.8396984',
        ELSDLEA: '',
        UNSDLEA: '06580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6238, 39.2956],
            [-121.5764, 39.3108],
            [-121.4828, 39.3266],
            [-121.3729, 39.3646],
            [-121.3334, 39.4687],
            [-121.3151, 39.5446],
            [-121.1854, 39.5558],
            [-121.1367, 39.6282],
            [-121.0095, 39.6395],
            [-121.0537, 39.5222],
            [-121.13, 39.5192],
            [-121.1368, 39.3681],
            [-121.2096, 39.2963],
            [-121.2795, 39.2305],
            [-121.3845, 39.2204],
            [-121.3956, 39.1488],
            [-121.5078, 39.1141],
            [-121.5841, 39.0096],
            [-121.6181, 39.1822],
            [-121.6236, 39.1974],
            [-121.6238, 39.2956],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624090',
        NAME: 'Marysville Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1196179179,
        AWATER: 18292938,
        INTPTLAT: '+39.3278994',
        INTPTLON: '-121.3543265',
        ELSDLEA: '',
        UNSDLEA: '24090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6865, 37.2911],
            [-118.5723, 37.2913],
            [-118.5725, 37.361],
            [-118.4594, 37.3612],
            [-118.4599, 37.463],
            [-117.8327, 37.4649],
            [-117.479, 37.2045],
            [-118.3246, 37.2026],
            [-118.3247, 37.1454],
            [-118.6671, 37.1579],
            [-118.6674, 37.16],
            [-118.6865, 37.2911],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601409',
        NAME: 'Bishop Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2487892116,
        AWATER: 8854528,
        INTPTLAT: '+37.2918324',
        INTPTLON: '-118.1356131',
        ELSDLEA: '',
        UNSDLEA: '01409',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0957, 38.0484],
            [-121.9327, 38.0481],
            [-121.9319, 37.98],
            [-121.869, 37.917],
            [-121.8322, 37.9171],
            [-121.8321, 37.8544],
            [-121.8324, 37.846],
            [-122.0072, 37.8824],
            [-122.0919, 37.917],
            [-122.0985, 37.9477],
            [-122.0957, 38.0484],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626370',
        NAME: 'Mount Diablo Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323426211,
        AWATER: 13062509,
        INTPTLAT: '+37.9599292',
        INTPTLON: '-121.9825760',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5005, 38.4161],
            [-121.4414, 38.503],
            [-121.3053, 38.5402],
            [-121.1959, 38.5693],
            [-121.0542, 38.57],
            [-121.0275, 38.5083],
            [-121.0272, 38.413],
            [-121.2606, 38.3798],
            [-121.3923, 38.2979],
            [-121.4289, 38.2542],
            [-121.4843, 38.2415],
            [-121.5005, 38.4161],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612330',
        NAME: 'Elk Grove Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 850256656,
        AWATER: 5006014,
        INTPTLAT: '+38.4413328',
        INTPTLON: '-121.2711972',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.42, 38.5961],
            [-121.4056, 38.6383],
            [-121.4013, 38.6541],
            [-121.3347, 38.7035],
            [-121.3088, 38.7228],
            [-121.2361, 38.7177],
            [-121.1938, 38.7147],
            [-121.1946, 38.6458],
            [-121.3443, 38.5784],
            [-121.42, 38.5961],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634620',
        NAME: 'San Juan Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196793769,
        AWATER: 2854505,
        INTPTLAT: '+38.6486140',
        INTPTLON: '-121.3066981',
        ELSDLEA: '',
        UNSDLEA: '34620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1361, 34.4126],
            [-119.096, 34.4208],
            [-119.0259, 34.3936],
            [-119.0066, 34.3599],
            [-119.0073, 34.3288],
            [-119.0293, 34.3273],
            [-119.0802, 34.3129],
            [-119.1361, 34.4126],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601421',
        NAME: 'Santa Paula Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74158422,
        AWATER: 937151,
        INTPTLAT: '+34.3663531',
        INTPTLON: '-119.0729271',
        ELSDLEA: '',
        UNSDLEA: '01421',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0095, 39.6395],
            [-120.8715, 39.7765],
            [-120.6808, 39.6768],
            [-120.6542, 39.7066],
            [-120.3599, 39.7085],
            [-120.3547, 39.8987],
            [-120.5638, 39.9547],
            [-120.5634, 40.2636],
            [-120.4364, 40.1696],
            [-120.3017, 40.1148],
            [-120.2096, 40.086],
            [-120.2013, 40.0135],
            [-120.1088, 39.9395],
            [-120.1106, 39.7658],
            [-120.0011, 39.7224],
            [-120.0038, 39.4451],
            [-120.455, 39.4461],
            [-120.5772, 39.5231],
            [-120.659, 39.5238],
            [-120.7557, 39.4527],
            [-121.0221, 39.3916],
            [-121.0537, 39.5222],
            [-121.0095, 39.6395],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636780',
        NAME: 'Sierra-Plumas Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3781936266,
        AWATER: 29992989,
        INTPTLAT: '+39.6238411',
        INTPTLON: '-120.3107973',
        ELSDLEA: '',
        UNSDLEA: '36780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5775, 37.098],
            [-120.5417, 37.0445],
            [-120.5006, 36.9952],
            [-120.4572, 36.9239],
            [-120.5992, 36.9227],
            [-120.6029, 36.7784],
            [-120.6392, 36.6338],
            [-120.7173, 36.5794],
            [-120.9187, 36.7404],
            [-120.707, 36.9118],
            [-120.7075, 37.0979],
            [-120.5775, 37.098],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600033',
        NAME: 'Dos Palos-Oro Loma Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 954395314,
        AWATER: 4117421,
        INTPTLAT: '+36.8424528',
        INTPTLON: '-120.6902072',
        ELSDLEA: '',
        UNSDLEA: '00033',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2268, 37.1348],
            [-120.9728, 37.3386],
            [-120.8185, 37.2489],
            [-120.8149, 37.236],
            [-120.9672, 37.1878],
            [-120.9982, 37.0756],
            [-121.2301, 37.1008],
            [-121.2268, 37.1348],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616323',
        NAME: 'Gustine Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492774142,
        AWATER: 19837983,
        INTPTLAT: '+37.1950410',
        INTPTLON: '-121.0216677',
        ELSDLEA: '',
        UNSDLEA: '16323',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8128, 37.4403],
            [-120.7488, 37.4557],
            [-120.7314, 37.4057],
            [-120.814, 37.3828],
            [-120.8128, 37.4403],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600039',
        NAME: 'Delhi Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44314461,
        AWATER: 17212,
        INTPTLAT: '+37.4202770',
        INTPTLON: '-120.7756334',
        ELSDLEA: '',
        UNSDLEA: '00039',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4142, 38.7952],
            [-121.3585, 38.7662],
            [-121.3645, 38.7039],
            [-121.4434, 38.7049],
            [-121.4142, 38.7952],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607900',
        NAME: 'Center Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53122657,
        AWATER: 0,
        INTPTLAT: '+38.7412979',
        INTPTLON: '-121.4029721',
        ELSDLEA: '',
        UNSDLEA: '07900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6029, 38.7358],
            [-121.5586, 38.7363],
            [-121.5583, 38.7363],
            [-121.558, 38.7001],
            [-121.4934, 38.6708],
            [-121.4932, 38.642],
            [-121.5087, 38.5968],
            [-121.5536, 38.6033],
            [-121.6304, 38.6976],
            [-121.6029, 38.7358],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600036',
        NAME: 'Natomas Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95948245,
        AWATER: 2823304,
        INTPTLAT: '+38.6670737',
        INTPTLON: '-121.5671691',
        ELSDLEA: '',
        UNSDLEA: '00036',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5581, 38.5019],
            [-121.5072, 38.5912],
            [-121.42, 38.5961],
            [-121.3443, 38.5784],
            [-121.3053, 38.5402],
            [-121.4414, 38.503],
            [-121.5005, 38.4161],
            [-121.5581, 38.5019],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633840',
        NAME: 'Sacramento City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174952844,
        AWATER: 4937983,
        INTPTLAT: '+38.5266112',
        INTPTLON: '-121.4544188',
        ELSDLEA: '',
        UNSDLEA: '33840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4098, 37.8977],
            [-120.3295, 37.891],
            [-120.2685, 37.8912],
            [-119.9387, 37.891],
            [-119.9059, 37.9787],
            [-119.3205, 37.9732],
            [-119.2001, 37.8845],
            [-119.2013, 37.8043],
            [-119.269, 37.7392],
            [-119.309, 37.778],
            [-119.534, 37.9029],
            [-119.6672, 37.8012],
            [-119.8055, 37.7596],
            [-119.9384, 37.763],
            [-120.0266, 37.8119],
            [-120.3454, 37.7248],
            [-120.3876, 37.6337],
            [-120.4617, 37.6895],
            [-120.353, 37.7755],
            [-120.4098, 37.8977],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '691137',
        NAME: 'Big Oak Flat-Groveland Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1663688747,
        AWATER: 44952054,
        INTPTLAT: '+37.8437086',
        INTPTLON: '-119.7597602',
        ELSDLEA: '',
        UNSDLEA: '91137',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.597, 36.3575],
            [-120.5971, 36.4856],
            [-120.4943, 36.4869],
            [-120.3933, 36.4001],
            [-120.3922, 36.3269],
            [-120.1576, 36.3131],
            [-120.0489, 36.3279],
            [-120.0486, 36.2555],
            [-119.9589, 36.2555],
            [-119.9589, 36.1814],
            [-120.3151, 35.9072],
            [-120.3623, 35.9652],
            [-120.4778, 36.0033],
            [-120.6951, 36.1555],
            [-120.7468, 36.1998],
            [-120.597, 36.3575],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609120',
        NAME: 'Coalinga-Huron Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2380264696,
        AWATER: 360375,
        INTPTLAT: '+36.1972313',
        INTPTLON: '-120.3635907',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9938, 36.8408],
            [-121.9317, 36.8893],
            [-121.9375, 36.9572],
            [-121.9192, 37.0453],
            [-121.8588, 37.0758],
            [-121.8425, 37.0945],
            [-121.7783, 37.0561],
            [-121.6981, 36.9726],
            [-121.5814, 36.8992],
            [-121.5971, 36.8368],
            [-121.6231, 36.8154],
            [-121.69, 36.8508],
            [-121.8043, 36.8383],
            [-122.0519, 36.8196],
            [-121.9938, 36.8408],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629490',
        NAME: 'Pajaro Valley Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 471682183,
        AWATER: 136337822,
        INTPTLAT: '+36.9350760',
        INTPTLON: '-121.7902311',
        ELSDLEA: '',
        UNSDLEA: '29490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5814, 36.8992],
            [-121.5117, 36.9492],
            [-121.4292, 36.8817],
            [-121.4562, 36.7911],
            [-121.483, 36.765],
            [-121.5351, 36.7836],
            [-121.5971, 36.8368],
            [-121.5814, 36.8992],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '691136',
        NAME: 'Aromas-San Juan Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185726985,
        AWATER: 193065,
        INTPTLAT: '+36.8512977',
        INTPTLON: '-121.5213084',
        ELSDLEA: '',
        UNSDLEA: '91136',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4312, 37.679],
            [-122.279, 37.672],
            [-122.279, 37.6339],
            [-122.4543, 37.6321],
            [-122.4641, 37.6388],
            [-122.4739, 37.6574],
            [-122.4312, 37.679],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637530',
        NAME: 'South San Francisco Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29539476,
        AWATER: 38401356,
        INTPTLAT: '+37.6551037',
        INTPTLON: '-122.3762341',
        ELSDLEA: '',
        UNSDLEA: '37530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.093, 35.1142],
            [-119.8308, 35.0058],
            [-119.7089, 34.9753],
            [-119.5358, 34.8976],
            [-119.4728, 34.9012],
            [-119.2769, 34.8797],
            [-119.1719, 34.8135],
            [-119.1549, 34.7064],
            [-119.1546, 34.5614],
            [-119.4424, 34.5611],
            [-119.4414, 34.7333],
            [-119.4761, 34.8195],
            [-119.8014, 34.8212],
            [-119.8011, 34.8959],
            [-119.9829, 34.8959],
            [-119.9836, 34.9698],
            [-120.0835, 34.9685],
            [-120.093, 35.1142],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600009',
        NAME: 'Cuyama Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1695009751,
        AWATER: 17592308,
        INTPTLAT: '+34.8335653',
        INTPTLON: '-119.6194202',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5917, 34.4658],
            [-119.4423, 34.4655],
            [-119.4423, 34.4639],
            [-119.501, 34.3269],
            [-119.6123, 34.3674],
            [-119.5917, 34.4658],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607560',
        NAME: 'Carpinteria Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105229052,
        AWATER: 73726085,
        INTPTLAT: '+34.4106580',
        INTPTLON: '-119.5277883',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.4785, 32.7594],
            [-115.4727, 32.8249],
            [-115.465, 32.9419],
            [-115.4601, 32.953],
            [-115.2641, 32.9503],
            [-115.252, 33.152],
            [-115.2218, 33.1116],
            [-115.0839, 33.0817],
            [-115.0865, 32.9917],
            [-114.9257, 32.9887],
            [-114.9269, 32.835],
            [-114.9671, 32.8119],
            [-114.9809, 32.7009],
            [-115.363, 32.6741],
            [-115.3822, 32.7309],
            [-115.4827, 32.7304],
            [-115.4785, 32.7594],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617430',
        NAME: 'Holtville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1731298136,
        AWATER: 11902,
        INTPTLAT: '+32.8826377',
        INTPTLON: '-115.2067350',
        ELSDLEA: '',
        UNSDLEA: '17430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1031, 32.9041],
            [-116.103, 32.9471],
            [-115.7791, 32.929],
            [-115.7345, 32.9605],
            [-115.6077, 32.9265],
            [-115.465, 32.9419],
            [-115.4727, 32.8249],
            [-115.5357, 32.8253],
            [-115.595, 32.8183],
            [-115.6182, 32.8255],
            [-115.7049, 32.8108],
            [-115.7072, 32.7231],
            [-115.8433, 32.7139],
            [-115.8433, 32.6387],
            [-116.1062, 32.6186],
            [-116.1031, 32.9041],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618210',
        NAME: 'Imperial Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1479390624,
        AWATER: 74124,
        INTPTLAT: '+32.8103043',
        INTPTLON: '-115.8255157',
        ELSDLEA: '',
        UNSDLEA: '18210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.2818, 35.7934],
            [-115.7359, 35.7936],
            [-115.6484, 35.8092],
            [-114.7132, 35.0665],
            [-115.4276, 35.0596],
            [-115.4273, 34.972],
            [-115.5257, 34.9593],
            [-115.538, 34.8151],
            [-116.0906, 34.8162],
            [-116.0896, 35.002],
            [-116.4885, 35.0008],
            [-116.4489, 35.0687],
            [-116.4462, 35.1682],
            [-116.2919, 35.1719],
            [-116.2818, 35.7934],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603610',
        NAME: 'Baker Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10181194369,
        AWATER: 3632156,
        INTPTLAT: '+35.2610578',
        INTPTLON: '-115.7943897',
        ELSDLEA: '',
        UNSDLEA: '03610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0906, 34.8162],
            [-115.538, 34.8151],
            [-115.5257, 34.9593],
            [-115.4273, 34.972],
            [-115.4276, 35.0596],
            [-114.7132, 35.0665],
            [-114.6335, 35.0019],
            [-114.6283, 34.8636],
            [-114.5749, 34.8081],
            [-114.4716, 34.713],
            [-114.4389, 34.6223],
            [-114.3396, 34.4514],
            [-114.1933, 34.3574],
            [-114.1383, 34.3032],
            [-114.2541, 34.1738],
            [-114.4354, 34.0797],
            [-114.9274, 34.0797],
            [-115.3161, 34.0778],
            [-115.3162, 34.0341],
            [-115.6663, 34.0353],
            [-115.6956, 34.0353],
            [-115.6955, 34.1801],
            [-115.7609, 34.1811],
            [-115.7616, 34.3972],
            [-115.9882, 34.398],
            [-116.0806, 34.4535],
            [-116.0906, 34.8162],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626760',
        NAME: 'Needles Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14137492246,
        AWATER: 47153988,
        INTPTLAT: '+34.5166414',
        INTPTLON: '-115.2016648',
        ELSDLEA: '',
        UNSDLEA: '26760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6313, 35.2742],
            [-117.523, 35.275],
            [-117.5223, 35.4461],
            [-116.9268, 35.45],
            [-116.9282, 34.8539],
            [-116.7487, 34.833],
            [-116.747, 34.6437],
            [-117.0276, 34.6429],
            [-117.2072, 34.6409],
            [-117.2416, 34.7616],
            [-117.2402, 34.8203],
            [-117.4545, 34.821],
            [-117.4546, 35.0853],
            [-117.6331, 35.0869],
            [-117.6313, 35.2742],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604020',
        NAME: 'Barstow Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4676509936,
        AWATER: 18168294,
        INTPTLAT: '+35.0578822',
        INTPTLON: '-117.1880391',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9268, 35.45],
            [-116.9194, 35.7954],
            [-116.2818, 35.7934],
            [-116.2919, 35.1719],
            [-116.4462, 35.1682],
            [-116.4489, 35.0687],
            [-116.4885, 35.0008],
            [-116.0896, 35.002],
            [-116.0906, 34.8162],
            [-116.0806, 34.4535],
            [-116.4029, 34.4551],
            [-116.7439, 34.4534],
            [-116.747, 34.6437],
            [-116.7487, 34.833],
            [-116.9282, 34.8539],
            [-116.9268, 35.45],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636820',
        NAME: 'Silver Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8752024107,
        AWATER: 6774136,
        INTPTLAT: '+35.0926365',
        INTPTLON: '-116.6172640',
        ELSDLEA: '',
        UNSDLEA: '36820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5154, 34.0772],
            [-117.4668, 34.1214],
            [-117.4645, 34.2201],
            [-117.3921, 34.085],
            [-117.4712, 34.0337],
            [-117.5243, 34.0335],
            [-117.5154, 34.0772],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613920',
        NAME: 'Fontana Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109446069,
        AWATER: 433824,
        INTPTLAT: '+34.1103855',
        INTPTLON: '-117.4581015',
        ELSDLEA: '',
        UNSDLEA: '13920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.7851, 34.0048],
            [-117.7245, 34.0447],
            [-117.606, 34.0305],
            [-117.6069, 33.9728],
            [-117.6737, 33.8708],
            [-117.7656, 33.9338],
            [-117.7833, 33.9464],
            [-117.7851, 34.0048],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608460',
        NAME: 'Chino Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219063152,
        AWATER: 157261,
        INTPTLAT: '+33.9697343',
        INTPTLON: '-117.6955223',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5555, 36.7517],
            [-116.8892, 36.75],
            [-116.8894, 36.7635],
            [-116.4882, 36.4591],
            [-115.846, 35.9637],
            [-115.6484, 35.8092],
            [-115.7359, 35.7936],
            [-116.2818, 35.7934],
            [-116.9194, 35.7954],
            [-117.2695, 35.7951],
            [-117.2695, 35.9706],
            [-117.4234, 35.9708],
            [-117.4343, 36.1455],
            [-117.5115, 36.2315],
            [-117.5119, 36.3471],
            [-117.2899, 36.3802],
            [-117.5323, 36.5834],
            [-117.5555, 36.7517],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610680',
        NAME: 'Death Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11200570867,
        AWATER: 44743523,
        INTPTLAT: '+36.2245687',
        INTPTLON: '-116.8154714',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5876, 37.5736],
            [-122.4654, 37.5701],
            [-122.4021, 37.534],
            [-122.345, 37.463],
            [-122.3185, 37.4344],
            [-122.2864, 37.3946],
            [-122.2873, 37.3596],
            [-122.3385, 37.3124],
            [-122.4723, 37.3115],
            [-122.5099, 37.4416],
            [-122.5681, 37.4792],
            [-122.5876, 37.5736],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606780',
        NAME: 'Cabrillo Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277459850,
        AWATER: 196245929,
        INTPTLAT: '+37.4377371',
        INTPTLON: '-122.4368377',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4723, 37.3115],
            [-122.3385, 37.3124],
            [-122.2873, 37.3596],
            [-122.1899, 37.3203],
            [-122.1623, 37.3044],
            [-122.1523, 37.2861],
            [-122.1515, 37.23],
            [-122.2427, 37.19],
            [-122.3139, 37.0548],
            [-122.3761, 37.0656],
            [-122.4641, 37.1778],
            [-122.4723, 37.3115],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620220',
        NAME: 'La Honda-Pescadero Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352397841,
        AWATER: 195032331,
        INTPTLAT: '+37.2256503',
        INTPTLON: '-122.2975334',
        ELSDLEA: '',
        UNSDLEA: '20220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.188, 36.2184],
            [-119.1364, 36.2462],
            [-118.9926, 36.2255],
            [-119.0859, 36.1672],
            [-119.1879, 36.1962],
            [-119.188, 36.2184],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621870',
        NAME: 'Lindsay Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111456438,
        AWATER: 0,
        INTPTLAT: '+36.2082058',
        INTPTLON: '-119.0918315',
        ELSDLEA: '',
        UNSDLEA: '21870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9039, 37.625],
            [-120.8017, 37.6188],
            [-120.7827, 37.6251],
            [-120.783, 37.5875],
            [-120.8104, 37.5585],
            [-120.8302, 37.5366],
            [-120.8652, 37.5367],
            [-120.9018, 37.5659],
            [-120.9039, 37.625],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600060',
        NAME: 'Hughson Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76028724,
        AWATER: 364105,
        INTPTLAT: '+37.5873715',
        INTPTLON: '-120.8494950',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9234, 37.8013],
            [-120.8008, 37.8394],
            [-120.7049, 37.8405],
            [-120.7019, 37.7557],
            [-120.611, 37.7012],
            [-120.6473, 37.6855],
            [-120.8117, 37.6821],
            [-120.8302, 37.6822],
            [-120.9043, 37.711],
            [-120.9229, 37.7588],
            [-120.9234, 37.8013],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600062',
        NAME: 'Oakdale Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310033632,
        AWATER: 2224875,
        INTPTLAT: '+37.7557510',
        INTPTLON: '-120.7764782',
        ELSDLEA: '',
        UNSDLEA: '00062',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9036, 37.5217],
            [-120.8652, 37.5367],
            [-120.8302, 37.5366],
            [-120.7483, 37.4926],
            [-120.7488, 37.4557],
            [-120.8128, 37.4403],
            [-120.8579, 37.4508],
            [-120.9036, 37.5217],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600158',
        NAME: 'Turlock Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89499624,
        AWATER: 0,
        INTPTLAT: '+37.4886656',
        INTPTLON: '-120.8279574',
        ELSDLEA: '',
        UNSDLEA: '00158',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6831, 42.0004],
            [-121.1112, 41.9955],
            [-121.106, 41.5175],
            [-121.4488, 41.5167],
            [-121.449, 41.7764],
            [-121.5688, 41.7764],
            [-121.5688, 41.8625],
            [-121.6848, 41.8627],
            [-121.6831, 42.0004],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639940',
        NAME: 'Tulelake Basin Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1763458252,
        AWATER: 151324683,
        INTPTLAT: '+41.7673009',
        INTPTLON: '-121.3388364',
        ELSDLEA: '',
        UNSDLEA: '39940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6548, 36.1413],
            [-121.576, 36.1415],
            [-121.4372, 36.0153],
            [-121.2438, 35.7945],
            [-121.3478, 35.7952],
            [-121.4623, 35.8856],
            [-121.5031, 36.0003],
            [-121.5735, 36.0236],
            [-121.6548, 36.1413],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600031',
        NAME: 'Pacific Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337103685,
        AWATER: 1816,
        INTPTLAT: '+35.9593517',
        INTPTLON: '-121.4613891',
        ELSDLEA: '',
        UNSDLEA: '00031',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9639, 36.5895],
            [-121.9021, 36.6185],
            [-121.8086, 36.7075],
            [-121.7483, 36.6986],
            [-121.7119, 36.6304],
            [-121.7774, 36.5517],
            [-121.9639, 36.5895],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625530',
        NAME: 'Monterey Peninsula Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168427250,
        AWATER: 740934,
        INTPTLAT: '+36.6137249',
        INTPTLON: '-121.8095929',
        ELSDLEA: '',
        UNSDLEA: '25530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4979, 36.4136],
            [-121.3877, 36.3425],
            [-121.3265, 36.4125],
            [-121.4298, 36.4538],
            [-121.2932, 36.5061],
            [-121.2393, 36.5048],
            [-121.1435, 36.4062],
            [-121.2486, 36.3796],
            [-121.3266, 36.3129],
            [-121.5011, 36.3137],
            [-121.4979, 36.4136],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637050',
        NAME: 'Soledad Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313275981,
        AWATER: 4549329,
        INTPTLAT: '+36.3860770',
        INTPTLON: '-121.3240272',
        ELSDLEA: '',
        UNSDLEA: '37050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9639, 36.5895],
            [-121.7774, 36.5517],
            [-121.6459, 36.5065],
            [-121.5679, 36.4749],
            [-121.5001, 36.4158],
            [-121.4979, 36.4136],
            [-121.5011, 36.3137],
            [-121.5758, 36.3142],
            [-121.576, 36.1415],
            [-121.6548, 36.1413],
            [-121.8356, 36.2493],
            [-121.8929, 36.3404],
            [-121.9639, 36.5895],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607530',
        NAME: 'Carmel Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1199083420,
        AWATER: 2853795,
        INTPTLAT: '+36.3244543',
        INTPTLON: '-121.6657814',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2767, 41.6361],
            [-123.2749, 41.696],
            [-123.1603, 41.6961],
            [-123.1007, 41.6717],
            [-122.9093, 41.6963],
            [-122.9169, 41.7835],
            [-122.7929, 41.782],
            [-122.6521, 41.6103],
            [-122.6522, 41.5768],
            [-122.6518, 41.4387],
            [-122.5976, 41.4394],
            [-122.5868, 41.3492],
            [-122.8113, 41.2025],
            [-122.9454, 41.1814],
            [-122.9469, 41.3567],
            [-123.0432, 41.3594],
            [-123.0387, 41.4912],
            [-123.2631, 41.4904],
            [-123.2767, 41.6361],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600159',
        NAME: 'Scott Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2135308438,
        AWATER: 7445715,
        INTPTLAT: '+41.5104187',
        INTPTLON: '-122.8970368',
        ELSDLEA: '',
        UNSDLEA: '00159',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0224, 39.0937],
            [-121.8942, 39.0904],
            [-121.8191, 38.9886],
            [-121.8172, 38.882],
            [-121.9715, 38.8379],
            [-122.093, 38.9257],
            [-122.335, 38.9239],
            [-122.3385, 39.0679],
            [-122.079, 39.0706],
            [-122.0224, 39.0937],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630360',
        NAME: 'Pierce Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 896618842,
        AWATER: 2126127,
        INTPTLAT: '+38.9817474',
        INTPTLON: '-122.0476021',
        ELSDLEA: '',
        UNSDLEA: '30360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.279, 34.0046],
            [-117.1742, 33.9826],
            [-117.1212, 33.9329],
            [-117.1211, 33.9031],
            [-117.1389, 33.859],
            [-117.2876, 33.8855],
            [-117.279, 34.0046],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625800',
        NAME: 'Moreno Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195067594,
        AWATER: 3144953,
        INTPTLAT: '+33.9336735',
        INTPTLON: '-117.2156584',
        ELSDLEA: '',
        UNSDLEA: '25800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9021, 36.6185],
            [-121.9639, 36.5895],
            [-121.9297, 36.637],
            [-121.9021, 36.6185],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629370',
        NAME: 'Pacific Grove Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13589609,
        AWATER: 95091,
        INTPTLAT: '+36.6108231',
        INTPTLON: '-121.9328305',
        ELSDLEA: '',
        UNSDLEA: '29370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5312, 36.533],
            [-121.4164, 36.5739],
            [-121.3636, 36.6456],
            [-121.3633, 36.6547],
            [-121.3314, 36.6204],
            [-121.3252, 36.577],
            [-121.2932, 36.5061],
            [-121.4298, 36.4538],
            [-121.5001, 36.4158],
            [-121.5679, 36.4749],
            [-121.5312, 36.533],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600046',
        NAME: 'Gonzales Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262177282,
        AWATER: 6066006,
        INTPTLAT: '+36.5150702',
        INTPTLON: '-121.4207484',
        ELSDLEA: '',
        UNSDLEA: '00046',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1664, 37.9422],
            [-120.9721, 37.9736],
            [-120.9265, 38.0172],
            [-120.9234, 37.8013],
            [-120.9229, 37.7588],
            [-120.9588, 37.7415],
            [-120.9818, 37.7563],
            [-121.014, 37.7565],
            [-121.1555, 37.8702],
            [-121.1839, 37.9054],
            [-121.1664, 37.9422],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612840',
        NAME: 'Escalon Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413451813,
        AWATER: 3566432,
        INTPTLAT: '+37.8855347',
        INTPTLON: '-121.0241489',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5729, 38.0742],
            [-121.5086, 37.9995],
            [-121.4215, 37.9954],
            [-121.3707, 37.9681],
            [-121.3187, 37.9204],
            [-121.3282, 37.8079],
            [-121.3988, 37.8037],
            [-121.3981, 37.7106],
            [-121.4791, 37.7071],
            [-121.5551, 37.8174],
            [-121.5656, 37.9182],
            [-121.5798, 38.0064],
            [-121.5729, 38.0742],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600047',
        NAME: 'Tracy Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504938791,
        AWATER: 27600752,
        INTPTLAT: '+37.8910619',
        INTPTLON: '-121.4707749',
        ELSDLEA: '',
        UNSDLEA: '00047',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3624, 33.8435],
            [-117.2876, 33.8855],
            [-117.1389, 33.859],
            [-117.1912, 33.8084],
            [-117.3626, 33.8],
            [-117.3624, 33.8435],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '691135',
        NAME: 'Val Verde Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145293777,
        AWATER: 5424226,
        INTPTLAT: '+33.8431387',
        INTPTLON: '-117.2588589',
        ELSDLEA: '',
        UNSDLEA: '91135',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.106, 33.6126],
            [-117.1012, 33.6163],
            [-116.9629, 33.6311],
            [-116.945, 33.4276],
            [-117.0946, 33.4305],
            [-117.1453, 33.4326],
            [-117.1929, 33.5094],
            [-117.106, 33.6126],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600028',
        NAME: 'Temecula Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381210483,
        AWATER: 4327114,
        INTPTLAT: '+33.5199165',
        INTPTLON: '-117.0509727',
        ELSDLEA: '',
        UNSDLEA: '00028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1365, 33.6996],
            [-117.0849, 33.7573],
            [-117.0509, 33.7875],
            [-116.8599, 33.7581],
            [-116.8599, 33.8161],
            [-116.7714, 33.7726],
            [-116.6675, 33.7734],
            [-116.6144, 33.7297],
            [-116.5125, 33.6853],
            [-116.5184, 33.4269],
            [-116.8571, 33.4266],
            [-116.945, 33.4276],
            [-116.9629, 33.6311],
            [-117.1012, 33.6163],
            [-117.1365, 33.6996],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616920',
        NAME: 'Hemet Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1696149344,
        AWATER: 2710337,
        INTPTLAT: '+33.6055744',
        INTPTLON: '-116.7818648',
        ELSDLEA: '',
        UNSDLEA: '16920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4536, 33.9673],
            [-117.3682, 34.0195],
            [-117.279, 34.0046],
            [-117.2876, 33.8855],
            [-117.3624, 33.8435],
            [-117.4497, 33.8436],
            [-117.4536, 33.9673],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633150',
        NAME: 'Riverside Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231339720,
        AWATER: 6322056,
        INTPTLAT: '+33.9233125',
        INTPTLON: '-117.3778383',
        ELSDLEA: '',
        UNSDLEA: '33150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1086, 33.8877],
            [-118.0114, 33.8809],
            [-118.0461, 33.8536],
            [-118.0504, 33.85],
            [-118.0632, 33.8196],
            [-118.0718, 33.8154],
            [-118.1017, 33.8535],
            [-118.1086, 33.8877],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601620',
        NAME: 'ABC Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38772821,
        AWATER: 556042,
        INTPTLAT: '+33.8637711',
        INTPTLON: '-118.0665456',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9984, 34.0743],
            [-117.9809, 34.1091],
            [-117.9426, 34.1322],
            [-117.943, 34.1071],
            [-117.943, 34.0925],
            [-117.9662, 34.0628],
            [-118.0026, 34.0606],
            [-117.9984, 34.0743],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603690',
        NAME: 'Baldwin Park Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22081432,
        AWATER: 626574,
        INTPTLAT: '+34.0896602',
        INTPTLON: '-117.9687223',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4311, 34.0915],
            [-118.4094, 34.0949],
            [-118.3907, 34.0721],
            [-118.4059, 34.0571],
            [-118.4311, 34.0915],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604830',
        NAME: 'Beverly Hills Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15521820,
        AWATER: 5889,
        INTPTLAT: '+34.0792300',
        INTPTLON: '-118.4024374',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2912, 34.2217],
            [-118.3081, 34.1612],
            [-118.363, 34.2068],
            [-118.2912, 34.2217],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606450',
        NAME: 'Burbank Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44888792,
        AWATER: 95448,
        INTPTLAT: '+34.1900793',
        INTPTLON: '-118.3264045',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2253, 33.93],
            [-118.1783, 33.9125],
            [-118.188, 33.8832],
            [-118.2, 33.8591],
            [-118.2253, 33.93],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609620',
        NAME: 'Compton Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46060552,
        AWATER: 547551,
        INTPTLAT: '+33.8918472',
        INTPTLON: '-118.2309201',
        ELSDLEA: '',
        UNSDLEA: '09620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1761, 33.932],
            [-118.1566, 33.954],
            [-118.1213, 33.973],
            [-118.0938, 33.9505],
            [-118.1088, 33.9277],
            [-118.1102, 33.9098],
            [-118.1429, 33.9025],
            [-118.1773, 33.9215],
            [-118.1761, 33.932],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611460',
        NAME: 'Downey Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35133521,
        AWATER: 594497,
        INTPTLAT: '+33.9361582',
        INTPTLON: '-118.1347032',
        ELSDLEA: '',
        UNSDLEA: '11460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.499, 33.9163],
            [-118.3961, 33.9309],
            [-118.3962, 33.9019],
            [-118.4758, 33.8882],
            [-118.499, 33.9163],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612210',
        NAME: 'El Segundo Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8840626,
        AWATER: 13678211,
        INTPTLAT: '+33.9103672',
        INTPTLON: '-118.4250794',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8101, 34.2232],
            [-117.8375, 34.1211],
            [-117.8726, 34.1178],
            [-117.8101, 34.2232],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615270',
        NAME: 'Glendora Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56926181,
        AWATER: 319862,
        INTPTLAT: '+34.1633150',
        INTPTLON: '-117.8430495',
        ELSDLEA: '',
        UNSDLEA: '15270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.204, 34.2671],
            [-118.1895, 34.3521],
            [-118.0285, 34.3812],
            [-117.8748, 34.3818],
            [-117.8747, 34.3385],
            [-117.979, 34.3381],
            [-117.9792, 34.267],
            [-118.0511, 34.2178],
            [-118.1869, 34.2515],
            [-118.1885, 34.1781],
            [-118.204, 34.2671],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620130',
        NAME: 'La Cañada Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361055610,
        AWATER: 414813,
        INTPTLAT: '+34.3465171',
        INTPTLON: '-118.0794160',
        ELSDLEA: '',
        UNSDLEA: '20130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0128, 34.2084],
            [-117.9774, 34.2083],
            [-118.0045, 34.1111],
            [-118.0184, 34.1091],
            [-118.0128, 34.2084],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625320',
        NAME: 'Monrovia Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33312562,
        AWATER: 25152,
        INTPTLAT: '+34.1664555',
        INTPTLON: '-118.0027852',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8269, 34.0641],
            [-117.827, 34.0642],
            [-117.7561, 34.0993],
            [-117.7111, 34.0795],
            [-117.7245, 34.0447],
            [-117.7851, 34.0048],
            [-117.8269, 34.0641],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631320',
        NAME: 'Pomona Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81196213,
        AWATER: 71790,
        INTPTLAT: '+34.0477384',
        INTPTLON: '-117.7805468',
        ELSDLEA: '',
        UNSDLEA: '31320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0816, 34.121],
            [-118.0736, 34.0905],
            [-118.0908, 34.0802],
            [-118.1083, 34.0771],
            [-118.1211, 34.1053],
            [-118.0816, 34.121],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634425',
        NAME: 'San Gabriel Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11895049,
        AWATER: 2872,
        INTPTLAT: '+34.1002074',
        INTPTLON: '-118.0951810',
        ELSDLEA: '',
        UNSDLEA: '34425',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8719, 34.0483],
            [-117.8269, 34.0641],
            [-117.7851, 34.0048],
            [-117.7833, 33.9464],
            [-117.8487, 33.9469],
            [-117.8719, 34.0483],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641280',
        NAME: 'Walnut Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56306133,
        AWATER: 82624,
        INTPTLAT: '+33.9993121',
        INTPTLON: '-117.8269853',
        ELSDLEA: '',
        UNSDLEA: '41280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3773, 34.5442],
            [-118.3759, 34.5586],
            [-118.1853, 34.5582],
            [-118.0797, 34.5244],
            [-118.0284, 34.4701],
            [-118.0285, 34.3812],
            [-118.1895, 34.3521],
            [-118.3244, 34.3522],
            [-118.3257, 34.4375],
            [-118.3773, 34.5442],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600001',
        NAME: 'Acton-Agua Dulce Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555175128,
        AWATER: 182565,
        INTPTLAT: '+34.4509057',
        INTPTLON: '-118.2276141',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4487, 36.5203],
            [-119.359, 36.5745],
            [-119.3679, 36.4582],
            [-119.3856, 36.4585],
            [-119.4219, 36.4728],
            [-119.4487, 36.5203],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600065',
        NAME: 'Dinuba Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87324375,
        AWATER: 0,
        INTPTLAT: '+36.5174472',
        INTPTLON: '-119.3926155',
        ELSDLEA: '',
        UNSDLEA: '00065',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1789, 36.305],
            [-119.1765, 36.2825],
            [-119.2251, 36.2832],
            [-119.1789, 36.305],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600035',
        NAME: 'Farmersville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15098983,
        AWATER: 0,
        INTPTLAT: '+36.3001188',
        INTPTLON: '-119.2015172',
        ELSDLEA: '',
        UNSDLEA: '00035',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2809, 37.7072],
            [-122.2818, 37.7082],
            [-122.341, 37.8018],
            [-122.2242, 37.7628],
            [-122.2809, 37.7072],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601770',
        NAME: 'Alameda City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27045458,
        AWATER: 32778370,
        INTPTLAT: '+37.7419107',
        INTPTLON: '-122.2599139',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0215, 37.1278],
            [-121.984, 37.1356],
            [-121.9535, 37.0762],
            [-122.0055, 37.0421],
            [-122.0471, 37.0464],
            [-122.0215, 37.1278],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600043',
        NAME: 'Scotts Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60415424,
        AWATER: 0,
        INTPTLAT: '+37.0759341',
        INTPTLON: '-122.0053215',
        ELSDLEA: '',
        UNSDLEA: '00043',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4758, 33.8882],
            [-118.3962, 33.9019],
            [-118.3787, 33.8946],
            [-118.3914, 33.8728],
            [-118.4556, 33.8614],
            [-118.4758, 33.8882],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600025',
        NAME: 'Manhattan Beach Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10197388,
        AWATER: 16690926,
        INTPTLAT: '+33.8983436',
        INTPTLON: '-118.4195130',
        ELSDLEA: '',
        UNSDLEA: '00025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4453, 33.8431],
            [-118.3914, 33.8728],
            [-118.3787, 33.8946],
            [-118.3698, 33.8946],
            [-118.3523, 33.8742],
            [-118.3744, 33.8157],
            [-118.4718, 33.816],
            [-118.4453, 33.8431],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600032',
        NAME: 'Redondo Beach Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16055823,
        AWATER: 20241959,
        INTPTLAT: '+33.8495382',
        INTPTLON: '-118.4007550',
        ELSDLEA: '',
        UNSDLEA: '00032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8747, 34.3385],
            [-117.6505, 34.339],
            [-117.647, 34.2971],
            [-117.7496, 34.2966],
            [-117.7497, 34.2229],
            [-117.8101, 34.2232],
            [-117.8726, 34.1178],
            [-117.8725, 34.1139],
            [-117.943, 34.1071],
            [-117.9426, 34.1322],
            [-117.9779, 34.2235],
            [-117.9792, 34.267],
            [-117.979, 34.3381],
            [-117.8747, 34.3385],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603600',
        NAME: 'Azusa Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384853974,
        AWATER: 3695542,
        INTPTLAT: '+34.2803329',
        INTPTLON: '-117.8661408',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0026, 34.0606],
            [-117.9662, 34.0628],
            [-117.9614, 34.0509],
            [-117.9953, 34.0343],
            [-118.0098, 34.0407],
            [-118.0026, 34.0606],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604110',
        NAME: 'Bassett Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9001847,
        AWATER: 138277,
        INTPTLAT: '+34.0515882',
        INTPTLON: '-117.9863908',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.943, 34.1071],
            [-117.8725, 34.1139],
            [-117.8398, 34.0795],
            [-117.827, 34.0642],
            [-117.8269, 34.0641],
            [-117.8719, 34.0483],
            [-117.8803, 34.0456],
            [-117.943, 34.0925],
            [-117.943, 34.1071],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610050',
        NAME: 'Covina-Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37740830,
        AWATER: 97223,
        INTPTLAT: '+34.0800615',
        INTPTLON: '-117.8871057',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9774, 34.2083],
            [-117.9779, 34.2235],
            [-117.9426, 34.1322],
            [-117.9809, 34.1091],
            [-118.0045, 34.1111],
            [-117.9774, 34.2083],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611520',
        NAME: 'Duarte Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59156041,
        AWATER: 465080,
        INTPTLAT: '+34.1665157',
        INTPTLON: '-117.9562370',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9522, 36.6633],
            [-119.9073, 36.6632],
            [-119.8268, 36.714],
            [-119.7727, 36.6923],
            [-119.8041, 36.6633],
            [-119.8013, 36.6154],
            [-119.8089, 36.5762],
            [-119.863, 36.5759],
            [-119.9525, 36.619],
            [-119.9522, 36.6633],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601415',
        NAME: 'Washington Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 111583363,
        AWATER: 0,
        INTPTLAT: '+36.6407149',
        INTPTLON: '-119.8570492',
        ELSDLEA: '',
        UNSDLEA: '01415',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7286, 34.4802],
            [-119.6703, 34.4658],
            [-119.6532, 34.4222],
            [-119.66, 34.3595],
            [-119.7557, 34.3493],
            [-119.7286, 34.4802],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601414',
        NAME: 'Santa Barbara Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59658765,
        AWATER: 59598656,
        INTPTLAT: '+34.4059550',
        INTPTLON: '-119.7049038',
        ELSDLEA: '',
        UNSDLEA: '01414',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8192, 37.1695],
            [-119.6455, 37.1863],
            [-119.6549, 37.2397],
            [-119.5091, 37.2852],
            [-119.4624, 37.4002],
            [-119.4645, 37.626],
            [-119.5037, 37.6239],
            [-119.309, 37.778],
            [-119.269, 37.7392],
            [-119.1237, 37.7333],
            [-119.0224, 37.5857],
            [-119.3122, 37.3527],
            [-119.3392, 37.1947],
            [-119.4068, 37.16],
            [-119.5594, 37.1436],
            [-119.563, 37.0641],
            [-119.6755, 37.0092],
            [-119.736, 36.9525],
            [-119.8378, 37.0104],
            [-119.8192, 37.1695],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600116',
        NAME: 'Chawanakee Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2183836078,
        AWATER: 19545762,
        INTPTLAT: '+37.3963734',
        INTPTLON: '-119.4022515',
        ELSDLEA: '',
        UNSDLEA: '00116',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.083, 34.0206],
            [-118.0542, 34.0207],
            [-118.0819, 33.9774],
            [-118.0892, 33.9571],
            [-118.0938, 33.9505],
            [-118.1213, 33.973],
            [-118.083, 34.0206],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '612180',
        NAME: 'El Rancho Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19514217,
        AWATER: 1104667,
        INTPTLAT: '+33.9875321',
        INTPTLON: '-118.0920535',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-118.2, 33.8591],
              [-118.188, 33.8832],
              [-118.1599, 33.8602],
              [-118.1424, 33.8548],
              [-118.1017, 33.8535],
              [-118.0718, 33.8154],
              [-118.1238, 33.7104],
              [-118.1259, 33.6972],
              [-118.2268, 33.6906],
              [-118.249, 33.7559],
              [-118.2, 33.8591],
            ],
          ],
          [
            [
              [-118.6682, 33.4859],
              [-118.6122, 33.5318],
              [-118.5232, 33.5302],
              [-118.3594, 33.4649],
              [-118.2627, 33.3704],
              [-118.2592, 33.2725],
              [-118.328, 33.2481],
              [-118.518, 33.2966],
              [-118.5534, 33.3802],
              [-118.6682, 33.4859],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622500',
        NAME: 'Long Beach Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340717590,
        AWATER: 645380852,
        INTPTLAT: '+33.3673364',
        INTPTLON: '-118.4807090',
        ELSDLEA: '',
        UNSDLEA: '22500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1463, 34.0375],
            [-118.1167, 34.043],
            [-118.075, 34.0407],
            [-118.083, 34.0206],
            [-118.1213, 33.973],
            [-118.1566, 33.954],
            [-118.1463, 34.0375],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625470',
        NAME: 'Montebello Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55981385,
        AWATER: 348267,
        INTPTLAT: '+34.0065446',
        INTPTLON: '-118.1295262',
        ELSDLEA: '',
        UNSDLEA: '25470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4832, 33.8044],
            [-118.3297, 33.7852],
            [-118.352, 33.6666],
            [-118.467, 33.7255],
            [-118.4832, 33.8044],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629700',
        NAME: 'Palos Verdes Peninsula Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63144873,
        AWATER: 105255483,
        INTPTLAT: '+33.7540721',
        INTPTLON: '-118.4009754',
        ELSDLEA: '',
        UNSDLEA: '29700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1783, 33.9125],
            [-118.1773, 33.9215],
            [-118.1429, 33.9025],
            [-118.1424, 33.8548],
            [-118.1599, 33.8602],
            [-118.188, 33.8832],
            [-118.1783, 33.9125],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629850',
        NAME: 'Paramount Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17414421,
        AWATER: 121916,
        INTPTLAT: '+33.8920153',
        INTPTLON: '-118.1610977',
        ELSDLEA: '',
        UNSDLEA: '29850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1422, 34.1196],
            [-118.0816, 34.121],
            [-118.1211, 34.1053],
            [-118.1353, 34.1064],
            [-118.1422, 34.1196],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634860',
        NAME: 'San Marino Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10777900,
        AWATER: 29371,
        INTPTLAT: '+34.1231978',
        INTPTLON: '-118.1102601',
        ELSDLEA: '',
        UNSDLEA: '34860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1681, 34.1239],
            [-118.1422, 34.1196],
            [-118.1353, 34.1064],
            [-118.1553, 34.0987],
            [-118.1681, 34.1239],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637500',
        NAME: 'South Pasadena Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8837142,
        AWATER: 31319,
        INTPTLAT: '+34.1089567',
        INTPTLON: '-118.1566154',
        ELSDLEA: '',
        UNSDLEA: '37500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9953, 34.0343],
            [-117.9614, 34.0509],
            [-117.9215, 34.0418],
            [-117.9344, 33.9729],
            [-117.9767, 33.9748],
            [-118.003, 33.9893],
            [-117.9953, 34.0343],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616325',
        NAME: 'Hacienda La Puente Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54704683,
        AWATER: 250513,
        INTPTLAT: '+34.0132250',
        INTPTLON: '-117.9643883',
        ELSDLEA: '',
        UNSDLEA: '16325',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1885, 34.1781],
            [-118.1869, 34.2515],
            [-118.0511, 34.2178],
            [-117.9792, 34.267],
            [-117.9779, 34.2235],
            [-117.9774, 34.2083],
            [-118.0128, 34.2084],
            [-118.0675, 34.1308],
            [-118.0816, 34.121],
            [-118.1422, 34.1196],
            [-118.1681, 34.1239],
            [-118.1839, 34.1491],
            [-118.1885, 34.1781],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629940',
        NAME: 'Pasadena Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191524701,
        AWATER: 671619,
        INTPTLAT: '+34.1962457',
        INTPTLON: '-118.0945899',
        ELSDLEA: '',
        UNSDLEA: '29940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1212, 40.4196],
            [-124.0627, 40.4196],
            [-123.9689, 40.3572],
            [-123.7968, 40.3786],
            [-123.6629, 40.3786],
            [-123.5445, 40.3412],
            [-123.5445, 40.0019],
            [-123.6713, 40.0018],
            [-123.7632, 40.0021],
            [-123.7974, 39.8864],
            [-123.9115, 39.8863],
            [-123.9112, 39.9687],
            [-124.0232, 40.0013],
            [-124.1223, 40.1087],
            [-124.0467, 40.1657],
            [-124.0655, 40.333],
            [-124.1212, 40.4196],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637590',
        NAME: 'Southern Humboldt Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2013996023,
        AWATER: 3354904,
        INTPTLAT: '+40.1677160',
        INTPTLON: '-123.8260926',
        ELSDLEA: '',
        UNSDLEA: '37590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2187, 37.6317],
            [-122.0962, 37.6881],
            [-122.014, 37.6317],
            [-122.18, 37.5845],
            [-122.1868, 37.5929],
            [-122.2087, 37.6195],
            [-122.2187, 37.6317],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '616740',
        NAME: 'Hayward Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83902803,
        AWATER: 20824381,
        INTPTLAT: '+37.6379509',
        INTPTLON: '-122.1010098',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8707, 37.7401],
            [-121.8324, 37.846],
            [-121.8321, 37.8544],
            [-121.765, 37.8366],
            [-121.7496, 37.7695],
            [-121.6203, 37.8003],
            [-121.6025, 37.6705],
            [-121.557, 37.6706],
            [-121.5567, 37.5427],
            [-121.4719, 37.4818],
            [-121.4726, 37.4822],
            [-121.7126, 37.4836],
            [-121.6941, 37.5727],
            [-121.804, 37.6142],
            [-121.8484, 37.7013],
            [-121.8707, 37.7401],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622110',
        NAME: 'Livermore Valley Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 681380135,
        AWATER: 4459695,
        INTPTLAT: '+37.6396841',
        INTPTLON: '-121.6633573',
        ELSDLEA: '',
        UNSDLEA: '22110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7179, 35.1092],
            [-120.6762, 35.1667],
            [-120.5715, 35.2005],
            [-120.5952, 35.3198],
            [-120.4723, 35.2608],
            [-120.2578, 35.2609],
            [-120.202, 35.1788],
            [-120.095, 35.1756],
            [-120.1015, 35.1135],
            [-120.1881, 35.0312],
            [-120.3099, 35.0092],
            [-120.3553, 34.9413],
            [-120.4355, 34.9868],
            [-120.5427, 34.9804],
            [-120.6341, 34.9596],
            [-120.7028, 35],
            [-120.7179, 35.1092],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623080',
        NAME: 'Lucia Mar Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1255033913,
        AWATER: 115647757,
        INTPTLAT: '+35.1037771',
        INTPTLON: '-120.4297038',
        ELSDLEA: '',
        UNSDLEA: '23080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9276, 35.3334],
            [-120.8376, 35.4644],
            [-120.8018, 35.4644],
            [-120.5952, 35.3198],
            [-120.5715, 35.2005],
            [-120.6762, 35.1667],
            [-120.7179, 35.1092],
            [-120.8004, 35.1166],
            [-120.9602, 35.2436],
            [-120.9276, 35.3334],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634800',
        NAME: 'San Luis Coastal Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 690121794,
        AWATER: 250259898,
        INTPTLAT: '+35.2611660',
        INTPTLON: '-120.7796068',
        ELSDLEA: '',
        UNSDLEA: '34800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4639, 35.9491],
            [-119.3923, 35.9057],
            [-119.3922, 35.8771],
            [-119.4278, 35.7901],
            [-119.5381, 35.7896],
            [-119.5362, 35.949],
            [-119.4639, 35.9491],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602010',
        NAME: 'Alpaugh Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181516633,
        AWATER: 0,
        INTPTLAT: '+35.8657227',
        INTPTLON: '-119.4783897',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.89, 39.3839],
            [-121.8565, 39.5326],
            [-121.6887, 39.5378],
            [-121.63, 39.4562],
            [-121.6174, 39.4054],
            [-121.8365, 39.391],
            [-121.8927, 39.3619],
            [-121.89, 39.3839],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605040',
        NAME: 'Biggs Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 330956418,
        AWATER: 16526415,
        INTPTLAT: '+39.4575816',
        INTPTLON: '-121.7598539',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9835, 39.6233],
            [-121.9692, 39.657],
            [-121.6911, 39.6848],
            [-121.595, 39.7141],
            [-121.6345, 39.5752],
            [-121.6887, 39.5378],
            [-121.8565, 39.5326],
            [-122.0031, 39.5577],
            [-121.9835, 39.6233],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611730',
        NAME: 'Durham Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465744976,
        AWATER: 4469892,
        INTPTLAT: '+39.6047133',
        INTPTLON: '-121.7952880',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8043, 36.8383],
            [-121.69, 36.8508],
            [-121.6231, 36.8154],
            [-121.6462, 36.7616],
            [-121.729, 36.7385],
            [-121.7349, 36.6984],
            [-121.7483, 36.6986],
            [-121.8086, 36.7075],
            [-121.8043, 36.8383],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627590',
        NAME: 'North Monterey County Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178800094,
        AWATER: 6034004,
        INTPTLAT: '+36.7798198',
        INTPTLON: '-121.7276731',
        ELSDLEA: '',
        UNSDLEA: '27590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5826, 36.0026],
            [-120.4778, 36.0033],
            [-120.3623, 35.9652],
            [-120.3151, 35.9072],
            [-120.1941, 35.7892],
            [-120.1939, 35.6144],
            [-120.0859, 35.6145],
            [-120.0867, 35.5266],
            [-119.9974, 35.4395],
            [-120.3551, 35.4319],
            [-120.3547, 35.5276],
            [-120.4803, 35.5278],
            [-120.4981, 35.593],
            [-120.4263, 35.6591],
            [-120.4256, 35.79],
            [-120.4558, 35.8778],
            [-120.5825, 35.8771],
            [-120.5826, 35.9458],
            [-120.5826, 36.0026],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636450',
        NAME: 'Shandon Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1551682677,
        AWATER: 3528290,
        INTPTLAT: '+35.6655503',
        INTPTLON: '-120.3346851',
        ELSDLEA: '',
        UNSDLEA: '36450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9844, 37.3994],
            [-120.8579, 37.4508],
            [-120.8128, 37.4403],
            [-120.814, 37.3828],
            [-120.706, 37.3028],
            [-120.7017, 37.3026],
            [-120.7019, 37.2489],
            [-120.8185, 37.2489],
            [-120.9728, 37.3386],
            [-120.9844, 37.3994],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '617220',
        NAME: 'Hilmar Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279912884,
        AWATER: 3296560,
        INTPTLAT: '+37.3456559',
        INTPTLON: '-120.8635433',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2301, 37.1008],
            [-120.9982, 37.0756],
            [-120.9672, 37.1878],
            [-120.8149, 37.236],
            [-120.7648, 37.1861],
            [-120.6008, 37.1715],
            [-120.5775, 37.098],
            [-120.7075, 37.0979],
            [-120.707, 36.9118],
            [-120.9187, 36.7404],
            [-120.9311, 36.75],
            [-121.1415, 36.8367],
            [-121.2197, 36.9115],
            [-121.2154, 36.9612],
            [-121.2301, 37.1008],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622740',
        NAME: 'Los Banos Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1698257110,
        AWATER: 79096781,
        INTPTLAT: '+37.0136081',
        INTPTLON: '-120.9465874',
        ELSDLEA: '',
        UNSDLEA: '22740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0275, 38.5083],
            [-120.8912, 38.5283],
            [-120.8124, 38.5621],
            [-120.6284, 38.5032],
            [-120.5652, 38.5081],
            [-120.2745, 38.5595],
            [-120.2326, 38.5935],
            [-120.153, 38.6372],
            [-120.1221, 38.6686],
            [-120.0724, 38.7028],
            [-120.0725, 38.5099],
            [-120.2644, 38.4827],
            [-120.5046, 38.4394],
            [-120.6091, 38.3967],
            [-120.6466, 38.3346],
            [-120.8468, 38.2643],
            [-120.8929, 38.2216],
            [-120.9955, 38.2254],
            [-121.0064, 38.2505],
            [-121.0271, 38.3003],
            [-121.0272, 38.413],
            [-121.0275, 38.5083],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602450',
        NAME: 'Amador County Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1539947599,
        AWATER: 29470568,
        INTPTLAT: '+38.4435501',
        INTPTLON: '-120.6538563',
        ELSDLEA: '',
        UNSDLEA: '02450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9552, 34.3431],
            [-118.7988, 34.3522],
            [-118.8291, 34.2653],
            [-118.8656, 34.259],
            [-118.9367, 34.2485],
            [-118.9552, 34.3431],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625690',
        NAME: 'Moorpark Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122980783,
        AWATER: 772263,
        INTPTLAT: '+34.3109401',
        INTPTLON: '-118.8760667',
        ELSDLEA: '',
        UNSDLEA: '25690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4424, 34.5611],
            [-119.1546, 34.5614],
            [-119.079, 34.5617],
            [-119.096, 34.4208],
            [-119.1361, 34.4126],
            [-119.1649, 34.4076],
            [-119.2659, 34.3914],
            [-119.3241, 34.4638],
            [-119.4423, 34.4639],
            [-119.4423, 34.4655],
            [-119.4424, 34.5611],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628270',
        NAME: 'Ojai Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490384042,
        AWATER: 463723,
        INTPTLAT: '+34.4937456',
        INTPTLON: '-119.2038818',
        ELSDLEA: '',
        UNSDLEA: '28270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.978, 37.7296],
            [-121.8707, 37.7401],
            [-121.8484, 37.7013],
            [-121.9777, 37.6999],
            [-121.978, 37.7296],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600019',
        NAME: 'Dublin Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35430345,
        AWATER: 0,
        INTPTLAT: '+37.7153750',
        INTPTLON: '-121.9105019',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9777, 37.6999],
            [-121.8484, 37.7013],
            [-121.804, 37.6142],
            [-121.9276, 37.6219],
            [-121.9777, 37.6999],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600020',
        NAME: 'Pleasanton Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102462451,
        AWATER: 392970,
        INTPTLAT: '+37.6649739',
        INTPTLON: '-121.8867095',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9328, 37.5917],
            [-121.9276, 37.6219],
            [-121.804, 37.6142],
            [-121.6941, 37.5727],
            [-121.7126, 37.4836],
            [-121.8678, 37.483],
            [-121.9328, 37.5917],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600021',
        NAME: 'Sunol Glen Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244315312,
        AWATER: 2809642,
        INTPTLAT: '+37.5511338',
        INTPTLON: '-121.8054596',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9955, 38.2254],
            [-120.8929, 38.2216],
            [-120.8468, 38.2643],
            [-120.6466, 38.3346],
            [-120.6091, 38.3967],
            [-120.5046, 38.4394],
            [-120.2644, 38.4827],
            [-120.2636, 38.3177],
            [-120.3959, 38.2934],
            [-120.3982, 38.1903],
            [-120.4974, 38.1466],
            [-120.5692, 38.1067],
            [-120.7907, 38.0886],
            [-120.7932, 37.9586],
            [-120.9264, 38.0774],
            [-120.9692, 38.163],
            [-120.9955, 38.2254],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '606870',
        NAME: 'Calaveras Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1540244136,
        AWATER: 30349378,
        INTPTLAT: '+38.2496213',
        INTPTLON: '-120.6205768',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0284, 37.4495],
            [-120.9844, 37.3994],
            [-120.9728, 37.3386],
            [-121.2268, 37.1348],
            [-121.3083, 37.168],
            [-121.3741, 37.173],
            [-121.4541, 37.274],
            [-121.4125, 37.3894],
            [-121.3155, 37.4353],
            [-121.0284, 37.4495],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627200',
        NAME: 'Newman-Crows Landing Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 961382847,
        AWATER: 1254347,
        INTPTLAT: '+37.3259547',
        INTPTLON: '-121.1933829',
        ELSDLEA: '',
        UNSDLEA: '27200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.925, 37.4542],
            [-121.8678, 37.483],
            [-121.7126, 37.4836],
            [-121.4726, 37.4822],
            [-121.4763, 37.4803],
            [-121.4812, 37.4774],
            [-121.4843, 37.4755],
            [-121.4862, 37.4741],
            [-121.4732, 37.4573],
            [-121.4721, 37.4563],
            [-121.5967, 37.444],
            [-121.7018, 37.3755],
            [-121.7691, 37.4079],
            [-121.8924, 37.4034],
            [-121.9152, 37.3961],
            [-121.925, 37.4542],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624500',
        NAME: 'Milpitas Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283452224,
        AWATER: 3832321,
        INTPTLAT: '+37.4364438',
        INTPTLON: '-121.7156359',
        ELSDLEA: '',
        UNSDLEA: '24500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4721, 37.4563],
            [-121.4732, 37.4573],
            [-121.4862, 37.4741],
            [-121.4843, 37.4755],
            [-121.4812, 37.4774],
            [-121.4763, 37.4803],
            [-121.4726, 37.4822],
            [-121.4719, 37.4818],
            [-121.3609, 37.5698],
            [-121.2439, 37.6619],
            [-121.2305, 37.6411],
            [-121.1759, 37.606],
            [-121.0882, 37.5086],
            [-121.0284, 37.4495],
            [-121.3155, 37.4353],
            [-121.4125, 37.3894],
            [-121.4541, 37.274],
            [-121.5867, 37.2741],
            [-121.5878, 37.3388],
            [-121.7018, 37.3755],
            [-121.5967, 37.444],
            [-121.4721, 37.4563],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630030',
        NAME: 'Patterson Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 931410421,
        AWATER: 1406703,
        INTPTLAT: '+37.4680012',
        INTPTLON: '-121.3907068',
        ELSDLEA: '',
        UNSDLEA: '30030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2869, 37.8987],
            [-122.3678, 37.8667],
            [-122.3738, 37.8837],
            [-122.2869, 37.8987],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601860',
        NAME: 'Albany City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4634305,
        AWATER: 9523833,
        INTPTLAT: '+37.8906499',
        INTPTLON: '-122.3181164',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3678, 37.8667],
            [-122.2869, 37.8987],
            [-122.2563, 37.897],
            [-122.2469, 37.8854],
            [-122.2887, 37.85],
            [-122.3337, 37.8404],
            [-122.3559, 37.8357],
            [-122.3678, 37.8667],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604740',
        NAME: 'Berkeley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27095285,
        AWATER: 18715614,
        INTPTLAT: '+37.8669717',
        INTPTLON: '-122.2991474',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9156, 33.9174],
            [-117.9156, 33.947],
            [-117.8487, 33.9469],
            [-117.7833, 33.9464],
            [-117.7656, 33.9338],
            [-117.8817, 33.907],
            [-117.9156, 33.9174],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605880',
        NAME: 'Brea-Olinda Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51346993,
        AWATER: 83691,
        INTPTLAT: '+33.9291134',
        INTPTLON: '-117.8466931',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8699, 33.5178],
            [-117.7945, 33.609],
            [-117.7482, 33.6196],
            [-117.744, 33.6136],
            [-117.7509, 33.419],
            [-117.8699, 33.5178],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620370',
        NAME: 'Laguna Beach Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60307298,
        AWATER: 81831883,
        INTPTLAT: '+33.5465080',
        INTPTLON: '-117.8013581',
        ELSDLEA: '',
        UNSDLEA: '20370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0718, 33.8154],
            [-118.0632, 33.8196],
            [-118.041, 33.7956],
            [-118.0425, 33.774],
            [-118.0419, 33.7395],
            [-118.0744, 33.7261],
            [-118.1238, 33.7104],
            [-118.0718, 33.8154],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622590',
        NAME: 'Los Alamitos Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42279382,
        AWATER: 7423782,
        INTPTLAT: '+33.7685265',
        INTPTLON: '-118.0705719',
        ELSDLEA: '',
        UNSDLEA: '22590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9451, 33.6724],
            [-117.9307, 33.7019],
            [-117.8645, 33.6553],
            [-117.7945, 33.609],
            [-117.8699, 33.5178],
            [-117.9891, 33.5858],
            [-117.9451, 33.6724],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627240',
        NAME: 'Newport-Mesa Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99167742,
        AWATER: 89507508,
        INTPTLAT: '+33.6076116',
        INTPTLON: '-117.8970048',
        ELSDLEA: '',
        UNSDLEA: '27240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8817, 33.907],
            [-117.7656, 33.9338],
            [-117.6737, 33.8708],
            [-117.6731, 33.8703],
            [-117.7404, 33.8802],
            [-117.8637, 33.8355],
            [-117.8961, 33.8564],
            [-117.8817, 33.907],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630660',
        NAME: 'Placentia-Yorba Linda Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101748922,
        AWATER: 2267786,
        INTPTLAT: '+33.8844743',
        INTPTLON: '-117.8009156',
        ELSDLEA: '',
        UNSDLEA: '30660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8472, 33.7672],
            [-117.7364, 33.7562],
            [-117.8415, 33.6903],
            [-117.8472, 33.7672],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640150',
        NAME: 'Tustin Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62293692,
        AWATER: 7205,
        INTPTLAT: '+33.7434589',
        INTPTLON: '-117.7986694',
        ELSDLEA: '',
        UNSDLEA: '40150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.3129, 40.6424],
            [-124.1959, 40.6134],
            [-124.1518, 40.5111],
            [-124.1961, 40.4196],
            [-124.4003, 40.4197],
            [-124.3129, 40.6424],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600037',
        NAME: 'Ferndale Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381280756,
        AWATER: 3223655,
        INTPTLAT: '+40.5230992',
        INTPTLON: '-124.3264209',
        ELSDLEA: '',
        UNSDLEA: '00037',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1698, 40.8369],
            [-124.1005, 40.837],
            [-124.1009, 40.8165],
            [-124.0836, 40.7655],
            [-124.0835, 40.7591],
            [-124.169, 40.7518],
            [-124.2194, 40.766],
            [-124.1698, 40.8369],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600052',
        NAME: 'Eureka City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34690302,
        AWATER: 23670962,
        INTPTLAT: '+40.7962052',
        INTPTLON: '-124.1495411',
        ELSDLEA: '',
        UNSDLEA: '00052',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.998, 41.2673],
            [-123.9976, 41.4643],
            [-123.7706, 41.4642],
            [-123.7702, 41.3808],
            [-123.6614, 41.3821],
            [-123.5012, 41.3826],
            [-123.474, 41.3264],
            [-123.4083, 41.1799],
            [-123.448, 40.9438],
            [-123.5346, 40.9308],
            [-123.5501, 40.764],
            [-123.6993, 40.7636],
            [-123.7163, 40.8972],
            [-123.7708, 40.9408],
            [-123.7988, 41.1208],
            [-123.9256, 41.1193],
            [-123.998, 41.1149],
            [-123.998, 41.2673],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619950',
        NAME: 'Klamath-Trinity Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2350370910,
        AWATER: 11356658,
        INTPTLAT: '+41.1483116',
        INTPTLON: '-123.6909175',
        ELSDLEA: '',
        UNSDLEA: '19950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.4003, 40.4197],
            [-124.1961, 40.4196],
            [-124.1212, 40.4196],
            [-124.0655, 40.333],
            [-124.0467, 40.1657],
            [-124.1223, 40.1087],
            [-124.3522, 40.2494],
            [-124.4003, 40.4197],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600038',
        NAME: 'Mattole Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 682444217,
        AWATER: 762617,
        INTPTLAT: '+40.2907612',
        INTPTLON: '-124.1692525',
        ELSDLEA: '',
        UNSDLEA: '00038',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9063, 33.7925],
            [-117.8637, 33.8355],
            [-117.7404, 33.8802],
            [-117.6731, 33.8703],
            [-117.535, 33.7275],
            [-117.4579, 33.6902],
            [-117.6642, 33.6981],
            [-117.7364, 33.7562],
            [-117.8472, 33.7672],
            [-117.8892, 33.7739],
            [-117.9063, 33.7925],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628650',
        NAME: 'Orange Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368914608,
        AWATER: 4801314,
        INTPTLAT: '+33.7828190',
        INTPTLON: '-117.6975419',
        ELSDLEA: '',
        UNSDLEA: '28650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.309, 37.778],
            [-119.5037, 37.6239],
            [-119.652, 37.4979],
            [-119.6512, 37.4178],
            [-119.78, 37.4026],
            [-120.0521, 37.1831],
            [-120.1843, 37.2727],
            [-120.2454, 37.3764],
            [-120.3877, 37.6334],
            [-120.3876, 37.6337],
            [-120.3454, 37.7248],
            [-120.0266, 37.8119],
            [-119.9384, 37.763],
            [-119.8055, 37.7596],
            [-119.6672, 37.8012],
            [-119.534, 37.9029],
            [-119.309, 37.778],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623940',
        NAME: 'Mariposa County Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3678870866,
        AWATER: 36236982,
        INTPTLAT: '+37.5700335',
        INTPTLON: '-119.9128599',
        ELSDLEA: '',
        UNSDLEA: '23940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.535, 33.7275],
            [-117.4147, 33.7712],
            [-117.2668, 33.7282],
            [-117.206, 33.6119],
            [-117.4609, 33.598],
            [-117.4579, 33.6902],
            [-117.535, 33.7275],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600027',
        NAME: 'Lake Elsinore Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334324051,
        AWATER: 16367352,
        INTPTLAT: '+33.6750734',
        INTPTLON: '-117.3462833',
        ELSDLEA: '',
        UNSDLEA: '00027',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5231, 33.9656],
            [-117.4536, 33.9673],
            [-117.4497, 33.8436],
            [-117.5018, 33.8268],
            [-117.5231, 33.9656],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602430',
        NAME: 'Alvord Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81040751,
        AWATER: 1151729,
        INTPTLAT: '+33.9026304',
        INTPTLON: '-117.4849460',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8368, 36.8537],
            [-119.736, 36.9525],
            [-119.6755, 37.0092],
            [-119.6739, 36.9451],
            [-119.4736, 36.9494],
            [-119.3993, 36.8702],
            [-119.5392, 36.8521],
            [-119.61, 36.7428],
            [-119.6821, 36.7369],
            [-119.7361, 36.8376],
            [-119.8368, 36.8537],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609030',
        NAME: 'Clovis Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492552534,
        AWATER: 6919633,
        INTPTLAT: '+36.8792832',
        INTPTLON: '-119.6243155',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4572, 36.9239],
            [-120.273, 36.9241],
            [-120.2775, 36.7687],
            [-120.3742, 36.7913],
            [-120.6029, 36.7784],
            [-120.5992, 36.9227],
            [-120.4572, 36.9239],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613840',
        NAME: 'Firebaugh-Las Deltas Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 437156163,
        AWATER: 1658877,
        INTPTLAT: '+36.8548478',
        INTPTLON: '-120.4361544',
        ELSDLEA: '',
        UNSDLEA: '13840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2736, 36.7742],
            [-120.0339, 36.8154],
            [-119.9522, 36.6633],
            [-119.9525, 36.619],
            [-120.0257, 36.6181],
            [-120.0249, 36.6621],
            [-120.2062, 36.6614],
            [-120.2047, 36.7492],
            [-120.278, 36.7488],
            [-120.2736, 36.7742],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619490',
        NAME: 'Kerman Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360161042,
        AWATER: 17365,
        INTPTLAT: '+36.7238132',
        INTPTLON: '-120.0919777',
        ELSDLEA: '',
        UNSDLEA: '19490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.278, 36.7488],
            [-120.2047, 36.7492],
            [-120.2062, 36.6614],
            [-120.0249, 36.6621],
            [-120.0257, 36.6181],
            [-120.0614, 36.5462],
            [-120.1032, 36.4733],
            [-120.2936, 36.4428],
            [-120.3933, 36.4001],
            [-120.4943, 36.4869],
            [-120.4953, 36.5747],
            [-120.2787, 36.6874],
            [-120.278, 36.7488],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '691134',
        NAME: 'Golden Plains Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 937011297,
        AWATER: 3128739,
        INTPTLAT: '+36.5557658',
        INTPTLON: '-120.2572484',
        ELSDLEA: '',
        UNSDLEA: '91134',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0195, 38.6647],
            [-122.8634, 38.6859],
            [-122.7799, 38.591],
            [-122.8564, 38.5766],
            [-123.0195, 38.6647],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600044',
        NAME: 'Healdsburg Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145770119,
        AWATER: 345713,
        INTPTLAT: '+38.6373850',
        INTPTLON: '-122.8884599',
        ELSDLEA: '',
        UNSDLEA: '00044',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0724, 38.7028],
            [-119.8777, 38.8683],
            [-119.9043, 38.9333],
            [-119.5854, 38.7132],
            [-119.6191, 38.6035],
            [-119.5461, 38.4993],
            [-119.562, 38.4107],
            [-119.6392, 38.3269],
            [-119.7535, 38.4168],
            [-119.8847, 38.3562],
            [-120.02, 38.4335],
            [-120.0725, 38.5099],
            [-120.0724, 38.7028],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602070',
        NAME: 'Alpine County Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1912243121,
        AWATER: 12557304,
        INTPTLAT: '+38.6217831',
        INTPTLON: '-119.7983522',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9295, 34.0226],
            [-116.9296, 34.0341],
            [-116.8279, 34.0338],
            [-116.6156, 34.0326],
            [-116.6144, 33.7297],
            [-116.6675, 33.7734],
            [-116.7714, 33.7726],
            [-116.8599, 33.8161],
            [-116.9289, 33.855],
            [-116.9295, 34.0226],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603840',
        NAME: 'Banning Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 785423062,
        AWATER: 5911315,
        INTPTLAT: '+33.9087907',
        INTPTLON: '-116.7610337',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.6688, 33.8566],
            [-115.6663, 34.0353],
            [-115.3162, 34.0341],
            [-115.3161, 34.0778],
            [-114.9274, 34.0797],
            [-114.9348, 33.4299],
            [-115.251, 33.4282],
            [-115.3428, 33.4281],
            [-115.5712, 33.4269],
            [-115.5716, 33.6855],
            [-115.6774, 33.6855],
            [-115.6688, 33.8566],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611100',
        NAME: 'Desert Center Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4496258211,
        AWATER: 33472,
        INTPTLAT: '+33.7678022',
        INTPTLON: '-115.2170882',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5583, 34.0335],
            [-117.5243, 34.0335],
            [-117.4712, 34.0337],
            [-117.3682, 34.0195],
            [-117.4536, 33.9673],
            [-117.5231, 33.9656],
            [-117.5584, 33.9884],
            [-117.5583, 34.0335],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '619260',
        NAME: 'Jurupa Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109339634,
        AWATER: 1453759,
        INTPTLAT: '+34.0022592',
        INTPTLON: '-117.4733310',
        ELSDLEA: '',
        UNSDLEA: '19260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.3288, 41.9983],
            [-123.8215, 41.9955],
            [-123.5179, 42.0009],
            [-123.5654, 41.9031],
            [-123.7037, 41.8295],
            [-123.6724, 41.7337],
            [-123.7192, 41.5956],
            [-123.6124, 41.449],
            [-123.6614, 41.3821],
            [-123.7702, 41.3808],
            [-123.7706, 41.4642],
            [-123.9976, 41.4643],
            [-124.0652, 41.4647],
            [-124.1366, 41.4645],
            [-124.2117, 41.6812],
            [-124.3306, 41.7338],
            [-124.4389, 41.8245],
            [-124.4113, 41.8821],
            [-124.285, 41.8595],
            [-124.3288, 41.9983],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610770',
        NAME: 'Del Norte County Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2606282680,
        AWATER: 578761025,
        INTPTLAT: '+41.7499033',
        INTPTLON: '-123.9809983',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1742, 33.9826],
            [-117.1731, 34.0042],
            [-117.1213, 34.0039],
            [-116.9808, 33.9904],
            [-116.9295, 34.0226],
            [-116.9289, 33.855],
            [-116.999, 33.918],
            [-117.1212, 33.9329],
            [-117.1742, 33.9826],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604290',
        NAME: 'Beaumont Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194645994,
        AWATER: 699228,
        INTPTLAT: '+33.9480479',
        INTPTLON: '-117.0146192',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.6688, 33.8566],
            [-115.6774, 33.6855],
            [-115.5716, 33.6855],
            [-115.5712, 33.4269],
            [-115.8984, 33.4265],
            [-115.9012, 33.3392],
            [-115.6687, 33.3191],
            [-115.6684, 33.2345],
            [-116.082, 33.235],
            [-116.0852, 33.4259],
            [-116.2688, 33.4281],
            [-116.2687, 33.6998],
            [-116.0948, 33.7001],
            [-116.0923, 33.8599],
            [-115.6688, 33.8566],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609070',
        NAME: 'Coachella Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2710317156,
        AWATER: 510628347,
        INTPTLAT: '+33.5584855',
        INTPTLON: '-115.8414453',
        ELSDLEA: '',
        UNSDLEA: '09070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.9274, 34.0797],
            [-114.4354, 34.0797],
            [-114.4383, 34.0226],
            [-114.5341, 33.9252],
            [-114.4965, 33.6969],
            [-114.5249, 33.6388],
            [-114.558, 33.5326],
            [-114.6271, 33.4336],
            [-114.9348, 33.4299],
            [-114.9274, 34.0797],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629640',
        NAME: 'Palo Verde Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2743969300,
        AWATER: 9805897,
        INTPTLAT: '+33.7636634',
        INTPTLON: '-114.7251681',
        ELSDLEA: '',
        UNSDLEA: '29640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7518, 39.3478],
            [-122.5284, 39.349],
            [-122.4178, 39.384],
            [-122.136, 39.3855],
            [-122.0792, 39.2755],
            [-122.135, 39.247],
            [-122.2095, 39.2186],
            [-122.4118, 39.2191],
            [-122.4003, 39.0931],
            [-122.4882, 39.0946],
            [-122.5153, 39.2092],
            [-122.5734, 39.2043],
            [-122.7571, 39.2843],
            [-122.7518, 39.3478],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624150',
        NAME: 'Maxwell Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1003181763,
        AWATER: 7358765,
        INTPTLAT: '+39.2881789',
        INTPTLON: '-122.4155734',
        ELSDLEA: '',
        UNSDLEA: '24150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8927, 39.7089],
            [-122.6257, 39.7092],
            [-122.6256, 39.7573],
            [-122.4584, 39.7575],
            [-122.3466, 39.6976],
            [-122.3085, 39.6402],
            [-122.3983, 39.5237],
            [-122.4178, 39.384],
            [-122.5284, 39.349],
            [-122.7518, 39.3478],
            [-122.7356, 39.5807],
            [-122.8854, 39.5801],
            [-122.8927, 39.7089],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638070',
        NAME: 'Stony Creek Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1439068426,
        AWATER: 8256290,
        INTPTLAT: '+39.5719825',
        INTPTLON: '-122.5808268',
        ELSDLEA: '',
        UNSDLEA: '38070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0792, 39.2755],
            [-122.0193, 39.2783],
            [-122.0098, 39.3839],
            [-121.89, 39.3839],
            [-121.8927, 39.3619],
            [-121.9083, 39.3039],
            [-121.9334, 39.2126],
            [-121.9283, 39.1987],
            [-121.9003, 39.1003],
            [-121.8942, 39.0904],
            [-122.0224, 39.0937],
            [-122.0504, 39.189],
            [-122.135, 39.247],
            [-122.0792, 39.2755],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609570',
        NAME: 'Colusa Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370782865,
        AWATER: 5237573,
        INTPTLAT: '+39.2369732',
        INTPTLON: '-121.9915448',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0031, 39.5577],
            [-121.8565, 39.5326],
            [-121.89, 39.3839],
            [-122.0098, 39.3839],
            [-122.0193, 39.2783],
            [-122.0792, 39.2755],
            [-122.136, 39.3855],
            [-122.0031, 39.5577],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631620',
        NAME: 'Princeton Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412483741,
        AWATER: 4281221,
        INTPTLAT: '+39.4438126',
        INTPTLON: '-122.0062343',
        ELSDLEA: '',
        UNSDLEA: '31620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4902, 39.0737],
            [-122.4882, 39.0946],
            [-122.4003, 39.0931],
            [-122.4118, 39.2191],
            [-122.2095, 39.2186],
            [-122.135, 39.247],
            [-122.0504, 39.189],
            [-122.0224, 39.0937],
            [-122.079, 39.0706],
            [-122.3385, 39.0679],
            [-122.335, 38.9239],
            [-122.3402, 38.9242],
            [-122.4091, 38.9628],
            [-122.4902, 39.0737],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642540',
        NAME: 'Williams Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 664609898,
        AWATER: 143558,
        INTPTLAT: '+39.1346597',
        INTPTLON: '-122.2616916',
        ELSDLEA: '',
        UNSDLEA: '42540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9536, 36.5469],
            [-119.863, 36.5759],
            [-119.8089, 36.5762],
            [-119.7641, 36.5329],
            [-119.7371, 36.5329],
            [-119.7616, 36.4893],
            [-119.9539, 36.5187],
            [-119.9536, 36.5469],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600067',
        NAME: 'Caruthers Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118136391,
        AWATER: 0,
        INTPTLAT: '+36.5292633',
        INTPTLON: '-119.8431955',
        ELSDLEA: '',
        UNSDLEA: '00067',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7637, 36.6924],
            [-119.7184, 36.7077],
            [-119.6197, 36.6344],
            [-119.7095, 36.5764],
            [-119.7094, 36.5909],
            [-119.7547, 36.6634],
            [-119.7637, 36.6924],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614250',
        NAME: 'Fowler Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100334645,
        AWATER: 0,
        INTPTLAT: '+36.6389761',
        INTPTLON: '-119.6864795',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7616, 36.4893],
            [-119.7371, 36.5329],
            [-119.721, 36.5328],
            [-119.6758, 36.5037],
            [-119.6427, 36.4299],
            [-119.6669, 36.4187],
            [-119.7098, 36.4238],
            [-119.7531, 36.4019],
            [-119.7616, 36.4893],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621150',
        NAME: 'Laton Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113257146,
        AWATER: 0,
        INTPTLAT: '+36.4595686',
        INTPTLON: '-119.7217682',
        ELSDLEA: '',
        UNSDLEA: '21150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5748, 36.6267],
            [-119.5284, 36.6666],
            [-119.4937, 36.5755],
            [-119.5297, 36.5685],
            [-119.5748, 36.6267],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629910',
        NAME: 'Parlier Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51907156,
        AWATER: 0,
        INTPTLAT: '+36.6183202',
        INTPTLON: '-119.5283536',
        ELSDLEA: '',
        UNSDLEA: '29910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1549, 34.7064],
            [-118.9767, 34.7063],
            [-118.9767, 34.7907],
            [-118.8814, 34.7906],
            [-118.7968, 34.6166],
            [-118.6914, 34.403],
            [-118.6776, 34.375],
            [-118.7988, 34.3522],
            [-118.9552, 34.3431],
            [-119.0073, 34.3288],
            [-119.0066, 34.3599],
            [-119.0235, 34.4758],
            [-119.079, 34.5617],
            [-119.1546, 34.5614],
            [-119.1549, 34.7064],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613800',
        NAME: 'Fillmore Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1213744875,
        AWATER: 5664799,
        INTPTLAT: '+34.5333594',
        INTPTLON: '-118.9096311',
        ELSDLEA: '',
        UNSDLEA: '13800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7816, 34.2084],
            [-118.6698, 34.2165],
            [-118.7887, 34.1682],
            [-118.7816, 34.2084],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627850',
        NAME: 'Oak Park Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59838452,
        AWATER: 0,
        INTPTLAT: '+34.1960890',
        INTPTLON: '-118.7246371',
        ELSDLEA: '',
        UNSDLEA: '27850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-119.4681, 34.0631],
              [-119.3425, 34.069],
              [-119.2962, 33.9972],
              [-119.3805, 33.9536],
              [-119.4923, 33.9832],
              [-119.4681, 34.0631],
            ],
          ],
          [
            [
              [-119.501, 34.3269],
              [-119.4423, 34.4639],
              [-119.3241, 34.4638],
              [-119.2659, 34.3914],
              [-119.1649, 34.4076],
              [-119.1294, 34.2884],
              [-119.1613, 34.264],
              [-119.1949, 34.239],
              [-119.3292, 34.2278],
              [-119.501, 34.3269],
            ],
          ],
          [
            [
              [-119.6361, 33.2807],
              [-119.5253, 33.3349],
              [-119.3609, 33.2421],
              [-119.4337, 33.167],
              [-119.5742, 33.1865],
              [-119.6361, 33.2807],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640980',
        NAME: 'Ventura Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 502820515,
        AWATER: 640583741,
        INTPTLAT: '+34.2956691',
        INTPTLON: '-119.3258658',
        ELSDLEA: '',
        UNSDLEA: '40980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.251, 33.4282],
            [-114.9348, 33.4299],
            [-114.6271, 33.4336],
            [-114.7201, 33.4079],
            [-114.677, 33.2702],
            [-114.7079, 33.0974],
            [-114.6708, 33.038],
            [-114.5165, 33.0276],
            [-114.4629, 32.9079],
            [-114.5269, 32.7571],
            [-114.6504, 32.733],
            [-114.7196, 32.7188],
            [-114.9809, 32.7009],
            [-114.9671, 32.8119],
            [-114.9269, 32.835],
            [-114.9257, 32.9887],
            [-115.0865, 32.9917],
            [-115.0839, 33.0817],
            [-115.2218, 33.1116],
            [-115.252, 33.152],
            [-115.251, 33.4282],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635070',
        NAME: 'San Pasqual Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3663311434,
        AWATER: 19847320,
        INTPTLAT: '+33.0569965',
        INTPTLON: '-114.8632159',
        ELSDLEA: '',
        UNSDLEA: '35070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8571, 33.4266],
            [-116.5184, 33.4269],
            [-116.4197, 33.4273],
            [-116.4183, 33.2272],
            [-116.3831, 33.1714],
            [-116.2067, 33.1698],
            [-116.1845, 33.1327],
            [-116.6461, 33.1375],
            [-116.667, 33.1516],
            [-116.7716, 33.2558],
            [-116.8571, 33.4266],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600042',
        NAME: 'Warner Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1105880126,
        AWATER: 13816053,
        INTPTLAT: '+33.2770098',
        INTPTLON: '-116.5718357',
        ELSDLEA: '',
        UNSDLEA: '00042',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4101, 33.1335],
            [-117.2882, 33.1838],
            [-117.2352, 33.1316],
            [-117.2613, 33.0921],
            [-117.3759, 33.0752],
            [-117.4101, 33.1335],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607500',
        NAME: 'Carlsbad Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72126059,
        AWATER: 53950988,
        INTPTLAT: '+33.1317943',
        INTPTLON: '-117.3244121',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.6229, 32.7317],
            [-116.667, 32.8157],
            [-116.6685, 32.9054],
            [-116.1031, 32.9041],
            [-116.1062, 32.6186],
            [-116.6894, 32.5712],
            [-116.6235, 32.6545],
            [-116.6229, 32.7317],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626100',
        NAME: 'Mountain Empire Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1713636736,
        AWATER: 2330547,
        INTPTLAT: '+32.7503329',
        INTPTLON: '-116.3914400',
        ELSDLEA: '',
        UNSDLEA: '26100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2751, 33.2575],
            [-117.1909, 33.2275],
            [-117.2352, 33.1316],
            [-117.2882, 33.1838],
            [-117.2751, 33.2575],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641190',
        NAME: 'Vista Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99761339,
        AWATER: 249265,
        INTPTLAT: '+33.2028218',
        INTPTLON: '-117.2395900',
        ELSDLEA: '',
        UNSDLEA: '41190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7184, 36.7077],
            [-119.6821, 36.7369],
            [-119.61, 36.7428],
            [-119.5392, 36.8521],
            [-119.3993, 36.8702],
            [-119.314, 36.8381],
            [-119.413, 36.706],
            [-119.5284, 36.6666],
            [-119.5748, 36.6267],
            [-119.6197, 36.6344],
            [-119.7184, 36.7077],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635250',
        NAME: 'Sanger Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457010621,
        AWATER: 1908300,
        INTPTLAT: '+36.7597244',
        INTPTLON: '-119.5159339',
        ELSDLEA: '',
        UNSDLEA: '35250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7095, 36.5764],
            [-119.6197, 36.6344],
            [-119.5748, 36.6267],
            [-119.5297, 36.5685],
            [-119.5297, 36.5613],
            [-119.5931, 36.5035],
            [-119.6758, 36.5037],
            [-119.721, 36.5328],
            [-119.7095, 36.5764],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636270',
        NAME: 'Selma Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137637479,
        AWATER: 566179,
        INTPTLAT: '+36.5647942',
        INTPTLON: '-119.6265146',
        ELSDLEA: '',
        UNSDLEA: '36270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0489, 36.357],
            [-119.9539, 36.5187],
            [-119.7616, 36.4893],
            [-119.7531, 36.4019],
            [-119.7674, 36.3898],
            [-119.898, 36.385],
            [-119.9589, 36.2991],
            [-119.9589, 36.2555],
            [-120.0486, 36.2555],
            [-120.0489, 36.3279],
            [-120.0489, 36.357],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600040',
        NAME: 'Riverdale Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344756583,
        AWATER: 205358,
        INTPTLAT: '+36.4068202',
        INTPTLON: '-119.9191651',
        ELSDLEA: '',
        UNSDLEA: '00040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0339, 36.8154],
            [-119.906, 36.8501],
            [-119.8711, 36.8516],
            [-119.8445, 36.7139],
            [-119.9073, 36.6632],
            [-119.9522, 36.6633],
            [-120.0339, 36.8154],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '607970',
        NAME: 'Central Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224341272,
        AWATER: 239610,
        INTPTLAT: '+36.7715394',
        INTPTLON: '-119.9297105',
        ELSDLEA: '',
        UNSDLEA: '07970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4215, 35.7956],
            [-117.4234, 35.9708],
            [-117.2695, 35.9706],
            [-117.2695, 35.7951],
            [-116.9194, 35.7954],
            [-116.9268, 35.45],
            [-117.5223, 35.4461],
            [-117.6302, 35.451],
            [-117.5949, 35.6238],
            [-117.4569, 35.6577],
            [-117.4215, 35.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639840',
        NAME: 'Trona Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2396258566,
        AWATER: 9636431,
        INTPTLAT: '+35.6127020',
        INTPTLON: '-117.1697542',
        ELSDLEA: '',
        UNSDLEA: '39840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4399, 34.3542],
            [-117.3995, 34.4703],
            [-117.3731, 34.4704],
            [-117.2547, 34.4709],
            [-117.2404, 34.3015],
            [-117.347, 34.2666],
            [-117.42, 34.2672],
            [-117.4399, 34.3542],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600014',
        NAME: 'Hesperia Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358275694,
        AWATER: 4180205,
        INTPTLAT: '+34.3616273',
        INTPTLON: '-117.3369800',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2633, 34.5862],
            [-117.2072, 34.6409],
            [-117.0276, 34.6429],
            [-117.0306, 34.3007],
            [-117.2404, 34.3015],
            [-117.2547, 34.4709],
            [-117.2633, 34.5862],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600017',
        NAME: 'Apple Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765388361,
        AWATER: 1623211,
        INTPTLAT: '+34.4749613',
        INTPTLON: '-117.1399812',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8279, 34.1217],
            [-116.6065, 34.1214],
            [-116.6073, 34.2063],
            [-116.5109, 34.2054],
            [-116.5104, 34.3831],
            [-116.4035, 34.3832],
            [-116.4029, 34.4551],
            [-116.0806, 34.4535],
            [-115.9882, 34.398],
            [-115.7616, 34.3972],
            [-115.7609, 34.1811],
            [-115.6955, 34.1801],
            [-115.6956, 34.0353],
            [-116.3006, 34.0325],
            [-116.6156, 34.0326],
            [-116.8279, 34.0338],
            [-116.8279, 34.1217],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625860',
        NAME: 'Morongo Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3474401362,
        AWATER: 30264,
        INTPTLAT: '+34.2119000',
        INTPTLON: '-116.1807526',
        ELSDLEA: '',
        UNSDLEA: '25860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2713, 34.0759],
            [-117.1603, 34.1645],
            [-117.0351, 34.1584],
            [-116.8279, 34.1217],
            [-116.8279, 34.0338],
            [-116.9296, 34.0341],
            [-116.9446, 34.121],
            [-117.0501, 34.1217],
            [-117.1213, 34.0039],
            [-117.1731, 34.0042],
            [-117.271, 34.0202],
            [-117.2713, 34.0759],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632070',
        NAME: 'Redlands Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373319414,
        AWATER: 2122950,
        INTPTLAT: '+34.0830157',
        INTPTLON: '-117.0733168',
        ELSDLEA: '',
        UNSDLEA: '32070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4399, 34.3542],
            [-117.42, 34.2672],
            [-117.347, 34.2666],
            [-117.3345, 34.2235],
            [-117.1902, 34.2225],
            [-117.1603, 34.1645],
            [-117.2713, 34.0759],
            [-117.3257, 34.0963],
            [-117.4216, 34.2209],
            [-117.5101, 34.2968],
            [-117.4399, 34.3542],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634170',
        NAME: 'San Bernardino City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420427180,
        AWATER: 3220902,
        INTPTLAT: '+34.1980590',
        INTPTLON: '-117.3403775',
        ELSDLEA: '',
        UNSDLEA: '34170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6638, 34.2203],
            [-117.6301, 34.2199],
            [-117.6193, 34.1216],
            [-117.6285, 34.0994],
            [-117.6286, 34.0926],
            [-117.7046, 34.0944],
            [-117.6638, 34.2203],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600016',
        NAME: 'Upland Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68192380,
        AWATER: 502750,
        INTPTLAT: '+34.1463165',
        INTPTLON: '-117.6585233',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.347, 34.2666],
            [-117.2404, 34.3015],
            [-117.0306, 34.3007],
            [-117.0136, 34.2926],
            [-117.0351, 34.1584],
            [-117.1603, 34.1645],
            [-117.1902, 34.2225],
            [-117.3345, 34.2235],
            [-117.347, 34.2666],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632610',
        NAME: 'Rim of the World Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310735802,
        AWATER: 3714829,
        INTPTLAT: '+34.2403959',
        INTPTLON: '-117.1419506',
        ELSDLEA: '',
        UNSDLEA: '32610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9296, 34.0341],
            [-116.9295, 34.0226],
            [-116.9808, 33.9904],
            [-117.1213, 34.0039],
            [-117.0501, 34.1217],
            [-116.9446, 34.121],
            [-116.9296, 34.0341],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '643560',
        NAME: 'Yucaipa-Calimesa Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189144614,
        AWATER: 139015,
        INTPTLAT: '+34.0511758',
        INTPTLON: '-117.0130918',
        ELSDLEA: '',
        UNSDLEA: '43560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3921, 34.085],
            [-117.3257, 34.0963],
            [-117.2713, 34.0759],
            [-117.271, 34.0202],
            [-117.1731, 34.0042],
            [-117.1742, 33.9826],
            [-117.279, 34.0046],
            [-117.3682, 34.0195],
            [-117.4712, 34.0337],
            [-117.3921, 34.085],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609390',
        NAME: 'Colton Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119135848,
        AWATER: 2112084,
        INTPTLAT: '+34.0424408',
        INTPTLON: '-117.3289832',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0276, 34.6429],
            [-116.747, 34.6437],
            [-116.7439, 34.4534],
            [-116.4029, 34.4551],
            [-116.4035, 34.3832],
            [-116.5104, 34.3831],
            [-116.5109, 34.2054],
            [-116.6073, 34.2063],
            [-116.7158, 34.2066],
            [-116.7154, 34.2936],
            [-117.0136, 34.2926],
            [-117.0306, 34.3007],
            [-117.0276, 34.6429],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600015',
        NAME: 'Lucerne Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1666152057,
        AWATER: 0,
        INTPTLAT: '+34.4202360',
        INTPTLON: '-116.7769791',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9083, 39.3039],
            [-121.7055, 39.3046],
            [-121.6302, 39.3057],
            [-121.6238, 39.2956],
            [-121.6236, 39.1974],
            [-121.7181, 39.2165],
            [-121.9334, 39.2126],
            [-121.9083, 39.3039],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622050',
        NAME: 'Live Oak Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244916900,
        AWATER: 641208,
        INTPTLAT: '+39.2590224',
        INTPTLON: '-121.7768256',
        ELSDLEA: '',
        UNSDLEA: '22050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3443, 38.5784],
            [-121.1946, 38.6458],
            [-121.1938, 38.7147],
            [-121.141, 38.712],
            [-121.1186, 38.7171],
            [-121.097, 38.6718],
            [-121.0793, 38.6329],
            [-121.0542, 38.57],
            [-121.1959, 38.5693],
            [-121.3053, 38.5402],
            [-121.3443, 38.5784],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613890',
        NAME: 'Folsom-Cordova Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232114470,
        AWATER: 7314809,
        INTPTLAT: '+38.6174796',
        INTPTLON: '-121.1845995',
        ELSDLEA: '',
        UNSDLEA: '13890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6392, 38.3269],
            [-119.562, 38.4107],
            [-119.5461, 38.4993],
            [-119.6191, 38.6035],
            [-119.5854, 38.7132],
            [-119.3284, 38.5348],
            [-119.157, 38.4147],
            [-118.4281, 37.8962],
            [-117.8327, 37.4649],
            [-118.4599, 37.463],
            [-118.5156, 37.4627],
            [-118.5144, 37.5246],
            [-118.6356, 37.525],
            [-118.6382, 37.525],
            [-118.6134, 37.6411],
            [-118.7169, 37.6413],
            [-118.7168, 37.7353],
            [-118.83, 37.7348],
            [-118.8276, 37.814],
            [-118.9969, 37.8134],
            [-119.1237, 37.7333],
            [-119.269, 37.7392],
            [-119.2013, 37.8043],
            [-119.2001, 37.8845],
            [-119.3205, 37.9732],
            [-119.3498, 38.0856],
            [-119.5474, 38.144],
            [-119.6392, 38.3269],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611870',
        NAME: 'Eastern Sierra Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6813709385,
        AWATER: 190745036,
        INTPTLAT: '+37.9706121',
        INTPTLON: '-118.8889525',
        ELSDLEA: '',
        UNSDLEA: '11870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7783, 37.0561],
            [-121.6194, 37.0615],
            [-121.5619, 37.1184],
            [-121.3762, 37.1182],
            [-121.3741, 37.173],
            [-121.3083, 37.168],
            [-121.3311, 36.9973],
            [-121.3995, 36.9607],
            [-121.5117, 36.9492],
            [-121.5814, 36.8992],
            [-121.6981, 36.9726],
            [-121.7783, 37.0561],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615180',
        NAME: 'Gilroy Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 577128516,
        AWATER: 2334382,
        INTPTLAT: '+37.0405984',
        INTPTLON: '-121.4889799',
        ELSDLEA: '',
        UNSDLEA: '15180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1919, 37.3975],
            [-122.1899, 37.4288],
            [-122.1449, 37.4582],
            [-122.0968, 37.4619],
            [-122.1156, 37.4127],
            [-122.1841, 37.3348],
            [-122.1919, 37.3975],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629610',
        NAME: 'Palo Alto Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71033347,
        AWATER: 1764434,
        INTPTLAT: '+37.4095181',
        INTPTLON: '-122.1493751',
        ELSDLEA: '',
        UNSDLEA: '29610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-119.4068, 37.16],
              [-119.3392, 37.1947],
              [-119.3694, 37.146],
              [-119.4068, 37.16],
            ],
          ],
          [
            [
              [-119.4726, 34.9751],
              [-119.4728, 34.9012],
              [-119.5358, 34.8976],
              [-119.7089, 34.9753],
              [-119.4726, 34.9751],
            ],
          ],
          [
            [
              [-120.6225, 36.0034],
              [-120.5826, 36.0026],
              [-120.5826, 35.9458],
              [-120.6395, 35.9465],
              [-120.6225, 36.0034],
            ],
          ],
          [
            [
              [-121.3083, 37.168],
              [-121.2268, 37.1348],
              [-121.2301, 37.1008],
              [-121.2154, 36.9612],
              [-121.3995, 36.9607],
              [-121.3311, 36.9973],
              [-121.3083, 37.168],
            ],
          ],
          [
            [
              [-122.0519, 36.8196],
              [-121.8043, 36.8383],
              [-121.8086, 36.7075],
              [-121.9021, 36.6185],
              [-121.9297, 36.637],
              [-121.9639, 36.5895],
              [-121.8929, 36.3404],
              [-121.8356, 36.2493],
              [-121.6548, 36.1413],
              [-121.5735, 36.0236],
              [-121.5031, 36.0003],
              [-121.4623, 35.8856],
              [-121.3478, 35.7952],
              [-121.4382, 35.7967],
              [-121.5615, 35.9438],
              [-121.6482, 35.9986],
              [-121.6744, 36.0647],
              [-121.7707, 36.162],
              [-121.8802, 36.2103],
              [-121.9591, 36.2871],
              [-121.9704, 36.3834],
              [-122.0427, 36.5966],
              [-122.0011, 36.6477],
              [-122.0519, 36.8196],
            ],
          ],
          [
            [
              [-124.1366, 41.4645],
              [-124.0652, 41.4647],
              [-124.0991, 41.2671],
              [-124.1557, 41.0902],
              [-124.1161, 40.9997],
              [-124.1349, 40.9337],
              [-124.1626, 40.8687],
              [-124.1811, 40.8369],
              [-124.2341, 40.7653],
              [-124.2345, 40.7568],
              [-124.3129, 40.6424],
              [-124.4003, 40.4197],
              [-124.3522, 40.2494],
              [-124.1223, 40.1087],
              [-124.0232, 40.0013],
              [-124.1349, 40.0025],
              [-124.3928, 40.2098],
              [-124.4295, 40.3649],
              [-124.4786, 40.4221],
              [-124.4477, 40.5297],
              [-124.2326, 40.8693],
              [-124.1827, 41.0013],
              [-124.2474, 41.0922],
              [-124.1607, 41.2893],
              [-124.1366, 41.4645],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '699997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 420510732,
        AWATER: 2328488223,
        INTPTLAT: '+41.0756257',
        INTPTLON: '-124.1660313',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8404, 37.1556],
            [-121.8314, 37.1624],
            [-121.781, 37.2093],
            [-121.7153, 37.2414],
            [-121.7206, 37.3082],
            [-121.5878, 37.3388],
            [-121.5867, 37.2741],
            [-121.4541, 37.274],
            [-121.3741, 37.173],
            [-121.3762, 37.1182],
            [-121.5619, 37.1184],
            [-121.6194, 37.0615],
            [-121.7783, 37.0561],
            [-121.8425, 37.0945],
            [-121.8404, 37.1556],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '625830',
        NAME: 'Morgan Hill Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 737872370,
        AWATER: 7446134,
        INTPTLAT: '+37.1760166',
        INTPTLON: '-121.6210421',
        ELSDLEA: '',
        UNSDLEA: '25830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0453, 37.4603],
            [-121.925, 37.4542],
            [-121.9152, 37.3961],
            [-121.9275, 37.3861],
            [-121.9277, 37.386],
            [-121.933, 37.3749],
            [-121.9502, 37.3299],
            [-121.9533, 37.3299],
            [-121.9564, 37.3299],
            [-121.9682, 37.3299],
            [-122.0227, 37.3598],
            [-122.0381, 37.4393],
            [-122.0453, 37.4603],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635430',
        NAME: 'Santa Clara Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103565110,
        AWATER: 6271944,
        INTPTLAT: '+37.4016881',
        INTPTLON: '-121.9733927',
        ELSDLEA: '',
        UNSDLEA: '35430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9564, 37.3299],
            [-121.9533, 37.3299],
            [-121.9502, 37.3299],
            [-121.933, 37.3749],
            [-121.9297, 37.3718],
            [-121.9249, 37.3674],
            [-121.9277, 37.386],
            [-121.9275, 37.3861],
            [-121.8767, 37.3656],
            [-121.8691, 37.3664],
            [-121.8576, 37.3451],
            [-121.8498, 37.2746],
            [-121.781, 37.2093],
            [-121.8314, 37.1624],
            [-121.8404, 37.1556],
            [-121.844, 37.1577],
            [-121.8942, 37.2217],
            [-121.9058, 37.2608],
            [-121.9203, 37.2846],
            [-121.9193, 37.3166],
            [-121.92, 37.3167],
            [-121.9256, 37.3198],
            [-121.932, 37.3269],
            [-121.947, 37.3255],
            [-121.9502, 37.3255],
            [-121.9564, 37.3299],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634590',
        NAME: 'San Jose Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122648090,
        AWATER: 571701,
        INTPTLAT: '+37.2623912',
        INTPTLON: '-121.8658513',
        ELSDLEA: '',
        UNSDLEA: '34590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0943, 33.3882],
            [-117.0946, 33.4305],
            [-116.945, 33.4276],
            [-116.8571, 33.4266],
            [-116.7716, 33.2558],
            [-116.8766, 33.1828],
            [-116.9183, 33.0805],
            [-116.9986, 33.1534],
            [-117.1423, 33.2245],
            [-117.1407, 33.2938],
            [-117.0523, 33.3151],
            [-117.0943, 33.3882],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600069',
        NAME: 'Valley Center-Pauma Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 779660907,
        AWATER: 1073603,
        INTPTLAT: '+33.2775232',
        INTPTLON: '-116.9393998',
        ELSDLEA: '',
        UNSDLEA: '00069',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5121, 33.2562],
            [-117.4164, 33.2596],
            [-117.3492, 33.3439],
            [-117.2969, 33.2792],
            [-117.2751, 33.2575],
            [-117.2882, 33.1838],
            [-117.4101, 33.1335],
            [-117.5121, 33.2562],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628250',
        NAME: 'Oceanside Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167368977,
        AWATER: 79629261,
        INTPTLAT: '+33.2308720',
        INTPTLON: '-117.3762783',
        ELSDLEA: '',
        UNSDLEA: '28250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.173, 32.9702],
            [-117.1483, 33.0363],
            [-117.0316, 33.0668],
            [-116.9966, 33.0521],
            [-116.9621, 32.9628],
            [-116.9637, 32.9031],
            [-117.0318, 32.8909],
            [-117.1709, 32.9326],
            [-117.173, 32.9702],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631530',
        NAME: 'Poway Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258585887,
        AWATER: 1187568,
        INTPTLAT: '+32.9826388',
        INTPTLON: '-117.0591927',
        ELSDLEA: '',
        UNSDLEA: '31530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3344, 32.9396],
            [-117.1709, 32.9326],
            [-117.0318, 32.8909],
            [-117.0064, 32.8137],
            [-117.0496, 32.7554],
            [-117.023, 32.719],
            [-117.017, 32.6913],
            [-117.0654, 32.6624],
            [-117.1085, 32.6039],
            [-117.1204, 32.6088],
            [-117.1333, 32.676],
            [-117.1632, 32.6658],
            [-117.1343, 32.6054],
            [-117.1247, 32.5342],
            [-117.2049, 32.5288],
            [-117.2233, 32.6212],
            [-117.3209, 32.6898],
            [-117.3344, 32.9396],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634320',
        NAME: 'San Diego City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528242280,
        AWATER: 389571257,
        INTPTLAT: '+32.7735040',
        INTPTLON: '-117.1880998',
        ELSDLEA: '',
        UNSDLEA: '34320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5445, 40.6938],
            [-123.544, 40.7329],
            [-123.3475, 40.7053],
            [-123.107, 40.7036],
            [-123.0375, 40.622],
            [-122.9747, 40.5299],
            [-122.8606, 40.4827],
            [-123.0657, 40.3439],
            [-123.1823, 40.4],
            [-123.3459, 40.3428],
            [-123.4578, 40.446],
            [-123.5444, 40.5654],
            [-123.5445, 40.6938],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600018',
        NAME: 'Mountain Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1630759204,
        AWATER: 99155,
        INTPTLAT: '+40.5422563',
        INTPTLON: '-123.2407060',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-119.1172, 33.5056],
              [-118.9821, 33.5243],
              [-118.9805, 33.4286],
              [-119.0719, 33.4138],
              [-119.1172, 33.5056],
            ],
          ],
          [
            [
              [-119.4681, 34.0631],
              [-119.4923, 33.9832],
              [-119.5328, 33.9499],
              [-119.7075, 33.9104],
              [-119.9288, 33.9055],
              [-120.1041, 33.8424],
              [-120.2211, 33.8842],
              [-120.299, 33.9685],
              [-120.4668, 33.9761],
              [-120.5738, 34.076],
              [-120.561, 34.1407],
              [-120.362, 34.1488],
              [-120.2528, 34.058],
              [-120.0803, 34.0864],
              [-120.001, 34.0768],
              [-119.9288, 34.1299],
              [-119.7452, 34.1109],
              [-119.6549, 34.0712],
              [-119.5655, 34.1088],
              [-119.4681, 34.0631],
            ],
          ],
          [
            [
              [-120.6956, 34.7786],
              [-120.4809, 34.7744],
              [-120.4298, 34.7339],
              [-120.2629, 34.6869],
              [-120.2436, 34.6218],
              [-120.2981, 34.5722],
              [-120.3745, 34.5929],
              [-120.4177, 34.3976],
              [-120.5236, 34.4195],
              [-120.5594, 34.4885],
              [-120.6424, 34.5038],
              [-120.7128, 34.5683],
              [-120.6647, 34.6963],
              [-120.6956, 34.7786],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622410',
        NAME: 'Lompoc Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1299509191,
        AWATER: 2023576120,
        INTPTLAT: '+34.0776701',
        INTPTLON: '-119.9162390',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4595, 38.3875],
            [-122.3416, 38.4654],
            [-122.1034, 38.5122],
            [-122.1121, 38.4761],
            [-122.1074, 38.4024],
            [-122.172, 38.4031],
            [-122.206, 38.3157],
            [-122.196, 38.163],
            [-122.4068, 38.1556],
            [-122.4068, 38.1587],
            [-122.3599, 38.2305],
            [-122.4595, 38.3875],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626640',
        NAME: 'Napa Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 697445842,
        AWATER: 17609699,
        INTPTLAT: '+38.3381868',
        INTPTLON: '-122.2763008',
        ELSDLEA: '',
        UNSDLEA: '26640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3559, 37.8357],
            [-122.3337, 37.8404],
            [-122.278, 37.827],
            [-122.2887, 37.85],
            [-122.2469, 37.8854],
            [-122.1889, 37.8376],
            [-122.1779, 37.8163],
            [-122.1222, 37.7298],
            [-122.1768, 37.6694],
            [-122.2187, 37.6317],
            [-122.252, 37.6724],
            [-122.2656, 37.6889],
            [-122.2809, 37.7072],
            [-122.2242, 37.7628],
            [-122.341, 37.8018],
            [-122.3559, 37.8357],
          ],
          [
            [-122.2494, 37.8228],
            [-122.2151, 37.8122],
            [-122.2273, 37.8328],
            [-122.2494, 37.8228],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '628050',
        NAME: 'Oakland Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144805154,
        AWATER: 56897826,
        INTPTLAT: '+37.7698464',
        INTPTLON: '-122.2256900',
        ELSDLEA: '',
        UNSDLEA: '28050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.455, 39.4461],
            [-120.0038, 39.4451],
            [-120.0043, 39.1656],
            [-120.0034, 39.1127],
            [-120.0025, 39.0675],
            [-120.0421, 38.966],
            [-120.1498, 38.966],
            [-120.153, 39.0458],
            [-120.2591, 39.0247],
            [-120.2649, 39.1858],
            [-120.3242, 39.2492],
            [-120.567, 39.2153],
            [-120.6075, 39.3155],
            [-120.6076, 39.3448],
            [-120.4564, 39.3497],
            [-120.455, 39.4461],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638770',
        NAME: 'Tahoe-Truckee Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1330663202,
        AWATER: 283004628,
        INTPTLAT: '+39.2794725',
        INTPTLON: '-120.2463715',
        ELSDLEA: '',
        UNSDLEA: '38770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3278, 40.4454],
            [-121.0614, 40.4465],
            [-121.0615, 40.2564],
            [-121.0037, 40.221],
            [-120.8718, 40.2416],
            [-120.7964, 40.3013],
            [-120.7963, 40.3014],
            [-120.6118, 40.2856],
            [-120.5852, 40.2855],
            [-120.5634, 40.2636],
            [-120.5638, 39.9547],
            [-120.3547, 39.8987],
            [-120.3599, 39.7085],
            [-120.6542, 39.7066],
            [-120.6808, 39.6768],
            [-120.8715, 39.7765],
            [-121.0095, 39.6395],
            [-121.1367, 39.6282],
            [-121.2106, 39.7119],
            [-121.3605, 39.8407],
            [-121.4206, 39.9296],
            [-121.3765, 40.1086],
            [-121.4369, 40.1519],
            [-121.349, 40.2645],
            [-121.3818, 40.3405],
            [-121.4704, 40.3502],
            [-121.4976, 40.4456],
            [-121.3278, 40.4454],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631170',
        NAME: 'Plumas Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5299121810,
        AWATER: 149695557,
        INTPTLAT: '+40.0152002',
        INTPTLON: '-120.9393606',
        ELSDLEA: '',
        UNSDLEA: '31170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1492, 37.5458],
            [-121.9517, 37.5975],
            [-121.9328, 37.5917],
            [-121.8678, 37.483],
            [-121.925, 37.4542],
            [-122.0453, 37.4603],
            [-122.0815, 37.4778],
            [-122.1219, 37.5123],
            [-122.1492, 37.5458],
          ],
          [
            [-122.0781, 37.5174],
            [-122.0309, 37.4816],
            [-121.988, 37.5225],
            [-122.0385, 37.5634],
            [-122.0781, 37.5174],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614400',
        NAME: 'Fremont Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200405413,
        AWATER: 26291597,
        INTPTLAT: '+37.4944613',
        INTPTLON: '-121.9411499',
        ELSDLEA: '',
        UNSDLEA: '14400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.18, 37.5845],
            [-122.014, 37.6317],
            [-121.9517, 37.5975],
            [-122.1492, 37.5458],
            [-122.1504, 37.5475],
            [-122.18, 37.5845],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626910',
        NAME: 'New Haven Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73705332,
        AWATER: 11932926,
        INTPTLAT: '+37.5942745',
        INTPTLON: '-122.0601224',
        ELSDLEA: '',
        UNSDLEA: '26910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8117, 37.6821],
            [-120.6473, 37.6855],
            [-120.682, 37.645],
            [-120.7827, 37.6251],
            [-120.8017, 37.6188],
            [-120.8117, 37.6821],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600063',
        NAME: 'Waterford Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70672571,
        AWATER: 3222393,
        INTPTLAT: '+37.6617680',
        INTPTLON: '-120.7251737',
        ELSDLEA: '',
        UNSDLEA: '00063',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9154, 38.2284],
            [-121.7698, 38.2613],
            [-121.6672, 38.3135],
            [-121.5837, 38.5082],
            [-121.5581, 38.5019],
            [-121.5005, 38.4161],
            [-121.4843, 38.2415],
            [-121.5719, 38.1315],
            [-121.58, 38.0944],
            [-121.6619, 38.0956],
            [-121.7326, 38.029],
            [-121.842, 38.0526],
            [-121.8625, 38.066],
            [-121.9136, 38.1217],
            [-121.9154, 38.2284],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633110',
        NAME: 'River Delta Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 879207347,
        AWATER: 74052994,
        INTPTLAT: '+38.2150530',
        INTPTLON: '-121.6757975',
        ELSDLEA: '',
        UNSDLEA: '33110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8772, 38.5831],
            [-121.6195, 38.5906],
            [-121.5837, 38.5082],
            [-121.6672, 38.3135],
            [-121.7087, 38.5165],
            [-121.8613, 38.5385],
            [-121.8772, 38.5831],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610620',
        NAME: 'Davis Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329809651,
        AWATER: 5212509,
        INTPTLAT: '+38.4946474',
        INTPTLON: '-121.6771343',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9876, 38.3161],
            [-121.8412, 38.3151],
            [-121.8424, 38.2715],
            [-121.9154, 38.2284],
            [-121.9876, 38.3161],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639630',
        NAME: 'Travis Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114770909,
        AWATER: 917513,
        INTPTLAT: '+38.2822211',
        INTPTLON: '-121.9247148',
        ELSDLEA: '',
        UNSDLEA: '39630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4068, 38.1556],
            [-122.196, 38.163],
            [-122.1676, 38.1345],
            [-122.1958, 38.0568],
            [-122.276, 38.0611],
            [-122.3475, 38.0733],
            [-122.4068, 38.1556],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640740',
        NAME: 'Vallejo City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107573628,
        AWATER: 64975434,
        INTPTLAT: '+38.1132602',
        INTPTLON: '-122.2815836',
        ELSDLEA: '',
        UNSDLEA: '40740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1759, 37.606],
            [-121.0857, 37.5924],
            [-121.0859, 37.5805],
            [-120.9937, 37.5804],
            [-120.948, 37.6201],
            [-120.9039, 37.625],
            [-120.9018, 37.5659],
            [-120.9487, 37.5364],
            [-121.0882, 37.5086],
            [-121.1759, 37.606],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608130',
        NAME: 'Ceres Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168595454,
        AWATER: 1553248,
        INTPTLAT: '+37.5586997',
        INTPTLON: '-121.0393287',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1515, 37.23],
            [-122.0215, 37.1278],
            [-122.0471, 37.0464],
            [-122.0986, 37.0263],
            [-122.1933, 37.1057],
            [-122.2427, 37.19],
            [-122.1515, 37.23],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634740',
        NAME: 'San Lorenzo Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235764611,
        AWATER: 737895,
        INTPTLAT: '+37.1408751',
        INTPTLON: '-122.1152795',
        ELSDLEA: '',
        UNSDLEA: '34740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8302, 37.5366],
            [-120.8104, 37.5585],
            [-120.7646, 37.5656],
            [-120.6817, 37.5955],
            [-120.5215, 37.5815],
            [-120.5991, 37.5511],
            [-120.7483, 37.4926],
            [-120.8302, 37.5366],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611040',
        NAME: 'Denair Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145960415,
        AWATER: 0,
        INTPTLAT: '+37.5467341',
        INTPTLON: '-120.7071830',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1676, 38.1345],
            [-122.0658, 38.1073],
            [-122.1211, 38.0379],
            [-122.1569, 38.0329],
            [-122.1958, 38.0568],
            [-122.1676, 38.1345],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604620',
        NAME: 'Benicia Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81871389,
        AWATER: 12200035,
        INTPTLAT: '+38.0927119',
        INTPTLON: '-122.1381052',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-121.8424, 38.2715],
              [-121.7698, 38.2613],
              [-121.9154, 38.2284],
              [-121.8424, 38.2715],
            ],
          ],
          [
            [
              [-122.1074, 38.4024],
              [-122.0621, 38.3251],
              [-121.9876, 38.3161],
              [-121.9154, 38.2284],
              [-121.9136, 38.1217],
              [-121.8625, 38.066],
              [-121.9327, 38.0481],
              [-122.0957, 38.0484],
              [-122.1211, 38.0379],
              [-122.0658, 38.1073],
              [-122.1676, 38.1345],
              [-122.196, 38.163],
              [-122.206, 38.3157],
              [-122.172, 38.4031],
              [-122.1074, 38.4024],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '613360',
        NAME: 'Fairfield-Suisun Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612885199,
        AWATER: 112866225,
        INTPTLAT: '+38.2073034',
        INTPTLON: '-122.0296674',
        ELSDLEA: '',
        UNSDLEA: '13360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-118.6356, 37.525],
              [-118.5144, 37.5246],
              [-118.5156, 37.4627],
              [-118.6356, 37.525],
            ],
          ],
          [
            [
              [-119.1237, 37.7333],
              [-118.9969, 37.8134],
              [-118.8276, 37.814],
              [-118.83, 37.7348],
              [-118.7168, 37.7353],
              [-118.7169, 37.6413],
              [-118.6134, 37.6411],
              [-118.6382, 37.525],
              [-118.6632, 37.463],
              [-118.775, 37.4631],
              [-118.8505, 37.4758],
              [-118.9171, 37.5503],
              [-119.0224, 37.5857],
              [-119.1237, 37.7333],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623530',
        NAME: 'Mammoth Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1032660097,
        AWATER: 23938062,
        INTPTLAT: '+37.6411548',
        INTPTLON: '-118.8252172',
        ELSDLEA: '',
        UNSDLEA: '23530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4408, 39.1933],
            [-123.3478, 39.1367],
            [-123.3157, 39.0625],
            [-123.2178, 39.0614],
            [-123.233, 38.9122],
            [-123.1076, 38.9127],
            [-123.081, 38.8524],
            [-123.2093, 38.8089],
            [-123.3853, 38.777],
            [-123.3852, 38.8841],
            [-123.4336, 38.9339],
            [-123.5065, 39.0232],
            [-123.6189, 39.1743],
            [-123.4408, 39.1933],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602730',
        NAME: 'Anderson Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 905211644,
        AWATER: 158470,
        INTPTLAT: '+38.9917860',
        INTPTLON: '-123.3615566',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.8966, 39.3759],
            [-123.6908, 39.3722],
            [-123.5595, 39.3982],
            [-123.5122, 39.3349],
            [-123.4887, 39.2821],
            [-123.3155, 39.2824],
            [-123.4408, 39.1933],
            [-123.6189, 39.1743],
            [-123.5065, 39.0232],
            [-123.6269, 39.0895],
            [-123.7861, 39.0921],
            [-123.8389, 39.1833],
            [-123.8966, 39.3759],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624480',
        NAME: 'Mendocino Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 871190322,
        AWATER: 213626736,
        INTPTLAT: '+39.2458071',
        INTPTLON: '-123.6241160',
        ELSDLEA: '',
        UNSDLEA: '24480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5122, 39.3349],
            [-123.2674, 39.3342],
            [-123.2053, 39.3941],
            [-123.1518, 39.2028],
            [-123.0454, 39.1806],
            [-123.078, 39.1518],
            [-123.093, 39.072],
            [-122.9866, 38.9973],
            [-122.9684, 38.9402],
            [-122.8216, 38.8501],
            [-123.081, 38.8524],
            [-123.1076, 38.9127],
            [-123.233, 38.9122],
            [-123.2178, 39.0614],
            [-123.3157, 39.0625],
            [-123.3478, 39.1367],
            [-123.4408, 39.1933],
            [-123.3155, 39.2824],
            [-123.4887, 39.2821],
            [-123.5122, 39.3349],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640300',
        NAME: 'Ukiah Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1173691961,
        AWATER: 9741102,
        INTPTLAT: '+39.1044853',
        INTPTLON: '-123.1912316',
        ELSDLEA: '',
        UNSDLEA: '40300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1233, 39.5117],
            [-123.0444, 39.5112],
            [-123.0751, 39.4077],
            [-123.0197, 39.3218],
            [-123.0454, 39.1806],
            [-123.1518, 39.2028],
            [-123.2053, 39.3941],
            [-123.187, 39.4812],
            [-123.1233, 39.5117],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '631400',
        NAME: 'Potter Valley Community Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407181014,
        AWATER: 728351,
        INTPTLAT: '+39.3443895',
        INTPTLON: '-123.1098595',
        ELSDLEA: '',
        UNSDLEA: '31400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6713, 40.0018],
            [-123.5445, 40.0019],
            [-123.4473, 39.9772],
            [-123.4525, 39.9005],
            [-123.3437, 39.6297],
            [-123.3833, 39.5792],
            [-123.5178, 39.5687],
            [-123.6895, 39.6268],
            [-123.6906, 39.7374],
            [-123.5899, 39.7616],
            [-123.5899, 39.8756],
            [-123.6699, 39.8897],
            [-123.6713, 40.0018],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642580',
        NAME: 'Laytonville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 939128178,
        AWATER: 821025,
        INTPTLAT: '+39.7647180',
        INTPTLON: '-123.5330640',
        ELSDLEA: '',
        UNSDLEA: '42580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4694, 38.926],
            [-121.4148, 38.9965],
            [-121.2795, 39.0346],
            [-121.1878, 39.0125],
            [-121.1725, 38.9196],
            [-121.2314, 38.8392],
            [-121.3002, 38.8246],
            [-121.433, 38.8243],
            [-121.4695, 38.8969],
            [-121.4694, 38.926],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642140',
        NAME: 'Western Placer Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 438267161,
        AWATER: 2411781,
        INTPTLAT: '+38.9361446',
        INTPTLON: '-121.3032885',
        ELSDLEA: '',
        UNSDLEA: '42140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9689, 38.4749],
            [-121.8613, 38.5385],
            [-121.7087, 38.5165],
            [-121.6672, 38.3135],
            [-121.7698, 38.2613],
            [-121.8424, 38.2715],
            [-121.8412, 38.3151],
            [-121.8506, 38.3906],
            [-121.9689, 38.4749],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611280',
        NAME: 'Dixon Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504174328,
        AWATER: 7543649,
        INTPTLAT: '+38.4018724',
        INTPTLON: '-121.7952918',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1121, 38.4761],
            [-121.9689, 38.4749],
            [-121.8506, 38.3906],
            [-121.8412, 38.3151],
            [-121.9876, 38.3161],
            [-122.0621, 38.3251],
            [-122.1074, 38.4024],
            [-122.1121, 38.4761],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '640590',
        NAME: 'Vacaville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268981179,
        AWATER: 1084669,
        INTPTLAT: '+38.3823938',
        INTPTLON: '-121.9873549',
        ELSDLEA: '',
        UNSDLEA: '40590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3002, 38.8246],
            [-121.2314, 38.8392],
            [-121.2178, 38.7704],
            [-121.3002, 38.8246],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600013',
        NAME: 'Rocklin Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48713365,
        AWATER: 99188,
        INTPTLAT: '+38.8080407',
        INTPTLON: '-121.2503648',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6491, 38.1475],
            [-122.6451, 38.1811],
            [-122.5454, 38.1587],
            [-122.5028, 38.113],
            [-122.3475, 38.0733],
            [-122.3828, 38.0398],
            [-122.5193, 38.0399],
            [-122.6298, 38.0717],
            [-122.646, 38.1055],
            [-122.6491, 38.1475],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627720',
        NAME: 'Novato Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154169420,
        AWATER: 65184712,
        INTPTLAT: '+38.0982704',
        INTPTLON: '-122.5403661',
        ELSDLEA: '',
        UNSDLEA: '27720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1655, 38.3888],
            [-123.0169, 38.4042],
            [-122.8882, 38.3571],
            [-122.7963, 38.3284],
            [-122.787, 38.308],
            [-122.7871, 38.239],
            [-122.8416, 38.1642],
            [-122.7751, 38.1184],
            [-122.6977, 38.0335],
            [-122.7253, 38.0015],
            [-122.7751, 37.9838],
            [-123.084, 37.9838],
            [-123.0148, 38.1491],
            [-123.1345, 38.2963],
            [-123.1655, 38.3888],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636670',
        NAME: 'Shoreline Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611149468,
        AWATER: 436990319,
        INTPTLAT: '+38.1855080',
        INTPTLON: '-122.9483652',
        ELSDLEA: '',
        UNSDLEA: '36670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.6123, 37.8152],
              [-122.4336, 37.8469],
              [-122.4302, 37.9184],
              [-122.4323, 37.9298],
              [-122.3738, 37.8837],
              [-122.3678, 37.8667],
              [-122.3559, 37.8357],
              [-122.341, 37.8018],
              [-122.2818, 37.7082],
              [-122.43, 37.7083],
              [-122.4408, 37.7083],
              [-122.5752, 37.7067],
              [-122.6123, 37.8152],
            ],
          ],
          [
            [
              [-123.1738, 37.7757],
              [-123.0705, 37.8138],
              [-122.95, 37.7322],
              [-123.0009, 37.6399],
              [-123.1326, 37.7194],
              [-123.1738, 37.7757],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634410',
        NAME: 'San Francisco Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121455687,
        AWATER: 479135404,
        INTPTLAT: '+37.7272391',
        INTPTLON: '-123.0322294',
        ELSDLEA: '',
        UNSDLEA: '34410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5569, 37.8172],
            [-121.5551, 37.8174],
            [-121.4791, 37.7071],
            [-121.557, 37.7077],
            [-121.5569, 37.8172],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601410',
        NAME: 'Lammersville Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60842577,
        AWATER: 69569,
        INTPTLAT: '+37.7460948',
        INTPTLON: '-121.5230350',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-118.6678, 34.2155],
              [-118.6509, 34.3205],
              [-118.4487, 34.3528],
              [-118.3244, 34.3522],
              [-118.1895, 34.3521],
              [-118.204, 34.2671],
              [-118.2912, 34.2217],
              [-118.363, 34.2068],
              [-118.3081, 34.1612],
              [-118.1839, 34.1491],
              [-118.1681, 34.1239],
              [-118.1553, 34.0987],
              [-118.1463, 34.0375],
              [-118.1566, 33.954],
              [-118.1761, 33.932],
              [-118.2253, 33.93],
              [-118.2, 33.8591],
              [-118.249, 33.7559],
              [-118.2268, 33.6906],
              [-118.352, 33.6666],
              [-118.3297, 33.7852],
              [-118.3265, 33.8874],
              [-118.3265, 33.9019],
              [-118.3265, 33.9237],
              [-118.3735, 34.0006],
              [-118.3872, 34.0294],
              [-118.4214, 34.0103],
              [-118.3867, 33.9769],
              [-118.37, 33.95],
              [-118.37, 33.9309],
              [-118.3961, 33.9309],
              [-118.499, 33.9163],
              [-118.5375, 33.9667],
              [-118.5513, 33.9825],
              [-118.5697, 33.9888],
              [-118.5704, 34.0693],
              [-118.641, 34.0745],
              [-118.6678, 34.2155],
            ],
            [
              [-118.4311, 34.0915],
              [-118.4059, 34.0571],
              [-118.3907, 34.0721],
              [-118.4094, 34.0949],
              [-118.4311, 34.0915],
            ],
          ],
          [
            [
              [-118.6782, 33.0397],
              [-118.6413, 33.0819],
              [-118.5421, 33.0738],
              [-118.4489, 32.9617],
              [-118.2972, 32.8451],
              [-118.3238, 32.7735],
              [-118.4365, 32.75],
              [-118.5382, 32.8128],
              [-118.6782, 33.0397],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '622710',
        NAME: 'Los Angeles Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1807170448,
        AWATER: 678529412,
        INTPTLAT: '+33.9523639',
        INTPTLON: '-118.2760583',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3867, 33.9769],
            [-118.3735, 34.0006],
            [-118.3265, 33.9237],
            [-118.3439, 33.9309],
            [-118.37, 33.95],
            [-118.3867, 33.9769],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618390',
        NAME: 'Inglewood Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26414294,
        AWATER: 77031,
        INTPTLAT: '+33.9612098',
        INTPTLON: '-118.3458973',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3081, 34.1612],
            [-118.2912, 34.2217],
            [-118.204, 34.2671],
            [-118.1885, 34.1781],
            [-118.1839, 34.1491],
            [-118.3081, 34.1612],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615240',
        NAME: 'Glendale Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97406696,
        AWATER: 370682,
        INTPTLAT: '+34.2038347',
        INTPTLON: '-118.2517672',
        ELSDLEA: '',
        UNSDLEA: '15240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8769, 34.1142],
            [-118.8681, 34.1196],
            [-118.8333, 34.0752],
            [-118.641, 34.0745],
            [-118.5704, 34.0693],
            [-118.5697, 33.9888],
            [-118.7275, 33.9803],
            [-118.8249, 33.949],
            [-118.9517, 33.9929],
            [-118.941, 34.0748],
            [-118.8769, 34.1142],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635700',
        NAME: 'Santa Monica-Malibu Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210644976,
        AWATER: 240429271,
        INTPTLAT: '+34.0327412',
        INTPTLON: '-118.7293110',
        ELSDLEA: '',
        UNSDLEA: '35700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4718, 33.816],
            [-118.3744, 33.8157],
            [-118.3523, 33.8742],
            [-118.3265, 33.8874],
            [-118.3297, 33.7852],
            [-118.4832, 33.8044],
            [-118.4718, 33.816],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639420',
        NAME: 'Torrance Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53294832,
        AWATER: 10265073,
        INTPTLAT: '+33.8309606',
        INTPTLON: '-118.3567633',
        ELSDLEA: '',
        UNSDLEA: '39420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1553, 34.0987],
            [-118.1353, 34.1064],
            [-118.1211, 34.1053],
            [-118.1083, 34.0771],
            [-118.1167, 34.043],
            [-118.1463, 34.0375],
            [-118.1553, 34.0987],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600153',
        NAME: 'Alhambra Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30456296,
        AWATER: 66539,
        INTPTLAT: '+34.0746675',
        INTPTLON: '-118.1376365',
        ELSDLEA: '',
        UNSDLEA: '00153',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1088, 33.9277],
            [-118.0604, 33.9171],
            [-118.0232, 33.9241],
            [-117.9943, 33.9172],
            [-117.9766, 33.9088],
            [-117.9766, 33.9028],
            [-118.0114, 33.8809],
            [-118.1086, 33.8877],
            [-118.1102, 33.9098],
            [-118.1088, 33.9277],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '627690',
        NAME: 'Norwalk-La Mirada Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40201717,
        AWATER: 325811,
        INTPTLAT: '+33.9041127',
        INTPTLON: '-118.0443463',
        ELSDLEA: '',
        UNSDLEA: '27690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9215, 34.0418],
            [-117.8803, 34.0456],
            [-117.8719, 34.0483],
            [-117.8487, 33.9469],
            [-117.9156, 33.947],
            [-117.9258, 33.946],
            [-117.9344, 33.9729],
            [-117.9215, 34.0418],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633750',
        NAME: 'Rowland Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63695706,
        AWATER: 168863,
        INTPTLAT: '+33.9908261',
        INTPTLON: '-117.8919640',
        ELSDLEA: '',
        UNSDLEA: '33750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1761, 33.932],
            [-118.1773, 33.9215],
            [-118.1783, 33.9125],
            [-118.2253, 33.93],
            [-118.1761, 33.932],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623160',
        NAME: 'Lynwood Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12261296,
        AWATER: 0,
        INTPTLAT: '+33.9236223',
        INTPTLON: '-118.2001301',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3735, 34.0006],
            [-118.3867, 33.9769],
            [-118.4214, 34.0103],
            [-118.3872, 34.0294],
            [-118.3735, 34.0006],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '610260',
        NAME: 'Culver City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15665796,
        AWATER: 74659,
        INTPTLAT: '+34.0035384',
        INTPTLON: '-118.3943059',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.943, 34.0925],
            [-117.8803, 34.0456],
            [-117.9215, 34.0418],
            [-117.9614, 34.0509],
            [-117.9662, 34.0628],
            [-117.943, 34.0925],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642000',
        NAME: 'West Covina Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22345829,
        AWATER: 109073,
        INTPTLAT: '+34.0605521',
        INTPTLON: '-117.9235050',
        ELSDLEA: '',
        UNSDLEA: '42000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8726, 34.1178],
            [-117.8375, 34.1211],
            [-117.8398, 34.0795],
            [-117.8725, 34.1139],
            [-117.8726, 34.1178],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608190',
        NAME: 'Charter Oak Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15123441,
        AWATER: 13566,
        INTPTLAT: '+34.1009759',
        INTPTLON: '-117.8541043',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.7497, 34.2229],
            [-117.7496, 34.2966],
            [-117.647, 34.2971],
            [-117.6469, 34.2959],
            [-117.6638, 34.2203],
            [-117.7046, 34.0944],
            [-117.7111, 34.0795],
            [-117.7561, 34.0993],
            [-117.7497, 34.2229],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608760',
        NAME: 'Claremont Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164272349,
        AWATER: 386652,
        INTPTLAT: '+34.1942751',
        INTPTLON: '-117.7129238',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8375, 34.1211],
            [-117.8101, 34.2232],
            [-117.7497, 34.2229],
            [-117.7561, 34.0993],
            [-117.827, 34.0642],
            [-117.8398, 34.0795],
            [-117.8375, 34.1211],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605610',
        NAME: 'Bonita Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99411195,
        AWATER: 1610051,
        INTPTLAT: '+34.1427207',
        INTPTLON: '-117.7828749',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7887, 34.1682],
            [-118.6698, 34.2165],
            [-118.6678, 34.2155],
            [-118.641, 34.0745],
            [-118.8333, 34.0752],
            [-118.8681, 34.1196],
            [-118.7887, 34.1682],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '621000',
        NAME: 'Las Virgenes Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201717846,
        AWATER: 1282113,
        INTPTLAT: '+34.1211089',
        INTPTLON: '-118.7391728',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8748, 34.3818],
            [-117.6552, 34.3972],
            [-117.6601, 34.4986],
            [-117.3995, 34.5067],
            [-117.3995, 34.4703],
            [-117.4399, 34.3542],
            [-117.5101, 34.2968],
            [-117.5592, 34.296],
            [-117.6469, 34.2959],
            [-117.647, 34.2971],
            [-117.6505, 34.339],
            [-117.8747, 34.3385],
            [-117.8748, 34.3818],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636970',
        NAME: 'Snowline Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 562773041,
        AWATER: 62683,
        INTPTLAT: '+34.4028688',
        INTPTLON: '-117.5847205',
        ELSDLEA: '',
        UNSDLEA: '36970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1768, 37.6694],
            [-122.1227, 37.716],
            [-122.0962, 37.6881],
            [-122.2187, 37.6317],
            [-122.1768, 37.6694],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634710',
        NAME: 'San Lorenzo Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28581174,
        AWATER: 14683602,
        INTPTLAT: '+37.6671133',
        INTPTLON: '-122.1485865',
        ELSDLEA: '',
        UNSDLEA: '34710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8656, 34.259],
            [-118.8291, 34.2653],
            [-118.7816, 34.2084],
            [-118.7887, 34.1682],
            [-118.8681, 34.1196],
            [-118.8769, 34.1142],
            [-118.9797, 34.1043],
            [-118.9966, 34.1564],
            [-118.8656, 34.259],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609640',
        NAME: 'Conejo Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208146976,
        AWATER: 873482,
        INTPTLAT: '+34.1776321',
        INTPTLON: '-118.8849122',
        ELSDLEA: '',
        UNSDLEA: '09640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8291, 34.2653],
            [-118.7988, 34.3522],
            [-118.6776, 34.375],
            [-118.6509, 34.3205],
            [-118.6678, 34.2155],
            [-118.6698, 34.2165],
            [-118.7816, 34.2084],
            [-118.8291, 34.2653],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '636840',
        NAME: 'Simi Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228032194,
        AWATER: 2905017,
        INTPTLAT: '+34.2878799',
        INTPTLON: '-118.7320265',
        ELSDLEA: '',
        UNSDLEA: '36840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7639, 37.302],
            [-119.6733, 37.3022],
            [-119.6549, 37.2397],
            [-119.6455, 37.1863],
            [-119.8192, 37.1695],
            [-119.8368, 37.2389],
            [-119.7639, 37.302],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600160',
        NAME: 'Yosemite Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188255967,
        AWATER: 343866,
        INTPTLAT: '+37.2309353',
        INTPTLON: '-119.7447835',
        ELSDLEA: '',
        UNSDLEA: '00160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-121.4932, 38.642],
              [-121.4056, 38.6383],
              [-121.42, 38.5961],
              [-121.5072, 38.5912],
              [-121.5087, 38.5968],
              [-121.4932, 38.642],
            ],
          ],
          [
            [
              [-121.558, 38.7001],
              [-121.4434, 38.7049],
              [-121.3645, 38.7039],
              [-121.3347, 38.7035],
              [-121.4013, 38.6541],
              [-121.4934, 38.6708],
              [-121.558, 38.7001],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601332',
        NAME: 'Twin Rivers Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114346981,
        AWATER: 1389497,
        INTPTLAT: '+38.6727995',
        INTPTLON: '-121.3842225',
        ELSDLEA: '',
        UNSDLEA: '01332',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0438, 41.0141],
            [-122.9142, 40.9981],
            [-122.8266, 40.9398],
            [-122.8262, 40.8399],
            [-122.903, 40.6935],
            [-122.9979, 40.6935],
            [-122.991, 40.9273],
            [-123.0438, 41.0141],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601331',
        NAME: 'Trinity Alps Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433572061,
        AWATER: 3364819,
        INTPTLAT: '+40.8612369',
        INTPTLON: '-122.9379689',
        ELSDLEA: '',
        UNSDLEA: '01331',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1349, 40.0025],
            [-124.0232, 40.0013],
            [-123.9112, 39.9687],
            [-123.9115, 39.8863],
            [-123.7974, 39.8864],
            [-123.7632, 40.0021],
            [-123.6713, 40.0018],
            [-123.6699, 39.8897],
            [-123.5899, 39.8756],
            [-123.5899, 39.7616],
            [-123.6906, 39.7374],
            [-123.9001, 39.7368],
            [-123.901, 39.7805],
            [-124.0786, 39.9723],
            [-124.1349, 40.0025],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609665',
        NAME: 'Leggett Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 533844931,
        AWATER: 207327592,
        INTPTLAT: '+39.8551980',
        INTPTLON: '-123.8079534',
        ELSDLEA: '',
        UNSDLEA: '09665',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.9001, 39.7368],
            [-123.6906, 39.7374],
            [-123.6895, 39.6268],
            [-123.5178, 39.5687],
            [-123.5595, 39.3982],
            [-123.6908, 39.3722],
            [-123.8966, 39.3759],
            [-123.8346, 39.5492],
            [-123.8572, 39.6747],
            [-123.9001, 39.7368],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614070',
        NAME: 'Fort Bragg Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651689398,
        AWATER: 250835191,
        INTPTLAT: '+39.5333808',
        INTPTLON: '-123.6830068',
        ELSDLEA: '',
        UNSDLEA: '14070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4473, 39.9772],
            [-122.934, 39.9781],
            [-122.9258, 39.9682],
            [-122.9601, 39.8452],
            [-122.9377, 39.7982],
            [-122.8927, 39.7089],
            [-122.8854, 39.5801],
            [-122.8903, 39.529],
            [-123.0444, 39.5112],
            [-123.1233, 39.5117],
            [-123.2334, 39.5457],
            [-123.2336, 39.6207],
            [-123.3437, 39.6297],
            [-123.4525, 39.9005],
            [-123.4473, 39.9772],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '633720',
        NAME: 'Round Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1958893842,
        AWATER: 3208127,
        INTPTLAT: '+39.7754661',
        INTPTLON: '-123.1447714',
        ELSDLEA: '',
        UNSDLEA: '33720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5178, 39.5687],
            [-123.3833, 39.5792],
            [-123.3437, 39.6297],
            [-123.2336, 39.6207],
            [-123.2334, 39.5457],
            [-123.1233, 39.5117],
            [-123.187, 39.4812],
            [-123.2053, 39.3941],
            [-123.2674, 39.3342],
            [-123.5122, 39.3349],
            [-123.5595, 39.3982],
            [-123.5178, 39.5687],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '642560',
        NAME: 'Willits Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 832050714,
        AWATER: 1739778,
        INTPTLAT: '+39.4727087',
        INTPTLON: '-123.3568253',
        ELSDLEA: '',
        UNSDLEA: '42560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8936, 37.0103],
            [-119.8378, 37.0104],
            [-119.736, 36.9525],
            [-119.8368, 36.8537],
            [-119.8711, 36.8516],
            [-119.906, 36.8501],
            [-119.8936, 37.0103],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600068',
        NAME: 'Golden Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219644179,
        AWATER: 134043,
        INTPTLAT: '+36.9288401',
        INTPTLON: '-119.8429798',
        ELSDLEA: '',
        UNSDLEA: '00068',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5275, 41.0435],
            [-122.1791, 41.0462],
            [-122.0935, 41.1126],
            [-122.0728, 41.1831],
            [-122.018, 41.1832],
            [-122.029, 40.9601],
            [-122.0351, 40.8242],
            [-122.1238, 40.7645],
            [-122.2573, 40.7662],
            [-122.2854, 40.6505],
            [-122.3468, 40.6154],
            [-122.3635, 40.6123],
            [-122.4341, 40.5942],
            [-122.4894, 40.75],
            [-122.4912, 40.8389],
            [-122.6093, 40.926],
            [-122.5275, 41.0435],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '614950',
        NAME: 'Gateway Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1600370857,
        AWATER: 106469365,
        INTPTLAT: '+40.8953831',
        INTPTLON: '-122.2869370',
        ELSDLEA: '',
        UNSDLEA: '14950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2341, 37.9845],
            [-122.1569, 38.0329],
            [-122.1211, 38.0379],
            [-122.0957, 38.0484],
            [-122.0985, 37.9477],
            [-122.1612, 37.9239],
            [-122.2204, 37.948],
            [-122.2341, 37.9845],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '624030',
        NAME: 'Martinez Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107016486,
        AWATER: 3874717,
        INTPTLAT: '+37.9776833',
        INTPTLON: '-122.1497155',
        ELSDLEA: '',
        UNSDLEA: '24030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2494, 37.8228],
            [-122.2273, 37.8328],
            [-122.2151, 37.8122],
            [-122.2494, 37.8228],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630330',
        NAME: 'Piedmont City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4402167,
        AWATER: 0,
        INTPTLAT: '+37.8225436',
        INTPTLON: '-122.2300249',
        ELSDLEA: '',
        UNSDLEA: '30330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.475, 36.3905],
            [-119.3856, 36.4585],
            [-119.3679, 36.4582],
            [-119.2952, 36.4695],
            [-119.2323, 36.4576],
            [-119.2062, 36.4284],
            [-119.1531, 36.3773],
            [-119.1789, 36.305],
            [-119.2251, 36.2832],
            [-119.234, 36.2759],
            [-119.2786, 36.2771],
            [-119.3539, 36.2763],
            [-119.4561, 36.2688],
            [-119.475, 36.3905],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '641160',
        NAME: 'Visalia Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456250010,
        AWATER: 649626,
        INTPTLAT: '+36.3685191',
        INTPTLON: '-119.3149800',
        ELSDLEA: '',
        UNSDLEA: '41160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0796, 36.0638],
            [-119.0803, 36.1164],
            [-119.0715, 36.1164],
            [-118.947, 36.1093],
            [-118.9467, 36.0802],
            [-118.9779, 36.082],
            [-118.9816, 36.0513],
            [-118.9268, 36.066],
            [-118.7884, 36.0579],
            [-118.6805, 36.1091],
            [-118.6269, 36.0388],
            [-118.0402, 36.0324],
            [-118.0101, 35.9776],
            [-118.6339, 35.9813],
            [-118.841, 35.9521],
            [-119.0178, 35.9948],
            [-119.1075, 36.0223],
            [-119.0796, 36.0638],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600064',
        NAME: 'Porterville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761941844,
        AWATER: 1670839,
        INTPTLAT: '+36.0204394',
        INTPTLON: '-118.6159864',
        ELSDLEA: '',
        UNSDLEA: '00064',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0659, 34.9261],
            [-118.8538, 34.9247],
            [-118.8366, 34.8175],
            [-118.8814, 34.7906],
            [-118.9767, 34.7907],
            [-118.9767, 34.7063],
            [-119.1549, 34.7064],
            [-119.1719, 34.8135],
            [-119.1699, 34.9051],
            [-119.0659, 34.9261],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600026',
        NAME: 'El Tejon Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 572900390,
        AWATER: 1096743,
        INTPTLAT: '+34.8432832',
        INTPTLON: '-118.9897489',
        ELSDLEA: '',
        UNSDLEA: '00026',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.592, 38.3986],
            [-122.4944, 38.4227],
            [-122.4595, 38.3875],
            [-122.3599, 38.2305],
            [-122.4068, 38.1587],
            [-122.5371, 38.2664],
            [-122.5837, 38.3577],
            [-122.6018, 38.3575],
            [-122.592, 38.3986],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '637200',
        NAME: 'Sonoma Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311653475,
        AWATER: 1994246,
        INTPTLAT: '+38.2908198',
        INTPTLON: '-122.4703247',
        ELSDLEA: '',
        UNSDLEA: '37200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4726, 34.9751],
            [-119.4727, 35.0769],
            [-119.2894, 35.0939],
            [-119.0742, 35.0948],
            [-119.0648, 35.0949],
            [-119.0659, 34.9261],
            [-119.1699, 34.9051],
            [-119.1719, 34.8135],
            [-119.2769, 34.8797],
            [-119.4728, 34.9012],
            [-119.4726, 34.9751],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '623820',
        NAME: 'Maricopa Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 882547224,
        AWATER: 1534137,
        INTPTLAT: '+34.9906182',
        INTPTLON: '-119.2642456',
        ELSDLEA: '',
        UNSDLEA: '23820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6331, 35.0869],
            [-117.4546, 35.0853],
            [-117.4545, 34.821],
            [-117.6673, 34.8225],
            [-118.0234, 34.8222],
            [-118.0247, 34.9545],
            [-117.8991, 34.9994],
            [-117.8996, 35.0874],
            [-117.6331, 35.0869],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '626490',
        NAME: 'Muroc Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1423311399,
        AWATER: 7346654,
        INTPTLAT: '+34.9479151',
        INTPTLON: '-117.7033028',
        ELSDLEA: '',
        UNSDLEA: '26490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7222, 35.2095],
            [-118.6996, 35.2385],
            [-118.6273, 35.2676],
            [-118.2686, 35.2753],
            [-118.2722, 35.0945],
            [-118.3098, 34.9656],
            [-118.6359, 34.9528],
            [-118.6367, 35.0396],
            [-118.7526, 35.102],
            [-118.7222, 35.2095],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638880',
        NAME: 'Tehachapi Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1285292706,
        AWATER: 801166,
        INTPTLAT: '+35.1224342',
        INTPTLON: '-118.4915717',
        ELSDLEA: '',
        UNSDLEA: '38880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8019, 35.5027],
            [-120.7057, 35.5326],
            [-120.5868, 35.5132],
            [-120.4981, 35.593],
            [-120.4803, 35.5278],
            [-120.3547, 35.5276],
            [-120.3551, 35.4319],
            [-119.9974, 35.4395],
            [-119.9442, 35.4393],
            [-119.8802, 35.3512],
            [-119.8093, 35.3509],
            [-119.8094, 35.2636],
            [-119.6667, 35.2616],
            [-119.6671, 35.1748],
            [-119.5536, 35.18],
            [-119.4988, 35.0913],
            [-119.4727, 35.0769],
            [-119.4726, 34.9751],
            [-119.7089, 34.9753],
            [-119.8308, 35.0058],
            [-120.093, 35.1142],
            [-120.1015, 35.1135],
            [-120.095, 35.1756],
            [-120.202, 35.1788],
            [-120.2578, 35.2609],
            [-120.4723, 35.2608],
            [-120.5952, 35.3198],
            [-120.8018, 35.4644],
            [-120.8019, 35.5027],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '603300',
        NAME: 'Atascadero Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3109298606,
        AWATER: 4351687,
        INTPTLAT: '+35.2679064',
        INTPTLON: '-120.1006541',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.925, 35.5778],
            [-120.793, 35.6395],
            [-120.7402, 35.5694],
            [-120.6234, 35.5677],
            [-120.5868, 35.5132],
            [-120.7057, 35.5326],
            [-120.8019, 35.5027],
            [-120.9073, 35.5462],
            [-120.925, 35.5778],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '639000',
        NAME: 'Templeton Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260717334,
        AWATER: 0,
        INTPTLAT: '+35.5527828',
        INTPTLON: '-120.7662719',
        ELSDLEA: '',
        UNSDLEA: '39000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4382, 35.7967],
            [-121.3478, 35.7952],
            [-121.2438, 35.7945],
            [-121.1757, 35.7941],
            [-121.1125, 35.7042],
            [-120.925, 35.5778],
            [-120.9073, 35.5462],
            [-120.9712, 35.5109],
            [-120.9841, 35.4076],
            [-121.0416, 35.42],
            [-121.2016, 35.5837],
            [-121.3235, 35.625],
            [-121.4382, 35.7967],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600049',
        NAME: 'Coast Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554235197,
        AWATER: 342747219,
        INTPTLAT: '+35.6597457',
        INTPTLON: '-121.1391026',
        ELSDLEA: '',
        UNSDLEA: '00049',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1125, 35.7042],
            [-120.873, 35.7214],
            [-120.6494, 35.707],
            [-120.5336, 35.7032],
            [-120.5151, 35.7903],
            [-120.4256, 35.79],
            [-120.4263, 35.6591],
            [-120.4981, 35.593],
            [-120.5868, 35.5132],
            [-120.6234, 35.5677],
            [-120.7402, 35.5694],
            [-120.793, 35.6395],
            [-120.925, 35.5778],
            [-121.1125, 35.7042],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600048',
        NAME: 'Paso Robles Joint Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 802307555,
        AWATER: 4491840,
        INTPTLAT: '+35.6572823',
        INTPTLON: '-120.7154599',
        ELSDLEA: '',
        UNSDLEA: '00048',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1498, 38.966],
            [-120.0421, 38.966],
            [-120.0025, 39.0675],
            [-120.001, 38.9996],
            [-119.9043, 38.9333],
            [-119.8777, 38.8683],
            [-120.0724, 38.7028],
            [-120.1221, 38.6686],
            [-120.1466, 38.6864],
            [-120.1154, 38.7667],
            [-120.15, 38.8784],
            [-120.1498, 38.966],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '620640',
        NAME: 'Lake Tahoe Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452924237,
        AWATER: 90184264,
        INTPTLAT: '+38.8697684',
        INTPTLON: '-120.0280338',
        ELSDLEA: '',
        UNSDLEA: '20640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.141, 38.712],
            [-121.121, 38.7525],
            [-121.1014, 38.8153],
            [-121.0375, 38.9157],
            [-120.9347, 38.9638],
            [-120.7302, 39.004],
            [-120.5724, 38.9144],
            [-120.471, 38.9655],
            [-120.4352, 39.0284],
            [-120.2591, 39.0247],
            [-120.153, 39.0458],
            [-120.1498, 38.966],
            [-120.15, 38.8784],
            [-120.2687, 38.8825],
            [-120.459, 38.8867],
            [-120.6886, 38.9117],
            [-120.6928, 38.867],
            [-120.7861, 38.839],
            [-120.8319, 38.7696],
            [-120.9042, 38.8272],
            [-121.0075, 38.7637],
            [-121.1186, 38.7171],
            [-121.141, 38.712],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '605240',
        NAME: 'Black Oak Mine Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1046100196,
        AWATER: 21230193,
        INTPTLAT: '+38.9127655',
        INTPTLON: '-120.6348534',
        ELSDLEA: '',
        UNSDLEA: '05240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2093, 38.8089],
            [-123.081, 38.8524],
            [-122.8216, 38.8501],
            [-122.8047, 38.8367],
            [-122.7476, 38.7923],
            [-122.9064, 38.7909],
            [-122.9495, 38.7265],
            [-123.2452, 38.7047],
            [-123.2093, 38.8089],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '608970',
        NAME: 'Cloverdale Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384431617,
        AWATER: 9270259,
        INTPTLAT: '+38.7880084',
        INTPTLON: '-123.0293479',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7476, 38.7923],
            [-122.6967, 38.721],
            [-122.8634, 38.6859],
            [-123.0195, 38.6647],
            [-123.1278, 38.6465],
            [-123.2452, 38.7047],
            [-122.9495, 38.7265],
            [-122.9064, 38.7909],
            [-122.7476, 38.7923],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '615150',
        NAME: 'Geyserville Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360504455,
        AWATER: 10595962,
        INTPTLAT: '+38.6934977',
        INTPTLON: '-123.1219970',
        ELSDLEA: '',
        UNSDLEA: '15150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.3006, 34.0325],
            [-115.6956, 34.0353],
            [-115.6663, 34.0353],
            [-115.6688, 33.8566],
            [-116.0923, 33.8599],
            [-116.0948, 33.7001],
            [-116.2687, 33.6998],
            [-116.2688, 33.4281],
            [-116.4143, 33.4273],
            [-116.4077, 33.7725],
            [-116.3011, 33.776],
            [-116.3006, 34.0325],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '611110',
        NAME: 'Desert Sands Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1981842222,
        AWATER: 2555425,
        INTPTLAT: '+33.8197276',
        INTPTLON: '-116.1121798',
        ELSDLEA: '',
        UNSDLEA: '11110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1212, 33.9329],
            [-116.999, 33.918],
            [-116.9289, 33.855],
            [-116.8599, 33.8161],
            [-116.8599, 33.7581],
            [-117.0509, 33.7875],
            [-117.0511, 33.8456],
            [-117.1211, 33.9031],
            [-117.1212, 33.9329],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '634440',
        NAME: 'San Jacinto Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232615198,
        AWATER: 2471916,
        INTPTLAT: '+33.8352719',
        INTPTLON: '-116.9946766',
        ELSDLEA: '',
        UNSDLEA: '34440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.6156, 34.0326],
            [-116.3006, 34.0325],
            [-116.3011, 33.776],
            [-116.4077, 33.7725],
            [-116.4143, 33.4273],
            [-116.4197, 33.4273],
            [-116.5184, 33.4269],
            [-116.5125, 33.6853],
            [-116.6144, 33.7297],
            [-116.6156, 34.0326],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '629550',
        NAME: 'Palm Springs Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1291352638,
        AWATER: 3230984,
        INTPTLAT: '+33.8068582',
        INTPTLON: '-116.4684359',
        ELSDLEA: '',
        UNSDLEA: '29550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6069, 33.9728],
            [-117.5584, 33.9884],
            [-117.5231, 33.9656],
            [-117.5018, 33.8268],
            [-117.4497, 33.8436],
            [-117.3624, 33.8435],
            [-117.3626, 33.8],
            [-117.4147, 33.7712],
            [-117.535, 33.7275],
            [-117.6731, 33.8703],
            [-117.6737, 33.8708],
            [-117.6069, 33.9728],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609850',
        NAME: 'Corona-Norco Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370470936,
        AWATER: 8348349,
        INTPTLAT: '+33.8446960',
        INTPTLON: '-117.5378961',
        ELSDLEA: '',
        UNSDLEA: '09850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.842, 38.0526],
            [-121.7326, 38.029],
            [-121.7324, 37.9832],
            [-121.8322, 37.9171],
            [-121.869, 37.917],
            [-121.842, 38.0526],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602850',
        NAME: 'Antioch Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102838901,
        AWATER: 6314611,
        INTPTLAT: '+37.9789846',
        INTPTLON: '-121.8066181',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.276, 38.0611],
            [-122.1958, 38.0568],
            [-122.1569, 38.0329],
            [-122.2341, 37.9845],
            [-122.276, 38.0611],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '618990',
        NAME: 'John Swett Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52776337,
        AWATER: 8760242,
        INTPTLAT: '+38.0320857',
        INTPTLON: '-122.2234423',
        ELSDLEA: '',
        UNSDLEA: '18990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9327, 38.0481],
            [-121.8625, 38.066],
            [-121.842, 38.0526],
            [-121.869, 37.917],
            [-121.9319, 37.98],
            [-121.9327, 38.0481],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '630600',
        NAME: 'Pittsburg Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67788591,
        AWATER: 8670791,
        INTPTLAT: '+38.0027878',
        INTPTLON: '-121.8893695',
        ELSDLEA: '',
        UNSDLEA: '30600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4013, 38.0179],
            [-122.3828, 38.0398],
            [-122.3475, 38.0733],
            [-122.276, 38.0611],
            [-122.2341, 37.9845],
            [-122.2204, 37.948],
            [-122.2563, 37.897],
            [-122.2869, 37.8987],
            [-122.3738, 37.8837],
            [-122.4323, 37.9298],
            [-122.435, 37.9368],
            [-122.4013, 38.0179],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '632550',
        NAME: 'West Contra Costa Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159621864,
        AWATER: 115378750,
        INTPTLAT: '+37.9730280',
        INTPTLON: '-122.3310035',
        ELSDLEA: '',
        UNSDLEA: '32550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8564, 38.5766],
            [-122.7799, 38.591],
            [-122.7243, 38.5833],
            [-122.8208, 38.5033],
            [-122.8443, 38.4923],
            [-122.861, 38.5032],
            [-122.8564, 38.5766],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '600034',
        NAME: 'Windsor Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79455156,
        AWATER: 488314,
        INTPTLAT: '+38.5491930',
        INTPTLON: '-122.8050545',
        ELSDLEA: '',
        UNSDLEA: '00034',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.667, 38.3505],
            [-122.7046, 38.3041],
            [-122.7195, 38.3138],
            [-122.7539, 38.3364],
            [-122.7597, 38.3604],
            [-122.667, 38.3505],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '609940',
        NAME: 'Cotati-Rohnert Park Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38554955,
        AWATER: 29015,
        INTPTLAT: '+38.3392564',
        INTPTLON: '-122.7071204',
        ELSDLEA: '',
        UNSDLEA: '09940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0816, 34.121],
            [-118.0675, 34.1308],
            [-118.0499, 34.0965],
            [-118.0549, 34.0897],
            [-118.0736, 34.0905],
            [-118.0816, 34.121],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '638980',
        NAME: 'Temple City Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9481533,
        AWATER: 0,
        INTPTLAT: '+34.1080394',
        INTPTLON: '-118.0651444',
        ELSDLEA: '',
        UNSDLEA: '38980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0128, 34.2084],
            [-118.0184, 34.1091],
            [-118.0499, 34.0965],
            [-118.0675, 34.1308],
            [-118.0128, 34.2084],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '602970',
        NAME: 'Arcadia Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32730429,
        AWATER: 90655,
        INTPTLAT: '+34.1438613',
        INTPTLON: '-118.0357522',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0631, 37.8371],
            [-122.0616, 37.843],
            [-122.0072, 37.8824],
            [-121.8324, 37.846],
            [-121.8707, 37.7401],
            [-121.978, 37.7296],
            [-122.0643, 37.7994],
            [-122.0631, 37.8371],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '635130',
        NAME: 'San Ramon Valley Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276720423,
        AWATER: 72161,
        INTPTLAT: '+37.8046693',
        INTPTLON: '-121.9365298',
        ELSDLEA: '',
        UNSDLEA: '35130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1429, 33.9025],
            [-118.1102, 33.9098],
            [-118.1086, 33.8877],
            [-118.1017, 33.8535],
            [-118.1424, 33.8548],
            [-118.1429, 33.9025],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '604440',
        NAME: 'Bellflower Unified School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18358937,
        AWATER: 705,
        INTPTLAT: '+33.8789155',
        INTPTLON: '-118.1227639',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1009, 39.7517],
            [-122.0228, 39.7664],
            [-121.9416, 39.6981],
            [-121.9692, 39.657],
            [-121.9835, 39.6233],
            [-122.0809, 39.6524],
            [-122.0962, 39.7438],
            [-122.0999, 39.7498],
            [-122.1009, 39.7517],
          ],
        ],
      },
      properties: {
        STATEFP: '06',
        SCSDLEA: '',
        GEOID: '601339',
        NAME: 'Hamilton Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125178791,
        AWATER: 4739545,
        INTPTLAT: '+39.6880057',
        INTPTLON: '-122.0300886',
        ELSDLEA: '',
        UNSDLEA: '01339',
      },
    },
  ],
};

import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { recoverApi } from '../../api/users';
import './style.css';

const ForgotPasswordForm = () => {
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const validateAndSend = async (payload) => {
    setSubmitted(true);
    setEmail(payload.usernameOrEmail);
    await recoverApi(payload.usernameOrEmail);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const { elements } = form;
      const payload = {};
      Object.keys(elements).forEach((elKey) => {
        const el = elements[elKey];
        if (el.name && el.value) {
          const key = el.name.replace(/[^a-zA-Z0-9]/g, '');
          payload[key] = el.value;
        }
      });
      validateAndSend(payload);
    }
    setValidated(true);
  };

  return (
    <Container className="w-50 bg-white p-4 m-0">
      <h3 className="Login-info-subtitle mb-3">Reset my password</h3>
      {submitted && (
        <>
          <Alert variant="info">
            <p className="m-0">Reset password link has been sent to {email}</p>
          </Alert>
          <Container className="p-0">
            <a href="/login">Login</a>
          </Container>
        </>
      )}
      {!submitted && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              required
              type="email"
              placeholder="Enter your e-mail *"
              name="usernameOrEmail"
              size="md"
              className="mb-2"
              maxLength="100"
            />
            <Form.Control.Feedback type="invalid">Please complete email.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Container className="p-0">
              <a href="/login">Cancel</a>
              <Button type="submit" className="bento-btn ml-3">
                <span className="button-text">Reset</span>
              </Button>
            </Container>
          </Form.Group>
        </Form>
      )}
    </Container>
  );
};

export default ForgotPasswordForm;

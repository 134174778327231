import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Breadcrumbs from '../Breadcrumbs';
import './style.css';

const SectionTitle = (props) => {
  const { title, buttonText, buttonLink, buttonTheme, buttonIcon, margin } = props;
  return (
    <div className={`SectionTitle ${margin}`}>
      <Breadcrumbs />
      <div className="main-container">
        <h2 className="title-h2">{title}</h2>
        {buttonText && buttonLink && (
          <Button
            buttonLink={buttonLink}
            buttonText={buttonText}
            theme={buttonTheme}
            isRoute
            icon={buttonIcon}
            redirect
          />
        )}
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonTheme: PropTypes.string,
  buttonIcon: PropTypes.string,
  margin: PropTypes.string,
};

SectionTitle.defaultProps = {
  buttonText: null,
  buttonLink: null,
  buttonTheme: 'dark',
  buttonIcon: null,
  margin: 'md',
};

export default SectionTitle;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ResetPasswordForm from '../Components/ResetPasswordForm';
import './css/login.css';

export default function Activate() {
  return (
    <div className="Login">
      <Container>
        <Row>
          <Col>
            <Container>
              <Row className="flex-column mt-2">
                <Col className="Login-info-logo">
                  <img src="/bento-logo.svg" alt="Bento Logo" />
                  <span>Bento</span>
                </Col>
                <Col className="Login-info-welcome">Welcome to</Col>
                <h2 className="Login-info-title">Bento</h2>
                <h3 className="Login-info-subtitle">The Benchmarking Tool</h3>
              </Row>
              <Row className="mt-4">
                <ResetPasswordForm isActivate />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

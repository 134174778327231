import React from 'react';
import Button from '../../Button';
import './style.css';

const TableHeader = () => (
  <div className="TableHeader">
    <div className="TableHeader-title">
      Data
    </div>
    <Button
      buttonLink="/admin/dashboard/manager/add"
      buttonText="Add data"
      theme="dark"
      size="sm"
      isRoute
      redirect
    />
  </div>
);

export default TableHeader;

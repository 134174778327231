/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {
  makeMLITooltip,
  regroupForWaffle,
  regroupForWaffleDashboard,
} from '../../../helpers/charts';
import { HIGHCHARTS_TOOLTIP_DELAY, HIGHCHARTS_TYPES } from '../../../constants/charts';

const WaffleChart = forwardRef(
  (
    {
      parsedData,
      currentJob = {},
      benchmarks = {},
      chartType = HIGHCHARTS_TYPES.SCATTER,
      displayOnDashboard = false,
      chartLoaded,
      scheme = undefined,
    },
    chartRef
  ) => {
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);
    function dashboardLegendLabelFormatter() {
      let updated_name = this.name;
      if (updated_name.includes('% ')) updated_name = updated_name.replace('% ', '');
      return `${updated_name}  (${this.userOptions.data_point}%)`;
    }
    function tooltipFormatter() {
      let series_name;
      let text_color;
      let text;
      let percent_diff;
      let benchmarked = false;
      let segmented = false;

      if (parsedData.segmentsActive && (benchmarks.state || benchmarks.national)) {
        benchmarked = true;
        segmented = true;
      } else if (parsedData.segmentsActive && !benchmarks.state && !benchmarks.national) {
        segmented = true;
      }

      if (this.series.name === 'default') {
        series_name = 'All respondents';
      } else {
        series_name = this.series.userOptions.segment_name;
      }
      const benchmark = this.series.userOptions.benchmark;
      let benchmark_label = this.series.userOptions.benchmark_label;
      let nat_category = false;
      const filtered = this.series.userOptions.filtered;
      const moe = Array.isArray(this.series.userOptions.MOE)
        ? this.series.userOptions.MOE[0]
        : this.series.userOptions.MOE;

      if (segmented) {
        if (benchmarked) {
          if (series_name.includes('National')) {
            nat_category = true;
          } else if (!series_name.includes('State') && !filtered) {
            nat_category = true;
          }
        } else if (series_name.includes('National')) {
          nat_category = true;
        } else if (
          (series_name.includes('All respondents') && !filtered) ||
          (series_name.includes('deafult') && !filtered)
        ) {
          nat_category = true;
        }
      } else if (!filtered) {
        nat_category = true;
      }

      if (benchmark !== undefined && benchmark !== null && !nat_category) {
        percent_diff = Math.round(this.series.userOptions.cat_value - benchmark);

        if (percent_diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          percent_diff = Math.abs(percent_diff);
        }
      } else {
        percent_diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
        benchmark_label = '';
      }

      return makeMLITooltip(
        `${this.series.name} (${series_name}) `,
        Math.round(this.series.userOptions.cat_value),
        percent_diff,
        moe,
        text,
        benchmark_label,
        this.color,
        text_color,
        false
      );
    }

    function legendLabelFormatter() {
      let legend_item_label = `<div style: 'z-index: -999;'>${this.name}  (${this.userOptions.cat_value}%)`;
      let series_value;
      let series_name;

      if (parsedData.length > 1) {
        for (let i = 1; i < parsedData.length; i += 1) {
          series_value = parsedData[i][this.userOptions.series_index].cat_value;
          series_name = parsedData[i][this.userOptions.series_index].segment_name;

          legend_item_label = `${legend_item_label}<br><span style='font-weight:normal;opacity=0.7;'>${series_name} (${series_value}%)</span>`;
        }
      }

      return `${legend_item_label}</div>`;
    }

    useEffect(() => {
      if (displayOnDashboard) {
        const dashboard_series = regroupForWaffleDashboard(parsedData, 6, scheme?.discrete);
        const dashboard_theme = {
          title: {
            text: null,
            x: -5,
            y: -1,
          },
          chart: {
            width: 350,
            height: 250,
            marginLeft: 10,
            type: chartType,
          },
          legend: {
            layout: 'vertical',
            margin: 20,
            align: 'right',
            itemMarginTop: 0,
            itemMarginBottom: 0,
            verticalAlign: 'bottom',
            symbolRadius: 0,
            symbolWidth: 7,
            symbolHeight: 7,
            x: -10,
            y: -20,
            useHTML: true,
            itemWidth: 100,
            itemStyle: {
              color: '#404040',
              fontSize: '14px',
              fontWeight: 'heavy',
            },
            itemHoverStyle: {
              color: '#404040',
            },
            itemHiddenStyle: {
              color: '#404040',
            },
            labelFormatter: dashboardLegendLabelFormatter,
          },
          series: dashboard_series,
          tooltip: {
            enabled: false,
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            visible: false,
          },
          credits: { enabled: false },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
        };
        if (parsedData.every((w) => w.value === null)) {
          setChartOptions(null);
        } else {
          setChartOptions({ ...dashboard_theme });
        }
      } else if (!currentJob?.queryResult?.suppressed) {
        const series_data = regroupForWaffle(parsedData, 13);
        const default_theme = {
          chart: {
            width: 1200,
            height: 600,
            type: chartType,
            inverted: true,
            marginLeft: 50,

            // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
            style: {
              fontFamily: 'AvenirNext-Regular',
            },

            // plotBorderColor option is static
            plotBorderColor: '#f7f7f7',
          },

          // title formatting options will be static
          title: {
            text: null,
            align: 'left',
            style: {
              color: '#404040',
              fontSize: '14px',
            },
          },
          credits: { enabled: false },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            visible: false,
          },
          tooltip: {
            hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
            enabled: true,
            backgroundColor: 'rgba(255,255,255, 0.98)',
            useHTML: true,
            formatter: tooltipFormatter,
          },
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            y: -10,
            itemMarginTop: 10,
            itemWidth: 400,
            itemStyle: {
              fontSize: '14px',
              color: '#121111',
              textOverflow: 'wrap',
            },
            itemHoverStyle: {
              color: '#121111',
            },
            itemHiddenStyle: {
              color: '#121111',
            },
            useHTML: true,
            navigation: {
              activeColor: '#ac193c',
              buttonOptions: {
                enabled: false,
              },
            },
            labelFormatter: legendLabelFormatter,
          },
          plotOptions: {
            scatter: {
              events: {
                legendItemClick: () => {
                  return false;
                },
              },
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
          },
          series: series_data[0],
        };
        setChartOptions(default_theme);
        chartLoaded();
      } else {
        setChartOptions({ suppressed: true });
        chartLoaded();
      }
      setLoadingChart(false);
    }, [parsedData]);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    if (chartOptions?.suppressed) {
      return (
        <Alert className="m-3" variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
          visualization suppressed
        </Alert>
      );
    }
    if (chartOptions) {
      return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
    }
    return (
      <Alert className="m-3" variant="warning">
        <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data not
        available
      </Alert>
    );
  }
);

WaffleChart.displayName = 'WaffleChart';

export default React.memo(WaffleChart);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { renderGroup } from './helper';
import Validations from '../../Validations';

const Form = ({ prevStep, nextStep }) => {
  const wizard = useSelector((state) => ({
    providerSteps: state.wizard.providerSteps,
    step: state.wizard.step,
  }));
  let actualStep;

  const submitButton = useRef(null);

  if (wizard && wizard.providerSteps) {
    actualStep = wizard.providerSteps[`step${wizard.step}`];
  }

  const onSubmitHanlder = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <>
      {actualStep && actualStep.ui && (
        <>
          <form onSubmit={onSubmitHanlder}>
            {renderGroup(actualStep.ui)}
            <Validations
              formAction={actualStep.formAction}
              nextStep={nextStep}
              prevStep={prevStep}
              submitRef={submitButton}
            />
            <input type="submit" ref={submitButton} value="Submit" style={{ display: 'none' }} />
          </form>
        </>
      )}
    </>
  );
};

Form.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
};

Form.defaultProps = {
  prevStep: () => {},
  nextStep: () => {},
};

export default Form;

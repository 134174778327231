/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SectionTitle from '../Components/SectionTitle';
import Wizard from '../Components/Wizard';
import { setWizard } from '../actions/wizard.action';

const AddNewData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWizard('visualization'));
  }, []);

  return (
    <div className="AddNewData">
      <SectionTitle title="Add New Data" />
      <Wizard />
    </div>
  );
};

export default AddNewData;

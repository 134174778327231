export const snackBarParser = (snackBarType) => {
  switch (snackBarType) {
    case 'surveyConverted': {
      return 'Survey was published!';
    }
    case 'newSurvey': {
      return 'New Survey';
    }
    case 'visualizationConverted': {
      return 'Visualization was converted';
    }
    case 'newVisualization': {
      return 'Chart was updated';
    }
    case 'copyCreated': {
      return 'Copy was created';
    }
    default:
      return '';
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import SectionTitle from '../Components/SectionTitle';
import FetchScreen from '../Components/FetchScreen';
import VisualizationsContainer from '../Components/VisualizationsContainer';
import { getVisualizations, existDashboardData } from '../actions/visualization.action';

const Dashboard = () => {
  const visualizations = useSelector((state) => state.visualizations.visualizations);
  const isFetchingVisualizations = useSelector(
    (state) => state.visualizations.isFetchingVisualizations
  );
  const jwt = useSelector((state) => state.session.jwt);
  const dispatch = useDispatch();
  const visualizationsFetchErr = useSelector((state) => state.errors.visualizationsFetchErr);
  useEffect(() => {
    dispatch(getVisualizations(jwt));
    dispatch(existDashboardData(jwt));
  }, []);

  if (visualizationsFetchErr) {
    return (
      <FetchScreen
        title="There was an error while loading the page. Please try again later."
        type="error"
      />
    );
  }

  return (
    <div className="Dashboard">
      <SectionTitle
        title="Manage Dashboard"
        buttonText="Data Source Manager"
        buttonLink="/admin/dashboard/manager"
        buttonTheme="white"
        buttonIcon="/data-icon.svg"
      />

      {isFetchingVisualizations && (
        <Container>
          <FetchScreen />
        </Container>
      )}

      {!isFetchingVisualizations && (
        <>{visualizations && <VisualizationsContainer visualizations={visualizations} />}</>
      )}
    </div>
  );
};

export default Dashboard;

/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  error: '',
  modalProps: {
    open: false,
  },
  exportItems: [],
  loading: false,
};

const exportFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.EXPORT_MODAL_SHOW:
      return {
        ...state,
        modalProps: { open: true },
        exportItems: action.payload,
      };
    case types.reducerTypes.EXPORT_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case types.reducerTypes.EXPORT_SUCCESS:
      return initialState;
    case types.reducerTypes.EXPORT_ERROR:
      return {
        ...state,
        error: action.payload,
        modalProps: { open: false },
        loading: false,
      };
    case types.reducerTypes.EXPORT_MODAL_HIDE:
      return {
        ...state,
        modalProps: { open: false },
      };
    default:
  }
  return state;
};

export default exportFileReducer;

import React from 'react';
import { useDispatch } from 'react-redux';
import types from '../../../types';
import Form from '../Form/Form';

import { randS0ImportToBentoStatus } from '../../../actions/validation.action';

const Stepper = () => {
  const dispatch = useDispatch();

  const nextStep = () => {
    dispatch({ type: types.reducerTypes.SUM_STEP });
    dispatch(randS0ImportToBentoStatus());
  };

  const prevStep = () => {
    dispatch({ type: types.reducerTypes.DEC_STEP });
  };

  return (
    <>
      <Form nextStep={nextStep} prevStep={prevStep} />
    </>
  );
};

export default Stepper;

import React, { useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';

const CreateReportModal = ({ open, onRequestToClose, onConfirm }) => {
  const [reportName, setReportName] = useState('');

  const handleCreateButtonClick = (e) => {
    e.preventDefault();
    onConfirm(reportName);
    setReportName('');
    onRequestToClose();
  };

  return (
    <Modal
      centered
      show={open}
      backdrop="static"
      onHide={() => {
        setReportName('');
        onRequestToClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h2" className="font-weight-normal">Name your new report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-3 pb-4">
        <FormControl
          type="text"
          placeholder="[Name - Date - Referral]"
          aria-label="Report name input"
          value={reportName}
          onChange={(e) => setReportName(e.currentTarget.value)}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button onClick={handleCreateButtonClick}>Create</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateReportModal;

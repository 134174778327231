import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const ReportSearchInput = ({ onChange }) => {
  return (
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        type="text"
        placeholder="Search for a Report"
        aria-label="Search for a Report"
        onChange={onChange}
      />
    </InputGroup>
  );
};

export default ReportSearchInput;

import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';

const LineConfig = ({ onClickHandler, isChecked }) => (
  <Tabs
    type="line"
    onClickHandler={onClickHandler}
    isChecked={isChecked}
  />
);


LineConfig.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
};


export default LineConfig;

export const IN = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2753, 39.8093],
            [-86.2341, 39.776],
            [-86.2344, 39.7716],
            [-86.2446, 39.7766],
            [-86.2753, 39.8093],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810920',
        NAME: 'Speedway School Town',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12310570,
        AWATER: 20868,
        INTPTLAT: '+39.7936983',
        INTPTLON: '-086.2475418',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0823, 39.7304],
            [-86.0734, 39.724],
            [-86.0824, 39.6946],
            [-86.1113, 39.7233],
            [-86.0823, 39.7304],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800450',
        NAME: 'Beech Grove City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10867973,
        AWATER: 0,
        INTPTLAT: '+39.7152408',
        INTPTLON: '-086.0866047',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2267, 41.0889],
            [-85.226, 41.1778],
            [-85.0755, 41.1798],
            [-85.0024, 41.0917],
            [-85.1133, 41.0462],
            [-85.1097, 40.9173],
            [-85.2234, 40.917],
            [-85.2267, 41.0889],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803630',
        NAME: 'Fort Wayne Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374801597,
        AWATER: 583610,
        INTPTLAT: '+41.0678911',
        INTPTLON: '-085.1418582',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0755, 41.1798],
            [-85.0778, 41.2656],
            [-84.9626, 41.2677],
            [-84.8036, 41.2713],
            [-84.8035, 41.2526],
            [-84.8035, 41.1359],
            [-84.8033, 40.9894],
            [-84.8029, 40.9226],
            [-85.0739, 40.9178],
            [-85.1097, 40.9173],
            [-85.1133, 41.0462],
            [-85.0024, 41.0917],
            [-85.0755, 41.1798],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802850',
        NAME: 'East Allen County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 872793904,
        AWATER: 5546590,
        INTPTLAT: '+41.0849182',
        INTPTLON: '-084.9397987',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6868, 39.1309],
            [-85.5663, 39.1328],
            [-85.4401, 39.1956],
            [-85.4411, 39.1617],
            [-85.4449, 38.913],
            [-85.6106, 38.8242],
            [-85.6838, 38.8154],
            [-85.7399, 38.8193],
            [-85.7954, 38.8075],
            [-85.7963, 38.9024],
            [-85.7988, 39.0685],
            [-85.7998, 39.1289],
            [-85.6868, 39.1309],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805190',
        NAME: 'Jennings County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 975364217,
        AWATER: 4546213,
        INTPTLAT: '+38.9962339',
        INTPTLON: '-085.6281106',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9308, 40.9124],
            [-86.7109, 40.9122],
            [-86.6967, 40.9122],
            [-86.6966, 40.8628],
            [-86.8143, 40.8261],
            [-86.8332, 40.7217],
            [-86.928, 40.7209],
            [-86.9298, 40.8388],
            [-86.9864, 40.8379],
            [-86.9308, 40.9124],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808130',
        NAME: 'North White School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338703201,
        AWATER: 2680424,
        INTPTLAT: '+40.8364773',
        INTPTLON: '-086.8453331',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4234, 41.4386],
            [-85.3084, 41.4379],
            [-85.3094, 41.3516],
            [-85.4245, 41.3522],
            [-85.4241, 41.265],
            [-85.5363, 41.2952],
            [-85.5382, 41.4379],
            [-85.4234, 41.4386],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801710',
        NAME: 'Central Noble Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269514842,
        AWATER: 5608190,
        INTPTLAT: '+41.3578995',
        INTPTLON: '-085.4498806',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1685, 40.9961],
            [-86.13, 40.9961],
            [-85.9464, 40.9992],
            [-85.9447, 40.9428],
            [-85.9423, 40.8235],
            [-86.0542, 40.7862],
            [-86.1683, 40.7859],
            [-86.168, 40.8164],
            [-86.1685, 40.9961],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807890',
        NAME: 'North Miami Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402658807,
        AWATER: 3184297,
        INTPTLAT: '+40.9026614',
        INTPTLON: '-086.0651600',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.091, 39.953],
            [-87.0915, 40.039],
            [-86.968, 40.0392],
            [-86.8802, 40.0178],
            [-86.8664, 40.0396],
            [-86.6958, 40.0399],
            [-86.6951, 39.9228],
            [-86.6951, 39.8648],
            [-87.0095, 39.8667],
            [-87.0915, 39.8673],
            [-87.091, 39.953],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810450',
        NAME: 'South Montgomery Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629791506,
        AWATER: 1973562,
        INTPTLAT: '+39.9452179',
        INTPTLON: '-086.8931402',
        ELSDLEA: '',
        UNSDLEA: '10450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6205, 39.185],
            [-87.6041, 39.2595],
            [-87.2404, 39.2591],
            [-87.2409, 39.0844],
            [-87.2408, 38.9945],
            [-87.241, 38.9079],
            [-87.337, 38.9018],
            [-87.3154, 39.0389],
            [-87.3536, 39.1137],
            [-87.3156, 39.1718],
            [-87.6194, 39.1721],
            [-87.6205, 39.185],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808160',
        NAME: 'Northeast School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516395290,
        AWATER: 9804815,
        INTPTLAT: '+39.2210895',
        INTPTLON: '-087.2909975',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9167, 38.1566],
            [-87.8473, 38.0824],
            [-87.9579, 38.0838],
            [-87.9167, 38.1566],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807500',
        NAME: 'New Harmony Town and Township Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91310690,
        AWATER: 1768412,
        INTPTLAT: '+38.1091306',
        INTPTLON: '-087.9017918',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9333, 41.761],
            [-86.825, 41.7603],
            [-86.6997, 41.7598],
            [-86.6999, 41.6795],
            [-86.8164, 41.6798],
            [-86.8183, 41.607],
            [-86.932, 41.607],
            [-87.0096, 41.6817],
            [-86.9333, 41.761],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806570',
        NAME: 'Michigan City Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277282667,
        AWATER: 31090044,
        INTPTLAT: '+41.6899266',
        INTPTLON: '-086.8588150',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2229, 41.6326],
            [-87.1445, 41.6457],
            [-87.124, 41.5212],
            [-87.2206, 41.5216],
            [-87.2214, 41.5506],
            [-87.2218, 41.5651],
            [-87.222, 41.5935],
            [-87.2229, 41.6326],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809150',
        NAME: 'Portage Township Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91859604,
        AWATER: 5619847,
        INTPTLAT: '+41.5778255',
        INTPTLON: '-087.1758181',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2196, 41.4346],
            [-87.1237, 41.4346],
            [-87.0851, 41.4346],
            [-87.0655, 41.3475],
            [-87.2198, 41.3473],
            [-87.2196, 41.4346],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809180',
        NAME: 'Porter Township School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116126188,
        AWATER: 835917,
        INTPTLAT: '+41.3866114',
        INTPTLON: '-087.1394811',
        ELSDLEA: '',
        UNSDLEA: '09180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.124, 41.5212],
            [-87.1237, 41.4346],
            [-87.2196, 41.4346],
            [-87.2202, 41.5071],
            [-87.2206, 41.5216],
            [-87.124, 41.5212],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811700',
        NAME: 'Union Township School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76331160,
        AWATER: 1221147,
        INTPTLAT: '+41.4742233',
        INTPTLON: '-087.1764637',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.124, 41.5212],
            [-87.0269, 41.5211],
            [-87.0851, 41.4346],
            [-87.1237, 41.4346],
            [-87.124, 41.5212],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811970',
        NAME: 'Valparaiso Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76510695,
        AWATER: 1049513,
        INTPTLAT: '+41.4759657',
        INTPTLON: '-087.0741680',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6194, 39.1721],
            [-87.3156, 39.1718],
            [-87.3536, 39.1137],
            [-87.3154, 39.0389],
            [-87.337, 38.9018],
            [-87.5229, 38.9024],
            [-87.6107, 39.0812],
            [-87.6194, 39.1721],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810860',
        NAME: 'Southwest School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 641596860,
        AWATER: 8357679,
        INTPTLAT: '+39.0478988',
        INTPTLON: '-087.4545691',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0933, 40.3079],
            [-84.9008, 40.3094],
            [-84.9017, 40.121],
            [-85.0351, 40.1209],
            [-85.1111, 40.1214],
            [-85.0933, 40.3079],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801770',
        NAME: 'Randolph Central School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350035994,
        AWATER: 932813,
        INTPTLAT: '+40.2145899',
        INTPTLON: '-085.0085554',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9017, 40.121],
            [-84.9008, 40.3094],
            [-84.8037, 40.3101],
            [-84.8085, 40.1072],
            [-84.9017, 40.121],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803180',
        NAME: 'Randolph Eastern School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181453798,
        AWATER: 247297,
        INTPTLAT: '+40.2133203',
        INTPTLON: '-084.8554602',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0351, 40.1209],
            [-84.9017, 40.121],
            [-84.8085, 40.1072],
            [-84.8089, 40.0971],
            [-84.809, 40.0857],
            [-84.8108, 40.0051],
            [-85.0345, 40.0048],
            [-85.0351, 40.1209],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810740',
        NAME: 'Randolph Southern School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234128830,
        AWATER: 315357,
        INTPTLAT: '+40.0576102',
        INTPTLON: '-084.9209718',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8602, 40.3057],
            [-85.7856, 40.3063],
            [-85.8619, 40.2189],
            [-85.8602, 40.3057],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803300',
        NAME: 'Elwood Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36859964,
        AWATER: 0,
        INTPTLAT: '+40.2756022',
        INTPTLON: '-085.8318119',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8147, 37.9987],
            [-86.7723, 37.9983],
            [-86.6419, 37.999],
            [-86.6724, 37.9147],
            [-86.7035, 37.9013],
            [-86.755, 37.9191],
            [-86.8147, 37.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811260',
        NAME: 'Tell City-Troy Township School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106976975,
        AWATER: 2915609,
        INTPTLAT: '+37.9609680',
        INTPTLON: '-086.7152274',
        ELSDLEA: '',
        UNSDLEA: '11260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.755, 37.9191],
            [-86.7035, 37.9013],
            [-86.7307, 37.8941],
            [-86.755, 37.9191],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801170',
        NAME: 'Cannelton City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4573132,
        AWATER: 381774,
        INTPTLAT: '+37.9081653',
        INTPTLON: '-086.7332384',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8619, 40.2189],
            [-85.7856, 40.3063],
            [-85.7471, 40.3063],
            [-85.6714, 40.2195],
            [-85.7487, 40.135],
            [-85.7492, 40.0465],
            [-85.8624, 40.0316],
            [-85.8633, 40.1039],
            [-85.8619, 40.2189],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812880',
        NAME: 'Frankton-Lapel Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319817789,
        AWATER: 356765,
        INTPTLAT: '+40.1568832',
        INTPTLON: '-085.7763291',
        ELSDLEA: '',
        UNSDLEA: '12880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6714, 40.2195],
            [-85.5756, 40.2189],
            [-85.576, 40.1496],
            [-85.5762, 40.0771],
            [-85.5764, 40.0478],
            [-85.7492, 40.0465],
            [-85.7487, 40.135],
            [-85.6714, 40.2195],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800150',
        NAME: 'Anderson Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222176477,
        AWATER: 950122,
        INTPTLAT: '+40.1276816',
        INTPTLON: '-085.6488683',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3393, 40.2232],
            [-87.2449, 40.3093],
            [-87.0921, 40.3666],
            [-87.0927, 40.2584],
            [-87.1863, 40.2583],
            [-87.2051, 40.1857],
            [-87.3005, 40.1861],
            [-87.3393, 40.2232],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800210',
        NAME: 'Attica Consolidated School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208360133,
        AWATER: 1815016,
        INTPTLAT: '+40.2702354',
        INTPTLON: '-087.2194964',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6834, 38.7726],
            [-86.4671, 38.7985],
            [-86.3101, 38.7331],
            [-86.3087, 38.6881],
            [-86.5906, 38.6871],
            [-86.6831, 38.6866],
            [-86.6834, 38.7556],
            [-86.6836, 38.7677],
            [-86.6834, 38.7726],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806900',
        NAME: 'Mitchell Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375366069,
        AWATER: 3194567,
        INTPTLAT: '+38.7472010',
        INTPTLON: '-086.5181356',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7915, 38.2051],
            [-86.6795, 38.2631],
            [-86.5717, 38.2075],
            [-86.4604, 38.2068],
            [-86.461, 38.1211],
            [-86.4909, 38.0457],
            [-86.5071, 37.9299],
            [-86.6038, 37.8593],
            [-86.6553, 37.8425],
            [-86.6724, 37.9147],
            [-86.6419, 37.999],
            [-86.7723, 37.9983],
            [-86.7915, 38.2051],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801740',
        NAME: 'Perry Central Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 877118522,
        AWATER: 8520272,
        INTPTLAT: '+38.1037973',
        INTPTLON: '-086.6277202',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5331, 39.8233],
            [-87.3854, 39.8242],
            [-87.3824, 39.6079],
            [-87.5322, 39.6073],
            [-87.5328, 39.6945],
            [-87.5331, 39.8233],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810590',
        NAME: 'South Vermillion Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302750329,
        AWATER: 4242952,
        INTPTLAT: '+39.7202571',
        INTPTLON: '-087.4512306',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2199, 40.379],
            [-85.2011, 40.5672],
            [-85.0685, 40.5682],
            [-84.8025, 40.5722],
            [-84.8035, 40.4654],
            [-84.8038, 40.3436],
            [-84.8037, 40.3101],
            [-84.9008, 40.3094],
            [-85.0933, 40.3079],
            [-85.2188, 40.3067],
            [-85.2199, 40.379],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804980',
        NAME: 'Jay School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 994310101,
        AWATER: 458556,
        INTPTLAT: '+40.4349719',
        INTPTLON: '-085.0033804',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9579, 38.0838],
            [-87.8473, 38.0824],
            [-87.8491, 38.0241],
            [-87.6983, 38.0075],
            [-87.7008, 37.8976],
            [-87.833, 37.8771],
            [-87.9271, 37.9017],
            [-87.9069, 37.8076],
            [-88.028, 37.7992],
            [-88.0922, 37.8909],
            [-88.0134, 37.8949],
            [-88.0341, 38.032],
            [-87.9579, 38.0838],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807290',
        NAME: 'Mount Vernon Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602017709,
        AWATER: 21344041,
        INTPTLAT: '+37.9417654',
        INTPTLON: '-087.9036257',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9837, 38.2304],
            [-87.7992, 38.2286],
            [-87.6884, 38.1684],
            [-87.6983, 38.0075],
            [-87.8491, 38.0241],
            [-87.8473, 38.0824],
            [-87.9167, 38.1566],
            [-87.9853, 38.2155],
            [-87.9837, 38.2304],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807950',
        NAME: 'North Posey County Schools Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 367459401,
        AWATER: 2146927,
        INTPTLAT: '+38.1329522',
        INTPTLON: '-087.8013752',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2499, 39.6336],
            [-86.2429, 39.6337],
            [-86.2393, 39.6338],
            [-86.2339, 39.6339],
            [-86.1413, 39.6353],
            [-86.1208, 39.5933],
            [-86.1393, 39.5343],
            [-86.2503, 39.5167],
            [-86.2499, 39.6336],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801440',
        NAME: 'Center Grove Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123893297,
        AWATER: 815067,
        INTPTLAT: '+39.5762986',
        INTPTLON: '-086.1944332',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2516, 39.5167],
            [-86.2503, 39.5167],
            [-86.1393, 39.5343],
            [-85.9519, 39.5379],
            [-85.9514, 39.5231],
            [-85.9525, 39.4359],
            [-86.0261, 39.4341],
            [-86.2513, 39.4306],
            [-86.2516, 39.5167],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803690',
        NAME: 'Franklin Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276248405,
        AWATER: 127945,
        INTPTLAT: '+39.4818333',
        INTPTLON: '-086.0998833',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0724, 38.5147],
            [-87.0355, 38.5123],
            [-86.9425, 38.4926],
            [-86.8868, 38.4375],
            [-86.8859, 38.3507],
            [-86.9052, 38.3357],
            [-87.0726, 38.3347],
            [-87.0724, 38.5147],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803960',
        NAME: 'Greater Jasper Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267545677,
        AWATER: 3430227,
        INTPTLAT: '+38.4164657',
        INTPTLON: '-086.9878027',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0726, 38.3347],
            [-86.9052, 38.3357],
            [-86.9237, 38.203],
            [-87.0175, 38.2036],
            [-87.0731, 38.2326],
            [-87.0726, 38.3347],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810870',
        NAME: 'Southwest Dubois County School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201501765,
        AWATER: 2308253,
        INTPTLAT: '+38.2742580',
        INTPTLON: '-086.9869612',
        ELSDLEA: '',
        UNSDLEA: '10870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9425, 38.4926],
            [-86.9242, 38.5054],
            [-86.8314, 38.5264],
            [-86.6824, 38.5264],
            [-86.6814, 38.3948],
            [-86.6809, 38.351],
            [-86.8859, 38.3507],
            [-86.8868, 38.4375],
            [-86.9425, 38.4926],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808120',
        NAME: 'Northeast Dubois County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359499165,
        AWATER: 13323893,
        INTPTLAT: '+38.4380444',
        INTPTLON: '-086.7958103',
        ELSDLEA: '',
        UNSDLEA: '08120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9298, 41.519],
            [-86.8168, 41.5176],
            [-86.7209, 41.5175],
            [-86.6425, 41.4326],
            [-86.7118, 41.3753],
            [-86.8139, 41.4328],
            [-86.9302, 41.4341],
            [-86.9298, 41.519],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802190',
        NAME: 'South Central Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238750502,
        AWATER: 483332,
        INTPTLAT: '+41.4559662',
        INTPTLON: '-086.8008144',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.932, 41.607],
            [-86.8183, 41.607],
            [-86.8168, 41.5176],
            [-86.9298, 41.519],
            [-86.932, 41.607],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807470',
        NAME: 'New Durham Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93142409,
        AWATER: 265248,
        INTPTLAT: '+41.5592453',
        INTPTLON: '-086.8723126',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8883, 38.7344],
            [-85.8696, 38.7262],
            [-85.7384, 38.708],
            [-85.7399, 38.8193],
            [-85.6838, 38.8154],
            [-85.6833, 38.7367],
            [-85.6087, 38.7079],
            [-85.57, 38.6062],
            [-85.7238, 38.6055],
            [-85.8479, 38.5613],
            [-85.8881, 38.6488],
            [-85.8883, 38.7344],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810020',
        NAME: 'Scott County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412384454,
        AWATER: 5938143,
        INTPTLAT: '+38.6592216',
        INTPTLON: '-085.7371891',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4673, 38.1654],
            [-87.4667, 38.2013],
            [-87.317, 38.2018],
            [-87.3169, 38.2461],
            [-87.0731, 38.2326],
            [-87.0175, 38.2036],
            [-87.0175, 38.1183],
            [-87.1947, 37.9896],
            [-87.269, 37.878],
            [-87.3029, 37.8977],
            [-87.4512, 37.941],
            [-87.4673, 38.1654],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812390',
        NAME: 'Warrick County School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 996945714,
        AWATER: 15874469,
        INTPTLAT: '+38.0977282',
        INTPTLON: '-087.2720486',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4679, 40.692],
            [-86.3915, 40.6924],
            [-86.3744, 40.5614],
            [-86.3755, 40.4926],
            [-86.3758, 40.4319],
            [-86.4685, 40.431],
            [-86.5815, 40.4822],
            [-86.5824, 40.5619],
            [-86.4679, 40.5614],
            [-86.4679, 40.692],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801290',
        NAME: 'Carroll Consolidated School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368645262,
        AWATER: 118567,
        INTPTLAT: '+40.5396816',
        INTPTLON: '-086.4632234',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6966, 40.8628],
            [-86.5809, 40.8626],
            [-86.5817, 40.7358],
            [-86.5252, 40.7172],
            [-86.6554, 40.6491],
            [-86.7726, 40.6492],
            [-86.8332, 40.7217],
            [-86.8143, 40.8261],
            [-86.6966, 40.8628],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811580',
        NAME: 'Twin Lakes School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412152440,
        AWATER: 9709157,
        INTPTLAT: '+40.7456488',
        INTPTLON: '-086.6923485',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7954, 38.8075],
            [-85.7399, 38.8193],
            [-85.7384, 38.708],
            [-85.8696, 38.7262],
            [-85.7954, 38.8075],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809990',
        NAME: 'Scott County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80741590,
        AWATER: 150858,
        INTPTLAT: '+38.7623081',
        INTPTLON: '-085.7811539',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2759, 41.2186],
            [-87.2189, 41.242],
            [-87.1023, 41.281],
            [-86.9301, 41.2368],
            [-86.9304, 41.1583],
            [-87.0076, 41.0857],
            [-87.2759, 41.0712],
            [-87.2759, 41.2186],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805280',
        NAME: 'Kankakee Valley School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525174891,
        AWATER: 3518716,
        INTPTLAT: '+41.1663188',
        INTPTLON: '-087.1133452',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5567, 39.3505],
            [-85.3855, 39.367],
            [-85.3883, 39.2496],
            [-85.5175, 39.2352],
            [-85.5567, 39.3505],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804080',
        NAME: 'Greensburg Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203126425,
        AWATER: 545431,
        INTPTLAT: '+39.3182060',
        INTPTLON: '-085.4672077',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0175, 38.2036],
            [-86.9237, 38.203],
            [-86.7915, 38.2051],
            [-86.7723, 37.9983],
            [-86.8147, 37.9987],
            [-86.9073, 37.943],
            [-87.1499, 37.9596],
            [-87.1947, 37.9896],
            [-87.0175, 38.1183],
            [-87.0175, 38.2036],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808010',
        NAME: 'North Spencer County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670151545,
        AWATER: 7680655,
        INTPTLAT: '+38.0702452',
        INTPTLON: '-086.9555842',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1947, 37.9896],
            [-87.1499, 37.9596],
            [-86.9073, 37.943],
            [-86.9788, 37.9302],
            [-87.0342, 37.9063],
            [-87.0992, 37.7845],
            [-87.1288, 37.7863],
            [-87.1647, 37.8414],
            [-87.269, 37.878],
            [-87.1947, 37.9896],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810560',
        NAME: 'South Spencer County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357538132,
        AWATER: 4319347,
        INTPTLAT: '+37.9032322',
        INTPTLON: '-087.1179535',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9524, 39.7828],
            [-85.8399, 39.7841],
            [-85.7264, 39.7573],
            [-85.7254, 39.6989],
            [-85.8389, 39.6984],
            [-85.9517, 39.6971],
            [-85.9517, 39.7264],
            [-85.9524, 39.7828],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810710',
        NAME: 'Southern Hancock County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154194438,
        AWATER: 270892,
        INTPTLAT: '+39.7349065',
        INTPTLON: '-085.8515314',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.928, 40.7209],
            [-86.8332, 40.7217],
            [-86.7726, 40.6492],
            [-86.7713, 40.5621],
            [-86.9815, 40.562],
            [-86.9857, 40.7212],
            [-86.928, 40.7209],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803810',
        NAME: 'Frontier School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297444440,
        AWATER: 55845,
        INTPTLAT: '+40.6348308',
        INTPTLON: '-086.8857148',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0095, 39.8667],
            [-86.6951, 39.8648],
            [-86.6868, 39.8197],
            [-86.6871, 39.7172],
            [-86.6872, 39.6881],
            [-86.7937, 39.6889],
            [-87.0119, 39.6919],
            [-87.0095, 39.8667],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807980',
        NAME: 'North Putnam Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538862853,
        AWATER: 2780307,
        INTPTLAT: '+39.7798445',
        INTPTLON: '-086.8494378',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5314, 40.1483],
            [-87.531, 40.1772],
            [-87.428, 40.1783],
            [-87.3393, 40.2232],
            [-87.3005, 40.1861],
            [-87.3185, 39.9528],
            [-87.4202, 39.9525],
            [-87.4067, 40.1275],
            [-87.5314, 40.1481],
            [-87.5314, 40.1483],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802430',
        NAME: 'Covington Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320507265,
        AWATER: 3459009,
        INTPTLAT: '+40.0903542',
        INTPTLON: '-087.3826180',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3005, 40.1861],
            [-87.2051, 40.1857],
            [-87.1863, 40.2583],
            [-87.0927, 40.2584],
            [-87.0926, 40.2148],
            [-87.0915, 40.039],
            [-87.091, 39.953],
            [-87.3185, 39.9528],
            [-87.3005, 40.1861],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810620',
        NAME: 'Southeast Fountain School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538814760,
        AWATER: 1024400,
        INTPTLAT: '+40.0914877',
        INTPTLON: '-087.1937125',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6876, 39.5219],
            [-86.6566, 39.6014],
            [-86.5598, 39.6009],
            [-86.5403, 39.4988],
            [-86.6319, 39.4411],
            [-86.6857, 39.4701],
            [-86.6876, 39.5219],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803330',
        NAME: 'Eminence Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159459252,
        AWATER: 318554,
        INTPTLAT: '+39.5178210',
        INTPTLON: '-086.6086111',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5598, 39.6009],
            [-86.4371, 39.6303],
            [-86.4179, 39.6305],
            [-86.4176, 39.5568],
            [-86.5403, 39.4988],
            [-86.5598, 39.6009],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804140',
        NAME: 'Monroe-Gregg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134446363,
        AWATER: 855600,
        INTPTLAT: '+39.5643426',
        INTPTLON: '-086.4941294',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3181, 39.0488],
            [-86.0803, 39.0503],
            [-86.0617, 39.036],
            [-86.0553, 38.9034],
            [-85.9632, 38.9345],
            [-85.9615, 38.8231],
            [-85.8844, 38.822],
            [-85.9084, 38.7562],
            [-86.0917, 38.7834],
            [-86.1667, 38.7734],
            [-86.1315, 38.862],
            [-86.2771, 38.8596],
            [-86.3175, 38.9909],
            [-86.3181, 39.0488],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801050',
        NAME: 'Brownstown Central Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666705482,
        AWATER: 5882609,
        INTPTLAT: '+38.9019798',
        INTPTLON: '-086.1176747',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9084, 38.7562],
            [-85.8844, 38.822],
            [-85.7963, 38.9024],
            [-85.7954, 38.8075],
            [-85.8696, 38.7262],
            [-85.8883, 38.7344],
            [-85.9084, 38.7562],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812060',
        NAME: 'Crothersville Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111454559,
        AWATER: 102184,
        INTPTLAT: '+38.8121462',
        INTPTLON: '-085.8438724',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0617, 39.036],
            [-85.8606, 39.0409],
            [-85.7988, 39.0685],
            [-85.7963, 38.9024],
            [-85.8844, 38.822],
            [-85.9615, 38.8231],
            [-85.9632, 38.9345],
            [-86.0553, 38.9034],
            [-86.0617, 39.036],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810080',
        NAME: 'Seymour Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433928349,
        AWATER: 4298287,
        INTPTLAT: '+38.9557447',
        INTPTLON: '-085.9230325',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2227, 40.7426],
            [-85.071, 40.7431],
            [-85.0696, 40.6868],
            [-85.0704, 40.6555],
            [-85.2213, 40.6546],
            [-85.2227, 40.7426],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800720',
        NAME: 'Bluffton-Harrison Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123879916,
        AWATER: 1108990,
        INTPTLAT: '+40.6950849',
        INTPTLON: '-085.1468568',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4488, 40.6536],
            [-85.3347, 40.6544],
            [-85.335, 40.742],
            [-85.2227, 40.7426],
            [-85.2213, 40.6546],
            [-85.0704, 40.6555],
            [-85.0685, 40.5682],
            [-85.2011, 40.5672],
            [-85.447, 40.5669],
            [-85.4488, 40.6536],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810770',
        NAME: 'Southern Wells Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401722182,
        AWATER: 1604507,
        INTPTLAT: '+40.6323799',
        INTPTLON: '-085.2688901',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1886, 39.2209],
            [-85.0849, 39.2063],
            [-85.1196, 39.0172],
            [-85.1856, 39.0159],
            [-85.1872, 39.1337],
            [-85.1886, 39.2209],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806630',
        NAME: 'Milan Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170559060,
        AWATER: 187477,
        INTPTLAT: '+39.1303792',
        INTPTLON: '-085.1477120',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4075, 38.3925],
            [-87.3159, 38.375],
            [-87.3169, 38.2461],
            [-87.317, 38.2018],
            [-87.4667, 38.2013],
            [-87.5026, 38.2893],
            [-87.501, 38.3992],
            [-87.4075, 38.3925],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802940',
        NAME: 'East Gibson School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303760015,
        AWATER: 2303960,
        INTPTLAT: '+38.2959597',
        INTPTLON: '-087.4045460',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2759, 41.0712],
            [-87.0076, 41.0857],
            [-86.9889, 40.9781],
            [-86.9308, 40.9124],
            [-86.9864, 40.8379],
            [-87.2678, 40.8223],
            [-87.2764, 40.9115],
            [-87.2759, 41.0712],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809420',
        NAME: 'Rensselaer Central School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 689811350,
        AWATER: 796919,
        INTPTLAT: '+40.9499229',
        INTPTLON: '-087.1311923',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7577, 41.0851],
            [-86.6598, 41.1721],
            [-86.5828, 41.172],
            [-86.5834, 41.0846],
            [-86.4338, 41.0843],
            [-86.4335, 41.026],
            [-86.4685, 40.9099],
            [-86.6967, 40.9122],
            [-86.7109, 40.9122],
            [-86.6995, 41.0203],
            [-86.7577, 41.0851],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807110',
        NAME: 'Eastern Pulaski Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528966456,
        AWATER: 2676015,
        INTPTLAT: '+41.0244661',
        INTPTLON: '-086.5988063',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4411, 39.1617],
            [-85.3856, 39.1111],
            [-85.2408, 39.1034],
            [-85.1872, 39.1337],
            [-85.1856, 39.0159],
            [-85.1196, 39.0172],
            [-85.1325, 38.9481],
            [-85.1358, 38.9296],
            [-85.2032, 38.9138],
            [-85.4449, 38.913],
            [-85.4411, 39.1617],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810530',
        NAME: 'South Ripley Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567075014,
        AWATER: 2000694,
        INTPTLAT: '+39.0195090',
        INTPTLON: '-085.2914857',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.336, 40.9171],
            [-85.2234, 40.917],
            [-85.1097, 40.9173],
            [-85.0739, 40.9178],
            [-85.0722, 40.8309],
            [-85.071, 40.7431],
            [-85.2227, 40.7426],
            [-85.335, 40.742],
            [-85.336, 40.9171],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808220',
        NAME: 'Northern Wells Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427739176,
        AWATER: 2882879,
        INTPTLAT: '+40.8186133',
        INTPTLON: '-085.1974498',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6958, 40.1787],
            [-86.4318, 40.177],
            [-86.3565, 40.1781],
            [-86.3564, 40.0983],
            [-86.5347, 40.0973],
            [-86.5542, 39.9956],
            [-86.451, 39.9962],
            [-86.4498, 39.9233],
            [-86.6951, 39.9228],
            [-86.6958, 40.0399],
            [-86.6958, 40.1787],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812990',
        NAME: 'Western Boone County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569745150,
        AWATER: 488973,
        INTPTLAT: '+40.0619394',
        INTPTLON: '-086.5685580',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9447, 40.9428],
            [-85.7935, 40.9418],
            [-85.6423, 40.9147],
            [-85.6386, 40.6531],
            [-85.6755, 40.6528],
            [-85.9395, 40.651],
            [-85.9418, 40.7725],
            [-85.9423, 40.8235],
            [-85.9447, 40.9428],
          ],
          [
            [-85.8507, 40.803],
            [-85.8136, 40.7794],
            [-85.8017, 40.8151],
            [-85.8507, 40.803],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812180',
        NAME: 'Wabash County Schools Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 747495706,
        AWATER: 19506913,
        INTPTLAT: '+40.7920664',
        INTPTLON: '-085.8000835',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3814, 39.3398],
            [-86.3623, 39.3401],
            [-86.0948, 39.344],
            [-86.0818, 39.2263],
            [-86.0803, 39.0503],
            [-86.3181, 39.0488],
            [-86.3701, 39.0484],
            [-86.3814, 39.3398],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800960',
        NAME: 'Brown County County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 808029632,
        AWATER: 12040223,
        INTPTLAT: '+39.1928644',
        INTPTLON: '-086.2397283',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.447, 40.5669],
            [-85.2011, 40.5672],
            [-85.2199, 40.379],
            [-85.4443, 40.3787],
            [-85.4443, 40.3791],
            [-85.447, 40.5669],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800570',
        NAME: 'Blackford County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427554635,
        AWATER: 1303566,
        INTPTLAT: '+40.4726717',
        INTPTLON: '-085.3237297',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2164, 39.8238],
            [-86.0838, 39.8342],
            [-86.0839, 39.8473],
            [-86.0821, 39.8551],
            [-85.972, 39.8265],
            [-86.0648, 39.8255],
            [-86.0823, 39.7304],
            [-86.1113, 39.7233],
            [-86.1963, 39.7163],
            [-86.1962, 39.7216],
            [-86.2341, 39.776],
            [-86.2753, 39.8093],
            [-86.2758, 39.8236],
            [-86.2164, 39.8238],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804770',
        NAME: 'Indianapolis Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203324401,
        AWATER: 2619474,
        INTPTLAT: '+39.7930964',
        INTPTLON: '-086.1360915',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3273, 39.8225],
            [-86.2758, 39.8236],
            [-86.2753, 39.8093],
            [-86.2446, 39.7766],
            [-86.2344, 39.7716],
            [-86.2341, 39.776],
            [-86.1962, 39.7216],
            [-86.3266, 39.7195],
            [-86.3268, 39.8008],
            [-86.3273, 39.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812810',
        NAME: 'Wayne Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87575622,
        AWATER: 818993,
        INTPTLAT: '+39.7614881',
        INTPTLON: '-086.2746698',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9521, 39.6383],
            [-85.9517, 39.6971],
            [-85.8389, 39.6984],
            [-85.8002, 39.626],
            [-85.8366, 39.5251],
            [-85.9514, 39.5231],
            [-85.9519, 39.5379],
            [-85.9521, 39.6383],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808310',
        NAME: 'Northwestern Consolidated School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214099937,
        AWATER: 925558,
        INTPTLAT: '+39.6055417',
        INTPTLON: '-085.8846217',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1358, 38.9296],
            [-85.1325, 38.9481],
            [-84.8776, 39.0312],
            [-84.8352, 38.9579],
            [-84.8707, 38.9009],
            [-85.1403, 38.9032],
            [-85.1358, 38.9296],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809600',
        NAME: 'Rising Sun-Ohio County Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223101879,
        AWATER: 3352213,
        INTPTLAT: '+38.9405270',
        INTPTLON: '-084.9642994',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0626, 41.7605],
            [-85.9261, 41.7597],
            [-85.8776, 41.7593],
            [-85.8191, 41.7591],
            [-85.774, 41.7591],
            [-85.7733, 41.696],
            [-85.8884, 41.6951],
            [-86.0031, 41.6634],
            [-86.0614, 41.6805],
            [-86.0626, 41.7605],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803270',
        NAME: 'Elkhart Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195203537,
        AWATER: 7527546,
        INTPTLAT: '+41.7221000',
        INTPTLON: '-085.9304069',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6748, 40.6102],
            [-85.5601, 40.5669],
            [-85.5592, 40.5095],
            [-85.6727, 40.4803],
            [-85.7295, 40.4801],
            [-85.6748, 40.6102],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806390',
        NAME: 'Marion Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145212567,
        AWATER: 817029,
        INTPTLAT: '+40.5467502',
        INTPTLON: '-085.6533726',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5592, 40.5095],
            [-85.5583, 40.4571],
            [-85.6727, 40.4803],
            [-85.5592, 40.5095],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806870',
        NAME: 'Mississinewa Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60033856,
        AWATER: 16191,
        INTPTLAT: '+40.4834260',
        INTPTLON: '-085.6170596',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6814, 38.3948],
            [-86.5695, 38.396],
            [-86.3089, 38.3941],
            [-86.3087, 38.4229],
            [-86.255, 38.4227],
            [-86.2767, 38.2341],
            [-86.3296, 38.1818],
            [-86.3321, 38.1299],
            [-86.461, 38.1211],
            [-86.4604, 38.2068],
            [-86.5717, 38.2075],
            [-86.6795, 38.2631],
            [-86.6809, 38.351],
            [-86.6814, 38.3948],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802440',
        NAME: 'Crawford County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793388749,
        AWATER: 7974816,
        INTPTLAT: '+38.2894327',
        INTPTLON: '-086.4408711',
        ELSDLEA: '',
        UNSDLEA: '02440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0614, 41.6805],
            [-86.0031, 41.6634],
            [-86.0024, 41.6089],
            [-86.0607, 41.6084],
            [-86.0614, 41.6805],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800420',
        NAME: 'Baugo Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37966493,
        AWATER: 819476,
        INTPTLAT: '+41.6415495',
        INTPTLON: '-086.0286099',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8858, 41.5223],
            [-85.7702, 41.5228],
            [-85.7719, 41.6091],
            [-85.6567, 41.6105],
            [-85.6547, 41.5233],
            [-85.6535, 41.4369],
            [-85.8858, 41.4356],
            [-85.8858, 41.5223],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803480',
        NAME: 'Fairfield Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279345712,
        AWATER: 458621,
        INTPTLAT: '+41.5013794',
        INTPTLON: '-085.7291207',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9464, 40.9992],
            [-85.9075, 41.0429],
            [-85.7958, 41.0436],
            [-85.6842, 41.0467],
            [-85.6438, 41.0023],
            [-85.6423, 40.9147],
            [-85.7935, 40.9418],
            [-85.9447, 40.9428],
            [-85.9464, 40.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806270',
        NAME: 'Manchester Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309023941,
        AWATER: 2378081,
        INTPTLAT: '+40.9794071',
        INTPTLON: '-085.7889257',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8507, 40.803],
            [-85.8017, 40.8151],
            [-85.8136, 40.7794],
            [-85.8507, 40.803],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812150',
        NAME: 'Wabash City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11686571,
        AWATER: 230994,
        INTPTLAT: '+40.7982051',
        INTPTLON: '-085.8251960',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8872, 41.6087],
            [-85.8702, 41.6087],
            [-85.7719, 41.6091],
            [-85.7702, 41.5228],
            [-85.8858, 41.5223],
            [-85.8872, 41.6087],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803930',
        NAME: 'Goshen Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90733299,
        AWATER: 1719559,
        INTPTLAT: '+41.5658645',
        INTPTLON: '-085.8319585',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8884, 41.6951],
            [-85.7733, 41.696],
            [-85.774, 41.7591],
            [-85.6826, 41.759],
            [-85.6598, 41.7591],
            [-85.6567, 41.6105],
            [-85.7719, 41.6091],
            [-85.8702, 41.6087],
            [-85.8884, 41.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806600',
        NAME: 'Middlebury Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247846446,
        AWATER: 1180974,
        INTPTLAT: '+41.6752746',
        INTPTLON: '-085.7621647',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.683, 39.1657],
            [-86.5735, 39.1646],
            [-86.5743, 39.3397],
            [-86.3814, 39.3398],
            [-86.3701, 39.0484],
            [-86.3181, 39.0488],
            [-86.3175, 38.9909],
            [-86.6823, 38.9924],
            [-86.683, 39.1657],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800630',
        NAME: 'Monroe County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 835914456,
        AWATER: 43475575,
        INTPTLAT: '+39.1403228',
        INTPTLON: '-086.5003502',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6308, 39.3469],
            [-86.5743, 39.3397],
            [-86.5735, 39.1646],
            [-86.683, 39.1657],
            [-86.6856, 39.336],
            [-86.6308, 39.3469],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809480',
        NAME: 'Richland-Bean Blossom Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185897302,
        AWATER: 28679,
        INTPTLAT: '+39.2597281',
        INTPTLON: '-086.6296760',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6325, 39.6985],
            [-85.5215, 39.6989],
            [-85.4941, 39.7872],
            [-85.3016, 39.7876],
            [-85.2981, 39.5255],
            [-85.2976, 39.4533],
            [-85.6293, 39.4528],
            [-85.6325, 39.6985],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809750',
        NAME: 'Rush County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 964624641,
        AWATER: 409968,
        INTPTLAT: '+39.6075818',
        INTPTLON: '-085.4541773',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4202, 39.9525],
            [-87.3185, 39.9528],
            [-87.091, 39.953],
            [-87.0915, 39.8673],
            [-87.0095, 39.8667],
            [-87.0119, 39.6919],
            [-87.1232, 39.6938],
            [-87.29, 39.6959],
            [-87.2924, 39.8533],
            [-87.368, 39.8676],
            [-87.4202, 39.9525],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800118',
        NAME: 'North Central Parke Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 702610757,
        AWATER: 10726925,
        INTPTLAT: '+39.8256080',
        INTPTLON: '-087.1808141',
        ELSDLEA: '',
        UNSDLEA: '00118',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.94, 39.4733],
            [-86.9033, 39.5304],
            [-86.6876, 39.5219],
            [-86.6857, 39.4701],
            [-86.705, 39.3987],
            [-86.9423, 39.3999],
            [-86.94, 39.4733],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802220',
        NAME: 'Cloverdale Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277281107,
        AWATER: 6050646,
        INTPTLAT: '+39.4657293',
        INTPTLON: '-086.8037590',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9423, 39.3999],
            [-86.705, 39.3987],
            [-86.6857, 39.4701],
            [-86.6319, 39.4411],
            [-86.6308, 39.3469],
            [-86.6856, 39.336],
            [-86.683, 39.1657],
            [-86.8321, 39.167],
            [-86.8931, 39.1679],
            [-87.0546, 39.1681],
            [-87.0536, 39.3426],
            [-86.9425, 39.342],
            [-86.9423, 39.3999],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810950',
        NAME: 'Spencer-Owen Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 839054892,
        AWATER: 1690282,
        INTPTLAT: '+39.2900766',
        INTPTLON: '-086.8414148',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4443, 40.3787],
            [-85.2199, 40.379],
            [-85.2188, 40.3067],
            [-85.2174, 40.2349],
            [-85.3306, 40.2337],
            [-85.4428, 40.2335],
            [-85.4443, 40.3787],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802660',
        NAME: 'Delaware Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305162296,
        AWATER: 1962013,
        INTPTLAT: '+40.3119754',
        INTPTLON: '-085.3251318',
        ELSDLEA: '',
        UNSDLEA: '02660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4427, 40.2191],
            [-85.4428, 40.2335],
            [-85.3306, 40.2337],
            [-85.3293, 40.1493],
            [-85.4429, 40.1494],
            [-85.4427, 40.2191],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807320',
        NAME: 'Muncie Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89310341,
        AWATER: 695523,
        INTPTLAT: '+40.1915305',
        INTPTLON: '-085.3860070',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2428, 40.3088],
            [-86.1269, 40.332],
            [-85.8616, 40.3347],
            [-85.8602, 40.3057],
            [-85.8619, 40.2189],
            [-86.1282, 40.2172],
            [-86.2427, 40.2158],
            [-86.2428, 40.3088],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811400',
        NAME: 'Tipton Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391489624,
        AWATER: 6955,
        INTPTLAT: '+40.2711350',
        INTPTLON: '-086.0439648',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.243, 40.3737],
            [-86.1271, 40.4043],
            [-85.9755, 40.4062],
            [-85.8621, 40.4069],
            [-85.8616, 40.3347],
            [-86.1269, 40.332],
            [-86.2428, 40.3088],
            [-86.243, 40.3737],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808040',
        NAME: 'Tipton County Northern Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283307971,
        AWATER: 61073,
        INTPTLAT: '+40.3693007',
        INTPTLON: '-086.0642036',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3386, 41.1791],
            [-85.3078, 41.2642],
            [-85.1921, 41.2642],
            [-85.0778, 41.2656],
            [-85.0755, 41.1798],
            [-85.226, 41.1778],
            [-85.2267, 41.0889],
            [-85.337, 41.0897],
            [-85.3386, 41.1791],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808250',
        NAME: 'Northwest Allen County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279077458,
        AWATER: 641661,
        INTPTLAT: '+41.1982060',
        INTPTLON: '-085.2206803',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3016, 39.7876],
            [-85.2211, 39.7884],
            [-85.1851, 39.7155],
            [-85.0533, 39.7148],
            [-85.0346, 39.7148],
            [-85.0361, 39.5262],
            [-85.2981, 39.5255],
            [-85.3016, 39.7876],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803510',
        NAME: 'Fayette County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556901540,
        AWATER: 366408,
        INTPTLAT: '+39.6396552',
        INTPTLON: '-085.1850315',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.337, 41.0897],
            [-85.2267, 41.0889],
            [-85.2234, 40.917],
            [-85.336, 40.9171],
            [-85.3356, 41.0052],
            [-85.337, 41.0897],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800030',
        NAME: 'Southwest Allen County Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175781393,
        AWATER: 227743,
        INTPTLAT: '+41.0063449',
        INTPTLON: '-085.2718804',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5906, 38.6871],
            [-86.3087, 38.6881],
            [-86.308, 38.5862],
            [-86.3836, 38.6154],
            [-86.5338, 38.5858],
            [-86.5906, 38.6871],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808490',
        NAME: 'Orleans Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239211708,
        AWATER: 1133935,
        INTPTLAT: '+38.6421106',
        INTPTLON: '-086.4485719',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7594, 38.3927],
            [-87.7437, 38.4144],
            [-87.4629, 38.5335],
            [-87.4075, 38.3925],
            [-87.501, 38.3992],
            [-87.5026, 38.2893],
            [-87.6228, 38.289],
            [-87.7594, 38.3927],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807770',
        NAME: 'North Gibson School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394281324,
        AWATER: 6494064,
        INTPTLAT: '+38.4005437',
        INTPTLON: '-087.5722537',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.943, 38.2578],
            [-87.8511, 38.2751],
            [-87.7594, 38.3927],
            [-87.6228, 38.289],
            [-87.5026, 38.2893],
            [-87.4667, 38.2013],
            [-87.4673, 38.1654],
            [-87.6884, 38.1684],
            [-87.7992, 38.2286],
            [-87.9837, 38.2304],
            [-87.943, 38.2578],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810350',
        NAME: 'South Gibson School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 564564918,
        AWATER: 21425892,
        INTPTLAT: '+38.2602600',
        INTPTLON: '-087.6861166',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4335, 41.026],
            [-86.3382, 40.9964],
            [-86.1685, 40.9961],
            [-86.168, 40.8164],
            [-86.2423, 40.8012],
            [-86.3548, 40.8229],
            [-86.3559, 40.9095],
            [-86.4685, 40.9099],
            [-86.4335, 41.026],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801410',
        NAME: 'Caston School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433795413,
        AWATER: 1421746,
        INTPTLAT: '+40.9204453',
        INTPTLON: '-086.2897364',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6831, 38.6866],
            [-86.5906, 38.6871],
            [-86.5338, 38.5858],
            [-86.5708, 38.4971],
            [-86.5695, 38.396],
            [-86.6814, 38.3948],
            [-86.6824, 38.5264],
            [-86.6831, 38.6866],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810980',
        NAME: 'Springs Valley Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310534960,
        AWATER: 18510688,
        INTPTLAT: '+38.5352100',
        INTPTLON: '-086.6203212',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5266, 41.1661],
            [-87.4144, 41.1622],
            [-87.2759, 41.2186],
            [-87.2759, 41.0712],
            [-87.2764, 40.9115],
            [-87.526, 40.9118],
            [-87.5263, 41.0027],
            [-87.5266, 41.1215],
            [-87.5266, 41.1661],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807920',
        NAME: 'North Newton School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 617730627,
        AWATER: 4109211,
        INTPTLAT: '+41.0425613',
        INTPTLON: '-087.4018179',
        ELSDLEA: '',
        UNSDLEA: '07920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6829, 38.9047],
            [-86.6823, 38.9924],
            [-86.3175, 38.9909],
            [-86.2771, 38.8596],
            [-86.2753, 38.7638],
            [-86.3101, 38.7331],
            [-86.4671, 38.7985],
            [-86.6834, 38.7726],
            [-86.6829, 38.9047],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807860',
        NAME: 'North Lawrence Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 788000972,
        AWATER: 3928331,
        INTPTLAT: '+38.8866221',
        INTPTLON: '-086.4748133',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5323, 40.0118],
            [-87.532, 40.0889],
            [-87.5314, 40.1481],
            [-87.4067, 40.1275],
            [-87.4202, 39.9525],
            [-87.368, 39.8676],
            [-87.3854, 39.8242],
            [-87.5331, 39.8233],
            [-87.5332, 39.8534],
            [-87.5323, 40.0118],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808070',
        NAME: 'North Vermillion Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362561423,
        AWATER: 3661807,
        INTPTLAT: '+39.9611526',
        INTPTLON: '-087.4695708',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1325, 38.9481],
            [-85.1196, 39.0172],
            [-85.0849, 39.2063],
            [-84.9298, 39.1475],
            [-84.8708, 39.073],
            [-84.8776, 39.0312],
            [-85.1325, 38.9481],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800240',
        NAME: 'South Dearborn Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 393110558,
        AWATER: 2134905,
        INTPTLAT: '+39.0842326',
        INTPTLON: '-085.0288888',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9376, 39.9271],
            [-85.8625, 39.9436],
            [-85.8057, 39.9446],
            [-85.8399, 39.7841],
            [-85.9524, 39.7828],
            [-85.9531, 39.8268],
            [-85.9376, 39.9271],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807260',
        NAME: 'Mount Vernon Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174020814,
        AWATER: 408948,
        INTPTLAT: '+39.8614172',
        INTPTLON: '-085.8825467',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6924, 39.9454],
            [-85.5762, 39.9458],
            [-85.5763, 39.9165],
            [-85.6332, 39.6985],
            [-85.7254, 39.6989],
            [-85.7264, 39.7573],
            [-85.6924, 39.9454],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803120',
        NAME: 'Eastern Hancock County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248784786,
        AWATER: 473754,
        INTPTLAT: '+39.8268972',
        INTPTLON: '-085.6594316',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6983, 38.0075],
            [-87.6884, 38.1684],
            [-87.4673, 38.1654],
            [-87.4512, 37.941],
            [-87.6734, 37.8299],
            [-87.7008, 37.8976],
            [-87.6983, 38.0075],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803450',
        NAME: 'Evansville-Vanderburgh School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604741974,
        AWATER: 7352001,
        INTPTLAT: '+38.0200703',
        INTPTLON: '-087.5861659',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4903, 41.6576],
            [-87.4625, 41.6853],
            [-87.4239, 41.6554],
            [-87.4324, 41.6131],
            [-87.4903, 41.6576],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802880',
        NAME: 'City of East Chicago School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36481177,
        AWATER: 5350064,
        INTPTLAT: '+41.6504588',
        INTPTLON: '-087.4511231',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5265, 41.3484],
            [-87.4312, 41.3482],
            [-87.2775, 41.3621],
            [-87.2203, 41.3333],
            [-87.2189, 41.242],
            [-87.2759, 41.2186],
            [-87.4144, 41.1622],
            [-87.5266, 41.1661],
            [-87.5265, 41.2091],
            [-87.5267, 41.2981],
            [-87.5265, 41.3484],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811460',
        NAME: 'Tri-Creek School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460553031,
        AWATER: 2168603,
        INTPTLAT: '+41.2674829',
        INTPTLON: '-087.3819539',
        ELSDLEA: '',
        UNSDLEA: '11460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9298, 39.1475],
            [-84.8199, 39.1614],
            [-84.8201, 39.1185],
            [-84.8202, 39.1055],
            [-84.8708, 39.073],
            [-84.9298, 39.1475],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805700',
        NAME: 'Lawrenceburg Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62190519,
        AWATER: 2218239,
        INTPTLAT: '+39.1260479',
        INTPTLON: '-084.8769501',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0967, 39.3101],
            [-84.8194, 39.3052],
            [-84.8199, 39.1614],
            [-84.9298, 39.1475],
            [-85.0849, 39.2063],
            [-85.1886, 39.2209],
            [-85.2082, 39.2208],
            [-85.1517, 39.31],
            [-85.0967, 39.3101],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811190',
        NAME: 'Sunman-Dearborn Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422345383,
        AWATER: 2296197,
        INTPTLAT: '+39.2623545',
        INTPTLON: '-084.9784050',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0921, 40.3666],
            [-86.9991, 40.4167],
            [-87.0012, 40.5622],
            [-86.9815, 40.562],
            [-86.7713, 40.5621],
            [-86.6949, 40.482],
            [-86.6947, 40.3886],
            [-86.6956, 40.2144],
            [-87.0926, 40.2148],
            [-87.0927, 40.2584],
            [-87.0921, 40.3666],
          ],
          [
            [-86.9353, 40.4629],
            [-86.9001, 40.4278],
            [-86.8995, 40.469],
            [-86.9353, 40.4629],
          ],
          [
            [-86.9066, 40.4059],
            [-86.828, 40.3884],
            [-86.8241, 40.4316],
            [-86.8848, 40.4404],
            [-86.9066, 40.4059],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811340',
        NAME: 'Tippecanoe School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1106904948,
        AWATER: 7479031,
        INTPTLAT: '+40.3047647',
        INTPTLON: '-086.9849353',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5253, 41.6011],
            [-87.5251, 41.6155],
            [-87.5251, 41.6302],
            [-87.525, 41.6446],
            [-87.5242, 41.717],
            [-87.4943, 41.6962],
            [-87.4903, 41.6576],
            [-87.4324, 41.6131],
            [-87.4329, 41.5808],
            [-87.4325, 41.5666],
            [-87.4804, 41.5674],
            [-87.5253, 41.5771],
            [-87.5253, 41.5815],
            [-87.5253, 41.6011],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804320',
        NAME: 'Hammond School City',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59154594,
        AWATER: 5455139,
        INTPTLAT: '+41.6216378',
        INTPTLON: '-087.4938102',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4312, 41.4353],
            [-87.3924, 41.4351],
            [-87.2196, 41.4346],
            [-87.2198, 41.3473],
            [-87.2203, 41.3333],
            [-87.2775, 41.3621],
            [-87.4312, 41.3482],
            [-87.4312, 41.4353],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802490',
        NAME: 'Crown Point Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166006015,
        AWATER: 2359207,
        INTPTLAT: '+41.3921185',
        INTPTLON: '-087.3235672',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2787, 41.5802],
            [-87.222, 41.5935],
            [-87.2218, 41.5651],
            [-87.2787, 41.5802],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802910',
        NAME: 'Lake Station Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15922968,
        AWATER: 129176,
        INTPTLAT: '+41.5767347',
        INTPTLON: '-087.2528784',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0988, 38.904],
            [-86.9596, 38.9032],
            [-86.9043, 38.9042],
            [-86.9233, 38.7308],
            [-87.0925, 38.728],
            [-87.247, 38.7289],
            [-87.2466, 38.8077],
            [-87.0988, 38.904],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807710',
        NAME: 'North Daviess Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468858711,
        AWATER: 5441147,
        INTPTLAT: '+38.8100800',
        INTPTLON: '-087.0613809',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2625, 38.7289],
            [-87.247, 38.7289],
            [-87.0925, 38.728],
            [-87.0917, 38.6116],
            [-87.0355, 38.5123],
            [-87.0724, 38.5147],
            [-87.2421, 38.5449],
            [-87.2625, 38.7289],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812450',
        NAME: 'Washington Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343245318,
        AWATER: 7991777,
        INTPTLAT: '+38.6231916',
        INTPTLON: '-087.1545092',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5273, 40.4327],
            [-87.5263, 40.5673],
            [-87.5262, 40.6498],
            [-87.4385, 40.6499],
            [-87.4391, 40.7367],
            [-87.2673, 40.7366],
            [-87.2108, 40.6496],
            [-87.0973, 40.6495],
            [-87.0954, 40.5629],
            [-87.0012, 40.5622],
            [-86.9991, 40.4167],
            [-87.0921, 40.3666],
            [-87.1781, 40.3887],
            [-87.1784, 40.4756],
            [-87.378, 40.4768],
            [-87.3773, 40.3895],
            [-87.528, 40.389],
            [-87.5273, 40.4327],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800480',
        NAME: 'Benton Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1216223695,
        AWATER: 2151148,
        INTPTLAT: '+40.5555127',
        INTPTLON: '-087.2940497',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9066, 40.4059],
            [-86.8848, 40.4404],
            [-86.8241, 40.4316],
            [-86.828, 40.3884],
            [-86.9066, 40.4059],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805400',
        NAME: 'Lafayette School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42796269,
        AWATER: 101460,
        INTPTLAT: '+40.4108906',
        INTPTLON: '-086.8633508',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9353, 40.4629],
            [-86.8995, 40.469],
            [-86.9001, 40.4278],
            [-86.9353, 40.4629],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812870',
        NAME: 'West Lafayette Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11182723,
        AWATER: 0,
        INTPTLAT: '+40.4474590',
        INTPTLON: '-086.9125040',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7657, 41.264],
            [-85.6512, 41.2658],
            [-85.5683, 41.1777],
            [-85.5665, 41.0031],
            [-85.6438, 41.0023],
            [-85.6842, 41.0467],
            [-85.7958, 41.0436],
            [-85.7986, 41.177],
            [-85.7657, 41.264],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1813230',
        NAME: 'Whitko Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 449527727,
        AWATER: 3688449,
        INTPTLAT: '+41.1325363',
        INTPTLON: '-085.6799661',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4429, 40.1494],
            [-85.3293, 40.1493],
            [-85.3281, 40.0765],
            [-85.4037, 40.0765],
            [-85.4425, 40.0767],
            [-85.4429, 40.1494],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807020',
        NAME: 'Cowan Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78413399,
        AWATER: 97829,
        INTPTLAT: '+40.1129991',
        INTPTLON: '-085.3877672',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.576, 40.1496],
            [-85.4429, 40.1494],
            [-85.4425, 40.0767],
            [-85.5762, 40.0771],
            [-85.576, 40.1496],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809840',
        NAME: 'Daleville Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90848890,
        AWATER: 357962,
        INTPTLAT: '+40.1026089',
        INTPTLON: '-085.5092283',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8624, 40.0316],
            [-85.7492, 40.0465],
            [-85.5764, 40.0478],
            [-85.5762, 39.9458],
            [-85.6924, 39.9454],
            [-85.8057, 39.9446],
            [-85.8625, 39.9436],
            [-85.8624, 40.0316],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810440',
        NAME: 'South Madison Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264200322,
        AWATER: 1156668,
        INTPTLAT: '+39.9942647',
        INTPTLON: '-085.7108230',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3306, 40.2337],
            [-85.2174, 40.2349],
            [-85.2149, 40.1061],
            [-85.3088, 40.0767],
            [-85.3281, 40.0765],
            [-85.3293, 40.1493],
            [-85.3306, 40.2337],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805880',
        NAME: 'Liberty-Perry Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163514490,
        AWATER: 5331734,
        INTPTLAT: '+40.1561119',
        INTPTLON: '-085.2670793',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0328, 38.4179],
            [-85.9946, 38.4183],
            [-85.8077, 38.4044],
            [-85.7629, 38.349],
            [-85.7923, 38.2874],
            [-85.8998, 38.1804],
            [-85.9577, 38.1866],
            [-85.9552, 38.2605],
            [-86.0141, 38.3018],
            [-86.0328, 38.4179],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807410',
        NAME: 'New Albany-Floyd County Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 383288306,
        AWATER: 2645487,
        INTPTLAT: '+38.3179365',
        INTPTLON: '-085.9114742',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2771, 38.8596],
            [-86.1315, 38.862],
            [-86.1667, 38.7734],
            [-86.2371, 38.7678],
            [-86.2753, 38.7638],
            [-86.2771, 38.8596],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801230',
        NAME: 'Medora Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107030902,
        AWATER: 1627401,
        INTPTLAT: '+38.8196916',
        INTPTLON: '-086.2262454',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8858, 41.4356],
            [-85.6535, 41.4369],
            [-85.6519, 41.2948],
            [-85.6512, 41.2658],
            [-85.7657, 41.264],
            [-85.7676, 41.3496],
            [-85.9425, 41.3472],
            [-85.8858, 41.4356],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805550',
        NAME: 'Wawasee Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292318047,
        AWATER: 33131605,
        INTPTLAT: '+41.3691485',
        INTPTLON: '-085.7709116',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0005, 41.3468],
            [-85.9425, 41.3472],
            [-85.7676, 41.3496],
            [-85.7657, 41.264],
            [-85.7986, 41.177],
            [-85.7958, 41.0436],
            [-85.9075, 41.0429],
            [-85.9112, 41.262],
            [-85.9983, 41.2604],
            [-86.0005, 41.3468],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812420',
        NAME: 'Warsaw Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424444063,
        AWATER: 15441386,
        INTPTLAT: '+41.2204696',
        INTPTLON: '-085.8617223',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1879, 41.1723],
            [-86.1528, 41.1723],
            [-86.054, 41.1731],
            [-85.9983, 41.2604],
            [-85.9112, 41.262],
            [-85.9075, 41.0429],
            [-85.9464, 40.9992],
            [-86.13, 40.9961],
            [-86.13, 41.084],
            [-86.1879, 41.1723],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811370',
        NAME: 'Tippecanoe Valley School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493462686,
        AWATER: 7900195,
        INTPTLAT: '+41.1220774',
        INTPTLON: '-086.0285931',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0607, 41.6084],
            [-86.0024, 41.6089],
            [-85.8872, 41.6087],
            [-85.8858, 41.5223],
            [-85.8858, 41.4356],
            [-85.9425, 41.3472],
            [-86.0005, 41.3468],
            [-86.0586, 41.3756],
            [-86.0595, 41.4792],
            [-86.0607, 41.6084],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812240',
        NAME: 'Wa-Nee Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372089166,
        AWATER: 413095,
        INTPTLAT: '+41.4893930',
        INTPTLON: '-085.9712045',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6999, 41.6795],
            [-86.6992, 41.61],
            [-86.4866, 41.607],
            [-86.4864, 41.5705],
            [-86.4951, 41.5634],
            [-86.5819, 41.5199],
            [-86.5823, 41.4327],
            [-86.6425, 41.4326],
            [-86.7209, 41.5175],
            [-86.8168, 41.5176],
            [-86.8183, 41.607],
            [-86.8164, 41.6798],
            [-86.6999, 41.6795],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805580',
        NAME: 'La Porte Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439861182,
        AWATER: 7886064,
        INTPTLAT: '+41.5533603',
        INTPTLON: '-086.6722619',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5763, 39.9165],
            [-85.4432, 39.9175],
            [-85.4296, 39.8736],
            [-85.4941, 39.7872],
            [-85.5215, 39.6989],
            [-85.6325, 39.6985],
            [-85.6332, 39.6985],
            [-85.5763, 39.9165],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801060',
        NAME: 'C. A. Beard Memorial School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242337001,
        AWATER: 1105544,
        INTPTLAT: '+39.8135495',
        INTPTLON: '-085.5441384',
        ELSDLEA: '',
        UNSDLEA: '01060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2633, 39.9606],
            [-85.2015, 40.0045],
            [-85.0535, 40.0048],
            [-85.0056, 39.9033],
            [-85.0707, 39.8238],
            [-85.2207, 39.8736],
            [-85.2592, 39.8732],
            [-85.2633, 39.9606],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807380',
        NAME: 'Nettle Creek School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302090304,
        AWATER: 891713,
        INTPTLAT: '+39.9254270',
        INTPTLON: '-085.1266400',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5762, 40.0771],
            [-85.4425, 40.0767],
            [-85.4037, 40.0765],
            [-85.4042, 39.961],
            [-85.4432, 39.9175],
            [-85.5763, 39.9165],
            [-85.5762, 39.9458],
            [-85.5764, 40.0478],
            [-85.5762, 40.0771],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808280',
        NAME: 'Shenandoah School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244623469,
        AWATER: 479792,
        INTPTLAT: '+39.9970961',
        INTPTLON: '-085.4882744',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4296, 39.8736],
            [-85.2592, 39.8732],
            [-85.2207, 39.8736],
            [-85.2211, 39.7884],
            [-85.3016, 39.7876],
            [-85.4941, 39.7872],
            [-85.4296, 39.8736],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810380',
        NAME: 'South Henry School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211023117,
        AWATER: 758556,
        INTPTLAT: '+39.8198331',
        INTPTLON: '-085.3479148',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3088, 40.0767],
            [-85.2149, 40.1061],
            [-85.1111, 40.1214],
            [-85.0351, 40.1209],
            [-85.0345, 40.0048],
            [-85.0535, 40.0048],
            [-85.2015, 40.0045],
            [-85.3073, 40.019],
            [-85.3088, 40.0767],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811730',
        NAME: 'Union School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235249595,
        AWATER: 1915913,
        INTPTLAT: '+40.0552625',
        INTPTLON: '-085.1552238',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0739, 40.9178],
            [-84.8029, 40.9226],
            [-84.8023, 40.8347],
            [-84.8801, 40.8035],
            [-85.0722, 40.8309],
            [-85.0739, 40.9178],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807680',
        NAME: 'North Adams Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251982796,
        AWATER: 642183,
        INTPTLAT: '+40.8715725',
        INTPTLON: '-084.9262594',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4309, 39.9235],
            [-86.3553, 39.924],
            [-86.3263, 39.924],
            [-86.3273, 39.8225],
            [-86.3268, 39.8008],
            [-86.4383, 39.7987],
            [-86.4309, 39.9235],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801020',
        NAME: 'Brownsburg Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126551846,
        AWATER: 962489,
        INTPTLAT: '+39.8613838',
        INTPTLON: '-086.3813030',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6868, 39.8197],
            [-86.5055, 39.8344],
            [-86.457, 39.7984],
            [-86.4571, 39.7181],
            [-86.6871, 39.7172],
            [-86.6868, 39.8197],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802550',
        NAME: 'Danville Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218967182,
        AWATER: 877870,
        INTPTLAT: '+39.7751626',
        INTPTLON: '-086.5688769',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.438, 39.7184],
            [-86.3266, 39.7195],
            [-86.3263, 39.6322],
            [-86.4179, 39.6305],
            [-86.4371, 39.6303],
            [-86.438, 39.7184],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808970',
        NAME: 'Plainfield Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92315721,
        AWATER: 741693,
        INTPTLAT: '+39.6758341',
        INTPTLON: '-086.3831843',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5594, 40.379],
            [-85.4443, 40.3791],
            [-85.4443, 40.3787],
            [-85.4428, 40.2335],
            [-85.4427, 40.2191],
            [-85.5756, 40.2189],
            [-85.577, 40.3064],
            [-85.5594, 40.379],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804500',
        NAME: 'Wes-Del Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201078986,
        AWATER: 716401,
        INTPTLAT: '+40.2966004',
        INTPTLON: '-085.5088058',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5756, 40.2189],
            [-85.4427, 40.2191],
            [-85.4429, 40.1494],
            [-85.576, 40.1496],
            [-85.5756, 40.2189],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807230',
        NAME: 'Mount Pleasant Township Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87278866,
        AWATER: 631296,
        INTPTLAT: '+40.1818739',
        INTPTLON: '-085.5083797',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6967, 40.9122],
            [-86.4685, 40.9099],
            [-86.3559, 40.9095],
            [-86.3548, 40.8229],
            [-86.3546, 40.7786],
            [-86.5252, 40.7172],
            [-86.5817, 40.7358],
            [-86.5809, 40.8626],
            [-86.6966, 40.8628],
            [-86.6967, 40.9122],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808940',
        NAME: 'Pioneer Regional School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390506006,
        AWATER: 1342660,
        INTPTLAT: '+40.8303071',
        INTPTLON: '-086.4921691',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.968, 40.0392],
            [-86.8664, 40.0396],
            [-86.8802, 40.0178],
            [-86.968, 40.0392],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802460',
        NAME: 'Crawfordsville Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26334346,
        AWATER: 69815,
        INTPTLAT: '+40.0317057',
        INTPTLON: '-086.9154788',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9233, 38.7308],
            [-86.9043, 38.9042],
            [-86.8314, 38.9043],
            [-86.7944, 38.9044],
            [-86.795, 38.745],
            [-86.8499, 38.7458],
            [-86.8314, 38.5264],
            [-86.9242, 38.5054],
            [-86.9233, 38.7308],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806060',
        NAME: 'Loogootee Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340092031,
        AWATER: 6150734,
        INTPTLAT: '+38.7220343',
        INTPTLON: '-086.8729400',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5316, 39.4771],
            [-87.5322, 39.6073],
            [-87.3824, 39.6079],
            [-87.199, 39.6071],
            [-87.239, 39.5206],
            [-87.2401, 39.3028],
            [-87.2404, 39.2591],
            [-87.6041, 39.2595],
            [-87.5316, 39.3479],
            [-87.5316, 39.4771],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812090',
        NAME: 'Vigo County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1044993096,
        AWATER: 18068475,
        INTPTLAT: '+39.4291426',
        INTPTLON: '-087.3903754',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3565, 40.1781],
            [-86.2427, 40.2158],
            [-86.1282, 40.2172],
            [-86.1281, 40.1014],
            [-86.2415, 40.0708],
            [-86.356, 40.069],
            [-86.3564, 40.0983],
            [-86.3565, 40.1781],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806480',
        NAME: 'Sheridan Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243999368,
        AWATER: 251372,
        INTPTLAT: '+40.1406098',
        INTPTLON: '-086.2376094',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2411, 39.9988],
            [-86.0896, 40.0002],
            [-86.0232, 40.0002],
            [-86.0765, 39.9269],
            [-86.2031, 39.9266],
            [-86.2397, 39.9261],
            [-86.2411, 39.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801200',
        NAME: 'Carmel Clay Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127153314,
        AWATER: 2812142,
        INTPTLAT: '+39.9654794',
        INTPTLON: '-086.1483688',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4432, 39.9175],
            [-85.4042, 39.961],
            [-85.2633, 39.9606],
            [-85.2592, 39.8732],
            [-85.4296, 39.8736],
            [-85.4432, 39.9175],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807440',
        NAME: 'New Castle Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148941633,
        AWATER: 1397220,
        INTPTLAT: '+39.9171246',
        INTPTLON: '-085.3524713',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4037, 40.0765],
            [-85.3281, 40.0765],
            [-85.3088, 40.0767],
            [-85.3073, 40.019],
            [-85.2015, 40.0045],
            [-85.2633, 39.9606],
            [-85.4042, 39.961],
            [-85.4037, 40.0765],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800660',
        NAME: 'Blue River Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159505169,
        AWATER: 2750355,
        INTPTLAT: '+40.0072369',
        INTPTLON: '-085.3181733',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9301, 41.2368],
            [-86.7835, 41.2855],
            [-86.7286, 41.3461],
            [-86.6985, 41.3462],
            [-86.6984, 41.2155],
            [-86.6598, 41.1721],
            [-86.7577, 41.0851],
            [-86.8145, 41.1468],
            [-86.9304, 41.1583],
            [-86.9301, 41.2368],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807800',
        NAME: 'North Judson-San Pierre School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373028769,
        AWATER: 275437,
        INTPTLAT: '+41.2059822',
        INTPTLON: '-086.7828623',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0076, 41.0857],
            [-86.9304, 41.1583],
            [-86.8145, 41.1468],
            [-86.7577, 41.0851],
            [-86.6995, 41.0203],
            [-86.7109, 40.9122],
            [-86.9308, 40.9124],
            [-86.9889, 40.9781],
            [-87.0076, 41.0857],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809360',
        NAME: 'West Central School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 511842264,
        AWATER: 109531,
        INTPTLAT: '+41.0221294',
        INTPTLON: '-086.8641976',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2513, 39.4306],
            [-86.0261, 39.4341],
            [-86.0277, 39.3967],
            [-86.0948, 39.344],
            [-86.3623, 39.3401],
            [-86.3617, 39.4279],
            [-86.2513, 39.4306],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807620',
        NAME: 'Nineveh-Hensley-Jackson United School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254055824,
        AWATER: 2852400,
        INTPTLAT: '+39.3827531',
        INTPTLON: '-086.2147793',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4239, 41.6554],
            [-87.2229, 41.6326],
            [-87.222, 41.5935],
            [-87.2787, 41.5802],
            [-87.3171, 41.5511],
            [-87.3167, 41.5221],
            [-87.3458, 41.5222],
            [-87.4329, 41.5808],
            [-87.4324, 41.6131],
            [-87.4239, 41.6554],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803870',
        NAME: 'Gary Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108678828,
        AWATER: 18902853,
        INTPTLAT: '+41.6008530',
        INTPTLON: '-087.3405443',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5262, 41.3853],
            [-87.5258, 41.4356],
            [-87.4312, 41.4353],
            [-87.4312, 41.3482],
            [-87.5265, 41.3484],
            [-87.5262, 41.3853],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804350',
        NAME: 'Hanover Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74490376,
        AWATER: 2347522,
        INTPTLAT: '+41.3910871',
        INTPTLON: '-087.4787149',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4804, 41.5674],
            [-87.4325, 41.5666],
            [-87.4325, 41.5664],
            [-87.4518, 41.5229],
            [-87.4807, 41.5231],
            [-87.4804, 41.5674],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804560',
        NAME: 'Highland School Town',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18006265,
        AWATER: 55855,
        INTPTLAT: '+41.5466221',
        INTPTLON: '-087.4576162',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3171, 41.5511],
            [-87.2214, 41.5506],
            [-87.2206, 41.5216],
            [-87.2202, 41.5071],
            [-87.3167, 41.5221],
            [-87.3171, 41.5511],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804590',
        NAME: 'Hobart School City',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38080776,
        AWATER: 970740,
        INTPTLAT: '+41.5288988',
        INTPTLON: '-087.2687662',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2787, 41.5802],
            [-87.2218, 41.5651],
            [-87.2214, 41.5506],
            [-87.3171, 41.5511],
            [-87.2787, 41.5802],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804620',
        NAME: 'River Forest Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15353382,
        AWATER: 216471,
        INTPTLAT: '+41.5610433',
        INTPTLON: '-087.2767486',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8479, 38.5613],
            [-85.7238, 38.6055],
            [-85.7629, 38.349],
            [-85.8077, 38.4044],
            [-85.9946, 38.4183],
            [-85.9933, 38.4885],
            [-85.8848, 38.5035],
            [-85.8479, 38.5613],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809370',
        NAME: 'West Clark Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410668030,
        AWATER: 3521102,
        INTPTLAT: '+38.4776329',
        INTPTLON: '-085.8365591',
        ELSDLEA: '',
        UNSDLEA: '09370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7629, 38.349],
            [-85.7238, 38.6055],
            [-85.57, 38.6062],
            [-85.4283, 38.5863],
            [-85.4332, 38.5244],
            [-85.586, 38.4501],
            [-85.6379, 38.3803],
            [-85.6554, 38.3238],
            [-85.7515, 38.268],
            [-85.7526, 38.3059],
            [-85.7795, 38.2877],
            [-85.7923, 38.2874],
            [-85.7629, 38.349],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803940',
        NAME: 'Greater Clark County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 545353052,
        AWATER: 5675660,
        INTPTLAT: '+38.4770710',
        INTPTLON: '-085.6201024',
        ELSDLEA: '',
        UNSDLEA: '03940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7515, 38.268],
            [-85.7795, 38.2877],
            [-85.7526, 38.3059],
            [-85.7515, 38.268],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801920',
        NAME: 'Clarksville Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9605349,
        AWATER: 47540,
        INTPTLAT: '+38.2989494',
        INTPTLON: '-085.7623620',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.084, 41.7597],
            [-85.0588, 41.7596],
            [-84.9177, 41.7597],
            [-84.8251, 41.76],
            [-84.8059, 41.7602],
            [-84.8059, 41.7027],
            [-85.0836, 41.7005],
            [-85.084, 41.7597],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803780',
        NAME: 'Fremont Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137772305,
        AWATER: 12219199,
        INTPTLAT: '+41.7327992',
        INTPTLON: '-084.9467851',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1966, 41.6875],
            [-86.1385, 41.6514],
            [-86.2158, 41.6512],
            [-86.1966, 41.6875],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806840',
        NAME: 'Mishawaka School City',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22583574,
        AWATER: 803513,
        INTPTLAT: '+41.6632698',
        INTPTLON: '-086.1608178',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4951, 41.5634],
            [-86.4864, 41.5705],
            [-86.4247, 41.6043],
            [-86.4146, 41.7597],
            [-86.3688, 41.7599],
            [-86.313, 41.7603],
            [-86.1787, 41.7605],
            [-86.1966, 41.6875],
            [-86.2158, 41.6512],
            [-86.2152, 41.5645],
            [-86.3315, 41.5638],
            [-86.4951, 41.5634],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810290',
        NAME: 'South Bend Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406447700,
        AWATER: 4519438,
        INTPTLAT: '+41.6631416',
        INTPTLON: '-086.3130754',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0704, 40.6555],
            [-85.0696, 40.6868],
            [-84.8024, 40.6748],
            [-84.8024, 40.5824],
            [-84.8025, 40.5722],
            [-85.0685, 40.5682],
            [-85.0704, 40.6555],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810260',
        NAME: 'South Adams Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266452971,
        AWATER: 1666286,
        INTPTLAT: '+40.6291842',
        INTPTLON: '-084.9409201',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0722, 40.8309],
            [-84.8801, 40.8035],
            [-84.8023, 40.8347],
            [-84.8024, 40.7717],
            [-84.8024, 40.6748],
            [-85.0696, 40.6868],
            [-85.071, 40.7431],
            [-85.0722, 40.8309],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800060',
        NAME: 'Adams Central Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359643032,
        AWATER: 122173,
        INTPTLAT: '+40.7527967',
        INTPTLON: '-084.9292038',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6985, 41.3462],
            [-86.4663, 41.3458],
            [-86.4666, 41.2737],
            [-86.5822, 41.2591],
            [-86.5828, 41.172],
            [-86.6598, 41.1721],
            [-86.6984, 41.2155],
            [-86.6985, 41.3462],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805340',
        NAME: 'Knox Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264510797,
        AWATER: 4316580,
        INTPTLAT: '+41.2775323',
        INTPTLON: '-086.5956990',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5257, 41.4703],
            [-87.5256, 41.4989],
            [-87.5256, 41.5227],
            [-87.4807, 41.5231],
            [-87.4518, 41.5229],
            [-87.4131, 41.5226],
            [-87.3939, 41.5226],
            [-87.3924, 41.4351],
            [-87.4312, 41.4353],
            [-87.5258, 41.4356],
            [-87.5257, 41.4703],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805450',
        NAME: 'Lake Central School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101705469,
        AWATER: 425368,
        INTPTLAT: '+41.4781248',
        INTPTLON: '-087.4616638',
        ELSDLEA: '',
        UNSDLEA: '05450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4329, 41.5808],
            [-87.3458, 41.5222],
            [-87.3939, 41.5226],
            [-87.4131, 41.5226],
            [-87.4325, 41.5664],
            [-87.4325, 41.5666],
            [-87.4329, 41.5808],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805460',
        NAME: 'Lake Ridge Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31168895,
        AWATER: 63437,
        INTPTLAT: '+41.5510681',
        INTPTLON: '-087.3854798',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.525, 41.5283],
            [-87.5254, 41.5575],
            [-87.5253, 41.5771],
            [-87.4804, 41.5674],
            [-87.4807, 41.5231],
            [-87.5256, 41.5227],
            [-87.525, 41.5283],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807350',
        NAME: 'Munster School Town',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19487920,
        AWATER: 209987,
        INTPTLAT: '+41.5469100',
        INTPTLON: '-087.5045891',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3939, 41.5226],
            [-87.3458, 41.5222],
            [-87.3167, 41.5221],
            [-87.2202, 41.5071],
            [-87.2196, 41.4346],
            [-87.3924, 41.4351],
            [-87.3939, 41.5226],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809690',
        NAME: 'Merrillville Community School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126955082,
        AWATER: 102304,
        INTPTLAT: '+41.4789410',
        INTPTLON: '-087.3066829',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4943, 41.6962],
            [-87.4625, 41.6853],
            [-87.4903, 41.6576],
            [-87.4943, 41.6962],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1813200',
        NAME: 'Whiting School City',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4594213,
        AWATER: 3701344,
        INTPTLAT: '+41.6767540',
        INTPTLON: '-087.4764910',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4629, 38.5335],
            [-87.2421, 38.5449],
            [-87.0724, 38.5147],
            [-87.0726, 38.3347],
            [-87.0731, 38.2326],
            [-87.3169, 38.2461],
            [-87.3159, 38.375],
            [-87.4075, 38.3925],
            [-87.4629, 38.5335],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808900',
        NAME: 'Pike County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 865677126,
        AWATER: 17752830,
        INTPTLAT: '+38.3978982',
        INTPTLON: '-087.2325320',
        ELSDLEA: '',
        UNSDLEA: '08900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0707, 39.8238],
            [-85.0056, 39.9033],
            [-84.9394, 39.8744],
            [-84.9277, 39.7266],
            [-85.0346, 39.7148],
            [-85.0533, 39.7148],
            [-85.0707, 39.8238],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801560',
        NAME: 'Centerville-Abington Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163457070,
        AWATER: 1015768,
        INTPTLAT: '+39.7979152',
        INTPTLON: '-084.9987996',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2207, 39.8736],
            [-85.0707, 39.8238],
            [-85.0533, 39.7148],
            [-85.1851, 39.7155],
            [-85.2211, 39.7884],
            [-85.2207, 39.8736],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1813050',
        NAME: 'Western Wayne Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180210180,
        AWATER: 2140491,
        INTPTLAT: '+39.7722134',
        INTPTLON: '-085.1295787',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0031, 41.6634],
            [-85.8884, 41.6951],
            [-85.8702, 41.6087],
            [-85.8872, 41.6087],
            [-86.0024, 41.6089],
            [-86.0031, 41.6634],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802400',
        NAME: 'Concord Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70041789,
        AWATER: 439229,
        INTPTLAT: '+41.6432653',
        INTPTLON: '-085.9340818',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0903, 40.1013],
            [-85.9586, 40.1025],
            [-85.9567, 40.0011],
            [-86.0232, 40.0002],
            [-86.0896, 40.0002],
            [-86.0903, 40.1013],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807650',
        NAME: 'Noblesville Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121269609,
        AWATER: 5926551,
        INTPTLAT: '+40.0536465',
        INTPTLON: '-086.0217262',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0232, 40.0002],
            [-85.9567, 40.0011],
            [-85.9586, 40.1025],
            [-85.8633, 40.1039],
            [-85.8624, 40.0316],
            [-85.8625, 39.9436],
            [-85.9376, 39.9271],
            [-86.0692, 39.927],
            [-86.0765, 39.9269],
            [-86.0232, 40.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810650',
        NAME: 'Hamilton Southeastern Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217597718,
        AWATER: 6576171,
        INTPTLAT: '+39.9983501',
        INTPTLON: '-085.9394182',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7471, 40.3063],
            [-85.577, 40.3064],
            [-85.5756, 40.2189],
            [-85.6714, 40.2195],
            [-85.7471, 40.3063],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800120',
        NAME: 'Alexandria Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132669202,
        AWATER: 62320,
        INTPTLAT: '+40.2667043',
        INTPTLON: '-085.6584206',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0133, 40.5641],
            [-85.9388, 40.5648],
            [-85.863, 40.4787],
            [-85.8621, 40.4069],
            [-85.9755, 40.4062],
            [-86.0129, 40.4777],
            [-86.0133, 40.5641],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803150',
        NAME: 'Eastern Howard School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209322878,
        AWATER: 713557,
        INTPTLAT: '+40.4907766',
        INTPTLON: '-085.9324932',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1271, 40.4407],
            [-86.0795, 40.4769],
            [-86.0129, 40.4777],
            [-85.9755, 40.4062],
            [-86.1271, 40.4043],
            [-86.1271, 40.4407],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811250',
        NAME: 'Taylor Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78744942,
        AWATER: 0,
        INTPTLAT: '+40.4350982',
        INTPTLON: '-086.0521133',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3152, 39.2587],
            [-85.2969, 39.4106],
            [-85.1694, 39.4119],
            [-85.0967, 39.3101],
            [-85.1517, 39.31],
            [-85.2082, 39.2208],
            [-85.3152, 39.2587],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800390',
        NAME: 'Batesville Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275919871,
        AWATER: 1718157,
        INTPTLAT: '+39.3186030',
        INTPTLON: '-085.2279188',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4401, 39.1956],
            [-85.3152, 39.2587],
            [-85.2082, 39.2208],
            [-85.1886, 39.2209],
            [-85.1872, 39.1337],
            [-85.2408, 39.1034],
            [-85.3856, 39.1111],
            [-85.4411, 39.1617],
            [-85.4401, 39.1956],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804800',
        NAME: 'Jac-Cen-Del Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242825459,
        AWATER: 361957,
        INTPTLAT: '+39.1700736',
        INTPTLON: '-085.3040620',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6512, 41.2658],
            [-85.6519, 41.2948],
            [-85.5363, 41.2952],
            [-85.4241, 41.265],
            [-85.4245, 41.1785],
            [-85.3386, 41.1791],
            [-85.337, 41.0897],
            [-85.3356, 41.0052],
            [-85.5665, 41.0031],
            [-85.5683, 41.1777],
            [-85.6512, 41.2658],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802280',
        NAME: 'Whitley County Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555702891,
        AWATER: 4748850,
        INTPTLAT: '+41.1379319',
        INTPTLON: '-085.4716472',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6598, 41.7591],
            [-85.5258, 41.7594],
            [-85.4866, 41.7593],
            [-85.4728, 41.6121],
            [-85.4235, 41.5256],
            [-85.6547, 41.5233],
            [-85.6567, 41.6105],
            [-85.6598, 41.7591],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1813110',
        NAME: 'Westview School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428751330,
        AWATER: 3732935,
        INTPTLAT: '+41.6372763',
        INTPTLON: '-085.5511848',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6438, 41.0023],
            [-85.5665, 41.0031],
            [-85.3356, 41.0052],
            [-85.336, 40.9171],
            [-85.335, 40.742],
            [-85.3347, 40.6544],
            [-85.4488, 40.6536],
            [-85.6386, 40.6531],
            [-85.6423, 40.9147],
            [-85.6438, 41.0023],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804710',
        NAME: 'Huntington County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 991056505,
        AWATER: 13133894,
        INTPTLAT: '+40.8263938',
        INTPTLON: '-085.4785975',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5252, 40.7172],
            [-86.3546, 40.7786],
            [-86.3548, 40.8229],
            [-86.2423, 40.8012],
            [-86.3915, 40.6924],
            [-86.4679, 40.692],
            [-86.5252, 40.7172],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806030',
        NAME: 'Logansport Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155305282,
        AWATER: 3213164,
        INTPTLAT: '+40.7537328',
        INTPTLON: '-086.3777566',
        ELSDLEA: '',
        UNSDLEA: '06030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3296, 38.1818],
            [-86.2767, 38.2341],
            [-86.2003, 38.2616],
            [-86.0511, 38.2583],
            [-86.0507, 38.1851],
            [-85.9577, 38.1866],
            [-85.8998, 38.1804],
            [-85.9471, 38.0051],
            [-85.999, 37.9997],
            [-86.0342, 37.9653],
            [-86.1216, 38.0161],
            [-86.2678, 38.0572],
            [-86.2728, 38.1408],
            [-86.3296, 38.1818],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810360',
        NAME: 'South Harrison Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 779533962,
        AWATER: 4404063,
        INTPTLAT: '+38.1223011',
        INTPTLON: '-086.1011329',
        ELSDLEA: '',
        UNSDLEA: '10360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7726, 40.6492],
            [-86.6554, 40.6491],
            [-86.5252, 40.7172],
            [-86.4679, 40.692],
            [-86.4679, 40.5614],
            [-86.5824, 40.5619],
            [-86.5815, 40.4822],
            [-86.6949, 40.482],
            [-86.7713, 40.5621],
            [-86.7726, 40.6492],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802700',
        NAME: 'Delphi Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395735235,
        AWATER: 3577323,
        INTPTLAT: '+40.6016627',
        INTPTLON: '-086.6059660',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2158, 41.6512],
            [-86.1385, 41.6514],
            [-86.1966, 41.6875],
            [-86.1787, 41.7605],
            [-86.1718, 41.7605],
            [-86.0626, 41.7605],
            [-86.0614, 41.6805],
            [-86.0607, 41.6084],
            [-86.0595, 41.4792],
            [-86.185, 41.4788],
            [-86.2152, 41.5645],
            [-86.2158, 41.6512],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808760',
        NAME: 'Penn-Harris-Madison School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329271361,
        AWATER: 1700305,
        INTPTLAT: '+41.6151650',
        INTPTLON: '-086.1282664',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6997, 41.7598],
            [-86.6688, 41.7597],
            [-86.5724, 41.7595],
            [-86.4146, 41.7597],
            [-86.4247, 41.6043],
            [-86.4864, 41.5705],
            [-86.4866, 41.607],
            [-86.6992, 41.61],
            [-86.6999, 41.6795],
            [-86.6997, 41.7598],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807560',
        NAME: 'New Prairie United School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406119139,
        AWATER: 4986154,
        INTPTLAT: '+41.6832109',
        INTPTLON: '-086.5547513',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5644, 38.6749],
            [-87.5062, 38.7335],
            [-87.4629, 38.7287],
            [-87.4632, 38.6428],
            [-87.5644, 38.6749],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812120',
        NAME: 'Vincennes Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77925567,
        AWATER: 1694006,
        INTPTLAT: '+38.6708541',
        INTPTLON: '-087.5148378',
        ELSDLEA: '',
        UNSDLEA: '12120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5229, 38.9024],
            [-87.337, 38.9018],
            [-87.241, 38.9079],
            [-87.0988, 38.904],
            [-87.2466, 38.8077],
            [-87.247, 38.7289],
            [-87.2625, 38.7289],
            [-87.4629, 38.7287],
            [-87.5062, 38.7335],
            [-87.5311, 38.8525],
            [-87.5229, 38.9024],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807830',
        NAME: 'North Knox School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538743398,
        AWATER: 5498016,
        INTPTLAT: '+38.8279534',
        INTPTLON: '-087.3570915',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7349, 38.48],
            [-87.6515, 38.5682],
            [-87.5644, 38.6749],
            [-87.4632, 38.6428],
            [-87.4629, 38.7287],
            [-87.2625, 38.7289],
            [-87.2421, 38.5449],
            [-87.4629, 38.5335],
            [-87.7437, 38.4144],
            [-87.7349, 38.48],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810410',
        NAME: 'South Knox School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 719845557,
        AWATER: 13562752,
        INTPTLAT: '+38.5904180',
        INTPTLON: '-087.4624132',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9052, 38.3357],
            [-86.8859, 38.3507],
            [-86.6809, 38.351],
            [-86.6795, 38.2631],
            [-86.7915, 38.2051],
            [-86.9237, 38.203],
            [-86.9052, 38.3357],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810640',
        NAME: 'Southeast Dubois County School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278078169,
        AWATER: 1822189,
        INTPTLAT: '+38.2840456',
        INTPTLON: '-086.8069779',
        ELSDLEA: '',
        UNSDLEA: '10640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2408, 38.9945],
            [-87.1293, 38.9946],
            [-87.1291, 39.0821],
            [-87.0632, 39.168],
            [-87.0546, 39.1681],
            [-86.8931, 39.1679],
            [-86.9605, 39.1179],
            [-86.9596, 38.9032],
            [-87.0988, 38.904],
            [-87.241, 38.9079],
            [-87.2408, 38.9945],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800008',
        NAME: 'White River Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540340911,
        AWATER: 4064171,
        INTPTLAT: '+39.0262822',
        INTPTLON: '-087.0648079',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8931, 39.1679],
            [-86.8321, 39.167],
            [-86.8314, 38.9043],
            [-86.9043, 38.9042],
            [-86.9596, 38.9032],
            [-86.9605, 39.1179],
            [-86.8931, 39.1679],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800600',
        NAME: 'Bloomfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304422348,
        AWATER: 1743026,
        INTPTLAT: '+39.0269816',
        INTPTLON: '-086.8930089',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2404, 39.2591],
            [-87.2401, 39.3028],
            [-87.1751, 39.3008],
            [-87.1459, 39.2072],
            [-87.0632, 39.168],
            [-87.1291, 39.0821],
            [-87.2409, 39.0844],
            [-87.2404, 39.2591],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810110',
        NAME: 'Shakamak Schools Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206511065,
        AWATER: 1724485,
        INTPTLAT: '+39.1826410',
        INTPTLON: '-087.1872042',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0535, 40.0048],
            [-85.0345, 40.0048],
            [-84.8108, 40.0051],
            [-84.8123, 39.9273],
            [-84.8126, 39.9022],
            [-84.9394, 39.8744],
            [-85.0056, 39.9033],
            [-85.0535, 40.0048],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808190',
        NAME: 'Northeastern Wayne Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246576918,
        AWATER: 556007,
        INTPTLAT: '+39.9491233',
        INTPTLON: '-084.9320719',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0925, 38.728],
            [-86.9233, 38.7308],
            [-86.9242, 38.5054],
            [-86.9425, 38.4926],
            [-87.0355, 38.5123],
            [-87.0917, 38.6116],
            [-87.0925, 38.728],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800330',
        NAME: 'Barr-Reeve Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300266933,
        AWATER: 5694903,
        INTPTLAT: '+38.6351071',
        INTPTLON: '-086.9899801',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6319, 39.4411],
            [-86.5403, 39.4988],
            [-86.4176, 39.5568],
            [-86.2516, 39.5167],
            [-86.2513, 39.4306],
            [-86.3617, 39.4279],
            [-86.3623, 39.3401],
            [-86.3814, 39.3398],
            [-86.5743, 39.3397],
            [-86.6308, 39.3469],
            [-86.6319, 39.4411],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806510',
        NAME: 'Martinsville Schools Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 509183088,
        AWATER: 9326698,
        INTPTLAT: '+39.4385923',
        INTPTLON: '-086.4414276',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4179, 39.6305],
            [-86.3263, 39.6322],
            [-86.2499, 39.6336],
            [-86.2503, 39.5167],
            [-86.2516, 39.5167],
            [-86.4176, 39.5568],
            [-86.4179, 39.6305],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807140',
        NAME: 'Mooresville Consolidated School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151289771,
        AWATER: 2515609,
        INTPTLAT: '+39.5833441',
        INTPTLON: '-086.3278336',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4685, 40.431],
            [-86.3758, 40.4319],
            [-86.243, 40.3737],
            [-86.2428, 40.3088],
            [-86.2427, 40.2158],
            [-86.3565, 40.1781],
            [-86.4318, 40.177],
            [-86.432, 40.2573],
            [-86.4684, 40.3584],
            [-86.4685, 40.431],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802130',
        NAME: 'Clinton Central School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464278038,
        AWATER: 35984,
        INTPTLAT: '+40.3052307',
        INTPTLON: '-086.3537093',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2371, 38.7678],
            [-86.1667, 38.7734],
            [-86.0917, 38.7834],
            [-85.9084, 38.7562],
            [-85.8883, 38.7344],
            [-85.8881, 38.6488],
            [-86.0157, 38.648],
            [-86.0143, 38.5601],
            [-86.1224, 38.558],
            [-86.1967, 38.5568],
            [-86.2331, 38.6439],
            [-86.2371, 38.7678],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809810',
        NAME: 'Salem Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550276422,
        AWATER: 4056939,
        INTPTLAT: '+38.6835012',
        INTPTLON: '-086.0856484',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3101, 38.7331],
            [-86.2753, 38.7638],
            [-86.2371, 38.7678],
            [-86.2331, 38.6439],
            [-86.1967, 38.5568],
            [-86.1224, 38.558],
            [-86.1233, 38.4197],
            [-86.255, 38.4227],
            [-86.3087, 38.4229],
            [-86.308, 38.5862],
            [-86.3087, 38.6881],
            [-86.3101, 38.7331],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812930',
        NAME: 'West Washington School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 414841928,
        AWATER: 2291183,
        INTPTLAT: '+38.5558147',
        INTPTLON: '-086.2408860',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3755, 40.4926],
            [-86.184, 40.4753],
            [-86.1271, 40.4407],
            [-86.1271, 40.4043],
            [-86.243, 40.3737],
            [-86.3758, 40.4319],
            [-86.3755, 40.4926],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804470',
        NAME: 'Western School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143818708,
        AWATER: 321299,
        INTPTLAT: '+40.4354804',
        INTPTLON: '-086.2531105',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3744, 40.5614],
            [-86.165, 40.5626],
            [-86.0133, 40.5641],
            [-86.0129, 40.4777],
            [-86.0795, 40.4769],
            [-86.184, 40.4753],
            [-86.3755, 40.4926],
            [-86.3744, 40.5614],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802040',
        NAME: 'Northwestern School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260571815,
        AWATER: 1167712,
        INTPTLAT: '+40.5207329',
        INTPTLON: '-086.2056789',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.185, 41.4788],
            [-86.0595, 41.4792],
            [-86.0586, 41.3756],
            [-86.1939, 41.3767],
            [-86.2325, 41.3916],
            [-86.185, 41.4788],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800900',
        NAME: 'Bremen Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159234926,
        AWATER: 1600741,
        INTPTLAT: '+41.4282968',
        INTPTLON: '-086.1301833',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4663, 41.3458],
            [-86.4663, 41.3748],
            [-86.3576, 41.3761],
            [-86.2325, 41.3916],
            [-86.1939, 41.3767],
            [-86.1926, 41.2741],
            [-86.3477, 41.274],
            [-86.4666, 41.2737],
            [-86.4663, 41.3458],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809060',
        NAME: 'Plymouth Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262360110,
        AWATER: 4535397,
        INTPTLAT: '+41.3240709',
        INTPTLON: '-086.3253166',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5828, 41.172],
            [-86.5822, 41.2591],
            [-86.4666, 41.2737],
            [-86.3477, 41.274],
            [-86.3467, 41.172],
            [-86.3569, 41.0846],
            [-86.4338, 41.0843],
            [-86.5834, 41.0846],
            [-86.5828, 41.172],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802520',
        NAME: 'Culver Community Schools Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381150289,
        AWATER: 10948830,
        INTPTLAT: '+41.1750575',
        INTPTLON: '-086.4597959',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3477, 41.274],
            [-86.1926, 41.2741],
            [-86.1528, 41.1723],
            [-86.1879, 41.1723],
            [-86.3467, 41.172],
            [-86.3477, 41.274],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800180',
        NAME: 'Argos Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183458931,
        AWATER: 439110,
        INTPTLAT: '+41.2230306',
        INTPTLON: '-086.2462914',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3266, 39.7195],
            [-86.1962, 39.7216],
            [-86.1963, 39.7163],
            [-86.2429, 39.6337],
            [-86.2499, 39.6336],
            [-86.3263, 39.6322],
            [-86.3266, 39.7195],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802640',
        NAME: 'Decatur Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83295409,
        AWATER: 195021,
        INTPTLAT: '+39.6786557',
        INTPTLON: '-086.2754512',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0692, 39.927],
            [-85.9376, 39.9271],
            [-85.9531, 39.8268],
            [-85.972, 39.8265],
            [-86.0821, 39.8551],
            [-86.0692, 39.927],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805670',
        NAME: 'Lawrence Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108975099,
        AWATER: 4188930,
        INTPTLAT: '+39.8798709',
        INTPTLON: '-086.0114259',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3263, 39.924],
            [-86.2397, 39.9261],
            [-86.2031, 39.9266],
            [-86.2164, 39.8238],
            [-86.2758, 39.8236],
            [-86.3273, 39.8225],
            [-86.3263, 39.924],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808910',
        NAME: 'Pike Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105965811,
        AWATER: 6181081,
        INTPTLAT: '+39.8782716',
        INTPTLON: '-086.2669563',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2031, 39.9266],
            [-86.0765, 39.9269],
            [-86.0692, 39.927],
            [-86.0821, 39.8551],
            [-86.0839, 39.8473],
            [-86.0838, 39.8342],
            [-86.2164, 39.8238],
            [-86.2031, 39.9266],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812720',
        NAME: 'Washington Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97644482,
        AWATER: 2670197,
        INTPTLAT: '+39.8853630',
        INTPTLON: '-086.1441502',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.972, 39.8265],
            [-85.9531, 39.8268],
            [-85.9524, 39.7828],
            [-85.9517, 39.7264],
            [-86.0734, 39.724],
            [-86.0823, 39.7304],
            [-86.0648, 39.8255],
            [-85.972, 39.8265],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812360',
        NAME: 'Warren Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101018017,
        AWATER: 209265,
        INTPTLAT: '+39.7692952',
        INTPTLON: '-086.0100789',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0824, 39.6946],
            [-86.0734, 39.724],
            [-85.9517, 39.7264],
            [-85.9517, 39.6971],
            [-85.9521, 39.6383],
            [-86.0827, 39.6364],
            [-86.0824, 39.6946],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803750',
        NAME: 'Franklin Township Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105620339,
        AWATER: 22840,
        INTPTLAT: '+39.6773296',
        INTPTLON: '-086.0107899',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1963, 39.7163],
            [-86.1113, 39.7233],
            [-86.0824, 39.6946],
            [-86.0827, 39.6364],
            [-86.1413, 39.6353],
            [-86.2339, 39.6339],
            [-86.2393, 39.6338],
            [-86.2429, 39.6337],
            [-86.1963, 39.7163],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808820',
        NAME: 'Perry Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110002694,
        AWATER: 264394,
        INTPTLAT: '+39.6742165',
        INTPTLON: '-086.1526461',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8575, 39.3493],
            [-85.7418, 39.3502],
            [-85.6845, 39.35],
            [-85.6867, 39.2614],
            [-85.9222, 39.2595],
            [-85.8575, 39.3493],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803570',
        NAME: 'Flat Rock-Hawcreek School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176512798,
        AWATER: 378253,
        INTPTLAT: '+39.3004477',
        INTPTLON: '-085.7897818',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0818, 39.2263],
            [-86.0067, 39.2279],
            [-85.99, 39.3466],
            [-85.952, 39.3474],
            [-85.8575, 39.3493],
            [-85.9222, 39.2595],
            [-85.6867, 39.2614],
            [-85.6868, 39.1309],
            [-85.7998, 39.1289],
            [-85.7988, 39.0685],
            [-85.8606, 39.0409],
            [-86.0617, 39.036],
            [-86.0803, 39.0503],
            [-86.0818, 39.2263],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800360',
        NAME: 'Bartholomew County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 773077173,
        AWATER: 6435878,
        INTPTLAT: '+39.1657361',
        INTPTLON: '-085.9027552',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9394, 39.8744],
            [-84.8126, 39.9022],
            [-84.8144, 39.7436],
            [-84.8144, 39.7266],
            [-84.9277, 39.7266],
            [-84.9394, 39.8744],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809510',
        NAME: 'Richmond Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199093608,
        AWATER: 2182273,
        INTPTLAT: '+39.8106500',
        INTPTLON: '-084.8758147',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1224, 38.558],
            [-86.0143, 38.5601],
            [-86.0157, 38.648],
            [-85.8881, 38.6488],
            [-85.8479, 38.5613],
            [-85.8848, 38.5035],
            [-85.9933, 38.4885],
            [-85.9946, 38.4183],
            [-86.0328, 38.4179],
            [-86.1233, 38.4197],
            [-86.1224, 38.558],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803000',
        NAME: 'East Washington School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365424337,
        AWATER: 1093635,
        INTPTLAT: '+38.5387606',
        INTPTLON: '-085.9943433',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.255, 38.4227],
            [-86.1233, 38.4197],
            [-86.0328, 38.4179],
            [-86.0141, 38.3018],
            [-86.0511, 38.2583],
            [-86.2003, 38.2616],
            [-86.2767, 38.2341],
            [-86.255, 38.4227],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800690',
        NAME: 'North Harrison Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 383212180,
        AWATER: 725129,
        INTPTLAT: '+38.3367471',
        INTPTLON: '-086.1494407',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0511, 38.2583],
            [-86.0141, 38.3018],
            [-85.9552, 38.2605],
            [-85.9577, 38.1866],
            [-86.0507, 38.1851],
            [-86.0511, 38.2583],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803720',
        NAME: 'Lanesville Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90356269,
        AWATER: 63997,
        INTPTLAT: '+38.2337253',
        INTPTLON: '-086.0075419',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0795, 40.4769],
            [-86.1271, 40.4407],
            [-86.184, 40.4753],
            [-86.0795, 40.4769],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805370',
        NAME: 'Kokomo-Center Township Consolidated School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66400628,
        AWATER: 191617,
        INTPTLAT: '+40.4830463',
        INTPTLON: '-086.1389423',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0346, 39.7148],
            [-84.9277, 39.7266],
            [-84.8144, 39.7266],
            [-84.8148, 39.616],
            [-84.8152, 39.5487],
            [-84.8157, 39.4794],
            [-84.9306, 39.4799],
            [-85.0361, 39.5262],
            [-85.0346, 39.7148],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811610',
        NAME: 'Union County-College Corner Joint School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465382203,
        AWATER: 10311808,
        INTPTLAT: '+39.6152589',
        INTPTLON: '-084.9145998',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0119, 39.6919],
            [-86.7937, 39.6889],
            [-86.7949, 39.6021],
            [-87.0131, 39.6048],
            [-87.0119, 39.6919],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803990',
        NAME: 'Greencastle Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180271223,
        AWATER: 602586,
        INTPTLAT: '+39.6574711',
        INTPTLON: '-086.9194703',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0131, 39.6048],
            [-86.7949, 39.6021],
            [-86.7937, 39.6889],
            [-86.6872, 39.6881],
            [-86.6566, 39.6014],
            [-86.6876, 39.5219],
            [-86.9033, 39.5304],
            [-86.94, 39.4733],
            [-87.0147, 39.4738],
            [-87.0131, 39.6048],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810500',
        NAME: 'South Putnam Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407038132,
        AWATER: 1003277,
        INTPTLAT: '+39.5756618',
        INTPTLON: '-086.8326655',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.457, 39.7984],
            [-86.4383, 39.7987],
            [-86.3268, 39.8008],
            [-86.3266, 39.7195],
            [-86.438, 39.7184],
            [-86.4571, 39.7181],
            [-86.457, 39.7984],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800270',
        NAME: 'Avon Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99295320,
        AWATER: 893147,
        INTPTLAT: '+39.7607134',
        INTPTLON: '-086.3941455',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6872, 39.6881],
            [-86.6871, 39.7172],
            [-86.4571, 39.7181],
            [-86.438, 39.7184],
            [-86.4371, 39.6303],
            [-86.5598, 39.6009],
            [-86.6566, 39.6014],
            [-86.6872, 39.6881],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806660',
        NAME: 'Mill Creek Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264123328,
        AWATER: 1118936,
        INTPTLAT: '+39.6628360',
        INTPTLON: '-086.5726982',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2981, 39.5255],
            [-85.0361, 39.5262],
            [-84.9306, 39.4799],
            [-84.8157, 39.4794],
            [-84.8176, 39.3918],
            [-84.8194, 39.3052],
            [-85.0967, 39.3101],
            [-85.1694, 39.4119],
            [-85.2969, 39.4106],
            [-85.2976, 39.4533],
            [-85.2981, 39.5255],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803700',
        NAME: 'Franklin County Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760101494,
        AWATER: 16558699,
        INTPTLAT: '+39.4289698',
        INTPTLON: '-085.0314470',
        ELSDLEA: '',
        UNSDLEA: '03700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6951, 39.9228],
            [-86.4498, 39.9233],
            [-86.4309, 39.9235],
            [-86.4383, 39.7987],
            [-86.457, 39.7984],
            [-86.5055, 39.8344],
            [-86.6868, 39.8197],
            [-86.6951, 39.8648],
            [-86.6951, 39.9228],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808100',
        NAME: 'North West Hendricks Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252595787,
        AWATER: 286710,
        INTPTLAT: '+39.8753539',
        INTPTLON: '-086.5550946',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6867, 39.2614],
            [-85.6845, 39.35],
            [-85.6293, 39.4528],
            [-85.2976, 39.4533],
            [-85.2969, 39.4106],
            [-85.3152, 39.2587],
            [-85.4401, 39.1956],
            [-85.5663, 39.1328],
            [-85.6868, 39.1309],
            [-85.6867, 39.2614],
          ],
          [
            [-85.5567, 39.3505],
            [-85.5175, 39.2352],
            [-85.3883, 39.2496],
            [-85.3855, 39.367],
            [-85.5567, 39.3505],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802610',
        NAME: 'Decatur County Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 762106605,
        AWATER: 1410040,
        INTPTLAT: '+39.2057127',
        INTPTLON: '-085.5893656',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8366, 39.5251],
            [-85.8002, 39.626],
            [-85.7058, 39.6259],
            [-85.7035, 39.4971],
            [-85.7553, 39.4384],
            [-85.8367, 39.4383],
            [-85.8366, 39.5251],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810140',
        NAME: 'Shelbyville Central Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177775527,
        AWATER: 1601865,
        INTPTLAT: '+39.5385685',
        INTPTLON: '-085.7704435',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8389, 39.6984],
            [-85.7254, 39.6989],
            [-85.6332, 39.6985],
            [-85.6325, 39.6985],
            [-85.6293, 39.4528],
            [-85.6845, 39.35],
            [-85.7418, 39.3502],
            [-85.7553, 39.4384],
            [-85.7035, 39.4971],
            [-85.7058, 39.6259],
            [-85.8002, 39.626],
            [-85.8389, 39.6984],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803210',
        NAME: 'Shelby Eastern Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400194771,
        AWATER: 612617,
        INTPTLAT: '+39.5415308',
        INTPTLON: '-085.6973116',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9525, 39.4359],
            [-85.9514, 39.5231],
            [-85.8366, 39.5251],
            [-85.8367, 39.4383],
            [-85.7553, 39.4384],
            [-85.7418, 39.3502],
            [-85.8575, 39.3493],
            [-85.952, 39.3474],
            [-85.9525, 39.4359],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810830',
        NAME: 'Southwestern Shelby County Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272509319,
        AWATER: 1027765,
        INTPTLAT: '+39.4294744',
        INTPTLON: '-085.8652664',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7944, 38.9044],
            [-86.6829, 38.9047],
            [-86.6834, 38.7726],
            [-86.6836, 38.7677],
            [-86.6834, 38.7556],
            [-86.6831, 38.6866],
            [-86.6824, 38.5264],
            [-86.8314, 38.5264],
            [-86.8499, 38.7458],
            [-86.795, 38.745],
            [-86.7944, 38.9044],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810170',
        NAME: 'Shoals Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 529523685,
        AWATER: 5882515,
        INTPTLAT: '+38.6971465',
        INTPTLON: '-086.7654688',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2188, 40.3067],
            [-85.0933, 40.3079],
            [-85.1111, 40.1214],
            [-85.2149, 40.1061],
            [-85.2174, 40.2349],
            [-85.2188, 40.3067],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807080',
        NAME: 'Monroe Central School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220804571,
        AWATER: 580898,
        INTPTLAT: '+40.2141256',
        INTPTLON: '-085.1555126',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8057, 39.9446],
            [-85.6924, 39.9454],
            [-85.7264, 39.7573],
            [-85.8399, 39.7841],
            [-85.8057, 39.9446],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804050',
        NAME: 'Greenfield-Central Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215580829,
        AWATER: 1456918,
        INTPTLAT: '+39.8503259',
        INTPTLON: '-085.7615142',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1657, 40.7429],
            [-85.9418, 40.7725],
            [-85.9395, 40.651],
            [-85.9388, 40.5648],
            [-86.0133, 40.5641],
            [-86.165, 40.5626],
            [-86.1657, 40.7429],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806090',
        NAME: 'Maconaquah School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395876301,
        AWATER: 3878391,
        INTPTLAT: '+40.6589088',
        INTPTLON: '-086.0521524',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1683, 40.7859],
            [-86.0542, 40.7862],
            [-85.9423, 40.8235],
            [-85.9418, 40.7725],
            [-86.1657, 40.7429],
            [-86.1683, 40.7859],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808850',
        NAME: 'Peru Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109368960,
        AWATER: 1926911,
        INTPTLAT: '+40.7848869',
        INTPTLON: '-086.0443895',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0926, 40.2148],
            [-86.6956, 40.2144],
            [-86.6958, 40.1787],
            [-86.6958, 40.0399],
            [-86.8664, 40.0396],
            [-86.968, 40.0392],
            [-87.0915, 40.039],
            [-87.0926, 40.2148],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1807900',
        NAME: 'North Montgomery Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 650828354,
        AWATER: 100644,
        INTPTLAT: '+40.1361049',
        INTPTLON: '-086.8939882',
        ELSDLEA: '',
        UNSDLEA: '07900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9302, 41.4341],
            [-86.8139, 41.4328],
            [-86.7118, 41.3753],
            [-86.7286, 41.3461],
            [-86.7835, 41.2855],
            [-86.9301, 41.2368],
            [-86.9302, 41.4341],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800107',
        NAME: 'Tri-Township Consolidated School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248997995,
        AWATER: 7211,
        INTPTLAT: '+41.3427740',
        INTPTLON: '-086.8423155',
        ELSDLEA: '',
        UNSDLEA: '00107',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6949, 40.482],
            [-86.5815, 40.4822],
            [-86.4685, 40.431],
            [-86.4684, 40.3584],
            [-86.5624, 40.3442],
            [-86.6947, 40.3886],
            [-86.6949, 40.482],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809720',
        NAME: 'Rossville Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195059079,
        AWATER: 30594,
        INTPTLAT: '+40.4128621',
        INTPTLON: '-086.5765377',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6956, 40.2144],
            [-86.6947, 40.3886],
            [-86.5624, 40.3442],
            [-86.5623, 40.2572],
            [-86.432, 40.2573],
            [-86.4318, 40.177],
            [-86.6958, 40.1787],
            [-86.6956, 40.2144],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802160',
        NAME: 'Clinton Prairie School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338856434,
        AWATER: 136909,
        INTPTLAT: '+40.2635565',
        INTPTLON: '-086.6048899',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5624, 40.3442],
            [-86.4684, 40.3584],
            [-86.432, 40.2573],
            [-86.5623, 40.2572],
            [-86.5624, 40.3442],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803660',
        NAME: 'Frankfort Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104688492,
        AWATER: 288224,
        INTPTLAT: '+40.3044105',
        INTPTLON: '-086.5105293',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3854, 39.8242],
            [-87.368, 39.8676],
            [-87.2924, 39.8533],
            [-87.29, 39.6959],
            [-87.1232, 39.6938],
            [-87.1242, 39.6058],
            [-87.199, 39.6071],
            [-87.3824, 39.6079],
            [-87.3854, 39.8242],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810900',
        NAME: 'Southwest Parke Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356918372,
        AWATER: 2524152,
        INTPTLAT: '+39.6786242',
        INTPTLON: '-087.3293468',
        ELSDLEA: '',
        UNSDLEA: '10900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7118, 41.3753],
            [-86.6425, 41.4326],
            [-86.5823, 41.4327],
            [-86.4666, 41.433],
            [-86.4663, 41.3748],
            [-86.4663, 41.3458],
            [-86.6985, 41.3462],
            [-86.7286, 41.3461],
            [-86.7118, 41.3753],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808460',
        NAME: 'Oregon-Davis School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185296243,
        AWATER: 1453454,
        INTPTLAT: '+41.3863866',
        INTPTLON: '-086.5831002',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4338, 41.0843],
            [-86.3569, 41.0846],
            [-86.3467, 41.172],
            [-86.1879, 41.1723],
            [-86.13, 41.084],
            [-86.13, 40.9961],
            [-86.1685, 40.9961],
            [-86.3382, 40.9964],
            [-86.4335, 41.026],
            [-86.4338, 41.0843],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809630',
        NAME: 'Rochester Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363672116,
        AWATER: 4086928,
        INTPTLAT: '+41.0777175',
        INTPTLON: '-086.2714175',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9864, 40.8379],
            [-86.9298, 40.8388],
            [-86.928, 40.7209],
            [-86.9857, 40.7212],
            [-86.9815, 40.562],
            [-87.0012, 40.5622],
            [-87.0954, 40.5629],
            [-87.0973, 40.6495],
            [-87.2108, 40.6496],
            [-87.2673, 40.7366],
            [-87.2678, 40.8223],
            [-86.9864, 40.8379],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811430',
        NAME: 'Tri-County School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 581544189,
        AWATER: 503641,
        INTPTLAT: '+40.7277029',
        INTPTLON: '-087.0826000',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4325, 41.5664],
            [-87.4131, 41.5226],
            [-87.4518, 41.5229],
            [-87.4325, 41.5664],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804170',
        NAME: 'Griffith Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15875983,
        AWATER: 0,
        INTPTLAT: '+41.5321824',
        INTPTLON: '-087.4292524',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2032, 38.9138],
            [-85.1358, 38.9296],
            [-85.1403, 38.9032],
            [-84.8707, 38.9009],
            [-84.795, 38.8573],
            [-84.8873, 38.7948],
            [-85.0235, 38.7619],
            [-85.2015, 38.6913],
            [-85.2032, 38.9138],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811220',
        NAME: 'Switzerland County School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571436384,
        AWATER: 7267095,
        INTPTLAT: '+38.8258545',
        INTPTLON: '-085.0296810',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6755, 40.6528],
            [-85.6386, 40.6531],
            [-85.4488, 40.6536],
            [-85.447, 40.5669],
            [-85.4443, 40.3791],
            [-85.5594, 40.379],
            [-85.5583, 40.4571],
            [-85.5592, 40.5095],
            [-85.5601, 40.5669],
            [-85.6748, 40.6102],
            [-85.6755, 40.6528],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803030',
        NAME: 'Eastbrook Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358354246,
        AWATER: 574676,
        INTPTLAT: '+40.5319517',
        INTPTLON: '-085.5178144',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.863, 40.4787],
            [-85.7295, 40.4801],
            [-85.6727, 40.4803],
            [-85.5583, 40.4571],
            [-85.5594, 40.379],
            [-85.577, 40.3064],
            [-85.7471, 40.3063],
            [-85.7856, 40.3063],
            [-85.8602, 40.3057],
            [-85.8616, 40.3347],
            [-85.8621, 40.4069],
            [-85.863, 40.4787],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806240',
        NAME: 'Madison-Grant United School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454655968,
        AWATER: 140845,
        INTPTLAT: '+40.3866817',
        INTPTLON: '-085.7145696',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9395, 40.651],
            [-85.6755, 40.6528],
            [-85.6748, 40.6102],
            [-85.7295, 40.4801],
            [-85.863, 40.4787],
            [-85.9388, 40.5648],
            [-85.9395, 40.651],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808340',
        NAME: 'Oak Hill United School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309272913,
        AWATER: 813259,
        INTPTLAT: '+40.5791243',
        INTPTLON: '-085.8094908',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.0277, 39.3967],
              [-85.99, 39.3466],
              [-86.0067, 39.2279],
              [-86.0818, 39.2263],
              [-86.0948, 39.344],
              [-86.0277, 39.3967],
            ],
          ],
          [
            [
              [-87.5237, 41.7599],
              [-87.2078, 41.761],
              [-86.9333, 41.761],
              [-87.0096, 41.6817],
              [-87.1445, 41.6457],
              [-87.2229, 41.6326],
              [-87.4239, 41.6554],
              [-87.4625, 41.6853],
              [-87.4943, 41.6962],
              [-87.5242, 41.717],
              [-87.5237, 41.7599],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1899997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 146791383,
        AWATER: 540476432,
        INTPTLAT: '+39.3960143',
        INTPTLON: '-086.0581156',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4235, 41.5256],
            [-85.3085, 41.5262],
            [-85.1941, 41.5264],
            [-85.1929, 41.38],
            [-85.1921, 41.2642],
            [-85.3078, 41.2642],
            [-85.3094, 41.3516],
            [-85.3084, 41.4379],
            [-85.4234, 41.4386],
            [-85.4235, 41.5256],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802970',
        NAME: 'East Noble School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366547479,
        AWATER: 6532988,
        INTPTLAT: '+41.4177077',
        INTPTLON: '-085.2762479',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6547, 41.5233],
            [-85.4235, 41.5256],
            [-85.4234, 41.4386],
            [-85.5382, 41.4379],
            [-85.5363, 41.2952],
            [-85.6519, 41.2948],
            [-85.6535, 41.4369],
            [-85.6547, 41.5233],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1812900',
        NAME: 'West Noble School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334262343,
        AWATER: 3971553,
        INTPTLAT: '+41.4308679',
        INTPTLON: '-085.5695570',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1208, 39.5933],
            [-86.0827, 39.6364],
            [-85.9521, 39.6383],
            [-85.9519, 39.5379],
            [-86.1393, 39.5343],
            [-86.1208, 39.5933],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801890',
        NAME: 'Clark-Pleasant Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157367139,
        AWATER: 90749,
        INTPTLAT: '+39.5874763',
        INTPTLON: '-086.0294680',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0261, 39.4341],
            [-85.9525, 39.4359],
            [-85.952, 39.3474],
            [-85.99, 39.3466],
            [-86.0277, 39.3967],
            [-86.0261, 39.4341],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803240',
        NAME: 'Edinburgh Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46558479,
        AWATER: 250553,
        INTPTLAT: '+39.3924220',
        INTPTLON: '-085.9770386',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1413, 39.6353],
            [-86.0827, 39.6364],
            [-86.1208, 39.5933],
            [-86.1413, 39.6353],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804110',
        NAME: 'Greenwood Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21201568,
        AWATER: 1883,
        INTPTLAT: '+39.6167707',
        INTPTLON: '-086.1139317',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9666, 41.4417],
            [-84.8519, 41.4441],
            [-84.8047, 41.5301],
            [-84.8045, 41.5129],
            [-84.8039, 41.3753],
            [-84.8036, 41.2713],
            [-84.9626, 41.2677],
            [-84.9666, 41.4417],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803060',
        NAME: 'DeKalb County Eastern Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298628364,
        AWATER: 321619,
        INTPTLAT: '+41.3783768',
        INTPTLON: '-084.8791895',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3094, 41.3516],
            [-85.3078, 41.2642],
            [-85.3386, 41.1791],
            [-85.4245, 41.1785],
            [-85.4241, 41.265],
            [-85.4245, 41.3522],
            [-85.3094, 41.3516],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810230',
        NAME: 'Smith-Green Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182815829,
        AWATER: 1913106,
        INTPTLAT: '+41.2687618',
        INTPTLON: '-085.3705600',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4866, 41.7593],
            [-85.2922, 41.7599],
            [-85.1968, 41.7596],
            [-85.1958, 41.7002],
            [-85.3107, 41.7001],
            [-85.3085, 41.5262],
            [-85.4235, 41.5256],
            [-85.4728, 41.6121],
            [-85.4866, 41.7593],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805520',
        NAME: 'Lakeland School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376073347,
        AWATER: 9511408,
        INTPTLAT: '+41.6710316',
        INTPTLON: '-085.3749799',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2423, 40.8012],
            [-86.168, 40.8164],
            [-86.1683, 40.7859],
            [-86.1657, 40.7429],
            [-86.165, 40.5626],
            [-86.3744, 40.5614],
            [-86.3915, 40.6924],
            [-86.2423, 40.8012],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810680',
        NAME: 'Southeastern School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 408595620,
        AWATER: 2029234,
        INTPTLAT: '+40.6706771',
        INTPTLON: '-086.2691953',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0836, 41.7005],
            [-84.8059, 41.7027],
            [-84.806, 41.6961],
            [-84.8058, 41.6164],
            [-84.9699, 41.6132],
            [-84.9685, 41.5281],
            [-85.0808, 41.527],
            [-85.0836, 41.7005],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811100',
        NAME: 'Steuben County Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301627075,
        AWATER: 10573366,
        INTPTLAT: '+41.6224288',
        INTPTLON: '-084.9625745',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.526, 40.9118],
            [-87.2764, 40.9115],
            [-87.2678, 40.8223],
            [-87.2673, 40.7366],
            [-87.4391, 40.7367],
            [-87.4385, 40.6499],
            [-87.5262, 40.6498],
            [-87.5257, 40.8173],
            [-87.526, 40.9118],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810470',
        NAME: 'South Newton School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494017749,
        AWATER: 250933,
        INTPTLAT: '+40.8065653',
        INTPTLON: '-087.4107782',
        ELSDLEA: '',
        UNSDLEA: '10470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1282, 40.2172],
            [-85.8619, 40.2189],
            [-85.8633, 40.1039],
            [-85.9586, 40.1025],
            [-86.0903, 40.1013],
            [-86.1281, 40.1014],
            [-86.1282, 40.2172],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804260',
        NAME: 'Hamilton Heights School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286003339,
        AWATER: 4731852,
        INTPTLAT: '+40.1542826',
        INTPTLON: '-085.9911277',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1281, 40.1014],
            [-86.0903, 40.1013],
            [-86.0896, 40.0002],
            [-86.2411, 39.9988],
            [-86.2415, 40.0708],
            [-86.1281, 40.1014],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1813080',
        NAME: 'Westfield-Washington Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144214898,
        AWATER: 766521,
        INTPTLAT: '+40.0515828',
        INTPTLON: '-086.1708800',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1968, 41.7596],
            [-85.084, 41.7597],
            [-85.0836, 41.7005],
            [-85.0808, 41.527],
            [-85.1941, 41.5264],
            [-85.3085, 41.5262],
            [-85.3107, 41.7001],
            [-85.1958, 41.7002],
            [-85.1968, 41.7596],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809300',
        NAME: 'Prairie Heights Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412742102,
        AWATER: 13910063,
        INTPTLAT: '+41.6352968',
        INTPTLON: '-085.1867632',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1941, 41.5264],
            [-85.0808, 41.527],
            [-84.9685, 41.5281],
            [-84.9666, 41.4417],
            [-84.9626, 41.2677],
            [-85.0778, 41.2656],
            [-85.0787, 41.381],
            [-85.1929, 41.38],
            [-85.1941, 41.5264],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1801590',
        NAME: 'DeKalb County Central United School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427517792,
        AWATER: 1865014,
        INTPTLAT: '+41.4178603',
        INTPTLON: '-085.0709854',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1929, 41.38],
            [-85.0787, 41.381],
            [-85.0778, 41.2656],
            [-85.1921, 41.2642],
            [-85.1929, 41.38],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803840',
        NAME: 'Garrett-Keyser-Butler Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123422472,
        AWATER: 292686,
        INTPTLAT: '+41.3253673',
        INTPTLON: '-085.1357326',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8058, 41.6164],
            [-84.8047, 41.5301],
            [-84.8519, 41.4441],
            [-84.9666, 41.4417],
            [-84.9685, 41.5281],
            [-84.9699, 41.6132],
            [-84.8058, 41.6164],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1804230',
        NAME: 'Hamilton Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217540630,
        AWATER: 4007877,
        INTPTLAT: '+41.5377407',
        INTPTLON: '-084.8907563',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8321, 39.167],
            [-86.683, 39.1657],
            [-86.6823, 38.9924],
            [-86.6829, 38.9047],
            [-86.7944, 38.9044],
            [-86.8314, 38.9043],
            [-86.8321, 39.167],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1803090',
        NAME: 'Eastern Greene County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374491376,
        AWATER: 170459,
        INTPTLAT: '+39.0371908',
        INTPTLON: '-086.7528498',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2409, 39.0844],
            [-87.1291, 39.0821],
            [-87.1293, 38.9946],
            [-87.2408, 38.9945],
            [-87.2409, 39.0844],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805910',
        NAME: 'Linton-Stockton School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93385211,
        AWATER: 1599153,
        INTPTLAT: '+39.0390972',
        INTPTLON: '-087.1889470',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.199, 39.6071],
            [-87.1242, 39.6058],
            [-87.1232, 39.6938],
            [-87.0119, 39.6919],
            [-87.0131, 39.6048],
            [-87.0147, 39.4738],
            [-86.94, 39.4733],
            [-86.9423, 39.3999],
            [-86.9425, 39.342],
            [-87.0536, 39.3426],
            [-87.0546, 39.1681],
            [-87.0632, 39.168],
            [-87.1459, 39.2072],
            [-87.1751, 39.3008],
            [-87.2401, 39.3028],
            [-87.239, 39.5206],
            [-87.199, 39.6071],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800840',
        NAME: 'Clay Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 904220299,
        AWATER: 7126394,
        INTPTLAT: '+39.4381583',
        INTPTLON: '-087.0993837',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4449, 38.913],
            [-85.2032, 38.9138],
            [-85.2015, 38.6913],
            [-85.3331, 38.7362],
            [-85.4432, 38.719],
            [-85.4625, 38.8247],
            [-85.5735, 38.825],
            [-85.6087, 38.7079],
            [-85.6833, 38.7367],
            [-85.6838, 38.8154],
            [-85.6106, 38.8242],
            [-85.4449, 38.913],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806120',
        NAME: 'Madison Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 650553971,
        AWATER: 4063771,
        INTPTLAT: '+38.8223104',
        INTPTLON: '-085.3942544',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4432, 38.719],
            [-85.4283, 38.5863],
            [-85.57, 38.6062],
            [-85.6087, 38.7079],
            [-85.5735, 38.825],
            [-85.4625, 38.8247],
            [-85.4432, 38.719],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1810800',
        NAME: 'Southwestern Jefferson County Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283479373,
        AWATER: 1780845,
        INTPTLAT: '+38.7039090',
        INTPTLON: '-085.5130536',
        ELSDLEA: '',
        UNSDLEA: '10800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4951, 41.5634],
            [-86.3315, 41.5638],
            [-86.3576, 41.3761],
            [-86.4663, 41.3748],
            [-86.4666, 41.433],
            [-86.5823, 41.4327],
            [-86.5819, 41.5199],
            [-86.4951, 41.5634],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809120',
        NAME: 'John Glenn School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322860670,
        AWATER: 1687789,
        INTPTLAT: '+41.4820353',
        INTPTLON: '-086.4403114',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1939, 41.3767],
            [-86.0586, 41.3756],
            [-86.0005, 41.3468],
            [-85.9983, 41.2604],
            [-86.054, 41.1731],
            [-86.1528, 41.1723],
            [-86.1926, 41.2741],
            [-86.1939, 41.3767],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811490',
        NAME: 'Triton School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273347017,
        AWATER: 788674,
        INTPTLAT: '+41.2801355',
        INTPTLON: '-086.0990519',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3315, 41.5638],
            [-86.2152, 41.5645],
            [-86.185, 41.4788],
            [-86.2325, 41.3916],
            [-86.3576, 41.3761],
            [-86.3315, 41.5638],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1811910',
        NAME: 'Union-North United School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219352451,
        AWATER: 967440,
        INTPTLAT: '+41.4757940',
        INTPTLON: '-086.2762496',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.356, 40.069],
            [-86.2415, 40.0708],
            [-86.2411, 39.9988],
            [-86.2397, 39.9261],
            [-86.3263, 39.924],
            [-86.3553, 39.924],
            [-86.3175, 39.9686],
            [-86.356, 40.069],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802830',
        NAME: 'Zionsville Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134039740,
        AWATER: 322488,
        INTPTLAT: '+39.9966334',
        INTPTLON: '-086.2912292',
        ELSDLEA: '',
        UNSDLEA: '02830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5338, 38.5858],
            [-86.3836, 38.6154],
            [-86.308, 38.5862],
            [-86.3087, 38.4229],
            [-86.3089, 38.3941],
            [-86.5695, 38.396],
            [-86.5708, 38.4971],
            [-86.5338, 38.5858],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1808640',
        NAME: 'Paoli Community School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482071751,
        AWATER: 5749168,
        INTPTLAT: '+38.4814041',
        INTPTLON: '-086.4042424',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5295, 40.2866],
            [-87.528, 40.389],
            [-87.3773, 40.3895],
            [-87.378, 40.4768],
            [-87.1784, 40.4756],
            [-87.1781, 40.3887],
            [-87.0921, 40.3666],
            [-87.2449, 40.3093],
            [-87.3393, 40.2232],
            [-87.428, 40.1783],
            [-87.531, 40.1772],
            [-87.5295, 40.2866],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1806080',
        NAME: 'Warren County Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 707316565,
        AWATER: 3305720,
        INTPTLAT: '+40.3342990',
        INTPTLON: '-087.3491772',
        ELSDLEA: '',
        UNSDLEA: '06080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3564, 40.0983],
            [-86.356, 40.069],
            [-86.3175, 39.9686],
            [-86.3553, 39.924],
            [-86.4309, 39.9235],
            [-86.4498, 39.9233],
            [-86.451, 39.9962],
            [-86.5542, 39.9956],
            [-86.5347, 40.0973],
            [-86.3564, 40.0983],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1805790',
        NAME: 'Lebanon Community School Corporation',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272581176,
        AWATER: 40360,
        INTPTLAT: '+40.0206120',
        INTPTLON: '-086.4322814',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2198, 41.3473],
            [-87.0655, 41.3475],
            [-87.1023, 41.281],
            [-87.2189, 41.242],
            [-87.2203, 41.3333],
            [-87.2198, 41.3473],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1800750',
        NAME: 'Boone Township Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93739673,
        AWATER: 86969,
        INTPTLAT: '+41.3107150',
        INTPTLON: '-087.1515421',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1445, 41.6457],
            [-87.0096, 41.6817],
            [-86.932, 41.607],
            [-86.9298, 41.519],
            [-87.0269, 41.5211],
            [-87.124, 41.5212],
            [-87.1445, 41.6457],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1802800',
        NAME: 'Duneland School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234531308,
        AWATER: 1994698,
        INTPTLAT: '+41.5877393',
        INTPTLON: '-087.0391475',
        ELSDLEA: '',
        UNSDLEA: '02800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0655, 41.3475],
            [-87.0851, 41.4346],
            [-87.0269, 41.5211],
            [-86.9298, 41.519],
            [-86.9302, 41.4341],
            [-86.9301, 41.2368],
            [-87.1023, 41.281],
            [-87.0655, 41.3475],
          ],
        ],
      },
      properties: {
        STATEFP: '18',
        SCSDLEA: '',
        GEOID: '1809000',
        NAME: 'East Porter County School Corporation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341757166,
        AWATER: 0,
        INTPTLAT: '+41.3684856',
        INTPTLON: '-086.9962174',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import { Col } from 'react-bootstrap';
import BarChart from '../Chart/BarChart';
import ColumnChart from '../Chart/ColumnChart';
import DonutChart from '../Chart/DonutChart';
import HeatMapChart from '../Chart/HeatMapChart';
import RadarChart from '../Chart/RadarChart';
import BarChartUneven from '../Chart/BarChartUneven';
import { HIGHCHARTS_TYPES, CHART_TYPES } from '../../constants/charts';
import { parseMLIData } from '../../helpers/charts';
import WaffleChart from '../Chart/WaffleChart';

const RelatedQuestion = forwardRef((props, chartRef) => {
  const {
    relatedQuestion,
    relatedSurveyName,
    visualization,
    chartLoaded,
    currentBenchmark,
    currentCategory,
  } = props;

  const [newParsedData, setNewParsedData] = useState();

  useEffect(() => {
    if (relatedQuestion && currentCategory) {
      const co = parseMLIData(
        relatedQuestion,
        relatedQuestion?.queryResult,
        currentBenchmark,
        currentCategory,
        0,
        relatedQuestion?.defaultVisualizationType,
        visualization?.details?.enableDefaultSegment
      );
      setNewParsedData(co);
    }
  }, [relatedQuestion, visualization, currentCategory]);

  return (
    <>
      <div className="survey-title">{relatedSurveyName}</div>
      <Col md="10" className="pl-3 pt-3">
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.STACKED_BAR && newParsedData && (
          <BarChart
            parsedData={newParsedData}
            benchmarks={currentBenchmark}
            qtyOptions={
              relatedQuestion?.answers?.length || relatedQuestion?.answerOptionsType?.length || 1
            }
            chartLoaded={chartLoaded}
            ref={chartRef}
          />
        )}
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.HORIZONTAL_BAR &&
          newParsedData && (
            <ColumnChart
              parsedData={newParsedData}
              currentJob={relatedQuestion}
              chartType={HIGHCHARTS_TYPES.BAR}
              benchmarks={currentBenchmark}
              qtyOptions={
                relatedQuestion?.answers?.length || relatedQuestion?.answerOptionsType?.length || 1
              }
              chartLoaded={chartLoaded}
              ref={chartRef}
            />
          )}
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.COLUMN && newParsedData && (
          <ColumnChart
            parsedData={newParsedData}
            currentJob={relatedQuestion}
            benchmarks={currentBenchmark}
            chartLoaded={chartLoaded}
            ref={chartRef}
            qtyOptions={
              relatedQuestion?.answers?.length || relatedQuestion?.answerOptionsType?.length || 1
            }
          />
        )}
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.DONUT && newParsedData && (
          <DonutChart
            parsedData={newParsedData}
            chartLoaded={chartLoaded}
            ref={chartRef}
            benchmarks={currentBenchmark}
          />
        )}
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.HEAT_MAP && newParsedData && (
          <HeatMapChart
            parsedData={newParsedData}
            chartLoaded={chartLoaded}
            ref={chartRef}
            qtyOptions={
              relatedQuestion?.answers?.length || relatedQuestion?.answerOptionsType?.length || 1
            }
            benchmarks={currentBenchmark}
          />
        )}
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.RADAR && newParsedData && (
          <RadarChart parsedData={newParsedData} chartLoaded={chartLoaded} ref={chartRef} />
        )}
        {(relatedQuestion?.defaultVisualizationType === CHART_TYPES.SPLIT_BAR_POS_NEG ||
          relatedQuestion?.defaultVisualizationType === CHART_TYPES.COMBINED_BAR_POS_NEG) &&
          newParsedData && (
            <BarChartUneven
              parsedData={newParsedData}
              chartLoaded={chartLoaded}
              ref={chartRef}
              benchmarks={currentBenchmark}
            />
          )}
        {relatedQuestion?.defaultVisualizationType === CHART_TYPES.WAFFLE && newParsedData && (
          <WaffleChart
            parsedData={newParsedData}
            qtyOptions={
              relatedQuestion?.answers?.length || newParsedData?.answerOptionsType?.length || 1
            }
            chartLoaded={chartLoaded}
            ref={chartRef}
            benchmarks={currentBenchmark}
          />
        )}
      </Col>
      <Col md="2" className="p-2 d-flex flex-column justify-content-center">
        <div>
          <h2 className="border-bottom border-dark w-50 text-uppercase pb-3 mb-3">Sample Size</h2>
        </div>
        {visualization?.details?.enableDefaultSegment && (
          <>
            <h3>N-Size</h3>
            <div className="pl-4 pt-3">
              {
                relatedQuestion?.queryResult?.mliDataSegments?.find((ds) => ds.name === 'default')
                  .numberOfRespondentsInterval
              }
            </div>
          </>
        )}
        {visualization?.details?.segments?.map((s) => {
          const sampleSizeSegment = relatedQuestion?.queryResult?.mliDataSegments?.find(
            (ds) => ds.name === s.label
          );
          return (
            <>
              <h3>N-Size - {sampleSizeSegment?.name}</h3>
              <div className="pl-4 pt-3">{sampleSizeSegment?.numberOfRespondentsInterval}</div>
            </>
          );
        })}
      </Col>
    </>
  );
});

RelatedQuestion.displayName = 'RelatedQuestion';

export default RelatedQuestion;

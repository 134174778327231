export const MT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0703, 47.4012],
            [-111.0296, 47.4256],
            [-111.0295, 47.5822],
            [-110.8646, 47.5247],
            [-110.7685, 47.5256],
            [-110.7691, 47.4461],
            [-110.6396, 47.4165],
            [-110.7671, 47.3591],
            [-110.7707, 47.0965],
            [-110.6452, 47.0959],
            [-110.6529, 46.8236],
            [-110.856, 46.9213],
            [-110.913, 46.9806],
            [-111.0843, 47.0106],
            [-111.0807, 47.0879],
            [-111.0039, 47.0878],
            [-111.0041, 47.24],
            [-111.1065, 47.278],
            [-111.0703, 47.4012],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '03450',
        GEOID: '3003450',
        NAME: 'Belt High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1732161468,
        AWATER: 556278,
        INTPTLAT: '+47.2268988',
        INTPTLON: '-110.8899232',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0382, 45.3514],
            [-110.9179, 45.3513],
            [-110.9192, 45.5249],
            [-110.7947, 45.6086],
            [-110.7954, 45.6283],
            [-110.7953, 45.7019],
            [-110.7891, 45.8023],
            [-110.5956, 45.8185],
            [-110.4886, 45.785],
            [-110.3446, 45.7851],
            [-110.3905, 45.8565],
            [-110.2929, 45.9152],
            [-110.2927, 45.7851],
            [-110.2203, 45.7475],
            [-110.2209, 45.7111],
            [-110.2195, 45.6809],
            [-110.2185, 45.6522],
            [-110.2185, 45.6514],
            [-110.2281, 45.1721],
            [-110.4446, 45.1739],
            [-110.752, 45.1774],
            [-110.8041, 45.2778],
            [-111.0383, 45.2609],
            [-111.0382, 45.3514],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '20100',
        GEOID: '3020100',
        NAME: 'Park High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3532373532,
        AWATER: 11559111,
        INTPTLAT: '+45.4219048',
        INTPTLON: '-110.5327983',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6026, 48.6258],
            [-107.3376, 48.6542],
            [-107.3366, 48.9132],
            [-107.3147, 49.0001],
            [-107.1798, 49.0001],
            [-107.206, 48.9127],
            [-107.2015, 48.5095],
            [-107.3017, 48.4809],
            [-107.2603, 48.3941],
            [-107.2605, 48.3048],
            [-107.3693, 48.3052],
            [-107.4047, 48.1157],
            [-107.5405, 48.1631],
            [-107.5406, 48.3978],
            [-107.6726, 48.3977],
            [-107.6728, 48.485],
            [-107.7467, 48.5703],
            [-107.6026, 48.6258],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '23400',
        GEOID: '3023400',
        NAME: 'Saco High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1736977764,
        AWATER: 31974464,
        INTPTLAT: '+48.5291427',
        INTPTLON: '-107.4220668',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0491, 49.0008],
            [-115.9755, 49.0009],
            [-115.4169, 49.0002],
            [-115.3795, 48.8837],
            [-115.3795, 48.8825],
            [-115.4124, 48.7052],
            [-115.132, 48.7036],
            [-115.1311, 48.6667],
            [-115.1507, 48.6297],
            [-115.4126, 48.6301],
            [-115.4126, 48.5717],
            [-115.6632, 48.5724],
            [-115.6639, 48.2257],
            [-115.694, 48.2258],
            [-115.8002, 48.2228],
            [-116.0092, 48.1597],
            [-116.0487, 48.2161],
            [-116.0491, 48.5021],
            [-116.049, 48.5153],
            [-116.0491, 49.0008],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '26580',
        GEOID: '3026580',
        NAME: 'Troy High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3550257600,
        AWATER: 28219150,
        INTPTLAT: '+48.6994376',
        INTPTLON: '-115.7560085',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0855, 45.8606],
            [-112.9232, 45.9348],
            [-112.9228, 46.0048],
            [-112.7777, 46.0483],
            [-112.7771, 46.1358],
            [-112.686, 46.1357],
            [-112.5966, 46.1655],
            [-112.5771, 46.179],
            [-112.4663, 46.093],
            [-112.4303, 45.96],
            [-112.3783, 45.7445],
            [-112.4853, 45.759],
            [-112.5495, 45.6843],
            [-112.6871, 45.6249],
            [-112.7367, 45.7001],
            [-112.7358, 45.7012],
            [-112.8236, 45.7743],
            [-113.0002, 45.8077],
            [-113.0855, 45.8606],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '05310',
        GEOID: '3005310',
        NAME: 'Butte High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1786872967,
        AWATER: 1522160,
        INTPTLAT: '+45.9047078',
        INTPTLON: '-112.6710760',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7576, 48.4766],
            [-104.6277, 48.4763],
            [-104.6278, 48.3894],
            [-104.2595, 48.3894],
            [-104.2706, 48.3023],
            [-104.3896, 48.3026],
            [-104.4546, 48.2447],
            [-104.5846, 48.2447],
            [-104.6062, 48.3026],
            [-104.7575, 48.2599],
            [-105.04, 48.2592],
            [-105.0834, 48.4039],
            [-104.9749, 48.404],
            [-104.9754, 48.4763],
            [-104.7576, 48.4766],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '11580',
        GEOID: '3011580',
        NAME: 'Froid High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057650411,
        AWATER: 4023602,
        INTPTLAT: '+48.3367897',
        INTPTLON: '-104.6668421',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.468, 48.999],
            [-109.8267, 48.9998],
            [-109.4895, 49.0003],
            [-109.5055, 48.9139],
            [-109.5053, 48.5679],
            [-109.4643, 48.4526],
            [-109.5513, 48.2935],
            [-109.5343, 48.1346],
            [-109.7269, 48.1473],
            [-109.6177, 48.1483],
            [-109.6177, 48.2219],
            [-109.7139, 48.2199],
            [-109.7352, 48.314],
            [-109.8698, 48.306],
            [-109.9193, 48.4657],
            [-109.9087, 48.4816],
            [-109.9759, 48.4815],
            [-109.9644, 48.6539],
            [-110.0518, 48.6538],
            [-110.1386, 48.8117],
            [-110.2275, 48.8264],
            [-110.2269, 48.8421],
            [-110.2267, 48.8561],
            [-110.3, 48.9236],
            [-110.468, 48.999],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13590',
        GEOID: '3013590',
        NAME: 'Havre High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3368395143,
        AWATER: 24287781,
        INTPTLAT: '+48.6898621',
        INTPTLON: '-109.7811301',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7566, 47.1857],
            [-109.6487, 47.1432],
            [-109.6402, 47.117],
            [-109.5051, 46.9261],
            [-109.4952, 46.791],
            [-109.3892, 46.7514],
            [-109.3892, 46.6936],
            [-109.5825, 46.6935],
            [-109.5589, 46.7639],
            [-109.6585, 46.7909],
            [-109.7876, 46.7803],
            [-109.7554, 46.896],
            [-109.7566, 47.1857],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '18900',
        GEOID: '3018900',
        NAME: 'Moore High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 806829692,
        AWATER: 246360,
        INTPTLAT: '+46.8380488',
        INTPTLON: '-109.6356810',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0487, 48.2161],
            [-116.0092, 48.1597],
            [-115.8002, 48.2228],
            [-115.694, 48.2258],
            [-115.6087, 48.0583],
            [-115.4739, 47.915],
            [-115.5003, 47.7889],
            [-115.749, 47.7753],
            [-115.7497, 47.7144],
            [-115.8238, 47.7526],
            [-116.0207, 47.9636],
            [-116.0491, 47.9773],
            [-116.0487, 48.2161],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '19500',
        GEOID: '3019500',
        NAME: 'Noxon High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1534565074,
        AWATER: 39608841,
        INTPTLAT: '+47.9877360',
        INTPTLON: '-115.7768036',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9024, 47.7563],
            [-111.9235, 47.814],
            [-111.746, 47.8136],
            [-111.6671, 47.785],
            [-111.4087, 47.7846],
            [-111.4089, 47.6981],
            [-111.4195, 47.6981],
            [-111.6665, 47.6978],
            [-111.6663, 47.6262],
            [-111.7312, 47.6111],
            [-111.7743, 47.698],
            [-111.9018, 47.6981],
            [-111.9231, 47.6982],
            [-111.9024, 47.7563],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '21540',
        GEOID: '3021540',
        NAME: 'Power High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 485189947,
        AWATER: 121848,
        INTPTLAT: '+47.7397005',
        INTPTLON: '-111.6759074',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0478, 47.516],
            [-111.9228, 47.5051],
            [-111.902, 47.6111],
            [-111.7312, 47.6111],
            [-111.6663, 47.6262],
            [-111.6665, 47.6978],
            [-111.4195, 47.6981],
            [-111.4195, 47.6027],
            [-111.5188, 47.5936],
            [-111.5263, 47.5174],
            [-111.5684, 47.5176],
            [-111.6701, 47.4353],
            [-111.889, 47.4212],
            [-112.0457, 47.3516],
            [-112.0478, 47.516],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '24330',
        GEOID: '3024330',
        NAME: 'Simms High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 877939963,
        AWATER: 436569,
        INTPTLAT: '+47.5354980',
        INTPTLON: '-111.7349120',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1083, 48.0062],
            [-106.0062, 48.0337],
            [-105.8527, 48.0084],
            [-105.8439, 48.0103],
            [-105.5821, 48.08],
            [-105.452, 48.0894],
            [-105.229, 48.0893],
            [-105.1948, 48.0666],
            [-105.1948, 47.9925],
            [-105.1949, 47.9696],
            [-105.1949, 47.9551],
            [-105.195, 47.9113],
            [-105.1951, 47.8876],
            [-105.2348, 47.7947],
            [-105.2348, 47.7905],
            [-105.3633, 47.7904],
            [-105.3634, 47.665],
            [-105.3635, 47.5296],
            [-105.4066, 47.5295],
            [-105.4063, 47.1816],
            [-105.833, 47.1811],
            [-105.8326, 47.0931],
            [-105.9599, 47.093],
            [-105.9598, 47.181],
            [-106.0868, 47.1809],
            [-106.1704, 47.1808],
            [-106.1707, 47.3545],
            [-106.2982, 47.3543],
            [-106.2611, 47.531],
            [-106.2609, 47.8684],
            [-106.3581, 47.8684],
            [-106.4186, 47.9575],
            [-106.3942, 48.0647],
            [-106.1083, 48.0062],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '06810',
        GEOID: '3006810',
        NAME: 'Circle High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6843602621,
        AWATER: 103093129,
        INTPTLAT: '+47.6296277',
        INTPTLON: '-105.7572220',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3635, 47.5296],
            [-105.2415, 47.5301],
            [-105.2007, 47.4734],
            [-105.1984, 47.3629],
            [-104.8959, 47.3628],
            [-104.6363, 47.3636],
            [-104.5132, 47.3831],
            [-104.5133, 47.3547],
            [-104.4203, 47.3544],
            [-104.4203, 47.3544],
            [-104.451, 47.3193],
            [-104.3417, 47.2454],
            [-104.3061, 47.042],
            [-104.4121, 46.904],
            [-104.529, 46.8608],
            [-104.6038, 46.8609],
            [-105.0077, 46.8614],
            [-105.0695, 46.9192],
            [-105.197, 46.9192],
            [-105.1967, 46.9772],
            [-105.3248, 46.9772],
            [-105.3249, 47.1815],
            [-105.4063, 47.1816],
            [-105.4066, 47.5295],
            [-105.3635, 47.5296],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '08340',
        GEOID: '3008340',
        NAME: 'Dawson High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4271391386,
        AWATER: 28082300,
        INTPTLAT: '+47.1492924',
        INTPTLON: '-104.8248909',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2136, 47.4167],
            [-110.1584, 47.4177],
            [-109.8452, 47.5468],
            [-109.8297, 47.7153],
            [-109.6775, 47.7347],
            [-109.6562, 47.4966],
            [-109.5794, 47.4204],
            [-109.6987, 47.3923],
            [-109.7231, 47.3233],
            [-109.6408, 47.2889],
            [-109.6194, 47.1811],
            [-109.6382, 47.1811],
            [-109.6487, 47.1432],
            [-109.7566, 47.1857],
            [-109.838, 47.1854],
            [-109.8725, 47.2289],
            [-110, 47.2287],
            [-110.1277, 47.2719],
            [-110.128, 47.359],
            [-110.2136, 47.4167],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '08850',
        GEOID: '3008850',
        NAME: 'Denton High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1493186092,
        AWATER: 1510011,
        INTPTLAT: '+47.4088256',
        INTPTLON: '-109.8421105',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5794, 47.4204],
            [-109.3993, 47.4044],
            [-109.4038, 47.3043],
            [-109.2073, 47.3144],
            [-109.0958, 47.2432],
            [-109.1124, 47.1143],
            [-109.1708, 47.0226],
            [-109.1681, 47.0226],
            [-109.194, 46.928],
            [-109.1807, 46.7964],
            [-109.3159, 46.7513],
            [-109.3892, 46.7514],
            [-109.4952, 46.791],
            [-109.5051, 46.9261],
            [-109.6402, 47.117],
            [-109.6487, 47.1432],
            [-109.6382, 47.1811],
            [-109.6194, 47.1811],
            [-109.6408, 47.2889],
            [-109.7231, 47.3233],
            [-109.6987, 47.3923],
            [-109.5794, 47.4204],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '10530',
        GEOID: '3010530',
        NAME: 'Fergus High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2170966069,
        AWATER: 1124114,
        INTPTLAT: '+47.0950756',
        INTPTLON: '-109.4122274',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6396, 47.4165],
            [-110.5544, 47.4165],
            [-110.2538, 47.4165],
            [-110.2986, 47.3587],
            [-110.3192, 47.1845],
            [-110.474, 47.1697],
            [-110.4727, 47.0973],
            [-110.6452, 47.0959],
            [-110.7707, 47.0965],
            [-110.7671, 47.3591],
            [-110.6396, 47.4165],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '12300',
        GEOID: '3012300',
        NAME: 'Geyser High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1086964161,
        AWATER: 295015,
        INTPTLAT: '+47.2647835',
        INTPTLON: '-110.5273491',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-107.0203, 45.1862],
              [-106.768, 45.1861],
              [-106.2797, 45.1797],
              [-106.2637, 44.9938],
              [-106.5709, 44.9948],
              [-106.9896, 44.9963],
              [-107.0052, 44.9964],
              [-107.0203, 45.1862],
            ],
          ],
          [
            [
              [-108.1049, 45.2205],
              [-108.0832, 45.2313],
              [-108.0706, 45.5185],
              [-108.0697, 45.6813],
              [-108.0474, 45.8995],
              [-107.8818, 45.8992],
              [-107.798, 45.9857],
              [-107.6745, 45.9858],
              [-107.6745, 46.0436],
              [-107.5115, 46.0434],
              [-107.4249, 46.0434],
              [-107.4251, 45.9567],
              [-107.1764, 45.9568],
              [-106.9391, 45.87],
              [-106.913, 45.6839],
              [-107.0171, 45.6914],
              [-107.0189, 45.4533],
              [-107.5741, 45.4462],
              [-107.6964, 45.3853],
              [-107.6969, 45.2603],
              [-107.8441, 45.2593],
              [-107.8449, 45.0007],
              [-107.9115, 45.0007],
              [-108.2494, 44.9999],
              [-108.2498, 45.0316],
              [-108.1049, 45.2205],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13340',
        GEOID: '3013340',
        NAME: 'Hardin High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7420881585,
        AWATER: 45758694,
        INTPTLAT: '+45.5142869',
        INTPTLON: '-107.4768299',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5388, 47.7662],
            [-109.4524, 47.7665],
            [-109.4539, 47.8796],
            [-109.4051, 47.9224],
            [-109.2052, 47.9349],
            [-109.2082, 48.0797],
            [-109.0788, 48.0782],
            [-109.0787, 48.2218],
            [-109.2065, 48.2361],
            [-109.2061, 48.3497],
            [-109.3258, 48.3949],
            [-109.0638, 48.4349],
            [-108.8888, 48.4345],
            [-108.8881, 48.5363],
            [-108.9604, 48.5582],
            [-108.96, 48.7012],
            [-108.843, 48.7009],
            [-108.8443, 48.9145],
            [-108.8234, 48.9991],
            [-108.6695, 48.9991],
            [-108.742, 48.9415],
            [-108.7173, 48.8729],
            [-108.7607, 48.7419],
            [-108.5865, 48.7419],
            [-108.4673, 48.7278],
            [-108.4674, 48.6985],
            [-108.4849, 48.421],
            [-108.4112, 48.445],
            [-108.4112, 48.2231],
            [-108.8878, 48.2228],
            [-108.8852, 47.9227],
            [-108.8911, 47.7345],
            [-108.9427, 47.7918],
            [-109.2774, 47.7924],
            [-109.4115, 47.7246],
            [-109.5387, 47.7389],
            [-109.5388, 47.7662],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13400',
        GEOID: '3013400',
        NAME: 'Harlem High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4105440052,
        AWATER: 14805113,
        INTPTLAT: '+48.2975911',
        INTPTLON: '-108.8744064',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.9019, 45.7843],
            [-108.8626, 45.7266],
            [-108.7096, 45.7328],
            [-108.6795, 45.711],
            [-108.6549, 45.5675],
            [-108.5398, 45.5673],
            [-108.645, 45.4641],
            [-108.6453, 45.4641],
            [-108.6989, 45.5083],
            [-108.8018, 45.552],
            [-108.7936, 45.6045],
            [-108.843, 45.611],
            [-108.9047, 45.6828],
            [-108.9019, 45.7843],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '16230',
        GEOID: '3016230',
        NAME: 'Laurel High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405657149,
        AWATER: 5272690,
        INTPTLAT: '+45.6254989',
        INTPTLON: '-108.7463250',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.9893, 47.8728],
            [-114.8814, 47.8728],
            [-114.8187, 47.8758],
            [-114.8387, 47.7924],
            [-114.7618, 47.7644],
            [-114.7744, 47.535],
            [-114.696, 47.4096],
            [-114.6872, 47.2743],
            [-114.7972, 47.2689],
            [-114.8425, 47.2979],
            [-114.969, 47.3126],
            [-114.9823, 47.3968],
            [-115.1281, 47.423],
            [-115.1298, 47.5483],
            [-115.0229, 47.5381],
            [-114.9904, 47.6962],
            [-114.9893, 47.8728],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '20850',
        GEOID: '3020850',
        NAME: 'Plains High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1351161486,
        AWATER: 10214108,
        INTPTLAT: '+47.5453953',
        INTPTLON: '-114.8864780',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4092, 48.5632],
            [-104.9734, 48.5632],
            [-104.7576, 48.5631],
            [-104.7576, 48.4766],
            [-104.9754, 48.4763],
            [-104.9749, 48.404],
            [-105.0834, 48.4039],
            [-105.04, 48.2592],
            [-104.7575, 48.2599],
            [-104.7579, 48.2163],
            [-104.9314, 48.2169],
            [-104.9323, 48.1048],
            [-105.0371, 48.0634],
            [-105.1948, 48.0666],
            [-105.229, 48.0893],
            [-105.452, 48.0894],
            [-105.4097, 48.2154],
            [-105.4092, 48.5632],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '21270',
        GEOID: '3021270',
        NAME: 'Poplar High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1918485694,
        AWATER: 11259285,
        INTPTLAT: '+48.3150442',
        INTPTLON: '-105.1389817',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3634, 47.665],
            [-105.3633, 47.7904],
            [-105.2348, 47.7905],
            [-105.235, 47.7036],
            [-105.1025, 47.7036],
            [-105.0212, 47.7034],
            [-104.9852, 47.7033],
            [-104.9781, 47.7033],
            [-104.9576, 47.6163],
            [-104.8722, 47.6161],
            [-104.5937, 47.6163],
            [-104.594, 47.5293],
            [-104.513, 47.5294],
            [-104.513, 47.5157],
            [-104.5132, 47.3831],
            [-104.6363, 47.3636],
            [-104.8959, 47.3628],
            [-105.1984, 47.3629],
            [-105.2007, 47.4734],
            [-105.2415, 47.5301],
            [-105.3635, 47.5296],
            [-105.3634, 47.665],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '22410',
        GEOID: '3022410',
        NAME: 'Richey High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1872508382,
        AWATER: 1197664,
        INTPTLAT: '+47.5345039',
        INTPTLON: '-104.9448200',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-104.5132, 47.3831],
              [-104.513, 47.5157],
              [-104.513, 47.5294],
              [-104.5106, 47.5729],
              [-104.3344, 47.5475],
              [-104.2704, 47.553],
              [-104.2805, 47.465],
              [-104.4203, 47.3544],
              [-104.5133, 47.3547],
              [-104.5132, 47.3831],
            ],
          ],
          [
            [
              [-105.1053, 47.8674],
              [-105.0652, 47.8974],
              [-105.0653, 47.9021],
              [-105.0652, 47.906],
              [-105.0391, 47.911],
              [-105.0253, 47.9108],
              [-104.9586, 47.9253],
              [-104.9585, 47.9284],
              [-104.9581, 47.9428],
              [-104.8927, 47.9723],
              [-104.8823, 47.972],
              [-104.8494, 47.9692],
              [-104.8492, 47.9447],
              [-104.9037, 47.8678],
              [-104.9149, 47.8679],
              [-104.9778, 47.7955],
              [-104.9778, 47.7352],
              [-104.9786, 47.7203],
              [-104.9781, 47.7033],
              [-104.9852, 47.7033],
              [-105.0212, 47.7034],
              [-105.1025, 47.7036],
              [-105.1051, 47.8547],
              [-105.1053, 47.8674],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '23550',
        GEOID: '3023550',
        NAME: 'Savage High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 686419080,
        AWATER: 3665096,
        INTPTLAT: '+47.5069263',
        INTPTLON: '-104.4510575',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1891, 48.6534],
            [-111.8404, 48.6532],
            [-111.5788, 48.6679],
            [-111.4587, 48.7475],
            [-111.4697, 48.8561],
            [-111.2733, 48.8815],
            [-111.2733, 48.876],
            [-111.2734, 48.7107],
            [-111.2734, 48.7042],
            [-111.2754, 48.3449],
            [-111.2753, 48.2195],
            [-111.409, 48.2195],
            [-111.6656, 48.2195],
            [-111.6658, 48.3495],
            [-111.7959, 48.3931],
            [-111.9909, 48.3943],
            [-112.0044, 48.4489],
            [-112.1423, 48.468],
            [-112.1848, 48.4776],
            [-112.1891, 48.6534],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '23910',
        GEOID: '3023910',
        NAME: 'Shelby High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2798564992,
        AWATER: 45163870,
        INTPTLAT: '+48.5019610',
        INTPTLON: '-111.6057314',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6695, 48.9991],
            [-108.2365, 48.9996],
            [-108.2364, 48.9136],
            [-108.2594, 48.7422],
            [-108.3191, 48.7422],
            [-108.319, 48.5684],
            [-108.3463, 48.4228],
            [-108.4112, 48.445],
            [-108.4849, 48.421],
            [-108.4674, 48.6985],
            [-108.4673, 48.7278],
            [-108.5865, 48.7419],
            [-108.7607, 48.7419],
            [-108.7173, 48.8729],
            [-108.742, 48.9415],
            [-108.6695, 48.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '26670',
        GEOID: '3026670',
        NAME: 'Turner High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1361562504,
        AWATER: 1787243,
        INTPTLAT: '+48.8001957',
        INTPTLON: '-108.4780479',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1208, 48.1138],
            [-114.0622, 48.168],
            [-114.0405, 48.1401],
            [-113.9448, 48.1395],
            [-113.9424, 48.0516],
            [-113.7955, 47.9698],
            [-113.6723, 47.8965],
            [-113.6341, 47.6],
            [-113.7109, 47.6001],
            [-113.7108, 47.745],
            [-113.9041, 47.745],
            [-113.9031, 47.6005],
            [-113.8503, 47.6002],
            [-113.9487, 47.6],
            [-113.9659, 47.6407],
            [-113.9255, 47.7554],
            [-113.9414, 47.8847],
            [-114.0373, 47.8846],
            [-114.0629, 47.9625],
            [-114.1101, 47.9625],
            [-114.1208, 48.1138],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '03840',
        GEOID: '3003840',
        NAME: 'Bigfork High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 966098199,
        AWATER: 70463517,
        INTPTLAT: '+47.8885618',
        INTPTLON: '-113.8763809',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5292, 45.6717],
            [-111.4541, 45.6714],
            [-111.3566, 45.6715],
            [-111.2687, 45.7437],
            [-111.2232, 45.6856],
            [-111.145, 45.7001],
            [-111.0345, 45.795],
            [-110.9155, 45.7871],
            [-110.977, 45.8727],
            [-110.9766, 45.9026],
            [-110.9139, 45.844],
            [-110.7891, 45.8023],
            [-110.7953, 45.7019],
            [-110.7954, 45.6283],
            [-110.7947, 45.6086],
            [-110.9192, 45.5249],
            [-110.9179, 45.3513],
            [-111.0382, 45.3514],
            [-111.1661, 45.3504],
            [-111.1655, 45.437],
            [-111.35, 45.3822],
            [-111.3501, 45.3905],
            [-111.3513, 45.5986],
            [-111.3514, 45.6419],
            [-111.5368, 45.6568],
            [-111.5292, 45.6717],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '04590',
        GEOID: '3004590',
        NAME: 'Bozeman High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1719128825,
        AWATER: 4049722,
        INTPTLAT: '+45.5942782',
        INTPTLON: '-111.0669771',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0457, 47.3516],
            [-111.889, 47.4212],
            [-111.6701, 47.4353],
            [-111.5684, 47.5176],
            [-111.5263, 47.5174],
            [-111.4814, 47.4141],
            [-111.4107, 47.4331],
            [-111.4044, 47.4335],
            [-111.3435, 47.3921],
            [-111.3897, 47.3096],
            [-111.3743, 47.2132],
            [-111.2654, 47.0688],
            [-111.2866, 47.001],
            [-111.5343, 47.0006],
            [-111.5392, 46.9134],
            [-111.6581, 46.9134],
            [-111.7902, 46.9138],
            [-111.7893, 47.1289],
            [-111.9201, 47.1357],
            [-112.0448, 47.1927],
            [-112.0457, 47.3516],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '05910',
        GEOID: '3005910',
        NAME: 'Cascade High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2427404400,
        AWATER: 16240735,
        INTPTLAT: '+47.0886153',
        INTPTLON: '-111.6852707',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5513, 48.2935],
            [-109.4643, 48.4526],
            [-109.5053, 48.5679],
            [-109.5055, 48.9139],
            [-109.4895, 49.0003],
            [-109.1722, 48.9999],
            [-108.8234, 48.9991],
            [-108.8443, 48.9145],
            [-108.843, 48.7009],
            [-108.96, 48.7012],
            [-108.9604, 48.5582],
            [-108.8881, 48.5363],
            [-108.8888, 48.4345],
            [-109.0638, 48.4349],
            [-109.3258, 48.3949],
            [-109.2061, 48.3497],
            [-109.2065, 48.2361],
            [-109.0787, 48.2218],
            [-109.0788, 48.0782],
            [-109.2082, 48.0797],
            [-109.2052, 47.9349],
            [-109.4051, 47.9224],
            [-109.4539, 47.8796],
            [-109.4524, 47.7665],
            [-109.5388, 47.7662],
            [-109.5334, 47.9906],
            [-109.5343, 48.1346],
            [-109.5513, 48.2935],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '06270',
        GEOID: '3006270',
        NAME: 'Chinook High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4449403924,
        AWATER: 9844432,
        INTPTLAT: '+48.5315842',
        INTPTLON: '-109.2385650',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0148, 48.131],
            [-112.9007, 48.131],
            [-112.4046, 48.1319],
            [-112.1778, 48.1307],
            [-112.0272, 48.0429],
            [-111.9843, 47.9848],
            [-111.9192, 47.9844],
            [-111.9235, 47.814],
            [-111.9024, 47.7563],
            [-111.9231, 47.6982],
            [-112.1792, 47.6971],
            [-112.298, 47.7132],
            [-112.3108, 47.5746],
            [-112.3044, 47.5265],
            [-112.4327, 47.6061],
            [-112.5687, 47.636],
            [-112.8547, 47.6292],
            [-112.9139, 47.6972],
            [-112.9133, 47.8262],
            [-112.9847, 47.9539],
            [-112.9075, 47.9557],
            [-112.952, 48.0447],
            [-113.0148, 48.131],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '06330',
        GEOID: '3006330',
        NAME: 'Choteau High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3887805024,
        AWATER: 33686425,
        INTPTLAT: '+47.8812348',
        INTPTLON: '-112.4622735',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9923, 48.0082],
            [-104.9733, 48.0125],
            [-104.964, 48.0111],
            [-104.9307, 48.0196],
            [-104.9308, 48.039],
            [-104.9203, 48.039],
            [-104.9106, 48.0321],
            [-104.8925, 48.0134],
            [-104.8115, 47.9695],
            [-104.6768, 47.9468],
            [-104.6763, 47.9111],
            [-104.6764, 47.8974],
            [-104.6764, 47.8821],
            [-104.6554, 47.8676],
            [-104.6029, 47.8676],
            [-104.5828, 47.8821],
            [-104.5248, 47.8823],
            [-104.5461, 47.8676],
            [-104.5238, 47.8502],
            [-104.5938, 47.761],
            [-104.5738, 47.7609],
            [-104.5739, 47.7317],
            [-104.5417, 47.718],
            [-104.4652, 47.7039],
            [-104.5074, 47.689],
            [-104.5106, 47.5729],
            [-104.513, 47.5294],
            [-104.594, 47.5293],
            [-104.5937, 47.6163],
            [-104.8722, 47.6161],
            [-104.9576, 47.6163],
            [-104.9781, 47.7033],
            [-104.9786, 47.7203],
            [-104.9778, 47.7352],
            [-104.9778, 47.7955],
            [-104.9149, 47.8679],
            [-104.9037, 47.8678],
            [-104.8492, 47.9447],
            [-104.8494, 47.9692],
            [-104.8823, 47.972],
            [-104.8927, 47.9723],
            [-104.9581, 47.9428],
            [-104.9585, 47.9284],
            [-104.9586, 47.9253],
            [-104.9734, 47.9252],
            [-104.9783, 47.9348],
            [-105.0945, 47.9533],
            [-105.0944, 47.9631],
            [-105.0946, 47.972],
            [-105.0946, 47.9763],
            [-104.9923, 48.0082],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '16020',
        GEOID: '3016020',
        NAME: 'Lambert High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1248578829,
        AWATER: 5144253,
        INTPTLAT: '+47.7666460',
        INTPTLON: '-104.7557820',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2839, 45.9619],
            [-111.284, 45.9764],
            [-111.2648, 46.0061],
            [-111.2631, 46.0061],
            [-111.1515, 45.9908],
            [-110.9759, 45.9885],
            [-110.9761, 45.9467],
            [-110.9766, 45.9026],
            [-110.977, 45.8727],
            [-110.9155, 45.7871],
            [-111.0345, 45.795],
            [-111.145, 45.7001],
            [-111.2232, 45.6856],
            [-111.2687, 45.7437],
            [-111.2586, 45.787],
            [-111.1863, 45.8456],
            [-111.2839, 45.9619],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '03330',
        GEOID: '3003330',
        NAME: 'Belgrade High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 618020388,
        AWATER: 2245545,
        INTPTLAT: '+45.8581688',
        INTPTLON: '-111.1271513',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.902, 45.8029],
            [-108.9019, 45.8136],
            [-108.7757, 45.8277],
            [-108.7241, 45.8276],
            [-108.6336, 45.9169],
            [-108.5929, 45.9581],
            [-108.4681, 45.9584],
            [-108.4259, 45.8993],
            [-108.3148, 45.8956],
            [-108.3631, 45.8686],
            [-108.3431, 45.7842],
            [-108.2425, 45.7836],
            [-108.2426, 45.6812],
            [-108.0697, 45.6813],
            [-108.0706, 45.5185],
            [-108.3164, 45.4605],
            [-108.645, 45.4641],
            [-108.5398, 45.5673],
            [-108.6549, 45.5675],
            [-108.6795, 45.711],
            [-108.7096, 45.7328],
            [-108.8626, 45.7266],
            [-108.9019, 45.7843],
            [-108.902, 45.8029],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '03900',
        GEOID: '3003900',
        NAME: 'Billings High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1941031485,
        AWATER: 9701690,
        INTPTLAT: '+45.6885624',
        INTPTLON: '-108.4601261',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7579, 48.2163],
            [-104.6109, 48.1414],
            [-104.6816, 48.1033],
            [-104.9312, 48.1068],
            [-104.9323, 48.1048],
            [-104.9314, 48.2169],
            [-104.7579, 48.2163],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '05040',
        GEOID: '3005040',
        NAME: 'Brockton High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218089741,
        AWATER: 4829626,
        INTPTLAT: '+48.1671541',
        INTPTLON: '-104.7998410',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9312, 48.1068],
            [-104.6816, 48.1033],
            [-104.6109, 48.1414],
            [-104.5315, 48.1246],
            [-104.3226, 48.0452],
            [-104.1672, 48.0355],
            [-104.0441, 47.9961],
            [-104.0425, 47.807],
            [-104.1651, 47.7904],
            [-104.2503, 47.8436],
            [-104.5238, 47.8502],
            [-104.5461, 47.8676],
            [-104.5248, 47.8823],
            [-104.5828, 47.8821],
            [-104.6029, 47.8676],
            [-104.6554, 47.8676],
            [-104.6764, 47.8821],
            [-104.6764, 47.8974],
            [-104.6763, 47.9111],
            [-104.6768, 47.9468],
            [-104.8115, 47.9695],
            [-104.8925, 48.0134],
            [-104.9106, 48.0321],
            [-104.9203, 48.039],
            [-104.9308, 48.039],
            [-104.9312, 48.1068],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '10290',
        GEOID: '3010290',
        NAME: 'Fairview High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1518241410,
        AWATER: 15692822,
        INTPTLAT: '+47.9832155',
        INTPTLON: '-104.4472277',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0201, 48.226],
            [-114.8481, 48.2261],
            [-114.8486, 48.3995],
            [-114.6317, 48.3995],
            [-114.4389, 48.3499],
            [-114.4427, 48.3528],
            [-114.4429, 48.3564],
            [-114.4089, 48.3499],
            [-114.3738, 48.3052],
            [-114.3497, 48.2805],
            [-114.2866, 48.3057],
            [-114.2535, 48.2947],
            [-114.222, 48.2692],
            [-114.0802, 48.309],
            [-114.0446, 48.2546],
            [-113.9926, 48.2185],
            [-113.9922, 48.2185],
            [-113.9448, 48.1395],
            [-114.0405, 48.1401],
            [-114.0622, 48.168],
            [-114.1208, 48.1138],
            [-114.1101, 47.9625],
            [-114.1878, 47.9623],
            [-114.3218, 47.9622],
            [-114.4922, 47.962],
            [-114.4774, 47.8321],
            [-114.5201, 47.7899],
            [-114.6046, 47.8756],
            [-114.8187, 47.8758],
            [-114.8814, 47.8728],
            [-114.9893, 47.8728],
            [-115.0107, 48.0176],
            [-115.0106, 48.0466],
            [-115.0201, 48.226],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '15420',
        GEOID: '3015420',
        NAME: 'Flathead High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3137387682,
        AWATER: 153717080,
        INTPTLAT: '+48.1210945',
        INTPTLON: '-114.5479392',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3775, 46.8334],
            [-114.3761, 47.009],
            [-114.2725, 47.0081],
            [-114.2101, 46.9359],
            [-114.1454, 46.9432],
            [-113.9792, 47.0495],
            [-113.7324, 47.1172],
            [-113.6948, 47.2076],
            [-113.7509, 47.2903],
            [-113.8243, 47.2744],
            [-113.8708, 47.2882],
            [-113.8949, 47.4337],
            [-113.9429, 47.4797],
            [-113.9487, 47.6],
            [-113.8503, 47.6002],
            [-113.9031, 47.6005],
            [-113.9041, 47.745],
            [-113.7108, 47.745],
            [-113.7109, 47.6001],
            [-113.6341, 47.6],
            [-113.4665, 47.6001],
            [-113.4664, 47.3463],
            [-113.4664, 47.1792],
            [-113.3029, 47.1796],
            [-113.3028, 47.0922],
            [-113.3031, 46.9673],
            [-113.3029, 46.8322],
            [-113.457, 46.8027],
            [-113.4799, 46.7882],
            [-113.668, 46.6584],
            [-113.8277, 46.6609],
            [-113.854, 46.661],
            [-113.9626, 46.6608],
            [-114.0553, 46.7331],
            [-114.1457, 46.7186],
            [-114.1708, 46.6604],
            [-114.3328, 46.6606],
            [-114.5948, 46.6335],
            [-114.6749, 46.7368],
            [-114.5491, 46.7474],
            [-114.549, 46.8336],
            [-114.3775, 46.8334],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '18540',
        GEOID: '3018540',
        NAME: 'Missoula High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5295686195,
        AWATER: 56682224,
        INTPTLAT: '+47.0693702',
        INTPTLON: '-113.8583358',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.4778, 46.1328],
            [-108.4048, 46.133],
            [-108.3032, 46.1327],
            [-108.3023, 46.0468],
            [-108.1729, 45.9987],
            [-108.2894, 45.9521],
            [-108.3148, 45.8956],
            [-108.4259, 45.8993],
            [-108.4681, 45.9584],
            [-108.5929, 45.9581],
            [-108.5924, 46.0439],
            [-108.4778, 46.1328],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '23970',
        GEOID: '3023970',
        NAME: 'Shepherd High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611942030,
        AWATER: 1483889,
        INTPTLAT: '+46.0221619',
        INTPTLON: '-108.4124906',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6255, 48.2197],
            [-110.6253, 48.3065],
            [-110.3674, 48.3064],
            [-110.0191, 48.3061],
            [-109.8698, 48.3059],
            [-109.8458, 48.25],
            [-109.8458, 48.2191],
            [-109.8397, 48.219],
            [-109.7269, 48.2215],
            [-109.7269, 48.1473],
            [-109.5343, 48.1346],
            [-109.5334, 47.9906],
            [-109.5388, 47.7662],
            [-109.5387, 47.7389],
            [-109.6775, 47.7347],
            [-109.8297, 47.7153],
            [-110.0662, 47.8214],
            [-110.0564, 47.9232],
            [-110.1227, 48.0172],
            [-110.3763, 47.995],
            [-110.417, 47.9586],
            [-110.5032, 48],
            [-110.6514, 48.0119],
            [-110.6255, 48.2197],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '03750',
        GEOID: '3003750',
        NAME: 'Big Sandy High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3569111373,
        AWATER: 15821567,
        INTPTLAT: '+48.0468783',
        INTPTLON: '-110.0354601',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1792, 47.6971],
            [-111.9231, 47.6982],
            [-111.9018, 47.6981],
            [-111.7743, 47.698],
            [-111.7312, 47.6111],
            [-111.902, 47.6111],
            [-111.9228, 47.5051],
            [-112.0478, 47.516],
            [-112.1369, 47.5137],
            [-112.3044, 47.5265],
            [-112.3108, 47.5746],
            [-112.298, 47.7132],
            [-112.1792, 47.6971],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '10170',
        GEOID: '3010170',
        NAME: 'Fairfield High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 704945579,
        AWATER: 18847112,
        INTPTLAT: '+47.6119949',
        INTPTLON: '-112.0469363',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4986, 46.3421],
            [-112.3106, 46.4212],
            [-112.2871, 46.4194],
            [-112.2427, 46.4225],
            [-112.1838, 46.5011],
            [-112.0752, 46.52],
            [-111.9592, 46.5692],
            [-111.9123, 46.5694],
            [-111.7879, 46.5695],
            [-111.7863, 46.3146],
            [-111.7842, 46.0828],
            [-111.9893, 46.0653],
            [-112.2241, 46.0656],
            [-112.225, 45.9583],
            [-112.4303, 45.96],
            [-112.4663, 46.093],
            [-112.5771, 46.179],
            [-112.5884, 46.2249],
            [-112.4986, 46.3421],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '15120',
        GEOID: '3015120',
        NAME: 'Jefferson High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2804207239,
        AWATER: 5413547,
        INTPTLAT: '+46.2737978',
        INTPTLON: '-112.1326505',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-111.3056, 46.1878],
              [-111.2421, 46.1878],
              [-111.062, 46.1928],
              [-110.7838, 46.1927],
              [-110.7907, 46.0829],
              [-110.8919, 46.083],
              [-110.9759, 45.9885],
              [-111.1515, 45.9908],
              [-111.2631, 46.0061],
              [-111.2648, 46.0061],
              [-111.2856, 46.0114],
              [-111.3056, 46.1878],
            ],
          ],
          [
            [
              [-111.4498, 45.7873],
              [-111.43, 45.8459],
              [-111.4273, 45.8557],
              [-111.4102, 45.8596],
              [-111.4102, 45.8636],
              [-111.3897, 45.9179],
              [-111.3898, 45.9255],
              [-111.3898, 45.9331],
              [-111.3366, 45.9416],
              [-111.3228, 45.9468],
              [-111.2839, 45.9555],
              [-111.2839, 45.9619],
              [-111.1863, 45.8456],
              [-111.2586, 45.787],
              [-111.2687, 45.7437],
              [-111.3566, 45.6715],
              [-111.4541, 45.6714],
              [-111.4498, 45.7873],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '17640',
        GEOID: '3017640',
        NAME: 'Manhattan High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1230848304,
        AWATER: 2877767,
        INTPTLAT: '+46.0793087',
        INTPTLON: '-110.9662297',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8698, 48.306],
            [-109.7352, 48.314],
            [-109.7139, 48.2199],
            [-109.6177, 48.2219],
            [-109.6177, 48.1483],
            [-109.7269, 48.1473],
            [-109.7269, 48.2215],
            [-109.8397, 48.219],
            [-109.8458, 48.2191],
            [-109.8458, 48.25],
            [-109.8698, 48.3059],
            [-109.8698, 48.306],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '28911',
        GEOID: '3028911',
        NAME: 'Rocky Boy High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211777062,
        AWATER: 46424,
        INTPTLAT: '+48.2406307',
        INTPTLON: '-109.7389107',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.639, 48.6589],
            [-114.6192, 48.7426],
            [-114.6191, 48.7456],
            [-114.4318, 48.7455],
            [-114.4263, 48.5734],
            [-114.2518, 48.5733],
            [-114.2518, 48.3925],
            [-114.2866, 48.3057],
            [-114.3497, 48.2805],
            [-114.3738, 48.3052],
            [-114.4089, 48.3499],
            [-114.4429, 48.3564],
            [-114.4427, 48.3528],
            [-114.4389, 48.3499],
            [-114.6317, 48.3995],
            [-114.8486, 48.3995],
            [-114.8498, 48.548],
            [-114.8886, 48.6588],
            [-114.639, 48.6589],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '27790',
        GEOID: '3027790',
        NAME: 'Whitefish High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1491555298,
        AWATER: 30541875,
        INTPTLAT: '+48.5291904',
        INTPTLON: '-114.5639020',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4303, 45.96],
            [-112.225, 45.9583],
            [-112.2241, 46.0656],
            [-111.9893, 46.0653],
            [-111.7842, 46.0828],
            [-111.7843, 46.0498],
            [-111.6603, 46.0055],
            [-111.66, 45.8778],
            [-111.6601, 45.8335],
            [-111.8053, 45.7983],
            [-111.8054, 45.7867],
            [-111.8449, 45.7498],
            [-112.0371, 45.7495],
            [-112.3783, 45.7445],
            [-112.4303, 45.96],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '27840',
        GEOID: '3027840',
        NAME: 'Whitehall High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1820225469,
        AWATER: 1625486,
        INTPTLAT: '+45.8964021',
        INTPTLON: '-112.0216047',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7575, 48.2599],
            [-104.6062, 48.3026],
            [-104.5846, 48.2447],
            [-104.4546, 48.2447],
            [-104.3896, 48.3026],
            [-104.2706, 48.3023],
            [-104.3248, 48.259],
            [-104.3226, 48.0452],
            [-104.5315, 48.1246],
            [-104.6109, 48.1414],
            [-104.7579, 48.2163],
            [-104.7575, 48.2599],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '07860',
        GEOID: '3007860',
        NAME: 'Culbertson High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 529182531,
        AWATER: 4671681,
        INTPTLAT: '+48.1894666',
        INTPTLON: '-104.4842545',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.965, 46.7499],
            [-109.8578, 46.7083],
            [-109.7876, 46.7803],
            [-109.6585, 46.7909],
            [-109.5589, 46.7639],
            [-109.5825, 46.6935],
            [-109.3892, 46.6936],
            [-109.3887, 46.5468],
            [-109.6917, 46.5616],
            [-109.9653, 46.6206],
            [-109.965, 46.7499],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '15390',
        GEOID: '3015390',
        NAME: 'Judith Gap High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761716595,
        AWATER: 50578,
        INTPTLAT: '+46.6488235',
        INTPTLON: '-109.6814117',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3897, 47.3096],
            [-111.3435, 47.3921],
            [-111.2823, 47.4354],
            [-111.1516, 47.4352],
            [-111.1492, 47.4352],
            [-111.1368, 47.4399],
            [-111.1165, 47.4397],
            [-111.0703, 47.4012],
            [-111.1065, 47.278],
            [-111.0041, 47.24],
            [-111.0039, 47.0878],
            [-111.0807, 47.0879],
            [-111.1584, 47.0118],
            [-111.2866, 47.001],
            [-111.2654, 47.0688],
            [-111.3743, 47.2132],
            [-111.3897, 47.3096],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '25140',
        GEOID: '3025140',
        NAME: 'Centerville High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 939301854,
        AWATER: 843130,
        INTPTLAT: '+47.2372472',
        INTPTLON: '-111.2076210',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4091, 48.1322],
            [-111.1437, 48.1329],
            [-110.8272, 48.1332],
            [-110.827, 48.2197],
            [-110.6255, 48.2197],
            [-110.6514, 48.0119],
            [-110.5032, 48],
            [-110.417, 47.9586],
            [-110.4292, 47.7425],
            [-110.5455, 47.6985],
            [-110.7693, 47.6984],
            [-110.8743, 47.7612],
            [-110.9764, 47.6986],
            [-111.2189, 47.6977],
            [-111.4089, 47.6981],
            [-111.4087, 47.7846],
            [-111.4084, 47.9242],
            [-111.4091, 48.1322],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '11260',
        GEOID: '3011260',
        NAME: 'Fort Benton High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3248236162,
        AWATER: 18672596,
        INTPTLAT: '+47.9197343',
        INTPTLON: '-110.9472082',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1681, 47.0226],
            [-109.1708, 47.0226],
            [-109.1124, 47.1143],
            [-109.0958, 47.2432],
            [-109.0079, 47.192],
            [-108.7361, 47.1834],
            [-108.7368, 47.0982],
            [-108.609, 47.0981],
            [-108.6111, 46.8368],
            [-108.6315, 46.7494],
            [-109.0096, 46.7507],
            [-109.1963, 46.7513],
            [-109.3159, 46.7513],
            [-109.1807, 46.7964],
            [-109.194, 46.928],
            [-109.1681, 47.0226],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '12990',
        GEOID: '3012990',
        NAME: 'Grass Range High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2064371173,
        AWATER: 1016932,
        INTPTLAT: '+46.9298732',
        INTPTLON: '-108.9365621',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4195, 47.6981],
            [-111.4089, 47.6981],
            [-111.2189, 47.6977],
            [-110.9764, 47.6986],
            [-111.0498, 47.5983],
            [-111.0295, 47.5822],
            [-111.0296, 47.4256],
            [-111.0703, 47.4012],
            [-111.1165, 47.4397],
            [-111.1368, 47.4399],
            [-111.1492, 47.4352],
            [-111.1516, 47.4352],
            [-111.2823, 47.4354],
            [-111.3435, 47.3921],
            [-111.4044, 47.4335],
            [-111.4107, 47.4331],
            [-111.4814, 47.4141],
            [-111.5263, 47.5174],
            [-111.5188, 47.5936],
            [-111.4195, 47.6027],
            [-111.4195, 47.6981],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13050',
        GEOID: '3013050',
        NAME: 'Great Falls High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1011554270,
        AWATER: 16255021,
        INTPTLAT: '+47.5546308',
        INTPTLON: '-111.2157261',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0189, 45.4533],
            [-107.0201, 45.2138],
            [-107.0203, 45.1862],
            [-107.0052, 44.9964],
            [-107.5683, 45.0009],
            [-107.8449, 45.0007],
            [-107.8441, 45.2593],
            [-107.6969, 45.2603],
            [-107.6964, 45.3853],
            [-107.5741, 45.4462],
            [-107.0189, 45.4533],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '17040',
        GEOID: '3017040',
        NAME: 'Lodge Grass High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2942958139,
        AWATER: 2762146,
        INTPTLAT: '+45.2073046',
        INTPTLON: '-107.4031079',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6603, 46.0055],
            [-111.4647, 45.9767],
            [-111.4238, 46.062],
            [-111.3056, 46.1878],
            [-111.2856, 46.0114],
            [-111.2648, 46.0061],
            [-111.284, 45.9764],
            [-111.2839, 45.9619],
            [-111.2839, 45.9555],
            [-111.3228, 45.9468],
            [-111.3366, 45.9416],
            [-111.3898, 45.9331],
            [-111.3898, 45.9255],
            [-111.3897, 45.9179],
            [-111.4102, 45.8636],
            [-111.4102, 45.8596],
            [-111.4273, 45.8557],
            [-111.43, 45.8459],
            [-111.4498, 45.7873],
            [-111.4541, 45.6714],
            [-111.5292, 45.6717],
            [-111.5133, 45.8376],
            [-111.6201, 45.8371],
            [-111.6304, 45.837],
            [-111.6332, 45.837],
            [-111.6398, 45.837],
            [-111.6601, 45.8335],
            [-111.66, 45.8778],
            [-111.6603, 46.0055],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '26190',
        GEOID: '3026190',
        NAME: 'Three Forks High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574923825,
        AWATER: 8056581,
        INTPTLAT: '+45.9356453',
        INTPTLON: '-111.4749028',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6453, 45.4641],
            [-108.645, 45.4641],
            [-108.3164, 45.4605],
            [-108.0706, 45.5185],
            [-108.0832, 45.2313],
            [-108.1049, 45.2205],
            [-108.6996, 45.2177],
            [-108.6996, 45.3446],
            [-108.6453, 45.4641],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13360',
        GEOID: '3013360',
        NAME: 'Plenty Coups High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1387569589,
        AWATER: 324508,
        INTPTLAT: '+45.3579660',
        INTPTLON: '-108.3611108',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3308, 46.5738],
            [-114.1093, 46.5718],
            [-114.0511, 46.6049],
            [-113.8152, 46.6162],
            [-113.8661, 46.4924],
            [-113.8678, 46.4859],
            [-113.8166, 46.3991],
            [-114.0257, 46.3995],
            [-114.1212, 46.4068],
            [-114.115, 46.4795],
            [-114.3794, 46.4603],
            [-114.3308, 46.5738],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '25050',
        GEOID: '3025050',
        NAME: 'Stevensville High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 690944990,
        AWATER: 3012092,
        INTPTLAT: '+46.5080544',
        INTPTLON: '-114.0462520',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8053, 45.7983],
            [-111.6601, 45.8335],
            [-111.6398, 45.837],
            [-111.6332, 45.837],
            [-111.6304, 45.837],
            [-111.6201, 45.8371],
            [-111.5133, 45.8376],
            [-111.5292, 45.6717],
            [-111.5368, 45.6568],
            [-111.6597, 45.7433],
            [-111.8054, 45.7867],
            [-111.8053, 45.7983],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '28170',
        GEOID: '3028170',
        NAME: 'Willow Creek High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230082290,
        AWATER: 2340326,
        INTPTLAT: '+45.7559363',
        INTPTLON: '-111.5924354',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0383, 45.187],
            [-105.0383, 45.2717],
            [-104.9775, 45.3526],
            [-104.8579, 45.3527],
            [-104.858, 45.4125],
            [-104.8579, 45.5376],
            [-104.9816, 45.5393],
            [-104.9819, 45.787],
            [-104.9393, 45.787],
            [-104.9393, 46.0044],
            [-104.8855, 46.1366],
            [-104.5161, 46.1362],
            [-104.5161, 46.0548],
            [-104.3907, 46.0548],
            [-104.3909, 45.9704],
            [-104.1348, 45.9688],
            [-104.1348, 45.8819],
            [-104.0442, 45.882],
            [-104.041, 45.4698],
            [-104.04, 45.2129],
            [-104.0579, 44.9976],
            [-105.0382, 45.0001],
            [-105.0383, 45.187],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '05610',
        GEOID: '3005610',
        NAME: 'Carter County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8405479207,
        AWATER: 19328422,
        INTPTLAT: '+45.5168247',
        INTPTLON: '-104.5153243',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1915, 45.8764],
            [-106.1916, 46.1372],
            [-106.1548, 46.3043],
            [-106.1204, 46.4836],
            [-106.1206, 46.8314],
            [-106.0865, 46.8459],
            [-106.0866, 46.8603],
            [-105.8327, 46.8608],
            [-105.6182, 46.8321],
            [-105.5468, 46.658],
            [-105.4189, 46.5709],
            [-105.2392, 46.5412],
            [-104.9847, 46.5404],
            [-104.7331, 46.6127],
            [-104.7327, 46.4822],
            [-104.906, 46.4823],
            [-104.8856, 46.2532],
            [-104.8855, 46.1366],
            [-104.9393, 46.0044],
            [-104.9393, 45.787],
            [-104.9819, 45.787],
            [-105.2887, 45.7877],
            [-105.4696, 45.7884],
            [-105.6547, 45.7884],
            [-105.7873, 45.7885],
            [-106.13, 45.7886],
            [-106.1917, 45.7886],
            [-106.1915, 45.8764],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '07930',
        GEOID: '3007930',
        NAME: 'Custer County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9798804902,
        AWATER: 25523467,
        INTPTLAT: '+46.2614807',
        INTPTLON: '-105.5505080',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.727, 49.0005],
            [-114.0682, 48.9989],
            [-114.0311, 48.8799],
            [-113.9569, 48.8175],
            [-113.8184, 48.8482],
            [-113.7029, 48.7199],
            [-113.7456, 48.6526],
            [-113.7505, 48.6143],
            [-113.4674, 48.5476],
            [-113.4667, 48.4756],
            [-113.356, 48.4239],
            [-113.349, 48.3103],
            [-113.2288, 48.2227],
            [-113.1308, 48.1401],
            [-113.0148, 48.131],
            [-112.952, 48.0447],
            [-112.9075, 47.9557],
            [-112.9847, 47.9539],
            [-113.0629, 47.8856],
            [-113.081, 47.7499],
            [-113.1658, 47.7198],
            [-113.1444, 47.5955],
            [-113.4665, 47.6001],
            [-113.6341, 47.6],
            [-113.6723, 47.8965],
            [-113.7955, 47.9698],
            [-113.9424, 48.0516],
            [-113.9448, 48.1395],
            [-113.9922, 48.2185],
            [-113.9926, 48.2185],
            [-114.0446, 48.2546],
            [-114.0802, 48.309],
            [-114.222, 48.2692],
            [-114.2535, 48.2947],
            [-114.2866, 48.3057],
            [-114.2518, 48.3925],
            [-114.2518, 48.5733],
            [-114.4263, 48.5734],
            [-114.4318, 48.7455],
            [-114.6191, 48.7456],
            [-114.7275, 48.8212],
            [-114.6894, 48.8639],
            [-114.727, 49.0005],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '07140',
        GEOID: '3007140',
        NAME: 'Columbia Falls High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8405142172,
        AWATER: 200330699,
        INTPTLAT: '+48.3222660',
        INTPTLON: '-113.7534147',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.4169, 49.0002],
            [-114.727, 49.0005],
            [-114.6894, 48.8639],
            [-114.7275, 48.8212],
            [-114.6191, 48.7456],
            [-114.6192, 48.7426],
            [-114.639, 48.6589],
            [-114.8886, 48.6588],
            [-114.8498, 48.548],
            [-114.8486, 48.3995],
            [-114.8481, 48.2261],
            [-115.0201, 48.226],
            [-115.1715, 48.2255],
            [-115.1082, 48.3126],
            [-115.1057, 48.5718],
            [-115.1507, 48.6297],
            [-115.1311, 48.6667],
            [-115.132, 48.7036],
            [-115.4124, 48.7052],
            [-115.3795, 48.8825],
            [-115.3795, 48.8837],
            [-115.4169, 49.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '16770',
        GEOID: '3016770',
        NAME: 'Lincoln County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2776442191,
        AWATER: 73369707,
        INTPTLAT: '+48.6855097',
        INTPTLON: '-115.0562685',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9761, 45.9467],
            [-110.9759, 45.9885],
            [-110.8919, 46.083],
            [-110.7907, 46.0829],
            [-110.7838, 46.1927],
            [-110.5326, 46.192],
            [-110.2819, 46.1844],
            [-110.2928, 45.9952],
            [-110.2929, 45.9274],
            [-110.2929, 45.9152],
            [-110.3905, 45.8565],
            [-110.3446, 45.7851],
            [-110.4886, 45.785],
            [-110.5956, 45.8185],
            [-110.7891, 45.8023],
            [-110.9139, 45.844],
            [-110.9766, 45.9026],
            [-110.9761, 45.9467],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '00933',
        GEOID: '3000933',
        NAME: 'Shields Valley High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1933295846,
        AWATER: 3101372,
        INTPTLAT: '+46.0096738',
        INTPTLON: '-110.5765249',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.7497, 47.7144],
            [-115.749, 47.7753],
            [-115.5003, 47.7889],
            [-115.4739, 47.915],
            [-115.1852, 47.919],
            [-115.1545, 48.0177],
            [-115.0107, 48.0176],
            [-114.9893, 47.8728],
            [-114.9904, 47.6962],
            [-115.0229, 47.5381],
            [-115.1298, 47.5483],
            [-115.1281, 47.423],
            [-115.2839, 47.4866],
            [-115.6295, 47.4796],
            [-115.6986, 47.5023],
            [-115.6916, 47.5934],
            [-115.7497, 47.7144],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '26100',
        GEOID: '3026100',
        NAME: 'Thompson Falls High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2406742433,
        AWATER: 11832882,
        INTPTLAT: '+47.6677221',
        INTPTLON: '-115.2957506',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9091, 47.4507],
            [-107.8212, 47.5455],
            [-107.6947, 47.6298],
            [-107.5943, 47.6497],
            [-107.4471, 47.6224],
            [-107.4149, 47.6917],
            [-107.249, 47.6616],
            [-106.9801, 47.6729],
            [-106.8617, 47.7374],
            [-106.571, 47.8129],
            [-106.4904, 47.9623],
            [-106.4186, 47.9575],
            [-106.3581, 47.8684],
            [-106.2609, 47.8684],
            [-106.2611, 47.531],
            [-106.2982, 47.3543],
            [-106.1707, 47.3545],
            [-106.1704, 47.1808],
            [-106.0868, 47.1809],
            [-106.0866, 46.8603],
            [-106.0865, 46.8459],
            [-106.7198, 46.8452],
            [-107.0796, 46.8592],
            [-107.1109, 46.8592],
            [-107.3396, 46.8591],
            [-107.7243, 46.8513],
            [-107.8922, 46.8512],
            [-107.8847, 46.9842],
            [-107.9223, 47.0769],
            [-107.9543, 47.1207],
            [-107.9566, 47.3609],
            [-107.9091, 47.4507],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '11880',
        GEOID: '3011880',
        NAME: 'Garfield County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12112207601,
        AWATER: 446716183,
        INTPTLAT: '+47.2800330',
        INTPTLON: '-106.9867061',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.5161, 45.9388],
            [-113.4282, 45.8222],
            [-113.3644, 45.771],
            [-113.2505, 45.8412],
            [-113.1489, 45.8836],
            [-113.0855, 45.8606],
            [-113.0002, 45.8077],
            [-112.8236, 45.7743],
            [-112.7358, 45.7012],
            [-112.7367, 45.7001],
            [-112.6871, 45.6249],
            [-112.6878, 45.5656],
            [-112.6878, 45.4772],
            [-112.4667, 45.3966],
            [-112.4575, 45.1739],
            [-112.4569, 45.0885],
            [-112.3339, 45.0884],
            [-112.3342, 45.0005],
            [-112.1912, 45.0005],
            [-112.1671, 44.8267],
            [-112.0658, 44.7835],
            [-112.3933, 44.7889],
            [-112.3925, 44.9347],
            [-112.792, 44.9313],
            [-112.9499, 44.9083],
            [-112.9496, 44.6536],
            [-113.0644, 44.6521],
            [-113.1016, 44.7295],
            [-113.2472, 44.823],
            [-113.3572, 44.8207],
            [-113.4551, 44.8654],
            [-113.452, 45.0592],
            [-113.522, 45.0932],
            [-113.5091, 45.1137],
            [-113.6929, 45.2642],
            [-113.7738, 45.4064],
            [-113.7654, 45.4768],
            [-113.8334, 45.5205],
            [-113.8064, 45.6016],
            [-113.9381, 45.6957],
            [-113.7125, 45.8384],
            [-113.5324, 45.8784],
            [-113.5161, 45.9388],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '03090',
        GEOID: '3003090',
        NAME: 'Beaverhead County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10190570525,
        AWATER: 31603673,
        INTPTLAT: '+45.3157262',
        INTPTLON: '-113.0935546',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2929, 45.9274],
            [-110.2928, 45.9952],
            [-110.2819, 46.1844],
            [-110.282, 46.2206],
            [-109.6539, 46.2194],
            [-109.6042, 46.1325],
            [-109.6052, 46.045],
            [-109.4172, 46.0448],
            [-109.423, 45.96],
            [-109.5063, 45.931],
            [-109.5488, 45.8707],
            [-109.5639, 45.7173],
            [-109.6046, 45.708],
            [-109.6427, 45.6756],
            [-109.6435, 45.609],
            [-109.734, 45.5658],
            [-109.8088, 45.5225],
            [-109.9311, 45.5225],
            [-109.932, 45.3488],
            [-110.0646, 45.3488],
            [-110.0645, 45.1721],
            [-110.2281, 45.1721],
            [-110.2185, 45.6514],
            [-110.2185, 45.6522],
            [-110.2195, 45.6809],
            [-110.2209, 45.7111],
            [-110.2203, 45.7475],
            [-110.2927, 45.7851],
            [-110.2929, 45.9152],
            [-110.2929, 45.9274],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '25560',
        GEOID: '3025560',
        NAME: 'Sweet Grass County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4786043098,
        AWATER: 17432445,
        INTPTLAT: '+45.8179799',
        INTPTLON: '-109.9435792',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2357, 45.3518],
            [-106.2341, 45.4936],
            [-106.2345, 45.7886],
            [-106.1917, 45.7886],
            [-106.13, 45.7886],
            [-105.7873, 45.7885],
            [-105.6547, 45.7884],
            [-105.4696, 45.7884],
            [-105.2887, 45.7877],
            [-104.9819, 45.787],
            [-104.9816, 45.5393],
            [-104.8579, 45.5376],
            [-104.858, 45.4125],
            [-104.8579, 45.3527],
            [-104.9775, 45.3526],
            [-105.0383, 45.2717],
            [-105.0383, 45.187],
            [-105.0382, 45.0001],
            [-105.0766, 44.9999],
            [-105.1969, 45.0001],
            [-105.7855, 45.0004],
            [-106.0248, 44.9935],
            [-106.2637, 44.9938],
            [-106.2797, 45.1797],
            [-106.2795, 45.3518],
            [-106.2357, 45.3518],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '04860',
        GEOID: '3004860',
        NAME: 'Powder River County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8788658674,
        AWATER: 1736644,
        INTPTLAT: '+45.4089262',
        INTPTLON: '-105.5553440',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.409, 48.2195],
            [-111.2753, 48.2195],
            [-111.2754, 48.3449],
            [-111.2734, 48.7042],
            [-111.2734, 48.7107],
            [-111.2733, 48.876],
            [-111.2733, 48.8815],
            [-111.2699, 48.9972],
            [-111.0691, 48.9977],
            [-110.6111, 48.9988],
            [-110.6253, 48.3065],
            [-110.6255, 48.2197],
            [-110.827, 48.2197],
            [-110.8272, 48.1332],
            [-111.1437, 48.1329],
            [-111.4091, 48.1322],
            [-111.409, 48.2195],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '00101',
        GEOID: '3000101',
        NAME: 'Chester-Joplin-Inverness High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4521658332,
        AWATER: 45838181,
        INTPTLAT: '+48.5729312',
        INTPTLON: '-110.9546212',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2031, 47.2562],
            [-113.8213, 47.2417],
            [-113.8243, 47.2744],
            [-113.7509, 47.2903],
            [-113.6948, 47.2076],
            [-113.7324, 47.1172],
            [-113.9792, 47.0495],
            [-114.1049, 47.0505],
            [-114.1872, 47.1382],
            [-114.2031, 47.2562],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '02250',
        GEOID: '3002250',
        NAME: 'Arlee High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 707944704,
        AWATER: 2336828,
        INTPTLAT: '+47.1585519',
        INTPTLON: '-113.9521918',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.365, 47.5181],
            [-114.3502, 47.6019],
            [-114.2198, 47.6023],
            [-114.1883, 47.6495],
            [-113.9659, 47.6407],
            [-113.9487, 47.6],
            [-113.9429, 47.4797],
            [-113.8949, 47.4337],
            [-114.1077, 47.4339],
            [-114.1825, 47.4994],
            [-114.2828, 47.4714],
            [-114.365, 47.5181],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '22800',
        GEOID: '3022800',
        NAME: 'Ronan High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 536809581,
        AWATER: 17365309,
        INTPTLAT: '+47.5401660',
        INTPTLON: '-114.1131864',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.365, 47.5181],
            [-114.2828, 47.4714],
            [-114.1825, 47.4994],
            [-114.1077, 47.4339],
            [-114.203, 47.3557],
            [-114.2031, 47.2562],
            [-114.1872, 47.1382],
            [-114.2536, 47.121],
            [-114.4825, 47.22],
            [-114.4802, 47.5191],
            [-114.365, 47.5181],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '06115',
        GEOID: '3006115',
        NAME: 'Charlo High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 873436059,
        AWATER: 13939453,
        INTPTLAT: '+47.3432396',
        INTPTLON: '-114.3226031',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3064, 47.2534],
            [-112.1752, 47.2482],
            [-112.1185, 47.2479],
            [-112.1177, 47.1122],
            [-111.9201, 47.1357],
            [-111.7893, 47.1289],
            [-111.7902, 46.9138],
            [-111.6581, 46.9134],
            [-111.6166, 46.8983],
            [-111.6228, 46.8294],
            [-111.4981, 46.7625],
            [-111.6393, 46.7382],
            [-111.6401, 46.5825],
            [-111.7879, 46.5695],
            [-111.9123, 46.5694],
            [-111.9592, 46.5692],
            [-112.0752, 46.52],
            [-112.1838, 46.5011],
            [-112.2427, 46.4225],
            [-112.2871, 46.4194],
            [-112.3106, 46.4212],
            [-112.2982, 46.6181],
            [-112.4106, 46.656],
            [-112.5356, 46.6995],
            [-112.5357, 46.8342],
            [-112.4499, 46.9521],
            [-112.3169, 47.0153],
            [-112.4301, 47.1534],
            [-112.5616, 47.1747],
            [-112.56, 47.2648],
            [-112.3064, 47.2534],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13830',
        GEOID: '3013830',
        NAME: 'Helena High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4035371201,
        AWATER: 73859000,
        INTPTLAT: '+46.8361092',
        INTPTLON: '-112.0814249',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.5201, 47.7899],
            [-114.4774, 47.8321],
            [-114.4922, 47.962],
            [-114.3218, 47.9622],
            [-114.1878, 47.9623],
            [-114.1101, 47.9625],
            [-114.0629, 47.9625],
            [-114.0373, 47.8846],
            [-113.9414, 47.8847],
            [-113.9255, 47.7554],
            [-113.9659, 47.6407],
            [-114.1883, 47.6495],
            [-114.2198, 47.6023],
            [-114.3502, 47.6019],
            [-114.3504, 47.6021],
            [-114.4975, 47.6287],
            [-114.5201, 47.7899],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '21090',
        GEOID: '3021090',
        NAME: 'Polson High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1147367629,
        AWATER: 372184920,
        INTPTLAT: '+47.7857780',
        INTPTLON: '-114.2364166',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9847, 47.9539],
            [-112.9133, 47.8262],
            [-112.9139, 47.6972],
            [-112.8547, 47.6292],
            [-112.5687, 47.636],
            [-112.4327, 47.6061],
            [-112.3044, 47.5265],
            [-112.1369, 47.5137],
            [-112.0478, 47.516],
            [-112.0457, 47.3516],
            [-112.0448, 47.1927],
            [-111.9201, 47.1357],
            [-112.1177, 47.1122],
            [-112.1185, 47.2479],
            [-112.1752, 47.2482],
            [-112.3064, 47.2534],
            [-112.56, 47.2648],
            [-112.5616, 47.1747],
            [-112.6126, 47.2231],
            [-112.7464, 47.2475],
            [-112.9337, 47.3458],
            [-112.9242, 47.3874],
            [-113.0592, 47.4886],
            [-113.1444, 47.5955],
            [-113.1658, 47.7198],
            [-113.081, 47.7499],
            [-113.0629, 47.8856],
            [-112.9847, 47.9539],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '02450',
        GEOID: '3002450',
        NAME: 'Augusta High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3236279903,
        AWATER: 23194500,
        INTPTLAT: '+47.4692663',
        INTPTLON: '-112.6172950',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4172, 46.0448],
            [-109.4162, 46.1324],
            [-109.1601, 46.1319],
            [-109.0828, 46.1319],
            [-109.0827, 46.0472],
            [-108.9224, 45.9741],
            [-108.9019, 45.9019],
            [-108.9019, 45.8136],
            [-108.902, 45.8029],
            [-108.9851, 45.803],
            [-109.0635, 45.727],
            [-109.2369, 45.7972],
            [-109.2966, 45.8729],
            [-109.465, 45.8873],
            [-109.5063, 45.931],
            [-109.423, 45.96],
            [-109.4172, 46.0448],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '21900',
        GEOID: '3021900',
        NAME: 'Rapelje High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1261113924,
        AWATER: 4093826,
        INTPTLAT: '+45.9582327',
        INTPTLON: '-109.1936164',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.278, 46.0453],
            [-113.2845, 46.2228],
            [-113.0368, 46.2233],
            [-113.0368, 46.2668],
            [-112.8892, 46.2659],
            [-112.8414, 46.1943],
            [-112.6577, 46.1947],
            [-112.5966, 46.1655],
            [-112.686, 46.1357],
            [-112.7771, 46.1358],
            [-112.7777, 46.0483],
            [-112.9228, 46.0048],
            [-112.9232, 45.9348],
            [-113.0855, 45.8606],
            [-113.1489, 45.8836],
            [-113.2505, 45.8412],
            [-113.3644, 45.771],
            [-113.4282, 45.8222],
            [-113.5161, 45.9388],
            [-113.5174, 45.9401],
            [-113.278, 46.0453],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '02030',
        GEOID: '3002030',
        NAME: 'Anaconda High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1727224653,
        AWATER: 12167685,
        INTPTLAT: '+46.0218272',
        INTPTLON: '-113.0672846',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.2966, 45.8729],
            [-109.2369, 45.7972],
            [-109.0635, 45.727],
            [-109.0336, 45.6407],
            [-109.0345, 45.5969],
            [-109.1268, 45.5877],
            [-109.1917, 45.4884],
            [-109.2777, 45.4644],
            [-109.3564, 45.4643],
            [-109.3633, 45.5946],
            [-109.3674, 45.595],
            [-109.3926, 45.6065],
            [-109.3381, 45.6482],
            [-109.3398, 45.6525],
            [-109.4966, 45.7355],
            [-109.335, 45.752],
            [-109.2966, 45.8729],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '07200',
        GEOID: '3007200',
        NAME: 'Columbus High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 837567040,
        AWATER: 8824739,
        INTPTLAT: '+45.6517827',
        INTPTLON: '-109.2175310',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9311, 45.5225],
            [-109.8088, 45.5225],
            [-109.734, 45.5658],
            [-109.4437, 45.583],
            [-109.3926, 45.6065],
            [-109.3674, 45.595],
            [-109.3633, 45.5946],
            [-109.3564, 45.4643],
            [-109.3972, 45.4498],
            [-109.5003, 45.4063],
            [-109.6085, 45.2614],
            [-109.6879, 45.2614],
            [-109.6911, 45.1673],
            [-109.7987, 45.1673],
            [-109.8122, 45.1721],
            [-110.0645, 45.1721],
            [-110.0646, 45.3488],
            [-109.932, 45.3488],
            [-109.9311, 45.5225],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '01740',
        GEOID: '3001740',
        NAME: 'Absarokee High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1598184815,
        AWATER: 6777608,
        INTPTLAT: '+45.3766476',
        INTPTLON: '-109.7502143',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0828, 46.1319],
            [-108.7797, 46.1325],
            [-108.7793, 46.2104],
            [-108.4766, 46.2079],
            [-108.4778, 46.1328],
            [-108.5924, 46.0439],
            [-108.5929, 45.9581],
            [-108.6336, 45.9169],
            [-108.7241, 45.8276],
            [-108.7757, 45.8277],
            [-108.9019, 45.8136],
            [-108.9019, 45.9019],
            [-108.9224, 45.9741],
            [-109.0827, 46.0472],
            [-109.0828, 46.1319],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '04920',
        GEOID: '3004920',
        NAME: 'Broadview High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1102788131,
        AWATER: 1662140,
        INTPTLAT: '+46.0457221',
        INTPTLON: '-108.7578843',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.281, 46.4089],
            [-110.2733, 46.7105],
            [-109.965, 46.7499],
            [-109.9653, 46.6206],
            [-109.6917, 46.5616],
            [-109.3887, 46.5468],
            [-109.4032, 46.356],
            [-109.4029, 46.2197],
            [-109.6445, 46.2194],
            [-109.6539, 46.2194],
            [-110.282, 46.2206],
            [-110.281, 46.4089],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '13470',
        GEOID: '3013470',
        NAME: 'Harlowton High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3090507252,
        AWATER: 12995606,
        INTPTLAT: '+46.4368668',
        INTPTLON: '-109.8704479',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0635, 45.727],
            [-108.9851, 45.803],
            [-108.902, 45.8029],
            [-108.9019, 45.7843],
            [-108.9047, 45.6828],
            [-108.843, 45.611],
            [-108.9241, 45.6052],
            [-109.0345, 45.5969],
            [-109.0336, 45.6407],
            [-109.0635, 45.727],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '20070',
        GEOID: '3020070',
        NAME: 'Park City High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239669461,
        AWATER: 1862119,
        INTPTLAT: '+45.7015700',
        INTPTLON: '-108.9648849',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.734, 45.5658],
            [-109.6435, 45.609],
            [-109.6427, 45.6756],
            [-109.6046, 45.708],
            [-109.5639, 45.7173],
            [-109.5488, 45.8707],
            [-109.5063, 45.931],
            [-109.465, 45.8873],
            [-109.2966, 45.8729],
            [-109.335, 45.752],
            [-109.4966, 45.7355],
            [-109.3398, 45.6525],
            [-109.3381, 45.6482],
            [-109.3926, 45.6065],
            [-109.4437, 45.583],
            [-109.734, 45.5658],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '22260',
        GEOID: '3022260',
        NAME: 'Reedpoint High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573866807,
        AWATER: 2628510,
        INTPTLAT: '+45.7427203',
        INTPTLON: '-109.4758593',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8047, 48.3029],
            [-105.8044, 48.5634],
            [-105.6064, 48.5633],
            [-105.4092, 48.5632],
            [-105.4097, 48.2154],
            [-105.452, 48.0894],
            [-105.5821, 48.08],
            [-105.8439, 48.0103],
            [-105.8438, 48.219],
            [-105.8047, 48.3029],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '28620',
        GEOID: '3028620',
        NAME: 'Wolf Point High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1637470413,
        AWATER: 6096956,
        INTPTLAT: '+48.3168109',
        INTPTLON: '-105.6286771',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.4665, 47.6001],
            [-113.1444, 47.5955],
            [-113.0592, 47.4886],
            [-113.0591, 47.1796],
            [-112.7958, 47.177],
            [-112.7961, 46.9685],
            [-112.7953, 46.8317],
            [-112.5357, 46.8342],
            [-112.5356, 46.6995],
            [-112.4106, 46.656],
            [-112.2982, 46.6181],
            [-112.3106, 46.4212],
            [-112.4986, 46.3421],
            [-112.5884, 46.2249],
            [-112.5771, 46.179],
            [-112.5966, 46.1655],
            [-112.6577, 46.1947],
            [-112.8414, 46.1943],
            [-112.8892, 46.2659],
            [-113.0368, 46.2668],
            [-113.0367, 46.3597],
            [-113.0385, 46.547],
            [-113.0387, 46.7355],
            [-113.1156, 46.7134],
            [-113.3029, 46.8322],
            [-113.3031, 46.9673],
            [-113.3028, 47.0922],
            [-113.3029, 47.1796],
            [-113.4664, 47.1792],
            [-113.4664, 47.3463],
            [-113.4665, 47.6001],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '21450',
        GEOID: '3021450',
        NAME: 'Powell County High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6205216666,
        AWATER: 16252364,
        INTPTLAT: '+46.8442254',
        INTPTLON: '-112.9310998',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6581, 46.9134],
            [-111.5392, 46.9134],
            [-111.5343, 47.0006],
            [-111.2866, 47.001],
            [-111.1584, 47.0118],
            [-111.0807, 47.0879],
            [-111.0843, 47.0106],
            [-110.913, 46.9806],
            [-110.856, 46.9213],
            [-110.6529, 46.8236],
            [-110.6207, 46.7722],
            [-110.4546, 46.6795],
            [-110.396, 46.6886],
            [-110.2733, 46.7105],
            [-110.281, 46.4089],
            [-110.282, 46.2206],
            [-110.2819, 46.1844],
            [-110.5326, 46.192],
            [-110.7838, 46.1927],
            [-111.062, 46.1928],
            [-111.1181, 46.317],
            [-111.057, 46.3981],
            [-111.1495, 46.4507],
            [-111.2465, 46.4381],
            [-111.3202, 46.5694],
            [-111.4981, 46.7625],
            [-111.6228, 46.8294],
            [-111.6166, 46.8983],
            [-111.6581, 46.9134],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '28770',
        GEOID: '3028770',
        NAME: 'White Sulphur Springs High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6194857385,
        AWATER: 7404298,
        INTPTLAT: '+46.5856996',
        INTPTLON: '-110.9217396',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3182, 48.2216],
            [-112.2072, 48.2214],
            [-112.1203, 48.2445],
            [-112.099, 48.3955],
            [-112.1423, 48.468],
            [-112.0044, 48.4489],
            [-111.9909, 48.3943],
            [-111.7959, 48.3931],
            [-111.6658, 48.3495],
            [-111.6656, 48.2195],
            [-111.6663, 48.0886],
            [-111.7745, 48.1156],
            [-112.0059, 48.1014],
            [-112.0272, 48.0429],
            [-112.1778, 48.1307],
            [-112.4046, 48.1319],
            [-112.3182, 48.2216],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '07320',
        GEOID: '3007320',
        NAME: 'Conrad High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1333033972,
        AWATER: 1369545,
        INTPTLAT: '+48.2277428',
        INTPTLON: '-111.9537886',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5812, 48.3099],
            [-112.5772, 48.3138],
            [-112.5769, 48.4833],
            [-112.1848, 48.4776],
            [-112.1423, 48.468],
            [-112.099, 48.3955],
            [-112.1203, 48.2445],
            [-112.2072, 48.2214],
            [-112.3182, 48.2216],
            [-112.4046, 48.1319],
            [-112.9007, 48.131],
            [-112.7474, 48.2305],
            [-112.6305, 48.2443],
            [-112.5812, 48.3099],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '27090',
        GEOID: '3027090',
        NAME: 'Valier High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1335795152,
        AWATER: 37497310,
        INTPTLAT: '+48.2833465',
        INTPTLON: '-112.4222648',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1944, 48.4797],
            [-106.1947, 48.567],
            [-106.0647, 48.5664],
            [-106.021, 48.5663],
            [-105.8044, 48.5634],
            [-105.8047, 48.3029],
            [-105.8438, 48.219],
            [-105.8439, 48.0103],
            [-105.8527, 48.0084],
            [-106.0062, 48.0337],
            [-106.1083, 48.0062],
            [-106.1083, 48.2191],
            [-106.0629, 48.3053],
            [-106.1947, 48.3058],
            [-106.1944, 48.4797],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '11460',
        GEOID: '3011460',
        NAME: 'Frazer High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1448342258,
        AWATER: 6448755,
        INTPTLAT: '+48.3206929',
        INTPTLON: '-105.9907372',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1268, 45.5877],
            [-109.0345, 45.5969],
            [-108.9241, 45.6052],
            [-108.843, 45.611],
            [-108.7936, 45.6045],
            [-108.8018, 45.552],
            [-108.6989, 45.5083],
            [-108.9431, 45.4823],
            [-108.9479, 45.3723],
            [-109.0891, 45.3771],
            [-109.1917, 45.4884],
            [-109.1268, 45.5877],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '15260',
        GEOID: '3015260',
        NAME: 'Joliet High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 542347841,
        AWATER: 5257033,
        INTPTLAT: '+45.5220240',
        INTPTLON: '-108.9914559',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.7243, 46.8513],
            [-107.3396, 46.8591],
            [-107.1109, 46.8592],
            [-107.0796, 46.8592],
            [-106.7198, 46.8452],
            [-106.7492, 46.831],
            [-106.7493, 46.4851],
            [-106.7891, 46.3507],
            [-106.5578, 46.351],
            [-106.4958, 46.0508],
            [-106.8722, 46.0361],
            [-106.9391, 46.0048],
            [-106.9391, 46.1367],
            [-107.0055, 46.1367],
            [-107.0284, 46.3955],
            [-107.1534, 46.3956],
            [-107.1536, 46.4681],
            [-107.1745, 46.4827],
            [-107.728, 46.4818],
            [-107.7243, 46.8513],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '11190',
        GEOID: '3011190',
        NAME: 'Forsyth High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4747317616,
        AWATER: 23038182,
        INTPTLAT: '+46.4834882',
        INTPTLON: '-107.0756033',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0096, 46.7507],
            [-108.6315, 46.7494],
            [-108.3235, 46.752],
            [-108.0086, 46.755],
            [-108.01, 46.5075],
            [-107.9295, 46.5061],
            [-107.9285, 46.3953],
            [-108.0294, 46.2641],
            [-108.3208, 46.264],
            [-108.4049, 46.236],
            [-108.4048, 46.133],
            [-108.4778, 46.1328],
            [-108.4766, 46.2079],
            [-108.7793, 46.2104],
            [-108.7793, 46.2774],
            [-108.8623, 46.3646],
            [-108.9049, 46.5769],
            [-109.01, 46.6212],
            [-109.0096, 46.7507],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '23070',
        GEOID: '3023070',
        NAME: 'Roundup High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4103285433,
        AWATER: 7113774,
        INTPTLAT: '+46.5052807',
        INTPTLON: '-108.4397631',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.365, 48.9987],
            [-112.3661, 48.8167],
            [-112.7104, 48.8181],
            [-112.7106, 48.5413],
            [-112.5765, 48.542],
            [-112.5769, 48.4833],
            [-112.5772, 48.3138],
            [-112.5812, 48.3099],
            [-112.9674, 48.3099],
            [-113.349, 48.3103],
            [-113.356, 48.4239],
            [-113.4667, 48.4756],
            [-113.4674, 48.5476],
            [-113.7505, 48.6143],
            [-113.7456, 48.6526],
            [-113.7029, 48.7199],
            [-113.8184, 48.8482],
            [-113.9569, 48.8175],
            [-114.0311, 48.8799],
            [-114.0682, 48.9989],
            [-113.6948, 48.9975],
            [-112.365, 48.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '05190',
        GEOID: '3005190',
        NAME: 'Browning High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6143070535,
        AWATER: 93735495,
        INTPTLAT: '+48.6700782',
        INTPTLON: '-113.1568269',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.9391, 46.0048],
            [-106.8722, 46.0361],
            [-106.4958, 46.0508],
            [-106.4957, 45.9206],
            [-106.3462, 45.9207],
            [-106.3032, 45.8767],
            [-106.3272, 45.6863],
            [-106.7276, 45.6765],
            [-106.913, 45.6839],
            [-106.9391, 45.87],
            [-106.9391, 46.0048],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '07080',
        GEOID: '3007080',
        NAME: 'Colstrip High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1691259031,
        AWATER: 864250,
        INTPTLAT: '+45.8450514',
        INTPTLON: '-106.6460698',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.365, 48.9987],
            [-112.1934, 48.9985],
            [-112.1891, 48.6534],
            [-112.1848, 48.4776],
            [-112.5769, 48.4833],
            [-112.5765, 48.542],
            [-112.7106, 48.5413],
            [-112.7104, 48.8181],
            [-112.3661, 48.8167],
            [-112.365, 48.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '08190',
        GEOID: '3008190',
        NAME: 'Cut Bank High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1613652050,
        AWATER: 12404716,
        INTPTLAT: '+48.6991466',
        INTPTLON: '-112.3929518',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8152, 46.6162],
            [-113.8277, 46.6609],
            [-113.668, 46.6584],
            [-113.4799, 46.7882],
            [-113.457, 46.8027],
            [-113.3029, 46.8322],
            [-113.1156, 46.7134],
            [-113.0387, 46.7355],
            [-113.0385, 46.547],
            [-113.0574, 46.5471],
            [-113.2757, 46.5482],
            [-113.2752, 46.4921],
            [-113.4113, 46.4918],
            [-113.4665, 46.5715],
            [-113.6231, 46.5715],
            [-113.6231, 46.4951],
            [-113.8661, 46.4924],
            [-113.8152, 46.6162],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '09210',
        GEOID: '3009210',
        NAME: 'Drummond High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1448030741,
        AWATER: 1292924,
        INTPTLAT: '+46.6428258',
        INTPTLON: '-113.4102851',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0383, 45.2609],
            [-110.8041, 45.2778],
            [-110.752, 45.1774],
            [-110.4446, 45.1739],
            [-110.2281, 45.1721],
            [-110.0645, 45.1721],
            [-109.8122, 45.1721],
            [-109.7987, 45.1673],
            [-109.7985, 45.0023],
            [-110.0015, 45.0034],
            [-110.7045, 44.9922],
            [-111.0451, 45.0009],
            [-111.0383, 45.2609],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '11850',
        GEOID: '3011850',
        NAME: 'Gardiner High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2100125011,
        AWATER: 12742345,
        INTPTLAT: '+45.1045153',
        INTPTLON: '-110.5189936',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4047, 48.1157],
            [-107.3693, 48.3052],
            [-107.2605, 48.3048],
            [-107.2603, 48.3941],
            [-107.3017, 48.4809],
            [-107.2015, 48.5095],
            [-107.206, 48.9127],
            [-107.1798, 49.0001],
            [-106.6513, 48.9999],
            [-106.6797, 48.9124],
            [-106.676, 48.6958],
            [-106.7245, 48.6552],
            [-106.9003, 48.6551],
            [-106.9104, 48.4084],
            [-106.8454, 48.4082],
            [-106.8459, 48.2609],
            [-106.8778, 48.2175],
            [-106.8776, 47.9606],
            [-107.4048, 47.9633],
            [-107.4047, 48.1157],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '14370',
        GEOID: '3014370',
        NAME: 'Hinsdale High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3962454358,
        AWATER: 11156307,
        INTPTLAT: '+48.4950492',
        INTPTLON: '-107.0472065',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.0086, 46.755],
            [-107.8277, 46.7559],
            [-107.8922, 46.8512],
            [-107.7243, 46.8513],
            [-107.728, 46.4818],
            [-107.7385, 46.4818],
            [-107.7821, 46.3952],
            [-107.9285, 46.3953],
            [-107.9295, 46.5061],
            [-108.01, 46.5075],
            [-108.0086, 46.755],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '18270',
        GEOID: '3018270',
        NAME: 'Melstone High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794794365,
        AWATER: 140360,
        INTPTLAT: '+46.6225427',
        INTPTLON: '-107.8771755',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4575, 45.1739],
            [-112.2669, 45.1727],
            [-112.256, 45.3324],
            [-112.1948, 45.5512],
            [-112.0391, 45.5523],
            [-111.9729, 45.5412],
            [-111.9716, 45.348],
            [-112.043, 45.348],
            [-112.0345, 45.1697],
            [-111.8904, 45.1682],
            [-111.8572, 45.003],
            [-111.9176, 45.0031],
            [-111.9117, 44.7821],
            [-112.0658, 44.7835],
            [-112.1671, 44.8267],
            [-112.1912, 45.0005],
            [-112.3342, 45.0005],
            [-112.3339, 45.0884],
            [-112.4569, 45.0885],
            [-112.4575, 45.1739],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '24180',
        GEOID: '3024180',
        NAME: 'Sheridan High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2085404757,
        AWATER: 4232167,
        INTPTLAT: '+45.1586838',
        INTPTLON: '-112.1109288',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0412, 48.48],
            [-109.9759, 48.4815],
            [-109.9087, 48.4816],
            [-109.9193, 48.4657],
            [-109.8698, 48.306],
            [-109.8698, 48.3059],
            [-110.0191, 48.3061],
            [-110.3674, 48.3064],
            [-110.3672, 48.3644],
            [-110.106, 48.3929],
            [-110.0412, 48.48],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '04500',
        GEOID: '3004500',
        NAME: 'Box Elder High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435190918,
        AWATER: 422815,
        INTPTLAT: '+48.3702485',
        INTPTLON: '-110.0823238',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0201, 45.2138],
            [-107.0189, 45.4533],
            [-107.0171, 45.6914],
            [-106.913, 45.6839],
            [-106.7276, 45.6765],
            [-106.3272, 45.6863],
            [-106.3032, 45.8767],
            [-106.1915, 45.8764],
            [-106.1917, 45.7886],
            [-106.2345, 45.7886],
            [-106.2341, 45.4936],
            [-106.2357, 45.3518],
            [-106.2795, 45.3518],
            [-106.2797, 45.1797],
            [-106.768, 45.1861],
            [-107.0203, 45.1862],
            [-107.0201, 45.2138],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '00095',
        GEOID: '3000095',
        NAME: 'Lame Deer High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3507925034,
        AWATER: 571461,
        INTPTLAT: '+45.4383323',
        INTPTLON: '-106.5888597',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7987, 45.1673],
            [-109.6911, 45.1673],
            [-109.6879, 45.2614],
            [-109.6085, 45.2614],
            [-109.5003, 45.4063],
            [-109.3972, 45.4498],
            [-109.3564, 45.4643],
            [-109.2777, 45.4644],
            [-109.2887, 45.3619],
            [-109.1403, 45.2697],
            [-109.1613, 45.1859],
            [-109.1947, 45.0057],
            [-109.7985, 45.0023],
            [-109.7987, 45.1673],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '22110',
        GEOID: '3022110',
        NAME: 'Red Lodge High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1797388651,
        AWATER: 15000927,
        INTPTLAT: '+45.2036324',
        INTPTLON: '-109.4241626',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6111, 48.9988],
            [-110.468, 48.999],
            [-110.3, 48.9236],
            [-110.2267, 48.8561],
            [-110.2269, 48.8421],
            [-110.2275, 48.8264],
            [-110.1386, 48.8117],
            [-110.0518, 48.6538],
            [-109.9644, 48.6539],
            [-109.9759, 48.4815],
            [-110.0412, 48.48],
            [-110.106, 48.3929],
            [-110.3672, 48.3644],
            [-110.3674, 48.3064],
            [-110.6253, 48.3065],
            [-110.6111, 48.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '00097',
        GEOID: '3000097',
        NAME: 'North Star High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2676132702,
        AWATER: 17996181,
        INTPTLAT: '+48.6325649',
        INTPTLON: '-110.3810185',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-104.5238, 47.8502],
              [-104.2503, 47.8436],
              [-104.1651, 47.7904],
              [-104.0425, 47.807],
              [-104.0425, 47.8068],
              [-104.0432, 47.7611],
              [-104.0439, 47.5904],
              [-104.0453, 47.3971],
              [-104.1319, 47.3539],
              [-104.4203, 47.3544],
              [-104.4203, 47.3544],
              [-104.2805, 47.465],
              [-104.2704, 47.553],
              [-104.3344, 47.5475],
              [-104.5106, 47.5729],
              [-104.5074, 47.689],
              [-104.4652, 47.7039],
              [-104.5417, 47.718],
              [-104.5739, 47.7317],
              [-104.5738, 47.7609],
              [-104.5938, 47.761],
              [-104.5238, 47.8502],
            ],
          ],
          [
            [
              [-105.2348, 47.7905],
              [-105.2348, 47.7947],
              [-105.1951, 47.8876],
              [-105.195, 47.9113],
              [-105.1949, 47.9551],
              [-105.1949, 47.9696],
              [-105.1948, 47.9925],
              [-105.1948, 48.0666],
              [-105.0371, 48.0634],
              [-104.9323, 48.1048],
              [-104.9312, 48.1068],
              [-104.9308, 48.039],
              [-104.9307, 48.0196],
              [-104.964, 48.0111],
              [-104.9733, 48.0125],
              [-104.9923, 48.0082],
              [-105.0946, 47.9763],
              [-105.0946, 47.972],
              [-105.0944, 47.9631],
              [-105.0945, 47.9533],
              [-104.9783, 47.9348],
              [-104.9734, 47.9252],
              [-104.9586, 47.9253],
              [-105.0253, 47.9108],
              [-105.0391, 47.911],
              [-105.0652, 47.906],
              [-105.0653, 47.9021],
              [-105.0652, 47.8974],
              [-105.1053, 47.8674],
              [-105.1051, 47.8547],
              [-105.1025, 47.7036],
              [-105.235, 47.7036],
              [-105.2348, 47.7905],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '24230',
        GEOID: '3024230',
        NAME: 'Sidney High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1945231817,
        AWATER: 25086234,
        INTPTLAT: '+47.6393065',
        INTPTLON: '-104.1842737',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2633, 48.2071],
            [-114.2191, 48.2438],
            [-114.2191, 48.2692],
            [-114.1645, 48.2696],
            [-114.1591, 48.2111],
            [-114.1682, 48.1678],
            [-114.278, 48.1682],
            [-114.2633, 48.2071],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010230',
        NAME: 'Fair-Mont-Egan Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69435959,
        AWATER: 7015606,
        INTPTLAT: '+48.2072808',
        INTPTLON: '-114.2101778',
        ELSDLEA: '10230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2869, 48.2476],
            [-114.2191, 48.2438],
            [-114.2633, 48.2071],
            [-114.2869, 48.2476],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010920',
        NAME: 'Evergreen Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14376581,
        AWATER: 519694,
        INTPTLAT: '+48.2286233',
        INTPTLON: '-114.2669828',
        ELSDLEA: '10920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.639, 48.6589],
            [-114.4968, 48.6603],
            [-114.497, 48.573],
            [-114.5902, 48.5302],
            [-114.5893, 48.3998],
            [-114.4429, 48.3564],
            [-114.4427, 48.3528],
            [-114.4389, 48.3499],
            [-114.4089, 48.3499],
            [-114.3738, 48.3052],
            [-114.5891, 48.3],
            [-114.5906, 48.2276],
            [-114.7621, 48.2267],
            [-114.8481, 48.2261],
            [-114.8486, 48.3995],
            [-114.8498, 48.548],
            [-114.8886, 48.6588],
            [-114.639, 48.6589],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010950',
        NAME: 'Olney-Bissell Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1153526296,
        AWATER: 7979704,
        INTPTLAT: '+48.4410214',
        INTPTLON: '-114.7088593',
        ELSDLEA: '10950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2535, 48.2947],
            [-114.222, 48.2692],
            [-114.2191, 48.2692],
            [-114.2191, 48.2438],
            [-114.2869, 48.2476],
            [-114.2535, 48.2947],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013800',
        NAME: 'Helena Flats Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21288190,
        AWATER: 939422,
        INTPTLAT: '+48.2637196',
        INTPTLON: '-114.2521063',
        ELSDLEA: '13800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3951, 48.1531],
            [-114.3951, 48.204],
            [-114.3497, 48.2805],
            [-114.2866, 48.3057],
            [-114.2535, 48.2947],
            [-114.2869, 48.2476],
            [-114.2633, 48.2071],
            [-114.278, 48.1682],
            [-114.3221, 48.0489],
            [-114.3951, 48.1531],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015450',
        NAME: 'Kalispell Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160783768,
        AWATER: 2872748,
        INTPTLAT: '+48.1696083',
        INTPTLON: '-114.3308341',
        ELSDLEA: '15450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.5239, 48.1529],
            [-114.3951, 48.1531],
            [-114.3221, 48.0489],
            [-114.3218, 47.9622],
            [-114.4922, 47.962],
            [-114.4774, 47.8321],
            [-114.5201, 47.7899],
            [-114.6046, 47.8756],
            [-114.8187, 47.8758],
            [-114.8814, 47.8728],
            [-114.8817, 47.962],
            [-114.6204, 47.962],
            [-114.6204, 48.0777],
            [-114.5239, 48.1529],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015570',
        NAME: 'Kila Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 734839091,
        AWATER: 4737983,
        INTPTLAT: '+47.9784493',
        INTPTLON: '-114.5790292',
        ELSDLEA: '15570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0107, 48.0176],
            [-115.0106, 48.0466],
            [-114.7495, 48.0489],
            [-114.7621, 48.2267],
            [-114.5906, 48.2276],
            [-114.5242, 48.2259],
            [-114.5239, 48.1529],
            [-114.6204, 48.0777],
            [-114.6204, 47.962],
            [-114.8817, 47.962],
            [-114.8814, 47.8728],
            [-114.9893, 47.8728],
            [-115.0107, 48.0176],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017700',
        NAME: 'Marion Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 632996080,
        AWATER: 31525301,
        INTPTLAT: '+48.0567752',
        INTPTLON: '-114.7273172',
        ELSDLEA: '17700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0405, 48.1401],
            [-113.9448, 48.1395],
            [-113.9424, 48.0516],
            [-114.0514, 48.0614],
            [-114.0405, 48.1401],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025500',
        NAME: 'Swan River Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66013278,
        AWATER: 4177362,
        INTPTLAT: '+48.1016451',
        INTPTLON: '-113.9893844',
        ELSDLEA: '25500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3738, 48.3052],
            [-114.3497, 48.2805],
            [-114.3951, 48.204],
            [-114.5242, 48.2259],
            [-114.5906, 48.2276],
            [-114.5891, 48.3],
            [-114.3738, 48.3052],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027570',
        NAME: 'West Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179244532,
        AWATER: 1124219,
        INTPTLAT: '+48.2612930',
        INTPTLON: '-114.4701394',
        ELSDLEA: '27570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6192, 48.7426],
            [-114.6191, 48.7456],
            [-114.4318, 48.7455],
            [-114.4263, 48.5734],
            [-114.2518, 48.5733],
            [-114.2518, 48.3925],
            [-114.2866, 48.3057],
            [-114.3497, 48.2805],
            [-114.3738, 48.3052],
            [-114.4089, 48.3499],
            [-114.4389, 48.3499],
            [-114.4427, 48.3528],
            [-114.4429, 48.3564],
            [-114.5893, 48.3998],
            [-114.5902, 48.5302],
            [-114.497, 48.573],
            [-114.4968, 48.6603],
            [-114.639, 48.6589],
            [-114.6192, 48.7426],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027740',
        NAME: 'Whitefish Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 772652089,
        AWATER: 23207589,
        INTPTLAT: '+48.5231911',
        INTPTLON: '-114.4327661',
        ELSDLEA: '27740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6026, 48.6258],
            [-107.3376, 48.6542],
            [-107.3366, 48.9132],
            [-107.3147, 49.0001],
            [-107.1798, 49.0001],
            [-107.206, 48.9127],
            [-107.2015, 48.5095],
            [-107.2603, 48.3941],
            [-107.2605, 48.3048],
            [-107.3693, 48.3052],
            [-107.4047, 48.1157],
            [-107.5405, 48.1631],
            [-107.5406, 48.3978],
            [-107.6726, 48.3977],
            [-107.6728, 48.485],
            [-107.7467, 48.5703],
            [-107.6026, 48.6258],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023370',
        NAME: 'Saco Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1822162135,
        AWATER: 31981700,
        INTPTLAT: '+48.5291427',
        INTPTLON: '-107.4220668',
        ELSDLEA: '23370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2928, 45.9952],
            [-110.059, 45.9946],
            [-109.9967, 46.0295],
            [-109.7737, 46.0391],
            [-109.7735, 45.8877],
            [-109.6101, 45.8571],
            [-109.6288, 45.7837],
            [-109.7527, 45.8123],
            [-109.8764, 45.7895],
            [-109.9335, 45.7337],
            [-110.1681, 45.9286],
            [-110.2929, 45.9274],
            [-110.2928, 45.9952],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003800',
        NAME: 'Big Timber Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 972518948,
        AWATER: 6768272,
        INTPTLAT: '+45.9247206',
        INTPTLON: '-109.9486250',
        ELSDLEA: '03800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9335, 45.7337],
            [-109.8764, 45.7895],
            [-109.7527, 45.8123],
            [-109.6288, 45.7837],
            [-109.6046, 45.708],
            [-109.6427, 45.6756],
            [-109.6435, 45.609],
            [-109.734, 45.5658],
            [-109.8088, 45.5225],
            [-109.9311, 45.5225],
            [-109.9335, 45.7337],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013140',
        NAME: 'Greycliff Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604967502,
        AWATER: 3150940,
        INTPTLAT: '+45.6662922',
        INTPTLON: '-109.7763431',
        ELSDLEA: '13140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2929, 45.9274],
            [-110.1681, 45.9286],
            [-109.9335, 45.7337],
            [-109.9311, 45.5225],
            [-109.932, 45.3488],
            [-110.0646, 45.3488],
            [-110.0645, 45.1721],
            [-110.2281, 45.1721],
            [-110.2185, 45.6514],
            [-110.2185, 45.6522],
            [-110.2195, 45.6809],
            [-110.2209, 45.7111],
            [-110.2203, 45.7475],
            [-110.2927, 45.7851],
            [-110.2929, 45.9152],
            [-110.2929, 45.9274],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018060',
        NAME: 'McLeod Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1506727233,
        AWATER: 4650753,
        INTPTLAT: '+45.5391363',
        INTPTLON: '-110.0098375',
        ELSDLEA: '18060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2819, 46.1844],
            [-110.282, 46.2206],
            [-109.6539, 46.2194],
            [-109.6042, 46.1325],
            [-109.6052, 46.045],
            [-109.4172, 46.0448],
            [-109.423, 45.96],
            [-109.5063, 45.931],
            [-109.5488, 45.8707],
            [-109.6101, 45.8571],
            [-109.7735, 45.8877],
            [-109.7737, 46.0391],
            [-109.9967, 46.0295],
            [-110.059, 45.9946],
            [-110.2928, 45.9952],
            [-110.2819, 46.1844],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018290',
        NAME: 'Melville Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1565535387,
        AWATER: 1615111,
        INTPTLAT: '+46.0774826',
        INTPTLON: '-109.9017383',
        ELSDLEA: '18290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-110.2929, 45.9152],
              [-110.2927, 45.7851],
              [-110.3446, 45.7851],
              [-110.3905, 45.8565],
              [-110.2929, 45.9152],
            ],
          ],
          [
            [
              [-110.2927, 45.7851],
              [-110.2203, 45.7475],
              [-110.2209, 45.7111],
              [-110.2195, 45.6809],
              [-110.2185, 45.6522],
              [-110.2185, 45.6514],
              [-110.2885, 45.6976],
              [-110.2927, 45.7851],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024780',
        NAME: 'Springdale Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257703624,
        AWATER: 1318663,
        INTPTLAT: '+45.6946358',
        INTPTLON: '-110.2427745',
        ELSDLEA: '24780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0368, 46.2668],
            [-112.8892, 46.2659],
            [-112.8414, 46.1943],
            [-112.6577, 46.1947],
            [-112.5966, 46.1655],
            [-112.686, 46.1357],
            [-112.7771, 46.1358],
            [-112.7777, 46.0483],
            [-112.9228, 46.0048],
            [-113.0082, 46.0451],
            [-113.278, 46.0453],
            [-113.2845, 46.2228],
            [-113.0368, 46.2233],
            [-113.0368, 46.2668],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002010',
        NAME: 'Anaconda Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 909696000,
        AWATER: 10041194,
        INTPTLAT: '+46.1373088',
        INTPTLON: '-113.0017956',
        ELSDLEA: '02010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.5161, 45.9388],
            [-113.4282, 45.8222],
            [-113.2505, 45.8412],
            [-113.1489, 45.8836],
            [-113.151, 45.5198],
            [-113.3646, 45.5194],
            [-113.3647, 45.465],
            [-113.7654, 45.4768],
            [-113.8334, 45.5205],
            [-113.8064, 45.6016],
            [-113.9381, 45.6957],
            [-113.7125, 45.8384],
            [-113.5324, 45.8784],
            [-113.5161, 45.9388],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028500',
        NAME: 'Wisdom Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2210817006,
        AWATER: 4260020,
        INTPTLAT: '+45.6803258',
        INTPTLON: '-113.5007183',
        ELSDLEA: '28500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0367, 46.3597],
            [-112.8784, 46.485],
            [-112.795, 46.4839],
            [-112.7944, 46.5949],
            [-112.6904, 46.595],
            [-112.535, 46.483],
            [-112.4986, 46.3421],
            [-112.5884, 46.2249],
            [-112.5771, 46.179],
            [-112.5966, 46.1655],
            [-112.6577, 46.1947],
            [-112.8414, 46.1943],
            [-112.8892, 46.2659],
            [-113.0368, 46.2668],
            [-113.0367, 46.3597],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008670',
        NAME: 'Deer Lodge Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1166327155,
        AWATER: 1900803,
        INTPTLAT: '+46.3714046',
        INTPTLON: '-112.7521431',
        ELSDLEA: '08670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4575, 45.1739],
            [-112.2669, 45.1727],
            [-112.256, 45.3324],
            [-112.043, 45.348],
            [-112.0345, 45.1697],
            [-111.8904, 45.1682],
            [-111.8572, 45.003],
            [-111.9176, 45.0031],
            [-111.9117, 44.7821],
            [-112.0658, 44.7835],
            [-112.1671, 44.8267],
            [-112.1912, 45.0005],
            [-112.3342, 45.0005],
            [-112.3339, 45.0884],
            [-112.4569, 45.0885],
            [-112.4575, 45.1739],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017460',
        NAME: 'Alder Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1580573923,
        AWATER: 3954771,
        INTPTLAT: '+45.0664545',
        INTPTLON: '-112.1123593',
        ELSDLEA: '17460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0391, 45.5523],
            [-111.9729, 45.5412],
            [-111.9716, 45.348],
            [-112.043, 45.348],
            [-112.256, 45.3324],
            [-112.1948, 45.5512],
            [-112.0391, 45.5523],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024150',
        NAME: 'Sheridan Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504830834,
        AWATER: 277396,
        INTPTLAT: '+45.4541736',
        INTPTLON: '-112.0726996',
        ELSDLEA: '24150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.3235, 46.752],
            [-108.0086, 46.755],
            [-107.8277, 46.7559],
            [-107.8922, 46.8512],
            [-107.7243, 46.8513],
            [-107.3396, 46.8591],
            [-107.1109, 46.8592],
            [-107.0796, 46.8592],
            [-107.0604, 46.6299],
            [-107.1229, 46.6302],
            [-107.1536, 46.4681],
            [-107.1745, 46.4827],
            [-107.728, 46.4818],
            [-107.7385, 46.4818],
            [-107.7821, 46.3952],
            [-107.9285, 46.3953],
            [-108.0294, 46.2641],
            [-108.3208, 46.264],
            [-108.1955, 46.3121],
            [-108.1449, 46.3978],
            [-108.1546, 46.5862],
            [-108.3827, 46.5777],
            [-108.3831, 46.667],
            [-108.3235, 46.752],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018240',
        NAME: 'Melstone Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3846819380,
        AWATER: 3698708,
        INTPTLAT: '+46.6092294',
        INTPTLON: '-107.6858263',
        ELSDLEA: '18240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0096, 46.7507],
            [-108.6315, 46.7494],
            [-108.3235, 46.752],
            [-108.3831, 46.667],
            [-108.3827, 46.5777],
            [-108.1546, 46.5862],
            [-108.1449, 46.3978],
            [-108.1955, 46.3121],
            [-108.3208, 46.264],
            [-108.4049, 46.236],
            [-108.4048, 46.133],
            [-108.4778, 46.1328],
            [-108.4766, 46.2079],
            [-108.7793, 46.2104],
            [-108.7793, 46.2774],
            [-108.8623, 46.3646],
            [-108.9049, 46.5769],
            [-109.01, 46.6212],
            [-109.0096, 46.7507],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023040',
        NAME: 'Roundup Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3071545101,
        AWATER: 6430781,
        INTPTLAT: '+46.4949110',
        INTPTLON: '-108.5641643',
        ELSDLEA: '23040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.9391, 46.0048],
            [-106.8722, 46.0361],
            [-106.4958, 46.0508],
            [-106.4957, 45.9206],
            [-106.3462, 45.9207],
            [-106.3032, 45.8767],
            [-106.3272, 45.6863],
            [-106.7276, 45.6765],
            [-106.913, 45.6839],
            [-106.9391, 45.87],
            [-106.9391, 46.0048],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007050',
        NAME: 'Colstrip Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1691259031,
        AWATER: 864250,
        INTPTLAT: '+45.8450514',
        INTPTLON: '-106.6460698',
        ELSDLEA: '07050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0796, 46.8592],
            [-106.7198, 46.8452],
            [-106.7492, 46.831],
            [-106.7493, 46.4851],
            [-106.7891, 46.3507],
            [-106.5578, 46.351],
            [-106.4958, 46.0508],
            [-106.8722, 46.0361],
            [-106.9391, 46.0048],
            [-106.9391, 46.1367],
            [-107.0055, 46.1367],
            [-107.0284, 46.3955],
            [-107.1534, 46.3956],
            [-107.1536, 46.4681],
            [-107.1229, 46.6302],
            [-107.0604, 46.6299],
            [-107.0796, 46.8592],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011160',
        NAME: 'Forsyth Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2727032933,
        AWATER: 20162827,
        INTPTLAT: '+46.4862156',
        INTPTLON: '-106.8780844',
        ELSDLEA: '11160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.7296, 45.408],
            [-106.4676, 45.3914],
            [-106.3561, 45.494],
            [-106.2341, 45.4936],
            [-106.2357, 45.3518],
            [-106.2795, 45.3518],
            [-106.2797, 45.1797],
            [-106.768, 45.1861],
            [-106.7687, 45.3528],
            [-106.7296, 45.408],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003990',
        NAME: 'Birney Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1080098967,
        AWATER: 178270,
        INTPTLAT: '+45.3416453',
        INTPTLON: '-106.4482313',
        ELSDLEA: '03990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.7276, 45.6765],
            [-106.3272, 45.6863],
            [-106.6319, 45.4971],
            [-106.3561, 45.494],
            [-106.4676, 45.3914],
            [-106.7296, 45.408],
            [-106.7276, 45.6765],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016050',
        NAME: 'Lame Deer Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 664073560,
        AWATER: 40097,
        INTPTLAT: '+45.5515896',
        INTPTLON: '-106.5985246',
        ELSDLEA: '16050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0498, 47.5983],
            [-111.0295, 47.5822],
            [-110.8646, 47.5247],
            [-110.7685, 47.5256],
            [-110.7691, 47.4461],
            [-110.6396, 47.4165],
            [-110.7671, 47.3591],
            [-110.7707, 47.0965],
            [-110.6452, 47.0959],
            [-110.6529, 46.8236],
            [-110.856, 46.9213],
            [-110.913, 46.9806],
            [-111.0843, 47.0106],
            [-111.0807, 47.0879],
            [-111.0039, 47.0878],
            [-111.0041, 47.24],
            [-111.1065, 47.278],
            [-111.0703, 47.4012],
            [-111.1165, 47.4397],
            [-111.1368, 47.4399],
            [-111.1492, 47.4352],
            [-111.1516, 47.4352],
            [-111.152, 47.5514],
            [-111.0498, 47.5983],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003420',
        NAME: 'Belt Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1878018476,
        AWATER: 1872687,
        INTPTLAT: '+47.2268988',
        INTPTLON: '-110.8899232',
        ELSDLEA: '03420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4195, 47.6981],
            [-111.4195, 47.6027],
            [-111.5188, 47.5936],
            [-111.5263, 47.5174],
            [-111.5684, 47.5176],
            [-111.6663, 47.6262],
            [-111.6665, 47.6978],
            [-111.4195, 47.6981],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005850',
        NAME: 'Vaughn Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235358656,
        AWATER: 61782,
        INTPTLAT: '+47.6252829',
        INTPTLON: '-111.5507044',
        ELSDLEA: '05850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0457, 47.3516],
            [-111.889, 47.4212],
            [-111.6701, 47.4353],
            [-111.4814, 47.4141],
            [-111.4107, 47.4331],
            [-111.4044, 47.4335],
            [-111.3435, 47.3921],
            [-111.3897, 47.3096],
            [-111.4748, 47.2809],
            [-111.5342, 47.1772],
            [-111.5343, 47.0006],
            [-111.5392, 46.9134],
            [-111.6581, 46.9134],
            [-111.7902, 46.9138],
            [-111.7893, 47.1289],
            [-111.9201, 47.1357],
            [-112.0448, 47.1927],
            [-112.0457, 47.3516],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005880',
        NAME: 'Cascade Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1767897437,
        AWATER: 14348841,
        INTPTLAT: '+47.2108831',
        INTPTLON: '-111.7031680',
        ELSDLEA: '05880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3897, 47.3096],
            [-111.3743, 47.2132],
            [-111.2654, 47.0688],
            [-111.2866, 47.001],
            [-111.5343, 47.0006],
            [-111.5342, 47.1772],
            [-111.4748, 47.2809],
            [-111.3897, 47.3096],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008640',
        NAME: 'Deep Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482085272,
        AWATER: 171202,
        INTPTLAT: '+47.0610374',
        INTPTLON: '-111.4353565',
        ELSDLEA: '08640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4195, 47.6981],
            [-111.4089, 47.6981],
            [-111.2189, 47.6977],
            [-110.9764, 47.6986],
            [-111.0498, 47.5983],
            [-111.152, 47.5514],
            [-111.1516, 47.4352],
            [-111.2823, 47.4354],
            [-111.3435, 47.3921],
            [-111.4044, 47.4335],
            [-111.4107, 47.4331],
            [-111.4814, 47.4141],
            [-111.5263, 47.5174],
            [-111.5188, 47.5936],
            [-111.4195, 47.6027],
            [-111.4195, 47.6981],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013040',
        NAME: 'Great Falls Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 861038878,
        AWATER: 14946522,
        INTPTLAT: '+47.5716908',
        INTPTLON: '-111.3043957',
        ELSDLEA: '13040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0478, 47.516],
            [-111.9228, 47.5051],
            [-111.902, 47.6111],
            [-111.7312, 47.6111],
            [-111.6663, 47.6262],
            [-111.5684, 47.5176],
            [-111.6701, 47.4353],
            [-111.889, 47.4212],
            [-112.0457, 47.3516],
            [-112.0478, 47.516],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024300',
        NAME: 'Sun River Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 642581307,
        AWATER: 374787,
        INTPTLAT: '+47.5007312',
        INTPTLON: '-111.8375627',
        ELSDLEA: '24300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3897, 47.3096],
            [-111.3435, 47.3921],
            [-111.2823, 47.4354],
            [-111.1516, 47.4352],
            [-111.1492, 47.4352],
            [-111.1368, 47.4399],
            [-111.1165, 47.4397],
            [-111.0703, 47.4012],
            [-111.1065, 47.278],
            [-111.0041, 47.24],
            [-111.0039, 47.0878],
            [-111.0807, 47.0879],
            [-111.1584, 47.0118],
            [-111.2866, 47.001],
            [-111.2654, 47.0688],
            [-111.3743, 47.2132],
            [-111.3897, 47.3096],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025130',
        NAME: 'Centerville Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 943960238,
        AWATER: 843130,
        INTPTLAT: '+47.2372472',
        INTPTLON: '-111.2076210',
        ELSDLEA: '25130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5684, 47.5176],
            [-111.5263, 47.5174],
            [-111.4814, 47.4141],
            [-111.6701, 47.4353],
            [-111.5684, 47.5176],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026880',
        NAME: 'Ulm Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177421691,
        AWATER: 1712782,
        INTPTLAT: '+47.4554719',
        INTPTLON: '-111.5847845',
        ELSDLEA: '26880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8088, 45.5225],
            [-109.734, 45.5658],
            [-109.4437, 45.583],
            [-109.3926, 45.6065],
            [-109.3674, 45.595],
            [-109.3633, 45.5946],
            [-109.3564, 45.4643],
            [-109.3972, 45.4498],
            [-109.5231, 45.3118],
            [-109.5301, 45.1674],
            [-109.6911, 45.1673],
            [-109.6879, 45.2614],
            [-109.6085, 45.2614],
            [-109.5003, 45.4063],
            [-109.5187, 45.4645],
            [-109.712, 45.4325],
            [-109.8088, 45.5225],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3001710',
        NAME: 'Absarokee Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728205591,
        AWATER: 1103752,
        INTPTLAT: '+45.5023802',
        INTPTLON: '-109.4940285',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1268, 45.5877],
            [-109.0345, 45.5969],
            [-108.9241, 45.6052],
            [-108.7936, 45.6045],
            [-108.8018, 45.552],
            [-108.6989, 45.5083],
            [-108.9431, 45.4823],
            [-108.9479, 45.3723],
            [-109.0891, 45.3771],
            [-109.1917, 45.4884],
            [-109.1268, 45.5877],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015200',
        NAME: 'Joliet Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503723169,
        AWATER: 3647439,
        INTPTLAT: '+45.5220240',
        INTPTLON: '-108.9914559',
        ELSDLEA: '15200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.9241, 45.6052],
            [-108.843, 45.611],
            [-108.9047, 45.6828],
            [-108.9019, 45.7843],
            [-108.8626, 45.7266],
            [-108.7096, 45.7328],
            [-108.6795, 45.711],
            [-108.6549, 45.5675],
            [-108.5398, 45.5673],
            [-108.645, 45.4641],
            [-108.6453, 45.4641],
            [-108.6989, 45.5083],
            [-108.8018, 45.552],
            [-108.7936, 45.6045],
            [-108.9241, 45.6052],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016200',
        NAME: 'Laurel Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444281821,
        AWATER: 6882284,
        INTPTLAT: '+45.6254989',
        INTPTLON: '-108.7463250',
        ELSDLEA: '16200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3972, 45.4498],
            [-109.3564, 45.4643],
            [-109.2777, 45.4644],
            [-109.2887, 45.3619],
            [-109.2744, 45.2514],
            [-109.3166, 45.1649],
            [-109.5301, 45.1674],
            [-109.5231, 45.3118],
            [-109.3972, 45.4498],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017370',
        NAME: 'Luther Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497692337,
        AWATER: 0,
        INTPTLAT: '+45.2995350',
        INTPTLON: '-109.3923330',
        ELSDLEA: '17370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7987, 45.1673],
            [-109.6911, 45.1673],
            [-109.5301, 45.1674],
            [-109.3166, 45.1649],
            [-109.2744, 45.2514],
            [-109.2887, 45.3619],
            [-109.1403, 45.2697],
            [-109.1613, 45.1859],
            [-109.1947, 45.0057],
            [-109.7985, 45.0023],
            [-109.7987, 45.1673],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022080',
        NAME: 'Red Lodge Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1041748292,
        AWATER: 14315117,
        INTPTLAT: '+45.0870724',
        INTPTLON: '-109.5583961',
        ELSDLEA: '22080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.278, 46.0453],
            [-113.0082, 46.0451],
            [-112.9228, 46.0048],
            [-112.9232, 45.9348],
            [-113.0855, 45.8606],
            [-113.0002, 45.8077],
            [-112.8236, 45.7743],
            [-112.7358, 45.7012],
            [-113.0002, 45.7005],
            [-113.001, 45.5221],
            [-113.151, 45.5198],
            [-113.1489, 45.8836],
            [-113.2505, 45.8412],
            [-113.4282, 45.8222],
            [-113.5161, 45.9388],
            [-113.5174, 45.9401],
            [-113.278, 46.0453],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010820',
        NAME: 'Wise River Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1356550154,
        AWATER: 2380393,
        INTPTLAT: '+45.8325520',
        INTPTLON: '-113.1094769',
        ELSDLEA: '10820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0446, 48.2546],
            [-114.1645, 48.2696],
            [-114.2191, 48.2692],
            [-114.222, 48.2692],
            [-114.0802, 48.309],
            [-114.0446, 48.2546],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008700',
        NAME: 'Deer Park Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47705650,
        AWATER: 778698,
        INTPTLAT: '+48.2840664',
        INTPTLON: '-114.1470549',
        ELSDLEA: '08700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0201, 48.226],
            [-114.8481, 48.2261],
            [-114.7621, 48.2267],
            [-114.7495, 48.0489],
            [-115.0106, 48.0466],
            [-115.0201, 48.226],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020880',
        NAME: 'Pleasant Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381787721,
        AWATER: 1299806,
        INTPTLAT: '+48.1381830',
        INTPTLON: '-114.8577681',
        ELSDLEA: '20880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8152, 46.6162],
            [-113.8277, 46.6609],
            [-113.668, 46.6584],
            [-113.4799, 46.7882],
            [-113.457, 46.8027],
            [-113.3029, 46.8322],
            [-113.1156, 46.7134],
            [-113.0387, 46.7355],
            [-113.0385, 46.547],
            [-113.0574, 46.5471],
            [-113.0574, 46.6002],
            [-113.2788, 46.6154],
            [-113.4068, 46.6564],
            [-113.4665, 46.5715],
            [-113.6231, 46.5715],
            [-113.6231, 46.4951],
            [-113.8661, 46.4924],
            [-113.8152, 46.6162],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009180',
        NAME: 'Drummond Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1132606712,
        AWATER: 566184,
        INTPTLAT: '+46.6290413',
        INTPTLON: '-113.4928053',
        ELSDLEA: '09180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0574, 46.5471],
            [-113.2757, 46.5482],
            [-113.2752, 46.4921],
            [-113.4113, 46.4918],
            [-113.4665, 46.5715],
            [-113.4068, 46.6564],
            [-113.2788, 46.6154],
            [-113.0574, 46.6002],
            [-113.0574, 46.5471],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013200',
        NAME: 'Hall Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315424029,
        AWATER: 726740,
        INTPTLAT: '+46.5929946',
        INTPTLON: '-113.3331289',
        ELSDLEA: '13200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.43, 45.8459],
            [-111.4273, 45.8557],
            [-111.4102, 45.8596],
            [-111.4102, 45.8636],
            [-111.3897, 45.9179],
            [-111.3898, 45.9255],
            [-111.3898, 45.9331],
            [-111.3366, 45.9416],
            [-111.3228, 45.9468],
            [-111.2839, 45.9555],
            [-111.2839, 45.9619],
            [-111.1863, 45.8456],
            [-111.2586, 45.787],
            [-111.4498, 45.7873],
            [-111.43, 45.8459],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017610',
        NAME: 'Manhattan Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295764700,
        AWATER: 2651226,
        INTPTLAT: '+45.8927365',
        INTPTLON: '-111.2984565',
        ELSDLEA: '17610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2232, 45.6856],
            [-111.145, 45.7001],
            [-111.1451, 45.6486],
            [-111.2232, 45.6856],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018750',
        NAME: 'Monforton Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28652356,
        AWATER: 185404,
        INTPTLAT: '+45.6814047',
        INTPTLON: '-111.1746566',
        ELSDLEA: '18750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2856, 46.0114],
            [-111.2434, 46.0643],
            [-111.2437, 46.0796],
            [-111.2421, 46.1878],
            [-111.062, 46.1928],
            [-110.7838, 46.1927],
            [-110.7907, 46.0829],
            [-110.8919, 46.083],
            [-110.9759, 45.9885],
            [-110.9761, 45.9467],
            [-111.1515, 45.9908],
            [-111.2631, 46.0061],
            [-111.2648, 46.0061],
            [-111.2856, 46.0114],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020170',
        NAME: 'Pass Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 734774822,
        AWATER: 555224,
        INTPTLAT: '+46.0956487',
        INTPTLON: '-111.0251062',
        ELSDLEA: '20170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7843, 46.0498],
            [-111.6603, 46.0055],
            [-111.4647, 45.9767],
            [-111.4238, 46.062],
            [-111.3056, 46.1878],
            [-111.2421, 46.1878],
            [-111.2437, 46.0796],
            [-111.2434, 46.0643],
            [-111.2856, 46.0114],
            [-111.2648, 46.0061],
            [-111.2631, 46.0061],
            [-111.284, 45.9764],
            [-111.2839, 45.9619],
            [-111.2839, 45.9555],
            [-111.3228, 45.9468],
            [-111.3366, 45.9416],
            [-111.3898, 45.9331],
            [-111.3898, 45.9255],
            [-111.3897, 45.9179],
            [-111.4102, 45.8636],
            [-111.4102, 45.8596],
            [-111.4273, 45.8557],
            [-111.43, 45.8459],
            [-111.4498, 45.7873],
            [-111.4541, 45.6714],
            [-111.5292, 45.6717],
            [-111.5133, 45.8376],
            [-111.6201, 45.8371],
            [-111.6272, 45.8521],
            [-111.6304, 45.837],
            [-111.6332, 45.837],
            [-111.6398, 45.837],
            [-111.6601, 45.8335],
            [-111.66, 45.8778],
            [-111.7284, 45.8776],
            [-111.8136, 45.9247],
            [-111.8096, 45.8775],
            [-111.8442, 45.9692],
            [-111.7843, 46.0498],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026160',
        NAME: 'Three Forks Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 882039062,
        AWATER: 8032367,
        INTPTLAT: '+45.9463812',
        INTPTLON: '-111.5039408',
        ELSDLEA: '26160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8096, 45.8775],
            [-111.7284, 45.8776],
            [-111.66, 45.8778],
            [-111.6601, 45.8335],
            [-111.6398, 45.837],
            [-111.6332, 45.837],
            [-111.6304, 45.837],
            [-111.6272, 45.8521],
            [-111.6201, 45.8371],
            [-111.5133, 45.8376],
            [-111.5292, 45.6717],
            [-111.5368, 45.6568],
            [-111.6597, 45.7433],
            [-111.8054, 45.7867],
            [-111.8053, 45.7983],
            [-111.8096, 45.8775],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028140',
        NAME: 'Willow Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327459076,
        AWATER: 3043628,
        INTPTLAT: '+45.7902314',
        INTPTLON: '-111.6441910',
        ELSDLEA: '28140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9223, 47.0769],
            [-107.8597, 47.1063],
            [-107.8505, 47.2774],
            [-107.8098, 47.3201],
            [-107.6509, 47.305],
            [-107.5733, 47.3642],
            [-107.4907, 47.2741],
            [-107.3179, 47.3031],
            [-107.1703, 47.3105],
            [-107.0415, 47.2599],
            [-107.0895, 47.1739],
            [-107.0905, 47.0297],
            [-107.2143, 47.0146],
            [-107.214, 46.9475],
            [-107.3401, 46.903],
            [-107.3396, 46.8591],
            [-107.7243, 46.8513],
            [-107.8922, 46.8512],
            [-107.8847, 46.9842],
            [-107.9223, 47.0769],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012060',
        NAME: 'Sand Springs Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2786000972,
        AWATER: 2383703,
        INTPTLAT: '+47.0974858',
        INTPTLON: '-107.5658000',
        ELSDLEA: '12060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9091, 47.4507],
            [-107.8212, 47.5455],
            [-107.8074, 47.4336],
            [-107.6476, 47.4335],
            [-107.5733, 47.3642],
            [-107.6509, 47.305],
            [-107.8098, 47.3201],
            [-107.8505, 47.2774],
            [-107.8597, 47.1063],
            [-107.9223, 47.0769],
            [-107.9543, 47.1207],
            [-107.9566, 47.3609],
            [-107.9091, 47.4507],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011990',
        NAME: 'Ross Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550230480,
        AWATER: 16017195,
        INTPTLAT: '+47.3185054',
        INTPTLON: '-107.8519521',
        ELSDLEA: '11990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4149, 47.6917],
            [-107.249, 47.6616],
            [-106.9801, 47.6729],
            [-106.9689, 47.4899],
            [-106.9151, 47.4452],
            [-106.6638, 47.4467],
            [-106.6602, 47.1622],
            [-106.8275, 47.1262],
            [-106.8319, 47.0403],
            [-106.9579, 47.044],
            [-106.9591, 46.9842],
            [-107.1114, 46.955],
            [-107.1109, 46.8592],
            [-107.3396, 46.8591],
            [-107.3401, 46.903],
            [-107.214, 46.9475],
            [-107.2143, 47.0146],
            [-107.0905, 47.0297],
            [-107.0895, 47.1739],
            [-107.0415, 47.2599],
            [-107.1703, 47.3105],
            [-107.3179, 47.3031],
            [-107.3492, 47.421],
            [-107.4471, 47.6224],
            [-107.4149, 47.6917],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015340',
        NAME: 'Jordan Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2659841402,
        AWATER: 58965802,
        INTPTLAT: '+47.3262844',
        INTPTLON: '-107.0329347',
        ELSDLEA: '15340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.977, 45.8727],
            [-110.9155, 45.7871],
            [-111.0345, 45.795],
            [-110.977, 45.8727],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024810',
        NAME: 'Springhill Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77425081,
        AWATER: 2341,
        INTPTLAT: '+45.8261337',
        INTPTLON: '-111.0007091',
        ELSDLEA: '24810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7953, 45.7019],
            [-110.7954, 45.6283],
            [-110.8762, 45.6723],
            [-110.7953, 45.7019],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017520',
        NAME: 'Malmborg Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38983843,
        AWATER: 8696,
        INTPTLAT: '+45.6766773',
        INTPTLON: '-110.8291007',
        ELSDLEA: '17520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.8762, 45.6723],
            [-110.7954, 45.6283],
            [-110.7947, 45.6086],
            [-110.9192, 45.5249],
            [-110.9804, 45.5253],
            [-110.9803, 45.6565],
            [-110.8762, 45.6723],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016110',
        NAME: 'La Motte Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179276258,
        AWATER: 216199,
        INTPTLAT: '+45.6175099',
        INTPTLON: '-110.8933239',
        ELSDLEA: '16110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.9801, 47.6729],
            [-106.8617, 47.7374],
            [-106.571, 47.8129],
            [-106.4904, 47.9623],
            [-106.4186, 47.9575],
            [-106.3581, 47.8684],
            [-106.2609, 47.8684],
            [-106.2611, 47.531],
            [-106.4362, 47.5314],
            [-106.4355, 47.4467],
            [-106.6638, 47.4467],
            [-106.9151, 47.4452],
            [-106.9689, 47.4899],
            [-106.9801, 47.6729],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015510',
        NAME: 'Kester Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1718111378,
        AWATER: 332696344,
        INTPTLAT: '+47.6553848',
        INTPTLON: '-106.5847652',
        ELSDLEA: '15510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.8212, 47.5455],
            [-107.6947, 47.6298],
            [-107.5943, 47.6497],
            [-107.4471, 47.6224],
            [-107.3492, 47.421],
            [-107.3179, 47.3031],
            [-107.4907, 47.2741],
            [-107.5733, 47.3642],
            [-107.6476, 47.4335],
            [-107.8074, 47.4336],
            [-107.8212, 47.5455],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020690',
        NAME: 'Pine Grove Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 937919411,
        AWATER: 32690270,
        INTPTLAT: '+47.4714400',
        INTPTLON: '-107.5421719',
        ELSDLEA: '20690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.6638, 47.4467],
            [-106.4355, 47.4467],
            [-106.4362, 47.5314],
            [-106.2611, 47.531],
            [-106.2982, 47.3543],
            [-106.1707, 47.3545],
            [-106.1704, 47.1808],
            [-106.0868, 47.1809],
            [-106.0866, 46.8603],
            [-106.0865, 46.8459],
            [-106.7198, 46.8452],
            [-107.0796, 46.8592],
            [-107.1109, 46.8592],
            [-107.1114, 46.955],
            [-106.9591, 46.9842],
            [-106.9579, 47.044],
            [-106.8319, 47.0403],
            [-106.8275, 47.1262],
            [-106.6602, 47.1622],
            [-106.6638, 47.4467],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007030',
        NAME: 'Cohagen Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3460103958,
        AWATER: 3962869,
        INTPTLAT: '+47.0996161',
        INTPTLON: '-106.4905228',
        ELSDLEA: '07030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0828, 46.1319],
            [-108.7797, 46.1325],
            [-108.7793, 46.2104],
            [-108.4766, 46.2079],
            [-108.4778, 46.1328],
            [-108.5924, 46.0439],
            [-108.5929, 45.9581],
            [-108.6336, 45.9169],
            [-108.7241, 45.8276],
            [-108.7757, 45.8277],
            [-108.9019, 45.9019],
            [-108.9224, 45.9741],
            [-109.0827, 46.0472],
            [-109.0828, 46.1319],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004890',
        NAME: 'Broadview Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1020212275,
        AWATER: 1657598,
        INTPTLAT: '+46.0457221',
        INTPTLON: '-108.7578843',
        ELSDLEA: '04890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.2966, 45.8729],
            [-109.2369, 45.7972],
            [-109.0635, 45.727],
            [-109.0336, 45.6407],
            [-109.1268, 45.5877],
            [-109.1917, 45.4884],
            [-109.2777, 45.4644],
            [-109.3564, 45.4643],
            [-109.3633, 45.5946],
            [-109.3674, 45.595],
            [-109.3926, 45.6065],
            [-109.3381, 45.6482],
            [-109.3398, 45.6525],
            [-109.4966, 45.7355],
            [-109.335, 45.752],
            [-109.2966, 45.8729],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007190',
        NAME: 'Columbus Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801881903,
        AWATER: 8216821,
        INTPTLAT: '+45.6517827',
        INTPTLON: '-109.2175310',
        ELSDLEA: '07190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.712, 45.4325],
            [-109.5187, 45.4645],
            [-109.5003, 45.4063],
            [-109.6085, 45.2614],
            [-109.6879, 45.2614],
            [-109.6911, 45.1673],
            [-109.7987, 45.1673],
            [-109.8122, 45.1721],
            [-109.8128, 45.3547],
            [-109.712, 45.4325],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010800',
        NAME: 'Fishtail Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493941461,
        AWATER: 3144080,
        INTPTLAT: '+45.3024320',
        INTPTLON: '-109.7138553',
        ELSDLEA: '10800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.9224, 45.9741],
            [-108.9019, 45.9019],
            [-108.7757, 45.8277],
            [-108.9019, 45.8136],
            [-108.902, 45.8029],
            [-108.9851, 45.803],
            [-109.0635, 45.727],
            [-109.0689, 45.8847],
            [-109.0065, 45.96],
            [-108.9224, 45.9741],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018660',
        NAME: 'Molt Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315272990,
        AWATER: 575854,
        INTPTLAT: '+45.8842027',
        INTPTLON: '-108.9948321',
        ELSDLEA: '18660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9311, 45.5225],
            [-109.8088, 45.5225],
            [-109.712, 45.4325],
            [-109.8128, 45.3547],
            [-109.8122, 45.1721],
            [-110.0645, 45.1721],
            [-110.0646, 45.3488],
            [-109.932, 45.3488],
            [-109.9311, 45.5225],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3019530',
        NAME: 'Nye Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 633980386,
        AWATER: 3215586,
        INTPTLAT: '+45.2663362',
        INTPTLON: '-109.8900836',
        ELSDLEA: '19530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0336, 45.6407],
            [-109.0635, 45.727],
            [-108.9851, 45.803],
            [-108.902, 45.8029],
            [-108.9019, 45.7843],
            [-108.9047, 45.6828],
            [-108.843, 45.611],
            [-108.9241, 45.6052],
            [-109.0345, 45.5969],
            [-109.1268, 45.5877],
            [-109.0336, 45.6407],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020040',
        NAME: 'Park City Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275380508,
        AWATER: 2470037,
        INTPTLAT: '+45.6820076',
        INTPTLON: '-108.9509173',
        ELSDLEA: '20040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4172, 46.0448],
            [-109.4162, 46.1324],
            [-109.1601, 46.1319],
            [-109.0828, 46.1319],
            [-109.0827, 46.0472],
            [-108.9224, 45.9741],
            [-109.0065, 45.96],
            [-109.0689, 45.8847],
            [-109.0635, 45.727],
            [-109.2369, 45.7972],
            [-109.2966, 45.8729],
            [-109.465, 45.8873],
            [-109.5063, 45.931],
            [-109.423, 45.96],
            [-109.4172, 46.0448],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021870',
        NAME: 'Rapelje Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1028416790,
        AWATER: 3522514,
        INTPTLAT: '+45.9703505',
        INTPTLON: '-109.2238348',
        ELSDLEA: '21870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.734, 45.5658],
            [-109.6435, 45.609],
            [-109.6427, 45.6756],
            [-109.6046, 45.708],
            [-109.6288, 45.7837],
            [-109.6101, 45.8571],
            [-109.5488, 45.8707],
            [-109.5063, 45.931],
            [-109.465, 45.8873],
            [-109.2966, 45.8729],
            [-109.335, 45.752],
            [-109.4966, 45.7355],
            [-109.3398, 45.6525],
            [-109.3381, 45.6482],
            [-109.3926, 45.6065],
            [-109.4437, 45.583],
            [-109.734, 45.5658],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022230',
        NAME: 'Reedpoint Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696987129,
        AWATER: 3667357,
        INTPTLAT: '+45.7366104',
        INTPTLON: '-109.4935566',
        ELSDLEA: '22230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6396, 47.4165],
            [-110.5544, 47.4165],
            [-110.2538, 47.4165],
            [-110.2986, 47.3587],
            [-110.3192, 47.1845],
            [-110.474, 47.1697],
            [-110.4727, 47.0973],
            [-110.6452, 47.0959],
            [-110.7707, 47.0965],
            [-110.7671, 47.3591],
            [-110.6396, 47.4165],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012270',
        NAME: 'Geyser Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1086964161,
        AWATER: 295015,
        INTPTLAT: '+47.2647835',
        INTPTLON: '-110.5273491',
        ELSDLEA: '12270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.365, 48.9987],
            [-112.1934, 48.9985],
            [-112.1891, 48.6534],
            [-112.1848, 48.4776],
            [-112.5769, 48.4833],
            [-112.5765, 48.542],
            [-112.7106, 48.5413],
            [-112.7104, 48.8181],
            [-112.3661, 48.8167],
            [-112.365, 48.9987],
          ],
          [
            [-112.6581, 48.7111],
            [-112.5586, 48.6888],
            [-112.6052, 48.7553],
            [-112.6581, 48.7111],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000003',
        NAME: 'Cut Bank Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1579282531,
        AWATER: 12391427,
        INTPTLAT: '+48.6991466',
        INTPTLON: '-112.3929518',
        ELSDLEA: '00003',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.365, 48.9987],
            [-112.3661, 48.8167],
            [-112.7104, 48.8181],
            [-112.7106, 48.5413],
            [-112.5765, 48.542],
            [-112.5769, 48.4833],
            [-112.5772, 48.3138],
            [-112.5812, 48.3099],
            [-112.9674, 48.3099],
            [-112.966, 48.4763],
            [-113.4667, 48.4756],
            [-113.4674, 48.5476],
            [-113.7505, 48.6143],
            [-113.7456, 48.6526],
            [-113.7029, 48.7199],
            [-113.8184, 48.8482],
            [-113.9569, 48.8175],
            [-114.0311, 48.8799],
            [-114.0682, 48.9989],
            [-113.6948, 48.9975],
            [-112.365, 48.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005140',
        NAME: 'Browning Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5573915824,
        AWATER: 88036665,
        INTPTLAT: '+48.7344464',
        INTPTLON: '-113.1270379',
        ELSDLEA: '05140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.4667, 48.4756],
            [-112.966, 48.4763],
            [-112.9674, 48.3099],
            [-113.349, 48.3103],
            [-113.356, 48.4239],
            [-113.4667, 48.4756],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009510',
        NAME: 'East Glacier Park Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569154711,
        AWATER: 5698830,
        INTPTLAT: '+48.3996448',
        INTPTLON: '-113.1858175',
        ELSDLEA: '09510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6581, 48.7111],
            [-112.6052, 48.7553],
            [-112.5586, 48.6888],
            [-112.6581, 48.7111],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000004',
        NAME: 'Mountain View Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34369519,
        AWATER: 13289,
        INTPTLAT: '+48.7328884',
        INTPTLON: '-112.6197880',
        ELSDLEA: '00004',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6111, 48.9988],
            [-110.468, 48.999],
            [-110.3, 48.9236],
            [-110.2267, 48.8561],
            [-110.2277, 48.8252],
            [-110.2275, 48.8264],
            [-110.1386, 48.8117],
            [-110.0518, 48.6538],
            [-110.0412, 48.48],
            [-110.106, 48.3929],
            [-110.3672, 48.3644],
            [-110.3674, 48.3064],
            [-110.6253, 48.3065],
            [-110.6111, 48.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000096',
        NAME: 'North Star Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2530061151,
        AWATER: 12172665,
        INTPTLAT: '+48.6325649',
        INTPTLON: '-110.3810185',
        ELSDLEA: '00096',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.409, 48.2195],
            [-111.2753, 48.2195],
            [-111.2754, 48.3449],
            [-111.2734, 48.7042],
            [-111.2522, 48.7043],
            [-111.2734, 48.7107],
            [-111.2733, 48.876],
            [-111.2127, 48.8392],
            [-111.0664, 48.8814],
            [-111.0691, 48.9977],
            [-110.6111, 48.9988],
            [-110.6253, 48.3065],
            [-110.6255, 48.2197],
            [-110.827, 48.2197],
            [-110.8272, 48.1332],
            [-111.1437, 48.1329],
            [-111.4091, 48.1322],
            [-111.409, 48.2195],
          ],
          [
            [-111.2409, 48.697],
            [-111.1424, 48.6761],
            [-111.1768, 48.7512],
            [-111.2409, 48.697],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000098',
        NAME: 'Chester-Joplin-Inverness Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4250798516,
        AWATER: 45255190,
        INTPTLAT: '+48.5503795',
        INTPTLON: '-110.9500114',
        ELSDLEA: '00098',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0412, 48.48],
            [-109.9759, 48.4815],
            [-109.9087, 48.4816],
            [-109.9086, 48.4816],
            [-109.9193, 48.4657],
            [-109.8698, 48.306],
            [-109.8698, 48.3059],
            [-110.0191, 48.3061],
            [-110.3674, 48.3064],
            [-110.3672, 48.3644],
            [-110.106, 48.3929],
            [-110.0412, 48.48],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004440',
        NAME: 'Box Elder Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435627068,
        AWATER: 422815,
        INTPTLAT: '+48.3702485',
        INTPTLON: '-110.0823238',
        ELSDLEA: '04440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5053, 48.5679],
            [-109.4643, 48.4526],
            [-109.5513, 48.2935],
            [-109.5732, 48.5527],
            [-109.5053, 48.5679],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008310',
        NAME: 'Davey Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135482890,
        AWATER: 25350,
        INTPTLAT: '+48.4613979',
        INTPTLON: '-109.5207765',
        ELSDLEA: '08310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9193, 48.4657],
            [-109.9086, 48.4816],
            [-109.7897, 48.5683],
            [-109.8334, 48.6398],
            [-109.8267, 48.9998],
            [-109.4895, 49.0003],
            [-109.5055, 48.9139],
            [-109.5053, 48.5679],
            [-109.5732, 48.5527],
            [-109.5513, 48.2935],
            [-109.5343, 48.1346],
            [-109.7269, 48.1473],
            [-109.6177, 48.1483],
            [-109.6177, 48.2219],
            [-109.7139, 48.2199],
            [-109.7352, 48.314],
            [-109.8698, 48.306],
            [-109.9193, 48.4657],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013560',
        NAME: 'Havre Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1965265230,
        AWATER: 4047520,
        INTPTLAT: '+48.6227530',
        INTPTLON: '-109.6389470',
        ELSDLEA: '13560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.468, 48.999],
            [-109.8267, 48.9998],
            [-109.8334, 48.6398],
            [-109.7897, 48.5683],
            [-109.9086, 48.4816],
            [-109.9087, 48.4816],
            [-109.9759, 48.4815],
            [-110.0412, 48.48],
            [-110.0518, 48.6538],
            [-110.1386, 48.8117],
            [-110.2275, 48.8264],
            [-110.2277, 48.8252],
            [-110.2269, 48.8421],
            [-110.2267, 48.8561],
            [-110.3, 48.9236],
            [-110.468, 48.999],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014150',
        NAME: 'Cottonwood Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 1374379626,
        AWATER: 26038427,
        INTPTLAT: '+48.7917969',
        INTPTLON: '-110.0190087',
        ELSDLEA: '14150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.281, 46.4089],
            [-110.282, 46.2206],
            [-110.2819, 46.1844],
            [-110.5326, 46.192],
            [-110.5335, 46.3257],
            [-110.6654, 46.3249],
            [-110.7969, 46.4084],
            [-110.7983, 46.49],
            [-110.4342, 46.4819],
            [-110.4375, 46.4086],
            [-110.281, 46.4089],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016360',
        NAME: 'Lennep Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 860234111,
        AWATER: 587775,
        INTPTLAT: '+46.3650442',
        INTPTLON: '-110.5314258',
        ELSDLEA: '16360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6581, 46.9134],
            [-111.5392, 46.9134],
            [-111.5343, 47.0006],
            [-111.2866, 47.001],
            [-111.1584, 47.0118],
            [-111.0807, 47.0879],
            [-111.0843, 47.0106],
            [-110.913, 46.9806],
            [-110.856, 46.9213],
            [-110.6529, 46.8236],
            [-110.6207, 46.7722],
            [-110.4546, 46.6795],
            [-110.396, 46.6886],
            [-110.2733, 46.7105],
            [-110.281, 46.4089],
            [-110.4375, 46.4086],
            [-110.4342, 46.4819],
            [-110.7983, 46.49],
            [-110.7969, 46.4084],
            [-110.6654, 46.3249],
            [-110.5335, 46.3257],
            [-110.5326, 46.192],
            [-110.7838, 46.1927],
            [-111.062, 46.1928],
            [-111.1181, 46.317],
            [-111.057, 46.3981],
            [-111.1495, 46.4507],
            [-111.2465, 46.4381],
            [-111.3202, 46.5694],
            [-111.4981, 46.7625],
            [-111.6228, 46.8294],
            [-111.6166, 46.8983],
            [-111.6581, 46.9134],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028750',
        NAME: 'White Sulphur Springs Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5334623274,
        AWATER: 6816523,
        INTPTLAT: '+46.6390379',
        INTPTLON: '-110.9664729',
        ELSDLEA: '28750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2267, 48.8561],
            [-110.2269, 48.8421],
            [-110.2277, 48.8252],
            [-110.2267, 48.8561],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012350',
        NAME: 'Gildford Colony Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38902798,
        AWATER: 0,
        INTPTLAT: '+48.8287979',
        INTPTLON: '-110.2843566',
        ELSDLEA: '12350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0382, 45.3514],
            [-110.9179, 45.3513],
            [-110.9192, 45.5249],
            [-110.7947, 45.6086],
            [-110.5745, 45.5854],
            [-110.5824, 45.5122],
            [-110.4697, 45.4947],
            [-110.4442, 45.3401],
            [-110.4446, 45.1739],
            [-110.752, 45.1774],
            [-110.8041, 45.2778],
            [-111.0383, 45.2609],
            [-111.0382, 45.3514],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002300',
        NAME: 'Arrowhead Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1539178385,
        AWATER: 5130893,
        INTPTLAT: '+45.3844366',
        INTPTLON: '-110.6974767',
        ELSDLEA: '02300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2281, 45.1721],
            [-110.0645, 45.1721],
            [-109.8122, 45.1721],
            [-109.7987, 45.1673],
            [-109.7985, 45.0023],
            [-110.0015, 45.0034],
            [-110.2272, 45.0298],
            [-110.2281, 45.1721],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007350',
        NAME: 'Cooke City Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 565722147,
        AWATER: 7760287,
        INTPTLAT: '+45.1080344',
        INTPTLON: '-110.1005631',
        ELSDLEA: '07350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0383, 45.2609],
            [-110.8041, 45.2778],
            [-110.752, 45.1774],
            [-110.4446, 45.1739],
            [-110.2281, 45.1721],
            [-110.2272, 45.0298],
            [-110.0015, 45.0034],
            [-110.7045, 44.9922],
            [-111.0451, 45.0009],
            [-111.0383, 45.2609],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011820',
        NAME: 'Gardiner Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1534402864,
        AWATER: 4982058,
        INTPTLAT: '+45.1045153',
        INTPTLON: '-110.5189936',
        ELSDLEA: '11820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.7953, 45.7019],
            [-110.7891, 45.8023],
            [-110.5956, 45.8185],
            [-110.4886, 45.785],
            [-110.3446, 45.7851],
            [-110.2927, 45.7851],
            [-110.2885, 45.6976],
            [-110.2185, 45.6514],
            [-110.2281, 45.1721],
            [-110.4446, 45.1739],
            [-110.4442, 45.3401],
            [-110.4697, 45.4947],
            [-110.4702, 45.6052],
            [-110.5745, 45.5854],
            [-110.7947, 45.6086],
            [-110.7954, 45.6283],
            [-110.7953, 45.7019],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016880',
        NAME: 'Livingston Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1626339094,
        AWATER: 4218998,
        INTPTLAT: '+45.4993049',
        INTPTLON: '-110.4133296',
        ELSDLEA: '16880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.5745, 45.5854],
            [-110.4702, 45.6052],
            [-110.4697, 45.4947],
            [-110.5824, 45.5122],
            [-110.5745, 45.5854],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020670',
        NAME: 'Pine Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122305624,
        AWATER: 1099079,
        INTPTLAT: '+45.5610794',
        INTPTLON: '-110.5204224',
        ELSDLEA: '20670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.1949, 47.9551],
            [-105.1949, 47.9696],
            [-105.1948, 47.9925],
            [-105.0946, 47.9763],
            [-105.0946, 47.972],
            [-104.9923, 48.0082],
            [-104.9733, 48.0125],
            [-104.964, 48.0111],
            [-104.9307, 48.0196],
            [-104.9308, 48.039],
            [-104.9203, 48.039],
            [-104.9106, 48.0321],
            [-104.8925, 48.0134],
            [-104.8927, 47.9723],
            [-104.8823, 47.972],
            [-104.8492, 47.9447],
            [-104.8494, 47.9692],
            [-104.8115, 47.9695],
            [-104.6768, 47.9468],
            [-104.6307, 47.9546],
            [-104.5248, 47.8823],
            [-104.5828, 47.8821],
            [-104.6551, 47.8821],
            [-104.6554, 47.8676],
            [-104.6029, 47.8676],
            [-104.5461, 47.8676],
            [-104.5238, 47.8502],
            [-104.5938, 47.761],
            [-104.5738, 47.7609],
            [-104.5739, 47.7317],
            [-104.5417, 47.718],
            [-104.5074, 47.689],
            [-104.5106, 47.5729],
            [-104.513, 47.5294],
            [-104.594, 47.5293],
            [-104.5937, 47.6163],
            [-104.8722, 47.6161],
            [-104.9576, 47.6163],
            [-104.9781, 47.7033],
            [-104.9559, 47.7205],
            [-104.9786, 47.7203],
            [-104.9852, 47.7033],
            [-105.0212, 47.7034],
            [-104.9778, 47.7352],
            [-104.9778, 47.7955],
            [-105.0071, 47.8338],
            [-105.0253, 47.9108],
            [-105.0391, 47.911],
            [-105.0652, 47.8974],
            [-105.0653, 47.9021],
            [-105.0652, 47.906],
            [-105.0898, 47.9161],
            [-105.0898, 47.9185],
            [-105.0582, 47.9367],
            [-105.0581, 47.9406],
            [-105.0945, 47.9533],
            [-105.0944, 47.9631],
            [-105.1099, 47.9184],
            [-105.1121, 47.902],
            [-105.1326, 47.8877],
            [-105.1548, 47.8877],
            [-105.1672, 47.9004],
            [-105.1553, 47.911],
            [-105.176, 47.9111],
            [-105.195, 47.9113],
            [-105.1949, 47.9551],
          ],
          [
            [-104.9734, 47.9252],
            [-104.9585, 47.9284],
            [-104.9581, 47.9428],
            [-104.9783, 47.9348],
            [-104.9734, 47.9252],
          ],
          [
            [-104.6551, 47.8821],
            [-104.6763, 47.9111],
            [-104.6764, 47.8974],
            [-104.6764, 47.8821],
            [-104.6551, 47.8821],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015990',
        NAME: 'Lambert Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1439965731,
        AWATER: 5537168,
        INTPTLAT: '+47.7987371',
        INTPTLON: '-104.7629032',
        ELSDLEA: '15990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.4203, 47.3544],
            [-104.2805, 47.465],
            [-104.2704, 47.553],
            [-104.0432, 47.7611],
            [-104.0439, 47.5904],
            [-104.0453, 47.3971],
            [-104.1319, 47.3539],
            [-104.4203, 47.3544],
            [-104.4203, 47.3544],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021960',
        NAME: 'Rau Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 613910102,
        AWATER: 12661351,
        INTPTLAT: '+47.5081141',
        INTPTLON: '-104.1552986',
        ELSDLEA: '21960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-111.2409, 48.697],
              [-111.1768, 48.7512],
              [-111.1424, 48.6761],
              [-111.2409, 48.697],
            ],
          ],
          [
            [
              [-111.2734, 48.7107],
              [-111.2522, 48.7043],
              [-111.2734, 48.7042],
              [-111.2734, 48.7107],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000093',
        NAME: 'Liberty Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47542772,
        AWATER: 104967,
        INTPTLAT: '+48.7135879',
        INTPTLON: '-111.1873112',
        ELSDLEA: '00093',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2733, 48.8815],
            [-111.2699, 48.9972],
            [-111.0691, 48.9977],
            [-111.0664, 48.8814],
            [-111.2127, 48.8392],
            [-111.2733, 48.876],
            [-111.2733, 48.8815],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027960',
        NAME: 'Whitlash Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223317044,
        AWATER: 478024,
        INTPTLAT: '+48.9354430',
        INTPTLON: '-111.1748060',
        ELSDLEA: '27960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5794, 47.4204],
            [-109.3993, 47.4044],
            [-109.4038, 47.3043],
            [-109.2073, 47.3144],
            [-109.0958, 47.2432],
            [-109.1124, 47.1143],
            [-109.1708, 47.0226],
            [-109.1681, 47.0226],
            [-109.194, 46.928],
            [-109.1807, 46.7964],
            [-109.3159, 46.7513],
            [-109.3892, 46.7514],
            [-109.4952, 46.791],
            [-109.5051, 46.9261],
            [-109.4816, 47.0843],
            [-109.5887, 47.1047],
            [-109.6194, 47.1811],
            [-109.6408, 47.2889],
            [-109.7231, 47.3233],
            [-109.6987, 47.3923],
            [-109.5794, 47.4204],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016490',
        NAME: 'Lewistown Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1926131184,
        AWATER: 1124114,
        INTPTLAT: '+47.1399709',
        INTPTLON: '-109.3107385',
        ELSDLEA: '16490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1891, 48.6534],
            [-111.8404, 48.6532],
            [-111.7133, 48.5954],
            [-111.7095, 48.4361],
            [-111.558, 48.393],
            [-111.5356, 48.3387],
            [-111.2754, 48.3449],
            [-111.2753, 48.2195],
            [-111.409, 48.2195],
            [-111.6656, 48.2195],
            [-111.6658, 48.3495],
            [-111.7959, 48.3931],
            [-111.9909, 48.3943],
            [-112.0044, 48.4489],
            [-112.1423, 48.468],
            [-112.1848, 48.4776],
            [-112.1891, 48.6534],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023900',
        NAME: 'Shelby Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1341168143,
        AWATER: 30554300,
        INTPTLAT: '+48.4257641',
        INTPTLON: '-111.7730014',
        ELSDLEA: '23900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8404, 48.6532],
            [-111.5788, 48.6679],
            [-111.4587, 48.7475],
            [-111.4697, 48.8561],
            [-111.2733, 48.8815],
            [-111.2733, 48.876],
            [-111.2734, 48.7107],
            [-111.2734, 48.7042],
            [-111.2754, 48.3449],
            [-111.5356, 48.3387],
            [-111.558, 48.393],
            [-111.7095, 48.4361],
            [-111.7133, 48.5954],
            [-111.8404, 48.6532],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011730',
        NAME: 'Galata Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1457396849,
        AWATER: 14609570,
        INTPTLAT: '+48.5737634',
        INTPTLON: '-111.4919321',
        ELSDLEA: '11730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5238, 47.8502],
            [-104.2503, 47.8436],
            [-104.1651, 47.7904],
            [-104.2102, 47.7326],
            [-104.4652, 47.7039],
            [-104.5074, 47.689],
            [-104.5417, 47.718],
            [-104.5739, 47.7317],
            [-104.5738, 47.7609],
            [-104.5938, 47.761],
            [-104.5238, 47.8502],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005130',
        NAME: 'Brorson Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392570796,
        AWATER: 362066,
        INTPTLAT: '+47.7820341',
        INTPTLON: '-104.3816814',
        ELSDLEA: '05130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-104.9783, 47.9348],
              [-104.9581, 47.9428],
              [-104.9585, 47.9284],
              [-104.9734, 47.9252],
              [-104.9783, 47.9348],
            ],
          ],
          [
            [
              [-105.0898, 47.9161],
              [-105.0652, 47.906],
              [-105.0653, 47.9021],
              [-105.0898, 47.9161],
            ],
          ],
          [
            [
              [-105.0944, 47.9631],
              [-105.0945, 47.9533],
              [-105.0581, 47.9406],
              [-105.0582, 47.9367],
              [-105.0898, 47.9185],
              [-105.1099, 47.9184],
              [-105.0944, 47.9631],
            ],
          ],
          [
            [
              [-105.1053, 47.8674],
              [-105.1051, 47.8547],
              [-105.1488, 47.8501],
              [-105.1053, 47.8674],
            ],
          ],
          [
            [
              [-105.1672, 47.9004],
              [-105.1548, 47.8877],
              [-105.1739, 47.8876],
              [-105.1672, 47.9004],
            ],
          ],
          [
            [
              [-105.1948, 48.0666],
              [-105.0371, 48.0634],
              [-104.9323, 48.1048],
              [-104.9312, 48.1068],
              [-104.6816, 48.1033],
              [-104.6109, 48.1414],
              [-104.7579, 48.2163],
              [-104.7575, 48.2599],
              [-104.6062, 48.3026],
              [-104.5846, 48.2447],
              [-104.4546, 48.2447],
              [-104.3896, 48.3026],
              [-104.2706, 48.3023],
              [-104.3248, 48.259],
              [-104.3226, 48.0452],
              [-104.5315, 48.1246],
              [-104.5322, 48.0326],
              [-104.6543, 48.0336],
              [-104.6307, 47.9546],
              [-104.6768, 47.9468],
              [-104.8115, 47.9695],
              [-104.8494, 47.9692],
              [-104.8492, 47.9447],
              [-104.8823, 47.972],
              [-104.8927, 47.9723],
              [-104.8925, 48.0134],
              [-104.9106, 48.0321],
              [-104.9203, 48.039],
              [-104.9308, 48.039],
              [-104.9307, 48.0196],
              [-104.964, 48.0111],
              [-104.9733, 48.0125],
              [-104.9923, 48.0082],
              [-105.0946, 47.972],
              [-105.0946, 47.9763],
              [-105.1948, 47.9925],
              [-105.1948, 48.0666],
            ],
          ],
          [
            [
              [-105.1951, 47.8876],
              [-105.1739, 47.8876],
              [-105.1488, 47.8501],
              [-105.2348, 47.7947],
              [-105.1951, 47.8876],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007830',
        NAME: 'Culbertson Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1269572989,
        AWATER: 15050034,
        INTPTLAT: '+48.1024953',
        INTPTLON: '-104.7457172',
        ELSDLEA: '07830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-104.6551, 47.8821],
              [-104.5828, 47.8821],
              [-104.5248, 47.8823],
              [-104.6307, 47.9546],
              [-104.6543, 48.0336],
              [-104.5322, 48.0326],
              [-104.5315, 48.1246],
              [-104.3226, 48.0452],
              [-104.1672, 48.0355],
              [-104.0441, 47.9961],
              [-104.0425, 47.807],
              [-104.1651, 47.7904],
              [-104.2503, 47.8436],
              [-104.5238, 47.8502],
              [-104.5461, 47.8676],
              [-104.6029, 47.8676],
              [-104.6554, 47.8676],
              [-104.6551, 47.8821],
            ],
          ],
          [
            [
              [-104.6764, 47.8974],
              [-104.6763, 47.9111],
              [-104.6551, 47.8821],
              [-104.6764, 47.8821],
              [-104.6764, 47.8974],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010210',
        NAME: 'Fairview Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1047244753,
        AWATER: 9931551,
        INTPTLAT: '+47.9453956',
        INTPTLON: '-104.3347686',
        ELSDLEA: '10210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.6487, 47.1432],
            [-109.6382, 47.1811],
            [-109.6194, 47.1811],
            [-109.5887, 47.1047],
            [-109.6402, 47.117],
            [-109.6487, 47.1432],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024690',
        NAME: 'Spring Creek Colony Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50990745,
        AWATER: 0,
        INTPTLAT: '+47.1601425',
        INTPTLON: '-109.6019691',
        ELSDLEA: '24690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0487, 48.2161],
            [-116.0092, 48.1597],
            [-115.8002, 48.2228],
            [-115.694, 48.2258],
            [-115.6087, 48.0583],
            [-115.707, 48.0267],
            [-115.743, 47.9376],
            [-115.8905, 47.9309],
            [-116.0207, 47.9636],
            [-116.0491, 47.9773],
            [-116.0487, 48.2161],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000090',
        NAME: 'Noxon Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 792937902,
        AWATER: 14165960,
        INTPTLAT: '+48.0832104',
        INTPTLON: '-115.8452418',
        ELSDLEA: '00090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.365, 47.5181],
            [-114.2828, 47.4714],
            [-114.3531, 47.3898],
            [-114.203, 47.3557],
            [-114.2031, 47.2562],
            [-114.1872, 47.1382],
            [-114.2536, 47.121],
            [-114.4825, 47.22],
            [-114.4802, 47.5191],
            [-114.365, 47.5181],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009030',
        NAME: 'Dixon Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 655516710,
        AWATER: 6230012,
        INTPTLAT: '+47.3178245',
        INTPTLON: '-114.3600673',
        ELSDLEA: '09030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.696, 47.4096],
            [-114.6872, 47.2743],
            [-114.7972, 47.2689],
            [-114.8425, 47.2979],
            [-114.8431, 47.4103],
            [-114.696, 47.4096],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3019980',
        NAME: 'Paradise Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179399575,
        AWATER: 3448771,
        INTPTLAT: '+47.3128777',
        INTPTLON: '-114.7472448',
        ELSDLEA: '19980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.9893, 47.8728],
            [-114.8814, 47.8728],
            [-114.8187, 47.8758],
            [-114.8387, 47.7924],
            [-114.7618, 47.7644],
            [-114.7744, 47.535],
            [-114.696, 47.4096],
            [-114.8431, 47.4103],
            [-114.8425, 47.2979],
            [-114.969, 47.3126],
            [-114.9823, 47.3968],
            [-115.1281, 47.423],
            [-115.1298, 47.5483],
            [-115.0229, 47.5381],
            [-114.9904, 47.6962],
            [-114.9893, 47.8728],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020820',
        NAME: 'Plains Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1171761911,
        AWATER: 6765337,
        INTPTLAT: '+47.5497125',
        INTPTLON: '-114.9191332',
        ELSDLEA: '20820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.7497, 47.7144],
            [-115.749, 47.7753],
            [-115.5003, 47.7889],
            [-115.4739, 47.915],
            [-115.1852, 47.919],
            [-115.1545, 48.0177],
            [-115.0107, 48.0176],
            [-114.9893, 47.8728],
            [-114.9904, 47.6962],
            [-115.0229, 47.5381],
            [-115.1298, 47.5483],
            [-115.1281, 47.423],
            [-115.2839, 47.4866],
            [-115.6295, 47.4796],
            [-115.6986, 47.5023],
            [-115.6916, 47.5934],
            [-115.7497, 47.7144],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026070',
        NAME: 'Thompson Falls Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2406742433,
        AWATER: 11832882,
        INTPTLAT: '+47.6677221',
        INTPTLON: '-115.2957506',
        ELSDLEA: '26070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0207, 47.9636],
            [-115.8905, 47.9309],
            [-115.743, 47.9376],
            [-115.707, 48.0267],
            [-115.6087, 48.0583],
            [-115.4739, 47.915],
            [-115.5003, 47.7889],
            [-115.749, 47.7753],
            [-115.7497, 47.7144],
            [-115.8238, 47.7526],
            [-116.0207, 47.9636],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026520',
        NAME: 'Trout Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 741627172,
        AWATER: 25442881,
        INTPTLAT: '+47.8770069',
        INTPTLON: '-115.6736688',
        ELSDLEA: '26520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1102, 47.0364],
            [-108.9296, 47.1014],
            [-108.9065, 47.0452],
            [-109.1102, 47.0364],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002570',
        NAME: 'Ayers Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114668464,
        AWATER: 50489,
        INTPTLAT: '+47.0909784',
        INTPTLON: '-108.9646586',
        ELSDLEA: '02570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.6402, 47.117],
            [-109.5887, 47.1047],
            [-109.4816, 47.0843],
            [-109.5051, 46.9261],
            [-109.6402, 47.117],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015610',
        NAME: 'King Colony Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193845225,
        AWATER: 0,
        INTPTLAT: '+47.0179453',
        INTPTLON: '-109.5597802',
        ELSDLEA: '15610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1681, 47.0226],
            [-109.1708, 47.0226],
            [-109.1124, 47.1143],
            [-109.0958, 47.2432],
            [-109.0079, 47.192],
            [-108.7361, 47.1834],
            [-108.7368, 47.0982],
            [-108.609, 47.0981],
            [-108.6111, 46.8368],
            [-108.6315, 46.7494],
            [-109.0096, 46.7507],
            [-109.1963, 46.7513],
            [-109.3159, 46.7513],
            [-109.1807, 46.7964],
            [-109.194, 46.928],
            [-109.1681, 47.0226],
          ],
          [
            [-109.1102, 47.0364],
            [-108.9065, 47.0452],
            [-108.9296, 47.1014],
            [-109.1102, 47.0364],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012960',
        NAME: 'Grass Range Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1949701624,
        AWATER: 966443,
        INTPTLAT: '+46.9298732',
        INTPTLON: '-108.9365621',
        ELSDLEA: '12960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2136, 47.4167],
            [-110.1584, 47.4177],
            [-109.8452, 47.5468],
            [-109.8297, 47.7153],
            [-109.6775, 47.7347],
            [-109.6562, 47.4966],
            [-109.5794, 47.4204],
            [-109.6987, 47.3923],
            [-109.7231, 47.3233],
            [-109.7081, 47.228],
            [-109.6382, 47.1811],
            [-109.6487, 47.1432],
            [-109.7566, 47.1857],
            [-109.838, 47.1854],
            [-109.8725, 47.2289],
            [-110, 47.2287],
            [-110.1277, 47.2719],
            [-110.128, 47.359],
            [-110.2136, 47.4167],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008860',
        NAME: 'Denton Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1423815007,
        AWATER: 1494818,
        INTPTLAT: '+47.4088256',
        INTPTLON: '-109.8421105',
        ELSDLEA: '08860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7566, 47.1857],
            [-109.6487, 47.1432],
            [-109.6402, 47.117],
            [-109.5051, 46.9261],
            [-109.4952, 46.791],
            [-109.3892, 46.7514],
            [-109.3892, 46.6936],
            [-109.5825, 46.6935],
            [-109.5589, 46.7639],
            [-109.6585, 46.7909],
            [-109.7876, 46.7803],
            [-109.7554, 46.896],
            [-109.7566, 47.1857],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018870',
        NAME: 'Moore Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 806829692,
        AWATER: 246360,
        INTPTLAT: '+46.8380488',
        INTPTLON: '-109.6356810',
        ELSDLEA: '18870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.965, 46.7499],
            [-109.8578, 46.7083],
            [-109.7876, 46.7803],
            [-109.6585, 46.7909],
            [-109.5589, 46.7639],
            [-109.5825, 46.6935],
            [-109.3892, 46.6936],
            [-109.3887, 46.5468],
            [-109.6917, 46.5616],
            [-109.9653, 46.6206],
            [-109.965, 46.7499],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015360',
        NAME: 'Judith Gap Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761716595,
        AWATER: 50578,
        INTPTLAT: '+46.6488235',
        INTPTLON: '-109.6814117',
        ELSDLEA: '15360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.7241, 45.8276],
            [-108.6336, 45.9169],
            [-108.5514, 45.8431],
            [-108.4321, 45.8467],
            [-108.4215, 45.8423],
            [-108.4779, 45.7624],
            [-108.5729, 45.7236],
            [-108.6278, 45.7551],
            [-108.7241, 45.8276],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003870',
        NAME: 'Billings Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240456146,
        AWATER: 2586233,
        INTPTLAT: '+45.8135192',
        INTPTLON: '-108.5944595',
        ELSDLEA: '03870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5982, 45.7093],
            [-108.5729, 45.7236],
            [-108.4779, 45.7624],
            [-108.4486, 45.6549],
            [-108.5297, 45.5931],
            [-108.5982, 45.7093],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004230',
        NAME: 'Blue Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130577339,
        AWATER: 1062581,
        INTPTLAT: '+45.6798705',
        INTPTLON: '-108.5323929',
        ELSDLEA: '04230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6795, 45.711],
            [-108.6282, 45.7357],
            [-108.5982, 45.7093],
            [-108.5297, 45.5931],
            [-108.5398, 45.5673],
            [-108.6549, 45.5675],
            [-108.6795, 45.711],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005460',
        NAME: 'Canyon Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132444248,
        AWATER: 949475,
        INTPTLAT: '+45.6423812',
        INTPTLON: '-108.5995949',
        ELSDLEA: '05460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.902, 45.8029],
            [-108.9019, 45.8136],
            [-108.7757, 45.8277],
            [-108.7241, 45.8276],
            [-108.6278, 45.7551],
            [-108.6282, 45.7357],
            [-108.6795, 45.711],
            [-108.7096, 45.7328],
            [-108.8626, 45.7266],
            [-108.9019, 45.7843],
            [-108.902, 45.8029],
          ],
          [
            [-108.7096, 45.7328],
            [-108.6995, 45.7328],
            [-108.6996, 45.7406],
            [-108.7097, 45.7407],
            [-108.7096, 45.7328],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009720',
        NAME: 'Elder Grove Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162141641,
        AWATER: 11649,
        INTPTLAT: '+45.8019945',
        INTPTLON: '-108.7801313',
        ELSDLEA: '09720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6278, 45.7551],
            [-108.5729, 45.7236],
            [-108.5982, 45.7093],
            [-108.6282, 45.7357],
            [-108.6278, 45.7551],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009840',
        NAME: 'Elysian Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22155848,
        AWATER: 938742,
        INTPTLAT: '+45.7371123',
        INTPTLON: '-108.5812536',
        ELSDLEA: '09840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5929, 45.9581],
            [-108.4681, 45.9584],
            [-108.4259, 45.8993],
            [-108.4321, 45.8467],
            [-108.5514, 45.8431],
            [-108.6336, 45.9169],
            [-108.5929, 45.9581],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014820',
        NAME: 'Independent Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171052389,
        AWATER: 475907,
        INTPTLAT: '+45.9183820',
        INTPTLON: '-108.5460917',
        ELSDLEA: '14820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.4779, 45.7624],
            [-108.4215, 45.8423],
            [-108.3631, 45.8686],
            [-108.3431, 45.7842],
            [-108.4486, 45.6549],
            [-108.4779, 45.7624],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016950',
        NAME: 'Lockwood Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120023030,
        AWATER: 1630070,
        INTPTLAT: '+45.7721361',
        INTPTLON: '-108.4229955',
        ELSDLEA: '16950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5398, 45.5673],
            [-108.5297, 45.5931],
            [-108.4486, 45.6549],
            [-108.3431, 45.7842],
            [-108.2425, 45.7836],
            [-108.2426, 45.6812],
            [-108.0697, 45.6813],
            [-108.0706, 45.5185],
            [-108.3164, 45.4605],
            [-108.645, 45.4641],
            [-108.5398, 45.5673],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018960',
        NAME: 'Morin Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 929578512,
        AWATER: 824081,
        INTPTLAT: '+45.6040041',
        INTPTLON: '-108.3353789',
        ELSDLEA: '18960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.4259, 45.8993],
            [-108.3148, 45.8956],
            [-108.3631, 45.8686],
            [-108.4215, 45.8423],
            [-108.4321, 45.8467],
            [-108.4259, 45.8993],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020790',
        NAME: 'Pioneer Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31919324,
        AWATER: 1222952,
        INTPTLAT: '+45.8754610',
        INTPTLON: '-108.3908706',
        ELSDLEA: '20790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.4778, 46.1328],
            [-108.4048, 46.133],
            [-108.3032, 46.1327],
            [-108.3023, 46.0468],
            [-108.1729, 45.9987],
            [-108.2894, 45.9521],
            [-108.3148, 45.8956],
            [-108.4259, 45.8993],
            [-108.4681, 45.9584],
            [-108.5929, 45.9581],
            [-108.5924, 46.0439],
            [-108.4778, 46.1328],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023940',
        NAME: 'Shepherd Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611942030,
        AWATER: 1483889,
        INTPTLAT: '+46.0221619',
        INTPTLON: '-108.4124906',
        ELSDLEA: '23940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.7096, 45.7328],
            [-108.7097, 45.7407],
            [-108.6996, 45.7406],
            [-108.6995, 45.7328],
            [-108.7096, 45.7328],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028860',
        NAME: 'Yellowstone Academy Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 683008,
        AWATER: 0,
        INTPTLAT: '+45.7367467',
        INTPTLON: '-108.7045810',
        ELSDLEA: '28860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7231, 47.3233],
            [-109.6408, 47.2889],
            [-109.6194, 47.1811],
            [-109.6382, 47.1811],
            [-109.7081, 47.228],
            [-109.7231, 47.3233],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008730',
        NAME: 'Deerfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69371085,
        AWATER: 15193,
        INTPTLAT: '+47.2591716',
        INTPTLON: '-109.6948018',
        ELSDLEA: '08730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.8234, 48.9991],
            [-108.6695, 48.9991],
            [-108.742, 48.9415],
            [-108.7173, 48.8729],
            [-108.7607, 48.7419],
            [-108.5865, 48.7419],
            [-108.4674, 48.6985],
            [-108.4673, 48.7278],
            [-108.3191, 48.7422],
            [-108.319, 48.5684],
            [-108.3463, 48.4228],
            [-108.4112, 48.445],
            [-108.4112, 48.2231],
            [-108.8878, 48.2228],
            [-108.8888, 48.4345],
            [-108.8881, 48.5363],
            [-108.9604, 48.5582],
            [-108.96, 48.7012],
            [-108.843, 48.7009],
            [-108.8443, 48.9145],
            [-108.8234, 48.9991],
          ],
          [
            [-108.7727, 48.5884],
            [-108.7109, 48.5914],
            [-108.711, 48.6313],
            [-108.7669, 48.6482],
            [-108.7727, 48.5884],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013395',
        NAME: 'Harlem Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2456699817,
        AWATER: 4019433,
        INTPTLAT: '+48.5268602',
        INTPTLON: '-108.6496852',
        ELSDLEA: '13395',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5388, 47.7662],
            [-109.4524, 47.7665],
            [-109.4539, 47.8796],
            [-109.4051, 47.9224],
            [-109.2052, 47.9349],
            [-109.2082, 48.0797],
            [-109.0788, 48.0782],
            [-109.0787, 48.2218],
            [-109.2065, 48.2361],
            [-109.2061, 48.3497],
            [-109.3258, 48.3949],
            [-109.0638, 48.4349],
            [-108.8888, 48.4345],
            [-108.8878, 48.2228],
            [-108.8852, 47.9227],
            [-108.8911, 47.7345],
            [-108.9427, 47.7918],
            [-109.2774, 47.7924],
            [-109.4115, 47.7246],
            [-109.5387, 47.7389],
            [-109.5388, 47.7662],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004050',
        NAME: 'Cleveland Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1919321705,
        AWATER: 11730294,
        INTPTLAT: '+48.0494815',
        INTPTLON: '-109.1002793',
        ELSDLEA: '04050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3258, 48.4524],
            [-109.1433, 48.4652],
            [-109.1856, 48.5676],
            [-109.1764, 48.6642],
            [-109.0463, 48.7213],
            [-109.046, 48.8195],
            [-109.2824, 48.8189],
            [-109.283, 48.9139],
            [-109.1719, 48.9141],
            [-109.1722, 48.9999],
            [-108.8234, 48.9991],
            [-108.8443, 48.9145],
            [-108.843, 48.7009],
            [-108.96, 48.7012],
            [-108.9604, 48.5582],
            [-108.8881, 48.5363],
            [-108.8888, 48.4345],
            [-109.0638, 48.4349],
            [-109.3258, 48.3949],
            [-109.3258, 48.4524],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028910',
        NAME: 'Zurich Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1358971638,
        AWATER: 1958295,
        INTPTLAT: '+48.6972672',
        INTPTLON: '-109.0202337',
        ELSDLEA: '28910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4895, 49.0003],
            [-109.1722, 48.9999],
            [-109.1719, 48.9141],
            [-109.283, 48.9139],
            [-109.2824, 48.8189],
            [-109.046, 48.8195],
            [-109.0463, 48.7213],
            [-109.1764, 48.6642],
            [-109.1856, 48.5676],
            [-109.1433, 48.4652],
            [-109.3258, 48.4524],
            [-109.4643, 48.4526],
            [-109.5053, 48.5679],
            [-109.5055, 48.9139],
            [-109.4895, 49.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006260',
        NAME: 'Chinook Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1498428232,
        AWATER: 6734246,
        INTPTLAT: '+48.7203657',
        INTPTLON: '-109.3242873',
        ELSDLEA: '06260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5513, 48.2935],
            [-109.4643, 48.4526],
            [-109.3258, 48.4524],
            [-109.3258, 48.3949],
            [-109.2061, 48.3497],
            [-109.2065, 48.2361],
            [-109.0787, 48.2218],
            [-109.0788, 48.0782],
            [-109.2082, 48.0797],
            [-109.2052, 47.9349],
            [-109.4051, 47.9224],
            [-109.4539, 47.8796],
            [-109.4524, 47.7665],
            [-109.5388, 47.7662],
            [-109.5334, 47.9906],
            [-109.5343, 48.1346],
            [-109.5513, 48.2935],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003000',
        NAME: 'Bear Paw Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1592004054,
        AWATER: 1151891,
        INTPTLAT: '+48.1583331',
        INTPTLON: '-109.3485816',
        ELSDLEA: '03000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6695, 48.9991],
            [-108.2365, 48.9996],
            [-108.2364, 48.9136],
            [-108.2594, 48.7422],
            [-108.3191, 48.7422],
            [-108.4673, 48.7278],
            [-108.4674, 48.6985],
            [-108.5865, 48.7419],
            [-108.7607, 48.7419],
            [-108.7173, 48.8729],
            [-108.742, 48.9415],
            [-108.6695, 48.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026640',
        NAME: 'Turner Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1048379210,
        AWATER: 759985,
        INTPTLAT: '+48.8620226',
        INTPTLON: '-108.4765298',
        ELSDLEA: '26640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.7727, 48.5884],
            [-108.7669, 48.6482],
            [-108.711, 48.6313],
            [-108.7109, 48.5914],
            [-108.7727, 48.5884],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3019310',
        NAME: 'North Harlem Colony Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42601824,
        AWATER: 82644,
        INTPTLAT: '+48.5920169',
        INTPTLON: '-108.7289507',
        ELSDLEA: '19310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2072, 48.2214],
            [-112.1203, 48.2445],
            [-112.099, 48.3955],
            [-112.1423, 48.468],
            [-112.0044, 48.4489],
            [-111.9909, 48.3943],
            [-111.7959, 48.3931],
            [-111.6658, 48.3495],
            [-111.6656, 48.2195],
            [-111.6663, 48.0886],
            [-111.7745, 48.1156],
            [-112.0059, 48.1014],
            [-112.0272, 48.0429],
            [-112.1778, 48.1307],
            [-112.2072, 48.2214],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007330',
        NAME: 'Conrad Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1169729875,
        AWATER: 1313888,
        INTPTLAT: '+48.2397359',
        INTPTLON: '-111.9098403',
        ELSDLEA: '07330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5812, 48.3099],
            [-112.5772, 48.3138],
            [-112.5464, 48.262],
            [-112.4128, 48.2621],
            [-112.3182, 48.2216],
            [-112.4046, 48.1319],
            [-112.9007, 48.131],
            [-112.7474, 48.2305],
            [-112.6305, 48.2443],
            [-112.5812, 48.3099],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009330',
        NAME: 'Dupuyer Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465019970,
        AWATER: 4796798,
        INTPTLAT: '+48.1862175',
        INTPTLON: '-112.6571918',
        ELSDLEA: '09330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3182, 48.2216],
            [-112.2072, 48.2214],
            [-112.1778, 48.1307],
            [-112.4046, 48.1319],
            [-112.3182, 48.2216],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021150',
        NAME: 'Miami Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163304097,
        AWATER: 55657,
        INTPTLAT: '+48.1792322',
        INTPTLON: '-112.2268737',
        ELSDLEA: '21150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5769, 48.4833],
            [-112.1848, 48.4776],
            [-112.1423, 48.468],
            [-112.099, 48.3955],
            [-112.1203, 48.2445],
            [-112.2072, 48.2214],
            [-112.3182, 48.2216],
            [-112.4128, 48.2621],
            [-112.5464, 48.262],
            [-112.5772, 48.3138],
            [-112.5769, 48.4833],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027060',
        NAME: 'Valier Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 870775182,
        AWATER: 32700512,
        INTPTLAT: '+48.3671232',
        INTPTLON: '-112.3147570',
        ELSDLEA: '27060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.952, 48.0447],
            [-112.5456, 48.0522],
            [-112.4816, 48.0053],
            [-112.3951, 48.0583],
            [-112.1657, 48.0324],
            [-112.1719, 47.9538],
            [-112.2476, 47.9259],
            [-112.5825, 47.9257],
            [-112.6015, 47.9564],
            [-112.9075, 47.9557],
            [-112.952, 48.0447],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005330',
        NAME: 'Bynum Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 582661968,
        AWATER: 14047698,
        INTPTLAT: '+47.9826565',
        INTPTLON: '-112.5100173',
        ELSDLEA: '05330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9075, 47.9557],
            [-112.6015, 47.9564],
            [-112.5825, 47.9257],
            [-112.2476, 47.9259],
            [-112.1719, 47.9538],
            [-112.1657, 48.0324],
            [-112.1296, 47.9855],
            [-111.9843, 47.9848],
            [-111.9192, 47.9844],
            [-111.9235, 47.814],
            [-111.9024, 47.7563],
            [-111.9231, 47.6982],
            [-112.1792, 47.6971],
            [-112.298, 47.7132],
            [-112.3108, 47.5746],
            [-112.3044, 47.5265],
            [-112.4327, 47.6061],
            [-112.5687, 47.636],
            [-112.8547, 47.6292],
            [-112.9139, 47.6972],
            [-112.9133, 47.8262],
            [-112.9847, 47.9539],
            [-112.9075, 47.9557],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006320',
        NAME: 'Choteau Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2564705425,
        AWATER: 18172869,
        INTPTLAT: '+47.8032797',
        INTPTLON: '-112.4422958',
        ELSDLEA: '06320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1792, 47.6971],
            [-111.9231, 47.6982],
            [-111.9018, 47.6981],
            [-111.902, 47.6111],
            [-111.9228, 47.5051],
            [-112.0478, 47.516],
            [-112.1369, 47.5137],
            [-112.1792, 47.6971],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010140',
        NAME: 'Fairfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349427316,
        AWATER: 17841637,
        INTPTLAT: '+47.6119949',
        INTPTLON: '-112.0469363',
        ELSDLEA: '10140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1792, 47.6971],
            [-112.1369, 47.5137],
            [-112.3044, 47.5265],
            [-112.3108, 47.5746],
            [-112.298, 47.7132],
            [-112.1792, 47.6971],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012600',
        NAME: 'Golden Ridge Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241474121,
        AWATER: 999043,
        INTPTLAT: '+47.5957400',
        INTPTLON: '-112.2413443',
        ELSDLEA: '12600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9018, 47.6981],
            [-111.9231, 47.6982],
            [-111.9024, 47.7563],
            [-111.7744, 47.7561],
            [-111.7743, 47.698],
            [-111.7312, 47.6111],
            [-111.902, 47.6111],
            [-111.9018, 47.6981],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013110',
        NAME: 'Greenfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180874716,
        AWATER: 57801,
        INTPTLAT: '+47.6760894',
        INTPTLON: '-111.8486545',
        ELSDLEA: '13110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0148, 48.131],
            [-112.9007, 48.131],
            [-112.4046, 48.1319],
            [-112.1778, 48.1307],
            [-112.0272, 48.0429],
            [-111.9843, 47.9848],
            [-112.1296, 47.9855],
            [-112.1657, 48.0324],
            [-112.3951, 48.0583],
            [-112.4816, 48.0053],
            [-112.5456, 48.0522],
            [-112.952, 48.0447],
            [-113.0148, 48.131],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020240',
        NAME: 'Pendroy Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740437631,
        AWATER: 1465858,
        INTPTLAT: '+48.0900956',
        INTPTLON: '-112.4834808',
        ELSDLEA: '20240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9235, 47.814],
            [-111.746, 47.8136],
            [-111.6671, 47.785],
            [-111.4087, 47.7846],
            [-111.4089, 47.6981],
            [-111.4195, 47.6981],
            [-111.6665, 47.6978],
            [-111.6663, 47.6262],
            [-111.7312, 47.6111],
            [-111.7743, 47.698],
            [-111.7744, 47.7561],
            [-111.9024, 47.7563],
            [-111.9235, 47.814],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021510',
        NAME: 'Power Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418359373,
        AWATER: 70479,
        INTPTLAT: '+47.7275907',
        INTPTLON: '-111.6342357',
        ELSDLEA: '21510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4189, 46.5709],
            [-105.2392, 46.5412],
            [-104.9847, 46.5404],
            [-104.7331, 46.6127],
            [-104.7327, 46.4822],
            [-104.906, 46.4823],
            [-104.8856, 46.2532],
            [-105.1478, 46.2578],
            [-105.1472, 46.3146],
            [-105.285, 46.3149],
            [-105.4211, 46.4125],
            [-105.4189, 46.5709],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000089',
        NAME: 'Cottonwood Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 1301990163,
        AWATER: 698641,
        INTPTLAT: '+46.4438472',
        INTPTLON: '-105.0539266',
        ELSDLEA: '00089',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4711, 46.1782],
            [-105.2911, 46.1724],
            [-105.2504, 46.1022],
            [-105.1392, 46.1037],
            [-105.0477, 46.0043],
            [-104.9393, 46.0044],
            [-104.9393, 45.787],
            [-104.9819, 45.787],
            [-104.9816, 45.5393],
            [-105.1107, 45.5292],
            [-105.1775, 45.5865],
            [-105.1742, 45.7013],
            [-105.2879, 45.6668],
            [-105.2887, 45.7877],
            [-105.4696, 45.7884],
            [-105.4711, 46.1782],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007950',
        NAME: 'Spring Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 1939643729,
        AWATER: 2088514,
        INTPTLAT: '+45.9734361',
        INTPTLON: '-105.2158308',
        ELSDLEA: '07950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0865, 46.8459],
            [-106.0866, 46.8603],
            [-105.8327, 46.8608],
            [-105.6182, 46.8321],
            [-105.5468, 46.658],
            [-105.5997, 46.5712],
            [-105.7246, 46.5305],
            [-105.8406, 46.4302],
            [-105.9972, 46.3482],
            [-106.1548, 46.3043],
            [-106.1204, 46.4836],
            [-106.1206, 46.8314],
            [-106.0865, 46.8459],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007980',
        NAME: 'Kinsey Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1844594123,
        AWATER: 9127097,
        INTPTLAT: '+46.6638510',
        INTPTLON: '-105.9214527',
        ELSDLEA: '07980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8187, 46.0488],
            [-105.7082, 46.0523],
            [-105.7085, 46.1777],
            [-105.5733, 46.178],
            [-105.4711, 46.1782],
            [-105.4696, 45.7884],
            [-105.6547, 45.7884],
            [-105.7873, 45.7885],
            [-105.8821, 45.9196],
            [-105.883, 46.0227],
            [-105.8187, 46.0488],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008010',
        NAME: 'S Y Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1100393485,
        AWATER: 349045,
        INTPTLAT: '+45.9901948',
        INTPTLON: '-105.6373518',
        ELSDLEA: '08010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1915, 45.8764],
            [-106.1916, 46.1372],
            [-106.1548, 46.3043],
            [-105.9972, 46.3482],
            [-105.918, 46.2729],
            [-105.8903, 46.0807],
            [-105.8187, 46.0488],
            [-105.883, 46.0227],
            [-105.8821, 45.9196],
            [-105.7873, 45.7885],
            [-106.13, 45.7886],
            [-106.1917, 45.7886],
            [-106.1915, 45.8764],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008040',
        NAME: 'S H Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1367846731,
        AWATER: 2838240,
        INTPTLAT: '+46.0301109',
        INTPTLON: '-106.0650491',
        ELSDLEA: '08040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.918, 46.2729],
            [-105.7665, 46.2733],
            [-105.8406, 46.4302],
            [-105.7246, 46.5305],
            [-105.5997, 46.5712],
            [-105.5468, 46.658],
            [-105.4189, 46.5709],
            [-105.4211, 46.4125],
            [-105.4214, 46.3316],
            [-105.5733, 46.2912],
            [-105.5733, 46.178],
            [-105.7085, 46.1777],
            [-105.7082, 46.0523],
            [-105.8187, 46.0488],
            [-105.8903, 46.0807],
            [-105.918, 46.2729],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015640',
        NAME: 'Kircher Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1350808110,
        AWATER: 5328181,
        INTPTLAT: '+46.3526183',
        INTPTLON: '-105.7081088',
        ELSDLEA: '15640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.9972, 46.3482],
            [-105.8406, 46.4302],
            [-105.7665, 46.2733],
            [-105.918, 46.2729],
            [-105.9972, 46.3482],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018410',
        NAME: 'Miles City Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270470043,
        AWATER: 4295256,
        INTPTLAT: '+46.3350020',
        INTPTLON: '-105.8735138',
        ELSDLEA: '18410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4211, 46.4125],
            [-105.285, 46.3149],
            [-105.1472, 46.3146],
            [-105.1478, 46.2578],
            [-104.8856, 46.2532],
            [-104.8855, 46.1366],
            [-104.9393, 46.0044],
            [-105.0477, 46.0043],
            [-105.1392, 46.1037],
            [-105.2504, 46.1022],
            [-105.2911, 46.1724],
            [-105.4711, 46.1782],
            [-105.5733, 46.178],
            [-105.5733, 46.2912],
            [-105.4214, 46.3316],
            [-105.4211, 46.4125],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026400',
        NAME: 'Trail Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1108766341,
        AWATER: 980975,
        INTPTLAT: '+46.2136246',
        INTPTLON: '-105.2240955',
        ELSDLEA: '26400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.281, 46.4089],
            [-110.2733, 46.7105],
            [-109.965, 46.7499],
            [-109.9653, 46.6206],
            [-109.6917, 46.5616],
            [-109.3887, 46.5468],
            [-109.4032, 46.356],
            [-109.5586, 46.397],
            [-109.5583, 46.4714],
            [-109.6539, 46.4901],
            [-109.6445, 46.2194],
            [-109.6539, 46.2194],
            [-110.282, 46.2206],
            [-110.281, 46.4089],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013440',
        NAME: 'Harlowton Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2662486695,
        AWATER: 5163651,
        INTPTLAT: '+46.4515956',
        INTPTLON: '-109.9380382',
        ELSDLEA: '13440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.4032, 46.356],
            [-109.4029, 46.2197],
            [-109.6445, 46.2194],
            [-109.6539, 46.4901],
            [-109.5583, 46.4714],
            [-109.5586, 46.397],
            [-109.4032, 46.356],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023850',
        NAME: 'Shawmut Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428020557,
        AWATER: 7831955,
        INTPTLAT: '+46.3336506',
        INTPTLON: '-109.6105506',
        ELSDLEA: '23850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0203, 45.1862],
            [-106.768, 45.1861],
            [-106.2797, 45.1797],
            [-106.2637, 44.9938],
            [-106.5709, 44.9948],
            [-106.9896, 44.9963],
            [-107.0052, 44.9964],
            [-107.0203, 45.1862],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003660',
        NAME: 'Spring Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 1206033375,
        AWATER: 8838215,
        INTPTLAT: '+45.0651044',
        INTPTLON: '-106.6510237',
        ELSDLEA: '03660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.1049, 45.2205],
            [-108.0832, 45.2313],
            [-108.0706, 45.5185],
            [-108.0697, 45.6813],
            [-108.0474, 45.8995],
            [-107.8818, 45.8992],
            [-107.798, 45.9857],
            [-107.6745, 45.9858],
            [-107.6745, 46.0436],
            [-107.5115, 46.0434],
            [-107.4249, 46.0434],
            [-107.4251, 45.9567],
            [-107.1764, 45.9568],
            [-106.9391, 45.87],
            [-106.913, 45.6839],
            [-106.7276, 45.6765],
            [-106.7296, 45.408],
            [-106.7687, 45.3528],
            [-106.768, 45.1861],
            [-107.0203, 45.1862],
            [-107.0201, 45.2138],
            [-107.0189, 45.4533],
            [-107.5741, 45.4462],
            [-107.6964, 45.3853],
            [-107.6969, 45.2603],
            [-107.8441, 45.2593],
            [-107.8449, 45.0007],
            [-107.9115, 45.0007],
            [-108.2494, 44.9999],
            [-108.2498, 45.0316],
            [-108.1049, 45.2205],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013310',
        NAME: 'Hardin Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7399060882,
        AWATER: 37260987,
        INTPTLAT: '+45.5798028',
        INTPTLON: '-107.4957776',
        ELSDLEA: '13310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0189, 45.4533],
            [-107.0201, 45.2138],
            [-107.2934, 45.2048],
            [-107.2936, 45.1778],
            [-107.5681, 45.1772],
            [-107.5683, 45.0009],
            [-107.8449, 45.0007],
            [-107.8441, 45.2593],
            [-107.6969, 45.2603],
            [-107.6964, 45.3853],
            [-107.5741, 45.4462],
            [-107.0189, 45.4533],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017010',
        NAME: 'Lodge Grass Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2005251876,
        AWATER: 2535318,
        INTPTLAT: '+45.2566607',
        INTPTLON: '-107.4515585',
        ELSDLEA: '17010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6453, 45.4641],
            [-108.645, 45.4641],
            [-108.3164, 45.4605],
            [-108.0706, 45.5185],
            [-108.0832, 45.2313],
            [-108.1049, 45.2205],
            [-108.6996, 45.2177],
            [-108.6996, 45.3446],
            [-108.6453, 45.4641],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021720',
        NAME: 'Pryor Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1387569589,
        AWATER: 324508,
        INTPTLAT: '+45.3579660',
        INTPTLON: '-108.3611108',
        ELSDLEA: '21720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0201, 45.2138],
            [-107.0203, 45.1862],
            [-107.0052, 44.9964],
            [-107.5683, 45.0009],
            [-107.5681, 45.1772],
            [-107.2936, 45.1778],
            [-107.2934, 45.2048],
            [-107.0201, 45.2138],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028800',
        NAME: 'Wyola Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 937706263,
        AWATER: 226828,
        INTPTLAT: '+45.0960447',
        INTPTLON: '-107.2782884',
        ELSDLEA: '28800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1944, 48.4797],
            [-106.1947, 48.567],
            [-106.0647, 48.5664],
            [-106.0653, 48.3186],
            [-105.8047, 48.3029],
            [-105.8438, 48.219],
            [-105.8439, 48.0103],
            [-105.8527, 48.0084],
            [-106.0062, 48.0337],
            [-106.1083, 48.0062],
            [-106.1083, 48.2191],
            [-106.0629, 48.3053],
            [-106.1947, 48.3058],
            [-106.1944, 48.4797],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011420',
        NAME: 'Frazer Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 893184596,
        AWATER: 5989631,
        INTPTLAT: '+48.2202180',
        INTPTLON: '-106.0315246',
        ELSDLEA: '11420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4047, 48.1157],
            [-107.3693, 48.3052],
            [-107.2605, 48.3048],
            [-107.2603, 48.3941],
            [-107.2015, 48.5095],
            [-107.206, 48.9127],
            [-107.1798, 49.0001],
            [-106.6513, 48.9999],
            [-106.6797, 48.9124],
            [-106.676, 48.6958],
            [-106.7245, 48.6552],
            [-106.9003, 48.6551],
            [-106.9104, 48.4084],
            [-106.8454, 48.4082],
            [-106.8459, 48.2609],
            [-106.8778, 48.2175],
            [-106.8776, 47.9606],
            [-107.4048, 47.9633],
            [-107.4047, 48.1157],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014340',
        NAME: 'Hinsdale Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3877269987,
        AWATER: 11149071,
        INTPTLAT: '+48.4950492',
        INTPTLON: '-107.0472065',
        ELSDLEA: '14340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0647, 48.5664],
            [-106.021, 48.5663],
            [-105.8044, 48.5634],
            [-105.8047, 48.3029],
            [-106.0653, 48.3186],
            [-106.0647, 48.5664],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017340',
        NAME: 'Lustre Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555157662,
        AWATER: 459124,
        INTPTLAT: '+48.4276101',
        INTPTLON: '-105.9132923',
        ELSDLEA: '17340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4092, 48.5632],
            [-104.9734, 48.5632],
            [-104.7576, 48.5631],
            [-104.7576, 48.4766],
            [-104.9754, 48.4763],
            [-104.9749, 48.404],
            [-105.0834, 48.4039],
            [-105.04, 48.2592],
            [-104.7575, 48.2599],
            [-104.7579, 48.2163],
            [-104.9314, 48.2169],
            [-104.9323, 48.1048],
            [-105.0371, 48.0634],
            [-105.1948, 48.0666],
            [-105.229, 48.0893],
            [-105.452, 48.0894],
            [-105.4097, 48.2154],
            [-105.4092, 48.5632],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021240',
        NAME: 'Poplar Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1918485694,
        AWATER: 11259285,
        INTPTLAT: '+48.3150442',
        INTPTLON: '-105.1389817',
        ELSDLEA: '21240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8047, 48.3029],
            [-105.8044, 48.5634],
            [-105.6064, 48.5633],
            [-105.6038, 48.3024],
            [-105.5821, 48.08],
            [-105.8439, 48.0103],
            [-105.8438, 48.219],
            [-105.8047, 48.3029],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028590',
        NAME: 'Wolf Point Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 954843872,
        AWATER: 4356866,
        INTPTLAT: '+48.3016858',
        INTPTLON: '-105.7172069',
        ELSDLEA: '28590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7579, 48.2163],
            [-104.6109, 48.1414],
            [-104.6816, 48.1033],
            [-104.9312, 48.1068],
            [-104.9323, 48.1048],
            [-104.9314, 48.2169],
            [-104.7579, 48.2163],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005010',
        NAME: 'Brockton Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218089741,
        AWATER: 4829626,
        INTPTLAT: '+48.1671541',
        INTPTLON: '-104.7998410',
        ELSDLEA: '05010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7576, 48.4766],
            [-104.6277, 48.4763],
            [-104.6278, 48.3894],
            [-104.2595, 48.3894],
            [-104.2706, 48.3023],
            [-104.3896, 48.3026],
            [-104.4546, 48.2447],
            [-104.5846, 48.2447],
            [-104.6062, 48.3026],
            [-104.7575, 48.2599],
            [-105.04, 48.2592],
            [-105.0834, 48.4039],
            [-104.9749, 48.404],
            [-104.9754, 48.4763],
            [-104.7576, 48.4766],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011550',
        NAME: 'Froid Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057650411,
        AWATER: 4023602,
        INTPTLAT: '+48.3367897',
        INTPTLON: '-104.6668421',
        ELSDLEA: '11550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.6064, 48.5633],
            [-105.4092, 48.5632],
            [-105.4097, 48.2154],
            [-105.452, 48.0894],
            [-105.5821, 48.08],
            [-105.6038, 48.3024],
            [-105.6064, 48.5633],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011670',
        NAME: 'Frontier Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 682626541,
        AWATER: 1740090,
        INTPTLAT: '+48.3494306',
        INTPTLON: '-105.4949383',
        ELSDLEA: '11670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.9761, 45.9467],
            [-110.9759, 45.9885],
            [-110.8919, 46.083],
            [-110.7907, 46.0829],
            [-110.7838, 46.1927],
            [-110.5326, 46.192],
            [-110.2819, 46.1844],
            [-110.2928, 45.9952],
            [-110.2929, 45.9274],
            [-110.2929, 45.9152],
            [-110.3905, 45.8565],
            [-110.3446, 45.7851],
            [-110.4886, 45.785],
            [-110.5956, 45.8185],
            [-110.7891, 45.8023],
            [-110.9139, 45.844],
            [-110.9766, 45.9026],
            [-110.9761, 45.9467],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000932',
        NAME: 'Shields Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1933295846,
        AWATER: 3101372,
        INTPTLAT: '+46.0096738',
        INTPTLON: '-110.5765249',
        ELSDLEA: '00932',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4498, 45.7873],
            [-111.2586, 45.787],
            [-111.2687, 45.7437],
            [-111.3566, 45.6715],
            [-111.4541, 45.6714],
            [-111.4498, 45.7873],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3001980',
        NAME: 'Amsterdam Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198352928,
        AWATER: 59586,
        INTPTLAT: '+45.7272490',
        INTPTLON: '-111.3746265',
        ELSDLEA: '01980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2839, 45.9619],
            [-111.284, 45.9764],
            [-111.2631, 46.0061],
            [-111.1515, 45.9908],
            [-110.9761, 45.9467],
            [-110.9766, 45.9026],
            [-110.977, 45.8727],
            [-111.0345, 45.795],
            [-111.145, 45.7001],
            [-111.2232, 45.6856],
            [-111.2687, 45.7437],
            [-111.2586, 45.787],
            [-111.1863, 45.8456],
            [-111.2839, 45.9619],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003290',
        NAME: 'Belgrade Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433210049,
        AWATER: 1844326,
        INTPTLAT: '+45.8506031',
        INTPTLON: '-111.1366257',
        ELSDLEA: '03290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5292, 45.6717],
            [-111.4541, 45.6714],
            [-111.3566, 45.6715],
            [-111.2687, 45.7437],
            [-111.2232, 45.6856],
            [-111.1451, 45.6486],
            [-111.145, 45.7001],
            [-111.0345, 45.795],
            [-110.9155, 45.7871],
            [-110.977, 45.8727],
            [-110.9766, 45.9026],
            [-110.9139, 45.844],
            [-110.7891, 45.8023],
            [-110.7953, 45.7019],
            [-110.8762, 45.6723],
            [-110.9803, 45.6565],
            [-110.9804, 45.5253],
            [-111.0628, 45.5253],
            [-111.0815, 45.5693],
            [-111.0836, 45.613],
            [-111.1371, 45.598],
            [-111.1586, 45.6128],
            [-111.2882, 45.6434],
            [-111.3513, 45.5986],
            [-111.3514, 45.6419],
            [-111.5368, 45.6568],
            [-111.5292, 45.6717],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004560',
        NAME: 'Bozeman Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 705604516,
        AWATER: 1426872,
        INTPTLAT: '+45.7016747',
        INTPTLON: '-111.0470534',
        ELSDLEA: '04560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3513, 45.5986],
            [-111.2882, 45.6434],
            [-111.1586, 45.6128],
            [-111.1749, 45.5573],
            [-111.0628, 45.5253],
            [-110.9804, 45.5253],
            [-110.9192, 45.5249],
            [-110.9179, 45.3513],
            [-111.0382, 45.3514],
            [-111.1661, 45.3504],
            [-111.1655, 45.437],
            [-111.35, 45.3822],
            [-111.3501, 45.3905],
            [-111.3513, 45.5986],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011790',
        NAME: 'Gallatin Gateway Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 710601934,
        AWATER: 2176566,
        INTPTLAT: '+45.4622990',
        INTPTLON: '-111.1239850',
        ELSDLEA: '11790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0815, 45.5693],
            [-111.1371, 45.598],
            [-111.0836, 45.613],
            [-111.0815, 45.5693],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002070',
        NAME: 'Anderson Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19845727,
        AWATER: 31246,
        INTPTLAT: '+45.5923579',
        INTPTLON: '-111.1094172',
        ELSDLEA: '02070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1586, 45.6128],
            [-111.1371, 45.598],
            [-111.0815, 45.5693],
            [-111.0628, 45.5253],
            [-111.1749, 45.5573],
            [-111.1586, 45.6128],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007500',
        NAME: 'Cottonwood Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 36164191,
        AWATER: 4739,
        INTPTLAT: '+45.5639407',
        INTPTLON: '-111.1366427',
        ELSDLEA: '07500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4986, 46.3421],
            [-112.3106, 46.4212],
            [-112.2871, 46.4194],
            [-112.2252, 46.3374],
            [-112.2254, 46.2251],
            [-112.5884, 46.2249],
            [-112.4986, 46.3421],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002820',
        NAME: 'Basin Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395985244,
        AWATER: 50100,
        INTPTLAT: '+46.2906049',
        INTPTLON: '-112.3889758',
        ELSDLEA: '02820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2871, 46.4194],
            [-112.2427, 46.4225],
            [-112.0969, 46.308],
            [-111.7863, 46.3146],
            [-111.7842, 46.0828],
            [-111.9893, 46.0653],
            [-112.2241, 46.0656],
            [-112.225, 45.9583],
            [-112.4303, 45.96],
            [-112.4663, 46.093],
            [-112.5771, 46.179],
            [-112.5884, 46.2249],
            [-112.2254, 46.2251],
            [-112.2252, 46.3374],
            [-112.2871, 46.4194],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004380',
        NAME: 'Boulder Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1627530036,
        AWATER: 4799824,
        INTPTLAT: '+46.1261044',
        INTPTLON: '-112.1986550',
        ELSDLEA: '04380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-111.8136, 45.9247],
              [-111.7284, 45.8776],
              [-111.8096, 45.8775],
              [-111.8136, 45.9247],
            ],
          ],
          [
            [
              [-111.9893, 46.0653],
              [-111.7842, 46.0828],
              [-111.7843, 46.0498],
              [-111.8442, 45.9692],
              [-111.8096, 45.8775],
              [-111.8053, 45.7983],
              [-111.8054, 45.7867],
              [-111.8449, 45.7498],
              [-112.0371, 45.7495],
              [-112.0303, 45.9289],
              [-111.9893, 46.0653],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005550',
        NAME: 'Cardwell Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 594873107,
        AWATER: 483263,
        INTPTLAT: '+45.9168894',
        INTPTLON: '-111.8888579',
        ELSDLEA: '05550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0752, 46.52],
            [-111.8795, 46.4989],
            [-111.9123, 46.5694],
            [-111.7879, 46.5695],
            [-111.7863, 46.3146],
            [-112.0969, 46.308],
            [-112.2427, 46.4225],
            [-112.1838, 46.5011],
            [-112.0752, 46.52],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006840',
        NAME: 'Clancy Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 679319408,
        AWATER: 211790,
        INTPTLAT: '+46.4238770',
        INTPTLON: '-111.9758912',
        ELSDLEA: '06840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0752, 46.52],
            [-111.9592, 46.5692],
            [-111.9123, 46.5694],
            [-111.8795, 46.4989],
            [-112.0752, 46.52],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018780',
        NAME: 'Montana City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101372551,
        AWATER: 351833,
        INTPTLAT: '+46.5258093',
        INTPTLON: '-111.9650982',
        ELSDLEA: '18780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4303, 45.96],
            [-112.225, 45.9583],
            [-112.2241, 46.0656],
            [-111.9893, 46.0653],
            [-112.0303, 45.9289],
            [-112.0371, 45.7495],
            [-112.3783, 45.7445],
            [-112.4303, 45.96],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027810',
        NAME: 'Whitehall Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 930201451,
        AWATER: 473744,
        INTPTLAT: '+45.8921992',
        INTPTLON: '-112.1900757',
        ELSDLEA: '27810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-113.001, 45.5221],
              [-113.0002, 45.7005],
              [-112.7358, 45.7012],
              [-112.7367, 45.7001],
              [-112.6871, 45.6249],
              [-112.6878, 45.5656],
              [-112.8968, 45.5652],
              [-112.8992, 45.4401],
              [-112.9998, 45.4402],
              [-113.001, 45.5221],
            ],
          ],
          [
            [
              [-113.2323, 45.2747],
              [-113.0504, 45.2733],
              [-112.9463, 45.2294],
              [-112.8986, 45.3453],
              [-112.5993, 45.3468],
              [-112.4667, 45.3966],
              [-112.4575, 45.1739],
              [-112.4569, 45.0885],
              [-112.3339, 45.0884],
              [-112.3342, 45.0005],
              [-112.1912, 45.0005],
              [-112.1671, 44.8267],
              [-112.0658, 44.7835],
              [-112.3933, 44.7889],
              [-112.3925, 44.9347],
              [-112.792, 44.9313],
              [-112.9499, 44.9083],
              [-112.9529, 45.0863],
              [-113.2551, 45.0883],
              [-113.2323, 45.2747],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008910',
        NAME: 'Dillon Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3304419992,
        AWATER: 21528581,
        INTPTLAT: '+45.1720695',
        INTPTLON: '-112.7088536',
        ELSDLEA: '08910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8152, 46.6162],
            [-113.8661, 46.4924],
            [-113.8678, 46.4859],
            [-114.0355, 46.5302],
            [-114.0511, 46.6049],
            [-113.8152, 46.6162],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017190',
        NAME: 'Lone Rock Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191871651,
        AWATER: 95697,
        INTPTLAT: '+46.5693945',
        INTPTLON: '-113.9373272',
        ELSDLEA: '17190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3308, 46.5738],
            [-114.1093, 46.5718],
            [-114.0511, 46.6049],
            [-114.0355, 46.5302],
            [-113.8678, 46.4859],
            [-113.8166, 46.3991],
            [-114.0257, 46.3995],
            [-114.1212, 46.4068],
            [-114.115, 46.4795],
            [-114.3794, 46.4603],
            [-114.3308, 46.5738],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025020',
        NAME: 'Stevensville Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499073339,
        AWATER: 2916395,
        INTPTLAT: '+46.4916908',
        INTPTLON: '-114.1127457',
        ELSDLEA: '25020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.522, 45.0932],
            [-113.2551, 45.0883],
            [-112.9529, 45.0863],
            [-112.9499, 44.9083],
            [-112.9496, 44.6536],
            [-113.0644, 44.6521],
            [-113.1016, 44.7295],
            [-113.2472, 44.823],
            [-113.3572, 44.8207],
            [-113.4551, 44.8654],
            [-113.452, 45.0592],
            [-113.522, 45.0932],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012900',
        NAME: 'Grant Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1459793897,
        AWATER: 157646,
        INTPTLAT: '+44.8552858',
        INTPTLON: '-113.1270907',
        ELSDLEA: '12900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.8992, 45.4401],
            [-112.8968, 45.5652],
            [-112.6878, 45.5656],
            [-112.6878, 45.4772],
            [-112.4667, 45.3966],
            [-112.5993, 45.3468],
            [-112.8986, 45.3453],
            [-112.8992, 45.4401],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022290',
        NAME: 'Reichle Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 522395551,
        AWATER: 848782,
        INTPTLAT: '+45.4532431',
        INTPTLON: '-112.7716874',
        ELSDLEA: '22290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.151, 45.5198],
            [-113.001, 45.5221],
            [-112.9998, 45.4402],
            [-112.8992, 45.4401],
            [-112.8986, 45.3453],
            [-112.9463, 45.2294],
            [-113.0504, 45.2733],
            [-113.2323, 45.2747],
            [-113.1518, 45.3513],
            [-113.151, 45.5198],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021030',
        NAME: 'Polaris Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552428270,
        AWATER: 1397499,
        INTPTLAT: '+45.3847881',
        INTPTLON: '-113.0592468',
        ELSDLEA: '21030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.7654, 45.4768],
            [-113.3647, 45.465],
            [-113.3646, 45.5194],
            [-113.151, 45.5198],
            [-113.1518, 45.3513],
            [-113.2323, 45.2747],
            [-113.2551, 45.0883],
            [-113.522, 45.0932],
            [-113.5091, 45.1137],
            [-113.6929, 45.2642],
            [-113.7738, 45.4064],
            [-113.7654, 45.4768],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015060',
        NAME: 'Jackson Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1601694308,
        AWATER: 3157243,
        INTPTLAT: '+45.3185406',
        INTPTLON: '-113.4410645',
        ELSDLEA: '15060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5133, 47.3547],
            [-104.5132, 47.3831],
            [-104.513, 47.5157],
            [-104.3969, 47.5002],
            [-104.3344, 47.5475],
            [-104.2704, 47.553],
            [-104.2805, 47.465],
            [-104.4203, 47.3544],
            [-104.4203, 47.3544],
            [-104.451, 47.3193],
            [-104.5133, 47.3547],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023520',
        NAME: 'Savage Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298916444,
        AWATER: 4319119,
        INTPTLAT: '+47.4377285',
        INTPTLON: '-104.4633608',
        ELSDLEA: '23520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.2007, 47.4734],
            [-104.9172, 47.4998],
            [-104.5936, 47.4855],
            [-104.594, 47.5293],
            [-104.513, 47.5294],
            [-104.513, 47.5157],
            [-104.5132, 47.3831],
            [-104.6363, 47.3636],
            [-104.8959, 47.3628],
            [-105.1984, 47.3629],
            [-105.2007, 47.4734],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004200',
        NAME: 'Bloomfield Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740412119,
        AWATER: 66585,
        INTPTLAT: '+47.4364996',
        INTPTLON: '-104.8582710',
        ELSDLEA: '04200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3635, 47.5296],
            [-105.2415, 47.5301],
            [-105.2007, 47.4734],
            [-105.1984, 47.3629],
            [-104.8959, 47.3628],
            [-104.9642, 47.2789],
            [-104.9426, 47.1598],
            [-104.8267, 47.0503],
            [-104.9418, 46.8926],
            [-105.0077, 46.8614],
            [-105.0695, 46.9192],
            [-105.197, 46.9192],
            [-105.1967, 46.9772],
            [-105.3248, 46.9772],
            [-105.3249, 47.1815],
            [-105.4063, 47.1816],
            [-105.4066, 47.5295],
            [-105.3635, 47.5296],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008400',
        NAME: 'Lindsay Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1949343426,
        AWATER: 1959641,
        INTPTLAT: '+47.1899527',
        INTPTLON: '-105.1100985',
        ELSDLEA: '08400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8959, 47.3628],
            [-104.6363, 47.3636],
            [-104.5132, 47.3831],
            [-104.5133, 47.3547],
            [-104.451, 47.3193],
            [-104.3417, 47.2454],
            [-104.3061, 47.042],
            [-104.4121, 46.904],
            [-104.529, 46.8608],
            [-104.5318, 47.0097],
            [-104.6097, 47.0928],
            [-104.4283, 47.093],
            [-104.429, 47.1831],
            [-104.5298, 47.2029],
            [-104.7096, 47.1228],
            [-104.8007, 47.1737],
            [-104.9426, 47.1598],
            [-104.9642, 47.2789],
            [-104.8959, 47.3628],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008470',
        NAME: 'Deer Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1344307456,
        AWATER: 9616701,
        INTPTLAT: '+47.2494460',
        INTPTLON: '-104.7768610',
        ELSDLEA: '08470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9426, 47.1598],
            [-104.8007, 47.1737],
            [-104.7096, 47.1228],
            [-104.5298, 47.2029],
            [-104.429, 47.1831],
            [-104.4283, 47.093],
            [-104.6097, 47.0928],
            [-104.5318, 47.0097],
            [-104.529, 46.8608],
            [-104.6038, 46.8609],
            [-105.0077, 46.8614],
            [-104.9418, 46.8926],
            [-104.8267, 47.0503],
            [-104.9426, 47.1598],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012510',
        NAME: 'Glendive Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 956873292,
        AWATER: 15725094,
        INTPTLAT: '+47.0381540',
        INTPTLON: '-104.7012158',
        ELSDLEA: '12510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3218, 47.9622],
            [-114.1878, 47.9623],
            [-114.2233, 47.8726],
            [-114.4774, 47.8321],
            [-114.4922, 47.962],
            [-114.3218, 47.9622],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015900',
        NAME: 'Upper West Shore Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266349384,
        AWATER: 6785795,
        INTPTLAT: '+47.9094424',
        INTPTLON: '-114.3557037',
        ELSDLEA: '15900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.9414, 47.8847],
            [-113.944, 47.9682],
            [-113.7955, 47.9698],
            [-113.6723, 47.8965],
            [-113.6341, 47.6],
            [-113.7109, 47.6001],
            [-113.8503, 47.6002],
            [-113.9487, 47.6],
            [-113.9659, 47.6407],
            [-113.9255, 47.7554],
            [-113.9414, 47.8847],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015930',
        NAME: 'Swan Lake-Salmon Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 862656557,
        AWATER: 14679526,
        INTPTLAT: '+47.7763002',
        INTPTLON: '-113.8028190',
        ELSDLEA: '15930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.5201, 47.7899],
            [-114.4774, 47.8321],
            [-114.2233, 47.8726],
            [-114.1878, 47.9623],
            [-114.1101, 47.9625],
            [-114.0629, 47.9625],
            [-114.0373, 47.8846],
            [-113.9414, 47.8847],
            [-113.9255, 47.7554],
            [-113.9659, 47.6407],
            [-114.1883, 47.6495],
            [-114.2198, 47.6023],
            [-114.3504, 47.6021],
            [-114.4975, 47.6287],
            [-114.5201, 47.7899],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021060',
        NAME: 'Polson Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 814157312,
        AWATER: 345393916,
        INTPTLAT: '+47.7581863',
        INTPTLON: '-114.2126510',
        ELSDLEA: '21060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.365, 47.5181],
            [-114.3502, 47.6019],
            [-114.2198, 47.6023],
            [-114.1883, 47.6495],
            [-113.9659, 47.6407],
            [-113.9487, 47.6],
            [-113.9429, 47.4797],
            [-113.8949, 47.4337],
            [-114.1077, 47.4339],
            [-114.1825, 47.4994],
            [-114.2828, 47.4714],
            [-114.365, 47.5181],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022790',
        NAME: 'Ronan Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 536809581,
        AWATER: 17365309,
        INTPTLAT: '+47.5401660',
        INTPTLON: '-114.1131864',
        ELSDLEA: '22790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2198, 47.6023],
            [-114.3502, 47.6019],
            [-114.3504, 47.6021],
            [-114.2198, 47.6023],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027150',
        NAME: 'Valley View Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66860933,
        AWATER: 897166,
        INTPTLAT: '+47.6241975',
        INTPTLON: '-114.2949580',
        ELSDLEA: '27150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2031, 47.2562],
            [-113.8213, 47.2417],
            [-113.8243, 47.2744],
            [-113.7509, 47.2903],
            [-113.6948, 47.2076],
            [-113.7324, 47.1172],
            [-113.9792, 47.0495],
            [-114.1049, 47.0505],
            [-114.1872, 47.1382],
            [-114.2031, 47.2562],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002220',
        NAME: 'Arlee Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 707944704,
        AWATER: 2336828,
        INTPTLAT: '+47.1585519',
        INTPTLON: '-113.9521918',
        ELSDLEA: '02220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2828, 47.4714],
            [-114.1825, 47.4994],
            [-114.1077, 47.4339],
            [-114.203, 47.3557],
            [-114.3531, 47.3898],
            [-114.2828, 47.4714],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006112',
        NAME: 'Charlo Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217919349,
        AWATER: 7709441,
        INTPTLAT: '+47.4225006',
        INTPTLON: '-114.2266445',
        ELSDLEA: '06112',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0855, 45.8606],
            [-112.9232, 45.9348],
            [-112.757, 45.925],
            [-112.5718, 45.8322],
            [-112.5542, 45.81],
            [-112.7367, 45.7001],
            [-112.7358, 45.7012],
            [-112.8236, 45.7743],
            [-113.0002, 45.8077],
            [-113.0855, 45.8606],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009000',
        NAME: 'Divide Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557449426,
        AWATER: 681776,
        INTPTLAT: '+45.8328242',
        INTPTLON: '-112.7964416',
        ELSDLEA: '09000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.686, 46.1357],
            [-112.5966, 46.1655],
            [-112.5771, 46.179],
            [-112.4663, 46.093],
            [-112.4303, 45.96],
            [-112.3783, 45.7445],
            [-112.4853, 45.759],
            [-112.5542, 45.81],
            [-112.5718, 45.8322],
            [-112.5696, 46.0415],
            [-112.6462, 46.0417],
            [-112.686, 46.1357],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005280',
        NAME: 'Butte Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559393542,
        AWATER: 390897,
        INTPTLAT: '+45.9644714',
        INTPTLON: '-112.5162783',
        ELSDLEA: '05280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9228, 46.0048],
            [-112.7777, 46.0483],
            [-112.7771, 46.1358],
            [-112.686, 46.1357],
            [-112.6462, 46.0417],
            [-112.5696, 46.0415],
            [-112.5718, 45.8322],
            [-112.757, 45.925],
            [-112.9232, 45.9348],
            [-112.9228, 46.0048],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021850',
        NAME: 'Ramsay Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493501315,
        AWATER: 385446,
        INTPTLAT: '+45.9867718',
        INTPTLON: '-112.7247073',
        ELSDLEA: '21850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.7367, 45.7001],
            [-112.5542, 45.81],
            [-112.4853, 45.759],
            [-112.5495, 45.6843],
            [-112.6871, 45.6249],
            [-112.7367, 45.7001],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018210',
        NAME: 'Melrose Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176528684,
        AWATER: 64041,
        INTPTLAT: '+45.7183091',
        INTPTLON: '-112.6228285',
        ELSDLEA: '18210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0491, 49.0008],
            [-115.9755, 49.0009],
            [-115.9591, 48.8327],
            [-115.8868, 48.7988],
            [-115.6773, 48.7972],
            [-115.6792, 48.601],
            [-115.5355, 48.6013],
            [-115.5357, 48.6666],
            [-115.1311, 48.6667],
            [-115.1507, 48.6297],
            [-115.4126, 48.6301],
            [-115.4126, 48.5717],
            [-115.6632, 48.5724],
            [-115.941, 48.5775],
            [-116.049, 48.5153],
            [-116.0491, 49.0008],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006030',
        NAME: 'McCormick Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1083320731,
        AWATER: 9243566,
        INTPTLAT: '+48.6994376',
        INTPTLON: '-115.7560085',
        ELSDLEA: '06030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.4169, 49.0002],
            [-114.727, 49.0005],
            [-114.6894, 48.8639],
            [-114.7275, 48.8212],
            [-115.0042, 48.8178],
            [-115.0042, 48.7259],
            [-115.0423, 48.548],
            [-114.8498, 48.548],
            [-114.8486, 48.3995],
            [-114.8481, 48.2261],
            [-115.0201, 48.226],
            [-115.1715, 48.2255],
            [-115.1082, 48.3126],
            [-115.1057, 48.5718],
            [-115.1507, 48.6297],
            [-115.1311, 48.6667],
            [-115.132, 48.7036],
            [-115.4124, 48.7052],
            [-115.3795, 48.8825],
            [-115.3795, 48.8837],
            [-115.4169, 49.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3010080',
        NAME: 'Eureka Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2191573053,
        AWATER: 67811231,
        INTPTLAT: '+48.6855097',
        INTPTLON: '-115.0562685',
        ELSDLEA: '10080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.7275, 48.8212],
            [-114.6191, 48.7456],
            [-114.6192, 48.7426],
            [-115.0042, 48.7259],
            [-115.0042, 48.8178],
            [-114.7275, 48.8212],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011340',
        NAME: 'Fortine Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225017963,
        AWATER: 1748859,
        INTPTLAT: '+48.7807306',
        INTPTLON: '-114.7577390',
        ELSDLEA: '11340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0042, 48.7259],
            [-114.6192, 48.7426],
            [-114.639, 48.6589],
            [-114.8886, 48.6588],
            [-114.8498, 48.548],
            [-115.0423, 48.548],
            [-115.0042, 48.7259],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026460',
        NAME: 'Trego Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359852639,
        AWATER: 3809617,
        INTPTLAT: '+48.6588069',
        INTPTLON: '-114.8831266',
        ELSDLEA: '26460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2357, 45.3518],
            [-106.1094, 45.3514],
            [-106.1055, 45.4398],
            [-106.0497, 45.5244],
            [-106.0924, 45.6081],
            [-105.9576, 45.6085],
            [-105.6133, 45.6142],
            [-105.671, 45.7196],
            [-105.6547, 45.7884],
            [-105.4696, 45.7884],
            [-105.2887, 45.7877],
            [-105.2879, 45.6668],
            [-105.1742, 45.7013],
            [-105.1775, 45.5865],
            [-105.1107, 45.5292],
            [-104.9816, 45.5393],
            [-104.8579, 45.5376],
            [-104.858, 45.4125],
            [-104.8579, 45.3527],
            [-104.9775, 45.3526],
            [-105.0383, 45.2717],
            [-105.0383, 45.187],
            [-105.0382, 45.0001],
            [-105.0766, 44.9999],
            [-105.1969, 45.0001],
            [-105.2373, 45.0857],
            [-105.2358, 45.2152],
            [-105.5963, 45.2222],
            [-105.6594, 45.2083],
            [-105.6604, 45.0967],
            [-105.7855, 45.0967],
            [-105.7855, 45.0004],
            [-106.0248, 44.9935],
            [-106.2637, 44.9938],
            [-106.2797, 45.1797],
            [-106.2795, 45.3518],
            [-106.2357, 45.3518],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000006',
        NAME: 'Broadus Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6108075155,
        AWATER: 932901,
        INTPTLAT: '+45.4089262',
        INTPTLON: '-105.5553440',
        ELSDLEA: '00006',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.13, 45.7886],
            [-105.7873, 45.7885],
            [-105.6547, 45.7884],
            [-105.671, 45.7196],
            [-105.6133, 45.6142],
            [-105.9576, 45.6085],
            [-105.9762, 45.6683],
            [-106.1131, 45.6682],
            [-106.13, 45.7886],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000007',
        NAME: 'South Stacey Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 670918971,
        AWATER: 227000,
        INTPTLAT: '+45.6946874',
        INTPTLON: '-105.8653315',
        ELSDLEA: '00007',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3272, 45.6863],
            [-106.3032, 45.8767],
            [-106.1915, 45.8764],
            [-106.1917, 45.7886],
            [-106.13, 45.7886],
            [-106.1131, 45.6682],
            [-105.9762, 45.6683],
            [-105.9576, 45.6085],
            [-106.0924, 45.6081],
            [-106.0497, 45.5244],
            [-106.1055, 45.4398],
            [-106.1094, 45.3514],
            [-106.2357, 45.3518],
            [-106.2341, 45.4936],
            [-106.3561, 45.494],
            [-106.6319, 45.4971],
            [-106.3272, 45.6863],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000008',
        NAME: 'Ashland Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1153971004,
        AWATER: 65327,
        INTPTLAT: '+45.5960419',
        INTPTLON: '-106.2548561',
        ELSDLEA: '00008',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.1969, 45.0001],
            [-105.7855, 45.0004],
            [-105.7855, 45.0967],
            [-105.6604, 45.0967],
            [-105.6594, 45.2083],
            [-105.5963, 45.2222],
            [-105.2358, 45.2152],
            [-105.2373, 45.0857],
            [-105.1969, 45.0001],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003600',
        NAME: 'Biddle Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 949525556,
        AWATER: 341520,
        INTPTLAT: '+45.1051735',
        INTPTLON: '-105.4773200',
        ELSDLEA: '03600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0383, 45.187],
            [-104.9385, 45.1874],
            [-104.9143, 45.0929],
            [-104.6659, 45.0923],
            [-104.6621, 45.263],
            [-104.5347, 45.2871],
            [-104.4837, 45.4458],
            [-104.3724, 45.47],
            [-104.041, 45.4698],
            [-104.04, 45.2129],
            [-104.0579, 44.9976],
            [-105.0382, 45.0001],
            [-105.0383, 45.187],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3001950',
        NAME: 'Alzada Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2708381929,
        AWATER: 7136942,
        INTPTLAT: '+45.2164658',
        INTPTLON: '-104.4472215',
        ELSDLEA: '01950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0491, 48.5021],
            [-116.049, 48.5153],
            [-115.941, 48.5775],
            [-115.6632, 48.5724],
            [-115.6639, 48.2257],
            [-115.694, 48.2258],
            [-115.8002, 48.2228],
            [-116.0092, 48.1597],
            [-116.0487, 48.2161],
            [-116.0491, 48.5021],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026550',
        NAME: 'Troy Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1100004854,
        AWATER: 12760716,
        INTPTLAT: '+48.3890415',
        INTPTLON: '-115.8509837',
        ELSDLEA: '26550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.9755, 49.0009],
            [-115.4169, 49.0002],
            [-115.3795, 48.8837],
            [-115.3795, 48.8825],
            [-115.4124, 48.7052],
            [-115.132, 48.7036],
            [-115.1311, 48.6667],
            [-115.5357, 48.6666],
            [-115.5355, 48.6013],
            [-115.6792, 48.601],
            [-115.6773, 48.7972],
            [-115.8868, 48.7988],
            [-115.9591, 48.8327],
            [-115.9755, 49.0009],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028830',
        NAME: 'Yaak Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1366930551,
        AWATER: 6214868,
        INTPTLAT: '+48.8265459',
        INTPTLON: '-115.6301747',
        ELSDLEA: '28830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9819, 45.787],
            [-104.9393, 45.787],
            [-104.9393, 46.0044],
            [-104.8855, 46.1366],
            [-104.5161, 46.1362],
            [-104.5161, 46.0548],
            [-104.3907, 46.0548],
            [-104.3909, 45.9704],
            [-104.1348, 45.9688],
            [-104.1348, 45.8819],
            [-104.0442, 45.882],
            [-104.041, 45.4698],
            [-104.3724, 45.47],
            [-104.4837, 45.4458],
            [-104.595, 45.4455],
            [-104.5947, 45.6153],
            [-104.7178, 45.617],
            [-104.7192, 45.4129],
            [-104.858, 45.4125],
            [-104.8579, 45.5376],
            [-104.9816, 45.5393],
            [-104.9819, 45.787],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009670',
        NAME: 'Ekalaka Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4498182375,
        AWATER: 8424920,
        INTPTLAT: '+45.7660570',
        INTPTLON: '-104.5305407',
        ELSDLEA: '09670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.858, 45.4125],
            [-104.7192, 45.4129],
            [-104.7178, 45.617],
            [-104.5947, 45.6153],
            [-104.595, 45.4455],
            [-104.4837, 45.4458],
            [-104.5347, 45.2871],
            [-104.6621, 45.263],
            [-104.6659, 45.0923],
            [-104.9143, 45.0929],
            [-104.9385, 45.1874],
            [-105.0383, 45.187],
            [-105.0383, 45.2717],
            [-104.9775, 45.3526],
            [-104.8579, 45.3527],
            [-104.858, 45.4125],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013280',
        NAME: 'Hawks Home Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1198914903,
        AWATER: 3766560,
        INTPTLAT: '+45.3109331',
        INTPTLON: '-104.7496592',
        ELSDLEA: '13280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8431, 46.8323],
            [-113.7309, 46.831],
            [-113.6596, 46.7882],
            [-113.4799, 46.7882],
            [-113.668, 46.6584],
            [-113.8277, 46.6609],
            [-113.854, 46.661],
            [-113.8431, 46.8323],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006870',
        NAME: 'Clinton Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398777805,
        AWATER: 1890124,
        INTPTLAT: '+46.7543251',
        INTPTLON: '-113.7519103',
        ELSDLEA: '06870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2101, 46.9359],
            [-114.1454, 46.9432],
            [-114.065, 46.9314],
            [-113.9804, 46.9972],
            [-113.9818, 46.9222],
            [-114.09, 46.8673],
            [-114.2101, 46.9359],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013860',
        NAME: 'Hellgate Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96075089,
        AWATER: 1077457,
        INTPTLAT: '+46.9182719',
        INTPTLON: '-114.0773901',
        ELSDLEA: '13860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1249, 46.8332],
            [-114.09, 46.8673],
            [-113.9818, 46.9222],
            [-113.9804, 46.9972],
            [-113.9792, 47.0495],
            [-113.935, 47.049],
            [-113.8493, 47.0082],
            [-113.8431, 46.8323],
            [-113.854, 46.661],
            [-113.9626, 46.6608],
            [-114.0553, 46.7331],
            [-114.1254, 46.8082],
            [-114.1249, 46.8332],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018570',
        NAME: 'Missoula Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460495330,
        AWATER: 2283310,
        INTPTLAT: '+46.8488584',
        INTPTLON: '-113.9559626',
        ELSDLEA: '18570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.935, 47.049],
            [-113.6865, 47.0474],
            [-113.5645, 46.9488],
            [-113.4669, 46.9351],
            [-113.457, 46.8027],
            [-113.4799, 46.7882],
            [-113.6596, 46.7882],
            [-113.7309, 46.831],
            [-113.7295, 47.0075],
            [-113.8493, 47.0082],
            [-113.935, 47.049],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021330',
        NAME: 'Potomac Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490046994,
        AWATER: 1782804,
        INTPTLAT: '+46.9198144',
        INTPTLON: '-113.5777091',
        ELSDLEA: '21330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.7509, 47.2903],
            [-113.5363, 47.4254],
            [-113.4664, 47.3463],
            [-113.4664, 47.1792],
            [-113.3029, 47.1796],
            [-113.3028, 47.0922],
            [-113.6865, 47.0474],
            [-113.935, 47.049],
            [-113.9792, 47.0495],
            [-113.7324, 47.1172],
            [-113.6948, 47.2076],
            [-113.7509, 47.2903],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023730',
        NAME: 'Seeley Lake Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 846243750,
        AWATER: 18418111,
        INTPTLAT: '+47.2031573',
        INTPTLON: '-113.5755686',
        ELSDLEA: '23730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.6865, 47.0474],
            [-113.3028, 47.0922],
            [-113.3031, 46.9673],
            [-113.3029, 46.8322],
            [-113.457, 46.8027],
            [-113.4669, 46.9351],
            [-113.5645, 46.9488],
            [-113.6865, 47.0474],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025380',
        NAME: 'Sunset Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 533603653,
        AWATER: 3522454,
        INTPTLAT: '+47.0180403',
        INTPTLON: '-113.5159499',
        ELSDLEA: '25380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.9487, 47.6],
            [-113.8503, 47.6002],
            [-113.7109, 47.6001],
            [-113.6341, 47.6],
            [-113.4665, 47.6001],
            [-113.4664, 47.3463],
            [-113.5363, 47.4254],
            [-113.7509, 47.2903],
            [-113.8243, 47.2744],
            [-113.8708, 47.2882],
            [-113.8949, 47.4337],
            [-113.9429, 47.4797],
            [-113.9487, 47.6],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025530',
        NAME: 'Swan Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 944617459,
        AWATER: 21092128,
        INTPTLAT: '+47.4717483',
        INTPTLON: '-113.7088130',
        ELSDLEA: '25530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3761, 47.009],
            [-114.2725, 47.0081],
            [-114.2101, 46.9359],
            [-114.09, 46.8673],
            [-114.1249, 46.8332],
            [-114.3775, 46.8334],
            [-114.3761, 47.009],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025890',
        NAME: 'Target Range Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276834059,
        AWATER: 1810792,
        INTPTLAT: '+46.8758788',
        INTPTLON: '-114.2334159',
        ELSDLEA: '25890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3775, 46.8334],
            [-114.1249, 46.8332],
            [-114.1254, 46.8082],
            [-114.1457, 46.7186],
            [-114.1708, 46.6604],
            [-114.3328, 46.6606],
            [-114.5948, 46.6335],
            [-114.6749, 46.7368],
            [-114.5491, 46.7474],
            [-114.549, 46.8336],
            [-114.3775, 46.8334],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028650',
        NAME: 'Woodman Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 679756685,
        AWATER: 397744,
        INTPTLAT: '+46.7539344',
        INTPTLON: '-114.3658949',
        ELSDLEA: '28650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9847, 47.9539],
            [-112.9133, 47.8262],
            [-112.9139, 47.6972],
            [-112.8547, 47.6292],
            [-112.5687, 47.636],
            [-112.4327, 47.6061],
            [-112.3044, 47.5265],
            [-112.1369, 47.5137],
            [-112.0478, 47.516],
            [-112.0457, 47.3516],
            [-112.0448, 47.1927],
            [-111.9201, 47.1357],
            [-112.1177, 47.1122],
            [-112.1185, 47.2479],
            [-112.1752, 47.2482],
            [-112.175, 47.3418],
            [-112.3052, 47.3845],
            [-112.3064, 47.2534],
            [-112.56, 47.2648],
            [-112.5616, 47.1747],
            [-112.6126, 47.2231],
            [-112.7464, 47.2475],
            [-112.9337, 47.3458],
            [-112.9242, 47.3874],
            [-113.0592, 47.4886],
            [-113.1444, 47.5955],
            [-113.1658, 47.7198],
            [-113.081, 47.7499],
            [-113.0629, 47.8856],
            [-112.9847, 47.9539],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002430',
        NAME: 'Augusta Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3045040324,
        AWATER: 22910563,
        INTPTLAT: '+47.4820523',
        INTPTLON: '-112.6154142',
        ELSDLEA: '02430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2982, 46.6181],
            [-112.1102, 46.6453],
            [-112.1098, 46.7848],
            [-111.9208, 46.7938],
            [-111.9227, 46.8907],
            [-111.6166, 46.8983],
            [-111.6228, 46.8294],
            [-111.7269, 46.8366],
            [-111.8543, 46.7235],
            [-111.8748, 46.6218],
            [-111.9592, 46.5692],
            [-112.0752, 46.52],
            [-112.1838, 46.5011],
            [-112.2427, 46.4225],
            [-112.2871, 46.4194],
            [-112.3106, 46.4212],
            [-112.2982, 46.6181],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000005',
        NAME: 'Helena Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 949391430,
        AWATER: 17380222,
        INTPTLAT: '+46.6775978',
        INTPTLON: '-112.0077700',
        ELSDLEA: '00005',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8493, 47.0082],
            [-113.7295, 47.0075],
            [-113.7309, 46.831],
            [-113.8431, 46.8323],
            [-113.8493, 47.0082],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004260',
        NAME: 'Bonner Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222347775,
        AWATER: 1772753,
        INTPTLAT: '+46.9082166',
        INTPTLON: '-113.7982144',
        ELSDLEA: '04260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1254, 46.8082],
            [-114.0553, 46.7331],
            [-114.1457, 46.7186],
            [-114.1254, 46.8082],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017130',
        NAME: 'Lolo Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46442012,
        AWATER: 473392,
        INTPTLAT: '+46.7718538',
        INTPTLON: '-114.1084488',
        ELSDLEA: '17130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.9792, 47.0495],
            [-113.9804, 46.9972],
            [-114.065, 46.9314],
            [-114.1454, 46.9432],
            [-113.9792, 47.0495],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008880',
        NAME: 'De Smet Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69678488,
        AWATER: 38709,
        INTPTLAT: '+46.9753281',
        INTPTLON: '-114.0533104',
        ELSDLEA: '08880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1752, 47.2482],
            [-112.3064, 47.2534],
            [-112.3052, 47.3845],
            [-112.175, 47.3418],
            [-112.1752, 47.2482],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002490',
        NAME: 'Auchard Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191239579,
        AWATER: 283937,
        INTPTLAT: '+47.3043431',
        INTPTLON: '-112.2125133',
        ELSDLEA: '02490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9592, 46.5692],
            [-111.8748, 46.6218],
            [-111.8543, 46.7235],
            [-111.7269, 46.8366],
            [-111.6228, 46.8294],
            [-111.4981, 46.7625],
            [-111.6393, 46.7382],
            [-111.6401, 46.5825],
            [-111.7879, 46.5695],
            [-111.9123, 46.5694],
            [-111.9592, 46.5692],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009560',
        NAME: 'East Helena Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491593204,
        AWATER: 35428750,
        INTPTLAT: '+46.6921077',
        INTPTLON: '-111.7704786',
        ELSDLEA: '09560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5357, 46.8342],
            [-112.4499, 46.9521],
            [-112.3169, 47.0153],
            [-112.2988, 46.8831],
            [-112.1757, 46.854],
            [-112.1098, 46.7848],
            [-112.1102, 46.6453],
            [-112.2982, 46.6181],
            [-112.4106, 46.656],
            [-112.5356, 46.6995],
            [-112.5357, 46.8342],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026490',
        NAME: 'Trinity Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 856265256,
        AWATER: 820898,
        INTPTLAT: '+46.7868577',
        INTPTLON: '-112.3281271',
        ELSDLEA: '26490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.3064, 47.2534],
            [-112.1752, 47.2482],
            [-112.1185, 47.2479],
            [-112.1177, 47.1122],
            [-111.9201, 47.1357],
            [-111.7893, 47.1289],
            [-111.7902, 46.9138],
            [-111.6581, 46.9134],
            [-111.6166, 46.8983],
            [-111.9227, 46.8907],
            [-111.9208, 46.7938],
            [-112.1098, 46.7848],
            [-112.1757, 46.854],
            [-112.2988, 46.8831],
            [-112.3169, 47.0153],
            [-112.4301, 47.1534],
            [-112.5616, 47.1747],
            [-112.56, 47.2648],
            [-112.3064, 47.2534],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028550',
        NAME: 'Wolf Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1738121311,
        AWATER: 20229130,
        INTPTLAT: '+47.0374719',
        INTPTLON: '-112.0918838',
        ELSDLEA: '28550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1083, 48.0062],
            [-106.0062, 48.0337],
            [-105.8527, 48.0084],
            [-105.8636, 47.7977],
            [-105.7854, 47.7082],
            [-105.552, 47.7065],
            [-105.3634, 47.665],
            [-105.3635, 47.5296],
            [-105.4066, 47.5295],
            [-105.4063, 47.1816],
            [-105.833, 47.1811],
            [-105.8326, 47.0931],
            [-105.9599, 47.093],
            [-105.9598, 47.181],
            [-106.0868, 47.1809],
            [-106.1704, 47.1808],
            [-106.1707, 47.3545],
            [-106.2982, 47.3543],
            [-106.2611, 47.531],
            [-106.2609, 47.8684],
            [-106.3581, 47.8684],
            [-106.4186, 47.9575],
            [-106.3942, 48.0647],
            [-106.1083, 48.0062],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006790',
        NAME: 'Circle Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5054328404,
        AWATER: 91978581,
        INTPTLAT: '+47.5426307',
        INTPTLON: '-105.8888241',
        ELSDLEA: '06790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8527, 48.0084],
            [-105.8439, 48.0103],
            [-105.5821, 48.08],
            [-105.452, 48.0894],
            [-105.229, 48.0893],
            [-105.1948, 48.0666],
            [-105.1948, 47.9925],
            [-105.1949, 47.9696],
            [-105.1949, 47.9551],
            [-105.195, 47.9113],
            [-105.1951, 47.8876],
            [-105.2348, 47.7947],
            [-105.2348, 47.7905],
            [-105.3633, 47.7904],
            [-105.3634, 47.665],
            [-105.552, 47.7065],
            [-105.7854, 47.7082],
            [-105.8636, 47.7977],
            [-105.8527, 48.0084],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027340',
        NAME: 'Vida Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1789274217,
        AWATER: 11114548,
        INTPTLAT: '+47.9611533',
        INTPTLON: '-105.5711479',
        ELSDLEA: '27340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-105.195, 47.9113],
              [-105.176, 47.9111],
              [-105.1553, 47.911],
              [-105.1672, 47.9004],
              [-105.1739, 47.8876],
              [-105.1951, 47.8876],
              [-105.195, 47.9113],
            ],
          ],
          [
            [
              [-105.3634, 47.665],
              [-105.3633, 47.7904],
              [-105.2348, 47.7905],
              [-105.2348, 47.7947],
              [-105.1488, 47.8501],
              [-105.1739, 47.8876],
              [-105.1548, 47.8877],
              [-105.1326, 47.8877],
              [-105.1121, 47.902],
              [-105.1099, 47.9184],
              [-105.0898, 47.9185],
              [-105.0898, 47.9161],
              [-105.0653, 47.9021],
              [-105.0652, 47.8974],
              [-105.0391, 47.911],
              [-105.0253, 47.9108],
              [-105.0071, 47.8338],
              [-104.9778, 47.7955],
              [-104.9778, 47.7352],
              [-105.0212, 47.7034],
              [-104.9852, 47.7033],
              [-104.9786, 47.7203],
              [-104.9559, 47.7205],
              [-104.9781, 47.7033],
              [-104.9576, 47.6163],
              [-104.8722, 47.6161],
              [-104.5937, 47.6163],
              [-104.594, 47.5293],
              [-104.5936, 47.4855],
              [-104.9172, 47.4998],
              [-105.2007, 47.4734],
              [-105.2415, 47.5301],
              [-105.3635, 47.5296],
              [-105.3634, 47.665],
            ],
            [
              [-105.1488, 47.8501],
              [-105.1051, 47.8547],
              [-105.1053, 47.8674],
              [-105.1488, 47.8501],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022370',
        NAME: 'Richey Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1488721729,
        AWATER: 1320140,
        INTPTLAT: '+47.6154945',
        INTPTLON: '-105.0383681',
        ELSDLEA: '22370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4084, 47.9242],
            [-111.2679, 47.8779],
            [-111.1581, 47.908],
            [-111.1572, 47.8775],
            [-111.155, 47.7851],
            [-111.2189, 47.6977],
            [-111.4089, 47.6981],
            [-111.4087, 47.7846],
            [-111.4084, 47.9242],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003480',
        NAME: 'Benton Lake Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376160241,
        AWATER: 30171,
        INTPTLAT: '+47.7999846',
        INTPTLON: '-111.2932091',
        ELSDLEA: '03480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6255, 48.2197],
            [-110.6253, 48.3065],
            [-110.3674, 48.3064],
            [-110.0191, 48.3061],
            [-109.8458, 48.25],
            [-109.8458, 48.2191],
            [-109.8397, 48.219],
            [-109.8177, 48.1473],
            [-109.8172, 48.04],
            [-109.7035, 47.9998],
            [-109.5334, 47.9906],
            [-109.5388, 47.7662],
            [-109.5387, 47.7389],
            [-109.6775, 47.7347],
            [-109.8297, 47.7153],
            [-110.0662, 47.8214],
            [-110.0564, 47.9232],
            [-110.1227, 48.0172],
            [-110.3763, 47.995],
            [-110.417, 47.9586],
            [-110.5032, 48],
            [-110.6514, 48.0119],
            [-110.6255, 48.2197],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003760',
        NAME: 'Big Sandy Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3149653631,
        AWATER: 15381836,
        INTPTLAT: '+48.0441167',
        INTPTLON: '-110.0735764',
        ELSDLEA: '03760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1572, 47.8775],
            [-110.8763, 47.8604],
            [-110.8743, 47.7612],
            [-110.9764, 47.6986],
            [-111.2189, 47.6977],
            [-111.155, 47.7851],
            [-111.1572, 47.8775],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005760',
        NAME: 'Carter Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411199288,
        AWATER: 1156811,
        INTPTLAT: '+47.7714853',
        INTPTLON: '-111.0483364',
        ELSDLEA: '05760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.1437, 48.1329],
            [-110.8272, 48.1332],
            [-110.827, 48.2197],
            [-110.6255, 48.2197],
            [-110.6514, 48.0119],
            [-110.5032, 48],
            [-110.417, 47.9586],
            [-110.4292, 47.7425],
            [-110.5455, 47.6985],
            [-110.7693, 47.6984],
            [-110.8743, 47.7612],
            [-110.8763, 47.8604],
            [-111.1572, 47.8775],
            [-111.1581, 47.908],
            [-110.9919, 47.9449],
            [-110.9935, 48.0316],
            [-111.1439, 48.0318],
            [-111.1437, 48.1329],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011240',
        NAME: 'Fort Benton Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1847452495,
        AWATER: 16973438,
        INTPTLAT: '+47.9552577',
        INTPTLON: '-110.7861661',
        ELSDLEA: '11240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4091, 48.1322],
            [-111.1437, 48.1329],
            [-111.1439, 48.0318],
            [-110.9935, 48.0316],
            [-110.9919, 47.9449],
            [-111.1581, 47.908],
            [-111.2679, 47.8779],
            [-111.4084, 47.9242],
            [-111.4091, 48.1322],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3015690',
        NAME: 'Knees Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 613424138,
        AWATER: 512176,
        INTPTLAT: '+48.0162941',
        INTPTLON: '-111.2383162',
        ELSDLEA: '15690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0191, 48.3061],
            [-109.8698, 48.3059],
            [-109.8698, 48.306],
            [-109.7352, 48.314],
            [-109.7139, 48.2199],
            [-109.6177, 48.2219],
            [-109.6177, 48.1483],
            [-109.7269, 48.1473],
            [-109.8177, 48.1473],
            [-109.8397, 48.219],
            [-109.8458, 48.2191],
            [-109.8458, 48.25],
            [-110.0191, 48.3061],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022750',
        NAME: 'Rocky Boy Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343024154,
        AWATER: 325191,
        INTPTLAT: '+48.2326611',
        INTPTLON: '-109.8013893',
        ELSDLEA: '22750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8177, 48.1473],
            [-109.7269, 48.1473],
            [-109.5343, 48.1346],
            [-109.5334, 47.9906],
            [-109.7035, 47.9998],
            [-109.8172, 48.04],
            [-109.8177, 48.1473],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027480',
        NAME: 'Warrick Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288210650,
        AWATER: 160964,
        INTPTLAT: '+48.0995819',
        INTPTLON: '-109.6848862',
        ELSDLEA: '27480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.7953, 46.8317],
            [-112.5357, 46.8342],
            [-112.5356, 46.6995],
            [-112.4106, 46.656],
            [-112.4762, 46.6414],
            [-112.535, 46.483],
            [-112.6904, 46.595],
            [-112.7944, 46.5949],
            [-112.7948, 46.7184],
            [-112.7953, 46.8317],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002550',
        NAME: 'Avon Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 725594518,
        AWATER: 1652226,
        INTPTLAT: '+46.6830765',
        INTPTLON: '-112.6320069',
        ELSDLEA: '02550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.535, 46.483],
            [-112.4762, 46.6414],
            [-112.4106, 46.656],
            [-112.2982, 46.6181],
            [-112.3106, 46.4212],
            [-112.4986, 46.3421],
            [-112.535, 46.483],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009780',
        NAME: 'Elliston Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481629581,
        AWATER: 29048,
        INTPTLAT: '+46.5089121',
        INTPTLON: '-112.4348375',
        ELSDLEA: '09780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.8812, 46.7186],
            [-112.8784, 46.485],
            [-113.0367, 46.3597],
            [-113.0385, 46.547],
            [-113.0387, 46.7355],
            [-112.8812, 46.7186],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012570',
        NAME: 'Gold Creek Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434592079,
        AWATER: 951630,
        INTPTLAT: '+46.5712494',
        INTPTLON: '-112.9720580',
        ELSDLEA: '12570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.3031, 46.9673],
            [-112.7961, 46.9685],
            [-112.7953, 46.8317],
            [-112.7948, 46.7184],
            [-112.8812, 46.7186],
            [-113.0387, 46.7355],
            [-113.1156, 46.7134],
            [-113.3029, 46.8322],
            [-113.3031, 46.9673],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013890',
        NAME: 'Helmville Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 957400202,
        AWATER: 3377507,
        INTPTLAT: '+46.8539519',
        INTPTLON: '-113.0377871',
        ELSDLEA: '13890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.4665, 47.6001],
            [-113.1444, 47.5955],
            [-113.0592, 47.4886],
            [-113.0591, 47.1796],
            [-112.7958, 47.177],
            [-112.7961, 46.9685],
            [-113.3031, 46.9673],
            [-113.3028, 47.0922],
            [-113.3029, 47.1796],
            [-113.4664, 47.1792],
            [-113.4664, 47.3463],
            [-113.4665, 47.6001],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3019950',
        NAME: 'Ovando Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2253353395,
        AWATER: 8334084,
        INTPTLAT: '+47.2756178',
        INTPTLON: '-113.2341867',
        ELSDLEA: '19950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.8812, 46.7186],
            [-112.7948, 46.7184],
            [-112.7944, 46.5949],
            [-112.795, 46.4839],
            [-112.8784, 46.485],
            [-112.8812, 46.7186],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021480',
        NAME: 'Garrison Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186319736,
        AWATER: 7066,
        INTPTLAT: '+46.6129802',
        INTPTLON: '-112.8243947',
        ELSDLEA: '21480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3221, 48.0489],
            [-114.278, 48.1682],
            [-114.1682, 48.1678],
            [-114.1208, 48.1138],
            [-114.1101, 47.9625],
            [-114.1878, 47.9623],
            [-114.3218, 47.9622],
            [-114.3221, 48.0489],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000002',
        NAME: 'Somers Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208107261,
        AWATER: 97947027,
        INTPTLAT: '+48.0382782',
        INTPTLON: '-114.2165160',
        ELSDLEA: '00002',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.7456, 48.6526],
            [-113.7505, 48.6143],
            [-113.4674, 48.5476],
            [-113.4667, 48.4756],
            [-113.356, 48.4239],
            [-113.349, 48.3103],
            [-113.2288, 48.2227],
            [-113.6991, 48.2258],
            [-113.8284, 48.4231],
            [-114.0671, 48.4577],
            [-114.105, 48.6595],
            [-113.7456, 48.6526],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000094',
        NAME: 'West Glacier Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1618821896,
        AWATER: 40049440,
        INTPTLAT: '+48.4517763',
        INTPTLON: '-113.5923854',
        ELSDLEA: '00094',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1208, 48.1138],
            [-114.0622, 48.168],
            [-114.0405, 48.1401],
            [-114.0514, 48.0614],
            [-113.9424, 48.0516],
            [-113.7955, 47.9698],
            [-113.944, 47.9682],
            [-113.9414, 47.8847],
            [-114.0373, 47.8846],
            [-114.0629, 47.9625],
            [-114.1101, 47.9625],
            [-114.1208, 48.1138],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003820',
        NAME: 'Bigfork Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268195460,
        AWATER: 72837118,
        INTPTLAT: '+48.0104445',
        INTPTLON: '-114.0255558',
        ELSDLEA: '03820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1645, 48.2696],
            [-114.0446, 48.2546],
            [-113.9926, 48.2185],
            [-113.9922, 48.2185],
            [-113.9448, 48.1395],
            [-114.0405, 48.1401],
            [-114.0622, 48.168],
            [-114.1591, 48.2111],
            [-114.1645, 48.2696],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3005990',
        NAME: 'Cayuse Prairie Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141304242,
        AWATER: 1969563,
        INTPTLAT: '+48.2085487',
        INTPTLON: '-114.0637242',
        ELSDLEA: '05990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.727, 49.0005],
            [-114.0682, 48.9989],
            [-114.0311, 48.8799],
            [-113.9569, 48.8175],
            [-113.8184, 48.8482],
            [-113.7029, 48.7199],
            [-113.7456, 48.6526],
            [-114.105, 48.6595],
            [-114.0671, 48.4577],
            [-113.8284, 48.4231],
            [-113.6991, 48.2258],
            [-113.2288, 48.2227],
            [-113.1308, 48.1401],
            [-113.0148, 48.131],
            [-112.952, 48.0447],
            [-112.9075, 47.9557],
            [-112.9847, 47.9539],
            [-113.0629, 47.8856],
            [-113.081, 47.7499],
            [-113.1658, 47.7198],
            [-113.1444, 47.5955],
            [-113.4665, 47.6001],
            [-113.6341, 47.6],
            [-113.6723, 47.8965],
            [-113.7955, 47.9698],
            [-113.9424, 48.0516],
            [-113.9448, 48.1395],
            [-113.9922, 48.2185],
            [-113.9926, 48.2185],
            [-114.0446, 48.2546],
            [-114.0802, 48.309],
            [-114.222, 48.2692],
            [-114.2535, 48.2947],
            [-114.2866, 48.3057],
            [-114.2518, 48.3925],
            [-114.2518, 48.5733],
            [-114.4263, 48.5734],
            [-114.4318, 48.7455],
            [-114.6191, 48.7456],
            [-114.7275, 48.8212],
            [-114.6894, 48.8639],
            [-114.727, 49.0005],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007110',
        NAME: 'Columbia Falls Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6786320458,
        AWATER: 160281259,
        INTPTLAT: '+48.3351995',
        INTPTLON: '-113.7991449',
        ELSDLEA: '07110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.5242, 48.2259],
            [-114.3951, 48.204],
            [-114.3951, 48.1531],
            [-114.5239, 48.1529],
            [-114.5242, 48.2259],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002850',
        NAME: 'Smith Valley Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71232956,
        AWATER: 71944,
        INTPTLAT: '+48.1869055',
        INTPTLON: '-114.4592414',
        ELSDLEA: '02850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1591, 48.2111],
            [-114.0622, 48.168],
            [-114.1208, 48.1138],
            [-114.1682, 48.1678],
            [-114.1591, 48.2111],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007710',
        NAME: 'Creston Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39662382,
        AWATER: 2269651,
        INTPTLAT: '+48.1729603',
        INTPTLON: '-114.1212754',
        ELSDLEA: '07710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.513, 47.5294],
            [-104.5106, 47.5729],
            [-104.5074, 47.689],
            [-104.4652, 47.7039],
            [-104.2102, 47.7326],
            [-104.1651, 47.7904],
            [-104.0425, 47.807],
            [-104.0425, 47.8068],
            [-104.0432, 47.7611],
            [-104.2704, 47.553],
            [-104.3344, 47.5475],
            [-104.3969, 47.5002],
            [-104.513, 47.5157],
            [-104.513, 47.5294],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024200',
        NAME: 'Sidney Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 529714598,
        AWATER: 6990600,
        INTPTLAT: '+47.6360430',
        INTPTLON: '-104.3679893',
        ELSDLEA: '24200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.349, 48.3103],
            [-112.9674, 48.3099],
            [-112.5812, 48.3099],
            [-112.6305, 48.2443],
            [-112.7474, 48.2305],
            [-112.9007, 48.131],
            [-113.0148, 48.131],
            [-113.1308, 48.1401],
            [-113.2288, 48.2227],
            [-113.349, 48.3103],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000099',
        NAME: 'Heart Butte K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 727869520,
        AWATER: 3813895,
        INTPTLAT: '+48.2134104',
        INTPTLON: '-113.0927582',
        ELSDLEA: '',
        UNSDLEA: '00099',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6656, 48.2195],
            [-111.409, 48.2195],
            [-111.4091, 48.1322],
            [-111.4084, 47.9242],
            [-111.4087, 47.7846],
            [-111.6671, 47.785],
            [-111.746, 47.8136],
            [-111.9235, 47.814],
            [-111.9192, 47.9844],
            [-111.9843, 47.9848],
            [-112.0272, 48.0429],
            [-112.0059, 48.1014],
            [-111.7745, 48.1156],
            [-111.6663, 48.0886],
            [-111.6656, 48.2195],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000102',
        NAME: 'Dutton/Brady K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1616263191,
        AWATER: 1301550,
        INTPTLAT: '+47.9797601',
        INTPTLON: '-111.6131072',
        ELSDLEA: '',
        UNSDLEA: '00102',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.35, 45.3822],
            [-111.1655, 45.437],
            [-111.1661, 45.3504],
            [-111.0382, 45.3514],
            [-111.0383, 45.2609],
            [-111.0451, 45.0009],
            [-111.0546, 45.001],
            [-111.3725, 45.0026],
            [-111.373, 45.3505],
            [-111.35, 45.3822],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3000654',
        NAME: 'Big Sky K-12 Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1114175834,
        AWATER: 3036479,
        INTPTLAT: '+45.1777253',
        INTPTLON: '-111.2145942',
        ELSDLEA: '',
        UNSDLEA: '00654',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.925, 46.8466],
            [-114.7826, 46.8463],
            [-114.7563, 47.0073],
            [-114.6722, 47.0651],
            [-114.6914, 47.1672],
            [-114.5867, 47.1238],
            [-114.5661, 47.0674],
            [-114.3761, 47.009],
            [-114.3775, 46.8334],
            [-114.549, 46.8336],
            [-114.5491, 46.7474],
            [-114.6749, 46.7368],
            [-114.7652, 46.7583],
            [-114.925, 46.8466],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3001860',
        NAME: 'Alberton K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1001755818,
        AWATER: 2652061,
        INTPTLAT: '+46.9129553',
        INTPTLON: '-114.6080160',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.2706, 48.3023],
            [-104.2595, 48.3894],
            [-104.047, 48.3893],
            [-104.0468, 48.3526],
            [-104.0443, 48.0248],
            [-104.0441, 47.9961],
            [-104.1672, 48.0355],
            [-104.3226, 48.0452],
            [-104.3248, 48.259],
            [-104.2706, 48.3023],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002640',
        NAME: 'Bainville K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 737078554,
        AWATER: 6939653,
        INTPTLAT: '+48.2223864',
        INTPTLON: '-104.1430666',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5161, 46.1362],
            [-104.448, 46.2086],
            [-104.4461, 46.455],
            [-104.5018, 46.569],
            [-104.3548, 46.5689],
            [-104.3539, 46.6414],
            [-104.0454, 46.6415],
            [-104.0452, 46.5409],
            [-104.0458, 46.1191],
            [-104.0455, 45.9453],
            [-104.0442, 45.882],
            [-104.1348, 45.8819],
            [-104.1348, 45.9688],
            [-104.3909, 45.9704],
            [-104.3907, 46.0548],
            [-104.5161, 46.0548],
            [-104.5161, 46.1362],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3002730',
        NAME: 'Baker K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2334540524,
        AWATER: 4152282,
        INTPTLAT: '+46.2577616',
        INTPTLON: '-104.2255865',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1613, 45.1859],
            [-108.8445, 45.1791],
            [-108.8457, 45],
            [-109.1947, 45.0057],
            [-109.1613, 45.1859],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3003270',
        NAME: 'Belfry K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 563115205,
        AWATER: 2186225,
        INTPTLAT: '+45.0984393',
        INTPTLON: '-109.0152634',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1403, 45.2697],
            [-109.0207, 45.3464],
            [-108.939, 45.3471],
            [-108.6996, 45.3446],
            [-108.6996, 45.2177],
            [-108.1049, 45.2205],
            [-108.2498, 45.0316],
            [-108.2494, 44.9999],
            [-108.6839, 45.0003],
            [-108.8457, 45],
            [-108.8445, 45.1791],
            [-109.1613, 45.1859],
            [-109.1403, 45.2697],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004800',
        NAME: 'Bridger K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1738240519,
        AWATER: 6950088,
        INTPTLAT: '+45.1564270',
        INTPTLON: '-108.6304222',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7879, 46.5695],
            [-111.6401, 46.5825],
            [-111.6393, 46.7382],
            [-111.4981, 46.7625],
            [-111.3202, 46.5694],
            [-111.2465, 46.4381],
            [-111.1495, 46.4507],
            [-111.057, 46.3981],
            [-111.1181, 46.317],
            [-111.062, 46.1928],
            [-111.2421, 46.1878],
            [-111.3056, 46.1878],
            [-111.4238, 46.062],
            [-111.4647, 45.9767],
            [-111.6603, 46.0055],
            [-111.7843, 46.0498],
            [-111.7842, 46.0828],
            [-111.7863, 46.3146],
            [-111.7879, 46.5695],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3004980',
        NAME: 'Townsend K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2961926035,
        AWATER: 118397914,
        INTPTLAT: '+46.3459646',
        INTPTLON: '-111.4919778',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.203, 47.3557],
            [-114.1077, 47.4339],
            [-113.8949, 47.4337],
            [-113.8708, 47.2882],
            [-113.8243, 47.2744],
            [-113.8213, 47.2417],
            [-114.2031, 47.2562],
            [-114.203, 47.3557],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3006110',
        NAME: 'St. Ignatius K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468098521,
        AWATER: 5783220,
        INTPTLAT: '+47.3329503',
        INTPTLON: '-114.0197476',
        ELSDLEA: '',
        UNSDLEA: '06110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.4142, 46.343],
            [-114.1623, 46.342],
            [-114.0257, 46.3995],
            [-113.8166, 46.3991],
            [-113.7782, 46.2369],
            [-114.0715, 46.2405],
            [-114.1554, 46.2876],
            [-114.3705, 46.2771],
            [-114.4543, 46.2415],
            [-114.4142, 46.343],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3007410',
        NAME: 'Corvallis K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 631815661,
        AWATER: 1334024,
        INTPTLAT: '+46.3069915',
        INTPTLON: '-114.0542175',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9285, 46.3953],
            [-107.7821, 46.3952],
            [-107.7385, 46.4818],
            [-107.6124, 46.3081],
            [-107.5286, 46.2502],
            [-107.467, 46.13],
            [-107.5115, 46.0434],
            [-107.6745, 46.0436],
            [-107.6745, 45.9858],
            [-107.798, 45.9857],
            [-107.8408, 46.0417],
            [-107.7792, 46.2065],
            [-107.8631, 46.2499],
            [-108.0294, 46.2641],
            [-107.9285, 46.3953],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008130',
        NAME: 'Custer K-12 Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1167457524,
        AWATER: 9810092,
        INTPTLAT: '+46.2784256',
        INTPTLON: '-107.8071138',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.4452, 46.1852],
            [-114.1691, 46.1848],
            [-114.1393, 46.09],
            [-113.7716, 46.0893],
            [-113.6484, 45.965],
            [-113.5174, 45.9401],
            [-113.5161, 45.9388],
            [-113.5324, 45.8784],
            [-113.7125, 45.8384],
            [-113.9381, 45.6957],
            [-114.2699, 45.4846],
            [-114.3442, 45.4597],
            [-114.4399, 45.537],
            [-114.5649, 45.5577],
            [-114.4997, 45.6685],
            [-114.5663, 45.7727],
            [-114.5003, 45.85],
            [-114.4059, 45.8704],
            [-114.4118, 45.9779],
            [-114.4805, 46.0304],
            [-114.4452, 46.1852],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3008280',
        NAME: 'Darby K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3549332930,
        AWATER: 14964509,
        INTPTLAT: '+45.8592371',
        INTPTLON: '-114.1484564',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-108.4112, 48.2231],
              [-108.4112, 48.445],
              [-108.3463, 48.4228],
              [-108.319, 48.5684],
              [-108.3191, 48.7422],
              [-108.2594, 48.7422],
              [-108.2364, 48.9136],
              [-108.1278, 48.9133],
              [-108.1288, 48.7416],
              [-108.0192, 48.741],
              [-108.0192, 48.5979],
              [-107.8832, 48.5983],
              [-107.883, 48.4801],
              [-108.0211, 48.4801],
              [-108.1481, 48.3535],
              [-108.1715, 48.0517],
              [-108.4339, 48.0473],
              [-108.4112, 48.2231],
            ],
          ],
          [
            [
              [-108.8852, 47.9227],
              [-108.6257, 47.9204],
              [-108.6054, 47.8691],
              [-108.6051, 47.6233],
              [-108.6689, 47.6191],
              [-108.8911, 47.7345],
              [-108.8852, 47.9227],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009120',
        NAME: 'Dodson K-12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2539765031,
        AWATER: 14733311,
        INTPTLAT: '+48.2931093',
        INTPTLON: '-108.3732981',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.043, 45.348],
            [-111.9716, 45.348],
            [-111.9729, 45.5412],
            [-111.736, 45.54],
            [-111.5432, 45.5114],
            [-111.5429, 45.3905],
            [-111.3501, 45.3905],
            [-111.35, 45.3822],
            [-111.373, 45.3505],
            [-111.3725, 45.0026],
            [-111.377, 44.7514],
            [-111.475, 44.7088],
            [-111.7811, 44.7088],
            [-111.7812, 44.781],
            [-111.9117, 44.7821],
            [-111.9176, 45.0031],
            [-111.8572, 45.003],
            [-111.8904, 45.1682],
            [-112.0345, 45.1697],
            [-112.043, 45.348],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3009930',
        NAME: 'Ennis K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3754882412,
        AWATER: 30729528,
        INTPTLAT: '+45.1447972',
        INTPTLON: '-111.6893574',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3328, 46.6606],
            [-114.1708, 46.6604],
            [-114.1457, 46.7186],
            [-114.0553, 46.7331],
            [-113.9626, 46.6608],
            [-113.854, 46.661],
            [-113.8277, 46.6609],
            [-113.8152, 46.6162],
            [-114.0511, 46.6049],
            [-114.1093, 46.5718],
            [-114.3308, 46.5738],
            [-114.3328, 46.6606],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011100',
        NAME: 'Florence-Carlton K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402205533,
        AWATER: 2004401,
        INTPTLAT: '+46.6499536',
        INTPTLON: '-114.1299855',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.7972, 47.2689],
            [-114.6872, 47.2743],
            [-114.4825, 47.22],
            [-114.2536, 47.121],
            [-114.1872, 47.1382],
            [-114.1049, 47.0505],
            [-113.9792, 47.0495],
            [-114.1454, 46.9432],
            [-114.2101, 46.9359],
            [-114.2725, 47.0081],
            [-114.3761, 47.009],
            [-114.5661, 47.0674],
            [-114.5867, 47.1238],
            [-114.6914, 47.1672],
            [-114.7972, 47.2689],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011520',
        NAME: 'Frenchtown K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 879763728,
        AWATER: 6313333,
        INTPTLAT: '+47.1924816',
        INTPTLON: '-114.4814516',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6989, 45.5083],
            [-108.6453, 45.4641],
            [-108.6996, 45.3446],
            [-108.939, 45.3471],
            [-108.9479, 45.3723],
            [-108.9431, 45.4823],
            [-108.6989, 45.5083],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3011650',
        NAME: 'Fromberg K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349235406,
        AWATER: 2403082,
        INTPTLAT: '+45.4257763',
        INTPTLON: '-108.8163845',
        ELSDLEA: '',
        UNSDLEA: '11650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.5455, 47.6985],
            [-110.4292, 47.7425],
            [-110.417, 47.9586],
            [-110.3763, 47.995],
            [-110.1227, 48.0172],
            [-110.0564, 47.9232],
            [-110.0662, 47.8214],
            [-109.8297, 47.7153],
            [-109.8452, 47.5468],
            [-110.1584, 47.4177],
            [-110.2136, 47.4167],
            [-110.2538, 47.4165],
            [-110.5544, 47.4165],
            [-110.6001, 47.5606],
            [-110.5142, 47.6113],
            [-110.5455, 47.6985],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012210',
        NAME: 'Geraldine K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2531314073,
        AWATER: 24336184,
        INTPTLAT: '+47.6779275',
        INTPTLON: '-110.2251812',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.4048, 47.9633],
            [-106.8776, 47.9606],
            [-106.8778, 48.2175],
            [-106.8459, 48.2609],
            [-106.8454, 48.4082],
            [-106.9104, 48.4084],
            [-106.9003, 48.6551],
            [-106.7245, 48.6552],
            [-106.676, 48.6958],
            [-106.5442, 48.6412],
            [-106.5444, 48.5584],
            [-106.2843, 48.5581],
            [-106.2828, 48.6477],
            [-106.0209, 48.6472],
            [-106.021, 48.5663],
            [-106.0647, 48.5664],
            [-106.1947, 48.567],
            [-106.1944, 48.4797],
            [-106.4934, 48.4791],
            [-106.4105, 48.3671],
            [-106.4087, 48.132],
            [-106.49, 48.1302],
            [-106.4904, 47.9623],
            [-106.571, 47.8129],
            [-106.8617, 47.7374],
            [-106.9801, 47.6729],
            [-107.249, 47.6616],
            [-107.4149, 47.6917],
            [-107.4048, 47.9633],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012420',
        NAME: 'Glasgow K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4346137428,
        AWATER: 303560853,
        INTPTLAT: '+48.1402626',
        INTPTLON: '-106.7763441',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8678, 46.4859],
            [-113.8661, 46.4924],
            [-113.6231, 46.4951],
            [-113.6231, 46.5715],
            [-113.4665, 46.5715],
            [-113.4113, 46.4918],
            [-113.2752, 46.4921],
            [-113.2757, 46.5482],
            [-113.0574, 46.5471],
            [-113.0385, 46.547],
            [-113.0367, 46.3597],
            [-113.0368, 46.2668],
            [-113.0368, 46.2233],
            [-113.2845, 46.2228],
            [-113.278, 46.0453],
            [-113.5174, 45.9401],
            [-113.6484, 45.965],
            [-113.7716, 46.0893],
            [-113.8212, 46.2125],
            [-113.7782, 46.2369],
            [-113.8166, 46.3991],
            [-113.8678, 46.4859],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3012840',
        NAME: 'Philipsburg K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3025237599,
        AWATER: 13254159,
        INTPTLAT: '+46.2857720',
        INTPTLON: '-113.4674064',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.7782, 46.2369],
            [-113.8212, 46.2125],
            [-113.7716, 46.0893],
            [-114.1393, 46.09],
            [-114.1691, 46.1848],
            [-114.4452, 46.1852],
            [-114.4543, 46.2415],
            [-114.3705, 46.2771],
            [-114.1554, 46.2876],
            [-114.0715, 46.2405],
            [-113.7782, 46.2369],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013260',
        NAME: 'Hamilton K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 716487538,
        AWATER: 1908985,
        INTPTLAT: '+46.1970624',
        INTPTLON: '-114.0829822',
        ELSDLEA: '',
        UNSDLEA: '13260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0371, 45.7495],
            [-111.8449, 45.7498],
            [-111.8054, 45.7867],
            [-111.6597, 45.7433],
            [-111.5368, 45.6568],
            [-111.3514, 45.6419],
            [-111.3513, 45.5986],
            [-111.3501, 45.3905],
            [-111.5429, 45.3905],
            [-111.5432, 45.5114],
            [-111.736, 45.54],
            [-111.9729, 45.5412],
            [-112.0391, 45.5523],
            [-112.0371, 45.7495],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013530',
        NAME: 'Harrison K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1333793245,
        AWATER: 3510384,
        INTPTLAT: '+45.6098408',
        INTPTLON: '-111.6829970',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.8878, 48.2228],
            [-108.4112, 48.2231],
            [-108.4339, 48.0473],
            [-108.434, 47.9766],
            [-108.6076, 47.991],
            [-108.6257, 47.9204],
            [-108.8852, 47.9227],
            [-108.8878, 48.2228],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3013660',
        NAME: 'Hays-Lodge Pole K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1031807514,
        AWATER: 2642362,
        INTPTLAT: '+48.0744699',
        INTPTLON: '-108.6751949',
        ELSDLEA: '',
        UNSDLEA: '13660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0498, 47.5983],
            [-110.9764, 47.6986],
            [-110.8743, 47.7612],
            [-110.7693, 47.6984],
            [-110.5455, 47.6985],
            [-110.5142, 47.6113],
            [-110.6001, 47.5606],
            [-110.5544, 47.4165],
            [-110.6396, 47.4165],
            [-110.7691, 47.4461],
            [-110.7685, 47.5256],
            [-110.8646, 47.5247],
            [-111.0295, 47.5822],
            [-111.0498, 47.5983],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014070',
        NAME: 'Highwood K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 939919982,
        AWATER: 3788100,
        INTPTLAT: '+47.6027230',
        INTPTLON: '-110.7493077',
        ELSDLEA: '',
        UNSDLEA: '14070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.838, 47.1854],
            [-109.7566, 47.1857],
            [-109.7554, 46.896],
            [-109.7876, 46.7803],
            [-109.8578, 46.7083],
            [-109.965, 46.7499],
            [-110.2733, 46.7105],
            [-110.396, 46.6886],
            [-110.39, 46.871],
            [-110.1562, 46.9749],
            [-110.0939, 47.0511],
            [-109.9254, 47.1132],
            [-109.838, 47.1854],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014430',
        NAME: 'Hobson K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1536908606,
        AWATER: 1267324,
        INTPTLAT: '+46.8813263',
        INTPTLON: '-110.0309818',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.8187, 47.8758],
            [-114.6046, 47.8756],
            [-114.5201, 47.7899],
            [-114.4975, 47.6287],
            [-114.3504, 47.6021],
            [-114.3502, 47.6019],
            [-114.365, 47.5181],
            [-114.4802, 47.5191],
            [-114.4825, 47.22],
            [-114.6872, 47.2743],
            [-114.696, 47.4096],
            [-114.7744, 47.535],
            [-114.7618, 47.7644],
            [-114.8387, 47.7924],
            [-114.8187, 47.8758],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014640',
        NAME: 'Hot Springs K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1323748853,
        AWATER: 8751283,
        INTPTLAT: '+47.5627743',
        INTPTLON: '-114.5977234',
        ELSDLEA: '',
        UNSDLEA: '14640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.3208, 46.264],
            [-108.0294, 46.2641],
            [-107.8631, 46.2499],
            [-107.7792, 46.2065],
            [-107.8408, 46.0417],
            [-107.798, 45.9857],
            [-107.8818, 45.8992],
            [-108.0474, 45.8995],
            [-108.0697, 45.6813],
            [-108.2426, 45.6812],
            [-108.2425, 45.7836],
            [-108.3431, 45.7842],
            [-108.3631, 45.8686],
            [-108.3148, 45.8956],
            [-108.2894, 45.9521],
            [-108.1729, 45.9987],
            [-108.3023, 46.0468],
            [-108.3032, 46.1327],
            [-108.4048, 46.133],
            [-108.4049, 46.236],
            [-108.3208, 46.264],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014700',
        NAME: 'Huntley Project K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1952009011,
        AWATER: 12930017,
        INTPTLAT: '+46.0125134',
        INTPTLON: '-108.1060223',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.7385, 46.4818],
            [-107.728, 46.4818],
            [-107.1745, 46.4827],
            [-107.1536, 46.4681],
            [-107.1534, 46.3956],
            [-107.0284, 46.3955],
            [-107.0055, 46.1367],
            [-106.9391, 46.1367],
            [-106.9391, 46.0048],
            [-106.9391, 45.87],
            [-107.1764, 45.9568],
            [-107.4251, 45.9567],
            [-107.4249, 46.0434],
            [-107.5115, 46.0434],
            [-107.467, 46.13],
            [-107.5286, 46.2502],
            [-107.6124, 46.3081],
            [-107.7385, 46.4818],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3014760',
        NAME: 'Hysham K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2532085947,
        AWATER: 17149961,
        INTPTLAT: '+46.2095584',
        INTPTLON: '-107.2803688',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.1963, 46.7513],
            [-109.0096, 46.7507],
            [-109.01, 46.6212],
            [-108.9049, 46.5769],
            [-108.8623, 46.3646],
            [-108.7793, 46.2774],
            [-108.7793, 46.2104],
            [-108.7797, 46.1325],
            [-109.0828, 46.1319],
            [-109.1601, 46.1319],
            [-109.1021, 46.1625],
            [-109.1357, 46.2477],
            [-109.088, 46.3135],
            [-109.1447, 46.4216],
            [-109.141, 46.5866],
            [-109.1968, 46.6692],
            [-109.1963, 46.7513],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016290',
        NAME: 'Lavina K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1389673444,
        AWATER: 2274788,
        INTPTLAT: '+46.3984825',
        INTPTLON: '-109.0156962',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.694, 48.2258],
            [-115.6639, 48.2257],
            [-115.6632, 48.5724],
            [-115.4126, 48.5717],
            [-115.4126, 48.6301],
            [-115.1507, 48.6297],
            [-115.1057, 48.5718],
            [-115.1082, 48.3126],
            [-115.1715, 48.2255],
            [-115.0201, 48.226],
            [-115.0106, 48.0466],
            [-115.0107, 48.0176],
            [-115.1545, 48.0177],
            [-115.1852, 47.919],
            [-115.4739, 47.915],
            [-115.6087, 48.0583],
            [-115.694, 48.2258],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016530',
        NAME: 'Libby K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3030222738,
        AWATER: 59489809,
        INTPTLAT: '+48.2699975',
        INTPTLON: '-115.3661912',
        ELSDLEA: '',
        UNSDLEA: '16530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0644, 44.6521],
            [-112.9496, 44.6536],
            [-112.9499, 44.9083],
            [-112.792, 44.9313],
            [-112.3925, 44.9347],
            [-112.3933, 44.7889],
            [-112.0658, 44.7835],
            [-111.9117, 44.7821],
            [-111.7812, 44.781],
            [-111.7811, 44.7088],
            [-111.475, 44.7088],
            [-111.5256, 44.6047],
            [-111.6173, 44.5504],
            [-111.8011, 44.526],
            [-111.8637, 44.5512],
            [-112.0965, 44.523],
            [-112.2887, 44.5679],
            [-112.4734, 44.4794],
            [-112.7179, 44.5043],
            [-112.8397, 44.424],
            [-112.8138, 44.3765],
            [-113.002, 44.4499],
            [-113.0068, 44.5262],
            [-113.0883, 44.5997],
            [-113.0644, 44.6521],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016710',
        NAME: 'Lima K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4164900700,
        AWATER: 46741899,
        INTPTLAT: '+44.6708769',
        INTPTLON: '-112.3885092',
        ELSDLEA: '',
        UNSDLEA: '16710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0592, 47.4886],
            [-112.9242, 47.3874],
            [-112.9337, 47.3458],
            [-112.7464, 47.2475],
            [-112.6126, 47.2231],
            [-112.5616, 47.1747],
            [-112.4301, 47.1534],
            [-112.3169, 47.0153],
            [-112.4499, 46.9521],
            [-112.5357, 46.8342],
            [-112.7953, 46.8317],
            [-112.7961, 46.9685],
            [-112.7958, 47.177],
            [-113.0591, 47.1796],
            [-113.0592, 47.4886],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3016810',
        NAME: 'Lincoln K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1685969964,
        AWATER: 4023521,
        INTPTLAT: '+47.1920861',
        INTPTLON: '-112.7473043',
        ELSDLEA: '',
        UNSDLEA: '16810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6257, 47.9204],
            [-108.6076, 47.991],
            [-108.434, 47.9766],
            [-108.4339, 48.0473],
            [-108.1715, 48.0517],
            [-108.1481, 48.3535],
            [-108.0211, 48.4801],
            [-107.883, 48.4801],
            [-107.8832, 48.5983],
            [-108.0192, 48.5979],
            [-108.0192, 48.741],
            [-108.1288, 48.7416],
            [-108.1278, 48.9133],
            [-108.2364, 48.9136],
            [-108.2365, 48.9996],
            [-107.9738, 48.9997],
            [-107.861, 48.9145],
            [-107.7487, 48.6846],
            [-107.949, 48.6984],
            [-108.0006, 48.6512],
            [-107.8268, 48.6077],
            [-107.6026, 48.6258],
            [-107.7467, 48.5703],
            [-107.6728, 48.485],
            [-107.6726, 48.3977],
            [-107.5406, 48.3978],
            [-107.5405, 48.1631],
            [-107.4047, 48.1157],
            [-107.4048, 47.9633],
            [-107.4149, 47.6917],
            [-107.4471, 47.6224],
            [-107.5943, 47.6497],
            [-107.6947, 47.6298],
            [-107.8212, 47.5455],
            [-107.9091, 47.4507],
            [-107.9311, 47.5691],
            [-108.0654, 47.5983],
            [-108.313, 47.5836],
            [-108.6051, 47.6233],
            [-108.6054, 47.8691],
            [-108.6257, 47.9204],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3017580',
        NAME: 'Malta K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7381612766,
        AWATER: 133592927,
        INTPTLAT: '+48.0477714',
        INTPTLON: '-107.9322790',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9733, 48.6059],
            [-104.8424, 48.6331],
            [-104.5804, 48.6343],
            [-104.1876, 48.6645],
            [-104.0479, 48.621],
            [-104.047, 48.3893],
            [-104.2595, 48.3894],
            [-104.6278, 48.3894],
            [-104.6277, 48.4763],
            [-104.7576, 48.4766],
            [-104.7576, 48.5631],
            [-104.9734, 48.5632],
            [-104.9733, 48.6059],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3018170',
        NAME: 'Medicine Lake K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1421664564,
        AWATER: 48388077,
        INTPTLAT: '+48.5184529',
        INTPTLON: '-104.3755937',
        ELSDLEA: '',
        UNSDLEA: '18170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1944, 48.4797],
            [-106.1947, 48.3058],
            [-106.0629, 48.3053],
            [-106.1083, 48.2191],
            [-106.1083, 48.0062],
            [-106.3942, 48.0647],
            [-106.4186, 47.9575],
            [-106.4904, 47.9623],
            [-106.49, 48.1302],
            [-106.4087, 48.132],
            [-106.4105, 48.3671],
            [-106.4934, 48.4791],
            [-106.1944, 48.4797],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3019170',
        NAME: 'Nashua K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1113786230,
        AWATER: 29556738,
        INTPTLAT: '+48.2265467',
        INTPTLON: '-106.2756602',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.6513, 48.9999],
            [-106.1121, 48.9995],
            [-106.1519, 48.8239],
            [-106.0213, 48.824],
            [-106.0209, 48.6472],
            [-106.2828, 48.6477],
            [-106.2843, 48.5581],
            [-106.5444, 48.5584],
            [-106.5442, 48.6412],
            [-106.676, 48.6958],
            [-106.6797, 48.9124],
            [-106.6513, 48.9999],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3019740',
        NAME: 'Opheim K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1888154818,
        AWATER: 739945,
        INTPTLAT: '+48.7986484',
        INTPTLON: '-106.3952833',
        ELSDLEA: '',
        UNSDLEA: '19740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0576, 48.999],
            [-104.3987, 48.9991],
            [-104.2871, 48.8408],
            [-104.2967, 48.737],
            [-104.1875, 48.7322],
            [-104.1876, 48.6645],
            [-104.5804, 48.6343],
            [-104.8424, 48.6331],
            [-104.9733, 48.6059],
            [-105.0385, 48.6501],
            [-105.0382, 48.9105],
            [-105.0576, 48.999],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3020960',
        NAME: 'Plentywood K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2202907871,
        AWATER: 2182177,
        INTPTLAT: '+48.8012906',
        INTPTLON: '-104.6876376',
        ELSDLEA: '',
        UNSDLEA: '20960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7331, 46.6127],
            [-104.6069, 46.6849],
            [-104.418, 46.6849],
            [-104.3539, 46.6414],
            [-104.3548, 46.5689],
            [-104.5018, 46.569],
            [-104.4461, 46.455],
            [-104.448, 46.2086],
            [-104.5161, 46.1362],
            [-104.8855, 46.1366],
            [-104.8856, 46.2532],
            [-104.906, 46.4823],
            [-104.7327, 46.4822],
            [-104.7331, 46.6127],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3021000',
        NAME: 'Plevna K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1862428329,
        AWATER: 1763049,
        INTPTLAT: '+46.3819924',
        INTPTLON: '-104.6598538',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.2777, 45.4644],
            [-109.1917, 45.4884],
            [-109.0891, 45.3771],
            [-108.9479, 45.3723],
            [-108.939, 45.3471],
            [-109.0207, 45.3464],
            [-109.1403, 45.2697],
            [-109.2887, 45.3619],
            [-109.2777, 45.4644],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022710',
        NAME: 'Roberts K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313799109,
        AWATER: 2877319,
        INTPTLAT: '+45.3752482',
        INTPTLON: '-109.1286678',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.7198, 46.8452],
            [-106.0865, 46.8459],
            [-106.1206, 46.8314],
            [-106.1204, 46.4836],
            [-106.1548, 46.3043],
            [-106.1916, 46.1372],
            [-106.1915, 45.8764],
            [-106.3032, 45.8767],
            [-106.3462, 45.9207],
            [-106.4957, 45.9206],
            [-106.4958, 46.0508],
            [-106.5578, 46.351],
            [-106.7891, 46.3507],
            [-106.7493, 46.4851],
            [-106.7492, 46.831],
            [-106.7198, 46.8452],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3022920',
        NAME: 'Rosebud K-12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3961091342,
        AWATER: 18769934,
        INTPTLAT: '+46.4200814',
        INTPTLON: '-106.3669589',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6689, 47.6191],
            [-108.6051, 47.6233],
            [-108.313, 47.5836],
            [-108.3155, 47.3152],
            [-108.5908, 47.3065],
            [-108.7192, 47.2703],
            [-108.7361, 47.1834],
            [-109.0079, 47.192],
            [-109.0958, 47.2432],
            [-109.2073, 47.3144],
            [-109.2081, 47.481],
            [-109.1402, 47.5474],
            [-109.0248, 47.5477],
            [-108.9642, 47.6292],
            [-108.6689, 47.6191],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023160',
        NAME: 'Roy K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2499799567,
        AWATER: 7974148,
        INTPTLAT: '+47.4266552',
        INTPTLON: '-108.7720770',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.6539, 46.2194],
            [-109.6445, 46.2194],
            [-109.4029, 46.2197],
            [-109.4032, 46.356],
            [-109.3887, 46.5468],
            [-109.3892, 46.6936],
            [-109.3892, 46.7514],
            [-109.3159, 46.7513],
            [-109.1963, 46.7513],
            [-109.1968, 46.6692],
            [-109.141, 46.5866],
            [-109.1447, 46.4216],
            [-109.088, 46.3135],
            [-109.1357, 46.2477],
            [-109.1021, 46.1625],
            [-109.1601, 46.1319],
            [-109.4162, 46.1324],
            [-109.4172, 46.0448],
            [-109.6052, 46.045],
            [-109.6042, 46.1325],
            [-109.6539, 46.2194],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023340',
        NAME: 'Ryegate K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1652070487,
        AWATER: 347004,
        INTPTLAT: '+46.3626429',
        INTPTLON: '-109.3212913',
        ELSDLEA: '',
        UNSDLEA: '23340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.1121, 48.9995],
            [-105.0576, 48.999],
            [-105.0382, 48.9105],
            [-105.0385, 48.6501],
            [-104.9733, 48.6059],
            [-104.9734, 48.5632],
            [-105.4092, 48.5632],
            [-105.6064, 48.5633],
            [-105.8044, 48.5634],
            [-106.021, 48.5663],
            [-106.0209, 48.6472],
            [-106.0213, 48.824],
            [-106.1519, 48.8239],
            [-106.1121, 48.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3023670',
        NAME: 'Scobey K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3693585018,
        AWATER: 1021697,
        INTPTLAT: '+48.7944290',
        INTPTLON: '-105.5417385',
        ELSDLEA: '',
        UNSDLEA: '23670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.6295, 47.4796],
            [-115.2839, 47.4866],
            [-115.1281, 47.423],
            [-114.9823, 47.3968],
            [-114.969, 47.3126],
            [-114.9909, 47.2337],
            [-115.1228, 47.1815],
            [-115.2618, 47.1814],
            [-115.3408, 47.2629],
            [-115.5285, 47.2992],
            [-115.5936, 47.371],
            [-115.7466, 47.4423],
            [-115.6295, 47.4796],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024930',
        NAME: 'St. Regis K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1200006103,
        AWATER: 2945825,
        INTPTLAT: '+47.3460297',
        INTPTLON: '-115.3218988',
        ELSDLEA: '',
        UNSDLEA: '24930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6452, 47.0959],
            [-110.4727, 47.0973],
            [-110.474, 47.1697],
            [-110.3192, 47.1845],
            [-110.2986, 47.3587],
            [-110.2538, 47.4165],
            [-110.2136, 47.4167],
            [-110.128, 47.359],
            [-110.1277, 47.2719],
            [-110, 47.2287],
            [-109.8725, 47.2289],
            [-109.838, 47.1854],
            [-109.9254, 47.1132],
            [-110.0939, 47.0511],
            [-110.1562, 46.9749],
            [-110.39, 46.871],
            [-110.396, 46.6886],
            [-110.4546, 46.6795],
            [-110.6207, 46.7722],
            [-110.6529, 46.8236],
            [-110.6452, 47.0959],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3024990',
        NAME: 'Stanford K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2156359440,
        AWATER: 492605,
        INTPTLAT: '+47.0325580',
        INTPTLON: '-110.3053200',
        ELSDLEA: '',
        UNSDLEA: '24990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2699, 48.9972],
            [-111.2733, 48.8815],
            [-111.4697, 48.8561],
            [-111.4587, 48.7475],
            [-111.5788, 48.6679],
            [-111.8404, 48.6532],
            [-112.1891, 48.6534],
            [-112.1934, 48.9985],
            [-111.2699, 48.9972],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025320',
        NAME: 'Sunburst K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2161129382,
        AWATER: 32845670,
        INTPTLAT: '+48.8145768',
        INTPTLON: '-111.8062319',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.2618, 47.1814],
            [-115.1228, 47.1815],
            [-114.9909, 47.2337],
            [-114.969, 47.3126],
            [-114.8425, 47.2979],
            [-114.7972, 47.2689],
            [-114.6914, 47.1672],
            [-114.6722, 47.0651],
            [-114.7563, 47.0073],
            [-114.7826, 46.8463],
            [-114.925, 46.8466],
            [-114.9593, 46.9329],
            [-115.0479, 46.9695],
            [-115.1061, 47.0486],
            [-115.2618, 47.1814],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025470',
        NAME: 'Superior K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1223262213,
        AWATER: 5190675,
        INTPTLAT: '+47.1002802',
        INTPTLON: '-114.8650872',
        ELSDLEA: '',
        UNSDLEA: '25470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0868, 47.1809],
            [-105.9598, 47.181],
            [-105.9599, 47.093],
            [-105.8326, 47.0931],
            [-105.833, 47.1811],
            [-105.4063, 47.1816],
            [-105.3249, 47.1815],
            [-105.3248, 46.9772],
            [-105.1967, 46.9772],
            [-105.197, 46.9192],
            [-105.0695, 46.9192],
            [-105.0077, 46.8614],
            [-104.6038, 46.8609],
            [-104.6069, 46.6849],
            [-104.7331, 46.6127],
            [-104.9847, 46.5404],
            [-105.2392, 46.5412],
            [-105.4189, 46.5709],
            [-105.5468, 46.658],
            [-105.6182, 46.8321],
            [-105.8327, 46.8608],
            [-106.0866, 46.8603],
            [-106.0868, 47.1809],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3025950',
        NAME: 'Terry K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4497958392,
        AWATER: 15092932,
        INTPTLAT: '+46.8123813',
        INTPTLON: '-105.5039914',
        ELSDLEA: '',
        UNSDLEA: '25950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6878, 45.5656],
            [-112.6871, 45.6249],
            [-112.5495, 45.6843],
            [-112.4853, 45.759],
            [-112.3783, 45.7445],
            [-112.0371, 45.7495],
            [-112.0391, 45.5523],
            [-112.1948, 45.5512],
            [-112.256, 45.3324],
            [-112.2669, 45.1727],
            [-112.4575, 45.1739],
            [-112.4667, 45.3966],
            [-112.6878, 45.4772],
            [-112.6878, 45.5656],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3026730',
        NAME: 'Twin Bridges K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1859050581,
        AWATER: 781037,
        INTPTLAT: '+45.5183162',
        INTPTLON: '-112.3878707',
        ELSDLEA: '',
        UNSDLEA: '26730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3794, 46.4603],
            [-114.115, 46.4795],
            [-114.1212, 46.4068],
            [-114.0257, 46.3995],
            [-114.1623, 46.342],
            [-114.4142, 46.343],
            [-114.3794, 46.4603],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027270',
        NAME: 'Victor K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311919804,
        AWATER: 1550836,
        INTPTLAT: '+46.4046943',
        INTPTLON: '-114.2430146',
        ELSDLEA: '',
        UNSDLEA: '27270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3725, 45.0026],
            [-111.0546, 45.001],
            [-111.0534, 44.6663],
            [-111.0524, 44.4784],
            [-111.1307, 44.5001],
            [-111.2193, 44.6225],
            [-111.377, 44.7514],
            [-111.3725, 45.0026],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027630',
        NAME: 'West Yellowstone K-12 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1078105290,
        AWATER: 53134471,
        INTPTLAT: '+44.7998833',
        INTPTLON: '-111.1903797',
        ELSDLEA: '',
        UNSDLEA: '27630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.3987, 48.9991],
            [-104.0491, 48.9996],
            [-104.0479, 48.621],
            [-104.1876, 48.6645],
            [-104.1875, 48.7322],
            [-104.2967, 48.737],
            [-104.2871, 48.8408],
            [-104.3987, 48.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027730',
        NAME: 'Westby K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 715698037,
        AWATER: 24507508,
        INTPTLAT: '+48.8563682',
        INTPTLON: '-104.1708300',
        ELSDLEA: '',
        UNSDLEA: '27730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9738, 48.9997],
            [-107.3147, 49.0001],
            [-107.3366, 48.9132],
            [-107.3376, 48.6542],
            [-107.6026, 48.6258],
            [-107.8268, 48.6077],
            [-108.0006, 48.6512],
            [-107.949, 48.6984],
            [-107.7487, 48.6846],
            [-107.861, 48.9145],
            [-107.9738, 48.9997],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3027930',
        NAME: 'Whitewater K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1655202191,
        AWATER: 5237822,
        INTPTLAT: '+48.8503856',
        INTPTLON: '-107.6244127',
        ELSDLEA: '',
        UNSDLEA: '27930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6038, 46.8609],
            [-104.529, 46.8608],
            [-104.4121, 46.904],
            [-104.3061, 47.042],
            [-104.3417, 47.2454],
            [-104.451, 47.3193],
            [-104.4203, 47.3544],
            [-104.1319, 47.3539],
            [-104.0453, 47.3971],
            [-104.0454, 47.3301],
            [-104.0461, 46.8035],
            [-104.0454, 46.6415],
            [-104.3539, 46.6414],
            [-104.418, 46.6849],
            [-104.6069, 46.6849],
            [-104.6038, 46.8609],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028020',
        NAME: 'Wibaux K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2301427764,
        AWATER: 1756850,
        INTPTLAT: '+46.9629657',
        INTPTLON: '-104.2745184',
        ELSDLEA: '',
        UNSDLEA: '28020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.6775, 47.7347],
            [-109.5387, 47.7389],
            [-109.4115, 47.7246],
            [-109.2774, 47.7924],
            [-108.9427, 47.7918],
            [-108.8911, 47.7345],
            [-108.6689, 47.6191],
            [-108.9642, 47.6292],
            [-109.0248, 47.5477],
            [-109.1402, 47.5474],
            [-109.2081, 47.481],
            [-109.2073, 47.3144],
            [-109.4038, 47.3043],
            [-109.3993, 47.4044],
            [-109.5794, 47.4204],
            [-109.6562, 47.4966],
            [-109.6775, 47.7347],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028380',
        NAME: 'Winifred K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2098139984,
        AWATER: 15805285,
        INTPTLAT: '+47.5920752',
        INTPTLON: '-109.2727545',
        ELSDLEA: '',
        UNSDLEA: '28380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.7361, 47.1834],
            [-108.7192, 47.2703],
            [-108.5908, 47.3065],
            [-108.3155, 47.3152],
            [-108.313, 47.5836],
            [-108.0654, 47.5983],
            [-107.9311, 47.5691],
            [-107.9091, 47.4507],
            [-107.9566, 47.3609],
            [-107.9543, 47.1207],
            [-107.9223, 47.0769],
            [-107.8847, 46.9842],
            [-107.8922, 46.8512],
            [-107.8277, 46.7559],
            [-108.0086, 46.755],
            [-108.3235, 46.752],
            [-108.6315, 46.7494],
            [-108.6111, 46.8368],
            [-108.609, 47.0981],
            [-108.7368, 47.0982],
            [-108.7361, 47.1834],
          ],
        ],
      },
      properties: {
        STATEFP: '30',
        SCSDLEA: '',
        GEOID: '3028470',
        NAME: 'Winnett K-12 Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4288041275,
        AWATER: 48927318,
        INTPTLAT: '+47.1419168',
        INTPTLON: '-108.2265753',
        ELSDLEA: '',
        UNSDLEA: '28470',
      },
    },
  ],
};

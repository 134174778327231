/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperclip,
  faTrashAlt /* ,  faExternalLinkAlt */,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import './style.css';
import { dateFormat } from '../../../../helpers/helpers';

const actionButtonDelay = { show: 1000, hide: 200 };

const ReportTile = ({ report, onShareButtonClick, onDeleteButtonClick, onToggleFav }) => {
  const { push } = useHistory();
  return (
    <Card className="h-100 text-center report-tile-container" size="lg">
      <OverlayTrigger
        trigger={['hover', 'hover']}
        placement="top"
        overlay={
          <Tooltip id="tooltip-related-question">
            {report.isStarred ? 'Remove from favorites' : 'Add to favorites'}
          </Tooltip>
        }
      >
        <Button
          className="favorite-icon text-dark bg-white border-0"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onToggleFav(report.id);
          }}
        >
          <FontAwesomeIcon
            color={report.isStarred ? 'unset' : '#ccc'}
            className="mt-0"
            icon={faStar}
          />
        </Button>
      </OverlayTrigger>
      <Card.Body
        className="h-75 d-flex flex-column justify-content-between pb-2"
        onClick={() => push(`/reports/${report.id}`)}
      >
        <Card.Title className="title-text-size">{report.name}</Card.Title>
        <Card.Text>
          Created: {dateFormat(report.dateCreated)}
          <br />
          Updated: {dateFormat(report.dateUpdated)}
          <br />
          Opened: {dateFormat(report.lastOpened)}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="h-25 d-flex justify-content-around align-items-center px-5 py-1">
        <OverlayTrigger
          placement="top"
          delay={actionButtonDelay}
          overlay={<Tooltip>Share report</Tooltip>}
        >
          <Button
            className="text-dark bg-white border-0 h-100"
            size="lg"
            aria-label="Share report"
            onClick={() => onShareButtonClick(report.id)}
          >
            <FontAwesomeIcon className="mt-0" icon={faPaperclip} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger delay={actionButtonDelay} overlay={<Tooltip>Delete report</Tooltip>}>
          <Button
            className="text-dark bg-white border-0 h-100"
            size="lg"
            aria-label="Delete report"
            onClick={() => onDeleteButtonClick(report.id)}
          >
            <FontAwesomeIcon className="mt-0" icon={faTrashAlt} />
          </Button>
        </OverlayTrigger>
      </Card.Footer>
    </Card>
  );
};

export default React.memo(ReportTile);

export const ID = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0348, 48.5689],
            [-117.0337, 48.6553],
            [-117.0324, 48.8466],
            [-116.7851, 48.8474],
            [-116.7848, 48.5006],
            [-116.6789, 48.5001],
            [-116.6781, 48.3716],
            [-116.7426, 48.3716],
            [-116.767, 47.9774],
            [-117.0422, 47.9774],
            [-117.0348, 48.5689],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600001',
        NAME: 'West Bonner County School District 83',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1906145892,
        AWATER: 118782531,
        INTPTLAT: '+48.4011753',
        INTPTLON: '-116.8965376',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.6789, 48.5001],
            [-116.0491, 48.5021],
            [-116.0487, 48.2161],
            [-116.0491, 47.9773],
            [-116.2347, 48.0696],
            [-116.3291, 48.0178],
            [-116.3228, 47.8903],
            [-116.505, 47.8902],
            [-116.5042, 47.9915],
            [-116.767, 47.9774],
            [-116.7426, 48.3716],
            [-116.6781, 48.3716],
            [-116.6789, 48.5001],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600002',
        NAME: 'Lake Pend Oreille School District 84',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2581611383,
        AWATER: 360170776,
        INTPTLAT: '+48.2427191',
        INTPTLON: '-116.4155686',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8549, 46.8165],
            [-116.6931, 46.848],
            [-116.6046, 46.7294],
            [-116.6611, 46.6677],
            [-116.7704, 46.6283],
            [-116.8117, 46.6715],
            [-116.8549, 46.8165],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600009',
        NAME: 'Troy School District 287',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308405470,
        AWATER: 541588,
        INTPTLAT: '+46.7419419',
        INTPTLON: '-116.7426795',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.4801, 47.037],
            [-116.3296, 47.0224],
            [-116.3296, 46.9345],
            [-115.6385, 46.9339],
            [-115.6386, 46.8464],
            [-115.8913, 46.8478],
            [-116.0171, 46.705],
            [-116.0093, 46.6585],
            [-116.3293, 46.6567],
            [-116.3294, 46.6864],
            [-116.6046, 46.7294],
            [-116.6931, 46.848],
            [-116.6495, 46.9064],
            [-116.4783, 46.9083],
            [-116.4801, 47.037],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600010',
        NAME: 'Whitepine Joint School District 288',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1773330044,
        AWATER: 18405306,
        INTPTLAT: '+46.8357559',
        INTPTLON: '-116.2472437',
        ELSDLEA: '',
        UNSDLEA: '00010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0077, 43.111],
            [-113.0078, 43.1548],
            [-112.6339, 43.1541],
            [-112.6132, 43.0335],
            [-112.6349, 43.022],
            [-112.7505, 42.9514],
            [-112.8164, 42.8633],
            [-113.0078, 42.8631],
            [-113.0077, 43.111],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600030',
        NAME: 'Aberdeen School District 58',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774840013,
        AWATER: 53018623,
        INTPTLAT: '+43.0416678',
        INTPTLON: '-112.8403142',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0077, 43.111],
            [-113.0078, 42.8631],
            [-112.8164, 42.8633],
            [-112.7505, 42.9514],
            [-112.595, 42.9731],
            [-112.516, 42.91],
            [-112.4972, 42.76],
            [-112.5351, 42.5885],
            [-112.6539, 42.5885],
            [-112.8139, 42.617],
            [-113.0028, 42.588],
            [-113.1562, 42.5893],
            [-113.2568, 42.6178],
            [-113.179, 42.6755],
            [-113.2369, 42.7624],
            [-113.2435, 43.1111],
            [-113.0077, 43.111],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600060',
        NAME: 'American Falls Joint School District 381',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2340474838,
        AWATER: 101926686,
        INTPTLAT: '+42.7978246',
        INTPTLON: '-112.9388361',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6539, 42.5885],
            [-112.5351, 42.5885],
            [-112.4972, 42.76],
            [-112.4576, 42.7187],
            [-112.3896, 42.6241],
            [-112.42, 42.5029],
            [-112.4982, 42.503],
            [-112.4978, 42.416],
            [-112.654, 42.416],
            [-112.6539, 42.5885],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600090',
        NAME: 'Arbon Elementary School District 383',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477198765,
        AWATER: 104106,
        INTPTLAT: '+42.5607623',
        INTPTLON: '-112.5156795',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.5281, 45.5396],
            [-116.391, 45.5398],
            [-116.3912, 45.5978],
            [-116.2647, 45.5983],
            [-116.2705, 45.4558],
            [-116.0232, 45.4561],
            [-116.0074, 45.1954],
            [-116.1445, 45.1076],
            [-116.3048, 45.1089],
            [-116.393, 45.1671],
            [-116.3929, 45.268],
            [-116.687, 45.2679],
            [-116.5281, 45.5396],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600138',
        NAME: 'Salmon River School District 243',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1677043594,
        AWATER: 9342173,
        INTPTLAT: '+45.3157275',
        INTPTLON: '-116.3047543',
        ELSDLEA: '',
        UNSDLEA: '00138',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7946, 45.856],
            [-116.702, 45.9962],
            [-116.6151, 46.0328],
            [-116.5528, 45.9751],
            [-116.4434, 45.9623],
            [-116.3256, 45.9976],
            [-116.191, 46.0741],
            [-116.056, 46.095],
            [-115.9947, 46.2013],
            [-115.765, 46.1931],
            [-115.7655, 46.2824],
            [-115.7334, 46.3629],
            [-115.6296, 46.4735],
            [-114.5948, 46.6335],
            [-114.3328, 46.6606],
            [-114.3308, 46.5738],
            [-114.3794, 46.4603],
            [-114.4142, 46.343],
            [-114.4543, 46.2415],
            [-114.4452, 46.1852],
            [-114.4805, 46.0304],
            [-114.4118, 45.9779],
            [-114.4059, 45.8704],
            [-114.5003, 45.85],
            [-114.5663, 45.7727],
            [-114.4997, 45.6685],
            [-114.5649, 45.5577],
            [-114.6635, 45.4712],
            [-114.7936, 45.4366],
            [-114.5983, 45.2922],
            [-114.6941, 45.1971],
            [-116.0074, 45.1954],
            [-116.0232, 45.4561],
            [-116.2705, 45.4558],
            [-116.2647, 45.5983],
            [-116.3912, 45.5978],
            [-116.391, 45.5398],
            [-116.5281, 45.5396],
            [-116.4636, 45.6028],
            [-116.5942, 45.7791],
            [-116.7946, 45.856],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600139',
        NAME: 'Mountain View School District 244',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19320762008,
        AWATER: 55076120,
        INTPTLAT: '+45.8957997',
        INTPTLON: '-115.3566098',
        ELSDLEA: '',
        UNSDLEA: '00139',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.3294, 47.3707],
            [-116.0426, 47.3707],
            [-115.8624, 47.371],
            [-115.5936, 47.371],
            [-115.5285, 47.2992],
            [-115.3408, 47.2629],
            [-115.2618, 47.1814],
            [-115.1061, 47.0486],
            [-115.0479, 46.9695],
            [-114.9593, 46.9329],
            [-115.6385, 46.9339],
            [-116.3296, 46.9345],
            [-116.3296, 47.0224],
            [-116.3294, 47.3707],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600150',
        NAME: 'Avery School District 394',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3966092402,
        AWATER: 8615365,
        INTPTLAT: '+47.1238627',
        INTPTLON: '-115.7764742',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0379, 43.9798],
            [-115.8997, 43.994],
            [-115.8003, 44.0663],
            [-115.2016, 44.0741],
            [-115.2873, 43.9355],
            [-115.5138, 43.8807],
            [-115.5475, 43.7927],
            [-115.6351, 43.7132],
            [-115.8222, 43.6119],
            [-115.8568, 43.6014],
            [-115.8972, 43.5986],
            [-116.0232, 43.6177],
            [-116.1414, 43.705],
            [-116.0351, 43.7045],
            [-116.0379, 43.9798],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600180',
        NAME: 'Basin School District 72',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2092085620,
        AWATER: 5372368,
        INTPTLAT: '+43.8651546',
        INTPTLON: '-115.6399011',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6041, 42.5906],
            [-111.4663, 42.5903],
            [-111.2812, 42.553],
            [-111.2347, 42.4784],
            [-111.0467, 42.5131],
            [-111.0467, 42.0017],
            [-111.5078, 41.9996],
            [-111.515, 42.068],
            [-111.5944, 42.0935],
            [-111.6262, 42.2086],
            [-111.5779, 42.3283],
            [-111.5588, 42.3488],
            [-111.6349, 42.5701],
            [-111.6041, 42.5906],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600240',
        NAME: 'Bear Lake County School District 33',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2476715776,
        AWATER: 192875338,
        INTPTLAT: '+42.2824701',
        INTPTLON: '-111.3309721',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6132, 43.0335],
            [-112.4766, 43.1514],
            [-112.4059, 43.1813],
            [-112.405, 43.3673],
            [-112.2963, 43.3672],
            [-112.2964, 43.2617],
            [-112.0434, 43.2626],
            [-111.9986, 43.1722],
            [-111.8256, 43.0206],
            [-111.9438, 43.021],
            [-112.0631, 43.0224],
            [-112.6349, 43.022],
            [-112.6132, 43.0335],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600270',
        NAME: 'Blackfoot School District 55',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1242995156,
        AWATER: 9499924,
        INTPTLAT: '+43.1431423',
        INTPTLON: '-112.2179313',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.9713, 43.9381],
            [-114.8734, 43.9929],
            [-114.8138, 43.9278],
            [-114.6812, 43.9278],
            [-114.6062, 43.8935],
            [-114.4099, 43.8956],
            [-113.8627, 43.6453],
            [-113.8061, 43.5717],
            [-113.7006, 43.5848],
            [-113.6343, 43.4973],
            [-113.6354, 43.3676],
            [-113.3619, 43.3672],
            [-113.3619, 43.2847],
            [-113.0073, 43.2848],
            [-113.0078, 43.1548],
            [-113.0077, 43.111],
            [-113.2435, 43.1111],
            [-113.2369, 42.7624],
            [-113.179, 42.6755],
            [-113.2568, 42.6178],
            [-113.3485, 42.6627],
            [-113.4722, 42.6693],
            [-113.4722, 42.8492],
            [-113.413, 42.8492],
            [-113.4137, 43.1998],
            [-113.7147, 43.2],
            [-114.2572, 43.2002],
            [-114.3749, 43.1996],
            [-114.3946, 43.3258],
            [-114.5346, 43.3333],
            [-114.5871, 43.5581],
            [-114.683, 43.5942],
            [-114.6612, 43.6554],
            [-114.7044, 43.8078],
            [-114.7602, 43.7917],
            [-114.9894, 43.8586],
            [-114.9713, 43.9381],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600300',
        NAME: 'Blaine County School District 61',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6837372799,
        AWATER: 44347641,
        INTPTLAT: '+43.3941920',
        INTPTLON: '-113.9553713',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0865, 43.1984],
            [-114.8924, 43.198],
            [-114.8916, 42.9385],
            [-114.8415, 42.8804],
            [-114.9054, 42.8627],
            [-115.0372, 42.8945],
            [-115.0868, 42.9147],
            [-115.0865, 43.1984],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600330',
        NAME: 'Bliss Joint School District 234',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554672181,
        AWATER: 3001753,
        INTPTLAT: '+43.0423095',
        INTPTLON: '-114.9860918',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.3856, 43.1973],
            [-116.2552, 43.2003],
            [-116.137, 43.2001],
            [-116.1344, 43.4984],
            [-116.259, 43.4989],
            [-116.3192, 43.6121],
            [-116.2488, 43.7832],
            [-116.1414, 43.705],
            [-116.0232, 43.6177],
            [-115.8972, 43.5986],
            [-115.9758, 43.5913],
            [-115.9787, 43.1134],
            [-116.2662, 43.1126],
            [-116.3758, 43.1852],
            [-116.3856, 43.1973],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600360',
        NAME: 'Boise City Independent School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1281458972,
        AWATER: 16498401,
        INTPTLAT: '+43.2790106',
        INTPTLON: '-116.0364278',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0324, 48.9992],
            [-116.0491, 49.0008],
            [-116.049, 48.5153],
            [-116.0491, 48.5021],
            [-116.6789, 48.5001],
            [-116.7848, 48.5006],
            [-116.7851, 48.8474],
            [-117.0324, 48.8466],
            [-117.0324, 48.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600420',
        NAME: 'Boundary County School District 101',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3285786073,
        AWATER: 24097890,
        INTPTLAT: '+48.7731326',
        INTPTLON: '-116.5246729',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7486, 42.9374],
            [-116.6278, 42.9382],
            [-116.6092, 43.0825],
            [-116.5305, 43.0854],
            [-116.3758, 43.1852],
            [-116.2662, 43.1126],
            [-115.9787, 43.1134],
            [-115.9639, 42.9866],
            [-115.6381, 42.9558],
            [-115.6497, 42.8259],
            [-115.4543, 42.8267],
            [-115.4543, 42.7677],
            [-115.0377, 42.7685],
            [-115.0377, 42.6824],
            [-115.1034, 42.6823],
            [-115.1038, 42.5796],
            [-115.0376, 42.5791],
            [-115.0379, 42.2456],
            [-115.5712, 42.2446],
            [-115.57, 41.9968],
            [-116.3685, 41.9963],
            [-116.8623, 41.9987],
            [-116.8614, 42.3302],
            [-116.6301, 42.3308],
            [-116.6297, 42.6791],
            [-116.5104, 42.6794],
            [-116.5112, 42.7657],
            [-116.7455, 42.7641],
            [-116.7486, 42.9374],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600450',
        NAME: 'Bruneau-Grand View Joint School District 365',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13448796688,
        AWATER: 66503244,
        INTPTLAT: '+42.5109704',
        INTPTLON: '-116.0020642',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0372, 42.8945],
            [-114.9054, 42.8627],
            [-114.9489, 42.7539],
            [-114.8468, 42.7249],
            [-114.6847, 42.6608],
            [-114.6925, 42.5257],
            [-114.7315, 42.5132],
            [-114.7996, 42.5131],
            [-114.8995, 42.6291],
            [-115.0376, 42.6289],
            [-115.0377, 42.6824],
            [-115.0377, 42.7685],
            [-115.0372, 42.8945],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600480',
        NAME: 'Buhl Joint School District 412',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604598296,
        AWATER: 5764848,
        INTPTLAT: '+42.6874142',
        INTPTLON: '-114.8799790',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.8627, 43.6453],
            [-113.7274, 43.8263],
            [-113.6559, 43.7825],
            [-113.3457, 43.7987],
            [-113.3563, 43.945],
            [-113.4449, 43.9452],
            [-113.4569, 44.0249],
            [-113.4163, 44.0592],
            [-113.4168, 44.2333],
            [-113.3207, 44.233],
            [-112.9954, 44.234],
            [-112.9942, 43.9724],
            [-112.695, 43.9719],
            [-112.6974, 43.623],
            [-112.6991, 43.5402],
            [-112.8162, 43.5359],
            [-112.8167, 43.449],
            [-112.9368, 43.4494],
            [-112.9472, 43.2847],
            [-113.0073, 43.2848],
            [-113.3619, 43.2847],
            [-113.3619, 43.3672],
            [-113.6354, 43.3676],
            [-113.6343, 43.4973],
            [-113.7006, 43.5848],
            [-113.8061, 43.5717],
            [-113.8627, 43.6453],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600490',
        NAME: 'Butte County Joint School District 111',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6097575710,
        AWATER: 5391782,
        INTPTLAT: '+43.6851010',
        INTPTLON: '-113.1776308',
        ELSDLEA: '',
        UNSDLEA: '00490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7332, 43.69],
            [-116.7184, 43.6784],
            [-116.6531, 43.6958],
            [-116.6331, 43.634],
            [-116.7331, 43.6333],
            [-116.7332, 43.69],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600510',
        NAME: 'Caldwell School District 132',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57331485,
        AWATER: 584141,
        INTPTLAT: '+43.6571274',
        INTPTLON: '-116.6923127',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0866, 43.3703],
            [-115.0831, 43.6043],
            [-115.0331, 43.635],
            [-115.0422, 43.7455],
            [-114.9894, 43.8586],
            [-114.7602, 43.7917],
            [-114.7044, 43.8078],
            [-114.6612, 43.6554],
            [-114.683, 43.5942],
            [-114.5871, 43.5581],
            [-114.5346, 43.3333],
            [-114.3946, 43.3258],
            [-114.3749, 43.1996],
            [-114.545, 43.1986],
            [-114.8924, 43.198],
            [-115.0865, 43.1984],
            [-115.0866, 43.3703],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600540',
        NAME: 'Camas County School District 121',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2782922931,
        AWATER: 11535218,
        INTPTLAT: '+43.4633958',
        INTPTLON: '-114.8044265',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0794, 44.6887],
            [-117.0442, 44.7451],
            [-116.9347, 44.7839],
            [-116.8997, 44.8406],
            [-116.6248, 44.8378],
            [-116.6035, 44.6148],
            [-116.1711, 44.6291],
            [-116.1711, 44.6288],
            [-116.1571, 44.499],
            [-116.2998, 44.4441],
            [-116.4075, 44.4438],
            [-116.5196, 44.4878],
            [-116.6517, 44.4987],
            [-116.717, 44.5566],
            [-116.8776, 44.5716],
            [-116.8776, 44.673],
            [-117.0813, 44.6736],
            [-117.0794, 44.6887],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600570',
        NAME: 'Cambridge Joint School District 432',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1437976800,
        AWATER: 12858742,
        INTPTLAT: '+44.6452085',
        INTPTLON: '-116.6224885',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8475, 43.6908],
            [-116.7332, 43.69],
            [-116.7331, 43.6333],
            [-116.6331, 43.634],
            [-116.6531, 43.6958],
            [-116.5125, 43.6816],
            [-116.4935, 43.605],
            [-116.6927, 43.5897],
            [-116.673, 43.4591],
            [-116.7129, 43.4302],
            [-116.717, 43.4302],
            [-116.7524, 43.531],
            [-116.8419, 43.5933],
            [-116.8624, 43.6327],
            [-116.8475, 43.6908],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600600',
        NAME: 'Vallivue School District 139',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357635776,
        AWATER: 15436533,
        INTPTLAT: '+43.5988520',
        INTPTLON: '-116.7031065',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1571, 44.499],
            [-116.1711, 44.6288],
            [-115.7866, 44.6292],
            [-115.7863, 44.6723],
            [-115.1549, 44.6738],
            [-115.2834, 44.6022],
            [-115.2282, 44.4229],
            [-115.2945, 44.3394],
            [-115.4572, 44.2429],
            [-115.5202, 44.2352],
            [-116.1031, 44.2366],
            [-116.1134, 44.1478],
            [-116.2121, 44.1514],
            [-116.2121, 44.3243],
            [-116.1523, 44.3244],
            [-116.1571, 44.499],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600630',
        NAME: 'Cascade School District 422',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3263699684,
        AWATER: 101075298,
        INTPTLAT: '+44.4620581',
        INTPTLON: '-115.7483573',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2866, 42.3323],
            [-114.2858, 42.3746],
            [-114.0496, 42.374],
            [-114.0099, 42.4749],
            [-114.0692, 42.5217],
            [-113.9906, 42.5323],
            [-113.89, 42.5495],
            [-113.7397, 42.523],
            [-113.5893, 42.5905],
            [-113.3557, 42.5896],
            [-113.3485, 42.6627],
            [-113.2568, 42.6178],
            [-113.1562, 42.5893],
            [-113.0028, 42.588],
            [-113.0008, 42.3275],
            [-113.0008, 41.9982],
            [-113.8149, 41.9887],
            [-114.0415, 41.9939],
            [-114.2819, 41.9942],
            [-114.2811, 42.2442],
            [-114.2866, 42.3323],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600660',
        NAME: 'Cassia County Joint School District 151',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6361450362,
        AWATER: 19602464,
        INTPTLAT: '+42.2823130',
        INTPTLON: '-113.6263276',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0377, 42.6824],
            [-115.0376, 42.6289],
            [-114.8995, 42.6291],
            [-114.7996, 42.5131],
            [-114.7315, 42.5132],
            [-114.7509, 42.4938],
            [-114.7567, 42.262],
            [-114.7394, 42.1577],
            [-114.93, 42.1577],
            [-115.0379, 42.2456],
            [-115.0376, 42.5791],
            [-115.1038, 42.5796],
            [-115.1034, 42.6823],
            [-115.0377, 42.6824],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600690',
        NAME: 'Castleford School District 417',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1168000397,
        AWATER: 1256874,
        INTPTLAT: '+42.3931586',
        INTPTLON: '-114.9046171',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.1549, 44.6738],
            [-115.086, 44.7713],
            [-114.9564, 44.7211],
            [-114.8124, 44.8081],
            [-114.8152, 44.7353],
            [-114.7113, 44.6506],
            [-114.6151, 44.6428],
            [-114.5677, 44.5759],
            [-114.4508, 44.6132],
            [-114.3866, 44.671],
            [-114.3883, 44.7525],
            [-114.2251, 44.8643],
            [-114.2157, 44.7454],
            [-114.0494, 44.7111],
            [-113.9963, 44.7405],
            [-113.8399, 44.7398],
            [-113.834, 44.699],
            [-113.6922, 44.6626],
            [-113.5342, 44.5419],
            [-113.5504, 44.479],
            [-113.4526, 44.4555],
            [-113.341, 44.3188],
            [-113.3207, 44.233],
            [-113.4168, 44.2333],
            [-113.4163, 44.0592],
            [-113.4569, 44.0249],
            [-113.5372, 44.0041],
            [-113.765, 44.0883],
            [-113.7831, 44.1627],
            [-113.9072, 44.2739],
            [-114.0142, 44.1506],
            [-114.09, 44.1249],
            [-114.2018, 43.9808],
            [-114.391, 43.9397],
            [-114.4099, 43.8956],
            [-114.6062, 43.8935],
            [-114.6812, 43.9278],
            [-114.8138, 43.9278],
            [-114.8734, 43.9929],
            [-114.9713, 43.9381],
            [-114.9907, 43.9497],
            [-115.0297, 44.0179],
            [-115.0262, 44.1584],
            [-115.1692, 44.206],
            [-115.179, 44.2964],
            [-115.2945, 44.3394],
            [-115.2282, 44.4229],
            [-115.2834, 44.6022],
            [-115.1549, 44.6738],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600720',
        NAME: 'Challis Joint School District 181',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10513681221,
        AWATER: 34882239,
        INTPTLAT: '+44.3648677',
        INTPTLON: '-114.3244214',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.9954, 44.234],
            [-112.8139, 44.2304],
            [-112.8138, 44.3765],
            [-112.8397, 44.424],
            [-112.7179, 44.5043],
            [-112.4734, 44.4794],
            [-112.2887, 44.5679],
            [-112.0965, 44.523],
            [-111.8637, 44.5512],
            [-111.8011, 44.526],
            [-111.6173, 44.5504],
            [-111.6178, 44.4016],
            [-111.7916, 44.4015],
            [-111.8524, 44.3169],
            [-111.858, 44.1878],
            [-111.9135, 44.1441],
            [-112.0337, 44.144],
            [-112.0343, 44.0573],
            [-112.156, 44.0577],
            [-112.6947, 44.0585],
            [-112.695, 43.9719],
            [-112.9942, 43.9724],
            [-112.9954, 44.234],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600750',
        NAME: 'Clark County School District 161',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4567262180,
        AWATER: 2476454,
        INTPTLAT: '+44.2902180',
        INTPTLON: '-112.3546128',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0411, 47.6218],
            [-116.8942, 47.6656],
            [-116.8406, 47.7519],
            [-116.7649, 47.8022],
            [-116.3245, 47.8047],
            [-116.324, 47.7352],
            [-116.5139, 47.7313],
            [-116.5251, 47.5843],
            [-116.7207, 47.5843],
            [-116.8608, 47.4776],
            [-116.9579, 47.5709],
            [-117.0409, 47.5715],
            [-117.0409, 47.5798],
            [-117.0411, 47.6218],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600780',
        NAME: "Coeur d'Alene School District 271",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 827336060,
        AWATER: 78744312,
        INTPTLAT: '+47.6771129',
        INTPTLON: '-116.6949184',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.6151, 46.0328],
            [-116.5145, 46.0811],
            [-116.4284, 46.2026],
            [-116.3594, 46.185],
            [-116.2021, 46.153],
            [-116.191, 46.0741],
            [-116.3256, 45.9976],
            [-116.4434, 45.9623],
            [-116.5528, 45.9751],
            [-116.6151, 46.0328],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600810',
        NAME: 'Cottonwood Joint School District 242',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540307283,
        AWATER: 927039,
        INTPTLAT: '+46.0590974',
        INTPTLON: '-116.3710054',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0409, 47.5715],
            [-116.9579, 47.5709],
            [-116.8608, 47.4776],
            [-116.7496, 47.3859],
            [-116.7355, 47.3116],
            [-116.8322, 47.2506],
            [-116.7894, 47.1487],
            [-116.6806, 47.1521],
            [-116.6501, 47.0375],
            [-116.8354, 47.0326],
            [-117.0397, 47.1273],
            [-117.0399, 47.3105],
            [-117.0399, 47.4052],
            [-117.0409, 47.5715],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600815',
        NAME: 'Plummer-Worley Joint School District 44',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1046761032,
        AWATER: 16479347,
        INTPTLAT: '+47.3022286',
        INTPTLON: '-116.8707743',
        ELSDLEA: '',
        UNSDLEA: '00815',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7839, 45.079],
            [-116.7242, 45.1649],
            [-116.3931, 45.1653],
            [-116.3929, 45.1072],
            [-116.5149, 45.1069],
            [-116.5153, 44.962],
            [-116.3514, 44.89],
            [-116.1739, 44.8904],
            [-116.2111, 44.7808],
            [-116.1571, 44.7033],
            [-116.1711, 44.6291],
            [-116.6035, 44.6148],
            [-116.6248, 44.8378],
            [-116.8997, 44.8406],
            [-116.832, 44.933],
            [-116.848, 45.0217],
            [-116.7839, 45.079],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600840',
        NAME: 'Council School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1960063256,
        AWATER: 7827557,
        INTPTLAT: '+44.8729972',
        INTPTLON: '-116.5047184',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.484, 46.4572],
            [-116.4693, 46.4462],
            [-116.661, 46.3253],
            [-116.7025, 46.2378],
            [-116.7474, 46.3332],
            [-116.6829, 46.476],
            [-116.619, 46.4957],
            [-116.484, 46.4572],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600870',
        NAME: 'Culdesac Joint School District 342',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293845461,
        AWATER: 5358,
        INTPTLAT: '+46.3927544',
        INTPTLON: '-116.6392157',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.9538, 42.8935],
            [-113.9537, 42.8501],
            [-114.0272, 42.765],
            [-114.0401, 42.8374],
            [-114.326, 42.8238],
            [-114.295, 42.9381],
            [-114.0529, 42.9376],
            [-113.9538, 42.8935],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600900',
        NAME: 'Dietrich School District 314',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309025608,
        AWATER: 1769559,
        INTPTLAT: '+42.8750124',
        INTPTLON: '-114.1550296',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.083, 43.6262],
            [-111.8339, 43.6268],
            [-111.8244, 43.5691],
            [-111.5653, 43.5689],
            [-111.5661, 43.4657],
            [-111.588, 43.2816],
            [-111.7058, 43.2822],
            [-111.7057, 43.1956],
            [-111.8226, 43.1952],
            [-111.8226, 43.3661],
            [-111.9441, 43.3661],
            [-111.9642, 43.3954],
            [-112.0037, 43.526],
            [-112.083, 43.6262],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600930',
        NAME: 'Bonneville Joint School District 93',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1146869292,
        AWATER: 6119277,
        INTPTLAT: '+43.4305519',
        INTPTLON: '-111.7829095',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9839, 42.2566],
            [-111.8974, 42.2636],
            [-111.8952, 42.4161],
            [-111.8364, 42.416],
            [-111.8183, 42.2855],
            [-111.7407, 42.3213],
            [-111.5779, 42.3283],
            [-111.6262, 42.2086],
            [-111.5944, 42.0935],
            [-111.515, 42.068],
            [-111.5078, 41.9996],
            [-111.9336, 41.9986],
            [-111.9095, 42.0708],
            [-111.9839, 42.2566],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1600960',
        NAME: 'Preston Joint School District 201',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1087400372,
        AWATER: 8113801,
        INTPTLAT: '+42.1642235',
        INTPTLON: '-111.7603770',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.5322, 44.0661],
            [-116.452, 44.1514],
            [-116.3585, 44.1511],
            [-116.3417, 44.3112],
            [-116.2998, 44.4441],
            [-116.1571, 44.499],
            [-116.1523, 44.3244],
            [-116.2121, 44.3243],
            [-116.2121, 44.1514],
            [-116.2165, 44.1514],
            [-116.2733, 44.109],
            [-116.2817, 43.8069],
            [-116.5126, 43.8072],
            [-116.7127, 43.8072],
            [-116.7126, 43.9813],
            [-116.5722, 43.9803],
            [-116.5322, 44.0661],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601020',
        NAME: 'Emmett Independent School District 221',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1464110984,
        AWATER: 12443645,
        INTPTLAT: '+44.0411869',
        INTPTLON: '-116.3606092',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.7394, 42.1577],
            [-114.7567, 42.262],
            [-114.7509, 42.4938],
            [-114.7315, 42.5132],
            [-114.6925, 42.5257],
            [-114.6847, 42.6608],
            [-114.6346, 42.6543],
            [-114.5392, 42.6336],
            [-114.5551, 42.4913],
            [-114.5159, 42.4038],
            [-114.4305, 42.4036],
            [-114.3989, 42.2445],
            [-114.2811, 42.2442],
            [-114.2819, 41.9942],
            [-114.7212, 41.9982],
            [-114.7654, 42.0691],
            [-114.7394, 42.1577],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601050',
        NAME: 'Filer School District 413',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1930246823,
        AWATER: 4283849,
        INTPTLAT: '+42.2503060',
        INTPTLON: '-114.5346691',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2963, 43.3672],
            [-112.1698, 43.3443],
            [-111.9441, 43.3661],
            [-111.8226, 43.3661],
            [-111.8226, 43.1952],
            [-111.7057, 43.1956],
            [-111.7058, 43.2822],
            [-111.588, 43.2816],
            [-111.5896, 43.0201],
            [-111.8256, 43.0206],
            [-111.9986, 43.1722],
            [-112.0434, 43.2626],
            [-112.2964, 43.2617],
            [-112.2963, 43.3672],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601080',
        NAME: 'Firth School District 59',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1165171354,
        AWATER: 3473449,
        INTPTLAT: '+43.1910953',
        INTPTLON: '-111.8724560',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.156, 44.0577],
            [-112.0343, 44.0573],
            [-112.0337, 44.144],
            [-111.9135, 44.1441],
            [-111.858, 44.1878],
            [-111.8524, 44.3169],
            [-111.7916, 44.4015],
            [-111.6178, 44.4016],
            [-111.6173, 44.5504],
            [-111.5256, 44.6047],
            [-111.475, 44.7088],
            [-111.377, 44.7514],
            [-111.2193, 44.6225],
            [-111.1307, 44.5001],
            [-111.0524, 44.4784],
            [-111.047, 43.9835],
            [-111.1871, 43.9322],
            [-111.3989, 43.9231],
            [-111.4598, 43.9174],
            [-111.6288, 43.8689],
            [-111.8311, 43.909],
            [-111.917, 44.0004],
            [-112.1074, 43.9719],
            [-112.156, 44.0577],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601110',
        NAME: 'Fremont County Joint School District 215',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4706474852,
        AWATER: 82174130,
        INTPTLAT: '+44.2141177',
        INTPTLON: '-111.4655727',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9691, 43.9377],
            [-116.9517, 44.0396],
            [-116.8564, 44.0238],
            [-116.8531, 43.8074],
            [-116.9838, 43.88],
            [-116.9691, 43.9377],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601140',
        NAME: 'Fruitland School District 373',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190470538,
        AWATER: 2602882,
        INTPTLAT: '+43.9215444',
        INTPTLON: '-116.9143681',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.2165, 44.1514],
            [-116.2121, 44.1514],
            [-116.1134, 44.1478],
            [-116.1031, 44.2366],
            [-115.5202, 44.2352],
            [-115.4572, 44.2429],
            [-115.2945, 44.3394],
            [-115.179, 44.2964],
            [-115.1692, 44.206],
            [-115.0262, 44.1584],
            [-115.0297, 44.0179],
            [-114.9907, 43.9497],
            [-115.0744, 43.944],
            [-115.2016, 44.0741],
            [-115.8003, 44.0663],
            [-115.8997, 43.994],
            [-116.0379, 43.9798],
            [-116.0384, 44.1091],
            [-116.1557, 44.0516],
            [-116.2165, 44.1514],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601170',
        NAME: 'Garden Valley School District 71',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2035603481,
        AWATER: 8636110,
        INTPTLAT: '+44.1527035',
        INTPTLON: '-115.5620914',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0396, 46.6381],
            [-116.8117, 46.6715],
            [-116.7704, 46.6283],
            [-116.7294, 46.5426],
            [-116.7818, 46.4808],
            [-116.9115, 46.4642],
            [-117.0398, 46.457],
            [-117.0396, 46.6381],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601200',
        NAME: 'Genesee Joint School District 282',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463012869,
        AWATER: 54395,
        INTPTLAT: '+46.5633697',
        INTPTLON: '-116.8842432',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.6381, 42.9558],
            [-115.5606, 42.9347],
            [-115.5591, 43.1408],
            [-115.5004, 43.1412],
            [-115.4997, 43.2851],
            [-115.4406, 43.315],
            [-115.1452, 43.3142],
            [-115.0866, 43.3703],
            [-115.0865, 43.1984],
            [-115.0868, 42.9147],
            [-115.0372, 42.8945],
            [-115.0377, 42.7685],
            [-115.4543, 42.7677],
            [-115.4543, 42.8267],
            [-115.6497, 42.8259],
            [-115.6381, 42.9558],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601230',
        NAME: 'Glenns Ferry Joint School District 192',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2333831523,
        AWATER: 13826129,
        INTPTLAT: '+43.0419511',
        INTPTLON: '-115.3152051',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.8924, 43.198],
            [-114.545, 43.1986],
            [-114.546, 42.9813],
            [-114.5944, 42.8508],
            [-114.6354, 42.8508],
            [-114.7903, 42.8391],
            [-114.8415, 42.8804],
            [-114.8916, 42.9385],
            [-114.8924, 43.198],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601260',
        NAME: 'Gooding Joint School District 231',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1000154018,
        AWATER: 3489728,
        INTPTLAT: '+43.0344155',
        INTPTLON: '-114.7202777',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9171, 42.589],
            [-111.8379, 42.6564],
            [-111.7203, 42.6507],
            [-111.7009, 42.5905],
            [-111.6041, 42.5906],
            [-111.6349, 42.5701],
            [-111.5588, 42.3488],
            [-111.5779, 42.3283],
            [-111.7407, 42.3213],
            [-111.8183, 42.2855],
            [-111.8364, 42.416],
            [-111.9358, 42.5246],
            [-111.9171, 42.589],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601290',
        NAME: 'Grace Joint School District 148',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 773283668,
        AWATER: 2717950,
        INTPTLAT: '+42.4768436',
        INTPTLON: '-111.7521481',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.9054, 42.8627],
            [-114.8415, 42.8804],
            [-114.7903, 42.8391],
            [-114.8468, 42.7249],
            [-114.9489, 42.7539],
            [-114.9054, 42.8627],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601380',
        NAME: 'Hagerman Joint School District 233',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135027006,
        AWATER: 5114286,
        INTPTLAT: '+42.8048340',
        INTPTLON: '-114.8680175',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.3258, 42.5724],
            [-114.1902, 42.5379],
            [-114.2295, 42.4173],
            [-114.2858, 42.3746],
            [-114.2866, 42.3323],
            [-114.3255, 42.3322],
            [-114.3258, 42.5724],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601410',
        NAME: 'Hansen School District 415',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160866176,
        AWATER: 1207412,
        INTPTLAT: '+42.4926807',
        INTPTLON: '-114.2595123',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7025, 46.2378],
            [-116.661, 46.3253],
            [-116.4693, 46.4462],
            [-116.3946, 46.435],
            [-116.3477, 46.3539],
            [-116.3594, 46.185],
            [-116.4284, 46.2026],
            [-116.5145, 46.0811],
            [-116.6151, 46.0328],
            [-116.702, 45.9962],
            [-116.7025, 46.2378],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601440',
        NAME: 'Highland Joint School District 305',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 901706753,
        AWATER: 1362307,
        INTPTLAT: '+46.2283183',
        INTPTLON: '-116.5442220',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0265, 43.6809],
            [-116.9916, 43.6714],
            [-116.8624, 43.6327],
            [-116.8419, 43.5933],
            [-116.8711, 43.5453],
            [-117.027, 43.5457],
            [-117.0265, 43.6809],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601470',
        NAME: 'Homedale Joint School District 370',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156727472,
        AWATER: 3633503,
        INTPTLAT: '+43.5996136',
        INTPTLON: '-116.9483516',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.2165, 44.1514],
            [-116.1557, 44.0516],
            [-116.0384, 44.1091],
            [-116.0379, 43.9798],
            [-116.0351, 43.7045],
            [-116.1414, 43.705],
            [-116.2488, 43.7832],
            [-116.2817, 43.8069],
            [-116.2733, 44.109],
            [-116.2165, 44.1514],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601500',
        NAME: 'Horseshoe Bend School District 73',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 730415785,
        AWATER: 1953388,
        INTPTLAT: '+43.9118705',
        INTPTLON: '-116.1304179',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5199, 43.6266],
            [-112.4627, 43.6266],
            [-112.083, 43.6262],
            [-112.0037, 43.526],
            [-111.9642, 43.3954],
            [-112.0916, 43.4536],
            [-112.2824, 43.4251],
            [-112.52, 43.4251],
            [-112.5199, 43.6266],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601530',
        NAME: 'Idaho Falls School District 91',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878351757,
        AWATER: 4449938,
        INTPTLAT: '+43.5232370',
        INTPTLON: '-112.2726368',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9774, 43.8268],
            [-111.9328, 43.7668],
            [-111.786, 43.6869],
            [-111.8339, 43.6268],
            [-112.083, 43.6262],
            [-112.4627, 43.6266],
            [-112.4618, 43.7582],
            [-112.2232, 43.7566],
            [-112.1952, 43.8307],
            [-111.9774, 43.8268],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601570',
        NAME: 'Jefferson County Joint School District 251',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878270061,
        AWATER: 13181867,
        INTPTLAT: '+43.7021004',
        INTPTLON: '-112.1831384',
        ELSDLEA: '',
        UNSDLEA: '01570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6354, 42.8508],
            [-114.5944, 42.8508],
            [-114.3465, 42.8509],
            [-114.3453, 42.7659],
            [-114.0272, 42.765],
            [-113.9537, 42.8501],
            [-113.8915, 42.7649],
            [-114.0029, 42.7214],
            [-114.3169, 42.7241],
            [-114.3447, 42.5852],
            [-114.4028, 42.5955],
            [-114.5392, 42.6336],
            [-114.6346, 42.6543],
            [-114.6354, 42.8508],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601590',
        NAME: 'Jerome Joint School District 261',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 807291248,
        AWATER: 4628399,
        INTPTLAT: '+42.7419367',
        INTPTLON: '-114.3938641',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1586, 46.3034],
            [-116.053, 46.3555],
            [-115.9991, 46.3062],
            [-115.7655, 46.2824],
            [-115.765, 46.1931],
            [-115.9947, 46.2013],
            [-116.056, 46.095],
            [-116.1187, 46.1208],
            [-116.1586, 46.3034],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601620',
        NAME: 'Kamiah Joint School District 304',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444326213,
        AWATER: 2843329,
        INTPTLAT: '+46.2375369',
        INTPTLON: '-116.0089636',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.3245, 47.8047],
            [-116.3245, 47.8052],
            [-116.008, 47.8043],
            [-116.008, 47.7176],
            [-116.1565, 47.7182],
            [-116.1582, 47.6309],
            [-116.0325, 47.6013],
            [-116.0426, 47.3707],
            [-116.3294, 47.3707],
            [-116.4857, 47.4129],
            [-116.4803, 47.5285],
            [-116.5251, 47.5843],
            [-116.5139, 47.7313],
            [-116.324, 47.7352],
            [-116.3245, 47.8047],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601650',
        NAME: 'Kellogg Joint School District 391',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1460959297,
        AWATER: 5691168,
        INTPTLAT: '+47.5832824',
        INTPTLON: '-116.2577458',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7704, 46.6283],
            [-116.6611, 46.6677],
            [-116.6046, 46.7294],
            [-116.3294, 46.6864],
            [-116.3293, 46.6567],
            [-116.3929, 46.5859],
            [-116.519, 46.5618],
            [-116.7294, 46.5426],
            [-116.7704, 46.6283],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601680',
        NAME: 'Kendrick Joint School District 283',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430838050,
        AWATER: 325338,
        INTPTLAT: '+46.6375984',
        INTPTLON: '-116.5508935',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.4305, 42.4036],
            [-114.4028, 42.5955],
            [-114.3447, 42.5852],
            [-114.3258, 42.5724],
            [-114.3255, 42.3322],
            [-114.2866, 42.3323],
            [-114.2811, 42.2442],
            [-114.3989, 42.2445],
            [-114.4305, 42.4036],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601710',
        NAME: 'Kimberly School District 414',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288981877,
        AWATER: 1054726,
        INTPTLAT: '+42.3945175',
        INTPTLON: '-114.3608332',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.5251, 47.5843],
            [-116.4803, 47.5285],
            [-116.4857, 47.4129],
            [-116.5812, 47.3714],
            [-116.7496, 47.3859],
            [-116.8608, 47.4776],
            [-116.7207, 47.5843],
            [-116.5251, 47.5843],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601740',
        NAME: 'Kootenai Joint School District 274',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 483801391,
        AWATER: 58384371,
        INTPTLAT: '+47.4974546',
        INTPTLON: '-116.6487485',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.4735, 43.583],
            [-116.3146, 43.5321],
            [-116.2542, 43.4155],
            [-116.2552, 43.2003],
            [-116.3856, 43.1973],
            [-116.3939, 43.4371],
            [-116.5732, 43.452],
            [-116.4735, 43.5176],
            [-116.4735, 43.583],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601770',
        NAME: 'Kuna Joint School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 532002812,
        AWATER: 2144586,
        INTPTLAT: '+43.3862972',
        INTPTLON: '-116.3609251',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0426, 47.8745],
            [-117.0423, 47.9584],
            [-117.0422, 47.9774],
            [-116.767, 47.9774],
            [-116.5042, 47.9915],
            [-116.505, 47.8902],
            [-116.3228, 47.8903],
            [-116.3245, 47.8052],
            [-116.3245, 47.8047],
            [-116.7649, 47.8022],
            [-116.8406, 47.7519],
            [-117.0416, 47.7311],
            [-117.0426, 47.8745],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601800',
        NAME: 'Lakeland Joint School District 272',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1001663016,
        AWATER: 26066615,
        INTPTLAT: '+47.8824840',
        INTPTLON: '-116.7391911',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.955, 46.1949],
            [-116.8425, 46.2419],
            [-116.8789, 46.448],
            [-116.9115, 46.4642],
            [-116.7818, 46.4808],
            [-116.7294, 46.5426],
            [-116.519, 46.5618],
            [-116.484, 46.4572],
            [-116.619, 46.4957],
            [-116.6829, 46.476],
            [-116.7474, 46.3332],
            [-116.7025, 46.2378],
            [-116.702, 45.9962],
            [-116.7946, 45.856],
            [-116.796, 45.859],
            [-116.916, 45.9954],
            [-116.9595, 46.0991],
            [-116.955, 46.1949],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601830',
        NAME: 'Lapwai School District 341',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1020960231,
        AWATER: 11669995,
        INTPTLAT: '+46.2356165',
        INTPTLON: '-116.7879900',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0526, 46.3747],
            [-117.0398, 46.4543],
            [-117.0398, 46.457],
            [-116.9115, 46.4642],
            [-116.8789, 46.448],
            [-116.8425, 46.2419],
            [-116.955, 46.1949],
            [-117.0526, 46.3747],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601860',
        NAME: 'Lewiston Independent School District 340',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361148752,
        AWATER: 8620789,
        INTPTLAT: '+46.3294882',
        INTPTLON: '-116.9365795',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.4569, 44.0249],
            [-113.4449, 43.9452],
            [-113.3563, 43.945],
            [-113.3457, 43.7987],
            [-113.6559, 43.7825],
            [-113.7274, 43.8263],
            [-113.8627, 43.6453],
            [-114.4099, 43.8956],
            [-114.391, 43.9397],
            [-114.2018, 43.9808],
            [-114.09, 44.1249],
            [-114.0142, 44.1506],
            [-113.9072, 44.2739],
            [-113.7831, 44.1627],
            [-113.765, 44.0883],
            [-113.5372, 44.0041],
            [-113.4569, 44.0249],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601900',
        NAME: 'Mackay Joint School District 182',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2657118708,
        AWATER: 7700843,
        INTPTLAT: '+43.9435803',
        INTPTLON: '-113.8812118',
        ELSDLEA: '',
        UNSDLEA: '01900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9774, 43.8268],
            [-111.8544, 43.8788],
            [-111.7182, 43.8116],
            [-111.4994, 43.8117],
            [-111.4598, 43.9174],
            [-111.3989, 43.9231],
            [-111.3999, 43.622],
            [-111.4686, 43.622],
            [-111.6264, 43.6267],
            [-111.786, 43.6869],
            [-111.9328, 43.7668],
            [-111.9774, 43.8268],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601920',
        NAME: 'Madison School District 321',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 880074078,
        AWATER: 7787885,
        INTPTLAT: '+43.7428668',
        INTPTLON: '-111.6526073',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4576, 42.7187],
            [-112.3016, 42.7189],
            [-112.3007, 42.9058],
            [-112.1364, 42.9058],
            [-112.1452, 42.7907],
            [-112.1055, 42.6992],
            [-111.9694, 42.718],
            [-111.9171, 42.589],
            [-111.9358, 42.5246],
            [-111.8364, 42.416],
            [-111.8952, 42.4161],
            [-111.8974, 42.2636],
            [-111.9839, 42.2566],
            [-112.1263, 42.2853],
            [-112.1907, 42.3512],
            [-112.2939, 42.3866],
            [-112.42, 42.5029],
            [-112.3896, 42.6241],
            [-112.4576, 42.7187],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601950',
        NAME: 'Marsh Valley Joint School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2031646330,
        AWATER: 2600166,
        INTPTLAT: '+42.5610658',
        INTPTLON: '-112.1415877',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.027, 43.5457],
            [-116.8711, 43.5453],
            [-116.8419, 43.5933],
            [-116.7524, 43.531],
            [-116.717, 43.4302],
            [-116.7489, 43.2859],
            [-116.8674, 43.2859],
            [-116.8674, 43.1991],
            [-116.9466, 43.1992],
            [-116.9471, 43.315],
            [-117.0268, 43.3153],
            [-117.0267, 43.4208],
            [-117.027, 43.5457],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1601980',
        NAME: 'Marsing Joint School District 363',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 739946150,
        AWATER: 4894669,
        INTPTLAT: '+43.3995788',
        INTPTLON: '-116.8994801',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1739, 44.8904],
            [-116.0894, 45.0637],
            [-116.1445, 45.1076],
            [-116.0074, 45.1954],
            [-114.6941, 45.1971],
            [-114.7315, 45.1528],
            [-114.7322, 44.8804],
            [-114.8124, 44.8081],
            [-114.9564, 44.7211],
            [-115.086, 44.7713],
            [-115.1549, 44.6738],
            [-115.7863, 44.6723],
            [-115.7866, 44.6292],
            [-116.1711, 44.6288],
            [-116.1711, 44.6291],
            [-116.1571, 44.7033],
            [-116.2111, 44.7808],
            [-116.1739, 44.8904],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602030',
        NAME: 'McCall-Donnelly Joint School District 421',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6231432991,
        AWATER: 76199997,
        INTPTLAT: '+44.9224290',
        INTPTLON: '-115.4552148',
        ELSDLEA: '',
        UNSDLEA: '02030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.687, 45.2679],
            [-116.3929, 45.268],
            [-116.393, 45.1671],
            [-116.3048, 45.1089],
            [-116.1445, 45.1076],
            [-116.0894, 45.0637],
            [-116.1739, 44.8904],
            [-116.3514, 44.89],
            [-116.5153, 44.962],
            [-116.5149, 45.1069],
            [-116.3929, 45.1072],
            [-116.3931, 45.1653],
            [-116.7242, 45.1649],
            [-116.687, 45.2679],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602060',
        NAME: 'Meadows Valley School District 11',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 999110308,
        AWATER: 7688635,
        INTPTLAT: '+45.0753667',
        INTPTLON: '-116.3847881',
        ELSDLEA: '',
        UNSDLEA: '02060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8674, 43.1991],
            [-116.8674, 43.2859],
            [-116.7489, 43.2859],
            [-116.717, 43.4302],
            [-116.7129, 43.4302],
            [-116.5732, 43.452],
            [-116.3939, 43.4371],
            [-116.3856, 43.1973],
            [-116.3758, 43.1852],
            [-116.5305, 43.0854],
            [-116.6092, 43.0825],
            [-116.6278, 42.9382],
            [-116.7486, 42.9374],
            [-116.7881, 42.9373],
            [-116.7869, 43.111],
            [-116.8674, 43.1111],
            [-116.8674, 43.1991],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602070',
        NAME: 'Melba Joint School District 136',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1440842463,
        AWATER: 8203812,
        INTPTLAT: '+43.2040025',
        INTPTLON: '-116.6227304',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.5126, 43.8072],
            [-116.2817, 43.8069],
            [-116.2488, 43.7832],
            [-116.3192, 43.6121],
            [-116.259, 43.4989],
            [-116.1344, 43.4984],
            [-116.137, 43.2001],
            [-116.2552, 43.2003],
            [-116.2542, 43.4155],
            [-116.3146, 43.5321],
            [-116.4735, 43.583],
            [-116.4935, 43.605],
            [-116.5125, 43.6816],
            [-116.5126, 43.8072],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602100',
        NAME: 'Meridian Joint School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 857711537,
        AWATER: 4000310,
        INTPTLAT: '+43.5503826',
        INTPTLON: '-116.3088816',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7531, 43.8001],
            [-116.7127, 43.8072],
            [-116.5126, 43.8072],
            [-116.5125, 43.6816],
            [-116.6531, 43.6958],
            [-116.7184, 43.6784],
            [-116.7531, 43.8001],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602130',
        NAME: 'Middleton School District 134',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232455867,
        AWATER: 968453,
        INTPTLAT: '+43.7530385',
        INTPTLON: '-116.6233104',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8776, 44.5716],
            [-116.717, 44.5566],
            [-116.6517, 44.4987],
            [-116.5196, 44.4878],
            [-116.4075, 44.4438],
            [-116.2998, 44.4441],
            [-116.3417, 44.3112],
            [-116.3585, 44.1511],
            [-116.452, 44.1514],
            [-116.5532, 44.1517],
            [-116.5529, 44.2379],
            [-116.6735, 44.2383],
            [-116.6732, 44.3544],
            [-116.8535, 44.3538],
            [-116.8776, 44.5716],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602160',
        NAME: 'Midvale School District 433',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1187941887,
        AWATER: 17562682,
        INTPTLAT: '+44.3851832',
        INTPTLON: '-116.5887611',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0029, 42.7214],
            [-113.8915, 42.7649],
            [-113.9537, 42.8501],
            [-113.9538, 42.8935],
            [-113.9541, 42.9371],
            [-113.8341, 42.9367],
            [-113.8336, 42.871],
            [-113.7141, 42.8849],
            [-113.7147, 43.2],
            [-113.4137, 43.1998],
            [-113.413, 42.8492],
            [-113.4722, 42.8492],
            [-113.4722, 42.6693],
            [-113.3485, 42.6627],
            [-113.3557, 42.5896],
            [-113.5893, 42.5905],
            [-113.7397, 42.523],
            [-113.89, 42.5495],
            [-113.9906, 42.5323],
            [-114.0301, 42.5918],
            [-114.0029, 42.7214],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602190',
        NAME: 'Minidoka County Joint School District 331',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2469998889,
        AWATER: 35493083,
        INTPTLAT: '+42.8259850',
        INTPTLON: '-113.6630999',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0397, 46.8222],
            [-117.0395, 46.8734],
            [-116.9058, 46.8734],
            [-116.8549, 46.8165],
            [-116.8117, 46.6715],
            [-117.0396, 46.6381],
            [-117.0396, 46.6423],
            [-117.0397, 46.8222],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602220',
        NAME: 'Moscow School District 281',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311021853,
        AWATER: 90729,
        INTPTLAT: '+46.7637860',
        INTPTLON: '-116.9547881',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.8972, 43.5986],
            [-115.8568, 43.6014],
            [-115.7198, 43.55],
            [-115.6748, 43.4749],
            [-115.4997, 43.3303],
            [-115.4415, 43.3602],
            [-115.4402, 43.611],
            [-115.8222, 43.6119],
            [-115.6351, 43.7132],
            [-115.5475, 43.7927],
            [-115.5138, 43.8807],
            [-115.2873, 43.9355],
            [-115.2016, 44.0741],
            [-115.0744, 43.944],
            [-114.9907, 43.9497],
            [-114.9713, 43.9381],
            [-114.9894, 43.8586],
            [-115.0422, 43.7455],
            [-115.0331, 43.635],
            [-115.0831, 43.6043],
            [-115.0866, 43.3703],
            [-115.1452, 43.3142],
            [-115.4406, 43.315],
            [-115.4997, 43.2851],
            [-115.5004, 43.1412],
            [-115.5591, 43.1408],
            [-115.5606, 42.9347],
            [-115.6381, 42.9558],
            [-115.9639, 42.9866],
            [-115.9787, 43.1134],
            [-115.9758, 43.5913],
            [-115.8972, 43.5986],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602250',
        NAME: 'Mountain Home School District 193',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5022607312,
        AWATER: 48672214,
        INTPTLAT: '+43.2115383',
        INTPTLON: '-115.7180170',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.6986, 47.5023],
            [-115.6295, 47.4796],
            [-115.7466, 47.4423],
            [-115.5936, 47.371],
            [-115.8624, 47.371],
            [-115.8584, 47.494],
            [-115.6986, 47.5023],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602280',
        NAME: 'Mullan School District 392',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199083981,
        AWATER: 250559,
        INTPTLAT: '+47.4431389',
        INTPTLON: '-115.7781974',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.1902, 42.5379],
            [-114.0692, 42.5217],
            [-114.0099, 42.4749],
            [-114.0496, 42.374],
            [-114.2858, 42.3746],
            [-114.2295, 42.4173],
            [-114.1902, 42.5379],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602310',
        NAME: 'Murtaugh Joint School District 418',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268455478,
        AWATER: 830282,
        INTPTLAT: '+42.4380707',
        INTPTLON: '-114.1371586',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.4935, 43.605],
            [-116.4735, 43.583],
            [-116.4735, 43.5176],
            [-116.5732, 43.452],
            [-116.7129, 43.4302],
            [-116.673, 43.4591],
            [-116.6927, 43.5897],
            [-116.4935, 43.605],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602340',
        NAME: 'Nampa School District 131',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240015318,
        AWATER: 13183946,
        INTPTLAT: '+43.5337974',
        INTPTLON: '-116.5980076',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8564, 44.0238],
            [-116.7717, 44.0684],
            [-116.5322, 44.0661],
            [-116.5722, 43.9803],
            [-116.7126, 43.9813],
            [-116.7127, 43.8072],
            [-116.7531, 43.8001],
            [-116.8281, 43.7928],
            [-116.8531, 43.8074],
            [-116.8564, 44.0238],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602370',
        NAME: 'New Plymouth School District 372',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445002062,
        AWATER: 2741017,
        INTPTLAT: '+43.9527068',
        INTPTLON: '-116.7267734',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.1586, 46.3034],
            [-116.1187, 46.1208],
            [-116.056, 46.095],
            [-116.191, 46.0741],
            [-116.2021, 46.153],
            [-116.3594, 46.185],
            [-116.3477, 46.3539],
            [-116.3946, 46.435],
            [-116.2747, 46.4783],
            [-116.1904, 46.3984],
            [-116.1586, 46.3034],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602400',
        NAME: 'Nezperce Joint School District 302',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571986493,
        AWATER: 237883,
        INTPTLAT: '+46.2891281',
        INTPTLON: '-116.2535599',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1364, 42.9058],
            [-112.0631, 43.0224],
            [-111.9438, 43.021],
            [-111.9439, 42.9348],
            [-111.8293, 42.9338],
            [-111.7982, 42.7653],
            [-111.7204, 42.7647],
            [-111.7203, 42.6507],
            [-111.8379, 42.6564],
            [-111.9171, 42.589],
            [-111.9694, 42.718],
            [-112.1055, 42.6992],
            [-112.1452, 42.7907],
            [-112.1364, 42.9058],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602430',
        NAME: 'North Gem School District 149',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 965748863,
        AWATER: 6462511,
        INTPTLAT: '+42.8139694',
        INTPTLON: '-111.9428035',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8917, 43.7198],
            [-116.8281, 43.7928],
            [-116.7531, 43.8001],
            [-116.7184, 43.6784],
            [-116.7332, 43.69],
            [-116.8475, 43.6908],
            [-116.8917, 43.7198],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602460',
        NAME: 'Notus School District 135',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93729925,
        AWATER: 1059103,
        INTPTLAT: '+43.7337157',
        INTPTLON: '-116.7901853',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0008, 42.3275],
            [-112.654, 42.3289],
            [-112.654, 42.416],
            [-112.4978, 42.416],
            [-112.4982, 42.503],
            [-112.42, 42.5029],
            [-112.2939, 42.3866],
            [-112.1907, 42.3512],
            [-112.1263, 42.2853],
            [-112.071, 42.2385],
            [-112.1092, 42.1698],
            [-112.1095, 41.9978],
            [-112.1592, 41.9984],
            [-113.0008, 41.9982],
            [-113.0008, 42.3275],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602490',
        NAME: 'Oneida County School District 351',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3061235818,
        AWATER: 3995238,
        INTPTLAT: '+42.1838954',
        INTPTLON: '-112.5204486',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.3293, 46.6567],
            [-116.0093, 46.6585],
            [-116.0171, 46.705],
            [-115.8913, 46.8478],
            [-115.6386, 46.8464],
            [-115.6385, 46.9339],
            [-114.9593, 46.9329],
            [-114.925, 46.8466],
            [-114.7652, 46.7583],
            [-114.6749, 46.7368],
            [-114.5948, 46.6335],
            [-115.6296, 46.4735],
            [-115.7334, 46.3629],
            [-115.7655, 46.2824],
            [-115.9991, 46.3062],
            [-116.053, 46.3555],
            [-116.1586, 46.3034],
            [-116.1904, 46.3984],
            [-116.2747, 46.4783],
            [-116.3946, 46.435],
            [-116.4693, 46.4462],
            [-116.484, 46.4572],
            [-116.519, 46.5618],
            [-116.3929, 46.5859],
            [-116.3293, 46.6567],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602520',
        NAME: 'Orofino Joint School District 171',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5299229890,
        AWATER: 62664865,
        INTPTLAT: '+46.6613907',
        INTPTLON: '-115.5716359',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9838, 43.88],
            [-116.8531, 43.8074],
            [-116.8281, 43.7928],
            [-116.8917, 43.7198],
            [-116.9916, 43.6714],
            [-117.0265, 43.6809],
            [-117.0243, 43.8125],
            [-116.9838, 43.88],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602550',
        NAME: 'Parma School District 137',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215536249,
        AWATER: 4621632,
        INTPTLAT: '+43.7718593',
        INTPTLON: '-116.9463565',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8949, 44.1665],
            [-116.5532, 44.1517],
            [-116.452, 44.1514],
            [-116.5322, 44.0661],
            [-116.7717, 44.0684],
            [-116.8564, 44.0238],
            [-116.9517, 44.0396],
            [-116.8949, 44.1665],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602580',
        NAME: 'Payette Joint School District 371',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445419546,
        AWATER: 3653565,
        INTPTLAT: '+44.1047720',
        INTPTLON: '-116.7224677',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0268, 43.3153],
            [-116.9471, 43.315],
            [-116.9466, 43.1992],
            [-116.8674, 43.1991],
            [-116.8674, 43.1111],
            [-116.7869, 43.111],
            [-116.7881, 42.9373],
            [-116.7486, 42.9374],
            [-116.7455, 42.7641],
            [-116.5112, 42.7657],
            [-116.5104, 42.6794],
            [-116.6297, 42.6791],
            [-116.6301, 42.3308],
            [-116.8614, 42.3302],
            [-116.8623, 41.9987],
            [-117.0183, 41.9994],
            [-117.0262, 41.9999],
            [-117.0264, 42.4305],
            [-117.0268, 43.3153],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602610',
        NAME: 'Pleasant Valley Elementary School District 364',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3184343238,
        AWATER: 4872562,
        INTPTLAT: '+42.7167009',
        INTPTLON: '-116.9164190',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.6349, 43.022],
            [-112.0631, 43.0224],
            [-112.1364, 42.9058],
            [-112.3007, 42.9058],
            [-112.3016, 42.7189],
            [-112.4576, 42.7187],
            [-112.4972, 42.76],
            [-112.516, 42.91],
            [-112.595, 42.9731],
            [-112.7505, 42.9514],
            [-112.6349, 43.022],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602640',
        NAME: 'Pocatello School District 25',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 840716378,
        AWATER: 89109643,
        INTPTLAT: '+42.9049160',
        INTPTLON: '-112.3800927',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0416, 47.7311],
            [-116.8406, 47.7519],
            [-116.8942, 47.6656],
            [-117.0411, 47.6218],
            [-117.0418, 47.6977],
            [-117.0416, 47.7311],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602670',
        NAME: 'Post Falls School District 273',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155563913,
        AWATER: 2557056,
        INTPTLAT: '+47.6990133',
        INTPTLON: '-116.9775686',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0397, 47.0489],
            [-117.0397, 47.1231],
            [-117.0397, 47.1273],
            [-116.8354, 47.0326],
            [-116.6501, 47.0375],
            [-116.4801, 47.037],
            [-116.4783, 46.9083],
            [-116.6495, 46.9064],
            [-116.6931, 46.848],
            [-116.8549, 46.8165],
            [-116.9058, 46.8734],
            [-117.0395, 46.8734],
            [-117.0396, 46.9611],
            [-117.0397, 47.0489],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602700',
        NAME: 'Potlatch School District 285',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 891195181,
        AWATER: 797445,
        INTPTLAT: '+46.9509593',
        INTPTLON: '-116.7988500',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.8568, 43.6014],
            [-115.8222, 43.6119],
            [-115.4402, 43.611],
            [-115.4415, 43.3602],
            [-115.4997, 43.3303],
            [-115.6748, 43.4749],
            [-115.7198, 43.55],
            [-115.8568, 43.6014],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602730',
        NAME: 'Prairie Elementary School District 191',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 546611933,
        AWATER: 3124219,
        INTPTLAT: '+43.5076911',
        INTPTLON: '-115.5762688',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.2572, 43.2002],
            [-113.7147, 43.2],
            [-113.7141, 42.8849],
            [-113.8336, 42.871],
            [-113.8341, 42.9367],
            [-113.9541, 42.9371],
            [-113.9731, 43.0247],
            [-114.1953, 43.0258],
            [-114.2957, 42.982],
            [-114.2922, 43.1636],
            [-114.2572, 43.2002],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602760',
        NAME: 'Richfield School District 316',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1225461651,
        AWATER: 2896069,
        INTPTLAT: '+43.0796845',
        INTPTLON: '-114.0058875',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8339, 43.6268],
            [-111.786, 43.6869],
            [-111.6264, 43.6267],
            [-111.4686, 43.622],
            [-111.4283, 43.4959],
            [-111.5661, 43.4657],
            [-111.5653, 43.5689],
            [-111.8244, 43.5691],
            [-111.8339, 43.6268],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602790',
        NAME: 'Ririe Joint School District 252',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347971583,
        AWATER: 6727754,
        INTPTLAT: '+43.5818932',
        INTPTLON: '-111.6162479',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0028, 42.588],
            [-112.8139, 42.617],
            [-112.6539, 42.5885],
            [-112.654, 42.416],
            [-112.654, 42.3289],
            [-113.0008, 42.3275],
            [-113.0028, 42.588],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602820',
        NAME: 'Rockland School District 382',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 880850743,
        AWATER: 76095,
        INTPTLAT: '+42.4707326',
        INTPTLON: '-112.8373171',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.8124, 44.8081],
            [-114.7322, 44.8804],
            [-114.7315, 45.1528],
            [-114.6941, 45.1971],
            [-114.5983, 45.2922],
            [-114.7936, 45.4366],
            [-114.6635, 45.4712],
            [-114.5649, 45.5577],
            [-114.4399, 45.537],
            [-114.3442, 45.4597],
            [-114.2699, 45.4846],
            [-113.9381, 45.6957],
            [-113.8064, 45.6016],
            [-113.8334, 45.5205],
            [-113.7654, 45.4768],
            [-113.7738, 45.4064],
            [-113.6929, 45.2642],
            [-113.5091, 45.1137],
            [-113.6688, 45.02],
            [-113.6708, 44.9256],
            [-113.8123, 44.9254],
            [-113.8818, 44.807],
            [-113.8399, 44.7398],
            [-113.9963, 44.7405],
            [-114.0494, 44.7111],
            [-114.2157, 44.7454],
            [-114.2251, 44.8643],
            [-114.3883, 44.7525],
            [-114.3866, 44.671],
            [-114.4508, 44.6132],
            [-114.5677, 44.5759],
            [-114.6151, 44.6428],
            [-114.7113, 44.6506],
            [-114.8152, 44.7353],
            [-114.8124, 44.8081],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602850',
        NAME: 'Salmon School District 291',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7493082059,
        AWATER: 12610173,
        INTPTLAT: '+45.1198209',
        INTPTLON: '-114.2388196',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.52, 43.4251],
            [-112.2824, 43.4251],
            [-112.0916, 43.4536],
            [-111.9642, 43.3954],
            [-111.9441, 43.3661],
            [-112.1698, 43.3443],
            [-112.2963, 43.3672],
            [-112.405, 43.3673],
            [-112.52, 43.4251],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602910',
        NAME: 'Shelley Joint School District 60',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354003698,
        AWATER: 2053060,
        INTPTLAT: '+43.3978724',
        INTPTLON: '-112.2164459',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.545, 43.1986],
            [-114.3749, 43.1996],
            [-114.2572, 43.2002],
            [-114.2922, 43.1636],
            [-114.2957, 42.982],
            [-114.1953, 43.0258],
            [-113.9731, 43.0247],
            [-113.9541, 42.9371],
            [-113.9538, 42.8935],
            [-114.0529, 42.9376],
            [-114.295, 42.9381],
            [-114.326, 42.8238],
            [-114.0401, 42.8374],
            [-114.0272, 42.765],
            [-114.3453, 42.7659],
            [-114.3465, 42.8509],
            [-114.5944, 42.8508],
            [-114.546, 42.9813],
            [-114.545, 43.1986],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602940',
        NAME: 'Shoshone Joint School District 312',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1320091107,
        AWATER: 4069921,
        INTPTLAT: '+42.9916016',
        INTPTLON: '-114.3363682',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.0073, 43.2848],
            [-112.9472, 43.2847],
            [-112.9368, 43.4494],
            [-112.8167, 43.449],
            [-112.8162, 43.5359],
            [-112.6991, 43.5402],
            [-112.6974, 43.623],
            [-112.5199, 43.6266],
            [-112.52, 43.4251],
            [-112.405, 43.3673],
            [-112.4059, 43.1813],
            [-112.4766, 43.1514],
            [-112.6132, 43.0335],
            [-112.6339, 43.1541],
            [-113.0078, 43.1548],
            [-113.0073, 43.2848],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1602970',
        NAME: 'Snake River School District 52',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1874967992,
        AWATER: 402250,
        INTPTLAT: '+43.3318284',
        INTPTLON: '-112.6817242',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9438, 43.021],
            [-111.8256, 43.0206],
            [-111.5896, 43.0201],
            [-111.4706, 43.0203],
            [-111.4693, 43.1943],
            [-111.2343, 43.1933],
            [-111.2355, 43.0195],
            [-111.0442, 43.0201],
            [-111.0467, 42.5131],
            [-111.2347, 42.4784],
            [-111.2812, 42.553],
            [-111.4663, 42.5903],
            [-111.6041, 42.5906],
            [-111.7009, 42.5905],
            [-111.7203, 42.6507],
            [-111.7204, 42.7647],
            [-111.7982, 42.7653],
            [-111.8293, 42.9338],
            [-111.9439, 42.9348],
            [-111.9438, 43.021],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603000',
        NAME: 'Soda Springs Joint School District 150',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3480096501,
        AWATER: 84601479,
        INTPTLAT: '+42.8244599',
        INTPTLON: '-111.4021824',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-113.5091, 45.1137],
            [-113.522, 45.0932],
            [-113.452, 45.0592],
            [-113.4551, 44.8654],
            [-113.3572, 44.8207],
            [-113.2472, 44.823],
            [-113.1016, 44.7295],
            [-113.0644, 44.6521],
            [-113.0883, 44.5997],
            [-113.0068, 44.5262],
            [-113.002, 44.4499],
            [-112.8138, 44.3765],
            [-112.8139, 44.2304],
            [-112.9954, 44.234],
            [-113.3207, 44.233],
            [-113.341, 44.3188],
            [-113.4526, 44.4555],
            [-113.5504, 44.479],
            [-113.5342, 44.5419],
            [-113.6922, 44.6626],
            [-113.834, 44.699],
            [-113.8399, 44.7398],
            [-113.8818, 44.807],
            [-113.8123, 44.9254],
            [-113.6708, 44.9256],
            [-113.6688, 45.02],
            [-113.5091, 45.1137],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603030',
        NAME: 'South Lemhi School District 292',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3592224288,
        AWATER: 1007308,
        INTPTLAT: '+44.6234792',
        INTPTLON: '-113.3561649',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.7496, 47.3859],
            [-116.5812, 47.3714],
            [-116.4857, 47.4129],
            [-116.3294, 47.3707],
            [-116.3296, 47.0224],
            [-116.4801, 47.037],
            [-116.6501, 47.0375],
            [-116.6806, 47.1521],
            [-116.7894, 47.1487],
            [-116.8322, 47.2506],
            [-116.7355, 47.3116],
            [-116.7496, 47.3859],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603060',
        NAME: 'St. Maries Joint School District 41',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1260796440,
        AWATER: 18915452,
        INTPTLAT: '+47.2273144',
        INTPTLON: '-116.5351419',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4598, 43.9174],
            [-111.4994, 43.8117],
            [-111.7182, 43.8116],
            [-111.8544, 43.8788],
            [-111.9774, 43.8268],
            [-111.9771, 43.9709],
            [-112.1074, 43.9719],
            [-111.917, 44.0004],
            [-111.8311, 43.909],
            [-111.6288, 43.8689],
            [-111.4598, 43.9174],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603090',
        NAME: 'Sugar-Salem Joint School District 322',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452896430,
        AWATER: 3719240,
        INTPTLAT: '+43.8933200',
        INTPTLON: '-111.7542472',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.588, 43.2816],
            [-111.5661, 43.4657],
            [-111.4283, 43.4959],
            [-111.4686, 43.622],
            [-111.3999, 43.622],
            [-111.2439, 43.6217],
            [-111.1991, 43.5397],
            [-111.0455, 43.5011],
            [-111.0446, 43.3157],
            [-111.0442, 43.0201],
            [-111.2355, 43.0195],
            [-111.2343, 43.1933],
            [-111.4693, 43.1943],
            [-111.4706, 43.0203],
            [-111.5896, 43.0201],
            [-111.588, 43.2816],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603120',
        NAME: 'Swan Valley Elementary School District 92',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2161547672,
        AWATER: 70454399,
        INTPTLAT: '+43.3341809',
        INTPTLON: '-111.3451300',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.3989, 43.9231],
            [-111.1871, 43.9322],
            [-111.047, 43.9835],
            [-111.0455, 43.5011],
            [-111.1991, 43.5397],
            [-111.2439, 43.6217],
            [-111.3999, 43.622],
            [-111.3989, 43.9231],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603180',
        NAME: 'Teton County School District 401',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1164084061,
        AWATER: 2860443,
        INTPTLAT: '+43.7609939',
        INTPTLON: '-111.2117653',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-115.0379, 42.2456],
            [-114.93, 42.1577],
            [-114.7394, 42.1577],
            [-114.7654, 42.0691],
            [-114.7212, 41.9982],
            [-115.57, 41.9968],
            [-115.5712, 42.2446],
            [-115.0379, 42.2456],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603210',
        NAME: 'Three Creek Joint Elementary School District 416',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1691103267,
        AWATER: 987933,
        INTPTLAT: '+42.1118320',
        INTPTLON: '-115.1953358',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.5392, 42.6336],
            [-114.4028, 42.5955],
            [-114.4305, 42.4036],
            [-114.5159, 42.4038],
            [-114.5551, 42.4913],
            [-114.5392, 42.6336],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603240',
        NAME: 'Twin Falls School District 411',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247840641,
        AWATER: 2271504,
        INTPTLAT: '+42.5185070',
        INTPTLON: '-114.4750292',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0029, 42.7214],
            [-114.0301, 42.5918],
            [-113.9906, 42.5323],
            [-114.0692, 42.5217],
            [-114.1902, 42.5379],
            [-114.3258, 42.5724],
            [-114.3447, 42.5852],
            [-114.3169, 42.7241],
            [-114.0029, 42.7214],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603270',
        NAME: 'Valley School District 262',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 564965420,
        AWATER: 6966956,
        INTPTLAT: '+42.6248221',
        INTPTLON: '-114.1518757',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0491, 47.9773],
            [-116.0207, 47.9636],
            [-115.8238, 47.7526],
            [-115.7497, 47.7144],
            [-115.6916, 47.5934],
            [-115.6986, 47.5023],
            [-115.8584, 47.494],
            [-115.8624, 47.371],
            [-116.0426, 47.3707],
            [-116.0325, 47.6013],
            [-116.1582, 47.6309],
            [-116.1565, 47.7182],
            [-116.008, 47.7176],
            [-116.008, 47.8043],
            [-116.3245, 47.8052],
            [-116.3228, 47.8903],
            [-116.3291, 48.0178],
            [-116.2347, 48.0696],
            [-116.0491, 47.9773],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603300',
        NAME: 'Wallace School District 393',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1649069342,
        AWATER: 2596234,
        INTPTLAT: '+47.7264437',
        INTPTLON: '-116.0057306',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0813, 44.6736],
            [-116.8776, 44.673],
            [-116.8776, 44.5716],
            [-116.8535, 44.3538],
            [-116.6732, 44.3544],
            [-116.6735, 44.2383],
            [-116.5529, 44.2379],
            [-116.5532, 44.1517],
            [-116.8949, 44.1665],
            [-116.9495, 44.1916],
            [-117.1199, 44.2783],
            [-117.1986, 44.2741],
            [-117.2427, 44.3965],
            [-117.2241, 44.4837],
            [-117.1442, 44.5456],
            [-117.0813, 44.6736],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603330',
        NAME: 'Weiser School District 431',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1611577078,
        AWATER: 24402786,
        INTPTLAT: '+44.3969086',
        INTPTLON: '-116.9390412',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.7903, 42.8391],
            [-114.6354, 42.8508],
            [-114.6346, 42.6543],
            [-114.6847, 42.6608],
            [-114.8468, 42.7249],
            [-114.7903, 42.8391],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603360',
        NAME: 'Wendell School District 232',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297206742,
        AWATER: 2279515,
        INTPTLAT: '+42.7574353',
        INTPTLON: '-114.7222058',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.695, 43.9719],
            [-112.6947, 44.0585],
            [-112.156, 44.0577],
            [-112.1074, 43.9719],
            [-111.9771, 43.9709],
            [-111.9774, 43.8268],
            [-112.1952, 43.8307],
            [-112.2232, 43.7566],
            [-112.4618, 43.7582],
            [-112.4627, 43.6266],
            [-112.5199, 43.6266],
            [-112.6974, 43.623],
            [-112.695, 43.9719],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603400',
        NAME: 'West Jefferson School District 253',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1901337087,
        AWATER: 16463025,
        INTPTLAT: '+43.8817889',
        INTPTLON: '-112.4182793',
        ELSDLEA: '',
        UNSDLEA: '03400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1263, 42.2853],
            [-111.9839, 42.2566],
            [-111.9095, 42.0708],
            [-111.9336, 41.9986],
            [-112.1095, 41.9978],
            [-112.1092, 42.1698],
            [-112.071, 42.2385],
            [-112.1263, 42.2853],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603420',
        NAME: 'West Side Joint School District 202',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401891176,
        AWATER: 3302768,
        INTPTLAT: '+42.1443558',
        INTPTLON: '-112.0176628',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.8917, 43.7198],
            [-116.8475, 43.6908],
            [-116.8624, 43.6327],
            [-116.9916, 43.6714],
            [-116.8917, 43.7198],
          ],
        ],
      },
      properties: {
        STATEFP: '16',
        SCSDLEA: '',
        GEOID: '1603480',
        NAME: 'Wilder School District 133',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78754748,
        AWATER: 865565,
        INTPTLAT: '+43.6802352',
        INTPTLON: '-116.9133232',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
  ],
};

export const GA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.972, 32.3777],
            [-84.9577, 32.3742],
            [-84.793, 32.4138],
            [-84.6945, 32.5187],
            [-84.6373, 32.5349],
            [-84.6573, 32.4949],
            [-84.6582, 32.3098],
            [-84.7938, 32.3486],
            [-84.7925, 32.2568],
            [-84.8882, 32.2597],
            [-85.0028, 32.3224],
            [-84.972, 32.3777],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '13053',
        GEOID: '1313053',
        NAME: 'Chattahoochee County for Fort Benning',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 489955989,
        AWATER: 4281677,
        INTPTLAT: '+32.3717532',
        INTPTLON: '-084.7974159',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6795, 32.5358],
            [-84.6945, 32.5187],
            [-84.793, 32.4138],
            [-84.9577, 32.3742],
            [-84.8923, 32.4228],
            [-84.8217, 32.5353],
            [-84.6795, 32.5358],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '13215',
        GEOID: '1313215',
        NAME: 'Muscogee County for Fort Benning',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 191854964,
        AWATER: 550018,
        INTPTLAT: '+32.4698571',
        INTPTLON: '-084.8130988',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.972, 32.3777],
            [-84.9577, 32.3742],
            [-84.8923, 32.4228],
            [-84.8217, 32.5353],
            [-84.6795, 32.5358],
            [-84.6945, 32.5187],
            [-84.6373, 32.5349],
            [-84.6573, 32.4949],
            [-84.6582, 32.3098],
            [-84.7938, 32.3486],
            [-84.7925, 32.2568],
            [-84.8882, 32.2597],
            [-85.0028, 32.3224],
            [-84.972, 32.3777],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300002',
        NAME: 'Fort Benning Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 681810953,
        AWATER: 4831695,
        INTPTLAT: '+32.4004755',
        INTPTLON: '-084.8022711',
        ELSDLEA: '00002',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2365, 33.1296],
            [-84.939, 33.2247],
            [-84.8624, 33.1912],
            [-84.8618, 32.8725],
            [-85.1841, 32.8705],
            [-85.1853, 32.8733],
            [-85.187, 32.8816],
            [-85.2324, 33.1081],
            [-85.2365, 33.1296],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300001',
        NAME: 'Troup County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1072232618,
        AWATER: 82803338,
        INTPTLAT: '+33.0344820',
        INTPTLON: '-085.0283600',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8575, 32.0498],
            [-81.7633, 32.1176],
            [-81.3847, 32.0795],
            [-81.309, 31.9825],
            [-81.4049, 31.9369],
            [-81.6379, 31.8496],
            [-81.7658, 31.8878],
            [-81.882, 31.9241],
            [-81.8575, 32.0498],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300003',
        NAME: 'Fort Stewart School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 1124730907,
        AWATER: 7428521,
        INTPTLAT: '+31.9868975',
        INTPTLON: '-081.6265693',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6079, 32.6645],
            [-83.603, 32.6618],
            [-83.5552, 32.6227],
            [-83.5882, 32.5751],
            [-83.6079, 32.6645],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300004',
        NAME: 'Robins AFB School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 27035383,
        AWATER: 393112,
        INTPTLAT: '+32.6190657',
        INTPTLON: '-083.5811330',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4315, 31.9662],
            [-82.225, 31.9131],
            [-82.1078, 31.8837],
            [-82.0486, 31.8271],
            [-82.133, 31.7734],
            [-82.1328, 31.4713],
            [-82.2266, 31.5307],
            [-82.3485, 31.6207],
            [-82.5214, 31.7108],
            [-82.5203, 31.8384],
            [-82.4314, 31.838],
            [-82.4315, 31.9662],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300060',
        NAME: 'Appling County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1313437498,
        AWATER: 13313808,
        INTPTLAT: '+31.7397120',
        INTPTLON: '-082.2901025',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1405, 31.4204],
            [-82.8441, 31.4033],
            [-82.8175, 31.3645],
            [-82.629, 31.3639],
            [-82.6296, 31.2747],
            [-82.6717, 31.1837],
            [-82.9712, 31.184],
            [-83.0469, 31.1837],
            [-83.047, 31.283],
            [-83.1405, 31.4204],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300090',
        NAME: 'Atkinson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 879043416,
        AWATER: 13294218,
        INTPTLAT: '+31.2968609',
        INTPTLON: '-082.8781986',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5079, 33.7955],
            [-84.4951, 33.8085],
            [-84.489, 33.8128],
            [-84.4511, 33.871],
            [-84.3484, 33.8823],
            [-84.3158, 33.765],
            [-84.2907, 33.751],
            [-84.3486, 33.7325],
            [-84.3502, 33.6479],
            [-84.3994, 33.6483],
            [-84.4154, 33.6972],
            [-84.5457, 33.6808],
            [-84.5079, 33.7955],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300120',
        NAME: 'Atlanta City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344721735,
        AWATER: 2217219,
        INTPTLAT: '+33.7629088',
        INTPTLON: '-084.4226745',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6273, 31.6727],
            [-82.5214, 31.7108],
            [-82.3485, 31.6207],
            [-82.2266, 31.5307],
            [-82.3448, 31.4301],
            [-82.4172, 31.4171],
            [-82.4947, 31.4687],
            [-82.5975, 31.4693],
            [-82.6283, 31.5583],
            [-82.6273, 31.6727],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300150',
        NAME: 'Bacon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 735650478,
        AWATER: 4626042,
        INTPTLAT: '+31.5502095',
        INTPTLON: '-082.4514325',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6376, 31.4339],
            [-84.4299, 31.4367],
            [-84.1411, 31.4402],
            [-84.198, 31.3575],
            [-84.3406, 31.3053],
            [-84.4045, 31.199],
            [-84.4833, 31.1555],
            [-84.5081, 31.0784],
            [-84.5427, 31.079],
            [-84.5371, 31.2559],
            [-84.6417, 31.259],
            [-84.6376, 31.4339],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300180',
        NAME: 'Baker County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 885629899,
        AWATER: 18634137,
        INTPTLAT: '+31.3195798',
        INTPTLON: '-084.4548812',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2741, 33.1872],
            [-83.0976, 33.1515],
            [-83.0522, 33.0807],
            [-83.0736, 32.9466],
            [-83.1338, 33.0072],
            [-83.3577, 32.9261],
            [-83.4291, 33.1854],
            [-83.2741, 33.1872],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300210',
        NAME: 'Baldwin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669822350,
        AWATER: 25008172,
        INTPTLAT: '+33.0594897',
        INTPTLON: '-083.2554566',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6153, 34.4317],
            [-83.5375, 34.4917],
            [-83.4598, 34.4811],
            [-83.3984, 34.4609],
            [-83.3939, 34.3248],
            [-83.3553, 34.2237],
            [-83.4024, 34.1975],
            [-83.4575, 34.2426],
            [-83.4577, 34.2427],
            [-83.5615, 34.2527],
            [-83.6201, 34.2953],
            [-83.6668, 34.368],
            [-83.6153, 34.4317],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300240',
        NAME: 'Banks County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601112274,
        AWATER: 4651151,
        INTPTLAT: '+34.3519216',
        INTPTLON: '-083.4984409',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8177, 34.1275],
            [-83.7514, 34.0642],
            [-83.5632, 34.0318],
            [-83.5374, 33.9659],
            [-83.647, 33.9062],
            [-83.7991, 33.9298],
            [-83.8689, 34.0048],
            [-83.8177, 34.1275],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300290',
        NAME: 'Barrow County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415349721,
        AWATER: 6642220,
        INTPTLAT: '+33.9920094',
        INTPTLON: '-083.7123025',
        ELSDLEA: '',
        UNSDLEA: '00290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0469, 34.0964],
            [-85.0058, 34.3924],
            [-84.7341, 34.3965],
            [-84.6532, 34.4126],
            [-84.6592, 34.078],
            [-84.7378, 34.0794],
            [-84.7725, 34.0824],
            [-84.8458, 34.1728],
            [-84.8565, 34.1666],
            [-84.8069, 34.0825],
            [-84.9227, 34.0825],
            [-85.0469, 34.0964],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300330',
        NAME: 'Bartow County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1115578070,
        AWATER: 26944081,
        INTPTLAT: '+34.2433632',
        INTPTLON: '-084.8452473',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4802, 31.8473],
            [-83.1775, 31.8479],
            [-82.9957, 31.781],
            [-82.9984, 31.6732],
            [-83.3256, 31.6806],
            [-83.3253, 31.7566],
            [-83.4536, 31.7579],
            [-83.4802, 31.8473],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300360',
        NAME: 'Ben Hill County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 647809457,
        AWATER: 9807028,
        INTPTLAT: '+31.7591045',
        INTPTLON: '-083.2159655',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4345, 31.3504],
            [-83.3387, 31.476],
            [-83.1456, 31.4723],
            [-83.1405, 31.4204],
            [-83.047, 31.283],
            [-83.0469, 31.1837],
            [-83.1651, 31.1472],
            [-83.198, 31.0254],
            [-83.2951, 31.0273],
            [-83.3359, 31.2446],
            [-83.4351, 31.3026],
            [-83.4345, 31.3504],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300390',
        NAME: 'Berrien County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1170534736,
        AWATER: 15375915,
        INTPTLAT: '+31.2886522',
        INTPTLON: '-083.2278695',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8919, 32.8484],
            [-83.7107, 32.9528],
            [-83.6582, 32.8879],
            [-83.5135, 32.8449],
            [-83.5977, 32.6643],
            [-83.603, 32.6618],
            [-83.6079, 32.6645],
            [-83.7012, 32.6922],
            [-83.8083, 32.7287],
            [-83.8919, 32.8484],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300420',
        NAME: 'Bibb County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645714764,
        AWATER: 14488557,
        INTPTLAT: '+32.8088443',
        INTPTLON: '-083.6941934',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4979, 32.4522],
            [-83.2265, 32.5842],
            [-83.139, 32.4231],
            [-83.1733, 32.4525],
            [-83.3047, 32.342],
            [-83.3465, 32.2725],
            [-83.498, 32.4017],
            [-83.4979, 32.4522],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300440',
        NAME: 'Bleckley County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559100198,
        AWATER: 8447343,
        INTPTLAT: '+32.4354034',
        INTPTLON: '-083.3317174',
        ELSDLEA: '',
        UNSDLEA: '00440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2846, 31.2244],
            [-82.1948, 31.2076],
            [-82.0927, 31.2761],
            [-82.0081, 31.2797],
            [-82.0411, 31.3737],
            [-81.9232, 31.3459],
            [-81.8011, 31.3637],
            [-81.7317, 31.33],
            [-81.7663, 31.1696],
            [-81.936, 31.048],
            [-82.0609, 31.0758],
            [-82.1317, 31.0107],
            [-82.2083, 31.0848],
            [-82.2087, 31.1709],
            [-82.2846, 31.2244],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300480',
        NAME: 'Brantley County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1145716267,
        AWATER: 12532588,
        INTPTLAT: '+31.1973339',
        INTPTLON: '-081.9829779',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1685, 33.7252],
            [-85.1295, 33.7369],
            [-85.11, 33.6933],
            [-85.1151, 33.6913],
            [-85.1159, 33.7018],
            [-85.1314, 33.6998],
            [-85.1331, 33.6999],
            [-85.1374, 33.7],
            [-85.1431, 33.6987],
            [-85.1935, 33.6831],
            [-85.1685, 33.7252],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300510',
        NAME: 'Bremen City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27254111,
        AWATER: 72967,
        INTPTLAT: '+33.7107648',
        INTPTLON: '-085.1518967',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7362, 31.0377],
            [-83.574, 31.0339],
            [-83.5749, 31.0781],
            [-83.4756, 31.032],
            [-83.3709, 30.8848],
            [-83.4825, 30.7569],
            [-83.3577, 30.6374],
            [-83.6117, 30.6513],
            [-83.7437, 30.6584],
            [-83.7362, 31.0377],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300540',
        NAME: 'Brooks County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1277108508,
        AWATER: 12188514,
        INTPTLAT: '+30.8229337',
        INTPTLON: '-083.5819054',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-81.309, 31.9825],
              [-81.1393, 31.8526],
              [-81.177, 31.8161],
              [-81.1547, 31.7131],
              [-81.2599, 31.7538],
              [-81.377, 31.8483],
              [-81.4049, 31.9369],
              [-81.309, 31.9825],
            ],
          ],
          [
            [
              [-81.7809, 32.1529],
              [-81.4358, 32.2413],
              [-81.3917, 32.0959],
              [-81.3847, 32.0795],
              [-81.7633, 32.1176],
              [-81.7809, 32.1529],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300570',
        NAME: 'Bryan County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 692238806,
        AWATER: 41929246,
        INTPTLAT: '+31.8420703',
        INTPTLON: '-081.2939363',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.007, 34.1432],
            [-84.0063, 34.1435],
            [-83.995, 34.1437],
            [-83.9605, 34.1315],
            [-83.9796, 34.0927],
            [-84.0185, 34.0916],
            [-84.007, 34.1432],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300600',
        NAME: 'Buford City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40915610,
        AWATER: 195462,
        INTPTLAT: '+34.1197983',
        INTPTLON: '-083.9952272',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0302, 32.5388],
            [-82.0012, 32.6069],
            [-81.841, 32.6491],
            [-81.6879, 32.5461],
            [-81.548, 32.4893],
            [-81.5309, 32.3904],
            [-81.4643, 32.3247],
            [-81.4358, 32.2413],
            [-81.7809, 32.1529],
            [-81.8163, 32.2375],
            [-81.9691, 32.2688],
            [-81.9187, 32.4151],
            [-82.0302, 32.5388],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300630',
        NAME: 'Bulloch County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1743037409,
        AWATER: 40494139,
        INTPTLAT: '+32.3934083',
        INTPTLON: '-081.7438098',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2676, 33.2674],
            [-82.1742, 33.2968],
            [-82.0926, 33.2301],
            [-81.8521, 33.2473],
            [-81.8315, 33.2334],
            [-81.7046, 33.1165],
            [-81.614, 33.0953],
            [-81.5418, 33.0457],
            [-81.7676, 32.9094],
            [-81.858, 32.9539],
            [-82.0813, 32.9168],
            [-82.145, 32.8127],
            [-82.3165, 32.8358],
            [-82.2724, 32.9378],
            [-82.2912, 33.0628],
            [-82.2331, 33.2277],
            [-82.2676, 33.2674],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300660',
        NAME: 'Burke County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2141835933,
        AWATER: 20758800,
        INTPTLAT: '+33.0508189',
        INTPTLON: '-082.0115709',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1026, 33.2982],
            [-83.9239, 33.4442],
            [-83.8631, 33.3683],
            [-83.8141, 33.2421],
            [-83.8223, 33.1802],
            [-84.0415, 33.2026],
            [-84.1238, 33.2028],
            [-84.1026, 33.2982],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300690',
        NAME: 'Butts County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475937514,
        AWATER: 11086906,
        INTPTLAT: '+33.2878552',
        INTPTLON: '-083.9569325',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9637, 34.5025],
            [-84.9531, 34.53],
            [-84.9057, 34.5119],
            [-84.9075, 34.492],
            [-84.9449, 34.4864],
            [-84.9405, 34.4817],
            [-84.9391, 34.4818],
            [-84.929, 34.4808],
            [-84.9177, 34.4806],
            [-84.9207, 34.4297],
            [-84.9637, 34.5025],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300720',
        NAME: 'Calhoun City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38404938,
        AWATER: 249433,
        INTPTLAT: '+34.4830052',
        INTPTLON: '-084.9449571',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8178, 31.6198],
            [-84.5469, 31.6212],
            [-84.4504, 31.6219],
            [-84.4299, 31.4367],
            [-84.6376, 31.4339],
            [-84.789, 31.4364],
            [-84.8198, 31.5012],
            [-84.8178, 31.6198],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300750',
        NAME: 'Calhoun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726147258,
        AWATER: 8315719,
        INTPTLAT: '+31.5212790',
        INTPTLON: '-084.6262895',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7663, 31.1696],
            [-81.5651, 31.0919],
            [-81.4238, 30.9909],
            [-81.3064, 30.9906],
            [-81.4005, 30.7664],
            [-81.347, 30.7124],
            [-81.5724, 30.7219],
            [-81.7181, 30.7448],
            [-81.9049, 30.8283],
            [-81.8921, 30.91],
            [-81.936, 31.048],
            [-81.7663, 31.1696],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300780',
        NAME: 'Camden County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1588171684,
        AWATER: 436849667,
        INTPTLAT: '+30.9133473',
        INTPTLON: '-081.6420006',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0302, 32.5388],
            [-81.9187, 32.4151],
            [-81.9691, 32.2688],
            [-82.0253, 32.2789],
            [-82.2328, 32.3188],
            [-82.2528, 32.3533],
            [-82.1483, 32.5204],
            [-82.0302, 32.5388],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300810',
        NAME: 'Candler County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629495413,
        AWATER: 15043697,
        INTPTLAT: '+32.4038515',
        INTPTLON: '-082.0712350',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3382, 33.6531],
            [-85.1935, 33.6831],
            [-85.1431, 33.6987],
            [-85.1374, 33.7],
            [-85.1331, 33.6999],
            [-85.1314, 33.6998],
            [-85.1151, 33.6913],
            [-85.11, 33.6933],
            [-85.0509, 33.7146],
            [-85.0379, 33.8119],
            [-84.9017, 33.7807],
            [-84.9058, 33.5734],
            [-84.8089, 33.5741],
            [-84.8507, 33.5115],
            [-84.9423, 33.4364],
            [-85.0154, 33.4255],
            [-85.2938, 33.4281],
            [-85.3044, 33.4829],
            [-85.3382, 33.6531],
          ],
          [
            [-85.1862, 33.5683],
            [-85.1171, 33.5679],
            [-85.0911, 33.5453],
            [-85.034, 33.5749],
            [-85.0253, 33.6074],
            [-85.093, 33.6083],
            [-85.1094, 33.5844],
            [-85.1862, 33.5683],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300840',
        NAME: 'Carroll County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1228925602,
        AWATER: 10847978,
        INTPTLAT: '+33.6186945',
        INTPTLON: '-085.0738540',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1862, 33.5683],
            [-85.1094, 33.5844],
            [-85.093, 33.6083],
            [-85.0253, 33.6074],
            [-85.034, 33.5749],
            [-85.0911, 33.5453],
            [-85.1171, 33.5679],
            [-85.1862, 33.5683],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300870',
        NAME: 'Carrollton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57785036,
        AWATER: 1384470,
        INTPTLAT: '+33.5818470',
        INTPTLON: '-085.0833328',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8458, 34.1728],
            [-84.7725, 34.0824],
            [-84.8069, 34.0825],
            [-84.8565, 34.1666],
            [-84.8458, 34.1728],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300900',
        NAME: 'Cartersville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74640954,
        AWATER: 424244,
        INTPTLAT: '+34.1629469',
        INTPTLON: '-084.8004152',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2651, 34.9851],
            [-84.9799, 34.9876],
            [-85.0616, 34.8195],
            [-85.1448, 34.7676],
            [-85.2022, 34.8545],
            [-85.2642, 34.8753],
            [-85.2643, 34.8805],
            [-85.2643, 34.8856],
            [-85.2643, 34.8911],
            [-85.2651, 34.9851],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300930',
        NAME: 'Catoosa County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 419999438,
        AWATER: 771159,
        INTPTLAT: '+34.8993926',
        INTPTLON: '-085.1373531',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1317, 31.0107],
            [-82.0609, 31.0758],
            [-81.936, 31.048],
            [-81.8921, 30.91],
            [-81.9049, 30.8283],
            [-82.0398, 30.7473],
            [-82.0502, 30.6745],
            [-82.0058, 30.5654],
            [-82.05, 30.3625],
            [-82.1702, 30.359],
            [-82.2107, 30.4256],
            [-82.2147, 30.5686],
            [-82.1499, 30.7843],
            [-82.4204, 30.7952],
            [-82.4156, 31.0136],
            [-82.1317, 31.0107],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1300990',
        NAME: 'Charlton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2003652394,
        AWATER: 22681775,
        INTPTLAT: '+30.7799036',
        INTPTLON: '-082.1396442',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1446, 32.2261],
            [-81.1172, 32.1176],
            [-81.012, 32.1002],
            [-80.9268, 32.0417],
            [-80.7514, 32.0335],
            [-80.7898, 31.9644],
            [-81.0515, 31.7052],
            [-81.1547, 31.7131],
            [-81.177, 31.8161],
            [-81.1393, 31.8526],
            [-81.309, 31.9825],
            [-81.3847, 32.0795],
            [-81.3917, 32.0959],
            [-81.1956, 32.2375],
            [-81.1446, 32.2261],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301020',
        NAME: 'Chatham County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1110634444,
        AWATER: 526916835,
        INTPTLAT: '+31.9804124',
        INTPTLON: '-081.0852063',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8882, 32.2597],
            [-84.7925, 32.2568],
            [-84.7938, 32.3486],
            [-84.6582, 32.3098],
            [-84.6589, 32.2329],
            [-84.923, 32.2309],
            [-84.8882, 32.2597],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301050',
        NAME: 'Chattahoochee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154266682,
        AWATER: 2011027,
        INTPTLAT: '+32.2725435',
        INTPTLON: '-084.7487963',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5273, 34.5887],
            [-85.1077, 34.5875],
            [-85.1805, 34.436],
            [-85.3349, 34.3697],
            [-85.3874, 34.2861],
            [-85.4622, 34.2864],
            [-85.5136, 34.5238],
            [-85.5273, 34.5887],
          ],
          [
            [-85.3256, 34.5496],
            [-85.3009, 34.5304],
            [-85.2979, 34.5644],
            [-85.3256, 34.5496],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301080',
        NAME: 'Chattooga County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801581393,
        AWATER: 539115,
        INTPTLAT: '+34.4742244',
        INTPTLON: '-085.3461294',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6532, 34.4126],
            [-84.5826, 34.3815],
            [-84.2576, 34.381],
            [-84.2581, 34.3352],
            [-84.2587, 34.1859],
            [-84.3283, 34.1861],
            [-84.4189, 34.0733],
            [-84.6592, 34.078],
            [-84.6532, 34.4126],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301110',
        NAME: 'Cherokee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1092190453,
        AWATER: 32938535,
        INTPTLAT: '+34.2443166',
        INTPTLON: '-084.4750574',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2643, 34.8911],
            [-85.2643, 34.8856],
            [-85.2643, 34.8805],
            [-85.2642, 34.8753],
            [-85.3004, 34.8608],
            [-85.3006, 34.8825],
            [-85.2643, 34.8911],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301140',
        NAME: 'Chickamauga City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6622903,
        AWATER: 32990,
        INTPTLAT: '+34.8774670',
        INTPTLON: '-085.2883505',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5374, 33.9659],
            [-83.36, 34.0406],
            [-83.2584, 33.9991],
            [-83.2409, 33.9044],
            [-83.2759, 33.848],
            [-83.5374, 33.9659],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301170',
        NAME: 'Clarke County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308738453,
        AWATER: 4741286,
        INTPTLAT: '+33.9521852',
        INTPTLON: '-083.3671522',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9586, 31.7779],
            [-84.9424, 31.6187],
            [-84.8178, 31.6198],
            [-84.8198, 31.5012],
            [-85.0292, 31.4861],
            [-85.0455, 31.5171],
            [-85.0575, 31.6186],
            [-85.1259, 31.6963],
            [-85.1273, 31.7626],
            [-85.1411, 31.7805],
            [-84.9586, 31.7779],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301200',
        NAME: 'Clay County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 506035802,
        AWATER: 56603707,
        INTPTLAT: '+31.6198309',
        INTPTLON: '-084.9925826',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4285, 33.6487],
            [-84.4192, 33.6486],
            [-84.3994, 33.6483],
            [-84.3502, 33.6479],
            [-84.2813, 33.6474],
            [-84.2626, 33.631],
            [-84.2947, 33.4357],
            [-84.3544, 33.3525],
            [-84.3881, 33.3525],
            [-84.3877, 33.4771],
            [-84.4587, 33.5509],
            [-84.4285, 33.6487],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301230',
        NAME: 'Clayton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366862138,
        AWATER: 7057627,
        INTPTLAT: '+33.5426863',
        INTPTLON: '-084.3555727',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9712, 31.184],
            [-82.6717, 31.1837],
            [-82.5921, 31.0185],
            [-82.4906, 30.9632],
            [-82.4922, 30.8326],
            [-82.4359, 30.8201],
            [-82.4189, 30.5818],
            [-82.4598, 30.5843],
            [-82.584, 30.5915],
            [-82.6043, 30.7137],
            [-82.7662, 30.7324],
            [-82.8464, 30.8349],
            [-82.9713, 30.8694],
            [-82.9712, 31.184],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301260',
        NAME: 'Clinch County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2072566668,
        AWATER: 61565560,
        INTPTLAT: '+30.9176531',
        INTPTLON: '-082.7026143',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7378, 34.0794],
            [-84.6592, 34.078],
            [-84.4189, 34.0733],
            [-84.383, 33.9638],
            [-84.4511, 33.871],
            [-84.489, 33.8128],
            [-84.4951, 33.8085],
            [-84.5079, 33.7955],
            [-84.5781, 33.7435],
            [-84.6227, 33.8048],
            [-84.7241, 33.8062],
            [-84.7378, 34.0794],
          ],
          [
            [-84.5969, 33.9385],
            [-84.4994, 33.9383],
            [-84.5475, 33.9973],
            [-84.5969, 33.9385],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301290',
        NAME: 'Cobb County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 819473410,
        AWATER: 12396452,
        INTPTLAT: '+33.9130742',
        INTPTLON: '-084.5610567',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1456, 31.4723],
            [-82.9984, 31.6732],
            [-82.9957, 31.781],
            [-82.8736, 31.7819],
            [-82.8364, 31.8162],
            [-82.8366, 31.6714],
            [-82.6273, 31.6727],
            [-82.6283, 31.5583],
            [-82.5975, 31.4693],
            [-82.629, 31.3639],
            [-82.8175, 31.3645],
            [-82.8441, 31.4033],
            [-83.1405, 31.4204],
            [-83.1456, 31.4723],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301350',
        NAME: 'Coffee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1490103280,
        AWATER: 70753625,
        INTPTLAT: '+31.5492446',
        INTPTLON: '-082.8449381',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9994, 31.335],
            [-83.6541, 31.3307],
            [-83.5126, 31.3274],
            [-83.5098, 31.2062],
            [-83.5749, 31.0781],
            [-83.574, 31.0339],
            [-83.7362, 31.0377],
            [-84.0039, 31.0416],
            [-84.0036, 31.0773],
            [-83.9994, 31.335],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301380',
        NAME: 'Colquitt County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1416686380,
        AWATER: 25002138,
        INTPTLAT: '+31.1897575',
        INTPTLON: '-083.7697405',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4253, 33.6506],
            [-82.329, 33.6977],
            [-82.2186, 33.6863],
            [-82.1153, 33.5966],
            [-82.0282, 33.5449],
            [-82.2942, 33.3546],
            [-82.4368, 33.5497],
            [-82.4253, 33.6506],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301410',
        NAME: 'Columbia County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 751327584,
        AWATER: 45369171,
        INTPTLAT: '+33.5476313',
        INTPTLON: '-082.2522957',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4577, 34.2427],
            [-83.4575, 34.2426],
            [-83.4314, 34.1915],
            [-83.4633, 34.1833],
            [-83.504, 34.2178],
            [-83.4577, 34.2427],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301440',
        NAME: 'Commerce City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30259022,
        AWATER: 228860,
        INTPTLAT: '+34.2142985',
        INTPTLON: '-083.4757128',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5126, 31.3274],
            [-83.4345, 31.3504],
            [-83.4351, 31.3026],
            [-83.3359, 31.2446],
            [-83.2951, 31.0273],
            [-83.4756, 31.032],
            [-83.5749, 31.0781],
            [-83.5098, 31.2062],
            [-83.5126, 31.3274],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301470',
        NAME: 'Cook County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588446812,
        AWATER: 15546061,
        INTPTLAT: '+31.1525285',
        INTPTLON: '-083.4294384',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0154, 33.4255],
            [-84.9423, 33.4364],
            [-84.8507, 33.5115],
            [-84.6095, 33.5025],
            [-84.586, 33.3579],
            [-84.4975, 33.2574],
            [-84.5024, 33.2211],
            [-84.8524, 33.2236],
            [-84.8624, 33.1912],
            [-84.939, 33.2247],
            [-85.0154, 33.4255],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301500',
        NAME: 'Coweta County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1141971255,
        AWATER: 12730357,
        INTPTLAT: '+33.3528965',
        INTPTLON: '-084.7621381',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1243, 32.8496],
            [-83.8919, 32.8484],
            [-83.8083, 32.7287],
            [-83.7012, 32.6922],
            [-83.7011, 32.6916],
            [-83.767, 32.6925],
            [-84.0034, 32.5299],
            [-84.105, 32.6734],
            [-84.2026, 32.69],
            [-84.1243, 32.8496],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301530',
        NAME: 'Crawford County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 841521432,
        AWATER: 3989504,
        INTPTLAT: '+32.7094283',
        INTPTLON: '-083.9791874',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9613, 32.0306],
            [-83.6097, 32.0279],
            [-83.6123, 31.8541],
            [-83.6126, 31.8041],
            [-83.8027, 31.8036],
            [-83.9394, 31.8479],
            [-83.9225, 31.9097],
            [-83.9613, 32.0306],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301560',
        NAME: 'Crisp County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 706002618,
        AWATER: 21657691,
        INTPTLAT: '+31.9151491',
        INTPTLON: '-083.7535313',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4741, 34.983],
            [-85.3639, 34.9834],
            [-85.4506, 34.8317],
            [-85.4504, 34.7592],
            [-85.5341, 34.6239],
            [-85.5831, 34.8604],
            [-85.6052, 34.9847],
            [-85.4741, 34.983],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301590',
        NAME: 'Dade County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450605327,
        AWATER: 467844,
        INTPTLAT: '+34.8524237',
        INTPTLON: '-085.5062006',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9835, 34.7931],
            [-84.9406, 34.8044],
            [-84.9267, 34.7912],
            [-84.961, 34.7107],
            [-84.9873, 34.7379],
            [-84.9977, 34.7457],
            [-84.9991, 34.7434],
            [-84.9835, 34.7931],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301620',
        NAME: 'Dalton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52423604,
        AWATER: 49899,
        INTPTLAT: '+34.7692219',
        INTPTLON: '-084.9704204',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1968, 34.6179],
            [-84.1886, 34.6027],
            [-84.191, 34.5392],
            [-84.102, 34.4645],
            [-83.9806, 34.4184],
            [-83.9571, 34.334],
            [-84.2581, 34.3352],
            [-84.2576, 34.381],
            [-84.3197, 34.4679],
            [-84.3456, 34.5627],
            [-84.2551, 34.5683],
            [-84.1968, 34.6179],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301650',
        NAME: 'Dawson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 546042942,
        AWATER: 9271910,
        INTPTLAT: '+34.4428421',
        INTPTLON: '-084.1732841',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2854, 33.76],
            [-84.2907, 33.751],
            [-84.3158, 33.765],
            [-84.2854, 33.76],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301680',
        NAME: 'Decatur City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11467525,
        AWATER: 2179,
        INTPTLAT: '+33.7714550',
        INTPTLON: '-084.2978566',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7309, 31.0692],
            [-84.5427, 31.079],
            [-84.5081, 31.0784],
            [-84.3766, 31.0789],
            [-84.3807, 30.69],
            [-84.8635, 30.7115],
            [-84.7559, 30.8853],
            [-84.7309, 31.0692],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301710',
        NAME: 'Decatur County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1546584747,
        AWATER: 67412755,
        INTPTLAT: '+30.8806553',
        INTPTLON: '-084.5841442',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2907, 33.751],
            [-84.2854, 33.76],
            [-84.3158, 33.765],
            [-84.3484, 33.8823],
            [-84.2768, 33.9577],
            [-84.2598, 33.9189],
            [-84.0237, 33.7528],
            [-84.1101, 33.6251],
            [-84.1841, 33.6462],
            [-84.2813, 33.6474],
            [-84.3502, 33.6479],
            [-84.3486, 33.7325],
            [-84.2907, 33.751],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301740',
        NAME: 'DeKalb County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663480031,
        AWATER: 9185365,
        INTPTLAT: '+33.7715940',
        INTPTLON: '-084.2219977',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3465, 32.2725],
            [-83.3047, 32.342],
            [-83.1733, 32.4525],
            [-83.139, 32.4231],
            [-82.991, 32.1473],
            [-82.8848, 32.1961],
            [-82.9279, 32.1353],
            [-83.2057, 31.9003],
            [-83.2615, 31.9521],
            [-83.3373, 32.1059],
            [-83.2911, 32.1788],
            [-83.3465, 32.2725],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301770',
        NAME: 'Dodge County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1284365331,
        AWATER: 18692950,
        INTPTLAT: '+32.1602346',
        INTPTLON: '-083.1562413',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0279, 32.1711],
            [-83.9588, 32.2521],
            [-83.8484, 32.291],
            [-83.6156, 32.2886],
            [-83.6085, 32.1184],
            [-83.6097, 32.0279],
            [-83.9613, 32.0306],
            [-84.0279, 32.1711],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301800',
        NAME: 'Dooly County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1015120153,
        AWATER: 13841085,
        INTPTLAT: '+32.1519954',
        INTPTLON: '-083.8071670',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4504, 31.6219],
            [-84.2978, 31.622],
            [-84.0432, 31.6236],
            [-84.0184, 31.6503],
            [-83.9962, 31.6252],
            [-83.9978, 31.4438],
            [-84.1411, 31.4402],
            [-84.4299, 31.4367],
            [-84.4504, 31.6219],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301830',
        NAME: 'Dougherty School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 851140302,
        AWATER: 15396056,
        INTPTLAT: '+31.5325876',
        INTPTLON: '-084.2090400',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9017, 33.7807],
            [-84.7241, 33.8062],
            [-84.6227, 33.8048],
            [-84.5781, 33.7435],
            [-84.6564, 33.6594],
            [-84.8089, 33.5741],
            [-84.9058, 33.5734],
            [-84.9017, 33.7807],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301860',
        NAME: 'Douglas County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 518293193,
        AWATER: 2485429,
        INTPTLAT: '+33.6993166',
        INTPTLON: '-084.7659441',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9851, 32.4957],
            [-82.9201, 32.5734],
            [-82.8854, 32.5184],
            [-82.9851, 32.4957],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301870',
        NAME: 'Dublin City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39455636,
        AWATER: 239546,
        INTPTLAT: '+32.5363601',
        INTPTLON: '-082.9247076',
        ELSDLEA: '',
        UNSDLEA: '01870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9571, 32.7083],
            [-82.8628, 32.7158],
            [-82.7945, 32.6602],
            [-82.6686, 32.6122],
            [-82.6477, 32.5125],
            [-82.6856, 32.4605],
            [-82.722, 32.3093],
            [-82.8848, 32.1961],
            [-82.991, 32.1473],
            [-83.139, 32.4231],
            [-83.2265, 32.5842],
            [-82.9571, 32.7083],
          ],
          [
            [-82.9851, 32.4957],
            [-82.8854, 32.5184],
            [-82.9201, 32.5734],
            [-82.9851, 32.4957],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301890',
        NAME: 'Laurens County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2051446937,
        AWATER: 28686392,
        INTPTLAT: '+32.4609443',
        INTPTLON: '-082.9111706',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0877, 31.3087],
            [-85.0455, 31.5171],
            [-85.0292, 31.4861],
            [-84.8198, 31.5012],
            [-84.789, 31.4364],
            [-84.6376, 31.4339],
            [-84.6417, 31.259],
            [-84.9174, 31.256],
            [-84.9224, 31.0726],
            [-85.0286, 31.0755],
            [-85.0996, 31.1649],
            [-85.0877, 31.3087],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301920',
        NAME: 'Early County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1327612129,
        AWATER: 9717177,
        INTPTLAT: '+31.3241906',
        INTPTLON: '-084.9067225',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0194, 30.8495],
            [-82.9713, 30.8694],
            [-82.8464, 30.8349],
            [-82.7662, 30.7324],
            [-82.6043, 30.7137],
            [-82.584, 30.5915],
            [-82.6895, 30.5977],
            [-83.1366, 30.624],
            [-83.1249, 30.8037],
            [-83.0194, 30.8495],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301950',
        NAME: 'Echols County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1074571646,
        AWATER: 15087403,
        INTPTLAT: '+30.7168312',
        INTPTLON: '-082.8752239',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.548, 32.4893],
            [-81.3892, 32.5954],
            [-81.2813, 32.5565],
            [-81.2004, 32.4683],
            [-81.2056, 32.4239],
            [-81.1226, 32.307],
            [-81.1446, 32.2261],
            [-81.1956, 32.2375],
            [-81.3917, 32.0959],
            [-81.4358, 32.2413],
            [-81.4643, 32.3247],
            [-81.5309, 32.3904],
            [-81.548, 32.4893],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1301980',
        NAME: 'Effingham County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1237247956,
        AWATER: 13536096,
        INTPTLAT: '+32.3617106',
        INTPTLON: '-081.3433367',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.078, 34.2236],
            [-82.9809, 34.2115],
            [-82.7734, 34.2889],
            [-82.741, 34.2089],
            [-82.5946, 34.0138],
            [-82.5645, 33.9557],
            [-82.6455, 33.9842],
            [-82.7795, 33.9711],
            [-82.8643, 33.9837],
            [-82.9763, 34.0432],
            [-83.0678, 34.1549],
            [-83.078, 34.2236],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302010',
        NAME: 'Elbert County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 909234534,
        AWATER: 59980940,
        INTPTLAT: '+34.1164548',
        INTPTLON: '-082.8418338',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6477, 32.5125],
            [-82.5944, 32.5792],
            [-82.4698, 32.6509],
            [-82.4341, 32.7623],
            [-82.4085, 32.8151],
            [-82.3165, 32.8358],
            [-82.145, 32.8127],
            [-82.0824, 32.6631],
            [-82.0012, 32.6069],
            [-82.0302, 32.5388],
            [-82.1483, 32.5204],
            [-82.2528, 32.3533],
            [-82.2328, 32.3188],
            [-82.3545, 32.2922],
            [-82.4091, 32.3538],
            [-82.3861, 32.4404],
            [-82.5, 32.4984],
            [-82.6477, 32.5125],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302040',
        NAME: 'Emanuel County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1762760538,
        AWATER: 24802077,
        INTPTLAT: '+32.5910995',
        INTPTLON: '-082.2997628',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0253, 32.2789],
            [-81.9691, 32.2688],
            [-81.8163, 32.2375],
            [-81.7809, 32.1529],
            [-81.7633, 32.1176],
            [-81.8575, 32.0498],
            [-81.98, 32.0815],
            [-82.0253, 32.2789],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302070',
        NAME: 'Evans County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404646734,
        AWATER: 9836713,
        INTPTLAT: '+32.1716672',
        INTPTLON: '-081.9023657',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6215, 34.9883],
            [-84.3219, 34.9884],
            [-84.1294, 34.9875],
            [-84.1036, 34.7278],
            [-84.158, 34.6482],
            [-84.1886, 34.6027],
            [-84.1968, 34.6179],
            [-84.3147, 34.8073],
            [-84.4291, 34.855],
            [-84.6186, 34.8554],
            [-84.6215, 34.9883],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302100',
        NAME: 'Fannin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1002370114,
        AWATER: 13560718,
        INTPTLAT: '+34.8665427',
        INTPTLON: '-084.3173308',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6095, 33.5025],
            [-84.4587, 33.5509],
            [-84.3877, 33.4771],
            [-84.3881, 33.3525],
            [-84.4329, 33.2565],
            [-84.4975, 33.2574],
            [-84.586, 33.3579],
            [-84.6095, 33.5025],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302130',
        NAME: 'Fayette County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503398796,
        AWATER: 12846500,
        INTPTLAT: '+33.4127172',
        INTPTLON: '-084.4939407',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4622, 34.2864],
            [-85.3874, 34.2861],
            [-85.3349, 34.3697],
            [-85.1805, 34.436],
            [-85.1077, 34.5875],
            [-85.0692, 34.5872],
            [-85.0868, 34.4013],
            [-85.0058, 34.3924],
            [-85.0469, 34.0964],
            [-85.2576, 34.1007],
            [-85.4219, 34.0808],
            [-85.4622, 34.2864],
          ],
          [
            [-85.1311, 34.2131],
            [-85.1284, 34.288],
            [-85.2115, 34.2633],
            [-85.1879, 34.2055],
            [-85.1311, 34.2131],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302190',
        NAME: 'Floyd County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1238417505,
        AWATER: 20234884,
        INTPTLAT: '+34.3345770',
        INTPTLON: '-085.2324136',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2587, 34.1859],
            [-84.2581, 34.3352],
            [-83.9571, 34.334],
            [-83.9273, 34.2794],
            [-83.9891, 34.1957],
            [-84.0628, 34.1679],
            [-84.0977, 34.0507],
            [-84.2589, 34.1095],
            [-84.2587, 34.1859],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302220',
        NAME: 'Forsyth County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 580297280,
        AWATER: 59743068,
        INTPTLAT: '+34.2251430',
        INTPTLON: '-084.1274353',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1037, 34.5366],
            [-83.0516, 34.4937],
            [-83.0942, 34.4159],
            [-83.1134, 34.2735],
            [-83.1727, 34.2385],
            [-83.2973, 34.2646],
            [-83.3553, 34.2237],
            [-83.3939, 34.3248],
            [-83.3984, 34.4609],
            [-83.1778, 34.477],
            [-83.1037, 34.5366],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302250',
        NAME: 'Franklin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 677301813,
        AWATER: 12803554,
        INTPTLAT: '+34.3750807',
        INTPTLON: '-083.2280639',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.4189, 34.0733],
              [-84.3283, 34.1861],
              [-84.2587, 34.1859],
              [-84.2589, 34.1095],
              [-84.0977, 34.0507],
              [-84.2768, 33.9577],
              [-84.3484, 33.8823],
              [-84.4511, 33.871],
              [-84.383, 33.9638],
              [-84.4189, 34.0733],
            ],
          ],
          [
            [
              [-84.8089, 33.5741],
              [-84.6564, 33.6594],
              [-84.5781, 33.7435],
              [-84.5079, 33.7955],
              [-84.5457, 33.6808],
              [-84.4154, 33.6972],
              [-84.3994, 33.6483],
              [-84.4192, 33.6486],
              [-84.4285, 33.6487],
              [-84.4587, 33.5509],
              [-84.6095, 33.5025],
              [-84.8507, 33.5115],
              [-84.8089, 33.5741],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302280',
        NAME: 'Fulton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1037565085,
        AWATER: 17586194,
        INTPTLAT: '+33.5852546',
        INTPTLON: '-084.5682092',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8652, 34.2828],
            [-83.795, 34.3277],
            [-83.7878, 34.2396],
            [-83.8652, 34.2828],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302310',
        NAME: 'Gainesville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85382220,
        AWATER: 5039686,
        INTPTLAT: '+34.2896583',
        INTPTLON: '-083.8298926',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6186, 34.8554],
            [-84.4291, 34.855],
            [-84.3147, 34.8073],
            [-84.1968, 34.6179],
            [-84.2551, 34.5683],
            [-84.3456, 34.5627],
            [-84.3714, 34.5485],
            [-84.6544, 34.5489],
            [-84.6545, 34.5832],
            [-84.6571, 34.7289],
            [-84.5822, 34.8246],
            [-84.6186, 34.8554],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302340',
        NAME: 'Gilmer County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1103983751,
        AWATER: 12186837,
        INTPTLAT: '+34.6904951',
        INTPTLON: '-084.4546507',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.432, 33.2748],
            [-82.6619, 33.1263],
            [-82.7395, 33.1744],
            [-82.7483, 33.2383],
            [-82.7558, 33.2534],
            [-82.5598, 33.3273],
            [-82.432, 33.2748],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302370',
        NAME: 'Glascock County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372284127,
        AWATER: 1847843,
        INTPTLAT: '+33.2274908',
        INTPTLON: '-082.6069126',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7317, 31.33],
            [-81.6243, 31.453],
            [-81.5776, 31.3994],
            [-81.4359, 31.3133],
            [-81.1837, 31.2943],
            [-81.2343, 31.1703],
            [-81.2921, 31.1554],
            [-81.3064, 30.9906],
            [-81.4238, 30.9909],
            [-81.5651, 31.0919],
            [-81.7663, 31.1696],
            [-81.7317, 31.33],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302400',
        NAME: 'Glynn County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1087272600,
        AWATER: 428512639,
        INTPTLAT: '+31.2127465',
        INTPTLON: '-081.4965167',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0692, 34.5872],
            [-85.0504, 34.6225],
            [-84.9135, 34.6341],
            [-84.8638, 34.6086],
            [-84.7141, 34.619],
            [-84.6545, 34.5832],
            [-84.6544, 34.5489],
            [-84.6532, 34.4126],
            [-84.7341, 34.3965],
            [-85.0058, 34.3924],
            [-85.0868, 34.4013],
            [-85.0692, 34.5872],
          ],
          [
            [-84.929, 34.4808],
            [-84.9391, 34.4818],
            [-84.9405, 34.4817],
            [-84.9449, 34.4864],
            [-84.9075, 34.492],
            [-84.9057, 34.5119],
            [-84.9531, 34.53],
            [-84.9637, 34.5025],
            [-84.9207, 34.4297],
            [-84.9177, 34.4806],
            [-84.929, 34.4808],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302430',
        NAME: 'Gordon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 883133790,
        AWATER: 5538395,
        INTPTLAT: '+34.5096669',
        INTPTLON: '-084.8738622',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3766, 31.0789],
            [-84.1166, 31.078],
            [-84.1192, 30.9835],
            [-84.0771, 30.915],
            [-84.0838, 30.6758],
            [-84.2826, 30.6853],
            [-84.3807, 30.69],
            [-84.3766, 31.0789],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302460',
        NAME: 'Grady County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1177231778,
        AWATER: 14846318,
        INTPTLAT: '+30.8758910',
        INTPTLON: '-084.2450655',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4062, 33.6983],
            [-83.2803, 33.7618],
            [-83.1187, 33.6988],
            [-82.9956, 33.6936],
            [-82.9511, 33.658],
            [-83.014, 33.5804],
            [-83.0129, 33.4692],
            [-83.1642, 33.355],
            [-83.2799, 33.4834],
            [-83.2776, 33.5276],
            [-83.4062, 33.6983],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302490',
        NAME: 'Greene County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1003451715,
        AWATER: 48630004,
        INTPTLAT: '+33.5767403',
        INTPTLON: '-083.1666127',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4975, 33.2574],
            [-84.4329, 33.2565],
            [-84.3881, 33.3525],
            [-84.3544, 33.3525],
            [-84.1506, 33.3356],
            [-84.1026, 33.2982],
            [-84.1238, 33.2028],
            [-84.2482, 33.189],
            [-84.4513, 33.2089],
            [-84.4968, 33.1839],
            [-84.5024, 33.2211],
            [-84.4975, 33.2574],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302520',
        NAME: 'Spalding County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507333988,
        AWATER: 9602978,
        INTPTLAT: '+33.2623888',
        INTPTLON: '-084.2860671',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2768, 33.9577],
            [-84.0977, 34.0507],
            [-84.0628, 34.1679],
            [-84.0124, 34.15],
            [-84.0087, 34.1487],
            [-83.9967, 34.1443],
            [-83.9963, 34.1442],
            [-83.995, 34.1437],
            [-84.0063, 34.1435],
            [-84.007, 34.1432],
            [-84.0185, 34.0916],
            [-83.9796, 34.0927],
            [-83.9605, 34.1315],
            [-83.8177, 34.1275],
            [-83.8689, 34.0048],
            [-83.7991, 33.9298],
            [-83.982, 33.7861],
            [-84.0237, 33.7528],
            [-84.2598, 33.9189],
            [-84.2768, 33.9577],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302550',
        NAME: 'Gwinnett County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1080368397,
        AWATER: 16163060,
        INTPTLAT: '+33.9552847',
        INTPTLON: '-084.0247030',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6816, 34.8006],
            [-83.6522, 34.823],
            [-83.549, 34.7495],
            [-83.3524, 34.7161],
            [-83.3382, 34.6872],
            [-83.365, 34.6753],
            [-83.4598, 34.4811],
            [-83.5375, 34.4917],
            [-83.6153, 34.4317],
            [-83.6664, 34.5036],
            [-83.6323, 34.5286],
            [-83.6468, 34.6689],
            [-83.6233, 34.7322],
            [-83.6816, 34.8006],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302580',
        NAME: 'Habersham County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 716754762,
        AWATER: 5917259,
        INTPTLAT: '+34.6343924',
        INTPTLON: '-083.5258749',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9571, 34.334],
            [-83.9806, 34.4184],
            [-83.8434, 34.5055],
            [-83.6664, 34.5036],
            [-83.6153, 34.4317],
            [-83.6668, 34.368],
            [-83.6201, 34.2953],
            [-83.8177, 34.1275],
            [-83.9605, 34.1315],
            [-83.995, 34.1437],
            [-83.9963, 34.1442],
            [-83.9967, 34.1443],
            [-84.0087, 34.1487],
            [-84.0124, 34.15],
            [-84.0628, 34.1679],
            [-83.9891, 34.1957],
            [-83.9273, 34.2794],
            [-83.9571, 34.334],
          ],
          [
            [-83.8652, 34.2828],
            [-83.7878, 34.2396],
            [-83.795, 34.3277],
            [-83.8652, 34.2828],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302610',
        NAME: 'Hall County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 925548538,
        AWATER: 89522262,
        INTPTLAT: '+34.4073266',
        INTPTLON: '-083.7400969',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1642, 33.355],
            [-83.0129, 33.4692],
            [-82.852, 33.4435],
            [-82.7558, 33.2534],
            [-82.7483, 33.2383],
            [-82.855, 33.1964],
            [-82.8889, 33.1386],
            [-83.0522, 33.0807],
            [-83.0976, 33.1515],
            [-83.2741, 33.1872],
            [-83.2535, 33.2593],
            [-83.1458, 33.3121],
            [-83.1642, 33.355],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302640',
        NAME: 'Hancock County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1220058031,
        AWATER: 17479750,
        INTPTLAT: '+33.2692195',
        INTPTLON: '-083.0004653',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.1159, 33.7018],
              [-85.1151, 33.6913],
              [-85.1314, 33.6998],
              [-85.1159, 33.7018],
            ],
          ],
          [
            [
              [-85.3866, 33.9017],
              [-85.0503, 33.9045],
              [-85.0379, 33.8119],
              [-85.0509, 33.7146],
              [-85.11, 33.6933],
              [-85.1295, 33.7369],
              [-85.1685, 33.7252],
              [-85.1935, 33.6831],
              [-85.3382, 33.6531],
              [-85.381, 33.8735],
              [-85.3866, 33.9017],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302670',
        NAME: 'Haralson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 709332701,
        AWATER: 2595482,
        INTPTLAT: '+33.7951649',
        INTPTLON: '-085.2200622',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1841, 32.8705],
            [-84.8618, 32.8725],
            [-84.7005, 32.8446],
            [-84.6946, 32.5839],
            [-84.9079, 32.5834],
            [-85.0808, 32.6081],
            [-85.135, 32.7465],
            [-85.1785, 32.8547],
            [-85.1849, 32.8634],
            [-85.1841, 32.8705],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302700',
        NAME: 'Harris County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1201462494,
        AWATER: 23488477,
        INTPTLAT: '+32.7315494',
        INTPTLON: '-084.9124320',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1134, 34.2735],
            [-83.0942, 34.4159],
            [-83.0516, 34.4937],
            [-82.9922, 34.4793],
            [-82.9016, 34.4868],
            [-82.8555, 34.4418],
            [-82.8469, 34.4194],
            [-82.7734, 34.2889],
            [-82.9809, 34.2115],
            [-83.078, 34.2236],
            [-83.1134, 34.2735],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302730',
        NAME: 'Hart County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601903610,
        AWATER: 63588441,
        INTPTLAT: '+34.3487328',
        INTPTLON: '-082.9632900',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2938, 33.4281],
            [-85.0154, 33.4255],
            [-84.939, 33.2247],
            [-85.2365, 33.1296],
            [-85.2938, 33.4281],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302790',
        NAME: 'Heard County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 766718985,
        AWATER: 13112095,
        INTPTLAT: '+33.2913474',
        INTPTLON: '-085.1378787',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2813, 33.6474],
            [-84.1841, 33.6462],
            [-84.1287, 33.5629],
            [-84.0445, 33.5258],
            [-83.9239, 33.4442],
            [-84.1026, 33.2982],
            [-84.1506, 33.3356],
            [-84.3544, 33.3525],
            [-84.2947, 33.4357],
            [-84.2626, 33.631],
            [-84.2813, 33.6474],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302820',
        NAME: 'Henry County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 825200366,
        AWATER: 20443876,
        INTPTLAT: '+33.4529466',
        INTPTLON: '-084.1540207',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8467, 32.4685],
            [-83.7199, 32.5318],
            [-83.7011, 32.6916],
            [-83.7012, 32.6922],
            [-83.6079, 32.6645],
            [-83.5882, 32.5751],
            [-83.5552, 32.6227],
            [-83.603, 32.6618],
            [-83.5977, 32.6643],
            [-83.5631, 32.6598],
            [-83.4979, 32.4522],
            [-83.498, 32.4017],
            [-83.6156, 32.2886],
            [-83.8484, 32.291],
            [-83.8467, 32.4685],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302880',
        NAME: 'Houston County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 945635932,
        AWATER: 10926228,
        INTPTLAT: '+32.4583811',
        INTPTLON: '-083.6628559',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5007, 31.594],
            [-83.4536, 31.7579],
            [-83.3253, 31.7566],
            [-83.3256, 31.6806],
            [-82.9984, 31.6732],
            [-83.1456, 31.4723],
            [-83.3387, 31.476],
            [-83.5007, 31.594],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302910',
        NAME: 'Irwin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 917793241,
        AWATER: 21758147,
        INTPTLAT: '+31.6043062',
        INTPTLON: '-083.2770374',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8177, 34.1275],
            [-83.6201, 34.2953],
            [-83.5615, 34.2527],
            [-83.4577, 34.2427],
            [-83.504, 34.2178],
            [-83.4633, 34.1833],
            [-83.4314, 34.1915],
            [-83.4575, 34.2426],
            [-83.4024, 34.1975],
            [-83.3638, 34.1641],
            [-83.36, 34.0406],
            [-83.5374, 33.9659],
            [-83.5632, 34.0318],
            [-83.7514, 34.0642],
            [-83.8177, 34.1275],
          ],
          [
            [-83.6744, 34.1564],
            [-83.6138, 34.1037],
            [-83.5758, 34.1795],
            [-83.6744, 34.1564],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302940',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793351572,
        AWATER: 7807118,
        INTPTLAT: '+34.1308883',
        INTPTLON: '-083.5625382',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8631, 33.3683],
            [-83.8225, 33.4378],
            [-83.6822, 33.5262],
            [-83.5337, 33.4345],
            [-83.5459, 33.1719],
            [-83.816, 33.1318],
            [-83.8223, 33.1802],
            [-83.8141, 33.2421],
            [-83.8631, 33.3683],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1302970',
        NAME: 'Jasper County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 953541347,
        AWATER: 13727188,
        INTPTLAT: '+33.3181691',
        INTPTLON: '-083.6901425',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8364, 31.8162],
            [-82.6457, 31.9189],
            [-82.5437, 31.9589],
            [-82.4831, 31.969],
            [-82.4315, 31.9662],
            [-82.4314, 31.838],
            [-82.5203, 31.8384],
            [-82.5214, 31.7108],
            [-82.6273, 31.6727],
            [-82.8366, 31.6714],
            [-82.8364, 31.8162],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303000',
        NAME: 'Jeff Davis County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 856690607,
        AWATER: 11986249,
        INTPTLAT: '+31.8116153',
        INTPTLON: '-082.6368248',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6744, 34.1564],
            [-83.5758, 34.1795],
            [-83.6138, 34.1037],
            [-83.6744, 34.1564],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303030',
        NAME: 'Jefferson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56105786,
        AWATER: 870571,
        INTPTLAT: '+34.1354856',
        INTPTLON: '-083.6015785',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6619, 33.1263],
            [-82.432, 33.2748],
            [-82.3838, 33.3121],
            [-82.3535, 33.3123],
            [-82.2676, 33.2674],
            [-82.2331, 33.2277],
            [-82.2912, 33.0628],
            [-82.2724, 32.9378],
            [-82.3165, 32.8358],
            [-82.4085, 32.8151],
            [-82.4341, 32.7623],
            [-82.5211, 32.8224],
            [-82.5109, 32.9178],
            [-82.5479, 33.0085],
            [-82.6619, 33.1263],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303060',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1363589116,
        AWATER: 8241829,
        INTPTLAT: '+33.0581756',
        INTPTLON: '-082.4200036',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7676, 32.9094],
            [-81.8679, 32.6811],
            [-81.841, 32.6491],
            [-82.0012, 32.6069],
            [-82.0824, 32.6631],
            [-82.145, 32.8127],
            [-82.0813, 32.9168],
            [-81.858, 32.9539],
            [-81.7676, 32.9094],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303090',
        NAME: 'Jenkins County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 899449594,
        AWATER: 13452206,
        INTPTLAT: '+32.7945631',
        INTPTLON: '-081.9715244',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.947, 32.7594],
            [-82.8025, 32.8098],
            [-82.7684, 32.7691],
            [-82.5211, 32.8224],
            [-82.4341, 32.7623],
            [-82.4698, 32.6509],
            [-82.5944, 32.5792],
            [-82.6477, 32.5125],
            [-82.6686, 32.6122],
            [-82.7945, 32.6602],
            [-82.8628, 32.7158],
            [-82.9571, 32.7083],
            [-82.947, 32.7594],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303120',
        NAME: 'Johnson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 784793325,
        AWATER: 9278537,
        INTPTLAT: '+32.6945842',
        INTPTLON: '-082.6639696',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.816, 33.1318],
            [-83.5459, 33.1719],
            [-83.4291, 33.1854],
            [-83.3577, 32.9261],
            [-83.407, 32.8982],
            [-83.5135, 32.8449],
            [-83.6582, 32.8879],
            [-83.7107, 32.9528],
            [-83.747, 33.0634],
            [-83.816, 33.1318],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303150',
        NAME: 'Jones County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1020291683,
        AWATER: 3832666,
        INTPTLAT: '+33.0202261',
        INTPTLON: '-083.5623393',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2482, 33.189],
            [-84.1238, 33.2028],
            [-84.0415, 33.2026],
            [-84.0441, 32.9479],
            [-84.1233, 32.9322],
            [-84.1222, 32.9887],
            [-84.2701, 32.991],
            [-84.2482, 33.189],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303210',
        NAME: 'Lamar County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475262774,
        AWATER: 6046030,
        INTPTLAT: '+33.0744604',
        INTPTLON: '-084.1467206',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0469, 31.1837],
            [-82.9712, 31.184],
            [-82.9713, 30.8694],
            [-83.0194, 30.8495],
            [-83.0429, 30.9473],
            [-83.1795, 30.9501],
            [-83.198, 31.0254],
            [-83.1651, 31.1472],
            [-83.0469, 31.1837],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303240',
        NAME: 'Lanier County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 479903182,
        AWATER: 37546562,
        INTPTLAT: '+31.0381973',
        INTPTLON: '-083.0631635',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9225, 31.9097],
            [-83.9394, 31.8479],
            [-84.0256, 31.71],
            [-84.0184, 31.6503],
            [-84.0432, 31.6236],
            [-84.2978, 31.622],
            [-84.3052, 31.6911],
            [-84.2623, 31.8285],
            [-84.3382, 31.8736],
            [-84.3381, 31.9162],
            [-83.9225, 31.9097],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303270',
        NAME: 'Lee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 921722909,
        AWATER: 15318763,
        INTPTLAT: '+31.8184185',
        INTPTLON: '-084.1466814',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7658, 31.8878],
            [-81.6379, 31.8496],
            [-81.4049, 31.9369],
            [-81.377, 31.8483],
            [-81.2599, 31.7538],
            [-81.1547, 31.7131],
            [-81.0515, 31.7052],
            [-81.0954, 31.521],
            [-81.1751, 31.5397],
            [-81.2619, 31.6501],
            [-81.4358, 31.6421],
            [-81.4918, 31.6996],
            [-81.6418, 31.7655],
            [-81.7658, 31.8878],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303300',
        NAME: 'Liberty County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 859216934,
        AWATER: 221945854,
        INTPTLAT: '+31.7325860',
        INTPTLON: '-081.3768061',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6455, 33.9842],
            [-82.5645, 33.9557],
            [-82.3469, 33.8343],
            [-82.2475, 33.7526],
            [-82.2186, 33.6863],
            [-82.329, 33.6977],
            [-82.4253, 33.6506],
            [-82.4795, 33.6395],
            [-82.5737, 33.7861],
            [-82.6455, 33.9842],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303330',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544880787,
        AWATER: 121516946,
        INTPTLAT: '+33.7921507',
        INTPTLON: '-082.4482992',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.882, 31.9241],
            [-81.7658, 31.8878],
            [-81.6418, 31.7655],
            [-81.4918, 31.6996],
            [-81.566, 31.5765],
            [-81.6632, 31.5387],
            [-81.8239, 31.6518],
            [-81.9691, 31.7893],
            [-81.882, 31.9241],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303360',
        NAME: 'Long County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 923860591,
        AWATER: 8449086,
        INTPTLAT: '+31.7318696',
        INTPTLON: '-081.7338618',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4756, 31.032],
            [-83.2951, 31.0273],
            [-83.198, 31.0254],
            [-83.1795, 30.9501],
            [-83.0429, 30.9473],
            [-83.0194, 30.8495],
            [-83.1249, 30.8037],
            [-83.1366, 30.624],
            [-83.3092, 30.6344],
            [-83.3409, 30.6363],
            [-83.3577, 30.6374],
            [-83.4825, 30.7569],
            [-83.3709, 30.8848],
            [-83.4756, 31.032],
          ],
          [
            [-83.3529, 30.8413],
            [-83.3026, 30.7932],
            [-83.2494, 30.8024],
            [-83.2845, 30.883],
            [-83.3529, 30.8413],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303390',
        NAME: 'Lowndes County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1191985146,
        AWATER: 36177238,
        INTPTLAT: '+30.7221612',
        INTPTLON: '-083.3626904',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1886, 34.6027],
            [-84.158, 34.6482],
            [-84.0363, 34.6421],
            [-83.939, 34.7409],
            [-83.8565, 34.7222],
            [-83.8644, 34.5506],
            [-83.8434, 34.5055],
            [-83.9806, 34.4184],
            [-84.102, 34.4645],
            [-84.191, 34.5392],
            [-84.1886, 34.6027],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303420',
        NAME: 'Lumpkin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 732793278,
        AWATER: 3273370,
        INTPTLAT: '+34.5681350',
        INTPTLON: '-083.9989062',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.0034, 32.5299],
              [-84.0085, 32.5218],
              [-84.0533, 32.522],
              [-84.0034, 32.5299],
            ],
          ],
          [
            [
              [-84.2546, 32.3721],
              [-84.1979, 32.4009],
              [-84.1223, 32.5059],
              [-84.0533, 32.522],
              [-84.0181, 32.5064],
              [-83.8467, 32.4685],
              [-83.8484, 32.291],
              [-83.9588, 32.2521],
              [-84.0279, 32.1711],
              [-84.1817, 32.2297],
              [-84.2559, 32.2962],
              [-84.2546, 32.3721],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303450',
        NAME: 'Macon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1037654069,
        AWATER: 13859152,
        INTPTLAT: '+32.3626859',
        INTPTLON: '-084.0512306',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4024, 34.1975],
            [-83.3553, 34.2237],
            [-83.2973, 34.2646],
            [-83.1727, 34.2385],
            [-83.1134, 34.2735],
            [-83.078, 34.2236],
            [-83.0678, 34.1549],
            [-82.9763, 34.0432],
            [-83.0493, 34.0161],
            [-83.1262, 34.0462],
            [-83.2584, 33.9991],
            [-83.36, 34.0406],
            [-83.3638, 34.1641],
            [-83.4024, 34.1975],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303480',
        NAME: 'Madison County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 731184844,
        AWATER: 8484618,
        INTPTLAT: '+34.1285257',
        INTPTLON: '-083.2037373',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5969, 33.9385],
            [-84.5475, 33.9973],
            [-84.4994, 33.9383],
            [-84.5969, 33.9385],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303510',
        NAME: 'Marietta City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60228233,
        AWATER: 200841,
        INTPTLAT: '+33.9535613',
        INTPTLON: '-084.5424631',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6582, 32.3098],
            [-84.6573, 32.4949],
            [-84.6373, 32.5349],
            [-84.4444, 32.5621],
            [-84.3923, 32.414],
            [-84.3899, 32.2972],
            [-84.4302, 32.1663],
            [-84.4312, 32.1341],
            [-84.5271, 32.1346],
            [-84.6493, 32.233],
            [-84.6589, 32.2329],
            [-84.6582, 32.3098],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303540',
        NAME: 'Marion County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 947978204,
        AWATER: 3791374,
        INTPTLAT: '+32.3595381',
        INTPTLON: '-084.5295606',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6497, 33.6088],
            [-82.4795, 33.6395],
            [-82.4253, 33.6506],
            [-82.4368, 33.5497],
            [-82.2942, 33.3546],
            [-82.3535, 33.3123],
            [-82.3838, 33.3121],
            [-82.5358, 33.3575],
            [-82.6497, 33.6088],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303570',
        NAME: 'McDuffie County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666816637,
        AWATER: 23116292,
        INTPTLAT: '+33.4824637',
        INTPTLON: '-082.4731880',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6632, 31.5387],
            [-81.566, 31.5765],
            [-81.4918, 31.6996],
            [-81.4358, 31.6421],
            [-81.2619, 31.6501],
            [-81.1751, 31.5397],
            [-81.0954, 31.521],
            [-81.1908, 31.3999],
            [-81.1837, 31.2943],
            [-81.4359, 31.3133],
            [-81.5776, 31.3994],
            [-81.6243, 31.453],
            [-81.6632, 31.5387],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303600',
        NAME: 'McIntosh County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1099050235,
        AWATER: 387326533,
        INTPTLAT: '+31.4885923',
        INTPTLON: '-081.3724597',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8624, 33.1912],
            [-84.8524, 33.2236],
            [-84.5024, 33.2211],
            [-84.4968, 33.1839],
            [-84.5287, 33.098],
            [-84.527, 32.9705],
            [-84.5069, 32.8818],
            [-84.5707, 32.8452],
            [-84.7005, 32.8446],
            [-84.8618, 32.8725],
            [-84.8624, 33.1912],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303630',
        NAME: 'Meriwether County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1298160991,
        AWATER: 10834086,
        INTPTLAT: '+33.0292436',
        INTPTLON: '-084.6670413',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6417, 31.259],
            [-84.5371, 31.2559],
            [-84.5427, 31.079],
            [-84.7309, 31.0692],
            [-84.9224, 31.0726],
            [-84.9174, 31.256],
            [-84.6417, 31.259],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303660',
        NAME: 'Miller County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 731466841,
        AWATER: 3216290,
        INTPTLAT: '+31.1629079',
        INTPTLON: '-084.7303856',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1411, 31.4402],
            [-83.9978, 31.4438],
            [-83.9994, 31.335],
            [-84.0036, 31.0773],
            [-84.1166, 31.078],
            [-84.3766, 31.0789],
            [-84.5081, 31.0784],
            [-84.4833, 31.1555],
            [-84.4045, 31.199],
            [-84.3406, 31.3053],
            [-84.198, 31.3575],
            [-84.1411, 31.4402],
          ],
          [
            [-84.1709, 31.1245],
            [-84.1395, 31.114],
            [-84.1336, 31.1343],
            [-84.1613, 31.1406],
            [-84.1709, 31.1245],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303690',
        NAME: 'Mitchell County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1315768774,
        AWATER: 4467833,
        INTPTLAT: '+31.2289970',
        INTPTLON: '-084.1920380',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1233, 32.9322],
            [-84.0441, 32.9479],
            [-84.0415, 33.2026],
            [-83.8223, 33.1802],
            [-83.816, 33.1318],
            [-83.747, 33.0634],
            [-83.7107, 32.9528],
            [-83.8919, 32.8484],
            [-84.1243, 32.8496],
            [-84.1233, 32.9322],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303720',
        NAME: 'Monroe County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1025912893,
        AWATER: 5603382,
        INTPTLAT: '+33.0174352',
        INTPTLON: '-083.9229376',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4091, 32.3537],
            [-82.4406, 32.2416],
            [-82.443, 32.2075],
            [-82.4436, 32.2042],
            [-82.4437, 32.2035],
            [-82.4831, 31.969],
            [-82.5437, 31.9589],
            [-82.5977, 32.014],
            [-82.6555, 32.2976],
            [-82.4091, 32.3537],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303750',
        NAME: 'Montgomery County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 618353156,
        AWATER: 13347669,
        INTPTLAT: '+32.1721081',
        INTPTLON: '-082.5333487',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6809, 33.5969],
            [-83.6745, 33.605],
            [-83.6691, 33.6119],
            [-83.5059, 33.8178],
            [-83.4062, 33.6983],
            [-83.2776, 33.5276],
            [-83.2799, 33.4834],
            [-83.5337, 33.4345],
            [-83.6822, 33.5262],
            [-83.6809, 33.5969],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303780',
        NAME: 'Morgan County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 899647678,
        AWATER: 18815669,
        INTPTLAT: '+33.5932361',
        INTPTLON: '-083.4910270',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8652, 34.728],
            [-84.8679, 34.7295],
            [-84.8785, 34.7796],
            [-84.8187, 34.877],
            [-84.8105, 34.9876],
            [-84.7759, 34.9879],
            [-84.6215, 34.9883],
            [-84.6186, 34.8554],
            [-84.5822, 34.8246],
            [-84.6571, 34.7289],
            [-84.6545, 34.5832],
            [-84.7141, 34.619],
            [-84.8638, 34.6086],
            [-84.9135, 34.6341],
            [-84.8652, 34.728],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303840',
        NAME: 'Murray County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 892278138,
        AWATER: 5719645,
        INTPTLAT: '+34.7970974',
        INTPTLON: '-084.7379899',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0808, 32.6081],
            [-84.9079, 32.5834],
            [-84.6946, 32.5839],
            [-84.6795, 32.5358],
            [-84.8217, 32.5353],
            [-84.8923, 32.4228],
            [-84.9577, 32.3742],
            [-84.972, 32.3777],
            [-84.9632, 32.4234],
            [-85.001, 32.5102],
            [-85.0013, 32.5128],
            [-85.0013, 32.513],
            [-85.0015, 32.5147],
            [-85.0808, 32.6081],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303870',
        NAME: 'Muscogee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368675279,
        AWATER: 11323604,
        INTPTLAT: '+32.5266873',
        INTPTLON: '-084.9108735',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0445, 33.5258],
            [-83.9309, 33.6518],
            [-83.9148, 33.7442],
            [-83.7549, 33.6464],
            [-83.7085, 33.6283],
            [-83.7055, 33.6263],
            [-83.7019, 33.6238],
            [-83.7004, 33.6228],
            [-83.6917, 33.6171],
            [-83.6919, 33.6167],
            [-83.6989, 33.6091],
            [-83.6809, 33.5969],
            [-83.6822, 33.5262],
            [-83.8225, 33.4378],
            [-83.8631, 33.3683],
            [-83.9239, 33.4442],
            [-84.0445, 33.5258],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303930',
        NAME: 'Newton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 703773141,
        AWATER: 18130753,
        INTPTLAT: '+33.5440212',
        INTPTLON: '-083.8551846',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.647, 33.9062],
            [-83.5374, 33.9659],
            [-83.2759, 33.848],
            [-83.2803, 33.7618],
            [-83.4062, 33.6983],
            [-83.5059, 33.8178],
            [-83.647, 33.9062],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303960',
        NAME: 'Oconee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477395751,
        AWATER: 5305707,
        INTPTLAT: '+33.8341247',
        INTPTLON: '-083.4377284',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2759, 33.848],
            [-83.2409, 33.9044],
            [-83.2584, 33.9991],
            [-83.1262, 34.0462],
            [-83.0493, 34.0161],
            [-82.9763, 34.0432],
            [-82.8643, 33.9837],
            [-82.7795, 33.9711],
            [-82.8467, 33.9406],
            [-82.9873, 33.7807],
            [-82.949, 33.7333],
            [-82.9956, 33.6936],
            [-83.1187, 33.6988],
            [-83.2803, 33.7618],
            [-83.2759, 33.848],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1303990',
        NAME: 'Oglethorpe County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1137034040,
        AWATER: 7839366,
        INTPTLAT: '+33.8668062',
        INTPTLON: '-083.0740805',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9227, 34.0825],
            [-84.8069, 34.0825],
            [-84.7725, 34.0824],
            [-84.7378, 34.0794],
            [-84.7241, 33.8062],
            [-84.9017, 33.7807],
            [-85.0379, 33.8119],
            [-85.0503, 33.9045],
            [-84.9787, 33.9514],
            [-84.9227, 34.0825],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304020',
        NAME: 'Paulding County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 808832044,
        AWATER: 5309331,
        INTPTLAT: '+33.9210260',
        INTPTLON: '-084.8671616',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0085, 32.5218],
            [-84.0034, 32.5299],
            [-83.767, 32.6925],
            [-83.7011, 32.6916],
            [-83.7199, 32.5318],
            [-83.8467, 32.4685],
            [-84.0181, 32.5064],
            [-84.0085, 32.5218],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304050',
        NAME: 'Peach County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389190275,
        AWATER: 2676569,
        INTPTLAT: '+32.5713241',
        INTPTLON: '-083.8319779',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1709, 31.1245],
            [-84.1613, 31.1406],
            [-84.1336, 31.1343],
            [-84.1395, 31.114],
            [-84.1709, 31.1245],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304080',
        NAME: 'Pelham City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10524378,
        AWATER: 36317,
        INTPTLAT: '+31.1265679',
        INTPTLON: '-084.1515448',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6544, 34.5489],
            [-84.3714, 34.5485],
            [-84.3456, 34.5627],
            [-84.3197, 34.4679],
            [-84.2576, 34.381],
            [-84.5826, 34.3815],
            [-84.6532, 34.4126],
            [-84.6544, 34.5489],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304110',
        NAME: 'Pickens County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601027938,
        AWATER: 1693768,
        INTPTLAT: '+34.4568125',
        INTPTLON: '-084.4903390',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4172, 31.4171],
            [-82.3448, 31.4301],
            [-82.2266, 31.5307],
            [-82.1328, 31.4713],
            [-82.0549, 31.4555],
            [-82.0411, 31.3737],
            [-82.0081, 31.2797],
            [-82.0927, 31.2761],
            [-82.1948, 31.2076],
            [-82.2846, 31.2244],
            [-82.3837, 31.2911],
            [-82.4172, 31.4171],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304140',
        NAME: 'Pierce County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 881051116,
        AWATER: 7910038,
        INTPTLAT: '+31.3539879',
        INTPTLON: '-082.2104267',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4968, 33.1839],
            [-84.4513, 33.2089],
            [-84.2482, 33.189],
            [-84.2701, 32.991],
            [-84.2987, 32.9997],
            [-84.4897, 32.9937],
            [-84.527, 32.9705],
            [-84.5287, 33.098],
            [-84.4968, 33.1839],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304170',
        NAME: 'Pike County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559659551,
        AWATER: 8596853,
        INTPTLAT: '+33.0907685',
        INTPTLON: '-084.3866265',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4219, 34.0808],
            [-85.2576, 34.1007],
            [-85.0469, 34.0964],
            [-84.9227, 34.0825],
            [-84.9787, 33.9514],
            [-85.0503, 33.9045],
            [-85.3866, 33.9017],
            [-85.3988, 33.9641],
            [-85.4219, 34.0808],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304200',
        NAME: 'Polk County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 803772833,
        AWATER: 4667496,
        INTPTLAT: '+33.9960153',
        INTPTLON: '-085.1883384',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6156, 32.2886],
            [-83.498, 32.4017],
            [-83.3465, 32.2725],
            [-83.2911, 32.1788],
            [-83.3373, 32.1059],
            [-83.6085, 32.1184],
            [-83.6156, 32.2886],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304220',
        NAME: 'Pulaski County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645061948,
        AWATER: 5560186,
        INTPTLAT: '+32.2387936',
        INTPTLON: '-083.4818548',
        ELSDLEA: '',
        UNSDLEA: '04220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5337, 33.4345],
            [-83.2799, 33.4834],
            [-83.1642, 33.355],
            [-83.1458, 33.3121],
            [-83.2535, 33.2593],
            [-83.2741, 33.1872],
            [-83.4291, 33.1854],
            [-83.5459, 33.1719],
            [-83.5337, 33.4345],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304260',
        NAME: 'Putnam County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 892634162,
        AWATER: 41539480,
        INTPTLAT: '+33.3210605',
        INTPTLON: '-083.3717898',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1419, 31.7822],
            [-85.1325, 31.8004],
            [-85.1316, 31.8185],
            [-85.1407, 31.8575],
            [-85.0682, 31.9919],
            [-84.907, 31.9245],
            [-84.9103, 31.7768],
            [-84.9586, 31.7779],
            [-85.1411, 31.7805],
            [-85.1419, 31.7822],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304290',
        NAME: 'Quitman County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391703078,
        AWATER: 24158294,
        INTPTLAT: '+31.8629478',
        INTPTLON: '-085.0048013',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5494, 34.9925],
            [-83.4828, 34.9933],
            [-83.1086, 35.0007],
            [-83.1274, 34.9502],
            [-83.3242, 34.7875],
            [-83.3524, 34.7161],
            [-83.549, 34.7495],
            [-83.6522, 34.823],
            [-83.6548, 34.8769],
            [-83.5494, 34.9925],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304320',
        NAME: 'Rabun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 958280735,
        AWATER: 17841177,
        INTPTLAT: '+34.8839494',
        INTPTLON: '-083.4048594',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9586, 31.7779],
            [-84.9103, 31.7768],
            [-84.907, 31.9245],
            [-84.6558, 31.9203],
            [-84.5998, 31.9202],
            [-84.6031, 31.772],
            [-84.5355, 31.6818],
            [-84.5469, 31.6212],
            [-84.8178, 31.6198],
            [-84.9424, 31.6187],
            [-84.9586, 31.7779],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304350',
        NAME: 'Randolph County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1109126239,
        AWATER: 6830041,
        INTPTLAT: '+31.7626510',
        INTPTLON: '-084.7523108',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3535, 33.3123],
            [-82.2942, 33.3546],
            [-82.0282, 33.5449],
            [-82.0124, 33.5321],
            [-81.9341, 33.4683],
            [-81.9183, 33.3328],
            [-81.8521, 33.2473],
            [-82.0926, 33.2301],
            [-82.1742, 33.2968],
            [-82.2676, 33.2674],
            [-82.3535, 33.3123],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304380',
        NAME: 'Richmond County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 840008393,
        AWATER: 11042219,
        INTPTLAT: '+33.3614870',
        INTPTLON: '-082.0749983',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1841, 33.6462],
            [-84.1101, 33.6251],
            [-84.0237, 33.7528],
            [-83.982, 33.7861],
            [-83.9148, 33.7442],
            [-83.9309, 33.6518],
            [-84.0445, 33.5258],
            [-84.1287, 33.5629],
            [-84.1841, 33.6462],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304410',
        NAME: 'Rockdale County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336205278,
        AWATER: 5926396,
        INTPTLAT: '+33.6520811',
        INTPTLON: '-084.0263696',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1311, 34.2131],
            [-85.1879, 34.2055],
            [-85.2115, 34.2633],
            [-85.1284, 34.288],
            [-85.1311, 34.2131],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304440',
        NAME: 'Rome City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82277236,
        AWATER: 1888959,
        INTPTLAT: '+34.2661329',
        INTPTLON: '-085.1860521',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3923, 32.414],
            [-84.3632, 32.3976],
            [-84.2546, 32.3721],
            [-84.2559, 32.2962],
            [-84.1817, 32.2297],
            [-84.1836, 32.1585],
            [-84.4302, 32.1663],
            [-84.3899, 32.2972],
            [-84.3923, 32.414],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304470',
        NAME: 'Schley County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432284457,
        AWATER: 2376387,
        INTPTLAT: '+32.2634410',
        INTPTLON: '-084.3227238',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7676, 32.9094],
            [-81.5418, 33.0457],
            [-81.5027, 32.9387],
            [-81.4265, 32.8408],
            [-81.4051, 32.745],
            [-81.4275, 32.7022],
            [-81.3892, 32.5954],
            [-81.548, 32.4893],
            [-81.6879, 32.5461],
            [-81.841, 32.6491],
            [-81.8679, 32.6811],
            [-81.7676, 32.9094],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304500',
        NAME: 'Screven County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1670803486,
        AWATER: 27888992,
        INTPTLAT: '+32.7447512',
        INTPTLON: '-081.6175849',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0286, 31.0755],
            [-84.9224, 31.0726],
            [-84.7309, 31.0692],
            [-84.7559, 30.8853],
            [-84.8635, 30.7115],
            [-84.8647, 30.7115],
            [-84.9111, 30.7512],
            [-84.9425, 30.8885],
            [-85.0025, 31.0007],
            [-85.0286, 31.0755],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304530',
        NAME: 'Seminole County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 609245340,
        AWATER: 55231605,
        INTPTLAT: '+30.9338940',
        INTPTLON: '-084.8675915',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6691, 33.6119],
            [-83.6745, 33.605],
            [-83.6989, 33.6091],
            [-83.6919, 33.6167],
            [-83.7085, 33.6283],
            [-83.7549, 33.6464],
            [-83.6953, 33.6794],
            [-83.6691, 33.6119],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304540',
        NAME: 'Social Circle City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35630197,
        AWATER: 327543,
        INTPTLAT: '+33.6499151',
        INTPTLON: '-083.7083164',
        ELSDLEA: '',
        UNSDLEA: '04540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3382, 34.6872],
            [-83.1037, 34.5366],
            [-83.1778, 34.477],
            [-83.3984, 34.4609],
            [-83.4598, 34.4811],
            [-83.365, 34.6753],
            [-83.3382, 34.6872],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304560',
        NAME: 'Stephens County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463948062,
        AWATER: 13121983,
        INTPTLAT: '+34.5529139',
        INTPTLON: '-083.2902156',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0542, 32.0267],
            [-85.056, 32.0631],
            [-85.0621, 32.1325],
            [-84.923, 32.2309],
            [-84.6589, 32.2329],
            [-84.6493, 32.233],
            [-84.6558, 31.9203],
            [-84.907, 31.9245],
            [-85.0682, 31.9919],
            [-85.0542, 32.0267],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304590',
        NAME: 'Stewart County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1188153047,
        AWATER: 12599265,
        INTPTLAT: '+32.0732250',
        INTPTLON: '-084.8349117',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4312, 32.1341],
            [-84.4302, 32.1663],
            [-84.1836, 32.1585],
            [-84.1817, 32.2297],
            [-84.0279, 32.1711],
            [-83.9613, 32.0306],
            [-83.9225, 31.9097],
            [-84.3381, 31.9162],
            [-84.3382, 31.8736],
            [-84.4438, 31.9675],
            [-84.4312, 32.1341],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304620',
        NAME: 'Sumter County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1250175714,
        AWATER: 25791716,
        INTPTLAT: '+32.0421975',
        INTPTLON: '-084.2042602',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7005, 32.8446],
            [-84.5707, 32.8452],
            [-84.5069, 32.8818],
            [-84.391, 32.787],
            [-84.2862, 32.7476],
            [-84.3379, 32.7206],
            [-84.4444, 32.5621],
            [-84.6373, 32.5349],
            [-84.6945, 32.5187],
            [-84.6795, 32.5358],
            [-84.6946, 32.5839],
            [-84.7005, 32.8446],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304650',
        NAME: 'Talbot County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1013691982,
        AWATER: 8737088,
        INTPTLAT: '+32.7046026',
        INTPTLON: '-084.5300288',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9956, 33.6936],
            [-82.949, 33.7333],
            [-82.8906, 33.6329],
            [-82.813, 33.6558],
            [-82.68, 33.5998],
            [-82.7473, 33.5117],
            [-82.852, 33.4435],
            [-83.0129, 33.4692],
            [-83.014, 33.5804],
            [-82.9511, 33.658],
            [-82.9956, 33.6936],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304680',
        NAME: 'Taliaferro County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504031039,
        AWATER: 1915743,
        INTPTLAT: '+33.5593190',
        INTPTLON: '-082.8752721',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2328, 32.3188],
            [-82.0253, 32.2789],
            [-81.98, 32.0815],
            [-81.8575, 32.0498],
            [-81.882, 31.9241],
            [-81.9691, 31.7893],
            [-82.0486, 31.8271],
            [-82.1078, 31.8837],
            [-82.225, 31.9131],
            [-82.1827, 32.1604],
            [-82.2328, 32.3188],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304770',
        NAME: 'Tattnall County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1215313220,
        AWATER: 22656415,
        INTPTLAT: '+32.0521878',
        INTPTLON: '-082.0659633',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.0533, 32.522],
              [-84.0085, 32.5218],
              [-84.0181, 32.5064],
              [-84.0533, 32.522],
            ],
          ],
          [
            [
              [-84.4444, 32.5621],
              [-84.3379, 32.7206],
              [-84.2862, 32.7476],
              [-84.2026, 32.69],
              [-84.105, 32.6734],
              [-84.0034, 32.5299],
              [-84.0533, 32.522],
              [-84.1223, 32.5059],
              [-84.1979, 32.4009],
              [-84.2546, 32.3721],
              [-84.3632, 32.3976],
              [-84.3923, 32.414],
              [-84.4444, 32.5621],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304800',
        NAME: 'Taylor County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 975611442,
        AWATER: 7803733,
        INTPTLAT: '+32.5546668',
        INTPTLON: '-084.2514261',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2057, 31.9003],
            [-82.9279, 32.1353],
            [-82.8877, 32.0799],
            [-82.7288, 32.0067],
            [-82.6457, 31.9189],
            [-82.8364, 31.8162],
            [-82.8736, 31.7819],
            [-82.9957, 31.781],
            [-83.1775, 31.8479],
            [-83.2057, 31.9003],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304830',
        NAME: 'Telfair County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1132610582,
        AWATER: 17366926,
        INTPTLAT: '+31.9136387',
        INTPTLON: '-082.9310624',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5998, 31.9202],
            [-84.4533, 31.9192],
            [-84.4438, 31.9675],
            [-84.3382, 31.8736],
            [-84.2623, 31.8285],
            [-84.3052, 31.6911],
            [-84.2978, 31.622],
            [-84.4504, 31.6219],
            [-84.5469, 31.6212],
            [-84.5355, 31.6818],
            [-84.6031, 31.772],
            [-84.5998, 31.9202],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304860',
        NAME: 'Terrell County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 868785319,
        AWATER: 5861795,
        INTPTLAT: '+31.7771909',
        INTPTLON: '-084.4394464',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1166, 31.078],
            [-84.0036, 31.0773],
            [-84.0039, 31.0416],
            [-83.7362, 31.0377],
            [-83.7437, 30.6584],
            [-84.0075, 30.6721],
            [-84.0838, 30.6758],
            [-84.0771, 30.915],
            [-84.1192, 30.9835],
            [-84.1166, 31.078],
          ],
          [
            [-83.9481, 30.8598],
            [-84.007, 30.8661],
            [-83.9978, 30.8134],
            [-83.9481, 30.816],
            [-83.9481, 30.8598],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304890',
        NAME: 'Thomas County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1371629054,
        AWATER: 19385735,
        INTPTLAT: '+30.8646064',
        INTPTLON: '-083.9198196',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9481, 30.8598],
            [-83.9481, 30.816],
            [-83.9978, 30.8134],
            [-84.007, 30.8661],
            [-83.9481, 30.8598],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304950',
        NAME: 'Thomasville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38881083,
        AWATER: 342461,
        INTPTLAT: '+30.8394221',
        INTPTLON: '-083.9787807',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6494, 31.568],
            [-83.5007, 31.594],
            [-83.3387, 31.476],
            [-83.4345, 31.3504],
            [-83.5126, 31.3274],
            [-83.6541, 31.3307],
            [-83.6494, 31.568],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1304980',
        NAME: 'Tift County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 675661214,
        AWATER: 20499404,
        INTPTLAT: '+31.4570027',
        INTPTLON: '-083.5259307',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4437, 32.2035],
            [-82.3797, 32.1773],
            [-82.3489, 32.2095],
            [-82.4406, 32.2416],
            [-82.4091, 32.3537],
            [-82.4091, 32.3538],
            [-82.3545, 32.2922],
            [-82.2328, 32.3188],
            [-82.1827, 32.1604],
            [-82.225, 31.9131],
            [-82.4315, 31.9662],
            [-82.4831, 31.969],
            [-82.4437, 32.2035],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305040',
        NAME: 'Toombs County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 899824252,
        AWATER: 17217517,
        INTPTLAT: '+32.1169265',
        INTPTLON: '-082.3309671',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9364, 34.9875],
            [-83.5494, 34.9925],
            [-83.6548, 34.8769],
            [-83.6522, 34.823],
            [-83.6816, 34.8006],
            [-83.7804, 34.7929],
            [-83.8126, 34.9092],
            [-83.9151, 34.9269],
            [-83.9364, 34.9875],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305070',
        NAME: 'Towns County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 431395520,
        AWATER: 14037933,
        INTPTLAT: '+34.9025294',
        INTPTLON: '-083.7322645',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6477, 32.5125],
            [-82.5, 32.4984],
            [-82.3861, 32.4404],
            [-82.4091, 32.3538],
            [-82.4091, 32.3537],
            [-82.6555, 32.2976],
            [-82.722, 32.3093],
            [-82.6856, 32.4605],
            [-82.6477, 32.5125],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305100',
        NAME: 'Treutlen County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516536217,
        AWATER: 7805621,
        INTPTLAT: '+32.4095857',
        INTPTLON: '-082.5708819',
        ELSDLEA: '',
        UNSDLEA: '05100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3256, 34.5496],
            [-85.2979, 34.5644],
            [-85.3009, 34.5304],
            [-85.3256, 34.5496],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305130',
        NAME: 'Trion City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9931012,
        AWATER: 31691,
        INTPTLAT: '+34.5484182',
        INTPTLON: '-085.3107687',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6123, 31.8541],
            [-83.4802, 31.8473],
            [-83.4536, 31.7579],
            [-83.5007, 31.594],
            [-83.6494, 31.568],
            [-83.7188, 31.622],
            [-83.7968, 31.6229],
            [-83.8027, 31.8036],
            [-83.6126, 31.8041],
            [-83.6123, 31.8541],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305190',
        NAME: 'Turner County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 739164629,
        AWATER: 11881239,
        INTPTLAT: '+31.7248005',
        INTPTLON: '-083.6203195',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5135, 32.8449],
            [-83.407, 32.8982],
            [-83.2265, 32.5842],
            [-83.4979, 32.4522],
            [-83.5631, 32.6598],
            [-83.5977, 32.6643],
            [-83.5135, 32.8449],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305220',
        NAME: 'Twiggs County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 928284540,
        AWATER: 10933791,
        INTPTLAT: '+32.6658469',
        INTPTLON: '-083.4258792',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1294, 34.9875],
            [-84.0054, 34.9872],
            [-83.9364, 34.9875],
            [-83.9151, 34.9269],
            [-83.8126, 34.9092],
            [-83.7804, 34.7929],
            [-83.8565, 34.7222],
            [-83.939, 34.7409],
            [-84.0363, 34.6421],
            [-84.158, 34.6482],
            [-84.1036, 34.7278],
            [-84.1294, 34.9875],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305250',
        NAME: 'Union County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 833844747,
        AWATER: 18273554,
        INTPTLAT: '+34.8333330',
        INTPTLON: '-083.9892568',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.527, 32.9705],
            [-84.4897, 32.9937],
            [-84.2987, 32.9997],
            [-84.2701, 32.991],
            [-84.1222, 32.9887],
            [-84.1233, 32.9322],
            [-84.1243, 32.8496],
            [-84.2026, 32.69],
            [-84.2862, 32.7476],
            [-84.391, 32.787],
            [-84.5069, 32.8818],
            [-84.527, 32.9705],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305280',
        NAME: 'Upson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 837696991,
        AWATER: 10663279,
        INTPTLAT: '+32.8818366',
        INTPTLON: '-084.2922810',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3529, 30.8413],
            [-83.2845, 30.883],
            [-83.2494, 30.8024],
            [-83.3026, 30.7932],
            [-83.3529, 30.8413],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305310',
        NAME: 'Valdosta City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92873485,
        AWATER: 1348175,
        INTPTLAT: '+30.8501261',
        INTPTLON: '-083.2788329',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4406, 32.2416],
            [-82.3489, 32.2095],
            [-82.3797, 32.1773],
            [-82.4437, 32.2035],
            [-82.4436, 32.2042],
            [-82.443, 32.2075],
            [-82.4406, 32.2416],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305340',
        NAME: 'Vidalia City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44963855,
        AWATER: 911683,
        INTPTLAT: '+32.2139013',
        INTPTLON: '-082.4030198',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5341, 34.6239],
            [-85.4504, 34.7592],
            [-85.4506, 34.8317],
            [-85.3639, 34.9834],
            [-85.2651, 34.9851],
            [-85.2643, 34.8911],
            [-85.3006, 34.8825],
            [-85.3004, 34.8608],
            [-85.2642, 34.8753],
            [-85.2022, 34.8545],
            [-85.1448, 34.7676],
            [-85.0508, 34.72],
            [-85.0504, 34.6225],
            [-85.0692, 34.5872],
            [-85.1077, 34.5875],
            [-85.5273, 34.5887],
            [-85.5341, 34.6239],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305370',
        NAME: 'Walker County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1149523114,
        AWATER: 1639686,
        INTPTLAT: '+34.7358269',
        INTPTLON: '-085.3053852',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.6989, 33.6091],
              [-83.6745, 33.605],
              [-83.6809, 33.5969],
              [-83.6989, 33.6091],
            ],
          ],
          [
            [
              [-83.7991, 33.9298],
              [-83.647, 33.9062],
              [-83.5059, 33.8178],
              [-83.6691, 33.6119],
              [-83.6953, 33.6794],
              [-83.7549, 33.6464],
              [-83.9148, 33.7442],
              [-83.982, 33.7861],
              [-83.7991, 33.9298],
            ],
            [
              [-83.6919, 33.6167],
              [-83.6917, 33.6171],
              [-83.7004, 33.6228],
              [-83.7019, 33.6238],
              [-83.7055, 33.6263],
              [-83.7085, 33.6283],
              [-83.6919, 33.6167],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305390',
        NAME: 'Walton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 809110780,
        AWATER: 10842587,
        INTPTLAT: '+33.7866896',
        INTPTLON: '-083.7334430',
        ELSDLEA: '',
        UNSDLEA: '05390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.629, 31.3639],
            [-82.5975, 31.4693],
            [-82.4947, 31.4687],
            [-82.4172, 31.4171],
            [-82.3837, 31.2911],
            [-82.2846, 31.2244],
            [-82.2087, 31.1709],
            [-82.2083, 31.0848],
            [-82.1317, 31.0107],
            [-82.4156, 31.0136],
            [-82.4204, 30.7952],
            [-82.1499, 30.7843],
            [-82.2147, 30.5686],
            [-82.4189, 30.5818],
            [-82.4359, 30.8201],
            [-82.4922, 30.8326],
            [-82.4906, 30.9632],
            [-82.5921, 31.0185],
            [-82.6717, 31.1837],
            [-82.6296, 31.2747],
            [-82.629, 31.3639],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305430',
        NAME: 'Ware County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2312574726,
        AWATER: 39103677,
        INTPTLAT: '+31.0508810',
        INTPTLON: '-082.4215072',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.68, 33.5998],
            [-82.6497, 33.6088],
            [-82.5358, 33.3575],
            [-82.3838, 33.3121],
            [-82.432, 33.2748],
            [-82.5598, 33.3273],
            [-82.7558, 33.2534],
            [-82.852, 33.4435],
            [-82.7473, 33.5117],
            [-82.68, 33.5998],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305460',
        NAME: 'Warren County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 736332037,
        AWATER: 6186048,
        INTPTLAT: '+33.4191691',
        INTPTLON: '-082.6880123',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0522, 33.0807],
            [-82.8889, 33.1386],
            [-82.855, 33.1964],
            [-82.7483, 33.2383],
            [-82.7395, 33.1744],
            [-82.6619, 33.1263],
            [-82.5479, 33.0085],
            [-82.5109, 32.9178],
            [-82.5211, 32.8224],
            [-82.7684, 32.7691],
            [-82.8025, 32.8098],
            [-82.947, 32.7594],
            [-83.0736, 32.9466],
            [-83.0522, 33.0807],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305490',
        NAME: 'Washington County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1757188472,
        AWATER: 15284511,
        INTPTLAT: '+32.9718477',
        INTPTLON: '-082.7981123',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0486, 31.8271],
            [-81.9691, 31.7893],
            [-81.8239, 31.6518],
            [-81.6632, 31.5387],
            [-81.6243, 31.453],
            [-81.7317, 31.33],
            [-81.8011, 31.3637],
            [-81.9232, 31.3459],
            [-82.0411, 31.3737],
            [-82.0549, 31.4555],
            [-82.1328, 31.4713],
            [-82.133, 31.7734],
            [-82.0486, 31.8271],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305550',
        NAME: 'Wayne County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1662237980,
        AWATER: 18136147,
        INTPTLAT: '+31.5478449',
        INTPTLON: '-081.9123763',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6493, 32.233],
            [-84.5271, 32.1346],
            [-84.4312, 32.1341],
            [-84.4438, 31.9675],
            [-84.4533, 31.9192],
            [-84.5998, 31.9202],
            [-84.6558, 31.9203],
            [-84.6493, 32.233],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305580',
        NAME: 'Webster County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 541613277,
        AWATER: 2965865,
        INTPTLAT: '+32.0466911',
        INTPTLON: '-084.5538279',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9279, 32.1353],
            [-82.8848, 32.1961],
            [-82.722, 32.3093],
            [-82.6555, 32.2976],
            [-82.5977, 32.014],
            [-82.5437, 31.9589],
            [-82.6457, 31.9189],
            [-82.7288, 32.0067],
            [-82.8877, 32.0799],
            [-82.9279, 32.1353],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305640',
        NAME: 'Wheeler County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765301200,
        AWATER: 12390212,
        INTPTLAT: '+32.1052681',
        INTPTLON: '-082.7338646',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8565, 34.7222],
            [-83.7804, 34.7929],
            [-83.6816, 34.8006],
            [-83.6233, 34.7322],
            [-83.6468, 34.6689],
            [-83.6323, 34.5286],
            [-83.6664, 34.5036],
            [-83.8434, 34.5055],
            [-83.8644, 34.5506],
            [-83.8565, 34.7222],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305670',
        NAME: 'White County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623402120,
        AWATER: 3912787,
        INTPTLAT: '+34.6437760',
        INTPTLON: '-083.7434165',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1448, 34.7676],
            [-85.0616, 34.8195],
            [-84.9799, 34.9876],
            [-84.977, 34.9877],
            [-84.8105, 34.9876],
            [-84.8187, 34.877],
            [-84.8785, 34.7796],
            [-84.8679, 34.7295],
            [-84.8652, 34.728],
            [-84.9135, 34.6341],
            [-85.0504, 34.6225],
            [-85.0508, 34.72],
            [-85.1448, 34.7676],
          ],
          [
            [-84.9991, 34.7434],
            [-84.9977, 34.7457],
            [-84.9873, 34.7379],
            [-84.961, 34.7107],
            [-84.9267, 34.7912],
            [-84.9406, 34.8044],
            [-84.9835, 34.7931],
            [-84.9991, 34.7434],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305700',
        NAME: 'Whitfield County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 699869927,
        AWATER: 1483855,
        INTPTLAT: '+34.8079369',
        INTPTLON: '-084.9674026',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6097, 32.0279],
            [-83.6085, 32.1184],
            [-83.3373, 32.1059],
            [-83.2615, 31.9521],
            [-83.2057, 31.9003],
            [-83.1775, 31.8479],
            [-83.4802, 31.8473],
            [-83.6123, 31.8541],
            [-83.6097, 32.0279],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305730',
        NAME: 'Wilcox County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 978250127,
        AWATER: 11444179,
        INTPTLAT: '+31.9627168',
        INTPTLON: '-083.4382617',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7795, 33.9711],
            [-82.6455, 33.9842],
            [-82.5737, 33.7861],
            [-82.4795, 33.6395],
            [-82.6497, 33.6088],
            [-82.68, 33.5998],
            [-82.813, 33.6558],
            [-82.8906, 33.6329],
            [-82.949, 33.7333],
            [-82.9873, 33.7807],
            [-82.8467, 33.9406],
            [-82.7795, 33.9711],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305760',
        NAME: 'Wilkes County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1215973896,
        AWATER: 11924769,
        INTPTLAT: '+33.7790312',
        INTPTLON: '-082.7479211',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3577, 32.9261],
            [-83.1338, 33.0072],
            [-83.0736, 32.9466],
            [-82.947, 32.7594],
            [-82.9571, 32.7083],
            [-83.2265, 32.5842],
            [-83.407, 32.8982],
            [-83.3577, 32.9261],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305790',
        NAME: 'Wilkinson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1158541304,
        AWATER: 12003359,
        INTPTLAT: '+32.8042663',
        INTPTLON: '-083.1755122',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9394, 31.8479],
            [-83.8027, 31.8036],
            [-83.7968, 31.6229],
            [-83.7188, 31.622],
            [-83.6494, 31.568],
            [-83.6541, 31.3307],
            [-83.9994, 31.335],
            [-83.9978, 31.4438],
            [-83.9962, 31.6252],
            [-84.0184, 31.6503],
            [-84.0256, 31.71],
            [-83.9394, 31.8479],
          ],
        ],
      },
      properties: {
        STATEFP: '13',
        SCSDLEA: '',
        GEOID: '1305850',
        NAME: 'Worth County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1478123915,
        AWATER: 10485470,
        INTPTLAT: '+31.5517733',
        INTPTLON: '-083.8499598',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from 'react';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faPollH } from '@fortawesome/free-solid-svg-icons';

import DashboardHeader from '../../Components/DashboardHeader';
import MapChart from '../../Components/Chart/MapChart';
import RankChart from '../../Components/Chart/RankChart';
import GraduationChart from '../../Components/Chart/GraduationChart';
import {
  getDashboard,
  getDashboardMenuOptions,
  getDashboardShareALink,
  getDashboardVisualization,
  postDashboardVisualizations,
  getDashboardShareALinkByShareToken,
} from '../../actions/dashboard.action';
import Demographics from '../../Components/Demographics';
import DashboardLine from '../../Components/DashboardLine';
import ReportModal from '../../Components/ReportModal';
import types from '../../types';
import ReportToast from '../../Components/ReportToast';
import ShareALinkModal from '../../Components/ShareALinkModal';

import './styles.css';
import { COMPONENT_ID_MAP } from '../../constants/charts';
import ExportModal from '../../Components/ExportModal';
import navigationTracker from '../../helpers/analytics/navigationTracker';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [scrollToId, setScrollToId] = useState(0);
  const { dashboard, filters, visualizations, menuOptions } = useSelector(
    (state) => state.dashboard
  );
  const storeJwt = useSelector((state) => state.session.jwt);
  const { search } = useLocation();
  const chartRefMap = dashboard?.components?.reduce(
    (total, curr) => ({
      ...total,
      [curr?.componentId]: createRef(),
    }),
    {}
  );

  useEffect(() => {
    dispatch(getDashboardMenuOptions(storeJwt));
    if (search) {
      const query = new URLSearchParams(search);
      const shareToken = query.get('shareToken');
      dispatch(getDashboardShareALinkByShareToken(shareToken, storeJwt));
    } else {
      dispatch(getDashboardVisualization(storeJwt));
    }
    return () => {
      dispatch({ type: types.reducerTypes.DASHBOARD_CLEAR_ALL });
    };
  }, []);

  useEffect(() => {
    if (
      !Array.isArray(visualizations) &&
      visualizations?.details &&
      !dashboard?.components?.length
    ) {
      dispatch(getDashboard(visualizations, storeJwt));
    }
  }, [visualizations]);

  useEffect(() => {
    if (dashboard?.components?.length && menuOptions?.length) {
      setIsLoading(false);
      const scrollComponent = document.getElementById(scrollToId);
      if (scrollComponent) {
        scrollComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [dashboard, filters, visualizations, menuOptions]);

  const handleShapeChange = (data) => {
    const newVisualization = { ...visualizations };
    newVisualization.details.state =
      data === null ? data : { ...newVisualization.details.state, ...data };
    setIsLoading(true);
    setScrollToId(0);
    dispatch(postDashboardVisualizations(newVisualization, storeJwt));
  };

  const handleHideChart = (chartIndex) => {
    const newVisualization = { ...visualizations };
    newVisualization.details.components[chartIndex].hidden = !newVisualization.details.components[
      chartIndex
    ].hidden;
    setScrollToId(dashboard.components[chartIndex].componentId);
    setIsLoading(true);
    dispatch(postDashboardVisualizations(newVisualization, storeJwt));
  };

  const handleAddToReport = (componentIndex) => {
    const reportItem = {
      visualizationDetails: visualizations?.details,
      visualizationId: visualizations?.id,
      contextType: 'DASHBOARD_ELEMENT',
      contextIds: [dashboard.components[componentIndex].componentId],
    };
    dispatch({ type: types.reducerTypes.REPORT_MODAL_SHOW, payload: reportItem });
    // navigationTracker.onAddToReport()
  };

  const handleUpdateDataSet = (newVisualizationComponent) => {
    const newVisualization = {
      ...visualizations,
      details: {
        ...visualizations.details,
        components: visualizations.details.components.map((c) =>
          c.id === newVisualizationComponent.id ? newVisualizationComponent : c
        ),
      },
    };
    setScrollToId(newVisualizationComponent.id);
    setIsLoading(true);
    dispatch(postDashboardVisualizations(newVisualization, storeJwt));
    dispatch(getDashboard(newVisualization, storeJwt));
  };

  const handleGlobalFilterChange = (newFilter) => {
    const newVisualization = { ...visualizations };
    newVisualization.details.globalFilter = newFilter;
    setScrollToId(0);
    setIsLoading(true);
    dispatch(getDashboard(newVisualization, storeJwt));
    dispatch(postDashboardVisualizations(newVisualization, storeJwt));
  };

  const handleShareALink = () => {
    dispatch(getDashboardShareALink(visualizations?.id, storeJwt));
  };

  const getSvg = (contextId) => chartRefMap[contextId].current.chart.getSVG();

  const getMapRankSvg = (item, type) => {
    if (type === 'map') {
      return document.querySelector(`#${type}-chart-${item.componentId} #map-chart svg`).outerHTML;
    }
    return document.querySelector(`#${type}-chart-${item.componentId} svg`).outerHTML;
  };

  const getDemoSvg = (item, type) => {
    return chartRefMap[`${item.componentId}-${type}`].current.chart.getSVG();
  };

  const getHeaderAndFooter = (item) => {
    let footer = null;
    let header = null;
    try {
      if (item?.notes) {
        const notes = JSON.parse(item.notes);
        footer = notes.footer;
        header = notes.header;
      }
    } catch (e) {
      console.log(e);
    }
    return {
      footer,
      header,
    };
  };

  const mapDashboardItemToExportItem = (item, type) => {
    const { header, footer } = getHeaderAndFooter(item);
    const config = visualizations?.details;
    if (item.componentId === COMPONENT_ID_MAP.ID_MAP) {
      return {
        svg: getMapRankSvg(item, type),
        config,
        contextId: item.componentId,
        dashboardData: item.queryResult,
        header,
        footer,
      };
    }
    if (item.componentId === COMPONENT_ID_MAP.ID_DEMOGRAPHICS) {
      return {
        svg: getDemoSvg(item, type),
        config,
        contextId: item.componentId,
        dashboardData: item.queryResult,
        header,
        footer,
      };
    }
    if (item.componentId === COMPONENT_ID_MAP.ID_POINT_IN_TIME) {
      return {
        svg: type,
        config,
        contextId: item.componentId,
        dashboardData: item.queryResult,
        header,
        footer,
      };
    }
    return {
      svg: getSvg(item.componentId),
      config,
      contextId: item.componentId,
      dashboardData: item.queryResult,
      header,
      footer,
    };
  };

  const handleExportChart = (chartIndex) => {
    const reportItems = [];
    const item = dashboard.components[chartIndex];
    if (item.componentId === COMPONENT_ID_MAP.ID_MAP) {
      reportItems.push(mapDashboardItemToExportItem(item, 'map'));
      reportItems.push(mapDashboardItemToExportItem(item, 'rank'));
    } else if (item.componentId === COMPONENT_ID_MAP.ID_DEMOGRAPHICS) {
      reportItems.push(mapDashboardItemToExportItem(item, 'school'));
      reportItems.push(mapDashboardItemToExportItem(item, 'students'));
    } else if (item.componentId === COMPONENT_ID_MAP.ID_POINT_IN_TIME) {
      const graduationCharts = document.querySelectorAll('#graduation-chart svg');
      graduationCharts.forEach((el) => {
        reportItems.push(mapDashboardItemToExportItem(item, el.outerHTML));
      });
    } else {
      reportItems.push(mapDashboardItemToExportItem(item));
    }

    dispatch({ type: types.reducerTypes.EXPORT_MODAL_SHOW, payload: reportItems });
  };

  const handleOnExport = (exportData) => {
    navigationTracker.onExport(exportData);
  };

  if (isLoading) {
    return (
      <Container fluid>
        <Row>
          <Col className="d-flex min-vh-100 justify-content-center align-items-center">
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (dashboard?.components?.length && dashboard?.components?.length < 5) {
    return (
      <Container fluid>
        <Row>
          <Col className="d-flex min-vh-100 justify-content-center align-items-center">
            Error in dashboard
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="p-0">
      <DashboardHeader
        visualizationDetails={visualizations?.details}
        onGlobalFilterChange={handleGlobalFilterChange}
        onShareALink={handleShareALink}
      />
      <Container className="px-5 pb-5" fluid>
        <Row className="map-demographics">
          <Col className="bg-white p-0 map-rank-container">
            <div id={`map-chart-${dashboard.components[0].componentId}`}>
              <MapChart
                componentConfig={dashboard.components.find((c) => c.componentId === 1)}
                visualizationDetails={visualizations?.details}
                handleSectionClick={handleShapeChange}
                onHideChart={() => handleHideChart(0)}
                onAddToReport={() => handleAddToReport(0)}
                onExportChart={() => handleExportChart(0)}
                menuOptions={menuOptions?.find((mo) => mo.componentId === '1')?.menuOptions}
                handleUpdateDataSet={handleUpdateDataSet}
              />
            </div>
            <div id={`rank-chart-${dashboard.components[0].componentId}`}>
              <RankChart
                componentConfig={dashboard.components.find((c) => c.componentId === 1)}
                visualizationDetails={visualizations?.details}
                handleSectionClick={handleShapeChange}
                handleUpdateDataSet={handleUpdateDataSet}
              />
            </div>
          </Col>
          <Col className="p-0 demographics-container">
            <Container
              fluid
              className="p-0 bg-white h-100"
              id={dashboard.components[3].componentId}
            >
              <Demographics
                componentConfig={dashboard.components[3]}
                visualizationConfig={visualizations?.details?.components.find(
                  (c) => c.id === dashboard.components[3].componentId
                )}
                handleHideChart={() => handleHideChart(3)}
                handleUpdateDataSet={handleUpdateDataSet}
                onAddToReport={() => handleAddToReport(3)}
                visualization={visualizations}
                onExportChart={() => handleExportChart(3)}
                handleRef={(chartRefs) => {
                  const [schoolRef, studentRef] = chartRefs;
                  chartRefMap[`${dashboard.components[3].componentId}-school`] = schoolRef;
                  chartRefMap[`${dashboard.components[3].componentId}-students`] = studentRef;
                }}
              />
            </Container>
          </Col>
        </Row>
        {visualizations?.details?.state !== null && (
          <Row className="mt-5" id="lineCharts">
            <Col md={6} className="p-0 pr-3">
              <Container fluid className="p-0 bg-white h-100" id="2">
                <DashboardLine
                  componentsConfig={dashboard.components[1]}
                  visualizationDetails={visualizations?.details}
                  handleHideChart={() => handleHideChart(1)}
                  menuOptions={menuOptions?.find((mo) => mo.componentId === '2')?.menuOptions}
                  handleUpdateDataSet={handleUpdateDataSet}
                  onAddToReport={() => handleAddToReport(1)}
                  onExportChart={() => handleExportChart(1)}
                  ref={chartRefMap[dashboard.components[1].componentId]}
                />
              </Container>
            </Col>
            <Col md={6} className="pr-0">
              <Container fluid className="h-100 p-0 bg-white" id="5">
                <GraduationChart
                  componentConfig={dashboard.components[4]}
                  visualizationDetails={visualizations?.details}
                  handleHideChart={() => handleHideChart(4)}
                  menuOptions={menuOptions?.find((mo) => mo.componentId === '5')?.menuOptions}
                  handleUpdateDataSet={handleUpdateDataSet}
                  onAddToReport={() => handleAddToReport(4)}
                  onExportChart={() => handleExportChart(4)}
                  ref={chartRefMap[dashboard.components[4].componentId]}
                />
              </Container>
            </Col>
          </Row>
        )}
        <Row className="mt-5" id="graduationChart">
          {visualizations?.details?.state !== null && (
            <Col md={6} className="p-0 pr-3" id="3">
              <DashboardLine
                componentsConfig={dashboard.components[2]}
                visualizationDetails={visualizations?.details}
                handleHideChart={() => handleHideChart(2)}
                menuOptions={menuOptions?.find((mo) => mo.componentId === '3')?.menuOptions}
                handleUpdateDataSet={handleUpdateDataSet}
                onAddToReport={() => handleAddToReport(2)}
                onExportChart={() => handleExportChart(2)}
                ref={chartRefMap[dashboard.components[2].componentId]}
              />
            </Col>
          )}
          <Col md={6} className="pr-0">
            <Row className="h-100">
              <Col md={6} className="d-flex flex-column h-100">
                <Container className="bg-pink text-center p-3 h-100">
                  <Container className="align-items-center bg-white d-flex justify-content-center flex-column h-100">
                    <FontAwesomeIcon icon={faPuzzlePiece} size="4x" />
                    <h2 className="mt-3">Dashboard Methodology</h2>
                    <p className="mt-3">
                      Learn more about the sources, definitions and methodology for all data
                      displayed in Bento.
                    </p>
                  </Container>
                </Container>
                <Container className="bg-pink mt-1 py-3 text-center">
                  <Button
                    href="https://bentoguide.squarespace.com/dashboard-methodology"
                    variant="primary"
                    type="button"
                    className="btn btn-custom dark px-5"
                  >
                    View more
                  </Button>
                </Container>
              </Col>
              <Col md={6} className="d-flex flex-column h-100">
                <Container className="bg-blue-100 text-center p-3 h-100">
                  <Container className="align-items-center bg-white d-flex justify-content-center flex-column h-100">
                    <FontAwesomeIcon icon={faPollH} size="4x" />
                    <h2 className="mt-3">Survey Explorer</h2>
                    <p className="mt-3">
                      Explore, filter, and segment teacher and principal survey results.
                    </p>
                  </Container>
                </Container>
                <Container className="bg-blue-100 mt-1 py-3 text-center">
                  <Button
                    href="/survey-explorer"
                    variant="primary"
                    type="button"
                    className="btn btn-custom dark px-5"
                  >
                    View more
                  </Button>
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>
        <ReportModal />
        <ReportToast />
        <ShareALinkModal />
        <ExportModal onExport={handleOnExport} />
      </Container>
    </Container>
  );
};

export default Dashboard;

export const OK = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4317, 36.757],
            [-95.2786, 36.7427],
            [-95.2248, 36.5978],
            [-95.3283, 36.5613],
            [-95.396, 36.5976],
            [-95.4317, 36.6844],
            [-95.4317, 36.757],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '40001',
        GEOID: '4040001',
        NAME: 'Secondary Coverage Area in White Oak Public Schools (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 298122893,
        AWATER: 416029,
        INTPTLAT: '+36.6748698',
        INTPTLON: '-095.3338848',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2001, 34.6807],
            [-95.0596, 34.6956],
            [-95.0251, 34.6808],
            [-94.9378, 34.6809],
            [-94.9375, 34.5943],
            [-94.9901, 34.5938],
            [-95.1998, 34.5938],
            [-95.2001, 34.6807],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002580',
        NAME: 'Albion Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258644573,
        AWATER: 1457172,
        INTPTLAT: '+34.6451157',
        INTPTLON: '-095.0855976',
        ELSDLEA: '02580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.137, 36.0757],
            [-96.1011, 36.0756],
            [-96.0738, 36.0609],
            [-96.1364, 36.0321],
            [-96.137, 36.0757],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002790',
        NAME: 'Allen-Bowden Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25464417,
        AWATER: 179087,
        INTPTLAT: '+36.0535923',
        INTPTLON: '-096.1100850',
        ELSDLEA: '02790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2645, 36.1794],
            [-96.2429, 36.2019],
            [-96.1661, 36.2164],
            [-96.0913, 36.2162],
            [-96.0908, 36.1729],
            [-96.2683, 36.162],
            [-96.2645, 36.1794],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003010',
        NAME: 'Anderson Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75673237,
        AWATER: 5737096,
        INTPTLAT: '+36.2041881',
        INTPTLON: '-096.1618333',
        ELSDLEA: '03010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1918, 36.4626],
            [-96.0487, 36.5428],
            [-95.9913, 36.467],
            [-96.1742, 36.39],
            [-96.1921, 36.3901],
            [-96.1918, 36.4626],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003450',
        NAME: 'Avant Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183045917,
        AWATER: 1698517,
        INTPTLAT: '+36.4589464',
        INTPTLON: '-096.0977472',
        ELSDLEA: '03450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9285, 35.4791],
            [-97.8217, 35.5329],
            [-97.7691, 35.4715],
            [-97.8218, 35.428],
            [-97.8751, 35.4571],
            [-97.9285, 35.4791],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003540',
        NAME: 'Banner Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104521621,
        AWATER: 273631,
        INTPTLAT: '+35.4897143',
        INTPTLON: '-097.8455165',
        ELSDLEA: '03540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4064, 35.4066],
            [-96.2907, 35.3916],
            [-96.2421, 35.3415],
            [-96.24, 35.3315],
            [-96.2446, 35.2902],
            [-96.4059, 35.2902],
            [-96.4064, 35.4066],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003690',
        NAME: 'Bearden Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183287800,
        AWATER: 3928313,
        INTPTLAT: '+35.3399949',
        INTPTLON: '-096.3504873',
        ELSDLEA: '03690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.63, 35.6673],
            [-94.4826, 35.6961],
            [-94.4629, 35.58],
            [-94.5132, 35.5356],
            [-94.5603, 35.5356],
            [-94.6023, 35.536],
            [-94.6022, 35.5514],
            [-94.6299, 35.6383],
            [-94.63, 35.6673],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003840',
        NAME: 'Belfonte Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194838290,
        AWATER: 901729,
        INTPTLAT: '+35.6342116',
        INTPTLON: '-094.5460947',
        ELSDLEA: '03840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3872, 34.5797],
            [-98.3871, 34.5581],
            [-98.4046, 34.5581],
            [-98.4221, 34.5509],
            [-98.4393, 34.551],
            [-98.3872, 34.5797],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004470',
        NAME: 'Bishop Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18936645,
        AWATER: 0,
        INTPTLAT: '+34.5721410',
        INTPTLON: '-098.4226581',
        ELSDLEA: '04470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3803, 36.9993],
            [-96.3453, 36.9993],
            [-96.0358, 36.999],
            [-96.0008, 36.9989],
            [-96.0007, 36.8687],
            [-96.1158, 36.7816],
            [-96.1874, 36.732],
            [-96.2596, 36.7379],
            [-96.2602, 36.8541],
            [-96.3858, 36.8538],
            [-96.3803, 36.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005160',
        NAME: 'Bowring Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 703792084,
        AWATER: 17222237,
        INTPTLAT: '+36.9042475',
        INTPTLON: '-096.1940858',
        ELSDLEA: '05160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8953, 35.9902],
            [-94.8684, 36.0455],
            [-94.7973, 36.046],
            [-94.7974, 35.9443],
            [-94.7974, 35.9407],
            [-94.7973, 35.9372],
            [-94.8074, 35.8779],
            [-94.8074, 35.8707],
            [-94.8608, 35.8707],
            [-94.9402, 35.9443],
            [-94.8953, 35.9902],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005430',
        NAME: 'Briggs Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164091976,
        AWATER: 1957349,
        INTPTLAT: '+35.9530163',
        INTPTLON: '-094.8505611',
        ELSDLEA: '05430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.84, 35.5591],
            [-94.7542, 35.5805],
            [-94.7012, 35.6384],
            [-94.6477, 35.6385],
            [-94.6479, 35.5515],
            [-94.6716, 35.536],
            [-94.8332, 35.5214],
            [-94.84, 35.5591],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005580',
        NAME: 'Brushy Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119028807,
        AWATER: 1295867,
        INTPTLAT: '+35.5730322',
        INTPTLON: '-094.7222348',
        ELSDLEA: '05580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1252, 34.934],
            [-96.9779, 34.9256],
            [-96.931, 34.8981],
            [-96.9308, 34.8763],
            [-97.0906, 34.8329],
            [-97.1423, 34.8551],
            [-97.1252, 34.934],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006030',
        NAME: 'Byars Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131876806,
        AWATER: 2795297,
        INTPTLAT: '+34.8854606',
        INTPTLON: '-097.0503942',
        ELSDLEA: '06030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0057, 36.5977],
            [-94.9994, 36.6271],
            [-94.9106, 36.627],
            [-94.9541, 36.5249],
            [-95.0057, 36.5977],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008010',
        NAME: 'Cleora Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64986022,
        AWATER: 18585665,
        INTPTLAT: '+36.5899693',
        INTPTLON: '-094.9622072',
        ELSDLEA: '08010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0921, 34.5931],
            [-96.0922, 34.5495],
            [-96.2317, 34.5601],
            [-96.162, 34.6072],
            [-96.0921, 34.5931],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008730',
        NAME: 'Cottonwood Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91488697,
        AWATER: 1218070,
        INTPTLAT: '+34.5848091',
        INTPTLON: '-096.1591884',
        ELSDLEA: '08730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4064, 35.5224],
            [-97.3975, 35.4786],
            [-97.4416, 35.4714],
            [-97.4416, 35.4865],
            [-97.4064, 35.5224],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009150',
        NAME: 'Crutcho Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15412498,
        AWATER: 0,
        INTPTLAT: '+35.4938180',
        INTPTLON: '-097.4164663',
        ELSDLEA: '09150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7013, 35.7687],
            [-94.6304, 35.754],
            [-94.6303, 35.725],
            [-94.7008, 35.7251],
            [-94.7013, 35.7687],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009360',
        NAME: 'Dahlonegah Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30474721,
        AWATER: 194417,
        INTPTLAT: '+35.7361382',
        INTPTLON: '-094.6621762',
        ELSDLEA: '09360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0921, 35.6892],
            [-98.0303, 35.6384],
            [-97.8608, 35.6313],
            [-97.9236, 35.5582],
            [-98.027, 35.5802],
            [-98.0921, 35.6892],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009420',
        NAME: 'Darlington Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156957880,
        AWATER: 1101259,
        INTPTLAT: '+35.6079285',
        INTPTLON: '-097.9631298',
        ELSDLEA: '09420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8003, 33.9392],
            [-94.7741, 33.9391],
            [-94.7144, 33.9279],
            [-94.7219, 33.8665],
            [-94.8003, 33.9392],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009720',
        NAME: 'Denison Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70075503,
        AWATER: 1238960,
        INTPTLAT: '+33.9052443',
        INTPTLON: '-094.7474072',
        ELSDLEA: '09720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7187, 35.9009],
            [-94.6193, 35.9082],
            [-94.5837, 35.901],
            [-94.6035, 35.8556],
            [-94.7187, 35.9009],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010560',
        NAME: 'Peavine Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67580445,
        AWATER: 228153,
        INTPTLAT: '+35.8795837',
        INTPTLON: '-094.6584238',
        ELSDLEA: '10560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8576, 35.0291],
            [-94.7649, 35.0581],
            [-94.8045, 34.9263],
            [-94.998, 34.9308],
            [-94.8576, 35.0291],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011400',
        NAME: 'Fanshawe Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199823745,
        AWATER: 1615056,
        INTPTLAT: '+34.9769748',
        INTPTLON: '-094.8495047',
        ELSDLEA: '11400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3782, 34.5581],
            [-98.3871, 34.5581],
            [-98.3872, 34.5797],
            [-98.3782, 34.5581],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011760',
        NAME: 'Flower Mound Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25680651,
        AWATER: 0,
        INTPTLAT: '+34.5761699',
        INTPTLON: '-098.3581335',
        ELSDLEA: '11760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9476, 33.9827],
            [-94.8432, 33.9536],
            [-94.8521, 33.8955],
            [-94.9308, 33.8521],
            [-94.9744, 33.9031],
            [-94.9476, 33.9827],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011850',
        NAME: 'Forest Grove Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113471179,
        AWATER: 1114620,
        INTPTLAT: '+33.9174348',
        INTPTLON: '-094.9220515',
        ELSDLEA: '11850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8996, 35.0876],
            [-97.8027, 35.095],
            [-97.8024, 35.015],
            [-97.8973, 35.0149],
            [-97.8996, 35.0876],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012210',
        NAME: 'Friend Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79923492,
        AWATER: 60405,
        INTPTLAT: '+35.0511247',
        INTPTLON: '-097.8418544',
        ELSDLEA: '12210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.86, 34.855],
            [-95.8571, 34.913],
            [-95.7453, 34.9058],
            [-95.7186, 34.9058],
            [-95.7184, 34.8985],
            [-95.86, 34.855],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012240',
        NAME: 'Frink-Chambers Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65576872,
        AWATER: 92266,
        INTPTLAT: '+34.8787109',
        INTPTLON: '-095.8085392',
        ELSDLEA: '12240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9393, 34.0197],
            [-94.8958, 34.1279],
            [-94.8435, 34.0988],
            [-94.8609, 34.0189],
            [-94.9475, 33.9967],
            [-94.9393, 34.0197],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012750',
        NAME: 'Glover Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69559472,
        AWATER: 2415000,
        INTPTLAT: '+34.0572762',
        INTPTLON: '-094.8871402',
        ELSDLEA: '12750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.02, 36.0223],
            [-95.0113, 36.0459],
            [-94.9512, 36.0023],
            [-94.9487, 35.9956],
            [-94.9488, 35.9662],
            [-95.0292, 35.9482],
            [-95.0111, 35.9878],
            [-95.0246, 36.0059],
            [-95.0133, 35.9878],
            [-95.0649, 35.9878],
            [-95.02, 36.0223],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013230',
        NAME: 'Grand View Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75853903,
        AWATER: 41920,
        INTPTLAT: '+35.9868609',
        INTPTLON: '-094.9944376',
        ELSDLEA: '13230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1302, 34.3839],
            [-98.0085, 34.4131],
            [-97.9998, 34.2968],
            [-98.0601, 34.2742],
            [-98.0953, 34.2903],
            [-98.0953, 34.3032],
            [-98.1319, 34.3405],
            [-98.1302, 34.3839],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013260',
        NAME: 'Grandview Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109689410,
        AWATER: 7568790,
        INTPTLAT: '+34.3408144',
        INTPTLON: '-098.0603622',
        ELSDLEA: '13260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1671, 34.0709],
            [-97.0877, 34.0709],
            [-97.0371, 33.9959],
            [-97.1608, 33.9546],
            [-97.1671, 34.0709],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013380',
        NAME: 'Greenville Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112802275,
        AWATER: 5113196,
        INTPTLAT: '+34.0122916',
        INTPTLON: '-097.1155838',
        ELSDLEA: '13380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9122, 35.3839],
            [-96.8596, 35.3805],
            [-96.8293, 35.3441],
            [-96.8694, 35.3329],
            [-96.8943, 35.3413],
            [-96.9122, 35.3839],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013500',
        NAME: 'Grove Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 30817189,
        AWATER: 81521,
        INTPTLAT: '+35.3689961',
        INTPTLON: '-096.8680583',
        ELSDLEA: '13500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4586, 35.7695],
            [-96.3872, 35.7264],
            [-96.3943, 35.6392],
            [-96.4679, 35.6391],
            [-96.5209, 35.653],
            [-96.4586, 35.7695],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013600',
        NAME: 'Gypsy Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119114152,
        AWATER: 925266,
        INTPTLAT: '+35.7013306',
        INTPTLON: '-096.4425979',
        ELSDLEA: '13600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9655, 34.9999],
            [-95.9125, 34.97],
            [-95.8598, 34.942],
            [-95.8571, 34.913],
            [-95.86, 34.855],
            [-95.935, 34.8111],
            [-96.0574, 34.8111],
            [-96.0272, 34.9272],
            [-95.9655, 34.9999],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014100',
        NAME: 'Haywood Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245181103,
        AWATER: 912901,
        INTPTLAT: '+34.8926252',
        INTPTLON: '-095.9543910',
        ELSDLEA: '14100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7278, 34.7683],
            [-94.6568, 34.7683],
            [-94.6569, 34.8637],
            [-94.4507, 34.8401],
            [-94.451, 34.8307],
            [-94.4547, 34.724],
            [-94.5706, 34.6814],
            [-94.7276, 34.6812],
            [-94.7278, 34.7676],
            [-94.7278, 34.7683],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014730',
        NAME: 'Hodgen Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359984176,
        AWATER: 3221104,
        INTPTLAT: '+34.7591936',
        INTPTLON: '-094.5662494',
        ELSDLEA: '14730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9475, 33.9967],
            [-94.8609, 34.0189],
            [-94.7744, 33.9972],
            [-94.7741, 33.9391],
            [-94.8003, 33.9392],
            [-94.8432, 33.9536],
            [-94.9476, 33.9827],
            [-94.9475, 33.9967],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014890',
        NAME: 'Holly Creek Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88400835,
        AWATER: 2059894,
        INTPTLAT: '+33.9803060',
        INTPTLON: '-094.8486804',
        ELSDLEA: '14890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6063, 36.2101],
            [-96.5122, 36.1411],
            [-96.6224, 36.1266],
            [-96.6063, 36.2101],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015750',
        NAME: 'Jennings Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67436117,
        AWATER: 0,
        INTPTLAT: '+36.1676015',
        INTPTLON: '-096.5698265',
        ELSDLEA: '15750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.538, 35.1302],
            [-96.4722, 35.1158],
            [-96.4906, 35.0941],
            [-96.5288, 35.0796],
            [-96.538, 35.1302],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015900',
        NAME: 'Justice Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37671563,
        AWATER: 94148,
        INTPTLAT: '+35.1090995',
        INTPTLON: '-096.5273963',
        ELSDLEA: '15900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.5787, 36.3363],
              [-95.4891, 36.2931],
              [-95.5115, 36.2351],
              [-95.5971, 36.2278],
              [-95.5787, 36.3363],
            ],
          ],
          [
            [
              [-95.6214, 36.2245],
              [-95.5996, 36.2278],
              [-95.6007, 36.2196],
              [-95.6214, 36.2245],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015930',
        NAME: 'Justus-Tiawah Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85852150,
        AWATER: 911391,
        INTPTLAT: '+36.2859442',
        INTPTLON: '-095.5524483',
        ELSDLEA: '15930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0056, 36.3647],
            [-94.9892, 36.396],
            [-94.9167, 36.3509],
            [-95.0058, 36.2929],
            [-95.0058, 36.3074],
            [-95.0056, 36.3647],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016320',
        NAME: 'Kenwood Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73388155,
        AWATER: 1659528,
        INTPTLAT: '+36.3441511',
        INTPTLON: '-094.9608347',
        ELSDLEA: '16320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0556, 35.7546],
            [-95.0356, 35.8128],
            [-95.0335, 35.8128],
            [-95.0248, 35.8274],
            [-94.8875, 35.7983],
            [-94.8078, 35.6822],
            [-94.8075, 35.6602],
            [-94.8073, 35.6386],
            [-94.8605, 35.6387],
            [-95.0003, 35.6384],
            [-94.9852, 35.7115],
            [-95.0556, 35.7546],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016440',
        NAME: 'Keys Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259114068,
        AWATER: 23408303,
        INTPTLAT: '+35.7306608',
        INTPTLON: '-094.9418372',
        ELSDLEA: '16440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3034, 36.1876],
            [-96.2645, 36.1794],
            [-96.2683, 36.162],
            [-96.1876, 36.0756],
            [-96.1902, 36.0322],
            [-96.1999, 36.0236],
            [-96.2975, 36.0539],
            [-96.3034, 36.1876],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016470',
        NAME: 'Keystone Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109513254,
        AWATER: 10255898,
        INTPTLAT: '+36.1130344',
        INTPTLON: '-096.2630724',
        ELSDLEA: '16470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2022, 36.7752],
            [-97.2019, 36.7822],
            [-97.2019, 36.7842],
            [-97.1476, 36.8546],
            [-97.0942, 36.8399],
            [-96.8696, 36.8181],
            [-96.9105, 36.7603],
            [-96.9414, 36.7312],
            [-97.0763, 36.7674],
            [-97.2019, 36.7605],
            [-97.2022, 36.7752],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016530',
        NAME: 'Kildare Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239875229,
        AWATER: 17653746,
        INTPTLAT: '+36.8034724',
        INTPTLON: '-097.0223068',
        ELSDLEA: '16530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7361, 34.9637],
            [-95.6545, 34.9633],
            [-95.6568, 34.9582],
            [-95.7073, 34.9059],
            [-95.7186, 34.9058],
            [-95.7453, 34.9058],
            [-95.7361, 34.9637],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016720',
        NAME: 'Krebs Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32963133,
        AWATER: 293856,
        INTPTLAT: '+34.9398851',
        INTPTLON: '-095.7141663',
        ELSDLEA: '16720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0051, 34.3438],
            [-95.9882, 34.3744],
            [-95.9008, 34.374],
            [-95.8993, 34.3885],
            [-95.8833, 34.3885],
            [-95.8664, 34.4173],
            [-95.7792, 34.4176],
            [-95.7792, 34.2727],
            [-95.7794, 34.1567],
            [-95.8362, 34.1568],
            [-95.919, 34.1566],
            [-95.9882, 34.2004],
            [-96.0405, 34.2438],
            [-96.0051, 34.3438],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017040',
        NAME: 'Lane Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520713524,
        AWATER: 3159242,
        INTPTLAT: '+34.2749747',
        INTPTLON: '-095.8967758',
        ELSDLEA: '17040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9768, 36.234],
            [-94.8331, 36.1907],
            [-94.9587, 36.1615],
            [-94.9768, 36.234],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017310',
        NAME: 'Leach Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77855987,
        AWATER: 1677,
        INTPTLAT: '+36.1977010',
        INTPTLON: '-094.9135072',
        ELSDLEA: '17310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5603, 35.5356],
            [-94.5132, 35.5356],
            [-94.5132, 35.5285],
            [-94.4779, 35.4413],
            [-94.5489, 35.4125],
            [-94.5603, 35.5356],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017700',
        NAME: 'Liberty Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 84723177,
        AWATER: 481767,
        INTPTLAT: '+35.4743211',
        INTPTLON: '-094.5357026',
        ELSDLEA: '17700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0839, 35.9739],
            [-96.083, 35.9229],
            [-96.1293, 35.9229],
            [-96.1633, 35.9739],
            [-96.0839, 35.9739],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018240',
        NAME: 'Lone Star Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40701033,
        AWATER: 186647,
        INTPTLAT: '+35.9410243',
        INTPTLON: '-096.1279776',
        ELSDLEA: '18240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0113, 36.0892],
            [-94.8864, 36.1109],
            [-94.8689, 36.0694],
            [-94.8684, 36.0455],
            [-94.8953, 35.9902],
            [-94.9488, 35.9662],
            [-94.9487, 35.9956],
            [-94.9512, 36.0023],
            [-95.0113, 36.0459],
            [-95.0113, 36.0892],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018480',
        NAME: 'Lowrey Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133732497,
        AWATER: 1254385,
        INTPTLAT: '+36.0476993',
        INTPTLON: '-094.9243036',
        ELSDLEA: '18480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8435, 34.0988],
            [-94.7744, 33.9972],
            [-94.8609, 34.0189],
            [-94.8435, 34.0988],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018570',
        NAME: 'Lukfata Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58170451,
        AWATER: 556397,
        INTPTLAT: '+34.0502297',
        INTPTLON: '-094.8184649',
        ELSDLEA: '18570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9335, 34.3327],
            [-96.933, 34.3327],
            [-96.8631, 34.2919],
            [-96.8456, 34.1725],
            [-96.9337, 34.1726],
            [-96.9335, 34.3327],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018870',
        NAME: 'Mannsville Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114133540,
        AWATER: 1539616,
        INTPTLAT: '+34.2450257',
        INTPTLON: '-096.9032977',
        ELSDLEA: '18870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2511, 35.5514],
            [-98.207, 35.5948],
            [-98.1229, 35.5297],
            [-98.0654, 35.5513],
            [-97.9815, 35.5006],
            [-97.9817, 35.479],
            [-98.0347, 35.4351],
            [-98.1277, 35.4344],
            [-98.2278, 35.4682],
            [-98.2511, 35.5514],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018900',
        NAME: 'Maple Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238851606,
        AWATER: 1393865,
        INTPTLAT: '+35.4973683',
        INTPTLON: '-098.1241575',
        ELSDLEA: '18900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8605, 35.6387],
            [-94.8073, 35.6386],
            [-94.7537, 35.6386],
            [-94.7542, 35.5805],
            [-94.84, 35.5591],
            [-94.8605, 35.6387],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018960',
        NAME: 'Marble City Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79667656,
        AWATER: 528648,
        INTPTLAT: '+35.5994755',
        INTPTLON: '-094.8094210',
        ELSDLEA: '18960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8074, 35.8707],
            [-94.8074, 35.8779],
            [-94.7187, 35.9009],
            [-94.6035, 35.8556],
            [-94.6659, 35.8338],
            [-94.7365, 35.8409],
            [-94.8074, 35.8356],
            [-94.8074, 35.8707],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019200',
        NAME: 'Maryetta Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57402315,
        AWATER: 155051,
        INTPTLAT: '+35.8491064',
        INTPTLON: '-094.6545844',
        ELSDLEA: '19200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0645, 36.6813],
            [-96.9954, 36.6881],
            [-96.9953, 36.6371],
            [-97.062, 36.6507],
            [-97.0645, 36.6813],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019500',
        NAME: 'McCord Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37016485,
        AWATER: 1472130,
        INTPTLAT: '+36.6629119',
        INTPTLON: '-097.0261517',
        ELSDLEA: '19500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7321, 35.1384],
            [-97.6705, 35.0805],
            [-97.7322, 34.986],
            [-97.8024, 34.9858],
            [-97.8024, 35.015],
            [-97.8027, 35.095],
            [-97.7321, 35.1384],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019890',
        NAME: 'Middleberg Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133748708,
        AWATER: 919975,
        INTPTLAT: '+35.0697514',
        INTPTLON: '-097.7426490',
        ELSDLEA: '19890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4357, 35.4195],
            [-94.431, 35.3937],
            [-94.4356, 35.3861],
            [-94.4728, 35.3758],
            [-94.4357, 35.4195],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020160',
        NAME: 'Moffett Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14372258,
        AWATER: 1804464,
        INTPTLAT: '+35.3962510',
        INTPTLON: '-094.4524316',
        ELSDLEA: '20160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6112, 35.0074],
            [-94.4443, 35.0287],
            [-94.4475, 34.9419],
            [-94.5578, 34.9419],
            [-94.6112, 35.0074],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020190',
        NAME: 'Monroe Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132015872,
        AWATER: 409518,
        INTPTLAT: '+34.9872891',
        INTPTLON: '-094.5205301',
        ELSDLEA: '20190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7079, 36.175],
            [-94.5678, 36.1909],
            [-94.5627, 36.1619],
            [-94.6988, 36.1485],
            [-94.7082, 36.1617],
            [-94.7079, 36.175],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020430',
        NAME: 'Moseley Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59944952,
        AWATER: 133619,
        INTPTLAT: '+36.1776857',
        INTPTLON: '-094.6480219',
        ELSDLEA: '20430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7066, 34.5068],
            [-95.5708, 34.4326],
            [-95.5702, 34.3309],
            [-95.6746, 34.2726],
            [-95.7792, 34.2727],
            [-95.7792, 34.4176],
            [-95.7794, 34.5066],
            [-95.7066, 34.5068],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020640',
        NAME: 'Moyers Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413393506,
        AWATER: 3011347,
        INTPTLAT: '+34.3868113',
        INTPTLON: '-095.6978771',
        ELSDLEA: '20640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.252, 34.5941],
            [-95.1998, 34.5938],
            [-94.9901, 34.5938],
            [-94.9901, 34.5071],
            [-95.0468, 34.4175],
            [-95.2037, 34.4175],
            [-95.2907, 34.4322],
            [-95.252, 34.5941],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021120',
        NAME: 'Nashoba Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439268035,
        AWATER: 2317912,
        INTPTLAT: '+34.5321281',
        INTPTLON: '-095.1257920',
        ELSDLEA: '21120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9737, 35.4636],
            [-96.8239, 35.4219],
            [-96.798, 35.3766],
            [-96.8596, 35.3805],
            [-96.9122, 35.3839],
            [-96.9474, 35.3983],
            [-96.9651, 35.4055],
            [-96.9737, 35.4636],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021840',
        NAME: 'North Rock Creek Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97255509,
        AWATER: 159158,
        INTPTLAT: '+35.4166704',
        INTPTLON: '-096.8852963',
        ELSDLEA: '21840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8075, 35.6602],
            [-94.7008, 35.7251],
            [-94.6303, 35.725],
            [-94.63, 35.6673],
            [-94.6299, 35.6383],
            [-94.6477, 35.6385],
            [-94.7012, 35.6384],
            [-94.7537, 35.6386],
            [-94.8073, 35.6386],
            [-94.8075, 35.6602],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021870',
        NAME: 'Greasy Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98780397,
        AWATER: 428739,
        INTPTLAT: '+35.6775531',
        INTPTLON: '-094.6919770',
        ELSDLEA: '21870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2428, 35.8421],
            [-95.231, 35.871],
            [-95.1272, 35.8418],
            [-95.2428, 35.8421],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021900',
        NAME: 'Norwood Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77191593,
        AWATER: 1212650,
        INTPTLAT: '+35.8453044',
        INTPTLON: '-095.1932295',
        ELSDLEA: '21900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6604, 36.0002],
            [-96.6211, 35.9415],
            [-96.6779, 35.9421],
            [-96.6604, 36.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022160',
        NAME: 'Oak Grove Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32490479,
        AWATER: 0,
        INTPTLAT: '+35.9687514',
        INTPTLON: '-096.6486521',
        ELSDLEA: '22160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4516, 35.6017],
            [-97.3957, 35.602],
            [-97.3983, 35.5583],
            [-97.4427, 35.5583],
            [-97.4513, 35.5583],
            [-97.4516, 35.6017],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022350',
        NAME: 'Oakdale Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23584653,
        AWATER: 34115,
        INTPTLAT: '+35.5839100',
        INTPTLON: '-097.4224065',
        ELSDLEA: '22350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.448, 36.8057],
            [-101.3789, 36.8632],
            [-101.2777, 36.7205],
            [-101.3844, 36.7183],
            [-101.448, 36.8057],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023070',
        NAME: 'Optima Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153120193,
        AWATER: 57413,
        INTPTLAT: '+36.7834261',
        INTPTLON: '-101.3544623',
        ELSDLEA: '23070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4354, 36.2928],
            [-95.4355, 36.3725],
            [-95.3638, 36.3726],
            [-95.3325, 36.2493],
            [-95.3458, 36.2493],
            [-95.3817, 36.2567],
            [-95.4354, 36.2928],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023190',
        NAME: 'Osage Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86795653,
        AWATER: 10887,
        INTPTLAT: '+36.3151368',
        INTPTLON: '-095.3912759',
        ELSDLEA: '23190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.08, 36.7233],
            [-96.0525, 36.7818],
            [-96.0441, 36.7673],
            [-96.0006, 36.7256],
            [-96.0521, 36.6514],
            [-96.08, 36.7233],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023220',
        NAME: 'Osage Hills Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61045605,
        AWATER: 136535,
        INTPTLAT: '+36.7094331',
        INTPTLON: '-096.0320758',
        ELSDLEA: '23220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2015, 36.9989],
            [-97.1114, 36.999],
            [-97.1476, 36.8546],
            [-97.2019, 36.7842],
            [-97.2019, 36.7822],
            [-97.2022, 36.7752],
            [-97.2556, 36.8691],
            [-97.2465, 36.9126],
            [-97.2015, 36.9989],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023700',
        NAME: 'Peckham Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214835843,
        AWATER: 63979,
        INTPTLAT: '+36.8910326',
        INTPTLON: '-097.1856371',
        ELSDLEA: '23700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.0111, 35.9878],
              [-95.0133, 35.9878],
              [-95.0246, 36.0059],
              [-95.0111, 35.9878],
            ],
          ],
          [
            [
              [-95.0289, 36.1618],
              [-95.0113, 36.0892],
              [-95.0113, 36.0459],
              [-95.02, 36.0223],
              [-95.0649, 35.9878],
              [-95.1363, 36.0164],
              [-95.1718, 36.0312],
              [-95.0915, 36.1616],
              [-95.0289, 36.1618],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023730',
        NAME: 'Peggs Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181090088,
        AWATER: 0,
        INTPTLAT: '+36.0697726',
        INTPTLON: '-095.0796868',
        ELSDLEA: '23730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.102, 35.0288],
            [-98.0312, 35.0435],
            [-97.97, 35.0078],
            [-97.9878, 34.9568],
            [-98.0496, 34.9419],
            [-98.102, 35.0288],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024150',
        NAME: 'Pioneer Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101069659,
        AWATER: 290188,
        INTPTLAT: '+34.9884789',
        INTPTLON: '-098.0295721',
        ELSDLEA: '24150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9086, 35.3146],
            [-96.8943, 35.3413],
            [-96.8694, 35.3329],
            [-96.9086, 35.3146],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024330',
        NAME: 'Pleasant Grove Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 4942855,
        AWATER: 4711,
        INTPTLAT: '+35.3247686',
        INTPTLON: '-096.8844183',
        ELSDLEA: '24330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1876, 36.0756],
            [-96.137, 36.0757],
            [-96.1364, 36.0321],
            [-96.1902, 36.0322],
            [-96.1876, 36.0756],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025080',
        NAME: 'Pretty Water Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22611123,
        AWATER: 1455554,
        INTPTLAT: '+36.0545676',
        INTPTLON: '-096.1590909',
        ELSDLEA: '25080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8281, 34.2903],
            [-96.7058, 34.3177],
            [-96.6975, 34.2194],
            [-96.828, 34.2048],
            [-96.8281, 34.2903],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025530',
        NAME: 'Ravia Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111817056,
        AWATER: 1860548,
        INTPTLAT: '+34.2560858',
        INTPTLON: '-096.7617368',
        ELSDLEA: '25530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9817, 35.479],
            [-97.9815, 35.5006],
            [-97.9236, 35.5582],
            [-97.8608, 35.6313],
            [-97.843, 35.6385],
            [-97.816, 35.5875],
            [-97.8217, 35.5329],
            [-97.9285, 35.4791],
            [-97.9817, 35.479],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026010',
        NAME: 'Riverside Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84058636,
        AWATER: 397144,
        INTPTLAT: '+35.6027441',
        INTPTLON: '-097.8569887',
        ELSDLEA: '26010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3461, 35.3412],
            [-97.344, 35.2979],
            [-97.396, 35.2762],
            [-97.3461, 35.3412],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026100',
        NAME: 'Robin Hill Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41073965,
        AWATER: 2878383,
        INTPTLAT: '+35.2972369',
        INTPTLON: '-097.3611904',
        ELSDLEA: '26100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8074, 35.8356],
            [-94.7365, 35.8409],
            [-94.7367, 35.7688],
            [-94.8074, 35.7692],
            [-94.8074, 35.8356],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026250',
        NAME: 'Rocky Mountain Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50859927,
        AWATER: 42351,
        INTPTLAT: '+35.7904945',
        INTPTLON: '-094.7713298',
        ELSDLEA: '26250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9813, 35.3768],
            [-95.9608, 35.3768],
            [-95.8983, 35.3693],
            [-95.9105, 35.3262],
            [-95.9813, 35.3768],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026580',
        NAME: 'Ryal Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46124620,
        AWATER: 912924,
        INTPTLAT: '+35.3449960',
        INTPTLON: '-095.9433832',
        ELSDLEA: '26580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6604, 36.9993],
            [-95.5224, 36.9993],
            [-95.4076, 36.9993],
            [-95.4319, 36.9023],
            [-95.4979, 36.957],
            [-95.6602, 36.9497],
            [-95.6604, 36.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026640',
        NAME: 'South Coffeyville Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153602011,
        AWATER: 297017,
        INTPTLAT: '+36.9624004',
        INTPTLON: '-095.5182491',
        ELSDLEA: '26640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1363, 36.0164],
            [-95.0649, 35.9878],
            [-95.0133, 35.9878],
            [-95.0111, 35.9878],
            [-95.0292, 35.9482],
            [-95.0646, 35.9442],
            [-95.1363, 36.0164],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027420',
        NAME: 'Shady Grove Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61927801,
        AWATER: 0,
        INTPTLAT: '+35.9697827',
        INTPTLON: '-095.0827319',
        ELSDLEA: '27420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9481, 35.2897],
            [-96.9086, 35.3146],
            [-96.8694, 35.3329],
            [-96.8293, 35.3441],
            [-96.7982, 35.333],
            [-96.8026, 35.3257],
            [-96.8514, 35.2897],
            [-96.86, 35.2824],
            [-96.8776, 35.2826],
            [-96.9481, 35.2897],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027990',
        NAME: 'South Rock Creek Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48561566,
        AWATER: 41118,
        INTPTLAT: '+35.3127368',
        INTPTLON: '-096.8662539',
        ELSDLEA: '27990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1221, 36.3725],
            [-95.0413, 36.4358],
            [-95.0296, 36.4379],
            [-94.9892, 36.396],
            [-95.0056, 36.3647],
            [-95.1126, 36.3362],
            [-95.1424, 36.3508],
            [-95.1221, 36.3725],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028110',
        NAME: 'Spavinaw Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63745864,
        AWATER: 8329977,
        INTPTLAT: '+36.3864848',
        INTPTLON: '-095.0536002',
        ELSDLEA: '28110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8342, 35.4055],
            [-95.823, 35.42],
            [-95.8053, 35.4182],
            [-95.6562, 35.4006],
            [-95.6121, 35.3559],
            [-95.822, 35.3041],
            [-95.8222, 35.3721],
            [-95.8342, 35.4055],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028620',
        NAME: 'Stidham Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136375582,
        AWATER: 25446843,
        INTPTLAT: '+35.3692566',
        INTPTLON: '-095.7290303',
        ELSDLEA: '28620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5952, 36.9951],
            [-101.5553, 36.9953],
            [-101.397, 36.9961],
            [-101.3789, 36.8632],
            [-101.448, 36.8057],
            [-101.5373, 36.8351],
            [-101.601, 36.7917],
            [-101.6189, 36.8488],
            [-101.5952, 36.9951],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028920',
        NAME: 'Straight Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388104929,
        AWATER: 824155,
        INTPTLAT: '+36.8994137',
        INTPTLON: '-101.4916920',
        ELSDLEA: '28920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1565, 34.0988],
            [-95.1559, 33.9366],
            [-95.2258, 33.9621],
            [-95.2261, 34.0699],
            [-95.1565, 34.0988],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029340',
        NAME: 'Swink Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106327314,
        AWATER: 1466895,
        INTPTLAT: '+34.0334110',
        INTPTLON: '-095.1959164',
        ELSDLEA: '29340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7883, 35.0121],
            [-95.8066, 34.9852],
            [-95.8598, 34.942],
            [-95.9125, 34.97],
            [-95.9302, 35.0944],
            [-95.807, 35.0871],
            [-95.7883, 35.0121],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029520',
        NAME: 'Tannehill Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147585034,
        AWATER: 6282887,
        INTPTLAT: '+35.0254458',
        INTPTLON: '-095.8613959',
        ELSDLEA: '29520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8875, 35.7983],
            [-94.8608, 35.8707],
            [-94.8074, 35.8707],
            [-94.8074, 35.8356],
            [-94.8074, 35.7692],
            [-94.8078, 35.6822],
            [-94.8875, 35.7983],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029670',
        NAME: 'Tenkiller Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124474311,
        AWATER: 3644133,
        INTPTLAT: '+35.7767571',
        INTPTLON: '-094.8434290',
        ELSDLEA: '29670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9734, 33.9438],
            [-97.782, 33.9387],
            [-97.7261, 33.9406],
            [-97.8536, 33.8527],
            [-97.9797, 33.8922],
            [-97.9734, 33.9438],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029760',
        NAME: 'Terral Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158574417,
        AWATER: 4755892,
        INTPTLAT: '+33.9106731',
        INTPTLON: '-097.8783046',
        ELSDLEA: '29760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7443, 36.6973],
            [-94.6183, 36.7331],
            [-94.6182, 36.7167],
            [-94.6177, 36.6247],
            [-94.7736, 36.6678],
            [-94.7443, 36.6973],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030290',
        NAME: 'Turkey Ford Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85906710,
        AWATER: 8172286,
        INTPTLAT: '+36.6796380',
        INTPTLON: '-094.6780380',
        ELSDLEA: '30290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5144, 34.7242],
            [-95.4448, 34.6808],
            [-95.2001, 34.6807],
            [-95.1998, 34.5938],
            [-95.252, 34.5941],
            [-95.5146, 34.6665],
            [-95.5144, 34.7242],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030390',
        NAME: 'Tuskahoma Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155284612,
        AWATER: 46069619,
        INTPTLAT: '+34.6501599',
        INTPTLON: '-095.3265393',
        ELSDLEA: '30390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9796, 35.7696],
            [-95.9797, 35.8135],
            [-95.8367, 35.856],
            [-95.82, 35.8559],
            [-95.7662, 35.748],
            [-95.9085, 35.6824],
            [-95.9796, 35.7696],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030450',
        NAME: 'Twin Hills Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244042703,
        AWATER: 146655,
        INTPTLAT: '+35.7715888',
        INTPTLON: '-095.8694303',
        ELSDLEA: '30450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6794, 36.2784],
            [-95.6361, 36.215],
            [-95.6992, 36.1891],
            [-95.7438, 36.2636],
            [-95.6794, 36.2784],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031110',
        NAME: 'Verdigris Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62009776,
        AWATER: 1224926,
        INTPTLAT: '+36.2389932',
        INTPTLON: '-095.6784411',
        ELSDLEA: '31110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5975, 35.6822],
            [-95.5441, 35.7329],
            [-95.491, 35.646],
            [-95.5446, 35.5517],
            [-95.5973, 35.5517],
            [-95.642, 35.6459],
            [-95.5975, 35.6822],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031380',
        NAME: 'Wainwright Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142024729,
        AWATER: 1381877,
        INTPTLAT: '+35.6327081',
        INTPTLON: '-095.5624540',
        ELSDLEA: '31380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4317, 36.757],
            [-95.2786, 36.7427],
            [-95.2248, 36.5978],
            [-95.3283, 36.5613],
            [-95.396, 36.5976],
            [-95.4317, 36.6844],
            [-95.4317, 36.757],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032610',
        NAME: 'White Oak Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298122893,
        AWATER: 416029,
        INTPTLAT: '+36.6748698',
        INTPTLON: '-095.3338848',
        ELSDLEA: '32610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0791, 35.5937],
            [-96.9993, 35.6011],
            [-96.9638, 35.6011],
            [-96.9547, 35.5899],
            [-96.9294, 35.5215],
            [-97.0268, 35.4924],
            [-97.0795, 35.5143],
            [-97.0791, 35.5937],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032640',
        NAME: 'White Rock Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128916795,
        AWATER: 2209389,
        INTPTLAT: '+35.5584578',
        INTPTLON: '-097.0161682',
        ELSDLEA: '32640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3233, 34.7827],
            [-97.2652, 34.7681],
            [-97.3003, 34.6955],
            [-97.3528, 34.732],
            [-97.3233, 34.7827],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032670',
        NAME: 'Whitebead Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75535431,
        AWATER: 836362,
        INTPTLAT: '+34.7458379',
        INTPTLON: '-097.2937647',
        ELSDLEA: '32670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3494, 35.2028],
            [-95.2737, 35.2829],
            [-95.2029, 35.2992],
            [-95.1853, 35.2029],
            [-95.2613, 35.1886],
            [-95.3233, 35.189],
            [-95.3494, 35.2028],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032700',
        NAME: 'Whitefield Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78577265,
        AWATER: 1526879,
        INTPTLAT: '+35.2244763',
        INTPTLON: '-095.2473913',
        ELSDLEA: '32700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1126, 36.3362],
            [-95.0056, 36.3647],
            [-95.0058, 36.3074],
            [-95.1126, 36.3362],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032760',
        NAME: 'Wickliffe Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52677928,
        AWATER: 0,
        INTPTLAT: '+36.3315394',
        INTPTLON: '-095.0518545',
        ELSDLEA: '32760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1277, 35.7763],
            [-95.0961, 35.7997],
            [-95.0961, 35.8004],
            [-95.0779, 35.8417],
            [-95.0736, 35.8563],
            [-95.0248, 35.8274],
            [-95.0335, 35.8128],
            [-95.0356, 35.8128],
            [-95.0556, 35.7546],
            [-95.1277, 35.7688],
            [-95.1277, 35.7763],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033090',
        NAME: 'Woodall Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59199720,
        AWATER: 0,
        INTPTLAT: '+35.8045132',
        INTPTLON: '-095.0674220',
        ELSDLEA: '33090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5626, 34.202],
            [-97.4482, 34.1947],
            [-97.4741, 34.0711],
            [-97.5624, 34.0711],
            [-97.5626, 34.202],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033540',
        NAME: 'Zaneis Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148597830,
        AWATER: 99564,
        INTPTLAT: '+34.1438506',
        INTPTLON: '-097.5010626',
        ELSDLEA: '33540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7365, 35.8409],
            [-94.6659, 35.8338],
            [-94.6304, 35.754],
            [-94.7013, 35.7687],
            [-94.7367, 35.7688],
            [-94.7365, 35.8409],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033600',
        NAME: 'Zion Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71640898,
        AWATER: 557274,
        INTPTLAT: '+35.7889829',
        INTPTLON: '-094.6853285',
        ELSDLEA: '33600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2149, 36.997],
            [-101.0665, 36.9979],
            [-101.0177, 36.9982],
            [-101.0357, 36.8921],
            [-101.0899, 36.8777],
            [-101.1622, 36.8919],
            [-101.2149, 36.997],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030480',
        NAME: 'Tyrone Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172821083,
        AWATER: 646891,
        INTPTLAT: '+36.9418387',
        INTPTLON: '-101.1000533',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0022, 37.0001],
            [-102.8867, 36.9995],
            [-102.3533, 36.9945],
            [-102.356, 36.6972],
            [-102.2837, 36.6466],
            [-102.0321, 36.6461],
            [-102.0323, 36.5006],
            [-102.3826, 36.5005],
            [-102.5707, 36.5004],
            [-102.5704, 36.6557],
            [-102.6418, 36.6192],
            [-102.7515, 36.6468],
            [-102.751, 36.7627],
            [-103.0021, 36.7635],
            [-103.0022, 37.0001],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004860',
        NAME: 'Boise City Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2767740684,
        AWATER: 12002323,
        INTPTLAT: '+36.7589325',
        INTPTLON: '-102.5447007',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0021, 36.7635],
            [-102.751, 36.7627],
            [-102.7515, 36.6468],
            [-102.6418, 36.6192],
            [-102.5704, 36.6557],
            [-102.5707, 36.5004],
            [-103.0024, 36.5004],
            [-103.0021, 36.7635],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011580',
        NAME: 'Felt Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 891738760,
        AWATER: 2100399,
        INTPTLAT: '+36.6225226',
        INTPTLON: '-102.8478172',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0926, 34.5062],
            [-96.0922, 34.5495],
            [-96.0921, 34.5931],
            [-96.092, 34.7675],
            [-96.0574, 34.8111],
            [-95.935, 34.8111],
            [-95.8118, 34.7675],
            [-95.8822, 34.7385],
            [-95.882, 34.5935],
            [-95.829, 34.5936],
            [-95.8818, 34.5064],
            [-96.0926, 34.4913],
            [-96.0926, 34.5062],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016650',
        NAME: 'Kiowa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 647181187,
        AWATER: 15661013,
        INTPTLAT: '+34.6464546',
        INTPTLON: '-095.9815020',
        ELSDLEA: '',
        UNSDLEA: '16650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7275, 35.1913],
            [-95.6157, 35.2497],
            [-95.4513, 35.296],
            [-95.4509, 35.2682],
            [-95.543, 35.1017],
            [-95.6038, 35.1452],
            [-95.7275, 35.1522],
            [-95.7275, 35.1913],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006390',
        NAME: 'Canadian Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198116796,
        AWATER: 65267905,
        INTPTLAT: '+35.1905977',
        INTPTLON: '-095.5821781',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.7073, 34.9059],
              [-95.6568, 34.9582],
              [-95.6569, 34.9129],
              [-95.7073, 34.9059],
            ],
          ],
          [
            [
              [-95.8598, 34.942],
              [-95.8066, 34.9852],
              [-95.7361, 34.9637],
              [-95.7453, 34.9058],
              [-95.8571, 34.913],
              [-95.8598, 34.942],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019440',
        NAME: 'McAlester Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79705607,
        AWATER: 2329988,
        INTPTLAT: '+34.9417023',
        INTPTLON: '-095.7736123',
        ELSDLEA: '',
        UNSDLEA: '19440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.86, 34.855],
            [-95.7184, 34.8985],
            [-95.6716, 34.8548],
            [-95.7243, 34.7823],
            [-95.8118, 34.7675],
            [-95.935, 34.8111],
            [-95.86, 34.855],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027000',
        NAME: 'Savanna Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181784476,
        AWATER: 2442330,
        INTPTLAT: '+34.8278903',
        INTPTLON: '-095.7842176',
        ELSDLEA: '',
        UNSDLEA: '27000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.543, 35.1017],
            [-95.4509, 35.2682],
            [-95.381, 35.2535],
            [-95.3802, 35.2534],
            [-95.3494, 35.2028],
            [-95.3233, 35.189],
            [-95.3492, 35.0585],
            [-95.4545, 35.0293],
            [-95.5521, 35.0339],
            [-95.543, 35.1017],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025410',
        NAME: 'Quinton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392652120,
        AWATER: 755048,
        INTPTLAT: '+35.1323585',
        INTPTLON: '-095.4556991',
        ELSDLEA: '',
        UNSDLEA: '25410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0123, 35.1451],
            [-95.9656, 35.1525],
            [-95.8707, 35.186],
            [-95.7278, 35.1914],
            [-95.7275, 35.1913],
            [-95.7275, 35.1522],
            [-95.7274, 35.058],
            [-95.7883, 35.0121],
            [-95.807, 35.0871],
            [-95.9302, 35.0944],
            [-95.9125, 34.97],
            [-95.9655, 34.9999],
            [-96.0474, 35.1013],
            [-96.0123, 35.1451],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015450',
        NAME: 'Indianola Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334647568,
        AWATER: 12031691,
        INTPTLAT: '+35.0998895',
        INTPTLON: '-095.8689446',
        ELSDLEA: '',
        UNSDLEA: '15450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4562, 36.9995],
            [-99.0003, 36.9995],
            [-98.986, 36.9995],
            [-98.9797, 36.7759],
            [-99.0698, 36.5986],
            [-99.151, 36.6817],
            [-99.2954, 36.6815],
            [-99.2953, 36.6382],
            [-99.3585, 36.6382],
            [-99.3221, 36.8572],
            [-99.373, 36.8786],
            [-99.4562, 36.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012180',
        NAME: 'Freedom Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1285645787,
        AWATER: 6595624,
        INTPTLAT: '+36.8259398',
        INTPTLON: '-099.1768919',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.4505, 36.4995],
              [-100.5221, 36.4993],
              [-100.5228, 36.5872],
              [-100.4418, 36.5872],
              [-100.4505, 36.4995],
            ],
          ],
          [
            [
              [-100.6855, 36.849],
              [-100.2542, 36.8486],
              [-100.2546, 36.7759],
              [-100.1456, 36.7764],
              [-100.2814, 36.7468],
              [-100.3619, 36.6814],
              [-100.6133, 36.6814],
              [-100.7385, 36.7179],
              [-100.7386, 36.8122],
              [-100.6855, 36.849],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003750',
        NAME: 'Beaver Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 788849409,
        AWATER: 213658,
        INTPTLAT: '+36.7483343',
        INTPTLON: '-100.4830555',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0541, 36.7288],
            [-94.9992, 36.8157],
            [-94.9091, 36.8226],
            [-94.9091, 36.7139],
            [-94.8645, 36.6705],
            [-94.9106, 36.627],
            [-94.9994, 36.6271],
            [-95.0901, 36.6636],
            [-95.0541, 36.7288],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002520',
        NAME: 'Afton Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270626069,
        AWATER: 3583384,
        INTPTLAT: '+36.7207541',
        INTPTLON: '-094.9756983',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1039, 36.5357],
            [-95.0057, 36.5977],
            [-94.9541, 36.5249],
            [-94.9636, 36.5102],
            [-95.0296, 36.4379],
            [-95.0413, 36.4358],
            [-95.116, 36.4887],
            [-95.1039, 36.5357],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016380',
        NAME: 'Ketchum Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143841904,
        AWATER: 12588933,
        INTPTLAT: '+36.5184162',
        INTPTLON: '-095.0530400',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4319, 36.9023],
            [-95.4076, 36.9993],
            [-95.159, 36.9996],
            [-95.035, 36.9995],
            [-95.0076, 36.9995],
            [-94.955, 36.9028],
            [-94.9993, 36.8593],
            [-95.0533, 36.8374],
            [-95.2154, 36.8587],
            [-95.4319, 36.8439],
            [-95.4319, 36.9023],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032190',
        NAME: 'Welch Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638602854,
        AWATER: 1536973,
        INTPTLAT: '+36.9531056',
        INTPTLON: '-095.2199789',
        ELSDLEA: '',
        UNSDLEA: '32190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6146, 36.4911],
            [-95.5799, 36.5976],
            [-95.5715, 36.6409],
            [-95.4317, 36.6844],
            [-95.396, 36.5976],
            [-95.3283, 36.5613],
            [-95.3282, 36.5102],
            [-95.4356, 36.4522],
            [-95.4356, 36.4085],
            [-95.6146, 36.4911],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007380',
        NAME: 'Chelsea Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418940568,
        AWATER: 49828054,
        INTPTLAT: '+36.5464750',
        INTPTLON: '-095.4642953',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0296, 35.9011],
            [-95.976, 35.9011],
            [-95.8777, 35.9011],
            [-95.8367, 35.856],
            [-95.9797, 35.8135],
            [-96.033, 35.8568],
            [-96.0296, 35.9011],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017760',
        NAME: 'Liberty Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 123787576,
        AWATER: 0,
        INTPTLAT: '+35.8619503',
        INTPTLON: '-095.9444949',
        ELSDLEA: '',
        UNSDLEA: '17760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0878, 35.5521],
            [-95.9816, 35.5363],
            [-95.9814, 35.4708],
            [-96.0877, 35.464],
            [-96.0878, 35.5521],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032880',
        NAME: 'Wilson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 94718563,
        AWATER: 24837,
        INTPTLAT: '+35.5146716',
        INTPTLON: '-096.0422450',
        ELSDLEA: '',
        UNSDLEA: '32880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6573, 36.5209],
            [-98.4624, 36.5284],
            [-98.3816, 36.5574],
            [-98.3278, 36.4568],
            [-98.3547, 36.2841],
            [-98.5017, 36.4037],
            [-98.6934, 36.4139],
            [-98.6573, 36.5209],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002720',
        NAME: 'Aline-Cleo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500314006,
        AWATER: 2157129,
        INTPTLAT: '+36.4414783',
        INTPTLON: '-098.4889411',
        ELSDLEA: '',
        UNSDLEA: '02720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5447, 36.9988],
            [-98.2918, 36.9977],
            [-98.1463, 36.9982],
            [-98.1098, 36.8408],
            [-98.2256, 36.8186],
            [-98.4947, 36.8209],
            [-98.5403, 36.8625],
            [-98.5447, 36.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005910',
        NAME: 'Burlington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 689956871,
        AWATER: 618206,
        INTPTLAT: '+36.9388456',
        INTPTLON: '-098.3385505',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9816, 35.7099],
            [-96.9821, 35.7353],
            [-96.9293, 35.8118],
            [-96.9208, 35.819],
            [-96.8316, 35.8186],
            [-96.8228, 35.7681],
            [-96.8309, 35.6389],
            [-96.9638, 35.6011],
            [-96.9993, 35.6011],
            [-96.9816, 35.7099],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007290',
        NAME: 'Chandler Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288351218,
        AWATER: 5427887,
        INTPTLAT: '+35.7077470',
        INTPTLON: '-096.9020088',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0268, 35.4924],
            [-96.9294, 35.5215],
            [-96.9547, 35.5899],
            [-96.9638, 35.6011],
            [-96.8309, 35.6389],
            [-96.7424, 35.5939],
            [-96.7864, 35.5866],
            [-96.8239, 35.4219],
            [-96.9737, 35.4636],
            [-97.0269, 35.4636],
            [-97.0268, 35.4924],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019680',
        NAME: 'Meeker Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303962397,
        AWATER: 6505700,
        INTPTLAT: '+35.5289012',
        INTPTLON: '-096.8796023',
        ELSDLEA: '',
        UNSDLEA: '19680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3184, 35.7397],
            [-97.2472, 35.7756],
            [-97.1533, 35.7891],
            [-97.1411, 35.6085],
            [-97.1851, 35.5581],
            [-97.3183, 35.6452],
            [-97.3184, 35.7397],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018630',
        NAME: 'Luther Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342606154,
        AWATER: 1162045,
        INTPTLAT: '+35.6886644',
        INTPTLON: '-097.2122515',
        ELSDLEA: '',
        UNSDLEA: '18630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6211, 35.6151],
            [-96.6206, 35.6571],
            [-96.5209, 35.653],
            [-96.4679, 35.6391],
            [-96.4678, 35.5955],
            [-96.5124, 35.595],
            [-96.5297, 35.4445],
            [-96.5351, 35.3915],
            [-96.6237, 35.4007],
            [-96.6211, 35.6151],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023340',
        NAME: 'Paden Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263034781,
        AWATER: 2977239,
        INTPTLAT: '+35.5319033',
        INTPTLON: '-096.5698233',
        ELSDLEA: '',
        UNSDLEA: '23340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7868, 36.9993],
            [-95.7548, 36.9992],
            [-95.6604, 36.9993],
            [-95.6602, 36.9497],
            [-95.4979, 36.957],
            [-95.4319, 36.9023],
            [-95.4319, 36.8439],
            [-95.4317, 36.7716],
            [-95.5491, 36.7413],
            [-95.6109, 36.7603],
            [-95.7376, 36.7281],
            [-95.8098, 36.7715],
            [-95.8099, 36.7842],
            [-95.8459, 36.902],
            [-95.7868, 36.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000021',
        NAME: 'Oklahoma Union Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794270175,
        AWATER: 2707588,
        INTPTLAT: '+36.8556403',
        INTPTLON: '-095.6386004',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0008, 36.9989],
            [-95.7868, 36.9993],
            [-95.8459, 36.902],
            [-95.8991, 36.8511],
            [-96.0007, 36.8687],
            [-96.0008, 36.9989],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008610',
        NAME: 'Copan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229449975,
        AWATER: 18364846,
        INTPTLAT: '+36.9351010',
        INTPTLON: '-095.8637745',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5351, 35.3915],
            [-96.5297, 35.4445],
            [-96.4064, 35.4066],
            [-96.4059, 35.2902],
            [-96.397, 35.232],
            [-96.4413, 35.2029],
            [-96.5303, 35.1885],
            [-96.5733, 35.2464],
            [-96.5292, 35.2827],
            [-96.5351, 35.3915],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006000',
        NAME: 'Butner Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292973004,
        AWATER: 4370394,
        INTPTLAT: '+35.3116407',
        INTPTLON: '-096.4746384',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2113, 35.5516],
            [-96.1928, 35.5589],
            [-96.1215, 35.5519],
            [-96.0878, 35.5521],
            [-96.0877, 35.464],
            [-96.0256, 35.377],
            [-96.0941, 35.3697],
            [-96.1228, 35.2899],
            [-96.24, 35.3315],
            [-96.2421, 35.3415],
            [-96.2011, 35.3771],
            [-96.2113, 35.5516],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032220',
        NAME: 'Weleetka Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371858542,
        AWATER: 6681364,
        INTPTLAT: '+35.4123675',
        INTPTLON: '-096.1507061',
        ELSDLEA: '',
        UNSDLEA: '32220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4059, 35.2902],
            [-96.2446, 35.2902],
            [-96.24, 35.3315],
            [-96.1228, 35.2899],
            [-96.0699, 35.2393],
            [-96.1239, 35.1449],
            [-96.318, 35.1669],
            [-96.397, 35.232],
            [-96.4059, 35.2902],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032430',
        NAME: 'Wetumka Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355746055,
        AWATER: 6745543,
        INTPTLAT: '+35.2233893',
        INTPTLON: '-096.2332776',
        ELSDLEA: '',
        UNSDLEA: '32430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4679, 35.6391],
            [-96.3943, 35.6392],
            [-96.1926, 35.6391],
            [-96.1928, 35.5589],
            [-96.2113, 35.5516],
            [-96.2555, 35.5003],
            [-96.4322, 35.5299],
            [-96.4678, 35.5955],
            [-96.4679, 35.6391],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019260',
        NAME: 'Mason Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284547421,
        AWATER: 6907844,
        INTPTLAT: '+35.5767254',
        INTPTLON: '-096.3156792',
        ELSDLEA: '',
        UNSDLEA: '19260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.925, 36.2973],
            [-96.802, 36.2097],
            [-96.8649, 36.1161],
            [-96.8993, 36.1166],
            [-96.9977, 36.1634],
            [-96.9972, 36.2465],
            [-96.925, 36.2973],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012690',
        NAME: 'Glencoe Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228607211,
        AWATER: 2745364,
        INTPTLAT: '+36.2069304',
        INTPTLON: '-096.8987784',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.802, 36.2097],
            [-96.6239, 36.2108],
            [-96.6063, 36.2101],
            [-96.6224, 36.1266],
            [-96.6403, 36.0511],
            [-96.6517, 36.0218],
            [-96.8283, 36.0644],
            [-96.8649, 36.1161],
            [-96.802, 36.2097],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033360',
        NAME: 'Yale Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336847478,
        AWATER: 1973026,
        INTPTLAT: '+36.1220973',
        INTPTLON: '-096.7258066',
        ELSDLEA: '',
        UNSDLEA: '33360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4648, 35.3178],
            [-94.4332, 35.3658],
            [-94.4342, 35.314],
            [-94.4648, 35.3178],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003210',
        NAME: 'Arkoma Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9195657,
        AWATER: 117492,
        INTPTLAT: '+35.3301680',
        INTPTLON: '-094.4483282',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.893, 35.2026],
            [-94.7792, 35.2457],
            [-94.7259, 35.2094],
            [-94.7524, 35.1456],
            [-94.8665, 35.106],
            [-94.893, 35.2026],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004950',
        NAME: 'Bokoshe Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150952168,
        AWATER: 664954,
        INTPTLAT: '+35.1795829',
        INTPTLON: '-094.8073905',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5754, 35.1985],
            [-94.4648, 35.3178],
            [-94.4342, 35.314],
            [-94.4357, 35.276],
            [-94.4377, 35.238],
            [-94.4385, 35.2019],
            [-94.5753, 35.1816],
            [-94.5754, 35.1985],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024630',
        NAME: 'Pocola Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81110752,
        AWATER: 747761,
        INTPTLAT: '+35.2428516',
        INTPTLON: '-094.4903366',
        ELSDLEA: '',
        UNSDLEA: '24630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6818, 35.109],
            [-94.6465, 35.1242],
            [-94.5963, 35.0875],
            [-94.4425, 35.0802],
            [-94.4443, 35.0287],
            [-94.6112, 35.0074],
            [-94.699, 34.967],
            [-94.7168, 35.0582],
            [-94.6818, 35.109],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024870',
        NAME: 'Poteau Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219045472,
        AWATER: 1671894,
        INTPTLAT: '+35.0410224',
        INTPTLON: '-094.6019109',
        ELSDLEA: '',
        UNSDLEA: '24870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6484, 35.1455],
            [-94.6465, 35.1242],
            [-94.6818, 35.109],
            [-94.6484, 35.1455],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027450',
        NAME: 'Shady Point Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12927575,
        AWATER: 3913,
        INTPTLAT: '+35.1266377',
        INTPTLON: '-094.6637898',
        ELSDLEA: '',
        UNSDLEA: '27450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8848, 34.767],
            [-94.7278, 34.7676],
            [-94.7276, 34.6812],
            [-94.5706, 34.6814],
            [-94.4561, 34.6816],
            [-94.4561, 34.6798],
            [-94.4587, 34.594],
            [-94.9375, 34.5943],
            [-94.9378, 34.6809],
            [-95.0251, 34.6808],
            [-94.8848, 34.767],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032730',
        NAME: 'Whitesboro Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651328418,
        AWATER: 1894194,
        INTPTLAT: '+34.6691157',
        INTPTLON: '-094.7575977',
        ELSDLEA: '',
        UNSDLEA: '32730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.0441, 36.7673],
              [-95.8099, 36.7842],
              [-95.8098, 36.7715],
              [-95.8095, 36.6699],
              [-95.9531, 36.6438],
              [-96.0006, 36.7256],
              [-96.0441, 36.7673],
            ],
          ],
          [
            [
              [-96.1158, 36.7816],
              [-96.0525, 36.7818],
              [-96.08, 36.7233],
              [-96.1158, 36.7816],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003630',
        NAME: 'Bartlesville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251404697,
        AWATER: 1273793,
        INTPTLAT: '+36.7244092',
        INTPTLON: '-095.9259983',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4966, 36.5355],
            [-96.3711, 36.5065],
            [-96.3533, 36.4484],
            [-96.2463, 36.4625],
            [-96.1918, 36.4626],
            [-96.1921, 36.3901],
            [-96.2328, 36.3466],
            [-96.4071, 36.3323],
            [-96.5407, 36.4004],
            [-96.6019, 36.3823],
            [-96.6202, 36.4099],
            [-96.6209, 36.5217],
            [-96.4966, 36.5355],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014970',
        NAME: 'Hominy Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 572462974,
        AWATER: 17086993,
        INTPTLAT: '+36.4458320',
        INTPTLON: '-096.4372604',
        ELSDLEA: '',
        UNSDLEA: '14970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5462, 36.6807],
            [-96.5458, 36.7535],
            [-96.4923, 36.8406],
            [-96.4206, 36.8685],
            [-96.4152, 36.9992],
            [-96.3803, 36.9993],
            [-96.3858, 36.8538],
            [-96.2602, 36.8541],
            [-96.2596, 36.7379],
            [-96.1874, 36.732],
            [-96.169, 36.6076],
            [-96.2457, 36.5496],
            [-96.3137, 36.6221],
            [-96.4034, 36.6222],
            [-96.4966, 36.5788],
            [-96.5462, 36.6807],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023580',
        NAME: 'Pawhuska Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 846499331,
        AWATER: 5587367,
        INTPTLAT: '+36.7102247',
        INTPTLON: '-096.3615031',
        ELSDLEA: '',
        UNSDLEA: '23580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0521, 36.6514],
            [-96.0006, 36.7256],
            [-95.9531, 36.6438],
            [-95.8095, 36.6699],
            [-95.8096, 36.5975],
            [-95.8127, 36.4236],
            [-95.9378, 36.4237],
            [-95.9913, 36.467],
            [-96.0487, 36.5428],
            [-96.0521, 36.6514],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025470',
        NAME: 'Caney Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489456768,
        AWATER: 3214090,
        INTPTLAT: '+36.5616452',
        INTPTLON: '-095.9246054',
        ELSDLEA: '',
        UNSDLEA: '25470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9074, 36.5939],
            [-96.897, 36.6877],
            [-96.7319, 36.6952],
            [-96.5462, 36.6807],
            [-96.4966, 36.5788],
            [-96.4966, 36.5355],
            [-96.6209, 36.5217],
            [-96.6202, 36.4099],
            [-96.6895, 36.4239],
            [-96.8332, 36.5072],
            [-96.961, 36.4601],
            [-97.0052, 36.4857],
            [-96.9074, 36.5939],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030048',
        NAME: 'Woodland Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 891172938,
        AWATER: 16437647,
        INTPTLAT: '+36.5713550',
        INTPTLON: '-096.7349803',
        ELSDLEA: '',
        UNSDLEA: '30048',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4966, 36.5788],
            [-96.4034, 36.6222],
            [-96.3137, 36.6221],
            [-96.2457, 36.5496],
            [-96.2463, 36.4625],
            [-96.3533, 36.4484],
            [-96.3711, 36.5065],
            [-96.4966, 36.5355],
            [-96.4966, 36.5788],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033330',
        NAME: 'Wynona Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240182597,
        AWATER: 104019,
        INTPTLAT: '+36.5288459',
        INTPTLON: '-096.3615313',
        ELSDLEA: '',
        UNSDLEA: '33330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.3352, 36.2099],
              [-98.3262, 36.21],
              [-98.3352, 36.2028],
              [-98.3352, 36.2099],
            ],
          ],
          [
            [
              [-98.7642, 36.3329],
              [-98.6934, 36.4139],
              [-98.5017, 36.4037],
              [-98.3547, 36.2841],
              [-98.2938, 36.2754],
              [-98.2799, 36.2462],
              [-98.3352, 36.2099],
              [-98.3352, 36.2028],
              [-98.4769, 36.1363],
              [-98.4862, 36.1949],
              [-98.6637, 36.2027],
              [-98.7258, 36.1633],
              [-98.7642, 36.3329],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011280',
        NAME: 'Fairview Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 818362311,
        AWATER: 2292496,
        INTPTLAT: '+36.2818802',
        INTPTLON: '-098.5509602',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9696, 34.071],
            [-96.9337, 34.1726],
            [-96.8456, 34.1725],
            [-96.67, 34.1723],
            [-96.5854, 34.1145],
            [-96.548, 34.0633],
            [-96.6249, 34.0076],
            [-96.8269, 34.0127],
            [-96.8926, 33.8897],
            [-96.9348, 33.9545],
            [-96.9696, 34.071],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018700',
        NAME: 'Madill Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 628588051,
        AWATER: 38446777,
        INTPTLAT: '+34.0785417',
        INTPTLON: '-096.8091016',
        ELSDLEA: '',
        UNSDLEA: '18700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8926, 33.8897],
            [-96.8269, 34.0127],
            [-96.6249, 34.0076],
            [-96.5806, 33.9233],
            [-96.5885, 33.895],
            [-96.6907, 33.85],
            [-96.7868, 33.8652],
            [-96.8926, 33.8897],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016590',
        NAME: 'Kingston Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332716282,
        AWATER: 105751924,
        INTPTLAT: '+33.9379029',
        INTPTLON: '-096.7441033',
        ELSDLEA: '',
        UNSDLEA: '16590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2601, 34.9565],
            [-98.2598, 34.9855],
            [-98.1808, 34.978],
            [-98.1454, 34.8549],
            [-98.2687, 34.8697],
            [-98.2601, 34.9565],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009300',
        NAME: 'Cyril Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140544008,
        AWATER: 374959,
        INTPTLAT: '+34.9202900',
        INTPTLON: '-098.2106053',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1742, 36.39],
            [-95.9913, 36.467],
            [-95.9378, 36.4237],
            [-95.9381, 36.3367],
            [-96.0971, 36.3038],
            [-96.1387, 36.3033],
            [-96.1742, 36.39],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027750',
        NAME: 'Skiatook Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211407333,
        AWATER: 22696296,
        INTPTLAT: '+36.3786645',
        INTPTLON: '-096.0430629',
        ELSDLEA: '',
        UNSDLEA: '27750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1808, 34.978],
            [-98.146, 35.0287],
            [-98.102, 35.0288],
            [-98.0496, 34.9419],
            [-98.0315, 34.8983],
            [-98.0315, 34.8913],
            [-98.0315, 34.8621],
            [-98.0717, 34.8406],
            [-98.1454, 34.8549],
            [-98.1808, 34.978],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006960',
        NAME: 'Cement Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175152942,
        AWATER: 779782,
        INTPTLAT: '+34.9229470',
        INTPTLON: '-098.1053127',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8797, 34.4497],
            [-98.8186, 34.4785],
            [-98.7293, 34.5219],
            [-98.4833, 34.529],
            [-98.4616, 34.5076],
            [-98.4615, 34.4853],
            [-98.4692, 34.4131],
            [-98.505, 34.3987],
            [-98.5624, 34.3186],
            [-98.6097, 34.3337],
            [-98.6794, 34.3261],
            [-98.8015, 34.3264],
            [-98.8713, 34.2686],
            [-98.8738, 34.3422],
            [-98.9234, 34.3919],
            [-98.8797, 34.4497],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007320',
        NAME: 'Chattanooga Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 682409759,
        AWATER: 4585416,
        INTPTLAT: '+34.4121303',
        INTPTLON: '-098.6653791',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1823, 34.5071],
            [-98.1769, 34.536],
            [-97.9933, 34.5507],
            [-97.995, 34.478],
            [-98.0085, 34.4131],
            [-98.1302, 34.3839],
            [-98.1823, 34.5071],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010890',
        NAME: 'Empire Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268108219,
        AWATER: 3624674,
        INTPTLAT: '+34.4754448',
        INTPTLON: '-098.0893268',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5448, 34.7608],
            [-98.4923, 34.723],
            [-98.3732, 34.7102],
            [-98.283, 34.768],
            [-98.2824, 34.6376],
            [-98.221, 34.6158],
            [-98.2383, 34.5289],
            [-98.3782, 34.5581],
            [-98.3872, 34.5797],
            [-98.4393, 34.551],
            [-98.4834, 34.5581],
            [-98.5448, 34.7608],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017250',
        NAME: 'Lawton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470708284,
        AWATER: 8118614,
        INTPTLAT: '+34.6447942',
        INTPTLON: '-098.3721849',
        ELSDLEA: '',
        UNSDLEA: '17250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2479, 34.7753],
            [-98.0891, 34.8116],
            [-98.0631, 34.6885],
            [-98.221, 34.6267],
            [-98.2479, 34.7753],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028590',
        NAME: 'Sterling Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240046241,
        AWATER: 608744,
        INTPTLAT: '+34.7318805',
        INTPTLON: '-098.1517814',
        ELSDLEA: '',
        UNSDLEA: '28590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.505, 34.3987],
            [-98.4692, 34.4131],
            [-98.3393, 34.4272],
            [-98.2383, 34.5143],
            [-98.1823, 34.5071],
            [-98.1302, 34.3839],
            [-98.1319, 34.3405],
            [-98.1391, 34.3404],
            [-98.2613, 34.3404],
            [-98.2956, 34.2824],
            [-98.3914, 34.2825],
            [-98.4264, 34.3404],
            [-98.5613, 34.3041],
            [-98.5624, 34.3186],
            [-98.505, 34.3987],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031470',
        NAME: 'Walters Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 502929761,
        AWATER: 4548305,
        INTPTLAT: '+34.3842462',
        INTPTLON: '-098.3238829',
        ELSDLEA: '',
        UNSDLEA: '31470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1591, 34.8477],
            [-99.1668, 34.8851],
            [-99.0872, 34.9505],
            [-98.9721, 34.9501],
            [-98.7958, 34.8847],
            [-98.7907, 34.8264],
            [-98.8087, 34.7906],
            [-98.8261, 34.5437],
            [-98.9573, 34.5438],
            [-99.1326, 34.5881],
            [-99.0957, 34.66],
            [-99.173, 34.7553],
            [-99.1591, 34.8477],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027930',
        NAME: 'Snyder Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1140715798,
        AWATER: 25626628,
        INTPTLAT: '+34.7370367',
        INTPTLON: '-099.0036803',
        ELSDLEA: '',
        UNSDLEA: '27930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5621, 34.8553],
            [-98.475, 34.8116],
            [-98.3609, 34.8259],
            [-98.2479, 34.7753],
            [-98.221, 34.6267],
            [-98.221, 34.6158],
            [-98.2824, 34.6376],
            [-98.283, 34.768],
            [-98.3732, 34.7102],
            [-98.4923, 34.723],
            [-98.5448, 34.7608],
            [-98.5621, 34.8553],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010710',
        NAME: 'Elgin Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306674311,
        AWATER: 12033300,
        INTPTLAT: '+34.7573045',
        INTPTLON: '-098.3865021',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0631, 34.6814],
            [-97.8965, 34.6816],
            [-97.8879, 34.587],
            [-97.9933, 34.5579],
            [-98.0631, 34.6814],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019080',
        NAME: 'Marlow Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160257399,
        AWATER: 5003904,
        INTPTLAT: '+34.6341545',
        INTPTLON: '-097.9642754',
        ELSDLEA: '',
        UNSDLEA: '19080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7722, 34.2818],
            [-97.7722, 34.2892],
            [-97.6154, 34.318],
            [-97.5625, 34.2818],
            [-97.5626, 34.202],
            [-97.5624, 34.0711],
            [-97.4741, 34.0711],
            [-97.4388, 34.0129],
            [-97.5099, 33.9166],
            [-97.5174, 33.914],
            [-97.5859, 33.9019],
            [-97.6307, 33.9793],
            [-97.6395, 34.0565],
            [-97.7373, 34.0898],
            [-97.7722, 34.2818],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025860',
        NAME: 'Ringling Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696348572,
        AWATER: 3375011,
        INTPTLAT: '+34.1098254',
        INTPTLON: '-097.6230061',
        ELSDLEA: '',
        UNSDLEA: '25860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8965, 34.6816],
            [-97.8876, 34.7326],
            [-97.7475, 34.7359],
            [-97.6658, 34.6915],
            [-97.5628, 34.6811],
            [-97.5718, 34.5288],
            [-97.7833, 34.5289],
            [-97.8792, 34.5798],
            [-97.8879, 34.587],
            [-97.8965, 34.6816],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005340',
        NAME: 'Bray-Doyle Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 593834075,
        AWATER: 16252378,
        INTPTLAT: '+34.6312606',
        INTPTLON: '-097.7402938',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4051, 34.5068],
            [-97.3172, 34.5432],
            [-97.0634, 34.5651],
            [-97.0381, 34.3685],
            [-97.2821, 34.3762],
            [-97.3346, 34.3762],
            [-97.3522, 34.3978],
            [-97.4051, 34.5068],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009510',
        NAME: 'Davis Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 582582503,
        AWATER: 11666290,
        INTPTLAT: '+34.4413170',
        INTPTLON: '-097.1814541',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2827, 34.681],
            [-97.1659, 34.7073],
            [-97.0353, 34.695],
            [-97.0027, 34.6368],
            [-97.0634, 34.5651],
            [-97.3172, 34.5432],
            [-97.2827, 34.681],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033300',
        NAME: 'Wynnewood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390895933,
        AWATER: 5648541,
        INTPTLAT: '+34.6272989',
        INTPTLON: '-097.1711687',
        ELSDLEA: '',
        UNSDLEA: '33300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8273, 34.506],
            [-96.7404, 34.5058],
            [-96.7058, 34.3177],
            [-96.8281, 34.2903],
            [-96.8631, 34.2919],
            [-96.933, 34.3327],
            [-96.907, 34.5062],
            [-96.8273, 34.506],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020040',
        NAME: 'Mill Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409303756,
        AWATER: 2138659,
        INTPTLAT: '+34.3966458',
        INTPTLON: '-096.8162758',
        ELSDLEA: '',
        UNSDLEA: '20040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2938, 36.2754],
            [-98.1581, 36.3402],
            [-98.1672, 36.4343],
            [-98.122, 36.4632],
            [-98.0063, 36.398],
            [-98.0061, 36.3836],
            [-98.1045, 36.3473],
            [-98.0777, 36.181],
            [-98.2103, 36.1476],
            [-98.2799, 36.2462],
            [-98.2938, 36.2754],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007710',
        NAME: 'Cimarron Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388579254,
        AWATER: 1319022,
        INTPTLAT: '+36.2965930',
        INTPTLON: '-098.1492317',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4769, 36.1363],
            [-98.3352, 36.2028],
            [-98.3262, 36.21],
            [-98.3352, 36.2099],
            [-98.2799, 36.2462],
            [-98.2103, 36.1476],
            [-98.1116, 36.0505],
            [-98.2102, 36.0506],
            [-98.2279, 35.9712],
            [-98.347, 35.9677],
            [-98.4408, 35.9849],
            [-98.4317, 36.0642],
            [-98.4769, 36.1363],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022650',
        NAME: 'Okeene Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 584349940,
        AWATER: 1024532,
        INTPTLAT: '+36.0942525',
        INTPTLON: '-098.3081999',
        ELSDLEA: '',
        UNSDLEA: '22650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4047, 34.7251],
            [-99.387, 34.7251],
            [-99.4045, 34.6595],
            [-99.2462, 34.6813],
            [-99.0957, 34.66],
            [-99.1326, 34.5881],
            [-99.1968, 34.5424],
            [-99.2219, 34.4283],
            [-99.2069, 34.3383],
            [-99.3151, 34.4087],
            [-99.3554, 34.4489],
            [-99.4039, 34.6305],
            [-99.4743, 34.6233],
            [-99.4392, 34.6886],
            [-99.5104, 34.725],
            [-99.4047, 34.7251],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002850',
        NAME: 'Altus Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 632667033,
        AWATER: 2669233,
        INTPTLAT: '+34.5728673',
        INTPTLON: '-099.2897784',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.73, 33.9388],
            [-95.6343, 34.0114],
            [-95.5301, 33.9608],
            [-95.3649, 33.9611],
            [-95.3656, 33.8671],
            [-95.5338, 33.8812],
            [-95.5528, 33.9243],
            [-95.7288, 33.8945],
            [-95.73, 33.9388],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013320',
        NAME: 'Grant Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292888185,
        AWATER: 10974897,
        INTPTLAT: '+33.9338568',
        INTPTLON: '-095.5354869',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.2559, 34.3309],
              [-95.1342, 34.3448],
              [-95.1358, 34.244],
              [-95.1565, 34.1133],
              [-95.4282, 34.0988],
              [-95.4422, 34.1569],
              [-95.5352, 34.1568],
              [-95.544, 34.1714],
              [-95.4827, 34.1713],
              [-95.4653, 34.2881],
              [-95.3606, 34.3307],
              [-95.2559, 34.3309],
            ],
          ],
          [
            [
              [-95.544, 34.1714],
              [-95.5433, 34.1568],
              [-95.5552, 34.1569],
              [-95.544, 34.1714],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025500',
        NAME: 'Rattan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 661657246,
        AWATER: 8682880,
        INTPTLAT: '+34.2483365',
        INTPTLON: '-095.2876355',
        ELSDLEA: '',
        UNSDLEA: '25500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8362, 34.1568],
            [-95.7794, 34.1567],
            [-95.5565, 34.1569],
            [-95.6343, 34.0114],
            [-95.73, 33.9388],
            [-95.8362, 34.1568],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027960',
        NAME: 'Soper Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358103621,
        AWATER: 499613,
        INTPTLAT: '+34.0766490',
        INTPTLON: '-095.7142305',
        ELSDLEA: '',
        UNSDLEA: '27960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.397, 36.9961],
            [-101.2149, 36.997],
            [-101.1622, 36.8919],
            [-101.0899, 36.8777],
            [-101.0616, 36.7764],
            [-100.9541, 36.7616],
            [-100.9539, 36.6712],
            [-100.954, 36.646],
            [-101.2419, 36.7179],
            [-101.2777, 36.7205],
            [-101.3789, 36.8632],
            [-101.397, 36.9961],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015090',
        NAME: 'Hooker Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 784169328,
        AWATER: 2657376,
        INTPTLAT: '+36.8271115',
        INTPTLON: '-101.1967750',
        ELSDLEA: '',
        UNSDLEA: '15090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2776, 36.6308],
            [-101.2419, 36.7179],
            [-100.954, 36.646],
            [-100.9542, 36.4999],
            [-101.0852, 36.4992],
            [-101.2048, 36.4995],
            [-101.3668, 36.4996],
            [-101.3634, 36.587],
            [-101.2776, 36.6308],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013740',
        NAME: 'Hardesty Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 647029853,
        AWATER: 585254,
        INTPTLAT: '+36.5840865',
        INTPTLON: '-101.1444358',
        ELSDLEA: '',
        UNSDLEA: '13740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3366, 35.9992],
            [-97.2468, 36.1014],
            [-97.1939, 36.1014],
            [-97.1576, 36.0578],
            [-97.1767, 35.8474],
            [-97.141, 35.8109],
            [-97.1533, 35.7891],
            [-97.2472, 35.7756],
            [-97.3435, 35.7761],
            [-97.3366, 35.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008940',
        NAME: 'Coyle Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 462206037,
        AWATER: 4755011,
        INTPTLAT: '+35.9220170',
        INTPTLON: '-097.2419191',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6517, 36.0218],
            [-96.6403, 36.0511],
            [-96.5391, 36.0465],
            [-96.4945, 35.9374],
            [-96.4943, 35.901],
            [-96.6192, 35.8643],
            [-96.6211, 35.9415],
            [-96.6604, 36.0002],
            [-96.6517, 36.0218],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010200',
        NAME: 'Drumright Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172912867,
        AWATER: 1207448,
        INTPTLAT: '+35.9627542',
        INTPTLON: '-096.5650736',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1575, 36.3114],
            [-97.0854, 36.3408],
            [-97.0052, 36.4857],
            [-96.961, 36.4601],
            [-96.925, 36.2973],
            [-96.9972, 36.2465],
            [-96.9977, 36.1634],
            [-97.0344, 36.2098],
            [-97.1409, 36.2099],
            [-97.1575, 36.3114],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020340',
        NAME: 'Morrison Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364941367,
        AWATER: 15286601,
        INTPTLAT: '+36.3133450',
        INTPTLON: '-097.0314758',
        ELSDLEA: '',
        UNSDLEA: '20340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7294, 36.9991],
            [-100.5522, 37.0007],
            [-100.2161, 37.002],
            [-100.1245, 37.0023],
            [-100.1456, 36.7764],
            [-100.2546, 36.7759],
            [-100.2542, 36.8486],
            [-100.6855, 36.849],
            [-100.7291, 36.8923],
            [-100.7294, 36.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011880',
        NAME: 'Forgan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 969898599,
        AWATER: 3337405,
        INTPTLAT: '+36.9157095',
        INTPTLON: '-100.4127863',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8195, 36.5078],
            [-99.856, 36.4351],
            [-99.8103, 36.2313],
            [-99.8638, 36.115],
            [-99.934, 36.0427],
            [-100.0004, 36.0426],
            [-100.0004, 36.0557],
            [-100.0004, 36.2735],
            [-100.0257, 36.4999],
            [-99.8195, 36.5078],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027540',
        NAME: 'Shattuck Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740962252,
        AWATER: 69344,
        INTPTLAT: '+36.3131752',
        INTPTLON: '-099.9245701',
        ELSDLEA: '',
        UNSDLEA: '27540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.954, 36.646],
            [-100.9539, 36.6712],
            [-100.8287, 36.6743],
            [-100.7385, 36.7179],
            [-100.6133, 36.6814],
            [-100.3619, 36.6814],
            [-100.3165, 36.645],
            [-100.3076, 36.6377],
            [-100.311, 36.4996],
            [-100.4245, 36.4994],
            [-100.4505, 36.4995],
            [-100.4418, 36.5872],
            [-100.5228, 36.5872],
            [-100.5221, 36.4993],
            [-100.6122, 36.4995],
            [-100.9542, 36.4999],
            [-100.954, 36.646],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003510',
        NAME: 'Balko Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1142190885,
        AWATER: 279891,
        INTPTLAT: '+36.5957215',
        INTPTLON: '-100.6460512',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1456, 36.7764],
            [-100.1245, 37.0023],
            [-99.8286, 37.0012],
            [-99.8363, 36.8558],
            [-99.7099, 36.6957],
            [-99.7638, 36.6513],
            [-99.7839, 36.5367],
            [-99.8195, 36.5078],
            [-100.0257, 36.4999],
            [-100.2582, 36.4998],
            [-100.311, 36.4996],
            [-100.3076, 36.6377],
            [-100.3165, 36.645],
            [-100.3619, 36.6814],
            [-100.2814, 36.7468],
            [-100.1456, 36.7764],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017220',
        NAME: 'Laverne Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2154116826,
        AWATER: 6038891,
        INTPTLAT: '+36.7214359',
        INTPTLON: '-100.0183522',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5231, 36.0461],
            [-95.4307, 36.0752],
            [-95.3123, 36.0353],
            [-95.2522, 35.9878],
            [-95.283, 35.9082],
            [-95.3941, 35.8817],
            [-95.4958, 35.9568],
            [-95.5648, 35.9676],
            [-95.5231, 36.0461],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031350',
        NAME: 'Wagoner Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342493506,
        AWATER: 30689579,
        INTPTLAT: '+35.9978303',
        INTPTLON: '-095.4071970',
        ELSDLEA: '',
        UNSDLEA: '31350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3123, 36.0353],
            [-95.2524, 36.0829],
            [-95.301, 36.1539],
            [-95.2455, 36.2201],
            [-95.2393, 36.2347],
            [-95.0112, 36.2344],
            [-95.0289, 36.1618],
            [-95.0915, 36.1616],
            [-95.1718, 36.0312],
            [-95.2522, 35.9878],
            [-95.3123, 36.0353],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018000',
        NAME: 'Locust Grove Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380959831,
        AWATER: 14222966,
        INTPTLAT: '+36.1395273',
        INTPTLON: '-095.1715185',
        ELSDLEA: '',
        UNSDLEA: '18000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6546, 36.075],
            [-95.5581, 36.0966],
            [-95.5231, 36.0461],
            [-95.5648, 35.9676],
            [-95.647, 35.8638],
            [-95.7265, 35.9032],
            [-95.7439, 35.9883],
            [-95.6546, 36.0027],
            [-95.6546, 36.075],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008850',
        NAME: 'Coweta Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295386085,
        AWATER: 6948672,
        INTPTLAT: '+35.9814311',
        INTPTLON: '-095.6243714',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.283, 35.9082],
            [-95.231, 35.871],
            [-95.2428, 35.8421],
            [-95.3224, 35.8135],
            [-95.3762, 35.8147],
            [-95.3941, 35.8817],
            [-95.283, 35.9082],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022620',
        NAME: 'Okay Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116914454,
        AWATER: 9603812,
        INTPTLAT: '+35.8732173',
        INTPTLON: '-095.3276641',
        ELSDLEA: '',
        UNSDLEA: '22620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0322, 35.9493],
            [-97.7387, 35.9345],
            [-97.7128, 35.8848],
            [-97.8339, 35.7766],
            [-98.0659, 35.8052],
            [-98.0922, 35.8985],
            [-98.0322, 35.9493],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016560',
        NAME: 'Kingfisher Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 472617312,
        AWATER: 4230497,
        INTPTLAT: '+35.8763201',
        INTPTLON: '-097.9161705',
        ELSDLEA: '',
        UNSDLEA: '16560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9965, 35.9959],
            [-97.9967, 36.0366],
            [-97.72, 36.0437],
            [-97.6757, 36.0438],
            [-97.7387, 35.9345],
            [-98.0322, 35.9493],
            [-97.9965, 35.9959],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010050',
        NAME: 'Dover Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315438864,
        AWATER: 3489624,
        INTPTLAT: '+35.9932314',
        INTPTLON: '-097.8631523',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3533, 34.8696],
            [-97.1951, 34.8552],
            [-97.2652, 34.7681],
            [-97.3233, 34.7827],
            [-97.3533, 34.8696],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023430',
        NAME: 'Paoli Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121617697,
        AWATER: 3433068,
        INTPTLAT: '+34.8200553',
        INTPTLON: '-097.2746281',
        ELSDLEA: '',
        UNSDLEA: '23430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.4629, 35.58],
              [-94.4547, 35.5284],
              [-94.5132, 35.5285],
              [-94.5132, 35.5356],
              [-94.4629, 35.58],
            ],
          ],
          [
            [
              [-94.6023, 35.536],
              [-94.5603, 35.5356],
              [-94.5489, 35.4125],
              [-94.4864, 35.3468],
              [-94.5791, 35.3288],
              [-94.6695, 35.4201],
              [-94.6023, 35.536],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020880',
        NAME: 'Muldrow Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205695624,
        AWATER: 5396344,
        INTPTLAT: '+35.4265087',
        INTPTLON: '-094.5816628',
        ELSDLEA: '',
        UNSDLEA: '20880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2017, 36.7315],
            [-97.2019, 36.7605],
            [-97.0763, 36.7674],
            [-96.9414, 36.7312],
            [-96.9105, 36.7603],
            [-96.897, 36.6877],
            [-96.9074, 36.5939],
            [-97.0029, 36.5826],
            [-97.1067, 36.6263],
            [-97.2019, 36.5955],
            [-97.2017, 36.7315],
          ],
          [
            [-97.0645, 36.6813],
            [-97.062, 36.6507],
            [-96.9953, 36.6371],
            [-96.9954, 36.6881],
            [-97.0645, 36.6813],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024690',
        NAME: 'Ponca City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 423656747,
        AWATER: 24218148,
        INTPTLAT: '+36.6327587',
        INTPTLON: '-097.1176649',
        ELSDLEA: '',
        UNSDLEA: '24690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5611, 35.7759],
            [-98.2338, 35.7832],
            [-98.2071, 35.7251],
            [-98.207, 35.5948],
            [-98.2511, 35.5514],
            [-98.4388, 35.5515],
            [-98.4913, 35.5592],
            [-98.5968, 35.6891],
            [-98.5611, 35.7759],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012570',
        NAME: 'Geary Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 768616846,
        AWATER: 1360273,
        INTPTLAT: '+35.6758312',
        INTPTLON: '-098.3947755',
        ELSDLEA: '',
        UNSDLEA: '12570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9221, 36.0536],
            [-95.8154, 36.1189],
            [-95.851, 36.0097],
            [-95.8776, 36.0174],
            [-95.9221, 36.0536],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030600',
        NAME: 'Union Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70755026,
        AWATER: 144848,
        INTPTLAT: '+36.0648797',
        INTPTLON: '-095.8542238',
        ELSDLEA: '',
        UNSDLEA: '30600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9916, 34.1568],
            [-95.9878, 34.1568],
            [-95.919, 34.1566],
            [-95.8362, 34.1568],
            [-95.73, 33.9388],
            [-95.7288, 33.8945],
            [-95.7615, 33.8731],
            [-95.8731, 33.8809],
            [-95.9379, 33.9482],
            [-95.9381, 34.1251],
            [-95.9916, 34.1568],
            [-95.9916, 34.1568],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005040',
        NAME: 'Boswell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460043283,
        AWATER: 2012121,
        INTPTLAT: '+34.0078495',
        INTPTLON: '-095.8428462',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4656, 33.969],
            [-96.338, 33.9541],
            [-96.3378, 33.9103],
            [-96.4655, 33.8963],
            [-96.5269, 33.9504],
            [-96.4656, 33.969],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006180',
        NAME: 'Calera Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121608915,
        AWATER: 1820002,
        INTPTLAT: '+33.9357164',
        INTPTLON: '-096.4303211',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5854, 34.1145],
            [-96.4538, 34.1144],
            [-96.4133, 34.1573],
            [-96.4106, 34.1573],
            [-96.3467, 34.0484],
            [-96.4656, 33.969],
            [-96.5269, 33.9504],
            [-96.5806, 33.9233],
            [-96.6249, 34.0076],
            [-96.548, 34.0633],
            [-96.5854, 34.1145],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027640',
        NAME: 'Silo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276363822,
        AWATER: 36967086,
        INTPTLAT: '+34.0526195',
        INTPTLON: '-096.4883898',
        ELSDLEA: '',
        UNSDLEA: '27640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6019, 36.3823],
            [-96.5407, 36.4004],
            [-96.4071, 36.3323],
            [-96.3713, 36.2558],
            [-96.2429, 36.2019],
            [-96.2645, 36.1794],
            [-96.3034, 36.1876],
            [-96.4282, 36.2027],
            [-96.4857, 36.1181],
            [-96.5122, 36.1172],
            [-96.5122, 36.1411],
            [-96.6063, 36.2101],
            [-96.6239, 36.2108],
            [-96.6019, 36.3823],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008040',
        NAME: 'Cleveland Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427922063,
        AWATER: 43704378,
        INTPTLAT: '+36.2489622',
        INTPTLON: '-096.4764140',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4624, 36.8833],
            [-97.3367, 36.8621],
            [-97.2556, 36.8691],
            [-97.2022, 36.7752],
            [-97.2019, 36.7605],
            [-97.2017, 36.7315],
            [-97.4176, 36.7314],
            [-97.4623, 36.7348],
            [-97.4624, 36.8833],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004630',
        NAME: 'Blackwell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296579185,
        AWATER: 165499,
        INTPTLAT: '+36.7896662',
        INTPTLON: '-097.3502126',
        ELSDLEA: '',
        UNSDLEA: '04630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2391, 35.2974],
            [-97.239, 35.3409],
            [-97.142, 35.3407],
            [-97.142, 35.2681],
            [-97.1416, 35.2103],
            [-97.1421, 35.1668],
            [-97.2386, 35.2107],
            [-97.2391, 35.2974],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017880',
        NAME: 'Little Axe Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139418219,
        AWATER: 8205028,
        INTPTLAT: '+35.2681757',
        INTPTLON: '-097.1861896',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5561, 35.2763],
            [-97.396, 35.2762],
            [-97.344, 35.2979],
            [-97.2391, 35.2974],
            [-97.2386, 35.2107],
            [-97.2915, 35.1672],
            [-97.4335, 35.1453],
            [-97.5295, 35.1459],
            [-97.5398, 35.1604],
            [-97.5561, 35.2763],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021720',
        NAME: 'Norman Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316634429,
        AWATER: 17029020,
        INTPTLAT: '+35.2233390',
        INTPTLON: '-097.4034725',
        ELSDLEA: '',
        UNSDLEA: '21720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1476, 36.8546],
              [-97.1114, 36.999],
              [-96.8675, 36.9993],
              [-96.7498, 36.999],
              [-96.7524, 36.8908],
              [-96.8696, 36.8181],
              [-97.0942, 36.8399],
              [-97.1476, 36.8546],
            ],
          ],
          [
            [
              [-97.4623, 36.9987],
              [-97.2199, 36.9989],
              [-97.2015, 36.9989],
              [-97.2465, 36.9126],
              [-97.2556, 36.8691],
              [-97.3367, 36.8621],
              [-97.4624, 36.8833],
              [-97.4623, 36.9987],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021540',
        NAME: 'Newkirk Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 864341555,
        AWATER: 6979215,
        INTPTLAT: '+36.9194744',
        INTPTLON: '-097.0810872',
        ELSDLEA: '',
        UNSDLEA: '21540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4335, 35.1453],
            [-97.2915, 35.1672],
            [-97.2386, 35.2107],
            [-97.1421, 35.1668],
            [-97.142, 35.1307],
            [-97.142, 35.0728],
            [-97.3578, 35.0803],
            [-97.4031, 35.0878],
            [-97.4335, 35.1453],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021630',
        NAME: 'Noble Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301203272,
        AWATER: 5521797,
        INTPTLAT: '+35.1383359',
        INTPTLON: '-097.2757834',
        ELSDLEA: '',
        UNSDLEA: '21630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9901, 34.5938],
            [-94.9375, 34.5943],
            [-94.4587, 34.594],
            [-94.4623, 34.4629],
            [-94.4664, 34.3307],
            [-94.6976, 34.3309],
            [-94.7851, 34.5073],
            [-94.9901, 34.5071],
            [-94.9901, 34.5938],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027840',
        NAME: 'Smithville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 991751115,
        AWATER: 4448335,
        INTPTLAT: '+34.4918281',
        INTPTLON: '-094.6738740',
        ELSDLEA: '',
        UNSDLEA: '27840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7219, 33.8665],
            [-94.7144, 33.9279],
            [-94.5756, 33.9513],
            [-94.4773, 33.9409],
            [-94.4815, 33.796],
            [-94.4825, 33.765],
            [-94.4859, 33.6379],
            [-94.5641, 33.6263],
            [-94.7461, 33.703],
            [-94.7547, 33.7331],
            [-94.7219, 33.8665],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014080',
        NAME: 'Haworth Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 711251544,
        AWATER: 16771274,
        INTPTLAT: '+33.8039777',
        INTPTLON: '-094.6092424',
        ELSDLEA: '',
        UNSDLEA: '14080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2037, 34.4175],
            [-95.0468, 34.4175],
            [-94.9901, 34.5071],
            [-94.7851, 34.5073],
            [-94.6976, 34.3309],
            [-94.7325, 34.1856],
            [-94.8022, 34.2583],
            [-94.9073, 34.2582],
            [-95.0464, 34.2873],
            [-95.1353, 34.244],
            [-95.1358, 34.244],
            [-95.1342, 34.3448],
            [-95.2559, 34.3309],
            [-95.2037, 34.4175],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003660',
        NAME: 'Battiest Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1024619601,
        AWATER: 3865182,
        INTPTLAT: '+34.3440083',
        INTPTLON: '-094.9405938',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0643, 33.9101],
            [-94.9744, 33.9031],
            [-94.9308, 33.8521],
            [-94.8521, 33.8955],
            [-94.8432, 33.9536],
            [-94.8003, 33.9392],
            [-94.7219, 33.8665],
            [-94.7547, 33.7331],
            [-94.8778, 33.7589],
            [-95.0643, 33.9101],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015370',
        NAME: 'Idabel Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313788083,
        AWATER: 15758487,
        INTPTLAT: '+33.8334794',
        INTPTLON: '-094.8570896',
        ELSDLEA: '',
        UNSDLEA: '15370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6976, 34.3309],
            [-94.4664, 34.3307],
            [-94.4703, 34.1899],
            [-94.4753, 34.0159],
            [-94.4773, 33.9409],
            [-94.5756, 33.9513],
            [-94.6346, 34.0985],
            [-94.7325, 34.1856],
            [-94.6976, 34.3309],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010440',
        NAME: 'Eagletown Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 718039335,
        AWATER: 58203306,
        INTPTLAT: '+34.1689324',
        INTPTLON: '-094.5797425',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3019, 34.9564],
            [-96.3, 34.9832],
            [-96.2741, 35.0288],
            [-96.1322, 35.0143],
            [-96.1619, 34.7672],
            [-96.2928, 34.7523],
            [-96.3019, 34.9564],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006270',
        NAME: 'Calvin Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397607601,
        AWATER: 3159650,
        INTPTLAT: '+34.8814948',
        INTPTLON: '-096.2280525',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8286, 37.0012],
            [-99.5411, 36.9996],
            [-99.4562, 36.9995],
            [-99.373, 36.8786],
            [-99.3221, 36.8572],
            [-99.3585, 36.6382],
            [-99.3949, 36.6376],
            [-99.5578, 36.6954],
            [-99.7099, 36.6957],
            [-99.8363, 36.8558],
            [-99.8286, 37.0012],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005760',
        NAME: 'Buffalo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1377602451,
        AWATER: 2273437,
        INTPTLAT: '+36.8391956',
        INTPTLON: '-099.5744078',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.9108, 35.4129],
              [-95.9107, 35.3985],
              [-95.9064, 35.3985],
              [-95.8754, 35.4095],
              [-95.8754, 35.4057],
              [-95.8612, 35.3767],
              [-95.8983, 35.3693],
              [-95.9608, 35.3768],
              [-95.9108, 35.4129],
            ],
          ],
          [
            [
              [-96.1228, 35.2899],
              [-96.0941, 35.3697],
              [-96.0256, 35.377],
              [-95.9813, 35.3768],
              [-95.9105, 35.3262],
              [-95.9815, 35.2243],
              [-95.9656, 35.1525],
              [-96.0123, 35.1451],
              [-96.1239, 35.1449],
              [-96.0699, 35.2393],
              [-96.1228, 35.2899],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000782',
        NAME: 'Graham-Dustin Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351634170,
        AWATER: 5774750,
        INTPTLAT: '+35.2694467',
        INTPTLON: '-096.0195648',
        ELSDLEA: '',
        UNSDLEA: '00782',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.822, 35.3041],
            [-95.6121, 35.3559],
            [-95.4513, 35.3483],
            [-95.4161, 35.3191],
            [-95.4513, 35.296],
            [-95.6157, 35.2497],
            [-95.7275, 35.1913],
            [-95.7278, 35.1914],
            [-95.8221, 35.2462],
            [-95.822, 35.3041],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011160',
        NAME: 'Eufaula Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267067566,
        AWATER: 96588204,
        INTPTLAT: '+35.2984164',
        INTPTLON: '-095.6316132',
        ELSDLEA: '',
        UNSDLEA: '11160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9105, 35.3262],
            [-95.8983, 35.3693],
            [-95.8612, 35.3767],
            [-95.8222, 35.3721],
            [-95.822, 35.3041],
            [-95.8221, 35.2462],
            [-95.7278, 35.1914],
            [-95.8707, 35.186],
            [-95.9656, 35.1525],
            [-95.9815, 35.2243],
            [-95.9105, 35.3262],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013680',
        NAME: 'Hanna Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280621263,
        AWATER: 9007275,
        INTPTLAT: '+35.2496174',
        INTPTLON: '-095.8695039',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5266, 34.5507],
            [-99.4743, 34.6233],
            [-99.4039, 34.6305],
            [-99.3554, 34.4489],
            [-99.3987, 34.3758],
            [-99.4952, 34.4055],
            [-99.4821, 34.4282],
            [-99.5266, 34.5507],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022920',
        NAME: 'Olustee Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267112389,
        AWATER: 311755,
        INTPTLAT: '+34.5006330',
        INTPTLON: '-099.4373400',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.986, 36.9995],
            [-98.5447, 36.9988],
            [-98.5403, 36.8625],
            [-98.4947, 36.8209],
            [-98.5494, 36.7608],
            [-98.5406, 36.6738],
            [-98.334, 36.5939],
            [-98.3816, 36.5574],
            [-98.4624, 36.5284],
            [-98.6573, 36.5209],
            [-98.7202, 36.6091],
            [-98.7203, 36.6886],
            [-98.7735, 36.7615],
            [-98.9797, 36.7759],
            [-98.986, 36.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002880',
        NAME: 'Alva Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1638444066,
        AWATER: 2352861,
        INTPTLAT: '+36.7902616',
        INTPTLON: '-098.6931675',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4947, 36.8209],
            [-98.2256, 36.8186],
            [-98.2712, 36.7896],
            [-98.334, 36.5939],
            [-98.5406, 36.6738],
            [-98.5494, 36.7608],
            [-98.4947, 36.8209],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007410',
        NAME: 'Cherokee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465034989,
        AWATER: 313514,
        INTPTLAT: '+36.7291584',
        INTPTLON: '-098.4042002',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3816, 36.5574],
            [-98.334, 36.5939],
            [-98.2712, 36.7896],
            [-98.2256, 36.8186],
            [-98.1098, 36.8408],
            [-97.9208, 36.717],
            [-97.9385, 36.6297],
            [-98.0955, 36.5574],
            [-98.122, 36.4632],
            [-98.1672, 36.4343],
            [-98.3278, 36.4568],
            [-98.3816, 36.5574],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000013',
        NAME: 'Timberlake Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1002622838,
        AWATER: 38584215,
        INTPTLAT: '+36.6428744',
        INTPTLON: '-098.1686412',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8986, 36.4253],
              [-97.8369, 36.4632],
              [-97.8368, 36.4261],
              [-97.8986, 36.4253],
            ],
          ],
          [
            [
              [-98.122, 36.4632],
              [-98.0955, 36.5574],
              [-97.899, 36.5067],
              [-97.8986, 36.4271],
              [-98.0063, 36.398],
              [-98.122, 36.4632],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021780',
        NAME: 'Chisholm Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225954315,
        AWATER: 168353,
        INTPTLAT: '+36.4711100',
        INTPTLON: '-097.9880327',
        ELSDLEA: '',
        UNSDLEA: '21780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3278, 36.4568],
            [-98.1672, 36.4343],
            [-98.1581, 36.3402],
            [-98.2938, 36.2754],
            [-98.3547, 36.2841],
            [-98.3278, 36.4568],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025920',
        NAME: 'Ringwood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307979784,
        AWATER: 1385569,
        INTPTLAT: '+36.3630032',
        INTPTLON: '-098.2571190',
        ELSDLEA: '',
        UNSDLEA: '25920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.976, 35.9846],
            [-95.8776, 36.0174],
            [-95.851, 36.0097],
            [-95.7615, 35.9125],
            [-95.82, 35.8559],
            [-95.8367, 35.856],
            [-95.8777, 35.9011],
            [-95.976, 35.9011],
            [-95.976, 35.9846],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004500',
        NAME: 'Bixby Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182170306,
        AWATER: 10161777,
        INTPTLAT: '+35.9371900',
        INTPTLON: '-095.8726314',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5256, 36.6007],
            [-97.4174, 36.63],
            [-97.3636, 36.6298],
            [-97.3096, 36.5357],
            [-97.3185, 36.4706],
            [-97.2917, 36.3404],
            [-97.4436, 36.3184],
            [-97.4703, 36.3687],
            [-97.4615, 36.4843],
            [-97.5424, 36.5348],
            [-97.5425, 36.5564],
            [-97.5256, 36.6007],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004360',
        NAME: 'Billings Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474278360,
        AWATER: 898161,
        INTPTLAT: '+36.4823826',
        INTPTLON: '-097.4040702',
        ELSDLEA: '',
        UNSDLEA: '04360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0777, 36.181],
            [-97.9789, 36.2106],
            [-97.8269, 36.1955],
            [-97.7381, 36.1811],
            [-97.72, 36.0437],
            [-97.9967, 36.0366],
            [-97.9965, 35.9959],
            [-98.1116, 36.0505],
            [-98.2103, 36.1476],
            [-98.0777, 36.181],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014340',
        NAME: 'Hennessey Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 626780438,
        AWATER: 3521272,
        INTPTLAT: '+36.1114954',
        INTPTLON: '-097.9367446',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5753, 35.1816],
            [-94.4385, 35.2019],
            [-94.4401, 35.1497],
            [-94.4425, 35.0802],
            [-94.5963, 35.0875],
            [-94.6465, 35.1242],
            [-94.6484, 35.1455],
            [-94.5753, 35.1816],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006330',
        NAME: 'Cameron Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191957191,
        AWATER: 1956610,
        INTPTLAT: '+35.1378107',
        INTPTLON: '-094.5216052',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.4547, 34.724],
              [-94.4561, 34.6816],
              [-94.5706, 34.6814],
              [-94.4547, 34.724],
            ],
          ],
          [
            [
              [-94.7562, 34.9073],
              [-94.6725, 34.8978],
              [-94.6727, 34.9129],
              [-94.5578, 34.9419],
              [-94.4475, 34.9419],
              [-94.4478, 34.9339],
              [-94.4507, 34.8401],
              [-94.6569, 34.8637],
              [-94.6568, 34.7683],
              [-94.7278, 34.7683],
              [-94.7562, 34.9073],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014160',
        NAME: 'Heavener Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329451084,
        AWATER: 1908024,
        INTPTLAT: '+34.8829501',
        INTPTLON: '-094.5131766',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8665, 35.106],
            [-94.7524, 35.1456],
            [-94.7259, 35.2094],
            [-94.5754, 35.1985],
            [-94.5753, 35.1816],
            [-94.6484, 35.1455],
            [-94.6818, 35.109],
            [-94.7168, 35.0582],
            [-94.7649, 35.0581],
            [-94.8576, 35.0291],
            [-94.9037, 35.0727],
            [-94.8665, 35.106],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023370',
        NAME: 'Panama Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232107148,
        AWATER: 1254202,
        INTPTLAT: '+35.1188747',
        INTPTLON: '-094.7333232',
        ELSDLEA: '',
        UNSDLEA: '23370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7243, 34.7823],
            [-95.6716, 34.8548],
            [-95.7184, 34.8985],
            [-95.7186, 34.9058],
            [-95.7073, 34.9059],
            [-95.6569, 34.9129],
            [-95.5643, 34.9129],
            [-95.5926, 34.8252],
            [-95.5143, 34.7531],
            [-95.5144, 34.7242],
            [-95.5146, 34.6665],
            [-95.5705, 34.5941],
            [-95.6895, 34.5939],
            [-95.6977, 34.7632],
            [-95.7243, 34.7823],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013620',
        NAME: 'Haileyville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477602805,
        AWATER: 2013529,
        INTPTLAT: '+34.7462379',
        INTPTLON: '-095.6226218',
        ELSDLEA: '',
        UNSDLEA: '13620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0295, 35.9884],
            [-96.0295, 35.9228],
            [-96.0662, 35.9191],
            [-96.083, 35.9229],
            [-96.0839, 35.9739],
            [-96.0295, 35.9884],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016500',
        NAME: 'Kiefer Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34869912,
        AWATER: 396637,
        INTPTLAT: '+35.9556344',
        INTPTLON: '-096.0583025',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2829, 35.5146],
            [-97.2212, 35.5143],
            [-97.1855, 35.4128],
            [-97.2389, 35.3834],
            [-97.2911, 35.3845],
            [-97.3531, 35.4713],
            [-97.2829, 35.5146],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007620',
        NAME: 'Choctaw/Nicoma Park Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150920092,
        AWATER: 91851,
        INTPTLAT: '+35.4533453',
        INTPTLON: '-097.2616595',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4594, 35.4641],
            [-97.4592, 35.4278],
            [-97.4948, 35.4279],
            [-97.4946, 35.4544],
            [-97.4594, 35.4641],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009060',
        NAME: 'Crooked Oak Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11209497,
        AWATER: 0,
        INTPTLAT: '+35.4450565',
        INTPTLON: '-097.4751773',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3957, 35.602],
            [-97.3183, 35.6452],
            [-97.1851, 35.5581],
            [-97.2212, 35.5143],
            [-97.2829, 35.5146],
            [-97.3983, 35.5583],
            [-97.3957, 35.602],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015840',
        NAME: 'Jones Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134053337,
        AWATER: 16652,
        INTPTLAT: '+35.5730488',
        INTPTLON: '-097.2958749',
        ELSDLEA: '',
        UNSDLEA: '15840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4319, 36.8439],
            [-95.2154, 36.8587],
            [-95.0533, 36.8374],
            [-94.9993, 36.8593],
            [-94.9992, 36.8157],
            [-95.0541, 36.7288],
            [-95.2786, 36.7427],
            [-95.4317, 36.757],
            [-95.4317, 36.7716],
            [-95.4319, 36.8439],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004830',
        NAME: 'Bluejacket Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435528541,
        AWATER: 635811,
        INTPTLAT: '+36.7930863',
        INTPTLON: '-095.2074200',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3283, 36.5613],
            [-95.2248, 36.5978],
            [-95.2786, 36.7427],
            [-95.0541, 36.7288],
            [-95.0901, 36.6636],
            [-94.9994, 36.6271],
            [-95.0057, 36.5977],
            [-95.1039, 36.5357],
            [-95.116, 36.4887],
            [-95.3282, 36.5102],
            [-95.3283, 36.5613],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031290',
        NAME: 'Vinita Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445985438,
        AWATER: 1147162,
        INTPTLAT: '+36.6045164',
        INTPTLON: '-095.1672008',
        ELSDLEA: '',
        UNSDLEA: '31290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.0646, 35.9442],
              [-95.0292, 35.9482],
              [-94.9488, 35.9662],
              [-94.8953, 35.9902],
              [-94.9402, 35.9443],
              [-94.8608, 35.8707],
              [-94.8875, 35.7983],
              [-95.0248, 35.8274],
              [-95.0736, 35.8563],
              [-95.0646, 35.9442],
            ],
          ],
          [
            [
              [-95.1277, 35.7688],
              [-95.0556, 35.7546],
              [-94.9852, 35.7115],
              [-95.0003, 35.6384],
              [-95.007, 35.6383],
              [-95.1275, 35.6389],
              [-95.1277, 35.7688],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029380',
        NAME: 'Tahlequah Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347345589,
        AWATER: 14816328,
        INTPTLAT: '+35.8542940',
        INTPTLON: '-094.9654165',
        ELSDLEA: '',
        UNSDLEA: '29380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4513, 35.5583],
            [-97.4427, 35.5583],
            [-97.4374, 35.5296],
            [-97.4591, 35.515],
            [-97.4513, 35.5583],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020080',
        NAME: 'Millwood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23333654,
        AWATER: 219667,
        INTPTLAT: '+35.5385439',
        INTPTLON: '-097.4700030',
        ELSDLEA: '',
        UNSDLEA: '20080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6714, 35.4861],
            [-97.6011, 35.4497],
            [-97.5573, 35.3844],
            [-97.6624, 35.3853],
            [-97.6714, 35.4714],
            [-97.6714, 35.4861],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032370',
        NAME: 'Western Heights Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66596503,
        AWATER: 147129,
        INTPTLAT: '+35.4284450',
        INTPTLON: '-097.6289542',
        ELSDLEA: '',
        UNSDLEA: '32370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7883, 35.0121],
            [-95.7274, 35.058],
            [-95.7275, 35.1522],
            [-95.6038, 35.1452],
            [-95.543, 35.1017],
            [-95.5521, 35.0339],
            [-95.4545, 35.0293],
            [-95.5077, 34.9564],
            [-95.6545, 34.9633],
            [-95.7361, 34.9637],
            [-95.8066, 34.9852],
            [-95.7883, 35.0121],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009090',
        NAME: 'Crowder Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347032383,
        AWATER: 81761257,
        INTPTLAT: '+35.0823654',
        INTPTLON: '-095.6524873',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2439, 35.0579],
            [-95.2085, 35.0579],
            [-95.1211, 34.9702],
            [-95.1033, 34.8982],
            [-95.1473, 34.8104],
            [-95.2515, 34.8103],
            [-95.3042, 34.9356],
            [-95.2438, 34.9728],
            [-95.2439, 35.0579],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023400',
        NAME: 'Panola Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311393034,
        AWATER: 1401877,
        INTPTLAT: '+34.9155102',
        INTPTLON: '-095.2101927',
        ELSDLEA: '',
        UNSDLEA: '23400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1037, 34.7677],
            [-94.9899, 34.797],
            [-94.9899, 34.8551],
            [-94.8848, 34.8548],
            [-94.8848, 34.767],
            [-95.0251, 34.6808],
            [-95.0596, 34.6956],
            [-95.1037, 34.7677],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029400',
        NAME: 'Talihina Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183658709,
        AWATER: 910976,
        INTPTLAT: '+34.7972758',
        INTPTLON: '-094.9805680',
        ELSDLEA: '',
        UNSDLEA: '29400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7381, 36.1811],
            [-97.6934, 36.2033],
            [-97.7024, 36.3256],
            [-97.6309, 36.369],
            [-97.4703, 36.3687],
            [-97.4436, 36.3184],
            [-97.4163, 36.2389],
            [-97.4964, 36.1646],
            [-97.5596, 36.0507],
            [-97.6757, 36.0438],
            [-97.72, 36.0437],
            [-97.7381, 36.1811],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008790',
        NAME: 'Covington-Douglas Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 700057469,
        AWATER: 2020461,
        INTPTLAT: '+36.2101783',
        INTPTLON: '-097.5948815',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.674, 35.726],
            [-97.5937, 35.7549],
            [-97.5584, 35.7768],
            [-97.5496, 35.7767],
            [-97.5229, 35.7759],
            [-97.5047, 35.6901],
            [-97.6119, 35.6021],
            [-97.6652, 35.6019],
            [-97.674, 35.726],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009570',
        NAME: 'Deer Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183678917,
        AWATER: 346243,
        INTPTLAT: '+35.6931316',
        INTPTLON: '-097.5978886',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4163, 36.2389],
            [-97.3628, 36.2461],
            [-97.3006, 36.1592],
            [-97.238, 36.1628],
            [-97.1939, 36.1014],
            [-97.2468, 36.1014],
            [-97.3366, 35.9992],
            [-97.4883, 35.9927],
            [-97.5596, 36.0507],
            [-97.4964, 36.1646],
            [-97.4163, 36.2389],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020910',
        NAME: 'Mulhall-Orlando Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 572518842,
        AWATER: 6676025,
        INTPTLAT: '+36.1051207',
        INTPTLON: '-097.3939176',
        ELSDLEA: '',
        UNSDLEA: '20910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4678, 35.5955],
            [-96.4322, 35.5299],
            [-96.2555, 35.5003],
            [-96.2113, 35.5516],
            [-96.2011, 35.3771],
            [-96.2421, 35.3415],
            [-96.2907, 35.3916],
            [-96.4064, 35.4066],
            [-96.5297, 35.4445],
            [-96.5124, 35.595],
            [-96.4678, 35.5955],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006850',
        NAME: 'Okemah Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 423586492,
        AWATER: 4355959,
        INTPTLAT: '+35.4750817',
        INTPTLON: '-096.3632169',
        ELSDLEA: '',
        UNSDLEA: '06850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4533, 36.2027],
            [-99.256, 36.21],
            [-99.2221, 36.1632],
            [-99.0799, 36.1517],
            [-99.0848, 36.0794],
            [-99.1508, 36.0937],
            [-99.1502, 35.8657],
            [-99.2413, 35.8895],
            [-99.2752, 36.0144],
            [-99.4519, 36.0055],
            [-99.4533, 36.2027],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031170',
        NAME: 'Vici Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761344848,
        AWATER: 2508931,
        INTPTLAT: '+36.0778252',
        INTPTLON: '-099.2896452',
        ELSDLEA: '',
        UNSDLEA: '31170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.379, 34.8423],
            [-99.2981, 34.8998],
            [-99.2485, 34.827],
            [-99.2641, 34.744],
            [-99.387, 34.7251],
            [-99.4047, 34.7251],
            [-99.4234, 34.7837],
            [-99.379, 34.8423],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004650',
        NAME: 'Blair Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150984513,
        AWATER: 714593,
        INTPTLAT: '+34.8013104',
        INTPTLON: '-099.3294198',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0004, 35.0304],
            [-99.8534, 35.0159],
            [-99.8362, 34.908],
            [-99.731, 34.9005],
            [-99.676, 34.7683],
            [-99.6666, 34.5796],
            [-99.7541, 34.5362],
            [-99.8736, 34.5359],
            [-99.9975, 34.5606],
            [-100.0004, 34.6163],
            [-100.0004, 35.0304],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014850',
        NAME: 'Hollis Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1318728681,
        AWATER: 3893730,
        INTPTLAT: '+34.7613349',
        INTPTLON: '-099.8508536',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.676, 34.7683],
            [-99.5281, 34.7397],
            [-99.5104, 34.725],
            [-99.4392, 34.6886],
            [-99.4743, 34.6233],
            [-99.5266, 34.5507],
            [-99.6666, 34.5796],
            [-99.676, 34.7683],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010260',
        NAME: 'Duke Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405975316,
        AWATER: 660097,
        INTPTLAT: '+34.6559223',
        INTPTLON: '-099.5740366',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.387, 34.7251],
              [-99.2641, 34.744],
              [-99.2485, 34.827],
              [-99.1591, 34.8477],
              [-99.173, 34.7553],
              [-99.0957, 34.66],
              [-99.2462, 34.6813],
              [-99.4045, 34.6595],
              [-99.387, 34.7251],
            ],
          ],
          [
            [
              [-99.5281, 34.7397],
              [-99.4234, 34.7837],
              [-99.4047, 34.7251],
              [-99.5104, 34.725],
              [-99.5281, 34.7397],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021180',
        NAME: 'Navajo Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376423872,
        AWATER: 155095,
        INTPTLAT: '+34.7323403',
        INTPTLON: '-099.2512321',
        ELSDLEA: '',
        UNSDLEA: '21180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.998, 36.051],
            [-96.8993, 36.1166],
            [-96.8649, 36.1161],
            [-96.8283, 36.0644],
            [-96.8649, 35.9422],
            [-96.918, 35.9418],
            [-96.998, 36.051],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025950',
        NAME: 'Ripley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214005069,
        AWATER: 4106922,
        INTPTLAT: '+36.0377173',
        INTPTLON: '-096.9009504',
        ELSDLEA: '',
        UNSDLEA: '25950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.238, 36.1628],
            [-97.1409, 36.2099],
            [-97.0344, 36.2098],
            [-96.9977, 36.1634],
            [-96.8993, 36.1166],
            [-96.998, 36.051],
            [-97.1576, 36.0578],
            [-97.1939, 36.1014],
            [-97.238, 36.1628],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028680',
        NAME: 'Stillwater Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309245011,
        AWATER: 10665366,
        INTPTLAT: '+36.1239869',
        INTPTLON: '-097.0825032',
        ELSDLEA: '',
        UNSDLEA: '28680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4891, 36.2931],
            [-95.4354, 36.2928],
            [-95.3817, 36.2567],
            [-95.3458, 36.2493],
            [-95.3325, 36.2493],
            [-95.2455, 36.2201],
            [-95.301, 36.1539],
            [-95.2524, 36.0829],
            [-95.3123, 36.0353],
            [-95.4307, 36.0752],
            [-95.44, 36.2349],
            [-95.5115, 36.2351],
            [-95.4891, 36.2931],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007670',
        NAME: 'Chouteau-Mazie Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340640376,
        AWATER: 9811136,
        INTPTLAT: '+36.1654215',
        INTPTLON: '-095.3605466',
        ELSDLEA: '',
        UNSDLEA: '07670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6935, 36.322],
            [-95.6993, 36.3364],
            [-95.6058, 36.3363],
            [-95.5787, 36.3363],
            [-95.5971, 36.2278],
            [-95.5996, 36.2278],
            [-95.6214, 36.2245],
            [-95.6361, 36.215],
            [-95.6794, 36.2784],
            [-95.6935, 36.322],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007740',
        NAME: 'Claremore Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86335449,
        AWATER: 730450,
        INTPTLAT: '+36.2820423',
        INTPTLON: '-095.6459645',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6774, 36.4232],
            [-95.6146, 36.4911],
            [-95.4356, 36.4085],
            [-95.4356, 36.3906],
            [-95.6774, 36.4232],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012090',
        NAME: 'Foyil Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85738758,
        AWATER: 11052256,
        INTPTLAT: '+36.4309264',
        INTPTLON: '-095.5440920',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6007, 36.2196],
            [-95.5996, 36.2278],
            [-95.5971, 36.2278],
            [-95.5115, 36.2351],
            [-95.44, 36.2349],
            [-95.4307, 36.0752],
            [-95.5231, 36.0461],
            [-95.5581, 36.0966],
            [-95.6007, 36.2196],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015480',
        NAME: 'Inola Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259346560,
        AWATER: 3744158,
        INTPTLAT: '+36.1549128',
        INTPTLON: '-095.5215758',
        ELSDLEA: '',
        UNSDLEA: '15480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9233, 35.1438],
            [-96.8264, 35.2177],
            [-96.7768, 35.1954],
            [-96.7136, 35.135],
            [-96.7225, 35.0683],
            [-96.9146, 35.1162],
            [-96.9233, 35.1438],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019290',
        NAME: 'Maud Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194320636,
        AWATER: 1808779,
        INTPTLAT: '+35.1368499',
        INTPTLON: '-096.8230831',
        ELSDLEA: '',
        UNSDLEA: '19290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7136, 35.135],
            [-96.6393, 35.1957],
            [-96.5974, 35.0579],
            [-96.6132, 35.0212],
            [-96.7225, 35.0683],
            [-96.7136, 35.135],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005130',
        NAME: 'Bowlegs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144383088,
        AWATER: 213729,
        INTPTLAT: '+35.1218816',
        INTPTLON: '-096.6497265',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7771, 35.2973],
            [-96.7153, 35.2973],
            [-96.7151, 35.2466],
            [-96.618, 35.2755],
            [-96.6003, 35.2466],
            [-96.6393, 35.1957],
            [-96.7136, 35.135],
            [-96.7768, 35.1954],
            [-96.7771, 35.2973],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027300',
        NAME: 'Seminole Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149846029,
        AWATER: 596631,
        INTPTLAT: '+35.2247169',
        INTPTLON: '-096.7151950',
        ELSDLEA: '',
        UNSDLEA: '27300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7153, 35.2973],
            [-96.618, 35.2755],
            [-96.7151, 35.2466],
            [-96.7153, 35.2973],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030990',
        NAME: 'Varnum Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73498002,
        AWATER: 309236,
        INTPTLAT: '+35.2805158',
        INTPTLON: '-096.6618562',
        ELSDLEA: '',
        UNSDLEA: '30990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5625, 34.2818],
            [-97.4572, 34.289],
            [-97.4133, 34.3979],
            [-97.3522, 34.3978],
            [-97.3346, 34.3762],
            [-97.2996, 34.26],
            [-97.4482, 34.2599],
            [-97.4482, 34.1947],
            [-97.5626, 34.202],
            [-97.5625, 34.2818],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014130',
        NAME: 'Healdton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252959164,
        AWATER: 1953859,
        INTPTLAT: '+34.2884130',
        INTPTLON: '-097.4304964',
        ELSDLEA: '',
        UNSDLEA: '14130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8713, 34.2686],
            [-98.8015, 34.3264],
            [-98.6794, 34.3261],
            [-98.6097, 34.3337],
            [-98.5624, 34.3186],
            [-98.5613, 34.3041],
            [-98.5614, 34.2957],
            [-98.6268, 34.2962],
            [-98.6186, 34.1566],
            [-98.7508, 34.1242],
            [-98.9234, 34.1863],
            [-98.8713, 34.2686],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013200',
        NAME: 'Grandfield Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 451289776,
        AWATER: 4655108,
        INTPTLAT: '+34.2392187',
        INTPTLON: '-098.7403119',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2219, 34.4283],
            [-99.0804, 34.4571],
            [-98.8797, 34.4497],
            [-98.9234, 34.3919],
            [-98.8738, 34.3422],
            [-98.8713, 34.2686],
            [-98.9233, 34.3195],
            [-99.2119, 34.3054],
            [-99.2069, 34.3383],
            [-99.2219, 34.4283],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012150',
        NAME: 'Frederick Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 532943483,
        AWATER: 1525798,
        INTPTLAT: '+34.3686110',
        INTPTLON: '-099.0204707',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1326, 34.5881],
            [-98.9573, 34.5438],
            [-98.8261, 34.5437],
            [-98.8186, 34.4785],
            [-98.8797, 34.4497],
            [-99.0804, 34.4571],
            [-99.2219, 34.4283],
            [-99.1968, 34.5424],
            [-99.1326, 34.5881],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030030',
        NAME: 'Tipton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 436122438,
        AWATER: 5326371,
        INTPTLAT: '+34.5002921',
        INTPTLON: '-099.0365111',
        ELSDLEA: '',
        UNSDLEA: '30030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2119, 34.3054],
            [-98.9233, 34.3195],
            [-98.8713, 34.2686],
            [-98.9234, 34.1863],
            [-98.9524, 34.2125],
            [-99.028, 34.2053],
            [-99.1898, 34.2144],
            [-99.1984, 34.2606],
            [-99.2119, 34.3054],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009480',
        NAME: 'Davidson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325233714,
        AWATER: 5417305,
        INTPTLAT: '+34.2544365',
        INTPTLON: '-099.0367618',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.337, 35.1534],
            [-98.2919, 35.1173],
            [-98.1704, 35.1603],
            [-98.146, 35.0287],
            [-98.1808, 34.978],
            [-98.2598, 34.9855],
            [-98.3833, 35.0291],
            [-98.337, 35.1534],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003000',
        NAME: 'Anadarko Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282761186,
        AWATER: 1817276,
        INTPTLAT: '+35.0673082',
        INTPTLON: '-098.2537055',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4738, 35.2833],
            [-98.4206, 35.3343],
            [-98.3061, 35.3339],
            [-98.2628, 35.377],
            [-98.1313, 35.3774],
            [-98.1568, 35.305],
            [-98.3154, 35.2545],
            [-98.3459, 35.1606],
            [-98.4512, 35.1607],
            [-98.5091, 35.2186],
            [-98.509, 35.233],
            [-98.4738, 35.2833],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033603',
        NAME: 'Binger-Oney Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381527195,
        AWATER: 6777249,
        INTPTLAT: '+35.2770562',
        INTPTLON: '-098.3328096',
        ELSDLEA: '',
        UNSDLEA: '33603',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5091, 35.2186],
            [-98.4512, 35.1607],
            [-98.3459, 35.1606],
            [-98.337, 35.1534],
            [-98.3833, 35.0291],
            [-98.2598, 34.9855],
            [-98.2601, 34.9565],
            [-98.5059, 34.942],
            [-98.5091, 35.2186],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033602',
        NAME: 'Fort Cobb-Broxton Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390645237,
        AWATER: 8902327,
        INTPTLAT: '+35.0519896',
        INTPTLON: '-098.4232633',
        ELSDLEA: '',
        UNSDLEA: '33602',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.142, 35.3407],
            [-97.0982, 35.3477],
            [-96.9826, 35.3498],
            [-96.9583, 35.2897],
            [-97.142, 35.2681],
            [-97.142, 35.3407],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004230',
        NAME: 'Bethel Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139818880,
        AWATER: 2954796,
        INTPTLAT: '+35.3113672',
        INTPTLON: '-097.0450599',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8596, 35.3805],
              [-96.798, 35.3766],
              [-96.7982, 35.333],
              [-96.8293, 35.3441],
              [-96.8596, 35.3805],
            ],
          ],
          [
            [
              [-96.8776, 35.2826],
              [-96.86, 35.2824],
              [-96.8514, 35.2897],
              [-96.8026, 35.3257],
              [-96.7771, 35.2973],
              [-96.7768, 35.1954],
              [-96.8264, 35.2177],
              [-96.8776, 35.2826],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010500',
        NAME: 'Earlsboro Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81158475,
        AWATER: 125571,
        INTPTLAT: '+35.2751328',
        INTPTLON: '-096.8282033',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.142, 35.2681],
            [-96.9583, 35.2897],
            [-96.9481, 35.2897],
            [-96.8776, 35.2826],
            [-96.8264, 35.2177],
            [-96.9233, 35.1438],
            [-96.948, 35.2171],
            [-97.1416, 35.2103],
            [-97.142, 35.2681],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029610',
        NAME: 'Tecumseh Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221571533,
        AWATER: 498257,
        INTPTLAT: '+35.2329136',
        INTPTLON: '-096.9772624',
        ELSDLEA: '',
        UNSDLEA: '29610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9474, 35.3983],
            [-96.9122, 35.3839],
            [-96.8943, 35.3413],
            [-96.9086, 35.3146],
            [-96.9481, 35.2897],
            [-96.9583, 35.2897],
            [-96.9826, 35.3498],
            [-96.9474, 35.3983],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027570',
        NAME: 'Shawnee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65327263,
        AWATER: 483361,
        INTPTLAT: '+35.3460563',
        INTPTLON: '-096.9431055',
        ELSDLEA: '',
        UNSDLEA: '27570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0982, 35.3477],
            [-97.0269, 35.4636],
            [-96.9737, 35.4636],
            [-96.9651, 35.4055],
            [-96.9474, 35.3983],
            [-96.9826, 35.3498],
            [-97.0982, 35.3477],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009390',
        NAME: 'Dale Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105920834,
        AWATER: 2989354,
        INTPTLAT: '+35.3971379',
        INTPTLON: '-097.0268705',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.142, 35.0728],
            [-97.142, 35.1307],
            [-96.9846, 35.1162],
            [-96.9843, 35.0534],
            [-96.9779, 34.9256],
            [-97.1252, 34.934],
            [-97.1423, 34.9282],
            [-97.142, 35.0728],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031500',
        NAME: 'Wanette Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342093064,
        AWATER: 2606289,
        INTPTLAT: '+35.0288688',
        INTPTLON: '-097.0708633',
        ELSDLEA: '',
        UNSDLEA: '31500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1636, 35.8123],
            [-99.0752, 35.845],
            [-98.8802, 35.856],
            [-98.802, 35.9019],
            [-98.7119, 35.8341],
            [-98.6676, 35.8988],
            [-98.6539, 35.9423],
            [-98.6539, 35.9496],
            [-98.5563, 35.9206],
            [-98.507, 35.9566],
            [-98.5726, 35.8557],
            [-98.5611, 35.7759],
            [-98.5968, 35.6891],
            [-98.6322, 35.6385],
            [-98.7917, 35.624],
            [-98.8627, 35.566],
            [-98.9066, 35.5659],
            [-98.9512, 35.7254],
            [-99.1108, 35.7254],
            [-99.1636, 35.8123],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000015',
        NAME: 'Thomas-Fay-Custer Unified Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1198190428,
        AWATER: 2046599,
        INTPTLAT: '+35.7636727',
        INTPTLON: '-098.8132778',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0848, 36.0794],
            [-98.9559, 36.0797],
            [-98.8929, 36.0214],
            [-98.7697, 36.0289],
            [-98.6539, 35.9496],
            [-98.6539, 35.9423],
            [-98.6676, 35.8988],
            [-98.7119, 35.8341],
            [-98.802, 35.9019],
            [-98.8802, 35.856],
            [-99.0752, 35.845],
            [-99.1502, 35.8657],
            [-99.1508, 36.0937],
            [-99.0848, 36.0794],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029430',
        NAME: 'Taloga Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 901015734,
        AWATER: 6199819,
        INTPTLAT: '+35.9502779',
        INTPTLON: '-098.9412526',
        ELSDLEA: '',
        UNSDLEA: '29430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0858, 35.1673],
            [-95.0264, 35.1992],
            [-94.893, 35.2026],
            [-94.8665, 35.106],
            [-94.9037, 35.0727],
            [-95.0596, 35.0576],
            [-95.0858, 35.1673],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019410',
        NAME: 'McCurtain Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270137902,
        AWATER: 2017343,
        INTPTLAT: '+35.1245406',
        INTPTLON: '-094.9927384',
        ELSDLEA: '',
        UNSDLEA: '19410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3233, 35.189],
            [-95.2613, 35.1886],
            [-95.0858, 35.1673],
            [-95.0596, 35.0576],
            [-95.2085, 35.0579],
            [-95.2439, 35.0579],
            [-95.3492, 35.0585],
            [-95.3233, 35.189],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016620',
        NAME: 'Kinta Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332296482,
        AWATER: 2444728,
        INTPTLAT: '+35.1231179',
        INTPTLON: '-095.2176315',
        ELSDLEA: '',
        UNSDLEA: '16620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.815, 36.2223],
            [-95.6935, 36.322],
            [-95.6794, 36.2784],
            [-95.7438, 36.2636],
            [-95.6992, 36.1891],
            [-95.6361, 36.215],
            [-95.6214, 36.2245],
            [-95.6007, 36.2196],
            [-95.5581, 36.0966],
            [-95.6546, 36.075],
            [-95.6513, 36.1187],
            [-95.6457, 36.1405],
            [-95.7616, 36.119],
            [-95.815, 36.2223],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006870',
        NAME: 'Catoosa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208284855,
        AWATER: 2597529,
        INTPTLAT: '+36.1804253',
        INTPTLON: '-095.7028385',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7394, 34.8399],
            [-96.7201, 34.8575],
            [-96.5904, 34.9092],
            [-96.5105, 34.8786],
            [-96.5031, 34.7528],
            [-96.5031, 34.738],
            [-96.6172, 34.7526],
            [-96.7121, 34.7963],
            [-96.7394, 34.8399],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006060',
        NAME: 'Byng Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300350797,
        AWATER: 3656213,
        INTPTLAT: '+34.8284139',
        INTPTLON: '-096.6112437',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9308, 34.8763],
            [-96.931, 34.8981],
            [-96.8531, 34.9288],
            [-96.737, 34.8629],
            [-96.7394, 34.8399],
            [-96.8186, 34.7675],
            [-96.8182, 34.7168],
            [-96.9325, 34.695],
            [-96.9308, 34.8763],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030960',
        NAME: 'Vanoss Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375335959,
        AWATER: 1585772,
        INTPTLAT: '+34.8216465',
        INTPTLON: '-096.8488304',
        ELSDLEA: '',
        UNSDLEA: '30960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7121, 34.7963],
            [-96.6172, 34.7526],
            [-96.6524, 34.7308],
            [-96.7121, 34.7963],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002430',
        NAME: 'Ada Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35410024,
        AWATER: 201833,
        INTPTLAT: '+34.7697359',
        INTPTLON: '-096.6702448',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.737, 34.8629],
              [-96.7201, 34.8575],
              [-96.7394, 34.8399],
              [-96.737, 34.8629],
            ],
          ],
          [
            [
              [-96.7394, 34.8399],
              [-96.7121, 34.7963],
              [-96.6524, 34.7308],
              [-96.7047, 34.6655],
              [-96.8182, 34.7168],
              [-96.8186, 34.7675],
              [-96.7394, 34.8399],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017190',
        NAME: 'Latta Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129835841,
        AWATER: 1297459,
        INTPTLAT: '+34.7310106',
        INTPTLON: '-096.7190855',
        ELSDLEA: '',
        UNSDLEA: '17190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7918, 36.463],
            [-97.7382, 36.4991],
            [-97.5953, 36.4986],
            [-97.5425, 36.5564],
            [-97.5424, 36.5348],
            [-97.4615, 36.4843],
            [-97.4703, 36.3687],
            [-97.6309, 36.369],
            [-97.7024, 36.3256],
            [-97.7918, 36.463],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012420',
        NAME: 'Garber Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 448226442,
        AWATER: 1594957,
        INTPTLAT: '+36.4264142',
        INTPTLON: '-097.6132887',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9525, 36.3403],
            [-97.8456, 36.3472],
            [-97.8269, 36.1955],
            [-97.9789, 36.2106],
            [-97.9525, 36.3403],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031950',
        NAME: 'Waukomis Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212640688,
        AWATER: 66657,
        INTPTLAT: '+36.2658524',
        INTPTLON: '-097.9032564',
        ELSDLEA: '',
        UNSDLEA: '31950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8096, 36.5975],
            [-95.5799, 36.5976],
            [-95.6146, 36.4911],
            [-95.6774, 36.4232],
            [-95.6058, 36.3363],
            [-95.6993, 36.3364],
            [-95.8127, 36.4236],
            [-95.8096, 36.5975],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023040',
        NAME: 'Oologah-Talala Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417283068,
        AWATER: 40981264,
        INTPTLAT: '+36.4886768',
        INTPTLON: '-095.7125874',
        ELSDLEA: '',
        UNSDLEA: '23040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9595, 36.2858],
            [-95.8842, 36.322],
            [-95.6993, 36.3364],
            [-95.6935, 36.322],
            [-95.815, 36.2223],
            [-95.9161, 36.2221],
            [-95.9595, 36.2858],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023280',
        NAME: 'Owasso Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187420612,
        AWATER: 547507,
        INTPTLAT: '+36.2809331',
        INTPTLON: '-095.8252577',
        ELSDLEA: '',
        UNSDLEA: '23280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6774, 36.4232],
            [-95.4356, 36.3906],
            [-95.4355, 36.3725],
            [-95.4354, 36.2928],
            [-95.4891, 36.2931],
            [-95.5787, 36.3363],
            [-95.6058, 36.3363],
            [-95.6774, 36.4232],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027390',
        NAME: 'Sequoyah Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164213070,
        AWATER: 2467330,
        INTPTLAT: '+36.3583266',
        INTPTLON: '-095.5320027',
        ELSDLEA: '',
        UNSDLEA: '27390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5005, 35.4281],
            [-98.3152, 35.4279],
            [-98.2628, 35.377],
            [-98.3061, 35.3339],
            [-98.4206, 35.3343],
            [-98.4738, 35.2833],
            [-98.5264, 35.2907],
            [-98.5005, 35.4281],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018390',
        NAME: 'Lookeba-Sickles Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273348255,
        AWATER: 1599035,
        INTPTLAT: '+35.3559081',
        INTPTLON: '-098.4121454',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0063, 36.398],
            [-97.8986, 36.4271],
            [-97.8986, 36.4253],
            [-97.8368, 36.4261],
            [-97.8456, 36.3472],
            [-97.9525, 36.3403],
            [-98.0061, 36.3836],
            [-98.0063, 36.398],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010920',
        NAME: 'Enid Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123966939,
        AWATER: 101534,
        INTPTLAT: '+36.3916526',
        INTPTLON: '-097.9177178',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8456, 36.3472],
            [-97.8368, 36.4261],
            [-97.8369, 36.4632],
            [-97.7918, 36.463],
            [-97.7024, 36.3256],
            [-97.6934, 36.2033],
            [-97.7381, 36.1811],
            [-97.8269, 36.1955],
            [-97.8456, 36.3472],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024510',
        NAME: 'Pioneer-Pleasant Vale Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326497359,
        AWATER: 131867,
        INTPTLAT: '+36.2959904',
        INTPTLON: '-097.7798332',
        ELSDLEA: '',
        UNSDLEA: '24510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8696, 36.8181],
            [-96.7524, 36.8908],
            [-96.7498, 36.999],
            [-96.6074, 36.9989],
            [-96.4152, 36.9992],
            [-96.4206, 36.8685],
            [-96.4923, 36.8406],
            [-96.5458, 36.7535],
            [-96.5462, 36.6807],
            [-96.7319, 36.6952],
            [-96.897, 36.6877],
            [-96.9105, 36.7603],
            [-96.8696, 36.8181],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027630',
        NAME: 'Shidler Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1025028230,
        AWATER: 35781387,
        INTPTLAT: '+36.8290634',
        INTPTLON: '-096.6110484',
        ELSDLEA: '',
        UNSDLEA: '27630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4176, 36.7314],
            [-97.2017, 36.7315],
            [-97.2019, 36.5955],
            [-97.3096, 36.5357],
            [-97.3636, 36.6298],
            [-97.4174, 36.63],
            [-97.4176, 36.7314],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030120',
        NAME: 'Tonkawa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326518428,
        AWATER: 3367455,
        INTPTLAT: '+36.6590155',
        INTPTLON: '-097.3138828',
        ELSDLEA: '',
        UNSDLEA: '30120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8195, 36.5078],
            [-99.7839, 36.5367],
            [-99.695, 36.5007],
            [-99.6677, 36.2541],
            [-99.6408, 36.2169],
            [-99.8103, 36.2313],
            [-99.856, 36.4351],
            [-99.8195, 36.5078],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012330',
        NAME: 'Gage Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433110779,
        AWATER: 40422,
        INTPTLAT: '+36.3577707',
        INTPTLON: '-099.7553907',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2522, 35.9878],
            [-95.1718, 36.0312],
            [-95.1363, 36.0164],
            [-95.0646, 35.9442],
            [-95.0736, 35.8563],
            [-95.0779, 35.8417],
            [-95.1272, 35.8418],
            [-95.231, 35.871],
            [-95.283, 35.9082],
            [-95.2522, 35.9878],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015240',
        NAME: 'Hulbert Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214428609,
        AWATER: 22280773,
        INTPTLAT: '+35.9334465',
        INTPTLON: '-095.1672858',
        ELSDLEA: '',
        UNSDLEA: '15240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.699, 34.967],
            [-94.6112, 35.0074],
            [-94.5578, 34.9419],
            [-94.6727, 34.9129],
            [-94.699, 34.967],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015120',
        NAME: 'Howe Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79567094,
        AWATER: 1457852,
        INTPTLAT: '+34.9476136',
        INTPTLON: '-094.6314839',
        ELSDLEA: '',
        UNSDLEA: '15120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7778, 35.3514],
            [-94.7777, 35.3514],
            [-94.648, 35.2912],
            [-94.5791, 35.3288],
            [-94.4864, 35.3468],
            [-94.4728, 35.3758],
            [-94.4356, 35.3861],
            [-94.4332, 35.3658],
            [-94.4648, 35.3178],
            [-94.5754, 35.1985],
            [-94.7259, 35.2094],
            [-94.7792, 35.2457],
            [-94.7778, 35.3514],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028200',
        NAME: 'Spiro Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322705000,
        AWATER: 13328928,
        INTPTLAT: '+35.2667849',
        INTPTLON: '-094.6271351',
        ELSDLEA: '',
        UNSDLEA: '28200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7649, 35.0581],
            [-94.7168, 35.0582],
            [-94.699, 34.967],
            [-94.6727, 34.9129],
            [-94.6725, 34.8978],
            [-94.7562, 34.9073],
            [-94.8045, 34.9263],
            [-94.7649, 35.0581],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032970',
        NAME: 'Wister Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115374790,
        AWATER: 12847700,
        INTPTLAT: '+34.9730167',
        INTPTLON: '-094.7298543',
        ELSDLEA: '',
        UNSDLEA: '32970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7047, 34.6655],
            [-96.6524, 34.7308],
            [-96.6172, 34.7526],
            [-96.5031, 34.738],
            [-96.5121, 34.5127],
            [-96.5139, 34.5054],
            [-96.5924, 34.4559],
            [-96.695, 34.5057],
            [-96.7218, 34.5457],
            [-96.7047, 34.6655],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028800',
        NAME: 'Stonewall Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 518804056,
        AWATER: 3044228,
        INTPTLAT: '+34.5994674',
        INTPTLON: '-096.5961423',
        ELSDLEA: '',
        UNSDLEA: '28800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0681, 34.2426],
            [-97.029, 34.3326],
            [-96.9335, 34.3327],
            [-96.9337, 34.1726],
            [-96.9696, 34.071],
            [-97.0639, 34.0709],
            [-97.1256, 34.1511],
            [-97.0681, 34.2426],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009910',
        NAME: 'Dickson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324734130,
        AWATER: 6549659,
        INTPTLAT: '+34.1959466',
        INTPTLON: '-097.0178446',
        ELSDLEA: '',
        UNSDLEA: '09910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2299, 34.2798],
            [-97.1775, 34.2688],
            [-97.1256, 34.1511],
            [-97.0639, 34.0709],
            [-97.0877, 34.0709],
            [-97.1671, 34.0709],
            [-97.2298, 34.0709],
            [-97.2299, 34.2798],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024240',
        NAME: 'Plainview Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 182133799,
        AWATER: 10247231,
        INTPTLAT: '+34.1470716',
        INTPTLON: '-097.1737800',
        ELSDLEA: '',
        UNSDLEA: '24240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6367, 35.5224],
            [-97.6234, 35.5187],
            [-97.623, 35.5078],
            [-97.6366, 35.5079],
            [-97.6367, 35.5224],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004110',
        NAME: 'Bethany Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1847892,
        AWATER: 0,
        INTPTLAT: '+35.5146583',
        INTPTLON: '-097.6301956',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4427, 35.5583],
              [-97.3983, 35.5583],
              [-97.2829, 35.5146],
              [-97.3531, 35.4713],
              [-97.3975, 35.4786],
              [-97.4064, 35.5224],
              [-97.4374, 35.5296],
              [-97.4427, 35.5583],
            ],
          ],
          [
            [
              [-97.6119, 35.6021],
              [-97.4516, 35.6017],
              [-97.4513, 35.5583],
              [-97.4591, 35.515],
              [-97.4416, 35.4865],
              [-97.4416, 35.4714],
              [-97.4594, 35.4641],
              [-97.4946, 35.4544],
              [-97.4948, 35.4279],
              [-97.4592, 35.4278],
              [-97.4506, 35.3845],
              [-97.5573, 35.3844],
              [-97.6011, 35.4497],
              [-97.5656, 35.5254],
              [-97.6119, 35.6021],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022770',
        NAME: 'Oklahoma City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341220248,
        AWATER: 6812986,
        INTPTLAT: '+35.5022286',
        INTPTLON: '-097.4901827',
        ELSDLEA: '',
        UNSDLEA: '22770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.674, 35.5684],
            [-97.674, 35.5695],
            [-97.674, 35.573],
            [-97.6652, 35.6019],
            [-97.6119, 35.6021],
            [-97.5656, 35.5254],
            [-97.6011, 35.4497],
            [-97.6714, 35.4861],
            [-97.674, 35.5684],
          ],
          [
            [-97.6367, 35.5224],
            [-97.6366, 35.5079],
            [-97.623, 35.5078],
            [-97.6234, 35.5187],
            [-97.6367, 35.5224],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025290',
        NAME: 'Putnam City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105066169,
        AWATER: 5499985,
        INTPTLAT: '+35.5268106',
        INTPTLON: '-097.6264524',
        ELSDLEA: '',
        UNSDLEA: '25290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2996, 34.26],
            [-97.3346, 34.3762],
            [-97.2821, 34.3762],
            [-97.2299, 34.2798],
            [-97.2298, 34.0709],
            [-97.3696, 34.0567],
            [-97.3523, 34.1665],
            [-97.2996, 34.26],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018090',
        NAME: 'Lone Grove Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328484712,
        AWATER: 2179473,
        INTPTLAT: '+34.1948298',
        INTPTLON: '-097.3007130',
        ELSDLEA: '',
        UNSDLEA: '18090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4388, 34.0129],
            [-97.3696, 34.0567],
            [-97.2298, 34.0709],
            [-97.1671, 34.0709],
            [-97.1608, 33.9546],
            [-97.1905, 33.9036],
            [-97.33, 33.8552],
            [-97.4033, 33.8195],
            [-97.5099, 33.9166],
            [-97.4388, 34.0129],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030300',
        NAME: 'Turner Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605153470,
        AWATER: 8770208,
        INTPTLAT: '+33.9579824',
        INTPTLON: '-097.3486333',
        ELSDLEA: '',
        UNSDLEA: '30300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8669, 36.9989],
            [-94.8315, 36.9988],
            [-94.7113, 36.9988],
            [-94.618, 36.9989],
            [-94.618, 36.9959],
            [-94.6183, 36.8936],
            [-94.7295, 36.8932],
            [-94.8194, 36.9436],
            [-94.8669, 36.9989],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025320',
        NAME: 'Quapaw Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197762084,
        AWATER: 1192925,
        INTPTLAT: '+36.9493259',
        INTPTLON: '-094.7320506',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8038, 36.8153],
            [-94.7295, 36.8932],
            [-94.6183, 36.8936],
            [-94.6183, 36.7331],
            [-94.7443, 36.6973],
            [-94.8038, 36.8153],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033240',
        NAME: 'Wyandotte Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274965757,
        AWATER: 14387472,
        INTPTLAT: '+36.8138132',
        INTPTLON: '-094.7119896',
        ELSDLEA: '',
        UNSDLEA: '33240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7036, 36.8486],
            [-101.6189, 36.8488],
            [-101.601, 36.7917],
            [-101.6008, 36.7189],
            [-101.5465, 36.6458],
            [-101.583, 36.4995],
            [-101.6239, 36.4995],
            [-101.6906, 36.4995],
            [-101.7447, 36.6099],
            [-101.7629, 36.7331],
            [-101.7036, 36.8486],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012870',
        NAME: 'Goodwell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481396134,
        AWATER: 1986762,
        INTPTLAT: '+36.6733283',
        INTPTLON: '-101.6524673',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0899, 36.8777],
            [-101.0357, 36.8921],
            [-101.0177, 36.9982],
            [-100.8682, 36.9988],
            [-100.7294, 36.9991],
            [-100.7291, 36.8923],
            [-100.6855, 36.849],
            [-100.7386, 36.8122],
            [-100.7385, 36.7179],
            [-100.8287, 36.6743],
            [-100.9539, 36.6712],
            [-100.9541, 36.7616],
            [-101.0616, 36.7764],
            [-101.0899, 36.8777],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030330',
        NAME: 'Turpin Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 923061284,
        AWATER: 827514,
        INTPTLAT: '+36.8557790',
        INTPTLON: '-100.8779850',
        ELSDLEA: '',
        UNSDLEA: '30330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-101.601, 36.7917],
              [-101.5373, 36.8351],
              [-101.448, 36.8057],
              [-101.3844, 36.7183],
              [-101.2777, 36.7205],
              [-101.2419, 36.7179],
              [-101.2776, 36.6308],
              [-101.3634, 36.587],
              [-101.3668, 36.4996],
              [-101.583, 36.4995],
              [-101.5465, 36.6458],
              [-101.6008, 36.7189],
              [-101.601, 36.7917],
            ],
          ],
          [
            [
              [-101.7037, 36.9946],
              [-101.5952, 36.9951],
              [-101.6189, 36.8488],
              [-101.7036, 36.8486],
              [-101.7037, 36.9946],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013590',
        NAME: 'Guymon Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 930559409,
        AWATER: 3715757,
        INTPTLAT: '+36.6994881',
        INTPTLON: '-101.4895237',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0321, 36.6461],
            [-101.9966, 36.7042],
            [-101.7629, 36.7331],
            [-101.7447, 36.6099],
            [-101.6906, 36.4995],
            [-101.9316, 36.5005],
            [-102.0323, 36.5006],
            [-102.0321, 36.6461],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029820',
        NAME: 'Texhoma Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651109964,
        AWATER: 3607257,
        INTPTLAT: '+36.6113159',
        INTPTLON: '-101.8702053',
        ELSDLEA: '',
        UNSDLEA: '29820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1365, 36.9934],
            [-102.0421, 36.993],
            [-101.7183, 36.9945],
            [-101.7037, 36.9946],
            [-101.7036, 36.8486],
            [-101.7629, 36.7331],
            [-101.9966, 36.7042],
            [-102.0322, 36.8492],
            [-102.1364, 36.8494],
            [-102.1365, 36.9934],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033390',
        NAME: 'Yarbrough Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 969134257,
        AWATER: 4620399,
        INTPTLAT: '+36.8726495',
        INTPTLON: '-101.9544355',
        ELSDLEA: '',
        UNSDLEA: '33390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.961, 36.4601],
            [-96.8332, 36.5072],
            [-96.6895, 36.4239],
            [-96.6202, 36.4099],
            [-96.6019, 36.3823],
            [-96.6239, 36.2108],
            [-96.802, 36.2097],
            [-96.925, 36.2973],
            [-96.961, 36.4601],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023610',
        NAME: 'Pawnee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 749244357,
        AWATER: 5865308,
        INTPTLAT: '+36.3411597',
        INTPTLON: '-096.7749923',
        ELSDLEA: '',
        UNSDLEA: '23610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9904, 35.1165],
            [-98.9165, 35.1969],
            [-98.7908, 35.2477],
            [-98.6767, 35.2549],
            [-98.6679, 35.2548],
            [-98.6638, 34.9305],
            [-98.6199, 34.8843],
            [-98.6198, 34.855],
            [-98.7907, 34.8264],
            [-98.7958, 34.8847],
            [-98.9721, 34.9501],
            [-98.9904, 35.1165],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029886',
        NAME: 'Mountain View-Gotebo Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1055099317,
        AWATER: 5923736,
        INTPTLAT: '+35.0402108',
        INTPTLON: '-098.7893603',
        ELSDLEA: '',
        UNSDLEA: '29886',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0289, 36.1618],
            [-95.0112, 36.2344],
            [-95.0112, 36.2344],
            [-94.9768, 36.234],
            [-94.9587, 36.1615],
            [-94.8331, 36.1907],
            [-94.8864, 36.1109],
            [-95.0113, 36.0892],
            [-95.0289, 36.1618],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022410',
        NAME: 'Oaks-Mission Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143677824,
        AWATER: 61651,
        INTPTLAT: '+36.1532358',
        INTPTLON: '-094.9486858',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9636, 36.5102],
            [-94.8804, 36.4764],
            [-94.6178, 36.4985],
            [-94.5931, 36.3439],
            [-94.6992, 36.3366],
            [-94.7734, 36.2784],
            [-94.9167, 36.3509],
            [-94.9892, 36.396],
            [-95.0296, 36.4379],
            [-94.9636, 36.5102],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015690',
        NAME: 'Jay Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 620415609,
        AWATER: 22976504,
        INTPTLAT: '+36.4164640',
        INTPTLON: '-094.7834463',
        ELSDLEA: '',
        UNSDLEA: '15690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7734, 36.2784],
            [-94.6992, 36.3366],
            [-94.5931, 36.3439],
            [-94.5916, 36.3349],
            [-94.5811, 36.2693],
            [-94.5678, 36.1909],
            [-94.7079, 36.175],
            [-94.7734, 36.2784],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008250',
        NAME: 'Colcord Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217575047,
        AWATER: 183095,
        INTPTLAT: '+36.2567241',
        INTPTLON: '-094.6570405',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0058, 36.2929],
            [-94.9167, 36.3509],
            [-94.7734, 36.2784],
            [-94.7079, 36.175],
            [-94.7082, 36.1617],
            [-94.8689, 36.0694],
            [-94.8864, 36.1109],
            [-94.8331, 36.1907],
            [-94.9768, 36.234],
            [-95.0112, 36.2344],
            [-95.0058, 36.2929],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015990',
        NAME: 'Kansas Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344710622,
        AWATER: 1096821,
        INTPTLAT: '+36.2244280',
        INTPTLON: '-094.8443729',
        ELSDLEA: '',
        UNSDLEA: '15990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8043, 35.4279],
            [-97.6714, 35.4714],
            [-97.6624, 35.3853],
            [-97.663, 35.3366],
            [-97.6793, 35.3348],
            [-97.8218, 35.3352],
            [-97.8043, 35.4279],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021000',
        NAME: 'Mustang Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185874260,
        AWATER: 2745366,
        INTPTLAT: '+35.4001932',
        INTPTLON: '-097.7373802',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8073, 35.7258],
            [-97.674, 35.726],
            [-97.6652, 35.6019],
            [-97.674, 35.573],
            [-97.816, 35.5875],
            [-97.843, 35.6385],
            [-97.8073, 35.7258],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023970',
        NAME: 'Piedmont Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236339981,
        AWATER: 3027751,
        INTPTLAT: '+35.6578533',
        INTPTLON: '-097.7540578',
        ELSDLEA: '',
        UNSDLEA: '23970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1277, 35.4344],
            [-98.0347, 35.4351],
            [-97.8751, 35.4571],
            [-97.8218, 35.428],
            [-97.8043, 35.4279],
            [-97.8218, 35.3352],
            [-97.8757, 35.3559],
            [-97.9551, 35.346],
            [-98.1141, 35.4209],
            [-98.1277, 35.4344],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030630',
        NAME: 'Union City Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217979619,
        AWATER: 1974839,
        INTPTLAT: '+35.3988234',
        INTPTLON: '-097.9279424',
        ELSDLEA: '',
        UNSDLEA: '30630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9325, 34.695],
            [-96.8182, 34.7168],
            [-96.7047, 34.6655],
            [-96.7218, 34.5457],
            [-96.695, 34.5057],
            [-96.7404, 34.5058],
            [-96.8273, 34.506],
            [-96.9149, 34.5352],
            [-96.9323, 34.6368],
            [-96.9325, 34.695],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026280',
        NAME: 'Roff Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411594312,
        AWATER: 1348509,
        INTPTLAT: '+34.6134969',
        INTPTLON: '-096.8182847',
        ELSDLEA: '',
        UNSDLEA: '26280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0027, 34.6368],
            [-96.9323, 34.6368],
            [-96.9149, 34.5352],
            [-96.8273, 34.506],
            [-96.907, 34.5062],
            [-96.933, 34.3327],
            [-96.9335, 34.3327],
            [-97.029, 34.3326],
            [-97.0381, 34.3685],
            [-97.0634, 34.5651],
            [-97.0027, 34.6368],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029160',
        NAME: 'Sulphur Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365455920,
        AWATER: 9036932,
        INTPTLAT: '+34.4977874',
        INTPTLON: '-096.9759093',
        ELSDLEA: '',
        UNSDLEA: '29160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5614, 34.2957],
            [-98.5613, 34.3041],
            [-98.4264, 34.3404],
            [-98.3914, 34.2825],
            [-98.3482, 34.1545],
            [-98.4171, 34.0834],
            [-98.4863, 34.0626],
            [-98.6186, 34.1566],
            [-98.6268, 34.2962],
            [-98.5614, 34.2957],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004350',
        NAME: 'Big Pasture Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513701563,
        AWATER: 8814479,
        INTPTLAT: '+34.2063632',
        INTPTLON: '-098.4857289',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3914, 34.2825],
            [-98.2956, 34.2824],
            [-98.2613, 34.3404],
            [-98.1391, 34.3404],
            [-98.0953, 34.3032],
            [-98.0953, 34.2903],
            [-98.1391, 34.1419],
            [-98.1688, 34.1143],
            [-98.3482, 34.1545],
            [-98.3914, 34.2825],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029640',
        NAME: 'Temple Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444398608,
        AWATER: 16151001,
        INTPTLAT: '+34.2243424',
        INTPTLON: '-098.2352916',
        ELSDLEA: '',
        UNSDLEA: '29640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0953, 34.2903],
            [-98.0601, 34.2742],
            [-98.0166, 34.2532],
            [-97.7722, 34.2818],
            [-97.7373, 34.0898],
            [-97.8595, 34.1293],
            [-97.8946, 34.0857],
            [-98.1207, 34.0798],
            [-98.1391, 34.1419],
            [-98.0953, 34.2903],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031980',
        NAME: 'Waurika Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657985535,
        AWATER: 18540263,
        INTPTLAT: '+34.1945399',
        INTPTLON: '-097.9559038',
        ELSDLEA: '',
        UNSDLEA: '31980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9508, 35.4708],
            [-95.9016, 35.5144],
            [-95.8225, 35.5145],
            [-95.8046, 35.4636],
            [-95.8053, 35.4182],
            [-95.823, 35.42],
            [-95.9508, 35.4708],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009780',
        NAME: 'Dewar Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85856501,
        AWATER: 1970564,
        INTPTLAT: '+35.4711705',
        INTPTLON: '-095.8704535',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7615, 35.9125],
            [-95.7265, 35.9032],
            [-95.647, 35.8638],
            [-95.5933, 35.7839],
            [-95.491, 35.8011],
            [-95.5441, 35.7329],
            [-95.5975, 35.6822],
            [-95.7131, 35.697],
            [-95.7662, 35.748],
            [-95.82, 35.8559],
            [-95.7615, 35.9125],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013950',
        NAME: 'Haskell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 367496271,
        AWATER: 12240420,
        INTPTLAT: '+35.7920979',
        INTPTLON: '-095.6721144',
        ELSDLEA: '',
        UNSDLEA: '13950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9349, 35.5706],
            [-95.9086, 35.6752],
            [-95.9085, 35.6824],
            [-95.7662, 35.748],
            [-95.7131, 35.697],
            [-95.7132, 35.5662],
            [-95.8225, 35.5145],
            [-95.9016, 35.5144],
            [-95.9349, 35.5706],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020310',
        NAME: 'Morris Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357786081,
        AWATER: 1140883,
        INTPTLAT: '+35.6177512',
        INTPTLON: '-095.8145473',
        ELSDLEA: '',
        UNSDLEA: '20310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1293, 35.9229],
            [-96.083, 35.9229],
            [-96.0662, 35.9191],
            [-96.0295, 35.9228],
            [-96.0296, 35.9011],
            [-96.033, 35.8568],
            [-96.1919, 35.857],
            [-96.1293, 35.9229],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020550',
        NAME: 'Mounds Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102453048,
        AWATER: 1158988,
        INTPTLAT: '+35.8800141',
        INTPTLON: '-096.0987526',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9796, 35.7696],
            [-95.9085, 35.6824],
            [-95.9086, 35.6752],
            [-96.0156, 35.6391],
            [-95.9796, 35.7696],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025050',
        NAME: 'Preston Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100671206,
        AWATER: 328362,
        INTPTLAT: '+35.6949452',
        INTPTLON: '-095.9839946',
        ELSDLEA: '',
        UNSDLEA: '25050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.6666, 34.5796],
            [-99.5266, 34.5507],
            [-99.4821, 34.4282],
            [-99.4952, 34.4055],
            [-99.5866, 34.3869],
            [-99.6945, 34.3782],
            [-99.8736, 34.5359],
            [-99.7541, 34.5362],
            [-99.6666, 34.5796],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010680',
        NAME: 'Eldorado Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469003303,
        AWATER: 515048,
        INTPTLAT: '+34.4745676',
        INTPTLON: '-099.6536372',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9208, 36.717],
            [-97.8938, 36.7457],
            [-97.8214, 36.6943],
            [-97.6691, 36.7163],
            [-97.6599, 36.6223],
            [-97.5256, 36.6007],
            [-97.5425, 36.5564],
            [-97.5953, 36.4986],
            [-97.7382, 36.4991],
            [-97.7741, 36.5933],
            [-97.9385, 36.6297],
            [-97.9208, 36.717],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024720',
        NAME: 'Pond Creek-Hunter Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552300605,
        AWATER: 2959511,
        INTPTLAT: '+36.6153927',
        INTPTLON: '-097.7416404',
        ELSDLEA: '',
        UNSDLEA: '24720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.695, 36.5007],
            [-99.5841, 36.4637],
            [-99.4613, 36.3333],
            [-99.5245, 36.2903],
            [-99.5423, 36.203],
            [-99.6408, 36.2169],
            [-99.6677, 36.2541],
            [-99.695, 36.5007],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011430',
        NAME: 'Fargo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456785497,
        AWATER: 54649,
        INTPTLAT: '+36.3625980',
        INTPTLON: '-099.5967175',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1524, 35.4799],
            [-99.0988, 35.5516],
            [-98.9066, 35.5659],
            [-98.8627, 35.566],
            [-98.8523, 35.465],
            [-98.8614, 35.4072],
            [-99.0456, 35.4217],
            [-99.0994, 35.4217],
            [-99.1524, 35.4799],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008070',
        NAME: 'Clinton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 353988175,
        AWATER: 1103807,
        INTPTLAT: '+35.4880544',
        INTPTLON: '-098.9869114',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8225, 35.5145],
            [-95.7132, 35.5662],
            [-95.7131, 35.697],
            [-95.5975, 35.6822],
            [-95.642, 35.6459],
            [-95.5973, 35.5517],
            [-95.6629, 35.4847],
            [-95.8046, 35.4636],
            [-95.8225, 35.5145],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014670',
        NAME: 'Midway Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257030440,
        AWATER: 25209159,
        INTPTLAT: '+35.5715673',
        INTPTLON: '-095.7036612',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3224, 35.8135],
            [-95.2428, 35.8421],
            [-95.1272, 35.8418],
            [-95.0779, 35.8417],
            [-95.0961, 35.8004],
            [-95.0961, 35.7997],
            [-95.1277, 35.7763],
            [-95.2181, 35.7402],
            [-95.2864, 35.7405],
            [-95.3224, 35.8135],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011970',
        NAME: 'Fort Gibson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142253168,
        AWATER: 4891456,
        INTPTLAT: '+35.7923008',
        INTPTLON: '-095.2089268',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3316, 35.6461],
            [-95.3759, 35.6389],
            [-95.3844, 35.6461],
            [-95.3316, 35.6461],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014520',
        NAME: 'Hilldale Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70919490,
        AWATER: 11110,
        INTPTLAT: '+35.6930543',
        INTPTLON: '-095.3788345',
        ELSDLEA: '',
        UNSDLEA: '14520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5441, 35.7329],
            [-95.491, 35.8011],
            [-95.3762, 35.8147],
            [-95.3224, 35.8135],
            [-95.2864, 35.7405],
            [-95.2181, 35.7402],
            [-95.2768, 35.6459],
            [-95.3316, 35.6461],
            [-95.3844, 35.6461],
            [-95.491, 35.646],
            [-95.5441, 35.7329],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020970',
        NAME: 'Muskogee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332879391,
        AWATER: 12919489,
        INTPTLAT: '+35.7343949',
        INTPTLON: '-095.3809890',
        ELSDLEA: '',
        UNSDLEA: '20970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3976, 35.5519],
            [-95.26, 35.6171],
            [-95.2248, 35.5993],
            [-95.2297, 35.4279],
            [-95.3448, 35.4281],
            [-95.3448, 35.5222],
            [-95.3976, 35.5519],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031650',
        NAME: 'Warner Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211661301,
        AWATER: 5954767,
        INTPTLAT: '+35.5305582',
        INTPTLON: '-095.3010741',
        ELSDLEA: '',
        UNSDLEA: '31650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9091, 36.8226],
            [-94.8038, 36.8153],
            [-94.7443, 36.6973],
            [-94.7736, 36.6678],
            [-94.8645, 36.6705],
            [-94.9091, 36.7139],
            [-94.9091, 36.8226],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011250',
        NAME: 'Fairland Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175882389,
        AWATER: 12530182,
        INTPTLAT: '+36.7488971',
        INTPTLON: '-094.8269148',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9993, 36.8593],
            [-94.955, 36.9028],
            [-94.8194, 36.9436],
            [-94.7295, 36.8932],
            [-94.8038, 36.8153],
            [-94.9091, 36.8226],
            [-94.9992, 36.8157],
            [-94.9993, 36.8593],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019860',
        NAME: 'Miami Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200011211,
        AWATER: 2308083,
        INTPTLAT: '+36.8702256',
        INTPTLON: '-094.8745015',
        ELSDLEA: '',
        UNSDLEA: '19860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2768, 35.6459],
            [-95.2181, 35.7402],
            [-95.1277, 35.7763],
            [-95.1277, 35.7688],
            [-95.1275, 35.6389],
            [-95.007, 35.6383],
            [-95.0261, 35.4929],
            [-95.0614, 35.4651],
            [-95.0623, 35.4651],
            [-95.027, 35.4932],
            [-95.0234, 35.6297],
            [-95.1275, 35.6386],
            [-95.1725, 35.5955],
            [-95.2248, 35.5993],
            [-95.26, 35.6171],
            [-95.2768, 35.6459],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005280',
        NAME: 'Braggs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185921093,
        AWATER: 14685430,
        INTPTLAT: '+35.6727710',
        INTPTLON: '-095.1837948',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0085, 34.4131],
              [-97.995, 34.478],
              [-97.8161, 34.4709],
              [-97.7724, 34.4054],
              [-97.7722, 34.2892],
              [-97.7722, 34.2818],
              [-98.0166, 34.2532],
              [-98.0601, 34.2742],
              [-97.9998, 34.2968],
              [-98.0085, 34.4131],
            ],
          ],
          [
            [
              [-98.1391, 34.3404],
              [-98.1319, 34.3405],
              [-98.0953, 34.3032],
              [-98.1391, 34.3404],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008460',
        NAME: 'Comanche Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395749235,
        AWATER: 13816472,
        INTPTLAT: '+34.3581652',
        INTPTLON: '-097.9212025',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.8053, 35.4182],
              [-95.8046, 35.4636],
              [-95.6629, 35.4847],
              [-95.5973, 35.5517],
              [-95.5446, 35.5517],
              [-95.3976, 35.5519],
              [-95.3448, 35.5222],
              [-95.3448, 35.4281],
              [-95.3447, 35.2931],
              [-95.4161, 35.3191],
              [-95.4513, 35.3483],
              [-95.6121, 35.3559],
              [-95.6562, 35.4006],
              [-95.8053, 35.4182],
            ],
          ],
          [
            [
              [-95.8754, 35.4057],
              [-95.8342, 35.4055],
              [-95.8222, 35.3721],
              [-95.8612, 35.3767],
              [-95.8754, 35.4057],
            ],
          ],
          [
            [
              [-95.9108, 35.4129],
              [-95.8754, 35.4095],
              [-95.9064, 35.3985],
              [-95.9107, 35.3985],
              [-95.9108, 35.4129],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007350',
        NAME: 'Checotah Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 646553155,
        AWATER: 85742970,
        INTPTLAT: '+35.4454715',
        INTPTLON: '-095.5293198',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3448, 35.4281],
            [-95.2297, 35.4279],
            [-95.1149, 35.3786],
            [-95.2029, 35.2992],
            [-95.2737, 35.2829],
            [-95.3447, 35.2931],
            [-95.3448, 35.4281],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024840',
        NAME: 'Porum Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253854878,
        AWATER: 7985576,
        INTPTLAT: '+35.3557245',
        INTPTLON: '-095.2475200',
        ELSDLEA: '',
        UNSDLEA: '24840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4436, 36.3184],
            [-97.2917, 36.3404],
            [-97.3185, 36.4706],
            [-97.1931, 36.3839],
            [-97.1575, 36.3114],
            [-97.1409, 36.2099],
            [-97.238, 36.1628],
            [-97.3006, 36.1592],
            [-97.3628, 36.2461],
            [-97.4163, 36.2389],
            [-97.4436, 36.3184],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023850',
        NAME: 'Perry Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507765668,
        AWATER: 7939028,
        INTPTLAT: '+36.2839385',
        INTPTLON: '-097.2602413',
        ELSDLEA: '',
        UNSDLEA: '23850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3644, 35.3782],
            [-99.3641, 35.5019],
            [-99.2797, 35.5662],
            [-99.1524, 35.4799],
            [-99.0994, 35.4217],
            [-99.2496, 35.3785],
            [-99.2584, 35.3059],
            [-99.3645, 35.2841],
            [-99.3644, 35.3782],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006510',
        NAME: 'Canute Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400338415,
        AWATER: 3975113,
        INTPTLAT: '+35.4281401',
        INTPTLON: '-099.2641552',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.586, 35.7981],
            [-99.4818, 35.7977],
            [-99.2876, 35.7181],
            [-99.2797, 35.5662],
            [-99.3641, 35.5019],
            [-99.4699, 35.509],
            [-99.4695, 35.5379],
            [-99.5536, 35.5955],
            [-99.586, 35.7981],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013650',
        NAME: 'Hammon Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 641386234,
        AWATER: 3023598,
        INTPTLAT: '+35.6501466',
        INTPTLON: '-099.4201407',
        ELSDLEA: '',
        UNSDLEA: '13650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5968, 35.6891],
            [-98.4913, 35.5592],
            [-98.4388, 35.5515],
            [-98.4975, 35.508],
            [-98.5005, 35.4281],
            [-98.5264, 35.2907],
            [-98.4738, 35.2833],
            [-98.509, 35.233],
            [-98.6236, 35.2764],
            [-98.6324, 35.2764],
            [-98.6323, 35.2836],
            [-98.6233, 35.4209],
            [-98.6322, 35.6385],
            [-98.5968, 35.6891],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000032',
        NAME: 'Hydro-Eakly Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486629186,
        AWATER: 1344086,
        INTPTLAT: '+35.4570476',
        INTPTLON: '-098.5619735',
        ELSDLEA: '',
        UNSDLEA: '00032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.4699, 35.509],
              [-99.3641, 35.5019],
              [-99.3644, 35.3782],
              [-99.4699, 35.509],
            ],
          ],
          [
            [
              [-99.4695, 35.5379],
              [-99.4699, 35.509],
              [-99.5759, 35.4939],
              [-99.4695, 35.5379],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010740',
        NAME: 'Elk City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163004854,
        AWATER: 863393,
        INTPTLAT: '+35.4177802',
        INTPTLON: '-099.3869525',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2876, 35.7181],
            [-99.2169, 35.8121],
            [-99.1636, 35.8123],
            [-99.1108, 35.7254],
            [-98.9512, 35.7254],
            [-98.9066, 35.5659],
            [-99.0988, 35.5516],
            [-99.1524, 35.4799],
            [-99.2797, 35.5662],
            [-99.2876, 35.7181],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003120',
        NAME: 'Arapaho Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 734299735,
        AWATER: 29621546,
        INTPTLAT: '+35.6458867',
        INTPTLON: '-099.1196382',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5876, 35.8579],
            [-99.4519, 36.0055],
            [-99.2752, 36.0144],
            [-99.2413, 35.8895],
            [-99.1502, 35.8657],
            [-99.0752, 35.845],
            [-99.1636, 35.8123],
            [-99.2169, 35.8121],
            [-99.2876, 35.7181],
            [-99.4818, 35.7977],
            [-99.586, 35.7981],
            [-99.5876, 35.8579],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017370',
        NAME: 'Leedey Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 820011524,
        AWATER: 6118524,
        INTPTLAT: '+35.8709292',
        INTPTLON: '-099.3674221',
        ELSDLEA: '',
        UNSDLEA: '17370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1841, 35.0657],
            [-99.1313, 35.1167],
            [-98.9904, 35.1165],
            [-98.9721, 34.9501],
            [-99.0872, 34.9505],
            [-99.1668, 34.8851],
            [-99.1841, 35.0657],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014700',
        NAME: 'Hobart Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352916701,
        AWATER: 990788,
        INTPTLAT: '+35.0194435',
        INTPTLON: '-099.0778096',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5031, 34.738],
            [-96.5031, 34.7528],
            [-96.3016, 34.7232],
            [-96.3978, 34.6362],
            [-96.3805, 34.5199],
            [-96.5121, 34.5127],
            [-96.5031, 34.738],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030270',
        NAME: 'Tupelo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303025265,
        AWATER: 2733458,
        INTPTLAT: '+34.6444065',
        INTPTLON: '-096.4284775',
        ELSDLEA: '',
        UNSDLEA: '30270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5105, 34.8786],
            [-96.4781, 34.9164],
            [-96.3019, 34.9564],
            [-96.2928, 34.7523],
            [-96.3016, 34.7232],
            [-96.5031, 34.7528],
            [-96.5105, 34.8786],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002760',
        NAME: 'Allen Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407039282,
        AWATER: 1764824,
        INTPTLAT: '+34.8436534',
        INTPTLON: '-096.3979655',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6224, 36.1266],
            [-96.5122, 36.1411],
            [-96.5122, 36.1172],
            [-96.5391, 36.0465],
            [-96.6403, 36.0511],
            [-96.6224, 36.1266],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022560',
        NAME: 'Oilton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99928046,
        AWATER: 1434259,
        INTPTLAT: '+36.0823407',
        INTPTLON: '-096.5609609',
        ELSDLEA: '',
        UNSDLEA: '22560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4594, 35.4641],
              [-97.4416, 35.4714],
              [-97.3975, 35.4786],
              [-97.3531, 35.4713],
              [-97.2911, 35.3845],
              [-97.2389, 35.3834],
              [-97.239, 35.3409],
              [-97.3461, 35.3412],
              [-97.4506, 35.3845],
              [-97.4592, 35.4278],
              [-97.4594, 35.4641],
            ],
          ],
          [
            [
              [-97.4591, 35.515],
              [-97.4374, 35.5296],
              [-97.4064, 35.5224],
              [-97.4416, 35.4865],
              [-97.4591, 35.515],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019950',
        NAME: 'Midwest City-Del City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179677755,
        AWATER: 1024172,
        INTPTLAT: '+35.4133833',
        INTPTLON: '-097.3640747',
        ELSDLEA: '',
        UNSDLEA: '19950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.239, 35.3409],
              [-97.2391, 35.2974],
              [-97.344, 35.2979],
              [-97.3461, 35.3412],
              [-97.239, 35.3409],
            ],
          ],
          [
            [
              [-97.6624, 35.3853],
              [-97.5573, 35.3844],
              [-97.4506, 35.3845],
              [-97.3461, 35.3412],
              [-97.396, 35.2762],
              [-97.5561, 35.2763],
              [-97.663, 35.3366],
              [-97.6624, 35.3853],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020250',
        NAME: 'Moore Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312060228,
        AWATER: 11611240,
        INTPTLAT: '+35.3362972',
        INTPTLON: '-097.4750778',
        ELSDLEA: '',
        UNSDLEA: '20250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3578, 35.0803],
            [-97.142, 35.0728],
            [-97.1423, 34.9282],
            [-97.3429, 34.9715],
            [-97.3578, 35.0803],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017580',
        NAME: 'Lexington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266737800,
        AWATER: 4144608,
        INTPTLAT: '+35.0072458',
        INTPTLON: '-097.2473684',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9462, 35.4045],
            [-94.9446, 35.4025],
            [-94.8371, 35.3695],
            [-94.8291, 35.3617],
            [-94.7963, 35.3265],
            [-94.7816, 35.3472],
            [-94.7778, 35.3514],
            [-94.7792, 35.2457],
            [-94.893, 35.2026],
            [-95.0264, 35.1992],
            [-95.0351, 35.2969],
            [-94.9462, 35.4045],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016350',
        NAME: 'Keota Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278741674,
        AWATER: 73817121,
        INTPTLAT: '+35.2837242',
        INTPTLON: '-094.9099240',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.2029, 35.2992],
              [-95.1149, 35.3786],
              [-95.0499, 35.4589],
              [-94.9462, 35.4045],
              [-95.0351, 35.2969],
              [-95.0264, 35.1992],
              [-95.0858, 35.1673],
              [-95.2613, 35.1886],
              [-95.1853, 35.2029],
              [-95.2029, 35.2992],
            ],
          ],
          [
            [
              [-95.4513, 35.296],
              [-95.4161, 35.3191],
              [-95.3447, 35.2931],
              [-95.2737, 35.2829],
              [-95.3494, 35.2028],
              [-95.3802, 35.2534],
              [-95.381, 35.2535],
              [-95.4509, 35.2682],
              [-95.4513, 35.296],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028650',
        NAME: 'Stigler Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 508284359,
        AWATER: 48393361,
        INTPTLAT: '+35.2974340',
        INTPTLON: '-095.1442289',
        ELSDLEA: '',
        UNSDLEA: '28650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0004, 35.8809],
            [-99.9099, 35.927],
            [-99.8717, 35.8814],
            [-99.8981, 35.7767],
            [-99.8009, 35.7254],
            [-99.7876, 35.5091],
            [-100.0004, 35.5525],
            [-100.0004, 35.7462],
            [-100.0004, 35.8809],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025800',
        NAME: 'Reydon Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638535366,
        AWATER: 2743606,
        INTPTLAT: '+35.7043343',
        INTPTLON: '-099.9163238',
        ELSDLEA: '',
        UNSDLEA: '25800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5876, 35.8579],
            [-99.586, 35.7981],
            [-99.5536, 35.5955],
            [-99.4695, 35.5379],
            [-99.5759, 35.4939],
            [-99.5934, 35.4506],
            [-99.7875, 35.4874],
            [-99.7876, 35.5091],
            [-99.8009, 35.7254],
            [-99.8981, 35.7767],
            [-99.8717, 35.8814],
            [-99.9099, 35.927],
            [-99.8934, 35.9853],
            [-99.7171, 35.8598],
            [-99.5876, 35.8579],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007500',
        NAME: 'Cheyenne Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1152045916,
        AWATER: 6775376,
        INTPTLAT: '+35.7085544',
        INTPTLON: '-099.7415716',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8534, 35.0159],
            [-99.712, 35.1459],
            [-99.5886, 35.0879],
            [-99.571, 35.0298],
            [-99.379, 34.8423],
            [-99.4234, 34.7837],
            [-99.5281, 34.7397],
            [-99.676, 34.7683],
            [-99.731, 34.9005],
            [-99.8362, 34.908],
            [-99.8534, 35.0159],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018780',
        NAME: 'Mangum Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1017562459,
        AWATER: 1022902,
        INTPTLAT: '+34.9251478',
        INTPTLON: '-099.6293698',
        ELSDLEA: '',
        UNSDLEA: '18780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8051, 35.3171],
            [-99.7875, 35.4874],
            [-99.5934, 35.4506],
            [-99.5233, 35.3494],
            [-99.5681, 35.1697],
            [-99.501, 35.1169],
            [-99.5886, 35.0879],
            [-99.712, 35.1459],
            [-99.7177, 35.2334],
            [-99.8051, 35.3171],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027060',
        NAME: 'Sayre Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 706573612,
        AWATER: 1419443,
        INTPTLAT: '+35.2980777',
        INTPTLON: '-099.6582544',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0004, 35.2987],
            [-99.924, 35.2729],
            [-99.8051, 35.3171],
            [-99.7177, 35.2334],
            [-99.712, 35.1459],
            [-99.8534, 35.0159],
            [-100.0004, 35.0304],
            [-100.0004, 35.1393],
            [-100.0004, 35.2987],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011040',
        NAME: 'Erick Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696487736,
        AWATER: 342574,
        INTPTLAT: '+35.1669364',
        INTPTLON: '-099.8656892',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9997, 35.4284],
            [-100.0004, 35.5525],
            [-99.7876, 35.5091],
            [-99.7875, 35.4874],
            [-99.8051, 35.3171],
            [-99.924, 35.2729],
            [-100.0004, 35.2987],
            [-100.0004, 35.299],
            [-99.9997, 35.4284],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029310',
        NAME: 'Sweetwater Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499168260,
        AWATER: 357509,
        INTPTLAT: '+35.4136603',
        INTPTLON: '-099.9026897',
        ELSDLEA: '',
        UNSDLEA: '29310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5934, 35.4506],
            [-99.5759, 35.4939],
            [-99.4699, 35.509],
            [-99.3644, 35.3782],
            [-99.3645, 35.2841],
            [-99.3602, 35.1895],
            [-99.4484, 35.1896],
            [-99.4093, 35.1145],
            [-99.501, 35.1169],
            [-99.5681, 35.1697],
            [-99.5233, 35.3494],
            [-99.5934, 35.4506],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019800',
        NAME: 'Merritt Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625299246,
        AWATER: 2963367,
        INTPTLAT: '+35.3139232',
        INTPTLON: '-099.4807498',
        ELSDLEA: '',
        UNSDLEA: '19800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.491, 35.646],
            [-95.3844, 35.6461],
            [-95.3759, 35.6389],
            [-95.3316, 35.6461],
            [-95.2768, 35.6459],
            [-95.26, 35.6171],
            [-95.3976, 35.5519],
            [-95.5446, 35.5517],
            [-95.491, 35.646],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022830',
        NAME: 'Oktaha Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174438677,
        AWATER: 1469082,
        INTPTLAT: '+35.6040779',
        INTPTLON: '-095.4172103',
        ELSDLEA: '',
        UNSDLEA: '22830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2248, 35.5993],
            [-95.1725, 35.5955],
            [-95.1712, 35.5548],
            [-95.0623, 35.4651],
            [-95.0614, 35.4651],
            [-95.0499, 35.4589],
            [-95.1149, 35.3786],
            [-95.2297, 35.4279],
            [-95.2248, 35.5993],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032130',
        NAME: 'Webbers Falls Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217427406,
        AWATER: 13776297,
        INTPTLAT: '+35.4904180',
        INTPTLON: '-095.1729784',
        ELSDLEA: '',
        UNSDLEA: '32130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9378, 36.4237],
            [-95.8127, 36.4236],
            [-95.6993, 36.3364],
            [-95.8842, 36.322],
            [-95.9381, 36.3367],
            [-95.9378, 36.4237],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008370',
        NAME: 'Collinsville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164867054,
        AWATER: 631307,
        INTPTLAT: '+36.3751284',
        INTPTLON: '-095.8374954',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6192, 35.8643],
            [-96.4943, 35.901],
            [-96.4495, 35.8499],
            [-96.4586, 35.7695],
            [-96.5209, 35.653],
            [-96.6206, 35.6571],
            [-96.6192, 35.8643],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009750',
        NAME: 'Depew Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333155008,
        AWATER: 5049200,
        INTPTLAT: '+35.7796525',
        INTPTLON: '-096.5466805',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1928, 35.5589],
            [-96.1926, 35.6391],
            [-96.1925, 35.7915],
            [-96.1919, 35.857],
            [-96.033, 35.8568],
            [-95.9797, 35.8135],
            [-95.9796, 35.7696],
            [-96.0156, 35.6391],
            [-96.1398, 35.6247],
            [-96.1215, 35.5519],
            [-96.1928, 35.5589],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003810',
        NAME: 'Beggs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439766912,
        AWATER: 2128947,
        INTPTLAT: '+35.7336670',
        INTPTLON: '-096.1047292',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0877, 35.464],
            [-95.9814, 35.4708],
            [-95.9508, 35.4708],
            [-95.823, 35.42],
            [-95.8342, 35.4055],
            [-95.8754, 35.4057],
            [-95.8754, 35.4095],
            [-95.9108, 35.4129],
            [-95.9608, 35.3768],
            [-95.9813, 35.3768],
            [-96.0256, 35.377],
            [-96.0877, 35.464],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014370',
        NAME: 'Henryetta Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123855240,
        AWATER: 1196855,
        INTPTLAT: '+35.4293503',
        INTPTLON: '-095.9803130',
        ELSDLEA: '',
        UNSDLEA: '14370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0156, 35.6391],
            [-95.9086, 35.6752],
            [-95.9349, 35.5706],
            [-95.9816, 35.5363],
            [-96.0878, 35.5521],
            [-96.1215, 35.5519],
            [-96.1398, 35.6247],
            [-96.0156, 35.6391],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022800',
        NAME: 'Okmulgee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194494245,
        AWATER: 5085112,
        INTPTLAT: '+35.6018414',
        INTPTLON: '-096.0212834',
        ELSDLEA: '',
        UNSDLEA: '22800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9816, 35.5363],
            [-95.9349, 35.5706],
            [-95.9016, 35.5144],
            [-95.9508, 35.4708],
            [-95.9814, 35.4708],
            [-95.9816, 35.5363],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027090',
        NAME: 'Schulter Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68297723,
        AWATER: 145574,
        INTPTLAT: '+35.5233199',
        INTPTLON: '-095.9384229',
        ELSDLEA: '',
        UNSDLEA: '27090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8087, 34.7906],
            [-98.7907, 34.8264],
            [-98.6198, 34.855],
            [-98.5621, 34.8553],
            [-98.5448, 34.7608],
            [-98.4834, 34.5581],
            [-98.4833, 34.529],
            [-98.7293, 34.5219],
            [-98.6679, 34.6159],
            [-98.6681, 34.6817],
            [-98.7559, 34.6963],
            [-98.8087, 34.7906],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006120',
        NAME: 'Cache Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 705281167,
        AWATER: 2599727,
        INTPTLAT: '+34.6978424',
        INTPTLON: '-098.6392806',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4834, 34.5581],
            [-98.4393, 34.551],
            [-98.4221, 34.5509],
            [-98.4046, 34.5581],
            [-98.3871, 34.5581],
            [-98.3782, 34.5581],
            [-98.2383, 34.5289],
            [-98.2383, 34.5143],
            [-98.3393, 34.4272],
            [-98.4692, 34.4131],
            [-98.4615, 34.4853],
            [-98.4616, 34.5076],
            [-98.4833, 34.529],
            [-98.4834, 34.5581],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012630',
        NAME: 'Geronimo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216869909,
        AWATER: 513082,
        INTPTLAT: '+34.4863383',
        INTPTLON: '-098.3731370',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8087, 34.7906],
            [-98.7559, 34.6963],
            [-98.6681, 34.6817],
            [-98.6679, 34.6159],
            [-98.7293, 34.5219],
            [-98.8186, 34.4785],
            [-98.8261, 34.5437],
            [-98.8087, 34.7906],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015420',
        NAME: 'Indiahoma Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317648956,
        AWATER: 731161,
        INTPTLAT: '+34.6229457',
        INTPTLON: '-098.7688653',
        ELSDLEA: '',
        UNSDLEA: '15420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5475, 34.9496],
            [-97.4412, 34.9863],
            [-97.3429, 34.9715],
            [-97.1423, 34.9282],
            [-97.1252, 34.934],
            [-97.1423, 34.8551],
            [-97.1427, 34.8551],
            [-97.1951, 34.8552],
            [-97.3533, 34.8696],
            [-97.4855, 34.877],
            [-97.5475, 34.9496],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032010',
        NAME: 'Wayne Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336875772,
        AWATER: 6977843,
        INTPTLAT: '+34.9061843',
        INTPTLON: '-097.3418942',
        ELSDLEA: '',
        UNSDLEA: '32010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4031, 35.0878],
            [-97.3578, 35.0803],
            [-97.3429, 34.9715],
            [-97.4412, 34.9863],
            [-97.4031, 35.0878],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025230',
        NAME: 'Purcell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104804575,
        AWATER: 3030807,
        INTPTLAT: '+35.0112041',
        INTPTLON: '-097.3964018',
        ELSDLEA: '',
        UNSDLEA: '25230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5295, 35.1459],
            [-97.4335, 35.1453],
            [-97.4031, 35.0878],
            [-97.4412, 34.9863],
            [-97.5475, 34.9496],
            [-97.5648, 34.9715],
            [-97.5298, 35.0589],
            [-97.6, 35.0951],
            [-97.5295, 35.1459],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031710',
        NAME: 'Washington Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246195474,
        AWATER: 2816199,
        INTPTLAT: '+35.0613995',
        INTPTLON: '-097.4922770',
        ELSDLEA: '',
        UNSDLEA: '31710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8627, 35.566],
            [-98.7917, 35.624],
            [-98.6322, 35.6385],
            [-98.6233, 35.4209],
            [-98.8523, 35.465],
            [-98.8627, 35.566],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032070',
        NAME: 'Weatherford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398233527,
        AWATER: 358743,
        INTPTLAT: '+35.5328922',
        INTPTLON: '-098.7384573',
        ELSDLEA: '',
        UNSDLEA: '32070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9385, 36.6297],
            [-97.7741, 36.5933],
            [-97.7382, 36.4991],
            [-97.7918, 36.463],
            [-97.8369, 36.4632],
            [-97.8986, 36.4253],
            [-97.8986, 36.4271],
            [-97.899, 36.5067],
            [-98.0955, 36.5574],
            [-97.9385, 36.6297],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016740',
        NAME: 'Kremlin-Hillsdale Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341124312,
        AWATER: 332590,
        INTPTLAT: '+36.5428881',
        INTPTLON: '-097.8985627',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1463, 36.9982],
            [-97.8023, 36.9987],
            [-97.6148, 36.9988],
            [-97.5881, 36.9344],
            [-97.6153, 36.7453],
            [-97.6691, 36.7163],
            [-97.8214, 36.6943],
            [-97.8938, 36.7457],
            [-97.9208, 36.717],
            [-98.1098, 36.8408],
            [-98.1463, 36.9982],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019620',
        NAME: 'Medford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1311642516,
        AWATER: 1294809,
        INTPTLAT: '+36.8756539',
        INTPTLON: '-097.8490531',
        ELSDLEA: '',
        UNSDLEA: '19620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.3533, 36.9945],
            [-102.3252, 36.9943],
            [-102.1365, 36.9934],
            [-102.1364, 36.8494],
            [-102.0322, 36.8492],
            [-101.9966, 36.7042],
            [-102.0321, 36.6461],
            [-102.2837, 36.6466],
            [-102.356, 36.6972],
            [-102.3533, 36.9945],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016410',
        NAME: 'Keyes Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 961422664,
        AWATER: 1809473,
        INTPTLAT: '+36.8133783',
        INTPTLON: '-102.2032149',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6691, 36.7163],
            [-97.6153, 36.7453],
            [-97.5881, 36.9344],
            [-97.6148, 36.9988],
            [-97.4729, 36.9987],
            [-97.4623, 36.9987],
            [-97.4624, 36.8833],
            [-97.4623, 36.7348],
            [-97.4176, 36.7314],
            [-97.4174, 36.63],
            [-97.5256, 36.6007],
            [-97.6599, 36.6223],
            [-97.6691, 36.7163],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017020',
        NAME: 'Deer Creek-Lamont Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 645547004,
        AWATER: 2030584,
        INTPTLAT: '+36.7792914',
        INTPTLON: '-097.5401748',
        ELSDLEA: '',
        UNSDLEA: '17020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1416, 35.2103],
            [-96.948, 35.2171],
            [-96.9233, 35.1438],
            [-96.9146, 35.1162],
            [-96.9312, 35.0728],
            [-96.9843, 35.0534],
            [-96.9846, 35.1162],
            [-97.142, 35.1307],
            [-97.1421, 35.1668],
            [-97.1416, 35.2103],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018660',
        NAME: 'Macomb Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216021912,
        AWATER: 262118,
        INTPTLAT: '+35.1646054',
        INTPTLON: '-097.0361185',
        ELSDLEA: '',
        UNSDLEA: '18660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5143, 34.7531],
            [-95.322, 34.7824],
            [-95.2515, 34.8103],
            [-95.1473, 34.8104],
            [-95.1037, 34.7677],
            [-95.0596, 34.6956],
            [-95.2001, 34.6807],
            [-95.4448, 34.6808],
            [-95.5144, 34.7242],
            [-95.5143, 34.7531],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005820',
        NAME: 'Buffalo Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392076144,
        AWATER: 7098620,
        INTPTLAT: '+34.7338044',
        INTPTLON: '-095.3239266',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5077, 34.9564],
            [-95.4545, 35.0293],
            [-95.3492, 35.0585],
            [-95.2439, 35.0579],
            [-95.2438, 34.9728],
            [-95.3042, 34.9356],
            [-95.2515, 34.8103],
            [-95.322, 34.7824],
            [-95.3913, 34.8547],
            [-95.5075, 34.8549],
            [-95.5077, 34.9564],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032790',
        NAME: 'Wilburton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464591878,
        AWATER: 3726680,
        INTPTLAT: '+34.9259077',
        INTPTLON: '-095.3605117',
        ELSDLEA: '',
        UNSDLEA: '32790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8283, 36.0644],
            [-96.6517, 36.0218],
            [-96.6604, 36.0002],
            [-96.6779, 35.9421],
            [-96.7579, 35.9059],
            [-96.8649, 35.9422],
            [-96.8283, 36.0644],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009240',
        NAME: 'Cushing Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217043139,
        AWATER: 1639269,
        INTPTLAT: '+35.9807943',
        INTPTLON: '-096.7720582',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9293, 35.8118],
            [-96.9821, 35.7353],
            [-97.1056, 35.8112],
            [-96.9736, 35.8482],
            [-96.9293, 35.8118],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006690',
        NAME: 'Carney Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126320251,
        AWATER: 831603,
        INTPTLAT: '+35.8020435',
        INTPTLON: '-097.0221300',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8228, 35.7681],
            [-96.7074, 35.7602],
            [-96.6799, 35.6156],
            [-96.7424, 35.5939],
            [-96.8309, 35.6389],
            [-96.8228, 35.7681],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009450',
        NAME: 'Davenport Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198150525,
        AWATER: 4920805,
        INTPTLAT: '+35.6882419',
        INTPTLON: '-096.7562796',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2389, 35.3834],
            [-97.1855, 35.4128],
            [-97.1325, 35.5144],
            [-97.0795, 35.5143],
            [-97.0268, 35.4924],
            [-97.0269, 35.4636],
            [-97.0982, 35.3477],
            [-97.142, 35.3407],
            [-97.239, 35.3409],
            [-97.2389, 35.3834],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019560',
        NAME: 'McLoud Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190464272,
        AWATER: 836145,
        INTPTLAT: '+35.4155144',
        INTPTLON: '-097.1323939',
        ELSDLEA: '',
        UNSDLEA: '19560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8631, 34.2919],
            [-96.8281, 34.2903],
            [-96.828, 34.2048],
            [-96.6975, 34.2194],
            [-96.7058, 34.3177],
            [-96.7404, 34.5058],
            [-96.695, 34.5057],
            [-96.5924, 34.4559],
            [-96.5836, 34.3173],
            [-96.5838, 34.1796],
            [-96.4477, 34.1969],
            [-96.4133, 34.1573],
            [-96.4538, 34.1144],
            [-96.5854, 34.1145],
            [-96.67, 34.1723],
            [-96.8456, 34.1725],
            [-96.8631, 34.2919],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030060',
        NAME: 'Tishomingo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544980563,
        AWATER: 30833066,
        INTPTLAT: '+34.2758066',
        INTPTLON: '-096.6512596',
        ELSDLEA: '',
        UNSDLEA: '30060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1704, 35.1603],
            [-98.1038, 35.2473],
            [-98.0774, 35.2472],
            [-97.9962, 35.0731],
            [-98.0312, 35.0435],
            [-98.102, 35.0288],
            [-98.146, 35.0287],
            [-98.1704, 35.1603],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031080',
        NAME: 'Verden Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256540431,
        AWATER: 3500960,
        INTPTLAT: '+35.1238865',
        INTPTLON: '-098.1000160',
        ELSDLEA: '',
        UNSDLEA: '31080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0774, 35.2472],
            [-97.9096, 35.2472],
            [-97.7848, 35.1818],
            [-97.7321, 35.1674],
            [-97.7321, 35.1384],
            [-97.8027, 35.095],
            [-97.8996, 35.0876],
            [-97.9962, 35.0731],
            [-98.0774, 35.2472],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002910',
        NAME: 'Amber-Pocasset Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378163471,
        AWATER: 325014,
        INTPTLAT: '+35.1728019',
        INTPTLON: '-097.9245634',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6786, 35.284],
            [-97.662, 35.2183],
            [-97.7321, 35.1674],
            [-97.7848, 35.1818],
            [-97.7854, 35.2689],
            [-97.6786, 35.284],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005400',
        NAME: 'Bridge Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114231316,
        AWATER: 99282,
        INTPTLAT: '+35.2235580',
        INTPTLON: '-097.7256953',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6793, 35.3348],
            [-97.663, 35.3366],
            [-97.5561, 35.2763],
            [-97.5398, 35.1604],
            [-97.662, 35.2183],
            [-97.6786, 35.284],
            [-97.6793, 35.3348],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021510',
        NAME: 'Newcastle Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137951597,
        AWATER: 2681021,
        INTPTLAT: '+35.2552157',
        INTPTLON: '-097.6101555',
        ELSDLEA: '',
        UNSDLEA: '21510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.0456, 35.4217],
              [-98.8614, 35.4072],
              [-98.8523, 35.465],
              [-98.6233, 35.4209],
              [-98.6323, 35.2836],
              [-98.6413, 35.2764],
              [-98.6324, 35.2764],
              [-98.6236, 35.2764],
              [-98.6591, 35.2619],
              [-98.6767, 35.2549],
              [-98.7908, 35.2477],
              [-98.9165, 35.1969],
              [-99.073, 35.2333],
              [-99.0456, 35.4217],
            ],
          ],
          [
            [
              [-98.6323, 35.2836],
              [-98.6324, 35.2764],
              [-98.6413, 35.2764],
              [-98.6323, 35.2836],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008640',
        NAME: 'Cordell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 900536760,
        AWATER: 4669854,
        INTPTLAT: '+35.3276371',
        INTPTLON: '-098.8436499',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.6591, 35.2619],
              [-98.6679, 35.2548],
              [-98.6767, 35.2549],
              [-98.6591, 35.2619],
            ],
          ],
          [
            [
              [-98.6679, 35.2548],
              [-98.6591, 35.2619],
              [-98.6236, 35.2764],
              [-98.509, 35.233],
              [-98.5091, 35.2186],
              [-98.5059, 34.942],
              [-98.6199, 34.8843],
              [-98.6638, 34.9305],
              [-98.6679, 35.2548],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006630',
        NAME: 'Carnegie Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 522358114,
        AWATER: 3853672,
        INTPTLAT: '+35.0708726',
        INTPTLON: '-098.5955436',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0994, 35.4217],
            [-99.0456, 35.4217],
            [-99.073, 35.2333],
            [-99.188, 35.2333],
            [-99.2584, 35.3059],
            [-99.2496, 35.3785],
            [-99.0994, 35.4217],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000014',
        NAME: 'Burns Flat-Dill City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340585421,
        AWATER: 1080220,
        INTPTLAT: '+35.3184724',
        INTPTLON: '-099.1456559',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0296, 36.0754],
            [-95.9221, 36.0536],
            [-95.8776, 36.0174],
            [-95.976, 35.9846],
            [-96.0295, 35.9884],
            [-96.0296, 36.0754],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015720',
        NAME: 'Jenks Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96623603,
        AWATER: 6602669,
        INTPTLAT: '+36.0265623',
        INTPTLON: '-095.9684706',
        ELSDLEA: '',
        UNSDLEA: '15720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3242, 36.0539],
            [-96.2975, 36.0539],
            [-96.1999, 36.0236],
            [-96.1633, 35.9739],
            [-96.1293, 35.9229],
            [-96.1919, 35.857],
            [-96.1925, 35.7915],
            [-96.3336, 35.8788],
            [-96.3512, 35.9521],
            [-96.3242, 36.0539],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016170',
        NAME: 'Kellyville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328239448,
        AWATER: 4889864,
        INTPTLAT: '+35.9319812',
        INTPTLON: '-096.2522936',
        ELSDLEA: '',
        UNSDLEA: '16170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4857, 36.1181],
            [-96.4282, 36.2027],
            [-96.3034, 36.1876],
            [-96.2975, 36.0539],
            [-96.3242, 36.0539],
            [-96.4857, 36.1181],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018840',
        NAME: 'Mannford Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168122752,
        AWATER: 32535759,
        INTPTLAT: '+36.1250522',
        INTPTLON: '-096.3759217',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5122, 36.1172],
            [-96.4857, 36.1181],
            [-96.3242, 36.0539],
            [-96.3512, 35.9521],
            [-96.4945, 35.9374],
            [-96.5391, 36.0465],
            [-96.5122, 36.1172],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022860',
        NAME: 'Olive Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246218436,
        AWATER: 1655888,
        INTPTLAT: '+36.0213864',
        INTPTLON: '-096.4317102',
        ELSDLEA: '',
        UNSDLEA: '22860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1902, 36.0322],
            [-96.1364, 36.0321],
            [-96.0738, 36.0609],
            [-96.0296, 36.0754],
            [-96.0295, 35.9884],
            [-96.0839, 35.9739],
            [-96.1633, 35.9739],
            [-96.1999, 36.0236],
            [-96.1902, 36.0322],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026910',
        NAME: 'Sapulpa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95410091,
        AWATER: 1546159,
        INTPTLAT: '+36.0103245',
        INTPTLON: '-096.1047367',
        ELSDLEA: '',
        UNSDLEA: '26910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.101, 36.0974],
            [-96.0474, 36.0973],
            [-96.0648, 36.1389],
            [-96.0908, 36.1729],
            [-96.0913, 36.2162],
            [-96.0906, 36.2162],
            [-95.9595, 36.2858],
            [-95.9161, 36.2221],
            [-95.815, 36.2223],
            [-95.7616, 36.119],
            [-95.8154, 36.1189],
            [-95.9221, 36.0536],
            [-96.0296, 36.0754],
            [-96.0738, 36.0609],
            [-96.1011, 36.0756],
            [-96.101, 36.0974],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030240',
        NAME: 'Tulsa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450376716,
        AWATER: 9583249,
        INTPTLAT: '+36.1644465',
        INTPTLON: '-095.9448255',
        ELSDLEA: '',
        UNSDLEA: '30240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9541, 36.5249],
            [-94.9106, 36.627],
            [-94.8645, 36.6705],
            [-94.7736, 36.6678],
            [-94.6177, 36.6247],
            [-94.6179, 36.4994],
            [-94.6178, 36.4985],
            [-94.8804, 36.4764],
            [-94.9636, 36.5102],
            [-94.9541, 36.5249],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013530',
        NAME: 'Grove Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 402102688,
        AWATER: 85762168,
        INTPTLAT: '+36.5725278',
        INTPTLON: '-094.7746283',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0007, 36.8687],
            [-95.8991, 36.8511],
            [-95.8459, 36.902],
            [-95.8099, 36.7842],
            [-96.0441, 36.7673],
            [-96.0525, 36.7818],
            [-96.1158, 36.7816],
            [-96.0007, 36.8687],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009810',
        NAME: 'Dewey Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221980122,
        AWATER: 1689169,
        INTPTLAT: '+36.8223841',
        INTPTLON: '-095.9425689',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1207, 34.0798],
            [-97.8946, 34.0857],
            [-97.8595, 34.1293],
            [-97.7373, 34.0898],
            [-97.6395, 34.0565],
            [-97.6307, 33.9793],
            [-97.7261, 33.9406],
            [-97.782, 33.9387],
            [-97.9734, 33.9438],
            [-97.9717, 34.0054],
            [-98.0835, 34.0412],
            [-98.1207, 34.0798],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026610',
        NAME: 'Ryan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550829783,
        AWATER: 5671638,
        INTPTLAT: '+34.0213377',
        INTPTLON: '-097.8531113',
        ELSDLEA: '',
        UNSDLEA: '26610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9096, 35.2472],
            [-97.8757, 35.3559],
            [-97.8218, 35.3352],
            [-97.6793, 35.3348],
            [-97.6786, 35.284],
            [-97.7854, 35.2689],
            [-97.7848, 35.1818],
            [-97.9096, 35.2472],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030420',
        NAME: 'Tuttle Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211623017,
        AWATER: 225880,
        INTPTLAT: '+35.2826992',
        INTPTLON: '-097.8057977',
        ELSDLEA: '',
        UNSDLEA: '30420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.141, 35.8109],
            [-97.1056, 35.8112],
            [-96.9821, 35.7353],
            [-96.9816, 35.7099],
            [-96.9993, 35.6011],
            [-97.0791, 35.5937],
            [-97.1411, 35.6085],
            [-97.1533, 35.7891],
            [-97.141, 35.8109],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032280',
        NAME: 'Wellston Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268497041,
        AWATER: 1754260,
        INTPTLAT: '+35.6959131',
        INTPTLON: '-097.0510500',
        ELSDLEA: '',
        UNSDLEA: '32280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7321, 35.1674],
            [-97.662, 35.2183],
            [-97.5398, 35.1604],
            [-97.5295, 35.1459],
            [-97.6, 35.0951],
            [-97.6705, 35.0805],
            [-97.7321, 35.1384],
            [-97.7321, 35.1674],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004680',
        NAME: 'Blanchard Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160552725,
        AWATER: 834403,
        INTPTLAT: '+35.1512493',
        INTPTLON: '-097.6248919',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6393, 35.1957],
            [-96.6003, 35.2466],
            [-96.5733, 35.2464],
            [-96.5303, 35.1885],
            [-96.538, 35.1302],
            [-96.5288, 35.0796],
            [-96.5974, 35.0579],
            [-96.6393, 35.1957],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021360',
        NAME: 'New Lima Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139372029,
        AWATER: 1389093,
        INTPTLAT: '+35.1583657',
        INTPTLON: '-096.6028575',
        ELSDLEA: '',
        UNSDLEA: '21360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9146, 35.1162],
            [-96.7225, 35.0683],
            [-96.6132, 35.0212],
            [-96.5904, 34.9092],
            [-96.7201, 34.8575],
            [-96.737, 34.8629],
            [-96.8531, 34.9288],
            [-96.8442, 35.0145],
            [-96.9312, 35.0728],
            [-96.9146, 35.1162],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4016710',
        NAME: 'Konawa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409814873,
        AWATER: 10132277,
        INTPTLAT: '+34.9920560',
        INTPTLON: '-096.7467589',
        ELSDLEA: '',
        UNSDLEA: '16710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.798, 35.3766],
            [-96.7313, 35.4234],
            [-96.6237, 35.4007],
            [-96.5351, 35.3915],
            [-96.5292, 35.2827],
            [-96.5733, 35.2464],
            [-96.6003, 35.2466],
            [-96.618, 35.2755],
            [-96.7153, 35.2973],
            [-96.7771, 35.2973],
            [-96.8026, 35.3257],
            [-96.7982, 35.333],
            [-96.798, 35.3766],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029040',
        NAME: 'Strother Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281561732,
        AWATER: 230520,
        INTPTLAT: '+35.3552636',
        INTPTLON: '-096.6623107',
        ELSDLEA: '',
        UNSDLEA: '29040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6199, 34.8843],
            [-98.5059, 34.942],
            [-98.2601, 34.9565],
            [-98.2687, 34.8697],
            [-98.3609, 34.8259],
            [-98.475, 34.8116],
            [-98.5621, 34.8553],
            [-98.6198, 34.855],
            [-98.6199, 34.8843],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005010',
        NAME: 'Boone-Apache Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351572082,
        AWATER: 4571581,
        INTPTLAT: '+34.8911409',
        INTPTLON: '-098.4179211',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3609, 34.8259],
            [-98.2687, 34.8697],
            [-98.1454, 34.8549],
            [-98.0717, 34.8406],
            [-98.0891, 34.8116],
            [-98.2479, 34.7753],
            [-98.3609, 34.8259],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4011700',
        NAME: 'Fletcher Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145819952,
        AWATER: 10190204,
        INTPTLAT: '+34.8243163',
        INTPTLON: '-098.2255912',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1568, 35.305],
            [-98.1038, 35.2473],
            [-98.1704, 35.1603],
            [-98.2919, 35.1173],
            [-98.337, 35.1534],
            [-98.3459, 35.1606],
            [-98.3154, 35.2545],
            [-98.1568, 35.305],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013080',
        NAME: 'Gracemont Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259585125,
        AWATER: 660551,
        INTPTLAT: '+35.2022473',
        INTPTLON: '-098.2173657',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4388, 35.5515],
            [-98.2511, 35.5514],
            [-98.2278, 35.4682],
            [-98.1277, 35.4344],
            [-98.1141, 35.4209],
            [-98.1313, 35.3774],
            [-98.2628, 35.377],
            [-98.3152, 35.4279],
            [-98.5005, 35.4281],
            [-98.4975, 35.508],
            [-98.4388, 35.5515],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014610',
        NAME: 'Hinton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441975152,
        AWATER: 1769992,
        INTPTLAT: '+35.4712234',
        INTPTLON: '-098.3327659',
        ELSDLEA: '',
        UNSDLEA: '14610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1568, 35.305],
            [-98.1313, 35.3774],
            [-98.1141, 35.4209],
            [-97.9551, 35.346],
            [-97.8757, 35.3559],
            [-97.9096, 35.2472],
            [-98.0774, 35.2472],
            [-98.1038, 35.2473],
            [-98.1568, 35.305],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020100',
        NAME: 'Minco Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307975151,
        AWATER: 1265637,
        INTPTLAT: '+35.3143883',
        INTPTLON: '-098.0120522',
        ELSDLEA: '',
        UNSDLEA: '20100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6154, 34.318],
            [-97.5626, 34.3182],
            [-97.5712, 34.507],
            [-97.4051, 34.5068],
            [-97.3522, 34.3978],
            [-97.4133, 34.3979],
            [-97.4572, 34.289],
            [-97.5625, 34.2818],
            [-97.6154, 34.318],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012060',
        NAME: 'Fox Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348805135,
        AWATER: 1404217,
        INTPTLAT: '+34.4046023',
        INTPTLON: '-097.4828097',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1775, 34.2688],
            [-97.0681, 34.2426],
            [-97.1256, 34.1511],
            [-97.1775, 34.2688],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003180',
        NAME: 'Ardmore Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68438161,
        AWATER: 2535655,
        INTPTLAT: '+34.2090685',
        INTPTLON: '-097.1313596',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.2821, 34.3762],
            [-97.0381, 34.3685],
            [-97.029, 34.3326],
            [-97.0681, 34.2426],
            [-97.1775, 34.2688],
            [-97.2299, 34.2798],
            [-97.2821, 34.3762],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028350',
        NAME: 'Springer Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259456818,
        AWATER: 4739014,
        INTPTLAT: '+34.3251916',
        INTPTLON: '-097.1390382',
        ELSDLEA: '',
        UNSDLEA: '28350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8161, 34.4709],
            [-97.7833, 34.5289],
            [-97.5718, 34.5288],
            [-97.5712, 34.507],
            [-97.5626, 34.3182],
            [-97.6154, 34.318],
            [-97.7722, 34.2892],
            [-97.7724, 34.4054],
            [-97.8161, 34.4709],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031020',
        NAME: 'Velma-Alma Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 589569062,
        AWATER: 3960812,
        INTPTLAT: '+34.4011774',
        INTPTLON: '-097.6691762',
        ELSDLEA: '',
        UNSDLEA: '31020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6705, 35.0805],
            [-97.6, 35.0951],
            [-97.5298, 35.0589],
            [-97.5648, 34.9715],
            [-97.6705, 34.9569],
            [-97.7322, 34.986],
            [-97.6705, 35.0805],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009870',
        NAME: 'Dibble Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187862928,
        AWATER: 2087052,
        INTPTLAT: '+35.0196446',
        INTPTLON: '-097.6270838',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0717, 34.8406],
            [-98.0315, 34.8621],
            [-97.8731, 34.8623],
            [-97.7562, 34.8121],
            [-97.7475, 34.7359],
            [-97.8876, 34.7326],
            [-97.8965, 34.6816],
            [-98.0631, 34.6814],
            [-98.0631, 34.6885],
            [-98.0891, 34.8116],
            [-98.0717, 34.8406],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026550',
        NAME: 'Rush Springs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424524923,
        AWATER: 2673723,
        INTPTLAT: '+34.7686443',
        INTPTLON: '-097.9302032',
        ELSDLEA: '',
        UNSDLEA: '26550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1874, 36.732],
            [-96.1158, 36.7816],
            [-96.08, 36.7233],
            [-96.0521, 36.6514],
            [-96.0487, 36.5428],
            [-96.1918, 36.4626],
            [-96.2463, 36.4625],
            [-96.2457, 36.5496],
            [-96.169, 36.6076],
            [-96.1874, 36.732],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003570',
        NAME: 'Barnsdall Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 383802433,
        AWATER: 2757107,
        INTPTLAT: '+36.6048329',
        INTPTLON: '-096.1432981',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4071, 36.3323],
            [-96.2328, 36.3466],
            [-96.1921, 36.3901],
            [-96.1742, 36.39],
            [-96.1387, 36.3033],
            [-96.1661, 36.2164],
            [-96.2429, 36.2019],
            [-96.3713, 36.2558],
            [-96.4071, 36.3323],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025170',
        NAME: 'Prue Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263396783,
        AWATER: 24274304,
        INTPTLAT: '+36.2947412',
        INTPTLON: '-096.2639300',
        ELSDLEA: '',
        UNSDLEA: '25170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0971, 36.3038],
            [-95.9381, 36.3367],
            [-95.8842, 36.322],
            [-95.9595, 36.2858],
            [-96.0906, 36.2162],
            [-96.0971, 36.3038],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028170',
        NAME: 'Sperry Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144011215,
        AWATER: 1740053,
        INTPTLAT: '+36.2930836',
        INTPTLON: '-096.0261755',
        ELSDLEA: '',
        UNSDLEA: '28170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.1387, 36.3033],
              [-96.0971, 36.3038],
              [-96.0906, 36.2162],
              [-96.0913, 36.2162],
              [-96.1661, 36.2164],
              [-96.1387, 36.3033],
            ],
          ],
          [
            [
              [-96.2683, 36.162],
              [-96.0908, 36.1729],
              [-96.0648, 36.1389],
              [-96.101, 36.0974],
              [-96.1011, 36.0756],
              [-96.137, 36.0757],
              [-96.1876, 36.0756],
              [-96.2683, 36.162],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026880',
        NAME: 'Sand Springs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188505221,
        AWATER: 7152321,
        INTPTLAT: '+36.1300405',
        INTPTLON: '-096.1019345',
        ELSDLEA: '',
        UNSDLEA: '26880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7697, 36.0289],
            [-98.7258, 36.1633],
            [-98.6637, 36.2027],
            [-98.4862, 36.1949],
            [-98.4769, 36.1363],
            [-98.4317, 36.0642],
            [-98.4408, 35.9849],
            [-98.507, 35.9566],
            [-98.5563, 35.9206],
            [-98.6539, 35.9496],
            [-98.7697, 36.0289],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006480',
        NAME: 'Canton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619541321,
        AWATER: 33891936,
        INTPTLAT: '+36.0620539',
        INTPTLON: '-098.5929368',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9073, 34.2582],
            [-94.8022, 34.2583],
            [-94.7325, 34.1856],
            [-94.6346, 34.0985],
            [-94.5756, 33.9513],
            [-94.7144, 33.9279],
            [-94.7741, 33.9391],
            [-94.7744, 33.9972],
            [-94.8435, 34.0988],
            [-94.8958, 34.1279],
            [-94.935, 34.2068],
            [-94.9073, 34.2582],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005520',
        NAME: 'Broken Bow Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547504750,
        AWATER: 5454275,
        INTPTLAT: '+34.0911063',
        INTPTLON: '-094.7473277',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1565, 34.0988],
            [-95.1565, 34.1133],
            [-95.1358, 34.244],
            [-95.1353, 34.244],
            [-95.0411, 34.0482],
            [-94.9393, 34.0197],
            [-94.9475, 33.9967],
            [-94.9476, 33.9827],
            [-94.9744, 33.9031],
            [-95.0643, 33.9101],
            [-95.0956, 33.8881],
            [-95.1559, 33.9366],
            [-95.1565, 34.0988],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030870',
        NAME: 'Valliant Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378167277,
        AWATER: 15793780,
        INTPTLAT: '+34.0250199',
        INTPTLON: '-095.0732161',
        ELSDLEA: '',
        UNSDLEA: '30870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9073, 34.2582],
            [-94.935, 34.2068],
            [-94.8958, 34.1279],
            [-94.9393, 34.0197],
            [-95.0411, 34.0482],
            [-95.1353, 34.244],
            [-95.0464, 34.2873],
            [-94.9073, 34.2582],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033210',
        NAME: 'Wright City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420808512,
        AWATER: 8771825,
        INTPTLAT: '+34.1611011',
        INTPTLON: '-095.0046173',
        ELSDLEA: '',
        UNSDLEA: '33210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.9882, 34.2004],
              [-95.919, 34.1566],
              [-95.9878, 34.1568],
              [-95.9882, 34.2004],
            ],
          ],
          [
            [
              [-96.0051, 34.3745],
              [-95.9882, 34.3744],
              [-96.0051, 34.3438],
              [-96.0051, 34.3745],
            ],
          ],
          [
            [
              [-96.0926, 34.5062],
              [-96.0926, 34.4913],
              [-96.0402, 34.4211],
              [-96.0753, 34.346],
              [-96.1439, 34.3467],
              [-96.2853, 34.324],
              [-96.3402, 34.3968],
              [-96.1514, 34.4187],
              [-96.1783, 34.4892],
              [-96.0926, 34.5062],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003360',
        NAME: 'Atoka Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321807892,
        AWATER: 3235322,
        INTPTLAT: '+34.3801723',
        INTPTLON: '-096.1469591',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6979, 34.594],
            [-95.6895, 34.5939],
            [-95.5705, 34.5941],
            [-95.5146, 34.6665],
            [-95.252, 34.5941],
            [-95.2907, 34.4322],
            [-95.5708, 34.4326],
            [-95.7066, 34.5068],
            [-95.6979, 34.594],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007800',
        NAME: 'Clayton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 756291385,
        AWATER: 7288531,
        INTPTLAT: '+34.5090320',
        INTPTLON: '-095.4434638',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5139, 34.5054],
            [-96.5121, 34.5127],
            [-96.3805, 34.5199],
            [-96.3978, 34.6362],
            [-96.3016, 34.7232],
            [-96.2928, 34.7523],
            [-96.1619, 34.7672],
            [-96.092, 34.7675],
            [-96.0921, 34.5931],
            [-96.162, 34.6072],
            [-96.2317, 34.5601],
            [-96.0922, 34.5495],
            [-96.0926, 34.5062],
            [-96.1783, 34.4892],
            [-96.1514, 34.4187],
            [-96.3402, 34.3968],
            [-96.5141, 34.4632],
            [-96.5139, 34.5054],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008130',
        NAME: 'Coalgate Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 918102021,
        AWATER: 8156358,
        INTPTLAT: '+34.5774930',
        INTPTLON: '-096.2552025',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1439, 34.3467],
            [-96.0753, 34.346],
            [-96.0402, 34.4211],
            [-96.0051, 34.3745],
            [-96.0051, 34.3438],
            [-96.0405, 34.2438],
            [-95.9882, 34.2004],
            [-95.9878, 34.1568],
            [-95.9916, 34.1568],
            [-96.071, 34.1761],
            [-96.1022, 34.1865],
            [-96.1104, 34.1996],
            [-96.1439, 34.3467],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013830',
        NAME: 'Harmony Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234129678,
        AWATER: 1198891,
        INTPTLAT: '+34.2900793',
        INTPTLON: '-096.0626736',
        ELSDLEA: '',
        UNSDLEA: '13830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8118, 34.7675],
            [-95.7243, 34.7823],
            [-95.6977, 34.7632],
            [-95.6895, 34.5939],
            [-95.6979, 34.594],
            [-95.829, 34.5936],
            [-95.882, 34.5935],
            [-95.8822, 34.7385],
            [-95.8118, 34.7675],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024180',
        NAME: 'Pittsburg Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311752083,
        AWATER: 1701476,
        INTPTLAT: '+34.6820598',
        INTPTLON: '-095.7941606',
        ELSDLEA: '',
        UNSDLEA: '24180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.338, 34.3167],
            [-96.2853, 34.324],
            [-96.1439, 34.3467],
            [-96.1104, 34.1996],
            [-96.2154, 34.273],
            [-96.3379, 34.2732],
            [-96.338, 34.3167],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030360',
        NAME: 'Tushka Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155898832,
        AWATER: 351005,
        INTPTLAT: '+34.3007079',
        INTPTLON: '-096.2047106',
        ELSDLEA: '',
        UNSDLEA: '30360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5924, 34.4559],
            [-96.5139, 34.5054],
            [-96.5141, 34.4632],
            [-96.3402, 34.3968],
            [-96.2853, 34.324],
            [-96.338, 34.3167],
            [-96.4786, 34.3031],
            [-96.5836, 34.3173],
            [-96.5924, 34.4559],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031590',
        NAME: 'Wapanucka Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357797657,
        AWATER: 3163388,
        INTPTLAT: '+34.3888633',
        INTPTLON: '-096.4515197',
        ELSDLEA: '',
        UNSDLEA: '31590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.338, 33.9541],
            [-96.2778, 34.0195],
            [-96.251, 34.07],
            [-96.0683, 34.099],
            [-96.071, 34.1761],
            [-95.9916, 34.1568],
            [-95.9916, 34.1568],
            [-95.9987, 34.1058],
            [-96.0859, 34.0553],
            [-96.0592, 33.8398],
            [-96.1002, 33.8479],
            [-96.1899, 33.9029],
            [-96.2421, 33.8349],
            [-96.3204, 33.8411],
            [-96.3378, 33.9103],
            [-96.338, 33.9541],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033601',
        NAME: 'Rock Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 575756614,
        AWATER: 4819550,
        INTPTLAT: '+33.9715106',
        INTPTLON: '-096.1681738',
        ELSDLEA: '',
        UNSDLEA: '33601',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7099, 36.6957],
            [-99.5578, 36.6954],
            [-99.3949, 36.6376],
            [-99.4606, 36.582],
            [-99.4798, 36.4624],
            [-99.5841, 36.4637],
            [-99.695, 36.5007],
            [-99.7839, 36.5367],
            [-99.7638, 36.6513],
            [-99.7099, 36.6957],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012000',
        NAME: 'Fort Supply Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623665896,
        AWATER: 7068231,
        INTPTLAT: '+36.5907832',
        INTPTLON: '-099.5992011',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5841, 36.4637],
            [-99.4798, 36.4624],
            [-99.4606, 36.582],
            [-99.3949, 36.6376],
            [-99.3585, 36.6382],
            [-99.2953, 36.6382],
            [-99.2815, 36.5363],
            [-99.3349, 36.4777],
            [-99.2731, 36.3628],
            [-99.4613, 36.3333],
            [-99.5841, 36.4637],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033180',
        NAME: 'Woodward Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 551240648,
        AWATER: 185130,
        INTPTLAT: '+36.4854334',
        INTPTLON: '-099.4127482',
        ELSDLEA: '',
        UNSDLEA: '33180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.9797, 36.7759],
            [-98.7735, 36.7615],
            [-98.7203, 36.6886],
            [-98.7202, 36.6091],
            [-98.6573, 36.5209],
            [-98.6934, 36.4139],
            [-98.7642, 36.3329],
            [-98.9065, 36.3404],
            [-98.9243, 36.3768],
            [-98.9603, 36.507],
            [-99.0698, 36.5986],
            [-98.9797, 36.7759],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032040',
        NAME: 'Waynoka Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1262448135,
        AWATER: 2743388,
        INTPTLAT: '+36.5375687',
        INTPTLON: '-098.8377295',
        ELSDLEA: '',
        UNSDLEA: '32040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2953, 36.6382],
            [-99.2954, 36.6815],
            [-99.151, 36.6817],
            [-99.0698, 36.5986],
            [-98.9603, 36.507],
            [-98.9243, 36.3768],
            [-98.9608, 36.3046],
            [-99.0055, 36.2757],
            [-99.2285, 36.3339],
            [-99.2731, 36.3628],
            [-99.3349, 36.4777],
            [-99.2815, 36.5363],
            [-99.2953, 36.6382],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020280',
        NAME: 'Mooreland Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1041203448,
        AWATER: 26891,
        INTPTLAT: '+36.4984089',
        INTPTLON: '-099.1477234',
        ELSDLEA: '',
        UNSDLEA: '20280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.0799, 36.1517],
            [-99.0361, 36.2167],
            [-98.956, 36.1893],
            [-98.9608, 36.3046],
            [-98.9243, 36.3768],
            [-98.9065, 36.3404],
            [-98.7642, 36.3329],
            [-98.7258, 36.1633],
            [-98.7697, 36.0289],
            [-98.8929, 36.0214],
            [-98.9559, 36.0797],
            [-99.0848, 36.0794],
            [-99.0799, 36.1517],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027240',
        NAME: 'Seiling Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 773009983,
        AWATER: 1318691,
        INTPTLAT: '+36.1739704',
        INTPTLON: '-098.8758966',
        ELSDLEA: '',
        UNSDLEA: '27240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0004, 36.0426],
            [-99.934, 36.0427],
            [-99.8638, 36.115],
            [-99.8103, 36.2313],
            [-99.6408, 36.2169],
            [-99.5423, 36.203],
            [-99.4533, 36.2027],
            [-99.4519, 36.0055],
            [-99.5876, 35.8579],
            [-99.7171, 35.8598],
            [-99.8934, 35.9853],
            [-99.9099, 35.927],
            [-100.0004, 35.8809],
            [-100.0004, 36.0426],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003240',
        NAME: 'Arnett Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1400158281,
        AWATER: 738479,
        INTPTLAT: '+36.0493926',
        INTPTLON: '-099.6890488',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4613, 36.3333],
            [-99.2731, 36.3628],
            [-99.2285, 36.3339],
            [-99.0055, 36.2757],
            [-98.9608, 36.3046],
            [-98.956, 36.1893],
            [-99.0361, 36.2167],
            [-99.0799, 36.1517],
            [-99.2221, 36.1632],
            [-99.256, 36.21],
            [-99.4533, 36.2027],
            [-99.5423, 36.203],
            [-99.5245, 36.2903],
            [-99.4613, 36.3333],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021030',
        NAME: 'Sharon-Mutual Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 718201717,
        AWATER: 77966,
        INTPTLAT: '+36.2532173',
        INTPTLON: '-099.2459560',
        ELSDLEA: '',
        UNSDLEA: '21030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5836, 34.3173],
            [-96.4786, 34.3031],
            [-96.4477, 34.1969],
            [-96.5838, 34.1796],
            [-96.5836, 34.3173],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019980',
        NAME: 'Milburn Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166938042,
        AWATER: 1016292,
        INTPTLAT: '+34.2525245',
        INTPTLON: '-096.5281696',
        ELSDLEA: '',
        UNSDLEA: '19980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.338, 34.3167],
            [-96.3379, 34.2732],
            [-96.3378, 34.2008],
            [-96.4106, 34.1573],
            [-96.4133, 34.1573],
            [-96.4477, 34.1969],
            [-96.4786, 34.3031],
            [-96.338, 34.3167],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008310',
        NAME: 'Coleman Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159008953,
        AWATER: 1356892,
        INTPTLAT: '+34.2550312',
        INTPTLON: '-096.4101417',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1565, 34.1133],
            [-95.1565, 34.0988],
            [-95.2261, 34.0699],
            [-95.2258, 33.9621],
            [-95.3105, 33.8772],
            [-95.3656, 33.8671],
            [-95.3649, 33.9611],
            [-95.4282, 34.0988],
            [-95.1565, 34.1133],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012300',
        NAME: 'Fort Towson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364683876,
        AWATER: 28266830,
        INTPTLAT: '+34.0176676',
        INTPTLON: '-095.3114185',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6343, 34.0114],
            [-95.5565, 34.1569],
            [-95.5552, 34.1569],
            [-95.5433, 34.1568],
            [-95.5352, 34.1568],
            [-95.4422, 34.1569],
            [-95.4282, 34.0988],
            [-95.3649, 33.9611],
            [-95.5301, 33.9608],
            [-95.6343, 34.0114],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4015210',
        NAME: 'Hugo Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315930460,
        AWATER: 26814996,
        INTPTLAT: '+34.0548777',
        INTPTLON: '-095.4922661',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.647, 35.8638],
            [-95.5648, 35.9676],
            [-95.4958, 35.9568],
            [-95.3941, 35.8817],
            [-95.3762, 35.8147],
            [-95.491, 35.8011],
            [-95.5933, 35.7839],
            [-95.647, 35.8638],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4030197',
        NAME: 'Porter Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300730053,
        AWATER: 8028250,
        INTPTLAT: '+35.8633142',
        INTPTLON: '-095.5153795',
        ELSDLEA: '',
        UNSDLEA: '30197',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5886, 35.0879],
            [-99.501, 35.1169],
            [-99.4093, 35.1145],
            [-99.4042, 35.1094],
            [-99.3268, 35.0189],
            [-99.2981, 34.8998],
            [-99.379, 34.8423],
            [-99.571, 35.0298],
            [-99.5886, 35.0879],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013290',
        NAME: 'Granite Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452797681,
        AWATER: 9871419,
        INTPTLAT: '+34.9960579',
        INTPTLON: '-099.4541111',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4042, 35.1094],
            [-99.2282, 35.1095],
            [-99.1841, 35.0657],
            [-99.1668, 34.8851],
            [-99.1591, 34.8477],
            [-99.2485, 34.827],
            [-99.2981, 34.8998],
            [-99.3268, 35.0189],
            [-99.4042, 35.1094],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018270',
        NAME: 'Lone Wolf Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406756024,
        AWATER: 9393086,
        INTPTLAT: '+34.9811736',
        INTPTLON: '-099.2570182',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.6659, 35.8338],
              [-94.6035, 35.8556],
              [-94.5837, 35.901],
              [-94.5174, 35.9008],
              [-94.4931, 35.7592],
              [-94.4826, 35.6961],
              [-94.63, 35.6673],
              [-94.6303, 35.725],
              [-94.6304, 35.754],
              [-94.6659, 35.8338],
            ],
          ],
          [
            [
              [-94.7973, 35.9372],
              [-94.6546, 35.9512],
              [-94.6193, 35.9082],
              [-94.7187, 35.9009],
              [-94.8074, 35.8779],
              [-94.7973, 35.9372],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028710',
        NAME: 'Stilwell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329372735,
        AWATER: 1543714,
        INTPTLAT: '+35.8083807',
        INTPTLON: '-094.6073900',
        ELSDLEA: '',
        UNSDLEA: '28710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.851, 36.0097],
            [-95.8154, 36.1189],
            [-95.7616, 36.119],
            [-95.6457, 36.1405],
            [-95.6513, 36.1187],
            [-95.6546, 36.075],
            [-95.6546, 36.0027],
            [-95.7439, 35.9883],
            [-95.7265, 35.9032],
            [-95.7615, 35.9125],
            [-95.851, 36.0097],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005490',
        NAME: 'Broken Arrow Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267475189,
        AWATER: 4402792,
        INTPTLAT: '+36.0427327',
        INTPTLON: '-095.7440767',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6988, 36.1485],
            [-94.5627, 36.1619],
            [-94.5499, 36.0894],
            [-94.699, 36.1037],
            [-94.6988, 36.1485],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031860',
        NAME: 'Watts Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97607751,
        AWATER: 1812814,
        INTPTLAT: '+36.1222637',
        INTPTLON: '-094.6159963',
        ELSDLEA: '',
        UNSDLEA: '31860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8689, 36.0694],
            [-94.7082, 36.1617],
            [-94.6988, 36.1485],
            [-94.699, 36.1037],
            [-94.5499, 36.0894],
            [-94.5352, 36.0048],
            [-94.5174, 35.9008],
            [-94.5837, 35.901],
            [-94.6193, 35.9082],
            [-94.6546, 35.9512],
            [-94.7973, 35.9372],
            [-94.7974, 35.9407],
            [-94.7974, 35.9443],
            [-94.7973, 36.046],
            [-94.8684, 36.0455],
            [-94.8689, 36.0694],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032400',
        NAME: 'Westville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501439723,
        AWATER: 3210124,
        INTPTLAT: '+36.0256838',
        INTPTLON: '-094.6683359',
        ELSDLEA: '',
        UNSDLEA: '32400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4945, 35.9374],
            [-96.3512, 35.9521],
            [-96.3336, 35.8788],
            [-96.1925, 35.7915],
            [-96.1926, 35.6391],
            [-96.3943, 35.6392],
            [-96.3872, 35.7264],
            [-96.4586, 35.7695],
            [-96.4495, 35.8499],
            [-96.4943, 35.901],
            [-96.4945, 35.9374],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4005460',
        NAME: 'Bristow Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 620366408,
        AWATER: 7779414,
        INTPTLAT: '+35.7860613',
        INTPTLON: '-096.3374845',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0295, 35.9228],
            [-96.0295, 35.9884],
            [-95.976, 35.9846],
            [-95.976, 35.9011],
            [-96.0296, 35.9011],
            [-96.0295, 35.9228],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012720',
        NAME: 'Glenpool Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46803649,
        AWATER: 0,
        INTPTLAT: '+35.9447149',
        INTPTLON: '-096.0027513',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.9843, 35.0534],
            [-96.9312, 35.0728],
            [-96.8442, 35.0145],
            [-96.8531, 34.9288],
            [-96.931, 34.8981],
            [-96.9779, 34.9256],
            [-96.9843, 35.0534],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003300',
        NAME: 'Asher Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166458622,
        AWATER: 2590848,
        INTPTLAT: '+34.9931598',
        INTPTLON: '-096.9046572',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9916, 34.1568],
            [-95.9381, 34.1251],
            [-95.9379, 33.9482],
            [-95.8731, 33.8809],
            [-95.7615, 33.8731],
            [-95.8465, 33.8399],
            [-95.9248, 33.8848],
            [-95.9837, 33.8528],
            [-96.0592, 33.8398],
            [-96.0859, 34.0553],
            [-95.9987, 34.1058],
            [-95.9916, 34.1568],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003930',
        NAME: 'Bennington Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404384978,
        AWATER: 10721537,
        INTPTLAT: '+33.9651012',
        INTPTLON: '-095.9762214',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5973, 33.8751],
            [-96.5885, 33.895],
            [-96.5806, 33.9233],
            [-96.5269, 33.9504],
            [-96.4655, 33.8963],
            [-96.4571, 33.7757],
            [-96.501, 33.7728],
            [-96.5973, 33.8751],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008220',
        NAME: 'Colbert Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141516454,
        AWATER: 31694303,
        INTPTLAT: '+33.8600648',
        INTPTLON: '-096.5227300',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4106, 34.1573],
            [-96.3378, 34.2008],
            [-96.2849, 34.1574],
            [-96.1022, 34.1865],
            [-96.071, 34.1761],
            [-96.0683, 34.099],
            [-96.251, 34.07],
            [-96.2778, 34.0195],
            [-96.3467, 34.0484],
            [-96.4106, 34.1573],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006150',
        NAME: 'Caddo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346694365,
        AWATER: 1518740,
        INTPTLAT: '+34.1182524',
        INTPTLON: '-096.2551263',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4655, 33.8963],
            [-96.3378, 33.9103],
            [-96.3204, 33.8411],
            [-96.2421, 33.8349],
            [-96.1899, 33.9029],
            [-96.1002, 33.8479],
            [-96.1892, 33.7557],
            [-96.307, 33.7206],
            [-96.3795, 33.7258],
            [-96.4571, 33.7757],
            [-96.4655, 33.8963],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002370',
        NAME: 'Achille Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415440148,
        AWATER: 14753558,
        INTPTLAT: '+33.8234829',
        INTPTLON: '-096.2872303',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3467, 34.0484],
            [-96.2778, 34.0195],
            [-96.338, 33.9541],
            [-96.4656, 33.969],
            [-96.3467, 34.0484],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010350',
        NAME: 'Durant Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111339392,
        AWATER: 154781,
        INTPTLAT: '+33.9998960',
        INTPTLON: '-096.3737281',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0061, 36.3836],
            [-97.9525, 36.3403],
            [-97.9789, 36.2106],
            [-98.0777, 36.181],
            [-98.1045, 36.3473],
            [-98.0061, 36.3836],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010170',
        NAME: 'Drummond Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226576424,
        AWATER: 110101,
        INTPTLAT: '+36.2826802',
        INTPTLON: '-098.0246171',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.347, 35.9677],
            [-98.2279, 35.9712],
            [-98.2102, 36.0506],
            [-98.1116, 36.0505],
            [-97.9965, 35.9959],
            [-98.0322, 35.9493],
            [-98.0922, 35.8985],
            [-98.0659, 35.8052],
            [-98.1935, 35.7251],
            [-98.2071, 35.7251],
            [-98.2338, 35.7832],
            [-98.2247, 35.8411],
            [-98.3522, 35.9276],
            [-98.347, 35.9677],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022950',
        NAME: 'Lomega Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 568557362,
        AWATER: 2564943,
        INTPTLAT: '+35.9077625',
        INTPTLON: '-098.1667324',
        ELSDLEA: '',
        UNSDLEA: '22950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.318, 35.1669],
            [-96.1239, 35.1449],
            [-96.0123, 35.1451],
            [-96.0474, 35.1013],
            [-96.1322, 35.0143],
            [-96.2741, 35.0288],
            [-96.3, 34.9832],
            [-96.3533, 35.1014],
            [-96.318, 35.1669],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4020460',
        NAME: 'Moss Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376596030,
        AWATER: 6321002,
        INTPTLAT: '+35.0917508',
        INTPTLON: '-096.2099581',
        ELSDLEA: '',
        UNSDLEA: '20460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1322, 35.0143],
            [-96.0474, 35.1013],
            [-95.9655, 34.9999],
            [-96.0272, 34.9272],
            [-96.0574, 34.8111],
            [-96.092, 34.7675],
            [-96.1619, 34.7672],
            [-96.1322, 35.0143],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029100',
        NAME: 'Stuart Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391419999,
        AWATER: 1741867,
        INTPTLAT: '+34.9297891',
        INTPTLON: '-096.0800480',
        ELSDLEA: '',
        UNSDLEA: '29100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8024, 34.9858],
            [-97.7322, 34.986],
            [-97.6705, 34.9569],
            [-97.6349, 34.9133],
            [-97.6858, 34.8121],
            [-97.7562, 34.8121],
            [-97.8731, 34.8623],
            [-97.8729, 34.9641],
            [-97.8024, 34.9858],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002670',
        NAME: 'Alex Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371824410,
        AWATER: 2446115,
        INTPTLAT: '+34.8931517',
        INTPTLON: '-097.7671843',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3003, 34.6955],
            [-97.2652, 34.7681],
            [-97.1951, 34.8552],
            [-97.1427, 34.8551],
            [-97.1659, 34.7073],
            [-97.2827, 34.681],
            [-97.3003, 34.6955],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023550',
        NAME: 'Pauls Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128943887,
        AWATER: 3138819,
        INTPTLAT: '+34.7479937',
        INTPTLON: '-097.1862038',
        ELSDLEA: '',
        UNSDLEA: '23550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7562, 34.8121],
            [-97.6858, 34.8121],
            [-97.6349, 34.9133],
            [-97.6705, 34.9569],
            [-97.5648, 34.9715],
            [-97.5475, 34.9496],
            [-97.4855, 34.877],
            [-97.4928, 34.7464],
            [-97.5628, 34.6811],
            [-97.6658, 34.6915],
            [-97.7475, 34.7359],
            [-97.7562, 34.8121],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017850',
        NAME: 'Lindsay Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474130625,
        AWATER: 4843518,
        INTPTLAT: '+34.8107790',
        INTPTLON: '-097.6073618',
        ELSDLEA: '',
        UNSDLEA: '17850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4855, 34.877],
            [-97.3533, 34.8696],
            [-97.3233, 34.7827],
            [-97.3528, 34.732],
            [-97.4928, 34.7464],
            [-97.4855, 34.877],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4019380',
        NAME: 'Maysville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204592814,
        AWATER: 3918709,
        INTPTLAT: '+34.8077601',
        INTPTLON: '-097.4181658',
        ELSDLEA: '',
        UNSDLEA: '19380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1427, 34.8551],
            [-97.1423, 34.8551],
            [-97.0906, 34.8329],
            [-96.9308, 34.8763],
            [-96.9325, 34.695],
            [-96.9323, 34.6368],
            [-97.0027, 34.6368],
            [-97.0353, 34.695],
            [-97.1659, 34.7073],
            [-97.1427, 34.8551],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4028980',
        NAME: 'Stratford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388996271,
        AWATER: 8803158,
        INTPTLAT: '+34.7597619',
        INTPTLON: '-097.0302669',
        ELSDLEA: '',
        UNSDLEA: '28980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2071, 35.7251],
            [-98.1935, 35.7251],
            [-98.0921, 35.6892],
            [-98.027, 35.5802],
            [-98.0654, 35.5513],
            [-98.1229, 35.5297],
            [-98.207, 35.5948],
            [-98.2071, 35.7251],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006240',
        NAME: 'Calumet Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244521613,
        AWATER: 1149453,
        INTPTLAT: '+35.6300681',
        INTPTLON: '-098.1419668',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1935, 35.7251],
            [-98.0659, 35.8052],
            [-97.8339, 35.7766],
            [-97.8073, 35.7258],
            [-97.843, 35.6385],
            [-97.8608, 35.6313],
            [-98.0303, 35.6384],
            [-98.0921, 35.6892],
            [-98.1935, 35.7251],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4022590',
        NAME: 'Okarche Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394633160,
        AWATER: 4135473,
        INTPTLAT: '+35.7179534',
        INTPTLON: '-097.9821608',
        ELSDLEA: '',
        UNSDLEA: '22590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.9817, 35.479],
              [-97.9285, 35.4791],
              [-97.8751, 35.4571],
              [-98.0347, 35.4351],
              [-97.9817, 35.479],
            ],
          ],
          [
            [
              [-98.027, 35.5802],
              [-97.9236, 35.5582],
              [-97.9815, 35.5006],
              [-98.0654, 35.5513],
              [-98.027, 35.5802],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010650',
        NAME: 'El Reno Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113912177,
        AWATER: 1514461,
        INTPTLAT: '+35.4680158',
        INTPTLON: '-097.9438355',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.816, 35.5875],
            [-97.674, 35.573],
            [-97.674, 35.5695],
            [-97.674, 35.5684],
            [-97.6714, 35.4861],
            [-97.6714, 35.4714],
            [-97.8043, 35.4279],
            [-97.8218, 35.428],
            [-97.7691, 35.4715],
            [-97.8217, 35.5329],
            [-97.816, 35.5875],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4033480',
        NAME: 'Yukon Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171193656,
        AWATER: 5644847,
        INTPTLAT: '+35.5299894',
        INTPTLON: '-097.7493545',
        ELSDLEA: '',
        UNSDLEA: '33480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.221, 34.6158],
            [-98.221, 34.6267],
            [-98.0631, 34.6885],
            [-98.0631, 34.6814],
            [-97.9933, 34.5579],
            [-97.9933, 34.5507],
            [-98.1769, 34.536],
            [-98.1823, 34.5071],
            [-98.2383, 34.5143],
            [-98.2383, 34.5289],
            [-98.221, 34.6158],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007170',
        NAME: 'Central High Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246504437,
        AWATER: 2663761,
        INTPTLAT: '+34.6014003',
        INTPTLON: '-098.1157831',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9962, 35.0731],
            [-97.8996, 35.0876],
            [-97.8973, 35.0149],
            [-97.97, 35.0078],
            [-98.0312, 35.0435],
            [-97.9962, 35.0731],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007560',
        NAME: 'Chickasha Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111205505,
        AWATER: 123501,
        INTPTLAT: '+35.0662672',
        INTPTLON: '-097.9564586',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0496, 34.9419],
            [-97.9878, 34.9568],
            [-97.97, 35.0078],
            [-97.8973, 35.0149],
            [-97.8024, 35.015],
            [-97.8024, 34.9858],
            [-97.8729, 34.9641],
            [-97.8731, 34.8623],
            [-98.0315, 34.8621],
            [-98.0315, 34.8913],
            [-98.0315, 34.8983],
            [-98.0496, 34.9419],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021600',
        NAME: 'Ninnekah Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249788311,
        AWATER: 950354,
        INTPTLAT: '+34.9380080',
        INTPTLON: '-097.9315919',
        ELSDLEA: '',
        UNSDLEA: '21600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8098, 36.7715],
            [-95.7376, 36.7281],
            [-95.6109, 36.7603],
            [-95.5491, 36.7413],
            [-95.4317, 36.7716],
            [-95.4317, 36.757],
            [-95.4317, 36.6844],
            [-95.5715, 36.6409],
            [-95.5799, 36.5976],
            [-95.8096, 36.5975],
            [-95.8095, 36.6699],
            [-95.8098, 36.7715],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4021930',
        NAME: 'Nowata Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494751827,
        AWATER: 16963791,
        INTPTLAT: '+36.6811030',
        INTPTLON: '-095.6389369',
        ELSDLEA: '',
        UNSDLEA: '21930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6568, 34.9582],
            [-95.6545, 34.9633],
            [-95.5077, 34.9564],
            [-95.5075, 34.8549],
            [-95.3913, 34.8547],
            [-95.322, 34.7824],
            [-95.5143, 34.7531],
            [-95.5926, 34.8252],
            [-95.5643, 34.9129],
            [-95.6569, 34.9129],
            [-95.6568, 34.9582],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013920',
        NAME: 'Hartshorne Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322586747,
        AWATER: 11319199,
        INTPTLAT: '+34.8705690',
        INTPTLON: '-095.4882901',
        ELSDLEA: '',
        UNSDLEA: '13920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1033, 34.8982],
            [-94.998, 34.9308],
            [-94.8045, 34.9263],
            [-94.7562, 34.9073],
            [-94.7278, 34.7683],
            [-94.7278, 34.7676],
            [-94.8848, 34.767],
            [-94.8848, 34.8548],
            [-94.9899, 34.8551],
            [-94.9899, 34.797],
            [-95.1037, 34.7677],
            [-95.1473, 34.8104],
            [-95.1033, 34.8982],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4017280',
        NAME: 'LeFlore Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470007984,
        AWATER: 4829335,
        INTPTLAT: '+34.8997218',
        INTPTLON: '-094.8131750',
        ELSDLEA: '',
        UNSDLEA: '17280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9933, 34.5507],
            [-97.9933, 34.5579],
            [-97.8879, 34.587],
            [-97.8792, 34.5798],
            [-97.7833, 34.5289],
            [-97.8161, 34.4709],
            [-97.995, 34.478],
            [-97.9933, 34.5507],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010290',
        NAME: 'Duncan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170155534,
        AWATER: 3628205,
        INTPTLAT: '+34.5167176',
        INTPTLON: '-097.9081043',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5718, 34.5288],
            [-97.5628, 34.6811],
            [-97.4928, 34.7464],
            [-97.3528, 34.732],
            [-97.3003, 34.6955],
            [-97.2827, 34.681],
            [-97.3172, 34.5432],
            [-97.4051, 34.5068],
            [-97.5712, 34.507],
            [-97.5718, 34.5288],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010860',
        NAME: 'Elmore City-Pernell Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 568284772,
        AWATER: 3281380,
        INTPTLAT: '+34.6156654',
        INTPTLON: '-097.4291891',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1905, 33.9036],
            [-97.1608, 33.9546],
            [-97.0371, 33.9959],
            [-97.0877, 34.0709],
            [-97.0639, 34.0709],
            [-96.9696, 34.071],
            [-96.9348, 33.9545],
            [-97.0289, 33.8425],
            [-97.0868, 33.8538],
            [-97.1733, 33.8678],
            [-97.1905, 33.9036],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4018990',
        NAME: 'Marietta Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283312021,
        AWATER: 25004151,
        INTPTLAT: '+33.9512332',
        INTPTLON: '-097.0616138',
        ELSDLEA: '',
        UNSDLEA: '18990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4482, 34.1947],
            [-97.4482, 34.2599],
            [-97.2996, 34.26],
            [-97.3523, 34.1665],
            [-97.3696, 34.0567],
            [-97.4388, 34.0129],
            [-97.4741, 34.0711],
            [-97.4482, 34.1947],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032940',
        NAME: 'Wilson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 236118775,
        AWATER: 245408,
        INTPTLAT: '+34.1529570',
        INTPTLON: '-097.3958758',
        ELSDLEA: '',
        UNSDLEA: '32940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1733, 33.8678],
            [-97.0868, 33.8538],
            [-97.0861, 33.7454],
            [-97.1004, 33.7254],
            [-97.1628, 33.7291],
            [-97.2057, 33.8029],
            [-97.1733, 33.8678],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029850',
        NAME: 'Thackerville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148641735,
        AWATER: 7745380,
        INTPTLAT: '+33.8025164',
        INTPTLON: '-097.1306074',
        ELSDLEA: '',
        UNSDLEA: '29850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7424, 35.5939],
            [-96.6799, 35.6156],
            [-96.6211, 35.6151],
            [-96.6237, 35.4007],
            [-96.7313, 35.4234],
            [-96.798, 35.3766],
            [-96.8239, 35.4219],
            [-96.7864, 35.5866],
            [-96.7424, 35.5939],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4024930',
        NAME: 'Prague Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357874894,
        AWATER: 4485532,
        INTPTLAT: '+35.4953365',
        INTPTLON: '-096.7098098',
        ELSDLEA: '',
        UNSDLEA: '24930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1576, 36.0578],
            [-96.998, 36.051],
            [-96.918, 35.9418],
            [-96.9208, 35.819],
            [-96.9293, 35.8118],
            [-96.9736, 35.8482],
            [-97.1056, 35.8112],
            [-97.141, 35.8109],
            [-97.1767, 35.8474],
            [-97.1576, 36.0578],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4023800',
        NAME: 'Perkins-Tryon Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478780957,
        AWATER: 3274393,
        INTPTLAT: '+35.9392533',
        INTPTLON: '-097.0463567',
        ELSDLEA: '',
        UNSDLEA: '23800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.918, 35.9418],
            [-96.8649, 35.9422],
            [-96.7579, 35.9059],
            [-96.8316, 35.8186],
            [-96.9208, 35.819],
            [-96.918, 35.9418],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002550',
        NAME: 'Agra Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141155223,
        AWATER: 1138096,
        INTPTLAT: '+35.8778556',
        INTPTLON: '-096.8508677',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1851, 35.5581],
            [-97.1411, 35.6085],
            [-97.0791, 35.5937],
            [-97.0795, 35.5143],
            [-97.1325, 35.5144],
            [-97.1855, 35.4128],
            [-97.2212, 35.5143],
            [-97.1851, 35.5581],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013890',
        NAME: 'Harrah Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164638872,
        AWATER: 1572984,
        INTPTLAT: '+35.5142705',
        INTPTLON: '-097.1505296',
        ELSDLEA: '',
        UNSDLEA: '13890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7579, 35.9059],
            [-96.6779, 35.9421],
            [-96.6211, 35.9415],
            [-96.6192, 35.8643],
            [-96.6206, 35.6571],
            [-96.6211, 35.6151],
            [-96.6799, 35.6156],
            [-96.7074, 35.7602],
            [-96.8228, 35.7681],
            [-96.8316, 35.8186],
            [-96.7579, 35.9059],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029070',
        NAME: 'Stroud Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 408222931,
        AWATER: 6394582,
        INTPTLAT: '+35.8119030',
        INTPTLON: '-096.6815780',
        ELSDLEA: '',
        UNSDLEA: '29070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2085, 35.0579],
            [-95.0596, 35.0576],
            [-94.9037, 35.0727],
            [-94.8576, 35.0291],
            [-94.998, 34.9308],
            [-95.1033, 34.8982],
            [-95.1211, 34.9702],
            [-95.2085, 35.0579],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025590',
        NAME: 'Red Oak Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333667940,
        AWATER: 1596426,
        INTPTLAT: '+34.9957509',
        INTPTLON: '-095.0460371',
        ELSDLEA: '',
        UNSDLEA: '25590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0076, 36.9995],
            [-94.8669, 36.9989],
            [-94.8194, 36.9436],
            [-94.955, 36.9028],
            [-95.0076, 36.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4008490',
        NAME: 'Commerce Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146301086,
        AWATER: 1269361,
        INTPTLAT: '+36.9517861',
        INTPTLON: '-094.9250754',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.612, 35.8855],
            [-97.521, 35.9083],
            [-97.4883, 35.9927],
            [-97.3366, 35.9992],
            [-97.3435, 35.7761],
            [-97.2472, 35.7756],
            [-97.3184, 35.7397],
            [-97.4521, 35.7306],
            [-97.5229, 35.7759],
            [-97.5496, 35.7767],
            [-97.612, 35.8855],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4013560',
        NAME: 'Guthrie Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531810016,
        AWATER: 5570863,
        INTPTLAT: '+35.8544866',
        INTPTLON: '-097.4319484',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7387, 35.9345],
            [-97.6757, 36.0438],
            [-97.5596, 36.0507],
            [-97.4883, 35.9927],
            [-97.521, 35.9083],
            [-97.612, 35.8855],
            [-97.7128, 35.8848],
            [-97.7387, 35.9345],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4009000',
        NAME: 'Crescent Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354212734,
        AWATER: 2102474,
        INTPTLAT: '+35.9631919',
        INTPTLON: '-097.6139723',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5229, 35.7759],
            [-97.4521, 35.7306],
            [-97.3184, 35.7397],
            [-97.3183, 35.6452],
            [-97.3957, 35.602],
            [-97.4516, 35.6017],
            [-97.6119, 35.6021],
            [-97.5047, 35.6901],
            [-97.5229, 35.7759],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4010590',
        NAME: 'Edmond Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324839847,
        AWATER: 9258844,
        INTPTLAT: '+35.6720985',
        INTPTLON: '-097.4470090',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7128, 35.8848],
            [-97.612, 35.8855],
            [-97.5496, 35.7767],
            [-97.5584, 35.7768],
            [-97.5937, 35.7549],
            [-97.674, 35.726],
            [-97.8073, 35.7258],
            [-97.8339, 35.7766],
            [-97.7128, 35.8848],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006810',
        NAME: 'Cashion Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296444455,
        AWATER: 2557167,
        INTPTLAT: '+35.8057954',
        INTPTLON: '-097.7010198',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3096, 36.5357],
            [-97.2019, 36.5955],
            [-97.1067, 36.6263],
            [-97.0029, 36.5826],
            [-96.9074, 36.5939],
            [-97.0052, 36.4857],
            [-97.0854, 36.3408],
            [-97.1575, 36.3114],
            [-97.1931, 36.3839],
            [-97.3185, 36.4706],
            [-97.3096, 36.5357],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4000020',
        NAME: 'Frontier Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 662868616,
        AWATER: 15499544,
        INTPTLAT: '+36.4885370',
        INTPTLON: '-097.1450341',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.507, 35.9566],
            [-98.4408, 35.9849],
            [-98.347, 35.9677],
            [-98.3522, 35.9276],
            [-98.2247, 35.8411],
            [-98.2338, 35.7832],
            [-98.5611, 35.7759],
            [-98.5726, 35.8557],
            [-98.507, 35.9566],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031770',
        NAME: 'Watonga Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537759557,
        AWATER: 609642,
        INTPTLAT: '+35.8655470',
        INTPTLON: '-098.4151503',
        ELSDLEA: '',
        UNSDLEA: '31770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6716, 35.536],
            [-94.6479, 35.5515],
            [-94.6022, 35.5514],
            [-94.6023, 35.536],
            [-94.6695, 35.4201],
            [-94.7434, 35.4109],
            [-94.7517, 35.4925],
            [-94.6716, 35.536],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4007140',
        NAME: 'Central Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120077312,
        AWATER: 1174614,
        INTPTLAT: '+35.4857645',
        INTPTLON: '-094.6804236',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5132, 35.5285],
            [-94.4547, 35.5284],
            [-94.4521, 35.5126],
            [-94.4357, 35.4195],
            [-94.4728, 35.3758],
            [-94.4864, 35.3468],
            [-94.5489, 35.4125],
            [-94.4779, 35.4413],
            [-94.5132, 35.5285],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026310',
        NAME: 'Roland Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103813246,
        AWATER: 2205704,
        INTPTLAT: '+35.4380695',
        INTPTLON: '-094.4826554',
        ELSDLEA: '',
        UNSDLEA: '26310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.007, 35.6383],
            [-95.0003, 35.6384],
            [-94.8605, 35.6387],
            [-94.84, 35.5591],
            [-94.8332, 35.5214],
            [-94.92, 35.5071],
            [-94.9446, 35.4025],
            [-94.9462, 35.4045],
            [-95.0499, 35.4589],
            [-95.0614, 35.4651],
            [-95.0261, 35.4929],
            [-95.007, 35.6383],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4031140',
        NAME: 'Vian Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325691803,
        AWATER: 25087358,
        INTPTLAT: '+35.5440188',
        INTPTLON: '-094.9408140',
        ELSDLEA: '',
        UNSDLEA: '31140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1725, 35.5955],
            [-95.1275, 35.6386],
            [-95.0234, 35.6297],
            [-95.027, 35.4932],
            [-95.0623, 35.4651],
            [-95.1712, 35.5548],
            [-95.1725, 35.5955],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012900',
        NAME: 'Gore Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161627620,
        AWATER: 19835151,
        INTPTLAT: '+35.5559372',
        INTPTLON: '-095.0852293',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7434, 35.4109],
            [-94.6695, 35.4201],
            [-94.5791, 35.3288],
            [-94.648, 35.2912],
            [-94.7777, 35.3514],
            [-94.7434, 35.4109],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4012390',
        NAME: 'Gans Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126393035,
        AWATER: 8543849,
        INTPTLAT: '+35.3599991',
        INTPTLON: '-094.6771703',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.6477, 35.6385],
              [-94.6299, 35.6383],
              [-94.6022, 35.5514],
              [-94.6479, 35.5515],
              [-94.6477, 35.6385],
            ],
          ],
          [
            [
              [-94.7537, 35.6386],
              [-94.7012, 35.6384],
              [-94.7542, 35.5805],
              [-94.7537, 35.6386],
            ],
          ],
          [
            [
              [-94.8332, 35.5214],
              [-94.6716, 35.536],
              [-94.7517, 35.4925],
              [-94.7434, 35.4109],
              [-94.7777, 35.3514],
              [-94.7778, 35.3514],
              [-94.7816, 35.3472],
              [-94.7963, 35.3265],
              [-94.8291, 35.3617],
              [-94.8371, 35.3695],
              [-94.9446, 35.4025],
              [-94.92, 35.5071],
              [-94.8332, 35.5214],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026790',
        NAME: 'Sallisaw Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315703385,
        AWATER: 40501276,
        INTPTLAT: '+35.4731622',
        INTPTLON: '-094.7907435',
        ELSDLEA: '',
        UNSDLEA: '26790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6132, 35.0212],
            [-96.5974, 35.0579],
            [-96.5288, 35.0796],
            [-96.4906, 35.0941],
            [-96.4781, 34.9164],
            [-96.5105, 34.8786],
            [-96.5904, 34.9092],
            [-96.6132, 35.0212],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026940',
        NAME: 'Sasakwa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212510800,
        AWATER: 3296748,
        INTPTLAT: '+34.9968205',
        INTPTLON: '-096.5375078',
        ELSDLEA: '',
        UNSDLEA: '26940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5303, 35.1885],
            [-96.4413, 35.2029],
            [-96.4722, 35.1158],
            [-96.538, 35.1302],
            [-96.5303, 35.1885],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4032460',
        NAME: 'Wewoka Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90147963,
        AWATER: 793139,
        INTPTLAT: '+35.1687230',
        INTPTLON: '-096.4790173',
        ELSDLEA: '',
        UNSDLEA: '32460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4906, 35.0941],
            [-96.4722, 35.1158],
            [-96.4413, 35.2029],
            [-96.397, 35.232],
            [-96.318, 35.1669],
            [-96.3533, 35.1014],
            [-96.3, 34.9832],
            [-96.3019, 34.9564],
            [-96.4781, 34.9164],
            [-96.4906, 35.0941],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4014790',
        NAME: 'Holdenville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384732368,
        AWATER: 6711589,
        INTPTLAT: '+35.0556377',
        INTPTLON: '-096.3969590',
        ELSDLEA: '',
        UNSDLEA: '14790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8074, 35.7692],
            [-94.7367, 35.7688],
            [-94.7013, 35.7687],
            [-94.7008, 35.7251],
            [-94.8075, 35.6602],
            [-94.8078, 35.6822],
            [-94.8074, 35.7692],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006930',
        NAME: 'Cave Springs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100539272,
        AWATER: 968386,
        INTPTLAT: '+35.7281771',
        INTPTLON: '-094.7581396',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0926, 34.4913],
            [-95.8818, 34.5064],
            [-95.829, 34.5936],
            [-95.6979, 34.594],
            [-95.7066, 34.5068],
            [-95.7794, 34.5066],
            [-95.7792, 34.4176],
            [-95.8664, 34.4173],
            [-95.8833, 34.3885],
            [-95.8993, 34.3885],
            [-95.9008, 34.374],
            [-95.9882, 34.3744],
            [-96.0051, 34.3745],
            [-96.0402, 34.4211],
            [-96.0926, 34.4913],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4029010',
        NAME: 'Stringtown Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 443886488,
        AWATER: 11398573,
        INTPTLAT: '+34.4772883',
        INTPTLON: '-095.9245766',
        ELSDLEA: '',
        UNSDLEA: '29010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3379, 34.2732],
            [-96.2154, 34.273],
            [-96.1104, 34.1996],
            [-96.1022, 34.1865],
            [-96.2849, 34.1574],
            [-96.3378, 34.2008],
            [-96.3379, 34.2732],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4006420',
        NAME: 'Caney Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218247157,
        AWATER: 1635993,
        INTPTLAT: '+34.2143071',
        INTPTLON: '-096.2230280',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3638, 36.3726],
            [-95.1221, 36.3725],
            [-95.1424, 36.3508],
            [-95.2393, 36.2347],
            [-95.2455, 36.2201],
            [-95.3325, 36.2493],
            [-95.3638, 36.3726],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4025200',
        NAME: 'Pryor Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246622338,
        AWATER: 11335863,
        INTPTLAT: '+36.3140902',
        INTPTLON: '-095.2654689',
        ELSDLEA: '',
        UNSDLEA: '25200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1424, 36.3508],
            [-95.1126, 36.3362],
            [-95.0058, 36.3074],
            [-95.0058, 36.2929],
            [-95.0112, 36.2344],
            [-95.0112, 36.2344],
            [-95.2393, 36.2347],
            [-95.1424, 36.3508],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4026730',
        NAME: 'Salina Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137878305,
        AWATER: 21607815,
        INTPTLAT: '+36.2753161',
        INTPTLON: '-095.1108956',
        ELSDLEA: '',
        UNSDLEA: '26730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3282, 36.5102],
            [-95.116, 36.4887],
            [-95.0413, 36.4358],
            [-95.1221, 36.3725],
            [-95.3638, 36.3726],
            [-95.4355, 36.3725],
            [-95.4356, 36.3906],
            [-95.4356, 36.4085],
            [-95.4356, 36.4522],
            [-95.3282, 36.5102],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4002460',
        NAME: 'Adair Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396960743,
        AWATER: 11795681,
        INTPTLAT: '+36.4309120',
        INTPTLON: '-095.2477823',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.544, 34.1714],
              [-95.5352, 34.1568],
              [-95.5433, 34.1568],
              [-95.544, 34.1714],
            ],
          ],
          [
            [
              [-95.7792, 34.2727],
              [-95.6746, 34.2726],
              [-95.5702, 34.3309],
              [-95.5708, 34.4326],
              [-95.2907, 34.4322],
              [-95.2037, 34.4175],
              [-95.2559, 34.3309],
              [-95.3606, 34.3307],
              [-95.4653, 34.2881],
              [-95.4827, 34.1713],
              [-95.544, 34.1714],
              [-95.5552, 34.1569],
              [-95.5565, 34.1569],
              [-95.7794, 34.1567],
              [-95.7792, 34.2727],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4003060',
        NAME: 'Antlers Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 838558952,
        AWATER: 6093021,
        INTPTLAT: '+34.3100221',
        INTPTLON: '-095.5067647',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.4484, 35.1896],
            [-99.3602, 35.1895],
            [-99.3645, 35.2841],
            [-99.2584, 35.3059],
            [-99.188, 35.2333],
            [-99.073, 35.2333],
            [-98.9165, 35.1969],
            [-98.9904, 35.1165],
            [-99.1313, 35.1167],
            [-99.1841, 35.0657],
            [-99.2282, 35.1095],
            [-99.4042, 35.1094],
            [-99.4093, 35.1145],
            [-99.4484, 35.1896],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4027330',
        NAME: 'Sentinel Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660040534,
        AWATER: 3863777,
        INTPTLAT: '+35.1687723',
        INTPTLON: '-099.1942936',
        ELSDLEA: '',
        UNSDLEA: '27330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0648, 36.1389],
            [-96.0474, 36.0973],
            [-96.101, 36.0974],
            [-96.0648, 36.1389],
          ],
        ],
      },
      properties: {
        STATEFP: '40',
        SCSDLEA: '',
        GEOID: '4004020',
        NAME: 'Berryhill Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22937502,
        AWATER: 1467889,
        INTPTLAT: '+36.1209349',
        INTPTLON: '-096.0690662',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
  ],
};

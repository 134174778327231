export const NE = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.0594, 41.7136],
              [-97.0496, 41.7136],
              [-97.0501, 41.7065],
              [-97.0595, 41.7065],
              [-97.0594, 41.7136],
            ],
          ],
          [
            [
              [-97.0595, 41.7065],
              [-97.05, 41.6991],
              [-97.0501, 41.6918],
              [-97.0547, 41.6919],
              [-97.0618, 41.6886],
              [-97.0655, 41.6886],
              [-97.0595, 41.7065],
            ],
          ],
          [
            [
              [-97.1654, 41.6189],
              [-97.1652, 41.6266],
              [-97.1559, 41.6411],
              [-97.1511, 41.6482],
              [-97.1173, 41.6441],
              [-97.122, 41.6411],
              [-97.1421, 41.623],
              [-97.1461, 41.6048],
              [-97.156, 41.6094],
              [-97.1522, 41.6121],
              [-97.1654, 41.6189],
            ],
          ],
          [
            [
              [-97.1945, 41.6162],
              [-97.1944, 41.624],
              [-97.1944, 41.6268],
              [-97.186, 41.6341],
              [-97.1805, 41.6267],
              [-97.1752, 41.6267],
              [-97.1752, 41.619],
              [-97.1853, 41.6123],
              [-97.1945, 41.6123],
              [-97.1945, 41.6162],
            ],
          ],
          [
            [
              [-97.2329, 41.627],
              [-97.2233, 41.627],
              [-97.2329, 41.6199],
              [-97.2329, 41.627],
            ],
          ],
          [
            [
              [-97.2526, 41.5691],
              [-97.1945, 41.6047],
              [-97.1853, 41.6046],
              [-97.1461, 41.6048],
              [-97.0888, 41.6265],
              [-97.0786, 41.6221],
              [-97.0786, 41.6265],
              [-97.0887, 41.6302],
              [-97.0549, 41.6846],
              [-97.0551, 41.6812],
              [-97.0552, 41.6774],
              [-97.0451, 41.6773],
              [-97.0209, 41.6848],
              [-97.0209, 41.6629],
              [-97.0206, 41.6304],
              [-97.0206, 41.6268],
              [-97.0027, 41.6124],
              [-96.9983, 41.6089],
              [-96.993, 41.6122],
              [-96.9347, 41.612],
              [-96.9252, 41.612],
              [-96.906, 41.5974],
              [-96.9083, 41.3796],
              [-96.9083, 41.376],
              [-96.9085, 41.3651],
              [-96.9758, 41.3766],
              [-97.0038, 41.3689],
              [-97.1006, 41.3651],
              [-97.2535, 41.3842],
              [-97.2526, 41.5691],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176450',
        NAME: 'Schuyler Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774543943,
        AWATER: 16239706,
        INTPTLAT: '+41.5044115',
        INTPTLON: '-097.0698247',
        ELSDLEA: '',
        UNSDLEA: '76450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-101.4956, 42.9971],
              [-101.4264, 42.9972],
              [-101.4563, 42.9557],
              [-101.4956, 42.9971],
            ],
          ],
          [
            [
              [-102.713, 42.9659],
              [-102.713, 42.9775],
              [-102.7128, 43],
              [-102.0825, 42.9994],
              [-101.5142, 42.9971],
              [-101.5151, 42.9618],
              [-101.5252, 42.9358],
              [-101.5504, 42.916],
              [-101.5497, 42.9023],
              [-101.6137, 42.9002],
              [-101.5979, 42.7592],
              [-101.6962, 42.7606],
              [-101.7555, 42.6162],
              [-101.95, 42.6163],
              [-102.0664, 42.6834],
              [-102.0667, 42.4435],
              [-102.0382, 42.4433],
              [-101.9551, 42.3734],
              [-101.9168, 42.2564],
              [-102.0392, 42.2559],
              [-102.0996, 42.2128],
              [-102.2744, 42.2115],
              [-102.3621, 42.1551],
              [-102.4441, 42.0559],
              [-102.519, 42.0637],
              [-102.5091, 42.226],
              [-102.5494, 42.2723],
              [-102.5149, 42.3569],
              [-102.5343, 42.3567],
              [-102.5437, 42.3567],
              [-102.5539, 42.3568],
              [-102.5934, 42.3569],
              [-102.5931, 42.3708],
              [-102.6123, 42.3857],
              [-102.5236, 42.3996],
              [-102.6548, 42.5332],
              [-102.6552, 42.672],
              [-102.6188, 42.7232],
              [-102.6938, 42.8569],
              [-102.7019, 42.8942],
              [-102.7097, 42.8996],
              [-102.7097, 42.9052],
              [-102.6943, 42.958],
              [-102.713, 42.9659],
            ],
          ],
          [
            [
              [-102.7496, 42.4296],
              [-102.7494, 42.4399],
              [-102.731, 42.4209],
              [-102.7496, 42.4296],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100170',
        NAME: 'Gordon-Rushville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5772603339,
        AWATER: 50468366,
        INTPTLAT: '+42.6542331',
        INTPTLON: '-102.2417863',
        ELSDLEA: '',
        UNSDLEA: '00170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.6359, 42.0871],
              [-100.3656, 42.0867],
              [-100.3625, 41.7396],
              [-100.7117, 41.7398],
              [-100.8425, 41.7404],
              [-100.8461, 42.0006],
              [-100.7497, 42],
              [-100.6359, 42.0871],
            ],
          ],
          [
            [
              [-100.7088, 42.1919],
              [-100.6359, 42.1172],
              [-100.6762, 42.1169],
              [-100.6915, 42.0875],
              [-100.8677, 42.0882],
              [-100.869, 42.2193],
              [-100.7088, 42.1919],
            ],
          ],
          [
            [
              [-100.709, 42.2494],
              [-100.8585, 42.3576],
              [-100.8679, 42.4265],
              [-100.693, 42.4227],
              [-100.5958, 42.3505],
              [-100.5278, 42.2412],
              [-100.709, 42.2494],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177730',
        NAME: 'Thedford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1948358875,
        AWATER: 4497551,
        INTPTLAT: '+42.0281856',
        INTPTLON: '-100.6085710',
        ELSDLEA: '',
        UNSDLEA: '77730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9003, 40.423],
            [-97.8243, 40.4521],
            [-97.7859, 40.5245],
            [-97.6529, 40.4809],
            [-97.634, 40.423],
            [-97.6004, 40.3971],
            [-97.5962, 40.3939],
            [-97.5962, 40.3868],
            [-97.6072, 40.3869],
            [-97.6151, 40.3868],
            [-97.6341, 40.3796],
            [-97.6894, 40.3363],
            [-97.8812, 40.3505],
            [-97.9003, 40.423],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176650',
        NAME: 'Shickley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325926661,
        AWATER: 305171,
        INTPTLAT: '+40.4150511',
        INTPTLON: '-097.7589854',
        ELSDLEA: '',
        UNSDLEA: '76650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5404, 40.7277],
            [-98.5307, 40.7277],
            [-98.5307, 40.7346],
            [-98.4444, 40.7863],
            [-98.2826, 40.8687],
            [-98.2781, 40.821],
            [-98.2828, 40.7638],
            [-98.165, 40.6984],
            [-98.2223, 40.6117],
            [-98.3449, 40.6764],
            [-98.4296, 40.6696],
            [-98.5539, 40.6987],
            [-98.5563, 40.6987],
            [-98.5689, 40.6987],
            [-98.5404, 40.7277],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100121',
        NAME: 'Doniphan-Trumbull Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403627886,
        AWATER: 4783786,
        INTPTLAT: '+40.7309482',
        INTPTLON: '-098.3480936',
        ELSDLEA: '',
        UNSDLEA: '00121',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3967, 40.4085],
              [-97.3877, 40.4156],
              [-97.3688, 40.423],
              [-97.3688, 40.3794],
              [-97.3777, 40.3795],
              [-97.3873, 40.3869],
              [-97.3966, 40.3869],
              [-97.3967, 40.4085],
            ],
          ],
          [
            [
              [-97.416, 40.4375],
              [-97.4066, 40.4374],
              [-97.3878, 40.4301],
              [-97.3968, 40.4301],
              [-97.3968, 40.423],
              [-97.4065, 40.416],
              [-97.4155, 40.416],
              [-97.4162, 40.4231],
              [-97.4253, 40.4303],
              [-97.416, 40.4375],
            ],
          ],
          [
            [
              [-97.4531, 40.4114],
              [-97.4523, 40.4114],
              [-97.4523, 40.4112],
              [-97.4531, 40.4114],
            ],
          ],
          [
            [
              [-97.4723, 40.4739],
              [-97.4306, 40.4663],
              [-97.4348, 40.4593],
              [-97.4723, 40.4666],
              [-97.4723, 40.4739],
            ],
          ],
          [
            [
              [-97.7078, 40.2926],
              [-97.6895, 40.2927],
              [-97.7079, 40.2852],
              [-97.7078, 40.2926],
            ],
          ],
          [
            [
              [-97.8812, 40.3505],
              [-97.6894, 40.3363],
              [-97.6341, 40.3796],
              [-97.6151, 40.3868],
              [-97.6072, 40.3869],
              [-97.5962, 40.3868],
              [-97.5962, 40.3939],
              [-97.558, 40.3941],
              [-97.5395, 40.4087],
              [-97.5205, 40.4085],
              [-97.4916, 40.3941],
              [-97.4825, 40.387],
              [-97.4635, 40.4232],
              [-97.4529, 40.4232],
              [-97.4495, 40.4138],
              [-97.4495, 40.4124],
              [-97.4252, 40.416],
              [-97.4253, 40.4086],
              [-97.3966, 40.3869],
              [-97.3966, 40.3795],
              [-97.3777, 40.3724],
              [-97.3686, 40.3674],
              [-97.4822, 40.2629],
              [-97.6895, 40.2927],
              [-97.7078, 40.2926],
              [-97.7079, 40.2852],
              [-97.6982, 40.2635],
              [-97.8301, 40.198],
              [-97.854, 40.2023],
              [-97.8765, 40.1907],
              [-97.8769, 40.1996],
              [-97.877, 40.2053],
              [-97.8678, 40.2169],
              [-97.8739, 40.2227],
              [-97.9535, 40.3217],
              [-97.8812, 40.3505],
            ],
            [
              [-97.558, 40.3941],
              [-97.5391, 40.3869],
              [-97.5391, 40.3941],
              [-97.558, 40.3941],
            ],
            [
              [-97.4507, 40.4131],
              [-97.4523, 40.4125],
              [-97.4523, 40.4122],
              [-97.4507, 40.4131],
            ],
            [
              [-97.4523, 40.4122],
              [-97.4531, 40.4114],
              [-97.4532, 40.3941],
              [-97.4523, 40.4112],
              [-97.4523, 40.4114],
              [-97.4523, 40.4122],
            ],
            [
              [-97.4523, 40.4125],
              [-97.4536, 40.4132],
              [-97.4536, 40.4125],
              [-97.4523, 40.4125],
            ],
            [
              [-97.4536, 40.4125],
              [-97.456, 40.4122],
              [-97.456, 40.4136],
              [-97.4523, 40.4134],
              [-97.4517, 40.4145],
              [-97.4635, 40.4157],
              [-97.4634, 40.4121],
              [-97.4545, 40.4119],
              [-97.4536, 40.4125],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100124',
        NAME: 'Bruning-Davenport Unified School System',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 598478415,
        AWATER: 626601,
        INTPTLAT: '+40.3179478',
        INTPTLON: '-097.6665883',
        ELSDLEA: '',
        UNSDLEA: '00124',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7457, 41.314],
              [-96.7457, 41.3142],
              [-96.7366, 41.3141],
              [-96.7366, 41.3112],
              [-96.7458, 41.3111],
              [-96.7457, 41.314],
            ],
          ],
          [
            [
              [-96.906, 41.5974],
              [-96.8096, 41.6263],
              [-96.7613, 41.6263],
              [-96.7324, 41.6187],
              [-96.7324, 41.6116],
              [-96.6456, 41.5608],
              [-96.5586, 41.5029],
              [-96.6179, 41.4344],
              [-96.6973, 41.3825],
              [-96.7078, 41.3441],
              [-96.7173, 41.3508],
              [-96.7218, 41.3461],
              [-96.7269, 41.3462],
              [-96.7368, 41.3425],
              [-96.7368, 41.3363],
              [-96.7456, 41.3176],
              [-96.7561, 41.3217],
              [-96.7562, 41.3287],
              [-96.7846, 41.3144],
              [-96.7894, 41.3073],
              [-96.885, 41.3614],
              [-96.8899, 41.3579],
              [-96.9085, 41.3651],
              [-96.9083, 41.376],
              [-96.9083, 41.3796],
              [-96.906, 41.5974],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100115',
        NAME: 'North Bend Central Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644564553,
        AWATER: 15679335,
        INTPTLAT: '+41.4784660',
        INTPTLON: '-096.7695141',
        ELSDLEA: '',
        UNSDLEA: '00115',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.4348, 41.4952],
              [-96.4252, 41.491],
              [-96.4543, 41.4804],
              [-96.4348, 41.4952],
            ],
          ],
          [
            [
              [-96.4639, 41.3571],
              [-96.4532, 41.3643],
              [-96.4449, 41.3538],
              [-96.4449, 41.3522],
              [-96.4639, 41.3571],
            ],
          ],
          [
            [
              [-96.4542, 41.3424],
              [-96.4543, 41.3353],
              [-96.464, 41.3353],
              [-96.4542, 41.3424],
            ],
          ],
          [
            [
              [-96.6021, 41.3507],
              [-96.5923, 41.3544],
              [-96.5827, 41.3543],
              [-96.5923, 41.3437],
              [-96.6022, 41.3434],
              [-96.6021, 41.3507],
            ],
          ],
          [
            [
              [-96.5586, 41.5029],
              [-96.5303, 41.495],
              [-96.5063, 41.4804],
              [-96.4248, 41.4589],
              [-96.3307, 41.3931],
              [-96.4219, 41.3645],
              [-96.4351, 41.369],
              [-96.4629, 41.3709],
              [-96.4639, 41.3702],
              [-96.5111, 41.3452],
              [-96.5218, 41.3506],
              [-96.5264, 41.3572],
              [-96.5413, 41.3617],
              [-96.5413, 41.3693],
              [-96.6179, 41.4344],
              [-96.5586, 41.5029],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170710',
        NAME: 'Fremont Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192142842,
        AWATER: 14755273,
        INTPTLAT: '+41.4178563',
        INTPTLON: '-096.4795631',
        ELSDLEA: '',
        UNSDLEA: '70710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5692, 41.9076],
              [-96.5648, 41.9075],
              [-96.5552, 41.8869],
              [-96.5648, 41.8915],
              [-96.5639, 41.9041],
              [-96.5692, 41.9076],
            ],
          ],
          [
            [
              [-96.8258, 42.0253],
              [-96.8163, 42.0252],
              [-96.8065, 42.0179],
              [-96.7872, 42.0105],
              [-96.7971, 42.0104],
              [-96.8258, 42.0253],
            ],
          ],
          [
            [
              [-96.9422, 41.8298],
              [-96.9228, 41.8529],
              [-96.9033, 41.8587],
              [-96.8746, 41.9681],
              [-96.8541, 41.9677],
              [-96.8167, 41.9966],
              [-96.8065, 41.9967],
              [-96.798, 41.9968],
              [-96.7872, 42.0033],
              [-96.7872, 42.0105],
              [-96.7683, 42.0107],
              [-96.7583, 42.0036],
              [-96.7583, 42.0107],
              [-96.71, 42.0243],
              [-96.7101, 42.0167],
              [-96.7004, 42.0108],
              [-96.5938, 41.922],
              [-96.5842, 41.9085],
              [-96.5842, 41.9078],
              [-96.5792, 41.9076],
              [-96.5746, 41.9077],
              [-96.5746, 41.9015],
              [-96.5803, 41.8942],
              [-96.5747, 41.887],
              [-96.5651, 41.8832],
              [-96.5553, 41.8832],
              [-96.5743, 41.7711],
              [-96.6046, 41.7675],
              [-96.6035, 41.7656],
              [-96.5903, 41.7542],
              [-96.7681, 41.7425],
              [-96.7809, 41.7425],
              [-96.7907, 41.7426],
              [-96.7969, 41.7426],
              [-96.8025, 41.7426],
              [-96.9422, 41.8298],
            ],
            [
              [-96.7583, 42.0036],
              [-96.7583, 41.9961],
              [-96.7488, 41.9961],
              [-96.7489, 42.0036],
              [-96.7583, 42.0036],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100006',
        NAME: 'West Point Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 675151390,
        AWATER: 6775644,
        INTPTLAT: '+41.8796947',
        INTPTLON: '-096.7658275',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4011, 40.6258],
            [-101.3539, 40.6393],
            [-101.345, 40.6538],
            [-101.2025, 40.4588],
            [-101.2304, 40.452],
            [-101.0228, 40.408],
            [-100.9761, 40.3505],
            [-100.9669, 40.3504],
            [-100.9472, 40.35],
            [-100.9845, 40.2639],
            [-101.0978, 40.2487],
            [-101.1732, 40.2344],
            [-101.211, 40.3509],
            [-101.3242, 40.3649],
            [-101.3253, 40.2634],
            [-101.4748, 40.2479],
            [-101.5501, 40.3285],
            [-101.4944, 40.3647],
            [-101.4855, 40.51],
            [-101.4011, 40.6258],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100018',
        NAME: 'Wauneta-Palisade Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1181477468,
        AWATER: 545696,
        INTPTLAT: '+40.4168772',
        INTPTLON: '-101.2812506',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0516, 40.2659],
            [-101.9759, 40.3493],
            [-101.8293, 40.3346],
            [-101.7203, 40.2639],
            [-101.6269, 40.2638],
            [-101.5501, 40.3285],
            [-101.4748, 40.2479],
            [-101.3253, 40.2634],
            [-101.3242, 40.3649],
            [-101.211, 40.3509],
            [-101.1732, 40.2344],
            [-101.0978, 40.2487],
            [-101.0793, 40.1517],
            [-101.098, 40.0024],
            [-101.1869, 40.0025],
            [-101.5978, 40.0027],
            [-102.0517, 40.0031],
            [-102.0516, 40.2659],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100077',
        NAME: 'Dundy County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2612835505,
        AWATER: 21189999,
        INTPTLAT: '+40.1553520',
        INTPTLON: '-101.5780853',
        ELSDLEA: '',
        UNSDLEA: '00077',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9164, 40.0015],
              [-96.9192, 40.0015],
              [-96.9307, 40.0015],
              [-96.9832, 40.0016],
              [-96.9541, 40.0307],
              [-96.9164, 40.0015],
            ],
          ],
          [
            [
              [-97.3782, 40.1907],
              [-97.3312, 40.2052],
              [-97.2361, 40.35],
              [-97.2077, 40.35],
              [-97.1978, 40.35],
              [-97.1791, 40.3499],
              [-97.1723, 40.335],
              [-97.1504, 40.335],
              [-97.0674, 40.2045],
              [-96.9542, 40.2047],
              [-96.9731, 40.1755],
              [-96.9826, 40.1755],
              [-96.9828, 40.1755],
              [-97.0013, 40.1536],
              [-97.0108, 40.1465],
              [-97.0728, 40.0449],
              [-97.0102, 40.0014],
              [-97.0131, 40.0014],
              [-97.4068, 40.002],
              [-97.4351, 40.002],
              [-97.3782, 40.1907],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170440',
        NAME: 'Fairbury Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 978833605,
        AWATER: 8772144,
        INTPTLAT: '+40.1349798',
        INTPTLON: '-097.2113764',
        ELSDLEA: '',
        UNSDLEA: '70440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7083, 40.0601],
              [-97.7176, 40.0529],
              [-97.7272, 40.0529],
              [-97.7083, 40.0601],
            ],
          ],
          [
            [
              [-97.844, 40.0747],
              [-97.7934, 40.0746],
              [-97.7934, 40.0674],
              [-97.7838, 40.0602],
              [-97.7461, 40.0672],
              [-97.7272, 40.0529],
              [-97.7176, 40.0529],
              [-97.7083, 40.0601],
              [-97.6986, 40.0382],
              [-97.6329, 40.1181],
              [-97.6982, 40.2635],
              [-97.7079, 40.2852],
              [-97.6895, 40.2927],
              [-97.4822, 40.2629],
              [-97.3782, 40.1907],
              [-97.4351, 40.002],
              [-97.8328, 40.002],
              [-97.8403, 40.0021],
              [-97.8704, 40.0021],
              [-97.844, 40.0747],
            ],
            [
              [-97.8402, 40.0238],
              [-97.8213, 40.0311],
              [-97.8212, 40.037],
              [-97.8402, 40.0238],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100132',
        NAME: 'Thayer Central Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 722261771,
        AWATER: 1956297,
        INTPTLAT: '+40.1254162',
        INTPTLON: '-097.5667363',
        ELSDLEA: '',
        UNSDLEA: '00132',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1224, 40.4298],
              [-97.1133, 40.4297],
              [-97.1133, 40.4222],
              [-97.1178, 40.4222],
              [-97.1224, 40.4298],
            ],
          ],
          [
            [
              [-97.2007, 40.3944],
              [-97.1951, 40.3935],
              [-97.1993, 40.3935],
              [-97.1997, 40.3935],
              [-97.2007, 40.3944],
            ],
          ],
          [
            [
              [-97.3966, 40.3795],
              [-97.3873, 40.3869],
              [-97.3777, 40.3795],
              [-97.3777, 40.3724],
              [-97.3966, 40.3795],
            ],
          ],
          [
            [
              [-97.3968, 40.4301],
              [-97.3878, 40.4301],
              [-97.3968, 40.423],
              [-97.3968, 40.4301],
            ],
          ],
          [
            [
              [-97.4065, 40.416],
              [-97.3877, 40.4156],
              [-97.3967, 40.4085],
              [-97.4064, 40.4121],
              [-97.4065, 40.416],
            ],
          ],
          [
            [
              [-97.4252, 40.416],
              [-97.4155, 40.416],
              [-97.4253, 40.4086],
              [-97.4252, 40.416],
            ],
          ],
          [
            [
              [-97.4822, 40.2629],
              [-97.3686, 40.3674],
              [-97.3688, 40.3794],
              [-97.3688, 40.423],
              [-97.3687, 40.4374],
              [-97.2361, 40.4589],
              [-97.2171, 40.4517],
              [-97.2075, 40.4482],
              [-97.1793, 40.437],
              [-97.179, 40.4225],
              [-97.1789, 40.4153],
              [-97.1789, 40.408],
              [-97.198, 40.408],
              [-97.2361, 40.35],
              [-97.3312, 40.2052],
              [-97.3782, 40.1907],
              [-97.4822, 40.2629],
            ],
          ],
          [
            [
              [-97.4916, 40.3941],
              [-97.488, 40.3941],
              [-97.4825, 40.3941],
              [-97.4825, 40.387],
              [-97.4916, 40.3941],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173660',
        NAME: 'Meridian Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404000097,
        AWATER: 1107454,
        INTPTLAT: '+40.3283461',
        INTPTLON: '-097.3206876',
        ELSDLEA: '',
        UNSDLEA: '73660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.2932, 41.0253],
              [-96.2736, 41.0199],
              [-96.293, 41.0086],
              [-96.2932, 41.0253],
            ],
          ],
          [
            [
              [-96.3026, 40.9976],
              [-96.3026, 40.9936],
              [-96.3077, 40.9889],
              [-96.3026, 40.9976],
            ],
          ],
          [
            [
              [-96.4266, 40.9509],
              [-96.3077, 40.9889],
              [-96.2929, 40.9966],
              [-96.1872, 40.9364],
              [-96.2365, 40.8632],
              [-96.1977, 40.8367],
              [-96.2064, 40.813],
              [-96.2168, 40.8129],
              [-96.2365, 40.8204],
              [-96.2365, 40.8127],
              [-96.2264, 40.8128],
              [-96.2263, 40.8059],
              [-96.2932, 40.7479],
              [-96.3496, 40.7764],
              [-96.4266, 40.9509],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3101992',
        NAME: 'Elmwood-Murdock Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293260711,
        AWATER: 850333,
        INTPTLAT: '+40.8821210',
        INTPTLON: '-096.2829770',
        ELSDLEA: '',
        UNSDLEA: '01992',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.064, 40.9508],
              [-96.0357, 40.9514],
              [-96.0257, 40.9439],
              [-96.0258, 40.9362],
              [-96.0449, 40.9362],
              [-96.0548, 40.9439],
              [-96.064, 40.9508],
            ],
          ],
          [
            [
              [-96.0447, 41.002],
              [-96.068, 41.0606],
              [-95.8795, 41.0533],
              [-95.833, 40.9587],
              [-96.0186, 40.9584],
              [-96.0447, 41.002],
            ],
            [
              [-96.0119, 40.9958],
              [-96.0065, 40.9875],
              [-96.0091, 40.9958],
              [-96.0119, 40.9958],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175660',
        NAME: 'Plattsmouth Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151182143,
        AWATER: 7459827,
        INTPTLAT: '+41.0085583',
        INTPTLON: '-095.9360992',
        ELSDLEA: '',
        UNSDLEA: '75660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8663, 41.4376],
            [-98.8368, 41.4667],
            [-98.8473, 41.4885],
            [-98.7512, 41.5718],
            [-98.7428, 41.6611],
            [-98.7522, 41.7404],
            [-98.6365, 41.6751],
            [-98.6358, 41.5257],
            [-98.5783, 41.5166],
            [-98.5832, 41.394],
            [-98.7466, 41.3514],
            [-98.8209, 41.3511],
            [-98.8663, 41.4376],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174400',
        NAME: 'North Loup Scotia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 607030222,
        AWATER: 3713394,
        INTPTLAT: '+41.5067174',
        INTPTLON: '-098.7008281',
        ELSDLEA: '',
        UNSDLEA: '74400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7466, 41.3514],
            [-98.5832, 41.394],
            [-98.5345, 41.3941],
            [-98.5171, 41.2493],
            [-98.6593, 41.2714],
            [-98.6894, 41.279],
            [-98.7466, 41.3514],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170020',
        NAME: 'Elba Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244483319,
        AWATER: 1978067,
        INTPTLAT: '+41.3165392',
        INTPTLON: '-098.6147125',
        ELSDLEA: '',
        UNSDLEA: '70020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.0109, 41.4088],
              [-99.0091, 41.4088],
              [-99.0015, 41.4088],
              [-99.0112, 41.4013],
              [-99.0109, 41.4088],
            ],
          ],
          [
            [
              [-99.2801, 41.3869],
              [-99.2617, 41.3795],
              [-99.2714, 41.3795],
              [-99.2807, 41.3832],
              [-99.2801, 41.3869],
            ],
          ],
          [
            [
              [-99.28, 41.4664],
              [-99.2508, 41.4811],
              [-99.2463, 41.481],
              [-99.2403, 41.481],
              [-99.2217, 41.4808],
              [-99.2123, 41.481],
              [-99.1354, 41.5296],
              [-98.9621, 41.4302],
              [-98.9819, 41.3943],
              [-99.0015, 41.4088],
              [-99.0109, 41.4099],
              [-99.0109, 41.4088],
              [-99.0206, 41.4088],
              [-99.0398, 41.3943],
              [-99.0498, 41.4088],
              [-99.0519, 41.4088],
              [-99.0594, 41.3943],
              [-99.1184, 41.322],
              [-99.2041, 41.3219],
              [-99.2326, 41.3653],
              [-99.2518, 41.3653],
              [-99.2617, 41.3795],
              [-99.2695, 41.4157],
              [-99.2888, 41.4234],
              [-99.2889, 41.4331],
              [-99.2889, 41.438],
              [-99.28, 41.4664],
            ],
            [
              [-99.0206, 41.4088],
              [-99.0205, 41.416],
              [-99.0205, 41.4232],
              [-99.0304, 41.4162],
              [-99.0206, 41.4088],
            ],
          ],
          [
            [
              [-99.3003, 41.4234],
              [-99.2888, 41.4234],
              [-99.2891, 41.416],
              [-99.3002, 41.416],
              [-99.3003, 41.4234],
            ],
          ],
          [
            [
              [-99.2876, 41.383],
              [-99.2807, 41.3832],
              [-99.281, 41.3797],
              [-99.3003, 41.3796],
              [-99.2876, 41.383],
            ],
          ],
          [
            [
              [-99.3081, 41.3795],
              [-99.3003, 41.3796],
              [-99.3004, 41.3653],
              [-99.3081, 41.3653],
              [-99.3081, 41.3795],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103120',
        NAME: 'Arcadia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322568534,
        AWATER: 66969,
        INTPTLAT: '+41.4289031',
        INTPTLON: '-099.1559965',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.0015, 41.4088],
              [-99.0091, 41.4088],
              [-99.0109, 41.4099],
              [-99.0015, 41.4088],
            ],
          ],
          [
            [
              [-99.0304, 41.4162],
              [-99.0205, 41.416],
              [-99.0206, 41.4088],
              [-99.0304, 41.4162],
            ],
          ],
          [
            [
              [-99.2041, 41.2717],
              [-99.2041, 41.3219],
              [-99.1184, 41.322],
              [-99.0594, 41.3943],
              [-99.0398, 41.3943],
              [-99.0206, 41.4088],
              [-99.0109, 41.4088],
              [-99.0112, 41.4013],
              [-98.9819, 41.3943],
              [-98.9621, 41.4302],
              [-98.8663, 41.4376],
              [-98.8209, 41.3511],
              [-98.7466, 41.3514],
              [-98.6894, 41.279],
              [-98.6898, 41.2645],
              [-98.6938, 41.2645],
              [-98.7087, 41.2791],
              [-98.7107, 41.2647],
              [-98.7099, 41.2062],
              [-98.71, 41.2023],
              [-98.7388, 41.1772],
              [-98.7748, 41.0963],
              [-98.8009, 41.0828],
              [-98.8438, 41.1138],
              [-99.062, 41.1342],
              [-99.0895, 41.2504],
              [-99.2041, 41.2717],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173090',
        NAME: 'Loup City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 968359250,
        AWATER: 14226247,
        INTPTLAT: '+41.2712185',
        INTPTLON: '-098.9307751',
        ELSDLEA: '',
        UNSDLEA: '73090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.7869, 41.0322],
              [-99.7676, 41.032],
              [-99.7675, 41.0174],
              [-99.7869, 41.0322],
            ],
          ],
          [
            [
              [-100.047, 41.0694],
              [-100.0366, 41.0614],
              [-100.0366, 41.0547],
              [-100.0472, 41.0547],
              [-100.047, 41.0694],
            ],
          ],
          [
            [
              [-100.0659, 41.1046],
              [-100.0551, 41.1045],
              [-100.0365, 41.1042],
              [-100.0659, 41.098],
              [-100.0659, 41.1046],
            ],
          ],
          [
            [
              [-99.9623, 41.3935],
              [-99.8477, 41.3943],
              [-99.8284, 41.4017],
              [-99.8203, 41.4016],
              [-99.8196, 41.3788],
              [-99.8004, 41.35],
              [-99.7204, 41.2772],
              [-99.7011, 41.1909],
              [-99.683, 41.1874],
              [-99.6545, 41.1508],
              [-99.6648, 41.104],
              [-99.6551, 41.0811],
              [-99.6455, 41.0811],
              [-99.6456, 41.0753],
              [-99.6557, 41.0754],
              [-99.7229, 41.0828],
              [-99.7804, 41.0467],
              [-99.7869, 41.0322],
              [-99.8153, 41.0323],
              [-99.8447, 41.0033],
              [-99.8446, 41.0093],
              [-99.8537, 41.0179],
              [-99.8637, 41.0179],
              [-99.986, 41.0473],
              [-100.0081, 41.0473],
              [-100.0083, 41.0615],
              [-100.0083, 41.0759],
              [-100.0269, 41.1046],
              [-100.0269, 41.111],
              [-100.0656, 41.1262],
              [-100.0458, 41.1339],
              [-100.0455, 41.1535],
              [-100.1197, 41.1911],
              [-100.1193, 41.2059],
              [-100.1191, 41.2204],
              [-100.0638, 41.264],
              [-100.0829, 41.3934],
              [-99.9623, 41.3935],
            ],
            [
              [-99.762, 41.1427],
              [-99.7616, 41.1433],
              [-99.762, 41.1433],
              [-99.762, 41.1427],
            ],
          ],
          [
            [
              [-100.139, 41.2202],
              [-100.1191, 41.2204],
              [-100.1193, 41.2059],
              [-100.139, 41.2202],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100012',
        NAME: 'Callaway Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1112225437,
        AWATER: 43973,
        INTPTLAT: '+41.1980695',
        INTPTLON: '-099.9030635',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4263, 40.888],
            [-98.4124, 40.9604],
            [-98.2825, 40.9448],
            [-98.3493, 40.8873],
            [-98.4263, 40.888],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100016',
        NAME: 'Grand Island Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67907879,
        AWATER: 569172,
        INTPTLAT: '+40.9235134',
        INTPTLON: '-098.3574663',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3735, 42.0164],
            [-98.3154, 42.0308],
            [-98.3154, 42.0164],
            [-98.3058, 42.0164],
            [-98.2956, 42.0231],
            [-98.2955, 42.031],
            [-98.2955, 42.0452],
            [-98.2955, 42.0489],
            [-98.2955, 42.057],
            [-98.2955, 42.0596],
            [-98.2954, 42.0744],
            [-98.2954, 42.0807],
            [-98.2988, 42.0889],
            [-98.3006, 42.0889],
            [-98.3005, 42.1179],
            [-98.3005, 42.1324],
            [-98.2474, 42.118],
            [-98.2425, 42.1035],
            [-98.1623, 42.052],
            [-98.0871, 42.1296],
            [-97.9695, 42.0378],
            [-97.9942, 42.0168],
            [-97.95, 42.0028],
            [-97.9159, 41.9161],
            [-97.9304, 41.916],
            [-97.94, 41.916],
            [-98.0844, 41.8939],
            [-98.2956, 41.915],
            [-98.3735, 42.0164],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170050',
        NAME: 'Elgin Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 622451096,
        AWATER: 158143,
        INTPTLAT: '+41.9948330',
        INTPTLON: '-098.1331340',
        ELSDLEA: '',
        UNSDLEA: '70050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.5344, 42.2992],
              [-98.5248, 42.2992],
              [-98.5152, 42.2992],
              [-98.5152, 42.2921],
              [-98.5344, 42.2992],
            ],
          ],
          [
            [
              [-98.7687, 42.3215],
              [-98.573, 42.3203],
              [-98.5732, 42.2629],
              [-98.5152, 42.2921],
              [-98.5055, 42.292],
              [-98.5151, 42.2629],
              [-98.5151, 42.2049],
              [-98.5353, 42.1102],
              [-98.7133, 42.0885],
              [-98.7611, 42.0585],
              [-98.7609, 41.9424],
              [-98.7999, 41.9567],
              [-98.8193, 41.9716],
              [-98.8382, 42.0883],
              [-98.9632, 42.0883],
              [-98.9822, 42.1321],
              [-98.9933, 42.1383],
              [-98.9631, 42.2155],
              [-98.7687, 42.3215],
            ],
          ],
          [
            [
              [-99.2347, 42.1311],
              [-99.147, 42.1459],
              [-99.1658, 42.1748],
              [-99.1763, 42.1747],
              [-99.1762, 42.1893],
              [-99.0599, 42.146],
              [-99.2227, 42.0879],
              [-99.2346, 42.088],
              [-99.2347, 42.1311],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105010',
        NAME: 'Chambers Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 977380762,
        AWATER: 684933,
        INTPTLAT: '+42.1454012',
        INTPTLON: '-098.7874921',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2958, 41.7412],
            [-98.2811, 41.7121],
            [-98.2913, 41.5901],
            [-98.3392, 41.5817],
            [-98.3681, 41.5818],
            [-98.4006, 41.5745],
            [-98.4061, 41.5819],
            [-98.5405, 41.6834],
            [-98.5214, 41.733],
            [-98.2958, 41.7412],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176890',
        NAME: 'Spalding Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302761152,
        AWATER: 347084,
        INTPTLAT: '+41.6510636',
        INTPTLON: '-098.4050270',
        ELSDLEA: '',
        UNSDLEA: '76890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8768, 42.6114],
              [-97.8653, 42.6114],
              [-97.8528, 42.6115],
              [-97.8477, 42.6115],
              [-97.8428, 42.6116],
              [-97.8379, 42.5897],
              [-97.8768, 42.6114],
            ],
          ],
          [
            [
              [-97.8773, 42.6265],
              [-97.8772, 42.6194],
              [-97.8868, 42.6157],
              [-97.8773, 42.6265],
            ],
          ],
          [
            [
              [-98.0385, 42.3421],
              [-98.0385, 42.3539],
              [-98.0193, 42.3478],
              [-98.0384, 42.3352],
              [-98.0385, 42.3421],
            ],
          ],
          [
            [
              [-98.0386, 42.3642],
              [-98.0385, 42.3573],
              [-98.0487, 42.3572],
              [-98.0386, 42.3642],
            ],
          ],
          [
            [
              [-98.299, 42.0596],
              [-98.2955, 42.0596],
              [-98.2955, 42.057],
              [-98.299, 42.0596],
            ],
          ],
          [
            [
              [-98.2988, 42.0889],
              [-98.2954, 42.0807],
              [-98.3055, 42.0743],
              [-98.3055, 42.0666],
              [-98.3152, 42.0806],
              [-98.2988, 42.0889],
            ],
          ],
          [
            [
              [-98.3103, 42.1178],
              [-98.3005, 42.1179],
              [-98.3006, 42.0889],
              [-98.3103, 42.1178],
            ],
          ],
          [
            [
              [-98.3249, 42.4514],
              [-98.3151, 42.4458],
              [-98.3249, 42.4458],
              [-98.3249, 42.4514],
            ],
          ],
          [
            [
              [-98.349, 42.4295],
              [-98.3394, 42.4298],
              [-98.3395, 42.4225],
              [-98.349, 42.4295],
            ],
          ],
          [
            [
              [-98.3344, 42.4586],
              [-98.3344, 42.4514],
              [-98.3249, 42.4458],
              [-98.325, 42.4368],
              [-98.3297, 42.4302],
              [-98.3395, 42.4301],
              [-98.364, 42.4514],
              [-98.3344, 42.4586],
            ],
          ],
          [
            [
              [-98.349, 42.4295],
              [-98.3787, 42.4152],
              [-98.3878, 42.4226],
              [-98.349, 42.4295],
            ],
          ],
          [
            [
              [-98.3054, 42.6905],
              [-98.3053, 42.7121],
              [-98.3052, 42.7335],
              [-98.3051, 42.7604],
              [-98.105, 42.6861],
              [-97.9739, 42.6696],
              [-97.9735, 42.6915],
              [-97.9344, 42.6929],
              [-97.9243, 42.6805],
              [-97.9153, 42.6693],
              [-97.8972, 42.6693],
              [-97.9348, 42.6664],
              [-97.9448, 42.6625],
              [-97.9251, 42.637],
              [-97.945, 42.5392],
              [-98.1603, 42.5098],
              [-98.2095, 42.4371],
              [-98.0491, 42.3931],
              [-98.0386, 42.3787],
              [-98.0577, 42.3572],
              [-98.0679, 42.3497],
              [-98.0585, 42.3497],
              [-98.0487, 42.3497],
              [-98.0385, 42.3421],
              [-98.0384, 42.3352],
              [-98.0382, 42.3279],
              [-98.0538, 42.3278],
              [-98.0538, 42.3208],
              [-98.0405, 42.3135],
              [-98.1161, 42.2337],
              [-98.0968, 42.2338],
              [-98.0871, 42.1296],
              [-98.1623, 42.052],
              [-98.2425, 42.1035],
              [-98.2474, 42.118],
              [-98.3005, 42.1324],
              [-98.3103, 42.1178],
              [-98.32, 42.1178],
              [-98.3395, 42.1179],
              [-98.2329, 42.2556],
              [-98.3396, 42.3283],
              [-98.3693, 42.3791],
              [-98.3695, 42.3791],
              [-98.3695, 42.3858],
              [-98.3497, 42.3935],
              [-98.3591, 42.3995],
              [-98.3709, 42.3935],
              [-98.3788, 42.3935],
              [-98.3787, 42.4081],
              [-98.3395, 42.4225],
              [-98.32, 42.4225],
              [-98.3285, 42.4009],
              [-98.3101, 42.4369],
              [-98.3055, 42.4458],
              [-98.3249, 42.4586],
              [-98.3344, 42.4586],
              [-98.3054, 42.5169],
              [-98.3054, 42.6905],
            ],
            [
              [-98.3285, 42.4009],
              [-98.3395, 42.3935],
              [-98.3287, 42.3935],
              [-98.3285, 42.4009],
            ],
          ],
          [
            [
              [-98.3979, 42.4226],
              [-98.3878, 42.4226],
              [-98.3787, 42.4152],
              [-98.3787, 42.4081],
              [-98.3979, 42.4226],
            ],
          ],
          [
            [
              [-98.4143, 42.4022],
              [-98.4143, 42.4017],
              [-98.415, 42.4017],
              [-98.4143, 42.4022],
            ],
          ],
          [
            [
              [-98.4156, 42.4013],
              [-98.4156, 42.4017],
              [-98.415, 42.4017],
              [-98.4139, 42.4008],
              [-98.413, 42.4007],
              [-98.4128, 42.4007],
              [-98.4151, 42.4],
              [-98.4156, 42.4013],
            ],
          ],
          [
            [
              [-98.4173, 42.3723],
              [-98.4085, 42.3724],
              [-98.384, 42.3645],
              [-98.3396, 42.3283],
              [-98.4172, 42.3645],
              [-98.4173, 42.3723],
            ],
          ],
          [
            [
              [-98.4197, 42.4017],
              [-98.4181, 42.4017],
              [-98.4182, 42.4013],
              [-98.4185, 42.4013],
              [-98.4197, 42.4013],
              [-98.4197, 42.4017],
            ],
          ],
          [
            [
              [-98.3927, 42.075],
              [-98.4215, 42.067],
              [-98.4315, 42.0742],
              [-98.3927, 42.075],
            ],
          ],
          [
            [
              [-98.4563, 42.3937],
              [-98.4173, 42.3964],
              [-98.4173, 42.3967],
              [-98.4117, 42.379],
              [-98.4173, 42.379],
              [-98.4269, 42.3645],
              [-98.4368, 42.3649],
              [-98.4563, 42.3937],
            ],
          ],
          [
            [
              [-98.5315, 42.3803],
              [-98.5286, 42.3803],
              [-98.5286, 42.3796],
              [-98.5315, 42.3803],
            ],
          ],
          [
            [
              [-98.5314, 42.3814],
              [-98.5314, 42.3815],
              [-98.5314, 42.3807],
              [-98.5314, 42.3808],
              [-98.5314, 42.3814],
            ],
          ],
          [
            [
              [-98.5321, 42.3804],
              [-98.5321, 42.3803],
              [-98.5329, 42.38],
              [-98.5341, 42.3801],
              [-98.5321, 42.3804],
            ],
          ],
          [
            [
              [-98.5178, 42.3141],
              [-98.5152, 42.3142],
              [-98.4854, 42.3064],
              [-98.476, 42.2776],
              [-98.4956, 42.2708],
              [-98.4954, 42.2634],
              [-98.5151, 42.2629],
              [-98.5055, 42.292],
              [-98.4955, 42.2992],
              [-98.5248, 42.2992],
              [-98.5344, 42.2992],
              [-98.5178, 42.3141],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100119',
        NAME: 'Nebraska Unified District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1509600611,
        AWATER: 3919781,
        INTPTLAT: '+42.4302799',
        INTPTLON: '-098.2037246',
        ELSDLEA: '',
        UNSDLEA: '00119',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8633, 40.7919],
              [-96.8539, 40.7848],
              [-96.8635, 40.7849],
              [-96.8633, 40.7919],
            ],
          ],
          [
            [
              [-96.9107, 40.9807],
              [-96.9005, 40.981],
              [-96.72, 40.9154],
              [-96.72, 40.8864],
              [-96.863, 40.8032],
              [-96.9106, 40.8284],
              [-96.9107, 40.9807],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173290',
        NAME: 'Malcolm Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198512206,
        AWATER: 7681555,
        INTPTLAT: '+40.8974009',
        INTPTLON: '-096.8414133',
        ELSDLEA: '',
        UNSDLEA: '73290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5204, 40.0156],
              [-96.5297, 40.001],
              [-96.554, 40.0011],
              [-96.5204, 40.0156],
            ],
          ],
          [
            [
              [-96.5727, 40.0482],
              [-96.572, 40.0472],
              [-96.5727, 40.0472],
              [-96.5727, 40.0482],
            ],
          ],
          [
            [
              [-96.5767, 40.0067],
              [-96.5657, 40.0158],
              [-96.5579, 40.0084],
              [-96.5638, 40.001],
              [-96.5858, 40.001],
              [-96.5767, 40.0067],
            ],
          ],
          [
            [
              [-96.5954, 40.0373],
              [-96.5748, 40.0664],
              [-96.5671, 40.0665],
              [-96.5581, 40.059],
              [-96.5581, 40.0519],
              [-96.5674, 40.0518],
              [-96.5694, 40.0519],
              [-96.5699, 40.0517],
              [-96.5717, 40.0507],
              [-96.5722, 40.0502],
              [-96.57, 40.0512],
              [-96.57, 40.0502],
              [-96.5676, 40.0494],
              [-96.5623, 40.0445],
              [-96.5669, 40.0337],
              [-96.5767, 40.0266],
              [-96.5954, 40.0373],
            ],
            [
              [-96.574, 40.0472],
              [-96.5727, 40.0472],
              [-96.5727, 40.0467],
              [-96.5714, 40.0467],
              [-96.5713, 40.0471],
              [-96.5727, 40.0487],
              [-96.5732, 40.0487],
              [-96.574, 40.0472],
            ],
          ],
          [
            [
              [-96.6093, 40.0374],
              [-96.6046, 40.0439],
              [-96.5954, 40.0373],
              [-96.6093, 40.0374],
            ],
          ],
          [
            [
              [-96.6804, 40.0158],
              [-96.6614, 40.0196],
              [-96.6522, 40.0158],
              [-96.6711, 40.0013],
              [-96.6899, 40.0013],
              [-96.6804, 40.0158],
            ],
          ],
          [
            [
              [-97.0108, 40.1465],
              [-97.0013, 40.1536],
              [-96.9828, 40.1755],
              [-96.9826, 40.1755],
              [-96.9731, 40.1755],
              [-96.9542, 40.2047],
              [-96.9164, 40.2484],
              [-96.9163, 40.1901],
              [-96.9164, 40.1828],
              [-96.7845, 40.1758],
              [-96.737, 40.0597],
              [-96.6804, 40.0158],
              [-96.6957, 40.0014],
              [-96.8058, 40.0015],
              [-96.9164, 40.0015],
              [-96.9541, 40.0307],
              [-96.9832, 40.0016],
              [-97.0102, 40.0014],
              [-97.0728, 40.0449],
              [-97.0108, 40.1465],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100125',
        NAME: 'Diller-Odell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528413514,
        AWATER: 6403092,
        INTPTLAT: '+40.0834424',
        INTPTLON: '-096.8726915',
        ELSDLEA: '',
        UNSDLEA: '00125',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7986, 40.4797],
              [-96.7798, 40.4727],
              [-96.7892, 40.4654],
              [-96.7986, 40.4797],
            ],
          ],
          [
            [
              [-96.7892, 40.4654],
              [-96.7799, 40.4654],
              [-96.7608, 40.4509],
              [-96.7893, 40.4452],
              [-96.7892, 40.4654],
            ],
          ],
          [
            [
              [-96.9163, 40.1901],
              [-96.9071, 40.1901],
              [-96.9071, 40.1828],
              [-96.9164, 40.1828],
              [-96.9163, 40.1901],
            ],
          ],
          [
            [
              [-97.1791, 40.3499],
              [-97.179, 40.3644],
              [-97.1789, 40.408],
              [-97.1789, 40.4153],
              [-97.179, 40.4225],
              [-97.1793, 40.437],
              [-97.1178, 40.4222],
              [-97.1133, 40.4222],
              [-96.9229, 40.4075],
              [-96.7891, 40.4307],
              [-96.7891, 40.4146],
              [-96.7991, 40.4146],
              [-96.7891, 40.4074],
              [-96.7609, 40.2991],
              [-96.9164, 40.2484],
              [-96.9542, 40.2047],
              [-97.0674, 40.2045],
              [-97.1504, 40.335],
              [-97.1723, 40.335],
              [-97.1791, 40.3499],
            ],
            [
              [-96.7991, 40.4146],
              [-96.8182, 40.4166],
              [-96.8182, 40.415],
              [-96.7991, 40.4146],
            ],
          ],
          [
            [
              [-97.2361, 40.35],
              [-97.198, 40.408],
              [-97.1789, 40.408],
              [-97.179, 40.3644],
              [-97.1791, 40.3499],
              [-97.1978, 40.35],
              [-97.2077, 40.35],
              [-97.2361, 40.35],
            ],
            [
              [-97.2007, 40.3944],
              [-97.1997, 40.3935],
              [-97.1993, 40.3935],
              [-97.1951, 40.3935],
              [-97.2007, 40.3944],
            ],
          ],
          [
            [
              [-97.2361, 40.4589],
              [-97.2264, 40.4588],
              [-97.2263, 40.4588],
              [-97.2171, 40.4549],
              [-97.2171, 40.4517],
              [-97.2361, 40.4589],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105970',
        NAME: 'Tri County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623031742,
        AWATER: 4942063,
        INTPTLAT: '+40.3312817',
        INTPTLON: '-096.9822926',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5579, 40.0084],
              [-96.5579, 40.0011],
              [-96.5638, 40.001],
              [-96.5579, 40.0084],
            ],
          ],
          [
            [
              [-96.5674, 40.0518],
              [-96.5581, 40.0519],
              [-96.558, 40.0444],
              [-96.5623, 40.0445],
              [-96.5676, 40.0494],
              [-96.5674, 40.0518],
            ],
          ],
          [
            [
              [-96.57, 40.0512],
              [-96.5699, 40.0517],
              [-96.5694, 40.0519],
              [-96.57, 40.0502],
              [-96.57, 40.0512],
            ],
          ],
          [
            [
              [-96.5727, 40.0472],
              [-96.572, 40.0472],
              [-96.5713, 40.0471],
              [-96.5714, 40.0467],
              [-96.5727, 40.0467],
              [-96.5727, 40.0472],
            ],
          ],
          [
            [
              [-96.5732, 40.0487],
              [-96.5727, 40.0487],
              [-96.5727, 40.0482],
              [-96.5732, 40.0487],
            ],
          ],
          [
            [
              [-96.5855, 40.0157],
              [-96.5767, 40.0266],
              [-96.5669, 40.0337],
              [-96.5657, 40.0158],
              [-96.5767, 40.0067],
              [-96.5855, 40.0157],
            ],
          ],
          [
            [
              [-96.5205, 40.2332],
              [-96.4637, 40.2767],
              [-96.426, 40.3965],
              [-96.3881, 40.3709],
              [-96.351, 40.3351],
              [-96.3309, 40.3351],
              [-96.3118, 40.2552],
              [-96.3023, 40.2477],
              [-96.2926, 40.2529],
              [-96.2552, 40.2621],
              [-96.2462, 40.2039],
              [-96.2463, 40.1931],
              [-96.2329, 40.1314],
              [-96.3315, 40.1423],
              [-96.3504, 40.1028],
              [-96.3551, 40.1028],
              [-96.3504, 40.0955],
              [-96.3507, 40.0009],
              [-96.4637, 40.001],
              [-96.4727, 40.0301],
              [-96.4829, 40.0546],
              [-96.5296, 40.0445],
              [-96.5393, 40.0445],
              [-96.5393, 40.0517],
              [-96.5393, 40.0554],
              [-96.5393, 40.0591],
              [-96.5343, 40.0665],
              [-96.5581, 40.059],
              [-96.5671, 40.0665],
              [-96.5748, 40.0664],
              [-96.5205, 40.1027],
              [-96.5106, 40.1028],
              [-96.558, 40.1972],
              [-96.5673, 40.2088],
              [-96.5857, 40.2267],
              [-96.5816, 40.2333],
              [-96.5958, 40.2478],
              [-96.5205, 40.2332],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172780',
        NAME: 'Lewiston Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653922136,
        AWATER: 5448409,
        INTPTLAT: '+40.1811740',
        INTPTLON: '-096.4203367',
        ELSDLEA: '',
        UNSDLEA: '72780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5669, 40.0337],
              [-96.5623, 40.0445],
              [-96.558, 40.0444],
              [-96.5581, 40.0519],
              [-96.5581, 40.059],
              [-96.5343, 40.0665],
              [-96.5393, 40.0591],
              [-96.5393, 40.0554],
              [-96.5393, 40.0517],
              [-96.5393, 40.0445],
              [-96.5296, 40.0445],
              [-96.4829, 40.0546],
              [-96.4727, 40.0301],
              [-96.4637, 40.001],
              [-96.5014, 40.001],
              [-96.5203, 40.001],
              [-96.5297, 40.001],
              [-96.5204, 40.0156],
              [-96.554, 40.0011],
              [-96.5579, 40.0011],
              [-96.5579, 40.0084],
              [-96.5657, 40.0158],
              [-96.5669, 40.0337],
            ],
          ],
          [
            [
              [-96.57, 40.0502],
              [-96.5694, 40.0519],
              [-96.5674, 40.0518],
              [-96.5676, 40.0494],
              [-96.57, 40.0502],
            ],
          ],
          [
            [
              [-96.5727, 40.0487],
              [-96.5713, 40.0471],
              [-96.572, 40.0472],
              [-96.5727, 40.0482],
              [-96.5727, 40.0487],
            ],
          ],
          [
            [
              [-96.5722, 40.0502],
              [-96.5717, 40.0507],
              [-96.5699, 40.0517],
              [-96.57, 40.0512],
              [-96.5722, 40.0502],
            ],
          ],
          [
            [
              [-96.5732, 40.0487],
              [-96.5727, 40.0482],
              [-96.5727, 40.0472],
              [-96.574, 40.0472],
              [-96.5732, 40.0487],
            ],
          ],
          [
            [
              [-96.6804, 40.0158],
              [-96.6899, 40.0013],
              [-96.6957, 40.0014],
              [-96.6804, 40.0158],
            ],
          ],
          [
            [
              [-96.7845, 40.1758],
              [-96.6333, 40.2481],
              [-96.6051, 40.2335],
              [-96.5955, 40.2267],
              [-96.5857, 40.2267],
              [-96.5673, 40.2088],
              [-96.558, 40.1972],
              [-96.5106, 40.1028],
              [-96.5205, 40.1027],
              [-96.5748, 40.0664],
              [-96.5954, 40.0373],
              [-96.5767, 40.0266],
              [-96.5855, 40.0157],
              [-96.5767, 40.0067],
              [-96.5858, 40.001],
              [-96.5954, 40.0009],
              [-96.6013, 40.0009],
              [-96.6145, 40.0009],
              [-96.6336, 40.0013],
              [-96.6615, 40.0013],
              [-96.6637, 40.0013],
              [-96.6711, 40.0013],
              [-96.6522, 40.0158],
              [-96.6614, 40.0196],
              [-96.6804, 40.0158],
              [-96.737, 40.0597],
              [-96.7845, 40.1758],
            ],
            [
              [-96.5954, 40.0373],
              [-96.6046, 40.0439],
              [-96.6093, 40.0374],
              [-96.5954, 40.0373],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177180',
        NAME: 'Southern School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430474038,
        AWATER: 5404374,
        INTPTLAT: '+40.1242769',
        INTPTLON: '-096.6256711',
        ELSDLEA: '',
        UNSDLEA: '77180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.5972, 41.5973],
              [-99.5971, 41.583],
              [-99.6359, 41.5897],
              [-99.5972, 41.5973],
            ],
          ],
          [
            [
              [-99.6866, 41.7403],
              [-99.3272, 41.7414],
              [-99.2122, 41.6684],
              [-99.2121, 41.6542],
              [-99.2121, 41.5966],
              [-99.308, 41.5679],
              [-99.2508, 41.4811],
              [-99.28, 41.4664],
              [-99.386, 41.4674],
              [-99.4052, 41.467],
              [-99.5313, 41.5683],
              [-99.5399, 41.5753],
              [-99.5592, 41.5825],
              [-99.578, 41.622],
              [-99.6905, 41.6675],
              [-99.6866, 41.7403],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176410',
        NAME: 'Sargent Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770779067,
        AWATER: 123585,
        INTPTLAT: '+41.6347272',
        INTPTLON: '-099.4472405',
        ELSDLEA: '',
        UNSDLEA: '76410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0127, 41.5243],
              [-98.0032, 41.5244],
              [-98.0032, 41.5171],
              [-98.0127, 41.5243],
            ],
          ],
          [
            [
              [-98.2536, 41.4379],
              [-98.2345, 41.4376],
              [-98.2152, 41.4374],
              [-98.1864, 41.4374],
              [-98.1573, 41.4371],
              [-98.1189, 41.4666],
              [-98.0032, 41.5171],
              [-97.9792, 41.5173],
              [-97.9449, 41.4957],
              [-97.9454, 41.4885],
              [-97.9456, 41.4815],
              [-97.811, 41.3903],
              [-97.8302, 41.3364],
              [-97.962, 41.2783],
              [-97.9641, 41.2783],
              [-97.9641, 41.2675],
              [-97.9641, 41.2637],
              [-97.9738, 41.2637],
              [-98.0686, 41.2727],
              [-98.0789, 41.2726],
              [-98.1123, 41.2644],
              [-98.1751, 41.2933],
              [-98.2404, 41.394],
              [-98.234, 41.4081],
              [-98.2536, 41.4379],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100091',
        NAME: 'Fullerton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 594234238,
        AWATER: 10294339,
        INTPTLAT: '+41.3837536',
        INTPTLON: '-098.0311895',
        ELSDLEA: '',
        UNSDLEA: '00091',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0527, 41.698],
            [-103.829, 41.6979],
            [-103.4942, 41.6995],
            [-103.4661, 41.6545],
            [-103.1965, 41.6261],
            [-103.195, 41.584],
            [-103.2339, 41.4816],
            [-103.3116, 41.4379],
            [-103.4477, 41.4369],
            [-103.5123, 41.3945],
            [-104.0523, 41.3932],
            [-104.0525, 41.5643],
            [-104.0527, 41.698],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100067',
        NAME: 'Banner County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2092068861,
        AWATER: 543485,
        INTPTLAT: '+41.5243537',
        INTPTLON: '-103.6777238',
        ELSDLEA: '',
        UNSDLEA: '00067',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8692, 41.8279],
            [-103.8117, 41.8405],
            [-103.7474, 41.8438],
            [-103.7404, 41.8583],
            [-103.6053, 41.8351],
            [-103.5191, 41.7899],
            [-103.4605, 41.7717],
            [-103.4942, 41.6995],
            [-103.829, 41.6979],
            [-103.8692, 41.8279],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100098',
        NAME: 'Gering Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459243553,
        AWATER: 796628,
        INTPTLAT: '+41.7689870',
        INTPTLON: '-103.6802617',
        ELSDLEA: '',
        UNSDLEA: '00098',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.6694, 42.0022],
            [-103.4016, 42.0035],
            [-103.3633, 42.0029],
            [-103.3615, 41.8302],
            [-103.4013, 41.815],
            [-103.4115, 41.807],
            [-103.4407, 41.8071],
            [-103.5191, 41.7899],
            [-103.6053, 41.8351],
            [-103.7404, 41.8583],
            [-103.7474, 41.8438],
            [-103.8117, 41.8405],
            [-103.8033, 41.8944],
            [-103.6632, 41.958],
            [-103.6694, 42.0022],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176470',
        NAME: 'Scottsbluff Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 582274637,
        AWATER: 13032427,
        INTPTLAT: '+41.9103955',
        INTPTLON: '-103.5440680',
        ELSDLEA: '',
        UNSDLEA: '76470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.4407, 41.8071],
            [-103.4605, 41.7717],
            [-103.5191, 41.7899],
            [-103.4407, 41.8071],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173800',
        NAME: 'Minatare Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39929378,
        AWATER: 49164,
        INTPTLAT: '+41.8073195',
        INTPTLON: '-103.4703190',
        ELSDLEA: '',
        UNSDLEA: '73800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9341, 40.1254],
            [-97.9152, 40.1471],
            [-97.9152, 40.1516],
            [-97.9248, 40.2053],
            [-97.9211, 40.2053],
            [-97.9104, 40.2054],
            [-97.9052, 40.1981],
            [-97.8942, 40.1911],
            [-97.8839, 40.1879],
            [-97.8765, 40.1907],
            [-97.854, 40.2023],
            [-97.8301, 40.198],
            [-97.6982, 40.2635],
            [-97.6329, 40.1181],
            [-97.6986, 40.0382],
            [-97.7083, 40.0601],
            [-97.7272, 40.0529],
            [-97.7461, 40.0672],
            [-97.7838, 40.0602],
            [-97.7934, 40.0674],
            [-97.7934, 40.0746],
            [-97.844, 40.0747],
            [-97.9341, 40.1254],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105910',
        NAME: 'Deshler Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415805262,
        AWATER: 581563,
        INTPTLAT: '+40.1543159',
        INTPTLON: '-097.7744932',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.6328, 42.3714],
              [-102.5931, 42.3708],
              [-102.5934, 42.3569],
              [-102.6278, 42.3571],
              [-102.6328, 42.3714],
            ],
          ],
          [
            [
              [-102.7589, 43],
              [-102.7428, 43],
              [-102.713, 42.9775],
              [-102.713, 42.9659],
              [-102.7589, 43],
            ],
          ],
          [
            [
              [-102.8322, 42.6188],
              [-102.7737, 42.6997],
              [-102.793, 42.8445],
              [-102.6938, 42.8569],
              [-102.6188, 42.7232],
              [-102.6552, 42.672],
              [-102.6548, 42.5332],
              [-102.5236, 42.3996],
              [-102.6123, 42.3857],
              [-102.7108, 42.4145],
              [-102.731, 42.4209],
              [-102.7494, 42.4399],
              [-102.7734, 42.4399],
              [-102.7729, 42.4969],
              [-102.8716, 42.5676],
              [-102.8912, 42.5679],
              [-102.8322, 42.6188],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171610',
        NAME: 'Hay Springs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 783053620,
        AWATER: 259770,
        INTPTLAT: '+42.6206811',
        INTPTLON: '-102.7043549',
        ELSDLEA: '',
        UNSDLEA: '71610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5123, 41.3945],
            [-103.4477, 41.4369],
            [-103.3116, 41.4379],
            [-103.2047, 41.3947],
            [-103.2029, 41.3088],
            [-103.1462, 41.2215],
            [-103.1838, 41.0766],
            [-103.2987, 41.0768],
            [-103.3062, 41.0019],
            [-103.5738, 41.0017],
            [-103.5927, 41.0017],
            [-103.5484, 41.0909],
            [-103.549, 41.3065],
            [-103.5123, 41.3945],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175810',
        NAME: 'Potter-Dix Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1337763585,
        AWATER: 0,
        INTPTLAT: '+41.2189571',
        INTPTLON: '-103.3660219',
        ELSDLEA: '',
        UNSDLEA: '75810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.7326, 41.7147],
              [-102.7148, 41.6992],
              [-102.7451, 41.6847],
              [-102.7326, 41.7147],
            ],
          ],
          [
            [
              [-102.7917, 41.5819],
              [-102.7916, 41.5759],
              [-102.8108, 41.5819],
              [-102.7917, 41.5819],
            ],
          ],
          [
            [
              [-103.2339, 41.4816],
              [-103.0211, 41.4514],
              [-103.0092, 41.5088],
              [-102.8535, 41.5119],
              [-102.7522, 41.4393],
              [-102.6094, 41.3951],
              [-102.61, 41.3087],
              [-102.7254, 41.3017],
              [-102.7635, 41.2218],
              [-102.8594, 41.1916],
              [-103.1462, 41.2215],
              [-103.2029, 41.3088],
              [-103.2047, 41.3947],
              [-103.3116, 41.4379],
              [-103.2339, 41.4816],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100072',
        NAME: 'Leyton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1418577540,
        AWATER: 585576,
        INTPTLAT: '+41.3669070',
        INTPTLON: '-102.9248755',
        ELSDLEA: '',
        UNSDLEA: '00072',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8478, 42.6765],
              [-97.8478, 42.6735],
              [-97.8478, 42.6698],
              [-97.8522, 42.6698],
              [-97.8577, 42.6698],
              [-97.8577, 42.673],
              [-97.8577, 42.6787],
              [-97.8478, 42.6765],
            ],
          ],
          [
            [
              [-97.8577, 42.6698],
              [-97.8523, 42.6601],
              [-97.8775, 42.648],
              [-97.8872, 42.6481],
              [-97.8577, 42.6698],
            ],
          ],
          [
            [
              [-97.9344, 42.6929],
              [-97.8972, 42.6693],
              [-97.9153, 42.6693],
              [-97.9243, 42.6805],
              [-97.9344, 42.6929],
            ],
          ],
          [
            [
              [-98.1603, 42.5098],
              [-97.945, 42.5392],
              [-97.9251, 42.637],
              [-97.9156, 42.6334],
              [-97.9156, 42.6406],
              [-97.8872, 42.6481],
              [-97.8775, 42.641],
              [-97.8773, 42.6265],
              [-97.8868, 42.6157],
              [-97.8768, 42.6114],
              [-97.8379, 42.5897],
              [-97.7941, 42.5248],
              [-97.7105, 42.5177],
              [-97.7106, 42.5105],
              [-97.7008, 42.5034],
              [-97.6913, 42.4961],
              [-97.6813, 42.4889],
              [-97.6812, 42.4816],
              [-97.6812, 42.4779],
              [-97.6811, 42.467],
              [-97.681, 42.4526],
              [-97.7887, 42.4456],
              [-97.8345, 42.3939],
              [-97.951, 42.386],
              [-98.0293, 42.3787],
              [-98.0386, 42.3787],
              [-98.0491, 42.3931],
              [-98.2095, 42.4371],
              [-98.1603, 42.5098],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105550',
        NAME: 'Creighton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 656087492,
        AWATER: 552230,
        INTPTLAT: '+42.4882349',
        INTPTLON: '-097.9454766',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0487, 42.3572],
              [-98.0385, 42.3573],
              [-98.0385, 42.3539],
              [-98.0385, 42.3421],
              [-98.0487, 42.3497],
              [-98.0487, 42.3572],
            ],
          ],
          [
            [
              [-98.0386, 42.3787],
              [-98.0293, 42.3787],
              [-98.0386, 42.3642],
              [-98.0487, 42.3572],
              [-98.0577, 42.3572],
              [-98.0386, 42.3787],
            ],
          ],
          [
            [
              [-98.0405, 42.3135],
              [-98.0382, 42.3279],
              [-98.0384, 42.3352],
              [-98.0193, 42.3478],
              [-98.0098, 42.3064],
              [-97.999, 42.299],
              [-97.9218, 42.2778],
              [-97.8349, 42.2565],
              [-97.8349, 42.1767],
              [-97.8539, 42.1187],
              [-97.88, 42.1261],
              [-97.8886, 42.1188],
              [-97.9265, 42.0571],
              [-97.9594, 42.0306],
              [-97.9695, 42.0306],
              [-97.9695, 42.0378],
              [-98.0871, 42.1296],
              [-98.0968, 42.2338],
              [-98.1161, 42.2337],
              [-98.0405, 42.3135],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174220',
        NAME: 'Neligh-Oakdale Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468807704,
        AWATER: 1512620,
        INTPTLAT: '+42.1835678',
        INTPTLON: '-097.9802856',
        ELSDLEA: '',
        UNSDLEA: '74220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0386, 42.3642],
              [-98.0293, 42.3787],
              [-97.951, 42.386],
              [-97.8345, 42.3939],
              [-97.7887, 42.4456],
              [-97.681, 42.4526],
              [-97.6597, 42.2643],
              [-97.7765, 42.1771],
              [-97.8349, 42.1767],
              [-97.8349, 42.2565],
              [-97.9218, 42.2778],
              [-97.999, 42.299],
              [-98.0098, 42.3064],
              [-98.0193, 42.3478],
              [-98.0385, 42.3539],
              [-98.0385, 42.3573],
              [-98.0386, 42.3642],
            ],
          ],
          [
            [
              [-98.0382, 42.3279],
              [-98.0405, 42.3135],
              [-98.0538, 42.3208],
              [-98.0538, 42.3278],
              [-98.0382, 42.3279],
            ],
          ],
          [
            [
              [-98.0577, 42.3572],
              [-98.0487, 42.3572],
              [-98.0487, 42.3497],
              [-98.0585, 42.3497],
              [-98.0679, 42.3497],
              [-98.0577, 42.3572],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175570',
        NAME: 'Plainview Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 542785742,
        AWATER: 77983,
        INTPTLAT: '+42.3256517',
        INTPTLON: '-097.8260471',
        ELSDLEA: '',
        UNSDLEA: '75570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.6976, 41.8497],
              [-97.6782, 41.8587],
              [-97.6782, 41.8657],
              [-97.6005, 41.8732],
              [-97.5424, 41.8874],
              [-97.4549, 41.9309],
              [-97.3783, 41.9314],
              [-97.3584, 41.9106],
              [-97.3682, 41.9025],
              [-97.3584, 41.9025],
              [-97.3013, 41.8229],
              [-97.3105, 41.823],
              [-97.2911, 41.8082],
              [-97.3009, 41.794],
              [-97.3028, 41.794],
              [-97.3102, 41.7941],
              [-97.3101, 41.7867],
              [-97.397, 41.7432],
              [-97.4454, 41.7291],
              [-97.5325, 41.7353],
              [-97.6479, 41.7645],
              [-97.6585, 41.7863],
              [-97.6585, 41.7938],
              [-97.6874, 41.8077],
              [-97.6974, 41.8077],
              [-97.6975, 41.8151],
              [-97.6892, 41.8162],
              [-97.6889, 41.8223],
              [-97.7079, 41.8374],
              [-97.6976, 41.8497],
            ],
          ],
          [
            [
              [-97.7079, 41.873],
              [-97.6782, 41.8657],
              [-97.6881, 41.8586],
              [-97.6975, 41.8586],
              [-97.708, 41.8656],
              [-97.7079, 41.873],
            ],
          ],
          [
            [
              [-97.3779, 41.7142],
              [-97.3718, 41.7142],
              [-97.3682, 41.7107],
              [-97.3682, 41.7073],
              [-97.3779, 41.7073],
              [-97.3779, 41.7142],
            ],
          ],
          [
            [
              [-97.3962, 41.9603],
              [-97.3811, 41.9604],
              [-97.3784, 41.9584],
              [-97.3784, 41.9501],
              [-97.3962, 41.9603],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173230',
        NAME: 'Madison Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 526120937,
        AWATER: 832357,
        INTPTLAT: '+41.8228753',
        INTPTLON: '-097.4934581',
        ELSDLEA: '',
        UNSDLEA: '73230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3379, 41.6395],
            [-96.3097, 41.5998],
            [-96.3096, 41.5528],
            [-96.2518, 41.5093],
            [-96.2327, 41.4876],
            [-96.1695, 41.4437],
            [-96.2439, 41.3631],
            [-96.2915, 41.3644],
            [-96.3307, 41.3931],
            [-96.4248, 41.4589],
            [-96.4252, 41.491],
            [-96.4348, 41.4952],
            [-96.4443, 41.5749],
            [-96.3379, 41.6395],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103130',
        NAME: 'Arlington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346216326,
        AWATER: 3132609,
        INTPTLAT: '+41.4872510',
        INTPTLON: '-096.3192800',
        ELSDLEA: '',
        UNSDLEA: '03130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1695, 41.4437],
            [-96.0815, 41.3932],
            [-96.1773, 41.3065],
            [-96.2439, 41.3631],
            [-96.1695, 41.4437],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103990',
        NAME: 'Bennington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108885964,
        AWATER: 349625,
        INTPTLAT: '+41.3740623',
        INTPTLON: '-096.1729264',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0166, 41.4819],
            [-95.9306, 41.4192],
            [-95.9378, 41.3908],
            [-95.9985, 41.381],
            [-96.0181, 41.3817],
            [-96.0694, 41.3932],
            [-96.0166, 41.4819],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170650',
        NAME: 'Fort Calhoun Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106972183,
        AWATER: 2487233,
        INTPTLAT: '+41.4318768',
        INTPTLON: '-095.9972462',
        ELSDLEA: '',
        UNSDLEA: '70650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8594, 41.1916],
            [-102.7635, 41.2218],
            [-102.7254, 41.3017],
            [-102.61, 41.3087],
            [-102.6094, 41.3951],
            [-102.5325, 41.3956],
            [-102.5332, 41.266],
            [-102.3798, 41.2219],
            [-102.2465, 41.2216],
            [-102.2402, 41.0023],
            [-102.3449, 41.0022],
            [-102.6535, 41.0023],
            [-102.7928, 41.0023],
            [-102.8399, 41.0622],
            [-102.8594, 41.1916],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100140',
        NAME: 'Creek Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1483034633,
        AWATER: 416935,
        INTPTLAT: '+41.1474638',
        INTPTLON: '-102.5563852',
        ELSDLEA: '',
        UNSDLEA: '00140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.4639, 41.3702],
              [-96.4629, 41.3709],
              [-96.4532, 41.3643],
              [-96.4639, 41.3571],
              [-96.4639, 41.3581],
              [-96.4639, 41.3586],
              [-96.4639, 41.3702],
            ],
          ],
          [
            [
              [-96.5218, 41.3506],
              [-96.5111, 41.3452],
              [-96.5218, 41.3452],
              [-96.5218, 41.3506],
            ],
          ],
          [
            [
              [-96.553, 41.3288],
              [-96.5508, 41.3141],
              [-96.5548, 41.3142],
              [-96.5605, 41.3219],
              [-96.553, 41.3288],
            ],
          ],
          [
            [
              [-96.7269, 41.3462],
              [-96.727, 41.3423],
              [-96.7368, 41.3425],
              [-96.7269, 41.3462],
            ],
          ],
          [
            [
              [-96.7368, 41.3363],
              [-96.7271, 41.3363],
              [-96.7218, 41.3461],
              [-96.7173, 41.3508],
              [-96.7078, 41.3441],
              [-96.6973, 41.3825],
              [-96.6179, 41.4344],
              [-96.5413, 41.3693],
              [-96.5509, 41.3694],
              [-96.5509, 41.3651],
              [-96.5413, 41.3617],
              [-96.5264, 41.3572],
              [-96.5606, 41.3365],
              [-96.563, 41.3433],
              [-96.5633, 41.3433],
              [-96.5923, 41.3437],
              [-96.5827, 41.3543],
              [-96.5923, 41.3544],
              [-96.6021, 41.3507],
              [-96.6022, 41.3434],
              [-96.6502, 41.3218],
              [-96.6503, 41.3292],
              [-96.6884, 41.3292],
              [-96.7092, 41.3295],
              [-96.7245, 41.3248],
              [-96.7246, 41.3218],
              [-96.7366, 41.3141],
              [-96.7457, 41.3142],
              [-96.7456, 41.3176],
              [-96.7368, 41.3363],
            ],
          ],
          [
            [
              [-96.7457, 41.314],
              [-96.7458, 41.3111],
              [-96.7461, 41.3111],
              [-96.7457, 41.314],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104870',
        NAME: 'Cedar Bluffs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155771475,
        AWATER: 1563931,
        INTPTLAT: '+41.3726808',
        INTPTLON: '-096.6304307',
        ELSDLEA: '',
        UNSDLEA: '04870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4543, 41.3353],
            [-96.4542, 41.3424],
            [-96.4449, 41.3522],
            [-96.4449, 41.3538],
            [-96.4393, 41.3531],
            [-96.4352, 41.353],
            [-96.4351, 41.369],
            [-96.4219, 41.3645],
            [-96.3506, 41.2652],
            [-96.3612, 41.2189],
            [-96.3247, 41.1859],
            [-96.3619, 41.1853],
            [-96.3676, 41.182],
            [-96.3588, 41.1612],
            [-96.3611, 41.1612],
            [-96.3677, 41.1612],
            [-96.4449, 41.1612],
            [-96.4543, 41.3353],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3179080',
        NAME: 'Yutan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132215972,
        AWATER: 7169631,
        INTPTLAT: '+41.2462249',
        INTPTLON: '-096.4105131',
        ELSDLEA: '',
        UNSDLEA: '79080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3452, 40.668],
            [-101.3079, 40.6979],
            [-100.7984, 40.7001],
            [-100.7828, 40.5255],
            [-100.7447, 40.517],
            [-100.772, 40.481],
            [-100.7624, 40.4448],
            [-100.7526, 40.4449],
            [-100.7341, 40.4667],
            [-100.734, 40.4593],
            [-100.7339, 40.4517],
            [-100.7713, 40.4046],
            [-100.7774, 40.3495],
            [-100.8, 40.3494],
            [-100.8436, 40.3357],
            [-100.9472, 40.35],
            [-100.9669, 40.3504],
            [-100.9761, 40.3505],
            [-101.0228, 40.408],
            [-101.2304, 40.452],
            [-101.2025, 40.4588],
            [-101.345, 40.6538],
            [-101.3452, 40.668],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3109120',
        NAME: 'Hayes Center Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1418724889,
        AWATER: 406004,
        INTPTLAT: '+40.5417640',
        INTPTLON: '-101.0050061',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0366, 41.0547],
              [-100.0267, 41.0614],
              [-100.0083, 41.0615],
              [-100.0081, 41.0473],
              [-100.0366, 41.0547],
            ],
          ],
          [
            [
              [-100.0656, 41.1262],
              [-100.0269, 41.111],
              [-100.0551, 41.1045],
              [-100.0659, 41.1046],
              [-100.0656, 41.1262],
            ],
          ],
          [
            [
              [-100.0659, 41.098],
              [-100.0365, 41.1042],
              [-100.0269, 41.1046],
              [-100.0083, 41.0759],
              [-100.0267, 41.0614],
              [-100.0366, 41.0614],
              [-100.047, 41.0694],
              [-100.0659, 41.098],
            ],
          ],
          [
            [
              [-100.148, 40.8441],
              [-100.1467, 40.8439],
              [-100.1447, 40.8439],
              [-100.1383, 40.8439],
              [-100.1479, 40.8405],
              [-100.148, 40.8439],
              [-100.148, 40.8441],
            ],
          ],
          [
            [
              [-100.1666, 40.8292],
              [-100.1477, 40.8294],
              [-100.0998, 40.83],
              [-100.0431, 40.917],
              [-100.0387, 40.9316],
              [-100.0431, 40.9392],
              [-100.0052, 40.9977],
              [-99.986, 41.0473],
              [-99.8637, 41.0179],
              [-99.8447, 41.0033],
              [-99.8373, 40.9889],
              [-99.8353, 40.9889],
              [-99.8352, 40.8803],
              [-99.8353, 40.8669],
              [-99.8353, 40.8591],
              [-99.897, 40.7429],
              [-99.9014, 40.7429],
              [-100.0048, 40.7432],
              [-100.1091, 40.7864],
              [-100.1859, 40.8016],
              [-100.1666, 40.8292],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105460',
        NAME: 'Cozad City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 621103457,
        AWATER: 3272462,
        INTPTLAT: '+40.8970812',
        INTPTLON: '-099.9617229',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.199, 40.2186],
              [-96.1991, 40.2152],
              [-96.2045, 40.2116],
              [-96.199, 40.2186],
            ],
          ],
          [
            [
              [-96.2462, 40.2039],
              [-96.2366, 40.2038],
              [-96.2367, 40.1931],
              [-96.2463, 40.1931],
              [-96.2462, 40.2039],
            ],
          ],
          [
            [
              [-96.3407, 40.5298],
              [-96.3221, 40.5374],
              [-96.3127, 40.5374],
              [-96.305, 40.5374],
              [-96.3018, 40.5365],
              [-96.3016, 40.5365],
              [-96.3015, 40.5369],
              [-96.3015, 40.5374],
              [-96.2957, 40.5369],
              [-96.2957, 40.5352],
              [-96.2971, 40.5347],
              [-96.2934, 40.5229],
              [-96.341, 40.5229],
              [-96.3407, 40.5298],
            ],
            [
              [-96.2986, 40.536],
              [-96.298, 40.5369],
              [-96.2986, 40.5365],
              [-96.2986, 40.536],
            ],
            [
              [-96.2994, 40.5355],
              [-96.2994, 40.5352],
              [-96.2987, 40.5351],
              [-96.2987, 40.5355],
              [-96.2986, 40.536],
              [-96.2989, 40.5355],
              [-96.2994, 40.5355],
            ],
          ],
          [
            [
              [-96.3592, 40.5575],
              [-96.3552, 40.56],
              [-96.3496, 40.5661],
              [-96.3459, 40.5735],
              [-96.336, 40.5764],
              [-96.3316, 40.5745],
              [-96.3497, 40.5513],
              [-96.3551, 40.5517],
              [-96.3592, 40.5552],
              [-96.3592, 40.5575],
            ],
          ],
          [
            [
              [-96.3881, 40.3709],
              [-96.3691, 40.378],
              [-96.3062, 40.3924],
              [-96.2553, 40.4068],
              [-96.2739, 40.516],
              [-96.2741, 40.5229],
              [-96.264, 40.5374],
              [-96.2746, 40.5664],
              [-96.0761, 40.5667],
              [-96.0106, 40.6246],
              [-96.0106, 40.6213],
              [-96.0005, 40.6173],
              [-95.9916, 40.6172],
              [-95.9915, 40.6101],
              [-95.9441, 40.5448],
              [-96.0099, 40.5086],
              [-96.0148, 40.5086],
              [-96.067, 40.5231],
              [-96.104, 40.3669],
              [-96.104, 40.3635],
              [-96.0938, 40.3565],
              [-96.0861, 40.3417],
              [-96.0795, 40.3263],
              [-96.0793, 40.3201],
              [-96.0954, 40.3128],
              [-96.067, 40.3054],
              [-96.067, 40.2978],
              [-96.0668, 40.2765],
              [-96.0994, 40.2839],
              [-96.1047, 40.2839],
              [-96.1093, 40.2876],
              [-96.1137, 40.2844],
              [-96.1137, 40.2839],
              [-96.1323, 40.2696],
              [-96.1417, 40.2624],
              [-96.1801, 40.2113],
              [-96.1893, 40.2114],
              [-96.199, 40.2186],
              [-96.2552, 40.2621],
              [-96.2926, 40.2529],
              [-96.3022, 40.2552],
              [-96.3118, 40.2552],
              [-96.3309, 40.3351],
              [-96.351, 40.3351],
              [-96.3881, 40.3709],
            ],
            [
              [-96.0954, 40.3128],
              [-96.1049, 40.3093],
              [-96.0955, 40.3056],
              [-96.0954, 40.3128],
            ],
          ],
          [
            [
              [-96.0668, 40.2765],
              [-96.0573, 40.2693],
              [-96.0668, 40.2699],
              [-96.0668, 40.2765],
            ],
          ],
          [
            [
              [-96.0861, 40.3417],
              [-96.0743, 40.3263],
              [-96.0747, 40.3263],
              [-96.0861, 40.3417],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100176',
        NAME: 'Johnson County Central Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 735890199,
        AWATER: 1159310,
        INTPTLAT: '+40.4281871',
        INTPTLON: '-096.1763137',
        ELSDLEA: '',
        UNSDLEA: '00176',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7105, 42.5177],
            [-97.7009, 42.5177],
            [-97.6814, 42.5107],
            [-97.6717, 42.5107],
            [-97.5542, 42.5834],
            [-97.4854, 42.5836],
            [-97.4461, 42.6053],
            [-97.4067, 42.5693],
            [-97.4067, 42.5623],
            [-97.387, 42.5258],
            [-97.4265, 42.4966],
            [-97.4263, 42.4677],
            [-97.4555, 42.4162],
            [-97.4655, 42.4164],
            [-97.4847, 42.4236],
            [-97.4847, 42.4236],
            [-97.5042, 42.4307],
            [-97.6811, 42.467],
            [-97.6812, 42.4779],
            [-97.6812, 42.4816],
            [-97.6813, 42.4889],
            [-97.6913, 42.4961],
            [-97.7008, 42.5034],
            [-97.7008, 42.5069],
            [-97.7009, 42.5105],
            [-97.7106, 42.5105],
            [-97.7105, 42.5177],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178450',
        NAME: 'Wausa Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314035891,
        AWATER: 10926,
        INTPTLAT: '+42.5055537',
        INTPTLON: '-097.5295412',
        ELSDLEA: '',
        UNSDLEA: '78450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.0779, 41.8573],
              [-97.0631, 41.8447],
              [-97.0582, 41.8369],
              [-97.0584, 41.8308],
              [-97.0584, 41.8301],
              [-97.0727, 41.8302],
              [-97.0779, 41.8573],
            ],
          ],
          [
            [
              [-97.3102, 41.7941],
              [-97.3028, 41.794],
              [-97.3009, 41.794],
              [-97.3101, 41.7867],
              [-97.3102, 41.7941],
            ],
          ],
          [
            [
              [-97.3493, 41.9457],
              [-97.3493, 41.9535],
              [-97.3298, 41.9751],
              [-97.2624, 42.0836],
              [-97.2517, 42.0908],
              [-97.2342, 42.0907],
              [-97.2241, 42.0907],
              [-97.1808, 42.0907],
              [-97.1758, 42.083],
              [-97.1759, 42.0907],
              [-97.1368, 42.0907],
              [-97.1367, 42.0041],
              [-97.1268, 41.997],
              [-97.0975, 41.91],
              [-97.0585, 41.8881],
              [-97.0873, 41.8879],
              [-97.0779, 41.8693],
              [-97.0779, 41.8659],
              [-97.2234, 41.8086],
              [-97.2424, 41.8155],
              [-97.2911, 41.8082],
              [-97.3105, 41.823],
              [-97.3013, 41.8229],
              [-97.3584, 41.9025],
              [-97.3493, 41.9457],
            ],
          ],
          [
            [
              [-97.3584, 41.9106],
              [-97.3584, 41.9025],
              [-97.3682, 41.9025],
              [-97.3584, 41.9106],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100041',
        NAME: 'Stanton Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 487088788,
        AWATER: 4798568,
        INTPTLAT: '+41.9398040',
        INTPTLON: '-097.2141447',
        ELSDLEA: '',
        UNSDLEA: '00041',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3301, 42.258],
              [-97.2909, 42.2645],
              [-97.1398, 42.2355],
              [-97.0671, 42.1919],
              [-97.0765, 42.0975],
              [-97.1368, 42.0907],
              [-97.1759, 42.0907],
              [-97.1808, 42.0907],
              [-97.2241, 42.0907],
              [-97.2342, 42.0907],
              [-97.2517, 42.0908],
              [-97.301, 42.1923],
              [-97.3298, 42.2434],
              [-97.3299, 42.2502],
              [-97.3301, 42.258],
            ],
          ],
          [
            [
              [-97.3493, 42.163],
              [-97.3298, 42.1631],
              [-97.3298, 42.1561],
              [-97.3399, 42.1486],
              [-97.3639, 42.1488],
              [-97.3493, 42.163],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178840',
        NAME: 'Winside Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298278732,
        AWATER: 186916,
        INTPTLAT: '+42.1702195',
        INTPTLON: '-097.2028380',
        ELSDLEA: '',
        UNSDLEA: '78840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2781, 40.821],
            [-98.264, 40.821],
            [-98.245, 40.821],
            [-98.2406, 40.8143],
            [-98.2331, 40.8209],
            [-98.2256, 40.8209],
            [-98.1217, 40.8434],
            [-98.054, 40.7274],
            [-98.165, 40.6984],
            [-98.2828, 40.7638],
            [-98.2781, 40.821],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171010',
        NAME: 'Giltner Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204308041,
        AWATER: 387924,
        INTPTLAT: '+40.7680814',
        INTPTLON: '-098.1647712',
        ELSDLEA: '',
        UNSDLEA: '71010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4655, 42.4164],
              [-97.4555, 42.4162],
              [-97.4558, 42.3877],
              [-97.4655, 42.4164],
            ],
          ],
          [
            [
              [-97.681, 42.4526],
              [-97.6811, 42.467],
              [-97.5042, 42.4307],
              [-97.4847, 42.4236],
              [-97.4847, 42.4236],
              [-97.4947, 42.4018],
              [-97.5042, 42.4019],
              [-97.4848, 42.3296],
              [-97.5722, 42.2643],
              [-97.6597, 42.2643],
              [-97.681, 42.4526],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175030',
        NAME: 'Osmond Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278370347,
        AWATER: 70635,
        INTPTLAT: '+42.3632010',
        INTPTLON: '-097.5925511',
        ELSDLEA: '',
        UNSDLEA: '75030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2496, 42.8376],
              [-97.2222, 42.82],
              [-97.2141, 42.8081],
              [-97.1721, 42.8003],
              [-97.1788, 42.7866],
              [-97.1902, 42.7867],
              [-97.2169, 42.7975],
              [-97.2222, 42.7938],
              [-97.2217, 42.7938],
              [-97.2197, 42.7866],
              [-97.2298, 42.7866],
              [-97.249, 42.7959],
              [-97.2489, 42.798],
              [-97.2489, 42.8012],
              [-97.2445, 42.8012],
              [-97.244, 42.8133],
              [-97.244, 42.8137],
              [-97.249, 42.8179],
              [-97.2496, 42.8376],
            ],
          ],
          [
            [
              [-97.7207, 42.8473],
              [-97.6354, 42.8515],
              [-97.327, 42.8609],
              [-97.3272, 42.8402],
              [-97.3272, 42.837],
              [-97.4162, 42.8156],
              [-97.3869, 42.7867],
              [-97.387, 42.678],
              [-97.4459, 42.6273],
              [-97.643, 42.6995],
              [-97.6536, 42.794],
              [-97.7207, 42.8473],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105630',
        NAME: 'Crofton Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486075844,
        AWATER: 33220452,
        INTPTLAT: '+42.7537265',
        INTPTLON: '-097.5151133',
        ELSDLEA: '',
        UNSDLEA: '05630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7794, 42.851],
            [-97.7988, 42.8159],
            [-97.9035, 42.8008],
            [-97.7794, 42.851],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176400',
        NAME: 'Santee Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36665564,
        AWATER: 18554532,
        INTPTLAT: '+42.8291433',
        INTPTLON: '-097.8399013',
        ELSDLEA: '',
        UNSDLEA: '76400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.1867, 42.8409],
              [-98.1825, 42.84],
              [-98.1874, 42.8285],
              [-98.1867, 42.8409],
            ],
          ],
          [
            [
              [-98.3247, 42.6977],
              [-98.3053, 42.7121],
              [-98.3054, 42.6905],
              [-98.3188, 42.6975],
              [-98.3247, 42.6977],
            ],
          ],
          [
            [
              [-98.4687, 42.9494],
              [-98.2177, 42.8523],
              [-98.3051, 42.7604],
              [-98.3052, 42.7335],
              [-98.3245, 42.7329],
              [-98.343, 42.7329],
              [-98.4819, 42.7561],
              [-98.4818, 42.7705],
              [-98.51, 42.756],
              [-98.5947, 42.8257],
              [-98.555, 42.9372],
              [-98.4687, 42.9494],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173170',
        NAME: 'Lynch Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495681801,
        AWATER: 12639080,
        INTPTLAT: '+42.8292066',
        INTPTLON: '-098.4164585',
        ELSDLEA: '',
        UNSDLEA: '73170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4498, 40.5984],
            [-98.3824, 40.623],
            [-98.3641, 40.5701],
            [-98.4498, 40.5984],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171580',
        NAME: 'Hastings Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34952087,
        AWATER: 410827,
        INTPTLAT: '+40.5829940',
        INTPTLON: '-098.3710735',
        ELSDLEA: '',
        UNSDLEA: '71580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.717, 41.8862],
              [-97.7067, 41.8871],
              [-97.6975, 41.8843],
              [-97.717, 41.8843],
              [-97.717, 41.8862],
            ],
          ],
          [
            [
              [-97.7366, 41.9019],
              [-97.7264, 41.8948],
              [-97.7365, 41.8874],
              [-97.7366, 41.9019],
            ],
          ],
          [
            [
              [-97.8719, 41.887],
              [-97.8719, 41.887],
              [-97.8718, 41.8795],
              [-97.8719, 41.8795],
              [-97.8719, 41.887],
            ],
          ],
          [
            [
              [-97.8845, 41.8652],
              [-97.8911, 41.8725],
              [-97.8912, 41.8794],
              [-97.8818, 41.8794],
              [-97.8719, 41.8725],
              [-97.8718, 41.8653],
              [-97.8613, 41.8496],
              [-97.8523, 41.8496],
              [-97.8621, 41.8653],
              [-97.8621, 41.8726],
              [-97.8526, 41.8795],
              [-97.8431, 41.8795],
              [-97.8332, 41.8795],
              [-97.8428, 41.8872],
              [-97.8428, 41.8905],
              [-97.7855, 41.8874],
              [-97.7463, 41.8874],
              [-97.7365, 41.8874],
              [-97.7269, 41.8729],
              [-97.717, 41.8729],
              [-97.7079, 41.873],
              [-97.708, 41.8656],
              [-97.708, 41.8585],
              [-97.7079, 41.8497],
              [-97.7079, 41.8374],
              [-97.6889, 41.8223],
              [-97.6892, 41.8162],
              [-97.6975, 41.8151],
              [-97.7072, 41.8076],
              [-97.6974, 41.8077],
              [-97.6874, 41.8077],
              [-97.6778, 41.7862],
              [-97.6684, 41.7862],
              [-97.6585, 41.7863],
              [-97.6479, 41.7645],
              [-97.6479, 41.7572],
              [-97.6585, 41.7572],
              [-97.7065, 41.6492],
              [-97.6969, 41.6417],
              [-97.6776, 41.6345],
              [-97.658, 41.6128],
              [-97.6966, 41.6127],
              [-97.6967, 41.6198],
              [-97.7021, 41.6198],
              [-97.702, 41.6127],
              [-97.7158, 41.6128],
              [-97.7927, 41.619],
              [-97.8027, 41.641],
              [-97.8308, 41.6947],
              [-97.8906, 41.7421],
              [-97.8814, 41.8145],
              [-97.8845, 41.8652],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174340',
        NAME: 'Newman Grove Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412487142,
        AWATER: 234137,
        INTPTLAT: '+41.7673216',
        INTPTLON: '-097.7793700',
        ELSDLEA: '',
        UNSDLEA: '74340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3592, 41.6852],
              [-97.3399, 41.6853],
              [-97.34, 41.6779],
              [-97.3399, 41.6707],
              [-97.3493, 41.6734],
              [-97.3493, 41.678],
              [-97.3512, 41.678],
              [-97.3593, 41.678],
              [-97.3592, 41.6852],
            ],
          ],
          [
            [
              [-97.4264, 41.671],
              [-97.4069, 41.682],
              [-97.3875, 41.678],
              [-97.3792, 41.6636],
              [-97.3779, 41.6563],
              [-97.3975, 41.6602],
              [-97.4264, 41.671],
            ],
          ],
          [
            [
              [-97.6, 41.5985],
              [-97.5423, 41.5695],
              [-97.3779, 41.6563],
              [-97.3682, 41.6634],
              [-97.3638, 41.6707],
              [-97.3512, 41.6745],
              [-97.3512, 41.6735],
              [-97.3399, 41.6633],
              [-97.2578, 41.5837],
              [-97.2526, 41.5807],
              [-97.2526, 41.5691],
              [-97.2535, 41.3842],
              [-97.3258, 41.3948],
              [-97.3104, 41.446],
              [-97.5418, 41.4676],
              [-97.6188, 41.4971],
              [-97.6096, 41.5839],
              [-97.6, 41.5985],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100112',
        NAME: 'Lakeview Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 545611145,
        AWATER: 9551187,
        INTPTLAT: '+41.5321465',
        INTPTLON: '-097.4162334',
        ELSDLEA: '',
        UNSDLEA: '00112',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9961, 42.5975],
              [-96.996, 42.6007],
              [-96.9785, 42.601],
              [-96.9961, 42.5903],
              [-96.9961, 42.5975],
            ],
          ],
          [
            [
              [-97.1837, 42.4313],
              [-97.1783, 42.4384],
              [-97.1828, 42.4458],
              [-97.1829, 42.4529],
              [-97.1236, 42.4747],
              [-97.1233, 42.5832],
              [-97.0939, 42.5938],
              [-97.0978, 42.6009],
              [-97.01, 42.612],
              [-97.0063, 42.6014],
              [-96.9961, 42.5903],
              [-96.9473, 42.5396],
              [-96.947, 42.5251],
              [-96.9372, 42.5251],
              [-96.9593, 42.3513],
              [-96.9593, 42.3441],
              [-96.969, 42.3077],
              [-96.979, 42.3077],
              [-97.0867, 42.3447],
              [-97.1442, 42.3321],
              [-97.1442, 42.3322],
              [-97.1461, 42.3441],
              [-97.1544, 42.3443],
              [-97.1614, 42.3443],
              [-97.1837, 42.4302],
              [-97.1837, 42.4313],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100004',
        NAME: 'Laurel-Concord Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473567616,
        AWATER: 103212,
        INTPTLAT: '+42.4540732',
        INTPTLON: '-097.0484706',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.0063, 42.6014],
              [-96.9961, 42.5975],
              [-96.9961, 42.5903],
              [-97.0063, 42.6014],
            ],
          ],
          [
            [
              [-97.2222, 42.82],
              [-97.2124, 42.8118],
              [-97.1721, 42.8029],
              [-97.1721, 42.8003],
              [-97.2141, 42.8081],
              [-97.2222, 42.82],
            ],
          ],
          [
            [
              [-97.1902, 42.7867],
              [-97.2197, 42.7866],
              [-97.2217, 42.7938],
              [-97.2222, 42.7938],
              [-97.2169, 42.7975],
              [-97.1902, 42.7867],
            ],
          ],
          [
            [
              [-97.249, 42.8179],
              [-97.244, 42.8137],
              [-97.2523, 42.816],
              [-97.249, 42.8179],
            ],
          ],
          [
            [
              [-97.2579, 42.8537],
              [-97.2496, 42.8555],
              [-97.2182, 42.8312],
              [-97.2181, 42.8288],
              [-97.2222, 42.82],
              [-97.2496, 42.8376],
              [-97.2579, 42.8537],
            ],
          ],
          [
            [
              [-97.3072, 42.8513],
              [-97.2875, 42.8552],
              [-97.283, 42.8544],
              [-97.288, 42.8377],
              [-97.3072, 42.8447],
              [-97.3072, 42.8513],
            ],
          ],
          [
            [
              [-97.327, 42.8609],
              [-97.3068, 42.8676],
              [-97.3072, 42.8513],
              [-97.3123, 42.8448],
              [-97.3272, 42.8402],
              [-97.327, 42.8609],
            ],
          ],
          [
            [
              [-97.4461, 42.6053],
              [-97.4459, 42.6273],
              [-97.387, 42.678],
              [-97.3869, 42.7867],
              [-97.4162, 42.8156],
              [-97.3272, 42.837],
              [-97.3123, 42.8448],
              [-97.3072, 42.8447],
              [-97.288, 42.8377],
              [-97.2523, 42.816],
              [-97.2501, 42.8106],
              [-97.244, 42.8133],
              [-97.2445, 42.8012],
              [-97.2489, 42.8012],
              [-97.2489, 42.798],
              [-97.249, 42.7959],
              [-97.2298, 42.7866],
              [-97.245, 42.7574],
              [-97.1653, 42.703],
              [-97.0544, 42.6989],
              [-97.0304, 42.6658],
              [-97.0304, 42.6574],
              [-97.01, 42.612],
              [-97.0978, 42.6009],
              [-97.0939, 42.5938],
              [-97.1233, 42.5832],
              [-97.1233, 42.5871],
              [-97.1328, 42.5906],
              [-97.1525, 42.598],
              [-97.3285, 42.5402],
              [-97.3382, 42.5296],
              [-97.3579, 42.5329],
              [-97.3675, 42.5369],
              [-97.4067, 42.5623],
              [-97.4067, 42.5693],
              [-97.4461, 42.6053],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171520',
        NAME: 'Hartington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 643587708,
        AWATER: 2294890,
        INTPTLAT: '+42.6784182',
        INTPTLON: '-097.2579211',
        ELSDLEA: '',
        UNSDLEA: '71520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4067, 42.5623],
            [-97.3675, 42.5369],
            [-97.3579, 42.5329],
            [-97.3382, 42.5296],
            [-97.3285, 42.5402],
            [-97.1525, 42.598],
            [-97.1328, 42.5906],
            [-97.1233, 42.5871],
            [-97.1233, 42.5832],
            [-97.1236, 42.4747],
            [-97.1829, 42.4529],
            [-97.299, 42.4604],
            [-97.387, 42.5258],
            [-97.4067, 42.5623],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105310',
        NAME: 'Coleridge Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240074363,
        AWATER: 53341,
        INTPTLAT: '+42.5182360',
        INTPTLON: '-097.2232211',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3493, 42.2502],
              [-97.3299, 42.2502],
              [-97.3298, 42.2434],
              [-97.3493, 42.2502],
            ],
          ],
          [
            [
              [-97.5042, 42.4019],
              [-97.4947, 42.4018],
              [-97.4847, 42.4236],
              [-97.4655, 42.4164],
              [-97.4558, 42.3877],
              [-97.4555, 42.4162],
              [-97.4263, 42.4677],
              [-97.4265, 42.4966],
              [-97.387, 42.5258],
              [-97.299, 42.4604],
              [-97.1829, 42.4529],
              [-97.1828, 42.4458],
              [-97.1783, 42.4384],
              [-97.1837, 42.4313],
              [-97.1837, 42.4302],
              [-97.1614, 42.3443],
              [-97.1442, 42.3322],
              [-97.1442, 42.3321],
              [-97.2227, 42.3299],
              [-97.2326, 42.3444],
              [-97.2326, 42.3371],
              [-97.2909, 42.2645],
              [-97.3301, 42.258],
              [-97.3493, 42.2502],
              [-97.4848, 42.3296],
              [-97.5042, 42.4019],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175930',
        NAME: 'Randolph Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491405321,
        AWATER: 195853,
        INTPTLAT: '+42.3733267',
        INTPTLON: '-097.3395605',
        ELSDLEA: '',
        UNSDLEA: '75930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3612, 41.2189],
            [-96.2825, 41.2191],
            [-96.2631, 41.2013],
            [-96.2563, 41.2048],
            [-96.2535, 41.2049],
            [-96.2343, 41.2048],
            [-96.1676, 41.1616],
            [-96.2342, 41.0669],
            [-96.2345, 41.0043],
            [-96.2736, 41.0199],
            [-96.2932, 41.0253],
            [-96.3398, 41.0825],
            [-96.3247, 41.1859],
            [-96.3612, 41.2189],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171220',
        NAME: 'Gretna Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210102677,
        AWATER: 10015543,
        INTPTLAT: '+41.1209046',
        INTPTLON: '-096.2638836',
        ELSDLEA: '',
        UNSDLEA: '71220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1773, 41.3065],
            [-96.0815, 41.3932],
            [-96.0694, 41.3932],
            [-96.0181, 41.3817],
            [-95.9985, 41.381],
            [-95.9378, 41.3908],
            [-95.9108, 41.2319],
            [-95.8419, 41.1763],
            [-95.9869, 41.1617],
            [-96.0059, 41.1907],
            [-96.0243, 41.2197],
            [-96.1007, 41.2197],
            [-96.1772, 41.2775],
            [-96.1773, 41.3065],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174820',
        NAME: 'Omaha Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339214246,
        AWATER: 9584580,
        INTPTLAT: '+41.2900058',
        INTPTLON: '-096.0131418',
        ELSDLEA: '',
        UNSDLEA: '74820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0815, 41.2193],
            [-96.0243, 41.2197],
            [-96.0059, 41.1907],
            [-96.0715, 41.1907],
            [-96.0815, 41.2193],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175900',
        NAME: 'Ralston Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18671481,
        AWATER: 88388,
        INTPTLAT: '+41.2071075',
        INTPTLON: '-096.0402212',
        ELSDLEA: '',
        UNSDLEA: '75900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4219, 41.3645],
            [-96.3307, 41.3931],
            [-96.2915, 41.3644],
            [-96.2825, 41.2191],
            [-96.3612, 41.2189],
            [-96.3506, 41.2652],
            [-96.4219, 41.3645],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100165',
        NAME: 'Douglas County West Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136615240,
        AWATER: 12353823,
        INTPTLAT: '+41.3024486',
        INTPTLON: '-096.3356861',
        ELSDLEA: '',
        UNSDLEA: '00165',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.6768, 41.2411],
              [-100.6767, 41.2471],
              [-100.6574, 41.2638],
              [-100.6384, 41.2638],
              [-100.6193, 41.2638],
              [-100.6769, 41.2349],
              [-100.6768, 41.2411],
            ],
          ],
          [
            [
              [-100.5805, 41.2463],
              [-100.5629, 41.3071],
              [-100.4468, 41.3212],
              [-100.3505, 41.263],
              [-100.3516, 41.1619],
              [-100.3516, 41.1537],
              [-100.4671, 41.133],
              [-100.5092, 41.0285],
              [-100.5083, 40.883],
              [-100.47, 40.8424],
              [-100.5834, 40.8292],
              [-100.64, 40.9161],
              [-100.6872, 41.0037],
              [-100.6008, 41.162],
              [-100.5805, 41.2463],
            ],
          ],
          [
            [
              [-100.7347, 41.2852],
              [-100.7347, 41.293],
              [-100.7291, 41.293],
              [-100.7197, 41.2929],
              [-100.7152, 41.2929],
              [-100.6957, 41.293],
              [-100.6955, 41.2785],
              [-100.7348, 41.2785],
              [-100.7347, 41.2852],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100023',
        NAME: 'Maxwell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 853806131,
        AWATER: 924961,
        INTPTLAT: '+41.0948419',
        INTPTLON: '-100.5357826',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4563, 42.9557],
            [-101.4264, 42.9972],
            [-101.2281, 42.9981],
            [-100.8474, 42.9983],
            [-100.9446, 42.8281],
            [-100.866, 42.8277],
            [-100.8304, 42.7291],
            [-100.8931, 42.6986],
            [-101.208, 42.7],
            [-101.2473, 42.7858],
            [-101.4352, 42.786],
            [-101.4946, 42.8278],
            [-101.4563, 42.9557],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105280',
        NAME: 'Cody-Kilgore Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1422555700,
        AWATER: 5389294,
        INTPTLAT: '+42.8726863',
        INTPTLON: '-101.1887153',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0523, 41.3932],
            [-103.5123, 41.3945],
            [-103.549, 41.3065],
            [-103.5484, 41.0909],
            [-103.5927, 41.0017],
            [-103.9148, 41.0017],
            [-104.0532, 41.0014],
            [-104.0523, 41.3932],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172570',
        NAME: 'Kimball Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1860329387,
        AWATER: 1448446,
        INTPTLAT: '+41.2076194',
        INTPTLON: '-103.7728671',
        ELSDLEA: '',
        UNSDLEA: '72570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1853, 41.6123],
              [-97.1752, 41.619],
              [-97.1654, 41.6189],
              [-97.1522, 41.6121],
              [-97.1559, 41.6121],
              [-97.156, 41.6094],
              [-97.1461, 41.6048],
              [-97.1853, 41.6046],
              [-97.1853, 41.6123],
            ],
          ],
          [
            [
              [-97.209, 41.6335],
              [-97.2136, 41.6269],
              [-97.2233, 41.627],
              [-97.209, 41.6335],
            ],
          ],
          [
            [
              [-97.2186, 41.8009],
              [-97.2234, 41.8086],
              [-97.0779, 41.8659],
              [-97.0779, 41.8591],
              [-97.0779, 41.8573],
              [-97.0727, 41.8302],
              [-97.0778, 41.7575],
              [-97.0778, 41.7502],
              [-97.0594, 41.7136],
              [-97.0595, 41.7065],
              [-97.0655, 41.6886],
              [-97.0618, 41.6886],
              [-97.0547, 41.6919],
              [-97.0549, 41.6846],
              [-97.0887, 41.6302],
              [-97.0888, 41.6265],
              [-97.1461, 41.6048],
              [-97.1421, 41.623],
              [-97.122, 41.6411],
              [-97.1173, 41.6441],
              [-97.1511, 41.6482],
              [-97.156, 41.6518],
              [-97.1559, 41.6411],
              [-97.1652, 41.6266],
              [-97.1752, 41.6267],
              [-97.1805, 41.6267],
              [-97.186, 41.6341],
              [-97.1659, 41.6631],
              [-97.185, 41.7137],
              [-97.2236, 41.7575],
              [-97.2186, 41.8009],
            ],
            [
              [-97.1944, 41.6268],
              [-97.1944, 41.624],
              [-97.1945, 41.6162],
              [-97.1944, 41.6268],
            ],
          ],
          [
            [
              [-97.156, 41.6701],
              [-97.1517, 41.6701],
              [-97.1465, 41.6701],
              [-97.156, 41.6701],
            ],
          ],
          [
            [
              [-97.1945, 41.6123],
              [-97.1945, 41.6047],
              [-97.2526, 41.5691],
              [-97.2526, 41.5807],
              [-97.2578, 41.5837],
              [-97.1945, 41.6123],
            ],
          ],
          [
            [
              [-97.3512, 41.6735],
              [-97.3493, 41.6734],
              [-97.3399, 41.6707],
              [-97.3399, 41.6633],
              [-97.3512, 41.6735],
            ],
          ],
          [
            [
              [-97.3614, 41.7066],
              [-97.3608, 41.7066],
              [-97.3608, 41.7056],
              [-97.3612, 41.7056],
              [-97.3614, 41.7056],
              [-97.3614, 41.7066],
            ],
          ],
          [
            [
              [-97.361, 41.7046],
              [-97.3593, 41.7048],
              [-97.3495, 41.707],
              [-97.3495, 41.701],
              [-97.361, 41.7046],
            ],
          ],
          [
            [
              [-97.3619, 41.7072],
              [-97.3616, 41.7072],
              [-97.3616, 41.7066],
              [-97.3619, 41.7072],
            ],
          ],
          [
            [
              [-97.3623, 41.7061],
              [-97.3623, 41.7059],
              [-97.3626, 41.7059],
              [-97.3623, 41.7061],
            ],
          ],
          [
            [
              [-97.3626, 41.7053],
              [-97.3626, 41.7056],
              [-97.3623, 41.7056],
              [-97.3626, 41.7051],
              [-97.3626, 41.7053],
            ],
          ],
          [
            [
              [-97.3631, 41.7072],
              [-97.3626, 41.7072],
              [-97.3626, 41.707],
              [-97.363, 41.7066],
              [-97.3631, 41.7072],
            ],
          ],
          [
            [
              [-97.3642, 41.7093],
              [-97.3637, 41.7093],
              [-97.3637, 41.7087],
              [-97.3642, 41.7087],
              [-97.3642, 41.7093],
            ],
          ],
          [
            [
              [-97.3645, 41.7066],
              [-97.3645, 41.7062],
              [-97.3649, 41.7066],
              [-97.3645, 41.7066],
            ],
          ],
          [
            [
              [-97.3654, 41.7093],
              [-97.3644, 41.7093],
              [-97.3644, 41.7087],
              [-97.3649, 41.7087],
              [-97.3654, 41.7093],
            ],
          ],
          [
            [
              [-97.3655, 41.7077],
              [-97.3638, 41.7072],
              [-97.3644, 41.7066],
              [-97.365, 41.7072],
              [-97.3655, 41.7077],
            ],
          ],
          [
            [
              [-97.3681, 41.6915],
              [-97.3639, 41.6852],
              [-97.3682, 41.6852],
              [-97.3681, 41.6915],
            ],
          ],
          [
            [
              [-97.3682, 41.7107],
              [-97.3656, 41.7097],
              [-97.3656, 41.7093],
              [-97.3664, 41.7087],
              [-97.3663, 41.7076],
              [-97.3655, 41.7066],
              [-97.3646, 41.7056],
              [-97.3682, 41.7073],
              [-97.3682, 41.7107],
            ],
          ],
          [
            [
              [-97.3875, 41.7285],
              [-97.3718, 41.7142],
              [-97.3779, 41.7142],
              [-97.3874, 41.7142],
              [-97.3874, 41.7174],
              [-97.3874, 41.7262],
              [-97.3875, 41.7285],
            ],
          ],
          [
            [
              [-97.3875, 41.6814],
              [-97.3778, 41.6959],
              [-97.3778, 41.6997],
              [-97.3733, 41.6915],
              [-97.3682, 41.6852],
              [-97.3593, 41.678],
              [-97.3512, 41.678],
              [-97.3512, 41.6745],
              [-97.3638, 41.6707],
              [-97.3682, 41.6634],
              [-97.3779, 41.6563],
              [-97.3792, 41.6636],
              [-97.3875, 41.678],
              [-97.3875, 41.6814],
            ],
          ],
          [
            [
              [-97.0206, 41.6268],
              [-96.9929, 41.6193],
              [-96.993, 41.6122],
              [-96.9983, 41.6089],
              [-97.0027, 41.6124],
              [-97.0206, 41.6268],
            ],
          ],
          [
            [
              [-97.0551, 41.6812],
              [-97.0501, 41.6918],
              [-97.05, 41.6991],
              [-97.0501, 41.7065],
              [-97.0496, 41.7136],
              [-97.0303, 41.7066],
              [-97.0268, 41.7023],
              [-97.0209, 41.6848],
              [-97.0451, 41.6773],
              [-97.0552, 41.6774],
              [-97.0551, 41.6812],
            ],
          ],
          [
            [
              [-97.0584, 41.8513],
              [-97.0481, 41.8513],
              [-97.0583, 41.8447],
              [-97.0584, 41.8513],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105160',
        NAME: 'Clarkson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301804572,
        AWATER: 463290,
        INTPTLAT: '+41.7211981',
        INTPTLON: '-097.1473914',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.0619, 41.1196],
              [-99.0521, 41.1197],
              [-99.0523, 41.1123],
              [-99.0619, 41.1196],
            ],
          ],
          [
            [
              [-99.0643, 41.0689],
              [-99.0643, 41.0693],
              [-99.0643, 41.075],
              [-99.0643, 41.0689],
            ],
          ],
          [
            [
              [-99.0732, 41.0687],
              [-99.0737, 41.0612],
              [-99.0823, 41.0612],
              [-99.0924, 41.0614],
              [-99.0732, 41.0687],
            ],
          ],
          [
            [
              [-99.322, 41.0533],
              [-99.3121, 41.0469],
              [-99.3221, 41.0469],
              [-99.322, 41.0533],
            ],
          ],
          [
            [
              [-99.3135, 41.1119],
              [-99.3411, 41.112],
              [-99.3411, 41.1188],
              [-99.3135, 41.1119],
            ],
          ],
          [
            [
              [-99.291, 41.1766],
              [-99.3017, 41.223],
              [-99.3003, 41.223],
              [-99.2041, 41.2717],
              [-99.0895, 41.2504],
              [-99.062, 41.1342],
              [-99.0619, 41.1196],
              [-99.0983, 41.0797],
              [-99.1213, 41.0829],
              [-99.1214, 41.0813],
              [-99.1214, 41.0764],
              [-99.1312, 41.0682],
              [-99.1404, 41.0684],
              [-99.1405, 41.0829],
              [-99.1448, 41.061],
              [-99.2811, 41.0469],
              [-99.3135, 41.1119],
              [-99.3403, 41.1476],
              [-99.3405, 41.1584],
              [-99.291, 41.1766],
            ],
            [
              [-99.0718, 41.1012],
              [-99.0785, 41.0917],
              [-99.077, 41.0901],
              [-99.0763, 41.0891],
              [-99.0718, 41.1012],
            ],
          ],
          [
            [
              [-99.3788, 41.1188],
              [-99.35, 41.112],
              [-99.3411, 41.112],
              [-99.3412, 41.1045],
              [-99.3788, 41.1188],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172910',
        NAME: 'Litchfield Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 438448678,
        AWATER: 109879,
        INTPTLAT: '+41.1492075',
        INTPTLON: '-099.1952960',
        ELSDLEA: '',
        UNSDLEA: '72910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2772, 41.0612],
              [-97.2723, 41.0612],
              [-97.2723, 41.0534],
              [-97.2772, 41.0612],
            ],
          ],
          [
            [
              [-97.3299, 41.0614],
              [-97.3206, 41.0693],
              [-97.3204, 41.0614],
              [-97.3299, 41.0614],
            ],
          ],
          [
            [
              [-97.3541, 41.1194],
              [-97.3541, 41.1169],
              [-97.3543, 41.1088],
              [-97.3541, 41.1194],
            ],
          ],
          [
            [
              [-97.3682, 41.359],
              [-97.3682, 41.3662],
              [-97.3499, 41.3803],
              [-97.3497, 41.3803],
              [-97.3496, 41.3891],
              [-97.3258, 41.3948],
              [-97.2535, 41.3842],
              [-97.1006, 41.3651],
              [-97.0038, 41.3689],
              [-96.9758, 41.3766],
              [-96.9085, 41.3651],
              [-96.8999, 41.2904],
              [-96.8897, 41.2936],
              [-96.8852, 41.2866],
              [-96.8604, 41.2352],
              [-96.8706, 41.2352],
              [-96.8705, 41.2277],
              [-96.8987, 41.2642],
              [-97.0326, 41.2637],
              [-97.0814, 41.1408],
              [-97.1767, 41.0826],
              [-97.2343, 41.0902],
              [-97.2344, 41.0973],
              [-97.2298, 41.1046],
              [-97.2346, 41.1114],
              [-97.2345, 41.1046],
              [-97.2344, 41.0977],
              [-97.2438, 41.0903],
              [-97.2534, 41.0903],
              [-97.2537, 41.1051],
              [-97.2537, 41.1116],
              [-97.2586, 41.1117],
              [-97.2635, 41.1049],
              [-97.2635, 41.0903],
              [-97.2725, 41.0902],
              [-97.2824, 41.0831],
              [-97.2914, 41.0794],
              [-97.2823, 41.0792],
              [-97.2778, 41.0757],
              [-97.282, 41.0687],
              [-97.3107, 41.0613],
              [-97.301, 41.0689],
              [-97.3299, 41.0834],
              [-97.3365, 41.0834],
              [-97.3369, 41.0904],
              [-97.3395, 41.0977],
              [-97.3537, 41.0977],
              [-97.3495, 41.1088],
              [-97.3303, 41.1157],
              [-97.3206, 41.1194],
              [-97.311, 41.1194],
              [-97.311, 41.1089],
              [-97.311, 41.1085],
              [-97.311, 41.1082],
              [-97.311, 41.1076],
              [-97.311, 41.1049],
              [-97.2825, 41.1344],
              [-97.2827, 41.1274],
              [-97.2729, 41.1272],
              [-97.273, 41.1343],
              [-97.2632, 41.1634],
              [-97.3681, 41.2791],
              [-97.3682, 41.359],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100002',
        NAME: 'David City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 777170839,
        AWATER: 6456829,
        INTPTLAT: '+41.2712862',
        INTPTLON: '-097.1487867',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9202, 41.0461],
              [-96.93, 41.0244],
              [-96.9394, 41.0243],
              [-96.9491, 41.0243],
              [-96.9202, 41.0461],
            ],
          ],
          [
            [
              [-97.3018, 41.0423],
              [-97.2921, 41.0468],
              [-97.273, 41.0467],
              [-97.2723, 41.0467],
              [-97.2723, 41.0534],
              [-97.2723, 41.0612],
              [-97.2773, 41.0687],
              [-97.282, 41.0687],
              [-97.2778, 41.0757],
              [-97.2677, 41.083],
              [-97.2635, 41.0867],
              [-97.2635, 41.0903],
              [-97.2534, 41.0903],
              [-97.2438, 41.0903],
              [-97.2343, 41.0902],
              [-97.1767, 41.0826],
              [-97.1393, 41.0249],
              [-97.1014, 41.0321],
              [-97.0883, 41.0174],
              [-97.0526, 40.9883],
              [-97.044, 40.9738],
              [-96.9681, 41.0169],
              [-96.9491, 41.0243],
              [-96.9396, 41.0169],
              [-96.9298, 41.0061],
              [-96.9213, 41.0062],
              [-96.9107, 40.988],
              [-96.9201, 40.9844],
              [-96.9107, 40.9807],
              [-96.9106, 40.8284],
              [-97.0828, 40.8222],
              [-97.0972, 40.8144],
              [-97.1209, 40.8145],
              [-97.1588, 40.7926],
              [-97.1685, 40.7926],
              [-97.1779, 40.7964],
              [-97.187, 40.7963],
              [-97.2009, 40.8147],
              [-97.2045, 40.8147],
              [-97.2393, 40.9092],
              [-97.2346, 40.9091],
              [-97.2728, 40.9745],
              [-97.2728, 40.9961],
              [-97.3018, 41.0423],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176560',
        NAME: 'Seward Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 665517382,
        AWATER: 5006891,
        INTPTLAT: '+40.9449975',
        INTPTLON: '-097.0919583',
        ELSDLEA: '',
        UNSDLEA: '76560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2866, 41.365],
            [-98.2438, 41.4013],
            [-98.2404, 41.394],
            [-98.1751, 41.2933],
            [-98.1123, 41.2644],
            [-98.1739, 41.155],
            [-98.2502, 41.1115],
            [-98.2871, 41.0608],
            [-98.287, 41.0898],
            [-98.287, 41.1112],
            [-98.287, 41.1333],
            [-98.2966, 41.1333],
            [-98.3061, 41.1332],
            [-98.2866, 41.365],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100089',
        NAME: 'Palmer Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364095733,
        AWATER: 4256791,
        INTPTLAT: '+41.2548307',
        INTPTLON: '-098.2463628',
        ELSDLEA: '',
        UNSDLEA: '00089',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2558, 40.5324],
            [-99.1794, 40.6122],
            [-99.0087, 40.612],
            [-99.0657, 40.5248],
            [-99.0655, 40.4086],
            [-99.2368, 40.4376],
            [-99.2557, 40.4961],
            [-99.2555, 40.5166],
            [-99.2558, 40.5324],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103420',
        NAME: 'Axtell Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361937245,
        AWATER: 21421,
        INTPTLAT: '+40.5150310',
        INTPTLON: '-099.1315956',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.8131, 40.2907],
              [-95.8034, 40.2907],
              [-95.8034, 40.2834],
              [-95.8128, 40.2834],
              [-95.8131, 40.2907],
            ],
          ],
          [
            [
              [-95.884, 40.3192],
              [-95.8886, 40.3047],
              [-95.8981, 40.3045],
              [-95.884, 40.3192],
            ],
          ],
          [
            [
              [-95.9064, 40.523],
              [-95.8834, 40.523],
              [-95.8781, 40.523],
              [-95.71, 40.5238],
              [-95.6249, 40.3493],
              [-95.6905, 40.2911],
              [-95.7286, 40.2837],
              [-95.8034, 40.2907],
              [-95.8131, 40.2907],
              [-95.8128, 40.2834],
              [-95.8034, 40.2834],
              [-95.86, 40.2614],
              [-95.8791, 40.3193],
              [-95.9052, 40.3568],
              [-95.8578, 40.4146],
              [-95.9064, 40.523],
            ],
            [
              [-95.7891, 40.3053],
              [-95.7844, 40.3053],
              [-95.7665, 40.3128],
              [-95.7891, 40.3053],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103330',
        NAME: 'Auburn Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 488487078,
        AWATER: 3125559,
        INTPTLAT: '+40.4145248',
        INTPTLON: '-095.7675973',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.3691, 41.0899],
              [-99.3599, 41.0756],
              [-99.3691, 41.0755],
              [-99.3789, 41.0827],
              [-99.3691, 41.0899],
            ],
          ],
          [
            [
              [-99.7229, 41.0828],
              [-99.6557, 41.0754],
              [-99.6456, 41.0698],
              [-99.6456, 41.072],
              [-99.6456, 41.0753],
              [-99.6404, 41.0811],
              [-99.5502, 41.0896],
              [-99.5223, 41.1083],
              [-99.5015, 41.1182],
              [-99.483, 41.1182],
              [-99.4546, 41.1116],
              [-99.45, 41.0789],
              [-99.4354, 41.0717],
              [-99.4251, 41.0526],
              [-99.418, 41.0525],
              [-99.4081, 41.0524],
              [-99.385, 41.0467],
              [-99.3939, 41.052],
              [-99.3982, 41.052],
              [-99.3791, 41.0611],
              [-99.3599, 41.073],
              [-99.322, 41.0547],
              [-99.322, 41.0533],
              [-99.3221, 41.0469],
              [-99.3594, 41.0135],
              [-99.3691, 40.866],
              [-99.4068, 40.8442],
              [-99.4264, 40.8372],
              [-99.5776, 40.8727],
              [-99.5967, 40.8585],
              [-99.6924, 40.8869],
              [-99.7677, 40.9674],
              [-99.7677, 40.9742],
              [-99.7675, 41.0174],
              [-99.7676, 41.032],
              [-99.7804, 41.0467],
              [-99.7229, 41.0828],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177340',
        NAME: 'Sumner-Eddyville-Miller Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 781109879,
        AWATER: 143797,
        INTPTLAT: '+40.9886375',
        INTPTLON: '-099.5590189',
        ELSDLEA: '',
        UNSDLEA: '77340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.9447, 41.3945],
              [-100.9063, 41.3944],
              [-100.9059, 41.3508],
              [-100.9447, 41.3945],
            ],
          ],
          [
            [
              [-101.2699, 41.2937],
              [-101.2697, 41.3084],
              [-101.2697, 41.3949],
              [-101.1007, 41.3951],
              [-101.1357, 41.2789],
              [-101.0986, 41.2093],
              [-101.0763, 41.0045],
              [-101.0195, 40.9606],
              [-101.2488, 40.9601],
              [-101.2704, 41.0474],
              [-101.2699, 41.2937],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100024',
        NAME: 'Sutherland Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 743203646,
        AWATER: 10396094,
        INTPTLAT: '+41.1728870',
        INTPTLON: '-101.1692275',
        ELSDLEA: '',
        UNSDLEA: '00024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.3349, 40.9308],
            [-101.2488, 40.9601],
            [-101.0195, 40.9606],
            [-100.9059, 40.9595],
            [-100.811, 40.9164],
            [-100.7984, 40.7001],
            [-101.3079, 40.6979],
            [-101.3452, 40.668],
            [-101.343, 40.7058],
            [-101.3207, 40.7847],
            [-101.3349, 40.9308],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178260',
        NAME: 'Wallace Public School District 65 R',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1245288623,
        AWATER: 881638,
        INTPTLAT: '+40.8061173',
        INTPTLON: '-101.0746701',
        ELSDLEA: '',
        UNSDLEA: '78260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-103.2046, 42.6761],
              [-103.1452, 42.6662],
              [-103.1452, 42.6406],
              [-103.2167, 42.6549],
              [-103.2046, 42.6761],
            ],
          ],
          [
            [
              [-103.5046, 42.9604],
              [-103.2272, 42.9438],
              [-103.3558, 42.8438],
              [-103.2858, 42.7994],
              [-103.2868, 42.7263],
              [-103.2046, 42.6761],
              [-103.2347, 42.6549],
              [-103.2206, 42.5677],
              [-103.2638, 42.5246],
              [-103.3229, 42.4451],
              [-103.3332, 42.4456],
              [-103.4802, 42.4664],
              [-103.4804, 42.785],
              [-103.5046, 42.9604],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105520',
        NAME: 'Crawford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 954454877,
        AWATER: 4157964,
        INTPTLAT: '+42.6910531',
        INTPTLON: '-103.3588672',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.727, 41.3423],
              [-96.7269, 41.3462],
              [-96.7218, 41.3461],
              [-96.7271, 41.3363],
              [-96.727, 41.3423],
            ],
          ],
          [
            [
              [-96.7295, 41.0893],
              [-96.7264, 41.0893],
              [-96.726, 41.0821],
              [-96.7296, 41.0821],
              [-96.7295, 41.0893],
            ],
          ],
          [
            [
              [-96.7553, 41.227],
              [-96.7553, 41.2202],
              [-96.7602, 41.2202],
              [-96.7553, 41.227],
            ],
          ],
          [
            [
              [-96.7643, 41.1258],
              [-96.7552, 41.1258],
              [-96.7552, 41.1224],
              [-96.7552, 41.1185],
              [-96.7597, 41.1185],
              [-96.7643, 41.1258],
            ],
          ],
          [
            [
              [-96.8129, 41.2349],
              [-96.7754, 41.2714],
              [-96.7461, 41.3111],
              [-96.7458, 41.3111],
              [-96.7366, 41.3112],
              [-96.7366, 41.3141],
              [-96.7246, 41.3218],
              [-96.7078, 41.3218],
              [-96.6982, 41.3267],
              [-96.6884, 41.3292],
              [-96.6503, 41.3292],
              [-96.6502, 41.3218],
              [-96.6022, 41.3434],
              [-96.5923, 41.3437],
              [-96.5633, 41.3433],
              [-96.563, 41.3433],
              [-96.5606, 41.3365],
              [-96.5502, 41.3361],
              [-96.5503, 41.3287],
              [-96.553, 41.3288],
              [-96.5605, 41.3219],
              [-96.5548, 41.3142],
              [-96.5508, 41.3141],
              [-96.5412, 41.3073],
              [-96.5634, 41.1772],
              [-96.5212, 41.0817],
              [-96.5213, 41.0793],
              [-96.5213, 41.0759],
              [-96.5213, 41.0743],
              [-96.5213, 41.068],
              [-96.5213, 41.065],
              [-96.7214, 41.1038],
              [-96.7461, 41.1224],
              [-96.7456, 41.1326],
              [-96.7643, 41.1258],
              [-96.7744, 41.1257],
              [-96.784, 41.1329],
              [-96.7937, 41.1292],
              [-96.803, 41.133],
              [-96.8129, 41.2349],
            ],
            [
              [-96.7602, 41.2202],
              [-96.7553, 41.2202],
              [-96.7553, 41.227],
              [-96.7602, 41.2202],
            ],
          ],
          [
            [
              [-96.8706, 41.2352],
              [-96.8604, 41.2352],
              [-96.8606, 41.2277],
              [-96.8705, 41.2277],
              [-96.8706, 41.2352],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178210',
        NAME: 'Wahoo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500287023,
        AWATER: 1364467,
        INTPTLAT: '+41.2037900',
        INTPTLON: '-096.6533407',
        ELSDLEA: '',
        UNSDLEA: '78210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.4351, 41.369],
              [-96.4352, 41.353],
              [-96.4393, 41.3531],
              [-96.4449, 41.3538],
              [-96.4532, 41.3643],
              [-96.4629, 41.3709],
              [-96.4351, 41.369],
            ],
          ],
          [
            [
              [-96.5509, 41.3651],
              [-96.5509, 41.3694],
              [-96.5413, 41.3693],
              [-96.5413, 41.3617],
              [-96.5509, 41.3651],
            ],
          ],
          [
            [
              [-96.5412, 41.3073],
              [-96.5508, 41.3141],
              [-96.553, 41.3288],
              [-96.5503, 41.3287],
              [-96.5502, 41.3361],
              [-96.5606, 41.3365],
              [-96.5264, 41.3572],
              [-96.5218, 41.3506],
              [-96.5218, 41.3452],
              [-96.5111, 41.3452],
              [-96.4639, 41.3702],
              [-96.4639, 41.3586],
              [-96.4639, 41.3581],
              [-96.4639, 41.3571],
              [-96.4449, 41.3522],
              [-96.4542, 41.3424],
              [-96.464, 41.3353],
              [-96.4543, 41.3353],
              [-96.4449, 41.1612],
              [-96.4065, 41.1392],
              [-96.4028, 41.1392],
              [-96.4308, 41.1032],
              [-96.5212, 41.0817],
              [-96.5634, 41.1772],
              [-96.5412, 41.3073],
            ],
          ],
          [
            [
              [-96.464, 41.3353],
              [-96.4739, 41.3354],
              [-96.4831, 41.3356],
              [-96.464, 41.3353],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173590',
        NAME: 'Mead Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239622759,
        AWATER: 1568232,
        INTPTLAT: '+41.2247798',
        INTPTLON: '-096.4976557',
        ELSDLEA: '',
        UNSDLEA: '73590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.4067, 40.5292],
              [-96.4067, 40.5311],
              [-96.4008, 40.5308],
              [-96.4067, 40.5229],
              [-96.4067, 40.5292],
            ],
          ],
          [
            [
              [-96.4555, 40.5304],
              [-96.4447, 40.5375],
              [-96.4352, 40.5375],
              [-96.4258, 40.523],
              [-96.4446, 40.5228],
              [-96.4555, 40.5304],
            ],
          ],
          [
            [
              [-96.5303, 40.7551],
              [-96.521, 40.7516],
              [-96.521, 40.7482],
              [-96.5399, 40.7335],
              [-96.5399, 40.7388],
              [-96.5399, 40.7398],
              [-96.5399, 40.7406],
              [-96.5492, 40.748],
              [-96.5303, 40.7551],
            ],
          ],
          [
            [
              [-96.5589, 40.7481],
              [-96.5492, 40.748],
              [-96.5459, 40.7372],
              [-96.5589, 40.7481],
            ],
          ],
          [
            [
              [-96.5872, 40.7407],
              [-96.5842, 40.7407],
              [-96.5832, 40.7407],
              [-96.5794, 40.7407],
              [-96.5776, 40.7335],
              [-96.5871, 40.7373],
              [-96.5871, 40.7384],
              [-96.5872, 40.7407],
            ],
          ],
          [
            [
              [-96.7893, 40.499],
              [-96.7893, 40.5016],
              [-96.7984, 40.5231],
              [-96.7613, 40.5666],
              [-96.7413, 40.6275],
              [-96.7434, 40.6284],
              [-96.715, 40.654],
              [-96.7587, 40.7248],
              [-96.6539, 40.7263],
              [-96.6492, 40.7263],
              [-96.6444, 40.7236],
              [-96.6295, 40.7263],
              [-96.6062, 40.7154],
              [-96.6062, 40.7224],
              [-96.5953, 40.7285],
              [-96.5963, 40.7288],
              [-96.5918, 40.7288],
              [-96.5898, 40.7235],
              [-96.5775, 40.7189],
              [-96.5723, 40.6833],
              [-96.4638, 40.6314],
              [-96.4638, 40.6245],
              [-96.4638, 40.6168],
              [-96.4541, 40.5918],
              [-96.456, 40.581],
              [-96.4448, 40.5665],
              [-96.455, 40.552],
              [-96.4636, 40.5446],
              [-96.4637, 40.5375],
              [-96.4603, 40.5375],
              [-96.4552, 40.5375],
              [-96.4555, 40.5304],
              [-96.6571, 40.487],
              [-96.6854, 40.4467],
              [-96.7609, 40.4364],
              [-96.7609, 40.4439],
              [-96.7608, 40.4509],
              [-96.7799, 40.4654],
              [-96.7798, 40.4727],
              [-96.7986, 40.4797],
              [-96.7893, 40.499],
            ],
            [
              [-96.5919, 40.7245],
              [-96.5923, 40.725],
              [-96.5926, 40.725],
              [-96.593, 40.725],
              [-96.5919, 40.7245],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100088',
        NAME: 'Norris School District 160',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554361213,
        AWATER: 4099974,
        INTPTLAT: '+40.5894577',
        INTPTLON: '-096.6371393',
        ELSDLEA: '',
        UNSDLEA: '00088',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0466, 40.6251],
            [-97.0273, 40.6541],
            [-97.0272, 40.6907],
            [-97.0175, 40.6906],
            [-97.0129, 40.6981],
            [-97.0064, 40.7157],
            [-96.9968, 40.7242],
            [-96.9109, 40.7561],
            [-96.9007, 40.7632],
            [-96.8828, 40.7633],
            [-96.8251, 40.734],
            [-96.7587, 40.7248],
            [-96.715, 40.654],
            [-96.7434, 40.6284],
            [-96.7413, 40.6275],
            [-96.7613, 40.5666],
            [-96.7984, 40.5231],
            [-96.865, 40.5305],
            [-96.9966, 40.5869],
            [-96.9964, 40.5962],
            [-97.0031, 40.587],
            [-97.008, 40.587],
            [-97.0084, 40.5962],
            [-97.0087, 40.6032],
            [-97.0185, 40.6106],
            [-97.0226, 40.6106],
            [-97.0185, 40.6254],
            [-97.0272, 40.6106],
            [-97.0186, 40.607],
            [-97.0187, 40.6034],
            [-97.0177, 40.5692],
            [-97.0274, 40.564],
            [-97.0653, 40.5799],
            [-97.0466, 40.6251],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100095',
        NAME: 'Crete Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 484589699,
        AWATER: 5230991,
        INTPTLAT: '+40.6468752',
        INTPTLON: '-096.8859126',
        ELSDLEA: '',
        UNSDLEA: '00095',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5492, 40.748],
              [-96.5399, 40.7406],
              [-96.5399, 40.7398],
              [-96.5399, 40.7388],
              [-96.5459, 40.7372],
              [-96.5492, 40.748],
            ],
          ],
          [
            [
              [-96.5922, 41.0454],
              [-96.5829, 41.0454],
              [-96.5213, 41.0527],
              [-96.5213, 41.0598],
              [-96.5061, 41.0597],
              [-96.5022, 41.0567],
              [-96.483, 41.0452],
              [-96.4731, 41.0451],
              [-96.4266, 40.9509],
              [-96.3496, 40.7764],
              [-96.4817, 40.726],
              [-96.4828, 40.7268],
              [-96.5019, 40.7225],
              [-96.521, 40.7482],
              [-96.521, 40.7516],
              [-96.5303, 40.7551],
              [-96.5492, 40.748],
              [-96.5589, 40.7481],
              [-96.5794, 40.7407],
              [-96.5832, 40.7407],
              [-96.5842, 40.7407],
              [-96.5872, 40.7407],
              [-96.5741, 40.8258],
              [-96.6131, 40.8894],
              [-96.72, 40.8864],
              [-96.72, 40.9154],
              [-96.7196, 40.9876],
              [-96.5922, 41.0454],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100021',
        NAME: 'Waverly School District 145',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 687104657,
        AWATER: 1578617,
        INTPTLAT: '+40.8926083',
        INTPTLON: '-096.5113739',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7552, 41.1185],
              [-96.7552, 41.1224],
              [-96.7461, 41.1224],
              [-96.7214, 41.1038],
              [-96.7295, 41.1038],
              [-96.731, 41.1038],
              [-96.7448, 41.0967],
              [-96.7551, 41.1039],
              [-96.7552, 41.1185],
            ],
          ],
          [
            [
              [-96.7456, 41.1326],
              [-96.7552, 41.1258],
              [-96.7643, 41.1258],
              [-96.7456, 41.1326],
            ],
          ],
          [
            [
              [-96.9396, 41.0169],
              [-96.93, 41.0244],
              [-96.9202, 41.0461],
              [-96.9086, 41.0754],
              [-96.7937, 41.1106],
              [-96.7841, 41.1184],
              [-96.7742, 41.1185],
              [-96.7744, 41.1257],
              [-96.7643, 41.1258],
              [-96.7597, 41.1185],
              [-96.7551, 41.1039],
              [-96.7448, 41.0967],
              [-96.7295, 41.0893],
              [-96.7296, 41.0821],
              [-96.726, 41.0821],
              [-96.7264, 41.0893],
              [-96.7214, 41.1038],
              [-96.5213, 41.065],
              [-96.5213, 41.0598],
              [-96.5213, 41.0527],
              [-96.5829, 41.0454],
              [-96.5922, 41.0454],
              [-96.7196, 40.9876],
              [-96.72, 40.9154],
              [-96.9005, 40.981],
              [-96.9107, 40.9807],
              [-96.9201, 40.9844],
              [-96.9107, 40.988],
              [-96.9213, 41.0062],
              [-96.9298, 41.0061],
              [-96.9396, 41.0169],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104950',
        NAME: 'Raymond Central Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439765600,
        AWATER: 3694903,
        INTPTLAT: '+41.0384150',
        INTPTLON: '-096.7504442',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0234, 40.1763],
              [-100.0129, 40.1762],
              [-100.013, 40.1695],
              [-100.0232, 40.1696],
              [-100.0234, 40.1763],
            ],
          ],
          [
            [
              [-100.0975, 40.0303],
              [-100.0992, 40.0016],
              [-100.1272, 40.0017],
              [-100.1273, 40.0092],
              [-100.1372, 40.009],
              [-100.1372, 40.0016],
              [-100.1459, 40.0016],
              [-100.1263, 40.0264],
              [-100.0975, 40.0303],
            ],
          ],
          [
            [
              [-100.2684, 40.5477],
              [-100.1336, 40.504],
              [-100.1238, 40.504],
              [-100.1069, 40.4967],
              [-100.103, 40.4826],
              [-100.0851, 40.4822],
              [-100.063, 40.2974],
              [-100.0808, 40.1906],
              [-100.109, 40.1474],
              [-100.1185, 40.1633],
              [-100.1178, 40.1184],
              [-100.2495, 40.2515],
              [-100.2704, 40.3784],
              [-100.3208, 40.4936],
              [-100.3156, 40.4965],
              [-100.3208, 40.4965],
              [-100.2684, 40.5477],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100015',
        NAME: 'Cambridge Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649832499,
        AWATER: 8783417,
        INTPTLAT: '+40.3131251',
        INTPTLON: '-100.1664452',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0226, 40.5034],
              [-100.0117, 40.5034],
              [-100.0189, 40.4676],
              [-100.0226, 40.5034],
            ],
          ],
          [
            [
              [-100.0232, 40.1696],
              [-100.013, 40.1695],
              [-100.0229, 40.1622],
              [-100.0232, 40.1696],
            ],
          ],
          [
            [
              [-100.0288, 40.5034],
              [-100.0345, 40.489],
              [-100.0383, 40.489],
              [-100.0288, 40.5034],
            ],
          ],
          [
            [
              [-100.0851, 40.4822],
              [-100.0574, 40.4818],
              [-100.0478, 40.4818],
              [-100.0189, 40.4676],
              [-99.8681, 40.4818],
              [-99.7738, 40.4531],
              [-99.7477, 40.4089],
              [-99.7284, 40.3937],
              [-99.7273, 40.3863],
              [-99.768, 40.3435],
              [-99.7814, 40.3435],
              [-99.7609, 40.3144],
              [-99.7607, 40.3074],
              [-99.7622, 40.2634],
              [-99.9294, 40.1756],
              [-100.0129, 40.1762],
              [-100.0234, 40.1763],
              [-100.0808, 40.1906],
              [-100.063, 40.2974],
              [-100.0851, 40.4822],
            ],
          ],
          [
            [
              [-99.7172, 40.3726],
              [-99.7167, 40.3801],
              [-99.71, 40.38],
              [-99.6995, 40.3729],
              [-99.7176, 40.3654],
              [-99.7172, 40.3726],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103090',
        NAME: 'Arapahoe Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 809739657,
        AWATER: 1062540,
        INTPTLAT: '+40.3271896',
        INTPTLON: '-099.9251419',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0117, 40.5034],
              [-99.9817, 40.5254],
              [-99.9014, 40.7429],
              [-99.897, 40.7429],
              [-99.8919, 40.7428],
              [-99.8826, 40.7427],
              [-99.7375, 40.6559],
              [-99.7573, 40.6469],
              [-99.7571, 40.641],
              [-99.7738, 40.4531],
              [-99.8681, 40.4818],
              [-100.0189, 40.4676],
              [-100.0117, 40.5034],
            ],
          ],
          [
            [
              [-100.0478, 40.4818],
              [-100.0345, 40.489],
              [-100.0288, 40.5034],
              [-100.0226, 40.5034],
              [-100.0189, 40.4676],
              [-100.0478, 40.4818],
            ],
          ],
          [
            [
              [-100.1069, 40.4967],
              [-100.0288, 40.5034],
              [-100.0383, 40.489],
              [-100.0574, 40.4818],
              [-100.0851, 40.4822],
              [-100.103, 40.4826],
              [-100.1069, 40.4967],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170220',
        NAME: 'Elwood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 483940729,
        AWATER: 14083643,
        INTPTLAT: '+40.5667159',
        INTPTLON: '-099.8862773',
        ELSDLEA: '',
        UNSDLEA: '70220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.3208, 40.4965],
              [-100.3156, 40.4965],
              [-100.3208, 40.4936],
              [-100.3208, 40.4965],
            ],
          ],
          [
            [
              [-100.3836, 40.5697],
              [-100.3346, 40.5847],
              [-100.2684, 40.5477],
              [-100.3208, 40.4965],
              [-100.3822, 40.4385],
              [-100.4587, 40.4606],
              [-100.3836, 40.5697],
            ],
          ],
          [
            [
              [-100.8869, 41.0471],
              [-100.8677, 40.973],
              [-100.754, 40.9891],
              [-100.64, 40.9161],
              [-100.5834, 40.8292],
              [-100.5825, 40.5695],
              [-100.574, 40.5484],
              [-100.6109, 40.5116],
              [-100.6012, 40.4747],
              [-100.6109, 40.4671],
              [-100.6866, 40.4517],
              [-100.724, 40.4518],
              [-100.7339, 40.4517],
              [-100.734, 40.4593],
              [-100.7341, 40.4667],
              [-100.7447, 40.517],
              [-100.7828, 40.5255],
              [-100.7984, 40.7001],
              [-100.811, 40.9164],
              [-100.9059, 40.9595],
              [-100.8869, 41.0471],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173440',
        NAME: 'Maywood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057114464,
        AWATER: 771803,
        INTPTLAT: '+40.6914147',
        INTPTLON: '-100.6477894',
        ELSDLEA: '',
        UNSDLEA: '73440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.8436, 40.3357],
              [-100.8, 40.3494],
              [-100.7963, 40.2928],
              [-100.7589, 40.3221],
              [-100.7584, 40.3495],
              [-100.7713, 40.4046],
              [-100.7339, 40.4517],
              [-100.724, 40.4518],
              [-100.6866, 40.4517],
              [-100.6109, 40.4671],
              [-100.6012, 40.4747],
              [-100.6109, 40.5116],
              [-100.5625, 40.4388],
              [-100.5906, 40.3942],
              [-100.6094, 40.3657],
              [-100.598, 40.3221],
              [-100.5732, 40.1916],
              [-100.5343, 40.1541],
              [-100.5337, 40.0019],
              [-100.5993, 40.0019],
              [-100.6089, 40.0019],
              [-100.6271, 40.0021],
              [-100.6459, 40.0019],
              [-100.6651, 40.002],
              [-100.6851, 40.039],
              [-100.6851, 40.0454],
              [-100.7208, 40.0802],
              [-100.74, 40.1231],
              [-100.7598, 40.2717],
              [-100.8147, 40.2717],
              [-100.8436, 40.3357],
            ],
          ],
          [
            [
              [-100.684, 40.0879],
              [-100.621, 40.0896],
              [-100.6571, 40.0748],
              [-100.6656, 40.0786],
              [-100.6839, 40.0879],
              [-100.684, 40.0879],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173470',
        NAME: 'McCook Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 718766693,
        AWATER: 6680817,
        INTPTLAT: '+40.2390731',
        INTPTLON: '-100.6617812',
        ELSDLEA: '',
        UNSDLEA: '73470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.574, 40.5484],
              [-100.5825, 40.5695],
              [-100.5834, 40.8292],
              [-100.47, 40.8424],
              [-100.4319, 40.8293],
              [-100.3423, 40.6707],
              [-100.324, 40.6281],
              [-100.3346, 40.5847],
              [-100.3836, 40.5697],
              [-100.4587, 40.4606],
              [-100.5625, 40.4388],
              [-100.6109, 40.5116],
              [-100.574, 40.5484],
            ],
          ],
          [
            [
              [-100.7713, 40.4046],
              [-100.7584, 40.3495],
              [-100.7774, 40.3495],
              [-100.7713, 40.4046],
            ],
          ],
          [
            [
              [-100.7447, 40.517],
              [-100.7341, 40.4667],
              [-100.7526, 40.4449],
              [-100.7624, 40.4448],
              [-100.772, 40.481],
              [-100.7447, 40.517],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173600',
        NAME: 'Medicine Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 789173024,
        AWATER: 430009,
        INTPTLAT: '+40.6354090',
        INTPTLON: '-100.5128965',
        ELSDLEA: '',
        UNSDLEA: '73600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.593, 40.725],
              [-96.5926, 40.725],
              [-96.5923, 40.725],
              [-96.5919, 40.7245],
              [-96.593, 40.725],
            ],
          ],
          [
            [
              [-96.8828, 40.7633],
              [-96.8728, 40.7632],
              [-96.8728, 40.7633],
              [-96.8635, 40.7704],
              [-96.8635, 40.7849],
              [-96.8539, 40.7848],
              [-96.8633, 40.7919],
              [-96.863, 40.8032],
              [-96.72, 40.8864],
              [-96.6131, 40.8894],
              [-96.5741, 40.8258],
              [-96.5872, 40.7407],
              [-96.5871, 40.7384],
              [-96.5871, 40.7373],
              [-96.5776, 40.7335],
              [-96.5794, 40.7407],
              [-96.5589, 40.7481],
              [-96.5459, 40.7372],
              [-96.5399, 40.7388],
              [-96.5399, 40.7335],
              [-96.5493, 40.7262],
              [-96.5591, 40.7262],
              [-96.5775, 40.7189],
              [-96.5898, 40.7235],
              [-96.5918, 40.7288],
              [-96.5963, 40.7288],
              [-96.5953, 40.7285],
              [-96.6062, 40.7224],
              [-96.6062, 40.7154],
              [-96.6295, 40.7263],
              [-96.6444, 40.7236],
              [-96.6492, 40.7263],
              [-96.6539, 40.7263],
              [-96.7587, 40.7248],
              [-96.8251, 40.734],
              [-96.8828, 40.7633],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172840',
        NAME: 'Lincoln Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351891187,
        AWATER: 4659422,
        INTPTLAT: '+40.7964660',
        INTPTLON: '-096.7078570',
        ELSDLEA: '',
        UNSDLEA: '72840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.187, 40.7963],
              [-97.1779, 40.7964],
              [-97.1779, 40.7926],
              [-97.187, 40.7963],
            ],
          ],
          [
            [
              [-97.1873, 40.7711],
              [-97.1779, 40.7926],
              [-97.1685, 40.7926],
              [-97.1588, 40.7926],
              [-97.1209, 40.8145],
              [-97.0972, 40.8144],
              [-97.0828, 40.8222],
              [-96.9106, 40.8284],
              [-96.863, 40.8032],
              [-96.8633, 40.7919],
              [-96.8635, 40.7849],
              [-96.8635, 40.7704],
              [-96.8728, 40.7633],
              [-96.8728, 40.7632],
              [-96.8828, 40.7633],
              [-96.9007, 40.7632],
              [-96.9109, 40.7561],
              [-96.9968, 40.7242],
              [-97.0064, 40.7157],
              [-97.0129, 40.6981],
              [-97.0175, 40.6906],
              [-97.0272, 40.6907],
              [-97.0461, 40.6942],
              [-97.0647, 40.6982],
              [-97.1868, 40.7276],
              [-97.1969, 40.7349],
              [-97.1873, 40.7711],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173710',
        NAME: 'Milford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303247203,
        AWATER: 2894179,
        INTPTLAT: '+40.7668750',
        INTPTLON: '-097.0323456',
        ELSDLEA: '',
        UNSDLEA: '73710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3592, 40.5552],
              [-96.3551, 40.5517],
              [-96.3592, 40.5519],
              [-96.3592, 40.5552],
            ],
          ],
          [
            [
              [-96.4064, 40.5447],
              [-96.4067, 40.5374],
              [-96.4104, 40.5374],
              [-96.4166, 40.5447],
              [-96.4064, 40.5447],
            ],
          ],
          [
            [
              [-96.4636, 40.5446],
              [-96.4552, 40.5375],
              [-96.4603, 40.5375],
              [-96.4637, 40.5375],
              [-96.4636, 40.5446],
            ],
          ],
          [
            [
              [-96.4448, 40.5665],
              [-96.4067, 40.5292],
              [-96.4067, 40.5229],
              [-96.4258, 40.523],
              [-96.4352, 40.5375],
              [-96.4447, 40.5446],
              [-96.455, 40.552],
              [-96.4448, 40.5665],
            ],
          ],
          [
            [
              [-96.5775, 40.7189],
              [-96.5591, 40.7262],
              [-96.5493, 40.7262],
              [-96.5399, 40.7335],
              [-96.521, 40.7482],
              [-96.5019, 40.7225],
              [-96.4828, 40.7268],
              [-96.4817, 40.726],
              [-96.3496, 40.7764],
              [-96.2932, 40.7479],
              [-96.3413, 40.6383],
              [-96.3384, 40.6316],
              [-96.3313, 40.6244],
              [-96.3242, 40.6204],
              [-96.3241, 40.6241],
              [-96.3123, 40.6243],
              [-96.3123, 40.6242],
              [-96.3123, 40.6098],
              [-96.3217, 40.5864],
              [-96.322, 40.5809],
              [-96.3225, 40.5744],
              [-96.3316, 40.5745],
              [-96.336, 40.5764],
              [-96.3459, 40.5735],
              [-96.3496, 40.5661],
              [-96.3552, 40.56],
              [-96.3592, 40.5575],
              [-96.3687, 40.5519],
              [-96.3735, 40.573],
              [-96.3877, 40.5809],
              [-96.4255, 40.5749],
              [-96.4256, 40.577],
              [-96.456, 40.581],
              [-96.4541, 40.5918],
              [-96.4638, 40.6168],
              [-96.4638, 40.6245],
              [-96.4533, 40.6245],
              [-96.4638, 40.6314],
              [-96.5723, 40.6833],
              [-96.5775, 40.7189],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175210',
        NAME: 'Palmyra District OR-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325447734,
        AWATER: 89992,
        INTPTLAT: '+40.6775346',
        INTPTLON: '-096.4349452',
        ELSDLEA: '',
        UNSDLEA: '75210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.2438, 41.4013],
              [-98.234, 41.4081],
              [-98.2404, 41.394],
              [-98.2438, 41.4013],
            ],
          ],
          [
            [
              [-98.6365, 41.6751],
              [-98.5405, 41.6834],
              [-98.4061, 41.5819],
              [-98.4006, 41.5745],
              [-98.3681, 41.5818],
              [-98.3392, 41.5817],
              [-98.349, 41.5457],
              [-98.3298, 41.5384],
              [-98.3298, 41.5308],
              [-98.2536, 41.4379],
              [-98.234, 41.4081],
              [-98.2438, 41.4013],
              [-98.2866, 41.365],
              [-98.4298, 41.358],
              [-98.5345, 41.3941],
              [-98.5832, 41.394],
              [-98.5783, 41.5166],
              [-98.6358, 41.5257],
              [-98.6365, 41.6751],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100148',
        NAME: 'Greeley-Wolbach Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 749093234,
        AWATER: 43563,
        INTPTLAT: '+41.5076332',
        INTPTLON: '-098.4559085',
        ELSDLEA: '',
        UNSDLEA: '00148',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.6966, 41.6127],
              [-97.658, 41.6128],
              [-97.658, 41.5984],
              [-97.6966, 41.6127],
            ],
          ],
          [
            [
              [-97.7021, 41.6198],
              [-97.6967, 41.6198],
              [-97.6966, 41.6127],
              [-97.702, 41.6127],
              [-97.7021, 41.6198],
            ],
          ],
          [
            [
              [-97.9944, 41.568],
              [-97.9846, 41.568],
              [-97.9943, 41.5609],
              [-97.9944, 41.568],
            ],
          ],
          [
            [
              [-97.9752, 41.5609],
              [-97.9753, 41.5681],
              [-97.9846, 41.568],
              [-97.8603, 41.6557],
              [-97.8308, 41.6947],
              [-97.8027, 41.641],
              [-97.7927, 41.619],
              [-97.7158, 41.6128],
              [-97.7282, 41.5463],
              [-97.9449, 41.4957],
              [-97.9792, 41.5173],
              [-98.0032, 41.5244],
              [-98.0034, 41.5389],
              [-97.9752, 41.5609],
            ],
          ],
          [
            [
              [-98.004, 41.5894],
              [-97.9943, 41.5824],
              [-98.004, 41.5823],
              [-98.004, 41.5894],
            ],
          ],
          [
            [
              [-98.023, 41.575],
              [-98.0135, 41.575],
              [-98.023, 41.5677],
              [-98.023, 41.575],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176980',
        NAME: 'St. Edward Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282814316,
        AWATER: 28891,
        INTPTLAT: '+41.5649208',
        INTPTLON: '-097.8574852',
        ELSDLEA: '',
        UNSDLEA: '76980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.71, 41.2023],
              [-98.7099, 41.2062],
              [-98.7001, 41.1876],
              [-98.71, 41.2023],
            ],
          ],
          [
            [
              [-98.7452, 40.9747],
              [-98.7408, 40.9747],
              [-98.7409, 40.9672],
              [-98.7452, 40.9747],
            ],
          ],
          [
            [
              [-98.8009, 41.0828],
              [-98.7748, 41.0963],
              [-98.7388, 41.1772],
              [-98.7194, 41.1917],
              [-98.6526, 41.1769],
              [-98.4791, 41.1262],
              [-98.4406, 41.1348],
              [-98.4698, 41.0465],
              [-98.541, 40.9676],
              [-98.5462, 40.9605],
              [-98.5505, 40.9605],
              [-98.6651, 40.9602],
              [-98.7219, 40.9168],
              [-98.7218, 40.9457],
              [-98.7218, 40.9671],
              [-98.7309, 40.9748],
              [-98.7452, 40.9747],
              [-98.8009, 41.0828],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3162950',
        NAME: 'Centura Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556032004,
        AWATER: 5872160,
        INTPTLAT: '+41.0517368',
        INTPTLON: '-098.6879103',
        ELSDLEA: '',
        UNSDLEA: '62950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.7309, 40.9748],
              [-98.7408, 40.9747],
              [-98.7452, 40.9747],
              [-98.7309, 40.9748],
            ],
          ],
          [
            [
              [-99.1214, 41.0764],
              [-99.0983, 41.0797],
              [-99.0619, 41.1196],
              [-99.062, 41.1342],
              [-98.8438, 41.1138],
              [-98.8009, 41.0828],
              [-98.7452, 40.9747],
              [-98.7409, 40.9672],
              [-98.7218, 40.9671],
              [-98.7218, 40.9457],
              [-98.7598, 40.9314],
              [-98.7886, 40.9312],
              [-98.8174, 40.9021],
              [-98.8237, 40.8875],
              [-98.8174, 40.8876],
              [-98.8173, 40.8731],
              [-98.8235, 40.8731],
              [-98.9499, 40.8508],
              [-98.9501, 40.869],
              [-98.9692, 40.8658],
              [-98.9786, 40.866],
              [-98.9883, 40.8734],
              [-99.0078, 41.0028],
              [-99.0823, 41.0612],
              [-99.0737, 41.0612],
              [-99.0643, 41.0689],
              [-99.0732, 41.0687],
              [-99.0924, 41.0614],
              [-99.1118, 41.0471],
              [-99.1165, 41.0613],
              [-99.1312, 41.0682],
              [-99.1214, 41.0764],
            ],
            [
              [-99.0619, 41.1196],
              [-99.0523, 41.1123],
              [-99.0521, 41.1197],
              [-99.0619, 41.1196],
            ],
            [
              [-99.0643, 41.0689],
              [-99.0643, 41.075],
              [-99.0643, 41.0693],
              [-99.0643, 41.0689],
            ],
          ],
          [
            [
              [-99.0763, 41.0891],
              [-99.077, 41.0901],
              [-99.0785, 41.0917],
              [-99.0718, 41.1012],
              [-99.0763, 41.0891],
            ],
          ],
          [
            [
              [-99.1405, 41.0829],
              [-99.1404, 41.0684],
              [-99.1448, 41.061],
              [-99.1405, 41.0829],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175960',
        NAME: 'Ravenna Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 622225712,
        AWATER: 1969960,
        INTPTLAT: '+41.0064468',
        INTPTLON: '-098.9345643',
        ELSDLEA: '',
        UNSDLEA: '75960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.7108, 42.4145],
              [-102.6123, 42.3857],
              [-102.5931, 42.3708],
              [-102.6328, 42.3714],
              [-102.7108, 42.4145],
            ],
          ],
          [
            [
              [-102.7886, 42.3131],
              [-102.7689, 42.32],
              [-102.7769, 42.2774],
              [-102.7788, 42.2984],
              [-102.7886, 42.3131],
            ],
          ],
          [
            [
              [-102.79, 42.3429],
              [-102.7689, 42.32],
              [-102.7896, 42.3349],
              [-102.79, 42.3429],
            ],
          ],
          [
            [
              [-102.7734, 42.4399],
              [-102.7494, 42.4399],
              [-102.7496, 42.4296],
              [-102.7734, 42.4399],
            ],
          ],
          [
            [
              [-102.8863, 42.2666],
              [-102.7769, 42.2774],
              [-102.7476, 42.33],
              [-102.6278, 42.3571],
              [-102.5934, 42.3569],
              [-102.5539, 42.3568],
              [-102.5437, 42.3567],
              [-102.5343, 42.3567],
              [-102.5149, 42.3569],
              [-102.5494, 42.2723],
              [-102.5091, 42.226],
              [-102.519, 42.0637],
              [-102.529, 42.0073],
              [-102.5401, 42.0071],
              [-102.5592, 42.007],
              [-102.6184, 42.0063],
              [-102.6385, 42.0059],
              [-102.6776, 42.0053],
              [-102.6696, 41.7584],
              [-102.6941, 41.7143],
              [-102.7148, 41.6992],
              [-102.7326, 41.7147],
              [-102.7224, 41.7866],
              [-102.8587, 41.7868],
              [-102.9064, 41.8627],
              [-102.8966, 41.9563],
              [-102.9364, 42.0165],
              [-103.1874, 41.9832],
              [-103.286, 42.0021],
              [-103.2862, 42.0473],
              [-103.4017, 42.0906],
              [-103.4049, 42.0906],
              [-102.881, 42.1778],
              [-102.8863, 42.2666],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3102910',
        NAME: 'Alliance Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2149383089,
        AWATER: 32021834,
        INTPTLAT: '+42.0780297',
        INTPTLON: '-102.8279130',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.6696, 41.7584],
              [-102.6633, 41.7434],
              [-102.6748, 41.7218],
              [-102.6941, 41.7143],
              [-102.6696, 41.7584],
            ],
          ],
          [
            [
              [-103.195, 41.584],
              [-103.1965, 41.6261],
              [-103.168, 41.8596],
              [-103.2852, 41.9041],
              [-103.1874, 41.9832],
              [-102.9364, 42.0165],
              [-102.8966, 41.9563],
              [-102.9064, 41.8627],
              [-102.8587, 41.7868],
              [-102.7224, 41.7866],
              [-102.7326, 41.7147],
              [-102.7451, 41.6847],
              [-102.7537, 41.6555],
              [-102.792, 41.641],
              [-102.7917, 41.5819],
              [-102.8108, 41.5819],
              [-102.7916, 41.5759],
              [-102.8535, 41.5119],
              [-103.0092, 41.5088],
              [-103.0211, 41.4514],
              [-103.2339, 41.4816],
              [-103.195, 41.584],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100105',
        NAME: 'Bridgeport Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1772859904,
        AWATER: 4435722,
        INTPTLAT: '+41.7322039',
        INTPTLON: '-102.9905936',
        ELSDLEA: '',
        UNSDLEA: '00105',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.3872, 40.2491],
              [-99.3774, 40.2491],
              [-99.3776, 40.2419],
              [-99.3872, 40.2491],
            ],
          ],
          [
            [
              [-99.3776, 40.2419],
              [-99.3683, 40.242],
              [-99.3683, 40.2429],
              [-99.3775, 40.2564],
              [-99.3867, 40.2564],
              [-99.3868, 40.2636],
              [-99.3682, 40.2637],
              [-99.359, 40.2637],
              [-99.3494, 40.2565],
              [-99.3494, 40.2564],
              [-99.3205, 40.3506],
              [-99.3029, 40.3582],
              [-99.3032, 40.3732],
              [-99.3035, 40.387],
              [-99.2853, 40.409],
              [-99.2558, 40.4088],
              [-99.237, 40.4163],
              [-99.237, 40.4231],
              [-99.2369, 40.4308],
              [-99.2368, 40.4376],
              [-99.0655, 40.4086],
              [-98.944, 40.3066],
              [-98.9349, 40.2999],
              [-98.9347, 40.2776],
              [-99.0603, 40.1975],
              [-99.1792, 40.1911],
              [-99.3776, 40.2419],
            ],
            [
              [-99.3304, 40.2526],
              [-99.3109, 40.2526],
              [-99.3109, 40.2565],
              [-99.3305, 40.2565],
              [-99.3304, 40.2526],
            ],
            [
              [-99.2924, 40.2249],
              [-99.2831, 40.2199],
              [-99.2734, 40.2198],
              [-99.2733, 40.2343],
              [-99.2832, 40.2344],
              [-99.2924, 40.2249],
            ],
          ],
          [
            [
              [-99.4052, 40.2491],
              [-99.3965, 40.2491],
              [-99.4052, 40.2344],
              [-99.4052, 40.2491],
            ],
          ],
          [
            [
              [-99.414, 40.26],
              [-99.4136, 40.2636],
              [-99.4053, 40.2637],
              [-99.414, 40.26],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100134',
        NAME: 'Wilcox-Hildreth Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728252298,
        AWATER: 98319,
        INTPTLAT: '+40.3009531',
        INTPTLON: '-099.1501957',
        ELSDLEA: '',
        UNSDLEA: '00134',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.2695, 41.4157],
              [-99.2617, 41.3795],
              [-99.2801, 41.3869],
              [-99.2695, 41.4157],
            ],
          ],
          [
            [
              [-99.2807, 41.3832],
              [-99.2714, 41.3795],
              [-99.281, 41.3797],
              [-99.2807, 41.3832],
            ],
          ],
          [
            [
              [-99.3411, 41.112],
              [-99.3135, 41.1119],
              [-99.2811, 41.0469],
              [-99.3037, 41.047],
              [-99.3121, 41.0469],
              [-99.322, 41.0533],
              [-99.322, 41.0547],
              [-99.3407, 41.0756],
              [-99.3309, 41.0757],
              [-99.3412, 41.1045],
              [-99.3411, 41.112],
            ],
          ],
          [
            [
              [-99.4052, 41.467],
              [-99.386, 41.4674],
              [-99.3003, 41.4234],
              [-99.3002, 41.416],
              [-99.2876, 41.383],
              [-99.3003, 41.3796],
              [-99.281, 41.3797],
              [-99.2714, 41.3795],
              [-99.2617, 41.3795],
              [-99.2518, 41.3653],
              [-99.2326, 41.3653],
              [-99.2041, 41.3219],
              [-99.2041, 41.2717],
              [-99.3003, 41.223],
              [-99.3017, 41.223],
              [-99.291, 41.1766],
              [-99.3405, 41.1584],
              [-99.3595, 41.1533],
              [-99.3543, 41.1476],
              [-99.3546, 41.1439],
              [-99.3494, 41.1437],
              [-99.3403, 41.1476],
              [-99.3135, 41.1119],
              [-99.3411, 41.1188],
              [-99.35, 41.112],
              [-99.3788, 41.1188],
              [-99.3691, 41.0899],
              [-99.3789, 41.0827],
              [-99.3691, 41.0755],
              [-99.3599, 41.0756],
              [-99.3599, 41.073],
              [-99.3791, 41.0611],
              [-99.3982, 41.052],
              [-99.3939, 41.052],
              [-99.385, 41.0467],
              [-99.4081, 41.0524],
              [-99.4354, 41.0717],
              [-99.45, 41.0789],
              [-99.4545, 41.1184],
              [-99.4546, 41.1116],
              [-99.483, 41.1182],
              [-99.5106, 41.1557],
              [-99.4345, 41.307],
              [-99.4052, 41.467],
            ],
            [
              [-99.3003, 41.3796],
              [-99.3081, 41.3795],
              [-99.3081, 41.3653],
              [-99.3004, 41.3653],
              [-99.3003, 41.3796],
            ],
          ],
          [
            [
              [-99.6455, 41.0811],
              [-99.6404, 41.0811],
              [-99.6456, 41.0753],
              [-99.6455, 41.0811],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103060',
        NAME: 'Ansley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 642475119,
        AWATER: 129470,
        INTPTLAT: '+41.2617700',
        INTPTLON: '-099.3763190',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.9692, 40.8224],
              [-98.9692, 40.8188],
              [-98.9693, 40.8153],
              [-98.9789, 40.8153],
              [-98.9791, 40.8186],
              [-98.9791, 40.8224],
              [-98.9692, 40.8224],
            ],
          ],
          [
            [
              [-99.0732, 41.0687],
              [-99.0643, 41.0689],
              [-99.0737, 41.0612],
              [-99.0732, 41.0687],
            ],
          ],
          [
            [
              [-99.1214, 41.0764],
              [-99.1214, 41.0813],
              [-99.1213, 41.0829],
              [-99.0983, 41.0797],
              [-99.1214, 41.0764],
            ],
          ],
          [
            [
              [-99.1412, 40.8294],
              [-99.1411, 40.8221],
              [-99.1507, 40.8221],
              [-99.1412, 40.8294],
            ],
          ],
          [
            [
              [-99.1419, 40.8584],
              [-99.1417, 40.8508],
              [-99.1512, 40.8506],
              [-99.1419, 40.8584],
            ],
          ],
          [
            [
              [-99.2811, 41.0469],
              [-99.1448, 41.061],
              [-99.1404, 41.0684],
              [-99.1312, 41.0682],
              [-99.1165, 41.0613],
              [-99.1118, 41.0471],
              [-99.0924, 41.0614],
              [-99.0823, 41.0612],
              [-99.0078, 41.0028],
              [-98.9883, 40.8734],
              [-98.9883, 40.8658],
              [-98.9883, 40.8512],
              [-98.9883, 40.8298],
              [-98.9932, 40.8298],
              [-99.0077, 40.8226],
              [-99.0844, 40.8299],
              [-99.1283, 40.8441],
              [-99.1283, 40.851],
              [-99.1419, 40.8584],
              [-99.1979, 40.9711],
              [-99.293, 41.0324],
              [-99.3037, 41.047],
              [-99.2811, 41.0469],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175690',
        NAME: 'Pleasanton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396663230,
        AWATER: 137686,
        INTPTLAT: '+40.9513928',
        INTPTLON: '-099.1169756',
        ELSDLEA: '',
        UNSDLEA: '75690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.9883, 40.8658],
              [-98.9883, 40.8734],
              [-98.9786, 40.866],
              [-98.9883, 40.8658],
            ],
          ],
          [
            [
              [-98.9885, 40.8224],
              [-98.9791, 40.8224],
              [-98.9791, 40.8186],
              [-98.9885, 40.8224],
            ],
          ],
          [
            [
              [-98.9932, 40.8298],
              [-98.9883, 40.8298],
              [-98.9885, 40.8224],
              [-98.9932, 40.8298],
            ],
          ],
          [
            [
              [-98.9595, 40.779],
              [-98.9692, 40.779],
              [-98.9789, 40.8153],
              [-98.9693, 40.8153],
              [-98.9692, 40.8188],
              [-98.9692, 40.8224],
              [-98.9883, 40.8298],
              [-98.9883, 40.8512],
              [-98.9692, 40.8658],
              [-98.9501, 40.869],
              [-98.9499, 40.8508],
              [-98.8235, 40.8731],
              [-98.8173, 40.8731],
              [-98.8174, 40.8876],
              [-98.8174, 40.9021],
              [-98.7886, 40.9312],
              [-98.7843, 40.9312],
              [-98.7789, 40.9288],
              [-98.7839, 40.9256],
              [-98.7887, 40.9227],
              [-98.7892, 40.8946],
              [-98.7891, 40.8877],
              [-98.7889, 40.8732],
              [-98.7982, 40.8732],
              [-98.7789, 40.7862],
              [-98.7981, 40.7716],
              [-98.7624, 40.6693],
              [-98.7716, 40.6258],
              [-98.886, 40.6112],
              [-98.9893, 40.6541],
              [-98.9415, 40.6899],
              [-98.9595, 40.779],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170980',
        NAME: 'Gibbon Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404123433,
        AWATER: 5836486,
        INTPTLAT: '+40.7485253',
        INTPTLON: '-098.8626950',
        ELSDLEA: '',
        UNSDLEA: '70980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.0109, 41.4099],
              [-99.0091, 41.4088],
              [-99.0109, 41.4088],
              [-99.0109, 41.4099],
            ],
          ],
          [
            [
              [-99.0112, 41.4013],
              [-99.0015, 41.4088],
              [-98.9819, 41.3943],
              [-99.0112, 41.4013],
            ],
          ],
          [
            [
              [-99.0205, 41.4232],
              [-99.0205, 41.416],
              [-99.0304, 41.4162],
              [-99.0205, 41.4232],
            ],
          ],
          [
            [
              [-99.0519, 41.4088],
              [-99.0498, 41.4088],
              [-99.0398, 41.3943],
              [-99.0594, 41.3943],
              [-99.0519, 41.4088],
            ],
          ],
          [
            [
              [-99.2121, 41.6542],
              [-99.1158, 41.7046],
              [-98.8755, 41.7621],
              [-98.8761, 41.8202],
              [-98.7987, 41.8271],
              [-98.7522, 41.7404],
              [-98.7428, 41.6611],
              [-98.7512, 41.5718],
              [-98.8473, 41.4885],
              [-98.8368, 41.4667],
              [-98.8663, 41.4376],
              [-98.9621, 41.4302],
              [-99.1354, 41.5296],
              [-99.2123, 41.481],
              [-99.2217, 41.4808],
              [-99.2403, 41.481],
              [-99.2463, 41.481],
              [-99.2508, 41.4811],
              [-99.308, 41.5679],
              [-99.2121, 41.5966],
              [-99.2121, 41.6542],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174940',
        NAME: 'Ord Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1169046082,
        AWATER: 5309380,
        INTPTLAT: '+41.6032281',
        INTPTLON: '-098.9728074',
        ELSDLEA: '',
        UNSDLEA: '74940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3504, 40.0955],
              [-96.3504, 40.1028],
              [-96.3315, 40.1423],
              [-96.2329, 40.1314],
              [-96.067, 40.1235],
              [-95.9729, 40.0006],
              [-96.1544, 40.0005],
              [-96.1726, 40.0008],
              [-96.3507, 40.0009],
              [-96.3504, 40.0955],
            ],
          ],
          [
            [
              [-96.3551, 40.1028],
              [-96.3504, 40.1028],
              [-96.3504, 40.0955],
              [-96.3551, 40.1028],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175330',
        NAME: 'Pawnee City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465590839,
        AWATER: 872773,
        INTPTLAT: '+40.0790097',
        INTPTLON: '-096.1891434',
        ELSDLEA: '',
        UNSDLEA: '75330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5213, 41.065],
            [-96.5213, 41.068],
            [-96.5213, 41.0743],
            [-96.5213, 41.0759],
            [-96.5213, 41.0793],
            [-96.5212, 41.0817],
            [-96.4308, 41.1032],
            [-96.4028, 41.1392],
            [-96.4065, 41.1392],
            [-96.4449, 41.1612],
            [-96.3677, 41.1612],
            [-96.3611, 41.1612],
            [-96.3588, 41.1612],
            [-96.3676, 41.182],
            [-96.3619, 41.1853],
            [-96.3247, 41.1859],
            [-96.3398, 41.0825],
            [-96.2932, 41.0253],
            [-96.293, 41.0086],
            [-96.2929, 40.9966],
            [-96.3077, 40.9889],
            [-96.4266, 40.9509],
            [-96.4731, 41.0451],
            [-96.483, 41.0452],
            [-96.5022, 41.0567],
            [-96.5061, 41.0597],
            [-96.5213, 41.0598],
            [-96.5213, 41.065],
          ],
          [
            [-96.3077, 40.9889],
            [-96.3026, 40.9936],
            [-96.3026, 40.9976],
            [-96.3077, 40.9889],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100034',
        NAME: 'Ashland-Greenwood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264398495,
        AWATER: 9494684,
        INTPTLAT: '+41.0515693',
        INTPTLON: '-096.3866958',
        ELSDLEA: '',
        UNSDLEA: '00034',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.2924, 40.2249],
              [-99.2832, 40.2344],
              [-99.2831, 40.2199],
              [-99.2924, 40.2249],
            ],
          ],
          [
            [
              [-99.3867, 40.2564],
              [-99.3775, 40.2564],
              [-99.3774, 40.2491],
              [-99.3872, 40.2491],
              [-99.3867, 40.2564],
            ],
          ],
          [
            [
              [-99.4052, 40.176],
              [-99.4052, 40.1815],
              [-99.4053, 40.2128],
              [-99.4053, 40.2199],
              [-99.4052, 40.2344],
              [-99.3965, 40.2491],
              [-99.3872, 40.2491],
              [-99.3776, 40.2419],
              [-99.1792, 40.1911],
              [-99.1792, 40.1835],
              [-99.1792, 40.1832],
              [-99.1791, 40.002],
              [-99.1978, 40.002],
              [-99.2166, 40.002],
              [-99.2353, 40.002],
              [-99.3851, 40.0021],
              [-99.4141, 40.0019],
              [-99.4052, 40.176],
            ],
          ],
          [
            [
              [-99.6095, 40.0164],
              [-99.6036, 40.0234],
              [-99.6006, 40.0234],
              [-99.5909, 40.0234],
              [-99.5629, 40.0456],
              [-99.5807, 40.0019],
              [-99.6094, 40.0019],
              [-99.6095, 40.0164],
            ],
          ],
          [
            [
              [-99.6197, 40.0233],
              [-99.6097, 40.0233],
              [-99.6095, 40.0164],
              [-99.6197, 40.0233],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100080',
        NAME: 'Alma Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474984642,
        AWATER: 52867581,
        INTPTLAT: '+40.1120347',
        INTPTLON: '-099.3455425',
        ELSDLEA: '',
        UNSDLEA: '00080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.7186, 40.3866],
              [-98.7124, 40.3866],
              [-98.7051, 40.3794],
              [-98.7185, 40.3793],
              [-98.7186, 40.3866],
            ],
          ],
          [
            [
              [-98.7241, 40.4373],
              [-98.7108, 40.4229],
              [-98.724, 40.4228],
              [-98.7241, 40.4373],
            ],
          ],
          [
            [
              [-98.752, 40.4232],
              [-98.7431, 40.4159],
              [-98.7335, 40.423],
              [-98.724, 40.4228],
              [-98.7241, 40.4083],
              [-98.7241, 40.4016],
              [-98.7338, 40.4015],
              [-98.7522, 40.4084],
              [-98.752, 40.4232],
            ],
          ],
          [
            [
              [-99.0087, 40.612],
              [-98.9893, 40.6541],
              [-98.886, 40.6112],
              [-98.7716, 40.6258],
              [-98.7336, 40.5677],
              [-98.743, 40.5387],
              [-98.724, 40.5097],
              [-98.724, 40.5],
              [-98.724, 40.4954],
              [-98.7241, 40.4662],
              [-98.7241, 40.452],
              [-98.7241, 40.4373],
              [-98.739, 40.4373],
              [-98.7809, 40.4372],
              [-98.7808, 40.3503],
              [-98.84, 40.2629],
              [-98.8399, 40.1903],
              [-98.8966, 40.2016],
              [-98.8968, 40.2125],
              [-98.9245, 40.2633],
              [-98.9338, 40.2633],
              [-98.9347, 40.2776],
              [-98.9349, 40.2999],
              [-98.944, 40.3066],
              [-99.0655, 40.4086],
              [-99.0657, 40.5248],
              [-99.0087, 40.612],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173830',
        NAME: 'Minden Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 908354063,
        AWATER: 211662,
        INTPTLAT: '+40.4553612',
        INTPTLON: '-098.8877253',
        ELSDLEA: '',
        UNSDLEA: '73830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.0861, 40.3417],
              [-96.0747, 40.3263],
              [-96.0795, 40.3263],
              [-96.0861, 40.3417],
            ],
          ],
          [
            [
              [-96.1137, 40.2839],
              [-96.1137, 40.2844],
              [-96.1093, 40.2876],
              [-96.1047, 40.2839],
              [-96.0994, 40.2839],
              [-96.1137, 40.2839],
            ],
          ],
          [
            [
              [-96.0148, 40.5086],
              [-96.0099, 40.5086],
              [-95.9441, 40.5448],
              [-95.9239, 40.523],
              [-95.9125, 40.523],
              [-95.9064, 40.523],
              [-95.8578, 40.4146],
              [-95.9052, 40.3568],
              [-95.8791, 40.3193],
              [-95.884, 40.3192],
              [-95.8981, 40.3045],
              [-95.917, 40.2902],
              [-95.9979, 40.2614],
              [-96.0757, 40.2699],
              [-96.0668, 40.2699],
              [-96.0573, 40.2693],
              [-96.0668, 40.2765],
              [-96.067, 40.2978],
              [-96.0575, 40.2978],
              [-96.0576, 40.3054],
              [-96.067, 40.3054],
              [-96.0954, 40.3128],
              [-96.0793, 40.3201],
              [-96.0743, 40.3263],
              [-96.0861, 40.3417],
              [-96.0938, 40.3565],
              [-96.104, 40.3635],
              [-96.104, 40.3669],
              [-96.067, 40.5231],
              [-96.0148, 40.5086],
            ],
          ],
          [
            [
              [-96.3022, 40.2552],
              [-96.2926, 40.2529],
              [-96.3023, 40.2477],
              [-96.3022, 40.2552],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172335',
        NAME: 'Johnson-Brock Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470304374,
        AWATER: 370379,
        INTPTLAT: '+40.4000175',
        INTPTLON: '-095.9997741',
        ELSDLEA: '',
        UNSDLEA: '72335',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.5649, 40.2329],
              [-95.5597, 40.2329],
              [-95.5595, 40.2256],
              [-95.5649, 40.2256],
              [-95.5649, 40.2329],
            ],
          ],
          [
            [
              [-95.5888, 40.2477],
              [-95.5935, 40.3059],
              [-95.5548, 40.2645],
              [-95.3965, 40.1247],
              [-95.42, 40.0504],
              [-95.3083, 40],
              [-95.3686, 40],
              [-95.673, 40.0003],
              [-95.8221, 40.0005],
              [-95.7758, 40.154],
              [-95.6446, 40.1647],
              [-95.5888, 40.2477],
            ],
            [
              [-95.5786, 40.2475],
              [-95.5649, 40.2329],
              [-95.5698, 40.2257],
              [-95.5649, 40.2256],
              [-95.5595, 40.2256],
              [-95.5597, 40.2329],
              [-95.5786, 40.2475],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170530',
        NAME: 'Falls City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774949116,
        AWATER: 7281816,
        INTPTLAT: '+40.1087992',
        INTPTLON: '-095.5854519',
        ELSDLEA: '',
        UNSDLEA: '70530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.2954, 42.0807],
              [-98.2954, 42.0744],
              [-98.3055, 42.0743],
              [-98.2954, 42.0807],
            ],
          ],
          [
            [
              [-98.3005, 42.1324],
              [-98.3005, 42.1179],
              [-98.3103, 42.1178],
              [-98.3005, 42.1324],
            ],
          ],
          [
            [
              [-98.3249, 42.4458],
              [-98.3151, 42.4458],
              [-98.3055, 42.4458],
              [-98.3101, 42.4369],
              [-98.325, 42.4368],
              [-98.3249, 42.4458],
            ],
          ],
          [
            [
              [-98.3344, 42.4586],
              [-98.3249, 42.4586],
              [-98.3249, 42.4514],
              [-98.3344, 42.4514],
              [-98.3344, 42.4586],
            ],
          ],
          [
            [
              [-98.3394, 42.4298],
              [-98.3395, 42.4301],
              [-98.3297, 42.4302],
              [-98.32, 42.4225],
              [-98.3395, 42.4225],
              [-98.3394, 42.4298],
            ],
          ],
          [
            [
              [-98.3788, 42.3935],
              [-98.3709, 42.3935],
              [-98.3695, 42.3858],
              [-98.3695, 42.3791],
              [-98.3788, 42.3935],
            ],
          ],
          [
            [
              [-98.384, 42.3645],
              [-98.3693, 42.3791],
              [-98.3396, 42.3283],
              [-98.384, 42.3645],
            ],
          ],
          [
            [
              [-98.414, 42.4017],
              [-98.413, 42.4007],
              [-98.4139, 42.4008],
              [-98.414, 42.4017],
            ],
          ],
          [
            [
              [-98.4143, 42.4022],
              [-98.414, 42.4017],
              [-98.4143, 42.4017],
              [-98.4143, 42.4022],
            ],
          ],
          [
            [
              [-98.4173, 42.379],
              [-98.4117, 42.379],
              [-98.4085, 42.3724],
              [-98.4173, 42.3723],
              [-98.4173, 42.379],
            ],
          ],
          [
            [
              [-98.4955, 42.2992],
              [-98.5152, 42.2992],
              [-98.5248, 42.2992],
              [-98.4955, 42.2992],
            ],
          ],
          [
            [
              [-98.5286, 42.3796],
              [-98.5286, 42.3803],
              [-98.5278, 42.3792],
              [-98.5286, 42.3792],
              [-98.5286, 42.3796],
            ],
          ],
          [
            [
              [-98.5321, 42.3804],
              [-98.5314, 42.3808],
              [-98.5314, 42.3807],
              [-98.5315, 42.3803],
              [-98.5321, 42.3803],
              [-98.5321, 42.3804],
            ],
          ],
          [
            [
              [-98.5341, 42.3801],
              [-98.5329, 42.38],
              [-98.5329, 42.3792],
              [-98.5341, 42.3792],
              [-98.5341, 42.3801],
            ],
          ],
          [
            [
              [-98.5353, 42.1102],
              [-98.5151, 42.2049],
              [-98.5151, 42.2629],
              [-98.4954, 42.2634],
              [-98.4956, 42.2708],
              [-98.476, 42.2776],
              [-98.4854, 42.3064],
              [-98.4561, 42.3391],
              [-98.4562, 42.3416],
              [-98.4368, 42.3649],
              [-98.4269, 42.3645],
              [-98.4172, 42.3645],
              [-98.3396, 42.3283],
              [-98.2329, 42.2556],
              [-98.3395, 42.1179],
              [-98.32, 42.1178],
              [-98.3103, 42.1178],
              [-98.3006, 42.0889],
              [-98.2988, 42.0889],
              [-98.3152, 42.0806],
              [-98.3055, 42.0666],
              [-98.299, 42.0596],
              [-98.2955, 42.057],
              [-98.2955, 42.0489],
              [-98.2955, 42.0452],
              [-98.2955, 42.031],
              [-98.3154, 42.0308],
              [-98.3735, 42.0164],
              [-98.3923, 42.0164],
              [-98.3924, 42.0317],
              [-98.3927, 42.075],
              [-98.4315, 42.0742],
              [-98.5353, 42.1102],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100020',
        NAME: 'Ewing Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571402381,
        AWATER: 1182021,
        INTPTLAT: '+42.2108577',
        INTPTLON: '-098.3703268',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.0119, 40.9958],
              [-96.0091, 40.9958],
              [-96.0065, 40.9875],
              [-96.0119, 40.9958],
            ],
          ],
          [
            [
              [-96.293, 41.0086],
              [-96.2736, 41.0199],
              [-96.2345, 41.0043],
              [-96.068, 41.0606],
              [-96.0447, 41.002],
              [-96.0186, 40.9584],
              [-96.0257, 40.9439],
              [-96.0357, 40.9514],
              [-96.064, 40.9508],
              [-96.1872, 40.9364],
              [-96.2929, 40.9966],
              [-96.293, 41.0086],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173050',
        NAME: 'Louisville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170322767,
        AWATER: 8039563,
        INTPTLAT: '+40.9953591',
        INTPTLON: '-096.1309609',
        ELSDLEA: '',
        UNSDLEA: '73050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.5899, 42.668],
              [-98.5795, 42.668],
              [-98.5794, 42.6614],
              [-98.5899, 42.668],
            ],
          ],
          [
            [
              [-98.745, 42.7542],
              [-98.741, 42.7542],
              [-98.7363, 42.7544],
              [-98.7454, 42.7253],
              [-98.7556, 42.7253],
              [-98.745, 42.7542],
            ],
          ],
          [
            [
              [-99.2545, 42.9981],
              [-99.1583, 42.9984],
              [-98.6455, 42.9985],
              [-98.4985, 42.9986],
              [-98.4687, 42.9494],
              [-98.555, 42.9372],
              [-98.5947, 42.8257],
              [-98.51, 42.756],
              [-98.5344, 42.7117],
              [-98.5404, 42.7412],
              [-98.6184, 42.7262],
              [-98.6183, 42.7291],
              [-98.6382, 42.7193],
              [-98.7363, 42.7544],
              [-98.7363, 42.7618],
              [-98.725, 42.7689],
              [-98.76, 42.8264],
              [-98.8819, 42.8414],
              [-98.9546, 42.8941],
              [-99.2077, 42.8231],
              [-99.254, 42.9049],
              [-99.2545, 42.9981],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100180',
        NAME: 'West Boyd Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1138579862,
        AWATER: 4961951,
        INTPTLAT: '+42.8937096',
        INTPTLON: '-098.8307302',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.5152, 42.2992],
              [-98.4955, 42.2992],
              [-98.5055, 42.292],
              [-98.5152, 42.2921],
              [-98.5152, 42.2992],
            ],
          ],
          [
            [
              [-98.76, 42.8264],
              [-98.725, 42.7689],
              [-98.7363, 42.7618],
              [-98.7363, 42.7544],
              [-98.741, 42.7542],
              [-98.745, 42.7618],
              [-98.76, 42.8264],
            ],
          ],
          [
            [
              [-98.745, 42.7618],
              [-98.745, 42.7542],
              [-98.7556, 42.7253],
              [-98.745, 42.7618],
            ],
          ],
          [
            [
              [-98.7454, 42.7253],
              [-98.7363, 42.7544],
              [-98.6382, 42.7193],
              [-98.6289, 42.697],
              [-98.6197, 42.697],
              [-98.5991, 42.6915],
              [-98.5344, 42.7117],
              [-98.51, 42.756],
              [-98.4818, 42.7705],
              [-98.4819, 42.7561],
              [-98.343, 42.7329],
              [-98.3245, 42.7329],
              [-98.3052, 42.7335],
              [-98.3053, 42.7121],
              [-98.3247, 42.6977],
              [-98.3188, 42.6975],
              [-98.3054, 42.6905],
              [-98.3054, 42.5169],
              [-98.3344, 42.4586],
              [-98.364, 42.4514],
              [-98.3395, 42.4301],
              [-98.3394, 42.4298],
              [-98.349, 42.4295],
              [-98.3878, 42.4226],
              [-98.3979, 42.4226],
              [-98.3787, 42.4081],
              [-98.3788, 42.3935],
              [-98.3695, 42.3791],
              [-98.3693, 42.3791],
              [-98.384, 42.3645],
              [-98.4085, 42.3724],
              [-98.4117, 42.379],
              [-98.4173, 42.3967],
              [-98.4173, 42.3964],
              [-98.4563, 42.3937],
              [-98.4368, 42.3649],
              [-98.4562, 42.3416],
              [-98.4561, 42.3391],
              [-98.4854, 42.3064],
              [-98.5152, 42.3142],
              [-98.5178, 42.3141],
              [-98.5344, 42.2992],
              [-98.5152, 42.2921],
              [-98.5732, 42.2629],
              [-98.573, 42.3203],
              [-98.7687, 42.3215],
              [-98.8668, 42.3354],
              [-98.8342, 42.5227],
              [-98.7454, 42.7253],
            ],
            [
              [-98.5329, 42.3792],
              [-98.5329, 42.38],
              [-98.5321, 42.3803],
              [-98.5315, 42.3803],
              [-98.5314, 42.3807],
              [-98.5314, 42.3815],
              [-98.5314, 42.3814],
              [-98.5314, 42.3808],
              [-98.5321, 42.3804],
              [-98.5341, 42.3801],
              [-98.5341, 42.3792],
              [-98.5329, 42.3792],
            ],
            [
              [-98.4197, 42.4013],
              [-98.4185, 42.4013],
              [-98.4182, 42.4013],
              [-98.4181, 42.4017],
              [-98.4197, 42.4017],
              [-98.4197, 42.4013],
            ],
            [
              [-98.5899, 42.668],
              [-98.5794, 42.6614],
              [-98.5795, 42.668],
              [-98.5899, 42.668],
            ],
            [
              [-98.5315, 42.3803],
              [-98.5286, 42.3796],
              [-98.5286, 42.3792],
              [-98.5278, 42.3792],
              [-98.5286, 42.3803],
              [-98.5315, 42.3803],
            ],
            [
              [-98.4128, 42.4007],
              [-98.413, 42.4007],
              [-98.414, 42.4017],
              [-98.4143, 42.4022],
              [-98.415, 42.4017],
              [-98.4156, 42.4017],
              [-98.4156, 42.4013],
              [-98.4151, 42.4],
              [-98.4128, 42.4007],
            ],
          ],
          [
            [
              [-98.3055, 42.4458],
              [-98.3151, 42.4458],
              [-98.3249, 42.4514],
              [-98.3249, 42.4586],
              [-98.3055, 42.4458],
            ],
          ],
          [
            [
              [-98.325, 42.4368],
              [-98.3101, 42.4369],
              [-98.3285, 42.4009],
              [-98.32, 42.4225],
              [-98.3297, 42.4302],
              [-98.325, 42.4368],
            ],
          ],
          [
            [
              [-98.3285, 42.4009],
              [-98.3287, 42.3935],
              [-98.3395, 42.3935],
              [-98.3285, 42.4009],
            ],
          ],
          [
            [
              [-98.3344, 42.4514],
              [-98.3249, 42.4514],
              [-98.3249, 42.4458],
              [-98.3344, 42.4514],
            ],
          ],
          [
            [
              [-98.3591, 42.3995],
              [-98.3497, 42.3935],
              [-98.3695, 42.3858],
              [-98.3709, 42.3935],
              [-98.3591, 42.3995],
            ],
          ],
          [
            [
              [-98.3787, 42.4152],
              [-98.349, 42.4295],
              [-98.3395, 42.4225],
              [-98.3787, 42.4081],
              [-98.3787, 42.4152],
            ],
          ],
          [
            [
              [-98.415, 42.4017],
              [-98.4143, 42.4017],
              [-98.414, 42.4017],
              [-98.4139, 42.4008],
              [-98.415, 42.4017],
            ],
          ],
          [
            [
              [-98.4173, 42.379],
              [-98.4173, 42.3723],
              [-98.4172, 42.3645],
              [-98.4269, 42.3645],
              [-98.4173, 42.379],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174850',
        NAME: "O'Neill Public Schools",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1690954784,
        AWATER: 884227,
        INTPTLAT: '+42.5341653',
        INTPTLON: '-098.5750577',
        ELSDLEA: '',
        UNSDLEA: '74850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.0069, 40.7295],
              [-96.0069, 40.7331],
              [-96.0069, 40.748],
              [-96.0026, 40.7551],
              [-95.9949, 40.7628],
              [-95.9781, 40.7697],
              [-95.8731, 40.8327],
              [-95.8481, 40.8573],
              [-95.8449, 40.8146],
              [-95.8451, 40.8091],
              [-95.8341, 40.7838],
              [-95.8374, 40.6742],
              [-95.7656, 40.5852],
              [-95.71, 40.5238],
              [-95.8781, 40.523],
              [-95.8834, 40.523],
              [-95.9064, 40.523],
              [-95.9125, 40.523],
              [-95.9239, 40.523],
              [-95.9441, 40.5448],
              [-95.9915, 40.6101],
              [-95.9916, 40.6172],
              [-96.0106, 40.6246],
              [-96.0069, 40.7295],
            ],
          ],
          [
            [
              [-96.0112, 40.7627],
              [-96.007, 40.7627],
              [-96.0069, 40.748],
              [-96.0112, 40.7627],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174160',
        NAME: 'Nebraska City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 471122425,
        AWATER: 7008710,
        INTPTLAT: '+40.6576369',
        INTPTLON: '-095.8927300',
        ELSDLEA: '',
        UNSDLEA: '74160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.0314, 40.9269],
              [-96.0258, 40.9293],
              [-96.0314, 40.9221],
              [-96.0314, 40.9269],
            ],
          ],
          [
            [
              [-96.0258, 40.9293],
              [-96.0258, 40.9362],
              [-96.0257, 40.9439],
              [-96.0186, 40.9584],
              [-95.833, 40.9587],
              [-95.8394, 40.9441],
              [-95.8481, 40.8573],
              [-95.8731, 40.8327],
              [-95.9781, 40.7697],
              [-95.9949, 40.7628],
              [-96.0026, 40.7551],
              [-96.007, 40.7627],
              [-96.0112, 40.7627],
              [-96.0592, 40.7841],
              [-96.0546, 40.8131],
              [-96.0258, 40.9293],
            ],
          ],
          [
            [
              [-96.0929, 40.8203],
              [-96.0833, 40.8203],
              [-96.0928, 40.8131],
              [-96.0929, 40.8203],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174200',
        NAME: 'Conestoga Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314620164,
        AWATER: 3013988,
        INTPTLAT: '+40.8797629',
        INTPTLON: '-095.9419811',
        ELSDLEA: '',
        UNSDLEA: '74200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.007, 40.7627],
              [-96.0026, 40.7551],
              [-96.0069, 40.748],
              [-96.007, 40.7627],
            ],
          ],
          [
            [
              [-96.0106, 40.6246],
              [-95.9916, 40.6172],
              [-96.0005, 40.6173],
              [-96.0106, 40.6213],
              [-96.0106, 40.6246],
            ],
          ],
          [
            [
              [-96.2365, 40.8204],
              [-96.2168, 40.8129],
              [-96.2264, 40.8128],
              [-96.2365, 40.8127],
              [-96.2365, 40.8204],
            ],
          ],
          [
            [
              [-96.2986, 40.5365],
              [-96.298, 40.5369],
              [-96.2986, 40.536],
              [-96.2986, 40.5365],
            ],
          ],
          [
            [
              [-96.2994, 40.5355],
              [-96.2989, 40.5355],
              [-96.2987, 40.5355],
              [-96.2987, 40.5351],
              [-96.2994, 40.5352],
              [-96.2994, 40.5355],
            ],
          ],
          [
            [
              [-96.3015, 40.5369],
              [-96.3016, 40.5365],
              [-96.3018, 40.5365],
              [-96.3015, 40.5369],
            ],
          ],
          [
            [
              [-96.3497, 40.5513],
              [-96.3316, 40.5745],
              [-96.3225, 40.5744],
              [-96.322, 40.5809],
              [-96.3217, 40.5864],
              [-96.3123, 40.6098],
              [-96.3123, 40.6242],
              [-96.3123, 40.6243],
              [-96.3241, 40.6241],
              [-96.3242, 40.6204],
              [-96.3313, 40.6244],
              [-96.3384, 40.6316],
              [-96.3413, 40.6383],
              [-96.2932, 40.7479],
              [-96.2263, 40.8059],
              [-96.2167, 40.806],
              [-96.2168, 40.8129],
              [-96.2064, 40.813],
              [-96.1499, 40.8131],
              [-96.1441, 40.8067],
              [-96.0929, 40.8203],
              [-96.0928, 40.8131],
              [-96.0592, 40.7841],
              [-96.0112, 40.7627],
              [-96.0069, 40.748],
              [-96.0069, 40.7331],
              [-96.0069, 40.7295],
              [-96.0106, 40.6246],
              [-96.0761, 40.5667],
              [-96.2746, 40.5664],
              [-96.264, 40.5374],
              [-96.2741, 40.5229],
              [-96.2739, 40.516],
              [-96.2837, 40.5159],
              [-96.2934, 40.5159],
              [-96.2934, 40.5229],
              [-96.2971, 40.5347],
              [-96.2957, 40.5352],
              [-96.2957, 40.5369],
              [-96.3015, 40.5374],
              [-96.305, 40.5374],
              [-96.3127, 40.5374],
              [-96.3221, 40.5374],
              [-96.3497, 40.5513],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177550',
        NAME: 'Syracuse-Dunbar-Avoca Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 654209975,
        AWATER: 2620521,
        INTPTLAT: '+40.6784548',
        INTPTLON: '-096.1742861',
        ELSDLEA: '',
        UNSDLEA: '77550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.2264, 40.8128],
              [-96.2168, 40.8129],
              [-96.2167, 40.806],
              [-96.2263, 40.8059],
              [-96.2264, 40.8128],
            ],
          ],
          [
            [
              [-96.1872, 40.9364],
              [-96.064, 40.9508],
              [-96.0548, 40.9439],
              [-96.0449, 40.9362],
              [-96.0258, 40.9362],
              [-96.0258, 40.9293],
              [-96.0546, 40.8131],
              [-96.0592, 40.7841],
              [-96.0928, 40.8131],
              [-96.0833, 40.8203],
              [-96.0929, 40.8203],
              [-96.1441, 40.8067],
              [-96.1499, 40.8131],
              [-96.2064, 40.813],
              [-96.1977, 40.8367],
              [-96.2365, 40.8632],
              [-96.1872, 40.9364],
            ],
            [
              [-96.0258, 40.9293],
              [-96.0314, 40.9269],
              [-96.0314, 40.9221],
              [-96.0258, 40.9293],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178540',
        NAME: 'Weeping Water Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219259331,
        AWATER: 186983,
        INTPTLAT: '+40.8816178',
        INTPTLON: '-096.1315932',
        ELSDLEA: '',
        UNSDLEA: '78540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8142, 41.7138],
            [-96.8142, 41.7182],
            [-96.8025, 41.7426],
            [-96.7969, 41.7426],
            [-96.7907, 41.7426],
            [-96.7809, 41.7425],
            [-96.7681, 41.7425],
            [-96.5903, 41.7542],
            [-96.5782, 41.6723],
            [-96.6456, 41.5608],
            [-96.7324, 41.6116],
            [-96.7324, 41.6187],
            [-96.7613, 41.6263],
            [-96.8096, 41.6263],
            [-96.8429, 41.6845],
            [-96.8429, 41.6915],
            [-96.8142, 41.7138],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100076',
        NAME: 'Scribner-Snyder Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299302626,
        AWATER: 3538666,
        INTPTLAT: '+41.6770014',
        INTPTLON: '-096.7035970',
        ELSDLEA: '',
        UNSDLEA: '00076',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.306, 40.4154],
              [-98.2874, 40.4155],
              [-98.2874, 40.4085],
              [-98.2874, 40.4012],
              [-98.2874, 40.3938],
              [-98.2971, 40.4008],
              [-98.306, 40.4082],
              [-98.306, 40.4154],
            ],
          ],
          [
            [
              [-98.5054, 40.365],
              [-98.4685, 40.4374],
              [-98.4018, 40.4013],
              [-98.3539, 40.4344],
              [-98.3495, 40.4373],
              [-98.3349, 40.4301],
              [-98.3349, 40.4229],
              [-98.3158, 40.3649],
              [-98.3062, 40.3612],
              [-98.3062, 40.3566],
              [-98.3112, 40.3503],
              [-98.3397, 40.2198],
              [-98.3965, 40.1764],
              [-98.5007, 40.2194],
              [-98.5199, 40.2701],
              [-98.5199, 40.2773],
              [-98.5054, 40.365],
            ],
          ],
          [
            [
              [-98.5294, 40.2774],
              [-98.5199, 40.2773],
              [-98.5199, 40.2701],
              [-98.5294, 40.2774],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104200',
        NAME: 'Blue Hill Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 386690943,
        AWATER: 111678,
        INTPTLAT: '+40.3099931',
        INTPTLON: '-098.4221536',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2033, 40.539],
            [-97.9953, 40.5827],
            [-97.9003, 40.4912],
            [-97.9381, 40.4375],
            [-98.0803, 40.4667],
            [-98.2033, 40.539],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105220',
        NAME: 'Clay Center Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233138640,
        AWATER: 198520,
        INTPTLAT: '+40.5183283',
        INTPTLON: '-098.0406189',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2223, 40.6117],
            [-98.165, 40.6984],
            [-98.054, 40.7274],
            [-97.9768, 40.6984],
            [-97.9953, 40.5827],
            [-98.2033, 40.539],
            [-98.2412, 40.539],
            [-98.2223, 40.6117],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171550',
        NAME: 'Harvard Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287597408,
        AWATER: 586956,
        INTPTLAT: '+40.6295208',
        INTPTLON: '-098.0979744',
        ELSDLEA: '',
        UNSDLEA: '71550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7447, 40.7118],
              [-97.7307, 40.7131],
              [-97.7307, 40.7203],
              [-97.7209, 40.6986],
              [-97.7292, 40.6986],
              [-97.7447, 40.7118],
            ],
          ],
          [
            [
              [-97.9502, 40.7784],
              [-97.9501, 40.7854],
              [-97.9401, 40.7854],
              [-97.8258, 40.829],
              [-97.8262, 40.9884],
              [-97.7412, 40.9885],
              [-97.7313, 40.9885],
              [-97.7219, 40.974],
              [-97.6549, 40.9448],
              [-97.7119, 40.9087],
              [-97.7119, 40.9015],
              [-97.7118, 40.7856],
              [-97.7024, 40.7711],
              [-97.7307, 40.7203],
              [-97.7447, 40.7132],
              [-97.7497, 40.7111],
              [-97.759, 40.7025],
              [-97.8829, 40.7274],
              [-97.8945, 40.7348],
              [-97.898, 40.713],
              [-97.921, 40.713],
              [-97.9502, 40.7784],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100117',
        NAME: 'Heartland Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399982099,
        AWATER: 2227903,
        INTPTLAT: '+40.8354169',
        INTPTLON: '-097.7969430',
        ELSDLEA: '',
        UNSDLEA: '00117',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9768, 40.6984],
            [-97.9591, 40.7058],
            [-97.9499, 40.7059],
            [-97.921, 40.713],
            [-97.898, 40.713],
            [-97.8945, 40.7348],
            [-97.8829, 40.7274],
            [-97.759, 40.7025],
            [-97.7497, 40.7111],
            [-97.7447, 40.7132],
            [-97.7447, 40.7118],
            [-97.7292, 40.6986],
            [-97.7623, 40.5606],
            [-97.7859, 40.5245],
            [-97.8243, 40.4521],
            [-97.9003, 40.423],
            [-97.9191, 40.4259],
            [-97.9381, 40.4375],
            [-97.9003, 40.4912],
            [-97.9953, 40.5827],
            [-97.9768, 40.6984],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177520',
        NAME: 'Sutton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501916810,
        AWATER: 1857318,
        INTPTLAT: '+40.6008045',
        INTPTLON: '-097.8605455',
        ELSDLEA: '',
        UNSDLEA: '77520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1685, 40.7926],
              [-97.1779, 40.7926],
              [-97.1779, 40.7964],
              [-97.1685, 40.7926],
            ],
          ],
          [
            [
              [-97.2346, 41.1114],
              [-97.2298, 41.1046],
              [-97.2345, 41.1046],
              [-97.2346, 41.1114],
            ],
          ],
          [
            [
              [-97.2344, 41.0977],
              [-97.2344, 41.0973],
              [-97.2343, 41.0902],
              [-97.2438, 41.0903],
              [-97.2344, 41.0977],
            ],
          ],
          [
            [
              [-97.2586, 41.1117],
              [-97.2537, 41.1116],
              [-97.2537, 41.1051],
              [-97.2635, 41.1049],
              [-97.2586, 41.1117],
            ],
          ],
          [
            [
              [-97.2825, 41.1344],
              [-97.273, 41.1343],
              [-97.2729, 41.1272],
              [-97.2827, 41.1274],
              [-97.2825, 41.1344],
            ],
          ],
          [
            [
              [-97.3112, 41.1272],
              [-97.3111, 41.1236],
              [-97.311, 41.1194],
              [-97.3206, 41.1194],
              [-97.3112, 41.1272],
            ],
          ],
          [
            [
              [-97.3299, 41.0834],
              [-97.301, 41.0689],
              [-97.3206, 41.0693],
              [-97.3299, 41.0759],
              [-97.3299, 41.0834],
            ],
          ],
          [
            [
              [-97.3496, 41.127],
              [-97.3495, 41.1194],
              [-97.3541, 41.1194],
              [-97.3496, 41.127],
            ],
          ],
          [
            [
              [-97.3541, 41.1169],
              [-97.3495, 41.1194],
              [-97.3303, 41.1157],
              [-97.3495, 41.1088],
              [-97.3543, 41.1088],
              [-97.3541, 41.1169],
            ],
          ],
          [
            [
              [-97.3492, 40.7277],
              [-97.3396, 40.7277],
              [-97.3492, 40.7194],
              [-97.3492, 40.7192],
              [-97.3491, 40.7191],
              [-97.3505, 40.7186],
              [-97.3512, 40.7186],
              [-97.3519, 40.7186],
              [-97.3533, 40.7187],
              [-97.3586, 40.7131],
              [-97.355, 40.7145],
              [-97.3546, 40.7145],
              [-97.3587, 40.7056],
              [-97.3684, 40.7276],
              [-97.3492, 40.7277],
            ],
          ],
          [
            [
              [-97.4069, 41.1117],
              [-97.402, 41.1086],
              [-97.4069, 41.1086],
              [-97.4069, 41.1117],
            ],
          ],
          [
            [
              [-97.4358, 41.0832],
              [-97.4257, 41.0832],
              [-97.4162, 41.0905],
              [-97.4068, 41.1049],
              [-97.402, 41.1086],
              [-97.3874, 41.105],
              [-97.3537, 41.0977],
              [-97.3395, 41.0977],
              [-97.3369, 41.0904],
              [-97.3365, 41.0834],
              [-97.3299, 41.0759],
              [-97.3299, 41.0614],
              [-97.3204, 41.0614],
              [-97.3107, 41.0613],
              [-97.282, 41.0687],
              [-97.2773, 41.0687],
              [-97.2772, 41.0612],
              [-97.2723, 41.0534],
              [-97.2723, 41.0467],
              [-97.273, 41.0467],
              [-97.2921, 41.0468],
              [-97.3018, 41.0423],
              [-97.2728, 40.9961],
              [-97.2728, 40.9745],
              [-97.2346, 40.9091],
              [-97.2393, 40.9092],
              [-97.2045, 40.8147],
              [-97.2009, 40.8147],
              [-97.187, 40.7963],
              [-97.1779, 40.7926],
              [-97.1873, 40.7711],
              [-97.1969, 40.7349],
              [-97.33, 40.7276],
              [-97.4683, 40.7779],
              [-97.4926, 40.7851],
              [-97.4903, 40.9011],
              [-97.569, 40.9592],
              [-97.5113, 41.0468],
              [-97.4358, 41.0832],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100099',
        NAME: 'Centennial Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 835047760,
        AWATER: 4222874,
        INTPTLAT: '+40.9055899',
        INTPTLON: '-097.3586249',
        ELSDLEA: '',
        UNSDLEA: '00099',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2501, 40.5677],
              [-97.2448, 40.5677],
              [-97.236, 40.5599],
              [-97.236, 40.5532],
              [-97.236, 40.5414],
              [-97.2465, 40.5416],
              [-97.2547, 40.5439],
              [-97.2547, 40.5242],
              [-97.2643, 40.5605],
              [-97.2501, 40.5677],
            ],
          ],
          [
            [
              [-97.3519, 40.718],
              [-97.3519, 40.7186],
              [-97.3512, 40.7186],
              [-97.3519, 40.7177],
              [-97.3519, 40.718],
            ],
          ],
          [
            [
              [-97.3533, 40.7187],
              [-97.355, 40.7145],
              [-97.3586, 40.7131],
              [-97.3533, 40.7187],
            ],
          ],
          [
            [
              [-97.5308, 40.6986],
              [-97.4683, 40.7779],
              [-97.33, 40.7276],
              [-97.3396, 40.7277],
              [-97.3492, 40.7277],
              [-97.3684, 40.7276],
              [-97.3587, 40.7056],
              [-97.3684, 40.5535],
              [-97.2927, 40.5242],
              [-97.2546, 40.5164],
              [-97.2264, 40.4588],
              [-97.2361, 40.4589],
              [-97.3687, 40.4374],
              [-97.3878, 40.4301],
              [-97.4066, 40.4374],
              [-97.4348, 40.4593],
              [-97.4306, 40.4663],
              [-97.4723, 40.4739],
              [-97.5017, 40.4812],
              [-97.5308, 40.6986],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100133',
        NAME: 'Exeter-Milligan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500595418,
        AWATER: 940437,
        INTPTLAT: '+40.5933281',
        INTPTLON: '-097.3981212',
        ELSDLEA: '',
        UNSDLEA: '00133',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.7119, 40.9015],
            [-97.7119, 40.9087],
            [-97.6549, 40.9448],
            [-97.569, 40.9592],
            [-97.4903, 40.9011],
            [-97.4926, 40.7851],
            [-97.7118, 40.7856],
            [-97.7119, 40.9015],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3179050',
        NAME: 'York Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288197674,
        AWATER: 1157539,
        INTPTLAT: '+40.8595456',
        INTPTLON: '-097.5986076',
        ELSDLEA: '',
        UNSDLEA: '79050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7563, 42.2318],
              [-96.7562, 42.2356],
              [-96.746, 42.2354],
              [-96.7565, 42.2282],
              [-96.7563, 42.2318],
            ],
          ],
          [
            [
              [-96.7583, 42.0036],
              [-96.7489, 42.0036],
              [-96.7488, 41.9961],
              [-96.7583, 41.9961],
              [-96.7583, 42.0036],
            ],
          ],
          [
            [
              [-96.7971, 42.0104],
              [-96.7872, 42.0105],
              [-96.7872, 42.0033],
              [-96.798, 41.9968],
              [-96.8065, 41.9967],
              [-96.7971, 42.0104],
            ],
          ],
          [
            [
              [-96.8064, 42.0397],
              [-96.8065, 42.0252],
              [-96.8163, 42.0252],
              [-96.8258, 42.0253],
              [-96.8064, 42.0397],
            ],
          ],
          [
            [
              [-96.7565, 42.221],
              [-96.7458, 42.2185],
              [-96.7457, 42.214],
              [-96.62, 42.206],
              [-96.6199, 42.2096],
              [-96.6198, 42.2133],
              [-96.5995, 42.206],
              [-96.551, 42.1045],
              [-96.6757, 42.0395],
              [-96.6711, 42.0353],
              [-96.7004, 42.0108],
              [-96.7101, 42.0167],
              [-96.7052, 42.0238],
              [-96.7053, 42.0244],
              [-96.71, 42.0243],
              [-96.7583, 42.0107],
              [-96.7683, 42.0107],
              [-96.7872, 42.0105],
              [-96.8065, 42.0179],
              [-96.8065, 42.0252],
              [-96.8064, 42.0397],
              [-96.89, 42.1006],
              [-96.7565, 42.221],
            ],
          ],
          [
            [
              [-96.9402, 42.1051],
              [-96.931, 42.105],
              [-96.9402, 42.101],
              [-96.9402, 42.1051],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175390',
        NAME: 'Pender Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399892116,
        AWATER: 826696,
        INTPTLAT: '+42.1244847',
        INTPTLON: '-096.7355034',
        ELSDLEA: '',
        UNSDLEA: '75390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8163, 42.0252],
              [-96.8065, 42.0252],
              [-96.8065, 42.0179],
              [-96.8163, 42.0252],
            ],
          ],
          [
            [
              [-96.9402, 42.1196],
              [-96.9323, 42.1196],
              [-96.9207, 42.1195],
              [-96.9207, 42.1118],
              [-96.9402, 42.1051],
              [-96.9402, 42.1196],
            ],
          ],
          [
            [
              [-97.0631, 41.8447],
              [-97.0583, 41.8447],
              [-97.0582, 41.8369],
              [-97.0631, 41.8447],
            ],
          ],
          [
            [
              [-97.1368, 42.0907],
              [-97.0765, 42.0975],
              [-97.0569, 42.1125],
              [-97.0484, 42.1051],
              [-96.9402, 42.101],
              [-96.931, 42.105],
              [-96.9207, 42.105],
              [-96.9112, 42.105],
              [-96.9012, 42.1008],
              [-96.8953, 42.1006],
              [-96.89, 42.1006],
              [-96.8064, 42.0397],
              [-96.8258, 42.0253],
              [-96.7971, 42.0104],
              [-96.8065, 41.9967],
              [-96.8167, 41.9966],
              [-96.8541, 41.9677],
              [-96.8746, 41.9681],
              [-96.9033, 41.8587],
              [-96.9228, 41.8529],
              [-96.9422, 41.8298],
              [-97.0481, 41.8513],
              [-97.0584, 41.8513],
              [-97.0779, 41.8591],
              [-97.0779, 41.8659],
              [-97.0678, 41.8659],
              [-97.0678, 41.8736],
              [-97.0728, 41.8735],
              [-97.0779, 41.8735],
              [-97.0779, 41.8693],
              [-97.0873, 41.8879],
              [-97.0585, 41.8881],
              [-97.0975, 41.91],
              [-97.1268, 41.997],
              [-97.1367, 42.0041],
              [-97.1368, 42.0907],
            ],
          ],
          [
            [
              [-96.7583, 42.0107],
              [-96.7583, 42.0036],
              [-96.7683, 42.0107],
              [-96.7583, 42.0107],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100008',
        NAME: 'Wisner-Pilger Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 590900547,
        AWATER: 4779853,
        INTPTLAT: '+41.9884249',
        INTPTLON: '-096.9843750',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3298, 42.1561],
              [-97.3298, 42.1561],
              [-97.3299, 42.1487],
              [-97.3399, 42.1486],
              [-97.3298, 42.1561],
            ],
          ],
          [
            [
              [-97.3301, 42.258],
              [-97.3299, 42.2502],
              [-97.3493, 42.2502],
              [-97.3301, 42.258],
            ],
          ],
          [
            [
              [-97.6597, 42.2643],
              [-97.5722, 42.2643],
              [-97.4848, 42.3296],
              [-97.3493, 42.2502],
              [-97.3298, 42.2434],
              [-97.301, 42.1923],
              [-97.3103, 42.1999],
              [-97.3205, 42.1999],
              [-97.3207, 42.1923],
              [-97.32, 42.1841],
              [-97.3299, 42.1779],
              [-97.3298, 42.1631],
              [-97.3493, 42.163],
              [-97.3639, 42.1488],
              [-97.4456, 42.1249],
              [-97.4457, 42.1198],
              [-97.4556, 42.1016],
              [-97.514, 42.0906],
              [-97.5237, 42.0905],
              [-97.5432, 42.0905],
              [-97.6893, 42.1191],
              [-97.7573, 42.1773],
              [-97.7676, 42.1772],
              [-97.7765, 42.1771],
              [-97.6597, 42.2643],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175510',
        NAME: 'Pierce Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610478560,
        AWATER: 3319142,
        INTPTLAT: '+42.1965070',
        INTPTLON: '-097.5291699',
        ELSDLEA: '',
        UNSDLEA: '75510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.6481, 40.5387],
              [-98.6481, 40.5351],
              [-98.6577, 40.5315],
              [-98.6481, 40.5387],
            ],
          ],
          [
            [
              [-98.6764, 40.4083],
              [-98.6667, 40.4156],
              [-98.629, 40.4157],
              [-98.629, 40.4082],
              [-98.638, 40.4013],
              [-98.6764, 40.4083],
            ],
          ],
          [
            [
              [-98.724, 40.4228],
              [-98.7108, 40.4229],
              [-98.7052, 40.4229],
              [-98.6957, 40.4084],
              [-98.7241, 40.4083],
              [-98.724, 40.4228],
            ],
          ],
          [
            [
              [-98.739, 40.4373],
              [-98.7335, 40.423],
              [-98.7431, 40.4159],
              [-98.752, 40.4232],
              [-98.7522, 40.4084],
              [-98.7338, 40.4015],
              [-98.7241, 40.4016],
              [-98.7241, 40.4011],
              [-98.7124, 40.3939],
              [-98.7124, 40.3866],
              [-98.7186, 40.3866],
              [-98.7185, 40.3793],
              [-98.7051, 40.3794],
              [-98.6291, 40.3868],
              [-98.638, 40.4013],
              [-98.629, 40.4082],
              [-98.6192, 40.4157],
              [-98.6196, 40.5097],
              [-98.6101, 40.5388],
              [-98.6009, 40.5388],
              [-98.5825, 40.5533],
              [-98.5732, 40.5679],
              [-98.5535, 40.4882],
              [-98.4678, 40.4604],
              [-98.4685, 40.4374],
              [-98.5054, 40.365],
              [-98.5199, 40.2773],
              [-98.5294, 40.2774],
              [-98.5199, 40.2701],
              [-98.5007, 40.2194],
              [-98.6604, 40.1693],
              [-98.7261, 40.1764],
              [-98.7314, 40.1764],
              [-98.7634, 40.1904],
              [-98.7821, 40.1905],
              [-98.8399, 40.1903],
              [-98.84, 40.2629],
              [-98.7808, 40.3503],
              [-98.7809, 40.4372],
              [-98.739, 40.4373],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100066',
        NAME: 'Silver Lake Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760785950,
        AWATER: 412474,
        INTPTLAT: '+40.3248310',
        INTPTLON: '-098.6403305',
        ELSDLEA: '',
        UNSDLEA: '00066',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4069, 41.1117],
              [-97.4069, 41.1086],
              [-97.4068, 41.1049],
              [-97.4117, 41.1049],
              [-97.4117, 41.1117],
              [-97.4069, 41.1117],
            ],
          ],
          [
            [
              [-97.7519, 41.1555],
              [-97.733, 41.1628],
              [-97.7137, 41.1774],
              [-97.4647, 41.134],
              [-97.4451, 41.0972],
              [-97.4358, 41.0832],
              [-97.5113, 41.0468],
              [-97.569, 40.9592],
              [-97.6549, 40.9448],
              [-97.7219, 40.974],
              [-97.7313, 40.9885],
              [-97.7519, 41.1555],
            ],
          ],
          [
            [
              [-97.7435, 41.1968],
              [-97.7137, 41.1774],
              [-97.7428, 41.1661],
              [-97.7521, 41.1772],
              [-97.7435, 41.1968],
            ],
          ],
          [
            [
              [-97.7788, 41.2002],
              [-97.7762, 41.2024],
              [-97.7725, 41.2038],
              [-97.7725, 41.2039],
              [-97.7788, 41.2002],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100130',
        NAME: 'Cross County Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457382709,
        AWATER: 1025860,
        INTPTLAT: '+41.0684799',
        INTPTLON: '-097.6079036',
        ELSDLEA: '',
        UNSDLEA: '00130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.28, 41.4664],
              [-99.2889, 41.438],
              [-99.2889, 41.4331],
              [-99.2888, 41.4234],
              [-99.3003, 41.4234],
              [-99.386, 41.4674],
              [-99.28, 41.4664],
            ],
          ],
          [
            [
              [-99.3788, 41.1188],
              [-99.3412, 41.1045],
              [-99.3309, 41.0757],
              [-99.3407, 41.0756],
              [-99.322, 41.0547],
              [-99.3599, 41.073],
              [-99.3599, 41.0756],
              [-99.3691, 41.0899],
              [-99.3788, 41.1188],
            ],
          ],
          [
            [
              [-99.4354, 41.0717],
              [-99.4081, 41.0524],
              [-99.418, 41.0525],
              [-99.4251, 41.0526],
              [-99.4354, 41.0717],
            ],
          ],
          [
            [
              [-99.4546, 41.1116],
              [-99.4545, 41.1184],
              [-99.45, 41.0789],
              [-99.4546, 41.1116],
            ],
          ],
          [
            [
              [-99.6557, 41.0754],
              [-99.6456, 41.0753],
              [-99.6456, 41.072],
              [-99.6456, 41.0698],
              [-99.6557, 41.0754],
            ],
          ],
          [
            [
              [-99.762, 41.1427],
              [-99.762, 41.1433],
              [-99.7616, 41.1433],
              [-99.762, 41.1427],
            ],
          ],
          [
            [
              [-99.8203, 41.4016],
              [-99.6176, 41.5679],
              [-99.5788, 41.5612],
              [-99.5971, 41.583],
              [-99.5972, 41.5973],
              [-99.578, 41.622],
              [-99.5592, 41.5825],
              [-99.5399, 41.5753],
              [-99.5313, 41.5683],
              [-99.4052, 41.467],
              [-99.4345, 41.307],
              [-99.5106, 41.1557],
              [-99.483, 41.1182],
              [-99.5015, 41.1182],
              [-99.5223, 41.1083],
              [-99.5502, 41.0896],
              [-99.6404, 41.0811],
              [-99.6455, 41.0811],
              [-99.6551, 41.0811],
              [-99.6648, 41.104],
              [-99.6545, 41.1508],
              [-99.683, 41.1874],
              [-99.7011, 41.1909],
              [-99.7204, 41.2772],
              [-99.8004, 41.35],
              [-99.8196, 41.3788],
              [-99.8203, 41.4016],
            ],
          ],
          [
            [
              [-99.3002, 41.416],
              [-99.2891, 41.416],
              [-99.2888, 41.4234],
              [-99.2695, 41.4157],
              [-99.2801, 41.3869],
              [-99.2807, 41.3832],
              [-99.2876, 41.383],
              [-99.3002, 41.416],
            ],
          ],
          [
            [
              [-99.3411, 41.1188],
              [-99.3411, 41.112],
              [-99.35, 41.112],
              [-99.3411, 41.1188],
            ],
          ],
          [
            [
              [-99.3405, 41.1584],
              [-99.3403, 41.1476],
              [-99.3494, 41.1437],
              [-99.3546, 41.1439],
              [-99.3543, 41.1476],
              [-99.3595, 41.1533],
              [-99.3405, 41.1584],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100011',
        NAME: 'Broken Bow Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1246944445,
        AWATER: 208747,
        INTPTLAT: '+41.3386628',
        INTPTLON: '-099.5913952',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.1462, 41.2215],
            [-102.8594, 41.1916],
            [-102.8399, 41.0622],
            [-102.7928, 41.0023],
            [-102.8856, 41.0021],
            [-103.3062, 41.0019],
            [-103.2987, 41.0768],
            [-103.1838, 41.0766],
            [-103.1462, 41.2215],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176710',
        NAME: 'Sidney Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 746437651,
        AWATER: 95209,
        INTPTLAT: '+41.0931107',
        INTPTLON: '-103.0367620',
        ELSDLEA: '',
        UNSDLEA: '76710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8719, 41.8725],
              [-97.8682, 41.8726],
              [-97.8621, 41.8726],
              [-97.8621, 41.8653],
              [-97.8718, 41.8653],
              [-97.8719, 41.8725],
            ],
          ],
          [
            [
              [-97.9695, 42.0378],
              [-97.9695, 42.0306],
              [-97.9594, 42.0306],
              [-97.9265, 42.0571],
              [-97.8886, 42.1188],
              [-97.88, 42.1261],
              [-97.8539, 42.1187],
              [-97.8349, 42.1767],
              [-97.7765, 42.1771],
              [-97.7676, 42.1772],
              [-97.7573, 42.1773],
              [-97.6893, 42.1191],
              [-97.6596, 42.0469],
              [-97.7368, 41.9598],
              [-97.7172, 41.9598],
              [-97.7464, 41.9233],
              [-97.7464, 41.9164],
              [-97.707, 41.9092],
              [-97.7065, 41.902],
              [-97.717, 41.8948],
              [-97.7264, 41.8948],
              [-97.7366, 41.9019],
              [-97.7463, 41.8874],
              [-97.7855, 41.8874],
              [-97.8428, 41.8905],
              [-97.8428, 41.8872],
              [-97.8332, 41.8795],
              [-97.8431, 41.8795],
              [-97.8526, 41.8795],
              [-97.8682, 41.8726],
              [-97.8718, 41.8795],
              [-97.8719, 41.887],
              [-97.8719, 41.887],
              [-97.8719, 41.8795],
              [-97.8818, 41.8794],
              [-97.8912, 41.8794],
              [-97.8916, 41.9161],
              [-97.8995, 41.9161],
              [-97.9159, 41.9161],
              [-97.95, 42.0028],
              [-97.9942, 42.0168],
              [-97.9695, 42.0378],
            ],
          ],
          [
            [
              [-97.708, 41.8585],
              [-97.6975, 41.8586],
              [-97.6881, 41.8586],
              [-97.6849, 41.8586],
              [-97.6782, 41.8587],
              [-97.6976, 41.8497],
              [-97.7079, 41.8497],
              [-97.708, 41.8585],
            ],
          ],
          [
            [
              [-97.717, 41.8948],
              [-97.7067, 41.8871],
              [-97.717, 41.8862],
              [-97.717, 41.8948],
            ],
          ],
          [
            [
              [-97.717, 41.8843],
              [-97.6975, 41.8843],
              [-97.7079, 41.873],
              [-97.717, 41.8729],
              [-97.7269, 41.8729],
              [-97.717, 41.8843],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100025',
        NAME: 'Elkhorn Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547610071,
        AWATER: 2066823,
        INTPTLAT: '+42.0251033',
        INTPTLON: '-097.8050990',
        ELSDLEA: '',
        UNSDLEA: '00025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3512, 41.6745],
              [-97.3512, 41.678],
              [-97.3493, 41.678],
              [-97.3493, 41.6734],
              [-97.3512, 41.6735],
              [-97.3512, 41.6745],
            ],
          ],
          [
            [
              [-97.3608, 41.7051],
              [-97.3593, 41.7048],
              [-97.361, 41.7046],
              [-97.3608, 41.7051],
            ],
          ],
          [
            [
              [-97.3612, 41.7056],
              [-97.3608, 41.7056],
              [-97.3608, 41.7051],
              [-97.3612, 41.7056],
            ],
          ],
          [
            [
              [-97.3616, 41.7072],
              [-97.3608, 41.7072],
              [-97.3605, 41.7072],
              [-97.3608, 41.7066],
              [-97.3614, 41.7066],
              [-97.3616, 41.7066],
              [-97.3616, 41.7072],
            ],
          ],
          [
            [
              [-97.3623, 41.7066],
              [-97.3623, 41.7072],
              [-97.3619, 41.7077],
              [-97.3619, 41.7072],
              [-97.3616, 41.7066],
              [-97.3623, 41.7061],
              [-97.3623, 41.7066],
            ],
          ],
          [
            [
              [-97.3626, 41.707],
              [-97.3626, 41.7072],
              [-97.3623, 41.7072],
              [-97.3623, 41.7066],
              [-97.3626, 41.707],
            ],
          ],
          [
            [
              [-97.3626, 41.7059],
              [-97.3623, 41.7059],
              [-97.3623, 41.7056],
              [-97.3626, 41.7056],
              [-97.3626, 41.7059],
            ],
          ],
          [
            [
              [-97.3626, 41.7051],
              [-97.3623, 41.7056],
              [-97.3614, 41.7056],
              [-97.3626, 41.7051],
            ],
          ],
          [
            [
              [-97.3637, 41.7093],
              [-97.3632, 41.7087],
              [-97.3635, 41.7087],
              [-97.3637, 41.7087],
              [-97.3637, 41.7093],
            ],
          ],
          [
            [
              [-97.3644, 41.7093],
              [-97.3642, 41.7093],
              [-97.3642, 41.7087],
              [-97.3644, 41.7087],
              [-97.3644, 41.7093],
            ],
          ],
          [
            [
              [-97.3645, 41.7062],
              [-97.3645, 41.7066],
              [-97.3644, 41.7066],
              [-97.3638, 41.7072],
              [-97.3637, 41.7072],
              [-97.3635, 41.7072],
              [-97.3631, 41.7072],
              [-97.363, 41.7066],
              [-97.3626, 41.7056],
              [-97.3626, 41.7053],
              [-97.3645, 41.7062],
            ],
          ],
          [
            [
              [-97.3653, 41.7066],
              [-97.3646, 41.7056],
              [-97.3655, 41.7066],
              [-97.3653, 41.7066],
            ],
          ],
          [
            [
              [-97.3656, 41.7097],
              [-97.3644, 41.7097],
              [-97.3644, 41.7093],
              [-97.3654, 41.7093],
              [-97.3656, 41.7093],
              [-97.3656, 41.7097],
            ],
          ],
          [
            [
              [-97.3664, 41.7087],
              [-97.3655, 41.7077],
              [-97.365, 41.7072],
              [-97.3645, 41.7066],
              [-97.3649, 41.7066],
              [-97.3653, 41.7066],
              [-97.3663, 41.7076],
              [-97.3664, 41.7087],
            ],
          ],
          [
            [
              [-97.3682, 41.6852],
              [-97.3639, 41.6852],
              [-97.3592, 41.6852],
              [-97.3593, 41.678],
              [-97.3682, 41.6852],
            ],
          ],
          [
            [
              [-97.3733, 41.6915],
              [-97.3681, 41.6915],
              [-97.3682, 41.6852],
              [-97.3733, 41.6915],
            ],
          ],
          [
            [
              [-97.6585, 41.7938],
              [-97.6585, 41.7863],
              [-97.6684, 41.7862],
              [-97.6585, 41.7938],
            ],
          ],
          [
            [
              [-97.6585, 41.7572],
              [-97.6479, 41.7572],
              [-97.6479, 41.7645],
              [-97.5325, 41.7353],
              [-97.4454, 41.7291],
              [-97.397, 41.7432],
              [-97.397, 41.7359],
              [-97.3875, 41.729],
              [-97.3875, 41.7285],
              [-97.3874, 41.7262],
              [-97.3874, 41.7174],
              [-97.3874, 41.7142],
              [-97.3779, 41.7142],
              [-97.3779, 41.7073],
              [-97.3874, 41.7072],
              [-97.3778, 41.6997],
              [-97.3778, 41.6959],
              [-97.3874, 41.6959],
              [-97.3874, 41.6886],
              [-97.3875, 41.6814],
              [-97.3875, 41.678],
              [-97.4069, 41.682],
              [-97.4264, 41.671],
              [-97.3975, 41.6602],
              [-97.3779, 41.6563],
              [-97.5423, 41.5695],
              [-97.6, 41.5985],
              [-97.6096, 41.5839],
              [-97.6291, 41.5839],
              [-97.6386, 41.5913],
              [-97.658, 41.5984],
              [-97.658, 41.6128],
              [-97.6776, 41.6345],
              [-97.6969, 41.6417],
              [-97.7065, 41.6492],
              [-97.6585, 41.7572],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172150',
        NAME: 'Humphrey Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405605073,
        AWATER: 505693,
        INTPTLAT: '+41.6777086',
        INTPTLON: '-097.5514210',
        ELSDLEA: '',
        UNSDLEA: '72150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3874, 41.6886],
              [-97.3874, 41.6959],
              [-97.3778, 41.6959],
              [-97.3875, 41.6814],
              [-97.3874, 41.6886],
            ],
          ],
          [
            [
              [-97.397, 41.7432],
              [-97.3101, 41.7867],
              [-97.3009, 41.794],
              [-97.2911, 41.8082],
              [-97.2424, 41.8155],
              [-97.2234, 41.8086],
              [-97.2186, 41.8009],
              [-97.2236, 41.7575],
              [-97.185, 41.7137],
              [-97.1659, 41.6631],
              [-97.186, 41.6341],
              [-97.1944, 41.6268],
              [-97.1945, 41.6162],
              [-97.1945, 41.6123],
              [-97.2578, 41.5837],
              [-97.3399, 41.6633],
              [-97.3399, 41.6707],
              [-97.34, 41.6779],
              [-97.3399, 41.6853],
              [-97.3592, 41.6852],
              [-97.3639, 41.6852],
              [-97.3681, 41.6915],
              [-97.3733, 41.6915],
              [-97.3778, 41.6997],
              [-97.3874, 41.7072],
              [-97.3779, 41.7073],
              [-97.3682, 41.7073],
              [-97.3646, 41.7056],
              [-97.3653, 41.7066],
              [-97.3649, 41.7066],
              [-97.3645, 41.7062],
              [-97.3626, 41.7053],
              [-97.3626, 41.7051],
              [-97.3614, 41.7056],
              [-97.3612, 41.7056],
              [-97.3608, 41.7051],
              [-97.3608, 41.7056],
              [-97.3608, 41.7066],
              [-97.3605, 41.7072],
              [-97.3608, 41.7072],
              [-97.3616, 41.7072],
              [-97.3619, 41.7072],
              [-97.3619, 41.7077],
              [-97.3623, 41.7072],
              [-97.3626, 41.7072],
              [-97.3631, 41.7072],
              [-97.3635, 41.7072],
              [-97.3637, 41.7072],
              [-97.3638, 41.7072],
              [-97.3655, 41.7077],
              [-97.3664, 41.7087],
              [-97.3656, 41.7093],
              [-97.3654, 41.7093],
              [-97.3649, 41.7087],
              [-97.3644, 41.7087],
              [-97.3642, 41.7087],
              [-97.3637, 41.7087],
              [-97.3635, 41.7087],
              [-97.3632, 41.7087],
              [-97.3637, 41.7093],
              [-97.3642, 41.7093],
              [-97.3644, 41.7093],
              [-97.3644, 41.7097],
              [-97.3656, 41.7097],
              [-97.3682, 41.7107],
              [-97.3718, 41.7142],
              [-97.3875, 41.7285],
              [-97.3875, 41.729],
              [-97.397, 41.7359],
              [-97.397, 41.7432],
            ],
            [
              [-97.2329, 41.6199],
              [-97.2233, 41.627],
              [-97.2329, 41.627],
              [-97.2329, 41.6199],
            ],
            [
              [-97.3608, 41.7051],
              [-97.361, 41.7046],
              [-97.3495, 41.701],
              [-97.3495, 41.707],
              [-97.3593, 41.7048],
              [-97.3608, 41.7051],
            ],
            [
              [-97.2233, 41.627],
              [-97.2136, 41.6269],
              [-97.209, 41.6335],
              [-97.2233, 41.627],
            ],
          ],
          [
            [
              [-97.3619, 41.7077],
              [-97.3626, 41.7081],
              [-97.3624, 41.7081],
              [-97.3619, 41.7077],
            ],
          ],
          [
            [
              [-97.1559, 41.6121],
              [-97.1522, 41.6121],
              [-97.156, 41.6094],
              [-97.1559, 41.6121],
            ],
          ],
          [
            [
              [-97.1511, 41.6482],
              [-97.1559, 41.6411],
              [-97.156, 41.6518],
              [-97.1511, 41.6482],
            ],
          ],
          [
            [
              [-97.1752, 41.6267],
              [-97.1652, 41.6266],
              [-97.1654, 41.6189],
              [-97.1752, 41.619],
              [-97.1752, 41.6267],
            ],
          ],
          [
            [
              [-97.1945, 41.6123],
              [-97.1853, 41.6123],
              [-97.1853, 41.6046],
              [-97.1945, 41.6047],
              [-97.1945, 41.6123],
            ],
          ],
          [
            [
              [-97.3623, 41.7059],
              [-97.3623, 41.7061],
              [-97.3616, 41.7066],
              [-97.3614, 41.7066],
              [-97.3614, 41.7056],
              [-97.3623, 41.7056],
              [-97.3623, 41.7059],
            ],
          ],
          [
            [
              [-97.363, 41.7066],
              [-97.3626, 41.707],
              [-97.3623, 41.7066],
              [-97.3623, 41.7061],
              [-97.3626, 41.7059],
              [-97.3626, 41.7056],
              [-97.363, 41.7066],
            ],
          ],
          [
            [
              [-97.365, 41.7072],
              [-97.3644, 41.7066],
              [-97.3645, 41.7066],
              [-97.365, 41.7072],
            ],
          ],
          [
            [
              [-97.3663, 41.7076],
              [-97.3653, 41.7066],
              [-97.3655, 41.7066],
              [-97.3663, 41.7076],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172720',
        NAME: 'Leigh Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287913036,
        AWATER: 478953,
        INTPTLAT: '+41.7059757',
        INTPTLON: '-097.2633344',
        ELSDLEA: '',
        UNSDLEA: '72720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0515, 40.3966],
            [-102.0515, 40.6464],
            [-101.9692, 40.6182],
            [-101.7233, 40.6998],
            [-101.4021, 40.698],
            [-101.3539, 40.6393],
            [-101.4011, 40.6258],
            [-101.4855, 40.51],
            [-101.4944, 40.3647],
            [-101.5501, 40.3285],
            [-101.6269, 40.2638],
            [-101.7203, 40.2639],
            [-101.8293, 40.3346],
            [-101.9759, 40.3493],
            [-102.0516, 40.2659],
            [-102.0515, 40.3966],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100163',
        NAME: 'Chase County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2001062939,
        AWATER: 7895859,
        INTPTLAT: '+40.5090059',
        INTPTLON: '-101.7540561',
        ELSDLEA: '',
        UNSDLEA: '00163',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5293, 42.4482],
              [-96.5248, 42.4453],
              [-96.5248, 42.4447],
              [-96.5263, 42.4447],
              [-96.5293, 42.4452],
              [-96.5293, 42.4458],
              [-96.5293, 42.4482],
            ],
          ],
          [
            [
              [-96.5378, 42.4562],
              [-96.5327, 42.4561],
              [-96.5327, 42.4556],
              [-96.5329, 42.452],
              [-96.537, 42.4483],
              [-96.5378, 42.4562],
            ],
          ],
          [
            [
              [-96.8693, 42.5175],
              [-96.8593, 42.5176],
              [-96.8593, 42.5108],
              [-96.8693, 42.5175],
            ],
          ],
          [
            [
              [-96.8694, 42.5455],
              [-96.8693, 42.554],
              [-96.7926, 42.6659],
              [-96.6877, 42.6531],
              [-96.6502, 42.5619],
              [-96.6333, 42.5402],
              [-96.6333, 42.5331],
              [-96.6293, 42.5194],
              [-96.6125, 42.5066],
              [-96.5992, 42.5046],
              [-96.5844, 42.5006],
              [-96.5844, 42.4952],
              [-96.5844, 42.493],
              [-96.5812, 42.4923],
              [-96.5797, 42.4952],
              [-96.5694, 42.4929],
              [-96.5516, 42.4955],
              [-96.5283, 42.4808],
              [-96.5228, 42.4664],
              [-96.5379, 42.4665],
              [-96.5459, 42.47],
              [-96.5496, 42.4723],
              [-96.5654, 42.4764],
              [-96.5497, 42.4638],
              [-96.5461, 42.4529],
              [-96.5494, 42.4515],
              [-96.5538, 42.4498],
              [-96.5606, 42.4474],
              [-96.5612, 42.4474],
              [-96.562, 42.4469],
              [-96.562, 42.4465],
              [-96.5629, 42.4464],
              [-96.5656, 42.4444],
              [-96.5678, 42.4467],
              [-96.5678, 42.4473],
              [-96.568, 42.4478],
              [-96.5691, 42.4487],
              [-96.5703, 42.4494],
              [-96.5708, 42.4484],
              [-96.5752, 42.4482],
              [-96.5767, 42.4481],
              [-96.5767, 42.4468],
              [-96.5752, 42.4468],
              [-96.575, 42.4455],
              [-96.5737, 42.4431],
              [-96.5725, 42.4375],
              [-96.5783, 42.4352],
              [-96.5707, 42.4233],
              [-96.6773, 42.409],
              [-96.6927, 42.4448],
              [-96.7079, 42.4449],
              [-96.7263, 42.4341],
              [-96.7261, 42.4374],
              [-96.7174, 42.4594],
              [-96.712, 42.4666],
              [-96.8002, 42.5031],
              [-96.8593, 42.5108],
              [-96.8593, 42.5176],
              [-96.8693, 42.5175],
              [-96.8694, 42.5455],
            ],
            [
              [-96.562, 42.4483],
              [-96.5612, 42.4488],
              [-96.5614, 42.4493],
              [-96.562, 42.4483],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175770',
        NAME: 'Ponca Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352385931,
        AWATER: 7013863,
        INTPTLAT: '+42.5356069',
        INTPTLON: '-096.7119268',
        ELSDLEA: '',
        UNSDLEA: '75770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7942, 42.3511],
            [-96.784, 42.3514],
            [-96.7795, 42.3657],
            [-96.7795, 42.3877],
            [-96.7713, 42.3845],
            [-96.7748, 42.3801],
            [-96.7708, 42.3801],
            [-96.7708, 42.3844],
            [-96.7261, 42.4374],
            [-96.7263, 42.4341],
            [-96.6773, 42.409],
            [-96.5707, 42.4233],
            [-96.551, 42.4015],
            [-96.5507, 42.3942],
            [-96.59, 42.3365],
            [-96.5508, 42.2777],
            [-96.5508, 42.2728],
            [-96.5507, 42.2638],
            [-96.6198, 42.2133],
            [-96.6199, 42.2096],
            [-96.62, 42.206],
            [-96.7457, 42.214],
            [-96.7458, 42.2185],
            [-96.7565, 42.221],
            [-96.7565, 42.2282],
            [-96.746, 42.2354],
            [-96.7655, 42.2501],
            [-96.7721, 42.2499],
            [-96.7939, 42.2642],
            [-96.7975, 42.2642],
            [-96.7942, 42.3511],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100141',
        NAME: 'Emerson-Hubbard Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381851629,
        AWATER: 198524,
        INTPTLAT: '+42.3176828',
        INTPTLON: '-096.6765765',
        ELSDLEA: '',
        UNSDLEA: '00141',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7106, 42.5105],
              [-97.7009, 42.5105],
              [-97.7008, 42.5069],
              [-97.7008, 42.5034],
              [-97.7106, 42.5105],
            ],
          ],
          [
            [
              [-97.8868, 42.6157],
              [-97.8772, 42.6194],
              [-97.8773, 42.6265],
              [-97.8775, 42.641],
              [-97.8775, 42.648],
              [-97.8523, 42.6601],
              [-97.8522, 42.6698],
              [-97.8478, 42.6698],
              [-97.7792, 42.6985],
              [-97.7988, 42.8159],
              [-97.7794, 42.851],
              [-97.7207, 42.8473],
              [-97.6536, 42.794],
              [-97.643, 42.6995],
              [-97.4459, 42.6273],
              [-97.4461, 42.6053],
              [-97.4854, 42.5836],
              [-97.5542, 42.5834],
              [-97.6717, 42.5107],
              [-97.6814, 42.5107],
              [-97.7009, 42.5177],
              [-97.7105, 42.5177],
              [-97.7941, 42.5248],
              [-97.8379, 42.5897],
              [-97.8428, 42.6116],
              [-97.8477, 42.6115],
              [-97.8528, 42.6115],
              [-97.8653, 42.6114],
              [-97.8768, 42.6114],
              [-97.8868, 42.6157],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104140',
        NAME: 'Bloomfield Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604459983,
        AWATER: 10226373,
        INTPTLAT: '+42.6461280',
        INTPTLON: '-097.6902292',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0544, 42.6989],
            [-97.0158, 42.7613],
            [-96.9611, 42.7374],
            [-96.9622, 42.7347],
            [-96.8062, 42.7042],
            [-96.7926, 42.6659],
            [-96.8693, 42.554],
            [-96.9372, 42.5251],
            [-96.947, 42.5251],
            [-96.9473, 42.5396],
            [-96.9961, 42.5903],
            [-96.9785, 42.601],
            [-96.996, 42.6007],
            [-96.9961, 42.5975],
            [-97.0063, 42.6014],
            [-97.01, 42.612],
            [-97.0304, 42.6574],
            [-97.0304, 42.6658],
            [-97.0544, 42.6989],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174310',
        NAME: 'Newcastle Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329904048,
        AWATER: 9346075,
        INTPTLAT: '+42.6440114',
        INTPTLON: '-096.9089232',
        ELSDLEA: '',
        UNSDLEA: '74310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9207, 42.1118],
              [-96.9207, 42.105],
              [-96.931, 42.105],
              [-96.9402, 42.1051],
              [-96.9207, 42.1118],
            ],
          ],
          [
            [
              [-96.9593, 42.3513],
              [-96.9496, 42.3513],
              [-96.9593, 42.3441],
              [-96.9593, 42.3513],
            ],
          ],
          [
            [
              [-97.1614, 42.3443],
              [-97.1544, 42.3443],
              [-97.1461, 42.3441],
              [-97.1442, 42.3322],
              [-97.1614, 42.3443],
            ],
          ],
          [
            [
              [-97.2326, 42.3444],
              [-97.2227, 42.3299],
              [-97.2326, 42.3371],
              [-97.2326, 42.3444],
            ],
          ],
          [
            [
              [-97.2326, 42.3371],
              [-97.2227, 42.3299],
              [-97.1442, 42.3321],
              [-97.0867, 42.3447],
              [-96.979, 42.3077],
              [-96.969, 42.3077],
              [-96.908, 42.2263],
              [-96.9207, 42.1195],
              [-96.9323, 42.1196],
              [-96.9321, 42.1266],
              [-96.9402, 42.1256],
              [-96.9402, 42.1196],
              [-96.9402, 42.1051],
              [-96.9402, 42.101],
              [-97.0484, 42.1051],
              [-97.0569, 42.1125],
              [-97.0765, 42.0975],
              [-97.0671, 42.1919],
              [-97.1398, 42.2355],
              [-97.2909, 42.2645],
              [-97.2326, 42.3371],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178520',
        NAME: 'Wayne Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474457181,
        AWATER: 237421,
        INTPTLAT: '+42.2317729',
        INTPTLON: '-097.0632775',
        ELSDLEA: '',
        UNSDLEA: '78520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.8, 40.3494],
              [-100.7774, 40.3495],
              [-100.7584, 40.3495],
              [-100.7589, 40.3221],
              [-100.7963, 40.2928],
              [-100.8, 40.3494],
            ],
          ],
          [
            [
              [-101.0978, 40.2487],
              [-100.9845, 40.2639],
              [-100.9472, 40.35],
              [-100.8436, 40.3357],
              [-100.8147, 40.2717],
              [-100.7598, 40.2717],
              [-100.74, 40.1231],
              [-100.7543, 40.1145],
              [-100.7588, 40.0866],
              [-100.7588, 40.0442],
              [-100.7399, 40.0447],
              [-100.7208, 40.0802],
              [-100.6851, 40.0454],
              [-100.6851, 40.039],
              [-100.6651, 40.002],
              [-100.6916, 40.0021],
              [-100.7027, 40.0021],
              [-100.7388, 40.0021],
              [-100.7496, 40.0021],
              [-100.7588, 40.0023],
              [-101.098, 40.0024],
              [-101.0793, 40.1517],
              [-101.0978, 40.2487],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100120',
        NAME: 'Hitchcock County School System',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1027439471,
        AWATER: 3628361,
        INTPTLAT: '+40.1592008',
        INTPTLON: '-100.9035851',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.7716, 40.6258],
              [-98.7624, 40.6693],
              [-98.6643, 40.7186],
              [-98.6546, 40.7215],
              [-98.6456, 40.7249],
              [-98.5729, 40.6987],
              [-98.573, 40.6915],
              [-98.5563, 40.6987],
              [-98.5539, 40.6987],
              [-98.5923, 40.5786],
              [-98.5732, 40.5679],
              [-98.5825, 40.5533],
              [-98.6863, 40.5463],
              [-98.693, 40.5463],
              [-98.724, 40.5097],
              [-98.743, 40.5387],
              [-98.7336, 40.5677],
              [-98.7716, 40.6258],
            ],
          ],
          [
            [
              [-98.629, 40.4157],
              [-98.6192, 40.4157],
              [-98.629, 40.4082],
              [-98.629, 40.4157],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172420',
        NAME: 'Kenesaw Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283100953,
        AWATER: 662237,
        INTPTLAT: '+40.6288299',
        INTPTLON: '-098.6632684',
        ELSDLEA: '',
        UNSDLEA: '72420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.7408, 40.9747],
              [-98.7309, 40.9748],
              [-98.7218, 40.9671],
              [-98.7409, 40.9672],
              [-98.7408, 40.9747],
            ],
          ],
          [
            [
              [-98.7981, 40.7716],
              [-98.7789, 40.7862],
              [-98.7982, 40.8732],
              [-98.7889, 40.8732],
              [-98.7891, 40.8877],
              [-98.7892, 40.8946],
              [-98.7887, 40.9227],
              [-98.7839, 40.9256],
              [-98.7789, 40.9288],
              [-98.7843, 40.9312],
              [-98.7886, 40.9312],
              [-98.7598, 40.9314],
              [-98.7218, 40.9457],
              [-98.7219, 40.9168],
              [-98.7218, 40.8029],
              [-98.6743, 40.7717],
              [-98.6643, 40.7186],
              [-98.7624, 40.6693],
              [-98.7981, 40.7716],
            ],
          ],
          [
            [
              [-98.8174, 40.9021],
              [-98.8174, 40.8876],
              [-98.8237, 40.8875],
              [-98.8174, 40.9021],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176620',
        NAME: 'Shelton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232961472,
        AWATER: 3701430,
        INTPTLAT: '+40.8079886',
        INTPTLON: '-098.7503688',
        ELSDLEA: '',
        UNSDLEA: '76620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0383, 40.489],
              [-100.0345, 40.489],
              [-100.0478, 40.4818],
              [-100.0574, 40.4818],
              [-100.0383, 40.489],
            ],
          ],
          [
            [
              [-100.148, 40.8584],
              [-100.1381, 40.8585],
              [-100.1383, 40.8439],
              [-100.1447, 40.8439],
              [-100.1467, 40.8439],
              [-100.148, 40.8441],
              [-100.148, 40.8584],
            ],
          ],
          [
            [
              [-100.1672, 40.8513],
              [-100.1573, 40.8513],
              [-100.148, 40.8439],
              [-100.1479, 40.8405],
              [-100.1477, 40.8294],
              [-100.1666, 40.8292],
              [-100.1672, 40.8513],
            ],
          ],
          [
            [
              [-100.4319, 40.8293],
              [-100.3674, 40.8292],
              [-100.2479, 40.8577],
              [-100.1859, 40.8016],
              [-100.1091, 40.7864],
              [-100.0048, 40.7432],
              [-99.9014, 40.7429],
              [-99.9817, 40.5254],
              [-100.0117, 40.5034],
              [-100.0226, 40.5034],
              [-100.0288, 40.5034],
              [-100.1069, 40.4967],
              [-100.1238, 40.504],
              [-100.1336, 40.504],
              [-100.2684, 40.5477],
              [-100.3346, 40.5847],
              [-100.324, 40.6281],
              [-100.3423, 40.6707],
              [-100.4319, 40.8293],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100111',
        NAME: 'Eustis-Farnam Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1024116385,
        AWATER: 1750276,
        INTPTLAT: '+40.6537149',
        INTPTLON: '-100.1667687',
        ELSDLEA: '',
        UNSDLEA: '00111',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.7273, 40.3863],
              [-99.7179, 40.3863],
              [-99.7167, 40.3801],
              [-99.7273, 40.3863],
            ],
          ],
          [
            [
              [-99.7571, 40.641],
              [-99.7573, 40.6469],
              [-99.7375, 40.6559],
              [-99.5981, 40.6793],
              [-99.5303, 40.641],
              [-99.5309, 40.5175],
              [-99.6059, 40.4812],
              [-99.595, 40.3512],
              [-99.6995, 40.3729],
              [-99.71, 40.38],
              [-99.71, 40.3864],
              [-99.7179, 40.3863],
              [-99.7284, 40.3937],
              [-99.7477, 40.4089],
              [-99.7738, 40.4531],
              [-99.7571, 40.641],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104020',
        NAME: 'Bertrand Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548923061,
        AWATER: 573360,
        INTPTLAT: '+40.5305259',
        INTPTLON: '-099.6537182',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1808, 42.0907],
              [-97.1759, 42.0907],
              [-97.1758, 42.083],
              [-97.1808, 42.0907],
            ],
          ],
          [
            [
              [-97.5432, 42.0905],
              [-97.5237, 42.0905],
              [-97.514, 42.0906],
              [-97.4556, 42.1016],
              [-97.4457, 42.1198],
              [-97.4456, 42.1249],
              [-97.3639, 42.1488],
              [-97.3399, 42.1486],
              [-97.3299, 42.1487],
              [-97.3298, 42.1561],
              [-97.3298, 42.1561],
              [-97.3298, 42.1631],
              [-97.3299, 42.1779],
              [-97.32, 42.1841],
              [-97.3207, 42.1923],
              [-97.3205, 42.1999],
              [-97.3103, 42.1999],
              [-97.301, 42.1923],
              [-97.2517, 42.0908],
              [-97.2624, 42.0836],
              [-97.3298, 41.9751],
              [-97.3493, 41.9535],
              [-97.3493, 41.9457],
              [-97.3584, 41.9025],
              [-97.3584, 41.9106],
              [-97.3783, 41.9314],
              [-97.3783, 41.9387],
              [-97.3783, 41.9459],
              [-97.3784, 41.9501],
              [-97.3784, 41.9584],
              [-97.3811, 41.9604],
              [-97.3962, 41.9603],
              [-97.451, 41.9963],
              [-97.5239, 42.0102],
              [-97.5432, 42.0905],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174430',
        NAME: 'Norfolk Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372097980,
        AWATER: 3524882,
        INTPTLAT: '+42.0567651',
        INTPTLON: '-097.3758539',
        ELSDLEA: '',
        UNSDLEA: '74430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.6874, 41.8077],
              [-97.6585, 41.7938],
              [-97.6684, 41.7862],
              [-97.6778, 41.7862],
              [-97.6874, 41.8077],
            ],
          ],
          [
            [
              [-97.6782, 41.8657],
              [-97.6782, 41.8587],
              [-97.6849, 41.8586],
              [-97.6881, 41.8586],
              [-97.6782, 41.8657],
            ],
          ],
          [
            [
              [-97.6975, 41.8151],
              [-97.6974, 41.8077],
              [-97.7072, 41.8076],
              [-97.6975, 41.8151],
            ],
          ],
          [
            [
              [-97.708, 41.8656],
              [-97.6975, 41.8586],
              [-97.708, 41.8585],
              [-97.708, 41.8656],
            ],
          ],
          [
            [
              [-97.7079, 41.8497],
              [-97.6976, 41.8497],
              [-97.7079, 41.8374],
              [-97.7079, 41.8497],
            ],
          ],
          [
            [
              [-97.7365, 41.8874],
              [-97.7264, 41.8948],
              [-97.717, 41.8948],
              [-97.717, 41.8862],
              [-97.717, 41.8843],
              [-97.7269, 41.8729],
              [-97.7365, 41.8874],
            ],
          ],
          [
            [
              [-97.7366, 41.9019],
              [-97.7365, 41.8874],
              [-97.7463, 41.8874],
              [-97.7366, 41.9019],
            ],
          ],
          [
            [
              [-97.6893, 42.1191],
              [-97.5432, 42.0905],
              [-97.5239, 42.0102],
              [-97.451, 41.9963],
              [-97.3962, 41.9603],
              [-97.3784, 41.9501],
              [-97.3783, 41.9459],
              [-97.3783, 41.9387],
              [-97.3783, 41.9314],
              [-97.4549, 41.9309],
              [-97.5424, 41.8874],
              [-97.6005, 41.8732],
              [-97.6782, 41.8657],
              [-97.7079, 41.873],
              [-97.6975, 41.8843],
              [-97.7067, 41.8871],
              [-97.717, 41.8948],
              [-97.7065, 41.902],
              [-97.707, 41.9092],
              [-97.7464, 41.9164],
              [-97.7464, 41.9233],
              [-97.7172, 41.9598],
              [-97.7368, 41.9598],
              [-97.6596, 42.0469],
              [-97.6893, 42.1191],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103540',
        NAME: 'Battle Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445707514,
        AWATER: 2195381,
        INTPTLAT: '+41.9753022',
        INTPTLON: '-097.6019251',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.3055, 42.0743],
              [-98.2954, 42.0744],
              [-98.2955, 42.0596],
              [-98.299, 42.0596],
              [-98.3055, 42.0666],
              [-98.3055, 42.0743],
            ],
          ],
          [
            [
              [-98.3154, 42.0308],
              [-98.2955, 42.031],
              [-98.2956, 42.0231],
              [-98.3058, 42.0164],
              [-98.3154, 42.0164],
              [-98.3154, 42.0308],
            ],
          ],
          [
            [
              [-98.7609, 41.9424],
              [-98.7611, 42.0585],
              [-98.7133, 42.0885],
              [-98.5353, 42.1102],
              [-98.4315, 42.0742],
              [-98.4215, 42.067],
              [-98.3927, 42.075],
              [-98.3924, 42.0317],
              [-98.3923, 42.0164],
              [-98.3735, 42.0164],
              [-98.2956, 41.915],
              [-98.2956, 41.9004],
              [-98.2956, 41.8858],
              [-98.2958, 41.7412],
              [-98.5214, 41.733],
              [-98.5405, 41.6834],
              [-98.6365, 41.6751],
              [-98.7522, 41.7404],
              [-98.7987, 41.8271],
              [-98.8767, 41.9283],
              [-98.7609, 41.9424],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178670',
        NAME: 'Wheeler Central Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1557925949,
        AWATER: 1442922,
        INTPTLAT: '+41.9348101',
        INTPTLON: '-098.6227146',
        ELSDLEA: '',
        UNSDLEA: '78670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.71, 41.2023],
            [-98.7001, 41.1876],
            [-98.7099, 41.2062],
            [-98.7107, 41.2647],
            [-98.7087, 41.2791],
            [-98.6938, 41.2645],
            [-98.6898, 41.2645],
            [-98.6894, 41.279],
            [-98.6593, 41.2714],
            [-98.5171, 41.2493],
            [-98.5345, 41.3941],
            [-98.4298, 41.358],
            [-98.2866, 41.365],
            [-98.3061, 41.1332],
            [-98.4406, 41.1348],
            [-98.4791, 41.1262],
            [-98.6526, 41.1769],
            [-98.7194, 41.1917],
            [-98.7388, 41.1772],
            [-98.71, 41.2023],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100085',
        NAME: 'St. Paul Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 591912600,
        AWATER: 7948390,
        INTPTLAT: '+41.2469104',
        INTPTLON: '-098.4768976',
        ELSDLEA: '',
        UNSDLEA: '00085',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.7896, 42.3349],
              [-102.7689, 42.32],
              [-102.7886, 42.3131],
              [-102.7896, 42.3349],
            ],
          ],
          [
            [
              [-103.4017, 42.0906],
              [-103.2862, 42.0473],
              [-103.286, 42.0021],
              [-103.3633, 42.0029],
              [-103.4016, 42.0035],
              [-103.4017, 42.0906],
            ],
          ],
          [
            [
              [-103.4802, 42.4664],
              [-103.3332, 42.4456],
              [-103.3425, 42.446],
              [-103.3229, 42.4451],
              [-103.2638, 42.5246],
              [-103.2206, 42.5677],
              [-103.2347, 42.6549],
              [-103.2167, 42.6549],
              [-103.1452, 42.6406],
              [-103.0079, 42.5686],
              [-102.9306, 42.582],
              [-102.9105, 42.5534],
              [-102.8912, 42.5679],
              [-102.8716, 42.5676],
              [-102.7729, 42.4969],
              [-102.7734, 42.4399],
              [-102.7496, 42.4296],
              [-102.731, 42.4209],
              [-102.7108, 42.4145],
              [-102.6328, 42.3714],
              [-102.6278, 42.3571],
              [-102.7476, 42.33],
              [-102.7769, 42.2774],
              [-102.8863, 42.2666],
              [-102.881, 42.1778],
              [-103.4049, 42.0906],
              [-103.4441, 42.0907],
              [-103.4445, 42.4374],
              [-103.4802, 42.4664],
            ],
            [
              [-102.7769, 42.2774],
              [-102.7689, 42.32],
              [-102.79, 42.3429],
              [-102.7896, 42.3349],
              [-102.7886, 42.3131],
              [-102.7788, 42.2984],
              [-102.7769, 42.2774],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171730',
        NAME: 'Hemingford Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2532739742,
        AWATER: 6441030,
        INTPTLAT: '+42.3473261',
        INTPTLON: '-103.1190264',
        ELSDLEA: '',
        UNSDLEA: '71730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.9883, 40.8298],
              [-98.9692, 40.8224],
              [-98.9791, 40.8224],
              [-98.9885, 40.8224],
              [-98.9883, 40.8298],
            ],
          ],
          [
            [
              [-98.9883, 40.8658],
              [-98.9786, 40.866],
              [-98.9692, 40.8658],
              [-98.9883, 40.8512],
              [-98.9883, 40.8658],
            ],
          ],
          [
            [
              [-99.1419, 40.8584],
              [-99.1283, 40.851],
              [-99.1417, 40.8508],
              [-99.1419, 40.8584],
            ],
          ],
          [
            [
              [-99.1512, 40.8506],
              [-99.1417, 40.8508],
              [-99.1414, 40.8439],
              [-99.1512, 40.8506],
            ],
          ],
          [
            [
              [-99.2931, 40.7722],
              [-99.2269, 40.7433],
              [-99.2173, 40.7433],
              [-99.1982, 40.7717],
              [-99.1638, 40.7845],
              [-99.1609, 40.7832],
              [-99.1507, 40.8221],
              [-99.1411, 40.8221],
              [-99.1412, 40.8294],
              [-99.1414, 40.8439],
              [-99.1283, 40.8441],
              [-99.0844, 40.8299],
              [-99.0077, 40.8226],
              [-98.9932, 40.8298],
              [-98.9885, 40.8224],
              [-98.9791, 40.8186],
              [-98.9789, 40.8153],
              [-98.9692, 40.779],
              [-98.9595, 40.779],
              [-98.9415, 40.6899],
              [-98.9893, 40.6541],
              [-99.0087, 40.612],
              [-99.1794, 40.6122],
              [-99.227, 40.6125],
              [-99.2366, 40.6125],
              [-99.2884, 40.681],
              [-99.2833, 40.6799],
              [-99.2931, 40.7722],
            ],
          ],
          [
            [
              [-99.5123, 40.6641],
              [-99.5122, 40.6713],
              [-99.5063, 40.6635],
              [-99.5123, 40.6568],
              [-99.5123, 40.6641],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172390',
        NAME: 'Kearney Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 534887003,
        AWATER: 5566456,
        INTPTLAT: '+40.7200842',
        INTPTLON: '-099.1052999',
        ELSDLEA: '',
        UNSDLEA: '72390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.3985, 40.6127],
              [-99.3974, 40.6126],
              [-99.3987, 40.6055],
              [-99.3985, 40.6127],
            ],
          ],
          [
            [
              [-99.4643, 40.5908],
              [-99.4603, 40.5908],
              [-99.4545, 40.5908],
              [-99.4544, 40.5836],
              [-99.4644, 40.5837],
              [-99.4643, 40.5908],
            ],
          ],
          [
            [
              [-99.5183, 40.676],
              [-99.4554, 40.6704],
              [-99.4264, 40.8372],
              [-99.4068, 40.8442],
              [-99.2931, 40.7722],
              [-99.2833, 40.6799],
              [-99.2884, 40.681],
              [-99.2366, 40.6125],
              [-99.3805, 40.6505],
              [-99.3805, 40.6522],
              [-99.3899, 40.6522],
              [-99.3899, 40.653],
              [-99.3899, 40.6577],
              [-99.3989, 40.6501],
              [-99.4071, 40.6422],
              [-99.4176, 40.6417],
              [-99.4175, 40.6562],
              [-99.427, 40.6561],
              [-99.4367, 40.6273],
              [-99.4369, 40.6193],
              [-99.442, 40.6194],
              [-99.4557, 40.6195],
              [-99.4555, 40.6271],
              [-99.4455, 40.6338],
              [-99.4554, 40.6338],
              [-99.4744, 40.642],
              [-99.4696, 40.649],
              [-99.4743, 40.6565],
              [-99.4804, 40.6566],
              [-99.4839, 40.6633],
              [-99.4839, 40.6708],
              [-99.4934, 40.6633],
              [-99.5063, 40.6635],
              [-99.5122, 40.6713],
              [-99.5183, 40.6708],
              [-99.5183, 40.676],
            ],
            [
              [-99.4554, 40.6628],
              [-99.4554, 40.6561],
              [-99.4506, 40.6561],
              [-99.4454, 40.6561],
              [-99.4506, 40.6627],
              [-99.4554, 40.6628],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170140',
        NAME: 'Elm Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306847723,
        AWATER: 3721974,
        INTPTLAT: '+40.7081772',
        INTPTLON: '-099.3595677',
        ELSDLEA: '',
        UNSDLEA: '70140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.1417, 40.8508],
              [-99.1283, 40.851],
              [-99.1283, 40.8441],
              [-99.1414, 40.8439],
              [-99.1417, 40.8508],
            ],
          ],
          [
            [
              [-99.3221, 41.0469],
              [-99.3121, 41.0469],
              [-99.3037, 41.047],
              [-99.293, 41.0324],
              [-99.1979, 40.9711],
              [-99.1419, 40.8584],
              [-99.1512, 40.8506],
              [-99.1414, 40.8439],
              [-99.1412, 40.8294],
              [-99.1507, 40.8221],
              [-99.1609, 40.7832],
              [-99.1638, 40.7845],
              [-99.1982, 40.7717],
              [-99.2173, 40.7433],
              [-99.2269, 40.7433],
              [-99.2931, 40.7722],
              [-99.4068, 40.8442],
              [-99.3691, 40.866],
              [-99.3594, 41.0135],
              [-99.3221, 41.0469],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100070',
        NAME: 'Amherst Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407657724,
        AWATER: 55886,
        INTPTLAT: '+40.8791420',
        INTPTLON: '-099.2733528',
        ELSDLEA: '',
        UNSDLEA: '00070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5293, 42.4458],
              [-96.5293, 42.4452],
              [-96.5274, 42.443],
              [-96.5315, 42.4434],
              [-96.5293, 42.4458],
            ],
          ],
          [
            [
              [-96.5612, 42.4474],
              [-96.5606, 42.4474],
              [-96.562, 42.4469],
              [-96.5612, 42.4474],
            ],
          ],
          [
            [
              [-96.59, 42.3365],
              [-96.5507, 42.3942],
              [-96.551, 42.4015],
              [-96.5707, 42.4233],
              [-96.5783, 42.4352],
              [-96.5725, 42.4375],
              [-96.5737, 42.4431],
              [-96.575, 42.4455],
              [-96.5752, 42.4468],
              [-96.5752, 42.4482],
              [-96.5708, 42.4484],
              [-96.5703, 42.4494],
              [-96.5691, 42.4487],
              [-96.568, 42.4478],
              [-96.5678, 42.4473],
              [-96.5678, 42.4467],
              [-96.5656, 42.4444],
              [-96.5559, 42.4399],
              [-96.5538, 42.4498],
              [-96.5494, 42.4515],
              [-96.5461, 42.4529],
              [-96.5497, 42.4638],
              [-96.5459, 42.47],
              [-96.5379, 42.4665],
              [-96.5378, 42.4562],
              [-96.5342, 42.4597],
              [-96.5227, 42.4619],
              [-96.5228, 42.4664],
              [-96.5283, 42.4808],
              [-96.5516, 42.4955],
              [-96.5654, 42.4929],
              [-96.5694, 42.4929],
              [-96.5797, 42.4952],
              [-96.5844, 42.4952],
              [-96.5844, 42.5006],
              [-96.5805, 42.5018],
              [-96.5651, 42.5117],
              [-96.5564, 42.5145],
              [-96.5373, 42.5101],
              [-96.5413, 42.5189],
              [-96.481, 42.4797],
              [-96.4113, 42.3863],
              [-96.3687, 42.3047],
              [-96.3564, 42.2765],
              [-96.5508, 42.2777],
              [-96.59, 42.3365],
            ],
            [
              [-96.5378, 42.4562],
              [-96.537, 42.4483],
              [-96.5369, 42.4422],
              [-96.5219, 42.4404],
              [-96.5274, 42.443],
              [-96.5263, 42.4447],
              [-96.5248, 42.4447],
              [-96.5248, 42.4453],
              [-96.5293, 42.4482],
              [-96.5329, 42.452],
              [-96.5327, 42.4556],
              [-96.5327, 42.4561],
              [-96.5378, 42.4562],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172000',
        NAME: 'Homer Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273560931,
        AWATER: 3321667,
        INTPTLAT: '+42.3573430',
        INTPTLON: '-096.4934623',
        ELSDLEA: '',
        UNSDLEA: '72000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7713, 42.3845],
              [-96.7708, 42.3844],
              [-96.7708, 42.3801],
              [-96.7748, 42.3801],
              [-96.7713, 42.3845],
            ],
          ],
          [
            [
              [-96.9402, 42.1256],
              [-96.9321, 42.1266],
              [-96.9323, 42.1196],
              [-96.9402, 42.1196],
              [-96.9402, 42.1256],
            ],
          ],
          [
            [
              [-96.9593, 42.3441],
              [-96.9496, 42.3513],
              [-96.7942, 42.3511],
              [-96.7975, 42.2642],
              [-96.7939, 42.2642],
              [-96.7721, 42.2499],
              [-96.7655, 42.2501],
              [-96.746, 42.2354],
              [-96.7562, 42.2356],
              [-96.7563, 42.2318],
              [-96.7565, 42.2282],
              [-96.7565, 42.221],
              [-96.89, 42.1006],
              [-96.8953, 42.1006],
              [-96.9012, 42.1008],
              [-96.9112, 42.105],
              [-96.9207, 42.105],
              [-96.9207, 42.1118],
              [-96.9207, 42.1195],
              [-96.908, 42.2263],
              [-96.969, 42.3077],
              [-96.9593, 42.3441],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178240',
        NAME: 'Wakefield Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294358556,
        AWATER: 1628511,
        INTPTLAT: '+42.2558764',
        INTPTLON: '-096.8729761',
        ELSDLEA: '',
        UNSDLEA: '78240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3444, 42.1619],
            [-96.3296, 42.1533],
            [-96.272, 42.0892],
            [-96.3175, 42.0892],
            [-96.3221, 42.097],
            [-96.3367, 42.0892],
            [-96.3761, 42.0895],
            [-96.3854, 42.1043],
            [-96.3444, 42.1619],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3119560',
        NAME: 'Umonhon Nation Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60731043,
        AWATER: 1827400,
        INTPTLAT: '+42.1182345',
        INTPTLON: '-096.3463143',
        ELSDLEA: '',
        UNSDLEA: '19560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3367, 42.0892],
              [-96.3221, 42.097],
              [-96.3175, 42.0892],
              [-96.3272, 42.0826],
              [-96.3367, 42.0892],
            ],
          ],
          [
            [
              [-96.5995, 42.206],
              [-96.3853, 42.1842],
              [-96.3471, 42.1912],
              [-96.3444, 42.1619],
              [-96.3854, 42.1043],
              [-96.4722, 42.0898],
              [-96.502, 42.0899],
              [-96.5017, 42.0963],
              [-96.551, 42.1045],
              [-96.5995, 42.206],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178300',
        NAME: 'Walthill Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182662842,
        AWATER: 501715,
        INTPTLAT: '+42.1509003',
        INTPTLON: '-096.4863710',
        ELSDLEA: '',
        UNSDLEA: '78300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5507, 42.2638],
            [-96.5508, 42.2728],
            [-96.5508, 42.2777],
            [-96.3564, 42.2765],
            [-96.3471, 42.1912],
            [-96.3853, 42.1842],
            [-96.5995, 42.206],
            [-96.6198, 42.2133],
            [-96.5507, 42.2638],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178810',
        NAME: 'Winnebago Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180545073,
        AWATER: 2269951,
        INTPTLAT: '+42.2318472',
        INTPTLON: '-096.4589555',
        ELSDLEA: '',
        UNSDLEA: '78810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.1272, 40.0017],
              [-100.1315, 40.0017],
              [-100.1372, 40.0016],
              [-100.1372, 40.009],
              [-100.1273, 40.0092],
              [-100.1272, 40.0017],
            ],
          ],
          [
            [
              [-100.5906, 40.3942],
              [-100.5625, 40.4388],
              [-100.4587, 40.4606],
              [-100.3822, 40.4385],
              [-100.3208, 40.4965],
              [-100.3208, 40.4936],
              [-100.2704, 40.3784],
              [-100.2495, 40.2515],
              [-100.1178, 40.1184],
              [-100.1185, 40.1633],
              [-100.109, 40.1474],
              [-100.0808, 40.1906],
              [-100.0234, 40.1763],
              [-100.0232, 40.1696],
              [-100.0229, 40.1622],
              [-100.0229, 40.1436],
              [-100.0228, 40.1403],
              [-100.0227, 40.133],
              [-100.0148, 40.118],
              [-100.0148, 40.1112],
              [-100.0228, 40.0892],
              [-100.0228, 40.0818],
              [-100.0228, 40.0742],
              [-100.013, 40.0675],
              [-100.013, 40.0596],
              [-100.0223, 40.0448],
              [-100.0223, 40.0387],
              [-100.0045, 40.0304],
              [-99.9896, 40.0305],
              [-99.9575, 40.0221],
              [-99.9577, 40.0017],
              [-100.0322, 40.0016],
              [-100.043, 40.0017],
              [-100.0992, 40.0016],
              [-100.0975, 40.0303],
              [-100.1263, 40.0264],
              [-100.1459, 40.0016],
              [-100.4575, 40.0018],
              [-100.4635, 40.0017],
              [-100.5337, 40.0019],
              [-100.5343, 40.1541],
              [-100.5732, 40.1916],
              [-100.598, 40.3221],
              [-100.6094, 40.3657],
              [-100.5906, 40.3942],
            ],
          ],
          [
            [
              [-100.7588, 40.0866],
              [-100.7543, 40.1145],
              [-100.74, 40.1231],
              [-100.7208, 40.0802],
              [-100.7399, 40.0447],
              [-100.7588, 40.0442],
              [-100.7588, 40.0866],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100178',
        NAME: 'Southwest Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1745798716,
        AWATER: 1851117,
        INTPTLAT: '+40.2046087',
        INTPTLON: '-100.3671227',
        ELSDLEA: '',
        UNSDLEA: '00178',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9964, 40.5962],
              [-96.9966, 40.5869],
              [-97.0031, 40.587],
              [-96.9964, 40.5962],
            ],
          ],
          [
            [
              [-97.0186, 40.607],
              [-97.0185, 40.6106],
              [-97.0087, 40.6032],
              [-97.0187, 40.6034],
              [-97.0186, 40.607],
            ],
          ],
          [
            [
              [-97.0185, 40.6254],
              [-97.0226, 40.6106],
              [-97.0272, 40.6106],
              [-97.0185, 40.6254],
            ],
          ],
          [
            [
              [-97.1941, 40.6038],
              [-97.1868, 40.7276],
              [-97.0647, 40.6982],
              [-97.0461, 40.6942],
              [-97.0272, 40.6907],
              [-97.0273, 40.6541],
              [-97.0466, 40.6251],
              [-97.0653, 40.5799],
              [-97.0653, 40.5754],
              [-97.0611, 40.5634],
              [-97.1791, 40.5242],
              [-97.1857, 40.5312],
              [-97.1883, 40.5533],
              [-97.1931, 40.5532],
              [-97.2099, 40.5533],
              [-97.1941, 40.6038],
            ],
            [
              [-97.0386, 40.6036],
              [-97.0446, 40.6037],
              [-97.0463, 40.6026],
              [-97.0521, 40.5963],
              [-97.0386, 40.6036],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3169840',
        NAME: 'Dorchester Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258905444,
        AWATER: 1363864,
        INTPTLAT: '+40.6215893',
        INTPTLON: '-097.1260411',
        ELSDLEA: '',
        UNSDLEA: '69840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.2452, 42.4996],
              [-99.2342, 42.4997],
              [-99.2451, 42.4928],
              [-99.2452, 42.4996],
            ],
          ],
          [
            [
              [-99.2459, 42.6955],
              [-99.0883, 42.696],
              [-99.0495, 42.5656],
              [-99.1669, 42.5283],
              [-99.2447, 42.457],
              [-99.2448, 42.4709],
              [-99.2342, 42.4997],
              [-99.2453, 42.5063],
              [-99.2459, 42.6955],
            ],
            [
              [-99.1759, 42.5437],
              [-99.1761, 42.5361],
              [-99.1363, 42.5569],
              [-99.1759, 42.5437],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100082',
        NAME: 'Stuart Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322170736,
        AWATER: 336202,
        INTPTLAT: '+42.6030367',
        INTPTLON: '-099.1595830',
        ELSDLEA: '',
        UNSDLEA: '00082',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0032, 41.5244],
              [-97.9792, 41.5173],
              [-98.0032, 41.5171],
              [-98.0032, 41.5244],
            ],
          ],
          [
            [
              [-98.349, 41.5457],
              [-98.3392, 41.5817],
              [-98.2913, 41.5901],
              [-98.2811, 41.7121],
              [-98.2342, 41.6976],
              [-98.2342, 41.6903],
              [-98.1189, 41.647],
              [-98.023, 41.575],
              [-98.023, 41.5677],
              [-98.0034, 41.5389],
              [-98.0032, 41.5244],
              [-98.0127, 41.5243],
              [-98.0032, 41.5171],
              [-98.1189, 41.4666],
              [-98.1573, 41.4371],
              [-98.1864, 41.4374],
              [-98.2152, 41.4374],
              [-98.2345, 41.4376],
              [-98.2536, 41.4379],
              [-98.3298, 41.5308],
              [-98.3298, 41.5384],
              [-98.349, 41.5457],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100068',
        NAME: 'Cedar Rapids Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490625439,
        AWATER: 1224530,
        INTPTLAT: '+41.5590103',
        INTPTLON: '-098.1743809',
        ELSDLEA: '',
        UNSDLEA: '00068',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-103.3425, 42.446],
              [-103.3332, 42.4456],
              [-103.3229, 42.4451],
              [-103.3425, 42.446],
            ],
          ],
          [
            [
              [-104.053, 43.0006],
              [-103.5434, 43.0009],
              [-103.5051, 43.0008],
              [-103.5046, 42.9604],
              [-103.4804, 42.785],
              [-103.4802, 42.4664],
              [-103.4445, 42.4374],
              [-103.4441, 42.0907],
              [-103.4049, 42.0906],
              [-103.4017, 42.0906],
              [-103.4016, 42.0035],
              [-103.6694, 42.0022],
              [-103.7982, 42.1466],
              [-103.8735, 42.0883],
              [-104.053, 42.087],
              [-104.0528, 42.6118],
              [-104.053, 43.0006],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176800',
        NAME: 'Sioux County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5096409969,
        AWATER: 1947347,
        INTPTLAT: '+42.5230771',
        INTPTLON: '-103.7313161',
        ELSDLEA: '',
        UNSDLEA: '76800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.8535, 41.5119],
            [-102.7916, 41.5759],
            [-102.7917, 41.5819],
            [-102.792, 41.641],
            [-102.7537, 41.6555],
            [-102.7451, 41.6847],
            [-102.7148, 41.6992],
            [-102.6941, 41.7143],
            [-102.6748, 41.7218],
            [-102.6633, 41.7434],
            [-102.6696, 41.7584],
            [-102.6776, 42.0053],
            [-102.6385, 42.0059],
            [-102.6184, 42.0063],
            [-102.5592, 42.007],
            [-102.5401, 42.0071],
            [-102.529, 42.0073],
            [-102.0666, 42.0092],
            [-102.0679, 41.7421],
            [-101.9855, 41.7423],
            [-101.9846, 41.3947],
            [-102.0552, 41.3033],
            [-102.0553, 41.2966],
            [-102.0938, 41.2215],
            [-102.2465, 41.2216],
            [-102.3798, 41.2219],
            [-102.5332, 41.266],
            [-102.5325, 41.3956],
            [-102.6094, 41.3951],
            [-102.7522, 41.4393],
            [-102.8535, 41.5119],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170800',
        NAME: 'Garden County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4599993572,
        AWATER: 71056167,
        INTPTLAT: '+41.6289625',
        INTPTLON: '-102.3563853',
        ELSDLEA: '',
        UNSDLEA: '70800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.2227, 42.0879],
              [-99.0599, 42.146],
              [-99.0242, 42.146],
              [-99.0016, 42.1382],
              [-98.9933, 42.1383],
              [-98.9822, 42.1321],
              [-98.9632, 42.0883],
              [-98.8382, 42.0883],
              [-98.8193, 41.9716],
              [-98.7999, 41.9567],
              [-98.7609, 41.9424],
              [-98.8767, 41.9283],
              [-98.7987, 41.8271],
              [-98.8761, 41.8202],
              [-98.8755, 41.7621],
              [-99.1158, 41.7046],
              [-99.2121, 41.6542],
              [-99.2122, 41.6684],
              [-99.2227, 42.0879],
            ],
          ],
          [
            [
              [-99.2346, 42.1929],
              [-99.1957, 42.1895],
              [-99.1762, 42.1893],
              [-99.1763, 42.1747],
              [-99.1658, 42.1748],
              [-99.147, 42.1459],
              [-99.2347, 42.1311],
              [-99.2346, 42.1929],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104640',
        NAME: 'Burwell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1419631975,
        AWATER: 4318821,
        INTPTLAT: '+41.9209481',
        INTPTLON: '-099.0677573',
        ELSDLEA: '',
        UNSDLEA: '04640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.4406, 41.1348],
            [-98.3061, 41.1332],
            [-98.2966, 41.1333],
            [-98.287, 41.1333],
            [-98.287, 41.1112],
            [-98.287, 41.0898],
            [-98.2871, 41.0608],
            [-98.1729, 41.0606],
            [-98.1303, 40.9915],
            [-98.1668, 40.9581],
            [-98.2136, 40.9318],
            [-98.2826, 40.8687],
            [-98.4444, 40.7863],
            [-98.4358, 40.8834],
            [-98.541, 40.9676],
            [-98.4698, 41.0465],
            [-98.4406, 41.1348],
          ],
          [
            [-98.4263, 40.888],
            [-98.3493, 40.8873],
            [-98.2825, 40.9448],
            [-98.4124, 40.9604],
            [-98.4263, 40.888],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174580',
        NAME: 'Northwest Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644422905,
        AWATER: 5558031,
        INTPTLAT: '+40.9871662',
        INTPTLON: '-098.3555559',
        ELSDLEA: '',
        UNSDLEA: '74580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7219, 40.9168],
            [-98.6651, 40.9602],
            [-98.5505, 40.9605],
            [-98.5462, 40.9605],
            [-98.541, 40.9676],
            [-98.4358, 40.8834],
            [-98.4444, 40.7863],
            [-98.5307, 40.7346],
            [-98.5404, 40.7277],
            [-98.5689, 40.6987],
            [-98.5729, 40.6987],
            [-98.6456, 40.7249],
            [-98.6546, 40.7215],
            [-98.6643, 40.7186],
            [-98.6743, 40.7717],
            [-98.7218, 40.8029],
            [-98.7219, 40.9168],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178940',
        NAME: 'Wood River Rural Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 471005282,
        AWATER: 6244886,
        INTPTLAT: '+40.8406734',
        INTPTLON: '-098.5798772',
        ELSDLEA: '',
        UNSDLEA: '78940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7164, 42.6949],
            [-99.6975, 42.7297],
            [-99.5375, 42.7176],
            [-99.4152, 42.7721],
            [-99.257, 42.8043],
            [-99.2459, 42.6955],
            [-99.2453, 42.5063],
            [-99.2452, 42.4996],
            [-99.2451, 42.4928],
            [-99.2448, 42.4709],
            [-99.2447, 42.457],
            [-99.2346, 42.1929],
            [-99.2347, 42.1311],
            [-99.2346, 42.088],
            [-99.6624, 42.086],
            [-99.6773, 42.6515],
            [-99.619, 42.6953],
            [-99.7164, 42.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176170',
        NAME: 'Rock County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2609096984,
        AWATER: 8855350,
        INTPTLAT: '+42.3947490',
        INTPTLON: '-099.4208512',
        ELSDLEA: '',
        UNSDLEA: '76170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.519, 42.0637],
            [-102.4441, 42.0559],
            [-102.3621, 42.1551],
            [-102.2744, 42.2115],
            [-102.0996, 42.2128],
            [-102.0392, 42.2559],
            [-101.9168, 42.2564],
            [-101.9551, 42.3734],
            [-102.0382, 42.4433],
            [-101.822, 42.443],
            [-101.8306, 42.3289],
            [-101.5757, 42.3295],
            [-101.5127, 42.352],
            [-101.456, 42.2799],
            [-101.4501, 42.2234],
            [-101.47, 42.2077],
            [-101.4266, 42.0922],
            [-101.4259, 41.7431],
            [-101.9855, 41.7423],
            [-102.0679, 41.7421],
            [-102.0666, 42.0092],
            [-102.529, 42.0073],
            [-102.519, 42.0637],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172210',
        NAME: 'Hyannis Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4034401379,
        AWATER: 54150876,
        INTPTLAT: '+42.0481668',
        INTPTLON: '-101.8113166',
        ELSDLEA: '',
        UNSDLEA: '72210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1196, 41.1616],
            [-96.0715, 41.1907],
            [-96.0059, 41.1907],
            [-95.9869, 41.1617],
            [-95.9718, 41.1263],
            [-95.9851, 41.128],
            [-95.9924, 41.1338],
            [-96.1196, 41.1616],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175270',
        NAME: 'Papillion-La Vista Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74783386,
        AWATER: 226556,
        INTPTLAT: '+41.1576154',
        INTPTLON: '-096.0540728',
        ELSDLEA: '',
        UNSDLEA: '75270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3473, 41.9054],
              [-96.3473, 41.9093],
              [-96.3203, 41.8941],
              [-96.3376, 41.8869],
              [-96.3426, 41.9013],
              [-96.3473, 41.9013],
              [-96.3473, 41.9054],
            ],
          ],
          [
            [
              [-96.3474, 41.8943],
              [-96.3474, 41.8905],
              [-96.3572, 41.8869],
              [-96.3474, 41.8943],
            ],
          ],
          [
            [
              [-96.5747, 41.887],
              [-96.5651, 41.887],
              [-96.5651, 41.8832],
              [-96.5747, 41.887],
            ],
          ],
          [
            [
              [-96.5553, 41.8832],
              [-96.5552, 41.8869],
              [-96.3605, 41.8943],
              [-96.3572, 41.8869],
              [-96.3085, 41.7922],
              [-96.3668, 41.7418],
              [-96.3669, 41.7338],
              [-96.3866, 41.7337],
              [-96.4002, 41.7418],
              [-96.4113, 41.7418],
              [-96.415, 41.7418],
              [-96.4151, 41.7501],
              [-96.4056, 41.7709],
              [-96.4154, 41.7709],
              [-96.4154, 41.7647],
              [-96.4152, 41.7634],
              [-96.4192, 41.7563],
              [-96.425, 41.7563],
              [-96.4188, 41.7418],
              [-96.419, 41.7343],
              [-96.4288, 41.7418],
              [-96.4442, 41.7563],
              [-96.5214, 41.7709],
              [-96.5552, 41.771],
              [-96.5654, 41.7709],
              [-96.5743, 41.7711],
              [-96.5553, 41.8832],
            ],
            [
              [-96.425, 41.7563],
              [-96.4311, 41.7647],
              [-96.4311, 41.7627],
              [-96.425, 41.7563],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174640',
        NAME: 'Oakland Craig Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316976846,
        AWATER: 1116455,
        INTPTLAT: '+41.8217781',
        INTPTLON: '-096.4344989',
        ELSDLEA: '',
        UNSDLEA: '74640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3605, 41.8943],
              [-96.3473, 41.9013],
              [-96.3426, 41.9013],
              [-96.3474, 41.8943],
              [-96.3572, 41.8869],
              [-96.3605, 41.8943],
            ],
          ],
          [
            [
              [-96.4002, 41.7418],
              [-96.3866, 41.7337],
              [-96.3669, 41.7287],
              [-96.3669, 41.7314],
              [-96.3669, 41.7338],
              [-96.3668, 41.7418],
              [-96.3085, 41.7922],
              [-96.3572, 41.8869],
              [-96.3474, 41.8905],
              [-96.3376, 41.8869],
              [-96.3203, 41.8941],
              [-96.2219, 41.9537],
              [-96.2071, 42.0091],
              [-96.1292, 41.9651],
              [-96.1621, 41.903],
              [-96.1091, 41.836],
              [-96.0649, 41.7917],
              [-96.1199, 41.6926],
              [-96.0953, 41.6503],
              [-96.2853, 41.6183],
              [-96.2904, 41.6109],
              [-96.3097, 41.5998],
              [-96.3379, 41.6395],
              [-96.3477, 41.6834],
              [-96.4002, 41.7418],
            ],
          ],
          [
            [
              [-96.4188, 41.7418],
              [-96.415, 41.7418],
              [-96.4113, 41.7418],
              [-96.419, 41.7343],
              [-96.4188, 41.7418],
            ],
          ],
          [
            [
              [-96.4192, 41.7563],
              [-96.4152, 41.7634],
              [-96.4056, 41.7709],
              [-96.4151, 41.7501],
              [-96.4192, 41.7563],
            ],
          ],
          [
            [
              [-96.4442, 41.7563],
              [-96.4288, 41.7418],
              [-96.4341, 41.7418],
              [-96.4346, 41.7418],
              [-96.4442, 41.7563],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100071',
        NAME: 'Tekamah-Herman Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 679262117,
        AWATER: 8660660,
        INTPTLAT: '+41.7841539',
        INTPTLON: '-096.2282584',
        ELSDLEA: '',
        UNSDLEA: '00071',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3866, 41.7337],
              [-96.3669, 41.7338],
              [-96.3669, 41.7314],
              [-96.3669, 41.7287],
              [-96.3866, 41.7337],
            ],
          ],
          [
            [
              [-96.4154, 41.7709],
              [-96.4056, 41.7709],
              [-96.4152, 41.7634],
              [-96.4154, 41.7647],
              [-96.4154, 41.7709],
            ],
          ],
          [
            [
              [-96.425, 41.7563],
              [-96.4192, 41.7563],
              [-96.4151, 41.7501],
              [-96.415, 41.7418],
              [-96.4188, 41.7418],
              [-96.425, 41.7563],
            ],
          ],
          [
            [
              [-96.4311, 41.7647],
              [-96.425, 41.7563],
              [-96.4311, 41.7627],
              [-96.4311, 41.7647],
            ],
          ],
          [
            [
              [-96.5903, 41.7542],
              [-96.6035, 41.7656],
              [-96.6046, 41.7675],
              [-96.5743, 41.7711],
              [-96.5654, 41.7709],
              [-96.5552, 41.771],
              [-96.5214, 41.7709],
              [-96.4442, 41.7563],
              [-96.4346, 41.7418],
              [-96.4341, 41.7418],
              [-96.4288, 41.7418],
              [-96.419, 41.7343],
              [-96.4113, 41.7418],
              [-96.4002, 41.7418],
              [-96.3477, 41.6834],
              [-96.3379, 41.6395],
              [-96.4443, 41.5749],
              [-96.4348, 41.4952],
              [-96.4543, 41.4804],
              [-96.4252, 41.491],
              [-96.4248, 41.4589],
              [-96.5063, 41.4804],
              [-96.5303, 41.495],
              [-96.5586, 41.5029],
              [-96.6456, 41.5608],
              [-96.5782, 41.6723],
              [-96.5903, 41.7542],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100114',
        NAME: 'Logan View Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514486692,
        AWATER: 7260306,
        INTPTLAT: '+41.6289017',
        INTPTLON: '-096.4963416',
        ELSDLEA: '',
        UNSDLEA: '00114',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3097, 41.5998],
            [-96.2904, 41.6109],
            [-96.2853, 41.6183],
            [-96.0953, 41.6503],
            [-96.0823, 41.5807],
            [-96.0166, 41.4819],
            [-96.0694, 41.3932],
            [-96.0815, 41.3932],
            [-96.1695, 41.4437],
            [-96.2327, 41.4876],
            [-96.2518, 41.5093],
            [-96.3096, 41.5528],
            [-96.3097, 41.5998],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104100',
        NAME: 'Blair Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390364403,
        AWATER: 3625045,
        INTPTLAT: '+41.5332861',
        INTPTLON: '-096.1637511',
        ELSDLEA: '',
        UNSDLEA: '04100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3592, 40.5575],
              [-96.3592, 40.5552],
              [-96.3592, 40.5519],
              [-96.3687, 40.5519],
              [-96.3592, 40.5575],
            ],
          ],
          [
            [
              [-96.4067, 40.5374],
              [-96.4064, 40.5447],
              [-96.3966, 40.5444],
              [-96.3968, 40.5374],
              [-96.4008, 40.5308],
              [-96.4067, 40.5311],
              [-96.4067, 40.5374],
            ],
          ],
          [
            [
              [-96.455, 40.552],
              [-96.4447, 40.5446],
              [-96.4447, 40.5375],
              [-96.4552, 40.5375],
              [-96.4636, 40.5446],
              [-96.455, 40.552],
            ],
          ],
          [
            [
              [-96.5816, 40.2333],
              [-96.5857, 40.2267],
              [-96.5955, 40.2267],
              [-96.5816, 40.2333],
            ],
          ],
          [
            [
              [-96.6571, 40.487],
              [-96.4555, 40.5304],
              [-96.4446, 40.5228],
              [-96.4448, 40.4359],
              [-96.426, 40.3965],
              [-96.4637, 40.2767],
              [-96.5205, 40.2332],
              [-96.5958, 40.2478],
              [-96.6051, 40.2335],
              [-96.6333, 40.2481],
              [-96.6098, 40.3782],
              [-96.6854, 40.4467],
              [-96.6571, 40.487],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100116',
        NAME: 'Freeman Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450993604,
        AWATER: 3914264,
        INTPTLAT: '+40.3912673',
        INTPTLON: '-096.5468366',
        ELSDLEA: '',
        UNSDLEA: '00116',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1792, 40.1832],
            [-99.1792, 40.1835],
            [-99.1792, 40.1911],
            [-99.0603, 40.1975],
            [-98.9347, 40.2776],
            [-98.9338, 40.2633],
            [-98.9245, 40.2633],
            [-98.8968, 40.2125],
            [-98.8966, 40.2016],
            [-98.8399, 40.1903],
            [-98.7821, 40.1905],
            [-98.7634, 40.1904],
            [-98.7314, 40.1764],
            [-98.7264, 40.0024],
            [-98.8796, 40.0023],
            [-99.1791, 40.002],
            [-99.1792, 40.1832],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100065',
        NAME: 'Franklin Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 884814900,
        AWATER: 458414,
        INTPTLAT: '+40.1108364',
        INTPTLON: '-098.9582015',
        ELSDLEA: '',
        UNSDLEA: '00065',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7314, 40.1764],
            [-98.7261, 40.1764],
            [-98.6604, 40.1693],
            [-98.5007, 40.2194],
            [-98.3965, 40.1764],
            [-98.3638, 40.0677],
            [-98.4152, 40.0025],
            [-98.5045, 40.0023],
            [-98.7264, 40.0024],
            [-98.7314, 40.1764],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175990',
        NAME: 'Red Cloud Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 562213033,
        AWATER: 9394,
        INTPTLAT: '+40.0959113',
        INTPTLON: '-098.5572244',
        ELSDLEA: '',
        UNSDLEA: '75990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7024, 40.7711],
              [-97.7118, 40.7856],
              [-97.4926, 40.7851],
              [-97.4683, 40.7779],
              [-97.5308, 40.6986],
              [-97.7209, 40.6986],
              [-97.7307, 40.7203],
              [-97.7024, 40.7711],
            ],
          ],
          [
            [
              [-97.7307, 40.7203],
              [-97.7307, 40.7131],
              [-97.7447, 40.7118],
              [-97.7447, 40.7132],
              [-97.7307, 40.7203],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173500',
        NAME: 'McCool Junction Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199388337,
        AWATER: 1909874,
        INTPTLAT: '+40.7395499',
        INTPTLON: '-097.6015362',
        ELSDLEA: '',
        UNSDLEA: '73500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.95, 40.8289],
            [-97.9306, 40.9593],
            [-97.8933, 41.0106],
            [-97.8885, 41.0106],
            [-97.884, 41.0104],
            [-97.8262, 40.9884],
            [-97.8258, 40.829],
            [-97.9401, 40.7854],
            [-97.95, 40.8289],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171370',
        NAME: 'Hampton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196523517,
        AWATER: 488227,
        INTPTLAT: '+40.9021238',
        INTPTLON: '-097.8807030',
        ELSDLEA: '',
        UNSDLEA: '71370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7215, 41.2464],
              [-97.7139, 41.2427],
              [-97.7139, 41.2418],
              [-97.7215, 41.2464],
            ],
          ],
          [
            [
              [-97.7295, 41.2502],
              [-97.7235, 41.25],
              [-97.7215, 41.2464],
              [-97.7279, 41.2464],
              [-97.7294, 41.2483],
              [-97.7295, 41.2502],
            ],
          ],
          [
            [
              [-97.9738, 41.2637],
              [-97.9641, 41.2637],
              [-97.9641, 41.2675],
              [-97.9641, 41.2783],
              [-97.962, 41.2783],
              [-97.8302, 41.3364],
              [-97.7769, 41.2928],
              [-97.7334, 41.2544],
              [-97.7338, 41.2542],
              [-97.7788, 41.2002],
              [-97.7725, 41.2039],
              [-97.7619, 41.2166],
              [-97.7525, 41.2134],
              [-97.7525, 41.2072],
              [-97.7435, 41.1968],
              [-97.7521, 41.1772],
              [-97.7428, 41.1661],
              [-97.733, 41.1628],
              [-97.7519, 41.1555],
              [-97.7313, 40.9885],
              [-97.7412, 40.9885],
              [-97.8262, 40.9884],
              [-97.884, 41.0104],
              [-97.8885, 41.0106],
              [-97.8933, 41.0106],
              [-97.9032, 41.0176],
              [-97.9124, 41.0278],
              [-97.9124, 41.0321],
              [-97.9406, 41.032],
              [-97.9055, 41.1767],
              [-97.9445, 41.2346],
              [-97.9738, 41.2637],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100123',
        NAME: 'High Plains Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567996901,
        AWATER: 7261359,
        INTPTLAT: '+41.1483980',
        INTPTLON: '-097.8310649',
        ELSDLEA: '',
        UNSDLEA: '00123',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0516, 40.9596],
            [-101.8803, 40.9596],
            [-101.8048, 41.0029],
            [-101.8048, 41.0174],
            [-101.8048, 41.0248],
            [-101.7859, 41.0031],
            [-101.5367, 41.0175],
            [-101.4405, 40.9597],
            [-101.4213, 40.9598],
            [-101.3349, 40.9308],
            [-101.3207, 40.7847],
            [-101.343, 40.7058],
            [-101.3452, 40.668],
            [-101.345, 40.6538],
            [-101.3539, 40.6393],
            [-101.4021, 40.698],
            [-101.7233, 40.6998],
            [-101.9692, 40.6182],
            [-102.0515, 40.6464],
            [-102.0514, 40.7496],
            [-102.0516, 40.9596],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100157',
        NAME: 'Perkins County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2095900276,
        AWATER: 2542514,
        INTPTLAT: '+40.8347635',
        INTPTLON: '-101.6959456',
        ELSDLEA: '',
        UNSDLEA: '00157',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9855, 41.7423],
            [-101.4259, 41.7431],
            [-101.4065, 41.7432],
            [-101.3576, 41.7285],
            [-101.3299, 41.5689],
            [-101.4067, 41.5689],
            [-101.4071, 41.4384],
            [-101.3666, 41.3955],
            [-101.3877, 41.3955],
            [-101.4073, 41.3954],
            [-101.5579, 41.3807],
            [-101.6154, 41.3954],
            [-101.9846, 41.3947],
            [-101.9855, 41.7423],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103210',
        NAME: 'Arthur County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1952915410,
        AWATER: 7800137,
        INTPTLAT: '+41.5823690',
        INTPTLON: '-101.6551178',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.3349, 40.4229],
              [-98.3161, 40.4153],
              [-98.306, 40.4154],
              [-98.306, 40.4082],
              [-98.2971, 40.4008],
              [-98.2874, 40.3938],
              [-98.2834, 40.372],
              [-98.3062, 40.3566],
              [-98.3062, 40.3612],
              [-98.3158, 40.3649],
              [-98.3349, 40.4229],
            ],
          ],
          [
            [
              [-98.5307, 40.7346],
              [-98.5307, 40.7277],
              [-98.5404, 40.7277],
              [-98.5307, 40.7346],
            ],
          ],
          [
            [
              [-98.5729, 40.6987],
              [-98.5689, 40.6987],
              [-98.5563, 40.6987],
              [-98.573, 40.6915],
              [-98.5729, 40.6987],
            ],
          ],
          [
            [
              [-98.5539, 40.6987],
              [-98.4296, 40.6696],
              [-98.3449, 40.6764],
              [-98.2223, 40.6117],
              [-98.2412, 40.539],
              [-98.2783, 40.5387],
              [-98.2782, 40.5256],
              [-98.2921, 40.43],
              [-98.2874, 40.4155],
              [-98.306, 40.4154],
              [-98.3349, 40.4301],
              [-98.3495, 40.4373],
              [-98.3539, 40.4344],
              [-98.4018, 40.4013],
              [-98.4685, 40.4374],
              [-98.4678, 40.4604],
              [-98.5535, 40.4882],
              [-98.5732, 40.5679],
              [-98.5923, 40.5786],
              [-98.5539, 40.6987],
            ],
            [
              [-98.4498, 40.5984],
              [-98.3641, 40.5701],
              [-98.3824, 40.623],
              [-98.4498, 40.5984],
            ],
          ],
          [
            [
              [-98.724, 40.4954],
              [-98.724, 40.5],
              [-98.724, 40.5097],
              [-98.693, 40.5463],
              [-98.6863, 40.5463],
              [-98.5825, 40.5533],
              [-98.6009, 40.5388],
              [-98.6101, 40.5388],
              [-98.6196, 40.5097],
              [-98.6192, 40.4157],
              [-98.629, 40.4157],
              [-98.6667, 40.4156],
              [-98.6764, 40.4083],
              [-98.638, 40.4013],
              [-98.6291, 40.3868],
              [-98.7051, 40.3794],
              [-98.7124, 40.3866],
              [-98.7124, 40.3939],
              [-98.7241, 40.4011],
              [-98.7241, 40.4016],
              [-98.7241, 40.4083],
              [-98.6957, 40.4084],
              [-98.7052, 40.4229],
              [-98.7108, 40.4229],
              [-98.7241, 40.4373],
              [-98.7241, 40.452],
              [-98.7241, 40.4662],
              [-98.724, 40.4954],
            ],
            [
              [-98.6577, 40.5315],
              [-98.6481, 40.5351],
              [-98.6481, 40.5387],
              [-98.6577, 40.5315],
            ],
          ],
          [
            [
              [-98.739, 40.4373],
              [-98.7241, 40.4373],
              [-98.724, 40.4228],
              [-98.7335, 40.423],
              [-98.739, 40.4373],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3102770',
        NAME: 'Adams Central Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 860262755,
        AWATER: 1371018,
        INTPTLAT: '+40.5322538',
        INTPTLON: '-098.4447969',
        ELSDLEA: '',
        UNSDLEA: '02770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-101.456, 42.2799],
              [-101.3726, 42.2082],
              [-101.4501, 42.2234],
              [-101.456, 42.2799],
            ],
          ],
          [
            [
              [-101.4501, 42.2234],
              [-101.4505, 42.2077],
              [-101.47, 42.2077],
              [-101.4501, 42.2234],
            ],
          ],
          [
            [
              [-101.5497, 42.9023],
              [-101.5504, 42.916],
              [-101.5252, 42.9358],
              [-101.5151, 42.9618],
              [-101.5142, 42.9971],
              [-101.4956, 42.9971],
              [-101.4563, 42.9557],
              [-101.4946, 42.8278],
              [-101.4352, 42.786],
              [-101.2473, 42.7858],
              [-101.208, 42.7],
              [-100.8931, 42.6986],
              [-100.8304, 42.7291],
              [-100.866, 42.8277],
              [-100.9446, 42.8281],
              [-100.8474, 42.9983],
              [-100.1984, 42.9987],
              [-100.1774, 42.8353],
              [-100.1832, 42.4334],
              [-100.1677, 42.1587],
              [-100.1676, 42.0858],
              [-100.3656, 42.0867],
              [-100.6359, 42.0871],
              [-100.6359, 42.1172],
              [-100.7088, 42.1919],
              [-100.709, 42.2494],
              [-100.5278, 42.2412],
              [-100.5958, 42.3505],
              [-100.693, 42.4227],
              [-100.8679, 42.4265],
              [-100.8585, 42.3576],
              [-100.9656, 42.3467],
              [-101.0251, 42.4102],
              [-101.101, 42.3533],
              [-101.2125, 42.353],
              [-101.2762, 42.4411],
              [-101.5127, 42.4119],
              [-101.5127, 42.352],
              [-101.5757, 42.3295],
              [-101.8306, 42.3289],
              [-101.822, 42.443],
              [-102.0382, 42.4433],
              [-102.0667, 42.4435],
              [-102.0664, 42.6834],
              [-101.95, 42.6163],
              [-101.7555, 42.6162],
              [-101.6962, 42.7606],
              [-101.5979, 42.7592],
              [-101.6137, 42.9002],
              [-101.5497, 42.9023],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178020',
        NAME: 'Valentine Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8830940822,
        AWATER: 82108639,
        INTPTLAT: '+42.5497414',
        INTPTLON: '-100.9376282',
        ELSDLEA: '',
        UNSDLEA: '78020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1984, 42.9987],
            [-99.8499, 42.998],
            [-99.534, 42.998],
            [-99.3555, 42.9982],
            [-99.2545, 42.9981],
            [-99.254, 42.9049],
            [-99.2077, 42.8231],
            [-99.257, 42.8043],
            [-99.4152, 42.7721],
            [-99.5375, 42.7176],
            [-99.6975, 42.7297],
            [-99.7164, 42.6949],
            [-99.8622, 42.769],
            [-99.9689, 42.7725],
            [-100.1774, 42.8353],
            [-100.1984, 42.9987],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172480',
        NAME: 'Keya Paha County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2063526085,
        AWATER: 2217819,
        INTPTLAT: '+42.8741814',
        INTPTLON: '-099.7009922',
        ELSDLEA: '',
        UNSDLEA: '72480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.4369, 40.6193],
              [-99.4267, 40.6193],
              [-99.4267, 40.6128],
              [-99.4369, 40.6193],
            ],
          ],
          [
            [
              [-99.4743, 40.649],
              [-99.4696, 40.649],
              [-99.4744, 40.642],
              [-99.4743, 40.649],
            ],
          ],
          [
            [
              [-99.4804, 40.6566],
              [-99.4743, 40.6565],
              [-99.4743, 40.649],
              [-99.4804, 40.649],
              [-99.4804, 40.6566],
            ],
          ],
          [
            [
              [-99.4934, 40.6567],
              [-99.4869, 40.6491],
              [-99.4934, 40.6491],
              [-99.4934, 40.6567],
            ],
          ],
          [
            [
              [-99.5788, 40.7648],
              [-99.5967, 40.8585],
              [-99.5776, 40.8727],
              [-99.4264, 40.8372],
              [-99.4554, 40.6704],
              [-99.5183, 40.676],
              [-99.524, 40.6704],
              [-99.5123, 40.6641],
              [-99.5123, 40.6568],
              [-99.5063, 40.6635],
              [-99.4934, 40.6633],
              [-99.4934, 40.6567],
              [-99.5303, 40.641],
              [-99.5981, 40.6793],
              [-99.5788, 40.7648],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175060',
        NAME: 'Overton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326186830,
        AWATER: 5500635,
        INTPTLAT: '+40.7645587',
        INTPTLON: '-099.5125288',
        ELSDLEA: '',
        UNSDLEA: '75060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7245, 41.3248],
              [-96.7092, 41.3295],
              [-96.6884, 41.3292],
              [-96.6982, 41.3267],
              [-96.7078, 41.3218],
              [-96.7246, 41.3218],
              [-96.7245, 41.3248],
            ],
          ],
          [
            [
              [-96.7368, 41.3425],
              [-96.727, 41.3423],
              [-96.7271, 41.3363],
              [-96.7368, 41.3363],
              [-96.7368, 41.3425],
            ],
          ],
          [
            [
              [-96.7448, 41.0967],
              [-96.731, 41.1038],
              [-96.7295, 41.1038],
              [-96.7214, 41.1038],
              [-96.7264, 41.0893],
              [-96.7295, 41.0893],
              [-96.7448, 41.0967],
            ],
          ],
          [
            [
              [-96.7456, 41.1326],
              [-96.7461, 41.1224],
              [-96.7552, 41.1224],
              [-96.7552, 41.1258],
              [-96.7456, 41.1326],
            ],
          ],
          [
            [
              [-96.7597, 41.1185],
              [-96.7552, 41.1185],
              [-96.7551, 41.1039],
              [-96.7597, 41.1185],
            ],
          ],
          [
            [
              [-96.9491, 41.0243],
              [-96.9394, 41.0243],
              [-96.93, 41.0244],
              [-96.9396, 41.0169],
              [-96.9491, 41.0243],
            ],
          ],
          [
            [
              [-96.8705, 41.2277],
              [-96.8606, 41.2277],
              [-96.8604, 41.2352],
              [-96.8852, 41.2866],
              [-96.8897, 41.2936],
              [-96.8999, 41.2904],
              [-96.9085, 41.3651],
              [-96.8899, 41.3579],
              [-96.885, 41.3614],
              [-96.7894, 41.3073],
              [-96.7846, 41.3144],
              [-96.7562, 41.3287],
              [-96.7561, 41.3217],
              [-96.7456, 41.3176],
              [-96.7457, 41.3142],
              [-96.7457, 41.314],
              [-96.7461, 41.3111],
              [-96.7754, 41.2714],
              [-96.8129, 41.2349],
              [-96.803, 41.133],
              [-96.7937, 41.1292],
              [-96.784, 41.1329],
              [-96.7744, 41.1257],
              [-96.7742, 41.1185],
              [-96.7841, 41.1184],
              [-96.7937, 41.1106],
              [-96.9086, 41.0754],
              [-96.9202, 41.0461],
              [-96.9491, 41.0243],
              [-96.9681, 41.0169],
              [-97.044, 40.9738],
              [-97.0526, 40.9883],
              [-97.0883, 41.0174],
              [-97.1014, 41.0321],
              [-97.1393, 41.0249],
              [-97.1767, 41.0826],
              [-97.0814, 41.1408],
              [-97.0326, 41.2637],
              [-96.8987, 41.2642],
              [-96.8705, 41.2277],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100003',
        NAME: 'East Butler Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 747878418,
        AWATER: 4383083,
        INTPTLAT: '+41.1573950',
        INTPTLON: '-096.9571975',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.1772, 41.2775],
              [-96.1007, 41.2197],
              [-96.0815, 41.2193],
              [-96.0715, 41.1907],
              [-96.1196, 41.1616],
              [-96.1676, 41.1616],
              [-96.2343, 41.2048],
              [-96.1772, 41.2775],
            ],
          ],
          [
            [
              [-96.2631, 41.212],
              [-96.2563, 41.2048],
              [-96.2631, 41.2013],
              [-96.2631, 41.2053],
              [-96.2631, 41.212],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173740',
        NAME: 'Millard Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94569073,
        AWATER: 1899495,
        INTPTLAT: '+41.2143713',
        INTPTLON: '-096.1542587',
        ELSDLEA: '',
        UNSDLEA: '73740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9718, 41.1263],
            [-95.9869, 41.1617],
            [-95.8419, 41.1763],
            [-95.866, 41.1024],
            [-95.8631, 41.0937],
            [-95.9718, 41.1263],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103810',
        NAME: 'Bellevue Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71478846,
        AWATER: 2218653,
        INTPTLAT: '+41.1346169',
        INTPTLON: '-095.9189233',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1676, 41.1616],
            [-96.1196, 41.1616],
            [-95.9924, 41.1338],
            [-95.9851, 41.128],
            [-95.9718, 41.1263],
            [-95.8631, 41.0937],
            [-95.8795, 41.0533],
            [-96.068, 41.0606],
            [-96.2345, 41.0043],
            [-96.2342, 41.0669],
            [-96.1676, 41.1616],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175630',
        NAME: 'South Sarpy District 46',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235169277,
        AWATER: 9224472,
        INTPTLAT: '+41.0832561',
        INTPTLON: '-096.0887756',
        ELSDLEA: '',
        UNSDLEA: '75630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.0549, 41.6846],
              [-97.0547, 41.6919],
              [-97.0501, 41.6918],
              [-97.0551, 41.6812],
              [-97.0549, 41.6846],
            ],
          ],
          [
            [
              [-97.0595, 41.7065],
              [-97.0501, 41.7065],
              [-97.05, 41.6991],
              [-97.0595, 41.7065],
            ],
          ],
          [
            [
              [-97.0779, 41.8735],
              [-97.0728, 41.8735],
              [-97.0678, 41.8736],
              [-97.0678, 41.8659],
              [-97.0779, 41.8659],
              [-97.0779, 41.8693],
              [-97.0779, 41.8735],
            ],
          ],
          [
            [
              [-97.0779, 41.8591],
              [-97.0584, 41.8513],
              [-97.0583, 41.8447],
              [-97.0631, 41.8447],
              [-97.0779, 41.8573],
              [-97.0779, 41.8591],
            ],
          ],
          [
            [
              [-97.0778, 41.7502],
              [-97.0778, 41.7575],
              [-97.0727, 41.8302],
              [-97.0584, 41.8301],
              [-97.0584, 41.8308],
              [-97.0582, 41.8369],
              [-97.0583, 41.8447],
              [-97.0481, 41.8513],
              [-96.9422, 41.8298],
              [-96.8025, 41.7426],
              [-96.8142, 41.7182],
              [-96.8142, 41.7138],
              [-96.8429, 41.6915],
              [-96.8429, 41.6845],
              [-96.8096, 41.6263],
              [-96.906, 41.5974],
              [-96.9252, 41.612],
              [-96.9347, 41.612],
              [-96.993, 41.6122],
              [-96.9929, 41.6193],
              [-97.0206, 41.6268],
              [-97.0206, 41.6304],
              [-97.0209, 41.6629],
              [-97.0209, 41.6848],
              [-97.0268, 41.7023],
              [-97.0303, 41.7066],
              [-97.0496, 41.7136],
              [-97.0594, 41.7136],
              [-97.0778, 41.7502],
            ],
          ],
          [
            [
              [-97.0887, 41.6302],
              [-97.0786, 41.6265],
              [-97.0786, 41.6221],
              [-97.0888, 41.6265],
              [-97.0887, 41.6302],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100183',
        NAME: 'Howells-Dodge Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435602130,
        AWATER: 230483,
        INTPTLAT: '+41.7192687',
        INTPTLON: '-096.9346828',
        ELSDLEA: '',
        UNSDLEA: '00183',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3516, 41.1537],
            [-100.3516, 41.1619],
            [-100.3505, 41.263],
            [-100.2355, 41.2199],
            [-100.2358, 41.205],
            [-100.2365, 41.176],
            [-100.2718, 40.9524],
            [-100.3205, 40.9385],
            [-100.3674, 40.8292],
            [-100.4319, 40.8293],
            [-100.47, 40.8424],
            [-100.5083, 40.883],
            [-100.5092, 41.0285],
            [-100.4671, 41.133],
            [-100.3516, 41.1537],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104290',
        NAME: 'Brady Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 676133003,
        AWATER: 3233834,
        INTPTLAT: '+41.0299481',
        INTPTLON: '-100.3647037',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0083, 41.0759],
              [-100.0083, 41.0615],
              [-100.0267, 41.0614],
              [-100.0083, 41.0759],
            ],
          ],
          [
            [
              [-100.0366, 41.0614],
              [-100.0267, 41.0614],
              [-100.0366, 41.0547],
              [-100.0366, 41.0614],
            ],
          ],
          [
            [
              [-100.0269, 41.111],
              [-100.0269, 41.1046],
              [-100.0365, 41.1042],
              [-100.0551, 41.1045],
              [-100.0269, 41.111],
            ],
          ],
          [
            [
              [-100.3674, 40.8292],
              [-100.3205, 40.9385],
              [-100.2718, 40.9524],
              [-100.2365, 41.176],
              [-100.1197, 41.1911],
              [-100.0455, 41.1535],
              [-100.0458, 41.1339],
              [-100.0656, 41.1262],
              [-100.0659, 41.1046],
              [-100.0659, 41.098],
              [-100.047, 41.0694],
              [-100.0472, 41.0547],
              [-100.0366, 41.0547],
              [-100.0081, 41.0473],
              [-99.986, 41.0473],
              [-100.0052, 40.9977],
              [-100.0431, 40.9392],
              [-100.0387, 40.9316],
              [-100.0431, 40.917],
              [-100.0998, 40.83],
              [-100.1477, 40.8294],
              [-100.1479, 40.8405],
              [-100.1383, 40.8439],
              [-100.1381, 40.8585],
              [-100.148, 40.8584],
              [-100.148, 40.8441],
              [-100.148, 40.8439],
              [-100.1573, 40.8513],
              [-100.1672, 40.8513],
              [-100.1666, 40.8292],
              [-100.1859, 40.8016],
              [-100.2479, 40.8577],
              [-100.3674, 40.8292],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171100',
        NAME: 'Gothenburg Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 811859991,
        AWATER: 864736,
        INTPTLAT: '+40.9958160',
        INTPTLON: '-100.1765507',
        ELSDLEA: '',
        UNSDLEA: '71100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.9063, 41.3944],
              [-100.8681, 41.3794],
              [-100.849, 41.394],
              [-100.7715, 41.3937],
              [-100.7717, 41.3506],
              [-100.8114, 41.3505],
              [-100.8495, 41.3361],
              [-100.9059, 41.3508],
              [-100.9063, 41.3944],
            ],
          ],
          [
            [
              [-101.1007, 41.3951],
              [-100.9447, 41.3945],
              [-100.9059, 41.3508],
              [-100.8496, 41.3059],
              [-100.8682, 41.2085],
              [-100.8396, 41.1151],
              [-100.8869, 41.0471],
              [-100.9059, 40.9595],
              [-101.0195, 40.9606],
              [-101.0763, 41.0045],
              [-101.0986, 41.2093],
              [-101.1357, 41.2789],
              [-101.1007, 41.3951],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171820',
        NAME: 'Hershey Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 909059511,
        AWATER: 1632781,
        INTPTLAT: '+41.1925353',
        INTPTLON: '-100.9720819',
        ELSDLEA: '',
        UNSDLEA: '71820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.5649, 40.2329],
              [-95.5649, 40.2256],
              [-95.5698, 40.2257],
              [-95.5649, 40.2329],
            ],
          ],
          [
            [
              [-95.5786, 40.2475],
              [-95.5597, 40.2329],
              [-95.5649, 40.2329],
              [-95.5786, 40.2475],
            ],
          ],
          [
            [
              [-95.7665, 40.3128],
              [-95.7844, 40.3053],
              [-95.7891, 40.3053],
              [-95.7665, 40.3128],
            ],
          ],
          [
            [
              [-96.067, 40.3054],
              [-96.0576, 40.3054],
              [-96.0575, 40.2978],
              [-96.067, 40.2978],
              [-96.067, 40.3054],
            ],
          ],
          [
            [
              [-96.0795, 40.3263],
              [-96.0747, 40.3263],
              [-96.0743, 40.3263],
              [-96.0793, 40.3201],
              [-96.0795, 40.3263],
            ],
          ],
          [
            [
              [-96.0954, 40.3128],
              [-96.0955, 40.3056],
              [-96.1049, 40.3093],
              [-96.0954, 40.3128],
            ],
          ],
          [
            [
              [-96.2552, 40.2621],
              [-96.199, 40.2186],
              [-96.1893, 40.2114],
              [-96.1801, 40.2113],
              [-96.1417, 40.2624],
              [-96.1323, 40.2696],
              [-96.1137, 40.2839],
              [-96.0994, 40.2839],
              [-96.0668, 40.2765],
              [-96.0668, 40.2699],
              [-96.0757, 40.2699],
              [-95.9979, 40.2614],
              [-95.917, 40.2902],
              [-95.8981, 40.3045],
              [-95.8886, 40.3047],
              [-95.884, 40.3192],
              [-95.8791, 40.3193],
              [-95.86, 40.2614],
              [-95.8034, 40.2834],
              [-95.8034, 40.2907],
              [-95.7286, 40.2837],
              [-95.6905, 40.2911],
              [-95.6249, 40.3493],
              [-95.5935, 40.3059],
              [-95.5888, 40.2477],
              [-95.6446, 40.1647],
              [-95.7758, 40.154],
              [-95.8221, 40.0005],
              [-95.9729, 40.0006],
              [-96.067, 40.1235],
              [-96.2329, 40.1314],
              [-96.2463, 40.1931],
              [-96.2367, 40.1931],
              [-96.2366, 40.2038],
              [-96.2462, 40.2039],
              [-96.2552, 40.2621],
            ],
            [
              [-96.199, 40.2186],
              [-96.2045, 40.2116],
              [-96.1991, 40.2152],
              [-96.199, 40.2186],
            ],
          ],
          [
            [
              [-96.3118, 40.2552],
              [-96.3022, 40.2552],
              [-96.3023, 40.2477],
              [-96.3118, 40.2552],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100179',
        NAME: 'Humboldt Table Rock Steinauer Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1062472625,
        AWATER: 2393612,
        INTPTLAT: '+40.1815469',
        INTPTLON: '-095.9071909',
        ELSDLEA: '',
        UNSDLEA: '00179',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.7804, 41.0467],
              [-99.7676, 41.032],
              [-99.7869, 41.0322],
              [-99.7804, 41.0467],
            ],
          ],
          [
            [
              [-99.8637, 41.0179],
              [-99.8537, 41.0179],
              [-99.8446, 41.0093],
              [-99.8447, 41.0033],
              [-99.8637, 41.0179],
            ],
          ],
          [
            [
              [-99.8353, 40.8591],
              [-99.8353, 40.8669],
              [-99.8352, 40.8803],
              [-99.8353, 40.9889],
              [-99.8373, 40.9889],
              [-99.8447, 41.0033],
              [-99.8153, 41.0323],
              [-99.7869, 41.0322],
              [-99.7675, 41.0174],
              [-99.7677, 40.9742],
              [-99.7677, 40.9674],
              [-99.6924, 40.8869],
              [-99.5967, 40.8585],
              [-99.5788, 40.7648],
              [-99.5981, 40.6793],
              [-99.7375, 40.6559],
              [-99.8826, 40.7427],
              [-99.8919, 40.7428],
              [-99.897, 40.7429],
              [-99.8353, 40.8591],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172810',
        NAME: 'Lexington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 642344849,
        AWATER: 3157234,
        INTPTLAT: '+40.8097814',
        INTPTLON: '-099.7385741',
        ELSDLEA: '',
        UNSDLEA: '72810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8526, 41.8795],
              [-97.8621, 41.8726],
              [-97.8682, 41.8726],
              [-97.8526, 41.8795],
            ],
          ],
          [
            [
              [-97.8718, 41.8653],
              [-97.8621, 41.8653],
              [-97.8523, 41.8496],
              [-97.8613, 41.8496],
              [-97.8718, 41.8653],
            ],
          ],
          [
            [
              [-97.8818, 41.8794],
              [-97.8719, 41.8795],
              [-97.8718, 41.8795],
              [-97.8682, 41.8726],
              [-97.8719, 41.8725],
              [-97.8818, 41.8794],
            ],
          ],
          [
            [
              [-98.2956, 41.8858],
              [-98.2956, 41.9004],
              [-98.2956, 41.915],
              [-98.0844, 41.8939],
              [-97.94, 41.916],
              [-97.9304, 41.916],
              [-97.9159, 41.9161],
              [-97.8995, 41.9161],
              [-97.8916, 41.9161],
              [-97.8912, 41.8794],
              [-97.8911, 41.8725],
              [-97.8845, 41.8652],
              [-97.8814, 41.8145],
              [-97.8906, 41.7421],
              [-97.8308, 41.6947],
              [-97.8603, 41.6557],
              [-97.9846, 41.568],
              [-97.9753, 41.5681],
              [-97.9752, 41.5609],
              [-98.0034, 41.5389],
              [-98.023, 41.5677],
              [-98.0135, 41.575],
              [-98.023, 41.575],
              [-98.1189, 41.647],
              [-98.2342, 41.6903],
              [-98.2342, 41.6976],
              [-98.2811, 41.7121],
              [-98.2958, 41.7412],
              [-98.2956, 41.8858],
            ],
            [
              [-97.9846, 41.568],
              [-97.9944, 41.568],
              [-97.9943, 41.5609],
              [-97.9846, 41.568],
            ],
            [
              [-98.004, 41.5894],
              [-98.004, 41.5823],
              [-97.9943, 41.5824],
              [-98.004, 41.5894],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3102820',
        NAME: 'Boone Central Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1078169472,
        AWATER: 516357,
        INTPTLAT: '+41.7737927',
        INTPTLON: '-098.0635900',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-102.7428, 43],
              [-102.7128, 43],
              [-102.713, 42.9775],
              [-102.7428, 43],
            ],
          ],
          [
            [
              [-103.2046, 42.6761],
              [-103.2167, 42.6549],
              [-103.2347, 42.6549],
              [-103.2046, 42.6761],
            ],
          ],
          [
            [
              [-103.5051, 43.0008],
              [-103.0009, 43.0007],
              [-102.7589, 43],
              [-102.713, 42.9659],
              [-102.6943, 42.958],
              [-102.7097, 42.9052],
              [-102.7019, 42.8942],
              [-102.6938, 42.8569],
              [-102.793, 42.8445],
              [-102.7737, 42.6997],
              [-102.8322, 42.6188],
              [-102.8912, 42.5679],
              [-102.9105, 42.5534],
              [-102.9306, 42.582],
              [-103.0079, 42.5686],
              [-103.1452, 42.6406],
              [-103.1452, 42.6662],
              [-103.2046, 42.6761],
              [-103.2868, 42.7263],
              [-103.2858, 42.7994],
              [-103.3558, 42.8438],
              [-103.2272, 42.9438],
              [-103.5046, 42.9604],
              [-103.5051, 43.0008],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104980',
        NAME: 'Chadron Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1863701456,
        AWATER: 1581986,
        INTPTLAT: '+42.8187334',
        INTPTLON: '-103.0424781',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.1774, 42.8353],
            [-99.9689, 42.7725],
            [-99.8622, 42.769],
            [-99.7164, 42.6949],
            [-99.619, 42.6953],
            [-99.6773, 42.6515],
            [-99.6624, 42.086],
            [-99.687, 42.0861],
            [-100.0106, 42.0856],
            [-100.0916, 42.1587],
            [-100.1295, 42.1587],
            [-100.1677, 42.1587],
            [-100.1832, 42.4334],
            [-100.1774, 42.8353],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3102790',
        NAME: 'Ainsworth Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3027941224,
        AWATER: 9741438,
        INTPTLAT: '+42.3595618',
        INTPTLON: '-099.9239198',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9846, 41.3947],
            [-101.6154, 41.3954],
            [-101.5579, 41.3807],
            [-101.5767, 41.2795],
            [-101.481, 41.265],
            [-101.5367, 41.0175],
            [-101.7859, 41.0031],
            [-101.8048, 41.0248],
            [-101.8614, 41.0385],
            [-101.8452, 41.1345],
            [-101.9026, 41.1648],
            [-101.8928, 41.2575],
            [-102.0552, 41.3033],
            [-101.9846, 41.3947],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174760',
        NAME: 'Ogallala Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1359576123,
        AWATER: 6001418,
        INTPTLAT: '+41.1914622',
        INTPTLON: '-101.6910850',
        ELSDLEA: '',
        UNSDLEA: '74760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.2465, 41.2216],
            [-102.0938, 41.2215],
            [-102.0553, 41.2966],
            [-101.8928, 41.2575],
            [-101.9026, 41.1648],
            [-101.8452, 41.1345],
            [-101.8614, 41.0385],
            [-101.8048, 41.0248],
            [-101.8048, 41.0174],
            [-101.8048, 41.0029],
            [-101.8803, 40.9596],
            [-102.0516, 40.9596],
            [-102.0517, 41.0024],
            [-102.2402, 41.0023],
            [-102.2465, 41.2216],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100110',
        NAME: 'South Platte Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 949578383,
        AWATER: 3568428,
        INTPTLAT: '+41.1155054',
        INTPTLON: '-102.0469177',
        ELSDLEA: '',
        UNSDLEA: '00110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.5579, 41.3807],
            [-101.4073, 41.3954],
            [-101.3877, 41.3955],
            [-101.3666, 41.3955],
            [-101.2697, 41.3949],
            [-101.2697, 41.3084],
            [-101.2699, 41.2937],
            [-101.2704, 41.0474],
            [-101.2488, 40.9601],
            [-101.3349, 40.9308],
            [-101.4213, 40.9598],
            [-101.4405, 40.9597],
            [-101.5367, 41.0175],
            [-101.481, 41.265],
            [-101.5767, 41.2795],
            [-101.5579, 41.3807],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175360',
        NAME: 'Paxton Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 994198583,
        AWATER: 1597828,
        INTPTLAT: '+41.1736963',
        INTPTLON: '-101.3842831',
        ELSDLEA: '',
        UNSDLEA: '75360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0552, 41.3033],
            [-101.8928, 41.2575],
            [-102.0553, 41.2966],
            [-102.0552, 41.3033],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3199997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 1727979,
        AWATER: 115690107,
        INTPTLAT: '+41.2387410',
        INTPTLON: '-101.8471170',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3084, 41.6253],
            [-100.1039, 41.6522],
            [-100.088, 41.644],
            [-100.0975, 41.6086],
            [-100.0205, 41.5675],
            [-100.0399, 41.4587],
            [-99.9623, 41.3935],
            [-100.0829, 41.3934],
            [-100.0638, 41.264],
            [-100.1191, 41.2204],
            [-100.139, 41.2202],
            [-100.1193, 41.2059],
            [-100.1197, 41.1911],
            [-100.2365, 41.176],
            [-100.2358, 41.205],
            [-100.2355, 41.2199],
            [-100.3505, 41.263],
            [-100.4468, 41.3212],
            [-100.443, 41.4515],
            [-100.3187, 41.4713],
            [-100.3084, 41.6253],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100073',
        NAME: 'Arnold Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1229015309,
        AWATER: 124346,
        INTPTLAT: '+41.4336086',
        INTPTLON: '-100.2089995',
        ELSDLEA: '',
        UNSDLEA: '00073',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.3349, 40.4301],
              [-98.306, 40.4154],
              [-98.3161, 40.4153],
              [-98.3349, 40.4229],
              [-98.3349, 40.4301],
            ],
          ],
          [
            [
              [-98.3112, 40.3503],
              [-98.3062, 40.3566],
              [-98.2834, 40.372],
              [-98.2874, 40.3938],
              [-98.2874, 40.4012],
              [-98.2874, 40.4085],
              [-98.2874, 40.4155],
              [-98.2921, 40.43],
              [-98.2782, 40.5256],
              [-98.2783, 40.5387],
              [-98.2412, 40.539],
              [-98.2033, 40.539],
              [-98.0803, 40.4667],
              [-97.9381, 40.4375],
              [-97.9191, 40.4259],
              [-97.9003, 40.423],
              [-97.8812, 40.3505],
              [-97.9535, 40.3217],
              [-97.8739, 40.2227],
              [-97.8678, 40.2169],
              [-97.877, 40.2053],
              [-97.8769, 40.1996],
              [-97.8765, 40.1907],
              [-97.8839, 40.1879],
              [-97.8942, 40.1911],
              [-97.9052, 40.1981],
              [-97.9104, 40.2054],
              [-97.9211, 40.2053],
              [-97.9248, 40.2053],
              [-97.9152, 40.1516],
              [-97.9152, 40.1471],
              [-97.9341, 40.1254],
              [-98.0763, 40.1114],
              [-98.2737, 40.133],
              [-98.3212, 40.2417],
              [-98.3212, 40.2343],
              [-98.3397, 40.2198],
              [-98.3112, 40.3503],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100122',
        NAME: 'South Central Nebraska School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1289528743,
        AWATER: 2219079,
        INTPTLAT: '+40.3060077',
        INTPTLON: '-098.1154055',
        ELSDLEA: '',
        UNSDLEA: '00122',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.6051, 40.2335],
              [-96.5958, 40.2478],
              [-96.5816, 40.2333],
              [-96.5955, 40.2267],
              [-96.6051, 40.2335],
            ],
          ],
          [
            [
              [-96.8182, 40.415],
              [-96.8182, 40.4166],
              [-96.7991, 40.4146],
              [-96.8182, 40.415],
            ],
          ],
          [
            [
              [-96.9164, 40.2484],
              [-96.7609, 40.2991],
              [-96.7891, 40.4074],
              [-96.7891, 40.4146],
              [-96.7891, 40.4307],
              [-96.7893, 40.4452],
              [-96.7608, 40.4509],
              [-96.7609, 40.4439],
              [-96.7609, 40.4364],
              [-96.6854, 40.4467],
              [-96.6098, 40.3782],
              [-96.6333, 40.2481],
              [-96.7845, 40.1758],
              [-96.9164, 40.1828],
              [-96.9071, 40.1828],
              [-96.9071, 40.1901],
              [-96.9163, 40.1901],
              [-96.9164, 40.2484],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103600',
        NAME: 'Beatrice Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474840907,
        AWATER: 3650327,
        INTPTLAT: '+40.3049415',
        INTPTLON: '-096.7420512',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.0272, 40.6106],
              [-97.0226, 40.6106],
              [-97.0185, 40.6106],
              [-97.0186, 40.607],
              [-97.0272, 40.6106],
            ],
          ],
          [
            [
              [-97.0521, 40.5963],
              [-97.0463, 40.6026],
              [-97.0446, 40.6037],
              [-97.0386, 40.6036],
              [-97.0521, 40.5963],
            ],
          ],
          [
            [
              [-97.2546, 40.5164],
              [-97.2547, 40.5242],
              [-97.2547, 40.5439],
              [-97.2465, 40.5416],
              [-97.236, 40.5414],
              [-97.2253, 40.5318],
              [-97.2171, 40.5451],
              [-97.2099, 40.5533],
              [-97.1931, 40.5532],
              [-97.1883, 40.5533],
              [-97.1857, 40.5312],
              [-97.1791, 40.5242],
              [-97.0611, 40.5634],
              [-97.0653, 40.5754],
              [-97.0653, 40.5799],
              [-97.0274, 40.564],
              [-97.0177, 40.5692],
              [-97.0187, 40.6034],
              [-97.0087, 40.6032],
              [-97.0084, 40.5962],
              [-97.008, 40.587],
              [-97.0031, 40.587],
              [-96.9966, 40.5869],
              [-96.865, 40.5305],
              [-96.7984, 40.5231],
              [-96.7893, 40.5016],
              [-96.7893, 40.499],
              [-96.7986, 40.4797],
              [-96.7892, 40.4654],
              [-96.7893, 40.4452],
              [-96.7891, 40.4307],
              [-96.9229, 40.4075],
              [-97.1133, 40.4222],
              [-97.1133, 40.4297],
              [-97.1224, 40.4298],
              [-97.1178, 40.4222],
              [-97.1793, 40.437],
              [-97.2075, 40.4482],
              [-97.2171, 40.4517],
              [-97.2171, 40.4549],
              [-97.2263, 40.4588],
              [-97.2264, 40.4588],
              [-97.2546, 40.5164],
            ],
          ],
          [
            [
              [-96.7798, 40.4727],
              [-96.7799, 40.4654],
              [-96.7892, 40.4654],
              [-96.7798, 40.4727],
            ],
          ],
          [
            [
              [-96.7991, 40.4146],
              [-96.7891, 40.4146],
              [-96.7891, 40.4074],
              [-96.7991, 40.4146],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178720',
        NAME: 'Wilber-Clatonia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516134437,
        AWATER: 3346863,
        INTPTLAT: '+40.4870283',
        INTPTLON: '-097.0119829',
        ELSDLEA: '',
        UNSDLEA: '78720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0243, 41.2197],
            [-96.0815, 41.2193],
            [-96.1007, 41.2197],
            [-96.0243, 41.2197],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178660',
        NAME: 'Westside Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29559299,
        AWATER: 135800,
        INTPTLAT: '+41.2441806',
        INTPTLON: '-096.0519781',
        ELSDLEA: '',
        UNSDLEA: '78660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2915, 41.3644],
            [-96.2439, 41.3631],
            [-96.1773, 41.3065],
            [-96.1772, 41.2775],
            [-96.2343, 41.2048],
            [-96.2535, 41.2049],
            [-96.2563, 41.2048],
            [-96.2631, 41.212],
            [-96.2631, 41.2053],
            [-96.2631, 41.2013],
            [-96.2825, 41.2191],
            [-96.2915, 41.3644],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3170110',
        NAME: 'Elkhorn Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121541181,
        AWATER: 890415,
        INTPTLAT: '+41.2858243',
        INTPTLON: '-096.2336927',
        ELSDLEA: '',
        UNSDLEA: '70110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3656, 42.0867],
            [-100.1676, 42.0858],
            [-100.1677, 42.1587],
            [-100.1295, 42.1587],
            [-100.0916, 42.1587],
            [-100.0106, 42.0856],
            [-99.687, 42.0861],
            [-99.6866, 41.7984],
            [-99.6917, 41.7984],
            [-99.7217, 41.7984],
            [-99.8522, 41.7911],
            [-99.9437, 41.7105],
            [-100.0979, 41.6973],
            [-100.1039, 41.6522],
            [-100.3084, 41.6253],
            [-100.3625, 41.7396],
            [-100.3656, 42.0867],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176380',
        NAME: 'Sandhills Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2439312014,
        AWATER: 9824316,
        INTPTLAT: '+41.8932875',
        INTPTLON: '-100.0381445',
        ELSDLEA: '',
        UNSDLEA: '76380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.8585, 42.3576],
            [-100.709, 42.2494],
            [-100.7088, 42.1919],
            [-100.869, 42.2193],
            [-100.8677, 42.0882],
            [-100.6915, 42.0875],
            [-100.6762, 42.1169],
            [-100.6359, 42.1172],
            [-100.6359, 42.0871],
            [-100.7497, 42],
            [-100.8461, 42.0006],
            [-100.8425, 41.7404],
            [-101.4065, 41.7432],
            [-101.4259, 41.7431],
            [-101.4266, 42.0922],
            [-101.47, 42.2077],
            [-101.4505, 42.2077],
            [-101.4501, 42.2234],
            [-101.3726, 42.2082],
            [-101.456, 42.2799],
            [-101.5127, 42.352],
            [-101.5127, 42.4119],
            [-101.2762, 42.4411],
            [-101.2125, 42.353],
            [-101.101, 42.3533],
            [-101.0251, 42.4102],
            [-100.9656, 42.3467],
            [-100.8585, 42.3576],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174040',
        NAME: 'Mullen Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3703547416,
        AWATER: 6366450,
        INTPTLAT: '+42.0654001',
        INTPTLON: '-101.1496968',
        ELSDLEA: '',
        UNSDLEA: '74040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.5648, 41.9075],
              [-96.5692, 41.9076],
              [-96.5746, 41.9077],
              [-96.5792, 41.9076],
              [-96.5746, 41.9129],
              [-96.5648, 41.9075],
            ],
          ],
          [
            [
              [-96.7004, 42.0108],
              [-96.6711, 42.0353],
              [-96.6757, 42.0395],
              [-96.551, 42.1045],
              [-96.5017, 42.0963],
              [-96.502, 42.0899],
              [-96.4722, 42.0898],
              [-96.3854, 42.1043],
              [-96.3761, 42.0895],
              [-96.3857, 42.0316],
              [-96.5023, 41.9883],
              [-96.5218, 41.9956],
              [-96.5552, 41.9276],
              [-96.5604, 41.9274],
              [-96.5938, 41.922],
              [-96.7004, 42.0108],
            ],
          ],
          [
            [
              [-96.71, 42.0243],
              [-96.7053, 42.0244],
              [-96.7052, 42.0238],
              [-96.7101, 42.0167],
              [-96.71, 42.0243],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103440',
        NAME: 'Bancroft-Rosalie Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311946691,
        AWATER: 1452439,
        INTPTLAT: '+42.0347803',
        INTPTLON: '-096.5482568',
        ELSDLEA: '',
        UNSDLEA: '03440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.6382, 42.7193],
              [-98.6183, 42.7291],
              [-98.6184, 42.7262],
              [-98.5404, 42.7412],
              [-98.5344, 42.7117],
              [-98.5991, 42.6915],
              [-98.6197, 42.697],
              [-98.6289, 42.697],
              [-98.6382, 42.7193],
            ],
          ],
          [
            [
              [-98.745, 42.7618],
              [-98.741, 42.7542],
              [-98.745, 42.7542],
              [-98.745, 42.7618],
            ],
          ],
          [
            [
              [-99.1759, 42.5437],
              [-99.1363, 42.5569],
              [-99.1761, 42.5361],
              [-99.1759, 42.5437],
            ],
          ],
          [
            [
              [-99.2451, 42.4928],
              [-99.2342, 42.4997],
              [-99.2448, 42.4709],
              [-99.2451, 42.4928],
            ],
          ],
          [
            [
              [-99.2453, 42.5063],
              [-99.2342, 42.4997],
              [-99.2452, 42.4996],
              [-99.2453, 42.5063],
            ],
          ],
          [
            [
              [-99.257, 42.8043],
              [-99.2077, 42.8231],
              [-98.9546, 42.8941],
              [-98.8819, 42.8414],
              [-98.76, 42.8264],
              [-98.745, 42.7618],
              [-98.7556, 42.7253],
              [-98.7454, 42.7253],
              [-98.8342, 42.5227],
              [-98.8668, 42.3354],
              [-98.7687, 42.3215],
              [-98.9631, 42.2155],
              [-98.9933, 42.1383],
              [-99.0016, 42.1382],
              [-99.0242, 42.146],
              [-99.0599, 42.146],
              [-99.1762, 42.1893],
              [-99.1957, 42.1895],
              [-99.2346, 42.1929],
              [-99.2447, 42.457],
              [-99.1669, 42.5283],
              [-99.0495, 42.5656],
              [-99.0883, 42.696],
              [-99.2459, 42.6955],
              [-99.257, 42.8043],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100172',
        NAME: 'West Holt Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2384027199,
        AWATER: 6596453,
        INTPTLAT: '+42.5174983',
        INTPTLON: '-099.0016985',
        ELSDLEA: '',
        UNSDLEA: '00172',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.7717, 41.3076],
              [-100.7155, 41.3075],
              [-100.7152, 41.2929],
              [-100.7197, 41.2929],
              [-100.7291, 41.293],
              [-100.7347, 41.293],
              [-100.7347, 41.2852],
              [-100.7348, 41.2785],
              [-100.7717, 41.3076],
            ],
          ],
          [
            [
              [-101.4065, 41.7432],
              [-100.8425, 41.7404],
              [-100.7117, 41.7398],
              [-100.7142, 41.3935],
              [-100.7715, 41.3937],
              [-100.849, 41.394],
              [-100.8681, 41.3794],
              [-100.9063, 41.3944],
              [-100.9447, 41.3945],
              [-101.1007, 41.3951],
              [-101.2697, 41.3949],
              [-101.3666, 41.3955],
              [-101.4071, 41.4384],
              [-101.4067, 41.5689],
              [-101.3299, 41.5689],
              [-101.3576, 41.7285],
              [-101.4065, 41.7432],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173560',
        NAME: 'McPherson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2126302895,
        AWATER: 2525251,
        INTPTLAT: '+41.5566800',
        INTPTLON: '-101.0294327',
        ELSDLEA: '',
        UNSDLEA: '73560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8212, 40.037],
              [-97.8213, 40.0311],
              [-97.8402, 40.0238],
              [-97.8212, 40.037],
            ],
          ],
          [
            [
              [-98.3965, 40.1764],
              [-98.3397, 40.2198],
              [-98.3212, 40.2343],
              [-98.3212, 40.2417],
              [-98.2737, 40.133],
              [-98.0763, 40.1114],
              [-97.9341, 40.1254],
              [-97.844, 40.0747],
              [-97.8704, 40.0021],
              [-97.9884, 40.0022],
              [-98.4152, 40.0025],
              [-98.3638, 40.0677],
              [-98.3965, 40.1764],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100029',
        NAME: 'Superior Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 704736580,
        AWATER: 516930,
        INTPTLAT: '+40.0771683',
        INTPTLON: '-098.1750914',
        ELSDLEA: '',
        UNSDLEA: '00029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.3305, 40.2565],
              [-99.3109, 40.2565],
              [-99.3109, 40.2526],
              [-99.3304, 40.2526],
              [-99.3305, 40.2565],
            ],
          ],
          [
            [
              [-99.3776, 40.2419],
              [-99.3774, 40.2491],
              [-99.3775, 40.2564],
              [-99.3683, 40.2429],
              [-99.3683, 40.242],
              [-99.3776, 40.2419],
            ],
          ],
          [
            [
              [-99.6097, 40.0233],
              [-99.6036, 40.0234],
              [-99.6095, 40.0164],
              [-99.6097, 40.0233],
            ],
          ],
          [
            [
              [-99.7179, 40.3863],
              [-99.71, 40.3864],
              [-99.71, 40.38],
              [-99.7167, 40.3801],
              [-99.7179, 40.3863],
            ],
          ],
          [
            [
              [-99.7179, 40.3863],
              [-99.7273, 40.3863],
              [-99.7284, 40.3937],
              [-99.7179, 40.3863],
            ],
          ],
          [
            [
              [-100.0229, 40.1622],
              [-100.013, 40.1695],
              [-100.0129, 40.1762],
              [-99.9294, 40.1756],
              [-99.7622, 40.2634],
              [-99.7607, 40.3074],
              [-99.7609, 40.3144],
              [-99.7814, 40.3435],
              [-99.768, 40.3435],
              [-99.7273, 40.3863],
              [-99.7167, 40.3801],
              [-99.7172, 40.3726],
              [-99.7176, 40.3654],
              [-99.6995, 40.3729],
              [-99.595, 40.3512],
              [-99.4991, 40.351],
              [-99.4828, 40.3509],
              [-99.4813, 40.3434],
              [-99.4811, 40.3293],
              [-99.4813, 40.3076],
              [-99.4567, 40.26],
              [-99.414, 40.26],
              [-99.4053, 40.2637],
              [-99.3868, 40.2636],
              [-99.3867, 40.2564],
              [-99.3872, 40.2491],
              [-99.3965, 40.2491],
              [-99.4052, 40.2491],
              [-99.4052, 40.2344],
              [-99.4053, 40.2199],
              [-99.4053, 40.2128],
              [-99.4052, 40.1815],
              [-99.4052, 40.176],
              [-99.4141, 40.0019],
              [-99.5807, 40.0019],
              [-99.5629, 40.0456],
              [-99.5909, 40.0234],
              [-99.6006, 40.0234],
              [-99.6036, 40.0234],
              [-99.6097, 40.0233],
              [-99.6197, 40.0233],
              [-99.6095, 40.0164],
              [-99.6094, 40.0019],
              [-99.8322, 40.0016],
              [-99.9577, 40.0017],
              [-99.9575, 40.0221],
              [-99.9896, 40.0305],
              [-100.0045, 40.0304],
              [-100.0223, 40.0387],
              [-100.0223, 40.0448],
              [-100.013, 40.0596],
              [-100.013, 40.0675],
              [-100.0228, 40.0742],
              [-100.0228, 40.0818],
              [-100.0228, 40.0892],
              [-100.0148, 40.1112],
              [-100.0148, 40.118],
              [-100.0227, 40.133],
              [-100.0228, 40.1403],
              [-100.0229, 40.1436],
              [-100.0229, 40.1622],
            ],
          ],
          [
            [
              [-99.6117, 40.1552],
              [-99.6024, 40.1477],
              [-99.6116, 40.1187],
              [-99.6117, 40.1552],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100109',
        NAME: 'Southern Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1506276184,
        AWATER: 2075345,
        INTPTLAT: '+40.1592170',
        INTPTLON: '-099.6788024',
        ELSDLEA: '',
        UNSDLEA: '00109',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.2832, 40.2344],
              [-99.2733, 40.2343],
              [-99.2734, 40.2198],
              [-99.2831, 40.2199],
              [-99.2832, 40.2344],
            ],
          ],
          [
            [
              [-99.4506, 40.6627],
              [-99.4454, 40.6561],
              [-99.4506, 40.6561],
              [-99.4506, 40.6627],
            ],
          ],
          [
            [
              [-99.4553, 40.6053],
              [-99.4603, 40.5908],
              [-99.4643, 40.5908],
              [-99.4553, 40.6053],
            ],
          ],
          [
            [
              [-99.4546, 40.3932],
              [-99.4179, 40.496],
              [-99.4367, 40.561],
              [-99.4363, 40.5687],
              [-99.4547, 40.5761],
              [-99.4544, 40.5836],
              [-99.4545, 40.5908],
              [-99.4359, 40.5981],
              [-99.4266, 40.5982],
              [-99.4267, 40.6128],
              [-99.4267, 40.6193],
              [-99.4367, 40.6273],
              [-99.427, 40.6561],
              [-99.4175, 40.6562],
              [-99.4176, 40.6417],
              [-99.4071, 40.6422],
              [-99.3989, 40.6501],
              [-99.3899, 40.6522],
              [-99.3805, 40.6522],
              [-99.3805, 40.6505],
              [-99.2366, 40.6125],
              [-99.227, 40.6125],
              [-99.1794, 40.6122],
              [-99.2558, 40.5324],
              [-99.2555, 40.5166],
              [-99.2557, 40.4961],
              [-99.2368, 40.4376],
              [-99.2369, 40.4308],
              [-99.237, 40.4231],
              [-99.237, 40.4163],
              [-99.2558, 40.4088],
              [-99.2853, 40.409],
              [-99.3035, 40.387],
              [-99.3132, 40.3732],
              [-99.3032, 40.3732],
              [-99.3029, 40.3582],
              [-99.3205, 40.3506],
              [-99.3494, 40.2564],
              [-99.3494, 40.2565],
              [-99.359, 40.2637],
              [-99.3682, 40.2637],
              [-99.3868, 40.2636],
              [-99.4053, 40.2637],
              [-99.4136, 40.2636],
              [-99.414, 40.26],
              [-99.4567, 40.26],
              [-99.4526, 40.3215],
              [-99.4813, 40.3076],
              [-99.4811, 40.3293],
              [-99.4719, 40.3434],
              [-99.4813, 40.3434],
              [-99.4828, 40.3509],
              [-99.4546, 40.3932],
            ],
            [
              [-99.3987, 40.6055],
              [-99.3974, 40.6126],
              [-99.3985, 40.6127],
              [-99.3987, 40.6055],
            ],
          ],
          [
            [
              [-99.4934, 40.6491],
              [-99.4869, 40.6491],
              [-99.4804, 40.649],
              [-99.4743, 40.649],
              [-99.4744, 40.642],
              [-99.4934, 40.6491],
            ],
          ],
          [
            [
              [-99.5183, 40.676],
              [-99.5183, 40.6708],
              [-99.524, 40.6704],
              [-99.5183, 40.676],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3171940',
        NAME: 'Holdrege Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556739333,
        AWATER: 102335,
        INTPTLAT: '+40.4639312',
        INTPTLON: '-099.3509528',
        ELSDLEA: '',
        UNSDLEA: '71940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.3474, 41.8943],
              [-96.3426, 41.9013],
              [-96.3376, 41.8869],
              [-96.3474, 41.8905],
              [-96.3474, 41.8943],
            ],
          ],
          [
            [
              [-96.5803, 41.8942],
              [-96.5746, 41.9015],
              [-96.5746, 41.9077],
              [-96.5692, 41.9076],
              [-96.5639, 41.9041],
              [-96.5648, 41.8915],
              [-96.5552, 41.8869],
              [-96.5553, 41.8832],
              [-96.5651, 41.8832],
              [-96.5651, 41.887],
              [-96.5747, 41.887],
              [-96.5803, 41.8942],
            ],
          ],
          [
            [
              [-96.5938, 41.922],
              [-96.5604, 41.9274],
              [-96.5552, 41.9276],
              [-96.5218, 41.9956],
              [-96.5023, 41.9883],
              [-96.3857, 42.0316],
              [-96.3761, 42.0895],
              [-96.3367, 42.0892],
              [-96.3272, 42.0826],
              [-96.3175, 42.0892],
              [-96.272, 42.0892],
              [-96.2779, 42.058],
              [-96.2071, 42.0091],
              [-96.2219, 41.9537],
              [-96.3203, 41.8941],
              [-96.3473, 41.9093],
              [-96.3473, 41.9054],
              [-96.3473, 41.9013],
              [-96.3605, 41.8943],
              [-96.5552, 41.8869],
              [-96.5648, 41.9075],
              [-96.5746, 41.9129],
              [-96.5792, 41.9076],
              [-96.5842, 41.9078],
              [-96.5842, 41.9085],
              [-96.5938, 41.922],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173210',
        NAME: 'Lyons-Decatur Northeast Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398396864,
        AWATER: 4593029,
        INTPTLAT: '+41.9704340',
        INTPTLON: '-096.3764583',
        ELSDLEA: '',
        UNSDLEA: '73210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2871, 41.0608],
            [-98.2502, 41.1115],
            [-98.1739, 41.155],
            [-98.1123, 41.2644],
            [-98.0789, 41.2726],
            [-98.0686, 41.2727],
            [-97.9738, 41.2637],
            [-97.9445, 41.2346],
            [-97.9055, 41.1767],
            [-97.9406, 41.032],
            [-97.979, 41.0389],
            [-98.1303, 40.9915],
            [-98.1729, 41.0606],
            [-98.2871, 41.0608],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3104920',
        NAME: 'Central City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 603591188,
        AWATER: 13316855,
        INTPTLAT: '+41.1401839',
        INTPTLON: '-098.0689078',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7279, 41.2464],
              [-97.7215, 41.2464],
              [-97.7139, 41.2418],
              [-97.7279, 41.2464],
            ],
          ],
          [
            [
              [-97.7338, 41.2542],
              [-97.7295, 41.2502],
              [-97.7294, 41.2483],
              [-97.7619, 41.2166],
              [-97.7725, 41.2039],
              [-97.7725, 41.2038],
              [-97.7762, 41.2024],
              [-97.7788, 41.2002],
              [-97.7338, 41.2542],
            ],
          ],
          [
            [
              [-97.9456, 41.4815],
              [-97.9454, 41.4885],
              [-97.9449, 41.4957],
              [-97.7282, 41.5463],
              [-97.7158, 41.6128],
              [-97.702, 41.6127],
              [-97.6966, 41.6127],
              [-97.658, 41.5984],
              [-97.6386, 41.5913],
              [-97.6291, 41.5839],
              [-97.6096, 41.5839],
              [-97.6188, 41.4971],
              [-97.5418, 41.4676],
              [-97.5598, 41.3424],
              [-97.5826, 41.3348],
              [-97.5883, 41.3338],
              [-97.6244, 41.2577],
              [-97.6751, 41.23],
              [-97.6941, 41.2356],
              [-97.7139, 41.2427],
              [-97.7215, 41.2464],
              [-97.7235, 41.25],
              [-97.7334, 41.2544],
              [-97.7769, 41.2928],
              [-97.8302, 41.3364],
              [-97.811, 41.3903],
              [-97.9456, 41.4815],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100129',
        NAME: 'Twin Rivers Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761467327,
        AWATER: 17451134,
        INTPTLAT: '+41.4183712',
        INTPTLON: '-097.7023852',
        ELSDLEA: '',
        UNSDLEA: '00129',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.2986, 40.536],
              [-96.2987, 40.5355],
              [-96.2989, 40.5355],
              [-96.2986, 40.536],
            ],
          ],
          [
            [
              [-96.305, 40.5374],
              [-96.3015, 40.5374],
              [-96.3015, 40.5369],
              [-96.3018, 40.5365],
              [-96.305, 40.5374],
            ],
          ],
          [
            [
              [-96.4447, 40.5446],
              [-96.4352, 40.5375],
              [-96.4447, 40.5375],
              [-96.4447, 40.5446],
            ],
          ],
          [
            [
              [-96.4552, 40.5375],
              [-96.4447, 40.5375],
              [-96.4555, 40.5304],
              [-96.4552, 40.5375],
            ],
          ],
          [
            [
              [-96.4446, 40.5228],
              [-96.4258, 40.523],
              [-96.4067, 40.5229],
              [-96.4008, 40.5308],
              [-96.3968, 40.5374],
              [-96.3966, 40.5444],
              [-96.4064, 40.5447],
              [-96.4166, 40.5447],
              [-96.4104, 40.5374],
              [-96.4067, 40.5374],
              [-96.4067, 40.5311],
              [-96.4067, 40.5292],
              [-96.4448, 40.5665],
              [-96.456, 40.581],
              [-96.4256, 40.577],
              [-96.4255, 40.5749],
              [-96.3877, 40.5809],
              [-96.3735, 40.573],
              [-96.3687, 40.5519],
              [-96.3592, 40.5519],
              [-96.3551, 40.5517],
              [-96.3497, 40.5513],
              [-96.3221, 40.5374],
              [-96.3407, 40.5298],
              [-96.341, 40.5229],
              [-96.2934, 40.5229],
              [-96.2934, 40.5159],
              [-96.2837, 40.5159],
              [-96.2739, 40.516],
              [-96.2553, 40.4068],
              [-96.3062, 40.3924],
              [-96.3691, 40.378],
              [-96.3881, 40.3709],
              [-96.426, 40.3965],
              [-96.4448, 40.4359],
              [-96.4446, 40.5228],
            ],
          ],
          [
            [
              [-96.4533, 40.6245],
              [-96.4638, 40.6245],
              [-96.4638, 40.6314],
              [-96.4533, 40.6245],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177190',
        NAME: 'Sterling Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298409580,
        AWATER: 497420,
        INTPTLAT: '+40.4721674',
        INTPTLON: '-096.3593698',
        ELSDLEA: '',
        UNSDLEA: '77190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.8577, 42.6698],
              [-97.8522, 42.6698],
              [-97.8523, 42.6601],
              [-97.8577, 42.6698],
            ],
          ],
          [
            [
              [-97.8775, 42.648],
              [-97.8775, 42.641],
              [-97.8872, 42.6481],
              [-97.8775, 42.648],
            ],
          ],
          [
            [
              [-98.2177, 42.8523],
              [-98.1867, 42.8409],
              [-98.1874, 42.8285],
              [-98.1825, 42.84],
              [-98.1531, 42.839],
              [-98.1302, 42.8223],
              [-98.0131, 42.7622],
              [-97.9035, 42.8008],
              [-97.7988, 42.8159],
              [-97.7792, 42.6985],
              [-97.8478, 42.6698],
              [-97.8478, 42.6735],
              [-97.8478, 42.6765],
              [-97.8577, 42.6787],
              [-97.8577, 42.673],
              [-97.8577, 42.6698],
              [-97.8872, 42.6481],
              [-97.9156, 42.6406],
              [-97.9156, 42.6334],
              [-97.9251, 42.637],
              [-97.9448, 42.6625],
              [-97.9348, 42.6664],
              [-97.8972, 42.6693],
              [-97.9344, 42.6929],
              [-97.9735, 42.6915],
              [-97.9739, 42.6696],
              [-98.105, 42.6861],
              [-98.3051, 42.7604],
              [-98.2177, 42.8523],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3174370',
        NAME: 'Niobrara Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481223641,
        AWATER: 16269041,
        INTPTLAT: '+42.7345995',
        INTPTLON: '-097.9870716',
        ELSDLEA: '',
        UNSDLEA: '74370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2345, 41.1046],
              [-97.2298, 41.1046],
              [-97.2344, 41.0973],
              [-97.2344, 41.0977],
              [-97.2345, 41.1046],
            ],
          ],
          [
            [
              [-97.2635, 41.1049],
              [-97.2537, 41.1051],
              [-97.2534, 41.0903],
              [-97.2635, 41.0903],
              [-97.2635, 41.1049],
            ],
          ],
          [
            [
              [-97.2773, 41.0687],
              [-97.2723, 41.0612],
              [-97.2772, 41.0612],
              [-97.2773, 41.0687],
            ],
          ],
          [
            [
              [-97.2824, 41.0831],
              [-97.2725, 41.0902],
              [-97.2635, 41.0903],
              [-97.2635, 41.0867],
              [-97.2677, 41.083],
              [-97.2778, 41.0757],
              [-97.2823, 41.0792],
              [-97.2914, 41.0794],
              [-97.2824, 41.0831],
            ],
          ],
          [
            [
              [-97.3206, 41.0693],
              [-97.301, 41.0689],
              [-97.3107, 41.0613],
              [-97.3204, 41.0614],
              [-97.3206, 41.0693],
            ],
          ],
          [
            [
              [-97.3299, 41.0759],
              [-97.3206, 41.0693],
              [-97.3299, 41.0614],
              [-97.3299, 41.0759],
            ],
          ],
          [
            [
              [-97.3365, 41.0834],
              [-97.3299, 41.0834],
              [-97.3299, 41.0759],
              [-97.3365, 41.0834],
            ],
          ],
          [
            [
              [-97.3541, 41.1194],
              [-97.3495, 41.1194],
              [-97.3541, 41.1169],
              [-97.3541, 41.1194],
            ],
          ],
          [
            [
              [-97.4069, 41.1086],
              [-97.402, 41.1086],
              [-97.4068, 41.1049],
              [-97.4069, 41.1086],
            ],
          ],
          [
            [
              [-97.4839, 41.365],
              [-97.3496, 41.3891],
              [-97.3497, 41.3803],
              [-97.3499, 41.3803],
              [-97.3682, 41.3662],
              [-97.3682, 41.359],
              [-97.3681, 41.2791],
              [-97.2632, 41.1634],
              [-97.273, 41.1343],
              [-97.2825, 41.1344],
              [-97.311, 41.1049],
              [-97.311, 41.1076],
              [-97.311, 41.1082],
              [-97.311, 41.1085],
              [-97.311, 41.1089],
              [-97.311, 41.1194],
              [-97.3111, 41.1236],
              [-97.3112, 41.1272],
              [-97.3206, 41.1194],
              [-97.3303, 41.1157],
              [-97.3495, 41.1194],
              [-97.3496, 41.127],
              [-97.3541, 41.1194],
              [-97.3543, 41.1088],
              [-97.3495, 41.1088],
              [-97.3537, 41.0977],
              [-97.3874, 41.105],
              [-97.402, 41.1086],
              [-97.4069, 41.1117],
              [-97.4117, 41.1117],
              [-97.4117, 41.1049],
              [-97.4068, 41.1049],
              [-97.4162, 41.0905],
              [-97.4257, 41.0832],
              [-97.4358, 41.0832],
              [-97.4451, 41.0972],
              [-97.4647, 41.134],
              [-97.4839, 41.365],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176590',
        NAME: 'Shelby Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435813764,
        AWATER: 720621,
        INTPTLAT: '+41.2283217',
        INTPTLON: '-097.3776314',
        ELSDLEA: '',
        UNSDLEA: '76590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.3035, 40.387],
              [-99.3032, 40.3732],
              [-99.3132, 40.3732],
              [-99.3035, 40.387],
            ],
          ],
          [
            [
              [-99.3899, 40.6577],
              [-99.3899, 40.653],
              [-99.3899, 40.6522],
              [-99.3989, 40.6501],
              [-99.3899, 40.6577],
            ],
          ],
          [
            [
              [-99.4367, 40.6273],
              [-99.4267, 40.6193],
              [-99.4369, 40.6193],
              [-99.4367, 40.6273],
            ],
          ],
          [
            [
              [-99.4554, 40.6628],
              [-99.4506, 40.6627],
              [-99.4506, 40.6561],
              [-99.4554, 40.6561],
              [-99.4554, 40.6628],
            ],
          ],
          [
            [
              [-99.4644, 40.5837],
              [-99.4544, 40.5836],
              [-99.4547, 40.5761],
              [-99.4644, 40.5837],
            ],
          ],
          [
            [
              [-99.4743, 40.6565],
              [-99.4696, 40.649],
              [-99.4743, 40.649],
              [-99.4743, 40.6565],
            ],
          ],
          [
            [
              [-99.4813, 40.3434],
              [-99.4719, 40.3434],
              [-99.4811, 40.3293],
              [-99.4813, 40.3434],
            ],
          ],
          [
            [
              [-99.4813, 40.3076],
              [-99.4526, 40.3215],
              [-99.4567, 40.26],
              [-99.4813, 40.3076],
            ],
          ],
          [
            [
              [-99.4839, 40.6708],
              [-99.4839, 40.6633],
              [-99.4804, 40.6566],
              [-99.4804, 40.649],
              [-99.4869, 40.6491],
              [-99.4934, 40.6567],
              [-99.4934, 40.6633],
              [-99.4839, 40.6708],
            ],
          ],
          [
            [
              [-99.524, 40.6704],
              [-99.5183, 40.6708],
              [-99.5122, 40.6713],
              [-99.5123, 40.6641],
              [-99.524, 40.6704],
            ],
          ],
          [
            [
              [-99.5303, 40.641],
              [-99.4934, 40.6567],
              [-99.4934, 40.6491],
              [-99.4744, 40.642],
              [-99.4554, 40.6338],
              [-99.4455, 40.6338],
              [-99.4555, 40.6271],
              [-99.4557, 40.6195],
              [-99.442, 40.6194],
              [-99.4369, 40.6193],
              [-99.4267, 40.6128],
              [-99.4266, 40.5982],
              [-99.4359, 40.5981],
              [-99.4545, 40.5908],
              [-99.4603, 40.5908],
              [-99.4553, 40.6053],
              [-99.4643, 40.5908],
              [-99.4644, 40.5837],
              [-99.4547, 40.5761],
              [-99.4363, 40.5687],
              [-99.4367, 40.561],
              [-99.4179, 40.496],
              [-99.4546, 40.3932],
              [-99.4828, 40.3509],
              [-99.4991, 40.351],
              [-99.595, 40.3512],
              [-99.6059, 40.4812],
              [-99.5309, 40.5175],
              [-99.5303, 40.641],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3172990',
        NAME: 'Loomis Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 386303406,
        AWATER: 212821,
        INTPTLAT: '+40.4708285',
        INTPTLON: '-099.4975496',
        ELSDLEA: '',
        UNSDLEA: '72990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.7338, 41.2542],
              [-97.7334, 41.2544],
              [-97.7235, 41.25],
              [-97.7295, 41.2502],
              [-97.7338, 41.2542],
            ],
          ],
          [
            [
              [-97.7428, 41.1661],
              [-97.7137, 41.1774],
              [-97.733, 41.1628],
              [-97.7428, 41.1661],
            ],
          ],
          [
            [
              [-97.7619, 41.2166],
              [-97.7294, 41.2483],
              [-97.7279, 41.2464],
              [-97.7139, 41.2418],
              [-97.7139, 41.2427],
              [-97.6941, 41.2356],
              [-97.6751, 41.23],
              [-97.6244, 41.2577],
              [-97.5883, 41.3338],
              [-97.5826, 41.3348],
              [-97.5598, 41.3424],
              [-97.4839, 41.365],
              [-97.4647, 41.134],
              [-97.7137, 41.1774],
              [-97.7435, 41.1968],
              [-97.7525, 41.2072],
              [-97.7525, 41.2134],
              [-97.7619, 41.2166],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3175000',
        NAME: 'Osceola Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332866578,
        AWATER: 622667,
        INTPTLAT: '+41.2282244',
        INTPTLON: '-097.5701292',
        ELSDLEA: '',
        UNSDLEA: '75000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5418, 41.4676],
            [-97.3104, 41.446],
            [-97.3258, 41.3948],
            [-97.3496, 41.3891],
            [-97.4839, 41.365],
            [-97.5598, 41.3424],
            [-97.5418, 41.4676],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3105340',
        NAME: 'Columbus Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152821079,
        AWATER: 13931686,
        INTPTLAT: '+41.4002029',
        INTPTLON: '-097.4535626',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.6576, 41.2785],
              [-100.6384, 41.2638],
              [-100.6574, 41.2638],
              [-100.6576, 41.2639],
              [-100.6576, 41.2785],
            ],
          ],
          [
            [
              [-100.7155, 41.3075],
              [-100.6957, 41.293],
              [-100.7152, 41.2929],
              [-100.7155, 41.3075],
            ],
          ],
          [
            [
              [-100.9059, 41.3508],
              [-100.8495, 41.3361],
              [-100.8114, 41.3505],
              [-100.7717, 41.3506],
              [-100.7717, 41.3076],
              [-100.7348, 41.2785],
              [-100.6955, 41.2785],
              [-100.6767, 41.2471],
              [-100.6768, 41.2411],
              [-100.6769, 41.2349],
              [-100.6193, 41.2638],
              [-100.5805, 41.2463],
              [-100.6008, 41.162],
              [-100.6872, 41.0037],
              [-100.64, 40.9161],
              [-100.754, 40.9891],
              [-100.8677, 40.973],
              [-100.8869, 41.0471],
              [-100.8396, 41.1151],
              [-100.8682, 41.2085],
              [-100.8496, 41.3059],
              [-100.9059, 41.3508],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100022',
        NAME: 'North Platte Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 676775029,
        AWATER: 11042627,
        INTPTLAT: '+41.1339280',
        INTPTLON: '-100.7530957',
        ELSDLEA: '',
        UNSDLEA: '00022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7217, 41.7984],
            [-99.7069, 41.7548],
            [-99.6868, 41.7403],
            [-99.6866, 41.7403],
            [-99.6905, 41.6675],
            [-99.578, 41.622],
            [-99.5972, 41.5973],
            [-99.6359, 41.5897],
            [-99.5971, 41.583],
            [-99.5788, 41.5612],
            [-99.6176, 41.5679],
            [-99.8203, 41.4016],
            [-99.8284, 41.4017],
            [-99.8477, 41.3943],
            [-99.9623, 41.3935],
            [-100.0399, 41.4587],
            [-100.0205, 41.5675],
            [-100.0975, 41.6086],
            [-100.088, 41.644],
            [-100.1039, 41.6522],
            [-100.0979, 41.6973],
            [-99.9437, 41.7105],
            [-99.8522, 41.7911],
            [-99.7217, 41.7984],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100010',
        NAME: 'Anselmo-Merna Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1265383338,
        AWATER: 1108260,
        INTPTLAT: '+41.5882238',
        INTPTLON: '-099.8518192',
        ELSDLEA: '',
        UNSDLEA: '00010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7217, 41.7984],
            [-99.6917, 41.7984],
            [-99.6866, 41.7984],
            [-99.687, 42.0861],
            [-99.6624, 42.086],
            [-99.2346, 42.088],
            [-99.2227, 42.0879],
            [-99.2122, 41.6684],
            [-99.3272, 41.7414],
            [-99.6866, 41.7403],
            [-99.6868, 41.7403],
            [-99.7069, 41.7548],
            [-99.7217, 41.7984],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173120',
        NAME: 'Loup County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1467441501,
        AWATER: 7275368,
        INTPTLAT: '+41.8650032',
        INTPTLON: '-099.4183761',
        ELSDLEA: '',
        UNSDLEA: '73120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2181, 42.8288],
              [-97.2124, 42.8118],
              [-97.2222, 42.82],
              [-97.2181, 42.8288],
            ],
          ],
          [
            [
              [-97.2523, 42.816],
              [-97.244, 42.8137],
              [-97.244, 42.8133],
              [-97.2501, 42.8106],
              [-97.2523, 42.816],
            ],
          ],
          [
            [
              [-97.245, 42.7574],
              [-97.2298, 42.7866],
              [-97.2197, 42.7866],
              [-97.1902, 42.7867],
              [-97.1788, 42.7866],
              [-97.1721, 42.8003],
              [-97.1721, 42.8029],
              [-97.1604, 42.7997],
              [-97.1371, 42.7792],
              [-97.1011, 42.7697],
              [-97.0158, 42.7613],
              [-97.0544, 42.6989],
              [-97.1653, 42.703],
              [-97.245, 42.7574],
            ],
          ],
          [
            [
              [-97.283, 42.8544],
              [-97.2786, 42.8542],
              [-97.2768, 42.8539],
              [-97.2665, 42.8527],
              [-97.2579, 42.8537],
              [-97.2496, 42.8376],
              [-97.249, 42.8179],
              [-97.2523, 42.816],
              [-97.288, 42.8377],
              [-97.283, 42.8544],
            ],
          ],
          [
            [
              [-97.3068, 42.8676],
              [-97.2875, 42.8552],
              [-97.3072, 42.8513],
              [-97.3068, 42.8676],
            ],
          ],
          [
            [
              [-97.3072, 42.8513],
              [-97.3072, 42.8447],
              [-97.3123, 42.8448],
              [-97.3072, 42.8513],
            ],
          ],
          [
            [
              [-97.3272, 42.8402],
              [-97.3123, 42.8448],
              [-97.3272, 42.837],
              [-97.3272, 42.8402],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3178990',
        NAME: 'Wynot Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175192077,
        AWATER: 9237807,
        INTPTLAT: '+42.7471005',
        INTPTLON: '-097.1670874',
        ELSDLEA: '',
        UNSDLEA: '78990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.7117, 41.7398],
            [-100.3625, 41.7396],
            [-100.3084, 41.6253],
            [-100.3187, 41.4713],
            [-100.443, 41.4515],
            [-100.4468, 41.3212],
            [-100.5629, 41.3071],
            [-100.5805, 41.2463],
            [-100.6193, 41.2638],
            [-100.6384, 41.2638],
            [-100.6576, 41.2785],
            [-100.6576, 41.2639],
            [-100.6574, 41.2638],
            [-100.6767, 41.2471],
            [-100.6955, 41.2785],
            [-100.6957, 41.293],
            [-100.7155, 41.3075],
            [-100.7717, 41.3076],
            [-100.7717, 41.3506],
            [-100.7715, 41.3937],
            [-100.7142, 41.3935],
            [-100.7117, 41.7398],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3177100',
        NAME: 'Stapleton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1549705072,
        AWATER: 1203586,
        INTPTLAT: '+41.5299996',
        INTPTLON: '-100.5892612',
        ELSDLEA: '',
        UNSDLEA: '77100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2826, 40.8687],
            [-98.2136, 40.9318],
            [-98.1668, 40.9581],
            [-98.1303, 40.9915],
            [-97.979, 41.0389],
            [-97.9406, 41.032],
            [-97.9124, 41.0321],
            [-97.9124, 41.0278],
            [-97.9032, 41.0176],
            [-97.8933, 41.0106],
            [-97.9306, 40.9593],
            [-97.95, 40.8289],
            [-97.9401, 40.7854],
            [-97.9501, 40.7854],
            [-97.9502, 40.7784],
            [-97.921, 40.713],
            [-97.9499, 40.7059],
            [-97.9591, 40.7058],
            [-97.9768, 40.6984],
            [-98.054, 40.7274],
            [-98.1217, 40.8434],
            [-98.2256, 40.8209],
            [-98.2331, 40.8209],
            [-98.2406, 40.8143],
            [-98.245, 40.821],
            [-98.264, 40.821],
            [-98.2781, 40.821],
            [-98.2826, 40.8687],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3103360',
        NAME: 'Aurora Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 676654191,
        AWATER: 4334595,
        INTPTLAT: '+40.8838343',
        INTPTLON: '-098.0583894',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.481, 42.4797],
              [-96.4455, 42.4906],
              [-96.3806, 42.4469],
              [-96.4113, 42.3863],
              [-96.481, 42.4797],
            ],
          ],
          [
            [
              [-96.5293, 42.4452],
              [-96.5263, 42.4447],
              [-96.5274, 42.443],
              [-96.5293, 42.4452],
            ],
          ],
          [
            [
              [-96.5329, 42.452],
              [-96.5293, 42.4482],
              [-96.5293, 42.4458],
              [-96.5315, 42.4434],
              [-96.5274, 42.443],
              [-96.5219, 42.4404],
              [-96.5369, 42.4422],
              [-96.537, 42.4483],
              [-96.5329, 42.452],
            ],
          ],
          [
            [
              [-96.5379, 42.4665],
              [-96.5228, 42.4664],
              [-96.5227, 42.4619],
              [-96.5342, 42.4597],
              [-96.5378, 42.4562],
              [-96.5379, 42.4665],
            ],
          ],
          [
            [
              [-96.5614, 42.4493],
              [-96.5612, 42.4488],
              [-96.562, 42.4483],
              [-96.5614, 42.4493],
            ],
          ],
          [
            [
              [-96.5656, 42.4444],
              [-96.5629, 42.4464],
              [-96.562, 42.4465],
              [-96.562, 42.4469],
              [-96.5606, 42.4474],
              [-96.5538, 42.4498],
              [-96.5559, 42.4399],
              [-96.5656, 42.4444],
            ],
          ],
          [
            [
              [-96.5654, 42.4764],
              [-96.5496, 42.4723],
              [-96.5459, 42.47],
              [-96.5497, 42.4638],
              [-96.5654, 42.4764],
            ],
          ],
          [
            [
              [-96.5767, 42.4481],
              [-96.5752, 42.4482],
              [-96.5752, 42.4468],
              [-96.5767, 42.4468],
              [-96.5767, 42.4481],
            ],
          ],
          [
            [
              [-96.5844, 42.4952],
              [-96.5797, 42.4952],
              [-96.5812, 42.4923],
              [-96.5844, 42.493],
              [-96.5844, 42.4952],
            ],
          ],
          [
            [
              [-96.5992, 42.5046],
              [-96.5413, 42.5189],
              [-96.5373, 42.5101],
              [-96.5564, 42.5145],
              [-96.5651, 42.5117],
              [-96.5805, 42.5018],
              [-96.5844, 42.5006],
              [-96.5992, 42.5046],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3176860',
        NAME: 'South Sioux City Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70886466,
        AWATER: 4131664,
        INTPTLAT: '+42.4523480',
        INTPTLON: '-096.4477674',
        ELSDLEA: '',
        UNSDLEA: '76860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.4942, 41.6995],
            [-103.4605, 41.7717],
            [-103.4407, 41.8071],
            [-103.4115, 41.807],
            [-103.4013, 41.815],
            [-103.3615, 41.8302],
            [-103.3633, 42.0029],
            [-103.286, 42.0021],
            [-103.1874, 41.9832],
            [-103.2852, 41.9041],
            [-103.168, 41.8596],
            [-103.1965, 41.6261],
            [-103.4661, 41.6545],
            [-103.4942, 41.6995],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100090',
        NAME: 'Bayard Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 680339064,
        AWATER: 854337,
        INTPTLAT: '+41.7927005',
        INTPTLON: '-103.3130888',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7263, 42.4341],
              [-96.7079, 42.4449],
              [-96.6927, 42.4448],
              [-96.6773, 42.409],
              [-96.7263, 42.4341],
            ],
          ],
          [
            [
              [-96.9372, 42.5251],
              [-96.8693, 42.554],
              [-96.8694, 42.5455],
              [-96.8693, 42.5175],
              [-96.8593, 42.5108],
              [-96.8002, 42.5031],
              [-96.712, 42.4666],
              [-96.7174, 42.4594],
              [-96.7261, 42.4374],
              [-96.7708, 42.3844],
              [-96.7713, 42.3845],
              [-96.7795, 42.3877],
              [-96.7795, 42.3657],
              [-96.784, 42.3514],
              [-96.7942, 42.3511],
              [-96.9496, 42.3513],
              [-96.9593, 42.3513],
              [-96.9372, 42.5251],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3102880',
        NAME: 'Allen Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287215914,
        AWATER: 363250,
        INTPTLAT: '+42.4482810',
        INTPTLON: '-096.8493994',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.053, 42.087],
            [-103.8735, 42.0883],
            [-103.8631, 41.9932],
            [-103.8692, 41.8279],
            [-103.829, 41.6979],
            [-104.0527, 41.698],
            [-104.053, 42.087],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100097',
        NAME: 'Morrill Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 656362815,
        AWATER: 957557,
        INTPTLAT: '+41.8918622',
        INTPTLON: '-103.9623468',
        ELSDLEA: '',
        UNSDLEA: '00097',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8631, 41.9932],
            [-103.8735, 42.0883],
            [-103.7982, 42.1466],
            [-103.6694, 42.0022],
            [-103.6632, 41.958],
            [-103.8033, 41.8944],
            [-103.8117, 41.8405],
            [-103.8692, 41.8279],
            [-103.8631, 41.9932],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3173890',
        NAME: 'Mitchell Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381703276,
        AWATER: 479738,
        INTPTLAT: '+41.9937322',
        INTPTLON: '-103.7843561',
        ELSDLEA: '',
        UNSDLEA: '73890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3587, 40.7056],
            [-97.3546, 40.7145],
            [-97.355, 40.7145],
            [-97.3533, 40.7187],
            [-97.3519, 40.7186],
            [-97.3519, 40.718],
            [-97.3519, 40.7177],
            [-97.3512, 40.7186],
            [-97.3505, 40.7186],
            [-97.3491, 40.7191],
            [-97.3492, 40.7192],
            [-97.3492, 40.7194],
            [-97.3396, 40.7277],
            [-97.33, 40.7276],
            [-97.1969, 40.7349],
            [-97.1868, 40.7276],
            [-97.1941, 40.6038],
            [-97.2099, 40.5533],
            [-97.2171, 40.5451],
            [-97.2253, 40.5318],
            [-97.236, 40.5414],
            [-97.236, 40.5532],
            [-97.236, 40.5599],
            [-97.2448, 40.5677],
            [-97.2501, 40.5677],
            [-97.2643, 40.5605],
            [-97.2547, 40.5242],
            [-97.2546, 40.5164],
            [-97.2927, 40.5242],
            [-97.3684, 40.5535],
            [-97.3587, 40.7056],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100096',
        NAME: 'Friend Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285613684,
        AWATER: 399946,
        INTPTLAT: '+40.6312916',
        INTPTLON: '-097.2848295',
        ELSDLEA: '',
        UNSDLEA: '00096',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3777, 40.3795],
              [-97.3688, 40.3794],
              [-97.3686, 40.3674],
              [-97.3777, 40.3724],
              [-97.3777, 40.3795],
            ],
          ],
          [
            [
              [-97.3966, 40.3869],
              [-97.3873, 40.3869],
              [-97.3966, 40.3795],
              [-97.3966, 40.3869],
            ],
          ],
          [
            [
              [-97.3968, 40.423],
              [-97.3878, 40.4301],
              [-97.3687, 40.4374],
              [-97.3688, 40.423],
              [-97.3877, 40.4156],
              [-97.4065, 40.416],
              [-97.3968, 40.423],
            ],
          ],
          [
            [
              [-97.4253, 40.4086],
              [-97.4155, 40.416],
              [-97.4065, 40.416],
              [-97.4064, 40.4121],
              [-97.3967, 40.4085],
              [-97.3966, 40.3869],
              [-97.4253, 40.4086],
            ],
          ],
          [
            [
              [-97.4523, 40.4125],
              [-97.4507, 40.4131],
              [-97.4523, 40.4122],
              [-97.4523, 40.4125],
            ],
          ],
          [
            [
              [-97.4523, 40.4122],
              [-97.4523, 40.4114],
              [-97.4531, 40.4114],
              [-97.4523, 40.4122],
            ],
          ],
          [
            [
              [-97.4536, 40.4132],
              [-97.4523, 40.4125],
              [-97.4536, 40.4125],
              [-97.4536, 40.4132],
            ],
          ],
          [
            [
              [-97.4531, 40.4114],
              [-97.4523, 40.4112],
              [-97.4532, 40.3941],
              [-97.4531, 40.4114],
            ],
          ],
          [
            [
              [-97.4517, 40.4145],
              [-97.4523, 40.4134],
              [-97.456, 40.4136],
              [-97.456, 40.4122],
              [-97.4536, 40.4125],
              [-97.4545, 40.4119],
              [-97.4634, 40.4121],
              [-97.4635, 40.4157],
              [-97.4517, 40.4145],
            ],
          ],
          [
            [
              [-97.558, 40.3941],
              [-97.5391, 40.3941],
              [-97.5391, 40.3869],
              [-97.558, 40.3941],
            ],
          ],
          [
            [
              [-97.7623, 40.5606],
              [-97.7292, 40.6986],
              [-97.7209, 40.6986],
              [-97.5308, 40.6986],
              [-97.5017, 40.4812],
              [-97.4723, 40.4739],
              [-97.4723, 40.4666],
              [-97.4348, 40.4593],
              [-97.4066, 40.4374],
              [-97.416, 40.4375],
              [-97.4253, 40.4303],
              [-97.4162, 40.4231],
              [-97.4155, 40.416],
              [-97.4252, 40.416],
              [-97.4495, 40.4124],
              [-97.4495, 40.4138],
              [-97.4529, 40.4232],
              [-97.4635, 40.4232],
              [-97.4825, 40.387],
              [-97.4825, 40.3941],
              [-97.488, 40.3941],
              [-97.4916, 40.3941],
              [-97.5205, 40.4085],
              [-97.5395, 40.4087],
              [-97.558, 40.3941],
              [-97.5962, 40.3939],
              [-97.6004, 40.3971],
              [-97.634, 40.423],
              [-97.6529, 40.4809],
              [-97.7859, 40.5245],
              [-97.7623, 40.5606],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '31',
        SCSDLEA: '',
        GEOID: '3100118',
        NAME: 'Fillmore Central Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 637951803,
        AWATER: 1697770,
        INTPTLAT: '+40.5493501',
        INTPTLON: '-097.6005427',
        ELSDLEA: '',
        UNSDLEA: '00118',
      },
    },
  ],
};

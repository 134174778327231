import { getSurveysSummaryApi, getSurveyTypesApi } from '../api/surveys';
import types from '../types';

export const getSurveysSummary = (jwt, filters) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SUMMARY_PENDING });
    const data = await getSurveysSummaryApi(jwt, filters);
    dispatch({
      type: types.reducerTypes.SURVEY_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_SUMMARY_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const getSurveysSummaryTypes = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SUMMARY_TYPES_PENDING });
    const data = await getSurveyTypesApi(jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_SUMMARY_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_SUMMARY_TYPES_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const hideErrorToast = () => ({ type: types.reducerTypes.SURVEY_SUMMARY_HIDE_ERROR_TOAST });

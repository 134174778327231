import React from 'react';
import PropTypes from 'prop-types';
import CardsGroup from '../CardsGroup';

const VisualizationsContainer = ({ visualizations }) => (
  <>
    { (visualizations && visualizations.length > 0) && (
      visualizations.map((visualization) => (
        <CardsGroup
          key={visualization.Id}
          id={visualization.Id}
          surveyData={visualization}
          surveyChild={visualization.Drafts}
          isVisualization
        />
      ))
    )}
  </>
);

VisualizationsContainer.propTypes = {
  visualizations: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
      Data: PropTypes.object,
    }),
  ).isRequired,
};

export default VisualizationsContainer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Col, Row, Button, Card, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import shortid from 'shortid';
import types from '../../types';

import {
  listUserReports,
  createAndAddToReport,
  addItemsToReport,
} from '../../actions/report.action';

import './style.css';
import navigationTracker from '../../helpers/analytics/navigationTracker';

const ReportModal = () => {
  const modalProps = useSelector((state) => state.modal.modalProps);
  const storeJwt = useSelector((state) => state.session.jwt);
  const show = useSelector((state) => state.reports.modalProps.open);
  const showCreate = useSelector((state) => state.reports.showCreate);
  const reports = useSelector((state) => state.reports.reports);
  const reportItem = useSelector((state) => state.reports.reportItem);
  const [addDisabled, setAddDisabled] = useState(true);
  const [selectedReport, setSelectedReport] = useState();
  const [reportName, setReportName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listUserReports(storeJwt));
  }, []);

  const handleReportSelected = (report) => {
    setAddDisabled(false);
    setSelectedReport(report);
  };

  const handleAddToReport = () => {
    dispatch(addItemsToReport(selectedReport.id, reportItem, storeJwt));
    dispatch({ type: types.reducerTypes.REPORT_MODAL_HIDE });
    navigationTracker.onAddToReport(selectedReport, reportItem);
  };

  const handleCreateReport = () => {
    dispatch(createAndAddToReport({ name: reportName }, reportItem, storeJwt));
  };

  const handleNewReport = () => {
    dispatch({ type: types.reducerTypes.REPORT_CREATE_MODAL_SHOW });
  };

  const handleBackToAdd = () => {
    dispatch({ type: types.reducerTypes.REPORT_CREATE_MODAL_HIDE });
  };

  const sortReportsByUpdatedDate = (reportA, reportB) => reportB.dateUpdated - reportA.dateUpdated;

  if (showCreate) {
    return (
      <Modal
        centered
        show={show}
        onHide={() => dispatch({ type: types.reducerTypes.REPORT_MODAL_HIDE })}
        className={`CreateReportModal ${modalProps.className}`}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Report's name"
              aria-label="Report's name"
              aria-describedby="basic-addon2"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
            <InputGroup.Append>
              <Button variant="outline-secondary" onClick={handleCreateReport}>
                Create
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button title="Back to Add Report" variant="dark" onClick={handleBackToAdd}>
            <FontAwesomeIcon icon={faChevronLeft} /> Back to Add to Report
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal
      centered
      show={show}
      onHide={() => dispatch({ type: types.reducerTypes.REPORT_MODAL_HIDE })}
      className={`ReportModal ${modalProps.className}`}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add to Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs="6" className="create-report">
            <img src="/add-to-report.png" alt="add to report" />
          </Col>
          <Col xs="6" className="d-flex flex-column reports-list">
            {reports.sort(sortReportsByUpdatedDate).map((report) => (
              <Card
                className={`report-card ${
                  selectedReport?.id === report.id ? 'selected' : ''
                } text-left`}
                key={shortid.generate()}
                onClick={() => handleReportSelected(report)}
              >
                <Card.Title>{report?.name}</Card.Title>
                <Card.Text>{`Created ${moment(report?.dateCreated).format('LL')}`}</Card.Text>
              </Card>
            ))}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="6">
            <Button variant="dark" onClick={handleNewReport}>
              Create new report
            </Button>
          </Col>
          <Col xs="6">
            <Button variant="dark" disabled={addDisabled} onClick={handleAddToReport}>
              Add to Report
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ReportModal;

export const WA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6209, 47.0014],
            [-119.365, 46.9998],
            [-119.2801, 46.9704],
            [-119.2801, 46.9414],
            [-119.3687, 46.9126],
            [-119.3691, 46.7956],
            [-119.9286, 46.7963],
            [-119.9861, 46.971],
            [-119.75, 46.9718],
            [-119.6209, 47.0014],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307620',
        NAME: 'Royal School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1006821661,
        AWATER: 20717114,
        INTPTLAT: '+46.8982124',
        INTPTLON: '-119.6362499',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5789, 48.6314],
            [-122.352, 48.6451],
            [-122.2693, 48.6454],
            [-122.313, 48.6021],
            [-122.2971, 48.471],
            [-122.4441, 48.4575],
            [-122.5148, 48.4688],
            [-122.5789, 48.6314],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300780',
        NAME: 'Burlington-Edison School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274589808,
        AWATER: 119020150,
        INTPTLAT: '+48.5598359',
        INTPTLON: '-122.4297055',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4168, 48.3647],
            [-122.2702, 48.3777],
            [-122.1836, 48.2973],
            [-122.2039, 48.2973],
            [-122.4583, 48.2976],
            [-122.5374, 48.3391],
            [-122.4168, 48.3647],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301680',
        NAME: 'Conway School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139383187,
        AWATER: 47075260,
        INTPTLAT: '+48.3307990',
        INTPTLON: '-122.3491991',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.781, 46.9163],
            [-122.6368, 46.7627],
            [-122.6781, 46.7635],
            [-122.6991, 46.7635],
            [-122.9187, 46.807],
            [-123.0127, 46.8511],
            [-122.8761, 46.9419],
            [-122.781, 46.9163],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308820',
        NAME: 'Tenino School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279778019,
        AWATER: 5688250,
        INTPTLAT: '+46.8334447',
        INTPTLON: '-122.7953178',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.591, 48.4176],
            [-122.5148, 48.4688],
            [-122.4441, 48.4575],
            [-122.4168, 48.3647],
            [-122.5374, 48.3391],
            [-122.591, 48.4176],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304110',
        NAME: 'La Conner School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87984952,
        AWATER: 37465056,
        INTPTLAT: '+48.4060245',
        INTPTLON: '-122.5121474',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4441, 48.4575],
            [-122.2971, 48.471],
            [-122.2702, 48.3777],
            [-122.4168, 48.3647],
            [-122.4441, 48.4575],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305400',
        NAME: 'Mount Vernon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96094499,
        AWATER: 3401943,
        INTPTLAT: '+48.4180234',
        INTPTLON: '-122.3591065',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.704, 47.0719],
            [-122.6212, 47.0942],
            [-122.5593, 47.1958],
            [-122.5259, 47.1991],
            [-122.4631, 47.1702],
            [-122.4637, 47.1413],
            [-122.5685, 47.0831],
            [-122.6328, 47.0003],
            [-122.6638, 47.0103],
            [-122.704, 47.0719],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301410',
        NAME: 'Clover Park School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162242682,
        AWATER: 7519244,
        INTPTLAT: '+47.1034421',
        INTPTLON: '-122.5825626',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3996, 46.9441],
            [-122.4002, 46.9804],
            [-122.1681, 46.9659],
            [-122.1059, 46.9372],
            [-121.981, 46.9077],
            [-121.7292, 46.9074],
            [-121.4968, 46.9074],
            [-121.4564, 46.7837],
            [-121.7589, 46.7837],
            [-121.855, 46.7291],
            [-122.2027, 46.7626],
            [-122.334, 46.8421],
            [-122.4214, 46.8497],
            [-122.3996, 46.9441],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302370',
        NAME: 'Eatonville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1146309388,
        AWATER: 20228801,
        INTPTLAT: '+46.9113507',
        INTPTLON: '-122.1434130',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8148, 47.3068],
            [-122.8045, 47.3535],
            [-122.8015, 47.404],
            [-122.5367, 47.403],
            [-122.5336, 47.332],
            [-122.5654, 47.2468],
            [-122.6009, 47.2097],
            [-122.6753, 47.2513],
            [-122.7595, 47.1412],
            [-122.8214, 47.1942],
            [-122.8148, 47.3068],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306690',
        NAME: 'Peninsula School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283418361,
        AWATER: 172603934,
        INTPTLAT: '+47.3211233',
        INTPTLON: '-122.6875711',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2609, 47.2501],
            [-122.2623, 47.2576],
            [-122.2402, 47.2575],
            [-122.1442, 47.2031],
            [-122.1343, 47.1326],
            [-122.2443, 47.1244],
            [-122.2609, 47.2501],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308610',
        NAME: 'Sumner School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89433194,
        AWATER: 2442696,
        INTPTLAT: '+47.1787848',
        INTPTLON: '-122.2062182',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2402, 47.2575],
            [-122.1144, 47.2283],
            [-122.112, 47.2137],
            [-122.1442, 47.2031],
            [-122.2402, 47.2575],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302130',
        NAME: 'Dieringer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32693324,
        AWATER: 796491,
        INTPTLAT: '+47.2395060',
        INTPTLON: '-122.1660150',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3064, 47.191],
            [-121.1121, 47.1905],
            [-121.1051, 47.5897],
            [-120.9243, 47.4334],
            [-120.7331, 47.3934],
            [-120.5566, 47.307],
            [-120.5165, 47.3209],
            [-120.5128, 47.1758],
            [-120.6457, 47.1755],
            [-120.6453, 47.1028],
            [-120.7723, 47.146],
            [-121.0275, 47.127],
            [-121.3986, 47.1272],
            [-121.3064, 47.191],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301350',
        NAME: 'Cle Elum-Roslyn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1528515864,
        AWATER: 24802921,
        INTPTLAT: '+47.2709861',
        INTPTLON: '-120.8951160',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6336, 46.8693],
            [-120.6349, 46.912],
            [-120.581, 46.9958],
            [-120.4816, 46.8688],
            [-120.6336, 46.8693],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301950',
        NAME: 'Damman School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104280654,
        AWATER: 1071394,
        INTPTLAT: '+46.9027983',
        INTPTLON: '-120.5854696',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3986, 47.1272],
            [-121.0275, 47.127],
            [-120.7723, 47.146],
            [-120.6453, 47.1028],
            [-120.7088, 47.0432],
            [-121.1791, 47.0416],
            [-121.282, 47.089],
            [-121.3797, 47.0868],
            [-121.3986, 47.1272],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308850',
        NAME: 'Thorp School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520438781,
        AWATER: 350484,
        INTPTLAT: '+47.0756512',
        INTPTLON: '-121.0066395',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4295, 47.8276],
            [-122.3936, 47.8647],
            [-122.228, 47.8638],
            [-122.2276, 47.8265],
            [-122.271, 47.777],
            [-122.438, 47.7781],
            [-122.4295, 47.8276],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302400',
        NAME: 'Edmonds School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93944059,
        AWATER: 42723623,
        INTPTLAT: '+47.8154340',
        INTPTLON: '-122.3313109',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1519, 47.8668],
            [-121.119, 47.7799],
            [-121.5845, 47.7775],
            [-121.585, 47.864],
            [-121.1519, 47.8668],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303720',
        NAME: 'Index School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334421220,
        AWATER: 1573908,
        INTPTLAT: '+47.8214844',
        INTPTLON: '-121.4065162',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.821, 47.5425],
            [-122.7362, 47.5561],
            [-122.5704, 47.5879],
            [-122.454, 47.5766],
            [-122.4451, 47.5446],
            [-122.5367, 47.403],
            [-122.8015, 47.404],
            [-122.821, 47.5425],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308160',
        NAME: 'South Kitsap School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342268554,
        AWATER: 60172620,
        INTPTLAT: '+47.4872898',
        INTPTLON: '-122.6474592',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2912, 48.181],
            [-122.1723, 48.1523],
            [-122.3143, 48.1233],
            [-122.2912, 48.181],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304260',
        NAME: 'Lakewood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54786044,
        AWATER: 3399696,
        INTPTLAT: '+48.1487844',
        INTPTLON: '-122.2458870',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1362, 47.971],
            [-122.0105, 48.0362],
            [-121.7968, 47.9791],
            [-121.9053, 47.9214],
            [-121.991, 47.9142],
            [-122.0265, 47.8191],
            [-122.1429, 47.8268],
            [-122.1533, 47.8342],
            [-122.1906, 47.9481],
            [-122.1362, 47.971],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308020',
        NAME: 'Snohomish School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324792111,
        AWATER: 7330536,
        INTPTLAT: '+47.9484958',
        INTPTLON: '-122.0290637',
        ELSDLEA: '',
        UNSDLEA: '08020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1059, 46.9372],
            [-122.1052, 47.025],
            [-122.0809, 47.0973],
            [-121.9721, 47.0547],
            [-121.9702, 46.997],
            [-121.7292, 46.9962],
            [-121.7292, 46.9074],
            [-121.981, 46.9077],
            [-122.1059, 46.9372],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300870',
        NAME: 'Carbonado School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399120342,
        AWATER: 3831464,
        INTPTLAT: '+46.9585225',
        INTPTLON: '-121.9396789',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3943, 47.2376],
            [-122.2609, 47.2501],
            [-122.2443, 47.1244],
            [-122.2724, 47.0888],
            [-122.336, 47.1221],
            [-122.3945, 47.1918],
            [-122.3943, 47.2376],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306960',
        NAME: 'Puyallup School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138015469,
        AWATER: 888282,
        INTPTLAT: '+47.1713988',
        INTPTLON: '-122.3010561',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6009, 47.2097],
            [-122.5654, 47.2468],
            [-122.5259, 47.1991],
            [-122.5593, 47.1958],
            [-122.6009, 47.2097],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309180',
        NAME: 'University Place School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19089785,
        AWATER: 5958035,
        INTPTLAT: '+47.2156619',
        INTPTLON: '-122.5535463',
        ELSDLEA: '',
        UNSDLEA: '09180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6462, 45.7363],
            [-122.6155, 45.8176],
            [-122.5663, 45.9102],
            [-122.4318, 45.9757],
            [-122.3578, 45.9569],
            [-122.2456, 46.0539],
            [-122.2495, 45.7508],
            [-122.5165, 45.7592],
            [-122.506, 45.6937],
            [-122.5994, 45.6825],
            [-122.6462, 45.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300380',
        NAME: 'Battle Ground School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 691309763,
        AWATER: 10335064,
        INTPTLAT: '+45.8478450',
        INTPTLON: '-122.4147216',
        ELSDLEA: '',
        UNSDLEA: '00380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7855, 45.8505],
            [-122.7205, 45.8668],
            [-122.6155, 45.8176],
            [-122.6462, 45.7363],
            [-122.761, 45.7504],
            [-122.7957, 45.8152],
            [-122.7855, 45.8505],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307350',
        NAME: 'Ridgefield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138080510,
        AWATER: 10274113,
        INTPTLAT: '+45.7989013',
        INTPTLON: '-122.7139819',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2492, 45.7157],
            [-122.2495, 45.7508],
            [-122.2456, 46.0539],
            [-122.2457, 46.0632],
            [-122.2396, 46.3842],
            [-122.1143, 46.3862],
            [-122.1224, 46.2111],
            [-122.1256, 45.6075],
            [-122.1668, 45.5804],
            [-122.1975, 45.5673],
            [-122.2596, 45.5779],
            [-122.2595, 45.5451],
            [-122.3418, 45.5585],
            [-122.3836, 45.5755],
            [-122.3431, 45.6578],
            [-122.2492, 45.7157],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309540',
        NAME: 'Washougal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 924614750,
        AWATER: 29149263,
        INTPTLAT: '+45.9810312',
        INTPTLON: '-122.1784196',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6391, 45.9271],
            [-122.5663, 45.9102],
            [-122.6155, 45.8176],
            [-122.7205, 45.8668],
            [-122.6391, 45.9271],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304170',
        NAME: 'La Center School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97734725,
        AWATER: 637094,
        INTPTLAT: '+45.8824387',
        INTPTLON: '-122.6334307',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6032, 47.6683],
            [-122.5438, 47.7266],
            [-122.4344, 47.7264],
            [-122.454, 47.5766],
            [-122.5704, 47.5879],
            [-122.5859, 47.6215],
            [-122.6032, 47.6683],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300330',
        NAME: 'Bainbridge Island School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71520935,
        AWATER: 96882221,
        INTPTLAT: '+47.6425448',
        INTPTLON: '-122.5077640',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5859, 47.6215],
            [-122.5704, 47.5879],
            [-122.7362, 47.5561],
            [-122.5859, 47.6215],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300660',
        NAME: 'Bremerton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43550893,
        AWATER: 15953397,
        INTPTLAT: '+47.5765203',
        INTPTLON: '-122.6511415',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2649, 48.0214],
            [-122.1389, 47.9927],
            [-122.1362, 47.971],
            [-122.1906, 47.9481],
            [-122.1533, 47.8342],
            [-122.2276, 47.8265],
            [-122.228, 47.8638],
            [-122.2649, 48.0214],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302670',
        NAME: 'Everett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97979249,
        AWATER: 36859013,
        INTPTLAT: '+47.9448287',
        INTPTLON: '-122.2116535',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1389, 47.9927],
            [-122.032, 48.065],
            [-122.0105, 48.0362],
            [-122.1362, 47.971],
            [-122.1389, 47.9927],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304200',
        NAME: 'Lake Stevens School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69402443,
        AWATER: 4628119,
        INTPTLAT: '+48.0201964',
        INTPTLON: '-122.0804541',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3469, 48.0294],
            [-122.3431, 48.0365],
            [-122.2649, 48.0214],
            [-122.228, 47.8638],
            [-122.3936, 47.8647],
            [-122.3285, 47.9638],
            [-122.3469, 48.0294],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305430',
        NAME: 'Mukilteo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70861688,
        AWATER: 58132376,
        INTPTLAT: '+47.9331011',
        INTPTLON: '-122.3015587',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7968, 47.9791],
            [-121.1954, 47.9649],
            [-121.1281, 47.99],
            [-121.1519, 47.8668],
            [-121.585, 47.864],
            [-121.5845, 47.7775],
            [-121.5845, 47.7767],
            [-121.8619, 47.7772],
            [-121.9053, 47.9214],
            [-121.7968, 47.9791],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308550',
        NAME: 'Sultan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 844421873,
        AWATER: 10864414,
        INTPTLAT: '+47.9115286',
        INTPTLON: '-121.6064173',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.271, 47.777],
            [-122.2276, 47.8265],
            [-122.1533, 47.8342],
            [-122.1429, 47.8268],
            [-122.0077, 47.7753],
            [-122.0332, 47.7323],
            [-122.2699, 47.7337],
            [-122.271, 47.777],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305910',
        NAME: 'Northshore School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152008661,
        AWATER: 2474006,
        INTPTLAT: '+47.7725635',
        INTPTLON: '-122.1572172',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3935, 45.6943],
            [-122.2492, 45.7157],
            [-122.3431, 45.6578],
            [-122.3836, 45.5755],
            [-122.4758, 45.5787],
            [-122.3935, 45.6943],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300810',
        NAME: 'Camas School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138218949,
        AWATER: 6963831,
        INTPTLAT: '+45.6466425',
        INTPTLON: '-122.3699688',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4318, 45.9757],
            [-122.5663, 45.9102],
            [-122.6391, 45.9271],
            [-122.523, 45.9841],
            [-122.4318, 45.9757],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303300',
        NAME: 'Green Mountain School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59418579,
        AWATER: 6954901,
        INTPTLAT: '+45.9487285',
        INTPTLON: '-122.5229942',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2495, 45.7508],
            [-122.2492, 45.7157],
            [-122.3935, 45.6943],
            [-122.506, 45.6937],
            [-122.5165, 45.7592],
            [-122.2495, 45.7508],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303570',
        NAME: 'Hockinson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121319165,
        AWATER: 11115,
        INTPTLAT: '+45.7181828',
        INTPTLON: '-122.3860585',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2444, 47.0921],
            [-123.2021, 47.0849],
            [-123.2014, 47.0681],
            [-123.2012, 46.9969],
            [-123.3291, 47.0412],
            [-123.2444, 47.0921],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304890',
        NAME: 'McCleary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68799966,
        AWATER: 339106,
        INTPTLAT: '+47.0527697',
        INTPTLON: '-123.2548972',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.4297, 47.5336],
            [-124.1793, 47.5336],
            [-124.1397, 47.4326],
            [-123.895, 47.4324],
            [-124.0541, 47.2985],
            [-124.1148, 47.2452],
            [-124.3054, 47.2446],
            [-124.4217, 47.3933],
            [-124.4297, 47.5336],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308730',
        NAME: 'Taholah School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605458090,
        AWATER: 228381026,
        INTPTLAT: '+47.3940328',
        INTPTLON: '-124.2145612',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6422, 47.6556],
            [-119.5782, 47.7285],
            [-119.3627, 47.8154],
            [-119.2663, 47.8079],
            [-119.2123, 47.8731],
            [-119.0176, 47.9031],
            [-119.073, 47.7209],
            [-118.9758, 47.7136],
            [-118.978, 47.5534],
            [-119.2342, 47.5541],
            [-119.2341, 47.4743],
            [-119.3732, 47.4766],
            [-119.3947, 47.5309],
            [-119.5329, 47.5276],
            [-119.5334, 47.47],
            [-119.7479, 47.4683],
            [-119.6624, 47.5703],
            [-119.6422, 47.6556],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303440',
        NAME: 'Coulee-Hartline School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1484700339,
        AWATER: 87016602,
        INTPTLAT: '+47.6551234',
        INTPTLON: '-119.3195771',
        ELSDLEA: '',
        UNSDLEA: '03440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0165, 48.2232],
            [-117.8887, 48.2513],
            [-117.6934, 48.1358],
            [-117.7632, 48.0997],
            [-118.0172, 48.1355],
            [-118.0165, 48.2232],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309240',
        NAME: 'Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295229396,
        AWATER: 2095817,
        INTPTLAT: '+48.1568642',
        INTPTLON: '-117.8723013',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7479, 47.4683],
            [-119.5334, 47.47],
            [-119.5329, 47.354],
            [-119.3731, 47.426],
            [-119.2986, 47.3376],
            [-119.3319, 47.2656],
            [-119.4479, 47.2678],
            [-119.6209, 47.104],
            [-119.715, 47.1328],
            [-119.6832, 47.3088],
            [-119.7475, 47.3082],
            [-119.7474, 47.4392],
            [-119.7479, 47.4683],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302610',
        NAME: 'Ephrata School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 845920018,
        AWATER: 3384548,
        INTPTLAT: '+47.3092432',
        INTPTLON: '-119.5560455',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.065, 47.2765],
            [-119.0429, 47.3859],
            [-118.9675, 47.4221],
            [-118.9142, 47.5456],
            [-118.8496, 47.5447],
            [-118.7209, 47.523],
            [-118.6355, 47.5809],
            [-118.5705, 47.5803],
            [-118.5719, 47.4947],
            [-118.4639, 47.4501],
            [-118.4226, 47.3042],
            [-118.5573, 47.2611],
            [-118.7271, 47.1303],
            [-118.9586, 47.1875],
            [-119.0441, 47.2031],
            [-119.065, 47.2765],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306120',
        NAME: 'Odessa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1546686018,
        AWATER: 6736443,
        INTPTLAT: '+47.3517359',
        INTPTLON: '-118.7190780',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0936, 47.7578],
            [-119.9539, 47.7722],
            [-119.8779, 47.8231],
            [-119.8136, 47.7357],
            [-119.6853, 47.7356],
            [-119.6422, 47.6556],
            [-119.6624, 47.5703],
            [-119.7479, 47.4683],
            [-119.8545, 47.51],
            [-119.9623, 47.4951],
            [-120.0055, 47.392],
            [-120.1331, 47.4488],
            [-120.1327, 47.5798],
            [-120.1973, 47.5795],
            [-120.1766, 47.7],
            [-120.0936, 47.7578],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309600',
        NAME: 'Waterville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1200589739,
        AWATER: 34147,
        INTPTLAT: '+47.6055188',
        INTPTLON: '-119.9310185',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7474, 47.4392],
            [-119.7475, 47.3082],
            [-119.6832, 47.3088],
            [-119.715, 47.1328],
            [-119.6209, 47.104],
            [-119.6209, 47.0014],
            [-119.75, 46.9718],
            [-119.9861, 46.971],
            [-120.0429, 47.0735],
            [-120.0021, 47.1262],
            [-120.0472, 47.2255],
            [-120.0491, 47.2913],
            [-119.9189, 47.3645],
            [-119.8758, 47.4374],
            [-119.7474, 47.4392],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307080',
        NAME: 'Quincy School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1156410690,
        AWATER: 21687025,
        INTPTLAT: '+47.1832845',
        INTPTLON: '-119.8427253',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9046, 47.9105],
            [-119.8704, 47.9604],
            [-119.4908, 47.9174],
            [-119.3627, 47.8154],
            [-119.5782, 47.7285],
            [-119.6422, 47.6556],
            [-119.6853, 47.7356],
            [-119.8136, 47.7357],
            [-119.8779, 47.8231],
            [-119.9046, 47.9105],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304710',
        NAME: 'Mansfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793112416,
        AWATER: 5175671,
        INTPTLAT: '+47.8237800',
        INTPTLON: '-119.6534118',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.185, 47.7615],
            [-120.0936, 47.7578],
            [-120.1766, 47.7],
            [-120.1973, 47.5795],
            [-120.2445, 47.5791],
            [-120.185, 47.7615],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306390',
        NAME: 'Orondo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89346547,
        AWATER: 10591538,
        INTPTLAT: '+47.6848644',
        INTPTLON: '-120.1834802',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.09, 47.4069],
            [-118.0161, 47.436],
            [-117.8229, 47.4206],
            [-117.8237, 47.2604],
            [-117.9601, 47.2604],
            [-118.2088, 47.2612],
            [-118.2186, 47.3622],
            [-118.09, 47.4069],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308280',
        NAME: 'Sprague School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 487741081,
        AWATER: 5032623,
        INTPTLAT: '+47.3273149',
        INTPTLON: '-118.0336093',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2801, 46.9704],
            [-119.1666, 46.9906],
            [-119.0822, 47.0494],
            [-119.0441, 47.2031],
            [-118.9586, 47.1875],
            [-118.9812, 47.0719],
            [-118.9399, 46.9261],
            [-119.0665, 46.8828],
            [-119.2801, 46.9414],
            [-119.2801, 46.9704],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309510',
        NAME: 'Warden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 425146914,
        AWATER: 5183373,
        INTPTLAT: '+47.0131857',
        INTPTLON: '-119.0628386',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2289, 46.5936],
            [-118.2157, 46.5889],
            [-118.1726, 46.5569],
            [-117.9801, 46.5878],
            [-118.0744, 46.4121],
            [-118.1894, 46.426],
            [-118.2289, 46.5936],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308400',
        NAME: 'Starbuck School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262576754,
        AWATER: 5387513,
        INTPTLAT: '+46.4839202',
        INTPTLON: '-118.0985935',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1894, 46.426],
            [-118.0744, 46.4121],
            [-118.1165, 46.2808],
            [-117.9913, 46.2078],
            [-117.9927, 46.1276],
            [-118.1167, 46.1937],
            [-118.221, 46.1942],
            [-118.3358, 46.2087],
            [-118.2418, 46.2807],
            [-118.2418, 46.3675],
            [-118.1894, 46.426],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309390',
        NAME: 'Waitsburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402492419,
        AWATER: 0,
        INTPTLAT: '+46.2730914',
        INTPTLON: '-118.1469539',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9028, 49.0004],
            [-118.3704, 49.0003],
            [-118.3703, 48.8284],
            [-118.4418, 48.741],
            [-118.572, 48.7406],
            [-118.6366, 48.7914],
            [-118.8365, 48.7994],
            [-118.9247, 48.8283],
            [-118.9028, 49.0004],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301890',
        NAME: 'Curlew School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 939719525,
        AWATER: 45906,
        INTPTLAT: '+48.8945764',
        INTPTLON: '-118.6301426',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8365, 48.7994],
            [-118.6366, 48.7914],
            [-118.572, 48.7406],
            [-118.4418, 48.741],
            [-118.4418, 48.7409],
            [-118.4775, 48.6536],
            [-118.4554, 48.4816],
            [-118.4551, 48.3968],
            [-118.8444, 48.3965],
            [-118.8695, 48.4819],
            [-118.8695, 48.6408],
            [-118.8365, 48.7994],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307260',
        NAME: 'Republic School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1249799700,
        AWATER: 4131502,
        INTPTLAT: '+48.5980972',
        INTPTLON: '-118.6597180',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6516, 46.7359],
            [-118.5055, 46.7368],
            [-118.3392, 46.7374],
            [-118.2337, 46.7067],
            [-118.2157, 46.5889],
            [-118.2289, 46.5936],
            [-118.4759, 46.5951],
            [-118.6047, 46.5141],
            [-118.6796, 46.5297],
            [-118.6936, 46.7073],
            [-118.6516, 46.7359],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303780',
        NAME: 'Kahlotus School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 617204892,
        AWATER: 11768434,
        INTPTLAT: '+46.6301809',
        INTPTLON: '-118.4994141',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8253, 46.3853],
            [-118.7413, 46.4721],
            [-118.6796, 46.5297],
            [-118.6047, 46.5141],
            [-118.6338, 46.414],
            [-118.7734, 46.3123],
            [-118.8253, 46.3853],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308370',
        NAME: 'Star School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187994595,
        AWATER: 9972679,
        INTPTLAT: '+46.4111163',
        INTPTLON: '-118.7375950',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2634, 46.4763],
            [-119.1125, 46.4381],
            [-118.9089, 46.4298],
            [-118.8253, 46.3853],
            [-118.7734, 46.3123],
            [-118.7669, 46.2979],
            [-118.9728, 46.2387],
            [-119.0414, 46.1929],
            [-119.0547, 46.1989],
            [-119.2367, 46.2609],
            [-119.2634, 46.4763],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306570',
        NAME: 'Pasco School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760194919,
        AWATER: 24469232,
        INTPTLAT: '+46.3317922',
        INTPTLON: '-119.0682391',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4551, 48.3968],
            [-118.4554, 48.4816],
            [-118.2065, 48.4703],
            [-118.1929, 48.395],
            [-118.1493, 48.2434],
            [-118.2486, 48.1343],
            [-118.2335, 48.0635],
            [-118.4008, 48.0287],
            [-118.3409, 47.8944],
            [-118.4658, 47.8822],
            [-118.4551, 48.3968],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300002',
        NAME: 'Inchelium School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1035331574,
        AWATER: 80784361,
        INTPTLAT: '+48.2169309',
        INTPTLON: '-118.3423313',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2039, 48.2973],
            [-122.1836, 48.2973],
            [-121.9226, 48.2977],
            [-121.858, 48.2979],
            [-121.8568, 48.2248],
            [-121.7494, 48.2108],
            [-122.054, 48.1013],
            [-122.1723, 48.1523],
            [-122.2912, 48.181],
            [-122.2039, 48.2973],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300240',
        NAME: 'Arlington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482542204,
        AWATER: 1911459,
        INTPTLAT: '+48.2237251',
        INTPTLON: '-122.0569438',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7494, 48.2108],
            [-121.7073, 48.1246],
            [-121.4532, 48.1247],
            [-121.4539, 48.0366],
            [-121.3242, 48.0363],
            [-121.324, 48.1253],
            [-120.9633, 48.1356],
            [-121.0154, 48.0751],
            [-121.1371, 48.0443],
            [-121.1281, 47.99],
            [-121.1954, 47.9649],
            [-121.7968, 47.9791],
            [-122.0105, 48.0362],
            [-122.032, 48.065],
            [-122.054, 48.1013],
            [-121.7494, 48.2108],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303210',
        NAME: 'Granite Falls School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1207472197,
        AWATER: 4996659,
        INTPTLAT: '+48.0549604',
        INTPTLON: '-121.5656142',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1429, 47.8268],
            [-122.0265, 47.8191],
            [-121.991, 47.9142],
            [-121.9053, 47.9214],
            [-121.8619, 47.7772],
            [-122.0077, 47.7753],
            [-122.1429, 47.8268],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305130',
        NAME: 'Monroe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211018395,
        AWATER: 1946869,
        INTPTLAT: '+47.8301028',
        INTPTLON: '-121.9737210',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6944, 47.2564],
            [-123.7353, 47.0658],
            [-123.8184, 47.0659],
            [-123.8397, 47.2127],
            [-123.8398, 47.3445],
            [-123.7394, 47.345],
            [-123.6944, 47.2564],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309990',
        NAME: 'Wishkah Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287072650,
        AWATER: 720068,
        INTPTLAT: '+47.2011849',
        INTPTLON: '-123.7651831',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.8582, 46.9525],
            [-123.8184, 47.0659],
            [-123.7353, 47.0658],
            [-123.6763, 46.9573],
            [-123.7784, 46.9223],
            [-123.791, 46.8505],
            [-123.8582, 46.9525],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300030',
        NAME: 'Aberdeen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176688611,
        AWATER: 10443477,
        INTPTLAT: '+46.9640151',
        INTPTLON: '-123.7951478',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8366, 47.1876],
            [-122.8214, 47.1942],
            [-122.7595, 47.1412],
            [-122.704, 47.0719],
            [-122.6638, 47.0103],
            [-122.7809, 46.9669],
            [-122.8444, 46.9962],
            [-122.8651, 47.1197],
            [-122.8366, 47.1876],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305850',
        NAME: 'North Thurston Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185638121,
        AWATER: 40702735,
        INTPTLAT: '+47.0687958',
        INTPTLON: '-122.7864079',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2014, 47.0681],
            [-123.0125, 47.0541],
            [-122.9098, 47.165],
            [-122.8366, 47.1876],
            [-122.8651, 47.1197],
            [-122.8444, 46.9962],
            [-122.9048, 47.0253],
            [-123.1584, 46.9959],
            [-123.2012, 46.9969],
            [-123.2014, 47.0681],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306180',
        NAME: 'Olympia School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198998531,
        AWATER: 42052291,
        INTPTLAT: '+47.0676341',
        INTPTLON: '-122.9825095',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4968, 46.9074],
            [-121.4565, 46.9227],
            [-121.3797, 47.0868],
            [-121.282, 47.089],
            [-121.1791, 47.0416],
            [-121.0264, 46.9982],
            [-121.0265, 46.9113],
            [-120.6349, 46.912],
            [-120.6336, 46.8693],
            [-120.6251, 46.826],
            [-120.6362, 46.7094],
            [-120.5779, 46.6313],
            [-120.5809, 46.6156],
            [-120.6201, 46.6219],
            [-120.7213, 46.7312],
            [-120.7831, 46.7423],
            [-120.887, 46.6516],
            [-121.0126, 46.6496],
            [-121.0117, 46.5788],
            [-121.4124, 46.5786],
            [-121.3717, 46.7269],
            [-121.4564, 46.7837],
            [-121.4968, 46.9074],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305460',
        NAME: 'Naches Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2384401191,
        AWATER: 20171298,
        INTPTLAT: '+46.7927722',
        INTPTLON: '-121.2261510',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0456, 48.3068],
            [-124.0501, 48.0889],
            [-124.1769, 48.1337],
            [-124.4528, 48.1383],
            [-124.4527, 48.0953],
            [-124.7928, 48.0944],
            [-124.8485, 48.174],
            [-124.7614, 48.2957],
            [-124.817, 48.3855],
            [-124.7497, 48.4998],
            [-124.0456, 48.3068],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300840',
        NAME: 'Cape Flattery School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 959168773,
        AWATER: 911378036,
        INTPTLAT: '+48.2540070',
        INTPTLON: '-124.4458443',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0501, 48.0889],
            [-124.0456, 48.3068],
            [-123.5851, 48.2295],
            [-123.5872, 48.1328],
            [-123.7011, 48.0715],
            [-123.6967, 47.8811],
            [-123.926, 47.8815],
            [-123.9244, 48.087],
            [-124.0501, 48.0889],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301830',
        NAME: 'Crescent School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 632435133,
        AWATER: 422060692,
        INTPTLAT: '+48.1209461',
        INTPTLON: '-123.8430677',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5851, 48.2295],
            [-123.2857, 48.2767],
            [-123.2923, 47.8669],
            [-123.6967, 47.8811],
            [-123.7011, 48.0715],
            [-123.5872, 48.1328],
            [-123.5851, 48.2295],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306820',
        NAME: 'Port Angeles School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 823331934,
        AWATER: 305155454,
        INTPTLAT: '+48.0480590',
        INTPTLON: '-123.4750828',
        ELSDLEA: '',
        UNSDLEA: '06820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2857, 48.2767],
            [-123.249, 48.2843],
            [-122.9524, 48.3497],
            [-122.9495, 47.9693],
            [-122.9492, 47.8668],
            [-123.2923, 47.8669],
            [-123.2857, 48.2767],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307830',
        NAME: 'Sequim School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 721246067,
        AWATER: 556749537,
        INTPTLAT: '+48.0903337',
        INTPTLON: '-123.1113095',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9812, 47.0719],
            [-118.7475, 47.0868],
            [-118.6632, 47.0437],
            [-118.4292, 47.0303],
            [-118.399, 46.8977],
            [-118.4831, 46.8394],
            [-118.5055, 46.7368],
            [-118.6516, 46.7359],
            [-118.8781, 46.8083],
            [-118.8768, 46.9115],
            [-118.9399, 46.9261],
            [-118.9812, 47.0719],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304410',
        NAME: 'Lind School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1289206522,
        AWATER: 66843,
        INTPTLAT: '+46.9245732',
        INTPTLON: '-118.6780204',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0744, 46.4121],
            [-117.9801, 46.5878],
            [-117.8518, 46.6248],
            [-117.8633, 46.4709],
            [-117.7373, 46.4714],
            [-117.6751, 46.3376],
            [-117.6118, 46.3385],
            [-117.6032, 45.9989],
            [-117.6236, 45.9991],
            [-117.9778, 46.0007],
            [-117.9969, 46.0008],
            [-117.9929, 46.0627],
            [-117.9927, 46.1276],
            [-117.9913, 46.2078],
            [-118.1165, 46.2808],
            [-118.0744, 46.4121],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302040',
        NAME: 'Dayton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1767579194,
        AWATER: 7179333,
        INTPTLAT: '+46.2510948',
        INTPTLON: '-117.8530715',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6047, 46.5141],
            [-118.4759, 46.5951],
            [-118.2289, 46.5936],
            [-118.1894, 46.426],
            [-118.2418, 46.3675],
            [-118.2418, 46.2807],
            [-118.3358, 46.2087],
            [-118.5335, 46.2093],
            [-118.5752, 46.2528],
            [-118.7524, 46.2545],
            [-118.7669, 46.2979],
            [-118.7734, 46.3123],
            [-118.6338, 46.414],
            [-118.6047, 46.5141],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306870',
        NAME: 'Prescott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1296526719,
        AWATER: 19740231,
        INTPTLAT: '+46.4028885',
        INTPTLON: '-118.4354367',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3704, 49.0003],
            [-118.0399, 49.0003],
            [-118.0408, 48.8283],
            [-117.9982, 48.8137],
            [-118.1164, 48.674],
            [-118.1734, 48.7406],
            [-118.4418, 48.7409],
            [-118.4418, 48.741],
            [-118.3703, 48.8284],
            [-118.3704, 49.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306360',
        NAME: 'Orient School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 789378872,
        AWATER: 10857237,
        INTPTLAT: '+48.8547769',
        INTPTLON: '-118.2121218',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1442, 47.2031],
            [-122.112, 47.2137],
            [-122.0479, 47.1716],
            [-121.8391, 47.1434],
            [-121.7967, 47.1731],
            [-121.6301, 47.1535],
            [-121.5816, 47.1184],
            [-121.3797, 47.0868],
            [-121.4565, 46.9227],
            [-121.4968, 46.9074],
            [-121.7292, 46.9074],
            [-121.7292, 46.9962],
            [-121.9702, 46.997],
            [-121.9721, 47.0547],
            [-122.0809, 47.0973],
            [-122.1343, 47.1326],
            [-122.1442, 47.2031],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309780',
        NAME: 'White River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1048085254,
        AWATER: 4971650,
        INTPTLAT: '+47.0557278',
        INTPTLON: '-121.7283601',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9524, 48.3497],
            [-122.9404, 48.3524],
            [-122.8631, 48.3704],
            [-122.8627, 48.254],
            [-122.7096, 48.1382],
            [-122.8106, 48.0566],
            [-122.843, 47.9259],
            [-122.9495, 47.9693],
            [-122.9524, 48.3497],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306840',
        NAME: 'Port Townsend School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150717456,
        AWATER: 288798967,
        INTPTLAT: '+48.1275375',
        INTPTLON: '-122.9135146',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1027, 46.8248],
            [-118.0126, 46.7905],
            [-117.9708, 46.8673],
            [-117.9122, 46.9074],
            [-117.7664, 46.8845],
            [-117.7371, 46.8255],
            [-117.6298, 46.8258],
            [-117.6345, 46.7445],
            [-117.7562, 46.6844],
            [-117.8518, 46.6248],
            [-117.9801, 46.5878],
            [-118.1726, 46.5569],
            [-118.2157, 46.5889],
            [-118.2337, 46.7067],
            [-118.1027, 46.8248],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304150',
        NAME: 'LaCrosse School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1063071632,
        AWATER: 17527112,
        INTPTLAT: '+46.7326366',
        INTPTLON: '-117.9483203',
        ELSDLEA: '',
        UNSDLEA: '04150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3691, 46.7956],
            [-119.3687, 46.9126],
            [-119.2801, 46.9414],
            [-119.0665, 46.8828],
            [-118.9399, 46.9261],
            [-118.8768, 46.9115],
            [-118.8781, 46.8083],
            [-118.9843, 46.7365],
            [-119.1122, 46.7381],
            [-119.1136, 46.6795],
            [-119.3689, 46.6782],
            [-119.3691, 46.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306480',
        NAME: 'Othello School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 767064416,
        AWATER: 4297185,
        INTPTLAT: '+46.8084639',
        INTPTLON: '-119.1638807',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.399, 46.8977],
            [-118.2143, 46.9137],
            [-118.0983, 46.8837],
            [-118.1027, 46.8248],
            [-118.2337, 46.7067],
            [-118.3392, 46.7374],
            [-118.5055, 46.7368],
            [-118.4831, 46.8394],
            [-118.399, 46.8977],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309570',
        NAME: 'Washtucna School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500356650,
        AWATER: 444782,
        INTPTLAT: '+46.8411997',
        INTPTLON: '-118.2934232',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3732, 47.4766],
            [-119.2341, 47.4743],
            [-119.2342, 47.5541],
            [-118.978, 47.5534],
            [-118.9142, 47.5456],
            [-118.9675, 47.4221],
            [-119.0429, 47.3859],
            [-119.065, 47.2765],
            [-119.1492, 47.3278],
            [-119.2986, 47.3376],
            [-119.3731, 47.426],
            [-119.3732, 47.4766],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309900',
        NAME: 'Wilson Creek School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 679573461,
        AWATER: 3366719,
        INTPTLAT: '+47.4639727',
        INTPTLON: '-119.1150202',
        ELSDLEA: '',
        UNSDLEA: '09900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.5705, 47.5803],
            [-118.3571, 47.5953],
            [-118.2309, 47.5797],
            [-118.1645, 47.5088],
            [-118.079, 47.4941],
            [-118.09, 47.4069],
            [-118.2186, 47.3622],
            [-118.2088, 47.2612],
            [-118.3155, 47.3042],
            [-118.4226, 47.3042],
            [-118.4639, 47.4501],
            [-118.5719, 47.4947],
            [-118.5705, 47.5803],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303360',
        NAME: 'Harrington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 840189387,
        AWATER: 1784427,
        INTPTLAT: '+47.4514774',
        INTPTLON: '-118.3218664',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4902, 45.8094],
            [-120.0551, 45.791],
            [-120.2108, 45.726],
            [-120.4531, 45.6963],
            [-120.3975, 45.7791],
            [-120.4902, 45.8094],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307530',
        NAME: 'Roosevelt School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211778034,
        AWATER: 22203918,
        INTPTLAT: '+45.7665013',
        INTPTLON: '-120.3047185',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5239, 46.044],
            [-121.4272, 46.0445],
            [-121.4459, 45.8987],
            [-121.6127, 45.8989],
            [-121.6125, 46.0438],
            [-121.5239, 46.044],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309060',
        NAME: 'Trout Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216565613,
        AWATER: 109637,
        INTPTLAT: '+45.9693828',
        INTPTLON: '-121.5107245',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8846, 48.279],
            [-119.7553, 48.2358],
            [-119.5794, 48.0779],
            [-119.7764, 48.0619],
            [-119.869, 48.0185],
            [-119.895, 48.1632],
            [-119.9714, 48.1925],
            [-119.9714, 48.2791],
            [-119.8846, 48.279],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300690',
        NAME: 'Brewster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454115632,
        AWATER: 25312568,
        INTPTLAT: '+48.1447458',
        INTPTLON: '-119.7776586',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8512, 49.0004],
            [-120.0355, 48.9995],
            [-120.0353, 48.6119],
            [-120.0353, 48.4824],
            [-119.8847, 48.4531],
            [-119.8846, 48.279],
            [-119.9714, 48.2791],
            [-119.9714, 48.1925],
            [-120.0795, 48.1646],
            [-120.1434, 48.2227],
            [-120.3837, 48.222],
            [-120.4282, 48.2507],
            [-120.5836, 48.3192],
            [-120.6449, 48.4013],
            [-120.6388, 48.4734],
            [-120.7016, 48.5316],
            [-120.789, 48.6254],
            [-120.665, 48.7331],
            [-120.7337, 48.7829],
            [-120.8512, 49.0004],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305020',
        NAME: 'Methow Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4431499059,
        AWATER: 5555014,
        INTPTLAT: '+48.6027341',
        INTPTLON: '-120.3205296',
        ELSDLEA: '',
        UNSDLEA: '05020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8847, 48.4531],
            [-119.7551, 48.453],
            [-119.6688, 48.5109],
            [-119.5872, 48.3954],
            [-119.4953, 48.3533],
            [-119.2351, 48.3527],
            [-119.2334, 48.3095],
            [-119.233, 48.1135],
            [-119.3412, 48.0495],
            [-119.5794, 48.0779],
            [-119.7553, 48.2358],
            [-119.8846, 48.279],
            [-119.8847, 48.4531],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306150',
        NAME: 'Okanogan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1445238994,
        AWATER: 28637511,
        INTPTLAT: '+48.2807522',
        INTPTLON: '-119.5470068',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8722, 47.9253],
            [-118.7454, 47.9032],
            [-118.5704, 47.9323],
            [-118.5369, 47.8768],
            [-118.5811, 47.8598],
            [-118.592, 47.6417],
            [-118.6355, 47.5809],
            [-118.7209, 47.523],
            [-118.8496, 47.5447],
            [-118.83, 47.7429],
            [-118.8722, 47.9253],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309840',
        NAME: 'Wilbur School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 793748334,
        AWATER: 15587419,
        INTPTLAT: '+47.7418708',
        INTPTLON: '-118.7098887',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6328, 47.0003],
            [-122.5685, 47.0831],
            [-122.4637, 47.1413],
            [-122.4348, 47.1252],
            [-122.336, 47.1221],
            [-122.2724, 47.0888],
            [-122.2109, 47.0241],
            [-122.1052, 47.025],
            [-122.1059, 46.9372],
            [-122.1681, 46.9659],
            [-122.4002, 46.9804],
            [-122.3996, 46.9441],
            [-122.6328, 47.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300480',
        NAME: 'Bethel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520118953,
        AWATER: 6704102,
        INTPTLAT: '+47.0286573',
        INTPTLON: '-122.3808634',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4637, 47.1413],
            [-122.4631, 47.1702],
            [-122.3945, 47.1918],
            [-122.336, 47.1221],
            [-122.4348, 47.1252],
            [-122.4637, 47.1413],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302940',
        NAME: 'Franklin Pierce School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53901062,
        AWATER: 124281,
        INTPTLAT: '+47.1523818',
        INTPTLON: '-122.3994288',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2724, 47.0888],
            [-122.2443, 47.1244],
            [-122.1343, 47.1326],
            [-122.0809, 47.0973],
            [-122.1052, 47.025],
            [-122.2109, 47.0241],
            [-122.2724, 47.0888],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306450',
        NAME: 'Orting School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117991646,
        AWATER: 1367995,
        INTPTLAT: '+47.0514152',
        INTPTLON: '-122.1532418',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6009, 47.2097],
            [-122.5593, 47.1958],
            [-122.6212, 47.0942],
            [-122.704, 47.0719],
            [-122.7595, 47.1412],
            [-122.6753, 47.2513],
            [-122.6009, 47.2097],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308460',
        NAME: 'Steilacoom Historical School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70526910,
        AWATER: 78774388,
        INTPTLAT: '+47.1594429',
        INTPTLON: '-122.6776632',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5336, 47.332],
            [-122.437, 47.3335],
            [-122.3557, 47.2775],
            [-122.3943, 47.2376],
            [-122.3945, 47.1918],
            [-122.4631, 47.1702],
            [-122.5259, 47.1991],
            [-122.5654, 47.2468],
            [-122.5336, 47.332],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308700',
        NAME: 'Tacoma Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134014530,
        AWATER: 46055651,
        INTPTLAT: '+47.2556300',
        INTPTLON: '-122.4621597',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.869, 48.0185],
            [-119.7764, 48.0619],
            [-119.5794, 48.0779],
            [-119.3412, 48.0495],
            [-119.2982, 47.888],
            [-119.2123, 47.8731],
            [-119.2663, 47.8079],
            [-119.3627, 47.8154],
            [-119.4908, 47.9174],
            [-119.8704, 47.9604],
            [-119.869, 48.0185],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300720',
        NAME: 'Bridgeport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 687175986,
        AWATER: 17233723,
        INTPTLAT: '+47.9738805',
        INTPTLON: '-119.5283843',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9714, 48.1925],
            [-119.895, 48.1632],
            [-119.869, 48.0185],
            [-119.8704, 47.9604],
            [-119.9046, 47.9105],
            [-119.9484, 47.9892],
            [-120.0488, 47.9606],
            [-120.3625, 48.1688],
            [-120.3837, 48.222],
            [-120.1434, 48.2227],
            [-120.0795, 48.1646],
            [-119.9714, 48.1925],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306600',
        NAME: 'Pateros School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 635229724,
        AWATER: 8935395,
        INTPTLAT: '+48.1202995',
        INTPTLON: '-120.0665064',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1585, 46.7136],
            [-124.0013, 46.6766],
            [-124.0013, 46.6166],
            [-123.9167, 46.5321],
            [-123.847, 46.4394],
            [-123.8501, 46.2406],
            [-123.9274, 46.2386],
            [-123.9763, 46.2699],
            [-124.1595, 46.2611],
            [-124.1309, 46.3716],
            [-124.1333, 46.6031],
            [-124.1585, 46.7136],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306060',
        NAME: 'Ocean Beach School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349310282,
        AWATER: 517735884,
        INTPTLAT: '+46.4385388',
        INTPTLON: '-124.0232662',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5885, 46.7363],
            [-123.5884, 46.6927],
            [-123.3679, 46.6922],
            [-123.3582, 46.4413],
            [-123.5652, 46.4397],
            [-123.7254, 46.4709],
            [-123.7255, 46.5271],
            [-123.7247, 46.5631],
            [-123.6638, 46.7359],
            [-123.5885, 46.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309870',
        NAME: 'Willapa Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 748369330,
        AWATER: 380836,
        INTPTLAT: '+46.5920271',
        INTPTLON: '-123.5083293',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0172, 48.1355],
            [-117.7632, 48.0997],
            [-117.6934, 48.1358],
            [-117.6499, 48.1357],
            [-117.6291, 48.0191],
            [-117.6495, 47.9467],
            [-117.7354, 47.9191],
            [-117.7127, 47.8464],
            [-117.8412, 47.8372],
            [-117.8642, 47.8445],
            [-117.8652, 48.0022],
            [-118.0182, 48.0022],
            [-118.0172, 48.1355],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304830',
        NAME: 'Mary Walker School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555852540,
        AWATER: 5332554,
        INTPTLAT: '+48.0167296',
        INTPTLON: '-117.8160836',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0182, 48.0022],
            [-117.8652, 48.0022],
            [-117.8642, 47.8445],
            [-118.0782, 47.8002],
            [-118.1486, 47.8866],
            [-118.0806, 47.8898],
            [-118.0182, 48.0022],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309630',
        NAME: 'Wellpinit School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 353473728,
        AWATER: 4282405,
        INTPTLAT: '+47.9015841',
        INTPTLON: '-117.9788463',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1493, 48.2434],
            [-118.0727, 48.267],
            [-118.0165, 48.2232],
            [-118.0172, 48.1355],
            [-118.0182, 48.0022],
            [-118.0806, 47.8898],
            [-118.1486, 47.8866],
            [-118.2086, 47.9444],
            [-118.3187, 47.9043],
            [-118.3409, 47.8944],
            [-118.4008, 48.0287],
            [-118.2335, 48.0635],
            [-118.2486, 48.1343],
            [-118.1493, 48.2434],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301560',
        NAME: 'Columbia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 617191678,
        AWATER: 40712188,
        INTPTLAT: '+48.0466322',
        INTPTLON: '-118.1769714',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9554, 48.8023],
            [-117.8789, 48.8022],
            [-117.8798, 48.7436],
            [-117.7585, 48.6992],
            [-117.7341, 48.7928],
            [-117.5638, 48.7861],
            [-117.6295, 48.7427],
            [-117.6292, 48.6558],
            [-117.4997, 48.6562],
            [-117.5007, 48.5717],
            [-117.5013, 48.484],
            [-117.5894, 48.4165],
            [-117.7715, 48.424],
            [-117.9553, 48.3942],
            [-117.9658, 48.3507],
            [-118.0314, 48.3509],
            [-118.0848, 48.3943],
            [-118.0657, 48.5102],
            [-117.9591, 48.5979],
            [-117.9554, 48.8023],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301630',
        NAME: 'Colville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1372195732,
        AWATER: 3555388,
        INTPTLAT: '+48.5662624',
        INTPTLON: '-117.7719282',
        ELSDLEA: '',
        UNSDLEA: '01630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3675, 46.0008],
            [-118.5549, 46.0009],
            [-118.4302, 46.0781],
            [-118.3675, 46.0008],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301470',
        NAME: 'College Place School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75275311,
        AWATER: 1715,
        INTPTLAT: '+46.0306408',
        INTPTLON: '-118.4401576',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.221, 46.1942],
            [-118.1167, 46.1937],
            [-117.9927, 46.1276],
            [-117.9929, 46.0627],
            [-118.1793, 46.0786],
            [-118.221, 46.1942],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302160',
        NAME: 'Dixie School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172595040,
        AWATER: 0,
        INTPTLAT: '+46.1242981',
        INTPTLON: '-118.1216291',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7524, 46.2545],
            [-118.5752, 46.2528],
            [-118.5548, 46.064],
            [-118.5757, 46.0009],
            [-118.7252, 46.001],
            [-118.8681, 46.0005],
            [-118.8264, 46.1458],
            [-118.7524, 46.2545],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309000',
        NAME: 'Touchet School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561571996,
        AWATER: 185199,
        INTPTLAT: '+46.1204800',
        INTPTLON: '-118.6972233',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9519, 48.5286],
            [-122.8763, 48.5761],
            [-122.7461, 48.5262],
            [-122.7397, 48.4055],
            [-122.8631, 48.3704],
            [-122.9404, 48.3524],
            [-122.9519, 48.5286],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304530',
        NAME: 'Lopez School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87450602,
        AWATER: 212809913,
        INTPTLAT: '+48.4573715',
        INTPTLON: '-122.8531193',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0085, 48.8313],
            [-122.921, 48.8037],
            [-122.7919, 48.763],
            [-122.6969, 48.6519],
            [-122.7568, 48.6185],
            [-122.7461, 48.5262],
            [-122.8763, 48.5761],
            [-123.0318, 48.5807],
            [-123.1263, 48.7344],
            [-123.0089, 48.7673],
            [-123.0085, 48.8313],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306300',
        NAME: 'Orcas Island School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184507729,
        AWATER: 407643631,
        INTPTLAT: '+48.6691497',
        INTPTLON: '-122.9120454',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1263, 48.7344],
            [-123.0318, 48.5807],
            [-122.9519, 48.5286],
            [-122.9404, 48.3524],
            [-122.9524, 48.3497],
            [-123.249, 48.2843],
            [-123.1155, 48.423],
            [-123.2191, 48.5488],
            [-123.274, 48.693],
            [-123.1263, 48.7344],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307650',
        NAME: 'San Juan Island School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158523686,
        AWATER: 513573468,
        INTPTLAT: '+48.5071904',
        INTPTLON: '-123.1037693',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5638, 48.7861],
            [-117.4986, 48.8368],
            [-117.4989, 48.9228],
            [-117.4295, 49.0005],
            [-117.0324, 48.9992],
            [-117.0324, 48.8466],
            [-117.0337, 48.6553],
            [-117.3271, 48.6562],
            [-117.327, 48.5709],
            [-117.5007, 48.5717],
            [-117.4997, 48.6562],
            [-117.6292, 48.6558],
            [-117.6295, 48.7427],
            [-117.5638, 48.7861],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307800',
        NAME: 'Selkirk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1507302610,
        AWATER: 20623484,
        INTPTLAT: '+48.7758722',
        INTPTLON: '-117.3464231',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0318, 48.5807],
            [-122.8763, 48.5761],
            [-122.9519, 48.5286],
            [-123.0318, 48.5807],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307860',
        NAME: 'Shaw Island School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19933343,
        AWATER: 24106241,
        INTPTLAT: '+48.5688139',
        INTPTLON: '-122.9575386',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0176, 47.9031],
            [-118.8722, 47.9253],
            [-118.83, 47.7429],
            [-118.8496, 47.5447],
            [-118.9142, 47.5456],
            [-118.978, 47.5534],
            [-118.9758, 47.7136],
            [-119.073, 47.7209],
            [-119.0176, 47.9031],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300090',
        NAME: 'Almira School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537236719,
        AWATER: 251564,
        INTPTLAT: '+47.7560052',
        INTPTLON: '-118.9276085',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1031, 46.2486],
            [-120.0204, 46.2389],
            [-119.867, 46.1955],
            [-119.8663, 46.0407],
            [-119.9901, 46.041],
            [-120.1231, 46.1065],
            [-120.1031, 46.2486],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304650',
        NAME: 'Mabton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384417224,
        AWATER: 1918685,
        INTPTLAT: '+46.1431951',
        INTPTLON: '-120.0001587',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9373, 46.5626],
            [-119.9368, 46.4761],
            [-120.1254, 46.4767],
            [-120.2901, 46.5201],
            [-120.3137, 46.5206],
            [-120.4683, 46.5351],
            [-120.4664, 46.5774],
            [-120.5093, 46.6302],
            [-120.2352, 46.6496],
            [-120.2557, 46.5624],
            [-119.9373, 46.5626],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305370',
        NAME: 'East Valley School District (Yakima)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444946693,
        AWATER: 992497,
        INTPTLAT: '+46.5533660',
        INTPTLON: '-120.2210679',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8849, 46.5217],
            [-120.5495, 46.5353],
            [-120.5125, 46.5354],
            [-120.4683, 46.5351],
            [-120.3137, 46.5206],
            [-120.3754, 46.4043],
            [-120.4386, 46.4046],
            [-120.5021, 46.3025],
            [-120.523, 46.4339],
            [-120.6282, 46.4781],
            [-120.8848, 46.4782],
            [-120.8849, 46.5217],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309480',
        NAME: 'Wapato School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453268329,
        AWATER: 491424,
        INTPTLAT: '+46.4558048',
        INTPTLON: '-120.5391438',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2901, 46.5201],
            [-120.1254, 46.4767],
            [-120.1881, 46.4764],
            [-120.2228, 46.3714],
            [-120.2935, 46.4069],
            [-120.2901, 46.5201],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5310170',
        NAME: 'Zillah School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114669286,
        AWATER: 0,
        INTPTLAT: '+46.4497629',
        INTPTLON: '-120.2342745',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4124, 46.5786],
            [-121.0117, 46.5788],
            [-120.8665, 46.5784],
            [-120.8242, 46.6219],
            [-120.6201, 46.6219],
            [-120.5809, 46.6156],
            [-120.5495, 46.5353],
            [-120.8849, 46.5217],
            [-120.9972, 46.4802],
            [-121.4344, 46.4744],
            [-121.4124, 46.5786],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309720',
        NAME: 'West Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 702033277,
        AWATER: 470963,
        INTPTLAT: '+46.5411898',
        INTPTLON: '-121.0171737',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5236, 46.3882],
            [-121.4027, 46.388],
            [-121.4344, 46.4744],
            [-120.9972, 46.4802],
            [-120.8849, 46.5217],
            [-120.8848, 46.4782],
            [-120.6282, 46.4781],
            [-120.523, 46.4339],
            [-120.5021, 46.3025],
            [-120.4999, 46.2147],
            [-120.6143, 46.2148],
            [-120.6143, 46.0408],
            [-121.2373, 46.0447],
            [-121.4272, 46.0445],
            [-121.5239, 46.044],
            [-121.5236, 46.3882],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305280',
        NAME: 'Mount Adams School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3498992316,
        AWATER: 2110949,
        INTPTLAT: '+46.2499006',
        INTPTLON: '-121.0053939',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2228, 46.3714],
            [-120.1881, 46.4764],
            [-120.1254, 46.4767],
            [-120.1031, 46.2486],
            [-120.1231, 46.1065],
            [-120.3664, 46.0415],
            [-120.377, 46.2648],
            [-120.2727, 46.2943],
            [-120.2228, 46.3714],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303180',
        NAME: 'Granger School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 616070016,
        AWATER: 788096,
        INTPTLAT: '+46.1999947',
        INTPTLON: '-120.2274128',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5021, 46.3025],
            [-120.4386, 46.4046],
            [-120.3754, 46.4043],
            [-120.3137, 46.5206],
            [-120.2901, 46.5201],
            [-120.2935, 46.4069],
            [-120.2228, 46.3714],
            [-120.2727, 46.2943],
            [-120.377, 46.2648],
            [-120.3664, 46.0415],
            [-120.4899, 46.0416],
            [-120.6143, 46.0408],
            [-120.6143, 46.2148],
            [-120.4999, 46.2147],
            [-120.5021, 46.3025],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308970',
        NAME: 'Toppenish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 695250522,
        AWATER: 29520,
        INTPTLAT: '+46.2272835',
        INTPTLON: '-120.4333782',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4664, 46.5774],
            [-120.4683, 46.5351],
            [-120.5125, 46.5354],
            [-120.4664, 46.5774],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309150',
        NAME: 'Union Gap School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13119468,
        AWATER: 279809,
        INTPTLAT: '+46.5544287',
        INTPTLON: '-120.4920734',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.1254, 46.4767],
            [-119.9368, 46.4761],
            [-119.9373, 46.5626],
            [-119.8769, 46.5624],
            [-119.8743, 46.534],
            [-119.8736, 46.3168],
            [-119.9677, 46.3021],
            [-120.0204, 46.2389],
            [-120.1031, 46.2486],
            [-120.1254, 46.4767],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308670',
        NAME: 'Sunnyside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 471786832,
        AWATER: 1540934,
        INTPTLAT: '+46.3908780',
        INTPTLON: '-119.9958088',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6201, 46.6219],
            [-120.8242, 46.6219],
            [-120.8665, 46.5784],
            [-121.0117, 46.5788],
            [-121.0126, 46.6496],
            [-120.887, 46.6516],
            [-120.7831, 46.7423],
            [-120.7213, 46.7312],
            [-120.6201, 46.6219],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303510',
        NAME: 'Highland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288084354,
        AWATER: 0,
        INTPTLAT: '+46.6494965',
        INTPTLON: '-120.8168617',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3038, 47.7148],
            [-117.2829, 47.744],
            [-117.2402, 47.744],
            [-117.2721, 47.6715],
            [-117.2935, 47.6207],
            [-117.3038, 47.7148],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309690',
        NAME: 'West Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47721074,
        AWATER: 342699,
        INTPTLAT: '+47.6940059',
        INTPTLON: '-117.2787791',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4225, 47.5874],
            [-117.3798, 47.573],
            [-117.3684, 47.4779],
            [-117.2492, 47.4889],
            [-117.1957, 47.4273],
            [-117.0399, 47.4052],
            [-117.0399, 47.3105],
            [-117.0886, 47.2595],
            [-117.1931, 47.2597],
            [-117.215, 47.2596],
            [-117.4779, 47.2959],
            [-117.4551, 47.5082],
            [-117.4225, 47.5874],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304380',
        NAME: 'Liberty School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713011296,
        AWATER: 261817,
        INTPTLAT: '+47.3782493',
        INTPTLON: '-117.2866515',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5073, 47.7588],
            [-117.3471, 47.7436],
            [-117.3038, 47.7148],
            [-117.2935, 47.6207],
            [-117.2977, 47.5943],
            [-117.3798, 47.573],
            [-117.4225, 47.5874],
            [-117.5182, 47.6648],
            [-117.511, 47.73],
            [-117.5073, 47.7588],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308250',
        NAME: 'Spokane Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214886769,
        AWATER: 2348544,
        INTPTLAT: '+47.6629956',
        INTPTLON: '-117.3996556',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1138, 45.8108],
            [-121.0516, 45.8403],
            [-120.9058, 45.7957],
            [-120.8852, 45.6937],
            [-121.0517, 45.6948],
            [-121.1141, 45.7239],
            [-121.1138, 45.8108],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301050',
        NAME: 'Centerville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271813829,
        AWATER: 0,
        INTPTLAT: '+45.7566596',
        INTPTLON: '-120.9806208',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2373, 46.0447],
            [-120.6143, 46.0408],
            [-120.4899, 46.0416],
            [-120.4901, 45.9544],
            [-120.542, 45.8674],
            [-120.4902, 45.8094],
            [-120.3975, 45.7791],
            [-120.4531, 45.6963],
            [-120.6536, 45.7372],
            [-120.8857, 45.65],
            [-120.8852, 45.6937],
            [-120.9058, 45.7957],
            [-121.0516, 45.8403],
            [-121.0923, 45.914],
            [-121.166, 46.031],
            [-121.2373, 46.0447],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303090',
        NAME: 'Goldendale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1519850677,
        AWATER: 22745809,
        INTPTLAT: '+45.8841015',
        INTPTLON: '-120.7655785',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0517, 45.6948],
            [-120.8852, 45.6937],
            [-120.8857, 45.65],
            [-120.9159, 45.6413],
            [-121.0714, 45.6509],
            [-121.0517, 45.6948],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5310020',
        NAME: 'Wishram School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59201909,
        AWATER: 9890850,
        INTPTLAT: '+45.6808234',
        INTPTLON: '-120.9515702',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2031, 46.6631],
            [-123.0224, 46.562],
            [-123.0524, 46.4312],
            [-123.2178, 46.3851],
            [-123.2393, 46.3848],
            [-123.2386, 46.5171],
            [-123.2031, 46.6631],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300630',
        NAME: 'Boistfort School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374134409,
        AWATER: 1393593,
        INTPTLAT: '+46.5164382',
        INTPTLON: '-123.1504578',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3926, 48.0752],
            [-117.1753, 48.0823],
            [-117.1239, 47.9588],
            [-117.0423, 47.9584],
            [-117.0426, 47.8745],
            [-117.0887, 47.8742],
            [-117.0943, 47.9174],
            [-117.3561, 47.8604],
            [-117.4327, 47.8819],
            [-117.4369, 48.0472],
            [-117.3926, 48.0752],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307440',
        NAME: 'Riverside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 487100301,
        AWATER: 3350562,
        INTPTLAT: '+47.9708338',
        INTPTLON: '-117.2670521',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5356, 47.8306],
            [-117.4327, 47.8819],
            [-117.3561, 47.8604],
            [-117.0943, 47.9174],
            [-117.0887, 47.8742],
            [-117.2402, 47.744],
            [-117.2829, 47.744],
            [-117.3471, 47.7436],
            [-117.5073, 47.7588],
            [-117.5356, 47.8306],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304920',
        NAME: 'Mead School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426889337,
        AWATER: 152796,
        INTPTLAT: '+47.8206556',
        INTPTLON: '-117.3210462',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6495, 47.9467],
            [-117.5356, 47.8306],
            [-117.5073, 47.7588],
            [-117.511, 47.73],
            [-117.5609, 47.7262],
            [-117.7127, 47.8464],
            [-117.7354, 47.9191],
            [-117.6495, 47.9467],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305640',
        NAME: 'Nine Mile Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183932689,
        AWATER: 12522576,
        INTPTLAT: '+47.8404992',
        INTPTLON: '-117.6274387',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6127, 45.8989],
            [-121.4459, 45.8987],
            [-121.3325, 45.8914],
            [-121.3835, 45.6993],
            [-121.4411, 45.6972],
            [-121.6093, 45.7097],
            [-121.6126, 45.8704],
            [-121.6127, 45.8989],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309810',
        NAME: 'White Salmon Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426544613,
        AWATER: 9676809,
        INTPTLAT: '+45.7989948',
        INTPTLON: '-121.4824586',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.9632, 48.2107],
            [-120.8728, 48.2439],
            [-120.5543, 48.1933],
            [-120.4572, 48.0623],
            [-120.2264, 47.9927],
            [-120.1987, 47.888],
            [-120.0936, 47.8531],
            [-120.0488, 47.9606],
            [-119.9484, 47.9892],
            [-119.9046, 47.9105],
            [-119.8779, 47.8231],
            [-119.9539, 47.7722],
            [-120.0936, 47.7578],
            [-120.185, 47.7615],
            [-120.4189, 47.9398],
            [-120.4125, 47.9749],
            [-120.7223, 48.1668],
            [-120.8169, 48.1484],
            [-120.9062, 48.1638],
            [-120.9632, 48.2107],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301200',
        NAME: 'Lake Chelan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 961793813,
        AWATER: 73704636,
        INTPTLAT: '+47.9756412',
        INTPTLON: '-120.3110756',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7017, 49.0003],
            [-118.9028, 49.0004],
            [-118.9247, 48.8283],
            [-119.1654, 48.8566],
            [-119.3635, 48.8579],
            [-119.4513, 48.8286],
            [-119.624, 48.9158],
            [-119.7017, 49.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306420',
        NAME: 'Oroville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 887195329,
        AWATER: 17119949,
        INTPTLAT: '+48.9648491',
        INTPTLON: '-119.2595588',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6209, 47.104],
            [-119.4479, 47.2678],
            [-119.3319, 47.2656],
            [-119.2986, 47.3376],
            [-119.1492, 47.3278],
            [-119.065, 47.2765],
            [-119.0441, 47.2031],
            [-119.0822, 47.0494],
            [-119.1666, 46.9906],
            [-119.2801, 46.9704],
            [-119.365, 46.9998],
            [-119.6209, 47.0014],
            [-119.6209, 47.104],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305220',
        NAME: 'Moses Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1106634945,
        AWATER: 81622441,
        INTPTLAT: '+47.1383851',
        INTPTLON: '-119.3040983',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8789, 48.8022],
            [-117.7341, 48.7928],
            [-117.7585, 48.6992],
            [-117.8798, 48.7436],
            [-117.8789, 48.8022],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306270',
        NAME: 'Onion Creek School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132945923,
        AWATER: 6608,
        INTPTLAT: '+48.7955853',
        INTPTLON: '-117.7738950',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-121.6839, 48.6629],
              [-121.69, 48.6498],
              [-121.7384, 48.6587],
              [-121.6839, 48.6629],
            ],
          ],
          [
            [
              [-122.2693, 48.6454],
              [-121.964, 48.645],
              [-121.9621, 48.7166],
              [-121.8515, 48.7166],
              [-121.7384, 48.6587],
              [-121.9202, 48.6448],
              [-121.9226, 48.2977],
              [-122.1836, 48.2973],
              [-122.2702, 48.3777],
              [-122.2971, 48.471],
              [-122.313, 48.6021],
              [-122.2693, 48.6454],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307740',
        NAME: 'Sedro-Woolley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1054987556,
        AWATER: 18868560,
        INTPTLAT: '+48.4976768',
        INTPTLON: '-122.0839869',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6298, 46.8258],
            [-117.5037, 46.9537],
            [-117.4367, 46.9727],
            [-117.2561, 46.9757],
            [-117.2131, 46.9578],
            [-117.1811, 46.8298],
            [-117.3283, 46.7428],
            [-117.4841, 46.6978],
            [-117.6012, 46.6727],
            [-117.7562, 46.6844],
            [-117.6345, 46.7445],
            [-117.6298, 46.8258],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301440',
        NAME: 'Colfax School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 827483500,
        AWATER: 6866137,
        INTPTLAT: '+46.8211635',
        INTPTLON: '-117.4339544',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4356, 46.6619],
            [-117.3176, 46.617],
            [-117.0396, 46.6423],
            [-117.0396, 46.6381],
            [-117.0398, 46.457],
            [-117.0398, 46.4543],
            [-117.2379, 46.4701],
            [-117.2539, 46.5451],
            [-117.4356, 46.6619],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301500',
        NAME: 'Colton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365581621,
        AWATER: 9625280,
        INTPTLAT: '+46.5689096',
        INTPTLON: '-117.2000617',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1811, 46.8298],
            [-117.0397, 46.8222],
            [-117.0396, 46.6423],
            [-117.3176, 46.617],
            [-117.4356, 46.6619],
            [-117.4841, 46.6978],
            [-117.3283, 46.7428],
            [-117.1811, 46.8298],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306930',
        NAME: 'Pullman School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 535032430,
        AWATER: 1292179,
        INTPTLAT: '+46.7161665',
        INTPTLON: '-117.2095265',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.7281, 47.2228],
            [-117.6656, 47.2598],
            [-117.4912, 47.277],
            [-117.4887, 47.2668],
            [-117.407, 47.0349],
            [-117.4367, 46.9727],
            [-117.5037, 46.9537],
            [-117.6407, 47.0038],
            [-117.7465, 46.9742],
            [-117.7797, 47.0906],
            [-117.8531, 47.09],
            [-117.7919, 47.1929],
            [-117.7281, 47.2228],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308310',
        NAME: 'St. John School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 746358929,
        AWATER: 10531514,
        INTPTLAT: '+47.1212438',
        INTPTLON: '-117.6091151',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3143, 48.1233],
            [-122.1723, 48.1523],
            [-122.054, 48.1013],
            [-122.032, 48.065],
            [-122.1389, 47.9927],
            [-122.2649, 48.0214],
            [-122.3431, 48.0365],
            [-122.3143, 48.1233],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304860',
        NAME: 'Marysville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173551236,
        AWATER: 47833896,
        INTPTLAT: '+48.0754534',
        INTPTLON: '-122.2159292',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6251, 46.826],
            [-120.5095, 46.7813],
            [-120.3819, 46.7801],
            [-120.3815, 46.7377],
            [-119.973, 46.7372],
            [-119.8741, 46.6286],
            [-119.8769, 46.5624],
            [-119.9373, 46.5626],
            [-120.2557, 46.5624],
            [-120.2352, 46.6496],
            [-120.5093, 46.6302],
            [-120.5779, 46.6313],
            [-120.6362, 46.7094],
            [-120.6251, 46.826],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307770',
        NAME: 'Selah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 897616829,
        AWATER: 9416444,
        INTPTLAT: '+46.6857636',
        INTPTLON: '-120.2765564',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.973, 46.7372],
            [-119.9286, 46.7963],
            [-119.3691, 46.7956],
            [-119.3689, 46.6782],
            [-119.4532, 46.6792],
            [-119.5157, 46.7274],
            [-119.6261, 46.645],
            [-119.8741, 46.6286],
            [-119.973, 46.7372],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309360',
        NAME: 'Wahluke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 646613852,
        AWATER: 28123757,
        INTPTLAT: '+46.7275500',
        INTPTLON: '-119.7098858',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5013, 48.484],
            [-117.5007, 48.5717],
            [-117.327, 48.5709],
            [-117.3271, 48.6562],
            [-117.0337, 48.6553],
            [-117.0348, 48.5689],
            [-117.1086, 48.4835],
            [-117.1755, 48.484],
            [-117.1986, 48.2103],
            [-117.4375, 48.1945],
            [-117.5022, 48.1947],
            [-117.5013, 48.484],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301920',
        NAME: 'Cusick School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1167193136,
        AWATER: 23866419,
        INTPTLAT: '+48.4294250',
        INTPTLON: '-117.3079238',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1143, 46.3862],
            [-122.0805, 46.0405],
            [-121.9972, 46.0402],
            [-122.004, 45.6945],
            [-121.9613, 45.6359],
            [-122.1668, 45.5804],
            [-122.1256, 45.6075],
            [-122.1224, 46.2111],
            [-122.1143, 46.3862],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307950',
        NAME: 'Skamania School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 569623897,
        AWATER: 18494339,
        INTPTLAT: '+45.8658903',
        INTPTLON: '-122.0652182',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1975, 45.5673],
            [-122.2595, 45.5451],
            [-122.2596, 45.5779],
            [-122.1975, 45.5673],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305340',
        NAME: 'Mount Pleasant School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17115430,
        AWATER: 3003143,
        INTPTLAT: '+45.5696544',
        INTPTLON: '-122.2339631',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.215, 47.2596],
            [-117.1931, 47.2597],
            [-117.1386, 47.1236],
            [-117.0397, 47.1231],
            [-117.0397, 47.0489],
            [-117.2234, 47.0642],
            [-117.255, 47.0204],
            [-117.3314, 47.0574],
            [-117.341, 47.0651],
            [-117.3641, 47.1522],
            [-117.215, 47.2596],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305970',
        NAME: 'Oakesdale School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324638170,
        AWATER: 0,
        INTPTLAT: '+47.1230132',
        INTPTLON: '-117.2219279',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.407, 47.0349],
            [-117.341, 47.0651],
            [-117.3314, 47.0574],
            [-117.255, 47.0204],
            [-117.2561, 46.9757],
            [-117.4367, 46.9727],
            [-117.407, 47.0349],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308490',
        NAME: 'Steptoe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109934497,
        AWATER: 0,
        INTPTLAT: '+47.0115002',
        INTPTLON: '-117.3506981',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1931, 47.2597],
            [-117.0886, 47.2595],
            [-117.0399, 47.3105],
            [-117.0397, 47.1273],
            [-117.0397, 47.1231],
            [-117.1386, 47.1236],
            [-117.1931, 47.2597],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308790',
        NAME: 'Tekoa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165577802,
        AWATER: 0,
        INTPTLAT: '+47.2062914',
        INTPTLON: '-117.1032663',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9601, 47.0171],
            [-117.8531, 47.09],
            [-117.7797, 47.0906],
            [-117.7465, 46.9742],
            [-117.6407, 47.0038],
            [-117.5037, 46.9537],
            [-117.6298, 46.8258],
            [-117.7371, 46.8255],
            [-117.7664, 46.8845],
            [-117.9122, 46.9074],
            [-117.9708, 46.8673],
            [-117.9601, 47.0171],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302520',
        NAME: 'Endicott School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574645195,
        AWATER: 416825,
        INTPTLAT: '+46.9613812',
        INTPTLON: '-117.7472638',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8518, 46.6248],
            [-117.7562, 46.6844],
            [-117.6012, 46.6727],
            [-117.4841, 46.6978],
            [-117.4356, 46.6619],
            [-117.2539, 46.5451],
            [-117.2379, 46.4701],
            [-117.3576, 46.4715],
            [-117.4196, 46.412],
            [-117.4196, 46.2793],
            [-117.4196, 46.1219],
            [-117.48, 46.122],
            [-117.4801, 45.9978],
            [-117.6032, 45.9989],
            [-117.6118, 46.3385],
            [-117.6751, 46.3376],
            [-117.7373, 46.4714],
            [-117.8633, 46.4709],
            [-117.8518, 46.6248],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306780',
        NAME: 'Pomeroy School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1764919327,
        AWATER: 19365245,
        INTPTLAT: '+46.4431655',
        INTPTLON: '-117.5500233',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5994, 45.6825],
            [-122.506, 45.6937],
            [-122.3935, 45.6943],
            [-122.4758, 45.5787],
            [-122.5823, 45.6041],
            [-122.5994, 45.6825],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302700',
        NAME: 'Evergreen School District (Clark)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121830216,
        AWATER: 1732660,
        INTPTLAT: '+45.6473987',
        INTPTLON: '-122.5085799',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.761, 45.7504],
            [-122.6462, 45.7363],
            [-122.5994, 45.6825],
            [-122.5823, 45.6041],
            [-122.586, 45.6047],
            [-122.635, 45.6093],
            [-122.7436, 45.6469],
            [-122.761, 45.7504],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309270',
        NAME: 'Vancouver Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136179084,
        AWATER: 22487702,
        INTPTLAT: '+45.6731852',
        INTPTLON: '-122.6754711',
        ELSDLEA: '',
        UNSDLEA: '09270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2379, 46.4701],
            [-117.0398, 46.4543],
            [-117.0526, 46.3747],
            [-117.1335, 46.3378],
            [-117.256, 46.338],
            [-117.4196, 46.2793],
            [-117.4196, 46.412],
            [-117.3576, 46.4715],
            [-117.2379, 46.4701],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301320',
        NAME: 'Clarkston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406867893,
        AWATER: 4700824,
        INTPTLAT: '+46.3755419',
        INTPTLON: '-117.2903004',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2178, 46.3851],
            [-123.0524, 46.4312],
            [-122.9283, 46.4381],
            [-122.9322, 46.3874],
            [-122.698, 46.3872],
            [-122.8655, 46.3152],
            [-122.8655, 46.2573],
            [-122.9895, 46.2283],
            [-123.0299, 46.3007],
            [-123.2172, 46.3698],
            [-123.2178, 46.3851],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300990',
        NAME: 'Castle Rock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420558737,
        AWATER: 4784841,
        INTPTLAT: '+46.3414882',
        INTPTLON: '-122.9571654',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.698, 46.3872],
            [-122.4894, 46.3874],
            [-122.3022, 46.3857],
            [-122.3035, 46.2986],
            [-122.3651, 46.2542],
            [-122.5534, 46.2562],
            [-122.6681, 46.2272],
            [-122.8655, 46.2573],
            [-122.8655, 46.3152],
            [-122.698, 46.3872],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309030',
        NAME: 'Toutle Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588291727,
        AWATER: 6548343,
        INTPTLAT: '+46.3159647',
        INTPTLON: '-122.5607689',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8831, 46.0539],
            [-122.7007, 46.0611],
            [-122.6977, 46.1417],
            [-122.5402, 46.1415],
            [-122.5403, 46.2273],
            [-122.2463, 46.2114],
            [-122.2458, 46.1282],
            [-122.4931, 46.1266],
            [-122.5556, 46.0406],
            [-122.638, 46.0408],
            [-122.6993, 45.9672],
            [-122.8118, 45.9531],
            [-122.8386, 45.9825],
            [-122.8831, 46.0539],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303810',
        NAME: 'Kalama School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552689731,
        AWATER: 5058351,
        INTPTLAT: '+46.1117084',
        INTPTLON: '-122.5695858',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0134, 46.6845],
            [-122.9608, 46.598],
            [-122.9708, 46.562],
            [-122.981, 46.5543],
            [-123.0224, 46.562],
            [-123.2031, 46.6631],
            [-123.2662, 46.7062],
            [-123.0719, 46.7208],
            [-123.0134, 46.6845],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300060',
        NAME: 'Adna School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202281702,
        AWATER: 378278,
        INTPTLAT: '+46.6606232',
        INTPTLON: '-123.0889462',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.981, 46.5543],
            [-122.9708, 46.562],
            [-122.9075, 46.5398],
            [-122.981, 46.5543],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302640',
        NAME: 'Evaline School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20329765,
        AWATER: 0,
        INTPTLAT: '+46.5401050',
        INTPTLON: '-122.9449103',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9608, 46.598],
            [-122.8295, 46.5616],
            [-122.7773, 46.5322],
            [-122.8558, 46.5251],
            [-122.9075, 46.5398],
            [-122.9708, 46.562],
            [-122.9608, 46.598],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305490',
        NAME: 'Napavine School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62090105,
        AWATER: 0,
        INTPTLAT: '+46.5613810',
        INTPTLON: '-122.8785914',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0224, 46.562],
            [-122.981, 46.5543],
            [-122.9075, 46.5398],
            [-122.8558, 46.5251],
            [-122.9283, 46.4381],
            [-123.0524, 46.4312],
            [-123.0224, 46.562],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309930',
        NAME: 'Winlock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131677566,
        AWATER: 0,
        INTPTLAT: '+46.4920893',
        INTPTLON: '-122.9558920',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2463, 46.2114],
            [-122.2417, 46.3853],
            [-122.241, 46.3864],
            [-121.5236, 46.3882],
            [-121.5239, 46.044],
            [-121.6125, 46.0438],
            [-121.6127, 45.8989],
            [-121.6126, 45.8704],
            [-121.7115, 45.8701],
            [-121.7117, 45.6947],
            [-121.8113, 45.7068],
            [-121.9222, 45.6491],
            [-121.9613, 45.6359],
            [-122.004, 45.6945],
            [-121.9972, 46.0402],
            [-122.0805, 46.0405],
            [-122.1143, 46.3862],
            [-122.2396, 46.3842],
            [-122.2457, 46.0632],
            [-122.2458, 46.1282],
            [-122.2463, 46.2114],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308520',
        NAME: 'Stevenson-Carson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2693196048,
        AWATER: 23325172,
        INTPTLAT: '+46.0838347',
        INTPTLON: '-121.8198375',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4802, 47.6418],
            [-120.3878, 47.6116],
            [-120.4103, 47.4362],
            [-120.5569, 47.4385],
            [-120.4802, 47.6418],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300960',
        NAME: 'Cashmere School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253729364,
        AWATER: 993521,
        INTPTLAT: '+47.5256270',
        INTPTLON: '-120.4630806',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6126, 45.8704],
            [-121.6093, 45.7097],
            [-121.7117, 45.6947],
            [-121.7115, 45.8701],
            [-121.6126, 45.8704],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305040',
        NAME: 'Mill A School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143915167,
        AWATER: 5151785,
        INTPTLAT: '+45.7768839',
        INTPTLON: '-121.6655019',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1929, 48.395],
            [-118.0848, 48.3943],
            [-118.0314, 48.3509],
            [-118.0727, 48.267],
            [-118.1493, 48.2434],
            [-118.1929, 48.395],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302730',
        NAME: 'Evergreen School District (Stevens)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120162620,
        AWATER: 18187590,
        INTPTLAT: '+48.3366922',
        INTPTLON: '-118.1246689',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8457, 48.0501],
            [-118.8444, 48.3965],
            [-118.4551, 48.3968],
            [-118.4658, 47.8822],
            [-118.5369, 47.8768],
            [-118.5704, 47.9323],
            [-118.7454, 47.9032],
            [-118.8458, 47.9635],
            [-118.8457, 48.0501],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303870',
        NAME: 'Keller School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1501673840,
        AWATER: 39498747,
        INTPTLAT: '+48.1653089',
        INTPTLON: '-118.6611414',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6499, 48.1357],
            [-117.5022, 48.1947],
            [-117.4375, 48.1945],
            [-117.4376, 48.1346],
            [-117.587, 48.1205],
            [-117.6291, 48.0191],
            [-117.6499, 48.1357],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304500',
        NAME: 'Loon Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164592623,
        AWATER: 7232316,
        INTPTLAT: '+48.0704020',
        INTPTLON: '-117.6257015',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8627, 48.254],
            [-122.5939, 48.2367],
            [-122.5242, 48.1255],
            [-122.5307, 48.0368],
            [-122.6386, 48.0353],
            [-122.7096, 48.1382],
            [-122.8627, 48.254],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301800',
        NAME: 'Coupeville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116054663,
        AWATER: 178191231,
        INTPTLAT: '+48.1725133',
        INTPTLON: '-122.6479730',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6964, 47.7074],
            [-123.1241, 47.7084],
            [-123.1241, 47.7813],
            [-122.8588, 47.781],
            [-122.8519, 47.6654],
            [-122.9505, 47.6063],
            [-123.5044, 47.607],
            [-123.5044, 47.5176],
            [-123.6326, 47.518],
            [-123.6321, 47.6204],
            [-123.6964, 47.7074],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300750',
        NAME: 'Brinnon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 893992233,
        AWATER: 53875628,
        INTPTLAT: '+47.6587863',
        INTPTLON: '-123.2522163',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.843, 47.9259],
            [-122.8106, 48.0566],
            [-122.7096, 48.1382],
            [-122.6386, 48.0353],
            [-122.6068, 47.973],
            [-122.5994, 47.8748],
            [-122.7272, 47.7798],
            [-122.7377, 47.758],
            [-122.7357, 47.8389],
            [-122.8433, 47.8678],
            [-122.843, 47.9259],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301290',
        NAME: 'Chimacum School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267864803,
        AWATER: 176489976,
        INTPTLAT: '+47.9613902',
        INTPTLON: '-122.7153604',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.54, 47.7237],
            [-124.2276, 47.7226],
            [-124.2258, 47.7948],
            [-123.926, 47.795],
            [-123.926, 47.8815],
            [-123.6967, 47.8811],
            [-123.6964, 47.7074],
            [-123.6321, 47.6204],
            [-123.6326, 47.518],
            [-124.0471, 47.5181],
            [-124.1793, 47.5336],
            [-124.4297, 47.5336],
            [-124.459, 47.6267],
            [-124.5478, 47.6383],
            [-124.54, 47.7237],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301380',
        NAME: 'Queets-Clearwater School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1658422258,
        AWATER: 197585569,
        INTPTLAT: '+47.6782098',
        INTPTLON: '-123.9779548',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6967, 47.8811],
            [-123.2923, 47.8669],
            [-122.9492, 47.8668],
            [-122.9495, 47.9693],
            [-122.843, 47.9259],
            [-122.8433, 47.8678],
            [-122.7357, 47.8389],
            [-122.7377, 47.758],
            [-122.7832, 47.6728],
            [-122.8519, 47.6654],
            [-122.8588, 47.781],
            [-123.1241, 47.7813],
            [-123.1241, 47.7084],
            [-123.6964, 47.7074],
            [-123.6967, 47.8811],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306990',
        NAME: 'Quilcene School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1125447011,
        AWATER: 83365271,
        INTPTLAT: '+47.7915332',
        INTPTLON: '-123.3134349',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6386, 48.0353],
            [-122.5307, 48.0368],
            [-122.5242, 48.1255],
            [-122.3469, 48.0294],
            [-122.3285, 47.9638],
            [-122.3936, 47.8647],
            [-122.4295, 47.8276],
            [-122.5106, 47.9337],
            [-122.6068, 47.973],
            [-122.6386, 48.0353],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308190',
        NAME: 'South Whidbey School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164352349,
        AWATER: 213821006,
        INTPTLAT: '+47.9835929',
        INTPTLON: '-122.4644701',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0792, 47.3017],
            [-122.994, 47.3164],
            [-122.8148, 47.3068],
            [-122.8214, 47.1942],
            [-122.8366, 47.1876],
            [-122.9098, 47.165],
            [-122.9362, 47.1895],
            [-122.9916, 47.1993],
            [-123.0741, 47.2116],
            [-123.0792, 47.3017],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306750',
        NAME: 'Pioneer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173719328,
        AWATER: 59907781,
        INTPTLAT: '+47.2485479',
        INTPTLON: '-122.9476115',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1964, 45.8984],
            [-121.0923, 45.914],
            [-121.0516, 45.8403],
            [-121.1138, 45.8108],
            [-121.1964, 45.8984],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304080',
        NAME: 'Klickitat School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108760764,
        AWATER: 0,
        INTPTLAT: '+45.8467160',
        INTPTLON: '-121.1352923',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.5334, 47.47],
            [-119.5329, 47.5276],
            [-119.3947, 47.5309],
            [-119.3732, 47.4766],
            [-119.3731, 47.426],
            [-119.5329, 47.354],
            [-119.5334, 47.47],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308070',
        NAME: 'Soap Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184011494,
        AWATER: 9603253,
        INTPTLAT: '+47.4413043',
        INTPTLON: '-119.4586356',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8118, 45.9531],
            [-122.6993, 45.9672],
            [-122.638, 46.0408],
            [-122.5556, 46.0406],
            [-122.4931, 46.1266],
            [-122.2458, 46.1282],
            [-122.2457, 46.0632],
            [-122.2456, 46.0539],
            [-122.3578, 45.9569],
            [-122.4318, 45.9757],
            [-122.523, 45.9841],
            [-122.6391, 45.9271],
            [-122.7205, 45.8668],
            [-122.7855, 45.8505],
            [-122.8118, 45.9531],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5310050',
        NAME: 'Woodland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493664922,
        AWATER: 25731482,
        INTPTLAT: '+46.0254039',
        INTPTLON: '-122.5144866',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2403, 47.6577],
            [-122.1106, 47.6024],
            [-122.1264, 47.564],
            [-122.1698, 47.54],
            [-122.1974, 47.5494],
            [-122.2657, 47.6136],
            [-122.2403, 47.6577],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300390',
        NAME: 'Bellevue School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86351224,
        AWATER: 13665465,
        INTPTLAT: '+47.6079113',
        INTPTLON: '-122.1721126',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3557, 47.2775],
            [-122.2634, 47.2718],
            [-122.2623, 47.2576],
            [-122.2609, 47.2501],
            [-122.3943, 47.2376],
            [-122.3557, 47.2775],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302880',
        NAME: 'Fife Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30606993,
        AWATER: 635738,
        INTPTLAT: '+47.2486784',
        INTPTLON: '-122.3356875',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2704, 47.4306],
            [-122.1166, 47.4592],
            [-122.0281, 47.3233],
            [-122.0385, 47.3013],
            [-122.2497, 47.3405],
            [-122.292, 47.3937],
            [-122.2704, 47.4306],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303960',
        NAME: 'Kent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172094766,
        AWATER: 6383763,
        INTPTLAT: '+47.3804384',
        INTPTLON: '-122.1546595',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0332, 47.7323],
            [-122.0077, 47.7753],
            [-121.8619, 47.7772],
            [-121.5845, 47.7767],
            [-121.5855, 47.6866],
            [-121.7565, 47.6878],
            [-121.7581, 47.6149],
            [-121.9921, 47.6443],
            [-122.0332, 47.7323],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304560',
        NAME: 'Riverview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450675713,
        AWATER: 11625832,
        INTPTLAT: '+47.7135688',
        INTPTLON: '-121.8321555',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4344, 47.7264],
            [-122.432, 47.7328],
            [-122.2698, 47.7335],
            [-122.2403, 47.6577],
            [-122.2657, 47.6136],
            [-122.2332, 47.5184],
            [-122.2703, 47.4956],
            [-122.2937, 47.5102],
            [-122.4088, 47.4817],
            [-122.4451, 47.5446],
            [-122.454, 47.5766],
            [-122.4344, 47.7264],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307710',
        NAME: 'Seattle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221000337,
        AWATER: 152780326,
        INTPTLAT: '+47.6193489',
        INTPTLON: '-122.3514706',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5855, 47.6866],
            [-121.5845, 47.7767],
            [-121.5845, 47.7775],
            [-121.119, 47.7799],
            [-121.0789, 47.7393],
            [-121.1136, 47.5971],
            [-121.2843, 47.5188],
            [-121.5984, 47.5121],
            [-121.5855, 47.6866],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307980',
        NAME: 'Skykomish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 968723573,
        AWATER: 16479015,
        INTPTLAT: '+47.6213876',
        INTPTLON: '-121.3264726',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1296, 47.4702],
            [-122.0584, 47.4371],
            [-121.8443, 47.4851],
            [-121.7201, 47.3427],
            [-121.8483, 47.3145],
            [-122.0281, 47.3233],
            [-122.1166, 47.4592],
            [-122.1296, 47.4702],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308760',
        NAME: 'Tahoma School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311604969,
        AWATER: 3021831,
        INTPTLAT: '+47.3937802',
        INTPTLON: '-121.9248952',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1144, 47.2283],
            [-122.0385, 47.3013],
            [-122.0281, 47.3233],
            [-121.8483, 47.3145],
            [-121.7201, 47.3427],
            [-121.444, 47.3438],
            [-121.4327, 47.2936],
            [-121.3064, 47.191],
            [-121.3986, 47.1272],
            [-121.3797, 47.0868],
            [-121.5816, 47.1184],
            [-121.6301, 47.1535],
            [-121.7967, 47.1731],
            [-121.8391, 47.1434],
            [-122.0479, 47.1716],
            [-122.112, 47.2137],
            [-122.1144, 47.2283],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300001',
        NAME: 'Enumclaw School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1164217427,
        AWATER: 9743264,
        INTPTLAT: '+47.2384366',
        INTPTLON: '-121.6732881',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4088, 47.4817],
            [-122.2937, 47.5102],
            [-122.2667, 47.4451],
            [-122.2704, 47.4306],
            [-122.292, 47.3937],
            [-122.3559, 47.3753],
            [-122.4088, 47.4817],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303540',
        NAME: 'Highline School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80170272,
        AWATER: 33315807,
        INTPTLAT: '+47.4470514',
        INTPTLON: '-122.3382338',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2657, 47.6136],
            [-122.1974, 47.5494],
            [-122.2332, 47.5184],
            [-122.2657, 47.6136],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304980',
        NAME: 'Mercer Island School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16373823,
        AWATER: 19420987,
        INTPTLAT: '+47.5696880',
        INTPTLON: '-122.2326829',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9921, 47.6443],
            [-121.7581, 47.6149],
            [-121.7565, 47.6878],
            [-121.5855, 47.6866],
            [-121.5984, 47.5121],
            [-121.2843, 47.5188],
            [-121.3797, 47.4543],
            [-121.444, 47.3438],
            [-121.7201, 47.3427],
            [-121.8443, 47.4851],
            [-121.9923, 47.6014],
            [-121.9921, 47.6443],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308040',
        NAME: 'Snoqualmie Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 996512873,
        AWATER: 23613063,
        INTPTLAT: '+47.4982323',
        INTPTLON: '-121.6522865',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2937, 47.5102],
            [-122.2703, 47.4956],
            [-122.2667, 47.4451],
            [-122.2937, 47.5102],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308130',
        NAME: 'Tukwila School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14082629,
        AWATER: 363148,
        INTPTLAT: '+47.4762891',
        INTPTLON: '-122.2757401',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.438, 47.7781],
            [-122.271, 47.777],
            [-122.2699, 47.7337],
            [-122.2698, 47.7335],
            [-122.432, 47.7328],
            [-122.438, 47.7781],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307920',
        NAME: 'Shoreline School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39335504,
        AWATER: 17851808,
        INTPTLAT: '+47.7568401',
        INTPTLON: '-122.3497222',
        ELSDLEA: '',
        UNSDLEA: '07920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2021, 47.0849],
            [-123.0751, 47.0845],
            [-122.9362, 47.1895],
            [-122.9098, 47.165],
            [-123.0125, 47.0541],
            [-123.2014, 47.0681],
            [-123.2021, 47.0849],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303330',
        NAME: 'Griffin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85216035,
        AWATER: 23390882,
        INTPTLAT: '+47.0995730',
        INTPTLON: '-123.0216467',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.781, 46.9163],
            [-122.7383, 46.9524],
            [-122.6541, 46.9381],
            [-122.6158, 46.762],
            [-122.6368, 46.7627],
            [-122.781, 46.9163],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307110',
        NAME: 'Rainier School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141695408,
        AWATER: 2476467,
        INTPTLAT: '+46.8610395',
        INTPTLON: '-122.6879225',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1584, 46.9959],
            [-122.9048, 47.0253],
            [-122.8444, 46.9962],
            [-122.7809, 46.9669],
            [-122.7383, 46.9524],
            [-122.781, 46.9163],
            [-122.8761, 46.9419],
            [-123.0127, 46.8511],
            [-123.0978, 46.9384],
            [-123.1593, 46.939],
            [-123.1584, 46.9959],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309100',
        NAME: 'Tumwater School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293704747,
        AWATER: 4808430,
        INTPTLAT: '+46.9573407',
        INTPTLON: '-122.9733789',
        ELSDLEA: '',
        UNSDLEA: '09100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6638, 47.0103],
            [-122.6328, 47.0003],
            [-122.3996, 46.9441],
            [-122.4214, 46.8497],
            [-122.334, 46.8421],
            [-122.2027, 46.7626],
            [-122.4868, 46.762],
            [-122.6158, 46.762],
            [-122.6541, 46.9381],
            [-122.7383, 46.9524],
            [-122.7809, 46.9669],
            [-122.6638, 47.0103],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5310140',
        NAME: 'Yelm Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500279565,
        AWATER: 12749407,
        INTPTLAT: '+46.8694744',
        INTPTLON: '-122.5146196',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2827, 46.7365],
            [-123.1823, 46.7645],
            [-122.9714, 46.7643],
            [-122.9187, 46.807],
            [-122.6991, 46.7635],
            [-122.7816, 46.6632],
            [-123.0134, 46.6845],
            [-123.0719, 46.7208],
            [-123.2662, 46.7062],
            [-123.2827, 46.7365],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301140',
        NAME: 'Centralia School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370855416,
        AWATER: 3228144,
        INTPTLAT: '+46.7332832',
        INTPTLON: '-122.9332378',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.2843, 47.5188],
            [-121.1136, 47.5971],
            [-121.1051, 47.5897],
            [-121.1121, 47.1905],
            [-121.3064, 47.191],
            [-121.4327, 47.2936],
            [-121.444, 47.3438],
            [-121.3797, 47.4543],
            [-121.2843, 47.5188],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302340',
        NAME: 'Easton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 736756893,
        AWATER: 28192859,
        INTPTLAT: '+47.4389695',
        INTPTLON: '-121.2749839',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2594, 47.2614],
            [-120.0945, 47.2622],
            [-120.0472, 47.2255],
            [-120.0021, 47.1262],
            [-120.0429, 47.0735],
            [-119.9861, 46.971],
            [-119.9286, 46.7963],
            [-119.973, 46.7372],
            [-120.3815, 46.7377],
            [-120.3819, 46.7801],
            [-120.3842, 46.9119],
            [-120.4502, 46.9265],
            [-120.4295, 47.0449],
            [-120.2603, 47.0452],
            [-120.2594, 47.2614],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304050',
        NAME: 'Kittitas School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1540180128,
        AWATER: 33949076,
        INTPTLAT: '+46.9572773',
        INTPTLON: '-120.1792337',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2497, 47.3405],
            [-122.0385, 47.3013],
            [-122.1144, 47.2283],
            [-122.2402, 47.2575],
            [-122.2623, 47.2576],
            [-122.2634, 47.2718],
            [-122.2497, 47.3405],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300300',
        NAME: 'Auburn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145928864,
        AWATER: 1954480,
        INTPTLAT: '+47.2962908',
        INTPTLON: '-122.1573667',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.3054, 47.2446],
            [-124.1148, 47.2452],
            [-124.0541, 47.2985],
            [-124.0448, 47.1726],
            [-124.0874, 47.129],
            [-124.0901, 46.9317],
            [-124.2411, 46.9029],
            [-124.2513, 47.0967],
            [-124.3054, 47.2446],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305700',
        NAME: 'North Beach School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254809631,
        AWATER: 281341520,
        INTPTLAT: '+47.1053313',
        INTPTLON: '-124.1644465',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4899, 46.0416],
            [-120.3664, 46.0415],
            [-120.1231, 46.1065],
            [-119.9901, 46.041],
            [-119.9923, 45.9097],
            [-119.9089, 45.828],
            [-119.9995, 45.8125],
            [-120.0551, 45.791],
            [-120.4902, 45.8094],
            [-120.542, 45.8674],
            [-120.4901, 45.9544],
            [-120.4899, 46.0416],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300510',
        NAME: 'Bickleton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1180290885,
        AWATER: 9334595,
        INTPTLAT: '+45.9211636',
        INTPTLON: '-120.2220429',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4272, 46.0445],
            [-121.2373, 46.0447],
            [-121.166, 46.031],
            [-121.0923, 45.914],
            [-121.1964, 45.8984],
            [-121.3325, 45.8914],
            [-121.4459, 45.8987],
            [-121.4272, 46.0445],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303030',
        NAME: 'Glenwood School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372985562,
        AWATER: 136260,
        INTPTLAT: '+45.9758812',
        INTPTLON: '-121.2747475',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3325, 45.8914],
            [-121.1964, 45.8984],
            [-121.1138, 45.8108],
            [-121.1141, 45.7239],
            [-121.0517, 45.6948],
            [-121.0714, 45.6509],
            [-121.1292, 45.6116],
            [-121.2158, 45.6712],
            [-121.3835, 45.6993],
            [-121.3325, 45.8914],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304590',
        NAME: 'Lyle School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 443247898,
        AWATER: 12038533,
        INTPTLAT: '+45.7443179',
        INTPTLON: '-121.2333347',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8741, 46.6286],
            [-119.6261, 46.645],
            [-119.5157, 46.7274],
            [-119.4532, 46.6792],
            [-119.2718, 46.5233],
            [-119.2634, 46.4763],
            [-119.2367, 46.2609],
            [-119.3481, 46.2133],
            [-119.4325, 46.2423],
            [-119.4274, 46.3736],
            [-119.554, 46.3744],
            [-119.6156, 46.4754],
            [-119.6179, 46.5341],
            [-119.8743, 46.534],
            [-119.8769, 46.5624],
            [-119.8741, 46.6286],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307320',
        NAME: 'Richland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1160460473,
        AWATER: 36060932,
        INTPTLAT: '+46.5121696',
        INTPTLON: '-119.5581728',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8736, 46.3168],
            [-119.867, 46.1955],
            [-120.0204, 46.2389],
            [-119.9677, 46.3021],
            [-119.8736, 46.3168],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303150',
        NAME: 'Grandview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102720845,
        AWATER: 945042,
        INTPTLAT: '+46.2613499',
        INTPTLON: '-119.9187681',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.5369, 47.8768],
            [-118.4658, 47.8822],
            [-118.3409, 47.8944],
            [-118.3187, 47.9043],
            [-118.2702, 47.774],
            [-118.3341, 47.7226],
            [-118.3571, 47.5953],
            [-118.5705, 47.5803],
            [-118.6355, 47.5809],
            [-118.592, 47.6417],
            [-118.5811, 47.8598],
            [-118.5369, 47.8768],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301860',
        NAME: 'Creston School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651490786,
        AWATER: 15963632,
        INTPTLAT: '+47.7336450',
        INTPTLON: '-118.4436638',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.3187, 47.9043],
            [-118.2086, 47.9444],
            [-118.1486, 47.8866],
            [-118.0782, 47.8002],
            [-117.8642, 47.8445],
            [-117.8412, 47.8372],
            [-117.9352, 47.8224],
            [-118.079, 47.4941],
            [-118.1645, 47.5088],
            [-118.2309, 47.5797],
            [-118.3571, 47.5953],
            [-118.3341, 47.7226],
            [-118.2702, 47.774],
            [-118.3187, 47.9043],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302010',
        NAME: 'Davenport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 853942288,
        AWATER: 12975625,
        INTPTLAT: '+47.7117081',
        INTPTLON: '-118.1684719',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8412, 47.8372],
            [-117.7127, 47.8464],
            [-117.5609, 47.7262],
            [-117.5932, 47.6864],
            [-117.6688, 47.6721],
            [-117.7686, 47.5513],
            [-117.8229, 47.4206],
            [-118.0161, 47.436],
            [-118.09, 47.4069],
            [-118.079, 47.4941],
            [-117.9352, 47.8224],
            [-117.8412, 47.8372],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307210',
        NAME: 'Reardan-Edwall School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1016402572,
        AWATER: 4568492,
        INTPTLAT: '+47.6405733',
        INTPTLON: '-117.8535219',
        ELSDLEA: '',
        UNSDLEA: '07210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5809, 46.6156],
            [-120.5779, 46.6313],
            [-120.5093, 46.6302],
            [-120.4664, 46.5774],
            [-120.5125, 46.5354],
            [-120.5495, 46.5353],
            [-120.5809, 46.6156],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5310110',
        NAME: 'Yakima School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60472360,
        AWATER: 1275993,
        INTPTLAT: '+46.5893088',
        INTPTLON: '-120.5270576',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2334, 48.3095],
            [-119.1053, 48.3096],
            [-119.1049, 48.4821],
            [-118.8695, 48.4819],
            [-118.8444, 48.3965],
            [-118.8457, 48.0501],
            [-119.0136, 48.0633],
            [-119.1261, 48.1505],
            [-119.233, 48.1135],
            [-119.2334, 48.3095],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305550',
        NAME: 'Nespelem School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1038978947,
        AWATER: 10718263,
        INTPTLAT: '+48.2771857',
        INTPTLON: '-118.9985435',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0353, 48.6119],
            [-119.6908, 48.6128],
            [-119.5239, 48.5834],
            [-119.196, 48.5687],
            [-119.1964, 48.4818],
            [-119.1049, 48.4821],
            [-119.1053, 48.3096],
            [-119.2334, 48.3095],
            [-119.2351, 48.3527],
            [-119.4953, 48.3533],
            [-119.5872, 48.3954],
            [-119.6688, 48.5109],
            [-119.7551, 48.453],
            [-119.8847, 48.4531],
            [-120.0353, 48.4824],
            [-120.0353, 48.6119],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306220',
        NAME: 'Omak School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1279449575,
        AWATER: 7207277,
        INTPTLAT: '+48.4838572',
        INTPTLON: '-119.5153604',
        ELSDLEA: '',
        UNSDLEA: '06220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0355, 48.9995],
            [-119.7017, 49.0003],
            [-119.624, 48.9158],
            [-119.4513, 48.8286],
            [-119.3635, 48.8579],
            [-119.1654, 48.8566],
            [-118.9247, 48.8283],
            [-118.8365, 48.7994],
            [-118.8695, 48.6408],
            [-118.8695, 48.4819],
            [-119.1049, 48.4821],
            [-119.1964, 48.4818],
            [-119.196, 48.5687],
            [-119.5239, 48.5834],
            [-119.6908, 48.6128],
            [-120.0353, 48.6119],
            [-120.0355, 48.9995],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308940',
        NAME: 'Tonasket School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3224951083,
        AWATER: 19548308,
        INTPTLAT: '+48.7288196',
        INTPTLON: '-119.4892429',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9505, 47.6063],
            [-122.8519, 47.6654],
            [-122.7832, 47.6728],
            [-122.7377, 47.758],
            [-122.7272, 47.7798],
            [-122.6621, 47.6723],
            [-122.6032, 47.6683],
            [-122.5859, 47.6215],
            [-122.7362, 47.5561],
            [-122.821, 47.5425],
            [-123.0378, 47.5202],
            [-122.9505, 47.6063],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301080',
        NAME: 'Central Kitsap School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303322822,
        AWATER: 83420622,
        INTPTLAT: '+47.6152170',
        INTPTLON: '-122.8198221',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7272, 47.7798],
            [-122.5994, 47.8748],
            [-122.6068, 47.973],
            [-122.5106, 47.9337],
            [-122.4295, 47.8276],
            [-122.438, 47.7781],
            [-122.432, 47.7328],
            [-122.4344, 47.7264],
            [-122.5438, 47.7266],
            [-122.6032, 47.6683],
            [-122.6621, 47.6723],
            [-122.7272, 47.7798],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305760',
        NAME: 'North Kitsap School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254745911,
        AWATER: 185445200,
        INTPTLAT: '+47.8070819',
        INTPTLON: '-122.5704386',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7397, 48.4055],
            [-122.591, 48.4176],
            [-122.5374, 48.3391],
            [-122.4583, 48.2976],
            [-122.5939, 48.2367],
            [-122.8627, 48.254],
            [-122.8631, 48.3704],
            [-122.7397, 48.4055],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305940',
        NAME: 'Oak Harbor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156508977,
        AWATER: 265138883,
        INTPTLAT: '+48.3171230',
        INTPTLON: '-122.6825304',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5939, 48.2367],
            [-122.4583, 48.2976],
            [-122.2039, 48.2973],
            [-122.2912, 48.181],
            [-122.3143, 48.1233],
            [-122.3431, 48.0365],
            [-122.3469, 48.0294],
            [-122.5242, 48.1255],
            [-122.5939, 48.2367],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308340',
        NAME: 'Stanwood-Camano School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266700758,
        AWATER: 196386735,
        INTPTLAT: '+48.2001649',
        INTPTLON: '-122.3749198',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.4282, 48.2507],
            [-120.3837, 48.222],
            [-120.3625, 48.1688],
            [-120.0488, 47.9606],
            [-120.0936, 47.8531],
            [-120.1987, 47.888],
            [-120.2264, 47.9927],
            [-120.4572, 48.0623],
            [-120.5543, 48.1933],
            [-120.4282, 48.2507],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304740',
        NAME: 'Manson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486684111,
        AWATER: 52926550,
        INTPTLAT: '+48.0786451',
        INTPTLON: '-120.2989118',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1281, 47.99],
            [-121.1371, 48.0443],
            [-121.0154, 48.0751],
            [-120.9633, 48.1356],
            [-120.9062, 48.1638],
            [-120.8169, 48.1484],
            [-120.6666, 47.978],
            [-120.6051, 47.8314],
            [-120.5113, 47.8336],
            [-120.5232, 47.7014],
            [-120.4802, 47.6418],
            [-120.5569, 47.4385],
            [-120.5566, 47.307],
            [-120.7331, 47.3934],
            [-120.9243, 47.4334],
            [-121.1051, 47.5897],
            [-121.1136, 47.5971],
            [-121.0789, 47.7393],
            [-121.119, 47.7799],
            [-121.1519, 47.8668],
            [-121.1281, 47.99],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300950',
        NAME: 'Cascade School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3023827772,
        AWATER: 21557846,
        INTPTLAT: '+47.6886243',
        INTPTLON: '-120.9181655',
        ELSDLEA: '',
        UNSDLEA: '00950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4418, 48.7409],
            [-118.1734, 48.7406],
            [-118.1164, 48.674],
            [-117.9982, 48.8137],
            [-117.9554, 48.8023],
            [-117.9591, 48.5979],
            [-118.0657, 48.5102],
            [-118.0848, 48.3943],
            [-118.1929, 48.395],
            [-118.2065, 48.4703],
            [-118.4554, 48.4816],
            [-118.4775, 48.6536],
            [-118.4418, 48.7409],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303990',
        NAME: 'Kettle Falls School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1034214254,
        AWATER: 63923210,
        INTPTLAT: '+48.5988383',
        INTPTLON: '-118.2137192',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2736, 47.5522],
            [-120.2445, 47.5791],
            [-120.1973, 47.5795],
            [-120.1327, 47.5798],
            [-120.1331, 47.4488],
            [-120.0055, 47.392],
            [-120.09, 47.32],
            [-120.1296, 47.3663],
            [-120.2865, 47.3931],
            [-120.318, 47.4569],
            [-120.2736, 47.5522],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302310',
        NAME: 'Eastmont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343587636,
        AWATER: 11674483,
        INTPTLAT: '+47.4495076',
        INTPTLON: '-120.1848039',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0945, 47.2622],
            [-120.09, 47.32],
            [-120.0055, 47.392],
            [-119.9623, 47.4951],
            [-119.8545, 47.51],
            [-119.7479, 47.4683],
            [-119.7474, 47.4392],
            [-119.8758, 47.4374],
            [-119.9189, 47.3645],
            [-120.0491, 47.2913],
            [-120.0472, 47.2255],
            [-120.0945, 47.2622],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306510',
        NAME: 'Palisades School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199812134,
        AWATER: 3507314,
        INTPTLAT: '+47.4319209',
        INTPTLON: '-119.9375365',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6012, 48.6276],
            [-122.5897, 48.7452],
            [-122.5053, 48.8043],
            [-122.4098, 48.8114],
            [-122.2675, 48.7307],
            [-122.352, 48.6451],
            [-122.5789, 48.6314],
            [-122.6012, 48.6276],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300420',
        NAME: 'Bellingham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235019137,
        AWATER: 120192366,
        INTPTLAT: '+48.7232619',
        INTPTLON: '-122.4584777',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6068, 48.9861],
            [-122.542, 48.9065],
            [-122.5053, 48.8043],
            [-122.5897, 48.7452],
            [-122.6012, 48.6276],
            [-122.6969, 48.6519],
            [-122.7919, 48.763],
            [-122.921, 48.8037],
            [-122.7363, 48.847],
            [-122.7151, 48.9573],
            [-122.6068, 48.9861],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302850',
        NAME: 'Ferndale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286638692,
        AWATER: 231390350,
        INTPTLAT: '+48.8134532',
        INTPTLON: '-122.6770516',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3412, 48.0495],
            [-119.233, 48.1135],
            [-119.1261, 48.1505],
            [-119.0136, 48.0633],
            [-118.8457, 48.0501],
            [-118.8458, 47.9635],
            [-118.7454, 47.9032],
            [-118.8722, 47.9253],
            [-119.0176, 47.9031],
            [-119.2123, 47.8731],
            [-119.2982, 47.888],
            [-119.3412, 48.0495],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303130',
        NAME: 'Grand Coulee Dam School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 751778698,
        AWATER: 70064936,
        INTPTLAT: '+48.0002621',
        INTPTLON: '-119.1109950',
        ELSDLEA: '',
        UNSDLEA: '03130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4689, 46.5941],
            [-122.3047, 46.5178],
            [-122.2417, 46.4685],
            [-122.241, 46.3864],
            [-122.2417, 46.3853],
            [-122.3022, 46.3857],
            [-122.4894, 46.3874],
            [-122.5726, 46.4022],
            [-122.6783, 46.5093],
            [-122.636, 46.5538],
            [-122.4689, 46.5941],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305250',
        NAME: 'Mossyrock School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 451453273,
        AWATER: 32726496,
        INTPTLAT: '+46.4781973',
        INTPTLON: '-122.4083420',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3679, 46.6922],
            [-123.3697, 46.7348],
            [-123.3305, 46.7363],
            [-123.2827, 46.7365],
            [-123.2662, 46.7062],
            [-123.2031, 46.6631],
            [-123.2386, 46.5171],
            [-123.2393, 46.3848],
            [-123.3582, 46.3842],
            [-123.3582, 46.4413],
            [-123.3679, 46.6922],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306660',
        NAME: 'Pe Ell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382963052,
        AWATER: 703084,
        INTPTLAT: '+46.5565948',
        INTPTLON: '-123.2938532',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9283, 46.4381],
            [-122.8558, 46.5251],
            [-122.7773, 46.5322],
            [-122.6783, 46.5093],
            [-122.5726, 46.4022],
            [-122.4894, 46.3874],
            [-122.698, 46.3872],
            [-122.9322, 46.3874],
            [-122.9283, 46.4381],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308910',
        NAME: 'Toledo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333746864,
        AWATER: 3580990,
        INTPTLAT: '+46.4423187',
        INTPTLON: '-122.7473826',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6068, 48.9861],
            [-122.6056, 49.0023],
            [-122.3632, 49.0023],
            [-122.3869, 48.8695],
            [-122.3979, 48.8695],
            [-122.542, 48.9065],
            [-122.6068, 48.9861],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304620',
        NAME: 'Lynden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175248420,
        AWATER: 2311170,
        INTPTLAT: '+48.9576090',
        INTPTLON: '-122.4746537',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.542, 48.9065],
            [-122.3979, 48.8695],
            [-122.4098, 48.8114],
            [-122.5053, 48.8043],
            [-122.542, 48.9065],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305010',
        NAME: 'Meridian School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96005256,
        AWATER: 1098453,
        INTPTLAT: '+48.8583401',
        INTPTLON: '-122.4675225',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3632, 49.0023],
            [-122.0984, 49.0021],
            [-121.7513, 48.9974],
            [-121.4339, 48.9998],
            [-121.4339, 48.8176],
            [-121.5636, 48.8177],
            [-121.5638, 48.7411],
            [-121.6563, 48.7153],
            [-121.6595, 48.6811],
            [-121.6731, 48.6528],
            [-121.6906, 48.649],
            [-121.6702, 48.6686],
            [-121.6605, 48.7181],
            [-121.5852, 48.7362],
            [-121.5855, 48.9922],
            [-121.9393, 48.9916],
            [-121.9715, 49.0003],
            [-122.1449, 49.0021],
            [-122.1758, 48.9867],
            [-122.1768, 48.9206],
            [-122.223, 48.8828],
            [-122.3869, 48.8695],
            [-122.3632, 49.0023],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305670',
        NAME: 'Nooksack Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439916734,
        AWATER: 15909156,
        INTPTLAT: '+48.9205203',
        INTPTLON: '-121.5562904',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-121.8515, 48.7166],
              [-121.7384, 48.7166],
              [-121.7385, 48.8177],
              [-121.6074, 48.8177],
              [-121.6071, 48.7384],
              [-121.6686, 48.7221],
              [-121.6839, 48.6629],
              [-121.7384, 48.6587],
              [-121.8515, 48.7166],
            ],
          ],
          [
            [
              [-121.7384, 48.6587],
              [-121.69, 48.6498],
              [-121.6906, 48.649],
              [-121.6731, 48.6528],
              [-121.6595, 48.6811],
              [-121.6563, 48.7153],
              [-121.5638, 48.7411],
              [-121.5636, 48.8177],
              [-121.4339, 48.8176],
              [-121.4339, 48.9998],
              [-120.8512, 49.0004],
              [-120.7337, 48.7829],
              [-120.665, 48.7331],
              [-120.789, 48.6254],
              [-120.7016, 48.5316],
              [-120.8576, 48.5485],
              [-121.062, 48.4594],
              [-121.0014, 48.2955],
              [-121.445, 48.2959],
              [-121.4444, 48.3839],
              [-121.8349, 48.3849],
              [-121.858, 48.2979],
              [-121.9226, 48.2977],
              [-121.9202, 48.6448],
              [-121.7384, 48.6587],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301660',
        NAME: 'Concrete School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4868195452,
        AWATER: 94700467,
        INTPTLAT: '+48.6460057',
        INTPTLON: '-121.2918806',
        ELSDLEA: '',
        UNSDLEA: '01660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.5752, 46.2528],
            [-118.5335, 46.2093],
            [-118.3358, 46.2087],
            [-118.221, 46.1942],
            [-118.1793, 46.0786],
            [-117.9929, 46.0627],
            [-117.9969, 46.0008],
            [-118.3675, 46.0008],
            [-118.4302, 46.0781],
            [-118.5549, 46.0009],
            [-118.5757, 46.0009],
            [-118.5548, 46.064],
            [-118.5752, 46.2528],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309450',
        NAME: 'Walla Walla School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 698576277,
        AWATER: 311059,
        INTPTLAT: '+46.0986431',
        INTPTLON: '-118.3388913',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6056, 49.0023],
            [-122.6068, 48.9861],
            [-122.7151, 48.9573],
            [-122.7363, 48.847],
            [-122.921, 48.8037],
            [-123.0085, 48.8313],
            [-123.3224, 49.002],
            [-122.6056, 49.0023],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300570',
        NAME: 'Blaine School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100742147,
        AWATER: 553134122,
        INTPTLAT: '+48.9807878',
        INTPTLON: '-123.0297037',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0134, 46.6845],
            [-122.7816, 46.6632],
            [-122.6991, 46.7635],
            [-122.6781, 46.7635],
            [-122.6564, 46.6481],
            [-122.7507, 46.6483],
            [-122.8295, 46.5616],
            [-122.9608, 46.598],
            [-123.0134, 46.6845],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301170',
        NAME: 'Chehalis School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245423192,
        AWATER: 1523241,
        INTPTLAT: '+46.6458106',
        INTPTLON: '-122.8445020',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4868, 46.762],
            [-122.2027, 46.7626],
            [-121.855, 46.7291],
            [-121.8552, 46.6777],
            [-122.1072, 46.6772],
            [-122.199, 46.6483],
            [-122.1784, 46.5179],
            [-122.3047, 46.5178],
            [-122.4689, 46.5941],
            [-122.3657, 46.6026],
            [-122.3604, 46.7344],
            [-122.4868, 46.762],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305190',
        NAME: 'Morton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 635920998,
        AWATER: 6332358,
        INTPTLAT: '+46.6567458',
        INTPTLON: '-122.2286397',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6781, 46.7635],
            [-122.6368, 46.7627],
            [-122.6158, 46.762],
            [-122.4868, 46.762],
            [-122.3604, 46.7344],
            [-122.3657, 46.6026],
            [-122.4689, 46.5941],
            [-122.636, 46.5538],
            [-122.6783, 46.5093],
            [-122.7773, 46.5322],
            [-122.8295, 46.5616],
            [-122.7507, 46.6483],
            [-122.6564, 46.6481],
            [-122.6781, 46.7635],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306240',
        NAME: 'Onalaska School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588327496,
        AWATER: 783020,
        INTPTLAT: '+46.6474181',
        INTPTLON: '-122.5709944',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3047, 46.5178],
            [-122.1784, 46.5179],
            [-122.199, 46.6483],
            [-122.1072, 46.6772],
            [-121.8552, 46.6777],
            [-121.855, 46.7291],
            [-121.7589, 46.7837],
            [-121.4564, 46.7837],
            [-121.3717, 46.7269],
            [-121.4124, 46.5786],
            [-121.4344, 46.4744],
            [-121.4027, 46.388],
            [-121.5236, 46.3882],
            [-122.241, 46.3864],
            [-122.2417, 46.4685],
            [-122.3047, 46.5178],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309750',
        NAME: 'White Pass School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2320245663,
        AWATER: 36775383,
        INTPTLAT: '+46.5970362',
        INTPTLON: '-121.7930868',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9601, 47.2604],
            [-117.8237, 47.2604],
            [-117.7281, 47.2228],
            [-117.7919, 47.1929],
            [-117.8531, 47.09],
            [-117.9601, 47.0171],
            [-118.0243, 47.0171],
            [-118.0246, 47.2203],
            [-117.9601, 47.2604],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304290',
        NAME: 'Lamont School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368836209,
        AWATER: 1803589,
        INTPTLAT: '+47.1293316',
        INTPTLON: '-117.9087222',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0448, 47.1726],
            [-123.9992, 47.2155],
            [-123.8397, 47.2127],
            [-123.8184, 47.0659],
            [-123.8582, 46.9525],
            [-124.0901, 46.9317],
            [-124.0874, 47.129],
            [-124.0448, 47.1726],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303660',
        NAME: 'Hoquiam School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415859671,
        AWATER: 103251244,
        INTPTLAT: '+47.0671026',
        INTPTLON: '-123.9664073',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1597, 46.9097],
            [-123.1607, 46.7993],
            [-123.2563, 46.7928],
            [-123.3305, 46.7363],
            [-123.3697, 46.7348],
            [-123.4537, 46.7926],
            [-123.4011, 46.8951],
            [-123.1597, 46.9097],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306000',
        NAME: 'Oakville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287528556,
        AWATER: 1742652,
        INTPTLAT: '+46.8404179',
        INTPTLON: '-123.3344322',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6326, 47.518],
            [-123.5044, 47.5176],
            [-123.506, 47.344],
            [-123.5546, 47.2408],
            [-123.6944, 47.2564],
            [-123.7394, 47.345],
            [-123.8398, 47.3445],
            [-123.8397, 47.2127],
            [-123.9992, 47.2155],
            [-124.0448, 47.1726],
            [-124.0541, 47.2985],
            [-123.895, 47.4324],
            [-124.1397, 47.4326],
            [-124.1793, 47.5336],
            [-124.0471, 47.5181],
            [-123.6326, 47.518],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307050',
        NAME: 'Lake Quinault School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1135787181,
        AWATER: 24329212,
        INTPTLAT: '+47.3937248',
        INTPTLON: '-123.8323599',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.437, 47.3335],
            [-122.3559, 47.3753],
            [-122.292, 47.3937],
            [-122.2497, 47.3405],
            [-122.2634, 47.2718],
            [-122.3557, 47.2775],
            [-122.437, 47.3335],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302820',
        NAME: 'Federal Way School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91915586,
        AWATER: 27768160,
        INTPTLAT: '+47.3235792',
        INTPTLON: '-122.3318868',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1698, 47.54],
            [-122.1264, 47.564],
            [-122.1106, 47.6024],
            [-121.9923, 47.6014],
            [-121.8443, 47.4851],
            [-122.0584, 47.4371],
            [-122.1296, 47.4702],
            [-122.1698, 47.54],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303750',
        NAME: 'Issaquah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263998162,
        AWATER: 10754496,
        INTPTLAT: '+47.5194036',
        INTPTLON: '-122.0248668',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2699, 47.7337],
            [-122.0332, 47.7323],
            [-121.9921, 47.6443],
            [-121.9923, 47.6014],
            [-122.1106, 47.6024],
            [-122.2403, 47.6577],
            [-122.2698, 47.7335],
            [-122.2699, 47.7337],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304230',
        NAME: 'Lake Washington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177981826,
        AWATER: 20818138,
        INTPTLAT: '+47.6782601',
        INTPTLON: '-122.1146996',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2703, 47.4956],
            [-122.2332, 47.5184],
            [-122.1974, 47.5494],
            [-122.1698, 47.54],
            [-122.1296, 47.4702],
            [-122.1166, 47.4592],
            [-122.2704, 47.4306],
            [-122.2667, 47.4451],
            [-122.2703, 47.4956],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307230',
        NAME: 'Renton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83213256,
        AWATER: 5992468,
        INTPTLAT: '+47.4810869',
        INTPTLON: '-122.1997857',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2935, 47.6207],
            [-117.2721, 47.6715],
            [-117.0418, 47.6977],
            [-117.0411, 47.6218],
            [-117.0409, 47.5798],
            [-117.2977, 47.5943],
            [-117.2935, 47.6207],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301110',
        NAME: 'Central Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201423045,
        AWATER: 3428969,
        INTPTLAT: '+47.6350595',
        INTPTLON: '-117.1576519',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2402, 47.744],
            [-117.0887, 47.8742],
            [-117.0426, 47.8745],
            [-117.0416, 47.7311],
            [-117.0418, 47.6977],
            [-117.2721, 47.6715],
            [-117.2402, 47.744],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302280',
        NAME: 'East Valley School District (Spokane)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236556235,
        AWATER: 5643981,
        INTPTLAT: '+47.7572108',
        INTPTLON: '-117.1293381',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3798, 47.573],
            [-117.2977, 47.5943],
            [-117.0409, 47.5798],
            [-117.0409, 47.5715],
            [-117.0399, 47.4052],
            [-117.1957, 47.4273],
            [-117.2492, 47.4889],
            [-117.3684, 47.4779],
            [-117.3798, 47.573],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302970',
        NAME: 'Freeman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373216019,
        AWATER: 17671,
        INTPTLAT: '+47.5100899',
        INTPTLON: '-117.1803879',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5932, 47.6864],
            [-117.5609, 47.7262],
            [-117.511, 47.73],
            [-117.5182, 47.6648],
            [-117.5932, 47.6864],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303270',
        NAME: 'Great Northern School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46814470,
        AWATER: 221521,
        INTPTLAT: '+47.6940244',
        INTPTLON: '-117.5533400',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6291, 48.0191],
            [-117.587, 48.1205],
            [-117.4376, 48.1346],
            [-117.3926, 48.0752],
            [-117.4369, 48.0472],
            [-117.4327, 47.8819],
            [-117.5356, 47.8306],
            [-117.6495, 47.9467],
            [-117.6291, 48.0191],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302070',
        NAME: 'Deer Park School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428830735,
        AWATER: 2516704,
        INTPTLAT: '+47.9874527',
        INTPTLON: '-117.5162562',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4375, 48.1945],
            [-117.1986, 48.2103],
            [-117.1755, 48.484],
            [-117.1086, 48.4835],
            [-117.0348, 48.5689],
            [-117.0422, 47.9774],
            [-117.0423, 47.9584],
            [-117.1239, 47.9588],
            [-117.1753, 48.0823],
            [-117.3926, 48.0752],
            [-117.4376, 48.1346],
            [-117.4375, 48.1945],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305610',
        NAME: 'Newport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 919897262,
        AWATER: 20290780,
        INTPTLAT: '+48.2175096',
        INTPTLON: '-117.1547540',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5367, 47.403],
            [-122.4451, 47.5446],
            [-122.4088, 47.4817],
            [-122.3559, 47.3753],
            [-122.437, 47.3335],
            [-122.5336, 47.332],
            [-122.5367, 47.403],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309300',
        NAME: 'Vashon Island School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95641726,
        AWATER: 117661981,
        INTPTLAT: '+47.4111909',
        INTPTLON: '-122.4551051',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6969, 48.6519],
            [-122.6012, 48.6276],
            [-122.5789, 48.6314],
            [-122.5148, 48.4688],
            [-122.591, 48.4176],
            [-122.7397, 48.4055],
            [-122.7461, 48.5262],
            [-122.7568, 48.6185],
            [-122.6969, 48.6519],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300150',
        NAME: 'Anacortes School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127754161,
        AWATER: 237763706,
        INTPTLAT: '+48.5089261',
        INTPTLON: '-122.6526329',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.858, 48.2979],
            [-121.8349, 48.3849],
            [-121.4444, 48.3839],
            [-121.445, 48.2959],
            [-121.0014, 48.2955],
            [-120.9632, 48.2107],
            [-120.9062, 48.1638],
            [-120.9633, 48.1356],
            [-121.324, 48.1253],
            [-121.3242, 48.0363],
            [-121.4539, 48.0366],
            [-121.4532, 48.1247],
            [-121.7073, 48.1246],
            [-121.7494, 48.2108],
            [-121.8568, 48.2248],
            [-121.858, 48.2979],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301980',
        NAME: 'Darrington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1500174916,
        AWATER: 8782855,
        INTPTLAT: '+48.2478509',
        INTPTLON: '-121.4181698',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3471, 47.7436],
            [-117.2829, 47.744],
            [-117.3038, 47.7148],
            [-117.3471, 47.7436],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306330',
        NAME: 'Orchard Prairie School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14840386,
        AWATER: 0,
        INTPTLAT: '+47.7294627',
        INTPTLON: '-117.3137172',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.7686, 47.5513],
            [-117.6688, 47.6721],
            [-117.604, 47.6283],
            [-117.6289, 47.5462],
            [-117.7686, 47.5513],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304950',
        NAME: 'Medical Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186369064,
        AWATER: 5945690,
        INTPTLAT: '+47.5779661',
        INTPTLON: '-117.7026115',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4098, 48.8114],
            [-122.3979, 48.8695],
            [-122.3869, 48.8695],
            [-122.223, 48.8828],
            [-122.1768, 48.9206],
            [-122.1758, 48.9867],
            [-122.1449, 49.0021],
            [-121.9715, 49.0003],
            [-121.9393, 48.9916],
            [-121.5855, 48.9922],
            [-121.5852, 48.7362],
            [-121.6605, 48.7181],
            [-121.6702, 48.6686],
            [-121.6906, 48.649],
            [-121.69, 48.6498],
            [-121.6839, 48.6629],
            [-121.6686, 48.7221],
            [-121.6071, 48.7384],
            [-121.6074, 48.8177],
            [-121.7385, 48.8177],
            [-121.7384, 48.7166],
            [-121.8515, 48.7166],
            [-121.9621, 48.7166],
            [-121.964, 48.645],
            [-122.2693, 48.6454],
            [-122.352, 48.6451],
            [-122.2675, 48.7307],
            [-122.4098, 48.8114],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305310',
        NAME: 'Mount Baker School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1680506838,
        AWATER: 31923816,
        INTPTLAT: '+48.8462585',
        INTPTLON: '-121.9838810',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9895, 46.2283],
            [-122.8655, 46.2573],
            [-122.6681, 46.2272],
            [-122.5534, 46.2562],
            [-122.3651, 46.2542],
            [-122.3035, 46.2986],
            [-122.3022, 46.3857],
            [-122.2417, 46.3853],
            [-122.2463, 46.2114],
            [-122.5403, 46.2273],
            [-122.5402, 46.1415],
            [-122.6977, 46.1417],
            [-122.7007, 46.0611],
            [-122.8831, 46.0539],
            [-122.9304, 46.0933],
            [-122.9154, 46.1592],
            [-122.9895, 46.2283],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300003',
        NAME: 'Kelso School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651733321,
        AWATER: 12439164,
        INTPTLAT: '+46.1859234',
        INTPTLON: '-122.6586822',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2172, 46.3698],
            [-123.0299, 46.3007],
            [-122.9895, 46.2283],
            [-122.9154, 46.1592],
            [-122.9304, 46.0933],
            [-123.073, 46.1634],
            [-123.2131, 46.1725],
            [-123.2172, 46.3698],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304470',
        NAME: 'Longview Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335734357,
        AWATER: 14226040,
        INTPTLAT: '+46.2364397',
        INTPTLON: '-123.0890055',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5044, 47.5176],
            [-123.5044, 47.607],
            [-122.9505, 47.6063],
            [-123.0378, 47.5202],
            [-123.1263, 47.3969],
            [-122.9939, 47.3609],
            [-122.994, 47.3164],
            [-123.0792, 47.3017],
            [-123.1646, 47.2584],
            [-123.3078, 47.2584],
            [-123.3766, 47.3448],
            [-123.506, 47.344],
            [-123.5044, 47.5176],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303600',
        NAME: 'Hood Canal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1125038143,
        AWATER: 73786119,
        INTPTLAT: '+47.4451300',
        INTPTLON: '-123.2804896',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3288, 47.1554],
            [-123.3078, 47.2584],
            [-123.1646, 47.2584],
            [-123.0792, 47.3017],
            [-123.0741, 47.2116],
            [-123.0762, 47.172],
            [-122.9917, 47.1706],
            [-122.9916, 47.1993],
            [-122.9362, 47.1895],
            [-123.0751, 47.0845],
            [-123.2021, 47.0849],
            [-123.2444, 47.0921],
            [-123.3288, 47.1554],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307900',
        NAME: 'Shelton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403163853,
        AWATER: 19781917,
        INTPTLAT: '+47.1890099',
        INTPTLON: '-123.1649383',
        ELSDLEA: '',
        UNSDLEA: '07900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9939, 47.3609],
            [-122.8045, 47.3535],
            [-122.8148, 47.3068],
            [-122.994, 47.3164],
            [-122.9939, 47.3609],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303240',
        NAME: 'Grapeview School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65788236,
        AWATER: 16969402,
        INTPTLAT: '+47.3292165',
        INTPTLON: '-122.8925913',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0378, 47.5202],
            [-122.821, 47.5425],
            [-122.8015, 47.404],
            [-122.8045, 47.3535],
            [-122.9939, 47.3609],
            [-123.1263, 47.3969],
            [-123.0378, 47.5202],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305790',
        NAME: 'North Mason School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336321887,
        AWATER: 64326094,
        INTPTLAT: '+47.4326828',
        INTPTLON: '-122.9420909',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0741, 47.2116],
            [-122.9916, 47.1993],
            [-122.9917, 47.1706],
            [-123.0762, 47.172],
            [-123.0741, 47.2116],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308220',
        NAME: 'Southside School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24174737,
        AWATER: 1558138,
        INTPTLAT: '+47.1913818',
        INTPTLON: '-123.0439321',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.255, 47.0204],
            [-117.2234, 47.0642],
            [-117.0397, 47.0489],
            [-117.0396, 46.9611],
            [-117.2131, 46.9578],
            [-117.2561, 46.9757],
            [-117.255, 47.0204],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303000',
        NAME: 'Garfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189920583,
        AWATER: 0,
        INTPTLAT: '+47.0102053',
        INTPTLON: '-117.1468251',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7353, 47.0658],
            [-123.6944, 47.2564],
            [-123.5546, 47.2408],
            [-123.5513, 47.0832],
            [-123.4682, 47.082],
            [-123.4674, 47.0679],
            [-123.5278, 47.0369],
            [-123.483, 46.9779],
            [-123.4834, 46.8519],
            [-123.6512, 46.9082],
            [-123.6763, 46.9573],
            [-123.7353, 47.0658],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305160',
        NAME: 'Montesano School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 576847016,
        AWATER: 7499124,
        INTPTLAT: '+47.0499547',
        INTPTLON: '-123.5841161',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7669, 46.2979],
            [-118.7524, 46.2545],
            [-118.8264, 46.1458],
            [-118.8681, 46.0005],
            [-118.9778, 46.0002],
            [-118.9871, 45.9999],
            [-118.9411, 46.0293],
            [-118.9809, 46.1463],
            [-119.0414, 46.1929],
            [-118.9728, 46.2387],
            [-118.7669, 46.2979],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301590',
        NAME: 'Columbia School District (Walla Walla)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297574956,
        AWATER: 54589578,
        INTPTLAT: '+46.1677332',
        INTPTLON: '-118.9000359',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6763, 46.9573],
            [-123.6512, 46.9082],
            [-123.6528, 46.7932],
            [-123.7984, 46.7924],
            [-123.791, 46.8505],
            [-123.7784, 46.9223],
            [-123.6763, 46.9573],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301710',
        NAME: 'Cosmopolis School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167535934,
        AWATER: 2452635,
        INTPTLAT: '+46.8585524',
        INTPTLON: '-123.7243825',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1597, 46.9097],
            [-123.1593, 46.939],
            [-123.0978, 46.9384],
            [-123.0127, 46.8511],
            [-122.9187, 46.807],
            [-122.9714, 46.7643],
            [-123.1823, 46.7645],
            [-123.2827, 46.7365],
            [-123.3305, 46.7363],
            [-123.2563, 46.7928],
            [-123.1607, 46.7993],
            [-123.1597, 46.9097],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307470',
        NAME: 'Rochester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273893206,
        AWATER: 2372942,
        INTPTLAT: '+46.8186580',
        INTPTLON: '-123.1019260',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4674, 47.0679],
            [-123.483, 46.9779],
            [-123.5278, 47.0369],
            [-123.4674, 47.0679],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307680',
        NAME: 'Satsop School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24866313,
        AWATER: 484856,
        INTPTLAT: '+47.0228404',
        INTPTLON: '-123.4829705',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.5013, 48.484],
            [-117.5022, 48.1947],
            [-117.6499, 48.1357],
            [-117.6934, 48.1358],
            [-117.8887, 48.2513],
            [-117.8681, 48.3302],
            [-117.9658, 48.3507],
            [-117.9553, 48.3942],
            [-117.7715, 48.424],
            [-117.5894, 48.4165],
            [-117.5013, 48.484],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301260',
        NAME: 'Chewelah School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795903332,
        AWATER: 1023766,
        INTPTLAT: '+48.3035858',
        INTPTLON: '-117.6837894',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0399, 49.0003],
            [-117.4295, 49.0005],
            [-117.4989, 48.9228],
            [-117.4986, 48.8368],
            [-117.5638, 48.7861],
            [-117.7341, 48.7928],
            [-117.8789, 48.8022],
            [-117.9554, 48.8023],
            [-117.9982, 48.8137],
            [-118.0408, 48.8283],
            [-118.0399, 49.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305880',
        NAME: 'Northport School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 859060750,
        AWATER: 14070290,
        INTPTLAT: '+48.8709872',
        INTPTLON: '-117.7538304',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8229, 47.4206],
            [-117.7686, 47.5513],
            [-117.6289, 47.5462],
            [-117.604, 47.6283],
            [-117.6688, 47.6721],
            [-117.5932, 47.6864],
            [-117.5182, 47.6648],
            [-117.4225, 47.5874],
            [-117.4551, 47.5082],
            [-117.4779, 47.2959],
            [-117.4912, 47.277],
            [-117.6656, 47.2598],
            [-117.7281, 47.2228],
            [-117.8237, 47.2604],
            [-117.8229, 47.4206],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5301230',
        NAME: 'Cheney School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 986642740,
        AWATER: 11116931,
        INTPTLAT: '+47.4393227',
        INTPTLON: '-117.6087072',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.2131, 46.9578],
            [-117.0396, 46.9611],
            [-117.0395, 46.8734],
            [-117.0397, 46.8222],
            [-117.1811, 46.8298],
            [-117.2131, 46.9578],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306540',
        NAME: 'Palouse School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175228094,
        AWATER: 2631,
        INTPTLAT: '+46.8922143',
        INTPTLON: '-117.1155997',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4887, 47.2668],
            [-117.4912, 47.277],
            [-117.4779, 47.2959],
            [-117.215, 47.2596],
            [-117.3641, 47.1522],
            [-117.341, 47.0651],
            [-117.407, 47.0349],
            [-117.4887, 47.2668],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307560',
        NAME: 'Rosalia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284413083,
        AWATER: 0,
        INTPTLAT: '+47.1903329',
        INTPTLON: '-117.3862410',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0314, 48.3509],
            [-117.9658, 48.3507],
            [-117.8681, 48.3302],
            [-117.8887, 48.2513],
            [-118.0165, 48.2232],
            [-118.0727, 48.267],
            [-118.0314, 48.3509],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308580',
        NAME: 'Summit Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141943000,
        AWATER: 40594,
        INTPTLAT: '+48.2761046',
        INTPTLON: '-118.0000665',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3582, 46.3842],
            [-123.2393, 46.3848],
            [-123.2178, 46.3851],
            [-123.2172, 46.3698],
            [-123.2131, 46.1725],
            [-123.2802, 46.1448],
            [-123.4227, 46.1759],
            [-123.4796, 46.2691],
            [-123.5693, 46.2573],
            [-123.5845, 46.3293],
            [-123.5229, 46.3836],
            [-123.3582, 46.3842],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309330',
        NAME: 'Wahkiakum School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 522548723,
        AWATER: 33907762,
        INTPTLAT: '+46.2724370',
        INTPTLON: '-123.3653845',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.483, 46.9779],
            [-123.4674, 47.0679],
            [-123.4682, 47.082],
            [-123.3887, 47.0826],
            [-123.3288, 47.1554],
            [-123.2444, 47.0921],
            [-123.3291, 47.0412],
            [-123.2012, 46.9969],
            [-123.1584, 46.9959],
            [-123.1593, 46.939],
            [-123.1597, 46.9097],
            [-123.4011, 46.8951],
            [-123.4537, 46.7926],
            [-123.4834, 46.8519],
            [-123.483, 46.9779],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302490',
        NAME: 'Elma School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 450327040,
        AWATER: 3971303,
        INTPTLAT: '+46.9831836',
        INTPTLON: '-123.3549471',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5546, 47.2408],
            [-123.506, 47.344],
            [-123.3766, 47.3448],
            [-123.3078, 47.2584],
            [-123.3288, 47.1554],
            [-123.3887, 47.0826],
            [-123.4682, 47.082],
            [-123.5513, 47.0832],
            [-123.5546, 47.2408],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304800',
        NAME: 'Mary M. Knight School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461180840,
        AWATER: 2434762,
        INTPTLAT: '+47.2120801',
        INTPTLON: '-123.4444271',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4196, 46.2793],
            [-117.256, 46.338],
            [-117.1335, 46.3378],
            [-117.0526, 46.3747],
            [-116.955, 46.1949],
            [-116.9595, 46.0991],
            [-116.916, 45.9954],
            [-117.382, 45.9981],
            [-117.4801, 45.9978],
            [-117.48, 46.122],
            [-117.4196, 46.1219],
            [-117.4196, 46.2793],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300280',
        NAME: 'Asotin-Anatone School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1356898669,
        AWATER: 8466578,
        INTPTLAT: '+46.1526639',
        INTPTLON: '-117.1960339',
        ELSDLEA: '',
        UNSDLEA: '00280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.7928, 48.0944],
            [-124.4527, 48.0953],
            [-124.4528, 48.1383],
            [-124.1769, 48.1337],
            [-124.0501, 48.0889],
            [-123.9244, 48.087],
            [-123.926, 47.8815],
            [-123.926, 47.795],
            [-124.2258, 47.7948],
            [-124.2276, 47.7226],
            [-124.54, 47.7237],
            [-124.6876, 47.844],
            [-124.7912, 47.9617],
            [-124.7928, 48.0944],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307020',
        NAME: 'Quillayute Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1941918806,
        AWATER: 401083885,
        INTPTLAT: '+47.9416079',
        INTPTLON: '-124.3438414',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0547, 46.1989],
            [-119.0414, 46.1929],
            [-118.9809, 46.1463],
            [-118.9411, 46.0293],
            [-118.9871, 45.9999],
            [-119.0932, 45.9427],
            [-119.2043, 45.9293],
            [-119.2045, 46.0101],
            [-119.0569, 46.0379],
            [-119.0547, 46.1989],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302910',
        NAME: 'Finley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239350362,
        AWATER: 36644795,
        INTPTLAT: '+45.9993403',
        INTPTLON: '-119.1481904',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3694, 46.131],
            [-119.3481, 46.2133],
            [-119.2367, 46.2609],
            [-119.0547, 46.1989],
            [-119.0569, 46.0379],
            [-119.2045, 46.0101],
            [-119.2043, 45.9293],
            [-119.2658, 45.939],
            [-119.4319, 45.9183],
            [-119.4563, 45.9156],
            [-119.4759, 46.072],
            [-119.3694, 46.131],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5303930',
        NAME: 'Kennewick School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755614901,
        AWATER: 19254468,
        INTPTLAT: '+46.0788210',
        INTPTLON: '-119.2552520',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6156, 46.4754],
            [-119.554, 46.3744],
            [-119.4274, 46.3736],
            [-119.4325, 46.2423],
            [-119.3481, 46.2133],
            [-119.3694, 46.131],
            [-119.4331, 46.1297],
            [-119.4955, 46.2433],
            [-119.6217, 46.2426],
            [-119.5541, 46.3601],
            [-119.6395, 46.4039],
            [-119.6156, 46.4754],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5304020',
        NAME: 'Kiona-Benton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342964699,
        AWATER: 3030668,
        INTPTLAT: '+46.2940189',
        INTPTLON: '-119.4971591',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4331, 46.1297],
            [-119.3694, 46.131],
            [-119.4759, 46.072],
            [-119.4563, 45.9156],
            [-119.5716, 45.9255],
            [-119.6699, 45.8569],
            [-119.8681, 45.836],
            [-119.8673, 45.9526],
            [-119.6853, 45.9537],
            [-119.6867, 46.0429],
            [-119.6024, 46.0435],
            [-119.5595, 46.1309],
            [-119.4331, 46.1297],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306630',
        NAME: 'Paterson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 509356969,
        AWATER: 57361197,
        INTPTLAT: '+45.9703900',
        INTPTLON: '-119.6224717',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.0243, 47.0171],
            [-117.9601, 47.0171],
            [-117.9708, 46.8673],
            [-118.0126, 46.7905],
            [-118.1027, 46.8248],
            [-118.0983, 46.8837],
            [-118.2143, 46.9137],
            [-118.0243, 47.0171],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5300450',
        NAME: 'Benge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304209034,
        AWATER: 1317654,
        INTPTLAT: '+46.9464148',
        INTPTLON: '-118.0223129',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4532, 46.6792],
            [-119.3689, 46.6782],
            [-119.1136, 46.6795],
            [-119.1122, 46.7381],
            [-118.9843, 46.7365],
            [-118.8781, 46.8083],
            [-118.6516, 46.7359],
            [-118.6936, 46.7073],
            [-118.6796, 46.5297],
            [-118.7413, 46.4721],
            [-118.8253, 46.3853],
            [-118.9089, 46.4298],
            [-119.1125, 46.4381],
            [-119.2634, 46.4763],
            [-119.2718, 46.5233],
            [-119.4532, 46.6792],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305730',
        NAME: 'North Franklin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1614530832,
        AWATER: 12160498,
        INTPTLAT: '+46.6153816',
        INTPTLON: '-118.9658178',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9586, 47.1875],
            [-118.7271, 47.1303],
            [-118.5573, 47.2611],
            [-118.4226, 47.3042],
            [-118.3155, 47.3042],
            [-118.2088, 47.2612],
            [-117.9601, 47.2604],
            [-118.0246, 47.2203],
            [-118.0243, 47.0171],
            [-118.2143, 46.9137],
            [-118.399, 46.8977],
            [-118.4292, 47.0303],
            [-118.6632, 47.0437],
            [-118.7475, 47.0868],
            [-118.9812, 47.0719],
            [-118.9586, 47.1875],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307380',
        NAME: 'Ritzville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1694569151,
        AWATER: 7328657,
        INTPTLAT: '+47.1210851',
        INTPTLON: '-118.3734693',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6512, 46.9082],
            [-123.4834, 46.8519],
            [-123.4537, 46.7926],
            [-123.3697, 46.7348],
            [-123.3679, 46.6922],
            [-123.5884, 46.6927],
            [-123.5885, 46.7363],
            [-123.6528, 46.7932],
            [-123.6512, 46.9082],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305820',
        NAME: 'North River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316782555,
        AWATER: 0,
        INTPTLAT: '+46.7829602',
        INTPTLON: '-123.5198515',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2411, 46.9029],
            [-124.0901, 46.9317],
            [-123.8582, 46.9525],
            [-123.791, 46.8505],
            [-123.7984, 46.7924],
            [-123.9152, 46.7926],
            [-123.9202, 46.7071],
            [-124.0013, 46.6766],
            [-124.1585, 46.7136],
            [-124.2411, 46.9029],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306090',
        NAME: 'Ocosta School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392162589,
        AWATER: 252217053,
        INTPTLAT: '+46.8252401',
        INTPTLON: '-123.9986910',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7984, 46.7924],
            [-123.6528, 46.7932],
            [-123.5885, 46.7363],
            [-123.6638, 46.7359],
            [-123.7247, 46.5631],
            [-123.7984, 46.7924],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5307140',
        NAME: 'Raymond School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231786000,
        AWATER: 12867771,
        INTPTLAT: '+46.7133251',
        INTPTLON: '-123.7275087',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.9167, 46.5321],
            [-123.7255, 46.5271],
            [-123.7254, 46.4709],
            [-123.5652, 46.4397],
            [-123.3582, 46.4413],
            [-123.3582, 46.3842],
            [-123.5229, 46.3836],
            [-123.5845, 46.3293],
            [-123.5693, 46.2573],
            [-123.6916, 46.2516],
            [-123.8501, 46.2406],
            [-123.847, 46.4394],
            [-123.9167, 46.5321],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5305520',
        NAME: 'Naselle-Grays River Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660185005,
        AWATER: 57733625,
        INTPTLAT: '+46.3860739',
        INTPTLON: '-123.6858159',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0013, 46.6766],
            [-123.9202, 46.7071],
            [-123.9152, 46.7926],
            [-123.7984, 46.7924],
            [-123.7247, 46.5631],
            [-123.7255, 46.5271],
            [-123.9167, 46.5321],
            [-124.0013, 46.6166],
            [-124.0013, 46.6766],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308100',
        NAME: 'South Bend School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282467868,
        AWATER: 109072299,
        INTPTLAT: '+46.6311935',
        INTPTLON: '-123.8683774',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9901, 46.041],
            [-119.8663, 46.0407],
            [-119.867, 46.1955],
            [-119.8736, 46.3168],
            [-119.8743, 46.534],
            [-119.6179, 46.5341],
            [-119.6156, 46.4754],
            [-119.6395, 46.4039],
            [-119.5541, 46.3601],
            [-119.6217, 46.2426],
            [-119.4955, 46.2433],
            [-119.4331, 46.1297],
            [-119.5595, 46.1309],
            [-119.6024, 46.0435],
            [-119.6867, 46.0429],
            [-119.6853, 45.9537],
            [-119.8673, 45.9526],
            [-119.8681, 45.836],
            [-119.9089, 45.828],
            [-119.9923, 45.9097],
            [-119.9901, 46.041],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5306900',
        NAME: 'Prosser School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1547129288,
        AWATER: 4338119,
        INTPTLAT: '+46.2138865',
        INTPTLON: '-119.7385675',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1791, 47.0416],
            [-120.7088, 47.0432],
            [-120.6453, 47.1028],
            [-120.6457, 47.1755],
            [-120.5128, 47.1758],
            [-120.5165, 47.3209],
            [-120.3885, 47.2611],
            [-120.2594, 47.2614],
            [-120.2603, 47.0452],
            [-120.4295, 47.0449],
            [-120.4502, 46.9265],
            [-120.3842, 46.9119],
            [-120.3819, 46.7801],
            [-120.5095, 46.7813],
            [-120.6251, 46.826],
            [-120.6336, 46.8693],
            [-120.4816, 46.8688],
            [-120.581, 46.9958],
            [-120.6349, 46.912],
            [-121.0265, 46.9113],
            [-121.0264, 46.9982],
            [-121.1791, 47.0416],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302460',
        NAME: 'Ellensburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1443192840,
        AWATER: 3390049,
        INTPTLAT: '+47.0375998',
        INTPTLON: '-120.5875008',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8169, 48.1484],
            [-120.7223, 48.1668],
            [-120.4125, 47.9749],
            [-120.4189, 47.9398],
            [-120.185, 47.7615],
            [-120.2445, 47.5791],
            [-120.2736, 47.5522],
            [-120.3878, 47.6116],
            [-120.4802, 47.6418],
            [-120.5232, 47.7014],
            [-120.5113, 47.8336],
            [-120.6051, 47.8314],
            [-120.6666, 47.978],
            [-120.8169, 48.1484],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5302550',
        NAME: 'Entiat School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1137148794,
        AWATER: 9472744,
        INTPTLAT: '+47.9790501',
        INTPTLON: '-120.6233267',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.7016, 48.5316],
            [-120.6388, 48.4734],
            [-120.6449, 48.4013],
            [-120.5836, 48.3192],
            [-120.4282, 48.2507],
            [-120.5543, 48.1933],
            [-120.8728, 48.2439],
            [-120.9632, 48.2107],
            [-121.0014, 48.2955],
            [-121.062, 48.4594],
            [-120.8576, 48.5485],
            [-120.7016, 48.5316],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5308430',
        NAME: 'Stehekin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1113434802,
        AWATER: 22639980,
        INTPTLAT: '+48.3598486',
        INTPTLON: '-120.7834834',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.5569, 47.4385],
            [-120.4103, 47.4362],
            [-120.3878, 47.6116],
            [-120.2736, 47.5522],
            [-120.318, 47.4569],
            [-120.2865, 47.3931],
            [-120.1296, 47.3663],
            [-120.09, 47.32],
            [-120.0945, 47.2622],
            [-120.2594, 47.2614],
            [-120.3885, 47.2611],
            [-120.5165, 47.3209],
            [-120.5566, 47.307],
            [-120.5569, 47.4385],
          ],
        ],
      },
      properties: {
        STATEFP: '53',
        SCSDLEA: '',
        GEOID: '5309660',
        NAME: 'Wenatchee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 652859386,
        AWATER: 10084574,
        INTPTLAT: '+47.3840428',
        INTPTLON: '-120.3523456',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Form, Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {
  regroupForSidebar,
  setLocationData,
  getDataSetLabel,
  makeSidebarMapPalette,
  findFirstValidValue,
  setSidebarElementColor,
  makeDistrictAbbr,
} from '../../../helpers/charts';
import { HIGHCHARTS_TOOLTIP_DELAY, HIGHCHARTS_TYPES } from '../../../constants/charts';

const RankChart = ({
  componentConfig,
  visualizationDetails,
  handleSectionClick,
  readOnly,
  handleUpdateDataSet,
}) => {
  const numFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });
  const [chartOptions, setChartOptions] = useState();
  const [loadingChart, setLoadingChart] = useState(true);

  const unescapeEntity = (input) => {
    const e = document.createElement('span');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  function tooltipFormatter() {
    const dataLabel = getDataSetLabel(visualizationDetails.components[0].datasetFilter);
    const dataValue = unescapeEntity(this.point.text_label);
    return `<div style="opacity: 0.99" class="tooltip-wrapper"><table border="0" cellspacing="0" cellpadding="1"><tr><td style="text-align: center; font-size: 14px;">${
      componentConfig?.queryResult?.stateMapData
        ? this.point.name.toUpperCase()
        : this.point.label.toUpperCase()
    } (${dataLabel.prefix}${
      Number.isNaN(Number(dataValue)) ? dataValue : numFormatter.format(dataValue)
    }${dataLabel.postfix})</td></div>`;
  }

  function seriesFormatter() {
    const mapData = componentConfig?.queryResult?.stateMapData
      ? componentConfig?.queryResult?.stateMapData
      : componentConfig?.queryResult?.districtMapData;
    const parseData = regroupForSidebar(mapData, !componentConfig?.queryResult?.stateMapData);
    const dataLabel = getDataSetLabel(visualizationDetails.components[0].datasetFilter);

    const last_index = parseData[1].length - 1;
    if (this.point.index === 0 || this.point.index === last_index) {
      const dataValue = unescapeEntity(this.point.text_label);
      return `${this.point.label}<br>(${dataLabel.prefix}${
        Number.isNaN(Number(dataValue)) ? dataValue : numFormatter.format(dataValue)
      }${dataLabel.postfix})`;
    }

    return '';
  }

  function handleItemClick() {
    if (readOnly) return;
    const options = this.options;
    const is_state = options.geoid === undefined || options.geoid === null;

    let geoid;
    if (typeof options.geoid === 'string') {
      // geo-id might be a string when retrieved from geojson for districts without data
      geoid = parseInt(options.geoid, 10);
    } else {
      geoid = options.geoid;
    }

    const leaid = options.leaid ? options.leaid : geoid; // fallback to geoid if leaid is not present
    const newShapeId = is_state ? options.id : leaid.toString();
    const visualizationsConfig = visualizationDetails.components[0];
    const locationData = setLocationData(visualizationDetails, visualizationsConfig.viewMode);
    if (
      visualizationDetails?.state?.abbreviation === newShapeId ||
      visualizationDetails?.state?.district?.leaId === leaid
    ) {
      if (visualizationDetails?.state?.abbreviation === newShapeId) {
        handleSectionClick(null);
      } else if (visualizationDetails?.state?.district?.leaId === leaid) {
        handleSectionClick({
          ...visualizationDetails?.state,
          district: null,
        });
      }
    } else if (componentConfig?.queryResult?.stateMapData) {
      handleSectionClick({
        abbreviation: options.id,
        name: options.name,
        // geoid,
        // leaid,
      });
    } else {
      handleSectionClick({
        abbreviation: locationData.state_abbr,
        name: locationData.state_name,
        district: {
          abbreviation: makeDistrictAbbr(options.label),
          name: options.label,
          geoId: 0,
          id: 0,
          leaId: leaid,
          showStateRanking: false,
        },
      });
    }
  }

  const onChangeBenchmark = () => {
    const newComponentVisualizationConfig = { ...visualizationDetails.components[0] };
    newComponentVisualizationConfig.benchmarks.state = !newComponentVisualizationConfig.benchmarks
      .state;
    handleUpdateDataSet(newComponentVisualizationConfig);
  };

  useEffect(() => {
    const visualizationsConfig = visualizationDetails.components[0];
    if (!componentConfig?.queryResult?.suppressed && !visualizationsConfig.hidden) {
      const locationData = setLocationData(visualizationDetails, visualizationsConfig.viewMode);
      const mapData = componentConfig?.queryResult?.stateMapData
        ? componentConfig?.queryResult?.stateMapData
        : componentConfig?.queryResult?.districtMapData;
      const parseData = regroupForSidebar(mapData, !componentConfig?.queryResult?.stateMapData);
      const postfix = findFirstValidValue(mapData, (item) => item.valueSuffix, '');

      const palette = makeSidebarMapPalette(
        parseData[2],
        parseData[3],
        visualizationDetails?.scheme?.index,
        postfix
      );

      let benchmark_label = 'National';
      let benchmark_value = parseData[0][0].nat_av;

      if (
        visualizationsConfig.benchmarks !== null &&
        visualizationsConfig.benchmarks.state &&
        visualizationsConfig.viewMode === 'DISTRICT'
      ) {
        benchmark_label = 'State';
        benchmark_value = parseData[0][0].state_av;
      }

      const valuePrefix = findFirstValidValue(parseData[0], (item) => item.value_prefix, '');
      const valueSuffix = findFirstValidValue(parseData[0], (item) => item.value_suffix, '');

      const benchmark_value_label =
        valuePrefix + Number(benchmark_value).toLocaleString() + valueSuffix;

      const num_bars = parseData[0][0].data.length;

      for (let i = 0; i < num_bars; i += 1) {
        parseData[0][0].data[i].color = setSidebarElementColor(
          parseData[0][0].data[i],
          parseData[2],
          parseData[3],
          componentConfig?.queryResult?.stateMapData ||
            (visualizationsConfig.viewMode === 'DISTRICT' &&
              visualizationDetails.state.district === null)
            ? locationData.abbr
            : visualizationDetails?.state?.district?.leaId?.toString() || '',
          true,
          !componentConfig?.queryResult?.stateMapData,
          palette[0]
        );
      }
      // Inject point click handler
      const sidebar_hc_series = parseData[0];
      sidebar_hc_series[0].point = {
        events: {
          click: handleItemClick,
        },
      };

      const default_theme = {
        chart: {
          type: HIGHCHARTS_TYPES.COLUMN,
          marginTop: 30,
          marginLeft: 80,
          marginRight: 90,
          height: 200,

          // backgroundColor option is static
          backgroundColor: null,
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
        },

        // title formatting options will be static
        title: {
          text: null,
        },

        // xAxis formatting will be static
        xAxis: {
          title: {
            text: null,
          },
          categories: parseData[1],
          gridLineColor: '#b8b4b4',
          labels: {
            style: {
              color: '#121111',
            },
            align: 'right',
            y: null,
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          tickInterval: 10,
        },

        // yAxis formatting will be static
        yAxis: {
          plotLines: [
            {
              value: benchmark_value,
              color: '#AC193C',
              width: 2.5,
              zIndex: 5,
              label: {
                align: 'left',
                verticalAlign: 'middle',
                x: -60,
                y: 0,
                rotation: 0,
                text: `${benchmark_label} <br>Average<br>(${benchmark_value_label})`,
                style: {
                  color: '#AC193C',
                  fontSize: '14px',
                  fontWeight: 'bold',
                },
              },
            },
          ],
          gridLineColor: 'transparent',
          labels: {
            enabled: false,
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            events: {
              legendItemClick: () => {
                return false;
              },
            },
          },
          series: {
            turboThreshold: 0,
            pointPadding: 0,
            groupPadding: 0,
            dataLabels: {
              enabled: true,
              allowOverlap: true,
              align: 'left',
              overflow: 'none',
              crop: false,
              shadow: false,
              style: {
                fontSize: '14px',
                fontWeight: 'normal',
                textShadow: '0px',
                textOutline: 'false',
                color: '#121111',
              },
              shape: 'square',
              useHTML: false,
              formatter: seriesFormatter,
            },
          },
        },
        tooltip: {
          hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
          followPointer: true,
          backgroundColor: 'rgba(255,255,255, 0.98)',
          useHTML: true,
          formatter: tooltipFormatter,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        series: sidebar_hc_series,
      };
      setChartOptions({ ...default_theme });
    } else if (!visualizationsConfig.hidden) {
      setChartOptions({ suppressed: true });
    }
    setLoadingChart(false);
  }, [componentConfig, visualizationDetails]);

  if (loadingChart) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (chartOptions?.suppressed) {
    return (
      <Alert className="m-3" variant="warning">
        <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
        visualization suppressed
      </Alert>
    );
  }
  if (!visualizationDetails.components[0].hidden) {
    return (
      <Container fluid className="p-4 bg-white">
        <Row noGutters className="d-flex justify-content-between">
          <Col>
            <h3>Ranking</h3>
          </Col>
          {visualizationDetails.components[0].viewMode === 'DISTRICT' && (
            <Col className="d-flex" md={1}>
              <span className="pt-3">State</span>
              <Form.Check
                type="switch"
                className="mx-3"
                checked={visualizationDetails.components[0].benchmarks.state}
                id="custom-switch-rank"
                onClick={onChangeBenchmark}
              />
            </Col>
          )}
        </Row>
        <HighchartsReact
          // callback={handleChartLoaded}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </Container>
    );
  }
  return null;
};

export default RankChart;

import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { parseFileType, checkValidFile } from '../../FileUpload/fileUpload.helper';
import { openModal } from '../../../actions/modal.action';
import modalMessages from '../../../messages/modal.messages';

const InputUploadButton = ({
  type,
  vendorDataType,
  setFile,
  progress,
  isUploading,
  uploaded,
  cancelUpload,
  removeFile,
}) => {
  const awsFolder = useSelector((state) => state.validations.awsFolder);
  const files = useSelector((state) => state.wizard.files) || {};
  const fileInput = useRef(null);
  const dispatch = useDispatch();

  const onButtonClick = () => {
    fileInput.current.click();
  };

  const onChangeHandler = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;
    const { name } = uploadedFile;
    if (name in files) {
      dispatch(openModal(modalMessages('duplicateFile')));
      e.target.value = null;
      return;
    }
    let uploadedFileType = uploadedFile.name.match(/\.[0-9a-z]+$/i);
    if (uploadedFileType) {
      if (Array.isArray(uploadedFileType)) {
        [uploadedFileType] = uploadedFileType;
      } else {
        uploadedFileType = uploadedFile.type;
      }
    } else {
      dispatch(openModal(modalMessages('invalidFile')));
      return;
    }
    if (checkValidFile(uploadedFileType, parseFileType(type))) {
      setFile(uploadedFile);
    } else {
      e.target.value = null;
      dispatch(openModal(modalMessages('invalidFile')));
    }
  };

  // If file is uploaded, show remove button.
  if (uploaded) {
    return <Button buttonText="Remove File" theme="dark" size="md" onClick={removeFile} />;
  }

  // If file is uploading (FE) remove it
  if (isUploading) {
    return <Button buttonText="Cancel Upload" theme="dark" size="md" disabled />;
  }

  // If file is uploading (FE) remove it
  if (progress > 0 && progress <= 100) {
    return (
      <Button
        buttonText="Cancel Upload"
        theme="dark"
        size="md"
        disabled={progress === 100}
        onClick={cancelUpload}
      />
    );
  }

  /** Hacky component to upload file when clicking on button */
  return (
    <div className="upload-area">
      <input
        type="file"
        id="customFile"
        onChange={onChangeHandler}
        style={{ display: 'none' }}
        ref={fileInput}
        accept={parseFileType(type)}
        data-type={vendorDataType}
      />

      <Button
        onClick={onButtonClick}
        buttonText="Upload File"
        theme="dark"
        size="md"
        disabled={!awsFolder || isUploading}
      />
    </div>
  );
};

InputUploadButton.propTypes = {
  type: PropTypes.string.isRequired,
  vendorDataType: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  progress: PropTypes.number,
  isUploading: PropTypes.bool,
  uploaded: PropTypes.bool,
  cancelUpload: PropTypes.func,
  removeFile: PropTypes.func,
};

InputUploadButton.defaultProps = {
  progress: 0,
  isUploading: false,
  uploaded: false,
  cancelUpload: () => {},
  removeFile: () => {},
};

export default InputUploadButton;

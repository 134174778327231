import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {Boolean} uploaded State of the file. If it's uploaded or not.
 */

const InputUploadFileUploadedMessage = ({ uploaded }) => {
  if (!uploaded) return null;
  return (
    <>
      <div className="FileUpload-success">
        <div className="FileUpload-success-img">
          <img src="/icon-success.svg" alt="Succes Icon" />
        </div>
        <span className="FileUpload-success-label">
          File Uploaded
        </span>
      </div>
    </>
  );
};

InputUploadFileUploadedMessage.propTypes = {
  uploaded: PropTypes.bool,
};

InputUploadFileUploadedMessage.defaultProps = {
  uploaded: false,
};

export default InputUploadFileUploadedMessage;

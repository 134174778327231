/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import jwtDecode from 'jwt-decode';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { getAllSurveys } from '../../actions/survey.action';
import { getOrgList, getOrgListByUser } from '../../actions/organization.actions';
import { setRelatedSurveyId, setRelatedOrgId } from '../../actions/wizard.action';
import { checkFileValidations } from '../../actions/validation.action';

import './style.css';

const CustomDropdown = ({ label, asyncAction, emptyLabel, required }) => {
  const surveys = useSelector((state) => state.surveys.surveys);
  const { organizations } = useSelector((state) => state.organizations);
  const jwt = useSelector((state) => state.session.jwt);
  const currentUser = jwtDecode(jwt);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(emptyLabel);

  useEffect(() => {
    if (surveys) return;
    if (asyncAction === 'getAllSurveys') {
      dispatch(setRelatedSurveyId(0));
      dispatch(getAllSurveys(jwt));
    }
  }, [surveys]);

  useEffect(() => {
    if (asyncAction === 'getAllOrganizations') {
      dispatch(setRelatedOrgId(0));
      if (currentUser.role === 'ORGANIZATION_ADMIN') {
        dispatch(getOrgListByUser(currentUser.userId, jwt));
      } else {
        dispatch(getOrgList(null, jwt));
      }
    }
  }, []);

  const onSelectHandler = (item, event) => {
    const value = JSON.parse(item);
    const { title, id } = value;
    event.preventDefault();
    setSelected(title);
    switch (asyncAction) {
      case 'getAllSurveys': {
        dispatch(setRelatedSurveyId(id));
        break;
      }
      case 'getAllOrganizations': {
        dispatch(setRelatedOrgId(id));
        dispatch(checkFileValidations());
        break;
      }
      default:
        break;
    }
  };

  const getDropdownItems = () => {
    switch (asyncAction) {
      case 'getAllSurveys': {
        if (surveys) {
          return (
            <>
              {surveys?.map((survey) => {
                if (survey.Data.title) {
                  const eventKey = { title: survey.Data.title, id: survey.Id };
                  return (
                    <Dropdown.Item
                      key={shortid.generate()}
                      as="button"
                      eventKey={JSON.stringify(eventKey)}
                    >
                      {survey.Data.title}
                    </Dropdown.Item>
                  );
                }
                return null;
              })}
            </>
          );
        }
        return null;
      }
      case 'getAllOrganizations': {
        if (organizations) {
          return (
            <>
              {organizations?.map((org) => {
                if (org.name) {
                  const eventKey = { title: org.name, id: org.id };
                  return (
                    <Dropdown.Item
                      key={shortid.generate()}
                      as="button"
                      eventKey={JSON.stringify(eventKey)}
                    >
                      {org.name}
                    </Dropdown.Item>
                  );
                }
                return null;
              })}
            </>
          );
        }
        return null;
      }
      default:
        break;
    }
    return null;
  };

  return (
    <div className="CustomDropdown">
      <div className="CustomDropdown-custom-label">
        {label}
        {required && <span> *</span>}
      </div>
      <div className="CustomDropdown-dropdown">
        <DropdownButton
          id="dropdown-item-button"
          className="custom-dropdown"
          onSelect={(a, e) => onSelectHandler(a, e)}
          title={selected}
        >
          {currentUser.role !== 'ORGANIZATION_ADMIN' && (
            <Dropdown.Item as="button" eventKey={JSON.stringify({ title: emptyLabel, id: 0 })}>
              {emptyLabel}
            </Dropdown.Item>
          )}
          {getDropdownItems()}
        </DropdownButton>
        {required && selected === emptyLabel && (
          <span className="CustomDropdown-error-message"> this field is required!</span>
        )}
      </div>
    </div>
  );
};

CustomDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  asyncAction: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default CustomDropdown;

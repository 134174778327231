/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faBorderAll,
  faBuilding,
  faCaretDown,
  faChartBar,
  faChevronLeft,
  faCloudUploadAlt,
  faFileAlt,
  faRedo,
  faSearch,
  faTachometerAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import shortid from 'shortid';
import { logout } from '../../actions/session.action';

import { getMostRecentReports } from '../../actions/report.action';
import { dateFormat } from '../../helpers/helpers';
import './style.css';

const ADMIN_ROLES = ['SUPER_ADMIN', 'ADMIN'];

const Nav = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const { id: surveyId } = useParams();
  const firstName = useSelector((state) => state.session.firstName) || 'User';
  const storeJwt = useSelector((state) => state.session.jwt);
  const jwtObj = jwtDecode(storeJwt);
  const viewAllReportsImageRef = useRef();
  const { mostRecentReports } = useSelector((state) => state.reports);

  const isAdmin = ADMIN_ROLES.indexOf(jwtObj.role) !== -1;
  const isSuperAdmin = jwtObj.role === 'SUPER_ADMIN';
  const isOrgAdmin = jwtObj.role === 'ORGANIZATION_ADMIN';

  useEffect(() => {
    if (mostRecentReports) return;
    dispatch(getMostRecentReports(storeJwt));
  }, [dispatch, mostRecentReports, storeJwt]);

  const handleLogout = () => {
    sessionStorage.removeItem('jwt');
    dispatch(logout());
    window.location = '/';
  };

  const handleKeyDown = (event, pathname) => {
    if (event.keyCode === 13) {
      history.push(pathname);
    }
  };

  const handleViewAllReportMouseOver = () => {
    viewAllReportsImageRef.current.src = '/view-all-reports-hover.svg';
  };

  const handleViewAllReportMouseLeave = () => {
    viewAllReportsImageRef.current.src = '/view-all-reports.svg';
  };

  if (window.location.pathname.includes('admin/survey/edit')) {
    return (
      <nav className="main-navigation" role="navigation">
        <div className="nav-content">
          <ul className="main-navigation-ul">
            <li>
              <span
                className="main-navigation-link"
                onClick={() => {
                  history.push('/');
                }}
                onKeyDown={(event) => handleKeyDown(event, '/')}
                role="button"
                tabIndex={0}
              >
                <img src="/bento-logo-small-transparent.svg" alt="Home Link" />
              </span>
            </li>
            <li>
              <span
                className="main-navigation-link d-flex"
                onClick={() => {
                  history.push('/admin/survey');
                }}
                onKeyDown={(event) => handleKeyDown(event, '/admin/survey')}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
                Back to Manage surveys
              </span>
            </li>
          </ul>
          <ul className="main-navigation-ul">
            <li>
              <a
                className="main-navigation-link"
                href="https://bentoguide.squarespace.com/admin-info"
                target="_blank"
                rel="noopener noreferrer"
              >
                Need help?
              </a>
            </li>
            <li>
              <span className="main-navigation-link d-flex" role="button" tabIndex={0}>
                <FontAwesomeIcon size="sm" className="mr-3" icon={faRedo} />
                Undo last save
              </span>
            </li>
            <li>
              <span className="main-navigation-link d-flex" role="button" tabIndex={0}>
                <FontAwesomeIcon size="sm" className="mr-3" icon={faCloudUploadAlt} />
                Saving
              </span>
            </li>
            <li>
              <span
                className="btn btn-custom dark"
                role="button"
                onClick={() => {
                  history.push(`/surveys/${surveyId}`);
                }}
                onKeyDown={(event) => handleKeyDown(event, '/admin/survey')}
                tabIndex={0}
              >
                View Draft Survey
              </span>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
  return (
    <nav className="main-navigation" role="navigation">
      <div className="nav-content">
        <ul className="main-navigation-ul">
          <li>
            <span
              className="main-navigation-link"
              onClick={() => {
                history.push('/');
              }}
              onKeyDown={(event) => handleKeyDown(event, '/')}
              role="button"
              tabIndex={0}
            >
              <img src="/bento-logo-small-transparent.svg" alt="Home Link" />
            </span>
          </li>
          <li>
            <span
              className="main-navigation-link"
              onClick={() => {
                history.push('/dashboard');
              }}
              onKeyDown={(event) => handleKeyDown(event, '/dashboard')}
              role="button"
              tabIndex={0}
            >
              Dashboard
            </span>
          </li>
          <li>
            <div>
              <span
                className="main-navigation-link"
                onClick={() => {
                  history.push('/survey-topics');
                }}
                onKeyDown={(event) => handleKeyDown(event, '/survey-topics')}
                role="button"
                tabIndex={0}
              >
                Survey Explorer
              </span>
              <div className="sub-menu">
                <div className="sub-menu-content">
                  <ul className="sub-list">
                    <li className="sub-list-item">
                      <Link className="sub-list-link" to="/survey-explorer">
                        <FontAwesomeIcon className="sub-list-img" icon={faBorderAll} />
                        View all Surveys
                      </Link>
                      <p>Explore all survey data in Bento</p>
                    </li>
                  </ul>
                  <ul className="sub-list">
                    <li className="sub-list-item">
                      <Link className="sub-list-link" to="/survey-topics">
                        <FontAwesomeIcon className="sub-list-img" icon={faSearch} />
                        Surveys by Topic
                      </Link>
                      <p>Search and browse for survey content by topic</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div>
              <span
                className="main-navigation-link"
                onClick={() => {
                  history.push('/reports');
                }}
                onKeyDown={(event) => handleKeyDown(event, '/reports')}
                role="button"
                tabIndex={0}
              >
                Reports
              </span>
              <div className="sub-menu">
                <div className="sub-menu-content reports">
                  <div>
                    <h2>Recent Reports</h2>
                    <div className="recent-reports">
                      {mostRecentReports?.map((report) => (
                        <Card
                          key={shortid.generate()}
                          onClick={() => history.push(`/reports/${report.id}`)}
                        >
                          <Card.Body>
                            <FontAwesomeIcon className="report-icon" icon={faFileAlt} size="lg" />
                            <p className="report-name">{report.name}</p>
                            <p className="report-modified-at">
                              Updated: {dateFormat(report.dateUpdated)}
                            </p>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  </div>
                  <div className="view-all-reports-container">
                    <h2>View All</h2>
                    <Card
                      className="view-all-reports-card"
                      onClick={() => history.push(`/reports`)}
                      onMouseOver={handleViewAllReportMouseOver}
                      onMouseLeave={handleViewAllReportMouseLeave}
                    >
                      <img
                        ref={viewAllReportsImageRef}
                        className="view-all-reports-image"
                        src="/view-all-reports.svg"
                        alt="view all reports"
                      />
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div>
              <span className="main-navigation-link">
                <a
                  href="https://bentoguide.squarespace.com/welcome"
                  target="_blank"
                  rel="noopener noreferrer" // to prevent a type of phishing known as tabnabbing.
                >
                  Guides
                </a>
              </span>
              <div className="sub-menu">
                <div className="sub-menu-content">
                  <ul className="sub-list guides-section">
                    <li className="sub-list-item">
                      <a
                        className="sub-list-link"
                        href="https://bentoguide.squarespace.com/survey-methodology"
                        target="_blank"
                        rel="noopener noreferrer" // to prevent a type of phishing known as tabnabbing.
                      >
                        Methodology
                        <FontAwesomeIcon className="arrow-down" icon={faCaretDown} />
                      </a>
                    </li>
                    <ul className="sub-list-internal">
                      <li className="sub-list-item">
                        Information on data sources, label definitions, and methods used to process
                        and display the data in the Survey Explorer and Dashboard
                      </li>
                    </ul>
                  </ul>
                  <ul className="sub-list guides-section">
                    <li className="sub-list-item">
                      <a
                        className="sub-list-link"
                        href="https://bentoguide.squarespace.com/faqs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQ
                        <FontAwesomeIcon className="arrow-down" icon={faCaretDown} />
                      </a>
                    </li>
                    <ul className="sub-list-internal">
                      <li className="sub-list-item">Frequently Asked Questions about Bento</li>
                    </ul>
                  </ul>
                  <ul className="sub-list guides-section">
                    <li className="sub-list-item">
                      <a
                        className="sub-list-link"
                        href="https://bentoguide.squarespace.com/welcome"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Training Material
                        <FontAwesomeIcon className="arrow-down" icon={faCaretDown} />
                      </a>
                    </li>
                    <ul className="sub-list-internal">
                      <li className="sub-list-item">
                        A guide to exploring, analyzing and sharing data in Bento
                      </li>
                    </ul>
                  </ul>
                  <ul className="sub-list guides-section">
                    <li className="sub-list-item">
                      <a
                        className="sub-list-link"
                        href="https://bentoguide.squarespace.com/aboutsurveys"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About Bento
                        <FontAwesomeIcon className="arrow-down" icon={faCaretDown} />
                      </a>
                    </li>
                    <ul className="sub-list-internal">
                      <li className="sub-list-item">
                        Learn more about Bento and the team behind it
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          {(isAdmin || isOrgAdmin) && (
            <li>
              <div>
                <span
                  className="main-navigation-link"
                  onClick={() => {
                    history.push('/');
                  }}
                  onKeyDown={(event) => handleKeyDown(event, '/')}
                  role="button"
                  tabIndex={0}
                >
                  Admin
                </span>
                <div className="sub-menu">
                  <div className="sub-menu-content">
                    {isSuperAdmin && (
                      <ul className="sub-list admin-section">
                        <li className="sub-list-item">
                          <Link className="sub-list-link" to="/admin/dashboard">
                            <FontAwesomeIcon className="sub-list-img" icon={faTachometerAlt} />
                            Manage Dashboard
                          </Link>
                        </li>
                      </ul>
                    )}
                    {(isSuperAdmin || isOrgAdmin) && (
                      <ul className="sub-list admin-section">
                        <li className="sub-list-item">
                          <Link className="sub-list-link" to="/admin/survey">
                            <FontAwesomeIcon className="sub-list-img" icon={faChartBar} />
                            Manage Survey Explorer
                          </Link>
                        </li>
                      </ul>
                    )}
                    <ul className={`sub-list ${isSuperAdmin ? 'admin-section' : ''}`}>
                      <li className="sub-list-item">
                        <Link className="sub-list-link" to="/admin/manage-users">
                          <FontAwesomeIcon className="sub-list-img" icon={faUsers} />
                          Add &amp; Manage Users
                        </Link>
                      </li>
                    </ul>
                    {isAdmin && isSuperAdmin && (
                      <ul className={`sub-list ${isSuperAdmin ? 'admin-section' : ''}`}>
                        <li className="sub-list-item">
                          <Link className="sub-list-link" to="/admin/manage-organizations">
                            <FontAwesomeIcon className="sub-list-img" icon={faBuilding} />
                            Add &amp; Manage Organizations
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </li>
          )}
          <li>
            <a
              className="main-navigation-link"
              href="https://www.getbento.info/your-data-in-bento"
              target="_blank"
              rel="noopener noreferrer"
            >
              See Your Data in Bento
            </a>
          </li>
        </ul>
        <div className="main-navigation-link custom-dropdown">
          <div>
            <span>{`Hi, ${firstName}`}</span>
            <FontAwesomeIcon className="arrow-down" icon={faCaretDown} />
          </div>
          <ul className="custom-dropdown-ul">
            <li className="custom-dropdown-li">
              <a className="custom-dropdown-link" href="/profile">
                My Profile
              </a>
            </li>
            <li className="custom-dropdown-li">
              <a className="custom-dropdown-link" href="/feedback">
                Provide feedback
              </a>
            </li>
            <li className="custom-dropdown-li">
              <a
                className="custom-dropdown-link"
                href="https://bentoguide.squarespace.com/welcome"
                target="_blank"
                rel="noopener noreferrer"
              >
                Need Help?
              </a>
            </li>
            <li className="custom-dropdown-li">
              <a className="custom-dropdown-link" href="#" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

Nav.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Nav;

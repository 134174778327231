import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SurveyInformationEditForm = ({ handleEditMode, currentSurvey, handleSave }) => {
  const [title, setTitle] = useState(currentSurvey.title || '');
  const [dateAdministrated, setDateAdministrated] = useState('');
  const [description, setDescription] = useState('');

  const { surveyMetadataDescription } = useSelector((state) => state.surveys);

  const surveySummaryDetailsDto = useMemo(() => {
    const metaDescription = surveyMetadataDescription.find(
      (metaDesc) =>
        metaDesc.Data.type === currentSurvey.type &&
        metaDesc.Data.subtype === currentSurvey.subType &&
        metaDesc.Data.year.startsWith(currentSurvey.year.toString())
    );
    if (metaDescription) {
      return metaDescription;
    }
    return {
      Data: {
        description: 'Click to add Information',
        administered: 'Click to add Information',
      },
    };
  }, [currentSurvey, surveyMetadataDescription]);

  useEffect(() => {
    if (surveySummaryDetailsDto) {
      setDateAdministrated(surveySummaryDetailsDto?.Data.administered);
      setDescription(surveySummaryDetailsDto?.Data.description);
    }
  }, [surveySummaryDetailsDto]);

  const onSave = () => {
    handleSave({
      title,
      description,
      dateAdministrated,
    });
  };
  return (
    <Col md="3" className="p-3 survey-sidebar edit-mode">
      <span
        className="d-flex"
        onClick={handleEditMode}
        onKeyDown={handleEditMode}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
        Back to overview
      </span>
      <Col className="info-edit-form">
        <h4>Survey Name</h4>
        <Form.Control
          defaultValue={title}
          onChangeCapture={(e) => setTitle(e.target.value)}
          placeholder="Survey Name"
          type="text"
          maxLength={35}
          value={title}
        />
        <h4>Description</h4>
        <Form.Control
          as="textarea"
          style={{ height: '100px' }}
          defaultValue={description}
          maxLength={500}
          placeholder="Survey Description"
          value={description}
          onChangeCapture={(e) => setDescription(e.target.value)}
        />
        <span className="hint">Character Limit: 500</span>
        <h4>Time Administered</h4>
        <Form.Control
          defaultValue={dateAdministrated}
          value={dateAdministrated}
          onChangeCapture={(e) => setDateAdministrated(e.target.value)}
          placeholder="Time Administered"
          type="text"
          maxLength={35}
        />
        <span className="hint">Character Limit: 35</span>
      </Col>
      <Button variant="primary" onClick={onSave} className="btn btn-custom dark">
        Save
      </Button>
    </Col>
  );
};

SurveyInformationEditForm.propTypes = {
  currentSurvey: PropTypes.shape({
    title: '',
    surveySummaryDetailsDto: {
      description: '',
      dateAdministrated: '',
    },
    type: '',
    subType: '',
    year: '',
  }).isRequired,
  handleEditMode: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

SurveyInformationEditForm.defaultProps = {};

export default SurveyInformationEditForm;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Modal } from 'react-bootstrap';

import types from '../../types';

import './style.css';

const ShareALinkModal = () => {
  const dispatch = useDispatch();
  const { shareLinkId, showModal } = useSelector((state) => state.shareALink);
  const [shareLinkUrl, setShareLinkUrl] = useState('');
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const handleCloseModal = () => {
    dispatch({ type: types.reducerTypes.SHARE_A_LINK_CLEAR });
  };

  const handleCopyClick = () => {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(shareLinkUrl);
    } else {
      const textField = document.createElement('textarea');
      textField.innerText = shareLinkUrl;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
    setShowCopyMessage(true);
  };

  useEffect(() => {
    if (shareLinkId) {
      setShareLinkUrl(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}?shareToken=${shareLinkId}`
      );
    }
  }, [shareLinkId]);

  return (
    <>
      <Modal
        className="share-a-link-modal"
        show={showModal}
        onHide={handleCloseModal}
        onExit={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share a Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Link to Share</h4>
          <div className="my-2">{shareLinkUrl}</div>
          <Button
            variant="primary"
            type="button"
            onClick={handleCopyClick}
            className="btn btn-custom dark"
          >
            Copy Link
          </Button>
          {showCopyMessage && (
            <Alert variant="success" className="my-2 p-2">
              <p className="m-0">Copy link to clipboard successfully!</p>
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ShareALinkModal);

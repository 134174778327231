export const VT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1209, 43.403],
            [-73.0217, 43.3993],
            [-72.9935, 43.3976],
            [-72.9963, 43.3053],
            [-73.1227, 43.3087],
            [-73.1209, 43.403],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '03430',
        GEOID: '5003430',
        NAME: 'Danby School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107263904,
        AWATER: 325782,
        INTPTLAT: '+43.3381575',
        INTPTLON: '-073.0962532',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2508, 43.4087],
            [-73.1209, 43.403],
            [-73.1227, 43.3087],
            [-73.2546, 43.3147],
            [-73.2508, 43.4087],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '06360',
        GEOID: '5006360',
        NAME: 'Pawlet School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110562742,
        AWATER: 538840,
        INTPTLAT: '+43.3606692',
        INTPTLON: '-073.1863230',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2557, 43.2791],
            [-73.2546, 43.3147],
            [-73.1227, 43.3087],
            [-73.128, 43.2138],
            [-73.2592, 43.2168],
            [-73.2557, 43.2791],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '07020',
        GEOID: '5007020',
        NAME: 'Rupert School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115429363,
        AWATER: 96656,
        INTPTLAT: '+43.2528541',
        INTPTLON: '-073.1973476',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.263, 44.5423],
            [-72.2374, 44.5768],
            [-72.1378, 44.5186],
            [-72.152, 44.5012],
            [-72.263, 44.5423],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '07710',
        GEOID: '5007710',
        NAME: 'Stannard School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32197890,
        AWATER: 224344,
        INTPTLAT: '+44.5255108',
        INTPTLON: '-072.1764331',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.233, 43.7487],
            [-73.1313, 43.7521],
            [-73.1084, 43.6762],
            [-73.238, 43.68],
            [-73.233, 43.7487],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '04740',
        GEOID: '5004740',
        NAME: 'Hubbardton School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71120057,
        AWATER: 3562843,
        INTPTLAT: '+43.7339667',
        INTPTLON: '-073.1635384',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9935, 43.3976],
            [-72.8764, 43.3935],
            [-72.8534, 43.3711],
            [-72.8679, 43.3007],
            [-72.9762, 43.2916],
            [-72.9963, 43.3053],
            [-72.9935, 43.3976],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05860',
        GEOID: '5005860',
        NAME: 'Mount Tabor School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113115183,
        AWATER: 362695,
        INTPTLAT: '+43.3508069',
        INTPTLON: '-072.9216255',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6544, 44.3982],
            [-72.5968, 44.4793],
            [-72.4929, 44.423],
            [-72.4905, 44.4257],
            [-72.3806, 44.3829],
            [-72.44, 44.3044],
            [-72.4804, 44.2338],
            [-72.5198, 44.2411],
            [-72.5331, 44.2157],
            [-72.5375, 44.2046],
            [-72.5597, 44.1629],
            [-72.5893, 44.1603],
            [-72.6837, 44.1851],
            [-72.6389, 44.2719],
            [-72.7166, 44.3125],
            [-72.6544, 44.3982],
          ],
          [
            [-72.6126, 44.2586],
            [-72.5318, 44.2404],
            [-72.5738, 44.3135],
            [-72.6126, 44.2586],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08237',
        GEOID: '5008237',
        NAME: 'Union High School District 32',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477167343,
        AWATER: 5202994,
        INTPTLAT: '+44.3189928',
        INTPTLON: '-072.5588385',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1574, 44.4901],
            [-73.0973, 44.5705],
            [-72.9711, 44.5503],
            [-73.032, 44.468],
            [-73.0893, 44.4808],
            [-73.1359, 44.475],
            [-73.1371, 44.5009],
            [-73.1574, 44.4901],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '00006',
        GEOID: '5000006',
        NAME: 'Essex Community Education Center',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100552793,
        AWATER: 1278976,
        INTPTLAT: '+44.5273788',
        INTPTLON: '-073.0701145',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4905, 44.4257],
            [-72.4343, 44.5061],
            [-72.4298, 44.512],
            [-72.3748, 44.5841],
            [-72.3682, 44.5923],
            [-72.3153, 44.6652],
            [-72.2029, 44.6241],
            [-72.2374, 44.5768],
            [-72.263, 44.5423],
            [-72.3192, 44.4618],
            [-72.3213, 44.4626],
            [-72.3806, 44.3829],
            [-72.4905, 44.4257],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08243',
        GEOID: '5008243',
        NAME: 'Hazen Union High School District 26',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294673882,
        AWATER: 9669189,
        INTPTLAT: '+44.5187137',
        INTPTLON: '-072.3489650',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0349, 43.5707],
            [-73.0122, 43.5714],
            [-72.9338, 43.5747],
            [-72.775, 43.5794],
            [-72.7715, 43.4779],
            [-72.87, 43.4808],
            [-72.8764, 43.3935],
            [-72.9935, 43.3976],
            [-73.0217, 43.3993],
            [-73.005, 43.4926],
            [-73.0508, 43.4939],
            [-73.0349, 43.5707],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08244',
        GEOID: '5008244',
        NAME: 'Mill River Union School District 40',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321818442,
        AWATER: 2255102,
        INTPTLAT: '+43.5002564',
        INTPTLON: '-072.9170193',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1916, 44.0616],
            [-72.1703, 44.1921],
            [-72.042, 44.1429],
            [-72.0786, 44.0296],
            [-72.0787, 44.0296],
            [-72.099, 43.9575],
            [-72.099, 43.9575],
            [-72.2327, 43.9927],
            [-72.1916, 44.0616],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08247',
        GEOID: '5008247',
        NAME: 'Oxbow Union High School District 30',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237237234,
        AWATER: 2621999,
        INTPTLAT: '+44.0956715',
        INTPTLON: '-072.1520753',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9221, 44.2222],
            [-72.896, 44.2763],
            [-72.7894, 44.2397],
            [-72.7166, 44.3125],
            [-72.6389, 44.2719],
            [-72.6837, 44.1851],
            [-72.7334, 44.2108],
            [-72.7823, 44.1298],
            [-72.7701, 44.1264],
            [-72.8216, 44.0526],
            [-72.909, 44.0726],
            [-72.9343, 44.1636],
            [-72.9522, 44.1613],
            [-72.9221, 44.2222],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '04580',
        GEOID: '5004580',
        NAME: 'Harwood Union High School District 19',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370301329,
        AWATER: 1463571,
        INTPTLAT: '+44.1922943',
        INTPTLON: '-072.8136152',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7913, 43.9619],
            [-72.7423, 44.0297],
            [-72.6838, 44.0129],
            [-72.6348, 44.0894],
            [-72.6289, 44.0886],
            [-72.5381, 44.0624],
            [-72.5099, 43.9661],
            [-72.5744, 43.8733],
            [-72.7035, 43.9093],
            [-72.7078, 43.9104],
            [-72.7913, 43.9619],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '02400',
        GEOID: '5002400',
        NAME: 'Randolph Union High School District 2',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329253644,
        AWATER: 1583211,
        INTPTLAT: '+43.9580873',
        INTPTLON: '-072.6428535',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9227, 44.6498],
            [-72.7955, 44.6922],
            [-72.7861, 44.7277],
            [-72.7446, 44.7865],
            [-72.6758, 44.7942],
            [-72.5935, 44.7906],
            [-72.5782, 44.7816],
            [-72.4173, 44.7229],
            [-72.4231, 44.7152],
            [-72.4767, 44.6418],
            [-72.4823, 44.6339],
            [-72.5247, 44.5733],
            [-72.6505, 44.6036],
            [-72.7475, 44.5948],
            [-72.7682, 44.563],
            [-72.8093, 44.5479],
            [-72.9106, 44.6309],
            [-72.9241, 44.6331],
            [-72.9227, 44.6498],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05060',
        GEOID: '5005060',
        NAME: 'Lamoille Union High School District 18',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666214133,
        AWATER: 8561996,
        INTPTLAT: '+44.6867286',
        INTPTLON: '-072.6710344',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4139, 44.1078],
            [-73.4117, 44.1115],
            [-73.4014, 44.1523],
            [-73.3456, 44.2328],
            [-73.3133, 44.2641],
            [-73.1968, 44.2666],
            [-73.1771, 44.1739],
            [-73.2136, 44.1599],
            [-73.2498, 44.0883],
            [-73.263, 44.0348],
            [-73.4074, 44.0203],
            [-73.4275, 44.0371],
            [-73.4139, 44.1078],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08280',
        GEOID: '5008280',
        NAME: 'Vergennes Union High School District 5',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299998547,
        AWATER: 72228484,
        INTPTLAT: '+44.1391144',
        INTPTLON: '-073.3090427',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4074, 44.0203],
            [-73.263, 44.0348],
            [-73.2498, 44.0883],
            [-73.1808, 44.046],
            [-73.096, 44.0544],
            [-73.0647, 44.0408],
            [-73.0259, 44.0446],
            [-72.9081, 44.0355],
            [-72.9122, 43.9656],
            [-72.9797, 43.9224],
            [-73.0388, 43.9231],
            [-73.0397, 43.9001],
            [-73.1747, 43.8793],
            [-73.1793, 43.9084],
            [-73.2418, 43.9048],
            [-73.2227, 43.8581],
            [-73.3738, 43.8429],
            [-73.4047, 43.9238],
            [-73.4079, 43.9299],
            [-73.4074, 44.0203],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05520',
        GEOID: '5005520',
        NAME: 'Middlebury Union High School District 3',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 647902976,
        AWATER: 20350080,
        INTPTLAT: '+43.9728936',
        INTPTLON: '-073.1946194',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5375, 44.2046],
            [-72.5331, 44.2157],
            [-72.5198, 44.2411],
            [-72.4804, 44.2338],
            [-72.4197, 44.221],
            [-72.443, 44.1287],
            [-72.456, 44.1331],
            [-72.5597, 44.1629],
            [-72.5375, 44.2046],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '00002',
        GEOID: '5000002',
        NAME: 'Spaulding Union High School District 41',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89379787,
        AWATER: 479085,
        INTPTLAT: '+44.1830280',
        INTPTLON: '-072.4751549',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6772, 42.899],
            [-72.5328, 42.8961],
            [-72.5552, 42.8582],
            [-72.5456, 42.8239],
            [-72.4584, 42.7268],
            [-72.5659, 42.73],
            [-72.5613, 42.8244],
            [-72.6811, 42.8272],
            [-72.6772, 42.899],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '02520',
        GEOID: '5002520',
        NAME: 'Brattleboro Union High School District 6',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132800713,
        AWATER: 3103873,
        INTPTLAT: '+42.8601870',
        INTPTLON: '-072.6330776',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4074, 43.6734],
            [-73.3936, 43.6995],
            [-73.3628, 43.7531],
            [-73.3793, 43.8085],
            [-73.3738, 43.8429],
            [-73.2227, 43.8581],
            [-73.2189, 43.8373],
            [-73.2314, 43.7645],
            [-73.233, 43.7487],
            [-73.238, 43.68],
            [-73.1084, 43.6762],
            [-73.1046, 43.6628],
            [-73.0957, 43.6325],
            [-73.1154, 43.5847],
            [-73.2432, 43.5875],
            [-73.2456, 43.5395],
            [-73.2898, 43.5778],
            [-73.3021, 43.6238],
            [-73.4074, 43.6734],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '03900',
        GEOID: '5003900',
        NAME: 'Fair Haven Union High School District 16',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454104534,
        AWATER: 22859986,
        INTPTLAT: '+43.6942670',
        INTPTLON: '-073.2864473',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0227, 44.4341],
            [-73.032, 44.468],
            [-72.9711, 44.5503],
            [-72.9106, 44.6309],
            [-72.8093, 44.5479],
            [-72.8386, 44.4592],
            [-72.805, 44.4517],
            [-72.8791, 44.33],
            [-72.896, 44.2763],
            [-72.9221, 44.2222],
            [-72.9652, 44.2303],
            [-73.0204, 44.3037],
            [-73.0327, 44.3651],
            [-73.0821, 44.3707],
            [-73.0227, 44.4341],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05840',
        GEOID: '5005840',
        NAME: 'Mount Mansfield Union School District 17',
        LSAD: '00',
        LOGRADE: '05',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515789442,
        AWATER: 2923452,
        INTPTLAT: '+44.4275408',
        INTPTLON: '-072.9327110',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2418, 43.9048],
            [-73.1793, 43.9084],
            [-73.1747, 43.8793],
            [-73.0397, 43.9001],
            [-73.0388, 43.9231],
            [-72.9797, 43.9224],
            [-72.9802, 43.8826],
            [-72.9589, 43.8265],
            [-73.0168, 43.805],
            [-73.0027, 43.7568],
            [-72.94, 43.6692],
            [-73.0135, 43.6663],
            [-73.0553, 43.6649],
            [-73.1046, 43.6628],
            [-73.1084, 43.6762],
            [-73.1313, 43.7521],
            [-73.233, 43.7487],
            [-73.2314, 43.7645],
            [-73.2189, 43.8373],
            [-73.2227, 43.8581],
            [-73.2418, 43.9048],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '06300',
        GEOID: '5006300',
        NAME: 'Otter Valley Union High School District 8',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413858667,
        AWATER: 6322474,
        INTPTLAT: '+43.7974461',
        INTPTLON: '-073.0822777',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6772, 43.2233],
            [-72.5559, 43.2354],
            [-72.5499, 43.2358],
            [-72.435, 43.2333],
            [-72.4378, 43.1251],
            [-72.4595, 43.0206],
            [-72.5862, 43.0234],
            [-72.5751, 43.0711],
            [-72.6054, 43.0721],
            [-72.6393, 43.1278],
            [-72.6825, 43.1325],
            [-72.6772, 43.2233],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08246',
        GEOID: '5008246',
        NAME: 'Bellows Falls Union High School District 27',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358919302,
        AWATER: 3164971,
        INTPTLAT: '+43.1351975',
        INTPTLON: '-072.5461654',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2136, 44.1599],
            [-73.1771, 44.1739],
            [-73.1968, 44.2666],
            [-73.1451, 44.2734],
            [-73.0806, 44.2823],
            [-73.0204, 44.3037],
            [-72.9652, 44.2303],
            [-72.9522, 44.1613],
            [-72.9343, 44.1636],
            [-72.909, 44.0726],
            [-72.9081, 44.0355],
            [-73.0259, 44.0446],
            [-73.0647, 44.0408],
            [-73.096, 44.0544],
            [-73.1808, 44.046],
            [-73.2498, 44.0883],
            [-73.2136, 44.1599],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08235',
        GEOID: '5008235',
        NAME: 'Mount Abraham Union High School District 28',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 540414577,
        AWATER: 4583211,
        INTPTLAT: '+44.1558496',
        INTPTLON: '-073.0663070',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2747, 42.9344],
            [-73.2587, 42.9338],
            [-73.2167, 42.9329],
            [-73.1464, 42.9323],
            [-73.1532, 42.836],
            [-73.2783, 42.8376],
            [-73.2747, 42.9344],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05810',
        GEOID: '5005810',
        NAME: 'Mount Anthony Union High School District 14',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104601490,
        AWATER: 584724,
        INTPTLAT: '+42.8823399',
        INTPTLON: '-073.2110929',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8791, 44.33],
            [-72.805, 44.4517],
            [-72.6544, 44.3982],
            [-72.7166, 44.3125],
            [-72.7894, 44.2397],
            [-72.896, 44.2763],
            [-72.8791, 44.33],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '50001',
        GEOID: '5050001',
        NAME: 'Harwood Union High School District 19 (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 236127472,
        AWATER: 4371311,
        INTPTLAT: '+44.3372202',
        INTPTLON: '-072.7826037',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8687, 43.1132],
            [-72.8649, 43.1662],
            [-72.7745, 43.1418],
            [-72.7303, 43.2332],
            [-72.6772, 43.2233],
            [-72.6825, 43.1325],
            [-72.6393, 43.1278],
            [-72.6054, 43.0721],
            [-72.5751, 43.0711],
            [-72.5862, 43.0234],
            [-72.6085, 42.9682],
            [-72.6357, 42.9691],
            [-72.6759, 42.9224],
            [-72.7748, 42.924],
            [-72.7548, 42.9872],
            [-72.7427, 43.0233],
            [-72.7341, 43.05],
            [-72.8725, 43.0535],
            [-72.8687, 43.1132],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05120',
        GEOID: '5005120',
        NAME: 'Leland and Gray Union High School District 34',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440847385,
        AWATER: 3736792,
        INTPTLAT: '+43.0625245',
        INTPTLON: '-072.7167848',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2938, 44.4382],
            [-73.1545, 44.4054],
            [-73.1359, 44.475],
            [-73.0893, 44.4808],
            [-73.032, 44.468],
            [-73.0227, 44.4341],
            [-73.0821, 44.3707],
            [-73.0327, 44.3651],
            [-73.0204, 44.3037],
            [-73.0806, 44.2823],
            [-73.1451, 44.2734],
            [-73.1968, 44.2666],
            [-73.3133, 44.2641],
            [-73.327, 44.3434],
            [-73.2938, 44.4382],
          ],
          [
            [-73.1326, 44.3645],
            [-73.0893, 44.3695],
            [-73.1416, 44.4014],
            [-73.1326, 44.3645],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '03030',
        GEOID: '5003030',
        NAME: 'Champlain Valley Union High School District 15',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349633793,
        AWATER: 79678371,
        INTPTLAT: '+44.3581230',
        INTPTLON: '-073.1695480',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-72.6759, 42.9224],
              [-72.6357, 42.9691],
              [-72.6085, 42.9682],
              [-72.5862, 43.0234],
              [-72.4595, 43.0206],
              [-72.5208, 42.9617],
              [-72.5267, 42.9341],
              [-72.5328, 42.8961],
              [-72.6772, 42.899],
              [-72.6759, 42.9224],
            ],
          ],
          [
            [
              [-72.6811, 42.8272],
              [-72.5613, 42.8244],
              [-72.5659, 42.73],
              [-72.6746, 42.7329],
              [-72.6862, 42.7332],
              [-72.6811, 42.8272],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '50003',
        GEOID: '5050003',
        NAME: 'Brattleboro Union High School District 6 (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 251701796,
        AWATER: 1144870,
        INTPTLAT: '+42.8017353',
        INTPTLON: '-072.6278665',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.2707, 43.0176],
              [-73.27, 43.0307],
              [-73.1405, 43.0258],
              [-73.1464, 42.9323],
              [-73.2167, 42.9329],
              [-73.2587, 42.9338],
              [-73.2747, 42.9344],
              [-73.2707, 43.0176],
            ],
          ],
          [
            [
              [-73.2893, 42.8041],
              [-73.2783, 42.8376],
              [-73.1532, 42.836],
              [-73.1464, 42.9323],
              [-73.0081, 42.9301],
              [-73.0119, 42.8591],
              [-73.0151, 42.8321],
              [-73.1373, 42.8355],
              [-73.1425, 42.7436],
              [-73.1636, 42.744],
              [-73.265, 42.7459],
              [-73.2893, 42.8041],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '50002',
        GEOID: '5050002',
        NAME: 'Mount Anthony Union High School District 14 (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 359565996,
        AWATER: 1447252,
        INTPTLAT: '+42.8259938',
        INTPTLON: '-073.2143369',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-72.2467, 45.0046],
              [-72.0522, 45.0064],
              [-71.8976, 45.0084],
              [-71.9177, 44.9436],
              [-71.9212, 44.9321],
              [-71.9104, 44.8952],
              [-71.8744, 44.8764],
              [-71.9202, 44.8369],
              [-72.0674, 44.9144],
              [-72.1201, 44.8647],
              [-72.1617, 44.886],
              [-72.1894, 44.9016],
              [-72.2428, 44.9311],
              [-72.2264, 44.9542],
              [-72.2467, 45.0046],
            ],
          ],
          [
            [
              [-72.5544, 45.0083],
              [-72.4335, 45.0079],
              [-72.4126, 44.9263],
              [-72.5357, 44.9258],
              [-72.5544, 45.0083],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '09570',
        GEOID: '5009570',
        NAME: 'North Country Union High School District 22 (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406600038,
        AWATER: 36207634,
        INTPTLAT: '+44.9426807',
        INTPTLON: '-072.1486543',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-72.1201, 44.8647],
              [-72.0674, 44.9144],
              [-71.9202, 44.8369],
              [-71.8744, 44.8764],
              [-71.8585, 44.8684],
              [-71.8329, 44.8546],
              [-71.7658, 44.819],
              [-71.8584, 44.7277],
              [-71.9399, 44.769],
              [-71.9743, 44.7865],
              [-72.0404, 44.8219],
              [-72.1201, 44.8647],
            ],
          ],
          [
            [
              [-72.5782, 44.7816],
              [-72.5326, 44.8335],
              [-72.5387, 44.9256],
              [-72.5357, 44.9258],
              [-72.4126, 44.9263],
              [-72.4335, 45.0079],
              [-72.3292, 45.0047],
              [-72.2467, 45.0046],
              [-72.2264, 44.9542],
              [-72.2428, 44.9311],
              [-72.309, 44.8752],
              [-72.344, 44.8421],
              [-72.3653, 44.7945],
              [-72.4048, 44.7405],
              [-72.4124, 44.7295],
              [-72.4173, 44.7229],
              [-72.5782, 44.7816],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '09600',
        GEOID: '5009600',
        NAME: 'North Country Union High School District 22 (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 680068957,
        AWATER: 13657702,
        INTPTLAT: '+44.9391072',
        INTPTLON: '-072.3605357',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.87, 43.4808],
            [-72.7715, 43.4779],
            [-72.7272, 43.4687],
            [-72.6611, 43.4538],
            [-72.6613, 43.3508],
            [-72.7598, 43.3541],
            [-72.8534, 43.3711],
            [-72.8764, 43.3935],
            [-72.87, 43.4808],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08241',
        GEOID: '5008241',
        NAME: 'Black River Union School District 39',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218298517,
        AWATER: 2545602,
        INTPTLAT: '+43.3993977',
        INTPTLON: '-072.7740087',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8586, 43.6949],
            [-72.8372, 43.7199],
            [-72.82, 43.7168],
            [-72.7009, 43.6828],
            [-72.6944, 43.6941],
            [-72.6619, 43.795],
            [-72.6452, 43.7905],
            [-72.5338, 43.7581],
            [-72.5288, 43.7566],
            [-72.4264, 43.7218],
            [-72.4781, 43.6392],
            [-72.4664, 43.6311],
            [-72.5284, 43.5333],
            [-72.5329, 43.5341],
            [-72.5302, 43.4441],
            [-72.6606, 43.457],
            [-72.6475, 43.5612],
            [-72.758, 43.5875],
            [-72.7728, 43.5916],
            [-72.8687, 43.618],
            [-72.8586, 43.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '09510',
        GEOID: '5009510',
        NAME: 'Woodstock Union High School District 4',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 698931273,
        AWATER: 3077837,
        INTPTLAT: '+43.6587063',
        INTPTLON: '-072.6485419',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1919, 45.0136],
            [-73.1701, 45.014],
            [-72.9934, 45.0149],
            [-72.8326, 45.0164],
            [-72.8223, 44.9254],
            [-72.8219, 44.9199],
            [-72.8997, 44.9348],
            [-73.0555, 44.9105],
            [-73.0022, 44.8805],
            [-72.9944, 44.8159],
            [-73.194, 44.8868],
            [-73.2308, 44.9274],
            [-73.1919, 45.0136],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05620',
        GEOID: '5005620',
        NAME: 'Missisquoi Valley Union High School District 7',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355680727,
        AWATER: 64822695,
        INTPTLAT: '+44.9579384',
        INTPTLON: '-073.0409043',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2561, 44.7825],
            [-73.194, 44.8868],
            [-72.9944, 44.8159],
            [-73.0848, 44.7729],
            [-73.1491, 44.7963],
            [-73.2289, 44.7229],
            [-73.2561, 44.7825],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '02085',
        GEOID: '5002085',
        NAME: 'Bellows Free Academy Union High School District 48',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101132124,
        AWATER: 60900805,
        INTPTLAT: '+44.8097618',
        INTPTLON: '-073.1434793',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4173, 44.7229],
            [-72.4124, 44.7295],
            [-72.4048, 44.7405],
            [-72.3653, 44.7945],
            [-72.344, 44.8421],
            [-72.309, 44.8752],
            [-72.2013, 44.8353],
            [-72.1617, 44.886],
            [-72.1201, 44.8647],
            [-72.0404, 44.8219],
            [-71.9743, 44.7865],
            [-71.9399, 44.769],
            [-72.0125, 44.6988],
            [-72.1145, 44.7496],
            [-72.0889, 44.6837],
            [-72.1466, 44.7035],
            [-72.2029, 44.6241],
            [-72.3153, 44.6652],
            [-72.3098, 44.673],
            [-72.4231, 44.7152],
            [-72.4173, 44.7229],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '05050',
        GEOID: '5005050',
        NAME: 'Lake Region Union High School District 24',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 575374689,
        AWATER: 17347036,
        INTPTLAT: '+44.7611100',
        INTPTLON: '-072.2025440',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7598, 43.3541],
            [-72.6613, 43.3508],
            [-72.6611, 43.4538],
            [-72.6606, 43.457],
            [-72.5302, 43.4441],
            [-72.5388, 43.381],
            [-72.607, 43.3481],
            [-72.5454, 43.3452],
            [-72.5499, 43.2358],
            [-72.5559, 43.2354],
            [-72.6772, 43.2233],
            [-72.7303, 43.2332],
            [-72.7598, 43.3541],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '08248',
        GEOID: '5008248',
        NAME: 'Green Mountain Union High School District 35',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320834581,
        AWATER: 1420932,
        INTPTLAT: '+43.3056461',
        INTPTLON: '-072.6421577',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1701, 45.014],
            [-72.9934, 45.0149],
            [-72.8997, 44.9348],
            [-73.0555, 44.9105],
            [-73.1573, 44.9478],
            [-73.1701, 45.014],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004650',
        NAME: 'Highgate School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131893196,
        AWATER: 23340802,
        INTPTLAT: '+44.9631977',
        INTPTLON: '-073.0598568',
        ELSDLEA: '04650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2561, 44.7825],
            [-73.194, 44.8868],
            [-72.9944, 44.8159],
            [-73.0848, 44.7729],
            [-73.1491, 44.7963],
            [-73.2289, 44.7229],
            [-73.2561, 44.7825],
          ],
          [
            [-73.1012, 44.8076],
            [-73.0758, 44.8004],
            [-73.0678, 44.8175],
            [-73.0808, 44.825],
            [-73.1012, 44.8076],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007600',
        NAME: 'St. Albans Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95885278,
        AWATER: 60896537,
        INTPTLAT: '+44.8113612',
        INTPTLON: '-073.1458957',
        ELSDLEA: '07600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4139, 44.1078],
            [-73.2498, 44.0883],
            [-73.263, 44.0348],
            [-73.4074, 44.0203],
            [-73.4275, 44.0371],
            [-73.4139, 44.1078],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001710',
        NAME: 'Addison School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107550913,
        AWATER: 19176859,
        INTPTLAT: '+44.0585392',
        INTPTLON: '-073.3323146',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4074, 44.0203],
            [-73.263, 44.0348],
            [-73.2567, 44.0123],
            [-73.2581, 43.9472],
            [-73.4079, 43.9299],
            [-73.4074, 44.0203],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002550',
        NAME: 'Bridport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113253128,
        AWATER: 6672605,
        INTPTLAT: '+43.9817242',
        INTPTLON: '-073.3319365',
        ELSDLEA: '02550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3456, 44.2328],
            [-73.3133, 44.2641],
            [-73.1968, 44.2666],
            [-73.1771, 44.1739],
            [-73.2136, 44.1599],
            [-73.4014, 44.1523],
            [-73.3456, 44.2328],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004050',
        NAME: 'Ferrisburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122878220,
        AWATER: 35574830,
        INTPTLAT: '+44.2031331',
        INTPTLON: '-073.2791964',
        ELSDLEA: '04050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4014, 44.1523],
            [-73.2136, 44.1599],
            [-73.2498, 44.0883],
            [-73.4139, 44.1078],
            [-73.4117, 44.1115],
            [-73.4014, 44.1523],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008250',
        NAME: 'Vergennes Union High School District 44',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69569414,
        AWATER: 17476795,
        INTPTLAT: '+44.1380836',
        INTPTLON: '-073.2944757',
        ELSDLEA: '08250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2418, 43.9048],
            [-73.1793, 43.9084],
            [-73.1747, 43.8793],
            [-73.1562, 43.8423],
            [-73.1557, 43.8397],
            [-73.2189, 43.8373],
            [-73.2227, 43.8581],
            [-73.2418, 43.9048],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009120',
        NAME: 'Whiting School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35172057,
        AWATER: 320800,
        INTPTLAT: '+43.8764212',
        INTPTLON: '-073.2125363',
        ELSDLEA: '09120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1793, 43.9084],
            [-73.1632, 43.9522],
            [-73.0582, 43.9628],
            [-73.0388, 43.9231],
            [-73.0397, 43.9001],
            [-73.1747, 43.8793],
            [-73.1793, 43.9084],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007140',
        NAME: 'Salisbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74786308,
        AWATER: 2961491,
        INTPTLAT: '+43.9254949',
        INTPTLON: '-073.1115282',
        ELSDLEA: '07140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.841, 44.8363],
            [-72.6784, 44.8376],
            [-72.6758, 44.7942],
            [-72.7446, 44.7865],
            [-72.7861, 44.7277],
            [-72.8598, 44.7385],
            [-72.841, 44.8363],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001890',
        NAME: 'Bakersfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115308275,
        AWATER: 274808,
        INTPTLAT: '+44.7883525',
        INTPTLON: '-072.7797390',
        ELSDLEA: '01890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0171, 44.1571],
            [-72.9522, 44.1613],
            [-72.9343, 44.1636],
            [-72.909, 44.0726],
            [-72.9081, 44.0355],
            [-73.0259, 44.0446],
            [-73.0171, 44.1571],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005160',
        NAME: 'Lincoln School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115010679,
        AWATER: 492712,
        INTPTLAT: '+44.0929248',
        INTPTLON: '-072.9735212',
        ELSDLEA: '05160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0848, 44.7729],
            [-73.038, 44.6512],
            [-73.2289, 44.7229],
            [-73.1491, 44.7963],
            [-73.0848, 44.7729],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004140',
        NAME: 'Georgia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101852115,
        AWATER: 15254545,
        INTPTLAT: '+44.7224385',
        INTPTLON: '-073.1284519',
        ELSDLEA: '04140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0647, 44.0408],
            [-73.0259, 44.0446],
            [-72.9081, 44.0355],
            [-72.9122, 43.9656],
            [-72.9797, 43.9224],
            [-73.0388, 43.9231],
            [-73.0582, 43.9628],
            [-73.0647, 44.0408],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006870',
        NAME: 'Ripton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128697497,
        AWATER: 436064,
        INTPTLAT: '+43.9849365',
        INTPTLON: '-072.9967754',
        ELSDLEA: '06870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1012, 44.8076],
            [-73.0808, 44.825],
            [-73.0678, 44.8175],
            [-73.0758, 44.8004],
            [-73.1012, 44.8076],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007560',
        NAME: 'St. Albans City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5246846,
        AWATER: 4268,
        INTPTLAT: '+44.8115382',
        INTPTLON: '-073.0844618',
        ELSDLEA: '07560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.071, 44.1844],
            [-73.0171, 44.1571],
            [-73.0259, 44.0446],
            [-73.0647, 44.0408],
            [-73.096, 44.0544],
            [-73.1495, 44.1767],
            [-73.071, 44.1844],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002640',
        NAME: 'Bristol School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107525418,
        AWATER: 1714149,
        INTPTLAT: '+44.0978095',
        INTPTLON: '-073.0485314',
        ELSDLEA: '02640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9934, 45.0149],
            [-72.8326, 45.0164],
            [-72.8223, 44.9254],
            [-72.8219, 44.9199],
            [-72.8997, 44.9348],
            [-72.9934, 45.0149],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004110',
        NAME: 'Franklin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99389384,
        AWATER: 6168013,
        INTPTLAT: '+44.9767688',
        INTPTLON: '-072.9017200',
        ELSDLEA: '04110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8326, 45.0164],
            [-72.7026, 45.0156],
            [-72.6781, 44.9289],
            [-72.8223, 44.9254],
            [-72.8326, 45.0164],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002190',
        NAME: 'Berkshire School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108754659,
        AWATER: 588608,
        INTPTLAT: '+44.9719337',
        INTPTLON: '-072.7428413',
        ELSDLEA: '02190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8997, 44.9348],
            [-72.8219, 44.9199],
            [-72.8384, 44.8585],
            [-73.0022, 44.8805],
            [-73.0555, 44.9105],
            [-72.8997, 44.9348],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007350',
        NAME: 'Sheldon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100365709,
        AWATER: 1892082,
        INTPTLAT: '+44.8985829',
        INTPTLON: '-072.9213899',
        ELSDLEA: '07350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6784, 44.8376],
            [-72.6723, 44.922],
            [-72.5387, 44.9256],
            [-72.5326, 44.8335],
            [-72.5782, 44.7816],
            [-72.5935, 44.7906],
            [-72.6758, 44.7942],
            [-72.6784, 44.8376],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005670',
        NAME: 'Montgomery School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146658205,
        AWATER: 193079,
        INTPTLAT: '+44.8600920',
        INTPTLON: '-072.6052451',
        ELSDLEA: '05670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0045, 44.7604],
            [-72.8598, 44.7385],
            [-72.7861, 44.7277],
            [-72.7955, 44.6922],
            [-72.9227, 44.6498],
            [-73.0045, 44.7604],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004080',
        NAME: 'Fletcher School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97728628,
        AWATER: 698341,
        INTPTLAT: '+44.7126159',
        INTPTLON: '-072.9004565',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0397, 43.9001],
            [-73.0388, 43.9231],
            [-72.9797, 43.9224],
            [-72.9802, 43.8826],
            [-72.9589, 43.8265],
            [-73.0168, 43.805],
            [-73.0299, 43.8467],
            [-73.0397, 43.9001],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004200',
        NAME: 'Goshen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53479664,
        AWATER: 419568,
        INTPTLAT: '+43.8647130',
        INTPTLON: '-072.9901335',
        ELSDLEA: '04200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0848, 44.7729],
            [-72.9944, 44.8159],
            [-73.0022, 44.8805],
            [-72.8384, 44.8585],
            [-72.841, 44.8363],
            [-72.8598, 44.7385],
            [-73.0045, 44.7604],
            [-73.0848, 44.7729],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003960',
        NAME: 'Fairfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174555578,
        AWATER: 2852855,
        INTPTLAT: '+44.8083126',
        INTPTLON: '-072.9280479',
        ELSDLEA: '03960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4079, 43.9299],
            [-73.2581, 43.9472],
            [-73.2418, 43.9048],
            [-73.2227, 43.8581],
            [-73.3738, 43.8429],
            [-73.4047, 43.9238],
            [-73.4079, 43.9299],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007410',
        NAME: 'Shoreham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112522775,
        AWATER: 7636009,
        INTPTLAT: '+43.8992426',
        INTPTLON: '-073.3153144',
        ELSDLEA: '07410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1906, 44.019],
            [-73.1808, 44.046],
            [-73.096, 44.0544],
            [-73.0647, 44.0408],
            [-73.0582, 43.9628],
            [-73.1632, 43.9522],
            [-73.1906, 44.019],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005490',
        NAME: 'Middlebury Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100561021,
        AWATER: 1011719,
        INTPTLAT: '+44.0018997',
        INTPTLON: '-073.1190475',
        ELSDLEA: '05490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2567, 44.0123],
            [-73.1906, 44.019],
            [-73.1632, 43.9522],
            [-73.1793, 43.9084],
            [-73.2418, 43.9048],
            [-73.2581, 43.9472],
            [-73.2567, 44.0123],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003330',
        NAME: 'Cornwall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73683322,
        AWATER: 423007,
        INTPTLAT: '+43.9629183',
        INTPTLON: '-073.2177012',
        ELSDLEA: '03330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3021, 43.6238],
            [-73.2805, 43.6686],
            [-73.2388, 43.6668],
            [-73.2432, 43.5875],
            [-73.2456, 43.5395],
            [-73.2898, 43.5778],
            [-73.3021, 43.6238],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003870',
        NAME: 'Fair Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45179400,
        AWATER: 1821240,
        INTPTLAT: '+43.6206053',
        INTPTLON: '-073.2603553',
        ELSDLEA: '03870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3936, 43.6995],
            [-73.3628, 43.7531],
            [-73.2314, 43.7645],
            [-73.233, 43.7487],
            [-73.238, 43.68],
            [-73.2388, 43.6668],
            [-73.2805, 43.6686],
            [-73.4074, 43.6734],
            [-73.3936, 43.6995],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002160',
        NAME: 'Benson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113917535,
        AWATER: 3976608,
        INTPTLAT: '+43.7100616',
        INTPTLON: '-073.3029712',
        ELSDLEA: '02160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1313, 43.7521],
            [-73.0027, 43.7568],
            [-72.94, 43.6692],
            [-73.0135, 43.6663],
            [-73.0553, 43.6649],
            [-73.1046, 43.6628],
            [-73.1084, 43.6762],
            [-73.1313, 43.7521],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006480',
        NAME: 'Pittsford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112227844,
        AWATER: 650827,
        INTPTLAT: '+43.7099607',
        INTPTLON: '-073.0484586',
        ELSDLEA: '06480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1202, 43.5358],
            [-73.087, 43.4955],
            [-73.1199, 43.4326],
            [-73.1797, 43.4659],
            [-73.1202, 43.5358],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005580',
        NAME: 'Middletown Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57769534,
        AWATER: 31828,
        INTPTLAT: '+43.4828326',
        INTPTLON: '-073.1274581',
        ELSDLEA: '05580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2488, 43.4618],
            [-73.1797, 43.4659],
            [-73.1199, 43.4326],
            [-73.1209, 43.403],
            [-73.2508, 43.4087],
            [-73.2488, 43.4618],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008760',
        NAME: 'Wells School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57263781,
        AWATER: 2269799,
        INTPTLAT: '+43.4429860',
        INTPTLON: '-073.1910858',
        ELSDLEA: '08760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.005, 43.4926],
            [-72.9285, 43.4846],
            [-72.87, 43.4808],
            [-72.8764, 43.3935],
            [-72.9935, 43.3976],
            [-73.0217, 43.3993],
            [-73.005, 43.4926],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008460',
        NAME: 'Wallingford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111333345,
        AWATER: 1163761,
        INTPTLAT: '+43.4270940',
        INTPTLON: '-072.9499415',
        ELSDLEA: '08460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0349, 43.5707],
            [-73.0122, 43.5714],
            [-72.9338, 43.5747],
            [-72.9285, 43.4846],
            [-73.005, 43.4926],
            [-73.0508, 43.4939],
            [-73.0349, 43.5707],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003210',
        NAME: 'Clarendon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81086849,
        AWATER: 557181,
        INTPTLAT: '+43.5311614',
        INTPTLON: '-072.9891642',
        ELSDLEA: '03210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1371, 44.5009],
            [-73.0893, 44.4808],
            [-73.1359, 44.475],
            [-73.1371, 44.5009],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003810',
        NAME: 'Essex Junction Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11818250,
        AWATER: 456925,
        INTPTLAT: '+44.4902875',
        INTPTLON: '-073.1141209',
        ELSDLEA: '03810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9536, 44.3458],
            [-72.9193, 44.4286],
            [-72.8886, 44.4704],
            [-72.8386, 44.4592],
            [-72.805, 44.4517],
            [-72.8791, 44.33],
            [-72.9536, 44.3458],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002310',
        NAME: 'Bolton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '04',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109444294,
        AWATER: 710677,
        INTPTLAT: '+44.3998598',
        INTPTLON: '-072.8773967',
        ELSDLEA: '02310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1916, 44.0616],
            [-72.0786, 44.0296],
            [-72.0787, 44.0296],
            [-72.099, 43.9575],
            [-72.099, 43.9575],
            [-72.2327, 43.9927],
            [-72.1916, 44.0616],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002340',
        NAME: 'Bradford Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76613673,
        AWATER: 665804,
        INTPTLAT: '+44.0121764',
        INTPTLON: '-072.1582963',
        ELSDLEA: '02340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1703, 44.1921],
            [-72.042, 44.1429],
            [-72.0786, 44.0296],
            [-72.1916, 44.0616],
            [-72.1703, 44.1921],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005940',
        NAME: 'Newbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160623561,
        AWATER: 1956195,
        INTPTLAT: '+44.1193206',
        INTPTLON: '-072.1174340',
        ELSDLEA: '05940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7913, 43.9619],
            [-72.7423, 44.0297],
            [-72.6838, 44.0129],
            [-72.6375, 44.0006],
            [-72.7035, 43.9093],
            [-72.7078, 43.9104],
            [-72.7913, 43.9619],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002370',
        NAME: 'Braintree School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99177259,
        AWATER: 259630,
        INTPTLAT: '+43.9670716',
        INTPTLON: '-072.7086036',
        ELSDLEA: '02370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6838, 44.0129],
            [-72.6348, 44.0894],
            [-72.6289, 44.0886],
            [-72.5381, 44.0624],
            [-72.5099, 43.9661],
            [-72.6375, 44.0006],
            [-72.6838, 44.0129],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002670',
        NAME: 'Brookfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105654004,
        AWATER: 918194,
        INTPTLAT: '+44.0239083',
        INTPTLON: '-072.5878915',
        ELSDLEA: '02670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4197, 44.221],
            [-72.3684, 44.2041],
            [-72.305, 44.1831],
            [-72.3481, 44.0961],
            [-72.443, 44.1287],
            [-72.4197, 44.221],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006210',
        NAME: 'Orange School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100317070,
        AWATER: 724516,
        INTPTLAT: '+44.1665554',
        INTPTLON: '-072.3466004',
        ELSDLEA: '06210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3427, 44.9137],
            [-73.3092, 44.8428],
            [-73.3414, 44.8088],
            [-73.3681, 44.8279],
            [-73.3427, 44.9137],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004890',
        NAME: 'Isle La Motte School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20421053,
        AWATER: 22738126,
        INTPTLAT: '+44.8713248',
        INTPTLON: '-073.3491998',
        ELSDLEA: '04890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6375, 44.0006],
            [-72.5099, 43.9661],
            [-72.5744, 43.8733],
            [-72.7035, 43.9093],
            [-72.6375, 44.0006],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006720',
        NAME: 'Randolph School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124422381,
        AWATER: 405387,
        INTPTLAT: '+43.9216848',
        INTPTLON: '-072.6109151',
        ELSDLEA: '06720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3431, 45.0108],
            [-73.1919, 45.0136],
            [-73.2308, 44.9274],
            [-73.3092, 44.8428],
            [-73.3427, 44.9137],
            [-73.3393, 44.9169],
            [-73.3431, 45.0108],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001770',
        NAME: 'Alburgh School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75475905,
        AWATER: 50955823,
        INTPTLAT: '+44.9578823',
        INTPTLON: '-073.2919738',
        ELSDLEA: '01770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3704, 44.6777],
            [-73.3521, 44.7607],
            [-73.2561, 44.7825],
            [-73.2289, 44.7229],
            [-73.2281, 44.6882],
            [-73.3717, 44.6752],
            [-73.3704, 44.6777],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004290',
        NAME: 'Grand Isle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42524869,
        AWATER: 48468934,
        INTPTLAT: '+44.7162872',
        INTPTLON: '-073.2996266',
        ELSDLEA: '04290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0135, 43.6663],
            [-72.94, 43.6692],
            [-72.9396, 43.6654],
            [-72.9338, 43.5747],
            [-73.0122, 43.5714],
            [-73.0321, 43.6108],
            [-73.0135, 43.6663],
          ],
          [
            [-73.0108, 43.6055],
            [-72.9461, 43.5939],
            [-72.9477, 43.6219],
            [-73.0108, 43.6055],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007080',
        NAME: 'Rutland Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49642888,
        AWATER: 436715,
        INTPTLAT: '+43.6446502',
        INTPTLON: '-072.9530419',
        ELSDLEA: '07080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4074, 43.6734],
            [-73.2805, 43.6686],
            [-73.3021, 43.6238],
            [-73.4074, 43.6734],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008820',
        NAME: 'West Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72078427,
        AWATER: 1667162,
        INTPTLAT: '+43.6392552',
        INTPTLON: '-073.3615947',
        ELSDLEA: '08820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2938, 44.4382],
            [-73.1545, 44.4054],
            [-73.1416, 44.4014],
            [-73.1326, 44.3645],
            [-73.1653, 44.3621],
            [-73.327, 44.3434],
            [-73.2938, 44.4382],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007320',
        NAME: 'Shelburne School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62960338,
        AWATER: 53758361,
        INTPTLAT: '+44.3926737',
        INTPTLON: '-073.2434343',
        ELSDLEA: '07320',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1209, 43.403],
            [-73.1199, 43.4326],
            [-73.087, 43.4955],
            [-73.0508, 43.4939],
            [-73.005, 43.4926],
            [-73.0217, 43.3993],
            [-73.1209, 43.403],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008040',
        NAME: 'Tinmouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73944718,
        AWATER: 429892,
        INTPTLAT: '+43.4343974',
        INTPTLON: '-073.0727822',
        ELSDLEA: '08040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9338, 43.5747],
            [-72.775, 43.5794],
            [-72.7715, 43.4779],
            [-72.87, 43.4808],
            [-72.9285, 43.4846],
            [-72.9338, 43.5747],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007440',
        NAME: 'Shrewsbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129398248,
        AWATER: 534160,
        INTPTLAT: '+43.5234408',
        INTPTLON: '-072.8601708',
        ELSDLEA: '07440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.87, 43.4808],
            [-72.7715, 43.4779],
            [-72.7272, 43.4687],
            [-72.7598, 43.3541],
            [-72.8534, 43.3711],
            [-72.8764, 43.3935],
            [-72.87, 43.4808],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005820',
        NAME: 'Mount Holly School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127187458,
        AWATER: 1193444,
        INTPTLAT: '+43.4308251',
        INTPTLON: '-072.8082587',
        ELSDLEA: '05820',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8586, 43.6949],
            [-72.8372, 43.7199],
            [-72.82, 43.7168],
            [-72.7009, 43.6828],
            [-72.758, 43.5875],
            [-72.7728, 43.5916],
            [-72.8687, 43.618],
            [-72.8586, 43.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007380',
        NAME: 'Killington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120652620,
        AWATER: 705218,
        INTPTLAT: '+43.6575940',
        INTPTLON: '-072.7844065',
        ELSDLEA: '07380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0227, 44.4341],
            [-72.9193, 44.4286],
            [-72.9536, 44.3458],
            [-73.0327, 44.3651],
            [-73.0821, 44.3707],
            [-73.0227, 44.4341],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006840',
        NAME: 'Richmond School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '04',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83465974,
        AWATER: 1351467,
        INTPTLAT: '+44.3821727',
        INTPTLON: '-072.9798316',
        ELSDLEA: '06840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9604, 44.54],
            [-72.9234, 44.5042],
            [-72.9791, 44.5163],
            [-72.9604, 44.54],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008190',
        NAME: 'Underhill Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '04',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18817836,
        AWATER: 3080,
        INTPTLAT: '+44.5216682',
        INTPTLON: '-072.9452374',
        ELSDLEA: '08190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2314, 43.7645],
            [-73.2189, 43.8373],
            [-73.1557, 43.8397],
            [-73.1313, 43.7521],
            [-73.233, 43.7487],
            [-73.2314, 43.7645],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007890',
        NAME: 'Sudbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55606704,
        AWATER: 1946604,
        INTPTLAT: '+43.7982284',
        INTPTLON: '-073.1742733',
        ELSDLEA: '07890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0327, 44.3651],
            [-72.9536, 44.3458],
            [-72.8791, 44.33],
            [-72.896, 44.2763],
            [-72.9221, 44.2222],
            [-72.9652, 44.2303],
            [-73.0204, 44.3037],
            [-73.0327, 44.3651],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004770',
        NAME: 'Huntington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '04',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98267306,
        AWATER: 257454,
        INTPTLAT: '+44.3125390',
        INTPTLON: '-072.9521082',
        ELSDLEA: '04770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2432, 43.5875],
            [-73.2388, 43.6668],
            [-73.238, 43.68],
            [-73.233, 43.7487],
            [-73.1313, 43.7521],
            [-73.1084, 43.6762],
            [-73.1046, 43.6628],
            [-73.0957, 43.6325],
            [-73.1154, 43.5847],
            [-73.2432, 43.5875],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000001',
        NAME: 'Castleton-Hubbardton Union School District 42',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171862911,
        AWATER: 12498839,
        INTPTLAT: '+43.6625647',
        INTPTLON: '-073.1577756',
        ELSDLEA: '00001',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1653, 44.3621],
            [-73.1326, 44.3645],
            [-73.0893, 44.3695],
            [-73.0821, 44.3707],
            [-73.0327, 44.3651],
            [-73.0204, 44.3037],
            [-73.0806, 44.2823],
            [-73.1451, 44.2734],
            [-73.1653, 44.3621],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004680',
        NAME: 'Hinesburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101916423,
        AWATER: 1030567,
        INTPTLAT: '+44.3327011',
        INTPTLON: '-073.0874086',
        ELSDLEA: '04680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.327, 44.3434],
            [-73.1653, 44.3621],
            [-73.1451, 44.2734],
            [-73.1968, 44.2666],
            [-73.3133, 44.2641],
            [-73.327, 44.3434],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003090',
        NAME: 'Charlotte School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106860232,
        AWATER: 23578181,
        INTPTLAT: '+44.3057701',
        INTPTLON: '-073.2356366',
        ELSDLEA: '03090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.032, 44.468],
            [-72.9711, 44.5503],
            [-72.9604, 44.54],
            [-72.9791, 44.5163],
            [-72.9234, 44.5042],
            [-72.8886, 44.4704],
            [-72.9193, 44.4286],
            [-73.0227, 44.4341],
            [-73.032, 44.468],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004980',
        NAME: 'Jericho School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '04',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79969793,
        AWATER: 411626,
        INTPTLAT: '+44.4644544',
        INTPTLON: '-072.9631555',
        ELSDLEA: '04980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1562, 43.8423],
            [-73.0299, 43.8467],
            [-73.0168, 43.805],
            [-73.0027, 43.7568],
            [-73.1313, 43.7521],
            [-73.1557, 43.8397],
            [-73.1562, 43.8423],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002480',
        NAME: 'Brandon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103380002,
        AWATER: 574975,
        INTPTLAT: '+43.8053476',
        INTPTLON: '-073.0753206',
        ELSDLEA: '02480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.456, 44.1331],
            [-72.443, 44.1287],
            [-72.3481, 44.0961],
            [-72.3822, 44.0082],
            [-72.3828, 44.0085],
            [-72.5021, 44.0503],
            [-72.456, 44.1331],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008580',
        NAME: 'Washington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100523253,
        AWATER: 144447,
        INTPTLAT: '+44.0761653',
        INTPTLON: '-072.4226326',
        ELSDLEA: '08580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5744, 43.8733],
            [-72.5099, 43.9661],
            [-72.3949, 43.9291],
            [-72.4601, 43.8383],
            [-72.5744, 43.8733],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008160',
        NAME: 'Tunbridge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115421279,
        AWATER: 394035,
        INTPTLAT: '+43.9006022',
        INTPTLON: '-072.4853535',
        ELSDLEA: '08160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2728, 43.8994],
            [-72.1675, 43.871],
            [-72.2052, 43.771],
            [-72.3375, 43.8097],
            [-72.2728, 43.8994],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008010',
        NAME: 'Thetford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112368849,
        AWATER: 2715971,
        INTPTLAT: '+43.8367087',
        INTPTLON: '-072.2494962',
        ELSDLEA: '08010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9106, 44.6309],
            [-72.8093, 44.5479],
            [-72.8386, 44.4592],
            [-72.8886, 44.4704],
            [-72.9234, 44.5042],
            [-72.9604, 44.54],
            [-72.9711, 44.5503],
            [-72.9106, 44.6309],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008220',
        NAME: 'Underhill Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '04',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125824239,
        AWATER: 189148,
        INTPTLAT: '+44.5423672',
        INTPTLON: '-072.8832262',
        ELSDLEA: '08220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3717, 44.6752],
            [-73.2281, 44.6882],
            [-73.2545, 44.6079],
            [-73.3615, 44.5636],
            [-73.3717, 44.6752],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007500',
        NAME: 'South Hero School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38626002,
        AWATER: 84366077,
        INTPTLAT: '+44.6285484',
        INTPTLON: '-073.3088248',
        ELSDLEA: '07500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3414, 44.8088],
            [-73.3092, 44.8428],
            [-73.2308, 44.9274],
            [-73.194, 44.8868],
            [-73.2561, 44.7825],
            [-73.3521, 44.7607],
            [-73.3414, 44.8088],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006090',
        NAME: 'North Hero School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34846768,
        AWATER: 85774599,
        INTPTLAT: '+44.8350813',
        INTPTLON: '-073.2256575',
        ELSDLEA: '06090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6772, 42.899],
            [-72.5328, 42.8961],
            [-72.5552, 42.8582],
            [-72.5456, 42.8239],
            [-72.5613, 42.8244],
            [-72.6811, 42.8272],
            [-72.6772, 42.899],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002490',
        NAME: 'Brattleboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82433741,
        AWATER: 1508475,
        INTPTLAT: '+42.8601870',
        INTPTLON: '-072.6330776',
        ELSDLEA: '02490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6393, 43.1278],
            [-72.5579, 43.1488],
            [-72.563, 43.1282],
            [-72.5751, 43.0711],
            [-72.6054, 43.0721],
            [-72.6393, 43.1278],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001860',
        NAME: 'Athens School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33775944,
        AWATER: 197857,
        INTPTLAT: '+43.1171099',
        INTPTLON: '-072.5989588',
        ELSDLEA: '01860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6772, 43.2233],
            [-72.5559, 43.2354],
            [-72.5579, 43.1488],
            [-72.6393, 43.1278],
            [-72.6825, 43.1325],
            [-72.6772, 43.2233],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004230',
        NAME: 'Grafton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99115191,
        AWATER: 250820,
        INTPTLAT: '+43.1818613',
        INTPTLON: '-072.6166363',
        ELSDLEA: '04230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6759, 42.9224],
            [-72.6357, 42.9691],
            [-72.6085, 42.9682],
            [-72.5208, 42.9617],
            [-72.5267, 42.9341],
            [-72.5328, 42.8961],
            [-72.6772, 42.899],
            [-72.6759, 42.9224],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003570',
        NAME: 'Dummerston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79235626,
        AWATER: 620567,
        INTPTLAT: '+42.9225376',
        INTPTLON: '-072.5994269',
        ELSDLEA: '03570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7548, 42.9872],
            [-72.7427, 43.0233],
            [-72.65, 43.019],
            [-72.6357, 42.9691],
            [-72.6759, 42.9224],
            [-72.7748, 42.924],
            [-72.7548, 42.9872],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005970',
        NAME: 'Newfane School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104185562,
        AWATER: 783492,
        INTPTLAT: '+42.9727535',
        INTPTLON: '-072.7040391',
        ELSDLEA: '05970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5862, 43.0234],
            [-72.4595, 43.0206],
            [-72.5208, 42.9617],
            [-72.6085, 42.9682],
            [-72.5862, 43.0234],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006690',
        NAME: 'Putney School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69306745,
        AWATER: 182238,
        INTPTLAT: '+42.9951916',
        INTPTLON: '-072.5298301',
        ELSDLEA: '06690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5579, 43.1488],
            [-72.5559, 43.2354],
            [-72.5499, 43.2358],
            [-72.435, 43.2333],
            [-72.4378, 43.1251],
            [-72.563, 43.1282],
            [-72.5579, 43.1488],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006930',
        NAME: 'Rockingham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107315819,
        AWATER: 2046987,
        INTPTLAT: '+43.1704749',
        INTPTLON: '-072.5065934',
        ELSDLEA: '06930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5613, 42.8244],
            [-72.5456, 42.8239],
            [-72.4584, 42.7268],
            [-72.5659, 42.73],
            [-72.5613, 42.8244],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008310',
        NAME: 'Vernon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50366972,
        AWATER: 1595398,
        INTPTLAT: '+42.7641329',
        INTPTLON: '-072.5220862',
        ELSDLEA: '08310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5862, 43.0234],
            [-72.5751, 43.0711],
            [-72.563, 43.1282],
            [-72.4378, 43.1251],
            [-72.4595, 43.0206],
            [-72.5862, 43.0234],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008970',
        NAME: 'Westminster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118712348,
        AWATER: 669307,
        INTPTLAT: '+43.0746825',
        INTPTLON: '-072.5080568',
        ELSDLEA: '08970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7303, 43.2332],
            [-72.6772, 43.2233],
            [-72.6825, 43.1325],
            [-72.7081, 43.1258],
            [-72.7745, 43.1418],
            [-72.7303, 43.2332],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009270',
        NAME: 'Windham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67375386,
        AWATER: 211573,
        INTPTLAT: '+43.1739140',
        INTPTLON: '-072.7014373',
        ELSDLEA: '09270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8725, 43.0535],
            [-72.7341, 43.05],
            [-72.7427, 43.0233],
            [-72.7548, 42.9872],
            [-72.8796, 42.9903],
            [-72.8725, 43.0535],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008520',
        NAME: 'Wardsboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75616032,
        AWATER: 84144,
        INTPTLAT: '+43.0194784',
        INTPTLON: '-072.8121484',
        ELSDLEA: '08520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9182, 42.9906],
            [-72.8796, 42.9903],
            [-72.7548, 42.9872],
            [-72.7748, 42.924],
            [-72.7986, 42.9222],
            [-72.9214, 42.9268],
            [-72.9182, 42.9906],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003540',
        NAME: 'Dover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91288549,
        AWATER: 224311,
        INTPTLAT: '+42.9654896',
        INTPTLON: '-072.8471074',
        ELSDLEA: '03540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8026, 42.8265],
            [-72.8024, 42.8285],
            [-72.6811, 42.8272],
            [-72.6862, 42.7332],
            [-72.8091, 42.7366],
            [-72.8026, 42.8265],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004500',
        NAME: 'Halifax School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102858929,
        AWATER: 249270,
        INTPTLAT: '+42.7839966',
        INTPTLON: '-072.7481962',
        ELSDLEA: '04500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7341, 43.05],
            [-72.7081, 43.1258],
            [-72.6825, 43.1325],
            [-72.6393, 43.1278],
            [-72.6054, 43.0721],
            [-72.65, 43.019],
            [-72.7427, 43.0233],
            [-72.7341, 43.05],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008100',
        NAME: 'Townshend School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109357672,
        AWATER: 1166470,
        INTPTLAT: '+43.0710269',
        INTPTLON: '-072.6821767',
        ELSDLEA: '08100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7986, 42.9222],
            [-72.7748, 42.924],
            [-72.6759, 42.9224],
            [-72.6772, 42.899],
            [-72.6811, 42.8272],
            [-72.8024, 42.8285],
            [-72.7986, 42.9222],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005400',
        NAME: 'Marlboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104296731,
        AWATER: 984826,
        INTPTLAT: '+42.8807639',
        INTPTLON: '-072.7361351',
        ELSDLEA: '05400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6811, 42.8272],
            [-72.5613, 42.8244],
            [-72.5659, 42.73],
            [-72.6746, 42.7329],
            [-72.6862, 42.7332],
            [-72.6811, 42.8272],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004470',
        NAME: 'Guilford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103159425,
        AWATER: 342065,
        INTPTLAT: '+42.7739623',
        INTPTLON: '-072.6186917',
        ELSDLEA: '04470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8687, 43.1132],
            [-72.8649, 43.1662],
            [-72.7745, 43.1418],
            [-72.7081, 43.1258],
            [-72.7341, 43.05],
            [-72.8725, 43.0535],
            [-72.8687, 43.1132],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004920',
        NAME: 'Jamaica School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126730668,
        AWATER: 1380580,
        INTPTLAT: '+43.1026637',
        INTPTLON: '-072.8200136',
        ELSDLEA: '04920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.65, 43.019],
            [-72.6054, 43.0721],
            [-72.5751, 43.0711],
            [-72.5862, 43.0234],
            [-72.6085, 42.9682],
            [-72.6357, 42.9691],
            [-72.65, 43.019],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002700',
        NAME: 'Brookline School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33198097,
        AWATER: 194677,
        INTPTLAT: '+43.0254956',
        INTPTLON: '-072.6112831',
        ELSDLEA: '02700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9248, 42.8562],
            [-72.9214, 42.9268],
            [-72.7986, 42.9222],
            [-72.8024, 42.8285],
            [-72.8026, 42.8265],
            [-72.926, 42.8299],
            [-72.9248, 42.8562],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009240',
        NAME: 'Wilmington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102802217,
        AWATER: 5231274,
        INTPTLAT: '+42.8810199',
        INTPTLON: '-072.8655808',
        ELSDLEA: '09240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.926, 42.8299],
            [-72.8026, 42.8265],
            [-72.8091, 42.7366],
            [-72.8643, 42.7378],
            [-72.9272, 42.7392],
            [-72.9303, 42.7393],
            [-72.926, 42.8299],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009150',
        NAME: 'Whitingham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94649480,
        AWATER: 6010677,
        INTPTLAT: '+42.7805148',
        INTPTLON: '-072.8694433',
        ELSDLEA: '09150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7446, 44.7865],
            [-72.6758, 44.7942],
            [-72.5935, 44.7906],
            [-72.663, 44.7032],
            [-72.7043, 44.7184],
            [-72.7446, 44.7865],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002100',
        NAME: 'Belvidere School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83104375,
        AWATER: 132187,
        INTPTLAT: '+44.7588431',
        INTPTLON: '-072.6776844',
        ELSDLEA: '02100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.663, 44.7032],
            [-72.5935, 44.7906],
            [-72.5782, 44.7816],
            [-72.4173, 44.7229],
            [-72.4231, 44.7152],
            [-72.4767, 44.6418],
            [-72.5973, 44.679],
            [-72.663, 44.7032],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003720',
        NAME: 'Eden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163950251,
        AWATER: 2560105,
        INTPTLAT: '+44.7144313',
        INTPTLON: '-072.5394153',
        ELSDLEA: '03720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5398, 44.5534],
            [-72.5247, 44.5733],
            [-72.4823, 44.6339],
            [-72.3682, 44.5923],
            [-72.3748, 44.5841],
            [-72.4298, 44.512],
            [-72.5398, 44.5534],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009390',
        NAME: 'Wolcott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100462284,
        AWATER: 890484,
        INTPTLAT: '+44.5701912',
        INTPTLON: '-072.4494248',
        ELSDLEA: '09390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5387, 44.9256],
            [-72.5357, 44.9258],
            [-72.4126, 44.9263],
            [-72.4028, 44.8414],
            [-72.5326, 44.8335],
            [-72.5387, 44.9256],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008910',
        NAME: 'Westfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103836033,
        AWATER: 276525,
        INTPTLAT: '+44.8834736',
        INTPTLON: '-072.4849577',
        ELSDLEA: '08910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5544, 45.0083],
            [-72.4335, 45.0079],
            [-72.4126, 44.9263],
            [-72.5357, 44.9258],
            [-72.5544, 45.0083],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004950',
        NAME: 'Jay School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87848736,
        AWATER: 125582,
        INTPTLAT: '+44.9566315',
        INTPTLON: '-072.5073198',
        ELSDLEA: '04950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1209, 43.403],
            [-73.0217, 43.3993],
            [-72.9935, 43.3976],
            [-72.8764, 43.3935],
            [-72.8534, 43.3711],
            [-72.8679, 43.3007],
            [-72.9762, 43.2916],
            [-72.9963, 43.3053],
            [-73.1227, 43.3087],
            [-73.1209, 43.403],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008240',
        NAME: 'Currier Memorial Union School District 23',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220379087,
        AWATER: 688477,
        INTPTLAT: '+43.3506378',
        INTPTLON: '-072.9911238',
        ELSDLEA: '08240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9735, 44.6788],
            [-71.9103, 44.647],
            [-71.8427, 44.6112],
            [-71.8981, 44.5579],
            [-71.9343, 44.5686],
            [-72.0007, 44.5905],
            [-71.9735, 44.6788],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002790',
        NAME: 'Burke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87791210,
        AWATER: 356442,
        INTPTLAT: '+44.6108633',
        INTPTLON: '-071.9309439',
        ELSDLEA: '02790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.263, 44.5423],
            [-72.152, 44.5012],
            [-72.2237, 44.4246],
            [-72.3192, 44.4618],
            [-72.263, 44.5423],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008430',
        NAME: 'Walden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99928049,
        AWATER: 922528,
        INTPTLAT: '+44.4878264',
        INTPTLON: '-072.2249990',
        ELSDLEA: '08430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0839, 44.5104],
            [-71.9582, 44.4914],
            [-71.9333, 44.4412],
            [-72.0357, 44.3883],
            [-72.0455, 44.3984],
            [-72.0839, 44.5104],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007650',
        NAME: 'St. Johnsbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94250326,
        AWATER: 917583,
        INTPTLAT: '+44.4603024',
        INTPTLON: '-072.0049239',
        ELSDLEA: '07650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0576, 44.598],
            [-72.0007, 44.5905],
            [-71.9343, 44.5686],
            [-71.9582, 44.4914],
            [-72.0839, 44.5104],
            [-72.0576, 44.598],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005310',
        NAME: 'Lyndon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102213087,
        AWATER: 922113,
        INTPTLAT: '+44.5514589',
        INTPTLON: '-072.0128704',
        ELSDLEA: '05310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4298, 44.512],
            [-72.3748, 44.5841],
            [-72.263, 44.5423],
            [-72.3192, 44.4618],
            [-72.3213, 44.4626],
            [-72.4343, 44.5061],
            [-72.4298, 44.512],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004560',
        NAME: 'Hardwick School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100014425,
        AWATER: 1032157,
        INTPTLAT: '+44.5187137',
        INTPTLON: '-072.3489650',
        ELSDLEA: '04560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3738, 43.8429],
            [-73.2227, 43.8581],
            [-73.2189, 43.8373],
            [-73.2314, 43.7645],
            [-73.3628, 43.7531],
            [-73.3793, 43.8085],
            [-73.3738, 43.8429],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006270',
        NAME: 'Orwell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122186318,
        AWATER: 6458980,
        INTPTLAT: '+43.8090987',
        INTPTLON: '-073.2779922',
        ELSDLEA: '06270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1359, 44.475],
            [-73.0893, 44.4808],
            [-73.032, 44.468],
            [-73.0227, 44.4341],
            [-73.0821, 44.3707],
            [-73.0893, 44.3695],
            [-73.1416, 44.4014],
            [-73.1545, 44.4054],
            [-73.1359, 44.475],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009210',
        NAME: 'Williston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77896800,
        AWATER: 1311262,
        INTPTLAT: '+44.4291659',
        INTPTLON: '-073.0963101',
        ELSDLEA: '09210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1574, 44.4901],
            [-73.0973, 44.5705],
            [-72.9711, 44.5503],
            [-73.032, 44.468],
            [-73.0893, 44.4808],
            [-73.1371, 44.5009],
            [-73.1574, 44.4901],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003840',
        NAME: 'Essex Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88734543,
        AWATER: 822051,
        INTPTLAT: '+44.5386242',
        INTPTLON: '-073.0502230',
        ELSDLEA: '03840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0946, 44.3574],
            [-72.0455, 44.3984],
            [-72.0357, 44.3883],
            [-71.9846, 44.3362],
            [-72.059, 44.2557],
            [-72.1878, 44.2769],
            [-72.0946, 44.3574],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001980',
        NAME: 'Barnet School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109404526,
        AWATER: 3428540,
        INTPTLAT: '+44.3126302',
        INTPTLON: '-072.0667786',
        ELSDLEA: '01980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0125, 44.6988],
            [-71.9399, 44.769],
            [-71.8584, 44.7277],
            [-71.8373, 44.7166],
            [-71.9103, 44.647],
            [-71.9735, 44.6788],
            [-72.0125, 44.6988],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005910',
        NAME: 'Newark School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95181230,
        AWATER: 1207797,
        INTPTLAT: '+44.7354157',
        INTPTLON: '-071.9395316',
        ELSDLEA: '05910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.038, 44.6512],
            [-72.9241, 44.6331],
            [-72.9106, 44.6309],
            [-72.9711, 44.5503],
            [-73.0973, 44.5705],
            [-73.038, 44.6512],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008940',
        NAME: 'Westford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101221150,
        AWATER: 540878,
        INTPTLAT: '+44.6074224',
        INTPTLON: '-073.0036416',
        ELSDLEA: '08940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1145, 44.7496],
            [-72.0125, 44.6988],
            [-71.9735, 44.6788],
            [-72.0007, 44.5905],
            [-72.0576, 44.598],
            [-72.0889, 44.6837],
            [-72.1145, 44.7496],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007950',
        NAME: 'Sutton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98881088,
        AWATER: 506310,
        INTPTLAT: '+44.6650706',
        INTPTLON: '-072.0359728',
        ELSDLEA: '07950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2698, 44.3402],
            [-72.2281, 44.3963],
            [-72.0946, 44.3574],
            [-72.1878, 44.2769],
            [-72.3176, 44.2984],
            [-72.2698, 44.3402],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006390',
        NAME: 'Peacham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121128462,
        AWATER: 2687766,
        INTPTLAT: '+44.3289270',
        INTPTLON: '-072.1998339',
        ELSDLEA: '06390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2029, 44.6241],
            [-72.1466, 44.7035],
            [-72.0889, 44.6837],
            [-72.0576, 44.598],
            [-72.0839, 44.5104],
            [-72.1378, 44.5186],
            [-72.2374, 44.5768],
            [-72.2029, 44.6241],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008228',
        NAME: 'Millers Run Union School District 37',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186530747,
        AWATER: 1493102,
        INTPTLAT: '+44.6011550',
        INTPTLON: '-072.1331263',
        ELSDLEA: '08228',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3682, 44.5923],
            [-72.3153, 44.6652],
            [-72.2029, 44.6241],
            [-72.2374, 44.5768],
            [-72.1378, 44.5186],
            [-72.152, 44.5012],
            [-72.263, 44.5423],
            [-72.3748, 44.5841],
            [-72.3682, 44.5923],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000003',
        NAME: 'Lakeview Union School District 43',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129709394,
        AWATER: 4751203,
        INTPTLAT: '+44.5805471',
        INTPTLON: '-072.2623544',
        ELSDLEA: '00003',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7958, 44.5051],
            [-71.7071, 44.5388],
            [-71.6054, 44.4845],
            [-71.7384, 44.408],
            [-71.7958, 44.5051],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005280',
        NAME: 'Lunenburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116741405,
        AWATER: 1832128,
        INTPTLAT: '+44.4709955',
        INTPTLON: '-071.7059469',
        ELSDLEA: '05280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8976, 45.0084],
            [-71.6981, 45.0114],
            [-71.701, 45.0082],
            [-71.7543, 44.9472],
            [-71.8458, 44.9461],
            [-71.8959, 44.9445],
            [-71.9177, 44.9436],
            [-71.8976, 45.0084],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006150',
        NAME: 'Norton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100954907,
        AWATER: 1065310,
        INTPTLAT: '+44.9772950',
        INTPTLON: '-071.8043380',
        ELSDLEA: '06150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6321, 44.6114],
            [-71.5563, 44.5699],
            [-71.5713, 44.5383],
            [-71.6054, 44.4845],
            [-71.7071, 44.5388],
            [-71.6321, 44.6114],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004440',
        NAME: 'Guildhall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84889056,
        AWATER: 803030,
        INTPTLAT: '+44.5407127',
        INTPTLON: '-071.6100731',
        ELSDLEA: '04440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5973, 44.679],
            [-72.4767, 44.6418],
            [-72.4823, 44.6339],
            [-72.5247, 44.5733],
            [-72.6505, 44.6036],
            [-72.5973, 44.679],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004800',
        NAME: 'Hyde Park School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97362061,
        AWATER: 3462028,
        INTPTLAT: '+44.6242619',
        INTPTLON: '-072.5704166',
        ELSDLEA: '04800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2467, 45.0046],
            [-72.0522, 45.0064],
            [-72.0658, 44.931],
            [-72.0674, 44.9144],
            [-72.1201, 44.8647],
            [-72.1617, 44.886],
            [-72.1894, 44.9016],
            [-72.2264, 44.9542],
            [-72.2467, 45.0046],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003480',
        NAME: 'Derby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126901407,
        AWATER: 21960776,
        INTPTLAT: '+44.9520163',
        INTPTLON: '-072.1364591',
        ELSDLEA: '03480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7861, 44.7277],
            [-72.7446, 44.7865],
            [-72.7043, 44.7184],
            [-72.7391, 44.6708],
            [-72.7955, 44.6922],
            [-72.7861, 44.7277],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008670',
        NAME: 'Waterville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42344007,
        AWATER: 143824,
        INTPTLAT: '+44.7376369',
        INTPTLON: '-072.7617729',
        ELSDLEA: '08670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7391, 44.6708],
            [-72.7043, 44.7184],
            [-72.663, 44.7032],
            [-72.5973, 44.679],
            [-72.6505, 44.6036],
            [-72.7475, 44.5948],
            [-72.7391, 44.6708],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005010',
        NAME: 'Johnson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115788961,
        AWATER: 981485,
        INTPTLAT: '+44.6481239',
        INTPTLON: '-072.6888397',
        ELSDLEA: '05010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5968, 44.4793],
            [-72.5398, 44.5534],
            [-72.4298, 44.512],
            [-72.4343, 44.5061],
            [-72.4905, 44.4257],
            [-72.4929, 44.423],
            [-72.5968, 44.4793],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003750',
        NAME: 'Elmore School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101283649,
        AWATER: 1416251,
        INTPTLAT: '+44.4880711',
        INTPTLON: '-072.5142868',
        ELSDLEA: '03750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9227, 44.6498],
            [-72.7955, 44.6922],
            [-72.7391, 44.6708],
            [-72.7475, 44.5948],
            [-72.7682, 44.563],
            [-72.8093, 44.5479],
            [-72.9106, 44.6309],
            [-72.9241, 44.6331],
            [-72.9227, 44.6498],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002910',
        NAME: 'Cambridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163664478,
        AWATER: 1282367,
        INTPTLAT: '+44.6265471',
        INTPTLON: '-072.8153714',
        ELSDLEA: '02910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2533, 44.7529],
            [-72.2214, 44.7973],
            [-72.1634, 44.8113],
            [-72.0824, 44.7817],
            [-72.1145, 44.7496],
            [-72.0889, 44.6837],
            [-72.1466, 44.7035],
            [-72.2589, 44.7441],
            [-72.2533, 44.7529],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002070',
        NAME: 'Barton Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101816847,
        AWATER: 4007219,
        INTPTLAT: '+44.7703056',
        INTPTLON: '-072.1496154',
        ELSDLEA: '02070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4173, 44.7229],
            [-72.4124, 44.7295],
            [-72.4048, 44.7405],
            [-72.3653, 44.7945],
            [-72.2533, 44.7529],
            [-72.2589, 44.7441],
            [-72.3098, 44.673],
            [-72.4231, 44.7152],
            [-72.4173, 44.7229],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001740',
        NAME: 'Albany School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99035833,
        AWATER: 1131070,
        INTPTLAT: '+44.7360043',
        INTPTLON: '-072.3359675',
        ELSDLEA: '01740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2013, 44.8353],
            [-72.1617, 44.886],
            [-72.1201, 44.8647],
            [-72.0404, 44.8219],
            [-72.0824, 44.7817],
            [-72.1634, 44.8113],
            [-72.2006, 44.8258],
            [-72.2013, 44.8353],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002730',
        NAME: 'Brownington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73150238,
        AWATER: 693154,
        INTPTLAT: '+44.8291789',
        INTPTLON: '-072.1276035',
        ELSDLEA: '02730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3098, 44.673],
            [-72.2589, 44.7441],
            [-72.1466, 44.7035],
            [-72.2029, 44.6241],
            [-72.3153, 44.6652],
            [-72.3098, 44.673],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004170',
        NAME: 'Glover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97462378,
        AWATER: 2524591,
        INTPTLAT: '+44.6787090',
        INTPTLON: '-072.2333825',
        ELSDLEA: '04170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2264, 44.9542],
            [-72.1894, 44.9016],
            [-72.2428, 44.9311],
            [-72.2264, 44.9542],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006000',
        NAME: 'Newport City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15092427,
        AWATER: 4671207,
        INTPTLAT: '+44.9415975',
        INTPTLON: '-072.2194775',
        ELSDLEA: '06000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2214, 44.7973],
            [-72.2006, 44.8258],
            [-72.1634, 44.8113],
            [-72.2214, 44.7973],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006240',
        NAME: 'Orleans Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10211003,
        AWATER: 200530,
        INTPTLAT: '+44.8062094',
        INTPTLON: '-072.1949856',
        ELSDLEA: '06240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2498, 44.0883],
            [-73.1808, 44.046],
            [-73.1906, 44.019],
            [-73.2567, 44.0123],
            [-73.263, 44.0348],
            [-73.2498, 44.0883],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009060',
        NAME: 'Weybridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44398925,
        AWATER: 1209185,
        INTPTLAT: '+44.0403287',
        INTPTLON: '-073.2227845',
        ELSDLEA: '09060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2136, 44.1599],
            [-73.1771, 44.1739],
            [-73.1495, 44.1767],
            [-73.096, 44.0544],
            [-73.1808, 44.046],
            [-73.2498, 44.0883],
            [-73.2136, 44.1599],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005880',
        NAME: 'New Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106667348,
        AWATER: 908128,
        INTPTLAT: '+44.1049674',
        INTPTLON: '-073.1657672',
        ELSDLEA: '05880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0522, 45.0064],
            [-71.8976, 45.0084],
            [-71.9177, 44.9436],
            [-71.9212, 44.9321],
            [-72.0658, 44.931],
            [-72.0522, 45.0064],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004710',
        NAME: 'Holland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96706696,
        AWATER: 1908432,
        INTPTLAT: '+44.9721002',
        INTPTLON: '-071.9869694',
        ELSDLEA: '04710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5782, 44.7816],
            [-72.5326, 44.8335],
            [-72.4028, 44.8414],
            [-72.3717, 44.8417],
            [-72.344, 44.8421],
            [-72.3653, 44.7945],
            [-72.4048, 44.7405],
            [-72.4124, 44.7295],
            [-72.4173, 44.7229],
            [-72.5782, 44.7816],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005220',
        NAME: 'Lowell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145028379,
        AWATER: 207782,
        INTPTLAT: '+44.7934371',
        INTPTLON: '-072.4584514',
        ELSDLEA: '05220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2428, 44.9311],
            [-72.1894, 44.9016],
            [-72.1617, 44.886],
            [-72.2013, 44.8353],
            [-72.309, 44.8752],
            [-72.2428, 44.9311],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003360',
        NAME: 'Coventry School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69599108,
        AWATER: 2068450,
        INTPTLAT: '+44.8881264',
        INTPTLON: '-072.2341924',
        ELSDLEA: '03360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0658, 44.931],
            [-71.9212, 44.9321],
            [-71.9104, 44.8952],
            [-71.8744, 44.8764],
            [-71.9202, 44.8369],
            [-72.0674, 44.9144],
            [-72.0658, 44.931],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005760',
        NAME: 'Morgan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80050772,
        AWATER: 7541637,
        INTPTLAT: '+44.8959364',
        INTPTLON: '-071.9412652',
        ELSDLEA: '05760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1968, 44.2666],
            [-73.1451, 44.2734],
            [-73.0806, 44.2823],
            [-73.071, 44.1844],
            [-73.1495, 44.1767],
            [-73.1771, 44.1739],
            [-73.1968, 44.2666],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005640',
        NAME: 'Monkton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92896049,
        AWATER: 971946,
        INTPTLAT: '+44.2270403',
        INTPTLON: '-073.1317208',
        ELSDLEA: '05640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0824, 44.7817],
            [-72.0404, 44.8219],
            [-71.9743, 44.7865],
            [-71.9399, 44.769],
            [-72.0125, 44.6988],
            [-72.1145, 44.7496],
            [-72.0824, 44.7817],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009000',
        NAME: 'Westmore School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88858229,
        AWATER: 8102763,
        INTPTLAT: '+44.7659825',
        INTPTLON: '-071.9972546',
        ELSDLEA: '09000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1201, 44.8647],
            [-72.0674, 44.9144],
            [-71.9202, 44.8369],
            [-71.9743, 44.7865],
            [-72.0404, 44.8219],
            [-72.1201, 44.8647],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003060',
        NAME: 'Charleston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96887945,
        AWATER: 3719553,
        INTPTLAT: '+44.8685434',
        INTPTLON: '-072.0234708',
        ELSDLEA: '03060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4335, 45.0079],
            [-72.3292, 45.0047],
            [-72.3717, 44.8417],
            [-72.4028, 44.8414],
            [-72.4126, 44.9263],
            [-72.4335, 45.0079],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008130',
        NAME: 'Troy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92560110,
        AWATER: 814505,
        INTPTLAT: '+44.9316643',
        INTPTLON: '-072.3849151',
        ELSDLEA: '08130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0806, 44.2823],
            [-73.0204, 44.3037],
            [-72.9652, 44.2303],
            [-72.9522, 44.1613],
            [-73.0171, 44.1571],
            [-73.071, 44.1844],
            [-73.0806, 44.2823],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007740',
        NAME: 'Starksboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118315083,
        AWATER: 496276,
        INTPTLAT: '+44.2103408',
        INTPTLON: '-072.9934047',
        ELSDLEA: '07740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1747, 43.8793],
            [-73.0397, 43.9001],
            [-73.0299, 43.8467],
            [-73.1562, 43.8423],
            [-73.1747, 43.8793],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005100',
        NAME: 'Leicester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53992396,
        AWATER: 2409700,
        INTPTLAT: '+43.8745281',
        INTPTLON: '-073.0997492',
        ELSDLEA: '05100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2557, 43.2791],
            [-73.2546, 43.3147],
            [-73.2508, 43.4087],
            [-73.1209, 43.403],
            [-73.1227, 43.3087],
            [-73.128, 43.2138],
            [-73.2592, 43.2168],
            [-73.2557, 43.2791],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000009',
        NAME: 'Mettawee Community Union School District 47',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225992105,
        AWATER: 635496,
        INTPTLAT: '+43.2914239',
        INTPTLON: '-073.1737602',
        ELSDLEA: '00009',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2707, 43.0176],
            [-73.27, 43.0307],
            [-73.1405, 43.0258],
            [-73.1464, 42.9323],
            [-73.2167, 42.9329],
            [-73.2283, 42.9536],
            [-73.2587, 42.9338],
            [-73.2747, 42.9344],
            [-73.2707, 43.0176],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007230',
        NAME: 'Shaftsbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106241433,
        AWATER: 199338,
        INTPTLAT: '+42.9745678',
        INTPTLON: '-073.1985564',
        ELSDLEA: '07230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.344, 44.8421],
            [-72.309, 44.8752],
            [-72.2013, 44.8353],
            [-72.2006, 44.8258],
            [-72.2214, 44.7973],
            [-72.2533, 44.7529],
            [-72.3653, 44.7945],
            [-72.344, 44.8421],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004860',
        NAME: 'Irasburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104402372,
        AWATER: 687709,
        INTPTLAT: '+44.8192416',
        INTPTLON: '-072.2827405',
        ELSDLEA: '04860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3717, 44.8417],
            [-72.3292, 45.0047],
            [-72.2467, 45.0046],
            [-72.2264, 44.9542],
            [-72.2428, 44.9311],
            [-72.309, 44.8752],
            [-72.344, 44.8421],
            [-72.3717, 44.8417],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006030',
        NAME: 'Newport Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107884634,
        AWATER: 4821758,
        INTPTLAT: '+44.9321187',
        INTPTLON: '-072.3001309',
        ELSDLEA: '06030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5375, 44.2046],
            [-72.5143, 44.187],
            [-72.5032, 44.2114],
            [-72.5331, 44.2157],
            [-72.5198, 44.2411],
            [-72.4804, 44.2338],
            [-72.4197, 44.221],
            [-72.443, 44.1287],
            [-72.456, 44.1331],
            [-72.5597, 44.1629],
            [-72.5375, 44.2046],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002040',
        NAME: 'Barre Town School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79157898,
        AWATER: 395627,
        INTPTLAT: '+44.1830280',
        INTPTLON: '-072.4751549',
        ELSDLEA: '02040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8791, 44.33],
            [-72.805, 44.4517],
            [-72.6544, 44.3982],
            [-72.7166, 44.3125],
            [-72.7894, 44.2397],
            [-72.896, 44.2763],
            [-72.8791, 44.33],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000008',
        NAME: 'Duxbury/Waterbury Union School District 45',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236127472,
        AWATER: 4371311,
        INTPTLAT: '+44.3372202',
        INTPTLON: '-072.7826037',
        ELSDLEA: '00008',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7166, 44.3125],
            [-72.6389, 44.2719],
            [-72.6837, 44.1851],
            [-72.7334, 44.2108],
            [-72.7894, 44.2397],
            [-72.7166, 44.3125],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005730',
        NAME: 'Moretown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103310996,
        AWATER: 840289,
        INTPTLAT: '+44.2507970',
        INTPTLON: '-072.6879242',
        ELSDLEA: '05730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6544, 44.3982],
            [-72.5506, 44.3456],
            [-72.5738, 44.3135],
            [-72.6126, 44.2586],
            [-72.6389, 44.2719],
            [-72.7166, 44.3125],
            [-72.6544, 44.3982],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005550',
        NAME: 'Middlesex School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102497375,
        AWATER: 773111,
        INTPTLAT: '+44.3189986',
        INTPTLON: '-072.6319489',
        ELSDLEA: '05550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6837, 44.1851],
            [-72.6389, 44.2719],
            [-72.6126, 44.2586],
            [-72.5318, 44.2404],
            [-72.5198, 44.2411],
            [-72.5331, 44.2157],
            [-72.5375, 44.2046],
            [-72.5597, 44.1629],
            [-72.5893, 44.1603],
            [-72.6837, 44.1851],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002220',
        NAME: 'Berlin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93949202,
        AWATER: 1734732,
        INTPTLAT: '+44.2045900',
        INTPTLON: '-072.6076120',
        ELSDLEA: '02220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5968, 44.4793],
            [-72.4929, 44.423],
            [-72.5506, 44.3456],
            [-72.6544, 44.3982],
            [-72.5968, 44.4793],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009540',
        NAME: 'Worcester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99910128,
        AWATER: 444454,
        INTPTLAT: '+44.4247095',
        INTPTLON: '-072.5954172',
        ELSDLEA: '09540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8216, 44.0526],
            [-72.7701, 44.1264],
            [-72.6348, 44.0894],
            [-72.6838, 44.0129],
            [-72.7423, 44.0297],
            [-72.8216, 44.0526],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006960',
        NAME: 'Roxbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108732500,
        AWATER: 124100,
        INTPTLAT: '+44.0651077',
        INTPTLON: '-072.7296247',
        ELSDLEA: '06960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5331, 44.2157],
            [-72.5032, 44.2114],
            [-72.5143, 44.187],
            [-72.5375, 44.2046],
            [-72.5331, 44.2157],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002010',
        NAME: 'Barre City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10221889,
        AWATER: 83458,
        INTPTLAT: '+44.1991117',
        INTPTLON: '-072.5079932',
        ELSDLEA: '02010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5506, 44.3456],
            [-72.4929, 44.423],
            [-72.4905, 44.4257],
            [-72.3806, 44.3829],
            [-72.44, 44.3044],
            [-72.5506, 44.3456],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002880',
        NAME: 'Calais School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98278551,
        AWATER: 1600482,
        INTPTLAT: '+44.3616211',
        INTPTLON: '-072.4658304',
        ELSDLEA: '02880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.128, 43.2138],
            [-73.0018, 43.2108],
            [-73.0037, 43.1171],
            [-73.1349, 43.1205],
            [-73.128, 43.2138],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005370',
        NAME: 'Manchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109114023,
        AWATER: 321106,
        INTPTLAT: '+43.1652234',
        INTPTLON: '-073.0630713',
        ELSDLEA: '05370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1227, 43.3087],
            [-72.9963, 43.3053],
            [-72.9762, 43.2916],
            [-72.9785, 43.2096],
            [-73.0018, 43.2108],
            [-73.128, 43.2138],
            [-73.1227, 43.3087],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003510',
        NAME: 'Dorset School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123678107,
        AWATER: 334716,
        INTPTLAT: '+43.2842180',
        INTPTLON: '-073.0320448',
        ELSDLEA: '03510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2893, 42.8041],
            [-73.2783, 42.8376],
            [-73.1532, 42.836],
            [-73.1373, 42.8355],
            [-73.1425, 42.7436],
            [-73.1636, 42.744],
            [-73.265, 42.7459],
            [-73.2893, 42.8041],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006630',
        NAME: 'Pownal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120325363,
        AWATER: 704736,
        INTPTLAT: '+42.7856800',
        INTPTLON: '-073.2102684',
        ELSDLEA: '06630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9221, 44.2222],
            [-72.896, 44.2763],
            [-72.7894, 44.2397],
            [-72.8685, 44.1546],
            [-72.9343, 44.1636],
            [-72.9522, 44.1613],
            [-72.9221, 44.2222],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004020',
        NAME: 'Fayston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94383541,
        AWATER: 23644,
        INTPTLAT: '+44.2204066',
        INTPTLON: '-072.8602696',
        ELSDLEA: '04020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1405, 43.0259],
            [-73.1349, 43.1205],
            [-73.0037, 43.1171],
            [-72.9971, 43.117],
            [-73.0028, 43.023],
            [-73.1405, 43.0259],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007920',
        NAME: 'Sunderland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117417394,
        AWATER: 814767,
        INTPTLAT: '+43.0820470',
        INTPTLON: '-073.0667660',
        ELSDLEA: '07920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8685, 44.1546],
            [-72.7894, 44.2397],
            [-72.7334, 44.2108],
            [-72.7823, 44.1298],
            [-72.8685, 44.1546],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008400',
        NAME: 'Waitsfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69197563,
        AWATER: 470803,
        INTPTLAT: '+44.1805737',
        INTPTLON: '-072.7937244',
        ELSDLEA: '08400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4905, 44.4257],
            [-72.4343, 44.5061],
            [-72.3213, 44.4626],
            [-72.3806, 44.3829],
            [-72.4905, 44.4257],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009420',
        NAME: 'Woodbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97147953,
        AWATER: 4110173,
        INTPTLAT: '+44.4580811',
        INTPTLON: '-072.3791338',
        ELSDLEA: '09420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5506, 44.3456],
            [-72.44, 44.3044],
            [-72.4804, 44.2338],
            [-72.5198, 44.2411],
            [-72.5318, 44.2404],
            [-72.5738, 44.3135],
            [-72.5506, 44.3456],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003690',
        NAME: 'East Montpelier School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82532087,
        AWATER: 650215,
        INTPTLAT: '+44.2961230',
        INTPTLON: '-072.5069208',
        ELSDLEA: '03690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9343, 44.1636],
            [-72.8685, 44.1546],
            [-72.7823, 44.1298],
            [-72.7701, 44.1264],
            [-72.8216, 44.0526],
            [-72.909, 44.0726],
            [-72.9343, 44.1636],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008550',
        NAME: 'Warren School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103409229,
        AWATER: 128835,
        INTPTLAT: '+44.1125264',
        INTPTLON: '-072.8740304',
        ELSDLEA: '08550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2587, 42.9338],
            [-73.2283, 42.9536],
            [-73.2167, 42.9329],
            [-73.2587, 42.9338],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006060',
        NAME: 'North Bennington Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10176254,
        AWATER: 106636,
        INTPTLAT: '+42.9327561',
        INTPTLON: '-073.2382478',
        ELSDLEA: '06060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2747, 42.9344],
            [-73.2587, 42.9338],
            [-73.2167, 42.9329],
            [-73.1464, 42.9323],
            [-73.1532, 42.836],
            [-73.2783, 42.8376],
            [-73.2747, 42.9344],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002130',
        NAME: 'Bennington Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104601490,
        AWATER: 584724,
        INTPTLAT: '+42.8823399',
        INTPTLON: '-073.2110929',
        ELSDLEA: '02130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1464, 42.9323],
            [-73.0081, 42.9301],
            [-73.0119, 42.8591],
            [-73.0151, 42.8321],
            [-73.1373, 42.8355],
            [-73.1532, 42.836],
            [-73.1464, 42.9323],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009450',
        NAME: 'Woodford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122822946,
        AWATER: 436542,
        INTPTLAT: '+42.9082823',
        INTPTLON: '-073.0783654',
        ELSDLEA: '09450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0018, 43.2108],
            [-72.9785, 43.2096],
            [-72.8649, 43.1662],
            [-72.8687, 43.1132],
            [-72.9971, 43.117],
            [-73.0037, 43.1171],
            [-73.0018, 43.2108],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009330',
        NAME: 'Winhall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112776484,
        AWATER: 1226764,
        INTPTLAT: '+43.1689742',
        INTPTLON: '-072.9723630',
        ELSDLEA: '09330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3375, 43.8097],
            [-72.2052, 43.771],
            [-72.3051, 43.6926],
            [-72.3051, 43.6924],
            [-72.4234, 43.7265],
            [-72.3375, 43.8097],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006180',
        NAME: 'Norwich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115141854,
        AWATER: 577517,
        INTPTLAT: '+43.7776276',
        INTPTLON: '-072.3239835',
        ELSDLEA: '06180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.775, 43.5794],
            [-72.7728, 43.5916],
            [-72.758, 43.5875],
            [-72.6475, 43.5612],
            [-72.6606, 43.457],
            [-72.6611, 43.4538],
            [-72.7272, 43.4687],
            [-72.7715, 43.4779],
            [-72.775, 43.5794],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006540',
        NAME: 'Plymouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124627489,
        AWATER: 1449320,
        INTPTLAT: '+43.5088509',
        INTPTLON: '-072.6937549',
        ELSDLEA: '06540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7272, 43.4687],
            [-72.6611, 43.4538],
            [-72.6613, 43.3508],
            [-72.7598, 43.3541],
            [-72.7272, 43.4687],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005250',
        NAME: 'Ludlow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91111059,
        AWATER: 1352158,
        INTPTLAT: '+43.3953158',
        INTPTLON: '-072.7038222',
        ELSDLEA: '05250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5288, 43.7566],
            [-72.4601, 43.8383],
            [-72.3375, 43.8097],
            [-72.4234, 43.7265],
            [-72.4264, 43.7218],
            [-72.5288, 43.7566],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007260',
        NAME: 'Sharon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102721453,
        AWATER: 1300659,
        INTPTLAT: '+43.7875746',
        INTPTLON: '-072.4351726',
        ELSDLEA: '07260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7828, 43.8043],
            [-72.766, 43.8232],
            [-72.6619, 43.795],
            [-72.6944, 43.6941],
            [-72.7009, 43.6828],
            [-72.82, 43.7168],
            [-72.7828, 43.8043],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007770',
        NAME: 'Stockbridge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118873649,
        AWATER: 708864,
        INTPTLAT: '+43.7507975',
        INTPTLON: '-072.7609138',
        ELSDLEA: '07770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6475, 43.5612],
            [-72.6379, 43.5588],
            [-72.5329, 43.5341],
            [-72.5302, 43.4441],
            [-72.6606, 43.457],
            [-72.6475, 43.5612],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006750',
        NAME: 'Reading School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107409108,
        AWATER: 472287,
        INTPTLAT: '+43.4964932',
        INTPTLON: '-072.5999690',
        ELSDLEA: '06750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5869, 43.6646],
            [-72.5338, 43.7581],
            [-72.5288, 43.7566],
            [-72.4264, 43.7218],
            [-72.4781, 43.6392],
            [-72.5777, 43.6594],
            [-72.5869, 43.6646],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006570',
        NAME: 'Pomfret School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102022151,
        AWATER: 201630,
        INTPTLAT: '+43.6996064',
        INTPTLON: '-072.5117644',
        ELSDLEA: '06570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5284, 43.5333],
            [-72.4664, 43.6311],
            [-72.3282, 43.6063],
            [-72.3296, 43.6002],
            [-72.3961, 43.5199],
            [-72.4505, 43.5254],
            [-72.5284, 43.5333],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004620',
        NAME: 'Hartland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116255512,
        AWATER: 810066,
        INTPTLAT: '+43.5805108',
        INTPTLON: '-072.4389109',
        ELSDLEA: '04620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5329, 43.5341],
            [-72.5284, 43.5333],
            [-72.4505, 43.5254],
            [-72.4551, 43.4355],
            [-72.5302, 43.4441],
            [-72.5329, 43.5341],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008880',
        NAME: 'West Windsor School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63802817,
        AWATER: 235773,
        INTPTLAT: '+43.4714681',
        INTPTLON: '-072.5026184',
        ELSDLEA: '08880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7598, 43.3541],
            [-72.6613, 43.3508],
            [-72.607, 43.3481],
            [-72.5454, 43.3452],
            [-72.5499, 43.2358],
            [-72.5559, 43.2354],
            [-72.6772, 43.2233],
            [-72.7303, 43.2332],
            [-72.7598, 43.3541],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008245',
        NAME: 'Chester-Andover Union School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218596006,
        AWATER: 817739,
        INTPTLAT: '+43.3056461',
        INTPTLON: '-072.6421577',
        ELSDLEA: '08245',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5777, 43.6594],
            [-72.4781, 43.6392],
            [-72.4664, 43.6311],
            [-72.5284, 43.5333],
            [-72.5329, 43.5341],
            [-72.6379, 43.5588],
            [-72.5777, 43.6594],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009480',
        NAME: 'Woodstock School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114881619,
        AWATER: 731108,
        INTPTLAT: '+43.5943771',
        INTPTLON: '-072.5482791',
        ELSDLEA: '09480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1919, 45.0136],
            [-73.1701, 45.014],
            [-73.1573, 44.9478],
            [-73.0555, 44.9105],
            [-73.0022, 44.8805],
            [-72.9944, 44.8159],
            [-73.194, 44.8868],
            [-73.2308, 44.9274],
            [-73.1919, 45.0136],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007980',
        NAME: 'Swanton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124398147,
        AWATER: 35313880,
        INTPTLAT: '+44.9096192',
        INTPTLON: '-073.1238620',
        ELSDLEA: '07980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7368, 44.6662],
            [-71.6623, 44.6267],
            [-71.6321, 44.6114],
            [-71.7071, 44.5388],
            [-71.8085, 44.5945],
            [-71.7368, 44.6662],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004260',
        NAME: 'Granby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101110876,
        AWATER: 240233,
        INTPTLAT: '+44.5985853',
        INTPTLON: '-071.7470992',
        ELSDLEA: '04260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6611, 43.4538],
            [-72.6606, 43.457],
            [-72.5302, 43.4441],
            [-72.5388, 43.381],
            [-72.607, 43.3481],
            [-72.6613, 43.3508],
            [-72.6611, 43.4538],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003010',
        NAME: 'Cavendish School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102238575,
        AWATER: 603193,
        INTPTLAT: '+43.3981554',
        INTPTLON: '-072.5996466',
        ELSDLEA: '03010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6619, 43.795],
            [-72.6452, 43.7905],
            [-72.5338, 43.7581],
            [-72.5869, 43.6646],
            [-72.6944, 43.6941],
            [-72.6619, 43.795],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001950',
        NAME: 'Barnard School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126001612,
        AWATER: 615008,
        INTPTLAT: '+43.6987923',
        INTPTLON: '-072.6338507',
        ELSDLEA: '01950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.758, 43.5875],
            [-72.7009, 43.6828],
            [-72.6944, 43.6941],
            [-72.5869, 43.6646],
            [-72.5777, 43.6594],
            [-72.6379, 43.5588],
            [-72.6475, 43.5612],
            [-72.758, 43.5875],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002580',
        NAME: 'Bridgewater School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127964163,
        AWATER: 352586,
        INTPTLAT: '+43.6404310',
        INTPTLON: '-072.6898013',
        ELSDLEA: '02580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9743, 44.7865],
            [-71.9202, 44.8369],
            [-71.8744, 44.8764],
            [-71.8585, 44.8684],
            [-71.8329, 44.8546],
            [-71.7658, 44.819],
            [-71.8584, 44.7277],
            [-71.9399, 44.769],
            [-71.9743, 44.7865],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002610',
        NAME: 'Brighton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134309645,
        AWATER: 3817579,
        INTPTLAT: '+44.8024831',
        INTPTLON: '-071.8753956',
        ELSDLEA: '02610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.766, 43.8232],
            [-72.7078, 43.9104],
            [-72.7035, 43.9093],
            [-72.5744, 43.8733],
            [-72.6452, 43.7905],
            [-72.6619, 43.795],
            [-72.766, 43.8232],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002250',
        NAME: 'Bethel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117013500,
        AWATER: 684747,
        INTPTLAT: '+43.8641620',
        INTPTLON: '-072.6951076',
        ELSDLEA: '02250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9652, 44.2303],
            [-72.9221, 44.2222],
            [-72.9522, 44.1613],
            [-72.9652, 44.2303],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000011',
        NAME: 'Buels Gore School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12848249,
        AWATER: 10488,
        INTPTLAT: '+44.1984222',
        INTPTLON: '-072.9477429',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8584, 44.7277],
            [-71.7658, 44.819],
            [-71.7417, 44.8063],
            [-71.7035, 44.7189],
            [-71.6623, 44.6267],
            [-71.7368, 44.6662],
            [-71.8373, 44.7166],
            [-71.8584, 44.7277],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000012',
        NAME: 'Ferdinand School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136242836,
        AWATER: 586469,
        INTPTLAT: '+44.7278700',
        INTPTLON: '-071.7874171',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1405, 43.0258],
            [-73.1405, 43.0259],
            [-73.0028, 43.023],
            [-73.0028, 43.0229],
            [-73.0081, 42.9301],
            [-73.1464, 42.9323],
            [-73.1405, 43.0258],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000013',
        NAME: 'Glastenbury School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115109843,
        AWATER: 14011,
        INTPTLAT: '+42.9737500',
        INTPTLON: '-073.0752397',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0028, 43.0229],
            [-72.9182, 42.9906],
            [-72.9214, 42.9268],
            [-73.0081, 42.9301],
            [-73.0028, 43.0229],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000014',
        NAME: 'Somerset School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67111537,
        AWATER: 5755443,
        INTPTLAT: '+42.9913460',
        INTPTLON: '-072.9716810',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7669, 44.933],
            [-71.7543, 44.9472],
            [-71.701, 45.0082],
            [-71.5988, 44.9535],
            [-71.6635, 44.8792],
            [-71.7669, 44.933],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000015',
        NAME: 'Averill School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93273472,
        AWATER: 5323165,
        INTPTLAT: '+44.9408097',
        INTPTLON: '-071.6547828',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8458, 44.9461],
            [-71.7543, 44.9472],
            [-71.7669, 44.933],
            [-71.8329, 44.8546],
            [-71.8585, 44.8684],
            [-71.8458, 44.9461],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000016',
        NAME: "Avery's Gore School District",
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45434152,
        AWATER: 85001,
        INTPTLAT: '+44.9111607',
        INTPTLON: '-071.8186969',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8329, 44.8546],
            [-71.7669, 44.933],
            [-71.6635, 44.8792],
            [-71.7305, 44.8005],
            [-71.7417, 44.8063],
            [-71.7658, 44.819],
            [-71.8329, 44.8546],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000017',
        NAME: 'Lewis School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102380290,
        AWATER: 332299,
        INTPTLAT: '+44.8703006',
        INTPTLON: '-071.7370282',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9212, 44.9321],
            [-71.9177, 44.9436],
            [-71.8959, 44.9445],
            [-71.9104, 44.8952],
            [-71.9212, 44.9321],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000018',
        NAME: "Warner's Grant School District",
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8145560,
        AWATER: 0,
        INTPTLAT: '+44.9223119',
        INTPTLON: '-071.9126024',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8959, 44.9445],
            [-71.8458, 44.9461],
            [-71.8585, 44.8684],
            [-71.8744, 44.8764],
            [-71.9104, 44.8952],
            [-71.8959, 44.9445],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000019',
        NAME: "Warren'S Gore School Disrrict",
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27903002,
        AWATER: 2137516,
        INTPTLAT: '+44.9121991',
        INTPTLON: '-071.8761221',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3828, 44.0085],
            [-72.3822, 44.0082],
            [-72.2327, 43.9927],
            [-72.099, 43.9575],
            [-72.1683, 43.8723],
            [-72.1675, 43.871],
            [-72.2728, 43.8994],
            [-72.3949, 43.9291],
            [-72.3828, 44.0085],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000024',
        NAME: 'Rivendell Interstate School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'C',
        FUNCSTAT: 'E',
        ALAND: 205364725,
        AWATER: 3623880,
        INTPTLAT: '+43.9385994',
        INTPTLON: '-072.2468459',
        ELSDLEA: '',
        UNSDLEA: '00024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9762, 43.2916],
            [-72.8679, 43.3007],
            [-72.8534, 43.3711],
            [-72.7598, 43.3541],
            [-72.7303, 43.2332],
            [-72.7745, 43.1418],
            [-72.8649, 43.1662],
            [-72.9785, 43.2096],
            [-72.9762, 43.2916],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5000386',
        NAME: 'Mountain Towns RED School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302266328,
        AWATER: 2238079,
        INTPTLAT: '+43.2545347',
        INTPTLON: '-072.8357459',
        ELSDLEA: '',
        UNSDLEA: '00386',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2641, 43.1234],
            [-73.1349, 43.1205],
            [-73.1405, 43.0259],
            [-73.1405, 43.0258],
            [-73.27, 43.0307],
            [-73.2641, 43.1234],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001830',
        NAME: 'Arlington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109389160,
        AWATER: 491718,
        INTPTLAT: '+43.0625213',
        INTPTLON: '-073.2223049',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5388, 43.381],
            [-72.5454, 43.3452],
            [-72.607, 43.3481],
            [-72.5388, 43.381],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5001920',
        NAME: 'Baltimore School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12042065,
        AWATER: 23776,
        INTPTLAT: '+43.3556185',
        INTPTLON: '-072.5593989',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0168, 43.805],
            [-72.9589, 43.8265],
            [-72.8734, 43.8396],
            [-72.8372, 43.7199],
            [-72.8586, 43.6949],
            [-72.9396, 43.6654],
            [-72.94, 43.6692],
            [-73.0027, 43.7568],
            [-73.0168, 43.805],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002060',
        NAME: 'Chittenden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187979485,
        AWATER: 3171259,
        INTPTLAT: '+43.7731815',
        INTPTLON: '-072.9058606',
        ELSDLEA: '',
        UNSDLEA: '02060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7305, 44.8005],
            [-71.6635, 44.8792],
            [-71.561, 44.8247],
            [-71.6022, 44.7704],
            [-71.632, 44.75],
            [-71.7305, 44.8005],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002280',
        NAME: 'Bloomfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104377133,
        AWATER: 615404,
        INTPTLAT: '+44.8182102',
        INTPTLON: '-071.6287096',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7305, 44.8005],
            [-71.632, 44.75],
            [-71.5867, 44.6617],
            [-71.7035, 44.7189],
            [-71.7417, 44.8063],
            [-71.7305, 44.8005],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002760',
        NAME: 'Brunswick School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65937071,
        AWATER: 1369743,
        INTPTLAT: '+44.7198109',
        INTPTLON: '-071.6728385',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2759, 44.5298],
            [-73.202, 44.4925],
            [-73.1764, 44.4848],
            [-73.2315, 44.4472],
            [-73.2759, 44.5298],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002820',
        NAME: 'Burlington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26622104,
        AWATER: 13452210,
        INTPTLAT: '+44.4919270',
        INTPTLON: '-073.2388823',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3213, 44.4626],
            [-72.3192, 44.4618],
            [-72.2237, 44.4246],
            [-72.2281, 44.3963],
            [-72.2698, 44.3402],
            [-72.3806, 44.3829],
            [-72.3213, 44.4626],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002850',
        NAME: 'Cabot School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96173412,
        AWATER: 3297872,
        INTPTLAT: '+44.3849795',
        INTPTLON: '-072.2894812',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.701, 45.0082],
            [-71.6981, 45.0114],
            [-71.5011, 45.0133],
            [-71.465, 45.0136],
            [-71.4885, 45.0007],
            [-71.515, 44.9431],
            [-71.4959, 44.9091],
            [-71.5988, 44.9535],
            [-71.701, 45.0082],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5002940',
        NAME: 'Canaan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85498353,
        AWATER: 1072053,
        INTPTLAT: '+44.9913560',
        INTPTLON: '-071.5980257',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5381, 44.0624],
            [-72.5021, 44.0503],
            [-72.3828, 44.0085],
            [-72.3949, 43.9291],
            [-72.5099, 43.9661],
            [-72.5381, 44.0624],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003120',
        NAME: 'Chelsea School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103059263,
        AWATER: 124611,
        INTPTLAT: '+43.9897471',
        INTPTLON: '-072.4549526',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3615, 44.5636],
            [-73.2545, 44.6079],
            [-73.0973, 44.5705],
            [-73.1574, 44.4901],
            [-73.1709, 44.489],
            [-73.202, 44.4925],
            [-73.2759, 44.5298],
            [-73.3212, 44.5203],
            [-73.3386, 44.5468],
            [-73.3615, 44.5636],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003240',
        NAME: 'Colchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94022661,
        AWATER: 57589394,
        INTPTLAT: '+44.5463972',
        INTPTLON: '-073.2204930',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8566, 44.4962],
            [-71.7958, 44.5051],
            [-71.7384, 44.408],
            [-71.7647, 44.4066],
            [-71.8377, 44.3478],
            [-71.9333, 44.4412],
            [-71.8566, 44.4962],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003270',
        NAME: 'Concord School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132936356,
        AWATER: 5676490,
        INTPTLAT: '+44.4390845',
        INTPTLON: '-071.8261040',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4823, 44.6339],
            [-72.4767, 44.6418],
            [-72.4231, 44.7152],
            [-72.3098, 44.673],
            [-72.3153, 44.6652],
            [-72.3682, 44.5923],
            [-72.4823, 44.6339],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003390',
        NAME: 'Craftsbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101531942,
        AWATER: 1452095,
        INTPTLAT: '+44.6497848',
        INTPTLON: '-072.3877684',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2237, 44.4246],
            [-72.152, 44.5012],
            [-72.1378, 44.5186],
            [-72.0839, 44.5104],
            [-72.0455, 44.3984],
            [-72.0946, 44.3574],
            [-72.2281, 44.3963],
            [-72.2237, 44.4246],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003450',
        NAME: 'Danville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157295542,
        AWATER: 1061586,
        INTPTLAT: '+44.4375039',
        INTPTLON: '-072.1248218',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8373, 44.7166],
            [-71.7368, 44.6662],
            [-71.8085, 44.5945],
            [-71.8427, 44.6112],
            [-71.9103, 44.647],
            [-71.8373, 44.7166],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003660',
        NAME: 'East Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96771921,
        AWATER: 119861,
        INTPTLAT: '+44.6638095',
        INTPTLON: '-071.8052959',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8384, 44.8585],
            [-72.8219, 44.9199],
            [-72.8223, 44.9254],
            [-72.6781, 44.9289],
            [-72.6723, 44.922],
            [-72.6784, 44.8376],
            [-72.841, 44.8363],
            [-72.8384, 44.8585],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003780',
        NAME: 'Enosburgh School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124949751,
        AWATER: 1262101,
        INTPTLAT: '+44.8778150',
        INTPTLON: '-072.7507619',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0848, 44.7729],
            [-73.0045, 44.7604],
            [-72.9227, 44.6498],
            [-72.9241, 44.6331],
            [-73.038, 44.6512],
            [-73.0848, 44.7729],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5003930',
        NAME: 'Fairfax School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103219967,
        AWATER: 1503226,
        INTPTLAT: '+44.7023790',
        INTPTLON: '-072.9977471',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9081, 44.0355],
            [-72.909, 44.0726],
            [-72.8216, 44.0526],
            [-72.7423, 44.0297],
            [-72.7913, 43.9619],
            [-72.8163, 43.9378],
            [-72.9122, 43.9656],
            [-72.9081, 44.0355],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004320',
        NAME: 'Granville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133150163,
        AWATER: 253803,
        INTPTLAT: '+44.0052914',
        INTPTLON: '-072.8413144',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9797, 43.9224],
            [-72.9122, 43.9656],
            [-72.8163, 43.9378],
            [-72.9802, 43.8826],
            [-72.9797, 43.9224],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004530',
        NAME: 'Hancock School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98483196,
        AWATER: 278673,
        INTPTLAT: '+43.9053249',
        INTPTLON: '-072.9181463',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4264, 43.7218],
            [-72.4234, 43.7265],
            [-72.3051, 43.6924],
            [-72.3282, 43.6063],
            [-72.4664, 43.6311],
            [-72.4781, 43.6392],
            [-72.4264, 43.7218],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004590',
        NAME: 'Hartford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116548660,
        AWATER: 2286971,
        INTPTLAT: '+43.6646844',
        INTPTLON: '-072.3861377',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1154, 43.5847],
            [-73.0957, 43.6325],
            [-73.0349, 43.5707],
            [-73.0508, 43.4939],
            [-73.087, 43.4955],
            [-73.1202, 43.5358],
            [-73.1154, 43.5847],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5004830',
        NAME: 'Ira School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58994407,
        AWATER: 17522,
        INTPTLAT: '+43.5685618',
        INTPTLON: '-073.0974179',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9582, 44.4914],
            [-71.9343, 44.5686],
            [-71.8981, 44.5579],
            [-71.8566, 44.4962],
            [-71.9333, 44.4412],
            [-71.9582, 44.4914],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005040',
        NAME: 'Kirby School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63114265,
        AWATER: 128112,
        INTPTLAT: '+44.5099545',
        INTPTLON: '-071.9293260',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6635, 44.8792],
            [-71.5988, 44.9535],
            [-71.4959, 44.9091],
            [-71.5227, 44.8796],
            [-71.561, 44.8247],
            [-71.6635, 44.8792],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005130',
        NAME: 'Lemington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91625090,
        AWATER: 372729,
        INTPTLAT: '+44.8820056',
        INTPTLON: '-071.5802905',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7035, 44.7189],
            [-71.5867, 44.6617],
            [-71.5578, 44.6421],
            [-71.5563, 44.5699],
            [-71.6321, 44.6114],
            [-71.6623, 44.6267],
            [-71.7035, 44.7189],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005340',
        NAME: 'Maidstone School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79124546,
        AWATER: 4508257,
        INTPTLAT: '+44.6474223',
        INTPTLON: '-071.6322114',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9396, 43.6654],
            [-72.8586, 43.6949],
            [-72.8687, 43.618],
            [-72.7728, 43.5916],
            [-72.775, 43.5794],
            [-72.9338, 43.5747],
            [-72.9396, 43.6654],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005460',
        NAME: 'Mendon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98566173,
        AWATER: 93306,
        INTPTLAT: '+43.6245775',
        INTPTLON: '-072.9102039',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2281, 44.6882],
            [-73.2289, 44.7229],
            [-73.038, 44.6512],
            [-73.0973, 44.5705],
            [-73.2545, 44.6079],
            [-73.2281, 44.6882],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005610',
        NAME: 'Milton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133074356,
        AWATER: 24641038,
        INTPTLAT: '+44.6459261',
        INTPTLON: '-073.1592036',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5738, 44.3135],
            [-72.5318, 44.2404],
            [-72.6126, 44.2586],
            [-72.5738, 44.3135],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005700',
        NAME: 'Montpelier School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26043274,
        AWATER: 501591,
        INTPTLAT: '+44.2664485',
        INTPTLON: '-072.5715237',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7475, 44.5948],
            [-72.6505, 44.6036],
            [-72.5247, 44.5733],
            [-72.5398, 44.5534],
            [-72.5968, 44.4793],
            [-72.7682, 44.563],
            [-72.7475, 44.5948],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5005790',
        NAME: 'Morristown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132483188,
        AWATER: 1364914,
        INTPTLAT: '+44.5469642',
        INTPTLON: '-072.6422000',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7823, 44.1298],
            [-72.7334, 44.2108],
            [-72.6837, 44.1851],
            [-72.5893, 44.1603],
            [-72.6289, 44.0886],
            [-72.6348, 44.0894],
            [-72.7701, 44.1264],
            [-72.7823, 44.1298],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006120',
        NAME: 'Northfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112666972,
        AWATER: 332615,
        INTPTLAT: '+44.1476130',
        INTPTLON: '-072.6912748',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8734, 43.8396],
            [-72.7828, 43.8043],
            [-72.82, 43.7168],
            [-72.8372, 43.7199],
            [-72.8734, 43.8396],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006450',
        NAME: 'Pittsfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52811356,
        AWATER: 169830,
        INTPTLAT: '+43.8040809',
        INTPTLON: '-072.8369731',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2456, 43.5395],
            [-73.2432, 43.5875],
            [-73.1154, 43.5847],
            [-73.1202, 43.5358],
            [-73.1797, 43.4659],
            [-73.2488, 43.4618],
            [-73.2456, 43.5395],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006600',
        NAME: 'Poultney School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111128603,
        AWATER: 2726150,
        INTPTLAT: '+43.5399354',
        INTPTLON: '-073.1891929',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0135, 43.6663],
            [-73.0321, 43.6108],
            [-73.0553, 43.6649],
            [-73.0135, 43.6663],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006660',
        NAME: 'Proctor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19249458,
        AWATER: 429471,
        INTPTLAT: '+43.6461080',
        INTPTLON: '-073.0360660',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0151, 42.8321],
            [-73.0119, 42.8591],
            [-72.9248, 42.8562],
            [-72.926, 42.8299],
            [-72.9303, 42.7393],
            [-73.0186, 42.7411],
            [-73.0151, 42.8321],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006780',
        NAME: 'Readsboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94088475,
        AWATER: 386495,
        INTPTLAT: '+42.7909426',
        INTPTLON: '-072.9726397',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7026, 45.0156],
            [-72.5544, 45.0083],
            [-72.5357, 44.9258],
            [-72.5387, 44.9256],
            [-72.6723, 44.922],
            [-72.6781, 44.9289],
            [-72.7026, 45.0156],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006810',
        NAME: 'Richford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111428012,
        AWATER: 687173,
        INTPTLAT: '+44.9485816',
        INTPTLON: '-072.5990709',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9802, 43.8826],
            [-72.8163, 43.9378],
            [-72.7913, 43.9619],
            [-72.7078, 43.9104],
            [-72.766, 43.8232],
            [-72.7828, 43.8043],
            [-72.8734, 43.8396],
            [-72.9589, 43.8265],
            [-72.9802, 43.8826],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006900',
        NAME: 'Rochester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147817737,
        AWATER: 783854,
        INTPTLAT: '+43.8794083',
        INTPTLON: '-072.8316848',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5744, 43.8733],
            [-72.4601, 43.8383],
            [-72.5288, 43.7566],
            [-72.5338, 43.7581],
            [-72.6452, 43.7905],
            [-72.5744, 43.8733],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5006990',
        NAME: 'Royalton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104526874,
        AWATER: 1487947,
        INTPTLAT: '+43.8284169',
        INTPTLON: '-072.5583609',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0108, 43.6055],
            [-72.9477, 43.6219],
            [-72.9461, 43.5939],
            [-73.0108, 43.6055],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007050',
        NAME: 'Rutland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19572204,
        AWATER: 326167,
        INTPTLAT: '+43.6096702',
        INTPTLON: '-072.9777884',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2616, 43.1695],
            [-73.2592, 43.2168],
            [-73.128, 43.2138],
            [-73.1349, 43.1205],
            [-73.2641, 43.1234],
            [-73.2616, 43.1695],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007170',
        NAME: 'Sandgate School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109132085,
        AWATER: 171503,
        INTPTLAT: '+43.1847877',
        INTPTLON: '-073.1802673',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0081, 42.9301],
            [-72.9214, 42.9268],
            [-72.9248, 42.8562],
            [-73.0119, 42.8591],
            [-73.0081, 42.9301],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007200',
        NAME: 'Searsburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55541319,
        AWATER: 327869,
        INTPTLAT: '+42.8902966',
        INTPTLON: '-072.9634911',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3212, 44.5203],
            [-73.2759, 44.5298],
            [-73.2315, 44.4472],
            [-73.1764, 44.4848],
            [-73.1709, 44.489],
            [-73.1574, 44.4901],
            [-73.1371, 44.5009],
            [-73.1359, 44.475],
            [-73.1545, 44.4054],
            [-73.2938, 44.4382],
            [-73.2994, 44.4736],
            [-73.3212, 44.5203],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007470',
        NAME: 'South Burlington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42848909,
        AWATER: 33907763,
        INTPTLAT: '+44.4364688',
        INTPTLON: '-073.1826228',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5454, 43.3452],
            [-72.4065, 43.3378],
            [-72.4069, 43.3374],
            [-72.435, 43.2333],
            [-72.5499, 43.2358],
            [-72.5454, 43.3452],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007530',
        NAME: 'Springfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126963280,
        AWATER: 1093939,
        INTPTLAT: '+43.2838145',
        INTPTLON: '-072.4828448',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1416, 44.4014],
            [-73.0893, 44.3695],
            [-73.1326, 44.3645],
            [-73.1416, 44.4014],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007620',
        NAME: 'St. George School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9419300,
        AWATER: 8797,
        INTPTLAT: '+44.3756415',
        INTPTLON: '-073.1119798',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1373, 42.8355],
            [-73.0151, 42.8321],
            [-73.0186, 42.7411],
            [-73.0229, 42.7411],
            [-73.0485, 42.7415],
            [-73.1425, 42.7436],
            [-73.1373, 42.8355],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007680',
        NAME: 'Stamford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102314648,
        AWATER: 300271,
        INTPTLAT: '+42.8080470',
        INTPTLON: '-073.0762766',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8093, 44.5479],
            [-72.7682, 44.563],
            [-72.5968, 44.4793],
            [-72.6544, 44.3982],
            [-72.805, 44.4517],
            [-72.8386, 44.4592],
            [-72.8093, 44.5479],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007800',
        NAME: 'Stowe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188030057,
        AWATER: 424432,
        INTPTLAT: '+44.4918064',
        INTPTLON: '-072.7126105',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3949, 43.9291],
            [-72.2728, 43.8994],
            [-72.3375, 43.8097],
            [-72.4601, 43.8383],
            [-72.3949, 43.9291],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007830',
        NAME: 'Strafford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114312820,
        AWATER: 470370,
        INTPTLAT: '+43.8720045',
        INTPTLON: '-072.3625325',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0028, 43.023],
            [-72.9971, 43.117],
            [-72.8687, 43.1132],
            [-72.8725, 43.0535],
            [-72.8796, 42.9903],
            [-72.9182, 42.9906],
            [-73.0028, 43.0229],
            [-73.0028, 43.023],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5007860',
        NAME: 'Stratton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119371244,
        AWATER: 2061555,
        INTPTLAT: '+43.0636568',
        INTPTLON: '-072.9184031',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3176, 44.2984],
            [-72.1878, 44.2769],
            [-72.059, 44.2557],
            [-72.0526, 44.218],
            [-72.0391, 44.1554],
            [-72.042, 44.1429],
            [-72.1703, 44.1921],
            [-72.305, 44.1831],
            [-72.3684, 44.2041],
            [-72.3176, 44.2984],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008224',
        NAME: 'Blue Mountain Union School District 21',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238871719,
        AWATER: 3973237,
        INTPTLAT: '+44.2377373',
        INTPTLON: '-072.1965936',
        ELSDLEA: '',
        UNSDLEA: '08224',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.44, 44.3044],
            [-72.3806, 44.3829],
            [-72.2698, 44.3402],
            [-72.3176, 44.2984],
            [-72.3684, 44.2041],
            [-72.4197, 44.221],
            [-72.4804, 44.2338],
            [-72.44, 44.3044],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008225',
        NAME: 'Twinfield Union School District 33',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165679572,
        AWATER: 1304306,
        INTPTLAT: '+44.2959501',
        INTPTLON: '-072.3462277',
        ELSDLEA: '',
        UNSDLEA: '08225',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3481, 44.0961],
            [-72.305, 44.1831],
            [-72.1703, 44.1921],
            [-72.1916, 44.0616],
            [-72.2327, 43.9927],
            [-72.3822, 44.0082],
            [-72.3481, 44.0961],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008242',
        NAME: 'Waits River Valley Union School District 36',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252136699,
        AWATER: 529128,
        INTPTLAT: '+44.0842116',
        INTPTLON: '-072.2702364',
        ELSDLEA: '',
        UNSDLEA: '08242',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8981, 44.5579],
            [-71.8427, 44.6112],
            [-71.8085, 44.5945],
            [-71.7071, 44.5388],
            [-71.7958, 44.5051],
            [-71.8566, 44.4962],
            [-71.8981, 44.5579],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008370',
        NAME: 'Victory School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111051669,
        AWATER: 328347,
        INTPTLAT: '+44.5587869',
        INTPTLON: '-071.8169167',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9333, 44.4412],
            [-71.8377, 44.3478],
            [-71.9846, 44.3362],
            [-72.0357, 44.3883],
            [-71.9333, 44.4412],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008640',
        NAME: 'Waterford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99237401,
        AWATER: 3690025,
        INTPTLAT: '+44.3628571',
        INTPTLON: '-071.9342681',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5388, 43.381],
            [-72.5302, 43.4441],
            [-72.4551, 43.4355],
            [-72.3964, 43.4289],
            [-72.4065, 43.3378],
            [-72.5454, 43.3452],
            [-72.5388, 43.381],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008700',
        NAME: 'Weathersfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113022374,
        AWATER: 1453573,
        INTPTLAT: '+43.3921094',
        INTPTLON: '-072.4767836',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0553, 43.6649],
            [-73.0321, 43.6108],
            [-73.0122, 43.5714],
            [-73.0349, 43.5707],
            [-73.0957, 43.6325],
            [-73.1046, 43.6628],
            [-73.0553, 43.6649],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5008850',
        NAME: 'West Rutland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46436052,
        AWATER: 139316,
        INTPTLAT: '+43.6221222',
        INTPTLON: '-073.0627288',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6289, 44.0886],
            [-72.5893, 44.1603],
            [-72.5597, 44.1629],
            [-72.456, 44.1331],
            [-72.5021, 44.0503],
            [-72.5381, 44.0624],
            [-72.6289, 44.0886],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009180',
        NAME: 'Williamstown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104254063,
        AWATER: 521654,
        INTPTLAT: '+44.1109048',
        INTPTLON: '-072.5415256',
        ELSDLEA: '',
        UNSDLEA: '09180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4505, 43.5254],
            [-72.3961, 43.5199],
            [-72.3964, 43.5192],
            [-72.3964, 43.4289],
            [-72.4551, 43.4355],
            [-72.4505, 43.5254],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009300',
        NAME: 'Windsor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50428383,
        AWATER: 719978,
        INTPTLAT: '+43.4753573',
        INTPTLON: '-072.4210442',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1709, 44.489],
            [-73.1764, 44.4848],
            [-73.202, 44.4925],
            [-73.1709, 44.489],
          ],
        ],
      },
      properties: {
        STATEFP: '50',
        SCSDLEA: '',
        GEOID: '5009360',
        NAME: 'Winooski Incorporated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3707470,
        AWATER: 206068,
        INTPTLAT: '+44.4955870',
        INTPTLON: '-073.1848573',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */

import React, { createRef, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import shortid from 'shortid';

import CalculateMoeConfirmationModal from '../../Components/CalculateMoeConfirmationModal';
import Chart from '../../Components/Chart';
import ExportModal from '../../Components/ExportModal';
import MoeCalculationInProgressModal from '../../Components/MoeCalculationInProgressModal';
import ReportModal from '../../Components/ReportModal';
import ReportToast from '../../Components/ReportToast';
import ShareALinkModal from '../../Components/ShareALinkModal';
import SurveyFiltersSegments from '../../Components/SurveyFiltersSegments';
import SurveyHeader from '../../Components/SurveyHeader';
import ThemeSelectorModal from '../../Components/ThemeSelectorModal';
import {
  calculateMoe,
  getSurveyDetail,
  getSurveyDetailFilters,
  getSurveyJob,
  getSurveyQuestionsCategories,
  getSurveySectionDetails,
  getSurveyShareALinkByShareToken,
  getSurveyVisualizations,
  postSurveyVisualizations,
} from '../../actions/survey.details.action';
import { CHART_TYPES } from '../../constants/charts';
import MliVisualizationDetails from '../../helpers/analytics/models/mliVisualizationDetails';
import navigationTracker from '../../helpers/analytics/navigationTracker';
import types from '../../types';
import './styles.css';

let jobsInterval = -1;

const SurveyDetailsLanding = () => {
  const dispatch = useDispatch();
  const [mliCallCount, setMliCount] = useState(0);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { activeElementId } = useSelector((state) => state.shareALinkResult);
  const [showCalculateMoeModal, setShowCalculateMoeModal] = useState(false);
  const [showThemeSelector, setShowThemeSelector] = useState(false);
  const {
    surveyDetails,
    filters,
    section,
    visualizations,
    jobs,
    questionsCategories,
    error,
    isExpandedAll,
  } = useSelector((state) => state.surveyDetails);

  const exportFileLoading = useSelector((state) => state.exportFile.loading);
  const storeJwt = useSelector((state) => state.session.jwt);
  const { id } = useParams();
  const { search } = useLocation();
  const chartRefMap = jobs?.questions?.reduce(
    (total, curr) => ({
      ...total,
      [curr.id]: createRef(),
    }),
    {}
  );

  const loadSectionDetailsAsync = async (paramSection) => {
    if (paramSection) {
      dispatch(getSurveyJob(paramSection, storeJwt));
    }
  };

  const loadAsyncData = async (surveySection) => {
    if (surveySection?.questions?.length) {
      await Promise.all(
        surveySection?.questions
          ?.filter((q) => q.relatedQuestionId && q.relatedQuestionId > 0)
          .map((q) => dispatch(getSurveyQuestionsCategories(q.relatedQuestionId, storeJwt)))
          .concat(
            surveySection?.questions?.map((q) =>
              dispatch(getSurveyQuestionsCategories(q.id, storeJwt))
            )
          )
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: types.reducerTypes.SURVEY_DETAILS_CLEAR });
    };
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    if (id && storeJwt) {
      jobsInterval = -1;
      dispatch(getSurveyDetail(id, storeJwt));
      dispatch(getSurveyDetailFilters(id, storeJwt));
      if (search) {
        const query = new URLSearchParams(search);
        const shareToken = query.get('shareToken');
        dispatch(getSurveyShareALinkByShareToken(shareToken, storeJwt));
      } else {
        dispatch(getSurveyVisualizations(id, storeJwt));
      }
    }
  }, [id, storeJwt]);

  useEffect(() => {
    if (section && section !== '' && selectedSectionIndex && surveyDetails?.questionSections) {
      navigationTracker.onNavigateWithinSurvey(
        {
          id: visualizations?.details?.activeSectionId,
          name: surveyDetails?.questionSections[selectedSectionIndex]?.name,
        },
        new MliVisualizationDetails(visualizations?.details)
      );
    }
  }, [section]);

  useEffect(() => {
    if (surveyDetails?.questionSections?.length > 0 && visualizations) {
      const questionSection = surveyDetails?.questionSections?.find(
        (qs) => qs.id === visualizations?.details?.activeSectionId
      );
      if (questionSection) {
        const index = surveyDetails?.questionSections?.findIndex(
          (qs) => qs.id === visualizations?.details?.activeSectionId
        );

        setSelectedSectionIndex(index);
        loadAsyncData(questionSection);
        dispatch(getSurveySectionDetails(questionSection?.id, visualizations, storeJwt));
      }
    }
  }, [visualizations, surveyDetails]);

  const loadNewSectionData = async (newSectionIndex) => {
    const updatedVisu = { ...visualizations };
    const newSection = surveyDetails?.questionSections[newSectionIndex];
    updatedVisu.details.activeSectionId = newSection.id;
    jobsInterval = -1;
    setSelectedSectionIndex(newSectionIndex);
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_CLEAR_OPEN_QUESTIONS,
    });
  };

  useEffect(() => {
    const lsSection = localStorage.getItem('sectionId');
    if (lsSection && surveyDetails?.questionSections) {
      const sectionIndex = surveyDetails?.questionSections?.findIndex(
        (qs) => qs.code.toString() === lsSection.toString()
      );
      if (sectionIndex >= 0) {
        localStorage.removeItem('sectionId');
        loadNewSectionData(sectionIndex);
      } else if (section && section !== '' && mliCallCount <= 20 && !jobs) {
        loadSectionDetailsAsync(section);
        if (jobsInterval === -1) {
          jobsInterval = setInterval(() => {
            if (!jobs) {
              setMliCount((prevValue) => prevValue + 1);
            }
          }, 10000);
        }
      }
    } else if (section && section !== '' && mliCallCount <= 20 && !jobs) {
      loadSectionDetailsAsync(section);
      if (jobsInterval === -1) {
        jobsInterval = setInterval(() => {
          if (!jobs) {
            setMliCount((prevValue) => prevValue + 1);
          }
        }, 10000);
      }
    }
  }, [section, mliCallCount]);

  useEffect(() => {
    if (jobs?.questions?.length) {
      clearInterval(jobsInterval);
      setLoading(false);
      let initQId = activeElementId || jobs.questions[0].id;
      const questionId = localStorage.getItem('questionId');
      if (questionId) {
        initQId = questionId;
        localStorage.removeItem('questionId');
      }
      if (initQId) {
        dispatch({
          type: types.reducerTypes.SURVEY_DETAILS_TOGGLE_OPEN_QUESTIONS,
          payload: {
            toggleQuestionId: Number(initQId),
            isInit: true,
          },
        });
      }
    }
  }, [jobs]);

  const handleRemoveFilter = (removeFilter) => {
    const updatedVisu = { ...visualizations };
    if (removeFilter.type === 'LOCATION') {
      updatedVisu.details.filter.constraintGroups = updatedVisu.details.filter.constraintGroups.filter(
        (cg) => cg.type !== 'LOCATION'
      );
    } else if (removeFilter.type === 'GENERAL') {
      updatedVisu.details.filter.constraintGroups = updatedVisu.details.filter.constraintGroups.filter(
        (cg) => (cg.type === 'GENERAL' && cg.field !== removeFilter.field) || cg.type !== 'GENERAL'
      );
    } else if (removeFilter.type === 'QUESTION') {
      updatedVisu.details.filter.constraintGroups = updatedVisu.details.filter.constraintGroups.filter(
        (cg) =>
          (cg.type === 'QUESTION' && cg.field !== removeFilter.field) || cg.type !== 'QUESTION'
      );
    }
    updatedVisu.details.activeSectionId = surveyDetails?.questionSections[selectedSectionIndex]?.id;
    if (updatedVisu.details.filter.constraintGroups.length === 0) {
      jobs.questions.forEach((q) => {
        if (updatedVisu?.details?.chartSettings?.chartSettings[q.id]?.benchmarks) {
          updatedVisu.details.chartSettings.chartSettings[q.id].benchmarks = {
            state: false,
            national: false,
          };
        }
      });
    }
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
  };

  const handleApplyFilter = (newFilter) => {
    const updatedVisu = { ...visualizations };
    // First remove the filter to add it again, this way we ensure that we are applying the new filter
    if (newFilter.type === 'LOCATION') {
      updatedVisu.details.filter.constraintGroups = updatedVisu.details.filter.constraintGroups.filter(
        (cg) => cg.type !== 'LOCATION'
      );
    }
    if (newFilter.type === 'GENERAL') {
      updatedVisu.details.filter.constraintGroups = updatedVisu.details.filter.constraintGroups.filter(
        (cg) => (cg.type === 'GENERAL' && cg.field !== newFilter.field) || cg.type !== 'GENERAL'
      );
    }
    if (newFilter.type === 'QUESTION') {
      updatedVisu.details.filter.constraintGroups = updatedVisu.details.filter.constraintGroups.filter(
        (cg) => (cg.type === 'QUESTION' && cg.field !== newFilter.field) || cg.type !== 'QUESTION'
      );
    }
    updatedVisu.details.filter.constraintGroups.push(newFilter);
    updatedVisu.details.activeSectionId = surveyDetails?.questionSections[selectedSectionIndex]?.id;
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
  };

  const handleApplySegment = (newSegment) => {
    const updatedVisu = { ...visualizations };
    // First remove the segment to add it again, this way we ensure that we are applying the new filter
    updatedVisu.details.segments = updatedVisu.details.segments.filter(
      (cg) => cg.id !== newSegment.id
    );
    updatedVisu.details.segments.push(newSegment);
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
  };

  const handleRemoveSegment = (removeSegment) => {
    const updatedVisu = { ...visualizations };
    // First remove the filter to add it again, this way we ensure that we are applying the new filter
    updatedVisu.details.segments = updatedVisu.details.segments.filter(
      (cg) => cg.id !== removeSegment.id
    );
    updatedVisu.details.activeSectionId = surveyDetails?.questionSections[selectedSectionIndex]?.id;
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
  };

  const handleToggleAllRespondents = () => {
    const updatedVisu = { ...visualizations };
    updatedVisu.details.enableDefaultSegment = !updatedVisu.details.enableDefaultSegment;
    updatedVisu.details.activeSectionId = surveyDetails?.questionSections[selectedSectionIndex]?.id;
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
  };

  const handleToggleSegment = (e) => {
    const segmentId = Number.parseInt(e.target.value, 10);
    const updatedVisu = { ...visualizations };
    const segment = updatedVisu.details.segments.find((cg) => cg.id === segmentId);
    segment.active = !segment.active;
    updatedVisu.details.segments = updatedVisu.details.segments.filter((cg) => cg.id !== segmentId);
    updatedVisu.details.segments.push(segment);
    updatedVisu.details.activeSectionId = surveyDetails?.questionSections[selectedSectionIndex]?.id;
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt));
  };

  const updateChartType = (currJob, chartName) => {
    const updatedVisu = { ...visualizations };
    const defaultSettings = {
      benchmarks: {
        state: false,
        national: false,
      },
      showPrincipalResponses: false,
    };
    updatedVisu.details.chartSettings.chartSettings[currJob.id] = {
      ...(updatedVisu?.details?.chartSettings?.chartSettings[currJob.id] || defaultSettings),
      selectedChartType: chartName,
    };
    jobsInterval = -1;
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt, false));
  };

  const updateBenchmark = (currJob, benchmarks) => {
    const updatedVisu = { ...visualizations };
    updatedVisu.details.chartSettings.chartSettings[currJob.id] = {
      benchmarks,
      showPrincipalResponses: false,
      selectedChartType:
        updatedVisu?.details?.chartSettings?.chartSettings[currJob.id]?.selectedChartType ||
        currJob?.defaultVisualizationType,
    };
    jobsInterval = -1;
    dispatch(postSurveyVisualizations(id, updatedVisu, storeJwt, false));
  };

  const handleCalculateMoeModalClose = () => {
    setShowCalculateMoeModal(false);
  };

  const handleCalculateMoeButtonClick = () => {
    setShowCalculateMoeModal(true);
  };

  const handleCalculateMoeModalConfirm = () => {
    const sectionId = surveyDetails?.questionSections[selectedSectionIndex]?.id;
    handleCalculateMoeModalClose();
    jobsInterval = -1;
    setLoading(true);
    setMliCount(1);
    dispatch(calculateMoe(sectionId, visualizations, storeJwt));
    setTimeout(() => window.location.reload(), 30000);
  };

  const getSvg = (questionId) =>
    document.querySelector(`#chart-id-${questionId} svg`)?.outerHTML || '';

  const getSvgRef = (questionId) => chartRefMap[questionId].current.chart.getSVG();

  const handleAddToReportButtonClick = () => {
    const reportItem = {
      visualizationDetails: visualizations?.details,
      visualizationId: visualizations?.id,
      contextType: 'QUESTION', // TODO - figure out from where to get this if not a question
      contextIds: jobs?.questions?.map((q) => q?.id),
    };
    dispatch({ type: types.reducerTypes.REPORT_MODAL_SHOW, payload: reportItem });
  };

  const handleExportQuestion = (questionId) => {
    const currentJob = jobs?.questions.find((job) => job.id === questionId);
    if (!currentJob) return;
    const currChartType =
      visualizations?.details?.chartSettings?.chartSettings[currentJob.id]?.selectedChartType ||
      currentJob?.defaultVisualizationType ||
      '';
    const exportItem = {
      svg: currChartType === CHART_TYPES.DONUT ? getSvgRef(questionId) : getSvg(questionId),
      config: visualizations?.details,
      contextId: questionId,
      mliDataSegments: currentJob.queryResult?.mliDataSegments,
      questionCode: currentJob.code,
      questionPrompt: currentJob.prompt,
      dashboardData: null,
    };
    dispatch({ type: types.reducerTypes.EXPORT_MODAL_SHOW, payload: [exportItem] });
  };

  const handleStyleSelectorButtonClick = () => {
    setShowThemeSelector(true);
  };

  const saveTheme = (theme) => {
    const newVisualization = {
      ...visualizations,
      details: {
        ...visualizations.details,
        scheme: theme,
      },
    };
    setShowThemeSelector(false);
    dispatch(postSurveyVisualizations(id, newVisualization, storeJwt));
  };

  const handleExpandAll = () => {
    if (isExpandedAll) {
      dispatch({
        type: types.reducerTypes.SURVEY_DETAILS_CLEAR_ALL_OPEN_QUESTIONS,
      });
    } else {
      dispatch({
        type: types.reducerTypes.SURVEY_DETAILS_TOGGLE_ALL_OPEN_QUESTIONS,
        payload: {
          allQuestionsIds: jobs?.questions?.map((question) => question.id),
        },
      });
    }
  };

  const exportAllQuestions = () => {
    const items = jobs?.questions?.map((question) => {
      const currChartType =
        visualizations?.details?.chartSettings?.chartSettings[question.id]?.selectedChartType ||
        question?.defaultVisualizationType ||
        '';
      return {
        svg: currChartType === CHART_TYPES.DONUT ? getSvgRef(question?.Id) : getSvg(question?.id),
        config: visualizations?.details,
        contextId: question?.id,
        mliDataSegments: question?.queryResult?.mliDataSegments,
        questionCode: question?.code,
        questionPrompt: question?.prompt,
        dashboardData: null,
      };
    });
    dispatch({ type: types.reducerTypes.EXPORT_MODAL_SHOW, payload: items });
  };

  const handleExportButtonClick = () => {
    handleExpandAll();
    setTimeout(exportAllQuestions, 5000);
  };

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col className="d-flex min-vh-100 justify-content-center align-items-center">
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container fluid className="p-0" key={shortid.generate()}>
      <SurveyHeader
        key={shortid.generate()}
        selectedSectionIndex={selectedSectionIndex}
        loadNewSectionData={loadNewSectionData}
        surveyDetails={surveyDetails}
        onExportButtonClick={handleExportButtonClick}
        onMoeButtonClick={handleCalculateMoeButtonClick}
        onAddToReportButtonClick={handleAddToReportButtonClick}
        onStyleSelectorButtonClick={handleStyleSelectorButtonClick}
        onExpandAll={handleExpandAll}
        isExpandedAll={isExpandedAll}
      />
      <SurveyFiltersSegments
        key={shortid.generate()}
        visualizations={visualizations}
        filters={filters}
        handleApplyFilter={handleApplyFilter}
        removeFilter={handleRemoveFilter}
        handleApplySegment={handleApplySegment}
        removeSegment={handleRemoveSegment}
        toggleAllRespondents={handleToggleAllRespondents}
        toggleSegment={handleToggleSegment}
      />
      <Row className="w-100 m-0 pb-5" key={shortid.generate()}>
        {(loading || exportFileLoading) && (
          <Container fluid key={shortid.generate()}>
            <Row>
              <Col>
                <Spinner animation="border" />
              </Col>
            </Row>
          </Container>
        )}
        {!loading &&
          !exportFileLoading &&
          jobs?.questions?.map((question, i) => (
            <Chart
              key={shortid.generate()}
              memberIdx={i}
              currentJob={question}
              visualization={visualizations}
              currentCategory={questionsCategories?.find((qc) => qc?.key_id === question?.id)}
              isRelated={!!question.relatedQuestionId}
              updateChartType={updateChartType}
              updateBenchmark={updateBenchmark}
              questionsCategories={questionsCategories}
              shouldScroll={
                activeElementId === question?.id && activeElementId !== jobs.questions[0].id
              }
              onExport={handleExportQuestion}
              ref={chartRefMap[question.id]}
              isExpandedAll={isExpandedAll}
            />
          ))}
      </Row>
      <ExportModal />
      <ReportModal />
      <ReportToast />
      <CalculateMoeConfirmationModal
        show={showCalculateMoeModal}
        onConfirm={handleCalculateMoeModalConfirm}
        onClose={handleCalculateMoeModalClose}
      />
      <MoeCalculationInProgressModal />
      <ShareALinkModal />
      <ThemeSelectorModal
        show={showThemeSelector}
        currentSelected={visualizations?.details?.scheme}
        onConfirm={saveTheme}
        onClose={() => setShowThemeSelector(false)}
      />
    </Container>
  );
};

export default React.memo(SurveyDetailsLanding);

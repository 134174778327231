import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import configStore from './store/configStore';

const initialState = {};
const store = configStore(initialState);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

import axios from 'axios';
import types from '../types';
import { API_ROOT } from '../config/appConfig';
import { fileWizardStructure } from '../Components/FileWizard/fileWizardStructure';
import { newError } from './errors.action';
import { getHeaders, businessLogicSort } from './helper.action';

export const fileWizardIsFetchingData = (isFetchingData) => (dispatch) => {
  dispatch({ type: types.reducerTypes.FILEWIZARD_IS_FETCHING_DATA, isFetchingData });
};

export const setFileWizard = (visualizationId) => (dispatch) => {
  const wizard = fileWizardStructure.filter((el) => el.fileWizard === Number(visualizationId))[0];
  const { steps } = wizard;
  dispatch({ type: types.reducerTypes.SET_FILE_WIZARD, payload: { steps } });
};

const updateChartSuccess = (res) => (dispatch) => {
  dispatch({ type: types.reducerTypes.UPDATE_CHART_SUCCESS, updateChartSuccess: res });
};

export const setVisualization = (id, jwt) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/visualization/${id}`,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      dispatch({
        type: types.reducerTypes.SET_VISUALIZATION,
        visualization: res.data,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const getAllMetadatas = (jwt) => async (dispatch, getState) => {
  const state = getState();
  const { fileWizard } = state;
  const { visualization } = fileWizard;
  const { VisualizationMetadata } = visualization;
  dispatch(fileWizardIsFetchingData(true));
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/dashboard-serie-metadata/`,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      const sortedMetadata = businessLogicSort(res.data, VisualizationMetadata);
      dispatch({
        type: types.reducerTypes.GET_ALL_METADATA,
        allMetadatas: sortedMetadata,
      });
      dispatch(fileWizardIsFetchingData(false));
      return;
    }
  } catch (err) {
    console.error(err);
    dispatch(fileWizardIsFetchingData(false));
    dispatch(newError('getAllMetadatasErr'));
  }
};

export const selectMetadata = (target) => (dispatch) => {
  const { name, checked } = target;
  const { id } = target.dataset;
  const numId = Number(id);
  dispatch({
    type: types.reducerTypes.SELECTED_METADATA,
    payload: { name, value: checked, id: numId },
  });
};

/*
export const getMultipleDimensions = (jwt) => async (dispatch) => {
  const metadatasId = [10, 8];
  const params = new URLSearchParams();
  metadatasId.forEach((id) => {
    params.append('id', id);
  });

  try {
    const res = await axios.get(`${API_ROOT}/admin-backend/dimension/multiple`,
      getHeaders('application/json', jwt, params));
    const { data } = res;
    return;
    if (res && res.status === 200) {
      dispatch({
        type: types.reducerTypes.GET_SAMPLE_METADATA,
        allSampleMetadata: data,
      });
      dispatch(fileWizardIsFetchingData(false));
    }
  } catch (err) {
    console.error(err);
    dispatch(fileWizardIsFetchingData(false));
    dispatch(newError('getAllSampleMetadataErr'));
  }
};
*/

/**
 * Dimensions on Dashboards are nested values.
 * A sample type "VALUE_" contains as Item the first Dimension
 * A Dimension contains as item another dimension (If present on the Dataset)
 */

const getDashboardDimensions = async (valueSampleItems = [], jwt, dimensions = [], parentId) => {
  if (valueSampleItems.length <= 0) return [];
  const dimensionId = valueSampleItems[0];
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/dimension/${dimensionId}`,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      const { data } = res;
      data.ParentId = parentId;
      dimensions.push(data);
      if (data.Items && data.Items.length > 0) {
        await getDashboardDimensions(data.Items, jwt, dimensions, parentId);
      }
    }
    return dimensions;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getDimensions = async (data, jwt) =>
  Promise.all(
    data.map((valueSample) =>
      getDashboardDimensions(valueSample[0].Items, jwt, [], valueSample[0].Parent)
    )
  );

export const getDimensionsBySample = (jwt) => async (dispatch, getState) => {
  const state = getState();
  const { allSampleMetadata } = state.fileWizard;
  const dimensions = await getDimensions(allSampleMetadata, jwt);
  dispatch({ type: types.reducerTypes.GET_DIMENSION_METADATA, allSampleDimension: dimensions });
};

export const getAllSampleMetadata = (jwt, metadatasIds) => async (dispatch) => {
  dispatch(fileWizardIsFetchingData(true));
  try {
    const res = await axios.post(
      `${API_ROOT}/admin-backend/dashboard-serie-metadata/multipleMetadata/samples`,
      { metadatasIds },
      getHeaders('application/json', jwt)
    );
    const { data } = res;
    if (res && res.status === 200) {
      dispatch({
        type: types.reducerTypes.GET_SAMPLE_METADATA,
        allSampleMetadata: data,
      });
      dispatch(fileWizardIsFetchingData(false));
    }
  } catch (err) {
    console.error(err);
    dispatch(fileWizardIsFetchingData(false));
    dispatch(newError('getAllSampleMetadataErr'));
  }
};

export const setSampleValue = (target) => (dispatch) => {
  const { name } = target;
  const { id, metadataid } = target.dataset;
  const numMetadataId = Number(metadataid);
  dispatch({
    type: types.reducerTypes.SET_FILEWIZARD_SAMPLES_VALUE,
    payload: {
      name,
      id,
      metadataid: numMetadataId,
    },
  });
};

const createChartConfig = (chartType, name, identifier, metadataToUpdate = []) => {
  // Add logic for adding and no replacing. The config should be ready here.

  let configuration = {};
  if (metadataToUpdate.configuration) {
    configuration = JSON.parse(JSON.stringify(metadataToUpdate.configuration));
  }
  switch (chartType) {
    case 'line': {
      if (!configuration.line) {
        configuration.line = {};
      }
      configuration.line[`${name}`] = identifier;
      break;
    }
    case 'bar': {
      if (!configuration.bar) configuration.bar = {};
      configuration.bar[`${name}`] = identifier;
      break;
    }
    case 'column': {
      if (!configuration.column) configuration.column = {};
      configuration.column[`${name}`] = identifier;
      break;
    }
    default:
      break;
  }
  return configuration;
};

export const setChartConfig = (target, chartType) => (dispatch, getState) => {
  const { metadataid, identifier, name } = target.dataset;
  const numMetadataId = Number(metadataid);
  const state = getState();
  const { dataSources } = state.fileWizard;
  const metadataToUpdate = dataSources.filter((el) => el.metadataId === numMetadataId)[0];
  // Create config depending on chart
  const configuration = createChartConfig(chartType, name, identifier, metadataToUpdate);

  dispatch({
    type: types.reducerTypes.SET_FILE_WIZARD_SELECTED_DIMENSIONS,
    payload: {
      configuration,
      metadataid: numMetadataId,
    },
  });
};

export const updateChart = (jwt) => async (dispatch, getState) => {
  const state = getState();
  const { fileWizard } = state;
  const { dataSources, visualization } = fileWizard;
  const data = {
    Payload: {
      Id: Number(visualization.Id),
      DataSources: dataSources,
    },
  };
  try {
    const res = await axios.patch(
      `${API_ROOT}/admin-backend/visualization`,
      data,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      dispatch(updateChartSuccess(true));
      return;
    }
  } catch (err) {
    console.error(err);
    dispatch(newError(types.errorTypes.updateChartErr));
  }
};

/**
 * When fetching data, if there are selected options per visualziation,
 * this function will set those options to the satate in order to show
 * the selection.
 */
export const initializeFileWizardSelectedSamples = () => (dispatch, getState) => {
  const state = getState();
  const { visualization, selectedMetadatasIds } = state.fileWizard;
  if (!visualization.DataSources) return null;
  const dataSources = visualization.DataSources.filter((el) =>
    selectedMetadatasIds.includes(el.metadataId)
  );
  if (dataSources.length <= 0) return null;
  dispatch({
    type: types.reducerTypes.SET_FILE_WIZARD_SELECTED_SAMPLES,
    dataSources,
  });
  return null;
};

export const initializeFileWizardSelectedMetadatas = () => (dispatch, getState) => {
  const state = getState();
  const { visualization } = state.fileWizard;
  if (!visualization.DataSources) return null;
  const { DataSources } = visualization;
  if (DataSources.length <= 0) return null;
  const selectedMetadatasIds = [];
  DataSources.forEach((element) => {
    selectedMetadatasIds.push(element.metadataId);
  });
  dispatch({
    type: types.reducerTypes.SET_FILE_WIZARD_SELECTED_METADATAS,
    selectedMetadatasIds,
  });
  return null;
};

export const resetFileWizard = () => (dispatch) => {
  dispatch({ type: types.reducerTypes.RESET_FILE_WIZARD });
};

export const resetSelectedSamples = () => (dispatch) => {
  dispatch({ type: types.reducerTypes.RESET_FILE_WIZARD_SELECTED_SAMPLES });
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Container,
  Col,
  FormControl,
  FormCheck,
  FormLabel,
  InputGroup,
  Modal,
  Table,
  Row,
  Spinner,
} from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRedo,
  faBuilding,
  faTrash,
  faTimesCircle,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faPencilAlt,
  faSave,
} from '@fortawesome/free-solid-svg-icons';

import {
  createOrg,
  deleteOrg,
  getOrgList,
  updateOrg,
} from '../../../../actions/organization.actions';
import Pagination from '../../../../Components/Pagination';
import OrgForm from '../OrgForm';
import './styles.css';

const OrgList = () => {
  const dispatch = useDispatch();
  const [usernameFilter, setUsernameFilter] = useState('');
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [orgAdminFilter, setOrgAdminFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortDir, setSortDir] = useState('');
  const [sortCol, setSortCol] = useState('');
  const [show, setShow] = useState(false);
  const [showMultiDelete, setShowMultiDelete] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [deleteItem, setDeleteItem] = useState({});
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const {
    loading,
    organizations: orgList,
    totalSize,
    exportFile,
    isProcessingExport,
    canGetList,
  } = useSelector((state) => state.organizations);
  const storeJwt = useSelector((state) => state.session.jwt);
  const currentUser = jwtDecode(storeJwt);

  const handleClose = () => {
    setShow(false);
    setShowMultiDelete(false);
    setDeleteItem({});
  };
  const handleCreateOrg = () => setShow(true);

  const retrieveOrgs = () => {
    const filter = {
      page: currentPage,
      size: pageSize,
    };
    if (sortDir !== '' && sortCol !== '') {
      filter.dir = sortDir;
      filter.sort = sortCol;
    } else {
      // Order by last login by default
      filter.dir = 'desc';
      filter.sort = 'name';
    }
    if (usernameFilter !== '') {
      filter.name = usernameFilter;
    }
    if (descriptionFilter !== '') {
      filter.description = descriptionFilter;
    }
    if (descriptionFilter !== '') {
      filter.description = descriptionFilter;
    }
    if (orgAdminFilter !== '') {
      filter.admin = orgAdminFilter;
    }
    dispatch(getOrgList(filter, storeJwt));
  };

  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  const handleCheckOrg = (org) => {
    const userIndex = selectedOrgs.findIndex((su) => su === org.id);
    if (userIndex === -1) {
      setSelectedOrgs([...selectedOrgs, org.id]);
    } else {
      setSelectedOrgs(selectedOrgs.filter((id) => org.id !== id));
    }
  };

  const handleSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      retrieveOrgs();
    }
  };

  useEffect(() => {
    retrieveOrgs();
  }, [currentPage, pageSize, sortDir, sortCol]);

  useEffect(() => {
    if (exportFile && exportFile !== '' && !isProcessingExport) {
      const link = document.createElement('a');
      link.download = true;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.href = exportFile;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [exportFile, isProcessingExport]);

  useEffect(() => {
    if (canGetList) {
      retrieveOrgs();
    }
  }, [canGetList]);

  const handleClearFilters = () => {
    setUsernameFilter('');
    setDescriptionFilter('');
    setOrgAdminFilter('');
    setSortDir('');
    setSortCol('');
    const filter = {
      page: currentPage,
      size: pageSize,
      dir: 'desc',
      sort: 'name',
    };
    dispatch(getOrgList(filter, storeJwt));
  };

  const handleOrgAdminFilterChange = (e) => {
    setOrgAdminFilter(e.target.value);
  };

  const handleDescriptionFilterChange = (e) => {
    setDescriptionFilter(e.target.value);
  };

  const handleUsernameFilterChange = (e) => {
    setUsernameFilter(e.target.value);
  };

  const handleSortChange = (newSortCol) => {
    if (sortCol === newSortCol) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCol(newSortCol);
      setSortDir('asc');
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const handleChangePage = (newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(newPage);
  };

  const handleSave = (newOrg) => {
    dispatch(createOrg(newOrg, storeJwt));
    setShow(false);
  };

  const handleUpdateOrg = (editOrg) => {
    dispatch(
      updateOrg(
        {
          name: editOrg.name,
          description: editOrg.description,
          suppressData: editOrg.suppressData,
        },
        storeJwt,
        editOrg.id
      )
    );
  };

  const handleDeleteOrg = async (ids) => {
    await Promise.all(ids.map((id) => dispatch(deleteOrg(id, storeJwt))));
    setShowMultiDelete(false);
  };

  const handleSaveUser = () => {
    if (editItem.name) {
      handleUpdateOrg(editItem);
      setEditItem({});
    }
  };

  const handleOnEditCheckboxFieldChange = (e) => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.checked,
    });
  };

  const handleOnEditFieldChange = (e) => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = () => {
    handleDeleteOrg([deleteItem.id]);
    setDeleteItem({});
  };

  const handleDeleteMultiple = () => {
    handleDeleteOrg(selectedOrgs);
  };

  return (
    <Container fluid className="p-5">
      <Row className="mb-2">
        <ButtonGroup>
          <button
            title="Create org"
            className="btn btn-custom dark"
            type="button"
            onClick={handleCreateOrg}
          >
            <FontAwesomeIcon icon={faBuilding} />
          </button>
          <button
            title="Delete Selected Organizations"
            className="btn btn-custom dark"
            type="button"
            onClick={() => setShowMultiDelete(true && selectedOrgs.length > 0)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            title="Clear Filters"
            className="btn btn-custom dark"
            type="button"
            onClick={handleClearFilters}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          <button
            title="Refresh Organization List"
            className="btn btn-custom dark"
            type="button"
            onClick={retrieveOrgs}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
        </ButtonGroup>
      </Row>
      <Row>
        <Table responsive="xl" striped bordered hover className="w-100">
          <thead className="custom-thead">
            <tr>
              <th className="text-center align-baseline">
                <FormCheck type="checkbox" onChange={handleCheckAll} checked={checkAll} />
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Organization Id</FormLabel>
              </th>
              <th>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Organization name"
                    aria-label="Organization name"
                    aria-describedby="Organization name"
                    value={usernameFilter}
                    onChange={handleUsernameFilterChange}
                  />
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                  <InputGroup.Append>
                    <Button
                      variant="light"
                      className="btn btn-custom dark"
                      onClick={() => handleSortChange('name')}
                    >
                      <FontAwesomeIcon
                        icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                        color={sortCol === 'name' ? 'white' : 'gray'}
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th className="text-center align-baseline">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Organization Description"
                    aria-label="Organization Description"
                    aria-describedby="Organization Description"
                    value={descriptionFilter}
                    onChange={handleDescriptionFilterChange}
                  />
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th className="text-center align-baseline">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Organization Admins"
                    aria-label="Organization Admins"
                    aria-describedby="Organization Admins"
                    value={orgAdminFilter}
                    onChange={handleOrgAdminFilterChange}
                  />
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Organization Suppress Data</FormLabel>
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Created Date</FormLabel>
                <Button
                  variant="light"
                  className="btn btn-custom dark"
                  onClick={() => handleSortChange('createdDate')}
                >
                  <FontAwesomeIcon
                    icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                    color={sortCol === 'createdDate' ? 'white' : 'gray'}
                  />
                </Button>
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Updated Date</FormLabel>
                <Button
                  variant="light"
                  className="btn btn-custom dark"
                  onClick={() => handleSortChange('lastModifiedDate')}
                >
                  <FontAwesomeIcon
                    icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                    color={sortCol === 'lastModifiedDate' ? 'white' : 'gray'}
                  />
                </Button>
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Options</FormLabel>
              </th>
            </tr>
          </thead>
          {loading && (
            <tbody>
              <tr className="text-center p-5 vh-100">
                <td>
                  <Spinner animation="border" />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && (
            <tbody>
              {orgList?.map((org) => (
                <tr key={org.id}>
                  <td>
                    <FormCheck
                      type="checkbox"
                      checked={checkAll || selectedOrgs.indexOf(org.id) !== -1}
                      onChange={() => handleCheckOrg(org)}
                    />
                  </td>
                  <td>
                    <span>{org.id}</span>
                  </td>
                  <td>
                    {editItem.id === org.id ? (
                      <FormControl
                        placeholder="Organization name"
                        aria-label="Organization name"
                        name="name"
                        aria-describedby="Organization name"
                        defaultValue={editItem.name}
                        onChange={handleOnEditFieldChange}
                        className={editItem.name ? '' : 'border border-danger'}
                      />
                    ) : (
                      <span>{org.name}</span>
                    )}
                  </td>
                  <td>
                    {editItem.id === org.id ? (
                      <FormControl
                        placeholder="Organization description"
                        aria-label="Organization description"
                        name="description"
                        aria-describedby="Organization description"
                        defaultValue={editItem.description}
                        onChange={handleOnEditFieldChange}
                      />
                    ) : (
                      <span>{org.description}</span>
                    )}
                  </td>
                  <td>
                    <div className="org-admin-column">
                      {org?.members
                        ?.filter((m) => m.isAdmin)
                        ?.map((m) => m.user.email)
                        ?.join(', ')}
                    </div>
                  </td>
                  <td>
                    {editItem.id === org.id ? (
                      <FormCheck
                        aria-label="Suppress Data"
                        name="suppressData"
                        aria-describedby="Suppress Data"
                        checked={editItem.suppressData}
                        onChange={handleOnEditCheckboxFieldChange}
                      />
                    ) : (
                      <span>{org.suppressData ? 'Yes' : 'No'}</span>
                    )}
                  </td>
                  <td>
                    <span>{new Date(org.createdDate).toLocaleDateString()}</span>
                  </td>
                  <td>
                    <span>{new Date(org.lastModifiedDate).toLocaleDateString()}</span>
                  </td>
                  <td className="text-center align-baseline">
                    {editItem?.id === org.id ? (
                      <ButtonGroup>
                        <Button
                          title="Save org"
                          variant="outline-secondary"
                          className="btn btn-custom dark"
                          onClick={handleSaveUser}
                        >
                          <FontAwesomeIcon icon={faSave} />
                        </Button>
                        <Button
                          title="Cancel"
                          variant="outline-secondary"
                          className="btn btn-custom dark"
                          onClick={() => setEditItem({})}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup>
                        <Button
                          title="Edit organization"
                          variant="outline-secondary"
                          className="btn btn-custom dark"
                          onClick={() => setEditItem(org)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                        <Button
                          title="Delete organization"
                          disabled={
                            (currentUser.role !== 'SUPER_ADMIN' && org.role === 'SUPER_ADMIN') ||
                            currentUser.userId === org.id
                          }
                          className="btn btn-custom dark"
                          variant="outline-secondary"
                          onClick={() => setDeleteItem(org)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </ButtonGroup>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        <Modal show={deleteItem.id !== undefined} onHide={handleClose}>
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Delete organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                All information about the organization {deleteItem.name} will be lost. Do you want
                to continue?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-custom white mr-3"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
        <Modal show={showMultiDelete} onHide={handleClose}>
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Delete organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                You are going to delete {selectedOrgs.length} organization(s). All information about
                the organization(s) will be lost. Do you want to continue?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                className="btn btn-custom white mr-3"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={handleDeleteMultiple}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
      </Row>
      <Row>
        <Col xs={1}>
          <FormControl as="select" onChange={handlePageSizeChange}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormControl>
        </Col>
        <Col>
          <Pagination
            currentPage={currentPage}
            onPageChange={handleChangePage}
            pageSize={pageSize}
            totalItems={totalSize}
          />
        </Col>
      </Row>
      <OrgForm show={show} handleClose={handleClose} handleSave={handleSave} />
    </Container>
  );
};

export default OrgList;

/* eslint-disable class-methods-use-this */
import analytics from 'react-ga4';
import { GA_MEASUREMENT_ID } from '../../config/appConfig';
import { describeState } from '../location';
import CustomDimension from './customDimension';
import ComponentConstants from './models/componentConstants';
import DashboardVisualizationDetails from './models/dashboardVisualizationDetails';
import HitInfo from './models/hitInfo';
import MliVisualizationDetails from './models/mliVisualizationDetails';
import UniversalAnalyticsTracker from './universalAnalyticsTracker';

class NavigationTracker {
  constructor() {
    this.trackers = [new UniversalAnalyticsTracker(analytics)];
  }

  onNavigateWithinSurvey(activeSection, details) {
    try {
      this.send(
        new HitInfo(HitInfo.EVENT)
          .setEventInfo('SurveyAnalyzer', 'Navigate Survey')
          .addCustomDimension(CustomDimension.SURVEY_SECTION, activeSection.name)
          .addCustomDimension(CustomDimension.FILTER_IS_APPLIED, details.isFiltered())
          .addCustomDimension(CustomDimension.FILTER, this.describeFilter(details))
          .addCustomDimension(
            CustomDimension.SEGMENTS_COUNT,
            this.countSegments(details).toString()
          )
      );
    } catch (error) {
      console.error('Navigation tracker - onNavigateWithinSurvey', error);
    }
  }

  onCalculateSectionMarginOfError(section, details) {
    try {
      this.send(
        new HitInfo(HitInfo.EVENT)
          .setEventInfo('SurveyAnalyzer', 'Calculate Margin of Error')
          .addCustomDimension(CustomDimension.SURVEY_SECTION, section.name)
          .addCustomDimension(CustomDimension.FILTER_IS_APPLIED, details.isFiltered())
          .addCustomDimension(CustomDimension.FILTER, this.describeFilter(details))
          .addCustomDimension(
            CustomDimension.SEGMENTS_COUNT,
            this.countSegments(details).toString()
          )
      );
    } catch (error) {
      console.error('Navigation tracker - onCalculateSectionMarginOfError', error);
    }
  }

  /**
   * @param  {DashboardVisualizationDetails} details
   */
  onNavigateWithinDashbaord(details) {
    try {
      this.send(
        new HitInfo(HitInfo.EVENT)
          .setEventInfo('Dashboard', 'Navigate Dashboard')
          .addCustomDimension(
            CustomDimension.DASH_FILTER_IS_APPLIED,
            details.getGlobalFilter() !== 'NONE'
          )
          .addCustomDimension(
            CustomDimension.DASH_LOCATION_SELECTION,
            describeState(details.getState())
          )
          .addCustomDimension(CustomDimension.DASH_GLOBAL_FILTER, details.getGlobalFilter())
          .addCustomDimension(
            CustomDimension.DASH_DATASET_LTOP,
            details.getComponent(ComponentConstants.ID_LTOP).datasetFilter?.description
          )
          .addCustomDimension(
            CustomDimension.DASH_DATASET_LBOT_LINE,
            details.getComponent(ComponentConstants.ID_LBOT_LINE).datasetFilter?.description
          )
          .addCustomDimension(
            CustomDimension.DASH_DATASET_LBOT_BAR,
            details.getComponent(ComponentConstants.ID_LBOT_BAR).datasetFilter?.description
          )
          .addCustomDimension(
            CustomDimension.DASH_DATASET_RTOP,
            details.getComponent(ComponentConstants.ID_RTOP).datasetFilter?.description
          )
          .addCustomDimension(
            CustomDimension.DASH_DATASET_RMID,
            details.getComponent(ComponentConstants.ID_RMID).datasetFilter?.description
          )
          .addCustomDimension(
            CustomDimension.DASH_DATASET_RBOT,
            details.getComponent(ComponentConstants.ID_RBOT).datasetFilter?.description
          )
      );
    } catch (error) {
      console.error('Navigation tracker - onNavigateWithinDashbaord', error);
    }
  }

  onExport(exportable) {
    try {
      // track export hit
      this.send(
        new HitInfo(HitInfo.EVENT)
          .setEventInfo(this.getAppSectionCategory('Export'), 'Export')
          .addCustomDimension(CustomDimension.ITEM_EXPORTED, this.describeExportable(exportable))
      );
    } catch (error) {
      console.error('Navigation tracker - onExport', error);
    }
  }

  onAddToReport(target, addable) {
    try {
      this.send(
        new HitInfo(HitInfo.EVENT)
          .setEventInfo(this.getAppSectionCategory('Add to Report'), 'Add to Report')
          .addCustomDimension(
            CustomDimension.ITEM_ADDED_TO_REPORT,
            this.describeAddable(target, addable)
          )
      );
    } catch (error) {
      console.error('Navigation tracker - onAddToReport', error);
    }
  }

  /**
   * @param  {MliVisualizationDetails | DashboardVisualizationDetails} details
   */
  describeFilter(details) {
    let filterStr = null;
    if (details.isFiltered()) {
      filterStr = details
        .getFilter()
        .getConstraintGroups()
        .map((filter) => `${filter.type?.typeName}:${filter.field}`)
        .join(',');
    }

    return filterStr;
  }

  /**
   * @param  {MliVisualizationDetails | DashboardVisualizationDetails} details
   */
  countSegments(details) {
    let segments = 0;
    if (details.getSegments() != null) {
      segments = details.getSegments().length;
    }

    return segments;
  }

  describeExportable(exportable) {
    if (exportable.reportId != null) {
      // Exporting a whole report
      return `Report: ${exportable.reportId}`;
    }
    const item = exportable.getItems()[0];
    const details = item?.config;

    if (!details) return 'Error';

    if (details instanceof DashboardVisualizationDetails) {
      // Describe dashboard element, followed by dataset selection
      return this.describeDashboardItem(details).getComponent(item.contextId);
    }
    if (details instanceof MliVisualizationDetails) {
      // Survey item(s)
      if (exportable.getItems().length > 1) {
        // Whole survey section
        return `Survey Section: ${exportable.getSurveySectionCode()}`;
      }
      // Single item
      return `Survey Question: ${item.contextId}`;
    }

    return '';
  }

  describeAddable(_, addable) {
    if (addable.contextIds.length > 1) {
      // Adding whole survey section
      return `Survey Section: ${addable.parentContextCode}`;
    }
    // Add a single item
    const details = addable.visualizationDetails;

    if (details['@class'] === 'DashboardVisualizationDetails') {
      // Describe dashboard element, followed by dataset selection
      return this.describeDashboardItem(
        new DashboardVisualizationDetails(details).getComponent(addable.contextIds[0])
      );
    }
    // Describe Question element
    return `Survey Question: ${addable.contextIds[0]}`;
  }

  getAppSectionCategory(defaultCategory) {
    return defaultCategory;
  }

  describeDashboardItem(config) {
    const dataset = config.datasetFilter?.description;

    // Dashboard item
    let itemDesc;
    switch (config.id) {
      case ComponentConstants.ID_LTOP:
        itemDesc = 'Dashboard Map ';
        break;
      case ComponentConstants.ID_LBOT_BAR:
        itemDesc = 'Dashboard Bar ';
        break;
      case ComponentConstants.ID_LBOT_LINE:
        itemDesc = 'Dashboard Line ';
        break;
      case ComponentConstants.ID_RTOP:
        itemDesc = 'Dashboard Demographics ';
        break;
      case ComponentConstants.ID_RMID:
        itemDesc = 'Dashboard Columns ';
        break;
      case ComponentConstants.ID_RBOT:
        itemDesc = 'Dashboard Question ';
        break;
      default:
        itemDesc = '';
    }

    return itemDesc + dataset;
  }

  send(hitInfo) {
    if (!GA_MEASUREMENT_ID) return;
    this.trackers.forEach((tracker) => tracker.send(hitInfo));
  }
}

const navigationTracker = new NavigationTracker();

export default navigationTracker;

/* eslint-disable react-hooks/exhaustive-deps */
import { faFileAlt, faInfoCircle, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Collapse, Container, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  getSectionNameByCodeAndSurvey,
  prepareSectionMetadata,
} from '../../actions/helpers/section.helper';
import { createSection, getSections, updateSection } from '../../actions/section.action';
import {
  createSurveyMetadataDescription,
  getSurveyMetadataDescription,
  getSurveyQuestionsBySurveyIdAndSection,
  updateSurveyMetadata,
  updateSurveyMetadataDescription,
} from '../../actions/survey.action';
import { getSurveysSummary } from '../../actions/survey.summary.action';
import types from '../../types';
import SurveyInformationEditForm from './components/SurveyInformationEditForm';
import SurveyInformationSection from './components/SurveyInformationSection';
import SurveySectionEditForm from './components/SurveySectionEditForm';
import SurveySections from './components/SurveySections';
import SurveySTypeQuestionEditForm from './components/SurveySTypeQuestionEditForm/intex';
import './styles.css';

const SurveyEditWizard = () => {
  const storeJwt = useSelector((state) => state.session.jwt);
  const dispatch = useDispatch();
  const { surveys } = useSelector((state) => state.surveySummary);
  const { allSections } = useSelector((state) => state.sections);
  const { surveyQuestions, isLoadingSurveyQuestions, surveyMetadataDescription } = useSelector(
    (state) => state.surveys
  );
  const [sectionSelected, setSectionSelected] = useState('');
  const [currentSurvey, setCurrentSurvey] = useState();
  const [selectedSurveySectionData, setSelectedSurveySectionData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const { id: surveyId } = useParams();

  const handleEditMode = () => setIsEdit(!isEdit);

  const handleSurveyInfoSave = (surveyInfo) => {
    const { title, description, dateAdministrated } = surveyInfo;
    dispatch(
      updateSurveyMetadata(
        currentSurvey.id,
        {
          title,
        },
        storeJwt
      )
    );
    const metaDescription = surveyMetadataDescription.find(
      (metaDesc) =>
        metaDesc.Data.type === currentSurvey.type &&
        metaDesc.Data.subtype === currentSurvey.subType &&
        metaDesc.Data.year.startsWith(currentSurvey.year)
    );

    if (metaDescription) {
      dispatch(
        updateSurveyMetadataDescription(
          metaDescription.Id,
          {
            ...metaDescription.Data,
            link: metaDescription?.Data?.link ?? 'https://www.rand.org/',
            administered: dateAdministrated,
            description,
          },
          storeJwt
        )
      );
      dispatch({
        type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION,
        payload: {
          ...metaDescription,
          Data: {
            ...metaDescription.Data,
            link: metaDescription?.Data?.link ?? 'https://www.rand.org/',
            administered: dateAdministrated,
            description,
          },
        },
      });
    } else {
      const newMetadataDescription = {
        subtype: currentSurvey.subType,
        type: currentSurvey.type,
        year: `${currentSurvey.year}_${currentSurvey.month}`,
        link: 'https://www.rand.org/',
        administered: dateAdministrated,
        description,
      };
      dispatch(createSurveyMetadataDescription(newMetadataDescription, storeJwt));
      dispatch({
        type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION,
        payload: {
          Id: -1,
          Data: newMetadataDescription,
        },
      });
    }
    setCurrentSurvey({
      ...currentSurvey,
      title,
      surveySummaryDetailsDto: {
        ...currentSurvey.surveySummaryDetailsDto,
        description,
        dateAdministrated,
      },
    });
  };

  const handleSurveySectionInfoSave = (sectionInfo) => {
    if (selectedSurveySectionData.Id === -1) {
      dispatch(
        createSection(
          {
            ...selectedSurveySectionData.Data,
            name: sectionInfo.title,
          },
          storeJwt
        )
      );
    } else {
      dispatch(
        updateSection(
          selectedSurveySectionData.Id,
          {
            ...selectedSurveySectionData.Data,
            name: sectionInfo.title,
          },
          selectedSurveySectionData.Data,
          currentSurvey,
          storeJwt
        )
      );
    }

    const finalMetadata = prepareSectionMetadata(
      {
        ...selectedSurveySectionData.Data,
        name: sectionInfo.title,
      },
      selectedSurveySectionData.Data,
      currentSurvey
    );

    setSelectedSurveySectionData({
      ...selectedSurveySectionData,
      Data: finalMetadata,
    });
  };

  const renderEditFormSection = () => {
    if (isEdit) {
      switch (sectionSelected) {
        case 'info':
          return (
            <SurveyInformationEditForm
              currentSurvey={currentSurvey}
              handleEditMode={handleEditMode}
              handleSave={handleSurveyInfoSave}
            />
          );
        case 'sections':
          return (
            <SurveySectionEditForm
              currentSection={() =>
                getSectionNameByCodeAndSurvey(
                  selectedSurveySectionData.Data.code,
                  currentSurvey,
                  allSections
                )
              }
              handleEditMode={handleEditMode}
              handleSave={handleSurveySectionInfoSave}
            />
          );
        case 's-type-question':
          return (
            <SurveySTypeQuestionEditForm
              handleEditMode={() => {
                setSectionSelected('sections');
                handleEditMode();
              }}
              handleSave={handleSurveySectionInfoSave}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  const handleSelectSection = (sectionCode, sectionName) => {
    const sectionData = allSections.find(
      (sec) => sec.Data.code.toLowerCase() === sectionCode.toLowerCase()
    );
    if (sectionData) {
      setSelectedSurveySectionData(sectionData);
    } else {
      setSelectedSurveySectionData({
        Id: -1,
        Data: {
          code: sectionCode,
          name: sectionName,
        },
      });
    }
  };

  function sortSections(a, b) {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  }

  useEffect(() => {
    if (selectedSurveySectionData) {
      dispatch(
        getSurveyQuestionsBySurveyIdAndSection(
          currentSurvey.id,
          selectedSurveySectionData.Data.code,
          storeJwt
        )
      );
    }
  }, [selectedSurveySectionData]);

  useEffect(() => {
    dispatch(getSurveysSummary(storeJwt));
    dispatch(getSections(storeJwt));
    dispatch(getSurveyMetadataDescription(storeJwt));
  }, [dispatch, storeJwt]);

  useEffect(() => {
    if (surveyId && surveys?.length) {
      const survey = surveys.find((s) => Number(s.id) === Number(surveyId));
      if (survey) {
        setCurrentSurvey(survey);
        setIsLoading(false);
      }
    }
  }, [surveyId, surveys]);

  if (isLoading) {
    return (
      <Container fluid>
        <Row>
          <Col xs="12" className="d-flex justify-content-center mt-5">
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="survey-wizard-container">
      <Row>
        {!isEdit && (
          <Col md="3" className="pt-5 survey-sidebar p-0 pr-5">
            <div className="pt-3 pb-4 pl-5">
              <p>Survey</p>
              <h2>{currentSurvey?.title}</h2>
            </div>
            <Alert
              variant="light"
              className={`alert-container alert-border ${
                sectionSelected === 'info' ? 'section-selected' : ''
              }`}
              onClick={() => setSectionSelected('info')}
            >
              <FontAwesomeIcon size="lg" className="mr-3" icon={faInfoCircle} />
              Survey information
            </Alert>
            <Alert
              variant="light"
              className={`alert-container alert-border ${
                ['sections', 's-type-question'].includes(sectionSelected) ? 'section-selected' : ''
              }`}
              onClick={() => setSectionSelected('sections')}
            >
              <FontAwesomeIcon size="lg" className="mr-3" icon={faFileAlt} />
              Sections
            </Alert>
            {['sections'].includes(sectionSelected) && (
              <Collapse in={['sections'].includes(sectionSelected)}>
                <Container className="pl-3 sidebar-section-container">
                  {currentSurvey.surveySummaryDetailsDto?.sectionCodes
                    .split(',')
                    .sort(sortSections)
                    .map((s) => {
                      const sectionName = getSectionNameByCodeAndSurvey(
                        s,
                        currentSurvey,
                        allSections
                      );

                      return (
                        <Alert
                          key={s.trim()}
                          variant="light"
                          className="alert-container alert-border"
                          onClick={() => handleSelectSection(s.trim(), sectionName.trim())}
                        >
                          {sectionName.trim()}
                        </Alert>
                      );
                    })}
                </Container>
              </Collapse>
            )}
            <Alert
              variant="light"
              className={`alert-container alert-border ${
                sectionSelected === 'filters' ? 'section-selected' : ''
              }`}
              onClick={() => setSectionSelected('filters')}
            >
              <FontAwesomeIcon size="lg" className="mr-3" icon={faSlidersH} />
              Other Filters
            </Alert>
          </Col>
        )}
        {renderEditFormSection()}
        <Col
          md="9"
          className={clsx([
            'survey-sections-container pt-5',
            { 'empty-section': !sectionSelected },
          ])}
        >
          {!sectionSelected && (
            <>
              <img src="/empty-section.svg" alt="select a section" />
              <h2>Choose a Section to edit</h2>
            </>
          )}
          {sectionSelected === 'info' && (
            <SurveyInformationSection
              currentSurvey={currentSurvey}
              onEditModeClick={handleEditMode}
            />
          )}
          {!isLoadingSurveyQuestions &&
            selectedSurveySectionData &&
            ['sections', 's-type-question'].includes(sectionSelected) && (
              <SurveySections
                sectionName={getSectionNameByCodeAndSurvey(
                  selectedSurveySectionData.Data.code,
                  currentSurvey,
                  allSections
                )}
                surveyQuestions={surveyQuestions}
                onEditModeClick={handleEditMode}
                onQuestionClick={() => setSectionSelected('s-type-question')}
              />
            )}
          {isLoadingSurveyQuestions && (
            <Container fluid>
              <Row>
                <Col className="d-flex min-vh-100 justify-content-center align-items-center">
                  <Spinner animation="border" />
                </Col>
              </Row>
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyEditWizard;

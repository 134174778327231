/* eslint-disable consistent-return */
import axios from 'axios';
import queryStringParser from 'query-string';
import { getHeaders } from '../actions/helper.action';
import { API_ROOT } from '../config/appConfig';

export const getMedatada = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/serie-metadata/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveyDetailApi = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/api/query/surveys/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveyDetailFiltersApi = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/api/query/surveys/${id}/filters`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveyVisualizationsApi = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/api/query/visualization?create=false&sid=${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const postSurveyVisualizationsApi = async (id, visualization, jwt) => {
  const res = await axios.post(
    `${API_ROOT}/api/query/visualization?sid=${id}`,
    {
      ...visualization,
    },
    {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (res.status === 200) {
    return res.data;
  }
};
export const getSurveyQuestionsCategoriesApi = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/api/query/question-category/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveySectionDetailsApi = async (
  id,
  visualization,
  jwt,
  moe = false,
  async = true
) => {
  if (visualization?.details) {
    const res = await axios.post(
      `${API_ROOT}/api/query/query/section/${id}/mli?moe=${moe}&asynch=${async}`,
      {
        ...visualization,
      },
      {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
          'Access-Control-Expose-Headers': 'location',
        },
      }
    );
    if (res.status === 202) {
      return res;
    }
  }
  return null;
};

export const getSurveyJobApi = async (id, jwt) => {
  if (id) {
    const res = await axios.post(
      `${API_ROOT}/asynch/jobs/${id}`,
      {},
      {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
  }
  return null;
};

export const getSurveysSummaryApi = async (jwt, queryParams) => {
  const queryParamsString = queryParams ? `?${queryStringParser.stringify(queryParams)}` : '';
  const res = await axios.get(`${API_ROOT}/api/query/surveys-summary${queryParamsString}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveyTypesApi = async (jwt) => {
  const res = await axios.get(`${API_ROOT}/api/query/survey-types`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveyShareALinkApi = async (idVisualization, idSurvey, idActiveElem, jwt) => {
  const res = await axios.get(
    `${API_ROOT}/api/query/visualization/${idVisualization}/share?active=${idActiveElem}&sid=${idSurvey}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const getShareALinkByShareTokenApi = async (sharedToken, jwt) => {
  const res = await axios.get(`${API_ROOT}/api/query/visualization/shared/${sharedToken}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getSurveyQuestionsBySurveyIdAndSectionApi = async (surveyId, sectionCode, jwt) => {
  const res = await axios.get(
    `${API_ROOT}/api/query/surveys/${surveyId}/sections/${sectionCode}/questions`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const updateSurveyCustomNSizeApi = async (surveyId, exactNSize, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/serie-metadata/${surveyId}/exact-nsize`,
    {
      exactNSize,
    },
    getHeaders('application/json', jwt)
  );
};

export const updateSurveyThresholdCustomNSizeApi = async (surveyId, threshold, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/serie-metadata/${surveyId}/min-respondents`,
    {
      minRespondents: Number(threshold),
    },
    getHeaders('application/json', jwt)
  );
};

export const updateSurveyMetadataApi = async (surveyId, metadata, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/serie-metadata/${surveyId}`,
    {
      Data: metadata,
    },
    getHeaders('application/json', jwt)
  );
};

export const updateSurveyMetadataDescriptionApi = async (surveyId, metadata, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/survey-description/${surveyId}`,
    {
      Data: metadata,
    },
    getHeaders('application/json', jwt)
  );
};

export const createSurveyMetadataDescriptionApi = async (metadata, jwt) => {
  await axios.post(
    `${API_ROOT}/admin-backend/survey-description`,
    {
      Data: metadata,
    },
    getHeaders('application/json', jwt)
  );
};

export const getSurveyMetadataDescriptionApi = async (jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/survey-description`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

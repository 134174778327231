import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import './styles.css';

const SurveyInformationSection = ({ currentSurvey, onEditModeClick }) => {
  const { surveyMetadataDescription } = useSelector((state) => state.surveys);

  const surveySummaryDetailsDto = useMemo(() => {
    const metaDescription = surveyMetadataDescription.find(
      (metaDesc) =>
        metaDesc.Data.type === currentSurvey.type &&
        metaDesc.Data.subtype === currentSurvey.subType &&
        metaDesc.Data.year.startsWith(currentSurvey.year.toString())
    );
    if (metaDescription) {
      return metaDescription;
    }
    return {
      Data: {
        description: 'Click to add Information',
        administered: 'Click to add Information',
      },
    };
  }, [currentSurvey, surveyMetadataDescription]);

  return (
    <Card className="survey-info-card" onClick={onEditModeClick}>
      <Card.Header className="survey-info-card-header">Survey Information</Card.Header>
      <Card.Body>
        <h4 className="mb-2">Survey Name</h4>
        <p>{currentSurvey.title}</p>
        <h4 className="mb-2">Description</h4>
        <p>{surveySummaryDetailsDto?.Data.description}</p>
        <hr />
        <h4 className="mb-2">Time Administered</h4>
        <p>{surveySummaryDetailsDto?.Data.administered}</p>
      </Card.Body>
    </Card>
  );
};

SurveyInformationSection.propTypes = {
  currentSurvey: PropTypes.shape({
    title: '',
    type: '',
    subType: '',
    year: '',
    surveySummaryDetailsDto: {
      description: '',
      dateAdministrated: '',
    },
  }).isRequired,
  onEditModeClick: PropTypes.func.isRequired,
};

SurveyInformationSection.defaultProps = {};

export default SurveyInformationSection;

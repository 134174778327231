export const SC = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7528, 32.4591],
            [-80.7404, 32.4866],
            [-80.6929, 32.5068],
            [-80.6903, 32.4589],
            [-80.714, 32.4471],
            [-80.7528, 32.4591],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '45013',
        GEOID: '4545013',
        NAME: 'Beaufort County School District within Beaufort Marine Corps Air Station',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 22732032,
        AWATER: 1261656,
        INTPTLAT: '+32.4742366',
        INTPTLON: '-080.7129348',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9519, 34.0236],
            [-80.8832, 34.0786],
            [-80.7782, 34.1024],
            [-80.7041, 34.0425],
            [-80.707, 34.0083],
            [-80.9336, 33.9799],
            [-80.9519, 34.0236],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '45079',
        GEOID: '4545079',
        NAME: 'Richland County School District 2 within Fort Jackson',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 211278101,
        AWATER: 2154219,
        INTPTLAT: '+34.0394315',
        INTPTLON: '-080.8250965',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9519, 34.0236],
            [-80.8832, 34.0786],
            [-80.7782, 34.1024],
            [-80.7041, 34.0425],
            [-80.707, 34.0083],
            [-80.9336, 33.9799],
            [-80.9519, 34.0236],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500005',
        NAME: 'Fort Jackson Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 211278101,
        AWATER: 2154219,
        INTPTLAT: '+34.0394315',
        INTPTLON: '-080.8250965',
        ELSDLEA: '00005',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7528, 32.4591],
            [-80.7404, 32.4866],
            [-80.6929, 32.5068],
            [-80.6903, 32.4589],
            [-80.714, 32.4471],
            [-80.7528, 32.4591],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500006',
        NAME: 'Beaufort Marine Corps Air Station School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 22732032,
        AWATER: 1261656,
        INTPTLAT: '+32.4742366',
        INTPTLON: '-080.7129348',
        ELSDLEA: '00006',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7903, 33.1808],
            [-80.6129, 33.2759],
            [-80.5028, 33.3345],
            [-80.4846, 33.28],
            [-80.3619, 33.2574],
            [-80.3291, 33.1476],
            [-80.2445, 33.0939],
            [-80.388, 33.0341],
            [-80.6238, 33.0669],
            [-80.7067, 33.1548],
            [-80.7903, 33.1808],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500002',
        NAME: 'Dorchester School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 881865377,
        AWATER: 2601912,
        INTPTLAT: '+33.1668984',
        INTPTLON: '-080.5038611',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.741, 34.2089],
            [-82.5785, 34.3133],
            [-82.314, 34.484],
            [-82.2959, 34.4564],
            [-82.2622, 34.3976],
            [-82.31, 34.3106],
            [-82.2477, 34.2196],
            [-82.2672, 34.1084],
            [-82.3269, 34.0641],
            [-82.5306, 34.0719],
            [-82.5946, 34.0138],
            [-82.741, 34.2089],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500690',
        NAME: 'Abbeville County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1249626676,
        AWATER: 52847561,
        INTPTLAT: '+34.2290410',
        INTPTLON: '-082.4540577',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.808, 33.8587],
            [-81.7634, 33.9501],
            [-81.6452, 33.9237],
            [-81.4348, 33.7733],
            [-81.2309, 33.6701],
            [-81.1873, 33.6529],
            [-81.3729, 33.4904],
            [-81.5163, 33.3823],
            [-81.6654, 33.3983],
            [-81.7788, 33.3264],
            [-81.8315, 33.2334],
            [-81.8521, 33.2473],
            [-81.9183, 33.3328],
            [-81.9341, 33.4683],
            [-82.0124, 33.5321],
            [-81.6518, 33.8145],
            [-81.808, 33.8587],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500720',
        NAME: 'Aiken County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2673262963,
        AWATER: 24407039,
        INTPTLAT: '+33.5914165',
        INTPTLON: '-081.6320470',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6136, 33.0957],
            [-81.5669, 33.1365],
            [-81.5234, 33.1534],
            [-81.46, 33.1368],
            [-81.4494, 33.1339],
            [-81.3649, 33.1081],
            [-81.1931, 33.1187],
            [-81.0937, 33.0408],
            [-81.0823, 33.0266],
            [-81.2196, 32.9534],
            [-81.2697, 32.867],
            [-81.3385, 32.7685],
            [-81.4051, 32.745],
            [-81.4265, 32.8408],
            [-81.5027, 32.9387],
            [-81.5418, 33.0457],
            [-81.614, 33.0953],
            [-81.6136, 33.0957],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500750',
        NAME: 'Allendale County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1056948589,
        AWATER: 11208070,
        INTPTLAT: '+32.9797838',
        INTPTLON: '-081.3634213',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6352, 34.7476],
            [-82.4867, 34.8195],
            [-82.4538, 34.6251],
            [-82.4204, 34.5591],
            [-82.574, 34.528],
            [-82.6453, 34.6148],
            [-82.6352, 34.7476],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500780',
        NAME: 'Anderson School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404881262,
        AWATER: 3825879,
        INTPTLAT: '+34.6708636',
        INTPTLON: '-082.5318859',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.574, 34.528],
            [-82.4204, 34.5591],
            [-82.3645, 34.516],
            [-82.2656, 34.516],
            [-82.314, 34.484],
            [-82.5785, 34.3133],
            [-82.5915, 34.4375],
            [-82.574, 34.528],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500810',
        NAME: 'Anderson School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396381501,
        AWATER: 4005984,
        INTPTLAT: '+34.4620214',
        INTPTLON: '-082.4784356',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8469, 34.4194],
            [-82.7105, 34.4558],
            [-82.5915, 34.4375],
            [-82.5785, 34.3133],
            [-82.741, 34.2089],
            [-82.7734, 34.2889],
            [-82.8469, 34.4194],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500840',
        NAME: 'Anderson School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413360517,
        AWATER: 21041296,
        INTPTLAT: '+34.3598682',
        INTPTLON: '-082.7013152',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9922, 34.4793],
            [-82.8553, 34.6092],
            [-82.845, 34.6189],
            [-82.8403, 34.6232],
            [-82.6352, 34.7476],
            [-82.6453, 34.6148],
            [-82.7358, 34.562],
            [-82.8555, 34.4418],
            [-82.9016, 34.4868],
            [-82.9922, 34.4793],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500870',
        NAME: 'Anderson School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373034013,
        AWATER: 54514458,
        INTPTLAT: '+34.5940255',
        INTPTLON: '-082.7886448',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8555, 34.4418],
            [-82.7358, 34.562],
            [-82.6453, 34.6148],
            [-82.574, 34.528],
            [-82.5915, 34.4375],
            [-82.7105, 34.4558],
            [-82.8469, 34.4194],
            [-82.8555, 34.4418],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500900',
        NAME: 'Anderson School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284079202,
        AWATER: 25423820,
        INTPTLAT: '+34.5122407',
        INTPTLON: '-082.7011673',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0587, 33.3578],
            [-80.9488, 33.308],
            [-80.7979, 33.1769],
            [-81.0823, 33.0266],
            [-81.0937, 33.0408],
            [-81.0772, 33.1162],
            [-81.1428, 33.1975],
            [-81.0587, 33.3578],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500930',
        NAME: 'Bamberg School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 628667818,
        AWATER: 3361004,
        INTPTLAT: '+33.1958690',
        INTPTLON: '-080.9945890',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2207, 33.2285],
            [-81.2227, 33.44],
            [-81.0587, 33.3578],
            [-81.1428, 33.1975],
            [-81.0772, 33.1162],
            [-81.0937, 33.0408],
            [-81.1931, 33.1187],
            [-81.2207, 33.2285],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4500960',
        NAME: 'Bamberg School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390147717,
        AWATER: 2324856,
        INTPTLAT: '+33.2482253',
        INTPTLON: '-081.1544956',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3571, 33.3117],
            [-81.2819, 33.4675],
            [-81.2227, 33.44],
            [-81.2207, 33.2285],
            [-81.3571, 33.3117],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501020',
        NAME: 'Barnwell School District 19',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235103513,
        AWATER: 1826869,
        INTPTLAT: '+33.3487417',
        INTPTLON: '-081.2739476',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5163, 33.3823],
            [-81.3729, 33.4904],
            [-81.2819, 33.4675],
            [-81.3571, 33.3117],
            [-81.4718, 33.3119],
            [-81.5163, 33.3823],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501050',
        NAME: 'Barnwell School District 29',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232729165,
        AWATER: 2329587,
        INTPTLAT: '+33.4068387',
        INTPTLON: '-081.4005476',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4718, 33.3119],
            [-81.3571, 33.3117],
            [-81.2207, 33.2285],
            [-81.1931, 33.1187],
            [-81.3649, 33.1081],
            [-81.4494, 33.1339],
            [-81.4817, 33.1993],
            [-81.4718, 33.3119],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501080',
        NAME: 'Barnwell School District 45',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475259640,
        AWATER: 4599975,
        INTPTLAT: '+33.2079231',
        INTPTLON: '-081.3704514',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8697, 32.6609],
            [-80.8263, 32.7043],
            [-80.6849, 32.6561],
            [-80.6443, 32.5944],
            [-80.5515, 32.5658],
            [-80.5029, 32.4957],
            [-80.3057, 32.3976],
            [-80.3923, 32.2803],
            [-80.5238, 32.2346],
            [-80.585, 32.1626],
            [-80.7455, 32.0787],
            [-80.9006, 32.1116],
            [-81.0085, 32.2121],
            [-80.9845, 32.3064],
            [-80.8415, 32.3851],
            [-80.8202, 32.4908],
            [-80.8697, 32.6609],
          ],
          [
            [-80.7528, 32.4591],
            [-80.714, 32.4471],
            [-80.6903, 32.4589],
            [-80.6929, 32.5068],
            [-80.7404, 32.4866],
            [-80.7528, 32.4591],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501110',
        NAME: 'Beaufort County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1469789296,
        AWATER: 897802780,
        INTPTLAT: '+32.3581474',
        INTPTLON: '-080.6893201',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2223, 33.4437],
            [-80.1916, 33.4475],
            [-80.1017, 33.4969],
            [-79.972, 33.5009],
            [-79.677, 33.3049],
            [-79.4467, 33.2135],
            [-79.5188, 33.1483],
            [-79.6433, 33.1235],
            [-79.8411, 32.9125],
            [-79.9564, 32.8598],
            [-80.0118, 32.8999],
            [-80.0555, 32.9995],
            [-80.1492, 33.0216],
            [-80.2445, 33.0939],
            [-80.3291, 33.1476],
            [-80.3619, 33.2574],
            [-80.2556, 33.3],
            [-80.2223, 33.4437],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501170',
        NAME: 'Berkeley County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2846196019,
        AWATER: 337556694,
        INTPTLAT: '+33.2077000',
        INTPTLON: '-079.9536554',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0388, 33.8484],
            [-81.0123, 33.8801],
            [-80.8076, 33.7742],
            [-80.6201, 33.7432],
            [-80.5341, 33.6439],
            [-80.4963, 33.5585],
            [-80.5523, 33.5654],
            [-80.659, 33.471],
            [-80.8076, 33.6041],
            [-80.9655, 33.6253],
            [-81.0429, 33.7071],
            [-81.0388, 33.8484],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501250',
        NAME: 'Calhoun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 987179168,
        AWATER: 29350862,
        INTPTLAT: '+33.6747804',
        INTPTLON: '-080.7803468',
        ELSDLEA: '',
        UNSDLEA: '01250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4013, 32.8585],
            [-80.1573, 32.8182],
            [-80.16, 32.8756],
            [-80.079, 32.9278],
            [-80.1492, 33.0216],
            [-80.0555, 32.9995],
            [-80.0118, 32.8999],
            [-79.9564, 32.8598],
            [-79.8411, 32.9125],
            [-79.6433, 33.1235],
            [-79.5188, 33.1483],
            [-79.4467, 33.2135],
            [-79.2933, 33.1029],
            [-79.2618, 33.0498],
            [-79.3223, 32.9561],
            [-79.4402, 32.9589],
            [-79.5347, 32.87],
            [-79.7601, 32.7383],
            [-79.7983, 32.6756],
            [-79.979, 32.5581],
            [-80.0889, 32.549],
            [-80.1215, 32.4992],
            [-80.2236, 32.4826],
            [-80.3363, 32.4936],
            [-80.4166, 32.5463],
            [-80.4033, 32.6516],
            [-80.4536, 32.7399],
            [-80.4013, 32.8585],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501440',
        NAME: 'Charleston County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2372842394,
        AWATER: 1144346152,
        INTPTLAT: '+32.8205891',
        INTPTLON: '-079.8962425',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7751, 35.1807],
            [-81.7681, 35.1805],
            [-81.3666, 35.1649],
            [-81.4071, 35.0725],
            [-81.4884, 35.0295],
            [-81.4573, 34.8393],
            [-81.7117, 34.9126],
            [-81.7549, 34.9327],
            [-81.8238, 35.0898],
            [-81.7751, 35.1807],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501500',
        NAME: 'Cherokee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 981877001,
        AWATER: 11566028,
        INTPTLAT: '+35.0497962',
        INTPTLON: '-081.6076469',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4785, 34.8215],
            [-81.1411, 34.8206],
            [-80.8982, 34.8207],
            [-80.8603, 34.6958],
            [-80.8964, 34.6318],
            [-80.8775, 34.5431],
            [-81.4227, 34.572],
            [-81.4785, 34.8215],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501530',
        NAME: 'Chester County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1503893313,
        AWATER: 14261786,
        INTPTLAT: '+34.6893450',
        INTPTLON: '-081.1612490',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5617, 34.8175],
            [-80.3204, 34.814],
            [-79.9276, 34.8066],
            [-79.9252, 34.7653],
            [-79.8039, 34.6091],
            [-79.83, 34.5319],
            [-79.8845, 34.4912],
            [-80.0454, 34.4926],
            [-80.2886, 34.3662],
            [-80.3276, 34.4978],
            [-80.4084, 34.6148],
            [-80.5617, 34.8175],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501560',
        NAME: 'Chesterfield County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2069616247,
        AWATER: 17254062,
        INTPTLAT: '+34.6370178',
        INTPTLON: '-080.1592269',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5341, 33.6439],
            [-80.4787, 33.7143],
            [-80.3922, 33.7226],
            [-80.2484, 33.6239],
            [-80.1916, 33.4475],
            [-80.2223, 33.4437],
            [-80.3545, 33.4304],
            [-80.4363, 33.4771],
            [-80.4963, 33.5585],
            [-80.5341, 33.6439],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501740',
        NAME: 'Clarendon School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464363890,
        AWATER: 203915519,
        INTPTLAT: '+33.5786358',
        INTPTLON: '-080.3613174',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1439, 33.8646],
            [-79.9932, 33.7045],
            [-80.1017, 33.4969],
            [-80.1916, 33.4475],
            [-80.2484, 33.6239],
            [-80.3922, 33.7226],
            [-80.3971, 33.7678],
            [-80.2712, 33.7863],
            [-80.1439, 33.8646],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501770',
        NAME: 'Clarendon School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 790012945,
        AWATER: 25410442,
        INTPTLAT: '+33.6755428',
        INTPTLON: '-080.1846227',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1439, 33.8646],
            [-79.9746, 33.9465],
            [-79.8756, 33.8852],
            [-79.9956, 33.7679],
            [-79.9932, 33.7045],
            [-80.1439, 33.8646],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501800',
        NAME: 'Clarendon School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317587230,
        AWATER: 434849,
        INTPTLAT: '+33.8237307',
        INTPTLON: '-079.9943951',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0823, 33.0266],
            [-80.7979, 33.1769],
            [-80.7903, 33.1808],
            [-80.7067, 33.1548],
            [-80.6238, 33.0669],
            [-80.388, 33.0341],
            [-80.4188, 32.9673],
            [-80.4013, 32.8585],
            [-80.4536, 32.7399],
            [-80.4033, 32.6516],
            [-80.4166, 32.5463],
            [-80.3363, 32.4936],
            [-80.2236, 32.4826],
            [-80.3057, 32.3976],
            [-80.5029, 32.4957],
            [-80.5515, 32.5658],
            [-80.6443, 32.5944],
            [-80.6849, 32.6561],
            [-80.8263, 32.7043],
            [-80.9002, 32.8328],
            [-80.9867, 32.9007],
            [-81.0823, 33.0266],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501830',
        NAME: 'Colleton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2736303053,
        AWATER: 198896959,
        INTPTLAT: '+32.8350184',
        INTPTLON: '-080.6552438',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2886, 34.3662],
            [-80.0454, 34.4926],
            [-79.8845, 34.4912],
            [-79.83, 34.5319],
            [-79.6852, 34.4007],
            [-79.6578, 34.3052],
            [-79.7296, 34.2952],
            [-79.9139, 34.1848],
            [-80.0728, 34.086],
            [-80.1586, 34.1616],
            [-80.1127, 34.197],
            [-80.1354, 34.2846],
            [-80.2887, 34.3641],
            [-80.2886, 34.3662],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501860',
        NAME: 'Darlington County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1453362943,
        AWATER: 14655104,
        INTPTLAT: '+34.3321854',
        INTPTLON: '-079.9621155',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5257, 34.4882],
            [-79.45, 34.621],
            [-79.0712, 34.2992],
            [-79.1282, 34.2537],
            [-79.3458, 34.2873],
            [-79.3322, 34.3337],
            [-79.5257, 34.4882],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501920',
        NAME: 'Dillon School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 659590654,
        AWATER: 3132312,
        INTPTLAT: '+34.4109965',
        INTPTLON: '-079.3189100',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5257, 34.4882],
            [-79.3322, 34.3337],
            [-79.3458, 34.2873],
            [-79.4807, 34.2881],
            [-79.5479, 34.2309],
            [-79.6334, 34.298],
            [-79.5257, 34.4882],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4501950',
        NAME: 'Dillon School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388932857,
        AWATER: 1413201,
        INTPTLAT: '+34.3583861',
        INTPTLON: '-079.4810187',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.388, 33.0341],
            [-80.2445, 33.0939],
            [-80.1492, 33.0216],
            [-80.079, 32.9278],
            [-80.16, 32.8756],
            [-80.1573, 32.8182],
            [-80.4013, 32.8585],
            [-80.4188, 32.9673],
            [-80.388, 33.0341],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502010',
        NAME: 'Dorchester School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602853023,
        AWATER: 4016111,
        INTPTLAT: '+32.9438046',
        INTPTLON: '-080.2469459',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.047, 33.9532],
            [-82.0083, 33.9616],
            [-81.8946, 33.9754],
            [-81.8704, 33.892],
            [-81.808, 33.8587],
            [-81.6518, 33.8145],
            [-82.0124, 33.5321],
            [-82.0282, 33.5449],
            [-82.1153, 33.5966],
            [-82.1728, 33.7652],
            [-82.1248, 33.8423],
            [-82.1557, 33.9293],
            [-82.047, 33.9532],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502070',
        NAME: 'Edgefield County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1296025451,
        AWATER: 16321055,
        INTPTLAT: '+33.7764976',
        INTPTLON: '-081.9682452',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4227, 34.572],
            [-80.8775, 34.5431],
            [-80.8792, 34.4578],
            [-80.7699, 34.3771],
            [-80.8259, 34.2688],
            [-81.0959, 34.2139],
            [-81.1783, 34.1746],
            [-81.3169, 34.2395],
            [-81.4212, 34.4538],
            [-81.4232, 34.4944],
            [-81.4227, 34.572],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502100',
        NAME: 'Fairfield County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1777449360,
        AWATER: 61135064,
        INTPTLAT: '+34.3956685',
        INTPTLON: '-081.1270007',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6578, 34.3052],
            [-79.6334, 34.298],
            [-79.5479, 34.2309],
            [-79.5718, 34.1837],
            [-79.5359, 34.0761],
            [-79.5754, 34.1096],
            [-79.7192, 34.0318],
            [-79.8935, 33.988],
            [-79.9047, 34.0021],
            [-79.8491, 34.1065],
            [-79.9139, 34.1848],
            [-79.7296, 34.2952],
            [-79.6578, 34.3052],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502130',
        NAME: 'Florence School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760808843,
        AWATER: 3971044,
        INTPTLAT: '+34.1529901',
        INTPTLON: '-079.7214128',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7192, 34.0318],
            [-79.5754, 34.1096],
            [-79.5359, 34.0761],
            [-79.4917, 33.9465],
            [-79.4497, 33.9126],
            [-79.5789, 33.8574],
            [-79.6866, 33.9496],
            [-79.7192, 34.0318],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502160',
        NAME: 'Florence School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390983867,
        AWATER: 2180052,
        INTPTLAT: '+33.9768664',
        INTPTLON: '-079.5923997',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9746, 33.9465],
            [-79.8935, 33.988],
            [-79.7192, 34.0318],
            [-79.6866, 33.9496],
            [-79.5789, 33.8574],
            [-79.6358, 33.818],
            [-79.7249, 33.8146],
            [-79.8756, 33.8852],
            [-79.9746, 33.9465],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502190',
        NAME: 'Florence School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496163401,
        AWATER: 257576,
        INTPTLAT: '+33.9189767',
        INTPTLON: '-079.7741230',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0728, 34.086],
            [-79.9139, 34.1848],
            [-79.8491, 34.1065],
            [-79.9047, 34.0021],
            [-80.0006, 34.0479],
            [-80.0728, 34.086],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502220',
        NAME: 'Florence School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211174984,
        AWATER: 330502,
        INTPTLAT: '+34.1036497',
        INTPTLON: '-079.9434262',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6358, 33.818],
            [-79.5789, 33.8574],
            [-79.4497, 33.9126],
            [-79.3243, 33.7991],
            [-79.3826, 33.8186],
            [-79.4965, 33.7754],
            [-79.6358, 33.818],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502250',
        NAME: 'Florence School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212763619,
        AWATER: 3004739,
        INTPTLAT: '+33.8387045',
        INTPTLON: '-079.5010197',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.317, 33.7799],
            [-79.2751, 33.7089],
            [-79.1903, 33.7058],
            [-79.1007, 33.5718],
            [-78.9268, 33.5719],
            [-79.0876, 33.3693],
            [-79.1202, 33.2469],
            [-79.0916, 33.1591],
            [-79.2618, 33.0498],
            [-79.2933, 33.1029],
            [-79.4467, 33.2135],
            [-79.677, 33.3049],
            [-79.5901, 33.4435],
            [-79.4357, 33.5727],
            [-79.4093, 33.6889],
            [-79.317, 33.7799],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502280',
        NAME: 'Georgetown County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2107094125,
        AWATER: 572624603,
        INTPTLAT: '+33.4175309',
        INTPTLON: '-079.2963325',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5749, 35.1455],
            [-82.3536, 35.1908],
            [-82.2695, 35.1996],
            [-82.2198, 35.0333],
            [-82.2238, 34.9075],
            [-82.177, 34.8148],
            [-82.1078, 34.7533],
            [-82.155, 34.6465],
            [-82.2071, 34.6563],
            [-82.2656, 34.516],
            [-82.3645, 34.516],
            [-82.4204, 34.5591],
            [-82.4538, 34.6251],
            [-82.4867, 34.8195],
            [-82.494, 34.8962],
            [-82.6256, 35.0647],
            [-82.7645, 35.0682],
            [-82.5749, 35.1455],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502310',
        NAME: 'Greenville County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2070723430,
        AWATER: 25665552,
        INTPTLAT: '+34.8832856',
        INTPTLON: '-082.3622096',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3269, 34.0641],
            [-82.2672, 34.1084],
            [-82.2477, 34.2196],
            [-82.31, 34.3106],
            [-82.1475, 34.3283],
            [-82.0587, 34.2792],
            [-82.051, 34.2695],
            [-82.0877, 34.1641],
            [-82.0054, 34.0474],
            [-81.9405, 34.0502],
            [-82.0083, 33.9616],
            [-82.047, 33.9532],
            [-82.2003, 33.9786],
            [-82.3269, 34.0641],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502340',
        NAME: 'Greenwood School District 50',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 827369072,
        AWATER: 7278479,
        INTPTLAT: '+34.1351839',
        INTPTLON: '-082.1658260',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2959, 34.4564],
            [-82.2346, 34.4991],
            [-82.1298, 34.3862],
            [-82.1475, 34.3283],
            [-82.31, 34.3106],
            [-82.2622, 34.3976],
            [-82.2959, 34.4564],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502370',
        NAME: 'Greenwood School District 51',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225280934,
        AWATER: 3939489,
        INTPTLAT: '+34.3962787',
        INTPTLON: '-082.2267208',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.051, 34.2695],
            [-81.945, 34.2031],
            [-81.8701, 34.1354],
            [-81.9405, 34.0502],
            [-82.0054, 34.0474],
            [-82.0877, 34.1641],
            [-82.051, 34.2695],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502400',
        NAME: 'Greenwood School District 52',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263106941,
        AWATER: 12192301,
        INTPTLAT: '+34.1569215',
        INTPTLON: '-081.9986522',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0823, 33.0266],
            [-80.9867, 32.9007],
            [-80.9002, 32.8328],
            [-80.8263, 32.7043],
            [-80.8697, 32.6609],
            [-81.0185, 32.7502],
            [-81.0703, 32.7103],
            [-81.1809, 32.8312],
            [-81.2697, 32.867],
            [-81.2196, 32.9534],
            [-81.0823, 33.0266],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502430',
        NAME: 'Hampton School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 794021012,
        AWATER: 1070299,
        INTPTLAT: '+32.8463518',
        INTPTLON: '-081.0426136',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4051, 32.745],
            [-81.3385, 32.7685],
            [-81.2697, 32.867],
            [-81.1809, 32.8312],
            [-81.0703, 32.7103],
            [-81.2813, 32.5565],
            [-81.3892, 32.5954],
            [-81.4275, 32.7022],
            [-81.4051, 32.745],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502460',
        NAME: 'Hampton School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 656101559,
        AWATER: 6206456,
        INTPTLAT: '+32.7041459',
        INTPTLON: '-081.2566367',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1282, 34.2537],
            [-79.0712, 34.2992],
            [-78.6503, 33.9442],
            [-78.4993, 33.8129],
            [-78.6914, 33.7553],
            [-78.8221, 33.6702],
            [-78.9268, 33.5719],
            [-79.1007, 33.5718],
            [-79.1903, 33.7058],
            [-79.3388, 33.9705],
            [-79.1282, 34.2537],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502490',
        NAME: 'Horry County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2936847582,
        AWATER: 313594600,
        INTPTLAT: '+33.9092694',
        INTPTLON: '-078.9766749',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2813, 32.5565],
            [-81.0703, 32.7103],
            [-81.0185, 32.7502],
            [-80.8697, 32.6609],
            [-80.8202, 32.4908],
            [-80.8415, 32.3851],
            [-80.9845, 32.3064],
            [-81.0085, 32.2121],
            [-80.9006, 32.1116],
            [-80.7455, 32.0787],
            [-80.7514, 32.0335],
            [-80.9268, 32.0417],
            [-81.012, 32.1002],
            [-81.1172, 32.1176],
            [-81.1446, 32.2261],
            [-81.1226, 32.307],
            [-81.2056, 32.4239],
            [-81.2004, 32.4683],
            [-81.2813, 32.5565],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502520',
        NAME: 'Jasper County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1697265693,
        AWATER: 114074607,
        INTPTLAT: '+32.4305900',
        INTPTLON: '-081.0216270',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8792, 34.4578],
            [-80.7433, 34.5399],
            [-80.6584, 34.4786],
            [-80.5489, 34.4933],
            [-80.5506, 34.5601],
            [-80.4084, 34.6148],
            [-80.3276, 34.4978],
            [-80.2886, 34.3662],
            [-80.2887, 34.3641],
            [-80.4205, 34.2931],
            [-80.4799, 34.1687],
            [-80.6162, 34.0996],
            [-80.7193, 34.069],
            [-80.8695, 34.1823],
            [-80.8259, 34.2688],
            [-80.7699, 34.3771],
            [-80.8792, 34.4578],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502550',
        NAME: 'Kershaw County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1881777521,
        AWATER: 35709176,
        INTPTLAT: '+34.3383561',
        INTPTLON: '-080.5908847',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9064, 35.0767],
            [-80.8403, 35.0017],
            [-80.782, 34.9358],
            [-80.7975, 34.8198],
            [-80.5617, 34.8175],
            [-80.4084, 34.6148],
            [-80.5506, 34.5601],
            [-80.5489, 34.4933],
            [-80.6584, 34.4786],
            [-80.7433, 34.5399],
            [-80.8792, 34.4578],
            [-80.8775, 34.5431],
            [-80.8964, 34.6318],
            [-80.8603, 34.6958],
            [-80.8982, 34.8207],
            [-80.8682, 34.9412],
            [-80.9064, 35.0767],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502580',
        NAME: 'Lancaster County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1422314512,
        AWATER: 15444741,
        INTPTLAT: '+34.6868182',
        INTPTLON: '-080.7036885',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.314, 34.484],
            [-82.2656, 34.516],
            [-82.2071, 34.6563],
            [-82.155, 34.6465],
            [-82.1078, 34.7533],
            [-82.0713, 34.7037],
            [-81.8997, 34.5991],
            [-81.9252, 34.4825],
            [-82.0095, 34.4237],
            [-82.0587, 34.2792],
            [-82.1475, 34.3283],
            [-82.1298, 34.3862],
            [-82.2346, 34.4991],
            [-82.2959, 34.4564],
            [-82.314, 34.484],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502610',
        NAME: 'Laurens School District 55',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 898927123,
        AWATER: 10879543,
        INTPTLAT: '+34.5246443',
        INTPTLON: '-082.0857174',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8997, 34.5991],
            [-81.854, 34.5945],
            [-81.6436, 34.5337],
            [-81.7826, 34.3651],
            [-81.8831, 34.312],
            [-81.945, 34.2031],
            [-82.051, 34.2695],
            [-82.0587, 34.2792],
            [-82.0095, 34.4237],
            [-81.9252, 34.4825],
            [-81.8997, 34.5991],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502640',
        NAME: 'Laurens School District 56',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 750993209,
        AWATER: 12675520,
        INTPTLAT: '+34.4322048',
        INTPTLON: '-081.8754570',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4799, 34.1687],
            [-80.4205, 34.2931],
            [-80.2887, 34.3641],
            [-80.1354, 34.2846],
            [-80.1127, 34.197],
            [-80.1586, 34.1616],
            [-80.0728, 34.086],
            [-80.0006, 34.0479],
            [-80.1804, 33.952],
            [-80.3217, 34.0804],
            [-80.4813, 34.1126],
            [-80.4799, 34.1687],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502670',
        NAME: 'Lee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1062372381,
        AWATER: 2715077,
        INTPTLAT: '+34.1586401',
        INTPTLON: '-080.2512086',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3319, 34.0651],
            [-81.134, 34.0274],
            [-81.1766, 33.8382],
            [-81.1943, 33.6906],
            [-81.2309, 33.6701],
            [-81.4348, 33.7733],
            [-81.4769, 33.8962],
            [-81.4274, 34.0088],
            [-81.3319, 34.0651],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502700',
        NAME: 'Lexington School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 869381778,
        AWATER: 54030347,
        INTPTLAT: '+33.8866190',
        INTPTLON: '-081.3080620',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.134, 34.0274],
            [-81.1038, 34.0233],
            [-81.017, 33.9309],
            [-81.0123, 33.8801],
            [-81.0388, 33.8484],
            [-81.1766, 33.8382],
            [-81.134, 34.0274],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502730',
        NAME: 'Lexington School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239101680,
        AWATER: 4644038,
        INTPTLAT: '+33.9171924',
        INTPTLON: '-081.1102579',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.472, 34.0766],
            [-81.3901, 34.0753],
            [-81.3319, 34.0651],
            [-81.4274, 34.0088],
            [-81.4769, 33.8962],
            [-81.4348, 33.7733],
            [-81.6452, 33.9237],
            [-81.472, 34.0766],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502760',
        NAME: 'Lexington School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355989112,
        AWATER: 30019066,
        INTPTLAT: '+33.9360946',
        INTPTLON: '-081.5099177',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2309, 33.6701],
            [-81.1943, 33.6906],
            [-81.1766, 33.8382],
            [-81.0388, 33.8484],
            [-81.0429, 33.7071],
            [-81.1869, 33.6529],
            [-81.1873, 33.6529],
            [-81.2309, 33.6701],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502790',
        NAME: 'Lexington School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305008388,
        AWATER: 2233495,
        INTPTLAT: '+33.7589416',
        INTPTLON: '-081.1045312',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3169, 34.2395],
            [-81.1783, 34.1746],
            [-81.1227, 34.1183],
            [-81.1038, 34.0233],
            [-81.134, 34.0274],
            [-81.3319, 34.0651],
            [-81.3901, 34.0753],
            [-81.4361, 34.1296],
            [-81.3169, 34.2395],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502820',
        NAME: 'Lexington School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337320652,
        AWATER: 72390499,
        INTPTLAT: '+34.1247675',
        INTPTLON: '-081.2718924',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9276, 34.8066],
            [-79.6929, 34.805],
            [-79.4618, 34.6304],
            [-79.45, 34.621],
            [-79.5257, 34.4882],
            [-79.6334, 34.298],
            [-79.6578, 34.3052],
            [-79.6852, 34.4007],
            [-79.83, 34.5319],
            [-79.8039, 34.6091],
            [-79.9252, 34.7653],
            [-79.9276, 34.8066],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4502970',
        NAME: 'Marlboro County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1242378725,
        AWATER: 14475689,
        INTPTLAT: '+34.6018053',
        INTPTLON: '-079.6779418',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5946, 34.0138],
            [-82.5306, 34.0719],
            [-82.3269, 34.0641],
            [-82.2003, 33.9786],
            [-82.047, 33.9532],
            [-82.1557, 33.9293],
            [-82.1248, 33.8423],
            [-82.1728, 33.7652],
            [-82.1153, 33.5966],
            [-82.2186, 33.6863],
            [-82.2475, 33.7526],
            [-82.3469, 33.8343],
            [-82.5645, 33.9557],
            [-82.5946, 34.0138],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503000',
        NAME: 'McCormick County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 930141784,
        AWATER: 89983085,
        INTPTLAT: '+33.8976052',
        INTPTLON: '-082.3161920',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6436, 34.5337],
            [-81.5532, 34.4771],
            [-81.4232, 34.4944],
            [-81.4212, 34.4538],
            [-81.3169, 34.2395],
            [-81.4361, 34.1296],
            [-81.3901, 34.0753],
            [-81.472, 34.0766],
            [-81.5389, 34.0817],
            [-81.7316, 34.1876],
            [-81.8534, 34.1801],
            [-81.8701, 34.1354],
            [-81.945, 34.2031],
            [-81.8831, 34.312],
            [-81.7826, 34.3651],
            [-81.6436, 34.5337],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503030',
        NAME: 'Newberry County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1631805482,
        AWATER: 44657974,
        INTPTLAT: '+34.2897302',
        INTPTLON: '-081.6000526',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3524, 34.7161],
            [-83.3242, 34.7875],
            [-83.1274, 34.9502],
            [-83.1086, 35.0007],
            [-83.0086, 35.0275],
            [-82.8975, 35.056],
            [-82.9386, 34.9809],
            [-82.9032, 34.9352],
            [-82.896, 34.7365],
            [-82.8403, 34.6232],
            [-82.845, 34.6189],
            [-82.8553, 34.6092],
            [-82.9922, 34.4793],
            [-83.0516, 34.4937],
            [-83.1037, 34.5366],
            [-83.3382, 34.6872],
            [-83.3524, 34.7161],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503060',
        NAME: 'Oconee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1622113934,
        AWATER: 122274812,
        INTPTLAT: '+34.7487713',
        INTPTLON: '-083.0615427',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.659, 33.471],
            [-80.5523, 33.5654],
            [-80.4963, 33.5585],
            [-80.4363, 33.4771],
            [-80.3545, 33.4304],
            [-80.2223, 33.4437],
            [-80.2556, 33.3],
            [-80.3619, 33.2574],
            [-80.4846, 33.28],
            [-80.5028, 33.3345],
            [-80.608, 33.3996],
            [-80.659, 33.471],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503150',
        NAME: 'Orangeburg School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 708903680,
        AWATER: 46537253,
        INTPTLAT: '+33.4010889',
        INTPTLON: '-080.4364160',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1873, 33.6529],
            [-81.1869, 33.6529],
            [-81.1996, 33.5725],
            [-81.0816, 33.5035],
            [-80.9992, 33.5615],
            [-80.8623, 33.4131],
            [-80.7683, 33.3554],
            [-80.7317, 33.2786],
            [-80.6129, 33.2759],
            [-80.7903, 33.1808],
            [-80.7979, 33.1769],
            [-80.9488, 33.308],
            [-81.0587, 33.3578],
            [-81.2227, 33.44],
            [-81.2819, 33.4675],
            [-81.3729, 33.4904],
            [-81.1873, 33.6529],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503180',
        NAME: 'Orangeburg School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1079984135,
        AWATER: 5232181,
        INTPTLAT: '+33.4064931',
        INTPTLON: '-080.9989077',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1869, 33.6529],
            [-81.0429, 33.7071],
            [-80.9655, 33.6253],
            [-80.8076, 33.6041],
            [-80.659, 33.471],
            [-80.608, 33.3996],
            [-80.5028, 33.3345],
            [-80.6129, 33.2759],
            [-80.7317, 33.2786],
            [-80.7683, 33.3554],
            [-80.8623, 33.4131],
            [-80.9992, 33.5615],
            [-81.0816, 33.5035],
            [-81.1996, 33.5725],
            [-81.1869, 33.6529],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503210',
        NAME: 'Orangeburg School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1075788485,
        AWATER: 4761314,
        INTPTLAT: '+33.4946981',
        INTPTLON: '-080.8524142',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8975, 35.056],
            [-82.7645, 35.0682],
            [-82.6256, 35.0647],
            [-82.494, 34.8962],
            [-82.4867, 34.8195],
            [-82.6352, 34.7476],
            [-82.8403, 34.6232],
            [-82.896, 34.7365],
            [-82.9032, 34.9352],
            [-82.9386, 34.9809],
            [-82.8975, 35.056],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503330',
        NAME: 'Pickens County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1285536060,
        AWATER: 40612589,
        INTPTLAT: '+34.8853801',
        INTPTLON: '-082.7233764',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0959, 34.2139],
            [-80.9519, 34.0236],
            [-80.9336, 33.9799],
            [-80.707, 34.0083],
            [-80.7041, 34.0425],
            [-80.6075, 34.0433],
            [-80.641, 33.9994],
            [-80.5977, 33.7775],
            [-80.6201, 33.7432],
            [-80.8076, 33.7742],
            [-81.0123, 33.8801],
            [-81.017, 33.9309],
            [-81.1038, 34.0233],
            [-81.1227, 34.1183],
            [-81.1783, 34.1746],
            [-81.0959, 34.2139],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503360',
        NAME: 'Richland School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1163439199,
        AWATER: 18564880,
        INTPTLAT: '+33.9611342',
        INTPTLON: '-080.8599622',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8259, 34.2688],
            [-80.8695, 34.1823],
            [-80.7193, 34.069],
            [-80.6162, 34.0996],
            [-80.6075, 34.0433],
            [-80.7041, 34.0425],
            [-80.7782, 34.1024],
            [-80.8832, 34.0786],
            [-80.9519, 34.0236],
            [-81.0959, 34.2139],
            [-80.8259, 34.2688],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503390',
        NAME: 'Richland School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407265774,
        AWATER: 6223415,
        INTPTLAT: '+34.1540000',
        INTPTLON: '-080.8983430',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9405, 34.0502],
            [-81.8701, 34.1354],
            [-81.8534, 34.1801],
            [-81.7316, 34.1876],
            [-81.5389, 34.0817],
            [-81.472, 34.0766],
            [-81.6452, 33.9237],
            [-81.7634, 33.9501],
            [-81.808, 33.8587],
            [-81.8704, 33.892],
            [-81.8946, 33.9754],
            [-82.0083, 33.9616],
            [-81.9405, 34.0502],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503460',
        NAME: 'Saluda County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 862615549,
        AWATER: 21838074,
        INTPTLAT: '+34.0424751',
        INTPTLON: '-081.7531116',
        ELSDLEA: '',
        UNSDLEA: '03460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2695, 35.1996],
            [-82.0177, 35.1889],
            [-82.0801, 35.0296],
            [-82.1116, 35.0055],
            [-82.2198, 35.0333],
            [-82.2695, 35.1996],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503480',
        NAME: 'Spartanburg School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356953341,
        AWATER: 6215929,
        INTPTLAT: '+35.1131948',
        INTPTLON: '-082.1471249',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0177, 35.1889],
            [-81.9693, 35.1874],
            [-81.7751, 35.1807],
            [-81.8238, 35.0898],
            [-81.8953, 35.0237],
            [-81.951, 34.9993],
            [-82.0801, 35.0296],
            [-82.0177, 35.1889],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503510',
        NAME: 'Spartanburg School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382747018,
        AWATER: 7058225,
        INTPTLAT: '+35.0949496',
        INTPTLON: '-081.9403573',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8953, 35.0237],
            [-81.8238, 35.0898],
            [-81.7549, 34.9327],
            [-81.7117, 34.9126],
            [-81.7831, 34.8374],
            [-81.8507, 34.8619],
            [-81.8396, 34.9686],
            [-81.8953, 35.0237],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503540',
        NAME: 'Spartanburg School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207357408,
        AWATER: 2633338,
        INTPTLAT: '+34.9527597',
        INTPTLON: '-081.8192747',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0351, 34.8387],
            [-81.8864, 34.7326],
            [-81.8153, 34.7145],
            [-81.854, 34.5945],
            [-81.8997, 34.5991],
            [-82.0713, 34.7037],
            [-82.1078, 34.7533],
            [-82.177, 34.8148],
            [-82.0351, 34.8387],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503570',
        NAME: 'Spartanburg School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417172290,
        AWATER: 3431070,
        INTPTLAT: '+34.7282109',
        INTPTLON: '-081.9827841',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2198, 35.0333],
            [-82.1116, 35.0055],
            [-82.045, 34.9312],
            [-82.0351, 34.8387],
            [-82.177, 34.8148],
            [-82.2238, 34.9075],
            [-82.2198, 35.0333],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503600',
        NAME: 'Spartanburg School District 5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274734845,
        AWATER: 5203856,
        INTPTLAT: '+34.9107618',
        INTPTLON: '-082.1287177',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1116, 35.0055],
            [-82.0801, 35.0296],
            [-81.951, 34.9993],
            [-81.9699, 34.9246],
            [-81.8507, 34.8619],
            [-81.7831, 34.8374],
            [-81.8153, 34.7145],
            [-81.8864, 34.7326],
            [-82.0351, 34.8387],
            [-82.045, 34.9312],
            [-82.1116, 35.0055],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503630',
        NAME: 'Spartanburg School District 6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 387052019,
        AWATER: 3718251,
        INTPTLAT: '+34.8636843',
        INTPTLON: '-081.9384526',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.951, 34.9993],
            [-81.8953, 35.0237],
            [-81.8396, 34.9686],
            [-81.8507, 34.8619],
            [-81.9699, 34.9246],
            [-81.951, 34.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503660',
        NAME: 'Spartanburg School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127398009,
        AWATER: 941129,
        INTPTLAT: '+34.9480414',
        INTPTLON: '-081.8996498',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8153, 34.7145],
            [-81.7831, 34.8374],
            [-81.7117, 34.9126],
            [-81.4573, 34.8393],
            [-81.4785, 34.8215],
            [-81.4227, 34.572],
            [-81.4232, 34.4944],
            [-81.5532, 34.4771],
            [-81.6436, 34.5337],
            [-81.854, 34.5945],
            [-81.8153, 34.7145],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503750',
        NAME: 'Union County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1331696478,
        AWATER: 4805061,
        INTPTLAT: '+34.6903905',
        INTPTLON: '-081.6159012',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9932, 33.7045],
            [-79.9956, 33.7679],
            [-79.8756, 33.8852],
            [-79.7249, 33.8146],
            [-79.6358, 33.818],
            [-79.4965, 33.7754],
            [-79.3826, 33.8186],
            [-79.3243, 33.7991],
            [-79.317, 33.7799],
            [-79.4093, 33.6889],
            [-79.4357, 33.5727],
            [-79.5901, 33.4435],
            [-79.677, 33.3049],
            [-79.972, 33.5009],
            [-80.1017, 33.4969],
            [-79.9932, 33.7045],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503780',
        NAME: 'Williamsburg County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2419464743,
        AWATER: 7469568,
        INTPTLAT: '+33.6264629',
        INTPTLON: '-079.7164752',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4071, 35.0725],
            [-81.1713, 35.0607],
            [-81.1252, 35.02],
            [-81.1834, 34.8946],
            [-81.1411, 34.8206],
            [-81.4785, 34.8215],
            [-81.4573, 34.8393],
            [-81.4884, 35.0295],
            [-81.4071, 35.0725],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503810',
        NAME: 'York School District 1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801956491,
        AWATER: 5302158,
        INTPTLAT: '+34.9553552',
        INTPTLON: '-081.2937748',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4071, 35.0725],
            [-81.3666, 35.1649],
            [-81.328, 35.1635],
            [-81.0332, 35.1487],
            [-81.0415, 35.0447],
            [-81.1252, 35.02],
            [-81.1713, 35.0607],
            [-81.4071, 35.0725],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503840',
        NAME: 'York School District 2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 345768692,
        AWATER: 18382734,
        INTPTLAT: '+35.1016974',
        INTPTLON: '-081.2129075',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1252, 35.02],
            [-81.0415, 35.0447],
            [-80.9469, 34.955],
            [-80.8682, 34.9412],
            [-80.8982, 34.8207],
            [-81.1411, 34.8206],
            [-81.1834, 34.8946],
            [-81.1252, 35.02],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503870',
        NAME: 'York School District 3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475655041,
        AWATER: 9997662,
        INTPTLAT: '+34.9073299',
        INTPTLON: '-081.0280608',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9064, 35.0767],
            [-80.8682, 34.9412],
            [-80.9469, 34.955],
            [-81.0415, 35.0447],
            [-80.9064, 35.0767],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503900',
        NAME: 'York School District 4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139614055,
        AWATER: 5458256,
        INTPTLAT: '+35.0265287',
        INTPTLON: '-080.9450168',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6075, 34.0433],
            [-80.6162, 34.0996],
            [-80.4799, 34.1687],
            [-80.4813, 34.1126],
            [-80.3217, 34.0804],
            [-80.1804, 33.952],
            [-80.0006, 34.0479],
            [-79.9047, 34.0021],
            [-79.8935, 33.988],
            [-79.9746, 33.9465],
            [-80.1439, 33.8646],
            [-80.2712, 33.7863],
            [-80.3971, 33.7678],
            [-80.3922, 33.7226],
            [-80.4787, 33.7143],
            [-80.5341, 33.6439],
            [-80.6201, 33.7432],
            [-80.5977, 33.7775],
            [-80.641, 33.9994],
            [-80.6075, 34.0433],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503902',
        NAME: 'Sumter County Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1722537600,
        AWATER: 44054050,
        INTPTLAT: '+33.9162531',
        INTPTLON: '-080.3822876',
        ELSDLEA: '',
        UNSDLEA: '03902',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5479, 34.2309],
            [-79.4807, 34.2881],
            [-79.3458, 34.2873],
            [-79.1282, 34.2537],
            [-79.3388, 33.9705],
            [-79.1903, 33.7058],
            [-79.2751, 33.7089],
            [-79.317, 33.7799],
            [-79.3243, 33.7991],
            [-79.4497, 33.9126],
            [-79.4917, 33.9465],
            [-79.5359, 34.0761],
            [-79.5718, 34.1837],
            [-79.5479, 34.2309],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4503908',
        NAME: 'Marion School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1267162776,
        AWATER: 12643194,
        INTPTLAT: '+34.0836195',
        INTPTLON: '-079.3540014',
        ELSDLEA: '',
        UNSDLEA: '03908',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8315, 33.2334],
            [-81.7788, 33.3264],
            [-81.6654, 33.3983],
            [-81.5163, 33.3823],
            [-81.4718, 33.3119],
            [-81.4817, 33.1993],
            [-81.4494, 33.1339],
            [-81.46, 33.1368],
            [-81.5234, 33.1534],
            [-81.5669, 33.1365],
            [-81.6136, 33.0957],
            [-81.614, 33.0953],
            [-81.7046, 33.1165],
            [-81.8315, 33.2334],
          ],
        ],
      },
      properties: {
        STATEFP: '45',
        SCSDLEA: '',
        GEOID: '4599997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 770929603,
        AWATER: 15555343,
        INTPTLAT: '+33.2573907',
        INTPTLON: '-081.6277716',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

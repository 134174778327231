import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SurveySectionEditForm = ({ handleEditMode, currentSection, handleSave }) => {
  const [title, setTitle] = useState(currentSection || '');
  const onSave = () => {
    handleSave({
      title,
    });
  };
  return (
    <Col md="3" className="p-3 survey-sidebar edit-mode">
      <span
        className="d-flex"
        onClick={handleEditMode}
        onKeyDown={handleEditMode}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
        Back to overview
      </span>
      <Col className="info-edit-form">
        <h4>Section Name</h4>
        <Form.Control
          onChangeCapture={(e) => setTitle(e.target.value)}
          placeholder="Section Name"
          type="text"
          maxLength={75}
          value={title}
        />
        <span className="hint">Character Limit: 75</span>
      </Col>
      <Button variant="primary" onClick={onSave} className="btn btn-custom dark">
        Save
      </Button>
    </Col>
  );
};

SurveySectionEditForm.propTypes = {
  currentSection: PropTypes.string.isRequired,
  handleEditMode: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

SurveySectionEditForm.defaultProps = {};

export default SurveySectionEditForm;

export default class EventInfo {
  constructor(category, action) {
    this.category = category;
    this.action = action;
  }

  getCategory() {
    return this.category;
  }

  getAction() {
    return this.action;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';

const ColumnConfig = ({ onClickHandler, isChecked }) => (
  <Tabs
    type="column"
    onClickHandler={onClickHandler}
    isChecked={isChecked}
  />
);


ColumnConfig.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
};


export default ColumnConfig;

import types from '../types';

const initialState = {
  actualStep: 0,
  steps: {},
  isFetchingData: false,
  visualization: undefined,
  dataSources: [],
  selectedMetadatasIds: [],
  allMetadatas: [],
  allSampleMetadata: [],
  allSampleDimension: [],
  getVisualizationsErr: false,
  updateChartSuccess: undefined,
};

const fileWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SET_FILE_WIZARD: {
      const { steps } = action.payload;
      return {
        ...state,
        steps,
      };
    }
    case types.reducerTypes.SUM_STEP_FILEWIZARD: {
      const actualStep = state.actualStep + 1;
      return {
        ...state,
        actualStep,
      };
    }

    case types.reducerTypes.DEC_STEP_FILEWIZARD: {
      const actualStep = state.actualStep - 1;
      return {
        ...state,
        actualStep,
      };
    }

    case types.reducerTypes.GET_ALL_METADATA: {
      const { allMetadatas } = action;
      return {
        ...state,
        allMetadatas,
        isFetchingData: false,
      };
    }

    case types.reducerTypes.GET_ALL_METADATA_ERR: {
      return {
        ...state,
        isFetchingData: false,
        getVisualizationsErr: true,
      };
    }

    case types.reducerTypes.SET_FILE_WIZARD_SELECTED_METADATAS: {
      return {
        ...state,
        selectedMetadatasIds: action.selectedMetadatasIds,
      };
    }

    case types.reducerTypes.SELECTED_METADATA: {
      const { id, value } = action.payload;
      const selectedMetadatasIds = [...state.selectedMetadatasIds];
      if (value) {
        selectedMetadatasIds.push(id);
      } else {
        const index = selectedMetadatasIds.indexOf(id);
        if (index !== -1) selectedMetadatasIds.splice(index, 1);
      }
      return {
        ...state,
        selectedMetadatasIds,
      };
    }

    case types.reducerTypes.FILEWIZARD_IS_FETCHING_DATA: {
      const { isFetchingData } = action;
      return {
        ...state,
        isFetchingData,
      };
    }

    case types.reducerTypes.GET_SAMPLE_METADATA: {
      const allSampleMetadata = [...action.allSampleMetadata];
      return {
        ...state,
        allSampleMetadata,
        isFetchingData: false,
      };
    }

    case types.reducerTypes.GET_DIMENSION_METADATA: {
      const allSampleDimension = [...action.allSampleDimension];
      return {
        ...state,
        allSampleDimension,
        isFetchingData: false,
      };
    }

    case types.reducerTypes.SET_FILEWIZARD_SAMPLES_VALUE: {
      const { id, metadataid } = action.payload;
      const dataSources = [...state.dataSources];

      // Initial Case - No metadata selected
      if (!dataSources.some((el) => el.metadataId === Number(metadataid))) {
        const obj = {
          metadataId: metadataid,
          samplesIds: [],
        };
        obj.samplesIds.push(id);
        dataSources.push(obj);

        return {
          ...state,
          dataSources,
        };
      }

      // Secondary Case - Metadatas selected - mutate object
      dataSources.forEach((el) => {
        if (el.metadataId === Number(metadataid)) {
          // eslint-disable-next-line no-param-reassign
          el.samplesIds = [id];
        }
      });

      return {
        ...state,
        dataSources,
      };
    }

    case types.reducerTypes.SET_FILE_WIZARD_SELECTED_DIMENSIONS: {
      const { configuration, metadataid } = action.payload;
      const dataSources = [...state.dataSources];

      // Initial Case - No metadata selected
      if (!dataSources.some((el) => el.metadataId === metadataid)) {
        return {
          ...state,
        };
      }

      dataSources.forEach((el) => {
        if (el.metadataId === metadataid) {
          // eslint-disable-next-line no-param-reassign
          el.configuration = configuration;
        }
      });

      return {
        ...state,
        dataSources,
      };
    }

    case types.reducerTypes.UPDATE_CHART_SUCCESS: {
      const { updateChartSuccess } = action;
      return {
        ...state,
        updateChartSuccess,
      };
    }

    case types.reducerTypes.SET_VISUALIZATION: {
      const { visualization } = action;
      return {
        ...state,
        visualization,
      };
    }

    case types.reducerTypes.RESET_FILE_WIZARD: {
      return {
        ...state,
        ...initialState,
      };
    }

    case types.reducerTypes.RESET_FILE_WIZARD_SELECTED_SAMPLES: {
      return {
        ...state,
        dataSources: [],
      };
    }

    case types.reducerTypes.SET_FILE_WIZARD_SELECTED_SAMPLES: {
      return {
        ...state,
        dataSources: action.dataSources,
      };
    }
    default:
  }
  return state;
};

export default fileWizardReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import InputCheckbox from '../InputCheckbox';
import { setCheckboxValue } from '../../../actions/wizard.action';
import './style.css';

const InputCheckboxContainer = ({ checkboxes, label }) => {
  const checkboxKey = label.toLowerCase();
  const dispatch = useDispatch();
  const onChangeCheckbox = (e) => {
    dispatch(setCheckboxValue(e.target, checkboxKey));
  };

  const renderCheckbox = () => {
    if (!checkboxes) return null;
    if (checkboxes.length <= 0) return null;
    const reactCheckbox = [];
    checkboxes.forEach((checkbox) => {
      reactCheckbox.push(
        <InputCheckbox
          required={checkbox.required}
          label={checkbox.label}
          name={checkbox.name}
          key={`checkbox-${checkbox.name}`}
          onChange={onChangeCheckbox}
        />,
      );
    });
    return reactCheckbox;
  };

  return (
    <div className="InputCheckboxContainer">
      <div className="InputCheckboxContainer-label">
        {label}
      </div>
      {renderCheckbox(checkboxes)}
    </div>
  );
};

InputCheckboxContainer.propTypes = {
  label: PropTypes.string,
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      required: PropTypes.bool,
    }),
  ).isRequired,
};

InputCheckboxContainer.defaultProps = {
  label: '',
};

export default InputCheckboxContainer;

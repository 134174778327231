export const CHART_TYPES = {
  COLUMN: 'Column chart',
  HORIZONTAL_BAR: 'Horizontal bar chart',
  STACKED_BAR: 'Stacked bar chart',
  DONUT: 'Donut chart',
  WAFFLE: 'Waffle chart',
  SPLIT_BAR_POS_NEG: 'Split bar +/-',
  COMBINED_BAR_POS_NEG: 'Combined bar +/-',
  HEAT_MAP: 'Heat map',
  RADAR: 'Radar chart',
};

export const HIGHCHARTS_TYPES = {
  BAR: 'bar',
  LINE: 'line',
  SPLINE: 'spline',
  AREA: 'area',
  AREASPLINE: 'areaspline',
  COLUMN: 'column',
  PIE: 'pie',
  SCATTER: 'scatter',
  GAUGE: 'gauge',
  AREARANGE: 'arearange',
  AREASPLINERANGE: 'areasplinerange',
  COLUMNRANGE: 'columnrange',
  HEATMAP: 'heatmap',
  MAP: 'map',
};

export const COMPONENT_ID_MAP = {
  ID_MAP: 1,
  ID_LINE: 2,
  ID_MLI_BAR: 3,
  ID_DEMOGRAPHICS: 4,
  ID_POINT_IN_TIME: 5,
  ID_MLI: 6,
};

export const HIGHCHARTS_TOOLTIP_DELAY = 250;

export const DASHBOARD_ALL_CATEGORIES = [
  'All students',
  'White',
  'Hispanic',
  'Black',
  'Asian',
  'Other',
  'ELL',
  'Students w/ Disabilities',
  'Low Income',
];

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Divider = ({ theme, spacing }) => (
  <div className={`divider ${theme} ${spacing}`} />
);

Divider.propTypes = {
  theme: PropTypes.string,
  spacing: PropTypes.string,
};

Divider.defaultProps = {
  theme: null,
  spacing: 'md',
};

export default Divider;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import jwtDecode from 'jwt-decode';
import { Container } from 'react-bootstrap';
import SectionTitle from '../Components/SectionTitle';
import SimpleText from '../Components/SimpleText';
import SimpleImage from '../Components/SimpleImage';
import SurveysContainer from '../Components/SurveysContainer/SurveysContainer';
import FetchScreen from '../Components/FetchScreen';
import { getAllSurveys } from '../actions/survey.action';
import { getOrgList, getOrgListByUser } from '../actions/organization.actions';

const Survey = () => {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt);
  const surveys = useSelector((state) => state.surveys.surveys);
  const isFetchingSurveys = useSelector((state) => state.surveys.isFetchingSurveys);
  const surveyFetchErr = useSelector((state) => state.errors.surveyFetchErr);
  const currentUser = jwtDecode(jwt);
  const { organizations: orgList, loading: orgLoading } = useSelector(
    (state) => state.organizations
  );

  useEffect(() => {
    dispatch(getAllSurveys(jwt));
    if (currentUser.role === 'ORGANIZATION_ADMIN') {
      dispatch(getOrgListByUser(currentUser.userId, jwt));
    } else {
      dispatch(getOrgList(null, jwt));
    }
  }, []);

  const handleOrgChange = (org) => {
    dispatch(getAllSurveys(jwt, org?.value));
  };

  const sortSurveyByCreatedDate = (surveyA, surveyB) =>
    new Date(surveyB.Data.createdAt) - new Date(surveyA.Data.createdAt);

  if (surveyFetchErr) {
    return (
      <FetchScreen
        title="There was an error while loading the page. Please try again later."
        type="error"
      />
    );
  }

  return (
    <div className="Survey">
      <SectionTitle
        title="Manage Survey Explorer"
        buttonText="Create new survey"
        buttonLink="/admin/survey/new"
        buttonTheme="dark"
      />
      {!orgLoading && (
        <Container>
          <div className="w-25 mb-3">
            <Select
              placeholder="Select Organization"
              options={orgList.map((l) => ({
                label: l.name,
                value: l.id,
              }))}
              isClearable
              classNamePrefix="react-select-org"
              onChange={handleOrgChange}
            />
          </div>
        </Container>
      )}

      {(isFetchingSurveys || orgLoading) && (
        <>
          <Container>
            <FetchScreen />
          </Container>
        </>
      )}

      {!isFetchingSurveys && !orgLoading && (
        <>
          {surveys && <SurveysContainer surveys={surveys.sort(sortSurveyByCreatedDate)} />}

          {surveys.length === 0 && (
            <>
              <SimpleImage
                src="/empty_survey.svg"
                alt="Survey Explorer"
                title="Survey Explorer"
                align="center"
                margin="0 0 50px 0"
              />
              <SimpleText
                text={[
                  'Welcome to the Manage Survey Explorer page!',
                  <br key="empty-case" />,
                  'Here you can create and edit surveys using your own data. To get started, click the "Create new survey" button above.  If you need help with this process, please contact the Bento support team.',
                ]}
                max={750}
                align="center"
                padding="0px 75px"
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Survey;

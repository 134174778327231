export const KY = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.10210000000001, 38.459379],
            [-84.073362, 38.457406999999996],
            [-84.01014599999999, 38.432871999999996],
            [-83.980031, 38.439159],
            [-83.94935699999999, 38.422956],
            [-83.90478999999999, 38.366222],
            [-83.86939799999999, 38.351714],
            [-83.835152, 38.317108],
            [-83.848355, 38.296949],
            [-83.977924, 38.192118],
            [-83.977367, 38.220048],
            [-84.027519, 38.218005],
            [-84.057214, 38.256828999999996],
            [-84.09532399999999, 38.259014],
            [-84.166253, 38.354912999999996],
            [-84.19404999999999, 38.37175],
            [-84.10210000000001, 38.459379],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04470',
        AFFGEOID: '9700000US2104470',
        GEOID: '2104470',
        NAME: 'Nicholas County School District',
        LSAD: '00',
        ALAND: 505485470,
        AWATER: 4305022,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.99160499999999, 38.593700999999996],
            [-83.90391694448489, 38.7681517942292],
            [-83.859028, 38.756792999999995],
            [-83.840595, 38.721911999999996],
            [-83.78362, 38.695640999999995],
            [-83.77216, 38.65815],
            [-83.668111, 38.628068],
            [-83.64591399999999, 38.637007],
            [-83.624679, 38.611553],
            [-83.641852, 38.525380999999996],
            [-83.721744, 38.503982],
            [-83.85928, 38.456348],
            [-83.930348, 38.492278],
            [-83.960962, 38.532872],
            [-83.9683, 38.58277],
            [-83.99160499999999, 38.593700999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03870',
        AFFGEOID: '9700000US2103870',
        GEOID: '2103870',
        NAME: 'Mason County School District',
        LSAD: '00',
        ALAND: 621927922,
        AWATER: 16277777,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.75943, 37.998731],
            [-83.718497, 38.003397],
            [-83.65929299999999, 38.028261],
            [-83.619671, 38.014046],
            [-83.57723299999999, 38.013313],
            [-83.574989, 38.058883],
            [-83.498846, 38.050619999999995],
            [-83.463408, 38.016974999999995],
            [-83.433942, 38.033974],
            [-83.44434299999999, 37.942468],
            [-83.496027, 37.890628],
            [-83.49508, 37.861906],
            [-83.55140999999999, 37.821615],
            [-83.590333, 37.818343],
            [-83.63105999999999, 37.825372],
            [-83.66342499999999, 37.835350999999996],
            [-83.66846, 37.852289999999996],
            [-83.748696, 37.895126999999995],
            [-83.768783, 37.918371],
            [-83.77448799999999, 37.972868999999996],
            [-83.75943, 37.998731],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04080',
        AFFGEOID: '9700000US2104080',
        GEOID: '2104080',
        NAME: 'Menifee County School District',
        LSAD: '00',
        ALAND: 527282799,
        AWATER: 6085060,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.433942, 38.033974],
            [-83.395153, 38.042105],
            [-83.37136799999999, 38.069559999999996],
            [-83.304656, 38.08977],
            [-83.263289, 38.115386],
            [-83.267544, 38.058949999999996],
            [-83.22289099999999, 38.043956],
            [-83.194445, 38.010649],
            [-83.146974, 38.000076],
            [-83.101689, 38.009751],
            [-83.04772799999999, 37.996818999999995],
            [-83.022115, 38.007047],
            [-83.010815, 37.96993],
            [-82.989014, 37.963968],
            [-82.96166, 37.912881999999996],
            [-82.98378199999999, 37.875867],
            [-83.004649, 37.859246999999996],
            [-83.02239, 37.874953999999995],
            [-83.056795, 37.870149999999995],
            [-83.08940899999999, 37.890339999999995],
            [-83.13132499999999, 37.892713],
            [-83.113277, 37.864121],
            [-83.207849, 37.819103999999996],
            [-83.225686, 37.79593],
            [-83.270387, 37.766844999999996],
            [-83.243859, 37.744769],
            [-83.26217199999999, 37.712657],
            [-83.287065, 37.742287999999995],
            [-83.334104, 37.758528999999996],
            [-83.327437, 37.7796],
            [-83.386437, 37.813674999999996],
            [-83.433048, 37.819112],
            [-83.45532, 37.842679],
            [-83.484258, 37.84391],
            [-83.49508, 37.861906],
            [-83.496027, 37.890628],
            [-83.44434299999999, 37.942468],
            [-83.433942, 38.033974],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04290',
        AFFGEOID: '9700000US2104290',
        GEOID: '2104290',
        NAME: 'Morgan County School District',
        LSAD: '00',
        ALAND: 987115107,
        AWATER: 6889992,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.295965, 37.391819],
            [-87.20917, 37.382238],
            [-87.17106799999999, 37.413589],
            [-87.099803, 37.416042],
            [-87.095156, 37.37593],
            [-87.13721, 37.341276],
            [-87.102578, 37.328621999999996],
            [-87.07058599999999, 37.370472],
            [-87.03056699999999, 37.363307],
            [-86.985957, 37.316566],
            [-86.98267, 37.269632],
            [-86.92322, 37.241493999999996],
            [-86.89926799999999, 37.212313],
            [-86.900384, 37.184371999999996],
            [-86.92688199999999, 37.16027],
            [-86.89752399999999, 37.118013999999995],
            [-86.897252, 37.09169],
            [-86.941391, 37.068981],
            [-86.976399, 37.073588],
            [-87.053164, 37.061019],
            [-87.11806899999999, 37.044731999999996],
            [-87.250256, 37.040557],
            [-87.259371, 37.072401],
            [-87.280587, 37.076941999999995],
            [-87.284329, 37.108024],
            [-87.312806, 37.113704],
            [-87.33374099999999, 37.157185999999996],
            [-87.307884, 37.19461],
            [-87.345742, 37.212333],
            [-87.35591099999999, 37.236581],
            [-87.387304, 37.255417],
            [-87.37196999999999, 37.29923],
            [-87.345192, 37.309855],
            [-87.330413, 37.344066],
            [-87.29958599999999, 37.368308],
            [-87.295965, 37.391819],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00081',
        AFFGEOID: '9700000US2100081',
        GEOID: '2100081',
        NAME: 'Muhlenberg County School District',
        LSAD: '00',
        ALAND: 1209734539,
        AWATER: 31906703,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.099803, 37.416042],
            [-87.103111, 37.4809],
            [-87.03826, 37.560803],
            [-87.006097, 37.580466],
            [-86.94829899999999, 37.630541],
            [-86.90487999999999, 37.629818],
            [-86.817514, 37.673814],
            [-86.866552, 37.70885],
            [-86.82342899999999, 37.737753999999995],
            [-86.63893499999999, 37.661877],
            [-86.640821, 37.619186],
            [-86.619503, 37.585487],
            [-86.59281, 37.56436],
            [-86.625322, 37.565887],
            [-86.66188, 37.541934],
            [-86.612095, 37.395123],
            [-86.75953299999999, 37.304099],
            [-86.775263, 37.325928],
            [-86.817168, 37.327616],
            [-86.81883599999999, 37.305282999999996],
            [-86.853405, 37.250495],
            [-86.87186799999999, 37.246609],
            [-86.89926799999999, 37.212313],
            [-86.92322, 37.241493999999996],
            [-86.98267, 37.269632],
            [-86.985957, 37.316566],
            [-87.03056699999999, 37.363307],
            [-87.07058599999999, 37.370472],
            [-87.102578, 37.328621999999996],
            [-87.13721, 37.341276],
            [-87.095156, 37.37593],
            [-87.099803, 37.416042],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04500',
        AFFGEOID: '9700000US2104500',
        GEOID: '2104500',
        NAME: 'Ohio County School District',
        LSAD: '00',
        ALAND: 1521032970,
        AWATER: 23187647,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.885262, 37.517688],
            [-83.830207, 37.486953],
            [-83.74298399999999, 37.494253],
            [-83.731864, 37.521158],
            [-83.68043899999999, 37.511984],
            [-83.657152, 37.536781],
            [-83.57918599999999, 37.506249],
            [-83.554192, 37.467048999999996],
            [-83.54165499999999, 37.398725999999996],
            [-83.548844, 37.371866],
            [-83.536271, 37.353297],
            [-83.547628, 37.334418],
            [-83.551777, 37.287217999999996],
            [-83.526907, 37.258528999999996],
            [-83.56163, 37.268353],
            [-83.609331, 37.301277999999996],
            [-83.638572, 37.344166],
            [-83.668138, 37.355232],
            [-83.678553, 37.337055],
            [-83.784975, 37.348717],
            [-83.811966, 37.370325],
            [-83.849961, 37.447848],
            [-83.88395299999999, 37.477335],
            [-83.885262, 37.517688],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04620',
        AFFGEOID: '9700000US2104620',
        GEOID: '2104620',
        NAME: 'Owsley County School District',
        LSAD: '00',
        ALAND: 511282948,
        AWATER: 2285945,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.399165, 37.169896],
            [-86.28175, 37.080597],
            [-86.21860699999999, 37.104029],
            [-86.192246, 37.076696],
            [-86.113512, 37.061071999999996],
            [-86.16673999999999, 36.934014999999995],
            [-86.203257, 36.913469],
            [-86.226309, 36.912633],
            [-86.26340499999999, 36.873134],
            [-86.40576899999999, 36.776187],
            [-86.40051799999999, 36.807226],
            [-86.583289, 36.830282],
            [-86.611586, 36.883057],
            [-86.67446199999999, 36.999766],
            [-86.61688099999999, 37.146578999999996],
            [-86.618158, 37.183906],
            [-86.581133, 37.185072999999996],
            [-86.541038, 37.176007999999996],
            [-86.49649, 37.190708],
            [-86.467309, 37.153456999999996],
            [-86.41631, 37.153090999999996],
            [-86.399165, 37.169896],
          ],
          [
            [-86.481295, 36.975001999999996],
            [-86.434663, 36.971140999999996],
            [-86.409919, 36.989084],
            [-86.445728, 37.00839],
            [-86.481295, 36.975001999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05730',
        AFFGEOID: '9700000US2105730',
        GEOID: '2105730',
        NAME: 'Warren County School District',
        LSAD: '00',
        ALAND: 1382469392,
        AWATER: 15435896,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.813413, 37.350645],
            [-87.593575, 37.461963],
            [-87.49866999999999, 37.487058999999995],
            [-87.37514499999999, 37.569987999999995],
            [-87.350016, 37.514142],
            [-87.361657, 37.488051999999996],
            [-87.351089, 37.424929],
            [-87.295965, 37.391819],
            [-87.29958599999999, 37.368308],
            [-87.330413, 37.344066],
            [-87.345192, 37.309855],
            [-87.37196999999999, 37.29923],
            [-87.387304, 37.255417],
            [-87.35591099999999, 37.236581],
            [-87.345742, 37.212333],
            [-87.307884, 37.19461],
            [-87.33374099999999, 37.157185999999996],
            [-87.359639, 37.156934],
            [-87.37467199999999, 37.133679],
            [-87.48518399999999, 37.126905],
            [-87.521609, 37.105343999999995],
            [-87.67624699999999, 37.149412999999996],
            [-87.688572, 37.179848],
            [-87.70795299999999, 37.177634],
            [-87.774694, 37.198127],
            [-87.77154399999999, 37.214127],
            [-87.80390899999999, 37.243199],
            [-87.79435199999999, 37.255002],
            [-87.79707499999999, 37.315911],
            [-87.851728, 37.318667999999995],
            [-87.813413, 37.350645],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02860',
        AFFGEOID: '9700000US2102860',
        GEOID: '2102860',
        NAME: 'Hopkins County School District',
        LSAD: '00',
        ALAND: 1396442733,
        AWATER: 31991764,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.489813, 36.895413],
            [-83.408614, 36.890218],
            [-83.384557, 36.925913],
            [-83.32391899999999, 36.940709],
            [-83.26002, 36.968951],
            [-83.230339, 37.002739],
            [-83.188002, 37.00573],
            [-83.182104, 37.021115],
            [-83.160825, 37.020739],
            [-83.12209, 37.000997],
            [-83.153607, 36.97163],
            [-83.150402, 36.954163],
            [-82.965561, 37.001894],
            [-82.93896699999999, 37.015223999999996],
            [-82.8691826690104, 36.9741830452543],
            [-82.856099, 36.952470999999996],
            [-82.86003199999999, 36.925241],
            [-82.879492, 36.889085],
            [-82.970253, 36.857686],
            [-83.07258999999999, 36.854589],
            [-83.101792, 36.829088999999996],
            [-83.098492, 36.814288999999995],
            [-83.13324399999999, 36.783212999999996],
            [-83.136395, 36.743088],
            [-83.194597, 36.739487],
            [-83.307103, 36.711386999999995],
            [-83.386099, 36.686589],
            [-83.423707, 36.667384999999996],
            [-83.4610151191699, 36.6648782480975],
            [-83.459459, 36.697387],
            [-83.500891, 36.733253999999995],
            [-83.48581399999999, 36.783293],
            [-83.511729, 36.807362],
            [-83.48170999999999, 36.849134],
            [-83.493867, 36.863507999999996],
            [-83.489813, 36.895413],
          ],
          [
            [-83.351187, 36.832302],
            [-83.29771699999999, 36.838074],
            [-83.296746, 36.864922],
            [-83.32340599999999, 36.864368999999996],
            [-83.351187, 36.832302],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02540',
        AFFGEOID: '9700000US2102540',
        GEOID: '2102540',
        NAME: 'Harlan County School District',
        LSAD: '00',
        ALAND: 1192213158,
        AWATER: 5773458,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.68952999999999, 38.488776],
            [-82.653679, 38.48997],
            [-82.672094, 38.465595],
            [-82.68952999999999, 38.488776],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01800',
        AFFGEOID: '9700000US2101800',
        GEOID: '2101800',
        NAME: 'Fairview Independent School District',
        LSAD: '00',
        ALAND: 7163283,
        AWATER: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.547628, 37.334418],
            [-83.504134, 37.341845],
            [-83.484738, 37.371822],
            [-83.445487, 37.370484999999995],
            [-83.405402, 37.338927],
            [-83.38165, 37.338266],
            [-83.36824399999999, 37.358070999999995],
            [-83.33222599999999, 37.379732],
            [-83.269786, 37.398779999999995],
            [-83.21473499999999, 37.443538],
            [-83.19973399999999, 37.41466],
            [-83.124348, 37.404973],
            [-83.110136, 37.320774],
            [-83.12724899999999, 37.268401],
            [-83.11652099999999, 37.240665],
            [-83.070358, 37.248521],
            [-83.05430799999999, 37.215818],
            [-83.032983, 37.216831],
            [-83.000142, 37.19571],
            [-83.035611, 37.15724],
            [-83.06077599999999, 37.115243],
            [-83.04077099999999, 37.080059],
            [-83.071912, 37.042272],
            [-83.05439299999999, 37.018328],
            [-83.103464, 36.993423],
            [-83.12209, 37.000997],
            [-83.160825, 37.020739],
            [-83.182104, 37.021115],
            [-83.186886, 37.034349999999996],
            [-83.166024, 37.071169],
            [-83.169191, 37.102438],
            [-83.194749, 37.109119],
            [-83.22689, 37.154548999999996],
            [-83.214542, 37.176078],
            [-83.274053, 37.202180999999996],
            [-83.344161, 37.260624],
            [-83.35177399999999, 37.295682],
            [-83.397915, 37.316564],
            [-83.44237199999999, 37.287455],
            [-83.47182099999999, 37.291247999999996],
            [-83.483729, 37.251733],
            [-83.507886, 37.235876999999995],
            [-83.526907, 37.258528999999996],
            [-83.551777, 37.287217999999996],
            [-83.547628, 37.334418],
          ],
          [
            [-83.21386299999999, 37.238648],
            [-83.20454699999999, 37.221691],
            [-83.161332, 37.23576],
            [-83.153758, 37.251523],
            [-83.193473, 37.279443],
            [-83.21386299999999, 37.238648],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04770',
        AFFGEOID: '9700000US2104770',
        GEOID: '2104770',
        NAME: 'Perry County School District',
        LSAD: '00',
        ALAND: 860332852,
        AWATER: 7228998,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.732693, 37.271502],
            [-82.710805, 37.285323],
            [-82.672375, 37.306841],
            [-82.65198099999999, 37.351461],
            [-82.666169, 37.370025999999996],
            [-82.628565, 37.380734],
            [-82.588627, 37.446259],
            [-82.584711, 37.466574],
            [-82.597786, 37.502749],
            [-82.623576, 37.504051],
            [-82.598278, 37.642226],
            [-82.614538, 37.670322],
            [-82.56356099999999, 37.684844],
            [-82.520561, 37.694305],
            [-82.455816, 37.668686],
            [-82.409708, 37.679280999999996],
            [-82.386422, 37.697685],
            [-82.413123, 37.712151999999996],
            [-82.375963, 37.739664999999995],
            [-82.33304432026151, 37.7409688362322],
            [-82.29611799999999, 37.686174],
            [-82.257111, 37.656749],
            [-82.226111, 37.653092],
            [-82.21349, 37.625408],
            [-82.181398, 37.626798],
            [-82.156718, 37.59279],
            [-82.13197699999999, 37.593537],
            [-82.121985, 37.552763],
            [-82.073246, 37.555023],
            [-82.06441799999999, 37.53687],
            [-82.007412, 37.533677],
            [-81.968012, 37.538035],
            [-82.124854, 37.427271999999995],
            [-82.316028, 37.294879],
            [-82.350948, 37.267077],
            [-82.449164, 37.243908],
            [-82.498858, 37.227044],
            [-82.5653752160821, 37.1960922172527],
            [-82.62892099999999, 37.247803999999995],
            [-82.682245, 37.228128999999996],
            [-82.69988599999999, 37.255427],
            [-82.732693, 37.271502],
          ],
          [
            [-82.574089, 37.473732],
            [-82.527307, 37.445475],
            [-82.492013, 37.453648],
            [-82.467717, 37.472937],
            [-82.486294, 37.504819],
            [-82.50847499999999, 37.493289],
            [-82.572786, 37.483641],
            [-82.574089, 37.473732],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04800',
        AFFGEOID: '9700000US2104800',
        GEOID: '2104800',
        NAME: 'Pike County School District',
        LSAD: '00',
        ALAND: 2000074086,
        AWATER: 4756854,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.96631, 37.930768],
            [-83.87866199999999, 37.905062],
            [-83.812494, 37.903283],
            [-83.768783, 37.918371],
            [-83.748696, 37.895126999999995],
            [-83.66846, 37.852289999999996],
            [-83.66342499999999, 37.835350999999996],
            [-83.63105999999999, 37.825372],
            [-83.707974, 37.716463],
            [-83.7221, 37.71642],
            [-83.722651, 37.728947],
            [-83.768963, 37.741096999999996],
            [-83.777592, 37.756775999999995],
            [-83.830347, 37.765246999999995],
            [-83.876863, 37.752089],
            [-83.91179, 37.766732],
            [-83.963262, 37.800135999999995],
            [-83.962554, 37.844643999999995],
            [-84.001967, 37.837592],
            [-84.005854, 37.864098],
            [-83.99176899999999, 37.917775999999996],
            [-83.96631, 37.930768],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04890',
        AFFGEOID: '9700000US2104890',
        GEOID: '2104890',
        NAME: 'Powell County School District',
        LSAD: '00',
        ALAND: 463555578,
        AWATER: 2957593,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.286461, 38.067028],
            [-84.08052699999999, 38.115117999999995],
            [-83.96631, 37.930768],
            [-83.99176899999999, 37.917775999999996],
            [-84.005854, 37.864098],
            [-84.001967, 37.837592],
            [-84.076027, 37.824176],
            [-84.080337, 37.853066],
            [-84.115877, 37.852683999999996],
            [-84.128221, 37.867160999999996],
            [-84.167954, 37.863008],
            [-84.186846, 37.879232],
            [-84.22191099999999, 37.871991],
            [-84.261377, 37.89489],
            [-84.27240599999999, 37.917546],
            [-84.33739299999999, 37.892007],
            [-84.34315099999999, 37.918076],
            [-84.323043, 37.964966],
            [-84.322463, 38.000878],
            [-84.282629, 38.019103],
            [-84.286461, 38.067028],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01200',
        AFFGEOID: '9700000US2101200',
        GEOID: '2101200',
        NAME: 'Clark County School District',
        LSAD: '00',
        ALAND: 653878894,
        AWATER: 6972358,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3025990430289, 37.8985582968574],
            [-87.220944, 37.849134],
            [-87.158878, 37.837871],
            [-87.1289623088189, 37.786109253925495],
            [-87.138691, 37.739011],
            [-87.10898, 37.735464],
            [-87.089456, 37.774558999999996],
            [-87.09931322990019, 37.7848406571326],
            [-87.07073199999999, 37.801936999999995],
            [-87.033444, 37.906593],
            [-86.9788342958521, 37.9302329010012],
            [-86.982158, 37.895537],
            [-86.931707, 37.90974],
            [-86.893616, 37.882037],
            [-86.912531, 37.837481],
            [-86.854035, 37.840810999999995],
            [-86.81710000000001, 37.791588999999995],
            [-86.82342899999999, 37.737753999999995],
            [-86.866552, 37.70885],
            [-86.817514, 37.673814],
            [-86.90487999999999, 37.629818],
            [-86.94829899999999, 37.630541],
            [-87.006097, 37.580466],
            [-87.03826, 37.560803],
            [-87.057272, 37.570640999999995],
            [-87.095524, 37.566953999999996],
            [-87.26160999999999, 37.622009],
            [-87.408547, 37.683563],
            [-87.394093, 37.723994999999995],
            [-87.37075899999999, 37.737814],
            [-87.32077, 37.742518],
            [-87.290173, 37.760636],
            [-87.307378, 37.81521],
            [-87.323402, 37.819238999999996],
            [-87.3025990430289, 37.8985582968574],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01470',
        AFFGEOID: '9700000US2101470',
        GEOID: '2101470',
        NAME: 'Daviess County School District',
        LSAD: '00',
        ALAND: 1167646348,
        AWATER: 44953980,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.861633, 36.531822999999996],
            [-88.84354499999999, 36.512285999999996],
            [-88.8435621254683, 36.502809705461],
            [-88.8993449449784, 36.5022123263371],
            [-88.898985, 36.540023],
            [-88.861633, 36.531822999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02070',
        AFFGEOID: '9700000US2102070',
        GEOID: '2102070',
        NAME: 'Fulton Independent School District',
        LSAD: '00',
        ALAND: 16137195,
        AWATER: 298548,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.297416, 36.945931],
            [-84.260387, 36.962924],
            [-84.232129, 36.943501],
            [-84.201296, 36.946735],
            [-84.20015699999999, 36.899001999999996],
            [-84.18104799999999, 36.897971],
            [-84.12951, 36.918093],
            [-84.086393, 36.916703999999996],
            [-84.08964999999999, 36.94142],
            [-84.08434199999999, 36.936765],
            [-84.08536199999999, 36.904821],
            [-84.07044599999999, 36.890592],
            [-84.06517699999999, 36.853432999999995],
            [-84.027546, 36.818607],
            [-83.983674, 36.800838999999996],
            [-83.958534, 36.727244999999996],
            [-83.87750299999999, 36.687259],
            [-83.878615, 36.680335],
            [-83.956234, 36.645491],
            [-83.924712, 36.602672999999996],
            [-83.9307607839439, 36.5876942581124],
            [-84.2271936534631, 36.5921799356786],
            [-84.239598, 36.613354],
            [-84.271529, 36.63395],
            [-84.307598, 36.676809],
            [-84.319769, 36.709756],
            [-84.32265199999999, 36.758669],
            [-84.350006, 36.780681],
            [-84.338346, 36.825277],
            [-84.30410499999999, 36.912459],
            [-84.297416, 36.945931],
          ],
          [
            [-84.196986, 36.729468],
            [-84.162318, 36.721148],
            [-84.13753, 36.761419],
            [-84.196452, 36.747276],
            [-84.196986, 36.729468],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05880',
        AFFGEOID: '9700000US2105880',
        GEOID: '2105880',
        NAME: 'Whitley County School District',
        LSAD: '00',
        ALAND: 1070745906,
        AWATER: 15230194,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.948544, 37.503167],
            [-82.92546, 37.486525],
            [-82.831473, 37.466989999999996],
            [-82.81052199999999, 37.411531],
            [-82.781926, 37.429463],
            [-82.764055, 37.420268],
            [-82.754763, 37.373681],
            [-82.764316, 37.352379],
            [-82.748166, 37.31679],
            [-82.710805, 37.285323],
            [-82.732693, 37.271502],
            [-82.801672, 37.261232],
            [-82.819862, 37.268706],
            [-82.874726, 37.242143],
            [-82.909137, 37.206241999999996],
            [-82.95831, 37.190034],
            [-82.977429, 37.173434],
            [-83.000142, 37.19571],
            [-83.032983, 37.216831],
            [-83.05430799999999, 37.215818],
            [-83.070358, 37.248521],
            [-83.11652099999999, 37.240665],
            [-83.12724899999999, 37.268401],
            [-83.110136, 37.320774],
            [-83.124348, 37.404973],
            [-83.134711, 37.457181],
            [-83.093144, 37.498101999999996],
            [-83.094663, 37.518285999999996],
            [-83.050201, 37.525314],
            [-83.024774, 37.507129],
            [-82.973344, 37.497811999999996],
            [-82.948544, 37.503167],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03120',
        AFFGEOID: '9700000US2103120',
        GEOID: '2103120',
        NAME: 'Knott County School District',
        LSAD: '00',
        ALAND: 910427521,
        AWATER: 3453309,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.733368, 37.002103],
            [-87.711005, 37.021527999999996],
            [-87.680617, 37.149239],
            [-87.67624699999999, 37.149412999999996],
            [-87.521609, 37.105343999999995],
            [-87.48518399999999, 37.126905],
            [-87.37467199999999, 37.133679],
            [-87.359639, 37.156934],
            [-87.33374099999999, 37.157185999999996],
            [-87.312806, 37.113704],
            [-87.284329, 37.108024],
            [-87.280587, 37.076941999999995],
            [-87.259371, 37.072401],
            [-87.33597953375501, 36.6415426709465],
            [-87.4363215449599, 36.640751890131504],
            [-87.46828699999999, 36.685375],
            [-87.50546899999999, 36.683799],
            [-87.524778, 36.652091999999996],
            [-87.57108699999999, 36.646415999999995],
            [-87.666697, 36.647],
            [-87.680396, 36.669484],
            [-87.682749, 36.703365999999995],
            [-87.67376999999999, 36.755855],
            [-87.671871, 36.878892],
            [-87.659724, 36.966397],
            [-87.733368, 37.002103],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01150',
        AFFGEOID: '9700000US2101150',
        GEOID: '2101150',
        NAME: 'Christian County School District',
        LSAD: '00',
        ALAND: 1803759447,
        AWATER: 16755327,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.61735999999999, 38.644335],
            [-84.59020799999999, 38.657874],
            [-84.54536399999999, 38.662493999999995],
            [-84.521344, 38.637119999999996],
            [-84.543644, 38.616873],
            [-84.572682, 38.614941],
            [-84.61735999999999, 38.644335],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05940',
        AFFGEOID: '9700000US2105940',
        GEOID: '2105940',
        NAME: 'Williamstown Independent School District',
        LSAD: '00',
        ALAND: 30941470,
        AWATER: 190175,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.502932, 37.328801],
            [-84.446719, 37.485893],
            [-84.37443999999999, 37.472663],
            [-84.354327, 37.50176],
            [-84.347805, 37.539063999999996],
            [-84.26297699999999, 37.515569],
            [-84.248397, 37.524797],
            [-84.199489, 37.522858],
            [-84.203552, 37.486177],
            [-84.159285, 37.417369],
            [-84.16948099999999, 37.385405999999996],
            [-84.129105, 37.357389999999995],
            [-84.13789799999999, 37.319759],
            [-84.14855, 37.302451999999995],
            [-84.178782, 37.299279],
            [-84.216241, 37.280256],
            [-84.217827, 37.242432],
            [-84.25431499999999, 37.239081999999996],
            [-84.268594, 37.179183],
            [-84.289076, 37.15174],
            [-84.30047499999999, 37.16394],
            [-84.446286, 37.27905],
            [-84.502932, 37.328801],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05070',
        AFFGEOID: '9700000US2105070',
        GEOID: '2105070',
        NAME: 'Rockcastle County School District',
        LSAD: '00',
        ALAND: 819848737,
        AWATER: 4016362,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.199489, 37.522858],
            [-84.171836, 37.557549],
            [-84.090364, 37.566227],
            [-84.045023, 37.563573],
            [-83.965244, 37.581421],
            [-83.939846, 37.573173],
            [-83.90557, 37.542856],
            [-83.885262, 37.517688],
            [-83.88395299999999, 37.477335],
            [-83.849961, 37.447848],
            [-83.811966, 37.370325],
            [-83.784975, 37.348717],
            [-83.791826, 37.330318999999996],
            [-83.821693, 37.331255],
            [-83.863192, 37.306571],
            [-83.899891, 37.266794999999995],
            [-83.94371799999999, 37.250551],
            [-84.005606, 37.253343],
            [-84.056477, 37.269762],
            [-84.107254, 37.308071999999996],
            [-84.13789799999999, 37.319759],
            [-84.129105, 37.357389999999995],
            [-84.16948099999999, 37.385405999999996],
            [-84.159285, 37.417369],
            [-84.203552, 37.486177],
            [-84.199489, 37.522858],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02940',
        AFFGEOID: '9700000US2102940',
        GEOID: '2102940',
        NAME: 'Jackson County School District',
        LSAD: '00',
        ALAND: 894055728,
        AWATER: 3407438,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.989014, 37.963968],
            [-82.965828, 37.993953],
            [-82.93960299999999, 37.998981],
            [-82.83158499999999, 37.967869],
            [-82.736583, 37.916326999999995],
            [-82.67693299999999, 37.892328],
            [-82.612393, 37.879272],
            [-82.670812, 37.852091],
            [-82.644807, 37.822939],
            [-82.674443, 37.786988],
            [-82.65337, 37.77768],
            [-82.65571899999999, 37.752854],
            [-82.64075, 37.720182],
            [-82.672055, 37.725525999999995],
            [-82.72321099999999, 37.749081],
            [-82.76317399999999, 37.756225],
            [-82.845548, 37.729518999999996],
            [-82.87083299999999, 37.729141],
            [-82.89175999999999, 37.708693],
            [-82.94076299999999, 37.716338],
            [-82.948257, 37.719871],
            [-82.950582, 37.794734],
            [-82.98974299999999, 37.817814999999996],
            [-82.98152, 37.835412],
            [-83.004649, 37.859246999999996],
            [-82.98378199999999, 37.875867],
            [-82.96166, 37.912881999999996],
            [-82.989014, 37.963968],
          ],
          [
            [-82.83873899999999, 37.810206],
            [-82.803309, 37.794255],
            [-82.790376, 37.819700000000005],
            [-82.836838, 37.826676],
            [-82.83873899999999, 37.810206],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03060',
        AFFGEOID: '9700000US2103060',
        GEOID: '2103060',
        NAME: 'Johnson County School District',
        LSAD: '00',
        ALAND: 663400807,
        AWATER: 5362505,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.94371799999999, 37.250551],
            [-83.899891, 37.266794999999995],
            [-83.863192, 37.306571],
            [-83.821693, 37.331255],
            [-83.791826, 37.330318999999996],
            [-83.784975, 37.348717],
            [-83.678553, 37.337055],
            [-83.668138, 37.355232],
            [-83.638572, 37.344166],
            [-83.609331, 37.301277999999996],
            [-83.56163, 37.268353],
            [-83.526907, 37.258528999999996],
            [-83.507886, 37.235876999999995],
            [-83.505113, 37.204212999999996],
            [-83.55130299999999, 37.16751],
            [-83.551237, 37.119056],
            [-83.53528399999999, 37.103096],
            [-83.54560599999999, 37.04859],
            [-83.51146, 37.003417],
            [-83.523037, 36.998487],
            [-83.50908299999999, 36.938508999999996],
            [-83.58952099999999, 36.955785999999996],
            [-83.648416, 36.96375],
            [-83.700092, 36.986582],
            [-83.74491499999999, 37.025697],
            [-83.774896, 37.015237],
            [-83.785765, 37.034464],
            [-83.822696, 37.026021],
            [-83.83398199999999, 37.043338],
            [-83.871315, 37.054919],
            [-83.88771899999999, 37.075119],
            [-83.862526, 37.091361],
            [-83.885673, 37.118767],
            [-83.925405, 37.111768],
            [-83.96881599999999, 37.175059999999995],
            [-83.94076, 37.203275999999995],
            [-83.94371799999999, 37.250551],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01230',
        AFFGEOID: '9700000US2101230',
        GEOID: '2101230',
        NAME: 'Clay County School District',
        LSAD: '00',
        ALAND: 1215344365,
        AWATER: 4763609,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.725168, 38.195426],
            [-84.714158, 38.175379],
            [-84.65570799999999, 38.179466999999995],
            [-84.650277, 38.144824],
            [-84.625079, 38.116397],
            [-84.660135, 38.003744999999995],
            [-84.709918, 37.861951999999995],
            [-84.765204, 37.847834999999996],
            [-84.759052, 37.874272999999995],
            [-84.794991, 37.914562],
            [-84.796615, 37.935759999999995],
            [-84.819816, 37.950944],
            [-84.79632699999999, 37.970088],
            [-84.81806, 37.973391],
            [-84.81714199999999, 37.999998],
            [-84.84656799999999, 38.040777],
            [-84.842989, 38.070006],
            [-84.827418, 38.082944],
            [-84.864908, 38.116926],
            [-84.86480399999999, 38.141373],
            [-84.725168, 38.195426],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '06000',
        AFFGEOID: '9700000US2106000',
        GEOID: '2106000',
        NAME: 'Woodford County School District',
        LSAD: '00',
        ALAND: 489064244,
        AWATER: 8307143,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.091492, 36.956575],
            [-84.061764, 36.976267],
            [-84.01570699999999, 36.983436],
            [-83.959138, 36.977273],
            [-83.93578099999999, 36.995768999999996],
            [-83.922336, 37.036412999999996],
            [-83.90554499999999, 37.032441],
            [-83.871315, 37.054919],
            [-83.83398199999999, 37.043338],
            [-83.822696, 37.026021],
            [-83.785765, 37.034464],
            [-83.774896, 37.015237],
            [-83.74491499999999, 37.025697],
            [-83.700092, 36.986582],
            [-83.648416, 36.96375],
            [-83.58952099999999, 36.955785999999996],
            [-83.56674, 36.932423],
            [-83.581094, 36.904666999999996],
            [-83.63076, 36.888145],
            [-83.64240699999999, 36.866246],
            [-83.682879, 36.854582],
            [-83.691771, 36.838915],
            [-83.738146, 36.808521999999996],
            [-83.781212, 36.802693],
            [-83.798248, 36.785197],
            [-83.80916599999999, 36.748103],
            [-83.828898, 36.743263999999996],
            [-83.839421, 36.714037],
            [-83.87750299999999, 36.687259],
            [-83.958534, 36.727244999999996],
            [-83.983674, 36.800838999999996],
            [-84.027546, 36.818607],
            [-84.06517699999999, 36.853432999999995],
            [-84.07044599999999, 36.890592],
            [-84.08536199999999, 36.904821],
            [-84.08434199999999, 36.936765],
            [-84.08964999999999, 36.94142],
            [-84.091492, 36.956575],
          ],
          [
            [-83.904918, 36.874592],
            [-83.901347, 36.859119],
            [-83.87852199999999, 36.859673],
            [-83.883017, 36.880981999999996],
            [-83.904918, 36.874592],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03150',
        AFFGEOID: '9700000US2103150',
        GEOID: '2103150',
        NAME: 'Knox County School District',
        LSAD: '00',
        ALAND: 994220416,
        AWATER: 3589052,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.507886, 37.235876999999995],
            [-83.483729, 37.251733],
            [-83.47182099999999, 37.291247999999996],
            [-83.44237199999999, 37.287455],
            [-83.397915, 37.316564],
            [-83.35177399999999, 37.295682],
            [-83.344161, 37.260624],
            [-83.274053, 37.202180999999996],
            [-83.214542, 37.176078],
            [-83.22689, 37.154548999999996],
            [-83.194749, 37.109119],
            [-83.169191, 37.102438],
            [-83.166024, 37.071169],
            [-83.186886, 37.034349999999996],
            [-83.182104, 37.021115],
            [-83.188002, 37.00573],
            [-83.230339, 37.002739],
            [-83.26002, 36.968951],
            [-83.32391899999999, 36.940709],
            [-83.384557, 36.925913],
            [-83.408614, 36.890218],
            [-83.489813, 36.895413],
            [-83.50908299999999, 36.938508999999996],
            [-83.523037, 36.998487],
            [-83.51146, 37.003417],
            [-83.54560599999999, 37.04859],
            [-83.53528399999999, 37.103096],
            [-83.551237, 37.119056],
            [-83.55130299999999, 37.16751],
            [-83.505113, 37.204212999999996],
            [-83.507886, 37.235876999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03330',
        AFFGEOID: '9700000US2103330',
        GEOID: '2103330',
        NAME: 'Leslie County School District',
        LSAD: '00',
        ALAND: 1038181754,
        AWATER: 9331182,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.12209, 37.000997],
            [-83.103464, 36.993423],
            [-83.05439299999999, 37.018328],
            [-83.071912, 37.042272],
            [-83.04077099999999, 37.080059],
            [-83.06077599999999, 37.115243],
            [-83.035611, 37.15724],
            [-83.000142, 37.19571],
            [-82.977429, 37.173434],
            [-82.95831, 37.190034],
            [-82.909137, 37.206241999999996],
            [-82.874726, 37.242143],
            [-82.819862, 37.268706],
            [-82.801672, 37.261232],
            [-82.732693, 37.271502],
            [-82.69988599999999, 37.255427],
            [-82.682245, 37.228128999999996],
            [-82.68667599999999, 37.19381],
            [-82.652222, 37.193833],
            [-82.67000399999999, 37.166211],
            [-82.6535640843278, 37.151172506618096],
            [-82.72209699999999, 37.120168],
            [-82.72225399999999, 37.057947999999996],
            [-82.782144, 37.008241999999996],
            [-82.822684, 37.004128],
            [-82.8691826690104, 36.9741830452543],
            [-82.93896699999999, 37.015223999999996],
            [-82.965561, 37.001894],
            [-83.150402, 36.954163],
            [-83.153607, 36.97163],
            [-83.12209, 37.000997],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03360',
        AFFGEOID: '9700000US2103360',
        GEOID: '2103360',
        NAME: 'Letcher County School District',
        LSAD: '00',
        ALAND: 810505892,
        AWATER: 2761036,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.52129, 37.554342999999996],
            [-85.450493, 37.685947999999996],
            [-85.40178399999999, 37.730658],
            [-85.373938, 37.699833],
            [-85.373898, 37.673606],
            [-85.34069699999999, 37.651423],
            [-85.311239, 37.651776],
            [-85.25694899999999, 37.630908],
            [-85.030172, 37.63127],
            [-85.03967399999999, 37.545232],
            [-85.044502, 37.419115999999995],
            [-85.07377199999999, 37.413669],
            [-85.1288, 37.453261],
            [-85.16956499999999, 37.464003999999996],
            [-85.222177, 37.444776999999995],
            [-85.246195, 37.420361],
            [-85.272278, 37.417282],
            [-85.287965, 37.436788],
            [-85.355364, 37.470974],
            [-85.381385, 37.465508],
            [-85.42532299999999, 37.474385999999996],
            [-85.46625, 37.465595],
            [-85.503001, 37.54909],
            [-85.52129, 37.554342999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03780',
        AFFGEOID: '9700000US2103780',
        GEOID: '2103780',
        NAME: 'Marion County School District',
        LSAD: '00',
        ALAND: 888390643,
        AWATER: 9967834,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.64591399999999, 38.637007],
            [-83.626922, 38.679387],
            [-83.52095299999999, 38.703044999999996],
            [-83.468059, 38.67547],
            [-83.366661, 38.658536999999995],
            [-83.322383, 38.630615],
            [-83.317542, 38.609241999999995],
            [-83.29419299999999, 38.596588],
            [-83.239515, 38.628588],
            [-83.20245299999999, 38.616955999999995],
            [-83.142836, 38.625076],
            [-83.102746, 38.677316],
            [-83.05310399999999, 38.695831],
            [-83.0308908096218, 38.7255636114076],
            [-83.038095, 38.699377999999996],
            [-83.024093, 38.682744],
            [-83.053578, 38.657109999999996],
            [-83.08079, 38.610465],
            [-83.071034, 38.595023],
            [-83.12313999999999, 38.535229],
            [-83.146652, 38.530239],
            [-83.16641899999999, 38.503965],
            [-83.184514, 38.495689],
            [-83.200485, 38.453818],
            [-83.2371, 38.426576],
            [-83.230598, 38.339507999999995],
            [-83.282237, 38.324242],
            [-83.32573699999999, 38.329677],
            [-83.341347, 38.319521],
            [-83.416928, 38.396155],
            [-83.452848, 38.381799],
            [-83.485787, 38.405066],
            [-83.579081, 38.430166],
            [-83.576791, 38.457187999999995],
            [-83.593335, 38.489031],
            [-83.624755, 38.503637999999995],
            [-83.641852, 38.525380999999996],
            [-83.624679, 38.611553],
            [-83.64591399999999, 38.637007],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03390',
        AFFGEOID: '9700000US2103390',
        GEOID: '2103390',
        NAME: 'Lewis County School District',
        LSAD: '00',
        ALAND: 1250540456,
        AWATER: 32492708,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.40178399999999, 37.730658],
            [-85.373323, 37.739581],
            [-85.374954, 37.764507],
            [-85.355835, 37.781428],
            [-85.319419, 37.78776],
            [-85.30748299999999, 37.819378],
            [-85.246509, 37.853922],
            [-85.205394, 37.863178999999995],
            [-85.181399, 37.892427999999995],
            [-85.15279699999999, 37.897661],
            [-85.106192, 37.909247],
            [-85.088441, 37.887646],
            [-85.03052799999999, 37.891538],
            [-85.00068499999999, 37.854794],
            [-85.025021, 37.678854],
            [-85.030172, 37.63127],
            [-85.25694899999999, 37.630908],
            [-85.311239, 37.651776],
            [-85.34069699999999, 37.651423],
            [-85.373898, 37.673606],
            [-85.373938, 37.699833],
            [-85.40178399999999, 37.730658],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05760',
        AFFGEOID: '9700000US2105760',
        GEOID: '2105760',
        NAME: 'Washington County School District',
        LSAD: '00',
        ALAND: 769931069,
        AWATER: 10176683,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.56606099999999, 37.075204],
            [-88.521436, 37.065584],
            [-88.45894799999999, 37.073796],
            [-88.424403, 37.152428],
            [-88.44776399999999, 37.203527],
            [-88.47175299999999, 37.220155],
            [-88.509328, 37.26213],
            [-88.515939, 37.284043],
            [-88.486947, 37.339596],
            [-88.476592, 37.386874999999996],
            [-88.456, 37.408482],
            [-88.408808, 37.425216],
            [-88.35850600784909, 37.4048167301695],
            [-88.299454, 37.377939999999995],
            [-88.293628, 37.335376],
            [-88.21898, 37.273145],
            [-88.19624999999999, 37.219482],
            [-88.220338, 37.183012999999995],
            [-88.195768, 37.173221999999996],
            [-88.193519, 37.147256],
            [-88.210909, 37.130426],
            [-88.217537, 37.094848],
            [-88.231145, 37.081575],
            [-88.216295, 37.056225],
            [-88.22687499999999, 37.028473],
            [-88.209221, 36.999976],
            [-88.240371, 36.981911],
            [-88.313133, 37.051566],
            [-88.362158, 37.063947],
            [-88.426519, 37.050079],
            [-88.482957, 37.022566],
            [-88.526674, 37.035247],
            [-88.56606099999999, 37.075204],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03510',
        AFFGEOID: '9700000US2103510',
        GEOID: '2103510',
        NAME: 'Livingston County School District',
        LSAD: '00',
        ALAND: 810999158,
        AWATER: 75641317,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.810284, 37.649257999999996],
            [-84.792423, 37.661953],
            [-84.742774, 37.657802],
            [-84.726115, 37.625434],
            [-84.804637, 37.632807],
            [-84.810284, 37.649257999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01440',
        AFFGEOID: '9700000US2101440',
        GEOID: '2101440',
        NAME: 'Danville Independent School District',
        LSAD: '00',
        ALAND: 32489716,
        AWATER: 204279,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4935547013056, 39.1027204798606],
            [-84.480943, 39.11676],
            [-84.4407547312217, 39.1097786325792],
            [-84.462971, 39.102123999999996],
            [-84.48996199999999, 39.102044],
            [-84.4935547013056, 39.1027204798606],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01530',
        AFFGEOID: '9700000US2101530',
        GEOID: '2101530',
        NAME: 'Dayton Independent School District',
        LSAD: '00',
        ALAND: 3312134,
        AWATER: 2182921,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.358024, 36.9594],
            [-84.347364, 36.990195],
            [-84.31706, 36.999333],
            [-84.331786, 37.02438],
            [-84.317027, 37.040492],
            [-84.325223, 37.062179],
            [-84.290724, 37.110375],
            [-84.289076, 37.15174],
            [-84.268594, 37.179183],
            [-84.25431499999999, 37.239081999999996],
            [-84.217827, 37.242432],
            [-84.216241, 37.280256],
            [-84.178782, 37.299279],
            [-84.14855, 37.302451999999995],
            [-84.13789799999999, 37.319759],
            [-84.107254, 37.308071999999996],
            [-84.056477, 37.269762],
            [-84.005606, 37.253343],
            [-83.94371799999999, 37.250551],
            [-83.94076, 37.203275999999995],
            [-83.96881599999999, 37.175059999999995],
            [-83.925405, 37.111768],
            [-83.885673, 37.118767],
            [-83.862526, 37.091361],
            [-83.88771899999999, 37.075119],
            [-83.871315, 37.054919],
            [-83.90554499999999, 37.032441],
            [-83.922336, 37.036412999999996],
            [-83.93578099999999, 36.995768999999996],
            [-83.959138, 36.977273],
            [-84.01570699999999, 36.983436],
            [-84.061764, 36.976267],
            [-84.091492, 36.956575],
            [-84.120938, 36.968742999999996],
            [-84.150645, 36.967487999999996],
            [-84.17422599999999, 36.940301999999996],
            [-84.201296, 36.946735],
            [-84.232129, 36.943501],
            [-84.260387, 36.962924],
            [-84.297416, 36.945931],
            [-84.345872, 36.939496],
            [-84.358024, 36.9594],
          ],
          [
            [-84.137979, 37.204557],
            [-84.126548, 37.174344],
            [-84.086387, 37.177864],
            [-84.096395, 37.201792999999995],
            [-84.137979, 37.204557],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03210',
        AFFGEOID: '9700000US2103210',
        GEOID: '2103210',
        NAME: 'Laurel County School District',
        LSAD: '00',
        ALAND: 1110571819,
        AWATER: 25180612,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.022115, 38.007047],
            [-83.00223799999999, 38.034600999999995],
            [-82.941991, 38.064164],
            [-82.942005, 38.077585],
            [-82.88892299999999, 38.111847],
            [-82.92480499999999, 38.175114],
            [-82.883764, 38.196027],
            [-82.853983, 38.190156],
            [-82.81227, 38.220016],
            [-82.794206, 38.243772],
            [-82.76259, 38.234685],
            [-82.72379699999999, 38.243777],
            [-82.699471, 38.261913],
            [-82.61430399999999, 38.266078],
            [-82.604625, 38.247302999999995],
            [-82.59843699999999, 38.217393],
            [-82.61134299999999, 38.171548],
            [-82.639054, 38.171113999999996],
            [-82.637306, 38.13905],
            [-82.585696, 38.107003],
            [-82.58403899999999, 38.090663],
            [-82.551259, 38.070799],
            [-82.51781, 38.002479],
            [-82.46425699999999, 37.983412],
            [-82.471801, 37.959119],
            [-82.4972999070143, 37.9454908576785],
            [-82.534871, 37.962786],
            [-82.58086399999999, 37.954074],
            [-82.595612, 37.890682],
            [-82.612393, 37.879272],
            [-82.67693299999999, 37.892328],
            [-82.736583, 37.916326999999995],
            [-82.83158499999999, 37.967869],
            [-82.93960299999999, 37.998981],
            [-82.965828, 37.993953],
            [-82.989014, 37.963968],
            [-83.010815, 37.96993],
            [-83.022115, 38.007047],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03240',
        AFFGEOID: '9700000US2103240',
        GEOID: '2103240',
        NAME: 'Lawrence County School District',
        LSAD: '00',
        ALAND: 1076386125,
        AWATER: 11584860,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.047598, 37.449933],
            [-85.892804, 37.440308],
            [-85.75027999999999, 37.431146999999996],
            [-85.733317, 37.425328],
            [-85.657332, 37.421996],
            [-85.652906, 37.391054],
            [-85.685485, 37.361306],
            [-85.69732499999999, 37.301838],
            [-85.682301, 37.288443],
            [-85.67899, 37.249995999999996],
            [-85.686482, 37.182427],
            [-85.744221, 37.169813],
            [-85.87974799999999, 37.149381999999996],
            [-85.86332999999999, 37.175140999999996],
            [-85.880527, 37.191991],
            [-85.926194, 37.189553],
            [-85.947682, 37.152754],
            [-85.986205, 37.156831],
            [-86.056443, 37.167207999999995],
            [-86.049133, 37.215285],
            [-86.157589, 37.335649],
            [-86.07362599999999, 37.348045],
            [-86.067317, 37.38294],
            [-86.039018, 37.403818],
            [-86.047598, 37.449933],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02640',
        AFFGEOID: '9700000US2102640',
        GEOID: '2102640',
        NAME: 'Hart County School District',
        LSAD: '00',
        ALAND: 1041957226,
        AWATER: 13730440,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.034245864167, 37.9652698457521],
            [-86.032468, 37.990100000000005],
            [-85.996629, 37.987442],
            [-85.932407, 37.994216],
            [-85.91442599999999, 38.007729],
            [-85.86547, 37.994234999999996],
            [-85.857114, 38.003436],
            [-85.80453899999999, 37.989896],
            [-85.769671, 37.989416],
            [-85.760151, 37.963992999999995],
            [-85.766076, 37.918199],
            [-85.751722, 37.884293],
            [-85.750152, 37.826737],
            [-85.764099, 37.811288],
            [-85.805984, 37.795984],
            [-85.84620000000001, 37.805876],
            [-85.902767, 37.797906999999995],
            [-85.905356, 37.829121],
            [-85.95587499999999, 37.861857],
            [-85.985496, 37.858401],
            [-86.035062, 37.884161],
            [-86.056694, 37.912548],
            [-86.019654, 37.935673],
            [-86.034245864167, 37.9652698457521],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00079',
        AFFGEOID: '9700000US2100079',
        GEOID: '2100079',
        NAME: 'Fort Knox Dependent Schools',
        LSAD: '00',
        ALAND: 438042720,
        AWATER: 6958590,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.557375, 38.492917],
            [-84.52139299999999, 38.508299],
            [-84.485295, 38.508552],
            [-84.476299, 38.542722999999995],
            [-84.452635, 38.531025],
            [-84.40032099999999, 38.560603],
            [-84.20534599999999, 38.582039],
            [-84.20401, 38.563961],
            [-84.162031, 38.554125],
            [-84.178484, 38.532773999999996],
            [-84.161372, 38.501785999999996],
            [-84.10210000000001, 38.459379],
            [-84.19404999999999, 38.37175],
            [-84.28184399999999, 38.312461],
            [-84.37230799999999, 38.279509],
            [-84.442661, 38.283235999999995],
            [-84.433848, 38.304159],
            [-84.462626, 38.357996],
            [-84.474249, 38.400216],
            [-84.527948, 38.43325],
            [-84.557375, 38.492917],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02580',
        AFFGEOID: '9700000US2102580',
        GEOID: '2102580',
        NAME: 'Harrison County School District',
        LSAD: '00',
        ALAND: 793480169,
        AWATER: 9057533,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.986205, 37.156831],
            [-85.947682, 37.152754],
            [-85.926194, 37.189553],
            [-85.880527, 37.191991],
            [-85.86332999999999, 37.175140999999996],
            [-85.87974799999999, 37.149381999999996],
            [-85.906494, 37.148392],
            [-85.914459, 37.127159],
            [-85.97012099999999, 37.102243],
            [-85.99012499999999, 37.113057999999995],
            [-85.97818699999999, 37.135605999999996],
            [-85.986205, 37.156831],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01110',
        AFFGEOID: '9700000US2101110',
        GEOID: '2101110',
        NAME: 'Caverna Independent School District',
        LSAD: '00',
        ALAND: 57744802,
        AWATER: 467583,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.612393, 37.879272],
            [-82.595612, 37.890682],
            [-82.58086399999999, 37.954074],
            [-82.534871, 37.962786],
            [-82.4972999070143, 37.9454908576785],
            [-82.487556, 37.916975],
            [-82.451352, 37.908471999999996],
            [-82.42148399999999, 37.885652],
            [-82.398444, 37.821647999999996],
            [-82.348849, 37.787178],
            [-82.33304432026151, 37.7409688362322],
            [-82.375963, 37.739664999999995],
            [-82.413123, 37.712151999999996],
            [-82.386422, 37.697685],
            [-82.409708, 37.679280999999996],
            [-82.455816, 37.668686],
            [-82.520561, 37.694305],
            [-82.56356099999999, 37.684844],
            [-82.64075, 37.720182],
            [-82.65571899999999, 37.752854],
            [-82.65337, 37.77768],
            [-82.674443, 37.786988],
            [-82.644807, 37.822939],
            [-82.670812, 37.852091],
            [-82.612393, 37.879272],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03840',
        AFFGEOID: '9700000US2103840',
        GEOID: '2103840',
        NAME: 'Martin County School District',
        LSAD: '00',
        ALAND: 594701951,
        AWATER: 2564962,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.27526999999999, 37.593519],
            [-86.151285, 37.798877999999995],
            [-86.007386, 37.818522],
            [-85.985496, 37.858401],
            [-85.95587499999999, 37.861857],
            [-85.905356, 37.829121],
            [-85.902767, 37.797906999999995],
            [-85.84620000000001, 37.805876],
            [-85.805984, 37.795984],
            [-85.764099, 37.811288],
            [-85.750152, 37.826737],
            [-85.737777, 37.811789999999995],
            [-85.728133, 37.774178],
            [-85.682434, 37.757698],
            [-85.680488, 37.732284],
            [-85.736209, 37.654364],
            [-85.80351999999999, 37.621586],
            [-85.819295, 37.584227],
            [-85.87600599999999, 37.543259],
            [-85.892804, 37.440308],
            [-86.047598, 37.449933],
            [-86.064073, 37.507902],
            [-86.11409499999999, 37.567130999999996],
            [-86.143045, 37.559539],
            [-86.195708, 37.565529],
            [-86.244878, 37.598939],
            [-86.27526999999999, 37.593519],
          ],
          [
            [-85.887453, 37.711486],
            [-85.876031, 37.683099],
            [-85.844099, 37.676319],
            [-85.850853, 37.717253],
            [-85.887453, 37.711486],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02490',
        AFFGEOID: '9700000US2102490',
        GEOID: '2102490',
        NAME: 'Hardin County School District',
        LSAD: '00',
        ALAND: 1354618511,
        AWATER: 13119878,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.625079, 38.116397],
            [-84.620601, 38.129545],
            [-84.40184699999999, 38.207889],
            [-84.378481, 38.211357],
            [-84.35515, 38.178036],
            [-84.373755, 38.157572],
            [-84.368589, 38.125901],
            [-84.379369, 38.111122],
            [-84.286461, 38.067028],
            [-84.282629, 38.019103],
            [-84.322463, 38.000878],
            [-84.323043, 37.964966],
            [-84.34315099999999, 37.918076],
            [-84.33739299999999, 37.892007],
            [-84.38358699999999, 37.893809999999995],
            [-84.366413, 37.863341999999996],
            [-84.393007, 37.847494],
            [-84.41067199999999, 37.87126],
            [-84.43571999999999, 37.847201999999996],
            [-84.434309, 37.868444],
            [-84.452614, 37.908989999999996],
            [-84.479629, 37.940219],
            [-84.59233499999999, 37.986428],
            [-84.660135, 38.003744999999995],
            [-84.625079, 38.116397],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01860',
        AFFGEOID: '9700000US2101860',
        GEOID: '2101860',
        NAME: 'Fayette County School District',
        LSAD: '00',
        ALAND: 734642357,
        AWATER: 4922241,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.740594, 38.352415],
            [-84.70606599999999, 38.370062],
            [-84.623583, 38.431191],
            [-84.580539, 38.473039],
            [-84.557375, 38.492917],
            [-84.527948, 38.43325],
            [-84.474249, 38.400216],
            [-84.462626, 38.357996],
            [-84.433848, 38.304159],
            [-84.442661, 38.283235999999995],
            [-84.421267, 38.252776],
            [-84.416828, 38.22125],
            [-84.40184699999999, 38.207889],
            [-84.620601, 38.129545],
            [-84.625079, 38.116397],
            [-84.650277, 38.144824],
            [-84.65570799999999, 38.179466999999995],
            [-84.714158, 38.175379],
            [-84.725168, 38.195426],
            [-84.730289, 38.208301999999996],
            [-84.740594, 38.352415],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05260',
        AFFGEOID: '9700000US2105260',
        GEOID: '2105260',
        NAME: 'Scott County School District',
        LSAD: '00',
        ALAND: 729781723,
        AWATER: 9487300,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.07458299999999, 38.596838],
            [-85.059846, 38.611022],
            [-85.02430000000001, 38.614202],
            [-84.934536, 38.66213],
            [-84.905579, 38.669364],
            [-84.85974499999999, 38.700891],
            [-84.785788, 38.720459],
            [-84.774322, 38.618594],
            [-84.674127, 38.555119],
            [-84.626651, 38.485132],
            [-84.580539, 38.473039],
            [-84.623583, 38.431191],
            [-84.70606599999999, 38.370062],
            [-84.740594, 38.352415],
            [-84.79341199999999, 38.338668999999996],
            [-84.808762, 38.349306],
            [-84.870482, 38.356750999999996],
            [-84.89759099999999, 38.389558],
            [-84.880248, 38.41618],
            [-84.96322599999999, 38.437059],
            [-84.955507, 38.472389],
            [-84.988781, 38.487085],
            [-84.98502599999999, 38.518405],
            [-85.028578, 38.506657],
            [-85.009185, 38.561124],
            [-85.07593, 38.578018],
            [-85.07458299999999, 38.596838],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04560',
        AFFGEOID: '9700000US2104560',
        GEOID: '2104560',
        NAME: 'Owen County School District',
        LSAD: '00',
        ALAND: 909365594,
        AWATER: 8129651,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.144239, 38.347225],
            [-85.236117, 38.35441],
            [-85.238457, 38.389129],
            [-85.16690799999999, 38.402941],
            [-85.145212, 38.378899],
            [-85.144239, 38.347225],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01710',
        AFFGEOID: '9700000US2101710',
        GEOID: '2101710',
        NAME: 'Eminence Independent School District',
        LSAD: '00',
        ALAND: 37331683,
        AWATER: 773534,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.63804773991271, 38.3802883493094],
            [-85.468206, 38.285309],
            [-85.404899, 38.263729999999995],
            [-85.424054, 38.147438],
            [-85.42882999999999, 38.118383],
            [-85.55727, 38.073841],
            [-85.712578, 38.087311],
            [-85.876969, 38.041818],
            [-85.938321, 37.998756],
            [-85.922395, 38.028679],
            [-85.90616299999999, 38.086169999999996],
            [-85.90876399999999, 38.161169],
            [-85.880264, 38.203368999999995],
            [-85.839664, 38.23977],
            [-85.829364, 38.276769],
            [-85.791563, 38.288568999999995],
            [-85.73874599999999, 38.269366],
            [-85.683561, 38.295469],
            [-85.653641, 38.327107999999996],
            [-85.63804773991271, 38.3802883493094],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02990',
        AFFGEOID: '9700000US2102990',
        GEOID: '2102990',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        ALAND: 977692215,
        AWATER: 44526010,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.623732315066, 39.0744265071074],
            [-84.5718392746594, 39.0822569566226],
            [-84.54872, 39.081866],
            [-84.52917599999999, 39.078092999999996],
            [-84.501228, 39.03369],
            [-84.489401, 39.032689999999995],
            [-84.473333, 39.004509999999996],
            [-84.427285, 38.980863],
            [-84.449412, 38.949869],
            [-84.438912, 38.90488],
            [-84.46340000000001, 38.852059],
            [-84.45150699999999, 38.820344],
            [-84.41810699999999, 38.806596],
            [-84.532288, 38.791937],
            [-84.597775, 38.79117],
            [-84.61574, 38.802192],
            [-84.59803099999999, 38.87954],
            [-84.600409, 38.892866999999995],
            [-84.614042, 38.997561],
            [-84.58830999999999, 38.994552999999996],
            [-84.581938, 39.010394999999995],
            [-84.61720199999999, 39.022452],
            [-84.623732315066, 39.0744265071074],
          ],
          [
            [-84.569728, 39.042884],
            [-84.539667, 39.041115],
            [-84.548338, 39.065449],
            [-84.569728, 39.042884],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03090',
        AFFGEOID: '9700000US2103090',
        GEOID: '2103090',
        NAME: 'Kenton County School District',
        LSAD: '00',
        ALAND: 375616517,
        AWATER: 7905780,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.578158, 36.867132999999995],
            [-84.416816, 36.94692],
            [-84.358024, 36.9594],
            [-84.345872, 36.939496],
            [-84.297416, 36.945931],
            [-84.30410499999999, 36.912459],
            [-84.338346, 36.825277],
            [-84.350006, 36.780681],
            [-84.32265199999999, 36.758669],
            [-84.319769, 36.709756],
            [-84.307598, 36.676809],
            [-84.271529, 36.63395],
            [-84.239598, 36.613354],
            [-84.2271936534631, 36.5921799356786],
            [-84.543138, 36.596277],
            [-84.7784559169908, 36.6032090178664],
            [-84.67424299999999, 36.73916],
            [-84.67231199999999, 36.758445],
            [-84.634323, 36.769779],
            [-84.62256, 36.793932999999996],
            [-84.57933899999999, 36.814397],
            [-84.578158, 36.867132999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03990',
        AFFGEOID: '9700000US2103990',
        GEOID: '2103990',
        NAME: 'McCreary County School District',
        LSAD: '00',
        ALAND: 1105416732,
        AWATER: 10730756,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.901112, 37.116296999999996],
            [-84.811396, 37.17371],
            [-84.72071199999999, 37.238327999999996],
            [-84.556461, 37.355729],
            [-84.502932, 37.328801],
            [-84.446286, 37.27905],
            [-84.30047499999999, 37.16394],
            [-84.289076, 37.15174],
            [-84.290724, 37.110375],
            [-84.325223, 37.062179],
            [-84.317027, 37.040492],
            [-84.331786, 37.02438],
            [-84.31706, 36.999333],
            [-84.347364, 36.990195],
            [-84.358024, 36.9594],
            [-84.416816, 36.94692],
            [-84.578158, 36.867132999999995],
            [-84.62023099999999, 36.896321],
            [-84.679165, 36.980914],
            [-84.77024399999999, 36.957845999999996],
            [-84.783794, 36.978483],
            [-84.835712, 36.997612],
            [-84.863737, 37.008019999999995],
            [-84.905778, 37.047187],
            [-84.901112, 37.116296999999996],
          ],
          [
            [-84.65651799999999, 37.192904999999996],
            [-84.640084, 37.163148],
            [-84.593232, 37.160952],
            [-84.601019, 37.187377],
            [-84.65651799999999, 37.192904999999996],
          ],
          [
            [-84.634023, 37.083850999999996],
            [-84.612776, 37.068245],
            [-84.57153799999999, 37.086169],
            [-84.58350399999999, 37.107549999999996],
            [-84.614856, 37.111332],
            [-84.634023, 37.083850999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04950',
        AFFGEOID: '9700000US2104950',
        GEOID: '2104950',
        NAME: 'Pulaski County School District',
        LSAD: '00',
        ALAND: 1671447613,
        AWATER: 48450400,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.080337, 37.853066],
            [-84.076027, 37.824176],
            [-84.001967, 37.837592],
            [-83.962554, 37.844643999999995],
            [-83.963262, 37.800135999999995],
            [-83.91179, 37.766732],
            [-83.876863, 37.752089],
            [-83.830347, 37.765246999999995],
            [-83.777592, 37.756775999999995],
            [-83.768963, 37.741096999999996],
            [-83.722651, 37.728947],
            [-83.7221, 37.71642],
            [-83.7496, 37.714846],
            [-83.780783, 37.687807],
            [-83.824088, 37.662974],
            [-83.83246, 37.636258999999995],
            [-83.858098, 37.609204999999996],
            [-83.90557, 37.542856],
            [-83.939846, 37.573173],
            [-83.965244, 37.581421],
            [-84.045023, 37.563573],
            [-84.090364, 37.566227],
            [-84.126948, 37.642421],
            [-84.096094, 37.661671],
            [-84.091664, 37.72445],
            [-84.081913, 37.767258],
            [-84.107175, 37.775838],
            [-84.073908, 37.801117],
            [-84.099159, 37.815605],
            [-84.101644, 37.840312],
            [-84.080337, 37.853066],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01760',
        AFFGEOID: '9700000US2101760',
        GEOID: '2101760',
        NAME: 'Estill County School District',
        LSAD: '00',
        ALAND: 655468668,
        AWATER: 6557897,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.57150899999999, 36.552569],
            [-89.55264, 36.577177999999996],
            [-89.52758299999999, 36.581147],
            [-89.484836, 36.571821],
            [-89.46588799999999, 36.529945999999995],
            [-89.485427, 36.497491],
            [-89.539232, 36.497934],
            [-89.57150899999999, 36.552569],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '99997',
        AFFGEOID: '9700000US2199997',
        GEOID: '2199997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        ALAND: 45202351,
        AWATER: 24427247,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.40576899999999, 36.776187],
            [-86.26340499999999, 36.873134],
            [-86.226309, 36.912633],
            [-86.203257, 36.913469],
            [-86.16673999999999, 36.934014999999995],
            [-86.107923, 36.887592],
            [-86.07021499999999, 36.877294],
            [-86.07801599999999, 36.8547],
            [-86.055692, 36.830065],
            [-86.03073499999999, 36.764382999999995],
            [-86.002309, 36.758711],
            [-85.97690999999999, 36.722722],
            [-85.94940299999999, 36.661645],
            [-85.9729214486467, 36.6285202945041],
            [-86.19757299999999, 36.639362999999996],
            [-86.333051, 36.648778],
            [-86.41138614351739, 36.6505962464493],
            [-86.413845, 36.723076999999996],
            [-86.40576899999999, 36.776187],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00070',
        AFFGEOID: '9700000US2100070',
        GEOID: '2100070',
        NAME: 'Allen County School District',
        LSAD: '00',
        ALAND: 891830441,
        AWATER: 19492342,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.101646, 38.03727],
            [-85.084032, 38.040706],
            [-85.047783, 38.072341],
            [-85.054538, 38.084223],
            [-85.03926899999999, 38.120014999999995],
            [-85.02371099999999, 38.129052],
            [-84.967393, 38.119268],
            [-84.907798, 38.093666],
            [-84.864908, 38.116926],
            [-84.827418, 38.082944],
            [-84.842989, 38.070006],
            [-84.84656799999999, 38.040777],
            [-84.81714199999999, 37.999998],
            [-84.81806, 37.973391],
            [-84.79632699999999, 37.970088],
            [-84.884146, 37.940056],
            [-84.93308499999999, 37.954186],
            [-84.973351, 37.935511],
            [-84.987192, 37.905657],
            [-85.03052799999999, 37.891538],
            [-85.088441, 37.887646],
            [-85.106192, 37.909247],
            [-85.15279699999999, 37.897661],
            [-85.167834, 37.971809],
            [-85.166291, 37.987519999999996],
            [-85.131052, 37.99588],
            [-85.126452, 38.020292],
            [-85.101646, 38.03727],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00120',
        AFFGEOID: '9700000US2100120',
        GEOID: '2100120',
        NAME: 'Anderson County School District',
        LSAD: '00',
        ALAND: 522745702,
        AWATER: 6311537,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.16673999999999, 36.934014999999995],
            [-86.113512, 37.061071999999996],
            [-86.07426699999999, 37.053284999999995],
            [-86.056443, 37.167207999999995],
            [-85.986205, 37.156831],
            [-85.97818699999999, 37.135605999999996],
            [-85.99012499999999, 37.113057999999995],
            [-85.97012099999999, 37.102243],
            [-85.914459, 37.127159],
            [-85.906494, 37.148392],
            [-85.87974799999999, 37.149381999999996],
            [-85.744221, 37.169813],
            [-85.776962, 37.138328],
            [-85.75609, 37.109835],
            [-85.759278, 37.068573],
            [-85.750396, 37.005902],
            [-85.739255, 36.841476],
            [-85.807011, 36.821638],
            [-85.97690999999999, 36.722722],
            [-86.002309, 36.758711],
            [-86.03073499999999, 36.764382999999995],
            [-86.055692, 36.830065],
            [-86.07801599999999, 36.8547],
            [-86.07021499999999, 36.877294],
            [-86.107923, 36.887592],
            [-86.16673999999999, 36.934014999999995],
          ],
          [
            [-85.95326999999999, 36.960671999999995],
            [-85.90458, 36.968494],
            [-85.887716, 37.003467],
            [-85.902738, 37.028985],
            [-85.929009, 37.024764],
            [-85.94713, 36.99893],
            [-85.95326999999999, 36.960671999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00300',
        AFFGEOID: '9700000US2100300',
        GEOID: '2100300',
        NAME: 'Barren County School District',
        LSAD: '00',
        ALAND: 1203372624,
        AWATER: 31907096,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.42882999999999, 38.118383],
            [-85.469231, 38.096222],
            [-85.511923, 38.012876999999996],
            [-85.48920199999999, 37.990654],
            [-85.54907399999999, 37.928242999999995],
            [-85.585683, 37.907728999999996],
            [-85.57972099999999, 37.883480999999996],
            [-85.643254, 37.834033],
            [-85.69885099999999, 37.811228],
            [-85.737777, 37.811789999999995],
            [-85.750152, 37.826737],
            [-85.751722, 37.884293],
            [-85.766076, 37.918199],
            [-85.760151, 37.963992999999995],
            [-85.769671, 37.989416],
            [-85.80453899999999, 37.989896],
            [-85.857114, 38.003436],
            [-85.86547, 37.994234999999996],
            [-85.91442599999999, 38.007729],
            [-85.932407, 37.994216],
            [-85.938321, 37.998756],
            [-85.876969, 38.041818],
            [-85.712578, 38.087311],
            [-85.55727, 38.073841],
            [-85.42882999999999, 38.118383],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00750',
        AFFGEOID: '9700000US2100750',
        GEOID: '2100750',
        NAME: 'Bullitt County School District',
        LSAD: '00',
        ALAND: 626742179,
        AWATER: 6800407,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.49775199999999, 39.076947],
            [-84.481898, 39.066521],
            [-84.460456, 39.05394],
            [-84.43235, 39.055304],
            [-84.4335444846402, 39.0963453989339],
            [-84.42602045601609, 39.053312201007195],
            [-84.40021, 39.033716999999996],
            [-84.3697761230449, 39.0427059289258],
            [-84.326539, 39.027463],
            [-84.304698, 39.006454999999995],
            [-84.288164, 38.955788999999996],
            [-84.234453, 38.893226],
            [-84.23191434799891, 38.8748661027637],
            [-84.387389, 38.812323],
            [-84.41810699999999, 38.806596],
            [-84.45150699999999, 38.820344],
            [-84.46340000000001, 38.852059],
            [-84.438912, 38.90488],
            [-84.449412, 38.949869],
            [-84.427285, 38.980863],
            [-84.473333, 39.004509999999996],
            [-84.489401, 39.032689999999995],
            [-84.49775199999999, 39.076947],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00900',
        AFFGEOID: '9700000US2100900',
        GEOID: '2100900',
        NAME: 'Campbell County School District',
        LSAD: '00',
        ALAND: 355306256,
        AWATER: 15180775,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.737777, 37.811789999999995],
            [-85.69885099999999, 37.811228],
            [-85.643254, 37.834033],
            [-85.57972099999999, 37.883480999999996],
            [-85.585683, 37.907728999999996],
            [-85.54907399999999, 37.928242999999995],
            [-85.48920199999999, 37.990654],
            [-85.463438, 37.971377],
            [-85.425822, 37.959632],
            [-85.399559, 37.939929],
            [-85.167834, 37.971809],
            [-85.15279699999999, 37.897661],
            [-85.181399, 37.892427999999995],
            [-85.205394, 37.863178999999995],
            [-85.246509, 37.853922],
            [-85.30748299999999, 37.819378],
            [-85.319419, 37.78776],
            [-85.355835, 37.781428],
            [-85.374954, 37.764507],
            [-85.373323, 37.739581],
            [-85.40178399999999, 37.730658],
            [-85.450493, 37.685947999999996],
            [-85.52129, 37.554342999999996],
            [-85.551131, 37.554024],
            [-85.570752, 37.527187],
            [-85.623949, 37.546490999999996],
            [-85.604162, 37.569120999999996],
            [-85.606766, 37.59045],
            [-85.57269, 37.637978],
            [-85.60100899999999, 37.657503999999996],
            [-85.592497, 37.693905],
            [-85.637889, 37.696683],
            [-85.645522, 37.71638],
            [-85.680488, 37.732284],
            [-85.682434, 37.757698],
            [-85.728133, 37.774178],
            [-85.737777, 37.811789999999995],
          ],
          [
            [-85.512922, 37.828987],
            [-85.486497, 37.788604],
            [-85.451661, 37.798753],
            [-85.419581, 37.834578],
            [-85.48281999999999, 37.852768999999995],
            [-85.512922, 37.828987],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04410',
        AFFGEOID: '9700000US2104410',
        GEOID: '2104410',
        NAME: 'Nelson County School District',
        LSAD: '00',
        ALAND: 1044866595,
        AWATER: 16557882,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0308908096218, 38.7255636114076],
            [-82.979395, 38.725975999999996],
            [-82.894193, 38.756575999999995],
            [-82.869892, 38.728176999999995],
            [-82.87759199999999, 38.690177],
            [-82.86329099999999, 38.669277],
            [-82.847186, 38.595166],
            [-82.820161, 38.572703],
            [-82.779472, 38.559022999999996],
            [-82.730958, 38.559264],
            [-82.7082917570131, 38.5479676852903],
            [-82.709941, 38.544923],
            [-82.761276, 38.556597],
            [-82.76045599999999, 38.522743],
            [-82.719657, 38.467993],
            [-82.769047, 38.433715],
            [-82.777227, 38.414939],
            [-82.810637, 38.393024],
            [-82.817454, 38.373934999999996],
            [-82.912712, 38.398949],
            [-82.967635, 38.394138],
            [-83.051566, 38.46524],
            [-83.16641899999999, 38.503965],
            [-83.146652, 38.530239],
            [-83.12313999999999, 38.535229],
            [-83.071034, 38.595023],
            [-83.08079, 38.610465],
            [-83.053578, 38.657109999999996],
            [-83.024093, 38.682744],
            [-83.038095, 38.699377999999996],
            [-83.0308908096218, 38.7255636114076],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02400',
        AFFGEOID: '9700000US2102400',
        GEOID: '2102400',
        NAME: 'Greenup County School District',
        LSAD: '00',
        ALAND: 844741184,
        AWATER: 23324534,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.76045599999999, 38.522743],
            [-82.727093, 38.525678],
            [-82.709941, 38.544923],
            [-82.7082917570131, 38.5479676852903],
            [-82.6654809868313, 38.5057370829218],
            [-82.689433, 38.489028],
            [-82.719657, 38.467993],
            [-82.76045599999999, 38.522743],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05130',
        AFFGEOID: '9700000US2105130',
        GEOID: '2105130',
        NAME: 'Russell Independent School District',
        LSAD: '00',
        ALAND: 35229032,
        AWATER: 2331382,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.061342239857, 37.505326559522494],
            [-87.999738, 37.514610999999995],
            [-87.955182, 37.47893],
            [-87.932952, 37.480052],
            [-87.943955, 37.465225],
            [-87.928767, 37.408632],
            [-87.90303399999999, 37.398196999999996],
            [-87.847034, 37.420752],
            [-87.847381, 37.375583],
            [-87.801046, 37.379445],
            [-88.052483, 37.237663],
            [-88.08949299999999, 37.222066],
            [-88.095781, 37.179684],
            [-88.110328, 37.159838],
            [-88.163384, 37.143153999999996],
            [-88.193519, 37.147256],
            [-88.195768, 37.173221999999996],
            [-88.220338, 37.183012999999995],
            [-88.19624999999999, 37.219482],
            [-88.21898, 37.273145],
            [-88.293628, 37.335376],
            [-88.299454, 37.377939999999995],
            [-88.35850600784909, 37.4048167301695],
            [-88.312585, 37.440591],
            [-88.281667, 37.452596],
            [-88.08176499999999, 37.473020999999996],
            [-88.061342239857, 37.505326559522494],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01380',
        AFFGEOID: '9700000US2101380',
        GEOID: '2101380',
        NAME: 'Crittenden County School District',
        LSAD: '00',
        ALAND: 932275444,
        AWATER: 28974077,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.263289, 38.115386],
            [-83.22200099999999, 38.132498999999996],
            [-83.20507599999999, 38.175218],
            [-83.243324, 38.176097999999996],
            [-83.244306, 38.19238],
            [-83.220913, 38.209848],
            [-83.219017, 38.232338],
            [-83.158962, 38.264457],
            [-83.135008, 38.242036999999996],
            [-83.092078, 38.227976],
            [-83.07584299999999, 38.206559],
            [-83.047536, 38.193466],
            [-83.011937, 38.199180999999996],
            [-82.975731, 38.171994999999995],
            [-82.92480499999999, 38.175114],
            [-82.88892299999999, 38.111847],
            [-82.942005, 38.077585],
            [-82.941991, 38.064164],
            [-83.00223799999999, 38.034600999999995],
            [-83.022115, 38.007047],
            [-83.04772799999999, 37.996818999999995],
            [-83.101689, 38.009751],
            [-83.146974, 38.000076],
            [-83.194445, 38.010649],
            [-83.22289099999999, 38.043956],
            [-83.267544, 38.058949999999996],
            [-83.263289, 38.115386],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01680',
        AFFGEOID: '9700000US2101680',
        GEOID: '2101680',
        NAME: 'Elliott County School District',
        LSAD: '00',
        ALAND: 606876137,
        AWATER: 2608208,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.452848, 38.381799],
            [-83.416928, 38.396155],
            [-83.341347, 38.319521],
            [-83.334909, 38.286950999999995],
            [-83.31196299999999, 38.252693],
            [-83.299784, 38.209129],
            [-83.244306, 38.19238],
            [-83.243324, 38.176097999999996],
            [-83.20507599999999, 38.175218],
            [-83.22200099999999, 38.132498999999996],
            [-83.263289, 38.115386],
            [-83.304656, 38.08977],
            [-83.37136799999999, 38.069559999999996],
            [-83.395153, 38.042105],
            [-83.433942, 38.033974],
            [-83.463408, 38.016974999999995],
            [-83.498846, 38.050619999999995],
            [-83.463285, 38.079710999999996],
            [-83.519617, 38.08889],
            [-83.51992, 38.115885],
            [-83.567933, 38.146108999999996],
            [-83.613722, 38.133213999999995],
            [-83.620296, 38.175593],
            [-83.635195, 38.187525],
            [-83.61549, 38.192977],
            [-83.507953, 38.273154999999996],
            [-83.49926599999999, 38.349351],
            [-83.470207, 38.355216999999996],
            [-83.452848, 38.381799],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05100',
        AFFGEOID: '9700000US2105100',
        GEOID: '2105100',
        NAME: 'Rowan County School District',
        LSAD: '00',
        ALAND: 724726297,
        AWATER: 16698632,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.707974, 37.716463],
            [-83.63105999999999, 37.825372],
            [-83.590333, 37.818343],
            [-83.55140999999999, 37.821615],
            [-83.49508, 37.861906],
            [-83.484258, 37.84391],
            [-83.45532, 37.842679],
            [-83.433048, 37.819112],
            [-83.386437, 37.813674999999996],
            [-83.327437, 37.7796],
            [-83.334104, 37.758528999999996],
            [-83.287065, 37.742287999999995],
            [-83.26217199999999, 37.712657],
            [-83.243603, 37.699155],
            [-83.24869199999999, 37.669616],
            [-83.317939, 37.682894],
            [-83.33067, 37.674569],
            [-83.381934, 37.691936999999996],
            [-83.418635, 37.690655],
            [-83.433776, 37.657236],
            [-83.458716, 37.630476],
            [-83.494518, 37.619104],
            [-83.522308, 37.638515999999996],
            [-83.616649, 37.666925],
            [-83.62830699999999, 37.678889999999996],
            [-83.707974, 37.716463],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05970',
        AFFGEOID: '9700000US2105970',
        GEOID: '2105970',
        NAME: 'Wolfe County School District',
        LSAD: '00',
        ALAND: 575419340,
        AWATER: 1505210,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.765954, 36.615010999999996],
            [-83.73977099999999, 36.617149999999995],
            [-83.724226, 36.635159],
            [-83.68886499999999, 36.62565],
            [-83.689309, 36.602382],
            [-83.72761299999999, 36.592538],
            [-83.765954, 36.615010999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04170',
        AFFGEOID: '9700000US2104170',
        GEOID: '2104170',
        NAME: 'Middlesboro Independent School District',
        LSAD: '00',
        ALAND: 19529815,
        AWATER: 226426,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.442661, 38.283235999999995],
            [-84.37230799999999, 38.279509],
            [-84.28184399999999, 38.312461],
            [-84.19404999999999, 38.37175],
            [-84.166253, 38.354912999999996],
            [-84.09532399999999, 38.259014],
            [-84.057214, 38.256828999999996],
            [-84.027519, 38.218005],
            [-83.977367, 38.220048],
            [-83.977924, 38.192118],
            [-83.99017099999999, 38.175067],
            [-84.08052699999999, 38.115117999999995],
            [-84.286461, 38.067028],
            [-84.379369, 38.111122],
            [-84.368589, 38.125901],
            [-84.373755, 38.157572],
            [-84.35515, 38.178036],
            [-84.378481, 38.211357],
            [-84.40184699999999, 38.207889],
            [-84.416828, 38.22125],
            [-84.421267, 38.252776],
            [-84.442661, 38.283235999999995],
          ],
          [
            [-84.27463, 38.213471],
            [-84.265136, 38.196984],
            [-84.23409099999999, 38.215579],
            [-84.24496099999999, 38.224489],
            [-84.27463, 38.213471],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00540',
        AFFGEOID: '9700000US2100540',
        GEOID: '2100540',
        NAME: 'Bourbon County School District',
        LSAD: '00',
        ALAND: 745073034,
        AWATER: 4808348,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.57918599999999, 37.506249],
            [-83.522308, 37.638515999999996],
            [-83.494518, 37.619104],
            [-83.458716, 37.630476],
            [-83.433776, 37.657236],
            [-83.418635, 37.690655],
            [-83.381934, 37.691936999999996],
            [-83.33067, 37.674569],
            [-83.317939, 37.682894],
            [-83.24869199999999, 37.669616],
            [-83.205545, 37.644746999999995],
            [-83.18108099999999, 37.643786],
            [-83.114667, 37.611401],
            [-83.083866, 37.629815],
            [-83.038749, 37.584303],
            [-83.009766, 37.57673],
            [-83.018384, 37.555397],
            [-82.996952, 37.528332],
            [-82.948544, 37.503167],
            [-82.973344, 37.497811999999996],
            [-83.024774, 37.507129],
            [-83.050201, 37.525314],
            [-83.094663, 37.518285999999996],
            [-83.093144, 37.498101999999996],
            [-83.134711, 37.457181],
            [-83.124348, 37.404973],
            [-83.19973399999999, 37.41466],
            [-83.21473499999999, 37.443538],
            [-83.269786, 37.398779999999995],
            [-83.33222599999999, 37.379732],
            [-83.36824399999999, 37.358070999999995],
            [-83.38165, 37.338266],
            [-83.405402, 37.338927],
            [-83.445487, 37.370484999999995],
            [-83.484738, 37.371822],
            [-83.504134, 37.341845],
            [-83.547628, 37.334418],
            [-83.536271, 37.353297],
            [-83.548844, 37.371866],
            [-83.54165499999999, 37.398725999999996],
            [-83.554192, 37.467048999999996],
            [-83.57918599999999, 37.506249],
          ],
          [
            [-83.401207, 37.560398],
            [-83.38724599999999, 37.540850999999996],
            [-83.368811, 37.558034],
            [-83.401207, 37.560398],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00690',
        AFFGEOID: '9700000US2100690',
        GEOID: '2100690',
        NAME: 'Breathitt County School District',
        LSAD: '00',
        ALAND: 1271125390,
        AWATER: 7281181,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.719657, 38.467993],
            [-82.689433, 38.489028],
            [-82.68952999999999, 38.488776],
            [-82.672094, 38.465595],
            [-82.676553, 38.418804],
            [-82.6015466939101, 38.4427171017697],
            [-82.59348, 38.421821],
            [-82.597979, 38.344909],
            [-82.571877, 38.315781],
            [-82.58145999999999, 38.300444999999996],
            [-82.57465599999999, 38.263873],
            [-82.604625, 38.247302999999995],
            [-82.61430399999999, 38.266078],
            [-82.699471, 38.261913],
            [-82.72379699999999, 38.243777],
            [-82.76259, 38.234685],
            [-82.794206, 38.243772],
            [-82.782062, 38.335055],
            [-82.771737, 38.362587999999995],
            [-82.817454, 38.373934999999996],
            [-82.810637, 38.393024],
            [-82.777227, 38.414939],
            [-82.769047, 38.433715],
            [-82.719657, 38.467993],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00620',
        AFFGEOID: '9700000US2100620',
        GEOID: '2100620',
        NAME: 'Boyd County School District',
        LSAD: '00',
        ALAND: 372094164,
        AWATER: 2071429,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.89926799999999, 37.212313],
            [-86.87186799999999, 37.246609],
            [-86.853405, 37.250495],
            [-86.81883599999999, 37.305282999999996],
            [-86.817168, 37.327616],
            [-86.775263, 37.325928],
            [-86.75953299999999, 37.304099],
            [-86.612095, 37.395123],
            [-86.487152, 37.362501],
            [-86.469126, 37.321099],
            [-86.399165, 37.169896],
            [-86.41631, 37.153090999999996],
            [-86.467309, 37.153456999999996],
            [-86.49649, 37.190708],
            [-86.541038, 37.176007999999996],
            [-86.581133, 37.185072999999996],
            [-86.618158, 37.183906],
            [-86.61688099999999, 37.146578999999996],
            [-86.67446199999999, 36.999766],
            [-86.84535, 37.056512999999995],
            [-86.941391, 37.068981],
            [-86.897252, 37.09169],
            [-86.89752399999999, 37.118013999999995],
            [-86.92688199999999, 37.16027],
            [-86.900384, 37.184371999999996],
            [-86.89926799999999, 37.212313],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00810',
        AFFGEOID: '9700000US2100810',
        GEOID: '2100810',
        NAME: 'Butler County School District',
        LSAD: '00',
        ALAND: 1103562551,
        AWATER: 13947933,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.67280099999999, 37.811332],
            [-86.60708, 37.814935999999996],
            [-86.585151, 37.839379],
            [-86.60382433174, 37.859390013789],
            [-86.599848, 37.906754],
            [-86.58858099999999, 37.921158999999996],
            [-86.534156, 37.917007],
            [-86.507043, 37.936439],
            [-86.52517399999999, 37.968227999999996],
            [-86.524969, 38.027879],
            [-86.4907693787391, 38.045672484270696],
            [-86.429137, 38.000122999999995],
            [-86.24277699999999, 37.877002],
            [-86.151285, 37.798877999999995],
            [-86.27526999999999, 37.593519],
            [-86.352694, 37.586974],
            [-86.352941, 37.562773],
            [-86.44718999999999, 37.576178999999996],
            [-86.50336899999999, 37.622191],
            [-86.533378, 37.615700000000004],
            [-86.53482699999999, 37.58987],
            [-86.59281, 37.56436],
            [-86.619503, 37.585487],
            [-86.640821, 37.619186],
            [-86.63893499999999, 37.661877],
            [-86.622316, 37.655007999999995],
            [-86.67786799999999, 37.792836],
            [-86.67280099999999, 37.811332],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00720',
        AFFGEOID: '9700000US2100720',
        GEOID: '2100720',
        NAME: 'Breckinridge County School District',
        LSAD: '00',
        ALAND: 1449232172,
        AWATER: 43795654,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.67280099999999, 37.811332],
            [-86.65528645526759, 37.842505006112695],
            [-86.60382433174, 37.859390013789],
            [-86.585151, 37.839379],
            [-86.60708, 37.814935999999996],
            [-86.67280099999999, 37.811332],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01290',
        AFFGEOID: '9700000US2101290',
        GEOID: '2101290',
        NAME: 'Cloverport Independent School District',
        LSAD: '00',
        ALAND: 19817206,
        AWATER: 3906378,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9334049588957, 37.2251870778059],
            [-88.815952, 36.954100000000004],
            [-88.917183, 36.940843],
            [-88.990832, 36.919477],
            [-89.020276, 36.928450999999995],
            [-89.071162, 36.915559],
            [-89.10076200668469, 36.9440018170751],
            [-89.099594, 36.964543],
            [-89.16644699999999, 37.003336999999995],
            [-89.182509, 37.037275],
            [-89.154504, 37.088907],
            [-89.111189, 37.119051999999996],
            [-89.092934, 37.156439],
            [-89.02998099999999, 37.211144],
            [-88.974711, 37.229707999999995],
            [-88.9334049588957, 37.2251870778059],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00210',
        AFFGEOID: '9700000US2100210',
        GEOID: '2100210',
        NAME: 'Ballard County School District',
        LSAD: '00',
        ALAND: 638843942,
        AWATER: 70008724,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.680488, 37.732284],
            [-85.645522, 37.71638],
            [-85.637889, 37.696683],
            [-85.592497, 37.693905],
            [-85.60100899999999, 37.657503999999996],
            [-85.57269, 37.637978],
            [-85.606766, 37.59045],
            [-85.604162, 37.569120999999996],
            [-85.623949, 37.546490999999996],
            [-85.570752, 37.527187],
            [-85.551131, 37.554024],
            [-85.52129, 37.554342999999996],
            [-85.503001, 37.54909],
            [-85.46625, 37.465595],
            [-85.502771, 37.482303],
            [-85.528855, 37.454066999999995],
            [-85.583497, 37.469916999999995],
            [-85.638227, 37.454074999999996],
            [-85.665391, 37.436755999999995],
            [-85.657332, 37.421996],
            [-85.733317, 37.425328],
            [-85.75027999999999, 37.431146999999996],
            [-85.892804, 37.440308],
            [-85.87600599999999, 37.543259],
            [-85.819295, 37.584227],
            [-85.80351999999999, 37.621586],
            [-85.736209, 37.654364],
            [-85.680488, 37.732284],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03180',
        AFFGEOID: '9700000US2103180',
        GEOID: '2103180',
        NAME: 'Larue County School District',
        LSAD: '00',
        ALAND: 677366038,
        AWATER: 5418290,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3025990430289, 37.8985582968574],
            [-87.323402, 37.819238999999996],
            [-87.307378, 37.81521],
            [-87.290173, 37.760636],
            [-87.32077, 37.742518],
            [-87.37075899999999, 37.737814],
            [-87.394093, 37.723994999999995],
            [-87.408547, 37.683563],
            [-87.42053299999999, 37.670153],
            [-87.472811, 37.664868999999996],
            [-87.495331, 37.647546999999996],
            [-87.734545, 37.638376],
            [-87.754778, 37.671332],
            [-87.75565, 37.727762999999996],
            [-87.772691, 37.766692],
            [-87.819886, 37.76835],
            [-87.9270921983773, 37.9017059445802],
            [-87.892471, 37.927929999999996],
            [-87.865558, 37.915056],
            [-87.830578, 37.876515999999995],
            [-87.79518499999999, 37.875273],
            [-87.76226, 37.890906],
            [-87.710675, 37.893898],
            [-87.684018, 37.903498],
            [-87.665432, 37.879493],
            [-87.68163299999999, 37.855917],
            [-87.67239699999999, 37.829127],
            [-87.615399, 37.831973999999995],
            [-87.588729, 37.860983999999995],
            [-87.591582, 37.887194],
            [-87.628416, 37.92145],
            [-87.585916, 37.975442],
            [-87.551277, 37.925418],
            [-87.501131, 37.909161999999995],
            [-87.450458, 37.941451],
            [-87.418585, 37.944763],
            [-87.380247, 37.935596],
            [-87.344933, 37.911164],
            [-87.3025990430289, 37.8985582968574],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02710',
        AFFGEOID: '9700000US2102710',
        GEOID: '2102710',
        NAME: 'Henderson County School District',
        LSAD: '00',
        ALAND: 1130483401,
        AWATER: 76387049,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.94076299999999, 37.716338],
            [-82.89175999999999, 37.708693],
            [-82.87083299999999, 37.729141],
            [-82.845548, 37.729518999999996],
            [-82.76317399999999, 37.756225],
            [-82.72321099999999, 37.749081],
            [-82.672055, 37.725525999999995],
            [-82.64075, 37.720182],
            [-82.56356099999999, 37.684844],
            [-82.614538, 37.670322],
            [-82.598278, 37.642226],
            [-82.623576, 37.504051],
            [-82.597786, 37.502749],
            [-82.584711, 37.466574],
            [-82.588627, 37.446259],
            [-82.628565, 37.380734],
            [-82.666169, 37.370025999999996],
            [-82.65198099999999, 37.351461],
            [-82.672375, 37.306841],
            [-82.710805, 37.285323],
            [-82.748166, 37.31679],
            [-82.764316, 37.352379],
            [-82.754763, 37.373681],
            [-82.764055, 37.420268],
            [-82.781926, 37.429463],
            [-82.81052199999999, 37.411531],
            [-82.831473, 37.466989999999996],
            [-82.92546, 37.486525],
            [-82.897369, 37.514596999999995],
            [-82.888851, 37.564619],
            [-82.902147, 37.576932],
            [-82.904652, 37.63955],
            [-82.942223, 37.669111],
            [-82.94076299999999, 37.716338],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01950',
        AFFGEOID: '9700000US2101950',
        GEOID: '2101950',
        NAME: 'Floyd County School District',
        LSAD: '00',
        ALAND: 1018763006,
        AWATER: 6108909,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.59281, 37.56436],
            [-86.53482699999999, 37.58987],
            [-86.533378, 37.615700000000004],
            [-86.50336899999999, 37.622191],
            [-86.44718999999999, 37.576178999999996],
            [-86.352941, 37.562773],
            [-86.352694, 37.586974],
            [-86.27526999999999, 37.593519],
            [-86.244878, 37.598939],
            [-86.195708, 37.565529],
            [-86.143045, 37.559539],
            [-86.11409499999999, 37.567130999999996],
            [-86.064073, 37.507902],
            [-86.047598, 37.449933],
            [-86.039018, 37.403818],
            [-86.067317, 37.38294],
            [-86.07362599999999, 37.348045],
            [-86.157589, 37.335649],
            [-86.32068799999999, 37.32673],
            [-86.469126, 37.321099],
            [-86.487152, 37.362501],
            [-86.612095, 37.395123],
            [-86.66188, 37.541934],
            [-86.625322, 37.565887],
            [-86.59281, 37.56436],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02300',
        AFFGEOID: '9700000US2102300',
        GEOID: '2102300',
        NAME: 'Grayson County School District',
        LSAD: '00',
        ALAND: 1291451120,
        AWATER: 32112260,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.801046, 37.379445],
            [-87.813413, 37.350645],
            [-87.851728, 37.318667999999995],
            [-87.79707499999999, 37.315911],
            [-87.79435199999999, 37.255002],
            [-87.80390899999999, 37.243199],
            [-87.77154399999999, 37.214127],
            [-87.774694, 37.198127],
            [-87.70795299999999, 37.177634],
            [-87.680617, 37.149239],
            [-87.711005, 37.021527999999996],
            [-87.733368, 37.002103],
            [-87.76486799999999, 36.974737],
            [-87.858088, 36.950714],
            [-87.87699599999999, 36.960116],
            [-87.97507999999999, 37.067389999999996],
            [-88.04228499999999, 37.174774],
            [-88.095781, 37.179684],
            [-88.08949299999999, 37.222066],
            [-88.052483, 37.237663],
            [-87.801046, 37.379445],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00840',
        AFFGEOID: '9700000US2100840',
        GEOID: '2100840',
        NAME: 'Caldwell County School District',
        LSAD: '00',
        ALAND: 893006769,
        AWATER: 8700648,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.004649, 37.859246999999996],
            [-82.98152, 37.835412],
            [-82.98974299999999, 37.817814999999996],
            [-82.950582, 37.794734],
            [-82.948257, 37.719871],
            [-82.94076299999999, 37.716338],
            [-82.942223, 37.669111],
            [-82.904652, 37.63955],
            [-82.902147, 37.576932],
            [-82.888851, 37.564619],
            [-82.897369, 37.514596999999995],
            [-82.92546, 37.486525],
            [-82.948544, 37.503167],
            [-82.996952, 37.528332],
            [-83.018384, 37.555397],
            [-83.009766, 37.57673],
            [-83.038749, 37.584303],
            [-83.083866, 37.629815],
            [-83.114667, 37.611401],
            [-83.18108099999999, 37.643786],
            [-83.205545, 37.644746999999995],
            [-83.24869199999999, 37.669616],
            [-83.243603, 37.699155],
            [-83.26217199999999, 37.712657],
            [-83.243859, 37.744769],
            [-83.270387, 37.766844999999996],
            [-83.225686, 37.79593],
            [-83.207849, 37.819103999999996],
            [-83.113277, 37.864121],
            [-83.13132499999999, 37.892713],
            [-83.08940899999999, 37.890339999999995],
            [-83.056795, 37.870149999999995],
            [-83.02239, 37.874953999999995],
            [-83.004649, 37.859246999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03750',
        AFFGEOID: '9700000US2103750',
        GEOID: '2103750',
        NAME: 'Magoffin County School District',
        LSAD: '00',
        ALAND: 798866620,
        AWATER: 1847051,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.71554599999999, 37.768923],
            [-84.70540799999999, 37.793127],
            [-84.71692499999999, 37.815388999999996],
            [-84.675889, 37.826242],
            [-84.679825, 37.802693999999995],
            [-84.665733, 37.783100000000005],
            [-84.611903, 37.766712999999996],
            [-84.58520899999999, 37.750613],
            [-84.555375, 37.749224999999996],
            [-84.525643, 37.769497],
            [-84.494485, 37.747461],
            [-84.485404, 37.711126],
            [-84.45370199999999, 37.691206],
            [-84.44234, 37.662628999999995],
            [-84.418245, 37.641698],
            [-84.39665099999999, 37.599571999999995],
            [-84.37532999999999, 37.595467],
            [-84.347805, 37.539063999999996],
            [-84.354327, 37.50176],
            [-84.37443999999999, 37.472663],
            [-84.446719, 37.485893],
            [-84.473024, 37.494482999999995],
            [-84.65256699999999, 37.61821],
            [-84.65829599999999, 37.634501],
            [-84.698217, 37.668506],
            [-84.704273, 37.697632999999996],
            [-84.744888, 37.713074999999996],
            [-84.702185, 37.72539],
            [-84.71554599999999, 37.768923],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02160',
        AFFGEOID: '9700000US2102160',
        GEOID: '2102160',
        NAME: 'Garrard County School District',
        LSAD: '00',
        ALAND: 595898801,
        AWATER: 10017651,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.341347, 38.319521],
            [-83.32573699999999, 38.329677],
            [-83.282237, 38.324242],
            [-83.230598, 38.339507999999995],
            [-83.2371, 38.426576],
            [-83.200485, 38.453818],
            [-83.184514, 38.495689],
            [-83.16641899999999, 38.503965],
            [-83.051566, 38.46524],
            [-82.967635, 38.394138],
            [-82.912712, 38.398949],
            [-82.817454, 38.373934999999996],
            [-82.771737, 38.362587999999995],
            [-82.782062, 38.335055],
            [-82.794206, 38.243772],
            [-82.81227, 38.220016],
            [-82.853983, 38.190156],
            [-82.883764, 38.196027],
            [-82.92480499999999, 38.175114],
            [-82.975731, 38.171994999999995],
            [-83.011937, 38.199180999999996],
            [-83.047536, 38.193466],
            [-83.07584299999999, 38.206559],
            [-83.092078, 38.227976],
            [-83.135008, 38.242036999999996],
            [-83.158962, 38.264457],
            [-83.219017, 38.232338],
            [-83.220913, 38.209848],
            [-83.244306, 38.19238],
            [-83.299784, 38.209129],
            [-83.31196299999999, 38.252693],
            [-83.334909, 38.286950999999995],
            [-83.341347, 38.319521],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01020',
        AFFGEOID: '9700000US2101020',
        GEOID: '2101020',
        NAME: 'Carter County School District',
        LSAD: '00',
        ALAND: 1060590187,
        AWATER: 6800548,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.977924, 38.192118],
            [-83.956181, 38.159275],
            [-83.88135299999999, 38.136517],
            [-83.820799, 38.081109999999995],
            [-83.80168599999999, 38.041702],
            [-83.75943, 37.998731],
            [-83.77448799999999, 37.972868999999996],
            [-83.768783, 37.918371],
            [-83.812494, 37.903283],
            [-83.87866199999999, 37.905062],
            [-83.96631, 37.930768],
            [-84.08052699999999, 38.115117999999995],
            [-83.99017099999999, 38.175067],
            [-83.977924, 38.192118],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04250',
        AFFGEOID: '9700000US2104250',
        GEOID: '2104250',
        NAME: 'Montgomery County School District',
        LSAD: '00',
        ALAND: 511177442,
        AWATER: 3859635,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.977924, 38.192118],
            [-83.848355, 38.296949],
            [-83.822957, 38.300809],
            [-83.73231799999999, 38.283452],
            [-83.737793, 38.265285],
            [-83.69945, 38.246058],
            [-83.685828, 38.214107],
            [-83.649654, 38.182164],
            [-83.635195, 38.187525],
            [-83.620296, 38.175593],
            [-83.613722, 38.133213999999995],
            [-83.567933, 38.146108999999996],
            [-83.51992, 38.115885],
            [-83.519617, 38.08889],
            [-83.463285, 38.079710999999996],
            [-83.498846, 38.050619999999995],
            [-83.574989, 38.058883],
            [-83.57723299999999, 38.013313],
            [-83.619671, 38.014046],
            [-83.65929299999999, 38.028261],
            [-83.718497, 38.003397],
            [-83.75943, 37.998731],
            [-83.80168599999999, 38.041702],
            [-83.820799, 38.081109999999995],
            [-83.88135299999999, 38.136517],
            [-83.956181, 38.159275],
            [-83.977924, 38.192118],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00330',
        AFFGEOID: '9700000US2100330',
        GEOID: '2100330',
        NAME: 'Bath County School District',
        LSAD: '00',
        ALAND: 722081570,
        AWATER: 13486212,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.87750299999999, 36.687259],
            [-83.839421, 36.714037],
            [-83.828898, 36.743263999999996],
            [-83.80916599999999, 36.748103],
            [-83.798248, 36.785197],
            [-83.781212, 36.802693],
            [-83.738146, 36.808521999999996],
            [-83.691771, 36.838915],
            [-83.682879, 36.854582],
            [-83.64240699999999, 36.866246],
            [-83.63076, 36.888145],
            [-83.581094, 36.904666999999996],
            [-83.56674, 36.932423],
            [-83.58952099999999, 36.955785999999996],
            [-83.50908299999999, 36.938508999999996],
            [-83.489813, 36.895413],
            [-83.493867, 36.863507999999996],
            [-83.48170999999999, 36.849134],
            [-83.511729, 36.807362],
            [-83.48581399999999, 36.783293],
            [-83.500891, 36.733253999999995],
            [-83.459459, 36.697387],
            [-83.4610151191699, 36.6648782480975],
            [-83.53191199999999, 36.664984],
            [-83.57731199999999, 36.641784],
            [-83.649513, 36.616683],
            [-83.690714, 36.582581],
            [-83.9307607839439, 36.5876942581124],
            [-83.924712, 36.602672999999996],
            [-83.956234, 36.645491],
            [-83.878615, 36.680335],
            [-83.87750299999999, 36.687259],
          ],
          [
            [-83.765954, 36.615010999999996],
            [-83.72761299999999, 36.592538],
            [-83.689309, 36.602382],
            [-83.68886499999999, 36.62565],
            [-83.724226, 36.635159],
            [-83.73977099999999, 36.617149999999995],
            [-83.765954, 36.615010999999996],
          ],
          [
            [-83.71653099999999, 36.755756999999996],
            [-83.70367399999999, 36.757748],
            [-83.69344099999999, 36.746218999999996],
            [-83.688825, 36.768329],
            [-83.711822, 36.769501],
            [-83.71653099999999, 36.755756999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00390',
        AFFGEOID: '9700000US2100390',
        GEOID: '2100390',
        NAME: 'Bell County School District',
        LSAD: '00',
        ALAND: 906681415,
        AWATER: 5110371,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.02859299666821, 38.7739100289129],
            [-83.96048797779311, 38.7871529580372],
            [-83.950442, 38.764505],
            [-84.001066, 38.739374999999995],
            [-84.050842, 38.758814],
            [-84.02859299666821, 38.7739100289129],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00180',
        AFFGEOID: '9700000US2100180',
        GEOID: '2100180',
        NAME: 'Augusta Independent School District',
        LSAD: '00',
        ALAND: 23517305,
        AWATER: 1689315,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.930348, 38.492278],
            [-83.85928, 38.456348],
            [-83.721744, 38.503982],
            [-83.641852, 38.525380999999996],
            [-83.624755, 38.503637999999995],
            [-83.593335, 38.489031],
            [-83.576791, 38.457187999999995],
            [-83.579081, 38.430166],
            [-83.485787, 38.405066],
            [-83.452848, 38.381799],
            [-83.470207, 38.355216999999996],
            [-83.49926599999999, 38.349351],
            [-83.507953, 38.273154999999996],
            [-83.61549, 38.192977],
            [-83.635195, 38.187525],
            [-83.649654, 38.182164],
            [-83.685828, 38.214107],
            [-83.69945, 38.246058],
            [-83.737793, 38.265285],
            [-83.73231799999999, 38.283452],
            [-83.822957, 38.300809],
            [-83.848355, 38.296949],
            [-83.835152, 38.317108],
            [-83.86939799999999, 38.351714],
            [-83.90478999999999, 38.366222],
            [-83.94935699999999, 38.422956],
            [-83.980031, 38.439159],
            [-83.97001, 38.463997],
            [-83.930348, 38.492278],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01920',
        AFFGEOID: '9700000US2101920',
        GEOID: '2101920',
        NAME: 'Fleming County School District',
        LSAD: '00',
        ALAND: 902721336,
        AWATER: 7183992,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.525643, 37.769497],
            [-84.476287, 37.801991],
            [-84.48512, 37.840637],
            [-84.43571999999999, 37.847201999999996],
            [-84.41067199999999, 37.87126],
            [-84.393007, 37.847494],
            [-84.366413, 37.863341999999996],
            [-84.38358699999999, 37.893809999999995],
            [-84.33739299999999, 37.892007],
            [-84.27240599999999, 37.917546],
            [-84.261377, 37.89489],
            [-84.22191099999999, 37.871991],
            [-84.186846, 37.879232],
            [-84.167954, 37.863008],
            [-84.128221, 37.867160999999996],
            [-84.115877, 37.852683999999996],
            [-84.080337, 37.853066],
            [-84.101644, 37.840312],
            [-84.099159, 37.815605],
            [-84.073908, 37.801117],
            [-84.107175, 37.775838],
            [-84.081913, 37.767258],
            [-84.091664, 37.72445],
            [-84.096094, 37.661671],
            [-84.126948, 37.642421],
            [-84.090364, 37.566227],
            [-84.171836, 37.557549],
            [-84.199489, 37.522858],
            [-84.248397, 37.524797],
            [-84.26297699999999, 37.515569],
            [-84.347805, 37.539063999999996],
            [-84.37532999999999, 37.595467],
            [-84.39665099999999, 37.599571999999995],
            [-84.418245, 37.641698],
            [-84.44234, 37.662628999999995],
            [-84.45370199999999, 37.691206],
            [-84.485404, 37.711126],
            [-84.494485, 37.747461],
            [-84.525643, 37.769497],
          ],
          [
            [-84.324471, 37.553359],
            [-84.28201899999999, 37.563097],
            [-84.28178899999999, 37.585978],
            [-84.311038, 37.574573],
            [-84.324471, 37.553359],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03720',
        AFFGEOID: '9700000US2103720',
        GEOID: '2103720',
        NAME: 'Madison County School District',
        LSAD: '00',
        ALAND: 1122746329,
        AWATER: 15374347,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.21386299999999, 37.238648],
            [-83.193473, 37.279443],
            [-83.153758, 37.251523],
            [-83.161332, 37.23576],
            [-83.20454699999999, 37.221691],
            [-83.21386299999999, 37.238648],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02670',
        AFFGEOID: '9700000US2102670',
        GEOID: '2102670',
        NAME: 'Hazard Independent School District',
        LSAD: '00',
        ALAND: 19417273,
        AWATER: 374773,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.27463, 38.213471],
            [-84.24496099999999, 38.224489],
            [-84.23409099999999, 38.215579],
            [-84.265136, 38.196984],
            [-84.27463, 38.213471],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04710',
        AFFGEOID: '9700000US2104710',
        GEOID: '2104710',
        NAME: 'Paris Independent School District',
        LSAD: '00',
        ALAND: 5297965,
        AWATER: 40670,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.574089, 37.473732],
            [-82.572786, 37.483641],
            [-82.50847499999999, 37.493289],
            [-82.486294, 37.504819],
            [-82.467717, 37.472937],
            [-82.492013, 37.453648],
            [-82.527307, 37.445475],
            [-82.574089, 37.473732],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04830',
        AFFGEOID: '9700000US2104830',
        GEOID: '2104830',
        NAME: 'Pikeville Independent School District',
        LSAD: '00',
        ALAND: 37779486,
        AWATER: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.162031, 38.554125],
            [-84.146365, 38.549802],
            [-84.090769, 38.595439999999996],
            [-84.050252, 38.602592],
            [-84.023322, 38.584174999999995],
            [-83.99160499999999, 38.593700999999996],
            [-83.9683, 38.58277],
            [-83.960962, 38.532872],
            [-83.930348, 38.492278],
            [-83.97001, 38.463997],
            [-83.980031, 38.439159],
            [-84.01014599999999, 38.432871999999996],
            [-84.073362, 38.457406999999996],
            [-84.10210000000001, 38.459379],
            [-84.161372, 38.501785999999996],
            [-84.178484, 38.532773999999996],
            [-84.162031, 38.554125],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05040',
        AFFGEOID: '9700000US2105040',
        GEOID: '2105040',
        NAME: 'Robertson County School District',
        LSAD: '00',
        ALAND: 258774035,
        AWATER: 484952,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.70795299999999, 37.177634],
            [-87.688572, 37.179848],
            [-87.67624699999999, 37.149412999999996],
            [-87.680617, 37.149239],
            [-87.70795299999999, 37.177634],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01500',
        AFFGEOID: '9700000US2101500',
        GEOID: '2101500',
        NAME: 'Dawson Springs Independent School District',
        LSAD: '00',
        ALAND: 7320508,
        AWATER: 173796,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.157347, 36.867183],
            [-88.002697, 36.910168],
            [-87.890669, 36.960144],
            [-87.87699599999999, 36.960116],
            [-87.858088, 36.950714],
            [-87.76486799999999, 36.974737],
            [-87.733368, 37.002103],
            [-87.659724, 36.966397],
            [-87.671871, 36.878892],
            [-87.67376999999999, 36.755855],
            [-87.682749, 36.703365999999995],
            [-87.71851099999999, 36.72663],
            [-87.80336299999999, 36.718723],
            [-87.794364, 36.697663999999996],
            [-87.7971637006779, 36.6347478025861],
            [-87.85320399999999, 36.633247],
            [-87.849567, 36.663700999999996],
            [-88.011792, 36.677025],
            [-88.070532, 36.678118],
            [-88.084215, 36.713147],
            [-88.11083099999999, 36.74715],
            [-88.129464, 36.806259],
            [-88.157347, 36.867183],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05580',
        AFFGEOID: '9700000US2105580',
        GEOID: '2105580',
        NAME: 'Trigg County School District',
        LSAD: '00',
        ALAND: 1051860056,
        AWATER: 103305510,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.351187, 36.832302],
            [-83.32340599999999, 36.864368999999996],
            [-83.296746, 36.864922],
            [-83.29771699999999, 36.838074],
            [-83.351187, 36.832302],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02520',
        AFFGEOID: '9700000US2102520',
        GEOID: '2102520',
        NAME: 'Harlan Independent School District',
        LSAD: '00',
        ALAND: 14274289,
        AWATER: 243188,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.67702299999999, 36.737487],
            [-88.629224, 36.751545],
            [-88.62310000000001, 36.728795],
            [-88.661597, 36.715568999999995],
            [-88.67702299999999, 36.737487],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03900',
        AFFGEOID: '9700000US2103900',
        GEOID: '2103900',
        NAME: 'Mayfield Independent School District',
        LSAD: '00',
        ALAND: 14058375,
        AWATER: 39426,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9270921983773, 37.9017059445802],
            [-87.819886, 37.76835],
            [-87.772691, 37.766692],
            [-87.75565, 37.727762999999996],
            [-87.754778, 37.671332],
            [-87.734545, 37.638376],
            [-87.807403, 37.576439],
            [-87.932952, 37.480052],
            [-87.955182, 37.47893],
            [-87.999738, 37.514610999999995],
            [-88.061342239857, 37.505326559522494],
            [-88.069018, 37.525296999999995],
            [-88.133393, 37.574235],
            [-88.158374, 37.639948],
            [-88.15820699999999, 37.664542],
            [-88.125033, 37.707094],
            [-88.063802, 37.738645],
            [-88.035827, 37.791917],
            [-88.01514399999999, 37.80193],
            [-87.94450599999999, 37.775256],
            [-87.934936, 37.79522],
            [-87.90681, 37.807624],
            [-87.914892, 37.849618],
            [-87.936228, 37.867937],
            [-87.9270921983773, 37.9017059445802],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05640',
        AFFGEOID: '9700000US2105640',
        GEOID: '2105640',
        NAME: 'Union County School District',
        LSAD: '00',
        ALAND: 887974689,
        AWATER: 53167465,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.495331, 37.647546999999996],
            [-87.472811, 37.664868999999996],
            [-87.42053299999999, 37.670153],
            [-87.408547, 37.683563],
            [-87.26160999999999, 37.622009],
            [-87.095524, 37.566953999999996],
            [-87.057272, 37.570640999999995],
            [-87.03826, 37.560803],
            [-87.103111, 37.4809],
            [-87.099803, 37.416042],
            [-87.17106799999999, 37.413589],
            [-87.20917, 37.382238],
            [-87.295965, 37.391819],
            [-87.351089, 37.424929],
            [-87.361657, 37.488051999999996],
            [-87.350016, 37.514142],
            [-87.37514499999999, 37.569987999999995],
            [-87.39697, 37.589253],
            [-87.464462, 37.593761],
            [-87.499647, 37.627445],
            [-87.495331, 37.647546999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04020',
        AFFGEOID: '9700000US2104020',
        GEOID: '2104020',
        NAME: 'McLean County School District',
        LSAD: '00',
        ALAND: 653906323,
        AWATER: 9735021,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.259371, 37.072401],
            [-87.250256, 37.040557],
            [-87.11806899999999, 37.044731999999996],
            [-87.053164, 37.061019],
            [-87.058188, 36.804718],
            [-87.05015999999999, 36.767516],
            [-87.059535, 36.747951],
            [-87.0608458150142, 36.6432171572868],
            [-87.33597953375501, 36.6415426709465],
            [-87.259371, 37.072401],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05550',
        AFFGEOID: '9700000US2105550',
        GEOID: '2105550',
        NAME: 'Todd County School District',
        LSAD: '00',
        ALAND: 969942076,
        AWATER: 6629425,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.37486799999999, 37.34563],
            [-85.33331299999999, 37.360524],
            [-85.321049, 37.341629999999995],
            [-85.343367, 37.32169],
            [-85.37486799999999, 37.34563],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00930',
        AFFGEOID: '9700000US2100930',
        GEOID: '2100930',
        NAME: 'Campbellsville Independent School District',
        LSAD: '00',
        ALAND: 15716908,
        AWATER: 256000,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.83873899999999, 37.810206],
            [-82.836838, 37.826676],
            [-82.790376, 37.819700000000005],
            [-82.803309, 37.794255],
            [-82.83873899999999, 37.810206],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04680',
        AFFGEOID: '9700000US2104680',
        GEOID: '2104680',
        NAME: 'Paintsville Independent School District',
        LSAD: '00',
        ALAND: 15067288,
        AWATER: 276792,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.31400599999999, 38.492591999999995],
            [-85.27962699999999, 38.496268],
            [-85.247716, 38.526109999999996],
            [-85.16826999999999, 38.585448],
            [-85.07458299999999, 38.596838],
            [-85.07593, 38.578018],
            [-85.009185, 38.561124],
            [-85.028578, 38.506657],
            [-84.98502599999999, 38.518405],
            [-84.988781, 38.487085],
            [-84.955507, 38.472389],
            [-84.96322599999999, 38.437059],
            [-84.880248, 38.41618],
            [-84.89759099999999, 38.389558],
            [-84.870482, 38.356750999999996],
            [-84.906256, 38.374835999999995],
            [-84.99766699999999, 38.335586],
            [-85.144239, 38.347225],
            [-85.145212, 38.378899],
            [-85.16690799999999, 38.402941],
            [-85.238457, 38.389129],
            [-85.236117, 38.35441],
            [-85.283082, 38.358053999999996],
            [-85.325109, 38.404077],
            [-85.331779, 38.443112],
            [-85.34608, 38.45954],
            [-85.31400599999999, 38.492591999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02760',
        AFFGEOID: '9700000US2102760',
        GEOID: '2102760',
        NAME: 'Henry County School District',
        LSAD: '00',
        ALAND: 704119078,
        AWATER: 11778835,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.95326999999999, 36.960671999999995],
            [-85.94713, 36.99893],
            [-85.929009, 37.024764],
            [-85.902738, 37.028985],
            [-85.887716, 37.003467],
            [-85.90458, 36.968494],
            [-85.95326999999999, 36.960671999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02220',
        AFFGEOID: '9700000US2102220',
        GEOID: '2102220',
        NAME: 'Glasgow Independent School District',
        LSAD: '00',
        ALAND: 27972917,
        AWATER: 65993,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.03052799999999, 37.891538],
            [-84.987192, 37.905657],
            [-84.973351, 37.935511],
            [-84.93308499999999, 37.954186],
            [-84.884146, 37.940056],
            [-84.79632699999999, 37.970088],
            [-84.819816, 37.950944],
            [-84.796615, 37.935759999999995],
            [-84.794991, 37.914562],
            [-84.759052, 37.874272999999995],
            [-84.765204, 37.847834999999996],
            [-84.709918, 37.861951999999995],
            [-84.723423, 37.832947],
            [-84.71692499999999, 37.815388999999996],
            [-84.70540799999999, 37.793127],
            [-84.71554599999999, 37.768923],
            [-84.753896, 37.786608],
            [-84.79285399999999, 37.781479999999995],
            [-84.800033, 37.743036],
            [-84.782782, 37.716324],
            [-84.807867, 37.704614],
            [-85.025021, 37.678854],
            [-85.00068499999999, 37.854794],
            [-85.03052799999999, 37.891538],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04110',
        AFFGEOID: '9700000US2104110',
        GEOID: '2104110',
        NAME: 'Mercer County School District',
        LSAD: '00',
        ALAND: 592445453,
        AWATER: 8237238,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.201296, 36.946735],
            [-84.17422599999999, 36.940301999999996],
            [-84.150645, 36.967487999999996],
            [-84.120938, 36.968742999999996],
            [-84.091492, 36.956575],
            [-84.08964999999999, 36.94142],
            [-84.086393, 36.916703999999996],
            [-84.12951, 36.918093],
            [-84.18104799999999, 36.897971],
            [-84.20015699999999, 36.899001999999996],
            [-84.201296, 36.946735],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01320',
        AFFGEOID: '9700000US2101320',
        GEOID: '2101320',
        NAME: 'Corbin Independent School District',
        LSAD: '00',
        ALAND: 49784908,
        AWATER: 3252453,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.240371, 36.981911],
            [-88.209221, 36.999976],
            [-88.22687499999999, 37.028473],
            [-88.216295, 37.056225],
            [-88.231145, 37.081575],
            [-88.217537, 37.094848],
            [-88.210909, 37.130426],
            [-88.193519, 37.147256],
            [-88.163384, 37.143153999999996],
            [-88.110328, 37.159838],
            [-88.095781, 37.179684],
            [-88.04228499999999, 37.174774],
            [-87.97507999999999, 37.067389999999996],
            [-87.87699599999999, 36.960116],
            [-87.890669, 36.960144],
            [-88.002697, 36.910168],
            [-88.157347, 36.867183],
            [-88.180283, 36.900827],
            [-88.186458, 36.929767],
            [-88.240371, 36.981911],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03690',
        AFFGEOID: '9700000US2103690',
        GEOID: '2103690',
        NAME: 'Lyon County School District',
        LSAD: '00',
        ALAND: 553844796,
        AWATER: 110561084,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.56606099999999, 37.075204],
            [-88.526674, 37.035247],
            [-88.482957, 37.022566],
            [-88.48410299999999, 36.942096],
            [-88.699804, 36.942774],
            [-88.81289699999999, 36.946864],
            [-88.815952, 36.954100000000004],
            [-88.9334049588957, 37.2251870778059],
            [-88.80572, 37.188595],
            [-88.73210499999999, 37.143955999999996],
            [-88.687767, 37.139378],
            [-88.61144, 37.112745],
            [-88.56606099999999, 37.075204],
          ],
          [
            [-88.66646399999999, 37.071714],
            [-88.605931, 37.052416],
            [-88.58530999999999, 37.071932],
            [-88.596526, 37.091344],
            [-88.63132, 37.100454],
            [-88.66646399999999, 37.071714],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03960',
        AFFGEOID: '9700000US2103960',
        GEOID: '2103960',
        NAME: 'McCracken County School District',
        LSAD: '00',
        ALAND: 616796838,
        AWATER: 50241270,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.66646399999999, 37.071714],
            [-88.63132, 37.100454],
            [-88.596526, 37.091344],
            [-88.58530999999999, 37.071932],
            [-88.605931, 37.052416],
            [-88.66646399999999, 37.071714],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04650',
        AFFGEOID: '9700000US2104650',
        GEOID: '2104650',
        NAME: 'Paducah Independent School District',
        LSAD: '00',
        ALAND: 27454098,
        AWATER: 65302,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.030172, 37.63127],
            [-85.025021, 37.678854],
            [-84.807867, 37.704614],
            [-84.782782, 37.716324],
            [-84.744888, 37.713074999999996],
            [-84.704273, 37.697632999999996],
            [-84.698217, 37.668506],
            [-84.65829599999999, 37.634501],
            [-84.722782, 37.591868999999996],
            [-84.75682599999999, 37.588362],
            [-84.847326, 37.547894],
            [-84.8981, 37.532112999999995],
            [-85.002183, 37.533628],
            [-85.03967399999999, 37.545232],
            [-85.030172, 37.63127],
          ],
          [
            [-84.810284, 37.649257999999996],
            [-84.804637, 37.632807],
            [-84.726115, 37.625434],
            [-84.742774, 37.657802],
            [-84.792423, 37.661953],
            [-84.810284, 37.649257999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00630',
        AFFGEOID: '9700000US2100630',
        GEOID: '2100630',
        NAME: 'Boyle County School District',
        LSAD: '00',
        ALAND: 434781318,
        AWATER: 6256258,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.623732315066, 39.0744265071074],
            [-84.61720199999999, 39.022452],
            [-84.614042, 38.997561],
            [-84.600409, 38.892866999999995],
            [-84.655057, 38.860355999999996],
            [-84.71684599999999, 38.810919999999996],
            [-84.760015, 38.847187999999996],
            [-84.794616596501, 38.857119076861395],
            [-84.78814299999999, 38.883728],
            [-84.81274599999999, 38.895132],
            [-84.860759, 38.897653999999996],
            [-84.87776199999999, 38.920356999999996],
            [-84.83516, 38.957961],
            [-84.83712, 38.988059],
            [-84.897171, 39.052406999999995],
            [-84.831197, 39.10192],
            [-84.783991, 39.11806],
            [-84.754449, 39.146657999999995],
            [-84.718548, 39.137059],
            [-84.689747, 39.104158999999996],
            [-84.623732315066, 39.0744265071074],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00510',
        AFFGEOID: '9700000US2100510',
        GEOID: '2100510',
        NAME: 'Boone County School District',
        LSAD: '00',
        ALAND: 575051789,
        AWATER: 26205003,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7632948135703, 36.648907168479305],
            [-86.75134, 36.72712],
            [-86.74058, 36.754971999999995],
            [-86.704274, 36.809729],
            [-86.690719, 36.844300000000004],
            [-86.65588799999999, 36.876256],
            [-86.611586, 36.883057],
            [-86.583289, 36.830282],
            [-86.40051799999999, 36.807226],
            [-86.40576899999999, 36.776187],
            [-86.413845, 36.723076999999996],
            [-86.41138614351739, 36.6505962464493],
            [-86.50777099999999, 36.652445],
            [-86.564143, 36.633472],
            [-86.589906, 36.652485999999996],
            [-86.7632948135703, 36.648907168479305],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05400',
        AFFGEOID: '9700000US2105400',
        GEOID: '2105400',
        NAME: 'Simpson County School District',
        LSAD: '00',
        ALAND: 606584091,
        AWATER: 5857356,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.583497, 37.469916999999995],
            [-85.528855, 37.454066999999995],
            [-85.502771, 37.482303],
            [-85.46625, 37.465595],
            [-85.42532299999999, 37.474385999999996],
            [-85.381385, 37.465508],
            [-85.355364, 37.470974],
            [-85.287965, 37.436788],
            [-85.272278, 37.417282],
            [-85.246195, 37.420361],
            [-85.222177, 37.444776999999995],
            [-85.16956499999999, 37.464003999999996],
            [-85.1288, 37.453261],
            [-85.07377199999999, 37.413669],
            [-85.08252399999999, 37.400586],
            [-85.13116199999999, 37.384265],
            [-85.131241, 37.365062],
            [-85.165174, 37.310396],
            [-85.192542, 37.272138999999996],
            [-85.22359399999999, 37.262494],
            [-85.272446, 37.260031999999995],
            [-85.314663, 37.240739999999995],
            [-85.317235, 37.223912999999996],
            [-85.35277099999999, 37.192432],
            [-85.371895, 37.221312],
            [-85.406476, 37.222076],
            [-85.39277899999999, 37.249324],
            [-85.478081, 37.363673],
            [-85.58447699999999, 37.432091],
            [-85.583497, 37.469916999999995],
          ],
          [
            [-85.37486799999999, 37.34563],
            [-85.343367, 37.32169],
            [-85.321049, 37.341629999999995],
            [-85.33331299999999, 37.360524],
            [-85.37486799999999, 37.34563],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05520',
        AFFGEOID: '9700000US2105520',
        GEOID: '2105520',
        NAME: 'Taylor County School District',
        LSAD: '00',
        ALAND: 674092496,
        AWATER: 26906478,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.71554599999999, 37.768923],
            [-84.702185, 37.72539],
            [-84.744888, 37.713074999999996],
            [-84.782782, 37.716324],
            [-84.800033, 37.743036],
            [-84.79285399999999, 37.781479999999995],
            [-84.753896, 37.786608],
            [-84.71554599999999, 37.768923],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00780',
        AFFGEOID: '9700000US2100780',
        GEOID: '2100780',
        NAME: 'Burgin Independent School District',
        LSAD: '00',
        ALAND: 52027455,
        AWATER: 3364086,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.48410299999999, 36.942096],
            [-88.482957, 37.022566],
            [-88.426519, 37.050079],
            [-88.362158, 37.063947],
            [-88.313133, 37.051566],
            [-88.240371, 36.981911],
            [-88.186458, 36.929767],
            [-88.180283, 36.900827],
            [-88.157347, 36.867183],
            [-88.129464, 36.806259],
            [-88.11083099999999, 36.74715],
            [-88.223366, 36.750054],
            [-88.485697, 36.750327999999996],
            [-88.48410299999999, 36.942096],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03810',
        AFFGEOID: '9700000US2103810',
        GEOID: '2103810',
        NAME: 'Marshall County School District',
        LSAD: '00',
        ALAND: 780275897,
        AWATER: 100762021,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1289623088189, 37.786109253925495],
            [-87.09931322990019, 37.7848406571326],
            [-87.089456, 37.774558999999996],
            [-87.10898, 37.735464],
            [-87.138691, 37.739011],
            [-87.1289623088189, 37.786109253925495],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04590',
        AFFGEOID: '9700000US2104590',
        GEOID: '2104590',
        NAME: 'Owensboro Independent School District',
        LSAD: '00',
        ALAND: 19517214,
        AWATER: 2204164,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.794616596501, 38.857119076861395],
            [-84.760015, 38.847187999999996],
            [-84.71684599999999, 38.810919999999996],
            [-84.660107, 38.777293],
            [-84.780766, 38.765208],
            [-84.785788, 38.720459],
            [-84.85974499999999, 38.700891],
            [-84.905579, 38.669364],
            [-84.934536, 38.66213],
            [-84.962671, 38.679586],
            [-85.0235842033963, 38.7620346655344],
            [-84.978723, 38.77928],
            [-84.932977, 38.777519],
            [-84.89393, 38.793704],
            [-84.835672, 38.784289],
            [-84.811645, 38.792766],
            [-84.82709799999999, 38.818633999999996],
            [-84.823363, 38.839196],
            [-84.794616596501, 38.857119076861395],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02130',
        AFFGEOID: '9700000US2102130',
        GEOID: '2102130',
        NAME: 'Gallatin County School District',
        LSAD: '00',
        ALAND: 254699489,
        AWATER: 16518571,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.904918, 36.874592],
            [-83.883017, 36.880981999999996],
            [-83.87852199999999, 36.859673],
            [-83.901347, 36.859119],
            [-83.904918, 36.874592],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00240',
        AFFGEOID: '9700000US2100240',
        GEOID: '2100240',
        NAME: 'Barbourville Independent School District',
        LSAD: '00',
        ALAND: 5157112,
        AWATER: 148528,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.887453, 37.711486],
            [-85.850853, 37.717253],
            [-85.844099, 37.676319],
            [-85.876031, 37.683099],
            [-85.887453, 37.711486],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01650',
        AFFGEOID: '9700000US2101650',
        GEOID: '2101650',
        NAME: 'Elizabethtown Independent School District',
        LSAD: '00',
        ALAND: 12845701,
        AWATER: 71665,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.614042, 38.997561],
            [-84.61720199999999, 39.022452],
            [-84.581938, 39.010394999999995],
            [-84.58830999999999, 38.994552999999996],
            [-84.614042, 38.997561],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01740',
        AFFGEOID: '9700000US2101740',
        GEOID: '2101740',
        NAME: 'Erlanger-Elsmere Independent School District',
        LSAD: '00',
        ALAND: 12864989,
        AWATER: 132033,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.682245, 37.228128999999996],
            [-82.62892099999999, 37.247803999999995],
            [-82.5653752160821, 37.1960922172527],
            [-82.633493, 37.154264],
            [-82.6535640843278, 37.151172506618096],
            [-82.67000399999999, 37.166211],
            [-82.652222, 37.193833],
            [-82.68667599999999, 37.19381],
            [-82.682245, 37.228128999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03000',
        AFFGEOID: '9700000US2103000',
        GEOID: '2103000',
        NAME: 'Jenkins Independent School District',
        LSAD: '00',
        ALAND: 64687192,
        AWATER: 137536,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.053164, 37.061019],
            [-86.976399, 37.073588],
            [-86.941391, 37.068981],
            [-86.84535, 37.056512999999995],
            [-86.67446199999999, 36.999766],
            [-86.611586, 36.883057],
            [-86.65588799999999, 36.876256],
            [-86.690719, 36.844300000000004],
            [-86.704274, 36.809729],
            [-86.74058, 36.754971999999995],
            [-86.75134, 36.72712],
            [-86.7632948135703, 36.648907168479305],
            [-87.0608458150142, 36.6432171572868],
            [-87.059535, 36.747951],
            [-87.05015999999999, 36.767516],
            [-87.058188, 36.804718],
            [-87.053164, 37.061019],
          ],
          [
            [-86.91449, 36.846878],
            [-86.90066999999999, 36.824036],
            [-86.858989, 36.842149],
            [-86.860599, 36.862699],
            [-86.888526, 36.867464999999996],
            [-86.91449, 36.846878],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03540',
        AFFGEOID: '9700000US2103540',
        GEOID: '2103540',
        NAME: 'Logan County School District',
        LSAD: '00',
        ALAND: 1411936290,
        AWATER: 12645724,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.709918, 37.861951999999995],
            [-84.660135, 38.003744999999995],
            [-84.59233499999999, 37.986428],
            [-84.479629, 37.940219],
            [-84.452614, 37.908989999999996],
            [-84.434309, 37.868444],
            [-84.43571999999999, 37.847201999999996],
            [-84.48512, 37.840637],
            [-84.476287, 37.801991],
            [-84.525643, 37.769497],
            [-84.555375, 37.749224999999996],
            [-84.58520899999999, 37.750613],
            [-84.611903, 37.766712999999996],
            [-84.665733, 37.783100000000005],
            [-84.679825, 37.802693999999995],
            [-84.675889, 37.826242],
            [-84.71692499999999, 37.815388999999996],
            [-84.723423, 37.832947],
            [-84.709918, 37.861951999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03030',
        AFFGEOID: '9700000US2103030',
        GEOID: '2103030',
        NAME: 'Jessamine County School District',
        LSAD: '00',
        ALAND: 445793092,
        AWATER: 6166287,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.90557, 37.542856],
            [-83.858098, 37.609204999999996],
            [-83.83246, 37.636258999999995],
            [-83.824088, 37.662974],
            [-83.780783, 37.687807],
            [-83.7496, 37.714846],
            [-83.7221, 37.71642],
            [-83.707974, 37.716463],
            [-83.62830699999999, 37.678889999999996],
            [-83.616649, 37.666925],
            [-83.522308, 37.638515999999996],
            [-83.57918599999999, 37.506249],
            [-83.657152, 37.536781],
            [-83.68043899999999, 37.511984],
            [-83.731864, 37.521158],
            [-83.74298399999999, 37.494253],
            [-83.830207, 37.486953],
            [-83.885262, 37.517688],
            [-83.90557, 37.542856],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03270',
        AFFGEOID: '9700000US2103270',
        GEOID: '2103270',
        NAME: 'Lee County School District',
        LSAD: '00',
        ALAND: 540937712,
        AWATER: 6215038,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.91449, 36.846878],
            [-86.888526, 36.867464999999996],
            [-86.860599, 36.862699],
            [-86.858989, 36.842149],
            [-86.90066999999999, 36.824036],
            [-86.91449, 36.846878],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05190',
        AFFGEOID: '9700000US2105190',
        GEOID: '2105190',
        NAME: 'Russellville Independent School District',
        LSAD: '00',
        ALAND: 18078356,
        AWATER: 42883,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1234809771075, 36.7852576789117],
            [-89.104158, 36.797284999999995],
            [-89.061815, 36.787017999999996],
            [-89.06726499999999, 36.772763],
            [-88.81322899999999, 36.773115],
            [-88.81959499999999, 36.755905999999996],
            [-88.816287, 36.682894],
            [-88.81676518603649, 36.5028152435007],
            [-88.834865800811, 36.5029112012574],
            [-88.84354499999999, 36.512285999999996],
            [-88.861633, 36.531822999999996],
            [-88.903877, 36.576975],
            [-88.940873, 36.591514],
            [-89.026708, 36.592124],
            [-89.147098, 36.649117],
            [-89.17468187525279, 36.6504726524698],
            [-89.15908, 36.666351999999996],
            [-89.169522, 36.688877999999995],
            [-89.19948, 36.716045],
            [-89.197808, 36.739412],
            [-89.166888, 36.759633],
            [-89.126134, 36.751735],
            [-89.1234809771075, 36.7852576789117],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02790',
        AFFGEOID: '9700000US2102790',
        GEOID: '2102790',
        NAME: 'Hickman County School District',
        LSAD: '00',
        ALAND: 627487975,
        AWATER: 27882017,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.657332, 37.421996],
            [-85.665391, 37.436755999999995],
            [-85.638227, 37.454074999999996],
            [-85.583497, 37.469916999999995],
            [-85.58447699999999, 37.432091],
            [-85.478081, 37.363673],
            [-85.39277899999999, 37.249324],
            [-85.406476, 37.222076],
            [-85.371895, 37.221312],
            [-85.35277099999999, 37.192432],
            [-85.380258, 37.168313999999995],
            [-85.526881, 37.109449999999995],
            [-85.632313, 37.125056],
            [-85.660898, 37.143039],
            [-85.686482, 37.182427],
            [-85.67899, 37.249995999999996],
            [-85.682301, 37.288443],
            [-85.69732499999999, 37.301838],
            [-85.685485, 37.361306],
            [-85.652906, 37.391054],
            [-85.657332, 37.421996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02340',
        AFFGEOID: '9700000US2102340',
        GEOID: '2102340',
        NAME: 'Green County School District',
        LSAD: '00',
        ALAND: 740837706,
        AWATER: 7123457,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.634023, 37.083850999999996],
            [-84.614856, 37.111332],
            [-84.58350399999999, 37.107549999999996],
            [-84.57153799999999, 37.086169],
            [-84.612776, 37.068245],
            [-84.634023, 37.083850999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05430',
        AFFGEOID: '9700000US2105430',
        GEOID: '2105430',
        NAME: 'Somerset Independent School District',
        LSAD: '00',
        ALAND: 17669782,
        AWATER: 13524,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.847326, 37.547894],
            [-84.75682599999999, 37.588362],
            [-84.722782, 37.591868999999996],
            [-84.65829599999999, 37.634501],
            [-84.65256699999999, 37.61821],
            [-84.473024, 37.494482999999995],
            [-84.446719, 37.485893],
            [-84.502932, 37.328801],
            [-84.556461, 37.355729],
            [-84.72071199999999, 37.238327999999996],
            [-84.723978, 37.286791],
            [-84.706122, 37.299835],
            [-84.722826, 37.360043],
            [-84.796104, 37.390556],
            [-84.850816, 37.427143],
            [-84.86013, 37.467349999999996],
            [-84.857086, 37.528518999999996],
            [-84.847326, 37.547894],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03480',
        AFFGEOID: '9700000US2103480',
        GEOID: '2103480',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        ALAND: 861773600,
        AWATER: 9315415,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.569728, 39.042884],
            [-84.548338, 39.065449],
            [-84.539667, 39.041115],
            [-84.569728, 39.042884],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00360',
        AFFGEOID: '9700000US2100360',
        GEOID: '2100360',
        NAME: 'Beechwood Independent School District',
        LSAD: '00',
        ALAND: 6872752,
        AWATER: 36616,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.54872, 39.081866],
            [-84.53724371521729, 39.097441317755695],
            [-84.50608223252529, 39.0950807457156],
            [-84.49775199999999, 39.076947],
            [-84.489401, 39.032689999999995],
            [-84.501228, 39.03369],
            [-84.52917599999999, 39.078092999999996],
            [-84.54872, 39.081866],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01350',
        AFFGEOID: '9700000US2101350',
        GEOID: '2101350',
        NAME: 'Covington Independent School District',
        LSAD: '00',
        ALAND: 16489365,
        AWATER: 1269512,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5718392746594, 39.0822569566226],
            [-84.53724371521729, 39.097441317755695],
            [-84.54872, 39.081866],
            [-84.5718392746594, 39.0822569566226],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '03630',
        AFFGEOID: '9700000US2103630',
        GEOID: '2103630',
        NAME: 'Ludlow Independent School District',
        LSAD: '00',
        ALAND: 3207022,
        AWATER: 1261746,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.43291605412969, 38.52405813652349],
            [-85.378743, 38.518822],
            [-85.31400599999999, 38.492591999999995],
            [-85.34608, 38.45954],
            [-85.331779, 38.443112],
            [-85.325109, 38.404077],
            [-85.283082, 38.358053999999996],
            [-85.324563, 38.310085],
            [-85.38587199999999, 38.308009999999996],
            [-85.445083, 38.299445999999996],
            [-85.468206, 38.285309],
            [-85.63804773991271, 38.3802883493094],
            [-85.620521, 38.423105],
            [-85.587758, 38.450495],
            [-85.536542, 38.456083],
            [-85.49886599999999, 38.468242],
            [-85.47435399999999, 38.504073999999996],
            [-85.43291605412969, 38.52405813652349],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04530',
        AFFGEOID: '9700000US2104530',
        GEOID: '2104530',
        NAME: 'Oldham County School District',
        LSAD: '00',
        ALAND: 484931825,
        AWATER: 23831365,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.33305185946669, 38.7360828292115],
            [-85.329683, 38.725817],
            [-85.229151, 38.627205],
            [-85.21137499999999, 38.580216],
            [-85.16826999999999, 38.585448],
            [-85.247716, 38.526109999999996],
            [-85.27962699999999, 38.496268],
            [-85.31400599999999, 38.492591999999995],
            [-85.378743, 38.518822],
            [-85.43291605412969, 38.52405813652349],
            [-85.415821, 38.563558],
            [-85.439351, 38.610388],
            [-85.43874199999999, 38.659318999999996],
            [-85.455486, 38.682089999999995],
            [-85.452114, 38.709348],
            [-85.41092499999999, 38.73708],
            [-85.372284, 38.730576],
            [-85.33305185946669, 38.7360828292115],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05610',
        AFFGEOID: '9700000US2105610',
        GEOID: '2105610',
        NAME: 'Trimble County School District',
        LSAD: '00',
        ALAND: 392766219,
        AWATER: 11833390,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.196986, 36.729468],
            [-84.196452, 36.747276],
            [-84.13753, 36.761419],
            [-84.162318, 36.721148],
            [-84.196986, 36.729468],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05910',
        AFFGEOID: '9700000US2105910',
        GEOID: '2105910',
        NAME: 'Williamsburg Independent School District',
        LSAD: '00',
        ALAND: 14576821,
        AWATER: 408722,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.532288, 38.791937],
            [-84.41810699999999, 38.806596],
            [-84.387389, 38.812323],
            [-84.23191434799891, 38.8748661027637],
            [-84.23042691143739, 38.8274224447174],
            [-84.235962, 38.822361],
            [-84.20534599999999, 38.582039],
            [-84.40032099999999, 38.560603],
            [-84.452635, 38.531025],
            [-84.476299, 38.542722999999995],
            [-84.532288, 38.791937],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04740',
        AFFGEOID: '9700000US2104740',
        GEOID: '2104740',
        NAME: 'Pendleton County School District',
        LSAD: '00',
        ALAND: 717839313,
        AWATER: 12325351,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.23202599999999, 36.925069],
            [-85.20583099999999, 36.996862],
            [-85.166186, 37.033265],
            [-85.04331599999999, 37.186028],
            [-84.97047099999999, 37.112941],
            [-84.95440599999999, 37.102271],
            [-84.901112, 37.116296999999996],
            [-84.905778, 37.047187],
            [-84.863737, 37.008019999999995],
            [-84.835712, 36.997612],
            [-84.907859, 36.945447],
            [-84.950428, 36.955467],
            [-84.960878, 36.916337999999996],
            [-85.064307, 36.858743],
            [-85.118411, 36.827334],
            [-85.19698799999999, 36.874052999999996],
            [-85.21540499999999, 36.854467],
            [-85.27494999999999, 36.897901],
            [-85.23202599999999, 36.925069],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05160',
        AFFGEOID: '9700000US2105160',
        GEOID: '2105160',
        NAME: 'Russell County School District',
        LSAD: '00',
        ALAND: 656969513,
        AWATER: 75674970,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.660107, 38.777293],
            [-84.656167, 38.790116999999995],
            [-84.61574, 38.802192],
            [-84.597775, 38.79117],
            [-84.532288, 38.791937],
            [-84.476299, 38.542722999999995],
            [-84.485295, 38.508552],
            [-84.52139299999999, 38.508299],
            [-84.557375, 38.492917],
            [-84.580539, 38.473039],
            [-84.626651, 38.485132],
            [-84.674127, 38.555119],
            [-84.774322, 38.618594],
            [-84.785788, 38.720459],
            [-84.780766, 38.765208],
            [-84.660107, 38.777293],
          ],
          [
            [-84.61735999999999, 38.644335],
            [-84.572682, 38.614941],
            [-84.543644, 38.616873],
            [-84.521344, 38.637119999999996],
            [-84.54536399999999, 38.662493999999995],
            [-84.59020799999999, 38.657874],
            [-84.61735999999999, 38.644335],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02250',
        AFFGEOID: '9700000US2102250',
        GEOID: '2102250',
        NAME: 'Grant County School District',
        LSAD: '00',
        ALAND: 637184980,
        AWATER: 7063560,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.709941, 38.544923],
            [-82.727093, 38.525678],
            [-82.76045599999999, 38.522743],
            [-82.761276, 38.556597],
            [-82.709941, 38.544923],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04980',
        AFFGEOID: '9700000US2104980',
        GEOID: '2104980',
        NAME: 'Raceland-Worthington Independent School District',
        LSAD: '00',
        ALAND: 12155457,
        AWATER: 238223,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.42602045601609, 39.053312201007195],
            [-84.3697761230449, 39.0427059289258],
            [-84.40021, 39.033716999999996],
            [-84.42602045601609, 39.053312201007195],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05370',
        AFFGEOID: '9700000US2105370',
        GEOID: '2105370',
        NAME: 'Silver Grove Independent School District',
        LSAD: '00',
        ALAND: 5109114,
        AWATER: 2333348,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.157589, 37.335649],
            [-86.049133, 37.215285],
            [-86.056443, 37.167207999999995],
            [-86.07426699999999, 37.053284999999995],
            [-86.113512, 37.061071999999996],
            [-86.192246, 37.076696],
            [-86.21860699999999, 37.104029],
            [-86.28175, 37.080597],
            [-86.399165, 37.169896],
            [-86.469126, 37.321099],
            [-86.32068799999999, 37.32673],
            [-86.157589, 37.335649],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01620',
        AFFGEOID: '9700000US2101620',
        GEOID: '2101620',
        NAME: 'Edmonson County School District',
        LSAD: '00',
        ALAND: 784468319,
        AWATER: 13258106,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.064307, 36.858743],
            [-84.960878, 36.916337999999996],
            [-84.950428, 36.955467],
            [-84.907859, 36.945447],
            [-84.835712, 36.997612],
            [-84.783794, 36.978483],
            [-84.77024399999999, 36.957845999999996],
            [-84.679165, 36.980914],
            [-84.62023099999999, 36.896321],
            [-84.578158, 36.867132999999995],
            [-84.57933899999999, 36.814397],
            [-84.62256, 36.793932999999996],
            [-84.634323, 36.769779],
            [-84.67231199999999, 36.758445],
            [-84.67424299999999, 36.73916],
            [-84.7784559169908, 36.6032090178664],
            [-84.843091, 36.605126999999996],
            [-84.9748750576222, 36.6155518237457],
            [-84.999106, 36.624161],
            [-84.998144, 36.663097],
            [-85.020476, 36.69435],
            [-85.004099, 36.756237],
            [-85.026377, 36.806912],
            [-85.059167, 36.833096999999995],
            [-85.064307, 36.858743],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05790',
        AFFGEOID: '9700000US2105790',
        GEOID: '2105790',
        NAME: 'Wayne County School District',
        LSAD: '00',
        ALAND: 1186687833,
        AWATER: 67286710,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.932952, 37.480052],
            [-87.807403, 37.576439],
            [-87.734545, 37.638376],
            [-87.495331, 37.647546999999996],
            [-87.499647, 37.627445],
            [-87.464462, 37.593761],
            [-87.39697, 37.589253],
            [-87.37514499999999, 37.569987999999995],
            [-87.49866999999999, 37.487058999999995],
            [-87.593575, 37.461963],
            [-87.813413, 37.350645],
            [-87.801046, 37.379445],
            [-87.847381, 37.375583],
            [-87.847034, 37.420752],
            [-87.90303399999999, 37.398196999999996],
            [-87.928767, 37.408632],
            [-87.943955, 37.465225],
            [-87.932952, 37.480052],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05820',
        AFFGEOID: '9700000US2105820',
        GEOID: '2105820',
        NAME: 'Webster County School District',
        LSAD: '00',
        ALAND: 859730022,
        AWATER: 9613041,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.42882999999999, 38.118383],
            [-85.424054, 38.147438],
            [-85.232636, 38.089501],
            [-85.101646, 38.03727],
            [-85.126452, 38.020292],
            [-85.131052, 37.99588],
            [-85.166291, 37.987519999999996],
            [-85.167834, 37.971809],
            [-85.399559, 37.939929],
            [-85.425822, 37.959632],
            [-85.463438, 37.971377],
            [-85.48920199999999, 37.990654],
            [-85.511923, 38.012876999999996],
            [-85.469231, 38.096222],
            [-85.42882999999999, 38.118383],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05490',
        AFFGEOID: '9700000US2105490',
        GEOID: '2105490',
        NAME: 'Spencer County School District',
        LSAD: '00',
        ALAND: 483493837,
        AWATER: 12896776,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.744221, 37.169813],
            [-85.686482, 37.182427],
            [-85.660898, 37.143039],
            [-85.632313, 37.125056],
            [-85.526881, 37.109449999999995],
            [-85.522736, 37.067339],
            [-85.50362899999999, 37.00768],
            [-85.441755, 36.954076],
            [-85.45183, 36.938137999999995],
            [-85.47422, 36.893823],
            [-85.50737699999999, 36.867509],
            [-85.56071899999999, 36.850857],
            [-85.59621399999999, 36.818039],
            [-85.613232, 36.832733],
            [-85.739255, 36.841476],
            [-85.750396, 37.005902],
            [-85.759278, 37.068573],
            [-85.75609, 37.109835],
            [-85.776962, 37.138328],
            [-85.744221, 37.169813],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04140',
        AFFGEOID: '9700000US2104140',
        GEOID: '2104140',
        NAME: 'Metcalfe County School District',
        LSAD: '00',
        ALAND: 750178423,
        AWATER: 3999881,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.512922, 37.828987],
            [-85.48281999999999, 37.852768999999995],
            [-85.419581, 37.834578],
            [-85.451661, 37.798753],
            [-85.486497, 37.788604],
            [-85.512922, 37.828987],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00270',
        AFFGEOID: '9700000US2100270',
        GEOID: '2100270',
        NAME: 'Bardstown Independent School District',
        LSAD: '00',
        ALAND: 36487933,
        AWATER: 439450,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.526881, 37.109449999999995],
            [-85.380258, 37.168313999999995],
            [-85.35277099999999, 37.192432],
            [-85.317235, 37.223912999999996],
            [-85.314663, 37.240739999999995],
            [-85.272446, 37.260031999999995],
            [-85.22359399999999, 37.262494],
            [-85.192542, 37.272138999999996],
            [-85.165174, 37.310396],
            [-85.05689799999999, 37.257041],
            [-85.05356499999999, 37.196746],
            [-85.04331599999999, 37.186028],
            [-85.166186, 37.033265],
            [-85.20583099999999, 36.996862],
            [-85.23202599999999, 36.925069],
            [-85.45183, 36.938137999999995],
            [-85.441755, 36.954076],
            [-85.50362899999999, 37.00768],
            [-85.522736, 37.067339],
            [-85.526881, 37.109449999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00030',
        AFFGEOID: '9700000US2100030',
        GEOID: '2100030',
        NAME: 'Adair County School District',
        LSAD: '00',
        ALAND: 1049678094,
        AWATER: 18430783,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.71684599999999, 38.810919999999996],
            [-84.655057, 38.860355999999996],
            [-84.600409, 38.892866999999995],
            [-84.59803099999999, 38.87954],
            [-84.61574, 38.802192],
            [-84.656167, 38.790116999999995],
            [-84.660107, 38.777293],
            [-84.71684599999999, 38.810919999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05700',
        AFFGEOID: '9700000US2105700',
        GEOID: '2105700',
        NAME: 'Walton-Verona Independent School District',
        LSAD: '00',
        ALAND: 62644693,
        AWATER: 337812,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.71653099999999, 36.755756999999996],
            [-83.711822, 36.769501],
            [-83.688825, 36.768329],
            [-83.69344099999999, 36.746218999999996],
            [-83.70367399999999, 36.757748],
            [-83.71653099999999, 36.755756999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04860',
        AFFGEOID: '9700000US2104860',
        GEOID: '2104860',
        NAME: 'Pineville Independent School District',
        LSAD: '00',
        ALAND: 3595548,
        AWATER: 143944,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.48996199999999, 39.102044],
            [-84.462971, 39.102123999999996],
            [-84.467506, 39.097331],
            [-84.48996199999999, 39.102044],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00420',
        AFFGEOID: '9700000US2100420',
        GEOID: '2100420',
        NAME: 'Bellevue Independent School District',
        LSAD: '00',
        ALAND: 2411609,
        AWATER: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.50608223252529, 39.0950807457156],
            [-84.4935547013056, 39.1027204798606],
            [-84.48996199999999, 39.102044],
            [-84.467506, 39.097331],
            [-84.469841, 39.075565999999995],
            [-84.481898, 39.066521],
            [-84.49775199999999, 39.076947],
            [-84.50608223252529, 39.0950807457156],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04440',
        AFFGEOID: '9700000US2104440',
        GEOID: '2104440',
        NAME: 'Newport Independent School District',
        LSAD: '00',
        ALAND: 7221105,
        AWATER: 663262,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.45183, 36.938137999999995],
            [-85.23202599999999, 36.925069],
            [-85.27494999999999, 36.897901],
            [-85.21540499999999, 36.854467],
            [-85.24645799999999, 36.743958],
            [-85.2959488756198, 36.6258382676941],
            [-85.4364330342016, 36.6185230953912],
            [-85.496607, 36.690329],
            [-85.470106, 36.730098999999996],
            [-85.51742899999999, 36.731325999999996],
            [-85.542513, 36.774814],
            [-85.588751, 36.777349],
            [-85.59621399999999, 36.818039],
            [-85.56071899999999, 36.850857],
            [-85.50737699999999, 36.867509],
            [-85.47422, 36.893823],
            [-85.45183, 36.938137999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01410',
        AFFGEOID: '9700000US2101410',
        GEOID: '2101410',
        NAME: 'Cumberland County School District',
        LSAD: '00',
        ALAND: 790416910,
        AWATER: 14830126,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.892228, 38.214911],
            [-84.858948, 38.20519],
            [-84.87110299999999, 38.189122999999995],
            [-84.892228, 38.214911],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01980',
        AFFGEOID: '9700000US2101980',
        GEOID: '2101980',
        NAME: 'Frankfort Independent School District',
        LSAD: '00',
        ALAND: 5358689,
        AWATER: 416726,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.99766699999999, 38.335586],
            [-84.906256, 38.374835999999995],
            [-84.870482, 38.356750999999996],
            [-84.808762, 38.349306],
            [-84.79341199999999, 38.338668999999996],
            [-84.740594, 38.352415],
            [-84.730289, 38.208301999999996],
            [-84.725168, 38.195426],
            [-84.86480399999999, 38.141373],
            [-84.864908, 38.116926],
            [-84.907798, 38.093666],
            [-84.967393, 38.119268],
            [-85.02371099999999, 38.129052],
            [-85.00383699999999, 38.16066],
            [-85.00748, 38.175779999999996],
            [-84.998898, 38.274978999999995],
            [-84.956959, 38.332271],
            [-84.99766699999999, 38.335586],
          ],
          [
            [-84.892228, 38.214911],
            [-84.87110299999999, 38.189122999999995],
            [-84.858948, 38.20519],
            [-84.892228, 38.214911],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02010',
        AFFGEOID: '9700000US2102010',
        GEOID: '2102010',
        NAME: 'Franklin County School District',
        LSAD: '00',
        ALAND: 532714342,
        AWATER: 10608837,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.401207, 37.560398],
            [-83.368811, 37.558034],
            [-83.38724599999999, 37.540850999999996],
            [-83.401207, 37.560398],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02910',
        AFFGEOID: '9700000US2102910',
        GEOID: '2102910',
        NAME: 'Jackson Independent School District',
        LSAD: '00',
        ALAND: 4220748,
        AWATER: 109697,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.324471, 37.553359],
            [-84.311038, 37.574573],
            [-84.28178899999999, 37.585978],
            [-84.28201899999999, 37.563097],
            [-84.324471, 37.553359],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00480',
        AFFGEOID: '9700000US2100480',
        GEOID: '2100480',
        NAME: 'Berea Independent School District',
        LSAD: '00',
        ALAND: 9857602,
        AWATER: 52175,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.84354499999999, 36.512285999999996],
              [-88.834865800811, 36.5029112012574],
              [-88.8435621254683, 36.502809705461],
              [-88.84354499999999, 36.512285999999996],
            ],
          ],
          [
            [
              [-89.17468187525279, 36.6504726524698],
              [-89.147098, 36.649117],
              [-89.026708, 36.592124],
              [-88.940873, 36.591514],
              [-88.903877, 36.576975],
              [-88.861633, 36.531822999999996],
              [-88.898985, 36.540023],
              [-88.8993449449784, 36.5022123263371],
              [-89.117537, 36.503603],
              [-89.30028399999999, 36.507146999999996],
              [-89.41727499999999, 36.499010999999996],
              [-89.382762, 36.583603],
              [-89.375453, 36.615719],
              [-89.326731, 36.632186],
              [-89.294637, 36.593728999999996],
              [-89.258318, 36.564948],
              [-89.227319, 36.569375],
              [-89.202607, 36.601576],
              [-89.197654, 36.628935999999996],
              [-89.17468187525279, 36.6504726524698],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02100',
        AFFGEOID: '9700000US2102100',
        GEOID: '2102100',
        NAME: 'Fulton County School District',
        LSAD: '00',
        ALAND: 472018800,
        AWATER: 39359154,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.467506, 39.097331],
            [-84.462971, 39.102123999999996],
            [-84.4407547312217, 39.1097786325792],
            [-84.4335444846402, 39.0963453989339],
            [-84.43235, 39.055304],
            [-84.460456, 39.05394],
            [-84.469841, 39.075565999999995],
            [-84.467506, 39.097331],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02040',
        AFFGEOID: '9700000US2102040',
        GEOID: '2102040',
        NAME: 'Fort Thomas Independent School District',
        LSAD: '00',
        ALAND: 14853256,
        AWATER: 619354,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0235842033963, 38.7620346655344],
            [-84.962671, 38.679586],
            [-84.934536, 38.66213],
            [-85.02430000000001, 38.614202],
            [-85.059846, 38.611022],
            [-85.07458299999999, 38.596838],
            [-85.16826999999999, 38.585448],
            [-85.21137499999999, 38.580216],
            [-85.229151, 38.627205],
            [-85.329683, 38.725817],
            [-85.33305185946669, 38.7360828292115],
            [-85.289226, 38.74241],
            [-85.246505, 38.731821],
            [-85.226062, 38.705456],
            [-85.190507, 38.68795],
            [-85.133049, 38.702374999999996],
            [-85.100963, 38.726800000000004],
            [-85.0235842033963, 38.7620346655344],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00990',
        AFFGEOID: '9700000US2100990',
        GEOID: '2100990',
        NAME: 'Carroll County School District',
        LSAD: '00',
        ALAND: 332993895,
        AWATER: 22650069,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.07377199999999, 37.413669],
            [-85.044502, 37.419115999999995],
            [-85.03967399999999, 37.545232],
            [-85.002183, 37.533628],
            [-84.8981, 37.532112999999995],
            [-84.847326, 37.547894],
            [-84.857086, 37.528518999999996],
            [-84.86013, 37.467349999999996],
            [-84.850816, 37.427143],
            [-84.796104, 37.390556],
            [-84.722826, 37.360043],
            [-84.706122, 37.299835],
            [-84.723978, 37.286791],
            [-84.72071199999999, 37.238327999999996],
            [-84.811396, 37.17371],
            [-84.901112, 37.116296999999996],
            [-84.95440599999999, 37.102271],
            [-84.97047099999999, 37.112941],
            [-85.04331599999999, 37.186028],
            [-85.05356499999999, 37.196746],
            [-85.05689799999999, 37.257041],
            [-85.165174, 37.310396],
            [-85.131241, 37.365062],
            [-85.13116199999999, 37.384265],
            [-85.08252399999999, 37.400586],
            [-85.07377199999999, 37.413669],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01050',
        AFFGEOID: '9700000US2101050',
        GEOID: '2101050',
        NAME: 'Casey County School District',
        LSAD: '00',
        ALAND: 1150557995,
        AWATER: 3892620,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.97690999999999, 36.722722],
            [-85.807011, 36.821638],
            [-85.739255, 36.841476],
            [-85.613232, 36.832733],
            [-85.59621399999999, 36.818039],
            [-85.588751, 36.777349],
            [-85.542513, 36.774814],
            [-85.51742899999999, 36.731325999999996],
            [-85.470106, 36.730098999999996],
            [-85.496607, 36.690329],
            [-85.4364330342016, 36.6185230953912],
            [-85.48835299999999, 36.614993999999996],
            [-85.832172, 36.622046],
            [-85.9729214486467, 36.6285202945041],
            [-85.94940299999999, 36.661645],
            [-85.97690999999999, 36.722722],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04200',
        AFFGEOID: '9700000US2104200',
        GEOID: '2104200',
        NAME: 'Monroe County School District',
        LSAD: '00',
        ALAND: 853073337,
        AWATER: 6949352,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.81322899999999, 36.773115],
            [-88.81289699999999, 36.946864],
            [-88.699804, 36.942774],
            [-88.48410299999999, 36.942096],
            [-88.485697, 36.750327999999996],
            [-88.4890752146435, 36.5010676761135],
            [-88.81676518603649, 36.5028152435007],
            [-88.816287, 36.682894],
            [-88.81959499999999, 36.755905999999996],
            [-88.81322899999999, 36.773115],
          ],
          [
            [-88.67702299999999, 36.737487],
            [-88.661597, 36.715568999999995],
            [-88.62310000000001, 36.728795],
            [-88.629224, 36.751545],
            [-88.67702299999999, 36.737487],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02280',
        AFFGEOID: '9700000US2102280',
        GEOID: '2102280',
        NAME: 'Graves County School District',
        LSAD: '00',
        ALAND: 1414967657,
        AWATER: 13008139,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.682749, 36.703365999999995],
            [-87.680396, 36.669484],
            [-87.666697, 36.647],
            [-87.57108699999999, 36.646415999999995],
            [-87.524778, 36.652091999999996],
            [-87.50546899999999, 36.683799],
            [-87.46828699999999, 36.685375],
            [-87.4363215449599, 36.640751890131504],
            [-87.7971637006779, 36.6347478025861],
            [-87.794364, 36.697663999999996],
            [-87.80336299999999, 36.718723],
            [-87.71851099999999, 36.72663],
            [-87.682749, 36.703365999999995],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00078',
        AFFGEOID: '9700000US2100078',
        GEOID: '2100078',
        NAME: 'Fort Campbell Dependent Schools',
        LSAD: '00',
        ALAND: 146020321,
        AWATER: 197739,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.283082, 38.358053999999996],
            [-85.236117, 38.35441],
            [-85.144239, 38.347225],
            [-84.99766699999999, 38.335586],
            [-84.956959, 38.332271],
            [-84.998898, 38.274978999999995],
            [-85.00748, 38.175779999999996],
            [-85.00383699999999, 38.16066],
            [-85.02371099999999, 38.129052],
            [-85.03926899999999, 38.120014999999995],
            [-85.054538, 38.084223],
            [-85.047783, 38.072341],
            [-85.084032, 38.040706],
            [-85.101646, 38.03727],
            [-85.232636, 38.089501],
            [-85.424054, 38.147438],
            [-85.404899, 38.263729999999995],
            [-85.468206, 38.285309],
            [-85.445083, 38.299445999999996],
            [-85.38587199999999, 38.308009999999996],
            [-85.324563, 38.310085],
            [-85.283082, 38.358053999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '05320',
        AFFGEOID: '9700000US2105320',
        GEOID: '2105320',
        NAME: 'Shelby County School District',
        LSAD: '00',
        ALAND: 983256960,
        AWATER: 15627991,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.034245864167, 37.9652698457521],
            [-86.019654, 37.935673],
            [-86.056694, 37.912548],
            [-86.035062, 37.884161],
            [-85.985496, 37.858401],
            [-86.007386, 37.818522],
            [-86.151285, 37.798877999999995],
            [-86.24277699999999, 37.877002],
            [-86.429137, 38.000122999999995],
            [-86.4907693787391, 38.045672484270696],
            [-86.452192, 38.050489999999996],
            [-86.434046, 38.086763],
            [-86.466081, 38.114436999999995],
            [-86.431749, 38.126121],
            [-86.405068, 38.105801],
            [-86.37532399999999, 38.130629],
            [-86.335145, 38.129242],
            [-86.325941, 38.154317],
            [-86.37174, 38.164183],
            [-86.373801, 38.193352],
            [-86.347736, 38.195363],
            [-86.287773, 38.158049999999996],
            [-86.271802, 38.137874],
            [-86.278656, 38.098509],
            [-86.266891, 38.057125],
            [-86.220371, 38.027922],
            [-86.178983, 38.011308],
            [-86.108156, 38.013416],
            [-86.080034, 38.000848],
            [-86.048458, 37.959368999999995],
            [-86.034245864167, 37.9652698457521],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04050',
        AFFGEOID: '9700000US2104050',
        GEOID: '2104050',
        NAME: 'Meade County School District',
        LSAD: '00',
        ALAND: 731861294,
        AWATER: 46357494,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9788342958521, 37.9302329010012],
            [-86.91932899999999, 37.936664],
            [-86.85594999999999, 37.987292],
            [-86.82349099999999, 37.998939],
            [-86.794985, 37.988982],
            [-86.75099, 37.912893],
            [-86.718462, 37.893122999999996],
            [-86.686015, 37.913084],
            [-86.650087, 37.910616],
            [-86.64475399999999, 37.894805999999996],
            [-86.66249499999999, 37.856950999999995],
            [-86.65528645526759, 37.842505006112695],
            [-86.67280099999999, 37.811332],
            [-86.67786799999999, 37.792836],
            [-86.622316, 37.655007999999995],
            [-86.63893499999999, 37.661877],
            [-86.82342899999999, 37.737753999999995],
            [-86.81710000000001, 37.791588999999995],
            [-86.854035, 37.840810999999995],
            [-86.912531, 37.837481],
            [-86.893616, 37.882037],
            [-86.931707, 37.90974],
            [-86.982158, 37.895537],
            [-86.9788342958521, 37.9302329010012],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '02460',
        AFFGEOID: '9700000US2102460',
        GEOID: '2102460',
        NAME: 'Hancock County School District',
        LSAD: '00',
        ALAND: 486020462,
        AWATER: 29093100,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.689433, 38.489028],
            [-82.6654809868313, 38.5057370829218],
            [-82.61380199999999, 38.474529],
            [-82.6015466939101, 38.4427171017697],
            [-82.676553, 38.418804],
            [-82.672094, 38.465595],
            [-82.653679, 38.48997],
            [-82.68952999999999, 38.488776],
            [-82.689433, 38.489028],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00150',
        AFFGEOID: '9700000US2100150',
        GEOID: '2100150',
        NAME: 'Ashland Independent School District',
        LSAD: '00',
        ALAND: 34796490,
        AWATER: 3509812,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.23042691143739, 38.8274224447174],
            [-84.212904, 38.805707],
            [-84.155912, 38.794909],
            [-84.071491, 38.770475],
            [-84.02859299666821, 38.7739100289129],
            [-84.050842, 38.758814],
            [-84.001066, 38.739374999999995],
            [-83.950442, 38.764505],
            [-83.96048797779311, 38.7871529580372],
            [-83.90391694448489, 38.7681517942292],
            [-83.99160499999999, 38.593700999999996],
            [-84.023322, 38.584174999999995],
            [-84.050252, 38.602592],
            [-84.090769, 38.595439999999996],
            [-84.146365, 38.549802],
            [-84.162031, 38.554125],
            [-84.20401, 38.563961],
            [-84.20534599999999, 38.582039],
            [-84.235962, 38.822361],
            [-84.23042691143739, 38.8274224447174],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00660',
        AFFGEOID: '9700000US2100660',
        GEOID: '2100660',
        NAME: 'Bracken County School District',
        LSAD: '00',
        ALAND: 509016346,
        AWATER: 6947508,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.334594, 36.612106],
            [-88.29963, 36.617457],
            [-88.304242, 36.587638],
            [-88.325267, 36.576978],
            [-88.334594, 36.612106],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '04380',
        AFFGEOID: '9700000US2104380',
        GEOID: '2104380',
        NAME: 'Murray Independent School District',
        LSAD: '00',
        ALAND: 13635766,
        AWATER: 23080,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.10076200668469, 36.9440018170751],
            [-89.071162, 36.915559],
            [-89.020276, 36.928450999999995],
            [-88.990832, 36.919477],
            [-88.917183, 36.940843],
            [-88.815952, 36.954100000000004],
            [-88.81289699999999, 36.946864],
            [-88.81322899999999, 36.773115],
            [-89.06726499999999, 36.772763],
            [-89.061815, 36.787017999999996],
            [-89.104158, 36.797284999999995],
            [-89.1234809771075, 36.7852576789117],
            [-89.155891, 36.789125999999996],
            [-89.17741699999999, 36.807269],
            [-89.173419, 36.839805999999996],
            [-89.137969, 36.847349],
            [-89.117567, 36.887356],
            [-89.10076200668469, 36.9440018170751],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00960',
        AFFGEOID: '9700000US2100960',
        GEOID: '2100960',
        NAME: 'Carlisle County School District',
        LSAD: '00',
        ALAND: 490632724,
        AWATER: 24722530,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.485697, 36.750327999999996],
            [-88.223366, 36.750054],
            [-88.11083099999999, 36.74715],
            [-88.084215, 36.713147],
            [-88.070532, 36.678118],
            [-88.045127, 36.602939],
            [-88.032489, 36.540662],
            [-88.05320499999999, 36.497129],
            [-88.4890752146435, 36.5010676761135],
            [-88.485697, 36.750327999999996],
          ],
          [
            [-88.334594, 36.612106],
            [-88.325267, 36.576978],
            [-88.304242, 36.587638],
            [-88.29963, 36.617457],
            [-88.334594, 36.612106],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00870',
        AFFGEOID: '9700000US2100870',
        GEOID: '2100870',
        NAME: 'Calloway County School District',
        LSAD: '00',
        ALAND: 983570180,
        AWATER: 66469646,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.481295, 36.975001999999996],
            [-86.445728, 37.00839],
            [-86.409919, 36.989084],
            [-86.434663, 36.971140999999996],
            [-86.481295, 36.975001999999996],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '00570',
        AFFGEOID: '9700000US2100570',
        GEOID: '2100570',
        NAME: 'Bowling Green Independent School District',
        LSAD: '00',
        ALAND: 20274358,
        AWATER: 5469,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2959488756198, 36.6258382676941],
            [-85.24645799999999, 36.743958],
            [-85.21540499999999, 36.854467],
            [-85.19698799999999, 36.874052999999996],
            [-85.118411, 36.827334],
            [-85.064307, 36.858743],
            [-85.059167, 36.833096999999995],
            [-85.026377, 36.806912],
            [-85.004099, 36.756237],
            [-85.020476, 36.69435],
            [-84.998144, 36.663097],
            [-84.999106, 36.624161],
            [-84.9748750576222, 36.6155518237457],
            [-85.19537199999999, 36.625498],
            [-85.2959488756198, 36.6258382676941],
          ],
        ],
      },
      properties: {
        STATEFP: '21',
        UNSDLEA: '01260',
        AFFGEOID: '9700000US2101260',
        GEOID: '2101260',
        NAME: 'Clinton County School District',
        LSAD: '00',
        ALAND: 510864294,
        AWATER: 21164151,
      },
    },
  ],
};

import GA from 'react-ga4';
import { GA_MEASUREMENT_ID } from '../../config/appConfig';
import CustomDimension from './customDimension';
// eslint-disable-next-line
import HitInfo from './models/hitInfo';

export default class UniversalAnalyticsTracker {
  constructor() {
    this.analytics = GA;
  }

  /**
   * @param  {HitInfo} hitInfo
   */
  send(hitInfo) {
    if (!GA_MEASUREMENT_ID) return;
    const dimensions = this.addCustomDimensions(hitInfo);
    const options = {
      category: hitInfo.getEventInfo().getCategory(),
      action: hitInfo.getEventInfo().getAction(),
    };
    if (hitInfo.getHitType() === 'event') {
      this.analytics.event(options, dimensions);
    }
  }

  addCustomDimensions(hitInfo) {
    this.cleanDimensions();
    const dimensions = {};
    hitInfo.getCustomDimensions().forEach(({ dimension, value }) => {
      this.analytics.set({
        [`dimension${dimension.id}`]: value,
      });
      dimensions[`dimension${dimension.id}`] = value;
    });
    return dimensions;
  }

  cleanDimensions() {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const prop in CustomDimension) {
      this.analytics.set({ [`dimension${CustomDimension[prop].id}`]: null });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getHitType(hitInfo) {
    return hitInfo.getHitType();
  }
}

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setInputValue } from '../../../actions/wizard.action';
import './Input.css';

/**
 * The values of this input will be store on redux state.
 * formData: { InputName: InputValue }
 *
 * The correct submission of the data will depend
 * on the bussines logic.
 */

const Input = ({
  required, name, label, type, maxChar, isTextArea,
}) => {
  const [char, setChar] = useState(maxChar);
  const value = useSelector((state) => state.wizard.formData[name]);
  const dispatch = useDispatch();

  const onChangeHandler = (e) => {
    const inputLength = e.target.value.length;
    const maxLength = maxChar - inputLength;
    if (maxLength >= 0) {
      dispatch(setInputValue(e.target));
      setChar(maxLength);
    }
  };
  return (
    <div className="Input">
      <div className="labelContainer">
        {label
        && (
        <label htmlFor={name}>
          {label}
          {' '}
          {required && <span>*</span>}
        </label>
        )}
        { /** TBD
           required
          && <span className="required">*Required</span>
         */
        }
      </div>
      <div className="InputContainer">
        {!isTextArea
          && (
          <input
            required={required}
            id={name}
            value={value}
            name={name}
            type={type}
            onChange={onChangeHandler}
            className="form-control"
            autoComplete="off"
          />
          )}
        {isTextArea
          && (
          <textarea
            required={required}
            id={name}
            name={name}
            value={value}
            type={type}
            onChange={onChangeHandler}
            className="form-control"
            autoComplete="off"
          />
          )}
        <span className="requiredChar">
          {`${char} characters left`}
        </span>
      </div>
    </div>
  );
};

Input.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  maxChar: PropTypes.number,
  isTextArea: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

Input.defaultProps = {
  required: false,
  label: null,
  maxChar: 0,
  isTextArea: false,
};

export default Input;

export const AL = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7893, 31.4909],
            [-85.7591, 31.4838],
            [-85.6157, 31.3624],
            [-85.6757, 31.3156],
            [-85.7898, 31.3566],
            [-85.8582, 31.4658],
            [-85.7893, 31.4909],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100001',
        NAME: 'Fort Rucker School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 233061598,
        AWATER: 2735224,
        INTPTLAT: '+31.4097368',
        INTPTLON: '-085.7458071',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3766, 32.3936],
            [-86.3609, 32.398],
            [-86.3335, 32.3767],
            [-86.3715, 32.3659],
            [-86.3766, 32.3936],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100003',
        NAME: 'Maxwell AFB School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 8476761,
        AWATER: 566857,
        INTPTLAT: '+32.3809438',
        INTPTLON: '-086.3637490',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2661, 34.2978],
            [-86.2661, 34.2994],
            [-86.2577, 34.3019],
            [-86.2093, 34.2932],
            [-86.1556, 34.2326],
            [-86.1675, 34.2288],
            [-86.1737, 34.2304],
            [-86.1915, 34.2235],
            [-86.1915, 34.2216],
            [-86.1915, 34.2202],
            [-86.2509, 34.2521],
            [-86.2409, 34.2512],
            [-86.2661, 34.2978],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100005',
        NAME: 'Albertville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68731806,
        AWATER: 258708,
        INTPTLAT: '+34.2631303',
        INTPTLON: '-086.2106600',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5502, 34.546],
            [-86.5, 34.4823],
            [-86.4255, 34.4793],
            [-86.3112, 34.5393],
            [-86.3269, 34.5994],
            [-86.1485, 34.5991],
            [-86.1498, 34.5336],
            [-86.0577, 34.476],
            [-86.1104, 34.4278],
            [-86.1061, 34.2008],
            [-86.1556, 34.2326],
            [-86.2093, 34.2932],
            [-86.2577, 34.3019],
            [-86.2661, 34.2994],
            [-86.1916, 34.3758],
            [-86.2292, 34.422],
            [-86.3359, 34.3704],
            [-86.2661, 34.2978],
            [-86.2409, 34.2512],
            [-86.2509, 34.2521],
            [-86.1915, 34.2202],
            [-86.1788, 34.184],
            [-86.3035, 34.0991],
            [-86.453, 34.2593],
            [-86.4775, 34.3028],
            [-86.4623, 34.3102],
            [-86.5131, 34.3032],
            [-86.5819, 34.3047],
            [-86.5502, 34.546],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100006',
        NAME: 'Marshall County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1267334414,
        AWATER: 103647100,
        INTPTLAT: '+34.3739971',
        INTPTLON: '-086.3442702',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.6776, 33.4227],
              [-86.6742, 33.4227],
              [-86.6699, 33.4234],
              [-86.6664, 33.4292],
              [-86.6581, 33.4374],
              [-86.6437, 33.4411],
              [-86.6286, 33.4595],
              [-86.6264, 33.4632],
              [-86.6264, 33.4668],
              [-86.6237, 33.4668],
              [-86.6045, 33.4671],
              [-86.6606, 33.4091],
              [-86.6776, 33.4227],
            ],
          ],
          [
            [
              [-86.7696, 33.3887],
              [-86.7525, 33.4159],
              [-86.7277, 33.4165],
              [-86.7197, 33.4232],
              [-86.7047, 33.4257],
              [-86.7696, 33.3887],
            ],
          ],
          [
            [
              [-86.7914, 33.407],
              [-86.7903, 33.4064],
              [-86.7901, 33.406],
              [-86.784, 33.4051],
              [-86.7828, 33.4049],
              [-86.7825, 33.4056],
              [-86.7811, 33.4099],
              [-86.7785, 33.4111],
              [-86.7744, 33.4136],
              [-86.7741, 33.4138],
              [-86.7651, 33.4098],
              [-86.7645, 33.4092],
              [-86.7801, 33.3972],
              [-86.7914, 33.407],
            ],
          ],
          [
            [
              [-86.8127, 33.4226],
              [-86.8081, 33.423],
              [-86.8082, 33.4202],
              [-86.8103, 33.4196],
              [-86.8127, 33.4226],
            ],
          ],
          [
            [
              [-86.8159, 33.4224],
              [-86.8152, 33.4229],
              [-86.815, 33.4226],
              [-86.8159, 33.4224],
            ],
          ],
          [
            [
              [-86.8193, 33.4401],
              [-86.8165, 33.4412],
              [-86.8137, 33.4413],
              [-86.8131, 33.4335],
              [-86.817, 33.43],
              [-86.8171, 33.4298],
              [-86.8236, 33.4344],
              [-86.8193, 33.4401],
            ],
          ],
          [
            [
              [-86.8913, 33.348],
              [-86.886, 33.3548],
              [-86.8854, 33.3547],
              [-86.8854, 33.3568],
              [-86.8825, 33.3588],
              [-86.8782, 33.3548],
              [-86.8773, 33.3442],
              [-86.8995, 33.3417],
              [-86.8913, 33.348],
            ],
          ],
          [
            [
              [-86.9001, 33.3079],
              [-86.9001, 33.3115],
              [-86.8954, 33.3114],
              [-86.8956, 33.3164],
              [-86.8965, 33.3151],
              [-86.9001, 33.3147],
              [-86.9083, 33.3332],
              [-86.9045, 33.3352],
              [-86.9024, 33.3366],
              [-86.8995, 33.3417],
              [-86.8859, 33.342],
              [-86.8771, 33.3439],
              [-86.8761, 33.345],
              [-86.865, 33.3757],
              [-86.8603, 33.3757],
              [-86.8569, 33.3829],
              [-86.8603, 33.3829],
              [-86.8925, 33.3837],
              [-86.8986, 33.3838],
              [-86.8986, 33.3839],
              [-86.8942, 33.399],
              [-86.8933, 33.3999],
              [-86.8926, 33.4006],
              [-86.8872, 33.4046],
              [-86.8561, 33.4124],
              [-86.8537, 33.4147],
              [-86.8533, 33.4151],
              [-86.8415, 33.4262],
              [-86.8384, 33.4274],
              [-86.8375, 33.4288],
              [-86.8358, 33.4294],
              [-86.8347, 33.4303],
              [-86.8303, 33.4322],
              [-86.8159, 33.4231],
              [-86.8166, 33.4215],
              [-86.8152, 33.4216],
              [-86.8151, 33.4213],
              [-86.8149, 33.4212],
              [-86.8168, 33.415],
              [-86.8192, 33.4173],
              [-86.8197, 33.4151],
              [-86.8283, 33.4063],
              [-86.8278, 33.4064],
              [-86.8031, 33.4107],
              [-86.7785, 33.3711],
              [-86.8351, 33.332],
              [-86.8394, 33.332],
              [-86.8422, 33.3321],
              [-86.8485, 33.3322],
              [-86.9001, 33.3079],
            ],
            [
              [-86.8825, 33.328],
              [-86.8709, 33.3325],
              [-86.8751, 33.3326],
              [-86.8825, 33.3284],
              [-86.8825, 33.328],
            ],
            [
              [-86.8305, 33.3426],
              [-86.8305, 33.3387],
              [-86.8311, 33.3369],
              [-86.8306, 33.3362],
              [-86.8306, 33.3371],
              [-86.8306, 33.3373],
              [-86.8292, 33.3395],
              [-86.8254, 33.3401],
              [-86.8258, 33.3482],
              [-86.8305, 33.3427],
              [-86.8305, 33.3426],
            ],
            [
              [-86.8133, 33.3631],
              [-86.7978, 33.3673],
              [-86.8127, 33.3636],
              [-86.8133, 33.3635],
              [-86.8133, 33.3631],
            ],
          ],
          [
            [
              [-86.9261, 33.2942],
              [-86.9261, 33.2949],
              [-86.9238, 33.2966],
              [-86.9233, 33.2966],
              [-86.922, 33.2965],
              [-86.9262, 33.2892],
              [-86.9261, 33.2942],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100007',
        NAME: 'Hoover City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121282932,
        AWATER: 2553269,
        INTPTLAT: '+33.3769487',
        INTPTLON: '-086.8055789',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7862, 34.7436],
            [-86.747, 34.7579],
            [-86.7406, 34.7542],
            [-86.7369, 34.7534],
            [-86.7328, 34.7526],
            [-86.7343, 34.7545],
            [-86.7167, 34.753],
            [-86.7152, 34.753],
            [-86.7129, 34.7198],
            [-86.7178, 34.7197],
            [-86.7084, 34.7154],
            [-86.7111, 34.7139],
            [-86.7097, 34.7126],
            [-86.7099, 34.7082],
            [-86.7869, 34.6626],
            [-86.7862, 34.7436],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100008',
        NAME: 'Madison City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76680381,
        AWATER: 340948,
        INTPTLAT: '+34.7105329',
        INTPTLON: '-086.7622802',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.6162, 33.507],
              [-86.6161, 33.5054],
              [-86.6162, 33.5054],
              [-86.6169, 33.5058],
              [-86.6162, 33.507],
            ],
          ],
          [
            [
              [-86.6266, 33.5432],
              [-86.6242, 33.5433],
              [-86.6147, 33.5541],
              [-86.6137, 33.5541],
              [-86.6082, 33.5566],
              [-86.6082, 33.5573],
              [-86.5866, 33.5761],
              [-86.5821, 33.5761],
              [-86.5516, 33.5894],
              [-86.5126, 33.5576],
              [-86.508, 33.5461],
              [-86.5433, 33.5211],
              [-86.5517, 33.5174],
              [-86.5606, 33.5175],
              [-86.5648, 33.5106],
              [-86.5741, 33.5033],
              [-86.5869, 33.4997],
              [-86.5955, 33.4928],
              [-86.5996, 33.4883],
              [-86.5995, 33.4872],
              [-86.6165, 33.4936],
              [-86.6147, 33.5001],
              [-86.609, 33.501],
              [-86.6069, 33.503],
              [-86.6058, 33.503],
              [-86.6, 33.4996],
              [-86.5999, 33.5024],
              [-86.6034, 33.5028],
              [-86.6054, 33.5123],
              [-86.593, 33.5287],
              [-86.5949, 33.5318],
              [-86.6025, 33.5317],
              [-86.6045, 33.5433],
              [-86.618, 33.5447],
              [-86.6212, 33.5402],
              [-86.6266, 33.5432],
            ],
          ],
          [
            [
              [-86.6198, 33.5261],
              [-86.6034, 33.5311],
              [-86.5987, 33.5254],
              [-86.6021, 33.5221],
              [-86.6058, 33.5178],
              [-86.6058, 33.5175],
              [-86.606, 33.5171],
              [-86.6061, 33.5171],
              [-86.6198, 33.5261],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100011',
        NAME: 'Leeds City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60965255,
        AWATER: 577181,
        INTPTLAT: '+33.5420494',
        INTPTLON: '-086.5656607',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1915, 34.2202],
            [-86.1915, 34.2216],
            [-86.1915, 34.2235],
            [-86.1737, 34.2304],
            [-86.1675, 34.2288],
            [-86.1556, 34.2326],
            [-86.1061, 34.2008],
            [-86.1059, 34.1934],
            [-86.1057, 34.1901],
            [-86.115, 34.1864],
            [-86.1188, 34.1865],
            [-86.1322, 34.1869],
            [-86.1408, 34.187],
            [-86.1698, 34.1726],
            [-86.1698, 34.1762],
            [-86.1788, 34.184],
            [-86.1915, 34.2202],
          ],
          [
            [-86.1698, 34.1762],
            [-86.1672, 34.1875],
            [-86.1694, 34.1865],
            [-86.1698, 34.1762],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100012',
        NAME: 'Boaz City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37683452,
        AWATER: 161880,
        INTPTLAT: '+34.1990204',
        INTPTLON: '-086.1551515',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6522, 33.6201],
            [-86.6471, 33.6229],
            [-86.6087, 33.678],
            [-86.5503, 33.687],
            [-86.5694, 33.6612],
            [-86.5254, 33.6636],
            [-86.5342, 33.6344],
            [-86.5774, 33.6203],
            [-86.5515, 33.6078],
            [-86.5515, 33.5944],
            [-86.5821, 33.5761],
            [-86.5866, 33.5761],
            [-86.6064, 33.5834],
            [-86.6106, 33.5833],
            [-86.6108, 33.5833],
            [-86.6131, 33.5801],
            [-86.6132, 33.5758],
            [-86.6132, 33.5725],
            [-86.6216, 33.5689],
            [-86.6303, 33.5754],
            [-86.6304, 33.5789],
            [-86.6283, 33.5947],
            [-86.6259, 33.6016],
            [-86.6307, 33.6017],
            [-86.6368, 33.5982],
            [-86.6436, 33.5962],
            [-86.6439, 33.596],
            [-86.6453, 33.5951],
            [-86.6462, 33.5946],
            [-86.6471, 33.5947],
            [-86.6477, 33.5979],
            [-86.6477, 33.5988],
            [-86.6474, 33.6127],
            [-86.6522, 33.6163],
            [-86.6522, 33.6201],
          ],
          [
            [-86.6259, 33.6016],
            [-86.6136, 33.6145],
            [-86.6195, 33.6127],
            [-86.6305, 33.6072],
            [-86.6315, 33.6065],
            [-86.6295, 33.606],
            [-86.6261, 33.6054],
            [-86.6259, 33.6016],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100013',
        NAME: 'Trussville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83431415,
        AWATER: 946273,
        INTPTLAT: '+33.6379586',
        INTPTLON: '-086.5840469',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0083, 32.9661],
            [-85.8559, 32.9784],
            [-85.8954, 32.7546],
            [-86.0072, 32.755],
            [-86.0083, 32.9661],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100030',
        NAME: 'Alexander City City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246657204,
        AWATER: 43855967,
        INTPTLAT: '+32.8690219',
        INTPTLON: '-085.9510825',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5225, 31.3134],
            [-86.4391, 31.3398],
            [-86.4433, 31.2785],
            [-86.5225, 31.3134],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100060',
        NAME: 'Andalusia City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50909462,
        AWATER: 405912,
        INTPTLAT: '+31.3094363',
        INTPTLON: '-086.4780311',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.914, 33.6212],
            [-85.8373, 33.694],
            [-85.7601, 33.6341],
            [-85.76, 33.6334],
            [-85.76, 33.6323],
            [-85.7862, 33.6219],
            [-85.7905, 33.6215],
            [-85.8077, 33.6162],
            [-85.8197, 33.6243],
            [-85.8254, 33.6255],
            [-85.8279, 33.6233],
            [-85.8279, 33.6222],
            [-85.8279, 33.62],
            [-85.8294, 33.622],
            [-85.8472, 33.6225],
            [-85.8509, 33.6225],
            [-85.852, 33.6226],
            [-85.856, 33.6225],
            [-85.8595, 33.6203],
            [-85.8648, 33.6169],
            [-85.865, 33.6149],
            [-85.8818, 33.6038],
            [-85.886, 33.6016],
            [-85.886, 33.6004],
            [-85.9118, 33.6005],
            [-85.9169, 33.6003],
            [-85.914, 33.6212],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100090',
        NAME: 'Anniston City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118209414,
        AWATER: 179314,
        INTPTLAT: '+33.6734405',
        INTPTLON: '-085.8109178',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4623, 34.3102],
            [-86.4775, 34.3028],
            [-86.4821, 34.3028],
            [-86.4862, 34.3029],
            [-86.5131, 34.3032],
            [-86.4623, 34.3102],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100100',
        NAME: 'Arab City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34154363,
        AWATER: 346373,
        INTPTLAT: '+34.3323868',
        INTPTLON: '-086.5011665',
        ELSDLEA: '',
        UNSDLEA: '00100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9747, 34.746],
            [-87.0019, 34.836],
            [-86.9193, 34.829],
            [-86.9747, 34.746],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100120',
        NAME: 'Athens City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102349997,
        AWATER: 656309,
        INTPTLAT: '+34.7790209',
        INTPTLON: '-086.9507986',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0996, 34.0332],
            [-86.0675, 34.0405],
            [-86.0719, 34.0114],
            [-86.1009, 34.0012],
            [-86.0923, 33.9901],
            [-86.1366, 33.9966],
            [-86.0996, 34.0332],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100180',
        NAME: 'Attalla City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18079281,
        AWATER: 0,
        INTPTLAT: '+33.9861893',
        INTPTLON: '-086.1210365',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1498, 30.8554],
            [-88.0893, 30.8593],
            [-88.0786, 30.8435],
            [-88.0767, 30.8396],
            [-88.0635, 30.8336],
            [-88.0601, 30.8325],
            [-88.0587, 30.8343],
            [-88.0583, 30.8362],
            [-88.0557, 30.8371],
            [-88.0721, 30.7822],
            [-88.0903, 30.7903],
            [-88.1498, 30.8554],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100185',
        NAME: 'Saraland City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60112028,
        AWATER: 441056,
        INTPTLAT: '+30.8315847',
        INTPTLON: '-088.0941173',
        ELSDLEA: '',
        UNSDLEA: '00185',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0903, 30.7903],
            [-88.0721, 30.7822],
            [-88.0758, 30.7564],
            [-88.1002, 30.7617],
            [-88.0903, 30.7903],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100188',
        NAME: 'Chickasaw City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10894952,
        AWATER: 909156,
        INTPTLAT: '+30.7714495',
        INTPTLON: '-088.0796971',
        ELSDLEA: '',
        UNSDLEA: '00188',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0893, 30.8593],
            [-88.0557, 30.8371],
            [-88.0583, 30.8362],
            [-88.0587, 30.8343],
            [-88.0601, 30.8325],
            [-88.0635, 30.8336],
            [-88.0767, 30.8396],
            [-88.0786, 30.8435],
            [-88.0893, 30.8593],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100189',
        NAME: 'Satsuma City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19314308,
        AWATER: 122488,
        INTPTLAT: '+30.8578741',
        INTPTLON: '-088.0633829',
        ELSDLEA: '',
        UNSDLEA: '00189',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7821, 33.2075],
            [-86.7643, 33.1873],
            [-86.8176, 33.1995],
            [-86.8744, 33.2307],
            [-86.7821, 33.2075],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100190',
        NAME: 'Alabaster City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63347020,
        AWATER: 737693,
        INTPTLAT: '+33.2194420',
        INTPTLON: '-086.8239069',
        ELSDLEA: '',
        UNSDLEA: '00190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.4552, 32.6567],
              [-85.4518, 32.6535],
              [-85.4551, 32.65],
              [-85.4552, 32.6567],
            ],
          ],
          [
            [
              [-85.5328, 32.5423],
              [-85.5353, 32.5402],
              [-85.5353, 32.5407],
              [-85.5328, 32.5423],
            ],
          ],
          [
            [
              [-85.5174, 32.5841],
              [-85.5386, 32.6134],
              [-85.542, 32.6128],
              [-85.5456, 32.672],
              [-85.4902, 32.716],
              [-85.4893, 32.6839],
              [-85.4727, 32.6649],
              [-85.4572, 32.6594],
              [-85.4518, 32.6603],
              [-85.4552, 32.6567],
              [-85.4568, 32.6572],
              [-85.468, 32.64],
              [-85.4453, 32.6409],
              [-85.416, 32.5973],
              [-85.4154, 32.5976],
              [-85.4132, 32.5967],
              [-85.4234, 32.5455],
              [-85.4374, 32.5275],
              [-85.48, 32.5351],
              [-85.52, 32.5312],
              [-85.5272, 32.5484],
              [-85.5353, 32.5407],
              [-85.5353, 32.5402],
              [-85.539, 32.5498],
              [-85.5156, 32.5672],
              [-85.5174, 32.5841],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100210',
        NAME: 'Auburn City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152375114,
        AWATER: 2646161,
        INTPTLAT: '+32.6077220',
        INTPTLON: '-085.4895446',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9176, 32.6642],
            [-86.7134, 32.6617],
            [-86.7142, 32.7057],
            [-86.4131, 32.7074],
            [-86.4112, 32.4099],
            [-86.4968, 32.3444],
            [-86.6534, 32.3972],
            [-86.7814, 32.3925],
            [-86.8149, 32.3408],
            [-86.9067, 32.537],
            [-86.9176, 32.6642],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100240',
        NAME: 'Autauga County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1539589643,
        AWATER: 25769317,
        INTPTLAT: '+32.5322367',
        INTPTLON: '-086.6464395',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9729, 31.1627],
            [-87.9466, 31.1929],
            [-87.8739, 31.2015],
            [-87.7652, 31.2973],
            [-87.6159, 31.2445],
            [-87.5988, 30.9975],
            [-87.6262, 30.8467],
            [-87.3933, 30.6272],
            [-87.4451, 30.5289],
            [-87.3694, 30.4319],
            [-87.5, 30.329],
            [-87.5183, 30.2295],
            [-87.8072, 30.1774],
            [-88.0036, 30.1734],
            [-88.0373, 30.1466],
            [-88.0371, 30.2224],
            [-87.9878, 30.6371],
            [-88.0263, 30.7534],
            [-87.9499, 30.9267],
            [-87.9416, 31.0613],
            [-87.9729, 31.1627],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100270',
        NAME: 'Baldwin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4117584013,
        AWATER: 1133129224,
        INTPTLAT: '+30.6592183',
        INTPTLON: '-087.7460666',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6577, 31.8803],
            [-85.5873, 31.9974],
            [-85.4285, 32.015],
            [-85.4102, 32.1467],
            [-85.2577, 32.1483],
            [-85.1851, 32.0617],
            [-85.056, 32.0631],
            [-85.0542, 32.0267],
            [-85.139, 32.0039],
            [-85.2093, 31.9307],
            [-85.2301, 31.8427],
            [-85.1419, 31.7822],
            [-85.1411, 31.7805],
            [-85.1273, 31.7626],
            [-85.2161, 31.7024],
            [-85.416, 31.7067],
            [-85.4164, 31.6195],
            [-85.7483, 31.618],
            [-85.6627, 31.7833],
            [-85.6577, 31.8803],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100300',
        NAME: 'Barbour County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2138003480,
        AWATER: 14382066,
        INTPTLAT: '+31.8702531',
        INTPTLON: '-085.4051035',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.8603, 33.3829],
              [-86.8569, 33.3829],
              [-86.8603, 33.3757],
              [-86.8603, 33.3829],
            ],
          ],
          [
            [
              [-86.8782, 33.3548],
              [-86.865, 33.3757],
              [-86.8761, 33.345],
              [-86.8773, 33.3442],
              [-86.8782, 33.3548],
            ],
          ],
          [
            [
              [-86.9335, 33.4357],
              [-86.935, 33.4281],
              [-86.9215, 33.417],
              [-86.9229, 33.4155],
              [-86.9248, 33.413],
              [-86.9171, 33.3851],
              [-86.9103, 33.3841],
              [-86.9051, 33.384],
              [-86.9, 33.3839],
              [-86.8986, 33.3838],
              [-86.8925, 33.3837],
              [-86.8825, 33.3588],
              [-86.8854, 33.3568],
              [-86.8854, 33.3547],
              [-86.886, 33.3548],
              [-86.8913, 33.348],
              [-86.8995, 33.3417],
              [-86.8995, 33.3417],
              [-86.9024, 33.3366],
              [-86.9045, 33.3352],
              [-86.9083, 33.3332],
              [-86.9197, 33.3239],
              [-87.0135, 33.3527],
              [-86.9335, 33.4357],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100330',
        NAME: 'Bessemer City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104798632,
        AWATER: 439841,
        INTPTLAT: '+33.3707887',
        INTPTLON: '-086.9715956',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4219, 33.0034],
            [-87.3185, 33.0062],
            [-87.2819, 33.1331],
            [-87.1992, 33.1966],
            [-87.0657, 33.2469],
            [-87.0268, 33.2465],
            [-87.0256, 33.1658],
            [-86.8812, 33.0499],
            [-86.8761, 32.8363],
            [-87.0192, 32.837],
            [-87.3192, 32.8315],
            [-87.4212, 32.8745],
            [-87.4219, 33.0034],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100360',
        NAME: 'Bibb County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1612481559,
        AWATER: 9287974,
        INTPTLAT: '+33.0158929',
        INTPTLON: '-087.1271475',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.6058, 33.5175],
              [-86.6058, 33.5178],
              [-86.6021, 33.5221],
              [-86.5987, 33.5254],
              [-86.5949, 33.5318],
              [-86.593, 33.5287],
              [-86.6058, 33.5175],
            ],
          ],
          [
            [
              [-86.6315, 33.6065],
              [-86.6305, 33.6072],
              [-86.6195, 33.6127],
              [-86.6136, 33.6145],
              [-86.6259, 33.6016],
              [-86.6261, 33.6054],
              [-86.6295, 33.606],
              [-86.6315, 33.6065],
            ],
          ],
          [
            [
              [-87.0927, 33.6278],
              [-87.0927, 33.6347],
              [-87.0642, 33.5892],
              [-87.1092, 33.5872],
              [-87.1166, 33.5167],
              [-87.0122, 33.4944],
              [-86.9236, 33.5243],
              [-86.852, 33.5874],
              [-86.7882, 33.5749],
              [-86.7511, 33.5775],
              [-86.7478, 33.5802],
              [-86.7447, 33.6046],
              [-86.7017, 33.622],
              [-86.6471, 33.6229],
              [-86.6522, 33.6201],
              [-86.6522, 33.6163],
              [-86.6474, 33.6127],
              [-86.6519, 33.6023],
              [-86.6477, 33.5979],
              [-86.6471, 33.5947],
              [-86.6462, 33.5946],
              [-86.6453, 33.5951],
              [-86.6439, 33.596],
              [-86.6436, 33.5962],
              [-86.6368, 33.5982],
              [-86.6307, 33.6017],
              [-86.6259, 33.6016],
              [-86.6283, 33.5947],
              [-86.6304, 33.5789],
              [-86.6303, 33.5754],
              [-86.6216, 33.5689],
              [-86.6132, 33.5725],
              [-86.6153, 33.5618],
              [-86.6137, 33.5541],
              [-86.6147, 33.5541],
              [-86.6242, 33.5433],
              [-86.6266, 33.5432],
              [-86.6212, 33.5402],
              [-86.618, 33.5447],
              [-86.6045, 33.5433],
              [-86.6025, 33.5317],
              [-86.6034, 33.5311],
              [-86.6198, 33.5261],
              [-86.6061, 33.5171],
              [-86.606, 33.5171],
              [-86.6142, 33.5101],
              [-86.6204, 33.506],
              [-86.6271, 33.5002],
              [-86.6287, 33.4981],
              [-86.6341, 33.4943],
              [-86.6397, 33.4885],
              [-86.6301, 33.4885],
              [-86.6301, 33.4935],
              [-86.6216, 33.4955],
              [-86.6217, 33.5035],
              [-86.6161, 33.5054],
              [-86.6162, 33.507],
              [-86.6054, 33.5123],
              [-86.6034, 33.5028],
              [-86.5999, 33.5024],
              [-86.6, 33.4996],
              [-86.6058, 33.503],
              [-86.6069, 33.503],
              [-86.609, 33.501],
              [-86.6147, 33.5001],
              [-86.6165, 33.4936],
              [-86.5995, 33.4872],
              [-86.5996, 33.4883],
              [-86.5955, 33.4928],
              [-86.5869, 33.4997],
              [-86.584, 33.4984],
              [-86.5865, 33.4973],
              [-86.587, 33.4961],
              [-86.5871, 33.4907],
              [-86.5957, 33.4815],
              [-86.5958, 33.4799],
              [-86.6046, 33.4742],
              [-86.6045, 33.4671],
              [-86.6237, 33.4668],
              [-86.6289, 33.4765],
              [-86.6305, 33.4668],
              [-86.6264, 33.4632],
              [-86.6286, 33.4595],
              [-86.6437, 33.4411],
              [-86.6581, 33.4374],
              [-86.6664, 33.4292],
              [-86.6699, 33.4234],
              [-86.6742, 33.4227],
              [-86.6776, 33.4227],
              [-86.7047, 33.4304],
              [-86.7059, 33.4305],
              [-86.7047, 33.4257],
              [-86.7197, 33.4232],
              [-86.7261, 33.4287],
              [-86.7262, 33.4288],
              [-86.7307, 33.4346],
              [-86.7358, 33.4321],
              [-86.7358, 33.4308],
              [-86.7387, 33.4293],
              [-86.7395, 33.4308],
              [-86.7385, 33.4338],
              [-86.7403, 33.4384],
              [-86.7403, 33.4455],
              [-86.736, 33.4459],
              [-86.7093, 33.4551],
              [-86.7088, 33.4676],
              [-86.7053, 33.4752],
              [-86.7103, 33.4822],
              [-86.7125, 33.4819],
              [-86.7084, 33.4828],
              [-86.7059, 33.481],
              [-86.7051, 33.4752],
              [-86.7053, 33.4673],
              [-86.7087, 33.4672],
              [-86.7092, 33.4522],
              [-86.6736, 33.4725],
              [-86.6653, 33.4774],
              [-86.6651, 33.4815],
              [-86.6523, 33.4854],
              [-86.6612, 33.501],
              [-86.6726, 33.5004],
              [-86.674, 33.5023],
              [-86.6749, 33.5032],
              [-86.6782, 33.4994],
              [-86.6792, 33.4993],
              [-86.6914, 33.4923],
              [-86.7001, 33.4891],
              [-86.709, 33.4889],
              [-86.7013, 33.4996],
              [-86.7011, 33.4976],
              [-86.6905, 33.5031],
              [-86.6867, 33.4995],
              [-86.679, 33.5017],
              [-86.6726, 33.5051],
              [-86.6725, 33.5007],
              [-86.6607, 33.5014],
              [-86.6567, 33.5541],
              [-86.7065, 33.5501],
              [-86.7261, 33.515],
              [-86.7745, 33.4849],
              [-86.7801, 33.4829],
              [-86.7816, 33.4834],
              [-86.7829, 33.4841],
              [-86.7834, 33.4846],
              [-86.7854, 33.4864],
              [-86.7861, 33.4864],
              [-86.7868, 33.4876],
              [-86.8222, 33.4673],
              [-86.8229, 33.4676],
              [-86.8264, 33.475],
              [-86.8294, 33.4744],
              [-86.8379, 33.4721],
              [-86.8385, 33.4717],
              [-86.8391, 33.4711],
              [-86.8345, 33.4676],
              [-86.8368, 33.4664],
              [-86.8382, 33.466],
              [-86.8396, 33.4658],
              [-86.8395, 33.4651],
              [-86.8405, 33.4629],
              [-86.8402, 33.4598],
              [-86.8484, 33.4484],
              [-86.8478, 33.4472],
              [-86.8385, 33.4436],
              [-86.8365, 33.4443],
              [-86.8275, 33.4435],
              [-86.827, 33.442],
              [-86.8276, 33.4403],
              [-86.8357, 33.4372],
              [-86.8417, 33.4332],
              [-86.8415, 33.4262],
              [-86.8533, 33.4151],
              [-86.8537, 33.4147],
              [-86.8561, 33.4124],
              [-86.8872, 33.4046],
              [-86.8926, 33.4006],
              [-86.8933, 33.3999],
              [-86.8942, 33.399],
              [-86.8986, 33.3839],
              [-86.8986, 33.3838],
              [-86.9, 33.3839],
              [-86.9051, 33.384],
              [-86.9103, 33.3841],
              [-86.9171, 33.3851],
              [-86.9248, 33.413],
              [-86.9229, 33.4155],
              [-86.9215, 33.417],
              [-86.8902, 33.4423],
              [-86.935, 33.4281],
              [-86.9335, 33.4357],
              [-86.922, 33.4477],
              [-86.9204, 33.4495],
              [-86.917, 33.4495],
              [-86.9142, 33.446],
              [-86.9079, 33.4468],
              [-86.902, 33.4505],
              [-86.9009, 33.4533],
              [-86.9007, 33.4542],
              [-86.9007, 33.4604],
              [-86.9045, 33.4721],
              [-86.9106, 33.5002],
              [-86.9124, 33.5002],
              [-86.9187, 33.4939],
              [-86.935, 33.4949],
              [-87.0113, 33.4934],
              [-87.1178, 33.516],
              [-87.1138, 33.5941],
              [-87.0927, 33.6278],
            ],
          ],
          [
            [
              [-86.7153, 33.6303],
              [-86.7135, 33.6289],
              [-86.717, 33.631],
              [-86.7153, 33.6303],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100390',
        NAME: 'Birmingham City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378356937,
        AWATER: 6591013,
        INTPTLAT: '+33.5274441',
        INTPTLON: '-086.7990470',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9634, 33.8582],
            [-86.9244, 33.9092],
            [-86.764, 33.9742],
            [-86.6877, 34.0807],
            [-86.5998, 34.1217],
            [-86.5196, 34.2518],
            [-86.453, 34.2593],
            [-86.3035, 34.0991],
            [-86.3256, 33.9401],
            [-86.406, 33.8359],
            [-86.5778, 33.7653],
            [-86.6453, 33.773],
            [-86.7591, 33.8406],
            [-86.9537, 33.8153],
            [-86.9634, 33.8582],
          ],
          [
            [-86.5831, 33.8774],
            [-86.5541, 33.8958],
            [-86.5472, 33.8958],
            [-86.5421, 33.9099],
            [-86.5292, 33.9206],
            [-86.5224, 33.9177],
            [-86.5141, 33.9177],
            [-86.502, 33.9245],
            [-86.4461, 33.9253],
            [-86.4468, 33.9733],
            [-86.4985, 33.9766],
            [-86.4982, 33.9251],
            [-86.5064, 33.9252],
            [-86.5144, 33.9187],
            [-86.5242, 33.9185],
            [-86.5292, 33.9207],
            [-86.5324, 33.9181],
            [-86.5778, 33.902],
            [-86.5831, 33.8774],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100420',
        NAME: 'Blount County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1629972369,
        AWATER: 14927499,
        INTPTLAT: '+33.9773575',
        INTPTLON: '-086.5664400',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1242, 31.0858],
            [-87.0899, 31.0967],
            [-87.0772, 31.1407],
            [-87.0516, 31.1083],
            [-87.0845, 31.0782],
            [-87.1242, 31.0858],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100450',
        NAME: 'Brewton City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29028375,
        AWATER: 609964,
        INTPTLAT: '+31.1091749',
        INTPTLON: '-087.0765833',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9193, 32.2744],
            [-85.8562, 32.232],
            [-85.4335, 32.2346],
            [-85.4102, 32.1467],
            [-85.4285, 32.015],
            [-85.5873, 31.9974],
            [-85.6577, 31.8803],
            [-85.791, 31.8804],
            [-85.79, 31.9673],
            [-85.8843, 31.9673],
            [-85.8937, 32.0474],
            [-85.9969, 32.051],
            [-85.9992, 32.2505],
            [-85.9193, 32.2744],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100480',
        NAME: 'Bullock County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1613059135,
        AWATER: 6055023,
        INTPTLAT: '+32.1017589',
        INTPTLON: '-085.7172613',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8576, 31.9622],
            [-86.4482, 31.9646],
            [-86.4486, 31.6556],
            [-86.4995, 31.6552],
            [-86.4992, 31.5253],
            [-86.7016, 31.5239],
            [-86.8394, 31.5252],
            [-86.9068, 31.6327],
            [-86.9059, 31.753],
            [-86.9069, 31.8306],
            [-86.9089, 31.9617],
            [-86.8576, 31.9622],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100510',
        NAME: 'Butler County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2011976232,
        AWATER: 2727475,
        INTPTLAT: '+31.7516670',
        INTPTLON: '-086.6819689',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0653, 33.8422],
            [-85.8818, 33.9503],
            [-85.739, 33.9685],
            [-85.6542, 33.9634],
            [-85.637, 33.8753],
            [-85.6386, 33.6484],
            [-85.7422, 33.6],
            [-85.7601, 33.6341],
            [-85.8373, 33.694],
            [-85.914, 33.6212],
            [-86.0216, 33.6012],
            [-86.0507, 33.6746],
            [-86.1456, 33.6791],
            [-86.044, 33.7636],
            [-86.0653, 33.8422],
          ],
          [
            [-85.7568, 33.7959],
            [-85.7292, 33.8327],
            [-85.7809, 33.8246],
            [-85.7568, 33.7959],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100540',
        NAME: 'Calhoun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1229613200,
        AWATER: 13906686,
        INTPTLAT: '+33.7792078',
        INTPTLON: '-085.8427990',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.5932, 33.1073],
              [-85.4118, 33.1087],
              [-85.4027, 33.1086],
              [-85.3984, 33.1086],
              [-85.3946, 33.1086],
              [-85.2324, 33.1081],
              [-85.187, 32.8816],
              [-85.2058, 32.8861],
              [-85.1785, 32.8547],
              [-85.135, 32.7465],
              [-85.285, 32.7392],
              [-85.3092, 32.7307],
              [-85.4568, 32.7303],
              [-85.4729, 32.7305],
              [-85.4907, 32.7306],
              [-85.5932, 32.7285],
              [-85.5932, 33.1073],
            ],
          ],
          [
            [
              [-85.1853, 32.8733],
              [-85.1841, 32.8705],
              [-85.1849, 32.8634],
              [-85.1853, 32.8733],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100600',
        NAME: 'Chambers County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1528841695,
        AWATER: 17048126,
        INTPTLAT: '+32.9155039',
        INTPTLON: '-085.3940321',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6274, 34.3955],
            [-85.6275, 34.3961],
            [-85.5136, 34.5238],
            [-85.4622, 34.2864],
            [-85.4219, 34.0808],
            [-85.3988, 33.9641],
            [-85.6542, 33.9634],
            [-85.739, 33.9685],
            [-85.8436, 34.2],
            [-85.6274, 34.3955],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100630',
        NAME: 'Cherokee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1434128031,
        AWATER: 119806782,
        INTPTLAT: '+34.1761269',
        INTPTLON: '-085.6181957',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0192, 32.837],
            [-86.8761, 32.8363],
            [-86.8812, 33.0499],
            [-86.61, 33.07],
            [-86.5173, 33.0206],
            [-86.516, 32.9294],
            [-86.457, 32.8139],
            [-86.375, 32.7536],
            [-86.4131, 32.7074],
            [-86.7142, 32.7057],
            [-86.7134, 32.6617],
            [-86.9176, 32.6642],
            [-87.0177, 32.6633],
            [-87.0178, 32.7295],
            [-87.0192, 32.837],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100660',
        NAME: 'Chilton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1794482899,
        AWATER: 20586310,
        INTPTLAT: '+32.8540514',
        INTPTLON: '-086.7266071',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4732, 31.8939],
            [-88.4427, 32.1386],
            [-88.4314, 32.2277],
            [-88.4215, 32.3087],
            [-87.9307, 32.3106],
            [-87.9297, 32.2925],
            [-88.0191, 32.2852],
            [-88.0096, 32.1955],
            [-88.0706, 32.0617],
            [-88.0735, 31.9902],
            [-88.1804, 31.8145],
            [-88.0883, 31.6993],
            [-88.4644, 31.698],
            [-88.4732, 31.8939],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100690',
        NAME: 'Choctaw County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2365954971,
        AWATER: 19059247,
        INTPTLAT: '+31.9909972',
        INTPTLON: '-088.2489085',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0735, 31.9902],
            [-87.8169, 31.9905],
            [-87.8173, 31.825],
            [-87.6202, 31.8333],
            [-87.5009, 31.8293],
            [-87.5161, 31.6978],
            [-87.5668, 31.6971],
            [-87.5657, 31.4817],
            [-87.6031, 31.4096],
            [-87.706, 31.401],
            [-87.7652, 31.2973],
            [-87.8739, 31.2015],
            [-87.9466, 31.1929],
            [-87.9508, 31.2995],
            [-87.8889, 31.364],
            [-87.9074, 31.4944],
            [-88.0722, 31.5945],
            [-88.0883, 31.6993],
            [-88.1804, 31.8145],
            [-88.0735, 31.9902],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100720',
        NAME: 'Clarke County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2933606539,
        AWATER: 36425277,
        INTPTLAT: '+31.6649369',
        INTPTLON: '-087.8292633',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8519, 33.4987],
            [-85.6435, 33.4959],
            [-85.6537, 33.1066],
            [-85.9747, 33.1053],
            [-86.0092, 33.0903],
            [-86.1744, 33.1044],
            [-86.1206, 33.1945],
            [-86.1182, 33.2963],
            [-85.9803, 33.2942],
            [-85.9238, 33.3962],
            [-85.9049, 33.4987],
            [-85.8519, 33.4987],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100750',
        NAME: 'Clay County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1564252367,
        AWATER: 5284573,
        INTPTLAT: '+33.2703999',
        INTPTLON: '-085.8635254',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8519, 33.4987],
            [-85.7961, 33.5562],
            [-85.7422, 33.6],
            [-85.6386, 33.6484],
            [-85.637, 33.8753],
            [-85.381, 33.8735],
            [-85.3382, 33.6531],
            [-85.3044, 33.4829],
            [-85.6435, 33.4959],
            [-85.8519, 33.4987],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100780',
        NAME: 'Cleburne County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1323790180,
        AWATER: 2135809,
        INTPTLAT: '+33.6504892',
        INTPTLON: '-085.5219586',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1951, 31.3534],
            [-86.0754, 31.3455],
            [-86.0043, 31.4019],
            [-86.1947, 31.5245],
            [-86.1459, 31.6177],
            [-85.7891, 31.618],
            [-85.7893, 31.4909],
            [-85.8582, 31.4658],
            [-85.7898, 31.3566],
            [-85.79, 31.3419],
            [-85.8886, 31.3715],
            [-85.8881, 31.2847],
            [-85.7904, 31.3227],
            [-85.7914, 31.1963],
            [-86.1935, 31.1922],
            [-86.1951, 31.3534],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100810',
        NAME: 'Coffee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1402728351,
        AWATER: 2358227,
        INTPTLAT: '+31.3941123',
        INTPTLON: '-085.9925944',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0979, 34.8922],
            [-87.9732, 34.8826],
            [-87.8358, 34.7412],
            [-87.5244, 34.8324],
            [-87.4265, 34.8],
            [-87.4331, 34.7175],
            [-87.5297, 34.5671],
            [-88.14, 34.5817],
            [-88.0979, 34.8922],
          ],
          [
            [-87.6647, 34.7058],
            [-87.6077, 34.7916],
            [-87.6746, 34.7595],
            [-87.7411, 34.735],
            [-87.7023, 34.6902],
            [-87.6647, 34.7058],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100840',
        NAME: 'Colbert County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1454506437,
        AWATER: 79638917,
        INTPTLAT: '+34.7040121',
        INTPTLON: '-087.8130371',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9059, 31.753],
            [-86.9068, 31.6327],
            [-86.8394, 31.5252],
            [-86.7016, 31.5239],
            [-86.6672, 31.3694],
            [-86.7011, 31.3244],
            [-86.7003, 31.1922],
            [-86.764, 31.2613],
            [-87.4275, 31.2604],
            [-87.1666, 31.5196],
            [-87.1351, 31.6424],
            [-86.9059, 31.753],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100870',
        NAME: 'Conecuh County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2201896058,
        AWATER: 6643480,
        INTPTLAT: '+31.4309257',
        INTPTLON: '-086.9887221',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5173, 33.0206],
            [-86.491, 33.1029],
            [-86.1744, 33.1044],
            [-86.0092, 33.0903],
            [-86.0083, 32.9661],
            [-86.0072, 32.755],
            [-86.375, 32.7536],
            [-86.457, 32.8139],
            [-86.516, 32.9294],
            [-86.5173, 33.0206],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100900',
        NAME: 'Coosa County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1685891509,
        AWATER: 39946563,
        INTPTLAT: '+32.9314453',
        INTPTLON: '-086.2434818',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7016, 31.5239],
            [-86.4992, 31.5253],
            [-86.3999, 31.5271],
            [-86.3985, 31.4513],
            [-86.194, 31.4401],
            [-86.1951, 31.3534],
            [-86.1935, 31.1922],
            [-86.1872, 30.994],
            [-86.3886, 30.9942],
            [-86.6883, 30.9945],
            [-86.7003, 31.1922],
            [-86.7011, 31.3244],
            [-86.6672, 31.3694],
            [-86.7016, 31.5239],
          ],
          [
            [-86.5225, 31.3134],
            [-86.4433, 31.2785],
            [-86.4391, 31.3398],
            [-86.5225, 31.3134],
          ],
          [
            [-86.3139, 31.2703],
            [-86.2713, 31.2447],
            [-86.2113, 31.3015],
            [-86.2704, 31.3266],
            [-86.3139, 31.2703],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100930',
        NAME: 'Covington County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2556671491,
        AWATER: 31723293,
        INTPTLAT: '+31.2453641',
        INTPTLON: '-086.4553817',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4482, 31.9646],
            [-86.4063, 32.0507],
            [-86.3036, 32.0517],
            [-86.3022, 31.9651],
            [-86.1914, 31.9665],
            [-86.1829, 31.8324],
            [-86.1483, 31.791],
            [-86.1459, 31.6177],
            [-86.1947, 31.5245],
            [-86.194, 31.4401],
            [-86.3985, 31.4513],
            [-86.3999, 31.5271],
            [-86.4992, 31.5253],
            [-86.4995, 31.6552],
            [-86.4486, 31.6556],
            [-86.4482, 31.9646],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100960',
        NAME: 'Crenshaw County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1576887783,
        AWATER: 5376390,
        INTPTLAT: '+31.7328256',
        INTPTLON: '-086.3192219',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8878, 34.2212],
            [-86.8696, 34.2154],
            [-86.8186, 34.1439],
            [-86.8668, 34.1811],
            [-86.8878, 34.2212],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '100990',
        NAME: 'Cullman City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48734677,
        AWATER: 3091938,
        INTPTLAT: '+34.1788918',
        INTPTLON: '-086.8418871',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1099, 34.2993],
            [-87.1101, 34.3138],
            [-86.5819, 34.3047],
            [-86.5131, 34.3032],
            [-86.4862, 34.3029],
            [-86.4821, 34.3028],
            [-86.4775, 34.3028],
            [-86.453, 34.2593],
            [-86.5196, 34.2518],
            [-86.5998, 34.1217],
            [-86.6877, 34.0807],
            [-86.764, 33.9742],
            [-86.9244, 33.9092],
            [-86.9634, 33.8582],
            [-87.0856, 33.9192],
            [-87.151, 33.9932],
            [-87.112, 33.9924],
            [-87.1099, 34.2993],
          ],
          [
            [-86.8668, 34.1811],
            [-86.8186, 34.1439],
            [-86.8696, 34.2154],
            [-86.8878, 34.2212],
            [-86.8668, 34.1811],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101020',
        NAME: 'Cullman County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1854306974,
        AWATER: 49024423,
        INTPTLAT: '+34.1319229',
        INTPTLON: '-086.8692666',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.4262, 31.2865],
              [-85.4262, 31.2863],
              [-85.4282, 31.2863],
              [-85.4262, 31.2865],
            ],
          ],
          [
            [
              [-85.7893, 31.4909],
              [-85.7891, 31.618],
              [-85.7483, 31.618],
              [-85.4164, 31.6195],
              [-85.4175, 31.3295],
              [-85.4262, 31.2865],
              [-85.435, 31.2865],
              [-85.4474, 31.2866],
              [-85.4677, 31.2868],
              [-85.4689, 31.2869],
              [-85.4692, 31.2869],
              [-85.4858, 31.2653],
              [-85.4858, 31.2586],
              [-85.4858, 31.2581],
              [-85.4945, 31.2611],
              [-85.4943, 31.2759],
              [-85.5116, 31.2714],
              [-85.5113, 31.2506],
              [-85.6751, 31.2556],
              [-85.6757, 31.3156],
              [-85.6157, 31.3624],
              [-85.5468, 31.4201],
              [-85.6591, 31.5173],
              [-85.7591, 31.4838],
              [-85.7893, 31.4909],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101050',
        NAME: 'Dale County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 933234045,
        AWATER: 588255,
        INTPTLAT: '+31.4922902',
        INTPTLON: '-085.5632718',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7904, 31.3227],
            [-85.7903, 31.3235],
            [-85.7901, 31.3301],
            [-85.79, 31.3419],
            [-85.7898, 31.3566],
            [-85.6757, 31.3156],
            [-85.6751, 31.2556],
            [-85.7109, 31.1952],
            [-85.7914, 31.1963],
            [-85.7904, 31.3227],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101080',
        NAME: 'Daleville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156355638,
        AWATER: 0,
        INTPTLAT: '+31.2666993',
        INTPTLON: '-085.7387646',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4731, 32.3076],
            [-87.4217, 32.3081],
            [-87.4232, 32.483],
            [-87.1124, 32.4886],
            [-87.0178, 32.7295],
            [-87.0177, 32.6633],
            [-86.9176, 32.6642],
            [-86.9067, 32.537],
            [-86.8149, 32.3408],
            [-86.9083, 32.225],
            [-86.907, 32.048],
            [-87.1781, 32.0475],
            [-87.3256, 32.1963],
            [-87.4722, 32.2649],
            [-87.4731, 32.3076],
          ],
          [
            [-87.0908, 32.3954],
            [-87.0187, 32.4048],
            [-87.0497, 32.4438],
            [-87.0908, 32.3954],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101110',
        NAME: 'Dallas County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2499041529,
        AWATER: 37597534,
        INTPTLAT: '+32.3335397',
        INTPTLON: '-087.1143556',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0577, 34.476],
            [-86.0038, 34.48],
            [-85.7855, 34.6246],
            [-85.6271, 34.8319],
            [-85.5831, 34.8604],
            [-85.5341, 34.6239],
            [-85.5273, 34.5887],
            [-85.5136, 34.5238],
            [-85.6275, 34.3961],
            [-85.6181, 34.4612],
            [-85.6668, 34.5395],
            [-85.7581, 34.4538],
            [-85.7752, 34.3907],
            [-85.6274, 34.3955],
            [-85.8436, 34.2],
            [-86.1061, 34.2008],
            [-86.1104, 34.4278],
            [-86.0577, 34.476],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101140',
        NAME: 'DeKalb County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1865622918,
        AWATER: 3200308,
        INTPTLAT: '+34.4633138',
        INTPTLON: '-085.8120557',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1054, 34.657],
            [-87.1053, 34.6592],
            [-87.1053, 34.6618],
            [-87.0878, 34.671],
            [-86.9734, 34.6135],
            [-86.9057, 34.6426],
            [-86.9067, 34.5798],
            [-86.9478, 34.5157],
            [-87.0666, 34.5247],
            [-87.1055, 34.6355],
            [-87.1054, 34.657],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101170',
        NAME: 'Decatur City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140137122,
        AWATER: 17594716,
        INTPTLAT: '+34.5733212',
        INTPTLON: '-086.9921353',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8236, 32.5244],
            [-87.8126, 32.5246],
            [-87.808, 32.5245],
            [-87.8236, 32.5244],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101200',
        NAME: 'Demopolis City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45941151,
        AWATER: 837165,
        INTPTLAT: '+32.4999378',
        INTPTLON: '-087.8254258',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4858, 31.2581],
            [-85.4858, 31.2586],
            [-85.4858, 31.2653],
            [-85.4817, 31.2653],
            [-85.4816, 31.2561],
            [-85.4858, 31.2564],
            [-85.4857, 31.2529],
            [-85.469, 31.2544],
            [-85.4692, 31.2869],
            [-85.4689, 31.2869],
            [-85.4677, 31.2868],
            [-85.4474, 31.2866],
            [-85.435, 31.2865],
            [-85.4262, 31.2865],
            [-85.4175, 31.3295],
            [-85.4175, 31.3257],
            [-85.4174, 31.315],
            [-85.3735, 31.3145],
            [-85.3232, 31.2469],
            [-85.346, 31.1683],
            [-85.4327, 31.1704],
            [-85.5113, 31.2506],
            [-85.5116, 31.2714],
            [-85.4943, 31.2759],
            [-85.4945, 31.2611],
            [-85.4858, 31.2581],
          ],
          [
            [-85.4262, 31.2865],
            [-85.4282, 31.2863],
            [-85.4262, 31.2863],
            [-85.4262, 31.2865],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101230',
        NAME: 'Dothan City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231582640,
        AWATER: 876793,
        INTPTLAT: '+31.2336983',
        INTPTLON: '-085.4068225',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.194, 31.4401],
            [-86.1947, 31.5245],
            [-86.0043, 31.4019],
            [-86.0754, 31.3455],
            [-86.1951, 31.3534],
            [-86.194, 31.4401],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101260',
        NAME: 'Elba City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217407282,
        AWATER: 1375445,
        INTPTLAT: '+31.4294292',
        INTPTLON: '-086.1078476',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.375, 32.7536],
            [-86.0072, 32.755],
            [-85.8954, 32.7546],
            [-85.9077, 32.6987],
            [-85.9902, 32.5372],
            [-85.9954, 32.4281],
            [-86.023, 32.42],
            [-86.1163, 32.417],
            [-86.2371, 32.495],
            [-86.4112, 32.4099],
            [-86.4131, 32.7074],
            [-86.375, 32.7536],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101290',
        NAME: 'Elmore County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1364745254,
        AWATER: 87688162,
        INTPTLAT: '+32.6027590',
        INTPTLON: '-086.1853080',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.79, 31.3419],
            [-85.7901, 31.3301],
            [-85.7903, 31.3235],
            [-85.7904, 31.3227],
            [-85.8881, 31.2847],
            [-85.8886, 31.3715],
            [-85.79, 31.3419],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101320',
        NAME: 'Enterprise City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79305520,
        AWATER: 174204,
        INTPTLAT: '+31.3269122',
        INTPTLON: '-085.8440771',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6159, 31.2445],
            [-87.549, 31.23],
            [-87.4275, 31.2604],
            [-86.764, 31.2613],
            [-86.7003, 31.1922],
            [-86.6883, 30.9945],
            [-86.7857, 30.997],
            [-87.1631, 30.999],
            [-87.5988, 30.9975],
            [-87.6159, 31.2445],
          ],
          [
            [-87.1242, 31.0858],
            [-87.0845, 31.0782],
            [-87.0516, 31.1083],
            [-87.0772, 31.1407],
            [-87.0899, 31.0967],
            [-87.1242, 31.0858],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101350',
        NAME: 'Escambia County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2418718731,
        AWATER: 20262991,
        INTPTLAT: '+31.1222867',
        INTPTLON: '-087.1684097',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.1694, 34.1865],
              [-86.1672, 34.1875],
              [-86.1698, 34.1762],
              [-86.1694, 34.1865],
            ],
          ],
          [
            [
              [-86.3035, 34.0991],
              [-86.1788, 34.184],
              [-86.1698, 34.1762],
              [-86.1698, 34.1726],
              [-86.1408, 34.187],
              [-86.1322, 34.1869],
              [-86.1188, 34.1865],
              [-86.115, 34.1864],
              [-86.1057, 34.1901],
              [-86.1059, 34.1934],
              [-86.1061, 34.2008],
              [-85.8436, 34.2],
              [-85.739, 33.9685],
              [-85.8818, 33.9503],
              [-86.0653, 33.8422],
              [-86.2155, 33.9874],
              [-86.3256, 33.9401],
              [-86.3035, 34.0991],
            ],
            [
              [-86.0923, 33.9901],
              [-85.9712, 33.9657],
              [-85.9713, 34.0541],
              [-86.0675, 34.0405],
              [-86.0996, 34.0332],
              [-86.1366, 33.9966],
              [-86.0923, 33.9901],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101380',
        NAME: 'Etowah County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1263875900,
        AWATER: 31484650,
        INTPTLAT: '+34.1161164',
        INTPTLON: '-085.8958874',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0542, 32.0267],
            [-85.0682, 31.9919],
            [-85.1407, 31.8575],
            [-85.1316, 31.8185],
            [-85.1325, 31.8004],
            [-85.1419, 31.7822],
            [-85.2301, 31.8427],
            [-85.2093, 31.9307],
            [-85.139, 32.0039],
            [-85.0542, 32.0267],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101410',
        NAME: 'Eufaula City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153817226,
        AWATER: 36482611,
        INTPTLAT: '+31.9100408',
        INTPTLON: '-085.1525559',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9364, 33.4597],
            [-86.9361, 33.4596],
            [-86.9391, 33.4647],
            [-86.9187, 33.4939],
            [-86.9124, 33.5002],
            [-86.9106, 33.5002],
            [-86.9045, 33.4721],
            [-86.9049, 33.47],
            [-86.9065, 33.4683],
            [-86.922, 33.4567],
            [-86.9364, 33.4597],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101440',
        NAME: 'Fairfield City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8987176,
        AWATER: 0,
        INTPTLAT: '+33.4747054',
        INTPTLON: '-086.9193397',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9518, 33.9199],
            [-87.9147, 33.9194],
            [-87.8964, 33.8531],
            [-87.804, 33.841],
            [-87.736, 33.9148],
            [-87.6359, 33.9153],
            [-87.5316, 33.8676],
            [-87.5283, 33.692],
            [-87.4238, 33.6891],
            [-87.4237, 33.6021],
            [-87.6317, 33.6098],
            [-87.6667, 33.5217],
            [-87.8407, 33.5248],
            [-87.9465, 33.5241],
            [-87.9518, 33.9199],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101470',
        NAME: 'Fayette County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1553567989,
        AWATER: 4282557,
        INTPTLAT: '+33.7089245',
        INTPTLON: '-087.7409780',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7225, 34.8173],
            [-87.6836, 34.8866],
            [-87.618, 34.8083],
            [-87.7225, 34.8173],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101530',
        NAME: 'Florence City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67609957,
        AWATER: 522106,
        INTPTLAT: '+34.8303226',
        INTPTLON: '-087.6661386',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6275, 34.3961],
            [-85.6274, 34.3955],
            [-85.7752, 34.3907],
            [-85.7581, 34.4538],
            [-85.6668, 34.5395],
            [-85.6181, 34.4612],
            [-85.6275, 34.3961],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101560',
        NAME: 'Fort Payne City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147041399,
        AWATER: 921230,
        INTPTLAT: '+34.4573293',
        INTPTLON: '-085.6894469',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1563, 34.4632],
            [-88.14, 34.5817],
            [-87.5297, 34.5671],
            [-87.5297, 34.3046],
            [-87.5733, 34.3053],
            [-87.6347, 34.307],
            [-88.1736, 34.3211],
            [-88.1563, 34.4632],
          ],
          [
            [-87.7125, 34.4618],
            [-87.7065, 34.5201],
            [-87.7591, 34.5283],
            [-87.7589, 34.4846],
            [-87.7125, 34.4618],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101590',
        NAME: 'Franklin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1606858151,
        AWATER: 32632156,
        INTPTLAT: '+34.4419892',
        INTPTLON: '-087.8428144',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0923, 33.9901],
            [-86.1009, 34.0012],
            [-86.0719, 34.0114],
            [-86.0675, 34.0405],
            [-85.9713, 34.0541],
            [-85.9712, 33.9657],
            [-86.0923, 33.9901],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101620',
        NAME: 'Gadsden City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96630456,
        AWATER: 3060329,
        INTPTLAT: '+34.0095631',
        INTPTLON: '-086.0156426',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7727, 30.9946],
            [-85.9973, 30.9929],
            [-85.9492, 31.0975],
            [-85.7962, 31.105],
            [-85.7727, 30.9946],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101640',
        NAME: 'Geneva City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218645870,
        AWATER: 2514519,
        INTPTLAT: '+31.0452131',
        INTPTLON: '-085.8800532',
        ELSDLEA: '',
        UNSDLEA: '01640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1935, 31.1922],
            [-85.7914, 31.1963],
            [-85.7109, 31.1952],
            [-85.4858, 31.1999],
            [-85.4883, 30.9971],
            [-85.498, 30.9969],
            [-85.7727, 30.9946],
            [-85.7962, 31.105],
            [-85.9492, 31.0975],
            [-85.9973, 30.9929],
            [-86.035, 30.9933],
            [-86.1872, 30.994],
            [-86.1935, 31.1922],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101660',
        NAME: 'Geneva County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1269064903,
        AWATER: 9250558,
        INTPTLAT: '+31.1467725',
        INTPTLON: '-085.6629566',
        ELSDLEA: '',
        UNSDLEA: '01660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1719, 32.9959],
            [-87.9688, 33.0776],
            [-87.8375, 33.1536],
            [-87.8322, 33.0173],
            [-87.7157, 33.0068],
            [-87.7683, 32.899],
            [-87.8316, 32.8649],
            [-87.7866, 32.6825],
            [-87.8126, 32.5246],
            [-87.8236, 32.5244],
            [-87.8534, 32.5321],
            [-87.9287, 32.6323],
            [-88.055, 32.6465],
            [-88.1327, 32.8329],
            [-88.2073, 32.9248],
            [-88.1719, 32.9959],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101680',
        NAME: 'Greene County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1675993309,
        AWATER: 33244743,
        INTPTLAT: '+32.8444965',
        INTPTLON: '-087.9642005',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2661, 34.2994],
            [-86.2661, 34.2978],
            [-86.3359, 34.3704],
            [-86.2292, 34.422],
            [-86.1916, 34.3758],
            [-86.2661, 34.2994],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101690',
        NAME: 'Guntersville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65748892,
        AWATER: 44109667,
        INTPTLAT: '+34.3679473',
        INTPTLON: '-086.2523225',
        ELSDLEA: '',
        UNSDLEA: '01690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7157, 33.0068],
            [-87.4219, 33.0034],
            [-87.4212, 32.8745],
            [-87.4722, 32.8306],
            [-87.4739, 32.6559],
            [-87.5252, 32.6557],
            [-87.5245, 32.482],
            [-87.7287, 32.4809],
            [-87.808, 32.5245],
            [-87.8126, 32.5246],
            [-87.7866, 32.6825],
            [-87.8316, 32.8649],
            [-87.7683, 32.899],
            [-87.7157, 33.0068],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101710',
        NAME: 'Hale County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1667804583,
        AWATER: 32525874,
        INTPTLAT: '+32.7527958',
        INTPTLON: '-087.6230608',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6347, 34.307],
            [-87.5733, 34.3053],
            [-87.5169, 34.2609],
            [-87.512, 34.1667],
            [-87.6359, 34.1424],
            [-87.6347, 34.307],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101720',
        NAME: 'Haleyville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192048269,
        AWATER: 926136,
        INTPTLAT: '+34.2110276',
        INTPTLON: '-087.5776691',
        ELSDLEA: '',
        UNSDLEA: '01720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.971, 34.4299],
            [-86.9055, 34.4653],
            [-86.8997, 34.431],
            [-86.9297, 34.3983],
            [-86.971, 34.4299],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101730',
        NAME: 'Hartselle City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38388174,
        AWATER: 198372,
        INTPTLAT: '+34.4383083',
        INTPTLON: '-086.9411417',
        ELSDLEA: '',
        UNSDLEA: '01730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4164, 31.6195],
            [-85.416, 31.7067],
            [-85.2161, 31.7024],
            [-85.1273, 31.7626],
            [-85.1259, 31.6963],
            [-85.0575, 31.6186],
            [-85.0455, 31.5171],
            [-85.0877, 31.3087],
            [-85.3735, 31.3145],
            [-85.4174, 31.315],
            [-85.4175, 31.3257],
            [-85.4175, 31.3295],
            [-85.4164, 31.6195],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101740',
        NAME: 'Henry County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1454502865,
        AWATER: 16986823,
        INTPTLAT: '+31.5169779',
        INTPTLON: '-085.2399712',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8402, 33.4598],
            [-86.8405, 33.4629],
            [-86.8395, 33.4651],
            [-86.8396, 33.4658],
            [-86.8382, 33.466],
            [-86.8368, 33.4664],
            [-86.8345, 33.4676],
            [-86.8391, 33.4711],
            [-86.8385, 33.4717],
            [-86.8379, 33.4721],
            [-86.8294, 33.4744],
            [-86.8264, 33.475],
            [-86.8229, 33.4676],
            [-86.8222, 33.4673],
            [-86.7868, 33.4876],
            [-86.7861, 33.4864],
            [-86.7854, 33.4864],
            [-86.7834, 33.4846],
            [-86.7829, 33.4841],
            [-86.7816, 33.4834],
            [-86.7801, 33.4829],
            [-86.7768, 33.4824],
            [-86.7768, 33.4816],
            [-86.7768, 33.4797],
            [-86.7781, 33.4792],
            [-86.7786, 33.4791],
            [-86.7722, 33.4772],
            [-86.7702, 33.4718],
            [-86.7701, 33.4694],
            [-86.7707, 33.4683],
            [-86.7703, 33.4661],
            [-86.7707, 33.4616],
            [-86.7712, 33.4613],
            [-86.78, 33.4613],
            [-86.7846, 33.4579],
            [-86.785, 33.4575],
            [-86.7853, 33.4571],
            [-86.7859, 33.4563],
            [-86.788, 33.456],
            [-86.7898, 33.4554],
            [-86.7919, 33.4584],
            [-86.7932, 33.4586],
            [-86.8054, 33.4472],
            [-86.8063, 33.4457],
            [-86.8078, 33.444],
            [-86.8122, 33.443],
            [-86.8137, 33.4413],
            [-86.8165, 33.4412],
            [-86.8212, 33.4399],
            [-86.8193, 33.4401],
            [-86.8236, 33.4344],
            [-86.8303, 33.4322],
            [-86.8347, 33.4303],
            [-86.8358, 33.4294],
            [-86.8375, 33.4288],
            [-86.8384, 33.4274],
            [-86.8415, 33.4262],
            [-86.8417, 33.4332],
            [-86.8357, 33.4372],
            [-86.8276, 33.4403],
            [-86.827, 33.442],
            [-86.8275, 33.4435],
            [-86.8365, 33.4443],
            [-86.8385, 33.4436],
            [-86.8478, 33.4472],
            [-86.8484, 33.4484],
            [-86.8402, 33.4598],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101760',
        NAME: 'Homewood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21657260,
        AWATER: 171816,
        INTPTLAT: '+33.4585496',
        INTPTLON: '-086.8096502',
        ELSDLEA: '',
        UNSDLEA: '01760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.4692, 31.2869],
              [-85.469, 31.2544],
              [-85.4857, 31.2529],
              [-85.4858, 31.2564],
              [-85.4816, 31.2561],
              [-85.4817, 31.2653],
              [-85.4858, 31.2653],
              [-85.4692, 31.2869],
            ],
          ],
          [
            [
              [-85.6751, 31.2556],
              [-85.5113, 31.2506],
              [-85.4327, 31.1704],
              [-85.346, 31.1683],
              [-85.3232, 31.2469],
              [-85.3735, 31.3145],
              [-85.0877, 31.3087],
              [-85.0996, 31.1649],
              [-85.0286, 31.0755],
              [-85.0025, 31.0007],
              [-85.4883, 30.9971],
              [-85.4858, 31.1999],
              [-85.7109, 31.1952],
              [-85.6751, 31.2556],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101770',
        NAME: 'Houston County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1289968311,
        AWATER: 3859293,
        INTPTLAT: '+31.1328169',
        INTPTLON: '-085.2815425',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.6921, 34.7135],
              [-86.6919, 34.7135],
              [-86.6921, 34.7133],
              [-86.6921, 34.7135],
            ],
          ],
          [
            [
              [-86.7859, 34.7583],
              [-86.7859, 34.7637],
              [-86.7859, 34.7655],
              [-86.7369, 34.7721],
              [-86.7128, 34.7644],
              [-86.7152, 34.753],
              [-86.7167, 34.753],
              [-86.7189, 34.7566],
              [-86.7406, 34.7542],
              [-86.747, 34.7579],
              [-86.7682, 34.7569],
              [-86.786, 34.7548],
              [-86.7859, 34.7583],
            ],
          ],
          [
            [
              [-86.7869, 34.6626],
              [-86.7099, 34.7082],
              [-86.625, 34.8299],
              [-86.5229, 34.7832],
              [-86.5198, 34.7266],
              [-86.4437, 34.6671],
              [-86.4751, 34.6528],
              [-86.5709, 34.5887],
              [-86.5865, 34.5827],
              [-86.6464, 34.5489],
              [-86.7238, 34.581],
              [-86.788, 34.618],
              [-86.7869, 34.6626],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101800',
        NAME: 'Huntsville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537324543,
        AWATER: 12884881,
        INTPTLAT: '+34.6877451',
        INTPTLON: '-086.6073033',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3113, 34.9911],
            [-85.8639, 34.9883],
            [-85.7579, 34.9872],
            [-85.7022, 34.9864],
            [-85.6052, 34.9847],
            [-85.5831, 34.8604],
            [-85.6271, 34.8319],
            [-85.7855, 34.6246],
            [-86.0038, 34.48],
            [-86.0577, 34.476],
            [-86.1498, 34.5336],
            [-86.1485, 34.5991],
            [-86.3269, 34.5994],
            [-86.359, 34.6326],
            [-86.3397, 34.7532],
            [-86.3511, 34.8733],
            [-86.3113, 34.9911],
          ],
          [
            [-86.0281, 34.7115],
            [-86.1124, 34.683],
            [-86.0658, 34.5691],
            [-85.9571, 34.6609],
            [-86.0281, 34.7115],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101830',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2659211607,
        AWATER: 112092664,
        INTPTLAT: '+34.7641140',
        INTPTLON: '-085.9800556',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7568, 33.7959],
            [-85.7809, 33.8246],
            [-85.7292, 33.8327],
            [-85.7568, 33.7959],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101860',
        NAME: 'Jacksonville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25981585,
        AWATER: 24984,
        INTPTLAT: '+33.8062138',
        INTPTLON: '-085.7582878',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3273, 33.8697],
            [-87.3309, 33.8548],
            [-87.3202, 33.8554],
            [-87.3057, 33.8585],
            [-87.3134, 33.8824],
            [-87.2583, 33.8891],
            [-87.2486, 33.7996],
            [-87.3038, 33.8168],
            [-87.3046, 33.8511],
            [-87.3129, 33.8538],
            [-87.3529, 33.8525],
            [-87.3576, 33.8592],
            [-87.3339, 33.859],
            [-87.3273, 33.8697],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101890',
        NAME: 'Jasper City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73884121,
        AWATER: 222997,
        INTPTLAT: '+33.8560346',
        INTPTLON: '-087.2702683',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.6474, 33.6127],
              [-86.6477, 33.5988],
              [-86.6477, 33.5979],
              [-86.6519, 33.6023],
              [-86.6474, 33.6127],
            ],
          ],
          [
            [
              [-86.7011, 33.4976],
              [-86.7013, 33.4996],
              [-86.7006, 33.501],
              [-86.7026, 33.5012],
              [-86.7261, 33.515],
              [-86.7065, 33.5501],
              [-86.6567, 33.5541],
              [-86.6607, 33.5014],
              [-86.6725, 33.5007],
              [-86.6726, 33.5051],
              [-86.679, 33.5017],
              [-86.6867, 33.4995],
              [-86.6905, 33.5031],
              [-86.7011, 33.4976],
            ],
          ],
          [
            [
              [-86.7712, 33.4155],
              [-86.7713, 33.4151],
              [-86.7721, 33.4152],
              [-86.7712, 33.4155],
            ],
          ],
          [
            [
              [-86.7721, 33.4152],
              [-86.7713, 33.4151],
              [-86.7712, 33.4155],
              [-86.7651, 33.4098],
              [-86.7741, 33.4138],
              [-86.7721, 33.4152],
            ],
          ],
          [
            [
              [-86.7756, 33.4131],
              [-86.7753, 33.4134],
              [-86.7744, 33.4136],
              [-86.7785, 33.4111],
              [-86.7756, 33.4131],
            ],
          ],
          [
            [
              [-86.7749, 33.3889],
              [-86.7784, 33.3869],
              [-86.7793, 33.3888],
              [-86.7749, 33.3889],
            ],
          ],
          [
            [
              [-86.7801, 33.4829],
              [-86.7745, 33.4849],
              [-86.7768, 33.4824],
              [-86.7801, 33.4829],
            ],
          ],
          [
            [
              [-86.5774, 33.6203],
              [-86.5342, 33.6344],
              [-86.5515, 33.6078],
              [-86.5774, 33.6203],
            ],
          ],
          [
            [
              [-86.5515, 33.5944],
              [-86.5516, 33.5894],
              [-86.5821, 33.5761],
              [-86.5515, 33.5944],
            ],
          ],
          [
            [
              [-86.5865, 33.4973],
              [-86.584, 33.4984],
              [-86.5869, 33.4997],
              [-86.5741, 33.5033],
              [-86.587, 33.4961],
              [-86.5865, 33.4973],
            ],
          ],
          [
            [
              [-86.6034, 33.5311],
              [-86.6025, 33.5317],
              [-86.5949, 33.5318],
              [-86.5987, 33.5254],
              [-86.6034, 33.5311],
            ],
          ],
          [
            [
              [-86.6132, 33.5725],
              [-86.6132, 33.5758],
              [-86.6131, 33.5801],
              [-86.6108, 33.5833],
              [-86.6106, 33.5833],
              [-86.6064, 33.5834],
              [-86.5866, 33.5761],
              [-86.6082, 33.5573],
              [-86.6082, 33.5566],
              [-86.6137, 33.5541],
              [-86.6153, 33.5618],
              [-86.6132, 33.5725],
            ],
          ],
          [
            [
              [-86.6289, 33.4765],
              [-86.6237, 33.4668],
              [-86.6264, 33.4668],
              [-86.6305, 33.4668],
              [-86.6289, 33.4765],
            ],
          ],
          [
            [
              [-86.606, 33.5171],
              [-86.6058, 33.5175],
              [-86.593, 33.5287],
              [-86.6054, 33.5123],
              [-86.6162, 33.507],
              [-86.6169, 33.5058],
              [-86.6162, 33.5054],
              [-86.6161, 33.5054],
              [-86.6217, 33.5035],
              [-86.6216, 33.4955],
              [-86.6301, 33.4935],
              [-86.6301, 33.4885],
              [-86.6397, 33.4885],
              [-86.6341, 33.4943],
              [-86.6287, 33.4981],
              [-86.6271, 33.5002],
              [-86.6204, 33.506],
              [-86.6142, 33.5101],
              [-86.606, 33.5171],
            ],
          ],
          [
            [
              [-86.8165, 33.4412],
              [-86.8193, 33.4401],
              [-86.8212, 33.4399],
              [-86.8165, 33.4412],
            ],
          ],
          [
            [
              [-86.8236, 33.4344],
              [-86.8171, 33.4298],
              [-86.8127, 33.4226],
              [-86.8103, 33.4196],
              [-86.8168, 33.415],
              [-86.8149, 33.4212],
              [-86.815, 33.4226],
              [-86.8152, 33.4229],
              [-86.8159, 33.4231],
              [-86.8303, 33.4322],
              [-86.8236, 33.4344],
            ],
          ],
          [
            [
              [-86.8305, 33.3387],
              [-86.8306, 33.3373],
              [-86.8306, 33.3371],
              [-86.8306, 33.3362],
              [-86.8311, 33.3369],
              [-86.8305, 33.3387],
            ],
          ],
          [
            [
              [-86.8031, 33.4107],
              [-86.7914, 33.407],
              [-86.7801, 33.3972],
              [-86.7645, 33.4092],
              [-86.753, 33.4299],
              [-86.7448, 33.4402],
              [-86.7403, 33.4384],
              [-86.7385, 33.4338],
              [-86.7395, 33.4308],
              [-86.7387, 33.4293],
              [-86.7376, 33.4288],
              [-86.7261, 33.4287],
              [-86.7197, 33.4232],
              [-86.7277, 33.4165],
              [-86.7505, 33.4173],
              [-86.7508, 33.4174],
              [-86.7514, 33.4174],
              [-86.7524, 33.4165],
              [-86.7525, 33.4159],
              [-86.7696, 33.3887],
              [-86.7721, 33.3889],
              [-86.7784, 33.3863],
              [-86.7785, 33.3711],
              [-86.8031, 33.4107],
            ],
            [
              [-86.7903, 33.4064],
              [-86.7903, 33.4063],
              [-86.7901, 33.406],
              [-86.7903, 33.4064],
            ],
          ],
          [
            [
              [-86.8995, 33.3417],
              [-86.8773, 33.3442],
              [-86.8761, 33.345],
              [-86.8771, 33.3439],
              [-86.8859, 33.342],
              [-86.8995, 33.3417],
              [-86.8995, 33.3417],
            ],
          ],
          [
            [
              [-86.8925, 33.3837],
              [-86.8603, 33.3829],
              [-86.8603, 33.3757],
              [-86.865, 33.3757],
              [-86.8782, 33.3548],
              [-86.8825, 33.3588],
              [-86.8925, 33.3837],
            ],
          ],
          [
            [
              [-86.935, 33.4281],
              [-86.8902, 33.4423],
              [-86.9215, 33.417],
              [-86.935, 33.4281],
            ],
          ],
          [
            [
              [-87.0927, 33.6347],
              [-87.0398, 33.6854],
              [-87.0233, 33.7727],
              [-86.9537, 33.8153],
              [-86.7591, 33.8406],
              [-86.6453, 33.773],
              [-86.5778, 33.7653],
              [-86.5254, 33.6636],
              [-86.5694, 33.6612],
              [-86.5503, 33.687],
              [-86.6087, 33.678],
              [-86.6471, 33.6229],
              [-86.7017, 33.622],
              [-86.7447, 33.6046],
              [-86.7882, 33.5749],
              [-86.852, 33.5874],
              [-86.9236, 33.5243],
              [-87.0122, 33.4944],
              [-87.1166, 33.5167],
              [-87.1092, 33.5872],
              [-87.0642, 33.5892],
              [-87.0927, 33.6347],
            ],
            [
              [-86.717, 33.631],
              [-86.7135, 33.6289],
              [-86.7153, 33.6303],
              [-86.717, 33.631],
            ],
          ],
          [
            [
              [-87.2669, 33.5129],
              [-87.1794, 33.6138],
              [-87.0927, 33.6278],
              [-87.1138, 33.5941],
              [-87.1178, 33.516],
              [-87.0113, 33.4934],
              [-86.935, 33.4949],
              [-86.9187, 33.4939],
              [-86.9391, 33.4647],
              [-86.9513, 33.4518],
              [-86.922, 33.4477],
              [-86.9335, 33.4357],
              [-87.0135, 33.3527],
              [-86.9197, 33.3239],
              [-86.9083, 33.3332],
              [-86.9001, 33.3147],
              [-86.9001, 33.3135],
              [-86.9001, 33.3115],
              [-86.9001, 33.3079],
              [-86.922, 33.2965],
              [-86.9233, 33.2966],
              [-86.9238, 33.2966],
              [-86.9261, 33.2949],
              [-86.9261, 33.2942],
              [-86.9262, 33.2892],
              [-87.0268, 33.2465],
              [-87.0657, 33.2469],
              [-87.1796, 33.3211],
              [-87.2844, 33.4406],
              [-87.2669, 33.5129],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101920',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2068225672,
        AWATER: 25371082,
        INTPTLAT: '+33.5753456',
        INTPTLON: '-086.9404745',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2489, 33.745],
            [-88.2072, 34.0583],
            [-87.9869, 34.0521],
            [-87.9518, 33.9199],
            [-87.9465, 33.5241],
            [-88.2746, 33.534],
            [-88.2489, 33.745],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101950',
        NAME: 'Lamar County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1566544639,
        AWATER: 1600679,
        INTPTLAT: '+33.7870852',
        INTPTLON: '-088.0874309',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2058, 32.8861],
            [-85.187, 32.8816],
            [-85.1853, 32.8733],
            [-85.1849, 32.8634],
            [-85.1785, 32.8547],
            [-85.2058, 32.8861],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '101980',
        NAME: 'Lanett City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16167426,
        AWATER: 0,
        INTPTLAT: '+32.8577677',
        INTPTLON: '-085.2077611',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9849, 35.0059],
            [-87.606, 35.0034],
            [-87.2241, 34.9994],
            [-87.2108, 34.999],
            [-87.2149, 34.816],
            [-87.2607, 34.7586],
            [-87.4265, 34.8],
            [-87.5244, 34.8324],
            [-87.8358, 34.7412],
            [-87.9732, 34.8826],
            [-88.0979, 34.8922],
            [-88.2001, 34.9956],
            [-87.9849, 35.0059],
          ],
          [
            [-87.7225, 34.8173],
            [-87.618, 34.8083],
            [-87.6836, 34.8866],
            [-87.7225, 34.8173],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102010',
        NAME: 'Lauderdale County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1661724121,
        AWATER: 133898818,
        INTPTLAT: '+34.9041221',
        INTPTLON: '-087.6509966',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5297, 34.5671],
            [-87.4331, 34.7175],
            [-87.4265, 34.8],
            [-87.2607, 34.7586],
            [-87.1051, 34.686],
            [-87.1053, 34.6618],
            [-87.1053, 34.6592],
            [-87.1054, 34.657],
            [-87.1055, 34.6355],
            [-87.1101, 34.3138],
            [-87.1099, 34.2993],
            [-87.5297, 34.3046],
            [-87.5297, 34.5671],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102040',
        NAME: 'Lawrence County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1788846346,
        AWATER: 68687359,
        INTPTLAT: '+34.5297760',
        INTPTLON: '-087.3218651',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.0184, 32.5098],
              [-85.0187, 32.5098],
              [-85.0193, 32.5098],
              [-85.0201, 32.5098],
              [-85.0202, 32.5107],
              [-85.0184, 32.5106],
              [-85.0184, 32.5098],
            ],
          ],
          [
            [
              [-85.4568, 32.6572],
              [-85.4551, 32.65],
              [-85.4518, 32.6535],
              [-85.4453, 32.6409],
              [-85.468, 32.64],
              [-85.4568, 32.6572],
            ],
          ],
          [
            [
              [-85.4902, 32.716],
              [-85.4907, 32.7306],
              [-85.4729, 32.7305],
              [-85.4568, 32.7303],
              [-85.3092, 32.7307],
              [-85.4568, 32.7115],
              [-85.4727, 32.6649],
              [-85.4893, 32.6839],
              [-85.4902, 32.716],
            ],
          ],
          [
            [
              [-85.5932, 32.7285],
              [-85.4907, 32.7306],
              [-85.4902, 32.716],
              [-85.5456, 32.672],
              [-85.542, 32.6128],
              [-85.5386, 32.6134],
              [-85.5174, 32.5841],
              [-85.5156, 32.5672],
              [-85.539, 32.5498],
              [-85.5353, 32.5402],
              [-85.5328, 32.5423],
              [-85.5353, 32.5407],
              [-85.5272, 32.5484],
              [-85.52, 32.5312],
              [-85.48, 32.5351],
              [-85.4374, 32.5275],
              [-85.4234, 32.5455],
              [-85.4132, 32.5967],
              [-85.3599, 32.5943],
              [-85.3114, 32.6657],
              [-85.3071, 32.6617],
              [-85.285, 32.7392],
              [-85.135, 32.7465],
              [-85.0808, 32.6081],
              [-85.0015, 32.5147],
              [-85.0511, 32.5093],
              [-85.0602, 32.4918],
              [-85.0848, 32.4723],
              [-85.0891, 32.4722],
              [-85.0938, 32.4721],
              [-85.3338, 32.4686],
              [-85.434, 32.4098],
              [-85.4893, 32.4969],
              [-85.6959, 32.5959],
              [-85.6968, 32.6974],
              [-85.5932, 32.7285],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102070',
        NAME: 'Lee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1256349147,
        AWATER: 16411860,
        INTPTLAT: '+32.5810513',
        INTPTLON: '-085.3503058',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2108, 34.999],
            [-86.8363, 34.9919],
            [-86.7836, 34.9919],
            [-86.7859, 34.7655],
            [-86.7859, 34.7637],
            [-86.7859, 34.7583],
            [-86.786, 34.7548],
            [-86.7862, 34.7436],
            [-86.7869, 34.6626],
            [-86.788, 34.618],
            [-86.7884, 34.6055],
            [-86.7884, 34.6048],
            [-86.7901, 34.5508],
            [-86.9067, 34.5798],
            [-86.9057, 34.6426],
            [-86.9734, 34.6135],
            [-87.0878, 34.671],
            [-87.1051, 34.686],
            [-87.2607, 34.7586],
            [-87.2149, 34.816],
            [-87.2108, 34.999],
          ],
          [
            [-86.9747, 34.746],
            [-86.9193, 34.829],
            [-87.0019, 34.836],
            [-86.9747, 34.746],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102100',
        NAME: 'Limestone County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1319248060,
        AWATER: 121302978,
        INTPTLAT: '+34.7707064',
        INTPTLON: '-087.0327422',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9297, 32.2925],
            [-87.8955, 32.387],
            [-87.8131, 32.4407],
            [-87.6942, 32.396],
            [-87.7323, 32.2451],
            [-87.8321, 32.1691],
            [-87.9733, 32.2199],
            [-88.0096, 32.1955],
            [-88.0191, 32.2852],
            [-87.9297, 32.2925],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102130',
        NAME: 'Linden City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550441591,
        AWATER: 3062730,
        INTPTLAT: '+32.3019011',
        INTPTLON: '-087.8337535',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8149, 32.3408],
            [-86.7814, 32.3925],
            [-86.6534, 32.3972],
            [-86.4968, 32.3444],
            [-86.4088, 32.2443],
            [-86.4063, 32.0507],
            [-86.4482, 31.9646],
            [-86.8576, 31.9622],
            [-86.907, 32.048],
            [-86.9083, 32.225],
            [-86.8149, 32.3408],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102160',
        NAME: 'Lowndes County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1854202072,
        AWATER: 23721045,
        INTPTLAT: '+32.1478880',
        INTPTLON: '-086.6505859',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.023, 32.42],
            [-85.9954, 32.4281],
            [-85.8861, 32.4931],
            [-85.7972, 32.4942],
            [-85.7986, 32.5811],
            [-85.6959, 32.5959],
            [-85.4893, 32.4969],
            [-85.434, 32.4098],
            [-85.4335, 32.2346],
            [-85.8562, 32.232],
            [-85.9193, 32.2744],
            [-85.9891, 32.3348],
            [-86.023, 32.42],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102190',
        NAME: 'Macon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1577005077,
        AWATER: 11195999,
        INTPTLAT: '+32.3870267',
        INTPTLON: '-085.6928870',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.7406, 34.7542],
              [-86.7189, 34.7566],
              [-86.7167, 34.753],
              [-86.7343, 34.7545],
              [-86.7328, 34.7526],
              [-86.7369, 34.7534],
              [-86.7406, 34.7542],
            ],
          ],
          [
            [
              [-86.7836, 34.9919],
              [-86.3188, 34.9912],
              [-86.3113, 34.9911],
              [-86.3511, 34.8733],
              [-86.3397, 34.7532],
              [-86.359, 34.6326],
              [-86.3269, 34.5994],
              [-86.3112, 34.5393],
              [-86.4255, 34.4793],
              [-86.5, 34.4823],
              [-86.5502, 34.546],
              [-86.5865, 34.5827],
              [-86.5709, 34.5887],
              [-86.4751, 34.6528],
              [-86.4437, 34.6671],
              [-86.5198, 34.7266],
              [-86.5229, 34.7832],
              [-86.625, 34.8299],
              [-86.7099, 34.7082],
              [-86.7097, 34.7126],
              [-86.7111, 34.7139],
              [-86.7084, 34.7154],
              [-86.7178, 34.7197],
              [-86.7129, 34.7198],
              [-86.7152, 34.753],
              [-86.7128, 34.7644],
              [-86.7369, 34.7721],
              [-86.7859, 34.7655],
              [-86.7836, 34.9919],
            ],
            [
              [-86.6921, 34.7133],
              [-86.6919, 34.7135],
              [-86.6921, 34.7135],
              [-86.6921, 34.7133],
            ],
          ],
          [
            [
              [-86.786, 34.7548],
              [-86.7682, 34.7569],
              [-86.747, 34.7579],
              [-86.7862, 34.7436],
              [-86.786, 34.7548],
            ],
          ],
          [
            [
              [-86.7884, 34.6048],
              [-86.7884, 34.6055],
              [-86.788, 34.618],
              [-86.7238, 34.581],
              [-86.7901, 34.5508],
              [-86.7884, 34.6048],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102220',
        NAME: 'Madison County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1478442274,
        AWATER: 15590218,
        INTPTLAT: '+34.7956622',
        INTPTLON: '-086.5221670',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0096, 32.1955],
            [-87.9733, 32.2199],
            [-87.8321, 32.1691],
            [-87.7323, 32.2451],
            [-87.6942, 32.396],
            [-87.8131, 32.4407],
            [-87.8955, 32.387],
            [-87.9297, 32.2925],
            [-87.9307, 32.3106],
            [-88.0463, 32.3771],
            [-88.0316, 32.4336],
            [-87.8534, 32.5321],
            [-87.8236, 32.5244],
            [-87.808, 32.5245],
            [-87.7287, 32.4809],
            [-87.5245, 32.482],
            [-87.5243, 32.3073],
            [-87.4731, 32.3076],
            [-87.4722, 32.2649],
            [-87.5234, 32.2548],
            [-87.5219, 32.1328],
            [-87.624, 32.1323],
            [-87.6228, 32.0055],
            [-87.6678, 31.9914],
            [-87.8169, 31.9905],
            [-88.0735, 31.9902],
            [-88.0706, 32.0617],
            [-88.0096, 32.1955],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102250',
        NAME: 'Marengo County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1933729573,
        AWATER: 11094649,
        INTPTLAT: '+32.1276169',
        INTPTLON: '-087.6291144',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.6359, 33.9294],
              [-87.6359, 33.9153],
              [-87.736, 33.9148],
              [-87.6359, 33.9294],
            ],
          ],
          [
            [
              [-88.2039, 34.0866],
              [-88.1736, 34.3211],
              [-87.6347, 34.307],
              [-87.6359, 34.1424],
              [-87.6361, 34.0022],
              [-87.636, 33.9322],
              [-87.8522, 33.986],
              [-87.9147, 33.9194],
              [-87.9518, 33.9199],
              [-87.9869, 34.0521],
              [-88.2072, 34.0583],
              [-88.2039, 34.0866],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102310',
        NAME: 'Marion County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1819986817,
        AWATER: 2996969,
        INTPTLAT: '+34.1465462',
        INTPTLON: '-087.8894150',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9391, 33.4647],
            [-86.9361, 33.4596],
            [-86.9364, 33.4597],
            [-86.922, 33.4567],
            [-86.9065, 33.4683],
            [-86.9049, 33.47],
            [-86.9045, 33.4721],
            [-86.9007, 33.4604],
            [-86.9007, 33.4542],
            [-86.9009, 33.4533],
            [-86.902, 33.4505],
            [-86.9079, 33.4468],
            [-86.9142, 33.446],
            [-86.917, 33.4495],
            [-86.9204, 33.4495],
            [-86.922, 33.4477],
            [-86.9513, 33.4518],
            [-86.9391, 33.4647],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102350',
        NAME: 'Midfield City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6869817,
        AWATER: 0,
        INTPTLAT: '+33.4551966',
        INTPTLON: '-086.9226334',
        ELSDLEA: '',
        UNSDLEA: '02350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.432, 31.1143],
            [-88.3298, 31.1438],
            [-88.0226, 31.1443],
            [-87.9729, 31.1627],
            [-87.9416, 31.0613],
            [-87.9499, 30.9267],
            [-88.0263, 30.7534],
            [-87.9878, 30.6371],
            [-88.0371, 30.2224],
            [-88.0373, 30.1466],
            [-88.162, 30.2001],
            [-88.3844, 30.1585],
            [-88.4037, 30.5449],
            [-88.4126, 30.7356],
            [-88.4254, 30.9983],
            [-88.432, 31.1143],
          ],
          [
            [-88.0903, 30.7903],
            [-88.1002, 30.7617],
            [-88.0758, 30.7564],
            [-88.0721, 30.7822],
            [-88.0557, 30.8371],
            [-88.0893, 30.8593],
            [-88.1498, 30.8554],
            [-88.0903, 30.7903],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102370',
        NAME: 'Mobile County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3093799916,
        AWATER: 1072379795,
        INTPTLAT: '+30.6815691',
        INTPTLON: '-088.1995725',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5009, 31.8293],
            [-86.9069, 31.8306],
            [-86.9059, 31.753],
            [-87.1351, 31.6424],
            [-87.1666, 31.5196],
            [-87.4275, 31.2604],
            [-87.549, 31.23],
            [-87.6159, 31.2445],
            [-87.7652, 31.2973],
            [-87.706, 31.401],
            [-87.6031, 31.4096],
            [-87.5657, 31.4817],
            [-87.5668, 31.6971],
            [-87.5161, 31.6978],
            [-87.5009, 31.8293],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102400',
        NAME: 'Monroe County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2656521232,
        AWATER: 22609677,
        INTPTLAT: '+31.5803324',
        INTPTLON: '-087.3832656',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4968, 32.3444],
            [-86.4112, 32.4099],
            [-86.2371, 32.495],
            [-86.1163, 32.417],
            [-86.023, 32.42],
            [-85.9891, 32.3348],
            [-85.9193, 32.2744],
            [-85.9992, 32.2505],
            [-85.9969, 32.051],
            [-85.9956, 31.9676],
            [-86.1914, 31.9665],
            [-86.3022, 31.9651],
            [-86.3036, 32.0517],
            [-86.4063, 32.0507],
            [-86.4088, 32.2443],
            [-86.4968, 32.3444],
          ],
          [
            [-86.3766, 32.3936],
            [-86.3715, 32.3659],
            [-86.3335, 32.3767],
            [-86.3609, 32.398],
            [-86.3766, 32.3936],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102430',
        NAME: 'Montgomery County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2022892487,
        AWATER: 39825946,
        INTPTLAT: '+32.2028812',
        INTPTLON: '-086.2044615',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.1051, 34.686],
              [-87.0878, 34.671],
              [-87.1053, 34.6618],
              [-87.1051, 34.686],
            ],
          ],
          [
            [
              [-87.1055, 34.6355],
              [-87.0666, 34.5247],
              [-86.9478, 34.5157],
              [-86.9067, 34.5798],
              [-86.7901, 34.5508],
              [-86.7238, 34.581],
              [-86.6464, 34.5489],
              [-86.5865, 34.5827],
              [-86.5502, 34.546],
              [-86.5819, 34.3047],
              [-87.1101, 34.3138],
              [-87.1055, 34.6355],
            ],
            [
              [-86.971, 34.4299],
              [-86.9297, 34.3983],
              [-86.8997, 34.431],
              [-86.9055, 34.4653],
              [-86.971, 34.4299],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102480',
        NAME: 'Morgan County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1334314220,
        AWATER: 33582000,
        INTPTLAT: '+34.4410724',
        INTPTLON: '-086.8331885',
        ELSDLEA: '',
        UNSDLEA: '02480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7261, 33.515],
            [-86.7026, 33.5012],
            [-86.7006, 33.501],
            [-86.7013, 33.4996],
            [-86.709, 33.4889],
            [-86.7125, 33.4819],
            [-86.7103, 33.4822],
            [-86.7173, 33.4711],
            [-86.7441, 33.464],
            [-86.736, 33.4459],
            [-86.7403, 33.4455],
            [-86.7475, 33.4415],
            [-86.7501, 33.442],
            [-86.7488, 33.4433],
            [-86.7528, 33.4462],
            [-86.7579, 33.4615],
            [-86.7631, 33.4679],
            [-86.7703, 33.4661],
            [-86.7707, 33.4683],
            [-86.7701, 33.4694],
            [-86.7702, 33.4718],
            [-86.7722, 33.4772],
            [-86.7786, 33.4791],
            [-86.7781, 33.4792],
            [-86.7768, 33.4797],
            [-86.7768, 33.4816],
            [-86.7768, 33.4824],
            [-86.7745, 33.4849],
            [-86.7261, 33.515],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102490',
        NAME: 'Mountain Brook City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33228843,
        AWATER: 62886,
        INTPTLAT: '+33.4914885',
        INTPTLON: '-086.7344699',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6746, 34.7595],
            [-87.6077, 34.7916],
            [-87.6647, 34.7058],
            [-87.6666, 34.7053],
            [-87.6677, 34.7113],
            [-87.667, 34.7116],
            [-87.6678, 34.7133],
            [-87.6678, 34.7139],
            [-87.6748, 34.7188],
            [-87.6766, 34.7207],
            [-87.6785, 34.7227],
            [-87.6792, 34.745],
            [-87.6746, 34.7595],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102520',
        NAME: 'Muscle Shoals City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39765816,
        AWATER: 53102,
        INTPTLAT: '+34.7431698',
        INTPTLON: '-087.6337614',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5831, 33.8774],
            [-86.5778, 33.902],
            [-86.5324, 33.9181],
            [-86.5292, 33.9207],
            [-86.5242, 33.9185],
            [-86.5144, 33.9187],
            [-86.5064, 33.9252],
            [-86.4982, 33.9251],
            [-86.4985, 33.9766],
            [-86.4468, 33.9733],
            [-86.4461, 33.9253],
            [-86.502, 33.9245],
            [-86.5141, 33.9177],
            [-86.5224, 33.9177],
            [-86.5292, 33.9206],
            [-86.5421, 33.9099],
            [-86.5472, 33.8958],
            [-86.5541, 33.8958],
            [-86.5831, 33.8774],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102550',
        NAME: 'Oneonta City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40069506,
        AWATER: 149959,
        INTPTLAT: '+33.9470820',
        INTPTLON: '-086.4895097',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.4568, 32.6572],
              [-85.4552, 32.6567],
              [-85.4551, 32.65],
              [-85.4568, 32.6572],
            ],
          ],
          [
            [
              [-85.3092, 32.7307],
              [-85.285, 32.7392],
              [-85.3071, 32.6617],
              [-85.3114, 32.6657],
              [-85.3599, 32.5943],
              [-85.4132, 32.5967],
              [-85.4154, 32.5976],
              [-85.416, 32.5973],
              [-85.4453, 32.6409],
              [-85.4518, 32.6535],
              [-85.4552, 32.6567],
              [-85.4518, 32.6603],
              [-85.4572, 32.6594],
              [-85.4727, 32.6649],
              [-85.4568, 32.7115],
              [-85.3092, 32.7307],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102580',
        NAME: 'Opelika City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154494049,
        AWATER: 2388300,
        INTPTLAT: '+32.6607385',
        INTPTLON: '-085.3788898',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3139, 31.2703],
            [-86.2704, 31.3266],
            [-86.2113, 31.3015],
            [-86.2713, 31.2447],
            [-86.3139, 31.2703],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102610',
        NAME: 'Opp City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61289127,
        AWATER: 2396950,
        INTPTLAT: '+31.2843753',
        INTPTLON: '-086.2574417',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0216, 33.6012],
            [-85.914, 33.6212],
            [-85.9169, 33.6003],
            [-85.9118, 33.6005],
            [-85.886, 33.6004],
            [-85.886, 33.6016],
            [-85.8818, 33.6038],
            [-85.865, 33.6149],
            [-85.8648, 33.6169],
            [-85.8595, 33.6203],
            [-85.856, 33.6225],
            [-85.852, 33.6226],
            [-85.8509, 33.6225],
            [-85.8472, 33.6225],
            [-85.8294, 33.622],
            [-85.8279, 33.62],
            [-85.8279, 33.6222],
            [-85.8279, 33.6233],
            [-85.8254, 33.6255],
            [-85.8197, 33.6243],
            [-85.8077, 33.6162],
            [-85.7905, 33.6215],
            [-85.7862, 33.6219],
            [-85.76, 33.6323],
            [-85.76, 33.6334],
            [-85.7601, 33.6341],
            [-85.7422, 33.6],
            [-85.7961, 33.5562],
            [-85.9484, 33.5858],
            [-85.9518, 33.5858],
            [-86.0216, 33.6012],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102635',
        NAME: 'Oxford City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106842825,
        AWATER: 1896904,
        INTPTLAT: '+33.5900912',
        INTPTLON: '-085.8522522',
        ELSDLEA: '',
        UNSDLEA: '02635',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7591, 31.4838],
            [-85.6591, 31.5173],
            [-85.5468, 31.4201],
            [-85.6157, 31.3624],
            [-85.7591, 31.4838],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102640',
        NAME: 'Ozark City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170458397,
        AWATER: 692978,
        INTPTLAT: '+31.4434702',
        INTPTLON: '-085.6412920',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4816, 33.5458],
            [-86.3838, 33.5991],
            [-86.3686, 33.6536],
            [-86.205, 33.6827],
            [-86.1695, 33.6192],
            [-86.1898, 33.5472],
            [-86.3787, 33.391],
            [-86.3782, 33.5024],
            [-86.4816, 33.5458],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102650',
        NAME: 'Pell City City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402429261,
        AWATER: 34625859,
        INTPTLAT: '+33.5442921',
        INTPTLON: '-086.3068795',
        ELSDLEA: '',
        UNSDLEA: '02650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5245, 32.482],
            [-87.5252, 32.6557],
            [-87.4739, 32.6559],
            [-87.4722, 32.8306],
            [-87.4212, 32.8745],
            [-87.3192, 32.8315],
            [-87.0192, 32.837],
            [-87.0178, 32.7295],
            [-87.1124, 32.4886],
            [-87.4232, 32.483],
            [-87.4217, 32.3081],
            [-87.4731, 32.3076],
            [-87.5243, 32.3073],
            [-87.5245, 32.482],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102670',
        NAME: 'Perry County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1863919953,
        AWATER: 10918482,
        INTPTLAT: '+32.6390053',
        INTPTLON: '-087.2938269',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0602, 32.4918],
            [-85.0511, 32.5093],
            [-85.0015, 32.5147],
            [-85.0013, 32.513],
            [-85.0013, 32.5128],
            [-85.001, 32.5102],
            [-84.9632, 32.4234],
            [-85.0678, 32.3882],
            [-85.0502, 32.433],
            [-85.0474, 32.4778],
            [-85.06, 32.488],
            [-85.0721, 32.4726],
            [-85.0848, 32.4723],
            [-85.0602, 32.4918],
          ],
          [
            [-85.0201, 32.5098],
            [-85.0193, 32.5098],
            [-85.0187, 32.5098],
            [-85.0184, 32.5098],
            [-85.0184, 32.5106],
            [-85.0202, 32.5107],
            [-85.0201, 32.5098],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102700',
        NAME: 'Phenix City City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72271863,
        AWATER: 325619,
        INTPTLAT: '+32.4616695',
        INTPTLON: '-085.0250208',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3044, 33.2883],
            [-88.2746, 33.534],
            [-87.9465, 33.5241],
            [-87.8407, 33.5248],
            [-87.8375, 33.1536],
            [-87.9688, 33.0776],
            [-88.1719, 32.9959],
            [-88.3404, 32.9912],
            [-88.3044, 33.2883],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102730',
        NAME: 'Pickens County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2282893857,
        AWATER: 22521211,
        INTPTLAT: '+33.2967933',
        INTPTLON: '-088.0968631',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6542, 33.9634],
            [-85.3988, 33.9641],
            [-85.3866, 33.9017],
            [-85.381, 33.8735],
            [-85.637, 33.8753],
            [-85.6542, 33.9634],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102760',
        NAME: 'Piedmont City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231098249,
        AWATER: 972380,
        INTPTLAT: '+33.9180048',
        INTPTLON: '-085.5138154',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1914, 31.9665],
            [-85.9956, 31.9676],
            [-85.9969, 32.051],
            [-85.8937, 32.0474],
            [-85.8843, 31.9673],
            [-85.79, 31.9673],
            [-85.791, 31.8804],
            [-85.6577, 31.8803],
            [-85.6627, 31.7833],
            [-85.7483, 31.618],
            [-85.7891, 31.618],
            [-86.1459, 31.6177],
            [-86.1483, 31.791],
            [-86.1829, 31.8324],
            [-86.1914, 31.9665],
          ],
          [
            [-85.9565, 31.7642],
            [-85.9004, 31.7893],
            [-85.9604, 31.8293],
            [-86.02, 31.7639],
            [-85.9565, 31.7642],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102790',
        NAME: 'Pike County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1667656964,
        AWATER: 2137043,
        INTPTLAT: '+31.7274194',
        INTPTLON: '-085.7926447',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6435, 33.4959],
            [-85.3044, 33.4829],
            [-85.2938, 33.4281],
            [-85.2365, 33.1296],
            [-85.2324, 33.1081],
            [-85.3946, 33.1086],
            [-85.3452, 33.1824],
            [-85.4035, 33.1184],
            [-85.4118, 33.1087],
            [-85.5932, 33.1073],
            [-85.6537, 33.1066],
            [-85.6435, 33.4959],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102820',
        NAME: 'Randolph County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1455090025,
        AWATER: 8296503,
        INTPTLAT: '+33.2964753',
        INTPTLON: '-085.4640679',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4035, 33.1184],
            [-85.3452, 33.1824],
            [-85.3946, 33.1086],
            [-85.3984, 33.1086],
            [-85.4027, 33.1086],
            [-85.4118, 33.1087],
            [-85.4035, 33.1184],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102850',
        NAME: 'Roanoke City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48526075,
        AWATER: 988793,
        INTPTLAT: '+33.1453048',
        INTPTLON: '-085.3695264',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.434, 32.4098],
            [-85.3338, 32.4686],
            [-85.0938, 32.4721],
            [-85.0891, 32.4722],
            [-85.0848, 32.4723],
            [-85.0721, 32.4726],
            [-85.06, 32.488],
            [-85.0474, 32.4778],
            [-85.0502, 32.433],
            [-85.0678, 32.3882],
            [-84.9632, 32.4234],
            [-84.972, 32.3777],
            [-85.0028, 32.3224],
            [-84.8882, 32.2597],
            [-84.923, 32.2309],
            [-85.0621, 32.1325],
            [-85.056, 32.0631],
            [-85.1851, 32.0617],
            [-85.2577, 32.1483],
            [-85.4102, 32.1467],
            [-85.4335, 32.2346],
            [-85.434, 32.4098],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102880',
        NAME: 'Russell County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1598646192,
        AWATER: 15352289,
        INTPTLAT: '+32.2693878',
        INTPTLON: '-085.1900577',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7125, 34.4618],
            [-87.7589, 34.4846],
            [-87.7591, 34.5283],
            [-87.7065, 34.5201],
            [-87.7125, 34.4618],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102910',
        NAME: 'Russellville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34720031,
        AWATER: 275054,
        INTPTLAT: '+34.5057374',
        INTPTLON: '-087.7281916',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0281, 34.7115],
            [-85.9571, 34.6609],
            [-86.0658, 34.5691],
            [-86.1124, 34.683],
            [-86.0281, 34.7115],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102940',
        NAME: 'Scottsboro City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132452163,
        AWATER: 14564487,
        INTPTLAT: '+34.6446410',
        INTPTLON: '-086.0482045',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0908, 32.3954],
            [-87.0497, 32.4438],
            [-87.0187, 32.4048],
            [-87.0908, 32.3954],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '102970',
        NAME: 'Selma City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35765691,
        AWATER: 1537275,
        INTPTLAT: '+32.4170205',
        INTPTLON: '-087.0357918',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7411, 34.735],
            [-87.6746, 34.7595],
            [-87.6792, 34.745],
            [-87.7411, 34.735],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103000',
        NAME: 'Sheffield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16725183,
        AWATER: 223244,
        INTPTLAT: '+34.7554939',
        INTPTLON: '-087.7012648',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.7525, 33.4159],
              [-86.7524, 33.4165],
              [-86.7514, 33.4174],
              [-86.7508, 33.4174],
              [-86.7505, 33.4173],
              [-86.7277, 33.4165],
              [-86.7525, 33.4159],
            ],
          ],
          [
            [
              [-86.8127, 33.3636],
              [-86.7978, 33.3673],
              [-86.8133, 33.3631],
              [-86.8133, 33.3635],
              [-86.8127, 33.3636],
            ],
          ],
          [
            [
              [-86.8305, 33.3387],
              [-86.8305, 33.3426],
              [-86.8305, 33.3427],
              [-86.8258, 33.3482],
              [-86.8254, 33.3401],
              [-86.8292, 33.3395],
              [-86.8306, 33.3373],
              [-86.8305, 33.3387],
            ],
          ],
          [
            [
              [-86.8825, 33.328],
              [-86.8825, 33.3284],
              [-86.8751, 33.3326],
              [-86.8709, 33.3325],
              [-86.8825, 33.328],
            ],
          ],
          [
            [
              [-86.8965, 33.3151],
              [-86.8956, 33.3164],
              [-86.8954, 33.3114],
              [-86.9001, 33.3115],
              [-86.9001, 33.3135],
              [-86.9001, 33.3147],
              [-86.8965, 33.3151],
            ],
          ],
          [
            [
              [-87.0268, 33.2465],
              [-86.9262, 33.2892],
              [-86.922, 33.2965],
              [-86.9001, 33.3079],
              [-86.8485, 33.3322],
              [-86.8422, 33.3321],
              [-86.8394, 33.332],
              [-86.8351, 33.332],
              [-86.7785, 33.3711],
              [-86.7784, 33.3863],
              [-86.7784, 33.3869],
              [-86.7749, 33.3889],
              [-86.7721, 33.3889],
              [-86.7696, 33.3887],
              [-86.7047, 33.4257],
              [-86.7059, 33.4305],
              [-86.7047, 33.4304],
              [-86.6776, 33.4227],
              [-86.6606, 33.4091],
              [-86.6045, 33.4671],
              [-86.6046, 33.4742],
              [-86.5958, 33.4799],
              [-86.5957, 33.4815],
              [-86.5871, 33.4907],
              [-86.587, 33.4961],
              [-86.5741, 33.5033],
              [-86.5648, 33.5106],
              [-86.5606, 33.5175],
              [-86.5517, 33.5174],
              [-86.5433, 33.5211],
              [-86.508, 33.5461],
              [-86.4816, 33.5458],
              [-86.3782, 33.5024],
              [-86.3787, 33.391],
              [-86.3573, 33.2969],
              [-86.458, 33.2414],
              [-86.5031, 33.1791],
              [-86.491, 33.1029],
              [-86.5173, 33.0206],
              [-86.61, 33.07],
              [-86.8812, 33.0499],
              [-87.0256, 33.1658],
              [-87.0268, 33.2465],
            ],
            [
              [-86.8744, 33.2307],
              [-86.8176, 33.1995],
              [-86.7643, 33.1873],
              [-86.7821, 33.2075],
              [-86.8744, 33.2307],
            ],
          ],
          [
            [
              [-86.6264, 33.4668],
              [-86.6264, 33.4632],
              [-86.6305, 33.4668],
              [-86.6264, 33.4668],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103030',
        NAME: 'Shelby County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1905722115,
        AWATER: 56969641,
        INTPTLAT: '+33.2594558',
        INTPTLON: '-086.6494235',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3256, 33.9401],
            [-86.2155, 33.9874],
            [-86.0653, 33.8422],
            [-86.044, 33.7636],
            [-86.1456, 33.6791],
            [-86.205, 33.6827],
            [-86.3686, 33.6536],
            [-86.3838, 33.5991],
            [-86.4816, 33.5458],
            [-86.508, 33.5461],
            [-86.5126, 33.5576],
            [-86.5516, 33.5894],
            [-86.5515, 33.5944],
            [-86.5515, 33.6078],
            [-86.5342, 33.6344],
            [-86.5254, 33.6636],
            [-86.5778, 33.7653],
            [-86.406, 33.8359],
            [-86.3256, 33.9401],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103062',
        NAME: 'St. Clair County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1222264758,
        AWATER: 21621089,
        INTPTLAT: '+33.7699986',
        INTPTLON: '-086.3179840',
        ELSDLEA: '',
        UNSDLEA: '03062',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3888, 32.5781],
            [-88.3479, 32.9291],
            [-88.3404, 32.9912],
            [-88.1719, 32.9959],
            [-88.2073, 32.9248],
            [-88.1327, 32.8329],
            [-88.055, 32.6465],
            [-87.9287, 32.6323],
            [-87.8534, 32.5321],
            [-88.0316, 32.4336],
            [-88.0463, 32.3771],
            [-87.9307, 32.3106],
            [-88.4215, 32.3087],
            [-88.3888, 32.5781],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103090',
        NAME: 'Sumter County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2341060793,
        AWATER: 24432727,
        INTPTLAT: '+32.5974811',
        INTPTLON: '-088.2000571',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2594, 33.1965],
            [-86.2591, 33.211],
            [-86.2337, 33.1963],
            [-86.2404, 33.1425],
            [-86.2809, 33.164],
            [-86.2594, 33.1965],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103120',
        NAME: 'Sylacauga City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52449240,
        AWATER: 420768,
        INTPTLAT: '+33.1889191',
        INTPTLON: '-086.2747572',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.158, 33.4178],
            [-86.0763, 33.4732],
            [-86.0644, 33.3971],
            [-86.158, 33.4178],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103150',
        NAME: 'Talladega City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66056296,
        AWATER: 209972,
        INTPTLAT: '+33.4317569',
        INTPTLON: '-086.0994543',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3787, 33.391],
            [-86.1898, 33.5472],
            [-86.1695, 33.6192],
            [-86.205, 33.6827],
            [-86.1456, 33.6791],
            [-86.0507, 33.6746],
            [-86.0216, 33.6012],
            [-85.9518, 33.5858],
            [-85.9484, 33.5858],
            [-85.7961, 33.5562],
            [-85.8519, 33.4987],
            [-85.9049, 33.4987],
            [-85.9238, 33.3962],
            [-85.9803, 33.2942],
            [-86.1182, 33.2963],
            [-86.1206, 33.1945],
            [-86.1744, 33.1044],
            [-86.491, 33.1029],
            [-86.5031, 33.1791],
            [-86.458, 33.2414],
            [-86.3573, 33.2969],
            [-86.3787, 33.391],
          ],
          [
            [-86.2591, 33.211],
            [-86.2594, 33.1965],
            [-86.2809, 33.164],
            [-86.2404, 33.1425],
            [-86.2337, 33.1963],
            [-86.2591, 33.211],
          ],
          [
            [-86.158, 33.4178],
            [-86.0644, 33.3971],
            [-86.0763, 33.4732],
            [-86.158, 33.4178],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103180',
        NAME: 'Talladega County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1774112502,
        AWATER: 60115881,
        INTPTLAT: '+33.3693135',
        INTPTLON: '-086.1759302',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0092, 33.0903],
            [-85.9747, 33.1053],
            [-85.6537, 33.1066],
            [-85.5932, 33.1073],
            [-85.5932, 32.7285],
            [-85.6968, 32.6974],
            [-85.6959, 32.5959],
            [-85.7986, 32.5811],
            [-85.7972, 32.4942],
            [-85.8861, 32.4931],
            [-85.9077, 32.6987],
            [-85.8954, 32.7546],
            [-85.8559, 32.9784],
            [-86.0083, 32.9661],
            [-86.0092, 33.0903],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103210',
        NAME: 'Tallapoosa County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1600835051,
        AWATER: 84333875,
        INTPTLAT: '+32.8571811',
        INTPTLON: '-085.7736267',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9077, 32.6987],
            [-85.8861, 32.4931],
            [-85.9954, 32.4281],
            [-85.9902, 32.5372],
            [-85.9077, 32.6987],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103240',
        NAME: 'Tallassee City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245418825,
        AWATER: 12781921,
        INTPTLAT: '+32.5469786',
        INTPTLON: '-085.9427351',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7447, 33.6046],
            [-86.7478, 33.5802],
            [-86.7511, 33.5775],
            [-86.7882, 33.5749],
            [-86.7447, 33.6046],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103270',
        NAME: 'Tarrant City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16473719,
        AWATER: 82470,
        INTPTLAT: '+33.5964950',
        INTPTLON: '-086.7625215',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8169, 31.9905],
            [-87.6678, 31.9914],
            [-87.6668, 31.8757],
            [-87.6202, 31.8333],
            [-87.8173, 31.825],
            [-87.8169, 31.9905],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103300',
        NAME: 'Thomasville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274005646,
        AWATER: 117380,
        INTPTLAT: '+31.9042853',
        INTPTLON: '-087.7335788',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9565, 31.7642],
            [-86.02, 31.7639],
            [-85.9604, 31.8293],
            [-85.9004, 31.7893],
            [-85.9565, 31.7642],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103330',
        NAME: 'Troy City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73084247,
        AWATER: 199932,
        INTPTLAT: '+31.8019595',
        INTPTLON: '-085.9671296',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6238, 33.2164],
            [-87.4826, 33.2709],
            [-87.4815, 33.149],
            [-87.5604, 33.1532],
            [-87.6238, 33.2164],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103360',
        NAME: 'Tuscaloosa City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152327580,
        AWATER: 24748759,
        INTPTLAT: '+33.2331218',
        INTPTLON: '-087.5284927',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8407, 33.5248],
            [-87.6667, 33.5217],
            [-87.6317, 33.6098],
            [-87.4237, 33.6021],
            [-87.3185, 33.5874],
            [-87.2669, 33.5129],
            [-87.2844, 33.4406],
            [-87.1796, 33.3211],
            [-87.0657, 33.2469],
            [-87.1992, 33.1966],
            [-87.2819, 33.1331],
            [-87.3185, 33.0062],
            [-87.4219, 33.0034],
            [-87.7157, 33.0068],
            [-87.8322, 33.0173],
            [-87.8375, 33.1536],
            [-87.8407, 33.5248],
          ],
          [
            [-87.4815, 33.149],
            [-87.4826, 33.2709],
            [-87.6238, 33.2164],
            [-87.5604, 33.1532],
            [-87.4815, 33.149],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103390',
        NAME: 'Tuscaloosa County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3270792315,
        AWATER: 51855964,
        INTPTLAT: '+33.3214387',
        INTPTLON: '-087.4562830',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7411, 34.735],
            [-87.6792, 34.745],
            [-87.6785, 34.7227],
            [-87.6766, 34.7207],
            [-87.6748, 34.7188],
            [-87.6678, 34.7139],
            [-87.6678, 34.7133],
            [-87.667, 34.7116],
            [-87.6677, 34.7113],
            [-87.6666, 34.7053],
            [-87.6647, 34.7058],
            [-87.7023, 34.6902],
            [-87.7411, 34.735],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103420',
        NAME: 'Tuscumbia City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23880895,
        AWATER: 114785,
        INTPTLAT: '+34.7185022',
        INTPTLON: '-087.7025864',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.709, 33.4889],
              [-86.7001, 33.4891],
              [-86.6914, 33.4923],
              [-86.6792, 33.4993],
              [-86.6782, 33.4994],
              [-86.6749, 33.5032],
              [-86.674, 33.5023],
              [-86.6726, 33.5004],
              [-86.6612, 33.501],
              [-86.6523, 33.4854],
              [-86.6651, 33.4815],
              [-86.6653, 33.4774],
              [-86.6736, 33.4725],
              [-86.7092, 33.4522],
              [-86.7087, 33.4672],
              [-86.7053, 33.4673],
              [-86.7051, 33.4752],
              [-86.7059, 33.481],
              [-86.7084, 33.4828],
              [-86.7125, 33.4819],
              [-86.709, 33.4889],
            ],
          ],
          [
            [
              [-86.7103, 33.4822],
              [-86.7053, 33.4752],
              [-86.7088, 33.4676],
              [-86.7093, 33.4551],
              [-86.736, 33.4459],
              [-86.7441, 33.464],
              [-86.7173, 33.4711],
              [-86.7103, 33.4822],
            ],
          ],
          [
            [
              [-86.7376, 33.4288],
              [-86.7387, 33.4293],
              [-86.7358, 33.4308],
              [-86.7358, 33.4321],
              [-86.7307, 33.4346],
              [-86.7262, 33.4288],
              [-86.7261, 33.4287],
              [-86.7376, 33.4288],
            ],
          ],
          [
            [
              [-86.8159, 33.4231],
              [-86.8152, 33.4229],
              [-86.8159, 33.4224],
              [-86.815, 33.4226],
              [-86.8149, 33.4212],
              [-86.8151, 33.4213],
              [-86.8152, 33.4216],
              [-86.8166, 33.4215],
              [-86.8159, 33.4231],
            ],
          ],
          [
            [
              [-86.8197, 33.4151],
              [-86.8192, 33.4173],
              [-86.8168, 33.415],
              [-86.8103, 33.4196],
              [-86.8082, 33.4202],
              [-86.8081, 33.423],
              [-86.8127, 33.4226],
              [-86.8171, 33.4298],
              [-86.817, 33.43],
              [-86.8131, 33.4335],
              [-86.8137, 33.4413],
              [-86.8122, 33.443],
              [-86.8078, 33.444],
              [-86.8063, 33.4457],
              [-86.8054, 33.4472],
              [-86.7932, 33.4586],
              [-86.7919, 33.4584],
              [-86.7898, 33.4554],
              [-86.788, 33.456],
              [-86.7859, 33.4563],
              [-86.7853, 33.4571],
              [-86.785, 33.4575],
              [-86.7846, 33.4579],
              [-86.78, 33.4613],
              [-86.7712, 33.4613],
              [-86.7707, 33.4616],
              [-86.7703, 33.4661],
              [-86.7631, 33.4679],
              [-86.7579, 33.4615],
              [-86.7528, 33.4462],
              [-86.7488, 33.4433],
              [-86.7501, 33.442],
              [-86.7475, 33.4415],
              [-86.7403, 33.4455],
              [-86.7403, 33.4384],
              [-86.7448, 33.4402],
              [-86.753, 33.4299],
              [-86.7645, 33.4092],
              [-86.7651, 33.4098],
              [-86.7712, 33.4155],
              [-86.7721, 33.4152],
              [-86.7741, 33.4138],
              [-86.7744, 33.4136],
              [-86.7753, 33.4134],
              [-86.7756, 33.4131],
              [-86.7785, 33.4111],
              [-86.7811, 33.4099],
              [-86.7825, 33.4056],
              [-86.7828, 33.4049],
              [-86.784, 33.4051],
              [-86.7901, 33.406],
              [-86.7903, 33.4063],
              [-86.7903, 33.4064],
              [-86.7914, 33.407],
              [-86.8031, 33.4107],
              [-86.8278, 33.4064],
              [-86.8283, 33.4063],
              [-86.8197, 33.4151],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103430',
        NAME: 'Vestavia Hills City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50877947,
        AWATER: 577755,
        INTPTLAT: '+33.4624959',
        INTPTLON: '-086.7395079',
        ELSDLEA: '',
        UNSDLEA: '03430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6361, 34.0022],
            [-87.151, 33.9932],
            [-87.0856, 33.9192],
            [-86.9634, 33.8582],
            [-86.9537, 33.8153],
            [-87.0233, 33.7727],
            [-87.0398, 33.6854],
            [-87.0927, 33.6347],
            [-87.0927, 33.6278],
            [-87.1794, 33.6138],
            [-87.2669, 33.5129],
            [-87.3185, 33.5874],
            [-87.4237, 33.6021],
            [-87.4238, 33.6891],
            [-87.5283, 33.692],
            [-87.5316, 33.8676],
            [-87.6359, 33.9153],
            [-87.6359, 33.9294],
            [-87.636, 33.9322],
            [-87.6361, 34.0022],
          ],
          [
            [-87.2486, 33.7996],
            [-87.2583, 33.8891],
            [-87.3134, 33.8824],
            [-87.3057, 33.8585],
            [-87.3202, 33.8554],
            [-87.3309, 33.8548],
            [-87.3273, 33.8697],
            [-87.3339, 33.859],
            [-87.3576, 33.8592],
            [-87.3529, 33.8525],
            [-87.3129, 33.8538],
            [-87.3046, 33.8511],
            [-87.3038, 33.8168],
            [-87.2486, 33.7996],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103450',
        NAME: 'Walker County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1975297936,
        AWATER: 36035719,
        INTPTLAT: '+33.7915581',
        INTPTLON: '-087.3010936',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4644, 31.698],
            [-88.0883, 31.6993],
            [-88.0722, 31.5945],
            [-87.9074, 31.4944],
            [-87.8889, 31.364],
            [-87.9508, 31.2995],
            [-87.9466, 31.1929],
            [-87.9729, 31.1627],
            [-88.0226, 31.1443],
            [-88.3298, 31.1438],
            [-88.432, 31.1143],
            [-88.4494, 31.4358],
            [-88.4644, 31.698],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103480',
        NAME: 'Washington County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2797722220,
        AWATER: 21650835,
        INTPTLAT: '+31.4052172',
        INTPTLON: '-088.1975644',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4722, 32.2649],
            [-87.3256, 32.1963],
            [-87.1781, 32.0475],
            [-86.907, 32.048],
            [-86.8576, 31.9622],
            [-86.9089, 31.9617],
            [-86.9069, 31.8306],
            [-87.5009, 31.8293],
            [-87.6202, 31.8333],
            [-87.6668, 31.8757],
            [-87.6678, 31.9914],
            [-87.6228, 32.0055],
            [-87.624, 32.1323],
            [-87.5219, 32.1328],
            [-87.5234, 32.2548],
            [-87.4722, 32.2649],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103510',
        NAME: 'Wilcox County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2301199922,
        AWATER: 48985143,
        INTPTLAT: '+31.9900845',
        INTPTLON: '-087.3049075',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9147, 33.9194],
            [-87.8522, 33.986],
            [-87.636, 33.9322],
            [-87.6359, 33.9294],
            [-87.736, 33.9148],
            [-87.804, 33.841],
            [-87.8964, 33.8531],
            [-87.9147, 33.9194],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103540',
        NAME: 'Winfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168111696,
        AWATER: 307602,
        INTPTLAT: '+33.9195334',
        INTPTLON: '-087.8090327',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6359, 34.1424],
            [-87.512, 34.1667],
            [-87.5169, 34.2609],
            [-87.5733, 34.3053],
            [-87.5297, 34.3046],
            [-87.1099, 34.2993],
            [-87.112, 33.9924],
            [-87.151, 33.9932],
            [-87.6361, 34.0022],
            [-87.6359, 34.1424],
          ],
        ],
      },
      properties: {
        STATEFP: '01',
        SCSDLEA: '',
        GEOID: '103580',
        NAME: 'Winston County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1402122102,
        AWATER: 48053502,
        INTPTLAT: '+34.1479268',
        INTPTLON: '-087.3341546',
        ELSDLEA: '',
        UNSDLEA: '03580',
      },
    },
  ],
};

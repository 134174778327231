/* eslint-disable consistent-return */
import axios from 'axios';
import { API_ROOT } from '../config/appConfig';

export const exportApi = async (format, exportItems, jwt) => {
  try {
    const res = await axios.post(`${API_ROOT}/api/export/${format}`, exportItems, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    console.error(err);
  }
};

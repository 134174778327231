/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { calculateChartHeight, makeMLITooltip, regroupForSplitBar } from '../../../helpers/charts';
import { HIGHCHARTS_TYPES, HIGHCHARTS_TOOLTIP_DELAY } from '../../../constants/charts';

const BarChartUneven = forwardRef(
  ({ parsedData, benchmarks = {}, chartLoaded, currentJob }, chartRef) => {
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);
    /**
     * Ensures that the given array of series objects contains
     * values that add up to 100%, filling in holes with invisible
     * series when necessary.
     *
     * @param {Array} series Highcharts series data
     */
    const fillPercentages = (series) => {
      const total = series.reduce((a, b) => a + b);

      if (total < 100) {
        // Add invisible bar to appropriately size other bars
        series.push({
          data: [100 - total],
          showInLegend: false,
          visible: false,
        });
      }
      return series;
    };

    function tooltipFormatter() {
      let text_color;
      let text;
      let percent_diff;
      let benchmark_label = this.series.userOptions.benchmark_labels[this.point.x];
      let nat_category = false;
      const benchmark = this.series.userOptions.benchmark_array[this.point.x];
      const this_category = this.point.category;
      const { filtered, segmented, benchmarked } = this.series.userOptions;
      const moe = this.series.userOptions.MOE[this.point.x];

      if (segmented) {
        if (benchmarked) {
          if (this_category.includes('National')) {
            nat_category = true;
          } else if (!this_category.includes('State') && !filtered) {
            nat_category = true;
          }
        } else if (this_category.includes('National')) {
          nat_category = true;
        } else if (this_category.includes('All respondents') && !filtered) {
          nat_category = true;
        }
      } else if (!filtered) {
        nat_category = true;
      }

      if (benchmark !== undefined && benchmark !== null && !nat_category) {
        percent_diff = Math.round(Math.abs(this.y) - benchmark);

        if (percent_diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          percent_diff = Math.abs(percent_diff);
        }
      } else {
        percent_diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
        benchmark_label = '';
      }

      return makeMLITooltip(
        this.series.name,
        Math.abs(this.y),
        percent_diff,
        moe,
        text,
        benchmark_label,
        this.series.color,
        text_color,
        false
      );
    }

    function seriesFormatter() {
      const label = Math.abs(this.y) > 0 ? `${Math.abs(this.y)}%` : '';
      return `<div>${label}</div>`;
    }

    const getTextHeight = (offset_width, font_size, seg_text, font_constant = 2.7) => {
      const line_height = 18;
      const num_chars_per_line = offset_width / (font_size * font_constant);
      const num_lines = Math.ceil(seg_text.length / num_chars_per_line);
      const seg_text_height = num_lines * line_height;

      return seg_text_height;
    };

    const getSegmentTextStart = (seg_text, font_size, offset_height, offset_width) => {
      const seg_text_height = getTextHeight(offset_width, font_size, seg_text);
      const text_start_height = Math.floor((offset_height - seg_text_height) / 2);

      return text_start_height;
    };
    const valueLabelSuffix = useMemo(() => {
      if (currentJob?.questionType === 'OPEN_NUMERIC') {
        return '';
      }
      return parsedData.valueLabelSuffix !== undefined && parsedData.valueLabelSuffix !== null
        ? parsedData.valueLabelSuffix
        : '%';
    }, [parsedData, currentJob]);

    const buildChart = (segment_id) => {
      // Build the chart
      let all_data;
      let series_data;
      let xaxis_categories;
      let margin_top = 50;
      let margin_left = 10;
      let spacing_left;
      let showLegend = true;

      let benchmarks_active = false;

      if (parsedData.segmentsActive && (benchmarks.state || benchmarks.national)) {
        benchmarks_active = true;
      }

      if (!benchmarks_active && parsedData.segmentsActive) {
        margin_left = 200;
        spacing_left = 130;
      } else {
        margin_left = 240;
      }

      // If it's segmented chart get segmented series
      if (parsedData.segmentsActive) {
        all_data = parsedData.segmentedSeries[0][segment_id];
        xaxis_categories = parsedData.segmentedSeries[1][segment_id];

        if (segment_id !== 0) {
          margin_top = 0;
          showLegend = false;
        }

        if (valueLabelSuffix === '%' && all_data?.length && all_data?.length > 1) {
          // make sure percentages sum is 100 in the one and only bar when showing percentage values
          let total = 0;
          for (let si = 0; si < all_data.length; si += 1) {
            // si: series index
            total += all_data[si].data[segment_id];
          }
          if (total === 101 || total === 102) {
            let newSi = 0;
            while (
              newSi < all_data.length &&
              (all_data[newSi].data[segment_id] === 0 || all_data[newSi].data[segment_id] === 1)
            ) {
              newSi += 1;
            }
            if (newSi < all_data.length && all_data[newSi].data[segment_id] > 1) {
              all_data[newSi].data[segment_id] -= 1;
              if (total === 102) {
                all_data[newSi].data[segment_id] -= 1;
              }
            }
          } else if (total === 97 || total === 98 || total === 99) {
            all_data[0].data[segment_id] += 100 - total;
          }
        }
        series_data = regroupForSplitBar(all_data);

        // Otherwise get just the parsed data for non-benchmarked data
      } else {
        [all_data] = parsedData.dataSeries;
        xaxis_categories = parsedData.categories;

        if (valueLabelSuffix === '%' && all_data?.length && all_data?.length > 1) {
          // make sure percentages sum is 100 in the one and only bar when showing percentage values
          let total = 0;
          for (let si = 0; si < all_data.length; si += 1) {
            // si: series index
            total += all_data[si].data[0];
          }
          if (total === 101 || total === 102) {
            let newSi = 0;
            while (
              newSi < all_data.length &&
              (all_data[newSi].data[0] === 0 || all_data[newSi].data[0] === 1)
            ) {
              newSi += 1;
            }
            if (newSi < all_data.length && all_data[newSi].data[0] > 1) {
              all_data[newSi].data[0] -= 1;
              if (total === 102) {
                all_data[newSi].data[0] -= 1;
              }
            }
          } else if (total === 97 || total === 98 || total === 99) {
            all_data[0].data[0] += 100 - total;
          }
        }
        series_data = regroupForSplitBar(all_data);
      }
      const maxCatSize = Math.max(...xaxis_categories.map((x) => x.length));

      let chartHeight = calculateChartHeight(xaxis_categories.length);

      if (chartHeight < 215 && maxCatSize > 90) {
        chartHeight = 400;
      } else if (segment_id === 0) {
        chartHeight += 100;
      } else if (chartHeight < 115) {
        chartHeight = 400;
      }

      // This is a default theme for the MLI bar chart
      const pos_neg_default_theme = {
        chart: {
          type: HIGHCHARTS_TYPES.BAR,
          ignoreHiddenSeries: false,
          marginTop: margin_top,
          paddingTop: 0,
          paddingLeft: 20,
          height: chartHeight < 400 ? 400 : chartHeight,
          width: 750,

          // backgroundColor option is static
          backgroundColor: null,
          marginLeft: margin_left + 10,
          spacingLeft: spacing_left,

          // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
          events: {
            load() {
              const isBenchmarkActive = benchmarks.state || benchmarks.national;
              if (!isBenchmarkActive && typeof segment_id !== 'undefined' && segment_id !== null) {
                const seg_text = parsedData.categories[segment_id];
                const font_size = 14;
                const text_start_height = getSegmentTextStart(
                  seg_text,
                  font_size,
                  this.container.offsetHeight,
                  this.container.offsetWidth
                );
                const path_length = this.container.offsetHeight;
                this.renderer
                  .label(seg_text, 0, text_start_height, undefined, undefined, undefined, true)
                  .css({
                    width: '100px',
                    textAlign: 'end',
                    color: '#121111',
                    fontSize: `${font_size}px`,
                  })
                  .add();

                const linePosition = 115;
                // Draw a line at the edge of margin
                this.renderer
                  .path(['M', linePosition, 5, 'L', linePosition, path_length])
                  .attr({
                    'stroke-width': 0.6,
                    stroke: '#121111',
                  })
                  .add();
              }
            },
          },
        },

        // title formatting options will be static
        title: {
          text: null,
        },
        credits: { enabled: false },

        // xAxis formatting will be static
        xAxis: {
          title: {
            enabled: false,
            text: '',
            style: {
              color: '#121111',
              fontSize: '14px',
            },
          },
          categories: xaxis_categories,
          labels: {
            style: {
              color: '#121111',
              fontSize: '14px',
            },
            align: 'right',
            x: -10,
            y: null,
          },
          gridLineColor: '#b8b4b4',
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
        },

        // yAxis formatting will be static
        yAxis: {
          gridLineColor: '#f7f7f7',
          labels: {
            enabled: false,
          },
          lineColor: '#f7f7f7',
          minorGridLineColor: '#f7f7f7',
          tickColor: '#f7f7f7',
          tickWidth: 1,
          reversedStacks: false,
          tickInterval: 5,
          title: {
            enabled: false,
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: '#b8b4b4',
            },
          ],
        },
        plotOptions: {
          bar: {
            events: {
              legendItemClick: () => {
                return false;
              },
              animation: false,
            },
          },
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              formatter: seriesFormatter,
              padding: 0,
              rotation: 0,
              shadow: false,
              style: {
                textOutline: false,
                fontSize: '14px',
              },
              shape: 'square',
              useHTML: false,
            },
            marker: {
              lineColor: '#b8b4b4',
            },
          },
        },
        tooltip: {
          hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
          backgroundColor: 'rgba(255,255,255,1)',
          style: {
            color: '#343434',
            fontWeight: 'normal',
          },
          useHTML: true,
          padding: 0,
          formatter: tooltipFormatter,
        },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        legend: {
          enabled: showLegend,
          layout: 'horizontal',
          x: margin_left - spacing_left + 10,
          y: -10,
          margin: 0,
          padding: 0,
          symbolRadius: 0,
          itemStyle: {
            fontSize: '14px',
            color: '#606060',
            textOverflow: 'wrap',
          },
          paddingRight: 10,
          maxHeight: 85,
          width: 750,
          navigation: {
            activeColor: '#ac193c',
          },
          itemHoverStyle: {
            color: '#606060',
          },
          itemHiddenStyle: {
            color: '#606060',
          },
          itemDistance: 25,
          align: 'left',
          verticalAlign: 'top',
          fontSize: '14px',
        },
      };

      const neutral_default_theme = {
        chart: {
          events: {
            load() {
              chartLoaded();
            },
          },
          type: HIGHCHARTS_TYPES.BAR,
          ignoreHiddenSeries: false,
          marginTop: margin_top,
          paddingTop: 0,
          paddingLeft: 20,
          height: chartHeight < 400 ? 400 : chartHeight,
          width: 250,

          // backgroundColor option is static
          backgroundColor: null,
          marginLeft: 10,

          // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
        },

        // title formatting options will be static
        title: {
          text: null,
        },
        credits: { enabled: false },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },

        // xAxis formatting will be static
        xAxis: {
          labels: {
            enabled: false,
          },
          categories: xaxis_categories,
          min: 0,
          gridLineWidth: 0,
          tickWidth: 0,
          gridLineColor: '#b8b4b4',
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          title: {
            style: {
              color: '#121111',
              fontSize: '14px',
            },
          },
        },

        // yAxis formatting will be static
        yAxis: {
          gridLineColor: '#f7f7f7',
          labels: {
            enabled: false,
          },
          lineColor: '#f7f7f7',
          minorGridLineColor: '#f7f7f7',
          tickColor: '#f7f7f7',
          tickWidth: 1,
          reversedStacks: false,
          tickInterval: 5,
          title: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            events: {
              legendItemClick: () => {
                return false;
              },
              animation: false,
            },
          },
          series: {
            stacking: 'normal',
            dataLabels: {
              color: '#121111',
              enabled: true,
              formatter: seriesFormatter,
              padding: 0,
              rotation: 0,
              shadow: false,
              style: {
                textOutline: false,
                fontSize: '14px',
              },
              shape: 'square',
              useHTML: false,
            },
            marker: {
              lineColor: '#b8b4b4',
            },
          },
        },
        tooltip: {
          hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
          backgroundColor: 'rgba(255,255,255,1)',
          style: {
            color: '#343434',
            fontWeight: 'normal',
          },
          useHTML: true,
          padding: 0,
          formatter: tooltipFormatter,
        },
        legend: {
          enabled: showLegend,
          layout: 'horizontal',
          x: 0,
          y: -10,
          margin: 0,
          padding: 0,
          symbolRadius: 0,
          itemStyle: {
            fontSize: '14px',
            color: '#606060',
            textOverflow: 'wrap',
          },
          paddingRight: 10,
          maxHeight: 85,
          width: 200,
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          itemHoverStyle: {
            color: '#606060',
          },
          itemHiddenStyle: {
            color: '#606060',
          },
          itemDistance: 10,
          align: 'left',
          verticalAlign: 'top',
          fontSize: '14px',
        },
      };

      // Construct options for chart
      const multiChart = [];

      // Ensure series adds to 100%
      series_data[0] = fillPercentages([...series_data[0]]);

      multiChart.push({
        ...pos_neg_default_theme,
        series: series_data[0],
        segment_id,
      });

      if (series_data[1] !== null) {
        // Ensure neutral series adds to 100%
        series_data[1] = fillPercentages([...series_data[1]]);

        multiChart.push({
          ...neutral_default_theme,
          series: series_data[1],
          segment_id,
        });
      }
      return multiChart;
    };

    useEffect(() => {
      const chartList = [];
      if (parsedData.segmentsActive) {
        for (let i = 0; i < parsedData.categories.length; i += 1) {
          if (
            !parsedData.suppressed ||
            parsedData.suppressed === null ||
            !parsedData.suppressed[i]
          ) {
            const c = buildChart(i);
            chartList.push(c);
          } else {
            chartList.push({ suppressed: true });
          }
        }
      } else if (
        !parsedData.suppressed ||
        parsedData.suppressed === null ||
        !parsedData.suppressed[0]
      ) {
        const c = buildChart();
        chartList.push(c);
      } else {
        chartList.push({ suppressed: true });
        chartLoaded();
      }
      setChartOptions(chartList);
      setLoadingChart(false);
    }, [parsedData]);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <>
        <div className="d-flex flex-wrap">
          {chartOptions.map((co) => {
            if (co.suppressed) {
              return (
                <Alert className="m-3" variant="warning" key={shortid.generate()}>
                  <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
                  visualization suppressed
                </Alert>
              );
            }
            return (
              <div key={shortid.generate()} className="d-flex">
                {co.map((c) => (
                  <HighchartsReact
                    key={shortid.generate()}
                    highcharts={Highcharts}
                    options={c}
                    ref={chartRef}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </>
    );
  }
);

BarChartUneven.displayName = 'BarChartUneven';

export default React.memo(BarChartUneven);

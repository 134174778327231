import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const SimpleText = ({
  text,
  max,
  align,
  padding,
}) => (
  <p className="SimpleText" style={{ maxWidth: max, textAlign: align, padding }}>
    { text.map((el) => <span key={Math.random()}>{ el }</span>) }
  </p>
);

SimpleText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  max: PropTypes.number.isRequired,
  align: PropTypes.string.isRequired,
  padding: PropTypes.string,
};

SimpleText.defaultProps = {
  padding: null,
};

export default SimpleText;

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { updateSurveyTitle } from '../../actions/survey.action';

const CardEditableTitle = ({ id, data }) => {
  const { createdAt, isPublished, title } = data;

  const titleInput = useRef(null);
  const dispatch = useDispatch();

  const jwt = useSelector((state) => state.session.jwt);

  const [isEditable, setIsEditable] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  useEffect(() => {
    if (isEditable) {
      titleInput.current.focus();
    }
  }, [isEditable]);

  const isEditableHandler = () => {
    setIsEditable(true);
  };

  const updateTitleHandler = () => {
    if (newTitle.length === 0 || !newTitle.trim()) {
      setNewTitle(title);
      setIsEditable(false);
      return;
    }
    setIsEditable(false);
    dispatch(updateSurveyTitle(id, newTitle, jwt));
  };

  const onChangeTitleHandler = (e) => {
    let { value } = e.target;
    // Remove White spaces
    value = value.replace(/\s\s+/g, ' ');
    setNewTitle(value);
  };

  return (
    <div className="Card-title-container">
      {!isEditable && (
        <div className="Card-title">
          {newTitle || ` Utitled Survey ${moment(createdAt).utc().format('LLL', 'YYYY-MM-DD')}`}
        </div>
      )}
      {isEditable && (
        <form className="Card-form">
          <textarea
            type="text"
            value={newTitle}
            onBlur={updateTitleHandler}
            onChange={onChangeTitleHandler}
            ref={titleInput}
            className="Card-input"
            maxLength="70"
          />
        </form>
      )}
      {!isPublished && (
        <div className="Card-title-edit">
          <button type="button" className="Card-edit-icon" onClick={isEditableHandler}>
            <img src="/edit-icon.svg" alt="Edit Icon" />
          </button>
        </div>
      )}
    </div>
  );
};

CardEditableTitle.propTypes = {
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    isPublished: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  id: PropTypes.number.isRequired,
};

export default CardEditableTitle;

import types from '../types';

const initialState = {
  modalProps: {
    open: false,
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SHOW_MODAL: {
      return {
        ...state,
        modalProps: { ...action.modalProps, open: true },
      };
    }

    case types.reducerTypes.HIDE_MODAL: {
      return {
        ...state,
        modalProps: {
          open: false,
        },
      };
    }
    default:
  }
  return state;
};

export default modalReducer;

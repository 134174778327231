/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import _SortBy from 'lodash/sortBy';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Alert,
  Container,
  Col,
  Row,
  Spinner,
  Form,
  InputGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import shortid from 'shortid';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faExternalLinkAlt,
  faSearchMinus,
  faSearchPlus,
  faEyeSlash,
  faFileAlt,
  faFilter,
  faSearch,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import { regroupForMap, setLocationData, getDataSetLabel } from '../../../helpers/charts';
import { HIGHCHARTS_TYPES } from '../../../constants/charts';
import { GEO_OBJ } from '../../../assets/geo';
import { leas } from '../../../assets/leas';

require('highcharts/modules/map')(Highcharts);

const NO_DISTRICT_TOGGLE_STATES = ['DC', 'HI'];

const MapChart = forwardRef(
  (
    {
      componentConfig,
      visualizationDetails,
      handleSectionClick,
      onHideChart,
      onAddToReport,
      menuOptions,
      handleUpdateDataSet,
      onExportChart,
      readOnly,
    },
    ref
  ) => {
    const numFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    });
    const zoomLevels = [1, 0.7, 0.4, 0.1];
    const zoomLevelsTexts = ['100%', '150%', '170%', '200%'];
    const [currentZoomText, setCurrentZoomText] = useState('100%');
    const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);
    const [shapeSelectedId, setShapeSelectedId] = useState();
    const [searchFilterType, setSearchFilterType] = useState('US');
    const [chartRef, setChartRef] = useState();

    const visualizationComponent = useMemo(
      () => visualizationDetails.components.find((c) => c.id === componentConfig.componentId),
      [visualizationDetails, componentConfig]
    );

    const onDatasetChange = (dataSetLvl1, dataSetLvl2, dataSetLvl3, dataSetLvl4) => {
      const newFilters = [];
      if (dataSetLvl1) {
        newFilters.push({ ...dataSetLvl1, children: [] });
      }
      if (dataSetLvl2) {
        newFilters.push({ ...dataSetLvl2, children: [] });
      }
      if (dataSetLvl3) {
        newFilters.push({ ...dataSetLvl3, children: [] });
      }
      if (dataSetLvl4) {
        newFilters.push({ ...dataSetLvl4, children: [] });
      }
      visualizationComponent.datasetFilter.filter.constraints[0].value = newFilters;
      setLoadingChart(true);
      handleUpdateDataSet(visualizationComponent);
    };

    function tooltipFormatter() {
      const dataLabel = getDataSetLabel(visualizationComponent?.datasetFilter);

      return `<div style="opacity: 0.99"; class="tooltip-wrapper"><h3 style="margin-bottom: 6px; text-align: left;">
      ${this.point.name.toUpperCase()}</h3><table border="0" cellspacing="0" cellpadding="10"><tr><td style="padding: 0px 8px; text-align: center; font-size: 16px;">
      (${
        this.point.ranking || 'NA'
      })</td><td style="padding: 0px 8px; border: solid 0 #c5c5c5; border-left-width: 1.5px; text-align: center; white-space: nowrap; font-size: 16px;">
      ${dataLabel.prefix} (${
        (Number.isNaN(Number(this.point.label))
          ? this.point.label
          : numFormatter.format(this.point.label)) || 'NA'
      }) ${dataLabel.postfix}
      </td></tr><tr><td style="padding: 0px 8px; text-align: center; font-size: 14px;">Ranking</td><td style="padding: 0px 8px; border: solid 0 #c5c5c5; border-left-width: 1.5px; text-align: center; white-space: nowrap; font-size: 14px;">
      (${dataLabel.data_label || 'NA'})</td></tr></table></div>`;
    }

    function fillMissingMapDatapoints(geo_data, map_data, join_by) {
      // find all geo_data shapes which have no corresponding data point
      const missing = geo_data?.features?.filter((shape) => {
        const value = shape.properties[join_by[0]];
        if (value === undefined) return false;

        return map_data.find((md) => md[join_by[1]].toString() === value.toString()) === undefined;
      });

      if (missing) {
        // Mock up a data point for each missing shape
        missing.forEach((el) => {
          const hole = {
            id: el.properties[join_by[0]], // all series need to have an id so they can be retrieved by state name / lea id with Chart.get()
            name: 'NO DATA AVAILABLE',
            color: '#c0c0c0',
          };

          hole[join_by[1]] = el.properties[join_by[0]];

          map_data.push(hole);
        });
      }
      return map_data;
    }

    const handleChartLoaded = (chart) => setChartRef(chart);

    const handleChangeShapeId = (newShapeId, itemData, leaid) => {
      const locationData = setLocationData(visualizationDetails, visualizationComponent.viewMode);
      if (
        visualizationDetails?.state?.abbreviation === newShapeId ||
        visualizationDetails?.state?.district?.leaId === leaid
      ) {
        if (visualizationDetails?.state?.abbreviation === newShapeId) {
          handleSectionClick(null);
        } else if (visualizationDetails?.state?.district?.leaId === leaid) {
          handleSectionClick({
            ...visualizationDetails?.state,
            district: null,
          });
        }
      } else {
        setShapeSelectedId(newShapeId);
        if (componentConfig?.queryResult?.stateMapData) {
          handleSectionClick({
            abbreviation: itemData.code,
            name: itemData.name,
            // geoid,
            // leaid,
          });
        } else {
          handleSectionClick({
            abbreviation: locationData.state_abbr,
            name: locationData.state_name,
            district: {
              abbreviation: itemData.name,
              name: itemData.name,
              geoId: 0,
              id: 0,
              leaId: leaid,
              showStateRanking: false,
            },
          });
        }
      }
    };

    function handleClick() {
      if (readOnly) return;
      const is_state = this.geoid === undefined || this.geoid === null;

      let geoid;
      if (typeof this.geoid === 'string') {
        // geo-id might be a string when retrieved from geojson for districts without data
        geoid = parseInt(this.geoid, 10);
      } else {
        geoid = this.geoid;
      }

      const leaid = this.leaid ? this.leaid : geoid; // fallback to geoid if leaid is not present
      const newShapeId = is_state ? this.code : leaid.toString();
      handleChangeShapeId(newShapeId, this, leaid);
    }

    useEffect(() => {
      if (!componentConfig?.queryResult?.suppressed && !visualizationComponent.hidden) {
        const isStateMode =
          visualizationComponent.viewMode === 'STATE' && componentConfig?.queryResult?.stateMapData;
        const allData = regroupForMap(
          isStateMode
            ? componentConfig?.queryResult?.stateMapData
            : componentConfig?.queryResult?.districtMapData,
          visualizationComponent.viewMode,
          visualizationDetails?.scheme?.index
        );

        const locationData = setLocationData(visualizationDetails, visualizationComponent.viewMode);
        const prevParseData = allData[0];
        const geoData = GEO_OBJ[locationData.state_abbr];
        let join_by = [];

        if (
          locationData.name === 'National' ||
          locationData.name === undefined ||
          locationData.name === null
        ) {
          join_by = ['postal-code', 'code'];
        } else if (locationData.abbr.length === 2 && locationData.state_abbr === 'US') {
          join_by = ['postal-code', 'code'];
          if (shapeSelectedId !== locationData.abbr) {
            setShapeSelectedId(locationData.abbr);
          }
        } else if (locationData.abbr.length === 2 && locationData.state_abbr !== 'US') {
          join_by = ['GEOID', 'geoid'];
        } else {
          if (shapeSelectedId !== visualizationDetails?.state?.district?.leaId?.toString()) {
            setShapeSelectedId(visualizationDetails?.state?.district?.leaId?.toString());
          }
          join_by = ['GEOID', 'geoid'];
        }

        if (visualizationComponent.viewMode === 'DISTRICT') {
          setSearchFilterType(locationData.state_abbr);
        }
        // Detect and fill map holes
        const parseData = fillMissingMapDatapoints(geoData, prevParseData, join_by);
        const default_theme = {
          chart: {
            type: HIGHCHARTS_TYPES.MAP,
            // backgroundColor option is static
            backgroundColor: null,
            height: 350,

            // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
            style: {
              fontFamily: 'AvenirNext-Regular',
            },

            marginBottom: 20,
          },
          legend: {
            enabled: false,
          },
          title: {
            text: null,
          },
          credits: { enabled: false },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          rangeSelector: { selected: 0.1 },
          allowPointSelect: true,
          series: [
            {
              point: {
                events: {
                  click: handleClick,
                },
              },
              mapData: geoData,
              data: parseData,
              joinBy: join_by,
              borderColor: '#f7f7f7',
              borderWidth: 0.5,
              states: {
                hover: {
                  brightness: 0.15,
                },
                select: {
                  color: '#F27B98',
                },
              },
            },
          ],
          tooltip: {
            backgroundColor: 'rgba(255,255,255, 0.98)',
            shape: 'callout',
            useHTML: true,
            formatter: tooltipFormatter,
          },
        };
        setChartOptions(default_theme);
      } else if (!visualizationComponent.hidden) {
        setChartOptions({ suppressed: true });
      }
      setLoadingChart(false);
    }, [componentConfig, visualizationDetails, visualizationComponent]);

    useEffect(() => {
      if (chartRef) {
        try {
          const itemInChart = chartRef.get(shapeSelectedId);
          if (itemInChart && itemInChart.select) {
            itemInChart.select();
          }
        } catch (error) {
          console.error(error);
        }
      }
    }, [chartRef, shapeSelectedId]);

    useEffect(() => {
      if (chartRef) {
        const selectedPoints = chartRef.getSelectedPoints()[0];

        chartRef.mapZoom();
        if (selectedPoints) {
          // eslint-disable-next-line no-underscore-dangle
          chartRef.mapZoom(currentZoomLevel, selectedPoints._midX, selectedPoints._midY);
        } else {
          chartRef.mapZoom(currentZoomLevel);
        }
      }
    }, [currentZoomLevel]);

    const handleZoomOut = () => {
      const currInx = zoomLevels.findIndex((zl) => zl === currentZoomLevel);
      const newIndex = currInx - 1;
      if (zoomLevels[newIndex]) {
        setCurrentZoomLevel(zoomLevels[newIndex]);
        setCurrentZoomText(zoomLevelsTexts[newIndex]);
      }
    };

    const handleZoomIn = () => {
      const currInx = zoomLevels.findIndex((zl) => zl === currentZoomLevel);
      const newIndex = currInx + 1;
      if (zoomLevels[newIndex]) {
        setCurrentZoomLevel(zoomLevels[newIndex]);
        setCurrentZoomText(zoomLevelsTexts[newIndex]);
      }
    };

    const handleSearchChange = (selectedOptions) => {
      if (shapeSelectedId === selectedOptions.value) {
        try {
          const itemInChart = chartRef.get(shapeSelectedId);
          if (itemInChart && itemInChart.select) {
            itemInChart.select();
          }
        } catch (error) {
          console.error(error);
        }
        handleSectionClick({});
      } else {
        const locationData = setLocationData(visualizationDetails, visualizationComponent.viewMode);
        if (componentConfig?.queryResult?.stateMapData) {
          handleSectionClick({
            abbreviation: selectedOptions.value,
            name: selectedOptions.label,
          });
        } else {
          handleSectionClick({
            abbreviation: locationData.state_abbr,
            name: locationData.state_name,
            district: {
              abbreviation: selectedOptions.label,
              name: selectedOptions.label,
              geoId: 0,
              id: 0,
              leaId: Number(selectedOptions.value),
              showStateRanking: false,
            },
          });
        }
      }
    };

    const onStateDistrictToggleClick = () => {
      const newVisualizationComponent = { ...visualizationComponent };
      newVisualizationComponent.viewMode = componentConfig?.queryResult?.stateMapData
        ? 'DISTRICT'
        : 'STATE';
      const newVisualization = { ...visualizationDetails };
      newVisualization.state.district = null;
      newVisualization.components[0].benchmarks.state = false;
      handleUpdateDataSet(newVisualizationComponent, false);
    };

    const getSearchOptions = () => {
      const geoSelection = GEO_OBJ[searchFilterType];
      const geoItems =
        geoSelection?.features?.map((l) => ({
          label: l.properties.name || l.properties.NAME,
          value: l.properties['hc-a2'] || l.properties.GEOID,
        })) ?? [];
      const leasItems = leas
        .filter(
          (l) => l.state_abbr === searchFilterType && !geoItems.some((g) => g.value === l.leaid)
        )
        .map((l) => ({ label: l.lea_name, value: l.leaid }));
      return _SortBy(
        [...geoItems, ...leasItems].filter((l) => l.label),
        ['label']
      );
    };

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    if (chartOptions?.suppressed) {
      return (
        <Alert className="m-3" variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
          visualization suppressed
        </Alert>
      );
    }
    return (
      <>
        {!readOnly && (
          <Container fluid className="p-4 bg-gray-200">
            <Row noGutters>
              <Col md={9} className="d-flex">
                {componentConfig?.queryResult?.filtered && (
                  <OverlayTrigger overlay={<Tooltip id="map-hide">Filtered</Tooltip>} delay={200}>
                    <FontAwesomeIcon
                      color="#AC193C"
                      icon={faFilter}
                      className="mt-0 mr-5"
                      size="lg"
                    />
                  </OverlayTrigger>
                )}
                <Dropdown>
                  <Dropdown.Toggle className="d-flex p-0 border-0 bg-gray-200 text-dark not-hover">
                    <h3>{visualizationComponent?.datasetFilter?.description}</h3>
                  </Dropdown.Toggle>
                  <Dropdown.Menu size="sm" title="">
                    {menuOptions.map((option) => (
                      <Dropdown key={shortid.generate()}>
                        <Dropdown.Toggle
                          variant="secondary"
                          className="w-100 d-flex bg-white text-dark border-0 px-4"
                        >
                          {option.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu size="sm" className="w-100">
                          {option.children.map((subOption) => {
                            if (subOption.children.length) {
                              return (
                                <Dropdown key={shortid.generate()}>
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    className="w-100 d-flex bg-white text-dark border-0 px-4"
                                  >
                                    {subOption.label}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu size="sm" className="w-100">
                                    {subOption.children.map((thirdOption) => {
                                      if (thirdOption.children.length) {
                                        return (
                                          <Dropdown key={shortid.generate()}>
                                            <Dropdown.Toggle
                                              variant="secondary"
                                              className="w-100 d-flex bg-white text-dark border-0 px-4"
                                            >
                                              {thirdOption.label}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm" className="w-100">
                                              {thirdOption.children.map((lastOption) => (
                                                <Dropdown.Item
                                                  className="w-100 custom-text-wrap"
                                                  key={shortid.generate()}
                                                  onClick={() =>
                                                    onDatasetChange(
                                                      option,
                                                      subOption,
                                                      thirdOption,
                                                      lastOption
                                                    )
                                                  }
                                                >
                                                  {lastOption.label}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        );
                                      }
                                      return (
                                        <Dropdown.Item
                                          className="w-100 custom-text-wrap"
                                          key={shortid.generate()}
                                          onClick={() =>
                                            onDatasetChange(option, subOption, thirdOption)
                                          }
                                        >
                                          {thirdOption.label}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              );
                            }
                            return (
                              <Dropdown.Item
                                className="w-100 custom-text-wrap"
                                key={shortid.generate()}
                                onClick={() => onDatasetChange(option, subOption)}
                              >
                                {subOption.label}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={3} className="d-flex justify-content-end">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      {visualizationComponent.hidden ? 'View' : 'Hide'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    icon={visualizationComponent.hidden ? faEye : faEyeSlash}
                    className="cursor-pointer mt-0"
                    size="lg"
                    onClick={onHideChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Export</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5 mt-0 cursor-pointer"
                    icon={faExternalLinkAlt}
                    size="lg"
                    onClick={onExportChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add to report</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5 mt-0 cursor-pointer"
                    icon={faFileAlt}
                    size="lg"
                    onClick={onAddToReport}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
        )}
        {!visualizationComponent.hidden && (
          <div className="bg-white">
            {!readOnly && (
              <Container className="my-3">
                <Row noGutters className="d-flex justify-content-between">
                  <Col className="d-flex" md={4}>
                    {!NO_DISTRICT_TOGGLE_STATES.includes(shapeSelectedId) && (
                      <>
                        <span className="pt-3">State</span>
                        <Form.Check
                          type="switch"
                          className="mx-3"
                          checked={visualizationComponent.viewMode !== 'STATE'}
                          id="custom-switch"
                          onClick={onStateDistrictToggleClick}
                        />
                        <span className="pt-3">District</span>
                      </>
                    )}
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Select
                        placeholder="Search"
                        className="w-75"
                        isSearchable
                        options={getSearchOptions()}
                        onChange={handleSearchChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            )}
            <div id="map-chart">
              <HighchartsReact
                callback={handleChartLoaded}
                highcharts={Highcharts}
                constructorType="mapChart"
                options={chartOptions}
                ref={ref}
              />
            </div>
            <div className="d-flex justify-content-end pr-5 pb-3">
              <FontAwesomeIcon
                icon={faSearchMinus}
                className="cursor-pointer mr-4 mt-0"
                size="lg"
                onClick={handleZoomOut}
              />
              {currentZoomText}
              <FontAwesomeIcon
                icon={faSearchPlus}
                className="cursor-pointer ml-4 mt-0"
                onClick={handleZoomIn}
                size="lg"
              />
            </div>
          </div>
        )}
      </>
    );
  }
);

MapChart.displayName = 'MapChart';

export default React.memo(MapChart);

export const MS = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6625, 33.9447],
            [-88.5496, 33.8987],
            [-88.4936, 33.8825],
            [-88.4971, 33.6802],
            [-88.7196, 33.6803],
            [-88.72, 33.8113],
            [-88.72, 33.9602],
            [-88.6625, 33.9447],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800360',
        NAME: 'Aberdeen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557125859,
        AWATER: 4010710,
        INTPTLAT: '+33.8031740',
        INTPTLON: '-088.6071574',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8231, 34.9952],
            [-88.7866, 34.9953],
            [-88.531, 34.9958],
            [-88.5617, 34.9018],
            [-88.4706, 34.9049],
            [-88.473, 34.996],
            [-88.3805, 34.9956],
            [-88.3635, 34.9955],
            [-88.3655, 34.7556],
            [-88.7185, 34.7568],
            [-88.7531, 34.7862],
            [-88.8235, 34.8586],
            [-88.8231, 34.9952],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800390',
        NAME: 'Alcorn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 957116246,
        AWATER: 3045454,
        INTPTLAT: '+34.8703808',
        INTPTLON: '-088.5901763',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6333, 31.3493],
            [-90.5482, 31.3496],
            [-90.5482, 31.2918],
            [-90.5481, 31.2035],
            [-90.5476, 30.9997],
            [-90.5672, 30.9997],
            [-90.8258, 30.9994],
            [-91.0602, 30.9989],
            [-91.0594, 31.2819],
            [-91.0954, 31.321],
            [-90.983, 31.3487],
            [-90.6333, 31.3493],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800420',
        NAME: 'Amite County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1890950833,
        AWATER: 3877823,
        INTPTLAT: '+31.2039333',
        INTPTLON: '-090.7955421',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6625, 33.9447],
            [-88.6548, 34.0153],
            [-88.4821, 34.0471],
            [-88.4655, 33.9712],
            [-88.5496, 33.8987],
            [-88.6625, 33.9447],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800450',
        NAME: 'Amory School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199262004,
        AWATER: 3467445,
        INTPTLAT: '+33.9753329',
        INTPTLON: '-088.5644047',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7494, 33.2145],
            [-89.6453, 33.2855],
            [-89.4538, 33.2859],
            [-89.3164, 33.2856],
            [-89.3179, 33.1089],
            [-89.3192, 32.9317],
            [-89.5439, 32.9309],
            [-89.4701, 32.9756],
            [-89.4625, 33.0461],
            [-89.5591, 33.182],
            [-89.7078, 33.197],
            [-89.7215, 33.0817],
            [-89.6273, 33.0698],
            [-89.6008, 32.9305],
            [-89.73, 32.8848],
            [-89.9659, 32.8796],
            [-89.9025, 32.9279],
            [-89.7494, 33.2145],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800510',
        NAME: 'Attala County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1507495180,
        AWATER: 3351913,
        INTPTLAT: '+33.1855748',
        INTPTLON: '-089.4725612',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7354, 34.5965],
            [-88.7178, 34.5965],
            [-88.5417, 34.5092],
            [-88.5421, 34.4653],
            [-88.5421, 34.4356],
            [-88.6848, 34.4676],
            [-88.7365, 34.5089],
            [-88.7354, 34.5965],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800540',
        NAME: 'Baldwyn School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170372233,
        AWATER: 282590,
        INTPTLAT: '+34.5159774',
        INTPTLON: '-088.6490381',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3408, 30.358],
            [-89.3069, 30.3091],
            [-89.3068, 30.2397],
            [-89.3408, 30.358],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800570',
        NAME: 'Bay St. Louis School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48643696,
        AWATER: 30671906,
        INTPTLAT: '+30.3081240',
        INTPTLON: '-089.3541706',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3527, 34.9939],
            [-89.1983, 34.9945],
            [-89.0171, 34.995],
            [-89.0176, 34.8587],
            [-89.0884, 34.786],
            [-89.0885, 34.5982],
            [-89.2456, 34.5832],
            [-89.2983, 34.5829],
            [-89.2989, 34.731],
            [-89.2992, 34.8444],
            [-89.3527, 34.8444],
            [-89.3527, 34.9939],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800600',
        NAME: 'Benton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1053131400,
        AWATER: 5279183,
        INTPTLAT: '+34.8105184',
        INTPTLON: '-089.2002848',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0013, 30.4061],
            [-88.8831, 30.4188],
            [-88.8618, 30.4156],
            [-88.8456, 30.4052],
            [-89.0013, 30.387],
            [-89.0013, 30.4061],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800630',
        NAME: 'Biloxi Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49788166,
        AWATER: 16337479,
        INTPTLAT: '+30.4122161',
        INTPTLON: '-088.9409476',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.079, 33.9542],
            [-90.9879, 34.019],
            [-90.8969, 34.0246],
            [-90.8718, 34.0768],
            [-90.8174, 33.9885],
            [-90.8676, 33.8946],
            [-90.8366, 33.8501],
            [-90.8058, 33.7329],
            [-90.8858, 33.7185],
            [-90.9024, 33.7476],
            [-91.1433, 33.747],
            [-91.0468, 33.8154],
            [-91.079, 33.9542],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800660',
        NAME: 'West Bolivar School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601160298,
        AWATER: 39228685,
        INTPTLAT: '+33.8729739',
        INTPTLON: '-090.9308982',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2309, 33.5614],
            [-91.1304, 33.606],
            [-91.2078, 33.7001],
            [-91.1433, 33.747],
            [-90.9024, 33.7476],
            [-90.8858, 33.7185],
            [-90.8854, 33.6162],
            [-90.9361, 33.5302],
            [-90.9715, 33.5303],
            [-91.2157, 33.5294],
            [-91.2309, 33.5614],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800690',
        NAME: 'Benoit School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537917627,
        AWATER: 26871731,
        INTPTLAT: '+33.6356532',
        INTPTLON: '-091.0380444',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.956, 34.1201],
            [-90.6595, 34.1189],
            [-90.657, 33.9876],
            [-90.6573, 33.9226],
            [-90.7977, 33.923],
            [-90.8676, 33.8946],
            [-90.8174, 33.9885],
            [-90.8718, 34.0768],
            [-90.956, 34.1201],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800720',
        NAME: 'North Bolivar School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399364417,
        AWATER: 4063110,
        INTPTLAT: '+34.0208193',
        INTPTLON: '-090.7684935',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8366, 33.8501],
            [-90.6583, 33.8507],
            [-90.6586, 33.6453],
            [-90.7633, 33.6456],
            [-90.8058, 33.7329],
            [-90.8366, 33.8501],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800750',
        NAME: 'Cleveland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278308319,
        AWATER: 4035478,
        INTPTLAT: '+33.7588549',
        INTPTLON: '-090.7255950',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8858, 33.7185],
            [-90.8058, 33.7329],
            [-90.7633, 33.6456],
            [-90.6586, 33.6453],
            [-90.7634, 33.5731],
            [-90.7635, 33.5294],
            [-90.9361, 33.5302],
            [-90.8854, 33.6162],
            [-90.8858, 33.7185],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800780',
        NAME: 'Shaw School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318825256,
        AWATER: 997652,
        INTPTLAT: '+33.6190782',
        INTPTLON: '-090.8253708',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8676, 33.8946],
            [-90.7977, 33.923],
            [-90.6573, 33.9226],
            [-90.6583, 33.8507],
            [-90.8366, 33.8501],
            [-90.8676, 33.8946],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800810',
        NAME: 'Mound Bayou Public School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134738870,
        AWATER: 545481,
        INTPTLAT: '+33.8888862',
        INTPTLON: '-090.7609229',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5877, 34.6704],
            [-88.5429, 34.669],
            [-88.5378, 34.638],
            [-88.5825, 34.6334],
            [-88.5877, 34.6704],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800820',
        NAME: 'Booneville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17840752,
        AWATER: 50138,
        INTPTLAT: '+34.6576043',
        INTPTLON: '-088.5626628',
        ELSDLEA: '',
        UNSDLEA: '00820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2449, 31.6436],
            [-90.2445, 31.4932],
            [-90.3703, 31.494],
            [-90.4803, 31.583],
            [-90.2449, 31.6436],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800840',
        NAME: 'Brookhaven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388803744,
        AWATER: 1912502,
        INTPTLAT: '+31.5710045',
        INTPTLON: '-090.3947917',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5097, 34.1621],
            [-89.2455, 34.1612],
            [-89.2444, 34.0739],
            [-89.1392, 34.0741],
            [-89.1381, 33.9591],
            [-89.1379, 33.8121],
            [-89.1918, 33.7386],
            [-89.5071, 33.7218],
            [-89.5074, 33.8675],
            [-89.5076, 34.0415],
            [-89.5097, 34.1621],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800870',
        NAME: 'Calhoun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1519218200,
        AWATER: 3590395,
        INTPTLAT: '+33.9366339',
        INTPTLON: '-089.3371142',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2351, 32.6654],
            [-90.099, 32.7007],
            [-90.0424, 32.7441],
            [-89.9345, 32.7252],
            [-89.9364, 32.5457],
            [-90.201, 32.546],
            [-90.2351, 32.6654],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800900',
        NAME: 'Canton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486244814,
        AWATER: 10680527,
        INTPTLAT: '+32.6251299',
        INTPTLON: '-090.0597566',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1278, 33.677],
            [-89.7869, 33.6767],
            [-89.7852, 33.4738],
            [-89.7477, 33.4166],
            [-89.6463, 33.4025],
            [-89.6453, 33.2855],
            [-89.7494, 33.2145],
            [-90.1746, 33.3334],
            [-90.1748, 33.417],
            [-90.1014, 33.4589],
            [-90.1022, 33.6524],
            [-90.1278, 33.677],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800930',
        NAME: 'Carroll County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1627140838,
        AWATER: 16426880,
        INTPTLAT: '+33.4407948',
        INTPTLON: '-089.9188870',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1392, 34.0741],
            [-88.8437, 34.075],
            [-88.8343, 33.956],
            [-88.8737, 33.9869],
            [-89.1381, 33.9591],
            [-89.1392, 34.0741],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800960',
        NAME: 'Chickasaw County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308351378,
        AWATER: 3043372,
        INTPTLAT: '+34.0216897',
        INTPTLON: '-088.9990476',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3811, 33.4603],
            [-89.222, 33.5272],
            [-89.0884, 33.5332],
            [-89.0883, 33.286],
            [-89.1089, 33.1981],
            [-89.2134, 33.1975],
            [-89.2139, 33.1094],
            [-89.3179, 33.1089],
            [-89.3164, 33.2856],
            [-89.4538, 33.2859],
            [-89.454, 33.4332],
            [-89.3811, 33.4603],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2800990',
        NAME: 'Choctaw County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1083088569,
        AWATER: 4331852,
        INTPTLAT: '+33.3459638',
        INTPTLON: '-089.2513319',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0302, 32.1144],
            [-90.9568, 32.0836],
            [-90.7748, 32.154],
            [-90.7286, 32.2251],
            [-90.7183, 32.0484],
            [-90.7158, 31.9622],
            [-90.7361, 31.8013],
            [-90.7363, 31.7861],
            [-91.0137, 31.8619],
            [-91.2481, 31.8698],
            [-91.1644, 31.9826],
            [-91.0302, 32.1144],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801020',
        NAME: 'Claiborne County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1262400025,
        AWATER: 35711133,
        INTPTLAT: '+31.9728096',
        INTPTLON: '-090.9154239',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6159, 34.1638],
            [-90.6114, 34.2191],
            [-90.5687, 34.2336],
            [-90.5386, 34.1754],
            [-90.6159, 34.1638],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801050',
        NAME: 'Clarksdale Municipal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35968206,
        AWATER: 16607,
        INTPTLAT: '+34.1975015',
        INTPTLON: '-090.5730546',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0345, 33.7394],
            [-88.9316, 33.7543],
            [-88.9312, 33.8124],
            [-88.8266, 33.8116],
            [-88.8448, 33.6056],
            [-88.7706, 33.5652],
            [-89.0186, 33.5621],
            [-89.0345, 33.7394],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801080',
        NAME: 'Clay County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 443540568,
        AWATER: 1128116,
        INTPTLAT: '+33.6717961',
        INTPTLON: '-088.9216761',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4841, 32.543],
            [-90.45, 32.5738],
            [-90.4459, 32.4879],
            [-90.2436, 32.4875],
            [-90.2436, 32.4072],
            [-90.3217, 32.2974],
            [-90.3746, 32.2924],
            [-90.4841, 32.543],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801090',
        NAME: 'Clinton Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346808190,
        AWATER: 2014154,
        INTPTLAT: '+32.4077542',
        INTPTLON: '-090.3724407',
        ELSDLEA: '',
        UNSDLEA: '01090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.713, 34.3638],
            [-90.5807, 34.4106],
            [-90.5693, 34.5249],
            [-90.3995, 34.4238],
            [-90.399, 34.2615],
            [-90.4506, 34.2624],
            [-90.451, 34.0729],
            [-90.451, 33.9857],
            [-90.657, 33.9876],
            [-90.6595, 34.1189],
            [-90.956, 34.1201],
            [-90.7652, 34.2805],
            [-90.713, 34.3638],
          ],
          [
            [-90.6159, 34.1638],
            [-90.5386, 34.1754],
            [-90.5687, 34.2336],
            [-90.6114, 34.2191],
            [-90.6159, 34.1638],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801110',
        NAME: 'Coahoma County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1394850184,
        AWATER: 79522853,
        INTPTLAT: '+34.2303156',
        INTPTLON: '-090.6040104',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9315, 34.1643],
            [-89.7764, 34.1637],
            [-89.7757, 34.0722],
            [-89.5076, 34.0415],
            [-89.5074, 33.8675],
            [-89.769, 33.8673],
            [-89.9261, 33.898],
            [-89.9315, 34.1643],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801140',
        NAME: 'Coffeeville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 870821685,
        AWATER: 55946737,
        INTPTLAT: '+33.9927997',
        INTPTLON: '-089.7324401',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9663, 31.3905],
            [-89.9589, 31.3905],
            [-89.8563, 31.3902],
            [-89.9663, 31.3905],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801170',
        NAME: 'Columbia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171573523,
        AWATER: 3611521,
        INTPTLAT: '+31.3130426',
        INTPTLON: '-089.8335249',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4803, 33.5227],
            [-88.4781, 33.6064],
            [-88.3596, 33.5758],
            [-88.3591, 33.4448],
            [-88.433, 33.4546],
            [-88.4803, 33.5227],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801200',
        NAME: 'Columbus Municipal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170554842,
        AWATER: 9709560,
        INTPTLAT: '+33.5408663',
        INTPTLON: '-088.4268172',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7363, 31.7861],
            [-90.7361, 31.8013],
            [-90.5825, 31.8011],
            [-90.5161, 31.7578],
            [-90.2304, 31.7756],
            [-90.275, 31.9101],
            [-90.5002, 31.9934],
            [-90.7158, 31.9622],
            [-90.7183, 32.0484],
            [-90.2303, 32.0497],
            [-90.1326, 31.8569],
            [-90.1226, 31.7527],
            [-90.2452, 31.7175],
            [-90.28, 31.6999],
            [-90.7368, 31.6985],
            [-90.7363, 31.7861],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801220',
        NAME: 'Copiah County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1157504107,
        AWATER: 4085401,
        INTPTLAT: '+31.9641408',
        INTPTLON: '-090.2681627',
        ELSDLEA: '',
        UNSDLEA: '01220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.531, 34.9958],
            [-88.473, 34.996],
            [-88.4706, 34.9049],
            [-88.5617, 34.9018],
            [-88.531, 34.9958],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801260',
        NAME: 'Corinth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78984349,
        AWATER: 372437,
        INTPTLAT: '+34.9468552',
        INTPTLON: '-088.5151343',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7551, 31.7747],
            [-89.653, 31.7807],
            [-89.4012, 31.7969],
            [-89.3992, 31.434],
            [-89.4516, 31.434],
            [-89.5868, 31.4337],
            [-89.6029, 31.5388],
            [-89.7556, 31.6098],
            [-89.7551, 31.7747],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801290',
        NAME: 'Covington County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1071727825,
        AWATER: 2937796,
        INTPTLAT: '+31.6333310',
        INTPTLON: '-089.5488968',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3093, 34.9957],
            [-90.3026, 34.9957],
            [-89.7243, 34.9948],
            [-89.7234, 34.7723],
            [-89.9086, 34.7726],
            [-90.0291, 34.7082],
            [-90.17, 34.7587],
            [-90.2002, 34.7244],
            [-90.2021, 34.8604],
            [-90.3045, 34.8606],
            [-90.2461, 34.9443],
            [-90.3093, 34.9957],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801320',
        NAME: 'DeSoto County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1233556702,
        AWATER: 53624068,
        INTPTLAT: '+34.8742660',
        INTPTLON: '-089.9932400',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8707, 33.0712],
            [-89.8675, 33.0838],
            [-89.8459, 33.0774],
            [-89.8559, 33.0637],
            [-89.8707, 33.0712],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801360',
        NAME: 'Durant Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5800933,
        AWATER: 61106,
        INTPTLAT: '+33.0819483',
        INTPTLON: '-089.8573345',
        ELSDLEA: '',
        UNSDLEA: '01360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1177, 32.224],
            [-89.1003, 32.224],
            [-88.9138, 32.2242],
            [-88.9136, 32.1085],
            [-88.9105, 31.8266],
            [-88.9434, 31.8246],
            [-89.1133, 31.8141],
            [-89.1177, 32.224],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801380',
        NAME: 'East Jasper School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 855508237,
        AWATER: 1564640,
        INTPTLAT: '+32.0127399',
        INTPTLON: '-088.9965520',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1869, 34.073],
            [-90.1355, 34.1601],
            [-89.9315, 34.1643],
            [-89.9261, 33.898],
            [-89.9263, 33.8239],
            [-90.1365, 33.8093],
            [-90.2414, 33.8408],
            [-90.2417, 33.9358],
            [-90.187, 33.9976],
            [-90.1869, 34.073],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801410',
        NAME: 'East Tallahatchie School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 952346485,
        AWATER: 8119595,
        INTPTLAT: '+33.9674180',
        INTPTLON: '-090.0644780',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9138, 32.2242],
            [-88.4314, 32.2277],
            [-88.4427, 32.1386],
            [-88.6271, 32.1381],
            [-88.8113, 32.1669],
            [-88.8102, 32.0595],
            [-88.9136, 32.1085],
            [-88.9138, 32.2242],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801440',
        NAME: 'Enterprise School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467096126,
        AWATER: 1355218,
        INTPTLAT: '+32.1831587',
        INTPTLON: '-088.6983111',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4037, 32.2233],
            [-89.5604, 32.2236],
            [-89.526, 32.371],
            [-89.4557, 32.3429],
            [-89.4747, 32.2378],
            [-89.4037, 32.2233],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801470',
        NAME: 'Forest Municipal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232432454,
        AWATER: 397165,
        INTPTLAT: '+32.3460295',
        INTPTLON: '-089.4953069',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4516, 31.434],
            [-89.3992, 31.434],
            [-89.299, 31.4341],
            [-89.2759, 31.3776],
            [-89.2799, 31.3394],
            [-89.2614, 31.3208],
            [-89.252, 31.2961],
            [-89.2254, 31.2595],
            [-89.1456, 31.2243],
            [-89.138, 30.9099],
            [-89.3408, 30.9099],
            [-89.349, 31.0825],
            [-89.3479, 31.2943],
            [-89.2631, 31.309],
            [-89.2617, 31.3204],
            [-89.2797, 31.3371],
            [-89.281, 31.3466],
            [-89.356, 31.3466],
            [-89.4511, 31.3464],
            [-89.4516, 31.434],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801490',
        NAME: 'Forrest County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 904180935,
        AWATER: 6137117,
        INTPTLAT: '+31.1355047',
        INTPTLON: '-089.2676371',
        ELSDLEA: '',
        UNSDLEA: '01490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1539, 31.6101],
            [-90.7373, 31.6111],
            [-90.6332, 31.6114],
            [-90.6333, 31.3493],
            [-90.983, 31.3487],
            [-91.0954, 31.321],
            [-91.1581, 31.3467],
            [-91.1539, 31.6101],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801530',
        NAME: 'Franklin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1460181276,
        AWATER: 7728324,
        INTPTLAT: '+31.4777763',
        INTPTLON: '-090.8958113',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.885, 30.9108],
            [-88.8343, 30.998],
            [-88.4254, 30.9983],
            [-88.4126, 30.7356],
            [-88.8845, 30.7356],
            [-88.885, 30.9108],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801560',
        NAME: 'George County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1239817243,
        AWATER: 12838126,
        INTPTLAT: '+30.8554305',
        INTPTLON: '-088.6422679',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8417, 31.4337],
            [-88.4494, 31.4358],
            [-88.432, 31.1143],
            [-88.4254, 30.9983],
            [-88.8343, 30.998],
            [-88.8412, 31.2583],
            [-88.8417, 31.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801590',
        NAME: 'Greene County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1846034019,
        AWATER: 15380474,
        INTPTLAT: '+31.2128455',
        INTPTLON: '-088.6348108',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.119, 33.4576],
            [-91.0223, 33.4578],
            [-91.0045, 33.388],
            [-91.1304, 33.3571],
            [-91.2093, 33.4049],
            [-91.119, 33.4576],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801620',
        NAME: 'Greenville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98364167,
        AWATER: 18016063,
        INTPTLAT: '+33.4188861',
        INTPTLON: '-091.1318233',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2434, 33.5252],
            [-90.2215, 33.5404],
            [-90.1563, 33.5244],
            [-90.1855, 33.499],
            [-90.2434, 33.5252],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801650',
        NAME: 'Greenwood Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26362549,
        AWATER: 798778,
        INTPTLAT: '+33.5192170',
        INTPTLON: '-090.1885655',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1368, 33.7216],
            [-90.1365, 33.8093],
            [-89.9263, 33.8239],
            [-89.9261, 33.898],
            [-89.769, 33.8673],
            [-89.5074, 33.8675],
            [-89.5071, 33.7218],
            [-89.5069, 33.6776],
            [-89.7869, 33.6767],
            [-90.1278, 33.677],
            [-90.1368, 33.7216],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801680',
        NAME: 'Grenada School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1093252414,
        AWATER: 70698246,
        INTPTLAT: '+33.7700310',
        INTPTLON: '-089.8027405',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1366, 30.3984],
            [-89.0013, 30.4061],
            [-89.0013, 30.387],
            [-89.1243, 30.3537],
            [-89.1366, 30.3984],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801710',
        NAME: 'Gulfport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58388409,
        AWATER: 18631035,
        INTPTLAT: '+30.3860926',
        INTPTLON: '-089.0676877',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6712, 30.3849],
            [-89.5452, 30.3851],
            [-89.5631, 30.5169],
            [-89.5442, 30.6484],
            [-89.3414, 30.6477],
            [-89.3415, 30.4548],
            [-89.3408, 30.358],
            [-89.3068, 30.2397],
            [-89.3061, 30.1779],
            [-89.495, 30.1608],
            [-89.5719, 30.1807],
            [-89.6316, 30.257],
            [-89.6712, 30.3849],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801740',
        NAME: 'Hancock County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1022206881,
        AWATER: 172503227,
        INTPTLAT: '+30.3942565',
        INTPTLON: '-089.4567940',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.3069, 30.3091],
              [-89.1869, 30.3303],
              [-89.1243, 30.3537],
              [-89.0013, 30.387],
              [-88.8456, 30.4052],
              [-88.8263, 30.3849],
              [-88.7953, 30.3504],
              [-88.7951, 30.2],
              [-88.889, 30.1398],
              [-89.0965, 30.1658],
              [-89.1837, 30.2122],
              [-89.3061, 30.1779],
              [-89.3068, 30.2397],
              [-89.3069, 30.3091],
            ],
          ],
          [
            [
              [-89.3414, 30.6477],
              [-89.2401, 30.6772],
              [-88.8845, 30.6773],
              [-88.8831, 30.4188],
              [-89.0013, 30.4061],
              [-89.1366, 30.3984],
              [-89.1893, 30.3406],
              [-89.2602, 30.4721],
              [-89.3415, 30.4548],
              [-89.3414, 30.6477],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801770',
        NAME: 'Harrison County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1188039896,
        AWATER: 982780788,
        INTPTLAT: '+30.5490738',
        INTPTLON: '-089.2070987',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3479, 31.2943],
            [-89.356, 31.3466],
            [-89.281, 31.3466],
            [-89.2797, 31.3371],
            [-89.2617, 31.3204],
            [-89.2631, 31.309],
            [-89.3479, 31.2943],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801800',
        NAME: 'Hattiesburg Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62607504,
        AWATER: 1664924,
        INTPTLAT: '+31.3121504',
        INTPTLON: '-089.3057715',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7158, 31.9622],
            [-90.5002, 31.9934],
            [-90.275, 31.9101],
            [-90.2304, 31.7756],
            [-90.5161, 31.7578],
            [-90.5825, 31.8011],
            [-90.7361, 31.8013],
            [-90.7158, 31.9622],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801830',
        NAME: 'Hazlehurst City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 855544459,
        AWATER: 1669585,
        INTPTLAT: '+31.8618635',
        INTPTLON: '-090.4992169',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7286, 32.2251],
            [-90.6836, 32.3528],
            [-90.628, 32.3395],
            [-90.6041, 32.4791],
            [-90.5538, 32.5076],
            [-90.4841, 32.543],
            [-90.3746, 32.2924],
            [-90.3217, 32.2974],
            [-90.3123, 32.2397],
            [-90.191, 32.2097],
            [-90.2454, 32.1714],
            [-90.2303, 32.0497],
            [-90.7183, 32.0484],
            [-90.7286, 32.2251],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801860',
        NAME: 'Hinds County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1636317183,
        AWATER: 11778863,
        INTPTLAT: '+32.2280550',
        INTPTLON: '-090.4977264',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9534, 33.2984],
            [-90.7642, 33.2995],
            [-90.7163, 33.27],
            [-90.6803, 33.202],
            [-90.7018, 33.0963],
            [-90.9145, 33.0974],
            [-90.9491, 33.1113],
            [-90.9534, 33.2984],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801890',
        NAME: 'Hollandale School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494084918,
        AWATER: 10554117,
        INTPTLAT: '+33.1976768',
        INTPTLON: '-090.8247927',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3527, 34.8444],
            [-89.2992, 34.8444],
            [-89.2989, 34.731],
            [-89.4239, 34.6865],
            [-89.6344, 34.701],
            [-89.5904, 34.7878],
            [-89.5922, 34.9049],
            [-89.4405, 34.8309],
            [-89.3527, 34.8444],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801950',
        NAME: 'Holly Springs School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464889244,
        AWATER: 1352586,
        INTPTLAT: '+34.7783752',
        INTPTLON: '-089.4700980',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3316, 33.3028],
            [-90.295, 33.3618],
            [-90.1746, 33.3334],
            [-89.7494, 33.2145],
            [-89.9025, 32.9279],
            [-89.9659, 32.8796],
            [-90.364, 33.0106],
            [-90.4288, 33.1079],
            [-90.3303, 33.1378],
            [-90.3316, 33.3028],
          ],
          [
            [-89.8707, 33.0712],
            [-89.8559, 33.0637],
            [-89.8459, 33.0774],
            [-89.8675, 33.0838],
            [-89.8707, 33.0712],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2801980',
        NAME: 'Holmes County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1954039991,
        AWATER: 20448052,
        INTPTLAT: '+33.1259415',
        INTPTLON: '-090.0911968',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1381, 33.9591],
            [-88.8737, 33.9869],
            [-88.8343, 33.956],
            [-88.746, 33.8109],
            [-88.8266, 33.8116],
            [-88.9312, 33.8124],
            [-88.9316, 33.7543],
            [-89.0345, 33.7394],
            [-89.1918, 33.7386],
            [-89.1379, 33.8121],
            [-89.1381, 33.9591],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802010',
        NAME: 'Houston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 734980598,
        AWATER: 1953000,
        INTPTLAT: '+33.8517818',
        INTPTLON: '-088.9863043',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7163, 33.27],
            [-90.5552, 33.2705],
            [-90.5555, 33.3292],
            [-90.4532, 33.3295],
            [-90.382, 33.2444],
            [-90.3316, 33.3028],
            [-90.3303, 33.1378],
            [-90.4288, 33.1079],
            [-90.364, 33.0106],
            [-90.4493, 33.009],
            [-90.4499, 32.9233],
            [-90.6566, 32.9225],
            [-90.658, 33.0965],
            [-90.7018, 33.0963],
            [-90.6803, 33.202],
            [-90.7163, 33.27],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802040',
        NAME: 'Humphreys County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1083947281,
        AWATER: 32942668,
        INTPTLAT: '+33.1309962',
        INTPTLON: '-090.5234209',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7635, 33.5294],
            [-90.7634, 33.5731],
            [-90.6585, 33.5725],
            [-90.6294, 33.4985],
            [-90.5631, 33.465],
            [-90.642, 33.3877],
            [-90.764, 33.3581],
            [-90.7635, 33.5294],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802070',
        NAME: 'Indianola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302093025,
        AWATER: 3191294,
        INTPTLAT: '+33.4674959',
        INTPTLON: '-090.6804562',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5421, 34.4356],
            [-88.5421, 34.4653],
            [-88.3292, 34.4636],
            [-88.1563, 34.4632],
            [-88.1736, 34.3211],
            [-88.2039, 34.0866],
            [-88.4312, 34.0881],
            [-88.5434, 34.089],
            [-88.5436, 34.1804],
            [-88.5421, 34.4356],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802100',
        NAME: 'Itawamba County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1379914599,
        AWATER: 19843967,
        INTPTLAT: '+34.2810747',
        INTPTLON: '-088.3631269',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.8263, 30.3849],
              [-88.7456, 30.347],
              [-88.6647, 30.362],
              [-88.6475, 30.1769],
              [-88.7951, 30.2],
              [-88.7953, 30.3504],
              [-88.8263, 30.3849],
            ],
          ],
          [
            [
              [-88.8845, 30.7356],
              [-88.4126, 30.7356],
              [-88.4037, 30.5449],
              [-88.4413, 30.5157],
              [-88.6077, 30.5183],
              [-88.6038, 30.4721],
              [-88.6981, 30.4104],
              [-88.8618, 30.4156],
              [-88.8831, 30.4188],
              [-88.8845, 30.6773],
              [-88.8845, 30.7356],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802160',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1330517857,
        AWATER: 283800915,
        INTPTLAT: '+30.5459181',
        INTPTLON: '-088.6734092',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2436, 32.4072],
            [-90.0657, 32.3995],
            [-90.0952, 32.3891],
            [-90.191, 32.2097],
            [-90.3123, 32.2397],
            [-90.3217, 32.2974],
            [-90.2436, 32.4072],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802190',
        NAME: 'Jackson Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269568848,
        AWATER: 5686890,
        INTPTLAT: '+32.3182716',
        INTPTLON: '-090.2151739',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2481, 31.8698],
            [-91.0137, 31.8619],
            [-90.7363, 31.7861],
            [-90.7368, 31.6985],
            [-90.7373, 31.6111],
            [-91.1539, 31.6101],
            [-91.2956, 31.6699],
            [-91.3179, 31.7498],
            [-91.3452, 31.8439],
            [-91.2481, 31.8698],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802220',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1346611166,
        AWATER: 18988092,
        INTPTLAT: '+31.7336333',
        INTPTLON: '-091.0438779',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9746, 31.7617],
            [-89.7551, 31.7747],
            [-89.7556, 31.6098],
            [-89.6029, 31.5388],
            [-89.5868, 31.4337],
            [-89.6542, 31.4338],
            [-89.8242, 31.4341],
            [-89.8563, 31.3902],
            [-89.9589, 31.3905],
            [-89.9761, 31.5225],
            [-89.9746, 31.7617],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802250',
        NAME: 'Jefferson Davis County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057871311,
        AWATER: 1791496,
        INTPTLAT: '+31.5656058',
        INTPTLON: '-089.8265175',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4012, 31.7969],
            [-89.3166, 31.8021],
            [-89.1133, 31.8141],
            [-88.9434, 31.8246],
            [-88.9435, 31.4335],
            [-89.0273, 31.4337],
            [-89.1461, 31.4341],
            [-89.299, 31.4341],
            [-89.3992, 31.434],
            [-89.4012, 31.7969],
          ],
          [
            [-89.1346, 31.7406],
            [-89.1809, 31.6624],
            [-89.1093, 31.6891],
            [-89.1346, 31.7406],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802280',
        NAME: 'Jones County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1761700392,
        AWATER: 11914123,
        INTPTLAT: '+31.6166011',
        INTPTLON: '-089.1685317',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9144, 32.9266],
            [-88.8128, 32.926],
            [-88.3479, 32.9291],
            [-88.3888, 32.5781],
            [-88.9145, 32.577],
            [-88.9144, 32.9266],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802310',
        NAME: 'Kemper County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1984360961,
        AWATER: 2182350,
        INTPTLAT: '+32.7501361',
        INTPTLON: '-088.6256306',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5439, 32.9309],
            [-89.6008, 32.9305],
            [-89.6273, 33.0698],
            [-89.7215, 33.0817],
            [-89.7078, 33.197],
            [-89.5591, 33.182],
            [-89.4625, 33.0461],
            [-89.4701, 32.9756],
            [-89.5439, 32.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802340',
        NAME: 'Kosciusko School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396099298,
        AWATER: 1069513,
        INTPTLAT: '+33.0904703',
        INTPTLON: '-089.5886221',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7211, 34.4446],
            [-89.7213, 34.5543],
            [-89.6684, 34.5544],
            [-89.4766, 34.5542],
            [-89.2461, 34.4956],
            [-89.2463, 34.3793],
            [-89.2455, 34.1612],
            [-89.5097, 34.1621],
            [-89.5094, 34.191],
            [-89.721, 34.1925],
            [-89.7213, 34.365],
            [-89.7213, 34.3792],
            [-89.7211, 34.4446],
          ],
          [
            [-89.5444, 34.3141],
            [-89.4391, 34.2926],
            [-89.4569, 34.4229],
            [-89.6485, 34.3921],
            [-89.6649, 34.3299],
            [-89.5444, 34.3141],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802370',
        NAME: 'Lafayette County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1412998789,
        AWATER: 122331739,
        INTPTLAT: '+34.2552590',
        INTPTLON: '-089.3619138',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6542, 31.4338],
            [-89.5868, 31.4337],
            [-89.4516, 31.434],
            [-89.4511, 31.3464],
            [-89.356, 31.3466],
            [-89.3479, 31.2943],
            [-89.349, 31.0825],
            [-89.5528, 31.0826],
            [-89.5519, 31.0019],
            [-89.654, 31.0025],
            [-89.6542, 31.4338],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802400',
        NAME: 'Lamar County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1113712254,
        AWATER: 7985551,
        INTPTLAT: '+31.2331823',
        INTPTLON: '-089.5298385',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9145, 32.577],
            [-88.3888, 32.5781],
            [-88.4215, 32.3087],
            [-88.4314, 32.2277],
            [-88.9138, 32.2242],
            [-88.9145, 32.577],
          ],
          [
            [-88.7736, 32.3562],
            [-88.6357, 32.335],
            [-88.6099, 32.3975],
            [-88.7358, 32.4288],
            [-88.7736, 32.3562],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802430',
        NAME: 'Lauderdale County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1683263361,
        AWATER: 27808213,
        INTPTLAT: '+32.4890982',
        INTPTLON: '-088.5186745',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1346, 31.7406],
            [-89.1093, 31.6891],
            [-89.1809, 31.6624],
            [-89.1346, 31.7406],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802460',
        NAME: 'Laurel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37858511,
        AWATER: 762679,
        INTPTLAT: '+31.6938347',
        INTPTLON: '-089.1433954',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2452, 31.7175],
            [-90.1226, 31.7527],
            [-89.9746, 31.7617],
            [-89.9761, 31.5225],
            [-89.9589, 31.3905],
            [-89.9663, 31.3905],
            [-90.0405, 31.336],
            [-90.2439, 31.3503],
            [-90.2445, 31.4932],
            [-90.2449, 31.6436],
            [-90.2452, 31.7175],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802490',
        NAME: 'Lawrence County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1115424609,
        AWATER: 13213419,
        INTPTLAT: '+31.5500087',
        INTPTLON: '-090.1075299',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.73, 32.8848],
            [-89.6008, 32.9305],
            [-89.5439, 32.9309],
            [-89.3192, 32.9317],
            [-89.3176, 32.5765],
            [-89.3226, 32.5764],
            [-89.7302, 32.5772],
            [-89.7303, 32.6342],
            [-89.73, 32.8848],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802520',
        NAME: 'Leake County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1509966828,
        AWATER: 6411187,
        INTPTLAT: '+32.7607372',
        INTPTLON: '-089.5222361',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.825, 34.3648],
            [-88.7894, 34.4949],
            [-88.7365, 34.5089],
            [-88.6848, 34.4676],
            [-88.5421, 34.4356],
            [-88.5436, 34.1804],
            [-88.656, 34.1656],
            [-88.719, 34.0745],
            [-88.8245, 34.0749],
            [-88.8247, 34.1842],
            [-88.8248, 34.206],
            [-88.7185, 34.203],
            [-88.6495, 34.237],
            [-88.6504, 34.309],
            [-88.7396, 34.3464],
            [-88.8254, 34.2936],
            [-88.825, 34.3648],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802550',
        NAME: 'Lee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 798859319,
        AWATER: 6172522,
        INTPTLAT: '+34.4023181',
        INTPTLON: '-088.6131097',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4532, 33.3295],
            [-90.4516, 33.8114],
            [-90.1826, 33.8093],
            [-90.1368, 33.7216],
            [-90.1278, 33.677],
            [-90.1022, 33.6524],
            [-90.1014, 33.4589],
            [-90.1748, 33.417],
            [-90.1746, 33.3334],
            [-90.295, 33.3618],
            [-90.3316, 33.3028],
            [-90.382, 33.2444],
            [-90.4532, 33.3295],
          ],
          [
            [-90.2434, 33.5252],
            [-90.1855, 33.499],
            [-90.1563, 33.5244],
            [-90.2215, 33.5404],
            [-90.2434, 33.5252],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802580',
        NAME: 'Leflore County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1510430978,
        AWATER: 32921585,
        INTPTLAT: '+33.5615801',
        INTPTLON: '-090.3106985',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9715, 33.5303],
            [-90.9361, 33.5302],
            [-90.7635, 33.5294],
            [-90.764, 33.3581],
            [-90.7642, 33.2995],
            [-90.9534, 33.2984],
            [-90.9715, 33.5303],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802610',
        NAME: 'Leland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493503897,
        AWATER: 4417282,
        INTPTLAT: '+33.4130061',
        INTPTLON: '-090.8665908',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7368, 31.6985],
            [-90.28, 31.6999],
            [-90.2452, 31.7175],
            [-90.2449, 31.6436],
            [-90.4803, 31.583],
            [-90.3703, 31.494],
            [-90.2445, 31.4932],
            [-90.2439, 31.3503],
            [-90.2604, 31.3503],
            [-90.5482, 31.3496],
            [-90.6333, 31.3493],
            [-90.6332, 31.6114],
            [-90.7373, 31.6111],
            [-90.7368, 31.6985],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802640',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1129255938,
        AWATER: 3393062,
        INTPTLAT: '+31.4361569',
        INTPTLON: '-090.3637117',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1366, 30.3984],
            [-89.1243, 30.3537],
            [-89.1869, 30.3303],
            [-89.1893, 30.3406],
            [-89.1366, 30.3984],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802670',
        NAME: 'Long Beach School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39247561,
        AWATER: 1130804,
        INTPTLAT: '+30.3737947',
        INTPTLON: '-089.1593578',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3179, 33.1089],
            [-89.2139, 33.1094],
            [-89.2134, 33.1975],
            [-89.1089, 33.1981],
            [-89.0883, 33.286],
            [-88.8131, 33.2864],
            [-88.8128, 32.926],
            [-88.9144, 32.9266],
            [-89.3192, 32.9317],
            [-89.3179, 33.1089],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802700',
        NAME: 'Louisville Municipal School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1572731671,
        AWATER: 7383407,
        INTPTLAT: '+33.0787236',
        INTPTLON: '-089.0373913',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6713, 33.5062],
            [-88.4803, 33.5227],
            [-88.433, 33.4546],
            [-88.3591, 33.4448],
            [-88.3596, 33.5758],
            [-88.4781, 33.6064],
            [-88.5139, 33.6502],
            [-88.3703, 33.6859],
            [-88.2489, 33.745],
            [-88.2746, 33.534],
            [-88.3044, 33.2883],
            [-88.6691, 33.2869],
            [-88.6713, 33.5062],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802730',
        NAME: 'Lowndes County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1138522375,
        AWATER: 18850940,
        INTPTLAT: '+33.4613532',
        INTPTLON: '-088.4413891',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.349, 31.0825],
            [-89.3408, 30.9099],
            [-89.3409, 30.895],
            [-89.4593, 30.9104],
            [-89.5519, 31.0019],
            [-89.5528, 31.0826],
            [-89.349, 31.0825],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802760',
        NAME: 'Lumberton Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326783199,
        AWATER: 1480628,
        INTPTLAT: '+31.0032428',
        INTPTLON: '-089.4305567',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2351, 32.6654],
            [-90.201, 32.546],
            [-89.9364, 32.5457],
            [-89.9345, 32.7252],
            [-90.0424, 32.7441],
            [-89.9659, 32.8796],
            [-89.73, 32.8848],
            [-89.7303, 32.6342],
            [-89.7841, 32.5869],
            [-89.8612, 32.5832],
            [-90.0657, 32.3995],
            [-90.2436, 32.4072],
            [-90.2436, 32.4875],
            [-90.4459, 32.4879],
            [-90.45, 32.5738],
            [-90.2351, 32.6654],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802790',
        NAME: 'Madison County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1363809998,
        AWATER: 60467399,
        INTPTLAT: '+32.7593662',
        INTPTLON: '-089.9113827',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0405, 31.336],
            [-89.9663, 31.3905],
            [-89.8563, 31.3902],
            [-89.8242, 31.4341],
            [-89.6542, 31.4338],
            [-89.654, 31.0025],
            [-89.7281, 31.0023],
            [-89.8359, 31.002],
            [-89.8359, 31.0749],
            [-89.9209, 31.0747],
            [-89.9382, 31.1329],
            [-90.0319, 31.1757],
            [-90.0405, 31.336],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802820',
        NAME: 'Marion County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1233196070,
        AWATER: 12538421,
        INTPTLAT: '+31.2219780',
        INTPTLON: '-089.8178685',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7243, 34.9948],
            [-89.6443, 34.9953],
            [-89.3527, 34.9939],
            [-89.3527, 34.8444],
            [-89.4405, 34.8309],
            [-89.5922, 34.9049],
            [-89.5904, 34.7878],
            [-89.6344, 34.701],
            [-89.4239, 34.6865],
            [-89.2989, 34.731],
            [-89.2983, 34.5829],
            [-89.2456, 34.5832],
            [-89.2461, 34.4956],
            [-89.4766, 34.5542],
            [-89.6684, 34.5544],
            [-89.6692, 34.6858],
            [-89.7234, 34.7723],
            [-89.7243, 34.9948],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802850',
        NAME: 'Marshall County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1364100589,
        AWATER: 7842604,
        INTPTLAT: '+34.8671951',
        INTPTLON: '-089.6031029',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5482, 31.2918],
            [-90.4645, 31.3172],
            [-90.4462, 31.2195],
            [-90.5481, 31.2035],
            [-90.5482, 31.2918],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802880',
        NAME: 'McComb School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113803343,
        AWATER: 553577,
        INTPTLAT: '+31.2533502',
        INTPTLON: '-090.4907585',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7736, 32.3562],
            [-88.7358, 32.4288],
            [-88.6099, 32.3975],
            [-88.6357, 32.335],
            [-88.7736, 32.3562],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802910',
        NAME: 'Meridian Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139183380,
        AWATER: 1983871,
        INTPTLAT: '+32.3878083',
        INTPTLON: '-088.6903335',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5496, 33.8987],
            [-88.4655, 33.9712],
            [-88.4821, 34.0471],
            [-88.4312, 34.0881],
            [-88.2039, 34.0866],
            [-88.2072, 34.0583],
            [-88.2489, 33.745],
            [-88.3703, 33.6859],
            [-88.5139, 33.6502],
            [-88.4971, 33.6802],
            [-88.4936, 33.8825],
            [-88.5496, 33.8987],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802940',
        NAME: 'Monroe County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1025837393,
        AWATER: 10259264,
        INTPTLAT: '+33.8967312',
        INTPTLON: '-088.3645352',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7869, 33.6767],
            [-89.5069, 33.6776],
            [-89.5065, 33.4598],
            [-89.3811, 33.4603],
            [-89.454, 33.4332],
            [-89.4538, 33.2859],
            [-89.6453, 33.2855],
            [-89.6463, 33.4025],
            [-89.7477, 33.4166],
            [-89.6806, 33.4739],
            [-89.7852, 33.4738],
            [-89.7869, 33.6767],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2802970',
        NAME: 'Montgomery County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 991851424,
        AWATER: 2107670,
        INTPTLAT: '+33.5028018',
        INTPTLON: '-089.6054832',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4037, 30.5449],
            [-88.3844, 30.1585],
            [-88.447, 30.1463],
            [-88.4467, 30.3843],
            [-88.5646, 30.4192],
            [-88.6038, 30.4721],
            [-88.6077, 30.5183],
            [-88.4413, 30.5157],
            [-88.4037, 30.5449],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803000',
        NAME: 'Moss Point School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268541209,
        AWATER: 138050775,
        INTPTLAT: '+30.3883778',
        INTPTLON: '-088.4590453',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3803, 31.7333],
            [-91.3179, 31.7498],
            [-91.2956, 31.6699],
            [-91.1539, 31.6101],
            [-91.1581, 31.3467],
            [-91.3036, 31.361],
            [-91.424, 31.315],
            [-91.4788, 31.239],
            [-91.5895, 31.1911],
            [-91.6413, 31.2669],
            [-91.5186, 31.2753],
            [-91.5482, 31.3461],
            [-91.4721, 31.3971],
            [-91.5159, 31.5307],
            [-91.4439, 31.5425],
            [-91.3803, 31.7333],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803030',
        NAME: 'Natchez-Adams School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1197468111,
        AWATER: 65273643,
        INTPTLAT: '+31.4862176',
        INTPTLON: '-091.3517814',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3192, 32.9317],
            [-88.9144, 32.9266],
            [-88.9145, 32.577],
            [-89.0343, 32.5769],
            [-89.0762, 32.627],
            [-89.1786, 32.6351],
            [-89.2563, 32.5765],
            [-89.3176, 32.5765],
            [-89.3192, 32.9317],
          ],
          [
            [-89.1396, 32.7726],
            [-89.0933, 32.7489],
            [-89.077, 32.7595],
            [-89.1142, 32.7894],
            [-89.1396, 32.7726],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803060',
        NAME: 'Neshoba County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1368989424,
        AWATER: 3742645,
        INTPTLAT: '+32.7553274',
        INTPTLON: '-089.1143345',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.719, 34.0745],
            [-88.656, 34.1656],
            [-88.5436, 34.1804],
            [-88.5434, 34.089],
            [-88.4312, 34.0881],
            [-88.4821, 34.0471],
            [-88.6548, 34.0153],
            [-88.719, 34.0745],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803090',
        NAME: 'Nettleton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253949288,
        AWATER: 623869,
        INTPTLAT: '+34.0813454',
        INTPTLON: '-088.6033114',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0041, 34.5985],
            [-88.8247, 34.597],
            [-88.9467, 34.471],
            [-89.0576, 34.4684],
            [-89.0041, 34.5985],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803120',
        NAME: 'New Albany Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198353738,
        AWATER: 506880,
        INTPTLAT: '+34.5341011',
        INTPTLON: '-088.9365250',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3226, 32.5764],
            [-89.3176, 32.5765],
            [-89.2563, 32.5765],
            [-89.2521, 32.5322],
            [-89.0849, 32.5288],
            [-89.0343, 32.5769],
            [-88.9145, 32.577],
            [-88.9138, 32.2242],
            [-89.1003, 32.224],
            [-89.1004, 32.3845],
            [-89.2881, 32.3984],
            [-89.3226, 32.308],
            [-89.3226, 32.5764],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803150',
        NAME: 'Newton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1048607645,
        AWATER: 3631238,
        INTPTLAT: '+32.4219060',
        INTPTLON: '-089.0887564',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3226, 32.308],
            [-89.2881, 32.3984],
            [-89.1004, 32.3845],
            [-89.1003, 32.224],
            [-89.1177, 32.224],
            [-89.3227, 32.2235],
            [-89.3226, 32.308],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803180',
        NAME: 'Newton Municipal School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355256355,
        AWATER: 495759,
        INTPTLAT: '+32.3036744',
        INTPTLON: '-089.2072190',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1986, 34.5544],
            [-90.0315, 34.5541],
            [-89.8984, 34.5537],
            [-89.7213, 34.5543],
            [-89.7211, 34.4446],
            [-89.8947, 34.376],
            [-90.1974, 34.3761],
            [-90.1985, 34.5111],
            [-90.1986, 34.5544],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803210',
        NAME: 'North Panola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 772695584,
        AWATER: 23910210,
        INTPTLAT: '+34.4749300',
        INTPTLON: '-089.9724424',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5482, 31.3496],
            [-90.2604, 31.3503],
            [-90.2605, 31.2042],
            [-90.4462, 31.2195],
            [-90.4645, 31.3172],
            [-90.5482, 31.2918],
            [-90.5482, 31.3496],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803240',
        NAME: 'North Pike School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325661833,
        AWATER: 837057,
        INTPTLAT: '+31.2905377',
        INTPTLON: '-090.3713389',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0171, 34.995],
            [-88.8231, 34.9952],
            [-88.8235, 34.8586],
            [-88.7531, 34.7862],
            [-88.8232, 34.7715],
            [-89.0884, 34.786],
            [-89.0176, 34.8587],
            [-89.0171, 34.995],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803270',
        NAME: 'North Tippah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505352764,
        AWATER: 3017659,
        INTPTLAT: '+34.8763823',
        INTPTLON: '-088.9133145',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8131, 33.2864],
            [-88.6691, 33.2869],
            [-88.3044, 33.2883],
            [-88.3404, 32.9912],
            [-88.3479, 32.9291],
            [-88.8128, 32.926],
            [-88.8131, 33.2864],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803300',
        NAME: 'Noxubee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1800451878,
        AWATER: 12487979,
        INTPTLAT: '+33.1065082',
        INTPTLON: '-088.5660044',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6981, 30.4104],
            [-88.6647, 30.362],
            [-88.7456, 30.347],
            [-88.8263, 30.3849],
            [-88.8456, 30.4052],
            [-88.8618, 30.4156],
            [-88.6981, 30.4104],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803360',
        NAME: 'Ocean Springs School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88183820,
        AWATER: 12010998,
        INTPTLAT: '+30.3922808',
        INTPTLON: '-088.7557559',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8437, 34.075],
            [-88.8245, 34.0749],
            [-88.719, 34.0745],
            [-88.6548, 34.0153],
            [-88.6625, 33.9447],
            [-88.72, 33.9602],
            [-88.72, 33.8113],
            [-88.746, 33.8109],
            [-88.8343, 33.956],
            [-88.8437, 34.075],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803390',
        NAME: 'Okolona Separate School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302479759,
        AWATER: 1500424,
        INTPTLAT: '+33.9717489',
        INTPTLON: '-088.7614086',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0884, 33.5332],
            [-89.0186, 33.5621],
            [-88.7706, 33.5652],
            [-88.6713, 33.5062],
            [-88.6691, 33.2869],
            [-88.8131, 33.2864],
            [-89.0883, 33.286],
            [-89.0884, 33.5332],
          ],
          [
            [-88.9708, 33.3562],
            [-88.7949, 33.356],
            [-88.7927, 33.5057],
            [-88.9594, 33.5061],
            [-88.9708, 33.3562],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803420',
        NAME: 'Oktibbeha County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 911333606,
        AWATER: 7676970,
        INTPTLAT: '+33.3534310',
        INTPTLON: '-088.7732429',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4569, 34.4229],
            [-89.4391, 34.2926],
            [-89.5444, 34.3141],
            [-89.6649, 34.3299],
            [-89.6485, 34.3921],
            [-89.4569, 34.4229],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803450',
        NAME: 'Oxford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223142967,
        AWATER: 715843,
        INTPTLAT: '+34.3653652',
        INTPTLON: '-089.5349487',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6981, 30.4104],
            [-88.6038, 30.4721],
            [-88.5646, 30.4192],
            [-88.4467, 30.3843],
            [-88.447, 30.1463],
            [-88.6475, 30.1769],
            [-88.6647, 30.362],
            [-88.6981, 30.4104],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803480',
        NAME: 'Pascagoula School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184679405,
        AWATER: 396615883,
        INTPTLAT: '+30.3322656',
        INTPTLON: '-088.5811746',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3415, 30.4548],
            [-89.2602, 30.4721],
            [-89.1893, 30.3406],
            [-89.1869, 30.3303],
            [-89.3069, 30.3091],
            [-89.3408, 30.358],
            [-89.3415, 30.4548],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803510',
        NAME: 'Pass Christian Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151248954,
        AWATER: 44738722,
        INTPTLAT: '+30.3819957',
        INTPTLON: '-089.2673985',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0514, 32.3236],
            [-90.038, 32.2471],
            [-90.123, 32.2391],
            [-90.1298, 32.3015],
            [-90.0514, 32.3236],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803520',
        NAME: 'Pearl Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66288596,
        AWATER: 297380,
        INTPTLAT: '+32.2724563',
        INTPTLON: '-090.0918269',
        ELSDLEA: '',
        UNSDLEA: '03520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.299, 31.4341],
            [-89.1461, 31.4341],
            [-89.1456, 31.2243],
            [-89.2254, 31.2595],
            [-89.252, 31.2961],
            [-89.2614, 31.3208],
            [-89.2799, 31.3394],
            [-89.2759, 31.3776],
            [-89.299, 31.4341],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803530',
        NAME: 'Petal School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242709857,
        AWATER: 3076437,
        INTPTLAT: '+31.3417705',
        INTPTLON: '-089.2101760',
        ELSDLEA: '',
        UNSDLEA: '03530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7299, 30.736],
            [-89.5615, 30.7359],
            [-89.5442, 30.6484],
            [-89.5631, 30.5169],
            [-89.7299, 30.6056],
            [-89.7299, 30.736],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803540',
        NAME: 'Pearl River County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344846266,
        AWATER: 3124632,
        INTPTLAT: '+30.6316380',
        INTPTLON: '-089.6366661',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1456, 31.2243],
            [-89.1461, 31.4341],
            [-89.0273, 31.4337],
            [-89.0101, 31.2584],
            [-88.8412, 31.2583],
            [-88.8343, 30.998],
            [-88.885, 30.9108],
            [-89.138, 30.9099],
            [-89.1456, 31.2243],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803570',
        NAME: 'Perry County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1356522060,
        AWATER: 7458374,
        INTPTLAT: '+31.1247680',
        INTPTLON: '-089.0034955',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1396, 32.7726],
            [-89.1142, 32.7894],
            [-89.077, 32.7595],
            [-89.0933, 32.7489],
            [-89.1396, 32.7726],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803600',
        NAME: 'Philadelphia Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16401860,
        AWATER: 0,
        INTPTLAT: '+32.7735460',
        INTPTLON: '-089.1091348',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8423, 30.666],
            [-89.836, 30.7358],
            [-89.7299, 30.736],
            [-89.7299, 30.6056],
            [-89.5631, 30.5169],
            [-89.5452, 30.3851],
            [-89.6712, 30.3849],
            [-89.6836, 30.4518],
            [-89.7528, 30.503],
            [-89.8423, 30.666],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803630',
        NAME: 'Picayune School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489909098,
        AWATER: 6967444,
        INTPTLAT: '+30.5518505',
        INTPTLON: '-089.7010485',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2463, 34.3793],
            [-88.9289, 34.3794],
            [-88.825, 34.3648],
            [-88.8254, 34.2936],
            [-88.9304, 34.3076],
            [-89.0709, 34.2787],
            [-89.0197, 34.2065],
            [-88.9034, 34.1336],
            [-88.8247, 34.1842],
            [-88.8245, 34.0749],
            [-88.8437, 34.075],
            [-89.1392, 34.0741],
            [-89.2444, 34.0739],
            [-89.2455, 34.1612],
            [-89.2463, 34.3793],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803660',
        NAME: 'Pontotoc County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1029503225,
        AWATER: 5104993,
        INTPTLAT: '+34.2289910',
        INTPTLON: '-089.0612906',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9304, 34.3076],
            [-88.8254, 34.2936],
            [-88.8248, 34.206],
            [-88.8247, 34.1842],
            [-88.9034, 34.1336],
            [-89.0197, 34.2065],
            [-89.0709, 34.2787],
            [-88.9304, 34.3076],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803690',
        NAME: 'Pontotoc City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259639649,
        AWATER: 3391518,
        INTPTLAT: '+34.2484174',
        INTPTLON: '-088.9358575',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8328, 30.7653],
            [-89.8329, 30.7657],
            [-89.7281, 31.0023],
            [-89.654, 31.0025],
            [-89.5519, 31.0019],
            [-89.4593, 30.9104],
            [-89.3409, 30.895],
            [-89.3414, 30.6477],
            [-89.5442, 30.6484],
            [-89.5615, 30.7359],
            [-89.7299, 30.736],
            [-89.836, 30.7358],
            [-89.8328, 30.7653],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803720',
        NAME: 'Poplarville Separate School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1266682630,
        AWATER: 10740210,
        INTPTLAT: '+30.8190664',
        INTPTLON: '-089.5622328',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7185, 34.7568],
            [-88.3655, 34.7556],
            [-88.3307, 34.7407],
            [-88.3292, 34.4636],
            [-88.5421, 34.4653],
            [-88.5417, 34.5092],
            [-88.7178, 34.5965],
            [-88.7185, 34.7568],
          ],
          [
            [-88.5877, 34.6704],
            [-88.5825, 34.6334],
            [-88.5378, 34.638],
            [-88.5429, 34.669],
            [-88.5877, 34.6704],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803750',
        NAME: 'Prentiss County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 965872537,
        AWATER: 8130203,
        INTPTLAT: '+34.6245435',
        INTPTLON: '-088.5056374',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9136, 32.1085],
            [-88.8102, 32.0595],
            [-88.8113, 32.1669],
            [-88.6271, 32.1381],
            [-88.4427, 32.1386],
            [-88.4732, 31.8939],
            [-88.6224, 31.8572],
            [-88.9105, 31.8266],
            [-88.9136, 32.1085],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803780',
        NAME: 'Quitman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1324081096,
        AWATER: 3714543,
        INTPTLAT: '+31.9969503',
        INTPTLON: '-088.6792559',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3995, 34.4238],
            [-90.3019, 34.4245],
            [-90.3043, 34.5096],
            [-90.1985, 34.5111],
            [-90.1974, 34.3761],
            [-90.1941, 34.3014],
            [-90.1344, 34.2652],
            [-90.1355, 34.1601],
            [-90.1869, 34.073],
            [-90.451, 34.0729],
            [-90.4506, 34.2624],
            [-90.399, 34.2615],
            [-90.3995, 34.4238],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803810',
        NAME: 'Quitman County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1048975542,
        AWATER: 3676001,
        INTPTLAT: '+34.2528327',
        INTPTLON: '-090.2901707',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.191, 32.2097],
            [-90.0952, 32.3891],
            [-90.0657, 32.3995],
            [-89.8612, 32.5832],
            [-89.7841, 32.5869],
            [-89.7301, 32.3527],
            [-89.7304, 32.222],
            [-89.7301, 32.0471],
            [-90.2303, 32.0497],
            [-90.2454, 32.1714],
            [-90.191, 32.2097],
          ],
          [
            [-90.0514, 32.3236],
            [-90.1298, 32.3015],
            [-90.123, 32.2391],
            [-90.038, 32.2471],
            [-90.0514, 32.3236],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803830',
        NAME: 'Rankin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1942033309,
        AWATER: 79132874,
        INTPTLAT: '+32.2692938',
        INTPTLON: '-089.9333337',
        ELSDLEA: '',
        UNSDLEA: '03830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0273, 31.4337],
            [-88.9435, 31.4335],
            [-88.8417, 31.4337],
            [-88.8412, 31.2583],
            [-89.0101, 31.2584],
            [-89.0273, 31.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803870',
        NAME: 'Richton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319737579,
        AWATER: 295971,
        INTPTLAT: '+31.3500325',
        INTPTLON: '-088.9262350',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7841, 32.5869],
            [-89.7303, 32.6342],
            [-89.7302, 32.5772],
            [-89.3226, 32.5764],
            [-89.3226, 32.308],
            [-89.3227, 32.2235],
            [-89.4037, 32.2233],
            [-89.4747, 32.2378],
            [-89.4557, 32.3429],
            [-89.526, 32.371],
            [-89.5604, 32.2236],
            [-89.7304, 32.222],
            [-89.7301, 32.3527],
            [-89.7841, 32.5869],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803900',
        NAME: 'Scott County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1345469623,
        AWATER: 2604357,
        INTPTLAT: '+32.4138679',
        INTPTLON: '-089.5474393',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8984, 34.5537],
            [-90.0315, 34.5541],
            [-90.0414, 34.6411],
            [-89.898, 34.6266],
            [-89.8984, 34.5537],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803930',
        NAME: 'Senatobia Municipal School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114464832,
        AWATER: 394295,
        INTPTLAT: '+34.5967370',
        INTPTLON: '-089.9707012',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.064, 32.9241],
            [-91.0344, 32.9677],
            [-90.9143, 33.0077],
            [-90.9145, 33.0974],
            [-90.7018, 33.0963],
            [-90.658, 33.0965],
            [-90.6566, 32.9225],
            [-90.7598, 32.8358],
            [-90.6931, 32.7543],
            [-90.7201, 32.6189],
            [-90.7263, 32.5699],
            [-90.8606, 32.5752],
            [-90.8614, 32.4421],
            [-90.9512, 32.4444],
            [-90.964, 32.5756],
            [-91.0436, 32.5763],
            [-91.1527, 32.6408],
            [-91.0608, 32.7275],
            [-91.1658, 32.7578],
            [-91.1436, 32.8447],
            [-91.064, 32.9241],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803960',
        NAME: 'South Delta School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2055441855,
        AWATER: 66472708,
        INTPTLAT: '+32.8088397',
        INTPTLON: '-090.8740804',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2303, 32.0497],
            [-89.7301, 32.0471],
            [-89.6544, 32.0137],
            [-89.653, 31.7807],
            [-89.7551, 31.7747],
            [-89.9746, 31.7617],
            [-90.1226, 31.7527],
            [-90.1326, 31.8569],
            [-90.2303, 32.0497],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2803990',
        NAME: 'Simpson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1525930830,
        AWATER: 3378529,
        INTPTLAT: '+31.9025047',
        INTPTLON: '-089.9177086',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7304, 32.222],
            [-89.5604, 32.2236],
            [-89.4037, 32.2233],
            [-89.3227, 32.2235],
            [-89.3166, 31.8021],
            [-89.4012, 31.7969],
            [-89.653, 31.7807],
            [-89.6544, 32.0137],
            [-89.7301, 32.0471],
            [-89.7304, 32.222],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804020',
        NAME: 'Smith County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1647926085,
        AWATER: 2599432,
        INTPTLAT: '+32.0190291',
        INTPTLON: '-089.4948768',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1974, 34.3761],
            [-89.8947, 34.376],
            [-89.7211, 34.4446],
            [-89.7213, 34.3792],
            [-89.7213, 34.365],
            [-89.721, 34.1925],
            [-89.7764, 34.1637],
            [-89.9315, 34.1643],
            [-90.1355, 34.1601],
            [-90.1344, 34.2652],
            [-90.1941, 34.3014],
            [-90.1974, 34.3761],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804050',
        NAME: 'South Panola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1001819585,
        AWATER: 27856861,
        INTPTLAT: '+34.2838671',
        INTPTLON: '-089.9335723',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5481, 31.2035],
            [-90.4462, 31.2195],
            [-90.2605, 31.2042],
            [-90.2596, 31.0007],
            [-90.3473, 31.0005],
            [-90.5476, 30.9997],
            [-90.5481, 31.2035],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804080',
        NAME: 'South Pike School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619885327,
        AWATER: 2950471,
        INTPTLAT: '+31.1086884',
        INTPTLON: '-090.4147269',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0884, 34.786],
            [-88.8232, 34.7715],
            [-88.7531, 34.7862],
            [-88.7185, 34.7568],
            [-88.7178, 34.5965],
            [-88.7354, 34.5965],
            [-88.8247, 34.597],
            [-89.0041, 34.5985],
            [-89.0885, 34.5982],
            [-89.0884, 34.786],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804110',
        NAME: 'South Tippah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 680372813,
        AWATER: 2533600,
        INTPTLAT: '+34.6898759',
        INTPTLON: '-088.9103020',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9708, 33.3562],
            [-88.9594, 33.5061],
            [-88.7927, 33.5057],
            [-88.7949, 33.356],
            [-88.9708, 33.3562],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804140',
        NAME: 'Starkville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275417125,
        AWATER: 1972686,
        INTPTLAT: '+33.4323824',
        INTPTLON: '-088.8777268',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3409, 30.895],
            [-89.3408, 30.9099],
            [-89.138, 30.9099],
            [-88.885, 30.9108],
            [-88.8845, 30.7356],
            [-88.8845, 30.6773],
            [-89.2401, 30.6772],
            [-89.3414, 30.6477],
            [-89.3409, 30.895],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804170',
        NAME: 'Stone County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1153800122,
        AWATER: 6712353,
        INTPTLAT: '+30.7901840',
        INTPTLON: '-089.1122970',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7642, 33.2995],
            [-90.764, 33.3581],
            [-90.642, 33.3877],
            [-90.5631, 33.465],
            [-90.6294, 33.4985],
            [-90.6585, 33.5725],
            [-90.7634, 33.5731],
            [-90.6586, 33.6453],
            [-90.6583, 33.8507],
            [-90.6573, 33.9226],
            [-90.657, 33.9876],
            [-90.451, 33.9857],
            [-90.4516, 33.8114],
            [-90.4532, 33.3295],
            [-90.5555, 33.3292],
            [-90.5552, 33.2705],
            [-90.7163, 33.27],
            [-90.7642, 33.2995],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804200',
        NAME: 'Sunflower County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1505082344,
        AWATER: 20567667,
        INTPTLAT: '+33.6280605',
        INTPTLON: '-090.5664696',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2002, 34.7244],
            [-90.17, 34.7587],
            [-90.0291, 34.7082],
            [-89.9086, 34.7726],
            [-89.7234, 34.7723],
            [-89.6692, 34.6858],
            [-89.6684, 34.5544],
            [-89.7213, 34.5543],
            [-89.8984, 34.5537],
            [-89.898, 34.6266],
            [-90.0414, 34.6411],
            [-90.0315, 34.5541],
            [-90.1986, 34.5544],
            [-90.2439, 34.6022],
            [-90.2002, 34.7244],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804230',
        NAME: 'Tate County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 933865857,
        AWATER: 15698437,
        INTPTLAT: '+34.6566052',
        INTPTLON: '-089.9417090',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3635, 34.9955],
            [-88.2001, 34.9956],
            [-88.0979, 34.8922],
            [-88.14, 34.5817],
            [-88.1563, 34.4632],
            [-88.3292, 34.4636],
            [-88.3307, 34.7407],
            [-88.3655, 34.7556],
            [-88.3635, 34.9955],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804260',
        NAME: 'Tishomingo County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1098939230,
        AWATER: 52359814,
        INTPTLAT: '+34.7376972',
        INTPTLON: '-088.2360640',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.408, 34.833],
            [-90.3045, 34.8606],
            [-90.2021, 34.8604],
            [-90.2002, 34.7244],
            [-90.2439, 34.6022],
            [-90.1986, 34.5544],
            [-90.1985, 34.5111],
            [-90.3043, 34.5096],
            [-90.3019, 34.4245],
            [-90.3995, 34.4238],
            [-90.5693, 34.5249],
            [-90.5832, 34.6414],
            [-90.5172, 34.6309],
            [-90.408, 34.833],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804290',
        NAME: 'Tunica County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1177602384,
        AWATER: 67538891,
        INTPTLAT: '+34.6522011',
        INTPTLON: '-090.3717697',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8254, 34.2936],
            [-88.7396, 34.3464],
            [-88.6504, 34.309],
            [-88.6495, 34.237],
            [-88.7185, 34.203],
            [-88.8248, 34.206],
            [-88.8254, 34.2936],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804320',
        NAME: 'Tupelo Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186401562,
        AWATER: 1731447,
        INTPTLAT: '+34.2678518',
        INTPTLON: '-088.7326452',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2461, 34.4956],
            [-89.2456, 34.5832],
            [-89.0885, 34.5982],
            [-89.0041, 34.5985],
            [-89.0576, 34.4684],
            [-88.9467, 34.471],
            [-88.8247, 34.597],
            [-88.7354, 34.5965],
            [-88.7365, 34.5089],
            [-88.7894, 34.4949],
            [-88.825, 34.3648],
            [-88.9289, 34.3794],
            [-89.2463, 34.3793],
            [-89.2461, 34.4956],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804350',
        NAME: 'Union County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878045667,
        AWATER: 2880608,
        INTPTLAT: '+34.5359153',
        INTPTLON: '-089.1222263',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0343, 32.5769],
            [-89.0849, 32.5288],
            [-89.2521, 32.5322],
            [-89.2563, 32.5765],
            [-89.1786, 32.6351],
            [-89.0762, 32.627],
            [-89.0343, 32.5769],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804380',
        NAME: 'Union Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184022578,
        AWATER: 486663,
        INTPTLAT: '+32.5737491',
        INTPTLON: '-089.1687681',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2604, 31.3503],
            [-90.2439, 31.3503],
            [-90.0405, 31.336],
            [-90.0319, 31.1757],
            [-89.9382, 31.1329],
            [-89.9209, 31.0747],
            [-89.8359, 31.0749],
            [-89.8359, 31.002],
            [-90.2596, 31.0007],
            [-90.2605, 31.2042],
            [-90.2604, 31.3503],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804440',
        NAME: 'Walthall County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1046209912,
        AWATER: 1195914,
        INTPTLAT: '+31.1644920',
        INTPTLON: '-090.1034306',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.124, 32.2111],
            [-90.9834, 32.2118],
            [-90.9124, 32.3395],
            [-90.9867, 32.3518],
            [-91.0274, 32.4339],
            [-91.1167, 32.5001],
            [-91.0698, 32.5628],
            [-91.0436, 32.5763],
            [-90.964, 32.5756],
            [-90.9512, 32.4444],
            [-90.8614, 32.4421],
            [-90.8606, 32.5752],
            [-90.7263, 32.5699],
            [-90.7201, 32.6189],
            [-90.5538, 32.5076],
            [-90.6041, 32.4791],
            [-90.628, 32.3395],
            [-90.6836, 32.3528],
            [-90.7286, 32.2251],
            [-90.7748, 32.154],
            [-90.9568, 32.0836],
            [-91.0302, 32.1144],
            [-91.0308, 32.1206],
            [-91.0536, 32.1244],
            [-91.1628, 32.1327],
            [-91.124, 32.2111],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804470',
        NAME: 'Vicksburg Warren School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1524248408,
        AWATER: 77800995,
        INTPTLAT: '+32.3560946',
        INTPTLON: '-090.8523838',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7764, 34.1637],
            [-89.721, 34.1925],
            [-89.5094, 34.191],
            [-89.5097, 34.1621],
            [-89.5076, 34.0415],
            [-89.7757, 34.0722],
            [-89.7764, 34.1637],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804500',
        NAME: 'Water Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339036458,
        AWATER: 16250390,
        INTPTLAT: '+34.1201000',
        INTPTLON: '-089.6345623',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9434, 31.8246],
            [-88.9105, 31.8266],
            [-88.6224, 31.8572],
            [-88.4732, 31.8939],
            [-88.4644, 31.698],
            [-88.4494, 31.4358],
            [-88.8417, 31.4337],
            [-88.9435, 31.4335],
            [-88.9434, 31.8246],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804530',
        NAME: 'Wayne County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2099745578,
        AWATER: 7255476,
        INTPTLAT: '+31.6424754',
        INTPTLON: '-088.6782122',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5071, 33.7218],
            [-89.1918, 33.7386],
            [-89.0345, 33.7394],
            [-89.0186, 33.5621],
            [-89.0884, 33.5332],
            [-89.222, 33.5272],
            [-89.3811, 33.4603],
            [-89.5065, 33.4598],
            [-89.5069, 33.6776],
            [-89.5071, 33.7218],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804560',
        NAME: 'Webster County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1090223314,
        AWATER: 5935288,
        INTPTLAT: '+33.6120482',
        INTPTLON: '-089.2838356',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3227, 32.2235],
            [-89.1177, 32.224],
            [-89.1133, 31.8141],
            [-89.3166, 31.8021],
            [-89.3227, 32.2235],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804590',
        NAME: 'West Jasper School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 895935867,
        AWATER: 1616952,
        INTPTLAT: '+32.0183144',
        INTPTLON: '-089.2188239',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8266, 33.8116],
            [-88.746, 33.8109],
            [-88.72, 33.8113],
            [-88.7196, 33.6803],
            [-88.4971, 33.6802],
            [-88.5139, 33.6502],
            [-88.4781, 33.6064],
            [-88.4803, 33.5227],
            [-88.6713, 33.5062],
            [-88.7706, 33.5652],
            [-88.8448, 33.6056],
            [-88.8266, 33.8116],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804620',
        NAME: 'West Point School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 618525444,
        AWATER: 14264996,
        INTPTLAT: '+33.6397323',
        INTPTLON: '-088.6840444',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4516, 33.8114],
            [-90.451, 33.9857],
            [-90.451, 34.0729],
            [-90.1869, 34.073],
            [-90.187, 33.9976],
            [-90.2417, 33.9358],
            [-90.2414, 33.8408],
            [-90.1365, 33.8093],
            [-90.1368, 33.7216],
            [-90.1826, 33.8093],
            [-90.4516, 33.8114],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804650',
        NAME: 'West Tallahatchie School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 718762971,
        AWATER: 9810029,
        INTPTLAT: '+33.9200722',
        INTPTLON: '-090.3176323',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2336, 33.4435],
            [-91.2157, 33.5294],
            [-90.9715, 33.5303],
            [-90.9534, 33.2984],
            [-90.9491, 33.1113],
            [-90.9145, 33.0974],
            [-90.9143, 33.0077],
            [-91.0344, 32.9677],
            [-91.064, 32.9241],
            [-91.2128, 32.9221],
            [-91.1661, 33.0041],
            [-91.0876, 33.1452],
            [-91.1412, 33.2984],
            [-91.1304, 33.3571],
            [-91.0045, 33.388],
            [-91.0223, 33.4578],
            [-91.119, 33.4576],
            [-91.2336, 33.4435],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804680',
        NAME: 'Western Line School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 923607624,
        AWATER: 77349289,
        INTPTLAT: '+33.2011081',
        INTPTLON: '-091.0413037',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6369, 30.9994],
            [-91.5644, 31.039],
            [-91.6252, 31.116],
            [-91.5895, 31.1911],
            [-91.4788, 31.239],
            [-91.424, 31.315],
            [-91.3036, 31.361],
            [-91.1581, 31.3467],
            [-91.0954, 31.321],
            [-91.0594, 31.2819],
            [-91.0602, 30.9989],
            [-91.1761, 30.9991],
            [-91.6369, 30.9994],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804710',
        NAME: 'Wilkinson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1756260276,
        AWATER: 25019144,
        INTPTLAT: '+31.1610908',
        INTPTLON: '-091.3246169',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7852, 33.4738],
            [-89.6806, 33.4739],
            [-89.7477, 33.4166],
            [-89.7852, 33.4738],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804740',
        NAME: 'Winona Separate School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62223292,
        AWATER: 244610,
        INTPTLAT: '+33.4661771',
        INTPTLON: '-089.7481760',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4438, 32.8532],
            [-90.3801, 32.8952],
            [-90.3824, 32.8363],
            [-90.4438, 32.8532],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804770',
        NAME: 'Yazoo City Municipal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25479411,
        AWATER: 318250,
        INTPTLAT: '+32.8622192',
        INTPTLON: '-090.4079960',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6566, 32.9225],
            [-90.4499, 32.9233],
            [-90.4493, 33.009],
            [-90.364, 33.0106],
            [-89.9659, 32.8796],
            [-90.0424, 32.7441],
            [-90.099, 32.7007],
            [-90.2351, 32.6654],
            [-90.45, 32.5738],
            [-90.4841, 32.543],
            [-90.5538, 32.5076],
            [-90.7201, 32.6189],
            [-90.6931, 32.7543],
            [-90.7598, 32.8358],
            [-90.6566, 32.9225],
          ],
          [
            [-90.4438, 32.8532],
            [-90.3824, 32.8363],
            [-90.3801, 32.8952],
            [-90.4438, 32.8532],
          ],
        ],
      },
      properties: {
        STATEFP: '28',
        SCSDLEA: '',
        GEOID: '2804800',
        NAME: 'Yazoo County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2364942155,
        AWATER: 28998185,
        INTPTLAT: '+32.7656747',
        INTPTLON: '-090.3879282',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
  ],
};

/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faTint, faInfoCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const GLOBAL_FILTERS = {
  NONE: 'All students',
  ETHNICITY_WHITE: 'Ethnicity: White',
  ETHNICITY_HISPANIC: 'Ethnicity: Hispanic',
  ETHNICITY_BLACK: 'Ethnicity: Black',
  ETHNICITY_ASIAN: 'Ethnicity: Asian',
  ETHNICITY_OTHER: 'Ethnicity: Other',
  ELL: 'ELL',
  STUDENTS_WITH_DISABILITIES: 'Students w/ Disabilities',
  LOW_INCOME: 'Low Income',
};
const DashboardHeader = ({ visualizationDetails, onGlobalFilterChange, onShareALink }) => (
  <Row noGutters className="px-4 py-4 text-white bg-blue d-flex align-items-center">
    <Col md="3" className="d-flex">
      {visualizationDetails.globalFilter !== 'NONE' && (
        <OverlayTrigger overlay={<Tooltip id="map-hide">Filtered</Tooltip>} delay={200}>
          <FontAwesomeIcon color="#AC193C" icon={faFilter} className="mt-0 mr-5" size="lg" />
        </OverlayTrigger>
      )}
      <DropdownButton
        variant="secondary"
        id="order-by-dropdown"
        className="bg-blue"
        size="sm"
        title={`Filters: ${GLOBAL_FILTERS[visualizationDetails.globalFilter]}`}
      >
        {Object.keys(GLOBAL_FILTERS).map((k) => (
          <Dropdown.Item key={k} onClick={() => onGlobalFilterChange(k)}>
            {GLOBAL_FILTERS[k]}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Col>
    <Col md="6" className="d-flex justify-content-center align-items-center font-size-18">
      {visualizationDetails?.state?.name || 'National'}
      {visualizationDetails?.state?.district && ` - ${visualizationDetails?.state?.district?.name}`}
    </Col>
    <Col md="3" className="d-flex justify-content-end">
      <FontAwesomeIcon className="mr-5 cursor-pointer" size="lg" icon={faInfoCircle} />
      <FontAwesomeIcon
        className="mr-5 cursor-pointer"
        onClick={onShareALink}
        icon={faLink}
        size="lg"
      />
      <FontAwesomeIcon className="cursor-pointer" icon={faTint} size="lg" />
    </Col>
  </Row>
);

export default DashboardHeader;

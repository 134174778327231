export const getHeaders = (contentType = '', jwt = '', params = {}) => ({
  headers: {
    'Content-type': contentType,
    Authorization: `Bearer ${jwt}`,
  },
  params,
});

export const getHeadersIntegration = (contentType = '', jwt = '', params = {}) => ({
  headers: {
    'Content-type': contentType,
    'X-AUTH-TOKEN': jwt,
  },
  params,
});

// DASHBOARD METADATAS

/**
 * Sort the fetched metadatas by the business logic. This is:
 * If the selected visualization has a VisualizationMetadata, show that
 * metadata on index 0 of the array.
 *
 * This is used when iteration over allMetadatas on RadioContainer
 * @param {array()} arr - Array of all metadatas
 * @param {number} VisualizationMetadata - Id of the asociated Metadata of the visualization
 */

export const businessLogicSort = (arr = [], VisualizationMetadata) => {
  if (!VisualizationMetadata) return arr;
  if (arr.length <= 0) return arr;
  const newArr = [...arr];
  const selectedMetadata = newArr.filter((el) => el.Id === VisualizationMetadata);
  const index = newArr.findIndex((el) => el === selectedMetadata[0]);
  if (index >= 0) {
    newArr.splice(index, 1);
  }
  return [...selectedMetadata, ...newArr];
};

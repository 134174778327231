import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Card from '../Card';
import './style.css';

const cn = require('classnames');

const CardsGroup = ({ surveyData, surveyChild, id, isSurvey, isVisualization }) => {
  const { Data, Organization } = surveyData;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const collapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={cn('CardsGroup', { isCollapsed })}>
      <Accordion className="CardsGroup-accordion" defaultActiveKey={id}>
        {surveyChild && surveyChild.length > 0 && (
          <Accordion.Toggle className="CardsGroup-collapse-button" eventKey={id} onClick={collapse}>
            <img
              className="CardsGroup-arrow"
              src="/arrow-breadcrumbs-white.svg"
              alt="Dropdown Arrow"
            />
          </Accordion.Toggle>
        )}
        <div className="CardsGroup-container">
          <Card
            data={Data}
            organization={Organization}
            id={id}
            isVisualization={isVisualization}
            isSurvey={isSurvey}
            hasChildren={surveyChild && surveyChild.length > 0}
          />
          <Accordion.Collapse className="CardsGroup-collapse" eventKey={id}>
            <div>
              {surveyChild &&
                surveyChild.length > 0 &&
                surveyChild.map((child) => (
                  <Card
                    parentId={id}
                    data={child.Data}
                    organization={Organization}
                    id={child.Id}
                    isDraft={isSurvey}
                    isVisualization={isVisualization}
                    isSurvey={isSurvey}
                    isChild
                    key={child.Id}
                  />
                ))}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

CardsGroup.propTypes = {
  surveyData: PropTypes.shape({
    Id: PropTypes.number,
    Data: PropTypes.any,
    Organization: PropTypes.any,
  }).isRequired,
  id: PropTypes.number.isRequired,
  surveyChild: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
      Data: PropTypes.any,
    })
  ),
  isSurvey: PropTypes.bool,
  isVisualization: PropTypes.bool,
};

CardsGroup.defaultProps = {
  surveyChild: null,
  isSurvey: false,
  isVisualization: false,
};

export default CardsGroup;

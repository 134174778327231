import axios from 'axios';
import {
  createSurveyMetadataDescriptionApi,
  getMedatada,
  getSurveyMetadataDescriptionApi,
  getSurveyQuestionsBySurveyIdAndSectionApi,
  updateSurveyCustomNSizeApi,
  updateSurveyMetadataApi,
  updateSurveyMetadataDescriptionApi,
  updateSurveyThresholdCustomNSizeApi,
} from '../api/surveys';
import { API_ROOT } from '../config/appConfig';
import modalMessages from '../messages/modal.messages';
import types from '../types';
import { newError } from './errors.action';
import { getHeaders } from './helper.action';
import { openModal } from './modal.action';

export const showSnackbar = (id, snackBarType) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SHOW_SURVEY_SNACKBAR, id, snackBarType });
};

export const hideSnackbar = (id, snackBarType) => (dispatch) => {
  dispatch({ type: types.reducerTypes.HIDE_SURVEY_SNACKBAR, id, snackBarType });
};

export const isFetchingSurveys = (isFetchingSurveysParam = false) => (dispatch) => {
  dispatch({
    type: types.reducerTypes.IS_FETCHING_SURVEYS,
    isFetchingSurveys: isFetchingSurveysParam,
  });
};

export const showSpinner = (type, spinnerActionType, showSpinnerParam, Id) => (dispatch) => {
  dispatch({ type, payload: { spinnerActionType, showSpinner: showSpinnerParam, spinnerId: Id } });
};

export const getAllSurveys = (jwt, orgId) => async (dispatch) => {
  dispatch(isFetchingSurveys(true));
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/serie-metadata/filtered${orgId ? `?organization=${orgId}` : ''}`,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      dispatch(isFetchingSurveys(false));
      dispatch({ type: types.reducerTypes.GET_ALL_SURVEYS, surveys: res.data });
      return;
    }
  } catch (err) {
    console.error(err);
    dispatch(isFetchingSurveys(false));
    dispatch(newError(types.errorTypes.surveyFetchErr));
  }
};

export const updateSurveyTitle = (id, title, jwt) => async (dispatch) => {
  const data = {
    Data: {
      title,
    },
  };
  try {
    const res = await axios.patch(
      `${API_ROOT}/admin-backend/serie-metadata/${id}`,
      data,
      getHeaders('application/json', jwt)
    );
    if (res.status === 200) {
      const resTitle = res.data.newValues.title;
      const { Id } = res.data;
      dispatch({ type: types.reducerTypes.SURVEY_TITLE_UPDATED, resTitle, Id });
    }
  } catch (err) {
    console.error(err);
  }
};

export const publishSurvey = (id, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.publishSurvey, true, id)
  );
  try {
    const res = await axios.patch(
      `${API_ROOT}/admin-backend/serie-metadata/${id}/publish`,
      {},
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      dispatch({ type: types.reducerTypes.SURVEY_PUBLISHED, publishedSurvey: res.data });
      dispatch(
        showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.publishSurvey, false, id)
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.publishSurvey, false, id)
    );
    dispatch(openModal(modalMessages('errorPublishSurvey')));
  }
};

export const unpublishSurvey = (id, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.publishSurvey, true, id)
  );
  try {
    const res = await axios.patch(
      `${API_ROOT}/admin-backend/serie-metadata/${id}/unpublish`,
      {},
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      dispatch({ type: types.reducerTypes.SURVEY_UNPUBLISHED, publishedSurvey: { Id: id } });
      dispatch(
        showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.publishSurvey, false, id)
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.publishSurvey, false, id)
    );
    dispatch(openModal(modalMessages('errorPublishSurvey')));
  }
};

export const createDraftSurvey = (id, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.createDraftSurvey, true, id)
  );
  try {
    const res = await axios.post(
      `${API_ROOT}/admin-backend/draft/${id}`,
      {},
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      const updatedParent = await getMedatada(res.data.Id, jwt);
      dispatch({ type: types.reducerTypes.DRAFT_SURVEY_CREATED, updatedParent });
      dispatch(
        showSpinner(
          types.reducerTypes.SURVEY_SPINNER,
          types.spinnerTypes.createDraftSurvey,
          false,
          id
        )
      );
      return;
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(
        types.reducerTypes.SURVEY_SPINNER,
        types.spinnerTypes.createDraftSurvey,
        false,
        id
      )
    );
    dispatch(openModal(modalMessages('errorCreateDraftSurvey')));
  }
};

export const convertDraftIntoSurvey = (id, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(
      types.reducerTypes.SURVEY_SPINNER,
      types.spinnerTypes.convertDraftIntoSurvey,
      true,
      id
    )
  );
  try {
    const res = await axios.patch(
      `${API_ROOT}/admin-backend/serie-metadata/${id}/publish`,
      { Data: { isPublished: true } },
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      const { Id, Parent } = res.data;
      const updatedSurvey = await getMedatada(Id, jwt);
      dispatch({ type: types.reducerTypes.DRAFT_SURVEY_CONVERTED, updatedSurvey, Parent });
      dispatch(showSnackbar(Id, 'surveyConverted'));
      dispatch(
        showSpinner(
          types.reducerTypes.SURVEY_SPINNER,
          types.spinnerTypes.convertDraftIntoSurvey,
          false,
          id
        )
      );
      setTimeout(() => {
        dispatch(hideSnackbar(Id, 'surveyConverted'));
      }, 1000 * 30);
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(
        types.reducerTypes.SURVEY_SPINNER,
        types.spinnerTypes.convertDraftIntoSurvey,
        false,
        id
      )
    );
    dispatch(openModal(modalMessages('errorConvertDraftIntoSurvey')));
  }
};

export const deleteDraftSurvey = (id, isDraft, parentId, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(types.reducerTypes.SURVEY_SPINNER, types.spinnerTypes.deleteDraftSurvey, true, id)
  );
  try {
    const url = `${API_ROOT}/api/query/surveys/${id}`;
    const res = await axios.delete(url, getHeaders('application/json', jwt));

    if (res.status === 200 || res.status === 204) {
      if (isDraft) {
        dispatch({
          type: types.reducerTypes.DRAFT_DELETED,
          surveyToRemove: { draftSurveyId: id, parentId },
        });
      } else {
        dispatch({ type: types.reducerTypes.SURVEY_DELETED, id });
      }
      dispatch(
        showSpinner(
          types.reducerTypes.SURVEY_SPINNER,
          types.spinnerTypes.deleteDraftSurvey,
          false,
          id
        )
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(
        types.reducerTypes.SURVEY_SPINNER,
        types.spinnerTypes.deleteDraftSurvey,
        false,
        id
      )
    );
    dispatch(openModal(modalMessages('errorDeleteDraftSurvey')));
  }
};

export const getSurveyQuestionsBySurveyIdAndSection = (surveyId, sectionCode, jwt) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_QUESTIONS_SURVEY_ID_SECTION_PENDING });
    const data = await getSurveyQuestionsBySurveyIdAndSectionApi(surveyId, sectionCode, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_QUESTIONS_SURVEY_ID_SECTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: types.reducerTypes.SURVEY_QUESTIONS_SURVEY_ID_SECTION_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const updateSurveyCustomNSize = (surveyId, exactNSize, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_UPDATE_CUSTOM_N_SIZE_PENDING });
    await updateSurveyCustomNSizeApi(surveyId, exactNSize, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_CUSTOM_N_SIZE_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_CUSTOM_N_SIZE_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const updateSurveyThresholdCustomNSize = (surveyId, threshold, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_PENDING });
    await updateSurveyThresholdCustomNSizeApi(surveyId, threshold, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const updateSurveyMetadata = (surveyId, metadata, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_UPDATE_METADATA_PENDING });
    await updateSurveyMetadataApi(surveyId, metadata, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_METADATA_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_METADATA_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const updateSurveyMetadataDescription = (surveyId, metadata, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION_PENDING });
    await updateSurveyMetadataDescriptionApi(surveyId, metadata, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const createSurveyMetadataDescription = (metadata, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_CREATE_METADATA_DESCRIPTION_PENDING });
    await createSurveyMetadataDescriptionApi(metadata, jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_CREATE_METADATA_DESCRIPTION_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_CREATE_METADATA_DESCRIPTION_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const getSurveyMetadataDescription = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_GET_METADATA_DESCRIPTION_PENDING });
    const data = await getSurveyMetadataDescriptionApi(jwt);
    dispatch({
      type: types.reducerTypes.SURVEY_GET_METADATA_DESCRIPTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SURVEY_GET_METADATA_DESCRIPTION_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

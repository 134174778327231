class DashboardVisualizationDetails {
  constructor(details) {
    this.scheme = details.scheme;
    this.components = details.components;
    this.state = details.state;
    this.type = 3;
    this.globalFilter = details.globalFilter;
  }

  getFilter() {
    return this.globalFilter;
  }

  getGlobalFilter() {
    return this.globalFilter;
  }

  isFiltered() {
    return this.globalFilter !== 'NONE';
  }

  getType() {
    return this.type;
  }

  getScheme() {
    return this.scheme;
  }

  getState() {
    return this.state;
  }

  getComponents() {
    return this.components;
  }

  getComponent(id) {
    return (
      this.components && this.components.length > 0 && this.components.find((c) => c.id === id)
    );
  }

  toString() {
    return `DashboardVisualizationDetails [globalFilter=${this.globalFilter}, type=${this.type}, scheme=${this.scheme}]`;
  }

  equals(obj) {
    if (this === obj) {
      return true;
    }
    if (obj == null) {
      return false;
    }
    if (!(obj instanceof DashboardVisualizationDetails)) {
      return false;
    }
    const other = obj;
    if (this.enableDefaultSegment !== other.enableDefaultSegment) {
      return false;
    }
    if (this.globalFilter == null) {
      if (other.globalFilter != null) {
        return false;
      }
    } else if (!this.globalFilter === other.globalFilter) {
      return false;
    }
    if (this.segments == null) {
      if (other.segments != null) {
        return false;
      }
    } else if (!this.segments.equals(other.segments)) {
      return false;
    }
    return true;
  }
}

export default DashboardVisualizationDetails;

/* eslint-disable consistent-return */
import axios from 'axios';
import queryStringParser from 'query-string';
import { API_ROOT } from '../config/appConfig';

export const createReportApi = async (report, jwt) => {
  try {
    const res = await axios.post(`${API_ROOT}/api/query/report`, report, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    console.error(err);
  }
};

export const addToReportApi = async (id, reportItems, jwt) => {
  try {
    const res = await axios.post(`${API_ROOT}/api/query/report/${id}/add`, reportItems, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getReportDetailsApi = async (id, jwt) => {
  try {
    const res = await axios.get(`${API_ROOT}/api/query/report/${id}`, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getSharedReportDetailsApi = async (shareToken, jwt) => {
  try {
    const res = await axios.get(`${API_ROOT}/api/query/report/shared/${shareToken}`, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getUserReportsApi = async (
  jwt,
  orderBy = '',
  orderDirection = '',
  filterText = '',
  filterFav = false,
  originalCreator = ''
) => {
  try {
    const filters = {};
    if (orderBy) {
      filters.orderBy = orderBy;
    }
    if (orderDirection) {
      filters.orderAsc = orderDirection;
    }
    if (filterText) {
      filters.filterText = filterText;
    }
    if (filterFav) {
      filters.starred = filterFav;
    }
    if (originalCreator) {
      filters.originalCreator = originalCreator;
    }
    const filterString = queryStringParser.stringify(filters);
    const res = await axios.get(
      `${API_ROOT}/api/query/reports${filterString ? `?${filterString}` : ''}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const shareReportApi = async (id, jwt) => {
  try {
    const res = await axios.get(`${API_ROOT}/api/query/report/${id}/share`, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteReportApi = async (id, jwt) => {
  try {
    await axios.delete(`${API_ROOT}/api/query/report/${id}`, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const updateUserReportApi = async (report, jwt) => {
  try {
    const res = await axios.post(`${API_ROOT}/api/query/report`, report, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteReportItemApi = async (id, itemId, jwt) => {
  try {
    await axios.delete(`${API_ROOT}/api/query/report/${id}/${itemId}`, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const saveCopyApi = async (id, jwt) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/api/query/report/shared/${id}/copy`,
      {},
      {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const AK = {
  type: 'FeatureCollection',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200001',
        NAME: 'Lower Kuskokwim School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55122350876,
        AWATER: 12872801469,
        INTPTLAT: '+60.2124478',
        INTPTLON: '-163.4361460',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-165.9441, 61.282],
              [-163.9893, 61.2818],
              [-162.3387, 61.2826],
              [-160.7534, 61.282],
              [-159.4205, 60.9989],
              [-158.8816, 60.9034],
              [-159.0159, 60.7319],
              [-159.183, 60.7311],
              [-159.1852, 60.6446],
              [-159.3672, 60.6443],
              [-159.3696, 60.4711],
              [-159.437, 60.3872],
              [-159.6136, 60.3878],
              [-159.6134, 60.2146],
              [-159.7845, 60.2133],
              [-159.8503, 60.1284],
              [-159.8553, 59.9637],
              [-160.0152, 59.9589],
              [-160.0194, 59.7868],
              [-160.2482, 59.7845],
              [-160.2507, 59.6113],
              [-160.4245, 59.6115],
              [-160.425, 59.4359],
              [-160.4805, 59.3522],
              [-160.6508, 59.3492],
              [-160.6503, 59.2622],
              [-160.8209, 59.2615],
              [-160.8191, 59.0849],
              [-160.8743, 59.0037],
              [-161.0349, 59.0034],
              [-161.0345, 58.8386],
              [-161.3675, 58.8232],
              [-161.3687, 58.7356],
              [-161.1974, 58.7344],
              [-161.3369, 58.6084],
              [-161.4578, 58.586],
              [-161.6402, 58.5023],
              [-161.8176, 58.5057],
              [-161.8651, 58.5661],
              [-162.1322, 58.5714],
              [-162.2704, 58.6221],
              [-162.1685, 58.7103],
              [-161.963, 58.7262],
              [-161.8512, 58.808],
              [-161.8818, 58.9441],
              [-161.9533, 59.0327],
              [-162.1022, 59.1288],
              [-162.1666, 59.2751],
              [-162.0536, 59.409],
              [-161.8713, 59.5248],
              [-162.1783, 59.8148],
              [-162.3643, 59.8109],
              [-162.4498, 59.8846],
              [-162.7076, 59.8802],
              [-162.8687, 59.7804],
              [-163.1459, 59.7956],
              [-163.33, 59.7697],
              [-163.7844, 59.7406],
              [-163.761, 59.6397],
              [-164.0133, 59.6378],
              [-164.231, 59.7531],
              [-164.4157, 59.8849],
              [-164.4497, 59.9533],
              [-164.3643, 59.9992],
              [-164.4882, 60.055],
              [-164.5974, 60.1582],
              [-164.7488, 60.2403],
              [-164.9719, 60.277],
              [-165.2354, 60.4251],
              [-165.4265, 60.4744],
              [-165.5183, 60.566],
              [-165.1234, 60.7366],
              [-165.1705, 60.8769],
              [-165.4167, 61.0125],
              [-165.6637, 61.0578],
              [-165.8376, 61.2387],
              [-165.9441, 61.282],
            ],
            [
              [-161.4732, 60.8788],
              [-161.3805, 60.8772],
              [-161.372, 60.9215],
              [-161.47, 60.9206],
              [-161.4732, 60.8788],
            ],
          ],
          [
            [
              [-167.5582, 60.2244],
              [-167.3098, 60.2907],
              [-166.9438, 60.2781],
              [-166.7393, 60.3879],
              [-166.5288, 60.4443],
              [-166.3498, 60.4177],
              [-166.1524, 60.5107],
              [-166.0058, 60.4196],
              [-165.7385, 60.4056],
              [-165.5615, 60.3017],
              [-165.5732, 60.0751],
              [-165.4025, 59.9704],
              [-165.4955, 59.8702],
              [-165.8249, 59.8067],
              [-166.0579, 59.6955],
              [-166.1966, 59.7034],
              [-166.4297, 59.8016],
              [-166.6261, 59.7971],
              [-166.8289, 59.8523],
              [-166.9406, 59.9119],
              [-167.1378, 59.944],
              [-167.3856, 60.0212],
              [-167.5582, 60.2244],
            ],
          ],
          [
            [
              [-172.8857, 60.2088],
              [-172.6986, 60.2584],
              [-172.6343, 60.2007],
              [-172.7995, 60.1388],
              [-172.8857, 60.2088],
            ],
          ],
          [
            [
              [-173.2322, 60.6543],
              [-173.1586, 60.7288],
              [-172.9898, 60.7318],
              [-172.9438, 60.6507],
              [-172.6878, 60.4801],
              [-172.3936, 60.4365],
              [-172.1091, 60.314],
              [-172.1895, 60.2508],
              [-172.4075, 60.2788],
              [-172.5961, 60.2586],
              [-172.9102, 60.3396],
              [-172.9315, 60.3897],
              [-173.1678, 60.4811],
              [-173.1402, 60.5755],
              [-173.2322, 60.6543],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200003',
        NAME: 'Lower Yukon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45612599255,
        AWATER: 5858691172,
        INTPTLAT: '+62.2830603',
        INTPTLON: '-163.1909034',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-162.6664, 63.3169],
              [-162.5875, 63.2756],
              [-162.3777, 63.2729],
              [-162.3741, 63.1864],
              [-162.1847, 63.1864],
              [-162.1775, 63.0965],
              [-161.99, 63.096],
              [-161.9909, 63.0127],
              [-160.8511, 63.0127],
              [-160.9419, 62.9989],
              [-160.994, 62.8944],
              [-161.0004, 62.5518],
              [-161.0453, 62.5504],
              [-161.0478, 62.2067],
              [-160.9102, 62.2044],
              [-160.9083, 61.9494],
              [-160.7534, 61.282],
              [-162.3387, 61.2826],
              [-163.9893, 61.2818],
              [-165.9441, 61.282],
              [-166.0313, 61.3953],
              [-166.1945, 61.4599],
              [-166.2886, 61.5808],
              [-166.1978, 61.8343],
              [-166.1125, 61.8731],
              [-166.0759, 61.983],
              [-166.1261, 62.0387],
              [-166.0492, 62.108],
              [-165.7396, 62.2049],
              [-165.4489, 62.391],
              [-165.3387, 62.576],
              [-165.2393, 62.6341],
              [-165.2219, 62.7225],
              [-165.0219, 62.7562],
              [-165.0381, 62.8206],
              [-164.8955, 62.9486],
              [-164.9067, 63.0449],
              [-164.4957, 63.2512],
              [-164.25, 63.3035],
              [-164.0513, 63.3174],
              [-163.6581, 63.2497],
              [-163.2525, 63.0925],
              [-163.1187, 63.1016],
              [-162.8709, 63.2536],
              [-162.6664, 63.3169],
            ],
            [
              [-165.6066, 61.5208],
              [-165.5842, 61.5208],
              [-165.57, 61.5354],
              [-165.6061, 61.5356],
              [-165.6066, 61.5208],
            ],
            [
              [-163.4672, 62.0802],
              [-163.3405, 62.0326],
              [-163.1158, 62.0317],
              [-163.117, 62.1039],
              [-163.4672, 62.0802],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200004',
        NAME: 'Yupiit School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32692679,
        AWATER: 10775866,
        INTPTLAT: '+60.9064067',
        INTPTLON: '-161.3998618',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-161.4732, 60.8788],
              [-161.47, 60.9206],
              [-161.372, 60.9215],
              [-161.3805, 60.8772],
              [-161.4732, 60.8788],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200005',
        NAME: 'Kashunamiut School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2562639,
        AWATER: 54920,
        INTPTLAT: '+61.5310657',
        INTPTLON: '-165.5971990',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-165.6066, 61.5208],
              [-165.6061, 61.5356],
              [-165.57, 61.5354],
              [-165.5842, 61.5208],
              [-165.6066, 61.5208],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200007',
        NAME: 'Aleutians East Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18082872046,
        AWATER: 20793145623,
        INTPTLAT: '+55.2450437',
        INTPTLON: '-161.9974772',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-159.9103, 54.8142],
              [-159.7611, 54.8913],
              [-159.5561, 54.8803],
              [-159.4168, 54.7922],
              [-159.5479, 54.6993],
              [-159.8552, 54.7577],
              [-159.9103, 54.8142],
            ],
          ],
          [
            [
              [-162.819, 54.8112],
              [-162.7432, 54.8579],
              [-162.6372, 54.8048],
              [-162.7402, 54.7531],
              [-162.819, 54.8112],
            ],
          ],
          [
            [
              [-163.0137, 54.3851],
              [-162.9388, 54.5211],
              [-162.8807, 54.5532],
              [-162.3544, 54.4567],
              [-162.2482, 54.3993],
              [-162.2822, 54.331],
              [-162.495, 54.2917],
              [-162.5631, 54.2436],
              [-162.7001, 54.238],
              [-162.8179, 54.3148],
              [-162.9526, 54.3257],
              [-163.0137, 54.3851],
            ],
          ],
          [
            [
              [-163.2709, 55.4317],
              [-163.2425, 55.5177],
              [-163.1365, 55.525],
              [-163.0279, 55.4208],
              [-163.071, 55.3613],
              [-163.2354, 55.3661],
              [-163.2709, 55.4317],
            ],
          ],
          [
            [
              [-158.8934, 56.9451],
              [-158.891, 56.7542],
              [-159.0286, 56.7541],
              [-159.0286, 56.6694],
              [-159.2755, 56.6718],
              [-159.2755, 56.5809],
              [-159.5927, 56.5809],
              [-159.5934, 56.4945],
              [-159.8994, 56.492],
              [-159.9037, 56.3226],
              [-159.8097, 56.3217],
              [-159.8053, 55.9826],
              [-159.8681, 55.8885],
              [-159.5647, 55.8885],
              [-159.5644, 55.6289],
              [-159.458, 55.6289],
              [-159.5382, 55.532],
              [-159.9511, 55.5076],
              [-160.0412, 55.4489],
              [-159.9326, 55.337],
              [-159.8433, 55.34],
              [-159.6602, 55.2429],
              [-159.5443, 55.3284],
              [-159.3981, 55.2934],
              [-159.4109, 55.1235],
              [-159.2528, 55.1062],
              [-159.2515, 55.0067],
              [-159.1118, 54.9157],
              [-159.2015, 54.8337],
              [-159.3623, 54.8283],
              [-159.5033, 54.9008],
              [-159.5803, 54.9876],
              [-159.7347, 54.9902],
              [-159.8461, 54.9024],
              [-160.098, 54.8854],
              [-160.2077, 54.8073],
              [-160.3028, 54.8297],
              [-160.3434, 54.9309],
              [-160.2619, 55.0352],
              [-160.271, 55.1389],
              [-160.3536, 55.1937],
              [-160.5195, 55.0251],
              [-160.5987, 55.0941],
              [-160.7864, 55.0708],
              [-160.8997, 55.0953],
              [-160.9534, 55.3254],
              [-161.0804, 55.2144],
              [-161.2485, 55.1933],
              [-161.2465, 55.127],
              [-161.4701, 54.9497],
              [-161.6595, 54.9564],
              [-161.6676, 54.8829],
              [-161.5647, 54.7972],
              [-161.841, 54.6845],
              [-161.9668, 54.7148],
              [-162.1048, 54.6183],
              [-162.2394, 54.6387],
              [-162.365, 54.5077],
              [-162.4798, 54.5409],
              [-162.4599, 54.6665],
              [-162.5549, 54.7493],
              [-162.5126, 54.8115],
              [-162.6189, 54.8452],
              [-162.6511, 54.9222],
              [-162.8939, 54.8442],
              [-163.0632, 54.8795],
              [-163.1049, 54.8094],
              [-162.9686, 54.7087],
              [-163.0591, 54.6062],
              [-163.4139, 54.6004],
              [-163.5936, 54.5411],
              [-163.7128, 54.5702],
              [-164.1227, 54.5561],
              [-164.2279, 54.5237],
              [-164.2767, 54.4372],
              [-164.415, 54.3688],
              [-164.6171, 54.3389],
              [-164.9013, 54.3747],
              [-164.9956, 54.4573],
              [-165.0362, 54.5766],
              [-164.9995, 54.6262],
              [-164.7828, 54.6917],
              [-164.6143, 54.9239],
              [-164.4182, 54.9796],
              [-164.3075, 54.9515],
              [-164.1579, 55.0157],
              [-164.0474, 55.0207],
              [-163.9502, 55.0788],
              [-163.7703, 55.1088],
              [-163.6307, 55.0932],
              [-163.4589, 55.1281],
              [-163.1505, 55.2295],
              [-162.9352, 55.3821],
              [-162.5472, 55.5287],
              [-162.2898, 55.7249],
              [-161.8257, 55.9427],
              [-161.4287, 56.0116],
              [-161.2111, 56.0674],
              [-160.9296, 56.0794],
              [-160.616, 56.0674],
              [-160.4132, 56.3217],
              [-160.2254, 56.4336],
              [-160.0691, 56.4803],
              [-159.9051, 56.5782],
              [-159.581, 56.6729],
              [-159.1739, 56.8145],
              [-158.8934, 56.9451],
            ],
          ],
          [
            [
              [-166.2256, 54.042],
              [-166.1651, 54.1951],
              [-165.9372, 54.277],
              [-165.7891, 54.2391],
              [-165.6556, 54.3471],
              [-165.4625, 54.3468],
              [-165.3452, 54.251],
              [-165.0669, 54.1801],
              [-164.8054, 54.2824],
              [-164.6878, 54.2501],
              [-164.7145, 54.1661],
              [-164.8219, 54.1308],
              [-164.881, 54.0434],
              [-165.0273, 54.0129],
              [-165.1845, 54.017],
              [-165.2696, 53.9807],
              [-165.381, 54.0164],
              [-165.5198, 53.9721],
              [-165.6572, 54.0363],
              [-165.789, 54.0162],
              [-165.961, 53.9161],
              [-166.2256, 53.9602],
              [-166.2256, 54.042],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200020',
        NAME: 'Bering Strait School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59441046136,
        AWATER: 13744707835,
        INTPTLAT: '+64.7836858',
        INTPTLON: '-164.1889119',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-161.4369, 64.1336],
              [-161.3104, 64.1894],
              [-161.1767, 64.1469],
              [-161.2217, 64.0753],
              [-161.4369, 64.1336],
            ],
          ],
          [
            [
              [-168.214, 64.9843],
              [-168.1562, 65.0292],
              [-167.9243, 65.0027],
              [-167.9531, 64.933],
              [-168.137, 64.9184],
              [-168.214, 64.9843],
            ],
          ],
          [
            [
              [-164.4635, 66.6263],
              [-164.3189, 66.4763],
              [-164.3189, 66.1299],
              [-164.2442, 66.1299],
              [-164.2442, 65.7836],
              [-163.9635, 65.7836],
              [-163.9635, 65.6104],
              [-163.7554, 65.6104],
              [-163.7554, 65.4372],
              [-162.7845, 65.4371],
              [-161.0586, 65.437],
              [-159.8017, 65.4372],
              [-159.8017, 65.5238],
              [-159.5935, 65.5239],
              [-159.3867, 65.5231],
              [-159.3848, 65.2617],
              [-159.5786, 65.2627],
              [-159.5769, 64.9237],
              [-159.7772, 64.9231],
              [-159.7738, 64.7503],
              [-159.9781, 64.7485],
              [-159.9564, 64.051],
              [-159.7312, 64.0524],
              [-159.7184, 63.9547],
              [-159.6993, 63.7923],
              [-159.9308, 63.7938],
              [-159.9292, 63.7035],
              [-160.1037, 63.7023],
              [-160.0979, 63.6144],
              [-160.4935, 63.6137],
              [-160.495, 63.5371],
              [-160.6921, 63.5366],
              [-160.6713, 63.2708],
              [-160.8473, 63.2692],
              [-160.8511, 63.0127],
              [-161.9909, 63.0127],
              [-161.99, 63.096],
              [-162.1775, 63.0965],
              [-162.1847, 63.1864],
              [-162.3741, 63.1864],
              [-162.3777, 63.2729],
              [-162.5875, 63.2756],
              [-162.6664, 63.3169],
              [-162.4378, 63.492],
              [-162.6798, 63.4916],
              [-162.8264, 63.5764],
              [-162.5843, 63.6914],
              [-162.3944, 63.6883],
              [-162.2227, 63.5969],
              [-161.957, 63.5572],
              [-161.8404, 63.4933],
              [-161.3144, 63.5192],
              [-161.0242, 63.6718],
              [-160.8862, 63.7714],
              [-160.9446, 63.9215],
              [-161.0457, 64.0225],
              [-161.0754, 64.2067],
              [-161.4139, 64.3586],
              [-161.5908, 64.3355],
              [-162.3135, 64.5428],
              [-162.4867, 64.4709],
              [-162.5179, 64.3849],
              [-162.7958, 64.2757],
              [-163.2419, 64.3607],
              [-163.3638, 64.4456],
              [-163.657, 64.5219],
              [-164.3554, 64.5084],
              [-164.7548, 64.4078],
              [-165.0322, 64.388],
              [-165.3359, 64.4444],
              [-166.0778, 64.5205],
              [-166.1539, 64.433],
              [-166.3511, 64.4779],
              [-166.2814, 64.545],
              [-166.5078, 64.6314],
              [-166.5902, 64.7389],
              [-166.5892, 64.9083],
              [-166.979, 65.0694],
              [-167.0843, 65.1557],
              [-167.0085, 65.2767],
              [-167.0703, 65.3275],
              [-167.4009, 65.3473],
              [-168.1676, 65.5469],
              [-168.2319, 65.6226],
              [-168.1551, 65.7284],
              [-167.5208, 65.8782],
              [-166.7195, 66.148],
              [-165.7777, 66.3974],
              [-165.4344, 66.4752],
              [-164.7519, 66.5925],
              [-164.4635, 66.6263],
            ],
            [
              [-165.5138, 64.485],
              [-165.3536, 64.463],
              [-165.3599, 64.5491],
              [-165.5138, 64.485],
            ],
          ],
          [
            [
              [-168.9717, 65.7139],
              [-168.971, 65.8146],
              [-168.7625, 65.7512],
              [-168.9717, 65.7139],
            ],
          ],
          [
            [
              [-171.9547, 63.5209],
              [-171.7764, 63.8377],
              [-171.5488, 63.8005],
              [-171.5356, 63.7219],
              [-170.9592, 63.6259],
              [-170.7052, 63.7131],
              [-170.504, 63.7534],
              [-170.2968, 63.7556],
              [-169.9856, 63.623],
              [-169.9266, 63.5267],
              [-169.6023, 63.4806],
              [-169.4555, 63.415],
              [-169.035, 63.3875],
              [-168.6428, 63.3449],
              [-168.6093, 63.2294],
              [-168.7335, 63.1316],
              [-168.6828, 63.0847],
              [-168.8032, 63.0177],
              [-168.9228, 63.0996],
              [-169.0667, 63.1282],
              [-169.2823, 63.1144],
              [-169.4511, 63.0386],
              [-169.4199, 62.9739],
              [-169.591, 62.8879],
              [-169.839, 62.9259],
              [-169.9014, 63.0374],
              [-170.1154, 63.1272],
              [-170.328, 63.1387],
              [-170.5153, 63.2879],
              [-170.9082, 63.3699],
              [-171.0668, 63.3711],
              [-171.2514, 63.2926],
              [-171.4819, 63.2545],
              [-171.7723, 63.3153],
              [-171.8849, 63.3776],
              [-171.9547, 63.5209],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200030',
        NAME: 'Bristol Bay Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1304962195,
        AWATER: 993834934,
        INTPTLAT: '+58.7301584',
        INTPTLON: '-156.9966325',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-157.158, 58.8602],
              [-157.0706, 58.8878],
              [-156.3181, 58.8949],
              [-156.3177, 58.6096],
              [-158.0015, 58.6099],
              [-158.001, 58.6453],
              [-157.5506, 58.7545],
              [-157.3013, 58.8371],
              [-157.158, 58.8602],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200050',
        NAME: 'Alaska Gateway School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48243712657,
        AWATER: 333974755,
        INTPTLAT: '+63.5645308',
        INTPTLON: '-142.7339366',
        ELSDLEA: '',
        UNSDLEA: '00050',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-144.6551, 63.1336],
              [-144.6545, 63.4602],
              [-144.6298, 63.8486],
              [-144.6212, 64.5968],
              [-144.4624, 64.5829],
              [-144.3076, 64.6419],
              [-144.0465, 64.6668],
              [-144.1201, 64.7983],
              [-143.9958, 64.9431],
              [-144.0865, 65.0005],
              [-143.1509, 64.9997],
              [-142.2177, 65.0004],
              [-141.0023, 65.0001],
              [-141.0023, 64.5201],
              [-141.0023, 63.7969],
              [-141.0022, 62.9776],
              [-141.0023, 61.9019],
              [-142.2982, 62.3409],
              [-143.6954, 62.8152],
              [-144.6551, 63.1336],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200060',
        NAME: 'Cordova City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155311802,
        AWATER: 40634605,
        INTPTLAT: '+60.5301380',
        INTPTLON: '-145.6420649',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-145.8801, 60.5023],
              [-145.6721, 60.6393],
              [-145.5796, 60.5341],
              [-145.4156, 60.5343],
              [-145.417, 60.4638],
              [-145.694, 60.516],
              [-145.8801, 60.5023],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200070',
        NAME: 'Copper River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60955225040,
        AWATER: 1181434174,
        INTPTLAT: '+61.7953012',
        INTPTLON: '-144.1340370',
        ELSDLEA: '',
        UNSDLEA: '00070',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-147.206, 61.4253],
              [-147.2057, 61.4751],
              [-146.9451, 61.4743],
              [-146.9616, 62.1602],
              [-146.9817, 62.2487],
              [-146.4263, 62.2472],
              [-146.4381, 62.5111],
              [-146.4286, 62.8633],
              [-146.481, 62.9597],
              [-146.4841, 63.1733],
              [-146.1408, 63.184],
              [-146.1112, 63.2212],
              [-145.1489, 63.2222],
              [-145.1484, 63.1336],
              [-144.6551, 63.1336],
              [-143.6954, 62.8152],
              [-142.2982, 62.3409],
              [-141.0023, 61.9019],
              [-141.002, 61.3036],
              [-141.0019, 60.3917],
              [-141.2137, 60.4358],
              [-141.7603, 60.4342],
              [-141.7656, 60.5277],
              [-141.9631, 60.5214],
              [-141.9627, 60.6077],
              [-142.8619, 60.5954],
              [-142.8716, 60.789],
              [-143.4081, 60.7607],
              [-143.408, 60.8506],
              [-143.7132, 60.8504],
              [-143.7161, 61.0378],
              [-144.427, 61.0376],
              [-144.4161, 61.212],
              [-144.9874, 61.2102],
              [-146.2041, 61.2107],
              [-146.2061, 61.2981],
              [-147.2042, 61.297],
              [-147.206, 61.4253],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200090',
        NAME: 'Craig City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18654310,
        AWATER: 6010703,
        INTPTLAT: '+55.4882325',
        INTPTLON: '-133.1110690',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-133.1549, 55.4947],
              [-133.1081, 55.4952],
              [-133.1082, 55.4554],
              [-133.1577, 55.4549],
              [-133.1549, 55.4947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200100',
        NAME: 'Delta/Greely School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14544307346,
        AWATER: 350469648,
        INTPTLAT: '+63.8254742',
        INTPTLON: '-145.6983167',
        ELSDLEA: '',
        UNSDLEA: '00100',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-147.003, 64.2572],
              [-146.6458, 64.2572],
              [-146.3573, 64.2785],
              [-146.1568, 64.3861],
              [-146.001, 64.3837],
              [-145.7386, 64.4604],
              [-145.5313, 64.4177],
              [-145.3605, 64.4869],
              [-145.0722, 64.5131],
              [-144.9212, 64.5788],
              [-144.6212, 64.5968],
              [-144.6298, 63.8486],
              [-144.6545, 63.4602],
              [-144.6551, 63.1336],
              [-145.1484, 63.1336],
              [-145.1489, 63.2222],
              [-146.1112, 63.2212],
              [-146.1408, 63.184],
              [-146.4841, 63.1733],
              [-146.4895, 63.4829],
              [-146.9755, 63.4797],
              [-146.9724, 63.9192],
              [-147.003, 64.2572],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200120',
        NAME: 'Dillingham City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86946428,
        AWATER: 7195792,
        INTPTLAT: '+59.0604718',
        INTPTLON: '-158.5322231',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-158.6278, 59.1009],
              [-158.4203, 59.053],
              [-158.613, 58.9996],
              [-158.6278, 59.1009],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200130',
        NAME: 'Galena City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45900503,
        AWATER: 16497077,
        INTPTLAT: '+64.7294249',
        INTPTLON: '-156.8729673',
        ELSDLEA: '',
        UNSDLEA: '00130',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-156.9979, 64.7595],
              [-156.7299, 64.7598],
              [-156.7246, 64.716],
              [-156.9869, 64.7163],
              [-156.9979, 64.7595],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200150',
        NAME: 'Ketchikan Gateway Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12583284815,
        AWATER: 4649397284,
        INTPTLAT: '+55.4499378',
        INTPTLON: '-131.1066847',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-132.2021, 55.6389],
              [-132.0912, 55.6607],
              [-131.9356, 55.7984],
              [-132.011, 55.8548],
              [-131.8956, 55.9205],
              [-131.8234, 56.0546],
              [-131.6642, 56.0675],
              [-131.3643, 56.0179],
              [-131.44, 56.1311],
              [-131.2534, 56.2068],
              [-131.1067, 56.1951],
              [-131.0155, 56.2504],
              [-131.1144, 56.3587],
              [-131.0875, 56.4074],
              [-130.7822, 56.3675],
              [-130.6225, 56.2679],
              [-130.4669, 56.2398],
              [-130.4256, 56.1407],
              [-130.2455, 56.0969],
              [-130.2851, 55.9975],
              [-130.3887, 55.9433],
              [-130.1506, 55.767],
              [-130.1267, 55.5813],
              [-130.0443, 55.452],
              [-129.9801, 55.2842],
              [-130.0965, 55.198],
              [-130.1875, 55.0647],
              [-130.3395, 54.9214],
              [-130.5694, 54.7909],
              [-130.6154, 54.7055],
              [-131.7511, 54.687],
              [-131.7712, 54.9611],
              [-131.6107, 54.9859],
              [-131.3802, 54.9922],
              [-131.2939, 55.1815],
              [-131.584, 55.2994],
              [-131.648, 55.099],
              [-131.8315, 55.118],
              [-131.898, 55.1816],
              [-131.9589, 55.3488],
              [-132.0545, 55.4532],
              [-132.2182, 55.5561],
              [-132.2021, 55.6389],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200180',
        NAME: 'Anchorage School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4420343302,
        AWATER: 663023276,
        INTPTLAT: '+61.1742503',
        INTPTLON: '-149.2843294',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-150.4206, 61.1672],
              [-150.0022, 61.2239],
              [-149.8979, 61.267],
              [-149.7924, 61.3889],
              [-149.3594, 61.484],
              [-149.1805, 61.4262],
              [-148.4625, 61.4263],
              [-148.4721, 60.8485],
              [-148.7384, 60.8485],
              [-148.7456, 60.733],
              [-149.0377, 60.733],
              [-149.0386, 60.8493],
              [-149.1886, 60.9055],
              [-149.3733, 60.9076],
              [-149.6269, 60.9609],
              [-150.0537, 61.075],
              [-150.243, 61.075],
              [-150.4206, 61.1672],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200210',
        NAME: 'Juneau Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6998182120,
        AWATER: 1429472851,
        INTPTLAT: '+58.3729099',
        INTPTLON: '-134.1784454',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-134.328, 58.9634],
              [-134.2505, 58.858],
              [-133.8404, 58.728],
              [-133.6998, 58.6073],
              [-133.3799, 58.4279],
              [-133.4615, 58.3855],
              [-133.1764, 58.1502],
              [-133.6966, 57.795],
              [-133.8105, 57.8361],
              [-134.1775, 58.1596],
              [-134.5635, 58.0788],
              [-134.6727, 58.0272],
              [-134.7619, 58.0781],
              [-134.7725, 58.1664],
              [-134.7034, 58.2768],
              [-135.0741, 58.5021],
              [-135.2191, 58.975],
              [-134.328, 58.9634],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200240',
        NAME: 'Sitka Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7434275351,
        AWATER: 5027235337,
        INTPTLAT: '+57.1932044',
        INTPTLON: '-135.3673965',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-134.5676, 56.864],
              [-134.5042, 56.705],
              [-134.4372, 56.283],
              [-134.666, 56.2856],
              [-134.6664, 56.145],
              [-134.8675, 56.2474],
              [-135.87, 57.0006],
              [-136.0539, 57.2773],
              [-136.4108, 57.7547],
              [-136.4545, 57.8308],
              [-136.0267, 57.8359],
              [-135.9067, 58.0013],
              [-135.5347, 57.8828],
              [-135.2454, 57.7652],
              [-134.8433, 57.7901],
              [-134.7096, 57.4567],
              [-134.7028, 57.3181],
              [-134.7446, 57.2292],
              [-134.6822, 57.0056],
              [-134.5676, 56.864],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200270',
        NAME: 'Haines Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6009021796,
        AWATER: 1049986571,
        INTPTLAT: '+59.0987709',
        INTPTLON: '-135.5769363',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-135.7226, 59.7296],
              [-135.3758, 59.3406],
              [-135.0292, 59.3454],
              [-134.962, 59.2804],
              [-134.7024, 59.2478],
              [-134.6819, 59.1908],
              [-134.4812, 59.1281],
              [-134.328, 58.9634],
              [-135.2191, 58.975],
              [-135.0741, 58.5021],
              [-135.0042, 58.1227],
              [-135.2531, 58.1659],
              [-135.4556, 58.3168],
              [-135.4731, 58.508],
              [-135.3185, 58.5005],
              [-135.5333, 58.6603],
              [-135.5483, 58.6983],
              [-135.7571, 58.7423],
              [-135.6838, 58.8546],
              [-135.5679, 58.9443],
              [-135.6882, 59.0167],
              [-135.7019, 59.1041],
              [-135.8921, 59.1728],
              [-136.4866, 59.2611],
              [-136.4743, 59.4642],
              [-136.3018, 59.4641],
              [-136.1903, 59.6399],
              [-135.9459, 59.6638],
              [-135.7226, 59.7296],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200300',
        NAME: 'Hoonah City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15555019,
        AWATER: 3319359,
        INTPTLAT: '+58.1224206',
        INTPTLON: '-135.4327701',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-135.484, 58.0933],
              [-135.4617, 58.1339],
              [-135.4066, 58.109],
              [-135.484, 58.0933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200330',
        NAME: 'Hydaburg City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1506298,
        AWATER: 5839,
        INTPTLAT: '+55.2041202',
        INTPTLON: '-132.8183410',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-132.8312, 55.2186],
              [-132.8024, 55.1962],
              [-132.8302, 55.2058],
              [-132.8312, 55.2186],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200360',
        NAME: 'Kake City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23210322,
        AWATER: 15564766,
        INTPTLAT: '+56.9776983',
        INTPTLON: '-133.9043928',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-133.9949, 56.9775],
              [-133.8835, 56.9995],
              [-133.9137, 56.9314],
              [-133.9949, 56.9775],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200390',
        NAME: 'Kenai Peninsula Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41634738098,
        AWATER: 22473667447,
        INTPTLAT: '+59.9836715',
        INTPTLON: '-151.7230237',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-153.4362, 60.9085],
              [-153.4304, 61.1357],
              [-153.4753, 61.1369],
              [-153.4697, 61.4281],
              [-153.0022, 61.4257],
              [-151.3342, 61.4246],
              [-151.333, 61.2546],
              [-150.9727, 61.2535],
              [-150.9708, 61.1656],
              [-150.7755, 61.078],
              [-150.4206, 61.1672],
              [-150.243, 61.075],
              [-150.0537, 61.075],
              [-149.6269, 60.9609],
              [-149.3733, 60.9076],
              [-149.1886, 60.9055],
              [-149.0386, 60.8493],
              [-149.0377, 60.733],
              [-148.7456, 60.733],
              [-148.6554, 60.7345],
              [-148.6666, 60.4231],
              [-148.5624, 60.4226],
              [-148.5836, 59.9614],
              [-148.9802, 59.8937],
              [-149.2696, 59.8454],
              [-149.4675, 59.7338],
              [-149.6213, 59.5275],
              [-150.4565, 59.3087],
              [-150.9922, 59.1808],
              [-151.4442, 59.0997],
              [-153.0212, 59.0025],
              [-153.1841, 58.9057],
              [-153.2523, 58.8558],
              [-153.4551, 58.8557],
              [-153.4522, 58.7348],
              [-153.8166, 58.7343],
              [-153.8151, 58.6472],
              [-154.3059, 58.6468],
              [-154.6373, 58.6468],
              [-154.6927, 58.7346],
              [-154.6921, 59.076],
              [-154.7454, 59.077],
              [-154.7477, 59.2531],
              [-154.4093, 59.2546],
              [-154.4146, 59.4287],
              [-154.1213, 59.4313],
              [-154.1244, 59.5196],
              [-153.9545, 59.5201],
              [-153.9559, 59.6961],
              [-153.7836, 59.6966],
              [-153.7829, 59.785],
              [-153.6549, 59.7849],
              [-153.6566, 60.1214],
              [-153.5311, 60.1225],
              [-153.5285, 60.4706],
              [-153.3943, 60.4723],
              [-153.395, 60.8233],
              [-153.4362, 60.9085],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200450',
        NAME: 'Klawock City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1986340,
        AWATER: 556712,
        INTPTLAT: '+55.5564296',
        INTPTLON: '-133.0862642',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-133.1029, 55.5544],
              [-133.0671, 55.5608],
              [-133.0684, 55.5507],
              [-133.1029, 55.5544],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200480',
        NAME: 'Kodiak Island Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16965364377,
        AWATER: 14171111659,
        INTPTLAT: '+57.5020153',
        INTPTLON: '-153.5635123',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-152.4665, 58.915],
              [-152.414, 58.9922],
              [-152.0196, 59.0109],
              [-151.8539, 58.9088],
              [-151.9816, 58.8373],
              [-152.1341, 58.8581],
              [-152.3833, 58.8283],
              [-152.4665, 58.915],
            ],
          ],
          [
            [
              [-154.9337, 57.2947],
              [-154.7031, 57.542],
              [-154.407, 57.6905],
              [-154.2401, 57.7155],
              [-153.8706, 57.9288],
              [-153.7866, 57.9177],
              [-153.5389, 58.0074],
              [-153.5001, 58.0796],
              [-153.2, 58.2663],
              [-152.8617, 58.52],
              [-152.7751, 58.5494],
              [-152.6984, 58.6486],
              [-152.4736, 58.7573],
              [-152.2477, 58.6807],
              [-152.1828, 58.574],
              [-152.0609, 58.5008],
              [-152.1263, 58.4496],
              [-151.8952, 58.3717],
              [-151.7128, 58.374],
              [-151.6905, 58.2351],
              [-151.764, 58.1366],
              [-152.0335, 58.1118],
              [-152.4145, 58.0366],
              [-152.2202, 57.9302],
              [-152.2564, 57.8686],
              [-152.1241, 57.8117],
              [-152.031, 57.6286],
              [-152.0635, 57.5712],
              [-152.2224, 57.4753],
              [-152.1565, 57.4059],
              [-152.2585, 57.3234],
              [-152.5288, 57.3273],
              [-152.6427, 57.2354],
              [-152.7852, 57.1925],
              [-152.8342, 57.0944],
              [-153.0983, 57.0403],
              [-153.181, 56.9607],
              [-153.4045, 56.9508],
              [-153.5013, 56.8567],
              [-153.6152, 56.8344],
              [-153.8004, 56.6899],
              [-153.8066, 56.5094],
              [-153.9363, 56.4535],
              [-154.4982, 56.4558],
              [-154.7257, 56.3552],
              [-154.8629, 56.3839],
              [-154.8938, 56.4447],
              [-154.7581, 56.5598],
              [-154.5075, 56.6588],
              [-154.3601, 56.6335],
              [-154.2178, 56.7205],
              [-154.3855, 56.8303],
              [-154.3877, 56.8956],
              [-154.5775, 56.9528],
              [-154.6198, 57.1624],
              [-154.9337, 57.2947],
            ],
          ],
          [
            [
              [-155.8368, 55.8008],
              [-155.6768, 55.9077],
              [-155.4744, 55.9136],
              [-155.4748, 55.7415],
              [-155.5281, 55.7058],
              [-155.7943, 55.7296],
              [-155.8368, 55.8008],
            ],
          ],
          [
            [
              [-154.3059, 58.6468],
              [-153.8151, 58.6472],
              [-153.8166, 58.7343],
              [-153.4522, 58.7348],
              [-153.4551, 58.8557],
              [-153.2523, 58.8558],
              [-153.1841, 58.9057],
              [-153.1668, 58.7552],
              [-153.4788, 58.6417],
              [-153.5152, 58.5484],
              [-153.8818, 58.4565],
              [-153.8586, 58.3743],
              [-153.97, 58.3127],
              [-154.1196, 58.1132],
              [-154.3907, 57.9993],
              [-154.9407, 57.9751],
              [-154.994, 57.8632],
              [-155.3004, 57.6429],
              [-155.4599, 57.6376],
              [-155.6326, 57.5868],
              [-155.6455, 57.5224],
              [-155.8445, 57.4975],
              [-155.9398, 57.4129],
              [-156.1672, 57.3459],
              [-156.247, 57.2438],
              [-156.2278, 57.1625],
              [-156.359, 57.0372],
              [-156.3554, 57.1599],
              [-156.7508, 57.1629],
              [-156.7508, 57.2358],
              [-156.6174, 57.3349],
              [-156.5022, 57.3626],
              [-156.5088, 57.4348],
              [-156.3702, 57.5224],
              [-156.208, 57.523],
              [-156.0711, 57.5844],
              [-155.768, 57.6683],
              [-155.6938, 57.798],
              [-155.5388, 57.7998],
              [-155.3302, 57.8763],
              [-155.3319, 58.1933],
              [-155.2215, 58.2364],
              [-155.0577, 58.2362],
              [-155.0081, 58.2909],
              [-154.7304, 58.3047],
              [-154.4657, 58.3614],
              [-154.3034, 58.4604],
              [-154.3059, 58.6468],
            ],
          ],
          [
            [
              [-156.902, 56.1654],
              [-156.8722, 56.247],
              [-156.6906, 56.2572],
              [-156.6538, 56.1676],
              [-156.556, 56.0902],
              [-156.5533, 56.0271],
              [-156.6754, 55.9568],
              [-156.8222, 55.9676],
              [-156.8172, 56.1041],
              [-156.902, 56.1654],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200485',
        NAME: 'Lake And Peninsula School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60735993529,
        AWATER: 24532276414,
        INTPTLAT: '+58.2050652',
        INTPTLON: '-156.7051884',
        ELSDLEA: '',
        UNSDLEA: '00485',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-158.8934, 56.9451],
              [-158.6677, 57.2957],
              [-158.4874, 57.6156],
              [-158.0628, 58.4855],
              [-158.0015, 58.6099],
              [-156.3177, 58.6096],
              [-156.3181, 58.8949],
              [-157.0706, 58.8878],
              [-157.158, 58.8602],
              [-157.1609, 59.0678],
              [-157.2416, 59.069],
              [-157.2442, 59.2462],
              [-157.0742, 59.2444],
              [-157.0733, 59.3315],
              [-156.7294, 59.3288],
              [-156.635, 59.4151],
              [-156.6345, 59.504],
              [-156.4649, 59.503],
              [-156.4648, 59.5894],
              [-156.1233, 59.5882],
              [-156.1221, 59.6752],
              [-155.9558, 59.6773],
              [-155.9536, 60.1062],
              [-155.9611, 60.9076],
              [-154.4221, 60.907],
              [-153.4362, 60.9085],
              [-153.395, 60.8233],
              [-153.3943, 60.4723],
              [-153.5285, 60.4706],
              [-153.5311, 60.1225],
              [-153.6566, 60.1214],
              [-153.6549, 59.7849],
              [-153.7829, 59.785],
              [-153.7836, 59.6966],
              [-153.9559, 59.6961],
              [-153.9545, 59.5201],
              [-154.1244, 59.5196],
              [-154.1213, 59.4313],
              [-154.4146, 59.4287],
              [-154.4093, 59.2546],
              [-154.7477, 59.2531],
              [-154.7454, 59.077],
              [-154.6921, 59.076],
              [-154.6927, 58.7346],
              [-154.6373, 58.6468],
              [-154.3059, 58.6468],
              [-154.3034, 58.4604],
              [-154.4657, 58.3614],
              [-154.7304, 58.3047],
              [-155.0081, 58.2909],
              [-155.0577, 58.2362],
              [-155.2215, 58.2364],
              [-155.3319, 58.1933],
              [-155.3302, 57.8763],
              [-155.5388, 57.7998],
              [-155.6938, 57.798],
              [-155.768, 57.6683],
              [-156.0711, 57.5844],
              [-156.208, 57.523],
              [-156.3702, 57.5224],
              [-156.5088, 57.4348],
              [-156.5022, 57.3626],
              [-156.6174, 57.3349],
              [-156.7508, 57.2358],
              [-156.7508, 57.1629],
              [-156.3554, 57.1599],
              [-156.359, 57.0372],
              [-156.3573, 56.8223],
              [-156.7455, 56.5763],
              [-157.7119, 55.9694],
              [-158.2374, 55.635],
              [-159.458, 55.6289],
              [-159.5644, 55.6289],
              [-159.5647, 55.8885],
              [-159.8681, 55.8885],
              [-159.8053, 55.9826],
              [-159.8097, 56.3217],
              [-159.9037, 56.3226],
              [-159.8994, 56.492],
              [-159.5934, 56.4945],
              [-159.5927, 56.5809],
              [-159.2755, 56.5809],
              [-159.2755, 56.6718],
              [-159.0286, 56.6694],
              [-159.0286, 56.7541],
              [-158.891, 56.7542],
              [-158.8934, 56.9451],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200510',
        NAME: 'Matanuska-Susitna Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63734631763,
        AWATER: 1682661470,
        INTPTLAT: '+62.2790749',
        INTPTLON: '-149.6175617',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-153.0013, 62.7274],
              [-151.8931, 62.723],
              [-151.889, 62.7953],
              [-150.0022, 63.2214],
              [-149.507, 63.3322],
              [-148.0359, 63.3308],
              [-148.0327, 63.4698],
              [-146.9755, 63.4797],
              [-146.4895, 63.4829],
              [-146.4841, 63.1733],
              [-146.481, 62.9597],
              [-146.4286, 62.8633],
              [-146.4381, 62.5111],
              [-146.4263, 62.2472],
              [-146.9817, 62.2487],
              [-146.9616, 62.1602],
              [-146.9451, 61.4743],
              [-147.2057, 61.4751],
              [-147.206, 61.4253],
              [-148.4625, 61.4263],
              [-149.1805, 61.4262],
              [-149.3594, 61.484],
              [-149.7924, 61.3889],
              [-149.8979, 61.267],
              [-150.0022, 61.2239],
              [-150.4206, 61.1672],
              [-150.7755, 61.078],
              [-150.9708, 61.1656],
              [-150.9727, 61.2535],
              [-151.333, 61.2546],
              [-151.3342, 61.4246],
              [-153.0022, 61.4257],
              [-153.0022, 61.9994],
              [-153.0013, 62.7274],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200520',
        NAME: 'Iditarod Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104590398337,
        AWATER: 1419103567,
        INTPTLAT: '+62.7045483',
        INTPTLON: '-156.1552690',
        ELSDLEA: '',
        UNSDLEA: '00520',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-160.8511, 63.0127],
              [-160.8473, 63.2692],
              [-160.6713, 63.2708],
              [-160.6921, 63.5366],
              [-160.495, 63.5371],
              [-160.4935, 63.6137],
              [-160.0979, 63.6144],
              [-160.1037, 63.7023],
              [-159.9292, 63.7035],
              [-159.9308, 63.7938],
              [-159.6993, 63.7923],
              [-159.7184, 63.9547],
              [-158.0856, 63.9475],
              [-156.5612, 63.9674],
              [-154.4083, 63.986],
              [-153.3053, 63.991],
              [-152.0474, 64.0002],
              [-152.0555, 63.8237],
              [-152.2336, 63.8237],
              [-152.2419, 63.6567],
              [-152.8534, 63.6517],
              [-152.8665, 63.4816],
              [-152.812, 63.3512],
              [-152.6226, 63.3468],
              [-152.4346, 63.3059],
              [-152.4342, 63.1698],
              [-153.0013, 62.7274],
              [-153.0022, 61.9994],
              [-153.0022, 61.4257],
              [-153.4697, 61.4281],
              [-153.4753, 61.1369],
              [-153.4304, 61.1357],
              [-153.4362, 60.9085],
              [-154.4221, 60.907],
              [-155.9611, 60.9076],
              [-156.0046, 60.9075],
              [-155.9843, 61.1011],
              [-155.9869, 61.4871],
              [-155.9658, 62.0298],
              [-157.0736, 62.0296],
              [-157.0742, 62.1299],
              [-157.517, 62.1235],
              [-158.5324, 62.1186],
              [-158.5311, 62.0317],
              [-159.2609, 62.032],
              [-159.2651, 61.9456],
              [-159.9354, 61.9455],
              [-160.9083, 61.9494],
              [-160.9102, 62.2044],
              [-161.0478, 62.2067],
              [-161.0453, 62.5504],
              [-161.0004, 62.5518],
              [-160.994, 62.8944],
              [-160.9419, 62.9989],
              [-160.8511, 63.0127],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200525',
        NAME: 'Annette Island School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343946421,
        AWATER: 213611590,
        INTPTLAT: '+55.1383520',
        INTPTLON: '-131.4704235',
        ELSDLEA: '',
        UNSDLEA: '00525',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-131.6107, 54.9859],
              [-131.648, 55.099],
              [-131.584, 55.2994],
              [-131.2939, 55.1815],
              [-131.3802, 54.9922],
              [-131.6107, 54.9859],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200540',
        NAME: 'Nenana City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15268757,
        AWATER: 147278,
        INTPTLAT: '+64.5433395',
        INTPTLON: '-149.0712475',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-149.139, 64.5272],
              [-149.0515, 64.552],
              [-149.0541, 64.5166],
              [-149.139, 64.5272],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200570',
        NAME: 'Nome School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32722536,
        AWATER: 23269656,
        INTPTLAT: '+64.5057356',
        INTPTLON: '-165.4153072',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-165.5138, 64.485],
              [-165.3599, 64.5491],
              [-165.3536, 64.463],
              [-165.5138, 64.485],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200600',
        NAME: 'Fairbanks North Star Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19019023800,
        AWATER: 275189755,
        INTPTLAT: '+64.6923172',
        INTPTLON: '-146.6017326',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-148.6612, 65.2099],
              [-147.5537, 65.2099],
              [-147.3348, 65.2733],
              [-146.949, 65.2738],
              [-146.5688, 65.3447],
              [-146.5255, 65.421],
              [-146.1794, 65.4533],
              [-146.0051, 65.3746],
              [-146.1261, 65.2795],
              [-145.6155, 65.1441],
              [-145.6464, 65.0337],
              [-145.2099, 65.073],
              [-144.8914, 65.1372],
              [-144.4454, 65.0623],
              [-144.2472, 65.1188],
              [-143.8877, 65.0919],
              [-144.0865, 65.0005],
              [-143.9958, 64.9431],
              [-144.1201, 64.7983],
              [-144.0465, 64.6668],
              [-144.3076, 64.6419],
              [-144.4624, 64.5829],
              [-144.6212, 64.5968],
              [-144.9212, 64.5788],
              [-145.0722, 64.5131],
              [-145.3605, 64.4869],
              [-145.5313, 64.4177],
              [-145.7386, 64.4604],
              [-146.001, 64.3837],
              [-146.1568, 64.3861],
              [-146.3573, 64.2785],
              [-146.6458, 64.2572],
              [-147.003, 64.2572],
              [-147.7592, 64.2572],
              [-147.9952, 64.3417],
              [-148.1876, 64.4084],
              [-148.2422, 64.4834],
              [-148.4264, 64.5729],
              [-148.6482, 64.6368],
              [-148.6612, 65.2099],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200610',
        NAME: 'North Slope Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230092740514,
        AWATER: 15795362153,
        INTPTLAT: '+69.4493427',
        INTPTLON: '-153.4728302',
        ELSDLEA: '',
        UNSDLEA: '00610',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-141.0027, 68.4984],
              [-142.3605, 68.4896],
              [-144.2971, 68.5073],
              [-146.0004, 68.4897],
              [-145.9995, 67.9997],
              [-147.924, 67.9996],
              [-150.0006, 67.9996],
              [-152.081, 67.9996],
              [-153.0028, 67.9995],
              [-155.3187, 67.9997],
              [-155.3187, 68.0349],
              [-156.2368, 68.0349],
              [-156.2368, 68.1215],
              [-157.1549, 68.1215],
              [-157.1549, 68.2081],
              [-158.4578, 68.208],
              [-159.6935, 68.208],
              [-159.6935, 68.2946],
              [-160.1595, 68.2946],
              [-160.1594, 68.208],
              [-161.0914, 68.208],
              [-161.0914, 68.2945],
              [-161.5573, 68.2945],
              [-161.5573, 68.2079],
              [-162.0233, 68.2079],
              [-162.0233, 68.2945],
              [-162.7222, 68.2945],
              [-162.7222, 68.2079],
              [-164.4995, 68.2079],
              [-164.4994, 68.0347],
              [-165.596, 68.0348],
              [-165.82, 68.0306],
              [-166.0439, 68.0849],
              [-166.1484, 68.1689],
              [-166.41, 68.2508],
              [-166.9733, 68.3097],
              [-166.7706, 68.4152],
              [-166.4443, 68.4761],
              [-166.3699, 68.5712],
              [-166.3206, 68.7749],
              [-166.3669, 68.8583],
              [-166.1853, 68.9357],
              [-165.6668, 68.9146],
              [-164.2886, 68.9764],
              [-163.7598, 69.1218],
              [-163.3629, 69.3302],
              [-163.2823, 69.4563],
              [-163.2865, 69.6404],
              [-163.1835, 69.8064],
              [-162.5726, 70.1694],
              [-162.4008, 70.2385],
              [-161.8749, 70.3807],
              [-161.4443, 70.3479],
              [-161.1821, 70.3598],
              [-160.7298, 70.4572],
              [-160.491, 70.5355],
              [-159.7124, 70.8468],
              [-159.1758, 70.9291],
              [-158.6859, 70.9611],
              [-158.4388, 70.8824],
              [-158.1518, 70.8709],
              [-157.8397, 70.919],
              [-157.5533, 70.9993],
              [-156.9453, 71.312],
              [-156.7896, 71.3736],
              [-156.4604, 71.4411],
              [-155.8926, 71.354],
              [-155.7592, 71.2835],
              [-155.4361, 71.2706],
              [-155.0963, 71.2236],
              [-154.7699, 71.1494],
              [-154.3725, 71.0105],
              [-153.9004, 70.9307],
              [-153.4943, 70.9326],
              [-153.2428, 70.9765],
              [-152.8227, 70.9748],
              [-152.1995, 70.8988],
              [-151.6156, 70.5971],
              [-151.545, 70.4903],
              [-151.2466, 70.48],
              [-150.7492, 70.5546],
              [-150.3461, 70.5605],
              [-150.2839, 70.6046],
              [-149.4222, 70.608],
              [-148.7867, 70.5265],
              [-148.1299, 70.5],
              [-147.9069, 70.5389],
              [-147.6107, 70.4357],
              [-147.451, 70.4467],
              [-147.1884, 70.375],
              [-146.8697, 70.3471],
              [-146.7169, 70.2868],
              [-146.4055, 70.2889],
              [-145.9793, 70.2441],
              [-145.0329, 70.0295],
              [-144.6788, 70.0144],
              [-144.4812, 70.0907],
              [-144.005, 70.1684],
              [-143.5603, 70.2049],
              [-143.2264, 70.202],
              [-142.7063, 70.0882],
              [-142.0791, 69.9085],
              [-141.5915, 69.8146],
              [-141.3779, 69.7441],
              [-141.0027, 69.7036],
              [-141.0027, 68.9583],
              [-141.0027, 68.4984],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200625',
        NAME: 'Northwest Arctic Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92295122743,
        AWATER: 12906736753,
        INTPTLAT: '+67.0050655',
        INTPTLON: '-160.0210864',
        ELSDLEA: '',
        UNSDLEA: '00625',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-165.596, 68.0348],
              [-164.4994, 68.0347],
              [-164.4995, 68.2079],
              [-162.7222, 68.2079],
              [-162.7222, 68.2945],
              [-162.0233, 68.2945],
              [-162.0233, 68.2079],
              [-161.5573, 68.2079],
              [-161.5573, 68.2945],
              [-161.0914, 68.2945],
              [-161.0914, 68.208],
              [-160.1594, 68.208],
              [-160.1595, 68.2946],
              [-159.6935, 68.2946],
              [-159.6935, 68.208],
              [-158.4578, 68.208],
              [-157.1549, 68.2081],
              [-157.1549, 68.1215],
              [-156.2368, 68.1215],
              [-156.2368, 68.0349],
              [-155.3187, 68.0349],
              [-155.3187, 67.9997],
              [-155.369, 67.7752],
              [-155.1428, 67.7752],
              [-155.1428, 67.6886],
              [-154.9167, 67.6886],
              [-154.9166, 67.6021],
              [-154.6905, 67.6021],
              [-154.7489, 67.5155],
              [-154.7489, 67.2558],
              [-154.3031, 67.2558],
              [-154.3031, 67.1692],
              [-154.1463, 67.1692],
              [-154.1462, 66.8228],
              [-154.2103, 66.7363],
              [-154.8604, 66.7362],
              [-154.8604, 66.5631],
              [-155.5106, 66.5631],
              [-155.555, 66.4765],
              [-155.555, 66.3033],
              [-155.9825, 66.3033],
              [-155.9825, 66.3899],
              [-156.1963, 66.3899],
              [-156.1962, 66.4766],
              [-156.6238, 66.4764],
              [-156.6238, 66.3033],
              [-157.0513, 66.3033],
              [-157.0513, 66.4764],
              [-157.9063, 66.4764],
              [-157.9063, 66.1301],
              [-158.9722, 66.13],
              [-158.9722, 65.9569],
              [-159.6048, 65.9568],
              [-159.5935, 65.5239],
              [-159.8017, 65.5238],
              [-159.8017, 65.4372],
              [-161.0586, 65.437],
              [-162.7845, 65.4371],
              [-163.7554, 65.4372],
              [-163.7554, 65.6104],
              [-163.9635, 65.6104],
              [-163.9635, 65.7836],
              [-164.2442, 65.7836],
              [-164.2442, 66.1299],
              [-164.3189, 66.1299],
              [-164.3189, 66.4763],
              [-164.4635, 66.6263],
              [-163.8051, 66.6413],
              [-163.6262, 66.6242],
              [-162.832, 66.9268],
              [-163.0495, 66.9866],
              [-163.7815, 67.0698],
              [-163.9912, 67.1479],
              [-163.8825, 67.215],
              [-164.0045, 67.3978],
              [-164.1754, 67.5476],
              [-164.6552, 67.6924],
              [-165.4995, 68.0188],
              [-165.596, 68.0348],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200630',
        NAME: 'Pelican City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1563421,
        AWATER: 303456,
        INTPTLAT: '+57.9598001',
        INTPTLON: '-136.2167539',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-136.2381, 57.9638],
              [-136.201, 57.9609],
              [-136.2012, 57.9534],
              [-136.2311, 57.9536],
              [-136.2381, 57.9638],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200660',
        NAME: 'Petersburg Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7556529587,
        AWATER: 2399243232,
        INTPTLAT: '+57.1124581',
        INTPTLON: '-133.0085979',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-133.6966, 57.795],
              [-133.5559, 57.7722],
              [-133.4522, 57.8141],
              [-132.8719, 57.7128],
              [-132.8879, 57.6137],
              [-132.8121, 57.5763],
              [-132.6504, 57.6104],
              [-132.5592, 57.5039],
              [-132.368, 57.3487],
              [-132.2522, 57.2157],
              [-132.3713, 57.0952],
              [-132.051, 57.0512],
              [-132.119, 56.8913],
              [-132.214, 56.8924],
              [-132.3943, 56.7144],
              [-132.5753, 56.7151],
              [-132.5183, 56.5862],
              [-132.7567, 56.4844],
              [-133.0435, 56.4416],
              [-133.1691, 56.3551],
              [-133.4175, 56.3944],
              [-133.4269, 56.6185],
              [-133.5123, 56.6852],
              [-133.4866, 56.8664],
              [-133.2738, 56.9219],
              [-133.325, 57.0636],
              [-133.6589, 57.1624],
              [-133.7386, 57.1858],
              [-133.6966, 57.795],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200670',
        NAME: 'Pribilof School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194414803,
        AWATER: 1043002126,
        INTPTLAT: '+57.0495083',
        INTPTLON: '-170.4062251',
        ELSDLEA: '',
        UNSDLEA: '00670',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-169.8796, 56.6148],
              [-169.8294, 56.6698],
              [-169.5093, 56.6675],
              [-169.3775, 56.5877],
              [-169.5266, 56.493],
              [-169.6761, 56.4962],
              [-169.8796, 56.6148],
            ],
          ],
          [
            [
              [-170.0505, 57.1869],
              [-169.9097, 57.2363],
              [-169.849, 57.1762],
              [-169.9556, 57.1282],
              [-170.0505, 57.1869],
            ],
          ],
          [
            [
              [-170.5227, 57.1934],
              [-170.4496, 57.2493],
              [-170.191, 57.2974],
              [-170.0165, 57.2666],
              [-170.1118, 57.1367],
              [-170.4441, 56.9961],
              [-170.4427, 57.1023],
              [-170.5227, 57.1934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200680',
        NAME: 'St. Marys City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114715077,
        AWATER: 16401693,
        INTPTLAT: '+62.0652861',
        INTPTLON: '-163.2494788',
        ELSDLEA: '',
        UNSDLEA: '00680',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-163.4672, 62.0802],
              [-163.117, 62.1039],
              [-163.1158, 62.0317],
              [-163.3405, 62.0326],
              [-163.4672, 62.0802],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200690',
        NAME: 'Skagway School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1171510537,
        AWATER: 30331285,
        INTPTLAT: '+59.5750974',
        INTPTLON: '-135.3354181',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-135.7226, 59.7296],
              [-135.4774, 59.7996],
              [-135.2541, 59.7013],
              [-135.0275, 59.5637],
              [-135.0263, 59.4747],
              [-135.098, 59.4278],
              [-135.0292, 59.3454],
              [-135.3758, 59.3406],
              [-135.7226, 59.7296],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200700',
        NAME: 'Southeast Island School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13239238387,
        AWATER: 14302785349,
        INTPTLAT: '+55.7292480',
        INTPTLON: '-133.2241939',
        ELSDLEA: '',
        UNSDLEA: '00700',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-130.2455, 56.0969],
              [-130.1028, 56.1167],
              [-130.0169, 56.0173],
              [-130.0247, 55.9159],
              [-130.1506, 55.767],
              [-130.3887, 55.9433],
              [-130.2851, 55.9975],
              [-130.2455, 56.0969],
            ],
          ],
          [
            [
              [-134.5676, 56.864],
              [-134.3353, 56.9675],
              [-134.0631, 57.1563],
              [-133.6589, 57.1624],
              [-133.325, 57.0636],
              [-133.2738, 56.9219],
              [-133.4866, 56.8664],
              [-133.5123, 56.6852],
              [-133.4269, 56.6185],
              [-133.4175, 56.3944],
              [-133.1691, 56.3551],
              [-133.0427, 56.2497],
              [-133.0157, 56.147],
              [-132.888, 56.0645],
              [-132.7215, 55.9996],
              [-132.3559, 55.7958],
              [-132.2709, 55.6656],
              [-132.2021, 55.6389],
              [-132.2182, 55.5561],
              [-132.0545, 55.4532],
              [-131.9589, 55.3488],
              [-131.898, 55.1816],
              [-131.8315, 55.118],
              [-131.648, 55.099],
              [-131.6107, 54.9859],
              [-131.7712, 54.9611],
              [-131.7511, 54.687],
              [-132.9257, 54.659],
              [-133.6489, 54.632],
              [-133.7416, 54.8229],
              [-133.7905, 55.0006],
              [-133.6137, 55.1703],
              [-133.7756, 55.2822],
              [-133.7716, 55.3943],
              [-133.8739, 55.3988],
              [-133.7982, 55.6332],
              [-133.8675, 55.6573],
              [-133.8859, 55.7802],
              [-134.0466, 55.8505],
              [-134.192, 55.6931],
              [-134.5694, 55.804],
              [-134.6457, 55.8715],
              [-134.4942, 55.9996],
              [-134.6664, 56.145],
              [-134.666, 56.2856],
              [-134.4372, 56.283],
              [-134.5042, 56.705],
              [-134.5676, 56.864],
            ],
            [
              [-133.9949, 56.9775],
              [-133.9137, 56.9314],
              [-133.8835, 56.9995],
              [-133.9949, 56.9775],
            ],
            [
              [-133.1549, 55.4947],
              [-133.1577, 55.4549],
              [-133.1082, 55.4554],
              [-133.1081, 55.4952],
              [-133.1549, 55.4947],
            ],
            [
              [-133.1029, 55.5544],
              [-133.0684, 55.5507],
              [-133.0671, 55.5608],
              [-133.1029, 55.5544],
            ],
            [
              [-132.8312, 55.2186],
              [-132.8302, 55.2058],
              [-132.8024, 55.1962],
              [-132.8312, 55.2186],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200710',
        NAME: 'Southwest Region School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48006501844,
        AWATER: 6069943375,
        INTPTLAT: '+60.2982697',
        INTPTLON: '-158.0961482',
        ELSDLEA: '',
        UNSDLEA: '00710',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-160.0976, 58.5999],
              [-160.0046, 58.6656],
              [-159.8634, 58.5787],
              [-160.0591, 58.5478],
              [-160.0976, 58.5999],
            ],
          ],
          [
            [
              [-160.5441, 58.6878],
              [-160.416, 58.7984],
              [-160.1376, 58.7489],
              [-160.097, 58.6973],
              [-160.301, 58.5358],
              [-160.3969, 58.5539],
              [-160.5441, 58.6878],
            ],
          ],
          [
            [
              [-158.8816, 60.9034],
              [-157.8836, 60.9037],
              [-157.8807, 60.8248],
              [-157.6979, 60.8236],
              [-157.7009, 60.9113],
              [-157.5219, 60.9113],
              [-157.5225, 61.0005],
              [-157.1695, 61.0002],
              [-157.1683, 60.9122],
              [-156.0046, 60.9075],
              [-155.9611, 60.9076],
              [-155.9536, 60.1062],
              [-155.9558, 59.6773],
              [-156.1221, 59.6752],
              [-156.1233, 59.5882],
              [-156.4648, 59.5894],
              [-156.4649, 59.503],
              [-156.6345, 59.504],
              [-156.635, 59.4151],
              [-156.7294, 59.3288],
              [-157.0733, 59.3315],
              [-157.0742, 59.2444],
              [-157.2442, 59.2462],
              [-157.2416, 59.069],
              [-157.1609, 59.0678],
              [-157.158, 58.8602],
              [-157.3013, 58.8371],
              [-157.5506, 58.7545],
              [-158.001, 58.6453],
              [-158.0015, 58.6099],
              [-158.0628, 58.4855],
              [-158.213, 58.5426],
              [-158.6052, 58.4556],
              [-158.8103, 58.3472],
              [-159.0187, 58.3341],
              [-159.1514, 58.4158],
              [-159.4047, 58.666],
              [-159.5447, 58.7784],
              [-159.7017, 58.7964],
              [-159.8939, 58.715],
              [-160.2976, 58.7991],
              [-160.3431, 58.8805],
              [-160.6087, 58.8436],
              [-160.6016, 58.7933],
              [-160.8018, 58.5514],
              [-160.9334, 58.4961],
              [-161.1589, 58.5124],
              [-161.177, 58.6071],
              [-161.3369, 58.6084],
              [-161.1974, 58.7344],
              [-161.3687, 58.7356],
              [-161.3675, 58.8232],
              [-161.0345, 58.8386],
              [-161.0349, 59.0034],
              [-160.8743, 59.0037],
              [-160.8191, 59.0849],
              [-160.8209, 59.2615],
              [-160.6503, 59.2622],
              [-160.6508, 59.3492],
              [-160.4805, 59.3522],
              [-160.425, 59.4359],
              [-160.4245, 59.6115],
              [-160.2507, 59.6113],
              [-160.2482, 59.7845],
              [-160.0194, 59.7868],
              [-160.0152, 59.9589],
              [-159.8553, 59.9637],
              [-159.8503, 60.1284],
              [-159.7845, 60.2133],
              [-159.6134, 60.2146],
              [-159.6136, 60.3878],
              [-159.437, 60.3872],
              [-159.3696, 60.4711],
              [-159.3672, 60.6443],
              [-159.1852, 60.6446],
              [-159.183, 60.7311],
              [-159.0159, 60.7319],
              [-158.8816, 60.9034],
            ],
            [
              [-158.6278, 59.1009],
              [-158.613, 58.9996],
              [-158.4203, 59.053],
              [-158.6278, 59.1009],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200715',
        NAME: 'Tanana School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28595093,
        AWATER: 12025525,
        INTPTLAT: '+65.1845551',
        INTPTLON: '-152.0546429',
        ELSDLEA: '',
        UNSDLEA: '00715',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-152.1591, 65.1798],
              [-151.9817, 65.196],
              [-151.9826, 65.1505],
              [-152.1591, 65.1798],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200720',
        NAME: 'Unalaska City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 289526679,
        AWATER: 261279178,
        INTPTLAT: '+53.9107988',
        INTPTLON: '-166.5274463',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-166.7778, 53.891],
              [-166.7763, 53.9806],
              [-166.6305, 53.9802],
              [-166.6297, 54.0499],
              [-166.3717, 54.0472],
              [-166.3719, 53.8904],
              [-166.4784, 53.8014],
              [-166.642, 53.8015],
              [-166.6826, 53.8914],
              [-166.7778, 53.891],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200730',
        NAME: 'Chatham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16949245604,
        AWATER: 7796042892,
        INTPTLAT: '+58.4033360',
        INTPTLON: '-135.8849090',
        ELSDLEA: '',
        UNSDLEA: '00730',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-133.6966, 57.795],
              [-133.1764, 58.1502],
              [-133.0764, 57.9998],
              [-132.8693, 57.8429],
              [-132.6504, 57.6104],
              [-132.8121, 57.5763],
              [-132.8879, 57.6137],
              [-132.8719, 57.7128],
              [-133.4522, 57.8141],
              [-133.5559, 57.7722],
              [-133.6966, 57.795],
            ],
          ],
          [
            [
              [-138.0286, 58.7813],
              [-137.5253, 58.9069],
              [-137.4474, 58.9095],
              [-137.2648, 59.0024],
              [-136.8266, 59.1584],
              [-136.5815, 59.1649],
              [-136.4866, 59.2611],
              [-135.8921, 59.1728],
              [-135.7019, 59.1041],
              [-135.6882, 59.0167],
              [-135.5679, 58.9443],
              [-135.6838, 58.8546],
              [-135.7571, 58.7423],
              [-135.5483, 58.6983],
              [-135.5333, 58.6603],
              [-135.3185, 58.5005],
              [-135.4731, 58.508],
              [-135.4556, 58.3168],
              [-135.2531, 58.1659],
              [-135.0042, 58.1227],
              [-135.0741, 58.5021],
              [-134.7034, 58.2768],
              [-134.7725, 58.1664],
              [-134.7619, 58.0781],
              [-134.6727, 58.0272],
              [-134.5635, 58.0788],
              [-134.1775, 58.1596],
              [-133.8105, 57.8361],
              [-133.6966, 57.795],
              [-133.7386, 57.1858],
              [-133.6589, 57.1624],
              [-134.0631, 57.1563],
              [-134.3353, 56.9675],
              [-134.5676, 56.864],
              [-134.6822, 57.0056],
              [-134.7446, 57.2292],
              [-134.7028, 57.3181],
              [-134.7096, 57.4567],
              [-134.8433, 57.7901],
              [-135.2454, 57.7652],
              [-135.5347, 57.8828],
              [-135.9067, 58.0013],
              [-136.0267, 57.8359],
              [-136.4545, 57.8308],
              [-136.6586, 57.9015],
              [-136.6789, 58.0363],
              [-136.6163, 58.1472],
              [-136.8144, 58.1888],
              [-137.0145, 58.3443],
              [-137.1496, 58.3413],
              [-137.7622, 58.5965],
              [-137.7759, 58.6408],
              [-138.0286, 58.7813],
            ],
            [
              [-136.2381, 57.9638],
              [-136.2311, 57.9536],
              [-136.2012, 57.9534],
              [-136.201, 57.9609],
              [-136.2381, 57.9638],
            ],
            [
              [-135.484, 58.0933],
              [-135.4066, 58.109],
              [-135.4617, 58.1339],
              [-135.484, 58.0933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200760',
        NAME: 'Kuspuk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29513383643,
        AWATER: 596967965,
        INTPTLAT: '+61.5235521',
        INTPTLON: '-158.0360918',
        ELSDLEA: '',
        UNSDLEA: '00760',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-160.9083, 61.9494],
              [-159.9354, 61.9455],
              [-159.2651, 61.9456],
              [-159.2609, 62.032],
              [-158.5311, 62.0317],
              [-158.5324, 62.1186],
              [-157.517, 62.1235],
              [-157.0742, 62.1299],
              [-157.0736, 62.0296],
              [-155.9658, 62.0298],
              [-155.9869, 61.4871],
              [-155.9843, 61.1011],
              [-156.0046, 60.9075],
              [-157.1683, 60.9122],
              [-157.1695, 61.0002],
              [-157.5225, 61.0005],
              [-157.5219, 60.9113],
              [-157.7009, 60.9113],
              [-157.6979, 60.8236],
              [-157.8807, 60.8248],
              [-157.8836, 60.9037],
              [-158.8816, 60.9034],
              [-159.4205, 60.9989],
              [-160.7534, 61.282],
              [-160.9083, 61.9494],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200770',
        NAME: 'Denali Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33015947847,
        AWATER: 66068782,
        INTPTLAT: '+63.6811063',
        INTPTLON: '-150.0265438',
        ELSDLEA: '',
        UNSDLEA: '00770',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-152.0474, 64.0002],
              [-151.8571, 64.0302],
              [-151.7906, 64.0823],
              [-151.5793, 64.0854],
              [-151.5251, 64.0325],
              [-151.3013, 64.01],
              [-151.1362, 64.1331],
              [-150.7497, 64.3651],
              [-149.668, 64.3578],
              [-149.1207, 64.3436],
              [-147.9952, 64.3417],
              [-147.7592, 64.2572],
              [-147.003, 64.2572],
              [-146.9724, 63.9192],
              [-146.9755, 63.4797],
              [-148.0327, 63.4698],
              [-148.0359, 63.3308],
              [-149.507, 63.3322],
              [-150.0022, 63.2214],
              [-151.889, 62.7953],
              [-151.8931, 62.723],
              [-153.0013, 62.7274],
              [-152.4342, 63.1698],
              [-152.4346, 63.3059],
              [-152.6226, 63.3468],
              [-152.812, 63.3512],
              [-152.8665, 63.4816],
              [-152.8534, 63.6517],
              [-152.2419, 63.6567],
              [-152.2336, 63.8237],
              [-152.0555, 63.8237],
              [-152.0474, 64.0002],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200775',
        NAME: 'Yukon Flats School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139591604051,
        AWATER: 2552062868,
        INTPTLAT: '+66.7196853',
        INTPTLON: '-145.2587692',
        ELSDLEA: '',
        UNSDLEA: '00775',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-150.0006, 67.9996],
              [-147.924, 67.9996],
              [-145.9995, 67.9997],
              [-146.0004, 68.4897],
              [-144.2971, 68.5073],
              [-142.3605, 68.4896],
              [-141.0027, 68.4984],
              [-141.0027, 67.3973],
              [-141.0026, 66.7715],
              [-141.0025, 65.7997],
              [-141.0023, 65.0001],
              [-142.2177, 65.0004],
              [-143.1509, 64.9997],
              [-144.0865, 65.0005],
              [-143.8877, 65.0919],
              [-144.2472, 65.1188],
              [-144.4454, 65.0623],
              [-144.8914, 65.1372],
              [-145.2099, 65.073],
              [-145.6464, 65.0337],
              [-145.6155, 65.1441],
              [-146.1261, 65.2795],
              [-146.0051, 65.3746],
              [-146.1794, 65.4533],
              [-146.5255, 65.421],
              [-146.5688, 65.3447],
              [-146.949, 65.2738],
              [-147.3348, 65.2733],
              [-147.5537, 65.2099],
              [-148.6612, 65.2099],
              [-150.0118, 65.2913],
              [-150.316, 65.2994],
              [-150.9817, 65.3617],
              [-150.9761, 66.0187],
              [-150.0068, 65.9986],
              [-149.9946, 66.8486],
              [-150.0, 67.4738],
              [-150.0006, 67.9996],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200780',
        NAME: 'Valdez City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 560049656,
        AWATER: 154468481,
        INTPTLAT: '+61.0836208',
        INTPTLON: '-146.3171790',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-146.7322, 61.1242],
              [-146.6568, 61.1531],
              [-146.3874, 61.1541],
              [-146.3887, 61.2542],
              [-146.2676, 61.2545],
              [-146.2667, 61.1531],
              [-146.118, 61.1243],
              [-145.8429, 61.1242],
              [-145.8434, 61.0228],
              [-146.7298, 61.0209],
              [-146.7322, 61.1242],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200800',
        NAME: 'Chugach School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24282494135,
        AWATER: 14389453102,
        INTPTLAT: '+60.4884737',
        INTPTLON: '-146.2030924',
        ELSDLEA: '',
        UNSDLEA: '00800',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-146.5083, 59.3907],
              [-146.4278, 59.4667],
              [-146.4238, 59.5587],
              [-146.3159, 59.5866],
              [-146.1711, 59.4822],
              [-146.1495, 59.4172],
              [-146.3838, 59.334],
              [-146.5083, 59.3907],
            ],
          ],
          [
            [
              [-148.9802, 59.8937],
              [-148.5836, 59.9614],
              [-148.5624, 60.4226],
              [-148.6666, 60.4231],
              [-148.6554, 60.7345],
              [-148.7456, 60.733],
              [-148.7384, 60.8485],
              [-148.4721, 60.8485],
              [-148.4625, 61.4263],
              [-147.206, 61.4253],
              [-147.2042, 61.297],
              [-146.2061, 61.2981],
              [-146.2041, 61.2107],
              [-144.9874, 61.2102],
              [-144.4161, 61.212],
              [-144.427, 61.0376],
              [-143.7161, 61.0378],
              [-143.7132, 60.8504],
              [-143.408, 60.8506],
              [-143.4081, 60.7607],
              [-142.8716, 60.789],
              [-142.8619, 60.5954],
              [-141.9627, 60.6077],
              [-141.9631, 60.5214],
              [-143.1671, 60.5188],
              [-143.89, 60.0423],
              [-143.8854, 59.924],
              [-144.1441, 59.9195],
              [-144.4105, 59.7463],
              [-144.6768, 59.7274],
              [-144.7778, 59.8299],
              [-144.5397, 60.0101],
              [-144.6073, 60.0783],
              [-144.776, 60.1296],
              [-145.098, 60.1461],
              [-145.3177, 60.1797],
              [-145.8243, 60.3151],
              [-146.1793, 60.2801],
              [-146.9393, 60.1038],
              [-147.1019, 60.0813],
              [-147.2417, 59.9983],
              [-147.3348, 59.8339],
              [-147.6872, 59.7417],
              [-147.9564, 59.7441],
              [-148.2185, 59.8756],
              [-148.9802, 59.8937],
            ],
            [
              [-146.7322, 61.1242],
              [-146.7298, 61.0209],
              [-145.8434, 61.0228],
              [-145.8429, 61.1242],
              [-146.118, 61.1243],
              [-146.2667, 61.1531],
              [-146.2676, 61.2545],
              [-146.3887, 61.2542],
              [-146.3874, 61.1541],
              [-146.6568, 61.1531],
              [-146.7322, 61.1242],
            ],
            [
              [-145.8801, 60.5023],
              [-145.694, 60.516],
              [-145.417, 60.4638],
              [-145.4156, 60.5343],
              [-145.5796, 60.5341],
              [-145.6721, 60.6393],
              [-145.8801, 60.5023],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200810',
        NAME: 'Wrangell City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6582396275,
        AWATER: 2384301874,
        INTPTLAT: '+56.1807793',
        INTPTLON: '-132.0267878',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-133.1691, 56.3551],
              [-133.0435, 56.4416],
              [-132.7567, 56.4844],
              [-132.5183, 56.5862],
              [-132.5753, 56.7151],
              [-132.3943, 56.7144],
              [-132.214, 56.8924],
              [-132.119, 56.8913],
              [-131.8717, 56.805],
              [-131.9018, 56.7532],
              [-131.8351, 56.6018],
              [-131.5812, 56.6133],
              [-131.4618, 56.5479],
              [-131.0875, 56.4074],
              [-131.1144, 56.3587],
              [-131.0155, 56.2504],
              [-131.1067, 56.1951],
              [-131.2534, 56.2068],
              [-131.44, 56.1311],
              [-131.3643, 56.0179],
              [-131.6642, 56.0675],
              [-131.8234, 56.0546],
              [-131.8956, 55.9205],
              [-132.011, 55.8548],
              [-131.9356, 55.7984],
              [-132.0912, 55.6607],
              [-132.2021, 55.6389],
              [-132.2709, 55.6656],
              [-132.3559, 55.7958],
              [-132.7215, 55.9996],
              [-132.888, 56.0645],
              [-133.0157, 56.147],
              [-133.0427, 56.2497],
              [-133.1691, 56.3551],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200840',
        NAME: 'Yakutat City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19811664933,
        AWATER: 4696568493,
        INTPTLAT: '+60.0070617',
        INTPTLON: '-140.1481080',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-141.9631, 60.5214],
              [-141.7656, 60.5277],
              [-141.7603, 60.4342],
              [-141.2137, 60.4358],
              [-141.0019, 60.3917],
              [-141.0018, 60.3061],
              [-140.5351, 60.2242],
              [-140.4723, 60.3106],
              [-139.9891, 60.1852],
              [-139.6984, 60.3404],
              [-139.0867, 60.3577],
              [-139.2003, 60.0907],
              [-139.0316, 59.9937],
              [-138.7021, 59.9102],
              [-138.6209, 59.7706],
              [-137.6043, 59.2431],
              [-137.4986, 58.9867],
              [-137.5253, 58.9069],
              [-138.0286, 58.7813],
              [-138.026, 58.8524],
              [-138.2911, 59.0004],
              [-138.6672, 59.0831],
              [-138.8906, 59.1807],
              [-139.5314, 59.3572],
              [-140.0352, 59.5468],
              [-140.3631, 59.6536],
              [-140.6564, 59.6574],
              [-141.6466, 59.861],
              [-142.0824, 59.9411],
              [-142.669, 60.0115],
              [-142.859, 60.0114],
              [-143.0832, 59.9769],
              [-143.8854, 59.924],
              [-143.89, 60.0423],
              [-143.1671, 60.5188],
              [-141.9631, 60.5214],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        STATEFP: '02',
        SCSDLEA: '',
        GEOID: '200862',
        NAME: 'Yukon-Koyukuk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155553942946,
        AWATER: 2192134043,
        INTPTLAT: '+65.6344779',
        INTPTLON: '-154.3251960',
        ELSDLEA: '',
        UNSDLEA: '00862',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-159.5935, 65.5239],
              [-159.6048, 65.9568],
              [-158.9722, 65.9569],
              [-158.9722, 66.13],
              [-157.9063, 66.1301],
              [-157.9063, 66.4764],
              [-157.0513, 66.4764],
              [-157.0513, 66.3033],
              [-156.6238, 66.3033],
              [-156.6238, 66.4764],
              [-156.1962, 66.4766],
              [-156.1963, 66.3899],
              [-155.9825, 66.3899],
              [-155.9825, 66.3033],
              [-155.555, 66.3033],
              [-155.555, 66.4765],
              [-155.5106, 66.5631],
              [-154.8604, 66.5631],
              [-154.8604, 66.7362],
              [-154.2103, 66.7363],
              [-154.1462, 66.8228],
              [-154.1463, 67.1692],
              [-154.3031, 67.1692],
              [-154.3031, 67.2558],
              [-154.7489, 67.2558],
              [-154.7489, 67.5155],
              [-154.6905, 67.6021],
              [-154.9166, 67.6021],
              [-154.9167, 67.6886],
              [-155.1428, 67.6886],
              [-155.1428, 67.7752],
              [-155.369, 67.7752],
              [-155.3187, 67.9997],
              [-153.0028, 67.9995],
              [-152.081, 67.9996],
              [-150.0006, 67.9996],
              [-150.0, 67.4738],
              [-149.9946, 66.8486],
              [-150.0068, 65.9986],
              [-150.9761, 66.0187],
              [-150.9817, 65.3617],
              [-150.316, 65.2994],
              [-150.0118, 65.2913],
              [-148.6612, 65.2099],
              [-148.6482, 64.6368],
              [-148.4264, 64.5729],
              [-148.2422, 64.4834],
              [-148.1876, 64.4084],
              [-147.9952, 64.3417],
              [-149.1207, 64.3436],
              [-149.668, 64.3578],
              [-150.7497, 64.3651],
              [-151.1362, 64.1331],
              [-151.3013, 64.01],
              [-151.5251, 64.0325],
              [-151.5793, 64.0854],
              [-151.7906, 64.0823],
              [-151.8571, 64.0302],
              [-152.0474, 64.0002],
              [-153.3053, 63.991],
              [-154.4083, 63.986],
              [-156.5612, 63.9674],
              [-158.0856, 63.9475],
              [-159.7184, 63.9547],
              [-159.7312, 64.0524],
              [-159.9564, 64.051],
              [-159.9781, 64.7485],
              [-159.7738, 64.7503],
              [-159.7772, 64.9231],
              [-159.5769, 64.9237],
              [-159.5786, 65.2627],
              [-159.3848, 65.2617],
              [-159.3867, 65.5231],
              [-159.5935, 65.5239],
            ],
            [
              [-156.9979, 64.7595],
              [-156.9869, 64.7163],
              [-156.7246, 64.716],
              [-156.7299, 64.7598],
              [-156.9979, 64.7595],
            ],
            [
              [-152.1591, 65.1798],
              [-151.9826, 65.1505],
              [-151.9817, 65.196],
              [-152.1591, 65.1798],
            ],
            [
              [-149.139, 64.5272],
              [-149.0541, 64.5166],
              [-149.0515, 64.552],
              [-149.139, 64.5272],
            ],
          ],
        ],
      },
    },
  ],
};

import axios from 'axios';
import types from '../types';
import { API_ROOT } from '../config/appConfig';
import { getVisualization } from '../api/visualizations';
import { getHeaders } from './helper.action';
import { newError } from './errors.action';
import modalMessages from '../messages/modal.messages';
import { openModal } from './modal.action';

export const hideVisualizationSnackbar = (id, snackBarType) => (dispatch) => {
  dispatch({ type: types.reducerTypes.HIDE_VISUALIZATION_SNACKBAR, id, snackBarType });
};

export const showVisualizationSnackbar = (id, snackBarType) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SHOW_VISUALIZATION_SNACKBAR, id, snackBarType });
  setTimeout(() => {
    dispatch(hideVisualizationSnackbar(id, 'visualizationConverted'));
  }, 1000 * 30);
};

export const showSpinner = (type, spinnerActionType, showSpinnerParam, Id) => (dispatch) => {
  dispatch({ type, payload: { spinnerActionType, showSpinner: showSpinnerParam, spinnerId: Id } });
};

const isFetchingDashboardData = (isFetchingDashboardDataParam) => (dispatch) => {
  dispatch({
    type: types.reducerTypes.IS_FETCHING_DASHBOARDDATA,
    isFetchingDashboardData: isFetchingDashboardDataParam,
  });
};

export const getDashboardData = (jwt) => async (dispatch) => {
  dispatch(isFetchingDashboardData(true));
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/dashboard-serie-metadata/filtered`,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      dispatch({ type: types.reducerTypes.GET_DASHBOARD_DATA, dashboardData: res.data });
      dispatch(isFetchingDashboardData(false));
    }
  } catch (err) {
    console.error(err);
    dispatch(isFetchingDashboardData(false));
    dispatch({ type: types.reducerTypes.GET_DASHBOARD_DATA_ERR });
  }
};

export const isFetchingVisualizations = (isFetchingVisualizationsParam) => (dispatch) => {
  dispatch({
    type: types.reducerTypes.IS_FETCHING_VISUALIZATIONS,
    isFetchingVisualizations: isFetchingVisualizationsParam,
  });
};

export const getVisualizations = (jwt) => async (dispatch) => {
  dispatch(isFetchingVisualizations(true));
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/visualization/filtered`,
      getHeaders('application/json', jwt)
    );
    if (res && res.status === 200) {
      const visualizations = res.data;
      dispatch({ type: types.reducerTypes.GET_ALL_VISUALIZATIONS, visualizations });
      dispatch(isFetchingVisualizations(false));
    }
  } catch (err) {
    console.error(err);
    dispatch(isFetchingVisualizations(false));
    dispatch(newError(types.errorTypes.visualizationsFetchErr));
  }
};

export const copyVisualization = (id, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(
      types.reducerTypes.VISUALIZATION_SPINNER,
      types.spinnerTypes.copyVisualization,
      true,
      id
    )
  );
  try {
    const res = await axios.post(
      `${API_ROOT}/admin-backend/visualization/${id}/copy`,
      {},
      getHeaders('application/json', jwt)
    );
    if (res.status === 200) {
      const { Copy } = res.data;
      const updatedParent = await getVisualization(res.data.Id, jwt);
      dispatch({ type: types.reducerTypes.VISUALIZATIONS_COPY_CREATED, updatedParent });
      dispatch(showVisualizationSnackbar(Copy, 'copyCreated'));
      dispatch(
        showSpinner(
          types.reducerTypes.VISUALIZATION_SPINNER,
          types.spinnerTypes.copyVisualization,
          false,
          id
        )
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(
        types.reducerTypes.VISUALIZATION_SPINNER,
        types.spinnerTypes.copyVisualization,
        false,
        id
      )
    );
    dispatch(openModal(modalMessages('errorCopyIntoVisualization')));
  }
};

export const convertCopyIntoVisualization = (id, jwt) => async (dispatch) => {
  dispatch(
    showSpinner(
      types.reducerTypes.VISUALIZATION_SPINNER,
      types.spinnerTypes.convertCopyIntoVisualization,
      true,
      id
    )
  );
  dispatch({ type: types.reducerTypes.DRAFT_VISUALIZATIONS_ERROR });
  try {
    const res = await axios.post(
      `${API_ROOT}/admin-backend/visualization/${id}/publish`,
      {},
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      const { Id, Parent } = res.data;
      const updatedVisualization = await getVisualization(Id, jwt);
      dispatch({
        type: types.reducerTypes.VISUALIZATION_COPY_CONVERTED,
        updatedVisualization,
        Parent,
      });
      dispatch(
        showSpinner(
          types.reducerTypes.VISUALIZATION_SPINNER,
          types.spinnerTypes.convertCopyIntoVisualization,
          false,
          id
        )
      );
      dispatch(showVisualizationSnackbar(Id, 'visualizationConverted'));
    }
  } catch (err) {
    console.error(err);
    dispatch(
      showSpinner(
        types.reducerTypes.VISUALIZATION_SPINNER,
        types.spinnerTypes.convertCopyIntoVisualization,
        false,
        id
      )
    );
    dispatch(openModal(modalMessages('errorConvertCopyIntoVisualization')));
  }
};

export const deleteVisualization = (id, jwt) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${API_ROOT}/admin-backend/dashboard-serie-metadata/draft/${id}`,
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      const updatedParent = await getVisualization(res.data.ParentId, jwt);
      dispatch({ type: types.reducerTypes.VISUALIZATION_DELETED, updatedParent });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: types.reducerTypes.VISUALIZATION_DELETED_ERROR });
  }
};

export const selectedVisualization = (Id) => (dispatch) =>
  dispatch({ type: types.reducerTypes.SELECTED_VISUALIZATION, payload: { Id } });

export const existDashboardData = (jwt) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_ROOT}/admin-backend/dashboard-serie-metadata/exist`,
      getHeaders('application/json', jwt)
    );
    if (res.status === 200) {
      dispatch({ type: types.reducerTypes.EXIST_DASHBOARD_DATA, res: res.data });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: types.reducerTypes.EXIST_DASHBOARD_DATA_ERR });
  }
};

export const UT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1729, 40.4671],
            [-112.0066, 40.4267],
            [-111.9138, 40.4524],
            [-111.5939, 40.5771],
            [-111.6204, 40.455],
            [-111.5552, 40.3627],
            [-111.4803, 40.3622],
            [-111.4287, 40.2878],
            [-111.3213, 40.2874],
            [-111.2686, 40.2464],
            [-111.5424, 40.2472],
            [-111.581, 40.2871],
            [-111.7138, 40.2539],
            [-111.6607, 40.1954],
            [-111.8422, 40.1286],
            [-111.9185, 40.0204],
            [-111.9807, 40.1893],
            [-112.0718, 40.0444],
            [-112.1572, 40.098],
            [-112.1469, 40.1778],
            [-112.1985, 40.3132],
            [-112.1729, 40.4671],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900030',
        NAME: 'Alpine School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1684802122,
        AWATER: 355018998,
        INTPTLAT: '+40.2859903',
        INTPTLON: '-111.8666210',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.05, 38.573],
            [-112.9767, 38.5724],
            [-112.5154, 38.5728],
            [-112.5185, 38.5104],
            [-112.4441, 38.4056],
            [-112.3355, 38.3323],
            [-112.4442, 38.15],
            [-112.4787, 38.1474],
            [-113.4716, 38.1487],
            [-114.05, 38.1488],
            [-114.05, 38.573],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900060',
        NAME: 'Beaver School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6689371467,
        AWATER: 5298466,
        INTPTLAT: '+38.3575350',
        INTPTLON: '-113.2389481',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0415, 41.9939],
            [-113.8149, 41.9887],
            [-113.0008, 41.9982],
            [-112.1592, 41.9984],
            [-112.0527, 41.863],
            [-112.0264, 41.7764],
            [-112.0517, 41.6996],
            [-111.9966, 41.5576],
            [-111.9165, 41.5386],
            [-111.8854, 41.4264],
            [-112.0287, 41.3365],
            [-112.2381, 41.3366],
            [-112.4935, 41.0769],
            [-112.7994, 40.9999],
            [-114.042, 40.9999],
            [-114.0415, 41.9939],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900090',
        NAME: 'Box Elder School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14880833504,
        AWATER: 2547784400,
        INTPTLAT: '+41.6218833',
        INTPTLON: '-113.0436525',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1592, 41.9984],
            [-112.1095, 41.9978],
            [-111.9336, 41.9986],
            [-111.5078, 41.9996],
            [-111.4713, 41.9282],
            [-111.4269, 41.6779],
            [-111.4429, 41.5314],
            [-111.5108, 41.4231],
            [-111.6666, 41.4288],
            [-111.7262, 41.376],
            [-111.8854, 41.4264],
            [-111.9165, 41.5386],
            [-111.9966, 41.5576],
            [-112.0517, 41.6996],
            [-112.0264, 41.7764],
            [-112.0527, 41.863],
            [-112.1592, 41.9984],
          ],
          [
            [-111.9017, 41.7491],
            [-111.8399, 41.7028],
            [-111.7849, 41.7586],
            [-111.9017, 41.7491],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900120',
        NAME: 'Cache School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2970499292,
        AWATER: 19637302,
        INTPTLAT: '+41.7341676',
        INTPTLON: '-111.7445680',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9214, 40.6277],
            [-111.8659, 40.6303],
            [-111.7677, 40.682],
            [-111.6024, 40.6721],
            [-111.5533, 40.6093],
            [-111.5939, 40.5771],
            [-111.9138, 40.4524],
            [-111.9214, 40.6277],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900142',
        NAME: 'Canyons School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 437272824,
        AWATER: 652357,
        INTPTLAT: '+40.5850713',
        INTPTLON: '-111.7186960',
        ELSDLEA: '',
        UNSDLEA: '00142',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2475, 39.813],
            [-110.8578, 39.8133],
            [-110.5182, 39.8067],
            [-109.9768, 39.8062],
            [-110.0105, 39.6465],
            [-110.024, 39.4693],
            [-110.0241, 39.4693],
            [-111.0775, 39.4697],
            [-111.0854, 39.5212],
            [-111.2474, 39.7043],
            [-111.2475, 39.813],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900150',
        NAME: 'Carbon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3830974536,
        AWATER: 15891973,
        INTPTLAT: '+39.6732840',
        INTPTLON: '-110.5884845',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0007, 40.9975],
            [-109.05, 41.0007],
            [-109.0489, 40.6626],
            [-109.1611, 40.6838],
            [-109.2032, 40.8584],
            [-109.3945, 40.8582],
            [-109.3945, 40.791],
            [-109.4842, 40.7405],
            [-109.5293, 40.8276],
            [-109.8702, 40.7638],
            [-109.9764, 40.8097],
            [-110.0007, 40.8137],
            [-110.0007, 40.9975],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900180',
        NAME: 'Daggett School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1804943545,
        AWATER: 61445336,
        INTPTLAT: '+40.8900990',
        INTPTLON: '-109.5057855',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4935, 41.0769],
            [-112.1867, 41.153],
            [-111.8562, 41.1391],
            [-111.8154, 40.9574],
            [-111.7387, 40.861],
            [-111.7587, 40.8524],
            [-111.9414, 40.8218],
            [-112.0066, 40.9218],
            [-112.2602, 40.7691],
            [-112.4935, 41.0769],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900210',
        NAME: 'Davis School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774432531,
        AWATER: 870761758,
        INTPTLAT: '+40.9514614',
        INTPTLON: '-112.1676198',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0007, 40.8137],
            [-109.9764, 40.8097],
            [-109.9768, 39.8062],
            [-110.5182, 39.8067],
            [-110.8578, 39.8133],
            [-110.8917, 39.8997],
            [-110.902, 40.6782],
            [-110.7507, 40.7477],
            [-110.6565, 40.7403],
            [-110.379, 40.7872],
            [-110.261, 40.8348],
            [-110.0007, 40.8137],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900240',
        NAME: 'Duchesne School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8379461042,
        AWATER: 38798289,
        INTPTLAT: '+40.2896487',
        INTPTLON: '-110.4298303',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.2994, 39.0323],
            [-111.3009, 39.4226],
            [-111.2477, 39.4672],
            [-111.2474, 39.7043],
            [-111.0854, 39.5212],
            [-111.0775, 39.4697],
            [-110.0241, 39.4693],
            [-110.0222, 39.352],
            [-110.0773, 39.2759],
            [-110.1093, 39.113],
            [-110.1562, 39.0327],
            [-110.1347, 38.9336],
            [-110.1625, 38.8639],
            [-110.0819, 38.7567],
            [-109.9943, 38.5271],
            [-110.0254, 38.5],
            [-111.3057, 38.5],
            [-111.3057, 38.5102],
            [-111.2994, 39.0323],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900270',
        NAME: 'Emery School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11557280470,
        AWATER: 24843843,
        INTPTLAT: '+39.0090246',
        INTPTLON: '-110.7210999',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.4787, 38.1474],
            [-112.4442, 38.15],
            [-111.8432, 38.1513],
            [-110.6874, 38.1514],
            [-109.928, 38.1519],
            [-110.0095, 38.1097],
            [-110.0926, 37.9947],
            [-110.2024, 37.9465],
            [-110.2149, 37.8926],
            [-110.3641, 37.8922],
            [-110.4375, 37.8069],
            [-110.5016, 37.65],
            [-110.6464, 37.5411],
            [-111.8408, 37.5358],
            [-112.3613, 37.5364],
            [-112.6838, 37.5437],
            [-112.6892, 37.8056],
            [-112.5786, 37.8045],
            [-112.5784, 37.8821],
            [-112.4682, 37.8905],
            [-112.4787, 38.1474],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900300',
        NAME: 'Garfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13403500715,
        AWATER: 85796666,
        INTPTLAT: '+37.8316645',
        INTPTLON: '-111.4509076',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0241, 39.4693],
            [-110.024, 39.4693],
            [-109.9533, 39.4618],
            [-109.107, 39.462],
            [-109.0512, 39.4977],
            [-109.0508, 39.3667],
            [-109.0601, 38.5],
            [-110.0254, 38.5],
            [-109.9943, 38.5271],
            [-110.0819, 38.7567],
            [-110.1625, 38.8639],
            [-110.1347, 38.9336],
            [-110.1562, 39.0327],
            [-110.1093, 39.113],
            [-110.0773, 39.2759],
            [-110.0222, 39.352],
            [-110.0241, 39.4693],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900330',
        NAME: 'Grand School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9522887450,
        AWATER: 31266643,
        INTPTLAT: '+38.9743261',
        INTPTLON: '-109.5734502',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.2602, 40.7691],
            [-112.0066, 40.9218],
            [-111.9414, 40.8218],
            [-112.1014, 40.8271],
            [-112.0703, 40.7256],
            [-111.8055, 40.715],
            [-111.8151, 40.798],
            [-111.7587, 40.8524],
            [-111.7387, 40.861],
            [-111.6411, 40.7989],
            [-111.6024, 40.6721],
            [-111.7677, 40.682],
            [-111.8659, 40.6303],
            [-111.9293, 40.6313],
            [-112.1962, 40.6317],
            [-112.2602, 40.7691],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900360',
        NAME: 'Granite School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 700968887,
        AWATER: 162658517,
        INTPTLAT: '+40.7012293',
        INTPTLON: '-112.0943765',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.05, 38.1488],
            [-113.4716, 38.1487],
            [-112.4787, 38.1474],
            [-112.4682, 37.8905],
            [-112.5784, 37.8821],
            [-112.5786, 37.8045],
            [-112.6892, 37.8056],
            [-112.6838, 37.5437],
            [-112.9012, 37.5435],
            [-112.901, 37.5],
            [-113.037, 37.4745],
            [-113.2535, 37.4822],
            [-113.2533, 37.529],
            [-113.474, 37.5291],
            [-113.4745, 37.6183],
            [-113.5878, 37.6046],
            [-114.0524, 37.6048],
            [-114.05, 38.1488],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900390',
        NAME: 'Iron School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8537573733,
        AWATER: 11390956,
        INTPTLAT: '+37.9093044',
        INTPTLON: '-113.3067405',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1962, 40.6317],
            [-111.9293, 40.6313],
            [-111.9214, 40.6277],
            [-111.9138, 40.4524],
            [-112.0066, 40.4267],
            [-112.1729, 40.4671],
            [-112.1962, 40.6317],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900420',
        NAME: 'Jordan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 471308333,
        AWATER: 369596,
        INTPTLAT: '+40.5438075',
        INTPTLON: '-112.0469079',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0898, 39.7819],
            [-112.0318, 39.7812],
            [-111.8271, 39.947],
            [-111.6419, 39.8129],
            [-111.5848, 39.7401],
            [-111.7175, 39.6946],
            [-111.7132, 39.4603],
            [-111.7474, 39.417],
            [-111.9224, 39.3816],
            [-112.016, 39.3146],
            [-112.1889, 39.3294],
            [-112.212, 39.554],
            [-112.803, 39.5526],
            [-112.8031, 39.685],
            [-112.3482, 39.685],
            [-112.349, 39.7578],
            [-112.1149, 39.7571],
            [-112.0898, 39.7819],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900450',
        NAME: 'Juab School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3119555628,
        AWATER: 21759093,
        INTPTLAT: '+39.6165006',
        INTPTLON: '-112.0934899',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.901, 37.5],
            [-112.9012, 37.5435],
            [-112.6838, 37.5437],
            [-112.3613, 37.5364],
            [-111.8408, 37.5358],
            [-110.6464, 37.5411],
            [-110.7422, 37.3444],
            [-110.8125, 37.325],
            [-110.8995, 37.1762],
            [-110.9924, 37.1111],
            [-111.1783, 37.1026],
            [-111.2832, 37.0389],
            [-111.4128, 37.0015],
            [-112.0007, 37.001],
            [-112.8992, 37.0004],
            [-112.901, 37.5],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900480',
        NAME: 'Kane School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10333973705,
        AWATER: 306158362,
        INTPTLAT: '+37.2750892',
        INTPTLON: '-111.8153290',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9017, 41.7491],
            [-111.7849, 41.7586],
            [-111.8399, 41.7028],
            [-111.9017, 41.7491],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900510',
        NAME: 'Logan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46118493,
        AWATER: 1516617,
        INTPTLAT: '+41.7402562',
        INTPTLON: '-111.8417642',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0498, 38.6774],
            [-114.0476, 39.5427],
            [-113.8157, 39.5526],
            [-112.803, 39.5526],
            [-112.212, 39.554],
            [-112.1889, 39.3294],
            [-112.016, 39.3146],
            [-112.0141, 39.0455],
            [-112.0652, 38.9593],
            [-112.1339, 38.9593],
            [-112.2245, 38.8648],
            [-112.2184, 38.7274],
            [-112.31, 38.6763],
            [-112.4475, 38.6813],
            [-112.5154, 38.5728],
            [-112.9767, 38.5724],
            [-114.05, 38.573],
            [-114.0498, 38.6774],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900540',
        NAME: 'Millard School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17106720226,
        AWATER: 600823890,
        INTPTLAT: '+39.1103647',
        INTPTLON: '-113.0940216',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.4207, 41.3613],
            [-111.2949, 41.33],
            [-111.2258, 41.191],
            [-111.265, 41.144],
            [-111.3805, 41.0872],
            [-111.5075, 41.0632],
            [-111.5249, 40.9538],
            [-111.4862, 40.8731],
            [-111.5184, 40.7949],
            [-111.6411, 40.7989],
            [-111.7387, 40.861],
            [-111.8154, 40.9574],
            [-111.8562, 41.1391],
            [-111.8578, 41.1978],
            [-111.746, 41.2124],
            [-111.6586, 41.1857],
            [-111.5967, 41.2386],
            [-111.4968, 41.2306],
            [-111.4207, 41.3613],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900570',
        NAME: 'Morgan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1577750990,
        AWATER: 4437369,
        INTPTLAT: '+41.0910273',
        INTPTLON: '-111.5778846',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8659, 40.6303],
            [-111.9214, 40.6277],
            [-111.9293, 40.6313],
            [-111.8659, 40.6303],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900600',
        NAME: 'Murray School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25461998,
        AWATER: 0,
        INTPTLAT: '+40.6513238',
        INTPTLON: '-111.8955478',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.1572, 40.098],
            [-112.0718, 40.0444],
            [-111.9807, 40.1893],
            [-111.9185, 40.0204],
            [-111.8422, 40.1286],
            [-111.6607, 40.1954],
            [-111.5424, 40.2472],
            [-111.2686, 40.2464],
            [-111.2255, 40.1673],
            [-111.2493, 40.0552],
            [-111.0831, 39.9432],
            [-111.0825, 39.9002],
            [-110.8917, 39.8997],
            [-110.8578, 39.8133],
            [-111.2475, 39.813],
            [-111.6419, 39.8129],
            [-111.8271, 39.947],
            [-112.0318, 39.7812],
            [-112.0898, 39.7819],
            [-112.0626, 39.9047],
            [-112.1798, 40.0117],
            [-112.1572, 40.098],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900630',
        NAME: 'Nebo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3398548744,
        AWATER: 2811827,
        INTPTLAT: '+40.0090923',
        INTPTLON: '-111.5595777',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6419, 39.8129],
            [-111.2475, 39.813],
            [-111.2474, 39.7043],
            [-111.2477, 39.4672],
            [-111.3009, 39.4226],
            [-111.7474, 39.417],
            [-111.7132, 39.4603],
            [-111.7175, 39.6946],
            [-111.5848, 39.7401],
            [-111.6419, 39.8129],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900660',
        NAME: 'North Sanpete School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1638811369,
        AWATER: 3460956,
        INTPTLAT: '+39.6083009',
        INTPTLON: '-111.4643074',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-111.0877, 41.2287],
              [-111.0866, 41.2299],
              [-111.0468, 41.2009],
              [-111.0468, 41.156],
              [-111.0877, 41.2287],
            ],
          ],
          [
            [
              [-111.265, 41.144],
              [-111.1487, 41.1832],
              [-111.1329, 40.9432],
              [-111.3235, 40.8982],
              [-111.3246, 40.7821],
              [-111.3793, 40.7379],
              [-111.476, 40.7507],
              [-111.5184, 40.7949],
              [-111.4862, 40.8731],
              [-111.5249, 40.9538],
              [-111.5075, 41.0632],
              [-111.3805, 41.0872],
              [-111.265, 41.144],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900690',
        NAME: 'North Summit School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1066849467,
        AWATER: 10278368,
        INTPTLAT: '+40.9604767',
        INTPTLON: '-111.3327276',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.0258, 41.2098],
            [-111.9691, 41.2853],
            [-111.9287, 41.2665],
            [-111.9598, 41.1976],
            [-112.0258, 41.2098],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900720',
        NAME: 'Ogden School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70286523,
        AWATER: 8202,
        INTPTLAT: '+41.2283911',
        INTPTLON: '-111.9681469',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.6411, 40.7989],
            [-111.5184, 40.7949],
            [-111.476, 40.7507],
            [-111.4373, 40.6843],
            [-111.4898, 40.5993],
            [-111.5533, 40.6093],
            [-111.6024, 40.6721],
            [-111.6411, 40.7989],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900750',
        NAME: 'Park City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227453941,
        AWATER: 645396,
        INTPTLAT: '+40.7066484',
        INTPTLON: '-111.5435735',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5185, 38.5104],
            [-112.1258, 38.5121],
            [-111.7664, 38.5022],
            [-111.8488, 38.4249],
            [-111.8432, 38.1513],
            [-112.4442, 38.15],
            [-112.3355, 38.3323],
            [-112.4441, 38.4056],
            [-112.5185, 38.5104],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900780',
        NAME: 'Piute School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1964346244,
        AWATER: 20285697,
        INTPTLAT: '+38.3358805',
        INTPTLON: '-112.1293755',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5424, 40.2472],
            [-111.6607, 40.1954],
            [-111.7138, 40.2539],
            [-111.581, 40.2871],
            [-111.5424, 40.2472],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900810',
        NAME: 'Provo School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105880129,
        AWATER: 6474977,
        INTPTLAT: '+40.2453301',
        INTPTLON: '-111.6448069',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5078, 41.9996],
            [-111.0467, 42.0017],
            [-111.0458, 41.5798],
            [-111.0468, 41.2516],
            [-111.0866, 41.2299],
            [-111.0877, 41.2287],
            [-111.1297, 41.1983],
            [-111.1323, 41.1961],
            [-111.1487, 41.1832],
            [-111.265, 41.144],
            [-111.2258, 41.191],
            [-111.2949, 41.33],
            [-111.4207, 41.3613],
            [-111.5108, 41.4231],
            [-111.4429, 41.5314],
            [-111.4269, 41.6779],
            [-111.4713, 41.9282],
            [-111.5078, 41.9996],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900840',
        NAME: 'Rich School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2664700973,
        AWATER: 149109879,
        INTPTLAT: '+41.6275976',
        INTPTLON: '-111.2402269',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.9414, 40.8218],
            [-111.7587, 40.8524],
            [-111.8151, 40.798],
            [-111.8055, 40.715],
            [-112.0703, 40.7256],
            [-112.1014, 40.8271],
            [-111.9414, 40.8218],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900870',
        NAME: 'Salt Lake City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286946749,
        AWATER: 1216737,
        INTPTLAT: '+40.7785390',
        INTPTLON: '-111.9298677',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.6464, 37.5411],
            [-110.5016, 37.65],
            [-110.4375, 37.8069],
            [-110.3641, 37.8922],
            [-110.2149, 37.8926],
            [-110.2024, 37.9465],
            [-110.0926, 37.9947],
            [-110.0095, 38.1097],
            [-109.928, 38.1519],
            [-109.9098, 38.2712],
            [-110.032, 38.385],
            [-110.0254, 38.5],
            [-109.0601, 38.5],
            [-109.0602, 38.2755],
            [-109.0426, 38.153],
            [-109.0434, 37.4848],
            [-109.0452, 36.999],
            [-110.0007, 36.9983],
            [-110.7507, 37.003],
            [-111.4117, 37.0015],
            [-111.4128, 37.0015],
            [-111.2832, 37.0389],
            [-111.1783, 37.1026],
            [-110.9924, 37.1111],
            [-110.8995, 37.1762],
            [-110.8125, 37.325],
            [-110.7422, 37.3444],
            [-110.6464, 37.5411],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900900',
        NAME: 'San Juan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20243681585,
        AWATER: 292840510,
        INTPTLAT: '+37.6026332',
        INTPTLON: '-109.7915742',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.5154, 38.5728],
            [-112.4475, 38.6813],
            [-112.31, 38.6763],
            [-112.2184, 38.7274],
            [-112.2245, 38.8648],
            [-112.1339, 38.9593],
            [-112.0652, 38.9593],
            [-112.0141, 39.0455],
            [-111.8533, 39.033],
            [-111.2994, 39.0323],
            [-111.3057, 38.5102],
            [-111.7664, 38.5022],
            [-112.1258, 38.5121],
            [-112.5185, 38.5104],
            [-112.5154, 38.5728],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900930',
        NAME: 'Sevier School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4948102439,
        AWATER: 20260738,
        INTPTLAT: '+38.7468245',
        INTPTLON: '-111.8119299',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-112.016, 39.3146],
            [-111.9224, 39.3816],
            [-111.7474, 39.417],
            [-111.3009, 39.4226],
            [-111.2994, 39.0323],
            [-111.8533, 39.033],
            [-112.0141, 39.0455],
            [-112.016, 39.3146],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900960',
        NAME: 'South Sanpete School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2478956856,
        AWATER: 28588415,
        INTPTLAT: '+39.2268051',
        INTPTLON: '-111.5695882',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-111.0866, 41.2299],
              [-111.0468, 41.2516],
              [-111.0468, 41.2009],
              [-111.0866, 41.2299],
            ],
          ],
          [
            [
              [-111.476, 40.7507],
              [-111.3793, 40.7379],
              [-111.3246, 40.7821],
              [-111.3235, 40.8982],
              [-111.1329, 40.9432],
              [-111.1487, 41.1832],
              [-111.1323, 41.1961],
              [-111.1297, 41.1983],
              [-111.0877, 41.2287],
              [-111.0468, 41.156],
              [-111.0468, 40.9979],
              [-110.5457, 40.9963],
              [-110.0485, 40.9973],
              [-110.0007, 40.9975],
              [-110.0007, 40.8137],
              [-110.261, 40.8348],
              [-110.379, 40.7872],
              [-110.6565, 40.7403],
              [-110.7507, 40.7477],
              [-110.902, 40.6782],
              [-110.9761, 40.593],
              [-111.1209, 40.5905],
              [-111.1472, 40.5496],
              [-111.3755, 40.6331],
              [-111.4373, 40.6843],
              [-111.476, 40.7507],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4900990',
        NAME: 'South Summit School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3550452328,
        AWATER: 15689081,
        INTPTLAT: '+40.8571955',
        INTPTLON: '-110.4849425',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.047, 39.9061],
            [-113.2485, 39.9047],
            [-112.342, 39.9045],
            [-112.1798, 40.0117],
            [-112.0626, 39.9047],
            [-112.0898, 39.7819],
            [-112.1149, 39.7571],
            [-112.349, 39.7578],
            [-112.3482, 39.685],
            [-112.8031, 39.685],
            [-112.803, 39.5526],
            [-113.8157, 39.5526],
            [-114.0476, 39.5427],
            [-114.047, 39.9061],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901020',
        NAME: 'Tintic School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5664915530,
        AWATER: 14782995,
        INTPTLAT: '+39.7763990',
        INTPTLON: '-113.1206877',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0466, 40.1169],
            [-114.042, 40.9999],
            [-112.7994, 40.9999],
            [-112.4935, 41.0769],
            [-112.2602, 40.7691],
            [-112.1962, 40.6317],
            [-112.1729, 40.4671],
            [-112.1985, 40.3132],
            [-112.1469, 40.1778],
            [-112.1572, 40.098],
            [-112.1798, 40.0117],
            [-112.342, 39.9045],
            [-113.2485, 39.9047],
            [-114.047, 39.9061],
            [-114.0466, 40.1169],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901050',
        NAME: 'Tooele School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17979656909,
        AWATER: 891043070,
        INTPTLAT: '+40.4677525',
        INTPTLON: '-113.1239786',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.9768, 39.8062],
            [-109.9764, 40.8097],
            [-109.8702, 40.7638],
            [-109.5293, 40.8276],
            [-109.4842, 40.7405],
            [-109.3945, 40.791],
            [-109.3945, 40.8582],
            [-109.2032, 40.8584],
            [-109.1611, 40.6838],
            [-109.0489, 40.6626],
            [-109.0509, 40.2226],
            [-109.0512, 39.6605],
            [-109.0512, 39.4977],
            [-109.107, 39.462],
            [-109.9533, 39.4618],
            [-110.024, 39.4693],
            [-110.0105, 39.6465],
            [-109.9768, 39.8062],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901080',
        NAME: 'Uintah School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11609285613,
        AWATER: 56154091,
        INTPTLAT: '+40.1258872',
        INTPTLON: '-109.5177482',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.5939, 40.5771],
            [-111.5533, 40.6093],
            [-111.4898, 40.5993],
            [-111.4373, 40.6843],
            [-111.3755, 40.6331],
            [-111.1472, 40.5496],
            [-111.1209, 40.5905],
            [-110.9761, 40.593],
            [-110.902, 40.6782],
            [-110.8917, 39.8997],
            [-111.0825, 39.9002],
            [-111.0831, 39.9432],
            [-111.2493, 40.0552],
            [-111.2255, 40.1673],
            [-111.2686, 40.2464],
            [-111.3213, 40.2874],
            [-111.4287, 40.2878],
            [-111.4803, 40.3622],
            [-111.5552, 40.3627],
            [-111.6204, 40.455],
            [-111.5939, 40.5771],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901110',
        NAME: 'Wasatch School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3048429878,
        AWATER: 79148299,
        INTPTLAT: '+40.3348838',
        INTPTLON: '-111.1615683',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0524, 37.6048],
            [-113.5878, 37.6046],
            [-113.4745, 37.6183],
            [-113.474, 37.5291],
            [-113.2533, 37.529],
            [-113.2535, 37.4822],
            [-113.037, 37.4745],
            [-112.901, 37.5],
            [-112.8992, 37.0004],
            [-112.9376, 37.0003],
            [-113.321, 37],
            [-114.0501, 37.0002],
            [-114.0524, 37.6048],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901140',
        NAME: 'Washington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6285672118,
        AWATER: 9134289,
        INTPTLAT: '+37.2625308',
        INTPTLON: '-113.4878003',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.7664, 38.5022],
            [-111.3057, 38.5102],
            [-111.3057, 38.5],
            [-110.0254, 38.5],
            [-110.032, 38.385],
            [-109.9098, 38.2712],
            [-109.928, 38.1519],
            [-110.6874, 38.1514],
            [-111.8432, 38.1513],
            [-111.8488, 38.4249],
            [-111.7664, 38.5022],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901170',
        NAME: 'Wayne School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6374186210,
        AWATER: 15154124,
        INTPTLAT: '+38.2598402',
        INTPTLON: '-110.9908770',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.8854, 41.4264],
            [-111.7262, 41.376],
            [-111.6666, 41.4288],
            [-111.5108, 41.4231],
            [-111.4207, 41.3613],
            [-111.4968, 41.2306],
            [-111.5967, 41.2386],
            [-111.6586, 41.1857],
            [-111.746, 41.2124],
            [-111.8578, 41.1978],
            [-111.8562, 41.1391],
            [-112.1867, 41.153],
            [-112.4935, 41.0769],
            [-112.2381, 41.3366],
            [-112.0287, 41.3365],
            [-111.8854, 41.4264],
          ],
          [
            [-112.0258, 41.2098],
            [-111.9598, 41.1976],
            [-111.9287, 41.2665],
            [-111.9691, 41.2853],
            [-112.0258, 41.2098],
          ],
        ],
      },
      properties: {
        STATEFP: '49',
        SCSDLEA: '',
        GEOID: '4901200',
        NAME: 'Weber School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1422157338,
        AWATER: 216538383,
        INTPTLAT: '+41.2817567',
        INTPTLON: '-111.9207546',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
  ],
};

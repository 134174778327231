export const NY = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7, 40.7393],
            [-73.7, 40.7394],
            [-73.6871, 40.7549],
            [-73.6599, 40.7426],
            [-73.6609, 40.7357],
            [-73.6796, 40.7284],
            [-73.6829, 40.7167],
            [-73.6555, 40.7075],
            [-73.6677, 40.6918],
            [-73.6866, 40.6951],
            [-73.7133, 40.6786],
            [-73.7258, 40.6836],
            [-73.7292, 40.7192],
            [-73.7, 40.7393],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '26520',
        GEOID: '3626520',
        NAME: 'Sewanhaka Central High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29838925,
        AWATER: 205272,
        INTPTLAT: '+40.7142717',
        INTPTLON: '-073.6937605',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7258, 40.6836],
            [-73.7133, 40.6786],
            [-73.6866, 40.6951],
            [-73.6677, 40.6918],
            [-73.6677, 40.6823],
            [-73.676, 40.6713],
            [-73.6908, 40.6628],
            [-73.6868, 40.6511],
            [-73.7227, 40.6526],
            [-73.7249, 40.6534],
            [-73.7258, 40.6836],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '29520',
        GEOID: '3629520',
        NAME: 'Valley Stream Central High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16422638,
        AWATER: 44613,
        INTPTLAT: '+40.6696221',
        INTPTLON: '-073.7009502',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5767, 40.6943],
            [-73.5611, 40.6987],
            [-73.5319, 40.7064],
            [-73.5261, 40.6918],
            [-73.5214, 40.6737],
            [-73.5124, 40.5228],
            [-73.5293, 40.5252],
            [-73.5501, 40.5285],
            [-73.5732, 40.6691],
            [-73.5736, 40.6716],
            [-73.5749, 40.6908],
            [-73.5767, 40.6943],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '19020',
        GEOID: '3619020',
        NAME: 'Bellmore-Merrick Central High School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36308910,
        AWATER: 34428790,
        INTPTLAT: '+40.6189827',
        INTPTLON: '-073.5460502',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.544, 40.6707],
            [-73.5214, 40.6737],
            [-73.5124, 40.5228],
            [-73.5293, 40.5252],
            [-73.544, 40.6707],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604410',
        NAME: 'Bellmore Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8227579,
        AWATER: 15164387,
        INTPTLAT: '+40.5992327',
        INTPTLON: '-073.5206632',
        ELSDLEA: '04410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7292, 40.7192],
            [-73.6936, 40.7272],
            [-73.6796, 40.7284],
            [-73.6829, 40.7167],
            [-73.6866, 40.6951],
            [-73.7133, 40.6786],
            [-73.7258, 40.6836],
            [-73.7292, 40.7192],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610620',
        NAME: 'Elmont Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12792857,
        AWATER: 114592,
        INTPTLAT: '+40.6991762',
        INTPTLON: '-073.7061660',
        ELSDLEA: '10620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7, 40.7393],
            [-73.6936, 40.7272],
            [-73.7292, 40.7192],
            [-73.7, 40.7393],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611160',
        NAME: 'Floral Park-Bellerose Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4460954,
        AWATER: 34951,
        INTPTLAT: '+40.7233167',
        INTPTLON: '-073.7049494',
        ELSDLEA: '11160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6866, 40.6951],
            [-73.6829, 40.7167],
            [-73.6555, 40.7075],
            [-73.6677, 40.6918],
            [-73.6866, 40.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611460',
        NAME: 'Franklin Square Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6220505,
        AWATER: 0,
        INTPTLAT: '+40.7049467',
        INTPTLON: '-073.6710540',
        ELSDLEA: '11460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5732, 40.6691],
            [-73.5485, 40.6725],
            [-73.544, 40.6707],
            [-73.5293, 40.5252],
            [-73.5501, 40.5285],
            [-73.5732, 40.6691],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619110',
        NAME: 'Merrick Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14017847,
        AWATER: 19242740,
        INTPTLAT: '+40.6073347',
        INTPTLON: '-073.5457214',
        ELSDLEA: '19110',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7, 40.7393],
            [-73.7, 40.7394],
            [-73.6871, 40.7549],
            [-73.6599, 40.7426],
            [-73.6609, 40.7357],
            [-73.6796, 40.7284],
            [-73.6936, 40.7272],
            [-73.7, 40.7393],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620400',
        NAME: 'New Hyde Park-Garden City Park Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6364609,
        AWATER: 55729,
        INTPTLAT: '+40.7409582',
        INTPTLON: '-073.6812648',
        ELSDLEA: '20400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5319, 40.7064],
            [-73.5261, 40.6918],
            [-73.5214, 40.6737],
            [-73.544, 40.6707],
            [-73.5485, 40.6725],
            [-73.5611, 40.6987],
            [-73.5319, 40.7064],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620940',
        NAME: 'North Bellmore Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8632004,
        AWATER: 0,
        INTPTLAT: '+40.6882349',
        INTPTLON: '-073.5393148',
        ELSDLEA: '20940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5767, 40.6943],
            [-73.5611, 40.6987],
            [-73.5485, 40.6725],
            [-73.5732, 40.6691],
            [-73.5736, 40.6716],
            [-73.5749, 40.6908],
            [-73.5767, 40.6943],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621120',
        NAME: 'North Merrick Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5431480,
        AWATER: 21663,
        INTPTLAT: '+40.6843645',
        INTPTLON: '-073.5620583',
        ELSDLEA: '21120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7133, 40.6786],
            [-73.6866, 40.6951],
            [-73.6677, 40.6918],
            [-73.6677, 40.6823],
            [-73.676, 40.6713],
            [-73.6908, 40.6628],
            [-73.7056, 40.667],
            [-73.7133, 40.6786],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629430',
        NAME: 'Valley Stream Union Free School District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8335036,
        AWATER: 38959,
        INTPTLAT: '+40.6791565',
        INTPTLON: '-073.6899614',
        ELSDLEA: '29430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7227, 40.6526],
            [-73.7056, 40.667],
            [-73.6908, 40.6628],
            [-73.6868, 40.6511],
            [-73.7227, 40.6526],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629460',
        NAME: 'Valley Stream Union Free School District 24',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3598753,
        AWATER: 5654,
        INTPTLAT: '+40.6574054',
        INTPTLON: '-073.6989661',
        ELSDLEA: '29460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7258, 40.6836],
            [-73.7133, 40.6786],
            [-73.7056, 40.667],
            [-73.7227, 40.6526],
            [-73.7249, 40.6534],
            [-73.7258, 40.6836],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629490',
        NAME: 'Valley Stream Union Free School District 30',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4488849,
        AWATER: 0,
        INTPTLAT: '+40.6675132',
        INTPTLON: '-073.7180994',
        ELSDLEA: '29490',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6711, 40.8593],
            [-73.5926, 40.8568],
            [-73.5864, 40.8258],
            [-73.6211, 40.8116],
            [-73.65, 40.8158],
            [-73.6711, 40.8593],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626370',
        NAME: 'North Shore Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20841637,
        AWATER: 2810849,
        INTPTLAT: '+40.8363406',
        INTPTLON: '-073.6240579',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4948, 40.6909],
            [-73.4821, 40.6913],
            [-73.4817, 40.6883],
            [-73.471, 40.5247],
            [-73.5009, 40.5218],
            [-73.4948, 40.6909],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626400',
        NAME: 'Seaford Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12238393,
        AWATER: 23284927,
        INTPTLAT: '+40.5971567',
        INTPTLON: '-073.4894940',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6163, 40.6906],
            [-73.609, 40.7203],
            [-73.6171, 40.743],
            [-73.593, 40.7472],
            [-73.5875, 40.7468],
            [-73.5767, 40.6943],
            [-73.5749, 40.6908],
            [-73.6028, 40.6847],
            [-73.6198, 40.6812],
            [-73.6163, 40.6906],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629280',
        NAME: 'Uniondale Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17190813,
        AWATER: 35783,
        INTPTLAT: '+40.7148763',
        INTPTLON: '-073.5958961',
        ELSDLEA: '',
        UNSDLEA: '29280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5261, 40.6918],
            [-73.4948, 40.6909],
            [-73.5009, 40.5218],
            [-73.5124, 40.5228],
            [-73.5214, 40.6737],
            [-73.5261, 40.6918],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629850',
        NAME: 'Wantagh Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12725880,
        AWATER: 11011636,
        INTPTLAT: '+40.6180533',
        INTPTLON: '-073.5105908',
        ELSDLEA: '',
        UNSDLEA: '29850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0858, 42.4158],
            [-77.0499, 42.4023],
            [-76.9879, 42.3108],
            [-77.1093, 42.2813],
            [-77.1806, 42.3573],
            [-77.0858, 42.4158],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605220',
        NAME: 'Bradford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146569739,
        AWATER: 3713967,
        INTPTLAT: '+42.3488734',
        INTPTLON: '-077.0839746',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2717, 42.4592],
            [-77.2194, 42.4705],
            [-77.1666, 42.5767],
            [-77.1076, 42.5054],
            [-77.0858, 42.4158],
            [-77.1806, 42.3573],
            [-77.2265, 42.3448],
            [-77.2717, 42.4592],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613470',
        NAME: 'Hammondsport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188164477,
        AWATER: 24913636,
        INTPTLAT: '+42.4226577',
        INTPTLON: '-077.1832884',
        ELSDLEA: '',
        UNSDLEA: '13470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0504, 42.5402],
            [-75.0108, 42.6194],
            [-74.9106, 42.6591],
            [-74.8562, 42.6489],
            [-74.9468, 42.5037],
            [-75.0504, 42.5402],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619350',
        NAME: 'Milford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149155350,
        AWATER: 3138384,
        INTPTLAT: '+42.5798608',
        INTPTLON: '-074.9550875',
        ELSDLEA: '',
        UNSDLEA: '19350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6882, 40.7791],
            [-73.6629, 40.7827],
            [-73.6498, 40.78],
            [-73.6599, 40.7426],
            [-73.6871, 40.7549],
            [-73.6882, 40.7791],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614280',
        NAME: 'Herricks Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11189419,
        AWATER: 0,
        INTPTLAT: '+40.7653062',
        INTPTLON: '-073.6663956',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8391, 42.3521],
            [-77.6925, 42.3676],
            [-77.6789, 42.3047],
            [-77.7409, 42.2047],
            [-77.8776, 42.1935],
            [-77.9567, 42.2316],
            [-77.8391, 42.3521],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602700',
        NAME: 'Alfred-Almond Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261762763,
        AWATER: 905611,
        INTPTLAT: '+42.2740486',
        INTPTLON: '-077.7925765',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2829, 42.6622],
            [-78.1187, 42.6601],
            [-78.1035, 42.7117],
            [-77.9893, 42.6499],
            [-78.0155, 42.6093],
            [-78.0807, 42.5429],
            [-78.2787, 42.5132],
            [-78.2829, 42.6622],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617130',
        NAME: 'Letchworth Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350685798,
        AWATER: 1379583,
        INTPTLAT: '+42.6033634',
        INTPTLON: '-078.1519478',
        ELSDLEA: '',
        UNSDLEA: '17130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4444, 42.0219],
            [-78.3904, 42.1089],
            [-78.3088, 42.1405],
            [-78.2635, 42.1405],
            [-78.2192, 42.0639],
            [-78.284, 41.9991],
            [-78.3071, 41.9994],
            [-78.4442, 41.9997],
            [-78.4444, 42.0219],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623640',
        NAME: 'Portville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187176559,
        AWATER: 1283516,
        INTPTLAT: '+42.0649264',
        INTPTLON: '-078.3255855',
        ELSDLEA: '',
        UNSDLEA: '23640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3666, 42.4114],
            [-78.3085, 42.4695],
            [-78.177, 42.4032],
            [-78.1813, 42.2606],
            [-78.2051, 42.1734],
            [-78.2635, 42.1405],
            [-78.3088, 42.1405],
            [-78.3629, 42.2501],
            [-78.3211, 42.2724],
            [-78.3666, 42.4114],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3632010',
        NAME: 'Cuba-Rushford Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404458183,
        AWATER: 4974418,
        INTPTLAT: '+42.3128850',
        INTPTLON: '-078.2776263',
        ELSDLEA: '',
        UNSDLEA: '32010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9963, 43.2666],
            [-77.9217, 43.3588],
            [-77.8964, 43.3563],
            [-77.8466, 43.236],
            [-77.8822, 43.1715],
            [-77.9093, 43.1269],
            [-78.0178, 43.1431],
            [-77.9963, 43.2666],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605580',
        NAME: 'Brockport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201304061,
        AWATER: 919342,
        INTPTLAT: '+43.2254844',
        INTPTLON: '-077.9264845',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5498, 43.2366],
            [-77.5338, 43.2364],
            [-77.5328, 43.1676],
            [-77.5335, 43.1676],
            [-77.5498, 43.2366],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609690',
        NAME: 'East Irondequoit Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20709825,
        AWATER: 3977846,
        INTPTLAT: '+43.2009274',
        INTPTLON: '-077.5513991',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7542, 43.145],
            [-77.7015, 43.1794],
            [-77.6834, 43.1793],
            [-77.6501, 43.1257],
            [-77.654, 43.1258],
            [-77.6826, 43.1178],
            [-77.7304, 43.0917],
            [-77.7542, 43.145],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611880',
        NAME: 'Gates-Chili Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50442210,
        AWATER: 407256,
        INTPTLAT: '+43.1348761',
        INTPTLON: '-077.7121980',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5355, 40.7433],
            [-73.5158, 40.743],
            [-73.4854, 40.7087],
            [-73.4821, 40.6913],
            [-73.4948, 40.6909],
            [-73.5261, 40.6918],
            [-73.5319, 40.7064],
            [-73.5355, 40.7433],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617160',
        NAME: 'Levittown Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16453513,
        AWATER: 88085,
        INTPTLAT: '+40.7139110',
        INTPTLON: '-073.5128344',
        ELSDLEA: '',
        UNSDLEA: '17160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6039, 40.7865],
            [-73.5601, 40.7715],
            [-73.5496, 40.7567],
            [-73.5875, 40.7468],
            [-73.593, 40.7472],
            [-73.6024, 40.763],
            [-73.6039, 40.7865],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630960',
        NAME: 'Westbury Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14020002,
        AWATER: 0,
        INTPTLAT: '+40.7671390',
        INTPTLON: '-073.5809634',
        ELSDLEA: '',
        UNSDLEA: '30960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7285, 40.651],
            [-73.7249, 40.6534],
            [-73.7227, 40.6526],
            [-73.6868, 40.6511],
            [-73.6787, 40.6278],
            [-73.705, 40.6143],
            [-73.7285, 40.651],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631710',
        NAME: 'Hewlett-Woodmere Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9189051,
        AWATER: 605897,
        INTPTLAT: '+40.6358824',
        INTPTLON: '-073.7004957',
        ELSDLEA: '',
        UNSDLEA: '31710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9493, 42.3344],
            [-73.9453, 42.3414],
            [-73.943, 42.3519],
            [-73.8562, 42.3872],
            [-73.8665, 42.4495],
            [-73.7778, 42.4248],
            [-73.781, 42.3018],
            [-73.823, 42.2462],
            [-73.9167, 42.3034],
            [-73.9493, 42.3344],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608490',
        NAME: 'Coxsackie-Athens Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158390911,
        AWATER: 11031376,
        INTPTLAT: '+42.3474292',
        INTPTLON: '-073.8443517',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9755, 42.5427],
            [-73.8919, 42.5868],
            [-73.7522, 42.5667],
            [-73.7837, 42.4642],
            [-73.7778, 42.4248],
            [-73.8665, 42.4495],
            [-73.9662, 42.4612],
            [-73.9755, 42.5427],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624150',
        NAME: 'Ravena-Coeymans-Selkirk Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219568062,
        AWATER: 10806351,
        INTPTLAT: '+42.5152320',
        INTPTLON: '-073.8624313',
        ELSDLEA: '',
        UNSDLEA: '24150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.1866, 42.5392],
              [-74.0007, 42.5566],
              [-73.9755, 42.5427],
              [-73.9662, 42.4612],
              [-73.8665, 42.4495],
              [-73.8562, 42.3872],
              [-73.943, 42.3519],
              [-74.0302, 42.3509],
              [-74.185, 42.4446],
              [-74.1866, 42.5392],
            ],
          ],
          [
            [
              [-74.2783, 42.4145],
              [-74.1982, 42.4145],
              [-74.2745, 42.4053],
              [-74.2783, 42.4145],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612870',
        NAME: 'Greenville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348697136,
        AWATER: 3457729,
        INTPTLAT: '+42.4387709',
        INTPTLON: '-074.0356875',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5472, 43.1429],
            [-77.5335, 43.1676],
            [-77.5328, 43.1676],
            [-77.3834, 43.1665],
            [-77.3587, 43.143],
            [-77.3552, 43.1366],
            [-77.3534, 43.131],
            [-77.3525, 43.1178],
            [-77.3729, 43.1074],
            [-77.4761, 43.1224],
            [-77.5112, 43.1242],
            [-77.5348, 43.134],
            [-77.5472, 43.1429],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622710',
        NAME: 'Penfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66258531,
        AWATER: 566958,
        INTPTLAT: '+43.1514642',
        INTPTLON: '-077.4443955',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6131, 40.9508],
            [-73.6129, 40.9509],
            [-73.5622, 40.9617],
            [-73.5505, 40.8231],
            [-73.5864, 40.8258],
            [-73.5926, 40.8568],
            [-73.6131, 40.9508],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617700',
        NAME: 'Locust Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38707309,
        AWATER: 35290107,
        INTPTLAT: '+40.8985783',
        INTPTLON: '-073.5786631',
        ELSDLEA: '',
        UNSDLEA: '17700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8633, 42.6459],
            [-75.774, 42.7411],
            [-75.776, 42.818],
            [-75.7322, 42.8446],
            [-75.6784, 42.8241],
            [-75.7053, 42.7409],
            [-75.6249, 42.6454],
            [-75.7172, 42.5917],
            [-75.8633, 42.6459],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622100',
        NAME: 'Georgetown-South Otselic Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302612533,
        AWATER: 1188106,
        INTPTLAT: '+42.6929044',
        INTPTLON: '-075.7366554',
        ELSDLEA: '',
        UNSDLEA: '22100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4506, 42.4622],
            [-75.4252, 42.4734],
            [-75.3254, 42.5222],
            [-75.2058, 42.5065],
            [-75.2079, 42.5027],
            [-75.2536, 42.4178],
            [-75.3854, 42.3623],
            [-75.4126, 42.3603],
            [-75.4506, 42.4622],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604757',
        NAME: 'Gilbertsville-Mount Upton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194045923,
        AWATER: 119712,
        INTPTLAT: '+42.4625345',
        INTPTLON: '-075.3513704',
        ELSDLEA: '',
        UNSDLEA: '04757',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7573, 42.5461],
            [-75.5609, 42.5187],
            [-75.5415, 42.4472],
            [-75.5795, 42.3458],
            [-75.6468, 42.328],
            [-75.6554, 42.3396],
            [-75.7024, 42.4555],
            [-75.7778, 42.4866],
            [-75.7573, 42.5461],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622260',
        NAME: 'Oxford Academy and Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297064235,
        AWATER: 2117675,
        INTPTLAT: '+42.4427479',
        INTPTLON: '-075.6446522',
        ELSDLEA: '',
        UNSDLEA: '22260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.441, 42.1731],
            [-74.3076, 42.1143],
            [-74.0754, 42.0966],
            [-74.0838, 42.0384],
            [-74.1341, 41.931],
            [-74.2588, 41.8612],
            [-74.3554, 41.9131],
            [-74.3378, 41.9511],
            [-74.4963, 42.0345],
            [-74.5314, 42.1275],
            [-74.441, 42.1731],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621840',
        NAME: 'Onteora Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 707268316,
        AWATER: 34385633,
        INTPTLAT: '+42.0430652',
        INTPTLON: '-074.2948265',
        ELSDLEA: '',
        UNSDLEA: '21840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4126, 42.3603],
            [-75.3854, 42.3623],
            [-75.3227, 42.3103],
            [-75.2212, 42.3247],
            [-75.1836, 42.2615],
            [-75.332, 42.2044],
            [-75.4028, 42.1772],
            [-75.4126, 42.3603],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626850',
        NAME: 'Sidney Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225238035,
        AWATER: 1361237,
        INTPTLAT: '+42.2643286',
        INTPTLON: '-075.3337295',
        ELSDLEA: '',
        UNSDLEA: '26850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0155, 42.6093],
            [-77.9747, 42.6541],
            [-77.8415, 42.6544],
            [-77.8407, 42.5178],
            [-77.956, 42.4391],
            [-77.9909, 42.5095],
            [-78.0807, 42.5429],
            [-78.0155, 42.6093],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621450',
        NAME: 'Dalton-Nunda Central School District (Keshequa)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301405981,
        AWATER: 1070610,
        INTPTLAT: '+42.5649617',
        INTPTLON: '-077.9364897',
        ELSDLEA: '',
        UNSDLEA: '21450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8201, 42.0792],
            [-77.7469, 42.0695],
            [-77.6343, 42.0394],
            [-77.6357, 41.9994],
            [-77.8632, 41.9984],
            [-77.8201, 42.0792],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631350',
        NAME: 'Whitesville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125127816,
        AWATER: 158925,
        INTPTLAT: '+42.0206464',
        INTPTLON: '-077.7603310',
        ELSDLEA: '',
        UNSDLEA: '31350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2787, 42.5132],
            [-78.0807, 42.5429],
            [-77.9909, 42.5095],
            [-77.956, 42.4391],
            [-77.9457, 42.4062],
            [-78.0292, 42.3868],
            [-78.177, 42.4032],
            [-78.3085, 42.4695],
            [-78.2787, 42.5132],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611070',
        NAME: 'Fillmore Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287119834,
        AWATER: 1996458,
        INTPTLAT: '+42.4701295',
        INTPTLON: '-078.1151581',
        ELSDLEA: '',
        UNSDLEA: '11070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1191, 42.2604],
            [-78.0292, 42.3868],
            [-77.9457, 42.4062],
            [-77.8787, 42.3741],
            [-77.8391, 42.3521],
            [-77.9567, 42.2316],
            [-78.0742, 42.186],
            [-78.1191, 42.2604],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600017',
        NAME: 'Genesee Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304658581,
        AWATER: 588798,
        INTPTLAT: '+42.2973511',
        INTPTLON: '-077.9871417',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1813, 42.2606],
            [-78.1191, 42.2604],
            [-78.0742, 42.186],
            [-78.0774, 42.1682],
            [-78.2051, 42.1734],
            [-78.1813, 42.2606],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611640',
        NAME: 'Friendship Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104250992,
        AWATER: 106263,
        INTPTLAT: '+42.2040350',
        INTPTLON: '-078.1404889',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6908, 40.6628],
            [-73.676, 40.6713],
            [-73.6619, 40.6567],
            [-73.6763, 40.629],
            [-73.6787, 40.6278],
            [-73.6868, 40.6511],
            [-73.6908, 40.6628],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617910',
        NAME: 'Lynbrook Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5491686,
        AWATER: 52729,
        INTPTLAT: '+40.6518304',
        INTPTLON: '-073.6772530',
        ELSDLEA: '',
        UNSDLEA: '17910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.676, 40.6713],
            [-73.6677, 40.6823],
            [-73.6381, 40.6837],
            [-73.6538, 40.6573],
            [-73.6619, 40.6567],
            [-73.676, 40.6713],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618210',
        NAME: 'Malverne Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5272068,
        AWATER: 539214,
        INTPTLAT: '+40.6719104',
        INTPTLON: '-073.6594783',
        ELSDLEA: '',
        UNSDLEA: '18210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7116, 40.8171],
            [-73.6684, 40.8046],
            [-73.6629, 40.7827],
            [-73.6882, 40.7791],
            [-73.7116, 40.8171],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618270',
        NAME: 'Manhasset Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12524350,
        AWATER: 724799,
        INTPTLAT: '+40.7961633',
        INTPTLON: '-073.6910295',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5622, 40.9617],
            [-73.5537, 40.9635],
            [-73.5278, 40.9689],
            [-73.497, 40.849],
            [-73.5223, 40.8248],
            [-73.5505, 40.8231],
            [-73.5622, 40.9617],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622290',
        NAME: 'Oyster Bay-East Norwich Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33393908,
        AWATER: 30550514,
        INTPTLAT: '+40.8928530',
        INTPTLON: '-073.5235492',
        ELSDLEA: '',
        UNSDLEA: '22290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4911, 40.7321],
            [-73.4748, 40.7314],
            [-73.4644, 40.6946],
            [-73.4817, 40.6883],
            [-73.4821, 40.6913],
            [-73.4854, 40.7087],
            [-73.4911, 40.7321],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623190',
        NAME: 'Plainedge Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7216473,
        AWATER: 0,
        INTPTLAT: '+40.7099827',
        INTPTLON: '-073.4770700',
        ELSDLEA: '',
        UNSDLEA: '23190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4723, 42.2168],
            [-78.3629, 42.2501],
            [-78.3088, 42.1405],
            [-78.3904, 42.1089],
            [-78.4632, 42.1088],
            [-78.4723, 42.2168],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614490',
        NAME: 'Hinsdale Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149240111,
        AWATER: 281039,
        INTPTLAT: '+42.1748866',
        INTPTLON: '-078.3976420',
        ELSDLEA: '',
        UNSDLEA: '14490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4487, 40.8044],
            [-73.4367, 40.7423],
            [-73.4629, 40.7447],
            [-73.5009, 40.7676],
            [-73.503, 40.7828],
            [-73.4487, 40.8044],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623220',
        NAME: 'Plainview-Old Bethpage Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21134908,
        AWATER: 36158,
        INTPTLAT: '+40.7754829',
        INTPTLON: '-073.4644782',
        ELSDLEA: '',
        UNSDLEA: '23220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7481, 40.8717],
            [-73.7406, 40.8762],
            [-73.7097, 40.8942],
            [-73.6944, 40.9031],
            [-73.6711, 40.8593],
            [-73.65, 40.8158],
            [-73.6684, 40.8046],
            [-73.7116, 40.8171],
            [-73.758, 40.8455],
            [-73.7481, 40.8717],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623580',
        NAME: 'Port Washington Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27103132,
        AWATER: 27078948,
        INTPTLAT: '+40.8533789',
        INTPTLON: '-073.7035420',
        ELSDLEA: '',
        UNSDLEA: '23580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6381, 40.6837],
            [-73.637, 40.6839],
            [-73.6163, 40.6906],
            [-73.6198, 40.6812],
            [-73.6216, 40.6617],
            [-73.6556, 40.6494],
            [-73.6538, 40.6573],
            [-73.6381, 40.6837],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624780',
        NAME: 'Rockville Centre Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7674222,
        AWATER: 0,
        INTPTLAT: '+40.6688233',
        INTPTLON: '-073.6358165',
        ELSDLEA: '',
        UNSDLEA: '24780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7219, 43.1575],
            [-76.6244, 43.188],
            [-76.6313, 43.2613],
            [-76.4792, 43.2275],
            [-76.4375, 43.1279],
            [-76.4994, 43.0972],
            [-76.6283, 43.114],
            [-76.7055, 43.1277],
            [-76.7219, 43.1575],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606690',
        NAME: 'Cato-Meridian Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242551745,
        AWATER: 8731823,
        INTPTLAT: '+43.1787544',
        INTPTLON: '-076.5621430',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9641, 43.2838],
            [-76.7822, 43.3192],
            [-76.7219, 43.1575],
            [-76.7055, 43.1277],
            [-76.7234, 43.103],
            [-76.9634, 43.124],
            [-76.9715, 43.1895],
            [-76.9641, 43.2838],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616980',
        NAME: 'North Rose-Wolcott Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319686192,
        AWATER: 22239565,
        INTPTLAT: '+43.2096914',
        INTPTLON: '-076.8520602',
        ELSDLEA: '',
        UNSDLEA: '16980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4273, 40.9203],
            [-73.3917, 40.9081],
            [-73.3859, 40.848],
            [-73.435, 40.8461],
            [-73.4273, 40.9203],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615090',
        NAME: 'Huntington Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25806496,
        AWATER: 4356046,
        INTPTLAT: '+40.8795869',
        INTPTLON: '-073.4163714',
        ELSDLEA: '',
        UNSDLEA: '15090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6028, 40.6847],
            [-73.5749, 40.6908],
            [-73.5736, 40.6716],
            [-73.5938, 40.6685],
            [-73.6028, 40.6847],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624990',
        NAME: 'Roosevelt Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4763355,
        AWATER: 23348,
        INTPTLAT: '+40.6802310',
        INTPTLON: '-073.5865123',
        ELSDLEA: '',
        UNSDLEA: '24990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6684, 40.8046],
            [-73.65, 40.8158],
            [-73.6211, 40.8116],
            [-73.6105, 40.8027],
            [-73.643, 40.7799],
            [-73.6498, 40.78],
            [-73.6629, 40.7827],
            [-73.6684, 40.8046],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625050',
        NAME: 'Roslyn Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13930423,
        AWATER: 555350,
        INTPTLAT: '+40.8002283',
        INTPTLON: '-073.6405628',
        ELSDLEA: '',
        UNSDLEA: '25050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3145, 42.7591],
            [-77.2682, 42.8547],
            [-77.17, 42.8787],
            [-77.0849, 42.8708],
            [-77.0188, 42.767],
            [-77.0785, 42.7637],
            [-77.1606, 42.6669],
            [-77.2438, 42.6497],
            [-77.2659, 42.6351],
            [-77.3536, 42.7],
            [-77.3145, 42.7591],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612300',
        NAME: 'Gorham-Middlesex Central School District (Marcus Whitman)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413007420,
        AWATER: 19539233,
        INTPTLAT: '+42.7647757',
        INTPTLON: '-077.1949664',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0188, 42.767],
            [-76.9476, 42.7592],
            [-76.9364, 42.7173],
            [-76.8944, 42.6073],
            [-77.0875, 42.5458],
            [-77.1076, 42.5054],
            [-77.1666, 42.5767],
            [-77.2438, 42.6497],
            [-77.1606, 42.6669],
            [-77.0785, 42.7637],
            [-77.0188, 42.767],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622740',
        NAME: 'Penn Yan Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407952728,
        AWATER: 64400819,
        INTPTLAT: '+42.6468897',
        INTPTLON: '-077.0616797',
        ELSDLEA: '',
        UNSDLEA: '22740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0315, 43.1625],
            [-76.9715, 43.1895],
            [-76.9634, 43.124],
            [-76.9261, 43.0152],
            [-76.9541, 42.9696],
            [-77.0332, 43.0131],
            [-77.0315, 43.1625],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618030',
        NAME: 'Lyons Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147457897,
        AWATER: 607767,
        INTPTLAT: '+43.0736919',
        INTPTLON: '-076.9861188',
        ELSDLEA: '',
        UNSDLEA: '18030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8982, 42.159],
            [-77.8776, 42.1935],
            [-77.7409, 42.2047],
            [-77.7469, 42.0695],
            [-77.8201, 42.0792],
            [-77.8982, 42.159],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603030',
        NAME: 'Andover Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143041638,
        AWATER: 319640,
        INTPTLAT: '+42.1536232',
        INTPTLON: '-077.8023395',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.177, 42.4032],
            [-78.0292, 42.3868],
            [-78.1191, 42.2604],
            [-78.1813, 42.2606],
            [-78.177, 42.4032],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604350',
        NAME: 'Belfast Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179941805,
        AWATER: 1875370,
        INTPTLAT: '+42.3453499',
        INTPTLON: '-078.1208056',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9422, 43.0271],
            [-77.9086, 43.0448],
            [-77.7825, 43.0677],
            [-77.7329, 42.9819],
            [-77.7345, 42.9548],
            [-77.804, 42.9187],
            [-77.9291, 42.9192],
            [-77.9342, 42.9456],
            [-77.9422, 43.0271],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606180',
        NAME: 'Caledonia-Mumford Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153899744,
        AWATER: 657754,
        INTPTLAT: '+42.9802348',
        INTPTLON: '-077.8499312',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7825, 43.0677],
            [-77.7304, 43.0917],
            [-77.6826, 43.1178],
            [-77.654, 43.1258],
            [-77.6501, 43.1257],
            [-77.658, 43.1034],
            [-77.7329, 42.9819],
            [-77.7825, 43.0677],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631170',
        NAME: 'Wheatland-Chili Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92012704,
        AWATER: 1063119,
        INTPTLAT: '+43.0428652',
        INTPTLON: '-077.7528014',
        ELSDLEA: '',
        UNSDLEA: '31170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7822, 43.3192],
            [-76.6753, 43.3616],
            [-76.6313, 43.2613],
            [-76.6244, 43.188],
            [-76.7219, 43.1575],
            [-76.7822, 43.3192],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624210',
        NAME: 'Red Creek Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203173942,
        AWATER: 7924154,
        INTPTLAT: '+43.2534211',
        INTPTLON: '-076.7022854',
        ELSDLEA: '',
        UNSDLEA: '24210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2745, 42.4053],
            [-74.1982, 42.4145],
            [-74.185, 42.4446],
            [-74.0302, 42.3509],
            [-73.943, 42.3519],
            [-73.9453, 42.3414],
            [-73.9493, 42.3344],
            [-73.9167, 42.3034],
            [-74.0564, 42.2216],
            [-74.1046, 42.2678],
            [-74.1071, 42.3005],
            [-74.2589, 42.3792],
            [-74.2745, 42.4053],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606160',
        NAME: 'Cairo-Durham Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303111133,
        AWATER: 767227,
        INTPTLAT: '+42.3304403',
        INTPTLON: '-074.0786844',
        ELSDLEA: '',
        UNSDLEA: '06160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.4971, 42.3969],
              [-74.49, 42.4161],
              [-74.5028, 42.5029],
              [-74.4959, 42.5293],
              [-74.3093, 42.4819],
              [-74.2783, 42.4145],
              [-74.2745, 42.4053],
              [-74.2589, 42.3792],
              [-74.341, 42.3585],
              [-74.3941, 42.2819],
              [-74.4787, 42.2809],
              [-74.4435, 42.355],
              [-74.4971, 42.3969],
            ],
          ],
          [
            [
              [-74.5285, 42.5225],
              [-74.5233, 42.5261],
              [-74.5114, 42.4969],
              [-74.523, 42.501],
              [-74.5285, 42.5225],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612120',
        NAME: 'Gilboa-Conesville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331435702,
        AWATER: 7037465,
        INTPTLAT: '+42.4102721',
        INTPTLON: '-074.3957343',
        ELSDLEA: '',
        UNSDLEA: '12120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2589, 42.3792],
            [-74.1071, 42.3005],
            [-74.1046, 42.2678],
            [-74.3095, 42.2406],
            [-74.3941, 42.2819],
            [-74.341, 42.3585],
            [-74.2589, 42.3792],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631590',
        NAME: 'Windham-Ashland-Jewett Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249053660,
        AWATER: 509669,
        INTPTLAT: '+42.2931991',
        INTPTLON: '-074.2498455',
        ELSDLEA: '',
        UNSDLEA: '31590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4787, 42.2809],
            [-74.3941, 42.2819],
            [-74.3095, 42.2406],
            [-74.1046, 42.2678],
            [-74.0564, 42.2216],
            [-74.0424, 42.1704],
            [-74.0754, 42.0966],
            [-74.3076, 42.1143],
            [-74.441, 42.1731],
            [-74.4328, 42.1849],
            [-74.4321, 42.2034],
            [-74.4861, 42.2709],
            [-74.4787, 42.2809],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615060',
        NAME: 'Hunter-Tannersville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452116642,
        AWATER: 1238313,
        INTPTLAT: '+42.1824106',
        INTPTLON: '-074.2229908',
        ELSDLEA: '',
        UNSDLEA: '15060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4817, 40.6883],
            [-73.4644, 40.6946],
            [-73.4329, 40.7005],
            [-73.4253, 40.6518],
            [-73.4238, 40.6095],
            [-73.4253, 40.5343],
            [-73.471, 40.5247],
            [-73.4817, 40.6883],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618630',
        NAME: 'Massapequa Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27800557,
        AWATER: 52061005,
        INTPTLAT: '+40.6318174',
        INTPTLON: '-073.4471934',
        ELSDLEA: '',
        UNSDLEA: '18630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2635, 42.1405],
            [-78.2051, 42.1734],
            [-78.0774, 42.1682],
            [-78.1027, 42.0757],
            [-78.0719, 42.0181],
            [-78.0726, 41.9999],
            [-78.284, 41.9991],
            [-78.2192, 42.0639],
            [-78.2635, 42.1405],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600012',
        NAME: 'Bolivar-Richburg Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254456999,
        AWATER: 245879,
        INTPTLAT: '+42.0816729',
        INTPTLON: '-078.1768684',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8787, 42.3741],
            [-77.8541, 42.406],
            [-77.8514, 42.4059],
            [-77.7852, 42.38],
            [-77.7077, 42.4795],
            [-77.6322, 42.4603],
            [-77.5667, 42.4286],
            [-77.5636, 42.3912],
            [-77.6925, 42.3676],
            [-77.8391, 42.3521],
            [-77.8787, 42.3741],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603240',
        NAME: 'Arkport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163781158,
        AWATER: 541954,
        INTPTLAT: '+42.4209974',
        INTPTLON: '-077.7113473',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.956, 42.4391],
            [-77.8407, 42.5178],
            [-77.7077, 42.4795],
            [-77.7852, 42.38],
            [-77.8514, 42.4059],
            [-77.8541, 42.406],
            [-77.8787, 42.3741],
            [-77.9457, 42.4062],
            [-77.956, 42.4391],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606360',
        NAME: 'Canaseraga Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199909177,
        AWATER: 1474968,
        INTPTLAT: '+42.4461991',
        INTPTLON: '-077.8221268',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.566, 42.5646],
            [-78.4624, 42.6065],
            [-78.4322, 42.7154],
            [-78.3206, 42.6734],
            [-78.2829, 42.6622],
            [-78.2787, 42.5132],
            [-78.3085, 42.4695],
            [-78.3666, 42.4114],
            [-78.4309, 42.4282],
            [-78.5554, 42.3711],
            [-78.576, 42.4774],
            [-78.566, 42.5646],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608970',
        NAME: 'Yorkshire-Pioneer Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 589131704,
        AWATER: 4601619,
        INTPTLAT: '+42.5359619',
        INTPTLON: '-078.4231252',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0774, 42.1682],
            [-78.0742, 42.186],
            [-77.9567, 42.2316],
            [-77.8776, 42.1935],
            [-77.8982, 42.159],
            [-78.0142, 42.1195],
            [-78.0719, 42.0181],
            [-78.1027, 42.0757],
            [-78.0774, 42.1682],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626190',
        NAME: 'Scio Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154756905,
        AWATER: 170183,
        INTPTLAT: '+42.1479678',
        INTPTLON: '-077.9922526',
        ELSDLEA: '',
        UNSDLEA: '26190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0719, 42.0181],
            [-78.0142, 42.1195],
            [-77.8982, 42.159],
            [-77.8201, 42.0792],
            [-77.8632, 41.9984],
            [-77.9628, 41.9988],
            [-78.0726, 41.9999],
            [-78.0719, 42.0181],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630480',
        NAME: 'Wellsville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270726806,
        AWATER: 946733,
        INTPTLAT: '+42.0712423',
        INTPTLON: '-077.9427362',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7409, 42.2047],
            [-77.6789, 42.3047],
            [-77.5364, 42.3457],
            [-77.4307, 42.32],
            [-77.4466, 42.2494],
            [-77.4805, 42.2082],
            [-77.5953, 42.1579],
            [-77.6343, 42.0394],
            [-77.7469, 42.0695],
            [-77.7409, 42.2047],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600124',
        NAME: 'Canisteo-Greenwood Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497761842,
        AWATER: 521717,
        INTPTLAT: '+42.2053932',
        INTPTLON: '-077.6157532',
        ELSDLEA: '',
        UNSDLEA: '00124',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7912, 42.5346],
            [-76.6381, 42.5844],
            [-76.5777, 42.5456],
            [-76.6638, 42.4349],
            [-76.7546, 42.4484],
            [-76.7912, 42.5346],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628980',
        NAME: 'Trumansburg Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206459600,
        AWATER: 8480974,
        INTPTLAT: '+42.5057519',
        INTPTLON: '-076.6849842',
        ELSDLEA: '',
        UNSDLEA: '28980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1076, 42.5054],
            [-77.0875, 42.5458],
            [-76.8944, 42.6073],
            [-76.8956, 42.5415],
            [-76.8898, 42.4631],
            [-77.0499, 42.4023],
            [-77.0858, 42.4158],
            [-77.1076, 42.5054],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609390',
        NAME: 'Dundee Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242904945,
        AWATER: 23669701,
        INTPTLAT: '+42.5024714',
        INTPTLON: '-077.0013930',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5435, 41.7789],
            [-74.4071, 41.8589],
            [-74.2722, 41.7279],
            [-74.2597, 41.7027],
            [-74.3036, 41.6946],
            [-74.4538, 41.578],
            [-74.4942, 41.6235],
            [-74.4586, 41.7075],
            [-74.5435, 41.7789],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610500',
        NAME: 'Ellenville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340996008,
        AWATER: 8810896,
        INTPTLAT: '+41.7311232',
        INTPTLON: '-074.4225739',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7313, 42.2233],
            [-74.6753, 42.271],
            [-74.5669, 42.1958],
            [-74.4861, 42.2709],
            [-74.4321, 42.2034],
            [-74.4328, 42.1849],
            [-74.441, 42.1731],
            [-74.5314, 42.1275],
            [-74.4963, 42.0345],
            [-74.5293, 42.0213],
            [-74.7188, 42.0738],
            [-74.7313, 42.2233],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618510',
        NAME: 'Margaretville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434369221,
        AWATER: 1627137,
        INTPTLAT: '+42.1411766',
        INTPTLON: '-074.5700927',
        ELSDLEA: '',
        UNSDLEA: '18510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.051, 41.6794],
            [-73.947, 41.6688],
            [-73.9506, 41.6326],
            [-73.9595, 41.5714],
            [-73.9828, 41.5492],
            [-74.0416, 41.5802],
            [-74.051, 41.6794],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618570',
        NAME: 'Marlboro Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88054321,
        AWATER: 8376717,
        INTPTLAT: '+41.6204184',
        INTPTLON: '-074.0015739',
        ELSDLEA: '',
        UNSDLEA: '18570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4071, 41.8589],
            [-74.3554, 41.9131],
            [-74.2588, 41.8612],
            [-74.1341, 41.931],
            [-74.0755, 41.8971],
            [-74.1015, 41.8094],
            [-74.2722, 41.7279],
            [-74.4071, 41.8589],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624960',
        NAME: 'Rondout Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391981510,
        AWATER: 3268370,
        INTPTLAT: '+41.8393372',
        INTPTLON: '-074.2450640',
        ELSDLEA: '',
        UNSDLEA: '24960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0754, 42.0966],
            [-74.0424, 42.1704],
            [-73.9107, 42.1273],
            [-73.9296, 42.0788],
            [-73.9404, 41.9943],
            [-73.9433, 41.9855],
            [-74.0838, 42.0384],
            [-74.0754, 42.0966],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625800',
        NAME: 'Saugerties Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186146172,
        AWATER: 10068345,
        INTPTLAT: '+42.0925906',
        INTPTLON: '-073.9881105',
        ELSDLEA: '',
        UNSDLEA: '25800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2597, 41.7027],
            [-74.0727, 41.6878],
            [-74.051, 41.6794],
            [-74.0416, 41.5802],
            [-74.1101, 41.5488],
            [-74.2402, 41.6027],
            [-74.3036, 41.6946],
            [-74.2597, 41.7027],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629790',
        NAME: 'Wallkill Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208817950,
        AWATER: 4897540,
        INTPTLAT: '+41.6399703',
        INTPTLON: '-074.1578546',
        ELSDLEA: '',
        UNSDLEA: '29790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6737, 42.8831],
            [-74.4887, 42.9542],
            [-74.4273, 42.872],
            [-74.3955, 42.7894],
            [-74.3941, 42.7739],
            [-74.4776, 42.7805],
            [-74.6679, 42.8352],
            [-74.6737, 42.8831],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606300',
        NAME: 'Canajoharie Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257310909,
        AWATER: 2583135,
        INTPTLAT: '+42.8577778',
        INTPTLON: '-074.5271407',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3417, 43.0352],
            [-74.3106, 43.0488],
            [-74.207, 43.0712],
            [-74.2126, 43.1296],
            [-74.1243, 43.1539],
            [-74.0432, 43.1609],
            [-74.1152, 43.0958],
            [-74.1264, 42.9953],
            [-74.2907, 42.996],
            [-74.3417, 43.0352],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600005',
        NAME: 'Broadalbin-Perth Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213824662,
        AWATER: 22763365,
        INTPTLAT: '+43.0636935',
        INTPTLON: '-074.1724579',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2907, 42.996],
            [-74.1264, 42.9953],
            [-74.0475, 42.948],
            [-74.0848, 42.8969],
            [-74.2075, 42.8366],
            [-74.2626, 42.8309],
            [-74.2379, 42.9064],
            [-74.2893, 42.9844],
            [-74.2907, 42.996],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602970',
        NAME: 'Amsterdam City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202313001,
        AWATER: 5284935,
        INTPTLAT: '+42.9145175',
        INTPTLON: '-074.1890262',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9639, 43.0728],
            [-73.8072, 43.0345],
            [-73.7245, 43.0408],
            [-73.7255, 43.0384],
            [-73.7577, 42.9444],
            [-73.8404, 42.9541],
            [-73.9345, 42.9936],
            [-73.9639, 43.0728],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603930',
        NAME: 'Ballston Spa Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142540643,
        AWATER: 7830351,
        INTPTLAT: '+43.0095008',
        INTPTLON: '-073.8482586',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9345, 42.9936],
            [-73.8404, 42.9541],
            [-73.8762, 42.8772],
            [-73.9209, 42.8758],
            [-74.0085, 42.9472],
            [-73.9345, 42.9936],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605940',
        NAME: 'Burnt Hills-Ballston Lake Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128991616,
        AWATER: 1522209,
        INTPTLAT: '+42.9338799',
        INTPTLON: '-073.9187425',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1264, 42.9953],
            [-74.1152, 43.0958],
            [-74.0432, 43.1609],
            [-73.9775, 43.1669],
            [-73.9639, 43.0728],
            [-73.9345, 42.9936],
            [-74.0085, 42.9472],
            [-74.0475, 42.948],
            [-74.1264, 42.9953],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611730',
        NAME: 'Galway Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250083217,
        AWATER: 5328420,
        INTPTLAT: '+43.0461131',
        INTPTLON: '-074.0296270',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4888, 42.9851],
            [-74.2893, 42.9844],
            [-74.2379, 42.9064],
            [-74.2626, 42.8309],
            [-74.2653, 42.7956],
            [-74.3955, 42.7894],
            [-74.4273, 42.872],
            [-74.4887, 42.9542],
            [-74.4888, 42.9851],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611190',
        NAME: 'Fonda-Fultonville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327987430,
        AWATER: 6544213,
        INTPTLAT: '+42.8939616',
        INTPTLON: '-074.3716362',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1599, 40.8094],
            [-73.1447, 40.829],
            [-73.1345, 40.8298],
            [-73.086, 40.7648],
            [-73.1074, 40.684],
            [-73.1269, 40.6787],
            [-73.1651, 40.7756],
            [-73.1599, 40.8094],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608160',
        NAME: 'Connetquot Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55005275,
        AWATER: 9979998,
        INTPTLAT: '+40.7703685',
        INTPTLON: '-073.1464445',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2652, 41.0461],
            [-72.1876, 41.0338],
            [-72.1627, 40.9905],
            [-72.1141, 40.9151],
            [-72.1949, 40.8862],
            [-72.2726, 40.993],
            [-72.2876, 41.0107],
            [-72.2652, 41.0461],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609660',
        NAME: 'East Hampton Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63990998,
        AWATER: 58029986,
        INTPTLAT: '+40.9728935',
        INTPTLON: '-072.2021023',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2522, 40.8475],
            [-73.1447, 40.829],
            [-73.1599, 40.8094],
            [-73.231, 40.8041],
            [-73.2699, 40.8058],
            [-73.2522, 40.8475],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613980',
        NAME: 'Hauppauge Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32515223,
        AWATER: 22315,
        INTPTLAT: '+40.8205069',
        INTPTLON: '-073.2178574',
        ELSDLEA: '',
        UNSDLEA: '13980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0643, 40.8911],
            [-73.0269, 40.8995],
            [-73.0106, 40.8488],
            [-73.1273, 40.8543],
            [-73.1325, 40.8721],
            [-73.0643, 40.8911],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619200',
        NAME: 'Middle Country Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40337899,
        AWATER: 0,
        INTPTLAT: '+40.8670605',
        INTPTLON: '-073.0655127',
        ELSDLEA: '',
        UNSDLEA: '19200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0269, 40.8995],
            [-73.0133, 40.9038],
            [-72.9846, 40.9107],
            [-72.9628, 40.9143],
            [-72.9044, 40.9239],
            [-72.8439, 40.9201],
            [-72.8425, 40.9109],
            [-72.8648, 40.8197],
            [-72.8862, 40.8192],
            [-72.962, 40.8041],
            [-73.006, 40.8416],
            [-73.0106, 40.8488],
            [-73.0269, 40.8995],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619230',
        NAME: 'Longwood Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136668517,
        AWATER: 695554,
        INTPTLAT: '+40.8760829',
        INTPTLON: '-072.9295661',
        ELSDLEA: '',
        UNSDLEA: '19230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0519, 41.0743],
            [-73.0245, 41.081],
            [-73.0214, 40.959],
            [-72.9846, 40.9107],
            [-73.0133, 40.9038],
            [-73.0385, 40.9377],
            [-73.0519, 41.0743],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620040',
        NAME: 'Mount Sinai Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16282790,
        AWATER: 29699059,
        INTPTLAT: '+40.9645007',
        INTPTLON: '-073.0318202',
        ELSDLEA: '',
        UNSDLEA: '20040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0357, 40.7856],
            [-73.006, 40.8416],
            [-72.962, 40.8041],
            [-72.9875, 40.7203],
            [-73.0167, 40.7132],
            [-73.0357, 40.7856],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622470',
        NAME: 'Patchogue-Medford Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42528055,
        AWATER: 10085996,
        INTPTLAT: '+40.7827740',
        INTPTLON: '-073.0018862',
        ELSDLEA: '',
        UNSDLEA: '22470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6947, 41.1458],
            [-72.5633, 40.9458],
            [-72.5182, 40.9114],
            [-72.5624, 40.8783],
            [-72.6188, 40.8699],
            [-72.6901, 40.8547],
            [-72.7164, 40.8691],
            [-72.8425, 40.9109],
            [-72.8439, 40.9201],
            [-72.8866, 41.109],
            [-72.6947, 41.1458],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624690',
        NAME: 'Riverhead Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231060592,
        AWATER: 358957216,
        INTPTLAT: '+40.9771296',
        INTPTLON: '-072.7090754',
        ELSDLEA: '',
        UNSDLEA: '24690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1273, 40.8543],
            [-73.0106, 40.8488],
            [-73.006, 40.8416],
            [-73.0357, 40.7856],
            [-73.06, 40.7655],
            [-73.086, 40.7648],
            [-73.1345, 40.8298],
            [-73.1273, 40.8543],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625350',
        NAME: 'Sachem Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63495421,
        AWATER: 9778,
        INTPTLAT: '+40.8157318',
        INTPTLON: '-073.0646712',
        ELSDLEA: '',
        UNSDLEA: '25350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2476, 41.0263],
            [-73.1775, 41.0435],
            [-73.1325, 40.8721],
            [-73.1273, 40.8543],
            [-73.1345, 40.8298],
            [-73.1447, 40.829],
            [-73.2522, 40.8475],
            [-73.2589, 40.8688],
            [-73.2476, 41.0263],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627060',
        NAME: 'Smithtown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68654943,
        AWATER: 91791749,
        INTPTLAT: '+40.9192171',
        INTPTLON: '-073.1791193',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4764, 41.1875],
            [-72.3939, 41.0725],
            [-72.3702, 41.0239],
            [-72.4102, 40.9886],
            [-72.4232, 40.9759],
            [-72.4356, 41.0189],
            [-72.691, 41.1465],
            [-72.4764, 41.1875],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627620',
        NAME: 'Southold Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34655399,
        AWATER: 218205115,
        INTPTLAT: '+41.0535710',
        INTPTLON: '-072.4749707',
        ELSDLEA: '',
        UNSDLEA: '27620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1474, 41.0509],
            [-73.072, 40.9318],
            [-73.0643, 40.8911],
            [-73.1325, 40.8721],
            [-73.1775, 41.0435],
            [-73.1474, 41.0509],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628200',
        NAME: 'Three Village Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57431482,
        AWATER: 43385190,
        INTPTLAT: '+40.9731544',
        INTPTLON: '-073.1152773',
        ELSDLEA: '',
        UNSDLEA: '28200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.772, 40.955],
            [-73.7436, 40.9756],
            [-73.7303, 40.9638],
            [-73.7105, 40.9245],
            [-73.7097, 40.8942],
            [-73.7406, 40.8762],
            [-73.772, 40.955],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618240',
        NAME: 'Mamaroneck Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16045890,
        AWATER: 19192778,
        INTPTLAT: '+40.9216318',
        INTPTLON: '-073.7438883',
        ELSDLEA: '',
        UNSDLEA: '18240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.919, 42.7545],
            [-77.8405, 42.7459],
            [-77.7911, 42.6611],
            [-77.8415, 42.6544],
            [-77.9747, 42.6541],
            [-77.919, 42.7545],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620010',
        NAME: 'Mount Morris Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97204293,
        AWATER: 0,
        INTPTLAT: '+42.6942141',
        INTPTLON: '-077.8769204',
        ELSDLEA: '',
        UNSDLEA: '20010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7911, 42.6611],
            [-77.7382, 42.669],
            [-77.7215, 42.6689],
            [-77.7181, 42.6722],
            [-77.6851, 42.6927],
            [-77.6611, 42.6823],
            [-77.6322, 42.4603],
            [-77.7077, 42.4795],
            [-77.8407, 42.5178],
            [-77.8415, 42.6544],
            [-77.7911, 42.6611],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608790',
        NAME: 'Dansville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321190353,
        AWATER: 80551,
        INTPTLAT: '+42.5701406',
        INTPTLON: '-077.7337638',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9554, 42.8065],
            [-77.9291, 42.9192],
            [-77.804, 42.9187],
            [-77.8301, 42.8573],
            [-77.8405, 42.7459],
            [-77.919, 42.7545],
            [-77.9554, 42.8065],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631950',
        NAME: 'York Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174076028,
        AWATER: 30917,
        INTPTLAT: '+42.8388084',
        INTPTLON: '-077.8887509',
        ELSDLEA: '',
        UNSDLEA: '31950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0741, 42.7546],
            [-78.0257, 42.8046],
            [-77.9554, 42.8065],
            [-77.919, 42.7545],
            [-77.9747, 42.6541],
            [-78.0155, 42.6093],
            [-77.9893, 42.6499],
            [-78.1035, 42.7117],
            [-78.0741, 42.7546],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622770',
        NAME: 'Perry Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175340068,
        AWATER: 4125417,
        INTPTLAT: '+42.7278696',
        INTPTLON: '-077.9988747',
        ELSDLEA: '',
        UNSDLEA: '22770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4868, 42.8326],
            [-78.4181, 42.8875],
            [-78.1788, 42.8703],
            [-78.1897, 42.7893],
            [-78.255, 42.7786],
            [-78.3206, 42.6734],
            [-78.4322, 42.7154],
            [-78.4637, 42.7246],
            [-78.4868, 42.8326],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603420',
        NAME: 'Attica Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382891006,
        AWATER: 976263,
        INTPTLAT: '+42.7873341',
        INTPTLON: '-078.3441769',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6968, 42.8154],
            [-78.6969, 42.8638],
            [-78.587, 42.8673],
            [-78.4868, 42.8326],
            [-78.4637, 42.7246],
            [-78.5789, 42.7233],
            [-78.6835, 42.7963],
            [-78.6968, 42.8154],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615360',
        NAME: 'Iroquois Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206176147,
        AWATER: 211900,
        INTPTLAT: '+42.7964479',
        INTPTLON: '-078.5726260',
        ELSDLEA: '',
        UNSDLEA: '15360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6818, 43.0803],
            [-78.6152, 43.0845],
            [-78.5376, 42.9655],
            [-78.593, 42.9285],
            [-78.7029, 42.9511],
            [-78.6818, 43.0803],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607590',
        NAME: 'Clarence Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153397983,
        AWATER: 568856,
        INTPTLAT: '+43.0071013',
        INTPTLON: '-078.6227344',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8761, 42.5211],
            [-78.766, 42.5886],
            [-78.7541, 42.631],
            [-78.7238, 42.6393],
            [-78.6969, 42.6958],
            [-78.6394, 42.6942],
            [-78.629, 42.5953],
            [-78.566, 42.5646],
            [-78.576, 42.4774],
            [-78.7246, 42.4447],
            [-78.8295, 42.4487],
            [-78.8761, 42.5211],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612990',
        NAME: 'Springville-Griffith Institute Central School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362146459,
        AWATER: 638953,
        INTPTLAT: '+42.5453281',
        INTPTLON: '-078.7030487',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9764, 42.7291],
            [-78.9336, 42.6949],
            [-79.0032, 42.6194],
            [-78.9632, 42.5522],
            [-79.0812, 42.5539],
            [-79.1369, 42.5698],
            [-79.0538, 42.7019],
            [-78.9764, 42.7291],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616560',
        NAME: 'Evans-Brant Central School District (Lake Shore)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145784571,
        AWATER: 27376802,
        INTPTLAT: '+42.6304417',
        INTPTLON: '-079.0348751',
        ELSDLEA: '',
        UNSDLEA: '16560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8512, 43.0193],
            [-78.8313, 43.0502],
            [-78.7869, 43.0666],
            [-78.7782, 42.9783],
            [-78.8233, 42.9724],
            [-78.8514, 43.0119],
            [-78.8512, 43.0193],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628500',
        NAME: 'Sweet Home Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35652316,
        AWATER: 561606,
        INTPTLAT: '+43.0064754',
        INTPTLON: '-078.8098625',
        ELSDLEA: '',
        UNSDLEA: '28500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7869, 43.0666],
            [-78.6818, 43.0803],
            [-78.7029, 42.9511],
            [-78.7246, 42.9425],
            [-78.7593, 42.9492],
            [-78.7636, 42.9492],
            [-78.7782, 42.9783],
            [-78.7869, 43.0666],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631470',
        NAME: 'Williamsville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106163767,
        AWATER: 534143,
        INTPTLAT: '+43.0096864',
        INTPTLON: '-078.7317792',
        ELSDLEA: '',
        UNSDLEA: '31470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8104, 40.9884],
            [-73.7966, 41.0097],
            [-73.7853, 41.0215],
            [-73.7447, 40.9824],
            [-73.7436, 40.9756],
            [-73.772, 40.955],
            [-73.7931, 40.9752],
            [-73.8107, 40.9879],
            [-73.8104, 40.9884],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625950',
        NAME: 'Scarsdale Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18438283,
        AWATER: 20853,
        INTPTLAT: '+40.9890445',
        INTPTLON: '-073.7755440',
        ELSDLEA: '',
        UNSDLEA: '25950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9703, 42.841],
            [-76.8178, 42.8372],
            [-76.7291, 42.8081],
            [-76.7402, 42.7829],
            [-76.7342, 42.7332],
            [-76.852, 42.6997],
            [-76.9364, 42.7173],
            [-76.9476, 42.7592],
            [-76.9703, 42.841],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624930',
        NAME: 'Romulus Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168117331,
        AWATER: 61422252,
        INTPTLAT: '+42.7744309',
        INTPTLON: '-076.8577662',
        ELSDLEA: '',
        UNSDLEA: '24930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1897, 42.7893],
            [-78.0741, 42.7546],
            [-78.1035, 42.7117],
            [-78.1187, 42.6601],
            [-78.2829, 42.6622],
            [-78.3206, 42.6734],
            [-78.255, 42.7786],
            [-78.1897, 42.7893],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629940',
        NAME: 'Warsaw Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198432740,
        AWATER: 494532,
        INTPTLAT: '+42.7156536',
        INTPTLON: '-078.1760305',
        ELSDLEA: '',
        UNSDLEA: '29940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0501, 41.2766],
            [-71.9791, 41.3],
            [-71.9073, 41.3045],
            [-72.0616, 41.2243],
            [-72.1056, 41.2574],
            [-72.0501, 41.2766],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611100',
        NAME: 'Fishers Island Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10504165,
        AWATER: 55762791,
        INTPTLAT: '+41.2640738',
        INTPTLON: '-071.9983101',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.962, 40.8041],
            [-72.8862, 40.8192],
            [-72.8829, 40.7476],
            [-72.9875, 40.7203],
            [-72.962, 40.8041],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600008',
        NAME: 'South Country Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57951403,
        AWATER: 18695900,
        INTPTLAT: '+40.7742052',
        INTPTLON: '-072.9304559',
        ELSDLEA: '',
        UNSDLEA: '00008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5632, 41.5119],
            [-73.531, 41.5172],
            [-73.5371, 41.4397],
            [-73.5436, 41.3751],
            [-73.5437, 41.3743],
            [-73.6584, 41.3643],
            [-73.6613, 41.4294],
            [-73.5632, 41.5119],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605310',
        NAME: 'Brewster Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105964724,
        AWATER: 9137381,
        INTPTLAT: '+41.4354159',
        INTPTLON: '-073.5925061',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9814, 41.3247],
            [-73.9514, 41.399],
            [-73.8677, 41.4093],
            [-73.8914, 41.3649],
            [-73.9826, 41.3217],
            [-73.9814, 41.3247],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611860',
        NAME: 'Garrison Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50174328,
        AWATER: 3343617,
        INTPTLAT: '+41.3753251',
        INTPTLON: '-073.9262166',
        ELSDLEA: '',
        UNSDLEA: '11860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.691, 41.1465],
            [-72.4356, 41.0189],
            [-72.4232, 40.9759],
            [-72.4541, 40.919],
            [-72.4906, 40.897],
            [-72.5182, 40.9114],
            [-72.5633, 40.9458],
            [-72.6947, 41.1458],
            [-72.691, 41.1465],
          ],
          [
            [-72.4839, 40.9888],
            [-72.4656, 40.9612],
            [-72.4519, 40.9672],
            [-72.4839, 40.9888],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600021',
        NAME: 'Mattituck-Cutchogue Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59980038,
        AWATER: 160531723,
        INTPTLAT: '+41.0127286',
        INTPTLON: '-072.5476452',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1051, 41.0075],
            [-72.0627, 41.0257],
            [-71.9797, 40.9639],
            [-72.1141, 40.9151],
            [-72.1627, 40.9905],
            [-72.1051, 41.0075],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602880',
        NAME: 'Amagansett Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26432027,
        AWATER: 77611282,
        INTPTLAT: '+40.9905098',
        INTPTLON: '-072.0697464',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9802, 42.7411],
            [-75.8986, 42.8255],
            [-75.776, 42.818],
            [-75.774, 42.7411],
            [-75.8633, 42.6459],
            [-75.9415, 42.6701],
            [-75.9802, 42.7411],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608850',
        NAME: 'DeRuyter Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228026486,
        AWATER: 2191318,
        INTPTLAT: '+42.7344901',
        INTPTLON: '-075.8691519',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2232, 42.4083],
            [-76.1957, 42.4708],
            [-76.1153, 42.529],
            [-76.0035, 42.545],
            [-75.9624, 42.4109],
            [-75.9933, 42.3664],
            [-76.1327, 42.4103],
            [-76.2232, 42.4083],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618450',
        NAME: 'Marathon Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273121450,
        AWATER: 971346,
        INTPTLAT: '+42.4700652',
        INTPTLON: '-076.0721200',
        ELSDLEA: '',
        UNSDLEA: '18450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1579, 42.6073],
            [-75.9956, 42.6503],
            [-76.0035, 42.545],
            [-76.1153, 42.529],
            [-76.1579, 42.6073],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618840',
        NAME: 'McGraw Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144867771,
        AWATER: 632594,
        INTPTLAT: '+42.5891925',
        INTPTLON: '-076.0560731',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1327, 42.4103],
            [-75.9933, 42.3664],
            [-75.9624, 42.4109],
            [-75.8702, 42.4208],
            [-75.8565, 42.3316],
            [-75.9221, 42.2155],
            [-75.9716, 42.2162],
            [-76.0812, 42.2647],
            [-76.1327, 42.4103],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631380',
        NAME: 'Whitney Point Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359599831,
        AWATER: 6042010,
        INTPTLAT: '+42.3315004',
        INTPTLON: '-075.9950504',
        ELSDLEA: '',
        UNSDLEA: '31380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7172, 42.5917],
            [-75.6249, 42.6454],
            [-75.4783, 42.6062],
            [-75.4252, 42.4734],
            [-75.4506, 42.4622],
            [-75.5415, 42.4472],
            [-75.5609, 42.5187],
            [-75.7573, 42.5461],
            [-75.7172, 42.5917],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621330',
        NAME: 'Norwich City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277667967,
        AWATER: 1165710,
        INTPTLAT: '+42.5538178',
        INTPTLON: '-075.5712721',
        ELSDLEA: '',
        UNSDLEA: '21330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3393, 44.9169],
            [-73.3427, 44.9137],
            [-73.3681, 44.8279],
            [-73.5017, 44.8579],
            [-73.5141, 44.9238],
            [-73.4157, 44.9526],
            [-73.3393, 44.9169],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607170',
        NAME: 'Chazy Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120750322,
        AWATER: 18684837,
        INTPTLAT: '+44.8629639',
        INTPTLON: '-073.4318447',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0611, 44.7022],
            [-73.9976, 44.785],
            [-73.9584, 44.9982],
            [-73.8116, 45.0025],
            [-73.7734, 44.9411],
            [-73.5723, 44.9121],
            [-73.542, 44.8487],
            [-73.6428, 44.7663],
            [-73.8389, 44.7235],
            [-73.9546, 44.6324],
            [-74.0899, 44.6494],
            [-74.0611, 44.7022],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621260',
        NAME: 'Northern Adirondack Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 942866723,
        AWATER: 20894532,
        INTPTLAT: '+44.8118022',
        INTPTLON: '-073.8649130',
        ELSDLEA: '',
        UNSDLEA: '21260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3307, 44.5474],
            [-74.1698, 44.5598],
            [-74.1842, 44.6302],
            [-74.0899, 44.6494],
            [-73.9546, 44.6324],
            [-73.9277, 44.5463],
            [-73.9079, 44.4198],
            [-74.0264, 44.3378],
            [-74.0116, 44.2707],
            [-74.1158, 44.2613],
            [-74.0935, 44.1376],
            [-74.2819, 44.1206],
            [-74.3849, 44.1116],
            [-74.433, 44.395],
            [-74.4543, 44.5386],
            [-74.3307, 44.5474],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625740',
        NAME: 'Saranac Lake Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1656465381,
        AWATER: 117703455,
        INTPTLAT: '+44.3967650',
        INTPTLON: '-074.1742252',
        ELSDLEA: '',
        UNSDLEA: '25740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4253, 40.6518],
            [-73.4063, 40.6516],
            [-73.3661, 40.6645],
            [-73.346, 40.668],
            [-73.3351, 40.7121],
            [-73.3143, 40.7118],
            [-73.2674, 40.6712],
            [-73.2469, 40.6704],
            [-73.4238, 40.6095],
            [-73.4253, 40.6518],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603720',
        NAME: 'Babylon Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26312103,
        AWATER: 43779476,
        INTPTLAT: '+40.6409154',
        INTPTLON: '-073.3166911',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2822, 40.7357],
            [-73.2412, 40.7528],
            [-73.2398, 40.6701],
            [-73.2469, 40.6704],
            [-73.2674, 40.6712],
            [-73.2822, 40.7357],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604080',
        NAME: 'Bay Shore Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20975058,
        AWATER: 9432386,
        INTPTLAT: '+40.7108455',
        INTPTLON: '-073.2546364',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.06, 40.7655],
            [-73.0357, 40.7856],
            [-73.0167, 40.7132],
            [-73.069, 40.6958],
            [-73.06, 40.7655],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604110',
        NAME: 'Bayport-Blue Point Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13998543,
        AWATER: 13875839,
        INTPTLAT: '+40.7346529',
        INTPTLON: '-073.0444413',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6428, 44.7663],
            [-73.5371, 44.6878],
            [-73.8132, 44.5503],
            [-73.9277, 44.5463],
            [-73.9546, 44.6324],
            [-73.8389, 44.7235],
            [-73.6428, 44.7663],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600009',
        NAME: 'Saranac Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464214699,
        AWATER: 4554449,
        INTPTLAT: '+44.6581294',
        INTPTLON: '-073.7630228',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9277, 44.5463],
            [-73.8132, 44.5503],
            [-73.6409, 44.5505],
            [-73.585, 44.5152],
            [-73.4891, 44.5629],
            [-73.3386, 44.5468],
            [-73.3212, 44.5203],
            [-73.2994, 44.4736],
            [-73.4998, 44.3965],
            [-73.6114, 44.3878],
            [-73.7127, 44.2515],
            [-73.8129, 44.3052],
            [-73.8321, 44.4082],
            [-73.9079, 44.4198],
            [-73.9277, 44.5463],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616170',
        NAME: 'AuSable Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 719742083,
        AWATER: 81767172,
        INTPTLAT: '+44.4529355',
        INTPTLON: '-073.6415733',
        ELSDLEA: '',
        UNSDLEA: '16170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6654, 42.2379],
            [-76.6701, 42.2839],
            [-76.5054, 42.3082],
            [-76.4155, 42.3056],
            [-76.4191, 42.164],
            [-76.4715, 42.1264],
            [-76.5424, 42.1555],
            [-76.632, 42.1119],
            [-76.6613, 42.1289],
            [-76.6654, 42.2379],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627750',
        NAME: 'Spencer-Van Etten Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375313115,
        AWATER: 1877402,
        INTPTLAT: '+42.2189347',
        INTPTLON: '-076.5434852',
        ELSDLEA: '',
        UNSDLEA: '27750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1919, 42.1585],
            [-77.1093, 42.2813],
            [-76.9879, 42.3108],
            [-76.8952, 42.283],
            [-76.9654, 42.2236],
            [-76.9257, 42.0965],
            [-76.9657, 42.0013],
            [-77.2005, 41.9998],
            [-77.1919, 42.1585],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608400',
        NAME: 'Corning City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 603419193,
        AWATER: 5495438,
        INTPTLAT: '+42.1458751',
        INTPTLON: '-077.0584947',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4329, 40.7005],
            [-73.4151, 40.7063],
            [-73.4063, 40.6516],
            [-73.4253, 40.6518],
            [-73.4329, 40.7005],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602940',
        NAME: 'Amityville Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11433684,
        AWATER: 1109740,
        INTPTLAT: '+40.6781672',
        INTPTLON: '-073.4191516',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9491, 41.2926],
            [-73.8991, 41.2865],
            [-73.9572, 41.2764],
            [-73.9491, 41.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622650',
        NAME: 'Peekskill City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11311918,
        AWATER: 3186190,
        INTPTLAT: '+41.2873963',
        INTPTLON: '-073.9235971',
        ELSDLEA: '',
        UNSDLEA: '22650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7443, 41.0521],
            [-73.7507, 41.0792],
            [-73.7344, 41.0746],
            [-73.7066, 41.0729],
            [-73.6937, 41.0126],
            [-73.6849, 40.9977],
            [-73.7122, 40.9629],
            [-73.7303, 40.9638],
            [-73.7436, 40.9756],
            [-73.7447, 40.9824],
            [-73.7443, 41.0521],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613740',
        NAME: 'Harrison Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43416304,
        AWATER: 1562612,
        INTPTLAT: '+41.0264443',
        INTPTLON: '-073.7237065',
        ELSDLEA: '',
        UNSDLEA: '13740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7426, 43.2343],
            [-77.6727, 43.2947],
            [-77.6204, 43.269],
            [-77.6834, 43.1793],
            [-77.7015, 43.1794],
            [-77.7426, 43.2343],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612630',
        NAME: 'Greece Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77944941,
        AWATER: 2105395,
        INTPTLAT: '+43.2343088',
        INTPTLON: '-077.6799728',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7345, 42.9548],
            [-77.7329, 42.9819],
            [-77.658, 43.1034],
            [-77.6098, 43.1144],
            [-77.554, 43.0948],
            [-77.5842, 43.0337],
            [-77.6503, 42.954],
            [-77.7345, 42.9548],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625170',
        NAME: 'Rush-Henrietta Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163629966,
        AWATER: 1888597,
        INTPTLAT: '+43.0330931',
        INTPTLON: '-077.6507015',
        ELSDLEA: '',
        UNSDLEA: '25170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9093, 43.1269],
            [-77.8822, 43.1715],
            [-77.7542, 43.145],
            [-77.7304, 43.0917],
            [-77.7825, 43.0677],
            [-77.9086, 43.0448],
            [-77.9093, 43.1269],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607530',
        NAME: 'Churchville-Chili Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133127824,
        AWATER: 908421,
        INTPTLAT: '+43.1040326',
        INTPTLON: '-077.8455454',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.554, 43.0948],
            [-77.5348, 43.134],
            [-77.5112, 43.1242],
            [-77.4794, 43.0994],
            [-77.4526, 43.0459],
            [-77.4848, 43.0119],
            [-77.5842, 43.0337],
            [-77.554, 43.0948],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623160',
        NAME: 'Pittsford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81413686,
        AWATER: 700924,
        INTPTLAT: '+43.0648278',
        INTPTLON: '-077.5176629',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5338, 43.2364],
            [-77.376, 43.2768],
            [-77.3834, 43.1665],
            [-77.5328, 43.1676],
            [-77.5338, 43.2364],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630360',
        NAME: 'Webster Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131918641,
        AWATER: 5540538,
        INTPTLAT: '+43.2128895',
        INTPTLON: '-077.4443063',
        ELSDLEA: '',
        UNSDLEA: '30360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3834, 43.1665],
            [-77.376, 43.2768],
            [-77.2556, 43.2867],
            [-77.2449, 43.2065],
            [-77.2521, 43.1279],
            [-77.2609, 43.1276],
            [-77.3587, 43.143],
            [-77.3834, 43.1665],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630330',
        NAME: 'Wayne Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157375836,
        AWATER: 9259579,
        INTPTLAT: '+43.2160560',
        INTPTLON: '-077.3108572',
        ELSDLEA: '',
        UNSDLEA: '30330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4655, 43.3714],
            [-78.2099, 43.3717],
            [-78.2981, 43.2627],
            [-78.4477, 43.283],
            [-78.4655, 43.3714],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617970',
        NAME: 'Lyndonville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164284977,
        AWATER: 577043,
        INTPTLAT: '+43.3276569',
        INTPTLON: '-078.3504898',
        ELSDLEA: '',
        UNSDLEA: '17970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6216, 40.6617],
            [-73.6198, 40.6812],
            [-73.6028, 40.6847],
            [-73.5938, 40.6685],
            [-73.5861, 40.6256],
            [-73.6216, 40.6617],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603840',
        NAME: 'Baldwin Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10778978,
        AWATER: 1268765,
        INTPTLAT: '+40.6501274',
        INTPTLON: '-073.6077093',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5009, 40.7676],
            [-73.4629, 40.7447],
            [-73.4748, 40.7314],
            [-73.4911, 40.7321],
            [-73.5098, 40.743],
            [-73.5009, 40.7676],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604740',
        NAME: 'Bethpage Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11558739,
        AWATER: 0,
        INTPTLAT: '+40.7511534',
        INTPTLON: '-073.4836532',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4961, 40.9755],
            [-73.4565, 40.9838],
            [-73.4319, 40.989],
            [-73.4273, 40.9203],
            [-73.435, 40.8461],
            [-73.4547, 40.8359],
            [-73.497, 40.849],
            [-73.5278, 40.9689],
            [-73.4961, 40.9755],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608010',
        NAME: 'Cold Spring Harbor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40350129,
        AWATER: 48250031,
        INTPTLAT: '+40.9254269',
        INTPTLON: '-073.4633986',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6188, 43.1364],
            [-78.449, 43.1183],
            [-78.4126, 43.0312],
            [-78.4643, 42.9655],
            [-78.5376, 42.9655],
            [-78.6152, 43.0845],
            [-78.6335, 43.1199],
            [-78.6188, 43.1364],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602430',
        NAME: 'Akron Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207864856,
        AWATER: 506514,
        INTPTLAT: '+43.0539631',
        INTPTLON: '-078.5061896',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.593, 42.9285],
            [-78.5376, 42.9655],
            [-78.4643, 42.9655],
            [-78.4158, 42.9119],
            [-78.4181, 42.8875],
            [-78.4868, 42.8326],
            [-78.587, 42.8673],
            [-78.593, 42.9285],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602550',
        NAME: 'Alden Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145067953,
        AWATER: 1181264,
        INTPTLAT: '+42.8988147',
        INTPTLON: '-078.5109994',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.799, 42.9299],
            [-78.7693, 42.9246],
            [-78.7241, 42.9124],
            [-78.7147, 42.9137],
            [-78.721, 42.8761],
            [-78.7658, 42.8743],
            [-78.7993, 42.8988],
            [-78.799, 42.9299],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607230',
        NAME: 'Cheektowaga Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26250750,
        AWATER: 40835,
        INTPTLAT: '+42.8998998',
        INTPTLON: '-078.7607084',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8624, 42.8054],
            [-78.8114, 42.8047],
            [-78.806, 42.7969],
            [-78.8006, 42.763],
            [-78.908, 42.6966],
            [-78.9336, 42.6949],
            [-78.9764, 42.7291],
            [-78.8624, 42.8054],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611670',
        NAME: 'Frontier Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63279974,
        AWATER: 12023746,
        INTPTLAT: '+42.7495900',
        INTPTLON: '-078.8814735',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0812, 42.5539],
            [-78.9632, 42.5522],
            [-78.8761, 42.5211],
            [-78.8295, 42.4487],
            [-78.8929, 42.3802],
            [-78.974, 42.3915],
            [-79.061, 42.4061],
            [-79.061, 42.513],
            [-79.0812, 42.5539],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612390',
        NAME: 'Gowanda Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245771129,
        AWATER: 2249248,
        INTPTLAT: '+42.4734554',
        INTPTLON: '-078.9725792',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8006, 42.763],
            [-78.7238, 42.6393],
            [-78.7541, 42.631],
            [-78.8044, 42.6964],
            [-78.908, 42.6966],
            [-78.8006, 42.763],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613350',
        NAME: 'Hamburg Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70884823,
        AWATER: 9213,
        INTPTLAT: '+42.7010102',
        INTPTLON: '-078.8155093',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6394, 42.6942],
            [-78.5789, 42.7233],
            [-78.4637, 42.7246],
            [-78.4322, 42.7154],
            [-78.4624, 42.6065],
            [-78.566, 42.5646],
            [-78.629, 42.5953],
            [-78.6394, 42.6942],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614550',
        NAME: 'Holland Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207660661,
        AWATER: 353222,
        INTPTLAT: '+42.6531453',
        INTPTLON: '-078.5454097',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6619, 40.6567],
            [-73.6538, 40.6573],
            [-73.6556, 40.6494],
            [-73.6763, 40.629],
            [-73.6619, 40.6567],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609960',
        NAME: 'East Rockaway Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3093672,
        AWATER: 255803,
        INTPTLAT: '+40.6420179',
        INTPTLON: '-073.6631168',
        ELSDLEA: '',
        UNSDLEA: '09960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6105, 40.8027],
            [-73.6039, 40.7865],
            [-73.6024, 40.763],
            [-73.6251, 40.7546],
            [-73.643, 40.7799],
            [-73.6105, 40.8027],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610050',
        NAME: 'East Williston Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11353788,
        AWATER: 0,
        INTPTLAT: '+40.7730834',
        INTPTLON: '-073.6216061',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4629, 40.7447],
            [-73.4367, 40.7423],
            [-73.3997, 40.7386],
            [-73.398, 40.7316],
            [-73.3925, 40.7156],
            [-73.3969, 40.7144],
            [-73.4151, 40.7063],
            [-73.4329, 40.7005],
            [-73.4644, 40.6946],
            [-73.4748, 40.7314],
            [-73.4629, 40.7447],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610980',
        NAME: 'Farmingdale Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25436780,
        AWATER: 62416,
        INTPTLAT: '+40.7221770',
        INTPTLON: '-073.4369387',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7246, 42.9425],
            [-78.7029, 42.9511],
            [-78.593, 42.9285],
            [-78.587, 42.8673],
            [-78.6969, 42.8638],
            [-78.7147, 42.9137],
            [-78.7241, 42.9124],
            [-78.7246, 42.9425],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616680',
        NAME: 'Lancaster Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87137312,
        AWATER: 633182,
        INTPTLAT: '+42.9107316',
        INTPTLON: '-078.6492152',
        ELSDLEA: '',
        UNSDLEA: '16680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8114, 42.8047],
            [-78.8017, 42.832],
            [-78.8001, 42.8648],
            [-78.7658, 42.8743],
            [-78.721, 42.8761],
            [-78.6969, 42.8638],
            [-78.6968, 42.8154],
            [-78.806, 42.7969],
            [-78.8114, 42.8047],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630780',
        NAME: 'West Seneca Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60251100,
        AWATER: 146794,
        INTPTLAT: '+42.8382775',
        INTPTLON: '-078.7523363',
        ELSDLEA: '',
        UNSDLEA: '30780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-72.2641, 41.0881],
              [-72.0616, 41.2243],
              [-71.9073, 41.3045],
              [-71.791, 41.1841],
              [-71.7807, 41.0927],
              [-71.9604, 41.0824],
              [-72.0627, 41.0257],
              [-72.1051, 41.0075],
              [-72.0691, 41.1063],
              [-72.1426, 41.1252],
              [-72.1876, 41.0338],
              [-72.2652, 41.0461],
              [-72.2641, 41.0881],
            ],
          ],
          [
            [
              [-75.5335, 44.1175],
              [-75.5077, 44.0852],
              [-75.6251, 44.0125],
              [-75.7649, 44.0654],
              [-75.6575, 44.1695],
              [-75.5335, 44.1175],
            ],
          ],
          [
            [
              [-76.3997, 44.1123],
              [-76.3478, 44.0039],
              [-76.3337, 43.9411],
              [-76.4639, 43.9414],
              [-76.464, 43.8751],
              [-76.3239, 43.8611],
              [-76.2411, 43.801],
              [-76.1999, 43.6802],
              [-76.1981, 43.6142],
              [-76.2263, 43.5451],
              [-76.4013, 43.5313],
              [-76.6265, 43.4124],
              [-76.6753, 43.3616],
              [-76.7822, 43.3192],
              [-76.9641, 43.2838],
              [-77.1323, 43.2958],
              [-77.2556, 43.2867],
              [-77.376, 43.2768],
              [-77.5338, 43.2364],
              [-77.5498, 43.2366],
              [-77.5745, 43.2454],
              [-77.6001, 43.2586],
              [-77.6204, 43.269],
              [-77.6727, 43.2947],
              [-77.7489, 43.3429],
              [-77.8964, 43.3563],
              [-77.9217, 43.3588],
              [-78.1702, 43.3724],
              [-78.2099, 43.3717],
              [-78.4655, 43.3714],
              [-78.6562, 43.3547],
              [-78.7544, 43.3371],
              [-78.9586, 43.2905],
              [-79.0759, 43.2702],
              [-79.2009, 43.4504],
              [-78.6883, 43.6347],
              [-77.6963, 43.6313],
              [-76.7762, 43.6295],
              [-76.7535, 43.6843],
              [-76.3997, 44.1123],
            ],
          ],
          [
            [
              [-79.4398, 42.2255],
              [-79.3962, 42.1577],
              [-79.2705, 42.1116],
              [-79.2705, 42.111],
              [-79.3677, 42.1113],
              [-79.3829, 42.1157],
              [-79.4398, 42.2255],
            ],
          ],
          [
            [
              [-79.7624, 42.5161],
              [-78.9998, 42.8044],
              [-78.9569, 42.8327],
              [-78.869, 42.8323],
              [-78.8624, 42.8054],
              [-78.9764, 42.7291],
              [-79.0538, 42.7019],
              [-79.1369, 42.5698],
              [-79.2752, 42.5143],
              [-79.4002, 42.4571],
              [-79.4221, 42.4418],
              [-79.5281, 42.3732],
              [-79.6626, 42.3077],
              [-79.7619, 42.2693],
              [-79.7624, 42.5161],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3699997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 197734943,
        AWATER: 10510988199,
        INTPTLAT: '+44.0748956',
        INTPTLON: '-075.7698122',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5938, 40.6685],
            [-73.5736, 40.6716],
            [-73.5732, 40.6691],
            [-73.5501, 40.5285],
            [-73.5749, 40.5306],
            [-73.5741, 40.5959],
            [-73.5861, 40.6256],
            [-73.5938, 40.6685],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611550',
        NAME: 'Freeport Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17031993,
        AWATER: 17947715,
        INTPTLAT: '+40.5846895',
        INTPTLON: '-073.5612871',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4319, 40.989],
            [-73.2966, 41.0168],
            [-73.2885, 40.8732],
            [-73.3186, 40.8568],
            [-73.3372, 40.8624],
            [-73.3917, 40.9081],
            [-73.4273, 40.9203],
            [-73.4319, 40.989],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621270',
        NAME: 'Northport-East Northport Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40416441,
        AWATER: 95483540,
        INTPTLAT: '+40.9479608',
        INTPTLON: '-073.3850025',
        ELSDLEA: '',
        UNSDLEA: '21270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6815, 42.5537],
            [-74.6306, 42.6267],
            [-74.668, 42.6928],
            [-74.6715, 42.7275],
            [-74.4776, 42.7805],
            [-74.3941, 42.7739],
            [-74.3616, 42.6597],
            [-74.4526, 42.5832],
            [-74.5225, 42.5952],
            [-74.529, 42.6001],
            [-74.4959, 42.5293],
            [-74.5028, 42.5029],
            [-74.5114, 42.4969],
            [-74.5233, 42.5261],
            [-74.5252, 42.5267],
            [-74.5271, 42.5244],
            [-74.5285, 42.5225],
            [-74.523, 42.501],
            [-74.6396, 42.5488],
            [-74.6815, 42.5537],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600010',
        NAME: 'Cobleskill-Richmondville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467914293,
        AWATER: 2008763,
        INTPTLAT: '+42.6624126',
        INTPTLON: '-074.5337948',
        ELSDLEA: '',
        UNSDLEA: '00010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2107, 42.6173],
            [-74.1648, 42.7168],
            [-74.097, 42.7336],
            [-74.0644, 42.714],
            [-74.0311, 42.6781],
            [-74.0073, 42.5936],
            [-74.0007, 42.5566],
            [-74.1866, 42.5392],
            [-74.2107, 42.6173],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604650',
        NAME: 'Berne-Knox-Westerlo Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321289385,
        AWATER: 2847363,
        INTPTLAT: '+42.6116313',
        INTPTLON: '-074.1108187',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2653, 42.7956],
            [-74.2626, 42.8309],
            [-74.2075, 42.8366],
            [-74.1088, 42.7976],
            [-74.097, 42.7336],
            [-74.1648, 42.7168],
            [-74.2595, 42.7153],
            [-74.2653, 42.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609360',
        NAME: 'Duanesburg Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151462348,
        AWATER: 2382267,
        INTPTLAT: '+42.7575437',
        INTPTLON: '-074.1908674',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6679, 42.8352],
            [-74.4776, 42.7805],
            [-74.6715, 42.7275],
            [-74.6679, 42.8352],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626580',
        NAME: 'Sharon Springs Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133791480,
        AWATER: 392362,
        INTPTLAT: '+42.7769300',
        INTPTLON: '-074.6131650',
        ELSDLEA: '',
        UNSDLEA: '26580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7634, 42.4448],
            [-74.6641, 42.47],
            [-74.49, 42.4161],
            [-74.4971, 42.3969],
            [-74.5654, 42.3842],
            [-74.7634, 42.4448],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628020',
        NAME: 'Stamford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126843224,
        AWATER: 831132,
        INTPTLAT: '+42.4166281',
        INTPTLON: '-074.6480141',
        ELSDLEA: '',
        UNSDLEA: '28020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2003, 40.7573],
            [-73.1651, 40.7756],
            [-73.1269, 40.6787],
            [-73.2043, 40.6694],
            [-73.2003, 40.7573],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609720',
        NAME: 'East Islip Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32407912,
        AWATER: 25002805,
        INTPTLAT: '+40.7163398',
        INTPTLON: '-073.1474863',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4238, 40.6095],
            [-73.2469, 40.6704],
            [-73.2398, 40.6701],
            [-73.2043, 40.6694],
            [-73.1269, 40.6787],
            [-73.1074, 40.684],
            [-73.069, 40.6958],
            [-73.0167, 40.7132],
            [-72.9875, 40.7203],
            [-72.8829, 40.7476],
            [-72.8491, 40.6816],
            [-73.274, 40.5513],
            [-73.4253, 40.5343],
            [-73.4238, 40.6095],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621540',
        NAME: 'Fire Island Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19302208,
        AWATER: 416578230,
        INTPTLAT: '+40.6337349',
        INTPTLON: '-073.2056390',
        ELSDLEA: '',
        UNSDLEA: '21540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4547, 40.8359],
            [-73.435, 40.8461],
            [-73.3859, 40.848],
            [-73.3672, 40.8455],
            [-73.3581, 40.832],
            [-73.4498, 40.8104],
            [-73.4547, 40.8359],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627300',
        NAME: 'South Huntington Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28652245,
        AWATER: 9518,
        INTPTLAT: '+40.8210610',
        INTPTLON: '-073.4097420',
        ELSDLEA: '',
        UNSDLEA: '27300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7127, 44.2515],
            [-73.6114, 44.3878],
            [-73.4998, 44.3965],
            [-73.4824, 44.2961],
            [-73.5251, 44.1091],
            [-73.6299, 44.0985],
            [-73.6876, 44.0946],
            [-73.7127, 44.2515],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610440',
        NAME: 'Elizabethtown-Lewis Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446973132,
        AWATER: 4595537,
        INTPTLAT: '+44.2477411',
        INTPTLON: '-073.6060612',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3368, 43.9252],
            [-74.256, 43.9698],
            [-74.2311, 43.832],
            [-74.0217, 43.9516],
            [-73.9424, 43.9567],
            [-73.9163, 43.8115],
            [-73.8654, 43.7754],
            [-73.9662, 43.7535],
            [-74.057, 43.7445],
            [-74.0471, 43.7963],
            [-74.2137, 43.8109],
            [-74.3368, 43.9252],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619530',
        NAME: 'Minerva Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406831577,
        AWATER: 9086127,
        INTPTLAT: '+43.8553355',
        INTPTLON: '-074.1184588',
        ELSDLEA: '',
        UNSDLEA: '19530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3793, 43.8085],
            [-73.3628, 43.7531],
            [-73.3936, 43.6995],
            [-73.4839, 43.6906],
            [-73.4713, 43.7717],
            [-73.3793, 43.8085],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623970',
        NAME: 'Putnam Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88076530,
        AWATER: 6804325,
        INTPTLAT: '+43.7468866',
        INTPTLON: '-073.4175272',
        ELSDLEA: '',
        UNSDLEA: '23970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0217, 43.9516],
            [-73.9499, 43.9908],
            [-73.964, 44.067],
            [-73.6876, 44.0946],
            [-73.6299, 44.0985],
            [-73.6176, 43.9894],
            [-73.6288, 43.8836],
            [-73.6382, 43.7849],
            [-73.8687, 43.7628],
            [-73.8677, 43.7667],
            [-73.8654, 43.7754],
            [-73.9163, 43.8115],
            [-73.9424, 43.9567],
            [-74.0217, 43.9516],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626130',
        NAME: 'Schroon Lake Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 812464121,
        AWATER: 30934092,
        INTPTLAT: '+43.9347824',
        INTPTLON: '-073.8021697',
        ELSDLEA: '',
        UNSDLEA: '26130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4998, 44.3965],
            [-73.2994, 44.4736],
            [-73.2938, 44.4382],
            [-73.327, 44.3434],
            [-73.3133, 44.2641],
            [-73.3456, 44.2328],
            [-73.4824, 44.2961],
            [-73.4998, 44.3965],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631500',
        NAME: 'Willsboro Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181268433,
        AWATER: 97469520,
        INTPTLAT: '+44.3499779',
        INTPTLON: '-073.3903259',
        ELSDLEA: '',
        UNSDLEA: '31500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1051, 41.0075],
            [-72.1627, 40.9905],
            [-72.1876, 41.0338],
            [-72.1426, 41.1252],
            [-72.0691, 41.1063],
            [-72.1051, 41.0075],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627900',
        NAME: 'Springs Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34117329,
        AWATER: 63184139,
        INTPTLAT: '+41.0427183',
        INTPTLON: '-072.1410913',
        ELSDLEA: '',
        UNSDLEA: '27900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4906, 40.897],
            [-72.4541, 40.919],
            [-72.478, 40.8455],
            [-72.4906, 40.897],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629070',
        NAME: 'Tuckahoe Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16744991,
        AWATER: 15571761,
        INTPTLAT: '+40.8835172',
        INTPTLON: '-072.4519481',
        ELSDLEA: '',
        UNSDLEA: '29070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1056, 41.2574],
            [-72.0616, 41.2243],
            [-72.2641, 41.0881],
            [-72.3421, 41.1039],
            [-72.4023, 41.2016],
            [-72.1056, 41.2574],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621930',
        NAME: 'Oysterponds Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21864579,
        AWATER: 253988987,
        INTPTLAT: '+41.1591059',
        INTPTLON: '-072.2498337',
        ELSDLEA: '',
        UNSDLEA: '21930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0519, 41.0743],
            [-73.0385, 40.9377],
            [-73.072, 40.9318],
            [-73.1474, 41.0509],
            [-73.0519, 41.0743],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623490',
        NAME: 'Port Jefferson Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10031105,
        AWATER: 68930092,
        INTPTLAT: '+40.9707090',
        INTPTLON: '-073.0811988',
        ELSDLEA: '',
        UNSDLEA: '23490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6187, 40.8584],
            [-72.5411, 40.7899],
            [-72.5417, 40.7699],
            [-72.5882, 40.7562],
            [-72.6187, 40.8584],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624060',
        NAME: 'Quogue Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13522327,
        AWATER: 31111180,
        INTPTLAT: '+40.8101255',
        INTPTLON: '-072.5886902',
        ELSDLEA: '',
        UNSDLEA: '24060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0335, 41.0702],
            [-73.9956, 41.0769],
            [-73.9906, 41.0788],
            [-73.967, 41.0783],
            [-73.9665, 41.026],
            [-73.9938, 41.0382],
            [-74.0126, 41.0464],
            [-74.0509, 41.0635],
            [-74.0335, 41.0702],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622560',
        NAME: 'Pearl River Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18180870,
        AWATER: 1215220,
        INTPTLAT: '+41.0560657',
        INTPTLON: '-073.9956908',
        ELSDLEA: '',
        UNSDLEA: '22560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2345, 41.1429],
            [-74.151, 41.2035],
            [-74.114, 41.2432],
            [-74.0843, 41.2003],
            [-74.0912, 41.0813],
            [-74.1038, 41.0869],
            [-74.2133, 41.134],
            [-74.2345, 41.1429],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628320',
        NAME: 'Ramapo Central School District (Suffern)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105568679,
        AWATER: 3613055,
        INTPTLAT: '+41.1647748',
        INTPTLON: '-074.1559817',
        ELSDLEA: '',
        UNSDLEA: '28320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9793, 41.091],
            [-72.9103, 41.1044],
            [-72.9044, 40.9239],
            [-72.9628, 40.9143],
            [-72.9793, 41.091],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624840',
        NAME: 'Rocky Point Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27608028,
        AWATER: 80688263,
        INTPTLAT: '+40.9652028',
        INTPTLON: '-072.9542562',
        ELSDLEA: '',
        UNSDLEA: '24840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4792, 43.2275],
            [-76.4509, 43.2285],
            [-76.2552, 43.1894],
            [-76.2515, 43.1224],
            [-76.2654, 43.1082],
            [-76.3664, 43.1009],
            [-76.4375, 43.1279],
            [-76.4792, 43.2275],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603870',
        NAME: 'Baldwinsville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201829423,
        AWATER: 6110875,
        INTPTLAT: '+43.1715490',
        INTPTLON: '-076.3562409',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0837, 43.0583],
            [-76.0371, 43.044],
            [-76.0457, 42.9695],
            [-76.0555, 42.9273],
            [-76.1257, 42.9939],
            [-76.0837, 43.0583],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609090',
        NAME: 'Jamesville-DeWitt Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64665409,
        AWATER: 1176822,
        INTPTLAT: '+42.9990791',
        INTPTLON: '-076.0529422',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3498, 43.0239],
            [-76.2454, 43.0073],
            [-76.256, 42.906],
            [-76.2449, 42.8209],
            [-76.2596, 42.8199],
            [-76.3499, 42.875],
            [-76.405, 42.9942],
            [-76.3498, 43.0239],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618480',
        NAME: 'Marcellus Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150487842,
        AWATER: 6903159,
        INTPTLAT: '+42.9434112',
        INTPTLON: '-076.3120702',
        ELSDLEA: '',
        UNSDLEA: '18480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2454, 43.0073],
            [-76.2075, 42.9996],
            [-76.1627, 42.9844],
            [-76.136, 42.9847],
            [-76.1749, 42.8957],
            [-76.256, 42.906],
            [-76.2454, 43.0073],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621810',
        NAME: 'Onondaga Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88028395,
        AWATER: 83982,
        INTPTLAT: '+42.9518210',
        INTPTLON: '-076.2307147',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7778, 42.4866],
            [-75.7024, 42.4555],
            [-75.6554, 42.3396],
            [-75.6468, 42.328],
            [-75.6464, 42.2996],
            [-75.7472, 42.2492],
            [-75.8285, 42.2471],
            [-75.8565, 42.3316],
            [-75.8702, 42.4208],
            [-75.7778, 42.4866],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612750',
        NAME: 'Greene Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334382393,
        AWATER: 2841106,
        INTPTLAT: '+42.3592136',
        INTPTLON: '-075.7594987',
        ELSDLEA: '',
        UNSDLEA: '12750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9289, 42.0763],
            [-75.9468, 42.0719],
            [-75.9475, 42.0842],
            [-75.9289, 42.0763],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604800',
        NAME: 'Binghamton Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1865006,
        AWATER: 5077,
        INTPTLAT: '+42.0780491',
        INTPTLON: '-075.9401880',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8581, 42.1122],
            [-75.809, 42.1378],
            [-75.6093, 42.1363],
            [-75.5353, 42.1149],
            [-75.5388, 41.9991],
            [-75.6556, 41.9985],
            [-75.7422, 41.9979],
            [-75.8579, 42.1035],
            [-75.8581, 42.1122],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602730',
        NAME: 'Windsor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301068384,
        AWATER: 4121058,
        INTPTLAT: '+42.0721921',
        INTPTLON: '-075.6752885',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9716, 42.2162],
            [-75.9221, 42.2155],
            [-75.8565, 42.3316],
            [-75.8285, 42.2471],
            [-75.7472, 42.2492],
            [-75.8053, 42.1789],
            [-75.9018, 42.1645],
            [-75.9698, 42.1913],
            [-75.9716, 42.2162],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607260',
        NAME: 'Chenango Forks Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146145093,
        AWATER: 1761409,
        INTPTLAT: '+42.2199703',
        INTPTLON: '-075.8570896',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9698, 42.1913],
            [-75.9018, 42.1645],
            [-75.9256, 42.1318],
            [-75.9484, 42.0948],
            [-75.9947, 42.1143],
            [-75.9698, 42.1913],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615900',
        NAME: 'Johnson City Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56562829,
        AWATER: 692624,
        INTPTLAT: '+42.1458976',
        INTPTLON: '-075.9564895',
        ELSDLEA: '',
        UNSDLEA: '15900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6796, 40.7284],
            [-73.6609, 40.7357],
            [-73.6237, 40.7418],
            [-73.6171, 40.743],
            [-73.609, 40.7203],
            [-73.6412, 40.7131],
            [-73.6555, 40.7075],
            [-73.6829, 40.7167],
            [-73.6796, 40.7284],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611760',
        NAME: 'Garden City Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13804631,
        AWATER: 63946,
        INTPTLAT: '+40.7257555',
        INTPTLON: '-073.6453981',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6204, 43.269],
            [-77.6001, 43.2586],
            [-77.5846, 43.1954],
            [-77.5753, 43.2315],
            [-77.5745, 43.2454],
            [-77.5498, 43.2366],
            [-77.5335, 43.1676],
            [-77.5472, 43.1429],
            [-77.6098, 43.1144],
            [-77.658, 43.1034],
            [-77.6501, 43.1257],
            [-77.6834, 43.1793],
            [-77.6204, 43.269],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624750',
        NAME: 'Rochester City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92673587,
        AWATER: 3545786,
        INTPTLAT: '+43.1699272',
        INTPTLON: '-077.6168907',
        ELSDLEA: '',
        UNSDLEA: '24750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0521, 43.1321],
            [-79.0403, 43.1324],
            [-78.9453, 43.067],
            [-79.0192, 43.068],
            [-79.0521, 43.1321],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620820',
        NAME: 'Niagara Falls City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36482611,
        AWATER: 7100859,
        INTPTLAT: '+43.0928407',
        INTPTLON: '-079.0147434',
        ELSDLEA: '',
        UNSDLEA: '20820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2041, 43.047],
            [-76.1948, 43.0423],
            [-76.1948, 43.0463],
            [-76.1825, 43.0747],
            [-76.1625, 43.0861],
            [-76.144, 43.0834],
            [-76.1153, 43.0755],
            [-76.0837, 43.0583],
            [-76.1257, 42.9939],
            [-76.136, 42.9847],
            [-76.1627, 42.9844],
            [-76.1948, 43.0421],
            [-76.1989, 43.0418],
            [-76.2041, 43.047],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628590',
        NAME: 'Syracuse City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64867329,
        AWATER: 1463257,
        INTPTLAT: '+43.0409981',
        INTPTLON: '-076.1435544',
        ELSDLEA: '',
        UNSDLEA: '28590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2922, 43.0836],
            [-75.2739, 43.1015],
            [-75.271, 43.0986],
            [-75.2671, 43.1027],
            [-75.2672, 43.1124],
            [-75.268, 43.1126],
            [-75.1796, 43.0947],
            [-75.2078, 43.0645],
            [-75.2922, 43.0836],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629370',
        NAME: 'Utica City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43404629,
        AWATER: 662077,
        INTPTLAT: '+43.0963822',
        INTPTLON: '-075.2259832',
        ELSDLEA: '',
        UNSDLEA: '29370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9488, 42.8199],
            [-73.9211, 42.8406],
            [-73.893, 42.7827],
            [-73.9068, 42.7634],
            [-73.9832, 42.8017],
            [-73.9488, 42.8199],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626010',
        NAME: 'Schenectady City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27951010,
        AWATER: 479428,
        INTPTLAT: '+42.8018398',
        INTPTLON: '-073.9272950',
        ELSDLEA: '',
        UNSDLEA: '26010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2726, 40.993],
            [-72.1949, 40.8862],
            [-72.2086, 40.8813],
            [-72.2613, 40.9531],
            [-72.2726, 40.993],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629760',
        NAME: 'Wainscott Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18069385,
        AWATER: 8336238,
        INTPTLAT: '+40.9443748',
        INTPTLON: '-072.2425434',
        ELSDLEA: '',
        UNSDLEA: '29760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.398, 40.7316],
            [-73.3701, 40.7368],
            [-73.3351, 40.7121],
            [-73.346, 40.668],
            [-73.3925, 40.7156],
            [-73.398, 40.7316],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630540',
        NAME: 'West Babylon Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14360170,
        AWATER: 630926,
        INTPTLAT: '+40.7085454',
        INTPTLON: '-073.3614341',
        ELSDLEA: '',
        UNSDLEA: '30540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3143, 40.7118],
            [-73.3065, 40.7415],
            [-73.2822, 40.7357],
            [-73.2674, 40.6712],
            [-73.3143, 40.7118],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630690',
        NAME: 'West Islip Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16142467,
        AWATER: 6127033,
        INTPTLAT: '+40.7028607',
        INTPTLON: '-073.2955260',
        ELSDLEA: '',
        UNSDLEA: '30690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6216, 43.3168],
            [-73.617, 43.3053],
            [-73.6386, 43.3064],
            [-73.6216, 43.3168],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602310',
        NAME: 'Glens Falls Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2224278,
        AWATER: 92987,
        INTPTLAT: '+43.3112849',
        INTPTLON: '-073.6287823',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6216, 43.3168],
            [-73.6386, 43.3064],
            [-73.6659, 43.2909],
            [-73.6216, 43.3168],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612240',
        NAME: 'Glens Falls City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14659838,
        AWATER: 351369,
        INTPTLAT: '+43.3164868',
        INTPTLON: '-073.6516287',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8988, 40.9748],
            [-73.8548, 40.982],
            [-73.8431, 40.9836],
            [-73.8104, 40.9884],
            [-73.8107, 40.9879],
            [-73.8281, 40.9546],
            [-73.8335, 40.9483],
            [-73.8346, 40.9353],
            [-73.8527, 40.9099],
            [-73.9179, 40.9176],
            [-73.8988, 40.9748],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631920',
        NAME: 'Yonkers City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46651457,
        AWATER: 5916223,
        INTPTLAT: '+40.9458622',
        INTPTLON: '-073.8674464',
        ELSDLEA: '',
        UNSDLEA: '31920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7665, 41.1423],
            [-73.7144, 41.1596],
            [-73.6288, 41.1464],
            [-73.6339, 41.144],
            [-73.7063, 41.0741],
            [-73.7066, 41.0729],
            [-73.7344, 41.0746],
            [-73.7665, 41.1315],
            [-73.7665, 41.1423],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606060',
        NAME: 'Byram Hills Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54798512,
        AWATER: 4283286,
        INTPTLAT: '+41.1374094',
        INTPTLON: '-073.6999536',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.057, 43.7445],
            [-73.9662, 43.7535],
            [-73.8614, 43.591],
            [-74.0629, 43.5289],
            [-74.1826, 43.5209],
            [-74.2038, 43.6588],
            [-74.2146, 43.7287],
            [-74.057, 43.7445],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615870',
        NAME: 'Johnsburg Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561855085,
        AWATER: 9843674,
        INTPTLAT: '+43.6575543',
        INTPTLON: '-074.0641865',
        ELSDLEA: '',
        UNSDLEA: '15870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1826, 43.5209],
            [-74.0629, 43.5289],
            [-73.8614, 43.591],
            [-73.711, 43.6331],
            [-73.7032, 43.5065],
            [-73.7551, 43.4248],
            [-73.8743, 43.4249],
            [-73.9632, 43.4831],
            [-74.1724, 43.4538],
            [-74.1826, 43.5209],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629910',
        NAME: 'Warrensburg Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440076626,
        AWATER: 6904779,
        INTPTLAT: '+43.5141531',
        INTPTLON: '-073.8784138',
        ELSDLEA: '',
        UNSDLEA: '29910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6901, 40.8547],
            [-72.6188, 40.8699],
            [-72.6187, 40.8584],
            [-72.5882, 40.7562],
            [-72.6927, 40.728],
            [-72.6901, 40.8547],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631020',
        NAME: 'Westhampton Beach Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41529470,
        AWATER: 44911719,
        INTPTLAT: '+40.7998479',
        INTPTLON: '-072.6510791',
        ELSDLEA: '',
        UNSDLEA: '31020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3997, 40.7386],
            [-73.3506, 40.7654],
            [-73.3452, 40.7424],
            [-73.3701, 40.7368],
            [-73.398, 40.7316],
            [-73.3997, 40.7386],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631800',
        NAME: 'Wyandanch Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8583307,
        AWATER: 8646,
        INTPTLAT: '+40.7466890',
        INTPTLON: '-073.3710449',
        ELSDLEA: '',
        UNSDLEA: '31800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4102, 40.9886],
            [-72.3702, 41.0239],
            [-72.2876, 41.0107],
            [-72.2726, 40.993],
            [-72.2613, 40.9531],
            [-72.2852, 40.9488],
            [-72.3461, 40.9693],
            [-72.4102, 40.9886],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625380',
        NAME: 'Sag Harbor Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34858572,
        AWATER: 29083211,
        INTPTLAT: '+41.0004568',
        INTPTLON: '-072.3468955',
        ELSDLEA: '',
        UNSDLEA: '25380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2852, 40.9488],
            [-72.2613, 40.9531],
            [-72.2086, 40.8813],
            [-72.2645, 40.861],
            [-72.2852, 40.9488],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625410',
        NAME: 'Sagaponack Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11203470,
        AWATER: 26605213,
        INTPTLAT: '+40.9192222',
        INTPTLON: '-072.2568554',
        ELSDLEA: '',
        UNSDLEA: '25410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.086, 40.7648],
            [-73.06, 40.7655],
            [-73.069, 40.6958],
            [-73.1074, 40.684],
            [-73.086, 40.7648],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625920',
        NAME: 'Sayville Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14371212,
        AWATER: 11487331,
        INTPTLAT: '+40.7294948',
        INTPTLON: '-073.0869668',
        ELSDLEA: '',
        UNSDLEA: '25920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3421, 41.1039],
            [-72.2641, 41.0881],
            [-72.2652, 41.0461],
            [-72.2876, 41.0107],
            [-72.3702, 41.0239],
            [-72.3939, 41.0725],
            [-72.3421, 41.1039],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626640',
        NAME: 'Shelter Island Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31506254,
        AWATER: 43885540,
        INTPTLAT: '+41.0655576',
        INTPTLON: '-072.3195755',
        ELSDLEA: '',
        UNSDLEA: '26640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7107, 42.7941],
            [-73.6768, 42.7833],
            [-73.6844, 42.7608],
            [-73.6989, 42.7511],
            [-73.7107, 42.7941],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607980',
        NAME: 'Cohoes City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9773349,
        AWATER: 1198514,
        INTPTLAT: '+42.7728670',
        INTPTLON: '-073.7077593',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8778, 42.7194],
            [-73.8743, 42.7207],
            [-73.7623, 42.679],
            [-73.7433, 42.6794],
            [-73.7233, 42.6729],
            [-73.725, 42.6702],
            [-73.7596, 42.6142],
            [-73.7597, 42.6138],
            [-73.8472, 42.6486],
            [-73.8778, 42.7194],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602460',
        NAME: 'Albany City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55395226,
        AWATER: 1418568,
        INTPTLAT: '+42.6657453',
        INTPTLON: '-073.7983532',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4344, 44.7017],
            [-73.4791, 44.6743],
            [-73.4826, 44.7067],
            [-73.4344, 44.7017],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623280',
        NAME: 'Plattsburgh City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13049723,
        AWATER: 4028896,
        INTPTLAT: '+44.6955627',
        INTPTLON: '-073.4564144',
        ELSDLEA: '',
        UNSDLEA: '23280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6989, 42.7511],
            [-73.6844, 42.7608],
            [-73.6864, 42.754],
            [-73.6947, 42.7321],
            [-73.6993, 42.7363],
            [-73.6989, 42.7511],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612660',
        NAME: 'Green Island Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1935068,
        AWATER: 482922,
        INTPTLAT: '+42.7478701',
        INTPTLON: '-073.6925954',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9444, 41.7146],
            [-73.9065, 41.7062],
            [-73.9022, 41.6714],
            [-73.9425, 41.6841],
            [-73.9444, 41.7146],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623760',
        NAME: 'Poughkeepsie City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13324261,
        AWATER: 1487437,
        INTPTLAT: '+41.6945945',
        INTPTLON: '-073.9198692',
        ELSDLEA: '',
        UNSDLEA: '23760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9569, 42.8327],
            [-78.9142, 42.9403],
            [-78.8238, 42.9581],
            [-78.7983, 42.9493],
            [-78.799, 42.9299],
            [-78.7993, 42.8988],
            [-78.8001, 42.8648],
            [-78.8017, 42.832],
            [-78.869, 42.8323],
            [-78.9569, 42.8327],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605850',
        NAME: 'Buffalo City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104592941,
        AWATER: 31362925,
        INTPTLAT: '+42.8924919',
        INTPTLON: '-078.8596862',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7174, 42.8094],
            [-73.6851, 42.8419],
            [-73.6612, 42.8219],
            [-73.6768, 42.7833],
            [-73.7107, 42.7941],
            [-73.7174, 42.8094],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630060',
        NAME: 'Waterford-Halfmoon Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16893916,
        AWATER: 2212349,
        INTPTLAT: '+42.8041515',
        INTPTLON: '-073.6872928',
        ELSDLEA: '',
        UNSDLEA: '30060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9788, 43.1789],
            [-73.7817, 43.184],
            [-73.7108, 43.1537],
            [-73.7245, 43.0408],
            [-73.8072, 43.0345],
            [-73.9639, 43.0728],
            [-73.9775, 43.1669],
            [-73.9788, 43.1789],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625770',
        NAME: 'Saratoga Springs City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287638853,
        AWATER: 3615904,
        INTPTLAT: '+43.1155090',
        INTPTLON: '-073.8360267',
        ELSDLEA: '',
        UNSDLEA: '25770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.869, 42.8323],
            [-78.8017, 42.832],
            [-78.8114, 42.8047],
            [-78.8624, 42.8054],
            [-78.869, 42.8323],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616440',
        NAME: 'Lackawanna City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17019498,
        AWATER: 128249,
        INTPTLAT: '+42.8184242',
        INTPTLON: '-078.8327691',
        ELSDLEA: '',
        UNSDLEA: '16440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.859, 40.998],
            [-73.8502, 41.0248],
            [-73.843, 41.0354],
            [-73.8349, 41.0339],
            [-73.8279, 41.0105],
            [-73.8431, 40.9836],
            [-73.8548, 40.982],
            [-73.859, 40.998],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603180',
        NAME: 'Ardsley Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10841367,
        AWATER: 59705,
        INTPTLAT: '+41.0113124',
        INTPTLON: '-073.8413137',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8335, 40.9483],
            [-73.8198, 40.9311],
            [-73.8346, 40.9353],
            [-73.8335, 40.9483],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605640',
        NAME: 'Bronxville Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2491524,
        AWATER: 0,
        INTPTLAT: '+40.9394437',
        INTPTLON: '-073.8264463',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7674, 43.0552],
            [-74.7702, 43.1125],
            [-74.5477, 43.1012],
            [-74.4428, 43.0589],
            [-74.5781, 42.9912],
            [-74.7168, 42.9863],
            [-74.743, 42.9918],
            [-74.7674, 43.0552],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3601107',
        NAME: 'Oppenheim-Ephratah-St. Johnsville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301660190,
        AWATER: 4933819,
        INTPTLAT: '+43.0511696',
        INTPTLON: '-074.6490207',
        ELSDLEA: '',
        UNSDLEA: '01107',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0853, 43],
            [-75.0416, 43.0525],
            [-74.942, 43.022],
            [-74.8899, 42.9891],
            [-74.99, 42.9388],
            [-75.0606, 42.9197],
            [-75.0853, 43],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3601087',
        NAME: 'Central Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137409107,
        AWATER: 1533837,
        INTPTLAT: '+42.9856343',
        INTPTLON: '-075.0022855',
        ELSDLEA: '',
        UNSDLEA: '01087',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8116, 45.0025],
            [-73.3431, 45.0108],
            [-73.3393, 44.9169],
            [-73.4157, 44.9526],
            [-73.5141, 44.9238],
            [-73.5017, 44.8579],
            [-73.542, 44.8487],
            [-73.5723, 44.9121],
            [-73.7734, 44.9411],
            [-73.8116, 45.0025],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621250',
        NAME: 'Northeastern Clinton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322255234,
        AWATER: 20073018,
        INTPTLAT: '+44.9605735',
        INTPTLON: '-073.5557129',
        ELSDLEA: '',
        UNSDLEA: '21250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6411, 42.8124],
            [-75.5629, 42.8744],
            [-75.4983, 42.8562],
            [-75.4506, 42.8538],
            [-75.4325, 42.7684],
            [-75.6411, 42.8124],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613380',
        NAME: 'Hamilton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127442851,
        AWATER: 2263931,
        INTPTLAT: '+42.8193198',
        INTPTLON: '-075.5149772',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4689, 42.6177],
            [-76.4589, 42.6274],
            [-76.3141, 42.6729],
            [-76.2746, 42.5955],
            [-76.3285, 42.549],
            [-76.4419, 42.5389],
            [-76.4689, 42.6177],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613020',
        NAME: 'Groton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152493502,
        AWATER: 270690,
        INTPTLAT: '+42.6003588',
        INTPTLON: '-076.3651378',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6176, 43.9894],
            [-73.4275, 44.0371],
            [-73.4074, 44.0203],
            [-73.4079, 43.9299],
            [-73.4047, 43.9238],
            [-73.5027, 43.884],
            [-73.6288, 43.8836],
            [-73.6176, 43.9894],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608610',
        NAME: 'Crown Point Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209263182,
        AWATER: 15443805,
        INTPTLAT: '+43.9638373',
        INTPTLON: '-073.5832478',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4824, 44.2961],
            [-73.3456, 44.2328],
            [-73.4014, 44.1523],
            [-73.4117, 44.1115],
            [-73.5251, 44.1091],
            [-73.4824, 44.2961],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631080',
        NAME: 'Westport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164849921,
        AWATER: 19127114,
        INTPTLAT: '+44.2013544',
        INTPTLON: '-073.4544040',
        ELSDLEA: '',
        UNSDLEA: '31080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6299, 44.0985],
            [-73.5251, 44.1091],
            [-73.4117, 44.1115],
            [-73.4139, 44.1078],
            [-73.4275, 44.0371],
            [-73.6176, 43.9894],
            [-73.6299, 44.0985],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619830',
        NAME: 'Moriah Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168649743,
        AWATER: 16730644,
        INTPTLAT: '+44.0549668',
        INTPTLON: '-073.5326186',
        ELSDLEA: '',
        UNSDLEA: '19830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7643, 42.9925],
            [-75.6994, 43.0212],
            [-75.6942, 43.0284],
            [-75.5777, 42.9147],
            [-75.5629, 42.8744],
            [-75.6411, 42.8124],
            [-75.6784, 42.8241],
            [-75.7322, 42.8446],
            [-75.7643, 42.9925],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619920',
        NAME: 'Morrisville-Eaton Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201345861,
        AWATER: 2862318,
        INTPTLAT: '+42.9052596',
        INTPTLON: '-075.6634822',
        ELSDLEA: '',
        UNSDLEA: '19920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9368, 41.2108],
            [-73.8471, 41.2534],
            [-73.8206, 41.2163],
            [-73.9093, 41.1644],
            [-73.9217, 41.1863],
            [-73.9368, 41.2108],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608580',
        NAME: 'Croton-Harmon Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30935587,
        AWATER: 17031822,
        INTPTLAT: '+41.2107648',
        INTPTLON: '-073.8780708',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8899, 41.0257],
            [-73.8502, 41.0248],
            [-73.859, 40.998],
            [-73.8936, 41.0053],
            [-73.8899, 41.0257],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609120',
        NAME: 'Dobbs Ferry Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5478123,
        AWATER: 2320889,
        INTPTLAT: '+41.0135239',
        INTPTLON: '-073.8730572',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8279, 41.0105],
            [-73.7966, 41.0097],
            [-73.8104, 40.9884],
            [-73.8431, 40.9836],
            [-73.8279, 41.0105],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610200',
        NAME: 'Edgemont Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6981698,
        AWATER: 53809,
        INTPTLAT: '+40.9985556',
        INTPTLON: '-073.8198696',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.843, 41.0354],
            [-73.838, 41.064],
            [-73.8345, 41.0664],
            [-73.8019, 41.0612],
            [-73.8048, 41.0571],
            [-73.8349, 41.0339],
            [-73.843, 41.0354],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610650',
        NAME: 'Elmsford Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7470255,
        AWATER: 5284,
        INTPTLAT: '+41.0527480',
        INTPTLON: '-073.8243690',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8945, 41.0646],
            [-73.838, 41.064],
            [-73.843, 41.0354],
            [-73.8502, 41.0248],
            [-73.8899, 41.0257],
            [-73.8945, 41.0646],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615450',
        NAME: 'Irvington Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11362373,
        AWATER: 6889452,
        INTPTLAT: '+41.0453942',
        INTPTLON: '-073.8655176',
        ELSDLEA: '',
        UNSDLEA: '15450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1434, 42.9574],
            [-78.094, 42.9686],
            [-77.9342, 42.9456],
            [-77.9291, 42.9192],
            [-77.9554, 42.8065],
            [-78.0257, 42.8046],
            [-78.1167, 42.9163],
            [-78.1434, 42.9574],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622500',
        NAME: 'Pavilion Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199921061,
        AWATER: 278673,
        INTPTLAT: '+42.8955867',
        INTPTLON: '-078.0298269',
        ELSDLEA: '',
        UNSDLEA: '22500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1788, 42.8703],
            [-78.1167, 42.9163],
            [-78.0257, 42.8046],
            [-78.0741, 42.7546],
            [-78.1897, 42.7893],
            [-78.1788, 42.8703],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631860',
        NAME: 'Wyoming Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122270960,
        AWATER: 234265,
        INTPTLAT: '+42.8225938',
        INTPTLON: '-078.1103780',
        ELSDLEA: '',
        UNSDLEA: '31860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9634, 42.9188],
            [-76.9541, 42.9696],
            [-76.9261, 43.0152],
            [-76.8276, 43.0101],
            [-76.8178, 42.8372],
            [-76.9703, 42.841],
            [-76.9634, 42.9188],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600014',
        NAME: 'Waterloo Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217269352,
        AWATER: 7613043,
        INTPTLAT: '+42.9166394',
        INTPTLON: '-076.8832971',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7402, 42.7829],
            [-76.5389, 42.844],
            [-76.5153, 42.841],
            [-76.4658, 42.7569],
            [-76.4589, 42.6274],
            [-76.4689, 42.6177],
            [-76.6665, 42.6235],
            [-76.7342, 42.7332],
            [-76.7402, 42.7829],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627570',
        NAME: 'Southern Cayuga Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379110340,
        AWATER: 44834161,
        INTPTLAT: '+42.7296242',
        INTPTLON: '-076.5843857',
        ELSDLEA: '',
        UNSDLEA: '27570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5967, 42.9531],
            [-76.5751, 42.9541],
            [-76.5007, 42.9606],
            [-76.4726, 42.8422],
            [-76.5153, 42.841],
            [-76.5389, 42.844],
            [-76.5967, 42.9531],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603480',
        NAME: 'Auburn City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83624517,
        AWATER: 12980095,
        INTPTLAT: '+42.9070722',
        INTPTLON: '-076.5348404',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6283, 43.114],
            [-76.4994, 43.0972],
            [-76.4941, 42.99],
            [-76.5007, 42.9606],
            [-76.5751, 42.9541],
            [-76.6283, 43.114],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630420',
        NAME: 'Weedsport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110443188,
        AWATER: 1218908,
        INTPTLAT: '+43.0390979',
        INTPTLON: '-076.5482608',
        ELSDLEA: '',
        UNSDLEA: '30420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0838, 42.0384],
            [-73.9433, 41.9855],
            [-73.9403, 41.8727],
            [-73.9485, 41.8166],
            [-73.9677, 41.8197],
            [-74.1015, 41.8094],
            [-74.0755, 41.8971],
            [-74.1341, 41.931],
            [-74.0838, 42.0384],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616290',
        NAME: 'Kingston City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233962810,
        AWATER: 18799541,
        INTPTLAT: '+41.9199913',
        INTPTLON: '-074.0261192',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9364, 42.7173],
            [-76.852, 42.6997],
            [-76.7342, 42.7332],
            [-76.6665, 42.6235],
            [-76.6381, 42.5844],
            [-76.7912, 42.5346],
            [-76.8956, 42.5415],
            [-76.8944, 42.6073],
            [-76.9364, 42.7173],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622140',
        NAME: 'South Seneca Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274032886,
        AWATER: 65120538,
        INTPTLAT: '+42.6349428',
        INTPTLON: '-076.7935431',
        ELSDLEA: '',
        UNSDLEA: '22140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8276, 43.0101],
            [-76.7214, 42.9937],
            [-76.738, 42.9613],
            [-76.7291, 42.8081],
            [-76.8178, 42.8372],
            [-76.8276, 43.0101],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626430',
        NAME: 'Seneca Falls Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126935591,
        AWATER: 25316266,
        INTPTLAT: '+42.9058576',
        INTPTLON: '-076.7738939',
        ELSDLEA: '',
        UNSDLEA: '26430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5472, 43.1429],
            [-77.5348, 43.134],
            [-77.554, 43.0948],
            [-77.6098, 43.1144],
            [-77.5472, 43.1429],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605460',
        NAME: 'Brighton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20962901,
        AWATER: 149027,
        INTPTLAT: '+43.1165956',
        INTPTLON: '-077.5725517',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5112, 43.1242],
            [-77.4761, 43.1224],
            [-77.4794, 43.0994],
            [-77.5112, 43.1242],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609930',
        NAME: 'East Rochester Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7365934,
        AWATER: 31402,
        INTPTLAT: '+43.1117540',
        INTPTLON: '-077.4918917',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8964, 43.3563],
            [-77.7489, 43.3429],
            [-77.6727, 43.2947],
            [-77.7426, 43.2343],
            [-77.8466, 43.236],
            [-77.8964, 43.3563],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614460',
        NAME: 'Hilton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170859864,
        AWATER: 11566024,
        INTPTLAT: '+43.2939562',
        INTPTLON: '-077.8020492',
        ELSDLEA: '',
        UNSDLEA: '14460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0911, 43.2757],
            [-77.9963, 43.2666],
            [-78.0178, 43.1431],
            [-78.1111, 43.1315],
            [-78.0911, 43.2757],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614610',
        NAME: 'Holley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146100098,
        AWATER: 102380,
        INTPTLAT: '+43.2052117',
        INTPTLON: '-078.0576845',
        ELSDLEA: '',
        UNSDLEA: '14610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2934, 42.7477],
            [-75.2936, 42.7669],
            [-75.1213, 42.8045],
            [-75.0437, 42.7445],
            [-75.0762, 42.6533],
            [-75.1162, 42.6433],
            [-75.2159, 42.6528],
            [-75.3107, 42.6562],
            [-75.2934, 42.7477],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610260',
        NAME: 'Edmeston Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251109196,
        AWATER: 551231,
        INTPTLAT: '+42.7127363',
        INTPTLON: '-075.1856248',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4783, 42.6062],
            [-75.4115, 42.6431],
            [-75.391, 42.7395],
            [-75.2934, 42.7477],
            [-75.3107, 42.6562],
            [-75.2159, 42.6528],
            [-75.3254, 42.5222],
            [-75.4252, 42.4734],
            [-75.4783, 42.6062],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600019',
        NAME: 'Unadilla Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322245486,
        AWATER: 1547288,
        INTPTLAT: '+42.6124169',
        INTPTLON: '-075.3713500',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2058, 42.5065],
            [-75.1162, 42.6433],
            [-75.0762, 42.6533],
            [-75.0108, 42.6194],
            [-75.0504, 42.5402],
            [-75.1358, 42.4899],
            [-75.2079, 42.5027],
            [-75.2058, 42.5065],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616800',
        NAME: 'Laurens Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141949468,
        AWATER: 444813,
        INTPTLAT: '+42.5571884',
        INTPTLON: '-075.1321300',
        ELSDLEA: '',
        UNSDLEA: '16800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2705, 42.111],
            [-79.2705, 42.1116],
            [-79.2515, 42.1188],
            [-79.1702, 42.0759],
            [-79.263, 42.0464],
            [-79.2705, 42.111],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615630',
        NAME: 'Jamestown City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45630863,
        AWATER: 617285,
        INTPTLAT: '+42.0816537',
        INTPTLON: '-079.2288494',
        ELSDLEA: '',
        UNSDLEA: '15630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4087, 42.3264],
            [-79.368, 42.3614],
            [-79.2815, 42.3872],
            [-79.2066, 42.3805],
            [-79.193, 42.2983],
            [-79.1251, 42.2383],
            [-79.2685, 42.1644],
            [-79.3757, 42.2387],
            [-79.4087, 42.3264],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606660',
        NAME: 'Cassadaga Valley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356891730,
        AWATER: 1954860,
        INTPTLAT: '+42.2844625',
        INTPTLON: '-079.2798999',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.3677, 42.1113],
            [-79.2705, 42.111],
            [-79.263, 42.0464],
            [-79.2498, 41.9988],
            [-79.3817, 41.9987],
            [-79.3677, 42.1113],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627660',
        NAME: 'Southwestern Central School District at Jamestown',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111815788,
        AWATER: 37692,
        INTPTLAT: '+42.0513235',
        INTPTLON: '-079.3262763',
        ELSDLEA: '',
        UNSDLEA: '27660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2752, 42.5143],
            [-79.2484, 42.4951],
            [-79.4002, 42.4571],
            [-79.2752, 42.5143],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609420',
        NAME: 'Dunkirk City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37054993,
        AWATER: 258991,
        INTPTLAT: '+42.4823464',
        INTPTLON: '-079.3168885',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4398, 42.2255],
            [-79.3757, 42.2387],
            [-79.2685, 42.1644],
            [-79.2515, 42.1188],
            [-79.2705, 42.1116],
            [-79.3962, 42.1577],
            [-79.4398, 42.2255],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604530',
        NAME: 'Bemus Point Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119060342,
        AWATER: 327946,
        INTPTLAT: '+42.1689679',
        INTPTLON: '-079.3394184',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1369, 42.5698],
            [-79.0812, 42.5539],
            [-79.061, 42.513],
            [-79.2529, 42.4728],
            [-79.2484, 42.4951],
            [-79.2752, 42.5143],
            [-79.1369, 42.5698],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626880',
        NAME: 'Silver Creek Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85199476,
        AWATER: 1160716,
        INTPTLAT: '+42.5298624',
        INTPTLON: '-079.1631569',
        ELSDLEA: '',
        UNSDLEA: '26880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4002, 42.4571],
            [-79.2484, 42.4951],
            [-79.2529, 42.4728],
            [-79.2815, 42.3872],
            [-79.368, 42.3614],
            [-79.4221, 42.4418],
            [-79.4002, 42.4571],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611520',
        NAME: 'Fredonia Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101906242,
        AWATER: 283209,
        INTPTLAT: '+42.4288014',
        INTPTLON: '-079.3267348',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7813, 40.8293],
            [-72.7285, 40.8118],
            [-72.7284, 40.7188],
            [-72.7824, 40.7024],
            [-72.7813, 40.8293],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609870',
        NAME: 'East Moriches Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19512612,
        AWATER: 40456110,
        INTPTLAT: '+40.7637592',
        INTPTLON: '-072.7670323',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5624, 40.8783],
            [-72.5411, 40.7899],
            [-72.6187, 40.8584],
            [-72.6188, 40.8699],
            [-72.5624, 40.8783],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609900',
        NAME: 'East Quogue Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21336301,
        AWATER: 10836868,
        INTPTLAT: '+40.8458303',
        INTPTLON: '-072.5754518',
        ELSDLEA: '',
        UNSDLEA: '09900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3672, 40.8455],
            [-73.3372, 40.8624],
            [-73.3186, 40.8568],
            [-73.3091, 40.8271],
            [-73.3581, 40.832],
            [-73.3672, 40.8455],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610680',
        NAME: 'Elwood Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13616267,
        AWATER: 0,
        INTPTLAT: '+40.8474329',
        INTPTLON: '-073.3403427',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4023, 41.2016],
            [-72.3421, 41.1039],
            [-72.3939, 41.0725],
            [-72.4764, 41.1875],
            [-72.4023, 41.2016],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612840',
        NAME: 'Greenport Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12685548,
        AWATER: 71951700,
        INTPTLAT: '+41.1166117',
        INTPTLON: '-072.3774477',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6001, 43.2586],
            [-77.5745, 43.2454],
            [-77.5753, 43.2315],
            [-77.5846, 43.1954],
            [-77.6001, 43.2586],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615390',
        NAME: 'West Irondequoit Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18115098,
        AWATER: 770140,
        INTPTLAT: '+43.2231798',
        INTPTLON: '-077.5971764',
        ELSDLEA: '',
        UNSDLEA: '15390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1702, 43.3724],
            [-77.9217, 43.3588],
            [-77.9963, 43.2666],
            [-78.0911, 43.2757],
            [-78.1702, 43.3724],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616200',
        NAME: 'Kendall Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166265729,
        AWATER: 1456396,
        INTPTLAT: '+43.3293790',
        INTPTLON: '-078.0428003',
        ELSDLEA: '',
        UNSDLEA: '16200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4761, 43.1224],
            [-77.3729, 43.1074],
            [-77.3716, 43.0537],
            [-77.4526, 43.0459],
            [-77.4794, 43.0994],
            [-77.4761, 43.1224],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610890',
        NAME: 'Fairport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67751891,
        AWATER: 780960,
        INTPTLAT: '+43.0843076',
        INTPTLON: '-077.4245869',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8466, 43.236],
            [-77.7426, 43.2343],
            [-77.7015, 43.1794],
            [-77.7542, 43.145],
            [-77.8822, 43.1715],
            [-77.8466, 43.236],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627780',
        NAME: 'Spencerport Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94491035,
        AWATER: 684287,
        INTPTLAT: '+43.1855313',
        INTPTLON: '-077.7773105',
        ELSDLEA: '',
        UNSDLEA: '27780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5223, 40.8248],
            [-73.497, 40.849],
            [-73.4547, 40.8359],
            [-73.4498, 40.8104],
            [-73.4487, 40.8044],
            [-73.503, 40.7828],
            [-73.5175, 40.7846],
            [-73.5223, 40.8248],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628560',
        NAME: 'Syosset Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35246139,
        AWATER: 79495,
        INTPTLAT: '+40.8164962',
        INTPTLON: '-073.4958988',
        ELSDLEA: '',
        UNSDLEA: '28560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9677, 41.8197],
            [-73.9485, 41.8166],
            [-73.9444, 41.7146],
            [-73.9425, 41.6841],
            [-73.947, 41.6688],
            [-74.051, 41.6794],
            [-74.0727, 41.6878],
            [-73.9677, 41.8197],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614400',
        NAME: 'Highland Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105072257,
        AWATER: 7581063,
        INTPTLAT: '+41.7344434',
        INTPTLON: '-073.9892819',
        ELSDLEA: '',
        UNSDLEA: '14400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4498, 40.8104],
            [-73.3581, 40.832],
            [-73.3091, 40.8271],
            [-73.2948, 40.7949],
            [-73.295, 40.7843],
            [-73.3506, 40.7654],
            [-73.3997, 40.7386],
            [-73.4367, 40.7423],
            [-73.4487, 40.8044],
            [-73.4498, 40.8104],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613290',
        NAME: 'Half Hollow Hills Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81599757,
        AWATER: 2786,
        INTPTLAT: '+40.7874578',
        INTPTLON: '-073.3790903',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3065, 40.7415],
            [-73.295, 40.7843],
            [-73.2948, 40.7949],
            [-73.2699, 40.8058],
            [-73.231, 40.8041],
            [-73.2102, 40.7627],
            [-73.2412, 40.7528],
            [-73.2822, 40.7357],
            [-73.3065, 40.7415],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605280',
        NAME: 'Brentwood Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42985973,
        AWATER: 0,
        INTPTLAT: '+40.7747649',
        INTPTLON: '-073.2595657',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8158, 40.8236],
            [-72.7813, 40.8293],
            [-72.7824, 40.7024],
            [-72.8076, 40.6939],
            [-72.8158, 40.8236],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606840',
        NAME: 'Center Moriches Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13761891,
        AWATER: 19000543,
        INTPTLAT: '+40.7650541',
        INTPTLON: '-072.7958058',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.231, 40.8041],
            [-73.1599, 40.8094],
            [-73.1651, 40.7756],
            [-73.2003, 40.7573],
            [-73.2102, 40.7627],
            [-73.231, 40.8041],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606870',
        NAME: 'Central Islip Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21168582,
        AWATER: 0,
        INTPTLAT: '+40.7874498',
        INTPTLON: '-073.1919024',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6428, 44.7663],
            [-73.542, 44.8487],
            [-73.5017, 44.8579],
            [-73.3681, 44.8279],
            [-73.3414, 44.8088],
            [-73.3521, 44.7607],
            [-73.3704, 44.6777],
            [-73.4344, 44.7017],
            [-73.4826, 44.7067],
            [-73.4791, 44.6743],
            [-73.5371, 44.6878],
            [-73.6428, 44.7663],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604290',
        NAME: 'Beekmantown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232359279,
        AWATER: 49329286,
        INTPTLAT: '+44.7698694',
        INTPTLON: '-073.4795797',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2023, 44.9918],
            [-73.9584, 44.9982],
            [-73.9976, 44.785],
            [-74.0611, 44.7022],
            [-74.1102, 44.8642],
            [-74.2023, 44.9918],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607080',
        NAME: 'Chateaugay Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324290163,
        AWATER: 3070249,
        INTPTLAT: '+44.8987776',
        INTPTLON: '-074.0806853',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5371, 44.6878],
            [-73.4791, 44.6743],
            [-73.4344, 44.7017],
            [-73.3704, 44.6777],
            [-73.3717, 44.6752],
            [-73.3615, 44.5636],
            [-73.3386, 44.5468],
            [-73.4891, 44.5629],
            [-73.585, 44.5152],
            [-73.6409, 44.5505],
            [-73.8132, 44.5503],
            [-73.5371, 44.6878],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622830',
        NAME: 'Peru Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337675890,
        AWATER: 77988361,
        INTPTLAT: '+44.5929992',
        INTPTLON: '-073.5561658',
        ELSDLEA: '',
        UNSDLEA: '22830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6677, 40.6918],
            [-73.6555, 40.7075],
            [-73.6412, 40.7131],
            [-73.637, 40.6839],
            [-73.6381, 40.6837],
            [-73.6677, 40.6823],
            [-73.6677, 40.6918],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630660',
        NAME: 'West Hempstead Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6457970,
        AWATER: 317135,
        INTPTLAT: '+40.6948680',
        INTPTLON: '-073.6503074',
        ELSDLEA: '',
        UNSDLEA: '30660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3969, 40.7144],
            [-73.3661, 40.6645],
            [-73.4063, 40.6516],
            [-73.4151, 40.7063],
            [-73.3969, 40.7144],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608310',
        NAME: 'Copiague Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11947819,
        AWATER: 1248421,
        INTPTLAT: '+40.6799969',
        INTPTLON: '-073.3962139',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5624, 40.8783],
            [-72.5182, 40.9114],
            [-72.4906, 40.897],
            [-72.478, 40.8455],
            [-72.4565, 40.795],
            [-72.5417, 40.7699],
            [-72.5411, 40.7899],
            [-72.5624, 40.8783],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613530',
        NAME: 'Hampton Bays Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28637811,
        AWATER: 52633293,
        INTPTLAT: '+40.8351648',
        INTPTLON: '-072.5201520',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9634, 43.124],
            [-76.7234, 43.103],
            [-76.7214, 42.9937],
            [-76.8276, 43.0101],
            [-76.9261, 43.0152],
            [-76.9634, 43.124],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607860',
        NAME: 'Clyde-Savannah Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228400492,
        AWATER: 3527443,
        INTPTLAT: '+43.0710714',
        INTPTLON: '-076.8191782',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3587, 43.143],
            [-77.2609, 43.1276],
            [-77.2624, 43.1237],
            [-77.2678, 43.1057],
            [-77.3525, 43.1178],
            [-77.3534, 43.131],
            [-77.3552, 43.1366],
            [-77.3587, 43.143],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611740',
        NAME: 'Gananda Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42768886,
        AWATER: 95142,
        INTPTLAT: '+43.1040797',
        INTPTLON: '-077.3130463',
        ELSDLEA: '',
        UNSDLEA: '11740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9989, 41.463],
            [-73.9828, 41.5492],
            [-73.9595, 41.5714],
            [-73.9074, 41.5089],
            [-73.9815, 41.4389],
            [-73.9989, 41.463],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604140',
        NAME: 'Beacon City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51863930,
        AWATER: 14202689,
        INTPTLAT: '+41.5093775',
        INTPTLON: '-073.9622164',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.947, 41.6688],
            [-73.9425, 41.6841],
            [-73.9022, 41.6714],
            [-73.8961, 41.6577],
            [-73.9506, 41.6326],
            [-73.947, 41.6688],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606500',
        NAME: 'Spackenkill Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16130629,
        AWATER: 2383656,
        INTPTLAT: '+41.6550830',
        INTPTLON: '-073.9273718',
        ELSDLEA: '',
        UNSDLEA: '06500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4081, 42.8223],
            [-73.2893, 42.8041],
            [-73.265, 42.7459],
            [-73.307, 42.6326],
            [-73.3473, 42.5242],
            [-73.4436, 42.5594],
            [-73.4175, 42.6464],
            [-73.4814, 42.7193],
            [-73.5176, 42.7631],
            [-73.4081, 42.8223],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604620',
        NAME: 'Berlin Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 414888142,
        AWATER: 3720595,
        INTPTLAT: '+42.7092923',
        INTPTLON: '-073.3979846',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6252, 42.7644],
            [-73.6075, 42.8184],
            [-73.4727, 42.856],
            [-73.4081, 42.8223],
            [-73.5176, 42.7631],
            [-73.4814, 42.7193],
            [-73.6402, 42.73],
            [-73.6455, 42.7526],
            [-73.6252, 42.7644],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605520',
        NAME: 'Brunswick Central School District (Brittonkill)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177998851,
        AWATER: 3561163,
        INTPTLAT: '+42.7868698',
        INTPTLON: '-073.5525035',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7597, 42.6138],
            [-73.7596, 42.6142],
            [-73.7082, 42.6681],
            [-73.6584, 42.6846],
            [-73.5513, 42.5361],
            [-73.6081, 42.482],
            [-73.6467, 42.506],
            [-73.7522, 42.5674],
            [-73.7597, 42.6138],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609630',
        NAME: 'East Greenbush Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195772007,
        AWATER: 2725903,
        INTPTLAT: '+42.5760476',
        INTPTLON: '-073.6519526',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7296, 42.9226],
            [-73.6714, 42.9196],
            [-73.6401, 42.8252],
            [-73.6612, 42.8219],
            [-73.6851, 42.8419],
            [-73.7296, 42.9226],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618900',
        NAME: 'Mechanicville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42967783,
        AWATER: 3183777,
        INTPTLAT: '+42.8869215',
        INTPTLON: '-073.6843075',
        ELSDLEA: '',
        UNSDLEA: '18900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.4146, 42.4122],
              [-73.3915, 42.4038],
              [-73.4108, 42.3517],
              [-73.4121, 42.3482],
              [-73.4146, 42.4122],
            ],
          ],
          [
            [
              [-73.6081, 42.482],
              [-73.5513, 42.5361],
              [-73.4436, 42.5594],
              [-73.3473, 42.5242],
              [-73.3835, 42.4255],
              [-73.3886, 42.4116],
              [-73.4146, 42.4184],
              [-73.5201, 42.4608],
              [-73.602, 42.4339],
              [-73.6081, 42.482],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620430',
        NAME: 'New Lebanon Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203573823,
        AWATER: 1503098,
        INTPTLAT: '+42.4705503',
        INTPTLON: '-073.4448126',
        ELSDLEA: '',
        UNSDLEA: '20430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7522, 42.5667],
            [-73.7522, 42.5674],
            [-73.6467, 42.506],
            [-73.7837, 42.4642],
            [-73.7522, 42.5667],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626070',
        NAME: 'Schodack Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84179623,
        AWATER: 3158451,
        INTPTLAT: '+42.5104458',
        INTPTLON: '-073.7189727',
        ELSDLEA: '',
        UNSDLEA: '26070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3917, 40.9081],
            [-73.3372, 40.8624],
            [-73.3672, 40.8455],
            [-73.3859, 40.848],
            [-73.3917, 40.9081],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613620',
        NAME: 'Harborfields Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19325806,
        AWATER: 3765227,
        INTPTLAT: '+40.8819918',
        INTPTLON: '-073.3739357',
        ELSDLEA: '',
        UNSDLEA: '13620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2412, 40.7528],
            [-73.2102, 40.7627],
            [-73.2003, 40.7573],
            [-73.2043, 40.6694],
            [-73.2398, 40.6701],
            [-73.2412, 40.7528],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615540',
        NAME: 'Islip Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13538954,
        AWATER: 14306435,
        INTPTLAT: '+40.7109394',
        INTPTLON: '-073.2224522',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2966, 41.0168],
            [-73.2476, 41.0263],
            [-73.2589, 40.8688],
            [-73.2885, 40.8732],
            [-73.2966, 41.0168],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616260',
        NAME: 'Kings Park Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28534541,
        AWATER: 57500880,
        INTPTLAT: '+40.9183861',
        INTPTLON: '-073.2801660',
        ELSDLEA: '',
        UNSDLEA: '16260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3969, 40.7144],
            [-73.3925, 40.7156],
            [-73.346, 40.668],
            [-73.3661, 40.6645],
            [-73.3969, 40.7144],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617380',
        NAME: 'Lindenhurst Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15287298,
        AWATER: 416445,
        INTPTLAT: '+40.6903894',
        INTPTLON: '-073.3718166',
        ELSDLEA: '',
        UNSDLEA: '17380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2449, 43.2065],
            [-77.111, 43.194],
            [-77.1109, 43.1607],
            [-77.1893, 43.1028],
            [-77.2521, 43.1279],
            [-77.2449, 43.2065],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618540',
        NAME: 'Marion Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109003420,
        AWATER: 266736,
        INTPTLAT: '+43.1660142',
        INTPTLON: '-077.1913503',
        ELSDLEA: '',
        UNSDLEA: '18540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1323, 43.2958],
            [-76.9641, 43.2838],
            [-76.9715, 43.1895],
            [-77.0315, 43.1625],
            [-77.1109, 43.1607],
            [-77.111, 43.194],
            [-77.1323, 43.2958],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627120',
        NAME: 'Sodus Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147311242,
        AWATER: 19592132,
        INTPTLAT: '+43.2183681',
        INTPTLON: '-077.0495811',
        ELSDLEA: '',
        UNSDLEA: '27120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2556, 43.2867],
            [-77.1323, 43.2958],
            [-77.111, 43.194],
            [-77.2449, 43.2065],
            [-77.2556, 43.2867],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631440',
        NAME: 'Williamson Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89691165,
        AWATER: 6188316,
        INTPTLAT: '+43.2428811',
        INTPTLON: '-077.1841624',
        ELSDLEA: '',
        UNSDLEA: '31440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.738, 42.9613],
            [-76.7214, 42.9937],
            [-76.7234, 43.103],
            [-76.7055, 43.1277],
            [-76.6283, 43.114],
            [-76.5751, 42.9541],
            [-76.5967, 42.9531],
            [-76.738, 42.9613],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623430',
        NAME: 'Port Byron Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186449995,
        AWATER: 4019723,
        INTPTLAT: '+43.0349724',
        INTPTLON: '-076.6618324',
        ELSDLEA: '',
        UNSDLEA: '23430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.318, 44.6341],
            [-75.2265, 44.6842],
            [-75.1225, 44.6732],
            [-75.0372, 44.5716],
            [-75.041, 44.5178],
            [-75.1693, 44.5041],
            [-75.3188, 44.5465],
            [-75.318, 44.6341],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606470',
        NAME: 'Canton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329319273,
        AWATER: 3291687,
        INTPTLAT: '+44.5923295',
        INTPTLON: '-075.1818839',
        ELSDLEA: '',
        UNSDLEA: '06470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4285, 44.5037],
            [-75.3188, 44.5465],
            [-75.1693, 44.5041],
            [-75.2383, 44.3726],
            [-75.3276, 44.3439],
            [-75.3456, 44.4432],
            [-75.4285, 44.5037],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614250',
        NAME: 'Hermon-DeKalb Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255121891,
        AWATER: 3078752,
        INTPTLAT: '+44.4636068',
        INTPTLON: '-075.3023457',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7262, 44.9949],
            [-74.7111, 44.953],
            [-74.8595, 44.8345],
            [-74.9218, 44.8904],
            [-75.049, 44.8417],
            [-75.1389, 44.9],
            [-74.9725, 44.9834],
            [-74.8266, 45.0159],
            [-74.7262, 44.9949],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618660',
        NAME: 'Massena Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277635204,
        AWATER: 70384907,
        INTPTLAT: '+44.9287540',
        INTPTLON: '-074.9069380',
        ELSDLEA: '',
        UNSDLEA: '18660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7262, 44.9949],
            [-74.3721, 44.9936],
            [-74.3586, 44.9253],
            [-74.4301, 44.8796],
            [-74.6305, 44.8694],
            [-74.7111, 44.953],
            [-74.7262, 44.9949],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625500',
        NAME: 'Salmon River Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326371600,
        AWATER: 5570586,
        INTPTLAT: '+44.9465698',
        INTPTLON: '-074.5315128',
        ELSDLEA: '',
        UNSDLEA: '25500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2502, 40.5396],
            [-74.2291, 40.5582],
            [-74.2037, 40.5927],
            [-74.2005, 40.6326],
            [-74.1614, 40.6443],
            [-74.0496, 40.6616],
            [-74.0204, 40.7303],
            [-74.0138, 40.7566],
            [-74.0013, 40.7744],
            [-73.9929, 40.7863],
            [-73.9916, 40.7881],
            [-73.9849, 40.7975],
            [-73.954, 40.848],
            [-73.9484, 40.8585],
            [-73.9271, 40.8955],
            [-73.9199, 40.9136],
            [-73.9179, 40.9176],
            [-73.8527, 40.9099],
            [-73.8232, 40.8912],
            [-73.7837, 40.8811],
            [-73.7481, 40.8717],
            [-73.758, 40.8455],
            [-73.7, 40.7394],
            [-73.7, 40.7393],
            [-73.7292, 40.7192],
            [-73.7258, 40.6836],
            [-73.7249, 40.6534],
            [-73.7285, 40.651],
            [-73.7688, 40.5337],
            [-73.8867, 40.4898],
            [-73.9499, 40.5255],
            [-74.0915, 40.5001],
            [-74.2065, 40.481],
            [-74.2282, 40.4774],
            [-74.2437, 40.4835],
            [-74.2526, 40.4871],
            [-74.259, 40.4971],
            [-74.2502, 40.5396],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620580',
        NAME: 'New York City Department Of Education',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 780790796,
        AWATER: 431839634,
        INTPTLAT: '+40.6634682',
        INTPTLON: '-073.9386968',
        ELSDLEA: '',
        UNSDLEA: '20580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6136, 41.7904],
            [-73.5116, 41.7479],
            [-73.5181, 41.6667],
            [-73.521, 41.6198],
            [-73.6302, 41.6398],
            [-73.6328, 41.6746],
            [-73.6136, 41.7904],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609210',
        NAME: 'Dover Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 169842715,
        AWATER: 2805314,
        INTPTLAT: '+41.7063404',
        INTPTLON: '-073.5757279',
        ELSDLEA: '',
        UNSDLEA: '09210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.685, 44.3427],
            [-74.6099, 44.348],
            [-74.433, 44.395],
            [-74.3849, 44.1116],
            [-74.7681, 44.0776],
            [-74.7786, 44.1414],
            [-74.7198, 44.1494],
            [-74.7375, 44.2769],
            [-74.685, 44.3427],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629160',
        NAME: 'Tupper Lake Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701376762,
        AWATER: 59510355,
        INTPTLAT: '+44.1938206',
        INTPTLON: '-074.5692104',
        ELSDLEA: '',
        UNSDLEA: '29160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4577, 43.2329],
            [-74.2863, 43.2068],
            [-74.3311, 43.0742],
            [-74.3106, 43.0488],
            [-74.3417, 43.0352],
            [-74.424, 43.0537],
            [-74.4577, 43.2329],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612270',
        NAME: 'Gloversville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223682326,
        AWATER: 8049511,
        INTPTLAT: '+43.1479206',
        INTPTLON: '-074.3848618',
        ELSDLEA: '',
        UNSDLEA: '12270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7852, 41.8467],
            [-73.6678, 41.8479],
            [-73.6136, 41.7904],
            [-73.6328, 41.6746],
            [-73.7616, 41.6946],
            [-73.7728, 41.7929],
            [-73.7733, 41.7941],
            [-73.7734, 41.7953],
            [-73.7852, 41.8467],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619380',
        NAME: 'Millbrook Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202980877,
        AWATER: 2701165,
        INTPTLAT: '+41.7645638',
        INTPTLON: '-073.6868264',
        ELSDLEA: '',
        UNSDLEA: '19380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8862, 40.8192],
            [-72.8648, 40.8197],
            [-72.8158, 40.8236],
            [-72.8076, 40.6939],
            [-72.8491, 40.6816],
            [-72.8829, 40.7476],
            [-72.8862, 40.8192],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618690',
        NAME: 'William Floyd Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43932505,
        AWATER: 36049698,
        INTPTLAT: '+40.7577718',
        INTPTLON: '-072.8431982',
        ELSDLEA: '',
        UNSDLEA: '18690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0245, 41.081],
            [-72.9793, 41.091],
            [-72.9628, 40.9143],
            [-72.9846, 40.9107],
            [-73.0214, 40.959],
            [-73.0245, 41.081],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619410',
        NAME: 'Miller Place Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17046230,
        AWATER: 51223890,
        INTPTLAT: '+40.9657953',
        INTPTLON: '-072.9908908',
        ELSDLEA: '',
        UNSDLEA: '19410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7807, 41.0927],
            [-71.7941, 41.037],
            [-71.9797, 40.9639],
            [-72.0627, 41.0257],
            [-71.9604, 41.0824],
            [-71.7807, 41.0927],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619710',
        NAME: 'Montauk Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47888967,
        AWATER: 150350953,
        INTPTLAT: '+41.0252262',
        INTPTLON: '-071.9652123',
        ELSDLEA: '',
        UNSDLEA: '19710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4839, 40.9888],
            [-72.4519, 40.9672],
            [-72.4656, 40.9612],
            [-72.4839, 40.9888],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620520',
        NAME: 'New Suffolk Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3284727,
        AWATER: 4846751,
        INTPTLAT: '+40.9906994',
        INTPTLON: '-072.4711356',
        ELSDLEA: '',
        UNSDLEA: '20520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3701, 40.7368],
            [-73.3452, 40.7424],
            [-73.3065, 40.7415],
            [-73.3143, 40.7118],
            [-73.3351, 40.7121],
            [-73.3701, 40.7368],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620910',
        NAME: 'North Babylon Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13905977,
        AWATER: 267451,
        INTPTLAT: '+40.7295878',
        INTPTLON: '-073.3364592',
        ELSDLEA: '',
        UNSDLEA: '20910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3955, 42.7894],
            [-74.2653, 42.7956],
            [-74.2595, 42.7153],
            [-74.1648, 42.7168],
            [-74.2107, 42.6173],
            [-74.3616, 42.6597],
            [-74.3941, 42.7739],
            [-74.3955, 42.7894],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626100',
        NAME: 'Schoharie Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204313568,
        AWATER: 1796355,
        INTPTLAT: '+42.7048799',
        INTPTLON: '-074.2690664',
        ELSDLEA: '',
        UNSDLEA: '26100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9209, 42.8758],
            [-73.8762, 42.8772],
            [-73.8094, 42.7789],
            [-73.8236, 42.7569],
            [-73.893, 42.7827],
            [-73.9211, 42.8406],
            [-73.9209, 42.8758],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620880',
        NAME: 'Niskayuna Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50428657,
        AWATER: 3279430,
        INTPTLAT: '+42.8191298',
        INTPTLON: '-073.8754254',
        ELSDLEA: '',
        UNSDLEA: '20880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9832, 42.8017],
            [-73.9068, 42.7634],
            [-73.8743, 42.7207],
            [-73.8778, 42.7194],
            [-73.967, 42.7567],
            [-73.9832, 42.8017],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600004',
        NAME: 'Rotterdam-Mohonasen Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28168214,
        AWATER: 16204,
        INTPTLAT: '+42.7632600',
        INTPTLON: '-073.9357955',
        ELSDLEA: '',
        UNSDLEA: '00004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0475, 42.948],
            [-74.0085, 42.9472],
            [-73.9209, 42.8758],
            [-73.9211, 42.8406],
            [-73.9488, 42.8199],
            [-74.0848, 42.8969],
            [-74.0475, 42.948],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626310',
        NAME: 'Scotia-Glenville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91069742,
        AWATER: 3139861,
        INTPTLAT: '+42.8802595',
        INTPTLON: '-074.0082016',
        ELSDLEA: '',
        UNSDLEA: '26310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8801, 43.2414],
            [-74.8167, 43.2928],
            [-74.7053, 43.2864],
            [-74.5347, 43.2281],
            [-74.5477, 43.1012],
            [-74.7702, 43.1125],
            [-74.7674, 43.0552],
            [-74.8492, 43.0928],
            [-74.8801, 43.2414],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600001',
        NAME: 'Dolgeville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473227310,
        AWATER: 6414018,
        INTPTLAT: '+43.1897340',
        INTPTLON: '-074.7159629',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1601, 43.3715],
            [-74.048, 43.3635],
            [-73.9854, 43.2558],
            [-73.9788, 43.1789],
            [-73.9775, 43.1669],
            [-74.0432, 43.1609],
            [-74.1243, 43.1539],
            [-74.1581, 43.3599],
            [-74.1601, 43.3715],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610230',
        NAME: 'Edinburg Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227250956,
        AWATER: 20957755,
        INTPTLAT: '+43.2576075',
        INTPTLON: '-074.0849380',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1225, 44.6732],
            [-75.0981, 44.7036],
            [-74.9173, 44.7578],
            [-74.8488, 44.7207],
            [-74.9585, 44.5884],
            [-75.0372, 44.5716],
            [-75.1225, 44.6732],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623670',
        NAME: 'Potsdam Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243674869,
        AWATER: 3422353,
        INTPTLAT: '+44.6657309',
        INTPTLON: '-074.9920116',
        ELSDLEA: '',
        UNSDLEA: '23670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7926, 41.1435],
            [-73.7665, 41.1423],
            [-73.7665, 41.1315],
            [-73.8065, 41.1247],
            [-73.811, 41.126],
            [-73.7926, 41.1435],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623310',
        NAME: 'Pleasantville Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6314474,
        AWATER: 26919,
        INTPTLAT: '+41.1377774',
        INTPTLON: '-073.7850239',
        ELSDLEA: '',
        UNSDLEA: '23310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6937, 41.0126],
            [-73.6667, 41.0255],
            [-73.6576, 40.9855],
            [-73.6849, 40.9977],
            [-73.6937, 41.0126],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623460',
        NAME: 'Port Chester-Rye Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7801916,
        AWATER: 237769,
        INTPTLAT: '+41.0055086',
        INTPTLON: '-073.6727310',
        ELSDLEA: '',
        UNSDLEA: '23460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6849, 40.9977],
            [-73.6576, 40.9855],
            [-73.6129, 40.9509],
            [-73.6131, 40.9508],
            [-73.6944, 40.9031],
            [-73.7097, 40.8942],
            [-73.7105, 40.9245],
            [-73.7122, 40.9629],
            [-73.6849, 40.9977],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625260',
        NAME: 'Rye City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13241950,
        AWATER: 35215765,
        INTPTLAT: '+40.9446713',
        INTPTLON: '-073.6863755',
        ELSDLEA: '',
        UNSDLEA: '25260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3664, 43.1009],
            [-76.2654, 43.1082],
            [-76.2314, 43.0556],
            [-76.2075, 42.9996],
            [-76.2454, 43.0073],
            [-76.3498, 43.0239],
            [-76.3664, 43.1009],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630630',
        NAME: 'West Genesee Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103529091,
        AWATER: 48591,
        INTPTLAT: '+43.0692313',
        INTPTLON: '-076.2934631',
        ELSDLEA: '',
        UNSDLEA: '30630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8053, 42.1789],
            [-75.7472, 42.2492],
            [-75.6464, 42.2996],
            [-75.5171, 42.1219],
            [-75.5353, 42.1149],
            [-75.6093, 42.1363],
            [-75.809, 42.1378],
            [-75.8053, 42.1789],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613710',
        NAME: 'Harpursville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236940101,
        AWATER: 1938267,
        INTPTLAT: '+42.1918865',
        INTPTLON: '-075.6664955',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6251, 40.7546],
            [-73.6024, 40.763],
            [-73.593, 40.7472],
            [-73.6171, 40.743],
            [-73.6237, 40.7418],
            [-73.6251, 40.7546],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606540',
        NAME: 'Carle Place Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4127848,
        AWATER: 0,
        INTPTLAT: '+40.7518171',
        INTPTLON: '-073.6103318',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5496, 40.7567],
            [-73.5355, 40.7433],
            [-73.5319, 40.7064],
            [-73.5611, 40.6987],
            [-73.5767, 40.6943],
            [-73.5875, 40.7468],
            [-73.5496, 40.7567],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609840',
        NAME: 'East Meadow Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21894527,
        AWATER: 123790,
        INTPTLAT: '+40.7255500',
        INTPTLON: '-073.5596817',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6694, 40.6038],
            [-73.6451, 40.6177],
            [-73.6363, 40.5997],
            [-73.6694, 40.6038],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615480',
        NAME: 'Island Park Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3878353,
        AWATER: 924444,
        INTPTLAT: '+40.6053572',
        INTPTLON: '-073.6490463',
        ELSDLEA: '',
        UNSDLEA: '15480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7285, 40.651],
            [-73.705, 40.6143],
            [-73.7011, 40.5907],
            [-73.7061, 40.5345],
            [-73.7688, 40.5337],
            [-73.7285, 40.651],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616830',
        NAME: 'Lawrence Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22030423,
        AWATER: 31177290,
        INTPTLAT: '+40.5860567',
        INTPTLON: '-073.7250967',
        ELSDLEA: '',
        UNSDLEA: '16830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7011, 40.5907],
            [-73.5741, 40.5959],
            [-73.5749, 40.5306],
            [-73.7061, 40.5345],
            [-73.7011, 40.5907],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617730',
        NAME: 'Long Beach City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11096770,
        AWATER: 64442961,
        INTPTLAT: '+40.5834101',
        INTPTLON: '-073.6423143',
        ELSDLEA: '',
        UNSDLEA: '17730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6599, 40.7426],
            [-73.6498, 40.78],
            [-73.643, 40.7799],
            [-73.6251, 40.7546],
            [-73.6237, 40.7418],
            [-73.6609, 40.7357],
            [-73.6599, 40.7426],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619500',
        NAME: 'Mineola Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6433280,
        AWATER: 17844,
        INTPTLAT: '+40.7514651',
        INTPTLON: '-073.6440825',
        ELSDLEA: '',
        UNSDLEA: '19500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6787, 40.6278],
            [-73.6763, 40.629],
            [-73.6556, 40.6494],
            [-73.6216, 40.6617],
            [-73.5861, 40.6256],
            [-73.5741, 40.5959],
            [-73.7011, 40.5907],
            [-73.705, 40.6143],
            [-73.6787, 40.6278],
          ],
          [
            [-73.6694, 40.6038],
            [-73.6363, 40.5997],
            [-73.6451, 40.6177],
            [-73.6694, 40.6038],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621570',
        NAME: 'Oceanside Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23942208,
        AWATER: 15652392,
        INTPTLAT: '+40.6186485',
        INTPTLON: '-073.6360138',
        ELSDLEA: '',
        UNSDLEA: '21570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1488, 43.0293],
            [-78.1155, 43.1315],
            [-78.1111, 43.1315],
            [-78.0178, 43.1431],
            [-77.9093, 43.1269],
            [-77.9086, 43.0448],
            [-77.9422, 43.0271],
            [-78.1133, 43.0069],
            [-78.1488, 43.0293],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606090',
        NAME: 'Byron-Bergen Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197486133,
        AWATER: 505729,
        INTPTLAT: '+43.0751552',
        INTPTLON: '-078.0195319',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4643, 42.9655],
            [-78.4126, 43.0312],
            [-78.2309, 43.0182],
            [-78.2502, 42.9677],
            [-78.4158, 42.9119],
            [-78.4643, 42.9655],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608340',
        NAME: 'Pembroke Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180884540,
        AWATER: 523000,
        INTPTLAT: '+42.9763537',
        INTPTLON: '-078.3542463',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4158, 42.9119],
            [-78.2502, 42.9677],
            [-78.1434, 42.9574],
            [-78.1167, 42.9163],
            [-78.1788, 42.8703],
            [-78.4181, 42.8875],
            [-78.4158, 42.9119],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602580',
        NAME: 'Alexander Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206688137,
        AWATER: 770648,
        INTPTLAT: '+42.9205858',
        INTPTLON: '-078.2500655',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2981, 43.2627],
            [-78.2099, 43.3717],
            [-78.1702, 43.3724],
            [-78.0911, 43.2757],
            [-78.1111, 43.1315],
            [-78.1155, 43.1315],
            [-78.234, 43.1251],
            [-78.304, 43.1423],
            [-78.2981, 43.2627],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602520',
        NAME: 'Albion Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335404054,
        AWATER: 1901762,
        INTPTLAT: '+43.2373647',
        INTPTLON: '-078.2055367',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.619, 43.5206],
            [-73.5841, 43.573],
            [-73.3734, 43.4712],
            [-73.3535, 43.4213],
            [-73.4692, 43.3766],
            [-73.6115, 43.3935],
            [-73.6117, 43.3944],
            [-73.619, 43.5206],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611280',
        NAME: 'Fort Ann Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271199139,
        AWATER: 4725636,
        INTPTLAT: '+43.4638977',
        INTPTLON: '-073.5313656',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5899, 43.0375],
            [-73.5468, 43.1726],
            [-73.3954, 43.2236],
            [-73.3906, 43.1102],
            [-73.5048, 42.9646],
            [-73.5208, 42.9567],
            [-73.5955, 42.9432],
            [-73.5899, 43.0375],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612900',
        NAME: 'Greenwich Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255865397,
        AWATER: 1367900,
        INTPTLAT: '+43.0787456',
        INTPTLON: '-073.5010879',
        ELSDLEA: '',
        UNSDLEA: '12900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5253, 43.6361],
            [-73.4839, 43.6906],
            [-73.3936, 43.6995],
            [-73.4074, 43.6734],
            [-73.3021, 43.6238],
            [-73.2898, 43.5778],
            [-73.3015, 43.4852],
            [-73.3734, 43.4712],
            [-73.5841, 43.573],
            [-73.5253, 43.6361],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631290',
        NAME: 'Whitehall Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311680881,
        AWATER: 12073451,
        INTPTLAT: '+43.5716368',
        INTPTLON: '-073.4156304',
        ELSDLEA: '',
        UNSDLEA: '31290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6004, 43.2721],
            [-73.5739, 43.2792],
            [-73.5573, 43.2627],
            [-73.5871, 43.2588],
            [-73.6004, 43.2721],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611310',
        NAME: 'Fort Edward Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7562150,
        AWATER: 465851,
        INTPTLAT: '+43.2732202',
        INTPTLON: '-073.5733778',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8284, 43.8816],
            [-74.7362, 43.8657],
            [-74.6385, 43.9173],
            [-74.5596, 43.8418],
            [-74.6683, 43.7309],
            [-74.8201, 43.8185],
            [-74.8284, 43.8816],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624120',
        NAME: 'Raquette Lake Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203350323,
        AWATER: 27700071,
        INTPTLAT: '+43.8133636',
        INTPTLON: '-074.7237253',
        ELSDLEA: '',
        UNSDLEA: '24120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.529, 42.6001],
            [-74.5225, 42.5952],
            [-74.4526, 42.5832],
            [-74.3616, 42.6597],
            [-74.2107, 42.6173],
            [-74.1866, 42.5392],
            [-74.185, 42.4446],
            [-74.1982, 42.4145],
            [-74.2783, 42.4145],
            [-74.3093, 42.4819],
            [-74.4959, 42.5293],
            [-74.529, 42.6001],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619260',
        NAME: 'Middleburgh Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446386379,
        AWATER: 1420509,
        INTPTLAT: '+42.5420198',
        INTPTLON: '-074.3261453',
        ELSDLEA: '',
        UNSDLEA: '19260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4781, 41.5504],
            [-74.4538, 41.578],
            [-74.3036, 41.6946],
            [-74.2402, 41.6027],
            [-74.3329, 41.539],
            [-74.3413, 41.4847],
            [-74.3389, 41.4832],
            [-74.3379, 41.4807],
            [-74.3395, 41.4778],
            [-74.3401, 41.4772],
            [-74.3665, 41.4615],
            [-74.4337, 41.4787],
            [-74.495, 41.5335],
            [-74.4781, 41.5504],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623010',
        NAME: 'Pine Bush Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278862137,
        AWATER: 1078933,
        INTPTLAT: '+41.5850090',
        INTPTLON: '-074.3496409',
        ELSDLEA: '',
        UNSDLEA: '23010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4402, 41.3221],
            [-74.3315, 41.3384],
            [-74.2688, 41.3317],
            [-74.2569, 41.2776],
            [-74.333, 41.1879],
            [-74.3668, 41.2033],
            [-74.5367, 41.2847],
            [-74.5368, 41.2847],
            [-74.4402, 41.3221],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629970',
        NAME: 'Warwick Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196556454,
        AWATER: 1548076,
        INTPTLAT: '+41.2762758',
        INTPTLON: '-074.3732892',
        ELSDLEA: '',
        UNSDLEA: '29970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8162, 41.166],
            [-73.7926, 41.1435],
            [-73.811, 41.126],
            [-73.8448, 41.1224],
            [-73.8589, 41.1243],
            [-73.8162, 41.166],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605340',
        NAME: 'Briarcliff Manor Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17031299,
        AWATER: 116333,
        INTPTLAT: '+41.1416162',
        INTPTLON: '-073.8273722',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7433, 42.6794],
            [-73.721, 42.7087],
            [-73.7025, 42.7],
            [-73.7065, 42.6941],
            [-73.7233, 42.6729],
            [-73.7433, 42.6794],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618990',
        NAME: 'Menands Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7130939,
        AWATER: 655256,
        INTPTLAT: '+42.6920018',
        INTPTLON: '-073.7240895',
        ELSDLEA: '',
        UNSDLEA: '18990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9762, 44.1407],
            [-73.8669, 44.15],
            [-73.8915, 44.2982],
            [-73.8129, 44.3052],
            [-73.7127, 44.2515],
            [-73.6876, 44.0946],
            [-73.964, 44.067],
            [-73.9762, 44.1407],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616140',
        NAME: 'Keene Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404043786,
        AWATER: 1751056,
        INTPTLAT: '+44.1227720',
        INTPTLON: '-073.8835000',
        ELSDLEA: '',
        UNSDLEA: '16140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9079, 44.4198],
            [-73.8321, 44.4082],
            [-73.8129, 44.3052],
            [-73.8915, 44.2982],
            [-73.8669, 44.15],
            [-73.9762, 44.1407],
            [-74.0935, 44.1376],
            [-74.1158, 44.2613],
            [-74.0116, 44.2707],
            [-74.0264, 44.3378],
            [-73.9079, 44.4198],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616500',
        NAME: 'Lake Placid Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 451467479,
        AWATER: 10879086,
        INTPTLAT: '+44.2643469',
        INTPTLON: '-073.9465825',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3954, 43.2236],
            [-73.4039, 43.2697],
            [-73.3941, 43.2797],
            [-73.3951, 43.2816],
            [-73.3407, 43.2851],
            [-73.2557, 43.2791],
            [-73.2592, 43.2168],
            [-73.2616, 43.1695],
            [-73.33, 43.0737],
            [-73.3906, 43.1102],
            [-73.3954, 43.2236],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625470',
        NAME: 'Salem Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210947588,
        AWATER: 703014,
        INTPTLAT: '+43.1957531',
        INTPTLON: '-073.3356686',
        ELSDLEA: '',
        UNSDLEA: '25470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6638, 42.4349],
            [-76.5777, 42.5456],
            [-76.4506, 42.479],
            [-76.2756, 42.3989],
            [-76.299, 42.3154],
            [-76.4155, 42.3056],
            [-76.5054, 42.3082],
            [-76.5362, 42.3939],
            [-76.684, 42.3905],
            [-76.6638, 42.4349],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615570',
        NAME: 'Ithaca City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402764737,
        AWATER: 17331366,
        INTPTLAT: '+42.4080496',
        INTPTLON: '-076.4742559',
        ELSDLEA: '',
        UNSDLEA: '15570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3115, 42.154],
            [-76.2627, 42.1852],
            [-76.1646, 42.1418],
            [-76.1303, 42.0631],
            [-76.1058, 41.9989],
            [-76.1455, 41.9988],
            [-76.295, 41.9984],
            [-76.3427, 42.0498],
            [-76.3115, 42.154],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622170',
        NAME: 'Owego-Apalachin Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248688619,
        AWATER: 4592739,
        INTPTLAT: '+42.0719805',
        INTPTLON: '-076.2193625',
        ELSDLEA: '',
        UNSDLEA: '22170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4419, 42.5389],
            [-76.3285, 42.549],
            [-76.2746, 42.5955],
            [-76.2302, 42.5825],
            [-76.2591, 42.5028],
            [-76.1957, 42.4708],
            [-76.2232, 42.4083],
            [-76.2756, 42.3989],
            [-76.4506, 42.479],
            [-76.4419, 42.5389],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609330',
        NAME: 'Dryden Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258009643,
        AWATER: 1749547,
        INTPTLAT: '+42.4863192',
        INTPTLON: '-076.3198439',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.823, 42.2462],
            [-73.781, 42.3018],
            [-73.6963, 42.2905],
            [-73.7424, 42.1898],
            [-73.6823, 42.1212],
            [-73.7913, 42.1242],
            [-73.8051, 42.126],
            [-73.855, 42.192],
            [-73.823, 42.2462],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614940',
        NAME: 'Hudson City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166777669,
        AWATER: 7139414,
        INTPTLAT: '+42.2030113',
        INTPTLON: '-073.7591397',
        ELSDLEA: '',
        UNSDLEA: '14940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6963, 42.2905],
            [-73.6906, 42.2921],
            [-73.4316, 42.2953],
            [-73.5081, 42.0863],
            [-73.4873, 42.0496],
            [-73.4883, 42.03],
            [-73.5563, 41.9978],
            [-73.6599, 42.0535],
            [-73.6823, 42.1212],
            [-73.7424, 42.1898],
            [-73.6963, 42.2905],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628620',
        NAME: 'Taconic Hills Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514820360,
        AWATER: 5703713,
        INTPTLAT: '+42.1775634',
        INTPTLON: '-073.5851493',
        ELSDLEA: '',
        UNSDLEA: '28620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.602, 42.4339],
            [-73.5201, 42.4608],
            [-73.4146, 42.4184],
            [-73.4146, 42.4122],
            [-73.4121, 42.3482],
            [-73.4313, 42.2961],
            [-73.4316, 42.2953],
            [-73.6906, 42.2921],
            [-73.602, 42.4339],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607110',
        NAME: 'Chatham Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317613394,
        AWATER: 954817,
        INTPTLAT: '+42.3639447',
        INTPTLON: '-073.5458831',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9296, 42.0788],
            [-73.8051, 42.126],
            [-73.7913, 42.1242],
            [-73.8114, 42.0764],
            [-73.7668, 42.006],
            [-73.7855, 41.9385],
            [-73.9404, 41.9943],
            [-73.9296, 42.0788],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624240',
        NAME: 'Red Hook Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159654999,
        AWATER: 9824626,
        INTPTLAT: '+42.0163561',
        INTPTLON: '-073.8371801',
        ELSDLEA: '',
        UNSDLEA: '24240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6322, 43.6553],
            [-75.4485, 43.6891],
            [-75.4357, 43.7542],
            [-75.3244, 43.7945],
            [-75.3089, 43.8037],
            [-75.2331, 43.8204],
            [-75.1404, 43.8607],
            [-75.1102, 43.6152],
            [-75.1826, 43.5818],
            [-75.3402, 43.5098],
            [-75.6085, 43.5444],
            [-75.6322, 43.6553],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627360',
        NAME: 'South Lewis Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 945004196,
        AWATER: 12110824,
        INTPTLAT: '+43.6528665',
        INTPTLON: '-075.3445808',
        ELSDLEA: '',
        UNSDLEA: '27360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.4146, 42.4184],
              [-73.3886, 42.4116],
              [-73.3915, 42.4038],
              [-73.4146, 42.4122],
              [-73.4146, 42.4184],
            ],
          ],
          [
            [
              [-73.5563, 41.9978],
              [-73.4883, 42.03],
              [-73.4966, 41.9217],
              [-73.5079, 41.7915],
              [-73.5116, 41.7479],
              [-73.6136, 41.7904],
              [-73.6678, 41.8479],
              [-73.6658, 41.8484],
              [-73.6656, 41.8489],
              [-73.5349, 41.968],
              [-73.5563, 41.9978],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630390',
        NAME: 'Northeast Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215746522,
        AWATER: 2918992,
        INTPTLAT: '+41.8863622',
        INTPTLON: '-073.5412997',
        ELSDLEA: '',
        UNSDLEA: '30390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7303, 40.9638],
            [-73.7122, 40.9629],
            [-73.7105, 40.9245],
            [-73.7303, 40.9638],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625290',
        NAME: 'Rye Neck Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4911555,
        AWATER: 2345178,
        INTPTLAT: '+40.9492360',
        INTPTLON: '-073.7163841',
        ELSDLEA: '',
        UNSDLEA: '25290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8876, 41.1288],
            [-73.8589, 41.1243],
            [-73.8448, 41.1224],
            [-73.8345, 41.0664],
            [-73.838, 41.064],
            [-73.8945, 41.0646],
            [-73.8948, 41.0705],
            [-73.8876, 41.1288],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628650',
        NAME: 'Union Free School District of the Tarrytowns',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12274053,
        AWATER: 14040406,
        INTPTLAT: '+41.0923668',
        INTPTLON: '-073.8676395',
        ELSDLEA: '',
        UNSDLEA: '28650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2402, 41.6027],
            [-74.1101, 41.5488],
            [-74.116, 41.5058],
            [-74.2202, 41.4645],
            [-74.3379, 41.4807],
            [-74.3389, 41.4832],
            [-74.3413, 41.4847],
            [-74.3329, 41.539],
            [-74.2402, 41.6027],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619680',
        NAME: 'Valley Central School District (Montgomery)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203982383,
        AWATER: 3630875,
        INTPTLAT: '+41.5210806',
        INTPTLON: '-074.2141839',
        ELSDLEA: '',
        UNSDLEA: '19680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1779, 41.3499],
            [-74.1594, 41.3459],
            [-74.1588, 41.3316],
            [-74.178, 41.3401],
            [-74.1779, 41.3499],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604758',
        NAME: 'Kiryas Joel Village Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2871254,
        AWATER: 14564,
        INTPTLAT: '+41.3406673',
        INTPTLON: '-074.1672741',
        ELSDLEA: '',
        UNSDLEA: '04758',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3331, 41.34],
            [-74.2518, 41.3909],
            [-74.2396, 41.3694],
            [-74.2688, 41.3317],
            [-74.3315, 41.3384],
            [-74.3331, 41.34],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607380',
        NAME: 'Chester Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28167694,
        AWATER: 62885,
        INTPTLAT: '+41.3627073',
        INTPTLON: '-074.2780785',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0016, 41.5075],
            [-74.8954, 41.6139],
            [-74.7838, 41.6041],
            [-74.7524, 41.494],
            [-74.7369, 41.4294],
            [-74.8902, 41.4382],
            [-74.966, 41.4768],
            [-75.0016, 41.5075],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610410',
        NAME: 'Eldred Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261387025,
        AWATER: 12317455,
        INTPTLAT: '+41.5119280',
        INTPTLON: '-074.8539646',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.333, 41.1879],
            [-74.2569, 41.2776],
            [-74.2476, 41.2294],
            [-74.2802, 41.1631],
            [-74.333, 41.1879],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612960',
        NAME: 'Greenwood Lake Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30852262,
        AWATER: 4445932,
        INTPTLAT: '+41.2152570',
        INTPTLON: '-074.2893963',
        ELSDLEA: '',
        UNSDLEA: '12960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2396, 41.3694],
            [-74.1329, 41.3795],
            [-74.0962, 41.3494],
            [-74.0607, 41.3269],
            [-74.114, 41.2432],
            [-74.151, 41.2035],
            [-74.2476, 41.2294],
            [-74.2569, 41.2776],
            [-74.2688, 41.3317],
            [-74.2396, 41.3694],
          ],
          [
            [-74.1779, 41.3499],
            [-74.178, 41.3401],
            [-74.1588, 41.3316],
            [-74.1594, 41.3459],
            [-74.1779, 41.3499],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619650',
        NAME: 'Monroe-Woodbury Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212164501,
        AWATER: 8357112,
        INTPTLAT: '+41.2986340',
        INTPTLON: '-074.1761130',
        ELSDLEA: '',
        UNSDLEA: '19650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.438, 41.3547],
            [-74.3331, 41.34],
            [-74.3315, 41.3384],
            [-74.4402, 41.3221],
            [-74.438, 41.3547],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625320',
        NAME: 'Florida Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53260524,
        AWATER: 1692932,
        INTPTLAT: '+41.3217291',
        INTPTLON: '-074.3869679',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.894, 40.9972],
            [-73.8936, 41.0053],
            [-73.859, 40.998],
            [-73.8548, 40.982],
            [-73.8988, 40.9748],
            [-73.894, 40.9972],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613950',
        NAME: 'Hastings-on-Hudson Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6337518,
        AWATER: 2490222,
        INTPTLAT: '+40.9892169',
        INTPTLON: '-073.8755783',
        ELSDLEA: '',
        UNSDLEA: '13950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.675, 41.3088],
            [-73.5499, 41.3015],
            [-73.5025, 41.2372],
            [-73.4849, 41.2117],
            [-73.5128, 41.1994],
            [-73.7298, 41.2504],
            [-73.675, 41.3088],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616080',
        NAME: 'Katonah-Lewisboro Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112291184,
        AWATER: 8396230,
        INTPTLAT: '+41.2661426',
        INTPTLON: '-073.5882959',
        ELSDLEA: '',
        UNSDLEA: '16080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8527, 40.9099],
            [-73.8346, 40.9353],
            [-73.8198, 40.9311],
            [-73.8054, 40.9268],
            [-73.805, 40.9261],
            [-73.8232, 40.8912],
            [-73.8527, 40.9099],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620100',
        NAME: 'Mount Vernon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11358864,
        AWATER: 44579,
        INTPTLAT: '+40.9131051',
        INTPTLON: '-073.8293172',
        ELSDLEA: '',
        UNSDLEA: '20100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9093, 41.1644],
            [-73.8206, 41.2163],
            [-73.7933, 41.2078],
            [-73.8162, 41.166],
            [-73.8589, 41.1243],
            [-73.8876, 41.1288],
            [-73.8874, 41.1369],
            [-73.9093, 41.1644],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622020',
        NAME: 'Ossining Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33758781,
        AWATER: 10936985,
        INTPTLAT: '+41.1694263',
        INTPTLON: '-073.8534747',
        ELSDLEA: '',
        UNSDLEA: '22020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8448, 41.1224],
            [-73.811, 41.126],
            [-73.8065, 41.1247],
            [-73.7978, 41.0867],
            [-73.8019, 41.0612],
            [-73.8345, 41.0664],
            [-73.8448, 41.1224],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623340',
        NAME: 'Pocantico Hills Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21747630,
        AWATER: 422588,
        INTPTLAT: '+41.0977567',
        INTPTLON: '-073.8232061',
        ELSDLEA: '',
        UNSDLEA: '23340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7723, 41.0543],
            [-73.7443, 41.0521],
            [-73.7447, 40.9824],
            [-73.7853, 41.0215],
            [-73.7723, 41.0543],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631260',
        NAME: 'White Plains City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25298441,
        AWATER: 303058,
        INTPTLAT: '+41.0238010',
        INTPTLON: '-073.7510508',
        ELSDLEA: '',
        UNSDLEA: '31260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8349, 41.0339],
            [-73.8048, 41.0571],
            [-73.7723, 41.0543],
            [-73.7853, 41.0215],
            [-73.7966, 41.0097],
            [-73.8279, 41.0105],
            [-73.8349, 41.0339],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612720',
        NAME: 'Greenburgh Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15966259,
        AWATER: 41587,
        INTPTLAT: '+41.0326146',
        INTPTLON: '-073.8063718',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2078, 43.0645],
            [-75.1796, 43.0947],
            [-75.0721, 43.1024],
            [-75.0316, 43.0955],
            [-75.0416, 43.0525],
            [-75.0853, 43],
            [-75.1662, 43.0129],
            [-75.1928, 43.0482],
            [-75.2078, 43.0645],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611400',
        NAME: 'Frankfort-Schuyler Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107266377,
        AWATER: 700283,
        INTPTLAT: '+43.0616416',
        INTPTLON: '-075.1285696',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8492, 43.0928],
            [-74.7674, 43.0552],
            [-74.743, 42.9918],
            [-74.7758, 42.9623],
            [-74.8899, 42.9891],
            [-74.942, 43.022],
            [-74.9548, 43.0765],
            [-74.8492, 43.0928],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617460',
        NAME: 'Little Falls City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139456615,
        AWATER: 1813607,
        INTPTLAT: '+43.0262398',
        INTPTLON: '-074.8384219',
        ELSDLEA: '',
        UNSDLEA: '17460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0416, 43.0525],
            [-75.0316, 43.0955],
            [-74.9548, 43.0765],
            [-74.942, 43.022],
            [-75.0416, 43.0525],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614220',
        NAME: 'Herkimer Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64108054,
        AWATER: 990545,
        INTPTLAT: '+43.0476382',
        INTPTLON: '-074.9616873',
        ELSDLEA: '',
        UNSDLEA: '14220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0606, 42.9197],
            [-74.99, 42.9388],
            [-74.8857, 42.8635],
            [-74.9423, 42.8017],
            [-75.0437, 42.7445],
            [-75.1213, 42.8045],
            [-75.0606, 42.9197],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624570',
        NAME: 'Richfield Springs Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226440892,
        AWATER: 9121174,
        INTPTLAT: '+42.8378975',
        INTPTLON: '-075.0167793',
        ELSDLEA: '',
        UNSDLEA: '24570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1177, 43.4683],
            [-76.0255, 43.561],
            [-75.8908, 43.5517],
            [-75.8146, 43.4836],
            [-75.8868, 43.325],
            [-76.1573, 43.3975],
            [-76.1177, 43.4683],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602820',
        NAME: 'Altmar-Parish-Williamstown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440878477,
        AWATER: 7844247,
        INTPTLAT: '+43.4408499',
        INTPTLON: '-075.9817493',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6265, 43.4124],
            [-76.4013, 43.5313],
            [-76.3621, 43.421],
            [-76.5111, 43.3768],
            [-76.6265, 43.4124],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622050',
        NAME: 'Oswego City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160159875,
        AWATER: 17872193,
        INTPTLAT: '+43.4426201',
        INTPTLON: '-076.4801326',
        ELSDLEA: '',
        UNSDLEA: '22050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4013, 43.5313],
            [-76.2263, 43.5451],
            [-76.1177, 43.4683],
            [-76.1573, 43.3975],
            [-76.2488, 43.337],
            [-76.3224, 43.3545],
            [-76.3621, 43.421],
            [-76.4013, 43.5313],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619170',
        NAME: 'Mexico Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325530070,
        AWATER: 11864099,
        INTPTLAT: '+43.4507131',
        INTPTLON: '-076.2676700',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.2672, 43.1124],
              [-75.2671, 43.1027],
              [-75.271, 43.0986],
              [-75.2739, 43.1015],
              [-75.2672, 43.1124],
            ],
          ],
          [
            [
              [-75.3579, 43.1215],
              [-75.3029, 43.1792],
              [-75.155, 43.2058],
              [-75.1242, 43.1703],
              [-75.0721, 43.1024],
              [-75.1796, 43.0947],
              [-75.268, 43.1126],
              [-75.3094, 43.0989],
              [-75.3391, 43.0962],
              [-75.3579, 43.1215],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631320',
        NAME: 'Whitesboro Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164729463,
        AWATER: 1207382,
        INTPTLAT: '+43.1420739',
        INTPTLON: '-075.2116316',
        ELSDLEA: '',
        UNSDLEA: '31320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1981, 43.6142],
            [-76.0255, 43.561],
            [-76.1177, 43.4683],
            [-76.2263, 43.5451],
            [-76.1981, 43.6142],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623880',
        NAME: 'Pulaski Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162245364,
        AWATER: 6118293,
        INTPTLAT: '+43.5564058',
        INTPTLON: '-076.1291983',
        ELSDLEA: '',
        UNSDLEA: '23880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7635, 43.2254],
            [-73.7817, 43.184],
            [-73.9788, 43.1789],
            [-73.9854, 43.2558],
            [-73.8261, 43.2761],
            [-73.7635, 43.2254],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608370',
        NAME: 'Corinth Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185443717,
        AWATER: 3739485,
        INTPTLAT: '+43.2285923',
        INTPTLON: '-073.9222021',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8762, 42.8772],
            [-73.8404, 42.9541],
            [-73.7577, 42.9444],
            [-73.7296, 42.9226],
            [-73.6851, 42.8419],
            [-73.7174, 42.8094],
            [-73.8094, 42.7789],
            [-73.8762, 42.8772],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626670',
        NAME: 'Shenendehowa Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188193518,
        AWATER: 7767081,
        INTPTLAT: '+42.8661496',
        INTPTLON: '-073.7860608',
        ELSDLEA: '',
        UNSDLEA: '26670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7245, 43.0408],
            [-73.7108, 43.1537],
            [-73.5814, 43.2158],
            [-73.5822, 43.189],
            [-73.5468, 43.1726],
            [-73.5899, 43.0375],
            [-73.7255, 43.0384],
            [-73.7245, 43.0408],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626160',
        NAME: 'Schuylerville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195623854,
        AWATER: 5918191,
        INTPTLAT: '+43.0988788',
        INTPTLON: '-073.6251295',
        ELSDLEA: '',
        UNSDLEA: '26160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7635, 43.2254],
            [-73.7404, 43.2501],
            [-73.6659, 43.2909],
            [-73.6386, 43.3064],
            [-73.617, 43.3053],
            [-73.5995, 43.3067],
            [-73.6004, 43.2721],
            [-73.5871, 43.2588],
            [-73.5814, 43.2158],
            [-73.7108, 43.1537],
            [-73.7817, 43.184],
            [-73.7635, 43.2254],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627240',
        NAME: 'South Glens Falls Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167779631,
        AWATER: 3897013,
        INTPTLAT: '+43.2235073',
        INTPTLON: '-073.6705762',
        ELSDLEA: '',
        UNSDLEA: '27240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6864, 42.754],
            [-73.6844, 42.7608],
            [-73.6768, 42.7833],
            [-73.6612, 42.8219],
            [-73.6401, 42.8252],
            [-73.6075, 42.8184],
            [-73.6252, 42.7644],
            [-73.6455, 42.7526],
            [-73.6864, 42.754],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616740',
        NAME: 'Lansingburgh Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34370092,
        AWATER: 1654416,
        INTPTLAT: '+42.7914132',
        INTPTLON: '-073.6387213',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3578, 42.2419],
            [-77.2265, 42.3448],
            [-77.1806, 42.3573],
            [-77.1093, 42.2813],
            [-77.1919, 42.1585],
            [-77.3578, 42.2419],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600020',
        NAME: 'Campbell-Savona Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246068819,
        AWATER: 1154450,
        INTPTLAT: '+42.2527458',
        INTPTLON: '-077.2395950',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6925, 42.3676],
            [-77.5636, 42.3912],
            [-77.5364, 42.3457],
            [-77.6789, 42.3047],
            [-77.6925, 42.3676],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614820',
        NAME: 'Hornell City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92867338,
        AWATER: 63547,
        INTPTLAT: '+42.3569266',
        INTPTLON: '-077.6142115',
        ELSDLEA: '',
        UNSDLEA: '14820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6343, 42.0394],
            [-77.5953, 42.1579],
            [-77.4805, 42.2082],
            [-77.3877, 42.1184],
            [-77.4282, 42.0001],
            [-77.61, 41.9994],
            [-77.6357, 41.9994],
            [-77.6343, 42.0394],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600007',
        NAME: 'Jasper-Troupsburg Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352694318,
        AWATER: 158386,
        INTPTLAT: '+42.0898019',
        INTPTLON: '-077.4983654',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4307, 42.32],
            [-77.3576, 42.3997],
            [-77.2717, 42.4592],
            [-77.2265, 42.3448],
            [-77.3578, 42.2419],
            [-77.4466, 42.2494],
            [-77.4307, 42.32],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604050',
        NAME: 'Bath Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248630435,
        AWATER: 967481,
        INTPTLAT: '+42.3393843',
        INTPTLON: '-077.3499899',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5667, 42.4286],
            [-77.3922, 42.5386],
            [-77.3593, 42.533],
            [-77.3576, 42.3997],
            [-77.4307, 42.32],
            [-77.5364, 42.3457],
            [-77.5636, 42.3912],
            [-77.5667, 42.4286],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603630',
        NAME: 'Avoca Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240915263,
        AWATER: 484668,
        INTPTLAT: '+42.4243180',
        INTPTLON: '-077.4487545',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-74.5271, 42.5244],
              [-74.5252, 42.5267],
              [-74.5233, 42.5261],
              [-74.5285, 42.5225],
              [-74.5271, 42.5244],
            ],
          ],
          [
            [
              [-74.6396, 42.5488],
              [-74.523, 42.501],
              [-74.5114, 42.4969],
              [-74.5028, 42.5029],
              [-74.49, 42.4161],
              [-74.6641, 42.47],
              [-74.7634, 42.4448],
              [-74.7702, 42.4516],
              [-74.6396, 42.5488],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615720',
        NAME: 'Jefferson Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172326568,
        AWATER: 517333,
        INTPTLAT: '+42.4858518',
        INTPTLON: '-074.6022121',
        ELSDLEA: '',
        UNSDLEA: '15720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1485, 42.0717],
            [-74.9661, 42.1753],
            [-74.8875, 42.2002],
            [-74.8915, 42.1338],
            [-74.8336, 42.0391],
            [-75.1002, 41.9941],
            [-75.1155, 41.9606],
            [-75.1485, 42.0717],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609240',
        NAME: 'Downsville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355508691,
        AWATER: 14228786,
        INTPTLAT: '+42.0793601',
        INTPTLON: '-075.0118933',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.332, 42.2044],
            [-75.1836, 42.2615],
            [-75.0684, 42.2801],
            [-74.9661, 42.1753],
            [-75.1485, 42.0717],
            [-75.323, 42.0785],
            [-75.2898, 42.1691],
            [-75.332, 42.2044],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629820',
        NAME: 'Walton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422377799,
        AWATER: 16478678,
        INTPTLAT: '+42.1850218',
        INTPTLON: '-075.1645442',
        ELSDLEA: '',
        UNSDLEA: '29820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6635, 42.2857],
            [-74.5654, 42.3842],
            [-74.4971, 42.3969],
            [-74.4435, 42.355],
            [-74.4787, 42.2809],
            [-74.4861, 42.2709],
            [-74.5669, 42.1958],
            [-74.6753, 42.271],
            [-74.6635, 42.2857],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625110',
        NAME: 'Roxbury Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217773588,
        AWATER: 681806,
        INTPTLAT: '+42.2910874',
        INTPTLON: '-074.5614352',
        ELSDLEA: '',
        UNSDLEA: '25110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9827, 42.4163],
            [-74.8574, 42.3975],
            [-74.8549, 42.3935],
            [-74.8539, 42.3937],
            [-74.7598, 42.2773],
            [-74.6635, 42.2857],
            [-74.6753, 42.271],
            [-74.7313, 42.2233],
            [-74.7803, 42.2541],
            [-74.8875, 42.2002],
            [-74.9661, 42.1753],
            [-75.0684, 42.2801],
            [-75.1187, 42.3352],
            [-74.9827, 42.4163],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608910',
        NAME: 'Delhi Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496014641,
        AWATER: 2635481,
        INTPTLAT: '+42.3031809',
        INTPTLON: '-074.9188977',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6835, 42.7963],
            [-78.5789, 42.7233],
            [-78.6394, 42.6942],
            [-78.6969, 42.6958],
            [-78.6835, 42.7963],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609540',
        NAME: 'East Aurora Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82912729,
        AWATER: 75184,
        INTPTLAT: '+42.7363261',
        INTPTLON: '-078.6340401',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7617, 42.1553],
            [-79.7618, 42.1733],
            [-79.653, 42.0862],
            [-79.5276, 42.0854],
            [-79.528, 41.9986],
            [-79.7612, 41.9988],
            [-79.7617, 42.1553],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607890',
        NAME: 'Clymer Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213390374,
        AWATER: 1556993,
        INTPTLAT: '+42.0581694',
        INTPTLON: '-079.6518849',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5281, 42.3732],
            [-79.4221, 42.4418],
            [-79.368, 42.3614],
            [-79.4087, 42.3264],
            [-79.4843, 42.338],
            [-79.5281, 42.3732],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605610',
        NAME: 'Brocton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94140448,
        AWATER: 175856,
        INTPTLAT: '+42.3788971',
        INTPTLON: '-079.4376175',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1251, 42.2383],
            [-79.069, 42.2296],
            [-79.0756, 42.1073],
            [-79.1702, 42.0759],
            [-79.2515, 42.1188],
            [-79.2685, 42.1644],
            [-79.1251, 42.2383],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610920',
        NAME: 'Falconer Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191430226,
        AWATER: 600408,
        INTPTLAT: '+42.1537638',
        INTPTLON: '-079.1483080',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5118, 42.118],
            [-79.3829, 42.1157],
            [-79.3677, 42.1113],
            [-79.3817, 41.9987],
            [-79.511, 41.9985],
            [-79.528, 41.9986],
            [-79.5276, 42.0854],
            [-79.5118, 42.118],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622410',
        NAME: 'Panama Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155441568,
        AWATER: 415802,
        INTPTLAT: '+42.0495529',
        INTPTLON: '-079.4580993',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7621, 42.2311],
            [-79.6444, 42.2387],
            [-79.5887, 42.2394],
            [-79.5118, 42.118],
            [-79.5276, 42.0854],
            [-79.653, 42.0862],
            [-79.7618, 42.1733],
            [-79.7621, 42.2311],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626730',
        NAME: 'Sherman Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267700342,
        AWATER: 712419,
        INTPTLAT: '+42.1581259',
        INTPTLON: '-079.6279150',
        ELSDLEA: '',
        UNSDLEA: '26730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8024, 42.4349],
            [-74.7702, 42.4516],
            [-74.7634, 42.4448],
            [-74.5654, 42.3842],
            [-74.6635, 42.2857],
            [-74.7598, 42.2773],
            [-74.8539, 42.3937],
            [-74.8549, 42.3935],
            [-74.8574, 42.3975],
            [-74.8024, 42.4349],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627330',
        NAME: 'South Kortright Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259216668,
        AWATER: 541221,
        INTPTLAT: '+42.3678826',
        INTPTLON: '-074.7217607',
        ELSDLEA: '',
        UNSDLEA: '27330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5601, 40.7715],
            [-73.5175, 40.7846],
            [-73.503, 40.7828],
            [-73.5009, 40.7676],
            [-73.5098, 40.743],
            [-73.5158, 40.743],
            [-73.5355, 40.7433],
            [-73.5496, 40.7567],
            [-73.5601, 40.7715],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614340',
        NAME: 'Hicksville Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16833283,
        AWATER: 39048,
        INTPTLAT: '+40.7629282',
        INTPTLON: '-073.5255475',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5158, 40.743],
            [-73.5098, 40.743],
            [-73.4911, 40.7321],
            [-73.4854, 40.7087],
            [-73.5158, 40.743],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615510',
        NAME: 'Island Trees Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5381441,
        AWATER: 0,
        INTPTLAT: '+40.7293817',
        INTPTLON: '-073.5001240',
        ELSDLEA: '',
        UNSDLEA: '15510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.917, 43.0091],
            [-78.8514, 43.0119],
            [-78.8233, 42.9724],
            [-78.8238, 42.9581],
            [-78.9142, 42.9403],
            [-78.9324, 42.9559],
            [-78.917, 43.0091],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616230',
        NAME: 'Kenmore-Tonawanda Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44318744,
        AWATER: 3924643,
        INTPTLAT: '+42.9801800',
        INTPTLON: '-078.8825167',
        ELSDLEA: '',
        UNSDLEA: '16230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5281, 42.3732],
            [-79.4843, 42.338],
            [-79.5887, 42.2394],
            [-79.6444, 42.2387],
            [-79.6626, 42.3077],
            [-79.5281, 42.3732],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630990',
        NAME: 'Westfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108494238,
        AWATER: 100230,
        INTPTLAT: '+42.3024316',
        INTPTLON: '-079.5861175',
        ELSDLEA: '',
        UNSDLEA: '30990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5887, 42.2394],
            [-79.4843, 42.338],
            [-79.4087, 42.3264],
            [-79.3757, 42.2387],
            [-79.4398, 42.2255],
            [-79.3829, 42.1157],
            [-79.5118, 42.118],
            [-79.5887, 42.2394],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600018',
        NAME: 'Chautauqua Lake Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247429078,
        AWATER: 327468,
        INTPTLAT: '+42.1989193',
        INTPTLON: '-079.4857525',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7619, 42.2693],
            [-79.6626, 42.3077],
            [-79.6444, 42.2387],
            [-79.7621, 42.2311],
            [-79.7619, 42.2693],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624660',
        NAME: 'Ripley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77388798,
        AWATER: 54357,
        INTPTLAT: '+42.2555629',
        INTPTLON: '-079.6902401',
        ELSDLEA: '',
        UNSDLEA: '24660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1945, 44.246],
            [-75.1319, 44.3018],
            [-75.0487, 44.2547],
            [-74.9156, 44.2635],
            [-74.9232, 44.3263],
            [-74.8103, 44.3327],
            [-74.7786, 44.1414],
            [-74.7681, 44.0776],
            [-74.8542, 44.0701],
            [-75.0628, 44.0504],
            [-75.1702, 44.097],
            [-75.1945, 44.246],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607710',
        NAME: 'Clifton-Fine Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 780046861,
        AWATER: 48320373,
        INTPTLAT: '+44.1927086',
        INTPTLON: '-074.9487414',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5578, 44.6283],
            [-75.4303, 44.6903],
            [-75.318, 44.6341],
            [-75.3188, 44.5465],
            [-75.4285, 44.5037],
            [-75.5546, 44.4724],
            [-75.5578, 44.6283],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614310',
        NAME: 'Heuvelton Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285383148,
        AWATER: 8985515,
        INTPTLAT: '+44.5771385',
        INTPTLON: '-075.4368516',
        ELSDLEA: '',
        UNSDLEA: '14310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4441, 44.7427],
            [-75.306, 44.8241],
            [-75.2265, 44.6842],
            [-75.318, 44.6341],
            [-75.4303, 44.6903],
            [-75.4441, 44.7427],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617430',
        NAME: 'Lisbon Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222818077,
        AWATER: 14395251,
        INTPTLAT: '+44.7235124',
        INTPTLON: '-075.3311288',
        ELSDLEA: '',
        UNSDLEA: '17430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.58, 44.6492],
            [-75.5578, 44.6283],
            [-75.5546, 44.4724],
            [-75.6142, 44.4524],
            [-75.7381, 44.535],
            [-75.58, 44.6492],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619890',
        NAME: 'Morristown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180341708,
        AWATER: 24052087,
        INTPTLAT: '+44.5534663',
        INTPTLON: '-075.6045468',
        ELSDLEA: '',
        UNSDLEA: '19890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0981, 44.7036],
            [-75.049, 44.8417],
            [-74.9218, 44.8904],
            [-74.8595, 44.8345],
            [-74.9173, 44.7578],
            [-75.0981, 44.7036],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621360',
        NAME: 'Norwood-Norfolk Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216199496,
        AWATER: 4456993,
        INTPTLAT: '+44.7886372',
        INTPTLON: '-074.9880266',
        ELSDLEA: '',
        UNSDLEA: '21360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8595, 44.8345],
            [-74.7111, 44.953],
            [-74.6305, 44.8694],
            [-74.6142, 44.7524],
            [-74.6808, 44.7074],
            [-74.8488, 44.7207],
            [-74.9173, 44.7578],
            [-74.8595, 44.8345],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627960',
        NAME: 'Brasher Falls Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440119520,
        AWATER: 3565494,
        INTPTLAT: '+44.8082544',
        INTPTLON: '-074.7466211',
        ELSDLEA: '',
        UNSDLEA: '27960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5864, 40.8258],
            [-73.5505, 40.8231],
            [-73.5223, 40.8248],
            [-73.5175, 40.7846],
            [-73.5601, 40.7715],
            [-73.6039, 40.7865],
            [-73.6105, 40.8027],
            [-73.6211, 40.8116],
            [-73.5864, 40.8258],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615810',
        NAME: 'Jericho Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31232061,
        AWATER: 32509,
        INTPTLAT: '+40.8031304',
        INTPTLON: '-073.5593342',
        ELSDLEA: '',
        UNSDLEA: '15810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9065, 41.7062],
            [-73.8521, 41.7834],
            [-73.7734, 41.7953],
            [-73.7733, 41.7941],
            [-73.7728, 41.7929],
            [-73.7616, 41.6946],
            [-73.6328, 41.6746],
            [-73.6302, 41.6398],
            [-73.7095, 41.5576],
            [-73.8961, 41.6577],
            [-73.9022, 41.6714],
            [-73.9065, 41.7062],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603270',
        NAME: 'Arlington Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300629296,
        AWATER: 4044631,
        INTPTLAT: '+41.6787749',
        INTPTLON: '-073.7656122',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8384, 41.4783],
            [-73.7052, 41.5525],
            [-73.5632, 41.5119],
            [-73.6613, 41.4294],
            [-73.6584, 41.3643],
            [-73.6625, 41.364],
            [-73.7736, 41.4126],
            [-73.8347, 41.4542],
            [-73.8384, 41.4783],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606570',
        NAME: 'Carmel Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198914059,
        AWATER: 13287597,
        INTPTLAT: '+41.4724145',
        INTPTLON: '-073.6978184',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9485, 41.8166],
            [-73.9403, 41.8727],
            [-73.7945, 41.8655],
            [-73.7852, 41.8467],
            [-73.7734, 41.7953],
            [-73.8521, 41.7834],
            [-73.9065, 41.7062],
            [-73.9444, 41.7146],
            [-73.9485, 41.8166],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615180',
        NAME: 'Hyde Park Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174163583,
        AWATER: 9947693,
        INTPTLAT: '+41.8068809',
        INTPTLON: '-073.8796535',
        ELSDLEA: '',
        UNSDLEA: '15180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9433, 41.9855],
            [-73.9404, 41.9943],
            [-73.7855, 41.9385],
            [-73.7945, 41.8655],
            [-73.9403, 41.8727],
            [-73.9433, 41.9855],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624480',
        NAME: 'Rhinebeck Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147482004,
        AWATER: 12864697,
        INTPTLAT: '+41.9248846',
        INTPTLON: '-073.8636640',
        ELSDLEA: '',
        UNSDLEA: '24480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9506, 41.6326],
            [-73.8961, 41.6577],
            [-73.7095, 41.5576],
            [-73.7052, 41.5525],
            [-73.8384, 41.4783],
            [-73.9074, 41.5089],
            [-73.9595, 41.5714],
            [-73.9506, 41.6326],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629880',
        NAME: 'Wappingers Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238479229,
        AWATER: 6450562,
        INTPTLAT: '+41.5694215',
        INTPTLON: '-073.8463426',
        ELSDLEA: '',
        UNSDLEA: '29880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8868, 43.325],
            [-75.8146, 43.4836],
            [-75.7501, 43.469],
            [-75.7695, 43.6446],
            [-75.6322, 43.6553],
            [-75.6085, 43.5444],
            [-75.6889, 43.4552],
            [-75.5576, 43.3525],
            [-75.6245, 43.2535],
            [-75.6988, 43.2021],
            [-75.7378, 43.1647],
            [-75.8119, 43.1613],
            [-75.8843, 43.1556],
            [-75.8868, 43.325],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606240',
        NAME: 'Camden Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 765728916,
        AWATER: 88214670,
        INTPTLAT: '+43.3777338',
        INTPTLON: '-075.7182851',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3185, 43.0225],
            [-75.1928, 43.0482],
            [-75.1662, 43.0129],
            [-75.2074, 42.9406],
            [-75.303, 42.9357],
            [-75.3376, 42.9968],
            [-75.3185, 43.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600002',
        NAME: 'Sauquoit Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109263427,
        AWATER: 63349,
        INTPTLAT: '+42.9899699',
        INTPTLON: '-075.2509182',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2917, 43.3802],
            [-75.2839, 43.3737],
            [-75.2896, 43.3737],
            [-75.2769, 43.3105],
            [-75.0998, 43.324],
            [-75.155, 43.2058],
            [-75.3029, 43.1792],
            [-75.3398, 43.2],
            [-75.3835, 43.2173],
            [-75.461, 43.3416],
            [-75.2917, 43.3802],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614580',
        NAME: 'Holland Patent Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311762564,
        AWATER: 2581955,
        INTPTLAT: '+43.2786077',
        INTPTLON: '-075.2859919',
        ELSDLEA: '',
        UNSDLEA: '14580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3835, 43.2173],
            [-75.3398, 43.2],
            [-75.3029, 43.1792],
            [-75.3579, 43.1215],
            [-75.4471, 43.1801],
            [-75.3835, 43.2173],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621960',
        NAME: 'Oriskany Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65434039,
        AWATER: 886736,
        INTPTLAT: '+43.1668902',
        INTPTLON: '-075.3719411',
        ELSDLEA: '',
        UNSDLEA: '21960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6245, 43.2535],
            [-75.5576, 43.3525],
            [-75.461, 43.3416],
            [-75.3835, 43.2173],
            [-75.4471, 43.1801],
            [-75.496, 43.1746],
            [-75.6245, 43.2535],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624900',
        NAME: 'Rome City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247992910,
        AWATER: 11561245,
        INTPTLAT: '+43.2578430',
        INTPTLON: '-075.4818811',
        ELSDLEA: '',
        UNSDLEA: '24900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6157, 43.0499],
            [-75.549, 43.029],
            [-75.5777, 42.9147],
            [-75.6942, 43.0284],
            [-75.6157, 43.0499],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628140',
        NAME: 'Stockbridge Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113538011,
        AWATER: 0,
        INTPTLAT: '+42.9901272',
        INTPTLON: '-075.6060704',
        ELSDLEA: '',
        UNSDLEA: '28140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1242, 43.1703],
            [-75.0467, 43.2134],
            [-74.8801, 43.2414],
            [-74.8492, 43.0928],
            [-74.9548, 43.0765],
            [-75.0316, 43.0955],
            [-75.0721, 43.1024],
            [-75.1242, 43.1703],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630600',
        NAME: 'West Canada Valley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287833426,
        AWATER: 2345209,
        INTPTLAT: '+43.1545422',
        INTPTLON: '-074.9724876',
        ELSDLEA: '',
        UNSDLEA: '30600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.8114, 42.0764],
              [-73.7913, 42.1242],
              [-73.6823, 42.1212],
              [-73.6599, 42.0535],
              [-73.8114, 42.0764],
            ],
          ],
          [
            [
              [-73.9107, 42.1273],
              [-73.855, 42.192],
              [-73.8051, 42.126],
              [-73.9296, 42.0788],
              [-73.9107, 42.1273],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612030',
        NAME: 'Germantown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158115867,
        AWATER: 9410639,
        INTPTLAT: '+42.1209894',
        INTPTLON: '-073.7868966',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8114, 42.0764],
            [-73.6599, 42.0535],
            [-73.5563, 41.9978],
            [-73.5349, 41.968],
            [-73.6656, 41.8489],
            [-73.6658, 41.8484],
            [-73.6678, 41.8479],
            [-73.7852, 41.8467],
            [-73.7945, 41.8655],
            [-73.7855, 41.9385],
            [-73.7668, 42.006],
            [-73.8114, 42.0764],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623040',
        NAME: 'Pine Plains Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362518457,
        AWATER: 4068824,
        INTPTLAT: '+41.9690961',
        INTPTLON: '-073.6847301',
        ELSDLEA: '',
        UNSDLEA: '23040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7593, 42.9492],
            [-78.7246, 42.9425],
            [-78.7241, 42.9124],
            [-78.7693, 42.9246],
            [-78.7593, 42.9492],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618600',
        NAME: 'Cheektowaga-Maryvale Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13676152,
        AWATER: 0,
        INTPTLAT: '+42.9303838',
        INTPTLON: '-078.7464854',
        ELSDLEA: '',
        UNSDLEA: '18600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0032, 42.6194],
            [-78.766, 42.5886],
            [-78.8761, 42.5211],
            [-78.9632, 42.5522],
            [-79.0032, 42.6194],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620970',
        NAME: 'North Collins Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148083563,
        AWATER: 477124,
        INTPTLAT: '+42.5651994',
        INTPTLON: '-078.8789595',
        ELSDLEA: '',
        UNSDLEA: '20970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7993, 42.8988],
            [-78.7658, 42.8743],
            [-78.8001, 42.8648],
            [-78.7993, 42.8988],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627000',
        NAME: 'Cheektowaga-Sloan Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7967035,
        AWATER: 16234,
        INTPTLAT: '+42.8832328',
        INTPTLON: '-078.7851812',
        ELSDLEA: '',
        UNSDLEA: '27000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4155, 42.3056],
            [-76.299, 42.3154],
            [-76.2627, 42.1852],
            [-76.3115, 42.154],
            [-76.4191, 42.164],
            [-76.4155, 42.3056],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606420',
        NAME: 'Candor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217221261,
        AWATER: 300443,
        INTPTLAT: '+42.2532934',
        INTPTLON: '-076.3445476',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2302, 42.5825],
            [-76.1579, 42.6073],
            [-76.1153, 42.529],
            [-76.1957, 42.4708],
            [-76.2591, 42.5028],
            [-76.2302, 42.5825],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608460',
        NAME: 'Cortland City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126983039,
        AWATER: 145482,
        INTPTLAT: '+42.5263358',
        INTPTLON: '-076.1883439',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2756, 42.3989],
            [-76.2232, 42.4083],
            [-76.1327, 42.4103],
            [-76.0812, 42.2647],
            [-76.1463, 42.1648],
            [-76.1646, 42.1418],
            [-76.2627, 42.1852],
            [-76.299, 42.3154],
            [-76.2756, 42.3989],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620670',
        NAME: 'Newark Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371850944,
        AWATER: 668874,
        INTPTLAT: '+42.2875574',
        INTPTLON: '-076.1910810',
        ELSDLEA: '',
        UNSDLEA: '20670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4506, 42.8538],
            [-75.4528, 42.8676],
            [-75.3091, 42.8837],
            [-75.2936, 42.7669],
            [-75.2934, 42.7477],
            [-75.391, 42.7395],
            [-75.4325, 42.7684],
            [-75.4506, 42.8538],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605670',
        NAME: 'Brookfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150410838,
        AWATER: 506785,
        INTPTLAT: '+42.8027579',
        INTPTLON: '-075.3847380',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6085, 43.5444],
            [-75.3402, 43.5098],
            [-75.1826, 43.5818],
            [-75.1042, 43.5641],
            [-74.9494, 43.6024],
            [-74.9473, 43.5176],
            [-75.0069, 43.4474],
            [-75.1008, 43.3937],
            [-75.257, 43.4131],
            [-75.2917, 43.3802],
            [-75.461, 43.3416],
            [-75.5576, 43.3525],
            [-75.6889, 43.4552],
            [-75.6085, 43.5444],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605040',
        NAME: 'Adirondack Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 944737422,
        AWATER: 13912433,
        INTPTLAT: '+43.4670450',
        INTPTLON: '-075.2997028',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6988, 43.2021],
            [-75.6157, 43.0499],
            [-75.6942, 43.0284],
            [-75.6994, 43.0212],
            [-75.7378, 43.1647],
            [-75.6988, 43.2021],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600013',
        NAME: 'Oneida City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104003006,
        AWATER: 462824,
        INTPTLAT: '+43.0983497',
        INTPTLON: '-075.6730825',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3094, 43.0989],
            [-75.268, 43.1126],
            [-75.2672, 43.1124],
            [-75.2739, 43.1015],
            [-75.2922, 43.0836],
            [-75.3094, 43.0989],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620610',
        NAME: 'New York Mills Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6797468,
        AWATER: 0,
        INTPTLAT: '+43.0997096',
        INTPTLON: '-075.2892782',
        ELSDLEA: '',
        UNSDLEA: '20610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2896, 43.3737],
            [-75.2839, 43.3737],
            [-75.2917, 43.3802],
            [-75.257, 43.4131],
            [-75.1008, 43.3937],
            [-75.0069, 43.4474],
            [-75.0242, 43.3524],
            [-75.0998, 43.324],
            [-75.2769, 43.3105],
            [-75.2896, 43.3737],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624360',
        NAME: 'Remsen Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236275480,
        AWATER: 4637780,
        INTPTLAT: '+43.3661861',
        INTPTLON: '-075.1458580',
        ELSDLEA: '',
        UNSDLEA: '24360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.496, 43.1746],
            [-75.4471, 43.1801],
            [-75.3579, 43.1215],
            [-75.3391, 43.0962],
            [-75.3496, 43.0952],
            [-75.4451, 43.0655],
            [-75.496, 43.1746],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631050',
        NAME: 'Westmoreland Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110882401,
        AWATER: 18181,
        INTPTLAT: '+43.1278416',
        INTPTLON: '-075.4409232',
        ELSDLEA: '',
        UNSDLEA: '31050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2488, 43.337],
            [-76.1573, 43.3975],
            [-75.8868, 43.325],
            [-75.8843, 43.1556],
            [-75.9934, 43.1834],
            [-76.203, 43.2234],
            [-76.2488, 43.337],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606900',
        NAME: 'Central Square Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413072112,
        AWATER: 125999191,
        INTPTLAT: '+43.2795041',
        INTPTLON: '-076.0384211',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9744, 44.3456],
            [-75.86, 44.4033],
            [-75.7799, 44.3111],
            [-75.8521, 44.2375],
            [-75.9403, 44.2575],
            [-75.9744, 44.3456],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602670',
        NAME: 'Alexandria Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 169524006,
        AWATER: 28904812,
        INTPTLAT: '+44.3199062',
        INTPTLON: '-075.8784160',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6763, 44.3201],
            [-75.6009, 44.396],
            [-75.6142, 44.4524],
            [-75.5546, 44.4724],
            [-75.4285, 44.5037],
            [-75.3456, 44.4432],
            [-75.3276, 44.3439],
            [-75.269, 44.24],
            [-75.4461, 44.2177],
            [-75.6763, 44.3201],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612360',
        NAME: 'Gouverneur Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574289203,
        AWATER: 14239603,
        INTPTLAT: '+44.3665403',
        INTPTLON: '-075.4640440',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7381, 44.535],
            [-75.6142, 44.4524],
            [-75.6009, 44.396],
            [-75.6763, 44.3201],
            [-75.7799, 44.3111],
            [-75.86, 44.4033],
            [-75.7381, 44.535],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613440',
        NAME: 'Hammond Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233978755,
        AWATER: 60892457,
        INTPTLAT: '+44.4329818',
        INTPTLON: '-075.7123157',
        ELSDLEA: '',
        UNSDLEA: '13440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3337, 43.9411],
            [-76.2405, 43.9427],
            [-76.0622, 43.9944],
            [-75.9767, 43.9817],
            [-75.9781, 43.957],
            [-75.987, 43.8961],
            [-76.0962, 43.8608],
            [-76.1314, 43.904],
            [-76.2631, 43.9012],
            [-76.3239, 43.8611],
            [-76.464, 43.8751],
            [-76.4639, 43.9414],
            [-76.3337, 43.9411],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614880',
        NAME: 'Sackets Harbor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121072017,
        AWATER: 163065239,
        INTPTLAT: '+43.9088793',
        INTPTLON: '-076.3071516',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9542, 44.0953],
            [-75.8343, 44.1916],
            [-75.8521, 44.2375],
            [-75.7799, 44.3111],
            [-75.6763, 44.3201],
            [-75.4461, 44.2177],
            [-75.5335, 44.1175],
            [-75.6575, 44.1695],
            [-75.7649, 44.0654],
            [-75.8165, 44.0179],
            [-75.8605, 44.0053],
            [-75.9542, 44.0953],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615300',
        NAME: 'Indian River Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 643913253,
        AWATER: 22608366,
        INTPTLAT: '+44.1864639',
        INTPTLON: '-075.7145207',
        ELSDLEA: '',
        UNSDLEA: '15300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.071, 44.3158],
            [-75.9804, 44.3464],
            [-75.9744, 44.3456],
            [-75.9403, 44.2575],
            [-75.8521, 44.2375],
            [-75.8343, 44.1916],
            [-75.9542, 44.0953],
            [-75.9901, 44.092],
            [-76.0375, 44.1551],
            [-76.006, 44.2478],
            [-76.071, 44.3158],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616380',
        NAME: 'La Fargeville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228564053,
        AWATER: 20159057,
        INTPTLAT: '+44.2031153',
        INTPTLON: '-075.9576715',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9767, 43.9817],
            [-75.8605, 44.0053],
            [-75.8165, 44.0179],
            [-75.8353, 43.9636],
            [-75.848, 43.9095],
            [-75.9781, 43.957],
            [-75.9767, 43.9817],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630120',
        NAME: 'Watertown City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74514250,
        AWATER: 1403128,
        INTPTLAT: '+43.9652734',
        INTPTLON: '-075.8953171',
        ELSDLEA: '',
        UNSDLEA: '30120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7933, 41.2078],
            [-73.7477, 41.2242],
            [-73.7144, 41.1596],
            [-73.7665, 41.1423],
            [-73.7926, 41.1435],
            [-73.8162, 41.166],
            [-73.7933, 41.2078],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606990',
        NAME: 'Chappaqua Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48375033,
        AWATER: 352093,
        INTPTLAT: '+41.1791980',
        INTPTLON: '-073.7692362',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8107, 40.9879],
            [-73.7931, 40.9752],
            [-73.8019, 40.9441],
            [-73.8281, 40.9546],
            [-73.8107, 40.9879],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610080',
        NAME: 'Eastchester Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7043752,
        AWATER: 224926,
        INTPTLAT: '+40.9605184',
        INTPTLON: '-073.8076028',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9572, 41.2764],
            [-73.8991, 41.2865],
            [-73.8663, 41.273],
            [-73.8471, 41.2534],
            [-73.9368, 41.2108],
            [-73.9572, 41.2764],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614190',
        NAME: 'Hendrick Hudson Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37070262,
        AWATER: 9941478,
        INTPTLAT: '+41.2512755',
        INTPTLON: '-073.9164602',
        ELSDLEA: '',
        UNSDLEA: '14190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7477, 41.2242],
            [-73.7486, 41.2436],
            [-73.7298, 41.2504],
            [-73.5128, 41.1994],
            [-73.5548, 41.1799],
            [-73.6288, 41.1464],
            [-73.7144, 41.1596],
            [-73.7477, 41.2242],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619950',
        NAME: 'Bedford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132449430,
        AWATER: 3439755,
        INTPTLAT: '+41.2081001',
        INTPTLON: '-073.6357489',
        ELSDLEA: '',
        UNSDLEA: '19950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8065, 41.1247],
            [-73.7665, 41.1315],
            [-73.7344, 41.0746],
            [-73.7507, 41.0792],
            [-73.7978, 41.0867],
            [-73.8065, 41.1247],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620160',
        NAME: 'Mount Pleasant Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21487808,
        AWATER: 4261860,
        INTPTLAT: '+41.1032888',
        INTPTLON: '-073.7706915',
        ELSDLEA: '',
        UNSDLEA: '20160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8019, 40.9441],
            [-73.7931, 40.9752],
            [-73.772, 40.955],
            [-73.7406, 40.8762],
            [-73.7481, 40.8717],
            [-73.7837, 40.8811],
            [-73.805, 40.9261],
            [-73.8054, 40.9268],
            [-73.8019, 40.9441],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620490',
        NAME: 'New Rochelle City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26807094,
        AWATER: 7472696,
        INTPTLAT: '+40.9232433',
        INTPTLON: '-073.7793439',
        ELSDLEA: '',
        UNSDLEA: '20490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.805, 40.9261],
            [-73.7837, 40.8811],
            [-73.8232, 40.8912],
            [-73.805, 40.9261],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622680',
        NAME: 'Pelham Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5625295,
        AWATER: 119128,
        INTPTLAT: '+40.9004583',
        INTPTLON: '-073.8071383',
        ELSDLEA: '',
        UNSDLEA: '22680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8914, 41.3649],
            [-73.8677, 41.4093],
            [-73.8347, 41.4542],
            [-73.7736, 41.4126],
            [-73.8285, 41.3593],
            [-73.8914, 41.3649],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624000',
        NAME: 'Putnam Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75866751,
        AWATER: 3610897,
        INTPTLAT: '+41.3953643',
        INTPTLON: '-073.8390532',
        ELSDLEA: '',
        UNSDLEA: '24000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7066, 41.0729],
            [-73.7063, 41.0741],
            [-73.6667, 41.0255],
            [-73.6937, 41.0126],
            [-73.7066, 41.0729],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624630',
        NAME: 'Blind Brook-Rye Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7123648,
        AWATER: 28165,
        INTPTLAT: '+41.0391855',
        INTPTLON: '-073.6852535',
        ELSDLEA: '',
        UNSDLEA: '24630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7718, 41.3434],
            [-73.6895, 41.3519],
            [-73.675, 41.3088],
            [-73.7298, 41.2504],
            [-73.7486, 41.2436],
            [-73.7756, 41.3169],
            [-73.7718, 41.3434],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627180',
        NAME: 'Somers Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73828786,
        AWATER: 6417225,
        INTPTLAT: '+41.3033455',
        INTPTLON: '-073.7269724',
        ELSDLEA: '',
        UNSDLEA: '27180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8019, 41.0612],
            [-73.7978, 41.0867],
            [-73.7507, 41.0792],
            [-73.7443, 41.0521],
            [-73.7723, 41.0543],
            [-73.8048, 41.0571],
            [-73.8019, 41.0612],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629400',
        NAME: 'Valhalla Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13055400,
        AWATER: 591924,
        INTPTLAT: '+41.0758665',
        INTPTLON: '-073.7866609',
        ELSDLEA: '',
        UNSDLEA: '29400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8663, 41.273],
            [-73.7756, 41.3169],
            [-73.7486, 41.2436],
            [-73.7477, 41.2242],
            [-73.7933, 41.2078],
            [-73.8206, 41.2163],
            [-73.8471, 41.2534],
            [-73.8663, 41.273],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631980',
        NAME: 'Yorktown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69916981,
        AWATER: 5538685,
        INTPTLAT: '+41.2625938',
        INTPTLON: '-073.8044923',
        ELSDLEA: '',
        UNSDLEA: '31980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4485, 42.9444],
            [-73.3613, 42.9388],
            [-73.2707, 43.0176],
            [-73.2747, 42.9344],
            [-73.2783, 42.8376],
            [-73.2893, 42.8041],
            [-73.4081, 42.8223],
            [-73.4727, 42.856],
            [-73.4485, 42.9444],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614760',
        NAME: 'Hoosick Falls Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241484908,
        AWATER: 184271,
        INTPTLAT: '+42.8936064',
        INTPTLON: '-073.3528095',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0564, 42.2216],
            [-73.9167, 42.3034],
            [-73.823, 42.2462],
            [-73.855, 42.192],
            [-73.9107, 42.1273],
            [-74.0424, 42.1704],
            [-74.0564, 42.2216],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606720',
        NAME: 'Catskill Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182778293,
        AWATER: 10954951,
        INTPTLAT: '+42.2086890',
        INTPTLON: '-073.9388876',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0555, 42.9273],
            [-76.0457, 42.9695],
            [-75.959, 42.9349],
            [-75.8986, 42.8255],
            [-75.9802, 42.7411],
            [-76.0509, 42.7828],
            [-76.0917, 42.8535],
            [-76.0555, 42.9273],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610860',
        NAME: 'Fabius-Pompey Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232327254,
        AWATER: 896443,
        INTPTLAT: '+42.8487347',
        INTPTLON: '-075.9886876',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.959, 42.9349],
            [-75.902, 43.0143],
            [-75.7954, 43.0295],
            [-75.7643, 42.9925],
            [-75.7322, 42.8446],
            [-75.776, 42.818],
            [-75.8986, 42.8255],
            [-75.959, 42.9349],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606780',
        NAME: 'Cazenovia Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285432611,
        AWATER: 6290520,
        INTPTLAT: '+42.9102493',
        INTPTLON: '-075.8237705',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0371, 43.044],
            [-75.9152, 43.0592],
            [-75.902, 43.0143],
            [-75.959, 42.9349],
            [-76.0457, 42.9695],
            [-76.0371, 43.044],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618330',
        NAME: 'Fayetteville-Manlius Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111697634,
        AWATER: 452231,
        INTPTLAT: '+42.9988811',
        INTPTLON: '-075.9732182',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2314, 43.0556],
            [-76.2041, 43.047],
            [-76.1989, 43.0418],
            [-76.1948, 43.0421],
            [-76.1627, 42.9844],
            [-76.2075, 42.9996],
            [-76.2314, 43.0556],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607320',
        NAME: 'Westhill Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18228660,
        AWATER: 8125,
        INTPTLAT: '+43.0124709',
        INTPTLON: '-076.1891954',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6382, 43.7849],
            [-73.6288, 43.8836],
            [-73.5027, 43.884],
            [-73.4047, 43.9238],
            [-73.3738, 43.8429],
            [-73.3793, 43.8085],
            [-73.4713, 43.7717],
            [-73.4839, 43.6906],
            [-73.5253, 43.6361],
            [-73.6327, 43.6784],
            [-73.6382, 43.7849],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628680',
        NAME: 'Ticonderoga Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360954040,
        AWATER: 59165879,
        INTPTLAT: '+43.7744047',
        INTPTLON: '-073.5160064',
        ELSDLEA: '',
        UNSDLEA: '28680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9336, 42.6949],
            [-78.908, 42.6966],
            [-78.8044, 42.6964],
            [-78.7541, 42.631],
            [-78.766, 42.5886],
            [-79.0032, 42.6194],
            [-78.9336, 42.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610170',
        NAME: 'Eden Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175235470,
        AWATER: 144264,
        INTPTLAT: '+42.6403480',
        INTPTLON: '-078.8651075',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.806, 42.7969],
            [-78.6968, 42.8154],
            [-78.6835, 42.7963],
            [-78.6969, 42.6958],
            [-78.7238, 42.6393],
            [-78.8006, 42.763],
            [-78.806, 42.7969],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621900',
        NAME: 'Orchard Park Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128156327,
        AWATER: 228805,
        INTPTLAT: '+42.7444114',
        INTPTLON: '-078.7373382',
        ELSDLEA: '',
        UNSDLEA: '21900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5881, 42.8307],
            [-77.5519, 42.8541],
            [-77.4096, 42.7968],
            [-77.5046, 42.6926],
            [-77.6021, 42.6965],
            [-77.5881, 42.8307],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614670',
        NAME: 'Honeoye Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218781396,
        AWATER: 11507084,
        INTPTLAT: '+42.7612222',
        INTPTLON: '-077.5049615',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5046, 42.6926],
            [-77.4096, 42.7968],
            [-77.3822, 42.7948],
            [-77.3145, 42.7591],
            [-77.3536, 42.7],
            [-77.2659, 42.6351],
            [-77.3593, 42.533],
            [-77.3922, 42.5386],
            [-77.4852, 42.5772],
            [-77.5046, 42.6926],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620220',
        NAME: 'Naples Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297210087,
        AWATER: 8225165,
        INTPTLAT: '+42.6452413',
        INTPTLON: '-077.3997701',
        ELSDLEA: '',
        UNSDLEA: '20220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9634, 42.9188],
            [-76.9703, 42.841],
            [-76.9476, 42.7592],
            [-77.0188, 42.767],
            [-77.0849, 42.8708],
            [-76.9634, 42.9188],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611970',
        NAME: 'Geneva City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103378160,
        AWATER: 5644415,
        INTPTLAT: '+42.8461265',
        INTPTLON: '-077.0062354',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1893, 43.1028],
            [-77.1109, 43.1607],
            [-77.0315, 43.1625],
            [-77.0332, 43.0131],
            [-77.171, 43.0398],
            [-77.1893, 43.1028],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620640',
        NAME: 'Newark Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157105162,
        AWATER: 947654,
        INTPTLAT: '+43.0759075',
        INTPTLON: '-077.1006010',
        ELSDLEA: '',
        UNSDLEA: '20640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1863, 43.0167],
            [-77.171, 43.0398],
            [-77.0332, 43.0131],
            [-76.9541, 42.9696],
            [-76.9634, 42.9188],
            [-77.0849, 42.8708],
            [-77.17, 42.8787],
            [-77.2002, 42.9335],
            [-77.1863, 43.0167],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622890',
        NAME: 'Phelps-Clifton Springs Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245498699,
        AWATER: 859837,
        INTPTLAT: '+42.9530803',
        INTPTLON: '-077.0824179',
        ELSDLEA: '',
        UNSDLEA: '22890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2552, 43.1894],
            [-76.2149, 43.2054],
            [-76.1625, 43.0861],
            [-76.1825, 43.0747],
            [-76.2515, 43.1224],
            [-76.2552, 43.1894],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617520',
        NAME: 'Liverpool Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59036232,
        AWATER: 3616958,
        INTPTLAT: '+43.1366513',
        INTPTLON: '-076.2073550',
        ELSDLEA: '',
        UNSDLEA: '17520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2149, 43.2054],
            [-76.203, 43.2234],
            [-75.9934, 43.1834],
            [-75.9667, 43.1347],
            [-76.1166, 43.0909],
            [-76.144, 43.0834],
            [-76.1625, 43.0861],
            [-76.2149, 43.2054],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621210',
        NAME: 'North Syracuse Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166859575,
        AWATER: 451167,
        INTPTLAT: '+43.1603615',
        INTPTLON: '-076.1053660',
        ELSDLEA: '',
        UNSDLEA: '21210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8843, 43.1556],
            [-75.8119, 43.1613],
            [-75.7954, 43.0295],
            [-75.902, 43.0143],
            [-75.9152, 43.0592],
            [-75.9667, 43.1347],
            [-75.9934, 43.1834],
            [-75.8843, 43.1556],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607470',
        NAME: 'Chittenango Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165734969,
        AWATER: 432992,
        INTPTLAT: '+43.0906556',
        INTPTLON: '-075.8835628',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.136, 42.9847],
            [-76.1257, 42.9939],
            [-76.0555, 42.9273],
            [-76.0917, 42.8535],
            [-76.1749, 42.8957],
            [-76.136, 42.9847],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616410',
        NAME: 'LaFayette Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114192150,
        AWATER: 187233,
        INTPTLAT: '+42.9213853',
        INTPTLON: '-076.1198769',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.99, 42.9388],
            [-74.8899, 42.9891],
            [-74.7758, 42.9623],
            [-74.7301, 42.8974],
            [-74.8857, 42.8635],
            [-74.99, 42.9388],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622200',
        NAME: 'Van Hornesville-Owen D. Young Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171696187,
        AWATER: 104598,
        INTPTLAT: '+42.9163793',
        INTPTLON: '-074.8485380',
        ELSDLEA: '',
        UNSDLEA: '22200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2921, 42.8009],
            [-76.2596, 42.8199],
            [-76.2449, 42.8209],
            [-76.1252, 42.7268],
            [-76.0509, 42.7828],
            [-75.9802, 42.7411],
            [-75.9415, 42.6701],
            [-75.9956, 42.6503],
            [-76.1579, 42.6073],
            [-76.2302, 42.5825],
            [-76.2746, 42.5955],
            [-76.3141, 42.6729],
            [-76.2701, 42.6992],
            [-76.2921, 42.8009],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614640',
        NAME: 'Homer Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421652690,
        AWATER: 5124103,
        INTPTLAT: '+42.6995995',
        INTPTLON: '-076.1495760',
        ELSDLEA: '',
        UNSDLEA: '14640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4994, 43.0972],
            [-76.4375, 43.1279],
            [-76.3664, 43.1009],
            [-76.3498, 43.0239],
            [-76.405, 42.9942],
            [-76.4941, 42.99],
            [-76.4994, 43.0972],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615990',
        NAME: 'Jordan-Elbridge Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152511151,
        AWATER: 4184323,
        INTPTLAT: '+43.0536924',
        INTPTLON: '-076.4371332',
        ELSDLEA: '',
        UNSDLEA: '15990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6305, 44.8694],
            [-74.4301, 44.8796],
            [-74.3911, 44.6903],
            [-74.4819, 44.7493],
            [-74.6142, 44.7524],
            [-74.6305, 44.8694],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605820',
        NAME: 'Brushton-Moira Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288222323,
        AWATER: 48899,
        INTPTLAT: '+44.8020343',
        INTPTLON: '-074.5086145',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.549, 43.029],
            [-75.473, 43.0216],
            [-75.4528, 42.8676],
            [-75.4506, 42.8538],
            [-75.4983, 42.8562],
            [-75.5629, 42.8744],
            [-75.5777, 42.9147],
            [-75.549, 43.029],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618080',
        NAME: 'Madison Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143577542,
        AWATER: 309995,
        INTPTLAT: '+42.9287488',
        INTPTLON: '-075.5076886',
        ELSDLEA: '',
        UNSDLEA: '18080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3496, 43.0952],
            [-75.3391, 43.0962],
            [-75.3094, 43.0989],
            [-75.2922, 43.0836],
            [-75.2078, 43.0645],
            [-75.1928, 43.0482],
            [-75.3185, 43.0225],
            [-75.3496, 43.0952],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620370',
        NAME: 'New Hartford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66097223,
        AWATER: 332957,
        INTPTLAT: '+43.0488345',
        INTPTLON: '-075.2776671',
        ELSDLEA: '',
        UNSDLEA: '20370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0998, 43.324],
            [-75.0242, 43.3524],
            [-75.0069, 43.4474],
            [-74.9473, 43.5176],
            [-74.9494, 43.6024],
            [-74.9518, 43.6596],
            [-74.8092, 43.7391],
            [-74.7978, 43.6534],
            [-74.6606, 43.6737],
            [-74.6399, 43.5194],
            [-74.7687, 43.4258],
            [-74.6508, 43.3715],
            [-74.7053, 43.2864],
            [-74.8167, 43.2928],
            [-74.8801, 43.2414],
            [-75.0467, 43.2134],
            [-75.1242, 43.1703],
            [-75.155, 43.2058],
            [-75.0998, 43.324],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623370',
        NAME: 'Poland Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1215374007,
        AWATER: 28551320,
        INTPTLAT: '+43.5710090',
        INTPTLON: '-074.8410968',
        ELSDLEA: '',
        UNSDLEA: '23370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6988, 43.2021],
            [-75.6245, 43.2535],
            [-75.496, 43.1746],
            [-75.4451, 43.0655],
            [-75.4713, 43.026],
            [-75.473, 43.0216],
            [-75.549, 43.029],
            [-75.6157, 43.0499],
            [-75.6988, 43.2021],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626760',
        NAME: 'Sherrill City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277953539,
        AWATER: 1334860,
        INTPTLAT: '+43.1359950',
        INTPTLON: '-075.5779773',
        ELSDLEA: '',
        UNSDLEA: '26760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1826, 43.5818],
            [-75.1102, 43.6152],
            [-75.1404, 43.8607],
            [-75.1641, 44.049],
            [-75.1702, 44.097],
            [-75.0628, 44.0504],
            [-74.8542, 44.0701],
            [-74.8284, 43.8816],
            [-74.8201, 43.8185],
            [-74.8092, 43.7391],
            [-74.9518, 43.6596],
            [-74.9494, 43.6024],
            [-75.1042, 43.5641],
            [-75.1826, 43.5818],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628800',
        NAME: 'Town of Webb Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1193292243,
        AWATER: 80231464,
        INTPTLAT: '+43.8344113',
        INTPTLON: '-075.0005868',
        ELSDLEA: '',
        UNSDLEA: '28800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.473, 43.0216],
            [-75.4713, 43.026],
            [-75.3376, 42.9968],
            [-75.303, 42.9357],
            [-75.3091, 42.8837],
            [-75.4528, 42.8676],
            [-75.473, 43.0216],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630160',
        NAME: 'Waterville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191371501,
        AWATER: 465730,
        INTPTLAT: '+42.9320279',
        INTPTLON: '-075.3997566',
        ELSDLEA: '',
        UNSDLEA: '30160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.303, 42.9357],
            [-75.2074, 42.9406],
            [-75.1662, 43.0129],
            [-75.0853, 43],
            [-75.0606, 42.9197],
            [-75.1213, 42.8045],
            [-75.2936, 42.7669],
            [-75.3091, 42.8837],
            [-75.303, 42.9357],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630930',
        NAME: 'Mount Markham Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360889623,
        AWATER: 350248,
        INTPTLAT: '+42.8870057',
        INTPTLON: '-075.1829057',
        ELSDLEA: '',
        UNSDLEA: '30930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5596, 43.8418],
            [-74.3665, 43.9529],
            [-74.3368, 43.9252],
            [-74.2137, 43.8109],
            [-74.0471, 43.7963],
            [-74.057, 43.7445],
            [-74.2146, 43.7287],
            [-74.2038, 43.6588],
            [-74.3268, 43.6196],
            [-74.5388, 43.8223],
            [-74.5596, 43.8418],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615270',
        NAME: 'Indian Lake Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 652086358,
        AWATER: 37392668,
        INTPTLAT: '+43.8063790',
        INTPTLON: '-074.4176650',
        ELSDLEA: '',
        UNSDLEA: '15270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1581, 43.3599],
            [-74.1243, 43.1539],
            [-74.2126, 43.1296],
            [-74.2863, 43.2068],
            [-74.4577, 43.2329],
            [-74.4721, 43.3365],
            [-74.1581, 43.3599],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621300',
        NAME: 'Northville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351529986,
        AWATER: 38286528,
        INTPTLAT: '+43.2603185',
        INTPTLON: '-074.2749966',
        ELSDLEA: '',
        UNSDLEA: '21300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7285, 40.8118],
            [-72.7164, 40.8691],
            [-72.6901, 40.8547],
            [-72.6927, 40.728],
            [-72.7284, 40.7188],
            [-72.7285, 40.8118],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624420',
        NAME: 'Remsenburg-Speonk Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19268906,
        AWATER: 30150484,
        INTPTLAT: '+40.7998180',
        INTPTLON: '-072.7087610',
        ELSDLEA: '',
        UNSDLEA: '24420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5347, 43.2281],
            [-74.4577, 43.2329],
            [-74.424, 43.0537],
            [-74.4428, 43.0589],
            [-74.5477, 43.1012],
            [-74.5347, 43.2281],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631200',
        NAME: 'Wheelerville Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137119295,
        AWATER: 9455669,
        INTPTLAT: '+43.1240078',
        INTPTLON: '-074.5250700',
        ELSDLEA: '',
        UNSDLEA: '31200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8119, 43.1613],
            [-75.7378, 43.1647],
            [-75.6994, 43.0212],
            [-75.7643, 42.9925],
            [-75.7954, 43.0295],
            [-75.8119, 43.1613],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606390',
        NAME: 'Canastota Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145168099,
        AWATER: 185209,
        INTPTLAT: '+43.0849683',
        INTPTLON: '-075.7618630',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9667, 43.1347],
            [-75.9152, 43.0592],
            [-76.0371, 43.044],
            [-76.0837, 43.0583],
            [-76.1153, 43.0755],
            [-76.1166, 43.0909],
            [-75.9667, 43.1347],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609990',
        NAME: 'East Syracuse-Minoa Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120659571,
        AWATER: 1856207,
        INTPTLAT: '+43.0911770',
        INTPTLON: '-076.0215656',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7053, 42.7409],
            [-75.6784, 42.8241],
            [-75.6411, 42.8124],
            [-75.4325, 42.7684],
            [-75.391, 42.7395],
            [-75.4115, 42.6431],
            [-75.4783, 42.6062],
            [-75.6249, 42.6454],
            [-75.7053, 42.7409],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626700',
        NAME: 'Sherburne-Earlville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403735136,
        AWATER: 1267012,
        INTPTLAT: '+42.7084619',
        INTPTLON: '-075.5368118',
        ELSDLEA: '',
        UNSDLEA: '26700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5795, 42.3458],
            [-75.5415, 42.4472],
            [-75.4506, 42.4622],
            [-75.4126, 42.3603],
            [-75.4028, 42.1772],
            [-75.452, 42.1821],
            [-75.5795, 42.3458],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603810',
        NAME: 'Bainbridge-Guilford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274593745,
        AWATER: 2401582,
        INTPTLAT: '+42.3240343',
        INTPTLON: '-075.4763050',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6468, 42.328],
            [-75.5795, 42.3458],
            [-75.452, 42.1821],
            [-75.5171, 42.1219],
            [-75.6464, 42.2996],
            [-75.6468, 42.328],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602400',
        NAME: 'Afton Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201477318,
        AWATER: 2337205,
        INTPTLAT: '+42.2236236',
        INTPTLON: '-075.5564450',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1646, 42.1418],
            [-76.1463, 42.1648],
            [-76.0332, 42.1],
            [-76.1303, 42.0631],
            [-76.1646, 42.1418],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610710',
        NAME: 'Union-Endicott Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75506264,
        AWATER: 668367,
        INTPTLAT: '+42.1230619',
        INTPTLON: '-076.1066012',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0812, 42.2647],
            [-75.9716, 42.2162],
            [-75.9698, 42.1913],
            [-75.9947, 42.1143],
            [-76.0332, 42.1],
            [-76.1463, 42.1648],
            [-76.0812, 42.2647],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618150',
        NAME: 'Maine-Endwell Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128591632,
        AWATER: 564232,
        INTPTLAT: '+42.1886633',
        INTPTLON: '-076.0318099',
        ELSDLEA: '',
        UNSDLEA: '18150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4466, 42.2494],
            [-77.3578, 42.2419],
            [-77.1919, 42.1585],
            [-77.2005, 41.9998],
            [-77.4282, 42.0001],
            [-77.3877, 42.1184],
            [-77.4805, 42.2082],
            [-77.4466, 42.2494],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602370',
        NAME: 'Addison Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 431962530,
        AWATER: 1192752,
        INTPTLAT: '+42.1008317',
        INTPTLON: '-077.3148712',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3593, 42.533],
            [-77.2659, 42.6351],
            [-77.2438, 42.6497],
            [-77.1666, 42.5767],
            [-77.2194, 42.4705],
            [-77.2717, 42.4592],
            [-77.3576, 42.3997],
            [-77.3593, 42.533],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623790',
        NAME: 'Prattsburgh Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221612243,
        AWATER: 373305,
        INTPTLAT: '+42.5311481',
        INTPTLON: '-077.2817911',
        ELSDLEA: '',
        UNSDLEA: '23790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.144, 43.0834],
            [-76.1166, 43.0909],
            [-76.1153, 43.0755],
            [-76.144, 43.0834],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617940',
        NAME: 'Lyncourt Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3330413,
        AWATER: 0,
        INTPTLAT: '+43.0840936',
        INTPTLON: '-076.1272242',
        ELSDLEA: '',
        UNSDLEA: '17940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2515, 43.1224],
            [-76.1825, 43.0747],
            [-76.1948, 43.0463],
            [-76.1948, 43.0423],
            [-76.2041, 43.047],
            [-76.2314, 43.0556],
            [-76.2654, 43.1082],
            [-76.2515, 43.1224],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627150',
        NAME: 'Solvay Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17495156,
        AWATER: 8031411,
        INTPTLAT: '+43.0813997',
        INTPTLON: '-076.2163376',
        ELSDLEA: '',
        UNSDLEA: '27150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.256, 42.906],
            [-76.1749, 42.8957],
            [-76.0917, 42.8535],
            [-76.0509, 42.7828],
            [-76.1252, 42.7268],
            [-76.2449, 42.8209],
            [-76.256, 42.906],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629130',
        NAME: 'Tully Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200864318,
        AWATER: 5199663,
        INTPTLAT: '+42.8175863',
        INTPTLON: '-076.1568313',
        ELSDLEA: '',
        UNSDLEA: '29130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6885, 42.3578],
            [-78.5836, 42.3221],
            [-78.5283, 42.253],
            [-78.4937, 42.2278],
            [-78.4936, 42.216],
            [-78.4937, 42.2077],
            [-78.5803, 42.1512],
            [-78.6965, 42.2267],
            [-78.7689, 42.3139],
            [-78.6885, 42.3578],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610530',
        NAME: 'Ellicottville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287317775,
        AWATER: 596504,
        INTPTLAT: '+42.2685291',
        INTPTLON: '-078.6035792',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.069, 42.2296],
            [-78.9453, 42.2605],
            [-78.857, 42.1996],
            [-78.8271, 42.1393],
            [-78.6959, 42.1281],
            [-78.6964, 42.0745],
            [-78.6973, 41.9988],
            [-78.9189, 41.9981],
            [-79.0421, 41.9992],
            [-79.0756, 42.1073],
            [-79.069, 42.2296],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624090',
        NAME: 'Randolph Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657995917,
        AWATER: 20013624,
        INTPTLAT: '+42.1093442',
        INTPTLON: '-078.9062829',
        ELSDLEA: '',
        UNSDLEA: '24090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2066, 42.3805],
            [-79.061, 42.4061],
            [-78.974, 42.3915],
            [-78.9453, 42.2605],
            [-79.069, 42.2296],
            [-79.1251, 42.2383],
            [-79.193, 42.2983],
            [-79.2066, 42.3805],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623070',
        NAME: 'Pine Valley Central School District (South Dayton)',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298625469,
        AWATER: 1244404,
        INTPTLAT: '+42.3250696',
        INTPTLON: '-079.0824682',
        ELSDLEA: '',
        UNSDLEA: '23070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2529, 42.4728],
            [-79.061, 42.513],
            [-79.061, 42.4061],
            [-79.2066, 42.3805],
            [-79.2815, 42.3872],
            [-79.2529, 42.4728],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611250',
        NAME: 'Forestville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210022257,
        AWATER: 903751,
        INTPTLAT: '+42.4513428',
        INTPTLON: '-079.1664757',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8425, 40.9109],
            [-72.7164, 40.8691],
            [-72.7285, 40.8118],
            [-72.7813, 40.8293],
            [-72.8158, 40.8236],
            [-72.8648, 40.8197],
            [-72.8425, 40.9109],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600125',
        NAME: 'Eastport-South Manor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93096447,
        AWATER: 539025,
        INTPTLAT: '+40.8623282',
        INTPTLON: '-072.7875206',
        ELSDLEA: '',
        UNSDLEA: '00125',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.114, 41.2432],
            [-74.0607, 41.3269],
            [-73.9814, 41.3247],
            [-73.9826, 41.3217],
            [-73.9491, 41.2926],
            [-73.9572, 41.2764],
            [-73.9368, 41.2108],
            [-73.9217, 41.1863],
            [-74.0185, 41.1886],
            [-74.0843, 41.2003],
            [-74.114, 41.2432],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614010',
        NAME: 'Haverstraw-Stony Point Central School District (North Rockland)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132617998,
        AWATER: 24099139,
        INTPTLAT: '+41.2443763',
        INTPTLON: '-074.0162203',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0185, 41.1886],
            [-73.9217, 41.1863],
            [-73.9093, 41.1644],
            [-73.8874, 41.1369],
            [-73.9467, 41.0857],
            [-73.967, 41.0783],
            [-73.9906, 41.0788],
            [-74.019, 41.1121],
            [-74.0185, 41.1886],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620340',
        NAME: 'Clarkstown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67848833,
        AWATER: 13531575,
        INTPTLAT: '+41.1429377',
        INTPTLON: '-073.9681263',
        ELSDLEA: '',
        UNSDLEA: '20340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0843, 41.2003],
            [-74.0185, 41.1886],
            [-74.019, 41.1121],
            [-74.0335, 41.0702],
            [-74.0509, 41.0635],
            [-74.0719, 41.0729],
            [-74.0912, 41.0813],
            [-74.0843, 41.2003],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627810',
        NAME: 'East Ramapo Central School District (Spring Valley)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90986188,
        AWATER: 630130,
        INTPTLAT: '+41.1438342',
        INTPTLON: '-074.0624022',
        ELSDLEA: '',
        UNSDLEA: '27810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9956, 42.6503],
            [-75.9415, 42.6701],
            [-75.8633, 42.6459],
            [-75.7172, 42.5917],
            [-75.7573, 42.5461],
            [-75.7778, 42.4866],
            [-75.8702, 42.4208],
            [-75.9624, 42.4109],
            [-76.0035, 42.545],
            [-75.9956, 42.6503],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607560',
        NAME: 'Cincinnatus Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373428113,
        AWATER: 1872384,
        INTPTLAT: '+42.5465433',
        INTPTLON: '-075.8854448',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4451, 43.0655],
            [-75.3496, 43.0952],
            [-75.3185, 43.0225],
            [-75.3376, 42.9968],
            [-75.4713, 43.026],
            [-75.4451, 43.0655],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607770',
        NAME: 'Clinton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96836821,
        AWATER: 132694,
        INTPTLAT: '+43.0414789',
        INTPTLON: '-075.3870685',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7758, 42.9623],
            [-74.743, 42.9918],
            [-74.7168, 42.9863],
            [-74.5781, 42.9912],
            [-74.4888, 42.9851],
            [-74.4887, 42.9542],
            [-74.6737, 42.8831],
            [-74.7301, 42.8974],
            [-74.7758, 42.9623],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611370',
        NAME: 'Fort Plain Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157277659,
        AWATER: 1257122,
        INTPTLAT: '+42.9440424',
        INTPTLON: '-074.6493493',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5388, 43.8223],
            [-74.3268, 43.6196],
            [-74.3133, 43.4989],
            [-74.3935, 43.4116],
            [-74.4783, 43.3805],
            [-74.5388, 43.8223],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616530',
        NAME: 'Lake Pleasant Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 477043835,
        AWATER: 25671466,
        INTPTLAT: '+43.5789048',
        INTPTLON: '-074.4240332',
        ELSDLEA: '',
        UNSDLEA: '16530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.263, 42.0464],
            [-79.1702, 42.0759],
            [-79.0756, 42.1073],
            [-79.0421, 41.9992],
            [-79.2498, 41.9988],
            [-79.263, 42.0464],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611610',
        NAME: 'Frewsburg Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156323718,
        AWATER: 154676,
        INTPTLAT: '+42.0428243',
        INTPTLON: '-079.1357282',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.804, 42.9187],
            [-77.7345, 42.9548],
            [-77.6503, 42.954],
            [-77.6666, 42.8783],
            [-77.7527, 42.8566],
            [-77.8301, 42.8573],
            [-77.804, 42.9187],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603660',
        NAME: 'Avon Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130636693,
        AWATER: 411818,
        INTPTLAT: '+42.9064432',
        INTPTLON: '-077.7371079',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8301, 42.8573],
            [-77.7527, 42.8566],
            [-77.7163, 42.8094],
            [-77.7382, 42.669],
            [-77.7911, 42.6611],
            [-77.8405, 42.7459],
            [-77.8301, 42.8573],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611940',
        NAME: 'Geneseo Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187030167,
        AWATER: 4978961,
        INTPTLAT: '+42.7555668',
        INTPTLON: '-077.7777214',
        ELSDLEA: '',
        UNSDLEA: '11940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7527, 42.8566],
            [-77.6666, 42.8783],
            [-77.5881, 42.8307],
            [-77.6021, 42.6965],
            [-77.6611, 42.6823],
            [-77.6851, 42.6927],
            [-77.7181, 42.6722],
            [-77.7215, 42.6689],
            [-77.7382, 42.669],
            [-77.7163, 42.8094],
            [-77.7527, 42.8566],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617640',
        NAME: 'Livonia Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199958683,
        AWATER: 14196032,
        INTPTLAT: '+42.7835568',
        INTPTLON: '-077.6619547',
        ELSDLEA: '',
        UNSDLEA: '17640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6611, 42.6823],
            [-77.6021, 42.6965],
            [-77.5046, 42.6926],
            [-77.4852, 42.5772],
            [-77.3922, 42.5386],
            [-77.5667, 42.4286],
            [-77.6322, 42.4603],
            [-77.6611, 42.6823],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600011',
        NAME: 'Wayland-Cohocton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404922400,
        AWATER: 2689312,
        INTPTLAT: '+42.5655518',
        INTPTLON: '-077.5483511',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.041, 44.5178],
            [-75.0372, 44.5716],
            [-74.9585, 44.5884],
            [-74.7383, 44.5163],
            [-74.685, 44.3427],
            [-74.7375, 44.2769],
            [-74.7198, 44.1494],
            [-74.7786, 44.1414],
            [-74.8103, 44.3327],
            [-74.9232, 44.3263],
            [-74.9422, 44.4653],
            [-75.041, 44.5178],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608100',
        NAME: 'Colton-Pierrepont Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 621126834,
        AWATER: 31790516,
        INTPTLAT: '+44.4115496',
        INTPTLON: '-074.7991649',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1389, 44.9],
            [-75.049, 44.8417],
            [-75.0981, 44.7036],
            [-75.1225, 44.6732],
            [-75.2265, 44.6842],
            [-75.306, 44.8241],
            [-75.1389, 44.9],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618090',
        NAME: 'Madrid-Waddington Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309617830,
        AWATER: 18827221,
        INTPTLAT: '+44.7856408',
        INTPTLON: '-075.1723244',
        ELSDLEA: '',
        UNSDLEA: '18090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.58, 44.6492],
            [-75.4441, 44.7427],
            [-75.4303, 44.6903],
            [-75.5578, 44.6283],
            [-75.58, 44.6492],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621660',
        NAME: 'Ogdensburg City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47305375,
        AWATER: 15717192,
        INTPTLAT: '+44.6655962',
        INTPTLON: '-075.5207638',
        ELSDLEA: '',
        UNSDLEA: '21660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8488, 44.7207],
            [-74.6808, 44.7074],
            [-74.6221, 44.6454],
            [-74.6517, 44.577],
            [-74.6049, 44.4481],
            [-74.6099, 44.348],
            [-74.685, 44.3427],
            [-74.7383, 44.5163],
            [-74.9585, 44.5884],
            [-74.8488, 44.7207],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622440',
        NAME: 'Parishville-Hopkinton Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 563455474,
        AWATER: 5372809,
        INTPTLAT: '+44.5568914',
        INTPTLON: '-074.7266506',
        ELSDLEA: '',
        UNSDLEA: '22440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6142, 44.7524],
            [-74.4819, 44.7493],
            [-74.3911, 44.6903],
            [-74.3307, 44.5474],
            [-74.4543, 44.5386],
            [-74.433, 44.395],
            [-74.6099, 44.348],
            [-74.6049, 44.4481],
            [-74.6517, 44.577],
            [-74.6221, 44.6454],
            [-74.6808, 44.7074],
            [-74.6142, 44.7524],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627990',
        NAME: 'Saint Regis Falls Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713286206,
        AWATER: 6329163,
        INTPTLAT: '+44.5638514',
        INTPTLON: '-074.5240790',
        ELSDLEA: '',
        UNSDLEA: '27990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3747, 42.9443],
            [-77.3076, 42.9532],
            [-77.2002, 42.9335],
            [-77.17, 42.8787],
            [-77.2682, 42.8547],
            [-77.3145, 42.7591],
            [-77.3822, 42.7948],
            [-77.3409, 42.8702],
            [-77.3747, 42.9443],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606330',
        NAME: 'Canandaigua City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208431355,
        AWATER: 15559636,
        INTPTLAT: '+42.8563575',
        INTPTLON: '-077.3034968',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3729, 43.1074],
            [-77.3525, 43.1178],
            [-77.2678, 43.1057],
            [-77.2624, 43.1237],
            [-77.2609, 43.1276],
            [-77.2521, 43.1279],
            [-77.1893, 43.1028],
            [-77.171, 43.0398],
            [-77.1863, 43.0167],
            [-77.3004, 43.0259],
            [-77.3716, 43.0537],
            [-77.3729, 43.1074],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622380',
        NAME: 'Palmyra-Macedon Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125275804,
        AWATER: 489271,
        INTPTLAT: '+43.0699227',
        INTPTLON: '-077.2701946',
        ELSDLEA: '',
        UNSDLEA: '22380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3004, 43.0259],
            [-77.1863, 43.0167],
            [-77.2002, 42.9335],
            [-77.3076, 42.9532],
            [-77.3004, 43.0259],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624270',
        NAME: 'Manchester-Shortsville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88899013,
        AWATER: 0,
        INTPTLAT: '+42.9803437',
        INTPTLON: '-077.2439191',
        ELSDLEA: '',
        UNSDLEA: '24270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6503, 42.954],
            [-77.5842, 43.0337],
            [-77.4848, 43.0119],
            [-77.4825, 42.9432],
            [-77.5674, 42.9319],
            [-77.5519, 42.8541],
            [-77.5881, 42.8307],
            [-77.6666, 42.8783],
            [-77.6503, 42.954],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614700',
        NAME: 'Honeoye Falls-Lima Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186976432,
        AWATER: 1438426,
        INTPTLAT: '+42.9472487',
        INTPTLON: '-077.5767289',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4526, 43.0459],
            [-77.3716, 43.0537],
            [-77.3004, 43.0259],
            [-77.3076, 42.9532],
            [-77.3747, 42.9443],
            [-77.4825, 42.9432],
            [-77.4848, 43.0119],
            [-77.4526, 43.0459],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629640',
        NAME: 'Victor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151192891,
        AWATER: 300156,
        INTPTLAT: '+42.9890598',
        INTPTLON: '-077.3900057',
        ELSDLEA: '',
        UNSDLEA: '29640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4825, 42.9432],
            [-77.3747, 42.9443],
            [-77.3409, 42.8702],
            [-77.3822, 42.7948],
            [-77.4096, 42.7968],
            [-77.5519, 42.8541],
            [-77.5674, 42.9319],
            [-77.4825, 42.9432],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604920',
        NAME: 'East Bloomfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172872074,
        AWATER: 198566,
        INTPTLAT: '+42.8914340',
        INTPTLON: '-077.4552760',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7778, 42.4248],
            [-73.7837, 42.4642],
            [-73.6467, 42.506],
            [-73.6081, 42.482],
            [-73.602, 42.4339],
            [-73.6906, 42.2921],
            [-73.6963, 42.2905],
            [-73.781, 42.3018],
            [-73.7778, 42.4248],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615210',
        NAME: 'Kinderhook Central School District (Ichabod Crane)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202445673,
        AWATER: 9611722,
        INTPTLAT: '+42.4013967',
        INTPTLON: '-073.7100914',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6584, 42.6846],
            [-73.6572, 42.6878],
            [-73.6524, 42.6985],
            [-73.6402, 42.73],
            [-73.4814, 42.7193],
            [-73.4175, 42.6464],
            [-73.4436, 42.5594],
            [-73.5513, 42.5361],
            [-73.6584, 42.6846],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600016',
        NAME: 'Averill Park Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262464487,
        AWATER: 4879003,
        INTPTLAT: '+42.6331311',
        INTPTLON: '-073.5375989',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3906, 43.1102],
            [-73.33, 43.0737],
            [-73.2616, 43.1695],
            [-73.2641, 43.1234],
            [-73.27, 43.0307],
            [-73.2707, 43.0176],
            [-73.3613, 42.9388],
            [-73.4485, 42.9444],
            [-73.5048, 42.9646],
            [-73.3906, 43.1102],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606210',
        NAME: 'Cambridge Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266359067,
        AWATER: 2013804,
        INTPTLAT: '+43.0293154',
        INTPTLON: '-073.3778171',
        ELSDLEA: '',
        UNSDLEA: '06210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7577, 42.9444],
            [-73.7255, 43.0384],
            [-73.5899, 43.0375],
            [-73.5955, 42.9432],
            [-73.6714, 42.9196],
            [-73.7296, 42.9226],
            [-73.7577, 42.9444],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628110',
        NAME: 'Stillwater Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125202649,
        AWATER: 10064276,
        INTPTLAT: '+42.9752818',
        INTPTLON: '-073.6801523',
        ELSDLEA: '',
        UNSDLEA: '28110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5822, 43.189],
            [-73.5132, 43.2906],
            [-73.3951, 43.2816],
            [-73.3941, 43.2797],
            [-73.4039, 43.2697],
            [-73.3954, 43.2236],
            [-73.5468, 43.1726],
            [-73.5822, 43.189],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603210',
        NAME: 'Argyle Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158999469,
        AWATER: 3331430,
        INTPTLAT: '+43.2321535',
        INTPTLON: '-073.4790000',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2898, 43.5778],
            [-73.2456, 43.5395],
            [-73.2488, 43.4618],
            [-73.2508, 43.4087],
            [-73.2546, 43.3147],
            [-73.2557, 43.2791],
            [-73.3407, 43.2851],
            [-73.3535, 43.4213],
            [-73.3734, 43.4712],
            [-73.3015, 43.4852],
            [-73.2898, 43.5778],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612450',
        NAME: 'Granville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214695610,
        AWATER: 2029789,
        INTPTLAT: '+43.4000654',
        INTPTLON: '-073.3122873',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4692, 43.3766],
            [-73.3535, 43.4213],
            [-73.3407, 43.2851],
            [-73.3951, 43.2816],
            [-73.5132, 43.2906],
            [-73.4692, 43.3766],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613830',
        NAME: 'Hartford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155225847,
        AWATER: 417169,
        INTPTLAT: '+43.3557369',
        INTPTLON: '-073.4131564',
        ELSDLEA: '',
        UNSDLEA: '13830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8165, 44.0179],
            [-75.7649, 44.0654],
            [-75.6251, 44.0125],
            [-75.5077, 44.0852],
            [-75.4129, 44.0097],
            [-75.4469, 43.9608],
            [-75.5686, 43.9316],
            [-75.5926, 43.9118],
            [-75.8353, 43.9636],
            [-75.8165, 44.0179],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606630',
        NAME: 'Carthage Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355512996,
        AWATER: 5615692,
        INTPTLAT: '+44.0025936',
        INTPTLON: '-075.6116100',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2405, 43.9427],
            [-76.2171, 43.9865],
            [-76.0456, 44.0741],
            [-75.9901, 44.092],
            [-75.9542, 44.0953],
            [-75.8605, 44.0053],
            [-75.9767, 43.9817],
            [-76.0622, 43.9944],
            [-76.2405, 43.9427],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611910',
        NAME: 'General Brown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211059551,
        AWATER: 36922042,
        INTPTLAT: '+44.0185856',
        INTPTLON: '-076.0321855',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3478, 44.0039],
            [-76.2149, 44.1065],
            [-76.1229, 44.1359],
            [-76.0456, 44.0741],
            [-76.2171, 43.9865],
            [-76.2405, 43.9427],
            [-76.3337, 43.9411],
            [-76.3478, 44.0039],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617880',
        NAME: 'Lyme Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150810585,
        AWATER: 115503234,
        INTPTLAT: '+44.0248012',
        INTPTLON: '-076.2286732',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4129, 44.0097],
            [-75.1641, 44.049],
            [-75.1404, 43.8607],
            [-75.2331, 43.8204],
            [-75.3089, 43.8037],
            [-75.3244, 43.7945],
            [-75.5686, 43.9316],
            [-75.4469, 43.9608],
            [-75.4129, 44.0097],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604200',
        NAME: 'Beaver River Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 601460434,
        AWATER: 11641569,
        INTPTLAT: '+43.9245023',
        INTPTLON: '-075.3255690',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5335, 44.1175],
            [-75.4461, 44.2177],
            [-75.269, 44.24],
            [-75.1945, 44.246],
            [-75.1702, 44.097],
            [-75.1641, 44.049],
            [-75.4129, 44.0097],
            [-75.5077, 44.0852],
            [-75.5335, 44.1175],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613770',
        NAME: 'Harrisville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498211994,
        AWATER: 11755774,
        INTPTLAT: '+44.1313252',
        INTPTLON: '-075.3343158',
        ELSDLEA: '',
        UNSDLEA: '13770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8414, 43.869],
            [-75.79, 43.8287],
            [-75.6316, 43.8074],
            [-75.5926, 43.9118],
            [-75.5686, 43.9316],
            [-75.3244, 43.7945],
            [-75.4357, 43.7542],
            [-75.4485, 43.6891],
            [-75.6322, 43.6553],
            [-75.7695, 43.6446],
            [-75.7746, 43.6889],
            [-75.7868, 43.7883],
            [-75.8414, 43.869],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617820',
        NAME: 'Lowville Academy and Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669026089,
        AWATER: 2983419,
        INTPTLAT: '+43.7648398',
        INTPTLON: '-075.5946956',
        ELSDLEA: '',
        UNSDLEA: '17820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7596, 42.6142],
            [-73.725, 42.6702],
            [-73.7082, 42.6681],
            [-73.7596, 42.6142],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624450',
        NAME: 'Rensselaer City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8240553,
        AWATER: 861899,
        INTPTLAT: '+42.6457345',
        INTPTLON: '-073.7325407',
        ELSDLEA: '',
        UNSDLEA: '24450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0962, 43.8608],
            [-75.987, 43.8961],
            [-75.9781, 43.957],
            [-75.848, 43.9095],
            [-75.8414, 43.869],
            [-75.7868, 43.7883],
            [-75.7746, 43.6889],
            [-76.0251, 43.707],
            [-76.1464, 43.6899],
            [-76.0476, 43.7844],
            [-76.0962, 43.8608],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602340',
        NAME: 'South Jefferson Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 572787356,
        AWATER: 1112904,
        INTPTLAT: '+43.8015872',
        INTPTLON: '-075.9621689',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8353, 43.9636],
            [-75.5926, 43.9118],
            [-75.6316, 43.8074],
            [-75.79, 43.8287],
            [-75.8414, 43.869],
            [-75.848, 43.9095],
            [-75.8353, 43.9636],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608280',
        NAME: 'Copenhagen Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259828880,
        AWATER: 463688,
        INTPTLAT: '+43.8886758',
        INTPTLON: '-075.7123965',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1999, 43.6802],
            [-76.1464, 43.6899],
            [-76.0251, 43.707],
            [-75.7746, 43.6889],
            [-75.7695, 43.6446],
            [-75.7501, 43.469],
            [-75.8146, 43.4836],
            [-75.8908, 43.5517],
            [-76.0255, 43.561],
            [-76.1981, 43.6142],
            [-76.1999, 43.6802],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625650',
        NAME: 'Sandy Creek Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507263089,
        AWATER: 21098825,
        INTPTLAT: '+43.6222261',
        INTPTLON: '-075.9332507',
        ELSDLEA: '',
        UNSDLEA: '25650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3239, 43.8611],
            [-76.2631, 43.9012],
            [-76.1314, 43.904],
            [-76.0962, 43.8608],
            [-76.0476, 43.7844],
            [-76.1464, 43.6899],
            [-76.1999, 43.6802],
            [-76.2411, 43.801],
            [-76.3239, 43.8611],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604370',
        NAME: 'Belleville Henderson Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243453690,
        AWATER: 68580953,
        INTPTLAT: '+43.8011061',
        INTPTLON: '-076.1869146',
        ELSDLEA: '',
        UNSDLEA: '04370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3997, 44.1123],
            [-76.3126, 44.199],
            [-76.1643, 44.2396],
            [-76.071, 44.3158],
            [-76.006, 44.2478],
            [-76.0375, 44.1551],
            [-75.9901, 44.092],
            [-76.0456, 44.0741],
            [-76.1229, 44.1359],
            [-76.2149, 44.1065],
            [-76.3478, 44.0039],
            [-76.3997, 44.1123],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607650',
        NAME: 'Thousand Islands Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358067225,
        AWATER: 141828926,
        INTPTLAT: '+44.1645753',
        INTPTLON: '-076.1722115',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9826, 41.3217],
            [-73.8914, 41.3649],
            [-73.8285, 41.3593],
            [-73.7718, 41.3434],
            [-73.7756, 41.3169],
            [-73.8663, 41.273],
            [-73.8991, 41.2865],
            [-73.9491, 41.2926],
            [-73.9826, 41.3217],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616620',
        NAME: 'Lakeland Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71839682,
        AWATER: 2722209,
        INTPTLAT: '+41.3193417',
        INTPTLON: '-073.8648178',
        ELSDLEA: '',
        UNSDLEA: '16620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.917, 43.0091],
            [-78.8902, 43.022],
            [-78.8512, 43.0193],
            [-78.8514, 43.0119],
            [-78.917, 43.0091],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628740',
        NAME: 'Tonawanda City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9850439,
        AWATER: 745855,
        INTPTLAT: '+43.0119350',
        INTPTLON: '-078.8834425',
        ELSDLEA: '',
        UNSDLEA: '28740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9257, 42.0965],
            [-76.8729, 42.0965],
            [-76.7959, 42.1252],
            [-76.6613, 42.1289],
            [-76.632, 42.1119],
            [-76.7057, 42.0013],
            [-76.7473, 42.0017],
            [-76.9271, 42.0017],
            [-76.9657, 42.0013],
            [-76.9257, 42.0965],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610560',
        NAME: 'Elmira City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290123341,
        AWATER: 3799187,
        INTPTLAT: '+42.0633526',
        INTPTLON: '-076.8060854',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7546, 42.4484],
            [-76.6638, 42.4349],
            [-76.684, 42.3905],
            [-76.6701, 42.2839],
            [-76.6654, 42.2379],
            [-76.8478, 42.2787],
            [-76.8635, 42.3508],
            [-76.7546, 42.4484],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621630',
        NAME: 'Odessa-Montour Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245949516,
        AWATER: 1693224,
        INTPTLAT: '+42.3312546',
        INTPTLON: '-076.7422229',
        ELSDLEA: '',
        UNSDLEA: '21630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9256, 42.1318],
            [-75.8581, 42.1122],
            [-75.8579, 42.1035],
            [-75.9289, 42.0763],
            [-75.9475, 42.0842],
            [-75.9484, 42.0948],
            [-75.9256, 42.1318],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604870',
        NAME: 'Binghamton City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27166192,
        AWATER: 1674691,
        INTPTLAT: '+42.1013306',
        INTPTLON: '-075.9093694',
        ELSDLEA: '',
        UNSDLEA: '04870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9018, 42.1645],
            [-75.8053, 42.1789],
            [-75.809, 42.1378],
            [-75.8581, 42.1122],
            [-75.9256, 42.1318],
            [-75.9018, 42.1645],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607290',
        NAME: 'Chenango Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60031634,
        AWATER: 1530791,
        INTPTLAT: '+42.1528533',
        INTPTLON: '-075.8471262',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9468, 42.0719],
            [-75.9289, 42.0763],
            [-75.8579, 42.1035],
            [-75.7422, 41.9979],
            [-75.7733, 41.998],
            [-75.9936, 41.999],
            [-75.9468, 42.0719],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628380',
        NAME: 'Susquehanna Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162066004,
        AWATER: 2199714,
        INTPTLAT: '+42.0402725',
        INTPTLON: '-075.8635978',
        ELSDLEA: '',
        UNSDLEA: '28380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1303, 42.0631],
            [-76.0332, 42.1],
            [-75.9947, 42.1143],
            [-75.9484, 42.0948],
            [-75.9475, 42.0842],
            [-75.9468, 42.0719],
            [-75.9936, 41.999],
            [-76.1058, 41.9989],
            [-76.1303, 42.0631],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629610',
        NAME: 'Vestal Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133294178,
        AWATER: 2689216,
        INTPTLAT: '+42.0422724',
        INTPTLON: '-076.0286194',
        ELSDLEA: '',
        UNSDLEA: '29610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8752, 42.6485],
            [-73.8472, 42.6486],
            [-73.7597, 42.6138],
            [-73.7522, 42.5674],
            [-73.7522, 42.5667],
            [-73.8919, 42.5868],
            [-73.9755, 42.5427],
            [-74.0007, 42.5566],
            [-74.0073, 42.5936],
            [-73.8752, 42.6485],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3604710',
        NAME: 'Bethlehem Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117320973,
        AWATER: 1187735,
        INTPTLAT: '+42.5962672',
        INTPTLON: '-073.8739019',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0644, 42.714],
            [-73.967, 42.7567],
            [-73.8778, 42.7194],
            [-73.8472, 42.6486],
            [-73.8752, 42.6485],
            [-74.0311, 42.6781],
            [-74.0644, 42.714],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613080',
        NAME: 'Guilderland Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121703883,
        AWATER: 2083673,
        INTPTLAT: '+42.7066764',
        INTPTLON: '-073.9504820',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9068, 42.7634],
            [-73.893, 42.7827],
            [-73.8236, 42.7569],
            [-73.7623, 42.679],
            [-73.8743, 42.7207],
            [-73.9068, 42.7634],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627210',
        NAME: 'South Colonie Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56082940,
        AWATER: 221080,
        INTPTLAT: '+42.7348284',
        INTPTLON: '-073.8322772',
        ELSDLEA: '',
        UNSDLEA: '27210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8752, 42.6485],
            [-74.0073, 42.5936],
            [-74.0311, 42.6781],
            [-73.8752, 42.6485],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629670',
        NAME: 'Voorheesville Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94195705,
        AWATER: 1263419,
        INTPTLAT: '+42.6449439',
        INTPTLON: '-073.9528780',
        ELSDLEA: '',
        UNSDLEA: '29670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6993, 42.7363],
            [-73.6947, 42.7321],
            [-73.7025, 42.7],
            [-73.721, 42.7087],
            [-73.6993, 42.7363],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630210',
        NAME: 'Watervliet City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4738250,
        AWATER: 438343,
        INTPTLAT: '+42.7213576',
        INTPTLON: '-073.7083595',
        ELSDLEA: '',
        UNSDLEA: '30210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.632, 42.1119],
            [-76.5424, 42.1555],
            [-76.4715, 42.1264],
            [-76.473, 41.9991],
            [-76.4734, 41.9991],
            [-76.5234, 41.9997],
            [-76.5577, 42.0002],
            [-76.7057, 42.0013],
            [-76.632, 42.1119],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630270',
        NAME: 'Waverly Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221645721,
        AWATER: 2050148,
        INTPTLAT: '+42.0598622',
        INTPTLON: '-076.5758553',
        ELSDLEA: '',
        UNSDLEA: '30270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8952, 42.283],
            [-76.8478, 42.2787],
            [-76.6654, 42.2379],
            [-76.6613, 42.1289],
            [-76.7959, 42.1252],
            [-76.8616, 42.1498],
            [-76.8729, 42.0965],
            [-76.9257, 42.0965],
            [-76.9654, 42.2236],
            [-76.8952, 42.283],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614850',
        NAME: 'Horseheads Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360608720,
        AWATER: 2301848,
        INTPTLAT: '+42.1963729',
        INTPTLON: '-076.8147599',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6302, 41.6398],
            [-73.521, 41.6198],
            [-73.5307, 41.5202],
            [-73.531, 41.5172],
            [-73.5632, 41.5119],
            [-73.7052, 41.5525],
            [-73.7095, 41.5576],
            [-73.6302, 41.6398],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622530',
        NAME: 'Pawling Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131805055,
        AWATER: 3318851,
        INTPTLAT: '+41.5660869',
        INTPTLON: '-073.6022530',
        ELSDLEA: '',
        UNSDLEA: '22530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6895, 41.3519],
            [-73.6625, 41.364],
            [-73.6584, 41.3643],
            [-73.5437, 41.3743],
            [-73.5499, 41.3015],
            [-73.675, 41.3088],
            [-73.6895, 41.3519],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621180',
        NAME: 'North Salem Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68324502,
        AWATER: 4577912,
        INTPTLAT: '+41.3405273',
        INTPTLON: '-073.5976089',
        ELSDLEA: '',
        UNSDLEA: '21180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9074, 41.5089],
            [-73.8384, 41.4783],
            [-73.8347, 41.4542],
            [-73.8677, 41.4093],
            [-73.9514, 41.399],
            [-73.9745, 41.428],
            [-73.9815, 41.4389],
            [-73.9074, 41.5089],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613230',
        NAME: 'Haldane Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86767690,
        AWATER: 4140216,
        INTPTLAT: '+41.4619740',
        INTPTLON: '-073.8749084',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7736, 41.4126],
            [-73.6625, 41.364],
            [-73.6895, 41.3519],
            [-73.7718, 41.3434],
            [-73.8285, 41.3593],
            [-73.7736, 41.4126],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618120',
        NAME: 'Mahopac Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72036871,
        AWATER: 8095257,
        INTPTLAT: '+41.3830275',
        INTPTLON: '-073.7371717',
        ELSDLEA: '',
        UNSDLEA: '18120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4715, 42.1264],
            [-76.4191, 42.164],
            [-76.3115, 42.154],
            [-76.3427, 42.0498],
            [-76.295, 41.9984],
            [-76.3815, 41.9985],
            [-76.473, 41.9991],
            [-76.4715, 42.1264],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628710',
        NAME: 'Tioga Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238492861,
        AWATER: 3412616,
        INTPTLAT: '+42.0791671',
        INTPTLON: '-076.3906790',
        ELSDLEA: '',
        UNSDLEA: '28710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7959, 42.1252],
            [-76.8729, 42.0965],
            [-76.8616, 42.1498],
            [-76.7959, 42.1252],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610590',
        NAME: 'Elmira Heights Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36921070,
        AWATER: 188361,
        INTPTLAT: '+42.1255652',
        INTPTLON: '-076.8545037',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8956, 42.5415],
            [-76.7912, 42.5346],
            [-76.7546, 42.4484],
            [-76.8635, 42.3508],
            [-76.8478, 42.2787],
            [-76.8952, 42.283],
            [-76.9879, 42.3108],
            [-77.0499, 42.4023],
            [-76.8898, 42.4631],
            [-76.8956, 42.5415],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630240',
        NAME: 'Watkins Glen Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341222064,
        AWATER: 27549978,
        INTPTLAT: '+42.4111563',
        INTPTLON: '-076.8945694',
        ELSDLEA: '',
        UNSDLEA: '30240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.711, 43.6331],
            [-73.6327, 43.6784],
            [-73.5253, 43.6361],
            [-73.5841, 43.573],
            [-73.619, 43.5206],
            [-73.7032, 43.5065],
            [-73.711, 43.6331],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605010',
        NAME: 'Bolton Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127477170,
        AWATER: 45534147,
        INTPTLAT: '+43.5929255',
        INTPTLON: '-073.6394690',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8654, 43.7754],
            [-73.8677, 43.7667],
            [-73.8687, 43.7628],
            [-73.6382, 43.7849],
            [-73.6327, 43.6784],
            [-73.711, 43.6331],
            [-73.8614, 43.591],
            [-73.9662, 43.7535],
            [-73.8654, 43.7754],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616960',
        NAME: 'North Warren Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362756661,
        AWATER: 21762422,
        INTPTLAT: '+43.6925053',
        INTPTLON: '-073.7858677',
        ELSDLEA: '',
        UNSDLEA: '16960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1724, 43.4538],
            [-73.9632, 43.4831],
            [-73.8743, 43.4249],
            [-73.7551, 43.4248],
            [-73.7408, 43.3759],
            [-73.7404, 43.2501],
            [-73.7635, 43.2254],
            [-73.8261, 43.2761],
            [-73.9854, 43.2558],
            [-74.048, 43.3635],
            [-74.1601, 43.3715],
            [-74.1724, 43.4538],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613110',
        NAME: 'Hadley-Luzerne Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538257471,
        AWATER: 20350181,
        INTPTLAT: '+43.3682589',
        INTPTLON: '-073.9386174',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7551, 43.4248],
            [-73.7032, 43.5065],
            [-73.619, 43.5206],
            [-73.6117, 43.3944],
            [-73.7408, 43.3759],
            [-73.7551, 43.4248],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616470',
        NAME: 'Lake George Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124796779,
        AWATER: 31572211,
        INTPTLAT: '+43.4031070',
        INTPTLON: '-073.6709863',
        ELSDLEA: '',
        UNSDLEA: '16470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.019, 41.1121],
            [-73.9906, 41.0788],
            [-73.9956, 41.0769],
            [-74.0335, 41.0702],
            [-74.019, 41.1121],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620190',
        NAME: 'Nanuet Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13274650,
        AWATER: 8134,
        INTPTLAT: '+41.0889894',
        INTPTLON: '-074.0146544',
        ELSDLEA: '',
        UNSDLEA: '20190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8874, 41.1369],
            [-73.8876, 41.1288],
            [-73.8948, 41.0705],
            [-73.9467, 41.0857],
            [-73.8874, 41.1369],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621480',
        NAME: 'Nyack Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18821528,
        AWATER: 13696833,
        INTPTLAT: '+41.1065658',
        INTPTLON: '-073.9226377',
        ELSDLEA: '',
        UNSDLEA: '21480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.967, 41.0783],
            [-73.9467, 41.0857],
            [-73.8948, 41.0705],
            [-73.8945, 41.0646],
            [-73.8899, 41.0257],
            [-73.8936, 41.0053],
            [-73.894, 40.9972],
            [-73.9221, 41.0059],
            [-73.9384, 41.0133],
            [-73.959, 41.0225],
            [-73.9665, 41.026],
            [-73.967, 41.0783],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627450',
        NAME: 'South Orangetown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36637488,
        AWATER: 12634560,
        INTPTLAT: '+41.0460191',
        INTPTLON: '-073.9321456',
        ELSDLEA: '',
        UNSDLEA: '27450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.725, 42.6702],
            [-73.7233, 42.6729],
            [-73.7065, 42.6941],
            [-73.6627, 42.6972],
            [-73.6572, 42.6878],
            [-73.6584, 42.6846],
            [-73.7082, 42.6681],
            [-73.725, 42.6702],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631410',
        NAME: 'North Greenbush Common School District (Williams)',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6942839,
        AWATER: 370095,
        INTPTLAT: '+42.6863669',
        INTPTLON: '-073.7000521',
        ELSDLEA: '',
        UNSDLEA: '31410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6627, 42.6972],
            [-73.6524, 42.6985],
            [-73.6572, 42.6878],
            [-73.6627, 42.6972],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631830',
        NAME: 'Wynantskill Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7732264,
        AWATER: 12723,
        INTPTLAT: '+42.6867352',
        INTPTLON: '-073.6232467',
        ELSDLEA: '',
        UNSDLEA: '31830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7065, 42.6941],
            [-73.7025, 42.7],
            [-73.6947, 42.7321],
            [-73.6864, 42.754],
            [-73.6455, 42.7526],
            [-73.6402, 42.73],
            [-73.6524, 42.6985],
            [-73.6627, 42.6972],
            [-73.7065, 42.6941],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628950',
        NAME: 'Troy City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26625607,
        AWATER: 1075211,
        INTPTLAT: '+42.7273590',
        INTPTLON: '-073.6705864',
        ELSDLEA: '',
        UNSDLEA: '28950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6714, 42.9196],
            [-73.5955, 42.9432],
            [-73.5208, 42.9567],
            [-73.5048, 42.9646],
            [-73.4485, 42.9444],
            [-73.4727, 42.856],
            [-73.6075, 42.8184],
            [-73.6401, 42.8252],
            [-73.6714, 42.9196],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614730',
        NAME: 'Hoosic Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181781144,
        AWATER: 8686922,
        INTPTLAT: '+42.8956506',
        INTPTLON: '-073.5521209',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2863, 43.2068],
            [-74.2126, 43.1296],
            [-74.207, 43.0712],
            [-74.3106, 43.0488],
            [-74.3311, 43.0742],
            [-74.2863, 43.2068],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618750',
        NAME: 'Mayfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121186268,
        AWATER: 16217332,
        INTPTLAT: '+43.1171629',
        INTPTLON: '-074.2689682',
        ELSDLEA: '',
        UNSDLEA: '18750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1358, 42.4899],
            [-75.0504, 42.5402],
            [-74.9468, 42.5037],
            [-74.9687, 42.4684],
            [-74.9881, 42.4279],
            [-75.1148, 42.4064],
            [-75.1358, 42.4899],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621780',
        NAME: 'Oneonta City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126828033,
        AWATER: 746501,
        INTPTLAT: '+42.4608823',
        INTPTLON: '-075.0503693',
        ELSDLEA: '',
        UNSDLEA: '21780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2079, 42.5027],
            [-75.1358, 42.4899],
            [-75.1148, 42.4064],
            [-75.2212, 42.3247],
            [-75.3227, 42.3103],
            [-75.3854, 42.3623],
            [-75.2536, 42.4178],
            [-75.2079, 42.5027],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629240',
        NAME: 'Otego-Unadilla Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242691444,
        AWATER: 1939531,
        INTPTLAT: '+42.3944035',
        INTPTLON: '-075.2351099',
        ELSDLEA: '',
        UNSDLEA: '29240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0437, 42.7445],
            [-74.9423, 42.8017],
            [-74.8636, 42.7376],
            [-74.9106, 42.6591],
            [-75.0108, 42.6194],
            [-75.0762, 42.6533],
            [-75.0437, 42.7445],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608250',
        NAME: 'Cooperstown Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261172816,
        AWATER: 12150335,
        INTPTLAT: '+42.7040986',
        INTPTLON: '-074.9659373',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2159, 42.6528],
            [-75.1162, 42.6433],
            [-75.2058, 42.5065],
            [-75.3254, 42.5222],
            [-75.2159, 42.6528],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619860',
        NAME: 'Morris Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173143623,
        AWATER: 883051,
        INTPTLAT: '+42.5838006',
        INTPTLON: '-075.2215736',
        ELSDLEA: '',
        UNSDLEA: '19860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9881, 42.4279],
            [-74.9687, 42.4684],
            [-74.7749, 42.5257],
            [-74.6815, 42.5537],
            [-74.6396, 42.5488],
            [-74.7702, 42.4516],
            [-74.8024, 42.4349],
            [-74.8574, 42.3975],
            [-74.9827, 42.4163],
            [-74.9881, 42.4279],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607050',
        NAME: 'Charlotte Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197719862,
        AWATER: 1152903,
        INTPTLAT: '+42.4595538',
        INTPTLON: '-074.8319185',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3276, 44.3439],
            [-75.2383, 44.3726],
            [-75.1693, 44.5041],
            [-75.041, 44.5178],
            [-74.9422, 44.4653],
            [-74.9232, 44.3263],
            [-74.9156, 44.2635],
            [-75.0487, 44.2547],
            [-75.1319, 44.3018],
            [-75.1945, 44.246],
            [-75.269, 44.24],
            [-75.3276, 44.3439],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600003',
        NAME: 'Edwards-Knox Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653224877,
        AWATER: 5608478,
        INTPTLAT: '+44.3867667',
        INTPTLON: '-075.0986353',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9687, 42.4684],
            [-74.9468, 42.5037],
            [-74.8562, 42.6489],
            [-74.7401, 42.6832],
            [-74.7478, 42.6653],
            [-74.7535, 42.6491],
            [-74.7996, 42.6227],
            [-74.7749, 42.5257],
            [-74.9687, 42.4684],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603060',
        NAME: 'Schenevus Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196494348,
        AWATER: 494201,
        INTPTLAT: '+42.5844043',
        INTPTLON: '-074.8372883',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7535, 42.6491],
            [-74.7478, 42.6653],
            [-74.7401, 42.6832],
            [-74.668, 42.6928],
            [-74.6306, 42.6267],
            [-74.6815, 42.5537],
            [-74.7749, 42.5257],
            [-74.7996, 42.6227],
            [-74.7535, 42.6491],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631740',
        NAME: 'Worcester Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157798433,
        AWATER: 885540,
        INTPTLAT: '+42.6099990',
        INTPTLON: '-074.7247492',
        ELSDLEA: '',
        UNSDLEA: '31740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6665, 42.6235],
            [-76.4689, 42.6177],
            [-76.4419, 42.5389],
            [-76.4506, 42.479],
            [-76.5777, 42.5456],
            [-76.6381, 42.5844],
            [-76.6665, 42.6235],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616710',
        NAME: 'Lansing Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141815569,
        AWATER: 22545348,
        INTPTLAT: '+42.5638114',
        INTPTLON: '-076.5422467',
        ELSDLEA: '',
        UNSDLEA: '16710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.684, 42.3905],
            [-76.5362, 42.3939],
            [-76.5054, 42.3082],
            [-76.6701, 42.2839],
            [-76.684, 42.3905],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620790',
        NAME: 'Newfield Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157651004,
        AWATER: 414081,
        INTPTLAT: '+42.3371404',
        INTPTLON: '-076.6102451',
        ELSDLEA: '',
        UNSDLEA: '20790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2212, 42.3247],
            [-75.1148, 42.4064],
            [-74.9881, 42.4279],
            [-74.9827, 42.4163],
            [-75.1187, 42.3352],
            [-75.0684, 42.2801],
            [-75.1836, 42.2615],
            [-75.2212, 42.3247],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611430',
        NAME: 'Franklin Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171865992,
        AWATER: 524454,
        INTPTLAT: '+42.3523469',
        INTPTLON: '-075.1097412',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5353, 42.1149],
            [-75.5171, 42.1219],
            [-75.452, 42.1821],
            [-75.4028, 42.1772],
            [-75.332, 42.2044],
            [-75.2898, 42.1691],
            [-75.323, 42.0785],
            [-75.3596, 41.9994],
            [-75.4831, 41.9992],
            [-75.5388, 41.9991],
            [-75.5353, 42.1149],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609060',
        NAME: 'Deposit Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 306004237,
        AWATER: 7094950,
        INTPTLAT: '+42.0910816',
        INTPTLON: '-075.4268767',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.323, 42.0785],
            [-75.1485, 42.0717],
            [-75.1155, 41.9606],
            [-75.0808, 41.9511],
            [-75.0843, 41.9441],
            [-75.1693, 41.8705],
            [-75.2713, 41.8874],
            [-75.3596, 41.9994],
            [-75.323, 42.0785],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613560',
        NAME: 'Hancock Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341699248,
        AWATER: 6043621,
        INTPTLAT: '+41.9743964',
        INTPTLON: '-075.2398394',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.1015, 41.8094],
            [-73.9677, 41.8197],
            [-74.0727, 41.6878],
            [-74.2597, 41.7027],
            [-74.2722, 41.7279],
            [-74.1015, 41.8094],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620460',
        NAME: 'New Paltz Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211342619,
        AWATER: 2705697,
        INTPTLAT: '+41.7433857',
        INTPTLON: '-074.1235717',
        ELSDLEA: '',
        UNSDLEA: '20460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8875, 42.2002],
            [-74.7803, 42.2541],
            [-74.7313, 42.2233],
            [-74.7188, 42.0738],
            [-74.8102, 42.0291],
            [-74.8336, 42.0391],
            [-74.8915, 42.1338],
            [-74.8875, 42.2002],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603000',
        NAME: 'Andes Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238699331,
        AWATER: 9244417,
        INTPTLAT: '+42.1459512',
        INTPTLON: '-074.8003381',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8336, 42.0391],
            [-74.8102, 42.0291],
            [-74.898, 41.8767],
            [-75.0216, 41.8757],
            [-75.0808, 41.9511],
            [-75.1155, 41.9606],
            [-75.1002, 41.9941],
            [-74.8336, 42.0391],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625020',
        NAME: 'Roscoe Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276193393,
        AWATER: 3570505,
        INTPTLAT: '+41.9633899',
        INTPTLON: '-074.9577928',
        ELSDLEA: '',
        UNSDLEA: '25020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8857, 42.8635],
            [-74.7301, 42.8974],
            [-74.6737, 42.8831],
            [-74.6679, 42.8352],
            [-74.6715, 42.7275],
            [-74.668, 42.6928],
            [-74.7401, 42.6832],
            [-74.8562, 42.6489],
            [-74.9106, 42.6591],
            [-74.8636, 42.7376],
            [-74.9423, 42.8017],
            [-74.8857, 42.8635],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600006',
        NAME: 'Cherry Valley-Springfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 362096838,
        AWATER: 6942264,
        INTPTLAT: '+42.7736010',
        INTPTLON: '-074.7828049',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4428, 43.0589],
            [-74.424, 43.0537],
            [-74.3417, 43.0352],
            [-74.2907, 42.996],
            [-74.2893, 42.9844],
            [-74.4888, 42.9851],
            [-74.5781, 42.9912],
            [-74.4428, 43.0589],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615980',
        NAME: 'Johnstown City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117809854,
        AWATER: 339558,
        INTPTLAT: '+43.0169629',
        INTPTLON: '-074.4286474',
        ELSDLEA: '',
        UNSDLEA: '15980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2075, 42.8366],
            [-74.0848, 42.8969],
            [-73.9488, 42.8199],
            [-73.9832, 42.8017],
            [-73.967, 42.7567],
            [-74.0644, 42.714],
            [-74.097, 42.7336],
            [-74.1088, 42.7976],
            [-74.2075, 42.8366],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625980',
        NAME: 'Schalmont Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201234232,
        AWATER: 4123523,
        INTPTLAT: '+42.8177124',
        INTPTLON: '-074.0776458',
        ELSDLEA: '',
        UNSDLEA: '25980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8354, 43.0585],
            [-78.8313, 43.0502],
            [-78.8512, 43.0193],
            [-78.8902, 43.022],
            [-78.9059, 43.0516],
            [-78.8354, 43.0585],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621240',
        NAME: 'North Tonawanda City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26159690,
        AWATER: 2077523,
        INTPTLAT: '+43.0491570',
        INTPTLON: '-078.8712242',
        ELSDLEA: '',
        UNSDLEA: '21240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6115, 43.3935],
            [-73.4692, 43.3766],
            [-73.5132, 43.2906],
            [-73.5822, 43.189],
            [-73.5814, 43.2158],
            [-73.5871, 43.2588],
            [-73.5573, 43.2627],
            [-73.5739, 43.2792],
            [-73.6004, 43.2721],
            [-73.5995, 43.3067],
            [-73.6115, 43.3935],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614970',
        NAME: 'Hudson Falls Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134678100,
        AWATER: 2232187,
        INTPTLAT: '+43.3298985',
        INTPTLON: '-073.5432999',
        ELSDLEA: '',
        UNSDLEA: '14970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.7408, 43.3759],
            [-73.6117, 43.3944],
            [-73.6115, 43.3935],
            [-73.5995, 43.3067],
            [-73.617, 43.3053],
            [-73.6216, 43.3168],
            [-73.6659, 43.2909],
            [-73.7404, 43.2501],
            [-73.7408, 43.3759],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3624030',
        NAME: 'Queensbury Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95491407,
        AWATER: 3440942,
        INTPTLAT: '+43.3275352',
        INTPTLON: '-073.6887566',
        ELSDLEA: '',
        UNSDLEA: '24030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6964, 42.0745],
            [-78.5803, 42.1512],
            [-78.4937, 42.2077],
            [-78.4723, 42.2168],
            [-78.4632, 42.1088],
            [-78.4444, 42.0219],
            [-78.4442, 41.9997],
            [-78.5331, 41.9999],
            [-78.6973, 41.9988],
            [-78.6964, 42.0745],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600015',
        NAME: 'Allegany-Limestone Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309552529,
        AWATER: 2301517,
        INTPTLAT: '+42.0508720',
        INTPTLON: '-078.5371731',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5554, 42.3711],
            [-78.4309, 42.4282],
            [-78.3666, 42.4114],
            [-78.3211, 42.2724],
            [-78.3629, 42.2501],
            [-78.4723, 42.2168],
            [-78.4937, 42.2077],
            [-78.4936, 42.216],
            [-78.4937, 42.2278],
            [-78.5283, 42.253],
            [-78.5836, 42.3221],
            [-78.5554, 42.3711],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611490',
        NAME: 'Franklinville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 330473686,
        AWATER: 960005,
        INTPTLAT: '+42.3114654',
        INTPTLON: '-078.4389139',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7246, 42.4447],
            [-78.576, 42.4774],
            [-78.5554, 42.3711],
            [-78.5836, 42.3221],
            [-78.6885, 42.3578],
            [-78.7246, 42.4447],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630900',
        NAME: 'West Valley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165795665,
        AWATER: 703733,
        INTPTLAT: '+42.4129936',
        INTPTLON: '-078.6282225',
        ELSDLEA: '',
        UNSDLEA: '30900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5111, 43.3768],
            [-76.3621, 43.421],
            [-76.3224, 43.3545],
            [-76.3528, 43.2816],
            [-76.4529, 43.26],
            [-76.5111, 43.3768],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3611700',
        NAME: 'Fulton City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171370822,
        AWATER: 8369661,
        INTPTLAT: '+43.3443747',
        INTPTLON: '-076.4131019',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6753, 43.3616],
            [-76.6265, 43.4124],
            [-76.5111, 43.3768],
            [-76.4529, 43.26],
            [-76.4509, 43.2285],
            [-76.4792, 43.2275],
            [-76.6313, 43.2613],
            [-76.6753, 43.3616],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3613590',
        NAME: 'Hannibal Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215945019,
        AWATER: 1250796,
        INTPTLAT: '+43.3171031',
        INTPTLON: '-076.5636272',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3224, 43.3545],
            [-76.2488, 43.337],
            [-76.203, 43.2234],
            [-76.2149, 43.2054],
            [-76.2552, 43.1894],
            [-76.4509, 43.2285],
            [-76.4529, 43.26],
            [-76.3528, 43.2816],
            [-76.3224, 43.3545],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3622920',
        NAME: 'Phoenix Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183523587,
        AWATER: 4523252,
        INTPTLAT: '+43.2693471',
        INTPTLON: '-076.3076470',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5153, 42.841],
            [-76.4726, 42.8422],
            [-76.357, 42.8494],
            [-76.2921, 42.8009],
            [-76.2701, 42.6992],
            [-76.3141, 42.6729],
            [-76.4589, 42.6274],
            [-76.4658, 42.7569],
            [-76.5153, 42.841],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619800',
        NAME: 'Moravia Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324279524,
        AWATER: 14071420,
        INTPTLAT: '+42.7555850',
        INTPTLON: '-076.3872778',
        ELSDLEA: '',
        UNSDLEA: '19800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5007, 42.9606],
            [-76.4941, 42.99],
            [-76.405, 42.9942],
            [-76.3499, 42.875],
            [-76.2596, 42.8199],
            [-76.2921, 42.8009],
            [-76.357, 42.8494],
            [-76.4726, 42.8422],
            [-76.5007, 42.9606],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626940',
        NAME: 'Skaneateles Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173961150,
        AWATER: 26333549,
        INTPTLAT: '+42.8910396',
        INTPTLON: '-076.4138846',
        ELSDLEA: '',
        UNSDLEA: '26940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7291, 42.8081],
            [-76.738, 42.9613],
            [-76.5967, 42.9531],
            [-76.5389, 42.844],
            [-76.7402, 42.7829],
            [-76.7291, 42.8081],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629250',
        NAME: 'Union Springs Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183963911,
        AWATER: 23313235,
        INTPTLAT: '+42.8879399',
        INTPTLON: '-076.6570454',
        ELSDLEA: '',
        UNSDLEA: '29250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4439, 43.1295],
            [-78.304, 43.1423],
            [-78.234, 43.1251],
            [-78.206, 43.0219],
            [-78.2309, 43.0182],
            [-78.4126, 43.0312],
            [-78.449, 43.1183],
            [-78.4439, 43.1295],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621510',
        NAME: 'Oakfield-Alabama Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179523996,
        AWATER: 2468711,
        INTPTLAT: '+43.0788476',
        INTPTLON: '-078.3231392',
        ELSDLEA: '',
        UNSDLEA: '21510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6365, 43.2498],
            [-78.4747, 43.2704],
            [-78.4439, 43.1295],
            [-78.449, 43.1183],
            [-78.6188, 43.1364],
            [-78.6593, 43.1973],
            [-78.6365, 43.2498],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625140',
        NAME: 'Royalton-Hartland Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206510162,
        AWATER: 874887,
        INTPTLAT: '+43.1865239',
        INTPTLON: '-078.5475902',
        ELSDLEA: '',
        UNSDLEA: '25140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2309, 43.0182],
            [-78.206, 43.0219],
            [-78.1488, 43.0293],
            [-78.1133, 43.0069],
            [-78.094, 42.9686],
            [-78.1434, 42.9574],
            [-78.2502, 42.9677],
            [-78.2309, 43.0182],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603990',
        NAME: 'Batavia City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65281296,
        AWATER: 812420,
        INTPTLAT: '+42.9897233',
        INTPTLON: '-078.1773166',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.234, 43.1251],
            [-78.1155, 43.1315],
            [-78.1488, 43.0293],
            [-78.206, 43.0219],
            [-78.234, 43.1251],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610380',
        NAME: 'Elba Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111409196,
        AWATER: 106233,
        INTPTLAT: '+43.0857591',
        INTPTLON: '-078.1680026',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1133, 43.0069],
            [-77.9422, 43.0271],
            [-77.9342, 42.9456],
            [-78.094, 42.9686],
            [-78.1133, 43.0069],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3616950',
        NAME: 'Le Roy Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128307693,
        AWATER: 319222,
        INTPTLAT: '+42.9860476',
        INTPTLON: '-078.0086684',
        ELSDLEA: '',
        UNSDLEA: '16950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4747, 43.2704],
            [-78.4477, 43.283],
            [-78.2981, 43.2627],
            [-78.304, 43.1423],
            [-78.4439, 43.1295],
            [-78.4747, 43.2704],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618960',
        NAME: 'Medina Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218995750,
        AWATER: 1446660,
        INTPTLAT: '+43.2003729',
        INTPTLON: '-078.3816418',
        ELSDLEA: '',
        UNSDLEA: '18960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.974, 42.3915],
            [-78.8929, 42.3802],
            [-78.8295, 42.4487],
            [-78.7246, 42.4447],
            [-78.6885, 42.3578],
            [-78.7689, 42.3139],
            [-78.6965, 42.2267],
            [-78.857, 42.1996],
            [-78.9453, 42.2605],
            [-78.974, 42.3915],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3606750',
        NAME: 'Cattaraugus-Little Valley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478469753,
        AWATER: 1988683,
        INTPTLAT: '+42.3337857',
        INTPTLON: '-078.8285916',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4632, 42.1088],
            [-78.3904, 42.1089],
            [-78.4444, 42.0219],
            [-78.4632, 42.1088],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3621720',
        NAME: 'Olean City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48074866,
        AWATER: 859816,
        INTPTLAT: '+42.0747373',
        INTPTLON: '-078.4258069',
        ELSDLEA: '',
        UNSDLEA: '21720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4259, 41.4102],
            [-74.3665, 41.4615],
            [-74.3401, 41.4772],
            [-74.3395, 41.4778],
            [-74.3379, 41.4807],
            [-74.2202, 41.4645],
            [-74.2518, 41.3909],
            [-74.3331, 41.34],
            [-74.438, 41.3547],
            [-74.4259, 41.4102],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612330',
        NAME: 'Goshen Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147276286,
        AWATER: 882470,
        INTPTLAT: '+41.4075311',
        INTPTLON: '-074.3349419',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0962, 41.3494],
            [-73.9745, 41.428],
            [-73.9514, 41.399],
            [-73.9814, 41.3247],
            [-74.0607, 41.3269],
            [-74.0962, 41.3494],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614430',
        NAME: 'Highland Falls Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80218915,
        AWATER: 7662087,
        INTPTLAT: '+41.3607293',
        INTPTLON: '-074.0197489',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.4337, 41.4787],
            [-74.3665, 41.4615],
            [-74.4259, 41.4102],
            [-74.4337, 41.4787],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619320',
        NAME: 'Middletown City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71746985,
        AWATER: 1432734,
        INTPTLAT: '+41.4578431',
        INTPTLON: '-074.4230757',
        ELSDLEA: '',
        UNSDLEA: '19320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.495, 41.5335],
            [-74.4337, 41.4787],
            [-74.4259, 41.4102],
            [-74.438, 41.3547],
            [-74.4402, 41.3221],
            [-74.5368, 41.2847],
            [-74.649, 41.3364],
            [-74.674, 41.3477],
            [-74.495, 41.5335],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619560',
        NAME: 'Minisink Valley Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291882912,
        AWATER: 3263090,
        INTPTLAT: '+41.3926767',
        INTPTLON: '-074.5244226',
        ELSDLEA: '',
        UNSDLEA: '19560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.116, 41.5058],
            [-74.1101, 41.5488],
            [-74.0416, 41.5802],
            [-73.9828, 41.5492],
            [-73.9989, 41.463],
            [-74.0987, 41.4803],
            [-74.0985, 41.4808],
            [-74.099, 41.4832],
            [-74.116, 41.5058],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620700',
        NAME: 'Newburgh City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87998210,
        AWATER: 12008858,
        INTPTLAT: '+41.5154019',
        INTPTLON: '-074.0473266',
        ELSDLEA: '',
        UNSDLEA: '20700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2202, 41.4645],
            [-74.116, 41.5058],
            [-74.099, 41.4832],
            [-74.0985, 41.4808],
            [-74.0987, 41.4803],
            [-74.1329, 41.3795],
            [-74.2396, 41.3694],
            [-74.2518, 41.3909],
            [-74.2202, 41.4645],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630030',
        NAME: 'Washingtonville Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144321306,
        AWATER: 2752210,
        INTPTLAT: '+41.4351599',
        INTPTLON: '-074.1815294',
        ELSDLEA: '',
        UNSDLEA: '30030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8233, 42.9724],
            [-78.7782, 42.9783],
            [-78.7636, 42.9492],
            [-78.7983, 42.9493],
            [-78.8238, 42.9581],
            [-78.8233, 42.9724],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3602920',
        NAME: 'Amherst Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13093637,
        AWATER: 3829,
        INTPTLAT: '+42.9616071',
        INTPTLON: '-078.7904499',
        ELSDLEA: '',
        UNSDLEA: '02920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0192, 43.068],
            [-78.9453, 43.067],
            [-78.9059, 43.0516],
            [-78.8902, 43.022],
            [-78.917, 43.0091],
            [-78.9324, 42.9559],
            [-79.0116, 42.9853],
            [-79.0192, 43.068],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607020',
        NAME: 'Grand Island Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73229330,
        AWATER: 13000217,
        INTPTLAT: '+43.0179014',
        INTPTLON: '-078.9626570',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7983, 42.9493],
            [-78.7636, 42.9492],
            [-78.7593, 42.9492],
            [-78.7693, 42.9246],
            [-78.799, 42.9299],
            [-78.7983, 42.9493],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3607680',
        NAME: 'Cleveland Hill Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6459360,
        AWATER: 0,
        INTPTLAT: '+42.9384233',
        INTPTLON: '-078.7817585',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6562, 43.3547],
            [-78.4655, 43.3714],
            [-78.4477, 43.283],
            [-78.4747, 43.2704],
            [-78.6365, 43.2498],
            [-78.6562, 43.3547],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3603960',
        NAME: 'Barker Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196501167,
        AWATER: 617082,
        INTPTLAT: '+43.3177602',
        INTPTLON: '-078.5450881',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.857, 42.1996],
            [-78.6965, 42.2267],
            [-78.5803, 42.1512],
            [-78.6964, 42.0745],
            [-78.6959, 42.1281],
            [-78.8271, 42.1393],
            [-78.857, 42.1996],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3625440',
        NAME: 'Salamanca City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 169671537,
        AWATER: 2630229,
        INTPTLAT: '+42.1508682',
        INTPTLON: '-078.7111563',
        ELSDLEA: '',
        UNSDLEA: '25440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8236, 42.7569],
            [-73.8094, 42.7789],
            [-73.7174, 42.8094],
            [-73.7107, 42.7941],
            [-73.6989, 42.7511],
            [-73.6993, 42.7363],
            [-73.721, 42.7087],
            [-73.7433, 42.6794],
            [-73.7623, 42.679],
            [-73.8236, 42.7569],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600971',
        NAME: 'North Colonie Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76846353,
        AWATER: 3946907,
        INTPTLAT: '+42.7499866',
        INTPTLON: '-073.7545622',
        ELSDLEA: '',
        UNSDLEA: '00971',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0759, 43.2702],
            [-78.9586, 43.2905],
            [-78.8855, 43.1909],
            [-79.0403, 43.1324],
            [-79.0521, 43.1321],
            [-79.0759, 43.2702],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617190',
        NAME: 'Lewiston-Porter Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135062761,
        AWATER: 17471839,
        INTPTLAT: '+43.2154054',
        INTPTLON: '-078.9799773',
        ELSDLEA: '',
        UNSDLEA: '17190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7681, 43.1994],
            [-78.6593, 43.1973],
            [-78.6188, 43.1364],
            [-78.6335, 43.1199],
            [-78.7106, 43.1134],
            [-78.7681, 43.1994],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617670',
        NAME: 'Lockport City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86630873,
        AWATER: 183401,
        INTPTLAT: '+43.1628887',
        INTPTLON: '-078.6880742',
        ELSDLEA: '',
        UNSDLEA: '17670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7544, 43.3371],
            [-78.6562, 43.3547],
            [-78.6365, 43.2498],
            [-78.6593, 43.1973],
            [-78.7681, 43.1994],
            [-78.7783, 43.2009],
            [-78.7544, 43.3371],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620760',
        NAME: 'Newfane Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146911573,
        AWATER: 3978016,
        INTPTLAT: '+43.2763018',
        INTPTLON: '-078.6933455',
        ELSDLEA: '',
        UNSDLEA: '20760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0403, 43.1324],
            [-78.8855, 43.1909],
            [-78.8766, 43.191],
            [-78.8354, 43.0585],
            [-78.9059, 43.0516],
            [-78.9453, 43.067],
            [-79.0403, 43.1324],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620850',
        NAME: 'Niagara-Wheatfield Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131012560,
        AWATER: 4612596,
        INTPTLAT: '+43.1239664',
        INTPTLON: '-078.9279930',
        ELSDLEA: '',
        UNSDLEA: '20850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9586, 43.2905],
            [-78.7544, 43.3371],
            [-78.7783, 43.2009],
            [-78.8766, 43.191],
            [-78.8855, 43.1909],
            [-78.9586, 43.2905],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3631560',
        NAME: 'Wilson Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175687050,
        AWATER: 7433599,
        INTPTLAT: '+43.2573985',
        INTPTLON: '-078.8340525',
        ELSDLEA: '',
        UNSDLEA: '31560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.721, 42.8761],
            [-78.7147, 42.9137],
            [-78.6969, 42.8638],
            [-78.721, 42.8761],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3609030',
        NAME: 'Depew Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13424086,
        AWATER: 0,
        INTPTLAT: '+42.8950136',
        INTPTLON: '-078.7001189',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8766, 43.191],
            [-78.7783, 43.2009],
            [-78.7681, 43.1994],
            [-78.7106, 43.1134],
            [-78.6335, 43.1199],
            [-78.6152, 43.0845],
            [-78.6818, 43.0803],
            [-78.7869, 43.0666],
            [-78.8313, 43.0502],
            [-78.8354, 43.0585],
            [-78.8766, 43.191],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628050',
        NAME: 'Starpoint Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179648670,
        AWATER: 756976,
        INTPTLAT: '+43.1287201',
        INTPTLON: '-078.7759107',
        ELSDLEA: '',
        UNSDLEA: '28050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2476, 41.2294],
            [-74.151, 41.2035],
            [-74.2345, 41.1429],
            [-74.28, 41.163],
            [-74.2802, 41.1631],
            [-74.2476, 41.2294],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629190',
        NAME: 'Tuxedo Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61778601,
        AWATER: 3601168,
        INTPTLAT: '+41.1992122',
        INTPTLON: '-074.2245453',
        ELSDLEA: '',
        UNSDLEA: '29190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.0987, 41.4803],
            [-73.9989, 41.463],
            [-73.9815, 41.4389],
            [-73.9745, 41.428],
            [-74.0962, 41.3494],
            [-74.1329, 41.3795],
            [-74.0987, 41.4803],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608430',
        NAME: 'Cornwall Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90375611,
        AWATER: 5816056,
        INTPTLAT: '+41.4238906',
        INTPTLON: '-074.0608090',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7524, 41.494],
            [-74.6173, 41.5673],
            [-74.4781, 41.5504],
            [-74.495, 41.5335],
            [-74.674, 41.3477],
            [-74.6949, 41.3574],
            [-74.7369, 41.4294],
            [-74.7524, 41.494],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623520',
        NAME: 'Port Jervis City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264670804,
        AWATER: 7632103,
        INTPTLAT: '+41.4817038',
        INTPTLON: '-074.6483567',
        ELSDLEA: '',
        UNSDLEA: '23520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6944, 40.9031],
            [-73.6131, 40.9508],
            [-73.5926, 40.8568],
            [-73.6711, 40.8593],
            [-73.6944, 40.9031],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612180',
        NAME: 'Glen Cove City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17236273,
        AWATER: 32606059,
        INTPTLAT: '+40.8832609',
        INTPTLON: '-073.6448705',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.758, 40.8455],
            [-73.7116, 40.8171],
            [-73.6882, 40.7791],
            [-73.6871, 40.7549],
            [-73.7, 40.7394],
            [-73.758, 40.8455],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3612510',
        NAME: 'Great Neck Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28849749,
        AWATER: 11730749,
        INTPTLAT: '+40.7989089',
        INTPTLON: '-073.7336528',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6412, 40.7131],
            [-73.609, 40.7203],
            [-73.6163, 40.6906],
            [-73.637, 40.6839],
            [-73.6412, 40.7131],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3614130',
        NAME: 'Hempstead Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8077318,
        AWATER: 12386,
        INTPTLAT: '+40.7038292',
        INTPTLON: '-073.6232898',
        ELSDLEA: '',
        UNSDLEA: '14130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6696, 41.7778],
            [-74.5435, 41.7789],
            [-74.4586, 41.7075],
            [-74.4942, 41.6235],
            [-74.6536, 41.682],
            [-74.7045, 41.734],
            [-74.6696, 41.7778],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3610950',
        NAME: 'Fallsburg Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183086746,
        AWATER: 3882198,
        INTPTLAT: '+41.7184025',
        INTPTLON: '-074.5799647',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1693, 41.8705],
            [-75.0843, 41.9441],
            [-75.0808, 41.9511],
            [-75.0216, 41.8757],
            [-74.898, 41.8767],
            [-74.8472, 41.851],
            [-74.8415, 41.749],
            [-74.8897, 41.7189],
            [-74.8954, 41.6139],
            [-75.0016, 41.5075],
            [-75.0696, 41.6019],
            [-75.0532, 41.7517],
            [-75.1693, 41.8705],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3600023',
        NAME: 'Sullivan West Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649650111,
        AWATER: 11919411,
        INTPTLAT: '+41.7531002',
        INTPTLON: '-074.9876940',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8472, 41.851],
            [-74.7724, 41.8947],
            [-74.6785, 41.8862],
            [-74.6696, 41.7778],
            [-74.7045, 41.734],
            [-74.8415, 41.749],
            [-74.8472, 41.851],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617220',
        NAME: 'Liberty Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230253301,
        AWATER: 3052966,
        INTPTLAT: '+41.7866740',
        INTPTLON: '-074.7676688',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6785, 41.8862],
            [-74.5293, 42.0213],
            [-74.4963, 42.0345],
            [-74.3378, 41.9511],
            [-74.3554, 41.9131],
            [-74.4071, 41.8589],
            [-74.5435, 41.7789],
            [-74.6696, 41.7778],
            [-74.6785, 41.8862],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3628890',
        NAME: 'Tri-Valley Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498024857,
        AWATER: 9293341,
        INTPTLAT: '+41.9039407',
        INTPTLON: '-074.5476220',
        ELSDLEA: '',
        UNSDLEA: '28890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.898, 41.8767],
            [-74.8102, 42.0291],
            [-74.7188, 42.0738],
            [-74.5293, 42.0213],
            [-74.6785, 41.8862],
            [-74.7724, 41.8947],
            [-74.8472, 41.851],
            [-74.898, 41.8767],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617580',
        NAME: 'Livingston Manor Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401707069,
        AWATER: 4551751,
        INTPTLAT: '+41.9633124',
        INTPTLON: '-074.7428861',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8415, 41.749],
            [-74.7045, 41.734],
            [-74.6536, 41.682],
            [-74.4942, 41.6235],
            [-74.4538, 41.578],
            [-74.4781, 41.5504],
            [-74.6173, 41.5673],
            [-74.7524, 41.494],
            [-74.7838, 41.6041],
            [-74.8954, 41.6139],
            [-74.8897, 41.7189],
            [-74.8415, 41.749],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3619740',
        NAME: 'Monticello Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482042632,
        AWATER: 25227919,
        INTPTLAT: '+41.6286915',
        INTPTLON: '-074.6943953',
        ELSDLEA: '',
        UNSDLEA: '19740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.8281, 40.9546],
            [-73.8019, 40.9441],
            [-73.8054, 40.9268],
            [-73.8198, 40.9311],
            [-73.8335, 40.9483],
            [-73.8281, 40.9546],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3629040',
        NAME: 'Tuckahoe Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3034056,
        AWATER: 3583,
        INTPTLAT: '+40.9415088',
        INTPTLON: '-073.8139053',
        ELSDLEA: '',
        UNSDLEA: '29040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3461, 40.9693],
            [-72.2852, 40.9488],
            [-72.2645, 40.861],
            [-72.3216, 40.839],
            [-72.3461, 40.9693],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3605370',
        NAME: 'Bridgehampton Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28293953,
        AWATER: 23166971,
        INTPTLAT: '+40.9161929',
        INTPTLON: '-072.3064328',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3186, 40.8568],
            [-73.2885, 40.8732],
            [-73.2589, 40.8688],
            [-73.2522, 40.8475],
            [-73.2699, 40.8058],
            [-73.2948, 40.7949],
            [-73.3091, 40.8271],
            [-73.3186, 40.8568],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608130',
        NAME: 'Commack Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33326199,
        AWATER: 0,
        INTPTLAT: '+40.8392886',
        INTPTLON: '-073.2863280',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3506, 40.7654],
            [-73.295, 40.7843],
            [-73.3065, 40.7415],
            [-73.3452, 40.7424],
            [-73.3506, 40.7654],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3608880',
        NAME: 'Deer Park Union Free School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15146302,
        AWATER: 9723,
        INTPTLAT: '+40.7611363',
        INTPTLON: '-073.3252604',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.072, 40.9318],
            [-73.0385, 40.9377],
            [-73.0133, 40.9038],
            [-73.0269, 40.8995],
            [-73.0643, 40.8911],
            [-73.072, 40.9318],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615780',
        NAME: 'Brookhaven-Comsewogue Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17987851,
        AWATER: 0,
        INTPTLAT: '+40.9145652',
        INTPTLON: '-073.0463435',
        ELSDLEA: '',
        UNSDLEA: '15780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9103, 41.1044],
            [-72.8866, 41.109],
            [-72.8439, 40.9201],
            [-72.9044, 40.9239],
            [-72.9103, 41.1044],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3626840',
        NAME: 'Shoreham-Wading River Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31499289,
        AWATER: 65012984,
        INTPTLAT: '+40.9612515',
        INTPTLON: '-072.8851948',
        ELSDLEA: '',
        UNSDLEA: '26840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4232, 40.9759],
            [-72.4102, 40.9886],
            [-72.3461, 40.9693],
            [-72.3216, 40.839],
            [-72.4565, 40.795],
            [-72.478, 40.8455],
            [-72.4541, 40.919],
            [-72.4232, 40.9759],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3627540',
        NAME: 'Southampton Union Free School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81517311,
        AWATER: 103425814,
        INTPTLAT: '+40.8900684',
        INTPTLON: '-072.3921037',
        ELSDLEA: '',
        UNSDLEA: '27540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.3721, 44.9936],
            [-74.2023, 44.9918],
            [-74.1102, 44.8642],
            [-74.0611, 44.7022],
            [-74.0899, 44.6494],
            [-74.1842, 44.6302],
            [-74.1698, 44.5598],
            [-74.3307, 44.5474],
            [-74.3911, 44.6903],
            [-74.4301, 44.8796],
            [-74.3586, 44.9253],
            [-74.3721, 44.9936],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3618180',
        NAME: 'Malone Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 925990686,
        AWATER: 15844115,
        INTPTLAT: '+44.7627541',
        INTPTLON: '-074.2471431',
        ELSDLEA: '',
        UNSDLEA: '18180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.2819, 44.1206],
            [-74.0935, 44.1376],
            [-73.9762, 44.1407],
            [-73.964, 44.067],
            [-73.9499, 43.9908],
            [-74.0217, 43.9516],
            [-74.2311, 43.832],
            [-74.256, 43.9698],
            [-74.2819, 44.1206],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3620730',
        NAME: 'Newcomb Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 586039761,
        AWATER: 17864792,
        INTPTLAT: '+44.0071768',
        INTPTLON: '-074.0999654',
        ELSDLEA: '',
        UNSDLEA: '20730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8201, 43.8185],
            [-74.6683, 43.7309],
            [-74.6606, 43.6737],
            [-74.7978, 43.6534],
            [-74.8092, 43.7391],
            [-74.8201, 43.8185],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3615330',
        NAME: 'Inlet Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161088502,
        AWATER: 10811777,
        INTPTLAT: '+43.7217896',
        INTPTLON: '-074.7148359',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6606, 43.6737],
            [-74.6683, 43.7309],
            [-74.5596, 43.8418],
            [-74.5388, 43.8223],
            [-74.4783, 43.3805],
            [-74.4957, 43.3163],
            [-74.6508, 43.3715],
            [-74.7687, 43.4258],
            [-74.6399, 43.5194],
            [-74.6606, 43.6737],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3623130',
        NAME: 'Piseco Common School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 669245984,
        AWATER: 28079350,
        INTPTLAT: '+43.5002206',
        INTPTLON: '-074.5795437',
        ELSDLEA: '',
        UNSDLEA: '23130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8542, 44.0701],
            [-74.7681, 44.0776],
            [-74.3849, 44.1116],
            [-74.2819, 44.1206],
            [-74.256, 43.9698],
            [-74.3368, 43.9252],
            [-74.3665, 43.9529],
            [-74.5596, 43.8418],
            [-74.6385, 43.9173],
            [-74.7362, 43.8657],
            [-74.8284, 43.8816],
            [-74.8542, 44.0701],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3617760',
        NAME: 'Long Lake Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 916483668,
        AWATER: 85813655,
        INTPTLAT: '+43.9657630',
        INTPTLON: '-074.6349623',
        ELSDLEA: '',
        UNSDLEA: '17760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6508, 43.3715],
            [-74.4957, 43.3163],
            [-74.4783, 43.3805],
            [-74.3935, 43.4116],
            [-74.3133, 43.4989],
            [-74.3268, 43.6196],
            [-74.2038, 43.6588],
            [-74.1826, 43.5209],
            [-74.1724, 43.4538],
            [-74.1601, 43.3715],
            [-74.1581, 43.3599],
            [-74.4721, 43.3365],
            [-74.4577, 43.2329],
            [-74.5347, 43.2281],
            [-74.7053, 43.2864],
            [-74.6508, 43.3715],
          ],
        ],
      },
      properties: {
        STATEFP: '36',
        SCSDLEA: '',
        GEOID: '3630450',
        NAME: 'Wells Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696239637,
        AWATER: 8773701,
        INTPTLAT: '+43.4271471',
        INTPTLON: '-074.3644771',
        ELSDLEA: '',
        UNSDLEA: '30450',
      },
    },
  ],
};

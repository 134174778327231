export const fileWizardStructure = [
  {
    fileWizard: 1,
    visualization: 'mapAndRank',
    steps: {
      step0: {
        id: 'step0',
        title: 'Select a data source',
        action: 'getDashboardMetadata',
        component: 'radioContainer',
        chart: 'map',
        buttons: [
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step0-button1',
          },
        ],
      },
      step1: {
        id: 'step1',
        title: 'Confirm variables to add',
        action: 'getDashboardSampleMetadata',
        component: 'checkboxContainer',
        chart: 'map',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Back',
            id: 'step1-button1',
          },
          {
            buttonAction: 'confirm',
            buttonText: 'Confirm',
            id: 'step1-button2',
          },
        ],
      },
    },
  },
  {
    fileWizard: 2,
    steps: {
      step0: {
        id: 'step0',
        title: 'Select a data source',
        action: 'getDashboardMetadata',
        component: 'radioContainer',
        chart: 'demographics',
        buttons: [
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step0-button1',
          },
        ],
      },
      step1: {
        id: 'step1',
        title: 'Confirm variables to add',
        action: 'getDashboardSampleMetadata',
        component: 'checkboxContainer',
        chart: 'demographics',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Back',
            id: 'step1-button1',
          },
          {
            buttonAction: 'confirm',
            buttonText: 'Confirm',
            id: 'step1-button2',
          },
        ],
      },
    },
  },
  {
    fileWizard: 3,
    steps: {
      step0: {
        id: 'step0',
        title: 'Select a data source',
        action: 'getDashboardMetadata',
        component: 'radioContainer',
        chart: 'column',
        buttons: [
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step0-button1',
          },
        ],
      },
      step1: {
        id: 'step1',
        title: 'Confirm variables to add',
        action: 'getDashboardSampleMetadata',
        component: 'checkboxContainer',
        chart: 'column',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Back',
            id: 'step1-button1',
          },
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step1-button2',
          },
        ],
      },
      step2: {
        id: 'step2',
        title: 'Chart Configuration',
        action: 'getDashboardMetadata',
        component: 'chartsConfig',
        chart: 'column',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Cancel',
            id: 'step2-button1',
          },
          {
            buttonAction: 'confirm',
            buttonText: 'Confirm',
            id: 'step2-button2',
          },
        ],
      },
    },
  },
  {
    fileWizard: 4,
    steps: {
      step0: {
        id: 'step0',
        title: 'Select a data source',
        action: 'getDashboardMetadata',
        component: 'radioContainer',
        chart: 'bar',
        buttons: [
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step0-button1',
          },
        ],
      },
      step1: {
        id: 'step1',
        title: 'Confirm variables to add',
        action: 'getDashboardSampleMetadata',
        component: 'checkboxContainer',
        chart: 'bar',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Back',
            id: 'step1-button1',
          },
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step1-button2',
          },
        ],
      },
      step2: {
        id: 'step2',
        title: 'Chart Configuration',
        action: 'getDashboardMetadata',
        component: 'chartsConfig',
        chart: 'bar',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Cancel',
            id: 'step2-button1',
          },
          {
            buttonAction: 'confirm',
            buttonText: 'Confirm',
            id: 'step2-button2',
          },
        ],
      },
    },
  },
  {
    fileWizard: 5,
    steps: {
      step0: {
        id: 'step0',
        title: 'Select a data source',
        action: 'getDashboardMetadata',
        component: 'radioContainer',
        chart: 'line',
        buttons: [
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step0-button1',
          },
        ],
      },
      step1: {
        id: 'step1',
        title: 'Confirm variables to add',
        action: 'getDashboardSampleMetadata',
        component: 'checkboxContainer',
        chart: 'line',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Back',
            id: 'step1-button1',
          },
          {
            buttonAction: 'next',
            buttonText: 'Next',
            id: 'step1-button2',
          },
        ],
      },
      step2: {
        id: 'step2',
        title: 'Chart Configuration',
        action: 'getDashboardMetadata',
        component: 'chartsConfig',
        chart: 'line',
        buttons: [
          {
            buttonAction: 'prevStep',
            buttonText: 'Cancel',
            id: 'step2-button1',
          },
          {
            buttonAction: 'confirm',
            buttonText: 'Confirm',
            id: 'step2-button2',
          },
        ],
      },
    },
  },
];

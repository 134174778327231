/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  error: '',
  modalProps: {
    open: false,
  },
  reports: [],
  mostRecentReports: null,
  reportItem: [],
  loading: false,
  showCreate: false,
  showToast: false,
  showShareToast: false,
  shareToken: null,
  redirectToReport: 0,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.REPORT_MODAL_SHOW:
      return {
        ...state,
        modalProps: { open: true },
        reportItem: action.payload,
        loading: true,
      };
    case types.reducerTypes.REPORT_LOADED:
      return {
        ...state,
        loading: false,
      };
    case types.reducerTypes.REPORT_MODAL_HIDE:
      return {
        ...state,
        showCreate: false,
        modalProps: { open: false },
        reportItem: [],
      };
    case types.reducerTypes.REPORT_RECENT_LIST_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case types.reducerTypes.REPORT_RECENT_LIST_SUCCESS:
      return {
        ...state,
        mostRecentReports: action.payload,
        loading: false,
      };
    case types.reducerTypes.REPORT_RECENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.REPORT_LIST_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case types.reducerTypes.REPORT_LIST_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        loading: false,
      };
    case types.reducerTypes.REPORT_CREATE_MODAL_SHOW:
      return {
        ...state,
        showCreate: true,
      };
    case types.reducerTypes.REPORT_CREATE_MODAL_HIDE:
      return {
        ...state,
        showCreate: false,
      };
    case types.reducerTypes.REPORT_CREATE_SUCCESS:
      return {
        ...state,
        modalProps: { open: false },
      };
    case types.reducerTypes.REPORT_ADD_SUCCESS:
      return {
        ...state,
        showToast: true,
        showCreate: false,
        redirectToReport: action.payload,
      };
    case types.reducerTypes.REPORT_TOAST_HIDE:
      return {
        ...state,
        showToast: false,
        redirectToReport: 0,
      };
    case types.reducerTypes.REPORT_DELETE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.REPORT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: state.reports.filter((report) => report.id !== action.payload),
      };
    case types.reducerTypes.REPORT_SHARE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.REPORT_SHARE_SUCCESS:
      return {
        ...state,
        loading: false,
        shareToken: action.payload,
        showShareToast: true,
      };
    case types.reducerTypes.REPORT_SHARE_TOAST_HIDE:
      return {
        ...state,
        showShareToast: false,
        shareToken: null,
      };
    default:
  }
  return state;
};

export default reportsReducer;

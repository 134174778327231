export const NM = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.6617, 34.8639],
            [-105.5028, 34.8676],
            [-105.502, 34.9839],
            [-105.3974, 34.984],
            [-105.3956, 35.0421],
            [-105.2909, 35.0421],
            [-105.2908, 34.7971],
            [-104.8573, 34.7968],
            [-104.8578, 34.6043],
            [-104.892, 34.6044],
            [-104.8919, 34.347],
            [-105.313, 34.3467],
            [-105.3129, 34.4056],
            [-105.6294, 34.4064],
            [-105.6625, 34.435],
            [-105.8392, 34.4352],
            [-105.8389, 34.5523],
            [-105.6623, 34.5525],
            [-105.6617, 34.8639],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500001',
        NAME: 'Vaughn Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4169059401,
        AWATER: 71848,
        INTPTLAT: '+34.6242133',
        INTPTLON: '-105.3107718',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.5705, 35.3193],
            [-106.5977, 35.2809],
            [-106.6581, 35.218],
            [-106.8594, 35.218],
            [-106.8652, 35.2746],
            [-106.8622, 35.3567],
            [-106.6825, 35.3583],
            [-106.5705, 35.3193],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500010',
        NAME: 'Rio Rancho Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398371670,
        AWATER: 333471,
        INTPTLAT: '+35.3006401',
        INTPTLON: '-106.7347177',
        ELSDLEA: '',
        UNSDLEA: '00010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3768, 32.5616],
            [-106.3766, 32.9579],
            [-106.3405, 33.0257],
            [-105.8315, 33.0271],
            [-105.7533, 33.0039],
            [-105.7721, 32.8452],
            [-105.8834, 32.8454],
            [-105.8772, 32.3907],
            [-105.2447, 32.3887],
            [-105.2465, 32.5188],
            [-104.8515, 32.5205],
            [-104.8478, 32.0005],
            [-104.9183, 32.0005],
            [-105.4733, 32.0007],
            [-105.998, 32.0023],
            [-106.2007, 32.0018],
            [-106.2701, 32.0015],
            [-106.3235, 32.0015],
            [-106.3263, 32.0015],
            [-106.3772, 32.0813],
            [-106.3775, 32.1687],
            [-106.3768, 32.5616],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500030',
        NAME: 'Alamogordo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10101063389,
        AWATER: 23373002,
        INTPTLAT: '+32.3833460',
        INTPTLON: '-105.7425727',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.1962, 35.2193],
            [-106.9915, 35.2184],
            [-106.9915, 35.2752],
            [-106.8652, 35.2746],
            [-106.8594, 35.218],
            [-106.6581, 35.218],
            [-106.5977, 35.2809],
            [-106.5987, 35.2179],
            [-106.2448, 35.2152],
            [-106.2792, 35.1418],
            [-106.2778, 34.9535],
            [-106.1502, 34.9534],
            [-106.1497, 34.8981],
            [-106.1496, 34.8701],
            [-106.411, 34.8703],
            [-106.4321, 34.9033],
            [-106.6859, 34.9061],
            [-106.7207, 34.8701],
            [-107.0245, 34.8703],
            [-107.0667, 34.9572],
            [-107.1962, 35.2193],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500060',
        NAME: 'Albuquerque Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3032117949,
        AWATER: 17201170,
        INTPTLAT: '+35.0590007',
        INTPTLON: '-106.6836037',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0493, 31.5525],
            [-109.0491, 31.8417],
            [-109.0483, 32.0818],
            [-108.9461, 32.125],
            [-108.7391, 32.1244],
            [-108.7391, 32.0813],
            [-108.5381, 32.0798],
            [-108.5247, 31.8637],
            [-108.2172, 31.8641],
            [-108.2084, 31.7836],
            [-108.2086, 31.3334],
            [-109.05, 31.3325],
            [-109.0493, 31.5525],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500090',
        NAME: 'Animas Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5959091422,
        AWATER: 22796075,
        INTPTLAT: '+31.7095500',
        INTPTLON: '-108.6374335',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.3162, 33.0027],
            [-105.3169, 33.1322],
            [-104.9058, 33.1386],
            [-104.9061, 33.0518],
            [-104.9042, 32.9642],
            [-103.8146, 32.9652],
            [-103.8147, 32.7404],
            [-103.8138, 32.653],
            [-104.0199, 32.653],
            [-104.0201, 32.5987],
            [-104.3255, 32.5983],
            [-104.3256, 32.5537],
            [-104.601, 32.5539],
            [-104.6011, 32.5204],
            [-104.8515, 32.5205],
            [-105.2465, 32.5188],
            [-105.3539, 32.5188],
            [-105.3525, 32.962],
            [-105.3162, 33.0027],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500120',
        NAME: 'Artesia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7165596838,
        AWATER: 28181098,
        INTPTLAT: '+32.7875499',
        INTPTLON: '-104.6922913',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.032, 36.9998],
            [-107.7669, 37],
            [-107.4209, 37],
            [-107.4514, 36.904],
            [-107.5657, 36.8503],
            [-107.7821, 36.8499],
            [-107.8001, 36.7723],
            [-108.0143, 36.7714],
            [-108.032, 36.9998],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500150',
        NAME: 'Aztec Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1043060038,
        AWATER: 24646269,
        INTPTLAT: '+36.9018393',
        INTPTLON: '-107.7875475',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.3031, 34.5792],
            [-107.2044, 34.5788],
            [-106.9106, 34.5807],
            [-106.8877, 34.6953],
            [-106.7779, 34.7521],
            [-106.7367, 34.7266],
            [-106.4658, 34.7273],
            [-106.4661, 34.6672],
            [-106.47, 34.5212],
            [-106.5567, 34.2891],
            [-107.095, 34.2911],
            [-107.1987, 34.3472],
            [-107.198, 34.5071],
            [-107.3036, 34.5061],
            [-107.3031, 34.5792],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500180',
        NAME: 'Belen Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2794059834,
        AWATER: 4572866,
        INTPTLAT: '+34.4978392',
        INTPTLON: '-106.8087577',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2483, 35.6995],
            [-106.2459, 35.3044],
            [-106.2448, 35.2152],
            [-106.5987, 35.2179],
            [-106.5977, 35.2809],
            [-106.5705, 35.3193],
            [-106.5702, 35.4633],
            [-106.6149, 35.4637],
            [-106.6081, 35.7],
            [-106.2483, 35.6995],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500210',
        NAME: 'Bernalillo Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1670332734,
        AWATER: 9668865,
        INTPTLAT: '+35.4656607',
        INTPTLON: '-106.4239675',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.0143, 36.7714],
            [-107.8001, 36.7723],
            [-107.7821, 36.8499],
            [-107.5657, 36.8503],
            [-107.6182, 36.793],
            [-107.6249, 36.5663],
            [-107.6243, 36.2198],
            [-107.6265, 36.0003],
            [-108.1307, 36.0025],
            [-108.1254, 36.6763],
            [-108.0138, 36.6989],
            [-108.0143, 36.7714],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500240',
        NAME: 'Bloomfield Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3889433594,
        AWATER: 13115880,
        INTPTLAT: '+36.3944367',
        INTPTLON: '-107.8628719',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.4197, 33.8821],
            [-105.3497, 33.8823],
            [-105.3065, 33.7352],
            [-105.3057, 33.561],
            [-105.2342, 33.4887],
            [-105.2897, 33.417],
            [-105.411, 33.4321],
            [-105.497, 33.4617],
            [-105.6218, 33.4059],
            [-105.7273, 33.3905],
            [-105.8979, 33.3901],
            [-105.8975, 33.5345],
            [-105.6894, 33.5201],
            [-105.6899, 33.6506],
            [-105.6223, 33.7373],
            [-105.481, 33.7691],
            [-105.4892, 33.882],
            [-105.4197, 33.8821],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500270',
        NAME: 'Capitan Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1718337681,
        AWATER: 304233,
        INTPTLAT: '+33.5913479',
        INTPTLON: '-105.5328321',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8515, 32.5205],
            [-104.6011, 32.5204],
            [-104.601, 32.5539],
            [-104.3256, 32.5537],
            [-104.3255, 32.5983],
            [-104.0201, 32.5987],
            [-104.0199, 32.653],
            [-103.8138, 32.653],
            [-103.814, 32.5225],
            [-103.7229, 32.5225],
            [-103.723, 32.3281],
            [-104.185, 32.327],
            [-104.3415, 32.2683],
            [-103.7229, 32.2697],
            [-103.7229, 32.0002],
            [-103.9802, 32.0001],
            [-104.0245, 32],
            [-104.8478, 32.0005],
            [-104.8515, 32.5205],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500300',
        NAME: 'Carlsbad Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6409411050,
        AWATER: 18339685,
        INTPTLAT: '+32.3211332',
        INTPTLON: '-104.2934514',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3582, 33.9558],
            [-105.9253, 33.9565],
            [-105.9251, 34.0856],
            [-105.673, 34.0856],
            [-105.6294, 34.0415],
            [-105.6291, 33.9107],
            [-105.4198, 33.911],
            [-105.4197, 33.8821],
            [-105.4892, 33.882],
            [-105.481, 33.7691],
            [-105.6223, 33.7373],
            [-105.6899, 33.6506],
            [-105.6894, 33.5201],
            [-105.8975, 33.5345],
            [-105.8979, 33.3901],
            [-106.3459, 33.3908],
            [-106.3731, 33.4801],
            [-106.3733, 33.9559],
            [-106.3582, 33.9558],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500330',
        NAME: 'Carrizozo Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4476954015,
        AWATER: 189795,
        INTPTLAT: '+33.7298680',
        INTPTLON: '-105.9856955',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0457, 36.4656],
            [-109.0452, 36.999],
            [-108.5364, 36.9993],
            [-108.5363, 36.8503],
            [-108.2513, 36.8501],
            [-108.2879, 36.8061],
            [-108.2702, 36.674],
            [-108.2718, 36.0022],
            [-109.046, 36.0023],
            [-109.0457, 36.4656],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500390',
        NAME: 'Central Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7263379202,
        AWATER: 23094873,
        INTPTLAT: '+36.4797993',
        INTPTLON: '-108.6750792',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.8958, 37.0001],
            [-106.4762, 36.9938],
            [-106.3759, 36.9478],
            [-106.3729, 36.8922],
            [-106.2679, 36.8289],
            [-106.3173, 36.7376],
            [-106.2223, 36.6691],
            [-106.2427, 36.6261],
            [-106.3688, 36.6257],
            [-106.3329, 36.5247],
            [-106.3338, 36.3973],
            [-106.3982, 36.3132],
            [-106.3977, 36.2978],
            [-106.5727, 36.2973],
            [-106.661, 36.3489],
            [-106.7292, 36.5071],
            [-106.7918, 36.5211],
            [-106.8125, 36.5545],
            [-106.8124, 36.7669],
            [-106.7652, 36.8242],
            [-106.8144, 36.8801],
            [-106.8963, 36.8804],
            [-106.8958, 37.0001],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500420',
        NAME: 'Chama Valley Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3016109607,
        AWATER: 37525712,
        INTPTLAT: '+36.6784035',
        INTPTLON: '-106.5750435',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.2206, 36.9952],
            [-105.155, 36.9953],
            [-104.7559, 36.9935],
            [-104.7558, 36.6022],
            [-104.7561, 36.515],
            [-104.8647, 36.4491],
            [-104.8652, 36.2611],
            [-105.1522, 36.2613],
            [-105.3409, 36.2622],
            [-105.3251, 36.3886],
            [-105.344, 36.5146],
            [-105.3706, 36.681],
            [-105.2306, 36.7238],
            [-105.2001, 36.9156],
            [-105.2206, 36.9952],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500480',
        NAME: 'Cimarron Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3714284846,
        AWATER: 9622911,
        INTPTLAT: '+36.6654049',
        INTPTLON: '-105.0742043',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0089, 36.218],
            [-103.9021, 36.261],
            [-103.9, 36.3778],
            [-104.0082, 36.3781],
            [-104.0083, 36.4511],
            [-103.6666, 36.4525],
            [-103.6676, 36.5684],
            [-103.5241, 36.5694],
            [-103.4704, 36.6417],
            [-103.3607, 36.6415],
            [-103.3628, 36.8676],
            [-103.4714, 36.9111],
            [-103.4723, 36.9992],
            [-103.4081, 36.9994],
            [-103.0861, 37.0003],
            [-103.0022, 37.0001],
            [-103.0021, 36.7635],
            [-103.0024, 36.5004],
            [-103.0419, 36.5003],
            [-103.0408, 36.0552],
            [-103.0414, 35.7393],
            [-103.375, 35.74],
            [-103.365, 36.0864],
            [-103.7726, 36.0856],
            [-103.794, 36.174],
            [-104.009, 36.1745],
            [-104.0089, 36.218],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500510',
        NAME: 'Clayton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6814871434,
        AWATER: 15234663,
        INTPTLAT: '+36.3629520',
        INTPTLON: '-103.3378308',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7533, 33.0039],
            [-105.3162, 33.0027],
            [-105.3525, 32.962],
            [-105.3539, 32.5188],
            [-105.2465, 32.5188],
            [-105.2447, 32.3887],
            [-105.8772, 32.3907],
            [-105.8834, 32.8454],
            [-105.7721, 32.8452],
            [-105.7533, 33.0039],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500540',
        NAME: 'Cloudcroft Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3354752517,
        AWATER: 5119541,
        INTPTLAT: '+32.6761924',
        INTPTLON: '-105.5854417',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.4249, 34.605],
            [-103.3552, 34.6474],
            [-103.2143, 34.6474],
            [-103.1085, 34.6617],
            [-103.1083, 34.3611],
            [-103.2147, 34.3032],
            [-103.4524, 34.3032],
            [-103.4773, 34.5643],
            [-103.4249, 34.605],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500570',
        NAME: 'Clovis Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1171714545,
        AWATER: 4291873,
        INTPTLAT: '+34.4777941',
        INTPTLON: '-103.2754010',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.1823, 33.2011],
            [-108.0006, 33.2016],
            [-107.8756, 33.2026],
            [-107.8362, 33.0678],
            [-107.8567, 33.0025],
            [-107.7805, 32.9528],
            [-107.7731, 32.8538],
            [-107.7214, 32.7781],
            [-107.7323, 32.6274],
            [-107.6083, 32.6054],
            [-107.9235, 32.6044],
            [-107.9235, 32.5173],
            [-108.1829, 32.517],
            [-108.1823, 33.2011],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500600',
        NAME: 'Cobre Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2699763348,
        AWATER: 5132464,
        INTPTLAT: '+32.8252997',
        INTPTLON: '-107.9934449',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3581, 34.1292],
            [-106.1692, 34.1283],
            [-106.0646, 34.1738],
            [-106.064, 34.26],
            [-105.9819, 34.2599],
            [-105.8399, 34.26],
            [-105.8392, 34.4352],
            [-105.6625, 34.435],
            [-105.6294, 34.4064],
            [-105.3129, 34.4056],
            [-105.313, 34.3467],
            [-104.8919, 34.347],
            [-104.8931, 34.0883],
            [-104.8945, 33.9118],
            [-105.4198, 33.911],
            [-105.6291, 33.9107],
            [-105.6294, 34.0415],
            [-105.673, 34.0856],
            [-105.9251, 34.0856],
            [-105.9253, 33.9565],
            [-106.3582, 33.9558],
            [-106.3581, 34.1292],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500630',
        NAME: 'Corona Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5391262012,
        AWATER: 703,
        INTPTLAT: '+34.1389195',
        INTPTLON: '-105.5317135',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6243, 36.2198],
            [-106.885, 36.2166],
            [-106.8854, 36.0011],
            [-106.7783, 35.999],
            [-106.7781, 35.892],
            [-106.8594, 35.9062],
            [-106.8497, 35.6532],
            [-106.9922, 35.6532],
            [-106.9915, 35.2752],
            [-106.9915, 35.2184],
            [-107.1962, 35.2193],
            [-107.2392, 35.3054],
            [-107.31, 35.3052],
            [-107.3082, 35.9987],
            [-107.6265, 36.0003],
            [-107.6243, 36.2198],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500660',
        NAME: 'Cuba Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4568317845,
        AWATER: 967341,
        INTPTLAT: '+35.8303460',
        INTPTLON: '-107.1321494',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.1829, 32.517],
            [-107.9235, 32.5173],
            [-107.9235, 32.6044],
            [-107.6083, 32.6054],
            [-107.2995, 32.6055],
            [-107.2989, 32.5622],
            [-107.2985, 32.168],
            [-107.2968, 31.7837],
            [-108.2084, 31.7836],
            [-108.2172, 31.8641],
            [-108.23, 32.08],
            [-108.2293, 32.517],
            [-108.1829, 32.517],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500690',
        NAME: 'Deming Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7679791940,
        AWATER: 431978,
        INTPTLAT: '+32.1845231',
        INTPTLON: '-107.7471911',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0079, 36.9962],
            [-103.4723, 36.9992],
            [-103.4714, 36.9111],
            [-103.3628, 36.8676],
            [-103.3607, 36.6415],
            [-103.4704, 36.6417],
            [-103.5241, 36.5694],
            [-103.6676, 36.5684],
            [-103.6666, 36.4525],
            [-104.0083, 36.4511],
            [-104.0084, 36.523],
            [-104.0091, 36.6858],
            [-104.0975, 36.6864],
            [-104.0976, 36.7566],
            [-104.0081, 36.7735],
            [-104.0079, 36.9962],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500720',
        NAME: 'Des Moines Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3011599240,
        AWATER: 3588614,
        INTPTLAT: '+36.7460476',
        INTPTLON: '-103.7425615',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9036, 33.2648],
            [-104.4924, 33.2558],
            [-104.3504, 33.3148],
            [-103.7668, 33.3119],
            [-103.7662, 33.1395],
            [-104.9058, 33.1387],
            [-104.9036, 33.2648],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500750',
        NAME: 'Dexter Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1766937479,
        AWATER: 2558203,
        INTPTLAT: '+33.2082013',
        INTPTLON: '-104.2786927',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.458, 34.0255],
            [-103.4227, 34.0769],
            [-103.1788, 34.07],
            [-103.1794, 34.0116],
            [-103.0436, 34.0112],
            [-103.0479, 33.7962],
            [-103.05, 33.7147],
            [-103.0526, 33.5706],
            [-103.5103, 33.5703],
            [-103.5099, 33.5869],
            [-103.5593, 33.6567],
            [-103.5592, 33.7156],
            [-103.4577, 33.7158],
            [-103.458, 34.0255],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500790',
        NAME: 'Dora Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2115051487,
        AWATER: 2210320,
        INTPTLAT: '+33.7882880',
        INTPTLON: '-103.2794657',
        ELSDLEA: '',
        UNSDLEA: '00790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.5657, 36.8503],
            [-107.4514, 36.904],
            [-107.4209, 37],
            [-107.3338, 37],
            [-106.8958, 37.0001],
            [-106.8963, 36.8804],
            [-106.8144, 36.8801],
            [-106.7652, 36.8242],
            [-106.8124, 36.7669],
            [-106.8125, 36.5545],
            [-106.7918, 36.5211],
            [-107.1941, 36.5228],
            [-107.1942, 36.5671],
            [-107.6249, 36.5663],
            [-107.6182, 36.793],
            [-107.5657, 36.8503],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500810',
        NAME: 'Dulce Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3316157043,
        AWATER: 35589568,
        INTPTLAT: '+36.7549353',
        INTPTLON: '-107.1765211',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.9987, 34.0822],
            [-103.9459, 34.0828],
            [-103.8755, 34.0816],
            [-103.8753, 34.17],
            [-103.7384, 34.1699],
            [-103.7375, 34.1115],
            [-103.5278, 34.0838],
            [-103.458, 34.0255],
            [-103.4577, 33.7158],
            [-103.5592, 33.7156],
            [-103.5593, 33.6567],
            [-103.7709, 33.6556],
            [-103.8248, 33.7431],
            [-103.8942, 33.7432],
            [-103.9471, 33.819],
            [-103.9459, 33.9877],
            [-103.9987, 34.0822],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500840',
        NAME: 'Elida Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2059736410,
        AWATER: 2824614,
        INTPTLAT: '+33.9085498',
        INTPTLON: '-103.7327288',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3977, 36.2978],
            [-106.3982, 36.3132],
            [-106.3158, 36.2622],
            [-106.1343, 36.2621],
            [-105.9935, 36.219],
            [-105.8569, 36.2303],
            [-105.8353, 36.2077],
            [-105.8103, 36.0818],
            [-105.6992, 36.0471],
            [-105.6444, 35.9689],
            [-105.7175, 35.9732],
            [-106.0539, 35.973],
            [-106.0541, 35.9308],
            [-106.2454, 35.9309],
            [-106.2479, 35.9663],
            [-106.3999, 36.0011],
            [-106.3977, 36.2978],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500900',
        NAME: 'Española Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1837292969,
        AWATER: 5638922,
        INTPTLAT: '+36.1026586',
        INTPTLON: '-106.0896961',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.4658, 34.7273],
            [-106.4642, 34.8702],
            [-106.411, 34.8703],
            [-106.1496, 34.8701],
            [-106.1497, 34.8981],
            [-106.0437, 34.8702],
            [-105.6617, 34.8639],
            [-105.6623, 34.5525],
            [-105.8389, 34.5523],
            [-105.8392, 34.4352],
            [-105.8399, 34.26],
            [-105.9819, 34.2599],
            [-105.9805, 34.4786],
            [-106.1009, 34.5234],
            [-106.1008, 34.609],
            [-106.2801, 34.6672],
            [-106.4661, 34.6672],
            [-106.4658, 34.7273],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500930',
        NAME: 'Estancia Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2753245927,
        AWATER: 1635432,
        INTPTLAT: '+34.6850966',
        INTPTLON: '-105.9487291',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7229, 32.5225],
            [-103.0647, 32.5219],
            [-103.0647, 32.2709],
            [-103.7229, 32.2743],
            [-103.723, 32.3281],
            [-103.7229, 32.5225],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500960',
        NAME: 'Eunice Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1714236680,
        AWATER: 50336,
        INTPTLAT: '+32.3961746',
        INTPTLON: '-103.3908956',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5364, 36.9993],
            [-108.3792, 36.9995],
            [-108.032, 36.9998],
            [-108.0143, 36.7714],
            [-108.0138, 36.6989],
            [-108.1254, 36.6763],
            [-108.1307, 36.0025],
            [-108.2718, 36.0022],
            [-108.2702, 36.674],
            [-108.2879, 36.8061],
            [-108.2513, 36.8501],
            [-108.5363, 36.8503],
            [-108.5364, 36.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3500990',
        NAME: 'Farmington Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2083205086,
        AWATER: 4568868,
        INTPTLAT: '+36.6237159',
        INTPTLON: '-108.2216147',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.949, 34.3901],
            [-103.739, 34.3901],
            [-103.74, 34.3031],
            [-103.4596, 34.3033],
            [-103.4574, 34.1558],
            [-103.5278, 34.0838],
            [-103.7375, 34.1115],
            [-103.7384, 34.1699],
            [-103.8753, 34.17],
            [-103.8755, 34.0816],
            [-103.9459, 34.0828],
            [-103.949, 34.3901],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501020',
        NAME: 'Floyd Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1114873354,
        AWATER: 1494876,
        INTPTLAT: '+34.2546811',
        INTPTLON: '-103.7085764',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8919, 34.347],
            [-104.892, 34.6044],
            [-104.8578, 34.6043],
            [-104.4449, 34.6048],
            [-104.4449, 34.6917],
            [-104.3397, 34.6922],
            [-104.3401, 34.7791],
            [-104.1294, 34.7793],
            [-104.1289, 34.6052],
            [-103.9488, 34.6051],
            [-103.949, 34.5214],
            [-103.949, 34.3901],
            [-103.9459, 34.0828],
            [-103.9987, 34.0822],
            [-104.1558, 34.0833],
            [-104.1563, 33.9954],
            [-104.786, 34.0001],
            [-104.7862, 34.0878],
            [-104.8931, 34.0883],
            [-104.8919, 34.347],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501050',
        NAME: 'Fort Sumner Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6015539696,
        AWATER: 29159492,
        INTPTLAT: '+34.3592729',
        INTPTLON: '-104.3686961',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.2985, 32.168],
            [-106.3775, 32.1687],
            [-106.3772, 32.0813],
            [-106.3263, 32.0015],
            [-106.4093, 32.0013],
            [-106.4709, 32.0011],
            [-106.5704, 32.0007],
            [-106.6185, 32.0005],
            [-106.6255, 31.957],
            [-106.6324, 31.864],
            [-106.5282, 31.7831],
            [-107.2968, 31.7837],
            [-107.2985, 32.168],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501080',
        NAME: 'Gadsden Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3238534797,
        AWATER: 2357267,
        INTPTLAT: '+31.9810014',
        INTPTLON: '-106.9074707',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0467, 35.4287],
            [-109.0461, 35.923],
            [-109.046, 36.0023],
            [-108.2718, 36.0022],
            [-108.1307, 36.0025],
            [-107.6265, 36.0003],
            [-107.3082, 35.9987],
            [-107.31, 35.3052],
            [-108.4686, 35.3068],
            [-108.4693, 34.959],
            [-108.5029, 34.959],
            [-108.5413, 34.9909],
            [-108.5391, 35.3069],
            [-108.6806, 35.3071],
            [-108.6816, 35.2188],
            [-108.8396, 35.1693],
            [-109.0462, 35.1747],
            [-109.0467, 35.4287],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501110',
        NAME: 'Gallup-McKinley County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12809595744,
        AWATER: 11702564,
        INTPTLAT: '+35.6296575',
        INTPTLON: '-108.2283530',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.4486, 34.9239],
            [-103.2837, 34.9002],
            [-103.283, 34.9542],
            [-103.0427, 34.9541],
            [-103.0429, 34.8211],
            [-103.1876, 34.8214],
            [-103.2143, 34.6474],
            [-103.3552, 34.6474],
            [-103.4249, 34.605],
            [-103.4766, 34.8657],
            [-103.4486, 34.9239],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501140',
        NAME: 'Grady Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 926952441,
        AWATER: 786808,
        INTPTLAT: '+34.8070098',
        INTPTLON: '-103.2671903',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.046, 34.9042],
            [-109.0459, 34.9597],
            [-108.5029, 34.959],
            [-108.4693, 34.959],
            [-108.4686, 35.3068],
            [-107.31, 35.3052],
            [-107.2392, 35.3054],
            [-107.1962, 35.2193],
            [-107.0667, 34.9572],
            [-107.203, 34.9581],
            [-107.2044, 34.5788],
            [-107.3031, 34.5792],
            [-107.7099, 34.5776],
            [-107.7098, 34.6961],
            [-108.7193, 34.6965],
            [-109.046, 34.6981],
            [-109.046, 34.9042],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501170',
        NAME: 'Grants-Cibola County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10160869982,
        AWATER: 5657095,
        INTPTLAT: '+34.9544723',
        INTPTLON: '-107.9405313',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9058, 33.1386],
            [-104.9058, 33.1387],
            [-103.7662, 33.1395],
            [-103.7664, 33.0985],
            [-103.7663, 33.0517],
            [-104.9061, 33.0518],
            [-104.9058, 33.1386],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501200',
        NAME: 'Hagerman Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1033730720,
        AWATER: 2711392,
        INTPTLAT: '+33.1018614',
        INTPTLON: '-104.3329787',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3405, 33.0528],
            [-106.3405, 33.0257],
            [-106.3766, 32.9579],
            [-106.3768, 32.5616],
            [-107.2989, 32.5622],
            [-107.2995, 32.6055],
            [-107.2994, 32.7806],
            [-106.8877, 32.7793],
            [-106.8879, 32.8228],
            [-106.3405, 33.0528],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501230',
        NAME: 'Hatch Valley Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2902619459,
        AWATER: 12385994,
        INTPTLAT: '+32.7505006',
        INTPTLON: '-106.7864268',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8147, 32.7404],
            [-103.3509, 32.7407],
            [-103.351, 32.8292],
            [-103.0647, 32.8275],
            [-103.0647, 32.5222],
            [-103.0647, 32.5219],
            [-103.7229, 32.5225],
            [-103.814, 32.5225],
            [-103.8138, 32.653],
            [-103.8147, 32.7404],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501260',
        NAME: 'Hobbs Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1959402854,
        AWATER: 1074115,
        INTPTLAT: '+32.6436866',
        INTPTLON: '-103.4103099',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.411, 33.4321],
            [-105.2897, 33.417],
            [-105.2342, 33.4887],
            [-105.3057, 33.561],
            [-105.3065, 33.7352],
            [-105.3497, 33.8823],
            [-105.4197, 33.8821],
            [-105.4198, 33.911],
            [-104.8945, 33.9118],
            [-104.8924, 33.3977],
            [-104.9036, 33.2648],
            [-104.9058, 33.1387],
            [-104.9058, 33.1386],
            [-105.3169, 33.1322],
            [-105.319, 33.3051],
            [-105.4195, 33.3049],
            [-105.411, 33.4321],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501290',
        NAME: 'Hondo Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3500151427,
        AWATER: 43848,
        INTPTLAT: '+33.5451712',
        INTPTLON: '-105.0738566',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.1294, 34.7793],
            [-104.1294, 34.8677],
            [-104.0711, 34.9111],
            [-103.9179, 34.8516],
            [-103.7403, 34.8361],
            [-103.7049, 34.7806],
            [-103.7065, 34.6049],
            [-103.7385, 34.5215],
            [-103.949, 34.5214],
            [-103.9488, 34.6051],
            [-104.1289, 34.6052],
            [-104.1294, 34.7793],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501320',
        NAME: 'House Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1281317302,
        AWATER: 87581,
        INTPTLAT: '+34.7193525',
        INTPTLON: '-103.9466783',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7229, 32.2697],
            [-103.7229, 32.2743],
            [-103.0647, 32.2709],
            [-103.0645, 32.0871],
            [-103.0644, 32.0005],
            [-103.1651, 32.0003],
            [-103.7229, 32.0002],
            [-103.7229, 32.2697],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501350',
        NAME: 'Jal Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1874637911,
        AWATER: 71942,
        INTPTLAT: '+32.1225249',
        INTPTLON: '-103.4031680',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6249, 36.5663],
            [-107.1942, 36.5671],
            [-107.1941, 36.5228],
            [-106.7918, 36.5211],
            [-106.7292, 36.5071],
            [-106.661, 36.3489],
            [-106.5727, 36.2973],
            [-106.3977, 36.2978],
            [-106.3999, 36.0011],
            [-106.7783, 35.999],
            [-106.8854, 36.0011],
            [-106.885, 36.2166],
            [-107.6243, 36.2198],
            [-107.6249, 36.5663],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501380',
        NAME: 'Jemez Mountain Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4273324569,
        AWATER: 10911840,
        INTPTLAT: '+36.3025552',
        INTPTLON: '-107.0118425',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.7783, 35.999],
            [-106.3999, 36.0011],
            [-106.2479, 35.9663],
            [-106.3996, 35.9601],
            [-106.4007, 35.8076],
            [-106.2482, 35.7557],
            [-106.2483, 35.6995],
            [-106.6081, 35.7],
            [-106.6149, 35.4637],
            [-106.5702, 35.4633],
            [-106.5705, 35.3193],
            [-106.6825, 35.3583],
            [-106.8622, 35.3567],
            [-106.8652, 35.2746],
            [-106.9915, 35.2752],
            [-106.9922, 35.6532],
            [-106.8497, 35.6532],
            [-106.8594, 35.9062],
            [-106.7781, 35.892],
            [-106.7783, 35.999],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501410',
        NAME: 'Jemez Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2872736708,
        AWATER: 1992361,
        INTPTLAT: '+35.6969070',
        INTPTLON: '-106.6654977',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.9061, 33.0518],
            [-103.7663, 33.0517],
            [-103.8146, 32.9652],
            [-104.9042, 32.9642],
            [-104.9061, 33.0518],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501470',
        NAME: 'Lake Arthur Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1032188821,
        AWATER: 1757018,
        INTPTLAT: '+33.0123566',
        INTPTLON: '-104.3494019',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.2989, 32.5622],
            [-106.3768, 32.5616],
            [-106.3775, 32.1687],
            [-107.2985, 32.168],
            [-107.2989, 32.5622],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501500',
        NAME: 'Las Cruces Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3787211381,
        AWATER: 2841665,
        INTPTLAT: '+32.3418832',
        INTPTLON: '-106.8401356',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.5056, 35.8707],
            [-105.3478, 35.8707],
            [-105.059, 35.7801],
            [-105.0252, 35.8349],
            [-104.9229, 35.8355],
            [-104.9225, 35.7739],
            [-104.3674, 35.7814],
            [-104.0651, 35.7812],
            [-104.0213, 35.7815],
            [-104.3673, 35.6269],
            [-104.4342, 35.6513],
            [-104.5468, 35.5947],
            [-104.5223, 35.4777],
            [-105.1874, 35.4773],
            [-105.26, 35.643],
            [-105.5061, 35.6963],
            [-105.5056, 35.8707],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501530',
        NAME: 'Las Vegas City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3255500903,
        AWATER: 12200169,
        INTPTLAT: '+35.6440668',
        INTPTLON: '-104.8630266',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7179, 35.5208],
            [-105.6804, 35.4704],
            [-105.5137, 35.3554],
            [-105.4809, 35.4108],
            [-105.5061, 35.6963],
            [-105.26, 35.643],
            [-105.1874, 35.4773],
            [-104.5223, 35.4777],
            [-104.5468, 35.5947],
            [-104.4342, 35.6513],
            [-104.3673, 35.6269],
            [-104.0213, 35.7815],
            [-103.9769, 35.8017],
            [-103.7246, 35.4222],
            [-103.6371, 35.3897],
            [-103.6371, 35.3375],
            [-103.6371, 35.2409],
            [-103.8575, 35.2422],
            [-104.1248, 35.1422],
            [-104.1248, 35.2156],
            [-105.0828, 35.2168],
            [-105.1673, 35.2604],
            [-105.2914, 35.2163],
            [-105.2909, 35.0421],
            [-105.3956, 35.0421],
            [-105.7145, 35.0416],
            [-105.7174, 35.3043],
            [-105.7179, 35.5208],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501560',
        NAME: 'West Las Vegas Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7905448812,
        AWATER: 39213154,
        INTPTLAT: '+35.3909431',
        INTPTLON: '-104.6799831',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.6371, 35.3897],
            [-103.5217, 35.3898],
            [-103.5212, 35.5082],
            [-103.4857, 35.5658],
            [-103.3795, 35.5648],
            [-103.375, 35.74],
            [-103.0414, 35.7393],
            [-103.0423, 35.3949],
            [-103.1066, 35.3593],
            [-103.2747, 35.3904],
            [-103.279, 35.2591],
            [-103.4065, 35.2883],
            [-103.5846, 35.2886],
            [-103.6371, 35.3375],
            [-103.6371, 35.3897],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501590',
        NAME: 'Logan Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1782695719,
        AWATER: 15305415,
        INTPTLAT: '+35.4963500',
        INTPTLON: '-103.3027166',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0476, 32.4264],
            [-109.0471, 32.7776],
            [-108.8544, 32.7773],
            [-108.8531, 32.6036],
            [-108.6464, 32.6036],
            [-108.6472, 32.5169],
            [-108.537, 32.5168],
            [-108.5381, 32.0798],
            [-108.7391, 32.0813],
            [-108.7391, 32.1244],
            [-108.9461, 32.125],
            [-109.0483, 32.0818],
            [-109.0476, 32.4264],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501620',
        NAME: 'Lordsburg Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2942356022,
        AWATER: 855990,
        INTPTLAT: '+32.3676526',
        INTPTLON: '-108.8981993',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2479, 35.9663],
            [-106.2454, 35.9309],
            [-106.2484, 35.8556],
            [-106.2483, 35.8392],
            [-106.2475, 35.7557],
            [-106.2482, 35.7557],
            [-106.4007, 35.8076],
            [-106.3996, 35.9601],
            [-106.2479, 35.9663],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501650',
        NAME: 'Los Alamos Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282741305,
        AWATER: 229939,
        INTPTLAT: '+35.8700469',
        INTPTLON: '-106.3079679',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.0667, 34.9572],
            [-107.0245, 34.8703],
            [-106.7207, 34.8701],
            [-106.6859, 34.9061],
            [-106.4321, 34.9033],
            [-106.411, 34.8703],
            [-106.4642, 34.8702],
            [-106.4658, 34.7273],
            [-106.7367, 34.7266],
            [-106.7779, 34.7521],
            [-106.8877, 34.6953],
            [-106.9106, 34.5807],
            [-107.2044, 34.5788],
            [-107.203, 34.9581],
            [-107.0667, 34.9572],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501680',
        NAME: 'Los Lunas Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1731224148,
        AWATER: 3155084,
        INTPTLAT: '+34.7840759',
        INTPTLON: '-106.8803776',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.723, 32.3281],
            [-103.7229, 32.2743],
            [-103.7229, 32.2697],
            [-104.3415, 32.2683],
            [-104.185, 32.327],
            [-103.723, 32.3281],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501710',
        NAME: 'Loving Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318432818,
        AWATER: 11188182,
        INTPTLAT: '+32.2941965',
        INTPTLON: '-103.9939886',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8146, 32.9652],
            [-103.7663, 33.0517],
            [-103.7664, 33.0985],
            [-103.2141, 33.0973],
            [-103.2142, 33.1698],
            [-103.0611, 33.1699],
            [-103.0638, 33.0376],
            [-103.0647, 32.9591],
            [-103.0647, 32.8275],
            [-103.351, 32.8292],
            [-103.3509, 32.7407],
            [-103.8147, 32.7404],
            [-103.8146, 32.9652],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501740',
        NAME: 'Lovington Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2564750889,
        AWATER: 4036719,
        INTPTLAT: '+32.9325284',
        INTPTLON: '-103.4312924',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.7099, 34.5776],
            [-107.3031, 34.5792],
            [-107.3036, 34.5061],
            [-107.198, 34.5071],
            [-107.1987, 34.3472],
            [-107.095, 34.2911],
            [-107.094, 34.1731],
            [-106.9894, 34.1726],
            [-106.9898, 33.9984],
            [-107.1981, 33.9973],
            [-107.1894, 33.7361],
            [-107.45, 33.7364],
            [-107.4489, 33.4764],
            [-107.7127, 33.4769],
            [-107.7118, 33.7341],
            [-107.7163, 34.2599],
            [-107.7099, 34.5776],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501770',
        NAME: 'Magdalena Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5662742026,
        AWATER: 1249432,
        INTPTLAT: '+34.0962312',
        INTPTLON: '-107.4609041',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7558, 36.6022],
            [-104.6489, 36.6021],
            [-104.6484, 36.6899],
            [-104.2238, 36.6908],
            [-104.2242, 36.5234],
            [-104.7561, 36.515],
            [-104.7558, 36.6022],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501800',
        NAME: 'Maxwell Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 803731360,
        AWATER: 7084271,
        INTPTLAT: '+36.5945736',
        INTPTLON: '-104.4701751',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.949, 34.5214],
            [-103.7385, 34.5215],
            [-103.7065, 34.6049],
            [-103.7049, 34.7806],
            [-103.7403, 34.8361],
            [-103.6361, 34.8669],
            [-103.4766, 34.8657],
            [-103.4249, 34.605],
            [-103.4773, 34.5643],
            [-103.4524, 34.3032],
            [-103.4596, 34.3033],
            [-103.74, 34.3031],
            [-103.739, 34.3901],
            [-103.949, 34.3901],
            [-103.949, 34.5214],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501830',
        NAME: 'Melrose Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1858282655,
        AWATER: 1413062,
        INTPTLAT: '+34.5580867',
        INTPTLON: '-103.6169943',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7175, 35.9732],
            [-105.6444, 35.9689],
            [-105.5724, 35.9725],
            [-105.42, 36.1317],
            [-105.4004, 36.2305],
            [-105.3409, 36.2622],
            [-105.1522, 36.2613],
            [-105.1519, 36.1572],
            [-104.999, 36.1574],
            [-104.9985, 36.0397],
            [-105.0493, 35.9074],
            [-105.059, 35.7801],
            [-105.3478, 35.8707],
            [-105.5056, 35.8707],
            [-105.7203, 35.871],
            [-105.7175, 35.9732],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501860',
        NAME: 'Mora Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1950333758,
        AWATER: 2613223,
        INTPTLAT: '+36.0186467',
        INTPTLON: '-105.2879846',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2448, 35.2152],
            [-106.2459, 35.3044],
            [-105.7174, 35.3043],
            [-105.7145, 35.0416],
            [-105.3956, 35.0421],
            [-105.3974, 34.984],
            [-105.502, 34.9839],
            [-105.5028, 34.8676],
            [-105.6617, 34.8639],
            [-106.0437, 34.8702],
            [-106.1497, 34.8981],
            [-106.1502, 34.9534],
            [-106.2778, 34.9535],
            [-106.2792, 35.1418],
            [-106.2448, 35.2152],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501890',
        NAME: 'Moriarty Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2732452147,
        AWATER: 208940,
        INTPTLAT: '+35.0719315',
        INTPTLON: '-105.9142147',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7726, 36.0856],
            [-103.365, 36.0864],
            [-103.375, 35.74],
            [-103.3795, 35.5648],
            [-103.4857, 35.5658],
            [-103.5212, 35.5082],
            [-103.5217, 35.3898],
            [-103.6371, 35.3897],
            [-103.7246, 35.4222],
            [-103.9769, 35.8017],
            [-104.0213, 35.7815],
            [-104.0651, 35.7812],
            [-103.9629, 35.9129],
            [-103.9618, 35.974],
            [-103.7719, 35.9988],
            [-103.7726, 36.0856],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501920',
        NAME: 'Mosquero Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3245550361,
        AWATER: 461803,
        INTPTLAT: '+35.7727346',
        INTPTLON: '-103.6959016',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.5567, 34.2891],
            [-106.47, 34.5212],
            [-106.4661, 34.6672],
            [-106.2801, 34.6672],
            [-106.1008, 34.609],
            [-106.1009, 34.5234],
            [-105.9805, 34.4786],
            [-105.9819, 34.2599],
            [-106.064, 34.26],
            [-106.0646, 34.1738],
            [-106.1692, 34.1283],
            [-106.3581, 34.1292],
            [-106.5643, 34.1286],
            [-106.5567, 34.2891],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501950',
        NAME: 'Mountainair Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2461867988,
        AWATER: 576209,
        INTPTLAT: '+34.3916901',
        INTPTLON: '-106.3081863',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7185, 36.9958],
            [-105.7372, 36.9266],
            [-105.6704, 36.7644],
            [-105.6958, 36.6256],
            [-105.733, 36.4766],
            [-105.7286, 36.3505],
            [-105.8567, 36.3509],
            [-105.8569, 36.2303],
            [-105.9935, 36.219],
            [-106.1343, 36.2621],
            [-106.3158, 36.2622],
            [-106.3982, 36.3132],
            [-106.3338, 36.3973],
            [-106.3329, 36.5247],
            [-106.3688, 36.6257],
            [-106.2427, 36.6261],
            [-106.2223, 36.6691],
            [-106.3173, 36.7376],
            [-106.2679, 36.8289],
            [-106.3729, 36.8922],
            [-106.3759, 36.9478],
            [-106.4762, 36.9938],
            [-105.7185, 36.9958],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3501980',
        NAME: 'Mesa Vista Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4508678350,
        AWATER: 2044436,
        INTPTLAT: '+36.6360632',
        INTPTLON: '-106.0440041',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7206, 35.8627],
            [-105.7203, 35.871],
            [-105.5056, 35.8707],
            [-105.5061, 35.6963],
            [-105.4809, 35.4108],
            [-105.5137, 35.3554],
            [-105.6804, 35.4704],
            [-105.7179, 35.5208],
            [-105.7206, 35.8627],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502010',
        NAME: 'Pecos Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 992320385,
        AWATER: 0,
        INTPTLAT: '+35.6394101',
        INTPTLON: '-105.6018191',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8353, 36.2077],
            [-105.6626, 36.2384],
            [-105.5209, 36.1751],
            [-105.4004, 36.2305],
            [-105.42, 36.1317],
            [-105.5724, 35.9725],
            [-105.6444, 35.9689],
            [-105.6992, 36.0471],
            [-105.8103, 36.0818],
            [-105.8353, 36.2077],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502040',
        NAME: 'Peñasco Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 678011203,
        AWATER: 97963,
        INTPTLAT: '+36.0944577',
        INTPTLON: '-105.5632786',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2454, 35.9309],
            [-106.0541, 35.9308],
            [-106.0539, 35.973],
            [-105.7175, 35.9732],
            [-105.7203, 35.871],
            [-105.7206, 35.8627],
            [-105.9293, 35.862],
            [-105.9301, 35.766],
            [-106.2475, 35.7557],
            [-106.2483, 35.8392],
            [-106.2484, 35.8556],
            [-106.2454, 35.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502070',
        NAME: 'Pojoaque Valley Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801312794,
        AWATER: 680459,
        INTPTLAT: '+35.8683241',
        INTPTLON: '-105.9971952',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5278, 34.0838],
            [-103.4574, 34.1558],
            [-103.4596, 34.3033],
            [-103.4524, 34.3032],
            [-103.2147, 34.3032],
            [-103.2307, 34.1716],
            [-103.0438, 34.1716],
            [-103.0436, 34.0781],
            [-103.0436, 34.0112],
            [-103.1794, 34.0116],
            [-103.1788, 34.07],
            [-103.4227, 34.0769],
            [-103.458, 34.0255],
            [-103.5278, 34.0838],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502100',
        NAME: 'Portales Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 896216963,
        AWATER: 12163876,
        INTPTLAT: '+34.1497520',
        INTPTLON: '-103.2920127',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0465, 34.3873],
            [-109.046, 34.6981],
            [-108.7193, 34.6965],
            [-107.7098, 34.6961],
            [-107.7099, 34.5776],
            [-107.7163, 34.2599],
            [-107.7118, 33.7341],
            [-108.4369, 33.7354],
            [-108.455, 33.8651],
            [-108.3509, 33.8651],
            [-108.3507, 33.9967],
            [-108.5604, 33.9967],
            [-108.5597, 34.0852],
            [-108.8394, 34.0854],
            [-108.84, 34.2596],
            [-109.0466, 34.2595],
            [-109.0465, 34.3873],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502130',
        NAME: 'Quemado Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10492882341,
        AWATER: 7478035,
        INTPTLAT: '+34.2460931',
        INTPTLON: '-108.2755189',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.7185, 36.9958],
            [-105.2206, 36.9952],
            [-105.2001, 36.9156],
            [-105.2306, 36.7238],
            [-105.3706, 36.681],
            [-105.344, 36.5146],
            [-105.4128, 36.5262],
            [-105.4247, 36.6091],
            [-105.6958, 36.6256],
            [-105.6704, 36.7644],
            [-105.7372, 36.9266],
            [-105.7185, 36.9958],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502160',
        NAME: 'Questa Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1704874390,
        AWATER: 3186455,
        INTPTLAT: '+36.7854957',
        INTPTLON: '-105.4799753',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.7559, 36.9935],
            [-104.6314, 36.9936],
            [-104.4685, 36.9938],
            [-104.2323, 36.9948],
            [-104.0079, 36.9962],
            [-104.0081, 36.7735],
            [-104.0976, 36.7566],
            [-104.0975, 36.6864],
            [-104.0091, 36.6858],
            [-104.0084, 36.523],
            [-104.2242, 36.5234],
            [-104.2238, 36.6908],
            [-104.6484, 36.6899],
            [-104.6489, 36.6021],
            [-104.7558, 36.6022],
            [-104.7559, 36.9935],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502190',
        NAME: 'Raton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2611912240,
        AWATER: 2708143,
        INTPTLAT: '+36.8145000',
        INTPTLON: '-104.3773426',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0469, 33.7782],
            [-109.0466, 33.908],
            [-109.0466, 34.2595],
            [-108.84, 34.2596],
            [-108.8394, 34.0854],
            [-108.5597, 34.0852],
            [-108.5604, 33.9967],
            [-108.3507, 33.9967],
            [-108.3509, 33.8651],
            [-108.455, 33.8651],
            [-108.4369, 33.7354],
            [-107.7118, 33.7341],
            [-107.7127, 33.4769],
            [-108.0006, 33.478],
            [-108.0006, 33.2016],
            [-108.1823, 33.2011],
            [-109.0472, 33.209],
            [-109.0471, 33.2404],
            [-109.0469, 33.7782],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502220',
        NAME: 'Reserve Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9035625708,
        AWATER: 6855818,
        INTPTLAT: '+33.5845353',
        INTPTLON: '-108.5570817',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8945, 33.9118],
            [-104.8931, 34.0883],
            [-104.7862, 34.0878],
            [-104.786, 34.0001],
            [-104.1563, 33.9954],
            [-104.1558, 34.0833],
            [-103.9987, 34.0822],
            [-103.9459, 33.9877],
            [-103.9471, 33.819],
            [-103.8942, 33.7432],
            [-103.8248, 33.7431],
            [-103.7709, 33.6556],
            [-103.5593, 33.6567],
            [-103.5099, 33.5869],
            [-103.7207, 33.5697],
            [-103.7214, 33.3959],
            [-103.7668, 33.3119],
            [-104.3504, 33.3148],
            [-104.4924, 33.2558],
            [-104.9036, 33.2648],
            [-104.8924, 33.3977],
            [-104.8945, 33.9118],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502250',
        NAME: 'Roswell Independent Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8242068454,
        AWATER: 16984913,
        INTPTLAT: '+33.6422529',
        INTPTLON: '-104.3448198',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.4361, 36.2188],
            [-104.0089, 36.218],
            [-104.009, 36.1745],
            [-103.794, 36.174],
            [-103.7726, 36.0856],
            [-103.7719, 35.9988],
            [-103.9618, 35.974],
            [-103.9629, 35.9129],
            [-104.0651, 35.7812],
            [-104.3674, 35.7814],
            [-104.3657, 36.0443],
            [-104.4361, 36.2188],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502280',
        NAME: 'Roy Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2030480342,
        AWATER: 378945,
        INTPTLAT: '+36.0160831',
        INTPTLON: '-104.1046658',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.411, 33.4321],
            [-105.4195, 33.3049],
            [-105.726, 33.3048],
            [-105.7273, 33.3905],
            [-105.6218, 33.4059],
            [-105.497, 33.4617],
            [-105.411, 33.4321],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502310',
        NAME: 'Ruidoso Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427378367,
        AWATER: 25703,
        INTPTLAT: '+33.3630266',
        INTPTLON: '-105.5869266',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.4065, 35.2883],
            [-103.279, 35.2591],
            [-103.2747, 35.3904],
            [-103.1066, 35.3593],
            [-103.0423, 35.3949],
            [-103.0428, 35.0676],
            [-103.0427, 34.9541],
            [-103.283, 34.9542],
            [-103.2837, 34.9002],
            [-103.4486, 34.9239],
            [-103.4441, 34.9987],
            [-103.4963, 35.0702],
            [-103.4958, 35.2159],
            [-103.4019, 35.2167],
            [-103.4065, 35.2883],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502340',
        NAME: 'San Jon Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1648045920,
        AWATER: 239904,
        INTPTLAT: '+35.1816724',
        INTPTLON: '-103.1921838',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.2483, 35.6995],
            [-106.2482, 35.7557],
            [-106.2475, 35.7557],
            [-105.9301, 35.766],
            [-105.9293, 35.862],
            [-105.7206, 35.8627],
            [-105.7179, 35.5208],
            [-105.7174, 35.3043],
            [-106.2459, 35.3044],
            [-106.2483, 35.6995],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502370',
        NAME: 'Santa Fe Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2631440442,
        AWATER: 2510757,
        INTPTLAT: '+35.5543415',
        INTPTLON: '-105.9696400',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.1248, 35.1422],
            [-104.1294, 34.8677],
            [-104.1294, 34.7793],
            [-104.3401, 34.7791],
            [-104.3397, 34.6922],
            [-104.4449, 34.6917],
            [-104.4449, 34.6048],
            [-104.8578, 34.6043],
            [-104.8573, 34.7968],
            [-105.2908, 34.7971],
            [-105.2909, 35.0421],
            [-105.2914, 35.2163],
            [-105.1673, 35.2604],
            [-105.0828, 35.2168],
            [-104.1248, 35.2156],
            [-104.1248, 35.1422],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502400',
        NAME: 'Santa Rosa Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6020020367,
        AWATER: 2872080,
        INTPTLAT: '+34.9708335',
        INTPTLON: '-104.6972609',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0472, 33.209],
            [-108.1823, 33.2011],
            [-108.1829, 32.517],
            [-108.2293, 32.517],
            [-108.23, 32.08],
            [-108.2172, 31.8641],
            [-108.5247, 31.8637],
            [-108.5381, 32.0798],
            [-108.537, 32.5168],
            [-108.6472, 32.5169],
            [-108.6464, 32.6036],
            [-108.8531, 32.6036],
            [-108.8544, 32.7773],
            [-109.0471, 32.7776],
            [-109.0471, 32.9764],
            [-109.0472, 33.209],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502430',
        NAME: 'Silver City Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7560788716,
        AWATER: 10221101,
        INTPTLAT: '+32.6773909',
        INTPTLON: '-108.5095436',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.095, 34.2911],
            [-106.5567, 34.2891],
            [-106.5643, 34.1286],
            [-106.3581, 34.1292],
            [-106.3582, 33.9558],
            [-106.3733, 33.9559],
            [-106.3731, 33.4801],
            [-106.4743, 33.4758],
            [-107.4489, 33.4764],
            [-107.45, 33.7364],
            [-107.1894, 33.7361],
            [-107.1981, 33.9973],
            [-106.9898, 33.9984],
            [-106.9894, 34.1726],
            [-107.094, 34.1731],
            [-107.095, 34.2911],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502460',
        NAME: 'Socorro Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6774384801,
        AWATER: 1816395,
        INTPTLAT: '+33.7668962',
        INTPTLON: '-106.8212171',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8652, 36.2611],
            [-104.8647, 36.4491],
            [-104.7561, 36.515],
            [-104.2242, 36.5234],
            [-104.0084, 36.523],
            [-104.0083, 36.4511],
            [-104.0082, 36.3781],
            [-103.9, 36.3778],
            [-103.9021, 36.261],
            [-104.0089, 36.218],
            [-104.4361, 36.2188],
            [-104.8655, 36.218],
            [-104.8652, 36.2611],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502490',
        NAME: 'Springer Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2680590862,
        AWATER: 6539573,
        INTPTLAT: '+36.3484780',
        INTPTLON: '-104.4048298',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.8569, 36.2303],
            [-105.8567, 36.3509],
            [-105.7286, 36.3505],
            [-105.733, 36.4766],
            [-105.6958, 36.6256],
            [-105.4247, 36.6091],
            [-105.4128, 36.5262],
            [-105.344, 36.5146],
            [-105.3251, 36.3886],
            [-105.3409, 36.2622],
            [-105.4004, 36.2305],
            [-105.5209, 36.1751],
            [-105.6626, 36.2384],
            [-105.8353, 36.2077],
            [-105.8569, 36.2303],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502520',
        NAME: 'Taos Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1651070393,
        AWATER: 151638,
        INTPTLAT: '+36.3895424',
        INTPTLON: '-105.5621694',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7668, 33.3119],
            [-103.7214, 33.3959],
            [-103.7207, 33.5697],
            [-103.5099, 33.5869],
            [-103.5103, 33.5703],
            [-103.0526, 33.5706],
            [-103.0565, 33.3884],
            [-103.0611, 33.1699],
            [-103.2142, 33.1698],
            [-103.2141, 33.0973],
            [-103.7664, 33.0985],
            [-103.7662, 33.1395],
            [-103.7668, 33.3119],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502550',
        NAME: 'Tatum Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3271975446,
        AWATER: 3240372,
        INTPTLAT: '+33.3341920',
        INTPTLON: '-103.4147891',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.2147, 34.3032],
            [-103.1083, 34.3611],
            [-103.1085, 34.6617],
            [-103.2143, 34.6474],
            [-103.1876, 34.8214],
            [-103.0429, 34.8211],
            [-103.0428, 34.7474],
            [-103.0433, 34.6505],
            [-103.0434, 34.5251],
            [-103.0438, 34.2144],
            [-103.0438, 34.1716],
            [-103.2307, 34.1716],
            [-103.2147, 34.3032],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502580',
        NAME: 'Texico Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 759429488,
        AWATER: 1853729,
        INTPTLAT: '+34.3391364',
        INTPTLON: '-103.0821439',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.7127, 33.4769],
            [-107.4489, 33.4764],
            [-106.4743, 33.4758],
            [-106.3731, 33.4801],
            [-106.3459, 33.3908],
            [-106.3405, 33.0528],
            [-106.8879, 32.8228],
            [-106.8877, 32.7793],
            [-107.2994, 32.7806],
            [-107.2995, 32.6055],
            [-107.6083, 32.6054],
            [-107.7323, 32.6274],
            [-107.7214, 32.7781],
            [-107.7731, 32.8538],
            [-107.7805, 32.9528],
            [-107.8567, 33.0025],
            [-107.8362, 33.0678],
            [-107.8756, 33.2026],
            [-108.0006, 33.2016],
            [-108.0006, 33.478],
            [-107.7127, 33.4769],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502610',
        NAME: 'Truth or Consequences Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10823675584,
        AWATER: 148054792,
        INTPTLAT: '+33.1194611',
        INTPTLON: '-107.1881533',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.1248, 35.1422],
            [-103.8575, 35.2422],
            [-103.6371, 35.2409],
            [-103.6371, 35.3375],
            [-103.5846, 35.2886],
            [-103.4065, 35.2883],
            [-103.4019, 35.2167],
            [-103.4958, 35.2159],
            [-103.4963, 35.0702],
            [-103.4441, 34.9987],
            [-103.4486, 34.9239],
            [-103.4766, 34.8657],
            [-103.6361, 34.8669],
            [-103.7403, 34.8361],
            [-103.9179, 34.8516],
            [-104.0711, 34.9111],
            [-104.1294, 34.8677],
            [-104.1248, 35.1422],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502640',
        NAME: 'Tucumcari Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2579163663,
        AWATER: 3480047,
        INTPTLAT: '+35.0745548',
        INTPTLON: '-103.7800146',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.3459, 33.3908],
            [-105.8979, 33.3901],
            [-105.7273, 33.3905],
            [-105.726, 33.3048],
            [-105.4195, 33.3049],
            [-105.319, 33.3051],
            [-105.3169, 33.1322],
            [-105.3162, 33.0027],
            [-105.7533, 33.0039],
            [-105.8315, 33.0271],
            [-106.3405, 33.0257],
            [-106.3405, 33.0528],
            [-106.3459, 33.3908],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502670',
        NAME: 'Tularosa Municipal Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3606339929,
        AWATER: 8519688,
        INTPTLAT: '+33.1665903',
        INTPTLON: '-105.8989081',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.1522, 36.2613],
            [-104.8652, 36.2611],
            [-104.8655, 36.218],
            [-104.4361, 36.2188],
            [-104.3657, 36.0443],
            [-104.3674, 35.7814],
            [-104.9225, 35.7739],
            [-104.9229, 35.8355],
            [-105.0252, 35.8349],
            [-105.059, 35.7801],
            [-105.0493, 35.9074],
            [-104.9985, 36.0397],
            [-104.999, 36.1574],
            [-105.1519, 36.1572],
            [-105.1522, 36.2613],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502730',
        NAME: 'Wagon Mound Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2986968878,
        AWATER: 3432846,
        INTPTLAT: '+36.0087287',
        INTPTLON: '-104.7381637',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.0462, 35.1747],
            [-108.8396, 35.1693],
            [-108.6816, 35.2188],
            [-108.6806, 35.3071],
            [-108.5391, 35.3069],
            [-108.5413, 34.9909],
            [-108.5029, 34.959],
            [-109.0459, 34.9597],
            [-109.0462, 35.1747],
          ],
        ],
      },
      properties: {
        STATEFP: '35',
        SCSDLEA: '',
        GEOID: '3502800',
        NAME: 'Zuni Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1305372997,
        AWATER: 2427693,
        INTPTLAT: '+35.0944239',
        INTPTLON: '-108.7613255',
        ELSDLEA: '',
        UNSDLEA: '02800',
      },
    },
  ],
};

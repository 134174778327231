import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.css';

import breadcrumbs from './breadcrumbs';

const Breadcrumbs = () => {
  const location = useLocation();
  let path = location.pathname;

  // Replace Id's hack. It trims the id from the route url.
  if (path.includes('replace')) {
    path = path.substring(0, path.lastIndexOf('/'));
  }

  if (path.includes('dashboard/update')) {
    path = path.substring(0, path.lastIndexOf('/'));
  }

  if (breadcrumbs[path] && breadcrumbs[path].length > 0) {
    return (
      <div className="breadcrumbs">
        <ul className="breadcrumbs-ul">
          {breadcrumbs[path].map((breadcrumb, i) => (
            <li className="breadcrumbs-item" key={breadcrumb.link}>
              {i < breadcrumbs[path].length - 1 && (
                <Link to={breadcrumb.link}>{breadcrumb.name}</Link>
              )}

              {i === breadcrumbs[path].length - 1 && (
                <span className="breadcrumbs-last-a">{breadcrumb.name}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

export default Breadcrumbs;

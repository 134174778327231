import React from 'react';
import shortid from 'shortid';
import { Container } from 'react-bootstrap';
import HomePage from '../../Components/HomePage';

import './styles.css';

const HomeLanding = () => {
  return (
    <Container fluid className="p-0" key={shortid.generate()}>
      <HomePage />
    </Container>
  );
};
export default React.memo(HomeLanding);

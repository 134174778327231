export function describeState(state) {
  let locationDescription = 'National';
  if (state != null) {
    locationDescription = state.name;
    if (state.district) {
      // Append district name
      locationDescription += ` - ${state.district?.name}`;
    }
  }

  return locationDescription;
}

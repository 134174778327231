/* eslint-disable consistent-return */
import axios from 'axios';
import queryStringParser from 'query-string';
import { API_ROOT } from '../config/appConfig';
import { getHeaders } from '../actions/helper.action';

export const getOrgListByUserApi = async (userId, jwt) => {
  try {
    const res = await axios.get(
      `${API_ROOT}/user-management/admin/users/${userId}/memberships`,
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getOrgListApi = async (filters, jwt) => {
  try {
    const filterString = filters ? `&${queryStringParser.stringify(filters)}` : '';
    const res = await axios.get(
      `${API_ROOT}/user-management/admin/organizations?members=true${filterString}`,
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const createOrgApi = async (newOrg, jwt) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/user-management/admin/organizations`,
      newOrg,
      getHeaders('application/json', jwt)
    );

    return res;
  } catch (err) {
    return err?.response?.data;
  }
};

export const updateOrgApi = async (updateOrg, jwt, orgId) => {
  try {
    const res = await axios.patch(
      `${API_ROOT}/user-management/admin/organizations/${orgId}`,
      updateOrg,
      getHeaders('application/json', jwt)
    );

    return res;
  } catch (err) {
    return err?.response?.data;
  }
};

export const deleteOrgApi = async (ids, jwt) => {
  try {
    const res = await axios.delete(`${API_ROOT}/user-management/admin/organizations/${ids}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    return res;
  } catch (err) {
    console.log(err);
    return err?.response?.data;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

import './styles.css';

const MAX_VISIBLE_PAGES = 5;

const WrapperPagination = ({ totalItems, pageSize, onPageChange, currentPage }) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  const pages = [];

  const handlePageButtonClick = (e) => {
    const { tagName } = e.target;
    const newPage =
      tagName === 'SPAN' ? e.target.parentNode.dataset.pagenumber : e.target.dataset.pagenumber;
    // eslint-disable-next-line radix
    onPageChange(Number.parseInt(newPage));
  };

  const firstPage = currentPage <= MAX_VISIBLE_PAGES ? 1 : currentPage - MAX_VISIBLE_PAGES + 1;
  const renderPages = totalPages < MAX_VISIBLE_PAGES ? totalPages : firstPage + MAX_VISIBLE_PAGES;

  // eslint-disable-next-line no-plusplus
  for (let i = firstPage; i <= renderPages; i++) {
    const pageNumber = i;
    pages.push({
      pageNumber,
      isCurrentPage: pageNumber === currentPage,
    });
  }

  return (
    <Pagination className="custom-pagination">
      <Pagination.Prev
        onClick={handlePageButtonClick}
        data-pagenumber={currentPage - 1}
        disabled={currentPage === 1}
      />
      {pages.map((p) => (
        <Pagination.Item
          key={p.pageNumber}
          onClick={handlePageButtonClick}
          active={p.isCurrentPage}
          data-pagenumber={p.pageNumber}
        >
          {p.pageNumber}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={handlePageButtonClick}
        data-pagenumber={currentPage + 1}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

WrapperPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default WrapperPagination;

import PropTypes from 'prop-types';
import React from 'react';
import types from '../../types';
import './requestMessage.css';

const RequestMessage = ({ reset, type }) => {
  const onClickHandler = () => {
    reset();
  };
  return (
    <div className="RequestMessage-message">
      <div className="RequestMessage-container">
        <div className="RequestMessage-header">
          {type === types.email.success && <>Success!</>}
          {type === types.email.error && <>Submission not completed</>}
        </div>
        <div className="RequestMessage-mesage">
          {type === types.email.success && (
            <>
              Thank you. Your free account request was sent successfully. You will be notified by
              email when your account is ready.
            </>
          )}
          {type === types.email.error && (
            <>
              There was an error, please try again.
              <br />
              If you continue to receive this message, please contact{' '}
              <a className="mail-link" href="mailto: bento@mgt.us">
                {' '}
                bento@mgt.us
              </a>
            </>
          )}
        </div>
        <div className="RequestMessage-button-container">
          <button type="button" className="button-text" onClick={onClickHandler}>
            <span>Okay</span>
          </button>
        </div>
      </div>
    </div>
  );
};

RequestMessage.propTypes = {
  reset: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default RequestMessage;

import axios from 'axios';
import { API_ROOT } from '../config/appConfig';
import providers from '../providers';
import types from '../types';
import { checkFileValidations } from './validation.action';

export const setWizard = (wizardName, currentUser) => (dispatch) => {
  const selectedProvider = providers.filter((el) => el.wizard === wizardName)[0];
  const { steps, informative, providerLabel, wizard, wizardRule } = selectedProvider;
  if (currentUser?.role === 'ORGANIZATION_ADMIN') {
    steps.step0.ui[0].required = true;
  }
  dispatch({
    type: types.reducerTypes.SET_PROVIDER,
    payload: {
      wizard,
      steps,
      informative,
      providerLabel,
      wizardRule,
    },
  });
};

export const setFileProgress = (file) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SET_FILE_PROGRESS, payload: { file } });
};

export const setRelatedSurveyId = (relatedSurveyId) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SET_RELATED_SURVEY_ID, relatedSurveyId });
};

export const setRelatedOrgId = (relatedOrgId) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SET_RELATED_ORG_ID, relatedOrgId });
};

export const setFileSuccess = (file) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SET_FILE_SUCCESS, payload: { file } });
  // Every Time A file is uploaded, I check for all the validations.
  dispatch(checkFileValidations());
};

export const createNewAwsFolder = (jwt) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/admin-backend/upload/newFolder/rand`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (res && res.status === 200) {
      dispatch({ type: types.reducerTypes.SET_AWS_FOLDER, payload: res.data });
      return;
    }
    dispatch({ type: types.reducerTypes.SET_AWS_FOLDER_ERR });
  } catch (err) {
    if (err) {
      dispatch({ type: types.reducerTypes.SET_AWS_FOLDER_ERR });
    }
  }
};

export const selectFile = (file) => (dispatch) => {
  dispatch({ type: types.reducerTypes.SELECTED_FILE, payload: file });
};

export const setInputValue = (target) => (dispatch) => {
  const { name, value } = target;
  dispatch({ type: types.reducerTypes.SET_INPUT_VALUE, payload: { name, value } });
};

export const setCheckboxValue = (target, checkboxGroupLabel) => (dispatch) => {
  const { name, checked } = target;
  dispatch({
    type: types.reducerTypes.SET_CHECKBOX_VALUE,
    payload: { name, value: checked, checkboxGroupLabel },
  });
};

export const resetWizard = () => (dispatch) => {
  dispatch({ type: types.reducerTypes.RESET_WIZARD });
  dispatch({ type: types.reducerTypes.RESET_VALIDATIONS });
};

export const setReplaceData = (surveyId, surveyParentId, orgId) => (dispatch) => {
  dispatch({
    type: types.reducerTypes.SET_REPLACE_DATA,
    payload: { surveyId, surveyParentId, orgId },
  });
};

export const selectedSurvey = (Id, isDraft, parentId) => (dispatch) =>
  dispatch({ type: types.reducerTypes.SELECTED_SURVEY, payload: { Id, isDraft, parentId } });

export const removeFiles = () => (dispatch) => {
  // Every time a file is removed, check for all validations
  dispatch({ type: types.reducerTypes.REMOVE_FILE });
  dispatch(checkFileValidations());
};

/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, OverlayTrigger, Tooltip, Form, Collapse } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import shortid from 'shortid';
import './styles.css';

const YES_NO_DEFAULT = [
  {
    description: 'Yes',
    code: 1,
    constraint: {
      '@class': 'CategoryConstraint',
      value: 1,
      operator: 'EQUAL_TO',
    },
  },
  {
    description: 'No',
    code: 0,
    constraint: {
      '@class': 'CategoryConstraint',
      value: 0,
      operator: 'EQUAL_TO',
    },
  },
];

const SurveyFiltersSegments = ({
  filters,
  handleApplyFilter,
  visualizations,
  removeFilter,
  handleApplySegment,
  removeSegment,
  toggleAllRespondents,
  toggleSegment,
}) => {
  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [floatingFilters, setFloatingFilters] = useState(false);
  const [filterSteps, setFilterStep] = useState(0);
  const [filterType, setFilterType] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [selectedAttributeOptions, setSelectedAttributeOptions] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = useState();
  const [selectedQuestionAnswerOptions, setSelectedQuestionAnswerOptions] = useState();
  const [appliedFilters, setAppliedFilters] = useState([]);
  // Segments
  const [showSegments, setShowSegments] = useState(false);
  const [segmentName, setSegmentName] = useState('');
  const [segmentEditId, setSegmentEditId] = useState(0);
  const [segmentsSteps, setSegmentsStep] = useState(0);
  const [segmentsType, setSegmentsType] = useState(0);
  const [selectedSegmentsLocations, setSelectedSegmentsLocations] = useState();
  const [selectedSegmentsAttribute, setSelectedSegmentsAttribute] = useState();
  const [selectedSegmentsAttributeOptions, setSelectedSegmentsAttributeOptions] = useState();
  const [selectedSegmentsQuestion, setSelectedSegmentsQuestion] = useState();
  const [selectedSegmentsQuestionAnswer, setSelectedSegmentsQuestionAnswer] = useState();
  const [
    selectedSegmentsQuestionAnswerOptions,
    setSelectedSegmentsQuestionAnswerOptions,
  ] = useState();
  const [appliedSegments, setAppliedSegments] = useState([]);

  const handleScrollFilters = () => {
    setFloatingFilters(false);
    if (window.scrollY > 100) {
      setFloatingFilters(true);
    }
  };
  useEffect(() => {
    if (visualizations?.details) {
      setAppliedFilters(visualizations?.details?.filter.constraintGroups);
      setAppliedSegments(visualizations?.details?.segments);
    }
  }, [visualizations]);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollFilters);
    return () => {
      document.removeEventListener('scroll', handleScrollFilters);
    };
  }, []);
  // Filters
  const clearSegments = () => {
    setSegmentsStep(0);
    setSegmentsType('');
    setSegmentName('');
    setSegmentEditId(0);
    setSelectedSegmentsLocations([]);
    setSelectedSegmentsAttribute([]);
    setSelectedSegmentsAttributeOptions([]);
    setSelectedSegmentsQuestion([]);
    setSelectedSegmentsQuestionAnswer([]);
    setSelectedSegmentsQuestionAnswerOptions([]);
  };

  const clearFilter = () => {
    setFilterStep(0);
    setFilterType('');
    setSelectedLocations([]);
    setSelectedAttribute([]);
    setSelectedAttributeOptions([]);
    setSelectedQuestion([]);
    setSelectedQuestionAnswer([]);
    setSelectedQuestionAnswerOptions([]);
  };

  const handleFilterClick = () => {
    setShowFilters(!showFilters);
    clearFilter();
    setShowSegments(false);
    clearSegments();
  };

  const handleSelectFilterType = (e) => {
    setFilterStep(1);
    setFilterType(e.target.name);
    setSelectedLocations([]);
    setSelectedAttribute(null);
    setSelectedAttributeOptions([]);
    setSelectedQuestion(null);
    setSelectedQuestionAnswer(null);
    setSelectedQuestionAnswerOptions([]);
  };

  const handleBackStep = () => {
    if (filterType !== 'QUESTION') {
      setFilterStep(filterSteps - 1);
    }
    if (selectedQuestion.options.length === 1) {
      setFilterStep(filterSteps - 2);
    } else {
      setFilterStep(filterSteps - 1);
    }
  };

  const handleLocationFilterChange = (selectedOptions) => setSelectedLocations(selectedOptions);

  const handleApplyLocationFilter = () => {
    const locationList = filters?.find((item) => item.type === 'LOCATION');
    const locationFilter = {
      field: filterType,
      fieldDescription: null,
      subfield: null,
      type: filterType.toUpperCase(),
      suppressedRelatedResult: false,
      constraints: selectedLocations.map((sl) => {
        const locationItem = locationList.options.find((l) => l.code === sl.value);
        return locationItem.constraint;
      }),
    };
    handleApplyFilter(locationFilter);
    handleFilterClick();
  };

  const handleAttributeFilterChange = (selectedOption) => {
    const attributeOption = filters.find(
      (item) => item.type === 'ATTRIBUTE' && selectedOption.value === item.code
    );
    setSelectedAttribute(attributeOption);
    if (attributeOption.options[0].options) {
      setSelectedAttributeOptions(
        attributeOption.options[0].options.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    } else {
      setSelectedAttributeOptions(
        attributeOption.options.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    }
    setFilterStep(2);
  };

  const handleAttributeOptionsChange = (selectedOptions) => {
    setSelectedAttributeOptions(selectedOptions);
  };

  const checkSuppressRelatedQuestion = (aFilter) => {
    let mustSupRelQuestion = false;
    const suppressRelatedQuestions = aFilter.options.filter(
      (item) => item.suppressedRelatedResult === true
    );

    if (suppressRelatedQuestions && suppressRelatedQuestions.length > 0) {
      mustSupRelQuestion = true;
    }

    return mustSupRelQuestion;
  };
  const handleApplyAttributeFilter = () => {
    const attributeOption = filters.find(
      (item) => item.type === 'ATTRIBUTE' && selectedAttribute.code === item.code
    );
    const attributeFilter = {
      field: attributeOption.code,
      subfield: null,
      suppressedRelatedResult: checkSuppressRelatedQuestion(attributeOption),
      fieldDescription: attributeOption.description,
      type: 'GENERAL',
      constraints: selectedAttributeOptions.map((sa) => {
        let opt = {};
        if (attributeOption.options[0].options) {
          opt = attributeOption.options[0].options.find((o) => o.code === sa.value);
        } else {
          opt = attributeOption.options.find((o) => o.code === sa.value);
        }
        return opt?.constraint;
      }),
    };
    handleApplyFilter(attributeFilter);
    handleFilterClick();
  };

  const handleQuestionFilterChange = (selectedOption) => {
    const questionOption = filters.find(
      (item) => item.type === 'QUESTION' && selectedOption.value === item.code
    );
    setSelectedQuestion(questionOption);
    const questionOptions = questionOption.options?.filter((q) => !q.code.includes('_T_'));
    if (questionOptions.length === 1) {
      const answerOption = questionOptions[0];
      setSelectedQuestionAnswer(answerOption);
      if (answerOption.options) {
        setSelectedQuestionAnswerOptions(
          answerOption.options.map((l) => ({
            label: l.description,
            value: l.code,
          }))
        );
      } else {
        setSelectedQuestionAnswerOptions(
          YES_NO_DEFAULT.map((l) => ({
            label: l.description,
            value: l.code,
          }))
        );
      }
      setFilterStep(3);
    } else {
      setFilterStep(2);
    }
  };

  const handleQuestionAnswerChange = (selectedOption) => {
    const questionOption = filters.find(
      (item) => item.type === 'QUESTION' && selectedQuestion.code === item.code
    );
    const answerOption = questionOption.options.find((item) => selectedOption.value === item.code);
    setSelectedQuestionAnswer(answerOption);
    if (answerOption.options) {
      setSelectedQuestionAnswerOptions(
        answerOption.options.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    } else {
      setSelectedQuestionAnswerOptions(
        YES_NO_DEFAULT.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    }
    setFilterStep(3);
  };

  const handleQuestionAnswerOptionsChange = (selectedOptions) => {
    setSelectedQuestionAnswerOptions(selectedOptions);
  };

  const handleApplyQuestionFilter = () => {
    const questionOption = filters.find(
      (item) => item.type === 'QUESTION' && selectedQuestion.code === item.code
    );
    const answerOption = questionOption.options.find(
      (item) => selectedQuestionAnswer.code === item.code
    );

    const questionFilter = {
      field: answerOption.referenceId.toString(),
      fieldDescription: null,
      subfield: answerOption.referenceId.toString(),
      suppressedRelatedResult: checkSuppressRelatedQuestion(questionOption),
      type: filterType.toUpperCase(),
      constraints: [answerOption.constraint],
    };
    if (answerOption.options) {
      questionFilter.constraints = selectedQuestionAnswerOptions.map((sa) => {
        const opt = answerOption.options.find((o) => o.code === sa.value);
        return opt?.constraint;
      });
    } else {
      questionFilter.constraints = selectedQuestionAnswerOptions.map((sa) => {
        const opt = YES_NO_DEFAULT.find((o) => o.code === sa.value);
        return opt?.constraint;
      });
    }
    handleApplyFilter(questionFilter);
    handleFilterClick();
  };

  const editFilter = (currentFilter) => {
    clearSegments();
    setShowSegments(false);
    setShowFilters(true);
    if (currentFilter.type === 'LOCATION') {
      setFilterStep(1);
      setFilterType('location');
      setSelectedLocations(
        currentFilter.constraints.map((c) => ({ value: c.value, label: c.valueDescription }))
      );
      setSelectedAttribute(null);
      setSelectedAttributeOptions([]);
      setSelectedQuestion(null);
      setSelectedQuestionAnswer(null);
      setSelectedQuestionAnswerOptions([]);
    }

    if (currentFilter.type === 'GENERAL') {
      setFilterStep(2);
      setFilterType('attribute');
      setSelectedLocations([]);
      const attributeOption = filters.find(
        (item) => item.type === 'ATTRIBUTE' && currentFilter.field === item.code
      );
      setSelectedAttribute(attributeOption);
      setSelectedAttributeOptions(
        currentFilter.constraints.map((c) => ({ value: c.value, label: c.valueDescription }))
      );
      setSelectedQuestion(null);
      setSelectedQuestionAnswer(null);
      setSelectedQuestionAnswerOptions([]);
    }

    if (currentFilter.type === 'QUESTION') {
      setFilterType('question');
      setSelectedLocations([]);
      const questionOption = filters.find(
        (item) =>
          item.type === 'QUESTION' &&
          item.options.some((o) => currentFilter.field === o.referenceId.toString())
      );
      setSelectedQuestion(questionOption);

      const answerOption = questionOption.options.find(
        (o) => currentFilter.field === o.referenceId.toString()
      );
      setSelectedQuestionAnswer(answerOption);
      if (answerOption.options) {
        setSelectedQuestionAnswerOptions(
          currentFilter.constraints.map((c) => {
            const opt = answerOption.options.find((o) => o.code === c.value.toString());
            return { value: opt.code, label: opt.description };
          })
        );
      }
      setFilterStep(3);
    }
  };

  // Segments
  const handleSegmentClick = () => {
    setShowFilters(false);
    clearFilter();
    setShowSegments(!showSegments);
    clearSegments();
  };

  const handleSelectSegmentType = (e) => {
    setSegmentsStep(1);
    setSegmentsType(e.target.name);
    setSelectedSegmentsLocations([]);
    setSelectedSegmentsAttribute([]);
    setSelectedSegmentsAttributeOptions([]);
    setSelectedSegmentsQuestion([]);
    setSelectedSegmentsQuestionAnswer([]);
    setSelectedSegmentsQuestionAnswerOptions([]);
  };

  const handleBackStepSegment = () => {
    if (filterType !== 'QUESTION') {
      setSegmentsStep(segmentsSteps - 1);
    }
    if (selectedSegmentsQuestion.options.length === 1) {
      setSegmentsStep(segmentsSteps - 2);
    } else {
      setSegmentsStep(segmentsSteps - 1);
    }
  };

  const handleLocationSegmentChange = (selectedOptions) =>
    setSelectedSegmentsLocations(selectedOptions);

  const handleApplyLocationSegment = () => {
    const locationList = filters?.find((item) => item.type === 'LOCATION');
    let newId = segmentEditId === 0 ? 1 : segmentEditId;
    if (newId === 1 && visualizations?.details?.segments?.length && segmentEditId === 0) {
      newId = Math.max(...visualizations?.details?.segments?.map((s) => s.id)) + 1;
    }
    const locationConstraint = {
      field: segmentsType,
      fieldDescription: null,
      subfield: null,
      type: segmentsType.toUpperCase(),
      constraints: selectedSegmentsLocations.map((sl) => {
        const locationItem = locationList.options.find((l) => l.code === sl.value);
        return locationItem.constraint;
      }),
    };

    const locationSegment = {
      active: true,
      constraintGroups: [locationConstraint],
      label: segmentName,
      numberOfRespondents: null,
      id: newId,
    };
    handleApplySegment(locationSegment);
    handleSegmentClick();
  };

  const handleAttributeSegmentChange = (selectedOption) => {
    const attributeOption = filters.find(
      (item) => item.type === 'ATTRIBUTE' && selectedOption.value === item.code
    );
    setSelectedSegmentsAttribute(attributeOption);
    if (attributeOption.options[0].options) {
      setSelectedSegmentsAttributeOptions(
        attributeOption.options[0].options.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    } else {
      setSelectedSegmentsAttributeOptions(
        attributeOption.options.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    }
    setSegmentsStep(2);
  };

  const handleAttributeOptionsSegmentChange = (selectedOptions) => {
    setSelectedSegmentsAttributeOptions(selectedOptions);
  };
  const handleApplyAttributeSegment = () => {
    const attributeOption = filters.find(
      (item) => item.type === 'ATTRIBUTE' && selectedSegmentsAttribute.code === item.code
    );
    const attributeConstraint = {
      field: attributeOption.code,
      subfield: null,
      fieldDescription: attributeOption.description,
      type: 'GENERAL',
      constraints: selectedSegmentsAttributeOptions.map((sa) => {
        let opt = {};
        if (attributeOption.options[0].options) {
          opt = attributeOption.options[0].options.find((o) => o.code === sa.value);
        } else {
          opt = attributeOption.options.find((o) => o.code === sa.value);
        }
        return opt?.constraint;
      }),
    };
    let newId = segmentEditId === 0 ? 1 : segmentEditId;
    if (newId === 1 && visualizations?.details?.segments?.length && segmentEditId === 0) {
      newId = Math.max(...visualizations?.details?.segments?.map((s) => s.id)) + 1;
    }
    const attributeSegment = {
      active: true,
      constraintGroups: [attributeConstraint],
      label: segmentName,
      numberOfRespondents: null,
      id: newId,
    };
    handleApplySegment(attributeSegment);
    handleSegmentClick();
  };

  const handleQuestionSegmentChange = (selectedOption) => {
    const questionOption = filters.find(
      (item) => item.type === 'QUESTION' && selectedOption.value === item.code
    );
    setSelectedSegmentsQuestion(questionOption);
    const questionOptions = questionOption.options?.filter((q) => !q.code.includes('_T_'));
    if (questionOptions.length === 1) {
      const answerOption = questionOptions[0];
      setSelectedSegmentsQuestionAnswer(answerOption);
      if (answerOption.options) {
        setSelectedSegmentsQuestionAnswerOptions(
          answerOption.options.map((l) => ({
            label: l.description,
            value: l.code,
          }))
        );
      } else {
        setSelectedSegmentsQuestionAnswerOptions(
          YES_NO_DEFAULT.map((l) => ({
            label: l.description,
            value: l.code,
          }))
        );
      }
      setSegmentsStep(3);
    } else {
      setSegmentsStep(2);
    }
  };

  const handleQuestionAnswerSegmentChange = (selectedOption) => {
    const questionOption = filters.find(
      (item) => item.type === 'QUESTION' && selectedSegmentsQuestion.code === item.code
    );
    const answerOption = questionOption.options.find((item) => selectedOption.value === item.code);
    setSelectedSegmentsQuestionAnswer(answerOption);
    if (answerOption.options) {
      setSelectedSegmentsQuestionAnswerOptions(
        answerOption.options.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    } else {
      setSelectedSegmentsQuestionAnswerOptions(
        YES_NO_DEFAULT.map((l) => ({
          label: l.description,
          value: l.code,
        }))
      );
    }
    setSegmentsStep(3);
  };

  const handleQuestionAnswerOptionsSegmentChange = (selectedOptions) => {
    setSelectedSegmentsQuestionAnswerOptions(selectedOptions);
  };

  const handleApplyQuestionSegment = () => {
    const questionOption = filters.find(
      (item) => item.type === 'QUESTION' && selectedSegmentsQuestion.code === item.code
    );
    const answerOption = questionOption.options.find(
      (item) => selectedSegmentsQuestionAnswer.code === item.code
    );
    const firstAnswerOption = questionOption.options[0];

    const questionConstraint = {
      field: firstAnswerOption.referenceId.toString(),
      fieldDescription: null,
      subfield: answerOption.referenceId.toString(),
      type: segmentsType.toUpperCase(),
      constraints: [answerOption.constraint],
    };
    if (answerOption.options) {
      questionConstraint.constraints = selectedSegmentsQuestionAnswerOptions.map((sa) => {
        const opt = answerOption.options.find((o) => o.code === sa.value);
        return opt?.constraint;
      });
    } else {
      questionConstraint.constraints = selectedSegmentsQuestionAnswerOptions.map((sa) => {
        const opt = YES_NO_DEFAULT.find((o) => o.code === sa.value);
        return opt?.constraint;
      });
    }
    let newId = segmentEditId === 0 ? 1 : segmentEditId;
    if (newId === 1 && visualizations?.details?.segments?.length && segmentEditId === 0) {
      newId = Math.max(...visualizations?.details?.segments?.map((s) => s.id)) + 1;
    }
    const questionSegment = {
      active: true,
      constraintGroups: [questionConstraint],
      label: segmentName,
      numberOfRespondents: null,
      id: newId,
    };
    handleApplySegment(questionSegment);
    handleSegmentClick();
  };

  const editSegment = (currentSegment) => {
    clearFilter();
    setShowFilters(false);
    setShowSegments(true);
    setSegmentsStep(1);
    setSegmentName(currentSegment.label);
    setSegmentEditId(currentSegment.id);
    const filterSegment = currentSegment.constraintGroups[0];
    if (filterSegment.type === 'LOCATION') {
      setSegmentsType('location');
      setSelectedSegmentsLocations(
        filterSegment.constraints.map((c) => ({ value: c.value, label: c.valueDescription }))
      );
      setSelectedSegmentsAttribute(null);
      setSelectedSegmentsAttributeOptions([]);
      setSelectedSegmentsQuestion(null);
      setSelectedSegmentsQuestionAnswer(null);
      setSelectedSegmentsQuestionAnswerOptions([]);
    }
    if (filterSegment.type === 'GENERAL') {
      setSegmentsStep(2);
      setSegmentsType('attribute');
      setSelectedSegmentsLocations([]);
      const attributeOption = filters.find(
        (item) => item.type === 'ATTRIBUTE' && filterSegment.field === item.code
      );
      setSelectedSegmentsAttribute(attributeOption);
      setSelectedSegmentsAttributeOptions(
        filterSegment.constraints.map((c) => ({ value: c.value, label: c.valueDescription }))
      );
      setSelectedSegmentsQuestion(null);
      setSelectedSegmentsQuestionAnswer(null);
      setSelectedSegmentsQuestionAnswerOptions([]);
    }

    if (filterSegment.type === 'QUESTION') {
      setSegmentsStep(3);
      setSegmentsType('question');
      setSelectedSegmentsLocations([]);
      setSelectedSegmentsAttribute(null);
      setSelectedSegmentsAttributeOptions([]);
      const questionOption = filters.find(
        (item) =>
          item.type === 'QUESTION' &&
          item.options.some((o) => filterSegment.field === o.referenceId.toString())
      );
      setSelectedSegmentsQuestion(questionOption);

      const answerOption = questionOption.options.find(
        (o) => filterSegment.field === o.referenceId.toString()
      );
      setSelectedSegmentsQuestionAnswer(answerOption);
      if (answerOption.options) {
        setSelectedSegmentsQuestionAnswerOptions(
          filterSegment.constraints.map((c) => {
            const opt = answerOption.options.find((o) => o.code === c.value.toString());
            return { value: opt.code, label: opt.description };
          })
        );
      }
    }
  };

  const handleSegmentName = (e) => {
    setSegmentName(e.target.value);
  };

  return (
    <>
      <div className={`w-100 ${floatingFilters ? 'position-fixed floating-filters' : ''}`}>
        <Row noGutters className="text-center text-dark bg-gray-100 p-3">
          <Col>
            <Button
              className="text-reset text-dark font-weight-bold mr-5"
              onClick={handleFilterClick}
              variant="link"
            >
              Filters {appliedFilters.length > 0 && <span>({appliedFilters.length})</span>}
            </Button>{' '}
            |{' '}
            <Button
              onClick={handleSegmentClick}
              className="text-reset text-dark font-weight-bold ml-5"
              variant="link"
            >
              Segments {appliedSegments.length > 0 && <span>({appliedSegments.length})</span>}
            </Button>
          </Col>
        </Row>
        {/* Filters Section */}
        <Collapse in={showFilters}>
          <Row
            noGutters
            className={filterSteps === 0 ? 'text-center text-dark bg-gray-500 py-5' : null}
          >
            {filterSteps === 0 && (
              <Col>
                <h4>Add Filter By</h4>
                <span className="d-block my-3">
                  Display only certain responses based on criteria you select.
                </span>
                <Button
                  className="btn-filter-select"
                  name="location"
                  onClick={handleSelectFilterType}
                  variant="outline-dark"
                  disabled={visualizations?.details?.filter?.constraintGroups?.some(
                    (cg) => cg.type === 'LOCATION'
                  )}
                >
                  Location
                </Button>
                <Button
                  name="attribute"
                  onClick={handleSelectFilterType}
                  className="btn-filter-select mx-5"
                  variant="outline-dark"
                >
                  Attribute
                </Button>
                <Button
                  name="question"
                  onClick={handleSelectFilterType}
                  className="btn-filter-select"
                  variant="outline-dark"
                >
                  Question
                </Button>
              </Col>
            )}
            {showFilters && filterSteps >= 1 && filterType && (
              <>
                <Col md={1}>
                  <span className="d-flex align-items-center bg-black-100 text-white p-5 text-center text-capitalize m-h-md">
                    Add Filters by {filterType}
                  </span>
                  <Button
                    className="bg-black text-white w-100 border-0 rounded-0"
                    onClick={handleBackStep}
                  >
                    Back
                  </Button>
                </Col>
                {filterType === 'location' && filterSteps === 1 && (
                  <Col
                    md={11}
                    className="text-center text-dark bg-gray-500 p-4 d-flex justify-content-center align-items-center"
                  >
                    <div className="w-50 ml-5 mr-3">
                      <Select
                        placeholder="Select Locations"
                        isMulti
                        closeMenuOnSelect={false}
                        defaultValue={selectedLocations}
                        options={(
                          filters?.filter((item) => item.type === 'LOCATION')[0] || {}
                        ).options?.map((l) => ({
                          label: l.description,
                          value: l.code,
                        }))}
                        onChange={handleLocationFilterChange}
                      />
                    </div>
                    {selectedLocations?.length > 0 && (
                      <Button
                        className="btn-filter-select bg-black text-white border-0 rounded-0"
                        onClick={handleApplyLocationFilter}
                      >
                        Apply
                      </Button>
                    )}
                  </Col>
                )}
                {filterType === 'attribute' && (
                  <Col
                    md={11}
                    className="text-center text-dark bg-gray-500 p-4 d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex justify-content-center align-items-end w-100">
                      <div className="w-50 ml-5 mr-3">
                        {filterSteps === 1 && (
                          <Select
                            placeholder="Select Attribute"
                            options={filters
                              ?.filter((item) => item.type === 'ATTRIBUTE')
                              .map((l) => ({
                                label: l.label,
                                value: l.code,
                              }))}
                            onChange={handleAttributeFilterChange}
                          />
                        )}
                        {filterSteps === 2 && selectedAttribute && (
                          <>
                            <h3 className="mb-3">{selectedAttribute.label}</h3>
                            <Select
                              defaultValue={selectedAttributeOptions}
                              options={(
                                selectedAttribute.options[0].options || selectedAttribute.options
                              ).map((l) => ({
                                label: l.description,
                                value: l.code,
                              }))}
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={handleAttributeOptionsChange}
                            />
                          </>
                        )}
                      </div>
                      {filterSteps === 2 && selectedAttribute && (
                        <Button
                          className="btn-filter-select bg-black text-white border-0 rounded-0"
                          onClick={handleApplyAttributeFilter}
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </Col>
                )}
                {filterType === 'question' && (
                  <Col
                    md={11}
                    className="text-center text-dark bg-gray-500 p-4 d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex justify-content-center align-items-end w-100">
                      <div className="w-50 ml-5 mr-3">
                        {filterSteps === 1 && (
                          <Select
                            placeholder="Select Question"
                            options={filters
                              ?.filter((item) => item.type === 'QUESTION')
                              .map((l) => ({
                                label: l.label,
                                value: l.code,
                              }))}
                            onChange={handleQuestionFilterChange}
                          />
                        )}
                        {filterSteps === 2 && selectedQuestion && (
                          <>
                            <h3 className="mb-3">{selectedQuestion.label}</h3>
                            <Select
                              placeholder="Select Answer"
                              options={selectedQuestion.options.map((l) => ({
                                label: l.description,
                                value: l.code,
                              }))}
                              onChange={handleQuestionAnswerChange}
                            />
                          </>
                        )}
                        {filterSteps === 3 && selectedQuestionAnswer && (
                          <>
                            <h3 className="mb-3">
                              {selectedQuestion.label === selectedQuestionAnswer.description
                                ? selectedQuestion.label
                                : `${selectedQuestion.label}: ${selectedQuestionAnswer.description}`}
                            </h3>
                            <Select
                              placeholder="Select Option"
                              defaultValue={selectedQuestionAnswerOptions}
                              options={(selectedQuestionAnswer.options || YES_NO_DEFAULT).map(
                                (l) => ({
                                  label: l.description,
                                  value: l.code,
                                })
                              )}
                              isMulti
                              onChange={handleQuestionAnswerOptionsChange}
                            />
                          </>
                        )}
                      </div>
                      {filterSteps === 3 && selectedQuestionAnswer && (
                        <Button
                          className="btn-filter-select bg-black text-white border-0 rounded-0"
                          onClick={handleApplyQuestionFilter}
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Collapse>
        {/* Segments Section */}
        <Collapse in={showSegments}>
          <Row
            noGutters
            className={segmentsSteps === 0 ? 'text-center text-dark bg-gray-500 py-5' : null}
          >
            {segmentsSteps === 0 && (
              <>
                <Col className="border-right border-dark">
                  <h4>Segments Applied</h4>
                  <Row noGutters className="d-flex flex-wrap px-5">
                    <Col className="p-2 ml-2 d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        checked={visualizations?.details?.enableDefaultSegment}
                        onChange={toggleAllRespondents}
                        disabled={appliedSegments?.length === 0}
                      />
                      All Respondents
                    </Col>
                    {appliedSegments.map((ap) => (
                      <Col key={shortid.generate()} className="p-2 ml-2 d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          checked={ap.active}
                          onChange={toggleSegment}
                          value={ap.id}
                        />
                        <FontAwesomeIcon
                          className="cursor-pointer ml-3"
                          onClick={() => removeSegment(ap)}
                          icon={faTimesCircle}
                        />
                        <FontAwesomeIcon
                          onClick={() => editSegment(ap)}
                          className="cursor-pointer mx-3"
                          icon={faPencilAlt}
                        />
                        {ap.label}
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col>
                  <h4>Add Segment By</h4>
                  <span className="d-block my-3">
                    Display only certain responses based on criteria you select.
                  </span>
                  <Button
                    className="btn-filter-select"
                    name="location"
                    onClick={handleSelectSegmentType}
                    variant="outline-dark"
                  >
                    Location
                  </Button>
                  <Button
                    name="attribute"
                    onClick={handleSelectSegmentType}
                    className="btn-filter-select mx-5"
                    variant="outline-dark"
                  >
                    Attribute
                  </Button>
                  <Button
                    name="question"
                    onClick={handleSelectSegmentType}
                    className="btn-filter-select"
                    variant="outline-dark"
                  >
                    Question
                  </Button>
                </Col>
              </>
            )}
            {segmentsSteps >= 1 && segmentsType && (
              <>
                <Col md={1}>
                  <span className="d-block bg-black-100 text-white p-5 text-center text-capitalize">
                    Add Segments by {segmentsType}
                  </span>
                  <Button
                    className="bg-black text-white w-100 border-0 rounded-0"
                    onClick={handleBackStepSegment}
                  >
                    Back
                  </Button>
                </Col>
                {segmentsType === 'location' && segmentsSteps === 1 && (
                  <Col
                    md={11}
                    className="text-center text-dark bg-gray-500 p-4 d-flex justify-content-center align-items-center"
                  >
                    <div className="w-50 ml-5 mr-3">
                      <Form.Control
                        value={segmentName}
                        type="text"
                        className="mb-2"
                        placeholder="Enter segment name"
                        onChange={handleSegmentName}
                      />
                      <Select
                        placeholder="Select Locations"
                        isMulti
                        closeMenuOnSelect={false}
                        defaultValue={selectedSegmentsLocations}
                        options={(
                          filters?.filter((item) => item.type === 'LOCATION')[0] || {}
                        ).options?.map((l) => ({
                          label: l.description,
                          value: l.code,
                        }))}
                        onChange={handleLocationSegmentChange}
                      />
                    </div>
                    <Button
                      className="btn-filter-select bg-black text-white border-0 rounded-0"
                      onClick={handleApplyLocationSegment}
                      disabled={
                        segmentName === '' &&
                        (!selectedSegmentsLocations || selectedSegmentsLocations?.length === 0)
                      }
                    >
                      Apply
                    </Button>
                  </Col>
                )}
                {segmentsType === 'attribute' && (
                  <Col
                    md={11}
                    className="text-center text-dark bg-gray-500 p-4 d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex justify-content-center align-items-end w-100">
                      <div className="w-50 ml-5 mr-3">
                        <Form.Control
                          value={segmentName}
                          type="text"
                          className="mb-2"
                          placeholder="Enter segment name"
                          onChange={handleSegmentName}
                        />
                        {segmentsSteps === 1 && (
                          <Select
                            placeholder="Select Attribute"
                            options={filters
                              ?.filter((item) => item.type === 'ATTRIBUTE')
                              .map((l) => ({
                                label: l.label,
                                value: l.code,
                              }))}
                            onChange={handleAttributeSegmentChange}
                          />
                        )}
                        {segmentsSteps === 2 && selectedSegmentsAttribute && (
                          <>
                            <h3 className="mb-3">{selectedSegmentsAttribute.label}</h3>
                            <Select
                              defaultValue={selectedSegmentsAttributeOptions}
                              options={(
                                selectedSegmentsAttribute.options[0].options ||
                                selectedSegmentsAttribute.options
                              ).map((l) => ({
                                label: l.description,
                                value: l.code,
                              }))}
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={handleAttributeOptionsSegmentChange}
                            />
                          </>
                        )}
                      </div>
                      {segmentsSteps === 2 && selectedSegmentsAttribute && (
                        <Button
                          className="btn-filter-select bg-black text-white border-0 rounded-0"
                          onClick={handleApplyAttributeSegment}
                          disabled={segmentName === ''}
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </Col>
                )}
                {segmentsType === 'question' && (
                  <Col
                    md={11}
                    className="text-center text-dark bg-gray-500 p-4 d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex justify-content-center align-items-end w-100">
                      <div className="w-50 ml-5 mr-3">
                        <Form.Control
                          value={segmentName}
                          type="text"
                          className="mb-2"
                          placeholder="Enter segment name"
                          onChange={handleSegmentName}
                        />
                        {segmentsSteps === 1 && (
                          <Select
                            placeholder="Select Question"
                            options={filters
                              ?.filter((item) => item.type === 'QUESTION')
                              .map((l) => ({
                                label: l.label,
                                value: l.code,
                              }))}
                            onChange={handleQuestionSegmentChange}
                          />
                        )}
                        {segmentsSteps === 2 && selectedSegmentsQuestion && (
                          <>
                            <h3 className="mb-3">{selectedSegmentsQuestion.label}</h3>
                            <Select
                              placeholder="Select Answer"
                              options={selectedSegmentsQuestion.options.map((l) => ({
                                label: l.description,
                                value: l.code,
                              }))}
                              onChange={handleQuestionAnswerSegmentChange}
                            />
                          </>
                        )}
                        {segmentsSteps === 3 && selectedSegmentsQuestionAnswer && (
                          <>
                            <h3 className="mb-3">
                              {selectedSegmentsQuestion.label ===
                              selectedSegmentsQuestionAnswer.description
                                ? selectedSegmentsQuestion.label
                                : `${selectedSegmentsQuestion.label}: ${selectedSegmentsQuestionAnswer.description}`}
                            </h3>
                            <Select
                              placeholder="Select Option"
                              defaultValue={selectedSegmentsQuestionAnswerOptions}
                              options={(
                                selectedSegmentsQuestionAnswer.options || YES_NO_DEFAULT
                              ).map((l) => ({
                                label: l.description,
                                value: l.code,
                              }))}
                              isMulti
                              onChange={handleQuestionAnswerOptionsSegmentChange}
                            />
                          </>
                        )}
                      </div>
                      {segmentsSteps === 3 && selectedSegmentsQuestionAnswer && (
                        <Button
                          className="btn-filter-select bg-black text-white border-0 rounded-0"
                          onClick={handleApplyQuestionSegment}
                          disabled={segmentName === ''}
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Collapse>
        {appliedFilters.length > 0 && (
          <Row
            noGutters
            className="p-3 bg-white survey-filter-container d-flex flex-wrap align-items-center"
          >
            Applied Filters:{' '}
            {appliedFilters.map((ap) => {
              let filterName = '';
              if (ap.type === 'LOCATION') {
                filterName =
                  ap.constraints.length > 1 ? 'Multistate' : ap.constraints[0]?.valueDescription;
              }
              if (ap.type === 'GENERAL') {
                const attributeFilter = filters.find(
                  (item) => item.type === 'ATTRIBUTE' && ap.field === item.code
                );
                filterName = attributeFilter?.label;
              }
              if (ap.type === 'QUESTION') {
                const questionFilter = filters.find(
                  (item) =>
                    item.type === 'QUESTION' &&
                    item.options.some((i) => ap.field === i.referenceId.toString())
                );
                filterName = questionFilter?.label;
              }
              if (filterName?.length > 20) {
                return (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-${ap.field}`}>{filterName}</Tooltip>}
                  >
                    <div
                      md={1}
                      key={shortid.generate()}
                      className="border rounded border-dark p-2 ml-2 d-flex align-items-center bg-filters"
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        onClick={() => removeFilter(ap)}
                        icon={faTimesCircle}
                      />
                      <FontAwesomeIcon
                        onClick={() => editFilter(ap)}
                        className="cursor-pointer mx-3"
                        icon={faPencilAlt}
                      />
                      {filterName.substring(0, 20)}...
                    </div>
                  </OverlayTrigger>
                );
              }
              return (
                <div
                  md={1}
                  key={shortid.generate()}
                  className="border rounded border-dark p-2 ml-2 d-flex align-items-center bg-filters"
                >
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    onClick={() => removeFilter(ap)}
                    icon={faTimesCircle}
                  />
                  <FontAwesomeIcon
                    onClick={() => editFilter(ap)}
                    className="cursor-pointer mx-3"
                    icon={faPencilAlt}
                  />
                  {filterName}
                </div>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
};

SurveyFiltersSegments.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

export default SurveyFiltersSegments;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Button, Container, Alert, Modal, Row } from 'react-bootstrap';
import Spinner from '../Spinner';
import { checkToken } from '../../actions/user.action';
import { login } from '../../actions/session.action';
import { resetPasswordApi, acceptTermsApi, verifyAccountApi } from '../../api/users';
import './style.css';
import TermsAndConditions from '../TermsAndConditions';

const ResetPasswordForm = ({ isActivate }) => {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showTokenError, setShowTokenError] = useState(false);
  const { activeCheck } = useSelector((state) => state.users);
  const jwt = useSelector((state) => state.session.jwt);

  const validateAndSend = async () => {
    setShowModal(false);
    setIsSending(true);
    if (isActivate) {
      await verifyAccountApi({ newpassword: newPassword, token });
    } else {
      await resetPasswordApi({ newpassword: newPassword, token });
    }
    dispatch(login({ username: activeCheck.username, password: newPassword }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInvalidConfirmPassword(false);
    setInvalidPassword(false);
    const form = event.currentTarget;
    const passwordStrongRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    const passwordsNotMatch = newPassword !== confirmPassword;
    const validPassword = passwordStrongRegExp.test(newPassword);
    if (passwordsNotMatch) {
      setInvalidConfirmPassword(true);
    }
    if (!validPassword) {
      setInvalidPassword(true);
    }
    if (form.checkValidity() === false || passwordsNotMatch || !validPassword) {
      event.preventDefault();
      event.stopPropagation();
    } else if (isActivate) {
      setShowModal(true);
    } else {
      validateAndSend();
    }
    setValidated(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangePassword = (e) => setNewPassword(e.target.value);

  const handleChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);

  const verifySuccess = async () => {
    if (isActivate) {
      await acceptTermsApi(jwt);
    }
    sessionStorage.setItem('jwt', jwt);
    history.push('/');
  };

  useEffect(() => {
    if (jwt && jwt !== 'error') {
      verifySuccess();
    }
  }, [jwt]);

  useEffect(() => {
    setShowTokenError(false);
    setIsSending(true);
    dispatch(checkToken(token));
  }, [token]);

  useEffect(() => {
    if (activeCheck) {
      setIsSending(false);
      if (activeCheck.username === null) {
        setShowTokenError(true);
      }
    }
  }, [activeCheck]);

  return (
    <Container className="w-50 bg-white p-4 m-0">
      <h3 className="Login-info-subtitle mb-3">Set your password</h3>
      {isSending && <Spinner size="lg" />}
      {showTokenError && (
        <Alert variant="danger">
          <p className="m-0">Token expired or invalid</p>
        </Alert>
      )}
      {!showTokenError && !isSending && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              required
              type="password"
              placeholder="Password *"
              name="password"
              size="md"
              className="mb-2"
              maxLength="100"
              onChange={handleChangePassword}
            />
            <Form.Control.Feedback type="invalid">
              Please complete your password
            </Form.Control.Feedback>
            {invalidPassword && (
              <p className="m-0 invalid-feedback d-block">Password must fit the requirements.</p>
            )}
            <p className="text-black-50 font-size-10 mb-1">
              Must be at least 8 characters long. Must include uppercase and lowercase letters.
            </p>
            <p className="text-black-50 font-size-10 mb-3">
              Must include at least one number. Must include at least one special character:
              !@#$%^&*
            </p>
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="password"
              placeholder="Confirm Password *"
              name="confirmPassword"
              size="md"
              className="mb-2"
              maxLength="100"
              onChange={handleChangeConfirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              Please confirm the password.
            </Form.Control.Feedback>
            {invalidConfirmPassword && (
              <p className="m-0 invalid-feedback d-block">Passwords do not match</p>
            )}
          </Form.Group>
          <Form.Group>
            <Container className="p-0">
              <Button type="submit" className="bento-btn mr-3">
                <span className="button-text">Activate</span>
              </Button>
            </Container>
          </Form.Group>
        </Form>
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <TermsAndConditions />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="btn btn-custom white"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="primary" className="btn btn-custom dark" onClick={validateAndSend}>
              I accept the terms and conditions
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </Container>
  );
};

ResetPasswordForm.propTypes = {
  isActivate: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  isActivate: false,
};

export default ResetPasswordForm;

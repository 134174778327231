import React from 'react';
import PropTypes from 'prop-types';

const Cardtitle = ({ title }) => (
  <div className="Card-title-container">
    <div className="Card-title">
      { title }
    </div>
  </div>
);

Cardtitle.propTypes = {
  title: PropTypes.string,
};

Cardtitle.defaultProps = {
  title: 'Utitled Survey',
};

export default Cardtitle;

export const RI = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7978, 41.9286],
            [-71.5901, 41.9341],
            [-71.5751, 41.8586],
            [-71.691, 41.8566],
            [-71.6678, 41.7275],
            [-71.7897, 41.7246],
            [-71.7913, 41.7745],
            [-71.7947, 41.8544],
            [-71.7968, 41.9044],
            [-71.7978, 41.9286],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '00420',
        GEOID: '4400420',
        NAME: 'Foster-Glocester Regional School District',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271895237,
        AWATER: 9727071,
        INTPTLAT: '+41.8431827',
        INTPTLON: '-071.7150858',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7877, 41.6399],
            [-71.7872, 41.6561],
            [-71.5467, 41.6616],
            [-71.5224, 41.6026],
            [-71.5079, 41.5001],
            [-71.6131, 41.5253],
            [-71.6208, 41.5641],
            [-71.7914, 41.5458],
            [-71.7877, 41.6399],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400360',
        NAME: 'Exeter-West Greenwich Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279008720,
        AWATER: 5001414,
        INTPTLAT: '+41.5951762',
        INTPTLON: '-071.6528531',
        ELSDLEA: '00360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7947, 41.8544],
            [-71.691, 41.8566],
            [-71.6678, 41.7275],
            [-71.7897, 41.7246],
            [-71.7913, 41.7745],
            [-71.7947, 41.8544],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400390',
        NAME: 'Foster Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131566634,
        AWATER: 2836636,
        INTPTLAT: '+41.7887835',
        INTPTLON: '-071.7349343',
        ELSDLEA: '00390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7978, 41.9286],
            [-71.5901, 41.9341],
            [-71.5751, 41.8586],
            [-71.691, 41.8566],
            [-71.7947, 41.8544],
            [-71.7968, 41.9044],
            [-71.7978, 41.9286],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400450',
        NAME: 'Glocester Elementary School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140328603,
        AWATER: 6890435,
        INTPTLAT: '+41.8943197',
        INTPTLON: '-071.6869053',
        ELSDLEA: '00450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4219, 41.449],
            [-71.4116, 41.5045],
            [-71.3874, 41.6037],
            [-71.3136, 41.5686],
            [-71.3119, 41.5222],
            [-71.3616, 41.4647],
            [-71.4219, 41.449],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400510',
        NAME: 'Jamestown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24469395,
        AWATER: 67012308,
        INTPTLAT: '+41.5197301',
        INTPTLON: '-071.3710759',
        ELSDLEA: '00510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2211, 41.5433],
            [-71.1279, 41.5628],
            [-71.1164, 41.4846],
            [-71.211, 41.5002],
            [-71.2211, 41.5433],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400600',
        NAME: 'Little Compton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53151388,
        AWATER: 21438712,
        INTPTLAT: '+41.5028606',
        INTPTLON: '-071.1658085',
        ELSDLEA: '00600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3652, 41.7356],
            [-71.3178, 41.7761],
            [-71.3164, 41.7756],
            [-71.2844, 41.762],
            [-71.329, 41.6799],
            [-71.331, 41.687],
            [-71.3652, 41.7356],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400030',
        NAME: 'Barrington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21277117,
        AWATER: 18692573,
        INTPTLAT: '+41.7330374',
        INTPTLON: '-071.3196762',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.329, 41.6799],
            [-71.2844, 41.762],
            [-71.2084, 41.6906],
            [-71.2246, 41.6661],
            [-71.329, 41.6799],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400065',
        NAME: 'Bristol-Warren Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41273687,
        AWATER: 34658097,
        INTPTLAT: '+41.6896986',
        INTPTLON: '-071.2690060',
        ELSDLEA: '',
        UNSDLEA: '00065',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7992, 42.0081],
            [-71.6809, 42.0115],
            [-71.6063, 42.0134],
            [-71.5901, 41.9341],
            [-71.7978, 41.9286],
            [-71.7979, 41.9324],
            [-71.7992, 42.0081],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400090',
        NAME: 'Burrillville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142539722,
        AWATER: 5416476,
        INTPTLAT: '+41.9702909',
        INTPTLON: '-071.6986597',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3943, 41.8977],
            [-71.3817, 41.8932],
            [-71.4045, 41.8808],
            [-71.3943, 41.8977],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400120',
        NAME: 'Central Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3103174,
        AWATER: 198711,
        INTPTLAT: '+41.8900642',
        INTPTLON: '-071.3934808',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7977, 41.4169],
            [-71.7932, 41.5057],
            [-71.7914, 41.5458],
            [-71.6208, 41.5641],
            [-71.6131, 41.5253],
            [-71.5952, 41.4463],
            [-71.6515, 41.2984],
            [-71.7292, 41.2779],
            [-71.7309, 41.3985],
            [-71.7977, 41.4169],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400150',
        NAME: 'Chariho Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310045737,
        AWATER: 63724291,
        INTPTLAT: '+41.4444771',
        INTPTLON: '-071.7032378',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7897, 41.7246],
            [-71.6678, 41.7275],
            [-71.5455, 41.7301],
            [-71.534, 41.7302],
            [-71.5323, 41.6623],
            [-71.5467, 41.6616],
            [-71.7872, 41.6561],
            [-71.7897, 41.7246],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400210',
        NAME: 'Coventry School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152948276,
        AWATER: 8665475,
        INTPTLAT: '+41.6983871',
        INTPTLON: '-071.6575037',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5596, 41.7896],
            [-71.4637, 41.805],
            [-71.3782, 41.7844],
            [-71.3783, 41.7553],
            [-71.4839, 41.7318],
            [-71.534, 41.7302],
            [-71.5455, 41.7301],
            [-71.5596, 41.7896],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400240',
        NAME: 'Cranston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73400872,
        AWATER: 4145372,
        INTPTLAT: '+41.7697341',
        INTPTLON: '-071.4850489',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4581, 42.0178],
            [-71.3814, 42.0188],
            [-71.3813, 41.985],
            [-71.3815, 41.9148],
            [-71.3817, 41.8932],
            [-71.3943, 41.8977],
            [-71.4874, 41.9791],
            [-71.4581, 42.0178],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400270',
        NAME: 'Cumberland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68501549,
        AWATER: 4682420,
        INTPTLAT: '+41.9669126',
        INTPTLON: '-071.4289326',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5323, 41.6623],
            [-71.4992, 41.6629],
            [-71.4678, 41.6342],
            [-71.5224, 41.6026],
            [-71.5467, 41.6616],
            [-71.5323, 41.6623],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400300',
        NAME: 'East Greenwich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42466064,
        AWATER: 646172,
        INTPTLAT: '+41.6354690',
        INTPTLON: '-071.5050522',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3745, 41.8571],
            [-71.3344, 41.8613],
            [-71.3178, 41.7761],
            [-71.3652, 41.7356],
            [-71.3783, 41.7553],
            [-71.3782, 41.7844],
            [-71.3745, 41.8571],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400330',
        NAME: 'East Providence School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34296502,
        AWATER: 8575923,
        INTPTLAT: '+41.7999563',
        INTPTLON: '-071.3565841',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4955, 41.8687],
            [-71.4727, 41.8371],
            [-71.4637, 41.805],
            [-71.5596, 41.7896],
            [-71.5751, 41.8586],
            [-71.4955, 41.8687],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400540',
        NAME: 'Johnston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60683014,
        AWATER: 2290683,
        INTPTLAT: '+41.8274402',
        INTPTLON: '-071.5198792',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4901, 41.9437],
            [-71.4874, 41.9791],
            [-71.3943, 41.8977],
            [-71.4045, 41.8808],
            [-71.4222, 41.8786],
            [-71.4675, 41.8726],
            [-71.4901, 41.9437],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400570',
        NAME: 'Lincoln School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46930098,
        AWATER: 2152298,
        INTPTLAT: '+41.9154548',
        INTPTLON: '-071.4498149',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3136, 41.5686],
            [-71.2333, 41.5149],
            [-71.2859, 41.4892],
            [-71.3119, 41.5222],
            [-71.3136, 41.5686],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400630',
        NAME: 'Middletown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32935371,
        AWATER: 5388731,
        INTPTLAT: '+41.5186728',
        INTPTLON: '-071.2816845',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5752, 41.3209],
            [-71.4522, 41.4525],
            [-71.4501, 41.5018],
            [-71.4116, 41.5045],
            [-71.4219, 41.449],
            [-71.5094, 41.3077],
            [-71.5752, 41.3209],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400660',
        NAME: 'Narragansett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35981530,
        AWATER: 61854865,
        INTPTLAT: '+41.4081835',
        INTPTLON: '-071.4822618',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6788, 41.1589],
            [-71.6245, 41.2741],
            [-71.4922, 41.2409],
            [-71.4769, 41.1682],
            [-71.5282, 41.1031],
            [-71.6117, 41.0974],
            [-71.6788, 41.1589],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400690',
        NAME: 'New Shoreham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23521355,
        AWATER: 260101320,
        INTPTLAT: '+41.1946421',
        INTPTLON: '-071.5723773',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3616, 41.4647],
            [-71.3119, 41.5222],
            [-71.2859, 41.4892],
            [-71.3616, 41.4647],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400720',
        NAME: 'Newport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19874572,
        AWATER: 9628840,
        INTPTLAT: '+41.4769716',
        INTPTLON: '-071.3185515',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4678, 41.6342],
            [-71.3805, 41.6503],
            [-71.3874, 41.6037],
            [-71.4116, 41.5045],
            [-71.4501, 41.5018],
            [-71.5079, 41.5001],
            [-71.5224, 41.6026],
            [-71.4678, 41.6342],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400750',
        NAME: 'North Kingstown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111754242,
        AWATER: 39625998,
        INTPTLAT: '+41.5741383',
        INTPTLON: '-071.4538569',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4675, 41.8726],
            [-71.4222, 41.8786],
            [-71.4175, 41.8594],
            [-71.4727, 41.8371],
            [-71.4955, 41.8687],
            [-71.4675, 41.8726],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400780',
        NAME: 'North Providence School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14568978,
        AWATER: 332978,
        INTPTLAT: '+41.8615872',
        INTPTLON: '-071.4568791',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6063, 42.0134],
            [-71.5911, 42.0137],
            [-71.5291, 42.015],
            [-71.4874, 41.9791],
            [-71.4901, 41.9437],
            [-71.5901, 41.9341],
            [-71.6063, 42.0134],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400810',
        NAME: 'North Smithfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61651531,
        AWATER: 2429673,
        INTPTLAT: '+41.9751863',
        INTPTLON: '-071.5440692',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4222, 41.8786],
            [-71.4045, 41.8808],
            [-71.3817, 41.8932],
            [-71.3387, 41.8984],
            [-71.3344, 41.8613],
            [-71.3745, 41.8571],
            [-71.4175, 41.8594],
            [-71.4222, 41.8786],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400840',
        NAME: 'Pawtucket School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22485848,
        AWATER: 750340,
        INTPTLAT: '+41.8743679',
        INTPTLON: '-071.3756776',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3805, 41.6503],
            [-71.331, 41.687],
            [-71.329, 41.6799],
            [-71.2246, 41.6661],
            [-71.2211, 41.5433],
            [-71.211, 41.5002],
            [-71.2333, 41.5149],
            [-71.3136, 41.5686],
            [-71.3874, 41.6037],
            [-71.3805, 41.6503],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400870',
        NAME: 'Portsmouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59562630,
        AWATER: 94112079,
        INTPTLAT: '+41.6076135',
        INTPTLON: '-071.3054049',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4727, 41.8371],
            [-71.4175, 41.8594],
            [-71.3745, 41.8571],
            [-71.3782, 41.7844],
            [-71.4637, 41.805],
            [-71.4727, 41.8371],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400900',
        NAME: 'Providence School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47655935,
        AWATER: 5618032,
        INTPTLAT: '+41.8230558',
        INTPTLON: '-071.4187841',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.691, 41.8566],
            [-71.5751, 41.8586],
            [-71.5596, 41.7896],
            [-71.5455, 41.7301],
            [-71.6678, 41.7275],
            [-71.691, 41.8566],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400960',
        NAME: 'Scituate School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124750074,
        AWATER: 17112833,
        INTPTLAT: '+41.8034630',
        INTPTLON: '-071.6285380',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5901, 41.9341],
            [-71.4901, 41.9437],
            [-71.4675, 41.8726],
            [-71.4955, 41.8687],
            [-71.5751, 41.8586],
            [-71.5901, 41.9341],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4400990',
        NAME: 'Smithfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68131707,
        AWATER: 3769921,
        INTPTLAT: '+41.9012409',
        INTPTLON: '-071.5310604',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6515, 41.2984],
            [-71.5952, 41.4463],
            [-71.6131, 41.5253],
            [-71.5079, 41.5001],
            [-71.4501, 41.5018],
            [-71.4522, 41.4525],
            [-71.5752, 41.3209],
            [-71.6515, 41.2984],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4401020',
        NAME: 'South Kingstown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146202813,
        AWATER: 60370428,
        INTPTLAT: '+41.4108465',
        INTPTLON: '-071.5474690',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2246, 41.6661],
            [-71.2084, 41.6906],
            [-71.2083, 41.6905],
            [-71.2012, 41.6819],
            [-71.1385, 41.6036],
            [-71.1279, 41.5628],
            [-71.2211, 41.5433],
            [-71.2246, 41.6661],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4401050',
        NAME: 'Tiverton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75227126,
        AWATER: 18862745,
        INTPTLAT: '+41.6094357',
        INTPTLON: '-071.1781130',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4839, 41.7318],
            [-71.3783, 41.7553],
            [-71.3652, 41.7356],
            [-71.331, 41.687],
            [-71.3805, 41.6503],
            [-71.4678, 41.6342],
            [-71.4992, 41.6629],
            [-71.4839, 41.7318],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4401110',
        NAME: 'Warwick School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90752667,
        AWATER: 38210709,
        INTPTLAT: '+41.7007569',
        INTPTLON: '-071.4203343',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.534, 41.7302],
            [-71.4839, 41.7318],
            [-71.4992, 41.6629],
            [-71.5323, 41.6623],
            [-71.534, 41.7302],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4401140',
        NAME: 'West Warwick School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20176417,
        AWATER: 589271,
        INTPTLAT: '+41.6790059',
        INTPTLON: '-071.5186877',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8425, 41.4087],
            [-71.7977, 41.4169],
            [-71.7309, 41.3985],
            [-71.7292, 41.2779],
            [-71.791, 41.1841],
            [-71.9073, 41.3045],
            [-71.8294, 41.3424],
            [-71.8425, 41.4087],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4401170',
        NAME: 'Westerly School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76492575,
        AWATER: 116646012,
        INTPTLAT: '+41.3274577',
        INTPTLON: '-071.7788410',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5291, 42.015],
            [-71.4983, 42.0172],
            [-71.4581, 42.0178],
            [-71.4874, 41.9791],
            [-71.5291, 42.015],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4401200',
        NAME: 'Woonsocket School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20043690,
        AWATER: 501532,
        INTPTLAT: '+42.0017058',
        INTPTLON: '-071.4999487',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5094, 41.3077],
            [-71.4219, 41.449],
            [-71.3616, 41.4647],
            [-71.2859, 41.4892],
            [-71.2333, 41.5149],
            [-71.211, 41.5002],
            [-71.1164, 41.4846],
            [-71.0886, 41.4313],
            [-71.5094, 41.3077],
          ],
        ],
      },
      properties: {
        STATEFP: '44',
        SCSDLEA: '',
        GEOID: '4499997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 0,
        AWATER: 330539842,
        INTPTLAT: '+41.4102788',
        INTPTLON: '-071.3027192',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

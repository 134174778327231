export const NH = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2052, 43.771],
            [-72.0757, 43.7416],
            [-72.1603, 43.6586],
            [-72.3051, 43.6926],
            [-72.2052, 43.771],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '02670',
        GEOID: '3302670',
        NAME: 'Dresden School District',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126969682,
        AWATER: 3277622,
        INTPTLAT: '+43.7210227',
        INTPTLON: '-072.1852072',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.848, 44.1906],
            [-71.7942, 44.2712],
            [-71.6967, 44.3323],
            [-71.5758, 44.2547],
            [-71.4158, 44.2121],
            [-71.4129, 44.1664],
            [-71.4226, 44.1688],
            [-71.5374, 44.1979],
            [-71.5812, 44.112],
            [-71.727, 44.1585],
            [-71.7943, 44.069],
            [-71.8576, 44.0927],
            [-71.848, 44.1906],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '05830',
        GEOID: '3305830',
        NAME: 'Profile School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 529564760,
        AWATER: 2230640,
        INTPTLAT: '+44.1771430',
        INTPTLON: '-071.6185649',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9712, 43.9156],
            [-71.9592, 43.9167],
            [-71.8259, 43.8858],
            [-71.7227, 43.8556],
            [-71.7257, 43.882],
            [-71.7321, 43.9309],
            [-71.6281, 44.0288],
            [-71.6038, 43.9736],
            [-71.5646, 43.8931],
            [-71.5606, 43.8751],
            [-71.5436, 43.7962],
            [-71.5324, 43.765],
            [-71.5821, 43.6919],
            [-71.6585, 43.7175],
            [-71.7764, 43.7709],
            [-71.9071, 43.8041],
            [-72.0269, 43.8379],
            [-71.9712, 43.9156],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '99965',
        GEOID: '3399965',
        NAME: 'Pemi-Baker Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 659711066,
        AWATER: 23797281,
        INTPTLAT: '+43.8328463',
        INTPTLON: '-071.6967770',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9133, 43.2073],
            [-71.7877, 43.2319],
            [-71.7331, 43.1394],
            [-71.6678, 43.1526],
            [-71.6413, 43.0542],
            [-71.6385, 43.033],
            [-71.7624, 43.0141],
            [-71.7858, 43.0384],
            [-71.8097, 43.1245],
            [-71.9133, 43.2073],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '00003',
        GEOID: '3300003',
        NAME: 'John Stark Regional School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266483804,
        AWATER: 4436134,
        INTPTLAT: '+43.1173122',
        INTPTLON: '-071.7690988',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7377, 42.9226],
            [-71.64, 42.9354],
            [-71.6087, 42.9399],
            [-71.57, 42.8963],
            [-71.5584, 42.8033],
            [-71.6072, 42.7982],
            [-71.6664, 42.8666],
            [-71.6947, 42.8634],
            [-71.7377, 42.9226],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '06260',
        GEOID: '3306260',
        NAME: 'Souhegan Cooperative School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131627452,
        AWATER: 1529460,
        INTPTLAT: '+42.8856883',
        INTPTLON: '-071.6345219',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0804, 43.0209],
            [-71.011, 43.0203],
            [-71.0139, 43.0339],
            [-71.0133, 43.0526],
            [-70.9127, 43.0529],
            [-70.9077, 43.0599],
            [-70.868, 43.0055],
            [-70.8877, 42.9711],
            [-70.9016, 42.9673],
            [-70.9253, 42.9606],
            [-70.918, 42.9067],
            [-70.9169, 42.8984],
            [-70.9771, 42.8983],
            [-71.0114, 42.8944],
            [-70.9975, 42.9583],
            [-71.0061, 42.9587],
            [-71.088, 42.9593],
            [-71.0804, 43.0209],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '00017',
        GEOID: '3300017',
        NAME: 'Exeter Regional Cooperative School District',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208378887,
        AWATER: 3107996,
        INTPTLAT: '+42.9784150',
        INTPTLON: '-070.9875221',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.714, 42.7691],
            [-71.6426, 42.778],
            [-71.6072, 42.7982],
            [-71.5584, 42.8033],
            [-71.5562, 42.7926],
            [-71.543, 42.7027],
            [-71.6304, 42.7047],
            [-71.7012, 42.7064],
            [-71.714, 42.7691],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '03850',
        GEOID: '3303850',
        NAME: 'Hollis-Brookline Cooperative School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133469776,
        AWATER: 2377505,
        INTPTLAT: '+42.7425311',
        INTPTLON: '-071.6167799',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2813, 43.4182],
            [-71.317, 43.5094],
            [-71.3442, 43.6293],
            [-71.3411, 43.6272],
            [-71.1922, 43.5126],
            [-71.1601, 43.3559],
            [-71.2358, 43.2847],
            [-71.347, 43.3466],
            [-71.2813, 43.4182],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '00049',
        GEOID: '3300049',
        NAME: 'Prospect Mountain High School Joint Maintenance Agreement District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271542220,
        AWATER: 55342490,
        INTPTLAT: '+43.4423247',
        INTPTLON: '-071.2573646',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9253, 42.9606],
            [-70.9016, 42.9673],
            [-70.8877, 42.9711],
            [-70.868, 43.0055],
            [-70.8145, 43.0017],
            [-70.7628, 42.9666],
            [-70.7776, 42.9508],
            [-70.8114, 42.8886],
            [-70.8156, 42.8723],
            [-70.9145, 42.8866],
            [-70.9169, 42.8984],
            [-70.918, 42.9067],
            [-70.9253, 42.9606],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '07230',
        GEOID: '3307230',
        NAME: 'Winnacunnet Cooperative School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124070472,
        AWATER: 8827178,
        INTPTLAT: '+42.9377261',
        INTPTLON: '-070.8514130',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4663, 43.1239],
            [-71.4652, 43.1275],
            [-71.3938, 43.1756],
            [-71.3178, 43.1626],
            [-71.3562, 43.102],
            [-71.4663, 43.1239],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301380',
        NAME: 'Allenstown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52623655,
        AWATER: 285783,
        INTPTLAT: '+43.1433412',
        INTPTLON: '-071.3861063',
        ELSDLEA: '01380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3442, 43.6293],
            [-71.3411, 43.6272],
            [-71.1922, 43.5126],
            [-71.1601, 43.3559],
            [-71.2813, 43.4182],
            [-71.317, 43.5094],
            [-71.3442, 43.6293],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301440',
        NAME: 'Alton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162905033,
        AWATER: 49995227,
        INTPTLAT: '+43.4916750',
        INTPTLON: '-071.2429475',
        ELSDLEA: '01440',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6664, 42.8666],
            [-71.64, 42.9354],
            [-71.6087, 42.9399],
            [-71.57, 42.8963],
            [-71.5584, 42.8033],
            [-71.6072, 42.7982],
            [-71.6664, 42.8666],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301470',
        NAME: 'Amherst School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88591186,
        AWATER: 1316496,
        INTPTLAT: '+42.8748639',
        INTPTLON: '-071.6005026',
        ELSDLEA: '01470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.863, 43.4598],
            [-71.8171, 43.4751],
            [-71.7333, 43.5033],
            [-71.695, 43.4433],
            [-71.8385, 43.3957],
            [-71.863, 43.4598],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301500',
        NAME: 'Andover School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105006646,
        AWATER: 2300186,
        INTPTLAT: '+43.4507243',
        INTPTLON: '-071.7906003',
        ELSDLEA: '01500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6769, 43.747],
            [-71.5821, 43.6919],
            [-71.6585, 43.7175],
            [-71.6769, 43.747],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301560',
        NAME: 'Ashland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28461370,
        AWATER: 1379234,
        INTPTLAT: '+43.7260747',
        INTPTLON: '-071.6425081',
        ELSDLEA: '01560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3922, 43.0406],
            [-71.2881, 43.0214],
            [-71.3112, 42.9365],
            [-71.3466, 42.9418],
            [-71.3774, 42.9472],
            [-71.3922, 43.009],
            [-71.3922, 43.0406],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301590',
        NAME: 'Auburn School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65271669,
        AWATER: 9067444,
        INTPTLAT: '+42.9970492',
        INTPTLON: '-071.3658039',
        ELSDLEA: '01590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2813, 43.4182],
            [-71.1601, 43.3559],
            [-71.2358, 43.2847],
            [-71.347, 43.3466],
            [-71.2813, 43.4182],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301620',
        NAME: 'Barnstead School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108637187,
        AWATER: 5347263,
        INTPTLAT: '+43.3510454',
        INTPTLON: '-071.2591154',
        ELSDLEA: '01620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0547, 43.2886],
            [-70.9528, 43.2258],
            [-70.9616, 43.2158],
            [-71.0267, 43.1381],
            [-71.128, 43.2002],
            [-71.1335, 43.2037],
            [-71.0547, 43.2886],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301650',
        NAME: 'Barrington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120699933,
        AWATER: 5024033,
        INTPTLAT: '+43.2155657',
        INTPTLON: '-071.0389797',
        ELSDLEA: '01650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3469, 44.069],
            [-71.3236, 44.0915],
            [-71.3027, 44.1372],
            [-71.0977, 44.1241],
            [-71.0841, 44.0752],
            [-71.1634, 44.0568],
            [-71.1815, 44.0222],
            [-71.3826, 44.0225],
            [-71.3469, 44.069],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301680',
        NAME: 'Bartlett School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193932382,
        AWATER: 1111657,
        INTPTLAT: '+44.0772251',
        INTPTLON: '-071.2212358',
        ELSDLEA: '01680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0526, 44.218],
            [-72.0149, 44.2408],
            [-71.9335, 44.2012],
            [-71.9127, 44.1129],
            [-71.9127, 44.1128],
            [-72.0391, 44.1554],
            [-72.0526, 44.218],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301710',
        NAME: 'Bath School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97684996,
        AWATER: 2309004,
        INTPTLAT: '+44.1829552',
        INTPTLON: '-071.9870110',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6171, 42.9718],
            [-71.4966, 42.9827],
            [-71.4527, 42.9111],
            [-71.57, 42.8963],
            [-71.6087, 42.9399],
            [-71.6171, 42.9718],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301740',
        NAME: 'Bedford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85045286,
        AWATER: 729715,
        INTPTLAT: '+42.9355843',
        INTPTLON: '-071.5368709',
        ELSDLEA: '01740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6967, 44.3323],
            [-71.5758, 44.2547],
            [-71.4158, 44.2121],
            [-71.4129, 44.1664],
            [-71.4226, 44.1688],
            [-71.5374, 44.1979],
            [-71.7942, 44.2712],
            [-71.6967, 44.3323],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301890',
        NAME: 'Bethlehem School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234652035,
        AWATER: 915397,
        INTPTLAT: '+44.2604350',
        INTPTLON: '-071.6092032',
        ELSDLEA: '01890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0804, 43.0209],
            [-71.011, 43.0203],
            [-71.0061, 42.9587],
            [-71.088, 42.9593],
            [-71.0804, 43.0209],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302010',
        NAME: 'Brentwood School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43407154,
        AWATER: 573172,
        INTPTLAT: '+42.9837228',
        INTPTLON: '-071.0539976',
        ELSDLEA: '02010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.714, 42.7691],
            [-71.6426, 42.778],
            [-71.6304, 42.7047],
            [-71.7012, 42.7064],
            [-71.714, 42.7691],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302070',
        NAME: 'Brookline School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51214810,
        AWATER: 935401,
        INTPTLAT: '+42.7492630',
        INTPTLON: '-071.6756699',
        ELSDLEA: '02070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7494, 43.8025],
            [-71.7227, 43.8556],
            [-71.7257, 43.882],
            [-71.5606, 43.8751],
            [-71.5436, 43.7962],
            [-71.6692, 43.7828],
            [-71.7494, 43.8025],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302100',
        NAME: 'Campton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134289273,
        AWATER: 1805001,
        INTPTLAT: '+43.8270442',
        INTPTLON: '-071.6466128',
        ELSDLEA: '02100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3562, 43.102],
            [-71.2305, 43.08],
            [-71.2613, 43.016],
            [-71.2881, 43.0214],
            [-71.3922, 43.0406],
            [-71.3562, 43.102],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302130',
        NAME: 'Candia School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78557810,
        AWATER: 627056,
        INTPTLAT: '+43.0568701',
        INTPTLON: '-071.3405593',
        ELSDLEA: '02130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2881, 43.0214],
            [-71.2613, 43.016],
            [-71.1678, 42.9846],
            [-71.1793, 42.9605],
            [-71.2196, 42.9199],
            [-71.3112, 42.9365],
            [-71.2881, 43.0214],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302250',
        NAME: 'Chester School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67138226,
        AWATER: 316619,
        INTPTLAT: '+42.9845301',
        INTPTLON: '-071.2551041',
        ELSDLEA: '02250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5328, 42.8961],
            [-72.5267, 42.9341],
            [-72.3679, 42.9264],
            [-72.3468, 42.9107],
            [-72.389, 42.8481],
            [-72.4769, 42.8531],
            [-72.5552, 42.8582],
            [-72.5328, 42.8961],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302280',
        NAME: 'Chesterfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117978834,
        AWATER: 5199680,
        INTPTLAT: '+42.8880017',
        INTPTLON: '-072.4434717',
        ELSDLEA: '02280',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4496, 43.2452],
            [-71.3814, 43.3128],
            [-71.36, 43.2684],
            [-71.4029, 43.2008],
            [-71.4496, 43.2452],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302310',
        NAME: 'Chichester School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54427896,
        AWATER: 277042,
        INTPTLAT: '+43.2652592',
        INTPTLON: '-071.4088353',
        ELSDLEA: '02310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3964, 43.5192],
            [-72.2273, 43.4926],
            [-72.2695, 43.4238],
            [-72.3964, 43.4289],
            [-72.3964, 43.5192],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302520',
        NAME: 'Cornish School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109056163,
        AWATER: 1869203,
        INTPTLAT: '+43.4729880',
        INTPTLON: '-072.3117491',
        ELSDLEA: '02520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2273, 43.4926],
            [-72.2149, 43.4889],
            [-72.1114, 43.4698],
            [-72.1153, 43.4468],
            [-72.1263, 43.3945],
            [-72.2695, 43.4238],
            [-72.2273, 43.4926],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302550',
        NAME: 'Croydon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94978151,
        AWATER: 2023900,
        INTPTLAT: '+43.4442267',
        INTPTLON: '-072.1783518',
        ELSDLEA: '02550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3562, 43.102],
            [-71.3178, 43.1626],
            [-71.281, 43.2185],
            [-71.1567, 43.167],
            [-71.2132, 43.0771],
            [-71.2305, 43.08],
            [-71.3562, 43.102],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302580',
        NAME: 'Deerfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131769336,
        AWATER: 3454057,
        INTPTLAT: '+43.1363215',
        INTPTLON: '-071.2550770',
        ELSDLEA: '02580',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3466, 42.9418],
            [-71.3112, 42.9365],
            [-71.2196, 42.9199],
            [-71.2194, 42.9116],
            [-71.2078, 42.86],
            [-71.2065, 42.8524],
            [-71.2425, 42.848],
            [-71.3311, 42.8411],
            [-71.3466, 42.9418],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302610',
        NAME: 'Derry School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92227684,
        AWATER: 2253571,
        INTPTLAT: '+42.8927614',
        INTPTLON: '-071.2767629',
        ELSDLEA: '02610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6678, 43.1526],
            [-71.6284, 43.1603],
            [-71.5127, 43.0764],
            [-71.5185, 43.0689],
            [-71.6413, 43.0542],
            [-71.6678, 43.1526],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302760',
        NAME: 'Dunbarton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80038483,
        AWATER: 1184513,
        INTPTLAT: '+43.1163384',
        INTPTLON: '-071.5953553',
        ELSDLEA: '02760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9975, 42.9583],
            [-70.9823, 42.957],
            [-70.9771, 42.8983],
            [-71.0114, 42.8944],
            [-70.9975, 42.9583],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302790',
        NAME: 'East Kingston School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25895649,
        AWATER: 161798,
        INTPTLAT: '+42.9258646',
        INTPTLON: '-071.0026333',
        ELSDLEA: '02790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4029, 43.2008],
            [-71.36, 43.2684],
            [-71.2616, 43.2514],
            [-71.281, 43.2185],
            [-71.3178, 43.1626],
            [-71.3938, 43.1756],
            [-71.4029, 43.2008],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302910',
        NAME: 'Epsom School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88737155,
        AWATER: 507265,
        INTPTLAT: '+43.2048124',
        INTPTLON: '-071.3485630',
        ELSDLEA: '02910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0433, 44.8286],
            [-71.0393, 44.7779],
            [-71.0344, 44.71],
            [-71.1796, 44.7086],
            [-71.2111, 44.7083],
            [-71.2159, 44.8249],
            [-71.0433, 44.8286],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302940',
        NAME: 'Errol School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157041115,
        AWATER: 23619080,
        INTPTLAT: '+44.7582529',
        INTPTLON: '-071.1088122',
        ELSDLEA: '02940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0139, 43.0339],
            [-70.934, 43.0215],
            [-70.8877, 42.9711],
            [-70.9016, 42.9673],
            [-70.9253, 42.9606],
            [-70.9823, 42.957],
            [-70.9975, 42.9583],
            [-71.0061, 42.9587],
            [-71.011, 43.0203],
            [-71.0139, 43.0339],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302970',
        NAME: 'Exeter School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50656134,
        AWATER: 973873,
        INTPTLAT: '+42.9884525',
        INTPTLON: '-070.9615424',
        ELSDLEA: '02970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1836, 43.8328],
            [-71.0812, 43.8554],
            [-70.9917, 43.8776],
            [-70.9891, 43.792],
            [-71.1836, 43.8328],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303120',
        NAME: 'Freedom School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90610382,
        AWATER: 8121799,
        INTPTLAT: '+43.8420552',
        INTPTLON: '-071.0751220',
        ELSDLEA: '03120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1678, 42.9846],
            [-71.1501, 43.0211],
            [-71.0804, 43.0209],
            [-71.088, 42.9593],
            [-71.0922, 42.9592],
            [-71.1793, 42.9605],
            [-71.1678, 42.9846],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303150',
        NAME: 'Fremont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44305937,
        AWATER: 722775,
        INTPTLAT: '+43.0003697',
        INTPTLON: '-071.1191614',
        ELSDLEA: '03150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.395, 43.4812],
            [-71.317, 43.5094],
            [-71.2813, 43.4182],
            [-71.347, 43.3466],
            [-71.4532, 43.402],
            [-71.4674, 43.4094],
            [-71.395, 43.4812],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303210',
        NAME: 'Gilmanton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148249903,
        AWATER: 4560755,
        INTPTLAT: '+43.4320316',
        INTPTLON: '-071.3659711',
        ELSDLEA: '03210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7902, 42.7897],
            [-71.7193, 42.7973],
            [-71.714, 42.7691],
            [-71.7012, 42.7064],
            [-71.8054, 42.709],
            [-71.7902, 42.7897],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303276',
        NAME: 'Mason School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61927145,
        AWATER: 143147,
        INTPTLAT: '+42.7422651',
        INTPTLON: '-071.7509523',
        ELSDLEA: '03276',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2395, 43.2709],
            [-72.1655, 43.3083],
            [-72.1275, 43.3298],
            [-72.0787, 43.3386],
            [-72.0654, 43.255],
            [-72.1565, 43.182],
            [-72.2198, 43.1748],
            [-72.2395, 43.2709],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303300',
        NAME: 'Goshen-Lempster Cooperative School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142009138,
        AWATER: 1346055,
        INTPTLAT: '+43.2512127',
        INTPTLON: '-072.1560764',
        ELSDLEA: '03300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2149, 43.4889],
            [-72.1712, 43.574],
            [-72.0909, 43.5289],
            [-72.1114, 43.4698],
            [-72.2149, 43.4889],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303360',
        NAME: 'Grantham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70371912,
        AWATER: 2287193,
        INTPTLAT: '+43.5165899',
        INTPTLON: '-072.1490935',
        ELSDLEA: '03360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8802, 43.0772],
            [-70.8173, 43.0594],
            [-70.8098, 43.0199],
            [-70.8145, 43.0017],
            [-70.868, 43.0055],
            [-70.9077, 43.0599],
            [-70.8802, 43.0772],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303420',
        NAME: 'Greenland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26990876,
        AWATER: 7437715,
        INTPTLAT: '+43.0397315',
        INTPTLON: '-070.8456219',
        ELSDLEA: '03420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2194, 42.9116],
            [-71.1037, 42.8959],
            [-71.1256, 42.8772],
            [-71.2078, 42.86],
            [-71.2194, 42.9116],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303480',
        NAME: 'Hampstead School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34454053,
        AWATER: 1718420,
        INTPTLAT: '+42.8787385',
        INTPTLON: '-071.1782715',
        ELSDLEA: '03480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8877, 42.9711],
            [-70.7776, 42.9508],
            [-70.8114, 42.8886],
            [-70.8238, 42.8979],
            [-70.9016, 42.9673],
            [-70.8877, 42.9711],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303510',
        NAME: 'Hampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33417697,
        AWATER: 4722963,
        INTPTLAT: '+42.9391304',
        INTPTLON: '-070.8342732',
        ELSDLEA: '03510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9253, 42.9606],
            [-70.9016, 42.9673],
            [-70.8238, 42.8979],
            [-70.918, 42.9067],
            [-70.9253, 42.9606],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303540',
        NAME: 'Hampton Falls School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31574298,
        AWATER: 835762,
        INTPTLAT: '+42.9248106',
        INTPTLON: '-070.8862417',
        ELSDLEA: '03540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2052, 43.771],
            [-72.0757, 43.7416],
            [-72.1603, 43.6586],
            [-72.3051, 43.6926],
            [-72.2052, 43.771],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303600',
        NAME: 'Hanover School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126969682,
        AWATER: 3277622,
        INTPTLAT: '+43.7210227',
        INTPTLON: '-072.1852072',
        ELSDLEA: '03600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1672, 42.9626],
            [-72.0612, 42.9658],
            [-72.1565, 42.9238],
            [-72.1585, 42.9336],
            [-72.1672, 42.9626],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303630',
        NAME: 'Harrisville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48126079,
        AWATER: 4073718,
        INTPTLAT: '+42.9485146',
        INTPTLON: '-072.0688942',
        ELSDLEA: '03630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9133, 43.2073],
            [-71.7877, 43.2319],
            [-71.7331, 43.1394],
            [-71.8097, 43.1245],
            [-71.9133, 43.2073],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303690',
        NAME: 'Henniker School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114057908,
        AWATER: 1762282,
        INTPTLAT: '+43.1694073',
        INTPTLON: '-071.8312010',
        ELSDLEA: '03690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7061, 43.5382],
            [-71.6907, 43.5183],
            [-71.7333, 43.5033],
            [-71.8171, 43.4751],
            [-71.8149, 43.5511],
            [-71.7061, 43.5382],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303720',
        NAME: 'Hill School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69072562,
        AWATER: 696087,
        INTPTLAT: '+43.5283063',
        INTPTLON: '-071.7691921',
        ELSDLEA: '03720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6692, 43.7828],
            [-71.5436, 43.7962],
            [-71.5324, 43.765],
            [-71.5821, 43.6919],
            [-71.6769, 43.747],
            [-71.6692, 43.7828],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303810',
        NAME: 'Holderness School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78922264,
        AWATER: 13868730,
        INTPTLAT: '+43.7615905',
        INTPTLON: '-071.5833449',
        ELSDLEA: '03810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6426, 42.778],
            [-71.6072, 42.7982],
            [-71.5584, 42.8033],
            [-71.5562, 42.7926],
            [-71.543, 42.7027],
            [-71.6304, 42.7047],
            [-71.6426, 42.778],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303840',
        NAME: 'Hollis School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82254966,
        AWATER: 1442104,
        INTPTLAT: '+42.7496243',
        INTPTLON: '-071.5854018',
        ELSDLEA: '03840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5185, 43.0689],
            [-71.5127, 43.0764],
            [-71.4663, 43.1239],
            [-71.3562, 43.102],
            [-71.3922, 43.0406],
            [-71.3922, 43.009],
            [-71.5127, 43.0483],
            [-71.5185, 43.0689],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303870',
        NAME: 'Hooksett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94532921,
        AWATER: 2586890,
        INTPTLAT: '+43.0825841',
        INTPTLON: '-071.4468778',
        ELSDLEA: '03870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2605, 44.228],
            [-71.2432, 44.2439],
            [-71.0975, 44.2354],
            [-71.0977, 44.1241],
            [-71.3027, 44.1372],
            [-71.2605, 44.228],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303990',
        NAME: 'Jackson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173308712,
        AWATER: 99914,
        INTPTLAT: '+44.1876262',
        INTPTLON: '-071.1557596',
        ELSDLEA: '03990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9823, 42.957],
            [-70.9253, 42.9606],
            [-70.918, 42.9067],
            [-70.9169, 42.8984],
            [-70.9771, 42.8983],
            [-70.9823, 42.957],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304080',
        NAME: 'Kensington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30931408,
        AWATER: 36676,
        INTPTLAT: '+42.9313479',
        INTPTLON: '-070.9578091',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.848, 44.1906],
            [-71.7942, 44.2712],
            [-71.5374, 44.1979],
            [-71.5812, 44.112],
            [-71.727, 44.1585],
            [-71.7943, 44.069],
            [-71.8576, 44.0927],
            [-71.848, 44.1906],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304150',
        NAME: 'Lafayette Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294912725,
        AWATER: 1315243,
        INTPTLAT: '+44.1738311',
        INTPTLON: '-071.7294049',
        ELSDLEA: '04150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9335, 44.2012],
            [-71.848, 44.1906],
            [-71.8576, 44.0927],
            [-71.9127, 44.1129],
            [-71.9335, 44.2012],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304170',
        NAME: 'Landaff School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73412901,
        AWATER: 337960,
        INTPTLAT: '+44.1600939',
        INTPTLON: '-071.8852005',
        ELSDLEA: '04170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1675, 43.871],
            [-72.1683, 43.8723],
            [-72.0269, 43.8379],
            [-72.0757, 43.7416],
            [-72.2052, 43.771],
            [-72.1675, 43.871],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304500',
        NAME: 'Lyme School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139434627,
        AWATER: 2297846,
        INTPTLAT: '+43.8166336',
        INTPTLON: '-072.0942954',
        ELSDLEA: '04500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1404, 43.9634],
            [-71.102, 43.9245],
            [-71.0812, 43.8554],
            [-71.1836, 43.8328],
            [-71.1894, 43.8311],
            [-71.2126, 43.9246],
            [-71.1404, 43.9634],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304560',
        NAME: 'Madison School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99824794,
        AWATER: 6182403,
        INTPTLAT: '+43.9089798',
        INTPTLON: '-071.1536921',
        ELSDLEA: '04560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2341, 42.9071],
            [-72.2219, 42.924],
            [-72.1585, 42.9336],
            [-72.1565, 42.9238],
            [-72.1376, 42.8689],
            [-72.1398, 42.8465],
            [-72.2341, 42.9071],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304620',
        NAME: 'Marlborough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52849027,
        AWATER: 609124,
        INTPTLAT: '+42.8862255',
        INTPTLON: '-072.1776835',
        ELSDLEA: '04620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2198, 43.1748],
            [-72.1565, 43.182],
            [-72.1785, 43.132],
            [-72.194, 43.0762],
            [-72.2432, 43.071],
            [-72.2198, 43.1748],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304650',
        NAME: 'Marlow School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67201142,
        AWATER: 1217456,
        INTPTLAT: '+43.1385439',
        INTPTLON: '-072.2087693',
        ELSDLEA: '04650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3328, 44.5703],
            [-71.3163, 44.6037],
            [-71.1791, 44.6071],
            [-71.1236, 44.5284],
            [-71.3561, 44.5247],
            [-71.3328, 44.5703],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304800',
        NAME: 'Milan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165256873,
        AWATER: 1954083,
        INTPTLAT: '+44.5539000',
        INTPTLON: '-071.2182955',
        ELSDLEA: '04800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9846, 44.3362],
            [-71.9294, 44.3163],
            [-72.0149, 44.2408],
            [-72.0526, 44.218],
            [-72.059, 44.2557],
            [-71.9846, 44.3362],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304920',
        NAME: 'Monroe School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57744678,
        AWATER: 3862169,
        INTPTLAT: '+44.2966637',
        INTPTLON: '-071.9806488',
        ELSDLEA: '04920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7377, 42.9226],
            [-71.64, 42.9354],
            [-71.6664, 42.8666],
            [-71.6947, 42.8634],
            [-71.7377, 42.9226],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304950',
        NAME: 'Mont Vernon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43036266,
        AWATER: 212964,
        INTPTLAT: '+42.9088845',
        INTPTLON: '-071.6941990',
        ELSDLEA: '04950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1783, 42.985],
            [-72.1761, 43.0234],
            [-72.0581, 43.0189],
            [-72.0612, 42.9658],
            [-72.1672, 42.9626],
            [-72.1783, 42.985],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305010',
        NAME: 'Nelson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56661041,
        AWATER: 3562673,
        INTPTLAT: '+42.9994818',
        INTPTLON: '-072.1217028',
        ELSDLEA: '05010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7624, 43.0141],
            [-71.6385, 43.033],
            [-71.6171, 42.9718],
            [-71.6087, 42.9399],
            [-71.64, 42.9354],
            [-71.7377, 42.9226],
            [-71.7474, 42.9583],
            [-71.7624, 43.0141],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305040',
        NAME: 'New Boston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110949335,
        AWATER: 968233,
        INTPTLAT: '+42.9812095',
        INTPTLON: '-071.6775203',
        ELSDLEA: '05040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.738, 43.0737],
            [-70.7012, 43.0452],
            [-70.735, 43.0595],
            [-70.738, 43.0737],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305070',
        NAME: 'New Castle School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2137296,
        AWATER: 3759985,
        INTPTLAT: '+43.0621608',
        INTPTLON: '-070.7168835',
        ELSDLEA: '05070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0133, 43.0526],
            [-70.9127, 43.0529],
            [-70.934, 43.0215],
            [-71.0139, 43.0339],
            [-71.0133, 43.0526],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305190',
        NAME: 'Newfields School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18376889,
        AWATER: 418204,
        INTPTLAT: '+43.0385087',
        INTPTLON: '-070.9679535',
        ELSDLEA: '05190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8755, 43.0805],
            [-70.8471, 43.1262],
            [-70.82, 43.1225],
            [-70.7827, 43.0996],
            [-70.8173, 43.0594],
            [-70.8802, 43.0772],
            [-70.8755, 43.0805],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305250',
        NAME: 'Newington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21215639,
        AWATER: 11049883,
        INTPTLAT: '+43.0858876',
        INTPTLON: '-070.8274193',
        ELSDLEA: '05250',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.868, 43.0055],
            [-70.8145, 43.0017],
            [-70.7628, 42.9666],
            [-70.7776, 42.9508],
            [-70.8877, 42.9711],
            [-70.868, 43.0055],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305370',
        NAME: 'North Hampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36036870,
        AWATER: 1288397,
        INTPTLAT: '+42.9799344',
        INTPTLON: '-070.8294406',
        ELSDLEA: '05370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.281, 43.2185],
            [-71.2616, 43.2514],
            [-71.2472, 43.2742],
            [-71.1335, 43.2037],
            [-71.128, 43.2002],
            [-71.1567, 43.167],
            [-71.281, 43.2185],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305430',
        NAME: 'Northwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72672769,
        AWATER: 5234885,
        INTPTLAT: '+43.2118559',
        INTPTLON: '-071.2233933',
        ELSDLEA: '05430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1567, 43.167],
            [-71.128, 43.2002],
            [-71.0267, 43.1381],
            [-71.073, 43.0817],
            [-71.1308, 43.0626],
            [-71.2132, 43.0771],
            [-71.1567, 43.167],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305460',
        NAME: 'Nottingham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120549465,
        AWATER: 4933266,
        INTPTLAT: '+43.1298473',
        INTPTLON: '-071.1318591',
        ELSDLEA: '05460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0787, 44.0296],
            [-71.9633, 43.9983],
            [-71.9592, 43.9167],
            [-71.9712, 43.9156],
            [-72.099, 43.9575],
            [-72.0787, 44.0296],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305670',
        NAME: 'Piermont School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99641419,
        AWATER: 3571110,
        INTPTLAT: '+43.9618502',
        INTPTLON: '-072.0012913',
        ELSDLEA: '05670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3961, 43.5199],
            [-72.3296, 43.6002],
            [-72.2081, 43.5852],
            [-72.1712, 43.574],
            [-72.2149, 43.4889],
            [-72.2273, 43.4926],
            [-72.3964, 43.5192],
            [-72.3961, 43.5199],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305760',
        NAME: 'Plainfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135119289,
        AWATER: 1938102,
        INTPTLAT: '+43.5438346',
        INTPTLON: '-072.2955406',
        ELSDLEA: '05760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7764, 43.7709],
            [-71.7494, 43.8025],
            [-71.6692, 43.7828],
            [-71.6769, 43.747],
            [-71.6585, 43.7175],
            [-71.7764, 43.7709],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305790',
        NAME: 'Plymouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72846015,
        AWATER: 1487426,
        INTPTLAT: '+43.7439120',
        INTPTLON: '-071.7267441',
        ELSDLEA: '05790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8863, 43.2234],
            [-70.8389, 43.2445],
            [-70.8176, 43.2377],
            [-70.8199, 43.197],
            [-70.8863, 43.2234],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305970',
        NAME: 'Rollinsford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18887053,
        AWATER: 767192,
        INTPTLAT: '+43.2198154',
        INTPTLON: '-070.8431074',
        ELSDLEA: '05970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9071, 43.8041],
            [-71.8259, 43.8858],
            [-71.7227, 43.8556],
            [-71.7494, 43.8025],
            [-71.7764, 43.7709],
            [-71.9071, 43.8041],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306000',
        NAME: 'Rumney School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107869490,
        AWATER: 2370637,
        INTPTLAT: '+43.8347272',
        INTPTLON: '-071.7743455',
        ELSDLEA: '06000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8098, 43.0199],
            [-70.735, 43.0595],
            [-70.7012, 43.0452],
            [-70.6056, 42.9722],
            [-70.6946, 42.9564],
            [-70.7628, 42.9666],
            [-70.8145, 43.0017],
            [-70.8098, 43.0199],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306030',
        NAME: 'Rye School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32672324,
        AWATER: 62432879,
        INTPTLAT: '+43.0334671',
        INTPTLON: '-070.7192233',
        ELSDLEA: '06030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.918, 42.9067],
            [-70.8238, 42.8979],
            [-70.8114, 42.8886],
            [-70.8156, 42.8723],
            [-70.9145, 42.8866],
            [-70.9169, 42.8984],
            [-70.918, 42.9067],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306150',
        NAME: 'Seabrook School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23041607,
        AWATER: 1980056,
        INTPTLAT: '+42.8831227',
        INTPTLON: '-070.8608230',
        ELSDLEA: '06150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0114, 42.8944],
            [-70.9771, 42.8983],
            [-70.9169, 42.8984],
            [-70.9145, 42.8866],
            [-70.9145, 42.8866],
            [-70.9975, 42.8644],
            [-71.0114, 42.8944],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306270',
        NAME: 'South Hampton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20714377,
        AWATER: 62975,
        INTPTLAT: '+42.8896948',
        INTPTLON: '-070.9591110',
        ELSDLEA: '06270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4885, 44.6431],
            [-71.4147, 44.66],
            [-71.3242, 44.6618],
            [-71.3163, 44.6037],
            [-71.3328, 44.5703],
            [-71.4377, 44.5193],
            [-71.4462, 44.5157],
            [-71.4885, 44.6431],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306330',
        NAME: 'Stark School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151998280,
        AWATER: 2350275,
        INTPTLAT: '+44.5951103',
        INTPTLON: '-071.4031864',
        ELSDLEA: '06330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4885, 45.0007],
            [-71.2899, 44.9735],
            [-71.3082, 44.9158],
            [-71.515, 44.9431],
            [-71.4885, 45.0007],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306360',
        NAME: 'Stewartstown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119687622,
        AWATER: 1489315,
        INTPTLAT: '+44.9606699',
        INTPTLON: '-071.4069908',
        ELSDLEA: '06360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.194, 43.0762],
            [-72.1785, 43.132],
            [-72.0381, 43.1288],
            [-72.0421, 43.0823],
            [-72.0581, 43.0189],
            [-72.1761, 43.0234],
            [-72.1996, 43.0534],
            [-72.194, 43.0762],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306390',
        NAME: 'Stoddard School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131798169,
        AWATER: 5481891,
        INTPTLAT: '+43.0763141',
        INTPTLON: '-072.1139519',
        ELSDLEA: '06390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2358, 43.2847],
            [-71.1601, 43.3559],
            [-71.0727, 43.2995],
            [-71.0547, 43.2886],
            [-71.1335, 43.2037],
            [-71.2472, 43.2742],
            [-71.2358, 43.2847],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306420',
        NAME: 'Strafford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127320719,
        AWATER: 5750513,
        INTPTLAT: '+43.2753082',
        INTPTLON: '-071.1700041',
        ELSDLEA: '06420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6022, 44.7704],
            [-71.4444, 44.7874],
            [-71.4147, 44.66],
            [-71.4885, 44.6431],
            [-71.5578, 44.6421],
            [-71.5867, 44.6617],
            [-71.632, 44.75],
            [-71.6022, 44.7704],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306450',
        NAME: 'Stratford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205852210,
        AWATER: 1348161,
        INTPTLAT: '+44.7115391',
        INTPTLON: '-071.5221070',
        ELSDLEA: '06450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.934, 43.0215],
            [-70.9127, 43.0529],
            [-70.9077, 43.0599],
            [-70.868, 43.0055],
            [-70.8877, 42.9711],
            [-70.934, 43.0215],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306480',
        NAME: 'Stratham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39111653,
        AWATER: 944273,
        INTPTLAT: '+43.0149968',
        INTPTLON: '-070.9025858',
        ELSDLEA: '06480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3598, 43.9075],
            [-71.2126, 43.9246],
            [-71.1894, 43.8311],
            [-71.2801, 43.7751],
            [-71.3299, 43.783],
            [-71.3598, 43.9075],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306600',
        NAME: 'Tamworth School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154658851,
        AWATER: 2501115,
        INTPTLAT: '+43.8640381',
        INTPTLON: '-071.2960679',
        ELSDLEA: '06600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7321, 43.9309],
            [-71.6281, 44.0288],
            [-71.6038, 43.9736],
            [-71.5646, 43.8931],
            [-71.5606, 43.8751],
            [-71.7257, 43.882],
            [-71.7321, 43.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306660',
        NAME: 'Thornton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129974571,
        AWATER: 1570230,
        INTPTLAT: '+43.9478697',
        INTPTLON: '-071.6358022',
        ELSDLEA: '06660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3548, 43.322],
            [-72.2675, 43.3316],
            [-72.1655, 43.3083],
            [-72.2395, 43.2709],
            [-72.3548, 43.322],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306750',
        NAME: 'Unity School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95610233,
        AWATER: 676738,
        INTPTLAT: '+43.2993048',
        INTPTLON: '-072.2745675',
        ELSDLEA: '06750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9803, 43.6843],
            [-70.9793, 43.6732],
            [-70.9752, 43.6144],
            [-70.9622, 43.5411],
            [-71.0244, 43.483],
            [-71.0373, 43.5297],
            [-71.0635, 43.6325],
            [-70.9803, 43.6843],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306780',
        NAME: 'Wakefield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102200712,
        AWATER: 13573994,
        INTPTLAT: '+43.5905660',
        INTPTLON: '-071.0077543',
        ELSDLEA: '06780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9633, 43.9983],
            [-71.8219, 43.9836],
            [-71.801, 43.9509],
            [-71.8259, 43.8858],
            [-71.9592, 43.9167],
            [-71.9633, 43.9983],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306870',
        NAME: 'Warren School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125808966,
        AWATER: 1218131,
        INTPTLAT: '+43.9658297',
        INTPTLON: '-071.8591005',
        ELSDLEA: '06870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1565, 43.182],
            [-72.0654, 43.255],
            [-72.009, 43.1879],
            [-72.0234, 43.1602],
            [-72.0381, 43.1288],
            [-72.1785, 43.132],
            [-72.1565, 43.182],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306900',
        NAME: 'Washington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117732019,
        AWATER: 5633233,
        INTPTLAT: '+43.1900679',
        INTPTLON: '-072.0938788',
        ELSDLEA: '06900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6038, 43.9736],
            [-71.3787, 44.0017],
            [-71.3619, 43.9155],
            [-71.5646, 43.8931],
            [-71.6038, 43.9736],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306910',
        NAME: 'Waterville Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167886829,
        AWATER: 333506,
        INTPTLAT: '+43.9426527',
        INTPTLON: '-071.4477320',
        ELSDLEA: '06910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8097, 43.1245],
            [-71.7331, 43.1394],
            [-71.6678, 43.1526],
            [-71.6413, 43.0542],
            [-71.6385, 43.033],
            [-71.7624, 43.0141],
            [-71.7858, 43.0384],
            [-71.8097, 43.1245],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306930',
        NAME: 'Weare School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152425896,
        AWATER: 2673852,
        INTPTLAT: '+43.0780574',
        INTPTLON: '-071.7037080',
        ELSDLEA: '06930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9712, 43.9156],
            [-71.9592, 43.9167],
            [-71.8259, 43.8858],
            [-71.9071, 43.8041],
            [-72.0269, 43.8379],
            [-71.9712, 43.9156],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306990',
        NAME: 'Wentworth School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107348083,
        AWATER: 1316023,
        INTPTLAT: '+43.8587205',
        INTPTLON: '-071.9237177',
        ELSDLEA: '06990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5208, 42.9617],
            [-72.4595, 43.0206],
            [-72.3603, 43.0154],
            [-72.3632, 42.9768],
            [-72.3679, 42.9264],
            [-72.5267, 42.9341],
            [-72.5208, 42.9617],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307020',
        NAME: 'Westmoreland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92704224,
        AWATER: 2807189,
        INTPTLAT: '+42.9680753',
        INTPTLON: '-072.4308561',
        ELSDLEA: '07020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4769, 42.8531],
            [-72.389, 42.8481],
            [-72.3261, 42.7228],
            [-72.4513, 42.7267],
            [-72.4769, 42.8531],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307140',
        NAME: 'Winchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142349951,
        AWATER: 1417968,
        INTPTLAT: '+42.7893136',
        INTPTLON: '-072.3952385',
        ELSDLEA: '07140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.389, 44.4453],
            [-71.162, 44.4499],
            [-71.0188, 44.4404],
            [-71.0173, 44.4152],
            [-71.0151, 44.3714],
            [-71.0129, 44.3409],
            [-71.19, 44.3407],
            [-71.231, 44.3401],
            [-71.383, 44.3484],
            [-71.3868, 44.4079],
            [-71.389, 44.4453],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3300035',
        NAME: 'Gorham Randolph Shelburne Cooperative School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328579383,
        AWATER: 3597834,
        INTPTLAT: '+44.3937186',
        INTPTLON: '-071.1996843',
        ELSDLEA: '',
        UNSDLEA: '00035',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0244, 43.483],
            [-70.9622, 43.5411],
            [-70.9697, 43.4806],
            [-70.9857, 43.3792],
            [-70.9908, 43.3742],
            [-71.0861, 43.425],
            [-71.0244, 43.483],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3300616',
        NAME: 'Milton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85769395,
        AWATER: 3183206,
        INTPTLAT: '+43.4522291',
        INTPTLON: '-071.0146523',
        ELSDLEA: '',
        UNSDLEA: '00616',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3826, 44.0225],
            [-71.1815, 44.0222],
            [-71.1566, 44.025],
            [-71.1404, 43.9634],
            [-71.2126, 43.9246],
            [-71.3598, 43.9075],
            [-71.3619, 43.9155],
            [-71.3787, 44.0017],
            [-71.3826, 44.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301350',
        NAME: 'Albany School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193157309,
        AWATER: 1491480,
        INTPTLAT: '+43.9597707',
        INTPTLON: '-071.2740126',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9127, 44.1128],
            [-71.9127, 44.1129],
            [-71.8576, 44.0927],
            [-71.7943, 44.069],
            [-71.8219, 43.9836],
            [-71.9633, 43.9983],
            [-71.9127, 44.1128],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301830',
        NAME: 'Benton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124955549,
        AWATER: 594441,
        INTPTLAT: '+44.0359468',
        INTPTLON: '-071.8465311',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3561, 44.5247],
            [-71.1236, 44.5284],
            [-71.162, 44.4499],
            [-71.389, 44.4453],
            [-71.3561, 44.5247],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301860',
        NAME: 'Berlin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159588011,
        AWATER: 2189342,
        INTPTLAT: '+44.4843111',
        INTPTLON: '-071.2771536',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.603, 43.1652],
            [-71.4952, 43.1582],
            [-71.4652, 43.1275],
            [-71.4663, 43.1239],
            [-71.5127, 43.0764],
            [-71.6284, 43.1603],
            [-71.603, 43.1652],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3301950',
        NAME: 'Bow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72603162,
        AWATER: 1013660,
        INTPTLAT: '+43.1331765',
        INTPTLON: '-071.5372422',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0975, 44.2354],
            [-71.01, 44.2848],
            [-71.0096, 44.2733],
            [-71.0002, 44.0693],
            [-71.0841, 44.0752],
            [-71.0977, 44.1241],
            [-71.0975, 44.2354],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302200',
        NAME: 'Chatham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147106211,
        AWATER: 1427759,
        INTPTLAT: '+44.1274018',
        INTPTLON: '-071.0608356',
        ELSDLEA: '',
        UNSDLEA: '02200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3964, 43.4289],
            [-72.2695, 43.4238],
            [-72.2675, 43.3316],
            [-72.3548, 43.322],
            [-72.4069, 43.3374],
            [-72.4065, 43.3378],
            [-72.3964, 43.4289],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302340',
        NAME: 'Claremont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111683392,
        AWATER: 2497114,
        INTPTLAT: '+43.3864700',
        INTPTLON: '-072.3344957',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.465, 45.0136],
            [-71.4143, 45.0561],
            [-71.1752, 45.0148],
            [-71.1887, 44.9597],
            [-71.2899, 44.9735],
            [-71.4885, 45.0007],
            [-71.465, 45.0136],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302370',
        NAME: 'Clarksville School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155847675,
        AWATER: 5626437,
        INTPTLAT: '+45.0182490',
        INTPTLON: '-071.3255686',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4959, 44.9091],
            [-71.515, 44.9431],
            [-71.3082, 44.9158],
            [-71.3442, 44.8564],
            [-71.5227, 44.8796],
            [-71.4959, 44.9091],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302400',
        NAME: 'Colebrook School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105395253,
        AWATER: 525797,
        INTPTLAT: '+44.8952038',
        INTPTLON: '-071.4095275',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.561, 44.8247],
            [-71.5227, 44.8796],
            [-71.3442, 44.8564],
            [-71.3534, 44.822],
            [-71.3605, 44.7957],
            [-71.4444, 44.7874],
            [-71.6022, 44.7704],
            [-71.561, 44.8247],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302430',
        NAME: 'Columbia School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156097238,
        AWATER: 860457,
        INTPTLAT: '+44.8163371',
        INTPTLON: '-071.5124449',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5737, 43.2929],
            [-71.5249, 43.3031],
            [-71.4565, 43.2491],
            [-71.4952, 43.1582],
            [-71.603, 43.1652],
            [-71.6687, 43.26],
            [-71.5737, 43.2929],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302460',
        NAME: 'Concord School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155035417,
        AWATER: 7589169,
        INTPTLAT: '+43.2278334',
        INTPTLON: '-071.5568815',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1565, 42.9238],
            [-72.0612, 42.9658],
            [-72.0581, 43.0189],
            [-72.0421, 43.0823],
            [-71.9826, 43.0922],
            [-71.8851, 43.0319],
            [-71.7858, 43.0384],
            [-71.7624, 43.0141],
            [-71.7474, 42.9583],
            [-71.8397, 42.9099],
            [-71.811, 42.7876],
            [-71.9448, 42.7886],
            [-71.9904, 42.8653],
            [-72.1376, 42.8689],
            [-72.1565, 42.9238],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302480',
        NAME: 'Contoocook Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612760522,
        AWATER: 13389513,
        INTPTLAT: '+42.9309408',
        INTPTLON: '-071.9282474',
        ELSDLEA: '',
        UNSDLEA: '02480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0841, 44.0752],
            [-71.0002, 44.0693],
            [-70.9932, 43.9254],
            [-71.102, 43.9245],
            [-71.1404, 43.9634],
            [-71.1566, 44.025],
            [-71.1634, 44.0568],
            [-71.0841, 44.0752],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302490',
        NAME: 'Conway School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179757430,
        AWATER: 5847612,
        INTPTLAT: '+44.0057853',
        INTPTLON: '-071.0651698',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.1796, 44.7086],
              [-71.0344, 44.71],
              [-71.031, 44.6557],
              [-71.0188, 44.4404],
              [-71.162, 44.4499],
              [-71.1236, 44.5284],
              [-71.1791, 44.6071],
              [-71.1796, 44.7086],
            ],
          ],
          [
            [
              [-71.1634, 44.0568],
              [-71.1566, 44.025],
              [-71.1815, 44.0222],
              [-71.1634, 44.0568],
            ],
          ],
          [
            [
              [-71.4444, 44.7874],
              [-71.3605, 44.7957],
              [-71.3534, 44.822],
              [-71.3442, 44.8564],
              [-71.3082, 44.9158],
              [-71.2899, 44.9735],
              [-71.1887, 44.9597],
              [-71.1757, 44.8577],
              [-71.0468, 44.8635],
              [-71.0433, 44.8286],
              [-71.2159, 44.8249],
              [-71.2111, 44.7083],
              [-71.3242, 44.6618],
              [-71.4147, 44.66],
              [-71.4444, 44.7874],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302510',
        NAME: 'Coos County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760409087,
        AWATER: 9058419,
        INTPTLAT: '+44.6319902',
        INTPTLON: '-071.1066853',
        ELSDLEA: '',
        UNSDLEA: '02510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9528, 43.2258],
            [-70.9271, 43.255],
            [-70.8863, 43.2234],
            [-70.8199, 43.197],
            [-70.82, 43.1225],
            [-70.8471, 43.1262],
            [-70.9616, 43.2158],
            [-70.9528, 43.2258],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302640',
        NAME: 'Dover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69187312,
        AWATER: 5980094,
        INTPTLAT: '+43.1863602',
        INTPTLON: '-070.8836226',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3242, 44.6618],
            [-71.2111, 44.7083],
            [-71.1796, 44.7086],
            [-71.1791, 44.6071],
            [-71.3163, 44.6037],
            [-71.3242, 44.6618],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302730',
        NAME: 'Dummer School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124942439,
        AWATER: 3108687,
        INTPTLAT: '+44.6650096',
        INTPTLON: '-071.2518056',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.102, 43.9245],
            [-70.9932, 43.9254],
            [-70.9923, 43.8863],
            [-70.9917, 43.8776],
            [-71.0812, 43.8554],
            [-71.102, 43.9245],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302850',
        NAME: 'Eaton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62917847,
        AWATER: 3351696,
        INTPTLAT: '+43.9069604',
        INTPTLON: '-071.0475203',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.801, 43.9509],
            [-71.7321, 43.9309],
            [-71.7257, 43.882],
            [-71.7227, 43.8556],
            [-71.8259, 43.8858],
            [-71.801, 43.9509],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302870',
        NAME: 'Ellsworth School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55327042,
        AWATER: 319738,
        INTPTLAT: '+43.9117966',
        INTPTLON: '-071.7690072',
        ELSDLEA: '',
        UNSDLEA: '02870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1501, 43.0211],
            [-71.1308, 43.0626],
            [-71.073, 43.0817],
            [-71.0122, 43.082],
            [-71.0133, 43.0526],
            [-71.0139, 43.0339],
            [-71.011, 43.0203],
            [-71.0804, 43.0209],
            [-71.1501, 43.0211],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302880',
        NAME: 'Epping School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67464871,
        AWATER: 478266,
        INTPTLAT: '+43.0541870',
        INTPTLON: '-071.0841925',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4378, 43.1251],
            [-72.435, 43.2333],
            [-72.4069, 43.3374],
            [-72.3548, 43.322],
            [-72.2395, 43.2709],
            [-72.2198, 43.1748],
            [-72.2432, 43.071],
            [-72.3084, 43.0653],
            [-72.3603, 43.0154],
            [-72.4595, 43.0206],
            [-72.4378, 43.1251],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3302990',
        NAME: 'Fall Mountain Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427068689,
        AWATER: 12095672,
        INTPTLAT: '+43.1644681',
        INTPTLON: '-072.3570114',
        ELSDLEA: '',
        UNSDLEA: '02990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0888, 43.4224],
            [-71.0861, 43.425],
            [-70.9908, 43.3742],
            [-71.0727, 43.2995],
            [-71.1601, 43.3559],
            [-71.0888, 43.4224],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303000',
        NAME: 'Farmington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96394781,
        AWATER: 824183,
        INTPTLAT: '+43.3725594',
        INTPTLON: '-071.0832596',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6907, 43.5183],
            [-71.6387, 43.4927],
            [-71.6485, 43.3845],
            [-71.695, 43.4433],
            [-71.7333, 43.5033],
            [-71.6907, 43.5183],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303090',
        NAME: 'Franklin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70651031,
        AWATER: 4754969,
        INTPTLAT: '+43.4484094',
        INTPTLON: '-071.6677676',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4509, 43.6177],
            [-71.3444, 43.6295],
            [-71.3442, 43.6293],
            [-71.317, 43.5094],
            [-71.395, 43.4812],
            [-71.4491, 43.5051],
            [-71.4509, 43.6177],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303180',
        NAME: 'Gilford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101242337,
        AWATER: 38341404,
        INTPTLAT: '+43.5191565',
        INTPTLON: '-071.3755925',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6413, 43.0542],
            [-71.5185, 43.0689],
            [-71.5127, 43.0483],
            [-71.4966, 42.9827],
            [-71.6171, 42.9718],
            [-71.6385, 43.033],
            [-71.6413, 43.0542],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303240',
        NAME: 'Goffstown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95462913,
        AWATER: 1581952,
        INTPTLAT: '+43.0191982',
        INTPTLON: '-071.5554450',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3603, 43.0154],
            [-72.3084, 43.0653],
            [-72.2953, 42.9906],
            [-72.3632, 42.9768],
            [-72.3603, 43.0154],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303275',
        NAME: 'Surry School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40479682,
        AWATER: 817757,
        INTPTLAT: '+43.0369163',
        INTPTLON: '-072.3457697',
        ELSDLEA: '',
        UNSDLEA: '03275',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1996, 43.0534],
            [-72.1761, 43.0234],
            [-72.1783, 42.985],
            [-72.2169, 42.9717],
            [-72.2495, 42.9999],
            [-72.1996, 43.0534],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303293',
        NAME: 'Sullivan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47988494,
        AWATER: 489879,
        INTPTLAT: '+43.0206159',
        INTPTLON: '-072.2143736',
        ELSDLEA: '',
        UNSDLEA: '03293',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2801, 43.7751],
            [-71.1894, 43.8311],
            [-71.1836, 43.8328],
            [-70.9891, 43.792],
            [-70.9803, 43.6843],
            [-71.0635, 43.6325],
            [-71.0373, 43.5297],
            [-71.1118, 43.5069],
            [-71.0888, 43.4224],
            [-71.1601, 43.3559],
            [-71.1922, 43.5126],
            [-71.3411, 43.6272],
            [-71.2801, 43.7751],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303330',
        NAME: 'Governor Wentworth Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 679466359,
        AWATER: 74195469,
        INTPTLAT: '+43.6511225',
        INTPTLON: '-071.1503666',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4158, 44.2121],
            [-71.3952, 44.2149],
            [-71.3236, 44.0915],
            [-71.3469, 44.069],
            [-71.4129, 44.1664],
            [-71.4158, 44.2121],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303640',
        NAME: "Hart's Location School District",
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47867180,
        AWATER: 271831,
        INTPTLAT: '+44.1409256',
        INTPTLON: '-071.3757617',
        ELSDLEA: '',
        UNSDLEA: '03640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.042, 44.1429],
            [-72.0391, 44.1554],
            [-71.9127, 44.1128],
            [-71.9633, 43.9983],
            [-72.0787, 44.0296],
            [-72.0786, 44.0296],
            [-72.042, 44.1429],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303660',
        NAME: 'Haverhill Cooperative School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132063466,
        AWATER: 2899484,
        INTPTLAT: '+44.0763480',
        INTPTLON: '-071.9921137',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.009, 43.1879],
            [-71.9133, 43.2073],
            [-71.8097, 43.1245],
            [-71.7858, 43.0384],
            [-71.8851, 43.0319],
            [-71.9826, 43.0922],
            [-72.0234, 43.1602],
            [-72.009, 43.1879],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303750',
        NAME: 'Hillsboro-Deering Cooperative School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192782845,
        AWATER: 4246414,
        INTPTLAT: '+43.1144963',
        INTPTLON: '-071.9074939',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5552, 42.8582],
            [-72.4769, 42.8531],
            [-72.4513, 42.7267],
            [-72.4584, 42.7268],
            [-72.5456, 42.8239],
            [-72.5552, 42.8582],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303780',
        NAME: 'Hinsdale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53257692,
        AWATER: 5562051,
        INTPTLAT: '+42.8092977',
        INTPTLON: '-072.5048221',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7877, 43.2319],
            [-71.7258, 43.2442],
            [-71.6687, 43.26],
            [-71.603, 43.1652],
            [-71.6284, 43.1603],
            [-71.6678, 43.1526],
            [-71.7331, 43.1394],
            [-71.7877, 43.2319],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303900',
        NAME: 'Hopkinton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112321950,
        AWATER: 4637554,
        INTPTLAT: '+43.2021868',
        INTPTLON: '-071.6968031',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4271, 42.8249],
            [-71.3608, 42.799],
            [-71.3636, 42.783],
            [-71.3866, 42.6988],
            [-71.4323, 42.7],
            [-71.4638, 42.7973],
            [-71.4271, 42.8249],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303930',
        NAME: 'Hudson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73346366,
        AWATER: 2414646,
        INTPTLAT: '+42.7605084',
        INTPTLON: '-071.4094937',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.5646, 43.8931],
              [-71.3619, 43.9155],
              [-71.3598, 43.9075],
              [-71.3299, 43.783],
              [-71.5324, 43.765],
              [-71.5436, 43.7962],
              [-71.5606, 43.8751],
              [-71.5646, 43.8931],
            ],
          ],
          [
            [
              [-71.5821, 43.6919],
              [-71.5324, 43.765],
              [-71.3444, 43.6295],
              [-71.4509, 43.6177],
              [-71.5101, 43.5529],
              [-71.6426, 43.5827],
              [-71.5821, 43.6919],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3303960',
        NAME: 'Inter-Lakes School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 371841230,
        AWATER: 54578265,
        INTPTLAT: '+43.8248258',
        INTPTLON: '-071.4126429',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1376, 42.8689],
            [-71.9904, 42.8653],
            [-71.9448, 42.7886],
            [-71.9288, 42.7122],
            [-71.9816, 42.7133],
            [-72.0784, 42.7162],
            [-72.1398, 42.8465],
            [-72.1376, 42.8689],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304030',
        NAME: 'Jaffrey-Rindge Cooperative School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195584074,
        AWATER: 11541213,
        INTPTLAT: '+42.7883360',
        INTPTLON: '-072.0352018',
        ELSDLEA: '',
        UNSDLEA: '04030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1114, 43.4698],
            [-72.0909, 43.5289],
            [-71.931, 43.533],
            [-71.863, 43.4598],
            [-71.8385, 43.3957],
            [-71.7258, 43.2442],
            [-71.7877, 43.2319],
            [-71.9133, 43.2073],
            [-72.009, 43.1879],
            [-72.0654, 43.255],
            [-72.0787, 43.3386],
            [-72.1153, 43.4468],
            [-72.1114, 43.4698],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304040',
        NAME: 'Kearsarge Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 684894413,
        AWATER: 21126265,
        INTPTLAT: '+43.3585822',
        INTPTLON: '-071.9538080',
        ELSDLEA: '',
        UNSDLEA: '04040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3632, 42.9768],
            [-72.2953, 42.9906],
            [-72.2495, 42.9999],
            [-72.2169, 42.9717],
            [-72.2219, 42.924],
            [-72.2341, 42.9071],
            [-72.3468, 42.9107],
            [-72.3679, 42.9264],
            [-72.3632, 42.9768],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304050',
        NAME: 'Keene School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96472978,
        AWATER: 654531,
        INTPTLAT: '+42.9537747',
        INTPTLON: '-072.3067282',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4509, 43.6177],
            [-71.4491, 43.5051],
            [-71.5009, 43.5307],
            [-71.5101, 43.5529],
            [-71.4509, 43.6177],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304140',
        NAME: 'Laconia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51328808,
        AWATER: 15911403,
        INTPTLAT: '+43.5764957',
        INTPTLON: '-071.4823007',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3051, 43.6924],
            [-72.3051, 43.6926],
            [-72.1603, 43.6586],
            [-72.2081, 43.5852],
            [-72.3296, 43.6002],
            [-72.3282, 43.6063],
            [-72.3051, 43.6924],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304230',
        NAME: 'Lebanon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104530524,
        AWATER: 2621339,
        INTPTLAT: '+43.6233155',
        INTPTLON: '-072.2514532',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7943, 44.069],
            [-71.727, 44.1585],
            [-71.5812, 44.112],
            [-71.5374, 44.1979],
            [-71.4226, 44.1688],
            [-71.5409, 44.001],
            [-71.6281, 44.0288],
            [-71.7321, 43.9309],
            [-71.801, 43.9509],
            [-71.8219, 43.9836],
            [-71.7943, 44.069],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304260',
        NAME: 'Lincoln-Woodstock School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489253191,
        AWATER: 2880853,
        INTPTLAT: '+44.0946698',
        INTPTLON: '-071.6313690',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9294, 44.3163],
            [-71.7942, 44.2712],
            [-71.848, 44.1906],
            [-71.9335, 44.2012],
            [-72.0149, 44.2408],
            [-71.9294, 44.3163],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304290',
        NAME: 'Lisbon Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141656023,
        AWATER: 1918292,
        INTPTLAT: '+44.2517934',
        INTPTLON: '-071.9128212',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4538, 42.9034],
            [-71.4418, 42.8896],
            [-71.4271, 42.8249],
            [-71.4638, 42.7973],
            [-71.4683, 42.8005],
            [-71.4538, 42.9034],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304350',
        NAME: 'Litchfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38724429,
        AWATER: 904401,
        INTPTLAT: '+42.8460813',
        INTPTLON: '-071.4682830',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8377, 44.3478],
            [-71.7647, 44.4066],
            [-71.6967, 44.3323],
            [-71.7942, 44.2712],
            [-71.9294, 44.3163],
            [-71.9846, 44.3362],
            [-71.8377, 44.3478],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304380',
        NAME: 'Littleton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129765290,
        AWATER: 10379659,
        INTPTLAT: '+44.3433498',
        INTPTLON: '-071.7951157',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3774, 42.9472],
            [-71.3466, 42.9418],
            [-71.3311, 42.8411],
            [-71.3608, 42.799],
            [-71.4271, 42.8249],
            [-71.4418, 42.8896],
            [-71.3774, 42.9472],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304410',
        NAME: 'Londonderry School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108740501,
        AWATER: 332718,
        INTPTLAT: '+42.8698392',
        INTPTLON: '-071.3878445',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5127, 43.0483],
            [-71.3922, 43.009],
            [-71.3774, 42.9472],
            [-71.4418, 42.8896],
            [-71.4538, 42.9034],
            [-71.4527, 42.9111],
            [-71.4966, 42.9827],
            [-71.5127, 43.0483],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304590',
        NAME: 'Manchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85730174,
        AWATER: 4822469,
        INTPTLAT: '+42.9849406',
        INTPTLON: '-071.4441315',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.811, 42.7876],
            [-71.7902, 42.7897],
            [-71.8054, 42.709],
            [-71.8987, 42.7114],
            [-71.9288, 42.7122],
            [-71.9448, 42.7886],
            [-71.811, 42.7876],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304670',
        NAME: 'Mascenic Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102580571,
        AWATER: 819572,
        INTPTLAT: '+42.7518915',
        INTPTLON: '-071.8626204',
        ELSDLEA: '',
        UNSDLEA: '04670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2081, 43.5852],
            [-72.1603, 43.6586],
            [-72.0757, 43.7416],
            [-72.0269, 43.8379],
            [-71.9071, 43.8041],
            [-71.9321, 43.7058],
            [-71.8641, 43.5994],
            [-71.931, 43.533],
            [-72.0909, 43.5289],
            [-72.1712, 43.574],
            [-72.2081, 43.5852],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304680',
        NAME: 'Mascoma Valley Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525760731,
        AWATER: 16077008,
        INTPTLAT: '+43.6769397',
        INTPTLON: '-072.0070015',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.57, 42.8963],
            [-71.4527, 42.9111],
            [-71.4538, 42.9034],
            [-71.4683, 42.8005],
            [-71.5562, 42.7926],
            [-71.5584, 42.8033],
            [-71.57, 42.8963],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304740',
        NAME: 'Merrimack School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84265782,
        AWATER: 2260341,
        INTPTLAT: '+42.8520335',
        INTPTLON: '-071.5198762',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.5249, 43.3031],
              [-71.4532, 43.402],
              [-71.347, 43.3466],
              [-71.3814, 43.3128],
              [-71.4496, 43.2452],
              [-71.4565, 43.2491],
              [-71.5249, 43.3031],
            ],
          ],
          [
            [
              [-71.8385, 43.3957],
              [-71.695, 43.4433],
              [-71.6485, 43.3845],
              [-71.6462, 43.3637],
              [-71.5737, 43.2929],
              [-71.6687, 43.26],
              [-71.7258, 43.2442],
              [-71.8385, 43.3957],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304760',
        NAME: 'Merrimack Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372550619,
        AWATER: 6349621,
        INTPTLAT: '+43.2908476',
        INTPTLON: '-071.7215386',
        ELSDLEA: '',
        UNSDLEA: '04760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0373, 43.5297],
            [-71.0244, 43.483],
            [-71.0861, 43.425],
            [-71.0888, 43.4224],
            [-71.1118, 43.5069],
            [-71.0373, 43.5297],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304770',
        NAME: 'Middleton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46851569,
        AWATER: 1093456,
        INTPTLAT: '+43.4830066',
        INTPTLON: '-071.0706405',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6947, 42.8634],
            [-71.6664, 42.8666],
            [-71.6072, 42.7982],
            [-71.6426, 42.778],
            [-71.714, 42.7691],
            [-71.7193, 42.7973],
            [-71.6947, 42.8634],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304830',
        NAME: 'Milford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65581867,
        AWATER: 143903,
        INTPTLAT: '+42.8186453',
        INTPTLON: '-071.6733537',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-72.2169, 42.9717],
              [-72.1783, 42.985],
              [-72.1672, 42.9626],
              [-72.1585, 42.9336],
              [-72.2219, 42.924],
              [-72.2169, 42.9717],
            ],
          ],
          [
            [
              [-72.2432, 43.071],
              [-72.194, 43.0762],
              [-72.1996, 43.0534],
              [-72.2495, 42.9999],
              [-72.2953, 42.9906],
              [-72.3084, 43.0653],
              [-72.2432, 43.071],
            ],
          ],
          [
            [
              [-72.3468, 42.9107],
              [-72.2341, 42.9071],
              [-72.1398, 42.8465],
              [-72.0784, 42.7162],
              [-72.1111, 42.7172],
              [-72.283, 42.7215],
              [-72.3261, 42.7228],
              [-72.389, 42.8481],
              [-72.3468, 42.9107],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304890',
        NAME: 'Monadnock Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422659123,
        AWATER: 6271460,
        INTPTLAT: '+42.8472114',
        INTPTLON: '-072.2419796',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5324, 43.765],
            [-71.3299, 43.783],
            [-71.2801, 43.7751],
            [-71.3411, 43.6272],
            [-71.3442, 43.6293],
            [-71.3444, 43.6295],
            [-71.5324, 43.765],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304960',
        NAME: 'Moultonborough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154046201,
        AWATER: 39184533,
        INTPTLAT: '+43.7205148',
        INTPTLON: '-071.3685820',
        ELSDLEA: '',
        UNSDLEA: '04960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5562, 42.7926],
            [-71.4683, 42.8005],
            [-71.4638, 42.7973],
            [-71.4323, 42.7],
            [-71.4577, 42.7005],
            [-71.5425, 42.7027],
            [-71.543, 42.7027],
            [-71.5562, 42.7926],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3304980',
        NAME: 'Nashua School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79920841,
        AWATER: 2667031,
        INTPTLAT: '+42.7490744',
        INTPTLON: '-071.4905435',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9071, 43.8041],
            [-71.7764, 43.7709],
            [-71.6585, 43.7175],
            [-71.5821, 43.6919],
            [-71.6426, 43.5827],
            [-71.7061, 43.5382],
            [-71.8149, 43.5511],
            [-71.8171, 43.4751],
            [-71.863, 43.4598],
            [-71.931, 43.533],
            [-71.8641, 43.5994],
            [-71.9321, 43.7058],
            [-71.9071, 43.8041],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305220',
        NAME: 'Newfound Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550843891,
        AWATER: 25302411,
        INTPTLAT: '+43.6427090',
        INTPTLON: '-071.7891385',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0122, 43.082],
            [-70.8755, 43.0805],
            [-70.8802, 43.0772],
            [-70.9077, 43.0599],
            [-70.9127, 43.0529],
            [-71.0133, 43.0526],
            [-71.0122, 43.082],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305280',
        NAME: 'Newmarket School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32493539,
        AWATER: 4198524,
        INTPTLAT: '+43.0709095',
        INTPTLON: '-070.9468748',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2695, 43.4238],
            [-72.1263, 43.3945],
            [-72.1275, 43.3298],
            [-72.1655, 43.3083],
            [-72.2675, 43.3316],
            [-72.2695, 43.4238],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305310',
        NAME: 'Newport School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112832840,
        AWATER: 206600,
        INTPTLAT: '+43.3713455',
        INTPTLON: '-072.2027889',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5563, 44.5699],
            [-71.5578, 44.6421],
            [-71.4885, 44.6431],
            [-71.4462, 44.5157],
            [-71.5713, 44.5383],
            [-71.5563, 44.5699],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305400',
        NAME: 'Northumberland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92496406,
        AWATER: 2017604,
        INTPTLAT: '+44.5685595',
        INTPTLON: '-071.5192700',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.073, 43.0817],
            [-71.0267, 43.1381],
            [-70.9616, 43.2158],
            [-70.8471, 43.1262],
            [-70.8755, 43.0805],
            [-71.0122, 43.082],
            [-71.073, 43.0817],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305520',
        NAME: 'Oyster River Cooperative School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139838824,
        AWATER: 8231734,
        INTPTLAT: '+43.1278470',
        INTPTLON: '-070.9604898',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3636, 42.783],
            [-71.2845, 42.7601],
            [-71.2455, 42.7426],
            [-71.2552, 42.7367],
            [-71.3698, 42.6983],
            [-71.3866, 42.6988],
            [-71.3636, 42.783],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305550',
        NAME: 'Pelham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68344846,
        AWATER: 1413968,
        INTPTLAT: '+42.7309924',
        INTPTLON: '-071.3370733',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4565, 43.2491],
            [-71.4496, 43.2452],
            [-71.4029, 43.2008],
            [-71.3938, 43.1756],
            [-71.4652, 43.1275],
            [-71.4952, 43.1582],
            [-71.4565, 43.2491],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305580',
        NAME: 'Pembroke School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59006028,
        AWATER: 414803,
        INTPTLAT: '+43.1677223',
        INTPTLON: '-071.4385169',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0839, 45.3055],
            [-71.0595, 45.0149],
            [-71.1752, 45.0148],
            [-71.4143, 45.0561],
            [-71.465, 45.0136],
            [-71.5011, 45.0133],
            [-71.3753, 45.2455],
            [-71.2971, 45.299],
            [-71.132, 45.2454],
            [-71.0839, 45.3055],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305700',
        NAME: 'Pittsburg School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728593899,
        AWATER: 25322506,
        INTPTLAT: '+45.1453260',
        INTPTLON: '-071.2548765',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.347, 43.3466],
            [-71.2358, 43.2847],
            [-71.2472, 43.2742],
            [-71.2616, 43.2514],
            [-71.36, 43.2684],
            [-71.3814, 43.3128],
            [-71.347, 43.3466],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305730',
        NAME: 'Pittsfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61677466,
        AWATER: 810536,
        INTPTLAT: '+43.2885012',
        INTPTLON: '-071.3093678',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7827, 43.0996],
            [-70.7678, 43.0936],
            [-70.738, 43.0737],
            [-70.735, 43.0595],
            [-70.8098, 43.0199],
            [-70.8173, 43.0594],
            [-70.7827, 43.0996],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305820',
        NAME: 'Portsmouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40493552,
        AWATER: 3144107,
        INTPTLAT: '+43.0605947',
        INTPTLON: '-070.7803481',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2613, 43.016],
            [-71.2305, 43.08],
            [-71.2132, 43.0771],
            [-71.1308, 43.0626],
            [-71.1501, 43.0211],
            [-71.1678, 42.9846],
            [-71.2613, 43.016],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305880',
        NAME: 'Raymond School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74504232,
        AWATER: 2062596,
        INTPTLAT: '+43.0325830',
        INTPTLON: '-071.2031436',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0727, 43.2995],
            [-70.9908, 43.3742],
            [-70.9857, 43.3792],
            [-70.8987, 43.2874],
            [-70.9271, 43.255],
            [-70.9528, 43.2258],
            [-71.0547, 43.2886],
            [-71.0727, 43.2995],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3305940',
        NAME: 'Rochester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117589703,
        AWATER: 935955,
        INTPTLAT: '+43.2984927',
        INTPTLON: '-070.9770055',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2425, 42.848],
            [-71.2065, 42.8524],
            [-71.1667, 42.8083],
            [-71.1824, 42.7942],
            [-71.2455, 42.7426],
            [-71.2845, 42.7601],
            [-71.2425, 42.848],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306060',
        NAME: 'Salem School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64037651,
        AWATER: 2915952,
        INTPTLAT: '+42.7882642',
        INTPTLON: '-071.2217257',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1037, 42.8959],
            [-71.0922, 42.9592],
            [-71.088, 42.9593],
            [-71.0061, 42.9587],
            [-70.9975, 42.9583],
            [-71.0114, 42.8944],
            [-70.9975, 42.8644],
            [-71.0049, 42.8633],
            [-71.0536, 42.8331],
            [-71.1256, 42.8772],
            [-71.1037, 42.8959],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306080',
        NAME: 'Sanborn Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76562686,
        AWATER: 3577584,
        INTPTLAT: '+42.8995555',
        INTPTLON: '-071.0643609',
        ELSDLEA: '',
        UNSDLEA: '06080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.5009, 43.5307],
              [-71.4491, 43.5051],
              [-71.395, 43.4812],
              [-71.4674, 43.4094],
              [-71.547, 43.4526],
              [-71.5009, 43.5307],
            ],
          ],
          [
            [
              [-71.6462, 43.3637],
              [-71.4674, 43.4094],
              [-71.4532, 43.402],
              [-71.5249, 43.3031],
              [-71.5737, 43.2929],
              [-71.6462, 43.3637],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306180',
        NAME: 'Shaker Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191809687,
        AWATER: 6908523,
        INTPTLAT: '+43.4790310',
        INTPTLON: '-071.4493000',
        ELSDLEA: '',
        UNSDLEA: '06180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9271, 43.255],
            [-70.8987, 43.2874],
            [-70.8389, 43.2445],
            [-70.8863, 43.2234],
            [-70.9271, 43.255],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306240',
        NAME: 'Somersworth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25347719,
        AWATER: 534842,
        INTPTLAT: '+43.2534762',
        INTPTLON: '-070.8879437',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1263, 43.3945],
            [-72.1153, 43.4468],
            [-72.0787, 43.3386],
            [-72.1275, 43.3298],
            [-72.1263, 43.3945],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306540',
        NAME: 'Sunapee School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54663981,
        AWATER: 10630307,
        INTPTLAT: '+43.3900882',
        INTPTLON: '-072.0931293',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.2078, 42.86],
              [-71.1256, 42.8772],
              [-71.0536, 42.8331],
              [-71.1667, 42.8083],
              [-71.2065, 42.8524],
              [-71.2078, 42.86],
            ],
          ],
          [
            [
              [-71.1793, 42.9605],
              [-71.0922, 42.9592],
              [-71.1037, 42.8959],
              [-71.2194, 42.9116],
              [-71.2196, 42.9199],
              [-71.1793, 42.9605],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3306720',
        NAME: 'Timberlane Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122880918,
        AWATER: 2172133,
        INTPTLAT: '+42.9255926',
        INTPTLON: '-071.1820883',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7647, 44.4066],
            [-71.7384, 44.408],
            [-71.6054, 44.4845],
            [-71.5713, 44.5383],
            [-71.4462, 44.5157],
            [-71.4377, 44.5193],
            [-71.3868, 44.4079],
            [-71.383, 44.3484],
            [-71.3952, 44.2149],
            [-71.4158, 44.2121],
            [-71.5758, 44.2547],
            [-71.6967, 44.3323],
            [-71.7647, 44.4066],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307050',
        NAME: 'White Mountain Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548616234,
        AWATER: 5764689,
        INTPTLAT: '+44.3794446',
        INTPTLON: '-071.5398201',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7474, 42.9583],
            [-71.7377, 42.9226],
            [-71.6947, 42.8634],
            [-71.7193, 42.7973],
            [-71.7902, 42.7897],
            [-71.811, 42.7876],
            [-71.8397, 42.9099],
            [-71.7474, 42.9583],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307115',
        NAME: 'Wilton-Lyndeborough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145477708,
        AWATER: 545424,
        INTPTLAT: '+42.8695022',
        INTPTLON: '-071.7741500',
        ELSDLEA: '',
        UNSDLEA: '07115',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3608, 42.799],
            [-71.3311, 42.8411],
            [-71.2425, 42.848],
            [-71.2845, 42.7601],
            [-71.3636, 42.783],
            [-71.3608, 42.799],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307170',
        NAME: 'Windham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69427756,
        AWATER: 2739837,
        INTPTLAT: '+42.8110921',
        INTPTLON: '-071.3026878',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0381, 43.1288],
            [-72.0234, 43.1602],
            [-71.9826, 43.0922],
            [-72.0421, 43.0823],
            [-72.0381, 43.1288],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307200',
        NAME: 'Windsor School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21623009,
        AWATER: 707198,
        INTPTLAT: '+43.1130444',
        INTPTLON: '-072.0136387',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6426, 43.5827],
            [-71.5101, 43.5529],
            [-71.5009, 43.5307],
            [-71.547, 43.4526],
            [-71.4674, 43.4094],
            [-71.6462, 43.3637],
            [-71.6485, 43.3845],
            [-71.6387, 43.4927],
            [-71.6907, 43.5183],
            [-71.7061, 43.5382],
            [-71.6426, 43.5827],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3307300',
        NAME: 'Winnisquam Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226809034,
        AWATER: 8670759,
        INTPTLAT: '+43.4959639',
        INTPTLON: '-071.5977111',
        ELSDLEA: '',
        UNSDLEA: '07300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.099, 43.9575],
            [-72.099, 43.9575],
            [-71.9712, 43.9156],
            [-72.0269, 43.8379],
            [-72.1683, 43.8723],
            [-72.099, 43.9575],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3333009',
        NAME: 'Rivendell Interstate School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'C',
        FUNCSTAT: 'E',
        ALAND: 120780819,
        AWATER: 3605477,
        INTPTLAT: '+43.8832271',
        INTPTLON: '-072.0335833',
        ELSDLEA: '',
        UNSDLEA: '33009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.6946, 42.9564],
              [-70.6056, 42.9722],
              [-70.5751, 42.9171],
              [-70.6946, 42.9564],
            ],
          ],
          [
            [
              [-70.8114, 42.8886],
              [-70.7776, 42.9508],
              [-70.7628, 42.9666],
              [-70.6946, 42.9564],
              [-70.735, 42.8747],
              [-70.8138, 42.8724],
              [-70.8156, 42.8723],
              [-70.8114, 42.8886],
            ],
          ],
          [
            [
              [-71.1752, 45.0148],
              [-71.0595, 45.0149],
              [-71.0582, 44.9905],
              [-71.0494, 44.896],
              [-71.0468, 44.8635],
              [-71.1757, 44.8577],
              [-71.1887, 44.9597],
              [-71.1752, 45.0148],
            ],
          ],
          [
            [
              [-71.4377, 44.5193],
              [-71.3328, 44.5703],
              [-71.3561, 44.5247],
              [-71.389, 44.4453],
              [-71.3868, 44.4079],
              [-71.4377, 44.5193],
            ],
          ],
          [
            [
              [-71.383, 44.3484],
              [-71.231, 44.3401],
              [-71.19, 44.3407],
              [-71.0129, 44.3409],
              [-71.01, 44.2848],
              [-71.0975, 44.2354],
              [-71.2432, 44.2439],
              [-71.2605, 44.228],
              [-71.3027, 44.1372],
              [-71.3236, 44.0915],
              [-71.3952, 44.2149],
              [-71.383, 44.3484],
            ],
          ],
          [
            [
              [-71.6281, 44.0288],
              [-71.5409, 44.001],
              [-71.4226, 44.1688],
              [-71.4129, 44.1664],
              [-71.3469, 44.069],
              [-71.3826, 44.0225],
              [-71.3787, 44.0017],
              [-71.6038, 43.9736],
              [-71.6281, 44.0288],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '33',
        SCSDLEA: '',
        GEOID: '3399997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 859010418,
        AWATER: 115349215,
        INTPTLAT: '+44.3360917',
        INTPTLON: '-071.3019427',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const InputCheckbox = ({
  required,
  label,
  name,
  onChange,
}) => (
  <div className="InputCheckbox">
    <label htmlFor={label}>{label}</label>
    <input
      id={label}
      type="checkbox"
      required={required}
      name={name}
      onChange={onChange}
    />
  </div>
);

InputCheckbox.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

InputCheckbox.defaultProps = {
  required: false,
  label: null,
};

export default InputCheckbox;
